import { makeStyles } from "@material-ui/core";
import React from "react";

import { Link } from "react-router-dom";
import ContadorModal from "../Modals/ContadorModal";
import NarrativaModalS56 from "../Modals/Frames/NarrativaModalS56";
import IntervencionHeraldoModal from "../Modals/IntervencionHeraldoModal";

const useStyles = makeStyles((theme) => ({
  // },
  // '& .heraldo':{
  //     height:'787px',
  //     zIndex:2,
  // }

  // },
  link: {
    "& .BotonGrande": {
      cursor: "pointer",
      border: "none",
      backgroundColor: "transparent",
      position: "absolute",
      height: "55.7px",
      width: "55.7px",
      borderRadius: "50%",
      font: " 22px lato",
      "&:hover": {
        // borderWidth: "2px",
        // borderStyle: "solid",
        // borderColor: (props) => props.color,
      },
    },
    "& .Bienvenida": {
      top: "106.5px",
      left: "320px",
      "&:hover": {
        "&:before": {
          display: "flex",
          position: "relative",
          justifyContent: "center",
          alignItems: "center",
          bottom: "50px",
          content: '"Bienvenida"',
          background: "#fff",
          borderRadius: "25px",
          font: "bold 16px lato",
          height: "25px",
          width: "100px",
        },
      },
    },
    "& .Boton": {
      cursor: "pointer",
      border: "none",
      backgroundColor: "transparent",
      position: "absolute",
      height: "37px",
      width: "37px",
      borderRadius: "50%",
    },
    "& .bloqueada": {
      borderWidth: "2px",
      borderStyle: "solid",
      borderColor: "#808080",
      backgroundColor: "#80808080",

      "&:hover": {
        cursor: "default",
      },
    },
    "& .completa": {
      borderWidth: "2px",
      borderStyle: "solid",
      borderColor: "#008000",
      "&:hover": {},
    },
    "& .contador": {
      borderWidth: "2px",
      borderStyle: "solid",
      borderColor: "#FFCE30",
      "&:hover": {},
    },
    "& .remedial": {
      borderWidth: "2px",
      borderStyle: "solid",
      borderColor: "#800080",
      "&:hover": {},
    },

    "& .Actividad56": {
      top: "665.5px",
      left: "1553.5px",
    },

    "& .Actividad60": {
      top: "917.5px",
      left: "1543.5px",
    },
  },
}));

const ButtonMapS56 = ({ data, handleClose, actualizeList, grade, abbreviatedGrade }) => {
  const { path_imagen, path_video, narrativa, nombre, sesion } = data;
  const [intervention, setIntervention] = React.useState(false);
  const [frame, setFrame] = React.useState(false);
  const [contadorOpen, setContadorOpen] = React.useState(false);

  const handleIntervention = () => {
    setIntervention(!intervention);
  };

  const handleFrame = () => {
    setFrame(!frame);
  };

  const handleContadorOpen = () => {
    setContadorOpen(!contadorOpen);
  };

  const sessionNumber = `${`0${data?.sesion}`?.slice(-2)}${abbreviatedGrade}`;
  //En caso de ser una sesion menor a 10 agregará un 0. Ejemeplo: Sesion 2 seria /session02

  const formativas = [1, 2, 20, 38, 55, 59, 60];
  const buttonLarge = formativas.includes(sesion);

  const classes = useStyles({});
  const next = () => {
    setFrame(false);
    setIntervention(true);
  };

   return (
     <>
       {/* {data?.actividad_status.toLowerCase() === "pendiente" && ( */}
       {data?.actividad_status.toLowerCase() === "sesión desbloqueada" && (
         <>
           <NarrativaModalS56
             open={frame}
             // path={"https://pearson-cdl-contenidos-qa.s3.amazonaws.com/G1/frames/G1-Frame-4.mp4"}
             path={path_video}
             handleClose={handleFrame}
             handleNext={next}
           />
           <IntervencionHeraldoModal
             open={intervention}
             path={path_imagen}
             handleClose={handleIntervention}
             narrativa={narrativa}
             alt={nombre}
             grado={grade}
             to={sessionNumber}
             sesion={sesion}
           />
           <Link className={classes.link} to="#">
             <button
               className={buttonLarge ? `BotonGrande Actividad${sesion}` : `Boton Actividad${sesion}`}
               onClick={handleFrame}
               aria-label={`Actividad ${sesion}`}
             ></button>
           </Link>
         </>
       )}
       {data?.actividad_status.toLowerCase() === "completa" && (
         <>
           <NarrativaModalS56
             open={frame}
             // path={"https://pearson-cdl-contenidos-qa.s3.amazonaws.com/G1/frames/G1-Frame-4.mp4"}
             path={path_video}
             handleClose={handleFrame}
             handleNext={next}
           />
           <IntervencionHeraldoModal
             open={intervention}
             path={path_imagen}
             handleClose={handleIntervention}
             narrativa={narrativa}
             alt={nombre}
             grado={grade}
             to={sessionNumber}
             sesion={sesion}
           />
           <Link className={classes.link} to="#">
             <button
               className={buttonLarge ? `BotonGrande Actividad${sesion} completa` : `Boton Actividad${sesion} completa`}
               aria-label={`Actividad ${sesion}`}
               onClick={handleFrame}
             ></button>
           </Link>
         </>
       )}
       {data?.actividad_status.toLowerCase() === "contador" && (
         <>
           <Link className={classes.link} to="#">
             <button
               className={buttonLarge ? `BotonGrande Actividad${sesion} contador` : `Boton Actividad${sesion} contador`}
               aria-label={`Actividad ${sesion}`}
               onClick={handleContadorOpen}
             ></button>
           </Link>
           <ContadorModal
             open={contadorOpen}
             handleClose={handleContadorOpen}
             timestamp={data.tiempo_espera}
             actualizeList={actualizeList}
           />
         </>
       )}
       {data?.actividad_status.toLowerCase() === "bloqueada" && (
         <>
           <Link className={classes.link} to="#">
             <button
               className={buttonLarge ? `BotonGrande Actividad${sesion} bloqueada` : `Boton Actividad${sesion} bloqueada`}
               onClick={handleContadorOpen}
               aria-label={`Actividad ${sesion}`}
               disabled
             ></button>
           </Link>
         </>
       )}
     </>
   );
};

export default ButtonMapS56;
  // return (
  //   <>
  //     <NarrativaModalS56
  //       open={frame}
  //       // path={"https://pearson-cdl-contenidos-qa.s3.amazonaws.com/G1/frames/G1-Frame-4.mp4"}
  //       path={path_video}
  //       handleClose={handleFrame}
  //       handleNext={next}
  //     />
  //     <IntervencionHeraldoModal
  //       open={intervention}
  //       path={path_imagen}
  //       handleClose={handleIntervention}
  //       narrativa={narrativa}
  //       alt={nombre}
  //       grado={grade}
  //       to={sessionNumber}
  //       sesion={sesion}
  //     />
  //     <Link className={classes.link} to="#">
  //       <button
  //         className={buttonLarge ? `BotonGrande Actividad${sesion}` : `Boton Actividad${sesion}`}
  //         onClick={handleFrame}
  //       ></button>
  //     </Link>
  //   </>
  // );