import { Button, Grid, makeStyles } from "@material-ui/core";
import React, { useContext, useEffect } from "react";
import DrawerContext from "../../../../../usability/DrawerContext";
import { useOnLoadImages } from "../../../../hooks/useOnLoadImages";
import Drawer from "../../ReadingTools/Drawer";

const useStyles =  makeStyles (theme =>({
    Contenedor:{
       background:'#E9FBF9',
       width:'100vw',
       [theme.breakpoints.down(1161)]:{
           background:'none',
       },
       [theme.breakpoints.down(600)]:{
           marginTop: '79.51px',
       }
   },
  ContainerInfoLectura: {
    position: "relative",
    height: "86px",
    [theme.breakpoints.down(502)]:{
        height: "43px",
    },
    background: "#76D4C3",
    "& .BarraInfoLectura": {
      height: "86px",
      background: `Url(https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Barras/Azul.png)`,
        [theme.breakpoints.down(502)]:{
            height: "43px",
        },
    },
    "& .InfoLectura": {
      position: "absolute",
      height: "86px",
      width: "219px",
      boxSizing: "border-box",
      padding: "17.2px 0",
      background: "#FAFAFA",
      borderRadius: "50px",
      boxShadow: "2px 2px 2px 2px #00000029",
      zIndex: "4",
        [theme.breakpoints.down(502)]:{
            height: "43px",
            width: "109px",
            padding: "8.6px 0",
            borderRadius: "25px",
        },
    },
    "& .InfoLectura:before": {
      position: "absolute",
      display: "flex",
      boxSizing: "border-box",
      padding: "0 0 0 10px",
      justifyContent: "center",
      alignItems: "center",
      height: "39.88px",
      width: "39.88px",
      borderRadius: "50%",
      content: '""',
      boxShadow: "3px 0px .5px 0px #00000029",
      background: "#FAFAFA",
      left: "90px",
      top: "65px",
      transform: "rotate(0.25turn)",
      [theme.breakpoints.down(502)]:{
        left: "45px",
        top: "30px",
        height: "20.88px",
        width: "20.88px",
        },
    },
    "& .InfoLectura:after": {
      content: '""',
      height: "15px",
      width: "15px",
      borderTop: "2px solid #20A698",
      borderLeft: "2px solid #20A698",
      position: "absolute",
      bottom: "-10px",
      left: "Calc(50% - 8px)",
      transform: "rotate(0.625turn)",
      [theme.breakpoints.down(502)]:{
        height: "10px",
        width: "10px",
        left: "Calc(50% - 4.5px)",
        bottom: "-3px",
        borderTop: "1px solid #20A698",
        borderLeft: "1px solid #20A698",
        },
    },
    "& .Sesion": {
      borderLeft: "2px solid #D8D8D8",
    },
    "& .Dato": {
      margin: "0 0 5px 0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#20A698",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        margin: "0 0 2.5px 0",
        },
    },
    "& .Caracter": {
      margin: "0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#000000",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        },
    },
       '& .Regresar':{
        color: '#F2624F',
        border: '#F2624F 1px solid',
        height: '30px',
        width:'100px',
        borderRadius: '20px',
        letterSpacing: '0.14px',
        textTransform: 'none',
        background:'#FEFAF1',
        top:'calc(50% - 15px)',
        left:'5%',
        zIndex:4,
        [theme.breakpoints.down(502)]: {
          font:'bold 10px lato',
          width: "60px",
        },
      },
  },
   ContenedorLectura:{
       background:'#fff',
       width:'1160px',
       height:'100%',
       padding:'0',
           transform: (props) =>
      props.scale > 1 ? `scale(${props.scale}) translate(0px, 10%)` : `none`,
       [theme.breakpoints.down(1161)]:{
           width:'766px',
         },
         [theme.breakpoints.down(766)]:{
           width:'350px',
         },
       '& .Escritorio':{
           display:'block',
           [theme.breakpoints.down(1161)]:{
               display:'none'
           },
       },
       '& .Tablet':{
           display:'none',
           [theme.breakpoints.down(1161)]:{
               display:'block'
           },
       }
   },

   Parrafo1:{
       position:'relative',
       '& .Pos':{
           position:'absolute',
           margin:'0',
       },
       '& .Titulo':{
           margin:0,
           font:'48px Fredoka One',
           lineHeigt:'58px',
           color:'#FFFFFF',
           top:'2.63%',
           left:'18.23%',
           [theme.breakpoints.down(1161)]:{
               font:'43px Fredoka One',
               lineHeigt:'52px',
               left:'10.57%',
               top:'1.56%',
           },
           [theme.breakpoints.down(766)]:{
               font:'18px Fredoka One',
               lineHeigt:'22px',
           },
       },
       '& .Titulo2':{
           margin:0,
           font:'92px Fredoka One',
           lineHeigt:'112px',
           color:'#1363D0',
           top:'23.63%',
           [theme.breakpoints.down(1161)]:{
               font:'62px Fredoka One',
               lineHeigt:'75px',
               top:'26.86%',
           },
           [theme.breakpoints.down(766)]:{
               font:'25px Fredoka One',
               lineHeigt:'25px',
           },
       },
       '& .SubTitulo':{
           margin:0,
           font:'bold 22px lato',
           lineHeigt:'30px',
           letterSpacing:'.21px',
           color:'#FFFFFF',
           width:'379px',
           top:'4.94%',
           left:'18.23%',
           [theme.breakpoints.down(1161)]:{
               font:'bold 18px lato',
               lineHeigt:'28px',
               letterSpacing:'.17px',
               left:'10.57%',
               top:'4.81%',
           },
           [theme.breakpoints.down(766)]:{
               font:'bold 8.5px lato',
               lineHeigt:'10px',
               letterSpacing:'.12px',
               width:'179px',
           },
       },
       '& .SubTitulo2':{
           margin:'0 0 21px 0',
           font:' 30px Fredoka One',
           color:'#1363D0',
           [theme.breakpoints.down(1161)]:{
               font:'25px Fredoka One',
           },
           [theme.breakpoints.down(766)]:{
               margin:'0 0 11px 0',
               font:'10px Fredoka One',
           },
       },
       '& .SubTitulo3':{
           margin:'0',
           color:'#2197ED',
           font:'40px Fredoka One',
           lineHeigt:'48px',
           top:'26.36%',
           [theme.breakpoints.down(1161)]:{
               font:'27px Fredoka One',
               lineHeigt:'32px',
               top:'28.86%',
           },
           [theme.breakpoints.down(766)]:{
               font:'12px Fredoka One',
               lineHeigt:'20px',
           },
       },
       '& .Lectura':{
           margin:'0',
           color:'#000000',
           font:' 22px lato',
           letterSpacing:'.21px',
           lineHeigt:'30px',
           [theme.breakpoints.down(1161)]:{
               font:' 18px lato',
               letterSpacing:'.17px',
               lineHeigt:'28px',
           },
            [theme.breakpoints.down(766)]:{
               font:'7px lato',
               letterSpacing:'.09px',
               lineHeigt:'9px',
           },
       },
       '& .ul':{
           padding:'0 0 0 19px',
           margin:'0',
           color:'#2197ED',
           [theme.breakpoints.down(766)]:{
               padding:'0 0 0 10px',
           },
       },
       '& .li':{
           margin:'0 0 8px 0',
           [theme.breakpoints.down(766)]:{
               margin:'0 0 4px 0',
           },
       },
       '& .Paso':{
           left:'257px',
           [theme.breakpoints.down(1161)]:{
               left:'18.80%',
           },
       },
       '& .Lect':{
           top:'13%',
           left:'17%',
           [theme.breakpoints.down(1161)]:{
               top:'14.37%',
               left:'10.57%',
           },
       },
       '& .Lect1':{
           top:'1180px',
           width:'725px',
           [theme.breakpoints.down(1161)]:{
               top:'31.1%',
               width:'55%',
           },
       },
       '& .Lect2':{
           top:'1270px',
           width:'665px',
           [theme.breakpoints.down(1161)]:{
               top:'33.5%',
               width:'64.45%',
           },
       },
       '& .Lect3':{
           top:'1830px',
           width:'646px',
           [theme.breakpoints.down(1161)]:{
               top:'46.55%',
               width:'62.45%',
           },
       },
       '& .Lect4':{
           top:'1931px',
           [theme.breakpoints.down(1161)]:{
               top:'48.82%',
           },
       },
       '& .Lect5':{
           top:'2010px',
           width:'661px',
           [theme.breakpoints.down(1161)]:{
               top:'50%',
               width:'61%',
           },
       },
       '& .Lect6':{
           top:'2111px',
           [theme.breakpoints.down(1161)]:{
               top:'53.05%',
           },
       },
       '& .Lect7':{
           top:'2600px',
           width:'661px',
           [theme.breakpoints.down(1161)]:{
               top:'64.05%',
               width:'58%',
           },
       },
       '& .Lect8':{
           top:'2715px',
           width:'661px',
           [theme.breakpoints.down(1161)]:{
               top:'67.5%',
               width:'63%',
           },
       },
       '& .Lect9':{
           top:'3280px',
           width:'567px',
           [theme.breakpoints.down(1161)]:{
               top:'81%',
               width:'61%',
           },
       },
       '& .Lect10':{
           top:'3400px',
           width:'661px',
           [theme.breakpoints.down(1161)]:{
               top:'83.5%',
               width:'52%',
           },
       },

       '& .Derechos':{
           position:'relative',
           bottom:'35px',
           textAlign:"center",
           margin:"0px",
           font:" 15px lato",
           color:"#717476",
           [theme.breakpoints.down(1161)]:{
               display:'none'
           },
       },
       '& .Imagen':{
           [theme.breakpoints.down(766)]:{
               width:'100%',
           },
       },
   },

}));

const Arma_tu_propia_alcancía = (props) => {

  const {activeStep, setActiveStep, handlePreviewStep, id_actividad} = props

  const handleContinue = () => {
    setActiveStep(activeStep - 1)
    handlePreviewStep()
  }

    const {
      handleSize,
    disabledZoom,
    closeZoom,
    handleZoom,
    scale
  }= useContext(DrawerContext);
  const [loading, setLoading] = React.useState(true);
  const wrapperRef = React.useRef(null);
  const imagesLoaded = useOnLoadImages(wrapperRef);

  const detectSize = () => {
    const lectura = document.getElementById("lectura");
    const newWidth = lectura?.offsetWidth;
    const newHeight = lectura?.offsetHeight;
    setLoading(false);
    if (lectura) handleSize(newHeight, newWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", detectSize);
    return () => {
      handleZoom()
      window.removeEventListener("resize", detectSize);
      closeZoom()
    };
  }, []);

  const classes = useStyles({ disabledZoom,scale });
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
             <Grid container item justifyContent="center" className={classes.ContainerInfoLectura}>
                <Grid container className="BarraInfoLectura">
                    <Button className="Regresar" onClick={handleContinue}>
                        Regresar
                    </Button>
                 </Grid>
               <Grid container item className="InfoLectura">
                    <Grid container item direction="column" xs={6} justifyContent="center">
                        <p className="Dato">Grado</p>
                        <p className="Caracter">3</p>
                    </Grid>
                    <Grid container item direction="column" xs={6} justifyContent="center" className="Sesion">
                        <p className="Dato">Sesión</p>
                        <p className="Caracter">3</p>
                    </Grid>
               </Grid>
            </Grid>
        <Grid
            container
            item
            className={classes.ContenedorLectura}
            justifyContent="center"
            id="lectura"
            onLoad={detectSize}
            ref={wrapperRef}
        >
<Drawer id_lectura={id_actividad}  />
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                <h2 className='Pos Titulo'>¡Arma tu propia alcancía!</h2>
                <p className='Pos SubTitulo '>Siempre es un buen momento para empezar a ahorrar y guardar esas moneditas sueltas. Lee estas instrucciones para elaborar una alcancía con materiales reciclados.</p>
                <p className='Pos Lectura Lect'>
                    <h3 className='SubTitulo2'>Materiales:</h3>
                    <ul className='ul'>
                        <li className='li'><p className='Lectura'>1 botella de plástico o <span style={{fontVariant: "all-small-caps"}}>PET</span> de forma cilíndrica o ligeramente circular, con tapa</p> </li>
                        <li className='li'><p className='Lectura'>4 tapitas de plástico</p> </li>
                        <li className='li'><p className='Lectura'>1 hoja de periódico</p> </li>
                        <li className='li'><p className='Lectura'>¼ de cartón o cartulina</p> </li>
                        <li className='li'><p className='Lectura'>Pinturas de agua de los colores que quieras</p> </li>
                        <li className='li'><p className='Lectura'>Plumas, piedras pequeñas, semillas, botones y otros objetos decorativos</p> </li>
                        <li className='li'><p className='Lectura'>Pegamento</p> </li>
                        <li className='li'><p className='Lectura'>Tijeras</p> </li>
                        <li className='li'><p className='Lectura'>Un pincel</p> </li>
                    </ul>
                </p>
                <h3 className='Pos Titulo2'>Procedimiento</h3>
                <h3 className='Pos SubTitulo3'>Sigue estos pasos:</h3>
                <p className='Pos Lectura Lect1 Paso'>
                    Coloca el periódico sobre la superficie en la que vas a trabajar para protegerla.
                </p>
                <p className='Pos Lectura Lect2 Paso'>
                    Pon la botella de forma horizontal. Después, con ayuda de un adulto corta un 
                    rectángulo pequeño usando las tijeras para hacer la ranura por donde entrarán 
                    las monedas. Recuerda que no deben salirse, por lo que tiene que ser pequeño.                
                </p>
                <p className='Pos Lectura Lect3 Paso'>
                    Pinta la botella con la pintura de agua y el pincel. La tapa puede ser de otro 
                    tono, pues será la nariz.
                </p>
                <p className='Pos Lectura Lect4 Paso'>
                    Pinta también las cuatro tapas que formarán las patas.
                </p>
                <p className='Pos Lectura Lect5 Paso'>
                    Dibuja sobre el cartón o la cartulina las orejas del cerdito, recórtalas con cuidado
                    y píntalas del color que prefieras por ambos lados.
                </p>
                <p className='Pos Lectura Lect6 Paso'>
                    Deja secar todo.    
                </p>
                <p className='Pos Lectura Lect7 Paso'>
                    Cuando todo haya secado, coloca la botella en posición horizontal, con la ranura hacia
                    arriba. Luego, pega debajo de ella las tapitas que serán las patas.
                </p>
                <p className='Pos Lectura Lect8 Paso'>
                    Enrosca la tapa y fija sobre ella los botones para formar los orificios de la nariz. 
                    Une las orejas en la parte superior, cuida que queden levantadas. Puedes doblar 
                    ligeramente el cartón para lograrlo.
                </p>
                <p className='Pos Lectura Lect9 Paso'>
                    Pega los botones o piedritas entre la nariz y las orejas para hacer los ojos.
                </p>
                <p className='Pos Lectura Lect10 Paso'>
                    Decora con semillas y otros objetos para personalizar tu cerdito, puedes formar
                     manchas u otro detalle que te guste. Y ahora sí, ¡a ahorrar!
                </p>

               
                <img className='Imagen Escritorio' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img3ro/Sesion03/1.png" alt="Tarjeta" />
                <img className='Imagen Tablet' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img3ro/Sesion03/1R.png" alt="Tarjeta" />
              
                </Grid>
        </Grid>
        <Grid container item style={{ marginTop: 140}} ></Grid>
    </Grid>
    );
}
 
export default Arma_tu_propia_alcancía ;
