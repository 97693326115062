import React               from "react";
import {Grid, makeStyles}                 from '@material-ui/core';

const useStyles = makeStyles(theme =>({
  questionContainer:{
    width: "90%",
    height:"90%",
    // overflowY: "scroll",
    // scrollbarColor: "#F6DE95",
    // scrollbarWidth: "10px",
    // '&::-webkit-scrollbar': {
    //   width: '15px',
    // },
    // '&::-webkit-scrollbar-track': {
    //   borderRadius: '25px',
    //   backgroundColor: "#F6DE95",
    //   webkitBoxShadow: 'inset 0 0 3px rgba(0,0,0,0.00)'
    // },
    // '&::-webkit-scrollbar-thumb': {
    //   backgroundColor: '#8144DF',
    //   borderRadius: '25px',
    // },
  },
  reading: {
    width: "100%",
    margin:"10px 0 10px 0 ",
    font: '22px lato',
    letterSpacing: '0.21px',
    [theme.breakpoints.down(1400)]:{
      font: " 18px lato",
      width: "85%",
    },
  },
  imgStyle: {
    alignItems: "center",
    width:'90%',
    height:'90%',
    [theme.breakpoints.down(1400)]:{
      width:'85%',
      height:'85%',
    },
    [theme.breakpoints.down(1000)]:{
      width:'80%',
      height:'80%',
    },
  },
  table: {
    // border: '1px solid black',
    width: "100%",
    font: '22px lato',
    [theme.breakpoints.down(1400)]:{
      font: " 18px lato",
    },
  },
  tdTable: {
    padding: "0 1%",
    // border: '1px solid black',
  }
}));

const Question = (props) => {
  const classes = useStyles()
  const { picture } = props


  return(
    <Grid container className={classes.questionContainer}>
      <p 
        // dangerouslySetInnerHTML={{__html: `${step+1}. ${pregunta.replace( '\n', '<br/><br/>')}`}}
        className={classes.reading}
      >
        6. ¿La imagen personifica la teoría de grafos? ¿Por qué?
      </p>
      <Grid container item justifyContent="center" alignItems="center">
        <img alt="img" src={picture} className={classes.imgStyle}/>
      </Grid>
      {/* <p className={classes.reading} >
        La diversidad de frutas que existen en el mercado.
      </p> */}
     
    </Grid>
  )

}

export default Question;
