import { makeStyles, Grid, Radio } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
// import StudentsList from "./StudentsList";

import DropZone from "./DropZone";
import axios from "../../../../services/axios";
import ContainerAdminPearson from "../ContainerAdminPearson";

import ModalConfirm from "../../../Modals/ModalConfirm";
import Loader from "../../../Loader/Loader";
import ModalError from "../../../Modals/ModalError";
import ModalErrorDetails from "../../../Modals/ModalErrorDetails";
import ModalMessage from "../../../Modals/ModalMessage";
import TeachersAvatarUploader from "../../Docente/TeachersAvatarUploader";
import { useParams, useHistory } from "react-router-dom";
import SelectCity from "../CargarDatos/SelectCity";

import cdlLogo from "../../../imgs/CL_LOGO_FINAL_Login 1.png";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    padding: "48px 0 48px 0",
    width: "100vw",
    minHeight: "calc(100vh - 80px)",
    // marginTop: "80px",
    // minHeight:'calc(100vh - 80px)',
    background:
      "linear-gradient(217deg, rgba(255,194,0,0.4962185557816877) 2%, rgba(255,255,255,1) 25%, rgba(255,255,255,0.4962185557816877) 81%, rgba(144,81,177,1) 98%)",
    [theme.breakpoints.up("sm")]: {
      // marginTop: "0",
    },
  },
  boxContainer: {
    background: "#fff",
    width: "80%",
    height: "auto",
    borderRadius: "42px",
    padding: "20px",
    alignItems: "center",
    gap: "50px",
    boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.5)",
    [theme.breakpoints.up("md")]: {
      padding: "48px 20px 48px 20px",
    },
  },
  titleContainer: {
    margin: "30px 0",
  },
  title: {
    font: "28px Fredoka One ",
    margin: "0",
    textAlign: "center",
    marginBottom: "30px",
    color: " #DC5EA6",
    [theme.breakpoints.up("md")]: {
      fontSize: "36px",
    },
    [theme.breakpoints.up("xl")]: {
      fontSize: "42px",
    },
  },
  link: {
    cursor: "pointer",
    textDecoration: "none",
    // height: "31px",
    // width: "190px",
  },
  backButtonContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.up("sm")]: {
      justifyContent: "flex-start",
    },
  },
  backButton: {
    cursor: "pointer",
    height: "35px",
    // width: "200px",
    backgroundColor: "#FDEFF7",
    color: "#DC5EA6",
    border: "#DC5EA6 1px solid",
    borderRadius: "20px",
    textTransform: "none",
    font: "20px Lato",
    letterSpacing: "0.14px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
  },

  studentListContainer: {
    gap: "20px",
  },
  PurpleButton: {
    color: "#FFFFFF",
    backgroundColor: "#9051b1",
    borderRadius: "30px",
    alignSelf: "center",
    // margin: "0 auto",

    width: "100%",
    maxWidth: "238px",
    minHeight: "45px",
    border: "none",
    textTransform: "unset",
    cursor: "pointer",
    fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
    fontWeight: "500",
    padding: "6px 16px",
    lineHeight: "1.75",
    transition:
      "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    boxShadow:
      "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
    "&:hover": {
      backgroundColor: "#B965D3 ",
    },
  },
  formContainer: {
    // display: "flex",
    display: "grid",
    // flexDirection: "column"
    gridTemplateColumns: "1fr",
    width: "80%",
    boxSizing: "border-box",
    maxWidth: "560px",
    alignItems: "flex-start",
    justifyItems: "center",
    gap: "30px",
    [theme.breakpoints.up("lg")]: {
      width: "100%",
      maxWidth: "680px",
      gridTemplateColumns: "1fr 1fr",
      gridTemplateAreas: `"inputName inputName"
    "titleDataManager titleDataManager"
    "usernameManager usernameManager"
    "managerName managerLastName"
    "managerSecondLastName managerEmail"
    "selectCountry selectCity"
    "schoolAddress schoolAddress"
    "radioVip quantity"
    `,
    },
  },
  formItemContainer: {
    gap: "10px",
  },

  inputName: {
    gap: "10px",
    justifySelf: "flex-start",
    [theme.breakpoints.up("lg")]: {
      width: "60%",
      gridArea: "inputName",
    },
  },
  labelEncargado: {
    gap: "10px",
    justifySelf: "flex-start",
    [theme.breakpoints.up("lg")]: {
      width: "50%",
      gridArea: "titleDataManager",
    },
  },
  usernameEncargado: {
    gap: "10px",
    justifySelf: "flex-start",
    [theme.breakpoints.up("lg")]: {
      width: "50%",
      gridArea: "usernameManager",
    },
  },
  nombreEncargado: {
    gap: "10px",
    justifySelf: "flex-start",
    [theme.breakpoints.up("lg")]: {
      gridArea: "managerName",
    },
  },
  apellidoEncargado: {
    gap: "10px",
    justifySelf: "flex-start",
    [theme.breakpoints.up("lg")]: {
      gridArea: "managerLastName",
    },
  },
  segundoApellidoEncargado: {
    gap: "10px",
    justifySelf: "flex-start",
    [theme.breakpoints.up("lg")]: {
      gridArea: "managerSecondLastName",
    },
  },
  selectCountry: {
    gap: "10px",
    justifySelf: "flex-start",
    [theme.breakpoints.up("lg")]: {
      gridArea: "selectCountry",
    },
  },
  selectCity: {
    gap: "10px",
    justifySelf: "flex-start",
    [theme.breakpoints.up("lg")]: {
      gridArea: "selectCity",
    },
  },
  schoolAddress: {
    gap: "10px",
    justifySelf: "flex-start",
    [theme.breakpoints.up("lg")]: {
      gridArea: "schoolAddress",
    },
  },
  vip: {
    gap: "10px",
    justifySelf: "flex-start",
    [theme.breakpoints.up("lg")]: {
      gridArea: "radioVip",
    },
  },
  quantity: {
    gap: "10px",
    justifySelf: "flex-start",
    [theme.breakpoints.up("lg")]: {
      gridArea: "quantity",
    },
  },

  radioVip: {
    "& ::after": {
      width: "15px",
      height: "15px",
      borderRadius: "15px",
      top: "-2px",
      left: "-1px",
      position: "relative",
      backgroundColor: "#d1d3d1",
      content: "",
      display: "inline-block",
      visibility: "visible",
      border: "2px solid white",
    },
    "&:checked:after": {
      width: "15px",
      height: "15px",
      borderRadius: "15px",
      top: "-2px",
      left: "-1px",
      position: "relative",
      backgroundColor: "#00ff00",
      content: `" "`,
      display: "inline-block",
      visibility: "visible",
      border: "2px solid white",
    },
  },
  managerEmail: {
    gap: "10px",
    justifySelf: "flex-start",
    [theme.breakpoints.up("lg")]: {
      gridArea: "managerEmail",
    },
  },
  input: {
    width: "100%",
    height: "40px",
    paddingLeft: "27px",
    paddingRight: "27px",
    borderRadius: "61px",
    boxSizing: "border-box",
    // border: " solid #1D2AAD 3px ",
    border: "none",
    background: "#FFFFFF",
    font: "Helvetica",
    fontSize: "16px",
    //    width: "449px",
    // height: "72px",
    // margin: '43px 139px 19px 66px',
    // padding: '26px 24px 25px',
    backgroundColor: "#f0f0f0",
    "&:focus": {
      outline: "none",
    },
    [theme.breakpoints.up("lg")]: {
      // height: "60px",
      // maxWidth: "449px",
      fontSize: "18px",
    },
    "-moz-appearance": "Textfield",
    "&::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
    },
    "&::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
    },
  },
  radioContainer: {
    width: "auto",
    alignItems: "center",
  },
  radio: {
    color: "#222222",
    "&.Mui-checked": {
      color: "#5dc137",
    },
    "&:hover": {
      backgroundColor: "#22222210",
    },
    "&.Mui-checked:hover": {
      backgroundColor: "#5dc13720",
    },
  },
  label: {
    fontWeight: "bold",
    fontFamily: "Lato, sans-serif",
  },
  spacer: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
}));

const EditSchool = () => {
  const classes = useStyles();
  const history = useHistory();
  const { id_escuela } = useParams();

  const [open, setOpen] = React.useState(false);

  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [modalMessageOpen, setModalMessageOpen] = useState(false);
  const [modalErrorDetailsOpen, setModalErrorDetailsOpen] = useState(false);
  const [modalErrorOpen, setModalErrorOpen] = useState(true);
  const [confirmChangesResult, setConfirmChangesResult] = useState("");
  const [resultError, setResultError] = useState("");
  const [file, setFile] = useState(null);
  const [logo, setLogo] = useState("");

  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({});
  const [countrys, setCountrys] = useState([]);

  useEffect(() => {
    if (file) loadFile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file]);

  const loadDataSchool = () => {
    setLoading(true);
    return axios
      .get(`/listado/info/escuela?id_escuela=${id_escuela}`)
      .then((response) => {
        if (response.status === 200) {
          const data = response.data.resultado;
          setLogo(data?.logo);
          setFormData({
            ...formData,
            nombre_encargado: data?.nombre,
            id_usuario: data?.id_usuario,
            id_escuela: data?.id_escuela,
            correo_encargado: data?.correo,
            usuario_encargado: data?.nombre_usuario,
            nombre_escuela: data?.escuela,
            apellido_encargado: data?.apellido_paterno,
            segundo_apellido_encargado: data?.apellido_materno,
            ciudad: data?.ciudad,
            pais: data?.pais,
            direccion: data?.direccion,
            tipo: data?.tipo,
            limite_usuarios: data?.limite_usuarios,
          });
        }
      })
      .catch((error) => {
        history.push({
          pathname: "/error/servicios",
          state: {
            reason: "servicios",
          },
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const loadCountriesList = () => {
    setLoading(true);
    return axios
      .get(`/listado/paises/ciudad`)
      .then((response) => {
        if (response.status === 200) {
          const data = response?.data?.resultado;
          setCountrys(data);
        }
      })
      .catch((error) => {
        console.log(error, "error");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    loadDataSchool().then(() => {
      loadCountriesList();
    });

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCloseConfirmationModal = () => {
    setConfirmationModalOpen(!confirmationModalOpen);
  };
  const handleCloseMessageModal = () => {
    setModalMessageOpen(!modalMessageOpen);
    setResultError("");
  };
  const handleCloseErrorModal = () => {
    setModalErrorOpen(!modalErrorOpen);
  };
  const handleCloseErrorDetailsModal = () => {
    setModalErrorOpen(false);
    setModalErrorDetailsOpen(!modalErrorDetailsOpen);
  };

  const handleDocument = (documentBase64) => {
    setFile(documentBase64);
  };
  const loadFile = () => {
    setLoading(true);
    setLoading(false);
  };

  const confirmChanges = () => {
    setConfirmationModalOpen(false);
    if (
      formData?.nombre_escuela &&
      formData?.pais &&
      formData?.ciudad &&
      formData?.direccion &&
      formData?.nombre_encargado &&
      formData?.apellido_encargado &&
      formData?.correo_encargado
    ) {
      setLoading(true);
      const body = {
        escuela: formData?.nombre_escuela,
        pais: formData?.pais,
        ciudad: formData?.ciudad,
        direccion: formData?.direccion,
        nombre: formData?.nombre_encargado,
        id_usuario: formData?.id_usuario,
        id_escuela: formData?.id_escuela,
        apellido_paterno: formData?.apellido_encargado,
        apellido_materno: formData?.segundo_apellido_encargado,
        correo: formData?.correo_encargado,
        image: file?.base64 || "",
        tipo: formData?.tipo || "normal",
        limite_usuarios: formData?.limite_usuarios,
      };
      axios
        .put("/listado/update/escuela", body)
        .then((response) => {
          if (response.status === 200) {
            setLoading(false);
            setModalMessageOpen(true);
            setConfirmChangesResult(
              "Los datos se han actualizado correctamente"
            );
          }
        })
        .catch((error) => {
          setLoading(false);
          setModalMessageOpen(true);
          setConfirmChangesResult(
            "Ha ocurrido un error al actualizar los datos. Intente de nuevo más tarde."
          );
        });
    } else {
      setModalMessageOpen(true);
      setConfirmChangesResult("Asegúrese de llenar todos los datos");
    }
  };

  const handleForm = (e) => {
    if (e.target.name.toLowerCase() === "pais") {
      setFormData({
        ...formData,
        pais: e.target.value,
        ciudad: "",
      });
    } else {
      setFormData({
        ...formData,
        [e.target.name.toLowerCase()]: e.target.value,
      });
    }
  };

  return (
    <ContainerAdminPearson>
      <Grid
        container
        item
        sm={12}
        alignItems="center"
        justifyContent="center"
        className={classes.mainContainer}
      >
        <Grid
          container
          item
          direction="column"
          className={classes.boxContainer}
        >
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="center"
          >
            <Grid item className={classes.backButtonContainer}>
              <button
                className={classes.backButton}
                onClick={() => history.goBack()}
              >
                <ArrowBackIcon /> Escuelas
              </button>
            </Grid>
            <Grid
              container
              item
              alignItems="center"
              justifyContent="center"
              className={classes.titleContainer}
            >
              <h2 className={classes.title}>Editar escuela</h2>
            </Grid>
            <DropZone
              handleDocument={handleDocument}
              file={file}
              type={"image/*"}
              logo={logo || cdlLogo}
            />
          </Grid>
          <form
            className={classes.formContainer}
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <Grid container direction="column" className={classes.inputName}>
              <label htmlFor="input-name" className={classes.label}>
                Nombre de la escuela *
              </label>
              <input
                type="text"
                className={classes.input}
                id="input-name"
                spellcheck="false"
                name="nombre_escuela"
                value={formData.nombre_escuela}
                onChange={handleForm}
                required
              />
            </Grid>
            <Grid
              container
              direction="column"
              className={classes.labelEncargado}
            >
              <label className={classes.label}>Datos del encargado</label>
            </Grid>

            <Grid
              container
              direction="column"
              className={classes.usernameEncargado}
            >
              <label
                htmlFor="input-username-encargado"
                className={classes.label}
              >
                Usuario
              </label>
              <input
                type="text"
                className={classes.input}
                id="input-username-encargado"
                name="usuario_encargado"
                spellcheck="false"
                value={formData.usuario_encargado}
                onChange={handleForm}
                disabled
              />
            </Grid>

            <Grid
              container
              direction="column"
              className={classes.nombreEncargado}
            >
              <label htmlFor="input-nombre-encargado" className={classes.label}>
                Nombre(s) *
              </label>
              <input
                type="text"
                className={classes.input}
                id="input-nombre-encargado"
                name="nombre_encargado"
                spellcheck="false"
                value={formData.nombre_encargado}
                onChange={handleForm}
                required
              />
            </Grid>
            <Grid
              container
              direction="column"
              className={classes.apellidoEncargado}
            >
              <label
                htmlFor="input-primer-apellido-encargado"
                className={classes.label}
              >
                Primer apellido *
              </label>
              <input
                type="text"
                className={classes.input}
                id="input-primer-apellido-encargado"
                spellcheck="false"
                name="apellido_encargado"
                value={formData.apellido_encargado}
                onChange={handleForm}
                required
              />
            </Grid>
            <Grid
              container
              direction="column"
              className={classes.segundoApellidoEncargado}
            >
              <label
                htmlFor="input-segundo-apellido-encargado"
                className={classes.label}
              >
                Segundo apellido
              </label>
              <input
                type="text"
                className={classes.input}
                id="input-segundo-apellido-encargado"
                name="segundo_apellido_encargado"
                spellcheck="false"
                value={formData.segundo_apellido_encargado}
                onChange={handleForm}
                required
              />
            </Grid>

            <Grid container direction="column" className={classes.managerEmail}>
              <label htmlFor="input-email" className={classes.label}>
                Correo electrónico *
              </label>
              <input
                type="email"
                className={classes.input}
                id="input-email"
                name="correo_encargado"
                spellcheck="false"
                value={formData.correo_encargado}
                onChange={handleForm}
                required
              />
            </Grid>

            <Grid
              container
              direction="column"
              className={classes.selectCountry}
            >
              {formData?.pais && (
                <SelectCity
                  list={countrys}
                  fieldName={"pais"}
                  label={"País *"}
                  handleForm={handleForm}
                  defaultValue={formData?.pais}
                  value={formData?.pais}
                  formData={formData}
                />
              )}
            </Grid>

            <Grid container direction="column" className={classes.selectCity}>
              <SelectCity
                list={
                  countrys.find((item) => item.pais === formData.pais)?.ciudades
                }
                fieldName={"ciudad"}
                label={"Ciudad *"}
                handleForm={handleForm}
                defaultValue={formData?.ciudad}
                value={formData?.ciudad}
                formData={formData}
                disabled={!formData?.pais}
              />
            </Grid>

            <Grid
              container
              direction="column"
              className={classes.schoolAddress}
            >
              <label htmlFor="input-address" className={classes.label}>
                Dirección
              </label>
              <input
                type="text"
                className={classes.input}
                id="input-address"
                name="direccion"
                spellcheck="false"
                placeholder="Dirección"
                value={formData.direccion}
                onChange={handleForm}
                required
              />
            </Grid>
            <Grid container direction="column" className={classes.vip}>
              <label htmlFor="input-address" className={classes.label}>
                Escuela VIP
              </label>
              <Grid container alignItems="center" direction="row">
                <Grid container className={classes.radioContainer}>
                  <label htmlFor="option-vip-si" className={classes.label}>
                    Si
                  </label>
                  <Radio
                    name="tipo"
                    checked={formData?.tipo === "vip"}
                    value="vip"
                    onChange={handleForm}
                    className={classes.radio}
                  />
                </Grid>
                <Grid container className={classes.radioContainer}>
                  <label htmlFor="option-vip-no" className={classes.label}>
                    No
                  </label>

                  <Radio
                    name="tipo"
                    checked={formData?.tipo !== "vip"}
                    value="normal"
                    onChange={handleForm}
                    className={classes.radio}
                  />
                </Grid>
              </Grid>
            </Grid>

            {formData?.tipo === "vip" && (
              <Grid container direction="column" className={classes.quantity}>
                <label htmlFor="input-quantity" className={classes.label}>
                  Cantidad de usuarios
                </label>
                <input
                  type="number"
                  className={classes.input}
                  id="input-quantity"
                  name="limite_usuarios"
                  placeholder="Cantidad"
                  min={1}
                  value={formData.limite_usuarios}
                  onChange={handleForm}
                  required
                  onWheel={(e) => {
                    e.target.blur();
                  }}
                />
              </Grid>
            )}
          </form>
          <button
            className={classes.PurpleButton}
            onClick={handleCloseConfirmationModal}
          >
            Guardar cambios
          </button>
        </Grid>
      </Grid>
      <ModalConfirm
        open={confirmationModalOpen}
        handleClose={handleCloseConfirmationModal}
        handleConfirm={confirmChanges}
      />
      {loading && <Loader />}

      <ModalError
        open={modalErrorOpen}
        handleClose={handleCloseErrorModal}
        type={resultError}
        handleNext={handleCloseErrorDetailsModal}
      />
      <ModalErrorDetails
        open={modalErrorDetailsOpen}
        handleClose={handleCloseErrorDetailsModal}
      />
      <ModalMessage
        open={modalMessageOpen}
        handleClose={handleCloseMessageModal}
        title={confirmChangesResult}
        textOfButton="Aceptar"
      />
      <TeachersAvatarUploader open={open} setOpen={setOpen} />
    </ContainerAdminPearson>
  );
};

export default EditSchool;
