import React                from "react";
import { makeStyles,Grid }  from '@material-ui/core';

import maestra                       from './colorlessImages/Insignia_MaestraA.png';
import shinyMaster                   from './badgesImages/Insignia_maestra.png';

const useStyles = makeStyles(theme =>({
  mainContainer: {
    width:'100vw',
    padding: "62px 0 62px 0 ",
    backgroundColor:'linear-gradient(153deg, #FFFFFF80 0%, #FFFFFF9A 57%, #F7F0F0FE 87%, #FFFFFF 100%)',
    [theme.breakpoints.down(1440)]:{
      padding:'52px 0 42px 0 ',
    },
    [theme.breakpoints.down(700)]:{
      padding:'17px 0 17px 0 ',
    },    
  },
  mainContainer2:{
    borderRadius: "20px",
    border: "#1F9EC4 3px solid",
    boxShadow: '0px 2px 5px #0000006C',
    width:"80%",
    height: "50%",
  },
  mainContainer3:{
    maxWidth:"690px",
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    boxShadow: '0px 6px 5px #0000002F',
    border: '3px solid #FCCD0A',
    borderRadius: '20px',
    // height: "600px"

  },
  // Bola:{
  //   backgroundColor: '#EB7CBC',
  //   border: "#707070 1px solid",
  //   height:'201px',
  //   width: "198px"
  // },
  bola:{
    backgroundColor: '#69C8D8',
    border: "#707070 1px solid",
    borderRadius: "180px",
    height:'233px',
    width: "233px",
  },
  badgesTitle:{
    font:'38px Fredoka One',
    letterSpacing: '0.28px',
    color: '#1F9EC4',
    margin:'0px',
  },
  badgesName:{
    font:'24px Fredoka One',
    letterSpacing: '0.18px',
    color: '#55A7A9',
    margin:'0px',
  },
  badgesContainer:{
    width:"559px",
    height: "448px",
    margin:" 30px 0 30px 0",
    [theme.breakpoints.down(700)]:{
      width:"259px",
      height: "248px",
    },
  },
  masterText:{
    font:'36px Fredoka One',
    letterSpacing: '0.26px',
    color: '#CEAF29',
    marginTop:'20px',
    [theme.breakpoints.down(700)]:{
      font:'30px Fredoka One',
      textAlign: "center",
    },

  },
  subText:{
    font:'bold 30px lato',
    letterSpacing: '0.22px',
    color: '#777373',
    margin:'0px',
  },
  piayerText:{
    font:'36px Fredoka One',
    letterSpacing: '0.26px',
    color: '#777373',
  },
  playerCard: {
    border: "#707070 1px solid",
    height:'1033px',
    width: "666px"
  },
  // masterContainer:{
  //   border: "#FFED1A 3px solid",
  //   borderRadius: "16px",
  //   width:"900px",
  //   height: "600px"
  // }

}));


const BadgesMaster = (props) => {
  const classes = useStyles()
  const { showMaster } = props

  return(
    <Grid 
    container
    direction="column"
    item 
    sm={12}
    className={classes.mainContainer}
    justifyContent="center" 
    alignItems="center"
  >
      <Grid container item direction="column" className={classes.mainContainer3} justifyContent="center" alignItems="center">
      {/* <Grid container item xs={6} direction="column" justifyContent="center" alignItems="center"> */}
        <h2 className={classes.masterText}>Insignia Súper lector</h2>
        <p className={classes.subText}>Sabio</p>
        <Grid container item justifyContent="center" alignItems="center" >
          <img className={classes.badgesContainer} src={showMaster ? shinyMaster : maestra} alt="Insignia Maestra" />
        </Grid>
      </Grid>
  </Grid>
  )
}

export default BadgesMaster;