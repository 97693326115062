import React               from "react";
import {Grid, makeStyles}                 from '@material-ui/core';

const useStyles = makeStyles(theme =>({
  questionContainer:{
    width: "100%",
    height:"100%",
  },
  reading: {
    width: "80%",
    margin:"10px 0 10px 0 ",
    font: '22px lato',
    letterSpacing: '0.21px',
    [theme.breakpoints.down(1400)]:{
      font: " 18px lato",
      width: "95%",
    },
    [theme.breakpoints.down(1310)]:{
      font: "500 16px lato",

    },
    [theme.breakpoints.down(925)]:{
      font: "500 14px lato",

    },
    [theme.breakpoints.down(426)]:{
      font: "500 12px lato",
 
    }, 
  },
  table: {
    width: "100%",
    font: '22px lato',
    border: '1px solid black',
    [theme.breakpoints.down(1400)]:{
      font: " 18px lato",
      width: "95%",
    },
    [theme.breakpoints.down(1310)]:{
      font: "500 16px lato",
 
    },
    [theme.breakpoints.down(925)]:{
      font: "500 14px lato",

    },
    [theme.breakpoints.down(426)]:{
      font: "500 12px lato",

    }, 
  },
  tdTable: {
    padding: "0 1%",
    border: '1px solid black',
    textAlign: 'center'
  },
  imgStyle: {
    alignItems: "center",
    width:'50%',
    // height:'50%',
    // [theme.breakpoints.down(1400)]:{
    //   width:'85%px',
    //   height:'85%px',
    // },
  },
}));

const QuestionTable = (props) => {
  const classes = useStyles()
  const {picture5,picture6,picture7,picture8,picture9,picture10} = props

  return(
    <Grid container className={classes.questionContainer}>
      <p
        className={classes.reading}
      >
       6.Elige  las  imágenes  que ejemplifican la  siguiente afirmación: “esta  
       espiral aparece naturalmente.”
      </p>
      <br/>
      <table className={classes.table}>
        <thead>
          {/* <th style={{ border: '1px solid black',}}><i>Idea secundaria</i></th> */}
          {/* <th style={{width: "50%", border: '1px solid black',}}><i>Uso</i></th> */}
        </thead>
        <tbody>
          <tr>
            <td className={classes.tdTable}>
              <p>
                A 
              </p>
            </td>
            <td className={classes.tdTable}>
              <p>
                B 
              </p>
            </td>
            <td className={classes.tdTable}>
              <p>
                C 
              </p>
            </td>
          </tr>
          <tr>
            <td className={classes.tdTable}>
              <img src={picture5} alt="" className={classes.imgStyle}/>
            </td>
            <td className={classes.tdTable}>
              <img src={picture6} alt="" className={classes.imgStyle}/>
            </td>
            <td className={classes.tdTable}>
              <img src={picture7} alt="" className={classes.imgStyle}/>
            </td>
          </tr>
          <tr>
            <td className={classes.tdTable}>
              <p>
                D 
              </p>
            </td>
            <td className={classes.tdTable}>
              <p>
                E 
              </p>
            </td>
            <td className={classes.tdTable}>
              <p>
                F 
              </p>
            </td>
          </tr>
          <tr>
            <td className={classes.tdTable}>
              <img src={picture8} alt="" className={classes.imgStyle}/>
            </td>
            <td className={classes.tdTable}>
              <img src={picture9} alt="" className={classes.imgStyle}/>
            </td>
            <td className={classes.tdTable}>
              <img src={picture10} alt="" className={classes.imgStyle}/>
            </td>
          </tr>
        </tbody>
      </table>
    </Grid>
  )
}

export default QuestionTable;
