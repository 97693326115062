import { Button, Grid, makeStyles } from "@material-ui/core";
import React, { useContext, useEffect } from "react";
import DrawerContext from "../../../../../usability/DrawerContext";
import { useOnLoadImages } from "../../../../hooks/useOnLoadImages";
import Loader from "../../../../Loader/Loader";
import Drawer from "../../ReadingTools/Drawer";

const useStyles =  makeStyles (theme =>({
      Contenedor:{
        background:'#E9FBF9',
        width:'100vw',
        overflowX:'clip',
        [theme.breakpoints.down(1161)]:{
            background:'none',
           overflowX:'unset',
        },
        [theme.breakpoints.down(600)]:{
            marginTop: '79.51px',
        }
    },
  ContainerInfoLectura: {
    position: "relative",
    height: "86px",
    [theme.breakpoints.down(502)]:{
        height: "43px",
    },
    background: "#76D4C3",
    "& .BarraInfoLectura": {
      height: "86px",
      background: `Url(https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Barras/Azul.png)`,
        [theme.breakpoints.down(502)]:{
            height: "43px",
        },
    },
    "& .InfoLectura": {
      position: "absolute",
      height: "86px",
      width: "219px",
      boxSizing: "border-box",
      padding: "17.2px 0",
      background: "#FAFAFA",
      borderRadius: "50px",
      boxShadow: "2px 2px 2px 2px #00000029",
      zIndex: "4",
        [theme.breakpoints.down(502)]:{
            height: "43px",
            width: "109px",
            padding: "8.6px 0",
            borderRadius: "25px",
        },
    },
    "& .InfoLectura:before": {
      position: "absolute",
      display: "flex",
      boxSizing: "border-box",
      padding: "0 0 0 10px",
      justifyContent: "center",
      alignItems: "center",
      height: "39.88px",
      width: "39.88px",
      borderRadius: "50%",
      content: '""',
      boxShadow: "3px 0px .5px 0px #00000029",
      background: "#FAFAFA",
      left: "90px",
      top: "65px",
      transform: "rotate(0.25turn)",
      [theme.breakpoints.down(502)]:{
        left: "45px",
        top: "30px",
        height: "20.88px",
        width: "20.88px",
        },
    },
    "& .InfoLectura:after": {
      content: '""',
      height: "15px",
      width: "15px",
      borderTop: "2px solid #20A698",
      borderLeft: "2px solid #20A698",
      position: "absolute",
      bottom: "-10px",
      left: "Calc(50% - 8px)",
      transform: "rotate(0.625turn)",
      [theme.breakpoints.down(502)]:{
        height: "10px",
        width: "10px",
        left: "Calc(50% - 4.5px)",
        bottom: "-3px",
        borderTop: "1px solid #20A698",
        borderLeft: "1px solid #20A698",
        },
    },
    "& .Sesion": {
      borderLeft: "2px solid #D8D8D8",
    },
    "& .Dato": {
      margin: "0 0 5px 0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#20A698",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        margin: "0 0 2.5px 0",
        },
    },
    "& .Caracter": {
      margin: "0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#000000",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        },
    },
       '& .Regresar':{
        color: '#F2624F',
        border: '#F2624F 1px solid',
        height: '30px',
        width:'100px',
        borderRadius: '20px',
        letterSpacing: '0.14px',
        textTransform: 'none',
        background:'#FEFAF1',
        top:'calc(50% - 15px)',
        left:'5%',
        zIndex:4,
        [theme.breakpoints.down(502)]: {
          font:'bold 10px lato',
          width: "60px",
        },
      },
  }, 
    ContenedorLectura:{
        background:'#fff',
        width:'1160px',
        height:'100%',
        padding:'60px 168px 35px',
        
        transform: (props) =>
        props.scale > 1 ? `scale(${props.scale}) translate(0px, 10%)` : `none`,
        [theme.breakpoints.down(1161)]:{
            width:'502px',
            padding:'70px 0 0',
        },
        [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
        '& .ContainerDerechos':{
            bottom:'80px',
            [theme.breakpoints.down(1161)]:{
                display:'none',
            },
        },
        '& .Derechos':{
            textAlign:"center",
            margin:"0px",
            font:" 15px lato",
            color:"#717476",
        } ,
        '& .Escritorio':{
            display:'block',
            [theme.breakpoints.down(1161)]:{
                display:'none'
            },
        },
        '& .Tablet':{
            display:'none',
            [theme.breakpoints.down(1161)]:{
                display:'block'
            },
        },
    },
    ContainerTitulo:{
        margin:'0 0 46px 0',
        '& .Titulo':{
            font:'48px fredoka one',
            letterSpacing: '0px',
            color: '#F6644B',
            margin:'0',
            textAlign:'center',
            [theme.breakpoints.down(1161)]:{
                font:'43px fredoka one',
                lineHeight:'52px',
                textAlign:'center',
            },
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            lineHeight : '30px',
            margin:'0 0 36px 0',
            font:'22px lato',
            color:'#000',
            letterSpacing: '0.18px',
            [theme.breakpoints.down(1161)]:{
                lineHeight :'28px',
                font:'18px lato',
                letterSpacing: '0.17px',
            },
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .SubTitulo':{
            font:'bold 30px lato',
            color:'#F05B75',
            letterSpacing:'.28px',
            margin:'0',
            [theme.breakpoints.down(1161)]:{
                font:'bold 26px lato',
                letterSpacing:'.24px',
            },
            [theme.breakpoints.down(502)]:{
                font:'bold 18px lato',
                lineHeight :'24px',
                letterSpacing: '0.21px',
            },
        },
        '& .Imagen':{
            [theme.breakpoints.down(1161)]:{
                width:'100%',
            },
        },
    },
}));

const Día_de_Muertos = (props) => {

  const {activeStep, setActiveStep, handlePreviewStep, id_actividad} = props

  const handleContinue = () => {
    setActiveStep(activeStep - 1)
    handlePreviewStep()
  }

    const {
    handleSize,
    disabledZoom,
    closeZoom,
    handleZoom,
    scale
  }= useContext(DrawerContext);
  const [loading, setLoading] = React.useState(true);
  const wrapperRef = React.useRef(null);
  const imagesLoaded = useOnLoadImages(wrapperRef);

  const detectSize = () => {
    const lectura = document.getElementById("lectura");
    const newWidth = lectura?.offsetWidth;
    const newHeight = lectura?.offsetHeight;
    setLoading(false);
    if (lectura) handleSize(newHeight, newWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", detectSize);
    return () => {
      handleZoom()
      window.removeEventListener("resize", detectSize);
      closeZoom()
    };
  }, []);

  const classes = useStyles({ disabledZoom,scale });
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
             <Grid container item justifyContent="center" className={classes.ContainerInfoLectura}>
               <Grid container className="BarraInfoLectura">
                    <Button className="Regresar" onClick={handleContinue}>
                        Regresar
                    </Button>
                </Grid>
               <Grid container item className="InfoLectura">
                    <Grid container item direction="column" xs={6} justifyContent="center">
                        <p className="Dato">Grado</p>
                        <p className="Caracter">6</p>
                    </Grid>
                    <Grid container item direction="column" xs={6} justifyContent="center" className="Sesion">
                        <p className="Dato">Sesión</p>
                        <p className="Caracter">13</p>
                    </Grid>
               </Grid>
            </Grid>
{!imagesLoaded && <Loader />}
        <Grid
            container
            item
            className={classes.ContenedorLectura}
            justifyContent="center"
            id="lectura"
            onLoad={detectSize}
            ref={wrapperRef}
        >
<Drawer id_lectura={id_actividad} />
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >
                        Día de Muertos
                    </h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                      <p className="Lectura ">
                        Nadie me ve. Ni siquiera se han dado cuenta de que estoy parado bajo la lluvia. Antes, 
                        mi mamá me hubiera regañado por mojarme. Antes se hubiera preocupado de que 
                        me enfermara. Ahora pareciera que no le importa. <br/><br/>
                        
                        Desde que nació, mis tíos, primos, vecinos, papá y mamá la miran sólo a ella. No sé 
                        qué tiene de especial esa bebé. Vinieron a conocerla y la contemplan como si fuera
                        mágica. Yo no sé qué tiene de importante. Dicen que es preciosa y que cada 
                        movimiento de ella les sorprende. Incluso si mueve la boca sin sentido, como si 
                        sonreír fuera un milagro. Yo trato de hacer una sonrisa como la de ella, pero no veo 
                        nada. También copio los movimientos de la bebé, pero nadie me ve, ni siquiera yo 
                        puedo verme.<br/><br/> 
                        
                        La abrazan, la miman, le dan besos. Ya no recuerdo qué se siente que me tomen entre 
                        los brazos, que me acaricien. Yo quisiera abrazar a mi hermana y decirle que la 
                        quiero, pero ni siquiera puedo abrir una puerta.
                      </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <Grid container >
                            <img className='Imagen' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion13/1.png" alt="Tarjeta" />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container sm={12}>
                      <p className="Lectura "> 
                        Escuché que les gusta su olor de bebé. Respiré sobre mi brazo para saber a qué 
                        huelo, pero ningún aroma me llegó. Incluso, cuando mis papás me preparan mi 
                        comida favorita, no alcanzo a olerla, aunque pegue mi nariz a los platillos. Lo peor es 
                        que, aunque sé que estorbo, a mis papás parece no importarles, porque prefieren ir a 
                        ver a mi hermana para asegurarse de que no le pase nada. <br/><br/>
                        
                        —¡Mírenme! ¡Aquí estoy! ¡No me pasó nada! —les grito a veces. <br/><br/>
                        
                        Pero ellos ni voltean a verme, como cuando no me hacían caso. Antes, papá me 
                        explicaba que no me harían caso hasta que me tranquilizara. Ahora es porque no me 
                        ven y tampoco me escuchan. <br/><br/>
                        
                        Los miro desde afuera. Nadie se ha dado cuenta de que estoy aquí, detrás de la 
                        ventana que golpeo con fuerza, pero no me escuchan entre sus risas. Como está 
                        lloviendo, han de pensar que es granizo o las gotas que se estrellan en la ventana.
                        Desde aquí veo a mi hermanita que gatea y balbucea lo que pronto se convertirá en 
                        palabras. Mis papás se ríen y le enseñan una foto en la que estoy yo. <br/><br/>
                        
                        —Mira, es tu hermano, él es Gerardo —dice mamá, señalando mi rostro. Mi hermanita 
                        sonríe. <br/><br/>
                        
                        He hecho de todo para volver a estar con papá y mamá, para jugar con mi hermanita.
                        Ellos parecen no notarme, pero la bebé a veces me ve y trata de tocarme. Yo quisiera 
                        tomar su mano y hacer que se ría con mi voz. <br/><br/>
                        
                        Ya no aguanto más no poder jugar con ella. Entonces, decido irme para siempre, así 
                        que me alejo hasta la carretera que pasa enfrente de la casa. Me iré por ese camino 
                        hasta no sé dónde y viviré lejos, para saber si se acuerdan de mí y me extrañan.
                      </p>

                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <Grid container justifyContent="center" alignItems='flex-end'>
                            <img className='Imagen' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion13/2.png" alt="Tarjeta" />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container sm={12}>
                      <p className="Lectura "> 
                        Estoy mojado, tirito, tengo miedo. En realidad, no quiero irme, no quiero estar sin 
                        ellos. No sé qué voy a hacer yo solo, no me sé cuidar. Si me da hambre, no tendré qué 
                        comer. Tan sólo quisiera que mamá me abrazara de nuevo y que papá jugara conmigo, 
                        que la bebé pudiera decir mi nombre cuando empiece a hablar.<br/><br/>
                        
                        De pronto, veo carros que pasan rápido y zumban. Me asusto y recuerdo… Un coche 
                        pasó muy cerca y oí un rechinido, luego sentí un golpe. <br/><br/>
                        
                        —¡Gerardo! <br/><br/>
                        
                        Me acuerdo de que mamá gritó y llegó corriendo hasta mí y me abrazó. Yo escuché su 
                        corazón latir muy fuerte.<br/><br/>
                        
                        —¡Ahí está! —dijeron a papá, señalándome. <br/><br/>
                        
                        Ésa fue la primera vez que le dio miedo verme. Ahora lo recuerdo. La gente me 
                        miraba asustada. Después se escucharon ambulancias.<br/><br/>
                        
                        —¡No te vayas! Te quiero mucho, hijo —dijo mamá. <br/><br/>
                        
                        Ahora lo sé y lo recuerdo. ¡Mamá sí me quiere! Ella no quería que me fuera, por eso 
                        no me fui. Yo tenía mucho sueño. Traté de estar despierto, como cuando íbamos en el 
                        auto y me decían que no me durmiera, que ya casi íbamos a llegar. <br/><br/>
                        
                        Papá me abrazaba, no quería decirme adiós. Yo tampoco. Me gustaba cuando me 
                        cargaba porque me quedaba dormido. Tengo mucho sueño. <br/><br/>
                        
                        Me siento cansado. He llorado mucho. Tal vez sea momento de dormirme y regresar 
                        cuando papá y mamá vuelvan a preparar mi comida favorita y a poner en la mesa mis 
                        chocolates favoritos junto a mi foto. Esa noche, seguramente, lograré que me vean y 
                        podré jugar por fin con mi hermanita. Volveré a ser parte de la familia.
                      </p>

                    </Grid>
                </Grid>
            </Grid>
            <Grid container item style={{ marginTop: 80 }}></Grid>
        </Grid>
    );
}
 
export default Día_de_Muertos;
