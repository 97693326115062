import { useContext, useState } from "react";
import { Rnd } from "react-rnd";

import DeleteIcon from "@mui/icons-material/Delete";

import DrawerContext from "../../../../usability/DrawerContext";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme, props) => ({
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    flexWrap: "wrap",
    backgroundColor: "#FEFAF1",
    outline: "none",
    overflow: "visible",
    zIndex: "99",
    border: "2px solid #20A698",
    borderRadius: "5px",
  },
  headerContainer: {
    position: "relative",
  },
  header: {
    display: "grid",
    gridTemplateColumns: "1fr",
    gridTemplateRows: "30px",
    borderTopLeftRadius: "5px",
    borderTopRightRadius: "5px",
    justifyItems: "end",
    width: "100%",
    height: "20%",
    cursor: "move",
    backgroundColor: "#ddf6fa",
  },
  button: {
    zIndex: "999",
    border: "none",
    position: "absolute",
    top: "-30px",
    backgroundColor: "#ddf6fa",
    borderTopLeftRadius: "5px",
    borderTopRightRadius: "5px",
    borderColor: "#20A698",
    borderBottom: "transparent",
    cursor: "pointer",
  },
  containerTextArea: {
    position: "relative",
  },
  textArea: {
    backgroundColor: "#FEFAF1",
    resize: "none",
    width: "100%",
    border: "none",
    padding: "10px",
    boxSizing: "border-box",
    outline: "none",
    color: "#000",
    height: "100%",
    overflow: "hidden",
    fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
    fontWeight: "400",
    fontSize: (props) =>
      props.sizeWidth > 200 && props.sizeHeight > 200 ? "24px" : "20px",
    lineHeight: "1.1876em",
  },
}));

const Note = ({ data }) => {
  const { id, size, position } = data;
  const {
    removeOneFromList,
    handleMoveOneNote,
    handleTextOneNote,
    handleResizeNote,
  } = useContext(DrawerContext);

  const sizeWidth = parseInt(size?.width, 10);
  const sizeHeight = parseInt(size?.height, 10);
  const [disabledTextArea, setDisabledTextArea] = useState(false);

  const classes = useStyles({ sizeWidth, sizeHeight });
  return (
    <Rnd
      className={classes.container}
      dragHandleClassName={"handleDrag"}
      position={position}
      style={{
        display: "grid",
        gridTemplateRows: "min-content 1fr",
        gridTemplateColumns: "1fr",
      }}
      onDragStart={() => {
        setDisabledTextArea(true);
      }}
      onResizeStart={() => {
        setDisabledTextArea(true);
      }}
      size={size}
      onDragStop={(e, d) => {
        e.stopPropagation();
        e.cancelBubble = true;
        handleMoveOneNote(id, { x: d.x, y: d.y });
        setDisabledTextArea(false);
      }}
      onResizeStop={(e, direction, ref, delta, position) => {
        let newSize = {
          width: ref.style.width,
          height: ref.style.height,
          ...position,
        };
        handleResizeNote(id, newSize);

        e.stopPropagation();
        setDisabledTextArea(false);
      }}
      minHeight={150}
      minWidth={150}
      maxWidth={300}
      maxHeight={300}
    >
      <div className={classes.headerContainer}>
        <button
          className={classes.button}
          onClick={() => {
            removeOneFromList(id);
          }}
        >
          <DeleteIcon
            sx={{ color: "#20A698" }}
            onClick={() => {
              removeOneFromList(id);
            }}
          />
        </button>
        <div className="handleDrag">
          <div className={classes.header}></div>
        </div>
      </div>

      <textarea
        multiline
        rows={8}
        placeholder="Anotaciones"
        style={{ zIndex: "9999" }}
        className={classes.textArea}
        onClick={(e) => {
          e.stopPropagation();
        }}
        onMouseUp={(e) => {
          e.stopPropagation();
        }}
        onChange={(e) => {
          const text = e.target.value;
          handleTextOneNote(id, text);
          e.stopPropagation();
        }}
        value={data.text}
        maxLength={200}
        disabled={disabledTextArea}
      />
    </Rnd>
  );
};

export default Note;
