import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
    ContenedorLectura:{
        width:'502px',
                  [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
    },
    ContainerTitulo:{
        margin:'0 0 34px 0',
        '& .Titulo':{
            letterSpacing: '0px',
            color: '#F6644B',
            margin:'0',
            textAlign:'center',
            font:'43px fredoka one',
            lineHeight:'52px',
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            margin:'0 0 36px 0',
            color:'#000',
            font:'18px lato',
            lineHeight :'28px',
            letterSpacing: '0.17px',
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .Imagen':{
            width:'100%',
        }, 

    }
}));

const El_método_científico = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >
                        El método científico                   
                    </h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                      <p className="Lectura ">
                        Pedro estaba en su habitación observando la ventana tranquilamente. Era un día 
                        nublado y parecía que estaba a punto de llover. Pedro miraba con curiosidad las
                        nubes que se agrupaban en el cielo gris. De pronto, tuvo una duda y decidió acudir 
                        con su padre, quien siempre tenía una respuesta interesante para todo porque era un 
                        científico.<br/><br/> 
                        
                        —Papá, ¿el agua de la lluvia se puede beber? <br/><br/>
                        
                        Su padre solía dar respuestas claras y breves, pero esta vez se quedó mirando al cielo 
                        en silencio. Después de unos momentos, respondió: <br/><br/>
                        
                        —¿Por qué no me dices qué piensas tú? <br/><br/>
                        
                        Pedro se sorprendió de que su papá no tuviera una respuesta, pero aceptó su 
                        propuesta con entusiasmo. Se asomó por la ventana una vez una y exclamó:<br/><br/>
                        
                        —Las nubes están muy grises. Yo imagino que el agua que cae va a tener el mismo 
                        color y no se puede tomar. <br/><br/>
                        
                        —Muy bien, Pedro. Es verdad que las nubes tienen ese color. Pero no determina que 
                        pueda beberse el agua o no. ¿Estás seguro de que ése es el motivo? <br/><br/>
                        
                        —No, pero creo que así es. El agua contaminada siempre hace daño. <br/><br/>
                        
                        —¿Cómo podrías estar seguro?<br/><br/>
                        
                        Pedro se quedó pensativo un rato. En realidad, nunca se había preguntado las 
                        razones. Después respondió: <br/><br/>
                        
                        —¿Y si juntamos un poco dentro de un recipiente para ver? <br/><br/>
                        
                        —¡Sí! Muy bien, podemos hacer eso. <br/><br/>
                        
                        Los dos se dirigieron a la cocina y seleccionaron un vaso para dejarlo bajo la lluvia 
                        durante unos minutos. Pedro comprobó que estaba sucia una vez que se llenó. <br/><br/>
                        
                        —¡Te lo dije! No se puede ingerir. <br/><br/>
                        
                        —¿Cómo tienes la certeza de ello? <br/><br/>
                        
                        —Porque se ve gris.<br/><br/>
                        
                        —Sí, ¿pero estás completamente seguro de que ésa es la causa? ¿Qué tal si sólo es 
                        tierra, pero no tiene bacterias ni nada que te pueda enfermar? <br/><br/>
                        
                        —¿Entonces sí se podría beber? <br/><br/>
                        
                        —No lo sé. ¿Se te ocurre alguna manera de comprobarlo? <br/><br/>
                        
                        Recordó que su papá le había regalado un microscopio en su cumpleaños. <br/><br/>
                        
                        —¿Y si la vemos en el microscopio? <br/><br/>
                        
                        Entonces, fueron juntos adonde estaba el aparato y pusieron una gota debajo de la 
                        lente. Después se acercaron y vieron algunas bacterias.
                      </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <Grid container justifyContent="center" >
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img4to/Sesion44/1.png" alt="Tarjeta" />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container xs={12} >
                        <p className="Lectura ">
                            —Papá, se ve muy sucia. <br/><br/>
                            
                            —¿Entonces qué concluyes? ¿Piensas que el agua es bebible? <br/><br/>
                            
                            —No, yo creo que nos podemos enfermar si lo hacemos porque ahora ya hemos visto 
                            que tiene bacterias. Pero tú ya lo sabías, ¿verdad? ¿Por qué no me lo dijiste? <br/><br/>
                            
                            —No te respondí porque quería que aprendieras a usar el método científico. <br/><br/>
                            
                            —Pero yo no soy científico. <br/><br/>
                            
                            —¡Claro que sí! El método científico consiste en una serie de pasos. Primero, se hace 
                            una observación y después se plantea una pregunta. Tú estabas mirando las nubes 
                            cargadas de lluvia cuando te surgió la duda, ¿cierto? Después, te pregunté qué creías 
                            tú y me explicaste tu hipótesis, que es una posible respuesta a la pregunta. No 
                            estabas totalmente seguro, así que planteaste un experimento. Tal vez no te diste 
                            cuenta, pero al atrapar agua y ponerla bajo el microscopio estabas llevando a cabo 
                            una experimentación. Después analizaste tus resultados y viste que estaba sucia 
                            realmente. Llegaste a una conclusión gracias a eso: que el agua de lluvia no se puede 
                            tomar. Los investigadores siempre usamos este método para hacer descubrimientos. <br/><br/>
                            
                            —Entonces… ¿ya soy un científico como tú? <br/><br/>
                            
                            Su padre se rio y dijo:<br/><br/>
                            
                            —Sólo si sigues usando el método científico.
                        </p>
                    </Grid>
                </Grid>
        </Grid>
    </Grid>
    );
}
 
export default El_método_científico ;
