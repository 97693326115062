import React                from "react";
import {Grid, makeStyles}   from '@material-ui/core';
import Button               from '@material-ui/core/Button';
import Dialog               from '@material-ui/core/Dialog';
import DialogActions        from '@material-ui/core/DialogActions';
import DialogContent        from '@material-ui/core/DialogContent';
import DialogTitle          from '@material-ui/core/DialogTitle';

import Avatar                               from '@material-ui/core/Avatar';
import Dropzone                             from 'react-dropzone';

import defaultAvatar from "./img/default_avatar.png";

const useStyles = makeStyles(theme =>({
  MuiDialogContainer: {
    borderRadius: '61px',
    height: "90%",
    width: "800px",
    backgroundColor: "#FEFAF1",
    border: "#707070 2px solid",
    backdropFilter: "blur(3px)",
  },
  selectedStyle:{
    border: "#40DAC9 5px solid",
    backgroundColor: "#DDF6FA",
    width: "100%",
    borderRadius: "5px",
  },
  questionText: {
    textAlign: "justify",
    font: "18px Lato",
    letterSpacing:' 0.21px',
  },
  feedbackText:{
    width: "80%",
    font: " 18px Lato",
    letterSpacing:' 0.21px',
  },
  questionTittle: {
    font:'30px Fredoka One',
    color: "#707070",
    height: "10px",
    letterSpacing:' 0.23px',
  },
  feedbackTittle: {
    font:'30px Fredoka One',
    color: "#1DA698",
    height: "auto",
    letterSpacing:' 0.23px',
    margin:'10px 0px',
  },
  feedbackBox:{
    border: "#80D7C6 3px solid",
    backgroundColor: "#FFFFFF",
    borderRadius: "59px",
    marginTop: "1%",
    minHeight:"50%",
  },
  correctIcon:{
    width:"40px",
    height:"40px",
  },
  primaryIconFeedback:{
    color:"#1DC491"
  },
  sizeLargeIcon:{
    fontSize: "50px"
  },
  questionAndAnswer:{
    width:"80%",
  },
  questionContainer:{
    margin:"5% 0 5% 0"
  },
  mainContainer:{
    height:"70%",
  },
  buttonContainer:{
    height:"30%",
  },

  continueButton:{
    marginTop: "6%",
    marginBottom: "6%",
    textTransform: 'none',
    height:'62px',
    width:'279px',
    [theme.breakpoints.down(1368)]:{
      height:'52px',
      width:'179px',
    },  
    background: '#7A54BA',
    boxShadow: '0px 3px 6px #00000029',
    borderRadius: '61px',
    color: '#FFFFFF',
    font: 'bold 20px lato ',
    '&:hover':{
        background: '#dd78fb',
    },
  },

  large: {
    // width: theme.spacing(40),
    // height: theme.spacing(40),
    width: "211px",
    height: "209px"
  },
}));


const TeachersAvatarUploader = (props) => {
  const { open, setOpen,} = props
  const classes = useStyles()
  const [avanzar, setAvanzar] = React.useState(true)



  const handleClose = () => {
    setOpen(false)
  }



  return (
    <Dialog
      open={open}
      classes={{ paper: classes.MuiDialogContainer }}
      disableEscapeKeyDown
    >
      <DialogTitle>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <h1
            style={{ font: "40px Fredoka One", margin: "0px", color: "orange" }}
          >
            Sube tu fotografía
          </h1>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid
          container
          item
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Grid container item justifyContent="center" alignItems="center">
            <Dropzone
              accept="image/*"
              multiple={false} // Only upload 1 file
            >
              {({ getRootProps, getInputProps }) => (
                <div {...getRootProps()}>
                  <input {...getInputProps()} />
                  <div className={classes.avatarWrapper}>
                    {/* { loading && <CircularProgress thickness={1} size={326} className={classes.avatarUploadProgress}/> } */}
                    <Button
                      onClick={() => {
                        setAvanzar(false);
                      }}
                    >
                      <Avatar
                        alt="default_img"
                        src={defaultAvatar}
                        className={classes.large}
                      />
                    </Button>
                  </div>
                </div>
              )}
            </Dropzone>
          </Grid>
          <p style={{ font: "22px lato", color: "#000" }}>
            Personaliza tu usuario docente subiendo una fotografía con las
            siguientes características:
          </p>
          <p
            style={{
              font: "18px lato",
              color: "#505759",
              marginBottom: "10px",
            }}
          >
            Retrato de frente y con luz adecuada para que se distinga tu rostro.
            <br />
            Formato .png o .jpg únicamente.
            <br />
            Peso máximo: 1 000 KB.
            <br />
          </p>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          className={classes.continueButton}
          disabled={avanzar}
          onClick={handleClose}
        >
          Subir
        </Button>
        <Button className={classes.continueButton} onClick={handleClose}>
          Cancelar
        </Button>
      </DialogActions>
      {/* {
        finished && <Redirect to={{ pathname: `/Heraldo` }} />
      } */}
    </Dialog>
  );
}

export default TeachersAvatarUploader;
