import { Button, Grid,makeStyles}              from '@material-ui/core';
import React, { useContext, useEffect } from "react";
import DrawerContext from "../../../../../usability/DrawerContext";
import { useOnLoadImages } from "../../../../hooks/useOnLoadImages";
import Loader from "../../../../Loader/Loader";
import Drawer from "../../ReadingTools/Drawer";

const useStyles =  makeStyles (theme =>({
     Contenedor:{
        background:'#E9FBF9',
        width:'100vw',
        overflowX:'clip',
        [theme.breakpoints.down(1161)]:{
            background:'none',
           overflowX:'unset',
        },
        [theme.breakpoints.down(600)]:{
            marginTop: '79.51px',
        }
    },
  ContainerInfoLectura: {
    position: "relative",
    height: "86px",
    [theme.breakpoints.down(502)]:{
        height: "43px",
    },
    background: "#76D4C3",
    "& .BarraInfoLectura": {
      height: "86px",
      background: `Url(https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Barras/Azul.png)`,
        [theme.breakpoints.down(502)]:{
            height: "43px",
        },
    },
    "& .InfoLectura": {
      position: "absolute",
      height: "86px",
      width: "219px",
      boxSizing: "border-box",
      padding: "17.2px 0",
      background: "#FAFAFA",
      borderRadius: "50px",
      boxShadow: "2px 2px 2px 2px #00000029",
      zIndex: "4",
        [theme.breakpoints.down(502)]:{
            height: "43px",
            width: "109px",
            padding: "8.6px 0",
            borderRadius: "25px",
        },
    },
    "& .InfoLectura:before": {
      position: "absolute",
      display: "flex",
      boxSizing: "border-box",
      padding: "0 0 0 10px",
      justifyContent: "center",
      alignItems: "center",
      height: "39.88px",
      width: "39.88px",
      borderRadius: "50%",
      content: '""',
      boxShadow: "3px 0px .5px 0px #00000029",
      background: "#FAFAFA",
      left: "90px",
      top: "65px",
      transform: "rotate(0.25turn)",
      [theme.breakpoints.down(502)]:{
        left: "45px",
        top: "30px",
        height: "20.88px",
        width: "20.88px",
        },
    },
    "& .InfoLectura:after": {
      content: '""',
      height: "15px",
      width: "15px",
      borderTop: "2px solid #20A698",
      borderLeft: "2px solid #20A698",
      position: "absolute",
      bottom: "-10px",
      left: "Calc(50% - 8px)",
      transform: "rotate(0.625turn)",
      [theme.breakpoints.down(502)]:{
        height: "10px",
        width: "10px",
        left: "Calc(50% - 4.5px)",
        bottom: "-3px",
        borderTop: "1px solid #20A698",
        borderLeft: "1px solid #20A698",
        },
    },
    "& .Sesion": {
      borderLeft: "2px solid #D8D8D8",
    },
    "& .Dato": {
      margin: "0 0 5px 0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#20A698",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        margin: "0 0 2.5px 0",
        },
    },
    "& .Caracter": {
      margin: "0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#000000",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        },
    },
       '& .Regresar':{
        color: '#F2624F',
        border: '#F2624F 1px solid',
        height: '30px',
        width:'100px',
        borderRadius: '20px',
        letterSpacing: '0.14px',
        textTransform: 'none',
        background:'#FEFAF1',
        top:'calc(50% - 15px)',
        left:'5%',
        zIndex:4,
        [theme.breakpoints.down(502)]: {
          font:'bold 10px lato',
          width: "60px",
        },
      },
  },
    ContenedorLectura:{
        background:'#fff',
        width:'1160px',
        height:'100%',
       padding:'60px 168px 35px ',
        
        transform: (props) =>
        props.scale > 1 ? `scale(${props.scale}) translate(0px, 10%)` : `none`,
        [theme.breakpoints.down(1161)]:{
            width:'502px',
            padding:'60px 0 0 0',
          },
        [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },

        '& .Escritorio':{
            display:'block',
            [theme.breakpoints.down(1161)]:{
                display:'none'
            },
        },
        '& .Tablet':{
            display:'none',
            [theme.breakpoints.down(1161)]:{
                display:'block'
            },
        }
    },
    ContainerTitulo:{
        margin:'0 0 46px 0',
        '& .Titulo':{
            font:'48px fredoka one',
            letterSpacing: '0px',
            color: '#E1AF10',
            margin:'0',
            textAlign:'center',
            [theme.breakpoints.down(1161)]:{
                font:'43px fredoka one',
                lineHeight:'52px',
            },
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            lineHeight : '30px',
            margin:'0 0 36px 0 ',
            font:'22px lato',
            color:'#000',
            letterSpacing: '0.21px',
            lineHeight:'30px',
            [theme.breakpoints.down(1161)]:{
                font:'18px lato',
                lineHeight :'28px',
                letterSpacing: '0.17px',
            },
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .Esp':{
            letterSpacing: '-0.11px!important',
            [theme.breakpoints.down(1161)]:{
                letterSpacing: '0.17px!important',
            },

        },
        '& .Imagen':{
            [theme.breakpoints.down(1161)]:{
                width:'100%',
            },
        },
    },
    

}));

const La_leyenda_de_la_flor_amarilla = (props) => {

  const {activeStep, setActiveStep, handlePreviewStep, id_actividad} = props

  const handleContinue = () => {
    setActiveStep(activeStep - 1)
    handlePreviewStep()
  }

  const {
    handleSize,
    disabledZoom,
    closeZoom,
    handleZoom,
    scale
  }= useContext(DrawerContext);
  const [loading, setLoading] = React.useState(true);
  const wrapperRef = React.useRef(null);
  const imagesLoaded = useOnLoadImages(wrapperRef);

  const detectSize = () => {
    const lectura = document.getElementById("lectura");
    const newWidth = lectura?.offsetWidth;
    const newHeight = lectura?.offsetHeight;
    setLoading(false);
    if (lectura) handleSize(newHeight, newWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", detectSize);
    return () => {
      handleZoom()
      window.removeEventListener("resize", detectSize);
      closeZoom()
    };
  }, []);

  const classes = useStyles({ disabledZoom,scale });
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
             <Grid container item justifyContent="center" className={classes.ContainerInfoLectura}>
                       <Grid container className="BarraInfoLectura">
            <Button className="Regresar" onClick={handleContinue}>
                Regresar
            </Button>
        </Grid>
               <Grid container item className="InfoLectura">
                    <Grid container item direction="column" xs={6} justifyContent="center">
                        <p className="Dato">Grado</p>
                        <p className="Caracter">2</p>
                    </Grid>
                    <Grid container item direction="column" xs={6} justifyContent="center" className="Sesion">
                        <p className="Dato">Sesión</p>
                        <p className="Caracter">31</p>
                    </Grid>
               </Grid>
            </Grid>
            {!imagesLoaded && <Loader />}
            <Grid
            container
            item
            className={classes.ContenedorLectura}
            justifyContent="center"
            id="lectura"
            onLoad={detectSize}
            ref={wrapperRef}
            >
            <Drawer id_lectura={id_actividad} />
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >
                        La leyenda<br className='Tablet'/> de la flor amarilla
                    </h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                            Cuenta la leyenda que hace muchos años, en un bosque lejano, vivía una mujer muy
                            sabia que conocía el poder de las plantas. Con ellas preparaba brebajes y ungüentos 
                            curativos. Personas enfermas provenientes de todas partes la visitaban y muchas de 
                            ellas se aliviaban de sus padecimientos. Pero los médicos de la ciudad no aprobaban 
                            su trabajo, así que la acusaron de brujería.
                        </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <Grid container >
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img2do/Sesion31/1.png"  alt="Tarjeta" />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                            Una noche, un grupo de hombres llegó hasta su cabaña y la tomó presa. A pesar de 
                            que en la casa sólo había yerbas, plantas en frascos de vidrio y algunas anotaciones, 
                            se inició un juicio en su contra. <br/><br/>
                            
                            —¿Sabe usted de qué se le acusa? —preguntó el fiscal. <br/><br/>
                            
                            —De curar enfermos —respondió ella con aire sereno. <br/><br/>
                            
                            —¡No! ¡De brujería! ¡De usar la magia para engañar a la gente! —recriminó el fiscal. <br/><br/>
                            
                            En su defensa, ella dijo que lo que hacía con las plantas no era magia ni hechicería,
                            sino observación: <br/><br/>

                            <span className="Esp">—Tomo una planta y la examino. Veo el color de sus hojas, la forma de su tallo. Pruebo un 
                            pedacito y anoto cuál es su sabor o qué efectos tiene. La comparo con otras plantas 
                            parecidas. Experimento con ellas haciendo jarabes, pomadas y ungüentos. Algunas las 
                            aprovecho como medicamento y otras no. Desecho las que no sirven y conservo en un 
                            frasco con alcohol las que sí para usarlas después. Eso es todo —explicó pacientemente.</span>
                        </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <Grid container >
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img2do/Sesion31/2.png"  alt="Tarjeta" />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                    <p className="Lectura">
                        Apenas acabó de hablar, la llevaron a su celda para que el jurado decidiera si era
                        inocente o culpable. El veredicto resultó cruel: la sentenciaron a la hoguera. <br/><br/>
                        
                        Cuando fueron por ella para informarle el fallo, no la encontraron. De inmediato se
                        corrió una voz de alarma y la buscaron por todos lados: en la prisión, en el bosque y
                        hasta en los pueblos cercanos. Nunca apareció. En cambio, en su celda encontraron 
                        una planta parecida al girasol, de flores amarillas y sabor amargo, llamada <em>árnica</em>.
                        Éste era su regalo. <br/><br/>
                        
                        En la actualidad, los productos de esta planta se utilizan para curar heridas y golpes.<br/><br/>
                        
                        Para recordarla, en aquel bosque hay una estatua de una mujer que sostiene plantas
                        en la mano.
                    </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <Grid container >
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img2do/Sesion31/3.png"  alt="Tarjeta" />
                        </Grid>
                    </Grid>
                </Grid>
        </Grid>
        <Grid container item style={{ marginTop: 80}} ></Grid>
    </Grid>
    );
}
 
export default La_leyenda_de_la_flor_amarilla;
