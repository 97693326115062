import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
    ContenedorLectura:{
        width:'502px',
                  [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
    },
    ContainerTitulo:{
        margin:'0 0 34px 0',
        '& .Titulo':{
            letterSpacing: '0px',
            color: '#FF0000',
            margin:'0',
            textAlign:'center',
            font:'43px fredoka one',
            lineHeight:'52px',
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            margin:'0 0 36px 0',
            color:'#000',
            font:'18px lato',
            lineHeight :'28px',
            letterSpacing: '0.17px',
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .SubTitulo':{
            color:'#4B5AD9',
            margin:'0',
            lineHeight :'28px',
            font:'bold 26px lato',
            letterSpacing: '0.24px',
            [theme.breakpoints.down(502)]:{
                font:'bold 18px lato',
                lineHeight :'24px',
                letterSpacing: '0.21px',
            },
        },
        '& .SubTitulo2':{
            color:'#0CB5D6',
            margin:'0',
            font:'bold 22px lato',
            lineHeight :'24px',
            letterSpacing: '0.21px',
            [theme.breakpoints.down(502)]:{
                font:'bold 18px lato',
                lineHeight :'20px',
                letterSpacing: '0.16px',
            },
        },

        '& .Imagen':{
            width:'100%',
        },  
      
    }
}));

const La_autodeterminación_en_la_niñez = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >
                    La autodeterminación <br/> en la niñez
                    </h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                      <p className="Lectura">
                        <h3 className="SubTitulo">
                        La escuela local ofrecerá un curso sobre autodeterminación 
                        a estudiantes y familiares</h3> <br/>

                        La directora de la escuela local ha anunciado que se impartirá un curso de 
                        autodeterminación para todos los padres, madres, tutores y estudiantes.<br/><br/>

                        Tras el anuncio, en este medio de comunicación hemos querido conocer los motivos 
                        por los que el colegio ha tomado esta decisión, así que hemos hablado con su 
                        directora. Al preguntarle por el origen de esta iniciativa, comentó: “La 
                        autodeterminación es un valor esencial en nuestros niños. Necesitan aprender a 
                        tomar decisiones desde que son pequeños, y queremos enseñar a los adultos 
                        responsables de su crianza cómo ayudarlos en este proceso”.<br/><br/> 
                        
                        Por su parte, la especialista encargada de impartir el curso afirma: “Todos los niños 
                        tienen que aprender a valorar las diferentes opciones entre las que pueden elegir y a 
                        responsabilizarse de sus decisiones. Eso es lo que le vamos a enseñar a los 
                        estudiantes”.<br/><br/> 
                        
                        <h3 className="SubTitulo2">“La autodeterminación es un valor esencial en nuestros niños” </h3><br/>
                        
                        El curso comenzará la próxima semana y tendrá una duración de cuatro semanas. 
                        Cuando termine, hablaremos con los estudiantes y sus familiares para conocer su 
                        opinión y valorar los resultados.

                      </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <Grid container justifyContent="center" >
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img1ro/Sesion36/1.png" alt="Tarjeta" />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container justifyContent="center" sm={12}>
                        <Grid container justifyContent="center" >
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img1ro/Sesion36/2.png" alt="Tarjeta" />
                        </Grid>
                    </Grid>
                </Grid>
        </Grid>
    </Grid>
    );
}
 
export default La_autodeterminación_en_la_niñez ;
