import { Button, Grid,makeStyles}              from '@material-ui/core';
import React, { useContext, useEffect } from "react";
import DrawerContext from "../../../../../usability/DrawerContext";
import { useOnLoadImages } from "../../../../hooks/useOnLoadImages";
import Loader from "../../../../Loader/Loader";
import Drawer from "../../ReadingTools/Drawer";

const useStyles =  makeStyles (theme =>({
     Contenedor:{
        background:'#E9FBF9',
        width:'100vw',
        overflowX:'clip',
        [theme.breakpoints.down(1161)]:{
            background:'none',
           overflowX:'unset',
        },
        [theme.breakpoints.down(600)]:{
            marginTop: '79.51px',
        }
    },
  ContainerInfoLectura: {
    position: "relative",
    height: "86px",
    [theme.breakpoints.down(502)]:{
        height: "43px",
    },
    background: "#76D4C3",
    "& .BarraInfoLectura": {
      height: "86px",
      background: `Url(https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Barras/Azul.png)`,
        [theme.breakpoints.down(502)]:{
            height: "43px",
        },
    },
    "& .InfoLectura": {
      position: "absolute",
      height: "86px",
      width: "219px",
      boxSizing: "border-box",
      padding: "17.2px 0",
      background: "#FAFAFA",
      borderRadius: "50px",
      boxShadow: "2px 2px 2px 2px #00000029",
      zIndex: "4",
        [theme.breakpoints.down(502)]:{
            height: "43px",
            width: "109px",
            padding: "8.6px 0",
            borderRadius: "25px",
        },
    },
    "& .InfoLectura:before": {
      position: "absolute",
      display: "flex",
      boxSizing: "border-box",
      padding: "0 0 0 10px",
      justifyContent: "center",
      alignItems: "center",
      height: "39.88px",
      width: "39.88px",
      borderRadius: "50%",
      content: '""',
      boxShadow: "3px 0px .5px 0px #00000029",
      background: "#FAFAFA",
      left: "90px",
      top: "65px",
      transform: "rotate(0.25turn)",
      [theme.breakpoints.down(502)]:{
        left: "45px",
        top: "30px",
        height: "20.88px",
        width: "20.88px",
        },
    },
    "& .InfoLectura:after": {
      content: '""',
      height: "15px",
      width: "15px",
      borderTop: "2px solid #20A698",
      borderLeft: "2px solid #20A698",
      position: "absolute",
      bottom: "-10px",
      left: "Calc(50% - 8px)",
      transform: "rotate(0.625turn)",
      [theme.breakpoints.down(502)]:{
        height: "10px",
        width: "10px",
        left: "Calc(50% - 4.5px)",
        bottom: "-3px",
        borderTop: "1px solid #20A698",
        borderLeft: "1px solid #20A698",
        },
    },
    "& .Sesion": {
      borderLeft: "2px solid #D8D8D8",
    },
    "& .Dato": {
      margin: "0 0 5px 0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#20A698",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        margin: "0 0 2.5px 0",
        },
    },
    "& .Caracter": {
      margin: "0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#000000",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        },
    },
       '& .Regresar':{
        color: '#F2624F',
        border: '#F2624F 1px solid',
        height: '30px',
        width:'100px',
        borderRadius: '20px',
        letterSpacing: '0.14px',
        textTransform: 'none',
        background:'#FEFAF1',
        top:'calc(50% - 15px)',
        left:'5%',
        zIndex:4,
        [theme.breakpoints.down(502)]: {
          font:'bold 10px lato',
          width: "60px",
        },
      },
  },
    ContenedorLectura:{
        background:'#fff',
        width:'1160px',
        height:'100%',
       padding:'60px 168px 35px ',
        
        transform: (props) =>
        props.scale > 1 ? `scale(${props.scale}) translate(0px, 10%)` : `none`,
        [theme.breakpoints.down(1161)]:{
            width:'502px',
            padding:'60px 0 0 0',
          },
        [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },

        '& .Escritorio':{
            display:'block',
            [theme.breakpoints.down(1161)]:{
                display:'none'
            },
        },
        '& .Tablet':{
            display:'none',
            [theme.breakpoints.down(1161)]:{
                display:'block'
            },
        }
    },
    ContainerTitulo:{
        margin:'0 0 46px 0',
        '& .Titulo':{
            font:'48px fredoka one',
            letterSpacing: '0px',
            color: '#F18CDF',
            margin:'0',
            [theme.breakpoints.down(1161)]:{
                font:'43px fredoka one',
                lineHeight:'52px',
            },
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            lineHeight : '30px',
            margin:'0 0 36px 0',
            font:'22px lato',
            color:'#000',
            letterSpacing: '0.18px',
            [theme.breakpoints.down(1161)]:{
                font:'18px lato',
                lineHeight :'28px',
                letterSpacing: '0.17px',
            },
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .Esp':{
            margin:'0',
            padding:'0 38px 0 0',
            [theme.breakpoints.down(1161)]:{
                padding:'0',
                margin:'0 0 25px 0',
            },
        },
        '& .Esp2':{
            margin:'36px 0 0 0',
            [theme.breakpoints.down(1161)]:{
                margin:'0',

            },
        },

        '& .SubTitulo':{
            font:'bold 30px lato',
            color:'#F6644B',
            letterSpacing:'.28px',
            margin:'0',
            [theme.breakpoints.down(1161)]:{
                lineHeight :'28px',
                font:'bold 26px lato',
                letterSpacing: '0.24px',
            },
            [theme.breakpoints.down(502)]:{
                font:'bold 18px lato',
                lineHeight :'24px',
                letterSpacing: '0.21px',
            },
        },

        '& .SubTitulo2':{
            font:'bold 26px lato',
            color:'#3886AC',
            letterSpacing:'.28px',
            margin:'0',
            [theme.breakpoints.down(1161)]:{
                font:'bold 22px lato',
                lineHeight :'24px',
                letterSpacing: '0.21px',
            },
            [theme.breakpoints.down(502)]:{
                font:'bold 18px lato',
                lineHeight :'20px',
                letterSpacing: '0.16px',
            },
        },
        '& .TextImg':{
            margin:'22px 0 0 0',
            font:'20px lato',
            alignSelf:'flex-start',
            color:'#717476',
            [theme.breakpoints.down(1161)]:{
                font:' 16px lato',
                lineHeight :'25px',
                letterSpacing: '0.15px',
            },
            [theme.breakpoints.down(502)]:{
                font:' 11px lato',
                lineHeight :'14px',
                letterSpacing: '0.10px',
            },
        },

        '& .Imagen':{
            [theme.breakpoints.down(1161)]:{
                width:'471px',
            },
            [theme.breakpoints.down(502)]:{
                width:'100%',
            },
        },
        '& .Imagen2':{
            [theme.breakpoints.down(1161)]:{
                width:'100%',
            },
        },
        
    },
    Esp:{
        margin:' 0 0 15px 0',
    },
}));

const Aves_sin_fronteras = (props) => {

  const {activeStep, setActiveStep, handlePreviewStep, id_actividad} = props

  const handleContinue = () => {
    setActiveStep(activeStep - 1)
    handlePreviewStep()
  }

  const {
    handleSize,
    disabledZoom,
    closeZoom,
    handleZoom,
    scale
  }= useContext(DrawerContext);
  const [loading, setLoading] = React.useState(true);
  const wrapperRef = React.useRef(null);
  const imagesLoaded = useOnLoadImages(wrapperRef);

  const detectSize = () => {
    const lectura = document.getElementById("lectura");
    const newWidth = lectura?.offsetWidth;
    const newHeight = lectura?.offsetHeight;
    setLoading(false);
    if (lectura) handleSize(newHeight, newWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", detectSize);
    return () => {
      handleZoom()
      window.removeEventListener("resize", detectSize);
      closeZoom()
    };
  }, []);

  const classes = useStyles({ disabledZoom,scale });
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
             <Grid container item justifyContent="center" className={classes.ContainerInfoLectura}>
                       <Grid container className="BarraInfoLectura">
            <Button className="Regresar" onClick={handleContinue}>
                Regresar
            </Button>
        </Grid>
               <Grid container item className="InfoLectura">
                    <Grid container item direction="column" xs={6} justifyContent="center">
                        <p className="Dato">Grado</p>
                        <p className="Caracter">2</p>
                    </Grid>
                    <Grid container item direction="column" xs={6} justifyContent="center" className="Sesion">
                        <p className="Dato">Sesión</p>
                        <p className="Caracter">11</p>
                    </Grid>
               </Grid>
            </Grid>
            {!imagesLoaded && <Loader />}
            <Grid
            container
            item
            className={classes.ContenedorLectura}
            justifyContent="center"
            id="lectura"
            onLoad={detectSize}
            ref={wrapperRef}
            >
            <Drawer id_lectura={id_actividad} />
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >Aves sin fronteras</h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container md={12} lg={7}>
                      <p className="Lectura Esp">
                        <h3 className='SubTitulo'>Cooperación internacional </h3><br/>

                         El término <em>cooperación internacional</em> es similar 
                         al de <em>cooperación</em> (o <em>colaboración</em>) que 
                         aprendemos en casa y ponemos en práctica 
                         con nuestras amistades, en la escuela o en los 
                         diversos grupos donde participamos. La 
                         palabra se refiere a unir esfuerzos. Cuando 
                         cooperamos, aportamos algo de lo que 
                         podemos (y sabemos) hacer para lograr 
                         objetivos colectivos, como: ayudar a limpiar la 
                         casa, trabajar o jugar en equipo, o cuidar de 
                         las otras personas y nuestro entorno.
                      </p>
                    </Grid>
                    <Grid container justifyContent="center" md={12} lg={5}>
                        <Grid container justifyContent="center" alignItems='flex-end'>
                            <img className="Imagen Escritorio" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img2do/Sesion11/1.png" alt="Tarjeta" />
                            <img className="Imagen Tablet" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img2do/Sesion11/1R.png" alt="Tarjeta" />
                        </Grid>
                    </Grid>
                    <Grid container sm={12}>
                      <p className="Lectura Esp2"> 
                        La <em>cooperación internacional</em> sucede cuando varios países se unen para resolver juntos 
                        algún problema o necesidad global. Aunque no siempre fue considerada importante. 
                        Esta idea negativa cambió después de las dos guerras mundiales (principalmente la 
                        segunda), cuando se reconoció que se necesitaban instituciones que ayudaran a 
                        mantener la paz.<br/><br/> 
                        
                        Con ese objetivo en mente se creó en 1945 la Organización de las Naciones Unidas 
                        (<span style={{fontVariant: "all-small-caps"}}>ONU</span>), la cual se enfocó durante sus primeros años en la reconstrucción y la mejora
                         económica de los países afectados por la guerra.
                      </p>
                    </Grid>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                      <p className="Lectura">
                        <h3 className='SubTitulo2'>
                        ¿Cómo se relacionan las aves con la cooperación internacional?
                        </h3><br/>
                         Con el tiempo, los esfuerzos de cooperación entre países se concentraron en la 
                         resolución de problemas sociales, económicos, científicos y tecnológicos. En la 
                         actualidad, el cambio climático se ha unido a la lista.<br/><br/> 
                         
                         Una muestra de ello es la conservación de las especies. Por ejemplo, existe el 
                         proyecto de <em>Aves Playeras Migratorias</em>, que busca proteger a las aves. Este programa 
                         recopila información en los distintos países de América para conocer los problemas a 
                         los que se enfrentan y proponer soluciones basadas en la ciencia.<br/><br/> 
                         
                         Las aves migratorias se mueven de un lugar a otro cada año. Viajan cientos de
                         kilómetros en busca del mejor clima para pasar cierta temporada, alimentarse y así 
                         mantener el equilibro de los ecosistemas. Pero el cambio climático ha afectado este 
                         proceso, pues modifica los sitios a los que suelen migrar.
                      </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12} className='Img'>
                        <Grid container justifyContent="center" alignItems='flex-end'>
                            <img className='Imagen2' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img2do/Sesion11/2.png" alt="Tarjeta" />
                        </Grid>
                        <Grid container justifyContent="center" alignItems='flex-end'>
                           <p className='TextImg'>
                            Todos podemos contribuir a que el viaje de las aves migratorias sea más seguro y que así 
                            lleguen a su destino. Algunas formas de ayudarlas son plantar árboles y flores nativas, cuidar 
                            el agua y proteger sus nidos.</p>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                      <p className="Lectura">
                        Cuando un problema atraviesa fronteras, es necesario que diferentes gobiernos y 
                        organizaciones internacionales unan esfuerzos para buscar soluciones.<br/><br/>  

                        ¿Qué crees que podríamos hacer para contrarrestar un problema tan grave? 
                        Investiga más acerca del cambio climático y reflexiona. También te invitamos a 
                        cooperar poniendo lo que esté a tu alcance para mejorar una situación como la de las
                        aves migratorias. Verás que colaborar poco a poco da frutos.
                      </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12} className='Img'>
                        <Grid container justifyContent="center" alignItems='flex-end'>
                            <img className='Imagen2' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img2do/Sesion11/3.png" alt="Tarjeta" />
                        </Grid>
                        <Grid container justifyContent="center" alignItems='flex-end'>
                           <p className='TextImg'>
                           La cooperación en la escuela es fundamental para el aprendizaje de todos los estudiantes.
                           </p>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container item style={{ marginTop: 80}} ></Grid>
        </Grid>
    );
}
 
export default Aves_sin_fronteras;
