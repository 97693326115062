import { makeStyles, Grid } from "@material-ui/core";
import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
// import StudentsList from "./StudentsList";
import TeachersAvatarUploader from "../../TeachersAvatarUploader";

import AuthContext from "../../../../../auth/AuthContext";
import DropZone from "./DropZone";
import types from "../../../../../types/types";
import axios from "../../../../../services/axios";

import Loader from "../../../../Loader/Loader";

import ModalConfirm from "../../../../Modals/ModalConfirm";
import ModalMessage from "../../../../Modals/ModalMessage";
import ModalError from "../../../../Modals/ModalError";
import ModalErrorDetails from "../../../../Modals/ModalErrorDetails";
import ModalPasswordChange from "../../../../Modals/ModalPasswordChange";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    padding: "48px 0 48px 0",
    width: "100vw",
    minHeight: "calc(100vh - 80px)",
    marginTop: "80px",
    // minHeight:'calc(100vh - 80px)',
    background:
      "linear-gradient(217deg, rgba(255,194,0,0.4962185557816877) 2%, rgba(255,255,255,1) 25%, rgba(255,255,255,0.4962185557816877) 81%, rgba(144,81,177,1) 98%)",
    [theme.breakpoints.up("sm")]: {
      marginTop: "0",
    },
  },
  boxContainer: {
    background: "#fff",
    width: "80%",
    height: "auto",
    borderRadius: "42px",
    padding: "20px",

    gap: "20px",
    boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.5)",
    [theme.breakpoints.up("md")]: {
      padding: "48px 20px 48px 20px",
    },
  },
  titleContainer: {
    margin: "30px 0",
  },
  title: {
    font: "28px Fredoka One ",
    margin: "0",
    textAlign: "center",
    color: " #40ADA3",
    [theme.breakpoints.up("md")]: {
      fontSize: "36px",
    },
    [theme.breakpoints.up("xl")]: {
      fontSize: "42px",
    },
  },
  link: {
    cursor: "pointer",
    textDecoration: "none",
    // height: "31px",
    // width: "190px",
  },
  backButtonContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.up("sm")]: {
      justifyContent: "flex-start",
    },
  },
  backButton: {
    cursor: "pointer",
    height: "35px",
    // width: "200px",
    backgroundColor: "#FDEFF7",
    color: "#DC5EA6",
    border: "#DC5EA6 1px solid",
    borderRadius: "20px",
    textTransform: "none",
    font: "20px Lato",
    letterSpacing: "0.14px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
  },

  ContainerSerchBar: {
    position: "relative",
    height: "70px",
  },
  SerchBar: {
    outline: "none",
    height: "100%",
    width: "49.31%",
    borderRadius: "36px",
    padding: "0 32px",
    border: "4px solid #EA9D90",
    textAlign: "left",
    letterSpacing: "0.63px",
    color: "#7A54BA",
    font: "600 20px lato",
    margin: " 0 10%",
  },
  SerchBarIcon: {
    right: "25%",
    top: "25%",
    position: "absolute",
    fontSize: "40px",
    color: "#7A54BA",
  },
  studentListContainer: {
    // margin: "10px",
    gap: "20px",
  },
  inputLoginUser: {
    width: "80%",
    maxWidth: "280px",
    height: "40px",
    paddingLeft: "27px",
    borderRadius: "61px",
    // border: " solid #1D2AAD 3px ",
    border: "none",
    background: "#FFFFFF",
    font: "Helvetica",
    fontSize: "16px",
    //    width: '449px',
    // height: '72px',
    // margin: '43px 139px 19px 66px',
    // padding: '26px 24px 25px',
    backgroundColor: "#f0f0f0",
    "&:focus": {
      outline: "none",
    },
    [theme.breakpoints.up("lg")]: {
      height: "60px",
      maxWidth: "449px",
      fontSize: "18px",
    },
  },
  PurpleButton: {
    color: "#FFFFFF",
    backgroundColor: "#9051b1",
    borderRadius: "30px",
    // margin: "0 auto",
    width: "100%",
    maxWidth: "238px",
    minHeight: "45px",
    border: "none",
    textTransform: "unset",
    cursor: "pointer",
    fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
    fontWeight: "500",
    padding: "6px 16px",
    lineHeight: "1.75",
    transition:
      "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    boxShadow:
      "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
    "&:hover": {
      backgroundColor: "#B965D3 ",
    },
  },
  WitheButton: {
    color: "#9051b1",
    // borderColor: "#9051b1",
    border: "1px solid #9051b1",
    backgroundColor: "#FFFFFF",
    borderRadius: "30px",
    // margin: "0 auto",
    width: "100%",
    maxWidth: "238px",
    minHeight: "45px",
    // textTransform: "uppercase",
    cursor: "pointer",
    fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
    fontWeight: "500",
    padding: "6px 16px",
    lineHeight: "1.75",
    transition:
      "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    boxShadow:
      "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
    "&:hover": {
      backgroundColor: "#B965D330 ",
    },
  },
  linkToUploader: {
    textDecoration: "none",
    // width: '80%',
    width: "100%",
    maxWidth: "238px",
    margin: "0 auto",
    // marginLeft: "44px",
    // marginBottom: "40px",
    "& .ButtonIsg": {
      // color: "blue",
      // textTransform: "none",
      // font: " 22px lato",
      // letterSpacing: "0.36px",
      // width: '102px',
      // height: '21px',
      // background: '#FEFAF1 0% 0% no-repeat padding-box',
      // boxShadow: '0px 1px 2px #00000029',
      // border: '1px solid #F2624F',
      // borderRadius: '10px',
    },
    "& .icon": {
      // color: "purple",
      // marginRight: "20px",
      // fontSize: "18px",
      margin: "0",
      [theme.breakpoints.up("lg")]: {
        // fontSize: "30px",
      },
    },
  },
}));

const Edit = () => {
  const classes = useStyles();
  const { user, dispatch } = useContext(AuthContext);

  const [open, setOpen] = React.useState(false);
  const [name, setName] = useState(user?.name);
  const [lastName, setLastName] = useState(user?.firstLastName);
  const [secondLastName, setSecondLastName] = useState(user?.secondLastName);

  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [modalMessageOpen, setModalMessageOpen] = useState(false);
  const [modalErrorDetailsOpen, setModalErrorDetailsOpen] = useState(false);
  const [modalErrorOpen, setModalErrorOpen] = useState(true);
  const [confirmChangesResult, setConfirmChangesResult] = useState("");
  const [resultError, setResultError] = useState("");
  const [image, setImage] = React.useState("");
  const [loading, setLoading] = useState(false);
  const [modalChangePasswordOpen, setModalChangePasswordOpen] = useState(false);
  const handleModalOpen = () => {
    setModalChangePasswordOpen(!modalChangePasswordOpen);
  };

  React.useEffect(() => {
    if (image) changeImage();
  }, [image]);

  const handleCloseConfirmationModal = () => {
    setConfirmationModalOpen(!confirmationModalOpen);
  };
  const handleCloseMessageModal = () => {
    setModalMessageOpen(!modalMessageOpen);
    setResultError("");
  };
  const handleCloseErrorModal = () => {
    setModalErrorOpen(!modalErrorOpen);
  };
  const handleCloseErrorDetailsModal = () => {
    setModalErrorOpen(false);
    setModalErrorDetailsOpen(!modalErrorDetailsOpen);
  };

  const changeImage = () => {
    const imageToBase64 = image.split(",")[1];
    const body = {
      idUsuario: user.userId,
      image: imageToBase64,
    };
    setLoading(true);
    axios
      .post("/profesor/setImage", body)
      .then((response) => {
        if (response.status === 200) {
          dispatch({
            type: types.avatar,
            payload: {
              avatar: {
                ...user.avatar,
                path: image,
                // path: newImage,
              },
            },
          });
          setLoading(false);
          setConfirmationModalOpen(false);
          setModalMessageOpen(true);

          setConfirmChangesResult("Los datos se han actualizado correctamente");
        }
      })
      .catch((err) => {
        if (err.response) {
          const errorCode = err?.response?.data?.detalles?.error;
          if (errorCode === "00099") {
            setConfirmationModalOpen(false);
            setModalErrorOpen(true);
            setResultError("content");
            setImage("");
          } else if (errorCode === "00096") {
            setConfirmationModalOpen(false);
            setModalErrorOpen(true);
            setResultError("limit");
            setImage("");
          }
        } else {
          setModalMessageOpen(true);

          setConfirmChangesResult(
            "Ha ocurrido un error inesperado, por favor Intente de nuevo más tarde"
          );
          setImage("");
        }
        setLoading(false);
      });
  };
  const handleImage = (imageBase64) => {
    setImage(imageBase64);
  };
  const handleName = (e) => {
    setName(e.target.value);
  };
  const handleLastName = (e) => {
    setLastName(e.target.value);
  };
  const handleSecondLastName = (e) => {
    setSecondLastName(e.target.value);
  };

  const confirmChanges = () => {
    setConfirmationModalOpen(false);
    if (name && lastName) {
      setLoading(true);
      const body = {
        id_usuario: user.userId,
        nombre: name,
        apellido_paterno: lastName,
        apellido_materno: secondLastName,
      };
      axios
        .put("/listado/nombre", body)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: types.perfilChanges,
              payload: {
                name,
                lastName,
                secondLastName,
              },
            });
            setModalMessageOpen(true);
            setConfirmChangesResult(
              "Los datos se han actualizado correctamente"
            );
          }
        })
        .catch((error) => {
          setConfirmationModalOpen(false);
          setModalMessageOpen(true);
          setConfirmChangesResult("");
          if (error.response) {
            // Request made and server responded
            const errorData = error.response.data;
          const mensaje =
            errorData?.detalles?.msg ||
            "Ha ocurrido un error al consumir los servicios, inténtelo de nuevo más tarde.";
            setConfirmChangesResult(mensaje);
            setModalMessageOpen(true);
          } else {
            // Something happened in setting up the request that triggered an Error
            const mensaje =
              "Ha ocurrido un error al actualizar los datos. Intente de nuevo más tarde";
            setConfirmChangesResult(mensaje);
            setModalMessageOpen(true);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setConfirmationModalOpen(false);
      setModalMessageOpen(true);
      setConfirmChangesResult("Asegúrese de llenar todos los datos");
    }
  };

  return (
    <Grid
      container
      item
      sm={12}
      alignItems="center"
      justifyContent="center"
      className={classes.mainContainer}
    >
      <Grid container item direction="column" className={classes.boxContainer}>
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="center"
        >
          <Grid item className={classes.backButtonContainer}>
            <Link className={classes.link} to="/docente/configuracion">
              <button className={classes.backButton}>
                <ArrowBackIcon /> Configuración
              </button>
            </Link>
          </Grid>
          <Grid
            container
            item
            alignItems="center"
            justifyContent="center"
            className={classes.titleContainer}
          >
            <h2 className={classes.title}>Editar perfil del docente</h2>
          </Grid>
          <DropZone
            handleNext={() => {}}
            handleImage={handleImage}
            image={image}
          />
        </Grid>
        <Grid
          container
          direction="column"
          className={classes.studentListContainer}
          justifyContent="center"
          alignItems="center"
        >
          <input
            type="text"
            placeholder="Nombre"
            value={name}
            onChange={handleName}
            className={classes.inputLoginUser}
          />
          <input
            type="text"
            placeholder="Apellido Paterno"
            value={lastName}
            onChange={handleLastName}
            className={classes.inputLoginUser}
          />
          <input
            type="text"
            placeholder="Apellido Materno"
            value={secondLastName}
            onChange={handleSecondLastName}
            className={classes.inputLoginUser}
          />
          {/* <input type="text" placeholder="Correo" value={secondLastName} className={classes.inputLoginUser} disabled /> */}
          <input
            type="text"
            placeholder="Escuela"
            value={user?.school}
            className={classes.inputLoginUser}
            disabled
          />
          <input
            type="text"
            placeholder="Pais"
            value={user?.country}
            className={classes.inputLoginUser}
            disabled
          />
          {/* <input
            type="text"
            placeholder="Correo Electrónico"
            value={email}
            onChange={handleEmail}
            className={classes.inputLoginUser}
          /> */}
          <button
            className={classes.PurpleButton}
            onClick={handleCloseConfirmationModal}
          >
            Confirmar cambios
          </button>

          {/* <Link className={classes.linkToUploader} to="avatar_teacher">
            <Button className="ButtonIsg">
              <p className="icon"> Editar foto de perfil</p>
            </Button>
          </Link> */}

          <button className={classes.WitheButton} onClick={handleModalOpen}>
            Cambiar contraseña
          </button>

          <ModalConfirm
            open={confirmationModalOpen}
            handleClose={handleCloseConfirmationModal}
            handleConfirm={confirmChanges}
          />
          {loading && <Loader />}

          <ModalError
            open={modalErrorOpen}
            handleClose={handleCloseErrorModal}
            type={resultError}
            handleNext={handleCloseErrorDetailsModal}
          />
          <ModalErrorDetails
            open={modalErrorDetailsOpen}
            handleClose={handleCloseErrorDetailsModal}
          />
          <ModalMessage
            open={modalMessageOpen}
            handleClose={handleCloseMessageModal}
            title={confirmChangesResult}
            textOfButton="Aceptar"
          />
          <ModalPasswordChange
            open={modalChangePasswordOpen}
            handleClose={handleModalOpen}
          />
          <TeachersAvatarUploader open={open} setOpen={setOpen} />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Edit;
