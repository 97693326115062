import {
  makeStyles,
  Grid,
  FormGroup,
  styled,
  Button,
} from "@material-ui/core";
import React from "react";
import { Link } from "react-router-dom";
import AuthContext from "../../../../auth/AuthContext";
import ContainerDocente from "../ContainerDocente";
import { useParams } from "react-router-dom";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import axios from "../../../../services/axios";
import UsabilityContext from "../../../../usability/UsabilityContext";
import AsignarRemedialItem from "./AsignarRemedialItem";

const useStyles = makeStyles((theme) => ({
  Container: {
    padding: "48px 0 48px 0",
    background:
      "linear-gradient(217deg, rgba(255,194,0,0.4962185557816877) 2%, rgba(255,255,255,1) 25%, rgba(255,255,255,0.4962185557816877) 81%, rgba(144,81,177,1) 98%)",
    minHeight: "calc(100vh - 80px)",
  },
  ContainerReportes: {
    background: "#fff",
    maxWidth: "1216px",
    width: "90%",
    gap: "15px",
    minHeight: "75vh",
    // minHeight: "75vh",
    // maxHeight: "982px",
    borderRadius: "42px",
    padding: "30px",
    boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.5)",
    display: "grid",
    gridTemplateRows: "auto auto auto auto auto",
    [theme.breakpoints.up("md")]: {
      height: "75vh",
      gridTemplateRows: "auto 2px auto 1fr auto",
      fontSize: "24px",
    },
    "& .Titulo": {
      font: "42px fredoka one",
      margin: "0 0 84px 34px",
    },
  },
  ContainerSerchBar: {
    gap: "10%",
    padding: "10px 0",
    justifyContent: "space-between",
    // [theme.breakpoints.up("lg")]: {
    //   height: "70px",
    // },
  },
  ContainerInput: {
    position: "relative",
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "50%",
      height: "100%",
    },
  },

  SerchBar: {
    outline: "none",
    width: "100%",
    borderRadius: "36px",
    padding: "5px 32px",
    border: "4px solid #EA9D90",
    textAlign: "left",
    letterSpacing: "0.63px",
    color: "#7A54BA",
    font: "600 16px lato",
    // margin: " 0 10%",
    [theme.breakpoints.up("lg")]: {
      height: "auto",
      font: "600 20px lato",
      padding: "10px 32px",
    },
  },
  SerchBarIcon: {
    right: "12.5px",
    top: "10.5px",
    position: "absolute",
    fontSize: "20px",
    color: "#7A54BA",
    [theme.breakpoints.up("sm")]: {
      right: "12.5px",
      top: "12.5px",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "40px",
      right: "25px",
      top: "10px",
    },
  },
  ContainerBarra: {
    height: "498px",
    boxShadow: "0 0 30px 0 rgba(76, 147, 230, 0.1)",
    margin: "0 0 20px 0",
    borderRadius: "8px",
  },

  ContainerLinkinsiginas: {
    textDecoration: "none",
    // marginLeft: "44px",
    // marginBottom: "40px",
    textAlign: "left",
    "& .ButtonIsg": {
      color: "blue",
      textTransform: "none",
      font: "18px lato",
      letterSpacing: "0.36px",
      margin: "0",
      // width: '102px',
      // height: '21px',
      // background: '#FEFAF1 0% 0% no-repeat padding-box',
      // boxShadow: '0px 1px 2px #00000029',
      // border: '1px solid #F2624F',
      // borderRadius: '10px',
      [theme.breakpoints.up("md")]: {
        font: " 20px lato",
      },
      [theme.breakpoints.up("lg")]: {
        font: " 22px lato",
      },
    },
    "& .icon": {
      color: "purple",
      marginRight: "20px",
      fontSize: "30px",
      width: "30px",
      height: "30px",
      [theme.breakpoints.up("md")]: {
        width: "40px",
        height: "40px",
        fontSize: "40px",
      },
      [theme.breakpoints.up("lg")]: {
        width: "55px",
        height: "55px",
        fontSize: "50px",
      },
    },
  },
  topContainer: {
    display: "grid",
    width: "100%",
    gridTemplateColumns: "1fr",
    gridTemplateRows: "auto auto",
    gap: "20px",
    [theme.breakpoints.up("sm")]: {
      gridTemplateColumns: "auto 1fr",
      gridTemplateRows: "1fr",
    },
  },
  titleContainer: {
    // margin: "20px 0",
    flexDirection: "column",
    gap: "5px",
    [theme.breakpoints.up("lg")]: {
      // margin: "10px 0",
    },
  },

  title: {
    font: "26px fredoka one",
    textAlign: "center",
    width: "100%",
    margin: "0",
    [theme.breakpoints.up("sm")]: {
      width: "auto",
      // margin: "0 0 34px 14px",
    },
    [theme.breakpoints.up("md")]: {
      font: "38px fredoka one",
      width: "auto",
      // margin: "0 0 34px 14px",
    },
    [theme.breakpoints.up("lg")]: {
      font: "42px fredoka one",
    },
  },
  subtitle: {
    font: "20px fredoka one",
    textAlign: "center",
    width: "100%",
    margin: "0",
    color: "#44444f",
    [theme.breakpoints.up("sm")]: {
      width: "auto",
      // margin: "0 0 34px 14px",
    },
    [theme.breakpoints.up("md")]: {
      font: "28px fredoka one",
      width: "auto",
      // margin: "0 0 34px 14px",
    },
    [theme.breakpoints.up("lg")]: {
      font: "32px fredoka one",
    },
  },

  divisor: {
    width: "100%",
    backgroundColor: "#979797",
    height: "1px",
    margin: 0,
    padding: 0,
    boxSizing: "border-box",
  },

  linkToUploader: {
    textDecoration: "none",
    // width: '80%',
    // width: "100%",
    maxWidth: "238px",
    // margin: "0 auto",
    // marginLeft: "44px",
    // marginBottom: "40px",
    "& .ButtonIsg": {
      // color: "blue",
      // textTransform: "none",
      // font: " 22px lato",
      // letterSpacing: "0.36px",
      // width: '102px',
      // height: '21px',
      // background: '#FEFAF1 0% 0% no-repeat padding-box',
      // boxShadow: '0px 1px 2px #00000029',
      // border: '1px solid #F2624F',
      // borderRadius: '10px',
    },
    "& .icon": {
      // color: "purple",
      // marginRight: "20px",
      // fontSize: "18px",
      margin: "0",
      [theme.breakpoints.up("lg")]: {
        // fontSize: "30px",
      },
    },
  },
  PurpleButton: {
    color: "#FFFFFF",
    backgroundColor: "#9051b1",
    borderRadius: "30px",
    // margin: "0 auto",
    width: "100%",
    maxWidth: "238px",
    minHeight: "45px",
    border: "none",
    textTransform: "unset",
    cursor: "pointer",
    fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
    fontWeight: "500",
    padding: "6px 16px",
    lineHeight: "1.75",
    transition:
      "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    boxShadow:
      "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
    "&:hover": {
      backgroundColor: "#B965D3 ",
    },
  },
  WitheButton: {
    color: "#9051b1",
    // borderColor: "#9051b1",
    border: "1px solid #9051b1",
    backgroundColor: "#FFFFFF",
    borderRadius: "30px",
    // margin: "0 auto",
    width: "100%",
    minWidth: "75px",
    maxWidth: "238px",
    minHeight: "45px",
    textTransform: "uppercase",
    cursor: "pointer",
    fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
    fontWeight: "500",
    padding: "6px 16px",
    lineHeight: "1.75",
    transition:
      "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    boxShadow:
      "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
    [theme.breakpoints.up("lg")]: {
      minWidth: "100px",
    },

    "&:hover": {
      backgroundColor: "#B965D330 ",
    },
  },
  link: {
    cursor: "pointer",
    textDecoration: "none",
    // height: "31px",
    // width: "190px",
  },
  backButtonContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.up("sm")]: {
      justifyContent: "flex-start",
    },
  },
  backButton: {
    cursor: "pointer",
    height: "35px",
    // width: "200px",
    backgroundColor: "#FDEFF7",
    color: "#DC5EA6",
    border: "#DC5EA6 1px solid",
    borderRadius: "20px",
    textTransform: "none",
    font: "20px Lato",
    letterSpacing: "0.14px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
  },
  checksContainer: {
    alignItems: "flex-start",
    // height: "20vh",
    overflow: "auto",
    flexWrap: "nowrap",
    gap: "10px",
    boxSizing: "border-box",

    // "@media(min-height:780px)": {
    //   height: "25vh",
    // },
    // "@media(min-height:900px)": {
    //   height: "40vh",
    // },
    // "@media(min-height:1280px)": {
    //   height: "60vh",
    //   gap: "15px",
    // },
    [theme.breakpoints.up("sm")]: {
      paddingTop: "30px",
      paddingBottom: "30px",
    },
    [theme.breakpoints.up("lg")]: {
      "&::-webkit-scrollbar": {
        width: "10px",
      },
      "&::-webkit-scrollbar-track": {
        // backgroundColor: "#84E9DF20",
        backgroundColor: "#7A54BA20",
      },
      "&::-webkit-scrollbar-thumb": {
        // backgroundColor: "#84E9DF",
        backgroundColor: "#7A54BA",
        borderRadius: 5,
      },
    },
  },
  containerButtonLetters: {
    display: "grid",
    gridTemplateColumns: "repeat(5, 1fr)",
    [theme.breakpoints.up("sm")]: {
      gridTemplateColumns: "repeat(10, 1fr)",
      // margin: "0 0 34px 14px",
    },
    [theme.breakpoints.up("lg")]: {
      gridTemplateColumns: "repeat(14, 1fr)",
      // margin: "0 0 34px 14px",
    },
  },
  buttonLetter: {
    border: "1px solid #fbd939",
    backgroundColor: "#fff4d5",
    color: "#92929d",
    cursor: "pointer",
    fontFamily: "Lato",
    fontSize: "20px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "0.17px",
    padding: "7px 0px 7px 0px",
    textTransform: "uppercase",
    [theme.breakpoints.up("xl")]: {
      fontSize: "24px",
    },
  },
  buttonLetterSelected: {
    border: "1px solid #9051b1",
    backgroundColor: "#9051b1",
    color: "#ffffff",
    cursor: "pointer",
    // padding: "0",
    fontFamily: "Lato",
    fontSize: "20px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    padding: "7px 0px 7px 0px",
    letterSpacing: "0.17px",
    textTransform: "uppercase",
    [theme.breakpoints.up("xl")]: {
      fontSize: "24px",
    },
  },
  itemDisabled: {},
}));

const PurpleButton = styled(Button)(({ theme }) => ({
  color: "#FFFFFF",
  backgroundColor: "#9051b1",
  borderRadius: "30px",
  margin: "0 auto",
  marginTop: "auto",
  width: "100%",
  maxWidth: "204px",
  textTransform: "unset",
  "&:hover": {
    backgroundColor: "#B965D3 ",
  },
}));

const letters = [
  "a",
  "b",
  "c",
  "d",
  "e",
  "f",
  "g",
  "h",
  "i",
  "j",
  "k",
  "l",
  "m",
  "n",
  "ñ",
  "o",
  "p",
  "q",
  "r",
  "s",
  "t",
  "u",
  "v",
  "w",
  "x",
  "y",
  "z",
];

const AsignarRemedial = (props) => {
  const { user } = React.useContext(AuthContext);
  const { handleLoading, handleModalMessage } =
    React.useContext(UsabilityContext);
  const { idGrupo, numeroRemedial } = useParams();
  const idActividad = props.location.state.idActividad;

  const [list, setList] = React.useState([]);
  const [selectedList, setSelectedList] = React.useState([]);
  const [filterLetter, setFilterLetter] = React.useState("");

  const classes = useStyles();

  React.useEffect(() => {
    const body = {
      id_actividad: idActividad,
      id_usuario: user.userId,
      id_distribucion_escuela: parseInt(idGrupo),
    };
    handleLoading(true);
    axios
      .post(`/listado/alumnos`, body)
      .then((response) => {
        if (response.status === 200) {
          const studentList = response.data.resultado.alumnos;
          setList(studentList);
          //  dispatch({
          //    type: types.loadActivitiesList,
          //    payload: listaSinRemediales,
          //  });
        }
      })
      .catch((error) => {
        if (error.response) {
          // Request made and server responded
          const errorData = error.response.data;
          const mensaje =
            errorData?.detalles?.msg ||
            "Ha ocurrido un error al consumir los servicios, inténtelo de nuevo más tarde.";
          handleModalMessage({
            habilite: true,
            mensaje: mensaje,
          });
        } else {
          // Something happened in setting up the request that triggered an Error
          const mensaje =
            "Ha ocurrido un error al consumir los servicios, inténtelo de nuevo más tarde.";
          handleModalMessage({
            habilite: true,
            mensaje: mensaje,
          });
        }
      })
      .finally(() => {
        handleLoading(false);
      });

    return () => {};
  }, [idGrupo, user.userId]);

  // React.useEffect(() => {

  //   return () => {};
  // }, []);
  const AssignRemedial = () => {
    const body = {
      id_actividad: idActividad,
      id_inscripcion: selectedList,
    };
    handleLoading(true);
    axios
      .post(`/actividad/remedial/set`, body)
      .then((response) => {
        if (response.status === 200) {
          setSelectedList([]);
          const mensaje = "La actividad fue asignada correctamente.";
          handleModalMessage({
            habilite: true,
            mensaje: mensaje,
          });
        }
      })
      .catch((error) => {
        if (error.response) {
          // Request made and server responded
          const errorData = error.response.data;
          const mensaje =
            errorData?.detalles?.msg ||
            "Ha ocurrido un error al consumir los servicios, inténtelo de nuevo más tarde.";
          handleModalMessage({
            habilite: true,
            mensaje: mensaje,
          });
        } else {
          // Something happened in setting up the request that triggered an Error
          const mensaje =
            "Ha ocurrido un error al consumir los servicios, inténtelo de nuevo más tarde.";
          handleModalMessage({
            habilite: true,
            mensaje: mensaje,
          });
        }
      })
      .finally(() => {
        handleLoading(false);
      });
  };

  return (
    <>
      <ContainerDocente>
        <Grid
          container
          item
          sm={12}
          className={classes.Container}
          alignItems="center"
          justifyContent="center"
        >
          <Grid
            container
            item
            direction="column"
            className={classes.ContainerReportes}
          >
            <Grid
              container
              item
              alignItems="center"
              justifyContent="center"
              className={classes.topContainer}
            >
              <Grid item className={classes.backButtonContainer}>
                <Link className={classes.link} to="/docente/sesiones">
                  <button className={classes.backButton}>
                    <ArrowBackIcon /> Sesiones
                  </button>
                </Link>
              </Grid>
              <Grid
                container
                item
                alignItems="center"
                justifyContent="center"
                className={classes.titleContainer}
              >
                <h2 className={classes.title}>
                  {`Asignar práctica remedial ${numeroRemedial}`}{" "}
                </h2>
              </Grid>
            </Grid>
            <hr className={classes.divisor} />
            <Grid
              container
              direction="row"
              className={classes.containerButtonLetters}
            >
              {letters.map((letter, index) => (
                <button
                  className={
                    filterLetter === letter
                      ? classes.buttonLetterSelected
                      : classes.buttonLetter
                  }
                  key={index}
                  onClick={() => {
                    filterLetter === letter
                      ? setFilterLetter("")
                      : setFilterLetter(letter);
                  }}
                >
                  {letter}
                </button>
              ))}
            </Grid>

            <FormGroup className={classes.checksContainer}>
              {list
                .filter((alumn) =>
                  filterLetter
                    ? alumn?.apellido_paterno
                        ?.toLowerCase()
                        ?.startsWith(filterLetter)
                    : alumn
                )
                .map((alumn, index) => (
                  <AsignarRemedialItem
                    alumn={alumn}
                    setSelectedList={setSelectedList}
                    selectedList={selectedList}
                    key={index}
                  />
                ))}
            </FormGroup>
            <PurpleButton
              onClick={AssignRemedial}
              disabled={selectedList.length < 1}
            >
              Asignar remedial
            </PurpleButton>
          </Grid>
        </Grid>
      </ContainerDocente>
    </>
  );
};

export default AsignarRemedial;
