import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
     Contenedor:{
        background:'#E9FBF9',
        width:'100vw',
        overflowX:'clip',
        [theme.breakpoints.down(1161)]:{
            background:'none',
           overflowX:'unset',
        },
        [theme.breakpoints.down(600)]:{
            marginTop: '79.51px',
        }
    },
  ContainerInfoLectura: {
    position: "relative",
    height: "86px",
    [theme.breakpoints.down(502)]:{
        height: "43px",
    },
    background: "#76D4C3",
    "& .BarraInfoLectura": {
      height: "86px",
      background: `Url(https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Barras/Azul.png)`,
        [theme.breakpoints.down(502)]:{
            height: "43px",
        },
    },
    "& .InfoLectura": {
      position: "absolute",
      height: "86px",
      width: "219px",
      boxSizing: "border-box",
      padding: "17.2px 0",
      background: "#FAFAFA",
      borderRadius: "50px",
      boxShadow: "2px 2px 2px 2px #00000029",
      zIndex: "4",
        [theme.breakpoints.down(502)]:{
            height: "43px",
            width: "109px",
            padding: "8.6px 0",
            borderRadius: "25px",
        },
    },
    "& .InfoLectura:before": {
      position: "absolute",
      display: "flex",
      boxSizing: "border-box",
      padding: "0 0 0 10px",
      justifyContent: "center",
      alignItems: "center",
      height: "39.88px",
      width: "39.88px",
      borderRadius: "50%",
      content: '""',
      boxShadow: "3px 0px .5px 0px #00000029",
      background: "#FAFAFA",
      left: "90px",
      top: "65px",
      transform: "rotate(0.25turn)",
      [theme.breakpoints.down(502)]:{
        left: "45px",
        top: "30px",
        height: "20.88px",
        width: "20.88px",
        },
    },
    "& .InfoLectura:after": {
      content: '""',
      height: "15px",
      width: "15px",
      borderTop: "2px solid #20A698",
      borderLeft: "2px solid #20A698",
      position: "absolute",
      bottom: "-10px",
      left: "Calc(50% - 8px)",
      transform: "rotate(0.625turn)",
      [theme.breakpoints.down(502)]:{
        height: "10px",
        width: "10px",
        left: "Calc(50% - 4.5px)",
        bottom: "-3px",
        borderTop: "1px solid #20A698",
        borderLeft: "1px solid #20A698",
        },
    },
    "& .Sesion": {
      borderLeft: "2px solid #D8D8D8",
    },
    "& .Dato": {
      margin: "0 0 5px 0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#20A698",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        margin: "0 0 2.5px 0",
        },
    },
    "& .Caracter": {
      margin: "0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#000000",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        },
    },   },
    ContenedorLectura:{
        background:'#fff',
        width:'1160px',
        height:'100%',
        padding:'60px 168px 35px ',
        [theme.breakpoints.down(1161)]:{
            width:'502px',
            padding:'60px 0 0 0',
          },
        [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
        '& .ContainerDerechos':{
            bottom:'80px',
            [theme.breakpoints.down(1161)]:{
                display:'none',
            },
        },
        '& .Derechos':{
            textAlign:"center",
            margin:"0px",
            font:" 15px lato",
            color:"#717476",
        },
        '& .Escritorio':{
            display:'block',
            [theme.breakpoints.down(1161)]:{
                display:'none'
            },
        },
        '& .Tablet':{
            display:'none',
            [theme.breakpoints.down(1161)]:{
                display:'block'
            },
        }
    },
    ContainerTitulo:{
        margin:'0 0 34px 0',
        [theme.breakpoints.down(1161)]:{
            margin:'0 0 34px 0',
        },
        '& .Titulo':{
            font:'48px fredoka one',
            letterSpacing: '0px',
            lineHeight:'58px',
            color: '#E1AF10',
            margin:'0',
            textAlign:'center',
            [theme.breakpoints.down(1161)]:{
                font:'43px fredoka one',
                lineHeight:'52px',
            },
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            lineHeight : '30px',
            margin:'0 0 36px 0',
            font:'22px lato',
            color:'#000',
            letterSpacing: '0.20px',
            [theme.breakpoints.down(1161)]:{
                font:'18px lato',
                lineHeight :'28px',
                letterSpacing: '0.17px',
            },
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .SubTitulo':{
            font:'bold 30px lato',
            color:'#0CB5D6',
            letterSpacing:'.28px',
            lineHeight:'30px',
            margin:'0',
            [theme.breakpoints.down(1161)]:{
                lineHeight :'28px',
                font:'bold 26px lato',
                letterSpacing: '0.24px',
            },
            [theme.breakpoints.down(502)]:{
                font:'bold 18px lato',
                lineHeight :'24px',
                letterSpacing: '0.21px',
            },
        },
        '& .TextImg':{
            font:'20px lato',
            color:'#717476',
            letterSpacing:'.19px',
            lineHeight:'30px',
            margin:'24px 0 0 0',
            [theme.breakpoints.down(1161)]:{
                font:' 16px lato',
                lineHeight :'25px',
                letterSpacing: '0.15px',
            },
            [theme.breakpoints.down(502)]:{
                font:' 11px lato',
                lineHeight :'14px',
                letterSpacing: '0.10px',
            },
        },
        '& .TD':{
            background:'#E0CB28',
            height:'58px',
            width:'412px',
            font:'23px Fredoka One',
            lineHeight:'30px',
            letterSpacing:'.22px',
            color:'#FFFFFF',
            textAlign:'center',
            [theme.breakpoints.down(1161)]:{
                width:'250px',
                font:'18px Fredoka One',
                lineHeight:'20px',
                letterSpacing:'.17px',
            },
            [theme.breakpoints.down(502)]:{
                font:'13px Fredoka One',
                lineHeight:'30px',
                letterSpacing:'.12px',
            },
        },
        '& .TD1':{
            borderRadius:'25px 0 0 0',
            borderRight: '1px solid #fff',
        },
        '& .TD2':{
            borderRadius:'0 25px 0 0',
            borderLeft: '1px solid #fff',
            
        },
        '& .Td':{
            background:'#FBF8E9',
            border: '1px solid #e0cb28',
            height:'58px',
            width:'412px',
            font:'20px lato',
            lineHeight:'30px',
            letterSpacing:'.22px',
            color:'#000000',
            textAlign:'center',
            [theme.breakpoints.down(1161)]:{
                font:'18px lato',
                lineHeight:'30px',
                letterSpacing:'.17px',
            },
            [theme.breakpoints.down(502)]:{
                font:'14px lato',
                lineHeight:'30px',
                letterSpacing:'.12px',
            },
        },
        '& .Td1':{
            borderRadius:'0 0 0 25px',
        },
        '& .Td2':{
            borderRadius:'0 0 25px 0',
        },
        '& .Imagen':{
            [theme.breakpoints.down(1161)]:{
                width:'100%',
            },
        },  
      
    }
}));

const Ecología_lingüística_lenguas_en_peligro = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
             <Grid container item justifyContent="center" className={classes.ContainerInfoLectura}>
               <Grid container className="BarraInfoLectura"></Grid>
               <Grid container item className="InfoLectura">
                    <Grid container item direction="column" xs={6} justifyContent="center">
                        <p className="Dato">Grado</p>
                        <p className="Caracter">5</p>
                    </Grid>
                    <Grid container item direction="column" xs={6} justifyContent="center" className="Sesion">
                        <p className="Dato">Sesión</p>
                        <p className="Caracter">32</p>
                    </Grid>
               </Grid>
            </Grid>

            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                        <h2 className="Titulo">Ecología lingüística: <br/>lenguas en peligro</h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                       <p className='Lectura'>
                        A lo largo de la historia, miles de lenguas han muerto, es decir, se han quedado sin 
                        hablantes. En la actualidad, dicha extinción está sucediendo con una rapidez
                        preocupante. Se calcula que más de la mitad de las lenguas minoritarias en el mundo 
                        desaparecerá durante este siglo.<br/><br/> 
                        
                        <h3 className='SubTitulo'>Ecosistemas sociales</h3><br/> 
                        
                        Tal como sucede en la naturaleza, en la sociedad hay ecosistemas frágiles. Las 
                        lenguas viven en un espacio y se ven amenazadas cuando las desplazan de allí. <br/><br/>
                        
                        La <em>ecología lingüística</em> estudia el medio donde habitan los hablantes de una lengua.
                        Esta ciencia utiliza términos como <em>hábitat, coexistencia</em> y <em>lengua en peligro de extinción</em>. 
                        Como ves, son conceptos muy parecidos a los que la ecología usa para tratar la 
                        protección de animales y plantas, pero en este caso se aplican a la diversidad 
                        lingüística. <br/><br/>
                        
                        A veces parece que una lengua dominante amenaza a las minoritarias. Por ejemplo, 
                        alguien podría pensar que el español pone en peligro a las lenguas indígenas 
                        latinoamericanas, por imponerse como la lengua oficial o dominante. Pero no es la 
                        existencia de ciertas lenguas lo que amenaza a las otras: no es la lengua la que decide 
                        imponerse. En realidad, son las prácticas culturales y económicas de una sociedad las 
                        que discriminan a algunas comunidades en beneficio de otras. La mayoría de las 
                        veces esto lo promueven las autoridades, pues obligan a los hablantes a abandonar 
                        sus usos y sus costumbres. <br/><br/>
                        
                        <h3 className='SubTitulo'>Peligro de extinción</h3><br/>
                        
                        Una lengua que se queda sin hablantes es una lengua muerta. En Latinoamérica 
                        existen 248 lenguas en grave peligro de extinción. El <em>Atlas Interactivo UNESCO</em> de las 
                        <em> Lenguas del Mundo en Peligro</em> es una herramienta digital que puedes consultar para 
                        saber cuál es la situación de las lenguas más desfavorecidas de tu país y de tu región. 
                        De acuerdo con dicha fuente, éste es el número de lenguas en situación crítica y en 
                        grave peligro de extinción en cada país de Latinoamérica:
                       </p>                   
                    </Grid>
                    <Grid container justifyContent='center'>
                        <tr className='Tr'>
                            <tr>
                                <td valign="middle" className='TD TD1'>País</td>
                                <td valign="middle" className='TD TD2'><span className='Escritorio'>Lenguas en situación crítica</span> <span className='Tablet'>Lenguas en <br/>situación crítica</span></td>

                            </tr>
                            <tr>
                                <td valign="middle" className="Td">Argentina</td>
                                <td valign="middle" className="Td">6</td>
                            </tr>
                            <tr>
                                <td valign="middle" className="Td">Bolivia</td>
                                <td valign="middle" className="Td">18</td>
                            </tr>
                            <tr>
                                <td valign="middle" className="Td ">Costa Rica</td>
                                <td valign="middle" className="Td ">4</td>
                            </tr>
                            <tr>
                                <td valign="middle" className="Td ">Chile</td>
                                <td valign="middle" className="Td ">4</td>
                            </tr>
                            <tr>
                                <td valign="middle" className="Td ">Colombia</td>
                                <td valign="middle" className="Td ">24</td>
                            </tr>
                            <tr>
                                <td valign="middle" className="Td ">Ecuador</td>
                                <td valign="middle" className="Td ">6</td>
                            </tr>
                            <tr>
                                <td valign="middle" className="Td ">El Salvador</td>
                                <td valign="middle" className="Td ">1</td>
                            </tr>
                            <tr>
                                <td valign="middle" className="Td ">Honduras</td>
                                <td valign="middle" className="Td ">5</td>
                            </tr>
                            <tr>
                                <td valign="middle" className="Td ">Guatemala</td>
                                <td valign="middle" className="Td ">3</td>
                            </tr>
                            <tr>
                                <td valign="middle" className="Td ">México</td>
                                <td valign="middle" className="Td ">53</td>
                            </tr>
                            <tr>
                                <td valign="middle" className="Td ">Nicaragua</td>
                                <td valign="middle" className="Td ">4</td>
                            </tr>
                            <tr>
                                <td valign="middle" className="Td ">Panamá</td>
                                <td valign="middle" className="Td ">2</td>
                            </tr>
                            <tr>
                                <td valign="middle" className="Td ">Paraguay</td>
                                <td valign="middle" className="Td ">6</td>
                            </tr>
                            <tr>
                                <td valign="middle" className="Td ">Perú</td>
                                <td valign="middle" className="Td ">29</td>
                            </tr>
                            <tr>
                                <td valign="middle" className="Td ">Uruguay</td>
                                <td valign="middle" className="Td ">1</td>
                            </tr>
                            <tr>
                                <td valign="middle" className="Td Td1">Venezuela</td>
                                <td valign="middle" className="Td Td2">15</td>
                            </tr>
                        </tr>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container >
                        <p className='Lectura'>
                            Entre todas esas lenguas se encuentran, por ejemplo, el ayapaneco, el kaqchikel y el 
                            zapoteco (de México); el taushiro, el iñapari y el cauqui (de Perú); el baré y el baniva 
                            (de Venezuela); el baure, el maropa y el moré (de Bolivia), y el carabayo, el opón-
                            carare y el pijao (de Colombia).<br/><br/>
                            
                            Para proteger y revitalizar una lengua en peligro es necesario ofrecer a sus hablantes 
                            las mismas oportunidades que tienen quienes hablan una lengua dominante. Una vía 
                            para lograrlo es promover el <em>contacto recíproco</em> entre las lenguas. En otras palabras, si 
                            a los niños que hablan una lengua minoritaria se les enseña español y éste forma 
                            parte de su educación, lo correcto sería que también los niños hispanohablantes de 
                            las comunidades cercanas aprendieran alguna lengua minoritaria que se hable en su 
                            región. Iniciativas como ésta pueden beneficiar a ambas comunidades y generar un 
                            equilibrio en los ecosistemas lingüísticos. <br/><br/>
                            
                            Muchas lenguas minoritarias no cuentan con registros escritos ni tienen sistemas de 
                            escritura. Los especialistas y las comunidades pueden trabajar en conjunto para 
                            desarrollar un sistema que ayude a preservar las lenguas en peligro. También pueden 
                            recolectar textos (orales o escritos) y videos para dejar testimonio de su existencia y 
                            apoyar su revitalización.
                        </p>
                    </Grid>
                    <Grid container justifyContent='center'>
                        <Grid container justifyContent='center'>
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion32/1.png" alt="Tarjeta" />
                        </Grid>
                        <Grid container >
                            <p className="TextImg">
                                El tehuelche era una lengua indígena hablada en la Patagonia chilena y argentina. En 2019 
                                se extinguió tras el fallecimiento de su última hablante nativa, Dora Manchado. Sin embargo, 
                                ella permitió que se documentara su lengua a través de videos didácticos. Ahora el tehuelche 
                                está en proceso de revitalización en la comunidad a la que Dora pertenecía.
                            </p>
                        </Grid>
                    </Grid>
                </Grid>

            <Grid container item xs={12} justifyContent="center" className="ContainerDerechos">
                <p className="Derechos">
                    © 2022. Todos los derechos reservados, Pearson, Circuito de lectores.
                </p>                
            </Grid>
        </Grid>
    </Grid>
    );
}
 
export default Ecología_lingüística_lenguas_en_peligro;
