import React from "react";
import { makeStyles,Grid}   from '@material-ui/core';
import NavBar               from '../../../../NavBar';
import MenuIzquierdo        from '../../../../MenuIzquierdo';
import Activity             from '../../Activity';
import Lectura              from '../../../LecturasActividad/LecturasSexto/Sesion37/Lectura';
import QuestionProv         from "../../QuestionProv";
import Question             from "../../Question";
import QuestionTableImg     from './QuestionTableImg'

import picture1             from './image/CL_G6_B2_S37_CV42_E3_El_precio_de_la_moda_01.png'
import picture2             from './image/CL_G6_B2_S37_CV42_E3_El_precio_de_la_moda_02.png'
import picture3             from './image/CL_G6_B2_S37_CV42_E3_El_precio_de_la_moda_03.png'
import picture4             from './image/CL_G6_B2_S37_CV42_E3_El_precio_de_la_moda_04.png'
import Qpicture1             from './image/CL_G6_B2_S37_CV42_E3_El_precio_de_la_moda_05.png'
import Qpicture2             from './image/CL_G6_B2_S37_CV42_E3_El_precio_de_la_moda_06.png'
import Qpicture3             from './image/CL_G6_B2_S37_CV42_E3_El_precio_de_la_moda_07.png'
import Qpicture4             from './image/CL_G6_B2_S37_CV42_E3_El_precio_de_la_moda_08.png'
import useGetExcercisesData from "../../../../../hooks/useGetExcercisesData";

const useStyles = makeStyles(theme=>({
  grid1:{    
    zIndex:'3'
    
  },
  grid2:{    
    zIndex:'2'
    
  },
  grid3:{    
    boxSizing:'border-box',
    zIndex:'1',
    paddingLeft:'3%',
    [theme.breakpoints.down('md')]:{
      paddingLeft:'0px',
    }
    
  }
}))

const ActivityS37 = (props) => {
  const classes = useStyles()
  const activityId = 353;
  const { data, step, setStep, questionList, setQuestionList } =
    useGetExcercisesData(activityId);

  const imageList = {
    1548: picture1,
    1549: picture2,
    1550: picture3,
    1551: picture4,
  }

  const picturesTable = [
    Qpicture1,
    Qpicture2,
    Qpicture3,
    Qpicture4

  ]

  const renderQuestion = () => {
    return(
      step === 9 ?
      <QuestionProv
      pregunta={ questionList.length > 0 ? questionList[step].pregunta : "cargando..."}
      step={step}/>
    :
      step === 7 ?
      <QuestionTableImg
        picturesTable={picturesTable}
      />
      :
      <Question
        step={step}
        pregunta={ questionList.length > 0 ? questionList[step].pregunta : "cargando..."}
      />
    )
  }

  return(
    <Grid container >
      <Grid item className={classes.grid1} sm={12}> 
        <NavBar/>
      </Grid>
      <Grid item className={classes.grid2} sm={1}> 
        <MenuIzquierdo/>
      </Grid>
      <Grid item className={classes.grid3} sm={12}>
        <Activity
          step={step}
          setStep={setStep}
          questionList={questionList}
          setQuestionList={setQuestionList}
          questionComponent={renderQuestion()}
          Lectura={Lectura}
          imageList={imageList}
          picturesTable={picturesTable}
          activityNumber={activityId}
          enrollmendId={data && data.id_actividad_inscripcion}
          tryNumber={data && data.intento}
          toSession={'/session37'}
        />
      </Grid>
    </Grid>
  )
}

export default ActivityS37;
