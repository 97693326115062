import { makeStyles, Grid, Button } from "@material-ui/core";
import React, { useContext, useState } from "react";
import SendIcon from "@mui/icons-material/Send";

import AuthContext from "../../../auth/AuthContext";
import ChatContext from "./Context/ChatContext";

import axios from "../../../services/axios";

import Loader from "../../Loader/Loader";
import ModalError from "../../Modals/ModalError";
import ModalErrorDetails from "../../Modals/ModalErrorDetails";
import ModalMessage from "../../Modals/ModalMessage";
import ChatDropZoneButton from "./ChatDropZoneButton";

const useStyles = makeStyles((theme) => ({
  inputContainer: {
    gridArea: "input",
    background: "transparent",
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    alignContent: "center",
    [theme.breakpoints.up("lg")]: {
      justifyContent: "center",
      padding: "10px",
    },
    [theme.breakpoints.up("xl")]: {
      padding: "15px",
    },
  },
  containerInputMessage: {
    display: "flex",
    flexWrap: "nowrap",
    flexDirection: "row",
    alignContent: "center",
    justifyContent: "center",
    alignItems: "center",
    boxSizing:"border-box",
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    boxShadow: "0px 3px 6px #00000029",
    border: "1px solid #00000000",
    width: "100%",
    [theme.breakpoints.up("lg")]: {
      boxShadow: "0px 3px 6px #00000029",
      borderRadius: "38px",
      border: "1px solid #00000000",
      width: "75%",
    },
  },
  inputMessage: {
    outline: "none",
    width: "100%",
    margin: "15px 0",
    padding: "0 16px",
    border: "none",
    borderLeft: "3px solid #FFBB1C",
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    textAlign: "left",
    letterSpacing: "0.21px",
    color: "#575757",
    minHeight: "35px",
    // margin: " 0 10%",
    [theme.breakpoints.up("lg")]: {
      height: "auto",
      padding: "0 32px",
      // margin: "10px 0",
    },
  },
  fileIconButton: {
    height: "80%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "6px 16px",
    cursor: "pointer",

    [theme.breakpoints.down("sm")]: {
      padding: "8px",
      minWidth: "20px",
    },
    "&:hover": {
      textDecoration: "none",
      backgroundColor: "rgba(0, 0, 0, 0.04)",
    },
  },
  fileIconButtonLeft: {
    height: "80%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "6px 16px",
    cursor: "pointer",
    borderRadius: "38px",
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    [theme.breakpoints.down("sm")]: {
      padding: "8px",
      minWidth: "20px",
      borderRadius: "0",
    },
    "&:hover": {
      textDecoration: "none",
      backgroundColor: "rgba(0, 0, 0, 0.04)",
    },
  },
  fileIcon: {
    fontSize: "14px",
    color: "#1DA698",
    [theme.breakpoints.up("lg")]: {
      fontSize: "22px",
    },
  },
  sendIcon: {
    fontSize: "22px",
    height: "100%",
    color: "#7A54BA",
    marginLeft: "0px",
    [theme.breakpoints.up("lg")]: {
      marginLeft: "10px",
      color: "#FFFFFF",
    },
  },
  sendButton: {
    height: "100%",
    color: "#7A54BA",
    textTransform: "none",
    display: "flex",

    [theme.breakpoints.up("lg")]: {
      color: "#fff",
      background: "#7A54BA 0% 0% no-repeat padding-box",
      borderRadius: "38px",
      padding: "0 35px",
    },
    "& span": {
      fontSize: "0",
      [theme.breakpoints.up("lg")]: {
        fontSize: "16px",
        // padding: "0 70px",
      },
    },
  },
}));

const InputSendMessage = ({ handleSendMessage, sala }) => {
  const classes = useStyles();
  const [text, setText] = useState("");
  const { sendImage, sendDocument } = useContext(ChatContext);
  const [modalMessageOpen, setModalMessageOpen] = useState(false);
  const [confirmChangesResult, setConfirmChangesResult] = useState("");
  const [modalErrorDetailsOpen, setModalErrorDetailsOpen] = useState(false);
  const [resultError, setResultError] = useState("");
  const [modalErrorOpen, setModalErrorOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const limitInMb = 3;
  const handleCloseMessageModal = () => {
    setModalMessageOpen(!modalMessageOpen);
  };
  const handleCloseErrorModal = () => {
    setModalErrorOpen(!modalErrorOpen);
  };
  const handleCloseErrorDetailsModal = () => {
    setModalErrorOpen(false);
    setModalErrorDetailsOpen(!modalErrorDetailsOpen);
  };

  const handleText = (e) => {
    setText(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (text) {
      // sendMessage(newMessage);
      handleSendMessage(text);
      setText("");
    }
  };

  const formatBytes = (bytes) => {
    const limitInKb = limitInMb * 1000000;
    return bytes < limitInKb;
  };

  const handleSendDocument = (fileInBase64, type, sala, nombreArchivo) => {
    setLoading(true);
    const body = {
      id_usuario: user.userId,
      document: fileInBase64,
      tipo: type,
      nombreArchivo,
    };
    if (fileInBase64) {
      axios
        .post(`/profesor/upload/document`, body)
        // .get(`listado/sesiones/listado?id_usuario=${user.userId}&id_distribucion_escuela=${idGrupo?.id_distribucion_escuela}`)
        .then((response) => {
          if (response.status === 200) {
            const newDocument = response.data.resultado.path;
            const setMessage = {
              nombre_usuario: user.name,
              mensaje: newDocument,
              sala: sala,
              tipo: "document",
              id_usuario: user.userId,
            };
            sendDocument(setMessage);
          }
        })
        .catch((error) => {
          console.log(error, "ERRORS");
          // dispatch({
          //   type: types.loadActivitiesList,
          //   payload: [],
          // });
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };
  const handleSendImage = (fileInBase64, sala) => {
    setLoading(true);
    const body = {
      id_usuario: user.userId,
      image: fileInBase64,
    };
    if (fileInBase64) {
      axios
        .post(`/profesor/analiza/imagen`, body)
        // .get(`listado/sesiones/listado?id_usuario=${user.userId}&id_distribucion_escuela=${idGrupo?.id_distribucion_escuela}`)
        .then((response) => {
          if (response.status === 200) {
            const newImage = response.data.resultado.path;
            const setMessage = {
              nombre_usuario: user.name,
              mensaje: newImage,
              sala: sala,
              tipo: "image",
              id_usuario: user.userId,
            };
            sendImage(setMessage);
          }
        })
        .catch((err) => {
          if (err.response) {
            const errorCode = err?.response?.data?.detalles?.error;
            if (errorCode === "00099") {
              setModalErrorOpen(true);
              setResultError("content");
            }
          } else {
            setModalMessageOpen(true);
            setConfirmChangesResult(
              "Ha ocurrido un error inesperado, por favor Intente de nuevo más tarde"
            );
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const handleDocument = (file) => {
    if (file) {
      if (formatBytes(file?.size)) {
        if (file?.type?.includes("image")) {
          handleSendImage(file?.base64, sala);
        } else if (file?.type?.includes("pdf")) {
          // console.log(fileToBase64, format);
          handleSendDocument(file?.base64, "pdf", sala, file?.name);
        } else if (
          file?.type?.includes("word" || file?.type?.includes("office"))
        ) {
          handleSendDocument(file?.base64, "docx", sala, file?.name);
          // console.log(fileToBase64, format);
        }
      } else {
        setModalMessageOpen(true);
        setConfirmChangesResult(
          `El archivo seleccionado tiene que pesar menos de ${limitInMb} Mb.`
        );
      }
    } else {
      setModalMessageOpen(true);
      setConfirmChangesResult(
        `Asegúrese de que el archivo seleccionado tenga un formato jpg/png, doc/dox.`
      );
    }
  };

  // const handleArchivos = async (e) => {
  //   const fileList = e.target?.files;
  //   const fileSelected = fileList[0];
  //   const nameFile = fileSelected.name.split(".")[0];
  //   if (fileSelected) {
  //     if (formatBytes(fileSelected?.size)) {
  //       const base64 = await convertBase64(fileSelected);
  //       const [format, fileToBase64] = await base64.split(",");
  //       if (format.includes("image")) {
  //         handleSendImage(fileToBase64, sala);
  //         setValueInputImage([]);
  //       } else if (format.includes("pdf")) {
  //         // console.log(fileToBase64, format);
  //         handleSendDocument(fileToBase64, "pdf", sala, nameFile);
  //         setValueInputDocument([]);
  //       } else if (format.includes("word" || format.includes("office"))) {
  //         handleSendDocument(fileToBase64, "docx", sala, nameFile);
  //         setValueInputDocument([]);
  //         // console.log(fileToBase64, format);
  //       }
  //       // await sendImage(fileToBase64);
  //       // await sendImage(fileToBase64);
  //     } else {
  //       setModalMessageOpen(true);
  //       setConfirmChangesResult(
  //         `El archivo seleccionado tiene que pesar menos de ${limitInMb} Mb.`
  //       );
  //     }
  //   } else {
  //     setModalMessageOpen(true);
  //     setConfirmChangesResult(
  //       `Asegúrese de que el archivo seleccionado tenga un formato jpg/png, doc/dox.`
  //     );
  //     setValueInputDocument([]);
  //     setValueInputImage([]);
  //   }
  // };

  const { user } = React.useContext(AuthContext);

  return (
    <Grid container item direction="column" className={classes.inputContainer}>
      <form className={classes.containerInputMessage} onSubmit={handleSubmit}>
        <ChatDropZoneButton
          type={".png, .jpeg, .jpeg"}
          icon="image"
          handleDocument={handleDocument}
        />
        <ChatDropZoneButton
          type={".pdf, .doc, .docx"}
          icon="document"
          handleDocument={handleDocument}
        />
        <input
          className={classes.inputMessage}
          type="text"
          placeholder="Tu mensaje"
          onChange={handleText}
          value={text}
        />
        <Button type="submit" className={classes.sendButton}>
          Enviar
          <SendIcon className={classes.sendIcon} />
        </Button>
      </form>
      <ModalError
        open={modalErrorOpen}
        handleClose={handleCloseErrorModal}
        type={resultError}
        handleNext={handleCloseErrorDetailsModal}
      />
      <ModalErrorDetails
        open={modalErrorDetailsOpen}
        handleClose={handleCloseErrorDetailsModal}
      />
      <ModalMessage
        open={modalMessageOpen}
        handleClose={handleCloseMessageModal}
        title={confirmChangesResult}
      />
      {loading && <Loader />}
    </Grid>
  );
};

export default InputSendMessage;
