import { makeStyles } from "@material-ui/core";
import React, { useContext } from "react";
import DrawerContext from "../../../../usability/DrawerContext";

const useStyles = makeStyles({
  containerDrawerTools: {
    zIndex: 2,
    boxShadow: "0px 3px 4px #0000001A",

    // height: "10%",
    // paddingLeft: "4%",
    background: "#fff",
    // height: "90px",
    position: "absolute",
    top: "-300px",
    left: "20px",
    width: "25px",
    height: "250px",
    backgroundColor: "#FFFCFC",
    borderTopLeftRadius: "10px",
    borderTopRightRadius: "10px",
    border: "1px solid #EFEFEF",
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
    alignContent: "center",
    justifyContent: "space-evenly",
    padding: "20px",
    // "&:before": {
    //   // display: (props) => (props.openMenuInDesktop ? "none" : "flex"),
    //   display: "flex",
    //   zIndex: 1,
    //   textTransform: "none",
    //   justifyContent: "center",
    //   alignItems: "center",
    //   // borderRadius: "25px",
    //   position: "absolute",
    //   content: '""',
    //   height: "16px",
    //   // width: "80px",
    //   // background: (props) => (props.color ? `${props.color} 0% 0% no-repeat padding-box` : "#61728C"),
    //   border: "20px solid",
    //   borderTopColor: "#fff",
    //   borderLeftColor: "transparent",
    //   borderBottomColor: "transparent",
    //   borderRightColor: "transparent",
    //   font: "bold 14px lato",
    //   // border: "25px solid white",

    //   // position: "absolute",
    //   bottom: "-40px",
    //   left: "calc(50% - 20px)",
    // },
  },
  button: {
    width: "100%",
    backgroundColor: "transparent",
    justifyContent: "center",
    border: "none",
    borderBottom: "1.38px solid #EFEFEF",
    display: "flex",

    alignItems: "center",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#8AE9DF50",
    },
  },

  containerButtonColors: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    alignContent: "center",
    justifyContent: "space-evenly",
    gap: "10px",
    width: "100%",
    height: "100%",
  },
  buttonColor: {
    width: "10px",
    height: "26px",
    borderRadius: "1px",
    border: "none",
    outline: "none",
    cursor: "pointer",
  },

  buttonToolBar: {
    margin: "0 20px 0 20px",
    width: "54px",
    height: "54px",
    borderRadius: "15px",
    border: "#EFEFEF 3px solid",
    "&:hover": {
      background: "#8AE9DF",
      border: "#F1CB5A 3px solid",
    },
  },

  Nota: {
    "&:hover": {
      "&:before": {
        textTransform: "none",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "absolute",
        content: '"Agregar notas"',
        textAlign: "center",
        bottom: "60px",
        width: "80px",
        background: "#fff",
        font: "bold 16px lato",
      },
    },
  },
});
const MarkTools = () => {
  const {
    handleMarkerColor,

    openMarkTools,
    handleHabiliteMarker,
    handleMarkToolsOpen,
  } = useContext(DrawerContext);
  const classes = useStyles();
  const colors = ["#A0E9FF", "#D9AAE6", "#F5A8C6", "#F8DCA9", "#AEEADF"];

  return (
    <>
      {openMarkTools && (
        <div className={classes.containerDrawerTools}>
          {/* <select name="" id="" onChange={handleBrushWidth}>
        <option value="5">5</option>
        <option value="10">10</option>
        <option value="20">20</option>
      </select> */}

          <div className={classes.containerButtonColors}>
            {colors.map((color, index) => (
              <button
                onClick={() => {
                  handleMarkerColor(`${color}60`);
                  handleMarkToolsOpen();
                  handleHabiliteMarker();
                }}
                style={{ backgroundColor: color }}
                className={classes.buttonColor}
                key={index}
              ></button>
            ))}
          </div>
          {/* <input type="color" onChange={handleColor} /> */}
          {/* <button onClick={handleCursor}>Cambiar cursor</button> */}
        </div>
      )}
    </>
  );
};

export default MarkTools;
