import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
    ContenedorLectura:{
        width:'502px',
                  [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
    },
    ContainerTitulo:{
        margin:'0 0 34px 0',
        '& .Titulo':{
            letterSpacing: '0px',
            color: '#FF0000',
            margin:'0',
            textAlign:'center',
            font:'43px fredoka one',
            lineHeight:'52px',
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            margin:'0 0 36px 0',
            color:'#000',
            font:'18px lato',
            lineHeight :'28px',
            letterSpacing: '0.17px',
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .Imagen':{
            width:'100%',
        },  

      
    }
}));

const El_juego_de_la_honestidads = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >
                        El juego de la honestidad
                    </h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                      <p className="Lectura">
                        Sofía pasó esa tarde junto a su hermano Ulises y su prima Bibiana, que estaba de 
                        visita.<br/><br/> 
                        
                        Dibujaron y jugaron con los muñecos de Bibiana. Y también a lanzar un disco afuera 
                        de su casa.
                      </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img2do/Sesion51/1.png" alt="Tarjeta" />
                    </Grid>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                      <p className="Lectura">
                        En su turno, Ulises lanzó el disco y rompió sin querer una ventana de la casa. Al 
                        advertir que su madre no se dio cuenta, siguieron jugando… y acordaron no decirle 
                        nada. <br/><br/> 
                        
                        Cuando Bibiana se fue, la madre de Sofía y Ulises encontró los trozos de vidrio y 
                        preguntó quién había sido. Ulises respondió: <br/><br/> 
                        
                        —No fui yo.<br/><br/> 

                        —Yo tampoco —añadió Sofía.
                      </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img2do/Sesion51/2.png" alt="Tarjeta" />
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                      <p className="Lectura">
                        Su madre los miró seriamente y dijo: <br/><br/> 

                        —Díganme la verdad. ¿Quién rompió la ventana? <br/><br/> 

                        —Fue Bibiana —mintió Sofía para proteger a su hermano. <br/><br/> 

                        —Sí, fue ella —reafirmó Ulises. <br/><br/> 
                        
                        —Bueno, llamaré a casa de Bibiana para que su madre se entere y haga algo al 
                        respecto. <br/><br/> 
                        
                        Entonces, Ulises se puso nervioso y dijo: <br/><br/> 
                        
                        —La rompí yo, mamá. Ésa es la verdad. <br/><br/> 
                        
                        Sofía volteó a mirarlo, asombrada por su valentía. Pensó que su mamá los regañaría, 
                        o los castigaría. <br/><br/> 
                        
                        —Gracias por ser honesto, Ulises —le dijo con ternura, para sorpresa de ambos. <br/><br/> 
                        
                        —¿Qué significa <em>ser honesto</em>, mamá? —preguntó Sofía. <br/><br/> 
                        
                        —Significa decir la verdad, no engañar a otros con acciones ni con palabras. <br/><br/> 
                        
                        —¿Incluso si creemos que nos castigarán? —preguntó Ulises.<br/><br/> 
                        
                        —Exacto —respondió su mamá—. La honestidad ayuda a generar confianza y aprecio 
                        entre las personas, y nos volvemos empáticas al escuchar las razones. Por ejemplo,
                        romper un vidrio no debería ser motivo de castigo, sobre todo porque sé que no lo 
                        hiciste a propósito y que se estaban divirtiendo. Propongo que entre los tres 
                        limpiemos y arreglemos la ventana. <br/><br/> 
                        
                        Los hermanos aceptaron.<br/><br/> 
                        
                        —Ulises, ¿qué te parece si la próxima vez que venga Bibiana jugamos a ser honestos, 
                        decimos la verdad siempre y le explicamos qué es la honestidad? —propuso Sofía, 
                        conmovida por lo que sucedió. <br/><br/> 
                        
                        —Tengo otra idea: ¿qué tal si nosotros jugamos a ser honestos todo el tiempo? Así, se 
                        nos hará una bonita costumbre. <br/><br/> 
                        
                        Desde entonces, en esa casa hay un pacto permanente de honestidad.
                      </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img2do/Sesion51/3.png" alt="Tarjeta" />
                    </Grid>
                </Grid>
        </Grid>
    </Grid>
    );
}
 
export default El_juego_de_la_honestidads ;
