import React               from "react";
import {Grid, makeStyles}                 from '@material-ui/core';

const useStyles = makeStyles(theme =>({
  questionContainer:{
    width: "100%",
    height:"100%",
  },
  reading: {
    width: "90%",
    margin:"10px 0 10px 0 ",
    font: '22px lato',
    letterSpacing: '0.21px',
    [theme.breakpoints.down(1400)]:{
      font: " 18px lato",
      width: "95%",
    },
  },
  imgStyle: {
    alignItems: "center",
    width:'50%',
    height:'50%',
    // [theme.breakpoints.down(1400)]:{
    //   width:'85%px',
    //   height:'85%px',
    // },
  },
  table: {
    // border: '1px solid black',
    width: "100%",
    font: '22px lato',
    [theme.breakpoints.down(1400)]:{
      font: " 18px lato",
    },
  },
  tdTable: {
    padding: "0 1%",
    // border: '1px solid black',
  }
}));

const Question = (props) => {
  const classes = useStyles()
  const { picture1 } = props


  return(
    <Grid container justifyContent="center" alignItems="center" className={classes.questionContainer}>
      <p 
        // dangerouslySetInnerHTML={{__html: `${step+1}. ${pregunta.replace( '\n', '<br/><br/>')}`}}
        className={classes.reading}
      >
        7. Elige la opción que describe la imagen de acuerdo con el texto.
      </p>
      <br/>
 
       <img alt="img" src={picture1} className={classes.imgStyle}/>

    </Grid>
  )

}

export default Question;
