import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
    ContenedorLectura:{
        width:'502px',
                  [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
    },
    ContainerTitulo:{
        margin:'0 0 34px 0',
        '& .Titulo':{
            letterSpacing: '0px',
            color: '#823CE2',
            margin:'0',
            textAlign:'center',
            font:'43px fredoka one',
            lineHeight:'52px',
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            margin:'0 0 36px 0',
            color:'#000',
            font:'18px lato',
            lineHeight :'28px',
            letterSpacing: '0.17px',
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .Lectura2':{
           margin:'0',
        },
        '& .SubTitulo':{
            color:'#0093FF',
            margin:'0',
            lineHeight :'28px',
            font:'bold 26px lato',
            letterSpacing: '0.24px',
            [theme.breakpoints.down(502)]:{
                font:'bold 18px lato',
                lineHeight :'24px',
                letterSpacing: '0.21px',
            },
        },
        '& .SubTitulo2':{
            color:'#662D91',
            lineHeight :'30px',
            font:'bold 30px lato',
            letterSpacing: '0.28px',
            margin:'0 0 0 14%',
            [theme.breakpoints.down(502)]:{
                font:'bold 26px lato',
                lineHeight :'24px',
                letterSpacing: '0.24px',
            },
        },
        '& .Cuadro1':{
            position:'relative',
            margin:'0 0 13px 0',
        },
        '& .Pos':{
            position:'absolute',
            left:'30px',
        },
        '& .Pos2':{
            position:'absolute',
            left:'30px',
        },
        '& .TextCuadro':{
            margin:'14px 0 0 34px',
            [theme.breakpoints.down(502)]:{
                margin:'2.5px 0 0 14px',

            },
        },
        
        '& .ul':{
            listStyle:'alphanumeric',
            margin:'0',
            color:'#823CE2',
            padding:'0 0 0 19px',
            font:'18px lato',
            lineHeight :'28px',
            letterSpacing: '0.17px',
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .li':{
            margin:'0 0 8px 0',

        },
        '& .Imagen':{
            width:'75%',
        },
        '& .Imagen2':{
            width:'100%',
        },

    }
}));

const La_solución_es_un_árbol = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>

            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                        <h2 className="Titulo ">¡La solución es un árbol!</h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">

                    <Grid container sm={12}>
                       <p className='Lectura'>
                        <h3 className='SubTitulo'>Gráficas, árboles y ciclos</h3><br/>
                        
                        Las gráficas son herramientas con las que podemos representar muchas 
                        situaciones de la vida diaria. Una <em>gráfica</em> es un conjunto de vértices 
                        (puntos) que se unen por medio de aristas (líneas). Llamamos <em>árbol</em> a 
                        una clase especial de gráfica en la que todos los vértices están unidos 
                        por al menos una arista, pero sin formar figuras cerradas. En la imagen 1 
                        puedes ver un ejemplo.
                       </p>

                    </Grid>
                    <Grid container >
                      <h3 className='SubTitulo2'>
                        Árbol
                      </h3>
                    </Grid>
                    <Grid container justifyContent='center'>
                        <img className='Imagen' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion8/5.png" alt="Tarjeta" />
                    </Grid>
                    <Grid container sm={12}>
                       <p className='Lectura'>
                        <br/>
                            Si agregaras otra arista a este árbol, entre dos vértices que aún no estén unidos 
                            entre sí, se formaría una figura cerrada a la que llamamos <em>ciclo</em>. Cuando una gráfica 
                            contiene un ciclo, deja de ser un árbol. La imagen 2 representa en rojo la nueva arista. 
                            En la imagen 3, el ciclo nuevo está representado en morado.
                       </p>
                    </Grid>

                    <Grid container justifyContent='center'>
                        <img className='Imagen2' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion8/4.png" alt="Tarjeta" />
                    </Grid>

                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container >
                        <p className='Lectura'>
                            <h3 className='SubTitulo'>Ciudades y carreteras</h3><br/>

                            ¿Qué problemas podríamos solucionar con árboles? Piensa que 
                            los vértices representan ciudades y las aristas carreteras 
                            que las conectan.
                        </p>
                    </Grid>

                    <Grid container className='Cuadro1'>
                        <img className='Imagen2 ' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion8/1R.png" alt="Tarjeta" />

                        <p className='Pos Lectura TextCuadro'>
                            Hay que construir sólo las carreteras necesarias para conectar todas las ciudades. Eso significa que la red de carreteras debe ser un árbol.
                        </p>
                    </Grid>

                    <Grid container className='Cuadro1'>
                        <img className='Imagen2 ' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion8/6R.png" alt="Tarjeta" />

                        <p className='Pos2 Lectura TextCuadro'>
                            El costo total de construcción debe ser el mínimo.                        
                        </p>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container sm={12}>
                        <p className='Lectura'>
                            Para lograr ambos objetivos hay un algoritmo muy sencillo. En la siguiente gráfica 
                            tenemos representadas a las ciudades A, B, C, D, E, F y G, que son los vértices 
                            del árbol. Hemos dibujado todas las carreteras posibles entre esas ciudades. 
                            Algunas ciudades (como A y C) no están conectadas, porque hay algo que impide 
                            que se construya una carretera entre ellas, quizá una montaña o un lago. <br/><br/>
                            
                            Los números junto a cada arista nos indican cuántos millones de pesos costaría 
                            construir esa carretera. Por ejemplo, se necesitarían 19 millones para construir 
                            una carretera entre B y D.
                        </p>
                    </Grid>
                    <Grid container justifyContent='center'>
                        <img className="Imagen2" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion8/3.png" alt="Tarjeta" />
                    </Grid>

                </Grid>
                

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container >
                        <p className='Lectura'>
                            ¡Comencemos con nuestro procedimiento!
                        </p>
                        
                        <ul className='ul'>
                        <li className='li'>
                            <p className='Lectura Lectura2'>
                                Elijamos la arista con menor valor. En este caso hay dos que valen 10: A-D y C-E. Tomemos cualquiera de ellas y resaltémosla: ésta será parte del árbol.
                            </p>
                        </li>
                        <li className='li'>
                            <p className='Lectura Lectura2'>
                                Continuemos con la arista de menor valor que no esté en el árbol, cuidando que no se forme un ciclo. En este caso es la otra arista de valor 10.
                            </p>
                        </li>
                         <li className='li'>
                            <p className='Lectura Lectura2'>
                                Busquemos la siguiente arista de menor valor que no hemos incluido en el árbol y agreguémosla. En este caso es la de valor 12: D-F.
                            </p>
                        </li>
                        <li className='li'>
                            <p className='Lectura Lectura2'>
                            Nuevamente, busquemos la arista de menor valor que aún no esté en el árbol. En este caso hay dos con valor 14. Elijamos una.
                            </p>
                        </li>
                        <li className='li'>
                            <p className='Lectura Lectura2'>
                            Repitamos y agreguemos la otra arista de valor 14.
                            </p>
                        </li>
                        <li className='li'>
                            <p className='Lectura Lectura2'>
                            ¡Cuidado! Siguiendo el mismo procedimiento deberíamos agregar al árbol una de las dos aristas que tienen valor 15, pero no podemos. Si agregáramos la arista B-C, formaríamos un ciclo entre las ciudades BCE, mientras que con la arista a F-E crearíamos un ciclo entre las ciudades ABEFD. ¡Y ya no tendríamos un árbol!
                            </p>
                        </li>
                        <li className='li'>
                            <p className='Lectura Lectura2'>
                            Toca, entonces, el turno a una de las aristas con valor 19. Aunque hay dos, una forma un ciclo y la otra no. Entonces, agreguemos la que une las ciudades E y G. ¡Hemos terminado!
                            </p>
                        </li>
                        </ul>
                    </Grid>
                    <Grid container justifyContent='center' >
                        <img className='Imagen2' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion8/2.png" alt="Tarjeta" />
                    </Grid>

                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container >
                        <p className='Lectura'>
                            Observa que la carretera pasa por todas las ciudades y que hemos evitado los 
                            ciclos en el árbol. Su costo es 10+10+12+14+14+19 = 79. Te invitamos a comprobar 
                            que cualquier otro árbol que pueda formarse en esa misma gráfica tendrá un costo mayor.<br/><br/>

                            <h3 className='SubTitulo'>El peso de los árboles</h3><br/>

                            Si una gráfica tiene valores numéricos, es una <em>gráfica ponderada</em>. En latín, 
                            <em> ponderare</em> significa “pesar” o “medir”. En una gráfica ponderada, 
                            cada arista tiene un peso o una medida, es decir, un valor numérico. Hay ocasiones 
                            en las que preferimos tener un árbol con el menor peso posible, 
                            donde la suma de los valores sea tan pequeña como se pueda. Eso sucedía en 
                            ejemplo anterior. <br/><br/>
                            
                            El procedimiento que usamos para encontrar el árbol con menor peso posible se 
                            llama <em>algoritmo de Kruskal</em> y se usa en el diseño de redes de telecomunicación 
                            (aunque el costo no siempre es lo que está en juego). ¿Te imaginas qué otros
                            factores pueden graficarse con árboles? ¡Te invitamos a investigarlo!
                        </p>
                    </Grid>
                </Grid>
        </Grid>
    </Grid>
    );
}
 
export default La_solución_es_un_árbol;
