import React               from "react";
import {Grid, makeStyles}                 from '@material-ui/core';

const useStyles = makeStyles(theme =>({
  questionContainer:{
    width: "100%",
    height:"100%",
  },
  reading: {
    width: "90%",
    margin:"10px 0 10px 0 ",
    font: '22px lato',
    letterSpacing: '0.21px',
    [theme.breakpoints.down(1400)]:{
      font: " 18px lato",
      width: "95%",
    },
    [theme.breakpoints.down(1310)]:{
      font: "500 16px lato",
      // width: "80%",
      margin:"10px 0 10px 0"
    },
    [theme.breakpoints.down(925)]:{
      font: "500 14px lato",
      // width: "70%",
      margin:"6px 0 6px 0"
    },
    [theme.breakpoints.down(426)]:{
      font: "500 12px lato",
      // width: "70%",
      margin:"6px 0 6px 5%"
    }, 
  },
  table: {
    width: "100%",
    font: '22px lato',
    [theme.breakpoints.down(1400)]:{
      font: " 18px lato",
      width: "95%",
    },
    [theme.breakpoints.down(1310)]:{
      font: "500 16px lato",
      // width: "80%",
      margin:"10px 0 10px 0"
    },
    [theme.breakpoints.down(925)]:{
      font: "500 14px lato",
      // width: "70%",
      margin:"6px 0 6px 0"
    },
    [theme.breakpoints.down(426)]:{
      font: "500 12px lato",
      // width: "70%",
      margin:"6px 0 6px 5%"
    }, 
  },
  tdTable: {
    padding: "0 1%",
    // border: '1px solid black',
  }
}));

const QuestionTable = (props) => {
  const classes = useStyles()
  const { picturesTable } = props


  return(
    <Grid container className={classes.questionContainer}>
      <p
        className={classes.reading}
      >
        1. Relaciona a los científicos famosos con sus descubrimientos más sobresalientes.  
      </p>
      <br/>
      <table className={classes.table}>
        <thead>
          <th style={{width: "50%", textAlign: "center"}}>Científicos famosos</th>
          <th style={{width: "50%", textAlign: "center"}}>Descubrimientos científicos</th>
        </thead>
        <tbody>
          <tr>
            <td className={classes.tdTable}>
              <p>
                1. Galileo Galilei
              </p>
            </td>
            <td className={classes.tdTable}>
              <p>
                a. Descubrió los elementos radiactivos, investigó el radio y sus compuestos.
              </p>
            </td>
          </tr>
          <tr>
            <td className={classes.tdTable}>
              <p>
                2. Albert Einstein
              </p>
            </td>
            <td className={classes.tdTable}>
              <p>
                b. Creó las Leyes de Newton.
              </p>
            </td>
          </tr>
          <tr>
            <td className={classes.tdTable}>
              <p>
                3. Marie Curie
              </p>
            </td>
            <td className={classes.tdTable}>
              <p>
                c. Planteó la teoría de la relatividad espacial.
              </p>
            </td>
          </tr>
          <tr>
            <td className={classes.tdTable}>
              <p>
                4. Isaac Newton
              </p>
            </td>
            <td className={classes.tdTable}>
              <p>
                d. Mejoró el telescopio y descubrió la dinámica y la cinemática.
              </p>
            </td>
          </tr>
        </tbody>
      </table>
    </Grid>
  )
}

export default QuestionTable;
