import { Button, Grid, makeStyles } from "@material-ui/core";
import React, { useContext, useEffect } from "react";
import DrawerContext from "../../../../../usability/DrawerContext";
import { useOnLoadImages } from "../../../../hooks/useOnLoadImages";
import Loader from "../../../../Loader/Loader";
import Drawer from "../../ReadingTools/Drawer";

const useStyles =  makeStyles (theme =>({
     Contenedor:{
        background:'#E9FBF9',
        width:'100vw',
        overflowX:'clip',
        [theme.breakpoints.down(1161)]:{
            background:'none',
           overflowX:'unset',
        },
        [theme.breakpoints.down(600)]:{
            marginTop: '79.51px',
        }
    },
  ContainerInfoLectura: {
    position: "relative",
    height: "86px",
    [theme.breakpoints.down(502)]:{
        height: "43px",
    },
    background: "#76D4C3",
    "& .BarraInfoLectura": {
      height: "86px",
      background: `Url(https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Barras/Azul.png)`,
        [theme.breakpoints.down(502)]:{
            height: "43px",
        },
    },
    "& .InfoLectura": {
      position: "absolute",
      height: "86px",
      width: "219px",
      boxSizing: "border-box",
      padding: "17.2px 0",
      background: "#FAFAFA",
      borderRadius: "50px",
      boxShadow: "2px 2px 2px 2px #00000029",
      zIndex: "4",
        [theme.breakpoints.down(502)]:{
            height: "43px",
            width: "109px",
            padding: "8.6px 0",
            borderRadius: "25px",
        },
    },
    "& .InfoLectura:before": {
      position: "absolute",
      display: "flex",
      boxSizing: "border-box",
      padding: "0 0 0 10px",
      justifyContent: "center",
      alignItems: "center",
      height: "39.88px",
      width: "39.88px",
      borderRadius: "50%",
      content: '""',
      boxShadow: "3px 0px .5px 0px #00000029",
      background: "#FAFAFA",
      left: "90px",
      top: "65px",
      transform: "rotate(0.25turn)",
      [theme.breakpoints.down(502)]:{
        left: "45px",
        top: "30px",
        height: "20.88px",
        width: "20.88px",
        },
    },
    "& .InfoLectura:after": {
      content: '""',
      height: "15px",
      width: "15px",
      borderTop: "2px solid #20A698",
      borderLeft: "2px solid #20A698",
      position: "absolute",
      bottom: "-10px",
      left: "Calc(50% - 8px)",
      transform: "rotate(0.625turn)",
      [theme.breakpoints.down(502)]:{
        height: "10px",
        width: "10px",
        left: "Calc(50% - 4.5px)",
        bottom: "-3px",
        borderTop: "1px solid #20A698",
        borderLeft: "1px solid #20A698",
        },
    },
    "& .Sesion": {
      borderLeft: "2px solid #D8D8D8",
    },
    "& .Dato": {
      margin: "0 0 5px 0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#20A698",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        margin: "0 0 2.5px 0",
        },
    },
    "& .Caracter": {
      margin: "0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#000000",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        },
    },
       '& .Regresar':{
        color: '#F2624F',
        border: '#F2624F 1px solid',
        height: '30px',
        width:'100px',
        borderRadius: '20px',
        letterSpacing: '0.14px',
        textTransform: 'none',
        background:'#FEFAF1',
        top:'calc(50% - 15px)',
        left:'5%',
        zIndex:4,
        [theme.breakpoints.down(502)]: {
          font:'bold 10px lato',
          width: "60px",
        },
      },
  },
    ContenedorLectura:{
        background:'#fff',
        width:'1160px',
        height:'100%',
                padding:'60px 168px 35px ',
        
        transform: (props) =>
        props.scale > 1 ? `scale(${props.scale}) translate(0px, 10%)` : `none`,
        [theme.breakpoints.down(1161)]:{
            width:'512px',
            padding:'60px 0 0 0',
          },
          [theme.breakpoints.down(512)]:{
            width:'350px',
            padding:'60px 0 0 0',
          },

        '& .Escritorio':{
            display:'block',
            [theme.breakpoints.down(1161)]:{
                display:'none'
            },
        },
        '& .Tablet':{
            display:'none',
            [theme.breakpoints.down(1161)]:{
                display:'block'
            },
        }
    },
    ContainerTitulo:{
        margin:'0 0 34px 0',
        [theme.breakpoints.down(1161)]:{
            margin:'0 0 34px 0',
        },
        '& .Titulo':{
            font:'48px fredoka one',
            letterSpacing: '0px',
            lineHeight:'58px',
            color: '#12AAFA',
            margin:'0',
            textAlign:'center',
            [theme.breakpoints.down(1161)]:{
                font:'43px fredoka one',
                lineHeight:'52px',
            },
            [theme.breakpoints.down(512)]:{
                font:'38px fredoka one',
                lineHeight:'52px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            lineHeight : '30px',
            margin:'0 0 36px 0',
            font:'22px lato',
            color:'#000',
            letterSpacing: '0.20px',
            [theme.breakpoints.down(1161)]:{
                font:'18px lato',
                lineHeight :'28px',
                letterSpacing: '0.17px',
            },
            [theme.breakpoints.down(512)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
        },
       '& .Esp':{
        padding:'0 28px 0 0',
        boxSizing:'border-box',
        [theme.breakpoints.down(502)]:{
        padding:'0 10px 0 0',
            
        },
       },
       '& .Esp2':{
        padding:'0 24px 0 0',
        boxSizing:'border-box',
        [theme.breakpoints.down(502)]:{
        padding:'0 5px 0 0',
            
        },
       },
        '& .Lectura2':{
            margin:'0',
        },
        '& .SubTitulo':{
            font:'bold 30px lato',
            color:'#FF7600',
            letterSpacing:'.28px',
            lineHeight:'30px',
            margin:'0',
            [theme.breakpoints.down(1161)]:{
                lineHeight :'28px',
                font:'bold 26px lato',
                letterSpacing: '0.24px',
            },
            [theme.breakpoints.down(512)]:{
                font:'bold 22px lato',
                lineHeight :'24px',
                letterSpacing: '0.21px',
            },
        },
        '& .TextImg':{
            font:'20px lato',
            color:'#717476',
            letterSpacing:'.19px',
            lineHeight:'30px',
            margin:'24px 18px 0 0',
            [theme.breakpoints.down(1161)]:{
                margin:'16px 0 0 0',
                font:' 16px lato',
                lineHeight :'22px',
                letterSpacing: '-.03px',
            },
            [theme.breakpoints.down(512)]:{
                font:' 14px lato',
                lineHeight :'21px',
                letterSpacing: '0.15px',
            },
        },
        '& .Imagen':{
            [theme.breakpoints.down(1161)]:{
                width:'100%'
            },
        }, 


      
    }
}));

const Ciencia_y_guerra = (props) => {

  const {activeStep, setActiveStep, handlePreviewStep, id_actividad} = props
  
  const handleContinue = () => {
    setActiveStep(activeStep - 1)
    handlePreviewStep()
  }

    const {
      handleSize,
    disabledZoom,
    closeZoom,
    handleZoom,
    scale
  }= useContext(DrawerContext);
  const [loading, setLoading] = React.useState(true);
  const wrapperRef = React.useRef(null);
  const imagesLoaded = useOnLoadImages(wrapperRef);

  const detectSize = () => {
    const lectura = document.getElementById("lectura");
    const newWidth = lectura?.offsetWidth;
    const newHeight = lectura?.offsetHeight;
    setLoading(false);
    if (lectura) handleSize(newHeight, newWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", detectSize);
    return () => {
      handleZoom()
      window.removeEventListener("resize", detectSize);
      closeZoom()
    };
  }, []);

  const classes = useStyles({ disabledZoom,scale });
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
             <Grid container item justifyContent="center" className={classes.ContainerInfoLectura}>
                       <Grid container className="BarraInfoLectura">
            <Button className="Regresar" onClick={handleContinue}>
                Regresar
            </Button>
        </Grid>
               <Grid container item className="InfoLectura">
                    <Grid container item direction="column" xs={6} justifyContent="center">
                        <p className="Dato">Grado</p>
                        <p className="Caracter">5</p>
                    </Grid>
                    <Grid container item direction="column" xs={6} justifyContent="center" className="Sesion">
                        <p className="Dato">Sesión</p>
                        <p className="Caracter">47</p>
                    </Grid>
               </Grid>
            </Grid>
            {!imagesLoaded && <Loader />}
        <Grid
            container
            item
            className={classes.ContenedorLectura}
            justifyContent="center"
            id="lectura"
            onLoad={detectSize}
            ref={wrapperRef}
        >
<Drawer id_lectura={id_actividad} />
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                        <h2 className="Titulo">Ciencia y guerra</h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container xs={6} className='Escritorio'>
                       <p className='Lectura Lectura2 '>
                        ¿Alguna vez te has puesto a pensar cuánto <br/>
                        ha avanzado la ciencia durante los últimos <br/>
                        siglos? ¿De dónde salió la tecnología que <br/>
                        utilizamos todos los días? ¿Cómo llegó <br/>
                        hasta aquí la humanidad? En ocasiones el <br/>
                        avance de la ciencia y la tecnología estuvo <br/>
                        relacionado con acontecimientos <br/>
                        históricos terribles. Por ejemplo, algunos <br/>
                        de los mayores progresos científicos que <br/>
                        se han conseguido ocurrieron durante la <br/>
                        Segunda Guerra Mundial. <br/><br/>
                        
                        <h3 className='SubTitulo'>Inventos en guerra</h3><br/>
                        
                        Los gobiernos impulsaron la ciencia y la <br/>
                        tecnología durante la guerra. Estados <br/>
                        Unidos e Inglaterra buscaban evitar la <br/>
                        muerte de los soldados que tenían heridas <br/>
                        infectadas, así que promovieron la <br/>
                        investigación sobre la penicilina y su <br/>
                        producción en grandes cantidades.<br/>
                        Debido a la urgencia, en apenas unos <br/>
                        meses se logró comercializar el fármaco. <br/>
                        Actualmente se sigue utilizando para <br/>
                        combatir infecciones.
                       </p>                   
                    </Grid>
                    <Grid container xs={6}  className='Escritorio'>
                        <Grid item container justifyContent='flex-end'>
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion47/1.png" alt="Tarjeta" />
                        </Grid>
                        <Grid container justifyContent='flex-end'>
                            <p className="TextImg">
                                Sin antibióticos para tratar las infecciones, <br/>
                                muchas heridas de guerra eran mortales.<br/>
                                Por eso la penicilina fue un avance <br/>
                                importante.
                            </p>
                        </Grid> 
                    </Grid>
                    <Grid container sm={12} className='Tablet'>
                       <p className='Lectura Lectura2 '>
                        ¿Alguna vez te has puesto a pensar cuánto 
                        ha avanzado la ciencia durante los últimos 
                        siglos? ¿De dónde salió la tecnología que 
                        utilizamos todos los días? ¿Cómo llegó 
                        hasta aquí la humanidad? En ocasiones el 
                        avance de la ciencia y la tecnología estuvo 
                        relacionado con acontecimientos 
                        históricos terribles. Por ejemplo, algunos 
                        de los mayores progresos científicos que 
                        se han conseguido ocurrieron durante la 
                        Segunda Guerra Mundial. <br/><br/>
                       </p>                   
                    </Grid>
                    <Grid container xs={7} className='Tablet'>
                    <p className='Lectura Lectura2 Esp'> 
                    <h3 className='SubTitulo'>Inventos en guerra</h3><br/>
                        
                        Los gobiernos impulsaron la  
                        ciencia y la tecnología durante la  
                        guerra. Estados Unidos e  
                        Inglaterra buscaban evitar la 
                        muerte de los soldados que 
                        tenían heridas infectadas, así que  
                        promovieron la investigación  
                        sobre la penicilina y su 
                        producción en grandes 
                        cantidades. Debido a la urgencia,  
                        en apenas unos meses se logró  
                        comercializar el fármaco. 
                        Actualmente se sigue utilizando  
                        para combatir infecciones.
                       </p>                   
                    </Grid>
                    <Grid container xs={5} justifyContent='flex-end' className='Tablet'>
                        <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion47/1R.png" alt="Tarjeta" />
                        <p className="TextImg Esp2">
                            Sin antibióticos para tratar las  
                            infecciones, muchas heridas de 
                            guerra eran mortales. Por eso  
                            la penicilina fue un avance 
                            importante.
                        </p>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container >
                        <p className='Lectura'>
                            La Segunda Guerra Mundial también motivó la creación de un ancestro de la red wifi. 
                            La inventora y actriz austriaca Hedy Lamarr desarrolló un proyecto para construir 
                            torpedos que pudieran controlarse mediante ondas de radio y fueran difíciles de 
                            detectar para el enemigo. Aunque este invento no llegó a ser utilizado durante el 
                            combate, fue un paso significativo en el desarrollo de la comunicación inalámbrica. <br/><br/>
                            
                            <h3 className='SubTitulo'>Armas</h3><br/>
                            
                            Los inventos anteriores nacieron por intereses militares, pero llegaron a ser útiles 
                            para la sociedad. En cambio, muchos otros sólo tuvieron como finalidad la 
                            destrucción. La bomba atómica es una clara muestra de ello. Algunos de los 
                            científicos más destacados de la época colaboraron en su invención durante la 
                            guerra. La diseñaron para generar una gran cantidad de energía mediante un proceso 
                            físico complejo llamado <em>fisión nuclear</em>. Las bombas se detonaron en dos ciudades 
                            japonesas llamadas Hiroshima y Nagasaki. Dejaron centenas de miles de muertos, y 
                            los efectos de la radiación fueron atroces para los sobrevivientes.
                        </p>
                    </Grid>
                    <Grid container justifyContent='center'>
                        <Grid container justifyContent='center'>
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion47/2.png" alt="Tarjeta" />
                        </Grid>
                        <Grid container >
                            <p className="TextImg">
                                ¿Qué llevó a los científicos a colaborar en la construcción de las bombas? ¿Por qué los 
                                Aliados decidieron detonarlas? ¡Te invitamos a investigarlo!
                            </p>
                        </Grid>
                    </Grid>

                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container >
                        <p className='Lectura'>
                        Las armas químicas son otro ejemplo de innovación científica aplicada a la 
                        destrucción. Durante la guerra de Vietnam, Estados Unidos arrojó millones de litros 
                        de dioxina sobre selvas y cultivos. Millones de personas murieron y muchas otras 
                        quedaron dañadas de por vida, ya que esta sustancia es tóxica incluso en cantidades 
                        muy pequeñas.
                        </p>
                    </Grid>
                    <Grid container justifyContent='center'>
                        <Grid container justifyContent='center'>
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion47/3.png" alt="Tarjeta" />
                        </Grid>
                        <Grid container >
                            <p className="TextImg">
                                Los aviones estadounidenses esparcieron químicos en la década de los sesenta. Son tan 
                                tóxicos que luego de 50 años continúan afectando gravemente la salud de los vietnamitas.
                            </p>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container >
                        <p className='Lectura'>
                            <h3 className='SubTitulo'>Lo que hemos aprendido</h3><br/>
                            
                            Cuando la ciencia se utiliza con el objetivo de dañarnos los unos a los otros, los 
                            resultados pueden ser devastadores para la vida de pueblos enteros. Incluso para el 
                            planeta. Es cierto que algunos de los avances tecnológicos realizados en la Segunda 
                            Guerra Mundial nos permiten vivir más y con mayores comodidades. Sin embargo, es 
                            responsabilidad de los gobernantes impulsar el desarrollo científico no sólo para 
                            intentar mejorar la calidad de vida, sino también para conservar la de los 
                            ecosistemas.<br/><br/>
                            
                            Quedan muchas preguntas por responder. ¿Los científicos tienen responsabilidad 
                            moral si sus ideas y proyectos producen sufrimiento? ¿Qué responsabilidad histórica 
                            tienen los gobiernos que utilizan esta clase de armas y cómo puede hacérseles 
                            rendir cuentas? ¿Por qué razones los países que han causado tanto daño participan 
                            en las guerras? Te invitamos a investigarlo y reflexionarlo.
                        </p>
                    </Grid>
                </Grid>
        </Grid>
        <Grid container item style={{ marginTop: 80}} ></Grid>
    </Grid>
    );
}
 
export default Ciencia_y_guerra;
