import { Button, Grid, makeStyles } from "@material-ui/core";
import React, { useContext, useEffect } from "react";
import DrawerContext from "../../../../../usability/DrawerContext";
import { useOnLoadImages } from "../../../../hooks/useOnLoadImages";
import Loader from "../../../../Loader/Loader";
import Drawer from "../../ReadingTools/Drawer";

const useStyles =  makeStyles (theme =>({
     Contenedor:{
        background:'#E9FBF9',
        width:'100vw',
        overflowX:'clip',
        [theme.breakpoints.down(1161)]:{
            background:'none',
           overflowX:'unset',
        },
        [theme.breakpoints.down(600)]:{
            marginTop: '79.51px',
        }
    },
  ContainerInfoLectura: {
    position: "relative",
    height: "86px",
    [theme.breakpoints.down(502)]:{
        height: "43px",
    },
    background: "#76D4C3",
    "& .BarraInfoLectura": {
      height: "86px",
      background: `Url(https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Barras/Azul.png)`,
        [theme.breakpoints.down(502)]:{
            height: "43px",
        },
    },
    "& .InfoLectura": {
      position: "absolute",
      height: "86px",
      width: "219px",
      boxSizing: "border-box",
      padding: "17.2px 0",
      background: "#FAFAFA",
      borderRadius: "50px",
      boxShadow: "2px 2px 2px 2px #00000029",
      zIndex: "4",
        [theme.breakpoints.down(502)]:{
            height: "43px",
            width: "109px",
            padding: "8.6px 0",
            borderRadius: "25px",
        },
    },
    "& .InfoLectura:before": {
      position: "absolute",
      display: "flex",
      boxSizing: "border-box",
      padding: "0 0 0 10px",
      justifyContent: "center",
      alignItems: "center",
      height: "39.88px",
      width: "39.88px",
      borderRadius: "50%",
      content: '""',
      boxShadow: "3px 0px .5px 0px #00000029",
      background: "#FAFAFA",
      left: "90px",
      top: "65px",
      transform: "rotate(0.25turn)",
      [theme.breakpoints.down(502)]:{
        left: "45px",
        top: "30px",
        height: "20.88px",
        width: "20.88px",
        },
    },
    "& .InfoLectura:after": {
      content: '""',
      height: "15px",
      width: "15px",
      borderTop: "2px solid #20A698",
      borderLeft: "2px solid #20A698",
      position: "absolute",
      bottom: "-10px",
      left: "Calc(50% - 8px)",
      transform: "rotate(0.625turn)",
      [theme.breakpoints.down(502)]:{
        height: "10px",
        width: "10px",
        left: "Calc(50% - 4.5px)",
        bottom: "-3px",
        borderTop: "1px solid #20A698",
        borderLeft: "1px solid #20A698",
        },
    },
    "& .Sesion": {
      borderLeft: "2px solid #D8D8D8",
    },
    "& .Dato": {
      margin: "0 0 5px 0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#20A698",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        margin: "0 0 2.5px 0",
        },
    },
    "& .Caracter": {
      margin: "0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#000000",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        },
    },
       '& .Regresar':{
        color: '#F2624F',
        border: '#F2624F 1px solid',
        height: '30px',
        width:'100px',
        borderRadius: '20px',
        letterSpacing: '0.14px',
        textTransform: 'none',
        background:'#FEFAF1',
        top:'calc(50% - 15px)',
        left:'5%',
        zIndex:4,
        [theme.breakpoints.down(502)]: {
          font:'bold 10px lato',
          width: "60px",
        },
      },
  },
    ContenedorLectura:{
        background:'#fff',
        width:'1160px',
        height:'100%',
        padding:'60px 154px 35px 168px ',
            transform: (props) =>
      props.scale > 1 ? `scale(${props.scale}) translate(0px, 10%)` : `none`,[theme.breakpoints.down(1161)]:{
            width:'502px',
            padding:'60px 0 0 0',
          },
        [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },

        '& .Escritorio':{
            display:'flex',
            [theme.breakpoints.down(1161)]:{
                display:'none'
            },
        },
        '& .Tablet':{
            display:'none',
            [theme.breakpoints.down(1161)]:{
                display:'flex'
            },
        }
    },
    ContainerTitulo:{
        margin:'0 0 34px 0',
        [theme.breakpoints.down(1161)]:{
            margin:'0 0 34px 0',
        },
        '& .Titulo':{
            font:'48px fredoka one',
            letterSpacing: '0px',
            lineHeight:'58px',
            color: '#823CE2',
            margin:'0',
            textAlign:'center',
            [theme.breakpoints.down(1161)]:{
                font:'43px fredoka one',
                lineHeight:'52px',
            },
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            boxSizing:'border-box',
            padding:'0 14px 0 0',
            lineHeight : '30px',
            margin:'0 0 36px 0',
            font:'22px lato',
            color:'#000',
            letterSpacing: '0.20px',
            [theme.breakpoints.down(1161)]:{
                font:'18px lato',
                lineHeight :'28px',
                letterSpacing: '0.17px',
            },
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .Lectura2':{
            margin:'0',
            padding:'0',
            [theme.breakpoints.down(1161)]:{
                width:'94%'
            },
        },
        '& .Esp':{
            boxSizing:'border-box',
            [theme.breakpoints.down(1161)]:{
                padding:'0 4% 0 0'
            },
        },
        '& .Esp2':{
            boxSizing:'border-box',
            [theme.breakpoints.down(1161)]:{
                padding:'0 7% 0 0'
            },
        },
        '& .Esp3':{
            boxSizing:'border-box',
            [theme.breakpoints.down(1161)]:{
                padding:'0 10% 0 0'
            },
        },
        '& .SubTitulo':{
            font:'bold 30px lato',
            color:'#FC5E9E',
            letterSpacing:'.28px',
            lineHeight:'30px',
            margin:'0',
            [theme.breakpoints.down(1161)]:{
                lineHeight :'28px',
                font:'bold 26px lato',
                letterSpacing: '0.24px',
            },
            [theme.breakpoints.down(502)]:{
                font:'bold 18px lato',
                lineHeight :'24px',
                letterSpacing: '0.21px',
            },
        },
        '& .SubTitulo2':{
            font:'26px Fredoka One',
            color:'#823CE2',
            lineHeight:'30px',
            margin:'0 0 15px 0',
            [theme.breakpoints.down(1161)]:{
                font:'22px Fredoka One',
                lineHeight :'24px',
            },
            [theme.breakpoints.down(502)]:{
                font:'18px Fredoka One',
                lineHeight :'20px',
            },
        },
        '& .TextImg':{
            font:'20px lato',
            color:'#717476',
            letterSpacing:'.19px',
            lineHeight:'30px',
            margin:'24px 0 0 0',
            [theme.breakpoints.down(1161)]:{
                font:' 16px lato',
                lineHeight :'25px',
                letterSpacing: '0.15px',
            },
            [theme.breakpoints.down(502)]:{
                font:' 11px lato',
                lineHeight :'14px',
                letterSpacing: '0.10px',
            },
        },
        '& .Cuadro':{
            borderRadius:'20px',
            border: '5px solid #EB23CD',
            boxSizing:'border-box',
            padding:'24px 0 24px 17px',
            [theme.breakpoints.down(502)]:{
                padding:'14px 0 14px 17px',
            },
        },
        '& .ul':{
        margin:'0',
        padding:'0 0 0 19px',
        listStyle:'alphanumeric',
        color:'#FC5E9E',
        lineHeight : '30px',
        font:'bold 22px lato ',
        letterSpacing: '0.20px',
        [theme.breakpoints.down(1161)]:{
            font:'bold 18px lato ',
            lineHeight :'28px',
            letterSpacing: '0.17px',
        },
        [theme.breakpoints.down(502)]:{
            font:'bold 16px lato ',
            lineHeight :'21px',
            letterSpacing: '0.09px',
        },
        },
        '& .li':{
            margin:'0 0 8px 0',
            [theme.breakpoints.down(1161)]:{
                margin:'0 0 2px 0',
            },
        },

        '& .Imagen':{
            [theme.breakpoints.down(1161)]:{
                width:'100%',
            },
        }, 
        '& .Imagen2':{
            [theme.breakpoints.down(502)]:{
                width:'95%',
            },
        },  
      
    }
}));

const Qué_está_pasando_con_nuestra_privacidad = (props) => {

  const {activeStep, setActiveStep, handlePreviewStep, id_actividad} = props
  
  const handleContinue = () => {
    setActiveStep(activeStep - 1)
    handlePreviewStep()
  }

    const {
      handleSize,
    disabledZoom,
    closeZoom,
    handleZoom,
    scale
  }= useContext(DrawerContext);
  const [loading, setLoading] = React.useState(true);
  const wrapperRef = React.useRef(null);
  const imagesLoaded = useOnLoadImages(wrapperRef);

  const detectSize = () => {
    const lectura = document.getElementById("lectura");
    const newWidth = lectura?.offsetWidth;
    const newHeight = lectura?.offsetHeight;
    setLoading(false);
    if (lectura) handleSize(newHeight, newWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", detectSize);
    return () => {
      handleZoom()
      window.removeEventListener("resize", detectSize);
      closeZoom()
    };
  }, []);

  const classes = useStyles({ disabledZoom,scale });
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
             <Grid container item justifyContent="center" className={classes.ContainerInfoLectura}>
                       <Grid container className="BarraInfoLectura">
            <Button className="Regresar" onClick={handleContinue}>
                Regresar
            </Button>
        </Grid>
               <Grid container item className="InfoLectura">
                    <Grid container item direction="column" xs={6} justifyContent="center">
                        <p className="Dato">Grado</p>
                        <p className="Caracter">5</p>
                    </Grid>
                    <Grid container item direction="column" xs={6} justifyContent="center" className="Sesion">
                        <p className="Dato">Sesión</p>
                        <p className="Caracter">28</p>
                    </Grid>
               </Grid>
            </Grid>
            {!imagesLoaded && <Loader />}
        <Grid
            container
            item
            className={classes.ContenedorLectura}
            justifyContent="center"
            id="lectura"
            onLoad={detectSize}
            ref={wrapperRef}
        >
<Drawer id_lectura={id_actividad} />
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                        <h2 className="Titulo">¿Qué está pasando <br/>con nuestra privacidad?</h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                       <p className='Lectura'>
                        <h3 className='SubTitulo'>Sofía</h3><br/>
                        
                        Sofía es muy tímida. Le cuesta trabajo hablar de lo que siente y de lo que piensa. Por 
                        eso prefiere escribir. Por las noches toma un pequeño cuaderno que guarda detrás 
                        de su espejo y vacía en él todo lo que le pasó en el día. Cuenta las cosas que la 
                        entusiasmaron o la incomodaron. Pone en palabras lo que piensa de la gente y lo que 
                        siente por Rafa, el chico que le gusta. Escribe sus planes para el futuro y la manera en 
                        la que ve el mundo.
                       </p>                   
                    </Grid>
                    <Grid container justifyContent='center'>
                        <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion28/1.png" alt="Tarjeta" />
                    </Grid>
                    <Grid container >
                        <p className="TextImg">
                            El espacio detrás del espejo es un lugar simbólicamente protegido, un espacio donde Sofía resguarda sus secretos.
                        </p>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container >
                        <p className='Lectura'>
                            Un día, su hermana mayor, Juliana, la ve guardando el cuaderno en su escondite. Sin 
                            que Sofía se dé cuenta, Juliana toma el cuaderno y lo lee. Ahora Juliana sabe todos 
                            los secretos de Sofía. ¿Te ha pasado algo como esto? ¿Cómo te sentirías si estuvieras 
                            en la misma situación que Sofía?
                        </p>
                    </Grid>
                    <Grid container justifyContent='center'>
                        <Grid container justifyContent='center'>
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion28/2.png" alt="Tarjeta" />
                        </Grid>
                        <Grid container justifyContent='center' >
                            <p className="TextImg">
                                ¿Tú has hecho alguna vez lo que hace Juliana?
                            </p>
                        </Grid>
                    </Grid>

                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container >
                        <p className='Lectura'>
                            <h3 className='SubTitulo'>¿Por qué es importante cuidar tu información personal?</h3><br/>
                            
                            La información personal representa una parte fundamental de nuestra identidad: 
                            refleja quiénes somos, qué pensamos y qué consideramos importante. En nuestros 
                            espacios personales somos verdaderamente libres, ya que allí nos expresamos, 
                            experimentamos y tomamos nuestras propias decisiones sin sentirnos juzgados. Es 
                            por esto que necesitamos proteger nuestra información personal y nuestra intimidad 
                            de la mejor manera posible. La privacidad es un derecho con el que todos contamos y 
                            que nos da seguridad, porque nos permite guardar cualquier información que de 
                            alguna manera pueda dejarnos vulnerables o indefensos ante los demás.<br/><br/>
                            
                            ¿Te has preguntado si la información que compartes en internet podría ponerlos a ti 
                            o a la gente que quieres en una situación desagradable o peligrosa? Por ejemplo, si 
                            subes a tu red social favorita una foto de tus últimas vacaciones, en cuestión de 
                            minutos miles de personas podrán identificarte, saber con quiénes tienes contacto, 
                            hacerse una idea de cuánto dinero tiene tu familia, reconocer el tipo de lugares que 
                            frecuentas, lo que te gusta hacer, la forma en la que te vistes y hasta lo que comes. 
                            Abrumador, ¿cierto? <br/><br/>
                            
                            <h3 className='SubTitulo'>Redes sociales: amigos complicados</h3><br/>
                            
                            Las redes sociales nos permiten comunicarnos en tiempo real con nuestros seres 
                            queridos. También nos ponen en contacto con gente que vive del otro lado del 
                            mundo, a la que no habríamos conocido por otro medio. Pero hay gente que puede 
                            aprovecharse de esto para hacerte daño, para manipularte o para vender tus datos. 
                            Cuando subes información a las plataformas sociales dejas de ser dueño de estos 
                            datos y te expones al uso que la gente quiera darles.<br/><br/>
                            
                            Afortunadamente cada vez hay más preocupación por este tema a nivel 
                            internacional. Constantemente se revisan las leyes para tratar de prevenir los delitos
                            contra la privacidad y así ayudarnos a proteger nuestra información y nuestra 
                            intimidad. Mientras tanto, a nosotros nos corresponde ser cuidadosos y 
                            responsables, pensar qué publicamos y con quién lo compartimos.
                        </p>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container className='Cuadro'>
                        <Grid item container sm={12} >
                          <h3 className='SubTitulo2'>Éstas son algunas de las cosas que puedes hacer para cuidarte:</h3>
                        </Grid>
                        
                        <Grid item container sm={6} alignContent='flex-end' className='Escritorio'>
                         <ul className='ul'>
                             <li className='li'>
                                 <p className='Lectura Lectura2'>
                                 Utiliza las opciones de privacidad para que tu perfil no quede expuesto y decidas qué personas son las que verán tus contenidos.
                                 </p>
                             </li>
                             <li className='li'>
                                 <p className='Lectura Lectura2'>
                                 No compartas tus datos personales (como dirección y teléfono) de <br/>manera pública.
                                 </p>
                             </li>
                             <li className='li'>
                                 <p className='Lectura Lectura2'>
                                 Sube sólo los contenidos que te hagan sentir bien, para que nadie pueda usarlos con el fin de molestarte.
                                 </p>
                             </li>
                             <li className='li'>
                                 <p className='Lectura Lectura2'>
                                 Confía en tu instinto. Si alguna interacción te parece rara o <br/>incómoda, corta la <br/>comunicación con esa persona.
                                 </p>
                             </li>
                         </ul>
                        </Grid>
                        <Grid item container sm={6} className='Escritorio' >
                            <Grid container sm={12} justifyContent='center'>
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion28/3.png" alt="Tarjeta" />
                            </Grid>
                            <Grid container sm={12}>
                            <p className="TextImg">
                                Recuerda que Sofía tiene derecho a elegir <br/>qué es lo que quiere guardar para ella.
                            </p>
                            </Grid>
                            
                        </Grid>

                        <Grid item container sm={12} alignContent='flex-end' className='Tablet'>
                         <ul className='ul'>
                             <li className='li'>
                                 <p className='Lectura Lectura2 Esp'>
                                 Utiliza las opciones de privacidad para que tu perfil no quede expuesto y decidas qué personas son las que verán tus contenidos.
                                 </p>
                             </li>
                             <li className='li'>
                                 <p className='Lectura Lectura2 Esp'>
                                 No compartas tus datos personales (como dirección y teléfono) de manera pública.
                                 </p>
                             </li>
                             <li className='li'>
                                 <p className='Lectura Lectura2'>
                                 Sube sólo los contenidos que te hagan sentir bien, para que nadie pueda usarlos con el fin de molestarte.
                                 </p>
                             </li>
                             <li className='li'>
                                 <p className='Lectura Lectura2 Esp2'>
                                 Confía en tu instinto. Si alguna interacción te parece rara o incómoda, corta la comunicación con esa persona.
                                 </p>
                             </li>
                         </ul>
                        </Grid>
                        <Grid item container sm={12} className='Tablet'>
                            <img className="Imagen2" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion28/3R.png" alt="Tarjeta" />
                            <p className="TextImg Esp3">
                                Recuerda que Sofía tiene derecho a elegir qué es lo que quiere guardar para ella.
                            </p>
                        </Grid>
                    </Grid>
                </Grid>
        </Grid>
        <Grid container item style={{ marginTop: 80}} ></Grid>
    </Grid>
    );
}
 
export default Qué_está_pasando_con_nuestra_privacidad;
