import { makeStyles, Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import ChatListBar from "./ChatListBar";
import { useParams } from "react-router-dom";
import AuthContext from "../../../auth/AuthContext";
import ChatHeader from "./Chat_Header";
import InputSendMessage from "./Chat_Input_Send";
import ChatMessages from "./Chat_Messages";
import ChatContext from "./Context/ChatContext";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    background: "#FFFAE5 0% 0% no-repeat padding-box",
    minHeight: "calc(100vh - 80px)",
    display: "grid",
    width: "100%",
    height: "calc(100vh - 80px)",
    alignItems: "start",
    gridTemplateAreas: `"header"
"messages"
"input"`,
    gridTemplateColumns: "1fr",
    gridTemplateRows: "minmax(80px, 20%) 1fr minmax(80px, 15%)",
    // "@media (max-height: 400px)": {
    //   gridTemplateRows: "30% 1fr 30%",
    // },
    // "@media (max-height: 600px)": {
    //   gridTemplateRows: "30% 1fr 40%",
    // },
    [theme.breakpoints.up("lg")]: {
      gridTemplateAreas: `"bar header"
"bar messages"
"bar input"`,
      gridTemplateColumns: "minmax(20%, 300px) 1fr",
      // gridTemplateRows: "15vh 65vh 15vh",
      gridTemplateRows: "min-content 1fr min-content",
      // margin: "10px 0",
    },
  },
}));

const Chat = () => {
  const classes = useStyles();
  const { id } = useParams();
  const {
    user: { name, userId },
  } = React.useContext(AuthContext);
  const {
    chats,
    sendMessage,
    connectToChat,
    leaveFromChat,
    socket,
    consultMoreHistory,
    handleSearch,
    filterFavorites,
    consultFavorites,
  } = React.useContext(ChatContext);

  const [habiliteScroll, setHabiliteScroll] = useState(true);

  const handleHabiliteScroll = (habilite) => {
    setHabiliteScroll(habilite);
  };

  const chat = chats?.find((chat) => chat.sala === id);

  const [filter, setFilter] = useState("");
  const { mensajes, imagen_perfil, paginacion, sala } = chat || [];

  useEffect(() => {
    handleHabiliteScroll(true);
    return () => {};
  }, [paginacion?.pagina]);

  useEffect(() => {
    if (filterFavorites) {
      consultFavorites(sala, 1);
    } else {
      consultMoreHistory(1, sala);
    }

    return () => {};
  }, [filterFavorites]);

  // const [totalPages, setTotalPages] = useState(1);

  const handlePage = () => {
    // console.log(paginacion.pagina, paginacion.totalPaginas);
    if (paginacion?.pagina < paginacion?.totalPaginas) {
      if (filterFavorites) {
        consultFavorites(sala, paginacion?.pagina + 1);
      } else {
        consultMoreHistory(paginacion?.pagina + 1, id, filter);
      }
    }
  };

  useEffect(() => {
    const usuario = {
      nombre: name,
      sala: id,
      id_usuario: userId,
    };

    connectToChat(id);
    return () => {
      leaveFromChat(usuario);
    };
  }, [id, name, userId, socket]);

  const handleFilter = (e) => {
    const text = e.target.value;
    setFilter(text);
    if (text !== "") {
      handlePage();
      handleSearch(1, id, text);
    } else {
      handleSearch(1, id, text);
    }
  };
  const handleSendMessage = (text) => {
    const message = {
      nombre: name,
      mensaje: text,
      id_usuario: userId,
      sala: id,
    };
    sendMessage(message, id);
  };


  return (
    <Grid
      container
      item
      sm={12}
      alignItems="center"
      justifyContent="center"
      className={classes.mainContainer}
    >
      <ChatListBar inChat={true} />
      <ChatHeader
        name={chat?.name || chat?.nombre}
        conected={chat?.conectado}
        handleFilter={handleFilter}
        chatData={chat}
      />
      <ChatMessages
        chatData={chat}
        messages={mensajes}
        imagen_perfil={imagen_perfil}
        filterSearch={filter}
        paginacion={paginacion}
        habiliteScroll={habiliteScroll}
        handlePage={handlePage}
        handleHabiliteScroll={handleHabiliteScroll}
        filterFavorites={filterFavorites}
      />
      <InputSendMessage handleSendMessage={handleSendMessage} sala={id} />

      {/* <Dropzone
        accept="image/*"
        multiple={false} // Only upload 1 file
        onDrop={(file) => {
          handleArchivos(file);
        }}
      >
        {({ getRootProps, getInputProps }) => (
          <div {...getRootProps()} className={classes.container}>
            <input {...getInputProps()} />
            <ChatMessages chatData={chatData} messages={mensajes} image={image} />
            <InputSendMessage sendMessage={sendMessage} handleSendMessage={handleSendMessage} />
          </div>
        )}
      </Dropzone> */}
    </Grid>
  );
};

export default Chat;
