import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
    ContenedorLectura:{
        width:'502px',
                  [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
    },
    ContainerTitulo:{
        margin:'0 0 46px 0',
        '& .Titulo':{
            letterSpacing: '0px',
            color: '#F18CDF',
            margin:'0',
            font:'43px fredoka one',
            lineHeight:'52px',
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            margin:'0 0 36px 0',
            color:'#000',
            font:'18px lato',
            lineHeight :'28px',
            letterSpacing: '0.17px',
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .Esp':{
            padding:'0',
            margin:'0 0 25px 0',
        },
        '& .Esp2':{
            margin:'0',
        },

        '& .SubTitulo':{
            color:'#F6644B',
            margin:'0',
            lineHeight :'28px',
            font:'bold 26px lato',
            letterSpacing: '0.24px',
            [theme.breakpoints.down(502)]:{
                font:'bold 18px lato',
                lineHeight :'24px',
                letterSpacing: '0.21px',
            },
        },

        '& .SubTitulo2':{
            color:'#3886AC',
            margin:'0',
            font:'bold 22px lato',
            lineHeight :'24px',
            letterSpacing: '0.21px',
            [theme.breakpoints.down(502)]:{
                font:'bold 18px lato',
                lineHeight :'20px',
                letterSpacing: '0.16px',
            },
        },
        '& .TextImg':{
            margin:'22px 0 0 0',
            alignSelf:'flex-start',
            color:'#717476',
            font:' 16px lato',
            lineHeight :'25px',
            letterSpacing: '0.15px',
            [theme.breakpoints.down(502)]:{
                font:' 11px lato',
                lineHeight :'14px',
                letterSpacing: '0.10px',
            },
        },

        '& .Imagen':{
            width:'471px',
            [theme.breakpoints.down(502)]:{
                width:'100%',
            },
        },
        '& .Imagen2':{
                width:'100%',
        },
        
    },
    Esp:{
        margin:' 0 0 15px 0',
    },
}));

const Aves_sin_fronteras = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >Aves sin fronteras</h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container xs={12} >
                      <p className="Lectura Esp">
                        <h3 className='SubTitulo'>Cooperación internacional </h3><br/>

                         El término <em>cooperación internacional</em> es similar 
                         al de <em>cooperación</em> (o <em>colaboración</em>) que 
                         aprendemos en casa y ponemos en práctica 
                         con nuestras amistades, en la escuela o en los 
                         diversos grupos donde participamos. La 
                         palabra se refiere a unir esfuerzos. Cuando 
                         cooperamos, aportamos algo de lo que 
                         podemos (y sabemos) hacer para lograr 
                         objetivos colectivos, como: ayudar a limpiar la 
                         casa, trabajar o jugar en equipo, o cuidar de 
                         las otras personas y nuestro entorno.
                      </p>
                    </Grid>
                    <Grid container justifyContent="center" xs={12} >
                        <Grid container justifyContent="center" alignItems='flex-end'>
                            <img className="Imagen " src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img2do/Sesion11/1R.png" alt="Tarjeta" />
                        </Grid>
                    </Grid>
                    <Grid container sm={12}>
                      <p className="Lectura Esp2"> 
                        La <em>cooperación internacional</em> sucede cuando varios países se unen para resolver juntos 
                        algún problema o necesidad global. Aunque no siempre fue considerada importante. 
                        Esta idea negativa cambió después de las dos guerras mundiales (principalmente la 
                        segunda), cuando se reconoció que se necesitaban instituciones que ayudaran a 
                        mantener la paz.<br/><br/> 
                        
                        Con ese objetivo en mente se creó en 1945 la Organización de las Naciones Unidas 
                        (<span style={{fontVariant: "all-small-caps"}}>ONU</span>), la cual se enfocó durante sus primeros años en la reconstrucción y la mejora
                         económica de los países afectados por la guerra.
                      </p>
                    </Grid>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                      <p className="Lectura">
                        <h3 className='SubTitulo2'>
                        ¿Cómo se relacionan las aves con la cooperación internacional?
                        </h3><br/>
                         Con el tiempo, los esfuerzos de cooperación entre países se concentraron en la 
                         resolución de problemas sociales, económicos, científicos y tecnológicos. En la 
                         actualidad, el cambio climático se ha unido a la lista.<br/><br/> 
                         
                         Una muestra de ello es la conservación de las especies. Por ejemplo, existe el 
                         proyecto de <em>Aves Playeras Migratorias</em>, que busca proteger a las aves. Este programa 
                         recopila información en los distintos países de América para conocer los problemas a 
                         los que se enfrentan y proponer soluciones basadas en la ciencia.<br/><br/> 
                         
                         Las aves migratorias se mueven de un lugar a otro cada año. Viajan cientos de
                         kilómetros en busca del mejor clima para pasar cierta temporada, alimentarse y así 
                         mantener el equilibro de los ecosistemas. Pero el cambio climático ha afectado este 
                         proceso, pues modifica los sitios a los que suelen migrar.
                      </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12} className='Img'>
                        <Grid container justifyContent="center" alignItems='flex-end'>
                            <img className='Imagen2' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img2do/Sesion11/2.png" alt="Tarjeta" />
                        </Grid>
                        <Grid container justifyContent="center" alignItems='flex-end'>
                           <p className='TextImg'>
                            Todos podemos contribuir a que el viaje de las aves migratorias sea más seguro y que así 
                            lleguen a su destino. Algunas formas de ayudarlas son plantar árboles y flores nativas, cuidar 
                            el agua y proteger sus nidos.</p>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                      <p className="Lectura">
                        Cuando un problema atraviesa fronteras, es necesario que diferentes gobiernos y 
                        organizaciones internacionales unan esfuerzos para buscar soluciones.<br/><br/>  

                        ¿Qué crees que podríamos hacer para contrarrestar un problema tan grave? 
                        Investiga más acerca del cambio climático y reflexiona. También te invitamos a 
                        cooperar poniendo lo que esté a tu alcance para mejorar una situación como la de las
                        aves migratorias. Verás que colaborar poco a poco da frutos.
                      </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12} className='Img'>
                        <Grid container justifyContent="center" alignItems='flex-end'>
                            <img className='Imagen2' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img2do/Sesion11/3.png" alt="Tarjeta" />
                        </Grid>
                        <Grid container justifyContent="center" alignItems='flex-end'>
                           <p className='TextImg'>
                           La cooperación en la escuela es fundamental para el aprendizaje de todos los estudiantes.
                           </p>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

        </Grid>
    );
}
 
export default Aves_sin_fronteras;
