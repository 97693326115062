import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
    ContenedorLectura:{
        height:'100%',
        width:'502px',
                  [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
    },

    ContainerTitulo:{
        margin:'0 0 36px 0',
        '& .Titulo':{
            letterSpacing: '0px',
            color: '#823CE2',
            margin:'0',
            textAlign:'center',
            font:'43px fredoka one',
            lineHeight:'52px',
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    ContainerPoema:{
        position:'relative',
        '& .Lectura':{
            position:'absolute',
            margin:'0 ',
            color:'#000',
            textAlign:'center',
            font:'18px lato',
            lineHeight :'28px',
            letterSpacing: '0.17px',
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
            
        },  
        '& .Lectura1':{
            top:'47px',
            [theme.breakpoints.down(502)]:{
                top:'32px',
            },
        },
        '& .Lectura2':{
            top:'587px',
            [theme.breakpoints.down(502)]:{
                top:'400px',
            },
        },
        '& .Imagen':{
            position:'absolute',
        },
        '& .Imagen2':{
            width:'71.11%',
            top:'275px',
            [theme.breakpoints.down(502)]:{
                top:'190px',
            },
        },
        '& .Imagen3':{
            width:'68.32%',
            top:'1235px',
            [theme.breakpoints.down(502)]:{
                top:'870px',
            },
        },
        '& .Imagen4':{
            width:'81.87%',
            top:'2010px',
            [theme.breakpoints.down(502)]:{
                top:'1400px',
            },
        },

        '& .Lectura3':{
            top:'1595px',
            [theme.breakpoints.down(502)]:{
                top:'1100px',
            },
        },
        '& .Fondo':{
            [theme.breakpoints.down(502)]:{
                width:'100%',

            },
        },
        
    },
}));

const Vuela_vuela_colibrí = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >
                    Vuela, vuela, colibrí
                    </h2>
                </Grid>
                <Grid container item className={classes.ContainerPoema} justifyContent="center" >
                    <p className='Lectura Lectura1'>
                        De una hermosa piedra verde<br/> 
                        un colibrí fue creado.<br/> 
                        Así lo hicieron los dioses,<br/> 
                        pues el maíz se acabaron.<br/><br/> 
                        
                        Me escondo tras una flor,<br/> 
                        y allí llega un colibrí.<br/>
                    </p>
                    <img src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img2do/Sesion16/1.png" alt="Colibri" className='Imagen Imagen2'/>
                    <p className='Lectura Lectura2'>
                        Ve flores que son brillantes<br/>
                        y vuela para adelante.<br/>
                        Y cuando mira un alcatraz,<br/>
                        volando lo hace hacia atrás.<br/><br/>
                           
                        Hay una leyenda que cuenta<br/>
                        que esta misión le dejaron:<br/>
                        traernos mensajes y nuevas <br/>
                        de los que se adelantaron.<br/><br/> 

                        Me escondo tras otra flor<br/> 
                        y llega otro colibrí.<br/> <br/>
                        
                        Ve flores de tonos brillantes<br/>
                         y vuela para adelante.<br/> 
                         Y cuando mira un alcatraz,<br/> 
                         volando lo hace hacia atrás.<br/><br/> 
                         
                         Sus alas de arcoíris veo<br/> 
                         y va veloz a todos lados<br/> 
                         con más de cincuenta aleteos<br/> 
                         que en un segundo ha logrado.<br/>
                    </p>
                    <img src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img2do/Sesion16/2.png" alt="Colibri" className='Imagen Imagen3'/>
                    <p className='Lectura Lectura3'>
                        Me escondo tras una flor,<br/> 
                        y allí llega un colibrí.<br/><br/>

                        Ve flores de tonos brillantes,<br/> 
                        y vuela para adelante.<br/> 
                        Y cuando mira un alcatraz,<br/> 
                        de gusto, lo hace hacia atrás.<br/><br/> 
                        
                        El néctar que hay en las flores<br/> 
                        lo puede beber volando.<br/> 
                        Yo confundo sus colores <br/>
                        cuando en mi jardín bailando<br/> 
                        me escondo tras una flor<br/> 
                        y allí llega un colibrí.
                    </p>
                    <img src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img2do/Sesion16/3.png" alt="Colibri" className='Imagen Imagen4'/>
                    <img className='Fondo' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img2do/Sesion16/4R.png" alt="Fondo" />

                </Grid>
                
            </Grid>
        </Grid>
    );
}
 
export default Vuela_vuela_colibrí;
