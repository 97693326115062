import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import axios from "../../../../services/axios";
import { Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import IconButton from "@material-ui/core/IconButton";
import UsabilityContext from "../../../../usability/UsabilityContext";
import ModalConfirm from "../../../Modals/ModalConfirm";
import ModalMessage from "../../../Modals/ModalMessage";
import Loader from "../../../Loader/Loader";

const GroupsTable = (props) => {
  const { userId } = props;

  const { search } = React.useContext(UsabilityContext);
  const [rows, setRows] = React.useState([]);
  const [confirmationModalOpen, setConfirmationModalOpen] =
    React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [idGroupToDelete, setIdGrouptoDelete] = React.useState(null);

  const [modalMessageOpen, setModalMessageOpen] = React.useState(false);
  const [confirmChangesResult, setConfirmChangesResult] = React.useState("");

  const getAllTeachers = (teachers = []) => {
    const teachersNames = teachers?.map((teacher) => {
      return " " + teacher?.nombre;
    });
    return teachersNames?.toString();
  };

  const columns = [
    {
      field: "codigo_clase",
      headerName: "ID de clase",
      width: 160,
      editable: false,
    },
    {
      field: "grade",
      headerName: "Grado",
      width: 80,
      editable: false,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "group",
      headerName: "Grupo",
      width: 80,
      editable: false,
      align: "center",
    },
    {
      field: "teachers",
      headerName: "Docente",
      // width: 180,
      editable: false,
      flex: 1,
      valueGetter: (params) =>
        getAllTeachers(params?.row?.teachers) || "Sin asignar",
    },
    {
      field: "date",
      headerName: "Fecha de creación",
      width: 150,
      align: "center",
      editable: false,
      valueGetter: (params) => params?.row?.teachers?.[0]?.fechaCreacion,
    },
    {
      field: "expire",
      headerName: "Vigencia",
      width: 120,
      editable: false,
      headerAlign: "center",
      align: "center",
      valueGetter: (params) => params?.row?.teachers?.[0]?.vigencia,
    },
    // {
    //   field: "id_usuario",
    //   headerName: "ID",
    //   width: 60,
    //   editable: false,
    // },
    {
      field: "reports",
      headerName: "Reportes",
      width: 90,
      editable: false,
      renderCell: (params) => (
        <Link
          to={{
            pathname: `/admin-inst/reportes`,
            state: {
              id_usuario: params.row.id_usuario,
              id_distribucion_escuela: params.row.id_distribucion_escuela,
            },
          }}
          style={{ alignItems: "center", textDecoration: "none" }}
        >
          <Button color="primary" size="small">
            ir
          </Button>
        </Link>
      ),
    },

    {
      field: "look",
      headerName: "Detalle",
      width: 80,
      editable: false,
      renderCell: (params) => (
        <Link
          to={`/admin-inst/grupo/${params.row.id_distribucion_escuela}/usuarios`}
          style={{ alignItems: "center", textDecoration: "none" }}
        >
          <Button color="primary" size="small">
            ver
          </Button>
        </Link>
      ),
    },
    {
      field: "delete",
      headerName: "Eliminar",
      width: 90,
      editable: false,
      renderCell: (params) => (
        <IconButton
          color="secondary"
          aria-label="delete"
          onClick={() => {
            handleDelete(params?.row?.id_distribucion_escuela);
          }}
        >
          <DeleteForeverIcon />
        </IconButton>
      ),
    },
  ];

  const handleDelete = (id) => {
    setIdGrouptoDelete(id);
    setConfirmationModalOpen(true);
  };

  const handleCloseMessageModal = () => {
    setModalMessageOpen(!modalMessageOpen);
  };
  const handleCloseConfirmationModal = () => {
    setConfirmationModalOpen(!confirmationModalOpen);
  };

  const confirmDelete = () => {
    setConfirmationModalOpen(false);
    setLoading(true);
    const body = {
      id_distribucion_escuela: idGroupToDelete,
    };
    axios
      .put("/listado/delete/grupo", body)
      .then((response) => {
        if (response.status === 200) {
          setLoading(false);
          setConfirmationModalOpen(false);
          setModalMessageOpen(true);
          setConfirmChangesResult("El grupo ha sido eliminado");
          const listWithoutThisSchool = rows?.filter(
            (user) => user?.id_distribucion_escuela !== idGroupToDelete
          );
          setRows(listWithoutThisSchool);
        }
      })
      .catch((error) => {
        if (error.response) {
          // Request made and server responded
          const errorData = error.response.data;
          const mensaje =
            errorData?.detalles?.msg ||
            "Ha ocurrido un error al consumir los servicios, inténtelo de nuevo más tarde.";
          setConfirmChangesResult(mensaje);
          setConfirmationModalOpen(false);
          setModalMessageOpen(true);
        } else {
          // Something happened in setting up the request that triggered an Error
          const mensaje =
            "Ha ocurrido un error al consumir los servicios, inténtelo de nuevo más tarde.";
          setConfirmChangesResult(mensaje);
          setConfirmationModalOpen(false);
          setModalMessageOpen(true);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  React.useEffect(() => {
    const body = {
      id_escuela: 1,
      id_usuario: 32,
    };
    axios
      .get(`/listado/grupos/escuela?id_usuario=${userId}`, body)
      .then((response) => {
        if (response.status === 200) {
          const groupsList = response.data.resultado
            .map((group, index) => {
              return {
                codigo_clase: group.codigo_clase,
                usuarios: group.usuarios,
                grade: group.id_grado,
                group: group.grupo,
                id_usuario: group.id_usuario,
                id_distribucion_escuela: group.id_distribucion_escuela,
                date: group.fechaCreacion,
                expire: group.vigencia,
                teachers: group.docentes,
                id: Number(group.id_distribucion_escuela + `${index}`),
                nombre_escuela: group.escuela,
              };
            })
            ?.filter((grupo) => grupo?.usuarios > 0);
          setRows(groupsList);
        }
      })
      .catch((error) => {
        //  dispatch({
        //    type: types.loadActivitiesList,
        //    payload: [],
        //  });
      });

    return () => {};
  }, [userId]);

  return (
    <div style={{ height: 630, width: "100%" }}>
      <DataGrid
        rows={rows.filter((row) => {
          if (search) {
            return (
              row?.teacher?.toLowerCase().startsWith(search.toLowerCase()) ||
              row?.grade?.toString() === search ||
              row?.nombreClase?.toString() === search
            );
          } else {
            return row;
          }
        })}
        // rows={rows}
        columns={columns}
        pageSize={10}
        // checkboxSelection
        disableSelectionOnClick
        disableColumnFilter
        disableColumnMenu
        rowsPerPageOptions={[10]}
      />
      <ModalConfirm
        open={confirmationModalOpen}
        handleClose={handleCloseConfirmationModal}
        handleConfirm={confirmDelete}
        title="¿Estás seguro de eliminar este grupo? Al continuar borrarás únicamente a los estudiantes del grupo. Los docentes los podrás eliminar o asignar a un nuevo grupo desde la tabla de usuarios."
      />
      <ModalMessage
        open={modalMessageOpen}
        handleClose={handleCloseMessageModal}
        title={confirmChangesResult}
      />
      {loading && <Loader />}
    </div>
  );
};

export default GroupsTable;
