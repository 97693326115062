import { Button, Grid, makeStyles } from "@material-ui/core";
import React, { useContext, useEffect } from "react";
import DrawerContext from "../../../../../usability/DrawerContext";
import { useOnLoadImages } from "../../../../hooks/useOnLoadImages";
import Loader from "../../../../Loader/Loader";
import Drawer from "../../ReadingTools/Drawer";

const useStyles =  makeStyles (theme =>({
     Contenedor:{
        background:'#E9FBF9',
        width:'100vw',
        overflowX:'clip',
        [theme.breakpoints.down(1161)]:{
            background:'none',
           overflowX:'unset',
        },
        [theme.breakpoints.down(600)]:{
            marginTop: '79.51px',
        }
    },
      ContainerInfoLectura: {
    position: "relative",
    height: "86px",
    [theme.breakpoints.down(502)]:{
        height: "43px",
    },
    background: "#76D4C3",
    "& .BarraInfoLectura": {
      height: "86px",
      background: `Url(https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Barras/Azul.png)`,
        [theme.breakpoints.down(502)]:{
            height: "43px",
        },
    },
    "& .InfoLectura": {
      position: "absolute",
      height: "86px",
      width: "219px",
      boxSizing: "border-box",
      padding: "17.2px 0",
      background: "#FAFAFA",
      borderRadius: "50px",
      boxShadow: "2px 2px 2px 2px #00000029",
      zIndex: "4",
        [theme.breakpoints.down(502)]:{
            height: "43px",
            width: "109px",
            padding: "8.6px 0",
            borderRadius: "25px",
        },
    },
    "& .InfoLectura:before": {
      position: "absolute",
      display: "flex",
      boxSizing: "border-box",
      padding: "0 0 0 10px",
      justifyContent: "center",
      alignItems: "center",
      height: "39.88px",
      width: "39.88px",
      borderRadius: "50%",
      content: '""',
      boxShadow: "3px 0px .5px 0px #00000029",
      background: "#FAFAFA",
      left: "90px",
      top: "65px",
      transform: "rotate(0.25turn)",
      [theme.breakpoints.down(502)]:{
        left: "45px",
        top: "30px",
        height: "20.88px",
        width: "20.88px",
        },
    },
    "& .InfoLectura:after": {
      content: '""',
      height: "15px",
      width: "15px",
      borderTop: "2px solid #20A698",
      borderLeft: "2px solid #20A698",
      position: "absolute",
      bottom: "-10px",
      left: "Calc(50% - 8px)",
      transform: "rotate(0.625turn)",
      [theme.breakpoints.down(502)]:{
        height: "10px",
        width: "10px",
        left: "Calc(50% - 4.5px)",
        bottom: "-3px",
        borderTop: "1px solid #20A698",
        borderLeft: "1px solid #20A698",
        },
    },
    "& .Sesion": {
      borderLeft: "2px solid #D8D8D8",
    },
    "& .Dato": {
      margin: "0 0 5px 0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#20A698",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        margin: "0 0 2.5px 0",
        },
    },
    "& .Caracter": {
      margin: "0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#000000",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        },
    },
       '& .Regresar':{
        color: '#F2624F',
        border: '#F2624F 1px solid',
        height: '30px',
        width:'100px',
        borderRadius: '20px',
        letterSpacing: '0.14px',
        textTransform: 'none',
        background:'#FEFAF1',
        top:'calc(50% - 15px)',
        left:'5%',
        zIndex:4,
        [theme.breakpoints.down(502)]: {
          font:'bold 10px lato',
          width: "60px",
        },
      },
  },
    ContenedorLectura:{
        background:'#fff',
        width:'1160px',
        height:'100%',
        padding:'60px 168px 35px ',
        
        transform: (props) =>
        props.scale > 1 ? `scale(${props.scale}) translate(0px, 10%)` : `none`,
        [theme.breakpoints.down(1161)]:{
            width:'502px',
            padding:'60px 0 0 0',
          },
        [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },

        '& .Escritorio':{
            display:'block',
            [theme.breakpoints.down(1161)]:{
                display:'none'
            },
        },
        '& .Tablet':{
            display:'none',
            [theme.breakpoints.down(1161)]:{
                display:'block'
            },
        }
    },
    ContainerTitulo:{
        margin:'0 0 34px 0',
        [theme.breakpoints.down(1161)]:{
            margin:'0 0 34px 0',
        },
        '& .Titulo':{
            letterSpacing: '0px',
            color: '#FF0000',
            margin:'0',
            textAlign:'center',
            font:'48px fredoka one',
            lineHeight:'58px',
            [theme.breakpoints.down(1161)]:{
                font:'43px fredoka one',
                lineHeight:'52px',
            },
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            margin:'0 0 36px 0',
            color:'#000',
            font:'22px lato',
            lineHeight : '30px',
            letterSpacing: '0.20px',
            [theme.breakpoints.down(1161)]:{
                font:'18px lato',
                lineHeight :'28px',
                letterSpacing: '0.17px',
            },
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        "& .SubTitulo": {
            color: "#0189DE",
            margin: "0",
            font: "bold 30px lato",
            letterSpacing: ".28px",
            lineHeight: "30px",
            [theme.breakpoints.down(1161)]: {
              lineHeight: "28px",
              font: "bold 26px lato",
              letterSpacing: "0.24px",
            },
            [theme.breakpoints.down(502)]: {
              font: "bold 22px lato",
              lineHeight: "24px",
              letterSpacing: "0.21px",
            },
        },
        "& .SubTitulo2": {
            color: "#EA3323",
            margin: "0",
            font: "bold 26px lato",
            letterSpacing: ".24px",
            lineHeight: "28px",
            [theme.breakpoints.down(1161)]: {
                font: "bold 22px lato",
                lineHeight: "24px",
                letterSpacing: "0.21px",
            },
            [theme.breakpoints.down(502)]: {
                font: "bold 18px lato",
                lineHeight: "20px",
                letterSpacing: "0.16px",
            },
        },
        '& .TextImg':{
            font:'20px lato',
            color:'#717476',
            letterSpacing:'.19px',
            lineHeight:'30px',
            margin:'24px 0 0 0',
            [theme.breakpoints.down(1161)]:{
                font:' 16px lato',
                lineHeight :'25px',
                letterSpacing: '0.15px',
            },
            [theme.breakpoints.down(502)]:{
                font:' 11px lato',
                lineHeight :'14px',
                letterSpacing: '0.10px',
            },
        },

        '& .Imagen':{
            [theme.breakpoints.down(1161)]:{
                width:'100%',
            },
        }, 
        '& .Imagen2':{
            [theme.breakpoints.down(1161)]:{
                width:'80%',
            },
        }, 
    }
}));

const Simetrías = (props) => {

  const {activeStep, setActiveStep, handlePreviewStep, id_actividad} = props
  
  const handleContinue = () => {
    setActiveStep(activeStep - 1)
    handlePreviewStep()
  }

  const {
    handleSize,
    disabledZoom,
    closeZoom,
    handleZoom,
    scale
  }= useContext(DrawerContext);
  const [loading, setLoading] = React.useState(true);
  const wrapperRef = React.useRef(null);
  const imagesLoaded = useOnLoadImages(wrapperRef);

  const detectSize = () => {
    const lectura = document.getElementById("lectura");
    const newWidth = lectura?.offsetWidth;
    const newHeight = lectura?.offsetHeight;
    setLoading(false);
    if (lectura) handleSize(newHeight, newWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", detectSize);
    return () => {
      handleZoom()
      window.removeEventListener("resize", detectSize);
      closeZoom()
    };
  }, []);

  const classes = useStyles({ disabledZoom,scale });
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
             <Grid container item justifyContent="center" className={classes.ContainerInfoLectura}>
                       <Grid container className="BarraInfoLectura">
            <Button className="Regresar" onClick={handleContinue}>
                Regresar
            </Button>
        </Grid>
               <Grid container item className="InfoLectura">
                    <Grid container item direction="column" xs={6} justifyContent="center">
                        <p className="Dato">Grado</p>
                        <p className="Caracter">4</p>
                    </Grid>
                    <Grid container item direction="column" xs={6} justifyContent="center" className="Sesion">
                        <p className="Dato">Sesión</p>
                        <p className="Caracter">48</p>
                    </Grid>
               </Grid>
            </Grid>
            {!imagesLoaded && <Loader />}
        <Grid
            container
            item
            className={classes.ContenedorLectura}
            justifyContent="center"
            id="lectura"
            onLoad={detectSize}
            ref={wrapperRef}
        >
<Drawer id_lectura={id_actividad} />
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >
                        Simetrías                    
                    </h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                      <p className="Lectura">
                        ¿Sabías que las abejas construyen sus panales con hexágonos exactos? ¿A qué crees 
                        que se deba que usen hexágonos y no cualquier otra figura? Para averiguarlo, 
                        analizaremos algunos conceptos que vinculan a estos pequeños insectos con la 
                        geometría.<br/><br/> 
                        
                        <h3 className='SubTitulo'>Teselación regular</h3><br/>
                        
                        A la manera que utilizan las abejas de cubrir una superficie plana se le conoce como 
                        <em> teselación regular</em> . Existen dos principios que rigen a las teselaciones: que no queden 
                        espacios entre cada una de las figuras y que éstas no se superpongan. ¿Has notado 
                        que los panales no tienen hoyos? Ésta es la razón.<br/><br/>
                        
                        Existen tres figuras que pueden acomodarse en un área con exactitud y que cumplen 
                        los principios mencionados. Se trata de los triángulos equiláteros, los cuadrados y los 
                        hexágonos. Si usas otras formas, no habrá manera de que abarques todo, habrá 
                        espacios entre cada una y posiblemente tengas que encimar una con otra. <br/><br/>
                        
                        Observa el siguiente ejemplo. Las siluetas no dejan espacios entre sí, no se enciman y 
                        ocupan la superficie por completo.
                      </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <Grid container justifyContent="center" >
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img4to/Sesion48/1.png" alt="Tarjeta" />
                        </Grid>
                        <Grid container justifyContent="center" >
                            <p className='TextImg'>
                                Teselación hecha con cuadrados y triángulos.
                            </p>                        
                        </Grid>                    
                    </Grid>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                            Para elaborar una de éstas es necesario realizar variaciones en las formas, pero 
                            cuidando que el contorno siga siendo el mismo. Es decir, que los cambios aplicados 
                            no alteren las medidas ni el perímetro.<br/><br/> 
                            
                            <h3 className='SubTitulo2'>Traslación</h3><br/>
                            
                            La primera transformación es la traslación. Trasladar o mover una imagen mantiene 
                            su simetría, pues no cambia la forma ni el tamaño.
                        </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <Grid container justifyContent="center" >
                            <img className="Imagen2" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img4to/Sesion48/2.png" alt="Tarjeta" />
                        </Grid>
                        <Grid container justifyContent="center" >
                            <p className='TextImg'>
                                La translación consiste en desplazar las figuras conservando su simetría. 
                            </p>                        
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                            <h3 className='SubTitulo2'>Rotación</h3><br/>
                            
                            La segunda es la rotación. Rotar o girar tampoco cambia nada, más que la orientación.
                        </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <Grid container justifyContent="center" >
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img4to/Sesion48/3.png" alt="Tarjeta" />
                        </Grid>
                        <Grid container justifyContent="center" >
                            <p className='TextImg'>
                                La rotación consiste en girar la imagen.
                            </p>                        
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                            <h3 className='SubTitulo2'>Reflexión</h3><br/>
                            
                            La tercera es la reflexión. Actúa como un espejo. Cuando te miras en uno, no cambian 
                            tu cuerpo ni tu altura, pero sí tu orientación. Para reflejar algo es necesario imaginar 
                            una línea que funcione como un espejo.
                        </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <Grid container justifyContent="center" >
                            <img className="Imagen2" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img4to/Sesion48/4.png" alt="Tarjeta" />
                        </Grid>
                        <Grid container justifyContent="center" >
                            <p className='TextImg'>
                                La reflexión consiste en hacer otra figura idéntica a la original.                            
                            </p>                        
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                            <h3 className='SubTitulo2'>Pasos</h3><br/>
                            
                            El último cambio es una combinación de reflexión y traslación. Es llamada pasos. ¿Por 
                            qué? Imagina una huella de tus pies marcada en la arena. Si la reflejas, dibujarías tus 
                            dos huellas. Si trasladas la huella reflejada, parecerá que acabas de dar un paso. Si 
                            repites esto varias veces, crearás un camino sobre la arena.
                        </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <Grid container justifyContent="center" >
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img4to/Sesion48/5.png" alt="Tarjeta" />
                        </Grid>
                        <Grid container justifyContent="center" >
                            <p className='TextImg'>
                                Los pasos combinan la reflexión y la traslación.    
                            </p>                        
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                            Al aplicar este método a un hexágono se obtiene una teselación hexagonal, que es 
                            parecida a un panal de abejas, como viste al inicio.<br/><br/> 
                            
                            <h3 className='SubTitulo'>Teselados semirregulares</h3><br/>
                            
                            ¿Crees que otras formas geométricas puedan combinarse con los teselados regulares 
                            para hacer una nueva teselación? ¡La respuesta es sí! Reciben el nombre de <em>teselaciones
                            semirregulares</em> y sólo existen ocho.
                        </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <Grid container justifyContent="center" >
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img4to/Sesion48/6.png" alt="Tarjeta" />
                        </Grid>
                        <Grid container justifyContent="center" >
                            <p className='TextImg'>
                                Ejemplo de teselación semirregular.
                            </p>                        
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                            <h3 className='SubTitulo'>No todo es teselación</h3><br/> 
                            
                            Observa la siguiente imagen con detenimiento. ¿Crees que sigue los principios 
                            expuestos a lo largo del texto? ¿Cómo son las siluetas que componen su diseño?
                        </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img4to/Sesion48/7.png" alt="Tarjeta" />
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                            Como notaste en este ejemplo, la teselación no es la única manera de envolver una 
                            superficie sin dejar espacios y sin que las figuras se superpongan. Se pueden llenar 
                            los vacíos con las figuras irregulares que el autor elija. Puedes encontrar ejemplos de 
                            ello en las creaciones de artistas modernos y en el arte mural musulmán.<br/><br/> 
                            
                            Ahora sabes que existen distintas formas de abarcar un área plana en su totalidad y 
                            que las abejas utilizan el hexágono porque con ellos cubren el mayor espacio 
                            utilizando la menor cantidad de cera. De esta manera almacenan miel en el panal 
                            eficientemente. ¡Eso las convierte en auténticas <em>geómetras</em>, pues conocen de
                            geometría!<br/><br/>
                            
                            Ya puedes crear tu propia teselación con recortes de papel o incluso dibujarlo en una 
                            hoja en blanco. ¡Inténtalo!
                        </p>
                    </Grid>
                </Grid>
        </Grid>
        <Grid container item style={{ marginTop: 80}} ></Grid>
    </Grid>
    );
}
 
export default Simetrías ;
