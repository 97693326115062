import React from "react";
import ProtectedRoute from "./ProtectedRoute";
import Biblioteca from "../pages/Container/ContenedorBiblioteca";
import WelcomePage from "../pages/Container/ContenedorWelcomePage";
import Profile from "../pages/Container/ContenedorPerfil_del_alumno";
import Avatars from "../pages/Container/ContenedorAvatars";
import BadgesContainer from "../pages/badges/BadgesContainer";
import LibraryReadings from "../pages/Biblioteca/LibraryReading";
import MapTutorial from "../pages/tutorial/Map/MapTutorial";
import PerfilTutorial from "../pages/tutorial/PerfilTutorial";
import ContainerSession3Tutorial from "../pages/tutorial/activity/sesione03/ContainerSession03";
import ActivityS03Tutorial from "../pages/tutorial/activity/actividad03/Activity_Container";

const routes = [
  <ProtectedRoute exact path="/Perfil_del_alumno" component={Profile} />,
  <ProtectedRoute exact path="/welcome_page" component={WelcomePage} />,
  <ProtectedRoute exact path="/insignias" component={BadgesContainer} />,
  <ProtectedRoute exact path="/Biblioteca" component={Biblioteca} />,
  <ProtectedRoute exact path="/Avatars" component={Avatars} />,
  <ProtectedRoute exact path="/guia/mapa" component={MapTutorial} />,
  <ProtectedRoute exact path="/guia/perfil" component={PerfilTutorial} />,
  <ProtectedRoute
    exact
    path="/guia/session03"
    component={ContainerSession3Tutorial}
  />,
  <ProtectedRoute
    exact
    path="/guia/actividad03"
    component={ActivityS03Tutorial}
  />,
  <ProtectedRoute
    path="/lecturas/:id_grado/:id_actividad"
    component={LibraryReadings}
  />,
];

const Alumno = routes.map((route, key) => {
  return { ...route, key: key };
});
export default Alumno;
