import { Dialog, DialogContent, makeStyles, styled } from "@material-ui/core";
import React from "react";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-root": {
    // backdropFilter: "blur(10px)",
    height: "100%",
  },
  "& .MuiDialogContent-root": {
    padding: "0",
    position: "relative",
    height: "100%",
    // overflow: "hidden",
    [theme.breakpoints.up("sm")]: {
      // margin: "20px 0",
      paddingTop: "0",
      paddingBottom: "0",
    },
    [theme.breakpoints.up("sm")]: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      "@media(max-height:400px)": {
        justifyContent: "flex-start",
      },
    },
    [theme.breakpoints.up("xl")]: {
      justifyContent: "flex-start",
    },
  },
  "& .MuiDialog-paper": {
    borderRadius: "12.1px",
    backgroundColor: "transparent",
    boxShadow: "none",
    height: "100vh",
    width: "100vw",
    margin: "0px",
    maxHeight: "100vh",
    maxWidth: "1920px",
    [theme.breakpoints.up("lg")]: {
      margin: "0px",
    },
  },
  "& .MuiDialog-container": {
    // backdropFilter: "blur(10px)",
    alignItems: "flex-start",
    [theme.breakpoints.up("sm")]: {
      alignItems: "center",
    },
  },
}));

const useStyles = makeStyles((theme) => ({
  circle: {
    border: "none",
    backgroundColor: "transparent",
    position: "absolute",
    height: "67px",
    width: "67px",
    borderRadius: "50%",
    top: "calc(80px + 254.5px)",
    left: "314.5px",
    alignItems: "center",
    justifyContent: "center",
    // top: "269.5px",
    // left: "329.5px",
    display: "flex",
    backgroundImage: (props) => `url(${props.mapa})`,
    backgroundPosition: "-314.5px -254.5px",
    [theme.breakpoints.up("lg")]: {
      // left: "calc(3% + 314.5px)",
    },
    [theme.breakpoints.up("xl")]: {
      // left: "306px",
      // left: "314.5px", - 8.5
    },
    [theme.breakpoints.up("2320")]: {
      // left: "calc(2% + 314.5px)",
    },
    "@media(min-height:1180px)": {
      top: "30.97%",
    },
  },
  Button: {
    cursor: "pointer",
    border: "none",
    backgroundColor: "transparent",
    // position: "absolute",
    height: "37px",
    width: "37px",
    borderRadius: "50%",
    // top: "269.5px",
    // left: "329.5px",
    margin: "auto",
    boxShadow: "0px 0px 15px 15px #fff",
  },
  dialog: {
    backgroundColor: "#3f43b3",
    fontFamily: "Lato",
    letterSpacing: "0.36px",
    fontSize: "20px",
    color: "#ffffff",
    position: "absolute",
    left: "100px",
    width: "auto",
    minWidth: "425px",
    padding: "24px",
    boxSizing: "border-box",
    borderRadius: "21.25px",

    "&:before": {
      // display: (props) => (props.openMenuInDesktop ? "none" : "flex"),
      display: "flex",
      zIndex: 1,
      textTransform: "none",
      justifyContent: "center",
      alignItems: "center",
      // borderRadius: "25px",
      position: "absolute",
      content: '""',
      border: "15px solid",
      borderTopColor: "transparent",
      borderLeftColor: "transparent",
      borderBottomColor: "transparent",
      borderRightColor: "#3f43b3",
      font: "bold 14px lato",
      bottom: "calc(50% - 15px)",
      left: "-28px",
    },
  },
}));

const ButtonMapTutorial = ({ handleClose, open, handleIntervention, mapa }) => {
  const classes = useStyles({ mapa });
  return (
    <>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        maxWidth={false}
      >
        <DialogContent>
          <div className={classes.circle}>
            {/* Simulación del Botón del mapa bg del mapa pero ubicado en la posición que corresponde el botón de la sesión 3, dando la sensación del blur en el fondo */}
            <button
              className={classes.Button}
              onClick={handleIntervention}
            ></button>
            <p className={classes.dialog}>
              Aquí verás todas las sesiones del circuito, son en total 60, haz
              clic sobre la <b> sesión </b> a la que entrarás.
            </p>
          </div>
        </DialogContent>
      </BootstrapDialog>
    </>
  );
};

export default ButtonMapTutorial;
