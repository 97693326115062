import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
     Contenedor:{
        background:'#E9FBF9',
        width:'100vw',
        overflowX:'clip',
        [theme.breakpoints.down(1161)]:{
            background:'none',
           overflowX:'unset',
        },
        [theme.breakpoints.down(600)]:{
            marginTop: '79.51px',
        }
    },
  ContainerInfoLectura: {
    position: "relative",
    height: "86px",
    [theme.breakpoints.down(502)]:{
        height: "43px",
    },
    background: "#76D4C3",
    "& .BarraInfoLectura": {
      height: "86px",
      background: `Url(https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Barras/Azul.png)`,
        [theme.breakpoints.down(502)]:{
            height: "43px",
        },
    },
    "& .InfoLectura": {
      position: "absolute",
      height: "86px",
      width: "219px",
      boxSizing: "border-box",
      padding: "17.2px 0",
      background: "#FAFAFA",
      borderRadius: "50px",
      boxShadow: "2px 2px 2px 2px #00000029",
      zIndex: "4",
        [theme.breakpoints.down(502)]:{
            height: "43px",
            width: "109px",
            padding: "8.6px 0",
            borderRadius: "25px",
        },
    },
    "& .InfoLectura:before": {
      position: "absolute",
      display: "flex",
      boxSizing: "border-box",
      padding: "0 0 0 10px",
      justifyContent: "center",
      alignItems: "center",
      height: "39.88px",
      width: "39.88px",
      borderRadius: "50%",
      content: '""',
      boxShadow: "3px 0px .5px 0px #00000029",
      background: "#FAFAFA",
      left: "90px",
      top: "65px",
      transform: "rotate(0.25turn)",
      [theme.breakpoints.down(502)]:{
        left: "45px",
        top: "30px",
        height: "20.88px",
        width: "20.88px",
        },
    },
    "& .InfoLectura:after": {
      content: '""',
      height: "15px",
      width: "15px",
      borderTop: "2px solid #20A698",
      borderLeft: "2px solid #20A698",
      position: "absolute",
      bottom: "-10px",
      left: "Calc(50% - 8px)",
      transform: "rotate(0.625turn)",
      [theme.breakpoints.down(502)]:{
        height: "10px",
        width: "10px",
        left: "Calc(50% - 4.5px)",
        bottom: "-3px",
        borderTop: "1px solid #20A698",
        borderLeft: "1px solid #20A698",
        },
    },
    "& .Sesion": {
      borderLeft: "2px solid #D8D8D8",
    },
    "& .Dato": {
      margin: "0 0 5px 0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#20A698",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        margin: "0 0 2.5px 0",
        },
    },
    "& .Caracter": {
      margin: "0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#000000",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        },
    },   },

    ContenedorLectura:{
        background:'#fff',
        width:'1160px',
        height:'100%',
        padding:'0 ',
        position:'relative',
            transform: (props) =>
      props.scale > 1 ? `scale(${props.scale}) translate(0px, 10%)` : `none`,[theme.breakpoints.down(1161)]:{
            width:'768px',
          },
          [theme.breakpoints.down(768)]:{
            width:'350px',
        },
        '& .Derechos':{
            position:'absolute',
            bottom:'1.75%',
            textAlign:"center",
            margin:"0px",
            font:" 15px lato",
            color:"#FFFFFF",
            [theme.breakpoints.down(1161)]:{
                display:'none'
            },
        },
        '& .Escritorio':{
            display:'block',
            [theme.breakpoints.down(1161)]:{
                display:'none'
            },
        },
        '& .Tablet':{
            display:'none',
            [theme.breakpoints.down(1161)]:{
                display:'block'
            },
        },
        '& .Pos':{
            position:'absolute',
            margin:'0',
        },
        '& .Titulo':{
            font:'71px Fredoka One', 
            color:'#FFFFFF', 
            top:'0.76%',
            left:'5.26%',
            margin:'0',
            [theme.breakpoints.down(1161)]:{
                font:'46px Fredoka One', 
            },
            [theme.breakpoints.down(768)]:{
                font:'20px Fredoka One', 
            },
            [theme.breakpoints.down(350)]:{
                font:'16px Fredoka One', 
            },
        },  

        '& .Titulo2':{
            font:'50px Fredoka One', 
            color:'#FFFFFF',
            margin:'0',
            top:'1.83%',
            left:'25.08%',
            [theme.breakpoints.down(1161)]:{
                font:'32px Fredoka One', 
            },
            [theme.breakpoints.down(768)]:{
                font:'14px Fredoka One', 
            },
            [theme.breakpoints.down(350)]:{
                font:'12px Fredoka One', 
            },
        },
        '& .Titulo3':{
            font:'50px Fredoka One', 
            color:'#464E9C',
            margin:'0',
            top:'17.75%',
            [theme.breakpoints.down(1161)]:{
                font:'32px Fredoka One', 
            },
            [theme.breakpoints.down(768)]:{
                font:'14px Fredoka One', 
            },
            [theme.breakpoints.down(350)]:{
                font:'12px Fredoka One', 
            },
        },
        '& .TextA':{
            textAlign:'center',
            font:'bold 32px lato',
            color:'#464E9C', 
            letterSpacing: '0.3px',
            lineHeight : '44px',
            top:'3%',
            [theme.breakpoints.down(1161)]:{
                font:'bold 23px lato',
                lineHeight : '32px',
                letterSpacing: '0.22px',
            },
            [theme.breakpoints.down(768)]:{
                font:'12px lato',
                lineHeight : '15px',
                letterSpacing: '0.14px',
            },
            [theme.breakpoints.down(350)]:{
                font:'10px lato', 
            },
        },

        '& .SubTitulo':{
            textAlign:'center',
            font:'39px Fredoka One', 
            lineHeight:'47px',
            color:'#FFFFFF',
            margin:'0',
            [theme.breakpoints.down(1161)]:{
                font:'26px Fredoka One', 
                lineHeight:'31px',
            },
            [theme.breakpoints.down(768)]:{
                font:'13px Fredoka One', 
                lineHeight:'13px',
            },
            [theme.breakpoints.down(350)]:{
                font:'11px Fredoka One', 
                lineHeight:'13px',
            },
        },
        '& .Sub':{
            top:'4.51%'
        },
        '& .Sub2':{
            top:'46.45%'
        },
        '& .Sub3':{
            top:'77.35%'
        },
        '& .Sub4':{
            top:'86.85%'
        },
        '& .SubTitulo2':{
            textAlign:'center',
            font:'30px Fredoka One', 
            lineHeight:'30px',
            color:'#FFFFFF',
            margin:'0',
            top:'7.22%',
            [theme.breakpoints.down(1161)]:{
                font:'19px Fredoka One', 
                lineHeight:'19px',
            },
            [theme.breakpoints.down(768)]:{
                font:'9px Fredoka One', 
                lineHeight:'9px',
            },
            [theme.breakpoints.down(350)]:{
                font:'7px Fredoka One', 
                lineHeight:'8px',
            },
        },
        '& .Sub2-1':{
            left:'26.81%'
        },
        '& .Sub2-2':{
            left:'64.85%'
        },
        '& .Sub2-3':{
            top:'37.70%'
        },

        '& .Text':{
            textAlign:'center',
            color:'#FFFFFF',
            margin:'0',
            font:'21px Fredoka One', 
            lineHeight:'21px',
            [theme.breakpoints.down(1161)]:{
                font:'13px Fredoka One', 
                lineHeight:'13px',
            },
            [theme.breakpoints.down(768)]:{
                font:'6px Fredoka One', 
                lineHeight:'6px',
            },
        },
        '& .Text1':{
            top:'12.20%',
            left:'6.99%'
        },
        '& .Text2':{
            top:'12.08%',
            left:'32.84%'
        },
        '& .Text3':{
            top:'12.21%',
            left:'86.81%'
        },
        '& .Text4':{
            top:'15.11%',
            left:'14.82%'
        },
        '& .Text5':{
            top:'15.11%',
            left:'39.05%'
        },
        '& .Text6':{
            top:'15.25%',
            left:'63.9%'
        },
        '& .SubTitulo3':{
            textAlign:'center',
            color:'#FFFFFF',
            margin:'0',
            left:'38.01%',
            font:'48px Fredoka One', 
            lineHeight:'48px',
            [theme.breakpoints.down(1161)]:{
                font:'31px Fredoka One', 
                lineHeight:'31px',
            },
            [theme.breakpoints.down(768)]:{
                font:'15px Fredoka One', 
                lineHeight:'15px',
            },
        },
        '& .Sub-3':{
            left:'40%',
        },
        '& .Sub3-1':{
            top:'20.42%'
        },
        '& .Sub3-2':{
            top:'23.45%'
        },
        '& .Sub3-3':{
            top:'26.43%'
        },
        '& .Sub3-4':{
            top:'29.40%'
        },
        '& .Sub3-5':{
            top:'32.35%'
        },
        '& .Sub3-6':{
            top:'35.35%',
            color:'#464E9C'
        },
    
        '& .Imagen':{
            width:'100%'
        },
        '& .Text-2':{
            textAlign:'center',
            color:'#FFFFFF',
            margin:'0',
            left:'49.05%',
            font:'24px Fredoka One', 
            lineHeight:'24px',
            [theme.breakpoints.down(1161)]:{
                font:'15px Fredoka One', 
                lineHeight:'15px',
            },
            [theme.breakpoints.down(768)]:{
                font:'8px Fredoka One', 
                lineHeight:'8px',
            },
            [theme.breakpoints.down(350)]:{
                font:'7px Fredoka One', 
                lineHeight:'7px',
            },
        },
        '& .Text2-1':{
            top:'20.25%'
        },
        '& .Text2-2':{
            top:'23.25%'
        },
        '& .Text2-3':{
            top:'26.25%'
        },
        '& .Text2-4':{
            top:'29.40%'
        },
        '& .Text2-5':{
            top:'32.35%'
        },
        '& .Text2-6':{
            top:'35.35%',
            color:'#464E9C'
        },
        

        '& .Text-3':{
            textAlign:'center',
            color:'#000',
            margin:'0',
            font:'800 22px lato', 
            lineHeight:'30px',
            [theme.breakpoints.down(1161)]:{
                font:'800 13px lato', 
                lineHeight:'18px',
            },
            [theme.breakpoints.down(768)]:{
                font:'800 6px lato', 
                lineHeight:'8px',
            },
        },
        '& .Text3-1':{
            top:'42.43%',
            left:'2.50%',
        },
        '& .Text3-2':{
            top:'44.73%',
            left:'25.55%',
        },
        '& .Text3-3':{
            top:'42.43%',
            left:'49%',
        },
        '& .Text3-4':{
            top:'44.73%',
            left:'74.15%',
        },
        '& .T1':{
            top:'51.90%',
        },
        '& .T2':{
            top:'57.65%',
        },
        '& .T3':{
            top:'63.35%',
        },
        '& .T4':{
            top:'69.1%',
        },
        '& .T5':{
            top:'74.75%',
        },
        '& .L1':{
            left:'15.51%',
        },
        '& .L2':{
            left:'62.41%',
        },
        '& .L-1':{
            left:'28.18%',
            color:'#FFF'
        },
        '& .L-2':{
            left:'74.48%',
            color:'#FFF'
        },
        '& .Text-4':{
            textAlign:'center',
            color:'#000',
            margin:'0',
            font:'bold 22px lato', 
            lineHeight:'30px',
            [theme.breakpoints.down(1161)]:{
                font:'bold 13px lato', 
                lineHeight:'18px',
            },
            [theme.breakpoints.down(768)]:{
                font:'bold 6px lato', 
                lineHeight:'8px',
            },
        },
        '& .Rosa':{
            color:'#E94574'
         },
         '& .Naranja':{
             color:'#F6664D'
          },
        '& .Text4-1':{
            top:'79.85%',
            left:'15.56%',
        },
        '& .Text4-2':{
            top:'79.85%',
            left:'70.25%',
        },
        '& .Text4-3':{
            top:'84.10%',
            left:'50.04%',
        },
        '& .Text4-4':{
            top:'91.45%',
            left:'11%',
        },
        '& .Text4-5':{
            top:'91.45%',
            left:'38.27%',
        },
        '& .Text4-6':{
            top:'91.45%',
            left:'72.24%',
        },
        '& .Text4-7':{
            top:'96.07%',
            left:'22.32%',
        },
        '& .Text4-8':{
            top:'96.07%',
            left:'53.88%',
        },


    },

    
}));

const Cuida_los_oceanos = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
             <Grid container item justifyContent="center" className={classes.ContainerInfoLectura}>
               <Grid container className="BarraInfoLectura"></Grid>
               <Grid container item className="InfoLectura">
                    <Grid container item direction="column" xs={6} justifyContent="center">
                        <p className="Dato">Grado</p>
                        <p className="Caracter">4</p>
                    </Grid>
                    <Grid container item direction="column" xs={6} justifyContent="center" className="Sesion">
                        <p className="Dato">Sesión</p>
                        <p className="Caracter">14</p>
                    </Grid>
               </Grid>
            </Grid>

            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <h2 className='Pos Titulo'>
                    Cuidado de los océanos
                </h2>
                <h3 className='Pos Titulo2'>
                    Los problemas de los océanos
                </h3>
                <h3 className='Pos Titulo3'>
                    Origen de la contaminación
                </h3>
                <p className='Pos TextA'>
                    8 de junio,<br/> 
                    Día Mundial de los Océanos
                </p>

                <h3 className='Pos SubTitulo Sub'>
                    ¿Cuánta agua hay<br/> 
                    en nuestro planeta?
                </h3>
                <h3 className='Pos SubTitulo Sub2'>
                    Los 10 desechos<br/>
                    más frecuentes
                </h3>
                <h3 className='Pos SubTitulo Sub3'>
                    Consecuencias
                </h3>
                <h3 className='Pos SubTitulo Sub4'>
                    Soluciones
                </h3>
                <h3 className='Pos SubTitulo2 Sub2-1'>
                    70.8 %<br/>
                    agua
                </h3>
                <h3 className='Pos SubTitulo2 Sub2-2'>
                    29.2 %<br/>
                    tierra
                </h3>
                <h3 className='Pos SubTitulo2 Sub2-3'>
                    ¿Sabías que…?
                </h3>

                <p className='Pos Text Text1'>
                    Océano<br/>
                    Pacífico
                </p>
                <p className='Pos Text Text2'>
                    Océano<br/> 
                    Atlántico<br/> 
                    Norte
                </p>
                <p className='Pos Text Text3'>
                    Océano<br/>
                    Pacífico
                </p>
                <p className='Pos Text Text4'>
                    Océano<br/>
                    Pacífico<br/>
                    Sur
                </p>
                <p className='Pos Text Text5'>
                    Océano<br/> 
                    Atlántico<br/> 
                    Sur
                </p>
                <p className='Pos Text Text6'>
                    Océano<br/>  
                    Índico
                </p>
                <h3 className='Pos SubTitulo3 Sub3-1'>35 %</h3>
                <h3 className='Pos SubTitulo3 Sub3-2'>21 %</h3>
                <h3 className='Pos SubTitulo3 Sub3-3'>18 %</h3>
                <h3 className='Pos SubTitulo3 Sub3-4'>10 %</h3>
                <h3 className='Pos SubTitulo3 Sub-3 Sub3-5'>9 %</h3>
                <h3 className='Pos SubTitulo3 Sub-3 Sub3-6'>7 %</h3>

                <h3 className='Pos SubTitulo3 L1 T1'>26 %</h3>
                <h3 className='Pos SubTitulo3 L2 T1'>15 %</h3>
                <h3 className='Pos SubTitulo3 L1 T2'>14 %</h3>
                <h3 className='Pos SubTitulo3 L2 T2'>10 %</h3>
                <h3 className='Pos SubTitulo3 L1 T3'>7 %</h3>
                <h3 className='Pos SubTitulo3 L2 T3'>7 %</h3>
                <h3 className='Pos SubTitulo3 L1 T4'>6 %</h3>
                <h3 className='Pos SubTitulo3 L2 T4'>6 %</h3>
                <h3 className='Pos SubTitulo3 L1 T5'>5 %</h3>
                <h3 className='Pos SubTitulo3 L2 T5'>4 %</h3>




                <p className='Pos Text-2 Text2-1'>
                    Extracción<br/> 
                    de aceite<br/> 
                    y petróleo
                </p>
                <p className='Pos Text-2 Text2-2'>
                    Accidentes<br/> 
                    petroleros<br/> 
                    y de barcos
                </p>
                <p className='Pos Text-2 Text2-3'>
                    Desperdicios<br/> 
                    de fábricas<br/> 
                    y ciudades
                </p>
                <p className='Pos Text-2 Text2-4'>
                    Desperdicios<br/> 
                    radioactivos
                </p>
                <p className='Pos Text-2 Text2-5'>
                    Basura<br/> 
                    doméstica
                </p>
                <p className='Pos Text-2 Text2-6'>
                    Componentes<br/> 
                    químicos
                </p>

                <p className='Pos Text-3 Text3-1'>
                    Cada año se tiran de 3.5 a<br/> 
                    6 millones de toneladas<br/> 
                    de aceite en los océanos.
                </p>
                <p className='Pos Text-3 Text3-2'>
                    El 20 % de los residuos se<br/>
                    tira desde los barcos.
                </p>
                <p className='Pos Text-3 Text3-3'>
                    El 2 % de la superficie de<br/> 
                    las aguas está cubierta<br/> 
                    por una película de aceite.
                </p>
                <p className='Pos Text-3 Text3-4'>
                    El 80 % de la basura llega<br/>
                    desde la costa.
                </p>

                <p className='Pos Text-3 L-1 T1'>
                Colillas<br/> de cigarros
                </p>
                <p className='Pos Text-3 L-2 T1'>
                Desechables<br/> de plástico
                </p>
                <p className='Pos Text-3 L-1 T2'>
                    Desechos<br/> diversos
                </p>
                <p className='Pos Text-3 L-2 T2'>
                    Latas<br/> de bebidas
                </p>
                <p className='Pos Text-3 L-1 T3'>
                    Latas<br/> de conserva
                </p>
                <p className='Pos Text-3 L-2 T3'>
                    Botellas<br/> de plástico
                </p>
                <p className='Pos Text-3 L-1 T4'>
                    Botellas<br/> de cristal
                </p>
                <p className='Pos Text-3 L-2 T4'>
                    Bolsas<br/> de plástico
                </p>
                <p className='Pos Text-3 L-1 T5'>
                    Redes<br/> de plástico 

                </p>
                <p className='Pos Text-3 L-2 T5'>
                    Tapas<br/> de plástico
                </p>

                <p className='Pos Text-4 Text4-1'>
                    Millones de <span className='Rosa'>animales</span><br/>
                    resultan <span className='Rosa'>heridos</span> por<br/>
                    desechos plásticos.
                </p>
                <p className='Pos Text-4 Text4-2'>
                    <span className='Rosa'>Desaparecen<br/>
                    especies</span><br/>
                    de animales y peces.
                </p>
                <p className='Pos Text-4 Text4-3'>
                    Se genera <br/> 
                    <span className='Rosa'>sobrepesca.</span>
                </p>
                <p className='Pos Text-4 Naranja Text4-4'>
                    Desarrollar otro tipo<br/> 
                    de energías.
                </p>
                <p className='Pos Text-4 Naranja Text4-5'>
                    Disminuir la concentración<br/>
                    de dióxido de carbono.
                </p>
                <p className='Pos Text-4 Naranja Text4-6'>
                    Usar combustible<br/> 
                    ecológico.
                </p>
                <p className='Pos Text-4 Naranja Text4-7'>
                    Construir más plantas<br/> 
                    de tratamiento de residuos<br/> 
                    y aguas residuales.
                </p>
                <p className='Pos Text-4 Naranja Text4-8'>
                    Mejorar el proceso<br/> 
                    de extracción y transporte<br/> 
                    de petróleo.
                </p>




               
                <p className="Derechos">
                        © 2022. Todos los derechos reservados, Pearson, Circuito de lectores.
                </p>   
                <img className='Imagen' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img4to/Sesion14/1.png" alt="Fondo" />
      
            </Grid>

        </Grid>
    );
}
 
export default Cuida_los_oceanos;
