import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
    ContenedorLectura:{
        width:'502px',
                  [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
        '& .Escritorio':{
            display:'flex',
            [theme.breakpoints.down(1161)]:{
                display:'none'
            },
        },
        '& .Tablet':{
            display:'none',
            [theme.breakpoints.down(1161)]:{
                display:'flex'
            },
        }
    },
    ContainerTitulo:{
        margin:'0 0 34px 0',
        '& .Titulo':{
            letterSpacing: '0px',
            color: '#458CFC',
            margin:'0',
            textAlign:'center',
            font:'43px fredoka one',
            lineHeight:'52px',
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            color:'#000',
            margin:'0',
            font:'18px lato',
            lineHeight :'28px',
            letterSpacing: '0.17px',
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .Imagen':{
            width:'100%',
        },  
       
    },

}));

const Viajes_en_el_tiempo = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >
                        Viajes en el tiempo
                    </h2>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container xs={12} justifyContent="center">
                        <p className="Lectura ">
                            El auditorio estaba repleto, no cabía nadie más; incluso había personas sentadas en 
                            las escaleras. A todos les entusiasmaba escuchar la conferencia. Y no era para menos: 
                            la doctora Pires acababa de viajar en el tiempo y regresaba al presente para contar 
                            su experiencia. Por fortuna, yo me senté en primera fila, listo para registrar todo lo 
                            que dijera en mi libreta.<br/><br/> 
                            
                            Yo tenía una idea general de cómo había realizado su hazaña. Por ejemplo, sabía que 
                            había conducido un cohete que superaba la velocidad de la luz y que logró atravesar
                            un agujero negro con ayuda de él. De esta forma conoció el futuro. Eso ya lo conocía, 
                            lo que me interesaba era saber cómo vivían en ese otro tiempo. “Se lo preguntaré si 
                            tengo oportunidad”, me propuse antes de entrar.
                        </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12} >
                        <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img3ro/Sesion21R/1.png" alt="Tarjeta" />
                    </Grid>
                </Grid>

                <Grid container sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}  justifyContent='center'>
                        <p className="Lectura ">
                            Ella llegó a las cinco de la tarde en punto. Se sentó, tomó el micrófono y dijo:<br/><br/> 
                            
                            —Muchas gracias por estar aquí. Es un gusto para mí platicar con ustedes sobre esta 
                            increíble vivencia, pues tengo muchas cosas que quiero contarles. Para empezar, 
                            quisiera mostrarles un artefacto que se ha vuelto indispensable para la vida diaria del 
                            tiempo que visité, casi como una mano adicional de los seres humanos. <br/><br/> 
                            
                            Mientras hablaba, sacó de una de sus bolsas un rectángulo pequeño de color negro. 
                            “Una televisión en miniatura”, pensé, sin poder reconocer con exactitud de qué se 
                            trataba. <br/><br/> 
                            
                            —Este objeto —dijo y apretó algo en la pantalla del rectángulo para que se llenara de 
                            luz— es un teléfono móvil. Sirve para comunicarse con otras personas a distancia, 
                            además de que tiene cámara fotográfica integrada y mensajería instantánea.
                        </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12} >
                        <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img3ro/Sesion21R/2.png" alt="Tarjeta" />
                    </Grid>
                </Grid>

                <Grid container sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}  justifyContent='center'>
                        <p className="Lectura ">
                            Todos en la sala nos quedamos con la boca abierta. Nos pareció increíble que dentro 
                            de ese objeto tan pequeño hubiera tantas cosas. Alguien alzó la mano y preguntó:<br/><br/> 
                            
                            —¿En dónde están los cables? <br/><br/> 
                            
                            —Este teléfono no los necesita: se puede llevar en la bolsa del pantalón a cualquier 
                            lado y continúa funcionando de manera inalámbrica. <br/><br/> 
                            
                            Se escucharon murmullos de sorpresa entre el auditorio. Estoy seguro de que todos 
                            recordaron como yo el teléfono grande y pesado de sus casas, que es complicado 
                            mover a otro sitio. <br/><br/> 
                            
                            La conferencia siguió y la doctora Pires nos habló de automóviles que se conducen 
                            solos, de unos artefactos llamados <em>computadoras</em>, que son como nuestras máquinas 
                            de escribir, pero no usan hojas de papel. Y también nos describió los correos 
                            electrónicos, que llegan de un lado a otro sin que ningún mensajero los lleve en 
                            persona. ¡Nadie podía creer lo que nos decía la viajera! Sin duda alguna, el futuro 
                            será un lugar increíble para vivir.
                        </p>
                    </Grid>
                </Grid>
        </Grid>
    </Grid>
    );
}
 
export default Viajes_en_el_tiempo;
