import { Grid, makeStyles } from "@material-ui/core";
import React, { useState } from "react";
import map from "./MapaG5.jpg";
import Loader from "../../Loader/Loader";

import { useContext } from "react";
import AuthContext from "../../../auth/AuthContext";


import ButtonMap from "./Button/MapButton";
import ButtonMapS1 from "./Button/MapButtonS1";
import ButtonMapS60 from "./Button/MapButtonS60";
import ButtonMapS56 from "./Button/MapButtonS56";
import { useHistory } from "react-router-dom";
import MapButtons from "./Button/MapButtons";
import useGetMapData from "../../../hooks/useGetMapData";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100vw",
    marginTop: "80px",
    minHeight: "calc(100vh - 80px)",
    overflowY: "auto",
    // overflow: "auto",
    backgroundColor: "#7384d7 ",

    display: "grid",
    justifyContent: "start",
    [theme.breakpoints.up("sm")]: {
      marginTop: "0px",
      // top: "0",
    },
    [theme.breakpoints.up("lg")]: {
      width: "100%",
    },
    [theme.breakpoints.up("xl")]: {
      justifyContent: "center",
      alignItems: "center",
    },
  },
  containerMap: {
    // position: "absolute",
    // top: "80px",
    // left: "0",

    display: "flex",
    height: "1080px",
    // overflow: "hidden",
    width: "1920px",
    position: "relative",
    // marginTop: "80px",
    [theme.breakpoints.up("md")]: {
      marginTop: "0px",
      // top: "0",
    },
    "& .map": {
      height: "100%",
      width: "100%",
      boxShadow: "98px 117px 218px #6f6593 , -101px 93px 162px  #6f6593 ",
      // marginTop: "80px",
      [theme.breakpoints.up("md")]: {
        marginTop: "0px",
        // top: "0",
      },
    },
  },
}));

const Map5 = () => {
  const { user, dispatch } = useContext(AuthContext);
  const [loading, setLoading] = useState(true);
  const [loadingInterface, setLoadingInterface] = useState(true);

  const history = useHistory();

  const { loadActivitiesList } = useGetMapData(
    5,
    setLoading,
    dispatch,
    history
  );

  const activitiesList = user.activitiesList || [];

  const classes = useStyles();
  return (
    <>
      <Grid className={classes.container}>
        <Grid className={classes.containerMap}>
          <img
            className="map"
            src={map}
            alt="Mapa"
            loading="lazy"
            onLoad={() => {
              setLoadingInterface(false);
            }}
          />
     {activitiesList.length ? (
          <MapButtons
            loadActivitiesList={loadActivitiesList}
            activitiesList={activitiesList}
            grade="grado5"
            ButtonMap={ButtonMap}
            ButtonMapS1={ButtonMapS1}
            ButtonMapS56={ButtonMapS56}
            ButtonMapS60={ButtonMapS60}
          />
        ) : null}

          {loading || loadingInterface ? <Loader /> : <></>}
        </Grid>
      </Grid>
    </>
  );
};

export default Map5;
