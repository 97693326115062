import { Button, makeStyles, Grid, styled } from "@material-ui/core";
import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";

import AuthContext from "../../../auth/AuthContext";
import ContainerAdmin from "./ContainerAdmin";
import ModalPasswordChange from "../../Modals/ModalPasswordChange";
import cdlLogo from "../../imgs/CL_LOGO_FINAL_Login 1.png";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    padding: "48px 0 48px 0",
    background:
      "linear-gradient(217deg, rgba(255,194,0,0.4962185557816877) 2%, rgba(255,255,255,1) 25%, rgba(255,255,255,0.4962185557816877) 81%, rgba(144,81,177,1) 98%)",
    minHeight: "100%",
  },
  boxContainer: {
    background: "#fff",
    width: "90%",
    maxWidth: "1216px",
    minHeight: "70vh",
    borderRadius: "42px",
    padding: "30px",
    boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.5)",
    gap: "20px",
    [theme.breakpoints.down(1280)]: {
      width: "860px",
      height: "100%",
    },
    [theme.breakpoints.down(870)]: {
      width: "420px",
    },
    [theme.breakpoints.down(425)]: {
      width: "300px",
    },
    "& .Titulo": {
      font: "24px fredoka one",
      textAlign: "center",
      color: "#40ADA3",
      // font: "48px fredoka one",
      margin: "0px",
      // textAlign: "center",
      [theme.breakpoints.up("lg")]: {
        font: "42px fredoka one",
        // margin: "0 0 84px 34px",
      },
      [theme.breakpoints.up("xl")]: {
        font: "42px fredoka one",
      },
    },
  },
  dataContainer: {
    width: "100%",
    alignContent: "center",
    justifyContent: "center",
    alignItems: "center",
    gap: "30px",
  },
  table: {
    width: "100%",
    maxWidth: "576px",
    minHeight: "180px",
    [theme.breakpoints.down(870)]: {
      width: "420px",
    },
    borderRadius: "15px",
    border: "solid 1px rgba(46, 49, 60, 0.1)",
    backgroundColor: "#f5f5f5",
    alignContent: "center",
    justifyContent: "center",
    alignItems: "start",
    padding: "20px",
    display: "grid",
    gap: "30px",
    gridTemplateColumns: "1fr",
    gridTemplateRows: "1fr 1fr",
    gridTemplateAreas: `'admin groups'
    'country teachers'
    'city students'
    'address address'
    `,
    [theme.breakpoints.up("lg")]: {
      gap: "30px",
      gridTemplateColumns: "1fr 1fr",
      gridTemplateRows: "1fr",
    },
  },
  admin: {
    gridArea: "admin",
  },
  groups: {
    gridArea: "groups",
  },
  country: {
    gridArea: "country",
  },
  city: {
    gridArea: "city",
  },
  students: {
    gridArea: "students",
  },
  teachers: {
    gridArea: "teachers",
  },
  address: {
    gridArea: "address",
  },
  dataTR: {
    width: "100%",
    display: "flex",
    alignContent: "center",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    flexDirection: "column",
    [theme.breakpoints.up("lg")]: {
      flexDirection: "row",
      alignItems: "flex-start",
      gap: "5px",
    },
  },
  dataTH: {
    // width: "100%",
    margin: "0",
    fontFamily: "Lato",
    fontSize: "16px",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    // lineHeight: "2.38",
    letterSpacing: "0.15px",
    color: "#505759",
  },

  dataTD: {
    // width: "100%",
    margin: "0",
    fontFamily: "Lato",
    fontSize: "16px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    // lineHeight: "2.38",
    letterSpacing: "0.15px",
    color: "#505759",
  },

  avatar: {
    width: "163px",
    height: "163px",
    // margin: "39px 37px 101px 33px",
    // padding: "65px 32px 56px",
    backgroundColor: "#c5c3c3",
    borderRadius: "50%",
  },
  containerButtons: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    [theme.breakpoints.up("lg")]: {
      gap: "20px",
      flexDirection: "row",
    },
  },
  button: {
    color: "#B965D3",
    cursor: "pointer",
    textDecoration: "none",
    width: "100%",
    padding: "6px 10px",
    [theme.breakpoints.up("sm")]: {
      padding: "6px 10px",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "14px",
      padding: "6px 12px",
    },
    "&:hover": {
      // backgroundColor: "#B965D3 ",
    },
  },
}));

const WitheButton = styled(Button)(({ theme }) => ({
  color: "#9051b1",
  // borderColor: "#9051b1",
  border: "1px solid #9051b1",
  backgroundColor: "#FFFFFF",
  borderRadius: "30px",
  // margin: "0 auto",
  fontSize: "12px",
  width: "50%",
  maxWidth: "160px",
  textTransform: "unset",
  padding: "0",
  "&:hover": {
    backgroundColor: "#B965D310 ",
  },
}));

const PerfilAdministrador = () => {
  const classes = useStyles();
  const {
    user: { name, firstLastName, secondLastName, escuela, userId },
  } = useContext(AuthContext);
  const { adminInfo, avatar } = escuela;
  const [modalChangePasswordOpen, setModalChangePasswordOpen] = useState(false);
  const handleModalOpen = () => {
    setModalChangePasswordOpen(!modalChangePasswordOpen);
  };

  return (
    <ContainerAdmin>
      <Grid
        container
        item
        sm={12}
        alignItems="center"
        justifyContent="center"
        className={classes.mainContainer}
      >
        <Grid
          container
          item
          direction="column"
          className={classes.boxContainer}
        >
          <h2 className="Titulo">{escuela?.escuela}</h2>
          <Grid
            container
            direction="row"
            alignItems="center"
            className={classes.dataContainer}
          >
            <img
              src={avatar || cdlLogo}
              alt="Logo de Escuela"
              className={classes.avatar}
            />
            <Grid
              container
              direction="row"
              alignItems="center"
              className={classes.table}
            >
              <div className={clsx(`${classes.dataTR} ${classes.admin}`)}>
                <p className={classes.dataTH}>Nombre:</p>
                <p
                  className={classes.dataTD}
                >{`${name}  ${firstLastName}  ${secondLastName}`}</p>
              </div>
              <div className={clsx(`${classes.dataTR} ${classes.groups}`)}>
                <p className={classes.dataTH}>Grupos:</p>
                <p className={classes.dataTD}>{adminInfo?.grupos}</p>
              </div>
              <div className={clsx(`${classes.dataTR} ${classes.country} `)}>
                <p className={classes.dataTH}>País:</p>
                <p className={classes.dataTD}>{escuela?.pais}</p>
              </div>
              <div className={clsx(`${classes.dataTR} ${classes.teachers} `)}>
                <p className={classes.dataTH}>Docentes:</p>
                <p className={classes.dataTD}>{adminInfo?.profesores}</p>
              </div>
              <div className={clsx(`${classes.dataTR}${classes.city}`)}>
                <p className={classes.dataTH}>Ciudad:</p>
                <p className={classes.dataTD}>{escuela?.ciudad}</p>
              </div>
              <div className={clsx(`${classes.dataTR} ${classes.students}`)}>
                <p className={classes.dataTH}>Estudiantes:</p>
                <p className={classes.dataTD}>{adminInfo?.alumnos}</p>
              </div>
              <div className={clsx(`${classes.dataTR} ${classes.address}`)}>
                <p className={classes.dataTH}>Dirección:</p>
                <p className={classes.dataTD}>{escuela?.direccion}</p>
              </div>
            </Grid>
          </Grid>
          <Grid
            container
            item
            direction="row"
            alignItems="center"
            justifyContent="center"
            className={classes.containerButtons}
          >
            <WitheButton
              className={classes.button}
              onClick={handleModalOpen}
              variant="contained"
            >
              <Link className={classes.button} to="#">
                Cambiar contraseña
              </Link>
            </WitheButton>

            <WitheButton className={classes.button} variant="contained">
              <Link
                className={classes.button}
                to={{
                  pathname: `/admin-inst/grupos/${userId}`,
                  // state: { school: schoolName }
                }}
              >
                Ver grupos
              </Link>
            </WitheButton>
            <WitheButton className={classes.button} variant="contained">
              <Link
                className={classes.button}
                to={`/admin-inst/usuarios/${userId}`}
              >
                Ver usuarios
              </Link>
            </WitheButton>
            <ModalPasswordChange
              open={modalChangePasswordOpen}
              handleClose={handleModalOpen}
            />
          </Grid>
        </Grid>
      </Grid>
    </ContainerAdmin>
  );
};

export default PerfilAdministrador;
