import React from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import { Redirect } from "react-router-dom";

import { GridCloseIcon } from "@mui/x-data-grid";
import { Grid, makeStyles } from "@material-ui/core";
import axios from "../../../services/axios";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    // padding: theme.spacing(2),

    [theme.breakpoints.up("sm")]: {
      // margin: "20px 0",
      paddingTop: "0",
      paddingBottom: "0",
    },
  },
  "& .MuiDialog-paper": {
    borderRadius: "12.1px",
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
    display: "flex",
    justifyContent: "center",
    gap: "5%",
    marginBottom: "16px",
  },
  "& .MuiDialogTitle-root": {
    [theme.breakpoints.up("sm")]: {
      // margin: "20px 0",
      padding: "22px",
    },
  },
}));

const useStyles = makeStyles((theme) => ({
  containerContent: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    marginBottom: "20px",
    gap: "20px",
    [theme.breakpoints.up("sm")]: {
      // margin: "20px 0",
    },
  },
  containerButtons: {
    display: "flex",
    justifyContent: "center",
  },
  button: {
    width: "80%",
    maxWidth: "320px",
    borderRadius: "61px",
    // height: "50px",
    border: "none",
    backgroundColor: "#9051b1",
    color: "#fffffff",
    font: " bold Lato",
    fontSize: "16px",
    letterSpacing: "1.96px",
    textTransform: "none",
    textDecoration: "none",
    textAlign: "center",
    padding: "5px 0",
    fontWeight: "500",
    // minHeight: "50px",s
    "&:hover": {
      backgroundColor: "#B965D3",
      fontWeight: "bold",
    },
    [theme.breakpoints.up("lg")]: {
      padding: "10px 0",
      fontSize: "18px",
    },

    [theme.breakpoints.up("xl")]: {
      padding: "20px 0",
      fontSize: "22px",
      maxWidth: "450px",
    },
  },
  PurpleButton: {
    color: "#FFFFFF",
    backgroundColor: "#9051b1",
    borderRadius: "30px",
    alignSelf: "center",
    width: "100%",
    maxWidth: "238px",
    minHeight: "45px",
    border: "none",
    textTransform: "none",
    textDecoration: "none",
    textAlign: "center",
    cursor: "pointer",
    fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
    fontWeight: "500",
    padding: "6px 16px",
    lineHeight: "3",
    transition:
      "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    boxShadow:
      "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
    "&:hover": {
      backgroundColor: "#B965D3 ",
    },
    [theme.breakpoints.up("lg")]: {
      gridArea: "button",
    },
  },
  text: {
    // width: "364px",
    // height: "36px",
    // margin: "11px 63.7px 18px 35.3px",
    fontFamily: "Lato",
    margin: 0,
    // fontFamily: "Fredoka One",
    fontSize: "12px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "0.23px",
    textAlign: "center",

    [theme.breakpoints.up("lg")]: {
      fontSize: "16px",
      margin: "20px",
      fontWeight: "600",
    },
  },
  title: {
    font: "28px Fredoka One ",
    margin: "0",
    textAlign: "center",
    color: (props) => props.textColor,
    [theme.breakpoints.up("xl")]: {
      fontSize: "50px",
    },
  },
  welcomeText: {
    font: " bold 18px Lato",
    textAlign: "center",
    width: "100%",
    maxWidth: "448px",
    color: (props) => props.smallText,
    [theme.breakpoints.up("xl")]: {
      fontSize: "24px",
    },
  },

  containerInput: {
    gap: "20px",
    marginBottom: "5%",
  },
  inputLoginPassword: {
    width: "80%",
    maxWidth: "280px",
    height: "45px",
    padding: "0 27px",
    borderRadius: "61px",
    border: "solid #F3CD52 3px ",
    background: "#FFFFFF",
    font: "Helvetica",
    fontSize: "16px",
    "&:focus": {
      outline: "none",
    },
    [theme.breakpoints.up("xl")]: {
      height: "71px",
      maxWidth: "421px",
      fontSize: "18px",
    },
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 4 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <GridCloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const ModalIngresarCodigo = ({ open, handleClose }) => {
  const classes = useStyles();
  const [codeData, setCodeData] = React.useState({});
  const [referCode, setReferCode] = React.useState("");
  const [error, setError] = React.useState();

  const handleReferCode = (e) => {
    setReferCode(e.target.value);
  };

  const handleCode = () => {
    const body = {
      referCode,
    };
    axios
      .get(`/user/codes/info/get?codigo=${referCode}`, body)
      .then((response) => {
        if (response.status === 200) {
          const data = response.data.resultado.info_codigo;
          setCodeData(data);
        } else {
          console.log("sin rol");
        }
      })
      .catch((error) => {
        if (error.response) {
          // Request made and server responded
          const errorData = error.response.data;
          const mensaje =
            errorData?.detalles?.msg ||
            "Ha ocurrido un error al consumir los servicios, inténtelo de nuevo más tarde.";
          setError(mensaje);
        } else {
          // Something happened in setting up the request that triggered an Error
          setError("Ha ocurrido un error, inténtelo de nuevo más tarde");
        }
      });
  };

  const textColor = () => {
    return error ? { color: "red" } : { color: "#000" };
  };

  React.useEffect(() => {
    return () => {
      setCodeData("");
    };
  }, []);

  const close = () => {
    setCodeData("");
    setReferCode("");
    setError("");
    handleClose();
  };

  return (
    <>
      <BootstrapDialog
        onClose={close}
        aria-labelledby="customized-dialog-title"
        open={open}
        maxWidth="sm"
        fullWidth={true}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={close}
        ></BootstrapDialogTitle>
        <DialogContent>
          <Grid container justifyContent="center" alignItems="center">
            <Grid
              container
              item
              direction="column"
              justifyContent="space-around"
              alignItems="center"
              className={classes.containerContent}
            >
              <h1 className={classes.title}>Ingresar código</h1>
              <p className={classes.text} style={textColor()}>
                {!error
                  ? "Ingresa el código que te dieron para poder continuar."
                  : error}
              </p>
              <input
                type="code"
                placeholder="Ingresa el código"
                className={classes.inputLoginPassword}
                onChange={handleReferCode}
                value={referCode}
              ></input>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions
          disableSpacing={true}
          className={classes.containerButtons}
        >
          <button className={classes.PurpleButton} onClick={handleCode}>
            Acceder
          </button>
        </DialogActions>
      </BootstrapDialog>
      {codeData?.id_rol === 2 && (
        <Redirect
          to={{
            pathname: `/registro/docente`,
            state: { data: codeData },
          }}
        />
      )}
      {codeData?.id_rol === 1 && (
        <Redirect
          to={{
            pathname: `/registro/alumno`,
            state: {
              data: codeData,
            },
          }}
        />
      )}
    </>
  );
};
export default ModalIngresarCodigo;
