import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
   ContenedorLectura:{
        width:'502px',
         [theme.breakpoints.down(502)]:{
           width:'350px',
         },
       '& .Escritorio':{
           display:'block',
           [theme.breakpoints.down(1161)]:{
               display:'none'
           },
       },
       '& .Tablet':{
           display:'none',
           [theme.breakpoints.down(1161)]:{
               display:'block'
           },
       }
   },
    ContainerTitulo:{
        margin:'0 0 46px 0',
        '& .Titulo':{
            letterSpacing: '0px',
            color: '#0C90D6',
            margin:'0',
            textAlign:'center',
            font:'43px fredoka one',
            lineHeight:'52px',
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            margin:'0 0 36px 0 ',
            color:'#000',
            font:'18px lato',
            lineHeight :'28px',
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .Esp':{
            margin:'0',
        },
        '& .SubTitulo':{
            color:'#FF0000',
            margin:'0',
            font:'bold 22px lato',
            lineHeight :'24px',
            letterSpacing: '0.21px',
            [theme.breakpoints.down(502)]:{
                font:'bold 18px lato',
                lineHeight :'20px',
                letterSpacing: '0.16px',
            },
        },
        '& .TextImg':{
            margin:'24px 0 0 0',
            alignSelf:'flex-start',
            color:'#717476',
            font:' 16px lato',
            lineHeight :'25px',
            letterSpacing: '0.15px',
            [theme.breakpoints.down(502)]:{
                font:' 11px lato',
                lineHeight :'14px',
                letterSpacing: '0.10px',
            },
        },
        '& .Imagen':{
            width:'100%'
        },
        '& .Imagen2':{
             width:'100%'
        },
    }, 

}));

const Las_protestas_por_el_cambio_climático = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >
                    Las protestas <br/> 
                    por el cambio climático
                    </h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                            <h3 className='SubTitulo'>Para entender qué está sucediendo</h3><br/> 

                            El <em>cambio climático</em> es un proceso de transformación en la Tierra ocasionado por el 
                            descuido del ser humano y su comportamiento con respecto al entorno. Por ejemplo: 
                            la contaminación, la explotación inmoderada de la tierra o la destrucción de 
                            ecosistemas para construir empresas o fraccionamientos que no aportan nada al 
                            cuidado del planeta. <br/><br/>
                            
                            Actualmente, el cambio climático es la mayor amenaza medioambiental a la que se 
                            enfrenta la humanidad. Es por eso que niños y jóvenes salen a las calles exigiendo 
                            respuestas desde hace algunos años. Los países han propuesto soluciones para 
                            intentar corregir el rumbo. <br/><br/>
                            
                            <h3 className='SubTitulo'>Algunas acciones recientes respecto al cambio climático</h3><br/> 
                            
                            En los últimos años, niños y jóvenes han participado en el movimiento Fridays for 
                            Future (Los Viernes por el Futuro), que consiste en una serie de acciones e 
                            intervenciones permanentes para visibilizar el problema ante los gobiernos y exigir 
                            soluciones. <br/><br/>
                            
                            Este movimiento demanda acciones para combatir el cambio climático. Por ejemplo, 
                            exige que se escuche a los científicos y que se tomen decisiones basadas en su 
                            conocimiento. También pide que se controle el aumento de la temperatura y que los 
                            gobiernos cumplan acuerdos internacionales que enfrenten el cambio climático.<br/><br/>

                            Durante 2019, Fridays for Future organizó protestas en todo el mundo, conocidas 
                            como <em>huelgas globales por el cambio climático</em>. En ellas participaron miles de personas
                            en distintas ciudades y pueblos. Muchos de los asistentes fueron niños preocupados
                            por su futuro y por el planeta que exigen que se ponga un alto al daño al 
                            medioambiente. <br/><br/>
                            
                            En Latinoamérica han participado jóvenes activistas que se han hecho visibles por 
                            sus intervenciones públicas, como Xiye Bastida, Arabel Alí Mendoza, Yareni Pérez y 
                            Monse González. Si quieres conocer más sobre su labor, puedes buscar sus nombres 
                            en la red.
                        </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <Grid container >
                            <img className='Imagen' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img2do/Sesion37/1.png"  alt="Tarjeta" />
                        </Grid>
                        <Grid container >
                            <p className='TextImg'>Gracias a las protestas sociales pacíficas, algunos gobiernos escuchan las demandas de la 
                            sociedad y buscan tomar acciones.</p>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} >
                        <p className="Lectura Esp">
                            Como consecuencia de la pandemia provocada por el Covid-19, las actividades del 
                            movimiento disminuyeron en 2020 y 2021, pero hubo varias protestas virtuales. 
                            También se hicieron algunas manifestaciones presenciales cuidando las medidas 
                            sanitarias correspondientes.
                        </p>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent='center'>
                    <img className='Imagen2' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img2do/Sesion37/2.png"  alt="" />
                    <Grid container justifyContent='center'>
                        <p className='TextImg'>Recuento de acciones de la protesta global por el cambio climático.</p>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} >
                        <p className="Lectura">
                            ¿Qué opinas de este movimiento? ¿Habías escuchado hablar de él o del cambio  
                            climático? ¿Qué acciones podrías hacer tú en tu entorno inmediato para colaborar? 
                        </p>
                </Grid>
        </Grid>
    </Grid>
    );
}
 
export default Las_protestas_por_el_cambio_climático;
