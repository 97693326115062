import { Grid, makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import map1 from "../../Map/MapaG1.jpg";
import map2 from "../../Map/MapaG2.jpg";
import map3 from "../../Map/MapaG3.jpg";
import map4 from "../../Map/MapaG4.jpg";
import map5 from "../../Map/MapaG5.jpg";
import map6 from "../../Map/MapaG6.jpg";
import { useContext } from "react";
import AuthContext from "../../../../auth/AuthContext";
import Loader from "../../../Loader/Loader";
import Contenedor from "./Contenedor";
import ButtonMapTutorial from "./MapButton";
import IntervencionHeraldoModal from "./IntervencionHeraldoModal";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100vw",
    minHeight: "calc(100vh - 80px)",
    overflowY: "auto",
    backgroundColor: "#f6b290",
    display: "grid",
    justifyContent: "start",

    [theme.breakpoints.up("sm")]: {
      marginTop: "0px",
    },
    [theme.breakpoints.up("lg")]: {
      width: "100%",
    },
    [theme.breakpoints.up("xl")]: {
      justifyContent: "center",
      alignItems: "center",
    },
  },
  containerMap: {
    display: "flex",
    height: "1080px",
    width: "1920px",
    position: "relative",
    // backgroundImage: `url(${map})`,
    [theme.breakpoints.up("md")]: {
      marginTop: "0px",
      // top: "0",
    },
    "& .map": {
      height: "100%",
      width: "100%",
      // transform: "translate(1px, 1px)",
      boxShadow: "1px 1px 20px 8px #4f283e, 39px 93px 87px #4f283e",
      // filter: "blur(10px) brightness(0.6)",
      [theme.breakpoints.up("md")]: {
        marginTop: "0px",
      },
    },
  },
}));

const MapTutorial = ({ location }) => {
  const classes = useStyles();
  const completed = location?.state?.completedSesion;
  const {
    user: { grade },
  } = useContext(AuthContext);
  const [loadingInterface, setLoadingInterface] = useState(false);

  const [indicationButton, setIndicationButton] = useState(true);
  const [intervention, setIntervention] = React.useState(false);
  const [tutorialStep, setTutorialStep] = useState({
    one: false,
    two: false,
  });

  useEffect(() => {
    return () => {
      setIndicationButton(false);
      setIntervention(false);
    };
  }, []);

  const handleIntervention = () => {
    setIndicationButton(false);
    setIntervention(false);
  };

  const handleMapButtonClick = () => {
    setIndicationButton(false);
    setIntervention(true);
    setTutorialStep({
      one: true,
      two: false,
    })
  };

  const backToStep1 = () => {
    setIntervention(false);
    setIndicationButton(true);
  };

  const data = {
    primero: {
      mapa: map1 || "",
      namegrado: "grado1",
      intervencion: {
        narrativa:
          "¡Miau! ¡Hola! ¿Me recuerdas? ¡Soy Aris! ¡Te acompañaré en esta aventura!",
        path_imagen:
          "https://pearson-cdl-contenidos-qa.s3.amazonaws.com/G1/heraldo/CDLG1-saludando.gif",
      },
    },
    segundo: {
      mapa: map2 || "",
      namegrado: "grado2",
      intervencion: {
        narrativa: "¡Soy Hans! ¿Me recuerdas? ¡Te acompañaré en esta travesía!",
        path_imagen:
          "https://pearson-cdl-contenidos-qa.s3.amazonaws.com/G2/heraldo/CDLG2-saludando.gif",
      },
    },
    tercero: {
      mapa: map3 || "",
      namegrado: "grado3",
      intervencion: {
        narrativa:
          "¡Qué tal, soy Clemence! Yo te acompañaré en esta gran aventura. ¿Estás listo? ¡Vamos!",
        path_imagen:
          "https://pearson-cdl-contenidos-qa.s3.amazonaws.com/G3/heraldo/CDLG3-saludando.gif",
      },
    },
    cuarto: {
      mapa: map4 || "",
      namegrado: "grado4",
      intervencion: {
        narrativa:
          "¡Hola! ¿Te acuerdas de mí? Soy Xíimbal, el quetzal. ¡Estamos juntos en esta aventura!",
        path_imagen:
          "https://pearson-cdl-contenidos-qa.s3.amazonaws.com/G4/heraldo/CDLG4-saludando.gif",
      },
    },
    quinto: {
      mapa: map5 || "",
      namegrado: "grado5",
      intervencion: {
        narrativa:
          "¡Qué tal! ¿Me recuerdas? Soy ¡Kenjiro! Yo te acompañaré en esta gran aventura. ¿Estás listo? ¡Adelante!",
        path_imagen:
          "https://pearson-cdl-contenidos-qa.s3.amazonaws.com/G5/heraldo/CDLG5-saludando.gif",
      },
    },
    sexto: {
      mapa: map6 || "",
      namegrado: "grado6",
      intervencion: {
        narrativa:
          "¡Hola! ¿Me recuerdas? Soy Laika; estaremos juntos en esta gran aventura. ¡Acompáñame!",
        path_imagen:
          "https://pearson-cdl-contenidos-qa.s3.amazonaws.com/G6/heraldo/CDLG6-saludando.gif",
      },
    },
  };

  const mapa = data[grade]?.mapa;

  return (
    <>
      <Contenedor>
        <Grid className={classes.container}>
          <Grid className={classes.containerMap}>
            <img
              className="map"
              src={mapa}
              alt="Mapa"
              loading="lazy"
              onLoad={() => {
                setLoadingInterface(false);
              }}
            />
            {!tutorialStep?.one && !completed && (
              <ButtonMapTutorial
                open={indicationButton}
                handleIntervention={handleMapButtonClick}
                grade={data[grade]?.namegrado}
                mapa={mapa}
              />
            )}

            {intervention && (
              <IntervencionHeraldoModal
                open={intervention}
                path={data[grade]?.intervencion.path_imagen}
                handleClose={handleIntervention}
                narrativa={data[grade]?.intervencion.narrativa}
                alt={""}
                grado={data[grade]?.namegrado}
                to={"/guia/session03"}
                sesion={3}
                backToStep1={backToStep1}
              />
            )}
          </Grid>
        </Grid>
        {loadingInterface ? <Loader /> : <></>}
      </Contenedor>
    </>
  );
};

export default MapTutorial;
