import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
    ContenedorLectura:{
        width:'502px',
        [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '0 15px 0 ',
        },
    },
    ContainerTitulo:{
        margin:'0 0 38px 0',
        '& .Titulo':{
            letterSpacing: '0px',
            color: '#0058FF',
            margin:'0',
            font:'43px fredoka one',
            lineHeight:'52px',
            textAlign:'center',
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            margin:'0 0 36px 0 ',
            color:'#000',
            font:'18px lato',
            letterSpacing:'.17px',
            lineHeight:'28px',
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },

        },
        '& .Lectura2':{
            margin:'0 ',
        },
        '& .TextImg':{
            margin:' 0 0 3px',
            alignSelf:'flex-start',
            color:'#717476',
            font:'16px lato',
            letterSpacing:'.15px',
            lineHeight:'25px',
            [theme.breakpoints.down(502)]:{
                font:' 11px lato',
                lineHeight :'14px',
                letterSpacing: '0.10px',
            },
        },
        '& .SubTitulo':{
            color:'#FFFFFF',
            margin:'0 0 48px 0',
            font:'26px Fredoka One',
            letterSpacing:'.24px',
            lineHeight:'30px',
            [theme.breakpoints.down(502)]:{
                font:'18px Fredoka One',
                lineHeight:'20px',
            },
            [theme.breakpoints.down(350)]:{
                font:'16px Fredoka One',
                lineHeight:'18px',
            },
        },
        '& .SubTitulo2':{
            margin:'0 0 45px 0',
        },
        '& .Container':{
            boxSizing:'border-box',
            padding:'79px 32px 61px',
            [theme.breakpoints.down(502)]:{
                padding:'64px 32px 37px',
            },
        },
        '& .Container1':{
            background:`Url(https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion53/3R.png)`,
            backgroundSize:'100% 100%',
        },
        '& .Container2':{
            background:`Url(https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion53/4R.png)`,
            backgroundSize:'100% 100%',
        },
        '& .Container3':{
            background:`Url(https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion53/5R.png)`,
            backgroundSize:'100% 100%',
        },
        '& .Container4':{
                background:`Url(https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion53/6R.png)`,
                backgroundSize:'100% 100%',
        },

        '& .Imagen':{
            width:'90%',
        }

    },

}));

const Las_ramas_de_la_psicología = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >
                        Las ramas de la psicología
                    </h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                            Cuando piensas en un psicólogo, ¿qué tipo de persona te imaginas? Seguramente a 
                            alguien a quien tú o los adultos le cuentan sus problemas personales para que les 
                            ayude a encontrar la solución. Y tienes razón, aunque sólo en parte. <br/><br/>
                            
                            Es común que a tu edad desconozcas muchas profesiones o todo lo que hacen 
                            quienes se dedican a ellas. Entonces, ¿qué pensarías si supieras que los psicólogos, 
                            además de escuchar y apoyar a sus pacientes, también se dedican a la educación? En 
                            otras palabras, una de las áreas que atiende la psicología es aquella que trata los 
                            procesos de enseñanza y aprendizaje. A esta rama se le conoce como psicología 
                            educativa. <br/><br/>
                            
                            A partir de que esta profesión fijó su mira en esta área, diversos teóricos han tratado 
                            de explicar la forma en que los seres humanos se relacionan con el conocimiento. Es 
                            decir, de qué manera aprenden, cómo se debe enseñar y qué factores externos, como 
                            la sociedad o la tecnología, influyen en los procesos de aprendizaje de las personas. <br/><br/>
                            
                            A continuación, conocerás algunos de los más importantes exponentes de la 
                            psicología educativa, así como sus teorías.
                        </p>
                    </Grid>
                    <Grid container justifyContent="center" className='Container Container1' sm={12}>
                            <h3 className='SubTitulo'>John Dewey (1859-1952)</h3>
                            <p className='Lectura'>Fue un psicólogo estadounidense que sentó las bases para estudiar la 
                            relación entre la enseñanza y el aprendizaje desde una manera científica.
                            Es decir, procuró analizar la educación con el rigor de las ciencias. <br/><br/>
                            
                            Dewey pensaba que la experiencia de las personas es muy importante en 
                            el momento en el que adquieren sus conocimientos, ya que todo lo que 
                            han vivido les ayudará o limitará en su forma de aprender. <br/><br/>
                            
                            Él veía las escuelas como lugares de reconstrucción del orden social. En 
                            otras palabras, pensaba que los centros educativos sirven para que todos 
                            los alumnos puedan encontrar un sitio ordenado en el mundo. Para ello, 
                            los profesores funcionan como guías y orientadores de ese proceso.</p>

                            <img  className='Imagen' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion53/1.png"  alt="Tarjeta" />
                        <Grid container >
                          <p className='TextImg'>Según Dewey, con las dinámicas internas de orden de las escuelas, se puede ordenar también la sociedad.</p>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container justifyContent="center" className='Container Container2' sm={12}>
                            <h3 className='SubTitulo '>Jean Piaget (1896-1980)</h3>
                            <p className='Lectura'>A pesar de ser biólogo, Piaget es uno de los personajes que más ha 
                            influido en la psicología educativa, pues estudió y describió las diversas 
                            etapas por las que pasa una persona en su proceso de aprendizaje. <br/><br/>
                            
                            Para que tengas una idea de lo minucioso que fue al estudiar los procesos 
                            del ser humano, Piaget dividió las etapas de crecimiento de las personas 
                            desde que nacen y describió lo que podían o no aprender, de acuerdo con 
                            su etapa de maduración. Así, por ejemplo, explicaba de qué manera 
                            aprenden los niños de 0 a 2 años, de 2 a 7, de 7 a 12 y de 12 en adelante. 
                            Para él, la curiosidad de los niños es fundamental en todo el proceso.</p>

                            <img  className='Imagen' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion53/2.png"  alt="Tarjeta" />
                        <Grid container justifyContent='center'>
                          <p className='TextImg'>Piaget nombró su teoría como <em>Etapas de desarrollo cognitivo.</em></p>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container justifyContent="center" className='Container Container3' sm={12}>
                            <h3 className='SubTitulo SubTitulo2'>Lev Vygotsky (1898-1934)</h3>
                            <p className='Lectura Lectura2'>Este psicólogo ruso tuvo un fuerte interés por investigar cómo la cultura 
                            y la sociedad influyen en el aprendizaje de los niños. Él consideraba que 
                            los diferentes contextos en los que se lleva a cabo el aprendizaje influyen 
                            en la forma en que se aprende. <br/><br/>
                            
                            Pensaba que las actividades que se realizan de forma compartida, es 
                            decir, a partir de la socialización, facilitan que los niños hagan suyas las 
                            experiencias de aprendizaje. En otras palabras, afirmaba que si los 
                            estudiantes conviven con más personas en el momento que se enfrentan 
                            a nuevos conocimientos, será más fácil que los aprendan y recuerden.</p>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container justifyContent="center" className='Container Container4' sm={12}>
                            <h3 className='SubTitulo SubTitulo2'>Albert Bandura (1925-2021)</h3>
                            <p className='Lectura Lectura2'>A este psicólogo canadiense-estadounidense se le atribuye la Teoría del 
                            Aprendizaje Social (<span style={{fontVariant: "all-small-caps"}}>TAS</span>). Del mismo modo que Vygotsky, cuando se trata 
                            de los procesos de aprendizaje, él centra la atención en la interacción 
                            entre el aprendiz y el entorno. <br/><br/>
                            
                            Lo anterior significa que las personas, al vivir en contacto con los demás, 
                            aprenden de ellas ciertas formas de pensar y actuar.</p>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container justifyContent="center" sm={12}>
                        <p className='Lectura '>
                            Como podrás observar, ha habido muchos estudiosos que han pensado en la manera 
                            en la que puedes aprender y las herramientas de las que puedes disponer para 
                            hacerlo. Así que ahora que observes a tus profesores durante las clases piensa que 
                            los métodos que utilizan para enseñarte no son invenciones, sino que tienen 
                            sustento en años de estudio e investigaciones.
                        </p>
                    </Grid>
                </Grid>
        </Grid>
    </Grid>
    );
}
 
export default Las_ramas_de_la_psicología;
