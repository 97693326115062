import { makeStyles, Grid } from "@material-ui/core";
import React from "react";
import { Link } from "react-router-dom";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import AuthContext from "../../../../auth/AuthContext";
import UsabilityContext from "../../../../usability/UsabilityContext";
import Fortalezas from "../../../Charts/Fortalezas";
import ReporteContainer from "./Reporte_Container";
import axios from "../../../../services/axios";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    padding: "48px 0 48px 0",
    background:
      "linear-gradient(217deg, rgba(255,194,0,0.4962185557816877) 2%, rgba(255,255,255,1) 25%, rgba(255,255,255,0.4962185557816877) 81%, rgba(144,81,177,1) 98%)",
    minHeight: "92vh",
    // [theme.breakpoints.up(1920)]:{
    //     height:'92vh',
    // },
    // [theme.breakpoints.down(1920)]:{
    //     height:'92vh',
    // },
    // [theme.breakpoints.down(1811)]:{
    //     height:'1150px',
    // },
    // [theme.breakpoints.down(1487)]:{
    //     height:'1430px',
    // },
    // background: 'linear-gradient(217deg, rgba(255,194,0,0.4962185557816877) 2%, rgba(255,255,255,1) 25%, rgba(255,255,255,0.4962185557816877) 81%, rgba(144,81,177,1) 98%)',
    // [theme.breakpoints.down(1280)]:{
    //     background:'none',

    // },
  },
  boxContainer: {
    background: "#fff",
    width: "80%",
    maxWidth: "1216px",
    minHeight: "70vh",
    height: "auto",
    borderRadius: "42px",
    padding: "20px",
    gap: "20px",
    boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.5)",
    [theme.breakpoints.up("md")]: {
      padding: "48px 20px 48px 20px",
      gap: "0px",
    },

    // [theme.breakpoints.down(1280)]: {
    //   width: "860px",
    //   height: "100%",
    // },
    // [theme.breakpoints.down(870)]: {
    //   width: "420px",
    // },
    // [theme.breakpoints.down(425)]: {
    //   width: "300px",
    // },
  },
  title: {
    font: "20px fredoka one",
    textAlign: "center",
    [theme.breakpoints.up("xl")]: {
      font: "24px fredoka one",
    },
  },
  backButtonContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
  },
  backButton: {
    cursor: "pointer",
    height: "35px",
    backgroundColor: "#FDEFF7",
    color: "#DC5EA6",
    border: "#DC5EA6 1px solid",
    borderRadius: "20px",
    textTransform: "none",
    font: "20px Lato",
    letterSpacing: "0.14px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
  },
  link: {
    cursor: "pointer",
    textDecoration: "none",
    // height: "31px",
    // width: "190px",
  },
  ContainerLinkinsiginas: {
    textDecoration: "none",
    marginLeft: "44px",
    marginBottom: "40px",
    "& .ButtonIsg": {
      color: "blue",
      textTransform: "none",
      font: " 22px lato",
      letterSpacing: "0.36px",
      [theme.breakpoints.down(425)]: {
        font: " 16px lato",
      },
      // width: '102px',
      // height: '21px',
      // background: '#FEFAF1 0% 0% no-repeat padding-box',
      // boxShadow: '0px 1px 2px #00000029',
      // border: '1px solid #F2624F',
      // borderRadius: '10px',
    },
    "& p": {
      textAlign: "start",
    },
    "& .icon": {
      color: "purple",
      marginRight: "20px",
      fontSize: "50px",
      [theme.breakpoints.down(425)]: {
        fontSize: "30px",
        marginRight: "0px",
      },
    },
  },

  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  reading: {
    width: "60%",
    margin: "0px ",
    font: "18px lato",
    letterSpacing: "0.21px",
    [theme.breakpoints.down(1400)]: {
      font: " 14px lato",
    },
    [theme.breakpoints.down(650)]: {
      font: "10px lato",
    },
  },
}));

const Reporte4 = () => {
  const classes = useStyles();

  const { user } = React.useContext(AuthContext);
  const [skillsData, setSkillsData] = React.useState([]);
  const [downloadData, setDownloadData] = React.useState("");

  const { groupData, handleModalMessage, handleLoading } =
    React.useContext(UsabilityContext);

  const group = groupData || user?.grupos[0];
  const idDistribEsc = group?.id_distribucion_escuela;

  React.useEffect(() => {
    const body = {
      id_actividad_tipo: 2,
      id_distribucion_escuela: idDistribEsc,
      // id_usuario: idUsuario
    };
    handleLoading(true);
    axios
      .post(`/listado/reporte/4-6`, body)
      .then((response) => {
        if (response.status === 200) {
          setSkillsData(response.data.resultado.Resultado);
        }
      })
      .catch((error) => {
        if (error.response) {
          // Request made and server responded
          const errorData = error.response.data;
          const mensaje =
            errorData?.detalles?.msg ||
            "Ha ocurrido un error al consumir los servicios, inténtelo de nuevo más tarde.";
          handleModalMessage({
            habilite: true,
            mensaje: mensaje,
          });
        } else {
          // Something happened in setting up the request that triggered an Error
          const mensaje =
            "Ha ocurrido un error al consumir los servicios, inténtelo de nuevo más tarde.";
          handleModalMessage({
            habilite: true,
            mensaje: mensaje,
          });
        }
        setSkillsData([]);
      })
      .finally(() => {
        handleLoading(false);
      });
  }, [idDistribEsc]);

  React.useEffect(() => {
    axios
      .get(
        `/profesor/getReport/4-6?idDistribucionEscuela=${idDistribEsc}&idActividadTipo=2`
      )
      // .get(`listado/sesiones/listado?id_usuario=${user.userId}&id_distribucion_escuela=${idGrupo?.id_distribucion_escuela}`)
      .then((response) => {
        if (response.status === 200) {
          setDownloadData(response.data.resultado.path);
          // setDownloadData()
          // setDownloadData()
        }
      })
      .catch((error) => {
        if (error.response) {
          // Request made and server responded
          const errorData = error.response.data;
          const mensaje =
            errorData?.detalles?.msg ||
            "Ha ocurrido un error al consumir los servicios, inténtelo de nuevo más tarde.";
          handleModalMessage({
            habilite: true,
            mensaje: mensaje,
          });
        } else {
          // Something happened in setting up the request that triggered an Error
          const mensaje =
            "Ha ocurrido un error al consumir los servicios, inténtelo de nuevo más tarde.";
          handleModalMessage({
            habilite: true,
            mensaje: mensaje,
          });
        }
      })
      .finally(() => handleLoading(false));
  }, []);

  return (
    <ReporteContainer>
      <Grid
        container
        item
        sm={12}
        alignItems="center"
        justifyContent="center"
        className={classes.mainContainer}
      >
        <Grid
          container
          item
          direction="column"
          className={classes.boxContainer}
        >
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="center"
          >
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              className={classes.backButtonContainer}
            >
              <Link className={classes.link} to="/docente/reportes">
                <button className={classes.backButton}>
                  <ArrowBackIcon /> Reportes
                </button>
              </Link>
            </Grid>
            <Grid
              container
              item
              direction="column"
              alignItems="center"
              justifyContent="center"
              className={classes.titleContainer}
            >
              <h2 className={classes.title}>
                Reporte 4: Evaluación diagnóstica
              </h2>
              <p className={classes.reading}>
                Este reporte te muestra el nivel de dominio de las competencias
                de comprensión lectora y socioemocionales de tu grupo al iniciar
                el ciclo escolar, y te permite conocer aquellas que se deben
                consolidar a lo largo del circuito.
              </p>
            </Grid>
          </Grid>
          <Grid container item direction="row" alignItems="center">
            {skillsData.length && downloadData ? (
              <Fortalezas
                skillsData={skillsData}
                downloadData={downloadData}
                evaluationType={"ev. diagnóstica"}
              />
            ) : null}
          </Grid>
        </Grid>
      </Grid>
    </ReporteContainer>
  );
};

export default Reporte4;
