import React, { useContext, useEffect } from "react";
import { makeStyles, Grid } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import CorrectFeedback from "./CorrectFeedback";
import WrongFeedback from "./WrongFeedback";

import FinalFeedback from "./FinalFeedback";
import Answers from "./Answers";
import { useHistory } from "react-router-dom";
import ReadingModalEval from "./ReadingModalEval";
import axios from "../../../services/axios";
import socioEmTag from "../../imgs/socioEmTag.png";
import UsabilityContext from "../../../usability/UsabilityContext";

const useStyles = makeStyles((theme) => ({
  layout: {
    height: "92vh",
    // height: "900px",
    width: "100%",
  },
  // evaluationsGrid:{
  //   height:'90%',
  //   boxSizing:'border-box',
  // },
  mainTextContainer: {
    position: "relative",
    // height:'100%',
    padding: "5%",
    width: "100%",
    minHeight: "90vh",
    // backgroundColor: BackgroundColorText,
    [theme.breakpoints.down(1024)]: {
      display: "none",
    },
  },
  questionContainer: {
    backgroundColor: "#FEFAF1",
    minHeight: "92vh",
    maxHeight: "92vh",
    width: "100%",
    overflowY: "auto",
    [theme.breakpoints.down(1025)]: {
      width: "100%",
    },
    [theme.breakpoints.down(961)]: {
      width: "80%",
    },
    [theme.breakpoints.down(768)]: {
      padding: "0%",
    },
    [theme.breakpoints.down(620)]: {
      width: "90%",
    },
    [theme.breakpoints.down(570)]: {
      width: "100%",
    },
  },
  instructionsContainer: {
    width: "80%",
    minHeight: "12vh",
    // [theme.breakpoints.down(601)]:{
    // 	marginTop:'12%'
    // },
  },
  FinalInstructionsContainer: {
    width: "80%",
    minHeight: "30vh",
    // [theme.breakpoints.down(601)]:{
    // 	marginTop:'12%'
    // },
  },
  buttonContainer: {
    width: "80%",
    height: "15vh",
  },
  // progressContainer:{
  //   width:"100%",
  //   height:"10%"
  // },
  reading: {
    textAlign: "justify",
    width: "80%",
    font: "22px Lato;",
    letterSpacing: "0.21px",
  },
  backButton: {
    height: "30px",
    backgroundColor: "#FEFAF1",
    color: "#F2624F",
    border: "#F2624F 1px solid",
    borderRadius: "20px",
    textTransform: "none",
    font: "regular 20px Lato",
    letterSpacing: "0.14px",
  },
  arrowBackIcon: {
    marginRight: "5px",
  },
  continueButton: {
    textTransform: "none",
    height: "62px",
    width: "279px",
    [theme.breakpoints.down(1368)]: {
      height: "52px",
      width: "229px",
    },
    [theme.breakpoints.down(1068)]: {
      height: "52px",
      width: "60%",
    },
    [theme.breakpoints.down(769)]: {
      width: "56.5%",
    },
    background: "#7A54BA",
    boxShadow: "0px 3px 6px #00000029",
    borderRadius: "61px",
    color: "#FFFFFF",
    font: "bold 20px lato ",
    "&:hover": {
      background: "#dd78fb",
    },
  },
  answerContainer: {
    minHeight: "47vh",
    maxWidh: "80%",
    [theme.breakpoints.down(860)]: {
      maxWidh: "100%",
    },
  },
  answerTextContainer: {
    minHeight: "35vh",
  },
  progressLinearContainer: {
    height: "5vh",
    width: "72%",
    [theme.breakpoints.down(1024)]: {
      width: "82%",
    },
  },
  progressLinearBar: {
    width: "90%",
    height: "8%",
  },
  readingDemoContainer: {
    // maxHeight: "1024px",
    marginTop: "0px",
    // boxSizing: "border-box",
    boxShadow: " 5px 5px 10px 18px #00000029",
    padding: "3% 3% 3% 3%",
    backgroundColor: "white",
    borderRadius: "20px 20px 20px 20px",
  },

  progress: {
    borderRadius: "7px",
    width: "95%",
    height: "15px",
    marginLeft: -"11.5%",
    boxShadow: " 1px 1px 4px rgba( 0, 0, 0, 0.2 )",
    "&::-webkit-progress-bar": {
      backgroundColor: "#F2624F",
      borderRadius: "7px",
    },
    "&::-webkit-progress-value": {
      backgroundColor: "#84E9DF",
      borderRadius: "7px",
    },
  },
  link: {
    transform: "translate(-50%,-50%)",
    textDecoration: "none",
  },
  readingButton: {
    display: "none",
    [theme.breakpoints.down(1024)]: {
      display: "grid",

      // '& .readingButton:before':{
      //   position:'absolute',
      //   display:'flex',
      //   boxSizing:'border-box',
      //   padding:'0 0 0 10px',
      //   justifyContent:'center',
      //   alignItems:'center',
      //   height:'39.88px',
      //   width:'39.88px',
      //   borderRadius:'50%',
      //   content:'">"',
      //   boxShadow: '3px 0px .5px 0px #00000029',
      //   background:'#FAFAFA',
      //   left:'90px',
      //   bottom:'65px',
      //   font:'bold 20px lato',
      //   color:'#20A698',
      //   transform: 'rotate(0.25turn)',
      //   },
    },
  },
  showReadingButton: {
    marginTop: "10px",
    textTransform: "none",
    font: "24px Fredoka One",
    color: "white",
    backgroundColor: "#EA85AE",
    borderRadius: "50px 50px 0 0",
    width: "411px",
    height: "58px",
    paddingTop: "20px",
    [theme.breakpoints.down(700)]: {
      width: "250px",
    },
  },
  emotionalTagContainer: {
    width: "57px",
    height: "57px",
    marginBottom: "10px",
    [theme.breakpoints.down(1400)]: {
      width: "47px",
      height: "47px",
    },
    [theme.breakpoints.down(1020)]: {
      height: "42px",
    },
    [theme.breakpoints.down(1020)]: {
      marginBottom: "5px",
    },
  },
  emotionalTagGrid: {
    width: "55px",
    height: "90%",
    backgroundColor: "#FFDE72D1",
    border: "#B64F88 3px solid",
    borderRadius: "0 0 20px 20px",
    marginLeft: "10%",

    display: "none",
    [theme.breakpoints.down(1400)]: {
      width: "47px",
    },
    [theme.breakpoints.down(1020)]: {
      width: "40px",
      margin: "0 10%",
    },
    [theme.breakpoints.down(600)]: {
      width: "30px",
      margin: "0 10%",
    },
  },
  imgSociEm: {
    [theme.breakpoints.down(1020)]: {
      height: "25px",
    },
    [theme.breakpoints.down(600)]: {
      height: "20px",
    },
  },
}));

const Activity = (props, counterText) => {
  const classes = useStyles();
  const {
    step,
    setStep,
    questionList,
    // Lectura,
    textComponent: TextComponent,
    questionComponent: QuestionComponent,
    imageList,
    baseNumber,
    activityNumber,
    evaluationType,
    enrollmendId,
    tryNumber,
  } = props;

  const backgroundColors = ["#9051b1", "#ffc200", "#3f43b3", "#84e9df"];
  const [stepText, setStepText] = React.useState(backgroundColors[0]);
  const [openRight, setOpenRight] = React.useState(false);
  const [openWrong, setOpenWrong] = React.useState(false);
  const [isCorrect, setIsCorrect] = React.useState(false);
  const [pristine, setPristine] = React.useState(true);
  const [feedback, setFeedback] = React.useState("");
  const [answerText, setAnswerText] = React.useState("");
  const [currentAnswer, setCurrentAnswer] = React.useState("");
  const [openFinal, setOpenFinal] = React.useState(false);
  const [totalPoints, setTotalPoints] = React.useState(0);
  const [valueAnswerText, setValueAnswerText] = React.useState("Controlled");
  const [feedBackImage, setFeedBackImage] = React.useState(false);
  const [questionId, setQuestionId] = React.useState(0);
  const [openReading, setOpenReading] = React.useState(false);
  const [idPregunta, setIdPregunta] = React.useState(0);
  const [stopQuestion, setStopQuestion] = React.useState(false);

  const finalStep = step === questionList.length - 1;

  const userAnswer = finalStep ? valueAnswerText : answerText;
  const { handleLoading, handleModalMessage } = useContext(UsabilityContext);

  const history = useHistory();

  useEffect(() => {
    setIdPregunta(questionList[step] && questionList[step].id_pregunta);
  }, [questionList[step]]);

  useEffect(() => {
    setStepText(backgroundColors[baseNumber]);
  }, [step]);

  const feedBackContinue = () => {
    handleLoading(true);
    setStopQuestion(false);
    const body = {
      id_actividad_inscripcion: enrollmendId,
      id_respuesta: currentAnswer,
      id_pregunta: idPregunta,
      intento: tryNumber,
      // "puntos":                   Number(isCorrect),
      puntos: isCorrect || finalStep ? 1 : 0,
      respuesta_usuario: userAnswer,
    };
    axios
      .post("/respuesta/actividad/set", body)
      .then((response) => {
        if (response.status === 200) {
          if (step !== questionList.length - 1) {
            isCorrect ? setOpenRight(true) : setOpenWrong(true);
            isCorrect && setTotalPoints(totalPoints + 1);
          } else {
            setOpenFinal(true);
            setTotalPoints(totalPoints + 1);
          }
        }
      })
      .catch((error) => {
        if (!error.response.status) {
          handleModalMessage({
            habilite: true,
            mensaje: "Comprueba tu conexión a internet y vuelve a intentarlo.",
          })
        }
        else if (error.response.status === 500) {
          history.push({
            pathname: "/error/servicios",
            state: {
              reason: "sesion-servicios",
            },
          });
        } else if (error.response.status === 400) {
          if (step !== questionList.length - 1) {
            isCorrect ? setOpenRight(true) : setOpenWrong(true);
            isCorrect && setTotalPoints(totalPoints + 1);
          } else {
            setOpenFinal(true);
            setTotalPoints(totalPoints + 1);
          }
        }
      })
      .finally(() => {
        handleLoading(false);
      });
  };

  // const feedBackContinue = () => {
  //   isCorrect ? setOpenRight(true) : setOpenWrong(true)
  //   isCorrect && setTotalPoints(totalPoints + 1)
  // }

  const showSocioETag = questionList[step] && questionList[step].insignia;

  const renderSocioE = () => {
    if (showSocioETag === "socio emocional") {
      return { display: "grid" };
    }
  };

  const finishFeedback = () => {
    setOpenFinal(true);
    setTotalPoints(totalPoints + 1);
  };

  const nextQuestionIndex = questionList.findIndex(
    (question, index) => index > step && !question.respondida
  );

  return (
    <>
      <Grid
        container
        direction="column"
        item
        sm={12}
        className={classes.layout}
      >
        <Grid
          container
          item
          // direction="row"
          justifyContent="center"
          alignItems="center"
          className={classes.evaluationsGrid}
          sm={12}
        >
          {/* {TextComponent} */}
          <Grid
            container
            item
            md={6}
            direction="column"
            justifyContent="center"
            alignItems="center"
            className={classes.mainTextContainer}
            style={{ background: stepText }}
          >
            <Grid
              container
              item
              justifyContent="center"
              alignItems="center"
              className={classes.readingDemoContainer}
            >
              {TextComponent}
            </Grid>
          </Grid>
          <Grid
            container
            item
            md={6}
            justifyContent="center"
            alignItems="center"
            className={classes.questionContainer}
          >
            <Grid
              container
              item
              xs={12}
              className={classes.emotionalTagContainer}
            >
              <Grid
                justifyContent="center"
                alignItems="center"
                className={classes.emotionalTagGrid}
                style={renderSocioE()}
              >
                <img
                  src={socioEmTag}
                  alt="corazn"
                  className={classes.imgSociEm}
                />
              </Grid>
            </Grid>

            <Grid
              container
              item
              justifyContent="flex-start"
              alignItems="center"
              className={
                finalStep
                  ? classes.FinalInstructionsContainer
                  : classes.instructionsContainer
              }
            >
              {QuestionComponent}
            </Grid>
            <Grid
              container
              item
              justifyContent="center"
              alignItems="center"
              className={
                finalStep
                  ? classes.answerTextContainer
                  : classes.answerContainer
              }
            >
              {questionList && questionList.length && (
                <Answers
                  key={enrollmendId + evaluationType}
                  respuestas={questionList[step].respuestas}
                  setPristine={setPristine}
                  setIsCorrect={setIsCorrect}
                  setFeedback={setFeedback}
                  setAnswerText={setAnswerText}
                  setCurrentAnswer={setCurrentAnswer}
                  currentAnswer={currentAnswer}
                  setTotalPoints={setTotalPoints}
                  totalPoints={totalPoints}
                  setValueAnswerText={setValueAnswerText}
                  valueAnswerText={valueAnswerText}
                  questionType={questionList[step].tipo_pregunta}
                  feedBackImage={feedBackImage}
                  setFeedBackImage={setFeedBackImage}
                  questionId={questionId}
                  setQuestionId={setQuestionId}
                  imageList={imageList}
                />
              )}
            </Grid>
            <Grid
              container
              item
              justifyContent="center"
              alignItems="center"
              className={classes.buttonContainer}
            >
              {
                // finalStep ?
                //   <Button className={classes.continueButton} disabled={pristine} onClick={finishFeedback}>Continuar</Button>
                // :
                <Button
                  className={classes.continueButton}
                  disabled={pristine}
                  onClick={feedBackContinue}
                >
                  Continuar
                </Button>
              }
            </Grid>
            <Grid
              container
              item
              justifyContent="center"
              alignItems="center"
              className={classes.progressLinearContainer}
            >
              <progress
                value={step}
                max={questionList.length}
                className={classes.progress}
              ></progress>
            </Grid>
            <Grid
              container
              item
              xs={12}
              justifyContent="center"
              alignItems="center"
              className={classes.readingButton}
            >
              <Button
                className={classes.showReadingButton}
                onClick={() => {
                  setOpenReading(true);
                }}
              >
                Ver la lectura
              </Button>
            </Grid>
            {/* <ReadingModal
            open={openReading}
            setOpen={setOpenReading}
            lectura={TextComponent}
          /> */}
            <ReadingModalEval
              open={openReading}
              setOpen={setOpenReading}
              lectura={TextComponent}
              baseNumber={baseNumber}
              step={step}
            />
          </Grid>
        </Grid>
        <CorrectFeedback
          open={openRight}
          setOpen={setOpenRight}
          pregunta={QuestionComponent}
          feedback={feedback}
          answerText={answerText}
          setStep={setStep}
          step={step}
          setPristine={setPristine}
          feedBackImage={feedBackImage}
          setFeedBackImage={setFeedBackImage}
          questionId={questionId}
          imageList={imageList}
          badgesType={showSocioETag}
          stopQuestion={stopQuestion}
          setStopQuestion={setStopQuestion}
          evaluationType={evaluationType}
          nextQuestionIndex={nextQuestionIndex}
          activityNumber={activityNumber}
          totalQuestions={questionList.length}
        />
        <WrongFeedback
          open={openWrong}
          setOpen={setOpenWrong}
          pregunta={QuestionComponent}
          feedback={feedback}
          answerText={answerText}
          setStep={setStep}
          step={step}
          setPristine={setPristine}
          feedBackImage={feedBackImage}
          setFeedBackImage={setFeedBackImage}
          questionId={questionId}
          imageList={imageList}
          stopQuestion={stopQuestion}
          setStopQuestion={setStopQuestion}
          nextQuestionIndex={nextQuestionIndex}
          tryNumber={tryNumber}
          activityNumber={activityNumber}
          totalQuestions={questionList.length}
          evaluationType={evaluationType}
        />
        <FinalFeedback
          open={openFinal}
          setOpen={setOpenFinal}
          feedback={feedback}
          valueAnswerText={valueAnswerText}
          totalQuestions={questionList.length}
          activityNumber={activityNumber}
          evaluationType={evaluationType}
          tryNumber={tryNumber}
        />
      </Grid>
    </>
  );
};

export default Activity;
