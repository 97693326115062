import React                        from "react";
import { Button,makeStyles,Grid}    from "@material-ui/core";

const useStyles = makeStyles(theme=>({
  welcomePageContainer: {
    background:'#FFFDFD',
    height:'calc(100vh - 79.5px)',
  },
  ContainerIzquierdo:{
    width:'97%',
    [theme.breakpoints.down(1160)]:{
      width:'100%',
    },
  },

  ContainerEstrategi:{
    position:'relative',
    width:'100vw',
    height:'calc(100vh - 79.5px - 79.5px)',
    overflow: 'auto',
  },
  ContainerSubHabilidades:{
    background:'none',
    boxSizing:'border-box',
    cursor:'pointer',
    textAlign:'center',
    display:'flex',
    padding:'43px 17px 9px 17px',
    alignItems:'center',
    position:'absolute',
    height:'40px',
    width:'62px',
    right:'3%',
    zIndex:1,
    [theme.breakpoints.down(1281)]:{
      left:'calc(50% - 31px)',
      padding:'0 0 0 62px',
    },
    [theme.breakpoints.down(600)]:{
      left:'0',
      padding:'0 15px 0 55px',
    },
        '& .Habilidades':{
      color:'#575757',
      font: 'normal 16px Lato',
      letterSpacing: '0.13px',
      display:'none',
      [theme.breakpoints.down(1281)]:{
        padding:'0 ', 
        margin:'0',
        font: ' 13px Lato',
      },
      [theme.breakpoints.down(600)]:{
        padding:'0 ', 
        margin:'0',
        font: ' 11px Lato',
      },
    },
    '&:hover':{
      border:' 5px solid #F26973',
      borderRadius: '20px 0px 20px 20px',
      background:'#fff',
      height:'220px',
      width:'233px',
      [theme.breakpoints.down(1161)]:{
        height:'85px',
        width:'355px',
        borderRadius: '0px 0px 20px 20px',
      },
      [theme.breakpoints.down(600)]:{
        height:'78px',
        width:'325px',
      },

      '& .Habilidades':{
        display:'block',
      },
      '&:before':{
        borderTop:' 0px ',
        borderRight:'0px'
      },
    },
    '&:before':{
      display:'flex',
      justifyContent:'center',
      alignItems:'center',
      content: '"IC"',
      position: 'absolute',
      right: '0',
      top: '0',
      font: '20px Fredoka One',
      background: '#EE667A',
      color: '#FFF',
      border:' 3px solid #F2624F',
      borderRadius: '0px 0px 20px 20px',
      height: '40px',
      width: '62px',
      [theme.breakpoints.down(1281)]:{
        left: '-5px',
        top: '0',
      },
      [theme.breakpoints.down(600)]:{
        height: '30px',
        width: '52px',
        font: '18px Fredoka One',
      },

    },
  },
  Estrategia:{
    margin:'0px',
    font:'38px Fredoka One',
    textAlign: 'center',
    letterSpacing: '0.28px',
    color: '#20A698',
    opacity: '1',
    [theme.breakpoints.down(1161)]:{
      font:'28px Fredoka One',
    },
    [theme.breakpoints.down(600)]:{
      font:'24px Fredoka One',
    },
  },

   TextStrg:{
      margin:'0', 
      font:'normal  22px Lato',
      letterSpacing: '0.10px',
      color: '#575757',
      opacity: 1,
      padding:'0 0 40px 0',
      boxSizing:'border-box',
      width:'760px',
      [theme.breakpoints.down(1161)]:{
        width:'650px',
        font:'normal  18px Lato',
      },
      [theme.breakpoints.down(600)]:{
        font:'normal  16px Lato',
        width:'325px',
      },
      [theme.breakpoints.down(325)]:{
        font:'normal  14px Lato',
        width:'300px',
      },
  },

   Titulo:{
    position:'absolute',
    top:'58px',
    [theme.breakpoints.down(1161)]:{
      top:'80px',
    },
  },

  Objet1:{
    position:'absolute',
    top:'130px',
    '& .Td':{
      border:'solid 1.5px #000',
      width:'390px',
      textAlign:'center',
      height:'42px',
      boxSizing:'border-box',
      padding:'0 0 5px 5px',
    },
    '& .Td1':{
      borderRight:'none',
    },
    '& .Td2':{
      borderRight:'none',
      borderLeft:'none',
    },
    '& .Td3':{
      borderLeft:'none',
      borderRight:'solid 1.5px #000',
    },
    '& .TdS':{
      textAlign:'left',
    },
    '& .Br':{
      margin:'0 0 20px 0'
    },
    '& .ul':{
      margin:'22px 0',
      padding:'0 0 0 22px',
    },
    '& .li':{
      marginBottom:'10px',
    },
   
   '& .Amarillo':{
    textAlign:'center',
    height:'42px',
    background:'#F7DF92',
    padding:'0 ',
   },
   '& .Verde':{
    textAlign:'center',
    height:'42px',
    background:'#84E9DF',

    padding:'0 ',
   },

  },

  ContainerBarraInferior:{
    position:'relative',
    background:'#F3CD52',
    height:'79.51px',
    [theme.breakpoints.up(1920)]:{
      height:'8vh',
    },
    [theme.breakpoints.down(1161)]:{
      background: 'linear-gradient(280deg, #D3348F 0%, #F1CB5A 100%) 0% 0% ',
    },
  },

  ContainerBarraDerecha:{
    background: 'linear-gradient(180deg, #D3348F 0%, #F1CB5A 100%) 0% 0%',
    width:'3%',
    height:'100vh',
    [theme.breakpoints.down(1161)]:{
      display:'none',
    },
  },
  
   BotonAvanza:{
    position:'absolute',
    bottom:'52px',
    right:'134px',
    textTransform:'none',
    textDecoration:'none',
    height:'62px',
    width:'279px',  
    background: '#7A54BA',
    boxShadow: '0px 3px 6px #00000029',
    borderRadius: '61px',
    color: '#FFFFFF',
    font: 'bold 20px lato ',
    '&:hover':{
        background: '#dd78fb',
      },
    [theme.breakpoints.down(1161)]:{
      position:'relative',
      bottom:'32px',
      left:'0',
      right:'0',
    },
  },
    
}));

const ReadingStrategys = (props) => {
  const classes = useStyles();
  const {activeStep, setActiveStep, handleNextStep} = props

  const handleContinue = () => {
    setActiveStep(activeStep + 1)
    handleNextStep()
  }

  return(

    <Grid  container direction="column" item sm={12} className={classes.welcomePageContainer} >
<div className={classes.ContainerSubHabilidades}>
          <p className="Habilidades">Relacionar las ideas expresadas explícitamente en el texto con los conocimientos previos apropiados para conseguir la comprensión detallada.</p>
      </div>
      <Grid container item sm={12} justifyContent="center" className={classes.ContainerIzquierdo} >

        <Grid container item className={classes.ContainerEstrategi } >

              <Grid container item justifyContent="center" alignItems="center" sm={12} className={classes.Titulo}> 
                <p className={classes.Estrategia} >Estrategia de lectura</p>
              </Grid>

              <Grid container item sm={12} alignItems="center" justifyContent="center" className={classes.Objet1}>
                  <p className={classes.TextStrg}>
                    Para comprender mejor un texto, podemos ayudarnos de nuestros conocimientos
                    previos. Para activarlos, aplica la siguiente estrategia:
                    <ul className="ul">
                      <li className="li">
                        Escoge un texto que llame tu atención.
                      </li>
                      <li className="li">
                        Toma una hoja y un lápiz. Necesitarás el apoyo de un amigo o familiar.
                      </li>
                      <li className="li">
                        Traza una tabla y coloca en la primera columna tu nombre y en la segunda 
                        el nombre de tu compañero.
                      </li>
                    </ul>

                    <tr>
                      <td valign="middle" className="Td Verde"><b>Preguntas</b>  </td>
                      <td valign="middle" className="Td Verde"><b>Mi nombre</b></td>
                      <td valign="middle" className="Td Verde"><b>María</b></td>
                    </tr>

                    <tr>
                      <td valign="middle" className="Td">¿Sobre qué trata el tema? </td>
                      <td className="Td"> </td>
                      <td className="Td"> </td>
                    </tr>
                    
                   <ul className="ul">
                     <li className="li">
                      Antes de comenzar a leer, localicen en el texto las imágenes, el título y los 
                      subtítulos. Obsérvenlos y léanlos. Realicen una lluvia de ideas sobre de qué creen 
                      que trata el tema. Anoten sus respuestas en la tabla en donde corresponda a cada uno.
                     </li>
                     <li className="li">
                      Después, tracen otra tabla. Realicen una pequeña discusión para elegir un tema 
                      de la lluvia de ideas y piensen en los conocimientos que tiene cada uno sobre el 
                      tema, como en el ejemplo:
                     </li>
                   </ul>

                    <tr>
                      <td valign="middle" className="Td Td1 Verde"><b>Tema elegido:</b>  </td>
                      <td valign="middle" className="Td Td2 Verde"></td>
                      <td valign="middle" className="Td Td3 Verde"></td>
                    </tr>
                    <tr>
                      <td className="Td Amarillo"><b></b></td>
                      <td valign="middle" className="Td Amarillo"><b>Mi nombre</b></td>
                      <td valign="middle" className="Td Amarillo"><b>María</b></td>
                    </tr>
                    <tr>
                      <td className="Td TdS">Conocimientos previos que <br/>tengo sobre el tema.</td>
                      <td className="Td TdS">
                        Sé sobre el proceso de <br/>
                        las erupciones <br/>
                        volcánicas.<br/><br/>
                        Conozco las partes de <br/>
                        un volcán.<br/><br/>
                        He visto videos sobre <br/>
                        volcanes y tengo <br/>
                        conciencia de lo <br/>
                        peligrosos que pueden <br/>
                        ser.
                      </td>
                      <td className="Td TdS">
                        Tengo conocimiento <br/>
                        sobre las partes de un <br/>
                        volcán.<br/><br/>
                        Soy consciente de los <br/>
                        riesgos que provoca un <br/>
                        volcán.<br/><br/>
                        Mi maestra de <br/>
                        geografía nos enseñó <br/>
                        en clase los lugares<br/>
                        donde se localizan los <br/>
                        principales volcanes del <br/>
                        mundo.
                      </td>
                    </tr>
                    <ul className="ul">
                      <li className="li">
                        Es tiempo de realizar una lectura global del texto. Pongan mucha atención en 
                        cada detalle. Al terminar respondan:
                      </li>
                      <ul className="ul">
                        <li className="li">
                          ¿El texto trataba sobre lo que sospechaban?
                        </li>
                        <li className="li">
                          ¿Los conocimientos que tienen son adecuados para comprender mejor el 
                          texto? Si la respuesta a esta pregunta es no, ánimo, aún pueden aprender 
                          más sobre el tema.
                        </li>
                        <li className="li">
                          ¿Por qué es importante activar sus conocimientos previos cuando realizan 
                          una lectura?
                        </li>
                      </ul>
                    </ul>

                    Al principio, puede parecer un poco complicado, pero con el tiempo y la práctica  <br/>
                    lo harán mejor. Siempre investiguen un poco más acerca del tema. Platiquen o <br/>
                    intercambien ideas con sus familiares, maestros o amigos. ¡Todos los <br/>
                    conocimientos adquiridos les serán de mucha ayuda para comprender temas más <br/>
                    complejos! ¡Sigamos!


                  </p>
              </Grid>
                
        </Grid>
        
        <Grid container item sm={12} className={classes.ContainerBarraInferior} justifyContent="center">
            <Button id="BotonContinuarEstrategias" className={classes.BotonAvanza} onClick={handleContinue}>
              Continuar
            </Button>
        </Grid>
        
      </Grid>

      <Grid container item sm={12} className={classes.ContainerBarraDerecha}></Grid>

    </Grid>

  )
}

export default ReadingStrategys;
