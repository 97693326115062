import React from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";

import { GridCloseIcon } from "@mui/x-data-grid";
import { Grid, makeStyles } from "@material-ui/core";

import { Link } from "react-router-dom";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    // padding: theme.spacing(2),

    [theme.breakpoints.up("sm")]: {
      // margin: "20px 0",
      paddingTop: "0",
      paddingBottom: "0",
    },
  },
  "& .MuiDialog-paper": {
    borderRadius: "12.1px",
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
    display: "flex",
    justifyContent: "center",
    gap: "5%",
    marginBottom: "16px",
  },
  "& .MuiDialogTitle-root": {
    [theme.breakpoints.up("sm")]: {
      // margin: "20px 0",
      padding: "22px",
    },
  },
}));

const useStyles = makeStyles((theme) => ({
  containerContent: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    marginBottom: "20px",
    // gap: "20px",
    [theme.breakpoints.up("sm")]: {
      // margin: "20px 0",
    },
  },
  containerButtons: {
    display: "flex",
    justifyContent: "center",
  },
  button: {
    width: "80%",
    maxWidth: "320px",
    borderRadius: "61px",
    // height: "50px",
    border: "none",
    backgroundColor: "#9051b1",
    color: "#fffffff",
    font: " bold Lato",
    fontSize: "16px",
    letterSpacing: "1.96px",
    textTransform: "none",
    textDecoration: "none",
    textAlign: "center",
    padding: "5px 0",
    fontWeight: "500",
    // minHeight: "50px",s
    "&:hover": {
      backgroundColor: "#B965D3",
      fontWeight: "bold",
    },
    [theme.breakpoints.up("lg")]: {
      padding: "10px 0",
      fontSize: "18px",
    },

    [theme.breakpoints.up("xl")]: {
      padding: "20px 0",
      fontSize: "22px",
      maxWidth: "450px",
    },
  },
  PurpleButton: {
    color: "#FFFFFF",
    backgroundColor: "#9051b1",
    borderRadius: "30px",
    alignSelf: "center",
    width: "100%",
    maxWidth: "238px",
    // minHeight: "45px",
    border: "none",
    textTransform: "none",
    textDecoration: "none",
    textAlign: "center",
    cursor: "pointer",
    fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
    fontWeight: "500",
    // padding: "6px 16px",
    lineHeight: "3",
    transition:
      "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    boxShadow:
      "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
    "&:hover": {
      backgroundColor: "#B965D3 ",
    },
    [theme.breakpoints.up("lg")]: {
      gridArea: "button",
    },
  },

  title: {
    font: "bold 32px Lato",
    textAlign: "center",
    margin: "0px",
    width: "100%",
    maxWidth: "448px",
    color: "#9051b1",
    [theme.breakpoints.up("xl")]: {
      fontSize: "36px",
    },
  },
  subtitle: {
    // width: "364px",
    // height: "36px",
    // margin: "11px 63.7px 18px 35.3px",
    fontFamily: "Lato",
    margin: "0px",
    // fontFamily: "Fredoka One",
    fontSize: "12px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "0.23px",
    textAlign: "center",
    [theme.breakpoints.up("lg")]: {
      fontSize: "18px",
      margin: "20px",
      fontWeight: "600",
    },
  },
  text: {
    // width: "364px",
    // height: "36px",
    // margin: "11px 63.7px 18px 35.3px",
    fontFamily: "Lato",
    margin: "0px",
    // fontFamily: "Fredoka One",
    fontSize: "12px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "0.23px",
    textAlign: "center",
    [theme.breakpoints.up("lg")]: {
      fontSize: "16px",
      margin: "20px",
    },
  },

  containerInput: {
    gap: "20px",
    marginBottom: "5%",
  },
  inputLoginPassword: {
    width: "80%",
    maxWidth: "280px",
    height: "45px",
    padding: "0 27px",
    borderRadius: "61px",
    border: "solid #F3CD52 3px ",
    background: "#FFFFFF",
    font: "Helvetica",
    fontSize: "16px",
    "&:focus": {
      outline: "none",
    },
    [theme.breakpoints.up("xl")]: {
      height: "71px",
      maxWidth: "421px",
      fontSize: "18px",
    },
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 4 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <GridCloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const ModalRegistroExitoso = ({ open, handleClose, data }) => {
  const classes = useStyles();

  return (
    <>
      <BootstrapDialog
        // onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        maxWidth="sm"
        fullWidth={true}
      >
        <DialogContent>
          <Grid container justifyContent="center" alignItems="center">
            <Grid
              container
              item
              direction="column"
              justifyContent="center"
              alignItems="center"
              className={classes.containerContent}
            >
              {/* <h1 className={classes.title}>Bienvenido</h1> */}
              <h3 className={classes.subtitle}>Tu nombre de usuario es:</h3>
              <h3 className={classes.title}>{data?.nombreUsuario}</h3>
              <h3 className={classes.subtitle}> Tu contraseña es:</h3>
              <h3 className={classes.title}>{data?.contrasenia}</h3>
              <p className={classes.text}>
                Con este usuario podrás acceder a <b>Circuito de lectores, </b>
                cópialo y guárdalo en un lugar seguro.
              </p>
              {data?.codigoClase && (
                <p className={classes.text}>ID de clase: {data?.codigoClase}</p>
              )}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions
          disableSpacing={true}
          className={classes.containerButtons}
        >
          <Link to="/" className={classes.PurpleButton}>
            Aceptar
          </Link>
        </DialogActions>
      </BootstrapDialog>
    </>
  );
};
export default ModalRegistroExitoso;
