import React                from "react";
import { makeStyles,Grid }  from '@material-ui/core';
import Divider              from '@material-ui/core/Divider';

import lectura1                           from './badgesImages/Insignia_lectura1.png'
import lectura2                           from './badgesImages/Insignia_lectura2.png'
import lectura3                           from './badgesImages/Insignia_lectura3.png'
import holograma1                         from './badgesImages/Insignia_holograma1.png'
import holograma2                         from './badgesImages/Insignia_holograma2.png'
import holograma3                         from './badgesImages/Insignia_holograma3.png'
import principianteStar                   from './colorlessImages/Insignia_estrella1A.png'
import aprendizStar                       from './colorlessImages/Insignia_estrella2A.png'
import experimentadoStar                  from './colorlessImages/Insignia_estrella3A.png'
import expertoStar                        from './colorlessImages/Insignia_estrella4A.png'
import principianteA                      from './badgesImages/Insignia_estrella1.png'
import aprendizA                          from './badgesImages/Insignia_estrella2.png'
import experimentadoA                     from './badgesImages/Insignia_estrella3.png'
import expertoA                           from './badgesImages/Insignia_estrella4.png'
import MainBadge                          from "./MaindBadge";
import LevelStarBadge                     from "./LevelStarBadge";




const useStyles = makeStyles(theme =>({
  mainContainer: {
    width:'100vw',
    padding:'62px 0 0 0 ',
    [theme.breakpoints.down(1440)]:{
      padding:'52px 0 0 20px ',
    },
    [theme.breakpoints.down(1024)]:{
      padding:'37px 0 0 0px ',
    },
    [theme.breakpoints.down(700)]:{
      padding:'27px 0 0 0px ',
    },
    [theme.breakpoints.down(600)]:{
      padding:'87px 0 0 0px ',
    },
  },
  mainContainer2:{
    borderRadius: "20px",
    border: "#C692E2 3px solid",
    boxShadow: '0px 2px 5px #0000006C',
    maxWidth: '1380px',
    // width:"1380px",
    // height: "901px",
  },
  cuadrado:{
    backgroundColor: '#C692E2',
    border: "#707070 1px solid",
    height:'201px',
    width: "198px"
  },
  bolita:{
    backgroundColor: '#9163A5',
    border: "#707070 1px solid",
    borderRadius: "90px",
    height:'138px',
    width: "139px"
  },
  bolita2:{
    backgroundColor: '#AF7FC4',
    border: "#707070 1px solid",
    borderRadius: "90px",
    height:'138px',
    width: "139px"
  },
  bolita3:{
    backgroundColor: '#C8A5D8',
    border: "#707070 1px solid",
    borderRadius: "90px",
    height:'138px',
    width: "139px"
  },
  bolita4:{
    backgroundColor: '#CDABDB',
    border: "#707070 1px solid",
    borderRadius: "90px",
    height:'138px',
    width: "139px"
  },
  badgesTitle:{
    font:'38px Fredoka One',
    letterSpacing: '0.28px',
    color: '#7943B1',
    marginBottom: '0',
    [theme.breakpoints.down(700)]:{
      font:'30px Fredoka One',
    },
    [theme.breakpoints.down(500)]:{
      font:'24px Fredoka One',
    },
  },
  divider:{
    marginLeft: "5%",
    marginRight: "5%",
    width:"90%",
    color: "#C692E2",
    borderBottom: "#C692E2 1px solid",
    // [theme.breakpoints.down(700)]:{
    //   font:'30px Fredoka One',
    // },
  },
  badgesContainer:{
    maxHeight: '400px',
    margin: '30px 0',
    [theme.breakpoints.down(600)]:{
      maxHeight: '300px',
      margin: '20px 0',
    },
  }
}));

const BadgesReading = (props) => {
  const classes = useStyles()
  const { comprenBadges } = props

  console.log(comprenBadges, "compren")
  return(
    <Grid 
      container
      direction="column"
      item 
      sm={12}
      className={classes.mainContainer}
      justifyContent="center" 
      alignItems="center"
    >
      <Grid container className={classes.mainContainer2}> 
        <Grid container item xs={12}>
          <Grid container item xs={12} justifyContent="center" alignItems="center">
            <h2 className={classes.badgesTitle}>Insignias de lectura</h2>
          </Grid>
        </Grid>
        <Grid container item xs={12} direction="column" justifyContent="center" alignItems="center" className={classes.badgesContainer}>
          <Grid container item xs={3}>
            <MainBadge
              badgeImage={comprenBadges > 299 ? holograma1 : lectura1}
              badgeText={"Resolución de misterios"}
            />
          </Grid>
          <Grid container item xs={8} direction="row" justifyContent="space-around">
            <LevelStarBadge
              badgesStyle={comprenBadges > 34}
              badgeLevelImage={comprenBadges > 34 ? principianteA :principianteStar}
              badgeLevelText={"Principiante"}
            />
            <LevelStarBadge
              badgesStyle={comprenBadges > 114 }
              badgeLevelImage={comprenBadges > 114 ? aprendizA : aprendizStar}
              badgeLevelText={"Aprendiz"}
            />
            <LevelStarBadge
              badgesStyle={comprenBadges > 199}
              badgeLevelImage={comprenBadges > 199 ? experimentadoA : experimentadoStar}
              badgeLevelText={"Experimentado"}
            />
            <LevelStarBadge
              badgesStyle={comprenBadges > 299}
              badgeLevelImage={comprenBadges > 299 ? expertoA : expertoStar}
              badgeLevelText={"Experto"}
            />
          </Grid>
          {/* <Grid container item xs={1}></Grid> */}
        </Grid>
        <Divider classes={{root: classes.divider}}variant="middle" />
        <Grid container item xs={12} direction="column" justifyContent="center" alignItems="center" className={classes.badgesContainer}>
          <Grid container item xs={3}>
            <MainBadge
              badgeImage={comprenBadges > 264 ? holograma2 : lectura2}
              badgeText={"Búsqueda de palabras"}
            />
          </Grid>
          <Grid container item xs={8} direction="row" justifyContent="space-around">
            <LevelStarBadge
              badgesStyle={comprenBadges > 9 }
              badgeLevelImage={comprenBadges > 9 ? principianteA : principianteStar}
              badgeLevelText={"Principiante"}
            />
            <LevelStarBadge
              badgesStyle={comprenBadges > 79 }
              badgeLevelImage={comprenBadges > 79 ? aprendizA : aprendizStar}
              badgeLevelText={"Aprendiz"}
            />
            <LevelStarBadge
              badgesStyle={comprenBadges > 169}
              badgeLevelImage={comprenBadges > 169 ? experimentadoA : experimentadoStar}
              badgeLevelText={"Experimentado"}
            />
            <LevelStarBadge
              badgesStyle={comprenBadges > 264}
              badgeLevelImage={comprenBadges > 264 ? expertoA : expertoStar}
              badgeLevelText={"Experto"}
            />
          </Grid>
        </Grid>
        <Divider classes={{root: classes.divider}}variant="middle" />
        <Grid container item xs={12} direction="column" justifyContent="center" alignItems="center" className={classes.badgesContainer}>
          <Grid container item xs={3}>
            <MainBadge
              badgeImage={comprenBadges > 329 ? holograma3 : lectura3}
              badgeText={"Valoración de textos"}
            />
          </Grid>
          <Grid container item xs={8} direction="row" justifyContent="space-around">
            <LevelStarBadge
              badgesStyle={comprenBadges > 59 }
              badgeLevelImage={comprenBadges > 59 ? principianteA : principianteStar}
              badgeLevelText={"Principiante"}
            />
            <LevelStarBadge
              badgesStyle={comprenBadges > 139 }
              badgeLevelImage={comprenBadges > 139 ? aprendizA : aprendizStar}
              badgeLevelText={"Aprendiz"}
            />
            <LevelStarBadge
              badgesStyle={comprenBadges > 234 }
              badgeLevelImage={comprenBadges > 234 ? experimentadoA : experimentadoStar}
              badgeLevelText={"Experimentado"}
            />
            <LevelStarBadge
              badgesStyle={comprenBadges > 329 }
              badgeLevelImage={comprenBadges > 329 ? expertoA : expertoStar}
              badgeLevelText={"Experto"}
            />
          </Grid>
          {/* <Grid container item xs={1}></Grid> */}
        </Grid>
      </Grid>
    </Grid>
  )
}

export default BadgesReading;
