import { Button, Grid, makeStyles } from "@material-ui/core";
import React, { useContext, useEffect } from "react";
import DrawerContext from "../../../../../usability/DrawerContext";
import { useOnLoadImages } from "../../../../hooks/useOnLoadImages";
import Loader from "../../../../Loader/Loader";
import Drawer from "../../ReadingTools/Drawer";

const useStyles =  makeStyles (theme =>({
     Contenedor:{
        background:'#E9FBF9',
        width:'100vw',
        overflowX:'clip',
        [theme.breakpoints.down(1161)]:{
            background:'none',
           overflowX:'unset',
        },
        [theme.breakpoints.down(600)]:{
            marginTop: '79.51px',
        }
    },
      ContainerInfoLectura: {
    position: "relative",
    height: "86px",
    [theme.breakpoints.down(502)]:{
        height: "43px",
    },
    background: "#76D4C3",
    "& .BarraInfoLectura": {
      height: "86px",
      background: `Url(https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Barras/Azul.png)`,
        [theme.breakpoints.down(502)]:{
            height: "43px",
        },
    },
    "& .InfoLectura": {
      position: "absolute",
      height: "86px",
      width: "219px",
      boxSizing: "border-box",
      padding: "17.2px 0",
      background: "#FAFAFA",
      borderRadius: "50px",
      boxShadow: "2px 2px 2px 2px #00000029",
      zIndex: "4",
        [theme.breakpoints.down(502)]:{
            height: "43px",
            width: "109px",
            padding: "8.6px 0",
            borderRadius: "25px",
        },
    },
    "& .InfoLectura:before": {
      position: "absolute",
      display: "flex",
      boxSizing: "border-box",
      padding: "0 0 0 10px",
      justifyContent: "center",
      alignItems: "center",
      height: "39.88px",
      width: "39.88px",
      borderRadius: "50%",
      content: '""',
      boxShadow: "3px 0px .5px 0px #00000029",
      background: "#FAFAFA",
      left: "90px",
      top: "65px",
      transform: "rotate(0.25turn)",
      [theme.breakpoints.down(502)]:{
        left: "45px",
        top: "30px",
        height: "20.88px",
        width: "20.88px",
        },
    },
    "& .InfoLectura:after": {
      content: '""',
      height: "15px",
      width: "15px",
      borderTop: "2px solid #20A698",
      borderLeft: "2px solid #20A698",
      position: "absolute",
      bottom: "-10px",
      left: "Calc(50% - 8px)",
      transform: "rotate(0.625turn)",
      [theme.breakpoints.down(502)]:{
        height: "10px",
        width: "10px",
        left: "Calc(50% - 4.5px)",
        bottom: "-3px",
        borderTop: "1px solid #20A698",
        borderLeft: "1px solid #20A698",
        },
    },
    "& .Sesion": {
      borderLeft: "2px solid #D8D8D8",
    },
    "& .Dato": {
      margin: "0 0 5px 0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#20A698",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        margin: "0 0 2.5px 0",
        },
    },
    "& .Caracter": {
      margin: "0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#000000",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        },
    },
       '& .Regresar':{
        color: '#F2624F',
        border: '#F2624F 1px solid',
        height: '30px',
        width:'100px',
        borderRadius: '20px',
        letterSpacing: '0.14px',
        textTransform: 'none',
        background:'#FEFAF1',
        top:'calc(50% - 15px)',
        left:'5%',
        zIndex:4,
        [theme.breakpoints.down(502)]: {
          font:'bold 10px lato',
          width: "60px",
        },
      },
  },
    ContenedorLectura:{
        background:'#fff',
        width:'1160px',
        height:'100%',
        padding:'60px 168px 35px ',
        
        transform: (props) =>
        props.scale > 1 ? `scale(${props.scale}) translate(0px, 10%)` : `none`,
        [theme.breakpoints.down(1161)]:{
            width:'502px',
            padding:'60px 0 0 0',
          },
        [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },

        '& .Escritorio':{
            display:'block',
            [theme.breakpoints.down(1161)]:{
                display:'none'
            },
        },
        '& .Tablet':{
            display:'none',
            [theme.breakpoints.down(1161)]:{
                display:'block'
            },
        }
    },
    ContainerTitulo:{
        margin:'0 0 46px 0',
        '& .Titulo':{
            letterSpacing: '0px',
            color: '#32BEDE',
            margin:'0',
            textAlign:'center',
            font:'48px fredoka one',
            [theme.breakpoints.down(1161)]:{
                font:'43px fredoka one',
                lineHeight:'52px',
            },
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            margin:'0 0 36px 0 ',
            color:'#000',
            font:'22px lato',
            letterSpacing: '0.21px',
            lineHeight:'30px',
            [theme.breakpoints.down(1161)]:{
                font:'18px lato',
                lineHeight :'28px',
                letterSpacing: '0.17px',
            },
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .SubTitulo':{
            margin:'0',
            color:'#823CE2',
            font:'bold 30px lato',
            lineHeight:'30px',
            letterSpacing:'.28px',
            [theme.breakpoints.down(1161)]:{
                font:'bold 22px lato',
                lineHeight :'24px',
                letterSpacing: '0.21px',
            },
            [theme.breakpoints.down(502)]:{
                font:'bold 18px lato',
                lineHeight :'20px',
                letterSpacing: '0.16px',
            },
        },
        '& .Esp':{
            boxSizing:'border-box',
            padding:'0 19px 0 0',
            margin:'0',
            [theme.breakpoints.down(1161)]:{
                padding:'0 ',
                margin:'0 0 36px 0',
            },
        },
        '& .TextImg':{
            margin:'22px 0 0 0',
            color:'#717476',
            font:'20px lato',
            letterSpacing:'.19px',
            [theme.breakpoints.down(1161)]:{
                margin:'22px 0 0 0',
                font:' 16px lato',
                lineHeight :'25px',
                letterSpacing: '0.15px',
            },
            [theme.breakpoints.down(502)]:{
                font:' 11px lato',
                lineHeight :'14px',
                letterSpacing: '0.10px',
            },
        },
        '& .Esp2':{
            boxSizing:'border-box',
            padding:'0 32px 0 0',
            margin:' 0',
            [theme.breakpoints.down(1161)]:{
                padding:'0 ',
                margin:'22px 0 0 0',
            },
        },
        '& .Imagen':{
            [theme.breakpoints.down(1161)]:{
                width:'100%'
            },
        }
        

    },
    

}));

const Los_polos_se_derriten = (props) => {

  const {activeStep, setActiveStep, handlePreviewStep, id_actividad} = props
  
  const handleContinue = () => {
    setActiveStep(activeStep - 1)
    handlePreviewStep()
  }

  const {
    handleSize,
    disabledZoom,
    closeZoom,
    handleZoom,
    scale
  }= useContext(DrawerContext);
  const [loading, setLoading] = React.useState(true);
  const wrapperRef = React.useRef(null);
  const imagesLoaded = useOnLoadImages(wrapperRef);

  const detectSize = () => {
    const lectura = document.getElementById("lectura");
    const newWidth = lectura?.offsetWidth;
    const newHeight = lectura?.offsetHeight;
    setLoading(false);
    if (lectura) handleSize(newHeight, newWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", detectSize);
    return () => {
      handleZoom()
      window.removeEventListener("resize", detectSize);
      closeZoom()
    };
  }, []);

  const classes = useStyles({ disabledZoom,scale });
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
             <Grid container item justifyContent="center" className={classes.ContainerInfoLectura}>
                       <Grid container className="BarraInfoLectura">
            <Button className="Regresar" onClick={handleContinue}>
                Regresar
            </Button>
        </Grid>
               <Grid container item className="InfoLectura">
                    <Grid container item direction="column" xs={6} justifyContent="center">
                        <p className="Dato">Grado</p>
                        <p className="Caracter">4</p>
                    </Grid>
                    <Grid container item direction="column" xs={6} justifyContent="center" className="Sesion">
                        <p className="Dato">Sesión</p>
                        <p className="Caracter">33</p>
                    </Grid>
               </Grid>
            </Grid>
            {!imagesLoaded && <Loader />}
        <Grid
            container
            item
            className={classes.ContenedorLectura}
            justifyContent="center"
            id="lectura"
            onLoad={detectSize}
            ref={wrapperRef}
        >
<Drawer id_lectura={id_actividad} />
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >
                        Los polos se derriten
                    </h2>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container md={12} lg={5}>
                        <p className="Lectura Esp">
                            El calentamiento global 
                            ocasionado por las actividades 
                            humanas ha aumentado casi un 
                            grado Celsius la temperatura del 
                            mundo. La Tierra ha tenido unas 
                            etapas de frío intenso y otras de 
                            calentamiento a lo largo del 
                            tiempo. Esto se produce cuando 
                            cambia la cantidad de radiación 
                            que llega del Sol. A su vez, esta 
                            variación se origina cuando el 
                            planeta cambia su inclinación o 
                            modifica el camino que sigue 
                            alrededor del Sol.<br/><br/> 
                            
                            Los resultados del calentamiento 
                            global son muchos. Entre ellos 
                            está que el hielo de los polos se 
                            derrita y los animales que viven 
                            en esos ecosistemas pierdan su 
                            hábitat y mueran por falta 
                            de alimento.
                        </p>
                    </Grid>
                    <Grid container justifyContent="center" md={12} lg={7}>
                        <Grid container item justifyContent='center'>
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img4to/Sesion33/1.png"  alt="Tarjeta" />
                        </Grid>
                        <Grid container item justifyContent='center'>
                            <p className='TextImg Esp2'>
                                Los osos polares son una de las tantas especies que se ven afectadas por el incremento en la temperatura promedio.
                            </p>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container xs={12}>
                        <p className="Lectura ">
                            Las emisiones de dióxido de carbono han incrementado rápidamente desde la 
                            Revolución industrial. El <em>dióxido de carbono</em> (abreviado como <span style={{fontVariant: "all-small-caps"}}>CO</span><em><sub>2</sub></em>) es un gas que se 
                            produce cuando se queman combustibles fósiles, como gasolina y diésel. También
                            proviene de otras fuentes, como los incendios forestales.<br/><br/> 
                            
                            Las plantas necesitan <span style={{fontVariant: "all-small-caps"}}>CO</span><em><sub>2</sub></em> para realizar la fotosíntesis y producir los alimentos que 
                            consumen los seres vivos. Pero en cantidades elevadas, el gas contribuye al efecto 
                            invernadero, que ha elevado la temperatura de la Tierra.<br/><br/>  
                            
                            El aumento de la temperatura desencadena muchos problemas ambientales. El 
                            derretimiento de los polos, por ejemplo, hace que el nivel de los océanos crezca. Si 
                            esto continúa así, muchas ciudades costeras serán absorbidas por el mar y quedarán 
                            debajo del agua. Algunas islas podrían desaparecer en su totalidad. La caída de 
                            grandes masas de hielo provoca olas gigantes que causan daños a las costas y a las 
                            poblaciones que viven en ellas.<br/><br/> 
                             
                            En varios países de América Latina existen glaciares en las montañas o volcanes con 
                            más de 5 000 metros de altura que también corren el peligro de desaparecer. Lo 
                            anterior se debe a que no cae la nieve suficiente por el cambio de clima y no pueden
                            mantenerse congelados. Algunos volcanes donde se ubican estos glaciares han 
                            entrado en actividad, lo cual derrite la nieve y los extingue por completo.
                        </p>
                    </Grid>
                    <Grid container justifyContent="center" xs={12}>
                        <Grid container item justifyContent='center'>
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img4to/Sesion33/2.png"  alt="Tarjeta" />
                        </Grid>
                        <Grid container item justifyContent='center'>
                            <p className='TextImg '>
                                Cuando los glaciales se derriten, incrementan el nivel del mar, poniendo en riesgo ciudades costeras e islas enteras.
                            </p>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container xs={12}>
                        <p className="Lectura ">
                            Una solución al calentamiento global podría ser el incremento en la cantidad de 
                            árboles. Distintas organizaciones internacionales han concluido que se requiere 
                            sembrar millones de árboles en lugares que permitan su desarrollo. Tardarían 
                            aproximadamente diez años en alcanzar su madurez y, una vez hecho esto, serían 
                            capaces de absorber dos terceras partes de las emisiones de dióxido de carbono que 
                            se han producido desde la Revolución industrial. Después, mediante la fotosíntesis 
                            lo convertirían en oxígeno y éste quedaría limpio para ser respirado por todos los 
                            seres vivos. <br/><br/>
                            
                            Todos podemos hacer aportes para disminuir la temperatura del mundo y detener 
                            las catástrofes. Algunos ejemplos son usar menos el automóvil, caminar cuando la 
                            distancia por recorrer es pequeña, utilizar poco la calefacción o el aire 
                            acondicionado. Igualmente podemos evitar quemar basura, prevenir incendios 
                            forestales, cuidar los jardines y participar en programas de reforestación. Hay 
                            muchas acciones que podemos hacer desde casa ¡Conservemos sano nuestro 
                            planeta Tierra!
                        </p>
                    </Grid>
                </Grid>
        </Grid>
        <Grid container item style={{ marginTop: 80}} ></Grid>
    </Grid>
    );
}
 
export default Los_polos_se_derriten;
