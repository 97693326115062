import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
     Contenedor:{
        background:'#E9FBF9',
        width:'100vw',
        overflowX:'clip',
        [theme.breakpoints.down(1161)]:{
            background:'none',
           overflowX:'unset',
        },
        [theme.breakpoints.down(600)]:{
            marginTop: '79.51px',
        }
    },
  ContainerInfoLectura: {
    position: "relative",
    height: "86px",
    [theme.breakpoints.down(502)]:{
        height: "43px",
    },
    background: "#76D4C3",
    "& .BarraInfoLectura": {
      height: "86px",
      background: `Url(https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Barras/Azul.png)`,
        [theme.breakpoints.down(502)]:{
            height: "43px",
        },
    },
    "& .InfoLectura": {
      position: "absolute",
      height: "86px",
      width: "219px",
      boxSizing: "border-box",
      padding: "17.2px 0",
      background: "#FAFAFA",
      borderRadius: "50px",
      boxShadow: "2px 2px 2px 2px #00000029",
      zIndex: "4",
        [theme.breakpoints.down(502)]:{
            height: "43px",
            width: "109px",
            padding: "8.6px 0",
            borderRadius: "25px",
        },
    },
    "& .InfoLectura:before": {
      position: "absolute",
      display: "flex",
      boxSizing: "border-box",
      padding: "0 0 0 10px",
      justifyContent: "center",
      alignItems: "center",
      height: "39.88px",
      width: "39.88px",
      borderRadius: "50%",
      content: '""',
      boxShadow: "3px 0px .5px 0px #00000029",
      background: "#FAFAFA",
      left: "90px",
      top: "65px",
      transform: "rotate(0.25turn)",
      [theme.breakpoints.down(502)]:{
        left: "45px",
        top: "30px",
        height: "20.88px",
        width: "20.88px",
        },
    },
    "& .InfoLectura:after": {
      content: '""',
      height: "15px",
      width: "15px",
      borderTop: "2px solid #20A698",
      borderLeft: "2px solid #20A698",
      position: "absolute",
      bottom: "-10px",
      left: "Calc(50% - 8px)",
      transform: "rotate(0.625turn)",
      [theme.breakpoints.down(502)]:{
        height: "10px",
        width: "10px",
        left: "Calc(50% - 4.5px)",
        bottom: "-3px",
        borderTop: "1px solid #20A698",
        borderLeft: "1px solid #20A698",
        },
    },
    "& .Sesion": {
      borderLeft: "2px solid #D8D8D8",
    },
    "& .Dato": {
      margin: "0 0 5px 0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#20A698",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        margin: "0 0 2.5px 0",
        },
    },
    "& .Caracter": {
      margin: "0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#000000",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        },
    },   },
    ContenedorLectura:{
        background:'#fff',
        width:'1160px',
        height:'100%',
        padding:'60px 168px 35px ',
        [theme.breakpoints.down(1161)]:{
            width:'502px',
            padding:'60px 0 0 0',
          },
        [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
        '& .ContainerDerechos':{
            bottom:'80px',
            [theme.breakpoints.down(1161)]:{
                display:'none',
            },
        },
        '& .Derechos':{
            textAlign:"center",
            margin:"0px",
            font:" 15px lato",
            color:"#717476",
        },
        '& .Escritorio':{
            display:'block',
            [theme.breakpoints.down(1161)]:{
                display:'none'
            },
        },
        '& .Tablet':{
            display:'none',
            [theme.breakpoints.down(1161)]:{
                display:'block'
            },
        }
    },
    ContainerTitulo:{
        margin:'0 0 61px 0',
        '& .Titulo':{
            font:'48px fredoka one',
            letterSpacing: '0px',
            color: '#FF0892',
            margin:'0',
            textAlign:'center',
            [theme.breakpoints.down(1161)]:{
                font:'43px fredoka one',
                lineHeight:'52px',
            },
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            lineHeight : '30px',
            margin:'0 0 36px 0 ',
            font:'22px lato',
            color:'#000',
            letterSpacing: '0.20px',
            [theme.breakpoints.down(1161)]:{
                font:'18px lato',
                lineHeight :'28px',
                letterSpacing: '0.17px',
            },
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .Esp':{
            margin:' 0 ',
        },
        '& .SubTitulo':{
            font:'bold 30px lato',
            color:'#7940DA',
            letterSpacing:'.28px',
            margin:'0',
            [theme.breakpoints.down(1161)]:{
                lineHeight :'28px',
                font:'bold 26px lato',
                letterSpacing: '0.24px',
            },
            [theme.breakpoints.down(502)]:{
                font:'bold 18px lato',
                lineHeight :'24px',
                letterSpacing: '0.21px',
            },
        },
        '& .TextImg':{
            margin:'22px 0 0 0',
            font:'20px lato',
            alignSelf:'flex-start',
            color:'#717476',
            [theme.breakpoints.down(1161)]:{
                font:' 16px lato',
                lineHeight :'25px',
                letterSpacing: '0.15px',
            },
            [theme.breakpoints.down(502)]:{
                font:' 11px lato',
                lineHeight :'14px',
                letterSpacing: '0.10px',
            },
        },

        '& .li':{
            margin:'0 0 8px 0',
            [theme.breakpoints.down(502)]:{
            margin:'0 0 4px 0',
            }
        },

        '& .ul':{
            listStyle:'alphanumeric',
            margin:'0',
            color:'#823CE2',
            font:'20px lato',
            padding:'0 0 0 19px',
            [theme.breakpoints.down(1116)]:{
                font:'18px lato',
            },
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
            }
        },

        '& .Imagen':{
            [theme.breakpoints.down(1161)]:{
                width:'543px',
            },
            [theme.breakpoints.down(502)]:{
                width:'100%',
            },
        },
    },

}));

const Los_números_romanos = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
             <Grid container item justifyContent="center" className={classes.ContainerInfoLectura}>
               <Grid container className="BarraInfoLectura"></Grid>
               <Grid container item className="InfoLectura">
                    <Grid container item direction="column" xs={6} justifyContent="center">
                        <p className="Dato">Grado</p>
                        <p className="Caracter">2</p>
                    </Grid>
                    <Grid container item direction="column" xs={6} justifyContent="center" className="Sesion">
                        <p className="Dato">Sesión</p>
                        <p className="Caracter">10</p>
                    </Grid>
               </Grid>
            </Grid>

            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >
                    Los números romanos
                    </h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                      <p className="Lectura">
                        <h3 className='SubTitulo'>Algunas curiosidades sobre su uso </h3><br/>

                        Como sabrás, actualmente utilizamos un sistema numérico llamado <em>decimal</em>, cuyos 
                        dígitos (del 0 al 9) nos permiten escribir cualquier número natural.<br/><br/>

                        Los <em>números romanos</em> son otro sistema que (como su nombre lo indica) se desarrolló 
                        en la antigua Roma. Aunque ahora ya no tienen una aplicación matemática, todavía 
                        podemos encontrarlos en ciertos ámbitos. Por ejemplo, aparecen en relojes de 
                        manecillas, tomos y capítulos de libros, nombres de reyes y papas, ediciones de 
                        eventos, celebraciones o aniversarios, entre otros. Se podría decir que son una 
                        manera elegante o culta de numerar en la actualidad.
                      </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <Grid container justifyContent="center" alignItems='flex-end'>
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img2do/Sesion10/2.png" alt="Tarjeta" />
                        </Grid>
                        <Grid container >
                            <p className='TextImg'> Te recomendamos que te aprendas al menos los números y combinaciones básicas del 
                            sistema romano, ya que lo seguirás hallando en diversos sitios.
                            </p>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                      <p className="Lectura">
                        <h3 className='SubTitulo'>Las complicaciones de los números romanos </h3><br/>
                         Este sistema consta de siete letras mayúsculas y a cada una se le asigna un valor, 
                         como puedes observar en esta tabla:
                      </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <Grid container justifyContent="center" alignItems='flex-end'>
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img2do/Sesion10/3.png" alt="Tarjeta" />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container item sm={12}>
                    <p className='Lectura'>El resto de los números se forma con base en las siguientes reglas:</p>       
                    <ul  className="ul">
                          <li className="li"><p className="Lectura Esp">Se suman los valores de cada letra. Por ejemplo: XV se compone de la suma de 10 + 5 y es igual a 15.</p></li>
                          <li className="li"><p className="Lectura Esp">Las letras de mayor valor se escriben siempre a la izquierda y las de menor, a la derecha. Por ejemplo: VII equivale a 5 + 2 y es igual a 7.</p></li>
                          <li className="li"><p className="Lectura Esp">Existen ciertas excepciones a la regla anterior: la letra de menor valor va a la izquierda cuando se trata del 4 (IV), el 9 (IX), el 40 (XL), el 90 (XC), el 400 (CD) y el 900 (CM). En estos casos a la letra de mayor valor se le resta el de la ubicada a su izquierda. Por ejemplo: IV representa la resta 5 – 1.</p></li>
                          <li className="li"><p className="Lectura Esp">Las letras I, X, C y M pueden repetirse hasta tres veces seguidas. Por ejemplo: XXXII, donde se suman 10 + 10 + 10 + 1 + 1 y es igual a 32.</p></li>
                          <li className="li"><p className="Lectura Esp">Las letras V, L y D sólo pueden escribirse una vez. Por ejemplo: LV equivale a 50 + 5 y es igual a 55.</p></li>
                      </ul>
                    </Grid>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                        En los números romanos no es posible representar el 0, ya que ninguna letra está 
                        asignada a ese valor.<br/><br/>
                        
                        Otra de las desventajas de este sistema es que la representación de algunos números 
                        puede llegar a ser muy larga. Por ejemplo: MMDCCCLXXXVIII corresponde a 2 888 
                        (dos mil ochocientos ochenta y ocho).<br/><br/> 
                        
                        ¿Qué opinas? ¿Crees que el sistema decimal es más sencillo y práctico?
                        </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <Grid container justifyContent="center" alignItems='flex-end'>
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img2do/Sesion10/1.png"alt="Tarjeta" />
                        </Grid>
                        <Grid container >
                            <p className='TextImg'> 
                            Se dice que con estos 10 números podemos escribir cualquier cantidad. ¿Quieres intentarlo?
                            </p>
                        </Grid>

                    </Grid>
                </Grid>
               
            <Grid container item xs={12} justifyContent="center" className="ContainerDerechos">
                <p className="Derechos">
                    © 2022. Todos los derechos reservados, Pearson, Circuito de lectores.
                </p>                
            </Grid>
        </Grid>
    </Grid>
    );
}
 
export default Los_números_romanos;
