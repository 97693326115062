import React from "react";
import { Grid, makeStyles } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Radio from "@material-ui/core/Radio";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

import { Redirect, useHistory } from "react-router-dom";

import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import FiberManualRecordSharpIcon from "@material-ui/icons/FiberManualRecordSharp";

const useStyles = makeStyles((theme) => ({
  MuiDialogContainer: {
    borderRadius: "61px",
    height: "80%",
    maxWidth: "80%",
    backgroundColor: "#FEFAF1",
    border: "#707070 2px solid",
    backdropFilter: "blur(3px)",
  },
  selectedStyle: {
    border: "#F2624F 5px solid",
    backgroundColor: "#FFEEEB",
    width: "100%",
    borderRadius: "5px",
  },
  continueButton: {
    marginTop: "2%",
    marginLeft: "5%",
    textTransform: "none",
    height: "62px",
    width: "279px",
    // [theme.breakpoints.down(1368)]:{
    //   height:'52px',
    //   width:'179px',
    // },
    background: "#7A54BA",
    boxShadow: "0px 3px 6px #00000029",
    borderRadius: "61px",
    color: "#FFFFFF",
    font: "bold 20px lato ",
    "&:hover": {
      background: "#dd78fb",
    },
    [theme.breakpoints.down(1368)]: {
      height: "52px",
      width: "229px",
    },
    [theme.breakpoints.down(1068)]: {
      height: "52px",
      width: "60%",
    },
    [theme.breakpoints.down(769)]: {
      width: "56.5%",
    },
  },
  // questionText: {
  //   textAlign: "Left",
  //   font: "18px lato",
  //   letterSpacing:' 0.21px',
  //   margin: "0 15px 20px 8px",
  //   [theme.breakpoints.down(1370)]:{
  //     margin: "0 15px 15px 8px",
  //   },
  //   [theme.breakpoints.down(650)]:{
  //     font: "14px lato",
  //   },
  // },
  answeringText: {
    textAlign: "Left",
    font: "22px lato",
    letterSpacing: " 0.21px",
    margin: "8px",
    [theme.breakpoints.down(1370)]: {
      font: "18px lato",
      margin: "8px 8px 8px 8px",
    },
    [theme.breakpoints.down(1280)]: {
      font: "16px lato",
    },
    [theme.breakpoints.down(650)]: {
      font: "14px lato",
      margin: "4px",
    },
  },
  feedbackText: {
    width: "85%",
    font: " 22px lato",
    letterSpacing: " 0.21px",
    textAlign: "left",
    padding: "10px",
    [theme.breakpoints.down(1370)]: {
      font: "18px lato",
      paddingLeft: "0px",
    },
    [theme.breakpoints.down(650)]: {
      font: "14px lato",
    },
  },
  questionTittle: {
    font: "30px Fredoka One",
    color: "#707070",
    height: "10px",
    marginTop: "10px",
    letterSpacing: " 0.23px",
    [theme.breakpoints.down(1370)]: {
      marginTop: "0",
    },
    [theme.breakpoints.down(475)]: {
      font: "24px Fredoka One",
    },
    [theme.breakpoints.down(435)]: {
      font: "22px Fredoka One",
    },
  },
  feedbackTittle: {
    font: "30px Fredoka One",
    color: "#F6644B",
    marginTop: "10px",
    height: "auto",
    letterSpacing: " 0.23px",
    marginLeft: "5%",
    [theme.breakpoints.down(1370)]: {
      marginTop: "0",
    },
    [theme.breakpoints.down(960)]: {
      marginLeft: "0",
    },
    [theme.breakpoints.down(475)]: {
      font: "24px Fredoka One",
      marginTop: "8px",
    },
    [theme.breakpoints.down(435)]: {
      font: "22px Fredoka One",
    },
  },
  feedbackBox: {
    border: "#F89987 3px solid",
    backgroundColor: "#FFFFFF",
    borderRadius: "59px",
    marginTop: "1%",
    minHeight: "80%",
  },
  correctIcon: {
    width: "40px",
    height: "40px",
  },
  secondaryIconFeedback: {
    color: "#F2624F",
  },
  sizeLargeIcon: {
    fontSize: "50px",
    [theme.breakpoints.down(1440)]: {
      fontSize: "40px",
    },
    [theme.breakpoints.down(480)]: {
      fontSize: "30px",
    },
  },
  questionAndAnswer: {
    width: "80%",
  },
  questionContainer: {
    minWidth: "10%",
    margin: "25px 0 5% 0",
    [theme.breakpoints.down(1370)]: {
      margin: "4% 0 5% 0",
    },
    [theme.breakpoints.down(475)]: {
      margin: "0",
    },
  },
  questionMargin: {
    marginBottom: "4%",
  },
  mainContainer: {
    height: "70%",
    // paddingLeft:"5%"
  },
  buttonContainer: {
    height: "30%",
  },
  iconsContainer: {
    paddingRight: "5%",
    margin: "1% 0",
    justifyContent: "flex-end",
    [theme.breakpoints.down(960)]: {
      justifyContent: "center",
      margin: "0 2%",
    },
  },
  MuiDialogTitleContainer: {
    paddingBottom: "0px",
  },
  selectedimageStyle: {
    border: "#F89987 5px solid",
    backgroundColor: "white",
    width: "252px",
    height: "227px",
    borderRadius: "5px",
    margin: "4%",
    [theme.breakpoints.down(1571)]: {
      width: "242px",
      height: "217px",
    },
    [theme.breakpoints.down(1441)]: {
      width: "212px",
      height: "207px",
    },
    [theme.breakpoints.down(1401)]: {
      width: "172px",
      height: "157px",
    },
    [theme.breakpoints.down(1350)]: {
      width: "162px",
      height: "147px",
    },
    [theme.breakpoints.down(960)]: {
      width: "172px",
      height: "157px",
    },
    [theme.breakpoints.down(361)]: {
      width: "152px",
      height: "137px",
    },
  },
  primaryIcon: {
    color: "#4BBCF8",
  },
  primaryIconFeedback: {
    color: "#4BBCF8",
  },
  imageContainer: {
    padding: "1%",
    margin: "0%",
    maxWidth: "457px",
    [theme.breakpoints.down(1440)]: {
      maxWidth: "257px",
      maxHeight: "257px",
    },
  },
  imageStyled: {
    maxHeight: "100%",
    maxWidth: "100%",
  },
  MuiDialogContent: {
    padding: "8px 24px",
    [theme.breakpoints.down(500)]: {
      padding: "8px 0px",
    },
  },
  radioIcon: {
    fontSize: "24px",
    [theme.breakpoints.down(625)]: {
      padding: "0px",
      fontSize: "20px",
    },
    [theme.breakpoints.down(400)]: {
      padding: "0px",
      fontSize: "15px",
    },
  },
}));

const WrongFeedback = (props) => {
  const {
    open,
    setOpen,
    feedback,
    pregunta,
    answerText,
    setStep,
    step,
    setPristine,
    feedBackImage,
    setFeedBackImage,
    questionId,
    imageList,
    remedialFinal,
    remedialNumber,
    stopQuestion,
    setStopQuestion,
    nextQuestionIndex,
    tryNumber,
    activityNumber,
    totalQuestions,
    formativa,
    evaluationType,
    isRemedial,
  } = props;
  const classes = useStyles();
  const [finished, setFinished] = React.useState(false);
  const history = useHistory();

  React.useEffect(() => {
    if (stopQuestion) {
      if (nextQuestionIndex >= 0) {
        return setStep(nextQuestionIndex);
      } else {
        if (totalQuestions > 10) {
          history.push({
            pathname: `/resultado/${evaluationType}/${activityNumber}/${tryNumber}/${totalQuestions}`,
          });
        } else if (formativa) {
          history.push({
            pathname: `/resultado/${formativa}/${activityNumber}/${tryNumber}`,
            state: {
              formativa: formativa,
              activityNumber: activityNumber,
              intento: tryNumber,
            },
          });
        } else {
          if (isRemedial) {
            history.push({
              pathname: `/retro/${remedialNumber}/${activityNumber}/1`,
            });
          } else {
            history.push({
              pathname: `/resultado/${activityNumber}/${tryNumber}`,
            });
          }
        }
      }
    }
  }, [stopQuestion]);

  const nextStep = () => {
    // setStep(Number(step + 1))
    setOpen(false);
    setPristine(true);
    setStopQuestion(true);
  };

  const nextStepFalse = () => {
    // setStep(Number(step + 1))
    setOpen(false);
    setPristine(true);
    setFeedBackImage(false);
    setStopQuestion(true);
  };

  const finalStep = () => {
    setOpen(false);
    setFinished(true);
    setStopQuestion(true);
  };

  // console.log(stopQuestion, "wrong")
  return (
    <Dialog
      open={open}
      classes={{ paper: classes.MuiDialogContainer }}
      disableEscapeKeyDown
    >
      <DialogTitle>
        <Grid
          container
          direction="row"
          alignItems="center"
          className={classes.iconsContainer}
        >
          <Grid
            container
            item
            xs={2}
            sm={1}
            justifyContent="center"
            alignItems="center"
          >
            <CheckCircleIcon
              color="disabled"
              fontSize="large"
              classes={{ root: classes.sizeLargeIcon }}
            />
          </Grid>
          <Grid
            container
            item
            xs={2}
            sm={1}
            justifyContent="center"
            alignItems="center"
          >
            <CancelIcon
              color="secondary"
              fontSize="large"
              classes={{
                colorSecondary: classes.secondaryIconFeedback,
                root: classes.sizeLargeIcon,
              }}
            />
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent classes={{ root: classes.MuiDialogContent }}>
        <Grid container item direction="row" className={classes.mainContainer}>
          {/* <Grid item xs={1}></Grid> */}
          <Grid
            container
            item
            direction="column"
            xs={12}
            md={7}
            justifyContent="flex-start"
            alignItems="center"
          >
            <Grid
              container
              item
              justifyContent="center"
              alignItems="center"
              className={classes.questionAndAnswer}
            >
              <Grid
                container
                item
                justifyContent="flex-start"
                alignItems="center"
              >
                <h2 className={classes.questionTittle}>Pregunta</h2>
              </Grid>
              <Grid
                container
                item
                justifyContent="flex-start"
                alignItems="flex-start"
                className={classes.questionContainer}
              >
                {/* <p 
                  dangerouslySetInnerHTML={{__html: `${step+1}. ${pregunta}`}}
                  className={classes.questionText}
                >
                </p> */}
                <Grid container item className={classes.questionMargin}>
                  {props.pregunta}
                </Grid>
                <br />
                <br />
                {feedBackImage ? (
                  <Grid
                    container
                    item
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Grid
                      container
                      item
                      md={6}
                      justifyContent="center"
                      alignItems="center"
                      className={classes.imageContainer}
                    >
                      <Grid
                        container
                        item
                        className={classes.selectedimageStyle}
                        justifyContent="center"
                        alignItems="center"
                      >
                        <img
                          alt={questionId}
                          src={imageList[questionId] || "#"}
                          className={classes.imageStyled}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                ) : (
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    className={classes.selectedStyle}
                  >
                    <Grid item xs={1}>
                      <Radio
                        checked={true}
                        value="e"
                        name="answer-selected-feedback"
                        inputProps={{ "aria-label": "E" }}
                        classes={{ root: classes.radioIcon }}
                        checkedIcon={
                          <FiberManualRecordSharpIcon
                            color="primary"
                            classes={{
                              colorPrimary: classes.secondaryIconFeedback,
                            }}
                          />
                        }
                      />
                    </Grid>
                    <Grid item xs={11}>
                      {/* <p className={classes.answeringText}>{answerText}</p> */}
                      <p
                        dangerouslySetInnerHTML={{ __html: `${answerText}` }}
                        className={classes.answeringText}
                      ></p>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            item
            direction="column"
            xs={12}
            md={5}
            justifyContent="flex-start"
            alignItems="center"
          >
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              className={classes.questionAndAnswer}
            >
              <Grid
                container
                item
                justifyContent="flex-start"
                alignItems="center"
              >
                <h2 className={classes.feedbackTittle}>Retroalimentación</h2>
              </Grid>
              <Grid
                container
                item
                justifyContent="center"
                alignItems="flex-start"
                className={classes.feedbackBox}
              >
                <p
                  dangerouslySetInnerHTML={{ __html: `${feedback}` }}
                  className={classes.feedbackText}
                ></p>
              </Grid>
              <Grid
                container
                item
                justifyContent="center"
                alignItems="center"
                className={classes.buttonContainer}
              >
                <Grid
                  container
                  item
                  justifyContent="center"
                  alignItems="center"
                >
                  {feedBackImage ? (
                    <Button
                      id="BotonFeedback"
                      name="BotonFeedback"
                      onClick={nextStepFalse}
                      disabled={stopQuestion}
                      onDoubleClick={nextStepFalse}
                      className={classes.continueButton}
                    >
                      Continuar
                    </Button>
                  ) : remedialFinal ? (
                    <Button
                      id="BotonFeedback"
                      name="BotonFeedback"
                      onClick={nextStepFalse}
                      disabled={stopQuestion}
                      onDoubleClick={nextStepFalse}
                      className={classes.continueButton}
                    >
                      Continuar
                    </Button>
                  ) : (
                    <Button
                      id="BotonFeedback"
                      name="BotonFeedback"
                      onClick={nextStepFalse}
                      disabled={stopQuestion}
                      onDoubleClick={nextStepFalse}
                      className={classes.continueButton}
                    >
                      Continuar
                    </Button>
                  )}
                </Grid>
                {finished && (
                  <Redirect
                    to={{
                      pathname: `/retro/${remedialNumber}/${activityNumber}/1`,
                    }}
                  />
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default WrongFeedback;
