import React, { useContext,  useState } from "react";
import { Button, makeStyles, Grid } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import Maquina from "../../imgs/MaquinaCable.png";
import TermsAndConditions from "../TermsAndConditions/TermsAndConditions";

import AuthContext from "../../../auth/AuthContext";
import RegisterByCode from "./RegisterCode";

const useStyles = makeStyles((theme) => ({
  ContainerWelcomePage: {
    height: "100%",
  },
  ContainerImgTxt: {
    position: "relative",
    height: "100%",
    width: "97%",
    overflow: "auto",
    maxHeight: "100vh",
    [theme.breakpoints.down(1280)]: {
      width: "100vw",
      minWidth: "320px",
    },
  },
  Bienvenida: {
    padding: "84px 0 0 250px",
    flexWrap: "nowrap",
    flexDirection: "column",
    overflow: "auto",
    height: "auto",
    [theme.breakpoints.up("lg")]: {
      padding: "84px 0 0 140px",
      // height: "993px",
      flexWrap: "wrap",
    },
    background:
      "linear-gradient(32deg, rgba(185,101,211,0.2105042700674019) 1%, rgba(255,255,255,1) 20%, rgba(255,255,255,1) 36%, rgba(254,254,254,1) 77%, rgba(252,227,80,0.2777311608237045) 100%)",
    [theme.breakpoints.down(1571)]: {
      padding: "84px 0 0 140px",
    },
    [theme.breakpoints.down(1441)]: {
      padding: "84px 0 0 120px",
    },
    [theme.breakpoints.down(1401)]: {
      padding: "84px 0 0 100px",
    },
    [theme.breakpoints.down(1350)]: {
      padding: "84px 0 0 80px",
    },
    [theme.breakpoints.down(1280)]: {
      padding: "43px 0 0 0",
      alignItems: "center",
      // height: "1080px",
    },
  },

  Titulo: {
    width: "693px",
    height: " 73px",
    [theme.breakpoints.down(1280)]: {
      justifyContent: "center",
      height: " 111px",
      width: "100%",
    },
    "& .Titulo1": {
      font: "40px Fredoka One",
      color: "#F2624F",
      margin: "0 12px 0 0 ",
      [theme.breakpoints.down(768)]: {
        margin: "0",
        height: "36px",
      },
    },
    "& .Titulo2": {
      font: "40px Fredoka One",
      color: "#DC5EA6",
      margin: "0",
    },
    "& .Title": {
      margin: "0",
      justifyContent: "center",
    },
    "& .escritorio": {
      [theme.breakpoints.down(768)]: {
        display: "none",
      },
    },
    "& .tablet": {
      font: "36px Fredoka One",
      [theme.breakpoints.up(768)]: {
        display: "none",
        width: "100%",
      },
    },
    "& .Pregunta": {
      position: "relative",
      bottom: "10px",
    },
  },

  Texto: {
    width: "670px",
    minHeight: "85vh",

    // height: " 620px",
    [theme.breakpoints.down(1024)]: {
      justifyContent: "center",
      width: "100%",
      height: "auto",
      minHeight: "90vh",
    },
    [theme.breakpoints.up("xl")]: {
      minHeight: "90vh",
    },

    "& .Texto1": {
      font: "22px lato",
      color: "#505759",
      margin: "0px",
      paddingBottom: "20vh",
      [theme.breakpoints.down(768)]: {
        display: "none",
      },
    },
    "& .Texto2": {
      font: "18px lato",
      color: "#505759",
      margin: "0px",
      padding: "20px",
      paddingBottom: "20vh",
      hyphens: "manual",
      [theme.breakpoints.up(768)]: {
        display: "none",
      },
    },
  },

  BotomBar: {
    position: "fixed",
    bottom: "0px",
    background: "#F5D568",
    height: "8vh",
  },
  BarraDerecha: {
    background:
      "transparent linear-gradient(180deg, #D3348F 0%, #F6644B 100%) 0% 0% no-repeat padding-box",
    width: "3%",
    height: "100vh",
    [theme.breakpoints.down(1280)]: {
      display: "none",
    },
  },
  Img: {
    position: "absolute",
    left: "600px",
    top: "0px",
    height: "914px",
    [theme.breakpoints.down(1571)]: {
      left: "473px",
      top: "0px",
    },
    [theme.breakpoints.down(1441)]: {
      left: "453px",
      top: "0px",
    },
    [theme.breakpoints.down(1401)]: {
      left: "403px",
      top: "0px",
    },
    [theme.breakpoints.down(1350)]: {
      left: "323px",
      top: "0px",
    },
    [theme.breakpoints.down(1280)]: {
      display: "none",
    },
    "& .Maquina": {
      height: "776px",
      width: "914px",
    },
  },
  link: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    right: "10%",
    bottom: "50%",
    height: "100%",
    // width: "auto",
    minWidth: "238px",
    position: "absolute",
    textDecoration: "none",
    textTransform: "none",
    // height: "8vh",
    maxHeight: "62px",
    width: "100%",
    maxWidth: "238px",
    background: "#5825AD",
    boxShadow: "0px 3px 6px #00000029",
    borderRadius: "61px",
    color: "#FFFFFF",
    font: "bold 20px lato ",
    "&:hover": {
      background: "#dd78fb",
    },
    [theme.breakpoints.down("md")]: {
      minWidth: "200px",
      transform: "translate(50%)",
      right: "50%",
    },
    "& .BotonContinuar": {},
  },
}));

const Welcome_Page = () => {
  const classes = useStyles();
  const { user } = useContext(AuthContext);
  const history = useHistory();
  const [modalCodeOpen, setModalCodeOpen] = useState(false);

  const handleModalOpen = (activated = !modalCodeOpen) => {
    setModalCodeOpen(activated);
  };

  const validateCode = () => {
    if (user?.codigo?.codigo_alumno_generico) {
      setModalCodeOpen(true);
    } else {
      history.push("/avatars");
    }
  };

  return (
    <Grid
      container
      item
      sm={12}
      direction="column"
      className={classes.ContainerWelcomePage}
    >
      <Grid
        container
        item
        sm={12}
        direction="row"
        justifyContent="center"
        className={classes.ContainerImgTxt}
      >
        <Grid container item direction="column" className={classes.Bienvenida}>
          <Grid container item className={classes.Titulo}>
            <b className="Titulo1 escritorio">¡Hola!, </b>
            <b className="Titulo2 escritorio">¿cómo estás?</b>

            <Grid container item className="Title">
              <Grid container justifyContent="center" className="tablet">
                <b className="Titulo1 tablet">¡Hola!,</b>
              </Grid>
              <Grid
                container
                justifyContent="center"
                className="Pregunta tablet"
              >
                <b className="Titulo2 tablet">¿cómo estás?</b>
              </Grid>
            </Grid>
          </Grid>
          <Grid container item className={classes.Texto}>
            <p className="Texto1">
              Te damos la bienvenida a <b>Circuito de lectores,</b> una
              propuesta
              <br />
              innovadora en donde desarrollarás habilidades de comprensión
              <br />
              lectora de una manera muy atractiva y llena de retos. A través de
              <br />
              lecturas originales, aprenderás sobre diferentes temas que te{" "}
              <br />
              servirán en todas tus asignaturas, en la vida cotidiana y en el
              futuro.
              <br />
              Los contenidos abarcan desde literatura, pensamiento matemático,
              <br />
              ciencias humanas, ciencias naturales, arte y ética.
              <br />
              <br></br>
              Te invitamos a explorar la plataforma para que te familiarices
              <br />
              con los contenidos y las rutas de acceso, apóyate en el
              <br />
              Manual de usuario para conocer las diferentes secciones.
              <br />
              ¡Hay un personaje que te acompañará en tu recorrido y<br />
              tendrás tu propia Biblioteca virtual! Además, conforme avances en
              tu
              <br />
              circuito, podrás obtener puntos y recompensas.
              <br />
              <br></br>
              ​Estás a un paso de iniciar un viaje de aventuras, conocimiento y
              <br />
              autoconocimiento. Esperamos que tu experiencia en{" "}
              <b>
                Circuito de
                <br />
                lectores
              </b>{" "}
              sea placentera, entretenida y enriquecedora. Estamos
              <br />
              seguros de que cuando termines el recorrido serás una persona
              <br />
              diferente, rodeada de saberes nuevos.
              <br />
              <br></br>
              ¿Comenzamos una nueva aventura? ¡Adelante!
            </p>

            <p className="Texto2">
              Te damos la bienvenida a <b>Circuito de lectores,</b> una
              propuesta innovadora en donde desarrollarás habilidades de
              comprensión lectora de una manera muy atractiva y llena de retos.
              A través de lecturas originales, aprenderás sobre diferentes temas
              que te servirán en todas tus asignaturas, en la vida cotidiana y
              en el futuro. Los contenidos abarcan desde literatura, pensamiento
              matemático, ciencias humanas, ciencias naturales, arte y ética.
              <br></br>
              <br></br>
              Te invitamos a explorar la plataforma para que te familiarices con
              los contenidos y las rutas de acceso, apóyate en el Manual de
              usuario para conocer las diferentes secciones. ¡Hay un personaje
              que te acompañará en tu recorrido y tendrás tu propia Biblioteca
              virtual! Además, conforme avances en tu circuito, podrás obtener
              puntos y recompensas.
              <br></br>
              <br></br>
              ​Estás a un paso de iniciar un viaje de aventuras, conocimiento y
              autoconocimiento. Esperamos que tu experiencia en{" "}
              <b>Circuito de lectores</b> sea placentera, entretenida y
              enriquecedora. Estamos seguros de que cuando termines el recorrido
              serás una persona diferente, rodeada de saberes nuevos.
              <br></br>
              <br></br>
              ¿Comenzamos una nueva aventura? ¡Adelante!
            </p>
          </Grid>
          <Grid className={classes.Img}>
            <img className="Maquina" src={Maquina} alt="" />
          </Grid>
        </Grid>
      </Grid>
      <Grid container item sm={12} className={classes.BotomBar}>
        <Button className={classes.link} onClick={validateCode}>
          Continuar
        </Button>
      </Grid>
      <Grid container item sm={12} className={classes.BarraDerecha}></Grid>
      <TermsAndConditions />
      {user.acceptedConditions && user?.codigo?.codigo_alumno_generico && (
        <RegisterByCode
          handleModalOpen={handleModalOpen}
          modalCodeOpen={modalCodeOpen}
        />
      )}
    </Grid>
  );
};

export default Welcome_Page;
