import { Button, Grid, makeStyles } from "@material-ui/core";
import React, { useContext } from "react";
import DrawerContext from "../../../../../usability/DrawerContext";
import Drawer from "../../ReadingTools/Drawer";

const useStyles = makeStyles((theme) => ({
  Contenedor: {
    background: "#E9FBF9",
    width: "100vw",
    [theme.breakpoints.down(1161)]: {
      background: "none",
    },
    [theme.breakpoints.down(600)]: {
      marginTop: "79.51px",
    },
  },
  ContainerInfoLectura: {
    position: "relative",
    height: "86px",
    [theme.breakpoints.down(502)]:{
        height: "43px",
    },
    background: "#76D4C3",
    "& .BarraInfoLectura": {
      height: "86px",
      background: `Url(https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Barras/Azul.png)`,
        [theme.breakpoints.down(502)]:{
            height: "43px",
        },
    },
    "& .InfoLectura": {
      position: "absolute",
      height: "86px",
      width: "219px",
      boxSizing: "border-box",
      padding: "17.2px 0",
      background: "#FAFAFA",
      borderRadius: "50px",
      boxShadow: "2px 2px 2px 2px #00000029",
      zIndex: "4",
        [theme.breakpoints.down(502)]:{
            height: "43px",
            width: "109px",
            padding: "8.6px 0",
            borderRadius: "25px",
        },
    },
    "& .InfoLectura:before": {
      position: "absolute",
      display: "flex",
      boxSizing: "border-box",
      padding: "0 0 0 10px",
      justifyContent: "center",
      alignItems: "center",
      height: "39.88px",
      width: "39.88px",
      borderRadius: "50%",
      content: '""',
      boxShadow: "3px 0px .5px 0px #00000029",
      background: "#FAFAFA",
      left: "90px",
      top: "65px",
      transform: "rotate(0.25turn)",
      [theme.breakpoints.down(502)]:{
        left: "45px",
        top: "30px",
        height: "20.88px",
        width: "20.88px",
        },
    },
    "& .InfoLectura:after": {
      content: '""',
      height: "15px",
      width: "15px",
      borderTop: "2px solid #20A698",
      borderLeft: "2px solid #20A698",
      position: "absolute",
      bottom: "-10px",
      left: "Calc(50% - 8px)",
      transform: "rotate(0.625turn)",
      [theme.breakpoints.down(502)]:{
        height: "10px",
        width: "10px",
        left: "Calc(50% - 4.5px)",
        bottom: "-3px",
        borderTop: "1px solid #20A698",
        borderLeft: "1px solid #20A698",
        },
    },
    "& .Sesion": {
      borderLeft: "2px solid #D8D8D8",
    },
    "& .Dato": {
      margin: "0 0 5px 0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#20A698",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        margin: "0 0 2.5px 0",
        },
    },
    "& .Caracter": {
      margin: "0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#000000",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        },
    },
       '& .Regresar':{
        color: '#F2624F',
        border: '#F2624F 1px solid',
        height: '30px',
        width:'100px',
        borderRadius: '20px',
        letterSpacing: '0.14px',
        textTransform: 'none',
        background:'#FEFAF1',
        top:'calc(50% - 15px)',
        left:'5%',
        zIndex:4,
        [theme.breakpoints.down(502)]: {
          font:'bold 10px lato',
          width: "60px",
        },
      },
  },
  ContenedorLectura: {
    background: "#fff",
    width: "1160px",
    height: "100%",
    padding: "60px 168px 35px ",
    position: "relative",
    
    transform: (props) =>
      props.scale > 1 ? `scale(${props.scale}) translate(0px, 10%)` : `none`,
    [theme.breakpoints.down(1161)]: {
      width: "502px",
      padding: "60px 0 0 0",
    },
    [theme.breakpoints.down(502)]: {
      width: "350px",
      padding: "60px 0 0 0",
    },
    [theme.breakpoints.down(350)]: {
      width: "300px",
      padding: "30px 0 0 0",
    },
    "& .ContainerDerechos": {
      bottom: "80px",
      [theme.breakpoints.down(1161)]: {
        display: "none",
      },
    },
    "& .Derechos": {
      textAlign: "center",
      margin: "0px",
      font: " 15px lato",
      color: "#717476",
    },
    "& .Escritorio": {
      display: "flex",
      [theme.breakpoints.down(1161)]: {
        display: "none",
      },
    },
    "& .Tablet": {
      display: "none",
      [theme.breakpoints.down(1161)]: {
        display: "flex",
      },
    },
  },
  ContainerTitulo: {
    margin: "0 0 34px 0",
    [theme.breakpoints.down(1161)]: {
      margin: "0 0 34px 0",
    },
    "& .Titulo": {
      font: "48px fredoka one",
      letterSpacing: "0px",
      lineHeight: "58px",
      color: "#CB3668",
      margin: "0",
      textAlign: "center",
      [theme.breakpoints.down(1161)]: {
        font: "43px fredoka one",
        lineHeight: "52px",
      },
      [theme.breakpoints.down(502)]: {
        font: "30px fredoka one",
        lineHeight: "32px",
      },
    },
  },
  Parrafo1: {
    margin: " 0 0 36px 0",
    position: "relative",
    "& .Lectura": {
      lineHeight: "30px",
      margin: "0 ",
      font: "22px lato",
      color: "#000",
      letterSpacing: "0.20px",
      [theme.breakpoints.down(1161)]: {
        font: "18px lato",
        lineHeight: "28px",
        letterSpacing: "0.17px",
      },
      [theme.breakpoints.down(502)]: {
        font: "12px lato",
        lineHeight :'18px',
        letterSpacing: '0.09px',
      },
    },
    "& .Esp": {
      margin: "0 0 36px 0",
    },
    "& .Esp1": {
      margin: "35px 0 0 0",
      [theme.breakpoints.down(1161)]: {
        margin: " 0",
      },
    },
    "& .Pos": {
      position: "relative",
      top: "-135px",
    },
    "& .Esp2": {
      margin: "36px 0 0 0",
    },
    "& .Imagen": {
      [theme.breakpoints.down(1161)]: {
        width: "87.3%",
      },
    },
  },
}));

const Flix_el_elefante = (props) => {

  const {activeStep, setActiveStep, handlePreviewStep, id_actividad} = props
  
  const handleContinue = () => {
    setActiveStep(activeStep - 1)
    handlePreviewStep()
  }
    const {
      handleSize,
      disabledZoom,
      closeZoom,
      scale,
    } = useContext(DrawerContext);
    const detectSize = () => {
      const lectura = document.getElementById("lectura");

      const newWidth = lectura?.offsetWidth;
      const newHeight = lectura?.offsetHeight;
      if (lectura) handleSize(newHeight, newWidth);
    };
    React.useEffect(() => {
      window.addEventListener("resize", detectSize);
      return () => {
        window.removeEventListener("resize", detectSize);
        closeZoom();
      };
    }, []);

    const classes = useStyles({ disabledZoom, scale });
  return (
    <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
      <Grid container item justifyContent="center" className={classes.ContainerInfoLectura}>
        <Grid container className="BarraInfoLectura">
            <Button className="Regresar" onClick={handleContinue}>
                Regresar
            </Button>
        </Grid>
        <Grid container item className="InfoLectura">
          <Grid container item direction="column" xs={6} justifyContent="center">
            <p className="Dato">Grado</p>
            <p className="Caracter">1</p>
          </Grid>
          <Grid container item direction="column" xs={6} justifyContent="center" className="Sesion">
            <p className="Dato">Sesión</p>
            <p className="Caracter">6</p>
          </Grid>
        </Grid>
      </Grid>

      <Grid
        container
        item
        className={classes.ContenedorLectura}
        justifyContent="center"
        id="lectura"
        onLoad={detectSize}
      >
        <Drawer id_lectura={id_actividad} withoutTransform ={true}/>
        <Grid container item xs={12} className={classes.ContainerTitulo} justifyContent="center">
          <h2 className="Titulo">Flix, el elefante</h2>
        </Grid>
        <Grid container item xs={12} className={classes.Parrafo1} justifyContent="center">
          <Grid container xs={5} className="Escritorio">
            <p className="Lectura">
              A Mateo, un niño de siete años,
              <br />
              le encantaba leer{" "}
              <em>
                Las aventuras
                <br />
                de Flix
              </em>
              . Flix era un elefante que
              <br />
              vivía en Tailandia. Le encantaba
              <br />
              correr, tomar baños de lodo y<br />
              mojar a otros animales con su <br />
              trompa.
              <br />
              <br />
              Un día, ¡Flix se salió de las
              <br />
              páginas del cuento! Mateo no
              <br />
              podía creerlo. Se quedó
              <br />
              boquiabierto, ¡hasta que Flix
              <br />
              mojó con su trompa la
              <br />
              habitación y a Mateo y a su
              <br />
              mamá! Después, Flix corrió y<br />
              rompió tres adornos.
              <br />
              <br />
              <br />
              —¡Mateeooooo! —gritó mamá.
            </p>
          </Grid>
          <Grid container xs={7} justifyContent="center" className="Escritorio">
            <img
              className="Imagen"
              src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img1ro/Sesion6/1.png"
              alt="Tarjeta"
            />
          </Grid>

          <Grid container xs={12} className="Tablet">
            <p className="Lectura Esp">
              A Mateo, un niño de siete años, le encantaba leer <em>Las aventuras de Flix</em>. Flix era un elefante que vivía en
              Tailandia. Le encantaba correr, tomar baños de lodo y mojar a otros animales con su trompa.
            </p>
          </Grid>

          <Grid container xs={12} justifyContent="center" className="Tablet">
            <img
              className="Imagen"
              src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img1ro/Sesion6/1.png"
              alt="Tarjeta"
            />
          </Grid>

          <Grid container xs={12} className="Tablet">
            <p className="Lectura Esp2">
              Un día, ¡Flix se salió de las páginas del cuento! Mateo no podía creerlo. Se quedó boquiabierto, ¡hasta que Flix
              mojó con su trompa la habitación y a Mateo y a su mamá! Después, Flix corrió y rompió tres adornos.
              <br />
              <br />
              —¡Mateeooooo! —gritó mamá.
              <br />
              <br />
              Flix volteó a ver a Mateo y lo ayudó a secar y a ordenar.
              <br />
              <br />
              Los días siguientes, el nuevo amigo de Mateo se portó mejor. Jugaban juntos y luego recogían los juguetes. Comían
              nueces y maníes. Después, recogían las cáscaras y salían a pasear tomados de la mano de mamá.
            </p>
          </Grid>
        </Grid>

        <Grid container item xs={12} className={classes.Parrafo1} justifyContent="center">
          <Grid container xs={6} justifyContent="center" className="Escritorio">
            <img
              className="Imagen"
              src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img1ro/Sesion6/2.png"
              alt="Tarjeta"
            />
          </Grid>
          <Grid container xs={6} className="Escritorio" justifyContent="flex-end">
            <p className="Lectura Esp1">
              Flix volteó a ver a Mateo y lo ayudó a<br />
              secar y a ordenar.
              <br />
              <br />
              Los días siguientes, el nuevo amigo <br />
              de Mateo se portó mejor. Jugaban <br />
              juntos y luego recogían los juguetes. <br />
              Comían nueces y maníes. Después, <br />
              recogían las cáscaras y salían a <br />
              pasear tomados de la mano de <br />
              mamá.
              <br />
              <br />
              A Mateo le gustaba rascarle las <br />
              orejas a Flix y darle helado. Leían <br />
              cuentos y dormían juntos, pero Flix <br />
              estaba triste.
            </p>
          </Grid>

          <Grid container xs={12} justifyContent="center" className="Tablet">
            <img
              className="Imagen"
              src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img1ro/Sesion6/2.png"
              alt="Tarjeta"
            />
          </Grid>

          <Grid container xs={12} className="Tablet">
            <p className="Lectura Esp1">
              A Mateo le gustaba rascarle las orejas a Flix y darle helado. Leían cuentos y dormían juntos, pero Flix estaba
              triste.
              <br />
              <br />
              —Mateo —dijo mamá—, Flix extraña a su familia. Debe regresar a casa.
              <br />
              <br />
              Mateo se entristeció, pero entendió que Flix pertenecía a Tailandia. Flix abrazó a Mateo y se metió de nuevo al
              libro.
              <br />
              <br />
              Mateo siguió leyendo las historias de Flix. Algunas veces, el elefante salía del libro para visitar a Mateo.
            </p>
          </Grid>
        </Grid>

        <Grid container item xs={12} className={classes.Parrafo1} justifyContent="center">
          <Grid container xs={6} className="Escritorio" justifyContent="flex-end">
            <p className="Lectura Esp1">
              —Mateo —dijo mamá—, Flix extraña a su <br />
              familia. Debe regresar a casa.
              <br />
              <br />
              Mateo se entristeció, pero entendió que <br />
              Flix pertenecía a Tailandia. Flix abrazó a <br />
              Mateo y se metió de nuevo al libro.
              <br />
              <br />
              Mateo siguió leyendo las historias de <br />
              Flix. Algunas veces, el elefante salía del <br />
              libro para visitar a Mateo.
            </p>
          </Grid>
          <Grid container xs={6} justifyContent="center" className="Escritorio">
            <img
              className="Imagen Pos"
              src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img1ro/Sesion6/3.png"
              alt="Tarjeta"
            />
          </Grid>
          <Grid container xs={12} justifyContent="center" className="Tablet   ">
            <img
              className="Imagen"
              src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img1ro/Sesion6/3.png"
              alt="Tarjeta"
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid container item style={{ marginTop: 80 }}></Grid>
    </Grid>
  );
};

export default Flix_el_elefante;
