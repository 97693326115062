import { Button, Grid, makeStyles } from "@material-ui/core";
import React, { useContext, useEffect } from "react";
import DrawerContext from "../../../../../usability/DrawerContext";
import { useOnLoadImages } from "../../../../hooks/useOnLoadImages";
import Loader from "../../../../Loader/Loader";
import Drawer from "../../ReadingTools/Drawer";

const useStyles =  makeStyles (theme =>({
     Contenedor:{
        background:'#E9FBF9',
        width:'100vw',
        overflowX:'clip',
        [theme.breakpoints.down(1161)]:{
            background:'none',
           overflowX:'unset',
        },
        [theme.breakpoints.down(600)]:{
            marginTop: '79.51px',
        }
    },
  ContainerInfoLectura: {
    position: "relative",
    height: "86px",
    [theme.breakpoints.down(502)]:{
        height: "43px",
    },
    background: "#76D4C3",
    "& .BarraInfoLectura": {
      height: "86px",
      background: `Url(https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Barras/Azul.png)`,
        [theme.breakpoints.down(502)]:{
            height: "43px",
        },
    },
    "& .InfoLectura": {
      position: "absolute",
      height: "86px",
      width: "219px",
      boxSizing: "border-box",
      padding: "17.2px 0",
      background: "#FAFAFA",
      borderRadius: "50px",
      boxShadow: "2px 2px 2px 2px #00000029",
      zIndex: "4",
        [theme.breakpoints.down(502)]:{
            height: "43px",
            width: "109px",
            padding: "8.6px 0",
            borderRadius: "25px",
        },
    },
    "& .InfoLectura:before": {
      position: "absolute",
      display: "flex",
      boxSizing: "border-box",
      padding: "0 0 0 10px",
      justifyContent: "center",
      alignItems: "center",
      height: "39.88px",
      width: "39.88px",
      borderRadius: "50%",
      content: '""',
      boxShadow: "3px 0px .5px 0px #00000029",
      background: "#FAFAFA",
      left: "90px",
      top: "65px",
      transform: "rotate(0.25turn)",
      [theme.breakpoints.down(502)]:{
        left: "45px",
        top: "30px",
        height: "20.88px",
        width: "20.88px",
        },
    },
    "& .InfoLectura:after": {
      content: '""',
      height: "15px",
      width: "15px",
      borderTop: "2px solid #20A698",
      borderLeft: "2px solid #20A698",
      position: "absolute",
      bottom: "-10px",
      left: "Calc(50% - 8px)",
      transform: "rotate(0.625turn)",
      [theme.breakpoints.down(502)]:{
        height: "10px",
        width: "10px",
        left: "Calc(50% - 4.5px)",
        bottom: "-3px",
        borderTop: "1px solid #20A698",
        borderLeft: "1px solid #20A698",
        },
    },
    "& .Sesion": {
      borderLeft: "2px solid #D8D8D8",
    },
    "& .Dato": {
      margin: "0 0 5px 0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#20A698",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        margin: "0 0 2.5px 0",
        },
    },
    "& .Caracter": {
      margin: "0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#000000",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        },
    },
       '& .Regresar':{
        color: '#F2624F',
        border: '#F2624F 1px solid',
        height: '30px',
        width:'100px',
        borderRadius: '20px',
        letterSpacing: '0.14px',
        textTransform: 'none',
        background:'#FEFAF1',
        top:'calc(50% - 15px)',
        left:'5%',
        zIndex:4,
        [theme.breakpoints.down(502)]: {
          font:'bold 10px lato',
          width: "60px",
        },
      },
  },
    ContenedorLectura:{
        background:'#fff',
        width:'1160px',
        height:'100%',
                padding:'60px 168px 35px ',
        
        transform: (props) =>
        props.scale > 1 ? `scale(${props.scale}) translate(0px, 10%)` : `none`,
        [theme.breakpoints.down(1161)]:{
            width:'502px',
            padding:'60px 0 0 0',
          },
        [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },

        '& .Escritorio':{
            display:'block',
            [theme.breakpoints.down(1161)]:{
                display:'none'
            },
        },
        '& .Tablet':{
            display:'none',
            [theme.breakpoints.down(1161)]:{
                display:'block'
            },
        }
    },
    ContainerTitulo:{
        margin:'0 0 34px 0',
        [theme.breakpoints.down(1161)]:{
            margin:'0 0 34px 0',
        },
        '& .Titulo':{
            font:'48px fredoka one',
            letterSpacing: '0px',
            lineHeight:'58px',
            color: '#823CE2',
            margin:'0',
            textAlign:'center',
            [theme.breakpoints.down(1161)]:{
                font:'43px fredoka one',
                lineHeight:'52px',
            },
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            lineHeight : '30.5px',
            margin:'0 0 36px 0',
            font:'22px lato',
            color:'#000',
            letterSpacing: '0.20px',
            [theme.breakpoints.down(1161)]:{
                font:'18px lato',
                lineHeight :'28px',
                letterSpacing: '0.17px',
            },
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .SubTitulo':{
            font:'bold 30px lato',
            color:'#E97BA8',
            letterSpacing:'.28px',
            lineHeight:'30px',
            margin:'0',
            [theme.breakpoints.down(1161)]:{
                lineHeight :'28px',
                font:'bold 26px lato',
                letterSpacing: '0.24px',
            },
            [theme.breakpoints.down(502)]:{
                font:'bold 18px lato',
                lineHeight :'24px',
                letterSpacing: '0.21px',
            },
        },
        '& .SubTitulo2':{
            font:'black 30px lato',
            color:'#0F6DED',
            letterSpacing:'.28px',
            lineHeight:'30px',
            margin:'0',
            [theme.breakpoints.down(1161)]:{
                lineHeight :'28px',
                font:'black 26px lato',
                letterSpacing: '0.24px',
            },
            [theme.breakpoints.down(502)]:{
                font:'black 22px lato',
                lineHeight :'24px',
                letterSpacing: '0.21px',
            },
        },
        '& .SubTitulo3':{
            font:'bold 30px lato',
            color:'#0F6DED',
            letterSpacing:'.28px',
            lineHeight:'30px',
            margin:'0',
            [theme.breakpoints.down(1161)]:{
                lineHeight :'28px',
                font:'bold 26px lato',
                letterSpacing: '0.24px',
            },
            [theme.breakpoints.down(502)]:{
                font:'bold 18px lato',
                lineHeight :'24px',
                letterSpacing: '0.21px',
            },
        },
        '& .TextImg':{
            font:'20px lato',
            color:'#717476',
            letterSpacing:'-.10px',
            lineHeight:'30px',
            margin:'24px 0 0 0',
            [theme.breakpoints.down(1161)]:{
                font:' 16px lato',
                lineHeight :'25px',
                letterSpacing: '0.15px',
            },
            [theme.breakpoints.down(502)]:{
                font:' 11px lato',
                lineHeight :'14px',
                letterSpacing: '0.10px',
            },
        },
        '& .Esp':{
            boxSizing:'border-box',
            padding:'0 27px 0 0',
            [theme.breakpoints.down(1281)]:{
                padding:'0 '
            },
        },
        '& .Esp2':{
            boxSizing:'border-box',
            padding:'0 50px 0 0',
            [theme.breakpoints.down(1281)]:{
                padding:'0 '
            },
        },
        '& .Esp3':{
            textAlign:'center',
            [theme.breakpoints.down(1281)]:{
                textAlign:'left',

            },
        },
        '& .Lectura2':{
            margin:'0 ',
        },
        '& .Imagen':{
            [theme.breakpoints.down(1161)]:{
                width:'100%',
            },
        },  
        '& .Imagen1':{
            [theme.breakpoints.down(1161)]:{
                margin:'36px 0 18px 0',
                width:'60.75%',
            },
        },
      
    }
}));

const Arte_en_el_cuerpo_cuerpo_en_el_arte = (props) => {

  const {activeStep, setActiveStep, handlePreviewStep, id_actividad} = props
  
  const handleContinue = () => {
    setActiveStep(activeStep - 1)
    handlePreviewStep()
  }

    const {
      handleSize,
    disabledZoom,
    closeZoom,
    handleZoom,
    scale
  }= useContext(DrawerContext);
  const [loading, setLoading] = React.useState(true);
  const wrapperRef = React.useRef(null);
  const imagesLoaded = useOnLoadImages(wrapperRef);

  const detectSize = () => {
    const lectura = document.getElementById("lectura");
    const newWidth = lectura?.offsetWidth;
    const newHeight = lectura?.offsetHeight;
    setLoading(false);
    if (lectura) handleSize(newHeight, newWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", detectSize);
    return () => {
      handleZoom()
      window.removeEventListener("resize", detectSize);
      closeZoom()
    };
  }, []);

  const classes = useStyles({ disabledZoom,scale });
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
             <Grid container item justifyContent="center" className={classes.ContainerInfoLectura}>
                       <Grid container className="BarraInfoLectura">
            <Button className="Regresar" onClick={handleContinue}>
                Regresar
            </Button>
        </Grid>
               <Grid container item className="InfoLectura">
                    <Grid container item direction="column" xs={6} justifyContent="center">
                        <p className="Dato">Grado</p>
                        <p className="Caracter">5</p>
                    </Grid>
                    <Grid container item direction="column" xs={6} justifyContent="center" className="Sesion">
                        <p className="Dato">Sesión</p>
                        <p className="Caracter">56</p>
                    </Grid>
               </Grid>
            </Grid>
            {!imagesLoaded && <Loader />}
        <Grid
            container
            item
            className={classes.ContenedorLectura}
            justifyContent="center"
            id="lectura"
            onLoad={detectSize}
            ref={wrapperRef}
        >
<Drawer id_lectura={id_actividad} />
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                        <h2 className="Titulo">Arte en el cuerpo, cuerpo en el arte</h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                       <p className='Lectura Lectura2'>
                        ¿Qué se necesita para crear arte? Quizá pienses que se necesita tener talento o 
                        estudiar ciertas técnicas. Probablemente tengas razón, pero hay algo que viene 
                        antes: para crear, toda persona necesita su propio cuerpo.<br/><br/> 
                        
                        El pintor necesita moverse para trazar sobre una superficie. El escultor, para moldear 
                        los materiales en los que va diseñando una figura. Muchas veces el espectador ignora 
                        al cuerpo en disciplinas como éstas, porque la obra terminada no muestra el trabajo 
                        físico del artista como tal. <br/><br/>
                        
                        Por otro lado, hay artes donde el cuerpo es, a la vez, el creador y parte indispensable 
                        de la obra como producto. Las disciplinas que se muestran a continuación no son las 
                        únicas en las que pasa esto, pero sí algunas de las más conocidas. 
                       </p>                   
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container item md={12} lg={7}  >
                        <p className='Lectura Lectura2 Esp'>
                            <h3 className='SubTitulo'><span className='SubTitulo2'>Teatro:</span> el cuerpo actor</h3><br/>

                            El teatro y la dramaturgia no son exactamente 
                            lo mismo. El teatro parte de la obra que 
                            escribe un dramaturgo, pero necesita del 
                            talento de los actores y actrices. Los 
                            personajes se encarnan en el cuerpo de estos 
                            artistas, que saben emular situaciones e ideas 
                            por medio de tonos de voz y gestos. <br/><br/>
                            
                            Quizá el autor concibió la emoción que genera 
                            la obra. Sin duda la creatividad del director 
                            estuvo muy involucrada en el proceso 
                            creativo. Sin embargo, el actor ensaya y 
                            trabaja para volver reales las ideas del autor y 
                            del director. Representa la apariencia y las 
                            sensaciones del personaje y debe, además, 
                            conectar con el público y envolverlo en la 
                            historia.
                        </p>
                    </Grid>
                    <Grid container item md={12} lg={5} justifyContent='center'>
                        <Grid container justifyContent='center'>
                            <img className="Imagen1" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion56/1.png" alt="Tarjeta" />
                        </Grid>
                        <Grid container justifyContent='center'>
                            <p className='TextImg Lectura2'>En 1899 Sara Bernhart se convirtió en la primera mujer en interpretar el personaje de Hamlet frente a la cámara.</p>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container item md={12} lg={7}  >
                        <p className='Lectura Lectura2 Esp2'>
                            <h3 className='SubTitulo'><span className='SubTitulo2'>Música:</span> el cuerpo intérprete</h3><br/>

                            Con la música pasa algo semejante. Este 
                            arte también tiene autores y se transmite 
                            por escrito, en partituras. Pero los 
                            instrumentos necesitan un cuerpo humano 
                            que interactúe con ellos: un <em>intérprete</em> que 
                            ejecute las notas.<br/><br/> 
                            
                            A veces el intérprete compone en el 
                            momento mismo en que toca. A esto se le 
                            llama <em>improvisación</em> y sucede en algunos 
                            géneros musicales, como el jazz, el son 
                            jarocho (mexicano) y la payada (argentina, 
                            uruguaya y paraguaya). También es común 
                            en ciertas tradiciones poéticas. Algunos 
                            describen la improvisación como una 
                            especie de trance en el que el cuerpo 
                            empieza a crear por sí sólo.
                        </p>
                    </Grid>
                    <Grid container item  md={12} lg={5} justifyContent='center'>
                        <Grid container justifyContent='center'>
                            <img className="Imagen1" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion56/2.png" alt="Tarjeta" />
                        </Grid>
                        <Grid container item justifyContent='center'>
                            <p className='TextImg Lectura2 Esp3'>Charlie Parker destacó en la improvisación de jazz.</p>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container >
                        <p className='Lectura'>
                            <h3 className='SubTitulo'><span className='SubTitulo2'>Danza:</span> el arte del cuerpo</h3><br/>
                             Desde tiempos muy antiguos los movimientos del cuerpo adquirieron un significado 
                             especial. Se volvieron una forma de comunicación: en muchas civilizaciones la danza 
                             comenzó como una forma en la que los pueblos se relacionaban con sus dioses.
                        </p>
                    </Grid>
                    <Grid container item justifyContent='center'>
                        <Grid container justifyContent='center'>
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion56/3.png" alt="Tarjeta" />
                        </Grid>
                        <Grid container item justifyContent='center'>
                            <p className='TextImg '>
                                Durante mucho tiempo algunas danzas indias (como el odissi y el bharatanatyam) 
                                fueron formas rituales de comunicarse con la divinidad..
                            </p>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container >
                        <p className='Lectura'>
                            La danza se ha transformado a lo largo de la historia. Aunque en algunos lugares
                            todavía es un fenómeno religioso, en gran parte del mundo se entiende como un 
                            placer. Esto ocurre con el <em>ballet</em>, la danza contemporánea y las danzas tradicionales
                            de muchas comunidades.
                        </p>
                    </Grid>
                    <Grid container item justifyContent='center'>
                        <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion56/4.png" alt="Tarjeta" />
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container >
                        <p className='Lectura'>
                            En estos géneros los bailarines deben demostrar su dominio de la técnica para 
                            transmitir con sus cuerpos las emociones y situaciones que recrean con cada
                            movimiento.<br/><br/> 
                             
                            <h3 className='SubTitulo3'>Cuerpo de muchas artes</h3><br/>
                        
                            Ciertas obras y proyectos exigen que el actor domine varias artes a la vez. Esto pasa 
                            en el teatro musical, donde un actor también canta y baila para proyectar toda la 
                            emoción que requiere la historia.<br/><br/> 
                            
                            Como puedes ver, el principal instrumento del arte lo compartimos todos.
                        </p>
                    </Grid>
                </Grid>
        </Grid>
        <Grid container item style={{ marginTop: 80}} ></Grid>
    </Grid>
    );
}
 
export default Arte_en_el_cuerpo_cuerpo_en_el_arte;
