import React               from "react";
import {Grid, makeStyles}                 from '@material-ui/core';

const useStyles = makeStyles(theme =>({
  questionContainer:{
    width: "100%",
    height:"100%",
  },
  reading: {
    width: "98%",
    font: "22px lato",
    letterSpacing:'0',
    margin:"14px 10px 14px 0",
    [theme.breakpoints.down(1370)]:{
      font: "500 18px lato",
      width: "90%",
      margin:"10px 10px 10px 0"
    },
    [theme.breakpoints.down(1310)]:{
      font: "500 16px lato",
      // width: "80%",
      margin:"10px 5px 10px 0"
    },
    [theme.breakpoints.down(925)]:{
      font: "500 14px lato",
      // width: "70%",
      margin:"6px 0 6px 0"
    },
    [theme.breakpoints.down(426)]:{
      font: "500 12px lato",
      // width: "70%",
      margin:"6px 0 6px 5%"
    },
  },
  imgStyle: {
    alignItems: "center",
    width:'80%',
    height:'80%',
    [theme.breakpoints.down(1400)]:{
      width:'85%px',
      height:'85%px',
    },
  },
  table: {
    // border: '1px solid black',
    width: "100%",
    font: '22px lato',
    [theme.breakpoints.down(1400)]:{
      font: " 18px lato",
    },
  },
  tdTable: {
    padding: "0 1%",
    // border: '1px solid black',
  }
}));

const Question = (props) => {
  const classes = useStyles()
  const { picture } = props


  return(
    <Grid container className={classes.questionContainer}>
      <p 
        // dangerouslySetInnerHTML={{__html: `${step+1}. ${pregunta.replace( '\n', '<br/><br/>')}`}}
        className={classes.reading}
      >
        6. A partir de lo que aprendiste en la lectura, ¿qué emoción crees que sentía el pintor al hacer 
        este cuadro?
      </p>
      <br/>
      <img alt="img" src={picture} className={classes.imgStyle}/>
    </Grid>
  )

}

export default Question;
