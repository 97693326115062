import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
    ContenedorLectura:{
        width:'502px',
                  [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
    },
    ContainerTitulo:{
        margin:'0 0 36px 0',
        '& .Titulo':{
            letterSpacing: '0px',
            color: '#E60039',
            margin:'0',
            textAlign:'center',
            font:'43px fredoka one',
            lineHeight:'52px',
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            margin:'0 0 36px 0 ',
            color:'#000',
            font:'18px lato',
            lineHeight :'28px',
            letterSpacing: '0.17px',
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .TextImg':{
            margin:'22px 0 0 0',
            alignSelf:'flex-start',
            color:'#717476',
            font:' 16px lato',
            lineHeight :'25px',
            letterSpacing: '0.15px',
            [theme.breakpoints.down(502)]:{
                font:' 11px lato',
                lineHeight :'14px',
                letterSpacing: '0.10px',
            },
        },
        '& .Imagen':{
            width:'100%',
        },
    },
    

}));

const El_niño_que_se_comió_el_mundo = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >
                        El niño que se<br/> comió el mundo
                    </h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                            David tenía hambre, tanta que un relámpago estalló en su panza y el estruendo 
                            estremeció todo su cuarto. Aunque ya había desayunado, quería seguir comiendo, 
                            pues su hambre era atroz.<br/><br/> 
                            
                            “¿A qué sabrá la cama?”, se preguntó. Luego comenzó a masticar la sábana y la cobija, 
                            y a comerse las patas y la cabecera de madera.
                        </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <Grid container >
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img2do/Sesion23/1.png" alt="Tarjeta" />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                            Aunque sintió un ligero alivio después de ese banquete, todavía tenía hambre, así 
                            que se comió el espejo. Se asustó al ver su reflejo, pues le dio la impresión de que se 
                            devoraba a sí mismo. Siguió con el armario, el juguetero y (con un poco de tristeza) 
                            sus juguetes.<br/><br/> 
                            
                            Su madre entró al cuarto y David le dijo:<br/><br/> 
                            
                            —Mamá, tengo hambre.<br/><br/> 
                            
                            Había crecido, ahora medía el doble de su tamaño original. Su madre se desmayó. Él 
                            salió del cuarto. Mientras avanzaba, comía todo a su paso: la alfombra, los muñecos 
                            de porcelana, la vitrina, las ventanas…
                        </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <Grid container >
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img2do/Sesion23/2.png" alt="Tarjeta" />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                            Llegó a la cocina. Cualquiera pensaría que se comería únicamente los alimentos, pero 
                            se echó a la boca el refrigerador completo, junto con las alacenas y la estufa. Había 
                            crecido demasiado. Ya no cabía por la puerta de su casa, así que también la incluyó en 
                            el menú.<br/><br/> 
                            
                            La gente se asustó al verlo gigante. David se comió la calle, los árboles, los carros… 
                            Terminó devorando la ciudad entera. Luego se comió la región, y después, el país. 
                            Tenía tanta hambre que engulló todo el planeta.<br/><br/> 
                            
                            Cuando ya no hubo nada (ni árboles, ni pasto, ni mares, ni mundo), David se quedó
                            flotando en el espacio.
                        </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <Grid container >
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img2do/Sesion23/3.png"alt="Tarjeta" />
                        </Grid>
                    </Grid>
                </Grid>

                
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                            Entonces se dio cuenta de que estaba solo. Eso lo puso tan triste que se echó a llorar.<br/><br/> 
                            
                            Su llanto giró por el universo y formó mares. De sus ojos cayeron peces. Con el mar 
                            regresó toda forma de vida que David había devorado.
                        </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <Grid container >
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img2do/Sesion23/4.png" alt="Tarjeta" />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                            Los océanos crearon de nuevo un planeta azul y, mientras lloraba cada vez más por su 
                            soledad, David adelgazó hasta quedar pequeño como al principio. En medio de un 
                            suspiro descubrió las nubes, las casas, los perros, la gente y (mejor aún) a su mamá. 
                            Ella lo abrazó y le dijo:<br/><br/> 
                            
                            —No te preocupes, cariño. Ahora mismo te hago un sándwich.
                        </p>
                    </Grid>
                </Grid>
        </Grid>
    </Grid>
    );
}
 
export default El_niño_que_se_comió_el_mundo;
