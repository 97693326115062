import React from "react";
import { Grid, makeStyles } from "@material-ui/core";
import ResourceCard from "./ResourceCard";

import MallaCompetencias from "./pdf_list/CDL_Malla_de_competencias.pdf";
import perfil from "./pdf_list/perfil.pdf";
import SugerenciasG1 from "./pdf_list/CL1_Sugerenicas_didacticas_01_TE_HA.pdf";
import SugerenciasG2 from "./pdf_list/CL2_Sugerenicas_didacticas_01_TE_HA.pdf";
import SugerenciasG3 from "./pdf_list/CL3_Sugerenicas_didacticas_01_TE_HA.pdf";
import SugerenciasG4 from "./pdf_list/CL4_Sugerenicas_didacticas_01_TE_HA.pdf";
import SugerenciasG5 from "./pdf_list/CL5_Sugerenicas_didacticas_01_TE_HA.pdf";
import SugerenciasG6 from "./pdf_list/CL6_Sugerenicas_didacticas_01_TE_HA.pdf";
import UsabilityContext from "../../../../usability/UsabilityContext";
import { useContext } from "react";

// import requerimientos from "./pdf_list/request.docx";

const useStyles = makeStyles((theme) => ({
  ContainerBiblioteca: {
    height: "100%",
    background: "#E9FBF9",
    [theme.breakpoints.up(1921)]: {
      height: "100vh",
    },
  },
  TopBar: {
    height: "172px",
    background:
      "transparent linear-gradient(103deg, #4EA398 0%, #7848DA 100%) 0% 0% no-repeat padding-box;",
  },
  ContainerLecturas: {
    position: "absolute",
    left: "0",
    textAlign: "center",
    width: "100%",
    minHeight: "80vh",
    padding: "20px",
    // height:'873px',
    gap: "50px",
    alignItems: "center",
    maxWidth: "1160px",
    background: "#FFFFFF ",
    top: "12vh",
    boxShadow: "0px 5px 9px #00000029",

    paddingTop: "3.73%",
    boxSizing: "border-box",
    alignContent: "flex-start",
    [theme.breakpoints.up("1160")]: {
      borderRadius: "38px 38px 0 0",
    },
    "& .Titulo": {
      margin: "0",
      font: "32px Fredoka One",
      textSlign: "center",
      letterSpacing: "0px",
      color: "#E65D49",
      // color: "#7058CE",
      opacity: 1,
      [theme.breakpoints.up("lg")]: {
        font: "48px Fredoka One",
      },
    },
    [theme.breakpoints.up("lg")]: {
      left: "unset",
    },
  },
  ContainerSerchBar: {
    gap: "10%",
    padding: "10px 0",
    // justifyContent: "space-between",
    // [theme.breakpoints.up("lg")]: {
    //   height: "70px",
    // },
  },
  ContainerInput: {
    position: "relative",
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "50%",
      // height: "100%",
    },
  },
  SerchBar: {
    outline: "none",
    width: "100%",
    borderRadius: "36px",
    padding: "5px 32px",
    border: "4px solid #EA9D90",
    textAlign: "left",
    letterSpacing: "0.63px",
    color: "#7A54BA",
    font: "600 16px lato",
    // margin: " 0 10%",
    [theme.breakpoints.up("lg")]: {
      height: "auto",
      font: "600 20px lato",
      padding: "10px 32px",
    },
  },
  SerchBarIcon: {
    right: "12.5px",
    top: "10.5px",
    position: "absolute",
    fontSize: "20px",
    color: "#7A54BA",
    [theme.breakpoints.up("sm")]: {
      right: "12.5px",
      top: "12.5px",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "40px",
      right: "25px",
      top: "10px",
    },
  },
  ContainerLecturasImagenes: {
    minHeight: "75.95%",
    gap: "40px",
    display: "grid",
    gridTemplateColumns: "auto",
    justifyItems: "center",
    justifyContent: "space-evenly",
    [theme.breakpoints.up("720")]: {
      gridTemplateColumns: "auto auto",
    },
    [theme.breakpoints.up("lg")]: {
      gridTemplateColumns: "auto auto auto",
    },
    // padding: "0 50px",
  },
}));

const GuiaDelDocente = () => {
  const classes = useStyles();

  const { groupData } = useContext(UsabilityContext);
  
  return (
    <Grid
      container
      item
      sm={12}
      justifyContent="center"
      className={classes.ContainerBiblioteca}
    >
      <Grid container item sm={12} className={classes.TopBar}></Grid>
      <Grid container item className={classes.ContainerLecturas}>
        <Grid container item sm={12} direction="row" justifyContent="center">
          <Grid container item justifyContent="center">
            <p className="Titulo">Recursos del docente</p>
          </Grid>
        </Grid>

        <Grid
          container
          item
          sm={12}
          justifyContent="space-around"
          className={classes.ContainerLecturasImagenes}
        >
          <ResourceCard
            title="Video de bienvenida"
            data={
              "https://pearson-cdl-contenidos-qa.s3.amazonaws.com/G1/frames/cdl-bienvenia.mp4"
            }
            type="video"
            num="4"
            content="video"
          />
          <ResourceCard
            title="Mallas de competencias "
            data={MallaCompetencias}
            type="EXCEL"
            num="2"
            content="competencias"
          />
          <ResourceCard
            title="Manual de usuario del estudiante"
            data={perfil}
            type="PDF"
            num="3"
            content="manual"
          />
          <ResourceCard
            title="Sugerencias didácticas Grado 1"
            data={SugerenciasG1}
            type="PDF"
            num="1"
            content="sugerencias"
            focus={groupData?.id_grado === 1}
          />
          <ResourceCard
            title="Sugerencias didácticas Grado 2"
            data={SugerenciasG2}
            type="PDF"
            num="1"
            content="sugerencias"
            focus={groupData?.id_grado === 2}
          />
          <ResourceCard
            title="Sugerencias didácticas Grado 3"
            data={SugerenciasG3}
            type="PDF"
            num="1"
            content="sugerencias"
            focus={groupData?.id_grado === 3}
          />
          <ResourceCard
            title="Sugerencias didácticas Grado 4"
            data={SugerenciasG4}
            type="PDF"
            num="1"
            content="sugerencias"
            focus={groupData?.id_grado === 4}
          />
          <ResourceCard
            title="Sugerencias didácticas Grado 5"
            data={SugerenciasG5}
            type="PDF"
            num="1"
            content="sugerencias"
            focus={groupData?.id_grado === 5}
          />
          <ResourceCard
            title="Sugerencias didácticas Grado 6"
            data={SugerenciasG6}
            type="PDF"
            num="1"
            content="sugerencias"
            focus={groupData?.id_grado === 6}
          />
        </Grid>
        <Grid container justifyContent="center" alignItems="center"></Grid>
      </Grid>
    </Grid>
  );
};

export default GuiaDelDocente;
