import React               from "react";
import {Grid, makeStyles}                 from '@material-ui/core';

const useStyles = makeStyles(theme =>({
  questionContainer:{
    width: "100%",
    height:"100%",
  },
  reading: {
    width: "90%",
    margin:"10px 0 10px 0 ",
    font: '22px lato',
    letterSpacing: '0.21px',
    [theme.breakpoints.down(1400)]:{
      font: " 18px lato",
      width: "95%",
    },
    [theme.breakpoints.down(1310)]:{
      font: "500 16px lato",

    },
    [theme.breakpoints.down(925)]:{
      font: "500 14px lato",

    },
    [theme.breakpoints.down(426)]:{
      font: "500 12px lato",
 
    }, 
  },
  table: {
    width: "100%",
    font: '22px lato',
    margin: '0',
    [theme.breakpoints.down(1400)]:{
      font: " 18px lato",
      width: "95%",
    },
    [theme.breakpoints.down(1310)]:{
      font: "16px lato",
 
    },
    [theme.breakpoints.down(925)]:{
      font: "14px lato",

    },
    [theme.breakpoints.down(426)]:{
      font: "12px lato",

    }, 
  },
  tdTable: {
    padding: "0 1%",
    // border: '1px solid black',
  }
}));

const QuestionTable = (props) => {
  const classes = useStyles()

  return(
    <Grid container className={classes.questionContainer}>
      <p
        className={classes.reading}
      >
        9. Escoge las respuestas correctas.
      </p>
      <p
        className={classes.reading}
      >
        Relaciona a la artista con los datos de su vida, según corresponda a cada caso:
      </p>
      <br/>
      <table className={classes.table}>
        <thead>
          <th style={{width: "40%", textAlign: 'left'}}><i></i></th>
          <th style={{width: "60%", textAlign: 'left'}}><i></i></th>
        </thead>
        <tbody>
          <tr>
            <td className={classes.tdTable}>
              <p> 
                1. María Izquierdo
              </p>
            </td>
            <td className={classes.tdTable}>
              <p>
                a. Combinó la elementos europeos con temas tradicionales brasileños.
              </p>
            </td>
          </tr>
          <tr>
            <td className={classes.tdTable}>
              <p>
                2. Tarsila do Amaral
              </p>
            </td>
            <td className={classes.tdTable}>
              <p>
                b. Fue exponente de la escultura abstracta en Sudamérica.
              </p>
            </td>
          </tr>
          <tr>
            <td className={classes.tdTable}>
              <p>
                3. Raquel Forner
              </p>
            </td>
            <td className={classes.tdTable}>
              <p>
                c. Su  obra  de  juventud  muestra  una  perspectiva crítica hacia la sociedad colombiana.
              </p>
            </td>
          </tr>
          <tr>
            <td className={classes.tdTable}>
              <p>
                4. Lily Garafulic
              </p>
            </td>
            <td className={classes.tdTable}>
              <p>
                d. Es la primer mexicana en exhibir su obra fuera de México.
              </p>
            </td>
          </tr>
          <tr>
            <td className={classes.tdTable}>
              <p>
                5. Débora Arango
              </p>
            </td>
            <td className={classes.tdTable}>
              <p>
                e. Fue  galardonada  con  medalla  de  oro  en la Exposición Internacional de París
              </p>
            </td>
          </tr>
        </tbody>
      </table>
    </Grid>
  )
}

export default QuestionTable;
