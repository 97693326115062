import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
     Contenedor:{
        background:'#E9FBF9',
        width:'100vw',
        overflowX:'clip',
        [theme.breakpoints.down(1161)]:{
            background:'none',
           overflowX:'unset',
        },
        [theme.breakpoints.down(600)]:{
            marginTop: '79.51px',
        }
    },
  ContainerInfoLectura: {
    position: "relative",
    height: "86px",
    [theme.breakpoints.down(502)]:{
        height: "43px",
    },
    background: "#76D4C3",
    "& .BarraInfoLectura": {
      height: "86px",
      background: `Url(https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Barras/Azul.png)`,
        [theme.breakpoints.down(502)]:{
            height: "43px",
        },
    },
    "& .InfoLectura": {
      position: "absolute",
      height: "86px",
      width: "219px",
      boxSizing: "border-box",
      padding: "17.2px 0",
      background: "#FAFAFA",
      borderRadius: "50px",
      boxShadow: "2px 2px 2px 2px #00000029",
      zIndex: "4",
        [theme.breakpoints.down(502)]:{
            height: "43px",
            width: "109px",
            padding: "8.6px 0",
            borderRadius: "25px",
        },
    },
    "& .InfoLectura:before": {
      position: "absolute",
      display: "flex",
      boxSizing: "border-box",
      padding: "0 0 0 10px",
      justifyContent: "center",
      alignItems: "center",
      height: "39.88px",
      width: "39.88px",
      borderRadius: "50%",
      content: '""',
      boxShadow: "3px 0px .5px 0px #00000029",
      background: "#FAFAFA",
      left: "90px",
      top: "65px",
      transform: "rotate(0.25turn)",
      [theme.breakpoints.down(502)]:{
        left: "45px",
        top: "30px",
        height: "20.88px",
        width: "20.88px",
        },
    },
    "& .InfoLectura:after": {
      content: '""',
      height: "15px",
      width: "15px",
      borderTop: "2px solid #20A698",
      borderLeft: "2px solid #20A698",
      position: "absolute",
      bottom: "-10px",
      left: "Calc(50% - 8px)",
      transform: "rotate(0.625turn)",
      [theme.breakpoints.down(502)]:{
        height: "10px",
        width: "10px",
        left: "Calc(50% - 4.5px)",
        bottom: "-3px",
        borderTop: "1px solid #20A698",
        borderLeft: "1px solid #20A698",
        },
    },
    "& .Sesion": {
      borderLeft: "2px solid #D8D8D8",
    },
    "& .Dato": {
      margin: "0 0 5px 0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#20A698",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        margin: "0 0 2.5px 0",
        },
    },
    "& .Caracter": {
      margin: "0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#000000",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        },
    },   },
    ContenedorLectura:{
        background:'#fff',
        width:'1160px',
        height:'100%',
        padding:'60px 168px 35px ',
        [theme.breakpoints.down(1161)]:{
            width:'502px',
            padding:'60px 0 0 0',
          },
        [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
        '& .ContainerDerechos':{
            bottom:'80px',
            [theme.breakpoints.down(1161)]:{
                display:'none',
            },
        },
        '& .Derechos':{
            textAlign:"center",
            margin:"0px",
            font:" 15px lato",
            color:"#717476",
        },
        '& .Escritorio':{
            display:'block',
            [theme.breakpoints.down(1161)]:{
                display:'none'
            },
        },
        '& .Tablet':{
            display:'none',
            [theme.breakpoints.down(1161)]:{
                display:'block'
            },
        }
    },

    ContainerTitulo:{
        margin:'0 0 46px 0',
        '& .Titulo':{
            font:'48px fredoka one',
            letterSpacing: '0px',
            color: '#20A698',
            margin:'0',
            textAlign:'center',
            [theme.breakpoints.down(1161)]:{
                font:'43px fredoka one',
                lineHeight:'52px',
            },
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        position:'relative',
        '& .Lectura':{
            lineHeight : '30px',
            margin:'0 0 36px 0',
            font:'22px lato',
            color:'#000',
            letterSpacing: '0.20px',
            [theme.breakpoints.down(1161)]:{
                font:'18px lato',
                lineHeight :'28px',
                letterSpacing: '0.17px',
            },
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .Lectura2':{
            margin:'0',
        },
        '& .Esp':{
            margin:'0 ',
            padding:'0 15px 0 0',
            boxSizing:'border-box',
            [theme.breakpoints.down(1161)]:{
                padding:'0',
            },
        },
        '& .Esp2':{
            margin:'0 ',
            padding:'0 15px 0 0',
            boxSizing:'border-box',
            [theme.breakpoints.down(1161)]:{
                padding:'0',
            },
        },

        '& .SubTitulo':{
            margin:'0',
            font:'bold 30px lato',
            letterSpacing:'.28px',
            lineHeight:'30px',
            color:'#E97BA8',
            [theme.breakpoints.down(1161)]:{
                font:'bold 22px lato',
                lineHeight :'24px',
                letterSpacing: '0.21px',
            },
            [theme.breakpoints.down(502)]:{
                font:'bold 18px lato',
                lineHeight :'20px',
                letterSpacing: '0.16px',
            },
        },
        '& .SubTitulo2':{
            margin:'0',
            font:'bold 26px lato',
            letterSpacing:'.24px',
            lineHeight:'30px',
            color:'#823CE2',
            [theme.breakpoints.down(1161)]: {
                font: "bold 22px lato",
                lineHeight: "24px",
                letterSpacing: "0.21px",
            },
            [theme.breakpoints.down(502)]: {
                font: "bold 18px lato",
                lineHeight: "20px",
                letterSpacing: "0.16px",
            },
        },
       '& .TextImg':{
            margin:'24px 0 0 0',
            font:'20px lato',
            alignSelf:'flex-start',
            color:'#717476',
            letterSpacing:'.19px',
            lineHeight:'30px',
            [theme.breakpoints.down(1161)]:{
                font:' 16px lato',
                lineHeight :'25px',
                letterSpacing: '0.15px',
            },
            [theme.breakpoints.down(502)]:{
                font:' 11px lato',
                lineHeight :'14px',
                letterSpacing: '0.10px',
            },
        },
        '& .Imagen1':{ 
            [theme.breakpoints.down(1161)]: {
                margin:'0 0 36px 0',
            },
            [theme.breakpoints.down(502)]: {
                width:'100%',
            },
        },
        '& .Imagen2':{
            [theme.breakpoints.down(1116)]: {
                width:'100%',
            },
        },
        '& .Imagen3':{
            width:'489px',
            [theme.breakpoints.down(502)]: {
                width:'100%',
            },
        },
        '& .Imagen4':{
            [theme.breakpoints.down(1116)]: {
                width:'88.65%',
            },
        },
        
    },
    

}));

const Hay_belleza_en_la_naturaleza = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
             <Grid container item justifyContent="center" className={classes.ContainerInfoLectura}>
               <Grid container className="BarraInfoLectura"></Grid>
               <Grid container item className="InfoLectura">
                    <Grid container item direction="column" xs={6} justifyContent="center">
                        <p className="Dato">Grado</p>
                        <p className="Caracter">2</p>
                    </Grid>
                    <Grid container item direction="column" xs={6} justifyContent="center" className="Sesion">
                        <p className="Dato">Sesión</p>
                        <p className="Caracter">35</p>
                    </Grid>
               </Grid>
            </Grid>

            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >
                        ¿Hay belleza <br className='Tablet'/>en la naturaleza?
                    </h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura Lectura2">
                            <h3 className='SubTitulo'>La idea de lo bello</h3><br/>

                             Los diccionarios definen lo <em>bello</em> como alguien o algo que resalta por su hermosura. 
                             Pero no explican por qué o cómo se puede identificar si alguien o algo es bello o no. <br/><br/>

                             En realidad, no existe una manera precisa de medir la belleza, pues depende de quien 
                             lo ve: lo que para algunas personas resulta hermoso, para otras podría no serlo. La 
                             percepción depende de las vivencias y preferencias de cada uno.<br/><br/> 
                             
                             Pero existen ciertas características que a las personas en general les parecen 
                             agradables. Una de ellas es la <em>simetría</em>.
                        </p>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container md={12} lg={6}>
                        <p className="Lectura Esp">
                            <h3 className='SubTitulo2 '>¿Qué es la simetría?</h3><br/>

                            Un objeto se define como <em>simétrico </em> 
                            cuando hay una correspondencia exacta 
                            en la forma, el tamaño y la posición de 
                            sus partes a partir del centro. Por
                            ejemplo, si observas el rostro de un león 
                            e imaginas que una línea punteada lo 
                            atraviesa a la mitad, notarás que el lado 
                            derecho es simétrico con el izquierdo.<span className=" Escritorio"><br/><br/></span>
                            
                            <span className=" Escritorio">Observa ahora el rostro de un tigre.</span>
                        </p>
                    </Grid>
                    <Grid container  md={12} lg={6}>
                        <Grid container justifyContent="center" >
                            <img className="Imagen1 Escritorio" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img2do/Sesion35/1.png"  alt="Tarjeta" />
                            <img className="Imagen1 Tablet" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img2do/Sesion35/1R.png"  alt="Tarjeta" />
                            <span className="Tablet">Observa ahora el rostro de un tigre.</span>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <img className="Imagen2" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img2do/Sesion35/2.png"  alt="Tarjeta" />
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container  md={12} lg={6}>
                        <p className="Lectura Esp2">
                            Las manchas de su lado izquierdo no 
                            corresponden simétricamente con las del 
                            derecho. Entonces, ¿es o no es bello? 
                            Discútelo con las personas a tu 
                            alrededor. <br/><br/>
                            
                            La respuesta suele ser que sí lo es. Así, 
                            comprobamos que la belleza se define 
                            por diversos factores. <br/><br/>
                            
                            Otra característica que resulta 
                            placentera para los sentidos es la 
                            cualidad que tienen algunos objetos
                            naturales de formar fractales. Los 
                            <em> fractales</em> son formas geométricas que se 
                            repiten constantemente con diferentes 
                            tamaños. Por ejemplo, un brócoli 
                            romanesco que muestra sus partes con 
                            un patrón en espiral.
                        </p>
                    </Grid>
                    <Grid container  md={12} lg={6}>
                        <Grid container justifyContent="center" >
                            <img className="Imagen3" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img2do/Sesion35/3.png"  alt="Tarjeta" />
                        </Grid>
                        <Grid container justifyContent="center">
                            <p className='TextImg' > 
                                El brócoli romanesco es una variedad<br className='Escritorio'/>
                                 de coliflor italiana.
                            </p>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                             Finalmente, según los expertos existe una propiedad de la belleza llamada <em>proporción </em> 
                            <em>áurea</em>, una relación matemática entre las partes de un objeto. Se representa con un 
                            rectángulo que contiene distintas figuras geométricas. La forma que tiene el 
                            caparazón de un caracol se asemeja a la representación de la proporción áurea.
                        </p>
                    </Grid>
                    <Grid container  sm={12}>
                        <Grid container justifyContent="center" >
                            <img className="Imagen4" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img2do/Sesion35/4.png"  alt="Tarjeta" />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura ">
                            Es probable que algo que te gusta tenga por lo menos una de estas características, 
                            aunque no lo notes a simple vista. <br/><br/>
                            
                            Después de este repaso, ¿cuál es tu conclusión? ¿Consideras que hay belleza en la 
                            naturaleza?
                        </p>
                    </Grid>
                </Grid>


                <Grid container item xs={12} justifyContent="center" className="ContainerDerechos">
                    <p className="Derechos">
                        © 2022. Todos los derechos reservados, Pearson, Circuito de lectores.
                    </p>                
                </Grid>
        </Grid>
    </Grid>
    );
}
 
export default Hay_belleza_en_la_naturaleza;
