import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
     Contenedor:{
        background:'#E9FBF9',
        width:'100vw',
        overflowX:'clip',
        [theme.breakpoints.down(1161)]:{
            background:'none',
           overflowX:'unset',
        },
        [theme.breakpoints.down(600)]:{
            marginTop: '79.51px',
        }
    },
  ContainerInfoLectura: {
    position: "relative",
    height: "86px",
    [theme.breakpoints.down(502)]:{
        height: "43px",
    },
    background: "#76D4C3",
    "& .BarraInfoLectura": {
      height: "86px",
      background: `Url(https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Barras/Azul.png)`,
        [theme.breakpoints.down(502)]:{
            height: "43px",
        },
    },
    "& .InfoLectura": {
      position: "absolute",
      height: "86px",
      width: "219px",
      boxSizing: "border-box",
      padding: "17.2px 0",
      background: "#FAFAFA",
      borderRadius: "50px",
      boxShadow: "2px 2px 2px 2px #00000029",
      zIndex: "4",
        [theme.breakpoints.down(502)]:{
            height: "43px",
            width: "109px",
            padding: "8.6px 0",
            borderRadius: "25px",
        },
    },
    "& .InfoLectura:before": {
      position: "absolute",
      display: "flex",
      boxSizing: "border-box",
      padding: "0 0 0 10px",
      justifyContent: "center",
      alignItems: "center",
      height: "39.88px",
      width: "39.88px",
      borderRadius: "50%",
      content: '""',
      boxShadow: "3px 0px .5px 0px #00000029",
      background: "#FAFAFA",
      left: "90px",
      top: "65px",
      transform: "rotate(0.25turn)",
      [theme.breakpoints.down(502)]:{
        left: "45px",
        top: "30px",
        height: "20.88px",
        width: "20.88px",
        },
    },
    "& .InfoLectura:after": {
      content: '""',
      height: "15px",
      width: "15px",
      borderTop: "2px solid #20A698",
      borderLeft: "2px solid #20A698",
      position: "absolute",
      bottom: "-10px",
      left: "Calc(50% - 8px)",
      transform: "rotate(0.625turn)",
      [theme.breakpoints.down(502)]:{
        height: "10px",
        width: "10px",
        left: "Calc(50% - 4.5px)",
        bottom: "-3px",
        borderTop: "1px solid #20A698",
        borderLeft: "1px solid #20A698",
        },
    },
    "& .Sesion": {
      borderLeft: "2px solid #D8D8D8",
    },
    "& .Dato": {
      margin: "0 0 5px 0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#20A698",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        margin: "0 0 2.5px 0",
        },
    },
    "& .Caracter": {
      margin: "0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#000000",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        },
    },   },
    ContenedorLectura:{
        background:'#fff',
        width:'1160px',
        height:'100%',
        padding:'60px 168px ',
        [theme.breakpoints.down(1161)]:{
            width:'502px',
            padding:'60px 0 0 0',
          },
        [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
        '& .ContainerDerechos':{
            bottom:'80px',
            [theme.breakpoints.down(1161)]:{
                display:'none',
            },
        },
        '& .Derechos':{
            textAlign:"center",
            margin:"0px",
            font:" 15px lato",
            color:"#717476",
        },
        '& .Escritorio':{
            display:'block',
            [theme.breakpoints.down(1161)]:{
                display:'none'
            },
        },
        '& .Tablet':{
            display:'none',
            [theme.breakpoints.down(1161)]:{
                display:'block'
            },
        },
    },
    ContainerTitulo:{
        margin:'0 0 46px 0',
        '& .Titulo':{
            font:'48px fredoka one',
            letterSpacing: '0px',
            lineHeight:'58px',
            color: '#817FD9',
            margin:'0',
            textAlign:'center',
            [theme.breakpoints.down(1161)]:{
                font:'43px fredoka one',
                lineHeight:'52px',
            },
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            lineHeight : '30px',
            margin:'0 0 36px 0',
            font:'22px lato',
            color:'#000',
            letterSpacing: '0.20px',
            [theme.breakpoints.down(1161)]:{
                font:'18px lato',
                lineHeight :'28px',
                letterSpacing: '0.17px',
            },
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .SubTitulo':{
            font:'bold 30px lato',
            color:'#FF0000',
            letterSpacing:'.28px',
            lineHeight:'30px',
            margin:'0',
            [theme.breakpoints.down(1161)]:{
                font:'bold 26px lato',
                lineHeight :'28px',
                letterSpacing: '0.24px',
            },
            [theme.breakpoints.down(502)]:{
                padding:'19px 0 12px 0',
                font:'bold 22px lato',
                lineHeight :'24px',
                letterSpacing: '0.21px',
            },
        },
        "& .SubTitulo2": {
            font: "bold 26px lato",
            color: "#0CB5D6",
            letterSpacing: ".24px",
            lineHeight: "28px",
            margin: "0",
            [theme.breakpoints.down(1161)]: {
              font: "bold 22px lato",
              lineHeight: "24px",
              letterSpacing: "0.21px",
            },
            [theme.breakpoints.down(502)]: {
              font: "bold 18px lato",
              lineHeight: "20px",
              letterSpacing: "0.16px",
            },
        },
        '& .Esp':{
            margin:'0'
        },
        '& .ul':{
            padding:'0 0 0 19px',
            margin:0,
        },
        '& .li':{
            color:'#817FD9',
            margin:'0 0 8px 0 ',
            [theme.breakpoints.down(502)]: {
                margin:'0 0 4px 0 ',
              },
        },

        '& .TextImg':{
            font:'20px lato',
            color:'#717476',
            letterSpacing:'.19px',
            lineHeight:'30px',
            margin:'24px 0 0 0',
            [theme.breakpoints.down(1161)]:{
                font:' 16px lato',
                lineHeight :'25px',
                letterSpacing: '0.15px',
            },
            [theme.breakpoints.down(502)]:{
                font:' 11px lato',
                lineHeight :'14px',
                letterSpacing: '0.10px',
            },
        },
        '& .Imagen':{
            [theme.breakpoints.down(1161)]:{
                width:'100%',
            },
        },  
        '& .Imagen2':{
            [theme.breakpoints.down(1161)]:{
                width:'94.22%',
            },
        }, 
      
    },
}));

const La_dignidad_humana_a_través_del_autocuidado_y_los_límites = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
             <Grid container item justifyContent="center" className={classes.ContainerInfoLectura}>
               <Grid container className="BarraInfoLectura"></Grid>
               <Grid container item className="InfoLectura">
                    <Grid container item direction="column" xs={6} justifyContent="center">
                        <p className="Dato">Grado</p>
                        <p className="Caracter">2</p>
                    </Grid>
                    <Grid container item direction="column" xs={6} justifyContent="center" className="Sesion">
                        <p className="Dato">Sesión</p>
                        <p className="Caracter">40</p>
                    </Grid>
               </Grid>
            </Grid>

            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >
                        La dignidad humana a<br className='Tablet'/> través <br className='Escritorio'/> del autocuidado<br className='Tablet'/> y los límites
                    </h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container xs={12} >
                      <p className="Lectura ">
                        <h3 className='SubTitulo'>¿Qué es la <em>dignidad humana</em>?</h3><br/>
                        
                        La <em>dignidad humana</em> está estrechamente ligada a los derechos humanos. En esta 
                        ocasión nos enfocaremos en los de los niños, como tú.<br/><br/>
                        
                        La dignidad humana se relaciona con la forma en que te ves y te tratas, y cómo
                        promueves o exiges que te traten. Cuando te respetas y te valoras, la estás poniendo
                        en práctica. Esto también ocurre cuando procuras que las demás personas te
                        respeten y valoren por lo que eres: un ser humano. Todos tenemos la responsabilidad
                        de tratar dignamente a los otros: con igualdad y haciendo valer sus derechos (que
                        son los mismos que tú tienes).<br/><br/> 
                        
                        <h3 className='SubTitulo2'>Autocuidado</h3><br/>
                        
                        El <em>autocuidado</em> consiste en cuidarte a ti mismo en todos los aspectos. Comienza 
                        con tu cuerpo y tu salud: come alimentos saludables, haz ejercicio y mantén una buena 
                        higiene personal. También implica que no te hagas daño intencionadamente ni te 
                        expongas a situaciones peligrosas.<br/><br/> 
                        
                        Existen muchas formas de autocuidado, como leer, dormir bien, pasar el menor 
                        tiempo posible frente a una pantalla, convivir con tus seres queridos, mantener
                        limpios y agradables tus espacios… ¿Qué otras medidas se te ocurren?
                      </p>
                    </Grid>
                    <Grid container xs={12} >
                        <Grid container justifyContent="center" >
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img2do/Sesion40/1.png" alt="Tarjeta" />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container xs={12} >
                      <p className="Lectura ">
                        
                        <h3 className='SubTitulo2'>Límites</h3><br/>
                        
                        La dignidad humana implica que los otros también te respeten y valoren. Esto incluye 
                        tu cuerpo. Por lo tanto, nadie puede tocarte sin tu permiso, del mismo modo en que 
                        tú no debes tocar a alguien sin su consentimiento. Eso es <em>poner y respetar límites</em>. Es 
                        una acción que puedes aplicar respecto a tu cuerpo, a tus sentimientos y a tu espacio 
                        personal.<br/><br/>
                        
                        Una manera de poner límites es decir “No”. Por ejemplo, cuando no te gusta la forma 
                        en que se dirigen a ti, no te hablan por tu nombre o no respetan tus emociones.

                      </p>
                    </Grid>
                    <Grid container xs={12} >
                        <Grid container justifyContent="center">
                            <img className="Imagen2" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img2do/Sesion40/2.png" alt="Tarjeta" />
                        </Grid>
                        <Grid container justifyContent="center" >
                            <p className='TextImg'>
                                Poner y respetar límites es fundamental para el bienestar y el desarrollo de los niños.
                            </p>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container xs={12} >
                      <p className="Lectura ">
                        <h3 className='SubTitulo'>
                            Algunas recomendaciones para el autocuidado de los niños 
                            durante la pandemia de Covid-19
                        </h3><br/>
                        
                         Durante la pandemia de Covid-19, la Organización Mundial de la Salud propuso:<br/><br/>

                         <ul className='ul'>
                            <li className='li'>
                                <p className='Lectura Esp'>Lavarse las manos con frecuencia.</p>    
                            </li>
                            <li className='li'>
                                <p className='Lectura Esp'>Usar correctamente el cubrebocas.</p>    
                            </li>
                            <li className='li'>
                                <p className='Lectura Esp'>Mantener una distancia de al menos metro y medio con otras personas.</p>    
                            </li>
                        </ul>
                      </p>
                    </Grid>
                    <Grid container xs={12} >
                        <Grid container justifyContent="center" >
                            <img className="Imagen2" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img2do/Sesion40/3.png" alt="Tarjeta" />
                        </Grid>
                        <Grid container justifyContent="center" >
                            <p className='TextImg'>
                            Seguir las recomendaciones durante una pandemia te ayuda a estar más seguro.                            
                            </p>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container xs={12} >
                      <p className="Lectura ">
                        Estas medidas son de autocuidado porque los niños deben seguirlas por sí mismos, y 
                        ayudan a preservar la dignidad en la niñez.
                      </p>
                    </Grid>
                </Grid>

                <Grid container item xs={12} justifyContent="center" className="ContainerDerechos">
                    <p className="Derechos">
                        © 2022. Todos los derechos reservados, Pearson, Circuito de lectores.
                    </p>                
                </Grid>
        </Grid>
    </Grid>
    );
}
 
export default La_dignidad_humana_a_través_del_autocuidado_y_los_límites;
