import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
    ContenedorLectura:{
        height:'100%',
        width:'502px',
          [theme.breakpoints.down(576)]:{
            width:'350px',
          },
        '& .Escritorio':{
            display:'block',
            [theme.breakpoints.down(1161)]:{
                display:'none'
            },
        },
        '& .Tablet':{
            display:'none',
            [theme.breakpoints.down(1161)]:{
                display:'block'
            },
        }
    },
    ContainerTitulo:{
        margin:'0 0 34px 0',
        '& .Titulo':{
            letterSpacing: '0px',
            color: '#0CB5D6',
            margin:'0',
            textAlign:'center',
            font:'43px fredoka one',
            lineHeight:'52px',
            [theme.breakpoints.down(576)]:{
                font:'38px fredoka one',
                lineHeight:'52px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            margin:'0 0 36px 0',
            color:'#000',
            font:'18px lato',
            lineHeight :'28px',
            letterSpacing: '0.17px',
            [theme.breakpoints.down(576)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
        },
        '& .Esp':{
            margin:'0',
        },
        '& .TextImg':{
            color:'#717476',
            margin:'24px 0 0 0',
            font:' 16px lato',
            lineHeight :'25px',
            letterSpacing: '0.15px',
            [theme.breakpoints.down(576)]:{
                font:' 14px lato',
                lineHeight :'21px',
                letterSpacing: '0.15px',
            },
        },
        '& .Imagen':{
            width:'576px',
            [theme.breakpoints.down(576)]:{
                width:'100%'
            },
        }, 
        '& .Imagen2':{
            width:'576px',
            [theme.breakpoints.down(576)]:{
                width:'100%'
            },
        },  
      
    }
}));

const Una_familia_peculiar = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                        <h2 className="Titulo">Una familia peculiar</h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                       <p className='Lectura Esp'>
                        Todas las familias son especiales, pero hay pocas tan peculiares como ésta. Esta familia 
                        nació en la antigua Grecia y estaba presente en la vida de todos los griegos.<br/><br/>
                        
                         Zeus era el dios del rayo y de los truenos. Gobernaba el Monte Olimpo, una montaña 
                         mítica donde vivía la mayoría de los dioses. Zeus estaba casado con Hera, la diosa del 
                         hogar y del matrimonio, quien se encargaba de proteger las bodas y los nacimientos. 
                         Juntos eran los más importantes de todos los dioses.
                       </p>                   
                    </Grid>
                    <Grid container justifyContent='center'>
                        <Grid container justifyContent='center'>
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img1ro/Sesion7/1.png" alt="Tarjeta" />
                        </Grid>
                        <Grid container justifyContent='center' >
                            <p className="TextImg">
                                Zeus y Hera.
                            </p>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container >
                        <p className='Lectura'>
                            Uno de los hermanos de Zeus era Poseidón, pero él no vivía en el Olimpo, porque regía los 
                            mares y los océanos. Poseidón levantaba terribles olas y era el señor de los terremotos. 
                            Mientras que la sabiduría y la justicia estaban a cargo de Atenea, la hija favorita de Zeus. 
                            Ella además era muy generosa, porque daba valor a los héroes durante la guerra. Su hermano era 
                            Ares, el poderosísimo dios de la guerra, quien con su fuerza protegía el Olimpo.
                        </p>
                    </Grid>
                    <Grid container justifyContent='center'>
                        <Grid container justifyContent='center'>
                            <img className="Imagen2" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img1ro/Sesion7/2.png" alt="Tarjeta" />
                        </Grid>
                        <Grid container justifyContent='center' >
                            <p className="TextImg">
                                Poseidón, Atenea y Ares.
                            </p>
                        </Grid>
                    </Grid>

                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container >
                        <p className='Lectura'>
                            La diosa Artemisa vigilaba la cacería con su arco y su flecha, mientras que su tía 
                            Deméter protegía los cultivos. ¿Y el Sol? ¡También él tenía su propio dios! Se llamaba 
                            Apolo y los iluminaba a todos.
                        </p>
                    </Grid>
                    <Grid container justifyContent='center'>
                        <Grid container justifyContent='center'>
                            <img className="Imagen2" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img1ro/Sesion7/3.png" alt="Tarjeta" />
                        </Grid>
                        <Grid container justifyContent='center' >
                            <p className="TextImg">
                                Artemisa, Deméter y Apolo.
                            </p>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container >
                        <p className='Lectura'>
                            ¿Conoces a más miembros de esta familia tan peculiar? Investígalos y comparte tus hallazgos con tus compañeros.
                        </p>
                    </Grid>
                </Grid>
            </Grid>
    </Grid>
    );
}
 
export default Una_familia_peculiar;
