import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({

    ContenedorLectura:{
        width:'502px',
        [theme.breakpoints.down(502)]:{
            width:'350px',
        },
    },
    ContainerTitulo:{
        margin:'0 0 38px 0',
        '& .Titulo':{
            letterSpacing: '0px',
            color: '#0CB5D6',
            margin:'0',
            font:'43px fredoka one',
            lineHeight:'52px',
            textAlign:'center',
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            margin:'0 0 36px 0 ',
            color:'#000',
            font:'18px lato',
            letterSpacing:'.17px',
            lineHeight:'28px',
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .Lectura2':{
            margin:'0',
        },
        '& .SubTitulo':{
            color:'#926DD1',
            margin:'0',
            font:'bold 26px lato',
            letterSpacing:'.24px',
            lineHeight:'28px',
            [theme.breakpoints.down(502)]:{
                font:'bold 22px lato',
                lineHeight:'24px',
            },
            [theme.breakpoints.down(350)]:{
                font:'bold 18px lato',
                lineHeight:'24px',
            },
        },
        '& .TextImg':{
            margin:'22px 0 0 0',
            alignSelf:'flex-start',
            color:'#717476',
            font:'16px lato',
            letterSpacing:'.15px',
            lineHeight:'25px',
            [theme.breakpoints.down(502)]:{
                font:' 11px lato',
                lineHeight :'14px',
                letterSpacing: '0.10px',
            },
        },

        '& .Imagen':{
            width:'100%',
        },
    },
    

}));

const Mamuts_de_nuevo_a_la_vida_Clonación = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >
                    Mamuts de nuevo <br/>a la vida. Clonación
                    </h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                            La información genética es la información biológica que se transmite de generación 
                            en generación. Actualmente el avance en las técnicas de su manejo ha permitido la 
                            producción de más y mejores alimentos, el desarrollo de tratamientos para la cura de 
                            enfermedades e incluso la clonación. <br/><br/>
                            
                            Tales han sido los resultados que científicos han pensado en aplicar estos 
                            conocimientos en la conservación de las especies para evitar que desaparezcan. Es 
                            más, han pensado en recuperar especies extintas. En otras palabras, creen que es 
                            posible la “desextinción” de las especies. <br/><br/>
                            
                            En 2009, se clonó por primera vez a un animal extinto, pero la cría no sobrevivió. 
                            Para realizar una clonación se necesita la molécula de <span style={{fontVariant: "all-small-caps"}}>ADN</span> en la qu se almacena el 
                            material genético del animal. En el caso de los mamuts, este <span style={{fontVariant: "all-small-caps"}}>ADN</span> ha sido recuperado 
                            a partir de muestras de especímenes congelados en Siberia, por lo que está muy 
                            dañado. Y a pesar de varios intentos realizados por diferentes investigadores, no ha 
                            logrado repararse. <br/><br/>
                            
                            <h3 className='SubTitulo'>¿Cómo eran los mamuts y por qué se extinguieron?</h3> <br/>
                            
                            Los mamuts eran semejantes a los elefantes africanos actuales. Medían alrededor de 
                            seis metros de altura y nueve metros de longitud. Tenían el cuerpo cubierto por un 
                            abundante pelaje y la cabeza protegida por grandes colmillos.
                        </p>
                    </Grid>
                    <Grid container  sm={12}>
                        <Grid container justifyContent="center" >
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion35/1.png"  alt="Tarjeta" />
                        </Grid>
                        <Grid container justifyContent="center">
                            <p className='TextImg'>Los mamuts eran animales con características ideales para su entorno.</p>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                            Como la de muchas especies actuales, la extinción de los mamuts fue atribuida 
                            durante mucho tiempo a las actividades de las poblaciones humanas que en ese 
                            tiempo crecían cada vez más. Pero los estudios recientes han determinado que los 
                            mamuts se extinguieron en realidad como consecuencia del cambio climático y 
                            modificaciones en la vegetación que ocurrieron después de la Edad del Hielo, la cual 
                            terminó hace 21 000 años. <br/><br/>
                            
                            Los mamuts eran habitantes comunes de las praderas del norte de Europa, Siberia y 
                            Norteamérica. Eran inmensos herbívoros que se alimentaban de pastos que en un 
                            momento fueron abundantes, pero que después del periodo glacial se transformaron 
                            en grandes extensiones de bosques. <br/><br/>
                            
                            La falta de alimento llevó a los mamuts a sufrir de enfermedades de huesos y de las 
                            articulaciones. Esto los volvió más vulnerables ante sus depredadores naturales y 
                            también ante los humanos. Las últimas poblaciones de mamuts se establecieron en la 
                            isla de Wrangel en el Ártico y se extinguieron finalmente hace unos 4 000 años. <br/><br/>

                            Actualmente, por el calentamiento global, se han descongelado mamuts completos, 
                            preservados en el hielo en lugares como Siberia. Gracias a esa conservación óptima 
                            se ha contemplado la idea de volverlos a la vida, empleando la técnica de la clonación.<br/><br/>
                            
                            <h3 className='SubTitulo'>Causas de la extinción de las especies actuales</h3> <br/>
                            
                            A diferencia de los mamuts, la extinción de muchas especies actuales sí es por causa 
                            de las acciones humanas: ya sea por la caza inmoderada, por el cambio climático 
                            ocasionado por la contaminación de la industria y la quema de combustibles fósiles, e 
                            incluso también por la destrucción o alteración de sus hábitats naturales. <br/><br/>
                            
                            Ante estas situaciones, los científicos han planteado dentro de sus objetivos 
                            conservar y evitar la extinción y la destrucción del hogar de tantos seres vivos.
                        </p>
                    </Grid>
                    <Grid container  sm={12}>
                        <Grid container >
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion35/2.png"  alt="Tarjeta" />
                        </Grid>
                        <Grid container justifyContent="center">
                            <p className='TextImg'>El ser humano ha invadido territorios que originalmente eran de los animales.</p>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                            <h3 className='SubTitulo'>Recuperación y conservación de las especies</h3> <br/>
                            
                            Aunque recuperar especies parece una aventura muy interesante de explorar, la 
                            realidad es que existen muchas complicaciones, además de las genéticas. Por 
                            ejemplo, en el caso del mamut sería necesario reconstruir su hábitat para favorecer 
                            su supervivencia. Imagina que se tendrían que buscar lugares con temperaturas 
                            similares a las que vivieron en su época y con la flora de aquel tiempo que, como ya 
                            leíste, eran pastizales específicos. Además, la liberación de estos clones en el 
                            ambiente alteraría el equilibrio de los ecosistemas, pues podrían competir con las 
                            poblaciones naturales. <br/><br/>
                            
                            Aun así, existen proyectos de conservación de <span style={{fontVariant: "all-small-caps"}}>ADN</span>de especies en peligro extinción. 
                            Se trata de algo parecido a un “arca de Noé genética” para evitar su destrucción. <br/><br/>
                            
                            Recuerda que cuando se extingue una especie se extinguen muchas más, ya que todo 
                            está conectado y existe en un delicado equilibrio entre todas las especies.
                        </p>
                    </Grid>
                
                </Grid>
        </Grid>
    </Grid>
    );
}
 
export default Mamuts_de_nuevo_a_la_vida_Clonación;
