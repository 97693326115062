import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({

    ContenedorLectura:{
        position:'relative',
        height:'100%',
        width:'100%',
          [theme.breakpoints.down(768)]:{
            width:'350px',
        },
        '& .Imagen':{
            width:'100%'
        },

    },

    
}));

const Diversas_acciones_para_salvar_el_mundo = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <img className='Imagen ' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img2do/Sesion14/F2.png" alt="Fondo" />
            </Grid>
        </Grid>
    );
}
 
export default Diversas_acciones_para_salvar_el_mundo;
