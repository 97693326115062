import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
      Contenedor:{
        background:'#E9FBF9',
        width:'100vw',
        overflowX:'clip',
        [theme.breakpoints.down(1161)]:{
            background:'none',
           overflowX:'unset',
        },
        [theme.breakpoints.down(600)]:{
            marginTop: '79.51px',
        }
    },
    ContainerInfoLectura:{
        position:'relative',
        height:'86px',
        background:'#F6664D',
        '& .BarraInfoLectura':{
            height:'86px',
            background:`Url(https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Barras/Naranja.png)`,
        },
        '& .InfoLectura':{
            position:'absolute',
            height:'86px',
            width:'219px',
            boxSizing:'border-box',
            padding:'17.2px 0',
            background:'#FAFAFA',
            borderRadius:'50px',
            boxShadow: '2px 2px 2px 2px #00000029',
            zIndex:'4',
        },
        '& .InfoLectura:before':{
            position:'absolute',
            display:'flex',
            boxSizing:'border-box',
            padding:'0 0 0 10px',
            justifyContent:'center',
            alignItems:'center',
            height:'39.88px',
            width:'39.88px',
            borderRadius:'50%',
            content:'""',
            boxShadow: '3px 0px .5px 0px #00000029',
            background:'#FAFAFA',
            left:'90px',
            top:'65px',
            transform: 'rotate(0.25turn)',
        },
        '& .InfoLectura:after':{
            content:'""',
            height:'15px',
            width:'15px',
            borderTop:'2px solid #F6664D',
            borderLeft:'2px solid #F6664D',
            position:'absolute',
            bottom:'-10px',
            left:'Calc(50% - 8px)',
            transform: 'rotate(0.625turn)',
        },
        '& .Sesion':{
            borderLeft:'2px solid #D8D8D8',
        },
        '& .Dato':{
            margin:'0 0 5px 0',
            textAlign:'center',
            font:'bold 17px lato',
            color:'#F6664D',
        },
        '& .Caracter':{
            margin:'0',
            textAlign:'center',
            font:'bold 17px lato',
            color:'#000000',
        },
    },
    ContenedorLectura:{
        background:'#fff',
        width:'1160px',
        height:'100%',
        padding:'60px 168px 35px ',
        [theme.breakpoints.down(1161)]:{
            width:'502px',
            padding:'60px 0 0 0',
          },
        [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
        '& .ContainerDerechos':{
            bottom:'80px',
            [theme.breakpoints.down(1161)]:{
                display:'none',
            },
        },
        '& .Derechos':{
            textAlign:"center",
            margin:"0px",
            font:" 15px lato",
            color:"#717476",
        },
        '& .Escritorio':{
            display:'flex',
            [theme.breakpoints.down(1161)]:{
                display:'none'
            },
        },
        '& .Tablet':{
            display:'none',
            [theme.breakpoints.down(1161)]:{
                display:'flex'
            },
        }
    },
    ContainerTitulo:{
        margin:'0 0 34px 0',
        [theme.breakpoints.down(1161)]:{
            margin:'0 0 34px 0',
        },
        '& .Titulo':{
            letterSpacing: '0px',
            color: '#F6644B',
            margin:'0',
            textAlign:'center',
            font:'48px fredoka one',
            lineHeight:'58px',
            [theme.breakpoints.down(1161)]:{
                font:'43px fredoka one',
                lineHeight:'52px',
            },
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            color:'#000',
            margin:'0',
            font:'22px lato',
            letterSpacing: '0.21px',
            lineHeight : '30px',
            [theme.breakpoints.down(1161)]:{
                font:'18px lato',
                lineHeight :'28px',
                letterSpacing: '0.17px',
            },
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .Imagen':{
            [theme.breakpoints.down(1161)]:{
                width:'100%',
            },
        },  
       
    },

}));

const Viajes_en_el_tiempo = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
             <Grid container item justifyContent="center" className={classes.ContainerInfoLectura}>
               <Grid container className="BarraInfoLectura"></Grid>
               <Grid container item className="InfoLectura">
                    <Grid container item direction="column" xs={6} justifyContent="center">
                        <p className="Dato">Grado</p>
                        <p className="Caracter">4</p>
                    </Grid>
                    <Grid container item direction="column" xs={6} justifyContent="center" className="Sesion">
                        <p className="Dato">Sesión</p>
                        <p className="Caracter">21R</p>
                    </Grid>
               </Grid>
            </Grid>

            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >
                        El país del olvido
                    </h2>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container xs={12} justifyContent="center">
                        <p className="Lectura ">
                        —¡Mamá! ¡Mama! ¡Mamá! —gritó Lito desesperado desde su habitación.
                        <br/><br/>
                        —¿Qué pasa? —respondió ella de inmediato, preocupada.
                        <br/><br/>
                        —¿En qué año terminó nuestra independencia? ¿Quiénes son nuestros libertadores? —la interrogó cuando entró a su cuarto.
                        <br/><br/>
                        —¿Para eso tanto escándalo, Lito? ¡Pensé que era una emergencia!
                        <br/><br/>
                        —¡Lo es! Estaba repasando algunas fechas para el examen y me di cuenta de que ¡se me olvidó todo! ¡No puedo recordar los datos importantes!
                        <br/><br/>
                        —¡Qué bárbaro! Nuestra independencia se consumó en… mil… mil… ochos… —y de pronto se quedó callada. A ella también se le había olvidado la fecha por completo.
                        <br/><br/>
                        —Mamá, despierta. La fecha. ¿Cuál era?
                        <br/><br/>
                        —Espera. Yo tampoco la recuerdo. ¿Por qué no logro hacerlo?
                        <br/><br/>
                        —¿Ves? Heredé de ti la mala memoria.
                        <br/><br/>
                        —No. Esto es diferente. Me puedo acordar de muchas cosas, pero siento que nunca conocí nuestra historia si trato de recordarla. Es como si jamás hubiera sabido de ella.
                        <br/><br/>
                        —¿Como si se borrara del mundo?
                        <br/><br/>
                        —Sí… algo así. ¿Qué está sucediendo?
                        <br/><br/>
                        —Yo siento lo mismo. Puedo ver el lugar que les corresponde a esos recuerdos en mi cabeza, pero la casilla está vacía. ¡No hay información dentro!
                        </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12} >
                        <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img4to/Sesion21R/1.png" alt="Tarjeta" />
                    </Grid>
                </Grid>

                <Grid container sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}  justifyContent='center'>
                        <p className="Lectura ">
                            —A ver, probemos. ¿Cuál es la capital de Francia?
                            <br/><br/>
                            —París.
                            <br/><br/>
                            —¿De Perú?
                            <br/><br/>
                            —Lima.
                            <br/><br/>
                            —¿La nuestra?
                            <br/><br/>
                            —Mmm… no sé.
                            <br/><br/>
                            —Yo tampoco —dijo la mamá y ahora sí se veía muy preocupada.
                            <br/><br/>
                            Él corrió a su computadora y buscó en internet la historia de su país. El navegador tardó un buen rato en cargar y al final arrojó un mensaje terrible: “Información no encontrada”. ¿Cómo era posible?
                            <br/><br/>
                            —Mira, mamá… ¿Mamá?
                            <br/><br/>
                            Ella estaba sentada en la cama con un libro de historia nacional en las manos. Sus ojos casi se le desorbitaban y tenía la boca abierta, como si lo que veía fuera una película de terror. Él tomó el ejemplar y observó las páginas en blanco. Vacías. Cada letra de su contenido había desaparecido.
                            <br/><br/>
                            —No puede ser… —susurró la mamá—. Nos borraron el pasado.
                            <br/><br/>
                            Lito se recostó en la cama junto a ella y dijo:
                            <br/><br/>
                            —Veamos las cosas con calma. ¿Qué es lo peor que puede pasar? No creo que algo malo.
                            <br/><br/>
                            —¿Cómo que qué? ¿Te imaginas que no supieras quiénes fueron tus padres ni tus abuelos?, ¿que no sepas de dónde vienes? ¡No sabremos quiénes somos!
                            <br/><br/>
                            Lito pensó que se podría librar de las clases y los exámenes de Historia gracias a este suceso y se puso feliz. Pero ella tenía razón: se olvidaba un poco de sí mismo al olvidar su historia. Entonces, él se preocupó mucho.
                        </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12} >
                        <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img4to/Sesion21R/2.png" alt="Tarjeta" />
                    </Grid>
                </Grid>

                <Grid container sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}  justifyContent='center'>
                        <p className="Lectura ">
                            —Vamos a la calle. Hay que preguntarle a alguien. A quien sea.
                            <br/><br/>
                            Salieron. No necesitaron preguntar nada: se dieron cuenta de que todas las personas estaban igual con sólo mirar sus rostros. Había duda en sus ojos y temor en sus pasos.
                            <br/><br/>
                            Lito y sus compañeros de escuela decidieron hacer algo ante esta situación. Pronto se organizaron y se reunieron para buscar un remedio juntos. Contactaron por internet a personas de otros países, les pidieron libros, recuerdos o cualquier objeto que los ayudara a recordar la Historia. Se dedicaron a recabar datos de aquí y de allá a partir de ese momento. Recopilaron mucha información y la pegaron en un nuevo libro al que titularon La historia del país del olvido.

                        </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12} >
                        <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img4to/Sesion21R/3.png" alt="Tarjeta" />
                    </Grid>
                </Grid>

                <Grid container item xs={12} justifyContent="center" className="ContainerDerechos">
                    <p className="Derechos">
                        © 2022. Todos los derechos reservados, Pearson, Circuito de lectores.
                    </p>                
                </Grid>
        </Grid>
    </Grid>
    );
}
 
export default Viajes_en_el_tiempo;
