import React, { useState } from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import VideoPlayer from "react-video-js-player";

import { GridCloseIcon } from "@mui/x-data-grid";
import { Grid, makeStyles } from "@material-ui/core";

import { useHistory } from "react-router-dom";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    // padding: theme.spacing(2),

    [theme.breakpoints.up("sm")]: {
      // margin: "20px 0",
      paddingTop: "0",
      paddingBottom: "0",
    },
  },
  "& .MuiDialog-paper": {
    borderRadius: "12.1px",
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
    display: "flex",
    justifyContent: "center",
    gap: "5%",
  },
  "& .MuiDialogTitle-root": {
    [theme.breakpoints.up("sm")]: {
      // margin: "20px 0",
      padding: "22px",
    },
  },
}));

const useStyles = makeStyles((theme) => ({
  containerContent: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    [theme.breakpoints.up("sm")]: {
      // margin: "20px 0",
    },
  },
  button: {
    // backgroundColor: "#B965D3",
    // borderRadius: "4px",
    // boxSizing: "borderBox",
    // outline: "0",
    // border: "0",
    // color: "#FFFFFF",
    // backgroundColor: "#9051b1",
    // borderRadius: "30px",
    // margin: "0 auto",
    // padding: "6px 16px",
    // width: "50%",
    // maxWidth: "180px",
    cursor: "pointer",
    textDecoration: "none",
    "&:hover": {
      // backgroundColor: "#B965D3 ",
    },
  },
  text: {
    // width: "364px",
    // height: "36px",
    // margin: "11px 63.7px 18px 35.3px",
    fontFamily: "Fredoka One",
    margin: 0,
    // fontFamily: "Fredoka One",
    fontSize: "24px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "0.23px",
    textAlign: "center",
    color: "#000",
    [theme.breakpoints.up("lg")]: {
      fontSize: "32px",
      // margin: "10px",
    },
  },
  containerVideo: {
    // width: "90%",
    // paddingTop: "16px",
    // paddingBottom: "16px",
    // [theme.breakpoints.up("lg")]: {
    //   padding: "32px",
    //   // margin: "20px 0",
    //   // width: "80%",
    //   // minWidth: "768px",
    // },
    justifyContent: "center",
    height: "Calc(100% - 5px)",
    overflow: "auto",
    [theme.breakpoints.down(1161)]: {
      // height: "Calc(100% - 65px)",
    },
    "& .video-js": {
      display: "flex",
      height: "100%",
      width: "100%",
      minWidth: "200px",

      // width: "285px",
      borderRadius:'0px',
      minHeight: "260px",
      [theme.breakpoints.up("md")]: {
        borderRadius: "25px",
        borderBottomLeftRadius: "0",
        borderBottomRightRadius: "0",
        height: "520px",
        width: "80%",
      },
      [theme.breakpoints.up("xl")]: {
        height: "620px",
        maxWidth: "1100px",
      },
      "& button": { color: " #dfdfdf;" },
      "& .vjs-control": {
        width: "2rem",
        [theme.breakpoints.up("md")]: {
          // width: "3rem",
        },
      },
    },
    "& .vjs-volume-panel.vjs-volume-panel-horizontal:hover": {
      width: "4.5rem",
    },
    "& .vjs-tech": {
      borderRadius:'0px',
      [theme.breakpoints.up("md")]: {
        borderRadius: "25px",
        borderBottomLeftRadius: "0",
        borderBottomRightRadius: "0",
      },
    },
  },
  video: {
    width: "100%",
    [theme.breakpoints.up("lg")]: {
      height: "480px",
    },
    // width: "95%",
  },
}));

const PurpleButton = styled(Button)(({ theme }) => ({
  color: "#FFFFFF",
  backgroundColor: "#9051b1",
  borderRadius: "30px",
  margin: "0 auto",
  width: "50%",
  maxWidth: "180px",
  textTransform: "unset",
  "&:hover": {
    backgroundColor: "#B965D3 ",
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, src, ...other } = props;


  return (
    <DialogTitle sx={{ m: 0, p: 3 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <GridCloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function NarrativaModalS60({
  open,
  handleClose,
  handleNext,
  path,
  nombre,
  id_actividad,
  id_actividad_inscripcion,
}) {
  // console.log(timestamp);
  let history = useHistory();
  const handleNavigateToNext = () => {
    handleNext();
    history.push({
      pathname: "/carta",
      state: { id_actividad, id_actividad_inscripcion },
    });
  };
  const [reproduced, setReproduced] = useState(false);
  const classes = useStyles();
  // const hours = 5;
  // const minutes = 33;
  return (
    <>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        maxWidth="lg"
        fullWidth={true}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        ></BootstrapDialogTitle>
        <DialogContent>
          <div className={classes.containerContent}>
            {/* <h1 className={classes.text}>{nombre || "Narrativa"}</h1> */}
            <Grid
              container
              direction="column"
              item
              sm={12}
              alignItems="center"
              className={classes.containerVideo}
            >
              <VideoPlayer src={path} onPlay={() => setReproduced(true)} />
            </Grid>
          </div>
        </DialogContent>
        <DialogActions>
          <PurpleButton
            className={classes.button}
            variant="contained"
            sx={{ marginBottom: "16px" }}
            onClick={handleNavigateToNext}
            disabled={!reproduced}
          >
            Aceptar
            {/* <Link className={classes.button} to="#">
              Aceptar
            </Link> */}
          </PurpleButton>
        </DialogActions>
      </BootstrapDialog>
    </>
  );
}
