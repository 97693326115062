import * as React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";

import { GridCloseIcon } from "@mui/x-data-grid";
import { Grid, makeStyles } from "@material-ui/core";
import axios from "../../../../../services/axios";
import ModalMessage from "./ModalMessage";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    // padding: theme.spacing(2),

    [theme.breakpoints.up("sm")]: {
      // margin: "20px 0",
      paddingTop: "0",
      paddingBottom: "0",
    },
  },
  "& .MuiDialog-paper": {
    borderRadius: "12.1px",
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
    display: "flex",
    justifyContent: "center",
    gap: "5%",
    marginBottom: "16px",
  },
  "& .MuiDialogTitle-root": {
    [theme.breakpoints.up("sm")]: {
      // margin: "20px 0",
      padding: "22px",
    },
  },
}));

const useStyles = makeStyles((theme) => ({
  containerContent: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    marginBottom: "20px",
    gap: "20px",
    [theme.breakpoints.up("sm")]: {
      // margin: "20px 0",
    },
  },
  containerButtons: {
    display: "flex",
    justifyContent: "center",
  },
  button: {
    backgroundColor: "#B965D3",
    borderRadius: "4px",
    boxSizing: "borderBox",
    outline: "0",
    border: "0",
  },
  text: {
    // width: "364px",
    // height: "36px",
    // margin: "11px 63.7px 18px 35.3px",
    fontFamily: "Lato",
    margin: 0,
    // fontFamily: "Fredoka One",
    fontSize: "16px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "0.23px",
    textAlign: "center",
    color: "#000",
    [theme.breakpoints.up("lg")]: {
      fontSize: "22px",
      margin: "20px",
      fontWeight: "600",
    },
  },
  title: {
    font: "28px Fredoka One ",
    margin: "0",
    textAlign: "center",
    color: (props) => props.textColor,
    [theme.breakpoints.up("xl")]: {
      fontSize: "50px",
    },
  },
  welcomeText: {
    font: " bold 18px Lato",
    textAlign: "center",
    width: "100%",
    maxWidth: "448px",
    color: (props) => props.smallText,
    [theme.breakpoints.up("xl")]: {
      fontSize: "24px",
    },
  },

  containerInput: {
    gap: "20px",
    marginBottom: "5%",
  },
  inputLoginPassword: {
    width: "80%",
    maxWidth: "280px",
    height: "45px",
    padding: "0 27px",
    borderRadius: "61px",
    border: "solid #F3CD52 3px ",
    background: "#FFFFFF",
    font: "Helvetica",
    fontSize: "16px",
    "&:focus": {
      outline: "none",
    },
    [theme.breakpoints.up("xl")]: {
      height: "81px",
      maxWidth: "421px",
      fontSize: "18px",
    },
  },
}));

const PurpleButton = styled(Button)(({ theme }) => ({
  color: "#FFFFFF",
  backgroundColor: "#9051b1",
  borderRadius: "30px",
  textTransform: "unset",
  // margin: "0 auto",
  width: "50%",
  maxWidth: "130px",
  "&:hover": {
    backgroundColor: "#B965D3 ",
  },
}));
const WitheButton = styled(Button)(({ theme }) => ({
  color: "#9051b1",
  // borderColor: "#9051b1",
  border: "1px solid #9051b1",
  backgroundColor: "#FFFFFF",
  borderRadius: "30px",
  textTransform:'unset',
  // margin: "0 auto",
  width: "50%",
  maxWidth: "130px",
  "&:hover": {
    backgroundColor: "#B965D310 ",
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 4 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <GridCloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const TeacherRecoveryModal = ({ open, handleClose }) => {
  const classes = useStyles();
  const [email, setEmail] = React.useState("");
  const [textModal, setTextModal] = React.useState("");
  const [modalMessageOpen, setModalMessageOpen] = React.useState(false);
  const checkEmail = (email) => {
    const parameters = /^[^@]+@[^@]+.[a-zA-Z]{2,}$/;
    // const parameters = /^(?=\w*\d)(?=.*[!@#$&*])(?=\w*[A-Z])(?=\w*[a-z])\S{8,16}$/;
    const result = parameters.test(email);
    return result;
  };

  const confirmRecoveryByEmail = () => {
    const body = {
      email,
      // correo: email,
    };

    const verificateEmail = checkEmail(email);
    if (verificateEmail) {
      axios
        .post("/session/recoverDocente", body)
        .then((response) => {
          if (response.status === 200) {
            setEmail("");
            handleClose();
            setTextModal(`Se ha enviado un correo electrónico a: ${email}`);
            setModalMessageOpen(true);
          }
        })
        .catch((error) => {
          handleClose();
          setTextModal("Ha ocurrido un error, inténtelo de nuevo más tarde");
          setModalMessageOpen(true);
        });
    } else {
      setTextModal("El correo electrónico que has introducido no es válido, revísalo e inténtalo nuevamente.");
      setModalMessageOpen(true);
    }
  };

  const handleModalMessageOpen = () => {
    setModalMessageOpen(!modalMessageOpen);
  };
  const handleEmail = (e) => {
    setEmail(e.target.value);
  };
  return (
    <>
      <BootstrapDialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} maxWidth="sm" fullWidth={true}>
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}></BootstrapDialogTitle>
        <DialogContent>
          <Grid container justifyContent="center" alignItems="center">
            <Grid
              container
              item
              direction="column"
              justifyContent="space-around"
              alignItems="center"
              className={classes.containerContent}
            >
              <h1 className={classes.title}>Recuperar contraseña</h1>
              <p className={classes.text}>Introduce tu correo electrónico para recuperar tu contraseña.</p>
              <input
                type="email"
                placeholder="Correo Electrónico"
                className={classes.inputLoginPassword}
                onChange={handleEmail}
                value={email}
              ></input>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions disableSpacing={true} className={classes.containerButtons}>
          <WitheButton onClick={handleClose} className={classes.button} variant="contained">
            Cancelar
          </WitheButton>
          <PurpleButton onClick={confirmRecoveryByEmail} className={classes.button} variant="contained">
            Confirmar
          </PurpleButton>
        </DialogActions>
      </BootstrapDialog>
      <ModalMessage open={modalMessageOpen} handleClose={handleModalMessageOpen} title={textModal} />
    </>
  );
};
export default TeacherRecoveryModal;
