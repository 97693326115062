import React                        from "react";
import { makeStyles,Grid}    from "@material-ui/core";
import Verde                        from "../../../../imgs/Verde.png";
import PracticasUno from "../../PracticasUno";

const useStyles = makeStyles(theme =>({
  evaluationsContainer: {
    height:'100%',
  },
  ContainerIzquierdo:{
    position:'relative',
    background:'#7BD7C6',
    backgroundImage: `url(${Verde})`,
    width:'97%',
    [theme.breakpoints.down(1161)]:{
      width:'100%',
    },
  },

     ContainerIntroduccion:{
      width:'100vw',
      height:'calc(100vh - 79.5px - 79.5px)',
      overflow: 'auto',
  },

  ContainerBarraInferior:{
    position:'relative',
    bottom:'0px',
    background:'#F3CD52',
    height:'79.51px',
    [theme.breakpoints.up(1920)]:{
      height:'8vh',
    },
    [theme.breakpoints.down(1161)]:{
      background: 'linear-gradient(280deg, rgba(182,79,136,1) 34%, rgba(246,100,75,1) 91%)'
    },
  },
  
  ContainerBarraDerecha:{
    background: 'linear-gradient(180deg, rgba(182,79,136,1) 34%, rgba(246,100,75,1) 91%)',
    width:'3%',
    height:'100vh',
    [theme.breakpoints.down(1161)]:{
      display:'none',
    },
  },
 CSesion:{
    position:'relative',
  },
  Sesion:{
    boxSizing:'borde-box',
    padding:' 0 0 0 44px',
    width: '768px',
    height: '43px',
    background: '#6D69C1',
    boxShadow: '0px 2px 5px #00000029',
    borderRadius:'15px',
    [theme.breakpoints.down(1161)]:{
      width: '678px',
    },
    [theme.breakpoints.down(678)]:{
      width: '375px',
      padding:' 0 0 0 10px',
    },
    [theme.breakpoints.down(376)]:{
      width: '350px',
      borderRadius:'0 0 10px 10px',
      padding:' 0 0 0 10px',
    },
    
    '& .Espacio':{
      height:'25px',
      margin:'0 10px 0 0 ',
      borderRadius:'10px',
      borderLeft:'6px solid #F6DE95',
      [theme.breakpoints.down(1161)]:{
        height:'20px',
      },
      [theme.breakpoints.down(678)]:{
        display:'none',
      },
    },

    '& .Nsesion':{
      margin:'0',
      width:'500px',
      textSlign: 'center',
      font: 'bold 20px Lato',
      letterSpacing: '0.36px',
      color: '#FFFFFF',
      [theme.breakpoints.down(1161)]:{
        font: 'bold 18px Lato',
        width:'425px',
      },
      [theme.breakpoints.down(678)]:{
        font: 'bold 14px Lato',
        width:'240px',
      },
      [theme.breakpoints.down(376)]:{
        width:'67%',
      },
      [theme.breakpoints.down(337)]:{
        font: 'bold 12px Lato',
        width:'62%',
      },
    },
    
    '& .Tipo':{
      margin:'0 0 0',
      font: 'bold 20px Lato',
      letterSpacing: '0.36px',
      color: '#FFFFFF',
      [theme.breakpoints.down(1161)]:{
        font: 'bold 18px Lato',
      },
[theme.breakpoints.down(678)]:{
        textAlign:'center',
        font: 'bold 14px Lato',
        width:'100px'
      },
      [theme.breakpoints.down(338)]:{
        font: 'bold 12px Lato',
        width:'91px'
      },
    },


  },
  Evaluacion:{
    position:'absolute',
    top:'0px',
    width:'310px',
    height:'79px', 
    background:' #FFFFFF 0% 0% no-repeat padding-box',
    boxShadow: '0px 3px 5px #00000029',
    border: '3px solid #71D1C1',
    borderTop:'none',
    borderRadius:'0 0 61px 61px',
    [theme.breakpoints.down(1161)]:{
      width: '266px',
      height: '65px', 
      borderRadius:'0 0 51px 51px',
    },
    [theme.breakpoints.down(678)]:{
      width: '110px',
      height: '55px', 
      borderRadius:'0 0 31px 31px',
    },
    
  },

  TextoDiagnostico:{
    margin:0,
    font:'28px Fredoka One',
    letterSpacing:' 0.21px',
    color: '#1DA698',
    [theme.breakpoints.down(678)]:{
      font:'18px Fredoka One',
    },
  },

   
    ContainerIntroduccionTexto:{
    height:'auto',
    '& .Introduccion':{
      margin:'0',
      font:'50px Fredoka One',
      textAlign: 'center',
      letterSpacing: '0.37px',
      color: '#575757',
      [theme.breakpoints.down(1161)]:{
        font:'46px Fredoka One',
      },
      [theme.breakpoints.down(678)]:{
        font:'30px Fredoka One',
      },
    },
    '& .TextoIntroduccion':{
      padding:'40px 0 50px 0',
      width:'1161px',
      margin:'0',
      font:'bold 24px lato',
      textAlign: 'center',
      letterSpacing: '0.43px',
      color: '#575757',
      [theme.breakpoints.down(1161)]:{
        font:'bold 22px lato',
        letterSpacing: '0.40px',
        width:'700px',
      },  
 [theme.breakpoints.down(678)]:{
        font:'bold 16px lato',
        letterSpacing: '0.40px',
        width:'80%',
      },
    }

  },
   BotonAvanza:{
    position:'absolute',
    bottom:'52px',
    right:'134px',
    textTransform:'none',
    textDecoration:'none',
    height:'62px',
    width:'279px',  
    background: '#7A54BA',
    boxShadow: '0px 3px 6px #00000029',
    borderRadius: '61px',
    color: '#FFFFFF',
    font: 'bold 20px lato ',
    '&:hover':{
        background: '#dd78fb',
      },
    [theme.breakpoints.down(1161)]:{
      position:'relative',
      bottom:'32px',
      left:'0',
      right:'0',
    },
  },

}));

const Introduction = (props) => {
  const classes = useStyles()
   const {activeStep, setActiveStep, handleNextStep} = props

  return (
    <Grid container direction="column" item sm={12} className={classes.evaluationsContainer}>
      <Grid container item className={classes.ContainerIzquierdo} justifyContent="center" >
        <Grid container item className={classes.ContainerIntroduccion}>
          <Grid container item justifyContent="center" xs={12} className={classes.CSesion}> 

              <Grid container item className={classes.Sesion} alignItems="center" >
                <Grid className="Espacio"></Grid>
                <p className="Nsesion ">Sesión 18</p>
                <div className="Espacio"></div>
                <p className="Tipo">Ética</p>
              </Grid>

              <Grid container item justifyContent="center" alignItems="center" className={classes.Evaluacion}>
                <p className={classes.TextoDiagnostico}>Actividad</p>
              </Grid>

          </Grid>

<Grid container item justifyContent="center"  className={classes.ContainerIntroduccionTexto} xs={12}> 
            <p className="TextoIntroduccion">
              <p className="Introduccion"> Introducción </p><br/>
              Ya has visto que cuando leemos un texto encontramos varias ideas diferentes, pero siempre están relacionadas con algunas ideas principales. Si entendemos bien las ideas más importantes comprenderemos el significado del texto completo.
              <br/><br/>
              La lectura de esta sesión te ayudará a meditar sobre un tema relevante practicando tu habilidad para encontrar las ideas principales. Verás que cuando descubras la idea principal notarás lo valiosa que es.
              <br/><br/>
              Antes de pasar a la siguiente pantalla, haz una pausa para realizar la práctica guiada.
            </p>
          </Grid>


        </Grid>
       

            <Grid container item sm={12} className={classes.ContainerBarraInferior} justifyContent="center">
            <PracticasUno
              activeStep={activeStep}
              setActiveStep={setActiveStep}
              handleNextStep={handleNextStep}
            />
        </Grid>

      </Grid>
      
      <Grid container item sm={12} className={classes.ContainerBarraDerecha}></Grid>
    </Grid>
  );
}

export default Introduction;
