import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({

    ContenedorLectura:{
        width:'502px',
                  [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },

    },
    ContainerTitulo:{
        margin:'0 0 34px 0',

        '& .Titulo':{
            letterSpacing: '0px',
            color: '#01B57E',
            margin:'0',
            textAlign:'center',
            font:'43px fredoka one',
            lineHeight:'52px',
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            margin:'0 0 36px 0',
            color:'#000',
            font:'18px lato',
            lineHeight :'28px',
            letterSpacing: '0.17px',
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .TextImg':{
            color:'#717476',
            margin:'20px 0 0 0',
            font:' 16px lato',
            lineHeight :'25px',
            letterSpacing: '0.15px',
            [theme.breakpoints.down(502)]:{
                font:' 11px lato',
                lineHeight :'14px',
                letterSpacing: '0.10px',
            },
        },

        '& .Imagen':{
            width:'100%',
        }, 

    }
}));

const Los_cuidados_de_las_mascotas = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
        
            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >
                        La cocina como arte                   
                    </h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                      <p className="Lectura">
                      Todas las disciplinas artísticas transforman los elementos con los que trabajan. Por 
                      ejemplo, en la arquitectura un ladrillo deja de ser sólo una piedra para formar parte 
                      de una iglesia o de un edificio. Del mismo modo, el metal con el que se crea una 
                      escultura deja de serlo para convertirse en el principal sostén de toda una obra. 
                      Cuando se crea un poema, las palabras de un lenguaje gastado pasan a articular ideas 
                      y estructuras novedosas. Esto ocurre en el arte culinario también, en el que la comida
                       es modificada para conseguir platillos especiales.<br/><br/> 
                       
                       La gastronomía tiene dos pilares, dos puntos sin los cuales sería solamente una 
                       mezcla de condimentos, sin ninguna particularidad. El primero son los ingredientes. 
                       Hay que recordar que éstos provienen de la naturaleza y que cada uno posee un 
                       sabor y forma determinados, que lo hace único y lo distingue de los demás. Los 
                       seres humanos utilizan las esencias de los alimentos y las combinan para obtener nuevas 
                       presentaciones, sabores y colores. <br/><br/>
                       
                       De igual manera, cada alimento tiene una presentación especial. Existen platillos que
                        son adornados con flores, plantas, salsas y otros recursos. Las maneras de mezclarlos 
                        y las variedades son tan diversas que hacen de cada plato una obra única y atractiva. 
                        Una pieza de arte. Así, la comida empieza a disfrutarse desde la presentación, se 
                        degusta primero con la vista, luego con el olfato y al final con el gusto. Es una 
                        experiencia total.

                      </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <Grid container justifyContent="center" >
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img3ro/Sesion56R/1.png" alt="Tarjeta" />
                        </Grid>
                        <Grid container justifyContent="center" >
                            <p className="TextImg">
                                Los cocineros crean arte combinando ingredientes y dándoles una nueva vida.
                            </p>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                            El segundo pilar es todo lo que rodea al hecho mismo de cocinar. Es una actividad tan 
                            antigua para la sociedad que se ha nutrido de muchas otras manifestaciones, algunas 
                            de las cuales ya son parte de la acción de consumir los guisos. Por ejemplo, las fiestas 
                            regionales y nacionales para las que se preparan recetas especiales, la convivencia en 
                            familia y las muestras folclóricas. Esto hace que comer forme parte de la cultura de 
                            toda una comunidad, que pase de ser una simple acción a convertirse en una 
                            tradición. <br/><br/>
                            
                            ¿Cuánta sabiduría culinaria se hereda? ¿Cuántos guisos representan a todo un 
                            pueblo? Días como Navidad, Año Nuevo, Semana Santa y otras fiestas llegan siempre 
                            con alimentos, que son parte de su esencia. Los cocineros complementan sus 
                            creaciones con las tradiciones y los productos de la comunidad. De la misma forma, 
                            las costumbres se mezclan con la comida, haciendo más profunda y sólida la cultura.
                        </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <Grid container justifyContent="center" >
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img3ro/Sesion56R/2.png" alt="Tarjeta" />
                        </Grid>
                        <Grid container justifyContent="center" >
                            <p className="TextImg">
                                La gastronomía nos reúne y nos regresa a nuestras raíces.
                            </p>
                        </Grid>
                    </Grid>
                </Grid>
        </Grid>
    </Grid>
    );
}
 
export default Los_cuidados_de_las_mascotas ;
