import { Button, Grid, makeStyles } from "@material-ui/core";
import React, { useContext, useEffect } from "react";
import DrawerContext from "../../../../../usability/DrawerContext";
import { useOnLoadImages } from "../../../../hooks/useOnLoadImages";
import Loader from "../../../../Loader/Loader";
import Drawer from "../../ReadingTools/Drawer";

const useStyles =  makeStyles (theme =>({
     Contenedor:{
        background:'#E9FBF9',
        width:'100vw',
        overflowX:'clip',
        [theme.breakpoints.down(1161)]:{
            background:'none',
           overflowX:'unset',
        },
        [theme.breakpoints.down(600)]:{
            marginTop: '79.51px',
        }
    },
  ContainerInfoLectura: {
    position: "relative",
    height: "86px",
    [theme.breakpoints.down(502)]:{
        height: "43px",
    },
    background: "#76D4C3",
    "& .BarraInfoLectura": {
      height: "86px",
      background: `Url(https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Barras/Azul.png)`,
        [theme.breakpoints.down(502)]:{
            height: "43px",
        },
    },
    "& .InfoLectura": {
      position: "absolute",
      height: "86px",
      width: "219px",
      boxSizing: "border-box",
      padding: "17.2px 0",
      background: "#FAFAFA",
      borderRadius: "50px",
      boxShadow: "2px 2px 2px 2px #00000029",
      zIndex: "4",
        [theme.breakpoints.down(502)]:{
            height: "43px",
            width: "109px",
            padding: "8.6px 0",
            borderRadius: "25px",
        },
    },
    "& .InfoLectura:before": {
      position: "absolute",
      display: "flex",
      boxSizing: "border-box",
      padding: "0 0 0 10px",
      justifyContent: "center",
      alignItems: "center",
      height: "39.88px",
      width: "39.88px",
      borderRadius: "50%",
      content: '""',
      boxShadow: "3px 0px .5px 0px #00000029",
      background: "#FAFAFA",
      left: "90px",
      top: "65px",
      transform: "rotate(0.25turn)",
      [theme.breakpoints.down(502)]:{
        left: "45px",
        top: "30px",
        height: "20.88px",
        width: "20.88px",
        },
    },
    "& .InfoLectura:after": {
      content: '""',
      height: "15px",
      width: "15px",
      borderTop: "2px solid #20A698",
      borderLeft: "2px solid #20A698",
      position: "absolute",
      bottom: "-10px",
      left: "Calc(50% - 8px)",
      transform: "rotate(0.625turn)",
      [theme.breakpoints.down(502)]:{
        height: "10px",
        width: "10px",
        left: "Calc(50% - 4.5px)",
        bottom: "-3px",
        borderTop: "1px solid #20A698",
        borderLeft: "1px solid #20A698",
        },
    },
    "& .Sesion": {
      borderLeft: "2px solid #D8D8D8",
    },
    "& .Dato": {
      margin: "0 0 5px 0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#20A698",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        margin: "0 0 2.5px 0",
        },
    },
    "& .Caracter": {
      margin: "0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#000000",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        },
    },
       '& .Regresar':{
        color: '#F2624F',
        border: '#F2624F 1px solid',
        height: '30px',
        width:'100px',
        borderRadius: '20px',
        letterSpacing: '0.14px',
        textTransform: 'none',
        background:'#FEFAF1',
        top:'calc(50% - 15px)',
        left:'5%',
        zIndex:4,
        [theme.breakpoints.down(502)]: {
          font:'bold 10px lato',
          width: "60px",
        },
      },
  },
    ContenedorLectura:{
        background:'#fff',
        width:'1160px',
        height:'100%',
        padding:'60px 168px 35px ',
        
        transform: (props) =>
        props.scale > 1 ? `scale(${props.scale}) translate(0px, 10%)` : `none`,
        [theme.breakpoints.down(1161)]:{
            width:'502px',
            padding:'60px 0 0 0',
          },
        [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },

        '& .Escritorio':{
            display:'block',
            [theme.breakpoints.down(1161)]:{
                display:'none'
            },
        },
        '& .Tablet':{
            display:'none',
            [theme.breakpoints.down(1161)]:{
                display:'block'
            },
        }
    },
    ContainerTitulo:{
        margin:'0 0 34px 0',
        [theme.breakpoints.down(1161)]:{
            margin:'0 0 34px 0',
        },
        '& .Titulo':{
            font:'48px fredoka one',
            letterSpacing: '0px',
            lineHeight:'58px',
            color: '#007ACB',
            margin:'0',
            textAlign:'center',
            [theme.breakpoints.down(1161)]:{
                font:'43px fredoka one',
                lineHeight:'52px',
            },
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            lineHeight : '30px',
            margin:'0 0 36px 0',
            font:'22px lato',
            color:'#000',
            letterSpacing: '0.20px',
            [theme.breakpoints.down(1161)]:{
                font:'18px lato',
                lineHeight :'28px',
                letterSpacing: '0.17px',
            },
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .SubTitulo':{
            font:'bold 30px lato',
            color:'#926DD1',
            letterSpacing:'.28px',
            lineHeight:'30px',
            margin:'0',
            [theme.breakpoints.down(1161)]:{
                lineHeight :'28px',
                font:'bold 26px lato',
                letterSpacing: '0.24px',
            },
            [theme.breakpoints.down(502)]:{
                font:'bold 18px lato',
                lineHeight :'24px',
                letterSpacing: '0.21px',
            },
        },
        '& .SubTitulo2':{
            font:'bold 26px lato',
            color:'#1DA698',
            letterSpacing:'.24px',
            lineHeight:'28px',
            margin:'0',
            [theme.breakpoints.down(1161)]:{
                font:'bold 22px lato',
                lineHeight :'24px',
                letterSpacing: '0.21px',
            },
            [theme.breakpoints.down(502)]:{
                font:'bold 18px lato',
                lineHeight :'20px',
                letterSpacing: '0.16px',
            },
        },
        '& .TextImg':{
            font:'20px lato',
            color:'#717476',
            letterSpacing:'.19px',
            lineHeight:'28px',
            margin:'24px 0 0 0',
            [theme.breakpoints.down(1161)]:{
                font:' 16px lato',
                lineHeight :'25px',
                letterSpacing: '0.15px',
            },
            [theme.breakpoints.down(502)]:{
                font:' 11px lato',
                lineHeight :'14px',
                letterSpacing: '0.10px',
            },
        },
        '& .Esp':{
            padding:'0 5px 0 0',
            margin:'0',
            [theme.breakpoints.down(1281)]:{
                padding:'0',
                margin:'0 0 36px 0',
            },
        },
        '& .Lectura2':{
            margin:'0',
        },

        '& .Fragmento':{
            boxSizing:'border-box',
            padding:'0 0 0 23px',
            [theme.breakpoints.down(1281)]:{
                padding:'0 '
            },
        },

        '& .Imagen':{
            [theme.breakpoints.down(1161)]:{
                width:'100%',
            },
        },  
        '& .Imagen2':{
            [theme.breakpoints.down(1161)]:{
                width:'75.89%',
            },
        }, 
      
    }
}));

const La_voz_de_Rigoberta = (props) => {

  const {activeStep, setActiveStep, handlePreviewStep, id_actividad} = props
  
  const handleContinue = () => {
    setActiveStep(activeStep - 1)
    handlePreviewStep()
  }

    const {
      handleSize,
    disabledZoom,
    closeZoom,
    handleZoom,
    scale
  }= useContext(DrawerContext);
  const [loading, setLoading] = React.useState(true);
  const wrapperRef = React.useRef(null);
  const imagesLoaded = useOnLoadImages(wrapperRef);

  const detectSize = () => {
    const lectura = document.getElementById("lectura");
    const newWidth = lectura?.offsetWidth;
    const newHeight = lectura?.offsetHeight;
    setLoading(false);
    if (lectura) handleSize(newHeight, newWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", detectSize);
    return () => {
      handleZoom()
      window.removeEventListener("resize", detectSize);
      closeZoom()
    };
  }, []);

  const classes = useStyles({ disabledZoom,scale });
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
             <Grid container item justifyContent="center" className={classes.ContainerInfoLectura}>
                       <Grid container className="BarraInfoLectura">
            <Button className="Regresar" onClick={handleContinue}>
                Regresar
            </Button>
        </Grid>
               <Grid container item className="InfoLectura">
                    <Grid container item direction="column" xs={6} justifyContent="center">
                        <p className="Dato">Grado</p>
                        <p className="Caracter">5</p>
                    </Grid>
                    <Grid container item direction="column" xs={6} justifyContent="center" className="Sesion">
                        <p className="Dato">Sesión</p>
                        <p className="Caracter">57</p>
                    </Grid>
               </Grid>
            </Grid>
            {!imagesLoaded && <Loader />}
        <Grid
            container
            item
            className={classes.ContenedorLectura}
            justifyContent="center"
            id="lectura"
            onLoad={detectSize}
            ref={wrapperRef}
        >
<Drawer id_lectura={id_actividad} />
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                        <h2 className="Titulo">La voz de Rigoberta</h2>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                       <p className='Lectura'>
                        Rigoberta Menchú siempre tuvo una voz, pero ésta, junto con la de los pueblos 
                        indígenas de América Latina, ha sido ignorada y silenciada durante mucho tiempo. 
                        Rigoberta Menchú es una indígena maya quiché de Guatemala. Su padre fue Vicente 
                        Menchú, un campesino y activista que defendió hasta la muerte sus derechos y los de 
                        su pueblo. Su madre, Juana Tum, fue una partera y curandera que mantuvo vivas las 
                        tradiciones de la cultura maya.
                       </p>                   
                    </Grid>
                    <Grid container justifyContent='center'>
                        <Grid container >
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion57/1.png" alt="Tarjeta" />
                        </Grid>
                        <Grid container >
                            <p className="TextImg">
                                Rigoberta Menchú Tum (Uspantán, 9 de enero de 1959) es una indígena guatemalteca,
                                miembro del grupo maya quiché, y defensora de los derechos humanos.
                            </p>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container >
                        <p className='Lectura Lectura2'>
                            Juana y Vicente transmitieron el espíritu de lucha a sus hijos, quienes también 
                            protestaron en contra de las injusticias cometidas por el gobierno guatemalteco 
                            hacia los pueblos indígenas. Éstas se han repetido de generación en generación desde 
                            hace mucho tiempo, pero se tornaron más severas cuando los presidentes decidieron 
                            modernizar Guatemala: les quitaron las tierras a los campesinos indígenas y se las 
                            entregaron a los terratenientes para que cultivaran productos que enriquecieran al 
                            país (como el café).
                        </p>
                    </Grid>
                </Grid>

                <Grid container item sm={12}  className={classes.Parrafo1} justifyContent="center" >
                    <Grid container item md={12} lg={6} >
                        <p className='Lectura Esp'>
                        En 1951 llegó al poder un nuevo presidente, Jacobo 
                        Arbenz, quien trató de devolverles las tierras a los 
                        campesinos indígenas. Antes de conseguirlo, Arbenz fue 
                        derrocado por el coronel Carlos Castillo y el ejército, 
                        con el respaldo de Estados Unidos. A partir de entonces 
                        Guatemala entró en una etapa en la que se perseguía y 
                        castigaba con violencia a la oposición política. Contra 
                        esta injusticia se levantó el Ejército Guerrillero de los
                        Pobres (<span style={{fontVariant: "all-small-caps"}}>EGP</span>). El gobierno afirmaba que las comunidades 
                        indígenas apoyaban al <span style={{fontVariant: "all-small-caps"}}>EGP</span>, y con ese pretexto destruyeron 
                        sus comunidades y los sacaron de sus tierras. Ante dicha 
                        situación, los campesinos indígenas comenzaron a reclamar su 
                        derecho a vivir en su tierra en paz.
                        </p>
                    </Grid>
                    <Grid container item md={12} lg={6} className='Fragmento'>
                        <Grid container justifyContent='center' >
                            <img className="Imagen2" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion57/2.png" alt="Tarjeta" />
                        </Grid>
                        <Grid container >
                            <p className="TextImg">
                                El presidente Arbenz impulsó una reforma agraria que iba contra los intereses de una empresa multinacional de fruta (la United Fruit Company), de la influencia de Estados Unidos y de los privilegios de las clases acomodadas guatemaltecas.
                            </p>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                       <p className='Lectura'>
                        La lucha afectó directamente a la familia Menchú Tum. El ejército asesinó a varios jóvenes, 
                        entre los cuales se encontraba el hermano menor de Rigoberta, Patrocino, de apenas 16 años. 
                        Por su parte, Vicente, su padre, murió, junto con otras 36 personas, en la matanza de la 
                        embajada española en Guatemala. La policía prendió fuego al lugar, porque un grupo de indígenas 
                        se encontraba allí solicitando la intervención de España para poner fin a los abusos en el 
                        norte de país. El destino de doña Juana fue igual de cruel: la secuestraron, torturaron y asesinaron.
                       </p>                   
                    </Grid>
                    <Grid container justifyContent='center'>
                        <Grid container >
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion57/3.png" alt="Tarjeta" />
                        </Grid>
                        <Grid container >
                            <p className="TextImg">
                                El 31 de enero de 1980 el jefe de policía, Pedro García Arredondo, dirigió la 
                                matanza en la embajada de España en Guatemala por orden del presidente Romeo Lucas.
                            </p>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                       <p className='Lectura'>
                        Rigoberta tuvo miedo de ser la siguiente. Parecía que su única alternativa era salir 
                        del país. Como muchas otras guatemaltecas, Rigoberta dejó la tierra que la vio nacer 
                        y se exilió en México durante años. <br/><br/>
                        
                        En el exilio, Rigoberta descubrió la potencia de su voz mientras recorría el mundo y 
                        denunciaba lo ocurrido en Guatemala. El mundo dejó de ignorarla. Se convirtió en la 
                        voz que, profunda y serena, reclama el reconocimiento de los derechos de los 
                        pueblos indígenas ignorados en todo el mundo, no sólo en Guatemala. Su voz nos 
                        invita a resolver los problemas a través de la palabra, y busca construir un mundo
                        más justo, plural y pacífico.
                       </p>                   
                    </Grid>
                    <Grid container justifyContent='center'>
                        <Grid container >
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion57/4.png" alt="Tarjeta" />
                        </Grid>
                        <Grid container >
                            <p className="TextImg">
                                Rigoberta Menchú recibió el Premio Nobel de la Paz en 1992, año en que se conmemoró el V 
                                Centenario del Descubrimiento de América. ¿Cómo denunciaba Rigoberta la injusticia que 
                                había vivido? ¿A qué nos referimos cuando decimos que Rigoberta tiene una voz propia y 
                                fuerte? ¡Investígalo!
                            </p>
                        </Grid>
                    </Grid>
                </Grid>
        </Grid>
        <Grid container item style={{ marginTop: 80}} ></Grid>
    </Grid>
    );
}
 
export default La_voz_de_Rigoberta;
