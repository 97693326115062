import { Button, Grid, makeStyles } from "@material-ui/core";
import React, { useContext, useEffect } from "react";
import DrawerContext from "../../../../../usability/DrawerContext";
import { useOnLoadImages } from "../../../../hooks/useOnLoadImages";
import Loader from "../../../../Loader/Loader";
import Drawer from "../../ReadingTools/Drawer";

const useStyles =  makeStyles (theme =>({
     Contenedor:{
        background:'#E9FBF9',
        width:'100vw',
        overflowX:'clip',
        [theme.breakpoints.down(1161)]:{
            background:'none',
           overflowX:'unset',
        },
        [theme.breakpoints.down(600)]:{
            marginTop: '79.51px',
        }
    },
  ContainerInfoLectura: {
    position: "relative",
    height: "86px",
    [theme.breakpoints.down(502)]:{
        height: "43px",
    },
    background: "#76D4C3",
    "& .BarraInfoLectura": {
      height: "86px",
      background: `Url(https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Barras/Azul.png)`,
        [theme.breakpoints.down(502)]:{
            height: "43px",
        },
    },
    "& .InfoLectura": {
      position: "absolute",
      height: "86px",
      width: "219px",
      boxSizing: "border-box",
      padding: "17.2px 0",
      background: "#FAFAFA",
      borderRadius: "50px",
      boxShadow: "2px 2px 2px 2px #00000029",
      zIndex: "4",
        [theme.breakpoints.down(502)]:{
            height: "43px",
            width: "109px",
            padding: "8.6px 0",
            borderRadius: "25px",
        },
    },
    "& .InfoLectura:before": {
      position: "absolute",
      display: "flex",
      boxSizing: "border-box",
      padding: "0 0 0 10px",
      justifyContent: "center",
      alignItems: "center",
      height: "39.88px",
      width: "39.88px",
      borderRadius: "50%",
      content: '""',
      boxShadow: "3px 0px .5px 0px #00000029",
      background: "#FAFAFA",
      left: "90px",
      top: "65px",
      transform: "rotate(0.25turn)",
      [theme.breakpoints.down(502)]:{
        left: "45px",
        top: "30px",
        height: "20.88px",
        width: "20.88px",
        },
    },
    "& .InfoLectura:after": {
      content: '""',
      height: "15px",
      width: "15px",
      borderTop: "2px solid #20A698",
      borderLeft: "2px solid #20A698",
      position: "absolute",
      bottom: "-10px",
      left: "Calc(50% - 8px)",
      transform: "rotate(0.625turn)",
      [theme.breakpoints.down(502)]:{
        height: "10px",
        width: "10px",
        left: "Calc(50% - 4.5px)",
        bottom: "-3px",
        borderTop: "1px solid #20A698",
        borderLeft: "1px solid #20A698",
        },
    },
    "& .Sesion": {
      borderLeft: "2px solid #D8D8D8",
    },
    "& .Dato": {
      margin: "0 0 5px 0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#20A698",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        margin: "0 0 2.5px 0",
        },
    },
    "& .Caracter": {
      margin: "0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#000000",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        },
    },
       '& .Regresar':{
        color: '#F2624F',
        border: '#F2624F 1px solid',
        height: '30px',
        width:'100px',
        borderRadius: '20px',
        letterSpacing: '0.14px',
        textTransform: 'none',
        background:'#FEFAF1',
        top:'calc(50% - 15px)',
        left:'5%',
        zIndex:4,
        [theme.breakpoints.down(502)]: {
          font:'bold 10px lato',
          width: "60px",
        },
      },
  },
    ContenedorLectura:{
        background:'#fff',
        width:'1160px',
        height:'100%',
                padding:'60px 168px 35px ',
        
        transform: (props) =>
        props.scale > 1 ? `scale(${props.scale}) translate(0px, 10%)` : `none`,
        [theme.breakpoints.down(1161)]:{
            width:'502px',
            padding:'60px 0 0 0',
          },
        [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },

        '& .Escritorio':{
            display:'block',
            [theme.breakpoints.down(1161)]:{
                display:'none'
            },
        },
        '& .Tablet':{
            display:'none',
            [theme.breakpoints.down(1161)]:{
                display:'block'
            },
        }
    },
    ContainerTitulo:{
        margin:'0 0 34px 0',
        [theme.breakpoints.down(1161)]:{
            margin:'0 0 34px 0',
        },
        '& .Titulo':{
            font:'48px fredoka one',
            letterSpacing: '0px',
            lineHeight:'58px',
            color: '#FF2B2B',
            margin:'0 0 5px 0',
            textAlign:'center',
            [theme.breakpoints.down(1161)]:{
                font:'43px fredoka one',
                lineHeight:'52px',
            },
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
        '& .Titulo2':{
            font:'bold 30px lato',
            letterSpacing: '.28px',
            lineHeight:'30px',
            color: '#FF2B2B',
            margin:'0',
            textAlign:'center',
            [theme.breakpoints.down(1161)]:{
                font:'bold 26px lato',
                letterSpacing: '.24px',
                lineHeight:'28px',
            },
            [theme.breakpoints.down(502)]:{
                font:'bold 22px lato',
                lineHeight:'24px',
            },
            [theme.breakpoints.down(350)]:{
                font:'bold 18px lato',
                lineHeight:'24px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        position:'relative',
        '& .Lectura':{
            lineHeight : '30px',
            margin:'0',
            font:'22px lato',
            color:'#000',
            letterSpacing: '0.20px',
            [theme.breakpoints.down(1161)]:{
                font:'18px lato',
                lineHeight :'28px',
                letterSpacing: '0.17px',
            },
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .Lectura2':{
            position:'absolute',
            width:'391px',
            zIndex:1,
            [theme.breakpoints.down(1161)]:{
                margin:'0 0 36px 0',
                position:'relative',
                width:'100%',
            },
        },
        '& .Titulo3':{
            font:'bold 30px lato',
            letterSpacing: '.28px',
            lineHeight:'30px',
            color: '#00ADB6',
            margin:'0 0 47px 0',
            textAlign:'center',
            [theme.breakpoints.down(1161)]:{
                font:'bold 26px lato',
                letterSpacing: '.24px',
                lineHeight:'28px',
            },
            [theme.breakpoints.down(502)]:{
                font:'bold 22px lato',
                lineHeight:'24px',
            },
            [theme.breakpoints.down(350)]:{
                font:'bold 18px lato',
                lineHeight:'24px',
            },
        },
        '& .ContainerImagen':{
            zIndex:0,
            [theme.breakpoints.down(1161)]:{
                width:'100%',
            },
        }, 
        '& .Imagen':{
            [theme.breakpoints.down(1161)]:{
                width:'100%',
            },
        } 

      
    }
}));

const Pablo_y_el_intraterrestre_Cap_6 = (props) => {

  const {activeStep, setActiveStep, handlePreviewStep, id_actividad} = props
  
  const handleContinue = () => {
    setActiveStep(activeStep - 1)
    handlePreviewStep()
  }

    const {
    handleSize,
    disabledZoom,
    closeZoom,
    handleZoom,
    scale
  }= useContext(DrawerContext);

  const [loading, setLoading] = React.useState(true);
  const wrapperRef = React.useRef(null);
  const imagesLoaded = useOnLoadImages(wrapperRef);

  const detectSize = () => {
    const lectura = document.getElementById("lectura");
    const newWidth = lectura?.offsetWidth;
    const newHeight = lectura?.offsetHeight;
    setLoading(false);
    if (lectura) handleSize(newHeight, newWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", detectSize);
    return () => {
      handleZoom()
      window.removeEventListener("resize", detectSize);
      closeZoom()
    };
  }, []);

  const classes = useStyles({ disabledZoom,scale });
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
             <Grid container item justifyContent="center" className={classes.ContainerInfoLectura}>
                <Grid container className="BarraInfoLectura">
                <Button className="Regresar" onClick={handleContinue}>
                    Regresar
                </Button>
            </Grid>
               <Grid container item className="InfoLectura">
                    <Grid container item direction="column" xs={6} justifyContent="center">
                        <p className="Dato">Grado</p>
                        <p className="Caracter">5</p>
                    </Grid>
                    <Grid container item direction="column" xs={6} justifyContent="center" className="Sesion">
                        <p className="Dato">Sesión</p>
                        <p className="Caracter">27</p>
                    </Grid>
               </Grid>
            </Grid>
            {!imagesLoaded && <Loader />}
        <Grid
            container
            item
            className={classes.ContenedorLectura}
            justifyContent="center"
            id="lectura"
            onLoad={detectSize}
            ref={wrapperRef}
        >
<Drawer id_lectura={id_actividad} />
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                        <h2 className="Titulo">Pablo y el intraterrestre</h2>
                        <h3 className='Titulo2'>Fragmento 3 (capítulos 4-5)</h3>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                       <p className='Lectura '>
                            ¡No podía esperar a llegar al punto de acecho! Si iba a estallar en algún lugar del 
                            mundo, quería hacerlo en la guarida o en el punto de acecho […]<br/><br/> 
                            
                            El punto de acecho era un resquicio entre la columna del extinguidor y la puerta del 
                            baño de niños. <br/><br/> 
                            
                            —Yo descubrí este lugar: está en un punto estratégico entre nuestros tres salones. 
                            Allí están los cuartos, los quintos y sexto B, el salón de Juan Carlos. Sexto A está 
                            después del corredor, hacia allá, pero eso no importa. A las niñas las ahuyenta el olor 
                            de los baños. Doblando esa esquina está la sala de descanso de los maestros. Nadie 
                            espera que los niños problemáticos estén cerca de la sala de maestros; por eso es un 
                            escondite perfecto: mantén a tus enemigos cerca y a tus amigos… más cerca. <br/><br/> 
                            
                            Pablo estaba seguro de que la frase no iba así, pero ya se había equivocado y no iba a 
                            corregirse frente a Brimlog. <br/><br/> 
                            
                            —Algo como tú y yo —acotó Brimlog.<br/><br/> 
                            
                            —Exactamente. <br/><br/> 
                            
                            Cuando Pablo y Brimlog llegaron al punto de acecho, el resto de los Calaveras ya 
                            estaban allí. Marco, larguirucho, amarillento, con su cara tan cuadrada como sus 
                            lentes, rellenaba su botella de agua en el filtro de los maestros. Hacía eso casi diario, 
                            aunque dos o tres veces por semana lo sorprendían y entonces terminaban todos en 
                            la oficina del director. Agustín se rascaba la nariz de bolita, demasiado pequeña para 
                            su cara redonda de gigantescos cachetes rojos y bronceados. Preparaba su dedo para 
                            una exploración de sus fosas nasales, espectáculo que Pablo esperaba no tener que 
                            ver. <br/><br/> 
                            
                            Juan Carlos, un niño enorme y pálido, con manos gigantes y ojos tristes, jugaba con 
                            un insecto. Era tan alto que tenía que encorvarse todo el tiempo para comunicarse 
                            con sus compañeros. Como se cansaba de doblarse, se la vivía recargado sobre los 
                            muebles y las paredes, lo cual le daba un aire de oso bonachón y somnoliento. En ese 
                            momento, quieto y en reposo, Juan Carlos se recargaba en la columna del extintor, 
                            lugar donde había encontrado al insecto con el que jugaba. Con pasitos rápidos, el 
                            animalejo movía todas sus patitas, recorriendo una y otra vez el mismo camino a lo 
                            largo de las manos de Juan Carlos. Para cuando el bicho finalmente llegaba de un 
                            extremo al otro de esa eterna hilera de dedos gruesos como salchichas, Juan Carlos 
                            ponía su otra mano enfrente y así las patitas diminutas continuaban caminando por 
                            siempre. <br/><br/> 
                            
                            Todos estaban discutiendo sobre el partido que Pablo no había visto por estar 
                            atorado en el tráfico y cuyo horrible marcador sólo había escuchado por la radio.<br/><br/> 

                            […]<br/><br/> 
                            
                            ―¿Y ese es tu dizque equipo, Pablo? ―preguntó Agustín, burlón, y todos voltearon a 
                            verlo. <br/><br/> 
                            
                            ―Era… ―agregó Marco continuando la broma. <br/><br/> 
                            
                            ―El día que se organicen, van a aniquilar a tus gatitos ―contestó amenazante Pablo. <br/><br/> 
                            
                            ―No hay posibilidades ―se burló Marco―. Tienen un goleador y un portero, pero 
                            eso no hace un equipo. Un equipo es más que dos jugadores buenos. <br/><br/> 
                            
                            ―Lo imposible pasa ―dijo Pablo, pensando que, si en ese momento él tenía a 
                            Brimlog en la cabeza, no era imposible que los Lobos Esteparios se estructuraran 
                            mejor. <br/><br/> 
                            
                            ―¡Je! Hasta tú dices que es imposible ―se rio Juan Carlos. <br/><br/> 
                            
                            Luego, Marco, Agustín y finalmente el mismo Pablo se unieron a la carcajada. <br/><br/> 
                            
                            ―Se vale soñar ―dijo Pablo encogiéndose de hombros. <br/><br/> 
                            
                            —No lo entiendo, humano Pablo, ¿tus propios colegas están criticando tus gustos? —
                            exclamó Brimlog francamente ofendido.<br/><br/> 
                            
                            —Es carrilla, Brimlog. El que se enoja pierde —contestó Pablo alegre y sonriente. <br/><br/> 
                            
                            Brimlog no tenía una traducción al intraterrestre para la palabra <em>carrilla</em>, pero le 
                            pareció que sonaba a algo horrible y problemático […]<br/><br/>  

                            En ese instante, un niño bajito y tan delgado como una escoba, dobló la esquina […]<br/><br/> 

                            ―¡Hey! ―dijo Pablo al niño flaco―. Éste es nuestro rincón. No puedes pasar por 
                            aquí.
                       </p>                   
                    </Grid>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12} >
                    <p className='Lectura Lectura2'>
                        ―Sólo voy al baño, Pablo. […]<br/><br/>

                        Juan Carlos se paró de golpe, irguiéndose 
                        imponente, pasando de un oso agradable a 
                        un oso enojado. En ese instante sonó la 
                        primera campana de entrada. 
                        Aprovechando la conmoción, el flaquito 
                        salió corriendo hacia su salón.<br/><br/>

                        ―¿Quién es ése? ―preguntó Juan Carlos a Pablo.
                    </p>
                    </Grid>
                    <Grid container justifyContent='center' className='ContainerImagen'>
                        <img className="Imagen Escritorio" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion27/1.png" alt="Tarjeta" />
                        <img className="Imagen Tablet" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion27/1R.png" alt="Tarjeta" />

                    </Grid>
                </Grid>
        </Grid>
        <Grid container item style={{ marginTop: 80}} ></Grid>
    </Grid>
    );
}
 
export default Pablo_y_el_intraterrestre_Cap_6;
