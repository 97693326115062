import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
    ContenedorLectura:{
        width:'502px',
        [theme.breakpoints.down(502)]:{
            width:'350px',
        },
    },
    ContainerTitulo:{
        margin:'0 0 34px 0',
        '& .Titulo':{
            letterSpacing: '0px',
            color: '#926DD1',
            margin:'0',
            textAlign:'center',
            font:'43px fredoka one',
            lineHeight:'52px',
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            margin:'0 0 36px 0',
            color:'#000',
            font:'18px lato',
            lineHeight :'28px',
            letterSpacing: '0.17px',
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .TextImg':{
            color:'#717476',
            margin:'0',
            font:' 16px lato',
            lineHeight :'25px',
            letterSpacing: '0.15px',
            [theme.breakpoints.down(502)]:{
                font:' 11px lato',
                lineHeight :'14px',
                letterSpacing: '0.10px',
            },
        },
        '& .Esp':{
            padding:'0',
        },
        '& .Esp2':{
            margin:'0',
            padding:'0 10px 0 0',
            [theme.breakpoints.down(502)]:{
                padding:'0',
            },
        },
        '& .Imagen':{
            width:'100%',
        },  
      
    }
}));

const Arte_en_movimiento = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>

            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >
                        Arte en movimiento
                    </h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container xs={12} alignContent="flex-end" justifyContent='flex-end'>
                      <p className="Lectura Esp">
                        ¿Alguna vez has escuchado 
                        una canción y sientes la 
                        necesidad de pararte y bailar? 
                        Seguramente sí, pues a lo 
                        largo de la historia los seres 
                        humanos hemos utilizado la 
                        música para expresar 
                        nuestros sentimientos y 
                        emociones. Esta conexión se 
                        expresa en el baile o la danza.
                      </p>
                    </Grid>
                    <Grid container xs={12} >
                    <Grid container justifyContent="center" >
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img1ro/Sesion39/1.png" alt="Tarjeta" />
                        </Grid>
                        <Grid container justifyContent="center" >
                            <p className="TextImg">
                            El flamenco es un baile típico de España.
                            </p>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                      <p className="Lectura">
                        La danza es una forma de arte que surge de nuestro cuerpo, desde el tope de nuestra 
                        cabeza hasta la punta de los dedos de los pies. Así, nos movemos completamente al 
                        ritmo de la música mientras conectamos nuestros sentimientos con la letra de la 
                        canción.
                      </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <Grid container justifyContent="center" >
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img1ro/Sesion39/2.png" alt="Tarjeta" />
                        </Grid>
                        <Grid container justifyContent="center" >
                            <p className="TextImg">
                            El hip hop es un baile que combina diferentes estilos.
                            </p>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container xs={6} alignContent="flex-end" justifyContent='flex-end'>
                      <p className="Lectura Esp2">
                      En la danza profesional, los bailarines 
                      expresan emociones a través de 
                      movimientos y vestuarios especiales. 
                      Por ejemplo, los bailarines de <em>ballet </em> 
                      cuentan una historia a partir de la 
                      coreografía. Así, sus movimientos 
                      expresan si los personajes están 
                      felices, enamorados o tristes, 
                      mientras que sus vestuarios nos dicen 
                      si son reyes, princesas, brujas o 
                      hechiceros.
                      </p>
                    </Grid>
                    <Grid container xs={6} >
                    <Grid container justifyContent="center" >
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img1ro/Sesion39/3.png" alt="Tarjeta" />
                        </Grid>
                        <Grid container justifyContent="center" >
                            <p className="TextImg">
                            Los bailarines cuentan historias a través de los movimientos.
                            </p>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                      <p className="Lectura">
                        No importa si bailas <em>ballet</em>, flamenco, cumbia o tahitiano, lo importante es que te 
                        dejes llevar por el movimiento, el ritmo y tus emociones. Así que levántate de la silla, 
                        sube el volumen y disfruta de tu canción favorita. ¡A bailar se ha dicho!
                      </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <Grid container justifyContent="center" >
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img1ro/Sesion39/4.png" alt="Tarjeta" />
                        </Grid>
                        <Grid container justifyContent="center" >
                            <p className="TextImg">
                            Déjate llevar y crea arte con tu cuerpo.
                            </p>
                        </Grid>
                    </Grid>
                </Grid>
        </Grid>
    </Grid>
    );
}
 
export default Arte_en_movimiento ;
