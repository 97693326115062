import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
     Contenedor:{
        background:'#E9FBF9',
        width:'100vw',
        overflowX:'clip',
        [theme.breakpoints.down(1161)]:{
            background:'none',
           overflowX:'unset',
        },
        [theme.breakpoints.down(600)]:{
            marginTop: '79.51px',
        }
    },
  ContainerInfoLectura: {
    position: "relative",
    height: "86px",
    [theme.breakpoints.down(502)]:{
        height: "43px",
    },
    background: "#76D4C3",
    "& .BarraInfoLectura": {
      height: "86px",
      background: `Url(https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Barras/Azul.png)`,
        [theme.breakpoints.down(502)]:{
            height: "43px",
        },
    },
    "& .InfoLectura": {
      position: "absolute",
      height: "86px",
      width: "219px",
      boxSizing: "border-box",
      padding: "17.2px 0",
      background: "#FAFAFA",
      borderRadius: "50px",
      boxShadow: "2px 2px 2px 2px #00000029",
      zIndex: "4",
        [theme.breakpoints.down(502)]:{
            height: "43px",
            width: "109px",
            padding: "8.6px 0",
            borderRadius: "25px",
        },
    },
    "& .InfoLectura:before": {
      position: "absolute",
      display: "flex",
      boxSizing: "border-box",
      padding: "0 0 0 10px",
      justifyContent: "center",
      alignItems: "center",
      height: "39.88px",
      width: "39.88px",
      borderRadius: "50%",
      content: '""',
      boxShadow: "3px 0px .5px 0px #00000029",
      background: "#FAFAFA",
      left: "90px",
      top: "65px",
      transform: "rotate(0.25turn)",
      [theme.breakpoints.down(502)]:{
        left: "45px",
        top: "30px",
        height: "20.88px",
        width: "20.88px",
        },
    },
    "& .InfoLectura:after": {
      content: '""',
      height: "15px",
      width: "15px",
      borderTop: "2px solid #20A698",
      borderLeft: "2px solid #20A698",
      position: "absolute",
      bottom: "-10px",
      left: "Calc(50% - 8px)",
      transform: "rotate(0.625turn)",
      [theme.breakpoints.down(502)]:{
        height: "10px",
        width: "10px",
        left: "Calc(50% - 4.5px)",
        bottom: "-3px",
        borderTop: "1px solid #20A698",
        borderLeft: "1px solid #20A698",
        },
    },
    "& .Sesion": {
      borderLeft: "2px solid #D8D8D8",
    },
    "& .Dato": {
      margin: "0 0 5px 0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#20A698",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        margin: "0 0 2.5px 0",
        },
    },
    "& .Caracter": {
      margin: "0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#000000",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        },
    },   },
    ContenedorLectura:{
        background:'#fff',
        width:'1160px',
        height:'100%',
        padding:'60px 168px 35px ',
        [theme.breakpoints.down(1161)]:{
            width:'502px',
            padding:'60px 0 0 0',
          },
        [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
        '& .ContainerDerechos':{
            bottom:'80px',
            [theme.breakpoints.down(1161)]:{
                display:'none',
            },
        },
        '& .Derechos':{
            textAlign:"center",
            margin:"0px",
            font:" 15px lato",
            color:"#717476",
        },
        '& .Escritorio':{
            display:'block',
            [theme.breakpoints.down(1161)]:{
                display:'none'
            },
        },
        '& .Tablet':{
            display:'none',
            [theme.breakpoints.down(1161)]:{
                display:'block'
            },
            [theme.breakpoints.down(502)]:{
                display:'none'
            },  
        },
        '& .Mobil':{
            display:'none',
            [theme.breakpoints.down(502)]:{
                display:'block'
            },
        }
    },
    ContainerTitulo:{
        margin:'0 0 34px 0',
        [theme.breakpoints.down(1161)]:{
            margin:'0 0 34px 0',
        },
        '& .Titulo':{
            font:'48px fredoka one',
            letterSpacing: '0px',
            lineHeight:'58px',
            color: '#6352E5',
            margin:'0',
            textAlign:'center',
            [theme.breakpoints.down(1161)]:{
                font:'43px fredoka one',
                lineHeight:'52px',
            },
            [theme.breakpoints.down(1161)]:{
                font:'38px fredoka one',
                lineHeight:'52px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            lineHeight : '30px',
            margin:'0 0 36px 0',
            font:'22px lato',
            color:'#000',
            letterSpacing: '0.20px',
            [theme.breakpoints.down(1161)]:{
                font:'18px lato',
                lineHeight :'28px',
                letterSpacing: '0.17px',
            },
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .Lectura2':{
            lineHeight :'28px',
            margin:'0',
            font:'20px lato',
            color:'#707070',
            letterSpacing: '0.20px',
            [theme.breakpoints.down(1161)]:{
                font:'16px lato',
                lineHeight :'21.5px',
                letterSpacing: '0.17px',
            },
            [theme.breakpoints.down(502)]:{
                font:'14px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .Lectura3':{
            margin:'0',
        },
        '& .SubTitulo':{
            font:'bold 30px lato',
            color:'#F6644B',
            letterSpacing:'.28px',
            lineHeight:'30px',
            margin:'0',
            [theme.breakpoints.down(1161)]:{
                lineHeight :'28px',
                font:'bold 26px lato',
                letterSpacing: '0.24px',
            },
            [theme.breakpoints.down(502)]:{
                font:'bold 18px lato',
                lineHeight :'24px',
                letterSpacing: '0.21px',
            },
        },
        '& .SubTitulo2':{
            font:'bold 26px lato',
            color:'#161CB1',
            letterSpacing:'.24px',
            lineHeight:'28px',
            margin:'0',
            [theme.breakpoints.down(1161)]:{
                font:'bold 22px lato',
                lineHeight :'24px',
                letterSpacing: '0.21px',
            },
            [theme.breakpoints.down(502)]:{
                font:'bold 18px lato',
                lineHeight :'20px',
                letterSpacing: '0.16px',
            },
        },
        '& .SubTitulo3':{
            font:' 26px Fredoka One',
            color:'#2B3990',
            lineHeight:'28px',
            margin:'0 10%',
            [theme.breakpoints.down(1161)]:{
                font:'22px Fredoka One',
                margin:'0 ',
                lineHeight :'24px',
            },
            [theme.breakpoints.down(502)]:{
                font:'18px Fredoka One',
                lineHeight :'20px',
            },
        },
        '& .TextImg':{
            font:'20px lato',
            color:'#717476',
            letterSpacing:'.19px',
            lineHeight:'30px',
            margin:'24px 0 0 0',
            [theme.breakpoints.down(1161)]:{
                font:' 16px lato',
                lineHeight :'25px',
                letterSpacing: '0.15px',
            },
            [theme.breakpoints.down(502)]:{
                font:' 11px lato',
                lineHeight :'14px',
                letterSpacing: '0.10px',
            },
        },
        '& .CuadroAmarillo':{
            position:'relative',
        },
        '& .CuadroRosa':{
            margin:'12px 0 0 0',
            position:'relative',
        },
        '& .Pos':{
            position:'absolute',
        },
        '& .Cuadro':{
            margin:'53px 0 0 0',
            [theme.breakpoints.down(502)]:{
                margin:'33px 0 0 0',
            },
        },
        '& .Amarillo':{
            height:'140px',
            width:'845px',
            [theme.breakpoints.down(1161)]:{
                height:'158px',
                width:'100%',
            },
            [theme.breakpoints.down(502)]:{
                height:'138px',
                width:'100%',
            },
            [theme.breakpoints.down(350)]:{
                height:'117px',
            },
        },
        '& .Rosa':{
            height:'245px',
            width:'702px',
            [theme.breakpoints.down(1161)]:{
                height:'283px',
                width:'460px',
            },
            [theme.breakpoints.down(502)]:{
                width:'90%',
            },
            [theme.breakpoints.down(350)]:{
                height:'232px',
            },
        },
        '& .Recta':{
            background:'#FEEFED',
            padding:'25px 0',
            borderRadius:'50px',
        },

        '& .Imagen':{
            [theme.breakpoints.down(1161)]:{
                width:'100%',
            },
        },  
        '& .Imagen2':{
            [theme.breakpoints.down(1161)]:{
                width:'88%',
            },
        }, 
        '& .Imagen3':{
            [theme.breakpoints.down(1161)]:{
                width:'60.6%',
            },
        }, 
      
    },
    Especial:{
        margin:'0',
    },
}));

const Infinitos_dentro_de_infinitos = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
             <Grid container item justifyContent="center" className={classes.ContainerInfoLectura}>
               <Grid container className="BarraInfoLectura"></Grid>
               <Grid container item className="InfoLectura">
                    <Grid container item direction="column" xs={6} justifyContent="center">
                        <p className="Dato">Grado</p>
                        <p className="Caracter">5</p>
                    </Grid>
                    <Grid container item direction="column" xs={6} justifyContent="center" className="Sesion">
                        <p className="Dato">Sesión</p>
                        <p className="Caracter">10</p>
                    </Grid>
               </Grid>
            </Grid>

            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                        <h2 className="Titulo Escritorio">Infinitos dentro de infinitos</h2>
                        <h2 className="Titulo Tablet">Infinitos dentro <br/>de infinitos</h2>

                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                       <p className='Lectura'>
                        La idea de que algo no tiene fin ha existido desde hace miles de años. Aunque los 
                        antiguos griegos no fueron la primera civilización en hablar de lo infinito, les 
                        interesaba mucho este concepto porque les planteaba ciertas paradojas a filósofos y matemáticos.
                       </p>                   
                    </Grid>
                    <Grid container justifyContent='center' className='CuadroAmarillo'>
                        <img className="Escritorio Amarillo" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion10/6.png" alt="Tarjeta" />
                        <img className="Tablet Amarillo" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion10/6R.png" alt="Tarjeta" />
                        <img className="Mobil Amarillo" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion10/6R.png" alt="Tarjeta" />
                        <img  src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion10/9.png" alt="Tarjeta" />
                        <p className='Pos Lectura Cuadro Escritorio'>
                            Tenemos una paradoja cuando consideramos que dos ideas son verdaderas, 
                            <br/>pero cada una parece contradecir a la otra.
                        </p>
                        <p className='Pos Lectura Cuadro Tablet'>
                            Tenemos una paradoja cuando consideramos que dos <br/>
                            ideas son verdaderas, pero cada una parece <br/>contradecir a la otra.
                        </p>
                        <p className='Pos Lectura Cuadro Mobil'>
                            Tenemos una paradoja cuando consideramos <br/>  
                            que dos ideas son verdaderas, pero cada <br/>
                            una parece contradecir a la otra.
                        </p>
                    </Grid>
                    <Grid container justifyContent='center' className='CuadroRosa'>
                        <img className="Escritorio Rosa" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion10/5.png" alt="Tarjeta" />
                        <img className="Tablet Rosa" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion10/7R.png" alt="Tarjeta" />
                        <img className="Mobil Rosa" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion10/7R.png" alt="Tarjeta" />

                        <p className='Pos Lectura2 Cuadro Escritorio'>
                            En la literatura (sobre todo en poesía) existe un tipo de paradoja llamado<br/>
                            <em>oxímoron</em>. Te invitamos a investigar en qué consiste y para qué se usa. <br/><br/>

                            En matemáticas y en lógica las paradojas nos alertan de que alguna<br/>
                            parte de nuestro razonamiento es incorrecta. En la literatura admiramos <br/>
                            las paradojas, ¡pero en las matemáticas debemos resolverlas!
                        </p>
                        <p className='Pos Lectura2 Cuadro Tablet'>
                            En la literatura (sobre todo en poesía) existe un tipo de <br/>
                            paradoja llamado <em>oxímoron</em>. Te invitamos a investigar <br/>
                            en qué consiste y para qué se usa. <br/><br/>
                            
                            En matemáticas y en lógica las paradojas nos alertan <br/>
                            de que alguna parte de nuestro razonamiento es <br/>
                            incorrecta. En la literatura admiramos las paradojas, <br/>
                            ¡pero en las matemáticas debemos resolverlas!
                        </p>
                        <p className='Pos Lectura2 Cuadro Mobil'>
                            En la literatura (sobre todo en poesía)  <br/>
                            existe un tipo de paradoja llamado <em>oxímoron</em>. <br/>
                            Te invitamos a investigar en qué consiste<br/>
                            y para qué se usa. <br/><br/>
                            
                            En matemáticas y en lógica las paradojas <br/>
                            nos alertan  de que alguna parte de nuestro<br/>
                            razonamiento es incorrecta. En la literatura <br/>
                            admiramos las paradojas, ¡pero en las <br/>
                            matemáticas debemos resolverlas!
                        </p>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container >
                        <p className='Lectura'>
                            <h3 className='SubTitulo'>Números naturales</h3><br/>

                            Algunas paradojas de lo infinito tienen que ver con distintos tipos de números. Los 
                            que normalmente usamos para contar (1, 2, 3, 4…) se llaman <em>números enteros positivos</em> y, junto 
                            con el 0, son <em>números naturales</em>. Con ellos podemos describir objetos. 
                            Por ejemplo, podemos decir: <em>aquí hay cuatro manzanas</em>.<br/><br/>
                            
                            <h3 className='SubTitulo'>Las paradojas de lo infinito</h3><br/>
                            
                            Los números naturales son infinitos: siempre habrá uno más alto, uno que le siga a 
                            cualquier cantidad que te puedas imaginar. Pero estos números pueden ser pares o 
                            impares. Los números pares también son infinitos, porque siempre habrá un número 
                            par mayor que cualquier número par que te imagines. Lo mismo con los impares.<br/><br/>
                            
                            Como no todos los números naturales son pares, debe haber menos números pares que
                            naturales. Pero ¿cómo puede haber menos pares si ambas clases de número son 
                            infinitas? <br/><br/>
                             
                            Por otro lado, los números enteros pueden ser positivos y negativos, ¡y los negativos
                            también son infinitos! Entonces, tendría que haber muchos más números enteros que 
                            números naturales. ¿Cómo es eso posible si los números naturales son infinitos? 
                            ¿Cómo es que algo que no tiene fin puede ser menor que otra cantidad?<br/><br/>
                             
                            <h3 className='SubTitulo2'>La recta cortada</h3><br/>
                            
                            Para la tercera paradoja usaremos una recta numérica. En la parte superior están los 
                            números que hemos estudiado hasta ahora: los enteros positivos, los enteros 
                            negativos y el 0. Hagamos un acercamiento a lo que hay entre el 0 y el 1.
                        </p> 
                    </Grid>
                    <Grid container justifyContent='center' alignContent='center' className='Recta' >
                        <Grid container justifyContent='center' >
                            <img className="Imagen2" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion10/1.png" alt="Tarjeta" />
                        </Grid>
                    </Grid>

                </Grid>

                <Grid container item sm={12} className={`${classes.Parrafo1}  ${classes.Especial}`} justifyContent="center" >
                    <Grid container >
                        <p className='Lectura Lectura3'>
                            Como ves, es posible dividir en décimos cada unidad. También sería posible dividirla en
                            doceavos, quinceavos, centésimos o milésimos. Desde luego, cada una de las partes 
                            que saliera de esta división también podríamos dividirla. Los números fraccionarios 
                            que hay entre dos números enteros también son infinitos. Uno imaginaría que debe 
                            haber muchos más números fraccionarios entre el 0 y el 1 000 que entre el 0 y el 1, 
                            pero ¿cómo podemos compararlos si en ambos casos hay una infinidad de números?<br/><br/>
                            
                            <h3 className='SubTitulo'>La teoría de conjuntos tiene la solución</h3><br/>
                            
                            Por todo esto, autores como Zenón (siglo V a. n. e.) y Aristóteles 
                            (siglo IV a. n. e.) consideraban que el infinito era un concepto lleno 
                            de misterios.
                        </p>
                    </Grid>
                    <Grid container justifyContent='center'  >
                        <Grid container justifyContent='center' >
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion10/4.png" alt="Tarjeta" />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container >
                        <p className='Lectura'>
                            Resolver estas paradojas requirió siglos de estudio. La respuesta 
                            llegó cuando, en 1874, el matemático alemán Georg Cantor desarrolló la 
                            <em> teoría de conjuntos</em>. <br/><br/>

                            Imagina que metemos en una bolsa gigante todas las peras del mundo, en otra 
                            todas las manzanas y así sucesivamente hasta tener guardadas todas las 
                            frutas en distintas bolsas según su tipo. Luego metemos esas bolsas en 
                            una más grande, en la que cupieran todas. Esa bolsa sería, entonces, el 
                            <em> conjunto</em> de las frutas. Dentro, cada bolsa sería un <em>subconjunto</em> y cada 
                            fruta individual sería un elemento. No importa que los <em>elementos</em> dentro 
                            de ese conjunto sean muchísimos, son finitos (porque las frutas no son 
                            infinitas). Aquí un ejemplo de polígonos.
                        </p>
                    </Grid>
                    <Grid container justifyContent='center' >
                        <Grid container xs={12} justifyContent='space-between' alignItems="center">
                            <h3 className='SubTitulo3'>Polígonos</h3>
                            <h3 className='SubTitulo3'>Polígonos<br/>regulares</h3>
                        </Grid>
                        <img className="Imagen2" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion10/2.png" alt="Tarjeta" />
                        <Grid container justifyContent='center'  >
                            <p className='TextImg'>
                            Ejemplo de conjunto y subconjunto de polígonos.
                            </p>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container >
                        <p className='Lectura'>
                            Cantor descubrió que habíamos estado cometiendo un error: tratábamos a los conjuntos
                            infinitos (como el de los números naturales) como si fueran finitos (como el de 
                            las frutas). Argumentó que en los conjuntos infinitos un subconjunto puede tener 
                            la misma cantidad de elementos que el conjunto en el que está. ¿Te puedes imaginar 
                            eso? Aunque las ideas de Cantor no fueron valoradas en su tiempo, hoy son la base 
                            de las matemáticas.
                        </p>
                    </Grid>
                    <Grid container justifyContent='center' >
                        <img className="Imagen3" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion10/3.png" alt="Tarjeta" />
                    </Grid>
                </Grid>

            <Grid container item xs={12} justifyContent="center" className="ContainerDerechos">
                <p className="Derechos">
                    © 2022. Todos los derechos reservados, Pearson, Circuito de lectores.
                </p>                
            </Grid>
        </Grid>
    </Grid>
    );
}
 
export default Infinitos_dentro_de_infinitos;
