import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
   ContenedorLectura:{
        width:'502px',
         [theme.breakpoints.down(502)]:{
           width:'350px',
         },
   },
    ContainerTitulo:{
        margin:'0 0 46px 0',
        '& .Titulo':{
            color: '#E1AF10',
            margin:'0',
            textAlign:'center',
            font:'43px fredoka one',
            lineHeight:'52px',
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            margin:'0 0 36px 0 ',
            color:'#000',
            font:'18px lato',
            lineHeight :'28px',
            letterSpacing: '0.17px',
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .SubTitulo':{
            color:'#0C879F',
            margin:'0',
            font:'bold 22px lato',
            lineHeight :'24px',
            letterSpacing: '0.21px',
            [theme.breakpoints.down(502)]:{
                font:'bold 18px lato',
                lineHeight :'20px',
                letterSpacing: '0.16px',
            },
        },
        "& .SubTitulo2": {
            color: "#E1AF10",
            margin: "0",
            font: "bold 22px lato",
            lineHeight: "24px",
            letterSpacing: "0.21px",
            [theme.breakpoints.down(502)]: {
              font: "bold 18px lato",
              lineHeight: "20px",
              letterSpacing: "0.16px",
            },
          },
        '& .Cuadro':{
            position:'relative',
        },
        '& .Esp':{
            position:'absolute',
            margin:'53px 0 0 0',
            width:'85.25%',
            [theme.breakpoints.down(502)]:{
                margin:'15px 0 0 0',
            },
        },
        
        '& .Imagen':{
            width:'100%'
        },
    },
    

}));

const Qué_son_las_artes_plásticas = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >
                        ¿Qué son las <br/>artes plásticas?
                    </h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container item  sm={12}>
                        <p className="Lectura">
                            Cuando escuchas la palabra <em>plástico</em>, probablemente piensas en los múltiples objetos 
                            elaborados con este material. Pero las <em>artes plásticas</em> no están del todo relacionadas 
                            con ese producto flexible y de uso cotidiano. A continuación, conocerás sobre las 
                            distintas técnicas para producir piezas artísticas.
                        </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <img className='Imagen' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img3ro/Sesion39/1.png"  alt="" />
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} >
                    <Grid container item sm={12}>
                        <p className="Lectura ">
                            <h3 className='SubTitulo'>Transformar para embellecer</h3><br/> 
                            Se entiende por <em>arte</em> toda actividad humana dedicada a la creación de cosas bellas. La 
                            finalidad de un artista es convertir un objeto común en algo bello a través de 
                            diferentes procesos. Existen siete disciplinas: pintura, escultura, dibujo, grabado, 
                            arquitectura, orfebrería y cerámica, conocidas como <em>artes plásticas</em>.
                        </p>
                    </Grid>
                    <Grid container item className='Cuadro' justifyContent='center'>
                        <p className='Lectura Esp'>
                            Las artes plásticas forman parte de las <em>bellas artes </em>junto con la música, la 
                            danza, la literatura y el teatro. Estas ramas tienen en común que expresan un 
                            mensaje y que sus elementos son armónicos.
                        </p>
                        <img className='Imagen ' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img3ro/Sesion39/3R.png"  alt="" />
                    </Grid>
                       
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent='center'>
                    <Grid container item sm={12}>
                        <p className="Lectura ">
                            <h3 className="SubTitulo">El aspecto de la forma</h3><br/>
                            
                            La finalidad del arte es dar un mensaje. Por ejemplo, la pintura <em>El grito</em>, hecha por 
                            Edvard Munch, transmite desesperación y soledad. Cada creador emplea un sinfín de 
                            técnicas y habilidades para comunicar su idea, como las que se explican a 
                            continuación. <br/><br/>
                            
                            <h3 className="SubTitulo2">Pintura</h3><br/>
                            
                            Consiste en distribuir un colorante artificial o natural sobre una superficie, como 
                            tela, papel, madera o piedra. Este método ha acompañado a la humanidad desde hace 
                            siglos, prueba de ello son las pinturas rupestres que hay en las cuevas. <br/><br/>
                            
                            <h3 className='SubTitulo2'>Escultura</h3><br/>
                            
                            Se basa en manejar elementos para construir una figura en tercera dimensión. Suele 
                            utilizar madera, metal, plástico, piedra o cartón. Hay muchas técnicas de escultura y 
                            el tamaño de las obras es variado.
                        </p>
                    </Grid>
                    <Grid container item  justifyContent='center'>
                        <img className='Imagen' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img3ro/Sesion39/2.png"  alt="" />
                    </Grid>
                    
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent='center'>
                    <Grid container item sm={12}>
                        <p className="Lectura ">
                            <h3 className='SubTitulo2'>Dibujo</h3><br/>
                            
                            Su base son los trazos, que pueden hacerse sobre cualquier superficie. Casi siempre, 
                            se reproducen a partir de líneas suaves y figuras geométricas. Sus piezas están 
                            elaboradas con un solo color o con lápices. Las demás obras plásticas son creadas a 
                            partir de dibujos. <br/><br/>
                            
                            <h3 className='SubTitulo2'>Grabado</h3><br/>
                            
                            Lo conforman dos procesos. Primero, se talla una imagen sobre metal, piedra o 
                            madera. Después, se impregna con tinta y se coloca sobre papel o tela. Al separar 
                            ambas partes, la imagen quedará marcada gracias a la tinta. Se trata de una 
                            impresión o sello, como los que utilizan los profesores para calificar las tareas.<br/><br/>
                            
                            <h3 className='SubTitulo2'>Arquitectura</h3><br/>
                            
                            Es una muestra de lo que buscan las artes plásticas: la transformación de materiales. 
                            En ella se toman piedras, madera, metal o vidrio y se los convierte en objetos
                            admirables, como monumentos, iglesias, palacios o casas. Además, en una pieza 
                            arquitectónica intervienen otras disciplinas. Por ejemplo, el diseño, que se encarga 
                            de hacer los planos de las construcciones. <br/><br/>
                            
                            Como ves, las artes plásticas buscan causar reflexiones sobre la belleza. ¿Qué 
                            disciplina te parece más llamativa? No te quedes con las ganas y aprende lo 
                            necesario para convertirte en un artista plástico. ¡Inténtalo!
                        </p>
                    </Grid>
                </Grid>
        </Grid>
    </Grid>
    );
}
 
export default Qué_son_las_artes_plásticas;
