import React from "react";
import { makeStyles, Grid } from "@material-ui/core";
import Avatars from "../Avatars/Avatars";

// Import NavBar, BottomBar

const useStyles = makeStyles({});

function ContenedorAvatars() {
  const classes = useStyles();
  return (
    <Grid container>
      <Grid container item className={classes.grid1} sm={12}></Grid>
      <Grid container item className={classes.grid2} sm={12}>
        <Grid item className={classes.grid2Left} sm={12}>
          <Avatars />
        </Grid>
      </Grid>
    </Grid>
  );
}
export default ContenedorAvatars;
