import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
     Contenedor:{
        background:'#E9FBF9',
        width:'100vw',
        overflowX:'clip',
        [theme.breakpoints.down(1161)]:{
            background:'none',
           overflowX:'unset',
        },
        [theme.breakpoints.down(600)]:{
            marginTop: '79.51px',
        }
    },
  ContainerInfoLectura: {
    position: "relative",
    height: "86px",
    [theme.breakpoints.down(502)]:{
        height: "43px",
    },
    background: "#76D4C3",
    "& .BarraInfoLectura": {
      height: "86px",
      background: `Url(https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Barras/Azul.png)`,
        [theme.breakpoints.down(502)]:{
            height: "43px",
        },
    },
    "& .InfoLectura": {
      position: "absolute",
      height: "86px",
      width: "219px",
      boxSizing: "border-box",
      padding: "17.2px 0",
      background: "#FAFAFA",
      borderRadius: "50px",
      boxShadow: "2px 2px 2px 2px #00000029",
      zIndex: "4",
        [theme.breakpoints.down(502)]:{
            height: "43px",
            width: "109px",
            padding: "8.6px 0",
            borderRadius: "25px",
        },
    },
    "& .InfoLectura:before": {
      position: "absolute",
      display: "flex",
      boxSizing: "border-box",
      padding: "0 0 0 10px",
      justifyContent: "center",
      alignItems: "center",
      height: "39.88px",
      width: "39.88px",
      borderRadius: "50%",
      content: '""',
      boxShadow: "3px 0px .5px 0px #00000029",
      background: "#FAFAFA",
      left: "90px",
      top: "65px",
      transform: "rotate(0.25turn)",
      [theme.breakpoints.down(502)]:{
        left: "45px",
        top: "30px",
        height: "20.88px",
        width: "20.88px",
        },
    },
    "& .InfoLectura:after": {
      content: '""',
      height: "15px",
      width: "15px",
      borderTop: "2px solid #20A698",
      borderLeft: "2px solid #20A698",
      position: "absolute",
      bottom: "-10px",
      left: "Calc(50% - 8px)",
      transform: "rotate(0.625turn)",
      [theme.breakpoints.down(502)]:{
        height: "10px",
        width: "10px",
        left: "Calc(50% - 4.5px)",
        bottom: "-3px",
        borderTop: "1px solid #20A698",
        borderLeft: "1px solid #20A698",
        },
    },
    "& .Sesion": {
      borderLeft: "2px solid #D8D8D8",
    },
    "& .Dato": {
      margin: "0 0 5px 0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#20A698",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        margin: "0 0 2.5px 0",
        },
    },
    "& .Caracter": {
      margin: "0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#000000",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        },
    },   },
    ContenedorLectura:{
        background:'#fff',
        width:'1160px',
        height:'100%',
        padding:'60px 168px 35px ',
        [theme.breakpoints.down(1161)]:{
            width:'502px',
            padding:'60px 0 0 0',
          },
        [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
        '& .ContainerDerechos':{
            bottom:'80px',
            [theme.breakpoints.down(1161)]:{
                display:'none',
            },
        },
        '& .Derechos':{
            textAlign:"center",
            margin:"0px",
            font:" 15px lato",
            color:"#717476",
        },
        '& .Escritorio':{
            display:'block',
            [theme.breakpoints.down(1161)]:{
                display:'none'
            },
        },
        '& .Tablet':{
            display:'none',
            [theme.breakpoints.down(1161)]:{
                display:'block'
            },
        }
    },
    ContainerTitulo:{
        margin:'0 0 34px 0',
        [theme.breakpoints.down(1161)]:{
            margin:'0 0 34px 0',
        },
        '& .Titulo':{
            font:'48px fredoka one',
            letterSpacing: '0px',
            lineHeight:'58px',
            color: '#FF0000',
            margin:'0',
            textAlign:'center',
            [theme.breakpoints.down(1161)]:{
                font:'43px fredoka one',
                lineHeight:'52px',
            },
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            lineHeight : '30px',
            margin:'0 0 36px 0',
            font:'22px lato',
            color:'#000',
            letterSpacing: '0.20px',
            [theme.breakpoints.down(1161)]:{
                font:'18px lato',
                lineHeight :'28px',
                letterSpacing: '0.17px',
            },
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .SubTitulo':{
            font:'bold 30px lato',
            color:'#926DD1',
            letterSpacing:'.28px',
            lineHeight:'30px',
            margin:'0',
            [theme.breakpoints.down(1161)]:{
                lineHeight :'28px',
                font:'bold 26px lato',
                letterSpacing: '0.24px',
            },
            [theme.breakpoints.down(502)]:{
                font:'bold 18px lato',
                lineHeight :'24px',
                letterSpacing: '0.21px',
            },
        },
        '& .SubTitulo2':{
            font:'bold 26px lato',
            color:'#1DA698',
            letterSpacing:'.24px',
            lineHeight:'28px',
            margin:'0',
            [theme.breakpoints.down(1161)]:{
                font:'bold 22px lato',
                lineHeight :'24px',
                letterSpacing: '0.21px',
            },
            [theme.breakpoints.down(502)]:{
                font:'bold 18px lato',
                lineHeight :'20px',
                letterSpacing: '0.16px',
            },
        },
        '& .TextImg':{
            font:'20px lato',
            color:'#717476',
            letterSpacing:'.19px',
            lineHeight:'30px',
            margin:'24px 0 0 0',
            [theme.breakpoints.down(1161)]:{
                font:' 16px lato',
                lineHeight :'25px',
                letterSpacing: '0.15px',
            },
            [theme.breakpoints.down(502)]:{
                font:' 11px lato',
                lineHeight :'14px',
                letterSpacing: '0.10px',
            },
        },

        '& .Imagen':{
            [theme.breakpoints.down(1161)]:{
                width:'100%',
            },
        },  
      
    }
}));

const Hacia_un_mundo_feliz = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
             <Grid container item justifyContent="center" className={classes.ContainerInfoLectura}>
               <Grid container className="BarraInfoLectura"></Grid>
               <Grid container item className="InfoLectura">
                    <Grid container item direction="column" xs={6} justifyContent="center">
                        <p className="Dato">Grado</p>
                        <p className="Caracter">5</p>
                    </Grid>
                    <Grid container item direction="column" xs={6} justifyContent="center" className="Sesion">
                        <p className="Dato">Sesión</p>
                        <p className="Caracter">9</p>
                    </Grid>
               </Grid>
            </Grid>

            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                        <h2 className="Titulo">¿Hacia un mundo feliz?</h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                       <p className='Lectura'>
                       Imagina un mundo donde el desarrollo científico y tecnológico puedan combatir los 
                       males que ahora aquejan a la sociedad. Un mundo donde la manipulación genética 
                       pueda alterar las características biológicas de los organismos, incluyendo a 
                       los seres humanos. Esa práctica podría eliminar muchas de las enfermedades 
                       que conocemos. Considerando los avances científicos actuales, un mundo así 
                       parece posible. Pero ¿cómo sería? ¿Crees que eso garantizaría la felicidad?<br/><br/>
                       
                       <h3 className='SubTitulo'>Nuevos humanos</h3><br/>
                       
                        Todos tenemos limitaciones de diversa índole: físicas, intelectuales o 
                        emocionales. Si pensamos que son un obstáculo, podríamos querer que los 
                        conocimientos científicos y tecnológicos se utilicen para mejorar a los seres 
                        humanos, haciéndonos más fuertes, inteligentes o felices. De cualquier manera, 
                        el objetivo principal seguiría siendo el mismo que tiene la medicina: 
                        alargar la vida y aumentar su calidad.
                       </p>                   
                    </Grid>
                    <Grid container justifyContent='center'>
                        <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion9/1.png" alt="Tarjeta" />
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container >
                        <p className='Lectura'>
                            Estas ideas tendrían todo nuestro apoyo si desarrollarlas le produjera bienestar al 
                            mayor número de personas. Sin embargo, hay que considerar que estos cambios también 
                            podrían resultar contraproducentes. <br/><br/>
                            
                            Considera lo siguiente: ¿qué pasaría si el perfeccionamiento de la humanidad no se 
                            diera de manera uniforme, equitativa, justa? En casi todas las sociedades actuales 
                            un número muy pequeño de individuos acumula la mayor parte de la riqueza. Debido 
                            a esta desigualdad social, el proyecto de perfeccionamiento quedaría sólo al alcance
                            de unos pocos. Reflexiona lo siguiente: en un mundo donde los ricos llevaran también 
                            ventajas biológicas y psicológicas, ¿no crees que les resultaría más sencillo dominar 
                            a los demás? <br/><br/>
                            
                            Hagamos una comparación: se acerca la fecha de un partido de futbol. A uno de los 
                            equipos le prohíben entrenar, lo obligan a jugar sin tenis adecuados y lo hacen 
                            salir a la cancha con menos jugadores. En cambio, el otro equipo tiene tenis 
                            diseñados para no derraparse, para golpear mejor el balón y para dar a los jugadores 
                            más estabilidad. Ése ya sería un juego injusto. Ahora imagínate que, además, el equipo 
                            que tiene ventaja no experimenta cansancio y puede jugar por horas sin fatigarse.
                        </p>
                    </Grid>
                    <Grid container justifyContent='center'>
                        <Grid container justifyContent='center'>
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion9/2.png" alt="Tarjeta" />
                        </Grid>
                        <Grid container >
                            <p className="TextImg">
                                En la comparación, la falta de cansancio del equipo representa las posibilidades y las 
                                consecuencias de la manipulación genética. ¿Qué significan los tenis inadecuados y la 
                                falta de práctica? ¿Qué formas de desigualdad social existen ahora y cómo se les puede 
                                comparar con un partido injusto? ¿Hay alguna otra comparación que te parezca más exacta?
                            </p>
                        </Grid>
                    </Grid>

                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container >
                        <p className='Lectura'>
                            <h3 className='SubTitulo'>Desigualdad entre sociedades</h3><br/>

                            Esta desventaja pueden experimentarla tanto los individuos como las sociedades 
                            (incluso países enteros). En cualquier caso, existe el riesgo de que los más 
                            desarrollados se aprovechen de los otros. Por eso es importante que las 
                            políticas económicas de los gobiernos busquen proteger a sus ciudadanos, sus 
                            derechos, sus condiciones de trabajo y de desarrollo, antes de rendir cuentas 
                            a las empresas transnacionales.<br/><br/>
                            
                            <h3 className='SubTitulo2'>La ética frente al cambio</h3><br/>
                            
                            ¿Qué opinas de estos avances científicos? Quizá la posibilidad de mejorar 
                            tu cuerpo, tu mente y tus capacidades te cause asombro o te atraiga, ya que 
                            sería una herramienta bastante útil: podría ayudarte con tus proyectos. Podría 
                            hacerte feliz. Sin embargo, es importante que seamos conscientes de cómo 
                            son la sociedad y el mundo en que vivimos. ¿Quién recibiría realmente dichas 
                            ventajas? ¿Éstas ayudarían a construir un mundo más justo? <br/><br/>
                            
                            La ética tiene dos objetivos: la felicidad de los individuos y la justicia 
                            de las sociedades. Este último es el más urgente y ha sido el más importante 
                            en la historia, porque no puede asegurarse el bienestar individual si la mayor 
                            parte de la población vive una situación de desigualdad social. Por ello, 
                            en la actualidad muchos centros de investigación y empresas tienen comités 
                            de ética: deben saber cuál es el impacto que sus productos o sus 
                            descubrimientos tendrán en las sociedades.
                        </p>
                    </Grid>
                </Grid>

            <Grid container item xs={12} justifyContent="center" className="ContainerDerechos">
                <p className="Derechos">
                    © 2022. Todos los derechos reservados, Pearson, Circuito de lectores.
                </p>                
            </Grid>
        </Grid>
    </Grid>
    );
}
 
export default Hacia_un_mundo_feliz;
