import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
      Contenedor:{
        background:'#E9FBF9',
        width:'100vw',
        overflowX:'clip',
        [theme.breakpoints.down(1161)]:{
            background:'none',
           overflowX:'unset',
        },
        [theme.breakpoints.down(600)]:{
            marginTop: '79.51px',
        }
    },
    ContainerInfoLectura:{
        position:'relative',
        height:'86px',
        background:'#817FD9',
        '& .BarraInfoLectura':{
            height:'86px',
            background:`Url(https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Barras/Morada.png)`,

        },
        '& .InfoLectura':{
            position:'absolute',
            height:'86px',
            width:'219px',
            boxSizing:'border-box',
            padding:'17.2px 0',
            background:'#FAFAFA',
            borderRadius:'50px',
            boxShadow: '2px 2px 2px 2px #00000029',
            zIndex:'4',
        },
        '& .InfoLectura:before':{
            position:'absolute',
            display:'flex',
            boxSizing:'border-box',
            padding:'0 0 0 10px',
            justifyContent:'center',
            alignItems:'center',
            height:'39.88px',
            width:'39.88px',
            borderRadius:'50%',
            content:'">"',
            boxShadow: '3px 0px .5px 0px #00000029',
            background:'#FAFAFA',
            left:'90px',
            top:'65px',
            font:'bold 20px lato',
            color:'#926DD1',
            transform: 'rotate(0.25turn)',
            },
        '& .Sesion':{
            borderLeft:'2px solid #D8D8D8',
        },
        '& .Dato':{
            margin:'0 0 5px 0',
            textAlign:'center',
            font:'bold 17px lato',
            color:'#926DD1',
        },
        '& .Caracter':{
            margin:'0',
            textAlign:'center',
            font:'bold 17px lato',
            color:'#000000',
        },
    },
    ContenedorLectura:{
        background:'#fff',
        width:'1160px',
        height:'100%',
        padding:'57px 167px 35px' ,
        [theme.breakpoints.down(1161)]:{
            width:'502px',
            padding: '57px 0 0 0',
        },
        [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
        '& .ContainerDerechos':{
            bottom:'80px',
            [theme.breakpoints.down(1161)]:{
                display:'none',
            }
        },
        '& .Derechos':{
            textAlign:"center",
            margin:"0px",
            font:" 15px lato",
            color:"#717476",
        },
        '& .Escritorio':{
            display:'block',
            [theme.breakpoints.down(1161)]:{
                display:'none'
            },
        },
        '& .Tablet':{
            display:'none',
            [theme.breakpoints.down(1161)]:{
                display:'block'
            },
        },
    },
    ContainerTitulo:{
        margin:'0 0 39px 0',
        [theme.breakpoints.down(1161)]:{
            margin:'0 0 38px 0',
        },
        '& .Titulo':{
            font:'48px fredoka one',
            letterSpacing: '0px',
            color: '#4BA822',
            margin:'0',
            textAlign:'center',
            [theme.breakpoints.down(1161)]:{
                font:'43px fredoka one',
                lineHeight:'52px',
                textAlign:'center',
            },
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
      
    },
    Parrafo1:{
        margin:' 0 0 44px 0',
        '& .Lectura':{
            lineHeight : '30px',
            margin:'0 0 36px 0 ',
            font:'22px lato',
            color:'#000',
            letterSpacing: '0.20px',
            [theme.breakpoints.down(1161)]:{
                font:'18px lato',
                letterSpacing:'.17px',
                lineHeight:'28px',
            },
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .TextImg':{
            margin:'32px 0 0 0',
            font:'20px lato',
            alignSelf:'flex-start',
            color:'#717476',
            letterSpacing:'.19px',
            [theme.breakpoints.down(1161)]:{
                font:'16px lato',
                letterSpacing:'.15px',
                lineHeight:'25px',
            },
            [theme.breakpoints.down(502)]:{
                font:' 11px lato',
                lineHeight :'14px',
                letterSpacing: '0.10px',
            },
        },
        '& .Imagen':{
            [theme.breakpoints.down(1161)]:{
                width:'100%',
            },
        },

    },

}));

const De_verdad_quería_ver_escuchar_o_comprar_eso = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
             <Grid container item justifyContent="center" className={classes.ContainerInfoLectura}>
               <Grid container className="BarraInfoLectura"></Grid>
               <Grid container item className="InfoLectura">
                    <Grid container item direction="column" xs={6} justifyContent="center">
                        <p className="Dato">Grado</p>
                        <p className="Caracter">6</p>
                    </Grid>
                    <Grid container item direction="column" xs={6} justifyContent="center" className="Sesion">
                        <p className="Dato">Sesión</p>
                        <p className="Caracter">55</p>
                    </Grid>
               </Grid>
            </Grid>

            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo Escritorio" >
                        ¿De verdad quería ver, <br/>escuchar o comprar eso?
                    </h2>
                    <h2 className="Titulo Tablet" >
                        ¿De verdad quería <br/>ver, escuchar<br/>o comprar eso?
                    </h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                            Actualmente, las redes sociales representan la principal forma de interacción entre 
                            las personas. Un <em>clic</em> basta para comunicarte con alguien del otro lado del mundo. Es 
                            una de las herramientas más poderosas, pues en cuestión de segundos podemos 
                            saber lo que pasa alrededor del planeta en tiempo real y difundirlo. Pero hay que 
                            tener cuidado con lo que compartimos, con quién y dónde. Hay empresas que 
                            emplean poderosos programas de computación para recabar toda esa información y 
                            convertirla en datos para usarlos de una u otra forma. <br/><br/>
                            
                            Es probable que hayas utilizado alguna red social. En teoría, todas son gratuitas, pero 
                            ese acceso “gratuito” lo estás pagando con tus datos, es decir, con tu información 
                            personal. Desde el momento en que aceptas los términos y condiciones, todo lo que 
                            publicas deja de ser de tu propiedad y pasa a manos de los dueños de dichas redes 
                            sociales. <br/><br/>
                            
                            Entre más cosas publiques, más información otorgas a esos programas para hacer 
                            predicciones. ¿Para qué sirven las predicciones? Para encontrar patrones. Por 
                            ejemplo, del género musical que escuchas, de los artistas que te interesan e incluso 
                            de la hora en la que los oyes. Entonces, el programa predice qué podría gustarte y 
                            comienza a ofrecerte sugerencias parecidas, incluso en esas horas específicas.
                        </p>
                    </Grid>
                    <Grid container  sm={12}>
                            <Grid container >
                            <img className='Imagen' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion55/1.png"  alt="Tarjeta" />
                            </Grid>
                            <Grid container >
                                <p className='TextImg'>Nuestra información alimenta la inteligencia artificial de las redes sociales.</p>
                            </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                            Pero cuidado: estas predicciones sobre tus gustos (¡incluso sobre tus estados de 
                            ánimo!) son aprovechadas por las empresas. Imagina que pasas por un mal momento 
                            y en las últimas dos semanas has escuchado canciones tristes a cierta hora. Eso es 
                            suficiente para que el programa conozca un patrón acerca de ti y te haga llegar 
                            sugerencias. <br/><br/>
                            
                            Lo mismo sucede si tu estado de ánimo es el contrario. El programa analiza en qué 
                            días y a qué hora estás de buen ánimo para darte canciones felices o una película de 
                            comedia. Y es probable que tú aceptes alguna o varias de esas recomendaciones 
                            porque son atinadas, están diseñadas para ti. Pero aquí se encuentra lo 
                            impresionante del asunto: ¿de verdad querías ver esa serie o película o escuchar esa 
                            canción? Llega el punto en el que ya no estamos seguros de si es algo que de verdad 
                            queremos hacer o si nuestro estado de ánimo es inducido por los anuncios o 
                            sugerencias que nos aparecieron en redes sociales. <br/><br/>
                            
                            Lo mismo ocurre con las compras en línea. Los algoritmos examinan las páginas que 
                            consultan las personas para adquirir bienes materiales. También estudian la hora en 
                            la que lo hacen y la información que comparten al hacerlo. ¿Cuál es la razón de todo 
                            eso? Pues hay personas que realizan compras cuando tienen cierto estado de ánimo. 
                            Por ejemplo, algunas hacen compras innecesarias cuando están deprimidas, otras 
                            cuando están felices.<br/><br/>
                            
                            Pues bien, las empresas que se dedican a vender productos en línea pagan para tener 
                            acceso a tu información y así saber, con base en tus publicaciones, en qué momentos 
                            puedes estar de cierto ánimo para ser más susceptible de realizar una compra. ¡Y 
                            listo! Cuando detectan ese ánimo en particular por alguna de tus publicaciones, 
                            comienzan a bombardearte de propaganda o promociones.
                        </p>
                    </Grid>
                    <Grid container  sm={12}>
                            <Grid container >
                            <img className='Imagen' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion55/2.png"  alt="Tarjeta" />
                            </Grid>
                            <Grid container >
                                <p className='TextImg'>Los programas analizan todos nuestros movimientos en línea.</p>
                            </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                            Pero este análisis de información va más allá de recomendaciones de canciones, 
                            películas o productos. Hay documentales donde se muestra que esas prácticas se 
                            llevan a grandes escalas para influir en las decisiones de las personas en temas
                            políticos y sociales. Así, las personas que no tienen clara una postura política, por 
                            ejemplo, en tema de elecciones, y por lo tanto no saben por quién votar, pueden ser 
                            bombardeadas con anuncios en favor (o en contra, depende quién pague) de un 
                            candidato con la finalidad de influir en su decisión. Algo parecido ocurre cuando se 
                            pretende apoyar o terminar con un movimiento social.
                        </p>
                    </Grid>
                    <Grid container  sm={12}>
                            <Grid container >
                            <img className='Imagen' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion55/3.png"  alt="Tarjeta" />
                            </Grid>
                            <Grid container >
                                <p className='TextImg'>Es importante discernir la información que recibimos de las redes.</p>
                            </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                            Por eso hay que tener mucho cuidado de qué compartimos, con quién lo 
                            compartimos y en dónde lo compartimos.
                        </p>
                    </Grid>
                </Grid>

                <Grid container item xs={12} justifyContent="center" className="ContainerDerechos">
                    <p className="Derechos">
                        © 2022. Todos los derechos reservados, Pearson, Circuito de lectores.
                    </p>                
                </Grid>
        </Grid>
    </Grid>
    );
}
 
export default De_verdad_quería_ver_escuchar_o_comprar_eso;
