import React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { makeStyles } from "@material-ui/core";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    // padding: theme.spacing(2),

    [theme.breakpoints.up("sm")]: {
      // margin: "20px 0",
      paddingTop: "0",
      paddingBottom: "0",
    },
  },
  "& .MuiDialog-paper": {
    borderRadius: "12.1px",
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
    display: "flex",
    justifyContent: "center",
    gap: "5%",
    marginBottom: "16px",
  },
  "& .MuiDialogTitle-root": {
    [theme.breakpoints.up("sm")]: {
      // margin: "20px 0",
      padding: "22px",
    },
  },
}));

const useStyles = makeStyles((theme) => ({
  containerContent: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    gap: "15px",
    alignItems: "center",
    [theme.breakpoints.up("md")]: {
      minWidth: "680px",
    },
  },
  containerButtons: {
    display: "flex",
    justifyContent: "center",
  },
  button: {
    backgroundColor: "#B965D3",
    borderRadius: "4px",
    boxSizing: "borderBox",
    outline: "0",
    border: "0",
  },
  title: {
    margin: 0,
    fontFamily: "Fredoka One",
    fontSize: "26px",
    letterSpacing: "0.23px",
    textAlign: "center",
    color: "#000",
    [theme.breakpoints.up("md")]: {
      fontSize: "30px",
    },
  },
  subTitle: {
    fontFamily: "Lato",
    fontSize: "18px",
    margin: 0,
    color: "#505759",
    fontWeight: "normal",
  },
  codigos: {
    color: "#9051b1",
    fontFamily: "Fredoka One",
    fontSize: "26px",
    letterSpacing: "0.23px",
    margin: "20px 0",
    [theme.breakpoints.up("md")]: {
      fontSize: "30px",
    },
  },
  vigencia: {
    fontFamily: "Lato",
    fontSize: "18px",
    color: "#505759",
    fontWeight: "normal",
    margin: "20px 0",
  },
  link: {
    // width: "100%",
    textDecoration: "none",
    color: "inherit",
  },
}));

const PurpleButton = styled(Button)(({ theme }) => ({
  color: "#FFFFFF",
  backgroundColor: "#9051b1",
  borderRadius: "30px",
  textTransform: "unset",
  // margin: "0 auto",
  width: "100%",
  maxWidth: "130px",
  "&:hover": {
    backgroundColor: "#B965D3 ",
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, ...other } = props;
  return (
    <DialogTitle sx={{ m: 0, p: 4 }} {...other}>
      {children}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
};

const ModalCodigoCreado = ({ open, handleClose, data }) => {
  const classes = useStyles();
  return (
    <>
      <BootstrapDialog
        aria-labelledby="customized-dialog-title"
        open={open}
        maxWidth="md"
      >
        <BootstrapDialogTitle id="customized-dialog-title"></BootstrapDialogTitle>
        <DialogContent>
          <div className={classes.containerContent}>
            <h3 className={classes.title}>Se crearon:</h3>
            <h4 className={classes.codigos}>{data?.total_codigos} códigos</h4>
            <h5 className={classes.vigencia}>Con vigencia de: {data?.vigencia}</h5>
          </div>
        </DialogContent>
        <DialogActions
          disableSpacing={true}
          className={classes.containerButtons}
        >
          <a href={data?.archivo} download={true} className={classes.link}>
            <PurpleButton
              onClick={handleClose}
              className={classes.button}
              variant="contained"
            >
              Descargar
            </PurpleButton>
          </a>
        </DialogActions>
      </BootstrapDialog>
    </>
  );
};
export default ModalCodigoCreado;
