import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
    ContenedorLectura:{
        position:'relative',
        width:'100%',
        [theme.breakpoints.down(667)]:{
            width:'350px',
        },
        '& .Imagen':{
            width:'100%'
        },
    },

}));

const  El_lavado_de_manos = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img1ro/Sesion14/F2.png" alt="Tarjeta" />
        </Grid>
    </Grid>
    );
}
 
export default  El_lavado_de_manos;
