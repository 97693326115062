import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
    ContenedorLectura:{
        width:'502px',
                  [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
    },
    ContainerTitulo:{
        margin:'0 0 36px 0',
        '& .Titulo':{
            font:'48px fredoka one',
            letterSpacing: '0px',
            color: '#F6664D',
            margin:'0',
            textAlign:'center',
            font:'43px fredoka one',
            lineHeight:'52px',
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            lineHeight : '30px',
            margin:'0 0 36px 0',
            font:'18px lato',
            lineHeight :'28px',
            letterSpacing: '0.17px',
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .SubTitulo':{
            margin:'0 ',
            color:'#0CB5D6',
            font:'bold 22px lato',
            lineHeight :'24px',
            letterSpacing: '0.21px',
            [theme.breakpoints.down(502)]:{
                font:'bold 18px lato',
                lineHeight :'20px',
                letterSpacing: '0.16px',
            },
        },
        '& .TextImg':{
            color:'#717476',
            margin:'22px 0 0 0',
            font:' 16px lato',
            lineHeight :'25px',
            letterSpacing: '0.15px',
            [theme.breakpoints.down(502)]:{
                font:' 11px lato',
                lineHeight :'14px',
                letterSpacing: '0.10px',
            },
        },
        '& .Imagen':{
            width:'100%',
        },
    },

}));

const Los_hallazgos_más_extraordinarios_desde_2010 = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >
                        Los hallazgos<br/> más extraordinarios<br/> desde 2010
                    </h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                            En la década de 2010 se sucedieron dos hechos que los especialistas de diversas 
                            disciplinas consideran los más significativos de esos años. Uno de ellos ayuda a 
                            comprender el proceso evolutivo de la humanidad. El otro tiene que ver con el 
                            avance tecnológico logrado por medio de un trasplante facial.<br/><br/> 
                            
                            <h3 className='SubTitulo'>Los denisovanos </h3><br/>
                            
                            El hallazgo de un hueso antiguo de un dedo llamó la atención de los investigadores.
                            Notaron que no coincidía con los genes de los seres humanos modernos ni con los de 
                            los neandertales. Lo anterior les hizo pensar que nuestra línea evolutiva ancestral es 
                            más compleja de lo que se creía. <br/><br/>
                            
                            El hueso se encontró en la cueva Denísova, ubicada en Rusia, por ello se otorgó el 
                            mismo nombre a esta nueva especie. Un artículo sobre el hecho señala que en la 
                            misma cueva se hallaron otros objetos, además de huesos. Esto es una evidencia de 
                            que los neandertales y los denisovanos convivieron en tiempos pasados. Y por lo 
                            tanto, pone en duda la creencia de que los seres humanos modernos intercambiaron 
                            genes con los neandertales. <br/><br/>
                            
                            A ello se añade que recientemente se encontraron huellas genéticas de personas 
                            portadoras de <span style={{fontVariant: "all-small-caps"}}>ADN</span> denisovano. Se están elaborando nuevas hipótesis sobre la 
                            historia de la evolución humana a partir de estos hallazgos del equipo de Svante 
                            Pääbo. Y se espera que pronto se obtenga información sobre esta nueva especie.
                        </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <Grid container >
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img4to/Sesion22/1.png" alt="Tarjeta" />
                        </Grid>
                        <Grid container justifyContent="center">
                            <p className='TextImg'> 
                                El hallazgo se hizo en las cuevas Denísova, ubicadas en los montes Altái.
                            </p>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                            <h3 className='SubTitulo'>Trasplantes faciales</h3><br/>
                            
                            El otro hecho extraordinario de la década fue el primer trasplante de rostro 
                            completo, logrado en 2010. La operación duró 30 horas e intervinieron alrededor de 
                            25 profesionales. Actualmente, el procedimiento otorga una mejor calidad de vida a 
                            pacientes con problemas de salud o que tuvieron algún accidente que alteró su rosto. <br/><br/>
                            
                            Pero este transplante continúa generando polémica alrededor del mundo, pues 
                            muchos consideran que la cara es parte de la identidad de los individuos. También se 
                            cuestionan las consecuencias éticas, sociales e incluso legales que tienen para los 
                            beneficiarios. <br/><br/>
                            
                            Cada avance trae consigo nuevas preguntas. Pero es importante recordar que, en 
                            tanto la humanidad obtenga beneficios, podrá mejorar; por eso es fundamental 
                            seguir apoyando los estudios sociales, médicos, científicos y tecnológicos. ¿Imaginas 
                            todo lo que aún está por descubrirse?
                        </p>
                    </Grid>
                </Grid>
        </Grid>
    </Grid>
    );
}
 
export default Los_hallazgos_más_extraordinarios_desde_2010;
