import { Grid, makeStyles } from "@material-ui/core";
import React, { useState } from "react";
import map from "./MapaG2.jpg";

import { useContext } from "react";
import AuthContext from "../../../auth/AuthContext";

import ButtonMapS1 from "./Button/MapButtonS1";

import ButtonMap from "./Button/MapButton";
import ButtonMapS60 from "./Button/MapButtonS60";
import ButtonMapS56 from "./Button/MapButtonS56";
import Loader from "../../Loader/Loader";
import { useHistory } from "react-router-dom";
import useGetMapData from "../../../hooks/useGetMapData";
import MapButtons from "./Button/MapButtons";

const useStyles = makeStyles((theme) => ({
  container: {
    // position: "relative",
    width: "100vw",
    // width: "100%",
    // height: "1080px",
    marginTop: "80px",
    minHeight: "calc(100vh - 80px)",
    overflowY: "auto",
    backgroundColor: "#6da679 ",
    display: "grid",
    justifyContent: "start",

    [theme.breakpoints.up("sm")]: {
      marginTop: "0px",
    },
    [theme.breakpoints.up("lg")]: {
      width: "100%",
    },
    [theme.breakpoints.up("xl")]: {
      justifyContent: "center",
      alignItems: "center",
    },
  },
  containerMap: {
    // position: "absolute",
    // top: "80px",
    // left: "0",

    display: "flex",
    height: "1080px",
    // overflow: "hidden",
    width: "1920px",
    position: "relative",
    // marginTop: "80px",
    [theme.breakpoints.up("md")]: {
      marginTop: "0px",
      // top: "0",
    },
    "& .map": {
      height: "100%",
      width: "100%",
      boxShadow: "98px 117px 218px #37533D, -101px 93px 162px #37533D",
      // marginTop: "80px",
      [theme.breakpoints.up("md")]: {
        marginTop: "0px",
        // top: "0",
      },
    },
  },
}));

const Map2 = () => {
  const { user, dispatch } = useContext(AuthContext);
  const [loading, setLoading] = useState(true);
  const [loadingInterface, setLoadingInterface] = useState(true);
  const history = useHistory();

  const { loadActivitiesList } = useGetMapData(
    2,
    setLoading,
    dispatch,
    history
  );

  const activitiesList = user.activitiesList || [];

  const classes = useStyles();

  return (
    <Grid className={classes.container}>
      <Grid className={classes.containerMap}>
        <img
          className="map"
          src={map}
          loading="lazy"
          alt="Mapa"
          onLoad={() => {
            setLoadingInterface(false);
          }}
        />
          {activitiesList.length ? (
          <MapButtons
            loadActivitiesList={loadActivitiesList}
            activitiesList={activitiesList}
            grade="grado2"
            ButtonMap={ButtonMap}
            ButtonMapS1={ButtonMapS1}
            ButtonMapS56={ButtonMapS56}
            ButtonMapS60={ButtonMapS60}
          />
        ) : null}
        {loading || loadingInterface ? <Loader /> : <></>}
      </Grid>
    </Grid>
  );
};

export default Map2;
