import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
     Contenedor:{
        background:'#E9FBF9',
        width:'100vw',
        overflowX:'clip',
        [theme.breakpoints.down(1161)]:{
            background:'none',
           overflowX:'unset',
        },
        [theme.breakpoints.down(600)]:{
            marginTop: '79.51px',
        }
    },
  ContainerInfoLectura: {
    position: "relative",
    height: "86px",
    [theme.breakpoints.down(502)]:{
        height: "43px",
    },
    background: "#76D4C3",
    "& .BarraInfoLectura": {
      height: "86px",
      background: `Url(https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Barras/Azul.png)`,
        [theme.breakpoints.down(502)]:{
            height: "43px",
        },
    },
    "& .InfoLectura": {
      position: "absolute",
      height: "86px",
      width: "219px",
      boxSizing: "border-box",
      padding: "17.2px 0",
      background: "#FAFAFA",
      borderRadius: "50px",
      boxShadow: "2px 2px 2px 2px #00000029",
      zIndex: "4",
        [theme.breakpoints.down(502)]:{
            height: "43px",
            width: "109px",
            padding: "8.6px 0",
            borderRadius: "25px",
        },
    },
    "& .InfoLectura:before": {
      position: "absolute",
      display: "flex",
      boxSizing: "border-box",
      padding: "0 0 0 10px",
      justifyContent: "center",
      alignItems: "center",
      height: "39.88px",
      width: "39.88px",
      borderRadius: "50%",
      content: '""',
      boxShadow: "3px 0px .5px 0px #00000029",
      background: "#FAFAFA",
      left: "90px",
      top: "65px",
      transform: "rotate(0.25turn)",
      [theme.breakpoints.down(502)]:{
        left: "45px",
        top: "30px",
        height: "20.88px",
        width: "20.88px",
        },
    },
    "& .InfoLectura:after": {
      content: '""',
      height: "15px",
      width: "15px",
      borderTop: "2px solid #20A698",
      borderLeft: "2px solid #20A698",
      position: "absolute",
      bottom: "-10px",
      left: "Calc(50% - 8px)",
      transform: "rotate(0.625turn)",
      [theme.breakpoints.down(502)]:{
        height: "10px",
        width: "10px",
        left: "Calc(50% - 4.5px)",
        bottom: "-3px",
        borderTop: "1px solid #20A698",
        borderLeft: "1px solid #20A698",
        },
    },
    "& .Sesion": {
      borderLeft: "2px solid #D8D8D8",
    },
    "& .Dato": {
      margin: "0 0 5px 0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#20A698",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        margin: "0 0 2.5px 0",
        },
    },
    "& .Caracter": {
      margin: "0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#000000",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        },
    },   },
    ContenedorLectura:{
        background:'#fff',
        width:'1160px',
        height:'100%',
        padding:'60px 168px 35px ',
        [theme.breakpoints.down(1161)]:{
            width:'502px',
            padding:'60px 0 0 0',
          },
        [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
        '& .ContainerDerechos':{
            bottom:'80px',
            [theme.breakpoints.down(1161)]:{
                display:'none',
            },
        },
        '& .Derechos':{
            textAlign:"center",
            margin:"0px",
            font:" 15px lato",
            color:"#717476",
        },
        '& .Escritorio':{
            display:'block',
            [theme.breakpoints.down(1161)]:{
                display:'none'
            },
        },
        '& .Tablet':{
            display:'none',
            [theme.breakpoints.down(1161)]:{
                display:'block'
            },
        }
    },
    ContainerTitulo:{
        margin:'0 0 46px 0',
        '& .Titulo':{
            letterSpacing: '0px',
            color: '#13A38C',
            margin:'0',
            font:'48px fredoka one',
            [theme.breakpoints.down(1161)]:{
                font:'43px fredoka one',
                lineHeight:'52px',
            },
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        position:'relative',
        '& .Lectura':{
            margin:'0 0 36px 0',
            color:'#000',
            font:'22px lato',
            letterSpacing: '0.18px',
            lineHeight : '30px',
            [theme.breakpoints.down(1161)]:{
                font:'18px lato',
                lineHeight :'28px',
                letterSpacing: '0.17px',
            },
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .Esp':{
            position:'absolute',
            width:'226px',
            top:'283px',
            left:'11px',
            [theme.breakpoints.down(1161)]:{
                position:'relative',
                top:'0px',
                left:'0px',
                width:'100%',
            },
        },
        
        '& .Imagen':{
            [theme.breakpoints.down(1161)]:{
                width:'100%',
            },
        },
    },
}));


const Hermano_jaguar = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
             <Grid container item justifyContent="center" className={classes.ContainerInfoLectura}>
               <Grid container className="BarraInfoLectura"></Grid>
               <Grid container item className="InfoLectura">
                    <Grid container item direction="column" xs={6} justifyContent="center">
                        <p className="Dato">Grado</p>
                        <p className="Caracter">4</p>
                    </Grid>
                    <Grid container item direction="column" xs={6} justifyContent="center" className="Sesion">
                        <p className="Dato">Sesión</p>
                        <p className="Caracter">13</p>
                    </Grid>
               </Grid>
            </Grid>

            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >
                        Hermano jaguar
                    </h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container justifyContent='center' sm={12}>
                        <p className='Lectura Esp'>
                            —¡No lo mates, papá! —gritó Jacaranda.
                        </p>
                    </Grid>
                    <Grid container justifyContent='center' sm={12}>
                        <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img4to/Sesion13/1.png" alt="Tarjeta" />
                    </Grid>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container sm={12}>
                      <p className="Lectura"> 
                        —Entonces, ¿qué quieres? ¿Que el jaguar lo mate a él? —le recriminó su abuela. 
                        Jacaranda no quería que le pasara nada a su papá, pero tampoco al animal.<br/><br/> 
                        
                        —Jacaranda, entiende que el jaguar anda matando a las vacas. Tu papá tiene que 
                        cazarlo —le explicó su mamá. Se fue a su cuarto, llorando y con el deseo de que eso 
                        no sucediera en la realidad.<br/><br/>
                        
                        —Ya se le pasará —alcanzó a oír que decían.<br/><br/> 
                        
                        Nadie había querido escucharla. Pero ella intentó explicar que el jaguar tenía hambre 
                        y por eso se había comido la vaca. En la escuela le dijeron que le han quitado su 
                        territorio y ya no hay presas para él. Que es un animal en peligro de extinción. Pero
                        ellos sólo pensaban que era un asesino.
                      </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img4to/Sesion13/3.png" alt="Tarjeta" />
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container sm={12}>
                      <p className="Lectura"> 
                        Jacaranda no entendía por qué ponían la cara del jaguar en las artesanías, en los
                        folletos para los turistas y lo presumían con orgullo, si luego la gente de la comunidad 
                        salía a matarlo. <br/><br/> 
                         
                        Pero tampoco quería que el jaguar matara a su papá. Ni siquiera a las vacas. ¿Cómo 
                        alimentar al jaguar sin que se tenga que morir nadie?<br/><br/> 
                        
                        No encontró ninguna solución posible. No iba a poder convencer a su papá de que no 
                        lo cazara. Esperaba tan sólo que el felino y él no se encontraran, que el jaguar se 
                        fuera lejos, que huyera, pero no sabría cómo convencer a alguien de que su tierra ya 
                        no era suya, y de que debía irse a vivir lejos. <br/><br/> 
                        
                        —¿Qué hambre importa más: la del jaguar o la nuestra? <br className='Tablet'/>—habría dicho su mamá. <br/><br/> 
                        
                        Se dirigió a la computadora. Encontró un programa en el que unos biólogos buscaban 
                        a los felinos y los llevaban a una parte de la reserva para que no se enfrentaran con 
                        los pobladores. <br/><br/> 
                        
                        Les llamó a escondidas. Les explicó todo. Ellos le preguntaron acerca de la 
                        vestimenta de su padre y la ubicación de su casa para saber dónde andaba el jaguar. 
                        Ella contó todo lo que pudo, con la esperanza de que lo resolvieran y salvaran a 
                        todos. Cuando colgó, sintió remordimiento: ¿y si había denunciado a su papá y lo 
                        encarcelaban? <br/><br/> 
                        
                        Pasaron las horas y Jacaranda sólo gritaba y lloraba cuando le hablaban. Los demás 
                        pensaron que estaba así por el jaguar. No sabía si su papá iba a regresar y temía que 
                        volviera y la regañara. También temía que hubiera matado al jaguar, o que el animal lo 
                        hubiera matado a él. Le daba miedo que atraparan a su papá, que él supiera que ella 
                        lo había delatado y que todos en su casa la vieran como una traidora. <br/><br/> 
                        
                        Casi de noche, su papá llegó. Ella sintió alivio, pero también preocupación. Él dijo que
                        no lo encontraron, que era una fiera huidiza, pero que esa misma noche le darían un 
                        balazo si se acercaba a sus vacas. <br/><br/> 
                        
                        Su papá no podía dormir. Tampoco Jacaranda. Entonces, tocaron a la puerta. Eran dos 
                        hombres que no parecían ser de por allí. Jacaranda escuchó que le hablaron a su papá 
                        del jaguar, que ya no daría problemas, que se lo habían llevado a la reserva. Le 
                        agradecieron por haberles avisado y se fueron. <br/><br/> 
                        
                        —¿Avisado de qué? —dijo su papá al cerrar la puerta. Jacaranda no dijo nada. Esa
                        noche soñó que corría junto al jaguar y se sintió libre.
                      </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img4to/Sesion13/2.png" alt="Tarjeta" />
                    </Grid>
                </Grid>

                    
                <Grid container item xs={12} justifyContent="center" className="ContainerDerechos">
                    <p className="Derechos">
                        © 2022. Todos los derechos reservados, Pearson, Circuito de lectores.
                    </p>                
                </Grid>

            </Grid>
        </Grid>
    );
}
 
export default Hermano_jaguar;
