import React from 'react';
import { makeStyles, Grid } from "@material-ui/core";
import Retroalimentacion from './Retroalimentacion';

const useStyles = makeStyles(theme =>({
  questionContainer:{
    width: "100%",
    height:"90%",
  },
  questionText: {
    backgroundColor: "pink",
    width: "100%",
    margin:"10px 0 10px 0 ",
    paddingLeft: "5px",
    font: '24px lato',
    letterSpacing: '0.21px',
    [theme.breakpoints.down(1400)]:{
      font: " 20px lato",
      width: "100%",
    },
    [theme.breakpoints.down(650)]:{
      font: "16px lato",
    },
  },
  reading: {
    width: "100%",
    margin:"10px 0 10px 0 ",
    paddingLeft: "10px",
    font: '26px lato',
    letterSpacing: '0.21px',
    [theme.breakpoints.down(1400)]:{
      font: " 22px lato",
      width: "100%",
    },
    [theme.breakpoints.down(650)]:{
      font: "18px lato",
    },
  },
  retroStyle:{
    width: "100%",
    margin:"10px 0 10px 0 ",
    paddingLeft: "10px",
  },
  selectedStyle:{
    border: "#4BBCF8 5px solid",
    backgroundColor: "#DDF6FA",
    width: "82%",
    borderRadius: "5px",
    margin:'auto',
    [theme.breakpoints.down(1310)]:{
      font: "500 16px lato",
      width: "80%",
      // margin:"10px 0 10px 0"
    }, 
    [theme.breakpoints.down(700)]:{
      font: "500 12px lato",
      width: "80%",
      // margin:"10px 0 10px 0"
    }, 
  },
}));

const Preguntas = (props) => {
  const classes = useStyles()
  const {preguntasList,  topRef, sesionLectura, idGrado } = props



  return ( 
    <Grid>
      {
        
        preguntasList.map((question, index) =>{
          const renderQuestionText = () => {
            if (sesionLectura === "2" || sesionLectura === "59") {
              if(idGrado === 1 || idGrado === 2){
                
                if(index === 0 || index === 4 || index === 8){
                  return(
                  <p 
                    dangerouslySetInnerHTML={{__html: `${question.texto}`.replace("10.", " ")}}
                    className={classes.reading}
                  >
                  </p>
                  )
                }

              } else if(idGrado === 3 || idGrado === 4){
                
                if(index === 0 || index === 5 || index === 10){
                  return(
                    <p 
                      dangerouslySetInnerHTML={{__html: `${question.texto}`.replace("10.", " ")}}
                      className={classes.reading}
                    >
                    </p>
                  )
                }

              } else {
                if(index === 0 || index === 5 || index === 10 || index ===15){
                  return(
                    <p 
                      dangerouslySetInnerHTML={{__html: `${question.texto}`.replace("10.", " ")}}
                      className={classes.reading}
                    >
                    </p>
                  )
                }
              }
            }
          }
          return(
          <>
            {
              renderQuestionText()
            }
            <h2 ref={topRef[question.id_pregunta]} >Pregunta {index +1}.  </h2>
            <Retroalimentacion
              key={question.id_pregunta + "-retroalimentacion"}
              index={index}
              question={question}
              questionInfo={question.pregunta}
              respuestas={question.respuestas}
              // feedback={question.feedback}
            />
          </>
          )
        })
      } 
    </Grid>
  );
}
 
export default Preguntas;
