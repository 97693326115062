
import React, { Component } from 'react';
import Chart from 'react-apexcharts';



export default class BarChar extends Component{ 
    constructor(props) {
      super(props);

      this.state = {
      
        series: [
          {
            name: 'Sesion actual',
            data: [
              {
                x: 'Carlos ',
                y: 20,
              },
              {
                x: 'Karol',
                y: 30,

              },
              {
                x: 'Segio',
                y: 20,
              },
              {
                x: 'Julio',
                y: 25,
              },
              {
                x: 'Chris',
                y: 15,
              },
              {
                x: 'Nahani',
                y: 25,
              }
            ]
          }
        ],
        options: {
          chart: {
            height: 160,
            type: 'bar'
          },
          annotations: {
            xaxis: [{
              x: 25,
              borderColor: '#000',
              label: {
                borderColor: '#000',
                style: {
                  color: '#fff',
                  background: '#000',
                  fontSize:'16px',
                },
                text: 'Sesión esperada',
              }
            }],
          },
          plotOptions: {
            bar: {
              horizontal: true,
            }
          },
          colors: ['#00E396'],
          dataLabels: {
            formatter: function(val, opt) {
              const goals =
                opt.w.config.series[opt.seriesIndex].data[opt.dataPointIndex]
                  .goals
          
              if (goals && goals.length) {
                return `${val} / ${goals[0].value}`
              }
              return val
            }
          },
          
          legend: {
            show: true,
            showForSingleSeries: true,
            customLegendItems: ['Sesiones 60'],
            markers: {
              fillColors: ['#00E396']
            }
          }
        },
      
      
      };
    }

  

    render() {
      return (
        


  <div id="chart">
    <Chart options={this.state.options} series={this.state.series} type="bar" height={498}  />
</div>



      );
    }
  }
