import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
      Contenedor:{
        background:'#E9FBF9',
        width:'100vw',
        overflowX:'clip',
        [theme.breakpoints.down(1161)]:{
            background:'none',
           overflowX:'unset',
        },
        [theme.breakpoints.down(600)]:{
            marginTop: '79.51px',
        }
    },
  ContainerInfoLectura: {
    position: "relative",
    height: "86px",
    [theme.breakpoints.down(502)]:{
        height: "43px",
    },
    background: "#76D4C3",
    "& .BarraInfoLectura": {
      height: "86px",
      background: `Url(https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Barras/Azul.png)`,
        [theme.breakpoints.down(502)]:{
            height: "43px",
        },
    },
    "& .InfoLectura": {
      position: "absolute",
      height: "86px",
      width: "219px",
      boxSizing: "border-box",
      padding: "17.2px 0",
      background: "#FAFAFA",
      borderRadius: "50px",
      boxShadow: "2px 2px 2px 2px #00000029",
      zIndex: "4",
        [theme.breakpoints.down(502)]:{
            height: "43px",
            width: "109px",
            padding: "8.6px 0",
            borderRadius: "25px",
        },
    },
    "& .InfoLectura:before": {
      position: "absolute",
      display: "flex",
      boxSizing: "border-box",
      padding: "0 0 0 10px",
      justifyContent: "center",
      alignItems: "center",
      height: "39.88px",
      width: "39.88px",
      borderRadius: "50%",
      content: '""',
      boxShadow: "3px 0px .5px 0px #00000029",
      background: "#FAFAFA",
      left: "90px",
      top: "65px",
      transform: "rotate(0.25turn)",
      [theme.breakpoints.down(502)]:{
        left: "45px",
        top: "30px",
        height: "20.88px",
        width: "20.88px",
        },
    },
    "& .InfoLectura:after": {
      content: '""',
      height: "15px",
      width: "15px",
      borderTop: "2px solid #20A698",
      borderLeft: "2px solid #20A698",
      position: "absolute",
      bottom: "-10px",
      left: "Calc(50% - 8px)",
      transform: "rotate(0.625turn)",
      [theme.breakpoints.down(502)]:{
        height: "10px",
        width: "10px",
        left: "Calc(50% - 4.5px)",
        bottom: "-3px",
        borderTop: "1px solid #20A698",
        borderLeft: "1px solid #20A698",
        },
    },
    "& .Sesion": {
      borderLeft: "2px solid #D8D8D8",
    },
    "& .Dato": {
      margin: "0 0 5px 0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#20A698",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        margin: "0 0 2.5px 0",
        },
    },
    "& .Caracter": {
      margin: "0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#000000",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        },
    },   },

    ContenedorLectura:{
        background:'#fff',
        width:'1160px',
        height:'100%',
        padding:'57px 168px 35px' ,
        [theme.breakpoints.down(1161)]:{
            width:'502px',
            padding: '57px 0 0 0',
        },
        [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
        '& .ContainerDerechos':{
            bottom:'80px',
            [theme.breakpoints.down(1161)]:{
                display:'none',
        }
        },
        '& .Derechos':{
            textAlign:"center",
            margin:"0px",
            font:"15px lato",
            color:"#717476",
        },
        '& .Escritorio':{
            display:'block',
            [theme.breakpoints.down(1161)]:{
                display:'none'
            },
        },
        '& .Tablet':{
            display:'none',
            [theme.breakpoints.down(1161)]:{
                display:'block'
            },
        },
    },

    ContainerTitulo:{
        margin:'0 0 45px 0',
        [theme.breakpoints.down(1161)]:{
            margin:'0 0 38px 0',
        },
        '& .Titulo':{
            font:'48px fredoka one',
            letterSpacing: '0px',
            color: '#E56D54',
            margin:'0',
            textAlign:'center',
            [theme.breakpoints.down(1161)]:{
                font:'43px fredoka one',
                lineHeight:'52px',
                textAlign:'center',
            },
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },

    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            lineHeight : '30px',
            margin:'0 0 36px 0 ',
            font:'22px lato',
            color:'#000',
            letterSpacing: '0.20px',
            [theme.breakpoints.down(1161)]:{
                font:'18px lato',
                letterSpacing:'.17px',
                lineHeight:'28px',
            },
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .SubTitulo':{
            alignSelf:'center',
            font:'bold 30px lato',
            color:'#52B2D2',
            letterSpacing:'.28px',
            margin:'0 ',
            [theme.breakpoints.down(1161)]:{
                font:'bold 26px lato',
                letterSpacing:'.17px',
                lineHeight:'28px',
            },
            [theme.breakpoints.down(502)]:{
                font:'22px Fredoka One',
                lineHeight:'24px',
            },
        },
        '& .TextImg':{
            margin:'24px 0 0 0',
            font:'20px lato',
            alignSelf:'flex-start',
            color:'#717476',
            letterSpacing:'.19px',
            [theme.breakpoints.down(1161)]:{
                font:'16px lato',
                letterSpacing:'.15px',
                lineHeight:'25px',
            },
            [theme.breakpoints.down(502)]:{
                font:' 11px lato',
                lineHeight :'14px',
                letterSpacing: '0.10px',
            },
        },
        '& .Imagen':{
            [theme.breakpoints.down(1161)]:{
                width:'100%',
            },
            
        }
    },

}));

const La_grandeza_está_en_la_diversidad = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
             <Grid container item justifyContent="center" className={classes.ContainerInfoLectura}>
               <Grid container className="BarraInfoLectura"></Grid>
               <Grid container item className="InfoLectura">
                    <Grid container item direction="column" xs={6} justifyContent="center">
                        <p className="Dato">Grado</p>
                        <p className="Caracter">6</p>
                    </Grid>
                    <Grid container item direction="column" xs={6} justifyContent="center" className="Sesion">
                        <p className="Dato">Sesión</p>
                        <p className="Caracter">58</p>
                    </Grid>
               </Grid>
            </Grid>

            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo Escritorio" >
                        La grandeza está en la diversidad
                    </h2>
                    <h2 className="Titulo Tablet" >
                        La grandeza está <br/>en la diversidad
                    </h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                            Hoy en día es difícil imaginar la vida sin la tecnología. Sobre todo porque con ella 
                            cada vez más situaciones o tareas de la vida cotidiana son posibles de realizar con 
                            mayor precisión. Por ejemplo, seguramente conoces o usas aparatos como el 
                            teléfono móvil o algún asistente digital al que le hablas y hace lo que le pides. Todo 
                            esto ha sido posible gracias a la inteligencia artificial. <br/><br/>
                            
                            <h3 className='SubTitulo'>¿Qué es la inteligencia artificial?</h3><br/>
                            
                            La <em>inteligencia artificial</em> (IA) se encarga del diseño y la construcción de los sistemas o 
                            las máquinas que intentan imitar la inteligencia humana para realizar tareas o 
                            recopilar información. Ésta se aplica en disciplinas como las matemáticas, la 
                            computación, la robótica, la física, la medicina, la biología, la química, la 
                            lingüística, la astronomía, etcétera.
                        </p>
                    </Grid>
                    <Grid container  sm={12}>
                            <Grid container >
                                <img  className='Imagen' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion58/1.png"  alt="Tarjeta" />
                            </Grid>
                            <Grid container justifyContent='center'>
                                <p className='TextImg'>La inteligencia artificial paulatinamente se integra más a la vida cotidiana.</p>
                            </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                            <h3 className='SubTitulo'>Alan Turing y la automatización de datos y procesos</h3><br/>
                            
                            Gracias a Alan Turing (1912-1954), hoy en día la vida es mucho más fácil con la ayuda 
                            de computadoras y de la inteligencia artificial. Sin duda, uno de los grandes avances 
                            de la humanidad fue creado por este científico y matemático inglés, a quien se le 
                            conoce como el padre de la informática y un visionario de la inteligencia artificial. <br/><br/>
                            
                            Turing tuvo un papel fundamental durante la Segunda Guerra Mundial. Él fue quien 
                            consiguió descifrar matemáticamente los códigos de la compleja máquina <em>Enigma</em> del 
                            ejército nazi, con la cual se transmitía información clasificada sobre estrategias y
                            ataques. Esa participación ayudó a vencer a los alemanes y redujo la duración de la
                            guerra. Por lo tanto, contribuyó a salvar millones de vidas.<br/><br/>
                            
                            Después de la guerra, este personaje siguió desarrollando sus ideas y diseñó la 
                            máquina universal de Turing, la cual es el modelo en el que se basan las 
                            computadoras actuales. ¿Lo sabías? Por esta razón, también es considerado como 
                            uno de los padres de las ciencias de la computación.
                        </p>
                    </Grid>
                    <Grid container  sm={12}>
                            <Grid container>
                                <img  className='Imagen' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion58/2.png"  alt="Tarjeta" />
                            </Grid>
                            <Grid container justifyContent='center'>
                                <p className='TextImg'>Alan Turing descifró los códigos alemanes utilizando la máquina que creó.</p>
                            </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                            Lamentablemente para Alan Turing, los prejuicios de su época lo perjudicaron 
                            gravemente. Por ser homosexual, fue condenado y sometido a una terapia de 
                            conversión: a través de métodos clínicos y un tratamiento químico, se buscaba que se 
                            convirtiera en heterosexual. Esto es condenable en la actualidad, pues la 
                            homosexualidad no es una enfermedad y las terapias no son siquiera un proceso 
                            científico aceptado. <br/><br/>
                            
                            La terapia consistía en la inyección de hormonas. Esto le produjo posteriormente 
                            cambios físicos importantes, como el crecimiento de las glándulas mamarias. Todo
                            ello lo condujo a una fuerte depresión, que después de unos años lo llevó a la muerte. 
                            La versión oficial la catalogó como un suicidio por comer una manzana envenenada 
                            con cianuro, aunque se cree que pudo haber sido un accidente o incluso un asesinato. <br/><br/>
                            
                            Cabe mencionar que, en el año 2013, la reina Isabel II de Inglaterra le concedió el 
                            “perdón póstumo”, pues la sentencia de Alan Turing ahora se considera injusta y 
                            discriminatoria. <br/><br/>
                            
                            ¿Te imaginas lo que Alan Turing hubiera hecho con la inteligencia artificial si hubiese 
                            continuado? He ahí la importancia de aceptar y respetar la diversidad sexual. De
                            haberse hecho en ese entonces, la humanidad no hubiera perdido a una de las 
                            grandes mentes del siglo pasado.<br/><br/>
                            
                            Su relevancia es tal hasta nuestros días, sobre todo en inteligencia artificial, que aún 
                            existe la Prueba de Turing, la cual sirve para determinar si una computadora es 
                            inteligente. Es un examen complejo, pero consiste en lo siguiente: si una 
                            computadora puede engañar a un ser humano haciéndolo pensar que está 
                            conversando vía chat con otro humano, entonces se considera inteligente a la 
                            computadora. <br/><br/>
                            
                            En la actualidad, la inteligencia artificial ha logrado grandes avances en el área de las 
                            matemáticas y la ciencia, y un aprendizaje profundo en la asimilación de datos. 
                            Gracias a ella, han aumentado las herramientas, aplicaciones y plataformas digitales. 
                            Por ejemplo, que tú puedas acceder a esta plataforma, que leas este texto y que 
                            posteriormente resuelvas los ejercicios es posible gracias a la inteligencia artificial.
                        </p>
                    </Grid>
                </Grid>

                <Grid container item xs={12} justifyContent="center" className="ContainerDerechos">
                    <p className="Derechos">
                        © 2022. Todos los derechos reservados, Pearson, Circuito de lectores.
                    </p>                
                </Grid>
        </Grid>
    </Grid>
    );
}
 
export default La_grandeza_está_en_la_diversidad;
