import React               from "react";
import {Grid, makeStyles}                 from '@material-ui/core';

const useStyles = makeStyles(theme =>({
  questionContainer:{
    width: "100%",
    height:"100%",
  },
  // reading: {
  //   width: "80%",
  //   margin:"10px 0 10px 0 ",
  //   font: '22px lato',
  //   letterSpacing: '0.21px',
  //   [theme.breakpoints.down(1400)]:{
  //     font: " 18px lato",
  //     width: "85%",
  //   },
  // },
  reading: {
    width: "100%",
    margin:"10px 0 10px 0 ",
    font: '22px lato',
    letterSpacing: '0.21px',
    [theme.breakpoints.down(1400)]:{
      font: " 18px lato",
      width: "100%",
    },
    [theme.breakpoints.down(650)]:{
      font: "14px lato",
    },
  },
  table: {
    // border: '2px solid black',
    width: "100%",
    font: '22px lato',
    [theme.breakpoints.down(1400)]:{
      font: " 18px lato",
    },
    [theme.breakpoints.down(1370)]:{
      font: "16px lato",
      width: "90%",
      // margin:"10px 0 10px 0"
    },
    [theme.breakpoints.down(1310)]:{
      font: "16px lato",
      // margin:"10px 0 10px 0"
    },
    [theme.breakpoints.down(925)]:{
      font: "14px lato",
      marginLeft: '10px',
      // width: "70%",
      // margin:"6px 0 6px 0"
    },
    [theme.breakpoints.down(426)]:{
      font: "12px lato",
      // width: "70%",
      // margin:"6px 0 6px 5%"
    }, 
  },
  tdTable: {
    padding: "0 1%",
    // border: '2px solid black',
  }
}));

const QuestionTable = (props) => {
  const classes = useStyles()


  return(
    <Grid container className={classes.questionContainer}>
      <p
        className={classes.reading}
      >
       8. ¿Qué ejemplos muestran que el lenguaje sirve para mantener usos y costumbres y cuáles
       muestran que sirve para cambiarlos?
      </p>
      <br/>
      <table className={classes.table}>
        <tr>
          <th style={{width: "30%", textAlign: "left"}}><b>Función</b></th>
          <th style={{width: "70%", textAlign: "left"}}><b>Ejemplos</b></th>
        </tr>
        <tr>
          <td className={classes.tdTable}>
            <p className={classes.tdTable}>
              1. Mantienen
            </p><br/>
          </td>
          <td className={classes.tdTable}>
            <p className={classes.tdTable}>
              a. Personas realizan una manifestación con demandas y pancartas.
            </p>
          </td>
        </tr>
        <tr>
          <td className={classes.tdTable}>
            <p className={classes.tdTable}>
              2. Cambian 
            </p><br/>
          </td>
          <td className={classes.tdTable}>
            <p className={classes.tdTable}>
              b. Se promulga la Ley de Protección a los animales.
            </p>
          </td>
        </tr>
        <tr>
          <td className={classes.tdTable}>

          </td>
          <td className={classes.tdTable}>
            <p className={classes.tdTable}>
              c. Personas rezan en una misa de Navidad.
            </p>
          </td>
        </tr>
        <tr>
          <td className={classes.tdTable}>

          </td>
          <td className={classes.tdTable}>
            <p className={classes.tdTable}> 
              d. Invitados cantan una canción de cumpleaños en una fiesta.
            </p>
          </td>
        </tr>
      </table>
    </Grid>
  )
}

export default QuestionTable;
