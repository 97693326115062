import { makeStyles } from "@material-ui/core";
import React from "react";

import { Link } from "react-router-dom";
import PantallaFinal from "../../activities/PantallaFinal";
import ContadorModal from "../Modals/ContadorModal";

import NarrativaModalS60 from "../Modals/Frames/NarrativaModalS60";
import IntervencionHeraldoModal from "../Modals/IntervencionHeraldoModal";

const useStyles = makeStyles((theme) => ({
  link: {
    "& .BotonGrande": {
      cursor: "pointer",
      border: "none",
      backgroundColor: "transparent",
      position: "absolute",
      height: "55.7px",
      width: "55.7px",
      borderRadius: "50%",
      font: " 22px lato",
      "&:hover": {
      },
    },
    "& .Bienvenida": {
      top: "106.5px",
      left: "320px",
      "&:hover": {
        "&:before": {
          display: "flex",
          position: "relative",
          justifyContent: "center",
          alignItems: "center",
          bottom: "50px",
          content: '"Bienvenida"',
          background: "#fff",
          borderRadius: "25px",
          font: "bold 16px lato",
          height: "25px",
          width: "100px",
        },
      },
    },
    "& .Boton": {
      cursor: "pointer",
      border: "none",
      backgroundColor: "transparent",
      position: "absolute",
      height: "37px",
      width: "37px",
      borderRadius: "50%",
    },
    "& .bloqueada": {
      borderWidth: "2px",
      borderStyle: "solid",
      borderColor: "#808080",
      backgroundColor: "#80808080",

      "&:hover": {
        cursor: "default",
      },
    },
    "& .completa": {
      borderWidth: "2px",
      borderStyle: "solid",
      borderColor: "#008000",
      "&:hover": {},
    },
    "& .contador": {
      borderWidth: "2px",
      borderStyle: "solid",
      borderColor: "#FFCE30",
      "&:hover": {},
    },
    "& .remedial": {
      borderWidth: "2px",
      borderStyle: "solid",
      borderColor: "#800080",
      "&:hover": {},
    },

    "& .Actividad56": {
      top: "665.5px",
      left: "1553.5px",
    },

    "& .Actividad60": {
      top: "917.5px",
      left: "1543.5px",
      "&:hover": {
        "&:before": {
          display: "flex",
          position: "relative",
          justifyContent: "center",
          alignItems: "center",
          bottom: "50px",
          content: '"Sesión de cierre"',
          background: "#fff",
          borderRadius: "25px",
          font: "bold 16px lato",
          height: "25px",
          width: "135px",
        },
      },
    },
  },
}));

const ButtonMapS60 = ({ data, handleClose, actualizeList, grade, abbreviatedGrade }) => {
  const { path_imagen, path_video, narrativa, nombre, sesion } = data;
  const [intervention, setIntervention] = React.useState(false);
  const [frame, setFrame] = React.useState(false);
  const [contadorOpen, setContadorOpen] = React.useState(false);

  const handleIntervention = () => {
    setIntervention(!intervention);
  };

  const handleFrame = () => {
    setFrame(!frame);
  };

  const handleContadorOpen = () => {
    setContadorOpen(!contadorOpen);
  };

  const sessionNumber = `${`0${data?.sesion}`?.slice(-2)}${abbreviatedGrade}`;
  //En caso de ser una sesion menor a 10 agregará un 0. Ejemeplo: Sesion 2 seria /session02

  const formativas = [1, 2, 20, 38, 55, 59, 60];
  const buttonLarge = formativas.includes(sesion);

  const classes = useStyles({});
  const next = () => {
    setIntervention(false);
    setFrame(true);
  };

  return (
    <>
      {data?.actividad_status.toLowerCase() === "sesión desbloqueada" && (
        <>
          <NarrativaModalS60
            open={frame}
            path={path_video}
            handleClose={handleFrame}
            handleNext={handleFrame}
            id_actividad={data?.id_actividad}
            id_actividad_inscripcion={data?.id_actividad_inscripcion}
          />
          <IntervencionHeraldoModal
            open={intervention}
            path={path_imagen}
            handleClose={handleIntervention}
            handleNext={next}
            narrativa={narrativa}
            alt={nombre}
            grado={grade}
            to={sessionNumber}
            sesion={sesion}
          />
          <PantallaFinal grade={grade} />
          <Link className={classes.link} to="#">
            <button
              className={
                buttonLarge
                  ? `BotonGrande Actividad${sesion}`
                  : `Boton Actividad${sesion}`
              }
              aria-label={`Actividad ${sesion}`}
              onClick={handleIntervention}
            ></button>
          </Link>
        </>
      )}
      {data?.actividad_status.toLowerCase() === "completa" && (
        <>
          <NarrativaModalS60
            open={frame}
            // path={"https://pearson-cdl-contenidos-qa.s3.amazonaws.com/G1/frames/G1-Frame-5.mp4"}
            path={path_video}
            handleClose={handleFrame}
            handleNext={handleFrame}
            id_actividad={data?.id_actividad}
            id_actividad_inscripcion={data?.id_actividad_inscripcion}
          />
          <IntervencionHeraldoModal
            open={intervention}
            path={path_imagen}
            handleClose={handleIntervention}
            handleNext={next}
            narrativa={narrativa}
            alt={nombre}
            grado={grade}
            to={sessionNumber}
            sesion={sesion}
          />
          <PantallaFinal grade={grade} />
          <Link className={classes.link} to="#">
            <button
              className={
                buttonLarge
                  ? `BotonGrande Actividad${sesion} completa`
                  : `Boton Actividad${sesion} completa`
              }
              aria-label={`Actividad ${sesion}`}
              onClick={handleIntervention}
            ></button>
          </Link>
        </>
      )}
      {data?.actividad_status.toLowerCase() === "contador" && (
        <>
          <Link className={classes.link} to="#">
            <button
              className={
                buttonLarge
                  ? `BotonGrande Actividad${sesion} contador`
                  : `Boton Actividad${sesion} contador`
              }
              aria-label={`Actividad ${sesion}`}
              onClick={handleContadorOpen}
            ></button>
          </Link>
          <ContadorModal
            open={contadorOpen}
            handleClose={handleContadorOpen}
            timestamp={data.tiempo_espera}
            actualizeList={actualizeList}
          />
        </>
      )}
      {data?.actividad_status.toLowerCase() === "bloqueada" && (
        <>
          <Link className={classes.link} to="#">
            <button
              className={
                buttonLarge
                  ? `BotonGrande Actividad${sesion} bloqueada`
                  : `Boton Actividad${sesion} bloqueada`
              }
              aria-label={`Actividad ${sesion}`}
              onClick={handleContadorOpen}
              disabled
            ></button>
          </Link>
        </>
      )}
    </>
  );
};

export default ButtonMapS60;
