import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
      Contenedor:{
        background:'#E9FBF9',
        width:'100vw',
        overflowX:'clip',
        [theme.breakpoints.down(1161)]:{
            background:'none',
           overflowX:'unset',
        },
        [theme.breakpoints.down(600)]:{
            marginTop: '79.51px',
        }
    },
  ContainerInfoLectura: {
    position: "relative",
    height: "86px",
    [theme.breakpoints.down(502)]:{
        height: "43px",
    },
    background: "#76D4C3",
    "& .BarraInfoLectura": {
      height: "86px",
      background: `Url(https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Barras/Azul.png)`,
        [theme.breakpoints.down(502)]:{
            height: "43px",
        },
    },
    "& .InfoLectura": {
      position: "absolute",
      height: "86px",
      width: "219px",
      boxSizing: "border-box",
      padding: "17.2px 0",
      background: "#FAFAFA",
      borderRadius: "50px",
      boxShadow: "2px 2px 2px 2px #00000029",
      zIndex: "4",
        [theme.breakpoints.down(502)]:{
            height: "43px",
            width: "109px",
            padding: "8.6px 0",
            borderRadius: "25px",
        },
    },
    "& .InfoLectura:before": {
      position: "absolute",
      display: "flex",
      boxSizing: "border-box",
      padding: "0 0 0 10px",
      justifyContent: "center",
      alignItems: "center",
      height: "39.88px",
      width: "39.88px",
      borderRadius: "50%",
      content: '""',
      boxShadow: "3px 0px .5px 0px #00000029",
      background: "#FAFAFA",
      left: "90px",
      top: "65px",
      transform: "rotate(0.25turn)",
      [theme.breakpoints.down(502)]:{
        left: "45px",
        top: "30px",
        height: "20.88px",
        width: "20.88px",
        },
    },
    "& .InfoLectura:after": {
      content: '""',
      height: "15px",
      width: "15px",
      borderTop: "2px solid #20A698",
      borderLeft: "2px solid #20A698",
      position: "absolute",
      bottom: "-10px",
      left: "Calc(50% - 8px)",
      transform: "rotate(0.625turn)",
      [theme.breakpoints.down(502)]:{
        height: "10px",
        width: "10px",
        left: "Calc(50% - 4.5px)",
        bottom: "-3px",
        borderTop: "1px solid #20A698",
        borderLeft: "1px solid #20A698",
        },
    },
    "& .Sesion": {
      borderLeft: "2px solid #D8D8D8",
    },
    "& .Dato": {
      margin: "0 0 5px 0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#20A698",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        margin: "0 0 2.5px 0",
        },
    },
    "& .Caracter": {
      margin: "0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#000000",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        },
    },   },
    ContenedorLectura:{
        background:'#fff',
        width:'1160px',
        height:'100%',
        padding:'60px 165px 35px',
        [theme.breakpoints.down(1161)]:{
            width:'502px',
            padding:'60px 0 0',
        },
        [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
        '& .ContainerDerechos':{
            bottom:'80px',
            [theme.breakpoints.down(1161)]:{
                display:'none',
              },
        },
        '& .Derechos':{
            textAlign:"center",
            margin:"0px",
            font:" 15px lato",
            color:"#717476",
        },
        '& .Escritorio':{
            display:'block',
            [theme.breakpoints.down(1161)]:{
                display:'none'
            },
        },
        '& .Tablet':{
            display:'none',
            [theme.breakpoints.down(1161)]:{
                display:'block'
            },
        }
    },
    ContainerTitulo:{
        margin:'0 0 46px 0',
        '& .Titulo':{
            font:'48px fredoka one',
            letterSpacing: '0px',
            lineHeight:'58px',
            color: '#008ADE',
            margin:'0',
            textAlign:'center',
            [theme.breakpoints.down(1161)]:{
                font:'43px fredoka one',
                lineHeight:'52px',
                display:'block',
            },
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            lineHeight : '30px',
            margin:'0px 0 36px 0',
            font:'22px lato',
            color:'#000',
            letterSpacing: '0.19px',
            [theme.breakpoints.down(1161)]:{
                lineHeight :'28px',
                font:'18px lato',
                letterSpacing: '0.17px',
            },
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .SubTitulo':{
            font:'bold 26px lato',
            color:'#F6644B',
            letterSpacing:'.24px',
            lineHeight:'28px',
            margin:'0',
            [theme.breakpoints.down(1161)]:{
                lineHeight :'28px',
                font:'bold 26px lato',
                letterSpacing: '0.24px',
            },
            [theme.breakpoints.down(502)]:{
                font:'bold 18px lato',
                lineHeight :'24px',
                letterSpacing: '0.21px',
            },
        },
        '& .Imagen':{
            width:'100%',
        },
    }
}));

const Artesanías_patrimonio_de_una_cultura = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
             <Grid container item justifyContent="center" className={classes.ContainerInfoLectura}>
               <Grid container className="BarraInfoLectura"></Grid>
               <Grid container item className="InfoLectura">
                    <Grid container item direction="column" xs={6} justifyContent="center">
                        <p className="Dato">Grado</p>
                        <p className="Caracter">6</p>
                    </Grid>
                    <Grid container item direction="column" xs={6} justifyContent="center" className="Sesion">
                        <p className="Dato">Sesión</p>
                        <p className="Caracter">5</p>
                    </Grid>
               </Grid>
            </Grid>

            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >Artesanías, patrimonio <br/>de una cultura</h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                            <p className="Lectura ">
                                Imagina a una joven de un pequeño poblado, alejado de la ciudad, que se dedica a un 
                                oficio. Primero, obtiene lana de las ovejas que ella misma cría, para convertirla 
                                después en hilos. Luego, los teje para formar una vestimenta que la protegerá contra 
                                el frío o que usará para asistir a alguna ceremonia tradicional de su comunidad. <br/><br/>
                                
                                Ella podría hacer esa prenda cada vez más compleja si decide coser otros hilos de 
                                colores para formar diversas figuras, como las plantas que crecen cerca de su casa, 
                                animales que viven en las colinas o símbolos que se han utilizado en su localidad 
                                durante generaciones. <br/><br/>
                                
                                Debido al proceso como fue creada, esa vestimenta es una artesanía y se diferencia 
                                de otro tipo de prendas porque está hecha de manera única. Es decir, no hay otra 
                                igual, pues proviene de la imaginación de quien la elabora, lo cual es la esencia de las 
                                artesanías. <br/><br/>
                                
                                Por un lado, hay que pensar en lo que tejió la joven del ejemplo, en los árboles o 
                                animales que escogió para esa prenda, pues no serán los mismos que la siguiente que 
                                confeccione. Mismo caso sería el de un vaso hecho con técnicas manuales. Por el 
                                otro, también hay que pensar en aquellos objetos que se compran en el 
                                supermercado, como los vestidos y vasos.
                            </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <Grid container justifyContent="center" >
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion5/1.png" alt="Tarjeta" />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container sm={12}>
                            <p className="Lectura ">
                                Para que comprendas mejor qué son las artesanías y cuál es su importancia, a 
                                continuación observa sus características:<br/><br/>
                                
                                <h3 className='SubTitulo'>Materiales</h3><br/>
                                En todo el planeta existe una gran cantidad de elementos con los cuales pueden 
                                crearse objetos. Sin embargo, para que éstos se consideren artesanías, deben estar 
                                hechos con materiales del lugar en donde los artesanos los crean. <br/><br/>
                                
                                Un ejemplo para elaborar textiles es la lana de las alpacas que viven en los países 
                                andinos, es decir, donde se encuentra la Cordillera de los Andes en Sudamérica. El 
                                pelaje de estos animales es apropiado para generar hilos que protegen de las bajas 
                                temperaturas en esa región. Otro ejemplo está en las maderas y fibras de pino de las 
                                montañas mexicanas, con las cuales se realizan desde esculturas hasta muebles.
                            </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <Grid container justifyContent="center" >
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion5/2.png" alt="Tarjeta" />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                            <p className="Lectura">
                                <h3 className='SubTitulo'>Técnicas</h3><br/>
                                Existe una inmensa cantidad de técnicas artesanales para transformar los materiales 
                                en algo único: desde aquellas que permiten labrar un trozo de madera hasta las 
                                distintas formas de extraer líquido de ciertas plantas para elaborar bebidas 
                                alcohólicas. Por ejemplo, a este último método se le conoce como <em>destilación</em>. Estos 
                                procesos suelen ser ancestrales, ya que la técnica se transmite entre familias durante 
                                generaciones.<br/><br/>
                                
                                <h3 className='SubTitulo'>Belleza de lo único</h3><br/>
                                Los procesos industriales, es decir, los que se llevan a cabo en las fábricas, permiten 
                                que todos los productos sean idénticos entre sí. En cierto sentido, suele decirse que 
                                son perfectos. Pero es necesario reflexionar sobre la forma en que se hacen las 
                                artesanías: las dimensiones de los objetos, los hilos que se eligen, las formas que se 
                                trazan, el tiempo invertido, la experiencia familiar puesta en marcha, las diferentes 
                                manos que las elaboran. ¡Todas son diferentes! Estas y otras características hacen 
                                que su belleza sea única.<br/><br/>
                                
                                <h3 className='SubTitulo'>Finalidad</h3><br/>
                                En muchos hogares, restaurantes y tiendas, las artesanías se muestran como 
                                elementos decorativos. Y si bien es válido hacerlo debido a su belleza, piensa que
                                originalmente esos objetos fueron creados para ser utilizados de manera cotidiana 
                                en los lugares donde se hicieron inicialmente. <br/><br/>
                                
                                Cada artesanía es parte de las tradiciones y costumbres de una determinada cultura. 
                                Por muy bellos que sean, una vasija, un canasto, una silla o una prenda de ropa 
                                surgieron en su lugar de origen como objetos para satisfacer necesidades reales.<br/><br/>

                                <h3 className='SubTitulo'>Patrimonio cultural</h3><br/>
                                Ahora es momento de que recuerdes a la joven del inicio. Imagina de nuevo el 
                                proceso que siguió para confeccionar su vestido: los animales que crió para obtener 
                                sus hilos, los pigmentos de las plantas que cultivó para extraer los colores, el diseño 
                                tradicional que eligió. Todo es resultado del conocimiento heredado entre las 
                                personas de su comunidad durante generaciones. <br/><br/>
                                
                                Por ello, portar o exhibir esa prenda es sinónimo de orgullo, pues detrás de cada 
                                artesanía hay historias, familias y herencias únicas. Su grandeza es reflejo de toda 
                                una comunidad y, por esa simple razón, es necesario conservarlas y admirarlas. 
                                Merecidamente, son patrimonio cultural de la humanidad.

                            </p>
                    </Grid>
                </Grid>
               
            <Grid container item xs={12} justifyContent="center" className="ContainerDerechos">
                <p className="Derechos">
                    © 2022. Todos los derechos reservados, Pearson, Circuito de lectores.
                </p>                
            </Grid>
        </Grid>
    </Grid>
    );
}
 
export default Artesanías_patrimonio_de_una_cultura;
