import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
    ContenedorLectura:{
        width:'502px',
        [theme.breakpoints.down(502)]:{
            width:'350px',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
        },

    },
    ContainerTitulo:{
        margin:'0 0 34px 0',
        '& .Titulo':{
            letterSpacing: '0px',
            color: '#225EA3',
            margin:'0',
            textAlign:'center',
            font:'43px fredoka one',
            lineHeight:'52px',
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            margin:'0 0 36px 0',
            color:'#000',
              font:'18px lato',
              lineHeight :'28px',
              letterSpacing: '0.17px',
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        "& .SubTitulo": {
            color: "#823CE2",
            margin: "0",
            lineHeight: "28px",
            font: "bold 26px lato",
            letterSpacing: "0.24px",
            [theme.breakpoints.down(502)]: {
              font: "bold 22px lato",
              lineHeight: "24px",
              letterSpacing: "0.21px",
            },
        },
        '& .TextImg':{
            color:'#717476',
            margin:'20px 0 0 0',
              font:' 16px lato',
              lineHeight :'25px',
              letterSpacing: '0.15px',
            [theme.breakpoints.down(502)]:{
                font:' 11px lato',
                lineHeight :'14px',
                letterSpacing: '0.10px',
            },
        },
        '& .Imagen':{
              width:'100%',
        }, 
        '& .Imagen2':{
            width:'90%',
        }, 
    }
}));

const Fractales = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>

            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
            <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >
                        Fractales                 
                    </h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                      <p className="Lectura">
                        La antigua cultura griega es conocida por sus múltiples avances en diversas áreas. 
                        Una de las más amplias fueron las matemáticas, en la que aportaron una gran 
                        cantidad de conceptos y fórmulas. Los matemáticos de esa etapa estudiaron formas 
                        geométricas a la cuales llamaron <em>polígonos</em>. Se trata de figuras que tienen varios lados 
                        rectos. Por ejemplo, los triángulos, los cuadrados y los pentágonos. Se dieron a la 
                        tarea de analizar las características de cada uno y desarrollaron diferentes 
                        herramientas para describrir sus utilidades.<br/><br/> 
                        
                        Elaboraron toda una teoría sobre este tema, la cual fue utilizada por muchas 
                        personas durante siglos y sentó las bases para que se descubriera una propiedad en 
                        especial que los griegos habían pasado por algo: los fractales. Benoit Mandelbrot fue 
                        el primero en usar dicho término, entre los años 1970 y 1980. Lo hizo para describir 
                        los patrones de formas que aparecen en distintos elementos de la naturaleza. Se 
                        denomina <em>patrón</em> a la repetición de figuras geométricas en una misma área.<br/><br/>

                        Uno de los mejores ejemplos de ello son los copos de nieve. Su fractal se nombra <em>copo 
                        de nieve de Koch</em>, pues fue estudiado por el sueco Helge von Koch en 1904. Él aportó 
                        conocimiento relevante para esta área, pues se dedicó a estudiar los fractales 
                        aproximadamente 70 años antes de que Mandelbrot les diera nombre. Gracias a sus 
                        avances se pudo observar la relación que guardan las figuras geométricas y las 
                        creaciones naturales.
                      </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <Grid container justifyContent="center" >
                            <img className="Imagen2" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img4to/Sesion58/1.png" alt="Tarjeta" />
                        </Grid>
                        <Grid container justifyContent="center" >
                            <p className="TextImg">
                                Puedes apreciar que los elementos del copo de nieve de Koch son iguales, pero en diferentes escalas.                          
                            </p>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                      <p className="Lectura">
                        Este modelo se construye a partir de un primer triángulo. En cada uno de sus lados, 
                        aparece un triángulo similar al original, pero de menor tamaño. Y en cada uno de los 
                        lados de éstas nuevas figuras hay a su vez triángulos más pequeños. De esta manera 
                        puede decirse que el fractal del copo de nieve es la repetición del primer triángulo 
                        en escalas cada vez menores. El proceso puede continuar hasta el infinito, siempre 
                        con medidas más reducidas.<br/><br/>
                        
                        Otra muestra famosa es el brócoli romanesco. Con un primer vistazo puede notarse
                        que todos los picos que lo conforman tienen en sí mismos picos más pequeños, 
                        funcionando igual que el copo de nieve. Te darías cuenta de que las partes
                        diminutas del brócoli lucen prácticamente igual a las grandes si las miraras en un
                        microscopio o con una lupa.
                      </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                    <Grid container justifyContent="center" >
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img4to/Sesion58/2.png" alt="Tarjeta" />
                        </Grid>
                        <Grid container justifyContent="center" >
                            <p className="TextImg">
                            El brócoli romanesco es un ejemplo perfecto del principio de los fractales.                            </p>
                        </Grid>                    
                      </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                      <p className="Lectura">
                        El crecimiento de las ramas de un árbol también es un fractal y su organización es 
                        parecida a la del árbol bronquial de nuestros pulmones. ¿Alguna vez lo habías 
                        notado? La causa de ello es que ambos diseños evolucionaron para llevar a cabo 
                        una misma función: la respiración. Por eso las dos utilizan las mismas 
                        ramificaciones y están ordenadas de formas similares. De hecho, el cuerpo humano 
                        tiene más fractales parecidos en su interior, como el sistema nervioso y el 
                        sistema circulatorio.
                      </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                      <Grid container justifyContent="center" >
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img4to/Sesion58/3.png" alt="Tarjeta" />
                        </Grid>
                        <Grid container justifyContent="center" >
                            <p className="TextImg">
                              Las estructuras pueden repetirse en lugares diferentes..                            
                            </p>
                        </Grid>                    
                      </Grid>                    
                  </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                      <p className="Lectura">
                        <h3 className="SubTitulo">Aplicaciones</h3><br/>
                        La geometría fractal es una rama de las matemáticas que surgió gracias a los 
                        hallazgos de Benoit Mandelbrot. Tiene diversos usos en la actualidad. Por ejemplo, 
                        se emplea para conocer la trayectoria de los meteoritos, los ríos y los terremotos, 
                        ya que todos éstos forman un camino específico a su paso. También sirve de herramienta 
                        en el análisis de las células cancerígenas y de la formación de estrellas.<br/><br/>

                        Ahora ya conoces varios modelos de fractales y sabes que están presentes en muchos 
                        elementos naturales. ¿Imaginas dónde más podrías encontrarlos?
                      </p>
                    </Grid>
                </Grid>
                
        </Grid>
    </Grid>
    );
}
 
export default Fractales ;
