import React from "react";
import { Button, makeStyles } from "@material-ui/core";
import Dropzone from "react-dropzone";

import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import ImageOutlinedIcon from "@mui/icons-material/ImageOutlined";
import ModalMessage from "../../Modals/ModalMessage";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
    width: "auto",
    height: "100%",
    // maxWidth: "475px",
  },
  fileIconButton: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "6px 16px",
    cursor: "pointer",

    [theme.breakpoints.down("sm")]: {
      padding: "8px",
      minWidth: "20px",
    },
    "&:hover": {
      textDecoration: "none",
      backgroundColor: "rgba(0, 0, 0, 0.04)",
    },
  },
  fileIconButtonLeft: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "6px 16px",
    cursor: "pointer",
    borderRadius: "38px",
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    [theme.breakpoints.down("sm")]: {
      padding: "8px",
      minWidth: "20px",
      borderRadius: "0",
    },
    "&:hover": {
      textDecoration: "none",
      backgroundColor: "rgba(0, 0, 0, 0.04)",
    },
  },
  fileIcon: {
    fontSize: "14px",
    color: "#1DA698",
    [theme.breakpoints.up("lg")]: {
      fontSize: "22px",
    },
  },
}));
const ChatDropZoneButton = ({ handleNext, handleDocument, file, title, type, icon }) => {
  const classes = useStyles();
  const [modalMessageOpen, setModalMessageOpen] = React.useState(false);
  const [confirmChangesResult, setConfirmChangesResult] = React.useState("");
  const limitInMb = 3;

  const handleCloseMessageModal = () => {
    setModalMessageOpen(!modalMessageOpen);
  };

  const formatBytes = (bytes) => {
    const limitInKb = limitInMb * 1000000;
    return bytes < limitInKb;
  };

  const uploadImage = async (file) => {
    const [selectedFile] = file;
    if (selectedFile) {
      if (formatBytes(selectedFile?.size)) {
        const base64 = await convertBase64(selectedFile);
        // handleNext();
        handleDocument({
          name: selectedFile?.name,
          size: selectedFile?.size,
          type: selectedFile?.type,
          base64: base64?.split(",")[1],
        });
      } else {
        setModalMessageOpen(true);
        setConfirmChangesResult(`La imagen seleccionada tiene que pesar menos de ${limitInMb} Mb.`);
      }
    } else {
      setModalMessageOpen(true);
      setConfirmChangesResult(`Asegúrese de que la imagen seleccionada tenga un formato ${type}.`);
    }
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };
  return (
    <>
      <Dropzone
        accept={type}
        multiple={false} // Only upload 1 file
        onDrop={(file) => {
          uploadImage(file);
        }}
      >
        {({ getRootProps, getInputProps }) => (
          <div {...getRootProps()} className={classes.container}>
            <input {...getInputProps()} />
            {icon === "image" && (
              <Button className={classes.fileIconButtonLeft}>
                <ImageOutlinedIcon className={classes.fileIcon} />
              </Button>
            )}

            {icon === "document" && (
              <Button className={classes.fileIconButton}>
                <InsertDriveFileOutlinedIcon className={classes.fileIcon} />
              </Button>
            )}
          </div>
        )}
      </Dropzone>
      <ModalMessage
        open={modalMessageOpen}
        handleClose={handleCloseMessageModal}
        title={confirmChangesResult}
      />
    </>
  );
};

export default ChatDropZoneButton;
