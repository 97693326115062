import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
     Contenedor:{
        background:'#E9FBF9',
        width:'100vw',
        overflowX:'clip',
        [theme.breakpoints.down(1161)]:{
            background:'none',
           overflowX:'unset',
        },
        [theme.breakpoints.down(600)]:{
            marginTop: '79.51px',
        }
    },
  ContainerInfoLectura: {
    position: "relative",
    height: "86px",
    [theme.breakpoints.down(502)]:{
        height: "43px",
    },
    background: "#76D4C3",
    "& .BarraInfoLectura": {
      height: "86px",
      background: `Url(https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Barras/Azul.png)`,
        [theme.breakpoints.down(502)]:{
            height: "43px",
        },
    },
    "& .InfoLectura": {
      position: "absolute",
      height: "86px",
      width: "219px",
      boxSizing: "border-box",
      padding: "17.2px 0",
      background: "#FAFAFA",
      borderRadius: "50px",
      boxShadow: "2px 2px 2px 2px #00000029",
      zIndex: "4",
        [theme.breakpoints.down(502)]:{
            height: "43px",
            width: "109px",
            padding: "8.6px 0",
            borderRadius: "25px",
        },
    },
    "& .InfoLectura:before": {
      position: "absolute",
      display: "flex",
      boxSizing: "border-box",
      padding: "0 0 0 10px",
      justifyContent: "center",
      alignItems: "center",
      height: "39.88px",
      width: "39.88px",
      borderRadius: "50%",
      content: '""',
      boxShadow: "3px 0px .5px 0px #00000029",
      background: "#FAFAFA",
      left: "90px",
      top: "65px",
      transform: "rotate(0.25turn)",
      [theme.breakpoints.down(502)]:{
        left: "45px",
        top: "30px",
        height: "20.88px",
        width: "20.88px",
        },
    },
    "& .InfoLectura:after": {
      content: '""',
      height: "15px",
      width: "15px",
      borderTop: "2px solid #20A698",
      borderLeft: "2px solid #20A698",
      position: "absolute",
      bottom: "-10px",
      left: "Calc(50% - 8px)",
      transform: "rotate(0.625turn)",
      [theme.breakpoints.down(502)]:{
        height: "10px",
        width: "10px",
        left: "Calc(50% - 4.5px)",
        bottom: "-3px",
        borderTop: "1px solid #20A698",
        borderLeft: "1px solid #20A698",
        },
    },
    "& .Sesion": {
      borderLeft: "2px solid #D8D8D8",
    },
    "& .Dato": {
      margin: "0 0 5px 0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#20A698",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        margin: "0 0 2.5px 0",
        },
    },
    "& .Caracter": {
      margin: "0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#000000",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        },
    },   },
    ContenedorLectura:{
        background:'#fff',
        width:'1160px',
        height:'100%',
        padding:'60px 168px 35px ',
        [theme.breakpoints.down(1161)]:{
            width:'502px',
            padding:'60px 0 0 0',
          },
        [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
        '& .ContainerDerechos':{
            bottom:'80px',
            [theme.breakpoints.down(1161)]:{
                display:'none',
            },
        },
        '& .Derechos':{
            textAlign:"center",
            margin:"0px",
            font:" 15px lato",
            color:"#717476",
        },
        '& .Escritorio':{
            display:'block',
            [theme.breakpoints.down(1161)]:{
                display:'none'
            },
        },
        '& .Tablet':{
            display:'none',
            [theme.breakpoints.down(1161)]:{
                display:'block'
            },
        }
    },
    ContainerTitulo:{
        margin:'0 0 34px 0',
        [theme.breakpoints.down(1161)]:{
            margin:'0 0 34px 0',
        },
        '& .Titulo':{
            letterSpacing: '0px',
            color: '#E1AF10',
            margin:'0',
            textAlign:'center',
            font:'48px fredoka one',
            lineHeight:'58px',
            [theme.breakpoints.down(1161)]:{
                font:'43px fredoka one',
                lineHeight:'52px',
            },
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            margin:'0 0 36px 0',
            color:'#000',
            font:'22px lato',
            letterSpacing: '0.20px',
            lineHeight : '30px',
            [theme.breakpoints.down(1161)]:{
                font:'18px lato',
                lineHeight :'28px',
                letterSpacing: '0.17px',
            },
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .SubTitulo':{
            color:'#823CE2',
            margin:'0',
            font:'bold 30px lato',
            letterSpacing:'.28px',
            lineHeight:'30px',
            [theme.breakpoints.down(1161)]:{
                lineHeight :'28px',
                font:'bold 26px lato',
                letterSpacing: '0.24px',
            },
            [theme.breakpoints.down(502)]:{
                font:'bold 18px lato',
                lineHeight :'24px',
                letterSpacing: '0.21px',
            },
        },
        '& .Esp':{
            boxSizing:'border-box',
            padding:'0 31px 0 0',
            margin:'0',
            [theme.breakpoints.down(1161)]:{
                padding:'0',
                margin:'0 0 36px 0',
            },
        },
        '& .Esp1':{
            boxSizing:'border-box',
            padding:'0 31px 0 0',
            margin:'0',
            [theme.breakpoints.down(1161)]:{
                padding:'0',
            },
        },
        '& .Esp2':{
            margin:'0',
        },
        '& .TextImg':{
            color:'#717476',
            margin:'21px 0 0 0',
            font:'20px lato',
            lineHeight:'30px',
            letterSpacing:'.19px',
            [theme.breakpoints.down(1161)]:{
                font:' 16px lato',
                lineHeight :'25px',
                letterSpacing: '0.15px',

            },
            [theme.breakpoints.down(502)]:{
                font:' 11px lato',
                lineHeight :'14px',
                letterSpacing: '0.10px',
            },
        },
        '& .ul':{
            margin:'0',
            color:'#823CE2',
            font:'bold 22px lato',
            padding:'0 0 0 23px',
            [theme.breakpoints.down(1161)]:{
                font:'bold 18px lato',
                lineHeight :'28px',
                letterSpacing: '0.17px',
            },
            [theme.breakpoints.down(502)]:{
                font:'bold 16px lato',
                lineHeight :'21.5px',
                letterSpacing: '0.09px',
            },
        },
        '& .li':{
            margin:'0 0 8px 0',
            [theme.breakpoints.down(502)]:{
                margin:'0 0 4px 0',
            },
        },

        '& .Imagen':{
            [theme.breakpoints.down(1161)]:{
                width:'67.33%',
            },
        },  
        '& .Imagen2':{
            [theme.breakpoints.down(1161)]:{
                width:'100%',
            },
        }, 

    }
}));


const Los_dos_cuadros_más_caros_del_mundo = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
             <Grid container item justifyContent="center" className={classes.ContainerInfoLectura}>
               <Grid container className="BarraInfoLectura"></Grid>
               <Grid container item className="InfoLectura">
                    <Grid container item direction="column" xs={6} justifyContent="center">
                        <p className="Dato">Grado</p>
                        <p className="Caracter">4</p>
                    </Grid>
                    <Grid container item direction="column" xs={6} justifyContent="center" className="Sesion">
                        <p className="Dato">Sesión</p>
                        <p className="Caracter">12</p>
                    </Grid>
               </Grid>
            </Grid>

            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >Los dos cuadros<br className='Escritorio'/> más <br className='Tablet'/>caros del mundo</h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container md={12} lg={6}>
                      <p className="Lectura Esp"> 
                        ¿Sabes cuáles son las dos pinturas más 
                        costosas de la historia? La primera se 
                        llama <em>Salvator mundi</em> y fue hecha por 
                        Leonardo da Vinci. La segunda recibe 
                        el nombre de <em>Intercambio</em> y pertenece a 
                        Willem de Kooning. A continuación, 
                        aprenderás acerca de ellas. ¡Vamos!<br/><br/> 
                        
                        Existen varias leyendas sobre el origen 
                        de <em>Salvator mundi</em>. Esto aumenta su 
                        precio en el mercado al provocar 
                        curiosidad en el público. El cuadro fue 
                        pintado en el siglo <span style={{fontVariant: "all-small-caps"}}>XVI</span>. Es un retrato de 
                        Jesucristo en el que éste traza la 
                        bendición con una mano y con la otra 
                        sostiene una esfera de cristal que 
                        simboliza al mundo. Por eso tiene ese 
                        título, que significa salvador del <em>mundo </em> 
                        en latín.
                      </p>
                    </Grid>
                    <Grid container justifyContent="center" md={12} lg={6}>
                        <Grid container item justifyContent='center'>
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img4to/Sesion12/1.png" alt="Tarjeta" />
                        </Grid>
                        <Grid container item>
                            <p className='TextImg Esp1'>A pesar de ser la obra que se ha subastado al precio más alto en la historia, su autenticidad sigue en duda.</p>
                        </Grid>
                    </Grid>
                </Grid>
               
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container sm={12}>
                      <p className="Lectura "> 
                        El comercio del arte inició en el siglo <span style={{fontVariant: "all-small-caps"}}>XVII</span>, unas décadas después de que se pintara 
                        esta obra. Desde entonces ha sido la más cara, ¡pues en 2017 fue vendida en una 
                        subasta por 450 millones de dólares! El comprador prefirió permanecer anónimo y
                        tampoco se sabe dónde está el cuadro actualmente.<br/><br/> 
                        
                        Se dice que no fue hecho por Leonardo da Vinci, sino por los alumnos de su taller. 
                        También existen rumores de que en realidad es sólo una copia y que los trazos han 
                        sido alterados.<br/><br/> 
                        
                        Por otra parte, <em>Intercambio</em> es un trabajo realizado en 1955 por uno de los pintores 
                        más importantes en Estados Unidos, Willem de Kooning. Él perteneció a un grupo de 
                        artistas que buscó revolucionar el arte al aplicar técnicas novedosas para dar más 
                        expresión a los sentimientos.<br/><br/> 
                        
                        En 2015 fue vendido al millonario Kenneth C. Griffith por 300 millones de dólares. 
                        Durante dos años fue la pieza más cara de la historia, hasta que <em>Salvator mundi</em> ganó 
                        ese puesto. Hoy forma parte del catálogo de exposición del Instituto de Arte de 
                        Chicago.
                      </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <Grid container item justifyContent='center'>
                            <img className="Imagen2" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img4to/Sesion12/2.png" alt="Tarjeta" />
                        </Grid>
                        <Grid container item>
                            <p className='TextImg '>A partir de entonces, el pintor se convirtió en una figura sobresaliente de la vanguardia artística.</p>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container sm={12}>
                      <p className="Lectura "> 
                        Es común que se vendan obras a precios altos y que los creadores no reciban dinero. 
                        Por ejemplo, en estos casos ambos ya habían fallecido cuando se hicieron las 
                        subastas.<br/><br/> 
                        
                        <h3 className='SubTitulo'>La lista continúa…</h3><br/>
                        
                        Estas pinturas encabezan la lista de los diez cuadros más caros del mundo. Los ocho 
                        lugares restantes son ocupados por los siguientes títulos.<br/><br/>
                        <ol type='1' start={3} className="ul">
                            <li className='li'>
                                <p className='Lectura Esp2'><em>¿Cuándo te casas?</em>, hecho por Paul Gauguin en 1892.</p>
                            </li>
                            <li className='li'>
                                <p className='Lectura Esp2'><em>Los jugadores de cartas</em>, pintado por Paul Cézanne en 1895.</p>
                            </li>
                            <li className='li'>
                                <p className='Lectura Esp2'><em>Número 17A</em>, creado por Jackson Pollock en 1948.</p>
                            </li>
                            <li className='li'>
                                <p className='Lectura Esp2'><em>Número 6</em>, elaborado por Mark Rothko en 1951.</p>
                            </li>
                            <li className='li'>
                                <p className='Lectura Esp2'><em>Serpientes acuáticas II</em>, realizado por Gustav Klimt en 1907.</p>
                            </li>
                            <li className='li'>
                                <p className='Lectura Esp2'><em>El retrato de boda Marten Soolmans y Oopjen Coppit</em>, compuesto por Rembrandt van Rijn en 1634.</p>
                            </li>
                            <li className='li'>
                                <p className='Lectura Esp2'><em>Mujeres de Argel</em>, producido por Pablo Picasso en 1955.</p>
                            </li>
                            <li className='li'>
                                <p className='Lectura Esp2'><em>Nu couché</em>, terminado por Amedeo Modigliani en 1917.</p>
                            </li>
                        </ol>
                      </p>
                    </Grid>
                </Grid>
                
            <Grid container item xs={12} justifyContent="center" className="ContainerDerechos">
                <p className="Derechos">
                    © 2022. Todos los derechos reservados, Pearson, Circuito de lectores.
                </p>                
            </Grid>
        </Grid>
    </Grid>
    );
}
 
export default Los_dos_cuadros_más_caros_del_mundo;
