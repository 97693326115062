import React          from 'react';
import { withStyles } from '@material-ui/core/styles';
import Menu           from '@material-ui/core/Menu';
import Practica       from './Practica';

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'left',
    }}
    {...props}
  />
));


export default function CustomizedMenus(props) {
  const{ anchorEl, setAnchorEl, autogestList } = props
 
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {autogestList && 
          autogestList.map((autogestList, index) =>{
            return(
              <Practica
                key={index + "-autogestList"}
                index={index}
                source={autogestList?.recurso}
                grade={autogestList?.grado}
              />
            )
          })
        }
      </StyledMenu>
    </div>
  );
}
