import { Button, Grid, makeStyles } from "@material-ui/core";
import React, { useContext, useEffect } from "react";
import DrawerContext from "../../../../../usability/DrawerContext";
import { useOnLoadImages } from "../../../../hooks/useOnLoadImages";
import Loader from "../../../../Loader/Loader";
import Drawer from "../../ReadingTools/Drawer";

const useStyles =  makeStyles (theme =>({
      Contenedor:{
        background:'#E9FBF9',
        width:'100vw',
        overflowX:'clip',
        [theme.breakpoints.down(1161)]:{
            background:'none',
           overflowX:'unset',
        },
        [theme.breakpoints.down(600)]:{
            marginTop: '79.51px',
        }
    },
  ContainerInfoLectura: {
    position: "relative",
    height: "86px",
    [theme.breakpoints.down(502)]:{
        height: "43px",
    },
    background: "#76D4C3",
    "& .BarraInfoLectura": {
      height: "86px",
      background: `Url(https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Barras/Azul.png)`,
        [theme.breakpoints.down(502)]:{
            height: "43px",
        },
    },
    "& .InfoLectura": {
      position: "absolute",
      height: "86px",
      width: "219px",
      boxSizing: "border-box",
      padding: "17.2px 0",
      background: "#FAFAFA",
      borderRadius: "50px",
      boxShadow: "2px 2px 2px 2px #00000029",
      zIndex: "4",
        [theme.breakpoints.down(502)]:{
            height: "43px",
            width: "109px",
            padding: "8.6px 0",
            borderRadius: "25px",
        },
    },
    "& .InfoLectura:before": {
      position: "absolute",
      display: "flex",
      boxSizing: "border-box",
      padding: "0 0 0 10px",
      justifyContent: "center",
      alignItems: "center",
      height: "39.88px",
      width: "39.88px",
      borderRadius: "50%",
      content: '""',
      boxShadow: "3px 0px .5px 0px #00000029",
      background: "#FAFAFA",
      left: "90px",
      top: "65px",
      transform: "rotate(0.25turn)",
      [theme.breakpoints.down(502)]:{
        left: "45px",
        top: "30px",
        height: "20.88px",
        width: "20.88px",
        },
    },
    "& .InfoLectura:after": {
      content: '""',
      height: "15px",
      width: "15px",
      borderTop: "2px solid #20A698",
      borderLeft: "2px solid #20A698",
      position: "absolute",
      bottom: "-10px",
      left: "Calc(50% - 8px)",
      transform: "rotate(0.625turn)",
      [theme.breakpoints.down(502)]:{
        height: "10px",
        width: "10px",
        left: "Calc(50% - 4.5px)",
        bottom: "-3px",
        borderTop: "1px solid #20A698",
        borderLeft: "1px solid #20A698",
        },
    },
    "& .Sesion": {
      borderLeft: "2px solid #D8D8D8",
    },
    "& .Dato": {
      margin: "0 0 5px 0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#20A698",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        margin: "0 0 2.5px 0",
        },
    },
    "& .Caracter": {
      margin: "0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#000000",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        },
    },
       '& .Regresar':{
        color: '#F2624F',
        border: '#F2624F 1px solid',
        height: '30px',
        width:'100px',
        borderRadius: '20px',
        letterSpacing: '0.14px',
        textTransform: 'none',
        background:'#FEFAF1',
        top:'calc(50% - 15px)',
        left:'5%',
        zIndex:4,
        [theme.breakpoints.down(502)]: {
          font:'bold 10px lato',
          width: "60px",
        },
      },
  },
    ContenedorLectura:{
        background:'#fff',
        width:'1160px',
        height:'100%',
        padding:'0 ',
        position:'relative',
        
        transform: (props) =>
        props.scale > 1 ? `scale(${props.scale}) translate(0px, 10%)` : `none`,
        [theme.breakpoints.down(1161)]:{
            width:'768px',
            padding:'0',
          },
          [theme.breakpoints.down(768)]:{
            width:'350px',
            padding:'0',
          },
        '& .Derechos':{
            bottom:'28px',
            textAlign:"center",
            margin:"0px",
            font:" 15px lato",
            color:"#FFFFFF",
            [theme.breakpoints.down(1161)]:{
                display:'none',
            },
        },
        '& .Pos':{
            position:'absolute',
            margin:'0',
        },
        '& .Titulo':{
            font:'71px Fredoka One', 
            color:'#20668A', 
            top:'60px',
            lineHeight:'86px',
            [theme.breakpoints.down(1161)]:{
                font:'47px Fredoka One', 
                lineHeight:'57px',
                top:'40px',
            },
            [theme.breakpoints.down(768)]:{
                font:'18px fredoka one',
                lineHeight:'32px',
                top:'20px',
            },
        }, 
        '& .SubTitulo':{
            font:'34px Fredoka One',
            lineHeight:'41px',
            textAlign:'center',
            [theme.breakpoints.down(1161)]:{
                font:'22px Fredoka One', 
                lineHeight:'26px',
            },
            [theme.breakpoints.down(768)]:{
                font:'10px Fredoka One',
                lineHeight:'12px',
            },
        } ,
        '& .SubTitulo1':{
            color:'#20668A',
            top:'1410px',
            left:'202px',
            [theme.breakpoints.down(1161)]:{
                top:'936px',
                left:'132px',
            },
            [theme.breakpoints.down(1161)]:{
                top:'422px',
                left:'60px',
            },
        } ,
        '& .SubTitulo2':{
            color:'#330F65',
            top:'2594px',
            left:'186px',
            [theme.breakpoints.down(1161)]:{
                top:'1722px',
                left:'121px',
            },
            [theme.breakpoints.down(768)]:{
                top:'782px',
                left:'60px',
            },
        } ,
        '& .SubTitulo3':{
            color:'#20668A',
            top:'3143px',
            left:'299.5px',
            [theme.breakpoints.down(1161)]:{
                top:'2085.97px',
                left:'196.77px',
            },
            [theme.breakpoints.down(768)]:{
                top:'946px',
                left:'90px',
            },
        } ,
        '& .SubSubTitulo':{
            font:'25px Fredoka One',
            lineHeight:'30px',
            textAlign:'center',
            color:'#FFFFFF',
            [theme.breakpoints.down(1161)]:{
                font:'15px Fredoka One',
                lineHeight:'19px',
            },
            [theme.breakpoints.down(768)]:{
                font:'7px Fredoka One',
                lineHeight:'8px',
            },
        } ,
        '& .SubSubTitulo1':{
            left:'240.5px',
            top:'414px',
            [theme.breakpoints.down(1161)]:{
                left:'157.62px',
                top:'274.77px',
            },
            [theme.breakpoints.down(768)]:{
                top:'125px',
                left:'80px',
            },
        } ,
        '& .SubSubTitulo2':{
            left:'665.5px',
            top:'414px',
            color:'#00A7C4',
            [theme.breakpoints.down(1161)]:{
                left:'439.68px',
                top:'274.77px',
            },
            [theme.breakpoints.down(768)]:{
                top:'125px',
                left:'207px',
            },
        } ,
        '& .SubSubTitulo3':{
            top:'740px',
            left:'211.5px',
            color:'#20668A',
            [theme.breakpoints.down(1161)]:{
                top:'491.13px',
                left:'138.37px',
            },
            [theme.breakpoints.down(768)]:{
                top:'222px',
                left:'66px',
            },
        } ,
        '& .SubSubTitulo4':{
            top:'2021px',
            left:'210.5px',
            [theme.breakpoints.down(1161)]:{
                top:'1341.31px',
                left:'137.71px',
            },
            [theme.breakpoints.down(768)]:{
                top:'612px',
                left:'66px',
            },
        } ,
        '& .SubSubTitulo5':{
            top:'2021px',
            left:'709.5px',
            [theme.breakpoints.down(1161)]:{
                top:'1341.31px',
                left:'468.90px',
            },
            [theme.breakpoints.down(768)]:{
                top:'612px',
                left:'216px',
            },
        } ,
        '& .Texto':{
            fontSize:'22px',
            fontFamily:'lato',
            color:'#FFFFFF',
            lineHeight:'30px',
            letterSpacing:'.21px',
            top:'477px',
            [theme.breakpoints.down(1161)]:{
                fontSize:'14px',
                fontFamily:'lato',
                letterSpacing:'.13px',
            },
            [theme.breakpoints.down(768)]:{
                fontSize:'6px',
                fontFamily:'lato',
                letterSpacing:'.09px',
            },
        } ,
        '& .Texto1':{
            left:'319px',
            [theme.breakpoints.down(1161)]:{
                lineHeight:'19px',
                left:'210px',
                top:'317px',
            },
            [theme.breakpoints.down(768)]:{
                top: '139px',
                left: '90px',
            },
        } ,
        '& .Texto2':{
            top:'511px',
            left:'173px',
            [theme.breakpoints.down(1161)]:{
                lineHeight:'19px',
                left:'113px',
                top:'339px',
            },
            [theme.breakpoints.down(768)]:{
                top: '150px',
                left: '51px',
            },
        } ,
        '& .Texto3':{
            left:'685px',
            color:'#20668A', 
            [theme.breakpoints.down(1161)]:{
                lineHeight:'19px',
                left:'445px',
                top:'317px',
            },
            [theme.breakpoints.down(768)]:{
                top: '139px',
                left: '196px',
            },
        } ,
        '& .Texto4':{
            color:'#20668A',
            top:'865px',
            left:'657px',
            lineHeight:'33px',
            letterSpacing:'-.26px',  
            [theme.breakpoints.down(1161)]:{
                lineHeight:'20px',
                left:'434px',
                top:'574px',
            },
            [theme.breakpoints.down(768)]:{
                lineHeight:'10px',
                left:'200px',
                top:'260px',
            },
        } ,
        '& .Agua':{
            font:'bold 26px lato',
            color:'#20668A',
            lineHeight:'30px',
            letterSpacing:'.24px',  
            [theme.breakpoints.down(1161)]:{
                font:'bold 17px lato',
                lineHeight:'20px',
                letterSpacing:'.16px',
            },
            [theme.breakpoints.down(768)]:{
                font:'bold 8px lato',
                lineHeight:'12px',
                letterSpacing:'.10px',
            },
        },
        '& .Texto5':{
            top:'825px',
            left:'174px',
            [theme.breakpoints.down(1161)]:{
                top:'548px',
                left:'117px',
            },
            [theme.breakpoints.down(768)]:{
                top:'247px',
                left:'54px',
            }
        },
        '& .Texto6':{
            top:'1163px',
            left:'169px',
            [theme.breakpoints.down(1161)]:{
                top:'772px',
                left:'110px',
            },
            [theme.breakpoints.down(768)]:{
                top:'347px',
                left:'54px',
            }
        },

        '& .Por':{
            color:'#20668A',
            left:'379px',
            lineHeight:'30px',
            letterSpacing:'.21px',  
            [theme.breakpoints.down(1161)]:{
                lineHeight:'19px',
                letterSpacing:'.13px',
                left:'249px',
            },
            [theme.breakpoints.down(768)]:{
                lineHeight:'8px',
                letterSpacing:'.13px',
                left:'119px',
            }
        },

        '& .Texto7':{
            top:'852px',
            [theme.breakpoints.down(1161)]:{
                top:'565px',
            },
            [theme.breakpoints.down(768)]:{
                top:'256px',
            }
        },
        '& .Texto8':{
            top:'919px',
            [theme.breakpoints.down(1161)]:{
                top:'610px',
            },
            [theme.breakpoints.down(768)]:{
                top:'277px',
            }
        },
        '& .Texto9':{
            top:'1016px',
            [theme.breakpoints.down(1161)]:{
                top:'674px',
            },
            [theme.breakpoints.down(768)]:{
                top:'306px',
            }
        },
        '& .Por2':{
            lineHeight:'33px', 
            color:'#20668A',
            letterSpacing:'.21px', 
            [theme.breakpoints.down(1161)]:{
                lineHeight:'21px',
            },
            [theme.breakpoints.down(768)]:{
                lineHeight:'10px',
            } 
        },

        '& .Texto10':{
            left:'217px',
            top:'1214px',
            [theme.breakpoints.down(1161)]:{
                left:'142px',
                top:'803px',
            }, 
            [theme.breakpoints.down(768)]:{
                left:'66px',
                top:'366px',
            },
        },
        '& .Uso':{
            color:'#20668A',
            top:'1720px',
            [theme.breakpoints.down(1161)]:{
                top:'1142px',
                lineHeight:'19px',
                letterSpacing:'.13px',
            }, 
            [theme.breakpoints.down(768)]:{
                top:'514px',
                lineHeight:'19px',
                letterSpacing:'.13px',
            },
        },
        '& .Texto11':{
            left:'178px',
            [theme.breakpoints.down(1161)]:{
                left:'116px',
            }, 
            [theme.breakpoints.down(768)]:{
                left:'61px',
            }, 
        },
        '& .Texto12':{
            left:'506px',
            [theme.breakpoints.down(1161)]:{
                left:'334px',
            },
            [theme.breakpoints.down(768)]:{
                left:'150px',
            },  
        },
        '& .Texto13':{
            left:'767px',
            [theme.breakpoints.down(1161)]:{
                left:'507px',
            },
            [theme.breakpoints.down(768)]:{
                left:'234px',
            },
        },
        '& .Texto14':{
            color:'#20668A',
            top:'1779px',
            left:'202px',
            [theme.breakpoints.down(1161)]:{
                top:'1181px',
                left:'137px',
                lineHeight:'19px',
                letterSpacing:'.13px',
            },
            [theme.breakpoints.down(768)]:{
                top: '532px',
                left: '69px',
                lineHeight: '11px',
                letterSpacing:'.13px',
            }
        },
        '& .Pais':{
            left:'293px',
            [theme.breakpoints.down(1161)]:{
                left:'192px',
                lineHeight:'19px',
                letterSpacing:'.13px',
            }, 
            [theme.breakpoints.down(768)]:{
                left:'90px',
                lineHeight:'19px',
                letterSpacing:'.13px',
            }, 
        },
        '& .Texto15':{
            top:'2145px',
            [theme.breakpoints.down(1161)]:{
                top:'1420px',
            },
            [theme.breakpoints.down(768)]:{
                top:'641px',
            } 
        },
        '& .Texto16':{
            top:'2228px',
            [theme.breakpoints.down(1161)]:{
                top:'1470px',
            },
            [theme.breakpoints.down(768)]:{
                top:'667px',
            } 
        },
        '& .Texto17':{
            top:'2304px',
            [theme.breakpoints.down(1161)]:{
                top:'1520px',
            },
            [theme.breakpoints.down(768)]:{
                top:'688px',
            } 
        },
        '& .Texto18':{
            top:'2375px',
            [theme.breakpoints.down(1161)]:{
                top:'1571px',
            },
            [theme.breakpoints.down(768)]:{
                top:'712px',
            } 
        },
        '& .Texto19':{
            top:'2433px',
            [theme.breakpoints.down(1161)]:{
                top:'1610px',
            },
            [theme.breakpoints.down(768)]:{
                top:'729px',
            } 
        },
        '& .Texto20':{
            color:'#330F65',
            top:'2677px',
            left:'188px',
            [theme.breakpoints.down(1161)]:{
                lineHeight:'19px',
                letterSpacing:'.13px',
                top:'1777px',
                left:'123px',
            }, 
            [theme.breakpoints.down(768)]:{
                lineHeight:'9px',
                letterSpacing:'.13px',
                top:'796px',
                left:'63px',
            },
        },
        '& .Br':{
            margin:'0 0 10px 0',
            [theme.breakpoints.down(1161)]:{
                margin:'0 0 8px 0',
            } 
        },
        '& .Imagen':{
            [theme.breakpoints.down(1161)]:{
                width:'100%',
            },
        },
    },
    Esconder: {
        [theme.breakpoints.down(768)]:{
            display:'none'
        },
    },
    Imagen: {
        display:'none',
        [theme.breakpoints.down(768)]:{
            width:'100%',
            display:'flex'
        },
    },

    
}));

const La_hidrósfera = (props) => {

  const {activeStep, setActiveStep, handlePreviewStep, id_actividad} = props

  const handleContinue = () => {
    setActiveStep(activeStep - 1)
    handlePreviewStep()
  }

    const {
    handleSize,
    disabledZoom,
    closeZoom,
    handleZoom,
    scale
  }= useContext(DrawerContext);
  const [loading, setLoading] = React.useState(true);
  const wrapperRef = React.useRef(null);
  const imagesLoaded = useOnLoadImages(wrapperRef);

  const detectSize = () => {
    const lectura = document.getElementById("lectura");
    const newWidth = lectura?.offsetWidth;
    const newHeight = lectura?.offsetHeight;
    setLoading(false);
    if (lectura) handleSize(newHeight, newWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", detectSize);
    return () => {
      handleZoom()
      window.removeEventListener("resize", detectSize);
      closeZoom()
    };
  }, []);

  const classes = useStyles({ disabledZoom,scale });
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
             <Grid container item justifyContent="center" className={classes.ContainerInfoLectura}>
               <Grid container className="BarraInfoLectura">
                    <Button className="Regresar" onClick={handleContinue}>
                        Regresar
                    </Button>
                </Grid>
               <Grid container item className="InfoLectura">
                    <Grid container item direction="column" xs={6} justifyContent="center">
                        <p className="Dato">Grado</p>
                        <p className="Caracter">6</p>
                    </Grid>
                    <Grid container item direction="column" xs={6} justifyContent="center" className="Sesion">
                        <p className="Dato">Sesión</p>
                        <p className="Caracter">14</p>
                    </Grid>
               </Grid>
            </Grid>
{!imagesLoaded && <Loader />}
        <Grid
            container
            item
            className={classes.ContenedorLectura}
            justifyContent="center"
            id="lectura"
            onLoad={detectSize}
            ref={wrapperRef}
        >
<Drawer id_lectura={id_actividad} withoutTransform ={true} />
        <Grid container item justifyContent="center" className={classes.Esconder}>

                <h2 className='Pos Titulo'>La hidrósfera</h2>
                <h3 className='Pos SubTitulo SubTitulo1'>Uso mundial del agua dulce</h3>
                <h3 className='Pos SubTitulo SubTitulo2'>La contaminación del agua</h3>
                <h3 className='Pos SubTitulo SubTitulo3'>Alrededor de un millón de especies <br/>vivas habitan en los océanos</h3>
                <h4 className='Pos SubSubTitulo SubSubTitulo1'>Proporciones de agua<br/> salada y agua dulce</h4>
                <h4 className='Pos SubSubTitulo SubSubTitulo2'>Distribución del agua <br/>en la Tierra</h4>
                <h4 className='Pos SubSubTitulo SubSubTitulo3'>Distribución de agua dulce <br/>en la Tierra</h4>
                <h4 className='Pos SubSubTitulo SubSubTitulo4'>Los 5 países con mayores <br/>recursos de agua dulce <br/>por persona</h4>
                <h4 className='Pos SubSubTitulo SubSubTitulo5'>Ciclo del agua</h4>
                <b className='Pos Texto Texto1'>Agua dulce: 2.5 %</b>
                <b className='Pos Texto Texto2'>Agua salada: 97.5 %</b>
                <b className='Pos Texto Texto3'>Océanos y mares: 96.8 %</b>
                <b className='Pos Texto Texto4'>
                    Glaciares: 2 %<br/>
                    Agua subterránea: 1 %<br/>
                    Lagos y ríos: 0.1 %<br/>
                    Agua en el aire: 0.001 %<br/>
                </b>
                <b className='Pos Texto5 Agua'>Agua dulce</b>
                <b className='Pos Texto6 Agua'>Agua superficial</b>
                <p className='Pos Texto Texto7 Por'>
                    Glaciares:<br/>
                    68.7 %<br/>
                </p>
                <p className='Pos Texto Texto8 Por'>
                    Agua<br/>
                    subterránea:<br/>
                    30.1 %<br/>
                </p>
                <p className='Pos Texto Texto9 Por'>
                    Agua superficial:<br/>
                    1.2 %
                </p>
                <p className='Pos Texto Texto10 Por2'>
                    Lagos: 87 %<br/>
                    Pantanos: 11 %<br/>
                    Ríos: 2 %<br/>
                </p>
                <b className='Pos Texto Texto11 Uso'>Uso agrícola (riego): 70 %</b>
                <b className='Pos Texto Texto12 Uso'>Uso industrial: 20 %</b>
                <b className='Pos Texto Texto13 Uso'>Uso doméstico: 10 %</b>
                <b className='Pos Texto Texto14'> 
                En muchas regiones del mundo, más del 70 % del agua dulce se usa en la <br/>
                agricultura. Se calcula que en el año 2050, alimentar a un planeta de 9 mil <br/>
                millones de personas requerirá un aumento estimado del 50 % en la <br/>
                producción agrícola y un aumento del 15 % en la extracción de agua dulce.
                </b>
                <b className='Pos Texto Texto15 Pais'>Brasil: 6 950 km<sup>3</sup></b>
                <b className='Pos Texto Texto16 Pais'>Rusia: 4 500 km<sup>3</sup></b>
                <b className='Pos Texto Texto17 Pais'>Canadá: 2 900 km<sup>3</sup></b>
                <b className='Pos Texto Texto18 Pais'>China: 2 800 km<sup>3</sup></b>
                <b className='Pos Texto Texto19 Pais'>Indonesia: 2 530 km<sup>3</sup></b>
                <p className='Pos Texto Texto20'>
                El 80 % de la contaminación del océano proviene de fuentes<br/> 
                terrestres, como petróleo, pesticidas, fosas sépticas, suciedad,<br/> 
                descarga de nutrientes, aguas residuales sin árboles, escorrentías,<br/> 
                granjas, vehículos de motor, ranchos y granjas, etcétera.<div className='Br'></div>

                La contaminación del agua causa la muerte de aproximadamente 10 000<br/> 
                personas alrededor del mundo todos los días, es decir, 3.6 millones de personas<br/>
                cada año.<div className='Br'></div>

                El petróleo es más dañino para el ecosistema del océano que el agua<br/> 
                contaminada y la basura. Cada año se derraman 1.3 millones de galones de<br/> 
                petróleo en los océanos.<div className='Br'></div>

                40 000 toneladas de plásticos flotan actualmente en la superficie de los océanos.

                </p>  
                <img className='Imagen' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion14/1.png" alt="Fondo" />
                </Grid>
                <img className={classes.Imagen} src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion14/F.png" alt="Fondo" />

            </Grid>
            <Grid container item style={{ marginTop: 80 }}></Grid>
        </Grid>
    );
}
 
export default La_hidrósfera;
