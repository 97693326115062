import React from "react";
import { makeStyles } from "@material-ui/core";
import logo from "./CL_LOGO_FINAL_Login 1.png";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100vw",
    height: "100vh",
    position: "fixed",
    zIndex: "999",
  },

  loader: {
    backdropFilter: "blur(11px)",
    backgroundColor: "rgba(0,0,0, .55)",
    bottom: 0,
    left: 0,
    overflow: "hidden",
    position: "fixed",
    right: 0,
    top: 0,
  },
  loaderInner: {
    bottom: 0,
    height: "170px",
    left: 0,
    margin: "auto",
    position: "absolute",
    right: 0,
    top: 0,
    width: "180px",
    [theme.breakpoints.up("md")]: {
      height: "270px",
      width: "270px",
    },
  },

  loaderLineWrap: {
    boxSizing: "border-box",
    height: "180px",
    width: "180px",
    left: 0,
    overflow: "hidden",
    top: 0,
    transformOrigin: "50% 100%",
    position: "absolute",
    [theme.breakpoints.up("md")]: {
      height: "270px",
      width: "270px",
    },

    "& .loaderLine": {
      border: "5px solid transparent",
      borderRadius: "100%",
      boxSizing: "border-box",
      height: "100px",
      margin: "0 auto",
      position: "absolute",
      animation: "$spin 1500ms  infinite",
      top: 0,
      right: 0,
      left: 0,
      width: "100px",
      [theme.breakpoints.up("md")]: {
        border: "7.5px solid transparent",
        height: "150px",
        width: "150px",
      },
    },
    "&:nth-child(1)": {
      "& .loaderLine": {
        animationDelay: "-50ms",
        borderColor: "#B965D3",
        height: "160px",
        width: "160px",
        top: "5px",
        [theme.breakpoints.up("md")]: {
          height: "240px",
          width: "240px",
        },
      },
    },
    "&:nth-child(2)": {
      "& .loaderLine": {
        animationDelay: "-100ms",
        borderColor: "#2EDAC9",
        height: "140px",
        width: "140px",
        top: "14px",
        [theme.breakpoints.up("md")]: {
          height: "210px",
          width: "210px",
          top: "21px",
        },
      },
    },
    "&:nth-child(3)": {
      "& .loaderLine": {
        animationDelay: "-200ms",
        borderColor: "#F6644B",
        height: "120px",
        width: "120px",
        top: "24px",
        [theme.breakpoints.up("md")]: {
          height: "180px",
          width: "180px",
          top: "36px",
        },
      },
    },
    "&:nth-child(4)": {
      "& .loaderLine": {
        animationDelay: "-300ms",
        borderColor: "#FFC300",
        height: "100px",
        width: "100px",
        top: "35px",
        [theme.breakpoints.up("md")]: {
          height: "150px",
          width: "150px",
          top: "52px",
        },
      },
    },
    "&:nth-child(5)": {
      overflow: "visible",
      "& .loaderLine": {
        animation: "none",
        // borderColor: '#591AAF',
        // backgroundColor: '#591AAF',
        border: "none",
        padding: "0",
        height: "80px",
        width: "80px",
        top: "45px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        [theme.breakpoints.up("md")]: {
          height: "120px",
          width: "120px",
          top: "67.5px",
        },
      },
    },
  },
  img: {
    width: "100%",
  },

  "@keyframes spin": {
    "0%": {
      transform: "rotate(0)",
    },
    "75%": {
      borderBottomColor: "transparent",
    },
    "100%": {
      transform: "rotate(360deg)",
    },
  },
}));

const Loader = () => {
  const styles = useStyles();
  return (
    <div className={styles.container}>
      <div className={styles.loader}>
        <div className={styles.loaderInner}>
          <div className={styles.loaderLineWrap}>
            <div className="loaderLine"></div>
          </div>

          <div className={styles.loaderLineWrap}>
            <div className="loaderLine"></div>
          </div>

          <div className={styles.loaderLineWrap}>
            <div className="loaderLine"></div>
          </div>

          <div className={styles.loaderLineWrap}>
            <div className="loaderLine"></div>
          </div>

          <div className={styles.loaderLineWrap}>
            <div className="loaderLine">
              <img src={logo} className={styles.img} alt="Logo Circuito de Lectores" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Loader;
