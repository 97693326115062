import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
    ContenedorLectura:{
        height:'100%',
        width:'502px',
                  [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
        '& .Escritorio':{
            display:'flex',
            [theme.breakpoints.down(1161)]:{
                display:'none'
            },
        },
        '& .Tablet':{
            display:'none',
            [theme.breakpoints.down(1161)]:{
                display:'flex'
            },
        }
    },
    ContainerTitulo:{
        margin:'0 0 34px 0',
        '& .Titulo':{
            letterSpacing: '0px',
            color: '#CB3668',
            margin:'0',
            textAlign:'center',
            font:'43px fredoka one',
            lineHeight:'52px',
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        position:'relative',
        '& .Lectura':{
            margin:'0 ',
            color:'#000',
            font:'18px lato',
            lineHeight :'28px',
            letterSpacing: '0.17px',
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .Esp':{
            margin:'0 0 36px 0'
        },
        '& .Esp1':{
            margin:' 0',
        },
        '& .Pos':{
            position:'relative',
            top:'-135px',
        },
        '& .Esp2':{
            margin:'36px 0 0 0'
        },
        '& .Imagen':{
            width:'87.3%',
        },  
      
    }
}));

const Flix_el_elefante = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item xs={12} className={classes.ContainerTitulo} justifyContent="center">
                        <h2 className="Titulo">Flix, el elefante</h2>
                </Grid>
                <Grid container item xs={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container xs={12} >
                       <p className='Lectura Esp'>
                        A Mateo, un niño de siete años, le encantaba leer <em>Las aventuras 
                        de Flix</em>. Flix era un elefante que vivía en Tailandia. Le encantaba 
                        correr, tomar baños de lodo y mojar a otros animales con su 
                        trompa.
                       </p>                   
                    </Grid>

                    <Grid container xs={12} justifyContent='center' >
                        <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img1ro/Sesion6/1.png" alt="Tarjeta" />
                    </Grid>

                    <Grid container xs={12}>
                        <p className='Lectura Esp2'>
                            Un día, ¡Flix se salió de las páginas del cuento! Mateo no podía 
                            creerlo. Se quedó boquiabierto, ¡hasta que Flix mojó con su 
                            trompa la habitación y a Mateo y a su mamá! Después, Flix 
                            corrió y rompió tres adornos.
                            <br/><br/>
                            —¡Mateeooooo! —gritó mamá.
                            <br/><br/>
                            Flix volteó a ver a Mateo y lo ayudó a secar y a ordenar.
                            <br/><br/>
                            Los días siguientes, el nuevo amigo de Mateo se portó mejor. 
                            Jugaban juntos y luego recogían los juguetes. Comían nueces y 
                            maníes. Después, recogían las cáscaras y salían a pasear  
                            tomados de la mano de mamá.
                        </p> 
                    </Grid>

                </Grid>

                <Grid container item xs={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container xs={12} justifyContent='center'>
                        <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img1ro/Sesion6/2.png" alt="Tarjeta" />
                    </Grid>
                    <Grid container xs={12} >
                       <p className='Lectura Esp1'>
                        A Mateo le gustaba rascarle las orejas a Flix y darle helado. Leían cuentos y dormían juntos, pero Flix estaba triste.
                        <br/><br/>
                        —Mateo —dijo mamá—, Flix extraña a su familia. Debe regresar a casa.
                        <br/><br/>
                        Mateo se entristeció, pero entendió que Flix pertenecía a Tailandia. Flix abrazó a Mateo y se metió de nuevo al libro.
                        <br/><br/>
                        Mateo siguió leyendo las historias de Flix. Algunas veces, el elefante salía del libro para visitar a Mateo.
                       </p>                   
                    </Grid>

                </Grid>

                <Grid container item xs={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container xs={12} justifyContent='center' >
                        <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img1ro/Sesion6/3.png" alt="Tarjeta" />
                    </Grid>
                </Grid>
        </Grid>
    </Grid>
    );
}
 
export default Flix_el_elefante;
