import { Button, Grid, makeStyles } from "@material-ui/core";
import React, { useContext, useEffect } from "react";
import DrawerContext from "../../../../../usability/DrawerContext";
import { useOnLoadImages } from "../../../../hooks/useOnLoadImages";
import Loader from "../../../../Loader/Loader";
import Drawer from "../../ReadingTools/Drawer";

const useStyles =  makeStyles (theme =>({
     Contenedor:{
        background:'#E9FBF9',
        width:'100vw',
        overflowX:'clip',
        [theme.breakpoints.down(1161)]:{
            background:'none',
           overflowX:'unset',
        },
        [theme.breakpoints.down(600)]:{
            marginTop: '79.51px',
        }
    },
  ContainerInfoLectura: {
    position: "relative",
    height: "86px",
    [theme.breakpoints.down(502)]:{
        height: "43px",
    },
    background: "#76D4C3",
    "& .BarraInfoLectura": {
      height: "86px",
      background: `Url(https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Barras/Azul.png)`,
        [theme.breakpoints.down(502)]:{
            height: "43px",
        },
    },
    "& .InfoLectura": {
      position: "absolute",
      height: "86px",
      width: "219px",
      boxSizing: "border-box",
      padding: "17.2px 0",
      background: "#FAFAFA",
      borderRadius: "50px",
      boxShadow: "2px 2px 2px 2px #00000029",
      zIndex: "4",
        [theme.breakpoints.down(502)]:{
            height: "43px",
            width: "109px",
            padding: "8.6px 0",
            borderRadius: "25px",
        },
    },
    "& .InfoLectura:before": {
      position: "absolute",
      display: "flex",
      boxSizing: "border-box",
      padding: "0 0 0 10px",
      justifyContent: "center",
      alignItems: "center",
      height: "39.88px",
      width: "39.88px",
      borderRadius: "50%",
      content: '""',
      boxShadow: "3px 0px .5px 0px #00000029",
      background: "#FAFAFA",
      left: "90px",
      top: "65px",
      transform: "rotate(0.25turn)",
      [theme.breakpoints.down(502)]:{
        left: "45px",
        top: "30px",
        height: "20.88px",
        width: "20.88px",
        },
    },
    "& .InfoLectura:after": {
      content: '""',
      height: "15px",
      width: "15px",
      borderTop: "2px solid #20A698",
      borderLeft: "2px solid #20A698",
      position: "absolute",
      bottom: "-10px",
      left: "Calc(50% - 8px)",
      transform: "rotate(0.625turn)",
      [theme.breakpoints.down(502)]:{
        height: "10px",
        width: "10px",
        left: "Calc(50% - 4.5px)",
        bottom: "-3px",
        borderTop: "1px solid #20A698",
        borderLeft: "1px solid #20A698",
        },
    },
    "& .Sesion": {
      borderLeft: "2px solid #D8D8D8",
    },
    "& .Dato": {
      margin: "0 0 5px 0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#20A698",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        margin: "0 0 2.5px 0",
        },
    },
    "& .Caracter": {
      margin: "0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#000000",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        },
    },
       '& .Regresar':{
        color: '#F2624F',
        border: '#F2624F 1px solid',
        height: '30px',
        width:'100px',
        borderRadius: '20px',
        letterSpacing: '0.14px',
        textTransform: 'none',
        background:'#FEFAF1',
        top:'calc(50% - 15px)',
        left:'5%',
        zIndex:4,
        [theme.breakpoints.down(502)]: {
          font:'bold 10px lato',
          width: "60px",
        },
      },
  },
    ContenedorLectura:{
        background:'#fff',
        width:'1160px',
        height:'100%',
                padding:'60px 168px 35px ',
        
        transform: (props) =>
        props.scale > 1 ? `scale(${props.scale}) translate(0px, 10%)` : `none`,
        [theme.breakpoints.down(1161)]:{
            width:'502px',
            padding:'60px 0 0 0',
          },
        [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },

        '& .Escritorio':{
            display:'flex',
            [theme.breakpoints.down(1161)]:{
                display:'none'
            },
        },
        '& .Tablet':{
            display:'none',
            [theme.breakpoints.down(1161)]:{
                display:'flex'
            },
        }
    },
    ContainerTitulo:{
        margin:'0 0 34px 0',
        [theme.breakpoints.down(1161)]:{
            margin:'0 0 34px 0',
        },
        '& .Titulo':{
            font:'48px fredoka one',
            letterSpacing: '0px',
            lineHeight:'58px',
            color: '#1AA4E2',
            margin:'0',
            textAlign:'center',
            [theme.breakpoints.down(1161)]:{
                font:'43px fredoka one',
                lineHeight:'52px',
            },
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            lineHeight : '30px',
            margin:'0 0 36px 0',
            font:'22px lato',
            color:'#000',
            letterSpacing: '0.20px',
            [theme.breakpoints.down(1161)]:{
                font:'18px lato',
                lineHeight :'28px',
                letterSpacing: '0.17px',
            },
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .SubTitulo':{
            font:'bold 30px lato',
            color:'#213468',
            letterSpacing:'.28px',
            lineHeight:'30px',
            margin:'0',
            [theme.breakpoints.down(1161)]:{
                lineHeight :'28px',
                font:'bold 26px lato',
                letterSpacing: '0.24px',
            },
            [theme.breakpoints.down(502)]:{
                font:'bold 18px lato',
                lineHeight :'24px',
                letterSpacing: '0.21px',
            },
        },
        '& .SubTitulo2':{
            font:'bold 26px lato',
            color:'#5EA826',
            letterSpacing:'.24px',
            lineHeight:'28px',
            margin:'0',
            [theme.breakpoints.down(1161)]:{
                font:'bold 22px lato',
                lineHeight :'24px',
                letterSpacing: '0.21px',
            },
            [theme.breakpoints.down(502)]:{
                font:'bold 18px lato',
                lineHeight :'20px',
                letterSpacing: '0.16px',
            },
        },
        '& .TextImg':{
            font:'20px lato',
            color:'#717476',
            letterSpacing:'.19px',
            lineHeight:'30px',
            margin:'24px 0 0 0',
            [theme.breakpoints.down(1161)]:{
                font:' 16px lato',
                lineHeight :'25px',
                letterSpacing: '0.15px',
            },
            [theme.breakpoints.down(502)]:{
                font:' 11px lato',
                lineHeight :'14px',
                letterSpacing: '0.10px',
            },
        },

        '& .Imagen':{
            width:'833px',
            [theme.breakpoints.down(1161)]:{
                width:'100%',
            },
        },
       
      
    }
}));

const El_norte_magnético_y_el_norte_geográfico = (props) => {

  const {activeStep, setActiveStep, handlePreviewStep, id_actividad} = props
  
  const handleContinue = () => {
    setActiveStep(activeStep - 1)
    handlePreviewStep()
  }

    const {
      handleSize,
    disabledZoom,
    closeZoom,
    handleZoom,
    scale
  }= useContext(DrawerContext);
  const [loading, setLoading] = React.useState(true);
  const wrapperRef = React.useRef(null);
  const imagesLoaded = useOnLoadImages(wrapperRef);

  const detectSize = () => {
    const lectura = document.getElementById("lectura");
    const newWidth = lectura?.offsetWidth;
    const newHeight = lectura?.offsetHeight;
    setLoading(false);
    if (lectura) handleSize(newHeight, newWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", detectSize);
    return () => {
      handleZoom()
      window.removeEventListener("resize", detectSize);
      closeZoom()
    };
  }, []);

  const classes = useStyles({ disabledZoom,scale });
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
             <Grid container item justifyContent="center" className={classes.ContainerInfoLectura}>
                       <Grid container className="BarraInfoLectura">
            <Button className="Regresar" onClick={handleContinue}>
                Regresar
            </Button>
        </Grid>
               <Grid container item className="InfoLectura">
                    <Grid container item direction="column" xs={6} justifyContent="center">
                        <p className="Dato">Grado</p>
                        <p className="Caracter">5</p>
                    </Grid>
                    <Grid container item direction="column" xs={6} justifyContent="center" className="Sesion">
                        <p className="Dato">Sesión</p>
                        <p className="Caracter">4</p>
                    </Grid>
               </Grid>
            </Grid>
            {!imagesLoaded && <Loader />}
        <Grid
            container
            item
            className={classes.ContenedorLectura}
            justifyContent="center"
            id="lectura"
            onLoad={detectSize}
            ref={wrapperRef}
        >
<Drawer id_lectura={id_actividad} />
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                        <h2 className="Titulo">El norte magnético <br/>y el norte geográfico</h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                       <p className='Lectura'>
                        ¿Cómo llegas a un lugar que no conoces? Puedes utilizar un mapa, una guía o (si
                        cuentas con un dispositivo móvil y acceso a internet) una aplicación que te dé 
                        indicaciones precisas de cómo hacerlo. Incluso podrías preguntarle a alguien. <br/><br/>
                        
                        ¿Cómo crees que la gente lograba orientarse cuando no tenía ningún instrumento? 
                        Piensa en los marineros, que no tenían a su alrededor más que agua. Empleaban
                        puntos de referencia, como el sitio por donde salía o se ocultaba el Sol y la 
                        posición de las estrellas durante la noche. <br/><br/>
                        
                        El Sol sale por el este y se oculta por el oeste. Por eso al este también se le 
                        llama <em>oriente</em>, que viene del latín <em>oriens</em> y significa “alzándose” o “naciendo”.
                        Al oeste también se le llama <em>poniente</em> u <em>occidente</em>, que 
                        vienen del latín ponens y occidens (“cayendo” o “muriendo”). La palabra 
                        <em> orientación</em> está relacionada con el punto cardinal por el que sale el Sol.<br/><br/> 
                        
                        <h3 className='SubTitulo'>Los puntos cardinales</h3><br/>
                        
                        Los puntos cardinales son una forma muy efectiva de orientarse. Conocerlos y 
                        saber ubicarlos nos puede ser muy útil en algún momento de nuestra vida. Hace 
                        unos 2 000 años los chinos descubrieron que una aguja imantada siempre indica
                        la dirección norte-sur si se le deja girar libremente. ¿Por qué pasa 
                        esto? Es porque en el centro núcleo de la Tierra hay una gran cantidad de 
                        hierro que genera campos magnéticos. El planeta es un gran imán y por eso las brújulas no 
                        funcionan en el Polo Norte ni en el Polo Sur.<br/><br/>
                        
                        <h3 className='SubTitulo2'>¿Dónde se encuentra el norte?</h3> <br/>

                        El norte estará frente a ti si te paras con los brazos extendidos, 
                        el derecho apuntando hacia donde sale el Sol y el izquierdo hacia 
                        donde se oculta. Cuando te orientas utilizando un mapa, es muy 
                        importante hacer coincidir el norte magnético con el geográfico.<br/><br/>
                        
                        El norte geográfico y el magnético no están en el mismo lugar. El norte 
                        magnético de la Tierra cambia a una velocidad aproximada de 15 km por año.
                        El hierro en el centro del planeta está fundido por el calor y la presión, 
                        así que se mueve dentro de la Tierra, generando distintos campos eléctricos 
                        y magnéticos.
                       </p>
                      
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <Grid container justifyContent="center" >
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion4/1.png" alt="Tarjeta" />
                        </Grid>
                        <Grid container>
                            <p className='TextImg'>
                            En las últimas décadas el norte magnético ha recorrido un gran camino, desde el norte 
                            de Canadá hasta las costas de Rusia. Ahora mismo va entrando cada vez más 
                            en el continente asiático.
                            </p>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >

                    <Grid container sm={12} className='Escritorio'>
                        <Grid container sm={6}>
                            <p className='Lectura'>
                                El Polo Norte geográfico se ubica en la<br/>
                                mitad del océano Ártico y el Polo Sur en la <br/>
                                Antártida. Si fuéramos hacia el Polo Norte <br/>
                                y fuera de noche, podríamos llegar <br/>
                                siguiendo la estrella polar (también <br/>
                                llamada Polaris), la única estrella que no<br/>
                                se desplaza en el cielo nocturno, porque <br/>
                                está alineada con el eje de rotación de la <br/>
                                Tierra. Pero esto sólo pasa si vivimos en el <br/>
                                hemisferio norte de la Tierra, porque en el <br/>
                                hemisferio sur Polaris no puede verse. Si <br/>
                                estás en el hemisferio sur, ¿qué estrellas <br/>
                                necesitas usar para ubicarte?
                            </p>
                        </Grid>
                        <Grid container sm={6} justifyContent='center' alignContent='center'>
                            <img  src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion4/2.png" alt="Tarjeta" /> 
                        </Grid>
                    </Grid>

                    <Grid container sm={12} className='Tablet'>
                        <Grid container item sm={12}>
                            <p className='Lectura'>
                            El Polo Norte geográfico se ubica en la mitad del océano Ártico y el Polo Sur en la Antártida. Si fuéramos hacia el Polo Norte y fuera de noche, podríamos llegar siguiendo la estrella polar (también llamada Polaris), la única estrella que no se desplaza en el cielo nocturno, porque está alineada con el eje de rotación de la Tierra. Pero esto sólo pasa si vivimos en el hemisferio norte de la Tierra, porque en el hemisferio sur Polaris no puede verse. Si estás en el hemisferio sur, ¿qué estrellas necesitas usar para ubicarte?
                            </p>  
                        </Grid>

                        <Grid container justifyContent="center" >
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion4/2R.png" alt="Tarjeta" />
                        </Grid>
                        
                                      
                    </Grid>

                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className='Lectura'>
                            <h3 className='SubTitulo'>Sistemas de localización modernos</h3><br/>
                            
                            En la actualidad existen sistemas que nos ayudan a ubicarnos con mucha más 
                            precisión, como el Sistema de Posicionamiento Global (llamado <span style={{fontVariant: "all-small-caps"}}>GPS</span> por sus siglas 
                            en inglés). Este sistema utiliza 24 satélites para determinar una
                            ubicación. Algunos automóviles, teléfonos celulares, relojes y otros
                            dispositivos cuentan con este sistema, pero dependen de señales 
                            satelitales que en ocasiones no pueden ser recibidas en algunos 
                            lugares por falta de conectividad.
                        </p>
                    </Grid>
                    <Grid containter justifyContent="center" sm={12}>
                        <Grid container justifyContent="center" >
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion4/3.png" alt="Tarjeta" />
                        </Grid> 
                        <Grid container>
                            <p className='TextImg'>
                                Para que el <span style={{fontVariant: "all-small-caps"}}>GPS</span> funcione se necesitan tres elementos. En primer lugar, los 
                                satélites que orbitan en el espacio y mandan señales a la Tierra. En segundo, 
                                las torres de telecomunicaciones, localizadas en cada uno de los continentes.
                                Y, por último, los receptores que hay en los equipos de los usuarios.
                            </p>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className='Lectura'>
                            Los avances tecnológicos nos ayudan a ubicarnos de forma muy precisa, pero ¿qué pasaría si alguna vez no están disponibles? En ese caso, saber orientarse tomando los astros como referencia o aprender a utilizar la brújula sería la mejor opción.
                        </p>
                    </Grid>

                </Grid>
        </Grid>
        <Grid container item style={{ marginTop: 80}} ></Grid>
    </Grid>
    );
}
 
export default El_norte_magnético_y_el_norte_geográfico;
