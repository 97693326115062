import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
     Contenedor:{
        background:'#E9FBF9',
        width:'100vw',
        overflowX:'clip',
        [theme.breakpoints.down(1161)]:{
            background:'none',
           overflowX:'unset',
        },
        [theme.breakpoints.down(600)]:{
            marginTop: '79.51px',
        }
    },
  ContainerInfoLectura: {
    position: "relative",
    height: "86px",
    [theme.breakpoints.down(502)]:{
        height: "43px",
    },
    background: "#76D4C3",
    "& .BarraInfoLectura": {
      height: "86px",
      background: `Url(https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Barras/Azul.png)`,
        [theme.breakpoints.down(502)]:{
            height: "43px",
        },
    },
    "& .InfoLectura": {
      position: "absolute",
      height: "86px",
      width: "219px",
      boxSizing: "border-box",
      padding: "17.2px 0",
      background: "#FAFAFA",
      borderRadius: "50px",
      boxShadow: "2px 2px 2px 2px #00000029",
      zIndex: "4",
        [theme.breakpoints.down(502)]:{
            height: "43px",
            width: "109px",
            padding: "8.6px 0",
            borderRadius: "25px",
        },
    },
    "& .InfoLectura:before": {
      position: "absolute",
      display: "flex",
      boxSizing: "border-box",
      padding: "0 0 0 10px",
      justifyContent: "center",
      alignItems: "center",
      height: "39.88px",
      width: "39.88px",
      borderRadius: "50%",
      content: '""',
      boxShadow: "3px 0px .5px 0px #00000029",
      background: "#FAFAFA",
      left: "90px",
      top: "65px",
      transform: "rotate(0.25turn)",
      [theme.breakpoints.down(502)]:{
        left: "45px",
        top: "30px",
        height: "20.88px",
        width: "20.88px",
        },
    },
    "& .InfoLectura:after": {
      content: '""',
      height: "15px",
      width: "15px",
      borderTop: "2px solid #20A698",
      borderLeft: "2px solid #20A698",
      position: "absolute",
      bottom: "-10px",
      left: "Calc(50% - 8px)",
      transform: "rotate(0.625turn)",
      [theme.breakpoints.down(502)]:{
        height: "10px",
        width: "10px",
        left: "Calc(50% - 4.5px)",
        bottom: "-3px",
        borderTop: "1px solid #20A698",
        borderLeft: "1px solid #20A698",
        },
    },
    "& .Sesion": {
      borderLeft: "2px solid #D8D8D8",
    },
    "& .Dato": {
      margin: "0 0 5px 0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#20A698",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        margin: "0 0 2.5px 0",
        },
    },
    "& .Caracter": {
      margin: "0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#000000",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        },
    },   },
    ContenedorLectura:{
        background:'#fff',
        width:'1160px',
        height:'100%',
        padding:'60px 168px 35px ',
        [theme.breakpoints.down(1161)]:{
            width:'502px',
            padding:'60px 0 0 0',
          },
        [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
        '& .ContainerDerechos':{
            bottom:'80px',
            [theme.breakpoints.down(1161)]:{
                display:'none',
            },
        },
        '& .Derechos':{
            textAlign:"center",
            margin:"0px",
            font:" 15px lato",
            color:"#717476",
        },
        '& .Escritorio':{
            display:'block',
            [theme.breakpoints.down(1161)]:{
                display:'none'
            },
        },
        '& .Tablet':{
            display:'none',
            [theme.breakpoints.down(1161)]:{
                display:'block'
            },
        }
    },

    ContainerTitulo:{
        margin:'0 0 46px 0',
        '& .Titulo':{
            letterSpacing: '0px',
            color: '#13AAFA',
            margin:'0',
            textAlign:'center',
            font:'48px fredoka one',
            [theme.breakpoints.down(1161)]:{
                font:'43px fredoka one',
                lineHeight:'52px',
            },
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            margin:'0 0 36px 0 ',
            color:'#000',
            font:'22px lato',
            letterSpacing: '0.20px',
            lineHeight : '30px',
            [theme.breakpoints.down(1161)]:{
                font:'18px lato',
                lineHeight :'28px',
                letterSpacing: '0.17px',
            },
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .Imagen':{
            width:'100%'
        },
    },
    

}));

const El_secreto_de_la_vida = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
             <Grid container item justifyContent="center" className={classes.ContainerInfoLectura}>
               <Grid container className="BarraInfoLectura"></Grid>
               <Grid container item className="InfoLectura">
                    <Grid container item direction="column" xs={6} justifyContent="center">
                        <p className="Dato">Grado</p>
                        <p className="Caracter">3</p>
                    </Grid>
                    <Grid container item direction="column" xs={6} justifyContent="center" className="Sesion">
                        <p className="Dato">Sesión</p>
                        <p className="Caracter">25</p>
                    </Grid>
               </Grid>
            </Grid>

            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >
                        El secreto de la vida
                    </h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container justifyContent="center"  sm={12}>
                        <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img3ro/Sesion25/1.png" alt="Tarjeta" />
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                            Antes sólo había tierra y mar. No existían árboles, flores, frutos ni animales. El viento soplaba 
                            silenciosamente. Ante tal paisaje, los dioses sentían tristeza: su hermosa esfera azul llamada Tierra 
                            era callada, aburrida.
                            <br/><br/>
                            Una noche hubo tantas estrellas que el mundo parecía acunado por la Luna. Los dioses se reunieron 
                            para pensar si podrían llenar de vida la Tierra. Una diosa imaginó que podría haber muchas y 
                            distintas figuras frágiles y verdes que cascabelearan con el viento. Debían ser firmes para no 
                            caerse, pero también delicadas para mecerse. ¡Con ellas habría movimiento y alegría! Podrían ser 
                            altas y alcanzar las nubes, o bajas como la espuma marina.
                            <br/><br/>
                            Otro dios opinó que debían imaginar figuras capaces de trasladarse de un sitio a otro. También 
                            podrían ser altas y grandes, bajas y pequeñas, suaves o fuertes. Algunas treparían, otras correrían 
                            y unas más saltarían. Otras hasta nadarían o volarían.
                            <br/><br/>
                            Todos se emocionaron. Decidieron crear lo que imaginaban, hasta que una diosa intervino:
                            <br/><br/>
                            —Tú, diosa primera, dices que tus estructuras bailarán con el viento. ¿De qué material serán? Y 
                            tú, dios segundo, imaginas formas que se desplazan. ¿De dónde obtendrán la energía para hacerlo? 
                            Todo en el universo requiere fuerza para moverse <br className="Tablet"/>—dijo convencida.
                            <br/><br/>
                            —Deberán conseguirla de algún lado —respondió otro.
                            <br/><br/>
                            —¿De dónde? —preguntó una diosa.
                            <br/><br/>
                            —Los segundos se llamarán <em>animales</em> y podrán comerse a los primeros, las <em>plantas</em>. Ya que 
                            ellos podrán desplazarse, irán de un sitio a otro comiéndolas para tener energía —explicó una deidad.
                            <br/><br/>
                            —¡Entonces, pronto no habrá plantas y los animales ya no tendrán qué comer! Debemos idear la manera de 
                            crear un mundo donde ambos coexistan —advirtió la primera diosa.
                            <br/><br/>
                            —Sólo nosotros somos infinitos, ¿recuerdas? Morirán inevitablemente. Creemos una forma en la que mueran, 
                            pero también puedan renacer en un ciclo continuo. ¡Ya sé! Las plantas tendrán un pequeño ser similar a 
                            ellas, que vuelva a nacer y a crecer de ellas mismas. Ese ser generará otro, y ese mismo a otro. Así 
                            continuará su existencia.
                            <br/><br/>
                            —Todos morirán y renacerán. Así será una ley justa. ¿Pero qué pasará con los que mueran? Su materia 
                            quedará abandonada <br className='Tablet'/>—terminó de pronunciar un dios que no había hablado antes.
                            <br/><br/>
                            Reflexionaron largo rato hasta que se les ocurrió algo: crearían unos seres llamados insectos que 
                            ordenaran los ciclos de vida y muerte. Serían diminutos, y los habría de muchos colores y formas. 
                            Ellos sembrarían las semillas de las plantas, llevarían polen de unas a otras para que se reprodujeran 
                            y se comerían los cadáveres. Harían esas tareas casi en secreto, escondiéndose. Pocos sabrían que 
                            sin ellos no existiría el mundo.
                            <br/><br/>
                            A la mañana siguiente, la brisa soplaba meciendo las copas de los árboles y las aguas. Entonces, 
                            animales y plantas existieron bajo el cielo centelleante.
                        </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img3ro/Sesion25/2.png" alt="Tarjeta" />
                    </Grid>
                </Grid>

                <Grid container item xs={12} justifyContent="center" className="ContainerDerechos">
                    <p className="Derechos">
                        © 2022. Todos los derechos reservados, Pearson, Circuito de lectores.
                    </p>                
                </Grid>
        </Grid>
    </Grid>
    );
}
 
export default El_secreto_de_la_vida;
