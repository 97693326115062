import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
    ContenedorLectura:{
        width:'502px',
                  [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
        '& .Lectura':{
            margin:'0 0 ',
            color:'#000',
            font:'18px lato',
            lineHeight :'28px',
            letterSpacing: '0.17px',
            width:'442px',
            top:'90.52px',
            left:'30px',
            [theme.breakpoints.down(502)]:{
                font:'14px lato',
                lineHeight :'19.5px',
                letterSpacing: '0.09px',
                width:'314px',
                top:'60px',
                left:'22px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'16px',
                letterSpacing: '0.09px',
                width:'87%',
                top:'55px',
                left:'20px',
            },
        },
        '& .Escritorio':{
            display:'block',
            [theme.breakpoints.down(1161)]:{
                display:'none'
            },
        },
        '& .Tablet':{
            display:'none',
            [theme.breakpoints.down(1161)]:{
                display:'block'
            },
        },
        '& .SubTitulo':{
            color:'#F6664D',
            margin:'0',
            font:'bold 22px lato',
            lineHeight :'24px',
            letterSpacing: '0.21px',
            [theme.breakpoints.down(502)]:{
                font:'bold 18px lato',
                lineHeight :'20px',
                letterSpacing: '0.16px',
            },
        }, 

        '& .Imagen':{
            [theme.breakpoints.down(502)]:{
                width:'100%',
            },
        },
        '& .Esp':{
            width:'100%!important',
            [theme.breakpoints.down(502)]:{
                width:'100%!important',
            },
        },
        '& .Pos':{
            position:'absolute'
        },
    },
    ContainerTitulo:{
        margin:'0 0 46px 0',
        '& .Titulo':{
            letterSpacing: '0px',
            color: '#926DD1',
            margin:'0',
            textAlign:'center',
            font:'43px fredoka one',
            lineHeight:'52px',
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 46px 0',
    },
    Parrafo2:{
        margin:' 0 0 46px 0',
        position:'relative',
    },
    
    Parrafo3:{
        margin:' 0 0 46px 0',
        position:'relative',
        '& .Text':{
            position:'absolute',
            color:'#000',
            margin:'0',
            font:'11px lato',
            letterSpacing:'.10px',
            lineHeight:'14px',
            left:'265px',
            [theme.breakpoints.down(502)]:{
                font:'8px lato',
                letterSpacing:'.07px',
                lineHeight:'10px',
                left:'187px',
            },
            [theme.breakpoints.down(350)]:{
                font:'6.5px lato',
                letterSpacing:'.07px',
                lineHeight:'8px',
                left:'159px',
            },
        },
        '& .Text1':{
            top:'295px',
            [theme.breakpoints.down(502)]:{
                top:'207px',
            },
            [theme.breakpoints.down(350)]:{
                top:'182px',
            },
        },
        '& .Text2':{
            top:'425px',
            [theme.breakpoints.down(502)]:{
                top:'300px',
            },
            [theme.breakpoints.down(350)]:{
                top:'255px',
            },
        },
        '& .Text3':{
            top:'560px',
            [theme.breakpoints.down(502)]:{
                top:'390px',
            },
            [theme.breakpoints.down(350)]:{
                top:'337px',
            },
        },
        
    },
    

}));

const Normas_para_convivir = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>

            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo}  justifyContent="center">
                    <h2 className="Titulo" >
                        Normas para convivir
                    </h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1}  justifyContent="center">
                    <Grid container sm={12} justifyContent="center">
                        <h3 className='SubTitulo'>
                            Ética normativa: reflexiones para la vida diaria                        
                        </h3>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo2} justifyContent="center">
                    <Grid container sm={12}>
                        <img className="Imagen " src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img2do/Sesion18/1R.png" alt="Tarjeta" />

                            <p className="Pos Lectura"> 
                                Imagina que te encuentras en la siguiente situación: dos semanas antes 
                                de comenzar las clases, el maestro advierte a todo el grupo que es 
                                obligatorio llevar un cuaderno cuadriculado y que quien no lo lleve 
                                desde el primer día perderá un punto en la calificación final. Pero ese 
                                día tu mejor amiga olvida su cuaderno.<br/><br/>
                                
                                Por supuesto, ella no quiere que sus calificaciones bajen ni piensa 
                                arriesgarse a reprobar. Por eso decide tomar el cuaderno de otra 
                                compañera de la clase, una niña tímida y callada del rincón, que 
                                seguramente no dirá nada cuando el profesor le pregunte por su 
                                cuaderno.<br/><br/> 
                                
                                Tu amiga se salvó, pero para lograrlo puso en riesgo a su compañera. 
                                ¿Qué piensas al respecto?
                            </p>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo3} justifyContent="center">
                    <Grid container sm={12}>
                        <img className="Imagen " src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img2do/Sesion18/2R.png" alt="Tarjeta" />

                        <p className="Pos Lectura"> 
                            Tu mejor amiga sabe que lo que hizo es incorrecto. Por eso te pide que 
                            guardes su secreto. Te invitamos a analizar al menos tres caminos que 
                            podrías tomar y las consecuencias que tendría cada uno.
                        </p>
                        <p className='Text Text1'>
                            <b>Guardas el secreto de tu amiga.</b><br/>
                            Ella se salvará, pero su compañera<br/> 
                            perderá un punto de calificación.
                            </p>
                        <p className='Text Text2'>
                            <b>Le dices la verdad al profesor.</b> No <br/>
                            le quitarán puntos a la otra niña,<br/> 
                            tu amiga recibirá una sanción y<br/> 
                            muy probablemente ustedes dos<br/> 
                            terminarán disgustadas.
                        </p>
                        <p className='Text Text3'>
                            <b>Convences a tu amiga de que <br/>
                            devuelva el cuaderno antes de<br/> 
                            generar un problema que afecte<br/> 
                            a otros.</b>Tu amiga tendrá que <br/>
                            hacerse responsable y enfrentar<br/>
                            su situación, pero ahí estarás tú <br/>
                            para apoyarla.
                        </p>
                        
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1}  justifyContent="center">
                    <Grid container sm={12} >
                    <p className='Lectura Esp'>
                        <h3 className='SubTitulo'>
                            Reflexionemos                       
                        </h3><br/>
                        
                         ¿Cómo crees que te sentirías ante cada situación y cómo se sentirían los demás? 
                         Ponte en su lugar y piensa cuál de las acciones planteadas arriba es la correcta, y por 
                         qué. La decisión correcta es la que procura el bienestar de todas las personas, no sólo 
                         de algunas. Ésa es una <em>norma de convivencia</em>. Y como esa hay muchas otras que nos 
                         ayudan a mantener relaciones y sociedades sanas, por ejemplo: no mentir, aceptar
                        responsabilidades, cuidar a la gente que nos rodea, entre otras. A eso le llamamos
                        <em> ética normativa</em>.<br/><br/>
                        
                        De eso se trata tu clase de ética: de reflexionar sobre nuestras acciones en la vida
                        diaria a favor de la felicidad y el cuidado de todas las personas.<br/><br/>
                        
                        Para finalizar, te pedimos que imagines otras circunstancias donde debas elegir entre
                        acciones positivas y negativas. Hacer lo correcto es una tarea para toda la vida.
                    </p>
                    </Grid>
                </Grid>
        </Grid>
    </Grid>
    );
}
 
export default Normas_para_convivir;
