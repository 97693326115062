import React, {useEffect}   from "react";
import RedirecToErrorActivity from "../../../../hooks/redirectToerrorActivity";
import { useHistory }         from 'react-router-dom';
import { makeStyles,Grid}   from '@material-ui/core';
import NavBar               from '../../../../NavBar';
import MenuIzquierdo        from '../../../../MenuIzquierdo';
import ActivityEvaluation   from '../../ActivityEvaluation';
import QuestionText         from './QuestionText'
import axios                from "../../../../../services/axios";
import QuestionProv         from "../../QuestionProv";
import Question             from "../../Question";


const useStyles = makeStyles(theme=>({
  grid1:{    
    zIndex:'3'
    
  },
  grid2:{    
    zIndex:'2'
    
  },
  grid3:{    
    boxSizing:'border-box',
    zIndex:'1',
    paddingLeft:'3%',
    [theme.breakpoints.down('md')]:{
      paddingLeft:'0px',
    }
    
  }
}))

const ActivityS02 = (props) => {
  const classes = useStyles()
  const history = useHistory()
  const [step, setStep]                     = React.useState(0)
  const [questionList, setQuestionList]     = React.useState([])
  const [data, setData]                     = React.useState(null)
  // const [activityStatus, setActivityStatus] = React.useState()

  useEffect(() => {
    const body = {"id_actividad": 2};
    if(!questionList.length){
      axios.get( '/actividad/preguntas/get?id_actividad=2', body)
      .then(response => {
        console.log(response.data.resultado)
        console.log(response.data.resultado.preguntas.map((pregunta) => (
          pregunta.respuestas.map((respuesta)=>(
            respuesta.correcta
          ))
        )), "ejerc")
        setQuestionList(response.data.resultado.preguntas)
        setData(response.data.resultado)
        setStep(response.data.resultado.preguntas.findIndex(pregunta => !pregunta.respondida))
        RedirecToErrorActivity(response.data.resultado, history)
      })
      .catch((error) => {
        console.log(error, "error");
      });
    }
  }, [questionList.length, history ])

  const baseNumber = () => {
    if(step > 7){
      return(
        2
      )
    } else if(step > 3){
      return(
        1
      )
    } else {
      return(
        0
      )
    }
  }

  const renderQuestion = () => {
    return(
      step === 11 ?
      <QuestionProv
        pregunta={ questionList.length > 0 ? questionList[step].pregunta : "cargando..."}
        step={step}
        baseNumber={baseNumber()}
        stepsForText={4}
      />
      
    :
      <Question
        step={step}
        pregunta={ questionList.length > 0 ? questionList[step].pregunta : "cargando..."}
        baseNumber={baseNumber()}
        stepsForText={4}
      />
    )
  }


  const renderText = () => {
    return(
      <QuestionText
        questionText={questionList.length > 0 ? questionList[step].texto : "cargando..."}
        baseNumber={baseNumber()}
      />
    )
  }

  return(
    <Grid container >
      <Grid item className={classes.grid1} sm={12}> 
        <NavBar/>
      </Grid>
      <Grid item className={classes.grid2} sm={1}> 
        <MenuIzquierdo/>
      </Grid>
      <Grid item className={classes.grid3} sm={12}>
        {/* { data &&
        (data?.status === "Sesión desbloqueada" || data?.status === "Completa") 
          ? */}
        <ActivityEvaluation
          step={step}
          setStep={setStep}
          questionList={questionList}
          setQuestionList={setQuestionList}
          questionComponent={renderQuestion()}
          textComponent={renderText()}
          baseNumber={baseNumber()}
          activityNumber={2}
          tryNumber={data && data.intento}
          enrollmendId={data && data.id_actividad_inscripcion}
          evaluationType={"diagnostica"}
        />
        {/* :
        <Redirect  
          to={{
            pathname: "/error/sesion-bloqueada",
            state: {
              reason:'sesion-bloqueada'
            },
          }}
        />
        } */}
      </Grid>
    </Grid>
  )
}

export default ActivityS02;
