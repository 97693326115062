import React, { useState } from "react";
import { Button, makeStyles, Avatar } from "@material-ui/core";
import AddAPhotoIcon from "@material-ui/icons/AddAPhoto";
import defaultImg from "../../../imgs/default_avatar.png";
import Dropzone from "react-dropzone";

import ModalMessage from "../../../Modals/ModalMessage";

const useStyles = makeStyles((theme) => ({
  large: {
    width: "211px",
    height: "209px",
  },

  mainTitle: {
    font: "48px fredoka one",
    margin: "0px",
    textAlign: "center",
    color: "orange",
    [theme.breakpoints.down("760")]: {
      font: "36px fredoka one",
    },
    [theme.breakpoints.down("321")]: {
      font: "28px fredoka one",
    },
  },

  link: {
    // transform:'translate(-50%,-50%)',
    textDecoration: "none",
    marginTop: "40px",
  },

  button: {
    borderRadius: "50%",
  },
  iconImage: {
    marginBottom: "20px",
    color: "#fafafa",
  },
  container: {
    display: "flex",
    width: "auto",
    justifyContent: "center",
    alignContent: "center",
    "& .iconImageBar": {
      display: "none",
      alignItems: "flex-end",
      justifyContent: "center",
      width: "211px",
      position: "absolute",
      bottom: "7px",
    },
    "&:hover": {
      "& .iconImageBar": {
        display: "flex",
        alignItems: "center",
        height: "105.5px",
        backgroundColor: "#00000050",
        " -moz-border-radius": "0 0 211px 211px ",
        "-webkit-border-radius": " 0 0 211px 211px ",
        borderRadius: " 0 0 211px 211px ",
      },
    },
  },
}));
const DropZone = ({ handleDocument, file, type, logo }) => {
  const classes = useStyles();
  const [modalMessageOpen, setModalMessageOpen] = React.useState(false);
  const [confirmChangesResult, setConfirmChangesResult] = React.useState("");
  const limitInMb = 5;
  const [image, setImage] = useState("");

  const handleCloseMessageModal = () => {
    setModalMessageOpen(!modalMessageOpen);
  };

  const formatBytes = (bytes) => {
    const limitInKb = limitInMb * 1000000;
    return bytes < limitInKb;
  };

  const uploadImage = async (file) => {
    const [selectedFile] = file;
    if (selectedFile) {
      if (formatBytes(selectedFile?.size)) {
        const base64 = await convertBase64(selectedFile);
        setImage(base64);
        // handleNext();
        handleDocument({
          name: selectedFile?.name,
          size: selectedFile?.size,
          type: selectedFile?.type,
          base64: base64?.split(",")[1],
        });
      } else {
        setModalMessageOpen(true);
        setConfirmChangesResult(`La imagen seleccionada tiene que pesar menos de ${limitInMb} Mb.`);
      }
    } else {
      setModalMessageOpen(true);
      setConfirmChangesResult(`Asegúrese de que la imagen seleccionada tenga un formato ${type}.`);
    }
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  return (
    <>
      <Dropzone
        accept="image/*"
        multiple={false} // Only upload 1 file
        onDrop={(file) => {
          uploadImage(file);
        }}
      >
        {({ getRootProps, getInputProps }) => (
          <div {...getRootProps()} className={classes.container}>
            <input {...getInputProps()} />
            <div className={classes.avatarWrapper}>
              {/* { loading && <CircularProgress thickness={1} size={326} className={classes.avatarUploadProgress}/> } */}
              <Button>
                <Avatar
                  alt="default_img"
                  src={image || logo || defaultImg}
                  className={classes.large}
                />
                <div className={"iconImageBar"}>
                  <AddAPhotoIcon className={classes.iconImage} />
                </div>
              </Button>
            </div>
          </div>
        )}
      </Dropzone>
      <ModalMessage
        open={modalMessageOpen}
        handleClose={handleCloseMessageModal}
        title={confirmChangesResult}
      />
    </>
  );
};

export default DropZone;
