import  React from "react";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  emotion: {
    width: "30px",
    height: "30px",
    objectFit: "cover",
    [theme.breakpoints.up("xl")]: {
      width: "40px",
      height: "40px",
    },
  },
  cellContainer: {
    backgroundColor: "transparent",
    position: "relative",
    width: "auto",
    display: "flex",
    margin: "0 auto",
    overflow: "visible",
    justifyContent: "center",
    "& .name": {
      display: "none",
      color: "#FFFFFF",
      backgroundColor: "#9051b1",
      borderRadius: "21px",
      padding: "5px 10px",
      top: "0",
      left: "75px",
      zIndex: "99",
      position: "absolute",
      maxWidth: "200px",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden",
      [theme.breakpoints.up("xl")]: {
        left: "85px",
      },
    },
    "&:hover": {
      "& .name": {
        display: "block",
      },
    },
  },
}));

const EmocionCell = ({ row, name }) => {
  const classes = useStyles();

  const getImage = (emotion) => {
    switch (emotion) {
      case "entusiasmado":
        return "https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Moods/Entusiasmado.png";
      case "calmado":
        return "https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Moods/Calmado.png";
      case "enojado":
        return "https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Moods/Enojado.png";
      case "feliz":
        return "https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Moods/Feliz.png";
      case "miedoso":
        return "https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Moods/Miedo.png";
      case "triste":
        return "https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Moods/Triste.png";

      default:
        break;
    }
  };
  return (
    <>
      <div className={classes.cellContainer}>
        {row.sentimientoInicio && (
          <img
            src={getImage(row.sentimientoInicio)}
            alt=""
            className={classes.emotion}
          />
        )}

        {row.sentimientoInicio && (
          <img
            src={getImage(row.sentimientoFin)}
            alt=""
            className={classes.emotion}
          />
        )}

        <span className="name">{name}</span>
      </div>
    </>
  );
};

export default EmocionCell;
