import React               from "react";
import {Grid, makeStyles}                 from '@material-ui/core';

const useStyles = makeStyles(theme =>({
  questionContainer:{
    width: "100%",
    height:"100%",
  },
  reading: {
    width: "80%",
    margin:"10px 0 10px 0 ",
    font: '22px lato',
    letterSpacing: '0.21px',
    [theme.breakpoints.down(1400)]:{
      font: " 18px lato",
      width: "95%",
    },
    [theme.breakpoints.down(1310)]:{
      font: "500 16px lato",

    },
    [theme.breakpoints.down(925)]:{
      font: "500 14px lato",

    },
    [theme.breakpoints.down(426)]:{
      font: "500 12px lato",
 
    }, 
  },
  table: {
    width: "100%",
    font: '22px lato',
    margin: '0',
    [theme.breakpoints.down(1400)]:{
      font: " 18px lato",
      width: "95%",
    },
    [theme.breakpoints.down(1310)]:{
      font: "16px lato",
 
    },
    [theme.breakpoints.down(925)]:{
      font: "14px lato",

    },
    [theme.breakpoints.down(426)]:{
      font: "12px lato",

    }, 
  },
  tdTable: {
    padding: "0 1%",
    // border: '1px solid black',
  }
}));

const QuestionTable = (props) => {
  const classes = useStyles()

  return(
    <Grid container className={classes.questionContainer}>
      <p
        className={classes.reading}
      >
        1. Relaciona cada descripción con la teoría ética a la que corresponde.
      </p>
      <br/>
      <table className={classes.table}>
        <thead>
          <th style={{width: "40%", textAlign: 'left'}}><b>Teoría</b></th>
          <th style={{width: "60%", textAlign: 'left'}}><b>Descripción</b></th>
        </thead>
        <tbody>
          <tr>
            <td className={classes.tdTable}>
              <p className={classes.table}> 
                1. Emotivismo
              </p>
            </td>
            <td className={classes.tdTable}>
              <p className={classes.table}>
                a. La convivencia se basa en el cumplimiento de reglas que aplican para todos
              </p>
            </td>
          </tr>
          <tr>
            <td className={classes.tdTable}>
              <p className={classes.table}>
                2. Utilitarismo
              </p>
            </td>
            <td className={classes.tdTable}>
              <p className={classes.table}>
                b. Una guía para saber cómo actuar es poner atención a qué sentimientos te genera ese actuar.
              </p>
            </td>
          </tr>
          <tr>
            <td className={classes.tdTable}>
              <p className={classes.table}>
                3. Racionalismo
              </p>
            </td>
            <td className={classes.tdTable}>
              <p className={classes.table}>
                c. La mejor decisión será la que aporte mayor beneficio al menor costo posible.
              </p>
            </td>
          </tr>
          <tr>
            <td className={classes.tdTable}>
              <p className={classes.table}>
                4. Ética del cuidado
              </p>
            </td>
            <td className={classes.tdTable}>
              <p className={classes.table}>
                d. La  mejor  acción  es  la  que  ayuda  a  preservar  las relaciones más valiosas entre las personas.
              </p>
            </td>
          </tr>
        </tbody>
      </table>
    </Grid>
  )
}

export default QuestionTable;
