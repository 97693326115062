import { useContext } from "react";
import UsabilityContext from "../../usability/UsabilityContext";

const useLoading = () => {
  const { handleLoading } = useContext(UsabilityContext);

  return { handleLoading };
};

export default useLoading;
