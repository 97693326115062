import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
      Contenedor:{
        background:'#E9FBF9',
        width:'100vw',
        overflowX:'clip',
        [theme.breakpoints.down(1161)]:{
            background:'none',
           overflowX:'unset',
        },
        [theme.breakpoints.down(600)]:{
            marginTop: '79.51px',
        }
    },
  ContainerInfoLectura: {
    position: "relative",
    height: "86px",
    [theme.breakpoints.down(502)]:{
        height: "43px",
    },
    background: "#76D4C3",
    "& .BarraInfoLectura": {
      height: "86px",
      background: `Url(https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Barras/Azul.png)`,
        [theme.breakpoints.down(502)]:{
            height: "43px",
        },
    },
    "& .InfoLectura": {
      position: "absolute",
      height: "86px",
      width: "219px",
      boxSizing: "border-box",
      padding: "17.2px 0",
      background: "#FAFAFA",
      borderRadius: "50px",
      boxShadow: "2px 2px 2px 2px #00000029",
      zIndex: "4",
        [theme.breakpoints.down(502)]:{
            height: "43px",
            width: "109px",
            padding: "8.6px 0",
            borderRadius: "25px",
        },
    },
    "& .InfoLectura:before": {
      position: "absolute",
      display: "flex",
      boxSizing: "border-box",
      padding: "0 0 0 10px",
      justifyContent: "center",
      alignItems: "center",
      height: "39.88px",
      width: "39.88px",
      borderRadius: "50%",
      content: '""',
      boxShadow: "3px 0px .5px 0px #00000029",
      background: "#FAFAFA",
      left: "90px",
      top: "65px",
      transform: "rotate(0.25turn)",
      [theme.breakpoints.down(502)]:{
        left: "45px",
        top: "30px",
        height: "20.88px",
        width: "20.88px",
        },
    },
    "& .InfoLectura:after": {
      content: '""',
      height: "15px",
      width: "15px",
      borderTop: "2px solid #20A698",
      borderLeft: "2px solid #20A698",
      position: "absolute",
      bottom: "-10px",
      left: "Calc(50% - 8px)",
      transform: "rotate(0.625turn)",
      [theme.breakpoints.down(502)]:{
        height: "10px",
        width: "10px",
        left: "Calc(50% - 4.5px)",
        bottom: "-3px",
        borderTop: "1px solid #20A698",
        borderLeft: "1px solid #20A698",
        },
    },
    "& .Sesion": {
      borderLeft: "2px solid #D8D8D8",
    },
    "& .Dato": {
      margin: "0 0 5px 0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#20A698",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        margin: "0 0 2.5px 0",
        },
    },
    "& .Caracter": {
      margin: "0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#000000",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        },
    },   },
    ContenedorLectura:{
        background:'#fff',
        width:'1160px',
        height:'100%',
        padding:'60px 149px 35px' ,
        [theme.breakpoints.down(1161)]:{
            width:'502px',
            padding:'60px 0 0',
          },
        [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
        '& .ContainerDerechos':{
            bottom:'80px',
            [theme.breakpoints.down(1161)]:{
                display:'none',
            }
        },
        '& .Derechos':{
            textAlign:"center",
            margin:"0px",
            font:" 15px lato",
            color:"#717476",
        },
        '& .Escritorio':{
            display:'block',
            [theme.breakpoints.down(1161)]:{
                display:'none'
            },
        },
        '& .Tablet':{
            display:'none',
            [theme.breakpoints.down(1161)]:{
                display:'block'
            },
        },
    },
    ContainerTitulo:{
        margin:'0 0 46px 0',
        [theme.breakpoints.down(1161)]:{
            margin:'0 0 38px 0',
        },
        '& .Titulo':{
            font:'48px fredoka one',
            letterSpacing: '0px',
            color: '#F6644B',
            margin:'0',
            textAlign:'center',
            [theme.breakpoints.down(1161)]:{
                font:'43px fredoka one',
                lineHeight:'52px',
                textAlign:'center',
            },
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            lineHeight : '30px',
            margin:'0 0 36px 0 ',
            font:'22px lato',
            color:'#000',
            letterSpacing: '0.20px', 
            [theme.breakpoints.down(1161)]:{
                lineHeight :'28px',
                font:'18px lato',
                letterSpacing: '0.16px',
            },
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },

        },
        '& .TextImg':{
            margin:'22px 0 0 0',
            font:'20px lato',
            alignSelf:'flex-start',
            color:'#717476',
            [theme.breakpoints.down(1161)]:{
                font:' 16px lato',
                letterSpacing:'.15px',
            },
            [theme.breakpoints.down(502)]:{
                font:' 11px lato',
                lineHeight :'14px',
                letterSpacing: '0.10px',
            },
        },
        '& .Imagen':{
            [theme.breakpoints.down(1161)]:{
                width:'100%',
            },
        },
    },
    

}));

const Ritmo = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
             <Grid container item justifyContent="center" className={classes.ContainerInfoLectura}>
               <Grid container className="BarraInfoLectura"></Grid>
               <Grid container item className="InfoLectura">
                    <Grid container item direction="column" xs={6} justifyContent="center">
                        <p className="Dato">Grado</p>
                        <p className="Caracter">6</p>
                    </Grid>
                    <Grid container item direction="column" xs={6} justifyContent="center" className="Sesion">
                        <p className="Dato">Sesión</p>
                        <p className="Caracter">19</p>
                    </Grid>
               </Grid>
            </Grid>

            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >
                        Ritmo
                    </h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura ">
                            Cuando Fercho alzó la mano para participar, el profesor de música volteó a ver a la 
                            maestra Doloritas y juntos pasaron saliva. Luego, la profesora se repuso para 
                            contestar de un modo tajante:<br/><br/>

                            —Éste es un privilegio para los que se saben comportar.<br/><br/>

                            Y era verdad que Fercho parecía no saber comportarse. En realidad, no sabía. 
                            Constantemente se levantaba de su asiento, daba patadas en la silla de enfrente, 
                            golpeaba con la pluma al compañero de al lado y tamborileaba en la banca. Él decía 
                            que lo hacía sin darse cuenta, pero nadie le creía.<br/><br/>

                            Como era verdad, Fercho sólo bajó la mirada y de nuevo se sintió un perdedor. Lo 
                            terrible era que no quería serlo, que luchaba por no serlo, pero no le resultaba. Así, 
                            no podía detenerse cuando corría en el patio. No podía regresar a su sitio cuando se 
                            distraía. Se esforzaba por no olvidar sus materiales de clase aunque, aunque lo hacía. 
                            Intentaba concentrarse, pero se distraía pensando en otras cosas durante las  
                            actividades de la escuela.<br/><br/>

                            —Bue… no —dijo el maestro, dudoso, cuando vio cabizbajo a Fercho—, en realidad 
                            esta convocatoria está abierta para todos.<br/><br/>

                            En el momento en que lo decía, esperaba no arrepentirse. Se imaginó a Fercho con su 
                            ritmo imparable, sentándose en los instrumentos, corriendo mientras los otros 
                            alumnos aprendían a ejecutar una nota o distrayendo a los demás cuando él se 
                            ocupara de dar indicaciones a un alumno. En fin, Fercho podía fácilmente tornar su 
                            clase en un caos.<br/><br/>

                            —Sí, pero creo que los niños que se saben comportar tienen más derecho —dijo la 
                            maestra Doloritas. En su tono había cierta felicidad, como si al fin pudiera vengarse 
                            de todas las veces que Fercho no le había prestado atención.<br/><br/>

                            El profesor de música, dudoso de si era buena idea que Fercho participara, pudo 
                            conformarse con esta nueva respuesta y salir de disputas y de apuros. Pues tampoco 
                            le convenía contradecir a una compañera de trabajo frente a sus alumnos. Pero 
                            Fercho lo estaba mirando con los ojos llenos de un brillo que se llama esperanza. Y es 
                            difícil no sentirse iluminado cuando alguien deja caer toda su fe en ti.<br/>

                            —Podríamos probar —dijo quedito el maestro, como si alguien lo estuviera 
                            pellizcando en el fondo del estómago.<br/><br/>

                            Librando una batalla interior, la maestra Doloritas lo miró con suspicacia:<br/><br/>

                            —Si no funciona, si no se sabe comportar —subió la voz para verse firme—, ¡lo 
                            sacamos! —terminó el profesor.<br/><br/>

                            Menos mal que no se le quebró la voz al decirlo y la maestra estuvo de acuerdo.<br/><br/>

                            Cuando llegaron al salón de música, los niños se deslumbraron por las opciones. El 
                            profesor de música les dijo que se acercaran al instrumento que llamara más su 
                            atención. Fercho corrió hacia la batería.
                        </p>

                    </Grid>
                        <Grid container justifyContent="center" sm={12}>
                            <Grid container >
                                <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion19/1.png" alt="Tarjeta" />
                            </Grid>
                        </Grid>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                            De pronto, fue como si el desorden de Fercho tuviera fondo musical. El maestro 
                            pareció confirmar que él sólo había querido ir a la clase para golpear los tambores sin 
                            ton ni son, haciendo un ruido que no dejara a nadie concentrase, así como Fercho no 
                            se concentraba en nada. Se arrepintió de haberlo aceptado.<br/><br/>
                            
                            Pero todo eso estaba en su imaginación. Fercho exploraba en silencio. Como si fuera
                            un arqueólogo analizando la pieza de una cultura antigua, observaba cada parte de la 
                            batería.<br/><br/>
                            
                            —¿Cómo funciona? —preguntó.<br/><br/>

                            El maestro le fue explicando. Estaba sorprendido de verlo tan silencioso y atento. 
                            Luego le mostró cómo hacer un ritmo sencillo. Fercho lo copió como si lo hubiera 
                            sabido siempre, como si al fin conociera el lenguaje con el que pudiera comunicarse 
                            con los otros. Era como un cavernícola aprendiendo su primera palabra y 
                            repitiéndola para hacérsela saber a los demás y mostrarles que para él ya significaba 
                            algo. Su batería botaba y batía ritmos, sílabas, notas.<br/><br/>
                            
                            —¿Sabe? Cuando escucho canciones me aprendo el ritmo —dijo Fercho al maestro.<br/><br/>
                            
                            El profesor aprovechó para dar una clase general de ritmo y acentos. Comenzó con 
                            palmadas. Algunos niños no podían coordinar fácilmente o coordinaban sus manos 
                            sin entender realmente la función de cada aplauso, pero Fercho lo hacía sin 
                            problema. Como si lo hubiera sabido siempre.<br/><br/>
                            
                            El maestro sonrió, pero también habría querido soltar una lagrimita. No se había 
                            equivocado al elegir a Fercho. Siempre había pensado que en la vida se esconden 
                            unos hilos secretos que mueven las acciones humanas, aunque las personas a veces 
                            no entiendan por qué hacen lo que hacen. <br/><br/>
                            
                            Fercho por primera vez parecía concentrado. Tal vez porque mientras pensaba sus 
                            manos y pies inquietos seguían un ritmo, una voz. Pero al fin se coordinaban con sus 
                            pensamientos. “¡Esto es la música!”, se dijo el maestro, y también entendió por qué 
                            había decidido dedicarse a enseñar.
                        </p>
                    </Grid>
                </Grid>

                <Grid container item xs={12} justifyContent="center" className="ContainerDerechos">
                    <p className="Derechos">
                        © 2022. Todos los derechos reservados, Pearson, Circuito de lectores.
                    </p>                
                </Grid>
        </Grid>
    </Grid>
    );
}
 
export default Ritmo;
