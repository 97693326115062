import { Button, makeStyles } from "@material-ui/core";
import React from "react";

import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import { DataGrid } from "@mui/x-data-grid";
import AddIcon from "@mui/icons-material/Add";

const useStyles = makeStyles((theme) => ({
  table: {
    width: "100%",
    minHeight: "480px",
  },
  WitheButton: {
    color: "#9051b1",
    // borderColor: "#9051b1",
    border: "1px solid #9051b1",
    backgroundColor: "#FFFFFF",
    borderRadius: "30px",
    // margin: "0 auto",
    minWidth: "170px",
    // width: "100%",
    maxWidth: "238px",
    minHeight: "45px",
    textTransform: "unset",
    cursor: "pointer",
    fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
    fontWeight: "500",
    padding: "6px 16px",
    lineHeight: "1.75",
    transition:
      "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    boxShadow:
      "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
    "&:hover": {
      backgroundColor: "#B965D330 ",
    },
  },
}));

const TableTeacher = ({ removeUser, rows, addUser }) => {
  const classes = useStyles();
  const columns = [
    {
      field: "school_name",
      headerName: "Escuela",
      width: 200,
      editable: false,
    },
    {
      field: "grade",
      headerName: "Grado",
      width: 100,
      editable: false,
    },
    {
      field: "group",
      headerName: "Grupo",
      width: 100,
      editable: false,
    },
    {
      field: "delete",
      headerName: "Eliminar",
      width: 120,
      editable: false,
      renderCell: (params) => (
        <Button
          color="secondary"
          aria-label="delete"
          onClick={() => {
            removeUser(params?.row?.id, params?.row?.profile);
          }}
        >
          <DeleteForeverIcon />
        </Button>
      ),
    },
  ];
  return (
    <>
      <Button className={classes.WitheButton} onClick={addUser}>
        <AddIcon />
        Agregar Profesor
      </Button>
      <DataGrid
        className={classes.table}
        rows={rows}
        columns={columns}
        pageSize={10}
        disableSelectionOnClick
        disableColumnFilter
        rowsPerPageOptions={[10]}
        disableColumnMenu
      />
    </>
  );
};

export default TableTeacher;
