import { Button, Grid, makeStyles } from "@material-ui/core";
import React, { useContext } from "react";
import DrawerContext from "../../../../../usability/DrawerContext";
import Drawer from "../../ReadingTools/Drawer";

const useStyles = makeStyles((theme) => ({
  Contenedor: {
    background: "#E9FBF9",
    width: "100vw",
    [theme.breakpoints.down(1161)]: {
      background: "none",
    },
    [theme.breakpoints.down(600)]: {
      marginTop: "79.51px",
    },
  },
  ContainerInfoLectura: {
    position: "relative",
    height: "86px",
    [theme.breakpoints.down(502)]:{
        height: "43px",
    },
    background: "#76D4C3",
    "& .BarraInfoLectura": {
      height: "86px",
      background: `Url(https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Barras/Azul.png)`,
        [theme.breakpoints.down(502)]:{
            height: "43px",
        },
    },
    "& .InfoLectura": {
      position: "absolute",
      height: "86px",
      width: "219px",
      boxSizing: "border-box",
      padding: "17.2px 0",
      background: "#FAFAFA",
      borderRadius: "50px",
      boxShadow: "2px 2px 2px 2px #00000029",
      zIndex: "4",
        [theme.breakpoints.down(502)]:{
            height: "43px",
            width: "109px",
            padding: "8.6px 0",
            borderRadius: "25px",
        },
    },
    "& .InfoLectura:before": {
      position: "absolute",
      display: "flex",
      boxSizing: "border-box",
      padding: "0 0 0 10px",
      justifyContent: "center",
      alignItems: "center",
      height: "39.88px",
      width: "39.88px",
      borderRadius: "50%",
      content: '""',
      boxShadow: "3px 0px .5px 0px #00000029",
      background: "#FAFAFA",
      left: "90px",
      top: "65px",
      transform: "rotate(0.25turn)",
      [theme.breakpoints.down(502)]:{
        left: "45px",
        top: "30px",
        height: "20.88px",
        width: "20.88px",
        },
    },
    "& .InfoLectura:after": {
      content: '""',
      height: "15px",
      width: "15px",
      borderTop: "2px solid #20A698",
      borderLeft: "2px solid #20A698",
      position: "absolute",
      bottom: "-10px",
      left: "Calc(50% - 8px)",
      transform: "rotate(0.625turn)",
      [theme.breakpoints.down(502)]:{
        height: "10px",
        width: "10px",
        left: "Calc(50% - 4.5px)",
        bottom: "-3px",
        borderTop: "1px solid #20A698",
        borderLeft: "1px solid #20A698",
        },
    },
    "& .Sesion": {
      borderLeft: "2px solid #D8D8D8",
    },
    "& .Dato": {
      margin: "0 0 5px 0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#20A698",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        margin: "0 0 2.5px 0",
        },
    },
    "& .Caracter": {
      margin: "0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#000000",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        },
    },
       '& .Regresar':{
        color: '#F2624F',
        border: '#F2624F 1px solid',
        height: '30px',
        width:'100px',
        borderRadius: '20px',
        letterSpacing: '0.14px',
        textTransform: 'none',
        background:'#FEFAF1',
        top:'calc(50% - 15px)',
        left:'5%',
        zIndex:4,
        [theme.breakpoints.down(502)]: {
          font:'bold 10px lato',
          width: "60px",
        },
      },
  },
  ContenedorLectura: {
    width: "1160px",
    position: "relative",
    
    transform: (props) =>
      props.scale > 1 ? `scale(${props.scale}) translate(0px, 10%)` : `none`,
    "& .Escritorio": {
      [theme.breakpoints.down(1161)]: {
        display: "none",
      },
    },
    "& .Tablet": {
      display: "none",
      [theme.breakpoints.down(1161)]: {
        display: "block",
      },
    },
    [theme.breakpoints.down(1161)]: {
      width: "667px",
    },
    [theme.breakpoints.down(667)]: {
      width: "350px",
    },
    "& .Titulo": {
      position: "absolute",
      top: "42px",
      left: "84px",
      textAlign: "center",
      font: "79px fredoka one",
      lineHeight: "96px",
      letterSpacing: "0px",
      color: "#FFFFFF",
      margin: "0",
      [theme.breakpoints.down(1161)]: {
        font: "46px fredoka one",
        lineHeight: "56px",
        top: "26px",
        left: "49px",
      },
      [theme.breakpoints.down(667)]: {
        font: "16px fredoka one",
        lineHeight: "36px",
        top: "11px",
        left: "30px",
      },
    },
    "& .Titulo2": {
      position: "absolute",
      top: "138px",
      left: "550px",
      textAlign: "center",
      font: "52px fredoka one",
      letterSpacing: "0px",
      color: "#FFFFFF",
      margin: "0",
      [theme.breakpoints.down(1161)]: {
        font: "30px fredoka one",
        lineHeight: "36px",
        top: "82px",
        left: "321px",
      },
      [theme.breakpoints.down(667)]: {
        font: "13px fredoka one",
        lineHeight: "26px",
        top: "37px",
        left: "140px",
      },
    },
    "& .Texto": {
      margin: "0",
      textAlign: "center",
      width: "322px",
      font: "bold 28px lato",
      position: "absolute",
      top: "247px",
      left: "442px",
      [theme.breakpoints.down(1161)]: {
        width: "208px",
        font: "bold 18px lato",
        lineHeight: "23px",
        top: "142px",
        left: "241px",
      },
      [theme.breakpoints.down(667)]: {
        font: "bold 10px lato",
        lineHeight: "15px",
        top: "70px",
        left: "78px",
      },
    },

    "& .SubTitulo": {
      margin: "0",
      textAlign: "center",
      position: "absolute",
      top: "365px",
      left: "385px",
      font: "30px fredoka one",
      color: "#fff",
      [theme.breakpoints.down(1161)]: {
        font: "19px fredoka one",
        lineHeight: "23px",
        top: "210px",
        left: "205px",
      },
      [theme.breakpoints.down(667)]: {
        font: "10px fredoka one",
        lineHeight: "12px",
        top: "108px",
        left: "104px",
      },
    },

    "& .Step": {
      position: "absolute",
      font: "bold 47px lato",
      color: "#FFFFFF",
      margin: "0",
      [theme.breakpoints.down(1161)]: {
        font: "bold 27px lato",
        lineHeight: "37px",
      },
      [theme.breakpoints.down(667)]: {
        font: "bold 13px lato",
        lineHeight: "23px",
      },
    },
    "& .Inicio": {
      top: "634px",
      left: "242px",
      [theme.breakpoints.down(1161)]: {
        top: "363px",
        left: "141px",
      },
      [theme.breakpoints.down(667)]: {
        top: "188px",
        left: "76px",
      },
    },
    "& .Paso1": {
      top: "633px",
      left: "566px",
      [theme.breakpoints.down(1161)]: {
        top: "363px",
        left: "327px",
      },
      [theme.breakpoints.down(667)]: {
        top: "188px",
        left: "171px",
      },
    },
    "& .Pasos2": {
      top: "963px",
      left: "566px",
      [theme.breakpoints.down(1161)]: {
        top: "553px",
        left: "326px",
      },
      [theme.breakpoints.down(667)]: {
        top: "288px",
        left: "172px",
      },
    },
    "& .Pasos3": {
      top: "1262px",
      left: "566px",
      [theme.breakpoints.down(1161)]: {
        top: "722px",
        left: "326px",
      },
      [theme.breakpoints.down(667)]: {
        top: "377px",
        left: "172px",
      },
    },
    "& .Pasos4": {
      top: "1597px",
      left: "566px",
      [theme.breakpoints.down(1161)]: {
        top: "917px",
        left: "326px",
      },
      [theme.breakpoints.down(667)]: {
        top: "480px",
        left: "172px",
      },
    },
    "& .Pasos5": {
      top: "1970px",
      left: "566px",
      [theme.breakpoints.down(1161)]: {
        top: "1129px",
        left: "327px",
      },
      [theme.breakpoints.down(667)]: {
        top: "592px",
        left: "172px",
      },
    },
    "& .Fin": {
      top: "2111px",
      left: "248px",
      [theme.breakpoints.down(1161)]: {
        top: "1210px",
        left: "141px",
      },
      [theme.breakpoints.down(667)]: {
        top: "632px",
        left: "76px",
      },
    },

    "& .Istruccion": {
      position: "absolute",
      margin: "0",
      textAlign: "center",
      font: "22px lato",
      [theme.breakpoints.down(1161)]: {
        font: "16px lato",
      },
      [theme.breakpoints.down(667)]: {
        font: "8px lato",
      },
    },
    "& .IstruccionMorado": {
      color: "#7D4795",
    },
    "& .Istruccion1": {
      top: "844px",
      left: "708.5px",
      [theme.breakpoints.down(1161)]: {
        top: "475px",
        left: "381px",
      },
      [theme.breakpoints.down(667)]: {
        top: "250px",
        left: "205px",
      },
    },
    "& .Istruccion2": {
      top: "1173px",
      left: "96.5px",
      [theme.breakpoints.down(1161)]: {
        top: "670px",
        left: "27px",
      },
      [theme.breakpoints.down(667)]: {
        top: "350px",
        left: "16px",
      },
    },
    "& .Istruccion3": {
      top: "1462px",
      left: "708.5px",
      [theme.breakpoints.down(1161)]: {
        top: "840px",
        left: "391px",
      },
      [theme.breakpoints.down(667)]: {
        top: "442px",
        left: "205px",
      },
    },
    "& .Istruccion4": {
      top: "1801px",
      left: "96.5px",
      [theme.breakpoints.down(1161)]: {
        top: "1030px",
        left: "33px",
      },
      [theme.breakpoints.down(667)]: {
        top: "540px",
        left: "26px",
      },
    },
    "& .Istruccion5": {
      top: "2180px",
      left: "684px",
      [theme.breakpoints.down(1161)]: {
        top: "1245px",
        left: "400px",
      },
      [theme.breakpoints.down(667)]: {
        top: "652px",
        left: "210px",
      },
    },
    "& .SubTitulo2": {
      position: "absolute",
      left: "383px",
      top: "2320px",
      textAlign: "center",
      font: "30px fredoka one",
      color: "#FFFFFF",
      [theme.breakpoints.down(1161)]: {
        left: "214px",
        top: "1330px",
        font: "19px fredoka one",
      },
      [theme.breakpoints.down(667)]: {
        left: "110px",
        top: "698px",
        font: "10px fredoka one",
      },
    },
    "& .Momento": {
      position: "absolute",
      font: "bold 22px lato",
      color: "#000000",
      [theme.breakpoints.down(1161)]: {
        font: "bold 16px lato",
      },
      [theme.breakpoints.down(667)]: {
        font: "bold 8px lato",
      },
    },
    "& .Jugar": {
      top: "2700px",
      left: "84px",
      [theme.breakpoints.down(1161)]: {
        top: "1550px",
        left: "33px",
      },
      [theme.breakpoints.down(667)]: {
        top: "810px",
        left: "16px",
      },
    },
    "& .Comer": {
      top: "2860px",
      left: "360px",
      [theme.breakpoints.down(1161)]: {
        top: "1640px",
        left: "203px",
      },
      [theme.breakpoints.down(667)]: {
        top: "860px",
        left: "105px",
      },
    },
    "& .Casa": {
      top: "2700px",
      left: "644px",
      [theme.breakpoints.down(1161)]: {
        top: "1550px",
        left: "363px",
      },
      [theme.breakpoints.down(667)]: {
        top: "810px",
        left: "185px",
      },
    },
    "& .Baño": {
      top: "2860px",
      left: "890px",
      [theme.breakpoints.down(1161)]: {
        top: "1640px",
        left: "502px",
      },
      [theme.breakpoints.down(667)]: {
        top: "860px",
        left: "265px",
      },
    },
    "& .SubTitulo3": {
      position: "absolute",
      left: "422px",
      top: "3015px",
      textAlign: "center",
      font: "30px fredoka one",
      color: "#FFFFFF",
      [theme.breakpoints.down(1161)]: {
        left: "234px",
        top: "1730px",
        font: "19px fredoka one",
      },
      [theme.breakpoints.down(667)]: {
        left: "125px",
        top: "908px",
        font: "10px fredoka one",
      },
    },
    "& .Istruccion6": {
      top: "3180px",
      left: "77.3px",
      [theme.breakpoints.down(1161)]: {
        top: "1820px",
        left: "20px",
      },
      [theme.breakpoints.down(667)]: {
        top: "955px",
        left: "10px",
      },
    },
    "& .Istruccion7": {
      top: "3250px",
      left: "793px",
      [theme.breakpoints.down(1161)]: {
        top: "1850px",
        left: "461px",
      },
      [theme.breakpoints.down(667)]: {
        top: "970px",
        left: "245px",
      },
    },
    "& .Titulo3": {
      position: "absolute",
      bottom: "63px",
      left: "206px",
      textAlign: "center",
      font: "34px fredoka one",
      letterSpacing: "0px",
      color: "#FFFFFF",
      margin: "0",
      [theme.breakpoints.down(1161)]: {
        font: "19px fredoka one",
        bottom: "36px",
        left: "130px",
      },
      [theme.breakpoints.down(667)]: {
        font: "10px fredoka one",
        bottom: "18px",
        left: "75px",
      },
    },
    "& .Derechos": {
      position: "absolute",
      bottom: "20px",
      textAlign: "center",
      margin: "0px",
      font: " 15px lato",
      color: "#717476",
      [theme.breakpoints.down(1161)]: {
        display: "none",
      },
    },
    "& .Esconder": {
      [theme.breakpoints.down(350)]: {
        display: "none",
      },
    },
    "& .Imagen": {
      width: "100%",
    },
    "& .Imagen2": {
      display: "none",
      width: "100%",
      [theme.breakpoints.down(350)]: {
        display: "flex",
      },
    },
  },
}));

const El_lavado_de_manos = (props) => {

  const {activeStep, setActiveStep, handlePreviewStep, id_actividad} = props
  
  const handleContinue = () => {
    setActiveStep(activeStep - 1)
    handlePreviewStep()
  }

    const {
      handleSize,
      disabledZoom,
      closeZoom,
      scale
    } = useContext(DrawerContext);
    const detectSize = () => {
      const lectura = document.getElementById("lectura");

      const newWidth = lectura?.offsetWidth;
      const newHeight = lectura?.offsetHeight;
      if (lectura) handleSize(newHeight, newWidth);
    };
    React.useEffect(() => {
      window.addEventListener("resize", detectSize);
      return () => {
        window.removeEventListener("resize", detectSize);
        closeZoom();
      };
    }, []);

    const classes = useStyles({ disabledZoom, scale });
  return (
    <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
      <Grid container item justifyContent="center" className={classes.ContainerInfoLectura}>
        <Grid container className="BarraInfoLectura">
            <Button className="Regresar" onClick={handleContinue}>
                Regresar
            </Button>
        </Grid>
        <Grid container item className="InfoLectura">
          <Grid container item direction="column" xs={6} justifyContent="center">
            <p className="Dato">Grado</p>
            <p className="Caracter">1</p>
          </Grid>
          <Grid container item direction="column" xs={6} justifyContent="center" className="Sesion">
            <p className="Dato">Sesión</p>
            <p className="Caracter">14</p>
          </Grid>
        </Grid>
      </Grid>

      <Grid
        container
        item
        className={classes.ContenedorLectura}
        justifyContent="center"
        id="lectura"
        onLoad={detectSize}
      >
        
        <Drawer id_lectura={id_actividad} withoutTransform ={true}/>
        <Grid container
        item
        className="Esconder"
        justifyContent="center">
        <img
          className="Imagen"
          src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img1ro/Sesion14/1.png"
          alt=""
        />
        <Grid container item className={classes.ContainerTitulo}>
          <h2 className="Titulo">El lavado de manos</h2>
          <h2 className="Titulo2">¡Manos limpias!</h2>
        </Grid>
        <p className="Texto">
          Las manos limpias
          <br />
          previenen enfermedades.
        </p>
        <h3 className="SubTitulo">
          ¡Sigue estos pasos <br />
          para hacerlo correctamente!
        </h3>
        <p className="Step Inicio">INICIO</p>
        <p className="Step Paso1">1</p>
        <p className="Step Pasos2">2</p>
        <p className="Step Pasos3">3</p>
        <p className="Step Pasos4">4</p>
        <p className="Step Pasos5">5</p>
        <p className="Step Fin">FINAL</p>
        <p className="Istruccion Istruccion1 Escritorio">
          <b className="IstruccionMorado">
            Moja tus manos y pon un poco <br /> de jabón líquido en una de <br /> ellas.
          </b>
          <b>
            {" "}
            Si tu jabón es sólido, tómalo <br />
            con ambas manos y frótalo.
          </b>
        </p>
        <p className="Istruccion Istruccion2 Escritorio">
          <b className="IstruccionMorado">
            Frota tus manos hasta que el jabón <br /> forme espuma.
          </b>
          <b>
            {" "}
            Si consideras que <br />
            están muy sucias, enjuágalas con un poco <br /> de agua y ponles jabón nuevamente.
          </b>
        </p>
        <p className="Istruccion Istruccion3 Escritorio">
          <b>
            Distribuye la espuma por ambos
            <br /> lados de tus manos
          </b>
          <b className="IstruccionMorado">
            {" "}
            Asegúrate <br />
            de retirar toda la suciedad, <br /> incluyendo la que se encuentra <br />
            entre las uñas y los dedos.
          </b>
        </p>
        <p className="Istruccion Istruccion4 Escritorio">
          <b className="IstruccionMorado">
            Sigue frotando tus manos con fuerza <br />
            mientras cuentas hasta 10 y enjuágalas
            <br />
            con agua limpia para retirar todo
            <br /> el jabón.{" "}
          </b>
          <b>
            Recuerda cuidar el agua y usar <br /> sólo la necesaria.
          </b>
        </p>
        <p className="Istruccion Istruccion5 Escritorio">
          <b className="IstruccionMorado">
            Seca tus manos con una toalla.
            <br />
          </b>
          <b>¡Listo! Ahora tus manos están limpias</b>
        </p>

        <p className="Istruccion Istruccion1 Tablet">
          <b className="IstruccionMorado">
            Moja tus manos y pon <br />
            un poco de jabón líquido en una de <br /> ellas.
          </b>
          <b>
            {" "}
            Si tu jabón es sólido, tómalo <br />
            con ambas manos y frótalo.
          </b>
        </p>
        <p className="Istruccion Istruccion2 Tablet">
          <b className="IstruccionMorado">
            Frota tus manos hasta que el jabón <br /> forme espuma.
          </b>
          <b>
            {" "}
            Si consideras que están <br />
            muy sucias, enjuágalas con un poco <br /> de agua y ponles jabón nuevamente.
          </b>
        </p>
        <p className="Istruccion Istruccion3 Tablet">
          <b>
            Distribuye la espuma por ambos
            <br /> lados de tus manos
          </b>
          <b className="IstruccionMorado">
            {" "}
            Asegúrate
            <br />
            de retirar toda la suciedad, <br /> incluyendo la que se encuentra <br />
            entre las uñas y los dedos.
          </b>
        </p>
        <p className="Istruccion Istruccion4 Tablet">
          <b className="IstruccionMorado">
            Sigue frotando tus manos con fuerza <br />
            mientras cuentas hasta 10 y <br />
            enjuágalas con agua limpia para <br />
            retirar todo el jabón.{" "}
          </b>
          <b>
            Recuerda <br />
            cuidar el agua y usar sólo
            <br /> la necesaria.
          </b>
        </p>
        <p className="Istruccion Istruccion5 Tablet">
          <b className="IstruccionMorado">
            Seca tus manos con una toalla.
            <br />
          </b>
          <b>
            ¡Listo! Ahora tus manos <br />
            están limpias
          </b>
        </p>

        <h3 className="SubTitulo2">
          ¿Cuándo es buen momento <br />
          para lavarse las manos?
        </h3>
        <p className="Momento Jugar">Después de jugar</p>
        <p className="Momento Comer">Antes de comer</p>
        <p className="Momento Casa">Al llegar a casa</p>
        <p className="Momento Baño">Después de ir al baño</p>

        <h3 className="SubTitulo3">
          ¿Qué beneficios tiene <br />
          el lavado de manos?
        </h3>
        <p className="Istruccion Istruccion6">
          <b>La </b>
          <b className="IstruccionMorado">suciedad </b>
          <b>que se adhiere</b>
          <br />
          <b>a las manos contiene</b> <br />
          <b className="IstruccionMorado">microorganismos</b>
          <br />
          <b>que causan enfermedades.</b>
        </p>
        <p className="Istruccion Istruccion7 Escritorio">
          <b>Así que </b>
          <b className="IstruccionMorado">al lavar tus manos</b>
          <br />
          <b className="IstruccionMorado">retiras esa suciedad </b>
          <b>
            y evitas <br />
            que esos microorganismos <br />
            entren en contacto <br />
            con los objetos que usas, con <br />
            tu comida y con otras partes <br />
            de tu cuerpo.
          </b>
        </p>
        <p className="Istruccion Istruccion7 Tablet">
          <b>Así que </b>
          <b className="IstruccionMorado">al lavar tus manos</b>
          <br />
          <b className="IstruccionMorado">retiras esa suciedad </b>
          <b>
            y <br />
            evitas que esos <br />
            microorganismos <br />
            entren en contacto
            <br />
            con los objetos que usas, <br />
            con tu comida y con otras <br />
            partes de tu cuerpo.
          </b>
        </p>
        <h3 className="Titulo3">
          ¡Lavar constantemente tus manos es un buen <br />
          hábito que te mantendrá saludable!
        </h3>
        </Grid>
        <img
          className="Imagen2"
          src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img1ro/Sesion14/F2.png"
          alt=""
        />
      </Grid>
      <Grid container item style={{ marginTop: 80 }}></Grid>
    </Grid>
  );
};

export default El_lavado_de_manos;
