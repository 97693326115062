import { makeStyles, Grid } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import AuthContext from "../../../auth/AuthContext";

import imageError from "../../imgs/Errors.svg";

const useStyles = makeStyles((theme) => ({
  Container: {
    padding: "48px 0 48px 0",
    background:
      "linear-gradient(217deg, rgba(255,194,0,0.4962185557816877) 2%, rgba(255,255,255,1) 25%, rgba(255,255,255,0.4962185557816877) 81%, rgba(144,81,177,1) 98%)",
    minHeight: "100vh",
  },
  containerError: {
    maxWidth: "1216px",
    width: "100%",
    minHeight: "85vh",
    padding: "30px",
    display: "grid",
    gridTemplateRows: "auto auto",
    alignContent: "space-evenly",
    [theme.breakpoints.up("sm")]: {
      alignContent: "center",
      boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.5)",
      minHeight: "75vh",
      width: "90%",
      borderRadius: "42px",
      background: "#fff",
      justifyItems: "center",
    },
    [theme.breakpoints.up("md")]: {
      gridTemplateColumns: "0.75fr 1fr",
    },
  },
  ContainerLink: {
    textDecoration: "none",
    textAlign: "left",
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "auto",
    },
  },
  containerErrorDescription: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    [theme.breakpoints.up("md")]: {
      gap: "20px",
    },
  },
  title: {
    font: "36px fredoka one",
    textAlign: "center",
    width: "100%",
    margin: "0",
    color: "#1c2742",
    [theme.breakpoints.up("sm")]: {
      width: "auto",
    },
    [theme.breakpoints.up("md")]: {
      font: "42px fredoka one",
      width: "auto",
      // margin: "0 0 34px 14px",
    },
    [theme.breakpoints.up("lg")]: {
      font: "48px fredoka one",
    },
  },

  description: {
    color: "#505759",
    fontFamily: "Lato",
    fontSize: "18px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.33,
    letterSpacing: "normal",
    [theme.breakpoints.up("md")]: {
      fontSize: "22px",
    },
  },

  imageError: {
    width: "100%",
    maxWidth: "480px",
    [theme.breakpoints.up("md")]: {
      maxWidth: "573px",
    },
  },

  PurpleButton: {
    color: "#FFFFFF",
    backgroundColor: "#9051b1",
    borderRadius: "30px",
    width: "100%",
    maxWidth: "260px",
    border: "none",
    textTransform: "unset",
    cursor: "pointer",
    fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
    fontWeight: "500",
    padding: "6px 16px",
    lineHeight: "1.75",
    transition:
      "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    boxShadow:
      "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
    "&:hover": {
      backgroundColor: "#B965D3 ",
    },

    [theme.breakpoints.up("sm")]: {
      width: "238px",
    },
  },
}));

const Error404 = ({ location }) => {
  const classes = useStyles();
  const { user } = useContext(AuthContext);

  const path = location?.pathname;
  const getRolByParams = () => {
    const rolByPath = path?.split("/")?.filter((el) => el !== "")?.[0];
    if (rolByPath === "admin-inst") {
      return "administrador institucional";
    } else if (rolByPath === "admin") {
      return "administrador pearson";
    } else if (rolByPath === "docente") {
      return "docente";
    } else {
      return "alumno";
    }
  };

  const [reason, setReason] = useState("");

  const publicPaths = [
    "/password_recovery",
    "/password_recovery/student",
    "/password_recovery/docente",
    "/password_recovery/newPassword",
    "/singin/sexto",
    "/singin/quinto",
    "/singin/cuarto",
    "/singin/tercero",
    "/singin/segundo",
    "/singin/primero",
    "/singin/docente",
    "/singin/admin-inst",
    "/singin/admin",
    "/creditos",
    "/native/Perfil",
    "/native/Avatars",
    "/native/logout",
    "/registro",
    "/registro/alumno",
    "/registro/docente",
  ];

  const publicPathsMatch = () => {
    const isIncludes =
      publicPaths.find((el) => path.includes(el)) !== undefined;
    return isIncludes;
  };

  const getDefaultReason = () => {
    const isPublicPath = publicPathsMatch();
    const rolByParams = getRolByParams();
    const allowedRoles =
      rolByParams === "administrador institucional" ||
      rolByParams === "administrador pearson" ||
      rolByParams === "docente";

    if (!isPublicPath) {
      if (!user.logged) {
        setReason("login");
      } else {
        if (rolByParams !== user?.rol && allowedRoles) setReason("rol");
      }
    }
  };

  const defaultReason = {
    title: "404 Página no encontrada",
    description:
      "No fue posible obtener la página a la que has intentado entrar. Presiona el botón de volver para volver a la página principal.",
  };

  useEffect(() => {
    getDefaultReason();
    return () => {};
  }, []);

  const links = {
    docente: "/docente/perfil",
    "administrador institucional": "/admin-inst/perfil",
    "administrador pearson": "/admin/perfil",
    alumno: "/Perfil_del_alumno",
  };

  const possiblesReazons = {
    login: {
      title: "No has iniciado sesión",
      description:
        "Para poder acceder a este elemento debes iniciar sesión en Circuito de lectores.",
    },
    rol: {
      title: "Acceso denegado",
      description:
        "No tienes los permisos necesarios para ingresar a este apartado.",
    },
    other: {
      title: "404 Página no encontrada",
      description:
        "No fue posible obtener la página a la que has intentado entrar. Presiona el botón de volver para volver a la página principal.",
    },
  };

  return (
    <>
      <Grid
        container
        item
        sm={12}
        className={classes.Container}
        alignItems="center"
        justifyContent="center"
        id="lock"
      >
        <Grid
          container
          item
          direction="column"
          className={classes.containerError}
        >
          <Grid
            container
            item
            direction="column"
            className={classes.containerErrorDescription}
          >
            <h2 className={classes.title}>
              {possiblesReazons?.[reason]?.title || defaultReason?.title}
            </h2>
            <p className={classes.description}>
              {possiblesReazons?.[reason]?.description ||
                defaultReason?.description}
              <br />
              {/* <br />
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Iste
              officiis, omnis quibusdam sint sequi odit accusantium ad
              temporibus similique perferendis tempora? Hic saepe quo
              consectetur sint eius suscipit reprehenderit ipsa. */}
            </p>
            <Link
              to={links?.[user?.rol] || "/"}
              className={classes.ContainerLink}
            >
              <button className={classes.PurpleButton}>Volver</button>
            </Link>
          </Grid>

          <img src={imageError} alt="" className={classes.imageError} />
        </Grid>
      </Grid>
    </>
  );
};

export default Error404;
