import React from "react";
import { Grid, makeStyles } from "@material-ui/core";

import GuidedPracticeCard from "./GuidedPracticeCard";

import ContainerDocente from "../ContainerDocente";


import axios from "../../../../services/axios";
import UsabilityContext from "../../../../usability/UsabilityContext";

const useStyles = makeStyles((theme) => ({
  ContainerBiblioteca: {
    height: "100%",
    background: "#E9FBF9",
    [theme.breakpoints.up(1921)]: {
      height: "100vh",
    },
  },
  TopBar: {
    height: "172px",
    background:
      "transparent linear-gradient(103deg, #4EA398 0%, #7848DA 100%) 0% 0% no-repeat padding-box;",
  },
  ContainerLecturas: {
    position: "absolute",
    textAlign: "center",
    // height:'873px',
    width: "100%",
    maxWidth: "1160px",
    background: "#FFFFFF ",
    top: "12vh",
    boxShadow: "0px 5px 9px #00000029",
    borderRadius: "38px 38px 0 0",
    paddingTop: "3.73%",
    boxSizing: "border-box",
    "& .Titulo": {
      margin: "0",
      font: "48px Fredoka One",
      textSlign: "center",
      letterSpacing: "0px",
      color: "#7058CE",
      opacity: 1,
    },
    [theme.breakpoints.down(1280)]: {},
    [theme.breakpoints.down(830)]: {
      borderRadius: "0",
    },
    [theme.breakpoints.down(620)]: {
      width: "100%",
    },
  },
  ContainerSerchBar: {
    position: "relative",
    height: "68px",
    marginBottom: "75px",
  },
  SerchBar: {
    outline: "none",
    height: "100%",
    width: "49.31%",
    borderRadius: "36px",
    padding: "0 32px",
    border: "4px solid #EA9D90",
    textAlign: "left",
    letterSpacing: "0.63px",
    color: "#7A54BA",
    font: "600 20px lato",
    margin: " 0 10%",
  },
  SerchBarIcon: {
    right: "25%",
    top: "25%",
    position: "absolute",
    fontSize: "40px",
    color: "#7A54BA",
  },
  ContainerLecturasImagenes: {
    minHeight: "75.95%",
    padding: "0 50px",
  },
  tituloPracticas: {
    width: "100%",
    margin: "5px",
    font: "30px Fredoka One",
    textSlign: "center",
    letterSpacing: "0px",
    color: "#F27964",
    opacity: 1,
  },
}));

const PracticasGuiadas = (props) => {
  const classes = useStyles();
  const [relajacionLIst, setRelajacionList] = React.useState([]);
  const [sostenidaLIst, setSostenidaList] = React.useState([]);
  const [autoregLIst, setAutoregList] = React.useState([]);

  const { groupData, handleModalMessage, handleLoading } =
    React.useContext(UsabilityContext);

  const getRelagacionList = () => {
    handleLoading(true);
    axios
      .get(`/actividad/recursos/audio/relajacion?grado=${groupData?.id_grado}`)
      .then((response) => {
        if (response.status === 200) {
          // console.log(response.data, "relax")
          setRelajacionList(response.data.resultado?.practica_guiada || []);
          // const data = response.data.resultado.sesiones;
          // setSesionesList(data);
          // dispatch({
          //   type: types.loadActivitiesList,
          //   payload: listaSinRemediales,
          // });
        }
      })
      .catch((error) => {
        if (error.response) {
          // Request made and server responded
          const errorData = error.response.data;
          const mensaje =
            errorData?.detalles?.msg ||
            "Ha ocurrido un error al consumir los servicios, inténtelo de nuevo más tarde.";
          handleModalMessage({
            habilite: true,
            mensaje: mensaje,
          });
        } else {
          // Something happened in setting up the request that triggered an Error
          const mensaje =
            "Ha ocurrido un error al consumir los servicios, inténtelo de nuevo más tarde.";
          handleModalMessage({
            habilite: true,
            mensaje: mensaje,
          });
        }
      })
      .finally(() => {
        handleLoading(false);
      });
  };

  const getSostenidaList = () => {
    handleLoading(true);
    axios
      .get(
        `/actividad/recursos/audio/atencion-sostenida?grado=${groupData?.id_grado}`
      )
      .then((response) => {
        // console.log(response.data, "soste")
        if (response.status === 200) {
          setSostenidaList(response.data.resultado?.practica_guiada || []);
        }
      })
      .catch((error) => {
        if (error.response) {
          // Request made and server responded
          const errorData = error.response.data;
          const mensaje =
            errorData?.detalles?.msg ||
            "Ha ocurrido un error al consumir los servicios, inténtelo de nuevo más tarde.";
          handleModalMessage({
            habilite: true,
            mensaje: mensaje,
          });
        } else {
          // Something happened in setting up the request that triggered an Error
          const mensaje =
            "Ha ocurrido un error al consumir los servicios, inténtelo de nuevo más tarde.";
          handleModalMessage({
            habilite: true,
            mensaje: mensaje,
          });
        }
      })
      .finally(() => {
        handleLoading(false);
      });
  };

  const getAutoregList = () => {
    handleLoading(true);
    axios
      .get(`/actividad/recursos/audio/autogestion?grado=${groupData?.id_grado}`)
      .then((response) => {
        // console.log(response.data, "auto")
        if (response.status === 200) {
          setAutoregList(response.data.resultado?.practica_guiada || []);
        }
      })
      .catch((error) => {
        if (error.response) {
          // Request made and server responded
          const errorData = error.response.data;
          const mensaje =
            errorData?.detalles?.msg ||
            "Ha ocurrido un error al consumir los servicios, inténtelo de nuevo más tarde.";
          handleModalMessage({
            habilite: true,
            mensaje: mensaje,
          });
        } else {
          // Something happened in setting up the request that triggered an Error
          const mensaje =
            "Ha ocurrido un error al consumir los servicios, inténtelo de nuevo más tarde.";
          handleModalMessage({
            habilite: true,
            mensaje: mensaje,
          });
        }
      })
      .finally(() => {
        handleLoading(false);
      });
  };

  React.useEffect(() => {
    if (groupData?.id_grado) getRelagacionList();
  }, [groupData?.id_grado]);

  React.useEffect(() => {
    if (groupData?.id_grado) getSostenidaList();
  }, [groupData?.id_grado]);

  React.useEffect(() => {
    if (groupData?.id_grado) getAutoregList();
  }, [groupData?.id_grado]);

  return (
    <ContainerDocente>
      <Grid
        container
        item
        sm={12}
        justifyContent="center"
        alignItems="center"
        className={classes.ContainerBiblioteca}
      >
        <Grid container item sm={12} className={classes.TopBar}></Grid>
        <Grid container item className={classes.ContainerLecturas}>
          <Grid
            container
            item
            xm={12}
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid
              container
              item
              xm={6}
              justifyContent="center"
              alignItems="center"
            >
              <p className="Titulo">Prácticas guiadas</p>
            </Grid>
          </Grid>
          <Grid
            container
            item
            xm={12}
            justifyContent="space-around"
            className={classes.ContainerLecturasImagenes}
          >
            <Grid
              container
              item
              xs={12}
              md={4}
              justifyContent="center"
              alignItems="center"
            >
              <h3 className={classes.tituloPracticas}>Relajación</h3>
              {relajacionLIst?.length > 0 &&
                relajacionLIst?.map((practica1, index) => {
                  // console.log(practica1, "uno")
                  return (
                    <GuidedPracticeCard
                      key={index}
                      grade={practica1.grado}
                      resource={practica1.recurso}
                    />
                  );
                })}
            </Grid>
            <Grid
              container
              item
              xs={12}
              md={4}
              justifyContent="center"
              alignItems="center"
            >
              <h3 className={classes.tituloPracticas}>Atención sostenida</h3>
              {sostenidaLIst?.length > 0 &&
                sostenidaLIst?.map((practica2, index) => {
                  // console.log(practica2, "dos")
                  return (
                    <GuidedPracticeCard
                      key={index}
                      grade={practica2.grado}
                      resource={practica2.recurso}
                    />
                  );
                })}
            </Grid>
            <Grid
              container
              item
              xs={12}
              md={4}
              justifyContent="center"
              alignItems="center"
            >
              <h3 className={classes.tituloPracticas}>Autogestión</h3>
              {autoregLIst?.length > 0 &&
                autoregLIst?.map((practica3, index) => {
                  // console.log(practica3, "tres")
                  return (
                    <GuidedPracticeCard
                      key={index}
                      grade={practica3.grado}
                      resource={practica3.recurso}
                    />
                  );
                })}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </ContainerDocente>
  );
};

export default PracticasGuiadas;
