import { Button, Grid, makeStyles } from "@material-ui/core";
import React from "react";
import BlurContainer from "../../../BlurContainer";
import Answers from "../Answers";

const useStyles = makeStyles((theme) => ({
  layout: {
    maxHeight: "92vh",
    // height: "900px",
    width: "97vw",
    // backgroundColor: "#FEFAF1",
    alignItems: "flex-start",
    [theme.breakpoints.down(1280)]: {
      width: "100vw",
    },
  },
  // evaluationsGrid:{
  //   height:'90%',
  //   boxSizing:'border-box',
  //   // position: 'absolute'
  // },
  mainTextContainer: {
    position: "relative",
    maxHeight: "calc(100vh - 80px)",
    width: "100%",
    [theme.breakpoints.down(1280)]: {
      display: "none",
    },
  },
  questionContainerStepOne: {
    backgroundColor: "#FEFAF1",
    position: "relative",
    maxHeight: "92vh",
    width: "100%",
    // overflowY: "auto",
    display: "grid",
    marginTop: "57px",
    gridTemplateRows: "12vh minmax(240px, 47vh)",
    gridTemplateAreas: `"b"
                                    "c"`,
    justifyItems: "center",
    [theme.breakpoints.up(1280)]: {
      maxWidth: "48%",
    },
    [theme.breakpoints.down(1025)]: {
      width: "100%",
    },
    [theme.breakpoints.down(961)]: {
      width: "80%",
    },
    [theme.breakpoints.down(768)]: {
      padding: "0%",
    },
    [theme.breakpoints.down(620)]: {
      width: "90%",
    },
    [theme.breakpoints.down(570)]: {
      width: "100%",
    },
  },
  questionContainerStepTwo: {
    // backgroundColor: "#FEFAF1",
    maxHeight: "92vh",
    width: "100%",
    overflowY: "auto",
    display: "grid",
    gridTemplateRows: "57px 12vh 47vh 15vh 5vh",
    gridTemplateAreas: `"a"
                                    "b"
                                    "c"
                                    "d"
                                    "e"`,
    justifyItems: "center",
    [theme.breakpoints.down(1025)]: {
      width: "100%",
    },
    [theme.breakpoints.down(961)]: {
      width: "80%",
    },
    [theme.breakpoints.down(768)]: {
      padding: "0%",
    },
    [theme.breakpoints.down(620)]: {
      width: "90%",
    },
    [theme.breakpoints.down(570)]: {
      width: "100%",
    },
  },
  instructionsContainer: {
    width: "80%",
    minHeight: "12vh",
    gridArea: "b",
    backgroundColor: "#FEFAF1",
    // [theme.breakpoints.down(601)]:{
    // 	marginTop:'12%'
    // },
  },
  FinalInstructionsContainer: {
    marginTop: "15px",
    width: "80%",
    minHeight: "25vh",
    [theme.breakpoints.down(1440)]: {
      marginTop: "10px",
    },
  },
  buttonContainer: {
    width: "100%",
    height: "15vh",
    gridArea: "d",
    position: "relative",
  },
  // progressContainer:{
  //   width:"100%",
  //   height:"10%"
  // },
  reading: {
    textAlign: "justify",
    width: "80%",
    font: "16px Lato",
    letterSpacing: "0.21px",
    "@media(min-height: 500px)": {
      font: "22px Lato",
    },
  },
  backButton: {
    height: "30px",
    backgroundColor: "#FEFAF1",
    color: "#F2624F",
    border: "#F2624F 1px solid",
    borderRadius: "20px",
    textTransform: "none",
    font: "regular 20px Lato",
    letterSpacing: "0.14px",
  },
  arrowBackIcon: {
    marginRight: "5px",
  },
  continueButton: {
    // marginTop: "6%",
    // marginBottom: "6%",
    textTransform: "none",
    height: "62px",
    width: "279px",
    [theme.breakpoints.down(1368)]: {
      height: "52px",
      width: "229px",
    },
    [theme.breakpoints.down(1068)]: {
      height: "52px",
      width: "60%",
    },
    [theme.breakpoints.down(769)]: {
      width: "56.5%",
    },
    background: "#7A54BA",
    // boxShadow: "0px 3px 6px #00000029",
    borderRadius: "61px",
    color: "#FFFFFF",
    font: "bold 20px lato ",
    boxShadow: "0px 0px 15px 20px #fff",
    "&:hover": {
      background: "#dd78fb",
    },
  },
  answerContainer: {
    height: "100%",
    maxWidth: "80%",
    gridArea: "c",
    backgroundColor: "#FEFAF1",
    [theme.breakpoints.down(860)]: {
      maxWidth: "100%",
    },
  },
  answerTextContainer: {
    minHeight: "40vh",
  },
  progressLinearContainer: {
    height: "5vh",
    width: "72%",
    gridArea: "e",
    [theme.breakpoints.down(1024)]: {
      width: "82%",
    },
  },
  progressLinearBar: {
    width: "90%",
    height: "8%",
  },
  readingDemoContainer: {
    // maxHeight: "1024px",
    width: "653px",
    height: "835px",
  },
  arrowButton: {
    position: "absolute",
    left: "30px",
    margin: "2% 0 1% 0",
  },
  progress: {
    borderRadius: "7px",
    width: "95%",
    height: "15px",
    marginLeft: -"11.5%",
    boxShadow: " 1px 1px 4px rgba( 0, 0, 0, 0.2 )",
    "&::-webkit-progress-bar": {
      backgroundColor: "#F2624F",
      borderRadius: "7px",
    },
    "&::-webkit-progress-value": {
      backgroundColor: "#84E9DF",
      borderRadius: "7px",
      // boxShadow: "1px 1px 5px 3px rgba( 255, 0, 0, 0.8 )",
    },
  },
  link: {
    transform: "translate(-50%,-50%)",
    textDecoration: "none",
  },
  readingButton: {
    display: "none",
    [theme.breakpoints.down(1280)]: {
      display: "grid",
    },
  },
  showReadingButton: {
    marginTop: "10px",
    textTransform: "none",
    font: "24px Fredoka One",
    color: "white",
    backgroundColor: "#60BFAE",
    borderRadius: "50px 50px 0 0",
    width: "411px",
    height: "58px",
    paddingTop: "20px",
    [theme.breakpoints.down(700)]: {
      width: "250px",
    },
  },
  puntaje: {
    zIndex: "5",
    border: "black 1px solid",
    width: "200px",
    height: "40px",
    // '&:hover':{
    //   background: '#dd78fb',
    // },
  },
  emotionalTagContainer: {
    width: "57px",
    height: "57px",
    gridArea: "a",
    [theme.breakpoints.down(1400)]: {
      width: "47px",
      height: "47px",
    },
    [theme.breakpoints.down(1020)]: {
      height: "42px",
    },
  },
  emotionalTagGrid: {
    width: "55px",
    height: "90%",
    backgroundColor: "#FFDE72D1",
    border: "#B64F88 3px solid",
    borderRadius: "0 0 20px 20px",
    marginLeft: "10%",
    display: "none",
    [theme.breakpoints.down(1400)]: {
      width: "47px",
    },
    [theme.breakpoints.down(1020)]: {
      width: "40px",
      margin: "0 10%",
    },
  },
  imgSociEm: {
    [theme.breakpoints.down(1020)]: {
      height: "20px",
    },
  },

  dialog: {
    right: "105%",
    zIndex: "1500",
    top: "calc(50% - 100px)",
    margin: "0 auto",
    backgroundColor: "#3f43b3",
    fontFamily: "Lato",
    letterSpacing: "0.36px",
    fontSize: "16px",
    color: "#ffffff",
    position: "absolute",
    minWidth: "520px",
    width: "auto",
    maxWidth: "480px",
    padding: "16px",
    boxSizing: "border-box",
    borderRadius: "21.25px",
    [theme.breakpoints.down(1280)]: {
      top: "105%",
      right: "unset",
      "@media(min-height:800px)": {
        fontSize: "20px",
        padding: "24px",
      },
    },

    "&:before": {
      // display: (props) => (props.openMenuInDesktop ? "none" : "flex"),
      display: "flex",
      zIndex: 1,
      textTransform: "none",
      justifyContent: "center",
      alignItems: "center",
      // borderRadius: "25px",
      position: "absolute",
      content: '""',
      border: "15px solid",
      borderTopColor: "transparent",
      borderLeftColor: "#3f43b3",
      borderBottomColor: "transparent",
      borderRightColor: "transparent",
      font: "bold 14px lato",
      bottom: "calc(50% - 15px)",
      right: "-28px",
      [theme.breakpoints.down(1280)]: {
        borderTopColor: "transparent",
        borderLeftColor: "transparent",
        borderBottomColor: "#3f43b3",
        borderRightColor: "transparent",
        right: "calc(50% - 15px)",
        top: "-28px",
        bottom: "unset",
      },
    },
  },
  dialogContinue: {
    bottom: "100%",
    margin: "0 auto",
    backgroundColor: "#3f43b3",
    fontFamily: "Lato",
    letterSpacing: "0.36px",
    fontSize: "20px",
    color: "#ffffff",
    position: "absolute",
    minWidth: "520px",
    width: "auto",
    maxWidth: "480px",
    padding: "24px",
    boxSizing: "border-box",
    borderRadius: "21.25px",

    "&:before": {
      // display: (props) => (props.openMenuInDesktop ? "none" : "flex"),
      display: "flex",
      zIndex: 1,
      textTransform: "none",
      justifyContent: "center",
      alignItems: "center",
      // borderRadius: "25px",
      position: "absolute",
      content: '""',
      border: "15px solid",
      borderTopColor: "#3f43b3",
      borderLeftColor: "transparent",
      borderBottomColor: "transparent",
      borderRightColor: "transparent",
      font: "bold 14px lato",
      right: "calc(50% - 15px)",
      bottom: "-28px",
    },
  },
}));

const ActivityStep = ({
  step,
  toSession,
  questionList,
  setPristine,
  setIsCorrect,
  setFeedback,
  setAnswerText,
  setCurrentAnswer,
  currentAnswer,
  setAnswerImage,
  setTotalPoints,
  totalPoints,
  setValueAnswerText,
  valueAnswerText,
  feedBackImage,
  setFeedBackImage,
  questionId,
  setQuestionId,
  imageList,
  QuestionComponent,
  pristine,
  feedBackContinue,
  Lectura,
  stepTutorial,
}) => {
  const classes = useStyles({});

  return (
    <>
      <BlurContainer handleClose open>
        <Grid
          container
          direction="row"
          item
          sm={12}
          className={classes.layout}
          justifyContent="center"
        >
          <Grid
            container
            item
            md={6}
            direction="column"
            justifyContent="flex-start"
            alignItems="flex-start"
            className={classes.mainTextContainer}
            // style={renderFuncional()}
          >
            <Grid
              container
              item
              xs={12}
              justifyContent="center"
              alignItems="center"
              className={classes.readingDemoContainer}
            >
              <Grid
                container
                item
                justifyContent="center"
                xs={12}
                className={classes.Contenedor}
              ></Grid>
            </Grid>
          </Grid>
          <Grid
            container
            item
            md={6}
            justifyContent="center"
            alignItems="center"
            className={
              !stepTutorial.one
                ? classes.questionContainerStepOne
                : classes.questionContainerStepTwo
            }
          >
            {!stepTutorial.one && (
              <>
                <p className={classes.dialog}>
                  Ahora verás las preguntas de la sesión del lado derecho y la
                  lectura del lado izquierdo, la cual te servirá de guía para
                  responder cada una de las preguntas, en total son 10.
                </p>
                <Grid
                  container
                  item
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  className={
                    step === 9
                      ? classes.FinalInstructionsContainer
                      : classes.instructionsContainer
                  }
                >
                  {QuestionComponent}
                </Grid>
                <Grid
                  container
                  item
                  justifyContent="center"
                  // alignItems="center"
                  className={
                    step === 9
                      ? classes.answerTextContainer
                      : classes.answerContainer
                  }
                >
                  {questionList && questionList.length && (
                    <Answers
                      respuestas={questionList[step].respuestas}
                      setPristine={setPristine}
                      setIsCorrect={setIsCorrect}
                      setFeedback={setFeedback}
                      setAnswerText={setAnswerText}
                      setCurrentAnswer={setCurrentAnswer}
                      currentAnswer={currentAnswer}
                      setAnswerImage={setAnswerImage}
                      setTotalPoints={setTotalPoints}
                      totalPoints={totalPoints}
                      setValueAnswerText={setValueAnswerText}
                      valueAnswerText={valueAnswerText}
                      questionType={questionList[step].tipo_pregunta}
                      feedBackImage={feedBackImage}
                      setFeedBackImage={setFeedBackImage}
                      questionId={questionId}
                      setQuestionId={setQuestionId}
                      imageList={imageList}
                    />
                  )}
                </Grid>
              </>
            )}

            {stepTutorial.one && !stepTutorial.two && (
              <Grid
                container
                item
                justifyContent="center"
                alignItems="center"
                className={classes.buttonContainer}
              >
                <p className={classes.dialogContinue}>
                  Cuando hayas respondido la pregunta,  haz clic en
                  <b> Continuar. </b>
                </p>
                {
                  <Button
                    className={classes.continueButton}
                    disabled={pristine}
                    onClick={feedBackContinue}
                  >
                    Continuar
                  </Button>
                }
              </Grid>
            )}
          </Grid>
        </Grid>
      </BlurContainer>
    </>
  );
};

export default ActivityStep;
