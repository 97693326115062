import { Button, Grid, makeStyles } from "@material-ui/core";
import React, { useContext, useEffect } from "react";
import DrawerContext from "../../../../../usability/DrawerContext";
import { useOnLoadImages } from "../../../../hooks/useOnLoadImages";
import Loader from "../../../../Loader/Loader";
import Drawer from "../../ReadingTools/Drawer";

const useStyles =  makeStyles (theme =>({
     Contenedor:{
        background:'#E9FBF9',
        width:'100vw',
        overflowX:'clip',
        [theme.breakpoints.down(1161)]:{
            background:'none',
           overflowX:'unset',
        },
        [theme.breakpoints.down(600)]:{
            marginTop: '79.51px',
        }
    },
  ContainerInfoLectura: {
    position: "relative",
    height: "86px",
    [theme.breakpoints.down(502)]:{
        height: "43px",
    },
    background: "#76D4C3",
    "& .BarraInfoLectura": {
      height: "86px",
      background: `Url(https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Barras/Azul.png)`,
        [theme.breakpoints.down(502)]:{
            height: "43px",
        },
    },
    "& .InfoLectura": {
      position: "absolute",
      height: "86px",
      width: "219px",
      boxSizing: "border-box",
      padding: "17.2px 0",
      background: "#FAFAFA",
      borderRadius: "50px",
      boxShadow: "2px 2px 2px 2px #00000029",
      zIndex: "4",
        [theme.breakpoints.down(502)]:{
            height: "43px",
            width: "109px",
            padding: "8.6px 0",
            borderRadius: "25px",
        },
    },
    "& .InfoLectura:before": {
      position: "absolute",
      display: "flex",
      boxSizing: "border-box",
      padding: "0 0 0 10px",
      justifyContent: "center",
      alignItems: "center",
      height: "39.88px",
      width: "39.88px",
      borderRadius: "50%",
      content: '""',
      boxShadow: "3px 0px .5px 0px #00000029",
      background: "#FAFAFA",
      left: "90px",
      top: "65px",
      transform: "rotate(0.25turn)",
      [theme.breakpoints.down(502)]:{
        left: "45px",
        top: "30px",
        height: "20.88px",
        width: "20.88px",
        },
    },
    "& .InfoLectura:after": {
      content: '""',
      height: "15px",
      width: "15px",
      borderTop: "2px solid #20A698",
      borderLeft: "2px solid #20A698",
      position: "absolute",
      bottom: "-10px",
      left: "Calc(50% - 8px)",
      transform: "rotate(0.625turn)",
      [theme.breakpoints.down(502)]:{
        height: "10px",
        width: "10px",
        left: "Calc(50% - 4.5px)",
        bottom: "-3px",
        borderTop: "1px solid #20A698",
        borderLeft: "1px solid #20A698",
        },
    },
    "& .Sesion": {
      borderLeft: "2px solid #D8D8D8",
    },
    "& .Dato": {
      margin: "0 0 5px 0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#20A698",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        margin: "0 0 2.5px 0",
        },
    },
    "& .Caracter": {
      margin: "0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#000000",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        },
    },
       '& .Regresar':{
        color: '#F2624F',
        border: '#F2624F 1px solid',
        height: '30px',
        width:'100px',
        borderRadius: '20px',
        letterSpacing: '0.14px',
        textTransform: 'none',
        background:'#FEFAF1',
        top:'calc(50% - 15px)',
        left:'5%',
        zIndex:4,
        [theme.breakpoints.down(502)]: {
          font:'bold 10px lato',
          width: "60px",
        },
      },
  },
    ContenedorLectura:{
        background:'#fff',
        width:'1160px',
        height:'100%',
        padding:'60px 168px 35px ',
        
        transform: (props) =>
        props.scale > 1 ? `scale(${props.scale}) translate(0px, 10%)` : `none`,
        [theme.breakpoints.down(1161)]:{
            width:'502px',
            padding:'60px 0 0 0',
          },
                [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },

        '& .Escritorio':{
            display:'block',
            [theme.breakpoints.down(1161)]:{
                display:'none'
            },
        },
        '& .Tablet':{
            display:'none',
            [theme.breakpoints.down(1161)]:{
                display:'block'
            },
        }
    },
    ContainerTitulo:{
        margin:'0 0 34px 0',
        [theme.breakpoints.down(1161)]:{
            margin:'0 0 34px 0',
        },
        '& .Titulo':{
            font:'48px fredoka one',
            letterSpacing: '0px',
            lineHeight:'58px',
            color: '#F6644B',
            margin:'0',
            textAlign:'center',
            [theme.breakpoints.down(1161)]:{
                font:'43px fredoka one',
                lineHeight:'52px',
            },
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            lineHeight : '30px',
            margin:'0 0 36px 0',
            font:'22px lato',
            color:'#000',
            letterSpacing: '0.20px',
            [theme.breakpoints.down(1161)]:{
                font:'18px lato',
                lineHeight :'28px',
                letterSpacing: '0.17px',
            },
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .SubTitulo':{
            font:'bold 30px lato',
            color:'#0093FF',
            letterSpacing:'.28px',
            lineHeight:'30px',
            margin:'0',
            [theme.breakpoints.down(1161)]:{
                lineHeight :'28px',
                font:'bold 26px lato',
                letterSpacing: '0.24px',
            },
            [theme.breakpoints.down(502)]:{
                font:'bold 18px lato',
                lineHeight :'24px',
                letterSpacing: '0.21px',
            },
        },
        '& .SubTitulo2':{
            font:'bold 26px lato',
            color:'#823CE2',
            letterSpacing:'.24px',
            lineHeight:'28px',
            margin:'0',
            [theme.breakpoints.down(1161)]:{
                font:'bold 22px lato',
                lineHeight :'24px',
                letterSpacing: '0.21px',
            },
            [theme.breakpoints.down(502)]:{
                font:'bold 18px lato',
                lineHeight :'20px',
                letterSpacing: '0.16px',
            },
        },
        '& .Cartel':{
            margin:'0 0 36px 0'
        },  
        '& .Imagen':{
            [theme.breakpoints.down(1161)]:{
                width:'100%',
            },
        },  
        '& .Imagen2':{
            [theme.breakpoints.down(1161)]:{
                width:'63.6%',
            },
        },
      
    }
}));

const Privacidad = (props) => {

  const {activeStep, setActiveStep, handlePreviewStep, id_actividad} = props
  
  const handleContinue = () => {
    setActiveStep(activeStep - 1)
    handlePreviewStep()
  }

    const {
      handleSize,
    disabledZoom,
    closeZoom,
    handleZoom,
    scale
  }= useContext(DrawerContext);
  const [loading, setLoading] = React.useState(true);
  const wrapperRef = React.useRef(null);
  const imagesLoaded = useOnLoadImages(wrapperRef);

  const detectSize = () => {
    const lectura = document.getElementById("lectura");
    const newWidth = lectura?.offsetWidth;
    const newHeight = lectura?.offsetHeight;
    setLoading(false);
    if (lectura) handleSize(newHeight, newWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", detectSize);
    return () => {
      handleZoom()
      window.removeEventListener("resize", detectSize);
      closeZoom()
    };
  }, []);

  const classes = useStyles({ disabledZoom,scale });
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
             <Grid container item justifyContent="center" className={classes.ContainerInfoLectura}>
                       <Grid container className="BarraInfoLectura">
            <Button className="Regresar" onClick={handleContinue}>
                Regresar
            </Button>
        </Grid>
               <Grid container item className="InfoLectura">
                    <Grid container item direction="column" xs={6} justifyContent="center">
                        <p className="Dato">Grado</p>
                        <p className="Caracter">5</p>
                    </Grid>
                    <Grid container item direction="column" xs={6} justifyContent="center" className="Sesion">
                        <p className="Dato">Sesión</p>
                        <p className="Caracter">18</p>
                    </Grid>
               </Grid>
            </Grid>
            {!imagesLoaded && <Loader />}
        <Grid
            container
            item
            className={classes.ContenedorLectura}
            justifyContent="center"
            id="lectura"
            onLoad={detectSize}
            ref={wrapperRef}
        >
<Drawer id_lectura={id_actividad} />
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                        <h2 className="Titulo">Privacidad</h2>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container justifyContent='center' className='Cartel'>
                            <img className="Imagen2" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion18/1.png" alt="Tarjeta" />
                    </Grid>
                    <Grid container >
                        <p className='Lectura'>
                            Hay ciertas cosas que las personas preferimos hacer en privado. Algunas de 
                            ellas involucran nuestro cuerpo. Nadie puede ver ni tocar tu cuerpo sin tu 
                            consentimiento. Por eso es muy importante que todos respetemos la intimidad 
                            de los demás, por ejemplo, cuando se cambian de ropa o están en el baño.<br/><br/>

                            <h3 className='SubTitulo'>Privacidad y palabras</h3> <br/>
                            
                            La <em>privacidad</em> no sólo se relaciona con el cuerpo de una persona, 
                            sino también con sus pensamientos y sus experiencias. ¿Alguna vez has 
                            llevado un diario? Lo que escribes allí es algo íntimo, algo que has 
                            decidido no compartir con los demás, porque te avergüenza que lo sepan, 
                            porque podría herirlos o por cualquier razón que a ti te parezca importante. 
                            Si una persona leyera el diario sin tu consentimiento, violaría tu privacidad. 
                            Lo mismo sucede cuando alguien te confía un secreto: está contando con 
                            que no se lo dirás a nadie. Para esa persona eres como un diario, y si 
                            cuentas el secreto, sentirá que perdió algo muy personal. <br/><br/>
                            
                            <h3 className='SubTitulo'>Privacidad digital</h3><br/>

                            Hemos hablado del cuerpo y de los pensamientos, pero la privacidad 
                            también es importante en otro ámbito: nuestros datos. Tu nombre y tu dirección, 
                            la escuela en la que estudias, los deportes que practicas, los instrumentos que 
                            tocas, tus canciones favoritas o las series que más te gustan. Todos éstos son 
                            datos <em>privados</em> y sólo se comparten con personas de confianza.<br/><br/>
                            
                            Hasta hace unas décadas, cuando el internet y las redes sociales no existían, era 
                            más fácil mantener en privado los datos personales. Ahora que estos medios 
                            son la forma de comunicación más común, puede parecerte normal que las personas 
                            compartan allí algunos aspectos de su vida. Pero ¿te has detenido a pensar qué pasa con 
                            esa información? <br/><br/>
                            
                            <h3 className='SubTitulo2'>Un ejemplo: Raúl</h3><br/>
                            
                            Raúl juega basquetbol en la escuela y su equipo participa en la liga escolar de 
                            su localidad. Después del último juego subió algunas fotografías en sus redes para 
                            compartir el triunfo de su equipo. Raúl piensa que esa información sólo la van a ver 
                            sus amigos, pero ¿cuántas personas tienen acceso a ella en realidad?
                        </p>
                    </Grid>
                    <Grid container justifyContent='center' >
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion18/2.png" alt="Tarjeta" />
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container >
                        <p className='Lectura'>
                            Gran parte de la información que compartes en internet es pública: cualquier persona
                            puede verla. Algún desconocido puede ver las fotos que Raúl subió del juego y rastrearlo a 
                            partir de ellas. El nombre de la escuela en la que estudia aparece en su uniforme. También 
                            tiene su apellido en el dorso de la camiseta. Quizá el extraño pueda averiguar su nombre 
                            completo con apenas estos dos datos. Al conocer el equipo en el que juega puede investigar 
                            los días y las horas en que Raúl entrena, o enterarse de cuándo y dónde se realizará el siguiente 
                            partido en el que va a participar. También sabe quién es su hermano, porque Raúl lo etiquetó en 
                            una de las fotografías. Si es así, deduce que el hermano toma clases de violín, porque la imagen 
                            lo muestra llevando en la mano un maletín con el instrumento. <br/><br/>
                            
                            <h3 className='SubTitulo'>Consecuencias y precauciones</h3><br/>
                            
                            Como ves, hay mucha información que cualquier desconocido puede extraer de las redes sociales. 
                            Algunas personas pueden utilizarla para fines indeseables. Pueden estafar o extorsionar a tus 
                            padres, o incluso a ti. Pueden suplantar tu identidad. Puedes ser víctima de <em>bullying</em> por 
                            lo que compartes ahora o por lo que compartiste hace años. <br/><br/>
                            
                            El uso de internet y de las redes sociales deja al descubierto mucha información personal, y 
                            hace difícil mantener tu privacidad en la red. Por ello es primordial que seas muy cuidadoso 
                            con lo que compartes y con el lugar en el que lo haces. <br/><br/>
                            
                            Actualmente existen leyes de protección de datos. Además, muchos sitios web y otras organizaciones 
                            tienen lineamientos éticos para guiar el comportamiento digital. Asegúrate de cumplirlos, como 
                            cualquier otro reglamento. Recuerda: lo más básico, y quizá lo más importante, es no compartir 
                            nada en la red sin que todas las personas involucradas te den permiso de forma explícita.
                        </p>
                    </Grid>
                </Grid>
        </Grid>
        <Grid container item style={{ marginTop: 80}} ></Grid>
    </Grid>
    );
}
 
export default Privacidad;
