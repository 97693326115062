import React from "react";
import ProtectedRoute from "./ProtectedRoute";

import LectS_4_20 from "../pages/Container/ContainerLecturasBiblioteca4to/Sesion20";
import LectS_4_21R from "../pages/Container/ContainerLecturasBiblioteca4to/Sesion21R";
import LectS_4_38 from "../pages/Container/ContainerLecturasBiblioteca4to/Sesion38";
import LectS_4_39R from "../pages/Container/ContainerLecturasBiblioteca4to/Sesion39R";
import LectS_4_56 from "../pages/Container/ContainerLecturasBiblioteca4to/Sesion56";
import LectS_4_56R from "../pages/Container/ContainerLecturasBiblioteca4to/Sesion56R";

import LectS_4_3 from "../pages/Container/ContainerLecturasBiblioteca4to/Sesion3";
import LectS_4_4 from "../pages/Container/ContainerLecturasBiblioteca4to/Sesion4";
import LectS_4_5 from "../pages/Container/ContainerLecturasBiblioteca4to/Sesion5";
import LectS_4_6 from "../pages/Container/ContainerLecturasBiblioteca4to/Sesion6";
import LectS_4_7 from "../pages/Container/ContainerLecturasBiblioteca4to/Sesion7";
import LectS_4_8 from "../pages/Container/ContainerLecturasBiblioteca4to/Sesion8";
import LectS_4_9 from "../pages/Container/ContainerLecturasBiblioteca4to/Sesion9";
import LectS_4_10 from "../pages/Container/ContainerLecturasBiblioteca4to/Sesion10";
import LectS_4_11 from "../pages/Container/ContainerLecturasBiblioteca4to/Sesion11";
import LectS_4_12 from "../pages/Container/ContainerLecturasBiblioteca4to/Sesion12";
import LectS_4_13 from "../pages/Container/ContainerLecturasBiblioteca4to/Sesion13";
import LectS_4_14 from "../pages/Container/ContainerLecturasBiblioteca4to/Sesion14";
import LectS_4_15 from "../pages/Container/ContainerLecturasBiblioteca4to/Sesion15";
import LectS_4_16 from "../pages/Container/ContainerLecturasBiblioteca4to/Sesion16";
import LectS_4_17 from "../pages/Container/ContainerLecturasBiblioteca4to/Sesion17";
import LectS_4_18 from "../pages/Container/ContainerLecturasBiblioteca4to/Sesion18";
import LectS_4_19 from "../pages/Container/ContainerLecturasBiblioteca4to/Sesion19";
import LectS_4_21 from "../pages/Container/ContainerLecturasBiblioteca4to/Sesion21";
import LectS_4_22 from "../pages/Container/ContainerLecturasBiblioteca4to/Sesion22";
import LectS_4_23 from "../pages/Container/ContainerLecturasBiblioteca4to/Sesion23";
import LectS_4_24 from "../pages/Container/ContainerLecturasBiblioteca4to/Sesion24";
import LectS_4_25 from "../pages/Container/ContainerLecturasBiblioteca4to/Sesion25";
import LectS_4_26 from "../pages/Container/ContainerLecturasBiblioteca4to/Sesion26";
import LectS_4_27 from "../pages/Container/ContainerLecturasBiblioteca4to/Sesion27";
import LectS_4_28 from "../pages/Container/ContainerLecturasBiblioteca4to/Sesion28";
import LectS_4_29 from "../pages/Container/ContainerLecturasBiblioteca4to/Sesion29";
import LectS_4_30 from "../pages/Container/ContainerLecturasBiblioteca4to/Sesion30";
import LectS_4_31 from "../pages/Container/ContainerLecturasBiblioteca4to/Sesion31";
import LectS_4_32 from "../pages/Container/ContainerLecturasBiblioteca4to/Sesion32";
import LectS_4_33 from "../pages/Container/ContainerLecturasBiblioteca4to/Sesion33";
import LectS_4_34 from "../pages/Container/ContainerLecturasBiblioteca4to/Sesion34";
import LectS_4_35 from "../pages/Container/ContainerLecturasBiblioteca4to/Sesion35";
import LectS_4_36 from "../pages/Container/ContainerLecturasBiblioteca4to/Sesion36";
import LectS_4_37 from "../pages/Container/ContainerLecturasBiblioteca4to/Sesion37";
import LectS_4_39 from "../pages/Container/ContainerLecturasBiblioteca4to/Sesion39";
import LectS_4_40 from "../pages/Container/ContainerLecturasBiblioteca4to/Sesion40";
import LectS_4_41 from "../pages/Container/ContainerLecturasBiblioteca4to/Sesion41";
import LectS_4_42 from "../pages/Container/ContainerLecturasBiblioteca4to/Sesion42";
import LectS_4_43 from "../pages/Container/ContainerLecturasBiblioteca4to/Sesion43";
import LectS_4_44 from "../pages/Container/ContainerLecturasBiblioteca4to/Sesion44";
import LectS_4_45 from "../pages/Container/ContainerLecturasBiblioteca4to/Sesion45";
import LectS_4_46 from "../pages/Container/ContainerLecturasBiblioteca4to/Sesion46";
import LectS_4_47 from "../pages/Container/ContainerLecturasBiblioteca4to/Sesion47";
import LectS_4_48 from "../pages/Container/ContainerLecturasBiblioteca4to/Sesion48";
import LectS_4_49 from "../pages/Container/ContainerLecturasBiblioteca4to/Sesion49";
import LectS_4_50 from "../pages/Container/ContainerLecturasBiblioteca4to/Sesion50";
import LectS_4_51 from "../pages/Container/ContainerLecturasBiblioteca4to/Sesion51";
import LectS_4_52 from "../pages/Container/ContainerLecturasBiblioteca4to/Sesion52";
import LectS_4_53 from "../pages/Container/ContainerLecturasBiblioteca4to/Sesion53";
import LectS_4_54 from "../pages/Container/ContainerLecturasBiblioteca4to/Sesion54";
import LectS_4_55 from "../pages/Container/ContainerLecturasBiblioteca4to/Sesion55";
import LectS_4_57 from "../pages/Container/ContainerLecturasBiblioteca4to/Sesion57";
import LectS_4_58 from "../pages/Container/ContainerLecturasBiblioteca4to/Sesion58";

const routes = [
  <ProtectedRoute
    exact
    path="/Todos_en_uno_y_uno_en_todos"
    component={LectS_4_20}
  />,
  <ProtectedRoute exact path="/Viajes_en_el_tiempo" component={LectS_4_21R} />,
  <ProtectedRoute
    exact
    path="/Los_derechos_de_los_consumidores"
    component={LectS_4_38}
  />,
  <ProtectedRoute exact path="/El_Galeón_de_Manila" component={LectS_4_39R} />,
  <ProtectedRoute
    exact
    path="/El_caballo_hace_un_recorrido_semimágico"
    component={LectS_4_55}
  />,
  <ProtectedRoute exact path="/La_cocina_como_arte" component={LectS_4_56R} />,
  <ProtectedRoute exact path="/336" component={LectS_4_3} />,
  //*Arma_tu_propia_alcancía" 336
  <ProtectedRoute exact path="/337" component={LectS_4_4} />,
  //*Las_abejas_y_la_polinización"
  <ProtectedRoute exact path="/338" component={LectS_4_5} />,
  //*Para_qué_sirve_un_museo"
  <ProtectedRoute exact path="/339" component={LectS_4_6} />,
  //*De_otro_planeta"
  <ProtectedRoute exact path="/340" component={LectS_4_7} />,
  //*Los_anteojos_una_historia_inesperada"
  <ProtectedRoute exact path="/341" component={LectS_4_8} />,
  //*Operaciones_con_conjuntos"
  <ProtectedRoute exact path="/342" component={LectS_4_9} />,
  //*Países_en_distintas_vías_de_desarrollo"
  <ProtectedRoute exact path="/343" component={LectS_4_10} />,
  //*Las_torres_de_Hanói"
  <ProtectedRoute exact path="/344" component={LectS_4_11} />,
  //*La_carrera_espacial"
  <ProtectedRoute exact path="/345" component={LectS_4_12} />,
  //*El_Festival_Cervantino_en_época_virtual"
  <ProtectedRoute exact path="/346" component={LectS_4_13} />,
  //*La_hoja_mágica"
  <ProtectedRoute exact path="/348" component={LectS_4_15} />,
  //*Economía_naranja_creativa_y_sostenible"
  <ProtectedRoute exact path="/347" component={LectS_4_14} />,
  //*Muse_del_juego"
  <ProtectedRoute exact path="/349" component={LectS_4_16} />,
  //*La_misión_de_las_nubes"
  <ProtectedRoute exact path="/350" component={LectS_4_17} />,
  //*La_importancia_del_calcio"
  <ProtectedRoute exact path="/351" component={LectS_4_18} />,
  //*Diferentes_reglas"
  <ProtectedRoute exact path="/352" component={LectS_4_19} />,
  //*Todo_a_su_lugar"
  <ProtectedRoute exact path="/355" component={LectS_4_21} />,
  //*El_teatro_y_su_historia"
  <ProtectedRoute exact path="/356" component={LectS_4_22} />,
  //*Qué_hace_un_arqueólogo"
  <ProtectedRoute exact path="/357" component={LectS_4_23} />,
  //*El_extranjero"
  <ProtectedRoute exact path="/358" component={LectS_4_24} />,
  //*La_educación_es_un_derecho_para_todos"
  <ProtectedRoute exact path="/359" component={LectS_4_25} />,
  //*El_secreto_de_la_vida"
  <ProtectedRoute exact path="/360" component={LectS_4_26} />,
  //*Hipatia_de_Alejandría_y_las_matemáticas"
  <ProtectedRoute exact path="/361" component={LectS_4_27} />,
  //*El_Coco"
  <ProtectedRoute exact path="/362" component={LectS_4_28} />,
  //*Igualdad_y_equidad"
  <ProtectedRoute exact path="/363" component={LectS_4_29} />,
  //*Niños_de_azúcar"
  <ProtectedRoute exact path="/364" component={LectS_4_30} />,
  //*Hablar_con_emojis"
  <ProtectedRoute exact path="/365" component={LectS_4_31} />,
  //*Siempre_al_mar"
  <ProtectedRoute exact path="/366" component={LectS_4_32} />,
  //*Tecnología_digital"
  <ProtectedRoute exact path="/367" component={LectS_4_33} />,
  //*Viajeros_en_el_espacio_asteroides_y_cometas"
  <ProtectedRoute exact path="/368" component={LectS_4_34} />,
  //*Las_Vitaminas_se_comen"
  <ProtectedRoute exact path="/369" component={LectS_4_35} />,
  //*Energía_limpia_para_el_planeta_Tierra"
  <ProtectedRoute exact path="/370" component={LectS_4_36} />,
  //*Declaración_Universal_de_los_Derechos_Humanos"
  <ProtectedRoute exact path="/371" component={LectS_4_37} />,
  //*Jugando_con_la_historia"
  <ProtectedRoute exact path="/374" component={LectS_4_39} />,
  //*Qué_son_las_artes_plásticas"
  <ProtectedRoute exact path="/375" component={LectS_4_40} />,
  //*La_libertad_de_expresión"
  <ProtectedRoute exact path="/376" component={LectS_4_41} />,
  //*Viaje_a_lo_invisible"
  <ProtectedRoute exact path="/377" component={LectS_4_42} />,
  //*Fuegos_artificiales_y_su_contaminación"
  <ProtectedRoute exact path="/378" component={LectS_4_43} />,
  //*Anuncio_impresora"
  <ProtectedRoute exact path="/379" component={LectS_4_44} />,
  //*El_sombrerero_loco"
  <ProtectedRoute exact path="/380" component={LectS_4_45} />,
  //*Göbekli_Tepe"
  <ProtectedRoute exact path="/381" component={LectS_4_46} />,
  //*El_arte_de_la_pintura"
  <ProtectedRoute exact path="/382" component={LectS_4_47} />,
  //*El_primer_alimento_de_nuestra_vida_la_leche"
  <ProtectedRoute exact path="/383" component={LectS_4_48} />,
  //*Go_el_juego_de_mesa_hino"
  <ProtectedRoute exact path="/384" component={LectS_4_49} />,
  //*Una_fogata_en_el_cielo"
  <ProtectedRoute exact path="/385" component={LectS_4_50} />,
  //*La_importancia_del_sueño"
  <ProtectedRoute exact path="/386" component={LectS_4_51} />,
  //*Derecho_a_la_salud"
  <ProtectedRoute exact path="/387" component={LectS_4_52} />,
  //*Horario_de_vuelos"
  <ProtectedRoute exact path="/388" component={LectS_4_53} />,
  //*Distanciamiento_social_y_acercamiento_virtual"
  <ProtectedRoute exact path="/389" component={LectS_4_54} />,
  //*El_reciclaje"
  <ProtectedRoute exact path="/392" component={LectS_4_56} />,
  //*El_origen_del_cine"
  <ProtectedRoute exact path="/393" component={LectS_4_57} />,
  //*Una_obra_de_teatro"
  <ProtectedRoute exact path="/394" component={LectS_4_58} />,
  //*Los_números_triangulares"
];

const Lectura4to = routes.map((route, key) => {
  return { ...route, key: key };
});

export default Lectura4to;
