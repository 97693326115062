import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
      Contenedor:{
        background:'#E9FBF9',
        width:'100vw',
        overflowX:'clip',
        [theme.breakpoints.down(1161)]:{
            background:'none',
           overflowX:'unset',
        },
        [theme.breakpoints.down(600)]:{
            marginTop: '79.51px',
        }
    },
    ContainerInfoLectura:{
        position:'relative',
        height:'86px',
        background:'#76D4C3',
        '& .BarraInfoLectura':{
            height:'86px',
            background:`Url(https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Barras/Verde.png)`,
        },
        '& .InfoLectura':{
            position:'absolute',
            height:'86px',
            width:'219px',
            boxSizing:'border-box',
            padding:'17.2px 0',
            background:'#FAFAFA',
            borderRadius:'50px',
            boxShadow: '2px 2px 2px 2px #00000029',
            zIndex:'4',
        },
        '& .InfoLectura:before':{
            position:'absolute',
            display:'flex',
            boxSizing:'border-box',
            padding:'0 0 0 10px',
            justifyContent:'center',
            alignItems:'center',
            height:'39.88px',
            width:'39.88px',
            borderRadius:'50%',
            content:'""',
            boxShadow: '3px 0px .5px 0px #00000029',
            background:'#FAFAFA',
            left:'90px',
            top:'65px',
            transform: 'rotate(0.25turn)',
        },
        '& .InfoLectura:after':{
            content:'""',
            height:'15px',
            width:'15px',
            borderTop:'2px solid #20A698',
            borderLeft:'2px solid #20A698',
            position:'absolute',
            bottom:'-10px',
            left:'Calc(50% - 8px)',
            transform: 'rotate(0.625turn)',
        },
        '& .Sesion':{
            borderLeft:'2px solid #D8D8D8',
        },
        '& .Dato':{
            margin:'0 0 5px 0',
            textAlign:'center',
            font:'bold 17px lato',
            color:'#20A698',
        },
        '& .Caracter':{
            margin:'0',
            textAlign:'center',
            font:'bold 17px lato',
            color:'#000000',
        },
    },
    ContenedorLectura:{
        background:'#fff',
        width:'1160px',
        height:'100%',
        padding:'57px 168px 35px' ,
        [theme.breakpoints.down(1161)]:{
            width:'502px',
            padding: '57px 0 0 0',
        },
        [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
        '& .ContainerDerechos':{
            bottom:'80px',
            [theme.breakpoints.down(1161)]:{
                display:'none',
            }
        },
        '& .Derechos':{
            textAlign:"center",
            margin:"0px",
            font:" 15px lato",
            color:"#717476",
        },
        '& .Escritorio':{
            display:'block',
            [theme.breakpoints.down(1161)]:{
                display:'none'
            },
        },
        '& .Tablet':{
            display:'none',
            [theme.breakpoints.down(1161)]:{
                display:'block'
            },
        },
    },
    ContainerTitulo:{
        margin:'0 0 45px 0',
        [theme.breakpoints.down(1161)]:{
            margin:'0 0 38px 0',
        },
        '& .Titulo':{
            font:'48px fredoka one',
            letterSpacing: '0px',
            color: '#FF48E5',
            margin:'0',
            textAlign:'center',
            [theme.breakpoints.down(1161)]:{
                font:'43px fredoka one',
                lineHeight:'52px',
                textAlign:'center',
            },
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            lineHeight : '30px',
            margin:'0 0 36px 0 ',
            font:'22px lato',
            color:'#000',
            letterSpacing: '0.20px',
            [theme.breakpoints.down(1161)]:{
                font:'18px lato',
                letterSpacing:'.17px',
                lineHeight:'28px',
            },
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .SubTitulo':{
            margin:'0',
            font:'bold 30px lato',
            color:'#7940DA',
            letterSpacing:'.28px',
            lineHeight:'30px',
            [theme.breakpoints.down(1161)]:{
                font:'bold 26px lato',
                letterSpacing:'.24px',
                lineHeight:'28px',
            },
            [theme.breakpoints.down(502)]:{
                font:'bold 22px lato',
                letterSpacing:'.24px',
                lineHeight:'22px',
            },
        },
        '& .Imagen':{
            [theme.breakpoints.down(1161)]:{
                width:'100%',
            },
            
        }

    },

}));

const Los_nuevos_materiales_el_grafeno = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
             <Grid container item justifyContent="center" className={classes.ContainerInfoLectura}>
               <Grid container className="BarraInfoLectura"></Grid>
               <Grid container item className="InfoLectura">
                    <Grid container item direction="column" xs={6} justifyContent="center">
                        <p className="Dato">Grado</p>
                        <p className="Caracter">6</p>
                    </Grid>
                    <Grid container item direction="column" xs={6} justifyContent="center" className="Sesion">
                        <p className="Dato">Sesión</p>
                        <p className="Caracter">50</p>
                    </Grid>
               </Grid>
            </Grid>

            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >
                        Los nuevos materiales: el grafeno
                    </h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                            El planeta Tierra posee una riqueza de recursos naturales, aunque muchos de ellos se 
                            están agotando debido a que la humanidad los ha explotado de manera 
                            irresponsable. Por ello, durante las últimas décadas se ha impulsado el 
                            reciclaje y el consumo responsable como medios para frenar la 
                            extinción de los recursos y la creciente contaminación. <br/><br/>
                            
                            Aunque la toma de conciencia sobre esta problemática es importante para generar 
                            cambios, no es suficiente. Por tal motivo, paulatinamente la industria y la comunidad 
                            científica han buscado las maneras de utilizar nuevos procesos y materiales para 
                            generar bienes y servicios de manera más rápida, económica y amigable con el 
                            ambiente. <br/><br/>
                            
                            <h3 className='SubTitulo'>Los nuevos materiales</h3><br/>
                            
                            En la búsqueda de soluciones contra la sobreexplotación de recursos, no todos los 
                            descubrimientos se han dado gracias a que se estuviera buscando un resultado 
                            específico. Hay algunos que sucedieron por casualidad. Ése es el caso del grafeno. <br/><br/>

                            Para entender qué es el grafeno y cómo se descubrió, es importante que primero 
                            conozcas qué es el carbono. Se trata de un elemento químico que está presente en el 
                            universo. En la Tierra, se encuentra en todos los seres vivos, es decir, tú estás 
                            formado por carbono. Este elemento también se encuentra en forma de gas en el 
                            dióxido de carbono, indispensable para la fotosíntesis de las plantas. También está en 
                            el carbón que se utiliza para calentar e incluso en los diamantes. Como puedes ver, es 
                            abundante. <br/><br/>
                            
                            Hay un lugar más donde está el carbono. Es probable que ahora mismo lo tengas muy 
                            cerca o incluso lo estés sosteniendo con tu mano: en tu lápiz. Las puntas o minas con 
                            las que escribes están hechas de grafito, el cual es una forma del carbono. Quizá 
                            ahora encuentres la similitud entre las palabras grafito y grafeno. En conclusión, el 
                            grafeno es una forma de carbono.
                        </p>
                    </Grid>
                    <Grid container >
                            <img className='Imagen' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion50/1.png"  alt="Tarjeta" />
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                            El <em>grafeno</em> es una sustancia que fue descubierta accidentalmente hace relativamente 
                            poco, en 2004. En la Universidad de Manchester de Inglaterra, los físicos rusos
                            Andrey K. Geim y Kosya S. Novoselov colocaron cinta adhesiva sobre grafito y 
                            casualmente decidieron investigar lo que quedaba adherido. El resultado fue el 
                            descubrimiento del grafeno. Fue de tal magnitud el hallazgo, que los hizo 
                            merecedores del Premio Nobel de Física en 2010. <br/><br/>
                            
                            <h3 className='SubTitulo'>Propiedades insólitas del grafeno</h3><br/>
                        
                            Las propiedades de este material son únicas y muy apreciadas en muchas áreas como 
                            la electrónica, la informática y la medicina. Ejemplo de ello es la velocidad con la que 
                            transmite la electricidad, pues la conduce 200 veces mejor que el cobre y la plata, 
                            metales que actualmente son altamente utilizados. Una batería fabricada con 
                            grafeno podría acumular energía durante cien horas con una vida útil de 20 años. ¿Te 
                            imaginas todos los ahorros que esto supondría? <br/><br/>
                            
                            En el caso de la informática debes saber que los equipos funcionan con transistores. 
                            Como lo dice su nombre, su función es transmitir la corriente eléctrica necesaria
                            para que funcionen. Actualmente, se fabrican con silicio, pero si se hicieran con 
                            grafeno, las computadoras serían mucho más rápidas y eficientes. Lo cual sí es 
                            posible. <br/><br/>
                            
                            Su contribución en el área de la informática y la electrónica no se detiene ahí, pues el 
                            grafeno es sumamente resistente a la ruptura, aunque también flexible. Si se 
                            aprovecha esa propiedad, serviría para hacer pantallas de dispositivos móviles que 
                            se pudieran doblar sin romperse fácilmente o incluso autorrepararse en caso de 
                            quebrarse.
                        </p>
                    </Grid>
                    <Grid container  sm={12}>
                            <Grid container>
                                <img className='Imagen' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion50/2.png"  alt="Tarjeta" />
                            </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                            En el campo de la medicina, también sirve para fabricar medicamentos útiles en la 
                            lucha contra el cáncer, así como en la construcción de aparatos para la detección de 
                            enfermedades. Además, es bactericida, es decir que elimina bacterias. <br/><br/>
                            
                            <h3 className='SubTitulo'>Seguro para el ambiente</h3><br/>
                            
                            Sumado a sus múltiples atributos, el grafeno es bastante amigable con el ambiente. 
                            Por ello, es una alternativa prometedora para remplazar el uso de metales no 
                            renovables, que además contaminan cuando son desechados, como los encontrados 
                            en las baterías, transistores y otros componentes que viste anteriormente. <br/><br/>
                            
                            Nuevos materiales como éste prometen solucionar varios de los problemas que 
                            existen por falta de disponibilidad o agotamiento de los recursos. Lo que queda por 
                            hacer es apoyar las investigaciones para descubrir nuevas aplicaciones eficientes y 
                            de bajo costo, pues, como ya viste, el carbono está en todas partes.
                        </p>
                    </Grid>
                </Grid>

                <Grid container item xs={12} justifyContent="center" className="ContainerDerechos">
                    <p className="Derechos">
                        © 2022. Todos los derechos reservados, Pearson, Circuito de lectores.
                    </p>                
                </Grid>
        </Grid>
    </Grid>
    );
}
 
export default Los_nuevos_materiales_el_grafeno;
