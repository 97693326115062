import React               from "react";
import {Grid, makeStyles}                 from '@material-ui/core';

const useStyles = makeStyles(theme =>({
  questionContainer:{
    width: "100%",
    height:"100%",
  },
  reading: {
    width: "80%",
    margin:"10px 0 10px 0 ",
    font: '22px lato',
    letterSpacing: '0.21px',
    [theme.breakpoints.down(1400)]:{
      font: " 18px lato",
      width: "95%",
    },
    [theme.breakpoints.down(1310)]:{
      font: "500 16px lato",

    },
    [theme.breakpoints.down(925)]:{
      font: "500 14px lato",

    },
    [theme.breakpoints.down(426)]:{
      font: "500 12px lato",
 
    }, 
  },
  table: {
    width: "100%",
    font: '22px lato',
    border: '1px solid black',
    [theme.breakpoints.down(1400)]:{
      font: " 18px lato",
      width: "95%",
    },
    [theme.breakpoints.down(1310)]:{
      font: "500 16px lato",
 
    },
    [theme.breakpoints.down(925)]:{
      font: "500 14px lato",

    },
    [theme.breakpoints.down(426)]:{
      font: "500 12px lato",

    }, 
  },
  tdTable: {
    padding: "0 1%",
    border: '1px solid black',
    textAlign: 'center',
  }
}));

const QuestionTable = (props) => {
  const classes = useStyles()

  return(
    <Grid container className={classes.questionContainer}>
      <p
        className={classes.reading}
      >
        7. El narrador relaciona lo que le sucede con algo de su imaginación. 
        Relaciona esas ideas principales con su correspondiente idea secundaria. 
      </p>
      <br/>
      <table className={classes.table}>
        <thead>
          <th style={{width: "50%",    border: '1px solid black',}}><b>Idea principal</b></th>
          <th style={{width: "50%",    border: '1px solid black',}}><b>Idea secundaria</b></th>
        </thead>
        <tbody>
          <tr>
            <td className={classes.tdTable}>
              <p>
              El humo de la quema
              </p>
            </td>
            <td className={classes.tdTable}>
              <p>
                a. Un tren
              </p>
            </td>
          </tr>
          <tr>
            <td className={classes.tdTable}>
              <p>
              Las hojas de la caña
              </p>
            </td>
            <td className={classes.tdTable}>
              <p>
              b. Medias lunas
              </p>
            </td>
          </tr>
          <tr>
            <td className={classes.tdTable}>
              <p>
              La arena del mar
              </p>
            </td>
            <td className={classes.tdTable}>
              <p>
              c. Una cama 
              </p>
            </td>
          </tr>
          <tr>
            <td className={classes.tdTable}>
              <p>
              Las sonrisas
              </p>
            </td>
            <td className={classes.tdTable}>
              <p>
              d. Azúcar
              </p>
            </td>
          </tr>
        </tbody>
      </table>
    </Grid>
  )
}

export default QuestionTable;
