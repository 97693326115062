import React, { useContext, useEffect, useState } from "react";
import { Button, makeStyles, Grid } from "@material-ui/core";
import { Link, useHistory } from "react-router-dom";
import UsabilityContext from "../../../usability/UsabilityContext";
import AuthContext from "../../../auth/AuthContext";
import axios from "../../../services/axios";

const useStyles = makeStyles((theme) => ({
  evaluationsContainer: {
    height: "100%",
  },
  ContainerIzquierdo: {
    position: "relative",
    width: "97%",
    [theme.breakpoints.down(1161)]: {
      width: "100%",
    },
  },

  ContainerCarta: {
    width: "100vw",
    height: "calc(100vh - 79.5px - 79.5px)",
    overflow: "auto",
    background:
      "transparent linear-gradient(107deg, #F2E4E49B 0%, #FFFFFF 100%) 0% 0% no-repeat padding-box",
    "& .DivTexto": {
      [theme.breakpoints.down(1280)]: {
        boxSizing: "border-box",
        padding: "40px 0 0 0",
      },
    },
    "& .Texto": {
      font: "22px lato",
      lineHeight: "28px",
      letterSpacing: ".21px",
      color: "#4B4A4E",
      textAlign: "center",
      margin: "0",
      width: "545px",
      [theme.breakpoints.down(460)]: {
        font: "18px lato",
        lineHeight: "20px",
        width: "350px",
      },
    },
    "& .Titulo": {
      font: "45px Fredoka One",
      lineHeight: "55px",
      color: "#DA5882",
      margin: "0",
      [theme.breakpoints.down(460)]: {
        font: "40px Fredoka One",
        lineHeight: "50px",
      },
    },
    "& .SubTitulo": {
      font: "38px Fredoka One",
      lineHeight: "46px",
      color: "#36B1B7",
      margin: "0",
      [theme.breakpoints.down(460)]: {
        font: "33px Fredoka One",
        lineHeight: "41px",
      },
    },
    "& .DivCarta": {
      [theme.breakpoints.down(1280)]: {
        boxSizing: "border-box",
        padding: "40px 0 ",
      },
    },
    "& .Carta": {
      margin: "15px 0",
      width: "460px",
      height: "625px",
      background: "#fff",
      borderRadius: "4%",
      border: "1px solid #707070",
      [theme.breakpoints.down(1440)]: {
        width: "350px",
        height: "515px",
        margin: "50px 0",
      },
      [theme.breakpoints.down(460)]: {
        width: "350px",
        height: "515px",
      },
    },
    "& .Img": {
      width: "100%",
      height: "100%",
    },
  },

  ContainerBarraInferior: {
    position: "relative",
    bottom: "0px",
    background: "#F3CD52",
    height: "79.51px",
    [theme.breakpoints.up(1920)]: {
      height: "8vh",
    },
    [theme.breakpoints.down(1161)]: {
      background:
        "linear-gradient(280deg, rgba(182,79,136,1) 34%, rgba(246,100,75,1) 91%)",
    },
  },

  ContainerBarraDerecha: {
    background:
      "linear-gradient(180deg, rgba(182,79,136,1) 34%, rgba(246,100,75,1) 91%)",
    width: "3%",
    height: "100vh",
    [theme.breakpoints.down(1161)]: {
      display: "none",
    },
  },

  Boton: {
    position: "absolute",
    bottom: "-31px",
  },

  BotonAvanza: {
    position: "absolute",
    bottom: "52px",
    right: "134px",
    textTransform: "none",
    height: "62px",
    width: "279px",
    background: "#7A54BA",
    boxShadow: "0px 3px 6px #00000029",
    borderRadius: "61px",
    color: "#FFFFFF",
    font: "bold 20px lato ",
    "&:hover": {
      background: "#dd78fb",
    },
    [theme.breakpoints.down(1161)]: {
      position: "relative",
      bottom: "32px",
      left: "0",
      right: "0",
    },
  },
  Link: {
    textDecoration: "none",
  },
}));

const CartadeJugador = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const id_actividad = history?.location?.state?.id_actividad;
  const id_actividad_inscripcion =
    history?.location?.state?.id_actividad_inscripcion;

  // const [totalBadges, setTotalBadges] = useState(1)
  const { handleSesionFinalModal, handleLoading } =
    useContext(UsabilityContext);
  const [levelCard, setLevelCard] = useState();
  const [titleCard, setTitleCard] = useState();
  const {
    user: { insignias },
  } = useContext(AuthContext);

  const [lectora, emocional] = insignias;

  useEffect(() => {
    if (!id_actividad) {
      history.push("/error/sesion-bloqueada");
    }
    // const totalBadges = 451
    const totalBadges = Number(lectora?.total + emocional?.total);
    console.log(totalBadges, "totalBadg")
    if (totalBadges < 101) {
      setLevelCard("Principiante");
      setTitleCard("Principiante");
    }
    if (totalBadges > 100 && totalBadges < 211) {
      setLevelCard("Aprendiz");
      setTitleCard("Aprendiz");
    }
    if (totalBadges > 210 && totalBadges < 331) {
      setLevelCard("Experimentado");
      setTitleCard("Experimentado");
    }
    if (totalBadges > 330 && totalBadges < 451) {
      setLevelCard("Experto");
      setTitleCard("Experto");
    }
    if (totalBadges > 450) {
      setLevelCard("Sabio");
      setTitleCard("Sabio");
    }
    console.log(totalBadges);
  }, [emocional, lectora]);

  console.log(levelCard, "level")
  const renderLevelCard = () => {
    switch (levelCard) {
      case "Principiante":
        return (
          <div className="Carta">
            <img
              className="Img"
              src="https://pearson-cdl-contenidos-qa.s3.amazonaws.com/tarjetas/001-Principiante.png"
              alt="principiante"
            />
          </div>
        );
      case "Aprendiz":
        return (
          <div className="Carta">
            <img
              className="Img"
              src="https://pearson-cdl-contenidos-qa.s3.amazonaws.com/tarjetas/002-Aprendiz.png"
              alt="Aprendiz"
            />
          </div>
        );
      case "Experimentado":
        return (
          <div className="Carta">
            <img
              className="Img"
              src="https://pearson-cdl-contenidos-qa.s3.amazonaws.com/tarjetas/003-Experimentado.png"
              alt="Experimentado"
            />
          </div>
        );
      case "Experto":
        return (
          <div className="Carta">
            <img
              className="Img"
              src="https://pearson-cdl-contenidos-qa.s3.amazonaws.com/tarjetas/004-Experto.png"
              alt="Experto"
            />
          </div>
        );
      case "Sabio":
        return (
          <div className="Carta">
            <img
              className="Img"
              src="https://pearson-cdl-contenidos-qa.s3.amazonaws.com/tarjetas/005-Sabio.png"
              alt="Sabio"
            />
          </div>
        );

      default:
        break;
    }
  };

  const confirmeView = () => {
    handleLoading(true);
    const body = {
      id_actividad_inscripcion: id_actividad_inscripcion,
      id_respuesta: 0,
      id_pregunta: 0,
      intento: 1,
      puntos: 0,
      respuesta_usuario: "",
    };
    axios
      .post("/respuesta/actividad/set", body)
      .then((response) => {
        if (response.status === 200) {
        }
      })
      .catch((error) => {
        //  setConfirmationModalOpen(false);
        //  setModalMessageOpen(true);
        //  setConfirmChangesResult("Asegúrese de llenar todos los datos");
      })
      .finally(() => {
        handleLoading(false);
        handleSesionFinalModal();
      });
  };

  return (
    <Grid
      container
      direction="column"
      item
      xs={12}
      className={classes.evaluationsContainer}
    >
      <Grid
        container
        item
        className={classes.ContainerIzquierdo}
        justifyContent="center"
      >
        <Grid container item className={classes.ContainerCarta}>
          <Grid
            container
            item
            md={12}
            lg={6}
            justifyContent="center"
            alignItems="center"
            className="DivTexto"
          >
            <p className="Texto">
              <h3 className="Titulo">¡Felicidades! </h3>
              <br />
              Llegaste al final de esta aventura.
              <br /> ¿Puedes ver cuánto has mejorado en tus competencias
              lectoras y socioemocionales desde que iniciaste? Con el trabajo,
              el esfuerzo y la dedicación que pusiste en cada lección de este
              circuito ganaste la tarjeta de:
              <br />
              <br />
              <h3 className="SubTitulo">{titleCard}</h3>
              <br />
              Ahora cuentas con las herramientas necesarias para emprender un
              nuevo reto. ¡Mucho éxito!
            </p>
          </Grid>
          <Grid
            container
            item
            md={12}
            lg={6}
            justifyContent="center"
            alignContent="center"
            className="DivCarta"
          >
            {/* <div className="Carta">
              <img className="Img" src="https://images-cdl.s3.us-west-1.amazonaws.com/Carta+de+Jugador/1.png" alt="" />
            </div> */}
            {levelCard && renderLevelCard()}
          </Grid>
        </Grid>
        <Grid
          container
          item
          sm={12}
          className={classes.ContainerBarraInferior}
          justifyContent="center"
        >
          <Link className={classes.Link} to="/mapa">
            <Button className={classes.BotonAvanza} onClick={confirmeView}>
              Continúa la aventura
            </Button>
          </Link>
        </Grid>
      </Grid>
      <Grid
        container
        item
        xs={12}
        className={classes.ContainerBarraDerecha}
      ></Grid>
    </Grid>
  );
};

export default CartadeJugador;
