import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
     Contenedor:{
        background:'#E9FBF9',
        width:'100vw',
        overflowX:'clip',
        [theme.breakpoints.down(1161)]:{
            background:'none',
           overflowX:'unset',
        },
        [theme.breakpoints.down(600)]:{
            marginTop: '79.51px',
        }
    },
  ContainerInfoLectura: {
    position: "relative",
    height: "86px",
    [theme.breakpoints.down(502)]:{
        height: "43px",
    },
    background: "#76D4C3",
    "& .BarraInfoLectura": {
      height: "86px",
      background: `Url(https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Barras/Azul.png)`,
        [theme.breakpoints.down(502)]:{
            height: "43px",
        },
    },
    "& .InfoLectura": {
      position: "absolute",
      height: "86px",
      width: "219px",
      boxSizing: "border-box",
      padding: "17.2px 0",
      background: "#FAFAFA",
      borderRadius: "50px",
      boxShadow: "2px 2px 2px 2px #00000029",
      zIndex: "4",
        [theme.breakpoints.down(502)]:{
            height: "43px",
            width: "109px",
            padding: "8.6px 0",
            borderRadius: "25px",
        },
    },
    "& .InfoLectura:before": {
      position: "absolute",
      display: "flex",
      boxSizing: "border-box",
      padding: "0 0 0 10px",
      justifyContent: "center",
      alignItems: "center",
      height: "39.88px",
      width: "39.88px",
      borderRadius: "50%",
      content: '""',
      boxShadow: "3px 0px .5px 0px #00000029",
      background: "#FAFAFA",
      left: "90px",
      top: "65px",
      transform: "rotate(0.25turn)",
      [theme.breakpoints.down(502)]:{
        left: "45px",
        top: "30px",
        height: "20.88px",
        width: "20.88px",
        },
    },
    "& .InfoLectura:after": {
      content: '""',
      height: "15px",
      width: "15px",
      borderTop: "2px solid #20A698",
      borderLeft: "2px solid #20A698",
      position: "absolute",
      bottom: "-10px",
      left: "Calc(50% - 8px)",
      transform: "rotate(0.625turn)",
      [theme.breakpoints.down(502)]:{
        height: "10px",
        width: "10px",
        left: "Calc(50% - 4.5px)",
        bottom: "-3px",
        borderTop: "1px solid #20A698",
        borderLeft: "1px solid #20A698",
        },
    },
    "& .Sesion": {
      borderLeft: "2px solid #D8D8D8",
    },
    "& .Dato": {
      margin: "0 0 5px 0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#20A698",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        margin: "0 0 2.5px 0",
        },
    },
    "& .Caracter": {
      margin: "0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#000000",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        },
    },   },
    ContenedorLectura:{
        background:'#fff',
        width:'1160px',
        height:'100%',
        padding:'60px 168px 35px ',
        [theme.breakpoints.down(1161)]:{
            width:'502px',
            padding:'60px 0 0 0',
          },
        [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
        '& .ContainerDerechos':{
            bottom:'80px',
            [theme.breakpoints.down(1161)]:{
                display:'none',
            },
        },
        '& .Derechos':{
            textAlign:"center",
            margin:"0px",
            font:" 15px lato",
            color:"#717476",
        },
        '& .Escritorio':{
            display:'block',
            [theme.breakpoints.down(1161)]:{
                display:'none'
            },
        },
        '& .Tablet':{
            display:'none',
            [theme.breakpoints.down(1161)]:{
                display:'block'
            },
        }
    },
    ContainerTitulo:{
        margin:'0 0 34px 0',
        [theme.breakpoints.down(1161)]:{
            margin:'0 0 34px 0',
        },
        '& .Titulo':{
            font:'48px fredoka one',
            letterSpacing: '0px',
            lineHeight:'58px',
            color: '#00ACCE',
            margin:'0',
            textAlign:'center',
            [theme.breakpoints.down(1161)]:{
                font:'43px fredoka one',
                lineHeight:'52px',
            },
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            lineHeight : '30px',
            margin:'0 0 36px 0',
            font:'22px lato',
            color:'#000',
            letterSpacing: '0.20px',
            [theme.breakpoints.down(1161)]:{
                font:'18px lato',
                lineHeight :'28px',
                letterSpacing: '0.17px',
            },
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .Imagen':{
            [theme.breakpoints.down(1161)]:{
                width:'100%',
            },
        },  
      
    }
}));

const Bajo_la_manga = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
             <Grid container item justifyContent="center" className={classes.ContainerInfoLectura}>
               <Grid container className="BarraInfoLectura"></Grid>
               <Grid container item className="InfoLectura">
                    <Grid container item direction="column" xs={6} justifyContent="center">
                        <p className="Dato">Grado</p>
                        <p className="Caracter">5</p>
                    </Grid>
                    <Grid container item direction="column" xs={6} justifyContent="center" className="Sesion">
                        <p className="Dato">Sesión</p>
                        <p className="Caracter">19</p>
                    </Grid>
               </Grid>
            </Grid>

            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                        <h2 className="Titulo">Bajo la manga</h2>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container >
                        <p className='Lectura'>
                            Los padres de Ifigenia pensaron que era buena idea que aceptara la oportunidad y se 
                            cambiara de escuela. Ella misma quería pensar así. Un colegio particular la aceptaría 
                            becada por su promedio perfecto. Antes de que empezaran las clases Ifigenia se 
                            entretenía imaginando que allí podría aprender idiomas, tomar cursos de programación, 
                            talleres de danza y clases de natación. Sin embargo, desde su primer día presintió que 
                            las cosas no irían bien. <br/><br/>

                            Sus compañeros ya se conocían. Ifigenia podía distinguir los grupos de amigos y en ninguno 
                            parecía haber lugar para nadie más. No sabía cómo acercarse a ellos. Cada vez que lo 
                            intentaba había al menos una persona que le sonreía amablemente, pero cuando la conversación 
                            se transformaba en juegos y risas, ella siempre quedaba fuera. <br/><br/>
                            
                            Con el paso de los días comenzó a platicar con algunos compañeros. Varias veces 
                            sintió que las cosas mejoraban. Pero justo cuando creía poder hacer un amigo, 
                            escuchaba la frase: <br/><br/>
                            
                            “Nuestros papás nos llevarán a la plaza el viernes. Dame tu número y te aviso dónde nos vemos.”
                        </p>
                    </Grid>
                    <Grid container justifyContent='center' >
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion19/1.png" alt="Tarjeta" />
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container >
                        <p className='Lectura'>
                            Ifigenia no sabía qué decir. Aunque le hubiera gustado ir a la plaza, había muchas 
                            cosas que lo hacían imposible. Su mamá no la dejaría ir sola y nadie la llevaría porque 
                            sus papás trabajaban hasta tarde. <br/><br/>
                            
                            Aun si le dieran permiso, no le darían dinero. Podía imaginarse a los demás 
                            comprando helado o entrando al cine. Escuchaba en su cabeza el tintineo de las
                            monedas en los bolsillos, pasando de mano en mano y cayendo en las rendijas de las 
                            máquinas expendedoras. ¿Qué podía hacer ella sin dinero en un lugar como ése?<br/><br/>

                            Además, ni siquiera tenía celular. No tenía sentido pedir uno a sus padres: le 
                            hubieran dado uno que serviría solamente para hacer llamadas. Se imaginaba la cara 
                            que pondrían sus compañeros cuando ella sacara de su mochila un aparato que 
                            parecería salido de otra década. <br/><br/>
                            
                            Por eso cada vez que la invitaban contestaba cabizbaja que su mamá no la dejaba 
                            tener celular ni salir. Los demás la miraban con lástima un momento y seguían sus
                            planes. <br/><br/>
                            
                            Pasado un tiempo, ella sabía que todos la veían como “la pobre”. Constantemente se 
                            decía que ser pobre no era malo, pero le incomodaba sentirse inferior. Por años, 
                            Ifigenia había sido el primer lugar en todo. Tenía las mejores calificaciones. Era la que 
                            más participaba en actividades escolares. Incluso se sentía orgullosa de sus padres, 
                            que tenían mayores grados de estudio que los de sus amigos. Casi todo eso se había 
                            evaporado en la escuela nueva. Sin embargo, en algo seguía siendo la mejor: sus 
                            calificaciones, que ella guardaba en secreto frente a sus compañeros, eran perfectas.
                            Ahora que nadie le hablaba, leía y estudiaba todo su tiempo libre. Ése era su as bajo la
                            manga. Ya encontraría el momento de jugarlo. <br/><br/>
                            
                            Un día la seleccionaron para ir a los concursos de conocimiento interescolares, que 
                            eran por equipos. La maestra agendó tardes de estudio para que el equipo estudiara 
                            los temas y se organizara para participar. Al principio, Ifigenia tenía la mejor 
                            disposición y creyó que con esto por fin podría hacer amigos. Se equivocó. Ellos 
                            casi no le hablaban. Se ponían de acuerdo sin preguntarle y no la tomaban en cuenta. 
                            Cuando estudiaban, Ifigenia contestaba todas las preguntas en su mente. Aunque podía 
                            responder mejor sobre cualquier tema, le enfurecía que nadie quisiera escucharla. Por 
                            eso se guardaba las respuestas.
                        </p>
                    </Grid>
                    <Grid container justifyContent='center' >
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion19/2.png" alt="Tarjeta" />
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container >
                        <p className='Lectura'>
                            Durante la competencia, Ifigenia ocupó un lugar detrás del equipo, desde donde miraba las espaldas 
                            de sus compañeros. Escuchó la primera pregunta y la respuesta que dieron. Error. La segunda. 
                            Error. Tercera, cuarta y quinta. Errores todos. A la sexta, ella gritó fuerte la respuesta. 
                            Era correcta. El grito cumplió su cometido: se escuchó por encima de la voz de sus 
                            compañeros. Los jueces creyeron que había tenido que gritar por estar atrás, así que 
                            le pidieron que pasara adelante. Ifigenia siguió contestando. Conforme ella avanzaba, 
                            sus compañeros retrocedían.
                        </p>
                    </Grid>
                </Grid>

            <Grid container item xs={12} justifyContent="center" className="ContainerDerechos">
                <p className="Derechos">
                    © 2022. Todos los derechos reservados, Pearson, Circuito de lectores.
                </p>                
            </Grid>
        </Grid>
    </Grid>
    );
}
 
export default Bajo_la_manga;
