import React from "react";
import ProtectedRoute from "./ProtectedRoute";

import PerfilAdministrador from "../pages/Admin_Institucional/Perfil";
import WelcomeAdmin from "../pages/Admin_Institucional/WelcomeAdmin";
import UsersManual from "../pages/Admin_Institucional/manual_de_usuario/UsersManual";

import ReportesAdmin from "../pages/Admin_Institucional/ReporteAlumnos";
import Reporte1 from "../pages/Admin_Institucional/Reportes/Reporte_1";
import Reporte2 from "../pages/Admin_Institucional/Reportes/Reporte_2";
import Reporte4 from "../pages/Admin_Institucional/Reportes/Reporte_4";
import Reporte5 from "../pages/Admin_Institucional/Reportes/Reporte_5";
import Reporte6 from "../pages/Admin_Institucional/Reportes/Reporte_6";
import Reporte8 from "../pages/Admin_Institucional/Reportes/Reporte_8";
import GroupsInfo from "../pages/Admin_Institucional/grupos/GroupsInfo";
import UsersGroupsInfo from "../pages/Admin_Institucional/grupos/usuarios/UsersInfo";
import UsersInfo from "../pages/Admin_Institucional/usuarios/UsersInfo";

import CargarDatos from "../pages/Admin_Institucional/CargarDatos/CargarDatos";
import EditTeacher from "../pages/Admin_Institucional/usuarios/EditTeacher";
import EditStudent from "../pages/Admin_Institucional/usuarios/EditStudent";

const routes = [
  <ProtectedRoute
    exact
    path="/admin-inst/perfil"
    component={PerfilAdministrador}
  />,
  <ProtectedRoute exact path="/admin-inst/welcome" component={WelcomeAdmin} />,
  <ProtectedRoute
    exact
    path="/admin-inst/manual_de_usuario"
    component={UsersManual}
  />,
  <ProtectedRoute
    exact
    path="/admin-inst/cargar_datos"
    component={CargarDatos}
  />,

  <ProtectedRoute exact path="/admin-inst/grupos/:id" component={GroupsInfo} />,
  <ProtectedRoute
    path="/admin-inst/grupo/:id/usuarios"
    component={UsersGroupsInfo}
  />,
  <ProtectedRoute
    exact
    path="/admin-inst/usuarios/:id"
    component={UsersInfo}
  />,
  <ProtectedRoute
    exact
    path="/admin-inst/usuarios/docente/editar/:id_usuario"
    component={EditTeacher}
  />,
  <ProtectedRoute
    exact
    path="/admin-inst/usuarios/alumno/editar/:id_usuario"
    component={EditStudent}
  />,

  <ProtectedRoute
    exact
    path="/admin-inst/reportesAdmin"
    component={ReportesAdmin}
  />,
  <ProtectedRoute
    exact
    path="/admin-inst/reportes"
    component={ReportesAdmin}
  />,
  <ProtectedRoute exact path="/reportes/1" component={Reporte1} />,
  <ProtectedRoute exact path="/reportes/2" component={Reporte2} />,
  <ProtectedRoute exact path="/reportes/4" component={Reporte4} />,
  <ProtectedRoute exact path="/reportes/5" component={Reporte5} />,
  <ProtectedRoute exact path="/reportes/6" component={Reporte6} />,
  <ProtectedRoute exact path="/reportes/8" component={Reporte8} />,
];

const AdminInstituto = routes.map((route, key) => {
  return { ...route, key: key };
});

export default AdminInstituto;
