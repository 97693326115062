import { makeStyles, Grid, Button } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import axios from "../../../services/axios";
import UsabilityContext from "../../../usability/UsabilityContext";

import ModalRegistroExitoso from "./ModalRegistroExitoso";

const useStyles = makeStyles((theme) => ({
  formContainer: {
    // display: "flex",
    display: "grid",
    // flexDirection: "column"
    gridTemplateColumns: "1fr",
    width: "80%",
    boxSizing: "border-box",
    maxWidth: "560px",
    alignItems: "flex-start",
    justifyItems: "center",
    gap: "30px",

    [theme.breakpoints.up("lg")]: {
      width: "100%",
      maxWidth: "680px",
      alignItems: "end",
      gridTemplateColumns: "1fr 1fr",
    },
  },
  formItemContainer: {
    gap: "10px",
  },
  input: {
    width: "100%",
    height: "40px",
    paddingLeft: "27px",
    paddingRight: "27px",
    borderRadius: "61px",
    boxSizing: "border-box",
    // border: " solid #1D2AAD 3px ",
    border: "none",
    background: "#FFFFFF",
    font: "Helvetica",
    fontSize: "16px",
    //    width: '449px',
    // height: '72px',
    // margin: '43px 139px 19px 66px',
    // padding: '26px 24px 25px',
    backgroundColor: "#f0f0f0",
    "&:focus": {
      outline: "none",
    },
    [theme.breakpoints.up("lg")]: {
      // height: "60px",
      // maxWidth: "449px",
      fontSize: "18px",
    },
  },
  label: {
    fontWeight: "bold",
    fontFamily: "Lato, sans-serif",
  },
  PurpleButton: {
    color: "#FFFFFF",
    backgroundColor: "#9051b1",
    margin: "0 auto",
    borderRadius: "30px",
    alignSelf: "center",
    width: "100%",
    maxWidth: "238px",
    minHeight: "45px",
    border: "none",
    textTransform: "unset",
    cursor: "pointer",
    fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
    fontWeight: "500",
    padding: "6px 16px",
    lineHeight: "1.75",
    transition:
      "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    boxShadow:
      "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
    "&:hover": {
      backgroundColor: "#B965D3 ",
    },
  },
}));

const DocenteEspecifico = ({ data }) => {
  const classes = useStyles();
  const [formData, setFormData] = useState({
    escuela: data?.nombreEscuela,
    id_escuela: data?.id_escuela,
    grado: data?.id_grado,
    grupo: data?.nombreGrupo,
    id_grupo: data?.id_grupo,
  });

  const [readyToRegister, setReadyToRegister] = useState(true);
  const [responseRegister, setResponseRegister] = useState({});

  const { handleLoading, handleModalMessage } = useContext(UsabilityContext);

  const [registerCompletedModalOpen, setRegisterCompletedModalOpen] =
    useState(false);

  const handleCloseRegisterCompleted = () => {
    setRegisterCompletedModalOpen(!registerCompletedModalOpen);
  };

  useEffect(() => {
    if (
      formData?.nombre &&
      formData?.primer_apellido &&
      formData?.email &&
      formData?.email_confirmacion &&
      formData?.email === formData?.email_confirmacion
    ) {
      setReadyToRegister(false);
    } else {
      setReadyToRegister(true);
    }
  }, [formData]);

  const handleForm = (e) => {
    setFormData({
      ...formData,
      [e.target.name.toLowerCase()]: e.target.value,
    });
  };

  const registerTeacher = () => {
    handleLoading(true);
    const body = {
      nombre: formData?.nombre,
      apellido1: formData?.primer_apellido,
      apellido2: formData?.segundo_apellido,
      correo: formData?.email,
      codigo: data?.codigo,
      id_tipo_uso: data?.id_tipo_uso,
      asignacion: {
        id_grupo: data?.id_grupo,
        id_escuela: data?.id_escuela,
        id_grado: data?.id_grado,
      },
    };
    axios
      .post("/user/codes/register", body)
      .then((response) => {
        if (response.status === 200) {
          const data = response.data.resultado.usuario;
          setResponseRegister(data);
          setRegisterCompletedModalOpen(true);
          setFormData({
            ...formData,
            nombre: "",
            primer_apellido: "",
            segundo_apellido: "",
            email: "",
            email_confirmacion: "",
          });
        }
      })
      .catch((error) => {
        if (error.response) {
          // Request made and server responded
          const errorData = error.response.data;
          const mensaje =
            errorData?.detalles?.msg ||
            "Ha ocurrido un error al consumir los servicios, inténtelo de nuevo más tarde.";
          handleModalMessage({
            habilite: true,
            mensaje: mensaje,
          });
        } else {
          // Something happened in setting up the request that triggered an Error
          const mensaje =
            "Ha ocurrido un error al consumir los servicios, inténtelo de nuevo más tarde.";
          handleModalMessage({
            habilite: true,
            mensaje: mensaje,
          });
        }
      })
      .finally(() => {
        handleLoading(false);
      });
  };

  return (
    <>
      <form
        className={classes.formContainer}
        autoComplete="off"
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <Grid
          container
          direction="column"
          className={classes.formItemContainer}
        >
          <label className={classes.label}>Datos de la institución</label>
        </Grid>
        <br />
        <Grid
          container
          direction="column"
          className={classes.formItemContainer}
        >
          <input
            type="text"
            className={classes.input}
            id="input-nombre-institucion"
            name="nombre_Instituto"
            placeholder="Nombre(s) de la institución"
            value={formData?.escuela}
            // onChange={handleForm}
            disabled
          />
        </Grid>
        <br />
        <Grid
          container
          direction="column"
          className={classes.formItemContainer}
        >
          <label className={classes.label} htmlFor="input-grade">
            Grado
          </label>
          <input
            type="text"
            className={classes.input}
            id="input-grade"
            name="grado"
            value={formData?.grado}
            disabled
          />
        </Grid>
        <Grid
          container
          direction="column"
          className={classes.formItemContainer}
        >
          <label className={classes.label} htmlFor="input-group">
            Grupo
          </label>
          <input
            type="text"
            className={classes.input}
            id="input-group"
            name="grupo"
            value={formData?.grupo}
            onChange={handleForm}
            disabled
          />
        </Grid>
        <Grid
          container
          direction="column"
          className={classes.formItemContainer}
        >
          <label className={classes.label}>Datos del docente</label>
        </Grid>
        <br />
        <Grid
          container
          direction="column"
          className={classes.formItemContainer}
        >
          <input
            type="text"
            className={classes.input}
            id="input-nombre-docente"
            name="nombre"
            placeholder="Nombre(s)"
            value={formData.nombre}
            onChange={handleForm}
            required
          />
        </Grid>
        <br />
        <Grid
          container
          direction="column"
          className={classes.formItemContainer}
        >
          <input
            type="text"
            className={classes.input}
            id="input-first-name"
            placeholder="Primer apellido"
            name="primer_apellido"
            value={formData.primer_apellido}
            onChange={handleForm}
            required
          />
        </Grid>

        <Grid
          container
          direction="column"
          className={classes.formItemContainer}
        >
          <input
            type="text"
            className={classes.input}
            id="input-second-first-name"
            name="segundo_apellido"
            placeholder="Segundo apellido"
            value={formData.segundo_apellido}
            onChange={handleForm}
            required
          />
        </Grid>

        <Grid
          container
          direction="column"
          className={classes.formItemContainer}
        >
          <input
            type="email"
            className={classes.input}
            id="input-email"
            name="email"
            placeholder="Correo"
            value={formData.email}
            onChange={handleForm}
            required
          />
        </Grid>
        <Grid
          container
          direction="column"
          className={classes.formItemContainer}
        >
          <input
            type="email"
            className={classes.input}
            id="input-email-confirmation"
            name="email_confirmacion"
            placeholder="Confirmar correo"
            value={formData?.email_confirmacion}
            onChange={handleForm}
            required
          />
        </Grid>
      </form>
      <Button
        className={classes.PurpleButton}
        onClick={registerTeacher}
        disabled={readyToRegister}
      >
        Acceder
      </Button>
      <ModalRegistroExitoso
        open={registerCompletedModalOpen}
        handleClose={handleCloseRegisterCompleted}
        data={responseRegister}
      />
    </>
  );
};

export default DocenteEspecifico;
