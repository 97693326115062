import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
    ContenedorLectura:{
        width:'502px',
                  [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
        '& .Escritorio':{
            display:'block',
            [theme.breakpoints.down(1161)]:{
                display:'none'
            },
        },
        '& .Tablet':{
            display:'none',
            [theme.breakpoints.down(1161)]:{
                display:'block'
            },
            [theme.breakpoints.down(502)]:{
                display:'none'
            },
        }
    },
    ContainerTitulo:{
        margin:'0 0 46px 0',
        '& .Titulo':{
            letterSpacing: '0px',
            color: '#0CB5D6',
            margin:'0',
            textAlign:'center',
            font:'43px fredoka one',
            lineHeight:'52px',
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },

        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            margin:'0 0 36px 0 ',
            color:'#000',
            font:'18px lato',
            lineHeight :'28px',
            letterSpacing: '0.17px',
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .Imagen':{
            width:'100%',
        },
    },
    

}));

const Mis_vecinos_mutante = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >
                        Mis vecinos mutantes
                    </h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                            Laura no sabe cómo decirle a su mamá que cuando la deja encargada con sus vecinos
                            los González se siente rara, como si estuviera en otro mundo… <br/><br/> 
                            
                            Sospecha que doña Ramona tiene pinzas de cangrejo. Además, le brilla la cara y  
                            Laura no quiere tenerla cerca. Quizás el brillo se debe a su caparazón. Por si fuera  
                            poco, pareciera que doña Ramona habla en idioma crustáceo: dice las cosas  
                            demasiado rápido y Laura no alcanza a entender ni a responder lo que le pregunta. 
                        </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <Grid container >
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img2do/Sesion29/1.png" alt="Tarjeta" />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                            El hermano de doña Ramona, don Federico, es larguirucho como una serpiente de  
                            mar. Sus ojos amenazantes vigilan a Laura todo el tiempo. Ella imagina que se la  
                            tragará de un bocado si se mueve o habla. Por eso prefiere no hacer ruido cuando  
                            está cerca de él. Pero luego le reclaman si no sonríe, si no contesta cuando le hablan  
                            o si no quiere darles un beso cuando llega o se va.
                        </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <Grid container >
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img2do/Sesion29/2.png"  alt="Tarjeta" />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                            Le han dicho a su mamá que Laura es tranquila y callada, pero ella en realidad se 
                            siente incómoda. Piensa que el tiempo allí pasa lento, como en la consulta con el 
                            dentista. Ojalá su mamá no tuviera que irse a trabajar ni la dejara encargada con los 
                            vecinos. <br/><br/>
                            
                            A veces Laura quisiera jugar con las figuras de porcelana que adornan el librero de 
                            los González, pero no se atreve. Sabe que don Federico la observa y recuerda que 
                            doña Ramona le advirtió que no lo hiciera. <br/><br/>
                            
                            Lo único bueno es que en algunas ocasiones le ofrecen galletas con leche. Al principio 
                            les decía “No, gracias” (como le enseñó su mamá), pero luego ya no pudo resistirse. 
                            Las galletas vuelven el tiempo menos largo. Aun así, Laura sospecha que quieren 
                            comérsela. Por eso está a la defensiva, segura de que son monstruos disfrazados y de 
                            que por la noche sacan su verdadera apariencia. <br/><br/>
                            
                            Laura no sabe cómo decírselo a su mamá. Parece que a ella le caen bien y les 
                            agradece por cuidar a su hija. <br/><br/>
                            
                            —¡Vámonos ya, mamá! —susurra Laura cada vez que llega por ella. <br/><br/>
                            
                            No quiere que anochezca y que los González se transformen. Tampoco quiere 
                            perderse su serie favorita en la televisión: <em>Mis vecinos mutantes.</em>
                        </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <Grid container >
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img2do/Sesion29/3.png"  alt="Tarjeta" />
                        </Grid>
                    </Grid>
                </Grid>
        </Grid>
    </Grid>
    );
}
 
export default Mis_vecinos_mutante;
