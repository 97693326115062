import { Button, Grid, makeStyles } from "@material-ui/core";
import React, { useContext, useEffect } from "react";
import DrawerContext from "../../../../../usability/DrawerContext";
import { useOnLoadImages } from "../../../../hooks/useOnLoadImages";
import Loader from "../../../../Loader/Loader";
import Drawer from "../../ReadingTools/Drawer";

const useStyles =  makeStyles (theme =>({
     Contenedor:{
        background:'#E9FBF9',
        width:'100vw',
        overflowX:'clip',
        [theme.breakpoints.down(1161)]:{
            background:'none',
           overflowX:'unset',
        },
        [theme.breakpoints.down(600)]:{
            marginTop: '79.51px',
        }
    },
      ContainerInfoLectura: {
    position: "relative",
    height: "86px",
    [theme.breakpoints.down(502)]:{
        height: "43px",
    },
    background: "#76D4C3",
    "& .BarraInfoLectura": {
      height: "86px",
      background: `Url(https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Barras/Azul.png)`,
        [theme.breakpoints.down(502)]:{
            height: "43px",
        },
    },
    "& .InfoLectura": {
      position: "absolute",
      height: "86px",
      width: "219px",
      boxSizing: "border-box",
      padding: "17.2px 0",
      background: "#FAFAFA",
      borderRadius: "50px",
      boxShadow: "2px 2px 2px 2px #00000029",
      zIndex: "4",
        [theme.breakpoints.down(502)]:{
            height: "43px",
            width: "109px",
            padding: "8.6px 0",
            borderRadius: "25px",
        },
    },
    "& .InfoLectura:before": {
      position: "absolute",
      display: "flex",
      boxSizing: "border-box",
      padding: "0 0 0 10px",
      justifyContent: "center",
      alignItems: "center",
      height: "39.88px",
      width: "39.88px",
      borderRadius: "50%",
      content: '""',
      boxShadow: "3px 0px .5px 0px #00000029",
      background: "#FAFAFA",
      left: "90px",
      top: "65px",
      transform: "rotate(0.25turn)",
      [theme.breakpoints.down(502)]:{
        left: "45px",
        top: "30px",
        height: "20.88px",
        width: "20.88px",
        },
    },
    "& .InfoLectura:after": {
      content: '""',
      height: "15px",
      width: "15px",
      borderTop: "2px solid #20A698",
      borderLeft: "2px solid #20A698",
      position: "absolute",
      bottom: "-10px",
      left: "Calc(50% - 8px)",
      transform: "rotate(0.625turn)",
      [theme.breakpoints.down(502)]:{
        height: "10px",
        width: "10px",
        left: "Calc(50% - 4.5px)",
        bottom: "-3px",
        borderTop: "1px solid #20A698",
        borderLeft: "1px solid #20A698",
        },
    },
    "& .Sesion": {
      borderLeft: "2px solid #D8D8D8",
    },
    "& .Dato": {
      margin: "0 0 5px 0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#20A698",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        margin: "0 0 2.5px 0",
        },
    },
    "& .Caracter": {
      margin: "0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#000000",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        },
    },
       '& .Regresar':{
        color: '#F2624F',
        border: '#F2624F 1px solid',
        height: '30px',
        width:'100px',
        borderRadius: '20px',
        letterSpacing: '0.14px',
        textTransform: 'none',
        background:'#FEFAF1',
        top:'calc(50% - 15px)',
        left:'5%',
        zIndex:4,
        [theme.breakpoints.down(502)]: {
          font:'bold 10px lato',
          width: "60px",
        },
      },
  },
    ContenedorLectura:{
        background:'#fff',
        width:'1160px',
        height:'100%',
        padding:'60px 168px 35px ',
        
        transform: (props) =>
        props.scale > 1 ? `scale(${props.scale}) translate(0px, 10%)` : `none`,
        [theme.breakpoints.down(1161)]:{
            width:'502px',
            padding:'60px 0 0 0',
          },
        [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },

        '& .Escritorio':{
            display:'block',
            [theme.breakpoints.down(1161)]:{
                display:'none'
            },
        },
        '& .Tablet':{
            display:'none',
            [theme.breakpoints.down(1161)]:{
                display:'block'
            },
        }
    },
    ContainerTitulo:{
        margin:'0 0 34px 0',
        [theme.breakpoints.down(1161)]:{
            margin:'0 0 34px 0',
        },
        '& .Titulo':{
            letterSpacing: '0px',
            color: '#007CB2',
            margin:'0',
            textAlign:'center',
            font:'48px fredoka one',
            lineHeight:'58px',
            [theme.breakpoints.down(1161)]:{
                font:'43px fredoka one',
                lineHeight:'52px',
            },
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        position:'relative',
        '& .Lectura':{
            margin:'0 0 36px 0',
            color:'#000',
            font:'22px lato',
            letterSpacing: '0.20px',
            lineHeight : '30px',
            [theme.breakpoints.down(1161)]:{
                font:'18px lato',
                lineHeight :'28px',
                letterSpacing: '0.17px',
            },
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .Esp':{
            margin:'0',
        },
        '& .Esp2':{
            boxSizing:'border-box',
            padding:'0 74px 0 0',
            [theme.breakpoints.down(1161)]:{
                padding:'0 17% 0 0',
            },
        },
        '& .Esp3':{
            boxSizing:'border-box',
            padding:'0 74px 0 0',
        },
        
        '& .Imagen':{
            [theme.breakpoints.down(1161)]:{
                width:'100%',
            },
        }, 
        '& .Imagen2':{
            position:'absolute',
            top: '-194px',
            right: '-26px',
            [theme.breakpoints.down(1161)]:{
                width:'270px',
                top: '0px',
                right: '-26px',
            },
            [theme.breakpoints.down(502)]:{
                width:'200px',
            },
        },
  
    }
}));


const La_ñ_del_español = (props) => {

  const {activeStep, setActiveStep, handlePreviewStep, id_actividad} = props
  
  const handleContinue = () => {
    setActiveStep(activeStep - 1)
    handlePreviewStep()
  }

  const {
    handleSize,
    disabledZoom,
    closeZoom,
    handleZoom,
    scale
  }= useContext(DrawerContext);
  const [loading, setLoading] = React.useState(true);
  const wrapperRef = React.useRef(null);
  const imagesLoaded = useOnLoadImages(wrapperRef);

  const detectSize = () => {
    const lectura = document.getElementById("lectura");
    const newWidth = lectura?.offsetWidth;
    const newHeight = lectura?.offsetHeight;
    setLoading(false);
    if (lectura) handleSize(newHeight, newWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", detectSize);
    return () => {
      handleZoom()
      window.removeEventListener("resize", detectSize);
      closeZoom()
    };
  }, []);

  const classes = useStyles({ disabledZoom,scale });
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
             <Grid container item justifyContent="center" className={classes.ContainerInfoLectura}>
                       <Grid container className="BarraInfoLectura">
            <Button className="Regresar" onClick={handleContinue}>
                Regresar
            </Button>
        </Grid>
               <Grid container item className="InfoLectura">
                    <Grid container item direction="column" xs={6} justifyContent="center">
                        <p className="Dato">Grado</p>
                        <p className="Caracter">4</p>
                    </Grid>
                    <Grid container item direction="column" xs={6} justifyContent="center" className="Sesion">
                        <p className="Dato">Sesión</p>
                        <p className="Caracter">19</p>
                    </Grid>
               </Grid>
            </Grid>
            {!imagesLoaded && <Loader />}
        <Grid
            container
            item
            className={classes.ContenedorLectura}
            justifyContent="center"
            id="lectura"
            onLoad={detectSize}
            ref={wrapperRef}
        >
<Drawer id_lectura={id_actividad} />
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >La <em>ñ</em> del español</h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container sm={12}>
                      <p className="Lectura "> 
                        Mi tío nos visita a mis hermanos y a mí cada año. Al llegar, él sacude nuestro cabello 
                        con su mano. A mi hermano Lorenzo apenas le da unas palmaditas en el pelo, pero al 
                        pequeño Rubén le sonríe y dice: “Este muchacho salió más blanquito”.
                      </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img4to/Sesion19/1.png" alt="Tarjeta" />
                    </Grid>
                </Grid>
               
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container sm={12}>
                      <p className="Lectura Esp"> 
                        Él es cariñoso, pero al mismo tiempo es… no sé cómo. A veces me mira fijamente y 
                        exclama:<br/><br/>
                        
                        —Sí que es negro tu pelo, ¿eh? <br/><br/>
                        
                        Cuando está con mi papá, se acuerda de sus abuelos, que eran españoles de piel 
                        blanca, y se siente orgulloso. <br/><br/>
                        
                        —Te pudieron haber salido más claritos los niños —señala de repente. <br/><br/>
                        
                        Entonces, mi papá hace una mueca y cambia de tema. Es cuando yo comienzo a 
                        preocuparme de lucir color café. <br/><br/>
                        
                        —Mamá, ¿mi piel es de un tono muy oscuro? <br/><br/>
                        
                        —Sí, hijo, como el café negro. <br/><br/>
                        
                        —¡Noooo! <br/><br/>
                        
                        —Bueno, como la canela. <br/><br/>
                        
                        Respondo con un gesto. <br/><br/>
                        
                       —¿El chocolate? ¿La tierra? ¿La montaña? <br className='Tablet'/>—pregunta ella tratando de hacerme reír,
                        pero yo solo le contesto: <br/><br/>
                        
                        —¿No pude haber sido más clarito? <br/><br/>
                        
                        —¿Como la luna, el queso o el papel? ¡¿Como un fantasma?! <br/><br/>
                        
                        —No, como mi papá. Porque mi papá y mi tío son blancos. <br/><br/>
                        
                        —Y yo, negrita, hijo. Así que eres café con leche. <br/><br/>
                        
                        —Si me hubieras echado más leche, sería menos oscuro.<span className='Tablet'><br/></span>

                        <span className='Tablet'>
                            Mi tío chasquea los labios cuando escucha que conversamos en 
                            tu’un savi y nos reclama por hablar en “ese dialecto”. Asegura 
                            que no sirve para nada y que nos impide aprender bien 
                            el español.
                        </span>
                      </p>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} >
                    <Grid container xs={7} >
                      <p className="Lectura Esp2"> 
                        <span className='Escritorio'>
                            —Pero así negro estás guapo. Estás fuerte y 
                            tienes carácter, como el café y el chocolate.<br/><br/>
                            
                            Mi tío chasquea los labios cuando 
                            escucha que conversamos en <em>tu'un savi</em> y 
                            nos reclama por hablar en “ese dialecto”.<br/><br/>

                            Asegura que no sirve para nada y que nos 
                            impide aprender bien el español.<br/><br/>
                        </span>

                         Yo siento feo porque no es un dialecto, es 
                         el idioma en el que me comunico con mi 
                         mamá. Ella respinga la nariz un poco 
                         cuando le platico lo que él comenta. Pero 
                         luego sonríe y me pide que lo ignore.

                         <span className='Tablet'>
                            <br/>
                            —Yo soy morena y mis palabras están en la lengua de los <em>ñuu savi</em>, el pueblo de la 
                            lluvia. El día que pienses que eso es malo, yo no existiré para ti.
                         </span>

                      </p>
                    </Grid>
                    <img className="Imagen2" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img4to/Sesion19/2.png" alt="Tarjeta" />
                    <Grid container xs={12} >
                      <p className="Lectura "> 
                        <span className='Escritorio'>
                            —Yo soy morena y mis palabras están en la lengua de los <em>ñuu savi</em>, el pueblo de la 
                            lluvia. El día que pienses que eso es malo, yo no existiré para ti.<br/><br/> 
                         </span>
                        Una tarde, mi tío comenzó a platicar de su viaje por Europa. Contó que en Francia 
                        tuvo problemas para mandar correos porque los teclados de las computadoras no 
                        tenían ñ. Asombrado, afirmó que nunca había notado que la ñ fuera una letra tan 
                        española. Yo me acordé de la ñ, de su sonido, de mis palabras y de las palabras de 
                        lluvia de mamá, y le dije: <br/><br/>
                        
                        —También en el <em>tu’un sav</em>i existe. Escucha, nosotros somos <em>ñuu savi…</em> —pero no me 
                        escuchó y siguió su historia. <br/><br/>
                        
                        —También en el <em>tu’un savi</em> existe. Escucha, nosotros somos <em>ñññññuu savi</em> —repetí, 
                        alargando el sonido. Él apenas volteó. <br/><br/>
                        
                        Lo dije una vez más. Esta vez respondió: <br/><br/>
                        
                        —Sí, sí. Leí que los estadounidenses hicieron las computadoras y no querían incluir la 
                        letra <em>ñ</em> en ningún teclado del mundo. ¡Qué injusto! Nuestra lengua importa y merece 
                        ser incluida. <br/><br/>
                        
                        Mientras decía eso, me di cuenta de que mi mamá contemplaba mi frustración. Ella 
                        me miró con sus ojos que eran como charquitos que sonreían. Luego se rio un poco, 
                        con ese cuerpo de agua en el que todo se resbala. Y alzó los brazos para hacerme la 
                        seña de <em>ni modo</em>. Entonces, yo sonreí también y volteé a observar a mi tío, que no me 
                        veía.
                      </p>
                    </Grid>
                    
                </Grid>
        </Grid>
        <Grid container item style={{ marginTop: 80}} ></Grid>
    </Grid>
    );
}
 
export default La_ñ_del_español;
