import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
    ContenedorLectura:{
        width:'502px',
                  [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
    },
    ContainerTitulo:{
        margin:'0 0 34px 0',
        '& .Titulo':{
            letterSpacing: '0px',
            color: '#F78572',
            margin:'0',
            textAlign:'center',
            font:'43px fredoka one',
            lineHeight:'52px',
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:'0 0 36px 0',
        position:'relative',
        '& .Lectura':{
            color:'#000',
            font:'18px lato',
            lineHeight :'28px',
            letterSpacing: '0.17px',
            margin:'0 0 37px 0',
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .Esp':{
            margin:'0 ',
            color:'#000',
            font:'18px lato',
            lineHeight :'28px',
            letterSpacing: '0.17px',
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .TextImg':{
            color:'#717476',
            margin:'0',
            font:' 16px lato',
            lineHeight :'25px',
            letterSpacing: '0.15px',
            [theme.breakpoints.down(502)]:{
                font:' 11px lato',
                lineHeight :'14px',
                letterSpacing: '0.10px',
            },
        },
        '& .Imagen':{
            top:'0',
            position:'relative',
            width:'100%',
        },  
        '& .Imagen2':{
            position:'relative',
            left:'15px',
            width:'333px',
            height:'333px',
            [theme.breakpoints.down(502)]:{
                left:'0px',
                width:'175px',
                height:'175px'
            },
        },
    }
}));

const Manual_para_construir_un_cuerpo = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >
                        Manual para<br/> construir un cuerpo
                    </h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container xs={12}  >
                      <p className="Lectura ">
                        Los pies firmes en el suelo<br/> 
                        para recorrer caminos<br/> 
                        y volvernos peregrinos<br/> 
                        como estrellas en el firmamento.<br/><br/>
                        
                        Alza los brazos abiertos<br/>  
                        para abrazos repartir<br/>  
                        y todo el amor sentir<br/>  
                        aun en tiempos inciertos.
                      </p>
                    </Grid>
                    <Grid container xs={12}  >
                        <Grid container justifyContent="center" >
                            <img className="Imagen " src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img1ro/Sesion41/1R.png" alt="Tarjeta" />

                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container xs={6} >
                      <p className="Esp">
                        La cabeza despejada <br/> 
                        para sueños fabricar <br/> 
                        y así nunca renunciar <br/> 
                        nunca, nunca, a nada. <br/> <br/> 
                        
                        El corazón protegido, <br/> 
                        en el pecho resguardado, <br/> 
                        pues él es el encargado <br/> 
                        del cariño recibido.<br/> <br/> 
                        
                        Así es éste, nuestro cuerpo: <br/> 
                        casa, nave y fortaleza, <br/> 
                        fuerza, sí, y también nobleza, <br/> 
                        razón y alma en un solo templo.
                      </p>
                    </Grid>
                    <Grid container xs={6} >
                        <Grid container justifyContent="center" alignContent='flex-end'>
                            <img className="Imagen2 " src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img1ro/Sesion41/2.png" alt="Tarjeta" />
                        </Grid>
                    </Grid>
                </Grid>
        </Grid>
    </Grid>
    );
}
 
export default Manual_para_construir_un_cuerpo ;
