import React, { Suspense } from 'react';

const Lectura = (props) => {
  const {gradoLecturas, sesion} = props


  const OtherComponent = React.lazy(() => 
    import(`../../../LecturasActividad/Lecturas${gradoLecturas}/Sesion${sesion}/Lectura`)
  )
 
 
  return ( 
    <div>
      <Suspense fallback={<div>Cargando...</div>}>
        <OtherComponent />
      </Suspense>
    </div>
   );
}

 
export default Lectura;
