import React, { useEffect } from "react";
import { makeStyles, Grid } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import CorrectFeedback from "./CorrectFeedback";
import FinalFeedback from "./FinalFeedback";
import Answers from "./Answers";
import { Link } from "react-router-dom";

import ArrowBackIcon from "@material-ui/icons/ArrowBack";

import socioEmTag from "../../../../imgs/socioEmTag.png";

import ReadingModal from "../../../exercises/ReadingModal";
import WrongFeedback from "./WrongFeedback";
import ActivityStep from "./steps/ActivityStep";

const useStyles = makeStyles((theme) => ({
  layout: {
    maxHeight: "92vh",
    // height: "900px",
    width: "100%",
    backgroundColor: "#FEFAF1",
  },
  // evaluationsGrid:{
  //   height:'90%',
  //   boxSizing:'border-box',
  //   // position: 'absolute'
  // },
  mainTextContainer: {
    position: "relative",
    maxHeight: "calc(100vh - 80px)",
    width: "100%",
    [theme.breakpoints.down(1280)]: {
      display: "none",
    },
  },
  questionContainer: {
    backgroundColor: "#FEFAF1",
    maxHeight: "92vh",
    width: "100%",
    overflowY: "auto",
    display: "grid",
    gridTemplateRows: "57px 12vh 47vh 15vh 5vh",
    gridTemplateAreas: `"a"
                                    "b"
                                    "c"
                                    "d"
                                    "e"`,
    justifyItems: "center",
    [theme.breakpoints.down(1025)]: {
      width: "100%",
    },
    [theme.breakpoints.down(961)]: {
      width: "80%",
    },
    [theme.breakpoints.down(768)]: {
      padding: "0%",
    },
    [theme.breakpoints.down(620)]: {
      width: "90%",
    },
    [theme.breakpoints.down(570)]: {
      width: "100%",
    },
  },
  instructionsContainer: {
    width: "80%",
    minHeight: "12vh",
    gridArea: "b",
    // [theme.breakpoints.down(601)]:{
    // 	marginTop:'12%'
    // },
  },
  FinalInstructionsContainer: {
    marginTop: "15px",
    width: "80%",
    minHeight: "25vh",
    [theme.breakpoints.down(1440)]: {
      marginTop: "10px",
    },
  },
  buttonContainer: {
    width: "80%",
    height: "15vh",
    gridArea: "d",
  },
  // progressContainer:{
  //   width:"100%",
  //   height:"10%"
  // },
  reading: {
    textAlign: "justify",
    width: "80%",
    font: "22px Lato;",
    letterSpacing: "0.21px",
  },
  backButton: {
    height: "30px",
    backgroundColor: "#FEFAF1",
    color: "#F2624F",
    border: "#F2624F 1px solid",
    borderRadius: "20px",
    textTransform: "none",
    font: "regular 20px Lato",
    letterSpacing: "0.14px",
  },
  arrowBackIcon: {
    marginRight: "5px",
  },
  continueButton: {
    // marginTop: "6%",
    // marginBottom: "6%",
    textTransform: "none",
    height: "62px",
    width: "279px",
    [theme.breakpoints.down(1368)]: {
      height: "52px",
      width: "229px",
    },
    [theme.breakpoints.down(1068)]: {
      height: "52px",
      width: "60%",
    },
    [theme.breakpoints.down(769)]: {
      width: "56.5%",
    },
    background: "#7A54BA",
    boxShadow: "0px 3px 6px #00000029",
    borderRadius: "61px",
    color: "#FFFFFF",
    font: "bold 20px lato ",
    "&:hover": {
      background: "#dd78fb",
    },
  },
  answerContainer: {
    height: "100%",
    maxWidth: "80%",
    gridArea: "c",
    [theme.breakpoints.down(860)]: {
      maxWidth: "100%",
    },
  },
  answerTextContainer: {
    minHeight: "40vh",
  },
  progressLinearContainer: {
    height: "5vh",
    width: "72%",
    gridArea: "e",
    [theme.breakpoints.down(1024)]: {
      width: "82%",
    },
  },
  progressLinearBar: {
    width: "90%",
    height: "8%",
  },
  readingDemoContainer: {
    // maxHeight: "1024px",
    margin: "8% 0 0% 1%",
    overflowY: "scroll",
    scrollbarColor: "#F6DE95",
    scrollbarWidth: "15px",
    "&::-webkit-scrollbar": {
      width: "15px",
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "#F6DE95",
      webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#8144DF",
      borderRadius: "61px",
    },
  },
  arrowButton: {
    position: "absolute",
    left: "30px",
    margin: "2% 0 1% 0",
  },
  progress: {
    borderRadius: "7px",
    width: "95%",
    height: "15px",
    marginLeft: -"11.5%",
    boxShadow: " 1px 1px 4px rgba( 0, 0, 0, 0.2 )",
    "&::-webkit-progress-bar": {
      backgroundColor: "#F2624F",
      borderRadius: "7px",
    },
    "&::-webkit-progress-value": {
      backgroundColor: "#84E9DF",
      borderRadius: "7px",
      // boxShadow: "1px 1px 5px 3px rgba( 255, 0, 0, 0.8 )",
    },
  },
  link: {
    transform: "translate(-50%,-50%)",
    textDecoration: "none",
  },
  readingButton: {
    display: "none",
    [theme.breakpoints.down(1280)]: {
      display: "grid",
    },
  },
  showReadingButton: {
    marginTop: "10px",
    textTransform: "none",
    font: "24px Fredoka One",
    color: "white",
    backgroundColor: "#60BFAE",
    borderRadius: "50px 50px 0 0",
    width: "411px",
    height: "58px",
    paddingTop: "20px",
    [theme.breakpoints.down(700)]: {
      width: "250px",
    },
  },
  puntaje: {
    zIndex: "5",
    border: "black 1px solid",
    width: "200px",
    height: "40px",
    // '&:hover':{
    //   background: '#dd78fb',
    // },
  },
  emotionalTagContainer: {
    width: "57px",
    height: "57px",
    gridArea: "a",
    [theme.breakpoints.down(1400)]: {
      width: "47px",
      height: "47px",
    },
    [theme.breakpoints.down(1020)]: {
      height: "42px",
    },
  },
  emotionalTagGrid: {
    width: "55px",
    height: "90%",
    backgroundColor: "#FFDE72D1",
    border: "#B64F88 3px solid",
    borderRadius: "0 0 20px 20px",
    marginLeft: "10%",
    display: "none",
    [theme.breakpoints.down(1400)]: {
      width: "47px",
    },
    [theme.breakpoints.down(1020)]: {
      width: "40px",
      margin: "0 10%",
    },
  },
  imgSociEm: {
    [theme.breakpoints.down(1020)]: {
      height: "20px",
    },
  },
}));

const Activity = (props) => {
  const classes = useStyles();
  const {
    step,
    setStep,
    questionList,
    Lectura,
    questionComponent: QuestionComponent,
    imageList,
    activityNumber,
    funcional,
    formativaNumber,
    toSession,
    tryNumber,
  } = props;

  const [openRight, setOpenRight] = React.useState(false);
  const [openWrong, setOpenWrong] = React.useState(false);
  const [isCorrect, setIsCorrect] = React.useState(false);
  const [pristine, setPristine] = React.useState(true);
  const [feedback, setFeedback] = React.useState("");
  const [answerText, setAnswerText] = React.useState("");

  const [currentAnswer, setCurrentAnswer] = React.useState();
  const [openFinal, setOpenFinal] = React.useState(false);
  const [totalPoints, setTotalPoints] = React.useState(0);
  const [valueAnswerText, setValueAnswerText] = React.useState("Controlled");
  const [feedBackImage, setFeedBackImage] = React.useState(false);
  const [questionId, setQuestionId] = React.useState(0);
  const [openReading, setOpenReading] = React.useState(false);

  const [stopQuestion, setStopQuestion] = React.useState(false);

  const [stepTutorial, setStepTutorial] = React.useState({
    one: false,
    two: false,
    three: false,
  });

  const handleStep = (newStep) => {
    setStepTutorial(newStep);
  };


  useEffect(() => {
    if (currentAnswer) {
      handleStep({
        ...stepTutorial,
        one: true,
      });
    }
  }, [currentAnswer]);

  const feedBackContinue = () => {
    setStopQuestion(false);
    if (step !== 9) {
      isCorrect ? setOpenRight(true) : setOpenWrong(true);
      isCorrect && setTotalPoints(totalPoints + 1);
      handleStep({
        ...stepTutorial,
        two: true,
      });
    } else {
      setOpenFinal(true);
      setTotalPoints(totalPoints + 1);
    }
  };

  const renderFuncional = () => {
    if (funcional) {
      // return { display: "none" };
      return;
    }
  };

  const showSocioETag = questionList[step] && questionList[step].insignia;

  const renderSocioE = () => {
    if (showSocioETag === "socio emocional") {
      return { display: "grid" };
    }
  };

  return (
    <>
      <Grid
        container
        direction="row"
        item
        sm={12}
        className={classes.layout}
        justifyContent="center"
      >
        <Grid
          container
          item
          md={6}
          direction="column"
          justifyContent="flex-start"
          alignItems="flex-start"
          className={classes.mainTextContainer}
          style={renderFuncional()}
        >
          <Grid className={classes.arrowButton}>
            <Link className={classes.link} to={toSession}>
              <Button className={classes.backButton}>
                {" "}
                <ArrowBackIcon /> Lectura original
              </Button>
            </Link>
          </Grid>
          <Grid
            container
            item
            xs={12}
            justifyContent="center"
            alignItems="center"
            className={classes.readingDemoContainer}
          >
            <Lectura />
          </Grid>
        </Grid>
        <Grid
          container
          item
          md={6}
          justifyContent="center"
          alignItems="center"
          className={classes.questionContainer}
        >
          <Grid
            container
            item
            xs={12}
            className={classes.emotionalTagContainer}
          >
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              className={classes.emotionalTagGrid}
              style={renderSocioE()}
            >
              <img
                src={socioEmTag}
                alt="corazn"
                className={classes.imgSociEm}
              />
            </Grid>
          </Grid>
          <Grid
            container
            item
            justifyContent="flex-start"
            alignItems="flex-start"
            className={
              step === 9
                ? classes.FinalInstructionsContainer
                : classes.instructionsContainer
            }
          >
            {QuestionComponent}
          </Grid>
          <Grid
            container
            item
            justifyContent="center"
            // alignItems="center"
            className={
              step === 9 ? classes.answerTextContainer : classes.answerContainer
            }
          >
            {questionList && questionList.length && (
              <Answers
                respuestas={questionList[step].respuestas}
                setPristine={setPristine}
                setIsCorrect={setIsCorrect}
                setFeedback={setFeedback}
                setAnswerText={setAnswerText}
                setCurrentAnswer={setCurrentAnswer}
                currentAnswer={currentAnswer}
                setTotalPoints={setTotalPoints}
                totalPoints={totalPoints}
                setValueAnswerText={setValueAnswerText}
                valueAnswerText={valueAnswerText}
                questionType={questionList[step].tipo_pregunta}
                feedBackImage={feedBackImage}
                setFeedBackImage={setFeedBackImage}
                questionId={questionId}
                setQuestionId={setQuestionId}
                imageList={imageList}
              />
            )}
          </Grid>
          {(!stepTutorial.one || stepTutorial.two) && (
            <Grid
              container
              item
              justifyContent="center"
              alignItems="center"
              className={classes.buttonContainer}
            >
              {
                // step === 9 ?
                //   <Button className={classes.continueButton} disabled={pristine} onClick={finishFeedback}>Continuar</Button>
                // :
                <Button
                  className={classes.continueButton}
                  disabled={pristine}
                  onClick={feedBackContinue}
                >
                  Continuar
                </Button>
              }
            </Grid>
          )}
          <Grid
            container
            item
            justifyContent="center"
            alignItems="center"
            className={classes.progressLinearContainer}
          >
            <progress
              value={step * 10}
              max={100}
              className={classes.progress}
            ></progress>
          </Grid>
          <Grid
            container
            item
            xs={12}
            justifyContent="center"
            alignItems="center"
            className={classes.readingButton}
          >
            <Button
              className={classes.showReadingButton}
              style={renderFuncional()}
              onClick={() => {
                setOpenReading(true);
              }}
            >
              Ver la lectura
            </Button>
          </Grid>
          <ReadingModal
            open={openReading}
            setOpen={setOpenReading}
            lectura={<Lectura />}
          />
        </Grid>
        {/* </Grid> */}
        <CorrectFeedback
          open={openRight}
          setOpen={setOpenRight}
          pregunta={QuestionComponent}
          feedback={feedback}
          answerText={answerText}
          setStep={setStep}
          step={step}
          setPristine={setPristine}
          feedBackImage={feedBackImage}
          setFeedBackImage={setFeedBackImage}
          questionId={questionId}
          imageList={imageList}
          badgesType={showSocioETag}
          formativa={formativaNumber || false}
          stopQuestion={stopQuestion}
          setStopQuestion={setStopQuestion}
          tryNumber={tryNumber}
          activityNumber={activityNumber}
        />
        <WrongFeedback
          open={openWrong}
          setOpen={setOpenWrong}
          pregunta={QuestionComponent}
          feedback={feedback}
          answerText={answerText}
          setStep={setStep}
          step={step}
          setPristine={setPristine}
          feedBackImage={feedBackImage}
          setFeedBackImage={setFeedBackImage}
          questionId={questionId}
          imageList={imageList}
          stopQuestion={stopQuestion}
          setStopQuestion={setStopQuestion}
          tryNumber={tryNumber}
          activityNumber={activityNumber}
        />
        <FinalFeedback
          open={openFinal}
          setOpen={setOpenFinal}
          feedback={feedback}
          valueAnswerText={valueAnswerText}
          totalQuestions={questionList.length}
          activityNumber={activityNumber}
          formativa={formativaNumber || false}
          tryNumber={tryNumber}
          badgesType={showSocioETag}
        />
      </Grid>
      {(!stepTutorial.one || !stepTutorial.two) && (
        <ActivityStep
          Lectura={Lectura}
          questionList={questionList}
          step={step}
          setPristine={setPristine}
          setIsCorrect={setIsCorrect}
          setFeedback={setFeedback}
          setAnswerText={setAnswerText}
          setCurrentAnswer={setCurrentAnswer}
          currentAnswer={currentAnswer}
          setTotalPoints={setTotalPoints}
          totalPoints={totalPoints}
          setValueAnswerText={setValueAnswerText}
          valueAnswerText={valueAnswerText}
          feedBackImage={feedBackImage}
          setFeedBackImage={setFeedBackImage}
          questionId={questionId}
          setQuestionId={setQuestionId}
          imageList={imageList}
          QuestionComponent={QuestionComponent}
          stepTutorial={stepTutorial}
          feedBackContinue={feedBackContinue}
        />
      )}
    </>
  );
};

export default Activity;
