import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
   ContenedorLectura:{
        width:'502px',
         [theme.breakpoints.down(502)]:{
           width:'350px',
         },
   },
    ContainerTitulo:{
        margin:'0 0 46px 0',
        '& .Titulo':{
            letterSpacing: '0px',
            color: '#FF0000',
            margin:'0',
            textAlign:'center',
            font:'43px fredoka one',
            lineHeight:'52px',
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            margin:'0 0 36px 0',
            color:'#000',
            font:'18px lato',
            lineHeight :'28px',
            letterSpacing: '0.17px',
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .Imagen':{
            width:'100%',
        },
        
    },
    

}));

const Una_persona_no_es_una_isla = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >
                        Una persona<br/> no es una isla
                    </h2>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container item xs={12}>
                        <p className="Lectura">
                            Una persona encerrada en cuatro paredes<br/>
                            se convierte en una pequeña y triste<br/>
                            isla… de piel, de huesos.<br/>
                            Y vive en el engaño<br/>
                            de creer que su casa es todo el mundo,<br/>
                            sus costumbres las costumbres del mundo,<br/>
                            su comida la comida del mundo.
                        </p>
                    </Grid>
                    <Grid container item justifyContent="center" xs={12} >
                        <img className='Imagen' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img4to/Sesion41/1.png"  alt="Tarjeta" />
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container item  xs={12} >
                        <p className="Lectura ">
                            Dijo un filósofo griego que el hombre<br/>
                            es la medida de todas las cosas.<br/>
                            Sólo si ese hombre somos tú y yo, <br/>
                            nosotros, él y ella. <br/><br/>
                            
                            Dijo un filósofo griego que la mujer <br/>
                            es la medida de todas las cosas. <br/>
                            Sólo si esa mujer somos tú y yo, <br/>
                            nosotros, él y ella. <br/><br/>
                            
                            El mar es la suma de las olas. <br/>
                            De ola en ola se enaltece <br/>
                            hasta convertirse en el gigante <br/>
                            que nunca duerme. <br/>
                            Así el mundo es la suma de manos <br/>
                            que se amontañan para arrullar <br/>
                            al gigante que nunca duerme. <br/><br/>

                            ¡Levantemos juntos la casa del mundo! <br/>
                            Las cuatro paredes de la casa del mundo <br/>
                            para que una persona nunca sea una isla. <br/>
                            Para que la persona que soy yo, que eres tú, <br/>
                            habite muros transparentes.
                        </p>
                    </Grid>
                </Grid>
        </Grid>
    </Grid>
    );
}
 
export default Una_persona_no_es_una_isla;
