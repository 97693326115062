import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
    ContenedorLectura:{
        width:'502px',
                  [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
    },
    ContainerTitulo:{
        margin:'0 0 34px 0',
        '& .Titulo':{
            letterSpacing: '0px',
            color: '#F6664D',
            margin:'0',
            textAlign:'center',
            font:'43px fredoka one',
            lineHeight:'52px',
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            margin:'0 0 36px 0',
            color:'#000',
            font:'18px lato',
            lineHeight :'28px',
            letterSpacing: '0.17px',
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },

        '& .Imagen':{
            width:'100%',
        }, 
      
    }
}));

const Qué_le_pasa_a_la_abuela = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >
                        ¿Qué le pasa a la abuela?                    
                    </h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                      <p className="Lectura">
                        Muchas cosas han cambiado en casa durante los últimos meses. Mi abuela se mudó 
                        con nosotros y se comporta de una manera extraña, como si estuviera siempre 
                        distraída. A veces me mira como si no supiera quién soy. Incluso ha llegado a 
                        llamarme por el nombre de papá y se molesta si le digo que no soy él. Un día escuché 
                        que mis papás decían que estaba enferma, ¡pero no lo parece!<br/><br/>
                        
                        —Mamá, ¿qué le pasa a la abuela? ¿Por qué dicen que está enferma si no se ve así? <br/>
                        —pregunté una mañana. <br/><br/>
                        
                        —Bueno… Tu abuela tiene una enfermedad que se llama <em>Alzheimer</em>. Afecta su cerebro 
                        y sus pensamientos, pero no se nota a simple vista. Hace que pierda la memoria, y por 
                        eso a veces no nos reconoce o nos confunde con otras personas. <br/><br/>
                        
                        —Ah… ¿Y cuándo se va a curar? Yo quisiera jugar más con ella. <br/><br/>
                        
                        —Leo, aún no hay una cura para esa enfermedad. Todos tendremos que aprender a 
                        vivir con tu abuela de ese modo: un poco diferente a lo que estábamos 
                        acostumbrados —respondió mi madre y tomó mi hombro intentando hacerme sentir 
                        bien.
                      </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <Grid container justifyContent="center" >
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img2do/Sesion44/1.png" alt="Tarjeta" />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                      <p className="Lectura">
                        Aunque estas noticias me desanimaron un poco, todavía podía hablar con mi abuela y 
                        la situación no parecía tan mala. <br/><br/>
                        
                        —Poco a poco tu abuela irá olvidando otras cosas. Primero, no recordará lo que 
                        almorzó por la tarde. Después tampoco sabrá cómo comer o cómo ir al baño, y todos 
                        tendremos que ayudarle —dijo mi mamá sin esperar a que yo le preguntara.<br/><br/>
                        
                        Me asusté un poco. <br/><br/>
                        
                        —Pero, mamá… Yo no quiero que eso pase. ¿Qué puedo hacer para ayudarla?<br/>
                        —pregunté. <br/><br/>
                        
                        —Tendrás que ser paciente con ella. Así como ella lo fue contigo cuando eras un bebé.<br/><br/>
                        
                        —Entonces… ¿también se va a olvidar de mí? <br/><br/>
                        
                        —El Alzheimer te hace olvidar muchas cosas, pero tu abuela siempre va a sentir tu 
                        cariño. <br/><br/>
                        
                        Las palabras de mi madre me tranquilizaron. Ahora mi abuela y yo hacemos cosas 
                        que le agradan, como ver películas o armar rompecabezas. Sigue estando con 
                        nosotros, sólo que de una forma diferente.
                      </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <Grid container justifyContent="center" >
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img2do/Sesion44/2.png" alt="Tarjeta" />
                        </Grid>
                    </Grid>
                </Grid>
        </Grid>
    </Grid>
    );
}
 
export default Qué_le_pasa_a_la_abuela ;
