import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
      Contenedor:{
        background:'#E9FBF9',
        width:'100vw',
        overflowX:'clip',
        [theme.breakpoints.down(1161)]:{
            background:'none',
           overflowX:'unset',
        },
        [theme.breakpoints.down(600)]:{
            marginTop: '79.51px',
        }
    },
    ContainerInfoLectura:{
        position:'relative',
        height:'86px',
        background:'#76D4C3',
        '& .BarraInfoLectura':{
            height:'86px',
            background:`Url(https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Barras/Verde.png)`,
        },
        '& .InfoLectura':{
            position:'absolute',
            height:'86px',
            width:'219px',
            boxSizing:'border-box',
            padding:'17.2px 0',
            background:'#FAFAFA',
            borderRadius:'50px',
            boxShadow: '2px 2px 2px 2px #00000029',
            zIndex:'4',
        },
        '& .InfoLectura:before':{
            position:'absolute',
            display:'flex',
            boxSizing:'border-box',
            padding:'0 0 0 10px',
            justifyContent:'center',
            alignItems:'center',
            height:'39.88px',
            width:'39.88px',
            borderRadius:'50%',
            content:'""',
            boxShadow: '3px 0px .5px 0px #00000029',
            background:'#FAFAFA',
            left:'90px',
            top:'65px',
            transform: 'rotate(0.25turn)',
        },
        '& .InfoLectura:after':{
            content:'""',
            height:'15px',
            width:'15px',
            borderTop:'2px solid #20A698',
            borderLeft:'2px solid #20A698',
            position:'absolute',
            bottom:'-10px',
            left:'Calc(50% - 8px)',
            transform: 'rotate(0.625turn)',
        },
        '& .Sesion':{
            borderLeft:'2px solid #D8D8D8',
        },
        '& .Dato':{
            margin:'0 0 5px 0',
            textAlign:'center',
            font:'bold 17px lato',
            color:'#20A698',
        },
        '& .Caracter':{
            margin:'0',
            textAlign:'center',
            font:'bold 17px lato',
            color:'#000000',
        },
    },
    ContenedorLectura:{
        background:'#fff',
        width:'1160px',
        height:'100%',
        padding:'57px 168px 35px' ,
        [theme.breakpoints.down(1161)]:{
            width:'502px',
            padding: '57px 0 0 0',
        },
        [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
        '& .ContainerDerechos':{
            bottom:'80px',
            [theme.breakpoints.down(1161)]:{
                display:'none',
            }
        },
        '& .Derechos':{
            textAlign:"center",
            margin:"0px",
            font:" 15px lato",
            color:"#717476",
        },
        '& .Escritorio':{
            display:'block',
            [theme.breakpoints.down(1161)]:{
                display:'none'
            },
        },
        '& .Tablet':{
            display:'none',
            [theme.breakpoints.down(1161)]:{
                display:'block'
            },
        },
    },
    ContainerTitulo:{
        margin:'0 0 45px 0',
        [theme.breakpoints.down(1161)]:{
            margin:'0 0 38px 0',
        },
        '& .Titulo':{
            font:'48px fredoka one',
            letterSpacing: '0px',
            color: '#1072F8',
            margin:'0',
            textAlign:'center',
            [theme.breakpoints.down(1161)]:{
                font:'43px fredoka one',
                lineHeight:'52px',
                textAlign:'center',
            },
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            lineHeight : '30px',
            margin:'0 0 36px 0 ',
            font:'22px lato',
            color:'#000',
            letterSpacing: '0.20px',
            [theme.breakpoints.down(1161)]:{
                font:'18px lato',
                letterSpacing:'.17px',
                lineHeight:'28px',
            },
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .SubTitulo':{
            margin:'0',
            font:'bold 30px lato',
            color:'#E56D54',
            letterSpacing:'.28px',
            lineHeight:'30px',
            [theme.breakpoints.down(1161)]:{
                font:'26px Fredoka One',
                letterSpacing:'.24px',
                lineHeight:'28px',
            },
            [theme.breakpoints.down(502)]:{
                font:'bold 22px lato',
                lineHeight:'24px',
            },
            [theme.breakpoints.down(350)]:{
                font:'bold 18px lato',
                lineHeight:'24px',
            },
        },
        '& .Imagen':{
            [theme.breakpoints.down(1161)]:{
                width:'100%',
            },
        }

    },

}));

const Los_metales_y_su_papel_en_la_tecnología = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
             <Grid container item justifyContent="center" className={classes.ContainerInfoLectura}>
               <Grid container className="BarraInfoLectura"></Grid>
               <Grid container item className="InfoLectura">
                    <Grid container item direction="column" xs={6} justifyContent="center">
                        <p className="Dato">Grado</p>
                        <p className="Caracter">6</p>
                    </Grid>
                    <Grid container item direction="column" xs={6} justifyContent="center" className="Sesion">
                        <p className="Dato">Sesión</p>
                        <p className="Caracter">47</p>
                    </Grid>
               </Grid>
            </Grid>

            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >
                        Los metales y su papel <br/>en la tecnología
                    </h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                            ¿Alguna vez te has puesto a reflexionar sobre la importancia que tienen los metales 
                            en tu vida cotidiana? Quizá tendrías que hacerlo, porque están presentes en todas 
                            partes: desde lo más grande que te puedas imaginar, como las estructuras con las que 
                            se construyen los edificios, hasta lo más pequeño, como los teléfonos móviles. <br/><br/>
                            
                            Con la irrupción de la tecnología, se ha multiplicado el uso de los metales, ya que son
                             materiales con propiedades muy específicas difíciles de encontrar en otros 
                             elementos. Por ejemplo, la maleabilidad, es decir, su capacidad de doblarse sin 
                             romperse. O la conducción, lo cual implica que transmiten muy bien el calor y la 
                             electricidad. Además, los metales se encuentran en todo el planeta. <br/><br/>
                             
                             Las ventajas de estos materiales combinadas con el alto consumo de la sociedad han 
                             provocado su sobreexplotación. El problema es que son recursos no renovables, lo 
                             que ha provocado la escasez de unos cuantos, que en algunos casos son altamente 
                             contaminantes. Por esto último algunos de ellos pueden provocar contaminación del
                            medio ambiente. <br/><br/>
                            
                            A continuación, conoce un poco más sobre los metales utilizados en la tecnología que 
                            quizá desconozcas. Esto te permitirá tomar conciencia sobre su uso desmedido. <br/><br/>
                            
                            <h3 className='SubTitulo'>Propiedades de los metales</h3><br/>
                            
                            Los metales son materiales fundamentales en la fabricación de aparatos eléctricos y 
                            electrónicos. En el caso de un teléfono celular, en sus mecanismos internos se 
                            pueden encontrar cobre, estaño, indio, cobalto, antimonio, oro, plata y paladio. Ésos 
                            son los más comunes, pero existen metales menos conocidos y abundantes, como el 
                            tántalo.
                        </p>
                    </Grid>
                    <Grid container >
                            <img className='Imagen' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion47/1.png"  alt="Tarjeta" />
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                            El tántalo es un metal fundamental para el desarrollo de la tecnología debido a su 
                            resistencia a la corrosión. Esto quiere decir que, si pusiéramos a la intemperie un 
                            pedazo de tántalo y uno de hierro durante un mes el pedazo de hierro se llenaría de
                            óxido, mientras que el de tántalo seguiría intacto. Esta característica lo hace ideal 
                            para muchas aplicaciones tecnológicas y médicas. <br/><br/>
                            
                            Otra de sus virtudes es que puede conservar mucha electricidad en un espacio muy 
                            reducido, por lo que es ideal para fabricar baterías para teléfonos móviles y otros 
                            dispositivos portátiles. Sin el tántalo, los dispositivos móviles no cabrían en el bolsillo 
                            de un pantalón ni la batería duraría lo suficiente.<br/><br/>
                            
                            Otro de los metales más utilizados en la industria tecnológica es el paladio. Éste se 
                            emplea en computadoras, celulares y hasta televisiones, aunque su principal
                            aplicación se encuentra en la industria química y eléctrica. Una de sus principales
                            funciones es almacenar las cargas eléctricas. <br/><br/>
                            
                            <h3 className='SubTitulo'>Desventajas del exceso en el consumo</h3><br/>
                            
                            Uno de los problemas fundamentales relacionado con los metales es la dificultad que 
                            conlleva su obtención, lo cual incrementa paulatinamente los costos. Por ejemplo,
                            actualmente China produce cerca de 90% de los minerales raros que se utilizan para 
                            fabricar dispositivos electrónicos. De esa manera, el mundo está limitado en gran 
                            medida a las decisiones de ese país.
                        </p>
                    </Grid>
                    <Grid container  sm={12}>
                            <Grid container>
                                <img className='Imagen' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion47/2.png"  alt="Tarjeta" />
                            </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                            Es cierto que China no es el único lugar donde existen ese tipo de metales, pero la 
                            extracción en otros países no se compara con la cantidad que produce la nación 
                            asiática. Esto se debe a que la infraestructura y cantidad de recursos económicos que 
                            China invierte no se pueden igualar en otras partes del mundo. <br/><br/>
                            
                            Otra desventaja es que hay ciertos metales raros y escasos que se localizan 
                            únicamente en regiones específicas del planeta, las cuales no siempre gozan de 
                            estabilidad política y social. Tal es el caso de la República del Congo, un país africano 
                            constantemente envuelto en conflictos armados, donde se encuentra la principal 
                            mina de tántalo en el mundo. <br/><br/>
                            
                            Los metales han tenido un papel protagónico en el avance científico y tecnológico. 
                            Gracias a ellos, tu vida cotidiana es posible tal como la conoces. Pero es necesario 
                            que reflexiones en si hay una manera en la que puedas contribuir con la disminución 
                            de la explotación y contaminación relacionadas con los metales. Piensa, por ejemplo, 
                            en evitar adquirir un nuevo modelo de algo electrónico cada vez que se lance al 
                            mercado. También intenta retrasar el cambio y aprovechar la vida útil de tus 
                            aparatos. Si ya es inevitable, deséchalo en lugares especiales para ello, de tal manera 
                            que se puedan reciclar.<br/><br/>
                            
                            Recuerda que tus acciones y tu comportamiento tienen un mayor impacto en el 
                            mundo del que te imaginas.

                        </p>
                    </Grid>
                </Grid>

                <Grid container item xs={12} justifyContent="center" className="ContainerDerechos">
                    <p className="Derechos">
                        © 2022. Todos los derechos reservados, Pearson, Circuito de lectores.
                    </p>                
                </Grid>
        </Grid>
    </Grid>
    );
}
 
export default Los_metales_y_su_papel_en_la_tecnología;
