import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
     Contenedor:{
        background:'#E9FBF9',
        width:'100vw',
        overflowX:'clip',
        [theme.breakpoints.down(1161)]:{
            background:'none',
           overflowX:'unset',
        },
        [theme.breakpoints.down(600)]:{
            marginTop: '79.51px',
        }
    },
  ContainerInfoLectura: {
    position: "relative",
    height: "86px",
    [theme.breakpoints.down(502)]:{
        height: "43px",
    },
    background: "#76D4C3",
    "& .BarraInfoLectura": {
      height: "86px",
      background: `Url(https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Barras/Azul.png)`,
        [theme.breakpoints.down(502)]:{
            height: "43px",
        },
    },
    "& .InfoLectura": {
      position: "absolute",
      height: "86px",
      width: "219px",
      boxSizing: "border-box",
      padding: "17.2px 0",
      background: "#FAFAFA",
      borderRadius: "50px",
      boxShadow: "2px 2px 2px 2px #00000029",
      zIndex: "4",
        [theme.breakpoints.down(502)]:{
            height: "43px",
            width: "109px",
            padding: "8.6px 0",
            borderRadius: "25px",
        },
    },
    "& .InfoLectura:before": {
      position: "absolute",
      display: "flex",
      boxSizing: "border-box",
      padding: "0 0 0 10px",
      justifyContent: "center",
      alignItems: "center",
      height: "39.88px",
      width: "39.88px",
      borderRadius: "50%",
      content: '""',
      boxShadow: "3px 0px .5px 0px #00000029",
      background: "#FAFAFA",
      left: "90px",
      top: "65px",
      transform: "rotate(0.25turn)",
      [theme.breakpoints.down(502)]:{
        left: "45px",
        top: "30px",
        height: "20.88px",
        width: "20.88px",
        },
    },
    "& .InfoLectura:after": {
      content: '""',
      height: "15px",
      width: "15px",
      borderTop: "2px solid #20A698",
      borderLeft: "2px solid #20A698",
      position: "absolute",
      bottom: "-10px",
      left: "Calc(50% - 8px)",
      transform: "rotate(0.625turn)",
      [theme.breakpoints.down(502)]:{
        height: "10px",
        width: "10px",
        left: "Calc(50% - 4.5px)",
        bottom: "-3px",
        borderTop: "1px solid #20A698",
        borderLeft: "1px solid #20A698",
        },
    },
    "& .Sesion": {
      borderLeft: "2px solid #D8D8D8",
    },
    "& .Dato": {
      margin: "0 0 5px 0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#20A698",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        margin: "0 0 2.5px 0",
        },
    },
    "& .Caracter": {
      margin: "0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#000000",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        },
    },   },
    ContenedorLectura:{
        position:'relative',
        background:'#fff',
        width:'1160px',
        height:'100%',
        padding:'60px 168px 35px ',
        [theme.breakpoints.down(1161)]:{
            width:'502px',
            padding:'60px 0 0 0',
          },
        [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
        '& .ContainerDerechos':{
            bottom:'80px',
            [theme.breakpoints.down(1161)]:{
                display:'none',
            },
        },
        '& .Derechos':{
            textAlign:"center",
            margin:"0px",
            font:" 15px lato",
            color:"#717476",
        },
        '& .Escritorio':{
            display:'block',
            [theme.breakpoints.down(1161)]:{
                display:'none'
            },
        },
        '& .Tablet':{
            display:'none',
            [theme.breakpoints.down(1161)]:{
                display:'block'
            },
            [theme.breakpoints.down(502)]:{
                display:'none'
            },
        }
    },
    
    ContainerTitulo:{
        margin:'0 0 46px 0',
        '& .Titulo':{
            font:'48px fredoka one',
            letterSpacing: '0px',
            color: '#9524AF',
            margin:'0',
            textAlign:'center',
            [theme.breakpoints.down(1161)]:{
                font:'43px fredoka one',
                lineHeight:'52px',
            },
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            zIndex:2,
            lineHeight : '30px',
            margin:'0 ',
            font:'22px lato',
            color:'#000',
            letterSpacing: '-0.22px',
            lineHeight:'30px',
            [theme.breakpoints.down(1161)]:{
                font:'18px lato',
                lineHeight :'28px',
                letterSpacing: '0.17px',
            },
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .Esp':{
            boxSizing:'border-box',
            padding:'0 44px 0 0',
            [theme.breakpoints.down(1161)]:{
                padding:'0 51px 0 0',
            },
            [theme.breakpoints.down(502)]:{
                padding:'0 15px 0 0',
            },
        },
        '& .SubTitulo':{
            margin:'0',
            font:'bold 30px lato',
            letterSpacing:'.28px',
            lineHeight:'28px',
            color:'#FC5E9E',
            [theme.breakpoints.down(1161)]:{
                font:'bold 22px lato',
                lineHeight :'24px',
                letterSpacing: '0.21px',
            },
            [theme.breakpoints.down(502)]:{
                font:'bold 17px lato',
                lineHeight :'20px',
                letterSpacing: '0.16px',
            },
        },
        '& .TextImg':{
            margin:'0 0',
            font:'20px lato',
            color:'#717476',
            lineHeight :'30px',
            letterSpacing: '-0.81px',
            [theme.breakpoints.down(1161)]:{
                font:' 16px lato',
                lineHeight :'20px',
                letterSpacing: '-0.5px',
            },
            [theme.breakpoints.down(502)]:{
                font:' 10px lato',
                lineHeight :'14px',
                letterSpacing: '-0.21px',

            },
        },
        '& .Imagen':{
            margin:'10px 0 0 0',
            [theme.breakpoints.down(1161)]:{
                width:'196px',
                margin:'48px 0 0 0',
            },
            [theme.breakpoints.down(502)]:{
                width:'100%',
                margin:'20px 0 0 0',
                height:'320px',
            },
            [theme.breakpoints.down(350)]:{
                height:'220px',
            },
        },

    },
    

}));

const Qué_es_la_justicia_y_para_qué_sirve = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
             <Grid container item justifyContent="center" className={classes.ContainerInfoLectura}>
               <Grid container className="BarraInfoLectura"></Grid>
               <Grid container item className="InfoLectura">
                    <Grid container item direction="column" xs={6} justifyContent="center">
                        <p className="Dato">Grado</p>
                        <p className="Caracter">2</p>
                    </Grid>
                    <Grid container item direction="column" xs={6} justifyContent="center" className="Sesion">
                        <p className="Dato">Sesión</p>
                        <p className="Caracter">28</p>
                    </Grid>
               </Grid>
            </Grid>

            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >
                        ¿Qué es la justicia y para qué sirve?
                    </h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container xs={8}>
                        <p className="Lectura Esp">
                            <h3 className='SubTitulo'>Temis, la diosa de la justicia</h3><br/> 

                            <span></span>En la antigua Grecia solía representarse a la justicia
                            mediante la figura de una mujer con una espada y una
                            balanza: Temis, la del buen consejo. En todas las
                            culturas, los seres humanos han explicado sus reglas y
                            su manera de entender el mundo y de convivir a través
                            de los mitos y figuras de personajes poderosos.<br/><br/>
                            
                            Obsérvala con cuidado en la<br className='Tablet'/> ilustración de la <br className='Escritorio'/> 
                            derecha.<br/><br/>
                            
                            Esta escultura representa a la diosa griega de la
                            justicia, el orden y la <br className='Tablet'/>ley natural y divina. Según el <br className='Escritorio'/>
                            mito, ella era la encargada del orden en <br className='Tablet'/> la Tierra.
                            Guiaba a las personas por el camino correcto, los
                            buenos hábitos y el cumplimiento de las leyes.<span className="Escritorio"><br/></span>
                            
                            <span className="Escritorio">
                            Podrás observar que tiene los ojos vendados. Esto 
                            quiere decir que las leyes deben ser imparciales y 
                            equitativas. Por ejemplo, en un conflicto o una 
                            controversia se deben equilibrar los hechos y 
                            escuchar a todas las partes involucradas. De hecho, 
                            existe un dicho que dice que “la justicia es ciega”. <br/><br/>
                            
                            La balanza que porta es para sopesar la situación,<br className='Escritorio'/> 
                            los argumentos, las razones y los hechos. La espada 
                            simboliza la sanción que podría aplicarse a quien <br className='Escritorio'/> 
                            actúe de manera incorrecta con respecto a las leyes.</span>

                        </p>
                    </Grid>
                    <Grid container justifyContent="center" xs={4}>
                        <Grid container justifyContent='center' >
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img2do/Sesion28/1.png" alt="Tarjeta" />
                        </Grid>
                        <Grid container item alignContent='flex-end' justifyContent='flex-end'>
                            <p className='TextImg '>
                                En otras culturas antiguas hubo 
                                deidades equivalentes a la Temis 
                                griega. Por ejemplo, la cultura 
                                romana tenía a la diosa Iustitia, de 
                                donde se deriva la palabra <em>justicia</em>.
                            </p>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                            <span className="Tablet">Podrás observar que tiene los ojos vendados. Esto 
                            quiere decir que las leyes deben ser imparciales y 
                            equitativas. Por ejemplo, en un conflicto o una 
                            controversia se deben equilibrar los hechos y 
                            escuchar a todas las partes involucradas. De hecho, 
                            existe un dicho que dice que “la justicia es ciega”. <br/><br/>
                            
                            La balanza que porta es para sopesar la situación, 
                            los argumentos, las razones y los hechos. La espada 
                            simboliza la sanción que podría aplicarse a quien 
                            actúe de manera incorrecta con respecto a las leyes.<br/><br/></span>

                            <h3 className='SubTitulo'>La justicia en la actualidad</h3><br/> 

                             Partiendo de la figura de Temis y pensando en la actualidad podríamos decir que la 
                             justicia es parte de un proceso que intenta mantener la igualdad entre todas las 
                             personas. En otras palabras, busca que nadie esté por encima de los demás, abuse de 
                             su poder o cometa actos que perjudiquen a los otros. <br/><br/>
                             
                             Este objetivo se convierte en leyes y normas que ayudan a que las sociedades 
                             mantengan el orden, la armonía y el bienestar entre sus integrantes. Asimismo, que 
                             intervengan cuando sea necesario. <br/><br/>
                             
                             Piensa en tu familia, tu barrio, tu escuela, tu comunidad u otros grupos en los que 
                             participes. ¿Tienen normas, leyes o reglas? ¿Cuáles son? ¿Crees que sería buena idea 
                             tener reglamentos justos en estos espacios? ¿Por qué? <br/><br/>
                             
                             Te invitamos a conversar sobre este tema con tu familia y el resto de la clase.

                        </p>
                    </Grid>
                </Grid>

                <Grid container item xs={12} justifyContent="center" className="ContainerDerechos">
                    <p className="Derechos">
                        © 2022. Todos los derechos reservados, Pearson, Circuito de lectores.
                    </p>                
                </Grid>
        </Grid>
    </Grid>
    );
}
 
export default Qué_es_la_justicia_y_para_qué_sirve;
