import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
    ContenedorLectura:{
        width:'502px',
                  [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
    },
    ContainerTitulo:{
        margin:'0 0 34px 0',
        '& .Titulo':{
            letterSpacing: '0px',
            color: '#FC5E9E',
            margin:'0',
            textAlign:'center',
            font:'43px fredoka one',
            lineHeight:'52px',
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .TextImg':{
            color:'#717476',
            margin:'17px 0 36px 0',
            font:' 16px lato',
            lineHeight :'25px',
            letterSpacing: '0.15px',
            [theme.breakpoints.down(502)]:{
                font:' 11px lato',
                lineHeight :'14px',
                letterSpacing: '0.10px',
            },
        },
        '& .SubTitulo':{
            color:'#926DD1',
            margin:'0 0 36px 0',
            boxSizing:'border-box',
            lineHeight :'28px',
            font:'bold 26px lato',
            letterSpacing: '0.24px',
            padding:'0 0 0 46px',
            [theme.breakpoints.down(502)]:{
                font:'bold 18px lato',
                lineHeight :'24px',
                letterSpacing: '0.21px',
            },
        },
        '& .Ul':{
            color:'#000',
            margin:'0',
            display: "flex",
            padding:0,
            flexDirection: "column",
            counterReset: "numeros",
            listStyleType: "none",
            font:'18px lato',
            lineHeight :'28px',
            letterSpacing: '0.17px',
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .Li':{
            position: "relative",
            margin:'0 0 36px 0',
            boxSizing:'border-box',
            padding:'0 0 0 46px',
        },
        "& .Li:before": {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            content: "counter(numeros)",
            counterIncrement: "numeros",
            position: "absolute",
            left: "0",
            top: "0",
            background: "#F36539",
            color: "#FFF",
            borderRadius: "50% 50% 0% 50%",
            height: "35px",
            width: "34px",
            font: "21px Fredoka One",
        },  
        

        '& .Imagen':{
            width:'100%',
        },  
        

    }
}));

const Funciona_compartir = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>

            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >
                    ¿Funciona compartir?
                    </h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                      
                        <ul className="Ul">
                            <li className='Li'>
                                Imagina que en casa tienes un mueble en el que acomodas tus zapatos, similar al que 
                                te presentamos aquí. Como puedes ver, todos los pares de zapatos tienen su propio 
                                espacio.
                            </li>

                            <Grid container justifyContent="center" sm={12}>
                                <Grid container justifyContent="center" >
                                    <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img1ro/Sesion58/1.png" alt="Tarjeta" />
                                </Grid>
                                <Grid container justifyContent="center" >
                                    <p className="TextImg">
                                    Cada par de zapatos tiene su propio lugar.
                                    </p>
                                </Grid>
                            </Grid>

                            <li className='Li'>
                            Ahora piensa qué harías si compras un nuevo par de zapatos. ¿Dónde los pondrías? 
                            ¡Exacto! El nuevo par de zapatos debe compartir espacio con otros.

                            </li>

                            <h3 className='SubTitulo'>El Principio del palomar</h3>
                            
                            <li className='Li'>
                                En matemáticas, esta idea es conocida como el <em>Principio del palomar</em>. Se le llama así
                                porque fue redactada de la siguiente manera: “Si hay más palomas que nidos, 
                                entonces en al menos un nido habrá dos palomas”.
                            </li>

                            <li className='Li'>
                                Por ejemplo, aquí tenemos cinco nidos y seis palomas. Ya que no hay suficientes 
                                nidos para cada una de las palomas, dos de ellas tienen que compartir el nido.
                            </li>

                            <Grid container justifyContent="center" sm={12}>
                                <Grid container justifyContent="center" >
                                    <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img1ro/Sesion58/2.png" alt="Tarjeta" />
                                </Grid>
                                <Grid container justifyContent="center" >
                                    <p className="TextImg">
                                    De acuerdo con el Principio del palomar, dos palomas deben compartir nido.
                                    </p>
                                </Grid>
                             </Grid>

                            <li className='Li'>
                            El Principio del palomar es una herramienta matemática que funciona para 
                            demostrar diversos resultados. Recuerda: la idea es que, si tienes más elementos que 
                            opciones o características, al menos dos elementos compartirán esa opción o 
                            característica.
                            </li>
                            <li className='Li'>
                                Ahora que ya lo sabes, prueba el Principio del palomar con la siguiente situación: 
                                tienes dos calcetas azules y dos calcetas rojas. ¿Qué sucede si eliges tres calcetas sin 
                                ver? ¡Compara tu respuesta con la de tus compañeros!
                            </li>

                        </ul>
                     
                    </Grid>

                </Grid>
        </Grid>
    </Grid>
    );
}
 
export default Funciona_compartir ;
