import { makeStyles, Grid } from "@material-ui/core";
import React from "react";

import { Link } from "react-router-dom";
import AuthContext from "../../../../auth/AuthContext";
import axios from "../../../../services/axios";
import UsabilityContext from "../../../../usability/UsabilityContext";
import ContainerDocente from "../ContainerDocente";
import SearchIcon from "@material-ui/icons/Search";

const useStyles = makeStyles((theme) => ({
  Container: {
    padding: "48px 0 48px 0",
    background:
      "linear-gradient(217deg, rgba(255,194,0,0.4962185557816877) 2%, rgba(255,255,255,1) 25%, rgba(255,255,255,0.4962185557816877) 81%, rgba(144,81,177,1) 98%)",
    minHeight: "calc(100vh - 80px)",
  },
  ContainerReportes: {
    background: "#fff",
    maxWidth: "1216px",
    width: "90%",
    minHeight: "70vh",
    // maxHeight: "982px",
    borderRadius: "42px",
    padding: "30px",
    boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.5)",
    "& .Titulo": {
      font: "42px fredoka one",
      margin: "0 0 84px 34px",
    },
  },
  ContainerSerchBar: {
    gap: "10%",
    padding: "10px 0",
    justifyContent: "space-between",
    // [theme.breakpoints.up("lg")]: {
    //   height: "70px",
    // },
  },
  ContainerInput: {
    position: "relative",
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "50%",
      height: "100%",
    },
  },

  SerchBar: {
    outline: "none",
    width: "100%",
    borderRadius: "36px",
    padding: "5px 32px",
    border: "4px solid #EA9D90",
    textAlign: "left",
    letterSpacing: "0.63px",
    color: "#7A54BA",
    font: "600 16px lato",
    // margin: " 0 10%",
    [theme.breakpoints.up("lg")]: {
      height: "auto",
      font: "600 20px lato",
      padding: "10px 32px",
    },
  },
  SerchBarIcon: {
    right: "12.5px",
    top: "10.5px",
    position: "absolute",
    fontSize: "20px",
    color: "#7A54BA",
    [theme.breakpoints.up("sm")]: {
      right: "12.5px",
      top: "12.5px",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "40px",
      right: "25px",
      top: "10px",
    },
  },
  ContainerBarra: {
    height: "498px",
    boxShadow: "0 0 30px 0 rgba(76, 147, 230, 0.1)",
    margin: "0 0 20px 0",
    borderRadius: "8px",
  },

  ContainerLinkinsiginas: {
    textDecoration: "none",
    // marginLeft: "44px",
    // marginBottom: "40px",
    textAlign: "left",
    "& .ButtonIsg": {
      color: "blue",
      textTransform: "none",
      font: "18px lato",
      letterSpacing: "0.36px",
      margin: "0",
      // width: '102px',
      // height: '21px',
      // background: '#FEFAF1 0% 0% no-repeat padding-box',
      // boxShadow: '0px 1px 2px #00000029',
      // border: '1px solid #F2624F',
      // borderRadius: '10px',
      [theme.breakpoints.up("md")]: {
        font: " 20px lato",
      },
      [theme.breakpoints.up("lg")]: {
        font: " 22px lato",
      },
    },
    "& .icon": {
      color: "purple",
      marginRight: "20px",
      fontSize: "30px",
      width: "30px",
      height: "30px",
      [theme.breakpoints.up("md")]: {
        width: "40px",
        height: "40px",
        fontSize: "40px",
      },
      [theme.breakpoints.up("lg")]: {
        width: "55px",
        height: "55px",
        fontSize: "50px",
      },
    },
  },

  mainTitle: {
    font: "28px fredoka one",
    textAlign: "center",
    width: "100%",
    color: " #DC5EA6",
    [theme.breakpoints.up("sm")]: {
      width: "auto",
      // margin: "0 0 34px 14px",
    },
    [theme.breakpoints.up("md")]: {
      font: "38px fredoka one",
      width: "auto",
      // margin: "0 0 34px 14px",
    },
    [theme.breakpoints.up("lg")]: {
      font: "42px fredoka one",
    },
  },
  itemContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    padding: "20px 10px",
    gap: "15px",
    width: "100%",
    alignItems: "center",
    alignContent: "center",
    boxSizing: "border-box",
    border: "none",
    borderBottom: "1px solid #9051b1",
    [theme.breakpoints.up("lg")]: {
      flexDirection: "row",
      gap: "0px",
    },
  },
  itemButtonsContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    gap: "20px",
    // padding: "20px",
    // width: "100%",
    alignItems: "center",
    alignContent: "center",
    boxSizing: "border-box",
    width: "100%",
    [theme.breakpoints.up("lg")]: {
      justifyContent: "space-between",
      width: "auto",
      alignContent: "center",
    },
  },
  linkToUploader: {
    textDecoration: "none",
    // width: '80%',
    // width: "100%",
    maxWidth: "238px",
    // margin: "0 auto",
    // marginLeft: "44px",
    // marginBottom: "40px",
    "& .ButtonIsg": {
      // color: "blue",
      // textTransform: "none",
      // font: " 22px lato",
      // letterSpacing: "0.36px",
      // width: '102px',
      // height: '21px',
      // background: '#FEFAF1 0% 0% no-repeat padding-box',
      // boxShadow: '0px 1px 2px #00000029',
      // border: '1px solid #F2624F',
      // borderRadius: '10px',
    },
    "& .icon": {
      // color: "purple",
      // marginRight: "20px",
      // fontSize: "18px",
      margin: "0",
      [theme.breakpoints.up("lg")]: {
        // fontSize: "30px",
      },
    },
  },
  itemTittle: {
    fontSize: "20px",
    margin: 0,
    padding: 0,
    width: "100%",
    [theme.breakpoints.up("lg")]: {
      fontSize: "25px",
      width: "auto",
      textAlign: "start",
    },
  },
  PurpleButton: {
    color: "#FFFFFF",
    backgroundColor: "#9051b1",
    borderRadius: "30px",
    // margin: "0 auto",
    width: "100%",
    maxWidth: "238px",
    // minHeight: "45px",
    border: "none",
    textTransform: "unset",
    cursor: "pointer",
    fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
    fontWeight: "500",
    padding: "6px 16px",
    lineHeight: "1.75",
    transition:
      "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    boxShadow:
      "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
    "&:hover": {
      backgroundColor: "#B965D3 ",
    },
  },
  WitheButton: {
    color: "#9051b1",
    // borderColor: "#9051b1",
    border: "1px solid #9051b1",
    backgroundColor: "#FFFFFF",
    borderRadius: "30px",
    // margin: "0 auto",
    width: "100%",
    minWidth: "75px",
    maxWidth: "238px",
    // minHeight: "45px",
    textTransform: "none",
    cursor: "pointer",
    fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
    fontWeight: "500",
    padding: "6px 16px",
    lineHeight: "1.75",
    transition:
      "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    boxShadow:
      "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
    [theme.breakpoints.up("lg")]: {
      minWidth: "100px",
    },

    "&:hover": {
      backgroundColor: "#B965D330 ",
    },
  },
}));

const Sesiones = () => {
  const classes = useStyles();
  const [sesionesList, setSesionesList] = React.useState([]);
  const { groupData, handleModalMessage, handleLoading } =
    React.useContext(UsabilityContext);
  const { user } = React.useContext(AuthContext);
  const [paramsToSearch, setParamsToSearch] = React.useState("");


  React.useEffect(() => {
    const loadActivitiesList = () => {
      handleLoading(true);
      const body = {
        id_usuario: user.userId,
        id_distribucion_escuela: groupData?.id_distribucion_escuela,
      };
      axios
        .post(`listado/sesiones/listado`, body)
        .then((response) => {
          if (response.status === 200) {
            const data = response.data.resultado.sesiones;
            setSesionesList(data);
          }
        })
        .catch((error) => {
          if (error.response) {
            // Request made and server responded
            const errorData = error.response.data;
            const mensaje =
              errorData?.detalles?.msg ||
              "Ha ocurrido un error al consumir los servicios, inténtelo de nuevo más tarde.";
            handleModalMessage({
              habilite: true,
              mensaje: mensaje,
            });
          } else {
            // Something happened in setting up the request that triggered an Error
            const mensaje =
              "Ha ocurrido un error al consumir los servicios, inténtelo de nuevo más tarde.";
            handleModalMessage({
              habilite: true,
              mensaje: mensaje,
            });
          }
        })
        .finally(() => {
          handleLoading(false);
        });
    };
    loadActivitiesList();
  }, [user.userId, groupData]);

  const handleSearch = (event) => {
    setParamsToSearch(event.target.value.toLowerCase());
  };

  // console.log(sesionesList, "sesion")
  return (
    <>
      <ContainerDocente>
        <Grid
          container
          item
          sm={12}
          className={classes.Container}
          alignItems="center"
          justifyContent="center"
          id="lock"
        >
          <Grid
            container
            item
            direction="column"
            className={classes.ContainerReportes}
          >
            <Grid
              container
              item
              justifyContent="center"
              alignItems="center"
              className={classes.ContainerSerchBar}
            >
              <h2 className={classes.mainTitle}>Sesiones</h2>
              <Grid
                container
                item
                justifyContent="center"
                alignItems="center"
                className={classes.ContainerInput}
              >
                <input
                  className={classes.SerchBar}
                  type="text"
                  placeholder="Buscar actividad"
                  onChange={handleSearch}
                />
                <SearchIcon className={classes.SerchBarIcon} />
              </Grid>
            </Grid>

            {sesionesList
              ?.filter((sesion) => {
                if (paramsToSearch) {
                  return (
                    sesion.nombre.toLowerCase().startsWith(paramsToSearch) ||
                    sesion.nombre.toLowerCase().includes(paramsToSearch) ||
                    `${sesion.sesion}`.toLowerCase().startsWith(paramsToSearch)
                  );
                } else {
                  return sesion;
                }
              })
              .map((sesion) => (
                <div
                  className={classes.itemContainer}
                  key={sesion?.id_actividad}
                >
                  {sesion.tipoActividad === "Sesión" && (
                    <h3
                      className={classes.itemTittle}
                    >{`Sesión ${sesion.sesion}`}</h3>
                  )}
                  {sesion.tipoActividad.includes("Formativa") && (
                    <h3
                      className={classes.itemTittle}
                    >{`Sesión ${sesion.sesion}: ${sesion.tipoActividad}`}</h3>
                  )}
                  {sesion?.tipoActividad?.toLowerCase() === "remedial" && (
                    <h3
                      className={classes.itemTittle}
                    >{`Sesión de Práctica ${sesion.sesion}`}</h3>
                  )}
                  {sesion.tipoActividad !== "Sesión" &&
                    sesion.tipoActividad !== "Evaluación Formativa" &&
                    sesion?.tipoActividad?.toLowerCase() !== "remedial" && (
                      <h3 className={classes.itemTittle}>{`Sesión ${
                        sesion.sesion
                      }${`: ${sesion.tipoActividad}`}`}</h3>
                    )}
                  {sesion.sesion !== 1 && sesion.sesion !== 60 && (
                    <div className={classes.itemButtonsContainer}>
                      {sesion?.tipoActividad?.toLowerCase() === "remedial" && (
                        <Link
                          className={classes.linkToUploader}
                          to={{
                            pathname: `/docente/asignar_remedial/${
                              groupData?.id_distribucion_escuela
                            }/${sesion?.sesion.split(" ")[1]}/`,
                            state: { idActividad: sesion?.id_actividad },
                          }}
                        >
                          <button className={classes.WitheButton}>
                            Asignar
                          </button>
                        </Link>
                      )}

                      <Link
                        className={classes.linkToUploader}
                        to={{
                          pathname: `/docente/sesiones/detalle`,
                          state: {
                            id_grado: sesion.id_grado,
                            id_distribucion_escuela:
                              groupData?.id_distribucion_escuela,
                            id_actividad: sesion?.id_actividad,
                          },
                        }}
                      >
                        <button className={classes.WitheButton}>Ver</button>
                      </Link>
                    </div>
                  )}
                </div>
              ))}
          </Grid>
        </Grid>
      </ContainerDocente>
    </>
  );
};

export default Sesiones;
