import { Button, Grid, makeStyles } from "@material-ui/core";
import React, { useContext, useEffect } from "react";
import DrawerContext from "../../../../../usability/DrawerContext";
import { useOnLoadImages } from "../../../../hooks/useOnLoadImages";
import Loader from "../../../../Loader/Loader";
import Drawer from "../../ReadingTools/Drawer";

const useStyles =  makeStyles (theme =>({
      Contenedor:{
        background:'#E9FBF9',
        width:'100vw',
        overflowX:'clip',
        [theme.breakpoints.down(1161)]:{
            background:'none',
           overflowX:'unset',
        },
        [theme.breakpoints.down(600)]:{
            marginTop: '79.51px',
        }
    },
    ContainerInfoLectura:{
        position:'relative',
        height:'86px',
        background:'#76D4C3',
        '& .BarraInfoLectura':{
            height:'86px',
            background:`Url(https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Barras/Azul.png)`,
        },
        '& .InfoLectura':{
            position:'absolute',
            height:'86px',
            width:'219px',
            boxSizing:'border-box',
            padding:'17.2px 0',
            background:'#FAFAFA',
            borderRadius:'50px',
            boxShadow: '2px 2px 2px 2px #00000029',
            zIndex:'4',
        },
        '& .InfoLectura:before':{
            position:'absolute',
            display:'flex',
            boxSizing:'border-box',
            padding:'0 0 0 10px',
            justifyContent:'center',
            alignItems:'center',
            height:'39.88px',
            width:'39.88px',
            borderRadius:'50%',
            content:'""',
            boxShadow: '3px 0px .5px 0px #00000029',
            background:'#FAFAFA',
            left:'90px',
            top:'65px',
            transform: 'rotate(0.25turn)',
        },
        '& .InfoLectura:after':{
            content:'""',
            height:'15px',
            width:'15px',
            borderTop:'2px solid #20A698',
            borderLeft:'2px solid #20A698',
            position:'absolute',
            bottom:'-10px',
            left:'Calc(50% - 8px)',
            transform: 'rotate(0.625turn)',
        },
        '& .Sesion':{
            borderLeft:'2px solid #D8D8D8',
        },
        '& .Dato':{
            margin:'0 0 5px 0',
            textAlign:'center',
            font:'bold 17px lato',
            color:'#20A698',
        },
        '& .Caracter':{
            margin:'0',
            textAlign:'center',
            font:'bold 17px lato',
            color:'#000000',
        },
        '& .Regresar':{
            color: '#F2624F',
            border: '#F2624F 1px solid',
            height: '30px',
            width:'100px',
            borderRadius: '20px',
            letterSpacing: '0.14px',
            textTransform: 'none',
            background:'#FEFAF1',
            top:'calc(50% - 15px)',
            left:'5%',
            zIndex:4,
            [theme.breakpoints.down(502)]: {
              font:'bold 10px lato',
              width: "60px",
            },
          },

    },
    ContenedorLectura:{
        background:'#fff',
        width:'1160px',
        height:'100%',
        padding:'60px 168px 35px' ,
        
        transform: (props) =>
        props.scale > 1 ? `scale(${props.scale}) translate(0px, 10%)` : `none`,
        [theme.breakpoints.down(1161)]:{
            width:'502px',
            padding:'60px 0 0',
        },
        [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
        '& .ContainerDerechos':{
            bottom:'80px',
            [theme.breakpoints.down(1161)]:{
                display:'none',
            }
        },
        '& .Derechos':{
            textAlign:"center",
            margin:"0px",
            font:" 15px lato",
            color:"#717476",
        },
        '& .Escritorio':{
            display:'block',
            [theme.breakpoints.down(1161)]:{
                display:'none'
            },
        },
        '& .Tablet':{
            display:'none',
            [theme.breakpoints.down(1161)]:{
                display:'block'
            },
        },  
    },

    ContainerTitulo:{
        margin:'0 0 36px 0',
        [theme.breakpoints.down(1161)]:{
            margin:'0 0 38px 0',
        },
        '& .Titulo':{
            font:'48px fredoka one',
            letterSpacing: '0px',
            color: '#FF2B2B',
            margin:'0 0 15px 0',
            textAlign:'center',
            [theme.breakpoints.down(1161)]:{
                font:'43px fredoka one',
                lineHeight:'52px',
                textAlign:'center',
            },
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
        '& .SubTitulo':{
            font:'bold 30px lato',
            letterSpacing: '0px',
            color: '#FF2B2B',
            margin:'0',
            textAlign:'center',
            [theme.breakpoints.down(1161)]:{
                font:'bold 26px lato',
                letterSpacing:'.24px',
                lineHeight:'28px',
            },
            [theme.breakpoints.down(502)]:{
                font:'bold 22px lato',
                lineHeight:'24px',
            },
            [theme.breakpoints.down(350)]:{
                font:'bold 18px lato',
                lineHeight:'24px',
            },
        },
    },

    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            lineHeight : '30px',
            margin:'0 0 36px 0 ',
            font:'22px lato',
            color:'#000',
            letterSpacing: '0.20px',
            [theme.breakpoints.down(1161)]:{
                font:'18px lato',
                letterSpacing:'.17px',
                lineHeight:'28px',
            },
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .Imagen1':{
           [theme.breakpoints.down(1161)]:{
            width:'100%',
        },
        },


    },
    

}));

const La_infanta_Castile_y_el_insólito_mundo_de_Anomalía_Capitulo_3 = (props) => {

  const {activeStep, setActiveStep, handlePreviewStep, id_actividad} = props

  const handleContinue = () => {
    setActiveStep(activeStep - 1)
    handlePreviewStep()
  }

    const {
    handleSize,
    disabledZoom,
    closeZoom,
    handleZoom,
    scale
  }= useContext(DrawerContext);
  const [loading, setLoading] = React.useState(true);
  const wrapperRef = React.useRef(null);
  const imagesLoaded = useOnLoadImages(wrapperRef);

  const detectSize = () => {
    const lectura = document.getElementById("lectura");
    const newWidth = lectura?.offsetWidth;
    const newHeight = lectura?.offsetHeight;
    setLoading(false);
    if (lectura) handleSize(newHeight, newWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", detectSize);
    return () => {
      handleZoom()
      window.removeEventListener("resize", detectSize);
      closeZoom()
    };
  }, []);

  const classes = useStyles({ disabledZoom,scale });
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
             <Grid container item justifyContent="center" className={classes.ContainerInfoLectura}>
               <Grid container className="BarraInfoLectura">
                    <Button className="Regresar" onClick={handleContinue}>
                        Regresar
                    </Button>
                </Grid>
               <Grid container item className="InfoLectura">
                    <Grid container item direction="column" xs={6} justifyContent="center">
                        <p className="Dato">Grado</p>
                        <p className="Caracter">6</p>
                    </Grid>
                    <Grid container item direction="column" xs={6} justifyContent="center" className="Sesion">
                        <p className="Dato">Sesión</p>
                        <p className="Caracter">27</p>
                    </Grid>
               </Grid>
            </Grid>
{!imagesLoaded && <Loader />}
        <Grid
            container
            item
            className={classes.ContenedorLectura}
            justifyContent="center"
            id="lectura"
            onLoad={detectSize}
            ref={wrapperRef}
        >
<Drawer id_lectura={id_actividad} />
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo Escritorio" >
                        La infanta Castile y el insólito<br/>
                        mundo de Anomalía
                    </h2>
                    <h2 className="Titulo Tablet" >
                        La infanta Castile <br/>
                        y el insólito mundo <br/>
                        de Anomalía
                    </h2>
                    <h3 className='SubTitulo'>Fragmento 3 (capítulo 3)</h3>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura ">
                            … la perfectísima Minerva era muy severa con sus castigos, así que había construido 
                            una prisión especial para sus detractores. No había lugar en Anomalía más idóneo 
                            para ese fin que la Caverna de los Errores, donde ahora se encontraban. Por fuera 
                            parecía una cueva común y corriente, pero una vez adentro uno estaba condenado a 
                            fallar una y otra vez en el intento de escapar. <br/><br/>

                            —Nunca saldremos de aquí, pequeña. Será mejor que nos resignemos. Nadie puede 
                            salir y mucho menos vencer a la perfectísima Minerva. <br/><br/>

                            —Tal vez eso no sea del todo cierto —interrumpió una voz en la oscuridad. <br/><br/>

                            Y de repente una hermosa mujer salió de la penumbra. Parecía que un aura tenue 
                            brillaba a su alrededor, pero en realidad era todo su cuerpo el que resplandecía.
                             Estaba cubierta de pedazos de espejos: sus brazos, sus piernas, su rostro, todo 
                             estaba hecho de cristales.<br/><br/>

                            —¿Eres tú, Bartola? —pronunció Cardenio estupefacto. <br/><br/>

                            Luna, que apenas asimilaba la existencia de un hombre hecho de papel, creyó que una
                            mujer de espejos debía ser un sueño. <br/><br/>

                            —Soy yo, Cardenio, soy yo —respondió la mujer. <br/><br/>

                            A Cardenio se le iluminaron los ojos. Sin pensarlo, el origami se acercó a Bartola y 
                            ella lo recibió con afecto. Presenciar aquel reencuentro hizo que Luna deseara más 
                            que nunca volver a ver a sus hermanos. <br/><br/>

                            —Tienes una voz difícil de olvidar, Cardenio. Supe que eras tú tan pronto como los 
                            escuché hablar —aseguró la mujer de espejos. <br/><br/>

                            Luna se acercó a ella y pudo observarse a sí misma en los cristales que cubrían su 
                            cuerpo. Para su sorpresa, parecía que ningún espejo realmente servía, pues todos
                             mostraban un rostro distinto de ella. 
                        </p>
                    </Grid>
                    <Grid container  sm={12}>
                        <Grid container >
                            <img className="Imagen1" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion27/1.png" alt="Tarjeta" />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                            —Son las mil caras, las mil posibilidades del ser —le dijo Bartola—. Los espejos 
                            muestran más de lo que uno cree. Detrás del reflejo que todos vemos hay otras 
                            capas, otras mil posibilidades de cada persona.<br/><br/>

                            Había reflejos que Luna identificaba perfectamente, como su reflejo de la molestia, 
                            su reflejo de la risa, su reflejo del miedo. Pero había otros reflejos que le eran ajenos.<br/><br/> 

                            —Son emociones tuyas que probablemente aún no conoces, pero llegará su momento 
                            —precisó la mujer ante la curiosidad de la niña. <br/><br/>

                            —¿Qué haces aquí, Bartola? —preguntó Cardenio. <br/><br/>

                            —Me negué a ser parte de los planes de… tú sabes. Simplemente no podía ser parte 
                            de tanta maldad.<br/><br/>

                            —Busco a mis hermanos. ¿Los has visto? —interrumpió Luna. <br/><br/>

                            —No, pequeña, lo siento. Llevo mucho tiempo aquí, ya casi olvidé lo que es estar allá
                            afuera. <br/><br/>

                            La mujer de espejos se dirigió a un rincón con un dejo de nostalgia. <br/><br/>

                            —Tal vez es mejor —le dijo Cardenio para consolarla—. Afuera ahora todo es 
                            tenebroso y…<br/><br/>

                            —¿Rojizo? <br/><br/>

                            —Sí. <br/><br/>

                            Luna recordó aquel cielo rojo, su primera imagen de Anomalía, misterio del que aún 
                            no conocía la respuesta. <br/><br/>

                            —¿Por qué el cielo tiene ese color? —preguntó. <br/><br/>

                            —La perfectísima Minerva tiene el control del Fuego fatuo —aseguró la mujer—. Pero 
                            tal vez no sea demasiado tarde. <br/><br/>

                            Bartola abrió los brazos y, como si fuera un juego óptico, todo su cuerpo comenzó a 
                            brillar. Eran espejos serpenteando de un lado a otro produciendo una imagen
                            verdaderamente alucinante. En medio de ese destello de reflejos, como por arte de 
                            magia, una chica apareció detrás de Bartola. Tanto Luna como Cardenio dieron unos 
                            pasos atrás, asustados por tan repentina e inexplicable aparición. De a poco, Bartola 
                            fue deteniendo su movimiento de espejos y la chica se hizo más visible. Con sigilo,
                            Cardenio se acercó. Pareció que la reconocía.<br/><br/>

                            —Es la infanta Alondra —aseguró el origami—. Pero ¿cómo es posible?<br/><br/>

                            —Todo este tiempo la oculté —narró Bartola—. Siempre estuvo conmigo: es invisible 
                            para los ojos de los demás. Uso mi táctica de espejismo para que Minerva no la 
                            encuentre. Pero yo caí presa en este lugar, y conmigo también Alondra. <br/><br/>

                            Luna se acercó a Alondra con timidez y ella encogió los hombros temerosa. La chica 
                            debía tener unos 11 años. Era bastante delgada y de aspecto desaliñado. Sin 
                            embargo, había algo que destacaba a pesar de su deplorable aspecto, Alondra tenía 
                            unos ojos grandes y verdes, de un verde como el color de una esmeralda. Luna le 
                            tendió la mano a Alondra y ella, con recato, salió de la penumbra y se dejó iluminar 
                            con la escasez de rayos solares que se filtraban. 
                        </p>
                    </Grid>
                </Grid>
        </Grid>
        <Grid container item style={{ marginTop: 80 }}></Grid>
    </Grid>
    );
}
 
export default La_infanta_Castile_y_el_insólito_mundo_de_Anomalía_Capitulo_3;
