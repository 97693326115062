import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
     Contenedor:{
        background:'#E9FBF9',
        width:'100vw',
        overflowX:'clip',
        [theme.breakpoints.down(1161)]:{
            background:'none',
           overflowX:'unset',
        },
        [theme.breakpoints.down(600)]:{
            marginTop: '79.51px',
        }
    },
  ContainerInfoLectura: {
    position: "relative",
    height: "86px",
    [theme.breakpoints.down(502)]:{
        height: "43px",
    },
    background: "#76D4C3",
    "& .BarraInfoLectura": {
      height: "86px",
      background: `Url(https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Barras/Azul.png)`,
        [theme.breakpoints.down(502)]:{
            height: "43px",
        },
    },
    "& .InfoLectura": {
      position: "absolute",
      height: "86px",
      width: "219px",
      boxSizing: "border-box",
      padding: "17.2px 0",
      background: "#FAFAFA",
      borderRadius: "50px",
      boxShadow: "2px 2px 2px 2px #00000029",
      zIndex: "4",
        [theme.breakpoints.down(502)]:{
            height: "43px",
            width: "109px",
            padding: "8.6px 0",
            borderRadius: "25px",
        },
    },
    "& .InfoLectura:before": {
      position: "absolute",
      display: "flex",
      boxSizing: "border-box",
      padding: "0 0 0 10px",
      justifyContent: "center",
      alignItems: "center",
      height: "39.88px",
      width: "39.88px",
      borderRadius: "50%",
      content: '""',
      boxShadow: "3px 0px .5px 0px #00000029",
      background: "#FAFAFA",
      left: "90px",
      top: "65px",
      transform: "rotate(0.25turn)",
      [theme.breakpoints.down(502)]:{
        left: "45px",
        top: "30px",
        height: "20.88px",
        width: "20.88px",
        },
    },
    "& .InfoLectura:after": {
      content: '""',
      height: "15px",
      width: "15px",
      borderTop: "2px solid #20A698",
      borderLeft: "2px solid #20A698",
      position: "absolute",
      bottom: "-10px",
      left: "Calc(50% - 8px)",
      transform: "rotate(0.625turn)",
      [theme.breakpoints.down(502)]:{
        height: "10px",
        width: "10px",
        left: "Calc(50% - 4.5px)",
        bottom: "-3px",
        borderTop: "1px solid #20A698",
        borderLeft: "1px solid #20A698",
        },
    },
    "& .Sesion": {
      borderLeft: "2px solid #D8D8D8",
    },
    "& .Dato": {
      margin: "0 0 5px 0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#20A698",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        margin: "0 0 2.5px 0",
        },
    },
    "& .Caracter": {
      margin: "0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#000000",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        },
    },   },
    ContenedorLectura:{
        background:'#fff',
        width:'1160px',
        height:'100%',
        padding:'60px 168px 35px ',
        [theme.breakpoints.down(1161)]:{
            width:'502px',
            padding:'60px 0 0 0',
          },
        [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
        '& .ContainerDerechos':{
            bottom:'80px',
            [theme.breakpoints.down(1161)]:{
                display:'none',
            },
        },
        '& .Derechos':{
            textAlign:"center",
            margin:"0px",
            font:" 15px lato",
            color:"#717476",
        },
        '& .Escritorio':{
            display:'block',
            [theme.breakpoints.down(1161)]:{
                display:'none'
            },
        },
        '& .Tablet':{
            display:'none',
            [theme.breakpoints.down(1161)]:{
                display:'block'
            },
        }
    },
    ContainerTitulo:{
        margin:'0 0 34px 0',
        [theme.breakpoints.down(1161)]:{
            margin:'0 0 34px 0',
        },
        '& .Titulo':{
            font:'48px fredoka one',
            letterSpacing: '0px',
            lineHeight:'58px',
            color: '#F6644B',
            margin:'0',
            textAlign:'center',
            [theme.breakpoints.down(1161)]:{
                font:'43px fredoka one',
                lineHeight:'52px',
            },
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            lineHeight : '30px',
            margin:'0',
            font:'22px lato',
            color:'#000',
            letterSpacing: '0.20px',
            [theme.breakpoints.down(1161)]:{
                font:'18px lato',
                lineHeight :'28px',
                letterSpacing: '0.17px',
            },
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },

        '& .Imagen':{
            [theme.breakpoints.down(1161)]:{
                width:'508px',
            },
            [theme.breakpoints.down(502)]:{
                width:'100%',
            },
        },  
        '& .Imagen2':{
            [theme.breakpoints.down(1161)]:{
                width:'568px',
            },
            [theme.breakpoints.down(502)]:{
                width:'100%',
            },
        },  

      
    }
}));

const Las_rayas_no_hacen_al_tigre = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
             <Grid container item justifyContent="center" className={classes.ContainerInfoLectura}>
               <Grid container className="BarraInfoLectura"></Grid>
               <Grid container item className="InfoLectura">
                    <Grid container item direction="column" xs={6} justifyContent="center">
                        <p className="Dato">Grado</p>
                        <p className="Caracter">1</p>
                    </Grid>
                    <Grid container item direction="column" xs={6} justifyContent="center" className="Sesion">
                        <p className="Dato">Sesión</p>
                        <p className="Caracter">19</p>
                    </Grid>
               </Grid>
            </Grid>

            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                        <h2 className="Titulo Escritorio">Las rayas no hacen al tigre</h2>
                        <h2 className="Titulo Tablet">Las rayas<br/> no hacen al tigre</h2>


                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                       <p className='Lectura'>
                            “¡Riiiiiiiing!” Sonó el despertador. Para Rodrigo era hora de rugir:<br/><br/>
                            
                            —Oarghhhhhh. <br/><br/>
                            
                            Abrió la boca y fue al espejo a revisar sus colmillos. “¡Cada día están más grandes!”,
                            pensó orgulloso. Luego corrió hacia la sala, persiguiendo una presa imaginaria. <br/><br/>
                            
                            —Ven a desayunar —le gritó su mamá.<br/><br/>
                            
                            Rodrigo brincó a la mesa. <br/><br/>
                            
                            —¡Los tigres no comemos verduras! <br/><br/>
                            
                            —¡Pues a mí me encantan! —intervino su hermana. <br/><br/>
                            
                            —Oarghhhhhh —dijo Rodrigo estirando sus patas. <br/><br/>
                            
                            —¡Mira qué garras! —exclamó su mamá—. Tenemos que cortártelas ahora. <br/><br/>
                            
                             —¡No quiero! ¡Soy un tigre!<br/><br/>
                            
                            —Hasta los tigres deben limarse las uñas —contestó su papá.
                       </p>                   
                    </Grid>
                    <Grid container justifyContent='center'>
                        <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img1ro/Sesion19/1.png" alt="Tarjeta" />
                    </Grid>
                    
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container >
                        <p className='Lectura'>
                            “¡Ding dong!”, se escuchó el timbre. Rodrigo aprovechó para escapar. <br/><br/>
                            
                            Se escondió bajo el sillón. ¡Él era un tigre y se tenía que comportar como tal! ¿Por 
                            qué su familia le decía que cambiara sus rayas? <br/><br/>
                            
                            Después de unos minutos, escuchó a su abuelita entrar. <br/><br/>
                            
                            —Rodrigo —empezó a llamarlo. Pero él permanecía escondido—. ¿Qué pasa?<br/><br/>
                            
                            —Abuela, ¿por qué no me dejan ser como tengo que ser? <br/><br/>
                            
                            —Nadie ha dicho que los tigres se tienen que comportar de cierta manera. Tú decides
                            qué rayas quieres y cuáles no. Lo importante es que seas tú mismo. <br/><br/>
                            
                            Rodrigo salió. Se limpió las lágrimas. <br/><br/>
                            
                            —¡Ahí está mi pequeño felino! —exclamó la abuelita—. Lo que no sé es si los tigres 
                            comen pastel. <br/><br/>
                            
                            —Este tigre sí —respondió Rodrigo mientras le daba un abrazo.
                        </p>
                    </Grid>
                    <Grid container justifyContent='center'>
                        <img className="Imagen2" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img1ro/Sesion19/2.png" alt="Tarjeta" />
                    </Grid>
                </Grid>
                <Grid container item xs={12} justifyContent="center" className="ContainerDerechos">
                    <p className="Derechos">
                        © 2022. Todos los derechos reservados, Pearson, Circuito de lectores.
                    </p>                
                </Grid>
            </Grid>
    </Grid>
    );
}
 
export default Las_rayas_no_hacen_al_tigre;
