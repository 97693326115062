import { Button, Grid, makeStyles } from "@material-ui/core";
import React, { useContext, useEffect } from "react";
import DrawerContext from "../../../../../usability/DrawerContext";
import { useOnLoadImages } from "../../../../hooks/useOnLoadImages";
import Loader from "../../../../Loader/Loader";
import Drawer from "../../ReadingTools/Drawer";

const useStyles = makeStyles((theme) => ({
  Contenedor: {
    background: "#E9FBF9",
    width: "100vw",
    overflowX: "clip",
    [theme.breakpoints.down(1161)]: {
      background: "none",
      overflowX: "unset",
    },
    [theme.breakpoints.down(600)]: {
      marginTop: "79.51px",
    },
  },
  ContainerInfoLectura: {
    position: "relative",
    height: "86px",
    [theme.breakpoints.down(502)]: {
      height: "43px",
    },
    background: "#76D4C3",
    "& .BarraInfoLectura": {
      height: "86px",
      background: `Url(https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Barras/Azul.png)`,
      [theme.breakpoints.down(502)]: {
        height: "43px",
      },
    },
    "& .InfoLectura": {
      position: "absolute",
      height: "86px",
      width: "219px",
      boxSizing: "border-box",
      padding: "17.2px 0",
      background: "#FAFAFA",
      borderRadius: "50px",
      boxShadow: "2px 2px 2px 2px #00000029",
      zIndex: "4",
      [theme.breakpoints.down(502)]: {
        height: "43px",
        width: "109px",
        padding: "8.6px 0",
        borderRadius: "25px",
      },
    },
    "& .InfoLectura:before": {
      position: "absolute",
      display: "flex",
      boxSizing: "border-box",
      padding: "0 0 0 10px",
      justifyContent: "center",
      alignItems: "center",
      height: "39.88px",
      width: "39.88px",
      borderRadius: "50%",
      content: '""',
      boxShadow: "3px 0px .5px 0px #00000029",
      background: "#FAFAFA",
      left: "90px",
      top: "65px",
      transform: "rotate(0.25turn)",
      [theme.breakpoints.down(502)]: {
        left: "45px",
        top: "30px",
        height: "20.88px",
        width: "20.88px",
      },
    },
    "& .InfoLectura:after": {
      content: '""',
      height: "15px",
      width: "15px",
      borderTop: "2px solid #20A698",
      borderLeft: "2px solid #20A698",
      position: "absolute",
      bottom: "-10px",
      left: "Calc(50% - 8px)",
      transform: "rotate(0.625turn)",
      [theme.breakpoints.down(502)]: {
        height: "10px",
        width: "10px",
        left: "Calc(50% - 4.5px)",
        bottom: "-3px",
        borderTop: "1px solid #20A698",
        borderLeft: "1px solid #20A698",
      },
    },
    "& .Sesion": {
      borderLeft: "2px solid #D8D8D8",
    },
    "& .Dato": {
      margin: "0 0 5px 0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#20A698",
      [theme.breakpoints.down(502)]: {
        font: "bold 12px lato",
        margin: "0 0 2.5px 0",
      },
    },
    "& .Caracter": {
      margin: "0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#000000",
      [theme.breakpoints.down(502)]: {
        font: "bold 12px lato",
      },
    },
    "& .Regresar": {
      color: "#F2624F",
      border: "#F2624F 1px solid",
      height: "30px",
      width: "100px",
      borderRadius: "20px",
      letterSpacing: "0.14px",
      textTransform: "none",
      background: "#FEFAF1",
      top: "calc(50% - 15px)",
      left: "5%",
      zIndex: 4,
      [theme.breakpoints.down(502)]: {
        font: "bold 10px lato",
        width: "60px",
      },
    },
  },
  ContenedorLectura: {
    background: "#fff",
    width: "1161px",
    height: "100%",
    transform: (props) =>
      props.scale > 1 ? `scale(${props.scale}) translate(0px, 10%)` : `none`,
    [theme.breakpoints.down(1161)]: {
      width: "768px",
    },
    [theme.breakpoints.down(768)]: {
      width: "350px",
    },
    "& .Derechos": {
      position: "absolute",
      textAlign: "center",
      margin: "0px",
      font: " 15px lato",
      color: "#F8F8F8",
      bottom: "79px",
      [theme.breakpoints.down(1161)]: {
        display: "none",
      },
    },
    "& .Escritorio": {
      display: "block",
      [theme.breakpoints.down(1161)]: {
        display: "none",
      },
    },
    "& .Tablet": {
      display: "none",
      [theme.breakpoints.down(1161)]: {
        display: "block",
      },
    },
  },

  Parrafo1: {
    position: "relative",
    [theme.breakpoints.down(768)]: {
      display: "none",
    },
    "& .Br": {
      margin: "0 0 24px",
      [theme.breakpoints.down(1161)]: {
        margin: "0 0 12px",
      },
      [theme.breakpoints.down(768)]: {
        margin: "0 0 4px",
      },
    },
    "& .Pos": {
      position: "absolute  ",
    },

    "& .SubTitulo": {
      margin: "0",
      color: "#000000",
      font: "33px Fredoka One",
      lineHeight: "40px",
      top: "13.72%",
      left: "15.76%",
      [theme.breakpoints.down(1161)]: {
        font: "23px Fredoka One",
        lineHeight: "27px",
      },
      [theme.breakpoints.down(768)]: {
        font: "11px Fredoka One",
        lineHeight: "12px",
      },
    },
    "& .SubTitulo2": {
      margin: "0",
      color: "#FFFFFF",
      font: "33px Fredoka One",
      lineHeight: "40px",
      left: "22.03%",
      [theme.breakpoints.down(1161)]: {
        font: "22px Fredoka One",
        lineHeight: "27px",
      },
      [theme.breakpoints.down(768)]: {
        font: "10px Fredoka One",
        lineHeight: "12px",
      },
    },
    "& .Texto": {
      margin: "0",
      color: "#000000",
      font: "bold 22px lato",
      lineHeight: "27px",
      letterSpacing: ".21px",
      [theme.breakpoints.down(1161)]: {
        font: "bold 18px lato",
        lineHeight: "22px",
        letterSpacing: ".17px",
      },
      [theme.breakpoints.down(768)]: {
        font: "bold 8px lato",
        lineHeight: "10px",
        letterSpacing: ".10px",
      },
    },
    "& .Texto2": {
      margin: "0",
      color: "#000000",
      font: "bold 22px lato",
      lineHeight: "27px",
      letterSpacing: ".21px",
      [theme.breakpoints.down(1161)]: {
        font: "bold 15px lato",
        lineHeight: "19px",
        letterSpacing: ".15px",
      },
      [theme.breakpoints.down(768)]: {
        font: "bold 6px lato",
        lineHeight: "8.5px",
        letterSpacing: ".10px",
      },
    },
    "& .Text": {
      top: "17.72%",
      left: "15.76%",
      width: "663px",
      [theme.breakpoints.down(1161)]: {
        width: "57.94%",
      },
    },
    "& .Sub1": {
      top: "29.75%",
    },
    "& .Sub2": {
      top: "48.24%",
    },
    "& .Sub3": {
      top: "68.01%",
    },
    "& .Sub4": {
      top: "85.65%",
    },
    "& .ul": {
      color: "#926DD1",
      margin: 0,
      padding: "0",
      left: "23.60%",
      [theme.breakpoints.down(768)]: {
        fontSize: "7px",
      },
      [theme.breakpoints.down(350)]: {
        fontSize: "5px",
      },
    },
    "& .li": {
      margin: "0 0 8px 0",
      [theme.breakpoints.down(1161)]: {
        margin: "0 0 4px 0",
      },
      [theme.breakpoints.down(768)]: {
        margin: "0 ",
      },
    },
    "& .ul1": {
      top: "33.95%",
      width: "723px",
      [theme.breakpoints.down(1161)]: {
        width: "67.49%",
      },
      [theme.breakpoints.down(768)]: {
        top: "33.50%",
      },
      [theme.breakpoints.down(350)]: {
        top: "34.50%",
      },
    },
    "& .ul2": {
      top: "52.63%",
      width: "671px",
      [theme.breakpoints.down(1161)]: {
        width: "57%",
      },
      [theme.breakpoints.down(768)]: {
        top: "52%",
      },
      [theme.breakpoints.down(350)]: {
        top: "52.5%",
      },
    },
    "& .ul3": {
      top: "72.43%",
      width: "717px",
      [theme.breakpoints.down(1161)]: {
        width: "66%",
      },
      [theme.breakpoints.down(768)]: {
        top: "72%",
      },
      [theme.breakpoints.down(350)]: {
        top: "72.5%",
      },
    },
    "& .ul4": {
      top: "90.31%",
      width: "673px",
      [theme.breakpoints.down(1161)]: {
        width: "60.2%",
      },
      [theme.breakpoints.down(768)]: {
        top: "90%",
      },
    },

    "& .Imagen": {
      width: "100%",
    },
  },
  Imagen2: {
    width: "100%",
    display: "none",
    [theme.breakpoints.down(768)]: {
      width: "100%",
      display: "flex",
    },
  },
}));

const Deportivo_santa_ana = (props) => {

  const {activeStep, setActiveStep, handlePreviewStep, id_actividad} = props
  
  const handleContinue = () => {
    setActiveStep(activeStep - 1)
    handlePreviewStep()
  }

  const {
    handleSize,
    disabledZoom,
    closeZoom,
    handleZoom,
    scale
  }= useContext(DrawerContext);
  const [loading, setLoading] = React.useState(true);
  const wrapperRef = React.useRef(null);
  const imagesLoaded = useOnLoadImages(wrapperRef);

  const detectSize = () => {
    const lectura = document.getElementById("lectura");
    const newWidth = lectura?.offsetWidth;
    const newHeight = lectura?.offsetHeight;
    setLoading(false);
    if (lectura) handleSize(newHeight, newWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", detectSize);
    return () => {
      handleZoom()
      window.removeEventListener("resize", detectSize);
      closeZoom()
    };
  }, []);

  const classes = useStyles({ disabledZoom,scale });
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
             <Grid container item justifyContent="center" className={classes.ContainerInfoLectura}>
                       <Grid container className="BarraInfoLectura">
            <Button className="Regresar" onClick={handleContinue}>
                Regresar
            </Button>
        </Grid>
               <Grid container item className="InfoLectura">
                    <Grid container item direction="column" xs={6} justifyContent="center">
                        <p className="Dato">Grado</p>
                        <p className="Caracter">4</p>
                    </Grid>
                    <Grid container item direction="column" xs={6} justifyContent="center" className="Sesion">
                        <p className="Dato">Sesión</p>
                        <p className="Caracter">34</p>
                    </Grid>
               </Grid>
            </Grid>
            {!imagesLoaded && <Loader />}
        <Grid
            container
            item
            className={classes.ContenedorLectura}
            justifyContent="center"
            id="lectura"
            onLoad={detectSize}
            ref={wrapperRef}
        >
<Drawer id_lectura={id_actividad} />
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img4to/Sesion34/1.png"  alt="Tarjeta" />
                
                <h3 className='Pos SubTitulo'>
                    Reglamento para el uso de la alberca
                </h3> 
                <p className='Pos Texto Text'>
                    A todos los usuarios: <div className='Br'></div>
                    
                    A continuación, se enlistan las reglas de las instalaciones acuáticas
                    del Deportivo Santa Ana.
                </p>

                <h3 className='Pos SubTitulo2 Sub1'>
                    Requerimientos de acceso a la zona de la alberca
                </h3> 
                <h3 className='Pos SubTitulo2 Sub2'>
                    Uso de las instalaciones
                </h3> 
                <h3 className='Pos SubTitulo2 Sub3'>
                    Convivencia
                </h3>
                <h3 className='Pos SubTitulo2 Sub4'>
                    Sanciones
                </h3>
                <ul className='Pos ul ul1'>
                    <li className='li'>
                        <p className='Texto2'>
                            Ingresar con ropa acuática deportiva. No están permitidos otros tipos de trajes de baño.
                        </p>
                    </li>
                    <li className='li'>
                        <p className='Texto2'>
                            Por seguridad, es obligatorio portar sandalias que no se resbalen.
                        </p>
                    </li>
                    <li className='li'>
                        <p className='Texto2'>
                            Tomar una ducha antes de entrar al agua.
                        </p>
                    </li>
                    <li className='li'>
                        <p className='Texto2'>
                            Evitar esta actividad si se han consumido alimentos dos horas antes.
                        </p>
                    </li>
                    <li className='li'>
                        <p className='Texto2'>
                            No introducir comida, bebidas, mochilas u objetos de vidrio.
                        </p>
                    </li>
                </ul>

                <ul className='Pos ul ul2'>
                    <li className='li'>
                        <p className='Texto2'>
                            Los niños menores de 12 años no pueden ingresar sin la supervisión permanente de un adulto.
                        </p>
                    </li>
                    <li className='li'>
                        <p className='Texto2'>
                            No se permiten colchones flotantes. Los balones y/o pelotas sólo pueden tomarse con permiso 
                            de un instructor.
                        </p>
                    </li>
                    <li className='li'>
                        <p className='Texto2'>
                            Cuidar los separadores de carriles y otros materiales en el agua. La persona que destruya el 
                            material pagará su costo en la caja.
                        </p>
                    </li>
                    <li className='li'>
                        <p className='Texto2'>
                            No practicar clavados sin la vigilancia de un instructor.
                        </p>
                    </li>
                </ul>

                <ul className='Pos ul ul3'>
                    <li className='li'>
                        <p className='Texto2'>
                            No interferir en las áreas designadas para las clases de natación.
                        </p>
                    </li>
                    <li className='li'>
                        <p className='Texto2'>
                            El supervisor hará sonar un silbato en caso de una emergencia. Todas las personas dentro 
                            de la alberca tendrán que abandonarla de inmediato al escucharlo y seguir sus indicaciones 
                            para evacuar el edificio.
                        </p>
                    </li>
                    <li className='li'>
                        <p className='Texto2'>
                            El cupo máximo es de 40 personas. Si se alcanza el límite, se tendrá que esperar a que alguien 
                            salga para poder ingresar.
                        </p>
                    </li>
                </ul>

                <ul className='Pos ul ul4'>
                    <li className='li'>
                        <p className='Texto2'>
                            La persona que no siga las reglas será obligada a abandonar el lugar. En caso de ameritarlo, se verá forzada a pagar una multa.
                        </p>
                    </li>
                </ul>
                </Grid>
                <img className={classes.Imagen2} src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img4to/Sesion34/F.png"  alt="Tarjeta" />
        </Grid>
        <Grid container item style={{ marginTop: 80}} ></Grid>
    </Grid>
    );
}
 
export default Deportivo_santa_ana;
