import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
    ContenedorLectura:{
        width:'502px',
                  [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
    },
    ContainerTitulo:{
        margin:'0 0 34px 0',
        '& .Titulo':{
            letterSpacing: '0px',
            color: '#E13832',
            margin:'0',
            textAlign:'center',
            font:'43px fredoka one',
            lineHeight:'52px',
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            margin:'0 0 36px 0',
            color:'#000',
            font:'18px lato',
            lineHeight :'28px',
            letterSpacing: '0.17px',
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .TextImg':{
            color:'#717476',
            margin:'21px 0 0 0',
            font:' 16px lato',
            lineHeight :'25px',
            letterSpacing: '0.15px',
            [theme.breakpoints.down(502)]:{
                font:' 11px lato',
                lineHeight :'14px',
                letterSpacing: '0.10px',
            },
        },
        '& .Imagen':{
            width:'100%',
        },   
    }
}));


const La_historia_de_los_libros = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >La historia de los libros</h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container sm={12}>
                      <p className="Lectura "> 
                        Los libros no siempre han sido como los conocemos. El primer registro que se tiene 
                        de un instrumento parecido son las tablillas de arcilla que utilizaba el pueblo sumerio 
                        de la antigua Mesopotamia. Tenían un tipo de escritura llamado <em>cuneiforme</em>, que 
                        consistía en hacer grabados con una cuña sobre el barro que después se dejaban 
                        secar.
                      </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <Grid container item justifyContent='center'>
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img4to/Sesion07/1.png" alt="Tarjeta" />
                        </Grid>
                        <Grid container item justifyContent='center'>
                            <p className='TextImg'>Los libros eran obras de arte antes de la invención de la imprenta, cada uno requería largas horas de trabajo para su creación.</p>
                        </Grid>
                    </Grid>
                </Grid>
               
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container sm={12}>
                      <p className="Lectura "> 
                        El material comenzó a parecerse al papel con el paso del tiempo. En el antiguo Egipto 
                        se hacían hojas de una planta conocida como <em>papiro</em>. La pulpa de la planta se cortaba 
                        en tiras y luego las pegaban todas juntas. Los extremos de los papiros estaban 
                        enrollados en palos de madera que llegaban a medir 40 metros. Escribían sobre esta 
                        superficie con la punta de un cáñamo, pero duraba poco tiempo, por lo que se 
                        buscaron métodos más resistentes.<br/><br/> 
                        
                        Un material hecho de piel de animales se usó por primera vez en la ciudad griega de 
                        Pérgamo. Se elaboraba quemando, limpiando y estirando la piel. Las hojas 
                        resultantes duraban más que el delgado <em>papiro</em>, por lo que su uso perduró durante 
                        siglos. Pero tenía un inconveniente: las hojas no podían unirse. <br/><br/>
                        
                        Por otro lado, la palabra <em>códice</em> proviene del término en latín <em>codex</em>. Se trata de un 
                        objeto hecho con hojas de pergamino, que tenía texto escrito por ambos lados. El 
                        conjunto estaba protegido por una cubierta de madera o por varios pergaminos
                        endurecidos. <br/><br/>
                        
                        Los monjes de la Edad Media escribían toda clase de información en los códices, 
                        desde documentos antiguos hasta textos sagrados. Ellos conservaron el 
                        conocimiento en estos ejemplares y gracias a ello tenemos un amplio conocimiento 
                        del pasado. <br/><br/>
                        
                        También elaboraban libros con ilustraciones de colores, que decoraban con 
                        materiales valiosos como el oro y la plata. Se trataba generalmente de textos 
                        personalizados para alguien importante. Contenían rezos, salmos y muchas 
                        ilustraciones de escenas cristianas. El mundo medieval es conocido en gran parte 
                        gracias a estos objetos. <br/><br/>
                        
                        Pero la máxima revolución de los materiales de lectura llegó con el papel. El método 
                        de elaboración de este material se desarrolló en la antigua China. Y más tarde 
                        también se logró llevar a cabo un proceso de impresión. Éste consistía en tallar 
                        palabras en una especie de sellos que eran bloques de madera. Después los 
                        sumergían en tinta y los colocaban sobre el papel. <br/><br/>
                        
                        Posteriormente, se cambió la madera por arcilla y, en Europa, un herrero llamado 
                        Johannes Gutenberg utilizó el metal, creando así la imprenta moderna. El primer 
                        libro producido con este nuevo sistema fue la Biblia. <br/><br/>
                        
                        La imprenta ocasionó que fabricar un libro pasara de requerir meses a sólo unos días.
                        El italiano Aldus Pius Manutius tuvo la idea de hacer los sellos más pequeños y de 
                        esta manera conseguir ejemplares de lectura que fueran transportables. <br/><br/>
                        
                        En la actualidad, los libros evolucionaron también y ahora están disponibles en 
                        formato digital. Pero no sólo ellos, ¡también las tablillas antiguas! Tenemos a nuestro 
                        alcance mucho conocimiento gracias al desarrollo de estos objetos a lo largo de la
                        historia.
                      </p>
                    </Grid>
                </Grid>
        </Grid>
    </Grid>
    );
}
 
export default La_historia_de_los_libros;
