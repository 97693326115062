import { makeStyles, Grid } from "@material-ui/core";
import React from "react";
import { Link } from "react-router-dom";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import SearchIcon from "@material-ui/icons/Search";
// import StudentsList from "./StudentsList";
import UsersTable from "./UsersTable";
import { useParams } from "react-router-dom";
// import UsabilityContext from "../../../../../usability/UsabilityContext";
// import AuthContext from "../../../../../auth/AuthContext";
// import { useHistory } from "react-router-dom";
import ContainerAdmin from "../ContainerAdmin";
import UsabilityContext from "../../../../usability/UsabilityContext";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    minHeight: "92vh",
    padding: "48px 0 48px 0",
    background:
      "linear-gradient(217deg, rgba(255,194,0,0.4962185557816877) 2%, rgba(255,255,255,1) 25%, rgba(255,255,255,0.4962185557816877) 81%, rgba(144,81,177,1) 98%)",
    // [theme.breakpoints.up(1920)]:{
    //     height:'92vh',
    // },
    // [theme.breakpoints.down(1920)]:{
    //     height:'92vh',
    // },
    // [theme.breakpoints.down(1811)]:{
    //     height:'1150px',
    // },
    // [theme.breakpoints.down(1487)]:{
    //     height:'1430px',
    // },
    // background: 'linear-gradient(217deg, rgba(255,194,0,0.4962185557816877) 2%, rgba(255,255,255,1) 25%, rgba(255,255,255,0.4962185557816877) 81%, rgba(144,81,177,1) 98%)',
    // [theme.breakpoints.down(1280)]:{
    //     background:'none',

    // },
  },
  boxContainer: {
    background: "#fff",
    width: "90%",
    gap: "20px",
    maxWidth: "1216px",
    maxHeight: "1000px",
    borderRadius: "42px",
    padding: "30px",
    boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.5)",
  },
  titleContainer: {
    // height: "60px",
    gap: "10px",
  },
  title: {
    font: "32px fredoka one",
    margin: "0 ",
    width: "100%",
    textAlign: "center",
    color: "#DC5EA6",
    [theme.breakpoints.up("lg")]: {
      // height: "31px",
      // width: "150px",
      fontSize: "42px",
    },
  },
  link: {
    cursor: "pointer",
    textDecoration: "none",
    height: "31px",
    width: "190px",
  },
  backButton: {
    cursor: "pointer",
    // height: "35px",
    // width: "200px",
    backgroundColor: "#FDEFF7",
    color: "#DC5EA6",
    border: "#DC5EA6 1px solid",
    borderRadius: "20px",
    textTransform: "none",
    font: "20px Lato",
    letterSpacing: "0.14px",
    display: "flex",
    alignItems: "center",

    // justifyContent: "space-around",
    [theme.breakpoints.down("770")]: {
      // height: "31px",
      // width: "150px",
      font: "16px Lato",
    },
  },

  addButton: {
    cursor: "pointer",
    height: "35px",
    width: "180px",
    backgroundColor: "white",
    color: "#9051b1",
    border: "#9051b1 1px solid",
    borderRadius: "20px",
    textTransform: "none",
    font: "20px Lato",
    letterSpacing: "0.14px",
    display: "flex",
    alignItems: "center",
    textDecoration: "none",
    margin: 0,
    // justifyContent: "space-around",
    [theme.breakpoints.down("770")]: {
      // height: "31px",
      // width: "150px",
      font: "16px Lato",
    },
  },

  ContainerSerchBar: {
    display: "grid",
    gridTemplateColumns: "1fr",
    gridTemplateRows: "1fr",
    justifyItems: "center",
    alignItems: "center",
    gap: "10px",
    [theme.breakpoints.up("sm")]: {
      gridTemplateRows: "1fr",
      gridTemplateColumns: "1fr",
      justifyItems: "center",
    },
  },
  ContainerInput: {
    position: "relative",
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      height: "100%",
    },
    [theme.breakpoints.up("720")]: {
      width: "75%",
    },
    [theme.breakpoints.up("md")]: {
      width: "50%",
    },
  },

  SerchBar: {
    outline: "none",
    width: "100%",
    borderRadius: "36px",
    padding: "5px 32px",
    border: "4px solid #EA9D90",
    textAlign: "left",
    letterSpacing: "0.63px",
    color: "#7A54BA",
    font: "600 20px lato",
    // margin: " 0 10%",
    [theme.breakpoints.up("lg")]: {
      height: "auto",
      padding: "10px 32px",
    },
  },
  SerchBarIcon: {
    right: "23px",
    // top: "25px",
    position: "absolute",
    fontSize: "20px",
    color: "#7A54BA",
  },
  studentListContainer: {},

  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },

  returnButton: {
    cursor: "pointer",
    textDecoration: "none",
    // height: "31px",
    // width: "190px",
    [theme.breakpoints.down("770")]: {
      // height: "31px",
      // width: "150px",
    },
  },
}));

const StudentsInfo = () => {
  const classes = useStyles();
  const {id}  = useParams()
  // const { user } = React.useContext(AuthContext);
  const { handleSearch, search, clearSearch } = React.useContext(UsabilityContext);
  React.useEffect(() => {
    return () => {
      clearSearch();
    };
  }, [clearSearch]);

  // const [grade, setGrade] = React.useState("");
  // const history = useHistory();
  // const selectChange = (event) => {
  //   setGrade(event.target.value);
  //   handleGroupToEdit(event.target.value);
  // };

  return (
    <ContainerAdmin>
      <Grid
        container
        item
        sm={12}
        alignItems="center"
        justifyContent="center"
        className={classes.mainContainer}
      >
        <Grid container item direction="column" className={classes.boxContainer}>
          <Grid
            container
            direction="row"
            className={classes.titleContainer}
            alignItems="center"
            justifyContent="center"
          >
            <Grid container direction="row" alignItems="center" justifyContent="flex-start">
              <Link className={classes.returnButton} to="/admin-inst/perfil">
                <button className={classes.backButton}>
                  <ArrowBackIcon /> Perfil
                </button>
              </Link>
            </Grid>
            <h2 className={classes.title}>Usuarios</h2>
          </Grid>
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="center"
            className={classes.ContainerSerchBar}
          >
            <Grid
              container
              item
              justifyContent="center"
              alignItems="center"
              className={classes.ContainerInput}
            >
              <input
                className={classes.SerchBar}
                type="text"
                placeholder="Buscar usuario"
                onChange={handleSearch}
                value={search}
              />
              <SearchIcon className={classes.SerchBarIcon} />
            </Grid>
          </Grid>

          <Grid
            container
            className={classes.studentListContainer}
            justifyContent="center"
            alignItems="center"
          >
            {/* <StudentsList/> */}
            <UsersTable userId={id} />
          </Grid>
        </Grid>
      </Grid>
    </ContainerAdmin>
  );
};

export default StudentsInfo;
