import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
    ContenedorLectura:{
        width:'502px',
                  [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
    },
    ContainerTitulo:{
        margin:'0 0 34px 0',
        '& .Titulo':{
            letterSpacing: '0px',
            color: '#A4216C',
            margin:'0',
            textAlign:'center',
            font:'43px fredoka one',
            lineHeight:'52px',
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            margin:'0',
            color:'#000',
            font:'18px lato',
            lineHeight :'28px',
            letterSpacing: '0.17px',
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .Imagen':{
            width:'100%',
        },  
    }, 
      
}));

const Encima_de_mí_sólo_las_estrellas = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >
                        Encima de mí, <br/>sólo las estrellas
                    </h2>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container xs={12} >
                        <p className="Lectura Lectura2">
                        Soy el cuarto de cuatro hijos que tuvieron mis padres. Nací en la ciudad, en un 
                        hospital moderno, entre sábanas y paredes blancas. Cuando mi padre nació no hubo 
                        hospital, aunque sí sábanas blancas.<br/><br/> 
                        
                        Antes pensaba que todos nacíamos de la misma forma, pero no. Mi padre y mi madre 
                        nacieron en la casa de mis abuelos, Y en lugar de doctores los trajeron al mundo 
                        parteras.
                        </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12} >
                        <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img1ro/Sesion21R/1.png" alt="Tarjeta" />
                    </Grid>
                </Grid>

                <Grid container sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}  justifyContent='center'>
                        <p className="Lectura Lectura2">
                        —Los tiempos cambian —me dicen. <br/><br/>
                        
                        —¿Cómo nacieron mis abuelos? —pregunto yo. <br/><br/>
                        
                        Entonces me entero de que el padre de mi padre nació en un barco que venía de 
                        África. Estalló la tormenta en alta mar, y al sonido del primer relámpago mi bisabuela 
                        dio a luz a mi abuelo.
                        </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12} >
                        <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img1ro/Sesion21R/2.png" alt="Tarjeta" />
                    </Grid>
                </Grid>

                <Grid container sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}  justifyContent='center'>
                        <p className="Lectura Lectura2">
                            —De él heredamos el color de piel, el cabello rizado y el gusto por el baile, porque a 
                            tu abuelo lo arrullaron las olas —dice orgulloso mi padre.<br/><br/>
                            
                            Me voy a dormir. Antes de acostarme, en un cuaderno dibujo un árbol, el árbol de mi 
                            familia. Pero lo hago al revés: en éste yo soy la rama más alta. Encima de mí, sólo las 
                            estrellas. ¿Y la raíz? La raíz está en la Tierra.
                        </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12} >
                        <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img1ro/Sesion21R/3.png" alt="Tarjeta" />
                    </Grid>
                </Grid>
        </Grid>
    </Grid>
    );
}
 
export default Encima_de_mí_sólo_las_estrellas;
