import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
    ContenedorLectura:{
        width:'502px',
                  [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
    },
    ContainerTitulo:{
        margin:'0 0 16px 0',
        '& .Titulo':{
            textAlign:'center',
            letterSpacing: '0px',
            color: '#80C6AF',
            margin:'0',
            font:'43px fredoka one',
            lineHeight:'52px',
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
        
    },
    Parrafo1:{
        borderBottom: '2px dashed #80C6AF',
        border:'0 0 1px 0 solid',
        padding:' 0',
        height:'300px',
        [theme.breakpoints.down(502)]:{
            height:'250px',
        },
        '& .Lectura':{
            margin:'0',
            color:'#000',
            font:'18px lato',
            lineHeight :'28px',
            letterSpacing: '0.17px',
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .Lectura1':{
            width:'310px',
            [theme.breakpoints.down(502)]:{
                width:'200px',

            },
        },
        '& .Lectura2':{
            width:'263px',
            [theme.breakpoints.down(502)]:{
                width:'200px',
            },

        },
        '& .Subtitulo':{
            color:'#F37C6E',
            font:'21px Fredoka One',
            lineHeight:'45px',
            [theme.breakpoints.down(502)]:{
                font:'18px Fredoka One',
                lineHeight:'35px',
            }
        },
        '& .Subtitulo2':{
            color:'#8DB5A8',
            font:'21px Fredoka One',
            lineHeight:'45px',
            [theme.breakpoints.down(502)]:{
                font:'18px Fredoka One',
                lineHeight:'35px',
            }
        },
        '& .Imagen1':{
            height:'166px',
            width:'166px',
            [theme.breakpoints.down(502)]:{
                height:'106px',
                width:'106px'
            },
        },

        '& .TextImg':{
            margin:'22px 0 0 0',
            font:'20px lato',
            alignSelf:'flex-start',
            color:'#717476',
        },
    },
    Parrafo:{
        borderBottom: 'none',
    },

    Parrafo3:{
        margin:'0 0 67px 0',
        '& .Imagen2':{
            width:'100%'
        },
    },
}));

const Ratón_de_campo_y_Ratón_de_ciudad = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item xs={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >
                        Ratón de campo<br/> y Ratón de ciudad
                    </h2>
                </Grid>

                <Grid container item xs={12} className={classes.Parrafo1} alignItems="center" >

                    <Grid container xs={4}>
                        <Grid container xs={12} justifyContent="center" >
                            <img className="Imagen1" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img1ro/Sesion29/1.png" alt="Tarjeta" />
                        </Grid>
                    </Grid>

                    <Grid container justifyContent="flex-end" xs={8}>
                    <p className="Lectura Lectura1">
                      <b className="Subtitulo" >Ratón de campo</b><br/>  
                      —¿Y de verdad te gusta la ciudad? Hay mucho tráfico, ruido y 
                      contaminación. Hay gatos que se esconden en las sombras. 
                      ¿Por qué no vienes a vivir al campo? Comes lo que te da la 
                      tierra y no necesitas nada más.
                      </p>
                    </Grid>

                </Grid>

                <Grid container item xs={12} className={classes.Parrafo1} alignItems="center" justifyContent="center">

                    <Grid container justifyContent="center" xs={8}>
                        <p className="Lectura Lectura1">
                        <b className="Subtitulo2" >Ratón de ciudad</b><br/>  
                        —Bueno, en realidad hay más cosas en la ciudad. No todo es  
                        malo. Me gusta salir y divertirme, ir al teatro y a restaurantes. 
                        </p>
                    </Grid>

                    <Grid container xs={4}>
                        <Grid container justifyContent="center" >
                            <img className="Imagen1" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img1ro/Sesion29/2.png" alt="Tarjeta" />
                        </Grid>
                    </Grid>

                </Grid>

                <Grid container item xs={12} className={classes.Parrafo1} alignItems="center" justifyContent="center">

                    <Grid container xs={4}>
                        <Grid container justifyContent="center" >
                            <img className="Imagen1" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img1ro/Sesion29/1.png" alt="Tarjeta" />
                        </Grid>
                    </Grid>

                    <Grid container justifyContent="flex-end" xs={8}>
                        <p className="Lectura Lectura1">
                        <b className="Subtitulo" >Ratón de campo</b><br/>  
                        —Pues acá no sentimos nada de presión. A mí no me gusta la  
                        ciudad. Aunque admito que, a veces, me doy una vuelta para  
                        conocer los museos y hasta ir a bailar. Pero vivir ahí, ¡ni  
                        pensarlo!
                        </p>
                    </Grid>

                </Grid>

                <Grid container item xs={12} className={classes.Parrafo1} alignItems="center" justifyContent="center">

                    <Grid container  xs={8}>
                        <p className="Lectura Lectura2">
                        <b className="Subtitulo2" >Ratón de ciudad</b> <br/> 
                        —Yo tampoco sé si podría vivir en el campo, pero sí disfruto  
                        cuando lo visito. Sentir el olor de los árboles, ver el  
                        horizonte, oír los sonidos queditos y armoniosos de la  
                        naturaleza. Aunque creo que extrañaría las comodidades  
                        de la ciudad.
                        </p>
                    </Grid>

                    <Grid container xs={4}>
                        <Grid container justifyContent="center" >
                            <img className="Imagen1" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img1ro/Sesion29/2.png" alt="Tarjeta" />
                        </Grid>
                    </Grid>

                </Grid>

                <Grid container item xs={12} className={`${classes.Parrafo1} ${classes.Parrafo}`} alignItems="center" justifyContent="center">

                    <Grid container xs={4}>
                        <Grid container justifyContent="center" >
                            <img className="Imagen1" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img1ro/Sesion29/1.png"alt="Tarjeta" />
                        </Grid>
                    </Grid>

                    <Grid container justifyContent="flex-end" xs={8}>
                        <p className="Lectura Lectura1">
                        <b className="Subtitulo" >Ratón de campo</b> <br/> 
                        —Está bien, está bien. Cada uno de nosotros necesita 
                        distintas cosas. Pero, ahora que lo pienso, podemos 
                        visitarnos y así disfrutar del campo y de la ciudad.
                        </p>
                    </Grid>

                </Grid>
                <Grid container item xs={12} className={classes.Parrafo3} alignItems="center" justifyContent="center">
                    <img className="Imagen2" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img1ro/Sesion29/3.png" alt="Tarjeta" />
                </Grid>
        </Grid>
    </Grid>
    );
}
 
export default Ratón_de_campo_y_Ratón_de_ciudad;
