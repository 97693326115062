import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
    ContenedorLectura:{
        width:'502px',
                  [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
    },
    ContainerTitulo:{
        margin:'0 0 34px 0',
        '& .Titulo':{
            letterSpacing: '0px',
            color: '#0F90FF',
            margin:'0',
            textAlign:'center',
            font:'43px fredoka one',
            lineHeight:'52px',
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
            margin:'0 0 36px 0',
            color:'#000',
            font:'18px lato',
            lineHeight :'28px',
            letterSpacing: '0.17px',
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        '& .Imagen':{
            width:'100%',
        },   
    }
}));


const La_misión_de_las_nubes = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>

            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >La misión de las nubes</h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container sm={12}>
                      <p className="Lectura"> 
                        Esa mañana el rey despertó con particular energía. Para comenzar el día, se dirigió a 
                        sus sirvientes. Millones de esferitas que formaban una cobija muy fina encima de un 
                        lago se sintieron inquietas cuando el monarca se acercó a donde dormían.<br/><br/> 
                      
                        —¡Despierten, queridas!<br/><br/> 

                        —¿No podemos descansar un ratito más?<br/><br/> 
                        
                        —Vamos, tienen una gran misión que cumplir. <br/><br/> 
                        
                        La energía del rey provocó el movimiento de las esferitas, cada vez con mayor
                        rapidez. Debido a la velocidad que alcanzaron, empezaron a brincar alto, muy alto. La 
                        generala Dropy se levantó del lago y se dirigió al resto de la comunidad: <br/><br/> 
                        
                        —El rey supremo ordena que abandonemos la comodidad del lago. Para cumplir con 
                        la misión que nos ha encomendado, utilizaremos la energía que el gran monarca nos 
                        proporciona e intentaremos alcanzar el cielo. Arriba nos agruparemos y 
                        esperaremos nuevas órdenes, ¿entendido? <br/><br/> 
                        
                        La generala Dropy formó equipos y les pidió que se abrigaran, pues esperaba que en 
                        las alturas se sintiera mucho frío. Las esferitas cerraron filas y comenzaron a cumplir 
                        el mandato.
                      </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img3ro/Sesion16/1.png" alt="Tarjeta" />
                    </Grid>
                </Grid>
               
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container sm={12}>
                      <p className="Lectura "> 
                        —Jefe, ¿cuál será nuestra próxima misión? —preguntó la supervisora Cloudy, 
                        mientras se agrupaban en el cielo. <br/><br/> 
                        
                        —En realidad, tenemos muchas tareas por cumplir. Pero de momento vamos a 
                        emprender un viaje a través de esos canales de aire. <br/><br/> 
                        
                        Tomadas de los brazos, las esferitas se divirtieron con el ir y venir del viento. 
                        Además, en ese lugar podían disfrutar el espectáculo de las montañas y de los ríos.<br/><br/> 
                        
                        Minutos más tarde, la generala Dropy recibió un mensaje del monarca. Sin dar más 
                        detalles, informó al grupo que debían dividirse en tres. Ella quedaría a cargo del 
                        conjunto que se dejaría caer sobre la superficie terrestre, su objetivo era formar ríos 
                        o traspasar el suelo para reunirse debajo de la tierra. <br/><br/> 
                        
                        Cloudy dirigiría al equipo encargado de formar un escudo para proteger a los 
                        habitantes de la Tierra de la intensa energía que emite el rey. Supervisó que las 
                        esferitas estuvieran muy juntas, con el propósito de evitar el paso de cualquier rayo.<br/><br/> 

                        La generala Dropy formó otro grupo con la misión de mantener el calor cuando el rey 
                        durmiera, para evitar que la energía que mandó el día anterior se escapara. Su misión 
                        era mantener fresco el día y templada la noche. <br/><br/> 
                        
                        Cada grupo se puso a trabajar hasta entrada la noche. El rey se complació del 
                        desempeño de las esferitas y por fin las mandó a descansar.
                      </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img3ro/Sesion16/2.png" alt="Tarjeta" />
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container sm={12}>
                      <p className="Lectura "> 
                        —¡Despierten, queridas!<br/><br/> 
                        
                        —¿No podemos descansar un ratito más? <br/><br/> 
                        
                        —Vamos, tienen una gran misión que cumplir. Un día más. <br/><br/> 
                        
                        Y las esferitas comenzaron de nuevo a brincar, a brincar tan alto como pudieron 
                        llegar.
                      </p>
                    </Grid>
                </Grid>
        </Grid>
    </Grid>
    );
}
 
export default La_misión_de_las_nubes;
