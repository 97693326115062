import { Button, Grid,makeStyles}              from '@material-ui/core';
import React, { useContext, useEffect } from "react";
import DrawerContext from "../../../../../usability/DrawerContext";
import { useOnLoadImages } from "../../../../hooks/useOnLoadImages";
import Loader from "../../../../Loader/Loader";
import Drawer from "../../ReadingTools/Drawer";

const useStyles = makeStyles((theme) => ({
  Contenedor: {
    background: "#E9FBF9",
    width: "100vw",
    overflowX: "clip",
    [theme.breakpoints.down(1161)]: {
      background: "none",
      overflowX: "unset",
    },
    [theme.breakpoints.down(600)]: {
      marginTop: "79.51px",
    },
  },
  ContainerInfoLectura: {
    position: "relative",
    height: "86px",
    [theme.breakpoints.down(502)]: {
      height: "43px",
    },
    background: "#76D4C3",
    "& .BarraInfoLectura": {
      height: "86px",
      background: `Url(https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Barras/Azul.png)`,
      [theme.breakpoints.down(502)]: {
        height: "43px",
      },
    },
    "& .InfoLectura": {
      position: "absolute",
      height: "86px",
      width: "219px",
      boxSizing: "border-box",
      padding: "17.2px 0",
      background: "#FAFAFA",
      borderRadius: "50px",
      boxShadow: "2px 2px 2px 2px #00000029",
      zIndex: "4",
      [theme.breakpoints.down(502)]: {
        height: "43px",
        width: "109px",
        padding: "8.6px 0",
        borderRadius: "25px",
      },
    },
    "& .InfoLectura:before": {
      position: "absolute",
      display: "flex",
      boxSizing: "border-box",
      padding: "0 0 0 10px",
      justifyContent: "center",
      alignItems: "center",
      height: "39.88px",
      width: "39.88px",
      borderRadius: "50%",
      content: '""',
      boxShadow: "3px 0px .5px 0px #00000029",
      background: "#FAFAFA",
      left: "90px",
      top: "65px",
      transform: "rotate(0.25turn)",
      [theme.breakpoints.down(502)]: {
        left: "45px",
        top: "30px",
        height: "20.88px",
        width: "20.88px",
      },
    },
    "& .InfoLectura:after": {
      content: '""',
      height: "15px",
      width: "15px",
      borderTop: "2px solid #20A698",
      borderLeft: "2px solid #20A698",
      position: "absolute",
      bottom: "-10px",
      left: "Calc(50% - 8px)",
      transform: "rotate(0.625turn)",
      [theme.breakpoints.down(502)]: {
        height: "10px",
        width: "10px",
        left: "Calc(50% - 4.5px)",
        bottom: "-3px",
        borderTop: "1px solid #20A698",
        borderLeft: "1px solid #20A698",
      },
    },
    "& .Sesion": {
      borderLeft: "2px solid #D8D8D8",
    },
    "& .Dato": {
      margin: "0 0 5px 0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#20A698",
      [theme.breakpoints.down(502)]: {
        font: "bold 12px lato",
        margin: "0 0 2.5px 0",
      },
    },
    "& .Caracter": {
      margin: "0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#000000",
      [theme.breakpoints.down(502)]: {
        font: "bold 12px lato",
      },
    },
    "& .Regresar": {
      color: "#F2624F",
      border: "#F2624F 1px solid",
      height: "30px",
      width: "100px",
      borderRadius: "20px",
      letterSpacing: "0.14px",
      textTransform: "none",
      background: "#FEFAF1",
      top: "calc(50% - 15px)",
      left: "5%",
      zIndex: 4,
      [theme.breakpoints.down(502)]: {
        font: "bold 10px lato",
        width: "60px",
      },
    },
  },
  ContenedorLectura: {
    background: "#fff",
    width: "1160px",
    height: "100%",
    padding: "0",
    transform: (props) =>
      props.scale > 1 ? `scale(${props.scale}) translate(0px, 10%)` : `none`,
    [theme.breakpoints.down(1161)]: {
      width: "768px",
    },
    [theme.breakpoints.down(768)]: {
      width: "350px",
    },
    "& .Derechos": {
      position: "absolute",
      bottom: "95px",
      textAlign: "center",
      margin: "0px",
      font: " 15px lato",
      color: "#FFFFFF",
      [theme.breakpoints.down(1161)]: {
        display: "none",
      },
    },
    "& .Escritorio": {
      display: "block",
      [theme.breakpoints.down(1161)]: {
        display: "none",
      },
    },
    "& .Tablet": {
      display: "none",
      [theme.breakpoints.down(1161)]: {
        display: "block",
      },
    },
  },
  Parrafo1: {
    position: "relative",
    [theme.breakpoints.down(768)]: {
      display: "none",
    },
    "& .Titulo": {
      position: "absolute",
      font: "95px fredoka one",
      letterSpacing: "0px",
      lineHeight: "115px",
      color: "#FFFFFF",
      margin: "0",
      top: "0.49%",
      left: "35.60%",
      [theme.breakpoints.down(1161)]: {
        font: "63px fredoka one",
        lineHeight: "76px",
      },
      [theme.breakpoints.down(768)]: {
        font: "28px fredoka one",
        lineHeight: "32px",
      },
    },
    "& .Titulo2": {
      position: "absolute",
      font: "50px fredoka one",
      letterSpacing: "0px",
      lineHeight: "61px",
      color: "#FFFFFF",
      margin: "0",
      top: "1.92%",
      left: "35.25%",
      [theme.breakpoints.down(1161)]: {
        font: "33px fredoka one",
        lineHeight: "40px",
      },
      [theme.breakpoints.down(768)]: {
        font: "16px fredoka one",
        lineHeight: "18px",
      },
    },
    "& .SubTitulo": {
      position: "absolute",
      font: "bold 32px lato",
      textAlign: "center",
      color: "#000000",
      lineHeight: "44px",
      margin: "0",
      width: "60.34%",
      top: "3.15%",
      [theme.breakpoints.down(1161)]: {
        font: "bold 21px lato",
        lineHeight: "29px",
      },
      [theme.breakpoints.down(768)]: {
        font: "bold 8.5px lato",
        lineHeight: "13px",
      },
    },
    "& .SubTitulo2": {
      position: "absolute",
      font: "39px Fredoka One",
      textAlign: "center",
      color: "#FFFFFF",
      lineHeight: "47px",
      margin: "0",
      [theme.breakpoints.down(1161)]: {
        font: "26px Fredoka One",
        lineHeight: "31px",
      },
      [theme.breakpoints.down(768)]: {
        font: "14px Fredoka One",
        lineHeight: "16px",
      },
    },
    "& .SubTitulo3": {
      position: "absolute",
      font: "bold 22px lato",
      textAlign: "center",
      color: "#000000",
      lineHeight: "30px",
      margin: "0",
      width: "59.31%",
      top: "23.14%",
      [theme.breakpoints.down(1161)]: {
        font: "bold 18px lato",
        lineHeight: "28px",
        width: "73.44%",
      },
      [theme.breakpoints.down(768)]: {
        font: "bold 8px lato",
        lineHeight: "12px",
      },
    },
    "& .Ingre": {
      top: "5%",
    },
    "& .Uten": {
      top: "21.57%",
    },
    "& .Lectura": {
      position: "absolute",
      margin: "0 ",
      color: "#000",
      fontFamili: "lato",
      fontSize: "22px",
      lineHeight: "30px",
      letterSpacing: "0.20px",
      [theme.breakpoints.down(1161)]: {
        fontSize: "16px",
        lineHeight: "23px",
        letterSpacing: "0.15px",
      },
      [theme.breakpoints.down(768)]: {
        fontSize: "6px",
        lineHeight: "8px",
        letterSpacing: "0.09px",
      },
    },
    "& .Rosa": {
      color: "#B4486C",
    },
    "& .Cafe": {
      color: "#70330D",
    },
    "& .Lect": {
      top: "7.30%",
      left: "48.88%",
      width: "377px",
      [theme.breakpoints.down(1161)]: {
        width: "42.71%",
      },
    },
    "& .Lect-1": {
      left: "17.5%",
    },
    "& .Lect-2": {
      left: "60%",
    },
    "& .Lect2": {
      top: "16.78%",
    },
    "& .Lect3": {
      top: "17.99%",
    },
    "& .Lect4": {
      top: "19.15%",
    },
    "& .Lect5": {
      top: "16.59%",
    },
    "& .Lect6": {
      top: "17.87%",
    },
    "& .Lect7": {
      top: "34.34%",
    },
    "& .Lect8": {
      top: "35.38%",
    },
    "& .Lect9": {
      top: "36.65%",
    },
    "& .Lect10": {
      top: "38.10%",
    },
    "& .Lect11": {
      top: "39.38%",
    },
    "& .Lect12": {
      top: "34.34%",
    },
    "& .Lect13": {
      top: "35.58%",
    },
    "& .Lect14": {
      top: "36.82%",
    },
    "& .Lect15": {
      top: "38.10%",
    },
    "& .Mod": {
      top: "42.03%",
    },
    "& .Lect16": {
      textAlign: "center",
      top: "48.32%",
      left: "60.20%",
      [theme.breakpoints.down(1161)]: {
        left: "59%",
      },
    },
    "& .Lect17": {
      textAlign: "center",
      top: "52.31%",
      left: "10.77%",
      [theme.breakpoints.down(1161)]: {
        left: "9.77%",
      },
    },
    "& .Lect18": {
      textAlign: "center",
      top: "56.20%",
      left: "59.57%",
      [theme.breakpoints.down(1161)]: {
        left: "58.57%",
      },
    },
    "& .Lect19": {
      textAlign: "center",
      top: "61.07%",
      left: "8.69%",
      [theme.breakpoints.down(1161)]: {
        left: "7.69%",
      },
    },
    "& .Lect20": {
      textAlign: "center",
      top: "64.99%",
      left: "56.12%",
      [theme.breakpoints.down(1161)]: {
        left: "55.12%",
      },
    },
    "& .Lect21": {
      textAlign: "center",
      top: "69.61%",
      left: "12.13%",
      [theme.breakpoints.down(1161)]: {
        left: "11.13%",
      },
    },
    "& .Lect22": {
      textAlign: "center",
      top: "73.67%",
      left: "59.05%",
      [theme.breakpoints.down(1161)]: {
        left: "58.05%",
      },
    },
    "& .Lect23": {
      textAlign: "center",
      top: "80.14%",
      left: "6.89%",
      [theme.breakpoints.down(1161)]: {
        left: "5.89%",
      },
    },
    "& .Lect24": {
      textAlign: "center",
      top: "85.8%",
      left: "58.70%",
      [theme.breakpoints.down(1161)]: {
        left: "57.70%",
      },
    },
    "& .Lect25": {
      textAlign: "center",
      top: "91.53%",
      left: "66.89%",
      [theme.breakpoints.down(1161)]: {
        left: "65.89%",
      },
    },

    "& .Imagen": {
      [theme.breakpoints.down(1161)]: {
        width: "100%",
      },
    },
  },

  Imagen: {
    display: "none",
    [theme.breakpoints.down(768)]: {
      width: "100%",
      display: "flex",
    },
  },
}));

const Receta_chocobolitas = (props) => {

  const {activeStep, setActiveStep, handlePreviewStep, id_actividad} = props

  const handleContinue = () => {
    setActiveStep(activeStep - 1)
    handlePreviewStep()
  }

  const {
    handleSize,
    disabledZoom,
    closeZoom,
    handleZoom,
    scale
  }= useContext(DrawerContext);
  const [loading, setLoading] = React.useState(true);
  const wrapperRef = React.useRef(null);
  const imagesLoaded = useOnLoadImages(wrapperRef);

  const detectSize = () => {
    const lectura = document.getElementById("lectura");
    const newWidth = lectura?.offsetWidth;
    const newHeight = lectura?.offsetHeight;
    setLoading(false);
    if (lectura) handleSize(newHeight, newWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", detectSize);
    return () => {
      handleZoom()
      window.removeEventListener("resize", detectSize);
      closeZoom()
    };
  }, []);

  const classes = useStyles({ disabledZoom,scale });
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
             <Grid container item justifyContent="center" className={classes.ContainerInfoLectura}>
                       <Grid container className="BarraInfoLectura">
            <Button className="Regresar" onClick={handleContinue}>
                Regresar
            </Button>
        </Grid>
               <Grid container item className="InfoLectura">
                    <Grid container item direction="column" xs={6} justifyContent="center">
                        <p className="Dato">Grado</p>
                        <p className="Caracter">2</p>
                    </Grid>
                    <Grid container item direction="column" xs={6} justifyContent="center" className="Sesion">
                        <p className="Dato">Sesión</p>
                        <p className="Caracter">43</p>
                    </Grid>
               </Grid>
            </Grid>
            {!imagesLoaded && <Loader />}
            <Grid
            container
            item
            className={classes.ContenedorLectura}
            justifyContent="center"
            id="lectura"
            onLoad={detectSize}
            ref={wrapperRef}
            >
            <Drawer id_lectura={id_actividad} />
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    
                        <img className="Imagen Escritorio" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img2do/Sesion43/1.png" alt="Tarjeta" />
                        <img className="Imagen Tablet" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img2do/Sesion43/1R.png" alt="Tarjeta" />
                        <h2 className='Titulo'>
                            Receta
                        </h2>
                        <h2 className='Titulo2'>
                            Chocobolitas
                        </h2>
                        <p className='SubTitulo'>
                            Esta receta está calculada para 10 porciones.
                            Tiempo de preparación aproximado: 20 minutos.
                        </p>
                        <h3 className='SubTitulo2 Ingre'>
                            Ingredientes:
                        </h3>
                        <h3 className='SubTitulo2 Uten'>
                            Utensilios
                        </h3>
                        <p className='Lectura Lect Escritorio'>
                            <b className='Rosa'>
                            Las galletas marías son dulces y bajas 
                            en azúcar, están hechas de trigo y no 
                            tienen colorantes.</b>  Se caracterizan por 
                            su ligero sabor a vainilla, y por su 
                            forma redonda y plana. Son bastante 
                            económicas.
                        </p>
                        <p className='Lectura Lect Tablet'>
                            <b className='Rosa'>
                            Las galletas marías son dulces y bajas<br/> 
                            en azúcar, están hechas de trigo y no <br/>
                            tienen colorantes.</b>  Se caracterizan por<br/>
                            su ligero sabor a vainilla, y por su forma<br/>
                            redonda y plana. Son bastante económicas.
                        </p>
                        <p className='Lectura Lect2 Lect-1'>
                           <b>300 g de galletas </b>marías.
                        </p>
                        <p className='Lectura Lect3 Lect-1'>
                           <b>200 ml de leche </b>condensada.
                        </p>
                        <p className='Lectura Lect4 Lect-1'>
                           <b>100 g de cocoa</b>  en polvo para<br/> 
                           espolvorear y decorar.
                        </p>
                        <p className='Lectura Lect5 Lect-2'>
                           <b>100 g de uva pasa, pasa o</b><br/>  
                           mitades de nueces trituradas.
                        </p>
                        <p className='Lectura Lect6 Lect-2'>
                           <b>200 g de chocolate </b>semiamargo<br/> 
                            o amargo.
                        </p>
                        <p className='SubTitulo3'>
                            Observa bien los utensilios. Si no los tienes en casa, piensa con cuáles podrías sustituirlos. Pídele a un adulto que te oriente.
                        </p>
                        <p className='Lectura Lect7 Lect-1'>
                            <b>Horno</b> de microondas.
                        </p>
                        <p className='Lectura Lect8 Lect-1'>
                            <b>Tazón grande</b> para mezclar los<br/>
                             ingredientes.
                        </p>
                        <p className='Lectura Lect9 Lect-1'>
                            <b>Recipiente</b> de ½ l o 1 l apto para<br/>
                             horno de microondas.
                        </p>
                        <p className='Lectura Lect10 Lect-1'>
                            <b>Rodillo</b> de cocina.
                        </p>
                        <p className='Lectura Lect11 Lect-1'>
                            <b>Pala</b> de repostería
                        </p>
                        <p className='Lectura Lect12 Lect-2'>
                            <b>Vaso</b> con agua potable.
                        </p>
                        <p className='Lectura Lect13 Lect-2'>
                            <b>Bolsa</b> de plástico nueva o limpia.
                        </p>
                        <p className='Lectura Lect14 Lect-2'>
                            <b>Cuchara</b> sopera.    
                        </p>
                        <p className='Lectura Lect15 Lect-2'>
                            <b>2 platos</b> grandes. 
                        </p>
                        <h3 className='SubTitulo2 Mod'>
                            Modo de preparación
                        </h3>
                        <p className='Lectura Lect16'>
                           <b className='Cafe'>
                            Vacía las galletas en la bolsa<br/> 
                            de plástico y ciérrala. </b>Tritura<br/> 
                            las galletas con ayuda del rodillo<br/> 
                            hasta hacerlas polvo.
                        </p>
                        <p className='Lectura Lect17'>
                           <b className='Cafe'>
                            Vierte el polvo de galleta en el tazón<br/> 
                            junto con la leche condensada.</b><br/> 
                            Puedes usar dulce de leche<br/> 
                            en lugar de leche condensada<br/> 
                            (en la misma cantidad).
                        </p>
                        <p className='Lectura Lect18'>
                            Con ayuda de un adulto, <b className='Cafe'>coloca<br/>  
                            el chocolate en el recipiente apto<br/>  
                            para microondas y ponlo a calentar<br/>  
                            durante 20 segundos </b> <br/> 
                            o hasta que se derrita.
                        </p>
                        <p className='Lectura Lect19'>
                           <b className='Cafe'>
                            Saca el chocolate derretido y déjalo<br/> 
                            enfriar a temperatura ambiente. </b><br/> 
                            Ten cuidado: tanto el recipiente como<br/> 
                            el chocolate estarán calientes.
                        </p>
                        <p className='Lectura Lect20'>
                            Espera a que el chocolate se haya<br/> 
                            enfriado lo suficiente como para que puedas<br/>
                            tomar el recipiente. <b className='Cafe'>Vacíalo a la mezcla de<br/> 
                            polvo de galleta y leche condensada.</b><br/> 
                            Retira todo el chocolate del recipiente<br/> 
                            con ayuda de la pala de repostería.
                        </p>
                        <b className='Lectura Cafe Lect21'>
                            Mezcla los tres ingredientes<br/> 
                            con la pala.
                        </b>
                        <p className='Lectura Lect22'>
                            Cuando los ingredientes estén<br/> 
                            perfectamente mezclados,<br/> 
                            <b className='Cafe'>lava tus manos y humedécelas<br/> 
                            con agua potable</b> para que la mezcla<br/> 
                            no se pegue. Luego, <b className='Cafe'>pon un poco<br/> 
                            de la mezcla en una de tus manos,<br/> 
                            aplástala, coloca pasas o nueces<br/> 
                            al gusto en el centro y forma<br/> 
                            una bolita.</b> Repite este proceso hasta<br/> 
                            que se acabe la mezcla.
                        </p>
                        <p className='Lectura Lect23'>
                            <b className='Cafe'>Coloca cada bolita en el plato grande</b><br/>  
                            sin que se peguen entre sí. <b className='Cafe'>Toma<br/>  
                            un poco de cocoa en polvo<br/>  
                            con la cuchara sopera y espolvoréala<br/>  
                            sobre las bolitas</b> dando ligeros golpes.<br/>  
                            Asegúrate de voltearlas todas para<br/>  
                            que queden completamente cubiertas<br/>  
                            de cocoa.
                        </p>
                        <p className='Lectura Lect24'>
                        <b className='Cafe'>
                            Coloca las bolitas espolvoreadas<br/> 
                            en el segundo plato grande<br/> 
                            y refrigéralas por 30 minutos.</b> Pasado<br/> 
                            ese tiempo, ¡estarán listas para servir!
                        </p>
                        <p className='Lectura Lect25'>
                            <b className='Rosa'>
                            Puedes sustituir la cocoa<br/> 
                            con la confitería de tu<br/> 
                            agrado,</b> ralladura de coco<br/> 
                            o trocitos de cacahuate.<br/>
                        </p>
                        
                    
                </Grid>
                <img className={classes.Imagen} src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img2do/Sesion43/F4.png" alt="Tarjeta" />

        </Grid>
        <Grid container item style={{ marginTop: 80}} ></Grid>
    </Grid>
    );
}
 
export default Receta_chocobolitas;
