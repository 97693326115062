import { Button, Grid, makeStyles } from "@material-ui/core";
import React, { useContext, useEffect } from "react";
import DrawerContext from "../../../../../usability/DrawerContext";
import { useOnLoadImages } from "../../../../hooks/useOnLoadImages";
import Loader from "../../../../Loader/Loader";
import Drawer from "../../ReadingTools/Drawer";


const useStyles =  makeStyles (theme =>({
     Contenedor:{
        background:'#E9FBF9',
        width:'100vw',
        overflowX:'clip',
        [theme.breakpoints.down(1161)]:{
            background:'none',
           overflowX:'unset',
        },
        [theme.breakpoints.down(600)]:{
            marginTop: '79.51px',
        }
    },
  ContainerInfoLectura: {
    position: "relative",
    height: "86px",
    [theme.breakpoints.down(502)]:{
        height: "43px",
    },
    background: "#76D4C3",
    "& .BarraInfoLectura": {
      height: "86px",
      background: `Url(https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Barras/Azul.png)`,
        [theme.breakpoints.down(502)]:{
            height: "43px",
        },
    },
    "& .InfoLectura": {
      position: "absolute",
      height: "86px",
      width: "219px",
      boxSizing: "border-box",
      padding: "17.2px 0",
      background: "#FAFAFA",
      borderRadius: "50px",
      boxShadow: "2px 2px 2px 2px #00000029",
      zIndex: "4",
        [theme.breakpoints.down(502)]:{
            height: "43px",
            width: "109px",
            padding: "8.6px 0",
            borderRadius: "25px",
        },
    },
    "& .InfoLectura:before": {
      position: "absolute",
      display: "flex",
      boxSizing: "border-box",
      padding: "0 0 0 10px",
      justifyContent: "center",
      alignItems: "center",
      height: "39.88px",
      width: "39.88px",
      borderRadius: "50%",
      content: '""',
      boxShadow: "3px 0px .5px 0px #00000029",
      background: "#FAFAFA",
      left: "90px",
      top: "65px",
      transform: "rotate(0.25turn)",
      [theme.breakpoints.down(502)]:{
        left: "45px",
        top: "30px",
        height: "20.88px",
        width: "20.88px",
        },
    },
    "& .InfoLectura:after": {
      content: '""',
      height: "15px",
      width: "15px",
      borderTop: "2px solid #20A698",
      borderLeft: "2px solid #20A698",
      position: "absolute",
      bottom: "-10px",
      left: "Calc(50% - 8px)",
      transform: "rotate(0.625turn)",
      [theme.breakpoints.down(502)]:{
        height: "10px",
        width: "10px",
        left: "Calc(50% - 4.5px)",
        bottom: "-3px",
        borderTop: "1px solid #20A698",
        borderLeft: "1px solid #20A698",
        },
    },
    "& .Sesion": {
      borderLeft: "2px solid #D8D8D8",
    },
    "& .Dato": {
      margin: "0 0 5px 0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#20A698",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        margin: "0 0 2.5px 0",
        },
    },
    "& .Caracter": {
      margin: "0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#000000",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        },
    },
       '& .Regresar':{
        color: '#F2624F',
        border: '#F2624F 1px solid',
        height: '30px',
        width:'100px',
        borderRadius: '20px',
        letterSpacing: '0.14px',
        textTransform: 'none',
        background:'#FEFAF1',
        top:'calc(50% - 15px)',
        left:'5%',
        zIndex:4,
        [theme.breakpoints.down(502)]: {
          font:'bold 10px lato',
          width: "60px",
        },
      },
  },
    ContenedorLectura:{
        background:'#fff',
        width:'1160px',
        height:'100%',
                padding:'60px 168px 35px ',
        
        transform: (props) =>
        props.scale > 1 ? `scale(${props.scale}) translate(0px, 10%)` : `none`,
        [theme.breakpoints.down(1161)]:{
            width:'502px',
            padding:'60px 0 0 0',
          },
        [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },

        '& .Escritorio':{
            display:'block',
            [theme.breakpoints.down(1161)]:{
                display:'none'
            },
        },
        '& .Tablet':{
            display:'none',
            [theme.breakpoints.down(1161)]:{
                display:'block'
            },
        }
    },
    ContainerTitulo:{
        margin:'0 0 34px 0',
        [theme.breakpoints.down(1161)]:{
            margin:'0 0 34px 0',
        },
        '& .Titulo':{
            font:'48px fredoka one',
            letterSpacing: '0px',
            lineHeight:'58px',
            color: '#817FD9',
            margin:'0',
            textAlign:'center',
            [theme.breakpoints.down(1161)]:{
                font:'43px fredoka one',
                lineHeight:'52px',
            },
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            lineHeight : '30px',
            margin:'0 0 36px 0',
            font:'22px lato',
            color:'#000',
            letterSpacing: '0.20px',
            [theme.breakpoints.down(1161)]:{
                font:'18px lato',
                lineHeight :'28px',
                letterSpacing: '0.17px',
            },
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .SubTitulo':{
            font:'bold 30px lato',
            color:'#F6664D',
            letterSpacing:'.28px',
            lineHeight:'30px',
            margin:'0',
            [theme.breakpoints.down(1161)]:{
                lineHeight :'28px',
                font:'bold 26px lato',
                letterSpacing: '0.24px',
            },
            [theme.breakpoints.down(502)]:{
                font:'bold 18px lato',
                lineHeight :'24px',
                letterSpacing: '0.21px',
            },
        },

        '& .TextImg':{
            font:'20px lato',
            color:'#717476',
            letterSpacing:'.19px',
            lineHeight:'30px',
            margin:'24px 0 0 0',
            [theme.breakpoints.down(1161)]:{
                font:' 16px lato',
                lineHeight :'25px',
                letterSpacing: '0.15px',
            },
            [theme.breakpoints.down(1161)]:{
                font:' 14px lato',
                lineHeight :'21px',
                letterSpacing: '0.15px',
            },
        },

        '& .Imagen':{
            width:'833px',
            [theme.breakpoints.down(1161)]:{
                width:'100%',
            },
        },

    }
}));

const Vacas_y_vacunas = (props) => {

  const {activeStep, setActiveStep, handlePreviewStep, id_actividad} = props
  
  const handleContinue = () => {
    setActiveStep(activeStep - 1)
    handlePreviewStep()
  }

    const {
      handleSize,
    disabledZoom,
    closeZoom,
    handleZoom,
    scale
  }= useContext(DrawerContext);
  const [loading, setLoading] = React.useState(true);
  const wrapperRef = React.useRef(null);
  const imagesLoaded = useOnLoadImages(wrapperRef);

  const detectSize = () => {
    const lectura = document.getElementById("lectura");
    const newWidth = lectura?.offsetWidth;
    const newHeight = lectura?.offsetHeight;
    setLoading(false);
    if (lectura) handleSize(newHeight, newWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", detectSize);
    return () => {
      handleZoom()
      window.removeEventListener("resize", detectSize);
      closeZoom()
    };
  }, []);

  const classes = useStyles({ disabledZoom,scale });
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
             <Grid container item justifyContent="center" className={classes.ContainerInfoLectura}>
                <Grid container className="BarraInfoLectura">
                    <Button className="Regresar" onClick={handleContinue}>
                        Regresar
                    </Button>
                </Grid>
               <Grid container item className="InfoLectura">
                    <Grid container item direction="column" xs={6} justifyContent="center">
                        <p className="Dato">Grado</p>
                        <p className="Caracter">5</p>
                    </Grid>
                    <Grid container item direction="column" xs={6} justifyContent="center" className="Sesion">
                        <p className="Dato">Sesión</p>
                        <p className="Caracter">7</p>
                    </Grid>
               </Grid>
            </Grid>
            {!imagesLoaded && <Loader />}
        <Grid
            container
            item
            className={classes.ContenedorLectura}
            justifyContent="center"
            id="lectura"
            onLoad={detectSize}
            ref={wrapperRef}
        >
<Drawer id_lectura={id_actividad} />
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo ">Vacas y vacunas</h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                       <p className='Lectura'>
                            ¿Alguna vez has sentido miedo antes de recibir una inyección? 
                            Quizá ver la aguja te incomoda o te hace pensar que podría 
                            pasarte algo malo. Pero es todo lo contrario: con estos 
                            tratamientos nos aseguramos de no contraer enfermedades que 
                            en otros tiempos fueron muy graves. <br/><br/>
                            
                            Gracias a las vacunas, la sociedad actual puede tratar enfermedades 
                            letales y epidemias. Aunque no siempre fue así. Hace apenas 200 
                            años la humanidad se encontraba indefensa ante ellas. ¿Qué sucedió? 
                            ¿Cómo cambiaron las cosas?<br/><br/> 
                            
                            <h3 className='SubTitulo'>Viruela vacuna</h3><br/>
                            
                            En el siglo <span style={{fontVariant: "all-small-caps"}}>XVIII</span> una epidemia de viruela azotó al Reino Unido. 
                            Muchas personas murieron o quedaron desfiguradas por las cicatrices 
                            que dejaban las pústulas de esa enfermedad. Durante esa época la 
                            aristócrata inglesa Mary Wortley Montagu visitó Turquía y se 
                            percató de que allí tenían la situación controlada, porque los 
                            turcos estaban <em>inoculando</em> la viruela. Esto quiere decir que hacían
                            pequeñas incisiones en la piel de una persona sana para introducirle el 
                            líquido de una pústula. De esta manera, quienes recibían el tratamiento 
                            ya no contraían la enfermedad si entraban en contacto con alguien contagiado.<br/><br/>
                            
                            Montagu inoculó a sus hijos y difundió el método entre la aristocracia 
                            del Reino Unido y Europa. Sin embargo, éste no era muy efectivo, ya que 
                            en algunas ocasiones provocaba una enfermedad grave y más contagios. 
                            Después de todo, el tratamiento inoculaba la viruela misma. <br/><br/>
                            
                            En 1796 el método llegó a oídos del cirujano Edward Jenner, quien se 
                            decidió a adaptarlo. El médico había observado que algunas mujeres 
                            lecheras presentaban pústulas parecidas a las que provocaba la viruela, 
                            pero sin los síntomas graves. Además, no se contagiaban si tenían 
                            contacto con personas enfermas: estaban inmunizadas. Jenner concluyó 
                            que dichas mujeres habían contraído un tipo especial de viruela por 
                            medio de las ubres de vacas infectadas. Llamó a esa nueva enfermedad 
                            <em> viruela vacuna</em>.
                       </p>
                    </Grid>
                    <Grid container justifyContent='center'>
                        <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion7/1.png" alt="Tarjeta" />
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container >
                        <p className='Lectura'>
                            Jenner comenzó a experimentar. Tomó líquido de una pústula <em>vacuna</em> (o sea, 
                            proveniente de una vaca). Luego, le hizo dos cortes superficiales a un niño 
                            de ocho años y le aplicó el líquido. El niño se sintió mal a lo largo del día 
                            y manifestó síntomas leves: sus heridas adoptaron la forma de las pústulas de 
                            la viruela vacuna. Tras unos pocos días, se sintió mejor.<br/><br/>
                            
                            Pero todavía faltaba la segunda parte del experimento. Para comprobar 
                            que el niño fuera inmune, Jenner le hizo algunas incisiones en el cuerpo 
                            e introdujo en ellas el líquido de una pústula de viruela humana. El niño 
                            desarrolló algunas pústulas, pero los síntomas letales (fiebre, dolor de 
                            cabeza) nunca se presentaron. Al cabo de unos meses, el científico 
                            volvió a inyectarle viruela humana al niño y su cuerpo ya no 
                            presentó ninguna reacción.
                        </p>
                    </Grid>
                    <Grid container justifyContent='center'>
                        <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion7/2.png" alt="Tarjeta" />
                    </Grid>
                    
                    

                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container >
                        <p className='Lectura'>
                            <h3 className='SubTitulo'>La vacuna recibe su nombre</h3><br/>
                            
                            Después, en el siglo XIX, el científico francés Louis Pasteur 
                            desarrolló métodos de inoculación contra otras enfermedades 
                            graves que causaban epidemias: el cólera y la rabia. Fue él 
                            quien llamó vacuna al líquido que inyectaba, en honor a las 
                            aportaciones de Jenner. <br/><br/>
                            
                            <h3 className='SubTitulo'>Vacunas</h3><br/>
                            
                            Gracias a la vacunación se han controlado epidemias muy importantes. 
                            En el siglo XXI la viruela se considera una enfermedad erradicada. 
                            Asimismo, durante los años 2020 y 2021 los científicos tuvieron la 
                            tarea de controlar la epidemia causada por el coronavirus por medio 
                            de la vacunación. ¡Y pensar que todo empezó con unas vacas lecheras!
                        </p>
                    </Grid>
                </Grid>
        </Grid>
        <Grid container item style={{ marginTop: 80}} ></Grid>
    </Grid>
    );
}
 
export default Vacas_y_vacunas ;
