import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
     Contenedor:{
        background:'#E9FBF9',
        width:'100vw',
        overflowX:'clip',
        [theme.breakpoints.down(1161)]:{
            background:'none',
           overflowX:'unset',
        },
        [theme.breakpoints.down(600)]:{
            marginTop: '79.51px',
        }
    },
  ContainerInfoLectura: {
    position: "relative",
    height: "86px",
    [theme.breakpoints.down(502)]:{
        height: "43px",
    },
    background: "#76D4C3",
    "& .BarraInfoLectura": {
      height: "86px",
      background: `Url(https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Barras/Azul.png)`,
        [theme.breakpoints.down(502)]:{
            height: "43px",
        },
    },
    "& .InfoLectura": {
      position: "absolute",
      height: "86px",
      width: "219px",
      boxSizing: "border-box",
      padding: "17.2px 0",
      background: "#FAFAFA",
      borderRadius: "50px",
      boxShadow: "2px 2px 2px 2px #00000029",
      zIndex: "4",
        [theme.breakpoints.down(502)]:{
            height: "43px",
            width: "109px",
            padding: "8.6px 0",
            borderRadius: "25px",
        },
    },
    "& .InfoLectura:before": {
      position: "absolute",
      display: "flex",
      boxSizing: "border-box",
      padding: "0 0 0 10px",
      justifyContent: "center",
      alignItems: "center",
      height: "39.88px",
      width: "39.88px",
      borderRadius: "50%",
      content: '""',
      boxShadow: "3px 0px .5px 0px #00000029",
      background: "#FAFAFA",
      left: "90px",
      top: "65px",
      transform: "rotate(0.25turn)",
      [theme.breakpoints.down(502)]:{
        left: "45px",
        top: "30px",
        height: "20.88px",
        width: "20.88px",
        },
    },
    "& .InfoLectura:after": {
      content: '""',
      height: "15px",
      width: "15px",
      borderTop: "2px solid #20A698",
      borderLeft: "2px solid #20A698",
      position: "absolute",
      bottom: "-10px",
      left: "Calc(50% - 8px)",
      transform: "rotate(0.625turn)",
      [theme.breakpoints.down(502)]:{
        height: "10px",
        width: "10px",
        left: "Calc(50% - 4.5px)",
        bottom: "-3px",
        borderTop: "1px solid #20A698",
        borderLeft: "1px solid #20A698",
        },
    },
    "& .Sesion": {
      borderLeft: "2px solid #D8D8D8",
    },
    "& .Dato": {
      margin: "0 0 5px 0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#20A698",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        margin: "0 0 2.5px 0",
        },
    },
    "& .Caracter": {
      margin: "0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#000000",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        },
    },   },
    ContenedorLectura:{
        background:'#fff',
        width:'1160px',
        height:'100%',
        padding:'60px 168px 35px ',
        [theme.breakpoints.down(1161)]:{
            width:'502px',
            padding:'60px 0 0 0',
          },
        [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
        '& .ContainerDerechos':{
            bottom:'80px',
            [theme.breakpoints.down(1161)]:{
                display:'none',
            },
        },
        '& .Derechos':{
            textAlign:"center",
            margin:"0px",
            font:" 15px lato",
            color:"#717476",
        },
        '& .Escritorio':{
            display:'block',
            [theme.breakpoints.down(1161)]:{
                display:'none'
            },
        },
        '& .Tablet':{
            display:'none',
            [theme.breakpoints.down(1161)]:{
                display:'block'
            },
        }
    },
    ContainerTitulo:{
        margin:'0 0 61px 0',
        '& .Titulo':{
            font:'48px fredoka one',
            letterSpacing: '0px',
            color: '#F6644B',
            margin:'0',
            textAlign:'center',
            [theme.breakpoints.down(1161)]:{
                font:'43px fredoka one',
                lineHeight:'52px',
            },
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            lineHeight : '30px',
            margin:'0 0 36px 0 ',
            font:'22px lato',
            color:'#000',
            letterSpacing: '0.20px',
            [theme.breakpoints.down(1161)]:{
                font:'18px lato',
                lineHeight :'28px',
                letterSpacing: '0.17px',
            },
            [theme.breakpoints.down(502)]:{
                font:'15px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
        },
        '& .Esp':{
            margin:'0',
        },
        '& .SubTitulo':{
            font:'bold 30px lato',
            color:'#823CE2',
            letterSpacing:'.28px',
            margin:'0',
            [theme.breakpoints.down(1161)]:{
                lineHeight :'28px',
                font:'bold 26px lato',
                letterSpacing: '0.24px',
            },
            [theme.breakpoints.down(502)]:{
                font:'bold 18px lato',
                lineHeight :'24px',
                letterSpacing: '0.21px',
            },
        },
        '& .Cuadro':{
            position:'relative',
            background:`Url(https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img2do/Sesion8/6.png)`,
            height:'1430px',
            [theme.breakpoints.down(1161)]:{
                background:`Url(https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img2do/Sesion8/6R.png)`,
                height:'1979px',
            },
            [theme.breakpoints.down(502)]:{
                background:`Url(https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img2do/Sesion8/6R.png)`,
                height:'1380px',
                backgroundSize:'100%'
            },
            [theme.breakpoints.down(350)]:{
                background:`Url(https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img2do/Sesion8/F.png)`,
                width:'100%',
                height:'1180px',
                backgroundSize:'100%',
                backgroundRepeat:'no-repeat',
            },
        },
        '& .Ocultar':{
            [theme.breakpoints.down(350)]:{
                display:'none'
            },
        },
        '& .Subtitulo2':{
            color:'#FFFFFF',
            font:'bold 26px lato',
            margin:'65px 0 0 0',
            letterSpacing: '0.24px',
            [theme.breakpoints.down(1161)]:{
                textAlign:'center',
                font:'bold 22px lato',
                letterSpacing: '0.21px',
                margin:'60px 0 0 0',
            },
            [theme.breakpoints.down(502)]:{
                textAlign:'center',
                font:'bold 18px lato',
                letterSpacing: '0.21px',
                margin:'36px 0 0 0',
            },
        },
        '& .Subtitulo3':{
            font:'bold 24px lato',
            color:'#000',
            margin:'0 0 36px 0',
            letterSpacing: '0.23px',
            [theme.breakpoints.down(502)]:{
                font:'bold 16px lato',
                letterSpacing: '0.21px',
                margin:'0 0 8px 0',
            },
        },
        '& .ul':{
            width:'668px',
            font:'22px lato',
            color:'#F6644B',
            padding:'0',
            [theme.breakpoints.down(1161)]:{
                font:'18px lato',
                width:'339px',
                margin:0,
            },
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                width:'250px',
            },
        },
        '& .Imagen':{
            [theme.breakpoints.down(1161)]:{
                height:'224px',
                margin:'0'
            },
            [theme.breakpoints.down(502)]:{
                height:'138px',
                margin:'0'
            },
        },
        '& .Imagen1':{
            [theme.breakpoints.down(1161)]:{
                width:'100%'
            },
        },
        '& .Imagen2':{
            margin:'0 0 36px 0',
            [theme.breakpoints.down(1161)]:{
                margin:'0 0 15px 0'
            },
        },
        '& .Imagen3':{
            margin:'0 0 36px 0',
            [theme.breakpoints.down(1161)]:{
                margin:'0 0 15px 0'
            },
        },
        '& .Pos':{
            margin:'30px 0 16px 0',
            [theme.breakpoints.down(1161)]:{
                margin:'0',
            },
        },
        '& .Pos2':{
            margin:'16px 0 0 0',
            [theme.breakpoints.down(1161)]:{
                margin:'0',
            },
        },
        '& .Esp2':{
            margin:'0 0 36px 7%',
            [theme.breakpoints.down(1161)]:{
                margin:'0 0 0 13%',
                width:'368px'
            },
            [theme.breakpoints.down(502)]:{
                width:'250px'
            },
         },
        '& .Esp3':{
           width:'657px',
           [theme.breakpoints.down(1161)]:{
            margin:'0 0 21px 0 ',
            width:'342px',
            [theme.breakpoints.down(502)]:{
                margin:'0',
                width:'250px'
            },
        },
           
        },
        
    },
}));

const Un_conjunto_del_tamaño_de_tu_imaginación = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
             <Grid container item justifyContent="center" className={classes.ContainerInfoLectura}>
               <Grid container className="BarraInfoLectura"></Grid>
               <Grid container item className="InfoLectura">
                    <Grid container item direction="column" xs={6} justifyContent="center">
                        <p className="Dato">Grado</p>
                        <p className="Caracter">2</p>
                    </Grid>
                    <Grid container item direction="column" xs={6} justifyContent="center" className="Sesion">
                        <p className="Dato">Sesión</p>
                        <p className="Caracter">8</p>
                    </Grid>
               </Grid>
            </Grid>

            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >
                        Un conjunto del tamaño<br/> 
                        de tu imaginación
                    </h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                      <p className="Lectura">
                        <h3 className='SubTitulo'>Cosas que coleccionamos </h3><br/>

                         Piensa en esto: ¿a cuántas personas conoces?, ¿podrías contarlas? Por ejemplo, tus 
                         amigos, los miembros de tu familia, tus vecinos, tus compañeros de clase y de otros 
                         grados en la escuela. Lo que importa es que tienes la habilidad para contarlos y que la 
                         vas a desarrollar cada vez más.
                      </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <Grid container justifyContent="center" >
                            <img className="Imagen1" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img2do/Sesion8/1.png" alt="Tarjeta" />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container sm={12}>
                      <p className="Lectura Esp"> 
                        Si te fijas, esas personas suelen estar agrupadas en espacios específicos: tus vecinos 
                        en el barrio, tu familia en tu casa y tus compañeros en el salón o en una pantalla, si es 
                        que tomas tus clases a distancia. A cada uno de estos grupos de personas se le llama 
                        <em> conjunto</em>.
                      </p>
                    </Grid>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                      <p className="Lectura Esp">
                        <h3 className='SubTitulo'>
                        Diversidad de conjuntos
                        </h3><br/>
                         Los conjuntos pueden integrarse por elementos variados, como los que hay a tu 
                         alrededor: en la biblioteca de la escuela hay conjuntos de libros. En tu mochila, un 
                         conjunto de útiles escolares. En el frutero, un conjunto de frutas. Incluso en nuestra 
                         memoria guardamos conjuntos de recuerdos.
                      </p>
                    </Grid>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container justifyContent="center" sm={12} className='Cuadro'>
                    <Grid container justifyContent="center" className='Ocultar'>
                    <h3 className='Subtitulo2'>Instrucciones para<br className='Tablet'/> construir y contar conjuntos</h3>

                        <Grid container item className='Pos' justifyContent='center'>
                            <ol type='1' start={1} className='ul'>
                                <li className='li'>
                                    <p className='Lectura Esp'>Observa las siguientes imágenes y cuenta. ¿Cuántas pitayas ves?</p>
                                </li>
                            </ol>
                        </Grid>
                        <Grid container item justifyContent='center'>
                            <img className='Imagen Imagen2' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img2do/Sesion8/2.png" alt="Tarjeta" />
                            <img className='Imagen Imagen3' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img2do/Sesion8/3.png" alt="Tarjeta" />
                            <img className='Imagen Imagen4' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img2do/Sesion8/4.png" alt="Tarjeta" />
                            <img className='Imagen Imagen5' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img2do/Sesion8/5.png" alt="Tarjeta" />
                        </Grid>

                        <Grid container item className='Pos2' justifyContent='center'>
                            <ol type='1' start={2} className='ul'>
                                <li className='li'>
                                    <p className='Lectura Esp'>
                                        Escribe en tu cuaderno el número que le corresponde a cada imagen 
                                        al contar sus elementos.
                                    </p>
                                </li>
                                <li className='li'>
                                    <p className='Lectura Esp'>
                                        Haz lo mismo en casa. Toma una bolsa, un frutero, una caja, una cesta o 
                                        cualquier recipiente que tengas a la mano. Procura que sea un recipiente ligero y que no te lastimes con él.
                                    </p>
                                </li>
                                <li className='li'>
                                    <p className='Lectura Esp'>
                                        Consigue elementos diversos para poner dentro de tu recipiente. 
                                        Cuenta en voz alta mientras los colocas. Comienza a contar desde cero, cuando el envase esté 
                                        vacío, y cuenta tanto como puedas o sepas mientras le introduces nuevos elementos.
                                    </p>
                                </li>
                            </ol>
                        </Grid>
                        <Grid container item className='Pos3' >  
                            <p className='Lectura Esp2'>El recipiente con su contenido es un conjunto. ¡Lo hiciste bien!</p>
                        </Grid>
                        <Grid container item justifyContent='center'>
                            <p className='Lectura Esp3'>
                                <h3 className='Subtitulo3'>Repaso: los números naturales</h3>

                                A los números que usaste para contar, es decir, los que nos
                                permiten representar los elementos de un conjunto (1, 2, 3…) se les
                                llama  <em>naturales</em> (N) y no tienen fin.
                            </p>
                        </Grid>
                    </Grid>
                    </Grid>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura ">
                        En conclusión, puedes contar y crear conjuntos tan grandes como tu imaginación lo 
                        permita.
                        </p>
                    </Grid>
                </Grid>
               
            <Grid container item xs={12} justifyContent="center" className="ContainerDerechos">
                <p className="Derechos">
                    © 2022. Todos los derechos reservados, Pearson, Circuito de lectores.
                </p>                
            </Grid>
        </Grid>
    </Grid>
    );
}
 
export default Un_conjunto_del_tamaño_de_tu_imaginación;
