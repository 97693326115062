import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
    ContenedorLectura:{
        width:'502px',
                  [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
    },
    ContainerTitulo:{
        margin:'0 0 34px 0',
        '& .Titulo':{
            letterSpacing: '0px',
            color: '#596EBB',
            margin:'0',
            textAlign:'center',
            font:'43px fredoka one',
            lineHeight:'52px',
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            margin:'0 0 36px 0',
            color:'#000',
            font:'18px lato',
            lineHeight :'28px',
            letterSpacing: '0.17px',
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        "& .SubTitulo": {
            color: "#F6644B",
            margin: "0",
            lineHeight: "28px",
            font: "bold 26px lato",
            letterSpacing: "0.24px",
            [theme.breakpoints.down(502)]: {
              font: "bold 22px lato",
              lineHeight: "24px",
              letterSpacing: "0.21px",
            },
        },
        '& .TextImg':{
            color:'#717476',
            margin:'24px 0 0 0',
            font:' 16px lato',
            lineHeight :'25px',
            letterSpacing: '0.15px',
            [theme.breakpoints.down(502)]:{
                font:' 11px lato',
                lineHeight :'14px',
                letterSpacing: '0.10px',
            },
        },
        
        '& .Imagen':{
            width:'100%',
        }, 
      
    }
}));

const Göbekli_Tepe = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>

            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >
                        Göbekli Tepe                 
                    </h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                      <p className="Lectura">
                        En el suroeste de Turquía existe un sitio arqueológico llamado Göbekli Tepe. Se 
                        encuentra en la provincia de Sanliurfa y es famoso porque tiene una serie de 
                        monumentos megalíticos en forma de círculos y rectángulos. Es una región 
                        importante para el estudio de la arqueología. ¿Te gustaría saber por qué?
                      </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <Grid container justifyContent="center" >
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img3ro/Sesion45/1.png" alt="Tarjeta" />
                        </Grid>
                        <Grid container justifyContent="center" >
                            <p className='TextImg'>
                            Göbekli Tepe.
                            </p>                        
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                      <p className="Lectura">
                        <h3 className='SubTitulo'>Cambio en la historia</h3><br/>

                        El hallazgo de este asentamiento es reciente, ocurrió a finales del siglo pasado. Por 
                        eso se conoce poco acerca de él. Klaus Schmidt fue el arqueólogo alemán que dirigió 
                        las exploraciones desde 1994 hasta su fallecimiento en 2014. Hizo muchas 
                        investigaciones y análisis, pero a pesar de todas ellas se piensa que solamente se 
                        conoce 5% del lugar. Sin duda, es una zona amplia en la que aún hay muchas 
                        excavaciones por hacer. ¡Hay muchas cosas que continúan ocultas!<br/><br/>  
                        
                         Gracias a las exploraciones se reunió mucho material, que ha sido correctamente 
                         examinado en laboratorios especializados. Los resultados son estudiados por 
                         especialistas, quienes formularon la hipótesis de que la antigua sociedad que 
                         habitaba ahí utilizaba las habitaciones para llevar a cabo rituales. La idea se sostiene, 
                         en parte, porque hay pilares altos de aproximadamente 5.5 metros de altura, que 
                         tienen forma de T y representaciones de animales esculpidos.
                      </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <Grid container justifyContent="center" >
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img3ro/Sesion45/2.png" alt="Tarjeta" />
                        </Grid>
                        <Grid container justifyContent="center" >
                            <p className='TextImg'>
                                Pilar con la figura de un zorro.
                            </p>                        
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                      <p className="Lectura">
                        Actualmente, Göbekli Tepe es considerado el templo más antiguo del mundo y es uno
                        de los más importantes del periodo neolítico, que pertenece a la Edad de Piedra. El 
                        nombre de esta etapa deriva de dos términos griegos: <em>neo</em>, que significa “nuevo” y 
                        <em> lithikós</em>, que quiere decir “de piedra”. Esto porque los seres humanos de ese entonces 
                        construían sus herramientas a partir de piedras. En 2018, el templo fue declarado 
                        Patrimonio de la Humanidad por la <span style={{fontVariant: "all-small-caps"}}>UNESCO</span>.<br/><br/> 
                        
                        Su descubrimiento cambió la forma de interpretar las sociedades. Antes, los 
                        historiadores creían que las pirámides de Giza en Egipto eran las construcciones más 
                        antiguas, pero ahora se calcula que Göbekli Tepe fue construido hace 
                        aproximadamente 11 600 años, es decir, ¡7 000 años antes que las pirámides y 6 000 
                        años antes que Stonehenge!<br/><br/> 
                        
                        Se pensaba que las primeras civilizaciones habían surgido hace aproximadamente 
                        9000 años en la Revolución neolítica, gracias a que las condiciones meteorológicas 
                        permitieron la existencia de granjas y ganados. Y se sospechaba que los primeros 
                        pueblos y ciudades se habían constituido poco a poco, hasta llegar a establecer 
                        formas de gobierno y rituales religiosos. Pero Göbekli Tepe pone en evidencia que las 
                        sociedades organizadas existieron varios milenios antes de lo pensado. <br/><br/> 
                        
                        Aún se estudian los hallazgos y hay muchas preguntas por responder. Las nuevas 
                        pistas darán la oportunidad de conocer más sobre este tema. ¡Quizá se encuentren 
                        otras cosas que cambien nuevamente la interpretación de la historia!
                      </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <Grid container justifyContent="center" >
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img3ro/Sesion45/3.png" alt="Tarjeta" />
                        </Grid>
                        <Grid container justifyContent="center" >
                            <p className='TextImg'>
                            Göbekli Tepe al anochecer.
                            </p>                        
                        </Grid>
                    </Grid>
                </Grid>
        </Grid>
    </Grid>
    );
}
 
export default Göbekli_Tepe ;
