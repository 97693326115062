import { Button, Grid, makeStyles } from "@material-ui/core";
import React, { useContext, useEffect } from "react";
import DrawerContext from "../../../../../usability/DrawerContext";
import { useOnLoadImages } from "../../../../hooks/useOnLoadImages";
import Loader from "../../../../Loader/Loader";
import Drawer from "../../ReadingTools/Drawer";

const useStyles =  makeStyles (theme =>({
      Contenedor:{
        background:'#E9FBF9',
        width:'100vw',
        overflowX:'clip',
        [theme.breakpoints.down(1161)]:{
            background:'none',
           overflowX:'unset',
        },
        [theme.breakpoints.down(600)]:{
            marginTop: '79.51px',
        }
    },
  ContainerInfoLectura: {
    position: "relative",
    height: "86px",
    [theme.breakpoints.down(502)]:{
        height: "43px",
    },
    background: "#76D4C3",
    "& .BarraInfoLectura": {
      height: "86px",
      background: `Url(https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Barras/Azul.png)`,
        [theme.breakpoints.down(502)]:{
            height: "43px",
        },
    },
    "& .InfoLectura": {
      position: "absolute",
      height: "86px",
      width: "219px",
      boxSizing: "border-box",
      padding: "17.2px 0",
      background: "#FAFAFA",
      borderRadius: "50px",
      boxShadow: "2px 2px 2px 2px #00000029",
      zIndex: "4",
        [theme.breakpoints.down(502)]:{
            height: "43px",
            width: "109px",
            padding: "8.6px 0",
            borderRadius: "25px",
        },
    },
    "& .InfoLectura:before": {
      position: "absolute",
      display: "flex",
      boxSizing: "border-box",
      padding: "0 0 0 10px",
      justifyContent: "center",
      alignItems: "center",
      height: "39.88px",
      width: "39.88px",
      borderRadius: "50%",
      content: '""',
      boxShadow: "3px 0px .5px 0px #00000029",
      background: "#FAFAFA",
      left: "90px",
      top: "65px",
      transform: "rotate(0.25turn)",
      [theme.breakpoints.down(502)]:{
        left: "45px",
        top: "30px",
        height: "20.88px",
        width: "20.88px",
        },
    },
    "& .InfoLectura:after": {
      content: '""',
      height: "15px",
      width: "15px",
      borderTop: "2px solid #20A698",
      borderLeft: "2px solid #20A698",
      position: "absolute",
      bottom: "-10px",
      left: "Calc(50% - 8px)",
      transform: "rotate(0.625turn)",
      [theme.breakpoints.down(502)]:{
        height: "10px",
        width: "10px",
        left: "Calc(50% - 4.5px)",
        bottom: "-3px",
        borderTop: "1px solid #20A698",
        borderLeft: "1px solid #20A698",
        },
    },
    "& .Sesion": {
      borderLeft: "2px solid #D8D8D8",
    },
    "& .Dato": {
      margin: "0 0 5px 0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#20A698",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        margin: "0 0 2.5px 0",
        },
    },
    "& .Caracter": {
      margin: "0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#000000",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        },
    },
       '& .Regresar':{
        color: '#F2624F',
        border: '#F2624F 1px solid',
        height: '30px',
        width:'100px',
        borderRadius: '20px',
        letterSpacing: '0.14px',
        textTransform: 'none',
        background:'#FEFAF1',
        top:'calc(50% - 15px)',
        left:'5%',
        zIndex:4,
        [theme.breakpoints.down(502)]: {
          font:'bold 10px lato',
          width: "60px",
        },
      },
  },

    ContenedorLectura:{
        position:'relative',
        background:'#fff',
        width:'1160px',
        height:'100%',
        padding:'50px 65px 108px ',
        
        transform: (props) =>
        props.scale > 1 ? `scale(${props.scale}) translate(0px, 10%)` : `none`,
        [theme.breakpoints.down(1161)]:{
            width:'684.71px',
            margin:'35px',
            padding:'0',
          },
          [theme.breakpoints.down(768)]:{
            width:'350px',
            },
        '& .Escritorio':{
            display:'block',
            [theme.breakpoints.down(1161)]:{
                display:'none'
            },
        },
        '& .Tablet':{
            display:'none',
            [theme.breakpoints.down(1161)]:{
                display:'block'
            },
        },
        '& .ContainerDerechos':{
            position:'relative',
            bottom:'80px',
            [theme.breakpoints.down(1161)]:{
                display:'none',
              },
        },
        '& .Derechos':{
            textAlign:"center",
            margin:"0px",
            font:" 15px lato",
            color:"#717476",
        },

        '& .Pos':{
            position:'absolute',
            margin:'0',
        },
        '& .Titulo':{
            font:'98px Fredoka One',
            color:'#FFFFFF',
            lineHeigth:'118px',
            top:'161px',
            [theme.breakpoints.down(1161)]:{
                font:'65px Fredoka One',
                lineHeigth:'78px',
                top:'79px',
            },
            [theme.breakpoints.down(768)]:{
                font:'28px Fredoka One',
                lineHeigth:'28px',
                top:'41px',
            },
        },
        '& .Sub1':{
            font:'32px Fredoka One',
            color:'#FFFFFF',
            lineHeigth:'39px',
            top:'269px',
            [theme.breakpoints.down(1161)]:{
                font:'20px Fredoka One',
                lineHeigth:'24px',
                top:'151px',
            },
            [theme.breakpoints.down(768)]:{
                font:'9px Fredoka One',
                lineHeigth:'12px',
                top:'71px',
            },
        },
        '& .Sub2':{
            font:'114px Fredoka One',
            color:'#DF4248',
            lineHeigth:'138px',
            top:'341px',
            left:'299px',
            [theme.breakpoints.down(1161)]:{
                font:'75px Fredoka One',
                lineHeigth:'91px',
                top:'200px',
                left:'171px',
            },
            [theme.breakpoints.down(768)]:{
                font:'35px Fredoka One',
                lineHeigth:'91px',
                top:'88px',
                left:'87px',
            },
        },
        '& .Sub3':{
            font:'86px Fredoka One',
            color:'#34DAFC',
            lineHeigth:'104px',
            top:'442px',
            left:'509px',
            [theme.breakpoints.down(1161)]:{
                font:'57px Fredoka One',
                lineHeigth:'69px',
                top:'267px',
                left:'315px',
            },
            [theme.breakpoints.down(768)]:{
                font:'25px Fredoka One',
                lineHeigth:'91px',
                top:'126px',
                left:'158px',
            },
        },
        '& .Sub4':{
            font:'50px Fredoka One',
            color:'#FFFFFF',
            lineHeigth:'61px',
            top:'804px',
            [theme.breakpoints.down(1161)]:{
                font:'33px Fredoka One',
                lineHeigth:'40px',
                top:'512px',
            },
            [theme.breakpoints.down(768)]:{
                font:'20px Fredoka One',
                lineHeigth:'22px',
                top:'238px',
            },
        },
        '& .Sub5':{
            font:'79px Fredoka One',
            color:'#FFFFFF',
            lineHeigth:'96px',
            top:'1973px',
            [theme.breakpoints.down(1161)]:{
                font:'52px Fredoka One',
                lineHeigth:'63px',
                top:'1294px',
            },
            [theme.breakpoints.down(768)]:{
                font:'22px Fredoka One',
                lineHeigth:'25px',
                top:'607px',
            },
        },
        '& .Sub6':{
            font:'90px Fredoka One',
            color:'#FFFFFF',
            lineHeigth:'104px',
            top:'2384px',
            left:'479px',
            textAlign:'center',
            [theme.breakpoints.down(1161)]:{
                font:'60px Fredoka One',
                lineHeigth:'69px',
                top:'1565px',
                left:'285px',
            },
            [theme.breakpoints.down(768)]:{
                font:'26px Fredoka One',
                lineHeigth:'28px',
                top:'728px',
                left:'150px',
            },
        },
        '& .Sub7':{
            font:'64px Fredoka One',
            color:'#FFFFFF',
            lineHeigth:'74px',
            top:'2793px',
            left:'249px',
            textAlign:'center',
            [theme.breakpoints.down(1161)]:{
                font:'42px Fredoka One',
                lineHeigth:'48px',
                top:'1830px',
                left:'125px',
            },
            [theme.breakpoints.down(768)]:{
                font:'18px Fredoka One',
                lineHeigth:'18px',
                top:'852px',
                left:'70px',
            },

        },
        '& .Sub8':{
            font:'49px Fredoka One',
            color:'#FFFFFF',
            lineHeigth:'57px',
            top:'2893px',
            left:'660px',
            textAlign:'center',
            [theme.breakpoints.down(1161)]:{
                font:'32px Fredoka One',
                lineHeigth:'37px',
                top:'1906px',
                left:'400px',
            },
            [theme.breakpoints.down(768)]:{
                font:'15px Fredoka One',
                lineHeigth:'15px',
                top:'891px',
                left:'200px',
            },
        },
        '& .Sub9':{
            font:'40px Fredoka One',
            color:'#1D0839',
            lineHeigth:'48px',
            top:'3236px',
            textAlign:'center',
            [theme.breakpoints.down(1161)]:{
                font:'25px Fredoka One',
                lineHeigth:'30px',
                top:'2135px',
            },
            [theme.breakpoints.down(768)]:{
                font:'12px Fredoka One',
                lineHeigth:'12px',
                top:'1014px',
            },
        },
        '& .Cuerpo':{
            font:'bold 22px lato',
            color:'#000000',
            letterSpacing:'.21px',
            lineHeigth:'30px',
            textAlign:'center',
            [theme.breakpoints.down(1161)]:{
                font:'bold 14px lato',
                lineHeigth:'19px',
            },
            [theme.breakpoints.down(768)]:{
                font:'bold 7px lato',
                lineHeigth:'8px',
            },
        },
        '& .Cuerpo2':{
            font:' 22px lato',
            color:'#6F7070',
            letterSpacing:'.21px',
            lineHeigth:'30px',
            textAlign:'center',
            [theme.breakpoints.down(1161)]:{
                font:'14px lato',
                lineHeigth:'19px',
            },
            [theme.breakpoints.down(768)]:{
                font:'6px lato',
                lineHeigth:'8px',
            },
        },
        '& .Cuerpo3':{
            font:'bold 22px lato',
            color:'#FFFFFF',
            letterSpacing:'.21px',
            lineHeigth:'30px',
            textAlign:'center',
            [theme.breakpoints.down(1161)]:{
                font:'bold 14px lato',
                lineHeigth:'19px',
            },
            [theme.breakpoints.down(768)]:{
                font:'bold 7px lato',
                lineHeigth:'8px',
            },
        },
        '& .Text1':{
            top:'559px',
            [theme.breakpoints.down(1161)]:{
                top:'346px',
            },
            [theme.breakpoints.down(768)]:{
                top:'160px',
            },
        },
        '& .Text2':{
            top:'887px',

            [theme.breakpoints.down(1161)]:{
                top:'564px',

            },
            [theme.breakpoints.down(768)]:{
                top:'263px',

            },
        },
        '& .Text3':{
            top:'987px',

            [theme.breakpoints.down(1161)]:{
                top:'631px',

            },
            [theme.breakpoints.down(768)]:{
                top:'293px',

            },
        },
            '& .Text4':{
            top:'1520px',
            [theme.breakpoints.down(1161)]:{
                top:'986px',
            },
            [theme.breakpoints.down(768)]:{
                top:'470px',
            },
        },
        '& .Text5':{
            top:'2102px',
            [theme.breakpoints.down(1161)]:{
                top:'1377px',
            },
            [theme.breakpoints.down(768)]:{
                top:'647px',
            },
        },
        '& .Text6':{
            top:'2625px',
            left:'471.5px',
            [theme.breakpoints.down(1161)]:{
                top:'1726.48px',
                left:'275px',
            },
            [theme.breakpoints.down(768)]:{
                top:'805px',
                left:'138px',
            },
        },
        '& .Text7':{
            top:'2951px',
            left:'281.5px',
            [theme.breakpoints.down(1161)]:{
                top:'1944.46px',
                left:'152.76px',
            },
            [theme.breakpoints.down(768)]:{
                top:'910px',
                left:'80px',
            },
        },
        '& .Text8':{
            top:'3029px',
            left:'663.5px',
            [theme.breakpoints.down(1161)]:{
                top:'1996.62px',
                left:'405.19px',
            },
            [theme.breakpoints.down(768)]:{
                top:'935px',
                left:'202px',
            },
        },
        '& .Correo':{
            [theme.breakpoints.down(1161)]:{
                display:'none',
                height:'2478.05px',
                width:'684.71px',
            },
        },
        '& .Correo2':{
            display:'none',
            [theme.breakpoints.down(1161)]:{
                display:'block',
                width:'100%',
                height:'2478.05px',
            },
            [theme.breakpoints.down(768)]:{
                display:'block',
                height:'1160px',
            },
            
        },
    },
    Esconder: {
        [theme.breakpoints.down(768)]:{
            display:'none'
        },
    },
    Imagen: {
        display:'none',
        [theme.breakpoints.down(768)]:{
            width:'100%',
            display:'flex'
        },
    },
}));

const Mi_casa_mi_tierra = (props) => {

  const {activeStep, setActiveStep, handlePreviewStep, id_actividad} = props

  const handleContinue = () => {
    setActiveStep(activeStep - 1)
    handlePreviewStep()
  }

    const {
    handleSize,
    disabledZoom,
    closeZoom,
    handleZoom,
    scale
  }= useContext(DrawerContext);
  const [loading, setLoading] = React.useState(true);
  const wrapperRef = React.useRef(null);
  const imagesLoaded = useOnLoadImages(wrapperRef);

  const detectSize = () => {
    const lectura = document.getElementById("lectura");
    const newWidth = lectura?.offsetWidth;
    const newHeight = lectura?.offsetHeight;
    setLoading(false);
    if (lectura) handleSize(newHeight, newWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", detectSize);
    return () => {
      handleZoom()
      window.removeEventListener("resize", detectSize);
      closeZoom()
    };
  }, []);

  const classes = useStyles({ disabledZoom,scale });
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
             <Grid container item justifyContent="center" className={classes.ContainerInfoLectura}>
               <Grid container className="BarraInfoLectura">
                    <Button className="Regresar" onClick={handleContinue}>
                        Regresar
                    </Button>
                </Grid>
               <Grid container item className="InfoLectura">
                    <Grid container item direction="column" xs={6} justifyContent="center">
                        <p className="Dato">Grado</p>
                        <p className="Caracter">6</p>
                    </Grid>
                    <Grid container item direction="column" xs={6} justifyContent="center" className="Sesion">
                        <p className="Dato">Sesión</p>
                        <p className="Caracter">3</p>
                    </Grid>
               </Grid>
            </Grid>{!imagesLoaded && <Loader />}
        <Grid
            container
            item
            className={classes.ContenedorLectura}
            justifyContent="center"
            id="lectura"
            onLoad={detectSize}
            ref={wrapperRef}
        >
<Drawer id_lectura={id_actividad} withoutTransform ={true}/>
        <Grid container item justifyContent="center" className={classes.Esconder}>
                <h2 className='Pos Titulo'>CONVOCATORIA</h2>
                <h3 className='Pos Sub1'>PRIMER CONCURSO INFANTIL DE AUTORRETRATO</h3>
                <h3 className='Pos Sub2'>Mi casa,</h3>
                <h3 className='Pos Sub3'>mi tierra</h3>
                <p  className='Pos Cuerpo Text1'>
                    Si tienes entre 7 y 12 años, vives en cualquier país de <br/>
                    Latinoamérica y te gusta tomarte selfies (autorretratos), <br/>
                    te invitamos a participar en este concurso. <br/><br/>

                    Tómate una foto en un lugar que refleje la cultura y tradiciones de <br/>
                    tu país. El objetivo de la competencia es mostrar al mundo que los <br/>
                    niños latinoamericanos aprecian sus tradiciones y su cultura.
                </p>
                <h3 className='Pos Sub4'>BASES</h3>
                <p  className='Pos Cuerpo2 Text2'>
                    Las fotos deberán mostrar con claridad tu rostro y el lugar en el<br/> 
                    que te encuentras. Si alguno de estos dos componentes no<br/> 
                    aparece con claridad, tu participación será anulada.<br/>
                </p>
                <p  className='Pos Cuerpo2 Text3'>
                    La resolución de tu foto deberá ser de cualquiera de los siguientes tamaños:
                </p>
                <p  className='Pos Cuerpo2 Text4'>
                    La modalidad de entrega será digital. <br/> 
                    Deberás subir un archivo JPG o BMP al sitio www.fotolatam.org. <br/> <br/> 

                    Los participantes deberán completar su registro en el mismo sitio de internet. <br/> 
                    Éste deberá incluir el nombre completo, la edad y los datos de un responsable<br/> 
                    mayor de edad.<br/> <br/> 
                     
                    La fecha límite para completar el registro<br/> 
                    y subir la foto es el 15 de agosto de 2022 a las 23:59 horas. <br/> <br/> 

                    Las fotografías serán valoradas por un jurado de fotógrafos profesionales <br/> 
                    de la asociación convocante.
                </p>
                <h3 className='Pos Sub5'>GANADORES</h3>
                <p  className='Pos Cuerpo Text5'>
                    Los resultados se publicarán en el mismo sitio de internet,<br/>  
                    el 25 de septiembre de 2022 a las 13:00 horas.<br/><br/>  

                    Se premiará a los 3 primeros lugares, de acuerdo<br/> 
                    con el veredicto del jurado, de la siguiente manera:<br/> 
                </p>
                <h3 className='Pos Sub6'>Primer<br/>lugar:</h3>
                <p  className='Pos Cuerpo Text6'>
                    Una cámara digital <br/>
                    semiprofesional y la exhibición <br/>
                    de su trabajo en la Feria Mundial <br/>
                    de la Foto Infantil
                </p>
                <h3 className='Pos Sub7'>Segundo<br/>lugar:</h3>
                <p  className='Pos Cuerpo3 Text7'>
                    Cámara digital y un <br/>
                    lote de libros
                </p>
                <h3 className='Pos Sub8'>Tercer<br/>lugar:</h3>
                <p  className='Pos Cuerpo3 Text8'>
                    Cámara digital
                </p>
                <h3 className='Pos Sub9'>
                    ¡Anímate a participar y forma parte<br/> 
                    de la historia fotográfica de tu país!
                </h3>
 
                <img className='Correo' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion3/1.png" alt="Tarjeta" />
                <img className='Correo2' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion3/1R.png" alt="Tarjeta" />
            </Grid>
            <img className={classes.Imagen} src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion3/F.png" alt="Tarjeta" />

        </Grid>
        <Grid container item style={{ marginTop: 80}} ></Grid>
    </Grid>
    );
}
 
export default Mi_casa_mi_tierra;
