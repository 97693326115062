import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
    ContenedorLectura:{
        width:'502px',
                  [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
    },
    ContainerTitulo:{
        margin:'0 0 34px 0',
        '& .Titulo':{
            letterSpacing: '0px',
            color: '#F6644B',
            margin:'0',
            textAlign:'center',
            font:'43px fredoka one',
            lineHeight:'52px',
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            margin:'0',
            color:'#000',
            font:'18px lato',
            lineHeight :'28px',
            letterSpacing: '0.17px',
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },

        '& .Imagen':{
            width:'508px',
            [theme.breakpoints.down(502)]:{
                width:'100%',
            },
        },  
        '& .Imagen2':{
            width:'568px',
            [theme.breakpoints.down(502)]:{
                width:'100%',
            },
        },  

      
    }
}));

const Las_rayas_no_hacen_al_tigre = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                        <h2 className="Titulo">Las rayas<br/> no hacen al tigre</h2>


                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                       <p className='Lectura'>
                            “¡Riiiiiiiing!” Sonó el despertador. Para Rodrigo era hora de rugir:<br/><br/>
                            
                            —Oarghhhhhh. <br/><br/>
                            
                            Abrió la boca y fue al espejo a revisar sus colmillos. “¡Cada día están más grandes!”,
                            pensó orgulloso. Luego corrió hacia la sala, persiguiendo una presa imaginaria. <br/><br/>
                            
                            —Ven a desayunar —le gritó su mamá.<br/><br/>
                            
                            Rodrigo brincó a la mesa. <br/><br/>
                            
                            —¡Los tigres no comemos verduras! <br/><br/>
                            
                            —¡Pues a mí me encantan! —intervino su hermana. <br/><br/>
                            
                            —Oarghhhhhh —dijo Rodrigo estirando sus patas. <br/><br/>
                            
                            —¡Mira qué garras! —exclamó su mamá—. Tenemos que cortártelas ahora. <br/><br/>
                            
                             —¡No quiero! ¡Soy un tigre!<br/><br/>
                            
                            —Hasta los tigres deben limarse las uñas —contestó su papá.
                       </p>                   
                    </Grid>
                    <Grid container justifyContent='center'>
                        <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img1ro/Sesion19/1.png" alt="Tarjeta" />
                    </Grid>
                    
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container >
                        <p className='Lectura'>
                            “¡Ding dong!”, se escuchó el timbre. Rodrigo aprovechó para escapar. <br/><br/>
                            
                            Se escondió bajo el sillón. ¡Él era un tigre y se tenía que comportar como tal! ¿Por 
                            qué su familia le decía que cambiara sus rayas? <br/><br/>
                            
                            Después de unos minutos, escuchó a su abuelita entrar. <br/><br/>
                            
                            —Rodrigo —empezó a llamarlo. Pero él permanecía escondido—. ¿Qué pasa?<br/><br/>
                            
                            —Abuela, ¿por qué no me dejan ser como tengo que ser? <br/><br/>
                            
                            —Nadie ha dicho que los tigres se tienen que comportar de cierta manera. Tú decides
                            qué rayas quieres y cuáles no. Lo importante es que seas tú mismo. <br/><br/>
                            
                            Rodrigo salió. Se limpió las lágrimas. <br/><br/>
                            
                            —¡Ahí está mi pequeño felino! —exclamó la abuelita—. Lo que no sé es si los tigres 
                            comen pastel. <br/><br/>
                            
                            —Este tigre sí —respondió Rodrigo mientras le daba un abrazo.
                        </p>
                    </Grid>
                    <Grid container justifyContent='center'>
                        <img className="Imagen2" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img1ro/Sesion19/2.png" alt="Tarjeta" />
                    </Grid>
                </Grid>
            </Grid>
    </Grid>
    );
}
 
export default Las_rayas_no_hacen_al_tigre;
