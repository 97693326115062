import React, { useContext, useState } from 'react';
import { Grid,makeStyles,withStyles, Button}       from "@material-ui/core";
import Dialog               from '@material-ui/core/Dialog';
import DialogActions        from '@material-ui/core/DialogActions';
import DialogContent        from '@material-ui/core/DialogContent';
import DialogTitle          from '@material-ui/core/DialogTitle';
import NivelUno             from "../practicas_guiadas/NivelUno";
import axios                from "../../../services/axios";
import AuthContext          from "../../../auth/AuthContext";
import Menu                 from '@material-ui/core/Menu';
import ListItemText         from '@material-ui/core/ListItemText';
import MenuItem             from '@material-ui/core/MenuItem';

const useStyles = makeStyles(theme =>({
   BotonAvanza:{
    position:'absolute',
    bottom:'52px',
    right:'134px',
    textTransform:'none',
    textDecoration:'none',
    height:'62px',
    width:'279px',  
    background: '#7A54BA',
    boxShadow: '0px 3px 6px #00000029',
    borderRadius: '61px',
    color: '#FFFFFF',
    font: 'bold 20px lato ',
    '&:hover':{
        background: '#dd78fb',
      },
    [theme.breakpoints.down(1161)]:{
      position:'relative',
      bottom:'32px',
      left:'0',
      right:'0',
    },
  },
  MuiDialogContainer: {
    borderRadius: '61px',
    height: "400px",
    width: "70%",
    backgroundColor: "#FEFAF1",
    border: "#707070 2px solid",
    backdropFilter: "blur(3px)",
    [theme.breakpoints.down(1568)]:{
      height: "300px",
      maxWidth: "75%",
    },
    [theme.breakpoints.down(468)]:{
      height: "250px",
      maxWidth: "80%",
    },
  },
  continueButton:{
    marginBottom:"20px",
    textTransform: 'none',
    height:'62px',
    width:'279px',  
    background: '#7A54BA',
    boxShadow: '0px 3px 6px #00000029',
    borderRadius: '61px',
    color: '#FFFFFF',
    font: 'bold 20px lato ',
    '&:hover':{
        background: '#dd78fb',
    },
    [theme.breakpoints.down(1368)]:{
      height:'52px',
      width:'229px',
    },
    [theme.breakpoints.down(1068)]:{
      height:'52px',
      width:'60%',
    },
    [theme.breakpoints.down(769)]:{
      width: "56.5%"
    },
  },
  gridAudioContainer: {
    height: "100%"
  },
  audioControls: {
    width: "80%",
    border: "2px solid #00DAC9",
    borderRadius: "35px",
    
    [theme.breakpoints.down(769)]:{
      width: "90%"
    },
  }
}));

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    '&:focus': {
      backgroundColor: theme.palette.primary.main,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

const Practica = (props) => {
  const{ grade, source, index, setNextStepButton, nextStepButton, setAnchorEl } = props
  const classes = useStyles ()
  const [finished, setFinished] = React.useState(true)
  const [open, setOpen] = React.useState(false)
  const handleCloseDialog = () => {
    setOpen(false)
    setNextStepButton(false)
    setAnchorEl(null)
  }

  return(
    <>
      <StyledMenuItem key={index} onClick={()=> {setOpen(true)}} >
        <ListItemText primary={grade+ '°'+ " "+ 'grado'}/>
      </StyledMenuItem>
      <Dialog open={open} classes={{paper: classes.MuiDialogContainer}} disableEscapeKeyDown >
        <DialogTitle>
          <Grid container justifyContent="center" alignItems="center">
            <p>Grado: {grade}</p>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Grid container justifyContent="center" alignItems="center" className={classes.gridAudioContainer}>
            <audio controls autoPlay controlsList="nodownload" onEnded={() =>{ setFinished(false)}} className={classes.audioControls}>
              <source src={source} type="audio/mp3"></source>
            </audio>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container justifyContent="center" alignItems="center">
            <Button onClick={ handleCloseDialog } disabled={finished} id="BotonVolver" className={classes.continueButton} >Volver</Button>
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  )
}

const PracticasTres = (props) => {
  const classes = useStyles();
  const { activeStep, setActiveStep, handleNextStep } = props
  const {
    user: { name, firstLastName, secondLastName, userName, grade, userId, rol },
  } = useContext(AuthContext);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [relajacionList, setRelajacionList] = React.useState([])
  const [openPracticas, setOpenPracticas] = useState(false);
  const [nextStepButton, setNextStepButton] = useState(true);

  const practicaRelajacion = () => {
    let grado = 0
    switch (grade) {
      case "primero":
        grado = 1
      break;
      case "segundo":
        grado = 2
      break;
      case "tercero":
        grado = 3
      break;
      case "cuarto":
        grado = 4
      break;
      case "quinto":
        grado = 5
      break;
    
      default:
        grado = 6
        break;
    }
    const body = {
      grado: 2
    };
    axios
      .get(`/actividad/recursos/audio/autogestion?grado=${grado}`, body)
      .then((response) => {
        if (response.status === 200) {
          setRelajacionList(response.data.resultado.practica_guiada)
        }
      })
      .catch((error) => {
        console.log(error,'ERROR!')
      });
  }

  const handleOpen1 = (event) => {
    // setAnchorEl1(document.getElementById("practicaB1"));
    setAnchorEl(event.currentTarget)
    practicaRelajacion()
    
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleContinue = () => {
    setActiveStep(activeStep + 1)
    handleNextStep()
  }

  return(
    <>
      {  nextStepButton ?  
      <Button id="practicaB1" className={classes.BotonAvanza} onClick={handleOpen1}>
        Práctica guiada
      </Button>
        :
      <Button className={classes.BotonAvanza} onClick={handleContinue}>
        Continuar
      </Button>
      }

      <div>
        <StyledMenu
          id="customized-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          {relajacionList && 
            relajacionList.map((relajacion, index) =>{
              return(
                <Practica
                  key={index + "-autogestion"}
                  index={index}
                  source={relajacion?.recurso}
                  grade={relajacion?.grado}
                  nextStepButton={nextStepButton}
                  setNextStepButton={setNextStepButton}
                  setAnchorEl={setAnchorEl}
                  // handleClose={handleClose}
                />
              )
            })
          }
        </StyledMenu>
      </div>
      {/* {relajacionList.length > 0 &&
        <NivelUno
        anchorEl={anchorEl1}
        setAnchorEl={setAnchorEl1}
        openPracticas={openPracticas}
        setOpenPracticas={setOpenPracticas}
        grade={grade}
        relajacionList={relajacionList.length > 0 && relajacionList}
      />
      } */}
    </>
  )
}

export default PracticasTres;
