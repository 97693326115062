import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
     Contenedor:{
        background:'#E9FBF9',
        width:'100vw',
        overflowX:'clip',
        [theme.breakpoints.down(1161)]:{
            background:'none',
           overflowX:'unset',
        },
        [theme.breakpoints.down(600)]:{
            marginTop: '79.51px',
        }
    },
  ContainerInfoLectura: {
    position: "relative",
    height: "86px",
    [theme.breakpoints.down(502)]:{
        height: "43px",
    },
    background: "#76D4C3",
    "& .BarraInfoLectura": {
      height: "86px",
      background: `Url(https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Barras/Azul.png)`,
        [theme.breakpoints.down(502)]:{
            height: "43px",
        },
    },
    "& .InfoLectura": {
      position: "absolute",
      height: "86px",
      width: "219px",
      boxSizing: "border-box",
      padding: "17.2px 0",
      background: "#FAFAFA",
      borderRadius: "50px",
      boxShadow: "2px 2px 2px 2px #00000029",
      zIndex: "4",
        [theme.breakpoints.down(502)]:{
            height: "43px",
            width: "109px",
            padding: "8.6px 0",
            borderRadius: "25px",
        },
    },
    "& .InfoLectura:before": {
      position: "absolute",
      display: "flex",
      boxSizing: "border-box",
      padding: "0 0 0 10px",
      justifyContent: "center",
      alignItems: "center",
      height: "39.88px",
      width: "39.88px",
      borderRadius: "50%",
      content: '""',
      boxShadow: "3px 0px .5px 0px #00000029",
      background: "#FAFAFA",
      left: "90px",
      top: "65px",
      transform: "rotate(0.25turn)",
      [theme.breakpoints.down(502)]:{
        left: "45px",
        top: "30px",
        height: "20.88px",
        width: "20.88px",
        },
    },
    "& .InfoLectura:after": {
      content: '""',
      height: "15px",
      width: "15px",
      borderTop: "2px solid #20A698",
      borderLeft: "2px solid #20A698",
      position: "absolute",
      bottom: "-10px",
      left: "Calc(50% - 8px)",
      transform: "rotate(0.625turn)",
      [theme.breakpoints.down(502)]:{
        height: "10px",
        width: "10px",
        left: "Calc(50% - 4.5px)",
        bottom: "-3px",
        borderTop: "1px solid #20A698",
        borderLeft: "1px solid #20A698",
        },
    },
    "& .Sesion": {
      borderLeft: "2px solid #D8D8D8",
    },
    "& .Dato": {
      margin: "0 0 5px 0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#20A698",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        margin: "0 0 2.5px 0",
        },
    },
    "& .Caracter": {
      margin: "0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#000000",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        },
    },   },
    ContenedorLectura:{
        background:'#fff',
        width:'1160px',
        height:'100%',
        padding:'60px 168px 35px ',
        [theme.breakpoints.down(1161)]:{
            width:'502px',
            padding:'60px 0 0 0',
          },
        [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
        '& .ContainerDerechos':{
            bottom:'80px',
            [theme.breakpoints.down(1161)]:{
                display:'none',
            },
        },
        '& .Derechos':{
            textAlign:"center",
            margin:"0px",
            font:" 15px lato",
            color:"#717476",
        },
        '& .Escritorio':{
            display:'block',
            [theme.breakpoints.down(1161)]:{
                display:'none'
            },
        },
        '& .Tablet':{
            display:'none',
            [theme.breakpoints.down(1161)]:{
                display:'block'
            },
        }
    },
    ContainerTitulo:{
        margin:'0 0 34px 0',
        [theme.breakpoints.down(1161)]:{
            margin:'0 0 34px 0',
        },
        '& .Titulo':{
            font:'48px fredoka one',
            letterSpacing: '0px',
            lineHeight:'58px',
            color: '#F76E0D',
            margin:'0',
            textAlign:'center',
            [theme.breakpoints.down(1161)]:{
                font:'43px fredoka one',
                lineHeight:'52px',
            },
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            lineHeight : '30px',
            margin:'0 0 36px 0',
            font:'22px lato',
            color:'#000',
            letterSpacing: '0.20px',
            [theme.breakpoints.down(1161)]:{
                font:'18px lato',
                lineHeight :'28px',
                letterSpacing: '0.17px',
            },
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .SubTitulo':{
            font:'bold 30px lato',
            color:'#1350F5',
            letterSpacing:'.28px',
            lineHeight:'30px',
            margin:'0',
            [theme.breakpoints.down(1161)]:{
                lineHeight :'28px',
                font:'bold 26px lato',
                letterSpacing: '0.24px',
            },
            [theme.breakpoints.down(502)]:{
                font:'bold 18px lato',
                lineHeight :'24px',
                letterSpacing: '0.21px',
            },
        },
        '& .SubTitulo2':{
            font:'bold 26px lato',
            color:'#F21741',
            letterSpacing:'.24px',
            lineHeight:'28px',
            margin:'0',
            [theme.breakpoints.down(1161)]:{
                font:'bold 22px lato',
                lineHeight :'24px',
                letterSpacing: '0.21px',
            },
            [theme.breakpoints.down(502)]:{
                font:'bold 18px lato',
                lineHeight :'20px',
                letterSpacing: '0.16px',
            },
        },
        '& .TextImg':{
            font:'20px lato',
            color:'#717476',
            letterSpacing:'.19px',
            lineHeight:'30px',
            margin:'24px 0 0 0',
            [theme.breakpoints.down(1161)]:{
                font:' 16px lato',
                lineHeight :'25px',
                letterSpacing: '0.15px',
            },
            [theme.breakpoints.down(502)]:{
                font:' 11px lato',
                lineHeight :'14px',
                letterSpacing: '0.10px',
            },
        },
        '& .Esp':{
            margin:0,
        },
        '& .Imagenes':{
            width:'847px',
        },

        '& .Imagen':{
            width:'833px',
            [theme.breakpoints.down(1161)]:{
                width:'100%',
            },
        },
        '& .Imagen2':{
            width:'50%',
            [theme.breakpoints.down(1161)]:{
                width:'100%',
            },
        },
       
      
    }
}));

const Los_monumentos_y_la_memoria = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
             <Grid container item justifyContent="center" className={classes.ContainerInfoLectura}>
               <Grid container className="BarraInfoLectura"></Grid>
               <Grid container item className="InfoLectura">
                    <Grid container item direction="column" xs={6} justifyContent="center">
                        <p className="Dato">Grado</p>
                        <p className="Caracter">5</p>
                    </Grid>
                    <Grid container item direction="column" xs={6} justifyContent="center" className="Sesion">
                        <p className="Dato">Sesión</p>
                        <p className="Caracter">5</p>
                    </Grid>
               </Grid>
            </Grid>

            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                        <h2 className="Titulo Escritorio">Los monumentos y la memoria</h2>
                        <h2 className="Titulo Tablet">Los monumentos <br/>y la memoria</h2>

                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
            
                       <p className='Lectura Esp'>
                        ¿Qué dirías tú que es una obra de arte? ¿Qué obras de arte
                        recuerdas haber visto? Quizá algunas te han pasado inadvertidas, 
                        precisamente porque las ves todo el tiempo. Al recorrer una ciudad,
                        entre autos, edificios, personas y casas, también podemos ver obras
                        de arte. Aquí hablaremos de ellas.<br/><br/>
                        
                        <h3 className='SubTitulo'>Monumentos: arte enorme</h3><br/>
                        
                        En el camino diario a la escuela o al supermercado, cuando juegas
                        en un parque o simplemente paseas por las calles, hay obras de arte 
                        y muchas veces no son una simple decoración. También son un objeto 
                        histórico. Ciertas esculturas y edificios están colocados para recordar
                        un momento importante o un hecho conmovedor en pasado de un país. 
                        Algunas fueron construidas como homenaje para quienes 
                        realizaron actos importantes o benéficos. <br/><br/>
                          
                        Estas obras son conocidas como <em>monumentos</em>. Casi siempre son de gran tamaño, 
                        por lo que también se usa la palabra <em>monumental</em> para hablar de objetos enormes. 
                        Un ejemplo es la muy famosa Torre Eiffel en París, que mide 324 metros y
                        que fue construida en 1889 para celebrar los primeros 100 años de uno 
                        de los eventos más importantes de la historia de Francia: la Revolución francesa. <br/><br/>
                        
                        Entre los monumentos hay dos clases de arte: la escultura y la arquitectura.
                        Hay ciertos monumentos que son arquitectónicos, pues están hechos para ser 
                        recorridos, habitados y ocupados como espacio. ¿Se te ocurren algunos ejemplos? 
                        Si quieres investigarlo ¡no olvides buscar imágenes para darte una buena 
                        idea de cómo se ven! Aquí nos vamos a concentrar sobre todo en los 
                        monumentos escultóricos.

                       </p>
                      
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container >
                        <p className='Lectura'>
                            <h3 className='SubTitulo2'>Monumentos religiosos</h3><br/>

                            Entre los muchos tipos de monumentos están los religiosos, como el 
                            Cristo del Corcovado en Río de Janeiro (Brasil) o el Gran Buda de 
                            Kamakura en Japón. Suelen colocarse en espacios que sirven para 
                            llevar a cabo ceremonias o como lugares de meditación para los 
                            creyentes.
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion5/1.png" alt="Tarjeta" />
                        </p>
                    </Grid>
                    <Grid container justifyContent='center'>
                        <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion5/2.png" alt="Tarjeta" />
                    </Grid>
                    
                    

                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container >
                        <p className='Lectura'>
                            <h3 className='SubTitulo2'>Monumentos artísticos</h3><br/>
                            También existen monumentos artísticos, como las grandes figuras 
                            creadas por Fernando Botero que pueblan algunas calles de Medellín
                            (Colombia) o la enorme escultura <em>Cloud Gate</em>, conocida como “El 
                            frijol”, que está en un parque de Chicago (Estados Unidos), obra de
                            Anish Kapoor, un artista de origen indio. Estas esculturas sirven para
                            que los habitantes de un lugar puedan admirar arte sin tener que entrar
                            a un museo: ponen el arte al alcance de todos.
                        </p>
                    </Grid>
                    <Grid container justifyContent='space-between' className='Imagenes'>
                        <img className='Escritorio Imagen2' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion5/3.png" alt="Tarjeta" />
                        <img className='Escritorio Imagen2' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion5/4.png" alt="Tarjeta" />
                        <img className='Tablet Imagen2' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion5/3R.png" alt="Tarjeta" />
                        <img className='Tablet Imagen2' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion5/4R.png" alt="Tarjeta" />
                    </Grid>
                    


                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container >
                        <p className='Lectura'>
                            <h3 className='SubTitulo2'>Monumentos históricos</h3><br/>

                            Los monumentos más comunes son los históricos. ¿Has visto 
                            fotografías del Arco del Triunfo en París? Fue hecho para 
                            celebrar las victorias de Napoleón Bonaparte. A este tipo 
                            también pertenece el Obelisco que se encuentra en la avenida 
                            más importante de Buenos Aires (Argentina) y conmemora la 
                            fundación de la ciudad.

                        </p>
                    </Grid>
                    <Grid container justifyContent='space-between' className='Imagenes'>
                        <img className='Imagen' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion5/5.png" alt="Tarjeta" />
                        <img className='Imagen' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion5/6.png" alt="Tarjeta" />

                    </Grid>

                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container >
                        <p className='Lectura'>
                            <h3 className='SubTitulo'>Los monumentos y los símbolos</h3><br/>
                            
                            ¿De qué están hechos los monumentos? Por supuesto, son construidos 
                            con ciertos materiales, como la piedra o el metal. Sin embargo, a veces 
                            también están hechos a partir de simbolismos. <br/><br/>

                            Los monumentos simbólicos utilizan figuras, formas, objetos o personajes 
                            que tienen significados específicos.  Un ejemplo de eso es la Columna de
                            la Independencia, en la Ciudad de México. En la cima de esta altísima 
                            columna hay una mujer, con alas extendidas. En una mano sostiene una 
                            cadena rota, y en la otra una corona de laurel. La mujer es una Victoria
                            alada, una diosa romana que simboliza el triunfo mexicano en la Guerra 
                            de Independencia. La cadena rota es un símbolo de la libertad ganada. 
                            La corona de laurel se le coloca a los héroes victoriosos y la Victoria 
                            alada la está levantando como para ponérsela a quienes dieron la vida 
                            por su país. 
                            
                           
                        </p>
                    </Grid>
                    <Grid container justifyContent='space-between' className='Imagenes'>
                        <img className='Imagen' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion5/7.png" alt="Tarjeta" />
                    </Grid>

                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container >
                        <p className='Lectura'>
                            Pero no todas las esculturas tienen símbolos. Algunas sólo 
                            representan personajes históricos. Otras son completamente 
                            geométricas. Son los artistas quienes deciden el diseño de 
                            estas obras, para que todos aquellos que las miren al andar 
                            por las calles puedan recordar hechos, personas y valores 
                            importantes para la historia de su país, de su pueblo o de 
                            su cultura. Los monumentos se crean, se colocan y se 
                            observan para no olvidar.
                        </p>
                    </Grid>
                </Grid>
               
               
            <Grid container item xs={12} justifyContent="center" className="ContainerDerechos">
                <p className="Derechos">
                    © 2022. Todos los derechos reservados, Pearson, Circuito de lectores.
                </p>                
            </Grid>
        </Grid>
    </Grid>
    );
}
 
export default Los_monumentos_y_la_memoria;
