import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';


const useStyles =  makeStyles (theme =>({
      Contenedor:{
        background:'#E9FBF9',
        width:'100vw',
        overflowX:'clip',
        [theme.breakpoints.down(1161)]:{
            background:'none',
           overflowX:'unset',
        },
        [theme.breakpoints.down(600)]:{
            marginTop: '79.51px',
        }
    },
 ContainerInfoLectura: {
    position: "relative",
    height: "86px",
    [theme.breakpoints.down(502)]:{
        height: "43px",
    },
    background: "#76D4C3",
    "& .BarraInfoLectura": {
      height: "86px",
      background: `Url(https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Barras/Azul.png)`,
        [theme.breakpoints.down(502)]:{
            height: "43px",
        },
    },
    "& .InfoLectura": {
      position: "absolute",
      height: "86px",
      width: "219px",
      boxSizing: "border-box",
      padding: "17.2px 0",
      background: "#FAFAFA",
      borderRadius: "50px",
      boxShadow: "2px 2px 2px 2px #00000029",
      zIndex: "4",
        [theme.breakpoints.down(502)]:{
            height: "43px",
            width: "109px",
            padding: "8.6px 0",
            borderRadius: "25px",
        },
    },
    "& .InfoLectura:before": {
      position: "absolute",
      display: "flex",
      boxSizing: "border-box",
      padding: "0 0 0 10px",
      justifyContent: "center",
      alignItems: "center",
      height: "39.88px",
      width: "39.88px",
      borderRadius: "50%",
      content: '""',
      boxShadow: "3px 0px .5px 0px #00000029",
      background: "#FAFAFA",
      left: "90px",
      top: "65px",
      transform: "rotate(0.25turn)",
      [theme.breakpoints.down(502)]:{
        left: "45px",
        top: "30px",
        height: "20.88px",
        width: "20.88px",
        },
    },
    "& .InfoLectura:after": {
      content: '""',
      height: "15px",
      width: "15px",
      borderTop: "2px solid #20A698",
      borderLeft: "2px solid #20A698",
      position: "absolute",
      bottom: "-10px",
      left: "Calc(50% - 8px)",
      transform: "rotate(0.625turn)",
      [theme.breakpoints.down(502)]:{
        height: "10px",
        width: "10px",
        left: "Calc(50% - 4.5px)",
        bottom: "-3px",
        borderTop: "1px solid #20A698",
        borderLeft: "1px solid #20A698",
        },
    },
    "& .Sesion": {
      borderLeft: "2px solid #D8D8D8",
    },
    "& .Dato": {
      margin: "0 0 5px 0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#20A698",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        margin: "0 0 2.5px 0",
        },
    },
    "& .Caracter": {
      margin: "0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#000000",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        },
    },   },
    ContenedorLectura:{
        position:'relative',
        background:'#fff',
        height: "100%",
        width:'1160px',
        padding:'0 0 35px',
        [theme.breakpoints.down(1161)]:{
            width:'767px',
          },
          [theme.breakpoints.down(767)]:{
            width:'350px',
          },
        '& .ContainerDerechos':{
            bottom:'80px',
            [theme.breakpoints.down(1161)]:{
                display:'none',
            },
        },
        '& .Derechos':{
            textAlign:"center",
            margin:"0px",
            font:" 15px lato",
            color:"#717476",
        },
        '& .Escritorio':{
            display:'block',
            [theme.breakpoints.down(1161)]:{
                display:'none'
            },
        },
        '& .Tablet':{
            display:'none',
            [theme.breakpoints.down(1161)]:{
                display:'block'
            },
        },
        '& .Text':{
            font:'bold 29px mali',
            color:'#353535',
            letterSpacing: '0.27px',
            margin:'0',
            position:'absolute',
            textAlign:'center',
            display:'block',
            [theme.breakpoints.down(1161)]:{
                font:'bold 18px mali',
                letterSpacing: '0.17px',
            },
            [theme.breakpoints.down(768)]:{
                font:'bold 9px mali',
                letterSpacing: '0.07px',
                lineHeight:'11px',
            },
            
        },
        '& .Text1':{
            left:'845px',
            top:'950px',
            width:'280px',
            [theme.breakpoints.down(1161)]:{
                left:'563px',
                top:'625px',
                width:'174px',
            },
            [theme.breakpoints.down(768)]:{
                left:'252px',
                top:'286px',
                width:'90px',
            },
        },
        '& .Text2':{
            left:'117px',
            top:'1420px',
            width:'328px',
            [theme.breakpoints.down(1161)]:{
                left:'72px',
                top:'941px',
                width:'204px',
            },
            [theme.breakpoints.down(768)]:{
                left:'30px',
                top:'427px',
                width:'100px',
            },
        },
        '& .Text3':{
            left:'95px',
            top:'1660px',
            width:'245px',
            [theme.breakpoints.down(1161)]:{
                left:'65px',
                top:'1094px',
                width:'152px',
            },
            [theme.breakpoints.down(768)]:{
                top: '500px',
                left: '29px',
                width: '76px',
            },
        },
        '& .Text4':{
            left:'840px',
            top:'1645px',
            width:'245px',
            [theme.breakpoints.down(1161)]:{
                left:'563px',
                top:'1087px',
                width:'140px',
            },
            [theme.breakpoints.down(768)]:{
                top: '495px',
                left: '250px',
                width: '80px',
            },
        },
        '& .Text5':{
            left:'495px',
            top:'2340px',
            width:'200px',
            [theme.breakpoints.down(1161)]:{
                left:'325px',
                top:'1549px',
                width:'122px',
            },
            [theme.breakpoints.down(768)]:{
                left:'138px',
                top:'706px',
                width:'80px',
            },
        },
        '& .Text6':{
            left:'425px',
            top:'2560px',
            width:'330px',
            [theme.breakpoints.down(1161)]:{
                left:'282px',
                top:'1696px',
                width:'204px',
            },
            [theme.breakpoints.down(768)]:{
                left:'122px',
                top:'772px',
                width:'110px',
            },
        },
        '& .Text7':{
            left:'110px',
            top:'3040px',
            width:'170px',
            [theme.breakpoints.down(1161)]:{
                left:'70px',
                top:'2011px',
                width:'110px',
            },
            [theme.breakpoints.down(768)]:{
                left:'30px',
                top:'917px',
                width:'60px',
            },
        },
        '& .Text8':{
            left:'555px',
            top:'2910px',
            width:'220px',
            [theme.breakpoints.down(1161)]:{
                left:'370px',
                top:'1926px',
                width:'134px',
            },
            [theme.breakpoints.down(768)]:{
                top: '878px',
                left: '170px',
                width: '65px',
            },
        },
        '& .Text9':{
            left:'122px',
            top:'3570px',
            width:'263px',
            [theme.breakpoints.down(1161)]:{
                left:'80px',
                top:'2363px',
                width:'163px',
            },
            [theme.breakpoints.down(768)]:{
                left:'33px',
                top:'1078px',
                width:'90px',
            },
        },
        '& .Text10':{
            left:'850px',
            top:'3440px',
            width:'212px',
            [theme.breakpoints.down(1161)]:{
                left:'570px',
                top:'2278px',
                width:'132px',
            },
            [theme.breakpoints.down(768)]:{
                left:'256px',
                top:'1038px',
                width:'64px',
            },
        },
        '& .Text11':{
            left:'97px',
            top:'3950px',
            width:'190px',
            [theme.breakpoints.down(1161)]:{
                left:'64px',
                top:'2612px',
                width:'118px',
            },
            [theme.breakpoints.down(768)]:{
                left:'30px',
                top:'1191px',
                width:'56px',
            },
        },
        '& .Text12':{
            left:'460px',
            top:'4300px',
            width:'180px',
            [theme.breakpoints.down(1161)]:{
                left:'306px',
                top:'2844px',
                width:'112px',
            },
            [theme.breakpoints.down(768)]:{
                left:'138px',
                top:'1298px',
                width:'53px',
            },
        },
        '& .Text13':{
            left:'515px',
            top:'4610px',
            width:'215px',
            [theme.breakpoints.down(1161)]:{
                left:'342px',
                top:'3050px',
                width:'134px',
            },
            [theme.breakpoints.down(768)]:{
                left:'152px',
                top:'1390px',
                width:'68px',
            },
        },
        '& .Text14':{
            left:'142px',
            top:'4875px',
            width:'320px',
            [theme.breakpoints.down(1161)]:{
                left:'94px',
                top:'3227px',
                width:'198px',
            },
            [theme.breakpoints.down(768)]:{
                left:'45px',
                top:'1470px',
                width:'100px',
            },
        },
        '& .Text15':{
            left:'393px',
            top:'5930px',
            width:'230px',
            [theme.breakpoints.down(1161)]:{
                left:'262px',
                top:'3923px',
                width:'144px',
            },
            [theme.breakpoints.down(768)]:{
                left:'118px',
                top:'1788px',
                width:'69px',
            },
        },
        '& .Text16':{
            left:'790px',
            top:'5960px',
            width:'292px',
            [theme.breakpoints.down(1161)]:{
                left:'523px',
                top:'3944px',
                width:'182px',
            },
            [theme.breakpoints.down(768)]:{
                left:'235px',
                top:'1796px',
                width:'91px',
            },
        },
        '& .Text17':{
            left:'753px',
            top:'6680px',
            width:'98px',
            [theme.breakpoints.down(1161)]:{
                left:'497px',
                top:'4418px',
                width:'60px',
            },
            [theme.breakpoints.down(768)]:{
                left:'226px',
                top:'2013px',
                width:'30px',
            },
        },
        '& .Text18':{
            left:'175px',
            top:'6870px',
            width:'340px',
            [theme.breakpoints.down(1161)]:{
                left:'116px',
                top:'4545px',
                width:'210px',
            },
            [theme.breakpoints.down(768)]:{
                left:'50px',
                top:'2072px',
                width:'102px',
            },
        },
        '& .Text19':{
            left:'133px',
            top:'7640px',
            width:'302px',
            [theme.breakpoints.down(1161)]:{
                left:'88px',
                top:'5052px',
                width:'188px',
            },
            [theme.breakpoints.down(768)]:{
                left:'33px',
                top:'2306px',
                width:'100px',
            },
        },
        '& .Text20':{
            left:'620px',
            top:'7640px',
            width:'300px',
            [theme.breakpoints.down(1161)]:{
                left:'410px',
                top:'5052px',
                width:'184px',
            },
            [theme.breakpoints.down(768)]:{
                left:'184px',
                top:'2306px',
                width:'92px',
            },
        },

        '& .Text21':{
            left:'815px',
            top:'8260px',
            width:'228px',
            [theme.breakpoints.down(1161)]:{
                left:'540px',
                top:'5466px',
                width:'142px',
            },
            [theme.breakpoints.down(768)]:{
                left:'239px',
                top:'2489px',
                width:'80px',
            },
        },

        '& .Text22':{
            left:'412px',
            top:'8390px',
            width:'296px',
            [theme.breakpoints.down(1161)]:{
                left:'273px',
                top:'5553px',
                width:'184px',
            },
            [theme.breakpoints.down(768)]:{
                left:'125px',
                top:'2530px',
                width:'89px',
            },
        },

        '& .Text23':{
            left:'520px',
            top:'9005px',
            width:'166px',
            [theme.breakpoints.down(1161)]:{
                left:'346px',
                top:'5963px',
                width:'102px',
            },
            [theme.breakpoints.down(768)]:{
                left:'158px',
                top:'2715px',
                width:'46px',
            },
        },

        '& .ContainerDerechos':{
            bottom:'80px',
            [theme.breakpoints.down(1161)]:{
                display:'none'
            },
        },
        '& .Derechos':{
            textAlign:"center",
            margin:"0px",
            font:" 15px lato",
            color:"#717476",
        }  
    },
    Parrafo1:{
        margin:' 0 ',
        [theme.breakpoints.down(350)]:{
            display:'none',
        },
        '& .Imagen1':{
            width:'100%',
        },
    },
    Imagen2:{
        display:'none',
        [theme.breakpoints.down(350)]:{
            width:'100%',
            display:'flex',
        },
    }
}));

const El_yoyo_de_la_señora_chu = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
             <Grid container item justifyContent="center" className={classes.ContainerInfoLectura}>
               <Grid container className="BarraInfoLectura"></Grid>
               <Grid container item className="InfoLectura">
                    <Grid container item direction="column" xs={6} justifyContent="center">
                        <p className="Dato">Grado</p>
                        <p className="Caracter">1</p>
                    </Grid>
                    <Grid container item direction="column" xs={6} justifyContent="center" className="Sesion">
                        <p className="Dato">Sesión</p>
                        <p className="Caracter">34</p>
                    </Grid>
               </Grid>
            </Grid>

            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container justifyContent="center" sm={12}>
                        <Grid container justifyContent="center" >
                            <img className="Imagen1" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img1ro/Sesion34/1.png" alt="Tarjeta" />
                        </Grid>
                    </Grid>
                    <p className="Text Text1">¡Era emocionante conocer nuevos lugares!</p>
                    <p className="Text Text2">Zimo y su mamá llegaron a vivir hace poco a la ciudad.</p>
                    <p className="Text Text3">El sábado fueron al museo.</p>
                    <p className="Text Text4">El lunes, Zimo fue a su nueva escuela.</p>
                    <p className="Text Text5">El domingo visitaron<br/> el zoológico.</p>
                    <p className="Text Text6">De regreso a casa, Zimo pensaba en <br/>todo lo que iba <br/>a contarle a su mamá.</p>
                    <p className="Text Text7">¿Mamá? ¿Estás ahí?</p>
                    <p className="Text Text8">Hola, Zimo. Soy la señora Chu. Yo te cuidaré hasta que llegue<br/> tu mamá<br/> del trabajo.</p>
                    <p className="Text Text9">Zimo no estaba muy contento <br/>con la situación…</p>
                    <p className="Text Text10">¡Si mi mamá no está, iré a mi cuarto <br/>a ver <br/>televisión!</p>
                    <p className="Text Text11">¿Zimo? ¿Estás ahí? ¿Por qué no sales <br/>a jugar?</p>
                    <p className="Text Text12">Mira, tengo algo que mostrarte.</p>
                    <p className="Text Text13">Es sólo un tonto yoyo. ¡Los yoyos <br/>son aburridos!</p>
                    <p className="Text Text14">¿Aburridos? <br/>¡No lo creo! <br/>De pequeña era <br/> mi juguete favorito. Estoy segura de que todavía puedo hacer algunos trucos.</p>
                    <p className="Text Text15">¡Zimo no podía creer todos <br/>los trucos que <br/>la señora Chu <br/>podía hacer!</p>
                    <p className="Text Text16">¡Es increíble! ¿Puede enseñarme?</p>
                    <p className="Text Text17">¡Claro!</p>
                    <p className="Text Text18">Zimo y la señora Chu pasaron toda la tarde jugando al yoyo.</p>
                    <p className="Text Text19">Al final de la tarde, la mamá de Zimo llegó por él.</p>
                    <p className="Text Text20">¡Mamá! ¡Mira todo lo que puedo hacer!</p>
                    <p className="Text Text21">¡Gracias por cuidar a Zimo, señora Chu!</p>
                    <p className="Text Text22">¡Sí, gracias! <br/>¡Tenía razón! <br/>Los yoyos no <br/>son tontos. <br/>¿Me puede enseñar <br/>más trucos la <br/>próxima vez?</p>
                    <p className="Text Text23">¡Claro, Zimo! Nos vemos mañana.</p>

                    
                </Grid>
                <img className={classes.Imagen2} src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img1ro/Sesion34/F3.png" alt="Tarjeta" />

            <Grid container item xs={12} justifyContent="center" className="ContainerDerechos">
                <p className="Derechos">
                    © 2022. Todos los derechos reservados, Pearson, Circuito de lectores.
                </p>                
            </Grid>
        </Grid>
    </Grid>
    );
}
 
export default El_yoyo_de_la_señora_chu ;
