import React from "react";
import Chart from "react-apexcharts";

const ProgressBar = (props) => {
  const options = {
    chart: {
      type: "radialBar",
      offsetY: 0,
      sparkline: {
        enabled: false,
      },
      animations: {
        easing: "easeinin",
        speed: 9000,
        animateGradually: {
          enabled: true,
          delay: 150,
        },
        dynamicAnimation: {
          enabled: true,
          speed: 10,
        },
      },
    },

    colors: ["#2EDAC9"],
    stroke: {
      lineCap: "round",
    },

    plotOptions: {
      radialBar: {
        startAngle: -90,
        endAngle: 90,
        track: {
          background: "#F6644B",
          strokeWidth: "100%",
          margin: 30, // margin is in pixels
          dropShadow: {
            enabled: false,
            top: 0,
            left: 0,
            color: "#999",
            opacity: 0,
            blur: 0,
          },
        },
        dataLabels: {
          name: {
            show: false,
          },
          value: {
            show: false,
          },
        },
      },
    },
    grid: {
      padding: {
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
      },
    },
    fill: {
      type: "gradient",
      gradient: {
        shade: "light",
        shadeIntensity: 0,
        inverseColors: false,
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 0, 0, 0],
      },
    },
  };

  let num = props.num;
  const series = [(num * 100) / 60];

  return (
    <Chart
      options={options}
      series={series}
      type="radialBar"
      height="360px"
      width="280px"
    />
  );
};
export default ProgressBar;
