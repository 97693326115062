import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
    ContenedorLectura:{
        width:'502px',
        [theme.breakpoints.down(502)]:{
            width:'350px',
        },
    },
    ContainerTitulo:{
        margin:'0 0 46px 0',
        '& .Titulo':{
            textAlign:'center',
            letterSpacing: '0px',
            color: '#0CBF88',
            margin:'0',
            font:'43px fredoka one',
            lineHeight:'52px',
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        position:'relative',
        margin:' 0 0 36px 0',
        '& .Lectura':{
            margin:'0',
            color:'#000',
            lineHeight :'28px',
            font:'18px lato',
            letterSpacing: '0.17px',
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .Lectura2':{
            color:'#000',
                lineHeight :'28px',
                font:'18px lato',
                letterSpacing: '0.16px',
                margin:'0 0 0 7.5px',
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .SubTitulo':{
            color:'#FF0900',
            margin:'0',
            font:'bold 26px lato',
            letterSpacing:'.24px',
            [theme.breakpoints.down(502)]:{
                font:'bold 18px lato',
                lineHeight :'24px',
                letterSpacing: '0.21px',
            },
        },
        '& .Niña':{
            font:'18px  Fredoka One',
            letterSpacing:'.17px',
            [theme.breakpoints.down(502)]:{
                font:'14px  Fredoka One',
                letterSpacing:'.17px',
            },
        },
        '& .Sofia':{
            color:'#F64B93',
        },
        '& .Fatima':{
            color:'#10C4B6',
        },
        '& .Amelia':{
            color:'#4500FF',
        },
        '& .Imagen':{
            width:'100%',
        },
        '& .Pos':{
            position:'absolute',
             width:'48.55px',
        },
        '& .img':{
            bottom:'18px',
        },
        '& .img2':{
            top:'-6px',
        },
        '& .img3':{
            bottom:'103px',
        },
        '& .img4':{
            bottom:'45px',
        },
        '& .img5':{
            bottom:'75px',
        },
        '& .Linea':{
            height:'17.5px',
            borderBottom:'solid 6px #0CBF88',
        },
    },
}));

const La_despedida = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>

            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item xs={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >La despedida</h2>
                </Grid>
                <Grid container item xs={12} className={classes.Parrafo1} >
                        <p className='Lectura'>
                            <h3 className='SubTitulo'>ACTO 1</h3><br/>
                            <em >Afuera del salón de clases. Se escucha a lo lejos un murmullo de niños jugando. Fátima y 
                            Sofía están sentadas en una banca, sosteniendo sus almuerzos. Es la hora del receso.
                            </em> 
                        </p>
                </Grid>
                
                    <Grid container item xs={12} className={classes.Parrafo1} >
                            <Grid container justifyContent="center" xs={1}><img className='Pos img' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion6/S.png" alt="" /></Grid>
                            <Grid container xs={11}>
                                <p className='Lectura2'>
                                    <b className='Sofia'>SOFÍA.</b> <em>(Sin comprender lo que acaba de escuchar)</em> ¿O sea  <br/>
                                    que ya no nos veremos?
                                </p>
                            </Grid>
                            
                    </Grid>

                    <Grid container item xs={12} className={classes.Parrafo1} >
                        <Grid container justifyContent="center" xs={1}><img className='Pos img2' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion6/F.png" alt="" /></Grid>
                        <Grid container xs={11}>
                            <p className='Lectura2'>
                                <b className='Niña Fatima'>FÁTIMA.</b> <em>(Triste)</em> No.
                            </p>
                        </Grid>
                    </Grid> 

                    <Grid container item xs={12} className={classes.Parrafo1} >
                        <Grid container justifyContent="center" xs={1}><img className='Pos img3' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion6/S.png" alt="" /></Grid>
                        <Grid container xs={11}>
                            <p className='Lectura2'>
                                <b className='Niña Sofia'>SOFÍA.</b> <em>(Abraza con fuerza a Fátima)</em> ¡Noooo! ¡No <br/>
                                te vayas! <em>(Se escucha que llora un poco. De repente, deja de<br/>
                                abrazarla) </em> ¡Ya sé! ¡Tengo una idea! ¿Y si le digo a mi<br/> 
                                mamá que también a mí me cambie de escuela? ¡Así nos <br/>
                                vamos juntas!
                            </p>
                        </Grid>
                    </Grid>

                    <Grid container item xs={12} className={classes.Parrafo1} >
                        <Grid container justifyContent="center" xs={1}><img className='Pos img2' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion6/F.png" alt="" /></Grid>
                        <Grid container xs={11}>
                            <p className='Lectura2'>
                                <b className='Niña Fatima'>FÁTIMA.</b> No creo que quieran mis papás.
                            </p>
                        </Grid>
                    </Grid> 

                    <Grid container item xs={12} className={classes.Parrafo1} >
                        <Grid container justifyContent="center" xs={1}><img className='Pos img' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion6/S.png" alt="" /></Grid>
                        <Grid container xs={11}>
                            <p className='Lectura2'>
                                <b className='Niña Sofia'>SOFÍA.</b> <em>(Sorprendida)</em> Pero ¿por qué? ¿A tus papás  <br/>
                                no les gusta que yo sea tu ? amiga
                            </p>
                        </Grid>
                    </Grid>   

               
                <Grid container item xs={12} className={classes.Parrafo1} >
                    <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion6/1.png" alt="" />
                </Grid> 

                <Grid container item xs={12} className={classes.Parrafo1} >
                    <Grid container justifyContent="center" xs={1}><img className='Pos img2' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion6/F.png" alt="" /></Grid>
                    <Grid container xs={11}>
                        <p className='Lectura2'>
                            <b className='Niña Fatima'>FÁTIMA.</b> No, no es eso. Le ofrecieron un trabajo a mi papá en otra ciudad.
                        </p>
                    </Grid>
                </Grid> 

                    <Grid container item xs={12} className={classes.Parrafo1} >
                        <Grid container justifyContent="center" xs={1}><img className='Pos img' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion6/S.png" alt="" /></Grid>
                        <Grid container xs={11}>
                            <p className='Lectura2'>
                                <b className='Niña Sofia'>SOFÍA.</b> Pero puede irse él solo, ¿no? Así le hizo mi papá <br/> 
                                cuando nos dejó a mi  mamá y a mí.
                            </p>
                        </Grid>
                    </Grid>  

                    <Grid container item xs={12} className={classes.Parrafo1} >
                        <Grid container justifyContent="center" xs={1}><img className='Pos img2' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion6/F.png" alt="" /></Grid>
                        <Grid container xs={11}>
                            <p className='Lectura2'>
                                <b className='Niña Fatima'>FÁTIMA.</b> No. Él quiere que nos vayamos todos juntos.
                            </p>
                        </Grid>
                    </Grid> 
                    
                    <Grid container item xs={12} className={classes.Parrafo1} >
                        <Grid container justifyContent="center" xs={1}><img className='Pos img2' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion6/S.png" alt="" /></Grid>
                        <Grid container xs={11}>
                            <p className='Lectura2'>
                                <b className='Niña Sofia'>SOFÍA.</b> Pero… ¡Ay, Fátima, no te vayas!<em> (La abraza otra vez)</em>.
                            </p>
                        </Grid>
                    </Grid>  

                    <Grid container item xs={12} className={classes.Parrafo1} >
                        <Grid container justifyContent="center" xs={1}><img className='Pos img' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion6/F.png" alt="" /></Grid>
                        <Grid container xs={11}>
                            <p className='Lectura2'>
                                <b className='Niña Fatima'>FÁTIMA.</b> Yo tampoco quiero irme, pero dice mi mamá <br/> 
                                que allá conoceré a más niños, que haré nuevos amigos.
                            </p> 
                        </Grid>
                    </Grid> 
                    
                    <Grid container item xs={12} className={classes.Parrafo1} >
                        <Grid container justifyContent="center" xs={1}><img className='Pos img2' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion6/S.png" alt="" /></Grid>
                        <Grid container xs={11}>
                            <p className='Lectura2'>
                                <b className='Niña Sofia'>SOFÍA.</b> <em>(Enojada)</em> ¿Y yo qué? Yo soy tu mejor amiga.
                            </p>
                        </Grid>
                    </Grid>  

                    <Grid container item xs={12} className={classes.Parrafo1} >
                        <Grid container justifyContent="center" xs={1}><img className='Pos img4' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion6/F.png" alt="" /></Grid>
                        <Grid container xs={11}>
                            <p className='Lectura2'>
                                <b className='Niña Fatima'>FÁTIMA.</b> También podremos hablarnos. Le puedo pedir <br/>
                                a mi mamá que me  preste su teléfono para llamarte. Mi <br/>
                                mamá le pidió a tu mamá su número.
                            </p>
                        </Grid>
                    </Grid> 
                    
                    <Grid container item xs={12} className={classes.Parrafo1} >
                        <Grid container justifyContent="center" xs={1}><img className='Pos img2' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion6/S.png" alt="" /></Grid>
                        <Grid container xs={11}>
                            <p className='Lectura2'>
                                <b className='Niña Sofia'>SOFÍA. </b><em>(Feliz)</em> ¡Sí! (Se entristece de repente) Pero no será<br/>lo mismo.
                            </p>
                        </Grid>
                    </Grid>  

                    <Grid container item xs={12} className={classes.Parrafo1} >
                        <Grid container justifyContent="center" xs={1}><img className='Pos img2' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion6/F.png" alt="" /></Grid>
                        <Grid container xs={11}>
                            <p className='Lectura2'>
                                <b className='Niña Fatima'>FÁTIMA.</b> En las vacaciones podría venir a visitarte. O… <br/>
                                tú a mí.
                            </p>
                        </Grid>
                    </Grid> 
                    
                    <Grid container item xs={12} className={classes.Parrafo1} >
                        <Grid container justifyContent="center" xs={1}><img className='Pos img2' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion6/S.png" alt="" /></Grid>
                        <Grid container xs={11}>
                            <p className='Lectura2'>
                                <b className='Niña Sofia'>SOFÍA.</b> Sabes que no será lo mismo.
                            </p>
                        </Grid>
                    </Grid>  

                    <Grid container item xs={12} className={classes.Parrafo1} >
                        <Grid container justifyContent="center" xs={1}><img className='Pos img5' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion6/F.png" alt="" /></Grid>
                        <Grid container xs={11}>
                            <p className='Lectura2'>
                                <b className='Niña Fatima'>FÁTIMA.</b> Ya verás que será divertido cuando nos <br/>
                                volvamos a ver. Nos contaremos muchas cosas y <br/>
                                jugaremos mucho. (Le ofrece su almuerzo) ¿Quieres un <br/>
                                poco?
                            </p>
                        </Grid>
                    </Grid> 

                    <Grid container item xs={12} className={classes.Parrafo1} >
                        <Grid container xs={12} justifyContent='center'>
                                <p className='Lectura2'> <em>Telón</em> </p>
                        </Grid>
                        <Grid container xs={12} className='Linea'>
                            
                        </Grid>
                    </Grid> 

                    <Grid container item xs={12} className={classes.Parrafo1} >
                        <p className='Lectura'>
                            <h3 className='SubTitulo'>ACTO 2</h3><br/>
                            <em>Afuera del salón de clases. Se escucha a lo lejos un murmullo de  
                            niños jugando. Sofía está sentada en una banca, comiendo su 
                            almuerzo y hablando consigo misma enojada.
                            </em> 
                        </p>
                    </Grid>

                    <Grid container item xs={12} className={classes.Parrafo1} >
                        <Grid container justifyContent="center" xs={1}><img className='Pos img5' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion6/S2.png" alt="" /></Grid>
                        <Grid container xs={11}>
                            <p className='Lectura2'>
                                <b className='Niña Sofia'>SOFÍA.</b> Uy, sí, muy divertido. Pero divertido para ti,<br/> 
                                porque yo me aburro mucho ahora que no estás. Y ya no <br/> 
                                tengo a nadie con quién hablar. Pero tú de seguro ya<br/>
                                hiciste nuevas amigas…
                            </p>
                        </Grid>
                    </Grid>  

                    <Grid container item xs={12} className={classes.Parrafo1} >
                            <p className='Lectura2'>
                                <em>Entra Amelia</em>
                            </p>
                    </Grid>  

                    <Grid container item xs={12} className={classes.Parrafo1} >
                        <Grid container justifyContent="center" xs={1}><img className='Pos img2' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion6/A.png" alt="" /></Grid>
                        <Grid container xs={11}>
                            <p className='Lectura2'>
                                <b className='Niña Amelia'>AMELIA.</b> ¿Con quién hablas?
                            </p>
                        </Grid>
                    </Grid>

                    <Grid container item xs={12} className={classes.Parrafo1} >
                        <Grid container justifyContent="center" xs={1}><img className='Pos img2' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion6/S2.png" alt="" /></Grid>
                        <Grid container xs={11}>
                            <p className='Lectura2'>
                                <b className='Niña Sofia'>SOFÍA.</b><em> (Apenada) </em>¡Eh! ¡No! ¡Con nadie!
                            </p>
                        </Grid>
                    </Grid>  

                    <Grid container item xs={12} className={classes.Parrafo1} >
                        <Grid container justifyContent="center" xs={1}><img className='Pos img2' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion6/A.png" alt="" /></Grid>
                        <Grid container xs={11}>
                            <p className='Lectura2'>
                                <b className='Niña Amelia'>AMELIA.</b> ¿Con Fátima?
                            </p>
                        </Grid>
                    </Grid>

                    <Grid container item xs={12} className={classes.Parrafo1} >
                        <Grid container justifyContent="center" xs={1}><img className='Pos img2' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion6/S2.png" alt="" /></Grid>
                        <Grid container xs={11}>
                            <p className='Lectura2'>
                                <b className='Niña Sofia'>SOFÍA.</b> ¡No, cómo crees! Si ella ya se fue.
                            </p>
                        </Grid>
                    </Grid> 

                    <Grid container item xs={12} className={classes.Parrafo1} >
                        <Grid container justifyContent="center" xs={1}><img className='Pos img2' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion6/A.png" alt="" /></Grid>
                        <Grid container xs={11}>
                            <p className='Lectura2'>
                                <b className='Niña Amelia'>AMELIA.</b> ¿Y no la extrañas? Yo siempre las veía juntas.
                            </p>
                        </Grid>
                    </Grid> 

                    <Grid container item xs={12} className={classes.Parrafo1} >
                        <Grid container justifyContent="center" xs={1}><img className='Pos img2' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion6/S2.png" alt="" /></Grid>
                        <Grid container xs={11}>
                            <p className='Lectura2'>
                                <b className='Niña Sofia'>SOFÍA.</b> No, no la extraño. Estoy bien sola.
                            </p>
                        </Grid>
                    </Grid>

                    <Grid container item xs={12} className={classes.Parrafo1} >
                        <Grid container justifyContent="center" xs={1}><img className='Pos img2' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion6/A.png" alt="" /></Grid>
                        <Grid container xs={11}>
                            <p className='Lectura2'>
                                <b className='Niña Amelia'>AMELIA.</b> ¿No te aburre estar sola?
                            </p>
                        </Grid>
                    </Grid>  
                    
                    <Grid container item xs={12} className={classes.Parrafo1} >
                        <Grid container justifyContent="center" xs={1}><img className='Pos img2' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion6/S2.png" alt="" /></Grid>
                        <Grid container xs={11}>
                            <p className='Lectura2'>
                                <b className='Niña Sofia'>SOFÍA.</b> No, para nada.
                            </p>
                        </Grid>
                    </Grid>

                    <Grid container item xs={12} className={classes.Parrafo1} >
                        <Grid container justifyContent="center" xs={1}><img className='Pos img2' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion6/A.png" alt="" /></Grid>
                        <Grid container xs={11}>
                            <p className='Lectura2'>
                                <b className='Niña Amelia'>AMELIA.</b> ¿Me puedo sentar? <em>(Se sienta junto a Sofía)</em> Yo <br/>también la extraño.
                            </p>
                        </Grid>
                    </Grid>

                    <Grid container item xs={12} className={classes.Parrafo1} >
                        <Grid container justifyContent="center" xs={1}><img className='Pos img2' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion6/S2.png" alt="" /></Grid>
                        <Grid container xs={11}>
                            <p className='Lectura2'>
                                <b className='Niña Sofia'>SOFÍA.</b> ¡Yo no la extraño! ¿Qué quieres?
                            </p>
                        </Grid>
                    </Grid>  

                    <Grid container item xs={12} className={classes.Parrafo1} >
                        <Grid container justifyContent="center" xs={1}><img className='Pos img2' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion6/A.png" alt="" /></Grid>
                        <Grid container xs={11}>
                            <p className='Lectura2'>
                                <b className='Niña Amelia'>AMELIA.</b> <em>(Señala el almuerzo de Sofía)</em> ¿Me das un poco?
                            </p>
                        </Grid>
                    </Grid>

                    <Grid container item xs={12} className={classes.Parrafo1} >
                        <Grid container justifyContent="center" xs={1}><img className='Pos img2' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion6/S2.png" alt="" /></Grid>
                        <Grid container xs={11}>
                            <p className='Lectura2'>
                                <b className='Niña Sofia'>SOFÍA.</b> ¡No! ¡Sólo le comparto a Fátima!
                            </p>
                        </Grid>
                    </Grid> 

                    <Grid container item xs={12} className={classes.Parrafo1} >
                        <Grid container justifyContent="center" xs={1}><img className='Pos img2' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion6/A.png" alt="" /></Grid>
                        <Grid container xs={11}>
                            <p className='Lectura2'>
                                <b className='Niña Amelia'>AMELIA.</b> ¿Quieres jugar? Nos falta una niña para <br/>completar.
                            </p>
                        </Grid>
                    </Grid>

                    <Grid container item xs={12} className={classes.Parrafo1} >
                        <Grid container justifyContent="center" xs={1}><img className='Pos img2' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion6/S2.png" alt="" /></Grid>
                        <Grid container xs={11}>
                            <p className='Lectura2'>
                                <b className='Niña Sofia'>SOFÍA.</b> ¿A qué están jugando?
                            </p>
                        </Grid>
                    </Grid> 

                    <Grid container item xs={12} className={classes.Parrafo1} >
                        <Grid container justifyContent="center" xs={1}><img className='Pos img2' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion6/A.png" alt="" /></Grid>
                        <Grid container xs={11}>
                            <p className='Lectura2'>
                                <b className='Niña Amelia'>AMELIA.</b> Ven, te enseño.
                            </p>
                        </Grid>
                    </Grid>                          
                                        
                

                <Grid container item xs={12} className={classes.Parrafo1} >
                    <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion6/2.png" alt="" />
                </Grid>
                
                    <Grid container item xs={12} className={classes.Parrafo1} >
                        <Grid container justifyContent="center" xs={1}><img className='Pos img2' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion6/S2.png" alt="" /></Grid>
                        <Grid container xs={11}>
                            <p className='Lectura2'>
                                <b className='Niña Sofia'>SOFÍA.</b> ¿En serio puedo jugar con ustedes?
                            </p>
                        </Grid>
                    </Grid> 

                    <Grid container item xs={12} className={classes.Parrafo1} >
                        <Grid container justifyContent="center" xs={1}><img className='Pos img4' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion6/A.png" alt="" /></Grid>
                        <Grid container xs={11}>
                            <p className='Lectura2'>
                                <b className='Niña Amelia'>AMELIA.</b> Sí, claro. Ni modo que te quedes todo el  <br/>
                                tiempo aquí, sin hacer nada. O, si lo prefieres, te dejo <br/>estar sola.
                            </p>
                        </Grid>
                    </Grid>

                    <Grid container item xs={12} className={classes.Parrafo1} >
                        <Grid container justifyContent="center" xs={1}><img className='Pos img2' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion6/S2.png" alt="" /></Grid>
                        <Grid container xs={11}>
                            <p className='Lectura2'>
                                <b className='Niña Sofia'>SOFÍA.</b> ¡No, claro que no! Vamos.
                            </p>
                        </Grid>
                    </Grid> 

                    <Grid container item xs={12} className={classes.Parrafo1} >
                            <p className='Lectura2'>
                                <em>Sofía y Amelia salen. El almuerzo de Sofía se queda en la banca.</em>
                            </p>
                    </Grid> 

                    <Grid container item xs={12} className={classes.Parrafo1} >
                        <Grid container xs={12} justifyContent='center'>
                                <p className='Lectura2'> <em>Telón</em> </p>
                        </Grid>
                        <Grid container xs={12} className='Linea'>
                            
                        </Grid>
                    </Grid> 

                    <Grid container item xs={12} className={classes.Parrafo1} >
                        <p className='Lectura'>
                            <h3 className='SubTitulo'>ACTO 3</h3><br/>
                            <em>Sala-comedor de una casa. Sofía está recostada en un sillón mientras habla vía celular.</em> 
                        </p>
                    </Grid>

                    <Grid container item xs={12} className={classes.Parrafo1} >
                        <Grid container justifyContent="center" xs={1}><img className='Pos img4' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion6/S3.png" alt="" /></Grid>
                        <Grid container xs={11}>
                            <p className='Lectura2'>
                                <b className='Niña Sofia'>SOFÍA.</b> …y la maestra Leticia sólo dijo: “¿Dónde están<br/>
                                 mis lentes?” <em>(Ríe)</em> Y nadie le dijo que los traía en la<br/>
                                 cabeza.
                            </p>
                        </Grid>
                    </Grid> 

                    <Grid container item xs={12} className={classes.Parrafo1} >
                        <Grid container justifyContent="center" xs={1}><img className='Pos img2' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion6/F2.png" alt="" /></Grid>
                        <Grid container xs={11}>
                            <p className='Lectura2'>
                                <b className='Niña Fatima'>VOZ DE FÁTIMA.</b> <em>(Ríe)</em> De seguro le echó la culpa a <br/>Santiago.
                            </p>
                        </Grid>
                    </Grid> 

                    <Grid container item xs={12} className={classes.Parrafo1} >
                        <Grid container justifyContent="center" xs={1}><img className='Pos img2' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion6/S3.png" alt="" /></Grid>
                        <Grid container xs={11}>
                            <p className='Lectura2'>
                                <b className='Niña Sofia'>SOFÍA.</b> <em>(Deja de reír)</em> ¿Quién es Santiago?
                            </p>
                        </Grid>
                    </Grid> 

                    <Grid container item xs={12} className={classes.Parrafo1} >
                        <Grid container justifyContent="center" xs={1}><img className='Pos img2' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion6/F2.png" alt="" /></Grid>
                        <Grid container xs={11}>
                            <p className='Lectura2'>
                                <b className='Niña Fatima'>VOZ DE FÁTIMA.</b> <em>(Ríe)</em> De seguro le echó la culpa a Santiago.
                            </p>
                        </Grid>
                    </Grid> 

                    <Grid container item xs={12} className={classes.Parrafo1} >
                        <Grid container justifyContent="center" xs={1}><img className='Pos img2' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion6/S3.png" alt="" /></Grid>
                        <Grid container xs={11}>
                            <p className='Lectura2'>
                                <b className='Niña Sofia'>SOFÍA.</b> <em>(Deja de reír)</em> ¿Quién es Santiago?
                            </p>
                        </Grid>
                    </Grid> 
                    
                    <Grid container item xs={12} className={classes.Parrafo1} >
                        <Grid container justifyContent="center" xs={1}><img className='Pos img2' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion6/F2.png" alt="" /></Grid>
                        <Grid container xs={11}>
                            <p className='Lectura2'>
                                <b className='Niña Fatima'>VOZ DE FÁTIMA.</b> Ah, no, a Leonardo, quise decir.
                            </p>
                        </Grid>
                    </Grid> 

                    <Grid container item xs={12} className={classes.Parrafo1} >
                        <Grid container justifyContent="center" xs={1}><img className='Pos img2' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion6/S3.png" alt="" /></Grid>
                        <Grid container xs={11}>
                            <p className='Lectura2'>
                                <b className='Niña Sofia'>SOFÍA.</b> ¿Quién es Santiago?
                            </p>
                        </Grid>
                    </Grid> 

                    <Grid container item xs={12} className={classes.Parrafo1} >
                        <Grid container justifyContent="center" xs={1}><img className='Pos img2' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion6/F2.png" alt="" /></Grid>
                        <Grid container xs={11}>
                            <p className='Lectura2'>
                                <b className='Niña Fatima'>VOZ DE FÁTIMA.</b> Es un niño de mi escuela.
                            </p>
                        </Grid>
                    </Grid> 

                    <Grid container item xs={12} className={classes.Parrafo1} >
                        <Grid container justifyContent="center" xs={1}><img className='Pos img2' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion6/S3.png" alt="" /></Grid>
                        <Grid container xs={11}>
                            <p className='Lectura2'>
                                <b className='Niña Sofia'>SOFÍA.</b> ¡Ah…! ¿Y qué tal te va en tu nueva escuela?
                            </p>
                        </Grid>
                    </Grid> 

                    <Grid container item xs={12} className={classes.Parrafo1} >
                        <Grid container justifyContent="center" xs={1}><img className='Pos img2' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion6/F2.png" alt="" /></Grid>
                        <Grid container xs={11}>
                            <p className='Lectura2'>
                                <b className='Niña Fatima'>VOZ DE FÁTIMA.</b> Bien, pero te extraño.
                            </p>
                        </Grid>
                    </Grid> 

                    <Grid container item xs={12} className={classes.Parrafo1} >
                        <Grid container justifyContent="center" xs={1}><img className='Pos img2' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion6/S3.png" alt="" /></Grid>
                        <Grid container xs={11}>
                            <p className='Lectura2'>
                                <b className='Niña Sofia'>SOFÍA.</b> Yo también te extraño.
                            </p>
                        </Grid>
                    </Grid> 

                    <Grid container item xs={12} className={classes.Parrafo1} >
                        <Grid container justifyContent="center" xs={1}><img className='Pos img' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion6/F2.png" alt="" /></Grid>
                        <Grid container xs={11}>
                            <p className='Lectura2'>
                                <b className='Niña Fatima'>VOZ DE FÁTIMA.</b> ¿Y no juegas con nadie en el receso? <br/>
                                ¿Con Amelia, por ejemplo?
                            </p>
                        </Grid>
                    </Grid> 

                    <Grid container item xs={12} className={classes.Parrafo1} >
                        <Grid container justifyContent="center" xs={1}><img className='Pos img' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion6/S3.png" alt="" /></Grid>
                        <Grid container xs={11}>
                            <p className='Lectura2'>
                                <b className='Niña Sofia'>SOFÍA.</b> Esa niña… La otra vez me pidió que le <br/>
                                compartiera de mi almuerzo, ¿tú crees?
                            </p>
                        </Grid>
                    </Grid> 

                    <Grid container item xs={12} className={classes.Parrafo1} >
                        <Grid container justifyContent="center" xs={1}><img className='Pos img2' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion6/F2.png" alt="" /></Grid>
                        <Grid container xs={11}>
                            <p className='Lectura2'>
                                <b className='Niña Fatima'>VOZ DE FÁTIMA.</b> Sí, así ha sido siempre… ¿Y no juegas <br/>con ella?
                            </p>
                        </Grid>
                    </Grid> 

                    <Grid container item xs={12} className={classes.Parrafo1} >
                        <Grid container justifyContent="center" xs={1}><img className='Pos img2' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion6/S3.png" alt="" /></Grid>
                        <Grid container xs={11}>
                            <p className='Lectura2'>
                                <b className='Niña Sofia'>SOFÍA.</b> No, con nadie… ¿Y tú?
                            </p>
                        </Grid>
                    </Grid> 

                    <Grid container item xs={12} className={classes.Parrafo1} >
                        <Grid container justifyContent="center" xs={1}><img className='Pos img' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion6/F2.png" alt="" /></Grid>
                        <Grid container xs={11}>
                            <p className='Lectura2'>
                                <b className='Niña Fatima'>VOZ DE FÁTIMA.</b> No, cómo crees, con nadie… Bueno, <br/>
                                hay una niña… Pero no, no juego con ella…
                                
                            </p>
                        </Grid>
                    </Grid> 

                    <Grid container item xs={12} className={classes.Parrafo1} >
                        <Grid container justifyContent="center" xs={1}><img className='Pos img2' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion6/S3.png" alt="" /></Grid>
                        <Grid container xs={11}>
                            <p className='Lectura2'>
                                <b className='Niña Sofia'>SOFÍA.</b> Lo bueno es que ya pronto serán vacaciones.
                            </p>
                        </Grid>
                    </Grid>

                    <Grid container item xs={12} className={classes.Parrafo1} >
                        <Grid container justifyContent="center" xs={1}><img className='Pos img' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion6/F2.png" alt="" /></Grid>
                        <Grid container xs={11}>
                            <p className='Lectura2'>
                                <b className='Niña Fatima'>VOZ DE FÁTIMA.</b> <em>(Emocionada)</em> ¡Sí! Les dije a mis<br/>
                                papás que si podía visitarte y me dijeron que sí. <br/>
                            </p>
                        </Grid>
                    </Grid> 

                    <Grid container item xs={12} className={classes.Parrafo1} >
                        <Grid container justifyContent="center" xs={1}><img className='Pos img' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion6/S3.png" alt="" /></Grid>
                        <Grid container xs={11}>
                            <p className='Lectura2'>
                                <b className='Niña Sofia'>SOFÍA.</b> ¡Qué bueno! Oye, ya me voy, porque tengo que <br/> hacer la tarea.
                            </p>
                        </Grid>
                    </Grid>

                    <Grid container item xs={12} className={classes.Parrafo1} >
                        <Grid container justifyContent="center" xs={1}><img className='Pos img' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion6/F2.png" alt="" /></Grid>
                        <Grid container xs={11}>
                            <p className='Lectura2'>
                                <b className='Niña Fatima'>VOZ DE FÁTIMA.</b> Sí, yo también. Luego hablamos. <br/>Adiós.
                            </p>
                        </Grid>
                    </Grid> 

                    <Grid container  className={classes.Parrafo1}>
                        <p className='Lectura'><em>(Sofía no contesta mientras toca el teléfono celular y hace algunos<br/>
                         movimientos con su dedo índice.)</em></p>
                    </Grid>

                    <Grid container item xs={12} className={classes.Parrafo1} >
                        <Grid container justifyContent="center" xs={1}><img className='Pos img2' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion6/F2.png" alt="" /></Grid>
                        <Grid container xs={11}>
                            <p className='Lectura2'>
                                <b className='Niña Fatima'>VOZ DE FÁTIMA.</b>¡Ay! ¿Por qué no contestas?
                            </p>
                        </Grid>
                    </Grid> 

                    <Grid container item xs={12} className={classes.Parrafo1} >
                        <Grid container justifyContent="center" xs={1}><img className='Pos img' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion6/S3.png" alt="" /></Grid>
                        <Grid container xs={11}>
                            <p className='Lectura2'>
                                <b className='Niña Sofia'>SOFÍA.</b> <em>(Apenada)</em> Perdón… Es que estaba… haciendo la <br/>tarea… Adiós.
                            </p>
                        </Grid>
                    </Grid>
                
                

                <Grid container item xs={12} className={classes.Parrafo1} >
                    <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion6/3.png" alt="" />
                </Grid>
                       
            </Grid>
            <Grid container item style={{ marginTop: 80}} ></Grid>
        </Grid>
    );
}
 
export default La_despedida;
