import React from "react";

// This imports the functional component from the previous sample.
import VideoJS from "./VideoJS";
import { Grid, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  containerVideo: {
    justifyContent: "center",
    height: "Calc(100% - 5px)",
    cursor: "pointer",
    overflow: "hidden",
    [theme.breakpoints.down(1161)]: {
      // height: "Calc(100% - 65px)",
    },
    "& .video-js": {
      display: "flex",
      height: "100%",
      width: "100%",
      minWidth: "226px",
      minHeight: "150px",
      // width: "285px",
      borderRadius: "25px",
      borderBottomLeftRadius: "0",
      borderBottomRightRadius: "0",
      [theme.breakpoints.up("md")]: {
        // height: "520px",
        width: "100%",
      },
      [theme.breakpoints.up("xl")]: {
        // height: "620px",
        maxWidth: "1100px",
      },
      "& button": { color: " #dfdfdf;" },
      "& .vjs-control": {
        width: "2rem",
        [theme.breakpoints.up("md")]: {
          // width: "3rem",
        },
      },
      "& .vjs-control.vjs-picture-in-picture-control.vjs-disabled": {
        display: "none",
      },
    },
    "& .vjs-volume-panel.vjs-volume-panel-horizontal:hover": {
      width: "5rem",
    },
    "& .vjs-tech": {
      borderRadius: "25px",
      borderBottomLeftRadius: "0",
      borderBottomRightRadius: "0",
    },
    "& [data-vjs-player]": {
      width: "100%",
    },
  },
}));

const VideoResource = ({ src }) => {
  const classes = useStyles();

  const videoJsOptions = {
    preload: "none",
    disablePictureInPicture: true,
    controls: true,
    responsive: true,
    playbackRates: [0.5, 1, 1.5, 2],

    sources: [
      {
        src: src,
        type: "video/mp4",
      },
    ],
  };

  return (
    <>
      <Grid
        container
        direction="column"
        item
        sm={12}
        alignItems="center"
        className={classes.containerVideo}
      >
        <VideoJS options={videoJsOptions} />
      </Grid>
    </>
  );
};

export default VideoResource;
