import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
      Contenedor:{
        background:'#E9FBF9',
        width:'100vw',
        overflowX:'clip',
        [theme.breakpoints.down(1161)]:{
            background:'none',
           overflowX:'unset',
        },
        [theme.breakpoints.down(600)]:{
            marginTop: '79.51px',
        }
    },
    ContainerInfoLectura:{
        position:'relative',
        height:'86px',
        background:'#76D4C3',
        '& .BarraInfoLectura':{
            height:'86px',
            background:`Url(https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Barras/Verde.png)`,
        },
        '& .InfoLectura':{
            position:'absolute',
            height:'86px',
            width:'219px',
            boxSizing:'border-box',
            padding:'17.2px 0',
            background:'#FAFAFA',
            borderRadius:'50px',
            boxShadow: '2px 2px 2px 2px #00000029',
            zIndex:'4',
        },
        '& .InfoLectura:before':{
            position:'absolute',
            display:'flex',
            boxSizing:'border-box',
            padding:'0 0 0 10px',
            justifyContent:'center',
            alignItems:'center',
            height:'39.88px',
            width:'39.88px',
            borderRadius:'50%',
            content:'""',
            boxShadow: '3px 0px .5px 0px #00000029',
            background:'#FAFAFA',
            left:'90px',
            top:'65px',
            transform: 'rotate(0.25turn)',
        },
        '& .InfoLectura:after':{
            content:'""',
            height:'15px',
            width:'15px',
            borderTop:'2px solid #20A698',
            borderLeft:'2px solid #20A698',
            position:'absolute',
            bottom:'-10px',
            left:'Calc(50% - 8px)',
            transform: 'rotate(0.625turn)',
        },
        '& .Sesion':{
            borderLeft:'2px solid #D8D8D8',
        },
        '& .Dato':{
            margin:'0 0 5px 0',
            textAlign:'center',
            font:'bold 17px lato',
            color:'#20A698',
        },
        '& .Caracter':{
            margin:'0',
            textAlign:'center',
            font:'bold 17px lato',
            color:'#000000',
        },
    },
    ContenedorLectura:{
        background:'#fff',
        width:'1160px',
        height:'100%',
        padding:'57px 68px 35px 168px' ,
        [theme.breakpoints.down(1161)]:{
            width:'502px',
            padding: '57px 0 0 0',
        },
        [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
        '& .ContainerDerechos':{
            bottom:'80px',
            [theme.breakpoints.down(1161)]:{
                display:'none',
            }
        },
        '& .Derechos':{
            textAlign:"center",
            margin:"0px",
            font:" 15px lato",
            color:"#717476",
        },
        '& .Escritorio':{
            display:'block',
            [theme.breakpoints.down(1161)]:{
                display:'none'
            },
        },
        '& .Tablet':{
            display:'none',
            [theme.breakpoints.down(1161)]:{
                display:'block'
            },
        },
    },
    ContainerTitulo:{
        margin:'0 0 45px 0',
        [theme.breakpoints.down(1161)]:{
            margin:'0 0 38px 0',
        },
        '& .Titulo':{
            font:'48px fredoka one',
            letterSpacing: '0px',
            color: '#926DD1',
            margin:'0',
            textAlign:'center',
            [theme.breakpoints.down(1161)]:{
                font:'43px fredoka one',
                lineHeight:'52px',
                textAlign:'center',
            },
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            lineHeight : '30px',
            margin:'0 0 36px 0 ',
            font:'22px lato',
            color:'#000',
            letterSpacing: '0.20px',
            [theme.breakpoints.down(1161)]:{
                font:'18px lato',
                letterSpacing:'.17px',
                lineHeight:'28px',
            },
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .Imagen':{
            [theme.breakpoints.down(1161)]:{
                width:'100%',
            },
            
        }


    },

}));

const RebeK = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
             <Grid container item justifyContent="center" className={classes.ContainerInfoLectura}>
               <Grid container className="BarraInfoLectura"></Grid>
               <Grid container item className="InfoLectura">
                    <Grid container item direction="column" xs={6} justifyContent="center">
                        <p className="Dato">Grado</p>
                        <p className="Caracter">6</p>
                    </Grid>
                    <Grid container item direction="column" xs={6} justifyContent="center" className="Sesion">
                        <p className="Dato">Sesión</p>
                        <p className="Caracter">51</p>
                    </Grid>
               </Grid>
            </Grid>

            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >
                        Rebe-K
                    </h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                            —¿Por qué no puedo ser exploradora? —preguntó Rebe-K. <br/><br/>

                            —Porque fuimos creados para reparar los trajes de los exploradores. Debes 
                            entenderlo. No sé qué está pasando contigo —contestó C-Sar. <br/><br/>
                            
                            Rebe-K y C-Sar eran miembros del equipo de reparadores de las fuerzas 
                            exploradoras de la base espacial R2115, la cual era un puesto de investigación creado
                            por investigadores de la Tierra para tener una base más cercana al planeta J06. Este 
                            planeta fue descubierto en 2110 y, de acuerdo con los primeros informes de la base 
                            espacial, tenía condiciones favorables para ser habitado por los seres humanos.
                        </p>
                    </Grid>
                    <Grid container sm={12}>
                            <img className='Imagen' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion51/1.png"  alt="Tarjeta" />
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                            Debido al riesgo que representaba exponer a seres humanos al proyecto de 
                            exploración y colonización de J06, se creó la R2115. Se trataba de una base tripulada 
                            por androides creados para la colonización, con propiedades biológicamente 
                            humanas, pero con componentes cibernéticos. <br/><br/>
                            
                            Los tripulantes de la R2115, llamados <em>humanoides</em>, eran creados en unas cámaras de 
                            gestación en las que les podían asignar cualquier tipo de característica física o 
                            función de acuerdo con las necesidades de la R2115: exploradores, militares,
                            servicios básicos, técnicos e incluso características masculinas o femeninas. Dentro 
                            de los técnicos, se encontraban todos aquellos que daban servicios de 
                            mantenimiento en general a la R2115 y su tripulación. <br/><br/>
                            
                            Rebe-K y C-Sar formaban parte de los técnicos de la R2115. Es decir, fueron creados
                            como cualquier humanoide y programados posteriormente a su proceso de 
                            gestación por los mismos técnicos. El equipo era muy diverso. Había programadores, 
                            mecánicos, técnicos en electrónica, laboratoristas, pero también, humanoides que
                            realizaban servicios de mantenimiento a la R2115. <br/><br/>
                            
                            Rebe-K y C-Sar fueron creados exclusivamente para reparar los trajes de los 
                            exploradores, que, al someterse a los efectos del ambiente espacial, sufrían severos 
                            daños. Cada humanoide asignado a estas funciones debía reparar aproximadamente 
                            60 trajes al día, los 7 días de la semana, los 365 días del año, indefinidamente, hasta 
                            que el humanoide fuera dado de baja por volverse inservible. <br/><br/>
                            
                            A pesar de haber sido programada para formar parte del equipo técnico, Rebe-K muy 
                            tempranamente desarrolló un deseo profundo de ser exploradora. Esto le generó 
                            muchos problemas. En primer lugar, fue sometida a cuestionamientos por el grupo de 
                            técnicos, llevada en numerosas ocasiones para recibir ajustes de programación. Los 
                            exploradores eran el linaje más importante por la naturaleza del proyecto, pero los 
                            técnicos eran fundamentales para el éxito de la misión. Por lo tanto, cualquier 
                            desviación en la identidad de un humanoide sería evitada a toda costa con la baja 
                            definitiva y la expulsión de la R2115 en el planeta J06.<br/><br/>
                            
                            —Yo soy la que no entiende qué pasa contigo —replicó Rebe-K—. Ambos sabemos
                            que no queremos estar aquí. La diferencia es que yo no me conformo y lo expreso, a 
                            pesar de todo lo que he tenido que soportar. <br/><br/>
                            
                            —Somos compañeros y no quiero que te expulsen —contestó afligido C-Sar mientras 
                            descubría una serie de datos extraños en su sistema interno, pero que modificaban 
                            sus sensores físicos y alteraban sus datos. <br/><br/>
                            
                            —¡Apóyame! Si tú no me apoyas, todo está perdido para mí. ¿No te das cuenta de que
                            el proyecto de los humanoides tiene un error de principio? Fuimos creados para no 
                            cuestionar nuestros roles, para no dudar ni tener libertad, para no sentir. ¡Y míranos
                            ahora! ¿Acaso no percibes en tu interior datos extraños que modifican tu sistema? 
                            Quizá esto es lo que los humanos llaman “emociones”. <br/><br/>
                            
                            —¿Qué obtendrás si te expulsan? —contestó C-Sar—. Jamás modificarán tu programa. 
                            ¡Entiéndelo! Somos técnicos, no exploradores. Debemos mitigar estos pensamientos. <br/><br/>

                            —Si mi programa fuera completamente el de una técnica, no tendría esta necesidad 
                            de explorar —argumentó Rebe-K—. Soy una técnica que repara trajes, pero también 
                            soy una exploradora. Si tengo el deseo, puedo serlo. Y tú también puedes ser lo que 
                            desees. ¡Respeta tus emociones, humanoide! No necesito ajustes en mi programa. Yo 
                            soy lo que soy, un ser único e irrepetible. Somos seres posibles, no meros 
                            humanoides comunes.<br/><br/>
                            
                            Al escuchar a Rebe-K, C-Sar sintió una fuerza interna nunca experimentada. Tantos 
                            años reparando trajes habían servido para no atender aquellas emociones que 
                            siempre estuvieron presentes. Sintió coraje, valor, amor, libertad. <br/><br/>
                            
                            —¿Sabes algo? —exclamó C-Sar mientras la abrazaba con fuerza—. Vas a necesitar un 
                            técnico que repare tu traje.

                        </p>
                    </Grid>
                    <Grid container  sm={12}>
                        <img  className='Imagen' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion51/2.png"  alt="Tarjeta" />
                    </Grid>
                </Grid>

                <Grid container item xs={12} justifyContent="center" className="ContainerDerechos">
                    <p className="Derechos">
                        © 2022. Todos los derechos reservados, Pearson, Circuito de lectores.
                    </p>                
                </Grid>
        </Grid>
    </Grid>
    );
}
 
export default RebeK;
