import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
    ContenedorLectura:{
        width:'100%',
        [theme.breakpoints.down(768)]:{
            width:'350',
        },
        '& .Imagen1':{
             width:'100%',
         },
 

    },
    
}));

const Tabla_de_actividades_extraescolares = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <img className="Imagen1" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion34/F.png"  alt="Tarjeta" />
            </Grid>
        </Grid>
    );
}
 
export default Tabla_de_actividades_extraescolares;
