import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({

    ContenedorLectura:{
        width:'502px',
        [theme.breakpoints.down(502)]:{
            width:'350px',
        },
    },

    ContainerTitulo:{
        margin:'0 0 38px 0',
        '& .Titulo':{
            color: '#008AEF',
            margin:'0',
            font:'43px fredoka one',
            lineHeight:'52px',
            textAlign:'center',
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            margin:'0 0 36px 0 ',
            color:'#000',
            font:'18px lato',
            letterSpacing:'.17px',
            lineHeight:'28px',
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },

        '& .ContainerCuadro':{
            position:'relative',
        },

        '& .Esp':{
            position:'absolute',
            margin:'53px 0 0 0 ',
            width:'85%',
            [theme.breakpoints.down(502)]:{
                margin:'25px 0 0 0',
                lineHeight :'17px!important',
            },
        },

        '& .Imagen':{
            width:'100%',
        },

        '& .Imagen2':{
            [theme.breakpoints.down(502)]:{
                width:'100%',
                height:'105%',
            },
        },

    },

}));

const Pocos_colores_son_suficientes_pero_cuántos_son_pocos = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo " >
                        Pocos colores son <br/>suficientes, pero <br/>¿cuántos son pocos?
                    </h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                            ¿Te has preguntado alguna vez a qué se dedican los matemáticos? Quizá pienses que 
                            sólo hablan de números y que se la pasan haciendo operaciones, una cada vez más 
                            compleja que la otra. Y aunque en parte es cierto, a los matemáticos les encanta 
                            encontrarle utilidad a todo lo que calculan. Por ejemplo, demostrar si algo es 
                            verdadero o falso a partir de las matemáticas o incluso dibujar y colorear. Así como lo
                            lees: ¡colorear! A continuación, observa un ejemplo. <br/><br/>
                            
                            ¿Alguna vez te has preguntado cuántos colores son suficientes para colorear un 
                            mapa? Es probable que pienses que dependerá del mapa, es decir, de cuántos estados 
                            o países lo conforman. Te sorprenderá saber la respuesta, ya que son menos de los 
                            que te imaginas. <br/><br/>
                            
                            Para responder, es necesario que se establezca qué se entiende por <em>mapa</em> y cuál es la 
                            regla para colorearlo. Lee la definición a continuación:
                        </p>
                    </Grid>
                    <Grid container justifyContent='center' className='ContainerCuadro'>
                        <img className="Imagen2 " src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion48/1R.png" alt="Tarjeta" />
                        <p className='Lectura Esp'>
                            Un mapa es una superficie con un contorno delimitado, cuyo interior puede 
                            tener todo tipo de divisiones, a las que se les conoce como <em>fronteras</em>.               
                        </p>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                            Observa las ilustraciones. En ambas, el contorno es rojo y las divisiones o fronteras<br/>
                            están en color negro.
                        </p>
                    </Grid>
                    <Grid container  sm={12}>
                            <Grid container justifyContent='center'>
                                <img  className='Imagen' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion48/2.png"  alt="Tarjeta" />
                            </Grid>
                    </Grid>

                    <Grid container sm={12}>
                        <p className="Lectura">
                           Las fronteras dividen al mapa en regiones que, en tu clase de geografía, conoces 
                           también como Estados o países. <br/><br/>
                           
                           A continuación, conoce la regla para colorear.
                        </p>
                    </Grid>
                    <Grid container justifyContent='center' className='ContainerCuadro'>
                        <img className="Imagen2 " src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion48/3R.png" alt="Tarjeta" />
                        <p className='Lectura Esp'>
                        Dos o más regiones que compartan frontera deberán tener colores diferentes.            
                        </p>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                            Ahora, para resolver la pregunta inicial, es momento de intentar utilizar el mínimo de 
                            colores posibles en un ejemplo concreto. En este caso, trabajarás en un mapa de  
                            América de Sur. Toma como punto inicial Colombia, que tiene frontera con Panamá,
                            Ecuador, Perú, Brasil y Venezuela. <br/><br/>
                            
                            Recuerda que la regla dice que las regiones o países que comparten frontera deben 
                            tener colores diferentes. Además, según la pregunta inicial, necesitas saber cuál es el 
                            número mínimo de colores necesarios. Una conclusión rápida sería que se necesitan 
                            seis colores, pues son seis países. La sorpresa es que ésa no es la respuesta correcta.
                            A continuación, lo comprobarás. <br/><br/>
                            
                            Por ejemplo, empieza con color amarillo para Colombia y con azul para el primer país 
                            fronterizo que encuentres. En este caso, se trata de Panamá.

                        </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                            <img className='Imagen' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion48/4.png"  alt="Tarjeta" />
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                            Continúa con Ecuador, que será rojo. A partir de aquí, llega un momento clave con 
                            Perú, pues comparte frontera con Ecuador y Colombia, además de Chile, Bolivia y 
                            Brasil. Sin embargo, según lo anterior, Perú no puede tener el mismo color que 
                            Ecuador ni Colombia, entonces será de color verde.
                        </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                            <img className='Imagen' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion48/5.png"  alt="Tarjeta" />
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                            Si continúas con esta regla, puedes elegir más colores, tantos como te imagines. Pero  
                            la pregunta no es cuál es el máximo de colores que se necesitan para completar un  
                            mapa. Por el contrario, lo que quieres conocer es el mínimo. Aunque no lo creas, ya  
                            no se necesitan más para resolver el problema. ¡Cuatro colores son suficientes para  
                            colorear cualquier mapa!<br/><br/>
                            
                            Para comprobarlo, observa qué pasa con Brasil y Venezuela. El primer país comparte 
                            frontera con Colombia y Perú, por lo que no puede tener los colores amarillo ni  
                            verde. Pero no comparte frontera con Panamá ni con Ecuador. Por lo tanto, puede  
                            usar el azul o el rojo. En este caso, será el azul. <br/><br/>
                            
                            En el caso de Venezuela, le puedes asignar tanto el color rojo como el verde, que en 
                            este caso es el verde, y así sucesivamente con el resto de los países. Si tienes dudas,
                            puedes comprobarlo con todos los países y mapas que quieras.
                        </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                            <img className='Imagen' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion48/6.png"  alt="Tarjeta" />
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                            Es importante que sepas que, sin duda, bastan cuatro colores para concluir. Pero esto 
                            no quiere decir que haya una sola manera de hacerlo, es decir, puedes intercambiar 
                            colores. Por ejemplo, Perú y Venezuela podrían tener el color rojo. Entonces, 
                            Ecuador tendría el verde. Además, podrían ser otros colores, quizá morado, 
                            anaranjado, café y azul claro. Sean cuales sean los colores que elijas, lo importante es 
                            que, si sigues la regla inicial, sólo necesitarás cuatro colores para terminar cualquier 
                            mapa. <br/><br/>
                            
                            Este problema se planteó por primera vez en 1852 por el matemático sudafricano 
                            Francis Guthrie. Pero no fue sino hasta 124 años después, en 1976, que los 
                            matemáticos Kenneth Appel y Wolfang Haken demostraron que esto era válido con 
                            ayuda de una de las primeras computadoras de la historia. <br/><br/>
                            
                            Aunque comprobaste de manera rápida e intuitiva que el razonamiento de Guthrie 
                            es correcto, la dificultad radicaba en aplicarlo y comprobarlo en una gran cantidad de 
                            mapas sin tener que colorearlos uno por uno. Así que los cálculos matemáticos
                            ayudaron a esto.<br/><br/>
                            
                            Con todo lo anterior, ahora ya sabes que a los matemáticos no sólo les gusta calcular: 
                            ¡también colorear!

                        </p>
                    </Grid>
                </Grid>

        </Grid>
    </Grid>
    );
}
 
export default Pocos_colores_son_suficientes_pero_cuántos_son_pocos;
