import React, { useContext, useEffect } from "react";
import {
  makeStyles,
  Toolbar,
  AppBar,
  Button,
  Hidden,
  Grid,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import Corazon from "./imgs/Corazon.png";
import Estrella from "./imgs/Estrella.png";
import Corona from "./imgs/Corona.png";
import Mapa from "./imgs/Mapa.png";
import PearsonResponsive from "./imgs/PearsonLogo_responsive.svg";
import PearsonLogoNavbar from "./imgs/PearsonLogo_Navbar.svg";
import AuthContext from "../auth/AuthContext";
import { Link } from "react-router-dom";

import UsabilityContext from "../usability/UsabilityContext";
import compLect from "../components/sounds/Comprension-lectora.wav";
import sociEm from "../components/sounds/Socioemocional.wav";
import "animate.css";

import Popover from "@material-ui/core/Popover";

const useStyles = makeStyles((theme) => ({
  link: {
    textDecoration: "none",
  },
  offsete: {
    marginTop: "79.51px",
  },
  BotonMapa: {
    height: "34px",
    // width: "101px",
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    border: "3px solid #F2624F",
    borderRadius: "20px",
    color: "#F2624F",
    textTransform: "capitalize",
    font: "500 17px Lato;",
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "flex",
    justifyContent: "flex-end",
    alignContent: "center",
    alignItems: "center",
    "&:hover": {
      background: "#F2624F",
      color: "#FFFF",
    },
  },
  ContenedorImg: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "34px",
    width: "34px",
    right: "9px",
    borderRadius: "50%",
    position: "relative",
    background: "#F2624F",
  },
  Mapaimg: {
    width: "16.89px",
    height: "20.33px",
  },

  Nav: {
    width: "100%",
    padding: "0px",
    background: "#84E9DF",
    [theme.breakpoints.up(1920)]: {
      minHeight: "79.51px",
    },
    [theme.breakpoints.down(1161)]: {
      minHeight: "79.51px",
    },
    minHeight: "79.51px",
  },

  NavIzquierdo: {
    display: "flex",
    height: "79.51px",
    flexDirection: "row",
    alignItems: "center",
    flexGrow: "10",
    maxWidth: "100vw",
    [theme.breakpoints.up(1920)]: {
      height: "79.51px",
    },
    [theme.breakpoints.down(1161)]: {
      height: "79.51px",
    },
    [theme.breakpoints.down("md")]: {
      justifyContent: "space-around",
      alignItems: "center",
    },
  },
  MenuIcon: {
    color: "#969D9F",
    fontSize: "40px",
  },

  logoPearson: {
    // width: "121.6px",
    height: "70px",
    objectFit: "cover",
    paddingRight: "70.89px",
    paddingLeft: "63px",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  logoPearsonResponsive: {
    display: "none",
    height: "55px",
    objectFit: "cover",
    // width: "51.17px",
    [theme.breakpoints.down("md")]: {
      display: "flex",
    },
  },
  insignias: {
    alignItems: "center",
    display: "flex",
    width: "87px",
    height: "34px",
    background: "#fff",
    marginRight: "17.85px",
    border: "2px solid",
    borderRadius: "20px",
    [theme.breakpoints.down(1367)]: {
      width: "77px",
      height: "24px",
    },
  },
  Estrella: {
    borderColor: "#935D8B",
  },
  Corazon: {
    borderColor: "#B64F88",
  },
  Corona: {
    borderColor: "#633F79",
  },
  NavCentro: {
    display: "flex",
    height: "79.51px",
    [theme.breakpoints.up(1920)]: {
      height: "79.51px",
    },
    [theme.breakpoints.down(1161)]: {
      height: "79.51px",
    },
    background: "#00DAC9",
    alignItems: "center",
    justifyContent: "center",
    flexGrow: "1",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  NavDerecho: {
    display: "flex",
    height: "79.51px",
    [theme.breakpoints.up(1920)]: {
      height: "79.51px",
    },
    [theme.breakpoints.down(1161)]: {
      height: "79.51px",
    },
    background: "#FDDC7A",
    flexGrow: "3",
    margin: "0px",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  NavDerechoTexto: {
    display: "flex",
    flexDirection: "column",
    flexGrow: "2",
    justifyContent: "center",
    textAlign: "right",
  },
  ContainerAvatar: {
    display: "flex",
    flexGrow: "1",
    alignItems: "center",
  },
  texto: {
    // height: "19px",
    margin: "0px ",
    letterSpacing: "0.15px",
    color: "#2C2B2B",
    marginRight: "11px",
    font: " bold 16px Lato;",
  },
  Alumno: {
    letterSpacing: "0.15px",
    font: " 900 16px Lato;",
  },
  Avatar: {
    height: "49px",
    width: "49px",
    border: "3px solid #FFFFFF",
    borderRadius: "500px",
    [theme.breakpoints.down(1367)]: {
      height: "40px",
      width: "40px",
    },
    [theme.breakpoints.down(850)]: {
      height: "49px",
      width: "49px",
    },
  },

  insigniasimg: {
    right: "2px",
    position: "relative",
    width: "36.5px",
    height: "36.5px",
    [theme.breakpoints.down(1367)]: {
      width: "26.5px",
      height: "26.5px",
    },
  },

  puntos: {
    margin: "0px",
    width: "31px",
    height: "22px",
    font: " 600 18px Lato ",
    left: "6px",
    position: "relative",
    overflow: "hidden",
    color: "#000",
  },
  toggable: {
    position: "relative",
    minWidth: "320px",
    width: "100%",
  },

  popover: {
    pointerEvents: "none",
  },
  paper: {
    padding: theme.spacing(1),
    borderRadius: "0px 0px 30px 30px",
    [theme.breakpoints.down(650)]: {
      padding: theme.spacing(0),
    },
  },
  gridBadge: {
    width: "420px",
    marginBottom: "30px",
    height: "40px",
    [theme.breakpoints.down(650)]: {
      width: "420px",
      marginBottom: "25px",
      height: "30px",
    },
    [theme.breakpoints.down(425)]: {
      width: "310px",
    },
  },
  badgeText: {
    // width: "120px",
    font: " bold 22px Lato;",
    [theme.breakpoints.down(650)]: {
      font: " bold 16px Lato;",
    },
  },
  imgageBadge: {
    // right: "2px",
    // position: "relative",
    marginRight: "30px",
    width: "50px",
    height: "50px",
    [theme.breakpoints.down(650)]: {
      width: "35px",
      height: "35px",
      marginRight: "20px",
    },
  },
}));

const NavBar = () => {
  const classe = useStyles();
  const {
    user: { firstLastName, name, grade, avatar, insignias = [] },
  } = useContext(AuthContext);

  const [lectora, emocional, evaluacion] = insignias;
  const animatedHeartRef = React.useRef(null);
  const animatedStarRef = React.useRef(null);

  // const [startBadges, setStartBadges] = React.useState(lectora.total);
  // const [heartBadges, setHeartBadges] = React.useState(insignias);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverClose = () => {
    // const myTimeout = setTimeout(() => {setAnchorEl(null)}, 2000)
  };

  const open = Boolean(anchorEl);

  const {
    handleMenuInMobile,
    openMenuInMobile,
    openMenuInDesktop,
    handleMenuInDesktop,
    animationBadgeStar,
    animationBadgeHeart,
    setAnimationBadgeStart,
    setAnimationBadgeHeart,
  } = useContext(UsabilityContext);

  useEffect(() => {
    if (animationBadgeStar === true) {
      document.getElementById("audioCompr").play();
      setAnchorEl(document.getElementById("navBar"));
      const myTimeout = setTimeout(() => {
        setAnchorEl(null);
        setAnimationBadgeStart(false);
      }, 1500);
    }
  }, [animationBadgeStar]);

  useEffect(() => {
    if (animationBadgeHeart === true) {
      document.getElementById("audioSocioEm").play();
      setAnchorEl(document.getElementById("navBar"));
      const myTimeout = setTimeout(() => {
        setAnchorEl(null);
        setAnimationBadgeHeart(false);
      }, 1500);
    }
  }, [animationBadgeHeart]);

  const handleAnimationStar = () => {
    if (animationBadgeStar) {
      animatedStarRef?.current?.addEventListener(
        "animationend",
        () => {
          setAnimationBadgeStart(false);
        },
        { once: true }
      );
      return `${classe.insigniasimg} animate__animated animate__flip`;
    } else {
      return classe.insigniasimg;
    }
  };

  const handleAnimationHeart = () => {
    if (animationBadgeHeart) {
      animatedHeartRef?.current?.addEventListener(
        "animationend",
        () => {
          setAnimationBadgeHeart(false);
        },
        { once: true }
      );
      return `${classe.insigniasimg} animate__animated animate__heartBeat`;
    } else {
      return classe.insigniasimg;
    }
  };

  const renderBadges = () => {
    if (!animationBadgeStar && animationBadgeHeart) {
      return (
        <img
          className={`${classe.imgageBadge} animate__animated animate__heartBeat`}
          src={Corazon}
          alt="heart"
        />
      );
    } else if (!animationBadgeHeart && animationBadgeStar) {
      return (
        <img
          className={`${classe.imgageBadge} animate__animated animate__flip`}
          src={Estrella}
          alt="star"
        />
      );
    }
  };


  return (
    <>
      <AppBar id="navBar" className={classe.Nav}>
        <Toolbar className={classe.Nav}>
          <audio id="audioCompr">
            <source src={compLect} type="audio/mpeg"></source>
          </audio>
          <audio id="audioSocioEm">
            <source src={sociEm} type="audio/mpeg"></source>
          </audio>
          <div className={classe.NavIzquierdo}>
            <Hidden only={["xl", "lg"]}>
              <MenuIcon
                className={classe.MenuIcon}
                onClick={handleMenuInMobile}
              />
            </Hidden>
            <img
              className={classe.logoPearson}
              src={PearsonLogoNavbar}
              alt=""
            />
            <img
              className={classe.logoPearsonResponsive}
              src={PearsonResponsive}
              alt=""
            />

            <Hidden only={["xl", "lg"]}>
              <Popover
                id="mouse-over-popover"
                className={classe.popover}
                classes={{
                  paper: classe.paper,
                }}
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
              >
                <Grid
                  container
                  justifyContent="center"
                  alignItems="center"
                  className={classe.gridBadge}
                >
                  {renderBadges()}
                  <p className={classe.badgeText}>¡Ganaste una recompensa!</p>
                </Grid>
              </Popover>
            </Hidden>

            <Hidden only={["md", "sm", "xs"]}>
              <div className={`${classe.Estrella} ${classe.insignias}`}>
                <img
                  ref={animatedStarRef}
                  className={handleAnimationStar()}
                  src={Estrella}
                  alt="onta la imagen"
                />
                <p className={classe.puntos}>{lectora?.total}</p>
              </div>
              <div className={`${classe.Corazon} ${classe.insignias}`}>
                <img
                  ref={animatedHeartRef}
                  className={handleAnimationHeart()}
                  src={Corazon}
                  alt="onta la imagen"
                />
                <p className={classe.puntos}>
                  {emocional?.total}
                </p>
              </div>
              <div className={`${classe.Corona} ${classe.insignias}`}>
                <img
                  className={classe.insigniasimg}
                  src={Corona}
                  alt="onta la imagen"
                />
                <p className={classe.puntos}>
                  {evaluacion?.total}
                </p>
              </div>
            </Hidden>
            <Hidden only={["xl", "lg"]}>
              <Link
                className={classe.link}
                to="/Perfil_del_alumno"
                onClick={() => {
                  if (openMenuInMobile) handleMenuInMobile();
                  if (openMenuInDesktop) handleMenuInDesktop();
                }}
              >
                <img
                  className={classe.Avatar}
                  src={avatar?.path_min}
                  alt="Avatar"
                />
              </Link>
            </Hidden>
          </div>

          <div className={classe.NavCentro}>
            <Link className={classe.link} to="/mapa">
              <Button className={classe.BotonMapa}>
                <div className={classe.ContenedorImg}>
                  <img className={classe.Mapaimg} src={Mapa} alt="MapaImg" />
                </div>
                Mapa
              </Button>
            </Link>
          </div>
          <div className={classe.NavDerecho}>
            <div className={classe.NavDerechoTexto}>
              <p className={`${classe.Alumno} ${classe.texto}`}>
                {name} {firstLastName}
              </p>
              <p className={`${classe.Grado} ${classe.texto}`}>{grade}</p>
            </div>
            <div className={classe.ContainerAvatar}>
              <Link
                className={classe.link}
                to="/Perfil_del_alumno"
                onClick={() => {
                  if (openMenuInMobile) handleMenuInMobile();
                  if (openMenuInDesktop) handleMenuInDesktop();
                }}
              >
                <img
                  className={classe.Avatar}
                  src={avatar?.path_min}
                  alt="Avatar"
                />
              </Link>
            </div>
          </div>
        </Toolbar>
      </AppBar>

      <div className={classe.offsete}></div>
    </>
  );
};

export default NavBar;
