import { makeStyles, Grid, Input } from "@material-ui/core";
import React from "react";

// import AuthContext from "../../../auth/AuthContext";
import UnstyledSelectSimple from "./Select";
import ContainerAdminPearson from "../ContainerAdminPearson";

const useStyles = makeStyles((theme) => ({
  Container: {
    padding: "48px 0 48px 0",
    background:
      "linear-gradient(217deg, rgba(255,194,0,0.4962185557816877) 2%, rgba(255,255,255,1) 25%, rgba(255,255,255,0.4962185557816877) 81%, rgba(144,81,177,1) 98%)",
    minHeight: "calc(100vh - 80px)",
  },
  ContainerReportes: {
    background: "#fff",
    maxWidth: "1216px",
    width: "90%",
    minHeight: "70vh",
    // maxHeight: "982px",
    gap: "30px",
    borderRadius: "42px",
    padding: "30px",
    // justifyContent: "center",
    display: "flex",
    alignContent: "center",
    boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.5)",
    "& .Titulo": {
      font: "42px fredoka one",
      margin: "0 0 84px 34px",
    },
  },
  title: {
    font: "28px fredoka one",
    textAlign: "center",
    width: "100%",
    margin: "20px 0",
    color: "#40ADA3",
    [theme.breakpoints.up("sm")]: {
      width: "auto",
      // margin: "0 0 34px 14px",
    },
    [theme.breakpoints.up("md")]: {
      font: "32px fredoka one",
      width: "auto",
      // textAlign: "start",
      // margin: "0 0 34px 14px",
    },
    [theme.breakpoints.up("xl")]: {
      font: "38px fredoka one",
    },
  },
  formContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    maxWidth: "560px",
    alignItems: "flex-start",
    gap: "20px",
  },
  selectItem: {
    width: "80%",
    // backgroundColor: "#00000019",
    border: "none",
    borderRadius: "10px",
    boxSizing: "border-box",
    padding: "10px",
    overflow: "hidden",
    //  width: '230px';
    // "-webkit-appearance": "none",
    color: "#000",
    "-webkit-appearance": "none",
    " -moz-appearance": "none",
    // backgroundImage: `url("data:image/svg+xml;utf8,<svg fill='rgb(238, 205, 103)' height='36' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>")`,
    // backgroundRepeat: "no-repeat",
    // backgroundPositionX: "98%",
    // backgroundPositionY: "50%",
    fontStyle: "Helvética",
    fontSize: "16px",
    "&::after": {
      content: "▴",
      color: "rgb(238, 205, 103)",
      fontSize: "30px",

      lineHeight: "20px",
    },
    backgroundColor: "#f0f0f0",
    "&:focus": {
      outline: "none",
    },
  },
  input: {
    // maxWidth: "560px",
    width: "100%",
    backgroundColor: "#f0f0f0",
    padding: "5px 27px",
    borderRadius: "61px",
    borderBottom: "none",
    border: "none",
    // height: "40px",
    boxSizing: "border-box",
    fontSize: "16px",
    "&:focus": {
      outline: "none",
    },
    "& .MuiInput-underline:before": {
      width: "1px",
      borderBottom: "none",
    },
  },
  PurpleButton: {
    color: "#FFFFFF",
    backgroundColor: "#9051b1",
    borderRadius: "30px",
    // margin: "0 auto",
    width: "100%",
    maxWidth: "238px",
    minHeight: "45px",
    border: "none",
    textTransform: "unset",
    cursor: "pointer",
    fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
    fontWeight: "500",
    padding: "6px 16px",
    lineHeight: "1.75",
    transition:
      "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    boxShadow: "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
    "&:hover": {
      backgroundColor: "#B965D3 ",
    },
  },
  WitheButton: {
    color: "#9051b1",
    // borderColor: "#9051b1",
    border: "1px solid #9051b1",
    backgroundColor: "#FFFFFF",
    borderRadius: "30px",
    // margin: "0 auto",
    width: "100%",
    minWidth: "75px",
    maxWidth: "238px",
    minHeight: "45px",
    textTransform: "uppercase",
    cursor: "pointer",
    fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
    fontWeight: "500",
    padding: "6px 16px",
    lineHeight: "1.75",
    transition:
      "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    boxShadow: "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
    [theme.breakpoints.up("lg")]: {
      minWidth: "100px",
    },

    "&:hover": {
      backgroundColor: "#B965D330 ",
    },
  },
  label: {
    fontWeight: "bold",
    fontFamily: "Lato, sans-serif",
  },
}));

const profiles = ["Docente", "Estudiante"];
const grupos = ["A", "B"];
const grados = [1, 2, 3, 4, 5, 6];

const CargarDatosAdminPerarson = () => {
  const classes = useStyles();
  const defaultForm = {
    perfil: "",
    grado: "",
    grupo: "",
    nombre: "",
    apellidoPaterno: "",
    apellidoMaterno: "",
    correo: "",
  };
  const [formData, setFormData] = React.useState(defaultForm);
  // const {
  //   user: { name, firstLastName, secondLastName, adminInfo, avatar },
  // } = React.useContext(AuthContext);

  // const { user } = React.useContext(AuthContext);

  const handleForm = (e) => {
    setFormData({
      ...formData,
      [e.target.name.toLowerCase()]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };
  return (
    <ContainerAdminPearson>
      <Grid container item sm={12} className={classes.Container} alignItems="center" justifyContent="center">
        <Grid container item direction="column" className={classes.ContainerReportes}>
          <h2 className={classes.title}>Carga de usuarios</h2>
          <form onSubmit={handleSubmit} className={classes.formContainer}>
            <UnstyledSelectSimple list={profiles} title="Perfil" handleForm={handleForm} formData={formData} />
            <UnstyledSelectSimple list={grados} title="Grado" handleForm={handleForm} formData={formData} />
            <UnstyledSelectSimple list={grupos} title="Grupo" handleForm={handleForm} formData={formData} />
            <label className={classes.label}>Datos del usuario</label>
            <Input
              type="text"
              placeholder="Nombre(s)"
              name="nombre"
              onChange={handleForm}
              value={formData.nombre}
              className={classes.input}
              disableUnderline
            />
            <Input
              type="text"
              placeholder="Apellido Paterno"
              name="apellidoPaterno"
              onChange={handleForm}
              value={formData.apellidoPaterno}
              className={classes.input}
              disableUnderline
            />
            <Input
              type="text"
              placeholder="Apellido Materno"
              name="apellidoMaterno"
              onChange={handleForm}
              value={formData.apellidoMaterno}
              className={classes.input}
              disableUnderline
            />
            {formData?.perfil?.toLowerCase() === "docente" && (
              <Input
                type="email"
                placeholder="Correo"
                name="correo"
                onChange={handleForm}
                value={formData.correo}
                className={classes.input}
                disableUnderline
              />
            )}
          </form>
        </Grid>
      </Grid>
    </ContainerAdminPearson>
  );
};

export default CargarDatosAdminPerarson;
