import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
     Contenedor:{
        background:'#E9FBF9',
        width:'100vw',
        overflowX:'clip',
        [theme.breakpoints.down(1161)]:{
            background:'none',
           overflowX:'unset',
        },
        [theme.breakpoints.down(600)]:{
            marginTop: '79.51px',
        }
    },
  ContainerInfoLectura: {
    position: "relative",
    height: "86px",
    [theme.breakpoints.down(502)]:{
        height: "43px",
    },
    background: "#76D4C3",
    "& .BarraInfoLectura": {
      height: "86px",
      background: `Url(https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Barras/Azul.png)`,
        [theme.breakpoints.down(502)]:{
            height: "43px",
        },
    },
    "& .InfoLectura": {
      position: "absolute",
      height: "86px",
      width: "219px",
      boxSizing: "border-box",
      padding: "17.2px 0",
      background: "#FAFAFA",
      borderRadius: "50px",
      boxShadow: "2px 2px 2px 2px #00000029",
      zIndex: "4",
        [theme.breakpoints.down(502)]:{
            height: "43px",
            width: "109px",
            padding: "8.6px 0",
            borderRadius: "25px",
        },
    },
    "& .InfoLectura:before": {
      position: "absolute",
      display: "flex",
      boxSizing: "border-box",
      padding: "0 0 0 10px",
      justifyContent: "center",
      alignItems: "center",
      height: "39.88px",
      width: "39.88px",
      borderRadius: "50%",
      content: '""',
      boxShadow: "3px 0px .5px 0px #00000029",
      background: "#FAFAFA",
      left: "90px",
      top: "65px",
      transform: "rotate(0.25turn)",
      [theme.breakpoints.down(502)]:{
        left: "45px",
        top: "30px",
        height: "20.88px",
        width: "20.88px",
        },
    },
    "& .InfoLectura:after": {
      content: '""',
      height: "15px",
      width: "15px",
      borderTop: "2px solid #20A698",
      borderLeft: "2px solid #20A698",
      position: "absolute",
      bottom: "-10px",
      left: "Calc(50% - 8px)",
      transform: "rotate(0.625turn)",
      [theme.breakpoints.down(502)]:{
        height: "10px",
        width: "10px",
        left: "Calc(50% - 4.5px)",
        bottom: "-3px",
        borderTop: "1px solid #20A698",
        borderLeft: "1px solid #20A698",
        },
    },
    "& .Sesion": {
      borderLeft: "2px solid #D8D8D8",
    },
    "& .Dato": {
      margin: "0 0 5px 0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#20A698",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        margin: "0 0 2.5px 0",
        },
    },
    "& .Caracter": {
      margin: "0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#000000",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        },
    },   },
    ContenedorLectura:{
        background:'#fff',
        width:'1160px',
        height:'100%',
        padding:'60px 168px 35px ',
        [theme.breakpoints.down(1161)]:{
            width:'502px',
            padding:'60px 0 0 0',
          },
        [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
        '& .ContainerDerechos':{
            bottom:'80px',
            [theme.breakpoints.down(1161)]:{
                display:'none',
            },
        },
        '& .Derechos':{
            textAlign:"center",
            margin:"0px",
            font:" 15px lato",
            color:"#717476",
        },
        '& .Escritorio':{
            display:'block',
            [theme.breakpoints.down(1161)]:{
                display:'none'
            },
        },
        '& .Tablet':{
            display:'none',
            [theme.breakpoints.down(1161)]:{
                display:'block'
            },
        }
    },
    ContainerTitulo:{
        margin:'0 0 34px 0',
        [theme.breakpoints.down(1161)]:{
            margin:'0 0 34px 0',
        },
        '& .Titulo':{
            letterSpacing: '0px',
            color: '#FF2B2B',
            margin:'0',
            textAlign:'center',
            font:'48px fredoka one',
            lineHeight:'58px',
            [theme.breakpoints.down(1161)]:{
                font:'43px fredoka one',
                lineHeight:'52px',
            },
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            margin:'0 0 36px 0',
            color:'#000',
            font:'22px lato',
            lineHeight : '30px',
            letterSpacing: '0.20px',
            [theme.breakpoints.down(1161)]:{
                font:'18px lato',
                lineHeight :'28px',
                letterSpacing: '0.17px',
            },
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        "& .SubTitulo": {
            color: "#00ADB6",
            margin: "0",
            font: "bold 30px lato",
            letterSpacing: ".28px",
            lineHeight: "30px",
            [theme.breakpoints.down(1161)]: {
              lineHeight: "28px",
              font: "bold 26px lato",
              letterSpacing: "0.24px",
            },
            [theme.breakpoints.down(502)]: {
              font: "bold 22px lato",
              lineHeight: "24px",
              letterSpacing: "0.21px",
            },
        },
        '& .TextImg':{
            color:'#717476',
            margin:'0',
            font:'20px lato',
            lineHeight:'30px',
            letterSpacing:'.19px',
            [theme.breakpoints.down(1280)]:{
                margin:'21px 0 0 0',
                font:' 16px lato',
                lineHeight :'25px',
                letterSpacing: '0.15px',
            },
            [theme.breakpoints.down(502)]:{
                font:' 11px lato',
                lineHeight :'14px',
                letterSpacing: '0.10px',
            },
        },
        '& .Imagen':{
            [theme.breakpoints.down(1161)]:{
                width:'100%',
            },
        }, 
      
    }
}));

const La_automatización_y_la_revolución_digital = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
             <Grid container item justifyContent="center" className={classes.ContainerInfoLectura}>
               <Grid container className="BarraInfoLectura"></Grid>
               <Grid container item className="InfoLectura">
                    <Grid container item direction="column" xs={6} justifyContent="center">
                        <p className="Dato">Grado</p>
                        <p className="Caracter">4</p>
                    </Grid>
                    <Grid container item direction="column" xs={6} justifyContent="center" className="Sesion">
                        <p className="Dato">Sesión</p>
                        <p className="Caracter">42</p>
                    </Grid>
               </Grid>
            </Grid>

            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >
                        La automatización<br/> y la revolución digital                   
                    </h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                      <p className="Lectura">
                        La <em>innovación</em> es el proceso de modificar objetos, actividades e ideas para mejorarlos 
                        en favor de la sociedad. La humanidad ha innovado en muchos sentidos a través del 
                        tiempo, principalmente en el campo de la tecnología. Esto ha modificado nuestro 
                        estilo de vida en varios sentidos. Por ejemplo, la mayoría de la gente se dedicaba a 
                        cosechar y criar ganado antes, mientras que ahora los trabajos son diversos y se 
                        especializan cada vez más. Los efectos son benéficos y hacen grandes 
                        contribuciones, ¿pero las consecuencias del crecimiento tecnológico siempre son 
                        buenas?
                        <br/><br/>
                        Uno de los periodos de cambio más importantes que ha atravesado la humanidad fue 
                        la <em>Revolución industrial</em>, la cual ocurrió durante la segunda mitad del siglo <span style={{fontVariant: "all-small-caps"}}>XVIII</span>. En esa 
                        época, hubo varios inventos que mejoraron las condiciones de vida. Por ejemplo, se 
                        desarrollaron sistemas de transportes con lo que se crearon el ferrocarril y el barco 
                        de vapor tal como los conocemos. También se hicieron las primeras fábricas, las
                        cuales son la base de las que conocemos ahora. Este acontecimiento significó la 
                        llegada de nuevos empleos, actividades y necesidades, atendidos por cientos de 
                        personas en ese momento.
                        <br/><br/>
                        El automóvil es un ejemplo de innovación. Cuando se volvió más popular entre la 
                        gente, se formaron muchas industrias y negocios que antes no existían, tales como 
                        las fábricas de automóviles, las gasolinerías y el personal que pavimentaba las calles. 
                        Como ocurrió con este caso, cada nuevo invento generaba más empleos y las 
                        personas tenían más opciones para elegir dónde querían laborar.
                        <br/><br/>
                        El avance de la ciencia y la tecnología ha traído cosas positivas, tanto para la 
                        sociedad de los siglos pasados como para la actual. Pero las cosas comenzaron a 
                        cambiar cuando llegó la automatización. La <em>automatización</em> es el procedimiento 
                        mediante el cual una actividad se lleva a cabo por sí sola o de forma automática. La 
                        consecuencia directa es que el trabajo de varias personas puede ser realizado por 
                        una sola máquina, por lo tanto, las empresas que utilizaron este método ya no 
                        tuvieron la necesidad de contratar a tantos empleados.
                        <br/><br/>
                        Cuando aumentó la demanda de automóviles, las empresas no pudieron producir 
                        cantidades suficientes de forma rápida para satisfacer la demanda, así que optaron 
                        por introducir máquinas que facilitaran las labores. Los nuevos artefactos eran más 
                        veloces y eficaces que los seres humanos, además de que su uso resultaba más 
                        económico que pagar los salarios. Pronto, decenas de trabajadores perdieron sus 
                        empleos y fueron sustituidos por la tecnología.
                      </p> 
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <Grid cotainer item justifyContent='center' xs={12}>
                            <img className='Imagen' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img4to/Sesion42/1.png"  alt="" />
                        </Grid>
                        <Grid cotainer item xs={12}>
                            <p className='TextImg'>
                                Hoy en día existe una gran cantidad de actividades que son realizadas por máquinas en 
                                distintas industrias.
                            </p>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                      <p className="Lectura">
                        <h3 className='SubTitulo'>La revolución actual</h3><br/>

                        ¿Entonces los robots y las máquinas nos dejarán sin empleo? No necesariamente. En 
                        esta etapa también vivimos una revolución: la <em>revolución digital</em>. Como su nombre lo
                        indica, se basa en hacer digitales las cosas que no lo son. Ocurre en muchos ámbitos. 
                        Ahora la música se compra más por internet que en las tiendas. Las cartas y los 
                        mensajes se mandan de forma electrónica y no únicamente por correo. Incluso 
                        podemos hacer reuniones sin salir de casa a través de videollamadas y tener 
                        contacto en tiempo real con personas de todo el mundo.<br/><br/> 
                        
                        Existe la posibilidad de que los trabajos cambien lentamente, tal como sucedió en la 
                        Revolución industrial. Quizá algunos desaparecerán y otros serán realizados por 
                        robots. Pero también se crearán nuevos y diferentes empleos a los que tendremos 
                        que adaptarnos hasta la siguiente revolución.
                      </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <Grid cotainer item justifyContent='center' xs={12}>
                            <img className='Imagen' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img4to/Sesion42/2.png"  alt="" />
                        </Grid>
                        <Grid cotainer item xs={12}>
                            <p className='TextImg'>
                                Cada día son más las cosas que existen solamente en el mundo digital, hay 
                                cosas que han dejado de existir de manera física.
                            </p>
                        </Grid>                    
                    </Grid>
                </Grid>
               
                <Grid container item xs={12} justifyContent="center" className="ContainerDerechos">
                    <p className="Derechos">
                        © 2022. Todos los derechos reservados, Pearson, Circuito de lectores.
                    </p>                
                </Grid>
        </Grid>
    </Grid>
    );
}
 
export default La_automatización_y_la_revolución_digital ;
