import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
     Contenedor:{
        background:'#E9FBF9',
        width:'100vw',
        overflowX:'clip',
        [theme.breakpoints.down(1161)]:{
            background:'none',
           overflowX:'unset',
        },
        [theme.breakpoints.down(600)]:{
            marginTop: '79.51px',
        }
    },
  ContainerInfoLectura: {
    position: "relative",
    height: "86px",
    [theme.breakpoints.down(502)]:{
        height: "43px",
    },
    background: "#76D4C3",
    "& .BarraInfoLectura": {
      height: "86px",
      background: `Url(https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Barras/Azul.png)`,
        [theme.breakpoints.down(502)]:{
            height: "43px",
        },
    },
    "& .InfoLectura": {
      position: "absolute",
      height: "86px",
      width: "219px",
      boxSizing: "border-box",
      padding: "17.2px 0",
      background: "#FAFAFA",
      borderRadius: "50px",
      boxShadow: "2px 2px 2px 2px #00000029",
      zIndex: "4",
        [theme.breakpoints.down(502)]:{
            height: "43px",
            width: "109px",
            padding: "8.6px 0",
            borderRadius: "25px",
        },
    },
    "& .InfoLectura:before": {
      position: "absolute",
      display: "flex",
      boxSizing: "border-box",
      padding: "0 0 0 10px",
      justifyContent: "center",
      alignItems: "center",
      height: "39.88px",
      width: "39.88px",
      borderRadius: "50%",
      content: '""',
      boxShadow: "3px 0px .5px 0px #00000029",
      background: "#FAFAFA",
      left: "90px",
      top: "65px",
      transform: "rotate(0.25turn)",
      [theme.breakpoints.down(502)]:{
        left: "45px",
        top: "30px",
        height: "20.88px",
        width: "20.88px",
        },
    },
    "& .InfoLectura:after": {
      content: '""',
      height: "15px",
      width: "15px",
      borderTop: "2px solid #20A698",
      borderLeft: "2px solid #20A698",
      position: "absolute",
      bottom: "-10px",
      left: "Calc(50% - 8px)",
      transform: "rotate(0.625turn)",
      [theme.breakpoints.down(502)]:{
        height: "10px",
        width: "10px",
        left: "Calc(50% - 4.5px)",
        bottom: "-3px",
        borderTop: "1px solid #20A698",
        borderLeft: "1px solid #20A698",
        },
    },
    "& .Sesion": {
      borderLeft: "2px solid #D8D8D8",
    },
    "& .Dato": {
      margin: "0 0 5px 0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#20A698",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        margin: "0 0 2.5px 0",
        },
    },
    "& .Caracter": {
      margin: "0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#000000",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        },
    },   },
    ContenedorLectura:{
        background:'#fff',
        width:'1160px',
        height:'100%',
        padding:'60px 168px 35px ',
        [theme.breakpoints.down(1161)]:{
            width:'502px',
            padding:'60px 0 0 0',
          },
        [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
        '& .ContainerDerechos':{
            bottom:'80px',
            [theme.breakpoints.down(1161)]:{
                display:'none',
            },
        },
        '& .Derechos':{
            textAlign:"center",
            margin:"0px",
            font:" 15px lato",
            color:"#717476",
        },
        '& .Escritorio':{
            display:'block',
            [theme.breakpoints.down(1161)]:{
                display:'none'
            },
        },
        '& .Tablet':{
            display:'none',
            [theme.breakpoints.down(1161)]:{
                display:'block'
            },
        }
    },
    ContainerTitulo:{
        margin:'0 0 34px 0',
        [theme.breakpoints.down(1161)]:{
            margin:'0 0 34px 0',
        },
        '& .Titulo':{
            font:'48px fredoka one',
            letterSpacing: '0px',
            lineHeight:'58px',
            color: '#F6664D',
            margin:'0',
            textAlign:'center',
            [theme.breakpoints.down(1161)]:{
                font:'43px fredoka one',
                lineHeight:'52px',
            },
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            lineHeight : '30px',
            margin:'0 0 36px 0',
            font:'22px lato',
            color:'#000',
            letterSpacing: '0.20px',
            [theme.breakpoints.down(1161)]:{
                font:'18px lato',
                lineHeight :'28px',
                letterSpacing: '0.17px',
            },
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .SubTitulo':{
            font:'bold 30px lato',
            color:'#0CB5D6',
            letterSpacing:'.28px',
            lineHeight:'30px',
            margin:'0',
            [theme.breakpoints.down(1161)]:{
                lineHeight :'28px',
                font:'bold 26px lato',
                letterSpacing: '0.24px',
            },
            [theme.breakpoints.down(502)]:{
                font:'bold 18px lato',
                lineHeight :'24px',
                letterSpacing: '0.21px',
            },
        },
        '& .SubTitulo2':{
            font:'bold 26px lato',
            color:'#F6664D',
            letterSpacing:'.24px',
            lineHeight:'28px',
            margin:'0',
            [theme.breakpoints.down(1161)]:{
                font:'bold 22px lato',
                lineHeight :'24px',
                letterSpacing: '0.21px',
            },
            [theme.breakpoints.down(502)]:{
                font:'bold 18px lato',
                lineHeight :'20px',
                letterSpacing: '0.16px',
            },
        },
        '& .TextImg':{
            font:'20px lato',
            color:'#717476',
            letterSpacing:'.19px',
            lineHeight:'30px',
            margin:'24px 0 0 0',
            [theme.breakpoints.down(1161)]:{
                font:' 16px lato',
                lineHeight :'25px',
                letterSpacing: '0.15px',
            },
            [theme.breakpoints.down(502)]:{
                font:' 11px lato',
                lineHeight :'14px',
                letterSpacing: '0.10px',
            },
        },

        '& .Imagen':{
            [theme.breakpoints.down(1161)]:{
                width:'100%',
            },
        },  
      
    }
}));

const Basura_espacial = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
             <Grid container item justifyContent="center" className={classes.ContainerInfoLectura}>
               <Grid container className="BarraInfoLectura"></Grid>
               <Grid container item className="InfoLectura">
                    <Grid container item direction="column" xs={6} justifyContent="center">
                        <p className="Dato">Grado</p>
                        <p className="Caracter">5</p>
                    </Grid>
                    <Grid container item direction="column" xs={6} justifyContent="center" className="Sesion">
                        <p className="Dato">Sesión</p>
                        <p className="Caracter">35</p>
                    </Grid>
               </Grid>
            </Grid>

            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                        <h2 className="Titulo">Basura espacial</h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                       <p className='Lectura'>
                        El 4 de octubre de 1957 la Unión Soviética lanzó al espacio su primer satélite: el 
                        Sputnik. Ni siquiera alcanzaba los 85 kg, pero era capaz de transmitir datos sobre la 
                        densidad y la temperatura de la alta atmósfera.Esta información resultó muy valiosa 
                        para quienes estudiaban el espacio exterior.<br/><br/> 
                        
                        <h3 className='SubTitulo'>Mejor conectados y más sabios </h3><br/>
                        
                        Desde entonces se han lanzado al espacio muchos satélites, sondas y estaciones 
                        espaciales. Recientemente se han realizado incluso viajes turísticos. ¿Cuál es el 
                        propósito de todo esto? <br/><br/>
                        
                        En primer lugar, la humanidad se ha beneficiado lanzando satélites de 
                        telecomunicación. Gracias a ellos funcionan la telefonía celular y el internet 
                        inalámbrico. También son una parte esencial de los sistemas de geolocalización 
                        (como el GPS) que permiten encontrar lugares y personas en todo el mundo. <br/><br/>
                        
                        En segundo lugar, algunos objetos orbitan la Tierra para contribuir a la exploración 
                        espacial. Por ejemplo, el telescopio Hubble ha reunido datos importantes sobre el
                        origen del universo, el comportamiento de las estrellas y la composición de los 
                        planetas. Estas observaciones no podrían haberse realizado desde la Tierra, porque 
                        la atmósfera las distorsiona y produce imágenes menos exactas. <br/><br/>
                        
                        En tercer lugar, hay algunas estaciones espaciales dedicadas a la investigación 
                        científica. La Estación Espacial Internacional permite realizar toda clase de 
                        experimentos en condiciones muy diferentes a las del planeta. Hasta ahora ha 
                        logrado importantes avances tecnológicos en materia de celdas solares y novedosos 
                        sistemas para filtrar el agua.
                       </p>                   
                    </Grid>
                    <Grid container justifyContent='center'>
                        <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion35/2.png" alt="Tarjeta" />
                    </Grid>
                    <Grid container >
                        <p className="TextImg">
                            Muchos avances científicos provienen de investigaciones que sólo pueden realizarse en el espacio. ¡Te invitamos a investigar cuáles son!
                        </p>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container >
                        <p className='Lectura'>
                            <h3 className='SubTitulo'>Contaminación en la órbita</h3><br/>
                            
                            El problema es que los artefactos que se envían al espacio se quedan ahí. En 2018 la 
                            Oficina de Naciones Unidas para Asuntos del Espacio Exterior señaló que, aunque 
                            había 4 921 satélites orbitando la Tierra, solamente funcionaban 2 321. El resto es 
                            basura espacial: artefactos que permanecen girando en torno del planeta sin ninguna
                            utilidad. <br/><br/>
                            
                            Esta basura espacial es una forma de contaminación y supone un gran peligro. Un 
                            fragmento de metal suelto en el espacio puede alcanzar una velocidad de hasta 36 
                            000 km/h. ¡Eso significa ir 40 veces más rápido que un avión de pasajeros! Así, 
                            incluso el objeto más pequeño puede dañar gravemente a un satélite que aún 
                            funcione y que esté tripulado.<br/><br/>
                            
                            Se calcula que en la actualidad hay más de 17 000 objetos orbitando la Tierra. Las 
                            posibilidades de colisión entre ellos aumentan cada día. ¿Qué pasaría en ese caso? 
                            De inmediato habría interferencias en la comunicación, y si el choque fuera lo 
                            suficientemente fuerte, incluso podría enviar escombros espaciales al planeta.<br/><br/>
                            
                            ¿Es correcto seguir enviando artefactos al espacio? Por un lado, la tecnología 
                            espacial amplía los horizontes de la investigación científica y hace más fácil la vida en 
                            la Tierra (al menos para una parte de la población). Por el otro, contamina la órbita de 
                            la Tierra y aumenta el riesgo de colisiones espaciales. Los gobiernos y empresas de
                            todo el mundo se preguntan qué podemos hacer para encontrar un equilibrio y ya 
                            han comenzado a surgir soluciones.<br/><br/>
                            
                            <h3 className='SubTitulo2'>Soluciones</h3> <br/>
                            
                            Algunas empresas espaciales han empezado a utilizar <em>satélites de baja órbita</em>, es decir, 
                            satélites que se desplazan a alturas menores. De esta manera será más sencillo 
                            traerlos de vuelta a la Tierra cuando dejen de funcionar. <br/><br/>
                            
                            Otras propuestas parecen sacadas de libros de ciencia ficción. Incluyen utilizar 
                            imanes gigantes para atraer los restos metálicos y usar enormes redes (parecidas a 
                            las de pescar) para recoger los deshechos.
                        </p>
                    </Grid>
                    <Grid container justifyContent='center'>
                        <Grid container justifyContent='center'>
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion35/1.png" alt="Tarjeta" />
                        </Grid>
                        <Grid container justifyContent='center'>
                            <p className="TextImg">
                                ¿Imaginas otra solución para acabar con la basura espacial?
                            </p>
                        </Grid>
                    </Grid>
                </Grid>
            
                <Grid container item xs={12} justifyContent="center" className="ContainerDerechos">
                    <p className="Derechos">
                        © 2022. Todos los derechos reservados, Pearson, Circuito de lectores.
                    </p>                
                </Grid>
        </Grid>
    </Grid>
    );
}
 
export default Basura_espacial;
