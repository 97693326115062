import { makeStyles } from "@material-ui/core";
import React from "react";

import { Link } from "react-router-dom";
import ContadorModal from "../Modals/ContadorModal";
import NarrativaModal from "../Modals/Frames/NarrativaModal";
import IntervencionHeraldoModal from "../Modals/IntervencionHeraldoModal";
import MapButtonRemedial from "./MapButtonRemedial";

const useStyles = makeStyles((theme) => ({
  link: {
    "& .BotonGrande": {
      cursor: "pointer",
      border: "none",
      backgroundColor: "transparent",
      position: "absolute",
      height: "55.7px",
      width: "55.7px",
      borderRadius: "50%",
      font: " 22px lato",
      "&:hover": {
        // borderWidth: "2px",
        // borderStyle: "solid",
        // borderColor: (props) => props.color,
      },
    },
    "& .Bienvenida": {
      top: "106.5px",
      left: "320px",
      "&:hover": {
        "&:before": {
          display: "flex",
          position: "relative",
          justifyContent: "center",
          alignItems: "center",
          bottom: "50px",
          content: '"Bienvenida"',
          background: "#fff",
          borderRadius: "25px",
          font: "bold 16px lato",
          height: "25px",
          width: "100px",
        },
      },
    },
    "& .Boton": {
      cursor: "pointer",
      border: "none",
      backgroundColor: "transparent",
      position: "absolute",
      height: "37px",
      width: "37px",
      borderRadius: "50%",
    },
    "& .bloqueada": {
      borderWidth: "2px",
      borderStyle: "solid",
      borderColor: "#808080",
      backgroundColor: "#80808080",

      "&:hover": {
        cursor: "default",
      },
    },
    "& .completa": {
      borderWidth: "2px",
      borderStyle: "solid",
      borderColor: "#008000",
      "&:hover": {},
    },
    "& .contador": {
      borderWidth: "2px",
      borderStyle: "solid",
      borderColor: "#FFCE30",
      "&:hover": {},
    },
    "& .remedial": {
      borderWidth: "2px",
      borderStyle: "solid",
      borderColor: "#800080",
      "&:hover": {},
    },
    "& .Actividad2": {
      top: "196.5px",
      left: "320px",
      "&:hover": {
        "&:before": {
          display: "flex",
          position: "relative",
          justifyContent: "center",
          alignItems: "center",
          bottom: "50px",
          content: '"Evaluación diagnóstica"',
          background: "#fff",
          borderRadius: "25px",
          font: "bold 16px lato",
          minHeight: "25px",
          minWidth: "175px",
          height: "auto",
          width: "auto",
        },
      },
    },
    "& .Actividad3": {
      top: "269.5px",
      left: "329.5px",
    },
    "& .Actividad4": {
      top: "323.5px",
      left: "329.5px",
    },
    "& .Actividad5": {
      top: "377.5px",
      left: "329.5px",
    },
    "& .Actividad6": {
      top: "431.5px",
      left: "329.5px",
    },
    "& .Actividad7": {
      top: "485.5px",
      left: "329.5px",
    },
    "& .Actividad8": {
      top: "539.5px",
      left: "329.5px",
    },
    "& .Actividad9": {
      top: "593.5px",
      left: "329.5px",
    },
    "& .Actividad10": {
      top: "647.5px",
      left: "329.5px",
    },
    "& .Actividad11": {
      top: "701.5px",
      left: "329.5px",
    },
    "& .Actividad12": {
      top: "755.5px",
      left: "329.5px",
    },
    "& .Actividad13": {
      top: "809.5px",
      left: "329.5px",
    },
    "& .Actividad14": {
      top: "863.5px",
      left: "329.5px",
    },
    "& .Actividad15": {
      top: "863.5px",
      left: "383.5px",
    },
    "& .Actividad16": {
      top: "863.5px",
      left: "437.5px",
    },
    "& .Actividad17": {
      top: "863.5px",
      left: "491.5px",
    },
    "& .Actividad18": {
      top: "863.5px",
      left: "545.5px",
    },
    "& .Actividad19": {
      top: "863.5px",
      left: "599.5px",
    },
    "& .Actividad20": {
      top: "854.5px",
      left: "652.5px",
      "&:hover": {
        "&:before": {
          display: "flex",
          position: "relative",
          justifyContent: "center",
          alignItems: "center",
          bottom: "50px",
          content: '"Evaluación Formativa 1"',
          background: "#fff",
          borderRadius: "25px",
          font: "bold 16px lato",
          minHeight: "25px",
          minWidth: "195px",
          height: "auto",
          width: "auto",
        },
      },
    },
    "& .Actividad21": {
      top: "863.5px",
      left: "725.5px",
    },
    "& .Actividad22": {
      top: "863.5px",
      left: "779.5px",
    },
    "& .Actividad23": {
      top: "863.5px",
      left: "833.5px",
    },
    "& .Actividad24": {
      top: "863.5px",
      left: "887.5px",
    },
    "& .Actividad25": {
      top: "863.5px",
      left: "941.5px",
    },
    "& .Actividad26": {
      top: "809.5px",
      left: "941.5px",
    },
    "& .Actividad27": {
      top: "755.5px",
      left: "941.5px",
    },
    "& .Actividad28": {
      top: "701.5px",
      left: "941.5px",
    },
    "& .Actividad29": {
      top: "647.5px",
      left: "941.5px",
    },
    "& .Actividad30": {
      top: "593.5px",
      left: "941.5px",
    },
    "& .Actividad31": {
      top: "539.5px",
      left: "941.5px",
    },
    "& .Actividad32": {
      top: "485.5px",
      left: "941.5px",
    },
    "& .Actividad33": {
      top: "431.5px",
      left: "941.5px",
    },
    "& .Actividad34": {
      top: "377.5px",
      left: "941.5px",
    },
    "& .Actividad35": {
      top: "323.5px",
      left: "941.5px",
    },
    "& .Actividad36": {
      top: "269.5px",
      left: "941.5px",
    },
    "& .Actividad37": {
      top: "215.5px",
      left: "941.5px",
    },
    "& .Actividad38": {
      top: "206.5px",
      left: "994.5px",
      "&:hover": {
        "&:before": {
          display: "flex",
          position: "relative",
          justifyContent: "center",
          alignItems: "center",
          bottom: "50px",
          content: '"Evaluación Formativa 2"',
          background: "#fff",
          borderRadius: "25px",
          font: "bold 16px lato",
          minHeight: "25px",
          minWidth: "195px",
          height: "auto",
          width: "auto",
        },
      },
    },
    "& .Actividad39": {
      top: "215.5px",
      left: "1066.5px",
    },
    "& .Actividad40": {
      top: "215.5px",
      left: "1120.5px",
    },
    "& .Actividad41": {
      top: "215.5px",
      left: "1174.5px",
    },
    "& .Actividad42": {
      top: "215.5px",
      left: "1228.5px",
    },
    "& .Actividad43": {
      top: "215.5px",
      left: "1283.5px",
    },
    "& .Actividad44": {
      top: "215.5px",
      left: "1336.5px",
    },
    "& .Actividad45": {
      top: "215.5px",
      left: "1391.5px",
    },
    "& .Actividad46": {
      top: "215.5px",
      left: "1445.5px",
    },
    "& .Actividad47": {
      top: "215.5px",
      left: "1499.5px",
    },
    "& .Actividad48": {
      top: "215.5px",
      left: "1553.5px",
    },
    "& .Actividad49": {
      top: "269.5px",
      left: "1553.5px",
    },
    "& .Actividad50": {
      top: "323.5px",
      left: "1553.5px",
    },
    "& .Actividad51": {
      top: "377.5px",
      left: "1553.5px",
    },
    "& .Actividad52": {
      top: "431.5px",
      left: "1553.5px",
    },
    "& .Actividad53": {
      top: "485.5px",
      left: "1553.5px",
    },
    "& .Actividad54": {
      top: "539.5px",
      left: "1553.5px",
    },
    "& .Actividad55": {
      top: "593.5px",
      left: "1543.5px",
      "&:hover": {
        "&:before": {
          display: "flex",
          position: "relative",
          justifyContent: "center",
          alignItems: "center",
          bottom: "50px",
          content: '"Evaluación Formativa 3"',
          background: "#fff",
          borderRadius: "25px",
          font: "bold 16px lato",
          minHeight: "25px",
          minWidth: "195px",
          height: "auto",
          width: "auto",
        },
      },
    },
    "& .Actividad56": {
      top: "665.5px",
      left: "1553.5px",
    },
    "& .Actividad57": {
      top: "719.5px",
      left: "1553.5px",
    },
    "& .Actividad58": {
      top: "772.5px",
      left: "1553.5px",
    },
    "& .Actividad59": {
      top: "826.5px",
      left: "1543.5px",
      "&:hover": {
        "&:before": {
          display: "flex",
          position: "relative",
          justifyContent: "center",
          alignItems: "center",
          bottom: "50px",
          content: '"Evaluación Final"',
          background: "#fff",
          borderRadius: "25px",
          font: "bold 16px lato",
          height: "25px",
          width: "145px",
        },
      },
    },
    "& .Actividad60": {
      top: "917.5px",
      left: "1543.5px",
      "&:hover": {
        "&:before": {
          display: "flex",
          position: "relative",
          justifyContent: "center",
          alignItems: "center",
          bottom: "50px",
          content: '"Sesión de cierre"',
          background: "#fff",
          borderRadius: "25px",
          font: "bold 16px lato",
          height: "25px",
          width: "175px",
        },
      },
    },
  },
}));

const ButtonMap = ({
  data,
  handleClose,
  actualizeList,
  grade,
  abbreviatedGrade,
}) => {
  const { path_imagen, narrativa, nombre, sesion } = data;
  const [intervention, setIntervention] = React.useState(false);
  const [contadorOpen, setContadorOpen] = React.useState(false);

  const handleIntervention = () => {
    setIntervention(!intervention);
  };

  const handleContadorOpen = () => {
    setContadorOpen(!contadorOpen);
  };

  const sessionNumber = `${`0${data?.sesion}`?.slice(-2)}${abbreviatedGrade}`;
  const to = `session${sessionNumber}`;
  //En caso de ser una sesion menor a 10 agregará un 0. Ejemeplo: Sesion 2 seria /session02

  const formativas = [1, 2, 20, 38, 55, 59, 60];
  const buttonLarge = formativas?.includes(sesion);

  const classes = useStyles({});
  return (
    <>
      {data?.actividad_tipo.includes("Remedial") ? (
        <>
          <MapButtonRemedial data={data} actualizeList={actualizeList} />
        </>
      ) : (
        <>
          {/* {data?.actividad_status.toLowerCase() === "pendiente" && ( */}
          {data?.actividad_status.toLowerCase() === "sesión desbloqueada" && (
            <>
              {narrativa ? (
                <>
                  <Link className={classes.link} to="#">
                    <button
                      className={
                        buttonLarge
                          ? `BotonGrande Actividad${sesion}`
                          : `Boton Actividad${sesion}`
                      }
                      onClick={handleIntervention}
                      aria-label={`Actividad ${sesion}`}
                    ></button>
                  </Link>
                  {intervention && (
                    <IntervencionHeraldoModal
                      open={intervention}
                      path={path_imagen}
                      handleClose={handleIntervention}
                      narrativa={narrativa}
                      alt={nombre}
                      grado={grade}
                      to={sessionNumber}
                      sesion={sesion}
                    />
                  )}
                </>
              ) : (
                <>
                  {path_imagen.includes(".mp4") ? (
                    <>
                      <Link className={classes.link} to="#">
                        <button
                          className={
                            buttonLarge
                              ? `BotonGrande Actividad${sesion}`
                              : `Boton Actividad${sesion}`
                          }
                          aria-label={`Actividad ${sesion}`}
                          onClick={handleIntervention}
                        ></button>
                      </Link>
                      <NarrativaModal
                        open={intervention}
                        path={path_imagen}
                        handleClose={handleIntervention}
                        to={sessionNumber}
                      />
                    </>
                  ) : (
                    <>
                      <Link className={classes.link} to={to}>
                        <button
                          className={
                            buttonLarge
                              ? `BotonGrande Actividad${sesion}`
                              : `Boton Actividad${sesion}`
                          }
                          aria-label={`Actividad ${sesion}`}
                        ></button>
                      </Link>
                    </>
                  )}
                  {/* {[path_imagen].includes(".mp4") ? (
                  <>
                    <Link className={classes.link} to="#">
                      <button
                        className={buttonLarge ? `BotonGrande Actividad${sesion}` : `Boton Actividad${sesion}`}
                        onClick={handleIntervention}
                      ></button>
                    </Link>
                    <NarrativaModal open={intervention} path={path_imagen} handleClose={handleIntervention} to={sessionNumber} />
                  </>
                ) : (
                  <>
                    <Link className={classes.link} to={to}>
                      <button className={buttonLarge ? `BotonGrande Actividad${sesion}` : `Boton Actividad${sesion}`}></button>
                    </Link>
                  </>
                )} */}
                </>
              )}
            </>
          )}
          {data?.actividad_status.toLowerCase() === "completa" && (
            <>
              {data.narrativa ? (
                <>
                  <Link className={classes.link} to="#">
                    <button
                      className={
                        buttonLarge
                          ? `BotonGrande Actividad${sesion} completa`
                          : `Boton Actividad${sesion}  completa`
                      }
                      aria-label={`Actividad ${sesion}`}
                      onClick={handleIntervention}
                    ></button>
                  </Link>
                  {intervention && (
                    <IntervencionHeraldoModal
                      open={intervention}
                      path={path_imagen}
                      handleClose={handleIntervention}
                      narrativa={narrativa}
                      alt={nombre}
                      grado={grade}
                      to={sessionNumber}
                      sesion={sesion}
                    />
                  )}
                </>
              ) : (
                <>
                  {path_imagen.includes(".mp4") ? (
                    <>
                      <Link className={classes.link} to="#">
                        <button
                          className={
                            buttonLarge
                              ? `BotonGrande Actividad${sesion} completa`
                              : `Boton Actividad${sesion} completa`
                          }
                          aria-label={`Actividad ${sesion}`}
                          onClick={handleIntervention}
                        ></button>
                      </Link>
                      <NarrativaModal
                        open={intervention}
                        path={path_imagen}
                        handleClose={handleIntervention}
                        to={sessionNumber}
                        nombre={nombre}
                      />
                    </>
                  ) : (
                    <>
                      <Link className={classes.link} to={to}>
                        <button
                          className={
                            buttonLarge
                              ? `BotonGrande Actividad${sesion} completa`
                              : `Boton Actividad${sesion} completa`
                          }
                          aria-label={`Actividad ${sesion}`}
                        ></button>
                      </Link>
                    </>
                  )}
                </>
              )}
            </>
          )}
          {data?.actividad_status.toLowerCase() === "contador" && (
            <>
              <Link className={classes.link} to="#">
                <button
                  className={
                    buttonLarge
                      ? `BotonGrande Actividad${sesion} contador`
                      : `Boton Actividad${sesion} contador`
                  }
                  onClick={handleContadorOpen}
                ></button>
              </Link>
              <ContadorModal
                open={contadorOpen}
                handleClose={handleContadorOpen}
                timestamp={data.tiempo_espera}
                actualizeList={actualizeList}
              />
            </>
          )}
          {data?.actividad_status.toLowerCase() === "bloqueada" && (
            <>
              <Link className={classes.link} to="#">
                <button
                  className={
                    buttonLarge
                      ? `BotonGrande Actividad${sesion} bloqueada`
                      : `Boton Actividad${sesion} bloqueada`
                  }
                  // onClick={handleContadorOpen}
                  disabled
                ></button>
              </Link>
            </>
          )}
        </>
      )}
    </>
  );
};

export default ButtonMap;
