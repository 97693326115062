import { Button, Grid, makeStyles } from "@material-ui/core";
import React, { useContext } from "react";
import DrawerContext from "../../../../../usability/DrawerContext";
import Drawer from "../../ReadingTools/Drawer";

const useStyles = makeStyles((theme) => ({
  Contenedor: {
    background: "#E9FBF9",
    width: "100vw",
    [theme.breakpoints.down(1161)]: {
      background: "none",
    },
    [theme.breakpoints.down(600)]: {
      marginTop: "79.51px",
    },
  },
  ContainerInfoLectura: {
    position: "relative",
    height: "86px",
    background: "#76D4C3",
    "& .BarraInfoLectura": {
      height: "86px",
      background: `Url(https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Barras/Azul.png)`,
    },
    "& .InfoLectura": {
      position: "absolute",
      height: "86px",
      width: "219px",
      boxSizing: "border-box",
      padding: "17.2px 0",
      background: "#FAFAFA",
      borderRadius: "50px",
      boxShadow: "2px 2px 2px 2px #00000029",
      zIndex: "4",
    },
    "& .InfoLectura:before": {
      position: "absolute",
      display: "flex",
      boxSizing: "border-box",
      padding: "0 0 0 10px",
      justifyContent: "center",
      alignItems: "center",
      height: "39.88px",
      width: "39.88px",
      borderRadius: "50%",
      content: '""',
      boxShadow: "3px 0px .5px 0px #00000029",
      background: "#FAFAFA",
      left: "90px",
      top: "65px",
      transform: "rotate(0.25turn)",
    },
    "& .InfoLectura:after": {
      content: '""',
      height: "15px",
      width: "15px",
      borderTop: "2px solid #20A698",
      borderLeft: "2px solid #20A698",
      position: "absolute",
      bottom: "-10px",
      left: "Calc(50% - 8px)",
      transform: "rotate(0.625turn)",
    },
    "& .Sesion": {
      borderLeft: "2px solid #D8D8D8",
    },
    "& .Dato": {
      margin: "0 0 5px 0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#20A698",
    },
    "& .Caracter": {
      margin: "0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#000000",
    },
    "& .Regresar": {
      color: "#F2624F",
      border: "#F2624F 1px solid",
      height: "30px",
      width: "100px",
      borderRadius: "20px",
      letterSpacing: "0.14px",
      textTransform: "none",
      background: "#FEFAF1",
      top: "calc(50% - 15px)",
      left: "5%",
      zIndex: 4,
      [theme.breakpoints.down(502)]: {
        font: "bold 10px lato",
        width: "60px",
      },
    },
    "& .Regresar": {
      color: "#F2624F",
      border: "#F2624F 1px solid",
      height: "30px",
      width: "100px",
      borderRadius: "20px",
      letterSpacing: "0.14px",
      textTransform: "none",
      background: "#FEFAF1",
      top: "calc(50% - 15px)",
      left: "5%",
      zIndex: 4,
      [theme.breakpoints.down(502)]: {
        font: "bold 10px lato",
        width: "60px",
      },
    },
  },
  ContenedorLectura: {
    background: "#fff",
    width: "1160px",
    height: "100%",
    padding: "60px 168px 35px ",
    position: "relative",
    
    transform: (props) =>
      props.scale > 1 ? `scale(${props.scale}) translate(0px, 10%)` : `none`,
    [theme.breakpoints.down(1161)]: {
      width: "502px",
      padding: "60px 0 0 0",
    },
    [theme.breakpoints.down(502)]: {
      width: "350px",
      padding: "60px 0 0 0",
    },
    [theme.breakpoints.down(350)]: {
      width: "300px",
      padding: "30px 0 0 0",
    },
    "& .ContainerDerechos": {
      bottom: "80px",
      [theme.breakpoints.down(1161)]: {
        display: "none",
      },
    },
    "& .Derechos": {
      textAlign: "center",
      margin: "0px",
      font: " 15px lato",
      color: "#717476",
    },
    "& .Escritorio": {
      display: "block",
      [theme.breakpoints.down(1161)]: {
        display: "none",
      },
    },
    "& .Tablet": {
      display: "none",
      [theme.breakpoints.down(1161)]: {
        display: "block",
      },
    },
  },
  ContainerTitulo: {
    margin: "0 0 34px 0",
    "& .Titulo": {
      font: "48px fredoka one",
      letterSpacing: "0px",
      lineHeight: "58px",
      color: "#D94C66",
      margin: "0",
      textAlign: "center",
      [theme.breakpoints.down(1161)]: {
        font: "43px fredoka one",
        lineHeight: "52px",
      },
      [theme.breakpoints.down(502)]: {
        font: "30px fredoka one",
        lineHeight: "32px",
      },
    },
    "& .cel": {
      display: "none",
      [theme.breakpoints.down(502)]: {
        display: "block",
      },
    },
  },
  Parrafo1: {
    margin: " 0 0 36px 0",
    "& .Lectura": {
      lineHeight: "30px",
      margin: "0 0 36px 0",
      font: "22px lato",
      color: "#000",
      letterSpacing: "0.20px",
      [theme.breakpoints.down(1161)]: {
        font: "18px lato",
        lineHeight: "28px",
        letterSpacing: "0.17px",
      },
      [theme.breakpoints.down(502)]: {
        font: "12px lato",
        lineHeight: "18px",
        letterSpacing: "0.09px",
      },
    },
    "& .Esp": {
      padding: "0 25px 0 0",
      [theme.breakpoints.down(1161)]: {
        padding: "0 ",
      },
    },
    "& .SubTitulo": {
      font: "bold 30px lato",
      color: "#26CE64",
      letterSpacing: ".28px",
      lineHeight: "30px",
      margin: "0",
      [theme.breakpoints.down(1161)]: {
        lineHeight: "28px",
        font: "bold 26px lato",
        letterSpacing: "0.24px",
      },
      [theme.breakpoints.down(502)]: {
        font: "bold 22px lato",
        lineHeight: "24px",
        letterSpacing: "0.21px",
      },
    },

    "& .TextImg": {
      font: "20px lato",
      color: "#717476",
      letterSpacing: ".19px",
      lineHeight: "30px",
      margin: "24px 0 0 0",
      [theme.breakpoints.down(1161)]: {
        font: " 16px lato",
        lineHeight: "25px",
        letterSpacing: "0.15px",
      },
      [theme.breakpoints.down(502)]: {
        font: " 14px lato",
        lineHeight: "21px",
        letterSpacing: "0.15px",
      },
    },

    "& .Imagen": {
      width: "85.2%",
    },
    "& .Imagen2": {
      width: "83.4%",
    },
  },
}));

const Cómo_diferenciar_entre_el_bien_y_el_mal = (props) => {

   const {activeStep, setActiveStep, handlePreviewStep, id_actividad} = props
  
  const handleContinue = () => {
    setActiveStep(activeStep - 1)
    handlePreviewStep()
  }

 const {
   handleSize,
   disabledZoom,
   closeZoom,
   scale,
   
   
   
  
   
 } = useContext(DrawerContext);
 const detectSize = () => {
   const lectura = document.getElementById("lectura");

   const newWidth = lectura?.offsetWidth;
   const newHeight = lectura?.offsetHeight;
   if (lectura) handleSize(newHeight, newWidth);
 };
 React.useEffect(() => {
   window.addEventListener("resize", detectSize);
   return () => {
     window.removeEventListener("resize", detectSize);
     closeZoom();
   };
 }, []);

 const classes = useStyles({ disabledZoom, scale });
  return (
    <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
      <Grid container item justifyContent="center" className={classes.ContainerInfoLectura}>
        <Grid container className="BarraInfoLectura">
            <Button className="Regresar" onClick={handleContinue}>
                Regresar
            </Button>
        </Grid>
        <Grid container item className="InfoLectura">
          <Grid container item direction="column" xs={6} justifyContent="center">
            <p className="Dato">Grado</p>
            <p className="Caracter">1</p>
          </Grid>
          <Grid container item direction="column" xs={6} justifyContent="center" className="Sesion">
            <p className="Dato">Sesión</p>
            <p className="Caracter">28</p>
          </Grid>
        </Grid>
      </Grid>

      <Grid
        container
        item
        className={classes.ContenedorLectura}
        justifyContent="center"
        id="lectura"
        onLoad={detectSize}

      >
        <Drawer id_lectura={id_actividad} withoutTransform ={true}/>
        <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
          <h2 className="Titulo">
            ¿Cómo diferenciar <br />
            entre el bien <br className="cel"/>y el mal?
          </h2>
        </Grid>
        <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
          <Grid container sm={12}>
            <p className="Lectura Esp">
              ¿Te has preguntado qué pasaría si no estableciéramos ningún límite? Por ejemplo, imagina que estás jugando con un
              juguete. De pronto, un amigo tuyo se lo lleva sin pedirlo. ¿Cómo te sentirías? Seguramente pensarías que algo no
              está bien. <br />
              <br />
              <h3 className="SubTitulo">La moral</h3> <br />
              Todas nuestras acciones tienen consecuencias. Por lo tanto, es necesario establecer reglas que las limiten. Esta
              función la desempeña la moral. La <em>moral</em> es un conjunto de reglas o valores que regulan nuestra conducta.
              ¿Quién establece estas normas? Nuestra familia, la escuela o la sociedad en general.
            </p>
          </Grid>
          <Grid container justifyContent="center" sm={12}>
            <Grid container justifyContent="center">
              <img
                className="Imagen"
                src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img1ro/Sesion28/1.png"
                alt="Tarjeta"
              />
            </Grid>
            <Grid container justifyContent="center">
              <p className="TextImg">Gracias a las normas morales, podemos convivir en armonía.</p>
            </Grid>
          </Grid>
        </Grid>
        <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
          <Grid container sm={12}>
            <p className="Lectura">
              <h3 className="SubTitulo">La ética</h3> <br />
              Ahora bien, ¿alguna vez te has dado cuenta de que a veces actúas de acuerdo con reglas o ideas que surgen en ti?
              ¿Reflexionas si es correcto hacer algo aun cuando las demás personas sí lo hacen? Por ejemplo, mentir para ayudar a
              un amigo. A esta capacidad le llamamos <em>ética</em>. La ética es la capacidad de analizar si lo que hacemos está
              bien o mal.
            </p>
          </Grid>
          <Grid container justifyContent="center" sm={12}>
            <Grid container justifyContent="center">
              <img
                className="Imagen2"
                src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img1ro/Sesion28/2.png"
                alt="Tarjeta"
              />
            </Grid>
            <Grid container justifyContent="center">
              <p className="TextImg">La ética viene de nuestras propias ideas.</p>
            </Grid>
          </Grid>
        </Grid>
        <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
          <Grid container sm={12}>
            <p className="Lectura">
              Ahora que ya lo sabes, puedes reconocer de dónde vienen las ideas acerca del bien y el mal, reflexionar acerca de
              tus decisiones y actuar de manera correcta.
            </p>
          </Grid>
        </Grid>
      </Grid>
      <Grid container item style={{ marginTop: 80 }}></Grid>
    </Grid>
  );
};

export default Cómo_diferenciar_entre_el_bien_y_el_mal;
