import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
      Contenedor:{
        background:'#E9FBF9',
        width:'100vw',
        overflowX:'clip',
        [theme.breakpoints.down(1161)]:{
            background:'none',
           overflowX:'unset',
        },
        [theme.breakpoints.down(600)]:{
            marginTop: '79.51px',
        }
    },
  ContainerInfoLectura: {
    position: "relative",
    height: "86px",
    [theme.breakpoints.down(502)]:{
        height: "43px",
    },
    background: "#76D4C3",
    "& .BarraInfoLectura": {
      height: "86px",
      background: `Url(https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Barras/Azul.png)`,
        [theme.breakpoints.down(502)]:{
            height: "43px",
        },
    },
    "& .InfoLectura": {
      position: "absolute",
      height: "86px",
      width: "219px",
      boxSizing: "border-box",
      padding: "17.2px 0",
      background: "#FAFAFA",
      borderRadius: "50px",
      boxShadow: "2px 2px 2px 2px #00000029",
      zIndex: "4",
        [theme.breakpoints.down(502)]:{
            height: "43px",
            width: "109px",
            padding: "8.6px 0",
            borderRadius: "25px",
        },
    },
    "& .InfoLectura:before": {
      position: "absolute",
      display: "flex",
      boxSizing: "border-box",
      padding: "0 0 0 10px",
      justifyContent: "center",
      alignItems: "center",
      height: "39.88px",
      width: "39.88px",
      borderRadius: "50%",
      content: '""',
      boxShadow: "3px 0px .5px 0px #00000029",
      background: "#FAFAFA",
      left: "90px",
      top: "65px",
      transform: "rotate(0.25turn)",
      [theme.breakpoints.down(502)]:{
        left: "45px",
        top: "30px",
        height: "20.88px",
        width: "20.88px",
        },
    },
    "& .InfoLectura:after": {
      content: '""',
      height: "15px",
      width: "15px",
      borderTop: "2px solid #20A698",
      borderLeft: "2px solid #20A698",
      position: "absolute",
      bottom: "-10px",
      left: "Calc(50% - 8px)",
      transform: "rotate(0.625turn)",
      [theme.breakpoints.down(502)]:{
        height: "10px",
        width: "10px",
        left: "Calc(50% - 4.5px)",
        bottom: "-3px",
        borderTop: "1px solid #20A698",
        borderLeft: "1px solid #20A698",
        },
    },
    "& .Sesion": {
      borderLeft: "2px solid #D8D8D8",
    },
    "& .Dato": {
      margin: "0 0 5px 0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#20A698",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        margin: "0 0 2.5px 0",
        },
    },
    "& .Caracter": {
      margin: "0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#000000",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        },
    },   },
    ContenedorLectura:{
        background:'#fff',
        width:'1160px',
        height:'100%',
        padding:'60px 168px 35px ' ,
        [theme.breakpoints.down(1161)]:{
            width:'501px',
            padding:'60px 0 0',
        },
        [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
        '& .ContainerDerechos':{
            bottom:'80px',
            [theme.breakpoints.down(1161)]:{
                display:'none',
            }
        },
        '& .Derechos':{
            textAlign:"center",
            margin:"0px",
            font:" 15px lato",
            color:"#717476",
        },
        '& .Escritorio':{
            display:'block',
            [theme.breakpoints.down(1161)]:{
                display:'none'
            },
        },
        '& .Tablet':{
            display:'none',
            [theme.breakpoints.down(1161)]:{
                display:'block'
            },
        },    
    },

    ContainerTitulo:{
        margin:'0 0 46px 0',
        [theme.breakpoints.down(1161)]:{
            margin:'0 0 38px 0',
        },
        '& .Titulo':{
            font:'48px fredoka one',
            letterSpacing: '0px',
            color: '#926DD1',
            margin:'0',
            textAlign:'center',
            [theme.breakpoints.down(1161)]:{
                font:'43px fredoka one',
                lineHeight:'52px',
                textAlign:'center',
            },
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            lineHeight : '30px',
            margin:'0 0 36px 0 ',
            font:'22px lato',
            color:'#000',
            letterSpacing: '0.20px',
            [theme.breakpoints.down(1161)]:{
                font:'18px lato',
                letterSpacing:'.17px',
                lineHeight:'28px',
            },
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },

        '& .SubTitulo':{
            margin:'0',
            font:'bold 30px lato',
            lineHeight:'30px',
            letterSpacing:'.24px',
            color:'#0CB5D6',
            [theme.breakpoints.down(1161)]:{
                font:'bold 26px lato',
                lineHeight:'28px',
            },
            [theme.breakpoints.down(502)]:{
                font:'bold 22px lato',
                lineHeight:'24px',
            },
            [theme.breakpoints.down(350)]:{
                font:'bold 18px lato',
                lineHeight:'24px',
            },
        },
        '& .TextImg':{
            margin:'22px 0 0 0',
            font:'20px lato',
            alignSelf:'flex-start',
            color:'#717476',
            letterSpacing:'.19px',
            [theme.breakpoints.down(1161)]:{
                font:'16px lato',
                letterSpacing:'.15px',
                lineHeight:'25px',
            },
            [theme.breakpoints.down(502)]:{
                font:' 11px lato',
                lineHeight :'14px',
                letterSpacing: '0.10px',
            },
        },
        '& .Imagen1':{
            [theme.breakpoints.down(1161)]:{
                width:'100%',
            },
        },
    },
}));

const Las_criptomonedas_economía_digital = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
             <Grid container item justifyContent="center" className={classes.ContainerInfoLectura}>
               <Grid container className="BarraInfoLectura"></Grid>
               <Grid container item className="InfoLectura">
                    <Grid container item direction="column" xs={6} justifyContent="center">
                        <p className="Dato">Grado</p>
                        <p className="Caracter">6</p>
                    </Grid>
                    <Grid container item direction="column" xs={6} justifyContent="center" className="Sesion">
                        <p className="Dato">Sesión</p>
                        <p className="Caracter">32</p>
                    </Grid>
               </Grid>
            </Grid>

            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >
                        Las criptomonedas: <br/>economía digital
                    </h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                            Cuando piensas en comprar o vender cosas estás hablando de comercio, que se
                            define como el intercambio de bienes o servicios. Es decir, cuando vas a un centro 
                            comercial a comprar comida o ropa estás adquiriendo bienes. Y si en tu casa hay luz o 
                            internet es porque tu familia paga por un servicio. <br/><br/>
                            
                            Esto que para ti es muy común tiene una historia milenaria. Y aunque el comercio
                            siempre ha sido relativamente similar en el hecho de intercambiar bienes o servicios, 
                            lo que sí ha variado es lo que se da a cambio, es decir, con lo que se paga o 
                            intercambia. A continuación aprende un poco de historia sobre estos procesos para 
                            que se te facilite entender el motivo por el cual existen criptomonedas y lo que 
                            significan. <br/><br/>
                            
                            El trueque fue el inicio del comercio hace aproximadamente 10 000 millones de años. 
                            Consistía en intercambiar dos bienes entre personas: la gente se ponía de acuerdo en 
                            cosas que tuvieran el mismo valor y las intercambiaban. El problema de esto era la 
                            dificultad para definir si las cosas realmente valían lo mismo.
                        </p>
                    </Grid>
                    <Grid container  sm={12}>
                        <Grid container justifyContent="center">
                            <img className="Imagen1" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion32/1.png"  alt="Tarjeta" />
                        </Grid>
                        <Grid container> 
                            <p className='TextImg'>Aunque el trueque es milenario, en la actualidad sigue siendo usado en diversos contextos informales.</p>
                           
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                            Por ello, paulatinamente se crearon mecanismos para valorar las cosas de acuerdo 
                            con un mismo objeto común. Esto es la base de lo que hoy se conoce como las 
                            monedas de los países. <br/><br/>
                            
                            Algunas civilizaciones en la Antigüedad empleaban las semillas como moneda, de tal 
                            manera que se utilizó el cacao, el trigo o el maíz. La sal fue tan valiosa como método 
                            de pago que incluso hasta nuestros días se sigue utilizando la palabra salario. <br/><br/>
                            
                            Sin embargo, al igual que el trueque, las semillas o la sal como método de pago tenían 
                            desventajas, pues no se podían almacenar por ser perecederas. En otras palabras, 
                            sólo servían para intercambiar, pero no para ahorrar, pues se descomponían. Por ello 
                            surgió la necesidad de crear objetos que valieran y también se pudieran ahorrar. 
                            Entonces surgieron las monedas hechas de diversos materiales valiosos, como el oro 
                            y la plata. <br/><br/>
                            
                            En Mesopotamia alrededor del 1500 a. n. e. utilizaron por primera vez metales como 
                            método de pago, aunque no eran precisamente monedas. Pero no fue sino hasta el 
                            600 a. n. e. que surgieron las primeras monedas en Turquía, China e India. Y aunque 
                            fueron un gran paso, imagina cómo era llevar únicamente monedas a todas partes: 
                            son pesadas y ocupan mucho espacio. Por ello también en China, pero ya en el siglo 
                            VII de nuestra era, se creó el primer billete. <br/><br/>
                            
                            A mediados del siglo pasado inició el uso de las tarjetas bancarias, con el objetivo de 
                            evitar llevar dinero físico y facilitar el comercio. Pero con ello también se multiplicó 
                            la manera de robar, sobre todo con el avance del internet. <br/><br/>
                            
                            Hasta ahora has visto dos factores para generar cambios en la manera en que se 
                            maneja el dinero: practicidad y seguridad. Pero hay uno tercero que ha impulsado la 
                            nueva evolución: los bancos, que durante siglos se han encargado de guardar y 
                            distribuir el dinero en el mundo. <br/><br/>
                            
                            No toda la gente está de acuerdo con que esas instituciones sean las únicas 
                            responsables. Por ello, para dar mayor poder a los usuarios evadiendo el control 
                            bancario, y para hacer más seguras las transacciones, en 2008 se creó la primera 
                            criptomoneda: el bitcoin. Aunque en la actualidad existen más de 8 000. <br/><br/>
                            
                            Las criptomonedas son monedas digitales que se almacenan en un disco duro o una 
                            memoria externa. Están protegidas por un cifrado criptográfico, es decir, ocultan la 
                            información mediante claves.
                        </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <Grid container >
                            <img className="Imagen1" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion32/2.png"  alt="Tarjeta" />
                        </Grid>
                        <Grid container> 
                            <p className='TextImg'>Un problema de las criptomonedas es que generan problemas ambientales por su elevado consumo energético.</p>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                            Funcionan con un software que comparten todos los usuarios, el cual permite validar 
                            y asegurar que nadie está inventando más monedas de las que tiene. Para que sea 
                            más claro, piensa que cuando alguien desea pagar con tarjetas bancarias el vendedor 
                            pregunta digitalmente al banco si la persona tiene dinero suficiente en su cuenta 
                            para pagar. Si el banco lo confirma, se lleva a cabo la transacción. <br/><br/>
                            
                            En el caso de las criptomonedas, los usuarios tienen la información encriptada de los 
                            demás, así que al pagar algo, en lugar de preguntar a un banco, mediante complejos 
                            procesos digitales, ahora el resto de los usuarios son los encargados de confirmar si 
                            el comprador tiene fondos suficientes. Con esta tecnología se elimina la 
                            participación de los bancos. <br/><br/>
                            
                            Las criptomonedas son el inicio de otra gran revolución. Aún es difícil saber si 
                            prosperarán o será de otra manera. Pero sin duda el futuro inmediato no será como 
                            lo imaginas.
                        </p>
                    </Grid>
                </Grid>

                <Grid container item xs={12} justifyContent="center" className="ContainerDerechos">
                    <p className="Derechos">
                        © 2022. Todos los derechos reservados, Pearson, Circuito de lectores.
                    </p>                
                </Grid>
        </Grid>
    </Grid>
    );
}
 
export default Las_criptomonedas_economía_digital;
