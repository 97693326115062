import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
    ContenedorLectura:{
        height:'100%',
        width:'768px',
        [theme.breakpoints.down(768)]:{
        width:'350px',
        },
    },
    Parrafo1:{
        '& .Imagen':{
            width:'100%',
        },

      
    }
}));

const Edu_can = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>

            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container justifyContent='center'>
                        <img className="Imagen " src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img4to/Sesion43/F.png" alt="Tarjeta" />
                    </Grid>
                </Grid>

            
        </Grid>
    </Grid>
    );
}
 
export default Edu_can;
