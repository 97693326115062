import React, {useEffect}   from "react";
import RedirecToErrorActivity from "../../../../hooks/redirectToerrorActivity";
import { useHistory }         from 'react-router-dom';
import { makeStyles,Grid}   from '@material-ui/core';
import NavBar               from '../../../../NavBar';
import MenuIzquierdo        from '../../../../MenuIzquierdo';
import Activity             from '../../Activity';
import Lectura              from '../../../LecturasActividad/LecturasSegundo/Sesion48/Lectura';
import axios                from "../../../../../services/axios";
import QuestionProv         from "../../QuestionProv";
import Question             from "../../Question";

import QuestionImg             from "./QuestionImg";
import QuestionImg2             from "./QuestionImg2"; 
import QuestionImg3             from "./QuestionImg3"; 
import QuestionImg4             from "./QuestionImg4"; 

// import picture1             from './image/CL_G2_B3_S48_IC33_MATE_LógicaCreatividad_01.png';
import picture2             from './image/CL_G2_B3_S48_IC33_MATE_logicacreatividad_02.png';
import picture3             from './image/CL_G2_B3_S48_IC33_MATE_logicacreatividad_03.png';
import picture4             from './image/CL_G2_B3_S48_IC33_MATE_logicacreatividad_04.png';
import picture5             from './image/CL_G2_B3_S48_IC33_MATE_logicacreatividad_05.png';
import picture8             from './image/CL_G2_B3_S48_IC33_MATE_LogicaCreatividad_08.png';
import picture7a            from './image/CL_G2_B3_S48_IC33_MATE_LogicaCreatividad_07A.png';
import picture7b            from './image/CL_G2_B3_S48_IC33_MATE_LogicaCreatividad_07B.png';
import picture7c            from './image/CL_G2_B3_S48_IC33_MATE_LogicaCreatividad_07C.png';
import picture7d            from './image/CL_G2_B3_S48_IC33_MATE_LogicaCreatividad_07D.png';
import picture6a            from './image/CL_G2_B3_S48_IC33_MATE_LogicaCreatividad_06A.png';
import picture6b            from './image/CL_G2_B3_S48_IC33_MATE_LogicaCreatividad_06B.png';
import picture6c            from './image/CL_G2_B3_S48_IC33_MATE_LogicaCreatividad_06C.png';
import picture6d            from './image/CL_G2_B3_S48_IC33_MATE_LogicaCreatividad_06D.png';


const useStyles = makeStyles(theme=>({
  grid1:{    
    zIndex:'3'
    
  },
  grid2:{    
    zIndex:'2'
    
  },
  grid3:{    
    boxSizing:'border-box',
    zIndex:'1',
    paddingLeft:'3%',
    [theme.breakpoints.down('md')]:{
      paddingLeft:'0px',
    }
    
  }
}))

const ActivityS48 = (props) => {
  const classes = useStyles()
  const history = useHistory()
  const [step, setStep]                     = React.useState(0)
  const [questionList, setQuestionList]     = React.useState([])
  const [data, setData]                     = React.useState({})

  useEffect(() => {
    const body = {"id_actividad": 113};
    if(!questionList.length){
      axios.get( '/actividad/preguntas/get?id_actividad=113', body)
      .then(response => {
        setQuestionList(response.data.resultado.preguntas)
        setData(response.data.resultado)
        setStep(response.data.resultado.preguntas.findIndex(pregunta => !pregunta.respondida))
        RedirecToErrorActivity(response.data.resultado, history)
      })
    }
  }, [questionList.length, history])

  // const renderQuestion = () => {
  //   return(
  //     step === 9 ?
  //     <QuestionProv
  //     pregunta={ questionList.length > 0 ? questionList[step].pregunta : "cargando..."}
  //     step={step}/>
  //     :
  //     step === 1 ?
  //     <QuestionImg
  //       picture1={picture2}
  //       picture2={picture3}
  //       picture3={picture4}
  //       picture4={picture5}
  //     />
  //     :
  //     <Question
  //       step={step}
  //       pregunta={ questionList.length > 0 ? questionList[step].pregunta : "cargando..."}
  //     />
  //   )
  // }

  const renderQuestion = () => {
    switch (step) {
    
      case (1):
        return (
          <QuestionImg
            picture1={picture2}
            picture2={picture3}
            picture3={picture4}
            picture4={picture5}
          />
        )
      case (2):
        return (
          <QuestionImg2
          pictureA={picture6a}
          pictureB={picture6b}
          pictureC={picture6c}
          pictureD={picture6d}
          />
        )
      case (3):
        return (
          <QuestionImg3
            pictureA={picture7a}
            pictureB={picture7b}
            pictureC={picture7c}
            pictureD={picture7d}
          />
        )
        case (5):
          return (
            <QuestionImg4
              picture={picture8}
            />
          )
      case (9):
        return (
          <QuestionProv
            step={step}
            pregunta={ questionList.length > 0 ? questionList[step].pregunta : "cargando..."}
          />
        )
      default:
        return(
          <Question
            step={step}
            pregunta={ questionList.length > 0 ? questionList[step].pregunta : "cargando..."}
          />
        )
    }
  }



  return(
    <Grid container >
      <Grid item className={classes.grid1} sm={12}> 
        <NavBar/>
      </Grid>
      <Grid item className={classes.grid2} sm={1}> 
        <MenuIzquierdo/>
      </Grid>
      <Grid item className={classes.grid3} sm={12}>
        <Activity
          step={step}
          setStep={setStep}
          questionList={questionList}
          setQuestionList={setQuestionList}
          questionComponent={renderQuestion()}
          Lectura={Lectura}
          // imageList={imageList}
          // picturesTable={picturesTable}
          toSession={'/session48'}
          activityNumber={113}
          enrollmendId={data && data.id_actividad_inscripcion}
          tryNumber={data && data.intento}
        />
      </Grid>
    </Grid>
  )
}

export default ActivityS48;
