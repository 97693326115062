import { Grid,makeStyles}              from '@material-ui/core';
import {React,useState }                          from 'react';

const useStyle =  makeStyles (theme =>({
      Contenedor:{
        background:'#E9FBF9',
        width:'100vw',
        overflowX:'clip',
        [theme.breakpoints.down(1161)]:{
            background:'none',
           overflowX:'unset',
        },
        [theme.breakpoints.down(600)]:{
            marginTop: '79.51px',
        }
    },
  ContainerInfoLectura: {
    position: "relative",
    height: "86px",
    [theme.breakpoints.down(502)]:{
        height: "43px",
    },
    background: "#76D4C3",
    "& .BarraInfoLectura": {
      height: "86px",
      background: `Url(https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Barras/Azul.png)`,
        [theme.breakpoints.down(502)]:{
            height: "43px",
        },
    },
    "& .InfoLectura": {
      position: "absolute",
      height: "86px",
      width: "219px",
      boxSizing: "border-box",
      padding: "17.2px 0",
      background: "#FAFAFA",
      borderRadius: "50px",
      boxShadow: "2px 2px 2px 2px #00000029",
      zIndex: "4",
        [theme.breakpoints.down(502)]:{
            height: "43px",
            width: "109px",
            padding: "8.6px 0",
            borderRadius: "25px",
        },
    },
    "& .InfoLectura:before": {
      position: "absolute",
      display: "flex",
      boxSizing: "border-box",
      padding: "0 0 0 10px",
      justifyContent: "center",
      alignItems: "center",
      height: "39.88px",
      width: "39.88px",
      borderRadius: "50%",
      content: '""',
      boxShadow: "3px 0px .5px 0px #00000029",
      background: "#FAFAFA",
      left: "90px",
      top: "65px",
      transform: "rotate(0.25turn)",
      [theme.breakpoints.down(502)]:{
        left: "45px",
        top: "30px",
        height: "20.88px",
        width: "20.88px",
        },
    },
    "& .InfoLectura:after": {
      content: '""',
      height: "15px",
      width: "15px",
      borderTop: "2px solid #20A698",
      borderLeft: "2px solid #20A698",
      position: "absolute",
      bottom: "-10px",
      left: "Calc(50% - 8px)",
      transform: "rotate(0.625turn)",
      [theme.breakpoints.down(502)]:{
        height: "10px",
        width: "10px",
        left: "Calc(50% - 4.5px)",
        bottom: "-3px",
        borderTop: "1px solid #20A698",
        borderLeft: "1px solid #20A698",
        },
    },
    "& .Sesion": {
      borderLeft: "2px solid #D8D8D8",
    },
    "& .Dato": {
      margin: "0 0 5px 0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#20A698",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        margin: "0 0 2.5px 0",
        },
    },
    "& .Caracter": {
      margin: "0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#000000",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        },
    },   },

    ContenedorLectura:{
        background:'#FFFCF5',
        width:'1160px',
        height:'100%',
        padding:'68px 0 0 0',
        [theme.breakpoints.down(1161)]:{
            width:'768px',
          },
          [theme.breakpoints.down(768)]:{
            width:'350px',
          },
        '& .ContainerDerechos':{
            bottom:'80px',
            [theme.breakpoints.down(1161)]:{
                display:'none',
            },
        },
        '& .Derechos':{
            textAlign:"center",
            margin:"62px 0 15px 0",
            font:" 15px lato",
            color:"#717476",
        },
        '& .Escritorio':{
            display:'block',
            [theme.breakpoints.down(1161)]:{
                display:'none'
            },
        },
        '& .Tablet':{
            display:'none',
            [theme.breakpoints.down(1161)]:{
                display:'block'
            },
        }
    },
    ContainerTitulo:{
        margin:'0 0 0px 0',
        [theme.breakpoints.down(1161)]:{
            margin:'0 0 20px 0',
        },
        '& .Titulo':{
            font:'48px fredoka one',
            letterSpacing: '0px',
            lineHeight:'58px',
            color: '#F6644B',
            margin:'0',
            textAlign:'center',
            [theme.breakpoints.down(1161)]:{
                font:'43px fredoka one',
                lineHeight:'52px',
                display:'block',
            },
            [theme.breakpoints.down(768)]:{
                font:'38px fredoka one',
                lineHeight:'52px',
            },
        },
    },
    Parrafo1:{
        position:'relative',
        '& .Lectura':{
            font:'bold 22px lato',
            letterSpacing: '.21px',
            color: '#000000',
            lineHeight:'37px',
            [theme.breakpoints.down(1161)]:{
                font:'bold 17px lato',
                letterSpacing:'.15px',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(768)]:{
                font:'bold 12px lato',
                lineHeight :'18px',
                letterSpacing: '0.09px',
            },
        },

        '& .Pos':{
            position:'absolute',
            cursor:'pointer',
            height:'28px',
            width:'28px',
            [theme.breakpoints.down(768)]:{
                height:'8px',
                width:'8px',
            },
        },

        '& .Pos2':{
            position:'absolute',
        },

        '& .Corriente':{
            font:'bold 15px lato',
            letterSpacing: '.14px',
            color: '#353535',
            lineHeight:'19px',
            background: 'transparent linear-gradient(102deg, #FFFFFFC7 0%, #FFFFFFBF 100%) 0% 0% no-repeat padding-box',
            justifyContent:'center',
            borderRadius: '16px',
            height:'31px',
            padding:'0 5px',
            justifyContent:'center',
            alignItems:'center',
            [theme.breakpoints.down(1161)]:{
                font:'bold 10px lato',
                letterSpacing:'.09px',
                lineHeight:'13px',
                height:'21px',
            },
            [theme.breakpoints.down(768)]:{
                font:'bold 5px lato',
                letterSpacing:'.09px',
                lineHeight:'70px',
                height:'7px',
                padding:'0 2.5px',
            },
        },

        '& .Corriente1':{
            left:'21%',
            top:'14%',
            [theme.breakpoints.down(768)]:{
                left:'24%',
                top:'14%',
            }, 
        },

        '& .Corriente2':{
            left:'14%',
            top:'22%',
            [theme.breakpoints.down(768)]:{
                left:'17%',
                top:'22%',
            },
        },

        '& .Corriente2-1':{
            left:'86%',
            top:'23%',
            [theme.breakpoints.down(768)]:{
                left:'82%',
                top:'23%',
            }, 
        },

        '& .Corriente3':{
            left:'19%',
            top:'29%',
            [theme.breakpoints.down(768)]:{
                left:'21.2%',
                top:'28.5%',
            },    
        },

        '& .Corriente4':{
            left:'16%',
            top:'35%',
            [theme.breakpoints.down(768)]:{
                left:'18.5%',
                top:'35%',
            },    
        },

        '& .Corriente4-1':{
            left:'65%',
            top:'37%',
            [theme.breakpoints.down(768)]:{
                left:'63.5%',
                top:'37.5%',
            },  
        },

        '& .Corriente4-2':{
            left:'82%',
            top:'37%',
            [theme.breakpoints.down(768)]:{
                left:'79%',
                top:'37%',
            },  
        },

        '& .Corriente5':{
            left:'22%',
            top:'39%',
            [theme.breakpoints.down(768)]:{
                left:'25%',
                top:'38.8%',
            },  
        },

        '& .Corriente5-1':{
            left:'69%',
            top:'41%',
            [theme.breakpoints.down(768)]:{
                left:'67.5%',
                top:'40.5%',
            },  
        },

        '& .Corriente5-2':{
            left:'88%',
            top:'40%',
            [theme.breakpoints.down(768)]:{
                left:'85%',
                top:'39.70%',
            },  
        },

        '& .Corriente6':{
            left:'16%',
            top:'46%',
            [theme.breakpoints.down(768)]:{
                left:'19.2%',
                op:'45%',
            },  
        },

        '& .Corriente6-1':{
            left:'44%',
            top:'38%',
            [theme.breakpoints.down(768)]:{
                left:'44%',
                top:'38%',
            },  
        },

        '& .Corriente6-2':{
            left:'66%',
            top:'44%',
            [theme.breakpoints.down(768)]:{
                left:'64.2%',
                top:'43%',
            },
        },

        '& .Corriente7':{
            left:'29%',
            top:'56%',
            [theme.breakpoints.down(768)]:{
                left:'31%',
                top:'56%',
            },
        },

        '& .Corriente8':{
            left:'33%',
            top:'67%',
            [theme.breakpoints.down(768)]:{
                left:'35%',
                top:'66.5%',
                
            },
        },

        '& .Corriente8-1':{
            left:'65%',
            top:'65%',
            [theme.breakpoints.down(768)]:{
                left:'64%',
                top:'65%',
           },
        },

        '& .Corriente9':{
            left:'38%',
            top:'7%',
            [theme.breakpoints.down(768)]:{
                left:'39.2%',
                top:'7.5%',
           },
        },

        '& .Corriente10':{
            left:'45%',
            top:'8%',
            [theme.breakpoints.down(768)]:{
                left:'45.2%',
                top:'8%',
           }, 
        },

        '& .Corriente11':{
            left:'36%',
            top:'18%',
            [theme.breakpoints.down(768)]:{
                left:'37.2%',
                top:'18%',
           },
        },

        '& .Corriente12':{
            left:'30%',
            top:'25%',
            [theme.breakpoints.down(768)]:{
                left:'31.2%',
                top:'25%',
           },
        },

        '& .Corriente13':{
            left:'42%',
            top:'17%',
            [theme.breakpoints.down(768)]:{
                left:'43%',
                top:'17.5%',
           },
        },
        
        '& .Corriente14':{
            left:'52%',
            top:'7%',
            [theme.breakpoints.down(768)]:{
                left:'52%',
                top:'7%',
           }, 
        },

        '& .Corriente15':{
            left:'38%',
            top:'31%',
            [theme.breakpoints.down(768)]:{
                left:'39%',
                top:'31%',
           }, 
        },

        '& .Corriente16':{
            left: '44%',
            top: '26%',
            [theme.breakpoints.down(768)]:{
                left: '44.2%',
                top: '26.2%',
           }, 
        },

        '& .Corriente17':{
            left:'40%',
            top:'51%',
            [theme.breakpoints.down(768)]:{
                left:'40.8%',
                top:'51.2%',
           }, 
        },

        '& .Corriente18':{
            left:'48%',
            top:'50%',
            [theme.breakpoints.down(768)]:{
                left:'48.5%',
                top:'50%',
           },  
        },

        '& .Corriente19':{
            left:'57%',
            top:'53%',
            [theme.breakpoints.down(768)]:{
                left:'56.6%',
                top:'52.6%',
            },   
        },

        '& .Corriente20':{
            left:'58%',
            top:'42%',
            [theme.breakpoints.down(768)]:{
                left:'57.2%',
                top:'41.6%',
            }, 
        },

        '& .Corriente21':{
            left:'80%',
            top:'19%',
            [theme.breakpoints.down(768)]:{
                left:'78%',
                top:'19%',
            }, 
        },

        '& .Corriente22':{
            left:'81%',
            top:'30%',
            [theme.breakpoints.down(768)]:{
                left:'78%',
                top:'30%',
            },
        },

        '& .Corriente23':{
            left:'85%',
            top:'52%',
            [theme.breakpoints.down(768)]:{
                left:'82%',
                top:'52%',
            },
        },

        '& .Corriente24':{
            left:'71%',
            top:'54%',
            [theme.breakpoints.down(768)]:{
                left:'69%',
                top:'54%',
            },
        },

        '& .Apagar':{
            display:(props)=> props.Boton1 ? 'none':'flex',
            zIndex:'3'
        },

        '& .Apagar2':{
            display:(props)=> props.Boton2 ? 'none':'flex',
            zIndex:'3'
        },

        '& .Apagar3':{
            display:(props)=> props.Boton3 ? 'none':'flex',
            zIndex:'3'
        },

        '& .Apagar4':{
            display:(props)=> props.Boton4 ? 'none':'flex',
            zIndex:'3'
        },

        '& .Apagar5':{
            display:(props)=> props.Boton5 ? 'none':'flex',
            zIndex:'3'
        },

        '& .Apagar6':{
            display:(props)=> props.Boton6 ? 'none':'flex',
            zIndex:'3'
        },

        '& .Apagar7':{
            display:(props)=> props.Boton7 ? 'none':'flex',
            zIndex:'3'
        },

        '& .Apagar8':{
            display:(props)=> props.Boton8 ? 'none':'flex',
            zIndex:'3'
        },

        '& .Apagar9':{
            display:(props)=> props.Boton9 ? 'none':'flex',
            zIndex:'3'
        },

        '& .Apagar10':{
            display:(props)=> props.Boton10 ? 'none':'flex',
            zIndex:'3'
        },

        '& .Apagar11':{
            display:(props)=> props.Boton11 ? 'none':'flex',
            zIndex:'3'
        },

        '& .Apagar12':{
            display:(props)=> props.Boton12 ? 'none':'flex',
            zIndex:'3'
        },

        '& .Apagar13':{
            display:(props)=> props.Boton13 ? 'none':'flex',
            zIndex:'3'
        },

        '& .Apagar14':{
            display:(props)=> props.Boton14 ? 'none':'flex',
            zIndex:'3'
        },

        '& .Apagar15':{
            display:(props)=> props.Boton15 ? 'none':'flex',
            zIndex:'3'
        },

        '& .Apagar16':{
            display:(props)=> props.Boton16 ? 'none':'flex',
            zIndex:'3'
        },

        '& .Apagar17':{
            display:(props)=> props.Boton17 ? 'none':'flex',
            zIndex:'3'
        },

        '& .Apagar18':{
            display:(props)=> props.Boton18 ? 'none':'flex',
            zIndex:'3'
        },

        '& .Apagar19':{
            display:(props)=> props.Boton19 ? 'none':'flex',
            zIndex:'3'
        },

        '& .Apagar20':{
            display:(props)=> props.Boton20 ? 'none':'flex',
            zIndex:'3'
        },

        '& .Apagar21':{
            display:(props)=> props.Boton21 ? 'none':'flex',
            zIndex:'3'
        },

        '& .Apagar22':{
            display:(props)=> props.Boton22 ? 'none':'flex',
            zIndex:'3'
        },

        '& .Apagar23':{
            display:(props)=> props.Boton23 ? 'none':'flex',
            zIndex:'3'
        },
        
        '& .Apagar24':{
            display:(props)=> props.Boton24 ? 'none':'flex',
            zIndex:'3'
        },

        '& .Corriente-1':{
            left:'23%',
            top:'10%',
            display:(props)=> props.Boton1 ? 'none':'flex',
            [theme.breakpoints.down(768)]:{
                left:'23%',
                top:'10%',
            }, 
        },

        '& .Corriente-2':{
            left:'16%',
            top:'20%',
            display:(props)=> props.Boton2 ? 'none':'flex',
            [theme.breakpoints.down(768)]:{
                left:'16%',
                top:'18%',
            },
        },

        '& .Corriente-2-1':{
            left:'82%',
            top:'26%',
            display:(props)=> props.Boton2 ? 'none':'flex',
            [theme.breakpoints.down(768)]:{
                left:'69%',
                top:'26%',
            },
        },

        '& .Corriente-3':{
            left:'19%',
            top:'32%',
            display:(props)=> props.Boton3 ? 'none':'flex',
        },

        '& .Corriente-4':{
            left:'0%',
            top:'39%',
            display:(props)=> props.Boton4 ? 'none':'flex',
        },

        '& .Corriente-4-1':{
            left:'64%',
            top:'34%',
            display:(props)=> props.Boton4 ? 'none':'flex',
        },
        '& .Corriente-5':{
            left:'25%',
            top:'39%',
            display:(props)=> props.Boton5 ? 'none':'flex',
            [theme.breakpoints.down(768)]:{
                left:'25%',
                top:'42.2%',
            },
        },
        
        '& .Corriente-5-1':{
            left:'72%',
            top:'43%',
            display:(props)=> props.Boton5 ? 'none':'flex',
        },
        '& .Corriente-6':{
            left:'17%',
            top:'49%',
            display:(props)=> props.Boton6 ? 'none':'flex',
        },
        '& .Corriente-6-1':{
            left:'44%',
            top:'34%',
            display:(props)=> props.Boton6 ? 'none':'flex',
        },
        '& .Corriente-6-2':{
            left:'66%',
            top:'48%',
            display:(props)=> props.Boton6 ? 'none':'flex',
        },
        '& .Corriente-7':{
            left:'14%',
            top:'56%',
            display:(props)=> props.Boton7 ? 'none':'flex',
        },
        '& .Corriente-8':{
            left:'13%',
            top:'67%',
            display:(props)=> props.Boton8 ? 'none':'flex',
        },
        '& .Corriente-8-1':{
            left:'68%',
            top:'65%',
            display:(props)=> props.Boton8 ? 'none':'flex',
        },
        '& .Corriente-9':{
            left:'38%',
            top:'2%',
            display:(props)=> props.Boton9 ? 'none':'flex',
        },
        '& .Corriente-10':{
            left:'45%',
            top:'12%',
            display:(props)=> props.Boton10 ? 'none':'flex',
        },
        '& .Corriente-11':{
            left:'24%',
            top:'14.5%',
            display:(props)=> props.Boton11 ? 'none':'flex',
            [theme.breakpoints.down(768)]:{
                left:'28%',
                top:'14.5%',
            },
        },

        '& .Corriente-12':{
            left:'32%',
            top:'25%',
            display:(props)=> props.Boton12 ? 'none':'flex',
            [theme.breakpoints.down(768)]:{
                left:'30%',
                top:'22%',
            },
        },
        '& .Corriente-13':{
            left:'45%',
            top:'17%',
            display:(props)=> props.Boton13 ? 'none':'flex',
        },
        '& .Corriente-14':{
            left:'55%',
            top:'7%',
            display:(props)=> props.Boton14 ? 'none':'flex',
        },
        '& .Corriente-15':{
            left:'40.5%',
            top:'30%',
            display:(props)=> props.Boton15 ? 'none':'flex',
            [theme.breakpoints.down(768)]:{
                left:'40.7%',
                top:'30%',
            },
        },
        '& .Corriente-16':{
            left: '46%',
            top: '24%',
            display:(props)=> props.Boton16 ? 'none':'flex',
        },
        '& .Corriente-17':{
            left:'35%',
            top:'59%',
            display:(props)=> props.Boton17 ? 'none':'flex',
            [theme.breakpoints.down(768)]:{
                left:'35%',
                top:'60%',
            },
            
        },
        '& .Corriente-18':{
            left:'42%',
            top:'54%',
            display:(props)=> props.Boton18 ? 'none':'flex',
            [theme.breakpoints.down(768)]:{
                left:'42%',
                top:'56%',
            },
        },
        '& .Corriente-19':{
            left:'57%',
            top:'57%',
            display:(props)=> props.Boton19 ? 'none':'flex',
            [theme.breakpoints.down(768)]:{
                left:'55%',
                top:'60%',
            },
        },
        '& .Corriente-20':{
            left:'50%',
            top:'45%',
            display:(props)=> props.Boton20 ? 'none':'flex',
            [theme.breakpoints.down(768)]:{
                left:'45%',
                top:'45%',
            },
        },
        '& .Corriente-21':{
            left:'67%',
            top:'18%',
            display:(props)=> props.Boton21 ? 'none':'flex',
            [theme.breakpoints.down(768)]:{
                left:'65%',
                top:'15%',
            },
        },
        '& .Corriente-22':{
            left:'66.5%',
            top:'29%',
            display:(props)=> props.Boton22 ? 'none':'flex',
            [theme.breakpoints.down(768)]:{
                left:'61.8%',
                top:'30%',
            },
        },
        '& .Corriente-23':{
            left:'75%',
            top:'55%',
            display:(props)=> props.Boton23? 'none':'flex',
            [theme.breakpoints.down(768)]:{
                left:'75%',
                top:'56%',
            },
        },
        '& .Corriente-24':{
            left:'71%',
            top:'60%',
            display:(props)=> props.Boton24? 'none':'flex',
            [theme.breakpoints.down(768)]:{
                left:'71%',
                top:'60%',
            },
        },
        
        '& .ContainerUl':{
            position:'absolute',
            width:'992px',
            justifyContent:'space-around',
            [theme.breakpoints.down(1161)]:{
                width:'520px',            },
            [theme.breakpoints.down(768)]:{
                width:'100%',
                justifyContent:'center',

            },
        },
        '& .ul':{
            font:'22px fredoka one',
            letterSpacing: '.21px',
            color: '#F6644B',
            margin:'60px 0 0 63px',
            padding:'0',
            [theme.breakpoints.down(1161)]:{
                font:'17px fredoka one',
                letterSpacing: '.15px',
                margin:'45px 0 0 21px',
            },
             [theme.breakpoints.down(768)]:{
                font:'10px fredoka one',
                lineHeight :'10px',
                letterSpacing: '0.09px',
                margin:'10px 0 0 0',

            },
        },
        '& .ul2':{
            font:'22px fredoka one',
            letterSpacing: '.21px',
            color: '#F6644B',
            margin:'60px 0 0 51px',
            padding:'0',
            [theme.breakpoints.down(1161)]:{
                font:'17px fredoka one',
                letterSpacing: '.15px',
                margin:'45px 0 0 0',
            },
            [theme.breakpoints.down(768)]:{
                font:'10px fredoka one',
                lineHeight :'10px',
                letterSpacing: '0.09px',
                margin: '0 10px 0 0',
            },
        },
        '& .Cuadro':{
            [theme.breakpoints.down(1161)]:{
                margin:'0 0 36px 0',
            },
            [theme.breakpoints.down(768)]:{
                margin:'0 0 0px 0',
            },
        },
        '& .Imagen':{
            [theme.breakpoints.down(768)]:{
                width:'100%'
            },
        },
        '& .Imagen2':{
            [theme.breakpoints.down(768)]:{
                width:'100%',
                height:'455px',
                margin:'0 0 36px 0'
            },
        },
        
    },


}));

const Corrientes_marinas = () => {
    const [Boton1,Btn1] = useState(true);
    const [Boton2,Btn2] = useState(true);
    const [Boton3,Btn3] = useState(true);
    const [Boton4,Btn4] = useState(true);
    const [Boton5,Btn5] = useState(true);
    const [Boton6,Btn6] = useState(true);
    const [Boton7,Btn7] = useState(true);
    const [Boton8,Btn8] = useState(true);
    const [Boton9,Btn9] = useState(true);
    const [Boton10,Btn10] = useState(true);
    const [Boton11,Btn11] = useState(true);
    const [Boton12,Btn12] = useState(true);
    const [Boton13,Btn13] = useState(true);
    const [Boton14,Btn14] = useState(true);
    const [Boton15,Btn15] = useState(true);
    const [Boton16,Btn16] = useState(true);
    const [Boton17,Btn17] = useState(true);
    const [Boton18,Btn18] = useState(true);
    const [Boton19,Btn19] = useState(true);
    const [Boton20,Btn20] = useState(true);
    const [Boton21,Btn21] = useState(true);
    const [Boton22,Btn22] = useState(true);
    const [Boton23,Btn23] = useState(true);
    const [Boton24,Btn24] = useState(true);



    const classes = useStyle({
        Boton1,Boton2,Boton3,Boton4,Boton5,Boton6,
        Boton7,Boton8,Boton9,Boton10,Boton11,Boton12,
        Boton13,Boton14,Boton15,Boton16,Boton17,Boton18,
        Boton19,Boton20,Boton21,Boton22,Boton23,Boton24,
    })
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
             <Grid container item justifyContent="center" className={classes.ContainerInfoLectura}>
               <Grid container className="BarraInfoLectura"></Grid>
               <Grid container item className="InfoLectura">
                    <Grid container item direction="column" xs={6} justifyContent="center">
                        <p className="Dato">Grado</p>
                        <p className="Caracter">5</p>
                    </Grid>
                    <Grid container item direction="column" xs={6} justifyContent="center" className="Sesion">
                        <p className="Dato">Sesión</p>
                        <p className="Caracter">3</p>
                    </Grid>
               </Grid>
            </Grid>
            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                        <h2 className="Titulo">Corrientes marinas</h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <img className="Imagen Escritorio" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion3/1.png" alt="Tarjeta" />
                    <img className="Imagen Tablet" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion3/1R.png" alt="Tarjeta" />

                    <div className="Pos Corriente1" onClick={()=> {Btn1(false); } }></div>
                    <div className="Pos Corriente1 Apagar" onClick={()=> {Btn1(true); } }></div>  
                    <b className="Pos2 Corriente Corriente-1">Corriente de Alaska</b> 

                    <div className="Pos Corriente2" onClick={()=> {Btn2(false); } }></div>
                    <div className="Pos Corriente2 Apagar2" onClick={()=> {Btn2(true); } }></div>  
                    <b className="Pos2 Corriente Corriente-2"> Corriente del Pacífico Norte</b>
                    
                    <div className="Pos Corriente2-1" onClick={()=> {Btn2(false); } }></div>
                    <div className="Pos Corriente2-1 Apagar2" onClick={()=> {Btn2(true); } }></div>  
                    <b className="Pos2 Corriente Corriente-2-1"> Corriente del Pacífico Norte</b>

                    <div className="Pos Corriente3" onClick={()=> {Btn3(false); } }></div> 
                    <div className="Pos Corriente3 Apagar3" onClick={()=> {Btn3(true); } }></div> 
                    <b className="Pos2 Corriente Corriente-3"> Corriente de California</b>

                    <div className="Pos Corriente4" onClick={()=> {Btn4(false); } }></div> 
                    <div className="Pos Corriente4 Apagar4" onClick={()=> {Btn4(true); } }></div> 
                    <b className="Pos2 Corriente Corriente-4"> Corriente Ecuatorial del Norte</b>

                    <div className="Pos Corriente4-1" onClick={()=> {Btn4(false); } }></div> 
                    <div className="Pos Corriente4-1 Apagar4" onClick={()=> {Btn4(true); } }></div>     
                    <b className="Pos2 Corriente Corriente-4-1"> Corriente Ecuatorial del Norte</b>

                    <div className="Pos Corriente4-2" onClick={()=> {Btn4(false); } }></div> 
                    <div className="Pos Corriente4-2 Apagar4" onClick={()=> {Btn4(true); } }></div>    

                    <div className="Pos Corriente5" onClick={()=> {Btn5(false); } }></div> 
                    <div className="Pos Corriente5 Apagar5" onClick={()=> {Btn5(true); } }></div>     
                    <b className="Pos2 Corriente Corriente-5">Contracorriente Ecuatorial</b>

                    <div className="Pos Corriente5-1" onClick={()=> {Btn5(false); } }></div> 
                    <div className="Pos Corriente5-1 Apagar5" onClick={()=> {Btn5(true); } }></div>   
                    <b className="Pos2 Corriente Corriente-5-1">Contracorriente Ecuatorial</b>

                    <div className="Pos Corriente5-2" onClick={()=> {Btn5(false); } }></div> 
                    <div className="Pos Corriente5-2 Apagar5" onClick={()=> {Btn5(true); } }></div>   

                    <div className="Pos Corriente6" onClick={()=> {Btn6(false); } }></div> 
                    <div className="Pos Corriente6 Apagar6" onClick={()=> {Btn6(true); } }></div> 
                    <b className="Pos2 Corriente Corriente-6"> Corriente Ecuatorial del Sur</b>

                    <div className="Pos Corriente6-1" onClick={()=> {Btn6(false); } }></div> 
                    <div className="Pos Corriente6-1 Apagar6" onClick={()=> {Btn6(true); } }></div>   
                    <b className="Pos2 Corriente Corriente-6-1"> Corriente Ecuatorial del Sur</b>

                    <div className="Pos Corriente6-2" onClick={()=> {Btn6(false); } }></div> 
                    <div className="Pos Corriente6-2 Apagar6" onClick={()=> {Btn6(true); } }></div> 
                    <b className="Pos2 Corriente Corriente-6-2"> Corriente Ecuatorial del Sur</b>

                    <div className="Pos Corriente7" onClick={()=> {Btn7(false); } }></div> 
                    <div className="Pos Corriente7 Apagar7" onClick={()=> {Btn7(true); } }></div>  
                    <b className="Pos2 Corriente Corriente-7"> Corriente de Humboldt</b>

                    <div className="Pos Corriente8" onClick={()=> {Btn8(false); } }></div> 
                    <div className="Pos Corriente8 Apagar8" onClick={()=> {Btn8(true); } }></div> 
                    <b className="Pos2 Corriente Corriente-8"> Corriente Circunpolar Antártica</b>

                    <div className="Pos Corriente8-1" onClick={()=> {Btn8(false); } }></div> 
                    <div className="Pos Corriente8-1 Apagar8" onClick={()=> {Btn8(true); } }></div> 
                    <b className="Pos2 Corriente Corriente-8-1"> Corriente Circunpolar Antártica</b>

                    <div className="Pos Corriente9" onClick={()=> {Btn9(false); } }></div> 
                    <div className="Pos Corriente9 Apagar9" onClick={()=> {Btn9(true); } }></div> 
                    <b className="Pos2 Corriente Corriente-9"> Corriente de Groelandia Occidental</b>
                   
                    <div className="Pos Corriente10" onClick={()=> {Btn10(false); } }></div> 
                    <div className="Pos Corriente10 Apagar10" onClick={()=> {Btn10(true); } }></div> 
                    <b className="Pos2 Corriente Corriente-10"> Corriente de Groenlandia Oriental</b>
                   
                    <div className="Pos Corriente11" onClick={()=> {Btn11(false); } }></div> 
                    <div className="Pos Corriente11 Apagar11" onClick={()=> {Btn11(true); } }></div> 
                    <b className="Pos2 Corriente Corriente-11"> Corriente de Labrador</b>
                   
                    <div className="Pos Corriente12" onClick={()=> {Btn12(false); } }></div> 
                    <div className="Pos Corriente12 Apagar12" onClick={()=> {Btn12(true); } }></div> 
                    <b className="Pos2 Corriente Corriente-12"> Corriente del Golfo</b>
                    
                     <div className="Pos Corriente13" onClick={()=> {Btn13(false); } }></div> 
                    <div className="Pos Corriente13 Apagar13" onClick={()=> {Btn13(true); } }></div> 
                    <b className="Pos2 Corriente Corriente-13"> Corriente del Atlántico Norte</b>
                   
                    <div className="Pos Corriente14" onClick={()=> {Btn14(false); } }></div> 
                    <div className="Pos Corriente14 Apagar14" onClick={()=> {Btn14(true); } }></div> 
                    <b className="Pos2 Corriente Corriente-14"> Corriente de Noruega</b>
                   
                    <div className="Pos Corriente15" onClick={()=> {Btn15(false); } }></div> 
                    <div className="Pos Corriente15 Apagar15" onClick={()=> {Btn15(true); } }></div> 
                    <b className="Pos2 Corriente Corriente-15"> Corriente Ecuatorial del Norte</b>
                    
                     <div className="Pos Corriente16" onClick={()=> {Btn16(false); } }></div> 
                    <div className="Pos Corriente16 Apagar16" onClick={()=> {Btn16(true); } }></div> 
                    <b className="Pos2 Corriente Corriente-16"> Corriente de las Canarias</b>
                   
                    <div className="Pos Corriente17" onClick={()=> {Btn17(false); } }></div> 
                    <div className="Pos Corriente17 Apagar17" onClick={()=> {Btn17(true); } }></div> 
                    <b className="Pos2 Corriente Corriente-17"> Corriente de Brasil</b>
                    
                     <div className="Pos Corriente18" onClick={()=> {Btn18(false); } }></div> 
                    <div className="Pos Corriente18 Apagar18" onClick={()=> {Btn18(true); } }></div> 
                    <b className="Pos2 Corriente Corriente-18"> Corriente de Benguela</b>
                   
                    <div className="Pos Corriente19" onClick={()=> {Btn19(false); } }></div> 
                    <div className="Pos Corriente19 Apagar19" onClick={()=> {Btn19(true); } }></div> 
                    <b className="Pos2 Corriente Corriente-19"> Corriente de Agujas </b>
                   
                    <div className="Pos Corriente20" onClick={()=> {Btn20(false); } }></div> 
                    <div className="Pos Corriente20 Apagar20" onClick={()=> {Btn20(true); } }></div> 
                    <b className="Pos2 Corriente Corriente-20">Corriente de Mozambique</b>
                    
                     <div className="Pos Corriente21" onClick={()=> {Btn21(false); } }></div> 
                    <div className="Pos Corriente21 Apagar21" onClick={()=> {Btn21(true); } }></div> 
                    <b className="Pos2 Corriente Corriente-21"> Corriente de Oyashio</b>
                   
                    <div className="Pos Corriente22" onClick={()=> {Btn22(false); } }></div> 
                    <div className="Pos Corriente22 Apagar22" onClick={()=> {Btn22(true); } }></div> 
                    <b className="Pos2 Corriente Corriente-22"> Corriente de Kuroshio</b>
                   
                    <div className="Pos Corriente23" onClick={()=> {Btn23(false); } }></div> 
                    <div className="Pos Corriente23 Apagar23" onClick={()=> {Btn23(true); } }></div> 
                    <b className="Pos2 Corriente Corriente-23"> Corriente de Australia Oriental</b>
                   
                    <div className="Pos Corriente24" onClick={()=> {Btn24(false); } }></div> 
                    <div className="Pos Corriente24 Apagar24" onClick={()=> {Btn24(true); } }></div> 
                    <b className="Pos2 Corriente Corriente-24"> Corriente de Australia Occidental</b>


                </Grid>
                
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <img className="Imagen Escritorio" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion3/2.png" alt="Tarjeta" />
                    <img className="Imagen2 Tablet Cuadro" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion3/2R.png" alt="Tarjeta" />
                    <Grid container className='ContainerUl' >
                        <ol type="d" start="1" className='ul'>
                            <li><b className='Lectura'>Corriente de Alaska</b> </li>
                            <li><b className='Lectura'>Corriente del Pacífico Norte</b></li>
                            <li><b className='Lectura'>Corriente de California</b></li>
                            <li><b className='Lectura'>Corriente Ecuatorial del Norte</b></li>
                            <li><b className='Lectura'>Contracorriente Ecuatorial</b></li>
                            <li><b className='Lectura'>Corriente Ecuatorial del Sur</b></li>
                            <li><b className='Lectura'>Corriente de Humboldt</b></li>
                            <li><b className='Lectura'>Corriente Circunpolar Antártica</b></li>
                            <li><b className='Lectura'>Corriente de Groelandia Occidental</b></li>
                            <li><b className='Lectura'>Corriente de Groenlandia Oriental</b></li>
                            <li><b className='Lectura'>Corriente de Labrador</b></li>
                            <li><b className='Lectura'>Corriente del Golfo</b></li>
                        </ol>
                        <ol type="d" start="13" className='ul2'>
                            <li><b className='Lectura'>Corriente del Atlántico Norte</b></li>
                            <li><b className='Lectura'>Corriente de Noruega</b></li>
                            <li><b className='Lectura'>Corriente Ecuatorial del Norte</b></li>
                            <li><b className='Lectura'>Corriente de las Canarias</b></li>
                            <li><b className='Lectura'>Corriente de Brasil</b></li>
                            <li><b className='Lectura'>Corriente de Benguela</b></li>
                            <li><b className='Lectura'>Corriente de Agujas </b></li>
                            <li><b className='Lectura'>Corriente de Mozambique</b></li>
                            <li><b className='Lectura'>Corriente de Oyashio</b></li>
                            <li><b className='Lectura'>Corriente de Kuroshio</b></li>
                            <li><b className='Lectura'>Corriente de Australia Oriental</b></li>
                            <li><b className='Lectura'>Corriente de Australia Occidental</b></li>
                        </ol>
                    
                    </Grid>
                    
                </Grid>
                
            <Grid container item xs={12} justifyContent="center" className="ContainerDerechos">
                <p className="Derechos">
                    © 2022. Todos los derechos reservados, Pearson, Circuito de lectores.
                </p>                
            </Grid>
        </Grid>
    </Grid>
    );
}
 
export default Corrientes_marinas;
