import { useState } from "react";
import { useCallback } from "react";

const useModalMessage = () => {
  const [modalMessageOpen, setModalMessageOpen] = useState(false);
  const [modalMessageText, setModalMessageText] = useState("");

  const handleModalMessage = useCallback(
    (config) => {
      if (config?.habilite === true) {
        setModalMessageOpen(true);
        setModalMessageText(
          config?.mensaje ||
            "Ha ocurrido un error al consumir los servicios, inténtelo de nuevo más tarde."
        );
      } else if (config?.habilite === false) {
        setModalMessageOpen(false);
      } else {
        setModalMessageOpen(false);
      }
    },
    []
  );

  const closeModalMessage = () => {
    setModalMessageOpen(false);
  };

  const clearTextModalMessage = useCallback(() => {
    setModalMessageText("");
  }, []);

  return {
    modalMessageOpen,
    modalMessageText,
    handleModalMessage,
    clearTextModalMessage,
    closeModalMessage,
  };
};

export default useModalMessage;
