import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
    ContenedorLectura:{
        width:'502px',
                  [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
    },
    ContainerTitulo:{
        margin:'0 0 34px 0',
        '& .Titulo':{
            letterSpacing: '0px',
            color: '#EEA90A',
            margin:'0',
            textAlign:'center',
            font:'43px fredoka one',
            lineHeight:'52px',
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
        '& .cel':{
            display:'none',
            [theme.breakpoints.down(502)]:{
                display:'block'
            },
          }
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            margin:'0 0 36px 0',
            color:'#000',
            font:'18px lato',
            lineHeight :'28px',
            letterSpacing: '0.17px',
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .SubTitulo':{
            color:'#00ADB6',
            margin:'0',
            lineHeight :'28px',
            font:'bold 26px lato',
            letterSpacing: '0.24px',
            [theme.breakpoints.down(502)]:{
                font:'bold 18px lato',
                lineHeight :'24px',
                letterSpacing: '0.21px',
            },
        },
        '& .SubTitulo2':{
            color:'#0CB5D6',
            margin:'0',
            font:'bold 22px lato',
            lineHeight :'24px',
            letterSpacing: '0.21px',
            [theme.breakpoints.down(502)]:{
                font:'bold 18px lato',
                lineHeight :'20px',
                letterSpacing: '0.16px',
            },
        },

        '& .Imagen':{
            width:'100%',
            height:'392px',
            [theme.breakpoints.down(502)]:{
                width:'100%',
                height:'250px',
            },
        },  
      
    }
}));

const El_valor_del_respeto = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                        <h2 className="Titulo">El valor <br className='cel'/>del respeto</h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12} justifyContent='center'>
                       <p className='Lectura'>
                        <h3 className='SubTitulo'>Se promoverá en las escuelas una campaña de respeto</h3><br/>
                        
                        Esta semana se hizo pública la nueva campaña sobre el respeto, la cual será implementada 
                        por las autoridades escolares en todas las escuelas del país a partir del próximo mes.
                        <br/><br/>

                        Esta nueva campaña tiene como objetivo enseñar a los estudiantes en qué consiste el 
                        respeto y la importancia que tiene practicar este valor en la vida diaria.<br/><br/>

                        <h3 className='SubTitulo'>La campaña</h3><br/>

                        Los alumnos aprenderán la utilidad de los valores para lograr que nuestra 
                        convivencia con los demás sea sana y beneficiosa para todos.<br/><br/>

                        A través de actividades, reflexionarán qué implica ser respetuoso con las 
                        personas a nuestro alrededor. Algunos ejemplos son escuchar a los demás con 
                        atención cuando hablan, tratar con cuidado las cosas que nos prestan y pedir
                        disculpas cuando cometemos un error o lastimamos a alguien.<br/><br/>

                        El taller busca que maestros y alumnos puedan practicar el respeto dentro y 
                        fuera del salón de clase, así como con sus familias y amistades. Una de las 
                        actividades se centrará en trabajar el respeto a las opiniones que las demás
                        personas puedan tener.

                       </p>                   
                    </Grid>
                    <Grid container justifyContent='center'>
                        <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img1ro/Sesion38/1.png" alt="Tarjeta" />
                    </Grid>
                </Grid>
            </Grid>
    </Grid>
    );
}
 
export default El_valor_del_respeto;
