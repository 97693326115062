import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";

import { GridCloseIcon } from "@mui/x-data-grid";
import { Grid, makeStyles } from "@material-ui/core";
import axios from "../../services/axios";
import ModalResponse from "./ModalResponse";
import Loader from "../Loader/Loader";
import AuthContext from "../../auth/AuthContext";
import ModalMessage from "./ModalMessage";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    // padding: theme.spacing(2),

    [theme.breakpoints.up("sm")]: {
      // margin: "20px 0",
      paddingTop: "0",
      paddingBottom: "0",
    },
  },
  "& .MuiDialog-paper": {
    borderRadius: "12.1px",
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
    display: "flex",
    justifyContent: "center",
    gap: "5%",
    marginBottom: "16px",
  },
  "& .MuiDialogTitle-root": {
    [theme.breakpoints.up("sm")]: {
      // margin: "20px 0",
      padding: "22px",
    },
  },
}));

const useStyles = makeStyles((theme) => ({
  containerContent: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    padding: "20px 0",
    boxSizing: "border-box",
    [theme.breakpoints.up("sm")]: {
      // margin: "20px 0",
      minWidth: "480px",
    },
    [theme.breakpoints.up("md")]: {
      // margin: "20px 0",
      minWidth: "720px",
    },
  },
  code: {
    fontFamily: "Fredoka One",
    margin: " 0 20px",
    // fontFamily: "Fredoka One",
    fontSize: "20px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "0.23px",
    textAlign: "center",
    color: "#9051b1",

    [theme.breakpoints.up("lg")]: {
      fontSize: "32px",
      margin: " 0 30px",
    },
  },
  containerButtons: {
    display: "flex",
    justifyContent: "center",
  },
  button: {
    backgroundColor: "#B965D3",
    borderRadius: "4px",
    boxSizing: "borderBox",
    outline: "0",
    border: "0",
  },
  title: {
    fontFamily: "Fredoka One",
    margin: 0,
    fontSize: "24px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "0.23px",
    textAlign: "center",
    marginBottom: "20px",
    color: "#DC5EA6",
    [theme.breakpoints.up("lg")]: {
      fontSize: "32px",
      // margin: "10px",
    },
  },
  text: {
    fontFamily: "Lato",
    margin: "0",
    fontSize: "16px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "0.23px",
    textAlign: "center",
    color: "#000",
    [theme.breakpoints.up("lg")]: {
      fontSize: "22px",
      margin: "10px",
    },
  },
  formContainer: {
    // display: "flex",
    display: "grid",
    // flexDirection: "column"
    gridTemplateColumns: "1fr",
    width: "80%",
    boxSizing: "border-box",
    maxWidth: "560px",
    alignItems: "flex-start",
    justifyItems: "center",
    gap: "30px",

    [theme.breakpoints.up("lg")]: {
      width: "100%",
      maxWidth: "540px",
      alignItems: "end",
      gridTemplateColumns: "1fr",
    },
  },
  formItemContainer: {
    gap: "10px",
  },
  label: {
    fontWeight: "bold",
    fontFamily: "Lato, sans-serif",
  },
  input: {
    width: "100%",
    height: "40px",
    paddingLeft: "27px",
    paddingRight: "27px",
    borderRadius: "61px",
    boxSizing: "border-box",
    // border: " solid #1D2AAD 3px ",
    border: "none",
    background: "#FFFFFF",
    font: "Helvetica",
    fontSize: "16px",
    //    width: '449px',
    // height: '72px',
    // margin: '43px 139px 19px 66px',
    // padding: '26px 24px 25px',
    backgroundColor: "#f0f0f0",
    "&:focus": {
      outline: "none",
    },
    [theme.breakpoints.up("lg")]: {
      // height: "60px",
      // maxWidth: "449px",
      fontSize: "18px",
    },
  },
}));

const PurpleButton = styled(Button)(({ theme }) => ({
  color: "#FFFFFF",
  backgroundColor: "#9051b1",
  borderRadius: "30px",
  // margin: "0 auto",
  width: "50%",
  textTransform: "unset",
  maxWidth: "130px",
  "&:hover": {
    backgroundColor: "#B965D3 ",
  },
}));
const WitheButton = styled(Button)(({ theme }) => ({
  color: "#9051b1",
  // borderColor: "#9051b1",
  border: "1px solid #9051b1",
  backgroundColor: "#FFFFFF",
  textTransform: "unset",
  borderRadius: "30px",
  // margin: "0 auto",
  width: "50%",
  maxWidth: "130px",
  "&:hover": {
    backgroundColor: "#B965D310 ",
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 4 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <GridCloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const ModalPasswordChange = ({ open, handleClose, handleConfirm }) => {
  const classes = useStyles();
  const { user } = useContext(AuthContext);
  const [formData, setFormData] = useState({});
  const [responseModalOpen, setResponseModalOpen] = useState(false);
  const [errorModalOpen, setErrorModalOpen] = useState(false);
  const [responseData, setResponseData] = useState({});
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const handleForm = (e) => {
    setFormData({
      ...formData,
      [e.target.name.toLowerCase()]: e.target.value,
    });
  };

  useEffect(() => {
    return () => {
      setFormData({
        new_password: "",
        old_password: "",
        confirm_password: "",
      });
    };
  }, []);

  const handleResponseModalOpen = () => {
    setResponseModalOpen(!responseModalOpen);
    if (responseData?.status === "ok") {
      handleClose();
    }
  };

  const changePassword = () => {
    const body = {
      contraseñaActual: formData?.old_password,
      contraseñaNueva: formData?.new_password,
      id_usuario: user.userId,
    };
    // let verificatePassword = checkPassword(formData?.new_password);
    if (
      formData?.new_password &&
      formData.confirm_password &&
      formData?.old_password
    ) {
      if (formData?.new_password === formData?.confirm_password) {
        setLoading(true);

        axios
          .put("/listado/contrasena", body)
          .then((response) => {
            if (response.status === 200) {
              setResponseModalOpen(true);
              setFormData({
                new_password: "",
                old_password: "",
                confirm_password: "",
              });
              setResponseData({
                status: "ok",
                error: null,
              });
            }
          })
          .catch((error) => {
            if (error.response) {
              // Request made and server responded
              const errorData = error.response.data;
              const mensaje =
                errorData?.detalles?.msg ||
                "Ha ocurrido un error al consumir los servicios, inténtelo de nuevo más tarde.";
              setResponseData({
                status: "error",
                error: mensaje,
              });
            } else {
              // Something happened in setting up the request that triggered an Error
              console.log("Error", error.message);
            }

            setResponseModalOpen(true);
          });
        setLoading(false);
      } else {
        setError("Las contraseñas no coinciden, inténtalo de nuevo");
        setErrorModalOpen(true);
      }
    } else {
      setError("Asegúrese de llenar todos los campos");
      setErrorModalOpen(true);
    }
  };

  return (
    <>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        maxWidth="md"
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        ></BootstrapDialogTitle>
        <DialogContent>
          <div className={classes.containerContent}>
            <h3 className={classes.title}>Cambiar contraseña</h3>
            <form
              className={classes.formContainer}
              onSubmit={(e) => {
                e.preventDefault();
              }}
            >
              <Grid
                container
                direction="column"
                className={classes.formItemContainer}
              >
                <label htmlFor="input-old-password'" className={classes.label}>
                  Contraseña actual
                </label>
                <input
                  type="password"
                  id="input-old-password"
                  name="old_password"
                  className={classes.input}
                  onChange={handleForm}
                  value={formData?.old_password}
                  autoComplete={false}
                  // disabled={passwordHasChanged}
                />
              </Grid>
              <Grid
                container
                direction="column"
                className={classes.formItemContainer}
              >
                <label htmlFor="input-new-password'" className={classes.label}>
                  Nueva contraseña
                </label>
                <input
                  type="password"
                  name="new_password"
                  id="input-new-password"
                  className={classes.input}
                  onChange={handleForm}
                  value={formData?.new_password}
                  autoComplete={false}
                />
              </Grid>
              <Grid
                container
                direction="column"
                className={classes.formItemContainer}
              >
                <label
                  htmlFor="input-confirm-new-password'"
                  className={classes.label}
                >
                  Confirmar nueva contraseña
                </label>
                <input
                  type="password"
                  id="input-confirm-new-password"
                  name="confirm_password"
                  className={classes.input}
                  onChange={handleForm}
                  value={formData?.confirm_password}
                />
              </Grid>
            </form>
          </div>
          <ModalResponse
            open={responseModalOpen}
            handleClose={handleResponseModalOpen}
            responseData={responseData}
            title={{
              ok: "Tu contraseña se ha actualizado correctamente",
              warningMessage: "Ha ocurrido un error inesperado",
            }}
            paragraph={{
              ok: "Usa tu nueva contraseña para entrar a tu cuenta.",
            }}
          />
          {loading && <Loader />}
          <ModalMessage
            open={errorModalOpen}
            handleClose={() => {
              setErrorModalOpen(!errorModalOpen);
            }}
            title={error}
          />
        </DialogContent>
        <DialogActions
          disableSpacing={true}
          className={classes.containerButtons}
        >
          <WitheButton
            className={classes.button}
            variant="contained"
            onClick={handleClose}
          >
            Cancelar
          </WitheButton>
          <PurpleButton
            onClick={changePassword}
            className={classes.button}
            variant="contained"
          >
            Confirmar
          </PurpleButton>
        </DialogActions>
      </BootstrapDialog>
    </>
  );
};
export default ModalPasswordChange;
