import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
     Contenedor:{
        background:'#E9FBF9',
        width:'100vw',
        overflowX:'clip',
        [theme.breakpoints.down(1161)]:{
            background:'none',
           overflowX:'unset',
        },
        [theme.breakpoints.down(600)]:{
            marginTop: '79.51px',
        }
    },
  ContainerInfoLectura: {
    position: "relative",
    height: "86px",
    [theme.breakpoints.down(502)]:{
        height: "43px",
    },
    background: "#76D4C3",
    "& .BarraInfoLectura": {
      height: "86px",
      background: `Url(https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Barras/Azul.png)`,
        [theme.breakpoints.down(502)]:{
            height: "43px",
        },
    },
    "& .InfoLectura": {
      position: "absolute",
      height: "86px",
      width: "219px",
      boxSizing: "border-box",
      padding: "17.2px 0",
      background: "#FAFAFA",
      borderRadius: "50px",
      boxShadow: "2px 2px 2px 2px #00000029",
      zIndex: "4",
        [theme.breakpoints.down(502)]:{
            height: "43px",
            width: "109px",
            padding: "8.6px 0",
            borderRadius: "25px",
        },
    },
    "& .InfoLectura:before": {
      position: "absolute",
      display: "flex",
      boxSizing: "border-box",
      padding: "0 0 0 10px",
      justifyContent: "center",
      alignItems: "center",
      height: "39.88px",
      width: "39.88px",
      borderRadius: "50%",
      content: '""',
      boxShadow: "3px 0px .5px 0px #00000029",
      background: "#FAFAFA",
      left: "90px",
      top: "65px",
      transform: "rotate(0.25turn)",
      [theme.breakpoints.down(502)]:{
        left: "45px",
        top: "30px",
        height: "20.88px",
        width: "20.88px",
        },
    },
    "& .InfoLectura:after": {
      content: '""',
      height: "15px",
      width: "15px",
      borderTop: "2px solid #20A698",
      borderLeft: "2px solid #20A698",
      position: "absolute",
      bottom: "-10px",
      left: "Calc(50% - 8px)",
      transform: "rotate(0.625turn)",
      [theme.breakpoints.down(502)]:{
        height: "10px",
        width: "10px",
        left: "Calc(50% - 4.5px)",
        bottom: "-3px",
        borderTop: "1px solid #20A698",
        borderLeft: "1px solid #20A698",
        },
    },
    "& .Sesion": {
      borderLeft: "2px solid #D8D8D8",
    },
    "& .Dato": {
      margin: "0 0 5px 0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#20A698",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        margin: "0 0 2.5px 0",
        },
    },
    "& .Caracter": {
      margin: "0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#000000",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        },
    },   },
    ContenedorLectura:{
        background:'#fff',
        width:'1160px',
        height:'100%',
        padding:'60px 168px 35px ',
        [theme.breakpoints.down(1161)]:{
            width:'502px',
            padding:'60px 0 0 0',
          },
        [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
        '& .ContainerDerechos':{
            bottom:'80px',
            [theme.breakpoints.down(1161)]:{
                display:'none',
            },
        },
        '& .Derechos':{
            textAlign:"center",
            margin:"0px",
            font:" 15px lato",
            color:"#717476",
        },
        '& .Escritorio':{
            display:'block',
            [theme.breakpoints.down(1161)]:{
                display:'none'
            },
        },
        '& .Tablet':{
            display:'none',
            [theme.breakpoints.down(1161)]:{
                display:'block'
            },
        }
    },
    ContainerTitulo:{
        margin:'0 0 34px 0',
        [theme.breakpoints.down(1161)]:{
            margin:'0 0 34px 0',
        },
        '& .Titulo':{
            font:'48px fredoka one',
            letterSpacing: '0px',
            lineHeight:'58px',
            color: '#F6644B',
            margin:'0',
            textAlign:'center',
            [theme.breakpoints.down(1161)]:{
                font:'43px fredoka one',
                lineHeight:'52px',
            },
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            lineHeight : '30px',
            margin:'0 0 36px 0',
            font:'22px lato',
            color:'#000',
            letterSpacing: '0.20px',
            [theme.breakpoints.down(1161)]:{
                font:'18px lato',
                lineHeight :'28px',
                letterSpacing: '0.17px',
            },
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21.5px',
                letterSpacing: '0.10px',
            },
            [theme.breakpoints.down(350)]:{
                font:'14px lato',
                lineHeight :'18px',
                letterSpacing: '0.09px',
            },
        },
        '& .SubTitulo':{
            font:'bold 30px lato',
            color:'#CD1B20',
            letterSpacing:'.28px',
            lineHeight:'30px',
            margin:'0',
            [theme.breakpoints.down(1161)]:{
                lineHeight :'28px',
                font:'bold 26px lato',
                letterSpacing: '0.24px',
            },
            [theme.breakpoints.down(502)]:{
                font:'bold 18px lato',
                lineHeight :'24px',
                letterSpacing: '0.21px',
            },
        },
        '& .SubTitulo2':{
            font:'bold 25px lato',
            color:'#FFFFFF',
            letterSpacing:'.24px',
            lineHeight:'28px',
            margin:'0',
            width:'768px',
            top:'27px',
            left:'28px',
            [theme.breakpoints.down(1161)]:{
                font:'bold 20px lato',
                lineHeight :'24px',
                letterSpacing: '0.21px',
            },
            [theme.breakpoints.down(502)]:{
                font:'bold 16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'bold 12px lato',
                lineHeight :'18px',
                letterSpacing: '0.09px',
            },
        },

        '& .TextImg':{
            font:'20px lato',
            color:'#717476',
            letterSpacing:'.19px',
            lineHeight:'30px',
            margin:'24px 0 0 0',
            [theme.breakpoints.down(1161)]:{
                font:' 16px lato',
                lineHeight :'25px',
                letterSpacing: '0.15px',
            },
            [theme.breakpoints.down(502)]:{
                font:' 11px lato',
                lineHeight :'14px',
                letterSpacing: '0.10px',
            },
        },
        '& .ul':{
            color:'#F6644B',
            margin:'0 0 36px 0',
            padding:'0 0 0 15px'
        },
        '& .Lectura2':{
            margin:'0',
            [theme.breakpoints.down(502)]:{
                lineHeight :'16px!important',
            },
            [theme.breakpoints.down(350)]:{
                lineHeight :'14px!important',
            },
        },
        '& .li':{
            marginBottom:'8',
        },
        '& .Pos':{
            position:'absolute'
        },
        '& .Cuadro':{
            position:'relative'
        },
        '& .Punto1':{
            left:'96px',
            top:'144px',
            width:'675px',
            [theme.breakpoints.down(1161)]:{
                width:'68.12%',
                top:'13%',
                [theme.breakpoints.down(502)]:{
                    left:'65px',
                    top:'12.5%',
                },
                [theme.breakpoints.down(350)]:{
                    left:'50px',
                    top:'12.5%',
                },
                
            },
        },
        '& .Punto2':{
            left:'96px',
            top:'258px',
            width:'676px',
            [theme.breakpoints.down(1161)]:{
                width:'66.55%',
                top:'25.26%',
                [theme.breakpoints.down(502)]:{
                    left:'65px',
                    top:'24%',
                },
                [theme.breakpoints.down(350)]:{
                    left:'50px',
                    top:'25.5%',
                },
            },
        },
        '& .Punto3':{
            left:'96px',
            top:'368px',
            width:'685px',
            [theme.breakpoints.down(1161)]:{
                width:'72.90%',
                top:'39%',
            },
            [theme.breakpoints.down(502)]:{
                left:'65px',
                top:'38.5%',
            },
            [theme.breakpoints.down(350)]:{
                left:'50px',
                top:'39.5%',
            },
        },
        '& .Punto4':{
            left:'96px',
            top:'538px',
            width:'685px',
            [theme.breakpoints.down(1161)]:{
                width:'65.33%',
                top:'64%',
            },
            [theme.breakpoints.down(502)]:{
                top:'62.5%',
                left:'65px'
            },
            [theme.breakpoints.down(350)]:{
                top:'63.5%',
                left:'50px'
            },
        },
        '& .Punto5':{
            left:'96px',
            top:'712px',
            width:'676px',
            [theme.breakpoints.down(1161)]:{
                width:'68.12%',
                top:'87.5%',
            },
            [theme.breakpoints.down(502)]:{
                top:'87%',
                left:'65px'
            },
            [theme.breakpoints.down(350)]:{
                top:'87%',
                left:'50px'
            },
        },
        '& .Imagen':{
            [theme.breakpoints.down(1161)]:{
                width:'100%',
            },
        },  
        '& .Imagen2':{
            [theme.breakpoints.down(1161)]:{
                width:'83.3%',
            },
        },  
      
      
    }
}));

const Los_derechos_lingüísticos = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
             <Grid container item justifyContent="center" className={classes.ContainerInfoLectura}>
               <Grid container className="BarraInfoLectura"></Grid>
               <Grid container item className="InfoLectura">
                    <Grid container item direction="column" xs={6} justifyContent="center">
                        <p className="Dato">Grado</p>
                        <p className="Caracter">5</p>
                    </Grid>
                    <Grid container item direction="column" xs={6} justifyContent="center" className="Sesion">
                        <p className="Dato">Sesión</p>
                        <p className="Caracter">15</p>
                    </Grid>
               </Grid>
            </Grid>

            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                        <h2 className="Titulo">Los derechos lingüísticos</h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                       <p className='Lectura'>
                        América tiene entre 40 y 60 millones de habitantes indígenas, 522 pueblos 
                        originarios y 420 lenguas originarias. Por ejemplo, se habla zapoteco y 
                        tzeltal en México, iquito en Perú y mapuzugun en Chile. Todas se consideran 
                        <em> minoría</em> frente al español. A veces se utilizan en lugares donde el español 
                        es la lengua con más hablantes. En otras ocasiones toda la población habla 
                        una lengua originaria, pero está rodeada por otras que hablan español.
                       </p>                   
                    </Grid>
                    <Grid container justifyContent='center'>
                        <img className="Imagen2" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion15/1.png" alt="Tarjeta" />
                        <Grid container >
                            <p className="TextImg">
                                ¿Conoces el mapuzugun, la lengua del pueblo mapuche? ¡Te invitamos a investigar sobre ella!
                            </p>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container >
                        <p className='Lectura'>
                            De estas lenguas, 248 están en peligro de desaparecer, según la
                            Organización de las Naciones Unidas para la Educación, la Ciencia y la 
                            Cultura (<span style={{fontVariant: "all-small-caps"}}>UNESCO</span>). Los países con más lenguas en riego son 
                            Brasil, Perú, Colombia, México y Bolivia. <br/><br/>
                             
                            <h3 className='SubTitulo'>¿Por qué desaparece una lengua?</h3><br/>
                            
                            Piensa en una persona que durante toda su juventud fue criticada y excluida por 
                            hablar su Lengua materna en público. Con el tiempo, la va usando menos, hasta
                            que un día, cuando tiene un bebé, lo toma en sus brazos y le dice: “Todo va 
                            a ser más fácil para ti si te hablo sólo en español”.<br/><br/>
                             
                            Cuando un hablante toma esta decisión quien más pierde es la sociedad que lo orilla 
                            a tomarla, porque ahora tiene un patrimonio cultural menos rico e incluyente. <br/><br/>
                            
                            <h3 className='SubTitulo'>La lengua es un derecho</h3><br/>
                            
                            Para conservar las lenguas como patrimonio y proteger a los hablantes como 
                            personas surgieron los <em>derechos lingüísticos</em>. Todo hablante de una lengua 
                            minoritaria tiene derecho a:
                        </p>

                        <ul className='ul'>
                            <li className='li'>
                            <p className='Lectura Lectura2'>Vivir sin discriminación</p>
                            </li>
                            <li className='li'>
                            <p className='Lectura Lectura2'>Recibir educación</p>    
                            </li>
                            <li className='li'>
                            <p className='Lectura Lectura2'>Gozar de privacidad</p>    
                            </li>
                            <li className='li'>
                            <p className='Lectura Lectura2'>Utilizar su propio idioma para comunicarse con otras personas de su grupo</p>    
                            </li>
                        </ul>
                        <p className='Lectura'>
                        Éstos son derechos humanos y las personas los poseen sin importar su nacionalidad. Los derechos 
                        humanos fueron establecidos en la Organización de las Naciones Unidas (<span style={{fontVariant: "all-small-caps"}}>ONU</span>) en 1948. Algunas asambleas
                        internacionales discuten qué debe considerarse un derecho humano. 
                        De allí surgen tratados que los países firman, comprometiéndose a defenderlos en su territorio.
                        </p>
                    </Grid>
                    <Grid container justifyContent='center'>
                        <Grid container justifyContent='center'>
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion15/2.png" alt="Tarjeta" />
                        </Grid>
                        <Grid container >
                            <p className="TextImg">
                                ¿Qué decisiones se han tomado últimamente sobre los derechos humanos?
                            </p>
                        </Grid>
                    </Grid>

                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container className='Cuadro' >
                        <img className="Escritorio" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion15/4.png" alt="Tarjeta" />
                        <img className="Imagen Tablet" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion15/4R.png" alt="Tarjeta" />
                        <h3 className='Pos SubTitulo2 Escritorio'>Los gobiernos comprometidos con los derechos lingüísticos tienen estas obligaciones:</h3>
                        <p className='Pos Lectura Lectura2 Punto1'>Promulgar leyes que aseguren el respeto a las lenguas originarias y a sus hablantes.</p>
                        <p className='Pos Lectura Lectura2 Punto2'>Conducirse siempre con respeto ante cualquier población lingüística y promoverlo entre la ciudadanía.</p>
                        <p className='Pos Lectura Lectura2 Punto3'>Conseguir que los hablantes participen en la vida económica, política y social, sintiéndose seguros y respetados al usar su lengua. Esto se puede lograr dando un espacio a las lenguas originarias en la educación y en los medios de comunicación.</p>
                        <p className='Pos Lectura Lectura2 Punto4'>Asegurar que los hablantes monolingües de lenguas originarias puedan acceder a servicios de administración pública (como registros civiles y oficinas de impuestos) y defender sus derechos en el sistema judicial.</p>
                        <p className='Pos Lectura Lectura2 Punto5'>Asegurar que las minorías lingüísticas tengan las leyes traducidas a su lengua.</p>

                        
                    </Grid>
                    
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container >
                        <p className='Lectura'>
                            Los dos últimos puntos se consiguen formando intérpretes, traductores y defensores públicos.<br/><br/>

                            <h3 className='SubTitulo'>Las lenguas originarias y la escuela</h3><br/>

                            Los derechos lingüísticos buscan también una educación en la que los hablantes de lenguas
                            minoritarias se sientan incluidos. Varios países de Latinoamérica impulsan ya una educación 
                            intercultural bilingüe: los alumnos de minorías lingüísticas reciben educación primaria en su 
                            Lengua materna y lo siguientes grados tanto en esa lengua como en español. Esto crea un ambiente 
                            de confianza, donde el alumno siente que su lengua es valiosa. ¿Qué iniciativas de 
                            inclusión lingüística hay en tu país? ¿Te parecen suficientes? <br/><br/>
                            
                            <h3 className='SubTitulo'>Lenguas e investigación</h3><br/>
                            
                            Para que los gobiernos cumplan su obligación de velar por los derechos lingüísticos 
                            necesitan saber qué idiomas se hablan en sus territorios y cuántos hablantes tienen. 
                            También es importante que la población lo sepa, porque conocer la diversidad lingüística 
                            enseña a convivir con ella respetuosamente.<br/><br/>
                             
                            Por estas razones, la <span style={{fontVariant: "all-small-caps"}}>ONU</span> y el Fondo de las 
                            Naciones Unidas para la Infancia (<span style={{fontVariant: "all-small-caps"}}>UNICEF</span> ) llevan un <em>Atlas sociolingüístico de pueblos 
                            indígenas de América Latina</em>, que van actualizando constantemente. A nivel mundial, la <span style={{fontVariant: "all-small-caps"}}>UNESCO </span> 
                            lleva un <em>Atlas de las lenguas del mundo en peligro.</em>
                        </p>
                    </Grid>
                    <Grid container justifyContent='center'>
                        <Grid container justifyContent='center'>
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion15/3.png" alt="Tarjeta" />
                        </Grid>
                        <Grid container >
                            <p className="TextImg">
                            Muchas lenguas tienen una rica tradición oral. Los investigadores pueden preservarla a través de transcripciones y grabaciones de audio.
                            </p>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container >
                        <p className='Lectura'>
                            <h3 className='SubTitulo'>Logros</h3><br/>

                            Algunas iniciativas nacionales han contribuido a revitalizar lenguas 
                            latinoamericanas en riesgo de desaparición. ¡Pero aún queda mucho 
                            trabajo por hacer!
                        </p>
                    </Grid>
                </Grid>

            <Grid container item xs={12} justifyContent="center" className="ContainerDerechos">
                <p className="Derechos">
                    © 2022. Todos los derechos reservados, Pearson, Circuito de lectores.
                </p>                
            </Grid>
        </Grid>
    </Grid>
    );
}
 
export default Los_derechos_lingüísticos;
