import { Button, Grid, makeStyles } from "@material-ui/core";
import React, { useContext, useEffect } from "react";
import DrawerContext from "../../../../../usability/DrawerContext";
import { useOnLoadImages } from "../../../../hooks/useOnLoadImages";
import Loader from "../../../../Loader/Loader";
import Drawer from "../../ReadingTools/Drawer";

const useStyles =  makeStyles (theme =>({
     Contenedor:{
        background:'#E9FBF9',
        width:'100vw',
        overflowX:'clip',
        [theme.breakpoints.down(1161)]:{
            background:'none',
           overflowX:'unset',
        },
        [theme.breakpoints.down(600)]:{
            marginTop: '79.51px',
        }
    },
  ContainerInfoLectura: {
    position: "relative",
    height: "86px",
    [theme.breakpoints.down(502)]:{
        height: "43px",
    },
    background: "#76D4C3",
    "& .BarraInfoLectura": {
      height: "86px",
      background: `Url(https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Barras/Azul.png)`,
        [theme.breakpoints.down(502)]:{
            height: "43px",
        },
    },
    "& .InfoLectura": {
      position: "absolute",
      height: "86px",
      width: "219px",
      boxSizing: "border-box",
      padding: "17.2px 0",
      background: "#FAFAFA",
      borderRadius: "50px",
      boxShadow: "2px 2px 2px 2px #00000029",
      zIndex: "4",
        [theme.breakpoints.down(502)]:{
            height: "43px",
            width: "109px",
            padding: "8.6px 0",
            borderRadius: "25px",
        },
    },
    "& .InfoLectura:before": {
      position: "absolute",
      display: "flex",
      boxSizing: "border-box",
      padding: "0 0 0 10px",
      justifyContent: "center",
      alignItems: "center",
      height: "39.88px",
      width: "39.88px",
      borderRadius: "50%",
      content: '""',
      boxShadow: "3px 0px .5px 0px #00000029",
      background: "#FAFAFA",
      left: "90px",
      top: "65px",
      transform: "rotate(0.25turn)",
      [theme.breakpoints.down(502)]:{
        left: "45px",
        top: "30px",
        height: "20.88px",
        width: "20.88px",
        },
    },
    "& .InfoLectura:after": {
      content: '""',
      height: "15px",
      width: "15px",
      borderTop: "2px solid #20A698",
      borderLeft: "2px solid #20A698",
      position: "absolute",
      bottom: "-10px",
      left: "Calc(50% - 8px)",
      transform: "rotate(0.625turn)",
      [theme.breakpoints.down(502)]:{
        height: "10px",
        width: "10px",
        left: "Calc(50% - 4.5px)",
        bottom: "-3px",
        borderTop: "1px solid #20A698",
        borderLeft: "1px solid #20A698",
        },
    },
    "& .Sesion": {
      borderLeft: "2px solid #D8D8D8",
    },
    "& .Dato": {
      margin: "0 0 5px 0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#20A698",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        margin: "0 0 2.5px 0",
        },
    },
    "& .Caracter": {
      margin: "0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#000000",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        },
    },
       '& .Regresar':{
        color: '#F2624F',
        border: '#F2624F 1px solid',
        height: '30px',
        width:'100px',
        borderRadius: '20px',
        letterSpacing: '0.14px',
        textTransform: 'none',
        background:'#FEFAF1',
        top:'calc(50% - 15px)',
        left:'5%',
        zIndex:4,
        [theme.breakpoints.down(502)]: {
          font:'bold 10px lato',
          width: "60px",
        },
      },
  },
    ContenedorLectura:{
        background:'#fff',
        width:'1160px',
        height:'100%',
                padding:'60px 168px 35px ',
        
        transform: (props) =>
        props.scale > 1 ? `scale(${props.scale}) translate(0px, 10%)` : `none`,
        [theme.breakpoints.down(1161)]:{
            width:'502px',
            padding:'60px 0 0 0',
          },
        [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },

        '& .Escritorio':{
            display:'flex',
            [theme.breakpoints.down(1161)]:{
                display:'none'
            },
        },
        '& .Tablet':{
            display:'none',
            [theme.breakpoints.down(1161)]:{
                display:'flex'
            },
        }
    },
    ContainerTitulo:{
        margin:'0 0 34px 0',
        [theme.breakpoints.down(1161)]:{
            margin:'0 0 34px 0',
        },
        '& .Titulo':{
            font:'48px fredoka one',
            letterSpacing: '0px',
            lineHeight:'58px',
            color: '#0CB5D6',
            margin:'0',
            textAlign:'center',
            [theme.breakpoints.down(1161)]:{
                font:'43px fredoka one',
                lineHeight:'52px',
            },
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        position:'relative',
        margin:' 0 0 36px 0',
        '& .Lectura':{
            lineHeight : '30px',
            margin:'0 0 36px 0',
            font:'22px lato',
            color:'#000',
            letterSpacing: '0.20px',
            [theme.breakpoints.down(1161)]:{
                font:'18px lato',
                lineHeight :'28px',
                letterSpacing: '0.17px',
            },
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .Pos':{
            position:'absolute',
            margin:'53px 0 0 0',
            width:'740px',
            [theme.breakpoints.down(1161)]:{
                width:'85.25%',
            },
            [theme.breakpoints.down(502)]:{
                margin:'20px 0 0 0',
                width:'86%',
            },
        },

        '& .SubTitulo':{
            font:'bold 30px lato',
            color:'#FC5E9E',
            letterSpacing:'.28px',
            lineHeight:'30px',
            margin:'0',
            [theme.breakpoints.down(1161)]:{
                lineHeight :'28px',
                font:'bold 26px lato',
                letterSpacing: '0.24px',
            },
            [theme.breakpoints.down(502)]:{
                font:'bold 18px lato',
                lineHeight :'24px',
                letterSpacing: '0.21px',
            },
        },
        '& .SubTitulo2':{
            font:'bold 26px lato',
            color:'#1DA698',
            letterSpacing:'.24px',
            lineHeight:'28px',
            margin:'0',
            [theme.breakpoints.down(1161)]:{
                font:'bold 22px lato',
                lineHeight :'24px',
                letterSpacing: '0.21px',
            },
            [theme.breakpoints.down(502)]:{
                font:'bold 18px lato',
                lineHeight :'20px',
                letterSpacing: '0.16px',
            },
        },
        '& .TextImg':{
            font:'20px lato',
            color:'#717476',
            letterSpacing:'.19px',
            lineHeight:'30px',
            margin:'24px 0 0 0',
            position:'absolute',
            bottom:'0',
            [theme.breakpoints.down(1161)]:{
                position:'relative',
                font:' 16px lato',
                lineHeight :'25px',
                letterSpacing: '0.15px',
            },
            [theme.breakpoints.down(502)]:{
                font:' 11px lato',
                lineHeight :'14px',
                letterSpacing: '0.10px',
            },
        },

        '& .Imagen':{
            [theme.breakpoints.down(1161)]:{
                width:'100%',
            },
        },  
        '& .Imagen2':{
            position:'absolute',
            top:'-45px',
            [theme.breakpoints.down(502)]:{
                width:'83.65%',
            },
        },
        '& .Imagen3':{
            [theme.breakpoints.down(502)]:{
                width:'83.65%',
            },
        },
        '& .Esp':{
            margin:'0',
        },  
      
    }
}));

const OMG_el_lenguaje_en_la_mensajería_instantánea = (props) => {

  const {activeStep, setActiveStep, handlePreviewStep, id_actividad} = props
  
  const handleContinue = () => {
    setActiveStep(activeStep - 1)
    handlePreviewStep()
  }

    const {
      handleSize,
    disabledZoom,
    closeZoom,
    handleZoom,
    scale
  }= useContext(DrawerContext);
  const [loading, setLoading] = React.useState(true);
  const wrapperRef = React.useRef(null);
  const imagesLoaded = useOnLoadImages(wrapperRef);

  const detectSize = () => {
    const lectura = document.getElementById("lectura");
    const newWidth = lectura?.offsetWidth;
    const newHeight = lectura?.offsetHeight;
    setLoading(false);
    if (lectura) handleSize(newHeight, newWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", detectSize);
    return () => {
      handleZoom()
      window.removeEventListener("resize", detectSize);
      closeZoom()
    };
  }, []);

  const classes = useStyles({ disabledZoom,scale });
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
             <Grid container item justifyContent="center" className={classes.ContainerInfoLectura}>
                       <Grid container className="BarraInfoLectura">
            <Button className="Regresar" onClick={handleContinue}>
                Regresar
            </Button>
        </Grid>
               <Grid container item className="InfoLectura">
                    <Grid container item direction="column" xs={6} justifyContent="center">
                        <p className="Dato">Grado</p>
                        <p className="Caracter">5</p>
                    </Grid>
                    <Grid container item direction="column" xs={6} justifyContent="center" className="Sesion">
                        <p className="Dato">Sesión</p>
                        <p className="Caracter">30</p>
                    </Grid>
               </Grid>
            </Grid>
            {!imagesLoaded && <Loader />}
        <Grid
            container
            item
            className={classes.ContenedorLectura}
            justifyContent="center"
            id="lectura"
            onLoad={detectSize}
            ref={wrapperRef}
        >
<Drawer id_lectura={id_actividad} />
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                        <h2 className="Titulo">OMG: el lenguaje en la <br/>mensajería instantánea</h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className='Lectura'>
                            <h3 className='SubTitulo'>¡El tiempo apremia!</h3><br/>

                            Con las redes sociales y las nuevas plataformas de internet 
                            surgieron nuevas formas de comunicación. Estos espacios permiten 
                            interactuar con una inmediatez sin precedentes y han transformado la 
                            convivencia. Las personas los revisan frecuentemente para mantenerse al 
                            tanto de lo que comparten sus amistades, las celebridades y los creadores 
                            de contenido.
                       </p>                   
                    </Grid>
                    <Grid container justifyContent='center'>
                        <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion30/1.png" alt="Tarjeta" />
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container xs={12} >
                        <p className='Lectura Esp'>
                            Un meme, un emoji, una foto o un video viral compartido por millones… Aunque no 
                            lo creas, estos materiales también tienen propósitos comunicativos. Lo mismo ocurre con las reacciones (como <em>me gusta</em> o <em>me enoja</em>), dejar en visto o bloquear a una persona, anular un envío o eliminar una publicación. Cada una de estas acciones envía un mensaje específico.
                        </p>
                    </Grid>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container  xs={5}  className='Escritorio'  >
                        <p className='Lectura Esp'>
                            ¿Notas qué tienen en común<br/> 
                            todas ellas? Así es: por lo<br/> 
                            general, dan un mensaje breve<br/>
                            y veloz. Muchas formas de<br/> 
                            comunicación prefieren la<br/> 
                            rapidez, y los medios digitales<br/> 
                            no son la excepción.<br/><br/>  
                         
                            <h3 className='SubTitulo'>¿Una cosa nueva?</h3><br/>
                            
                            Aunque distintos servicios de<br/>
                            mensajería (como WhatsApp)<br/> 
                            y redes sociales (como<br/> 
                            Twitter) han popularizado<br/> 
                            abreviar las palabras, omitir<br/> 
                            letras se hace desde la época<br/> 
                            romana. Las abreviaturas se<br/> 
                            utilizaban para escribir más<br/> 
                            rápido en el menor espacio<br/> 
                            posible.                        
                        </p>
                    </Grid>
                    <Grid container  xs={7} className='Escritorio' justifyContent='center'>
                        <Grid container justifyContent='center'>
                            <img className="Imagen2" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion30/2.png" alt="Tarjeta" />
                        </Grid>
                        <Grid container justifyContent='center'>
                            <p className='TextImg'>
                                La gente no sólo buscaba escribir rápido. Por ejemplo, en la Edad Media, 
                                la escritura se hacía completamente a mano… ¡Y los materiales costaban mucho! 
                                Además, los escribas solían hacerlo por obligación y podían pasar horas con una 
                                misma tarea. Abreviar hacía las cosas más rápidas y más baratas a la vez.                            
                            </p>
                        </Grid>
                    </Grid>
                
                    
                    <Grid container  xs={12} className='Tablet'>
                    <p className='Lectura '>
                            ¿Notas qué tienen en común 
                            todas ellas? Así es: por lo 
                            general, dan un mensaje breve
                            y veloz. Muchas formas de 
                            comunicación prefieren la 
                            rapidez, y los medios digitales 
                            no son la excepción.<br/><br/>  
                         
                            <h3 className='SubTitulo'>¿Una cosa nueva?</h3><br/>
                            
                            Aunque distintos servicios de
                            mensajería (como WhatsApp) 
                            y redes sociales (como 
                            Twitter) han popularizado 
                            abreviar las palabras, omitir 
                            letras se hace desde la época 
                            romana. Las abreviaturas se 
                            utilizaban para escribir más 
                            rápido en el menor espacio 
                            posible.                        
                        </p>
                    </Grid>
                    <Grid container  xs={12} className='Tablet' justifyContent='center'>
                        <Grid container justifyContent='center'>
                            <img className="Imagen3" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion30/2R.png" alt="Tarjeta" />
                        </Grid>
                        <Grid container justifyContent='center'>
                            <p className='TextImg'>
                                La gente no sólo buscaba escribir rápido. Por ejemplo, en la Edad Media, 
                                la escritura se hacía completamente a mano… ¡Y los materiales costaban mucho! 
                                Además, los escribas solían hacerlo por obligación y podían pasar horas con una 
                                misma tarea. Abreviar hacía las cosas más rápidas y más baratas a la vez.                            
                            </p>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container justifyContent='center'>
                        <img className="Imagen Escritorio" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion30/3.png" alt="Tarjeta" />
                        <img className="Imagen Tablet" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion30/3R.png" alt="Tarjeta" />
                        <p className='Pos Lectura'>¿Conoces la <em>braquigrafía</em>? Es la disciplina encargada de estudiar las abreviaturas y las representaciones reducidas de las palabras. Permite entender lo que dicen los documentos antiguos.</p>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container >
                        <p className='Lectura'>
                            <h3 className='SubTitulo'>Comunicación en otras lenguas</h3><br/>

                            Otro cambio que han traído consigo las nuevas plataformas y las redes sociales es la 
                            posibilidad de interactuar con personas que hablan una lengua distinta. Actualmente 
                            se utilizan abreviaturas de diferentes orígenes. <em>NTP</em> (no te preocupes) y <em>TQM</em> (te 
                            quiero mucho) surgen del español, pero algunas otras tienen origen en el inglés: <em>LOL </em> 
                            (<em>laughing out loud</em>) se emplea cuando algo nos produce mucha risa; <em>OMG</em> (<em>Oh my God</em>) 
                            expresa asombro o incredulidad; <em>G2G</em> (<em>[I] got to go</em>) se usa para explicar que debes 
                            marcharte o abandonar la conversación, y <em>TL-DR</em> (<em>Too long – didn’t read</em>) para expresar 
                            que el texto que has recibido es demasiado extenso (dando a entender que es muy 
                            aburrido) y no lo leíste. ¿Ya las conocías? ¿Las has utilizado? <br/><br/>
                            
                            <h3 className='SubTitulo'>Algunas dificultades</h3><br/>
                            
                            ¿Cómo se puede resumir tanto en tan poco? ¿Y si alguien no entiende? Aunque las 
                            abreviaturas tienen la finalidad de agilizar la comunicación, muchas veces quien las 
                            usa debe detenerse un momento para aclarar el mensaje porque alguien no lo 
                            entendió. ¿Te ha pasado? Esto ocurre cuando la persona que recibe tu mensaje posee 
                            experiencias distintas a las tuyas, es de otra generación (más joven o mayor) o no 
                            comparte tu <em>bagaje cultural</em> (es decir, tu conocimiento sobre cosas específicas). Sin 
                            embargo, esto puede ocurrir incluso cuando coincides con alguien en uno o más de 
                            estos aspectos; por ejemplo, con personas de tu edad. <br/><br/>
                            
                            <h3 className='SubTitulo'>¿Las abreviaturas pueden modificar la lengua?</h3> <br/>
                            
                            El uso de estas abreviaturas plantea algunas interrogantes: ¿hasta dónde puede 
                            penetrar su uso en la lengua? ¿Algún día llegaremos a escribir nuestros trabajos 
                            escolares de la misma manera en que nos comunicamos por Facebook? <br/><br/>
                            
                            Antes de preocuparnos por esta posibilidad, debemos tomar en cuenta que la lengua 
                            no es algo fijo: está en constante cambio porque las sociedades que la usan también 
                            se transforman. Considera que su función es permitir la comunicación entre los miembros 
                            de una comunidad. ¿Cómo podría funcionar la lengua si no se adapta a las 
                            circunstancias en que vive la población que la usa? Tenemos que cuestionarnos si 
                            las circunstancias que está viviendo una sociedad la benefician. ¿La transformación 
                            que traen consigo las redes sociales es positiva? ¿Qué ventajas y desventajas tiene? 
                            ¡Reflexiona y discútelo con tus compañeros cuando tengas la oportunidad!
                        </p>
                    </Grid>
                </Grid>
        </Grid>
        <Grid container item style={{ marginTop: 70}} ></Grid>
    </Grid>
    );
}
 
export default OMG_el_lenguaje_en_la_mensajería_instantánea;
