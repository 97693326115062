import { Button, Grid,makeStyles}              from '@material-ui/core';
import React, { useContext, useEffect } from "react";
import DrawerContext from "../../../../../usability/DrawerContext";
import { useOnLoadImages } from "../../../../hooks/useOnLoadImages";
import Loader from "../../../../Loader/Loader";
import Drawer from "../../ReadingTools/Drawer";

const useStyles = makeStyles((theme) => ({
  Contenedor: {
    background: "#E9FBF9",
    width: "100vw",
    overflowX: "clip",
    [theme.breakpoints.down(1161)]: {
      background: "none",
      overflowX: "unset",
    },
    [theme.breakpoints.down(600)]: {
      marginTop: "79.51px",
    },
  },
  ContainerInfoLectura: {
    position: "relative",
    height: "86px",
    [theme.breakpoints.down(502)]: {
      height: "43px",
    },
    background: "#76D4C3",
    "& .BarraInfoLectura": {
      height: "86px",
      background: `Url(https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Barras/Azul.png)`,
      [theme.breakpoints.down(502)]: {
        height: "43px",
      },
    },
    "& .InfoLectura": {
      position: "absolute",
      height: "86px",
      width: "219px",
      boxSizing: "border-box",
      padding: "17.2px 0",
      background: "#FAFAFA",
      borderRadius: "50px",
      boxShadow: "2px 2px 2px 2px #00000029",
      zIndex: "4",
      [theme.breakpoints.down(502)]: {
        height: "43px",
        width: "109px",
        padding: "8.6px 0",
        borderRadius: "25px",
      },
    },
    "& .InfoLectura:before": {
      position: "absolute",
      display: "flex",
      boxSizing: "border-box",
      padding: "0 0 0 10px",
      justifyContent: "center",
      alignItems: "center",
      height: "39.88px",
      width: "39.88px",
      borderRadius: "50%",
      content: '""',
      boxShadow: "3px 0px .5px 0px #00000029",
      background: "#FAFAFA",
      left: "90px",
      top: "65px",
      transform: "rotate(0.25turn)",
      [theme.breakpoints.down(502)]: {
        left: "45px",
        top: "30px",
        height: "20.88px",
        width: "20.88px",
      },
    },
    "& .InfoLectura:after": {
      content: '""',
      height: "15px",
      width: "15px",
      borderTop: "2px solid #20A698",
      borderLeft: "2px solid #20A698",
      position: "absolute",
      bottom: "-10px",
      left: "Calc(50% - 8px)",
      transform: "rotate(0.625turn)",
      [theme.breakpoints.down(502)]: {
        height: "10px",
        width: "10px",
        left: "Calc(50% - 4.5px)",
        bottom: "-3px",
        borderTop: "1px solid #20A698",
        borderLeft: "1px solid #20A698",
      },
    },
    "& .Sesion": {
      borderLeft: "2px solid #D8D8D8",
    },
    "& .Dato": {
      margin: "0 0 5px 0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#20A698",
      [theme.breakpoints.down(502)]: {
        font: "bold 12px lato",
        margin: "0 0 2.5px 0",
      },
    },
    "& .Caracter": {
      margin: "0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#000000",
      [theme.breakpoints.down(502)]: {
        font: "bold 12px lato",
      },
    },
    "& .Regresar": {
      color: "#F2624F",
      border: "#F2624F 1px solid",
      height: "30px",
      width: "100px",
      borderRadius: "20px",
      letterSpacing: "0.14px",
      textTransform: "none",
      background: "#FEFAF1",
      top: "calc(50% - 15px)",
      left: "5%",
      zIndex: 4,
      [theme.breakpoints.down(502)]: {
        font: "bold 10px lato",
        width: "60px",
      },
    },
  },
  ContenedorLectura: {
    background: "#fff",
    width: "1160px",
    height: "100%",
    padding: "0px 168px 0px ",
    transform: (props) =>
      props.scale > 1 ? `scale(${props.scale}) translate(0px, 10%)` : `none`,
    [theme.breakpoints.down(1161)]: {
      width: "768px",
      padding: "0",
    },
    [theme.breakpoints.down(768)]: {
      width: "350px",
      padding: "0",
    },
    "& .ContainerDerechos": {
      position: "absolute",
      margin: "36px 0 0 0",
      bottom: "40px",
      [theme.breakpoints.down(1161)]: {
        display: "none",
      },
    },
    "& .Derechos": {
      textAlign: "center",
      margin: "0px",
      font: " 15px lato",
      color: "#717476",
    },
    "& .Escritorio": {
      display: "block",
      [theme.breakpoints.down(1161)]: {
        display: "none",
      },
    },
    "& .Tablet": {
      display: "none",
      [theme.breakpoints.down(1161)]: {
        display: "block",
      },
    },
  },
  ContainerTitulo: {
    position: "absolute",
    top: "63px",
    [theme.breakpoints.down(768)]: {
      top: "25px",
    },
    "& .Titulo": {
      font: "53px fredoka one",
      letterSpacing: "0px",
      lineHeight: "65px",
      color: "#817FD9",
      margin: "0",
      textAlign: "center",
      [theme.breakpoints.down(1161)]: {
        font: "46px fredoka one",
        lineHeight: "56px",
      },
      [theme.breakpoints.down(768)]: {
        font: "28px fredoka one",
        lineHeight: "33px",
      },
    },
  },
  Parrafo1: {
    margin: "0",
    position: "relative",
    "& .Lectura": {
      position: "absolute",
      left: "35px",
      margin: "0",
      font: "22px lato",
      color: "#000",
      letterSpacing: "0.20px",
      lineHeight: "36px",
      [theme.breakpoints.down(1161)]: {
        left: "85px",
        font: "18px lato",
        lineHeight: "28px",
        letterSpacing: "0.17px",
      },
      [theme.breakpoints.down(768)]: {
        left: "30px",
        font: "9px lato",
        lineHeight: "15px",
        letterSpacing: "0.09px",
      },
    },
    "& .Pos": {
      top: "185px",
      [theme.breakpoints.down(1161)]: {
        top: "164px",
      },
      [theme.breakpoints.down(768)]: {
        top: "75px",
      },
    },
    "& .Pos2": {
      top: "885px",
      [theme.breakpoints.down(1161)]: {
        top: "739px",
      },
      [theme.breakpoints.down(768)]: {
        top: "324px",
      },
    },
    "& .Pos3": {
      top: "1382px",
      [theme.breakpoints.down(1161)]: {
        top: "1159px",
      },
      [theme.breakpoints.down(768)]: {
        top: "534px",
      },
    },
    "& .Pos4": {
      top: "1910px",
      [theme.breakpoints.down(1161)]: {
        top: "1555px",
      },
      [theme.breakpoints.down(768)]: {
        top: "704px",
      },
    },
    "& .Imagen": {
      position: "relative",
      [theme.breakpoints.down(768)]: {
        width: "100%",
      },
    },
  },
}));

const Mundo_geométrico = (props) => {

  const {activeStep, setActiveStep, handlePreviewStep, id_actividad} = props

  const handleContinue = () => {
    setActiveStep(activeStep - 1)
    handlePreviewStep()
  }

  const {
    handleSize,
    disabledZoom,
    closeZoom,
    handleZoom,
    scale
  }= useContext(DrawerContext);
  const [loading, setLoading] = React.useState(true);
  const wrapperRef = React.useRef(null);
  const imagesLoaded = useOnLoadImages(wrapperRef);

  const detectSize = () => {
    const lectura = document.getElementById("lectura");
    const newWidth = lectura?.offsetWidth;
    const newHeight = lectura?.offsetHeight;
    setLoading(false);
    if (lectura) handleSize(newHeight, newWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", detectSize);
    return () => {
      handleZoom()
      window.removeEventListener("resize", detectSize);
      closeZoom()
    };
  }, []);

  const classes = useStyles({ disabledZoom,scale });
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
             <Grid container item justifyContent="center" className={classes.ContainerInfoLectura}>
                <Grid container className="BarraInfoLectura">
                    <Button className="Regresar" onClick={handleContinue}>
                        Regresar
                    </Button>
                </Grid>
               <Grid container item className="InfoLectura">
                    <Grid container item direction="column" xs={6} justifyContent="center">
                        <p className="Dato">Grado</p>
                        <p className="Caracter">2</p>
                    </Grid>
                    <Grid container item direction="column" xs={6} justifyContent="center" className="Sesion">
                        <p className="Dato">Sesión</p>
                        <p className="Caracter">41</p>
                    </Grid>
               </Grid>
            </Grid>
            {!imagesLoaded && <Loader />}
            <Grid
            container
            item
            className={classes.ContenedorLectura}
            justifyContent="center"
            id="lectura"
            onLoad={detectSize}
            ref={wrapperRef}
            >
            <Drawer id_lectura={id_actividad} withoutTransform ={true}/>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                <img className='Imagen Escritorio' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img2do/Sesion41/1.png"  alt="" />
                <img className='Imagen Tablet' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img2do/Sesion41/1R.png"  alt="" />

                    <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                        <h2 className="Titulo " >
                            Mundo geométrico
                        </h2>
                    </Grid>
                        <p className="Lectura Pos">
                            Si observas con atención,<br/>
                            verás que en el mundo todo,<br/>
                            de algún increíble modo,<br/>
                            está en total relación.<br/><br/>
                                
                            Desde el solitario punto <br/>
                            hasta la redonda esfera, <br/>
                            todo en el planeta es mera <br/>
                            geometría en su conjunto. <br/><br/>
                            
                            Mira bien las escaleras: <br/>
                            de rectángulos, serpiente <br/>
                            es, que sube una pendiente <br/>
                            y baila como bandera.
                        </p>

                        <p className="Lectura Pos2">
                            Sí, redondas son las mesas,<br/>
                            rectangulares también… <br/>
                            y hasta encontraremos quien<br/>
                            dé triangulares sorpresas.
                        </p>

                        <p className='Lectura Pos3'>
                            Los autos son la fusión <br/>
                            de geométricas figuras <br/>
                            que recorren las llanuras:<br/>
                            perfecta locomoción.
                        </p>

                        <p className='Lectura Pos4'>
                            Pirámides triangulares <br/>
                            lo mismo que las montañas. <br/>
                            Hay trapecios, musarañas <br/>
                            de figuras singulares.
                        </p>
                </Grid>
        </Grid>
        <Grid container item style={{ marginTop: 80}} ></Grid>
    </Grid>
    );
}
 
export default Mundo_geométrico ;
