import React                        from "react";
import { Button,makeStyles,Grid}    from "@material-ui/core";

const useStyles = makeStyles(theme=>({
  welcomePageContainer: {
    background:'#FFFDFD',
    height:'calc(100vh - 79.5px)',
  },
  ContainerIzquierdo:{
    width:'97%',
    [theme.breakpoints.down(1160)]:{
      width:'100%',
    },
  },

    ContainerEstrategi:{
    position:'relative',
    width:'100vw',
    height:'calc(100vh - 79.5px - 79.5px)',
    overflow: 'auto',
  },
  ContainerSubHabilidades:{
    background:'none',
    boxSizing:'border-box',
    cursor:'pointer',
    textAlign:'center',
    display:'flex',
    padding:'43px 17px 9px 17px',
    alignItems:'center',
    position:'absolute',
    height:'40px',
    width:'62px',
    right:'3%',
    zIndex:1,
    [theme.breakpoints.down(1281)]:{
      left:'calc(50% - 31px)',
      padding:'0 0 0 62px',
    },
       [theme.breakpoints.down(600)]:{
      left:'0',
      padding:'0 15px 0 55px',
    },
        '& .Habilidades':{
      color:'#575757',
      font: 'normal 16px Lato',
      letterSpacing: '0.13px',
      display:'none',
      [theme.breakpoints.down(1281)]:{
        padding:'0 ', 
        margin:'0',
        font: ' 13px Lato',
      },
      [theme.breakpoints.down(600)]:{
        padding:'0 ', 
        margin:'0',
        font: ' 11px Lato',
      },
    },
    '&:hover':{
      border:' 5px solid #F26973',
      borderRadius: '20px 0px 20px 20px',
      background:'#fff',
      height:'215px',
      width:'233px',
      [theme.breakpoints.down(1281)]:{
        height:'75px',
        width:'350px',
        borderRadius: '0px 0px 20px 20px',
      },
      [theme.breakpoints.down(600)]:{
        height:'75px',
        width:'100%',
      },

      '& .Habilidades':{
        display:'block',
      },
      '&:before':{
        borderTop:' 0px ',
        borderRight:'0px'
      },
    },
    '&:before':{
      display:'flex',
      justifyContent:'center',
      alignItems:'center',
      content: '"RG"',
      position: 'absolute',
      right: '0',
      top: '0',
      font: '20px Fredoka One',
      background:' #DC3F8C',
      color: '#FFF',
      border:' 3px solid #F2624F',
      borderRadius: '0px 0px 20px 20px',
      height: '40px',
      width: '62px',
      [theme.breakpoints.down(1281)]:{
        left: '-5px',
        top: '0',
      },
      [theme.breakpoints.down(600)]:{
        height: '30px',
        width: '52px',
        font: '18px Fredoka One',
      },

    },
  },
  Estrategia:{
    margin:'0px',
    font:'38px Fredoka One',
    textAlign: 'center',
    letterSpacing: '0.28px',
    color: '#20A698',
    opacity: '1',
    [theme.breakpoints.down(1161)]:{
      font:'28px Fredoka One',
    },
    [theme.breakpoints.down(600)]:{
      font:'24px Fredoka One',
    },
  },

   TextStrg:{
      margin:'0', 
      font:'normal  22px Lato',
      letterSpacing: '0.10px',
      color: '#575757',
      opacity: 1,
      padding:'0 0 40px 0',
      boxSizing:'border-box',
      width:'760px',
      [theme.breakpoints.down(1161)]:{
        width:'650px',
        font:'normal  18px Lato',
      },
      [theme.breakpoints.down(600)]:{
        font:'normal  16px Lato',
        width:'325px',
      },
      [theme.breakpoints.down(325)]:{
        font:'normal  14px Lato',
        width:'300px',
      },
  },

   Titulo:{
    position:'absolute',
    top:'58px',
    [theme.breakpoints.down(1161)]:{
      top:'80px',
    },
  },

  Objet1:{
    position:'absolute',
    top:'130px',
    '& .Td':{
      background:'#fff',
      border:'solid 1.5px #000',
      width:'500px',
      height:'42px',
      font:' 20px lato',
      color: '#575757',
      padding:'0 0 0 10px',
      boxSizing:'border-box',
      [theme.breakpoints.down(1161)]:{
        font:'18px lato',
      },
      [theme.breakpoints.down(600)]:{
        font:'16px lato',
      },
    },
    '& .Td2':{
      borderTop:'solid 1.5px #000',
    },
    '& .Td3':{
      borderTop:'solid 1.5px #000',
      borderRight:'solid 1.5px #000',

    },
    '& .Br':{
      margin:'0 0 20px 0'
    },
    '& .ul':{
      margin:'22px 0',
      padding:'0 0 0 19px',
    },
    '& .li':{
      marginBottom:'10px',
    },
   '& .Amarillo':{
    textAlign:'center',
    height:'42px',
    background:'#F7DF92',
    padding:'0 ',
   },
   '& .Verde':{
    textAlign:'center',
    height:'42px',
    background:'#84E9DF',
    padding:'0 ',
   },
   '& .Img':{
    width:'100%'
   },

  },

  ContainerBarraInferior:{
    position:'relative',
    background:'#F3CD52',
    height:'79.51px',
    [theme.breakpoints.up(1920)]:{
      height:'8vh',
    },
    [theme.breakpoints.down(1161)]:{
      background: 'linear-gradient(280deg, #D3348F 0%, #F1CB5A 100%) 0% 0% ',
    },
  },

  ContainerBarraDerecha:{
    background: 'linear-gradient(180deg, #D3348F 0%, #F1CB5A 100%) 0% 0%',
    width:'3%',
    height:'100vh',
    [theme.breakpoints.down(1161)]:{
      display:'none',
    },
  },
  
   BotonAvanza:{
    position:'absolute',
    bottom:'52px',
    right:'134px',
    textTransform:'none',
    textDecoration:'none',
    height:'62px',
    width:'279px',  
    background: '#7A54BA',
    boxShadow: '0px 3px 6px #00000029',
    borderRadius: '61px',
    color: '#FFFFFF',
    font: 'bold 20px lato ',
    '&:hover':{
        background: '#dd78fb',
      },
    [theme.breakpoints.down(1161)]:{
      position:'relative',
      bottom:'32px',
      left:'0',
      right:'0',
    },
  },
    
}));

const ReadingStrategys = (props) => {
  const classes = useStyles();
  const {activeStep, setActiveStep, handleNextStep} = props

  const handleContinue = () => {
    setActiveStep(activeStep + 1)
    handleNextStep()
  }

  return(

    <Grid  container direction="column" item sm={12} className={classes.welcomePageContainer} >
      <div className={classes.ContainerSubHabilidades}>
          <p className="Habilidades">Identificar el verbo (con persona, tiempo y modo) de una oración.</p>
      </div>
      <Grid container item sm={12} justifyContent="center" className={classes.ContainerIzquierdo} >

        <Grid container item className={classes.ContainerEstrategi } >

              <Grid container item justifyContent="center" alignItems="center" sm={12} className={classes.Titulo}> 
                <p className={classes.Estrategia} >Estrategia de lectura</p>
              </Grid>

              <Grid container item sm={12} alignItems="center" justifyContent="center" className={classes.Objet1}>
                  <p className={classes.TextStrg}>
                    Es fácil encontrar un verbo cuando las oraciones son simples, ¿cómo podemos hacerlo si 
                    se trata de oraciones compuestas en las que hay dos verbos o más? Sigue estos pasos.
                    <ul className="ul">
                      <li className="li">
                        Primero lee el texto completo, observa la imagen y lee su descripción.
                      </li>
                      <li className="li">
                        Ten a la mano material para hacer notas, como una hoja y un lápiz.
                      </li>
                      <li className="li">
                        Lee y localiza las siguientes oraciones en el texto y elabora una tabla para 
                        cada una. Sigue el ejemplo.
                      </li>
                    </ul>

                    <tr>
                      <tr>
                        <td valign="middle" className="Td"><b>Oración</b></td>
                        <td className="Td2"></td>
                        <td className="Td2"></td>
                        <td className="Td3"></td>
                      </tr>
                      <tr>
                        <td valign="middle" className="Td"><b>Sujeto</b></td>
                        <td className="Td2"></td>
                        <td className="Td2"></td>
                        <td className="Td3"></td>
                      </tr>
                      <tr>
                        <td valign="middle" className="Td"><b>Verbos</b></td>
                        <td className="Td"></td>
                        <td valign="middle" className="Td"><b>Verbo principal</b></td>
                        <td className="Td"></td>
                      </tr>
                    </tr>

                    <ul className="ul">
                      <li className="li">
                        En la introducción:
                        <ol type="a" >
                            <li className="li">
                              El valor de la arqueología se ha <em>reconocido</em> recientemente entre varios círculos.
                            </li>
                            <li className="li">
                              <em>Era</em> una labor más bien intuitiva.
                            </li>
                            <li className="li">
                              Una gran cantidad de estudiosos <em>han dejado</em> marca en esta área y en la historia.
                            </li>
                            <li className="li">
                              A continuación, <em>conocerás</em> los aportes y las trayectorias de algunos arqueólogos.
                            </li>
                        </ol>
                      </li>
                      <li className="li">
                        En el pie de imagen:
                        <ol type="a" start={5} >
                          <li className="li">
                            Las excavaciones <em>han evolucionado</em> considerablemente desde su creación.
                          </li>
                        </ol>
                      </li>
                      <li className="li">
                        En el primer subtítulo:
                        <ol type="a" start={6} >
                          <li className="li">
                            Dentro de sus aportaciones <em>destacan</em> las excavaciones en Tlapacoya 
                            (Estado de México) y en el Valle de Guadalupe (Jalisco).
                          </li>
                          <li className="li">
                            <em>Investigó</em> las clases sociales en el Distrito Federal, hoy 
                            llamada Ciudad de México.
                          </li>
                        </ol>
                      </li>
                      <li className="li">
                        En el segundo subtítulo:
                        <ol type="a" start={8} >
                          <li className="li">
                            <em>Descubrió</em> la vara de medida más antigua del mundo y los restos de las 
                            puertas de Balawat.
                          </li>
                          <li className="li">
                            La mayor parte de estos vestigios se <em>conservan</em> en el Museo Británico 
                            y algunos más en el Museo de Mosul.
                          </li>
                        </ol>
                      </li>

                      <li className="li">
                        En el tercer subtítulo:
                        <ol type="a" start={10} >
                          <li className="li">
                            <em>Fue</em> un político, historiador y coleccionista de arte ecuatoriano.
                          </li>
                          <li className="li">
                            También <em>definió</em> diversas culturas de la zona. 
                          </li>
                          <li className="li">
                            <em>Realizó</em> excavaciones en la sierra Manabí (Ecuador) y en Tiahuanaco (en Bolivia).
                          </li>
                        </ol>
                      </li>

                      <li className="li">
                        Escribe todas las oraciones en la tabla. Lee con atención los siguientes organizadores 
                        con datos prácticos. ¿Qué necesitas saber de los verbos? ¿Cómo identificas el verbo principal?
                      </li>
                      <img className="Img" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img4to/Sesion45/Est.png" alt="Tarjeta" />
                      <img className="Img" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img4to/Sesion45/Est2.png" alt="Tarjeta" />
                      <li className="li">
                        Completa la información de cada enunciado en las tablas.
                      </li>
                      <li className="li">
                        Ahora revisa los verbos de las oraciones que están resaltados. ¿En qué tiempo verbal 
                        están conjugados? ¿Por qué crees que esto sea así?
                      </li>
                    </ul>

                    Probablemente tu experiencia lectora fue distinta al analizar las oraciones 
                    del texto para identificar los verbos principales. Practica esta técnica siempre 
                    que necesites saber la conjugación de las acciones en un escrito.
                    <div className="Br"></div>
                    ¡Avanza!

                  </p>
              </Grid>
                
        </Grid>
        
        <Grid container item sm={12} className={classes.ContainerBarraInferior} justifyContent="center">
            <Button id="BotonContinuarEstrategias" className={classes.BotonAvanza} onClick={handleContinue}>
              Continuar
            </Button>
        </Grid>
        
      </Grid>

      <Grid container item sm={12} className={classes.ContainerBarraDerecha}></Grid>

    </Grid>

  )
}

export default ReadingStrategys;
