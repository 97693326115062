import React               from "react";
import {Grid, makeStyles}                 from '@material-ui/core';
import Radio                            from '@material-ui/core/Radio';
import FiberManualRecordSharpIcon       from '@material-ui/icons/FiberManualRecordSharp';

const useStyles = makeStyles(theme =>({
  answerOption:{
    border: "#2E313C26 3px solid",
    width: "80%",
    borderRadius: "5px",
    backgroundColor: "white",
    margin:'auto',
    '&:hover':{
      backgroundColor: "#2E313C1A",
      cursor: "pointer"
    },

  },
  selectedStyle:{
    border: "#4BBCF8 5px solid",
    backgroundColor: "#DDF6FA",
    width: "82%",
    borderRadius: "5px",
    margin:'auto',
    [theme.breakpoints.down(1310)]:{
      font: "500 16px lato",
      width: "80%",
      // margin:"10px 0 10px 0"
    }, 
    [theme.breakpoints.down(700)]:{
      font: "500 12px lato",
      width: "80%",
      // margin:"10px 0 10px 0"
    }, 
  },
  reading: {
    // '&:first-letter':{
    //   textTransform: 'capitalize',
    // },
    // textAlign: "justify",
    textAlign: "Left",
    margin:'3.46%',
    font: "22px lato",
    letterSpacing:' 0.21px',
    marginLeft: "8px",
    [theme.breakpoints.down(1370)]:{
      font: "18px lato",
      margin: "8px",
    },
    [theme.breakpoints.down(1280)]:{
      font: "16px lato",
      margin: "8px 0 8px 8px",
    },
    [theme.breakpoints.down(650)]:{
      font: "14px lato",
    },
  },
  primaryIcon:{
    color: "#4BBCF8",
  },
  primaryIconFeedback:{
    color:"#4BBCF8"
  },
  radioIcon:{
    fontSize: '35px',
    padding: '3px',
    [theme.breakpoints.down(625)]:{
      padding: '0px',
      fontSize: '20px',
    },
  },
}));


const Answer = (props) => {
  const classes = useStyles()
  const {
    setPristine,
    setIsCorrect,
    isCorrect,
    questionId,
    answerText,
    setAnswerText,
    feedback,
    setFeedback,
    setCurrentAnswer,
    currentAnswer,
    setValueAnswerText,
  } = props;

  const handleChange = (event) => {
    setCurrentAnswer(questionId)
  };


  const handleClickA = (event) => {
    setCurrentAnswer(questionId)
    setFeedback(feedback)
    setPristine(false)
    setIsCorrect(isCorrect > 0 ? true : false)
    setAnswerText(answerText)
    setValueAnswerText(answerText);
  }


  return(
    <Grid
      key={questionId + "answer"}
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      className={ currentAnswer === questionId ? classes.selectedStyle : classes.answerOption}
      onClick={handleClickA}
    >
      <Grid container item xs={1} lg={1} justifyContent="center" alignItems="center">
      <Radio
        checked={currentAnswer === questionId}
        onChange={handleChange}
        color="primary"
        value={questionId}
        name={"answer" + questionId}
        classes={{root: classes.radioIcon}}
        checkedIcon={
          <FiberManualRecordSharpIcon 
            color="primary"

            classes={{colorPrimary: classes.primaryIconFeedback, root: classes.radioIcon}}
          />
        }
        inputProps={{ 'aria-label': 'A' }}
      />
      </Grid>
      <Grid container item xs={11} lg={11}>
        {/* <p className={classes.reading}>{answerText}</p> */}
        <p 
          dangerouslySetInnerHTML={{__html: `${answerText}`}}
          className={classes.reading}
        >
        </p>
      </Grid>
    </Grid>
  )
}

export default Answer;
