export const backgroundColors = [
  "#D63390",
  "#F6644B",
  "#F3CD52",
  "#65EAE4",
  "#591AAF",
  "#091590",
];
export const loginBotton = [
  "#65EAE4",
  "#65EAE4",
  "#65EAE4",
  "#F3CD52",
  "#65EAE4",
  "#65EAE4",
];
export const lgColors = [
  "#F3CD52",
  "#F3CD52",
  "#D63390",
  "##591AAF",
  "#F3CD52",
  "#F3CD52",
];
export const txtColors = [
  "#FFFFFF",
  "#FFFFFF",
  "#575757",
  "#575757",
  "#FFFFFF",
  "#FFFFFF",
];
