import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
    ContenedorLectura:{
        width:'502px',
                  [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
    },
    ContainerTitulo:{
        margin:'0 0 46px 0',
        '& .Titulo':{
            letterSpacing: '0px',
            color: '#E1AF10',
            margin:'0',
            textAlign:'center',
            font:'43px fredoka one',
            lineHeight:'52px',
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            margin:'0 0 36px 0 ',
            color:'#000',
            font:'18px lato',
            lineHeight :'28px',
            letterSpacing: '0.17px',
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .Esp':{
            letterSpacing: '0.17px!important',
        },
        '& .Imagen':{
            width:'100%',
        },
    },
    

}));

const La_leyenda_de_la_flor_amarilla = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >
                        La leyenda<br/> de la flor amarilla
                    </h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                            Cuenta la leyenda que hace muchos años, en un bosque lejano, vivía una mujer muy
                            sabia que conocía el poder de las plantas. Con ellas preparaba brebajes y ungüentos 
                            curativos. Personas enfermas provenientes de todas partes la visitaban y muchas de 
                            ellas se aliviaban de sus padecimientos. Pero los médicos de la ciudad no aprobaban 
                            su trabajo, así que la acusaron de brujería.
                        </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <Grid container >
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img2do/Sesion31/1.png"  alt="Tarjeta" />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                            Una noche, un grupo de hombres llegó hasta su cabaña y la tomó presa. A pesar de 
                            que en la casa sólo había yerbas, plantas en frascos de vidrio y algunas anotaciones, 
                            se inició un juicio en su contra. <br/><br/>
                            
                            —¿Sabe usted de qué se le acusa? —preguntó el fiscal. <br/><br/>
                            
                            —De curar enfermos —respondió ella con aire sereno. <br/><br/>
                            
                            —¡No! ¡De brujería! ¡De usar la magia para engañar a la gente! —recriminó el fiscal. <br/><br/>
                            
                            En su defensa, ella dijo que lo que hacía con las plantas no era magia ni hechicería,
                            sino observación: <br/><br/>

                            <span className="Esp">—Tomo una planta y la examino. Veo el color de sus hojas, la forma de su tallo. Pruebo un 
                            pedacito y anoto cuál es su sabor o qué efectos tiene. La comparo con otras plantas 
                            parecidas. Experimento con ellas haciendo jarabes, pomadas y ungüentos. Algunas las 
                            aprovecho como medicamento y otras no. Desecho las que no sirven y conservo en un 
                            frasco con alcohol las que sí para usarlas después. Eso es todo —explicó pacientemente.</span>
                        </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <Grid container >
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img2do/Sesion31/2.png"  alt="Tarjeta" />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                    <p className="Lectura">
                        Apenas acabó de hablar, la llevaron a su celda para que el jurado decidiera si era
                        inocente o culpable. El veredicto resultó cruel: la sentenciaron a la hoguera. <br/><br/>
                        
                        Cuando fueron por ella para informarle el fallo, no la encontraron. De inmediato se
                        corrió una voz de alarma y la buscaron por todos lados: en la prisión, en el bosque y
                        hasta en los pueblos cercanos. Nunca apareció. En cambio, en su celda encontraron 
                        una planta parecida al girasol, de flores amarillas y sabor amargo, llamada <em>árnica</em>.
                        Éste era su regalo. <br/><br/>
                        
                        En la actualidad, los productos de esta planta se utilizan para curar heridas y golpes.<br/><br/>
                        
                        Para recordarla, en aquel bosque hay una estatua de una mujer que sostiene plantas
                        en la mano.
                    </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <Grid container >
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img2do/Sesion31/3.png"  alt="Tarjeta" />
                        </Grid>
                    </Grid>
                </Grid>
        </Grid>
    </Grid>
    );
}
 
export default La_leyenda_de_la_flor_amarilla;
