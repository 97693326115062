import { makeStyles, Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
// import StudentsList from "./StudentsList";
import ContainerAdminPearson from "../../ContainerAdminPearson";

import ModalConfirm from "../../../../Modals/ModalConfirm";
import ModalMessage from "../../../../Modals/ModalMessage";

import CodigoGenerico from "./Generico/CodigoGenerico";
import CodigoEspecifico from "./Especifico/CodigoEspecifico";
import axios from "../../../../../services/axios";
import SelectTipo from "./Selects/SelectTipo";
import ModalCodigoCreado from "../../Modals/ModalCodigoCreado";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    padding: "48px 0 48px 0",
    width: "100vw",
    minHeight: "calc(100vh - 80px)",
    // marginTop: "80px",
    // minHeight:'calc(100vh - 80px)',
    background:
      "linear-gradient(217deg, rgba(255,194,0,0.4962185557816877) 2%, rgba(255,255,255,1) 25%, rgba(255,255,255,0.4962185557816877) 81%, rgba(144,81,177,1) 98%)",
    [theme.breakpoints.up("sm")]: {
      // marginTop: "0",
    },
  },
  boxContainer: {
    background: "#fff",
    width: "80%",
    height: "auto",
    minHeight: "70vh",
    borderRadius: "42px",
    padding: "20px",
    alignItems: "center",
    gap: "50px",
    boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.5)",
    [theme.breakpoints.up("md")]: {
      padding: "48px 20px 48px 20px",
    },
  },
  titleContainer: {
    // margin: "30px 0",
  },
  title: {
    font: "28px Fredoka One ",
    margin: "0",
    textAlign: "center",
    color: " #DC5EA6",
    [theme.breakpoints.up("md")]: {
      fontSize: "36px",
    },
    [theme.breakpoints.up("xl")]: {
      fontSize: "42px",
    },
  },
  link: {
    cursor: "pointer",
    textDecoration: "none",
    // height: "31px",
    // width: "190px",
  },
  studentListContainer: {
    gap: "20px",
  },

  formContainer: {
    display: "flex",
    flexDirection: "column",
    width: "80%",
    boxSizing: "border-box",
    maxWidth: "560px",
    alignItems: "flex-start",
    justifyItems: "center",
    gap: "30px",
    [theme.breakpoints.up("lg")]: {
      width: "100%",
    },
  },
  formItemContainer: {
    display: "grid",
    rowGap: "10px",
  },
  input: {
    width: "100%",
    height: "40px",
    paddingLeft: "27px",
    paddingRight: "27px",
    borderRadius: "61px",
    boxSizing: "border-box",
    // border: " solid #1D2AAD 3px ",
    border: "none",
    background: "#FFFFFF",
    font: "Helvetica",
    fontSize: "16px",
    //    width: '449px',
    // height: '72px',
    // margin: '43px 139px 19px 66px',
    // padding: '26px 24px 25px',
    backgroundColor: "#f0f0f0",
    "&:focus": {
      outline: "none",
    },
    [theme.breakpoints.up("lg")]: {
      // height: "60px",
      // maxWidth: "449px",
      fontSize: "18px",
    },
  },
  label: {
    fontWeight: "bold",
    fontFamily: "Lato, sans-serif",
  },
}));

const CrearCodigos = () => {
  const classes = useStyles();

  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [modalMessageOpen, setModalMessageOpen] = useState(false);
  const [confirmChangesResult, setConfirmChangesResult] = useState("");
  const [formData, setFormData] = useState({});
  const [responseData, setResponseData] = useState({});
  const [loadCompleteModal, setLoadCompleteModal] = useState(false);
  const [codeTypes, setCodeTypes] = useState([]);
  const [profileTypes, setProfileTypes] = useState([]);
  const [useTypes, setUseTypes] = useState([]);

  const handleCloseConfirmationModal = () => {
    setConfirmationModalOpen(!confirmationModalOpen);
  };
  const handleCloseMessageModal = () => {
    setModalMessageOpen(!modalMessageOpen);
  };

  const confirmChanges = () => {
    // if (formData?.nombre && formData?.apellidoPaterno && formData?.apellidoMaterno) {
    //   setLoading(true);
    //   const body = {
    //     id_usuario: user.userId,
    //     nombre_escuela: formData?.nombre_escuela,
    //     nombre_encargado: formData?.nombre_encargado,
    //     correo_encargado: formData?.correo_encargado,
    //   };
    //   axios
    //     .put("/listado/nombre", body)
    //     .then((response) => {
    //       if (response.status === 200) {
    //         dispatch({
    //           type: types.perfilChanges,
    //           payload: {
    //             // name,
    //             // lastName,
    //             // secondLastName,
    //           },
    //         });
    //         setLoading(false);
    //         setConfirmationModalOpen(false);
    //         setModalMessageOpen(true);
    //         setConfirmChangesResult("Los datos se han actualizado correctamente");
    //       }
    //     })
    //     .catch((error) => {
    //       setConfirmationModalOpen(false);
    //       setLoading(false);
    //       setModalMessageOpen(true);
    //       setConfirmChangesResult(
    //         "Ha ocurrido un error al actualizar los datos. Intente de nuevo más tarde."
    //       );
    //     });
    // } else {
    //   setConfirmationModalOpen(false);
    //   setModalMessageOpen(true);
    //   setConfirmChangesResult("usernameAsegúrese de llenar todos los datos");
    // }
  };

  const handleForm = (e) => {
    if (e.target.name === "tipo_codigo") {
      setFormData({
        ...formData,
        tipo_uso: "",
        perfil: "",
        id_escuela: "",
        grado: "",
        grupo: "",
        cantidad_codigos_especificos: 1,
        [e.target.name.toLowerCase()]: e.target.value,
      });
    } else if (e.target.name === "perfil") {
      setFormData({
        ...formData,
        cantidad_codigos_especificos: 1,
        [e.target.name.toLowerCase()]: e.target.value,
      });
    } else {
      setFormData({
        ...formData,
        [e.target.name.toLowerCase()]: e.target.value,
      });
    }
  };

  const sendErrorToAdd = () => {
    setConfirmationModalOpen(false);
    setModalMessageOpen(true);
    setConfirmChangesResult(
      "Asegúrese de llenar todos los datos del estudiante"
    );
  };

  const handleResponseModalData = (data, error) => {
    if (error) {
      setModalMessageOpen(true);
      setConfirmChangesResult(
        data || "Ha ocurrido un error, inténtelo de nuevo más tarde"
      );
    } else {
      setResponseData(data);
      setLoadCompleteModal(true);
      setFormData({});
    }
  };

  useEffect(() => {
    axios
      .get(`/catalogo/tipo/rol`)
      .then((response) => {
        if (response.status === 200) {
          // console.log(response, "response");
          const list = response.data.resultado?.tipo_usos;
          setProfileTypes(list);
        }
      })
      .catch((error) => {
        console.log(error, "error");
      });

    axios
      .get(`/catalogo/tipo/uso`)
      .then((response) => {
        if (response.status === 200) {
          // console.log(response, "response");
          const list = response.data.resultado?.tipo_usos;
          setUseTypes(list);
        }
      })
      .catch((error) => {
        console.log(error, "error");
      });

    axios
      .get(`/catalogo/tipo/codigo`)
      .then((response) => {
        if (response.status === 200) {
          // console.log(response, "response");
          const list = response.data.resultado?.tipo_codigos;
          setCodeTypes(list);
        }
      })
      .catch((error) => {
        console.log(error, "error");
      });

    return () => {};
  }, []);

  return (
    <ContainerAdminPearson>
      <Grid
        container
        item
        sm={12}
        alignItems="center"
        justifyContent="center"
        className={classes.mainContainer}
      >
        <Grid
          container
          item
          direction="column"
          className={classes.boxContainer}
        >
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="center"
          >
            <Grid
              container
              item
              alignItems="center"
              justifyContent="center"
              className={classes.titleContainer}
            >
              <h2 className={classes.title}>Crear códigos</h2>
            </Grid>
          </Grid>
          <form
            className={classes.formContainer}
            onSubmit={(e) => {
              e.preventDefault();
              // addUser();
            }}
          >
            <SelectTipo
              fieldName="tipo_codigo"
              label="Tipo de código"
              formData={formData}
              list={codeTypes}
              handleForm={handleForm}
              type="codigo"
            />
            {formData?.tipo_codigo === 1 && (
              <CodigoEspecifico
                formData={formData}
                handleForm={handleForm}
                setFormData={setFormData}
                profileTypes={profileTypes}
                useTypes={useTypes}
                sendErrorToAdd={sendErrorToAdd}
                handleResponseModalData={handleResponseModalData}
              />
            )}
            {formData?.tipo_codigo === 2 && (
              <CodigoGenerico
                formData={formData}
                handleForm={handleForm}
                setFormData={setFormData}
                profileTypes={profileTypes}
                useTypes={useTypes}
                handleResponseModalData={handleResponseModalData}
              />
            )}
          </form>
        </Grid>
      </Grid>
      <ModalConfirm
        open={confirmationModalOpen}
        handleClose={handleCloseConfirmationModal}
        handleConfirm={confirmChanges}
      />
      <ModalCodigoCreado
        open={loadCompleteModal}
        data={responseData}
        handleClose={() => {
          setLoadCompleteModal(!loadCompleteModal);
        }}
      />
      <ModalMessage
        open={modalMessageOpen}
        handleClose={handleCloseMessageModal}
        title={confirmChangesResult}
      />
    </ContainerAdminPearson>
  );
};

export default CrearCodigos;
