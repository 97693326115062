import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
    Contenedor:{
       background:'#E9FBF9',
       width:'100vw',
       [theme.breakpoints.down(1161)]:{
           background:'none',
       },
       [theme.breakpoints.down(600)]:{
           marginTop: '79.51px',
       }
   },
  ContainerInfoLectura: {
    position: "relative",
    height: "86px",
    [theme.breakpoints.down(502)]:{
        height: "43px",
    },
    background: "#76D4C3",
    "& .BarraInfoLectura": {
      height: "86px",
      background: `Url(https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Barras/Azul.png)`,
        [theme.breakpoints.down(502)]:{
            height: "43px",
        },
    },
    "& .InfoLectura": {
      position: "absolute",
      height: "86px",
      width: "219px",
      boxSizing: "border-box",
      padding: "17.2px 0",
      background: "#FAFAFA",
      borderRadius: "50px",
      boxShadow: "2px 2px 2px 2px #00000029",
      zIndex: "4",
        [theme.breakpoints.down(502)]:{
            height: "43px",
            width: "109px",
            padding: "8.6px 0",
            borderRadius: "25px",
        },
    },
    "& .InfoLectura:before": {
      position: "absolute",
      display: "flex",
      boxSizing: "border-box",
      padding: "0 0 0 10px",
      justifyContent: "center",
      alignItems: "center",
      height: "39.88px",
      width: "39.88px",
      borderRadius: "50%",
      content: '""',
      boxShadow: "3px 0px .5px 0px #00000029",
      background: "#FAFAFA",
      left: "90px",
      top: "65px",
      transform: "rotate(0.25turn)",
      [theme.breakpoints.down(502)]:{
        left: "45px",
        top: "30px",
        height: "20.88px",
        width: "20.88px",
        },
    },
    "& .InfoLectura:after": {
      content: '""',
      height: "15px",
      width: "15px",
      borderTop: "2px solid #20A698",
      borderLeft: "2px solid #20A698",
      position: "absolute",
      bottom: "-10px",
      left: "Calc(50% - 8px)",
      transform: "rotate(0.625turn)",
      [theme.breakpoints.down(502)]:{
        height: "10px",
        width: "10px",
        left: "Calc(50% - 4.5px)",
        bottom: "-3px",
        borderTop: "1px solid #20A698",
        borderLeft: "1px solid #20A698",
        },
    },
    "& .Sesion": {
      borderLeft: "2px solid #D8D8D8",
    },
    "& .Dato": {
      margin: "0 0 5px 0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#20A698",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        margin: "0 0 2.5px 0",
        },
    },
    "& .Caracter": {
      margin: "0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#000000",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        },
    },
  },
   ContenedorLectura:{
       background:'#fff',
       width:'1160px',
       height:'100%',
       padding:'60px 168px 35px ',
       [theme.breakpoints.down(1161)]:{
           width:'502px',
           padding:'60px 0 0 0',
         },
                [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
       '& .ContainerDerechos':{
           bottom:'80px',
           [theme.breakpoints.down(1161)]:{
               display:'none',
           },
       },
       '& .Derechos':{
           textAlign:"center",
           margin:"0px",
           font:" 15px lato",
           color:"#717476",
       },
       '& .Escritorio':{
           display:'block',
           [theme.breakpoints.down(1161)]:{
               display:'none'
           },
       },
       '& .Tablet':{
           display:'none',
           [theme.breakpoints.down(1161)]:{
               display:'block'
           },
       }
   },
    ContainerTitulo:{
        margin:'0 0 46px 0',
        '& .Titulo':{
            font:'48px fredoka one',
            letterSpacing: '0px',
            color: '#0C90D6',
            margin:'0',
            textAlign:'center',
            [theme.breakpoints.down(1161)]:{
                font:'43px fredoka one',
                lineHeight:'52px',
            },
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            lineHeight : '30px',
            margin:'0 0 36px 0 ',
            font:'22px lato',
            color:'#000',
            [theme.breakpoints.down(1161)]:{
                font:'18px lato',
                lineHeight :'28px',
                letterSpacing: '0.17px',
            },
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .Esp':{
            margin:'0',
        },
        '& .SubTitulo':{
            font:'bold 30px lato',
            letterSpacing:'.28px',
            lineHeight:'30px',
            color:'#FF0000',
            margin:'0',
            [theme.breakpoints.down(1161)]:{
                font:'bold 22px lato',
                lineHeight :'24px',
                letterSpacing: '0.21px',
            },
            [theme.breakpoints.down(502)]:{
                font:'bold 18px lato',
                lineHeight :'20px',
                letterSpacing: '0.16px',
            },
        },
        '& .TextImg':{
            margin:'24px 0 0 0',
            font:'20px lato',
            alignSelf:'flex-start',
            color:'#717476',
            letterSpacing:'.19px',
            lineHeight:'30px',
            [theme.breakpoints.down(1161)]:{
                font:' 16px lato',
                lineHeight :'25px',
                letterSpacing: '0.15px',
            },
            [theme.breakpoints.down(502)]:{
                font:' 11px lato',
                lineHeight :'14px',
                letterSpacing: '0.10px',
            },
        },
        '& .Imagen':{
            [theme.breakpoints.down(1161)]:{
                width:'100%'
            },
        },
        '& .Imagen2':{
            [theme.breakpoints.down(1161)]:{
                width:'707px'
            },
            [theme.breakpoints.down(502)]:{
                width:'100%'
            },
        },

    },
    

}));

const Las_protestas_por_el_cambio_climático = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
             <Grid container item justifyContent="center" className={classes.ContainerInfoLectura}>
               <Grid container className="BarraInfoLectura"></Grid>
               <Grid container item className="InfoLectura">
                    <Grid container item direction="column" xs={6} justifyContent="center">
                        <p className="Dato">Grado</p>
                        <p className="Caracter">2</p>
                    </Grid>
                    <Grid container item direction="column" xs={6} justifyContent="center" className="Sesion">
                        <p className="Dato">Sesión</p>
                        <p className="Caracter">37</p>
                    </Grid>
               </Grid>
            </Grid>

            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >
                    Las protestas <br/> 
                    por el cambio climático
                    </h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                            <h3 className='SubTitulo'>Para entender qué está sucediendo</h3><br/> 

                            El <em>cambio climático</em> es un proceso de transformación en la Tierra ocasionado por el 
                            descuido del ser humano y su comportamiento con respecto al entorno. Por ejemplo: 
                            la contaminación, la explotación inmoderada de la tierra o la destrucción de 
                            ecosistemas para construir empresas o fraccionamientos que no aportan nada al 
                            cuidado del planeta. <br/><br/>
                            
                            Actualmente, el cambio climático es la mayor amenaza medioambiental a la que se 
                            enfrenta la humanidad. Es por eso que niños y jóvenes salen a las calles exigiendo 
                            respuestas desde hace algunos años. Los países han propuesto soluciones para 
                            intentar corregir el rumbo. <br/><br/>
                            
                            <h3 className='SubTitulo'>Algunas acciones recientes respecto al cambio climático</h3><br/> 
                            
                            En los últimos años, niños y jóvenes han participado en el movimiento Fridays for 
                            Future (Los Viernes por el Futuro), que consiste en una serie de acciones e 
                            intervenciones permanentes para visibilizar el problema ante los gobiernos y exigir 
                            soluciones. <br/><br/>
                            
                            Este movimiento demanda acciones para combatir el cambio climático. Por ejemplo, 
                            exige que se escuche a los científicos y que se tomen decisiones basadas en su 
                            conocimiento. También pide que se controle el aumento de la temperatura y que los 
                            gobiernos cumplan acuerdos internacionales que enfrenten el cambio climático.<br/><br/>

                            Durante 2019, Fridays for Future organizó protestas en todo el mundo, conocidas 
                            como <em>huelgas globales por el cambio climático</em>. En ellas participaron miles de personas
                            en distintas ciudades y pueblos. Muchos de los asistentes fueron niños preocupados
                            por su futuro y por el planeta que exigen que se ponga un alto al daño al 
                            medioambiente. <br/><br/>
                            
                            En Latinoamérica han participado jóvenes activistas que se han hecho visibles por 
                            sus intervenciones públicas, como Xiye Bastida, Arabel Alí Mendoza, Yareni Pérez y 
                            Monse González. Si quieres conocer más sobre su labor, puedes buscar sus nombres 
                            en la red.
                        </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <Grid container >
                            <img className='Imagen' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img2do/Sesion37/1.png"  alt="Tarjeta" />
                        </Grid>
                        <Grid container >
                            <p className='TextImg'>Gracias a las protestas sociales pacíficas, algunos gobiernos escuchan las demandas de la 
                            sociedad y buscan tomar acciones.</p>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} >
                        <p className="Lectura Esp">
                            Como consecuencia de la pandemia provocada por el Covid-19, las actividades del 
                            movimiento disminuyeron en 2020 y 2021, pero hubo varias protestas virtuales. 
                            También se hicieron algunas manifestaciones presenciales cuidando las medidas 
                            sanitarias correspondientes.
                        </p>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent='center'>
                    <img className='Imagen2' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img2do/Sesion37/2.png"  alt="" />
                    <Grid container justifyContent='center'>
                        <p className='TextImg'>Recuento de acciones de la protesta global por el cambio climático.</p>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} >
                        <p className="Lectura ">
                            ¿Qué opinas de este movimiento? ¿Habías escuchado hablar de él o del cambio  
                            climático? ¿Qué acciones podrías hacer tú en tu entorno inmediato para colaborar? 
                        </p>
                </Grid>
                
                <Grid container item xs={12} justifyContent="center" className="ContainerDerechos">
                    <p className="Derechos">
                        © 2022. Todos los derechos reservados, Pearson, Circuito de lectores.
                    </p>                
                </Grid>
        </Grid>
    </Grid>
    );
}
 
export default Las_protestas_por_el_cambio_climático;
