import React, { useContext } from "react";
import { Hidden, Button, makeStyles, Grid } from "@material-ui/core";
import { Link } from "react-router-dom";
// import Barra from "./assets/Barra.png";

import SaveIcon from "@mui/icons-material/Save";
import DrawerContext from "../../../../usability/DrawerContext";

import Notas from "../../../imgs/Notas.png";
import Dibujar from "../../../imgs/Dibujar.png";
import Marcatextos from "../../../imgs/Marcatextos.png";
import Zoom from "../../../imgs/Zoom.png";
import ZoomOut from "../../../imgs/ZoomOut.png";
import Deshacer from "../../../imgs/Deshacer.png";
import Pizarra from "../../../imgs/Pizarra.png";
import Limpiar from "../../../imgs/Limpiar.png";
import DrawTools from "./DrawTools";
import MarkTools from "./MarkTools";
// import Whiteboard from "./Whiteboard";

const useStyles = makeStyles((theme) => ({
  continuedivContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    alignContent: "center",
    width: "auto",
  },
  continuediv: {
    textTransform: "none",
    height: "40px",
    width: "130px",
    background: "#7A54BA",
    boxShadow: "0px 3px 6px #dfdfdf",
    font: "bold 14px lato ",
    borderRadius: "61px",
    color: "#FFFFFF",
    margin: "0",
    [theme.breakpoints.down(600)]: {
      height: "30px",
      width: "100px",
      font: "bold 10px lato ",
    },
    "&:hover": {
      background: "#dd78fb",
    },
  },
  containerToolBar: {
    zIndex: 999,
    boxShadow: "0px 2px 5px #00000029",
    // height: "10%",

    background: "#fff",
    height: "90px",
    position: "fixed",
    bottom: "0",
    width: "100%",
    ////////////

    display: "flex",
    flexWrap: "nowrap",
    flexDirection: "row",
    alignContent: "center",
    justifyContent: "center",
    alignItems: "center",
    gap: "20px",
    [theme.breakpoints.up("md")]: {
      justifyContent: "space-evenly",
      gap: "0px",
      paddingLeft: "4%",
    },
  },

  containerTools: {
    background: "#fff",
    height: "80px",
    width: "auto",
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
    flexWrap: "nowrap",
    gap: "1%",

    [theme.breakpoints.up("md")]: {
      width: "auto",
      display: "flex",
      justifyContent: "center",
      alignContent: "center",
      flexWrap: "nowrap",
      gap: "30px",
    },
  },

  divContainer: {
    height: "100%",
    position: "relative",
    alignItems: "center",
    [theme.breakpoints.down(1161)]: {
      height: "75px",
    },
  },

  divToolBar: {
    height: "50px",
    width: "50px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "15px",
    border: "#EFEFEF 3px solid",
    backgroundColor: "#fff",
    cursor: "pointer",
    padding: "0",
    [theme.breakpoints.down(600)]: {
      height: "30px",
      width: "30px",
    },
    [theme.breakpoints.down(400)]: {
      height: "100%",
      width: "25px",
      borderRadius: "0",
    },
    "&:hover": {
      background: "#8AE9DF",
      border: "#F1CB5A 3px solid",
    },
    "& .Img": {
      height: "30px",
      width: "30px",
      [theme.breakpoints.down(600)]: {
        height: "20px",
        width: "20px",
      },
    },
  },

  Nota: {
    display: "flex",
    "&:hover": {
      "&:before": {
        textTransform: "none",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "absolute",
        content: '"Agregar notas"',
        textAlign: "center",
        bottom: "80px",
        width: "80px",
        background: "#fff",
        font: "bold 14px lato",
      },
    },
  },
  Dibujar: {
    display: "flex",
    "&:hover": {
      "&:before": {
        textTransform: "none",
        display: (props) => (props.openDrawTools ? "none" : "flex"),
        justifyContent: "center",
        alignItems: "center",
        position: "absolute",
        content: '"Dibujar"',
        textAlign: "center",
        bottom: "80px",
        width: "80px",
        background: "#fff",
        font: "bold 14px lato",
      },
    },
  },
  Marcatextos: {
    display: "flex",
    "&:hover": {
      "&:before": {
        textTransform: "none",
        display: (props) => (props.openMarkTools ? "none" : "flex"),
        justifyContent: "center",
        alignItems: "center",
        position: "absolute",
        content: '"Marcar texto"',
        textAlign: "center",
        bottom: "80px",
        width: "80px",
        background: "#fff",
        font: "bold 14px lato",
      },
    },
  },
  Zoom: {
    display: "flex",
    "&:hover": {
      "&:before": {
        textTransform: "none",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "absolute",
        content: '"Zoom"',
        textAlign: "center",
        bottom: "80px",
        width: "80px",
        background: "#fff",
        font: "bold 14px lato",
      },
    },
  },
  Deshacer: {
    display: "flex",
    "&:hover": {
      "&:before": {
        textTransform: "none",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "absolute",
        content: '"Deshacer"',
        textAlign: "center",
        bottom: "80px",
        width: "80px",
        background: "#fff",
        font: "bold 14px lato",
      },
    },
  },
  Pizarra: {
    display: "flex",
    "&:hover": {
      "&:before": {
        textTransform: "none",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "absolute",
        content: '"Pizarra"',
        textAlign: "center",
        bottom: "80px",
        width: "80px",
        background: "#fff",
        font: "bold 14px lato",
      },
      [theme.breakpoints.down(1280)]: {
        display: "none",
      },
    },
  },
  Limpiar: {
    display: "flex",
    "&:hover": {
      "&:before": {
        textTransform: "none",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "absolute",
        content: '"Limpiar pantalla"',
        textAlign: "center",
        bottom: "80px",
        width: "80px",
        background: "#fff",
        font: "bold 14px lato",
      },
    },
  },

  Guardar: {
    display: "flex",
    "&:hover": {
      "&:before": {
        textTransform: "none",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "absolute",
        content: '"Guardar"',
        textAlign: "center",
        bottom: "80px",
        width: "80px",
        background: "#fff",
        font: "bold 14px lato",
      },
    },
  },

  linkdiv: {
    textDecoration: "none",
  },
}));

const ToolBar = (props) => {
  const { toActivity } = props;
  const {
    // addNewWhiteboard,

    addNewNote,
    handleSave,
    handleErase,
    undo,
    openDrawTools,
    handleDrawTools,
    openMarkTools,
    handleMarkToolsOpen,
    disabledZoom,
    handleZoom,
    handlePizarra,
    pizarraCanvas,
  } = useContext(DrawerContext);
  const classes = useStyles({ openDrawTools, openMarkTools });

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  // const handleSave = () => {

  // }
  // const [openWhiteboard, setOpenWhiteboard] = React.useState(false);

  // const handleClose = () => {
  //   setOpenWhiteboard(!openWhiteboard);
  // };

  // const addNewWhiteboard = () => {
  //   console.log(document.getElementById("pizarra"))
  // }
  const whiteboardSelected = () => {
    if (pizarraCanvas) {
      return {
        background: "#8AE9DF",
        border: "#F1CB5A 3px solid",
      };
    }
  };

  return (
    <Grid
      container
      item
      direction="row"
      justifyContent="center"
      alignItems="center"
      className={classes.containerToolBar}
    >
      <Grid
        container
        item
        direction="row"
        justifyContent="center"
        alignItems="center"
        className={classes.containerTools}
      >
        <Grid container item className={classes.divContainer}>
          <button
            variant="outlined"
            className={`${classes.divToolBar} ${classes.Nota}`}
            onClick={addNewNote}
          >
            <img className="Img" alt={"notes"} src={Notas} />
          </button>
        </Grid>

        <Hidden mdDown>
          <Grid container item className={classes.divContainer}>
            <DrawTools />
            <button
              variant="outlined"
              className={`${classes.divToolBar} ${classes.Dibujar}`}
              onClick={handleDrawTools}
              disabled={!disabledZoom}
            >
              <img className="Img" alt={"draw"} src={Dibujar} />
            </button>
          </Grid>
        </Hidden>

        <Hidden mdDown>
          <Grid container item className={classes.divContainer}>
            <MarkTools />
            <button
              variant="outlined"
              className={`${classes.divToolBar} ${classes.Marcatextos}`}
              onClick={handleMarkToolsOpen}
              disabled={!disabledZoom}
            >
              <img className="Img" alt={"marcatextos"} src={Marcatextos} />
            </button>
          </Grid>
        </Hidden>

        <Grid container item className={classes.divContainer}>
          <button
            variant="outlined"
            className={`${classes.divToolBar} ${classes.Zoom}`}
            onClick={() => {
              if (disabledZoom) {
                handleZoom();
              } else {
                handleZoom();
              }
            }}
          >
            <img
              className="Img"
              alt={"zoom"}
              src={disabledZoom ? Zoom : ZoomOut}
            />
          </button>
        </Grid>

        <Grid container item className={classes.divContainer}>
          <button
            variant="outlined"
            className={`${classes.divToolBar} ${classes.Deshacer}`}
            onClick={undo}
          >
            <img className="Img" alt={"deshacer"} src={Deshacer} />
          </button>
        </Grid>
        <Hidden mdDown>
          <Grid
            container
            item
            alignItems="center"
            className={classes.divContainer}
          >
            <button
              variant="outlined"
              className={`${classes.divToolBar} ${classes.Pizarra}`}
              onClick={handlePizarra}
              style={whiteboardSelected()}
            >
              <img className="Pizarra" alt={"pizarra"} src={Pizarra} />
            </button>
          </Grid>
        </Hidden>
        <Grid container item className={classes.divContainer}>
          <button
            variant="outlined"
            className={`${classes.divToolBar} ${classes.Limpiar}`}
            onClick={handleErase}
          >
            <img className="Img" alt={"limpiar"} src={Limpiar} />
          </button>
        </Grid>

        <Grid container item className={classes.divContainer}>
          <button
            variant="outlined"
            className={`${classes.divToolBar} ${classes.Guardar}`}
            onClick={handleSave}
          >
            <SaveIcon className="Img" sx={{ color: "#20A698" }} />
          </button>
        </Grid>
      </Grid>

      <Grid
        container
        item
        justifyContent="flex-start"
        alignItems="center"
        className={classes.continuedivContainer}
      >
        <Link className={classes.linkdiv} to={toActivity}>
          <Button id="BotonIrActividad" className={classes.continuediv}>Ir a actividad ➜</Button>
        </Link>
      </Grid>
    </Grid>
  );
};

export default ToolBar;
