import React from "react";
import { Grid, makeStyles } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Radio from "@material-ui/core/Radio";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Redirect, useHistory } from "react-router-dom";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import FiberManualRecordSharpIcon from "@material-ui/icons/FiberManualRecordSharp";

import "animate.css";

const useStyles = makeStyles((theme) => ({
  MuiDialogContainer: {
    borderRadius: "61px",
    height: "80%",
    maxWidth: "80%",
    backgroundColor: "#FEFAF1",
    border: "#707070 2px solid",
    backdropFilter: "blur(3px)",
  },
  selectedStyle: {
    border: "#40DAC9 5px solid",
    backgroundColor: "#DDF6FA",
    width: "100%",
    borderRadius: "5px",
  },
  continueButton: {
    marginTop: "2%",
    marginLeft: "5%",
    textTransform: "none",
    height: "62px",
    width: "279px",
    background: "#7A54BA",
    boxShadow: "0px 0px 15px 20px white, 0px 0px 0vw 60vw rgb(0 0 0 / 50%)",
    borderRadius: "61px",
    color: "#FFFFFF",
    font: "bold 20px lato ",
    "&:hover": {
      background: "#dd78fb",
    },
    [theme.breakpoints.down(1368)]: {
      height: "52px",
      width: "229px",
    },
    [theme.breakpoints.down(1068)]: {
      height: "52px",
      width: "60%",
    },
    [theme.breakpoints.down(769)]: {
      width: "56.5%",
    },
  },
  answeringText: {
    textAlign: "Left",
    font: "22px lato",
    letterSpacing: " 0.21px",
    margin: "8px",
    [theme.breakpoints.down(1370)]: {
      font: "18px lato",
      margin: "8px 8px 8px 8px",
    },
    [theme.breakpoints.down(1280)]: {
      font: "16px lato",
    },
    [theme.breakpoints.down(650)]: {
      font: "14px lato",
      margin: "4px",
    },
  },
  feedbackText: {
    width: "85%",
    font: " 22px lato",
    letterSpacing: " 0.21px",
    textAlign: "left",
    padding: "10px",
    [theme.breakpoints.down(1370)]: {
      font: "18px lato",
      paddingLeft: "0px",
    },
    [theme.breakpoints.down(650)]: {
      font: "14px lato",
    },
  },
  questionTittle: {
    font: "30px Fredoka One",
    color: "#707070",
    height: "10px",
    marginTop: "10px",
    letterSpacing: " 0.23px",
    [theme.breakpoints.down(1370)]: {
      marginTop: "0",
    },
    [theme.breakpoints.down(475)]: {
      font: "24px Fredoka One",
    },
    [theme.breakpoints.down(435)]: {
      font: "22px Fredoka One",
    },
  },
  feedbackTittle: {
    font: "30px Fredoka One",
    color: "#1DA698",
    marginTop: "10px",
    letterSpacing: " 0.23px",
    height: "auto",
    marginLeft: "5%",
    [theme.breakpoints.down(1370)]: {
      marginTop: "0",
    },
    [theme.breakpoints.down(960)]: {
      marginLeft: "0",
    },
    [theme.breakpoints.down(475)]: {
      font: "24px Fredoka One",
      marginTop: "8px",
    },
    [theme.breakpoints.down(435)]: {
      font: "22px Fredoka One",
    },
  },
  feedbackBox: {
    border: "#80D7C6 3px solid",
    backgroundColor: "#FFFFFF",
    borderRadius: "59px",
    marginTop: "1%",
    minHeight: "80%",
  },
  correctIcon: {
    width: "40px",
    height: "40px",
  },
  primaryIconFeedback: {
    color: "#1DC491",
  },
  sizeLargeIcon: {
    fontSize: "50px",
    [theme.breakpoints.down(1440)]: {
      fontSize: "40px",
    },
    [theme.breakpoints.down(480)]: {
      fontSize: "30px",
    },
    // [theme.breakpoints.down(850)]:{
    //   fontSize: "25px",
    // },
  },
  questionAndAnswer: {
    width: "80%",
  },
  questionContainer: {
    minWidth: "10%",
    margin: "25px 0 5% 0",
    [theme.breakpoints.down(1370)]: {
      margin: "4% 0 5% 0",
    },
    [theme.breakpoints.down(475)]: {
      margin: "0",
    },
  },
  questionMargin: {
    marginBottom: "4%",
  },
  mainContainer: {
    height: "70%",
  },
  buttonContainer: {
    height: "30%",
    position: "relative",
  },
  iconsContainer: {
    paddingRight: "5%",
    margin: "1% 0",
    justifyContent: "flex-end",
    [theme.breakpoints.down(960)]: {
      justifyContent: "center",
    },
  },
  MuiDialogTitleContainer: {
    paddingBottom: "0px",
  },
  selectedimageStyle: {
    border: "#80D7C6 5px solid",
    backgroundColor: "white",
    width: "252px",
    height: "227px",
    borderRadius: "5px",
    [theme.breakpoints.down(1571)]: {
      width: "242px",
      height: "217px",
    },
    [theme.breakpoints.down(1441)]: {
      width: "212px",
      height: "207px",
    },
    [theme.breakpoints.down(1401)]: {
      width: "172px",
      height: "157px",
    },
    [theme.breakpoints.down(1350)]: {
      width: "162px",
      height: "147px",
    },
    [theme.breakpoints.down(960)]: {
      width: "172px",
      height: "157px",
    },
    [theme.breakpoints.down(361)]: {
      width: "152px",
      height: "137px",
    },
  },
  primaryIcon: {
    color: "#4BBCF8",
  },
  primaryBigIconFeedback: {
    color: "#1DC491",
  },
  imageContainer: {
    margin: "0%",
  },
  imageStyled: {
    maxHeight: "100%",
    maxWidth: "100%",
  },
  MuiDialogContent: {
    padding: "8px 24px",
    overflowY: "unset",
    [theme.breakpoints.down(500)]: {
      padding: "8px 0px",
    },
  },
  radioIcon: {
    fontSize: "28px",
    [theme.breakpoints.down(1440)]: {
      fontSize: "24px",
    },
    [theme.breakpoints.down(625)]: {
      padding: "0px",
      fontSize: "20px",
    },
    [theme.breakpoints.down(400)]: {
      padding: "0px",
      fontSize: "15px",
    },
  },
  imgGrettings: {
    with: "40px",
    height: "40px",
  },
  dialogButtonContinuar: {
    backgroundColor: "#3f43b3",
    fontFamily: "Lato",
    letterSpacing: "0.36px",
    fontSize: "20px",
    color: "#ffffff",
    position: "absolute",
    // top: "-45px",
    minWidth: "480px",
    right: "100%",
    width: "auto",
    maxWidth: "480px",
    padding: "24px",
    boxSizing: "border-box",
    borderRadius: "21.25px",
    zIndex: 999,

    "&:before": {
      // display: (props) => (props.openMenuInDesktop ? "none" : "flex"),
      display: "flex",
      zIndex: 1,
      textTransform: "none",
      justifyContent: "center",
      alignItems: "center",
      // borderRadius: "25px",
      position: "absolute",
      content: '""',
      border: "15px solid",
      borderTopColor: "transparent",
      borderLeftColor: "#3f43b3",
      borderBottomColor: "transparent",
      borderRightColor: "transparent",
      font: "bold 14px lato",
      top: "calc(50% - 15px)",
      right: "-28px",
    },
  },
}));

const CorrectFeedback = (props) => {
  const {
    open,
    setOpen,
    feedback,
    answerText,
    setStep,
    step,
    feedBackImage,
    questionId,
    imageList,
    remedialFinal,
    activityNumber,
    remedialNumber,
    stopQuestion,
    setStopQuestion,
    tryNumber,
  } = props;
  const classes = useStyles();
  const [finished, setFinished] = React.useState(false);

  const history = useHistory();

  React.useEffect(() => {
    if (stopQuestion) {
      return setStep(Number(step + 1));
    }
  }, [stopQuestion]);

  const finalStep = () => {
    setOpen(false);
    setFinished(true);
    setStopQuestion(true);
    history.push({
      pathname: `/guia/resultado/${activityNumber}/${tryNumber}`,
    });
  };

  return (
    <Dialog
      open={open}
      classes={{ paper: classes.MuiDialogContainer }}
      disableEscapeKeyDown
    >
      <DialogTitle >
        <Grid
          container
          direction="row"
          alignItems="center"
          className={classes.iconsContainer}
        >
          <Grid>
          </Grid>
          <Grid
            container
            item
            xs={2}
            sm={1}
            justifyContent="center"
            alignItems="center"
          >
            <CheckCircleIcon
              color="primary"
              fontSize="large"
              classes={{
                colorPrimary: classes.primaryBigIconFeedback,
                fontSizeLarge: classes.sizeLargeIcon,
              }}
            />
          </Grid>
          <Grid
            container
            item
            xs={2}
            sm={1}
            justifyContent="center"
            alignItems="center"
          >
            <CancelIcon
              color="disabled"
              fontSize="large"
              classes={{ fontSizeLarge: classes.sizeLargeIcon }}
            />
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent classes={{ root: classes.MuiDialogContent }}>
        <Grid container item direction="row" className={classes.mainContainer}>
          {/* <Grid item xs={1}></Grid> */}
          <Grid
            container
            item
            direction="column"
            xs={12}
            md={7}
            justifyContent="flex-start"
            alignItems="center"
          >
            <Grid
              container
              item
              justifyContent="center"
              alignItems="center"
              className={classes.questionAndAnswer}
            >
              <Grid
                container
                item
                justifyContent="flex-start"
                alignItems="center"
              >
                <h2 className={classes.questionTittle}>Pregunta</h2>
              </Grid>
              <Grid
                container
                item
                justifyContent="flex-start"
                alignItems="flex-start"
                className={classes.questionContainer}
              >
                {/* <p 
                  dangerouslySetInnerHTML={{__html: `${step+1}. ${pregunta}`}}
                  className={classes.questionText}
                >
                </p> */}
                <Grid container item className={classes.questionMargin}>
                  {props.pregunta}
                </Grid>
                <br />
                <br />
                {feedBackImage ? (
                  <Grid
                    container
                    item
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Grid
                      container
                      item
                      md={6}
                      justifyContent="center"
                      alignItems="center"
                      className={classes.imageContainer}
                    >
                      <Grid
                        container
                        item
                        className={classes.selectedimageStyle}
                      >
                        <img
                          alt={questionId}
                          src={imageList[questionId] || "#"}
                          className={classes.imageStyled}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                ) : (
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    className={classes.selectedStyle}
                  >
                    <Grid item xs={1}>
                      <Radio
                        checked={true}
                        value="e"
                        name="answer-selected-feedback"
                        inputProps={{ "aria-label": "E" }}
                        classes={{ root: classes.radioIcon }}
                        checkedIcon={
                          <FiberManualRecordSharpIcon
                            color="primary"
                            classes={{
                              colorPrimary: classes.primaryIconFeedback,
                            }}
                            // classes={{colorPrimary: classes.primaryIconFeedback, fontSizeLarge: classes.sizeLargeIcon}}
                          />
                        }
                      />
                    </Grid>
                    <Grid item xs={11}>
                      {/* <p className={classes.answeringText}>{answerText}</p> */}
                      <p
                        dangerouslySetInnerHTML={{ __html: `${answerText}` }}
                        className={classes.answeringText}
                      ></p>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            item
            direction="column"
            xs={12}
            md={5}
            justifyContent="flex-start"
            alignItems="center"
          >
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              className={classes.questionAndAnswer}
            >
              <Grid
                container
                item
                justifyContent="flex-start"
                alignItems="center"
              >
                <h2 className={classes.feedbackTittle}>Retroalimentación</h2>
              </Grid>
              <Grid
                container
                item
                justifyContent="center"
                alignItems="flex-start"
                className={classes.feedbackBox}
              >
                <p
                  dangerouslySetInnerHTML={{ __html: `${feedback}` }}
                  className={classes.feedbackText}
                ></p>
              </Grid>
              <Grid
                container
                item
                justifyContent="center"
                alignItems="center"
                className={classes.buttonContainer}
              >
                <Grid
                  container
                  item
                  justifyContent="center"
                  alignItems="center"
                >
                  <p className={classes.dialogButtonContinuar}>
                    Cuando respondas cada pregunta te aparecerá una ventana con
                    la retroalimentación de la misma, para seguir, haz clic en
                    <b> Continuar.</b>
                  </p>
                  {feedBackImage ? (
                    <Button
                      onClick={finalStep}
                      onDoubleClick={finalStep}
                      className={classes.continueButton}
                    >
                      Continuar
                    </Button>
                  ) : remedialFinal ? (
                    <Button
                      onClick={finalStep}
                      onDoubleClick={finalStep}
                      className={classes.continueButton}
                    >
                      Finalizar
                    </Button>
                  ) : (
                    <Button
                      onClick={finalStep}
                      onDoubleClick={finalStep}
                      className={classes.continueButton}
                    >
                      Continuar
                    </Button>
                  )}
                </Grid>
                {finished && (
                  <Redirect
                    to={{
                      pathname: `/retro/${remedialNumber}/${activityNumber}/1`,
                    }}
                  />
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default CorrectFeedback;
