import React               from "react";
import {Grid, makeStyles}                 from '@material-ui/core';

const useStyles = makeStyles(theme =>({
  questionContainer:{
    width: "100%",
    height:"100%",
  },
  reading: {
    width: "80%",
    margin:"10px 0 10px 0 ",
    font: '22px lato',
    letterSpacing: '0.21px',
    [theme.breakpoints.down(1400)]:{
      font: " 18px lato",
      width: "95%",
    },
    [theme.breakpoints.down(1310)]:{
      font: "500 16px lato",
      // width: "80%",
      margin:"10px 0 10px 0"
    },
    [theme.breakpoints.down(925)]:{
      font: "500 14px lato",
      // width: "70%",
      margin:"6px 0 6px 0"
    },
    [theme.breakpoints.down(426)]:{
      font: "500 12px lato",
      // width: "70%",
      margin:"6px 0 6px 5%"
    }, 
  },
  table: {
    width: "100%",
    font: '22px lato',
    [theme.breakpoints.down(1400)]:{
      font: " 18px lato",
      width: "95%",
    },
    [theme.breakpoints.down(1310)]:{
      font: "500 16px lato",
      // width: "80%",
      margin:"10px 0 10px 0"
    },
    [theme.breakpoints.down(925)]:{
      font: "500 14px lato",
      // width: "70%",
      margin:"6px 0 6px 0"
    },
    [theme.breakpoints.down(426)]:{
      font: "500 12px lato",
      // width: "70%",
      margin:"6px 0 6px 5%"
    }, 
  },
  tdTable: {
    padding: "0 1%",
    border: '1px solid black',
  }
}));

const QuestionTable = (props) => {
  const classes = useStyles()


  return(
    <Grid container className={classes.questionContainer}>
      <p
        className={classes.reading}
      >
        3. Lee las oraciones y analiza los verbos subrayados para que los relaciones con el tiempo verbal en el que están conjugados.  
      </p>
      <br/>
      <table className={classes.table}>
        <thead>
          <th style={{width: "65%", border: '1px solid black'}}><b>Verbo</b></th>
          <th style={{width: "35%", border: '1px solid black'}}><b>Tiempo</b></th>
        </thead>
        <tbody>
          <tr>
            <td className={classes.tdTable}>
              <p>
                1. Hay efectos de las fotos que no <u>serían</u> posibles sin la tecnología digital.
              </p>
            </td>
            <td className={classes.tdTable}>
              <p>
                a. Antepresente
              </p>
            </td>
          </tr>
          <tr>
            <td className={classes.tdTable}>
              <p>
                2. Muchas técnicas se <u>han desarrollado</u> gracias a fotógrafos expertos. 
              </p>
            </td>
            <td className={classes.tdTable}>
              <p>
                b. Antepospretérito
              </p>
            </td>
          </tr>
          <tr>
            <td className={classes.tdTable}>
              <p>
                3. La primera foto no <u>habría existido</u> sin la paciencia de Niépce
              </p>
            </td>
            <td className={classes.tdTable}>
              <p>
                c. Pospretérito
              </p>
            </td>
          </tr>
          <tr>
            <td className={classes.tdTable}>
              <p>
                4. En aquel tiempo los fotógrafos <u>recubrían</u> las fotos con un aceite para revelado.   
              </p>
            </td>
            <td className={classes.tdTable}>
              <p>
                d. Copretérito
              </p>
            </td>
          </tr>
        </tbody>
      </table>
    </Grid>
  )
}

export default QuestionTable;
