import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
    ContenedorLectura:{
        height:'100%',
        width:'502px',
                  [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
    },
    ContainerTitulo:{
        margin:'0 0 34px 0',
        '& .Titulo':{
            letterSpacing: '0px',
            color: '#FF0027',
            margin:'0',
            textAlign:'center',
            font:'43px fredoka one',
            lineHeight:'52px',
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        background:'#F5FDFF',
        borderRadius:'20px',
        padding:'32px 21px 0 24px',
        [theme.breakpoints.down(502)]:{
            borderRadius:'20px',
        },
        '& .Lectura':{
            margin:'0 0 36px 0',
            color:'#000',
            font:'18px lato',
            lineHeight :'28px',
            letterSpacing: '0.15px',
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .SubTitulo':{
            color:'#FF0027',
            margin:'0',
            lineHeight :'28px',
            font:'26px Fredoka One',
            letterSpacing: '0.24px',
            [theme.breakpoints.down(502)]:{
                font:'18px Fredoka One',
                lineHeight :'24px',
                letterSpacing: '0.21px',
            },
        },
        '& .SubTitulo2':{
            position:'absolute',
            margin:'0',
            font:'11px Fredoka One',
            lineHeight :'13px',
            [theme.breakpoints.down(502)]:{
                font:'7px Fredoka One',
                lineHeight :'10px',
            },
        },
        '& .Pos1':{
            left:'5px',
            top:'190px',
            width:'80px',
            [theme.breakpoints.down(502)]:{
                left:'0px',
                top:'125px',
                width:'52px',
            },
        },
        '& .Pos2':{
            left:'375px',
            top:'195px',
            width:'88px',
            [theme.breakpoints.down(502)]:{
                top: '135px',
                left: '260px',
                width: '60px',
            },
        },
        '& .Granja':{
           position:'relative',
            left:'-21',
        }, 
        '& .Imagen':{
            width:'502px',
            [theme.breakpoints.down(520)]:{
                width:'350px',
            },
        },  
      
    }
}));

const Conjuntos = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                        <h2 className="Titulo">Conjuntos</h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container item justifyContent='center'>
                        <p className="Lectura">
                        Imagina que visitas una granja donde hay todo tipo de animales: caballos, 
                        vacas, burros, puercos, gallos, gallinas, pollos, patos, ovejas y hasta un perro y 
                        un gato. El granjero te pide ayuda para clasificar a los animales en pequeños 
                        grupos.<br/><br/>

                        ¿Cómo los podemos organizar? ¿Qué podrían tener en común? 
                        <br/><br/>

                        Pensemos en lo más sencillo, ¿qué son todos ellos? Exacto, animales. Ésa es la 
                        primera característica que comparten. ¿Podrían compartir algo más? Sí, son 
                        animales de granja. ¿Algo más? Sí, todos están domesticados, es decir, son 
                        animales acostumbrados a convivir con seres humanos.<br/><br/>

                        Pues bien, nuestro ejemplo es un conjunto de animales. Un <b>conjunto</b> es una 
                        colección de cosas, animales o personas que comparten una característica en 
                        común. En nuestro caso, cada animal es un <b>elemento</b> del conjunto.<br/><br/>

                        Además de este gran conjunto, podríamos formar otros más pequeños, a los 
                        que llamamos subconjuntos. Por ejemplo, el subconjunto de animales 
                        pequeños incluye solamente a los gallos, las gallinas, los pollos, los patos y el 
                        gato. Por su lado, el subconjunto de animales grandes incluye a los caballos y a 
                        la vaca. Y entonces, ¿quiénes formarían el subconjunto de animales medianos? 
                        Exacto, los puercos, las ovejas y el perro.<br/><br/>

                        ¡Ahora ya lo sabes! Al encontrar características en común, puedes formar 
                        conjuntos y subconjuntos de todo lo que se te ocurra.
                        </p>
                        <h3 className='SubTitulo'> Conjunto de animales de granja</h3>
                    </Grid>
                    <Grid container item className='Granja'>
                        <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img1ro/Sesion8/1.png" alt="Tarjeta" />
                        <p className='SubTitulo2 Pos1'>
                            Subconjunto 
                            de animales de 
                            granja grandes
                        </p>
                        <p className='SubTitulo2 Pos2'>
                            Subconjunto de 
                            animales de 
                            granja pequeños
                        </p>

                    </Grid>
                    
                </Grid>
        </Grid>
    </Grid>
    );
}
 
export default Conjuntos;

