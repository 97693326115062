import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
    ContenedorLectura:{
        width:'502px',
                  [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
    },
    ContainerTitulo:{
        margin:'0 0 61px 0',
        '& .Titulo':{
            letterSpacing: '0px',
            color: '#F6644B',
            margin:'0',
            textAlign:'center',
            font:'43px fredoka one',
            lineHeight:'52px',
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            margin:'0 0 36px 0 ',
            color:'#000',
            font:'18px lato',
            lineHeight :'28px',
            letterSpacing: '0.17px',
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .SubTitulo':{
            color:'#1DA698',
            margin:'0',
            lineHeight :'28px',
            font:'bold 26px lato',
            letterSpacing: '0.24px',
            [theme.breakpoints.down(502)]:{
                font:'bold 18px lato',
                lineHeight :'24px',
                letterSpacing: '0.21px',
            },
        },
        "& .SubTitulo2": {
            color: "#009137",
            margin: "0",
            font: "bold 22px lato",
            lineHeight: "24px",
            letterSpacing: "0.21px",
            [theme.breakpoints.down(502)]: {
              font: "bold 18px lato",
              lineHeight: "20px",
              letterSpacing: "0.16px",
            },
        },
        '& .Esp':{
            margin:'0'
        },
        '& .Pasos':{
            position:'relative',
        },
        '& .Text':{
            position:'absolute',
            margin:'0',
            color:'#135A86',
            font:'bold 16px lato',
            lineHeight:'26px',
            letterSpacing:'.15px',
            [theme.breakpoints.down(502)]:{
                font:'bold 11px lato',
                lineHeight:'15px',
                letterSpacing:'.13px',
            },
        },
        '& .Texto1':{
            top:'1.60%',
            left:'15.70%',
        },
        '& .Texto2':{
            top:'15.10%',
            left:'15.70%',
        },
        '& .Texto3':{
            top:'28.61%',
            left:'15.70%',
        },
        '& .Texto4':{
            top:'41.92%',
            left:'15.70%',
        },
        '& .Texto5':{
            top:'55.54%',
            left:'15.70%',
        },
        '& .Texto6':{
            top:'69%',
            left:'15.70%',
        },
        '& .Texto7':{
            width:'71.51%',
            top:'82.60%',
            left:'15.70%',
        },



        '& .li':{
            margin:'0 0 8px 0',
            [theme.breakpoints.down(502)]:{
            margin:'0 0 4px 0',
            }
        },

        '& .ul':{
            margin:'0',
            color:'#F6644B',
            padding:'0 0 0 19px',
            font:'bold 18px lato',
            [theme.breakpoints.down(502)]:{
                font:'bold 16px lato',
            }
        },

        '& .Imagen':{
            width:'100%',
        },
    },

}));

const Las_torres_de_Hanói = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >
                        Las torres de Hanói
                    </h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                      <p className="Lectura">
                        <h3 className='SubTitulo'>Los juegos y las matemáticas </h3><br/>

                        ¿Conoces la relación que hay entre los juegos y las matemáticas? Los juegos han 
                        desempeñado un papel importante en el desarrollo de las matemáticas. Por ejemplo, 
                        la probabilidad comenzó a estudiarse con los juegos de azar, como el lanzamiento de 
                        dados. En esta ocasión aprenderás matemáticas mientras te diviertes con las torres 
                        de Hanói. ¡Comencemos!
                      </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img3ro/Sesion10/1.png" alt="Tarjeta" />
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                      <p className="Lectura">
                        El matemático francés Édouard Lucas inventó un juego llamado torres de Hanói en 
                        1883. En su versión clásica contiene un tablero con tres postes verticales y ocho
                        discos de diferentes tamaños. En uno de los postes deben acomodarse los discos, del
                        más grande al más pequeño. Hay que formar una torre, como lo indica el nombre.<br/><br/> 
                        
                        La meta consiste en pasar la torre de discos de un poste a otro manteniendo el orden 
                        de los discos. Es decir, siempre deben seguir acomodados del más grande al más 
                        pequeño. Tienes que saber que las torres de Hanói se juegan de manera individual y
                        siguiendo estas reglas básicas:
                      </p>
                      <ol type='1' start={1} className='ul'>
                          <li className='li'>
                              <p className='Lectura Esp'>
                              Sólo se puede mover un disco a la vez.
                              </p>
                          </li>
                          <li className='li'>
                              <p className='Lectura Esp'>
                              No se puede colocar un disco más grande sobre uno más pequeño.
                              </p>
                          </li>
                      </ol>
                    </Grid>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container item sm={12}>
                        <p className='Lectura Esp'>
                            <h3 className='SubTitulo2'>¿Dónde está la complejidad de las torres de Hanói?</h3><br/>

                            Podrías pensar que es suficiente con cumplir el objetivo, pero lo interesante es 
                            aplicar tus conocimientos matemáticos para llegar a la meta con <em>el menor número de 
                            movimientos posibles,</em>  pues ésa es la mejor estrategia para ganar. Sólo debes tener 
                            en cuenta que el número de movimientos aumenta con cada disco.<br/><br/>
                            
                            A continuación, echaremos un vistazo a las torres de Hanói. Para comenzar, toma 
                            tres discos imaginarios: uno pequeño, otro mediano y otro grande. Luego, imagina 
                            tres postes: poste A, poste B y poste C. Ahora, apila los discos del más grande al más 
                            pequeño en el poste A. El reto es trasladar los discos al poste C. Sigue las 
                            instrucciones y observa con atención las imágenes. ¡Veamos!
                        </p>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container className='Pasos' sm={12}>
                        <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img3ro/Sesion10/2R.png" alt="Tarjeta" />
                        <p className='Pos Text Texto1'>Pasa el disco pequeño al poste C</p>
                        <p className='Pos Text Texto2'>Lleva el disco mediano al poste B</p>
                        <p className='Pos Text Texto3'>Cambia el disco pequeño al poste B</p>
                        <p className='Pos Text Texto4'>Mueve el disco grande al poste C</p>
                        <p className='Pos Text Texto5'>Pasa el disco pequeño al poste A</p>
                        <p className='Pos Text Texto6'>Traslada el disco mediano al poste C</p>
                        <p className='Pos Text Texto7'>Pon el disco pequeño en el poste C, ¡y listo! Conseguiste pasar la torre del poste A al poste C en el mínimo número de movimientos y sin quebrantar las reglas</p>

                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container item sm={12}>
                        <p className='Lectura'>
                            ¿Qué te pareció este juego? Probablemente pienses que esta estrategia puede 
                            mejorar. Para pulir tus habilidades y descubir nuevos procedimientos, puedes armar 
                            tus propias torres de Hanói con material reciclado. Agrega más discos y anota en tu 
                            cuaderno cuántos movimientos se necesitan para trasladar torres de 4 y 5 discos. 
                            ¡Puedes agregar todos los discos que desees!
                        </p>
                    </Grid>
                </Grid>
        </Grid>
    </Grid>
    );
}
 
export default Las_torres_de_Hanói;
