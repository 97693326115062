import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
     Contenedor:{
        background:'#E9FBF9',
        width:'100vw',
        overflowX:'clip',
        [theme.breakpoints.down(1161)]:{
            background:'none',
           overflowX:'unset',
        },
        [theme.breakpoints.down(600)]:{
            marginTop: '79.51px',
        }
    },
  ContainerInfoLectura: {
    position: "relative",
    height: "86px",
    [theme.breakpoints.down(502)]:{
        height: "43px",
    },
    background: "#76D4C3",
    "& .BarraInfoLectura": {
      height: "86px",
      background: `Url(https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Barras/Azul.png)`,
        [theme.breakpoints.down(502)]:{
            height: "43px",
        },
    },
    "& .InfoLectura": {
      position: "absolute",
      height: "86px",
      width: "219px",
      boxSizing: "border-box",
      padding: "17.2px 0",
      background: "#FAFAFA",
      borderRadius: "50px",
      boxShadow: "2px 2px 2px 2px #00000029",
      zIndex: "4",
        [theme.breakpoints.down(502)]:{
            height: "43px",
            width: "109px",
            padding: "8.6px 0",
            borderRadius: "25px",
        },
    },
    "& .InfoLectura:before": {
      position: "absolute",
      display: "flex",
      boxSizing: "border-box",
      padding: "0 0 0 10px",
      justifyContent: "center",
      alignItems: "center",
      height: "39.88px",
      width: "39.88px",
      borderRadius: "50%",
      content: '""',
      boxShadow: "3px 0px .5px 0px #00000029",
      background: "#FAFAFA",
      left: "90px",
      top: "65px",
      transform: "rotate(0.25turn)",
      [theme.breakpoints.down(502)]:{
        left: "45px",
        top: "30px",
        height: "20.88px",
        width: "20.88px",
        },
    },
    "& .InfoLectura:after": {
      content: '""',
      height: "15px",
      width: "15px",
      borderTop: "2px solid #20A698",
      borderLeft: "2px solid #20A698",
      position: "absolute",
      bottom: "-10px",
      left: "Calc(50% - 8px)",
      transform: "rotate(0.625turn)",
      [theme.breakpoints.down(502)]:{
        height: "10px",
        width: "10px",
        left: "Calc(50% - 4.5px)",
        bottom: "-3px",
        borderTop: "1px solid #20A698",
        borderLeft: "1px solid #20A698",
        },
    },
    "& .Sesion": {
      borderLeft: "2px solid #D8D8D8",
    },
    "& .Dato": {
      margin: "0 0 5px 0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#20A698",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        margin: "0 0 2.5px 0",
        },
    },
    "& .Caracter": {
      margin: "0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#000000",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        },
    },   },
    ContenedorLectura:{
        background:'#fff',
        width:'1160px',
        height:'100%',
        padding:'60px 168px 35px ',
        [theme.breakpoints.down(1161)]:{
            width:'502px',
            padding:'60px 0 0 0',
          },
        [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
        '& .ContainerDerechos':{
            bottom:'80px',
            [theme.breakpoints.down(1161)]:{
                display:'none',
            },
        },
        '& .Derechos':{
            textAlign:"center",
            margin:"0px",
            font:" 15px lato",
            color:"#717476",
        },
        '& .Escritorio':{
            display:'block',
            [theme.breakpoints.down(1161)]:{
                display:'none'
            },
        },
        '& .Tablet':{
            display:'none',
            [theme.breakpoints.down(1161)]:{
                display:'block'
            },
        }
    },
    ContainerTitulo:{
        margin:'0 0 46px 0',
        '& .Titulo':{
            letterSpacing: '0px',
            color: '#FC5E9E',
            margin:'0',
            textAlign:'center',
            font:'48px fredoka one',
            [theme.breakpoints.down(1161)]:{
                font:'43px fredoka one',
                lineHeight:'52px',
            },
            [theme.breakpoints.down(502)]:{
                font:'27px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            margin:'0 0 36px 0 ',
            color:'#000',
            font:'22px lato',
            letterSpacing: '0.21px',
            lineHeight:'30px',
            [theme.breakpoints.down(1161)]:{
                font:'18px lato',
                lineHeight :'28px',
                letterSpacing: '0.17px',
            },
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .SubTitulo':{
            color:'#F71396',
            margin:'0',
            font:'bold 30px lato',
            letterSpacing:'.28px',
            lineHeight:'30px',
            [theme.breakpoints.down(1161)]:{
                lineHeight :'28px',
                font:'bold 26px lato',
                letterSpacing: '0.24px',
            },
            [theme.breakpoints.down(502)]:{
                font:'bold 18px lato',
                lineHeight :'24px',
                letterSpacing: '0.21px',
            },
        },
        '& .TextImg':{
            margin:'11px 0 0 0',
            color:'#717476',
            font:'20px lato',
            lineHeight :'30px',
            letterSpacing: '0.19px',
            [theme.breakpoints.down(1161)]:{
                font:' 16px lato',
                lineHeight :'20px',
                letterSpacing: '0.15px',
            },
            [theme.breakpoints.down(502)]:{
                font:' 14px lato',
                lineHeight :'18px',
                letterSpacing: '0.10px',

            },
        },
        '& .Imagen':{
            [theme.breakpoints.down(1161)]:{
                width:'100%',
            },
        },


    },
    

}));

const África_cuna_de_la_humanidad_y_del_lenguaje = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
             <Grid container item justifyContent="center" className={classes.ContainerInfoLectura}>
               <Grid container className="BarraInfoLectura"></Grid>
               <Grid container item className="InfoLectura">
                    <Grid container item direction="column" xs={6} justifyContent="center">
                        <p className="Dato">Grado</p>
                        <p className="Caracter">4</p>
                    </Grid>
                    <Grid container item direction="column" xs={6} justifyContent="center" className="Sesion">
                        <p className="Dato">Sesión</p>
                        <p className="Caracter">32</p>
                    </Grid>
               </Grid>
            </Grid>

            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >
                        África: cuna de la<br className='Tablet'/> humanidad<br/> y del lenguaje
                    </h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                            El espécimen primitivo conocido como Lucy se encuentra entre los ancestros más 
                            famosos en la evolución humana. Se cree que tenía 14 años cuando murió a 
                            consecuencia de la caída de un árbol, según revelaron estudios publicados 
                            recientemente. Sus restos fueron descubiertos hace 40 años en una selva de Etiopía, 
                            en África. ¡Lucy vivió hace 3.18 millones de años! Ahora se sabe que el continente 
                            africano fue el sitio donde comenzó la evolución que originó a los primeros seres 
                            humanos.
                        </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <Grid container >
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img4to/Sesion32/1.png"  alt="Tarjeta" />
                        </Grid>
                        <Grid container justifyContent='center'>
                            <p className='TextImg'>
                                El cuerpo y el rostro de Lucy se han logrado reconstruir gracias a la tecnología.
                            </p>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                            <h3 className='SubTitulo'>¿Cuándo comenzamos a hablar?</h3><br/> 
                            
                            Los especialistas creen que el lenguaje surgió hace 700 000 años y que consistía en 
                            movimientos corporales acompañados de sonidos, como gritos y susurros. Los 
                            antepasados de los humanos que caminaban erguidos son llamados <em>homínidos</em>. Ellos 
                            elaboraron nuevas herramientas de caza, como piedras afiladas capaces de rebanar, 
                            cortar y romper restos animales. Después tuvieron la necesidad de explicar cómo 
                            fabricarlos y se piensa que esto dio inicio al desarrollo del lenguaje.<br/><br/>
                            
                            <h3 className='SubTitulo'>¿Cuál fue la primera palabra? </h3><br/>
                            
                            Es una pregunta común que quizá nunca podremos responder. Los homínidos que
                            vivían en aquella época inventaron las primeras palabras con conceptos simples y 
                            antiguos. El lenguaje fue volviéndose más complejo conforme las exigencias de la 
                            comunicación se tornaron mayores, pues tenían que explicar la organización de las 
                            familias y del gobierno, así como los sentimientos y estados de ánimo.<br/><br/>
                            
                            Otra teoría sostiene que hablar fue posible gracias a los cambios físicos que sufrió el 
                            cuerpo de los homínidos, pues para hacerlo requerimos controlar la respiración. La 
                            mayoría de los homínidos, los chimpancés y sus parientes más cercanos carecían de 
                            esta fuerza física en su diafragma para producir los sonidos. Este salto evolutivo fue 
                            posible por las mezclas de linajes entre los primeros <em>homo sapiens</em> y otras especies. <br/><br/>
                            
                            Pero la pregunta aún permanece: ¿cómo se desarrolló el lenguaje? Muchas 
                            investigaciones tratan de explicarlo. Gracias al lenguaje hoy podemos expresar 
                            nuestras emociones, además de que permite registrar y transmitir conocimientos de
                            manera eficaz. ¿Cómo imaginas que fueron las primeras palabras que pronunció la 
                            humanidad?
                        </p>
                    </Grid>
                </Grid>

                <Grid container item xs={12} justifyContent="center" className="ContainerDerechos">
                    <p className="Derechos">
                        © 2022. Todos los derechos reservados, Pearson, Circuito de lectores.
                    </p>                
                </Grid>
        </Grid>
    </Grid>
    );
}
 
export default África_cuna_de_la_humanidad_y_del_lenguaje;
