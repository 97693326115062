import { Button, Grid, makeStyles } from "@material-ui/core";
import React, { useContext, useEffect } from "react";
import DrawerContext from "../../../../../usability/DrawerContext";
import { useOnLoadImages } from "../../../../hooks/useOnLoadImages";
import Loader from "../../../../Loader/Loader";
import Drawer from "../../ReadingTools/Drawer";

const useStyles =  makeStyles (theme =>({
      Contenedor:{
        background:'#E9FBF9',
        width:'100vw',
        overflowX:'clip',
        [theme.breakpoints.down(1161)]:{
            background:'none',
           overflowX:'unset',
        },
        [theme.breakpoints.down(600)]:{
            marginTop: '79.51px',
        }
    },
  ContainerInfoLectura: {
    position: "relative",
    height: "86px",
    [theme.breakpoints.down(502)]:{
        height: "43px",
    },
    background: "#76D4C3",
    "& .BarraInfoLectura": {
      height: "86px",
      background: `Url(https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Barras/Azul.png)`,
        [theme.breakpoints.down(502)]:{
            height: "43px",
        },
    },
    "& .InfoLectura": {
      position: "absolute",
      height: "86px",
      width: "219px",
      boxSizing: "border-box",
      padding: "17.2px 0",
      background: "#FAFAFA",
      borderRadius: "50px",
      boxShadow: "2px 2px 2px 2px #00000029",
      zIndex: "4",
        [theme.breakpoints.down(502)]:{
            height: "43px",
            width: "109px",
            padding: "8.6px 0",
            borderRadius: "25px",
        },
    },
    "& .InfoLectura:before": {
      position: "absolute",
      display: "flex",
      boxSizing: "border-box",
      padding: "0 0 0 10px",
      justifyContent: "center",
      alignItems: "center",
      height: "39.88px",
      width: "39.88px",
      borderRadius: "50%",
      content: '""',
      boxShadow: "3px 0px .5px 0px #00000029",
      background: "#FAFAFA",
      left: "90px",
      top: "65px",
      transform: "rotate(0.25turn)",
      [theme.breakpoints.down(502)]:{
        left: "45px",
        top: "30px",
        height: "20.88px",
        width: "20.88px",
        },
    },
    "& .InfoLectura:after": {
      content: '""',
      height: "15px",
      width: "15px",
      borderTop: "2px solid #20A698",
      borderLeft: "2px solid #20A698",
      position: "absolute",
      bottom: "-10px",
      left: "Calc(50% - 8px)",
      transform: "rotate(0.625turn)",
      [theme.breakpoints.down(502)]:{
        height: "10px",
        width: "10px",
        left: "Calc(50% - 4.5px)",
        bottom: "-3px",
        borderTop: "1px solid #20A698",
        borderLeft: "1px solid #20A698",
        },
    },
    "& .Sesion": {
      borderLeft: "2px solid #D8D8D8",
    },
    "& .Dato": {
      margin: "0 0 5px 0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#20A698",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        margin: "0 0 2.5px 0",
        },
    },
    "& .Caracter": {
      margin: "0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#000000",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        },
    },
       '& .Regresar':{
        color: '#F2624F',
        border: '#F2624F 1px solid',
        height: '30px',
        width:'100px',
        borderRadius: '20px',
        letterSpacing: '0.14px',
        textTransform: 'none',
        background:'#FEFAF1',
        top:'calc(50% - 15px)',
        left:'5%',
        zIndex:4,
        [theme.breakpoints.down(502)]: {
          font:'bold 10px lato',
          width: "60px",
        },
      },
  },
    ContenedorLectura:{
        background:'#fff',
        width:'1160px',
        height:'100%',
        padding:'60px 113px 35px 168px' ,
        
        transform: (props) =>
        props.scale > 1 ? `scale(${props.scale}) translate(0px, 10%)` : `none`,
        [theme.breakpoints.down(1161)]:{
            width:'502px',
            padding:'60px 0 0',
        },
        [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
        '& .ContainerDerechos':{
            bottom:'80px',
            [theme.breakpoints.down(1161)]:{
                display:'none',
            }
        },
        '& .Derechos':{
            textAlign:"center",
            margin:"0px",
            font:" 15px lato",
            color:"#717476",
        }, 
        '& .Escritorio':{
            display:'block',
            [theme.breakpoints.down(1161)]:{
                display:'none'
            },
        },
        '& .Tablet':{
            display:'none',
            [theme.breakpoints.down(1161)]:{
                display:'block'
            },
        },  
    },

    ContainerTitulo:{
        margin:'0 0 36px 0',
        [theme.breakpoints.down(1161)]:{
            margin:'0 0 38px 0',
        },
        '& .Titulo':{
            font:'48px fredoka one',
            letterSpacing: '0px',
            color: '#FF2B2B',
            margin:'0 0 15px 0',
            textAlign:'center',
            [theme.breakpoints.down(1161)]:{
                font:'43px fredoka one',
                lineHeight:'52px',
                textAlign:'center',
            },
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
        '& .SubTitulo':{
            font:'bold 30px lato',
            letterSpacing: '0px',
            color: '#FF2B2B',
            margin:'0',
            textAlign:'center',
            [theme.breakpoints.down(1161)]:{
                font:'bold 26px lato',
                letterSpacing:'.24px',
                lineHeight:'28px',
            },
            [theme.breakpoints.down(502)]:{
                font:'bold 22px lato',
                lineHeight:'24px',
            },
            [theme.breakpoints.down(350)]:{
                font:'bold 18px lato',
                lineHeight:'24px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            lineHeight : '30px',
            margin:'0 0 36px 0 ',
            font:'22px lato',
            color:'#000',
            letterSpacing: '0.20px',
            [theme.breakpoints.down(1161)]:{
                font:'18px lato',
                letterSpacing:'.17px',
                lineHeight:'28px',
            },
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .Imagen1':{
            [theme.breakpoints.down(1161)]:{
             width:'100%',
         },
        },

    },
    

}));

const La_infanta_Castile_y_el_insólito_mundo_de_Anomalía_Capitulo_4 = (props) => {

  const {activeStep, setActiveStep, handlePreviewStep, id_actividad} = props

  const handleContinue = () => {
    setActiveStep(activeStep - 1)
    handlePreviewStep()
  }

    const {
    handleSize,
    disabledZoom,
    closeZoom,
    handleZoom,
    scale
  }= useContext(DrawerContext);
  const [loading, setLoading] = React.useState(true);
  const wrapperRef = React.useRef(null);
  const imagesLoaded = useOnLoadImages(wrapperRef);

  const detectSize = () => {
    const lectura = document.getElementById("lectura");
    const newWidth = lectura?.offsetWidth;
    const newHeight = lectura?.offsetHeight;
    setLoading(false);
    if (lectura) handleSize(newHeight, newWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", detectSize);
    return () => {
      handleZoom()
      window.removeEventListener("resize", detectSize);
      closeZoom()
    };
  }, []);

  const classes = useStyles({ disabledZoom,scale });
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
            
            <Grid container item justifyContent="center" className={classes.ContainerInfoLectura}>
               <Grid container className="BarraInfoLectura">
                    <Button className="Regresar" onClick={handleContinue}>
                        Regresar
                    </Button>
                </Grid>
               <Grid container item className="InfoLectura">
                    <Grid container item direction="column" xs={6} justifyContent="center">
                        <p className="Dato">Grado</p>
                        <p className="Caracter">6</p>
                    </Grid>
                    <Grid container item direction="column" xs={6} justifyContent="center" className="Sesion">
                        <p className="Dato">Sesión</p>
                        <p className="Caracter">29</p>
                    </Grid>
               </Grid>
            </Grid>
{!imagesLoaded && <Loader />}
        <Grid
            container
            item
            className={classes.ContenedorLectura}
            justifyContent="center"
            id="lectura"
            onLoad={detectSize}
            ref={wrapperRef}
        >
<Drawer id_lectura={id_actividad} />
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo Escritorio" >
                        La infanta Castile y el insólito<br/>
                        mundo de Anomalía
                    </h2>
                    <h2 className="Titulo Tablet" >
                        La infanta Castile y <br/>
                        el insólito mundo <br/>
                        de Anomalía
                    </h2>
                    <h3 className='SubTitulo'>Fragmento 4 (capítulo 4)</h3>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                            —Debes ser fuerte, ¿de acuerdo? Ahora que eres un poco mayor podrás soportarlo. 
                            Debo confesarte que nunca sentí que estuviera viviendo una vida que me gustara. 
                            Ser su padre no era algo que me hiciera feliz, René. Lo mejor que pudo sucederme fue 
                            estar lejos de ustedes, no soportaba un día más esa pesadilla. <br/><br/>
                            
                            René pudo escuchar su corazón detenerse por un segundo. El impacto de esa 
                            confesión le provocó la sensación de que todo su cuerpo se rompía. Era como estar 
                            en un torbellino que la hacía impactar una y otra vez contra el suelo. De todas las 
                            respuestas posibles, aquélla jamás había cruzado por su mente. “¿Y todos los 
                            momentos vividos, todas las alegrías compartidas? ¿Fueron una mentira?”, gritaba la 
                            rabia en su interior. De pronto la bruma comenzó a disiparse y René pudo ver a su 
                            alrededor. Su padre estaba de pie justo a la orilla de un acantilado. <br/><br/>
                            
                            —Es tu oportunidad, René, libera toda esa rabia que sientes. <br/><br/>
                            
                            René sintió que una pulsión en su interior se apoderaba de cada músculo de su 
                            cuerpo. Una parte de ella quería tomar impulso y empujar a su padre al vacío, pero 
                            otra parte hacía un esfuerzo por contenerse. René no podía creer en aquellas 
                            palabras que contradecían todos sus recuerdos. Era verdad que ella guardaba mucho 
                            rencor, pero el pasado seguía siendo un bello álbum de fotografías que le gustaba 
                            hojear en sus momentos de soledad. A su padre le debía muchas cosas; una de ellas 
                            era su personalidad ermitaña. También le debía su gusto por Las Cabecitas de Cerillo, 
                            “el mejor grupo de rock de mujeres de todos los tiempos”, como decía él. <br/><br/>

                            —Debes hacerlo, René —insistió su padre. <br/><br/>
                            
                            —¡Vete! —gritó la niña con todas sus fuerzas—. ¡Tú no eres mi padre! <br/><br/>
                            
                            —Eres débil, René, eres cobarde, estoy tan decepcionado de ti —dijo el padre antes 
                            de desaparecer. <br/><br/>
                            
                            La confrontación le provocó un ataque de llanto. René se sintió completamente 
                            vulnerable. Respiraba hondo intentando recuperarse, pero una segunda voz se dejó 
                            escuchar. <br/><br/>
                            
                            —René, ¿eres tú?<br/><br/>
                            
                             Frente a ella, en el mismo lugar donde había desaparecido el padre, en el mismo 
                             acantilado, ahora estaba Ingrid.<br/><br/>
                             
                            —¡Ingrid! ¡Sácame de aquí, por favor! <br/><br/>
                            
                            —Te ves… tan frágil. <br/><br/>
                            
                            —Mis hermanos también están aquí, caímos al agujero después de ti. Por favor, 
                            ayúdanos —imploró. <br/><br/>
                            
                            —Es demasiado tarde, René. Ellos no lo lograron, están muertos. <br/><br/>
                            
                            Si las palabras del padre habían sido un torbellino, esta noticia era un incendio 
                            abrasador. Sentía que todo en ella se convertía en cenizas. Sus hermanos estaban 
                            muertos y todo era por Ingrid. <br/><br/>
                            
                            —Es mi culpa, René —aceptó Ingrid—. No debí haberme acercado al agujero, jamás 
                            pensé que me seguirían. <br/><br/>
                            
                            Pero no era rabia contra Ingrid lo que René sentía. No la culpaba de lo que había 
                            pasado. En el fondo sabía que todo habría podido evitarse si ella hubiera alertado a 
                            Ingrid de que aquellas chicas en el colegio le harían daño. Entonces, ella y sus 
                            hermanos jamás habrían visitado su jardín y no habrían caído en el hoyo. <br/><br/>
                            
                            —Fue mi culpa. Sabes lo que tienes que hacer —le dijo Ingrid—. Tienes que vengarte.<br/><br/>
                            
                            René tomó fuerzas y se acercó a ella. <br/><br/>
                            
                            —Empújame, lo merezco —susurró Ingrid cuando la tuvo cerca.<br/><br/>
                            
                            Pero René simplemente suspiró y lloró. <br/><br/>
                            
                            —Estas lágrimas son por mí. Me avergüenza haber sido tan cobarde —confesó 
                            René—. Ojalá pudieras ver a través de mis ojos, Ingrid. Eres hermosa. Jamás había 
                            visto a una chica tan bella. Yo fui la mala de esta historia, la cobarde —prosiguió—. 
                            Debí haberte advertido, debí haberte defendido. Ahora es muy tarde. Perdón. 
                            Lamento todo el dolor que te causaron, así como lamento no poder volver a ver a mis 
                            hermanos. Tienes que ser fuerte, Ingrid.<br/><br/>
                            
                            René caminó y abrazó a su amiga, quien se desvaneció como si fuera un fantasma. 
                            Instantáneamente, el acantilado comenzó a derrumbarse. Como en cámara lenta, 
                            René sintió su cuerpo desvanecerse hacia la nada y, estando en esa caída mortal, una 
                            brisa la levantó. Miró hacia abajo y no pudo ver el final del precipicio. Un frío recorrió 
                            su cuerpo al escuchar un eco grave y violento. <br/><br/>
                            
                            —Tarde o temprano serás mía. <br/><br/>
                            
                            Luego René miró hacia arriba. La bruma, que ahora era más luminiscente, dejó ver la 
                            forma de un zorro blanco moviéndose con soltura. René volvió al piso delicadamente. <br/><br/>
                            
                            —Criatura de gran valentía —habló el zorro. <br/><br/>
                            
                            —¿Quién eres? —preguntó René. <br/><br/>
                            
                            —Soy la Aurora, una de las fuerzas vitales de este mundo, y tú me has liberado de mi 
                            opresor.
                        </p>
                    </Grid>
                    
                    <Grid container  sm={12}>
                        <Grid container >
                            <img className="Imagen1" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion29/1.png" alt="Tarjeta" />
                        </Grid>
                    </Grid>

                </Grid>
        </Grid>
        <Grid container item style={{ marginTop: 80 }}></Grid>
    </Grid>
    );
}
 
export default La_infanta_Castile_y_el_insólito_mundo_de_Anomalía_Capitulo_4;
