import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
    ContenedorLectura:{
        width:'502px',
                  [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
    },
    ContainerTitulo:{
        margin:'0 0 46px 0',
        '& .Titulo':{
            letterSpacing: '0px',
            color: '#E33430',
            margin:'0',
            textAlign:'center',
            font:'43px fredoka one',
            lineHeight:'52px',
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            margin:'0 0 36px 0 ',
            color:'#000',
            font:'18px lato',
            lineHeight :'28px',
            letterSpacing: '0.17px',
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .Cuadro':{
            position:'relative',
        },
        '& .Esp':{
            position:'absolute',
            margin:'102px 0 0 0',
            width:'85.25%',
            [theme.breakpoints.down(502)]:{
                margin:'65px 0 0 0',
                lineHeight :'18px!important',
            },
        },
        '& .Imagen':{
            width:'100%',
        },
    },
    

}));

const Siempre_al_mar = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>

            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >
                        Siempre al mar
                    </h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                            Me llamo Ulises Torres y he dedicado toda mi vida a viajar. Desde muy joven conocí 
                            ríos, montañas, desiertos, iglús, selvas, bosques y llanuras. Comí y bebí de todo. Hice 
                            amigos por aquí y por allá. Y gracias a eso aprecié costumbres distintas a las mías. 
                            Pero no importa cuántos años pasen, siempre regreso al mar que conocí por primera 
                            vez en aquel verano.<br/><br/> 
                            
                            Mamá, papá y yo vivíamos en una ciudad como cualquier otra. Casas, edificios y 
                            cemento; pocos árboles y flores. Entre mi escuela y su trabajo no teníamos tiempo 
                            para salir. A veces los fines de semana íbamos a algún parque, pero nada más.
                        </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <Grid container >
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img3ro/Sesion31/1.png"  alt="Tarjeta" />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                            Ese verano la rutina cambió. Mis padres habían conseguido vacaciones y yo ya había 
                            concluido el año escolar. ¡Al fin tendíamos tiempo para explorar otros lugares! Mamá 
                            propuso escalar una montaña, acampar en un bosque o nadar en el mar.<br/><br/>  
                            
                            —¡El mar! —respondimos papá y yo. <br/><br/> 
                            
                            Yo no lo conocía y me emocionaba pensar en tanta agua, en las olas que vienen y van. 
                            Al día siguiente muy temprano guardamos nuestras maletas en el carro; aún no 
                            amanecía cuando salimos. <br/><br/> 
                            
                            En el camino no pude despegar los ojos de la carretera. Cuando me cansaba de ver 
                            por la ventana, mientras papá manejaba, mamá y yo nos decíamos adivinanzas y 
                            cantábamos nuestras canciones preferidas. <br/><br/> 
                            
                            Nunca olvidaré la primera vez que lo vi. El carro salió de una curva y de pronto a
                            pareció frente a nosotros como un espejo de cielo enorme y deslumbrante; 
                            turquesa y azul.
                        </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <Grid container >
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img3ro/Sesion31/2.png"  alt="Tarjeta" />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                            ¡Al fin llegamos! Recuerdo cuánto ansiaba verlo de cerquita. Dejamos nuestro 
                            equipaje en la habitación del hotel, nos cambiamos de ropa y corrimos a la playa. 
                            ¡Quería nadar! Y aunque yo ya sabía hacerlo, papá me dijo que tuviera precaución, 
                            pues el mar es muy distinto a una alberca debido al oleaje.<br/><br/>
                            
                            —¡Sí, papá! —grité mientras corría hacia el agua.
                        </p>
                    </Grid>
                    <Grid container justifyContent="center" className='Cuadro' sm={12}>
                        <img className="Imagen " src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img3ro/Sesion31/3R.png" alt="Tarjeta" />
                        <p className='Lectura Esp'>
                            La arena provoca calor y cosquilleo en la planta de tus pies. Conforme te 
                            acercas al agua la arena se vuelve lisa y húmeda. Después escuchas el sonido 
                            de las olas. Mientras caminas la espuma acaricia tus dedos hasta envolverte 
                            en ella para flotar en la inmensidad del agua.
                        </p>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                            Los siguientes días yo sólo quería nadar. Mamá y papá también nadaron, pero a ellos
                            no se les hacían los dedos arrugaditos como a mí. ¡Era por pasar tanto tiempo en el 
                            agua! Yo no quería regresar a la ciudad, pero no los convencí de quedarnos. Así que 
                            volvimos. La ciudad me seguía pareciendo gris, pero al menos podía recordar el mar y 
                            la sensación de nadar en él.<br/><br/> 
                            
                            Aquel viaje es uno de los momentos más felices de mi vida. Aunque visite todos los 
                            rincones del mundo, nunca dejaré de ir a aquella playa en la que conocí por primera 
                            vez el mar.
                        </p>
                    </Grid>
                </Grid>
        </Grid>
    </Grid>
    );
}
 
export default Siempre_al_mar;
