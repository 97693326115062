import React                              from "react";
import {Grid, makeStyles}                 from '@material-ui/core';


const useStyles = makeStyles(theme =>({
  answerOption:{
    border: "#2E313C26 3px solid",
    width: "242px",
    height:"217px",
    margin: '5%',
    borderRadius: "7.07px",
    backgroundColor: "white",
    [theme.breakpoints.down(1571)]:{
      width: "242px",
      height:"217px",
    },
    [theme.breakpoints.down(1441)]:{
      width: "212px",
      height:"207px",
    },
    [theme.breakpoints.down(1401)]:{
      width:'172px',
      height:'157px',
    },
    [theme.breakpoints.down(1350)]:{
      width:'162px',
      height:'147px',
    },
    [theme.breakpoints.down(960)]:{
      width:'172px',
      height:'157px',
    },
    [theme.breakpoints.down(361)]:{
      width:'152px',
      height:'137px',
    },
    '&:hover':{
      backgroundColor: "#2E313C1A",
      cursor: "pointer"
    },
  },
  selectedStyle:{
    border: "#4BBCF8 5px solid",
    backgroundColor: "white",
    width: "242px",
    height:"217px",
    borderRadius: "5px",
    margin: '4%',
    [theme.breakpoints.down(1571)]:{
      width: "242px",
      height:"217px",
    },
    [theme.breakpoints.down(1441)]:{
      width: "212px",
      height:"207px",
    },
    [theme.breakpoints.down(1401)]:{
      width:'172px',
      height:'157px',
    },
    [theme.breakpoints.down(1350)]:{
      width:'162px',
      height:'147px',
    },
    [theme.breakpoints.down(960)]:{
      width:'172px',
      height:'157px',
    },
    [theme.breakpoints.down(361)]:{
      width:'152px',
      height:'137px',
    },
  },
  reading: {
    textAlign: "justify",
    width: "80%",
    font: "bold 20px Lato",
  },
  primaryIcon:{
    color: "#4BBCF8",
  },
  primaryIconFeedback:{
    color:"#4BBCF8"
  },
  imageContainer:{
    padding: '1%',
    margin:"0%",
    maxWidth: '457px',
    [theme.breakpoints.down(1440)]:{
      maxWidth: '257px',
      maxHeight: '257px',
    },
  },
  imageStyled:{
    maxWidth: '100%',
    maxHeight: '100%',
  }
}));


const Answer = (props) => {
  const classes = useStyles()
  const { 
    setPristine, 
    setIsCorrect, 
    isCorrect, 
    questionId,
    setQuestionId,
    answerText, 
    setAnswerText, 
    feedback, 
    setFeedback, 
    setCurrentAnswer, 
    currentAnswer,
    setFeedBackImage,
    imageList
  } = props


  const handleClick = (event) => {
    setCurrentAnswer(questionId)
    setFeedback(feedback)
    setPristine(false)
    setIsCorrect(isCorrect > 0 ? true : false)
    setAnswerText(answerText)
    setFeedBackImage(true)
    setQuestionId(questionId)
  }

  return(
    <Grid 
      container
      item
      // direction='row'
      xs={6}
      justifyContent="space-around"
      // alignItems="center"
      className={classes.imageContainer} 
    >
      <Grid
        justifyContent="center"
        alignItems="center"
        container
        item
        className={ 
          currentAnswer === questionId ? 
          classes.selectedStyle 
          :
          classes.answerOption}
        onClick={handleClick}
      >
        {/* <img alt={questionId} src={imageList[questionId] || "#"} className={classes.imageStyled} /> */}
        <img alt={questionId} src={imageList[questionId] || "#"} className={classes.imageStyled} />
      </Grid>
    </Grid>
  )
}

export default Answer;
