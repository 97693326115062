import React from "react";
import { Button, makeStyles, Avatar } from "@material-ui/core";
import AddAPhotoIcon from "@material-ui/icons/AddAPhoto";
import defaultImg from "../../img/default_avatar.png";
import Dropzone from "react-dropzone";
import AuthContext from "../../../../../auth/AuthContext";
import ModalMessage from "../../../../Modals/ModalMessage";

const useStyles = makeStyles((theme) => ({
  large: {
    // width: theme.spacing(40),
    // height: theme.spacing(40),
    width: "211px",
    height: "209px",
  },

  mainTitle: {
    font: "48px fredoka one",
    margin: "0px",
    textAlign: "center",
    color: "orange",
    [theme.breakpoints.down("760")]: {
      font: "36px fredoka one",
    },
    [theme.breakpoints.down("321")]: {
      font: "28px fredoka one",
    },
  },

  link: {
    // transform:'translate(-50%,-50%)',
    textDecoration: "none",
    marginTop: "40px",
  },

  BotonAvanza: {
    textTransform: "none",
    marginTop: "40px",
    height: "62px",
    width: "279px",
    background: "#7A54BA",
    boxShadow: "0px 3px 6px #00000029",
    borderRadius: "61px",
    color: "#FFFFFF",
    font: "bold 20px lato ",
    "&:hover": {
      background: "#dd78fb",
    },
    [theme.breakpoints.down(1367)]: {
      height: "52px",
      width: "250px",
    },
    [theme.breakpoints.down("321")]: {
      height: "62px",
      width: "198px",
      font: "bold 18px lato ",
    },
  },
  instructionsText: {
    font: "26px lato",
    color: "#000",
    [theme.breakpoints.down("760")]: {
      font: "22px lato",
    },
    [theme.breakpoints.down("321")]: {
      font: "18px lato",
    },
  },
  featuresText: {
    font: "20px lato",
    color: "#505759",
    marginBottom: "10px",
    [theme.breakpoints.down("760")]: {
      font: "18px lato",
    },
    [theme.breakpoints.down("321")]: {
      font: "16px lato",
    },
  },
  button: {
    borderRadius: "50%",
  },
  iconImage: {
    marginBottom: "20px",
    color: "#fafafa",
  },
  container: {
    display: "flex",
    width: "auto",
    justifyContent: "center",
    alignContent: "center",
    "& .iconImageBar": {
      display: "none",
      alignItems: "flex-end",
      justifyContent: "center",
      width: "211px",
      position: "absolute",
      bottom: "7px",
    },
    "&:hover": {
      "& .iconImageBar": {
        display: "flex",
        alignItems: "center",
        height: "105.5px",
        backgroundColor: "#00000050",
        " -moz-border-radius": "0 0 211px 211px ",
        "-webkit-border-radius": " 0 0 211px 211px ",
        borderRadius: " 0 0 211px 211px ",
      },
    },
  },
}));
const DropZone = ({ handleNext, handleImage, image }) => {
  const classes = useStyles();
  const { user } = React.useContext(AuthContext);
  const [modalMessageOpen, setModalMessageOpen] = React.useState(false);
  const [confirmChangesResult, setConfirmChangesResult] = React.useState("");
  const limitInMb = 5;

  const handleCloseMessageModal = () => {
    setModalMessageOpen(!modalMessageOpen);
  };

  const formatBytes = (bytes) => {
    const limitInKb = limitInMb * 1000000;
    return bytes < limitInKb;
  };

  const uploadImage = async (file) => {
    const [selectedFile] = file;
    if (selectedFile) {
      if (formatBytes(selectedFile?.size)) {
        const base64 = await convertBase64(selectedFile);
        handleNext();
        handleImage(base64);
      } else {
        setModalMessageOpen(true);
        setConfirmChangesResult(`La imagen seleccionada tiene que pesar menos de ${limitInMb} Mb.`);
      }
    } else {
      setModalMessageOpen(true);
      setConfirmChangesResult(`Asegúrese de que la imagen seleccionada tenga un formato jpg/png.`);
    }
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };
  return (
    <>
      <Dropzone
        accept="image/*"
        multiple={false} // Only upload 1 file
        onDrop={(file) => {
          uploadImage(file);
        }}
      >
        {({ getRootProps, getInputProps }) => (
          <div {...getRootProps()} className={classes.container}>
            <input {...getInputProps()} />
            <div className={classes.avatarWrapper}>
              {/* { loading && <CircularProgress thickness={1} size={326} className={classes.avatarUploadProgress}/> } */}
              <Button onClick={() => {}}>
                <Avatar alt="default_img" src={image || user.avatar?.path || defaultImg} className={classes.large} />
                <div className={"iconImageBar"}>
                  <AddAPhotoIcon className={classes.iconImage} />
                </div>
              </Button>
            </div>
          </div>
        )}
      </Dropzone>
      <ModalMessage open={modalMessageOpen} handleClose={handleCloseMessageModal} title={confirmChangesResult} />
    </>
  );
};

export default DropZone;
