import React, {useContext}                from "react";
import {Grid, makeStyles}   from '@material-ui/core';
import Button               from '@material-ui/core/Button';
import Dialog               from '@material-ui/core/Dialog';
import DialogContent        from '@material-ui/core/DialogContent';
import { Redirect }                        from 'react-router-dom';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import UsabilityContext from "../../../usability/UsabilityContext";
import types from "../../../types/types";
import AuthContext from "../../../auth/AuthContext";

const useStyles = makeStyles(theme =>({

  MuiDialogContainer: {
    borderRadius: '61px',
    height: "70%",
    maxWidth: "70%",
    backgroundColor: "#FEFAF1",
    border: "#707070 2px solid",
    backdropFilter: "blur(3px)",
    [theme.breakpoints.down(1568)]:{
      height: "80%",
      maxWidth: "75%",
    },
    [theme.breakpoints.down(468)]:{
      height: "85%",
      maxWidth: "80%",
    },
  },
  selectedStyle:{
    border: "#40DAC9 5px solid",
    backgroundColor: "#DDF6FA",
    width: "100%",
    borderRadius: "5px",
  },
  continueButton:{
    marginTop: "2%",
    marginLeft:"5%",
    textTransform: 'none',
    height:'62px',
    width:'279px',  
    background: '#7A54BA',
    boxShadow: '0px 3px 6px #00000029',
    borderRadius: '61px',
    color: '#FFFFFF',
    font: 'bold 20px lato ',
    '&:hover':{
        background: '#dd78fb',
    },
    [theme.breakpoints.down(1368)]:{
      height:'52px',
      width:'229px',
    },
    [theme.breakpoints.down(1068)]:{
      height:'52px',
      width:'60%',
    },
    [theme.breakpoints.down(769)]:{
      width: "56.5%"
    },
  },
  // questionText: {
  //   textAlign: "justify",
  //   font: "18px Lato",
  //   letterSpacing:' 0.21px',
  // },
  feedbackText:{
    width: "80%",
    font: " 18px Lato",
    letterSpacing:' 0.21px',
    overflow: 'auto',
    [theme.breakpoints.down(769)]:{
      font: " 14px Lato",
    },
    [theme.breakpoints.down(600)]:{
      width: "90%",
    },
    [theme.breakpoints.down(500)]:{
      font: " 12px Lato",
    },
  },
  // questionTittle: {
  //   font:'30px Fredoka One',
  //   color: "#707070",
  //   height: "10px",
  //   letterSpacing:' 0.23px',
  // },
  feedbackTittle: {
    font:'30px Fredoka One',
    color: "#1DA698",
    height: "auto",
    margin:'10px 0px',
    letterSpacing:' 0.23px',
    [theme.breakpoints.down(769)]:{
      font:'24px Fredoka One',
      margin: '0 0 10px 0',
    },
  },
  feedbackBox:{
    border: "#80D7C6 3px solid",
    backgroundColor: "#FFFFFF",
    borderRadius: "59px",
    marginTop: "1%",
    minHeight:"50%",
    overflowWrap: 'break-word',
    [theme.breakpoints.down(600)]:{
      padding: '5%',
    },
  },
  correctIcon:{
    width:"40px",
    height:"40px",
  },
  primaryIconFeedback:{
    color:"#1DC491"
  },
  sizeLargeIcon:{
    fontSize: "50px"
  },
  questionAndAnswer:{
    width:"80%",
    [theme.breakpoints.down(600)]:{
      width:"100%",
    },
  },
  questionContainer:{
    margin:"5% 0 5% 0"
  },
  mainContainer:{
    height:"70%",
  },
  iconsContainer:{
    paddingRight:"5%"
  },
  buttonContainer:{
    height:"30%",
    [theme.breakpoints.down(769)]:{
      height:"20%",
    },
  },
}));


const FinalFeedback = (props) => {
  const { 
    open, 
    setOpen, 
    feedback, 
    valueAnswerText, 
    totalQuestions, 
    activityNumber, 
    evaluationType, 
    formativa,
    tryNumber,
    badgesType
  } = props
  const classes = useStyles()
  const [finished, setFinished]     = React.useState(false)

  // const handleClose = (e, reason) => {
  // }

  const { handleSetHeart } = useContext(UsabilityContext); 
  const {
    dispatch,
  } = useContext(AuthContext);

  const finalStep = () => {
    setFinished(true)
    setOpen(false)
    if(tryNumber === 1){
      if(badgesType){
        dispatch({
          type: types.insignias,
          payload: {
            insignia: badgesType,
          },
        }); 
      }
    if(!formativa && !evaluationType){
      handleSetHeart()
    }
    }
  }
  
  const renderResults = () => {
    
    if(totalQuestions > 10){
      return(
        // <Redirect to={{ pathname: `/Heraldo2/${activityNumber}/1/${totalQuestions}` }} />
        <Redirect to={{ pathname: `/resultado/${evaluationType}/${activityNumber}/${tryNumber}/${totalQuestions}` }} />
      )
    } else if(formativa){
      return(
        <Redirect to={{
           pathname: `/resultado/${formativa}/${activityNumber}/${tryNumber}`,
           state: { formativa: formativa, activityNumber: activityNumber, intento: tryNumber  }
          }} 
        />
      )
    } else {
      return(
        <Redirect to={{ pathname: `/resultado/${activityNumber}/${tryNumber}` }} />
      )
    }
  }

  // console.log(props, "EVA")
  return(
    <Dialog open={open} classes={{paper: classes.MuiDialogContainer}} disableEscapeKeyDown >
      {/* <DialogTitle>
        <Grid container direction="row" justifyContent="flex-end" alignItems="center" className={classes.iconsContainer}>

        </Grid>
      </DialogTitle> */}
      <DialogContent>
        <Grid container item direction="row" className={classes.mainContainer}>
          <Grid container item direction="column" justifyContent="flex-start" alignItems="center">
            <Grid container justifyContent="center" alignItems="center" className={classes.questionAndAnswer}>
              <Grid container item justifyContent="center" alignItems="center">
                <CheckCircleIcon 
                  color="primary"
                  fontSize="large"
                  classes={{colorPrimary: classes.primaryIconFeedback, fontSizeLarge: classes.sizeLargeIcon}}
                />
                <CancelIcon 
                  color="disabled" 
                  fontSize="large" 
                  classes={{fontSizeLarge: classes.sizeLargeIcon}}
                />
              </Grid>
              <Grid container item justifyContent="center" alignItems="center">
                <h2 className={classes.feedbackTittle}>Retroalimentación</h2>
              </Grid>
              <Grid container item  justifyContent="center" alignItems="center">
                <p  dangerouslySetInnerHTML={{__html: `${feedback}`}} className={classes.feedbackText}
                >
                </p>
              </Grid>
              <Grid container item justifyContent="center" alignItems="flex-start" className={classes.feedbackBox}>
                <p className={classes.feedbackText}>
                  {valueAnswerText}
                </p>
              </Grid>
              <Grid container item justifyContent="center" alignItems="center" className={classes.buttonContainer}>
                <Button id="BotonFinalizarFeedback"  name="BotonFinalizarFeedback" onClick={ finalStep } className={classes.continueButton} >Finalizar</Button>


              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      {
        finished && renderResults()
      }
    </Dialog>
  )
}

export default FinalFeedback;
