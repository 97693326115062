import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
     Contenedor:{
        background:'#E9FBF9',
        width:'100vw',
        overflowX:'clip',
        [theme.breakpoints.down(1161)]:{
            background:'none',
           overflowX:'unset',
        },
        [theme.breakpoints.down(600)]:{
            marginTop: '79.51px',
        }
    },
  ContainerInfoLectura: {
    position: "relative",
    height: "86px",
    [theme.breakpoints.down(502)]:{
        height: "43px",
    },
    background: "#76D4C3",
    "& .BarraInfoLectura": {
      height: "86px",
      background: `Url(https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Barras/Azul.png)`,
        [theme.breakpoints.down(502)]:{
            height: "43px",
        },
    },
    "& .InfoLectura": {
      position: "absolute",
      height: "86px",
      width: "219px",
      boxSizing: "border-box",
      padding: "17.2px 0",
      background: "#FAFAFA",
      borderRadius: "50px",
      boxShadow: "2px 2px 2px 2px #00000029",
      zIndex: "4",
        [theme.breakpoints.down(502)]:{
            height: "43px",
            width: "109px",
            padding: "8.6px 0",
            borderRadius: "25px",
        },
    },
    "& .InfoLectura:before": {
      position: "absolute",
      display: "flex",
      boxSizing: "border-box",
      padding: "0 0 0 10px",
      justifyContent: "center",
      alignItems: "center",
      height: "39.88px",
      width: "39.88px",
      borderRadius: "50%",
      content: '""',
      boxShadow: "3px 0px .5px 0px #00000029",
      background: "#FAFAFA",
      left: "90px",
      top: "65px",
      transform: "rotate(0.25turn)",
      [theme.breakpoints.down(502)]:{
        left: "45px",
        top: "30px",
        height: "20.88px",
        width: "20.88px",
        },
    },
    "& .InfoLectura:after": {
      content: '""',
      height: "15px",
      width: "15px",
      borderTop: "2px solid #20A698",
      borderLeft: "2px solid #20A698",
      position: "absolute",
      bottom: "-10px",
      left: "Calc(50% - 8px)",
      transform: "rotate(0.625turn)",
      [theme.breakpoints.down(502)]:{
        height: "10px",
        width: "10px",
        left: "Calc(50% - 4.5px)",
        bottom: "-3px",
        borderTop: "1px solid #20A698",
        borderLeft: "1px solid #20A698",
        },
    },
    "& .Sesion": {
      borderLeft: "2px solid #D8D8D8",
    },
    "& .Dato": {
      margin: "0 0 5px 0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#20A698",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        margin: "0 0 2.5px 0",
        },
    },
    "& .Caracter": {
      margin: "0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#000000",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        },
    },   },
    ContenedorLectura:{
        background:'#fff',
        width:'1160px',
        height:'100%',
        padding:'60px 168px 35px ',
        [theme.breakpoints.down(1161)]:{
            width:'502px',
            padding:'60px 0 0 0',
          },
        [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
        '& .ContainerDerechos':{
            bottom:'80px',
            [theme.breakpoints.down(1161)]:{
                display:'none',
            },
        },
        '& .Derechos':{
            textAlign:"center",
            margin:"0px",
            font:" 15px lato",
            color:"#717476",
        },
        '& .Escritorio':{
            display:'block',
            [theme.breakpoints.down(1161)]:{
                display:'none'
            },
        },
        '& .Tablet':{
            display:'none',
            [theme.breakpoints.down(1161)]:{
                display:'block'
            },
        }
    },
    ContainerTitulo:{
        margin:'0 0 34px 0',
        [theme.breakpoints.down(1161)]:{
            margin:'0 0 34px 0',
        },
        '& .Titulo':{
            letterSpacing: '0px',
            color: '#E1AF10',
            margin:'0',
            textAlign:'center',
            font:'48px fredoka one',
            lineHeight:'58px',
            [theme.breakpoints.down(1161)]:{
                font:'43px fredoka one',
                lineHeight:'52px',
            },
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        position:'relative',
        margin:' 0 0 36px 0',
        '& .Lectura':{
            lineHeight : '30px',
            margin:'0 0 36px 0',
            font:'22px lato',
            color:'#000',
            letterSpacing: '0.20px',
            [theme.breakpoints.down(1161)]:{
                font:'18px lato',
                lineHeight :'28px',
                letterSpacing: '0.17px',
            },
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .Imagen':{
            [theme.breakpoints.down(1161)]:{
                width:'100%',
            },
        }, 
    }
}));

const La_quena_de_oro_Adaptación_de_una_leyenda_peruana = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
             <Grid container item justifyContent="center" className={classes.ContainerInfoLectura}>
               <Grid container className="BarraInfoLectura"></Grid>
               <Grid container item className="InfoLectura">
                    <Grid container item direction="column" xs={6} justifyContent="center">
                        <p className="Dato">Grado</p>
                        <p className="Caracter">4</p>
                    </Grid>
                    <Grid container item direction="column" xs={6} justifyContent="center" className="Sesion">
                        <p className="Dato">Sesión</p>
                        <p className="Caracter">57</p>
                    </Grid>
               </Grid>
            </Grid>

            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >
                        La quena de oro. <br className='Tablet'/>Adaptación<br className='Escritorio'/> de una <br className='Tablet'/>leyenda peruana                    
                    </h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                      <p className="Lectura">
                        Era un día soleado. Todo el pueblo estaba reunido junto a la laguna de Lauricocha
                        para celebrar una fecha importante. Se trataba de la boda de la hija del jefe. Era una 
                        celebración lujosa en la que había muchas cosas de comer: trucha salada, quinua y 
                        carne de pato. También había abundante chicha de cora para beber y los invitados 
                        llevaban los vestuarios más elegantes.<br/><br/> 
                        
                        Todos disfrutaban de la fiesta sin reparar en lo que ocurría a su alrededor, cuando se 
                        acercó un anciano con apariencia de mendigo al lugar. El jefe lo miró, hizo un gesto de 
                        desaprobación y se acercó de inmediato para pedirle que se fuera porque él no era 
                        parte de la fiesta. El anciano respondió que solamente quería algo de comer porque 
                        tenía mucha hambre y pidió a todos que mostraran compasión por él, pues no era 
                        más que un hombre pobre que pendía de la caridad de las personas. Pero el jefe lo 
                        ignoró y nuevamente le solicitó que se retirara, esta vez con más insistencia. <br/><br/>
                        
                        El hombre estaba a punto irse cuando uno de los invitados corrió tras él, conmovido 
                        por su situación. Se trataba de un pastor pobre. Le ofreció su casa para que comiera y 
                        reposara con tranquilidad y confianza. Afirmó que ahí tenía agua y papas de su 
                        cosecha que podía compartirle. El mendigo aceptó agradecido el gesto y se dirigió al 
                        hogar del pastor. Una vez ahí, le ofreció permanecer el tiempo que deseara. <br/><br/>
                        
                        Cuando despertó a la mañana siguiente, vio que el anciano emitía un brillo tan fuerte 
                        que no podía verlo directamente. Entonces, éste confesó:<br/><br/>
                        
                        —Soy tu padre, el Sol. Me disfracé de esa manera para conocer el verdadero corazón 
                        de mis hijos. Tú fuiste bueno conmigo sin esperar nada a cambio. Eres una buena 
                        persona y por eso te regalo esta quena de oro. Cada vez que quieras algo, sopla en 
                        ella y tus deseos se cumplirán.
                      </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img4to/Sesion57/1.png" alt="Tarjeta" />
                    </Grid>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura ">
                            El pastor estaba a punto de agradecer el regalo cuando el Sol desapareció. Decidió 
                            probar su quena y la sopló, deseando tener más ovejas y carneros. Sus nuevos 
                            animales aparecieron como por arte de magia frente a él. Sopló una vez más, pero 
                            esta vez pidió más pasto para alimentar a su ganado. De repente, el campo se 
                            convirtió en un pastizal. <br/><br/>
                            
                            Nadie entendía por qué de un momento a otro tenía tantos animales si él era tan 
                            pobre. El jefe lo visitó para saciar su curiosidad. Cuando estaba por llegar, escuchó al 
                            pastor decir: <br/><br/>
                            
                            —Papá Inti, señor Sol, ahora necesito agua para mis animales.<br/><br/>
                            
                            Y el jefe vio surgir una gran laguna en medio del pastizal. <br/><br/>
                            
                            Se acercó a él asombrado por la magia y lo convenció de que le diera la quena. Dijo 
                            que él la utilizaría para dar riquezas a todos los habitantes del pueblo.<br/><br/>
                            
                            El pastor aceptó porque era generoso y ya tenía todo lo que anhelaba. El jefe sopló la 
                            quena para ordenar palacios y riquezas en cuanto la tuvo en sus manos. Pero nada 
                            apareció, por más que lo pidió hasta el cansancio. Lanzó la quena con enojo y se 
                            marchó.<br/><br/>
                            
                            Entonces, el pastor decidió esconderla para regresarla al Sol. Algún día, éste la 
                            obsequiará a alguien más que demuestre tener buen corazón.
                        </p>
                    </Grid>
                </Grid>
                <Grid container item xs={12} justifyContent="center" className="ContainerDerechos">
                    <p className="Derechos">
                        © 2022. Todos los derechos reservados, Pearson, Circuito de lectores.
                    </p>                
                </Grid>
        </Grid>
    </Grid>
    );
}
 
export default La_quena_de_oro_Adaptación_de_una_leyenda_peruana ;
