import React , { useState } from "react";
import { Grid, makeStyles, Radio } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from '@material-ui/icons/Close';
import axios from "../../../../services/axios";

import ModalMessage from "../../../Modals/ModalMessage";
import ModalError from "../../../Modals/ModalError";
import ModalPlataformaCargada from "./ModalPlataformaCargada";



const useStyles = makeStyles((theme) => ({
  MuiDialogContainer: {
    borderRadius: "61px",
    height: "75%",
    width: "50%",
    backgroundColor: "#FFF",
    border: "#707070 2px solid",
    backdropFilter: "blur(3px)",
    '& .Crear':{
      display:(props)=> props.Boton1 ? 'none':'flex',
    },
    '& .Datos':{
      color:'#00c851',
      font:'24px lato',
      margin:'10px 0 0 0',
    },
  },
  continueButton: {
    marginBottom: "20px",
    textTransform: "none",
    height: "62px",
    width: "279px",
    background: "#7A54BA",
    boxShadow: "0px 3px 6px #00000029",
    borderRadius: "61px",
    color: "#FFFFFF",
    font: "bold 20px lato ",
    "&:hover": {
      background: "#dd78fb",
    },
    [theme.breakpoints.down(1368)]: {
      height: "52px",
      width: "229px",
    },
    [theme.breakpoints.down(1068)]: {
      height: "52px",
      width: "60%",
    },
    [theme.breakpoints.down(769)]: {
      width: "56.5%",
    },
  },
  mainTitle: {
    font: "28px fredoka one",
    margin: "0px",
    color:'#dc5ea6',
    textAlign: "center",
    [theme.breakpoints.up("md")]: {
      font: "30px fredoka one",
    },
    [theme.breakpoints.up("xl")]: {
      font: "42px fredoka one",
    },
  },
  titleContainer: {
    margin: "20px 0",
    [theme.breakpoints.up("xl")]: {
      margin: "30px 0",
    },
  },
  title: {
    font: "28px Fredoka One ",
    margin: "0",
    textAlign: "center",
    color: " #DC5EA6",
    [theme.breakpoints.up("md")]: {
      fontSize: "36px",
    },
    [theme.breakpoints.up("xl")]: {
      fontSize: "42px",
    },
  },
  link: {
    cursor: "pointer",
    textDecoration: "none",
    // height: "31px",
    // width: "190px",
  },
  formContainer: {
    display: "flex",
    flexDirection: "column",
    width: "75%",
    gap:10,
  },
  formItemContainer: {
    gap: "10px",
  },
  input: {
    width: "100%",
    height: "40px",
    paddingLeft: "27px",
    borderRadius: "61px",
    boxSizing: "border-box",
    border: "none",
    background: "#FFFFFF",
    font: "Helvetica",
    fontSize: "16px",
    backgroundColor: "#f0f0f0",
    "&:focus": {
      outline: "none",
    },
    [theme.breakpoints.up("lg")]: {
      height: "60px",
      fontSize: "18px",
    },
  },
  input2: {
    width: "90%",
    height: "40px",
    paddingLeft: "27px",
    borderRadius: "61px",
    boxSizing: "border-box",
    border: "none",
    background: "#FFFFFF",
    font: "Helvetica",
    fontSize: "16px",
    backgroundColor: "#f0f0f0",
    "&:focus": {
      outline: "none",
    },
    [theme.breakpoints.up("lg")]: {
      height: "60px",
      fontSize: "18px",
    },
  },
  label: {
    fontWeight: "bold",
    fontFamily: "Lato, sans-serif",
  },
  radio: {
    color: "#222222",
    "&.Mui-checked": {
      color: "#5dc137",
    },
    "&:hover": {
      backgroundColor: "#22222210",
    },
    "&.Mui-checked:hover": {
      backgroundColor: "#5dc13720",
    },
  },
  PurpleButton: {
    color: "#FFFFFF",
    backgroundColor: "#9051b1",
    borderRadius: "30px",
    minWidth: "200px",
    alignSelf: "center",
    width: "100%",
    margin:'25px 0 0 0',
    maxWidth: "238px",
    minHeight: "45px",
    border: "none",
    textTransform: "unset",
    cursor: "pointer",
    fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
    fontWeight: "500",
    padding: "6px 16px",
    lineHeight: "1.75",
    transition:
      "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    boxShadow:
      "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
    "&:hover": {
      backgroundColor: "#B965D3 ",
    },
  },
  WitheButton: {
    color: "#9051b1",
    border: "1px solid #9051b1",
    backgroundColor: "#FFFFFF",
    borderRadius: "30px",
    minWidth: "200px",
    width: "100%",
    maxWidth: "238px",
    minHeight: "45px",
    textTransform: "uppercase",
    cursor: "pointer",
    fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
    fontWeight: "500",
    padding: "6px 16px",
    lineHeight: "1.75",
    transition:
      "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    boxShadow:
      "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
    "&:hover": {
      backgroundColor: "#B965D330 ",
    },
  },
}));

const GenericActivityDialog = (props) => {
  const classes = useStyles();
  const [formData, setFormData] = useState({});
  const [confirmChangesResult, setConfirmChangesResult] = useState("");
  const [schoolDataUploaded, setSchoolDataUploaded] = useState({});
  const [loadSuccessfullyModal, setLoadSuccessfullyModal] = useState(false);
  const [modalMessageOpen, setModalMessageOpen] = useState(false);
  const [resultError, setResultError] = useState("");
  const [modalErrorOpen, setModalErrorOpen] = useState(true);
  const [modalErrorDetailsOpen, setModalErrorDetailsOpen] = useState(false);
  

  const handleCloseMessageModal = () => {
    setModalMessageOpen(!modalMessageOpen);
    setResultError("");
  };
  const handleCloseErrorModal = () => {
    setModalErrorOpen(!modalErrorOpen);
  };
  const handleCloseErrorDetailsModal = () => {
    setModalErrorOpen(false);
    setModalErrorDetailsOpen(!modalErrorDetailsOpen);
  };
  const confirmChanges = (e) => {
    e.preventDefault();
    if (
      formData.plataforma &&
      formData.correo_plaforma &&
      formData?.habilitado !== undefined &&
      formData.nombre 
    ) {

      const body = {
        url: formData?.plaforma,
        correo: formData?.correo_plaforma,
        nombre: formData?.nombre,
        habilitado: formData?.habilitado,
      };
      axios
        .post("/lti/set", body)
        .then((response) => {
          if (response.status === 200) {
            const data = response.data.resultado;
            setSchoolDataUploaded(data);
            setLoadSuccessfullyModal(true);
            setFormData({});
          }
        })
    } else {
      setModalMessageOpen(true);
      setConfirmChangesResult("Asegúrese de llenar todos los datos");
    }
  };
  const { open, setOpen } = props;

  const handleClose = () => {
    setOpen(false);
  };

  const handleForm = (e) => {
    if(e.target.name === "habilitado"){
      const isTrue = e.target.value === "true"
      setFormData({
        ...formData,
        habilitado: isTrue,
      })
    }else{
      setFormData({
        ...formData,
        [e.target.name.toLowerCase()]: e.target.value,
      });
    }
  };

  return (
    <Dialog
      open={open}
      classes={{ paper: classes.MuiDialogContainer }}
      disableEscapeKeyDown
      maxWidth="lg"
    >
      <DialogActions>
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogActions>

      <DialogContent>
        <Grid
          container
          item
          direction="column"
          alignItems="center"
          justifyContent="center"
          className="Crear"
        >
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="center"
          >
            <Grid
              container
              item
              alignItems="center"
              justifyContent="center"
              className={classes.titleContainer}
            >
              <h2 className={classes.mainTitle}>Crear plataforma</h2>
            </Grid>
          </Grid>
          <form
            className={classes.formContainer}
            justifyContent="center"
            alignItems="center"
            onSubmit={confirmChanges}
          >
            <Grid
              container
              direction="column"
              className={classes.formItemContainer}
            >
              <label htmlFor="input-url" className={classes.label}>
                URL de plataforma
              </label>
              <input
                type="text"
                className={classes.input}
                placeholder="Https.."
                name="plataforma"
                required
                id="input-url"
                value={formData.plataforma}
                onChange={handleForm}
              />
            </Grid>
            <Grid
              container
              direction="column"
              className={classes.formItemContainer}
            >
              <div>
                <div>Habilitado</div>
                <Radio
                  className={classes.radio}
                  type="radio"
                  id="si"
                  name="habilitado"
                  value={true}
                  checked={formData.habilitado === true}
                  onChange={handleForm}
                />
                <label htmlFor="si">Si</label>

                <Radio
                  className={classes.radio}
                  type="radio"
                  id="no"
                  name="habilitado"
                  value={false}
                  checked={formData.habilitado === false}
                  onChange={handleForm}
                />
                <label htmlFor="no">No</label>
              </div>
            </Grid>
            <Grid container item justifyContent="space-between">
              <Grid container item sm={6} className={classes.formItemContainer}>
                <label htmlFor="input-correo" className={classes.label}>
                  Correo de contacto
                </label>
                <input
                  type="email"
                  name="correo_plaforma"
                  className={classes.input2}
                  id="input-correo"
                  title="Validar que sea un correo legitimo perteneciente a algun domino  por ejemplo ejemplo@mail.com"
                  required
                  value={formData.correo_plaforma}
                  onChange={handleForm}
                />
                
              </Grid>
              
              <Grid container item sm={6} className={classes.formItemContainer}>
                <label htmlFor="input-nombre" className={classes.label}>
                  Nombre
                </label>
                <input
                  type="text"
                  className={classes.input2}
                  id="input-nombre"
                  name="nombre"
                  required
                  value={formData.nombre}
                  onChange={handleForm}
                />
              </Grid>
            </Grid>

            <button className={classes.PurpleButton}>
              Agregar
            </button>
          </form>
        </Grid>

        <ModalMessage
          open={modalMessageOpen}
          handleClose={handleCloseMessageModal}
          title={confirmChangesResult}
        />
        <ModalError
          open={modalErrorOpen}
          handleClose={handleCloseErrorModal}
          type={resultError}
          handleNext={handleCloseErrorDetailsModal}
        />
        <ModalPlataformaCargada
          schoolName={formData?.nombre_escuela}
          open={loadSuccessfullyModal}
          handleClose={() => {
            setLoadSuccessfullyModal(!loadSuccessfullyModal);
          }}
          dataPlatorma={schoolDataUploaded}
        />
      </DialogContent>
    </Dialog>
  );
};

export default GenericActivityDialog;
