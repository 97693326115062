import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
    ContenedorLectura:{
        width:'502px',
        [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
    },
    ContainerTitulo:{
        margin:'0 0 38px 0',
        '& .Titulo':{
            color: '#FF0027',
            margin:'0',
            font:'43px fredoka one',
            lineHeight:'52px',
            textAlign:'center',
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        '& .Lectura':{
            margin:'0 0 36px 0 ',
            color:'#000',
            font:'18px lato',
            letterSpacing:'.17px',
            lineHeight:'28px',
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .SubTitulo':{
            margin:'0',
            color:'#FF0027',
            font:'bold 26px lato',
            letterSpacing:'.17px',
            lineHeight:'28px',
            [theme.breakpoints.down(502)]:{
                font:'22px Fredoka One',
                lineHeight:'24px',
            },
            
        },
        '& .SubTitulo2':{
            color:'#FFC300'
        },
        '& .Kit':{
            margin:'0',
            font:'18px Fredoka One',
            letterSpacing:'.17px',
            lineHeight:'28px',
            [theme.breakpoints.down(502)]:{
                font:'16px Fredoka One',
                letterSpacing:'.17px',
                lineHeight:'26px',
            },
        },
        '& .Maria':{
            color:'#FF5733'
        },
        '& .Clara':{
            color:'#FFC300'
        },
        '& .Pedro':{
            color:'#C70039'
        },
        '& .Lucia':{
            color:'#900C3E'
        },
        '& .Lorenzo':{
            color:'#571845'
        },
        '& .Imagen':{
            margin:'0 0 36px 0',
            width:'100%',
        }

    },

}));

const La_basura_que_vino_de_lejos = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>

            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.Parrafo1} >
                    <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                        <h2 className="Titulo " >
                            La basura que <br/>vino de lejos
                        </h2>
                    </Grid>
                    <p className="Lectura">
                        <h3 className='SubTitulo'>ACTO 1</h3><br/>
                            <em>María, Lucía, Pedro, Lorenzo y Clara sentados en la banqueta afuera de casa de María.</em>
                    </p>

                    <Grid container justifyContent='center' >
                        <img className='Imagen' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion57/1.png"  alt="Tarjeta" />
                    </Grid>

                    <p className='Lectura'>
                        <b className='Kit Clara'>CLARA.</b> —¡Al fin de vacaciones! No voy a extrañar levantarme temprano para llegar 
                        a tiempo a la escuela. <br/><br/>

                        <b className='Kit Maria'>MARÍA.</b> —¡Sí! Yo podré desayunar con calma mientras veo la televisión. <br/><br/>

                        <b className='Kit Pedro'>PEDRO.</b> —Yo sí voy a extrañar ir a la escuela. <br/><br/>

                        <b className='Kit Lucia'>LUCÍA.</b> —Jejeje. Eso ya lo sabíamos. Yo voy a extrañar jugar futbol en la hora del 
                        recreo. <br/><br/>

                        <b className='Kit Lorenzo'>LORENZO.</b> —Pero también puedes jugar cuando estés con nosotros. <br/><br/>

                        <b className='Kit Lucia'>LUCÍA.</b> —Sí, pero es que en la escuela las porterías no son un par de botes y se 
                        pueden hacer equipos de once personas. No se ofendan, me gusta pasar tiempo con
                        ustedes, pero voy a extrañar jugar futbol de esa manera. <br/><br/>
                        
                        <b className='Kit Clara'>CLARA.</b> —Hay muchas otras cosas que hacer, como andar en bicicleta en el terreno 
                        baldío que está cerca de casa de Pedro. Es muy grande y podemos divertirnos mucho
                         ahí. <br/><br/>

                        <b className='Kit Pedro'>PEDRO.</b> —Ya no podemos ir a ese lugar, ya no está vacío. Hace unos meses lo 
                        cercaron y ya no se puede entrar. <br/><br/>

                        <b className='Kit Lucia'>LUCÍA.</b> —¿Y ahora a dónde vamos a ir? <br/><br/>

                        <b className='Kit Maria'>MARÍA.</b> —Podríamos jugar aquí en la calle, pero mi mamá dice que es peligroso 
                        porque pasan muchos automóviles. <br/><br/>

                        <b className='Kit Lucia'>LUCÍA.</b> —Sí, además, no es tan divertido. Cuando llueve aquí no se hacen charcos y 
                        no podemos hacer olas con las bicis. ¿Recuerdan el día en el que regresamos todos 
                        mojados a nuestras casas? Jajaja. <br/><br/>
                        
                        <b className='Kit Lorenzo'>LORENZO.</b> —Sí, mi mamá se enojó mucho. Oye, Pedro, ¿sabes qué pasó con ese
                         terreno? <br/><br/>

                        <b className='Kit Pedro'>PEDRO.</b> —He visto que hay montañas de botellas de plástico vacías. A veces huele 
                        feo y hay mucho humo, pero no sé bien qué pasa.
                    </p>
                    <p className="Lectura">
                        <h3 className='SubTitulo SubTitulo2'>ACTO 2</h3><br/>
                        <em>Comedor en la casa de Pedro. Pedro y sus papás a la hora de la cena.</em>
                    </p>

                    <Grid container justifyContent='center' >
                        <img className='Imagen' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion57/2.png"  alt="Tarjeta" />
                    </Grid>

                    <p className='Lectura'>
                        <b className='Kit Maria'>PAPÁ DE PEDRO.</b> —Hoy preparé sopa de lentejas, pollo asado y ensalada. <br/><br/>

                        <b className='Kit Lorenzo'>MAMÁ DE PEDRO.</b> —¡Qué rico! La sopa de lentejas me encanta. Y en verdad la 
                        necesito, porque el día en la oficina estuvo pesado. <br/><br/>

                        <b className='Kit Maria'>PAPÁ DE PEDRO.</b> —Dinos qué pasó. <br/><br/>

                        <b className='Kit Lorenzo'>MAMÁ DE PEDRO.</b> —Pues que tenemos un problema grave cerca de la casa, en el 
                        terreno que acaban de cerrar. Nos enteramos de quién manda basura para que 
                        nosotros nos encarguemos de ella. La siguiente semana haremos una inspección para  
                        que veamos si es cierto que están reciclando los plásticos. <br/><br/>

                        <b className='Kit Pedro'>PEDRO.</b> —¿A poco el terreno que estaba vacío ahora es un centro de reciclaje? Hoy
                         hablaba con mis amigos de ese lugar, porque nos gustaba jugar ahí. <br/><br/>

                        <b className='Kit Lorenzo'>MAMÁ DE PEDRO.</b> —Pues eso dicen. Pero en la Organización por la Defensa del 
                        Ambiente creemos que eso no es cierto. <br/><br/>

                        <b className='Kit Pedro'>PEDRO.</b> —Entonces, ¿qué creen que hacen? <br/><br/>

                        <b className='Kit Lorenzo'>MAMÁ DE PEDRO.</b> —Suponemos que están quemando plásticos en lugar de 
                        reutilizarlos. Y eso no está permitido por la ley, es ilegal. <br/><br/>

                        <b className='Kit Pedro'>PEDRO.</b> —¿Por qué antes no hacían eso? <br/><br/>

                        <b className='Kit Maria'>PAPÁ DE PEDRO.</b> —Lo que pasa es que el año pasado empezó a llegar a nuestro país 
                        basura de otros países, principalmente de Estados Unidos, con el pretexto de que la necesitamos para reutilizarla. <br/><br/>

                        <b className='Kit Pedro'>PEDRO.</b> —¿Por qué esos países no se quedan con su basura? <br/><br/>
                        
                        <b className='Kit Maria'>PAPÁ DE PEDRO.</b> —Porque producen mucha y ya no saben qué hacer con ella. 
                        Sobre todo plásticos, como botellas de refresco, de agua y de productos de belleza. Y 
                        como aquí hay empresas que se dedican a reciclar para crear nuevos productos, 
                        entonces reciben esa basura. <br/><br/>
                        
                        <b className='Kit Lorenzo'>MAMÁ DE PEDRO.</b> —Hasta hace unos años, mucha basura de Estados Unidos era 
                        enviada a China con el mismo objetivo. Pero en 2019 el gobierno de ese país prohibió 
                        que se importaran esos desechos a su territorio. Como consecuencia, la basura 
                        empezó a llegar a los países de América Latina, como el nuestro. <br/><br/>
                        
                        <b className='Kit Maria'>PAPÁ DE PEDRO.</b> —Lo peor de todo es que hay algunos países a los que les llega 
                        más basura de la que producen. Todo esto es posible porque los gobiernos no hacen 
                        leyes más estrictas que lo impidan.<br/><br/>
                        
                        <b className='Kit Lorenzo'>MAMÁ DE PEDRO.</b> —Y justo eso es lo que nos tiene tan preocupados en el trabajo. 
                        Estamos tratando de reunir pruebas suficientes para exigirle a nuestro gobierno que 
                        haga algo. No ha sido algo sencillo, pero poco a poco lo estamos logrando. No nos 
                        podíamos quedar con los brazos cruzados viendo cómo dañan el lugar donde vivimos 
                        y la salud de nuestras familias.<br/><br/>
                        
                        <b className='Kit Pedro'>PEDRO.</b> —¡Por eso eres una súper mamá!
                    </p>
                </Grid>
        </Grid>
    </Grid>
    );
}
 
export default La_basura_que_vino_de_lejos;
