import React from "react";
import { styled } from "@mui/system";
import {
  FormControl,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
} from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  select: {
    width: "100%",
    textTransform: "capitalize",
    // maxWidth: "560px",
    "&::after": {
      content: "▴",
      position: "absolute",
      color: "rgb(238, 205, 103)",
      fontSize: "30px",
      lineHeight: "20px",
    },
  },
  menuItem: {
    textTransform: "capitalize",
  },
  status: {
    textTransform: "none",
    marginLeft: "10px",
  },
}));

const SelectCustimized = styled(Select)(({ theme }) => ({
  "& .MuiSelect-icon": {
    color: "#EECD67",
  },
  "& .MuiSelect-select.MuiSelect-select": {
    padding: "11.5px",
    paddingLeft: "27px",
    paddingRight: "27px",
    backgroundColor: "#f0f0f0",
    // paddingLeft: "27px",
    borderRadius: "61px",
    fontSize: "16px",
    boxSizing: "border-box",

    "& :focus": {
      backgroundColor: "transparent",
    },
  },
}));
const FormControlCustimized = styled(FormControl)(({ theme }) => ({
  "& .MuiInputLabel-formControl": {
    transform: "translate(0px, 0px) scale(1)",
    left: "0px",
    top: "-10px",
    color: "#000",
    fontFamily: "Lato, sans-serif",
    fontWeight: "bold",
    textTransform: "capitalize",
  },
  "& .MuiFormLabel-root.Mui-focused": {
    transform: "translate(0px, -0px) scale(1)",
    top: "-10px",
    color: "#000",
    fontFamily: "Lato, sans-serif",
    fontWeight: "bold",
    textTransform: "capitalize",
  },
}));

const SelectGradeGroup = ({
  list = [],
  title = "",
  handleForm,
  value,
  formData,
  defaultValue,
  disabled,
}) => {
  const classes = useStyles();

  const checkDisabled = (grupo) => {
    //Rol 1 Alumno
    //Rol 2 Docente
    if (title === "grupo") {
      if (formData.perfil === 1) {
        return grupo?.existente === false;
      } else if (formData?.perfil === 2) {
        return grupo?.existente === true && grupo?.docentes === 2;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const checkTextToDisabled = (grupo) => {
    //Rol 1 Alumno
    //Rol 2 Docente
    if (title === "grupo") {
      if (formData.perfil === 1) {
        return grupo?.existente === false && "Aún no se ha creado el grupo";
      } else if (formData?.perfil === 2) {
        return (
          grupo?.existente === true &&
          grupo?.docentes === 2 &&
          " El grupo ya tiene dos docentes asignado"
        );
      } else {
        return "";
      }
    } else {
      return "";
    }
  };
  return (
    <FormControlCustimized fullWidth>
      <InputLabel htmlFor={`demo-simple-select-${title}`}>{title}</InputLabel>
      <SelectCustimized
        id={`demo-simple-select-${title}`}
        value={formData[title] || ""}
        // defaultValue={defaultValue}
        label={title}
        onChange={handleForm}
        className={classes.select}
        name={title}
        disableUnderline
        disabled={disabled}
      >
        {list.map((item, index) => (
          <MenuItem
            value={
              item?.id_grado ||
              item?.grado ||
              item?.id_grupo ||
              item?.grupo ||
              ""
            }
            key={
              `${item?.id_grado}${index}` || `${item?.id_grupo}${index}` || ""
            }
            disabled={checkDisabled(item)}
            className={classes.menuItem}
          >
            {item?.grado || item?.grupo}
            <span className={classes.status}>{checkTextToDisabled(item)}</span>
          </MenuItem>
        ))}
      </SelectCustimized>
    </FormControlCustimized>
  );
};

export default SelectGradeGroup;
