import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
      Contenedor:{
        background:'#E9FBF9',
        width:'100vw',
        overflowX:'clip',
        [theme.breakpoints.down(1161)]:{
            background:'none',
           overflowX:'unset',
        },
        [theme.breakpoints.down(600)]:{
            marginTop: '79.51px',
        }
    },
 ContainerInfoLectura: {
    position: "relative",
    height: "86px",
    [theme.breakpoints.down(502)]:{
        height: "43px",
    },
    background: "#76D4C3",
    "& .BarraInfoLectura": {
      height: "86px",
      background: `Url(https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Barras/Azul.png)`,
        [theme.breakpoints.down(502)]:{
            height: "43px",
        },
    },
    "& .InfoLectura": {
      position: "absolute",
      height: "86px",
      width: "219px",
      boxSizing: "border-box",
      padding: "17.2px 0",
      background: "#FAFAFA",
      borderRadius: "50px",
      boxShadow: "2px 2px 2px 2px #00000029",
      zIndex: "4",
        [theme.breakpoints.down(502)]:{
            height: "43px",
            width: "109px",
            padding: "8.6px 0",
            borderRadius: "25px",
        },
    },
    "& .InfoLectura:before": {
      position: "absolute",
      display: "flex",
      boxSizing: "border-box",
      padding: "0 0 0 10px",
      justifyContent: "center",
      alignItems: "center",
      height: "39.88px",
      width: "39.88px",
      borderRadius: "50%",
      content: '""',
      boxShadow: "3px 0px .5px 0px #00000029",
      background: "#FAFAFA",
      left: "90px",
      top: "65px",
      transform: "rotate(0.25turn)",
      [theme.breakpoints.down(502)]:{
        left: "45px",
        top: "30px",
        height: "20.88px",
        width: "20.88px",
        },
    },
    "& .InfoLectura:after": {
      content: '""',
      height: "15px",
      width: "15px",
      borderTop: "2px solid #20A698",
      borderLeft: "2px solid #20A698",
      position: "absolute",
      bottom: "-10px",
      left: "Calc(50% - 8px)",
      transform: "rotate(0.625turn)",
      [theme.breakpoints.down(502)]:{
        height: "10px",
        width: "10px",
        left: "Calc(50% - 4.5px)",
        bottom: "-3px",
        borderTop: "1px solid #20A698",
        borderLeft: "1px solid #20A698",
        },
    },
    "& .Sesion": {
      borderLeft: "2px solid #D8D8D8",
    },
    "& .Dato": {
      margin: "0 0 5px 0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#20A698",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        margin: "0 0 2.5px 0",
        },
    },
    "& .Caracter": {
      margin: "0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#000000",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        },
    },   },
    ContenedorLectura:{
        position:'relative',
        background:'#fff',
        width:'1160px',
        padding:'60px 168px 35px',
        [theme.breakpoints.down(1161)]:{
            width:'502px',
            padding:'60px 0 0 0',
          },
        [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
        '& .ContainerDerechos':{
            bottom:'80px',
            [theme.breakpoints.down(1161)]:{
                display:'none',
            },
        }, 
        '& .Derechos':{
            textAlign:"center",
            margin:"0px",
            font:" 15px lato",
            color:"#717476",
        },
        '& .Escritorio':{
            display:'block',
            [theme.breakpoints.down(1161)]:{
                display:'none'
            },
        },
        '& .Tablet':{
            display:'none',
            [theme.breakpoints.down(1161)]:{
                display:'block'
            },
        }
    },
    ContainerTitulo:{
        margin:'0',
        '& .Titulo':{
            textAlign:'center',
            font:'48px fredoka one',
            letterSpacing: '0px',
            color: '#926DD1',
            margin:'0',
            [theme.breakpoints.down(1280)]:{
                font:'43px fredoka one',
                lineHeight:'52px',
            },
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Cauadro:{
        position:'absolute',
        left:'200px',
        width: '763px',
        border:'5px #fff solid',
        background:'rgba(255, 255, 255, 0.65)',
        borderRadius: '50px',
       ' -webkit-backdrop-filter': 'blur(30px)',
        backdropFilter:'blur(30px)',
        [theme.breakpoints.down(1161)]:{
            left:'32px',
            width: '438px',
        },
        [theme.breakpoints.down(502)]:{
            borderRadius: '25px',
            left:'15px',
            width: '320px',
        },
        '& .TextoCuadro':{
            font:'22px lato',
            color:'#000',
            margin:'25px 25px 18px 36px',
            letterSpacing: '0.20px',
            [theme.breakpoints.down(1280)]:{
                font:'18px lato',
                lineHeight :'28px',
                letterSpacing: '0.17px',
                margin:'15px 3px 0px 24px',
            },
            [theme.breakpoints.down(502)]:{
                margin:'10px 3px 0px 10px',
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
        },
    },
    Cauadro1:{
        height:'220px',
        top:'805px',
        [theme.breakpoints.down(1161)]:{
            height:'220px',
            top:'540px',
        },
        [theme.breakpoints.down(502)]:{
            height:'120px',
            top:'405px',
        },
    },
    Cauadro2:{
        height:'115px',
        top:'1675px',
        [theme.breakpoints.down(1161)]:{
            '& .TextoCuadro':{
                margin:'10px 3px 0px 24px',
            },
            height:'85px',
            top:'1210px',
        },
        [theme.breakpoints.down(502)]:{
            '& .TextoCuadro':{
                margin:'10px 3px 0px 10px',
            },
            height:'85px',
            top:'855px',
        },
    },

    Cauadro3:{
        height:'72px',
        top:'2535px',
        '& .TextoCuadro3':{
            margin:'18px 25px 18px 36px',
        },
        [theme.breakpoints.down(1161)]:{
            '& .TextoCuadro3':{
                margin:'8px 5px 0 0px',
            },
            width:'487px',
            left:'7.5px',
            height:'53px',
            top:'1765px',
        },
        [theme.breakpoints.down(502)]:{
            '& .TextoCuadro3':{
                margin:'8px 0px 0 8px',
            },
            left:'0px',
            width:'360px',
            height:'65px',
            top:'1250px',
        },
        

    },

    Cauadro4:{
        height:'72px',
        top:'3175px',
        '& .TextoCuadro4':{
            margin:'18px 25px 18px 36px',
        },
        [theme.breakpoints.down(1161)]:{
            '& .TextoCuadro4':{
                margin:'10px 0 0 0',
            },
            width:'511px',
            left:'0px',
            height:'53px',
            top:'2190px',
        },
        [theme.breakpoints.down(502)]:{
            '& .TextoCuadro4':{
                margin:'8px 0 0 8px',
            },
            left:'0px',
            width:'360px',
            height:'65px',
            top:'1632px',
        },
    },

    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Subtitulo':{
            font:'bold 30px lato',
            color:'#FF0000',
            margin:'0 0 ',
        },
        '& .Lectura':{
            lineHeight : '30px',
            margin:'0 0 28px',
            font:'22px lato',
            color:'#000',
            letterSpacing: '0.21px',
            [theme.breakpoints.down(1280)]:{
                font:'18px lato',
                lineHeight :'28px',
                letterSpacing: '0.17px',
            },
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .Lectura1':{
            lineHeight : '30px',
            margin:'0 0 8px',
            font:'22px lato',
            color:'#000',
            letterSpacing: '0.21px',
            [theme.breakpoints.down(1280)]:{
                font:'18px lato',
                lineHeight :'28px',
                letterSpacing: '0.17px',
            },
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .Imagen':{
            width:'100%'
        },
        '& .Imagen2':{
            [theme.breakpoints.down(1161)]:{
                margin:'145px 0 0 0'
            },
            [theme.breakpoints.down(502)]:{
                margin:'100px 0 0 0'
            },
        },

    }
}));

const Otro_mirar = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
             <Grid container item justifyContent="center" className={classes.ContainerInfoLectura}>
               <Grid container className="BarraInfoLectura"></Grid>
               <Grid container item className="InfoLectura">
                    <Grid container item direction="column" xs={6} justifyContent="center">
                        <p className="Dato">Grado</p>
                        <p className="Caracter">1</p>
                    </Grid>
                    <Grid container item direction="column" xs={6} justifyContent="center" className="Sesion">
                        <p className="Dato">Sesión</p>
                        <p className="Caracter">27</p>
                    </Grid>
               </Grid>
            </Grid>

            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >Otro mirar</h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container direction="column" justifyContent="center" sm={12}>
                        <Grid container  justifyContent="center" >
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img1ro/Sesion27/1.png" alt="Tarjeta" />
                        </Grid>
                         <Grid container item className={`${classes.Cauadro} ${classes.Cauadro1}`}>
                            <p className="TextoCuadro ">
                            —¿Cómo son los colores? —me preguntó.<br/> <br/>

                            —El amarillo es como la piña, la miel  —respondi—. Se siente como el Sol.<br/> <br/>
                            
                            —El verde es el limón, el pasto recién cortado. Pero es también el olor a
                            árboles y la forma de un trébol.
                            </p>
                         </Grid>
                        
                    </Grid>
                </Grid> 

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container direction="column" justifyContent="center" sm={12}>
                        <Grid container   justifyContent="center" >
                            <img className="Imagen Imagen2" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img1ro/Sesion27/2.png" alt="Tarjeta" />
                        </Grid>
                         <Grid container item className={`${classes.Cauadro} ${classes.Cauadro2}`}>
                            <p className="TextoCuadro">
                            —El azul se siente como la tristeza. Un día con nubes y lluvia. Suena 
                            como las olas del mar.
                            </p>
                         </Grid>
                    </Grid>
                </Grid>  

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container direction="column" justifyContent="center" sm={12}>
                        <Grid container   justifyContent="center" >
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img1ro/Sesion27/3.png" alt="Tarjeta" />
                        </Grid>
                         <Grid container item className={`${classes.Cauadro} ${classes.Cauadro3}`}>
                            <p className="TextoCuadro TextoCuadro3">
                            —El café es la tierra y se escucha como las hojas de otoño.
                            </p>
                         </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container direction="column" justifyContent="center" sm={12}>
                        <Grid container   justifyContent="center" >
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img1ro/Sesion27/4.png" alt="Tarjeta" />
                        </Grid>
                         <Grid container item className={`${classes.Cauadro} ${classes.Cauadro4}`}>
                            <p className="TextoCuadro TextoCuadro4">
                            —El rojo es el calor de un abrazo. Es las fresas y las frambuesas.
                            </p>
                         </Grid>
                    </Grid>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                      <p className="Lectura">
                        —El negro… —alcancé a decir, pero me interrumpió.<br/> <br/>
                        
                        —El negro lo conozco bien —añadió entusiasmada—. En él caben todos los colores, 
                        sus sonidos y sabores. Cuando los toco, algunos gritan y otros susurran. Unos son 
                        tersos y otros ásperos y granulosos. Los colores hablan con mis dedos y les 
                        cuentan que son duendes.
                      </p>
                    </Grid>
                    <Grid container direction="column" justifyContent="center" sm={12}>
                        <Grid container  justifyContent="center" >
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img1ro/Sesion27/5.png" alt="Tarjeta" />
                        </Grid>
                    </Grid>
                </Grid> 
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                      <p className="Lectura">
                        —¿Como un truco de magia? —pregunté—. Pero ella no me entendió porque nunca 
                        había visto uno.<br/> <br/>
                        
                        Ya no dije nada. Pensé en que ella tenía razón. Me han dicho que las mariposas ven 
                        muchos más colores que nosotros y que los perros ven menos. Entonces ¿de qué 
                        manera es que los colores son reales si cada uno de nosotros los ve diferente? Ella, 
                        que no ve, parece saberlo mejor que yo.
                      </p>
                    </Grid>
                </Grid>  

            <Grid container item xs={12} justifyContent="center" className="ContainerDerechos">
                <p className="Derechos">
                    © 2022. Todos los derechos reservados, Pearson, Circuito de lectores.
                </p>                
            </Grid>
        </Grid>
    </Grid>
    );
}
 
export default Otro_mirar;
