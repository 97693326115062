import { makeStyles, Grid } from "@material-ui/core";
import React from "react";

import MessageIndicator from "./MessageIndicator";

const useStyles = makeStyles((theme) => ({
  messagesContainer: {
    background: "transparent",
    width: "100%",
    minHeight: "auto",
    margin: "0",
    boxSizing: "border-box",
    maxHeight: "100%",
    display: "flex",
    overflowY: "auto",
    // justifyContent: "flex-end",
    gridArea: "messages",
    alignItems: "center",
    alignContent: "center",
    gap: "25px",
    padding: "20px",
    flexDirection: "column-reverse",
    flexWrap: "nowrap",
    zIndex: "0",
    [theme.breakpoints.up("lg")]: {
      gap: "25px",
      padding: "20px 20px",
      "&::-webkit-scrollbar": {
        width: "auto",
      },
      "&::-webkit-scrollbar-track": {
        backgroundColor: "#84E9DF20",
        // backgroundColor: "#7A54BA20",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#84E9DF",
        // backgroundColor: "#7A54BA",
        borderRadius: 5,
      },
    },
  },
  container: {
    height: "100%",
    overflowY: "hidden",
    padding: "20px",
    gridArea: "messages",
  },
}));

const ChatMessages = ({
  chatData,
  messages,
  filterSearch,
  handlePage,
  paginacion,
  habiliteScroll,
  handleHabiliteScroll,
  filterFavorites,
  imagen_perfil,
}) => {
  const classes = useStyles();

  const handleScrollY = (e) => {
    const element = e.target;
    const scrollTop = element.scrollTop * -1;
    const totalSizeHeight = element.scrollHeight;
    const offsetHeight = element.offsetHeight;

    const threshold = 16;
    //Threshold = 15 + 1px

    const elementScroll = scrollTop + offsetHeight + threshold;
    const isFinal = elementScroll >= totalSizeHeight;
    if (isFinal) {
      if (habiliteScroll) {
        handlePage();
        handleHabiliteScroll(false);
      }
    }
  };

  return (
    <>
      <Grid
        container
        item
        direction="column"
        className={classes.messagesContainer}
        onScroll={handleScrollY}
      >
        {messages
          ?.sort((a, b) => b.fecha - a.fecha)
          ?.map((message, index) => (
            <MessageIndicator
              key={message?.id_mensaje || index}
              message={message}
              imagen_perfil={imagen_perfil}
            />
          ))}
      </Grid>
    </>
  );
};

export default ChatMessages;
