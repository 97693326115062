import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
    ContenedorLectura:{
        width:'502px',
                  [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
    },

    ContainerTitulo:{
        margin:'0 0 38px 0',
        '& .Titulo':{
            letterSpacing: '0px',
            color: '#FF2B2B',
            margin:'0 0 15px 0',
            font:'43px fredoka one',
            lineHeight:'52px',
            textAlign:'center',
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
        '& .SubTitulo':{
            letterSpacing: '0px',
            color: '#FF2B2B',
            margin:'0',
            font:'26px fredoka one',
            lineHeight:'28px',
            textAlign:'center',
            [theme.breakpoints.down(502)]:{
                font:'bold 22px lato',
                lineHeight:'24px',
            },
            [theme.breakpoints.down(350)]:{
                font:'bold 18px lato',
                lineHeight:'24px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            margin:'0 0 36px 0 ',
            color:'#000',
            font:'18px lato',
            letterSpacing:'.17px',
            lineHeight:'28px',
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .Imagen':{
            width:'100%',
        },

    },
    

}));

const La_infanta_Castile_y_el_insólito_mundo_de_Anomalía = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>

            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo " >
                        La infanta Castile <br/>
                        y el insólito mundo<br/>
                         de Anomalía
                    </h2>
                    <h3 className='SubTitulo'>Fragmento 1 (capítulo 1)</h3>

                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura ">
                            Así que ahí estaban, uno detrás del otro, caminando rumbo a la casa vecina con la 
                            cautela que caracteriza a dos tipos de personas: los precavidos y los miedosos.<br/><br/> 

                            —No sé por qué tenemos que ofrecerle disculpas —protestó René mientras se 
                            esforzaba por liberarse del abrazo de su pequeña hermana para inhalar un poco de 
                            aire. <br/><br/>

                            —Tengo mucha tarea pendiente —exclamó Wil, limpiando sus anteojos—. Si sigo así, 
                            bajaré mi promedio este semestre. <br/><br/>

                            —Pero hoy es viernes —le respondió Luna alarmada—. Hoy pasan el programa de 
                            bromas que nos gusta. <br/><br/>

                            —Algún día crecerás, Luna —sentenció el hermano mayor. <br/><br/>

                            Hacía ya varios meses que Luna sentía el distanciamiento de sus hermanos. Ya 
                            ninguno de los dos disfrutaba de su compañía. Wil estaba en el año previo a la 
                            secundaria y, como siempre había sido muy estudioso, ahora ni siquiera se permitía
                            un mínimo rato de entretenimiento. René, un año menor que Wil, cada vez hablaba
                            menos, vivía encerrada imaginando su propio mundo. Luna, en cambio, recién había 
                            empezado el primer año de la primaria y no encontraba interés alguno en los 
                            pasatiempos de sus hermanos ni le preocupaba demasiado su promedio escolar. “Ya 
                            habrá tiempo de mejorarlo”, pensaba. Prefería ocupar sus tardes armando los 
                            rompecabezas que su madre llevaba a casa. <br/><br/>

                            El trabajo de la señora Casandra Lugo resultaba bastante singular: era la ilustradora 
                            de una fábrica de juguetes y de juegos de mesa. En ocasiones llevaba a casa los 
                            rompecabezas defectuosos que no podían ser vendidos por la fábrica porque les 
                            faltaba alguna pieza. Luna disfrutaba armando los escenarios y personajes que su 
                            propia mamá dibujaba, aunque siempre quedaran incompletos. La señora Lugo 
                            también había hecho bocetos de personajes para cómics e historias para niños, pero 
                            nunca había logrado publicar nada. De los tres, sólo René había heredado el talento 
                            de su madre para dibujar, aunque Luna definitivamente prefería los dibujos de su 
                            mamá porque los de su hermana le parecían oscuros y le daban miedo.<br/><br/> 

                            Cuando llegaron al patio de la propiedad vecina, los tres se detuvieron y se miraron 
                            esperando que el otro tomara la iniciativa.<br/><br/> 

                            —Es tu compañera de salón, ¿no, René? Tú la conoces más, tú deberías hablar por 
                            nosotros —resolvió Wil.
                        </p>
                    </Grid>
                    <Grid container  sm={12}>
                        <Grid container >
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion23/1.png" alt="Tarjeta" />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura ">
                            En otra situación, René hubiera objetado que Wil le ordenara ser ella quien tuviera 
                            que dar la cara, pero esta vez le había parecido un argumento razonable, sobre todo 
                            porque ella se sentía un poco culpable después del horrible episodio de la mañana. Si 
                            había alguien aún más solitaria que René, ésa era Ingrid Manjarrez. Siempre con el 
                            cabello sobre el rostro y evitando hablar con el resto de los estudiantes, Ingrid 
                            pasaba desapercibida en la primaria. René y ella cursaban el mismo grado, pero 
                            pocas veces se habían dirigido la palabra.<br/><br/>

                            Ese mismo día, en la escuela, Ingrid había llegado sin tener la menor idea de lo que le 
                            aguardaba. Durante el receso, unas alumnas se metieron al baño de mujeres y 
                            esperaron cautelosas a que Ingrid entrara a uno de los sanitarios para entonces 
                            vaciar dos frascos de pintura desde arriba directo sobre su cabeza. Ingrid salió 
                            asustada y confundida al patio central y se enfrentó a las risas burlonas de todos los 
                            presentes. Su cabello era un desastre, estaba arruinado, bañado en pintura color 
                            marrón y verde, tonos maliciosamente escogidos por sus agresoras, porque eran los 
                            colores de sus ojos. Ingrid pertenecía a ese especial grupo de personas que tienen 
                            heterocromía de iris, es decir que cada ojo era de un color diferente.<br/><br/> 

                            Mientras René cruzaba la cerca de la entrada, no podía dejar de pensar en que podía 
                            haber evitado la tragedia de Ingrid, pues ella había entrado al sanitario unos minutos 
                            antes y se había percatado de lo que el grupo de chicas planeaba. Sin embargo, había 
                            elegido ser una suerte de cómplice por haberse acobardado y no haberle advertido
                            nada. <br/><br/>

                            —¿Es Ingrid? —preguntó Luna. <br/><br/>

                            Al fondo, cruzando por el pasillo lateral de la casa, la silueta de una joven muy 
                            parecida a Ingrid caminaba en la oscuridad del jardín trasero. <br/><br/>

                            —¿Qué hace? —dijo Wil mientras los tres chicos cambiaban el rumbo y se dirigían 
                            hacia ella. <br/><br/>

                            —Tal vez no deberíamos interrumpirla, tal vez quiere estar sola —supuso René.<br/><br/>

                            —No, iremos y le ofrecerás una disculpa, así por fin mamá nos dejará en paz y yo 
                            podré volver a mi tarea —respondió Wil con dureza.<br/><br/> 

                            Luna volvió a abrazar a René y ella se colocó nuevamente detrás de Wil. “Si un 
                            hombre lobo nos ataca, que se coma primero a Wil”, pensó.<br/><br/> 
                        </p>
                    </Grid>
                </Grid>
        </Grid>
    </Grid>
    );
}
 
export default La_infanta_Castile_y_el_insólito_mundo_de_Anomalía;
