import React               from "react";
import {Grid, makeStyles}                 from '@material-ui/core';

const useStyles = makeStyles(theme =>({
  questionContainer:{
    width: "100%",
    height:"100%",
  },
  reading: {
    width: "80%",
    margin:"10px 0 10px 0 ",
    font: '22px lato',
    letterSpacing: '0.21px',
    [theme.breakpoints.down(1400)]:{
      font: " 18px lato",
      width: "95%",
    },
    [theme.breakpoints.down(1310)]:{
      font: "500 16px lato",

    },
    [theme.breakpoints.down(925)]:{
      font: "500 14px lato",

    },
    [theme.breakpoints.down(426)]:{
      font: "500 12px lato",
 
    }, 
  },
  table: {
    width: "100%",
    font: '22px lato',
    margin: '0',
    [theme.breakpoints.down(1400)]:{
      font: " 18px lato",
      width: "95%",
    },
    [theme.breakpoints.down(1310)]:{
      font: "16px lato",
 
    },
    [theme.breakpoints.down(925)]:{
      font: "14px lato",

    },
    [theme.breakpoints.down(426)]:{
      font: "12px lato",

    }, 
  },
  tdTable: {
    padding: "0 1%",
    // border: '1px solid black',
  }
}));

const QuestionTable = (props) => {
  const classes = useStyles()

  return(
    <Grid container className={classes.questionContainer}>
      <p
        className={classes.reading}
      >
        4. Une a la artista con su profesión artística y su país de origen.
      </p>
      <br/>
      <table className={classes.table}>
        <thead>
          <th style={{width: "40%", textAlign: 'left'}}><i>Artista</i></th>
          <th style={{width: "60%", textAlign: 'left'}}><i>Profesión artística/país de origen</i></th>
        </thead>
        <tbody>
          <tr>
            <td className={classes.tdTable}>
              <p className={classes.table}> 
                1. Raquel Forner
              </p>
            </td>
            <td className={classes.tdTable}>
              <p className={classes.table}>
                a. pintora (México)
              </p>
            </td>
          </tr>
          <tr>
            <td className={classes.tdTable}>
              <p className={classes.table}>
                2. María Izquierdo
              </p>
            </td>
            <td className={classes.tdTable}>
              <p className={classes.table}>
                b. pintora (Brasil)
              </p>
            </td>
          </tr>
          <tr>
            <td className={classes.tdTable}>
              <p className={classes.table}>
                3. Débora Arango
              </p>
            </td>
            <td className={classes.tdTable}>
              <p className={classes.table}>
                c. pintora y escultora (Argentina) 
              </p>
            </td>
          </tr>
          <tr>
            <td className={classes.tdTable}>
              <p className={classes.table}>
                4. Tarsila do Amaral
              </p>
            </td>
            <td className={classes.tdTable}>
              <p className={classes.table}>
                d. escultora (Chile)
              </p>
            </td>
          </tr>
          <tr>
            <td className={classes.tdTable}>
              <p className={classes.table}>
                5. Lily Garafulic
              </p>
            </td>
            <td className={classes.tdTable}>
              <p className={classes.table}>
                e. pintora (Colombia)
              </p>
            </td>
          </tr>
        </tbody>
      </table>
    </Grid>
  )
}

export default QuestionTable;
