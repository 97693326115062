import React               from "react";
import {Grid, makeStyles}                 from '@material-ui/core';

const useStyles = makeStyles(theme =>({
  questionContainer:{
    width: "100%",
    height:"100%",
  },
  reading: {
    width: "98%",
    font: "22px lato",
    letterSpacing:'0',
    margin:"14px 0 14px 0",
    [theme.breakpoints.down(1370)]:{
      font: "500 16px lato",
      width: "90%",
      margin:"10px 0 10px 0"
    },
    [theme.breakpoints.down(1310)]:{
      font: "500 16px lato",
      // width: "80%",
      margin:"10px 0 10px 0"
    },
    [theme.breakpoints.down(925)]:{
      font: "500 14px lato",
      // width: "70%",
      margin:"6px 0 6px 0"
    },
    [theme.breakpoints.down(426)]:{
      font: "500 12px lato",
      // width: "70%",
      margin:"6px 0 6px 5%"
    }, 
  },
  tableText: {
    width: "80%",
    margin:"0",
    font: '22px lato',
    letterSpacing: '0.21px',
    textAlign: 'left',
    [theme.breakpoints.down(1400)]:{
      font: " 18px lato",
      width: "85%",
    },
    [theme.breakpoints.down(1310)]:{
      font: "500 16px lato",
      // width: "80%",
    },
    [theme.breakpoints.down(925)]:{
      font: "500 14px lato",
      // width: "70%",
    },
    [theme.breakpoints.down(426)]:{
      font: "500 12px lato",
      // width: "70%",
    },
  },
  table: {
    // border: '2px solid black',
    width: "100%",
    font: '22px lato',
    [theme.breakpoints.down(1400)]:{
      font: " 18px lato",
    },
    [theme.breakpoints.down(1370)]:{
      font: "16px lato",
      width: "90%",
    },
    [theme.breakpoints.down(1310)]:{
      font: "16px lato",
    },
    [theme.breakpoints.down(925)]:{
      font: "14px lato",
      marginLeft: '10px',
    },
    [theme.breakpoints.down(426)]:{
      font: "12px lato",
    }, 
  },
  tdTable: {
    padding: "0 1%",
    verticalAlign: 'text-top'
    // border: '1px solid black',
  },
}));

const QuestionTable = (props) => {
  const classes = useStyles()

  return(
    <Grid container className={classes.questionContainer}>
      <p
        className={classes.reading}
      >
        5. Ordena correctamente el argumento que plantea una postura que desafía los roles de género.
      </p>
      <br/>
      <table className={classes.table}>
        <thead>
          <th style={{width: "40%", textAlign: "left" }}><b>Componentes</b></th>
          <th style={{width: "60%", textAlign: "left"}}><b>Argumento que desafía los roles de género</b></th>
        </thead>
        <tbody>
          <tr>
            <td className={classes.tdTable}>
              <p>
                <b>1.  Tema</b>
              </p>
            </td>
            <td className={classes.tdTable}>
              <p>
                a. Por lo tanto, todos, hombres y mujeres, podemos realizar cualquier actividad  que nos guste sin importar nuestro sexo.
              </p>
            </td>
          </tr>
          <tr>
            <td className={classes.tdTable}>
              <p>
                <b>2. Argumentos</b>
              </p>
            </td>
            <td className={classes.tdTable}>
              <p>
                b. Las capacidades de las personas no dependen de su sexo. Todo depende de las acciones
                y  el esfuerzo que realice para lograr lo  que se quiere.  Un   ejemplo   es Valentina,
                la primera mujer que viajó al espacio.
              </p>
            </td>
          </tr>
          <tr>
            <td className={classes.tdTable}>
              <p>
                <b> 3. Conclusión </b>
              </p>
            </td>
            <td className={classes.tdTable}>
              <p>
                c. Las mujeres pueden desempeñar profesiones y actividades de hombres.
              </p>
            </td>
          </tr>
        </tbody>
      </table>
    </Grid>
  )
}

export default QuestionTable;
