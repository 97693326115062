import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
    ContenedorLectura:{
        width:'502px',
                  [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
    },
    ContainerTitulo:{
        margin:'0 0 34px 0',
        '& .Titulo':{
            letterSpacing: '0px',
            color: '#823CE2',
            margin:'0',
            textAlign:'center',
            font:'43px fredoka one',
            lineHeight:'52px',
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            margin:'0 0 36px 0',
            color:'#000',
            font:'18px lato',
            lineHeight :'28px',
            letterSpacing: '0.17px',
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },

        '& .Imagen':{
            width:'100%',
        }, 

    }
}));

const Un_ecosistema_particular = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >Un ecosistema particular</h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                      <p className="Lectura">
                        Una mañana entré al cuarto de mi hermano y… ¡sorpresa! Vi que montaba un pulpo.
                        Iba de allá para acá como si la casa fuera una pecera.
                      </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <Grid container justifyContent="center" >
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img1ro/Sesion23/1.png" alt="Tarjeta" />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                      <p className="Lectura">
                        Me espanté y corrí a decirles a mis papás, pero ¡una enorme mantarraya estaba
                        tendida sobre su cama! Ellos no estaban y me salí con cuidado para no despertarla.
                      </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <Grid container justifyContent="center" >
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img1ro/Sesion23/2.png" alt="Tarjeta" />
                        </Grid>
                    </Grid>
                </Grid> 
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                      <p className="Lectura">
                        Fui a buscar a mis papás al otro cuarto y ¡mi mamá acariciaba a un pequeño ratoncito
                        que escapaba de su mano! No se dio cuenta de que yo estaba ahí porque tenía la
                        mirada fija en la computadora. Estaba tan sorprendida que, en vez de hablarle, corrí a
                        buscar a mi papá.
                      </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <Grid container justifyContent="center" >
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img1ro/Sesion23/3.png" alt="Tarjeta" />
                        </Grid>
                    </Grid>
                </Grid>      
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                      <p className="Lectura">
                        Él estaba en la cocina lavando una olla y ¡en su mano tenía un erizo!<br/><br/>
                        —¡Papá! —le grité—. ¿Por qué hay tantos animales en la casa?<br/><br/>
                        —Susana —me respondió—, los animales nos ayudan a arreglar la casa. ¿No viste
                        cómo Pulpi cuida a tu hermano?
                      </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <Grid container justifyContent="center" >
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img1ro/Sesion23/4.png" alt="Tarjeta" />
                        </Grid>
                    </Grid>
                </Grid>   
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                      <p className="Lectura">
                        Esa noche me puse a dibujar a todos los animales. A mi lado había un pelícano que
                        cortaba hojas y un caracol que tiraba babita pegajosa sobre todos mis retratos. Al
                        irme a mi cama, un lindo osito me abrazaba y me cantaba canciones para dormir.
                      </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <Grid container justifyContent="center" >
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img1ro/Sesion23/5.png" alt="Tarjeta" />
                        </Grid>
                    </Grid>
                </Grid>  
        </Grid>
    </Grid>
    );
}
 
export default Un_ecosistema_particular;
