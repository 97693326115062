import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
    ContenedorLectura:{
        width:'502px',
                  [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
    },

    ContainerTitulo:{
        margin:'0 0 46px 0',
        '& .Titulo':{
            letterSpacing: '0px',
            color: '#0CB5D6',
            margin:'0',
            textAlign:'center',
            font:'43px fredoka one',
            lineHeight:'52px',
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            margin:'0 0 36px 0 ',
            color:'#000',
            font:'18px lato',
            lineHeight :'28px',
            letterSpacing: '0.17px',
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .TextImg':{
            margin:'22px 0 0 0',
            alignSelf:'flex-start',
            color:'#717476',
            font:' 16px lato',
            lineHeight :'25px',
            letterSpacing: '0.15px',
            [theme.breakpoints.down(502)]:{
                font:' 11px lato',
                lineHeight :'14px',
                letterSpacing: '0.10px',
            },
        },
        '& .Imagen':{
            width:'100%'
        },
    },
    

}));

const Colibrí = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >
                        Colibrí
                    </h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                            El día que nació Karla, sus familiares dijeron al verla:<br/><br/> 
                            
                            —Mira qué pequeñas piernitas.<br/><br/> 
                            
                            —¡Y esa frente prominente!<br/><br/> 
                            
                            —Y su nariz chata, sus manos diminutas, sus dedos redondos.<br/><br/> 
                            
                            Karla era tan pequeña que sus padres la apodaron Colibrí.
                        </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <Grid container >
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img2do/Sesion25/1.png" alt="Tarjeta" />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                            Pasó el tiempo. Karla entró a la escuela.<br/><br/> 
                            
                            —Es chiquita como una muñeca —decían sus compañeros—. Como una ciruela.<br/><br/> 
                            
                            A Karla no le disgustaba ser pequeña: le fascinaba ser la primera de la fila y que todos 
                            la cuidaran. Le gustaba que su padre la cargara en hombros para ver la pantalla del
                            cine, para alcanzar las frutas del mercado, para observar las puestas de sol.<br/><br/> 
                            
                            Cuando jugaba con los niños del barrio, gritaban:<br/><br/> 
                            
                            —¡Cuidado con Colibrí!<br/><br/> 
                            
                            Entonces se sentía frágil, como una muñeca, aunque en el fondo estaba contenta
                            porque cabía en todas partes, hasta entre sus juguetes.
                        </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <Grid container >
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img2do/Sesion25/2.png" alt="Tarjeta" />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                            Pero llegó un día en el que Karla quiso jugar otros juegos y le dijeron:<br/><br/> 
                            
                            —¡No corras, te vas a caer!<br/><br/> 
                            
                            —¡No toques la pelota, te va a aplastar!<br/><br/> 
                            
                            A Karla ya no le gustó tanto ser chiquita. Pero siguió jugando.<br/><br/> 
                            
                            —Parece un insecto —dijo un niño.<br/><br/> 
                            
                            —Pues ¿cómo no? ¡Miren sus piernas… tan cortas! —agregó otro.<br/><br/> 
                            
                            —¡Y sus brazos son como dos alitas! —rieron todos.<br/><br/> 
                            
                            Karla se alejó velozmente para que no la vieran llorar. Se metió en un rincón del 
                            clóset, más oscuro que el estómago de un monstruo. Sintió miedo.<br/><br/> 
                            
                            En lo profundo del armario, Karla pasó del llanto a la calma. Pensó que “Colibrí” no 
                            era un apodo desagradable, aunque prefería que la llamaran por su nombre. Recordó 
                            cuando jugaba sola a lanzar la pelota y reconoció que no era débil: su estatura le 
                            resultaba útil para escabullirse entre los otros jugadores. Secó sus lágrimas y salió al 
                            campo.<br/><br/> 
                            
                            —¿Dónde estabas, Colibrí? —preguntaron.<br/><br/>
                            
                            —Fui a recordar. Recordé que mi tamaño no me hace débil <br/>—respondió, al tiempo que 
                            corría con la velocidad de un colibrí.<br/><br/> 
                            
                            Llegó hasta la pelota y con su pierna diminuta le dio un golpe tan fuerte que la lanzó 
                            hasta el otro extremo y anotó un gol.<br/><br/> 
                            
                            —¡Bravo, Karla! —aplaudió su madre, seguida del resto de los presentes, que 
                            repetían una y otra vez su nombre.<br/><br/> 
                            
                            Nunca volvieron a ponerle apodos.
                        </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <Grid container >
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img2do/Sesion25/3.png" alt="Tarjeta" />
                        </Grid>
                    </Grid>
                </Grid>
        </Grid>
    </Grid>
    );
}
 
export default Colibrí;
