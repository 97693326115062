import {
  Button,
  Dialog,
  DialogContent,
  makeStyles,
  styled,
} from "@material-ui/core";
import React from "react";
import NavButton from "../../NavBar/NavButton";
import clsx from "clsx";

import flecha from "../../imgs/adelante.png";
import Salir from "../../imgs/cerrar.png";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-root": {
    backdropFilter: "blur(10px)",
    height: "100%",
  },
  "& .MuiDialogContent-root": {
    padding: "0",
    position: "relative",
    height: "100%",
    // overflow: "hidden",
    [theme.breakpoints.up("sm")]: {
      // margin: "20px 0",
      paddingTop: "0",
      paddingBottom: "0",
    },
    [theme.breakpoints.up("sm")]: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      "@media(max-height:400px)": {
        justifyContent: "flex-start",
      },
    },
    [theme.breakpoints.up("xl")]: {
      justifyContent: "flex-start",
    },
  },
  "& .MuiDialog-paper": {
    borderRadius: "12.1px",
    backgroundColor: "transparent",
    boxShadow: "none",
    height: "100vh",
    width: "100vw",
    margin: "0px",
    maxHeight: "100vh",
    maxWidth: "1920px",
    [theme.breakpoints.up("lg")]: {
      margin: "0px",
    },
  },
  "& .MuiDialog-container": {
    // backdropFilter: "blur(10px)",
    alignItems: "flex-start",
    [theme.breakpoints.up("sm")]: {
      alignItems: "center",
    },
  },
}));

const useStyles = makeStyles((theme) => ({
  container: {
    position: "relative",
    width: "100%",
    justifyContent: "center",
    [theme.breakpoints.up(1024)]: {
      minWidth: "320px",
      justifyContent: "space-between",
    },
  },
  Barra: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "start",
    position: "fixed",
    maxWidth: "100vw",
    // overflow: "hidden",

    top: "80px",
    height: "calc(100vh - 80px)",
    // maxHeight: "1080px",
    backgroundColor: "rgb(14,94,126)",
    background:
      "linear-gradient(180deg, rgba(14,94,126,1) 15%, rgba(35,177,174,1) 75%)",
    padding: "0px",
    minWidth: "3%",
    [theme.breakpoints.down("md")]: {
      display: "flex",
      flexDirection: "column",
      position: "fixed",
      top: (props) => (props.openMenuInMobile ? "80px" : "-100%"),
      [theme.breakpoints.down(1161)]: {
        top: (props) => (props.openMenuInMobile ? "79.51px" : "-100%"),

        // opacity: (props) => (props.openMenuInMobile ? "100%" : "0"),
      },
      overflow: "auto",
      minHeight: "calc(100vh - 79.51px)",
      // minHeight: "0%",
      backgroundColor: "rgb(14,94,126)",
      background:
        "linear-gradient(180deg, rgba(14,94,126,1) 15%, rgba(35,177,174,1) 75%)",
      padding: "0px",
      // width: (props) => (props.isOpen ? '280px' : '3%'),
      minWidth: "320px",
      // width: (props) => (props.openInDesktop ? '100%' : '3%'),
      width: "100%",
    },
    [theme.breakpoints.up(1920)]: {
      top: (props) => (props.openMenuInDesktop ? "79.51px" : "79.51px"),
      // transition: "$slow 0.5s ease-out",
    },

    //
  },
  shadowBar: {
    position: "fixed",
    top: "80px",
    height: "calc(100vh - 80px)",
    boxShadow: "0px 0px 15px 20px #fff, inset 10px -10px 10px 5px #fff",
    width: "280px",
  },
  link: {
    background: "transparent",
    // padding: '5px',
    textDecoration: "none",
    // width: (props) => (props.open ? 280 : '100%'),
    with: "100%",
    justifyContent: "space-between",
  },
  botonmenu: {
    display: "flex",
    minWidth: "100%",
    minHeight: "49px",
    backgroundColor: "#156A8D",
    // borderBottom: " 4px solid",
    borderRadius: "0px",
    colors: "red",
    justifyContent: "space-between",
    [theme.breakpoints.down(1161)]: {
      justifyContent: "center",
    },
    "& .text": {
      textDecoration: "none",
      textTransform: "none",
      margin: "0 0 0 0%",
      color: "#FFFFFF",
      font: " 600 16px Lato;",
      // display: (props) => (props.open ? 'flex' : 'none'),
    },
    "&:hover": {
      backgroundColor: "#3E42AD",
    },
    "&:focus": {
      background: "#FFF8F7 ",
      "& .text": {
        color: "#707070",
      },
    },
  },
  Cerrar: {
    "& .close": {
      backgroundRepeat: "no-repeat",
      backgroundImage: `url(${Salir})`,
      backgroundSize: "100% 100%",
      height: "30px",
      width: "30px",
      "&:hover": {
        "&:before": {
          textTransform: "none",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "25px",
          position: "absolute",
          content: '"Cerrar sesión"',
          left: "105%",
          height: "16px",
          width: "110px",
          background: "#fff",
          font: "bold 14px lato",
        },
      },
    },
    borderBottom: " 0px",
    background: "#0F5F7F",
    width: (props) => (props.open ? 280 : "3%"),
    "&:hover": {
      background: "#D9DF61",
      "& .text": {
        color: "#575757",
      },
    },
    "& .text": {
      font: "bold 17px lato",
    },
  },
  Abrir: {
    justifyContent: "center",
    background: "none",
    "&:focus": {
      background: "none ",
    },
    borderBottom: " 0px",
    padding: "0",
  },
  menuy: {
    display: (props) => (props.open ? "none" : "flex"),
  },
  menun: {
    display: (props) => (props.open ? "flex" : "none"),
  },
  hamburguesa: {
    padding: "0px",
    height: "30%",
    width: "100%",
    display: "none",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.up("lg")]: {
      display: "flex",
    },

    "& .buttonHandleMenu": {
      boxSizing: "border-box",
      cursor: "pointer",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      border: "none",
      outline: "none",
      backgroundColor: "transparent",
      // justifyContent: (props) => (props.open ? 'flex-end' : 'center'),
      // paddingRight: (props) => (props.open ? '22px' : '0'),
      height: "60px",
      width: "100%",
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
    "& .cerrar": {
      transform: "rotate(0.50turn)",
    },
  },

  espacio: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    alignItems: "center",
    flexGrow: "1",
  },
  containerCerrarSesion: {
    display: "flex",
    flexGrow: "1",
    justifyContent: "center",
    alignItems: "flex-end",
    "& div": {
      width: "100%",
    },
  },
  BarraPracticas: {
    display: (props) => (props.openPracticas ? "flex" : "none"),
    flexWrap: "wrap",
    [theme.breakpoints.up("sm")]: {
      flexDirection: "column",
      position: "absolute",
      backgroundColor: "#3E42AD",
      display: (props) => (props.openPracticas ? "flex" : "none"),
      width: (props) => (props.openMenuInDesktop ? 280 : "100%"),
      left: (props) => (props.openMenuInDesktop ? 280 : "100%"),
      top: "80px",
    },
  },
  BarraGrados: {
    display: (props) => (props.openGrados ? "flex" : "none"),
    flexWrap: "wrap",
    // [theme.breakpoints.up("sm")]: {
    //   flexDirection: "column",
    //   position: "absolute",
    //   backgroundColor: "#3E42AD",
    //   display: (props) => (props.openPracticas ? "flex" : "none"),
    //   width: (props) => (props.openMenuInDesktop ? 280 : "100%"),
    //   left: (props) => (props.openMenuInDesktop ? 280 : "100%"),
    //   top: "148px",
    // },
  },
  navButton: {
    textTransform: "none",
  },
  Practicas1: {
    background: "#D04C97",
  },
  Practicas2: {
    background: "#E352A5",
  },
  Practicas3: {
    background: "#E264AD",
  },
  dialog: {
    left: "110%",
    margin: "0 auto",
    backgroundColor: "#3f43b3",
    fontFamily: "Lato",
    letterSpacing: "0.36px",
    fontSize: "20px",
    color: "#ffffff",
    position: "absolute",
    width: "683px",
    padding: "24px",
    boxSizing: "border-box",
    borderRadius: "21.25px",

    "&:before": {
      // display: (props) => (props.openMenuInDesktop ? "none" : "flex"),
      display: "flex",
      zIndex: 1,
      textTransform: "none",
      justifyContent: "center",
      alignItems: "center",
      // borderRadius: "25px",
      position: "absolute",
      content: '""',
      border: "15px solid",
      borderTopColor: "transparent",
      borderLeftColor: "transparent",
      borderBottomColor: "transparent",
      borderRightColor: "#3f43b3",
      font: "bold 14px lato",
      top: "calc(50% - 15px)",
      left: "-28px",
    },
  },
}));

const MenuStepTutorial = ({
  handleClose,
  openMenuInDesktop,
  openMenuInMobile,
  handler,
}) => {
  const handleCloseSubMenu = () => {
    return;
  };
  const classes = useStyles({ openMenuInDesktop, openMenuInMobile });

  return (
    <>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={true}
      >
        <DialogContent>
          {/* Simulación del Menú real con tamaño real pero en transparente, únicamente superponiendo el botón del mapa, dando la sensación del blur en el fondo */}
          <nav className={classes.container}>
            <div className={classes.Barra} onClick={handler}>
              <>
                <p className={classes.dialog}>
                  El menú es la parte donde podrás navegar a los diferentes
                  apartados de la plataforma. Aquí encontrarás acceso a:
                  el mapa, el chat con tu docente, las prácticas guiadas,
                  biblioteca virtual con todas las lecturas del grado y grados
                  previos, el manual de usuario y los créditos.
                </p>
                <NavButton
                  className={classes.navButton}
                  title="Mapa"
                  to=""
                  handler={handleCloseSubMenu}
                  icon={"Mapa"}
                  openMenuInDesktop={openMenuInDesktop}
                  openMenuInMobile={openMenuInMobile}
                />

                <NavButton
                  className={classes.navButton}
                  title="Chat"
                  // notifications={notificacions}
                  to=""
                  handler={handleCloseSubMenu}
                  icon={"Chat"}
                  openMenuInDesktop={openMenuInDesktop}
                  openMenuInMobile={openMenuInMobile}
                />

                <NavButton
                  className={classes.navButton}
                  title="Prácticas guiadas"
                  to=""
                  // handler={handleOpenPracticas}
                  icon={"Practicas"}
                  openMenuInDesktop={openMenuInDesktop}
                  openMenuInMobile={openMenuInMobile}
                />
                <div className={classes.BarraPracticas}>
                  <Button
                    id="practica1"
                    className={clsx(
                      `${classes.Practicas} ${classes.botonmenu} ${classes.Practicas1} `
                    )}
                    // onClick={handleOpen1}
                  >
                    <p className="text">Relajación</p>
                    <div className={"practic"}></div>
                  </Button>
                  <Button
                    id="practica2"
                    className={clsx(
                      `${classes.Practicas} ${classes.botonmenu} ${classes.Practicas2} `
                    )}
                    // onClick={handleOpen2}
                  >
                    <p className="text">Atención sostenida</p>
                    <div className={"practic"}></div>
                  </Button>
                  <Button
                    id="practica3"
                    className={clsx(
                      `${classes.Practicas} ${classes.botonmenu} ${classes.Practicas3} `
                    )}
                    // onClick={handleOpen3}
                  >
                    <p className="text">Autogestión</p>
                    <div className={"practic"}></div>
                  </Button>
                </div>
                <div className={classes.BarraGrados}></div>

                <NavButton
                  classes={{ root: classes.navButton }}
                  title="Biblioteca virtual"
                  to=""
                  handler={handleCloseSubMenu}
                  icon={"Lectura"}
                  openMenuInDesktop={openMenuInDesktop}
                  openMenuInMobile={openMenuInMobile}
                />
                <a
                  href="https://pearson-cdl-reportes-qa.s3.amazonaws.com/CDL_ALUMNO_MANUAL.pdf"
                  target="_blank"
                  rel="noreferrer"
                  download
                >
                  <NavButton
                    title="Manual de usuario"
                    handler={handleCloseSubMenu}
                    icon={"Manual"}
                    openMenuInDesktop={openMenuInDesktop}
                    openMenuInMobile={openMenuInMobile}
                  />
                </a>
                <NavButton
                  title="Créditos"
                  to=""
                  // handler={handleOpenCredits}
                  icon={"Creditos"}
                  openMenuInDesktop={openMenuInDesktop}
                  openMenuInMobile={openMenuInMobile}
                />
                <NavButton
                  className={classes.navButton}
                  title="Guía"
                  // notifications={notificacions}
                  to=""
                  handler={handleCloseSubMenu}
                  icon={"Manual"}
                  openMenuInDesktop={openMenuInDesktop}
                  openMenuInMobile={openMenuInMobile}
                />
                <div
                  className={clsx(`${classes.menuy} ${classes.hamburguesa}`)}
                >
                  <button className="buttonHandleMenu" onClick={() => {}}>
                    <img
                      src={flecha}
                      alt=""
                      className={openMenuInDesktop ? "cerrar" : ""}
                    />
                  </button>
                </div>
                <div
                  className={clsx(`${classes.menun} ${classes.hamburguesa}`)}
                ></div>

                <div className={classes.containerCerrarSesion}>
                  <NavButton
                    title="Cerrar sesión"
                    to=""
                    // handler={handleLogout}
                    icon={"Cerrar"}
                    openMenuInDesktop={openMenuInDesktop}
                    openMenuInMobile={openMenuInMobile}
                    id="Logout-btn"
                  />
                </div>
              </>
              <div className={classes.shadowBar}></div>
            </div>
          </nav>
        </DialogContent>
      </BootstrapDialog>
    </>
  );
};

export default MenuStepTutorial;
