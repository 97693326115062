import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
    ContenedorLectura:{
        width:'502px',
                  [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
    },
    ContainerTitulo:{
        margin:'0 0 34px 0',
        '& .Titulo':{
            letterSpacing: '0px',
            color: '#5483D8',
            margin:'0',
            textAlign:'center',
            font:'43px fredoka one',
            lineHeight:'52px',
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            margin:'0 0 36px 0',
            color:'#000',
            font:'18px lato',
            lineHeight :'27px',
            letterSpacing: '0.17px',
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .ContainerCuadro':{
            position:'relative',
        },
        '& .Lectura2':{
            position:'absolute',
            margin:'53px 0 0 0',
            width:'85%',
            [theme.breakpoints.down(502)]:{
                margin:'20px 0 0 0',
            },
        },

        '& .Lectura3':{
            position:'absolute',
                        margin:'35px 0 0 0',
            width:'86%',
            [theme.breakpoints.down(502)]:{
                margin:'15px 0 0 0',
                width:'91%',
            },
        },

        '& .Lectura4':{
            margin:'0',
        },
        '& .SubTitulo':{
            color:'#CB4B4B',
            margin:'0',
            lineHeight :'28px',
            font:'bold 26px lato',
            letterSpacing: '0.24px',
            [theme.breakpoints.down(502)]:{
                font:'bold 18px lato',
                lineHeight :'24px',
                letterSpacing: '0.21px',
            },
        },
        '& .TextImg':{
            color:'#717476',
            margin:'24px 0 0 0',
            font:' 16px lato',
            lineHeight :'25px',
            letterSpacing: '0.15px',
            [theme.breakpoints.down(502)]:{
                font:' 11px lato',
                lineHeight :'14px',
                letterSpacing: '0.10px',
            },
        },
        '& .ul':{
            margin:'0',
            padding:'0 0 0 25px',
            listStyle: 'alphanumeric',
            color:'#5483D8',
            font:'bold 18px lato',
            [theme.breakpoints.down(502)]:{
                font:'bold 16px lato',
                padding:'0 0 0 15px',
            },
        },
        '& .li':{
            margin:'0 0 4px 0'
        },
        '& .ul2':{
            padding:'0 0 0 15px',
        },

        '& .Imagen':{
            width:'100%',
        }, 
        '& .Imagen2':{
            width:'100%',
            height:'105%'
        },  
      
    }
}));

const Una_nueva_constitución_demanda_de_la_ciudadanía_chilena = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                        <h2 className="Titulo">Una nueva constitución:<br/> demanda de la ciudadanía chilena</h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                       <p className='Lectura'>
                        Entre octubre de 2019 y marzo de 2020 hubo una serie de protestas masivas en 
                        Chile. La primera ocurrió en Santiago el 14 de octubre, a causa del aumento en el 
                        costo del transporte público. Sin embargo, durante los siguientes días se hizo notar 
                        que la inconformidad no se limitaba a ese tema, puesto que las protestas no se 
                        detuvieron cuando el gobierno regresó el transporte a sus tarifas anteriores.<br/><br/> 
                        
                        <h3 className='SubTitulo'>Razones para manifestarse</h3><br/> 
                        La población chilena tenía quejas específicas y fundamentadas. La riqueza se había 
                        acumulado en una parte muy pequeña de la población. Los salarios eran insuficientes 
                        para llevar una vida digna (sobre todo en la capital). La delincuencia había 
                        aumentado. El programa de pensiones era ineficaz y muchos desconfiaban de él. Los 
                        servicios de salud pública resultaban deficientes, y los privados, inaccesibles para la
                        mayoría. El agua prácticamente les pertenecía a las empresas, lo cual ocasionaba 
                        sequías con graves consecuencias ambientales y agrícolas. <br/><br/> 
                        
                        La ciudadanía chilena protestó ante todo esto, insatisfecha por la forma de proceder 
                        de sus gobernantes y molesta por la desigualdad en la que vivía.
                       </p>                   
                    </Grid>
                    <Grid container justifyContent='center'>
                        <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion37/1.png" alt="Tarjeta" />
                    </Grid>
                    <Grid container item justifyContent='center' >
                        <p className="TextImg">
                            Te invitamos a investigar más sobre estas protestas y sobre las demandas de la población.
                        </p>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container >
                        <p className='Lectura'>
                            El descontento se extendió por todo el país y las confrontaciones entre la ciudadanía 
                            y las autoridades crecieron. El 18 de octubre de 2019 el gobierno impuso un estado 
                            de emergencia y un toque de queda: el ejército salió a las calles para controlar las 
                            protestas. Esto no había ocurrido en Chile desde la dictadura militar de Augusto 
                            Pinochet.<br/><br/> 
                            
                            El gobierno levantó dichas medidas el 28 de octubre y buscó un diálogo con la 
                            ciudadanía para solucionar la inconformidad y los problemas que la generaban. <br/><br/> 
                            
                            <h3 className='SubTitulo'>Una nueva constitución para Chile</h3><br/>

                            Una de las principales demandas de los manifestantes era que se redactara una nueva constitución.
                        </p>
                    </Grid>
                    <Grid container justifyContent='center' className='ContainerCuadro'>
                            <img className="Imagen2" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion37/3R.png" alt="Tarjeta" />
                            <p className='Lectura Lectura2'>
                                La <em>constitución</em> es la ley máxima de un país. Establece las reglas de 
                                convivencia entre sus habitantes (qué está permitido y qué no) y qué 
                                derechos tienen los individuos frente al Estado. También señala cuál es la 
                                forma de gobierno y cómo funciona la organización política.
                            </p>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container >
                        <p className='Lectura'>
                            En Chile, la constitución vigente fue decretada en 1980, durante la dictadura militar. 
                            Cuando dicho régimen terminó, los gobiernos democráticos hicieron algunas 
                            modificaciones. Sin embargo, muchos chilenos veían en su constitución una herencia 
                            de la dictadura y la relacionaba con las medidas autoritarias y con el incumplimiento 
                            de los derechos sociales (como la salud y la educación).<br/><br/> 

                            Aunque no fueron las únicas manifestaciones que plantearon esta demanda, las 
                            protestas de 2019 fueron las primeras en ser escuchadas. El 10 de noviembre el
                            gobierno chileno anunció que iniciaría el proceso para crear la nueva constitución. <br/><br/> 
                             
                            El primer paso para este cambio fue un plebiscito nacional. El <em>plebiscito</em> es un 
                            procedimiento jurídico y electoral por medio del cual la ciudadanía vota una ley o 
                            asunto de importancia para el Estado. En este caso, había dos cuestiones que 
                            requerían votación:
                        </p>
                    </Grid>
                    <Grid container justifyContent='center' className='ContainerCuadro'>
                            <img className="Imagen2 " src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion37/2R.png" alt="Tarjeta" />
                            <p className='Lectura Lectura3'>
                                <ul className='ul'>
                                    <li className='li'>
                                        <p className='Lectura Lectura4'>¿Debía redactarse una constitución nueva?</p>
                                    </li>
                                    <li className='li'>
                                    <p className='Lectura Lectura4'>¿Quiénes debían redactarla? Para este punto había dos opciones:</p>
                                    </li>
                                    <ol type="a" className='ul2' >
                                        <li className='li'>
                                        <p className='Lectura Lectura4'>Una convención constitucional: todos y cada uno de los miembros serían elegidos por voto popular.</p>
                                        </li>
                                        <li className='li'>
                                        <p className='Lectura Lectura4'>Una convención mixta: una mitad de los miembros sería elegida por voto popular y la otra se compondría por integrantes del parlamento.</p>
                                        </li>
                                    </ol>
                                </ul>
                            </p>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container >
                        <p className='Lectura'>
                            El plebiscito se llevó a cabo el 25 de octubre de 2020. El 78% de los votantes estuvo a 
                            favor de modificar la constitución, y casi el 80% prefirió una asamblea conformada sólo 
                            por votación popular.
                        </p>
                    </Grid>
                </Grid>
        </Grid>
    </Grid>
    );
}
 
export default Una_nueva_constitución_demanda_de_la_ciudadanía_chilena;
