import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
    ContenedorLectura:{
        width:'502px',
                  [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
    },
    ContainerTitulo:{
        margin:'0 0 34px 0',
        '& .Titulo':{
            letterSpacing: '0px',
            color: '#FF0000',
            margin:'0',
            textAlign:'center',
            font:'43px fredoka one',
            lineHeight:'52px',
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            margin:'0 0 36px 0',
            color:'#000',
            font:'18px lato',
            lineHeight :'28px',
            letterSpacing: '0.17px',
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        "& .SubTitulo": {
            color: "#0069B6",
            margin: "0",
            lineHeight: "28px",
            font: "bold 26px lato",
            letterSpacing: "0.24px",
            [theme.breakpoints.down(502)]: {
              font: "bold 22px lato",
              lineHeight: "24px",
              letterSpacing: "0.21px",
            },
        },
        '& .TextImg':{
            color:'#717476',
            margin:'24px 0 0 0',
            font:' 16px lato',
            lineHeight :'25px',
            letterSpacing: '0.15px',
            [theme.breakpoints.down(502)]:{
                font:' 11px lato',
                lineHeight :'14px',
                letterSpacing: '0.10px',
            },
        },
    
        '& .Imagen':{
            width:'100%',
        }, 

    }
}));

const La_nueva_evolución_humana_la_era_cíborg = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >
                        La nueva evolución <br className='Tablet'/>humana: <br className='Escritorio'/>la era cíborg                  
                    </h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                      <p className="Lectura">
                        Mucha gente considera que la humanidad tiene la oportunidad de mejorar y renovar
                        sus capacidades a través de los avances tecnológicos. La psicología estudia el 
                        comportamiento de las personas, actualmente analiza una pérdida en las 
                        capacidades de aprendizaje que ha presentado una parte de la población.<br/><br/> 
                        
                        Esta dependencia se debe a que hemos desarrollado una importante dependencia a 
                        la tecnología. Por ejemplo, una gran parte de individuos no recuerda más de cuatro 
                        números telefónicos que tiene en la agenda de sus teléfonos móviles y no podría 
                        llegar a un destino determinado sin la ayuda de un <span style={{fontVariant: "all-small-caps"}}>GPS</span>. Eso se debe a que hemos 
                        dejado que la ciencia se encargue de tareas básicas y por lo tanto ya no tenemos 
                        necesidad de memorizar datos ni analizar información. <br/><br/>
                        
                        Por otro lado, los avances científicos podrían influir en nuestra apariencia futura. La 
                        antropología y la arqueología estudian la evolución desde los primates, homínidos, 
                        neandertales y <em>homo sapiens</em>, hasta los especímenes actuales. Los especialistas de
                        estas áreas consideran que estamos frente a una nueva etapa en la que predomina el
                        proceso digital. Piensan que el próximo gran salto evolutivo será la era cíborg, en la 
                        que existirán seres intervenidos por la tecnología para ser más eficientes.
                      </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <Grid container justifyContent="center" >
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img4to/Sesion53/1.png" alt="Tarjeta" />
                        </Grid> 
                        <Grid container justifyContent="center" >
                            <p className='TextImg'>
                                ¿Piensas que la próxima evolución creará seres mitad humanos y mitad máquina?
                            </p>                        
                        </Grid>  
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                            <h3 className='SubTitulo'>¿Seremos mejores en el futuro?</h3><br/>
                            
                            Neil Harbisson es el primer cíborg reconocido en el mundo. Él cree que la respuesta 
                            es un sí. Lleva una antena con un chip implantada en el cerebro para convertir la luz 
                            en ondas de sonidos. Esto le permite captar más colores que cualquier otro sujeto, 
                            ¡inclusive es capaz de ver luces ultravioletas e infrarrojas! Se sometió a este 
                            procedimiento para atender una condición de nacimiento que le impedía ver como 
                            los demás, pues sólo podía percibir el mundo en escalas de grises. Él afirma que no 
                            está usando tecnología, sino que él mismo es tecnología. Se identifica como un 
                            organismo cibernético. Declara que no hace falta cambiar el planeta para vivir mejor, 
                            sino cambiarnos a nosotros mismos. <br/><br/>
                            
                            <h3 className='SubTitulo'>¿Viviremos por siempre?</h3><br/>
                            
                            Uno de los ideales más perseguidos con esta nueva forma de vida es la inmortalidad 
                            electrónica. Los estudiosos consideran que en el futuro será posible escanear un 
                            cerebro átomo por átomo y que de esa forma se transferirán todos los pensamientos 
                            de una persona a una computadora. Una vez que eso suceda, dicha persona vivirá por 
                            siempre en la red sin que importe su cuerpo físico. <br/><br/>
                            
                            Imagina ser capaz de comunicarte sin hablar y escanear tus ideas a una pantalla. Así 
                            expresarías tus gustos, emociones, estados de ánimo y opiniones de una nueva 
                            manera, sin que haga falta hablar. Eso significaría que el lenguaje sería transformado 
                            y nacería una nueva forma de comunicación.<br/><br/>
                            
                            Estos cíborgs son los primeros, pero no serán los últimos. Algunos están pensando 
                            utilizar esos implantes en distintos ámbitos. Por ejemplo, en Suecia hay cerca de 4 000 
                            ciudadanos que se implantaron un chip en la mano para usarlo como llave, tarjeta de 
                            acceso o monedero en los supermercados.<br/><br/>
                            
                            Hay muchos beneficios de esta nueva era. Estamos presenciando el fin de una etapa 
                            y el inicio de otra. Pero es importante estar conscientes de los riesgos también y 
                            recordar que todo esto cambiará nuestra manera de relacionarnos también. ¿Tú qué 
                            opinas? ¿Conocías estas historias?
                        </p>
                    </Grid>
                </Grid>
        </Grid>
    </Grid>
    );
}
 
export default La_nueva_evolución_humana_la_era_cíborg ;
