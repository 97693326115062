import { Button, Grid, makeStyles } from "@material-ui/core";
import React, { useContext, useEffect } from "react";
import DrawerContext from "../../../../../usability/DrawerContext";
import { useOnLoadImages } from "../../../../hooks/useOnLoadImages";
import Loader from "../../../../Loader/Loader";
import Drawer from "../../ReadingTools/Drawer";

const useStyles =  makeStyles (theme =>({
      Contenedor:{
        background:'#E9FBF9',
        width:'100vw',
        overflowX:'clip',
        [theme.breakpoints.down(1161)]:{
            background:'none',
           overflowX:'unset',
        },
        [theme.breakpoints.down(600)]:{
            marginTop: '79.51px',
        }
    },
  ContainerInfoLectura: {
    position: "relative",
    height: "86px",
    [theme.breakpoints.down(502)]:{
        height: "43px",
    },
    background: "#76D4C3",
    "& .BarraInfoLectura": {
      height: "86px",
      background: `Url(https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Barras/Azul.png)`,
        [theme.breakpoints.down(502)]:{
            height: "43px",
        },
    },
    "& .InfoLectura": {
      position: "absolute",
      height: "86px",
      width: "219px",
      boxSizing: "border-box",
      padding: "17.2px 0",
      background: "#FAFAFA",
      borderRadius: "50px",
      boxShadow: "2px 2px 2px 2px #00000029",
      zIndex: "4",
        [theme.breakpoints.down(502)]:{
            height: "43px",
            width: "109px",
            padding: "8.6px 0",
            borderRadius: "25px",
        },
    },
    "& .InfoLectura:before": {
      position: "absolute",
      display: "flex",
      boxSizing: "border-box",
      padding: "0 0 0 10px",
      justifyContent: "center",
      alignItems: "center",
      height: "39.88px",
      width: "39.88px",
      borderRadius: "50%",
      content: '""',
      boxShadow: "3px 0px .5px 0px #00000029",
      background: "#FAFAFA",
      left: "90px",
      top: "65px",
      transform: "rotate(0.25turn)",
      [theme.breakpoints.down(502)]:{
        left: "45px",
        top: "30px",
        height: "20.88px",
        width: "20.88px",
        },
    },
    "& .InfoLectura:after": {
      content: '""',
      height: "15px",
      width: "15px",
      borderTop: "2px solid #20A698",
      borderLeft: "2px solid #20A698",
      position: "absolute",
      bottom: "-10px",
      left: "Calc(50% - 8px)",
      transform: "rotate(0.625turn)",
      [theme.breakpoints.down(502)]:{
        height: "10px",
        width: "10px",
        left: "Calc(50% - 4.5px)",
        bottom: "-3px",
        borderTop: "1px solid #20A698",
        borderLeft: "1px solid #20A698",
        },
    },
    "& .Sesion": {
      borderLeft: "2px solid #D8D8D8",
    },
    "& .Dato": {
      margin: "0 0 5px 0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#20A698",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        margin: "0 0 2.5px 0",
        },
    },
    "& .Caracter": {
      margin: "0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#000000",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        },
    },
       '& .Regresar':{
        color: '#F2624F',
        border: '#F2624F 1px solid',
        height: '30px',
        width:'100px',
        borderRadius: '20px',
        letterSpacing: '0.14px',
        textTransform: 'none',
        background:'#FEFAF1',
        top:'calc(50% - 15px)',
        left:'5%',
        zIndex:4,
        [theme.breakpoints.down(502)]: {
          font:'bold 10px lato',
          width: "60px",
        },
      },
  },
    ContenedorLectura:{
        background:'#fff',
        width:'1160px',
        height:'100%',
        padding:'36px 47.09px 35px' ,
        
        transform: (props) =>
        props.scale > 1 ? `scale(${props.scale}) translate(0px, 10%)` : `none`,
        [theme.breakpoints.down(1161)]:{
            width:'728px',
            padding: '57px 0 0 0',
        },
        [theme.breakpoints.down(728)]:{
            width:'350px',
            padding: '57px 0 0 0',
        },
        '& .ContainerDerechos':{
            bottom:'80px',
            [theme.breakpoints.down(1161)]:{
                display:'none',
            }
        },
        '& .Derechos':{
            textAlign:"center",
            margin:"0px",
            font:" 15px lato",
            color:"#717476",
        },
        '& .Escritorio':{
            display:'block',
            [theme.breakpoints.down(1161)]:{
                display:'none'
            },
        },
        '& .Tablet':{
            display:'none',
            [theme.breakpoints.down(1161)]:{
                display:'block'
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 67px 0',
        position:'relative',
        [theme.breakpoints.down(768)]:{
            display:'none'
        },
        '& .Lectura':{
            margin:'0 0 36px 0 ',
            font:'22px lato',
            color:'#000',
            letterSpacing: '0.20px',
            [theme.breakpoints.down(1161)]:{
                font:'18px lato',
                letterSpacing:'.17px',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(728)]:{
                font:'8px lato',
                letterSpacing:'.09px',
                lineHeight:'10px',
            },
        },
        '& .Pos':{
            position:'absolute',
            margin:'0',
        },
        '& .SubTitulo':{
            font:'48px Fredoka One',
            color:'#20A698',
            lineHeight:'58px',
            top:'65px',
            left:'383.91px',
            [theme.breakpoints.down(1161)]:{
                font:'33px Fredoka One',
                letterSpacing:'0px',
                lineHeight:'40px',
                top:'34.59px',
                left:'257.55px',
            },
            [theme.breakpoints.down(728)]:{
                font:'14px Fredoka One',
                letterSpacing:'0px',
                lineHeight:'18px',
                top:'20px',
                left:'125px',
            },
        },
        '& .SubTitulo2':{
            font:'43px Fredoka One',
            color:'#0B413C',
            lineHeight:'52px',
            top:'213px',
            left:'383.91px',
            [theme.breakpoints.down(1161)]:{
                font:'30px Fredoka One',
                letterSpacing:'0px',
                lineHeight:'36px',
                top:'138.78px',
                left:'257.55px',
            },
            [theme.breakpoints.down(768)]:{
                font:'13px Fredoka One',
                letterSpacing:'0px',
                lineHeight:'13px',
                top:'60px',
                left:'125px',
            },
        },
        '& .SubTitulo3':{
            font:' 30px lato',
            color:'#000000',
            letterSpacing:'.28px',
            lineHeight:'36px',
            top:'269px',
            left:'383.91px',
            [theme.breakpoints.down(1161)]:{
                font:' 21px lato',
                letterSpacing:'.2px',
                lineHeight:'21px',
                top:'178.22px',
                left:'257.55px', 
            },
            [theme.breakpoints.down(768)]:{
                font:'9px lato',
                letterSpacing:'.2px',
                lineHeight:'10px',
                top:'80px',
                left:'125px', 
            },
        },

        '& .SubTitulo4':{
            font:'bold 30px lato',
            color:'#FFFFFF',
            letterSpacing:'.28px',
            lineHeight:'36px',
            textAlign:'center',
            [theme.breakpoints.down(1161)]:{
                font:'bold 21px lato',
                letterSpacing:'.20px',
                lineHeight:'21px',
            },
            [theme.breakpoints.down(768)]:{
                font:'bold 9px lato',
                letterSpacing:'.2px',
                lineHeight:'10px',
            },
        },

        '& .Texto':{
            top:'349px',
            left:'120.91px',
            [theme.breakpoints.down(1161)]:{
                top:'231.69px',
                left:'52.62px',
            },
            [theme.breakpoints.down(768)]:{
                top:'102.8px',
                left:'24.6px',
            },
        },
        '& .Dato1':{
            top:'478px',
            left:'313.91px',
            [theme.breakpoints.down(1161)]:{
                top: '336.62px',
                left: '208.26px',
            },
            [theme.breakpoints.down(768)]:{
                top: '145px',
                left: '110px',
            },
        },
        '& .Dato2':{
            top:'1407px',
            left:'187.91px',
            [theme.breakpoints.down(1161)]:{
                top:'983.85px',
                left:'118.65px',
            },
            [theme.breakpoints.down(768)]:{
                top:'427px',
                left:'68px',
            },
        },
        '& .Dato3':{
            top:'2274px',
            left:'201.91px',
            [theme.breakpoints.down(1161)]:{
                top:'1590.41px',
                left:'129.39px',
            },
            [theme.breakpoints.down(768)]:{
                top: '689px',
                left: '72px',
            },
        },
        '& .Dato4':{
            top:'2553px',
            left:'264.91px',
            [theme.breakpoints.down(1161)]:{
                top:'1785.69px',
                left:'173.93px',
            },
            [theme.breakpoints.down(768)]:{
                top:'773px',
                left:'93px',
            },
        },
        '& .Text':{
            left:'92.91px',
            font:'bold 22px lato',
            lineHeight:'43px',
            [theme.breakpoints.down(1161)]:{
                left:'68px',
                font:'bold 18px lato',
                letterSpacing:'.17px',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(768)]:{
                left:'31px',
                font:'bold 9px lato',
                letterSpacing:'.09px!important',
                lineHeight:'14px!important',
            },
        },
        '& .Texto2':{
            top:'558px', 
            [theme.breakpoints.down(1161)]:{
                top:'392.96px', 
            },
            [theme.breakpoints.down(768)]:{
                top:'163px', 
            },
        },
        '& .Texto3':{
            top:'1478px', 
            [theme.breakpoints.down(1161)]:{
                top:'1027.28px', 
            },
            [theme.breakpoints.down(768)]:{
                top:'440px', 
            },
        },
        '& .Texto4':{
            top:'2345px', 
            [theme.breakpoints.down(1161)]:{
                top:'1628.28px', 
            },
            [theme.breakpoints.down(768)]:{
                top:'705px', 
            },
        },
        '& .Texto5':{
            top:'2625px',
            [theme.breakpoints.down(1161)]:{
                top:'1828.28px', 
            }, 
            [theme.breakpoints.down(768)]:{
                top:'795px', 
            },
        },
        '& .Texto6':{
            top:'2815px',
            [theme.breakpoints.down(1161)]:{
                top:'1938.28px', 
            }, 
            [theme.breakpoints.down(768)]:{
                top:'844px', 
            },
        },
        '& .ul':{
            margin:'19px 0 19px 0',
            [theme.breakpoints.down(1161)]:{
                margin:'3.5px 0 0px 0',
            },
        },
        
        '& .Imagen':{
            [theme.breakpoints.down(1161)]:{
                width:'100%',
                height:'2099px'
            },
            [theme.breakpoints.down(768)]:{
                width:'100%',
                height:'910px'
            },

        },
    },
    Imagen: {
        display:'none',
        [theme.breakpoints.down(768)]:{
            width:'100%',
            display:'flex'
        },
    },

}));

const Solicitud_de_inscripción = (props) => {

  const {activeStep, setActiveStep, handlePreviewStep, id_actividad} = props

  const handleContinue = () => {
    setActiveStep(activeStep - 1)
    handlePreviewStep()
  }

    const {
    handleSize,
    disabledZoom,
    closeZoom,
    handleZoom,
    scale
  }= useContext(DrawerContext);
  const [loading, setLoading] = React.useState(true);
  const wrapperRef = React.useRef(null);
  const imagesLoaded = useOnLoadImages(wrapperRef);

  const detectSize = () => {
    const lectura = document.getElementById("lectura");
    const newWidth = lectura?.offsetWidth;
    const newHeight = lectura?.offsetHeight;
    setLoading(false);
    if (lectura) handleSize(newHeight, newWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", detectSize);
    return () => {
      handleZoom()
      window.removeEventListener("resize", detectSize);
      closeZoom()
    };
  }, []);

  const classes = useStyles({ disabledZoom,scale });
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
             <Grid container item justifyContent="center" className={classes.ContainerInfoLectura}>
               <Grid container className="BarraInfoLectura">
                    <Button className="Regresar" onClick={handleContinue}>
                        Regresar
                    </Button>
                </Grid>
               <Grid container item className="InfoLectura">
                    <Grid container item direction="column" xs={6} justifyContent="center">
                        <p className="Dato">Grado</p>
                        <p className="Caracter">6</p>
                    </Grid>
                    <Grid container item direction="column" xs={6} justifyContent="center" className="Sesion">
                        <p className="Dato">Sesión</p>
                        <p className="Caracter">52</p>
                    </Grid>
               </Grid>
            </Grid>
{!imagesLoaded && <Loader />}
        <Grid
            container
            item
            className={classes.ContenedorLectura}
            justifyContent="center"
            id="lectura"
            onLoad={detectSize}
            ref={wrapperRef}
        >
<Drawer id_lectura={id_actividad} />
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container className='Escritorio' sm={12}>
                    <img className='Imagen' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion52/1.png"  alt="Tarjeta" />
                    <h2 className='Pos SubTitulo '>Escuela Secundaria <br/>Libertadores de América</h2>
                    <h3 className='Pos SubTitulo2 '>Solicitud de inscripción</h3>
                    <h3 className='Pos SubTitulo3 '>Nuevo ciclo escolar</h3>
                    <p className='Pos Lectura Texto'>
                        <b>Instrucciones de llenado</b>. Es obligatorio contestar lo señalado con asterisco. <br/>
                        Usar tinta azul y letra de molde legible.
                    </p>
                    <h3 className='Pos SubTitulo4 Dato1'>Datos personales del estudiante</h3>
                    <h3 className='Pos SubTitulo4 Dato2'>Datos personales de la madre, el padre o el tutor(a)</h3>
                    <h3 className='Pos SubTitulo4 Dato3'>Sólo para población en situación de discapacidad</h3>
                    <h3 className='Pos SubTitulo4 Dato4'>Observaciones o información adicional</h3>
                    <p className='Pos Lectura Text Texto2'>
                    *Nombre:_________________________________________________________________________________________<div className='Br'></div>
                    Segundo nombre:________________________________________________________________________________<div className='Br'></div>
                    *Primer apellido:________________________________________________________________________________<div className='Br'></div>
                    Segundo apellido:_______________________________________________________________________________<div className='Br'></div>
                    *Fecha de nacimiento (día/mes/año):_______________________ *Años cumplidos:________________<div className='Br'></div>
                    Género:__________________________________________________________________________________________<div className='Br'></div>
                    *Nacionalidad:___________________________________________________________________________________<div className='Br'></div>
                    *Número de identificación:_____________________________________________________________________<div className='Br'></div>
                    *Grupo sanguíneo:_______________________________________________________________________________<div className='Br'></div>
                    *Dirección de residencia:_______________________________________________________________________<div className='Br'></div>
                    Teléfono:_________________________________________________________________________________________<div className='Br'></div>
                    Correo electrónico:_____________________________________________________________________________<div className='Br'></div>
                    *Institución educativa de donde procede:_____________________________________________________<div className='Br'></div>
                    *Año de graduación:_____________________________________________________________________________<div className='Br'></div>
                    ¿Tiene hermanos actualmente en esta institución?: Sí/No<div className='Br'></div>
                    1._____________Grado:_________________Grupo:____________<div className='Br'></div>
                    2._____________Grado:_________________Grupo:____________<div className='Br'></div>
                    3._____________Grado:_________________Grupo:____________<div className='Br'></div>
                    </p>

                    <p className='Pos Lectura Text Texto3'>
                    *Nombre:_________________________________________________________________________________________<div className='Br'></div>
                    Segundo nombre:________________________________________________________________________________<div className='Br'></div>
                    *Primer apellido:________________________________________________________________________________<div className='Br'></div>
                    Segundo apellido:_______________________________________________________________________________<div className='Br'></div>
                    Género:__________________________________________________________________________________________<div className='Br'></div>
                    *Nacionalidad:___________________________________________________________________________________<div className='Br'></div>
                    *Estado civil:_____________________________________________________________________________________<div className='Br'></div>
                    *Fecha de nacimiento (día/mes/año):___________________________________________________________<div className='Br'></div>
                    *Número de identificación:_____________________________________________________________________<div className='Br'></div>
                    *Ocupación:______________________________________________________________________________________<div className='Br'></div>
                    Teléfono:_________________________________________________________________________________________
                    <ul className='ul'>
                        Residencial:______________________Móvil:_________________________Trabajo:__________________
                    </ul>
                    Correo electrónico:_____________________________________________________________________________<div className='Br'></div>
                    *En caso de emergencia, notificar a:<div className='Br'></div>
                    Nombre 1: __________________________________Télefono:_________________Parentesco:____________<div className='Br'></div>
                    Nombre 2: __________________________________Télefono:_________________Parentesco:____________<div className='Br'></div>
                    Nombre 3: __________________________________Télefono:_________________Parentesco:____________<div className='Br'></div>
                    </p>

                    <p className='Pos Lectura Text Texto4'>
                    ¿Tiene algún tipo de discapacidad?: Sí/No<div className='Br'></div>
                    Motriz___________________________________________ Sensorial______________________________________<div className='Br'></div>
                    Intelectual______________________________________ Psicosocial____________________________________<div className='Br'></div>
                    Indicar el nombre de la discapacidad:__________________________________________________________<div className='Br'></div>
                    </p>

                    <p className='Pos Lectura Text Texto5'>
                    Anexar:<div className='Br'></div>
                    Fotocopia de acta de nacimiento o de identificación.<br/>
                    Fotocopia de carnet de salud o de vacunación.
                    </p>

                    <p className='Pos Lectura Text Texto6'>
                    Fecha:____________________________________________________________________________________________<div className='Br'></div>
                    Firma del estudiante:____________________________________________________________________________<div className='Br'></div>
                    Firma de la madre, el padre o el tutor(a):_______________________________________________________<div className='Br'></div>
                    </p>

                    </Grid>
                    <Grid container className='Tablet' sm={12}>
                    <img className='Imagen' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion52/1.png"  alt="Tarjeta" />
                    <h2 className='Pos SubTitulo '>Escuela Secundaria <br/>Libertadores de América</h2>
                    <h3 className='Pos SubTitulo2 '>Solicitud de inscripción</h3>
                    <h3 className='Pos SubTitulo3 '>Nuevo ciclo escolar</h3>
                    <p className='Pos Lectura Texto'>
                        <b>Instrucciones de llenado</b>. Es obligatorio contestar lo señalado con asterisco. <br/>
                        Usar tinta azul y letra de molde legible.
                    </p>
                    <h3 className='Pos SubTitulo4 Dato1'>Datos personales del estudiante</h3>
                    <h3 className='Pos SubTitulo4 Dato2'>Datos personales de la madre, el padre o el tutor(a)</h3>
                    <h3 className='Pos SubTitulo4 Dato3'>Sólo para población en situación de discapacidad</h3>
                    <h3 className='Pos SubTitulo4 Dato4'>Observaciones o información adicional</h3>
                    <p className='Pos Lectura Text Texto2'>
                    *Nombre:_______________________________________________________________________<div className='Br'></div>
                    Segundo nombre:______________________________________________________________<div className='Br'></div>
                    *Primer apellido:_______________________________________________________________<div className='Br'></div>
                    Segundo apellido:______________________________________________________________<div className='Br'></div>
                    *Fecha de nacimiento (día/mes/año):__________ *Años cumplidos:____________<div className='Br'></div>
                    Género:________________________________________________________________________<div className='Br'></div>
                    *Nacionalidad:_________________________________________________________________<div className='Br'></div>
                    *Número de identificación:____________________________________________________<div className='Br'></div>
                    *Grupo sanguíneo:_____________________________________________________________<div className='Br'></div>
                    *Dirección de residencia:______________________________________________________<div className='Br'></div>
                    Teléfono:_______________________________________________________________________<div className='Br'></div>
                    Correo electrónico:____________________________________________________________<div className='Br'></div>
                    *Institución educativa de donde procede:____________________________________<div className='Br'></div>
                    *Año de graduación:___________________________________________________________<div className='Br'></div>
                    ¿Tiene hermanos actualmente en esta institución?: Sí/No<div className='Br'></div>
                    1._____________Grado:______________Grupo:____________<div className='Br'></div>
                    2._____________Grado:______________Grupo:____________<div className='Br'></div>
                    3._____________Grado:______________Grupo:____________<div className='Br'></div>
                    </p>

                    <p className='Pos Lectura Text Texto3'>
                    *Nombre:_______________________________________________________________________<div className='Br'></div>
                    Segundo nombre:______________________________________________________________<div className='Br'></div>
                    *Primer apellido:_______________________________________________________________<div className='Br'></div>
                    Segundo apellido:______________________________________________________________<div className='Br'></div>
                    Género:________________________________________________________________________<div className='Br'></div>
                    *Nacionalidad:_________________________________________________________________<div className='Br'></div>
                    *Estado civil:___________________________________________________________________<div className='Br'></div>
                    *Fecha de nacimiento (día/mes/año):__________________________________________<div className='Br'></div>
                    *Número de identificación:____________________________________________________<div className='Br'></div>
                    *Ocupación:____________________________________________________________________<div className='Br'></div>
                    Teléfono:_______________________________________________________________________
                    <ul className='ul'>
                        Residencial:________________Móvil:__________________Trabajo:_____________
                    </ul>
                    Correo electrónico:____________________________________________________________<div className='Br'></div>
                    *En caso de emergencia, notificar a:<div className='Br'></div>
                    Nombre 1: ______________________Télefono:_____________Parentesco:___________<div className='Br'></div>
                    Nombre 2: ______________________Télefono:_____________Parentesco:___________<div className='Br'></div>
                    Nombre 3: ______________________Télefono:_____________Parentesco:___________<div className='Br'></div>
                    </p>

                    <p className='Pos Lectura Text Texto4'>
                    ¿Tiene algún tipo de discapacidad?: Sí/No<div className='Br'></div>
                    Motriz______________________________________ Sensorial_________________________<div className='Br'></div>
                    Intelectual_________________________________ Psicosocial________________________<div className='Br'></div>
                    Indicar el nombre de la discapacidad:_________________________________________<div className='Br'></div>
                    </p>

                    <p className='Pos Lectura Text Texto5'>
                    Anexar:
                    Fotocopia de acta de nacimiento o de identificación.<br/>
                    Fotocopia de carnet de salud o de vacunación.
                    </p>

                    <p className='Pos Lectura Text Texto6'>
                    Fecha:__________________________________________________________________________<div className='Br'></div>
                    Firma del estudiante:__________________________________________________________<div className='Br'></div>
                    Firma de la madre, el padre o el tutor(a):______________________________________<div className='Br'></div>
                    </p>

                    </Grid>

                </Grid>
                <img className={classes.Imagen} src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion52/F.png"  alt="Tarjeta" />

        </Grid>
        <Grid container item style={{ marginTop: 80}} ></Grid>
    </Grid>
    );
}
 
export default Solicitud_de_inscripción;


