import { Button, Grid, makeStyles } from "@material-ui/core";
import React, { useContext, useEffect } from "react";
import DrawerContext from "../../../../../usability/DrawerContext";
import { useOnLoadImages } from "../../../../hooks/useOnLoadImages";
import Loader from "../../../../Loader/Loader";
import Drawer from "../../ReadingTools/Drawer";

const useStyles =  makeStyles (theme =>({
      Contenedor:{
        background:'#E9FBF9',
        width:'100vw',
        overflowX:'clip',
        [theme.breakpoints.down(1161)]:{
            background:'none',
           overflowX:'unset',
        },
        [theme.breakpoints.down(600)]:{
            marginTop: '79.51px',
        }
    },
    ContainerInfoLectura: {
    position: "relative",
    height: "86px",
    background: "#F6664D",
    [theme.breakpoints.down(502)]:{
        height: "43px",
    },
    "& .BarraInfoLectura": {
      height: "86px",
      background: `Url(https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Barras/Naranja.png)`,
      [theme.breakpoints.down(502)]:{
        height: "43px",
    },
    },
    "& .InfoLectura": {
      position: "absolute",
      height: "86px",
      width: "219px",
      boxSizing: "border-box",
      padding: "17.2px 0",
      background: "#FAFAFA",
      borderRadius: "50px",
      boxShadow: "2px 2px 2px 2px #00000029",
      zIndex: "4",
      [theme.breakpoints.down(502)]:{
        height: "43px",
        width: "109px",
        padding: "8.6px 0",
        borderRadius: "25px",
    },
    },
    "& .InfoLectura:before": {
      position: "absolute",
      display: "flex",
      boxSizing: "border-box",
      padding: "0 0 0 10px",
      justifyContent: "center",
      alignItems: "center",
      height: "39.88px",
      width: "39.88px",
      borderRadius: "50%",
      content: '""',
      boxShadow: "3px 0px .5px 0px #00000029",
      background: "#FAFAFA",
      left: "90px",
      top: "65px",
      transform: "rotate(0.25turn)",
      [theme.breakpoints.down(502)]:{
        left: "45px",
        top: "30px",
        height: "20.88px",
        width: "20.88px",
        },
    },
    "& .InfoLectura:after": {
      content: '""',
      height: "15px",
      width: "15px",
      borderTop: "2px solid #F6664D",
      borderLeft: "2px solid #F6664D",
      position: "absolute",
      bottom: "-10px",
      left: "Calc(50% - 8px)",
      transform: "rotate(0.625turn)",
      [theme.breakpoints.down(502)]:{
        height: "10px",
        width: "10px",
        left: "Calc(50% - 4.5px)",
        bottom: "-3px",
        borderTop: "1px solid #20A698",
        borderLeft: "1px solid #20A698",
        },
    },
    "& .Sesion": {
      borderLeft: "2px solid #D8D8D8",
    },
    "& .Dato": {
      margin: "0 0 5px 0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#F6664D",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        margin: "0 0 2.5px 0",
        },
    },
    "& .Caracter": {
      margin: "0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#000000",
    },
       '& .Regresar':{
        color: '#F2624F',
        border: '#F2624F 1px solid',
        height: '30px',
        width:'100px',
        borderRadius: '20px',
        letterSpacing: '0.14px',
        textTransform: 'none',
        background:'#FEFAF1',
        top:'calc(50% - 15px)',
        left:'5%',
        zIndex:4,
        [theme.breakpoints.down(502)]: {
          font:'bold 10px lato',
          width: "60px",
        },
      },
  },
    ContenedorLectura:{
        background:'#fff',
        width:'1160px',
        height:'100%',
        padding:'57px 168px 35px' ,
        
        transform: (props) =>
        props.scale > 1 ? `scale(${props.scale}) translate(0px, 10%)` : `none`,
        [theme.breakpoints.down(1161)]:{
            width:'502px',
            padding:'60px 0 0',
        },
        [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
        '& .ContainerDerechos':{
            bottom:'80px',
            [theme.breakpoints.down(1161)]:{
                display:'none',
            }
        },
        '& .Derechos':{
            textAlign:"center",
            margin:"0px",
            font:" 15px lato",
            color:"#717476",
        },
        '& .Escritorio':{
            display:'flex',
            [theme.breakpoints.down(1161)]:{
                display:'none'
            },
        },
        '& .Tablet':{
            display:'none',
            [theme.breakpoints.down(1161)]:{
                display:'flex'
            },
        },
    },
    ContainerTitulo:{
        margin:'0 0 45px 0',
        [theme.breakpoints.down(1161)]:{
            margin:'0 0 38px 0',
        },
        '& .Titulo':{
            font:'48px fredoka one',
            letterSpacing: '0px',
            color: '#2783FF',
            margin:'0',
            textAlign:'center',
            [theme.breakpoints.down(1161)]:{
                font:'43px fredoka one',
                lineHeight:'52px',
                textAlign:'center',
            },
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            lineHeight : '30px',
            margin:'0 0 36px 0 ',
            font:'22px lato',
            color:'#000',
            letterSpacing: '0.20px',
            [theme.breakpoints.down(1161)]:{
                font:'18px lato',
                letterSpacing:'.17px',
                lineHeight:'28px',
            },
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .TextImg':{
            margin:'24px 0 0 0',
            font:'20px lato',
            alignSelf:'flex-start',
            color:'#717476',
            letterSpacing:'.19px',
            [theme.breakpoints.down(1161)]:{
                font:'16px lato',
                letterSpacing:'.15px',
                lineHeight:'25px',
            },
            [theme.breakpoints.down(502)]:{
                font:' 11px lato',
                lineHeight :'14px',
                letterSpacing: '0.10px',
            },
        },
        '& .Imagen':{
            [theme.breakpoints.down(1161)]:{
                width:'100%',
            },
        },
        '& .Imagen2':{
            [theme.breakpoints.down(1161)]:{
                width:'100%',
            },
            [theme.breakpoints.down(502)]:{
                width:'100%',
                height:'105%',
            },
        },

        '& .ContainerCuadro':{
            position:'relative',
        },
        '& .Esp':{
            position:'absolute',
            margin:'53px 0 0 0 ',
            width:'750px',
            [theme.breakpoints.down(1161)]:{
                width:'85%',
            },
            [theme.breakpoints.down(502)]:{
                margin:'25px 0 0 0',
                lineHeight :'17px!important',
            },
        },

    },

}));

const El_canal_de_Panamá_una_renovación_para_el_futuro = (props) => {

  const {activeStep, setActiveStep, handlePreviewStep, id_actividad} = props
  
  const handleContinue = () => {
    setActiveStep(activeStep - 1)
    handlePreviewStep()
  }

    const {
    handleSize,
    disabledZoom,
    closeZoom,
    handleZoom,
    scale
  }= useContext(DrawerContext);
  const [loading, setLoading] = React.useState(true);
  const wrapperRef = React.useRef(null);
  const imagesLoaded = useOnLoadImages(wrapperRef);

  const detectSize = () => {
    const lectura = document.getElementById("lectura");
    const newWidth = lectura?.offsetWidth;
    const newHeight = lectura?.offsetHeight;
    setLoading(false);
    if (lectura) handleSize(newHeight, newWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", detectSize);
    return () => {
      handleZoom()
      window.removeEventListener("resize", detectSize);
      closeZoom()
    };
  }, []);

  const classes = useStyles({ disabledZoom,scale });
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
             <Grid container item justifyContent="center" className={classes.ContainerInfoLectura}>
               <Grid container className="BarraInfoLectura">
                    <Button className="Regresar" onClick={handleContinue}>
                        Regresar
                    </Button>
                </Grid>
               <Grid container item className="InfoLectura">
                    <Grid container item direction="column" xs={6} justifyContent="center">
                        <p className="Dato">Grado</p>
                        <p className="Caracter">6</p>
                    </Grid>
                    <Grid container item direction="column" xs={6} justifyContent="center" className="Sesion">
                        <p className="Dato">Sesión</p>
                        <p className="Caracter">39R</p>
                    </Grid>
               </Grid>
            </Grid>
{!imagesLoaded && <Loader />}
        <Grid
            container
            item
            className={classes.ContenedorLectura}
            justifyContent="center"
            id="lectura"
            onLoad={detectSize}
            ref={wrapperRef}
        >
<Drawer id_lectura={id_actividad} />
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo Escritorio" >
                        El canal de Panamá:<br/>
                        una renovación para el futuro
                    </h2>
                    <h2 className="Titulo Tablet" >
                        El canal de Panamá:<br/>
                        una renovación 
                    </h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                            El canal de Panamá es una ruta que conecta el océano Pacífico con el mar Caribe, a 
                            través del punto más estrecho del istmo de Panamá. 
                        </p>
                    </Grid>
                    <Grid container justifyContent='center' className='ContainerCuadro'>
                        <img className="Imagen2 Escritorio" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion39R/1.png" alt="Tarjeta" />
                        <img className="Imagen2 Tablet" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion39R/1R.png" alt="Tarjeta" />
                        <p className='Lectura Esp'>
                            <em>Istmo</em>. Es una franja delgada de tierra, la cual une dos áreas grandes de tierra <br/>
                            por medio del mar.                     
                        </p>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion39R/2.png" alt="Tarjeta" />
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                            Este canal fue construido por los Estados Unidos de América con el permiso del 
                            gobierno de Panamá, por lo que estuvo bajo el control estadounidense hasta 1979. 
                            Posteriormente fue controlado por la Comisión del Canal de Panamá, la cual era 
                            integrada por dicho país y también por los Estados Unidos. Finalmente, en 1999 el 
                            gobierno del país centroamericano asumió su control absoluto por medio de la 
                            conformación de la Autoridad del Canal de Panamá.<br/><br/>

                            Su inauguración fue en 1914 y modificó el tráfico marítimo para siempre, pues abrió 
                            una vía más rápida de comunicación entre el océano Pacífico y el océano Atlántico. 
                            Antes de que existiera el canal de Panamá las embarcaciones debían rodear América 
                            por el cabo de Hornos, ubicado en Chile, en el extremo sur del continente. Por lo 
                            tanto, esta nueva vía les ahorró ¡semanas enteras de navegación!<br/><br/>

                            Durante muchas décadas el canal de Panamá fue una innovación que facilitó el 
                            comercio marítimo. Pero con el paso del tiempo surgieron embarcaciones que 
                            superaban las medidas y el peso permitidos por el canal de Panamá, conocidas 
                            como <em>Post-Panamax</em>. Este cambio en las dimensiones de los navíos se convirtió en 
                            un problema para el canal, porque lo ponía en riesgo de volverlo obsoleto. Es decir, 
                            por no tener las características necesarias para dar servicio a esos nuevos barcos, el 
                            canal de Panamá corría el riesgo de dejar de ser utilizado. <br/><br/>

                            Con el objetivo de evitar lo anterior, en 2006 el entonces presidente de Panamá, 
                            Martín Torrijos Espino, propuso que se ampliara el canal para que las embarcaciones 
                            <em>Post-Panamax</em> pudieran utilizarlo.<br/><br/>

                            De acuerdo con las leyes panameñas, cualquier cambio importante en el canal de 
                            Panamá debía ser aprobado por la mayoría de la ciudadanía. Con el fin de cumplir 
                            ese requisito se llevó a cabo en octubre de 2006 una consulta popular, por medio 
                            de la cual las obras de ampliación fueron autorizadas.<br/><br/>

                            La ceremonia de inauguración de los trabajos de ampliación se llevó a cabo el 3 de 
                            septiembre de 2007. Al evento acudieron distintos mandatarios latinoamericanos, 
                            como los presidentes de Nicaragua, El Salvador, Honduras y Colombia. También, 
                            asistió Jimmy Carter, expresidente estadounidense que en 1977 firmó los Tratados 
                            del canal de Panamá, a través de los cuales se le devolvió al país el control de esa 
                            vía en 1999.
                        </p>
                    </Grid>
                    <Grid container  sm={12}>
                            <Grid container>
                                <img  className='Imagen' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion39R/3.png"  alt="Tarjeta" />
                            </Grid>
                            <Grid>
                                <p className='TextImg'>El puente de las Américas cruza encima del canal de Panamá y sirve para conectar dos 
                                ciudades de Panamá que quedaron separadas.</p>
                            </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                            Las obras de ampliación fueron llevadas a cabo por una agrupación de empresas 
                            denominada Grupo Unido por el Canal de Panamá. Al inicio de la obra se esperaba 
                            que los trabajos estuvieran concluidos en 2014. Pero en enero de ese año el grupo 
                            encargado de las obras anunció la suspensión de los trabajos, pues no iba a ser 
                            posible concluirlos con el costo que originalmente había pactado la Autoridad del 
                            Canal de Panamá.<br/><br/>

                            Como resultado de una serie de negociaciones, las obras de ampliación se 
                            reanudaron después de 21 días, en febrero de 2014. Finalmente, la inauguración se 
                            llevó a cabo en junio de 2016, es decir, nueve años después de haberse comenzado. 
                            El recorrido inaugural lo realizó la embarcación china <em>Cosco Shipping Panama</em>, la cual 
                            fue seleccionada mediante un sorteo. Esta embarcación zarpó de Grecia y realizó un 
                            viaje de 14 días para llegar a Panamá.<br/><br/>

                            A la ceremonia inaugural asistieron centenares de panameños, así como los 
                            mandatarios de 12 países, entre ellos el rey emérito de España Juan Carlos. Con 
                            estas obras se dio comienzo a una nueva etapa en la vida del canal, en la que se 
                            esperaba una mayor integración del país centroamericano a la economía global.

                        </p>
                    </Grid>
                </Grid>
        </Grid>
        <Grid container item style={{ marginTop: 80}} ></Grid>
    </Grid>
    );
}
 
export default El_canal_de_Panamá_una_renovación_para_el_futuro;
