import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
    ContenedorLectura:{
        width:'502px',
                  [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
    },
    ContainerTitulo:{
        margin:'0 0 34px 0',
        '& .Titulo':{
            color: '#FF2B2B',
            margin:'0 0 5px 0',
            textAlign:'center',
            font:'43px fredoka one',
            lineHeight:'52px',
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
        '& .Titulo2':{
            color: '#FF2B2B',
            margin:'0',
            textAlign:'center',
            font:'bold 26px lato',
            letterSpacing: '.24px',
            lineHeight:'28px',
            [theme.breakpoints.down(502)]:{
                font:'bold 22px lato',
                lineHeight:'24px',
            },
            [theme.breakpoints.down(350)]:{
                font:'bold 18px lato',
                lineHeight:'24px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        position:'relative',
        '& .Lectura':{
            margin:'0 0 36px 0',
            color:'#000',
            font:'18px lato',
            lineHeight :'28px',
            letterSpacing: '0.17px',
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .Imagen':{
            width:'100%',
        },  
      
    }
}));

const Los_viajes_de_Maqui_Cap3 = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>

            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                        <Grid container justifyContent="center" sm={12}>
                            <h2 className="Titulo">Los viajes de Maqui</h2>
                        </Grid>
                        <h3 className='Titulo2'>Fragmento 3 (capítulo 3)</h3>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                       <p className='Lectura'>
                        Dentro de la nave sólo hay suciedad. Latas y latas de lodo cubren el piso, hay envolturas de insectos disecados, restos de pelo y manchas verdes en la pared. Además, el aroma de los Uba-uba está impregnado en las paredes del transbordador. El olor es desagradable, pues en su planeta no conocen el agua ni el jabón.
                        <br/><br/>
                        Minutos después, Maqui y Laila encuentran los planos de las cuevas de <em>cilisium</em>. Si llegan a las cuevas antes que los Uba-uba, es probable que impidan la realización de su plan.
                        <br/><br/>
                        Maqui abandona rápidamente la nave enemiga con los planos entre las patas. Mientras tanto, Laila baja a los ductos de energía para sabotearlos y evitar que los Uba-uba nos persigan. Logra cortar el suministro, pero es localizada por un Uba-uba que daba mantenimiento a la nave. Ni tardo ni presuroso, lleva a Laila a las celdas de máxima seguridad y la vuelve su prisionera.
                        <br/><br/>
                        Maqui espera a la gatita varios minutos afuera del transbordador. Como Laila no sale, Maqui sospecha que quedó atrapada. “No hay tiempo que perder”, dice un poco inquieta. Con un dispositivo especial de telecomunicación, llama a los Vebox: 
                        <br/><br/>
                        —Manden al grupo de rescate de inmediato, ¡Laila no ha salido de la nave!
                        <br/><br/>
                        Mi perrita mira el mapa y divisa que las cuevas de <em>cilisium</em> no se encuentran muy lejos. Revisa adentro de su bolso de viajera y encuentra siete explosivos que podrá activar para que los Uba-uba pierdan el rastro de las ocho cuevas. Maqui emprende la misión y con presta velocidad va colocando un explosivo en la boca de cada caverna. Tiene escasos minutos para regresar a nuestra nave antes de que estallen.
                        <br/><br/>
                        —¿Dónde está Laila? —pregunta por el dispositivo de comunicación. —Aún no logramos rescatarla —le respondo.
                       </p>                   
                    </Grid>
                    <Grid container justifyContent='center' >
                        <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img4to/Sesion27/1.png" alt="Tarjeta" />
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                       <p className='Lectura'>
                        Los Uba-uba nos persiguen desde hace varios kilómetros. Cuando por fin pudimos entrar a su nave, descubrimos la ausencia de Laila. El Uba-uba que daba mantenimiento nos sorprendió y emitió una alerta. El equipo de rescate y yo regresamos disparados al desierto en busca de nuestra cueva.
                        <br/><br/>
                        Aún falta medio camino, cuando Maqui aparece de la nada. Le preguntamos cómo lo logró y nos responde un poco agitada:
                        <br/><br/>
                        —Gracias a este bolígrafo láser. ¡Pronto, tómense de las manos!
                        <br/><br/>
                        Y aparecimos súbitamente en nuestra cueva. Nos explica que el artefacto crea un campo cuántico mediante el cual puede transportarse a cualquier sitio de Marte o de cualquier planeta. Laila lo utilizó para escapar de la prisión y recoger a Maqui en una cueva. Se trasladaron a la cueva y, como la encontraron vacía, supieron que estábamos en peligro. Maqui decidió ir por mí y el equipo de rescate, Laila se quedó a desenterrar nuestra nave.
                        <br/><br/>
                        —Adelante, equipo. Aún tenemos que regresar a casa —dice Maqui, y toda la tripulación camina hasta la nave.
                        <br/><br/>
                        Desde el cielo, observamos que las cuevas de <em>cilisium</em> estallan. Los Vebox celebran la victoria, pero veo que Maqui se entristece. Seguramente desea que los Uba-uba salgan ilesos, pues mi perrita tiene sentimientos nobles. A pesar de su tristeza, sé que no habrá otro momento para preguntarle sobre los viajes en el tiempo.
                        <br/><br/>
                        —Maqui, lo siento. Necesito saber qué está pasando.
                        <br/><br/>
                        —Tienes razón, no he tenido oportunidad de aclararte este asunto. Las leyes de la Organización Mundial de Animales Domésticos para la Preservación del Tiempo me prohíben hablar sobre los acontecimientos que acabas de presenciar, sólo puedo contarte que fui elegida por esta organización cuando era una cachorrita.
                       </p>                   
                    </Grid>
                </Grid>
        </Grid>
    </Grid>
    );
}
 
export default Los_viajes_de_Maqui_Cap3;
