import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
    ContenedorLectura:{
        width:'502px',
                  [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
    },
    ContainerTitulo:{
        margin:'0 0 46px 0',
        '& .Titulo':{
            letterSpacing: '0px',
            color: '#4E91FD',
            margin:'0',
            font:'43px fredoka one',
            lineHeight:'52px',
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            margin:'0',
            color:'#000',
            font:'18px lato',
            lineHeight :'28px',
            letterSpacing: '0.17px',
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .Imagen':{
            width:'100%',
        },
    },
}));


const Urge_implementar_estrategias_para_reducir_nuestra_huella_ecológica_en_el_planeta = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >
                        El hombre orquesta                    
                    </h2>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container item justifyContent='center' xs={12}>
                        <p className="Lectura">
                        En el puerto lo llamaban “El hombre orquesta”. Era el capitán del Eguismo, el mejor barco mercante de la región. Todos lo respetaban por su experiencia y su habilidad para navegar. Se trataba de una figura conocida y admirada, pero su fiero carácter y los malos tratos que daba a su tripulación causaron que se quedara solo poco a poco, hasta que un día nadie quiso trabajar con él y lo abandonaron a su suerte.
                        <br/><br/>
                        Ese día esperó toda la mañana a sus ayudantes para que subieran la mercancía que llevarían a las costas de China, pero ninguno se presentó. Así que el hombre orquesta enfureció. Pateó el suelo, cerró los puños y gritó:
                        <br/><br/>
                        —¡Muy bien! Si nadie quiere laborar conmigo ¡lo haré todo yo! No necesito ayuda. Yo basto y sobro para hacer el trabajo de decenas de personas.
                        </p>
                    </Grid>
                    <Grid container justifyContent="center" xs={12} >
                        <img className='Imagen' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img4to/Sesion20/1.png"  alt="Tarjeta" />
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container item justifyContent='center' xs={12}>
                        <p className="Lectura">
                        Entró a su camarote, se quitó el abrigo de capitán y se puso en la cabeza una pañoleta para contener el sudor. Tardó todo el día en meter la mercancía. Los estibadores, los especialistas en guardar la carga en los barcos, dijeron que ellos pudieron haberlo hecho juntos en menos de tres horas mientras se burlaban de él.
                        <br/><br/>
                        —Es un insensato si piensa navegar sin compañía. No podrá encargarse de los aparejos y vigilar al mismo tiempo.
                        <br/><br/>
                        —Y peor si lo sorprende una tormenta. No podrá bajar las velas ni gobernar el timón.
                        <br/><br/>
                        Pese a lo que otros marineros opinaban, su orgullo pudo más que la prudencia y zarpó a la mañana siguiente. Verlo hacer todas las maniobras fue un espectáculo, pues corría de aquí para allá, subía al puente y gritaba órdenes que él mismo cumplía.
                        <br/><br/>
                        —Hay que reconocerlo —dijo un viejo marinero cuando vio al Eguismo salir del puerto con las velas desplegadas—, es el mejor de todos, pero ni el mejor puede solo contra el mar.
                        <br/><br/>
                        </p>
                    </Grid>
                    <Grid container justifyContent="center" xs={12} >
                        <img className='Imagen' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img4to/Sesion20/2.png"  alt="Tarjeta" />
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container item justifyContent='center' xs={12}>
                        <p className="Lectura">
                            Esas palabras se convirtieron en un presagio. Al segundo día de navegación el clima cambió en un instante. El cielo se cargó de nubes y la tempestad enloqueció al océano. El viento era tan fuerte que levantaba olas de diez metros y zarandeaba el barco como si éste fuera de papel. Necesitaba arriar las velas y mantener fijo el timón si no quería naufragar.
                            <br/><br/>
                            El capitán gritó la orden a sí mismo:
                            <br/><br/>
                            —¡Arriar las velas y todo a babor!
                            <br/><br/>
                            Pero no pudo hacer las dos cosas al mismo tiempo y la nave se hundió.
                            <br/><br/>
                            Por la noche, el vigilante de un navío que regresaba de China gritó despavorido:
                            <br/><br/>
                            —¡Hombre al agua!
                            <br/><br/>
                        </p>
                    </Grid>
                    <Grid container justifyContent="center" xs={12} >
                        <img className='Imagen' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img4to/Sesion20/3.png"  alt="Tarjeta" />
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container item justifyContent='center' xs={12}>
                        <p className="Lectura">
                            Trabajando en equipo, en poco tiempo los tripulantes rescataron al capitán, quien había permanecido horas sujeto a un barril para no hundirse.
                            <br/><br/>
                            Ya en la embarcación le dieron una taza de café caliente y una manta para el frío.
                            <br/><br/>
                            —Señor —le dijo el capitán que lo había rescatado—, he dispuesto que se le arregle una cama en la bodega. Espero que no sea muy incómodo para usted. Si necesita algo, avísenos y con gusto lo ayudaremos.
                            <br/><br/>
                            Conmovido porque entre todos le salvaron la vida, el hombre orquesta murmuró: “Gracias”. Desde ese día entendió la importancia de tener una tripulación a su lado.
                            <br/><br/>
                        </p>
                    </Grid>
                    <Grid container justifyContent="center" xs={12} >
                        <img className='Imagen' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img4to/Sesion20/4.png"  alt="Tarjeta" />
                    </Grid>
                </Grid>
        </Grid>
    </Grid>
    );
}
 
export default Urge_implementar_estrategias_para_reducir_nuestra_huella_ecológica_en_el_planeta;
