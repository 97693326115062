import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
     Contenedor:{
        background:'#E9FBF9',
        width:'100vw',
        overflowX:'clip',
        [theme.breakpoints.down(1161)]:{
            background:'none',
           overflowX:'unset',
        },
        [theme.breakpoints.down(600)]:{
            marginTop: '79.51px',
        }
    },
  ContainerInfoLectura: {
    position: "relative",
    height: "86px",
    [theme.breakpoints.down(502)]:{
        height: "43px",
    },
    background: "#76D4C3",
    "& .BarraInfoLectura": {
      height: "86px",
      background: `Url(https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Barras/Azul.png)`,
        [theme.breakpoints.down(502)]:{
            height: "43px",
        },
    },
    "& .InfoLectura": {
      position: "absolute",
      height: "86px",
      width: "219px",
      boxSizing: "border-box",
      padding: "17.2px 0",
      background: "#FAFAFA",
      borderRadius: "50px",
      boxShadow: "2px 2px 2px 2px #00000029",
      zIndex: "4",
        [theme.breakpoints.down(502)]:{
            height: "43px",
            width: "109px",
            padding: "8.6px 0",
            borderRadius: "25px",
        },
    },
    "& .InfoLectura:before": {
      position: "absolute",
      display: "flex",
      boxSizing: "border-box",
      padding: "0 0 0 10px",
      justifyContent: "center",
      alignItems: "center",
      height: "39.88px",
      width: "39.88px",
      borderRadius: "50%",
      content: '""',
      boxShadow: "3px 0px .5px 0px #00000029",
      background: "#FAFAFA",
      left: "90px",
      top: "65px",
      transform: "rotate(0.25turn)",
      [theme.breakpoints.down(502)]:{
        left: "45px",
        top: "30px",
        height: "20.88px",
        width: "20.88px",
        },
    },
    "& .InfoLectura:after": {
      content: '""',
      height: "15px",
      width: "15px",
      borderTop: "2px solid #20A698",
      borderLeft: "2px solid #20A698",
      position: "absolute",
      bottom: "-10px",
      left: "Calc(50% - 8px)",
      transform: "rotate(0.625turn)",
      [theme.breakpoints.down(502)]:{
        height: "10px",
        width: "10px",
        left: "Calc(50% - 4.5px)",
        bottom: "-3px",
        borderTop: "1px solid #20A698",
        borderLeft: "1px solid #20A698",
        },
    },
    "& .Sesion": {
      borderLeft: "2px solid #D8D8D8",
    },
    "& .Dato": {
      margin: "0 0 5px 0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#20A698",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        margin: "0 0 2.5px 0",
        },
    },
    "& .Caracter": {
      margin: "0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#000000",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        },
    },   },
    ContenedorLectura:{
        background:'#fff',
        width:'1160px',
        height:'100%',
        padding:'60px 168px 35px ',
        [theme.breakpoints.down(1161)]:{
            width:'502px',
            padding:'60px 0 0 0',
          },
        [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
        '& .ContainerDerechos':{
            bottom:'80px',
            [theme.breakpoints.down(1161)]:{
                display:'none',
            },
        },
        '& .Derechos':{
            textAlign:"center",
            margin:"0px",
            font:" 15px lato",
            color:"#717476",
        },
        '& .Lectura':{
            lineHeight : '30px',
            margin:'0 0 ',
            font:'22px lato',
            color:'#000',
            letterSpacing: '0.20px',
            width:'688px',
            top:'123.92px',
            left:'68px',
            [theme.breakpoints.down(1161)]:{
                font:'18px lato',
                lineHeight :'28px',
                letterSpacing: '0.17px',
                width:'442px',
                top:'90.52px',
                left:'30px',
            },
            [theme.breakpoints.down(502)]:{
                font:'14px lato',
                lineHeight :'19.5px',
                letterSpacing: '0.09px',
                width:'314px',
                top:'60px',
                left:'22px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'16px',
                letterSpacing: '0.09px',
                width:'87%',
                top:'55px',
                left:'20px',
            },
        },
        '& .Escritorio':{
            display:'block',
            [theme.breakpoints.down(1161)]:{
                display:'none'
            },
        },
        '& .Tablet':{
            display:'none',
            [theme.breakpoints.down(1161)]:{
                display:'block'
            },
        },
        '& .SubTitulo':{
            font:'bold 26px lato',
            color:'#F6664D',
            letterSpacing:'.24px',
            margin:'0',
            [theme.breakpoints.down(1161)]:{
                font:'bold 22px lato',
                lineHeight :'24px',
                letterSpacing: '0.21px',
            },
            [theme.breakpoints.down(502)]:{
                font:'bold 18px lato',
                lineHeight :'20px',
                letterSpacing: '0.16px',
            },
        }, 

        '& .Imagen':{
            [theme.breakpoints.down(502)]:{
                width:'100%',
            },
        },
        '& .Esp':{
            width:'100%',
            [theme.breakpoints.down(1161)]:{
                width:'100%!important',
            },
            [theme.breakpoints.down(502)]:{
                width:'100%!important',
            },
        },
        '& .Pos':{
            position:'absolute'
        },
    },
    ContainerTitulo:{
        margin:'0 0 46px 0',
        '& .Titulo':{
            font:'48px fredoka one',
            letterSpacing: '0px',
            color: '#926DD1',
            margin:'0',
            textAlign:'center',
            [theme.breakpoints.down(1161)]:{
                font:'43px fredoka one',
                lineHeight:'52px',
            },
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 46px 0',
    },
    Parrafo2:{
        margin:' 0 0 46px 0',
        position:'relative',
    },
    
    Parrafo3:{
        margin:' 0 0 46px 0',
        position:'relative',
        '& .Text':{
            position:'absolute',
            font:'18px lato',
            letterSpacing:'.17px',
            lineHeight:'22px',
            color:'#000',
            left:'445px',
            margin:'0',
            [theme.breakpoints.down(1161)]:{
                left:'267.90px',
                font:'11px lato',
                letterSpacing:'.10px',
                lineHeight:'14px',
                left:'265px',
            },
            [theme.breakpoints.down(502)]:{
                font:'8px lato',
                letterSpacing:'.07px',
                lineHeight:'10px',
                left:'187px',
            },
            [theme.breakpoints.down(350)]:{
                font:'6.5px lato',
                letterSpacing:'.07px',
                lineHeight:'8px',
                left:'159px',
            },
        },
        '& .Text1':{
            top:'350px',
            [theme.breakpoints.down(1161)]:{
                top:'295px',
            },
            [theme.breakpoints.down(502)]:{
                top:'207px',
            },
            [theme.breakpoints.down(350)]:{
                top:'182px',
            },
        },
        '& .Text2':{
            top:'550px',
            [theme.breakpoints.down(1161)]:{
                top:'425px',
            },
            [theme.breakpoints.down(502)]:{
                top:'300px',
            },
            [theme.breakpoints.down(350)]:{
                top:'255px',
            },
        },
        '& .Text3':{
            top:'750px',
            [theme.breakpoints.down(1161)]:{
                top:'560px',
            },
            [theme.breakpoints.down(502)]:{
                top:'390px',
            },
            [theme.breakpoints.down(350)]:{
                top:'337px',
            },
        },
        
    },
    

}));

const Normas_para_convivir = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
             <Grid container item justifyContent="center" className={classes.ContainerInfoLectura}>
               <Grid container className="BarraInfoLectura"></Grid>
               <Grid container item className="InfoLectura">
                    <Grid container item direction="column" xs={6} justifyContent="center">
                        <p className="Dato">Grado</p>
                        <p className="Caracter">2</p>
                    </Grid>
                    <Grid container item direction="column" xs={6} justifyContent="center" className="Sesion">
                        <p className="Dato">Sesión</p>
                        <p className="Caracter">18</p>
                    </Grid>
               </Grid>
            </Grid>

            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo}  justifyContent="center">
                    <h2 className="Titulo" >
                        Normas para convivir
                    </h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1}  justifyContent="center">
                    <Grid container sm={12} justifyContent="center">
                        <h3 className='SubTitulo'>
                            Ética normativa: reflexiones para la vida diaria                        
                        </h3>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo2} justifyContent="center">
                    <Grid container sm={12}>
                        <img className="Imagen Escritorio" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img2do/Sesion18/1.png" alt="Tarjeta" />
                        <img className="Imagen Tablet" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img2do/Sesion18/1R.png" alt="Tarjeta" />

                            <p className="Pos Lectura"> 
                                Imagina que te encuentras en la siguiente situación: dos semanas antes 
                                de comenzar las clases, el maestro advierte a todo el grupo que es 
                                obligatorio llevar un cuaderno cuadriculado y que quien no lo lleve 
                                desde el primer día perderá un punto en la calificación final. Pero ese 
                                día tu mejor amiga olvida su cuaderno.<br/><br/>
                                
                                Por supuesto, ella no quiere que sus calificaciones bajen ni piensa 
                                arriesgarse a reprobar. Por eso decide tomar el cuaderno de otra 
                                compañera de la clase, una niña tímida y callada del rincón, que 
                                seguramente no dirá nada cuando el profesor le pregunte por su 
                                cuaderno.<br/><br/> 
                                
                                Tu amiga se salvó, pero para lograrlo puso en riesgo a su compañera. 
                                ¿Qué piensas al respecto?
                            </p>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo3} justifyContent="center">
                    <Grid container sm={12}>
                        <img className="Imagen Escritorio" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img2do/Sesion18/2.png" alt="Tarjeta" />
                        <img className="Imagen Tablet" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img2do/Sesion18/2R.png" alt="Tarjeta" />

                        <p className="Pos Lectura"> 
                            Tu mejor amiga sabe que lo que hizo es incorrecto. Por eso te pide que 
                            guardes su secreto. Te invitamos a analizar al menos tres caminos que 
                            podrías tomar y las consecuencias que tendría cada uno.
                        </p>
                        <p className='Text Text1'>
                            <b>Guardas el secreto de tu amiga.</b><br/>
                            Ella se salvará, pero su compañera<br/> 
                            perderá un punto de calificación.
                            </p>
                        <p className='Text Text2'>
                            <b>Le dices la verdad al profesor.</b> No <br/>
                            le quitarán puntos a la otra niña,<br/> 
                            tu amiga recibirá una sanción y<br/> 
                            muy probablemente ustedes dos<br/> 
                            terminarán disgustadas.
                        </p>
                        <p className='Text Text3'>
                            <b>Convences a tu amiga de que <br/>
                            devuelva el cuaderno antes de<br/> 
                            generar un problema que afecte<br/> 
                            a otros.</b>Tu amiga tendrá que <br/>
                            hacerse responsable y enfrentar<br/>
                            su situación, pero ahí estarás tú <br/>
                            para apoyarla.
                        </p>
                        
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1}  justifyContent="center">
                    <Grid container sm={12} >
                    <p className='Lectura Esp'>
                        <h3 className='SubTitulo'>
                            Reflexionemos                       
                        </h3><br/>
                        
                         ¿Cómo crees que te sentirías ante cada situación y cómo se sentirían los demás? 
                         Ponte en su lugar y piensa cuál de las acciones planteadas arriba es la correcta, y por 
                         qué. La decisión correcta es la que procura el bienestar de todas las personas, no sólo 
                         de algunas. Ésa es una <em>norma de convivencia</em>. Y como esa hay muchas otras que nos 
                         ayudan a mantener relaciones y sociedades sanas, por ejemplo: no mentir, aceptar
                        responsabilidades, cuidar a la gente que nos rodea, entre otras. A eso le llamamos
                        <em> ética normativa</em>.<br/><br/>
                        
                        De eso se trata tu clase de ética: de reflexionar sobre nuestras acciones en la vida
                        diaria a favor de la felicidad y el cuidado de todas las personas.<br/><br/>
                        
                        Para finalizar, te pedimos que imagines otras circunstancias donde debas elegir entre
                        acciones positivas y negativas. Hacer lo correcto es una tarea para toda la vida.
                    </p>
                    </Grid>
                </Grid>

                <Grid container item xs={12} justifyContent="center" className="ContainerDerechos">
                    <p className="Derechos">
                        © 2022. Todos los derechos reservados, Pearson, Circuito de lectores.
                    </p>                
                </Grid>
        </Grid>
    </Grid>
    );
}
 
export default Normas_para_convivir;
