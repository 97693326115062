import { makeStyles } from "@material-ui/core";
import React from "react";

import { Link } from "react-router-dom";
import ContadorModal from "../Modals/ContadorModal";

import WelcomeVideoModal from "../Modals/Frames/VideoBienvenidaModal";

const useStyles = makeStyles((theme) => ({
  // },
  // '& .heraldo':{
  //     height:'787px',
  //     zIndex:2,
  // }

  // },
  link: {
    "& .BotonGrande": {
      cursor: "pointer",
      border: "none",
      backgroundColor: "transparent",
      position: "absolute",
      height: "55.7px",
      width: "55.7px",
      borderRadius: "50%",
      font: " 22px lato",
      "&:hover": {
        // borderWidth: "2px",
        // borderStyle: "solid",
        // borderColor: (props) => props.color,
      },
    },
    "& .Actividad1": {
      top: "106.5px",
      left: "320px",
      "&:hover": {
        "&:before": {
          display: "flex",
          position: "relative",
          justifyContent: "center",
          alignItems: "center",
          bottom: "50px",
          content: '"Bienvenida"',
          background: "#fff",
          borderRadius: "25px",
          font: "bold 16px lato",
          height: "25px",
          width: "100px",
        },
      },
    },
    "& .bloqueada": {
      borderWidth: "2px",
      borderStyle: "solid",
      borderColor: "#808080",
      backgroundColor: "#80808080",

      "&:hover": {
        cursor: "default",
      },
    },
    "& .completa": {
      borderWidth: "2px",
      borderStyle: "solid",
      borderColor: "#008000",
      "&:hover": {},
    },
    "& .contador": {
      borderWidth: "2px",
      borderStyle: "solid",
      borderColor: "#FFCE30",
      "&:hover": {},
    },
    "& .remedial": {
      borderWidth: "2px",
      borderStyle: "solid",
      borderColor: "#800080",
      "&:hover": {},
    },
   
  },
}));

const ButtonMapS1 = ({ data, actualizeList }) => {
  const { sesion } = data;
  const [intervention, setIntervention] = React.useState(false);
  const [contadorOpen, setContadorOpen] = React.useState(false);

  const handleIntervention = () => {
    setIntervention(!intervention);
  };

  const handleContadorOpen = () => {
    setContadorOpen(!contadorOpen);
  };


  const classes = useStyles({});
  return (
    <>
      {/* {data?.actividad_status.toLowerCase() === "pendiente" && ( */}
      {data?.actividad_status.toLowerCase() === "sesión desbloqueada" && (
        <>
          <Link className={classes.link} to="#">
            <button
              className={`BotonGrande Actividad${sesion}`}
              onClick={handleIntervention}
              aria-label={`Actividad ${sesion}`}
            ></button>
          </Link>
          <WelcomeVideoModal
            open={intervention}
            handleClose={handleIntervention}
            actualizeList={actualizeList}
            data={data}
          />
        </>
      )}
      {data?.actividad_status.toLowerCase() === "completa" && (
        <>
          <Link className={classes.link} to="#">
            <button
              className={`BotonGrande Actividad${sesion} completa`}
              onClick={handleIntervention}
              aria-label={`Actividad ${sesion}`}
            ></button>
          </Link>
          <WelcomeVideoModal
            open={intervention}
            handleClose={handleIntervention}
            actualizeList={actualizeList}
            data={data}
          />
        </>
      )}
      {data?.actividad_status.toLowerCase() === "contador" && (
        <>
          <Link className={classes.link} to="#">
            <button
              className={`BotonGrande Actividad${sesion} contador`}
              onClick={handleContadorOpen}
              aria-label={`Actividad ${sesion}`}
            ></button>
          </Link>
          <ContadorModal
            open={contadorOpen}
            handleClose={handleContadorOpen}
            timestamp={data.tiempo_espera}
            actualizeList={actualizeList}
          />
        </>
      )}
      {data?.actividad_status.toLowerCase() === "bloqueada" && (
        <>
          <Link className={classes.link} to="#">
            <button
              className={`BotonGrande Actividad${sesion} bloqueada`}
              aria-label={`Actividad ${sesion} Bloqueada`}
              // onClick={handleContadorOpen}
              disabled
            ></button>
          </Link>
        </>
      )}
    </>
  );
};

export default ButtonMapS1;
