import React, { useContext } from "react";
import { makeStyles, Toolbar, AppBar, Hidden } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";

import PearsonResponsive from "../../imgs/PearsonLogo_responsive.svg";
import PearsonLogoNavbar from "../../imgs/PearsonLogo_Navbar.svg";
import AuthContext from "../../../auth/AuthContext";
import { Link } from "react-router-dom";
import Grupo from "./GroupSelector";
import defaultImg from "./img/default_avatar.png";
import UsabilityContext from "../../../usability/UsabilityContext";
import GradeSelectorDrawer from "./GroupSelectorDrawer";

const useStyles = makeStyles((theme) => ({
  link: {
    textDecoration: "none",
  },
  offsete: {
    marginTop: "79.5px",
  },
  Nav: {
    padding: "0px",
    background: "#84E9DF",
    minHeight: "79.5px",
  },

  NavIzquierdo: {
    display: "flex",
    height: "79.5px",
    flexDirection: "row",
    alignItems: "center",
    flexGrow: "10",
    [theme.breakpoints.down("md")]: {
      justifyContent: "space-around",
      alignItems: "center",
    },

    [theme.breakpoints.down("321")]: {
      font: "18px lato",
    },
  },
  MenuIcon: {
    color: "#969D9F",
    fontSize: "40px",
  },

  logoPearson: {
    height: "70px",
    objectFit: "cover",
    paddingRight: "70.89px",
    paddingLeft: "63px",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  logoPearsonResponsive: {
    display: "none",
    height: "55px",
    objectFit: "cover",
    // width: "51.17px",

    [theme.breakpoints.down("md")]: {
      display: "flex",
      justifyContent: "center",
    },
  },

  NavCentro: {
    display: "flex",
    height: "79.5px",
    alignItems: "center",
    justifyContent: "center",
    flexGrow: "1",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  NavDerecho: {
    display: "flex",
    height: "79.5px",
    background: "#FDDC7A",
    flexGrow: "3",
    margin: "0px",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  NavDerechoTexto: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    flexGrow: "2",
    padding: "2% 0",
    justifyContent: "center",
    textAlign: "left",
  },
  groupContainer: {
    position: "relative",
    display: "flex",
    flexDirection: "row",
    textAlign: "left",
  },
  ContainerAvatar: {
    display: "flex",
    flexGrow: "1",
    alignItems: "center",
  },
  texto: {
    padding: "0 0 0 30%",
    margin: "0",
    // height: "19px",
    letterSpacing: "0.15px",
    color: "#000",
    font: " 900 16px Lato;",
  },

  Avatar: {
    height: "49px",
    width: "49px",
    border: "3px solid #FFFFFF",
    borderRadius: "500px",
    objectFit: "cover",

    [theme.breakpoints.down(1367)]: {
      height: "40px",
      width: "40px",
    },
    // [theme.breakpoints.down(850)]:{
    //   height:'49px',
    //   width:'49px',
    // },
  },
  grupo: {
    position: "absolute",
    left: "250px",
    bottom: "5%",
    width: "30%",
  },
}));

const NavBar = () => {
  const classe = useStyles();
  const {
    handleMenuInMobile,
    openMenuInMobile,
    openMenuInDesktop,
    handleMenuInDesktop,
  } = useContext(UsabilityContext);
  const {
    user: { name, firstLastName, secondLastName, avatar, grupos },
  } = useContext(AuthContext);

  return (
    <>
      <AppBar className={classe.Nav}>
        <Toolbar className={classe.Nav}>
          <div className={classe.NavIzquierdo}>
            <Hidden only={["xl", "lg"]}>
              <MenuIcon
                className={classe.MenuIcon}
                onClick={handleMenuInMobile}
              />
            </Hidden>
            <img
              className={classe.logoPearson}
              src={PearsonLogoNavbar}
              alt="pearson"
            />
            <img
              className={classe.logoPearsonResponsive}
              src={PearsonResponsive}
              alt=""
            />
            <Hidden only={["xl", "lg"]}>
              <GradeSelectorDrawer />
              {/* <Link
                className={classe.link}
                to="/docente/perfil"
                onClick={() => {
                  if (openMenuInMobile) handleMenuInMobile();
                  if (openMenuInDesktop) handleMenuInDesktop();
                }}
              >
                <img
                  className={classe.Avatar}
                  src={avatar?.path || defaultImg}
                  alt="userAvatar"
                />
              </Link> */}
            </Hidden>
          </div>
          <div className={classe.NavCentro}></div>
          <div className={classe.NavDerecho}>
            <div className={classe.NavDerechoTexto}>
              <div>
                <p
                  className={classe.texto}
                >{`${name} ${firstLastName} ${secondLastName}`}</p>
              </div>
              <div className={classe.groupContainer}>
                {grupos?.length > 0 ? (
                  <>
                    <p className={classe.texto}>Grupo:</p>
                    <Grupo />
                  </>
                ) : (
                  <>
                    <p className={classe.texto}>
                      Aun no tienes grupos asignados.
                    </p>
                  </>
                )}
              </div>
            </div>
            <div className={classe.ContainerAvatar}>
              <Link
                className={classe.link}
                to="/docente/perfil"
                onClick={() => {
                  if (openMenuInMobile) handleMenuInMobile();
                  if (openMenuInDesktop) handleMenuInDesktop();
                }}
              >
                <img
                  className={classe.Avatar}
                  src={avatar?.path || defaultImg}
                  alt="userAvatar"
                />
              </Link>
            </div>
          </div>
        </Toolbar>
      </AppBar>
      <div className={classe.offsete}></div>
    </>
  );
};

export default NavBar;
