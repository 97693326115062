import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
    ContenedorLectura:{
        width:'502px',
                  [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
    },
    ContainerTitulo:{
        margin:'0 0 61px 0',
        '& .Titulo':{
            letterSpacing: '0px',
            color: '#ED0024',
            margin:'0',
            textAlign:'center',
            font:'43px fredoka one',
            lineHeight:'52px',
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            margin:'0 0 36px 0 ',
            color:'#000',
            font:'18px lato',
            lineHeight :'28px',
            letterSpacing: '0.17px',
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .Esp':{
            margin:' 0 ',
        },
        '& .Cuadro':{
            position:'relative',
        },

        '& .SubTitulo':{
            color:'#005BB9',
            margin:'0',
            lineHeight :'28px',
            font:'bold 26px lato',
            letterSpacing: '0.24px',
            [theme.breakpoints.down(502)]:{
                font:'bold 18px lato',
                lineHeight :'24px',
                letterSpacing: '0.21px',
            },
        },
        "& .SubTitulo2": {
            color: "#E1AF10",
            margin: "0",
            font: "bold 22px lato",
            lineHeight: "24px",
            letterSpacing: "0.21px",
            [theme.breakpoints.down(502)]: {
              font: "bold 18px lato",
              lineHeight: "20px",
              letterSpacing: "0.16px",
            },
        },
        '& .Esp':{
            margin:'0'
        },

        '& .TextImg':{
            color:'#717476',
            margin:'21px 0 0 0',
            font:' 16px lato',
            lineHeight :'25px',
            letterSpacing: '0.15px',
            [theme.breakpoints.down(502)]:{
                font:' 11px lato',
                lineHeight :'14px',
                letterSpacing: '0.10px',
            },
        },
        
        '& .Esp2':{
            margin:' 68px 0 0 0',
            position:'absolute',
            width:'83.46%',
            [theme.breakpoints.down(502)]:{
                margin:' 55px 0 0 0',
                font:' 14px lato!important',
                lineHeight :'15px!important',
                letterSpacing: '0.10px',
            },
            [theme.breakpoints.down(350)]:{
                font:' 12px lato!important',
                lineHeight :'13px!important',
                letterSpacing: '0.10px',
            },
        },
        '& .Sov':{
            margin:'0 0 36px 0',
        },
        '& .Imagen':{
            width:'100%',
        },
        '& .Imagen2':{
            width:'100%',
        },
        '& .Imagen3':{
            width:'100%',
            [theme.breakpoints.down(502)]:{
                height:'280px',
            },
            [theme.breakpoints.down(350)]:{
                height:'263px',
            },
        },
    },

}));

const La_carrera_espacial = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>

            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >
                        La carrera espacial
                    </h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                      <p className="Lectura">
                        Los Estados Unidos de América y la Unión Soviética se convirtieron en los dos países 
                        más poderosos del mundo al finalizar la Segunda Guerra Mundial. Ambos 
                        habían estado en el mismo “bando”, por eso se pensó que seguirían colaborando de manera 
                        pacífica. Pero las diferencias entre estos países no tuvieron arreglo.
                      </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <Grid container item justifyContent='center' md={12} className="Sov">
                            <img className="Imagen2" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img3ro/Sesion11/1.png" alt="Tarjeta" />
                            <Grid container item justifyContent='center'>
                                <p className='TextImg'>La Unión Soviética lanza el satélite Sputnik I.</p>
                            </Grid>
                        </Grid>
                        <Grid container item justifyContent='center' md={12} >
                            <img className="Imagen2" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img3ro/Sesion11/1-2.png" alt="Tarjeta" />
                            <Grid container item justifyContent='center' >
                                <p className='TextImg'>Despegue del Apolo 11 en Estados Unidos.</p>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                      <p className="Lectura">
                       <h3 className='SubTitulo'>La Guerra Fría</h3><br/>
                       
                        Como consecuencia de ello, el mundo se dividió en dos bloques: uno era el capitalista 
                        y otro el comunista. En el primero dominaban los Estados Unidos y en el segundo la 
                        Unión Soviética.<br/><br/>
                        
                        Estas dos naciones nunca se enfrentaron de forma directa en una guerra. Si bien 
                        tenían a su alcance armas nucleares, también sabían que usarlas tendría 
                        consecuencias graves para la humanidad. Sin embargo, sí se enfrentaron en otros 
                        ámbitos, como en el desarrollo de ciencia y tecnología. Este periodo es conocido 
                        como la Guerra Fría. <br/><br/>
                        
                        El conflicto afectó a otras naciones, en las que la lucha entre comunismo y 
                        capitalismo provocó guerras internas, como sucedió en varios países asiáticos, 
                        africanos y latinoamericanos.<br/><br/>
                        
                        <h3 className='SubTitulo2'>El camino hacia la Luna</h3><br/>
                        
                        Entre otros campos, estas potencias se enfrentaron en la ciencia e iniciaron así una 
                        carrera por llegar al espacio. En 1957 la Unión Soviética lanzó al espacio los primeros 
                        satélites artificiales: el <em>Sputnik I</em> y el <em>Sputnik II</em>. En este último viajó la perrita Laika, 
                        que fue el primer ser vivo lanzado al espacio. <br/><br/>
                        
                        Los soviéticos obtuvieron imágenes del lado oscuro de la Luna. La humanidad nunca 
                        antes lo había visto porque no se puede observar desde la Tierra. Poco tiempo 
                        después también enviaron al primer hombre al espacio: el astronauta Yuri Gagarin.
                      </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <Grid container item justifyContent='center'>
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img3ro/Sesion11/2.png" alt="Tarjeta" />
                        </Grid>
                        <Grid container item justifyContent='center'>
                            <p className='TextImg'>Ilustración de Yuri Gagarin.</p>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container item sm={12}>
                        <p className='Lectura Esp'>
                            La ventaja era evidente. Por eso los estadounidenses crearon la Administración 
                            Nacional de Aeronáutica y el Espacio (<span style={{fontVariant: "all-small-caps"}}>NASA</span>). Y también programas espaciales como 
                            el Apolo, con el objetivo de mandar a un ser humano a la Luna. El 16 de julio de 1969 
                            se lanzó la misión Apolo 11. La nave espacial estuvo tripulada por Neil Armstrong, 
                            Edwin Aldrin y Michael Collins.<br/><br/>
                            
                            Quien pisó antes la superficie lunar fue su capitán, Neil Armstrong, y con ese
                            “pequeño paso para un hombre, pero un gran salto para la humanidad”, los 
                            estadounidenses lograron llegar al satélite natural de la Tierra.
                        </p>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container item justifyContent='center' className='Cuadro' sm={12}>
                        <img className="Imagen3 " src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img3ro/Sesion11/3R.png" alt="Tarjeta" />
                        <p className='TextImg Esp2'>
                            Si quieres conocer más sobre la historia de los viajes al espacio, consulta estos 
                            materiales:<br/>
                            Martos, A. (2009). <em>Breve Historia de la carrera espacial</em>. Nowtilus. Disponible en 
                            <a href="https://elibros.com/descargas/BHC_ESPACIAL.pdf" target="_blank"> https://elibros.com/descargas/BHC_<br/>ESPACIAL.pdf</a> (Consulta 5/09/21)<br/> 
                            Millán, J. M. L. (2013). “La Guerra Fría y la carrera espacial. Un breve análisis 
                            histórico”. Pasaje a la ciencia. N° 15. I.E.S Antonio de Mendoza. Disponible en <a href="http://www.juntadeandalucia.es/averroes/centrostic/23700542/helvia/aula/archivos/repositorio/0/162/PASAJE_15.pdf" target="_blank">http://
                            www.juntadeandalucia.es/averroes/centrostic/23700542/<br/>helvia/aula/archivos/
                            repositorio/0/162/PASAJE_15.pdf</a> (Consulta 5/09/21)
                        </p>
        

                    </Grid>
                </Grid>
        </Grid>
    </Grid>
    );
}
 
export default La_carrera_espacial;
