import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
     Contenedor:{
        background:'#E9FBF9',
        width:'100vw',
        overflowX:'clip',
        [theme.breakpoints.down(1161)]:{
            background:'none',
           overflowX:'unset',
        },
        [theme.breakpoints.down(600)]:{
            marginTop: '79.51px',
        }
    },
  ContainerInfoLectura: {
    position: "relative",
    height: "86px",
    [theme.breakpoints.down(502)]:{
        height: "43px",
    },
    background: "#76D4C3",
    "& .BarraInfoLectura": {
      height: "86px",
      background: `Url(https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Barras/Azul.png)`,
        [theme.breakpoints.down(502)]:{
            height: "43px",
        },
    },
    "& .InfoLectura": {
      position: "absolute",
      height: "86px",
      width: "219px",
      boxSizing: "border-box",
      padding: "17.2px 0",
      background: "#FAFAFA",
      borderRadius: "50px",
      boxShadow: "2px 2px 2px 2px #00000029",
      zIndex: "4",
        [theme.breakpoints.down(502)]:{
            height: "43px",
            width: "109px",
            padding: "8.6px 0",
            borderRadius: "25px",
        },
    },
    "& .InfoLectura:before": {
      position: "absolute",
      display: "flex",
      boxSizing: "border-box",
      padding: "0 0 0 10px",
      justifyContent: "center",
      alignItems: "center",
      height: "39.88px",
      width: "39.88px",
      borderRadius: "50%",
      content: '""',
      boxShadow: "3px 0px .5px 0px #00000029",
      background: "#FAFAFA",
      left: "90px",
      top: "65px",
      transform: "rotate(0.25turn)",
      [theme.breakpoints.down(502)]:{
        left: "45px",
        top: "30px",
        height: "20.88px",
        width: "20.88px",
        },
    },
    "& .InfoLectura:after": {
      content: '""',
      height: "15px",
      width: "15px",
      borderTop: "2px solid #20A698",
      borderLeft: "2px solid #20A698",
      position: "absolute",
      bottom: "-10px",
      left: "Calc(50% - 8px)",
      transform: "rotate(0.625turn)",
      [theme.breakpoints.down(502)]:{
        height: "10px",
        width: "10px",
        left: "Calc(50% - 4.5px)",
        bottom: "-3px",
        borderTop: "1px solid #20A698",
        borderLeft: "1px solid #20A698",
        },
    },
    "& .Sesion": {
      borderLeft: "2px solid #D8D8D8",
    },
    "& .Dato": {
      margin: "0 0 5px 0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#20A698",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        margin: "0 0 2.5px 0",
        },
    },
    "& .Caracter": {
      margin: "0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#000000",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        },
    },   },
    ContenedorLectura:{
        background:'#fff',
        width:'1160px',
        height:'100%',
        padding:'60px 165px 35px',
        [theme.breakpoints.down(1161)]:{
            width:'502px',
            padding:'60px 0 0 0',
          },
        [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
        '& .ContainerDerechos':{
            bottom:'80px',
            [theme.breakpoints.down(1161)]:{
                display:'none',
            },
        },
        '& .Derechos':{
            textAlign:"center",
            margin:"0px",
            font:" 15px lato",
            color:"#717476",
        },
        '& .Escritorio':{
            display:'flex',
            [theme.breakpoints.down(1161)]:{
                display:'none'
            },
        },
        '& .Tablet':{
            display:'none',
            [theme.breakpoints.down(1161)]:{
                display:'flex'
            },
        }
    },
    ContainerTitulo:{
        margin:'0 0 34px 0',
        [theme.breakpoints.down(1161)]:{
            margin:'0 0 34px 0',
        },
        '& .Titulo':{
            font:'48px fredoka one',
            letterSpacing: '0px',
            lineHeight:'58px',
            color: '#46B9D5',
            margin:'0',
            textAlign:'center',
            [theme.breakpoints.down(1161)]:{
                font:'43px fredoka one',
                lineHeight:'52px',
            },
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            lineHeight : '30px',
            margin:'0 0 36px 0',
            font:'22px lato',
            color:'#000',
            letterSpacing: '0.20px',
            [theme.breakpoints.down(1161)]:{
                font:'18px lato',
                lineHeight :'28px',
                letterSpacing: '0.17px',
            },
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },

        '& .Imagen':{
            width:'833px',
            [theme.breakpoints.down(1161)]:{
                width:'100%',
            },
        },
       
      
    }
}));

const El_país_de_los_cambios = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
             <Grid container item justifyContent="center" className={classes.ContainerInfoLectura}>
               <Grid container className="BarraInfoLectura"></Grid>
               <Grid container item className="InfoLectura">
                    <Grid container item direction="column" xs={6} justifyContent="center">
                        <p className="Dato">Grado</p>
                        <p className="Caracter">5</p>
                    </Grid>
                    <Grid container item direction="column" xs={6} justifyContent="center" className="Sesion">
                        <p className="Dato">Sesión</p>
                        <p className="Caracter">6</p>
                    </Grid>
               </Grid>
            </Grid>

            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                        <h2 className="Titulo">El país de los cambios</h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                       <p className='Lectura'>
                        Hace mucho tiempo existió un país que siempre cambiaba. Cambiaba en todo sentido 
                        y en todo momento. Los paisajes eran diferentes todos los días y las casas, además
                         de cambiar constantemente de lugar, también se transformaban por dentro. Así, 
                         una casa victoriana después de un tiempo parecía iglesia barroca y luego aparecía 
                         con un extraño estilo moderno. Por eso las cartas nunca llegaban a su destino y 
                         muchas veces las personas que escribían dichas cartas, con el tiempo, al transformarse, 
                         terminaban destruyéndolas.<br/><br/>
                         
                        Ese país contaba con filósofos y pensadores de un solo día. Había guerras y revoluciones 
                        fugaces, héroes nacionales que quedaban olvidados en la memoria de los pobladores. Y 
                        el gobernador del momento pudo haber sido un sacerdote o un profesor de primaria el 
                        día anterior.
                       </p>
                      
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <Grid container justifyContent="center" >
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion6/4.png" alt="Tarjeta" />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container sm={12} >
                        <Grid container sm={12}>
                            <p className='Lectura'>
                                Con la mañana surgían nuevos médicos renombrados que curaban las epidemias 
                                que aparecían y desaparecían en un pestañear. Por la tarde aparecían 
                                ingenieros que comenzaban a construir puentes y edificios que, con el 
                                tiempo, se volvían parques de atracciones. Ya en la noche se veían en 
                                las plazas públicas los cocineros, siempre distintos, quienes llamaban 
                                la atención de los peatones con fuertes golpes de cucharas en cacerolas 
                                de cobre, para recitar un poema o hacer malabares. Por desgracia, el 
                                público que llegaba allí no acostumbraba quedarse.
                            </p>
                        </Grid>
                        <Grid container sm={12} justifyContent='center' alignContent='center'>
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion6/2.png" alt="Tarjeta" /> 
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container sm={12} >
                        <Grid container sm={12}>
                            <p className='Lectura'>
                                Los niños crecían muy rápido o muy lento (nadie sabía exactamente) y 
                                siempre terminaban siendo huérfanos, porque sus padres originales ya 
                                se habían transformado en personas diferentes. Tampoco iban a la escuela,
                                o tal vez sí, en algunas ocasiones, pero ya estando allí olvidaban a qué 
                                habían ido.
                            </p>
                        </Grid>
                        <Grid container sm={12} justifyContent='center' alignContent='center'>
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion6/1.png" alt="Tarjeta" /> 
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className='Lectura'>
                            Todos estos cambios les generaban muchos problemas a los turistas, 
                            pues en poco tiempo se quedaban sin el hotel al que originalmente 
                            habían llegado, y tenían que vagar perdidos en ese absurdo y caprichoso 
                            lugar. Sin embargo, con el tiempo, el contingente de turistas vagabundos 
                            se iba transformando en médicos, ingenieros o cocineros. Los más 
                            atrevidos se volvían niños. <br/><br/>
                            
                            Los países fronterizos también sufrían con los cambios de su vecino, a 
                            tal grado que ya no sabían dónde acababa su territorio y dónde empezaba 
                            el del país en constante cambio. Los granjeros de las fronteras tenían 
                            que cambiar constantemente sus papeles para probar la nacionalidad que 
                            les correspondía en aquel momento. <br/><br/>
                            
                            Un día, los mayores representantes de los países fronterizos
                            se reunieron y acordaron formar un plan para poner un alto a los daños ocasionados por
                            el vecino. Las conversaciones que tuvieron con los embajadores del país 
                            cambiante siempre fracasaron, debido a las diferencias políticas que 
                            presentaban los distintos representantes del país flexible. En ocasiones, 
                            incluso, había dos o más supuestos embajadores que se llamaban farsantes 
                            uno a otro y nadie podía saber quién era el verdadero embajador.
                        </p>
                    </Grid>
                    <Grid container justifyContent="center" >
                        <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion6/3.png" alt="Tarjeta" />
                    </Grid> 
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className='Lectura'>
                            Entonces, los vecinos decidieron atacar. Bombardearon y saquearon el país 
                            en constante movimiento. A veces hasta recibieron ayuda de los mismos 
                            pobladores. Y cuando lograron destruir la última casa, el último árbol 
                            y al último habitante, la tierra comenzó a temblar y se fue hundiendo 
                            hasta desaparecer en un abismo oscuro. En donde antes hubo tierra, 
                            ahora se extendía un inmenso territorio de sombra y vacío. Sólo se 
                            oía un viento que soplaba sin cesar y en ocasiones se alcanzaba a escuchar 
                            uno que otro lamento.<br/><br/>
                            
                            Entonces, arrepentidos, los pobladores de los países vecinos 
                            decidieron componer canciones, contar historias y escribir 
                            libros en honor de aquel país desaparecido. Los científicos 
                            hicieron tratados sobre el cambio y la evolución, y los políticos 
                            inauguraron estatuas que recordaban a los antiguos habitantes del 
                            país desaparecido. Ninguno se dio cuenta de que todo empezaba a 
                            transformarse a su alrededor. El país de los cambios, de 
                            alguna manera, los había invadido.
                        </p>
                    </Grid>
                    <Grid container justifyContent="center" >
                        <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion6/5.png" alt="Tarjeta" />
                    </Grid> 
                </Grid>
               
               
            <Grid container item xs={12} justifyContent="center" className="ContainerDerechos">
                <p className="Derechos">
                    © 2022. Todos los derechos reservados, Pearson, Circuito de lectores.
                </p>                
            </Grid>
        </Grid>
    </Grid>
    );
}
 
export default El_país_de_los_cambios;
