import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
    ContenedorLectura:{
        width:'100%',
        [theme.breakpoints.down(768)]:{
            width:'350px',
        },
        
        '& .Imagen':{
            width:'100%',
        },

        
    },

    
}));

const La_hidrósfera = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>

            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                
                <img className='Imagen' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion14/F.png" alt="Fondo" />
      
            </Grid>

        </Grid>
    );
}
 
export default La_hidrósfera;
