import React              from "react";
import AnswerTetx from "./AnswerText";
import AnswerImage from "./AnswerImage";
import Answer from "./Answer";


const BasicAnswer = (props) => {
  const{
    index,
    setPristine,
    setIsCorrect,
    setQuestionId,
    setFeedback,
    answerText,
    setAnswerText,
    setCurrentAnswer,
    currentAnswer,
    setTotalPoints,
    totalPoints,
    setValueAnswerText,
    valueAnswerText,
    setAnswerImage,
    questionType,
    respuesta,
    setFeedBackImage,
    feedBackImage,
    imageList
  } = props

  const renderAnswer = () => {
    switch (questionType) {

      case "CuaText":
        return (
          <AnswerTetx
            // key={respuesta.id_respuesta + "-EvDiagnostica"}
            setPristine={setPristine}
            setIsCorrect={setIsCorrect}
            isCorrect={respuesta.correcta}
            questionId={respuesta.id_respuesta}
            feedback={respuesta.feedback}
            setFeedback={setFeedback}
            setTotalPoints={setTotalPoints}
            totalPoints={totalPoints}
            setValueAnswerText={setValueAnswerText}
            valueAnswerText={valueAnswerText}
            setCurrentAnswer={setCurrentAnswer}
            currentAnswer={currentAnswer}
          />
        )
      case "OpcMultImg":
          return (
            <AnswerImage
              // key={respuesta.id_respuesta + "-Evformativa1"}
              index={index}
              setPristine={setPristine}
              setIsCorrect={setIsCorrect}
              isCorrect={respuesta.correcta}
              questionId={respuesta.id_respuesta}
              setQuestionId={setQuestionId}
              feedback={respuesta.feedback}
              setFeedback={setFeedback}
              answerImage={respuesta.respuesta}
              setAnswerImage={setAnswerImage}
              setCurrentAnswer={setCurrentAnswer}
              currentAnswer={currentAnswer}
              setTotalPoints={setTotalPoints}
              totalPoints={totalPoints}
              answerText={answerText}
              setAnswerText={setAnswerText}
              feedBackImage={feedBackImage}
              setFeedBackImage={setFeedBackImage}
              imageList={imageList}
            />
            // <ImageList
            //   itemData={respuesta.respuesta}
            // />
          )
      default:
        return (
          <Answer
            key={respuesta.id_respuesta + "-EvDiagnostica"}
            index={index}
            setPristine={setPristine}
            setIsCorrect={setIsCorrect}
            isCorrect={respuesta.correcta}
            questionId={respuesta.id_respuesta}
            feedback={respuesta.feedback}
            setFeedback={setFeedback}
            answerText={respuesta.respuesta}
            setAnswerText={setAnswerText}
            setValueAnswerText={setValueAnswerText}
            setCurrentAnswer={setCurrentAnswer}
            currentAnswer={currentAnswer}
            setTotalPoints={setTotalPoints}
            totalPoints={totalPoints}
          />
        );
    }
  }


  return(
    
      renderAnswer()
    
  )
}

export default BasicAnswer;