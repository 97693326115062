import React               from "react";
import {Grid, makeStyles}                 from '@material-ui/core';

const useStyles = makeStyles(theme =>({
  questionContainer:{
    width: "100%",
    height:"100%",
  },
  reading: {
    width: "80%",
    margin:"10px 0 10px 0 ",
    font: '22px lato',
    letterSpacing: '0.21px',
    [theme.breakpoints.down(1400)]:{
      font: " 18px lato",
      width: "95%",
    },
    [theme.breakpoints.down(1310)]:{
      font: "500 16px lato",

    },
    [theme.breakpoints.down(925)]:{
      font: "500 14px lato",

    },
    [theme.breakpoints.down(426)]:{
      font: "500 12px lato",
 
    }, 
  },
  table: {
    border: '1px solid black',
    width: "100%",
    font: '22px lato',
    [theme.breakpoints.down(1400)]:{
      font: " 18px lato",
      width: "95%",
    },
    [theme.breakpoints.down(1310)]:{
      font: "500 16px lato",
 
    },
    [theme.breakpoints.down(925)]:{
      font: "500 14px lato",

    },
    [theme.breakpoints.down(426)]:{
      font: "500 12px lato",

    }, 
  },
  tdTable: {
    padding: "0 1%",
    border: '1px solid black',
  }
}));

const QuestionTable7 = (props) => {
  const classes = useStyles()

  return(
    <Grid container className={classes.questionContainer}>
      <p
        className={classes.reading}
      >
        7. Lee las escenas y relaciónalas con los tipos de narración que les corresponden.
      </p>
      <br/>
      <table className={classes.table}>
        <thead>
          <th style={{width: "60%", border: '1px solid black'}}><i>Descripción de escena</i></th>
          <th style={{width: "40%", border: '1px solid black'}}><i>Tipo de narración</i></th>
        </thead>
        <tbody>
          <tr>
            <td className={classes.tdTable}>
              <p>
                1. De las nubes, se asomó el Dios del trueno y castigó a todos aquellos que osaron ofenderlo con sus acciones.
              </p>
            </td>
            <td className={classes.tdTable}>
              <p>
                a. didáctica
              </p>
            </td>
          </tr>
          <tr>
            <td className={classes.tdTable}>
              <p>
              2. <br/>La tortuga caminó sin detenerse. Siempre esforzándose por ser constante, aunque se sintiera cansada.
                La liebre, al abrir los ojos después de su descanso, se percató de que había perdido la carrera.
              </p>
            </td>
            <td className={classes.tdTable}>
              <p>
                b. leyenda
              </p>
            </td>
          </tr>
          <tr>
            <td className={classes.tdTable}>
              <p>
                3. Los dos hermanos, que se encontraban perdidos en el bosque, no podían creer que habían encontrado una 
                deliciosa casa hecha de los dulces que más les gustaban. Pensaron que si entraban seguro podrían pasar 
                la noche y alimentarse, antes de intentar regresar a casa.
              </p>
            </td>
            <td className={classes.tdTable}>
              <p>
                c. cuento
              </p>
            </td>
          </tr>
          <tr>
            <td className={classes.tdTable}>
              <p>
                4. Los habitantes del pueblo afirman que todas las noches camina tenebrosamente aquella mujer que murió
                hace muchos años, esperanzada en volver a abrazar a sus hijos.
              </p>
            </td>
            <td className={classes.tdTable}>
              <p>
                d. mito
              </p>
            </td>
          </tr>
        </tbody>
      </table>
    </Grid>
  )
}

export default QuestionTable7;
