import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
    ContenedorLectura:{
        width:'502px',
                  [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
    },
    ContainerTitulo:{
        margin:'0 0 46px 0',
        '& .Titulo':{
            letterSpacing: '0px',
            color: '#F01A55',
            margin:'0',
            textAlign:'center',
            font:'43px fredoka one',
            lineHeight:'52px',
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            margin:'0 0 36px 0',
            color:'#000',
            font:'18px lato',
            lineHeight :'28px',
            letterSpacing: '0.17px',
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .Lectura2':{
            margin:'0',
        },
        '& .SubTitulo':{
            color:'#CE4F72',
            margin:'0',
            lineHeight :'28px',
            font:'bold 26px lato',
            letterSpacing: '0.24px',
            [theme.breakpoints.down(502)]:{
                font:'bold 18px lato',
                lineHeight :'24px',
                letterSpacing: '0.21px',
            },
        },
        '& .SubTitulo2':{
            color:'#5D71B6',
            margin:'0',
            font:'bold 22px lato',
            lineHeight :'24px',
            letterSpacing: '0.21px',
            [theme.breakpoints.down(502)]:{
                font:'bold 18px lato',
                lineHeight :'20px',
                letterSpacing: '0.16px',
            },
        },
        '& .TextImg':{
            color:'#717476',
            margin:'24px 0 0 0',
            font:' 16px lato',
            lineHeight :'25px',
            letterSpacing: '0.15px',
            [theme.breakpoints.down(502)]:{
                font:' 11px lato',
                lineHeight :'14px',
                letterSpacing: '0.10px',
            },
        },
        '& .ul':{
            margin:'0 ',
            padding:'0 0 0 19px',
            color:'#EEA90A',
        },
        '& .li':{
            margin:'0 8px 0 0',
            [theme.breakpoints.down(52)]:{
                margin:'0 4px 0 0',

            },
        },
        '& .Bully':{
            color:'#CE4F72'
        },

        '& .Imagen':{
            width:'100%',
        },  
      
    }
}));

const Violencia_escolar = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
             
            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                        <h2 className="Titulo">Violencia escolar</h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                       <p className='Lectura'>
                        <h3 className='SubTitulo'><em>Bullying</em></h3><br/>

                        El maltrato constante que algunos estudiantes reciben de otros se conoce como 
                        <em> acoso escolar</em> o <em>bullying</em> (término que significa <em>intimidación</em> en inglés). Esta forma de 
                        violencia tiene muchos tipos:
                       </p>
                       
                       <ul className='ul'>
                            <li className='li'>
                                <p className='Lectura Lectura2'>
                                    <span className='Bully'>Físico:</span> Cuando reciben golpes, heridas o cualquier forma de abuso en su cuerpo.
                                </p>
                            </li>
                            <li className='li'>
                                <p className='Lectura Lectura2'>
                                    <span className='Bully'>Verbal:</span> Cuando reciben insultos o se esparcen rumores sobre su persona. Puede ser oralmente o por escrito.
                                </p>
                            </li>
                            <li className='li'>
                                <p className='Lectura Lectura2'>
                                    <span className='Bully'>Psicológico:</span> Cuando reciben amenazas de cualquier tipo.
                                </p>
                            </li>
                            <li className='li'>
                                <p className='Lectura Lectura2'>
                                    <span className='Bully'>Cibernético:</span>  Cuando se les intimida a través de redes sociales o se comparte en línea contenido desagradable sobre su persona.
                                </p>
                            </li>
                        </ul>                  
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container >
                        <p className='Lectura'>
                            <h3 className='SubTitulo2'>Números para pensar</h3><br/>

                            El <em>bullying</em> afecta gravemente la tranquilidad de los estudiantes y repercute en su 
                            aprendizaje. En un estudio de 2011 la Comisión Económica para América Latina y el 
                            Caribe (<span style={{fontVariant: "all-small-caps"}}>CEPAL</span>) encontró que las víctimas de <em>bullying</em> tenían un desempeño en lectura 
                            y matemáticas mucho más bajo que el de los alumnos que no sufrían maltrato.
                        </p>
                    </Grid>
                    <Grid container justifyContent='center'>
                        <Grid container justifyContent='center'>
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion40/1.png" alt="Tarjeta" />
                        </Grid>
                        <Grid container justifyContent='center' >
                            <p className="TextImg">
                                ¿Qué tan común crees que sea el <em>bullying</em>?
                            </p>
                        </Grid>
                    </Grid>

                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container >
                        <p className='Lectura'>
                            El Fondo de las Naciones Unidas para la Infancia (<span style={{fontVariant: "all-small-caps"}}>UNICEF</span>, por sus siglas en inglés)
                            publicó en 2011 los resultados de una investigación sobre el acoso escolar en 
                            América Latina y el Caribe. Concluyó que entre 50 y 70% de la población estudiantil 
                            había sufrido o atestiguado algún tipo de violencia en la escuela.<br/><br/>
                            
                            En 2015 el Programa Internacional para la Evaluación de Estudiantes (<span style={{fontVariant: "all-small-caps"}}>PISA</span>, por sus 
                            siglas en inglés) realizó un estudio sobre bienestar. Encontró que 8.9% de los 
                            alumnos sufría un acoso escolar constante. Tres países de América Latina (República
                            Dominicana, Costa Rica y México) superaron el 10 por ciento. <br/><br/>
                            
                            <h3 className='SubTitulo'>¿De dónde viene la violencia?</h3><br/>
                            
                            Hay algo en el entorno escolar y juvenil que promueve la violencia y dificulta la 
                            empatía. Pero ¿qué es? Quizá hayas escuchado a alguien sugerir que los culpables
                            son los videojuegos.
                        </p>
                    </Grid>
                    <Grid container justifyContent='center'>
                        <Grid container justifyContent='center'>
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion40/2.png" alt="Tarjeta" />
                        </Grid>
                        <Grid container justifyContent='center' >
                            <p className="TextImg">
                            ¿Qué relación hay entre los videojuegos y la violencia?
                            </p>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container >
                        <p className='Lectura'>
                            El 10 de enero de 2020 un estudiante de 11 años realizó un tiroteo con armas de 
                            fuego dentro de un colegio privado en Coahuila (México). Vestía un pantalón negro 
                            con tirantes y una playera con la leyenda “Natural Selection”. Las investigaciones 
                            pronto identificaron que éste era el nombre de un videojuego con características del
                            género <em>shooter</em> (“tirador”): el jugador ve el ambiente a través de los ojos de un 
                            personaje que dispara armas de fuego y erradica a los enemigos que van apareciendo 
                            en la pantalla. Las autoridades mexicanas se apresuraron a culpar al juego por el 
                            tiroteo, reviviendo un viejo debate: ¿los videojuegos estimulan un comportamiento 
                            agresivo? <br/><br/>
                            
                            No debemos apresurarnos a juzgar. Unos pantalones negros y una playera blanca con
                            la leyenda “Natural Selection” eran la ropa que llevaba Eric Harris el 20 de abril de 
                            1999. Ese día el joven de 18 años y su compañero Dylan Klebold comenzaron una 
                            masacre en la Columbine High School en Colorado (Estados Unidos). En 1999 el 
                            videojuego <em>Natural Selection</em> aún no existía. Si algo influenció al chico de Coahuila, 
                            pudo ser el tiroteo de Columbine. ¿Por qué pensar que la influencia principal sería el 
                            videojuego? <br/><br/>
                            
                            Muchos estudios psicológicos recientes han intentado precisar qué relación hay 
                            entre los videojuegos y la violencia. La Asociación Estadounidense de Psicología 
                            (<span style={{fontVariant: "all-small-caps"}}>APA</span>, por sus siglas en inglés) concluyó que no existe evidencia suficiente para 
                            afirmar que los videojuegos provocan un comportamiento violento. La doctora 
                            Sandra L. Shullman, presidenta de la <span style={{fontVariant: "all-small-caps"}}>APA</span>, declaró que “atribuir la violencia a los 
                            videojuegos no tiene sustento científico y distrae nuestra atención de otros
                             factores”. <br/><br/>
                             
                             <h3 className='SubTitulo2'>Violencia y sociedad</h3><br/>
                             
                            ¿Qué factores determinan el desarrollo del <em>bullying</em>? Quizá el agresor también fue
                            víctima de violencia familiar o escolar. Si es hombre, tal vez tenga la idea de que la 
                            masculinidad es forzosamente agresiva. Por último, hay que valorar si vive en un 
                            contexto de inseguridad social. <br/><br/>
                            
                            Los ambientes violentos generan violencia. Discutir el problema sólo cuando hay 
                            tiroteos nos hace olvidar que el acoso en las aulas es un fenómeno común. ¿Te has 
                            preguntado si tú haces <em>bullying</em>? ¿Si estás siendo violento? ¿Si estás sufriendo 
                            violencia?
                        </p>
                    </Grid>
                </Grid>
        </Grid>
    </Grid>
    );
}
 
export default Violencia_escolar;
