import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
    ContenedorLectura:{
        width:'502px',
                  [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
    },
    ContainerTitulo:{
        margin:'0 0 34px 0',
        '& .Titulo':{
            letterSpacing: '0px',
            color: '#80A74D',
            margin:'0',
            textAlign:'center',
            font:'43px fredoka one',
            lineHeight:'52px',
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            margin:'0 0 36px 0',
            color:'#000',
            font:'18px lato',
            lineHeight :'28px',
            letterSpacing: '0.17px',
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        "& .SubTitulo": {
            color: "#564035",
            textAlign:'center',
            margin: "0 0 30px 0",
            font: "27px Fredoka One",
            lineHeight: "27px",
            [theme.breakpoints.down(502)]: {
                margin: "0 0 15px 0",
                textAlign:'center',
                font: "22px Fredoka One",
                lineHeight: "24px",
            },
            [theme.breakpoints.down(350)]: {
                margin: "0 0 10px 0",
                textAlign:'center',
                font: "20px Fredoka One",
                lineHeight: "22px",
            },
        },
        '& .TextImg':{
            color:'#717476',
            margin:'24px 0 0 0',
            font:' 16px lato',
            lineHeight :'25px',
            letterSpacing: '0.15px',
            [theme.breakpoints.down(502)]:{
                font:' 11px lato',
                lineHeight :'14px',
                letterSpacing: '0.10px',
            },
        },
        '& .Cuadro':{
            position:'relative',
        },
        '& .Esp':{
            position:'absolute',
            margin:'51px 0 0 0',
            width:'74.70%',
            [theme.breakpoints.down(502)]:{
                margin:'25px 0 0 0',
                font:'14.5px lato!important',
                lineHeight :'17.5px!important',
                letterSpacing: '0.09px!important',
            },
            [theme.breakpoints.down(350)]:{
                margin:'25px 0 0 0',
                font:'12px lato!important',
                lineHeight :'14px!important',
                letterSpacing: '0.09px!important',
            },
        },
        
        '& .Imagen':{
            width:'100%',
        },
      
    },
    Especial:{
        margin:'0',
    },
    
}));

const Los_grandes_arqueólogos_del_siglo_XX = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >
                        Los grandes<br/> arqueólogos<br/> del siglo XX                
                    </h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                      <p className="Lectura">
                        El valor de la arqueología se ha reconocido recientemente entre varios círculos. 
                        Durante muchos años, la mayor fuente de conocimiento sobre la historia humana 
                        fueron únicamente los mitos, las leyendas, los cantos y demás vestigios que las 
                        civilizaciones fueron preservando. Pero el panorama cambió en el siglo <span style={{fontVariant: "all-small-caps"}}>XIX</span>. Los 
                        viajeros y exploradores empezaron a documentar lo que veían en sus viajes a través 
                        de libros, fotografías y dibujos. Esto llamó la atención de los geólogos y los 
                        arquitectos, quienes profundizaron en sus observaciones. Entonces, surgió una 
                        nueva rama que se dedicó a estudiar estos registros: la arqueología.<br/><br/> 
                        
                        En ese periodo se vivió un gran interés por el estudio de culturas antiguas. Las 
                        primeras excavaciones se hicieron con pocas herramientas y conocimientos. Era una 
                        labor más bien intuitiva. La disciplina se consolidó en el siglo <span style={{fontVariant: "all-small-caps"}}>XX</span>, cuando se 
                        registraron innovaciones importantes. Actualmente es una disciplina más completa, 
                        tanto que ¡sus hallazgos han cambiado la forma en que se veía el origen y la historia 
                        de la humanidad!
                      </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <Grid container justifyContent="center" >
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img4to/Sesion45/1.png" alt="Tarjeta" />
                        </Grid>
                        <Grid container justifyContent="center" >
                            <p className='TextImg'>
                                Las excavaciones han evolucionado considerablemente desde su creación.
                            </p>                        
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={`${classes.Parrafo1} ${classes.Especial}`} justifyContent="center">
                    <Grid container sm={12}>
                      <p className="Lectura">
                        Una gran cantidad de estudiosos han dejado marca en esta área y en la historia. A 
                        continuación, conocerás los aportes y las trayectorias de algunos arqueólogos.
                      </p>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={`${classes.Parrafo1} ${classes.Especial}`} justifyContent="center">
                    <Grid container item justifyContent="center" className="Cuadro" sm={12}>
                        <img className="Imagen " src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img4to/Sesion45/2R.png" alt="Tarjeta" />
                        <p className="Lectura Esp">
                            <h3 className='SubTitulo'>
                                Beatriz Barba Ahuatzin<br/>
                                (1928 - 2021)
                            </h3>
                            Esta especialista nació en México y fue fundadora del Museo Nacional 
                            de las Culturas y miembro de la Academia Mexicana de Ciencias 
                            Antropológicas. Fue una reconocida maestra que abrió el camino a 
                            otras mujeres. Dentro de sus aportaciones destacan las excavaciones 
                            en Tlapacoya (Estado de México) y en el Valle de Guadalupe (Jalisco). 
                            <br/><br/>
                            Investigó las clases sociales en el Distrito Federal, hoy llamada Ciudad 
                            de México. Y participó en la planeación del Museo Nacional de 
                            Antropología e Historia de esa misma urbe. Abandonó las tareas del 
                            campo con el paso del tiempo y amplió sus horizontes a la 
                            investigación, docencia, difusión cultural, museografía y divulgación 
                            científica.
                        </p>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={`${classes.Parrafo1} ${classes.Especial}`} justifyContent="center">
                    <Grid container item justifyContent="center" className="Cuadro" sm={12}>
                        <img className="Imagen " src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img4to/Sesion45/3R.png" alt="Tarjeta" />
                        <p className="Lectura Esp">
                            <h3 className='SubTitulo'>
                                Eckhard Unger<br/> (1884 – 1966)
                            </h3>
                            Fue un alemán que se dedicó a conservar, cuidar y reparar los objetos 
                            que guardaba el Museo Arqueológico de Estambul. Descubrió la vara 
                            de medida más antigua del mundo y los restos de las puertas de 
                            Balawat. Estos últimos son tres conjuntos de bandas de bronce 
                            decoradas con relieves; su función es adornar las puertas principales 
                            de varios edificios de Balawat y datan del siglo IX a. n. e. La mayor 
                            parte de estos vestigios se conservan en el Museo Británico y 
                            algunos más en el Museo de Mosul. Eckhard Unger fue la primera 
                            persona en investigar el Obelisco Blanco y publicó estudios sobre los 
                            relieves del rey asirio Tiglatpileser III en 1916.
                        </p>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={`${classes.Parrafo1} ${classes.Especial}`} justifyContent="center">
                    <Grid container item justifyContent="center" className="Cuadro" sm={12}>
                        <img className="Imagen " src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img4to/Sesion45/4R.png" alt="Tarjeta" />
                        <p className="Lectura Esp">
                            <h3 className='SubTitulo'>
                                Jacinto Jijón y Caamaño<br/> (1890 - 1950)
                            </h3>
                            Fue un político, historiador y coleccionista de arte ecuatoriano. Logró 
                            identificar y describir la primera secuencia cultural del Ecuador 
                            precolombino. Estudió cómo vivían los habitantes de la zona de los 
                            Andes centrales y Centroamérica. También definió diversas culturas 
                            de la zona. Realizó excavaciones en la sierra Manabí (Ecuador) y en 
                            Tiahuanaco (en Bolivia). Así como en el Complejo Maranga y en 
                            Cusco, ambos en Perú.
                        </p>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                      <p className="Lectura">
                        Son muchos los hallazgos que ayudan a conocer el origen, el desarrollo y las 
                        características las civilizaciones antiguas y modernas. Sin la arqueología, sería 
                        difícil consolidar el conocimiento de la historia de la humanidad, lo cual nos permite 
                        comprender y explicar nuestro origen. ¿Tú conoces a algún especialista que haya 
                        hecho estudios importantes sobre tu comunidad?
                      </p>
                    </Grid>
                </Grid>
        </Grid>
    </Grid>
    );
}
 
export default Los_grandes_arqueólogos_del_siglo_XX ;
