import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
     Contenedor:{
        background:'#E9FBF9',
        width:'100vw',
        overflowX:'clip',
        [theme.breakpoints.down(1161)]:{
            background:'none',
           overflowX:'unset',
        },
        [theme.breakpoints.down(600)]:{
            marginTop: '79.51px',
        }
    },
  ContainerInfoLectura: {
    position: "relative",
    height: "86px",
    [theme.breakpoints.down(502)]:{
        height: "43px",
    },
    background: "#76D4C3",
    "& .BarraInfoLectura": {
      height: "86px",
      background: `Url(https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Barras/Azul.png)`,
        [theme.breakpoints.down(502)]:{
            height: "43px",
        },
    },
    "& .InfoLectura": {
      position: "absolute",
      height: "86px",
      width: "219px",
      boxSizing: "border-box",
      padding: "17.2px 0",
      background: "#FAFAFA",
      borderRadius: "50px",
      boxShadow: "2px 2px 2px 2px #00000029",
      zIndex: "4",
        [theme.breakpoints.down(502)]:{
            height: "43px",
            width: "109px",
            padding: "8.6px 0",
            borderRadius: "25px",
        },
    },
    "& .InfoLectura:before": {
      position: "absolute",
      display: "flex",
      boxSizing: "border-box",
      padding: "0 0 0 10px",
      justifyContent: "center",
      alignItems: "center",
      height: "39.88px",
      width: "39.88px",
      borderRadius: "50%",
      content: '""',
      boxShadow: "3px 0px .5px 0px #00000029",
      background: "#FAFAFA",
      left: "90px",
      top: "65px",
      transform: "rotate(0.25turn)",
      [theme.breakpoints.down(502)]:{
        left: "45px",
        top: "30px",
        height: "20.88px",
        width: "20.88px",
        },
    },
    "& .InfoLectura:after": {
      content: '""',
      height: "15px",
      width: "15px",
      borderTop: "2px solid #20A698",
      borderLeft: "2px solid #20A698",
      position: "absolute",
      bottom: "-10px",
      left: "Calc(50% - 8px)",
      transform: "rotate(0.625turn)",
      [theme.breakpoints.down(502)]:{
        height: "10px",
        width: "10px",
        left: "Calc(50% - 4.5px)",
        bottom: "-3px",
        borderTop: "1px solid #20A698",
        borderLeft: "1px solid #20A698",
        },
    },
    "& .Sesion": {
      borderLeft: "2px solid #D8D8D8",
    },
    "& .Dato": {
      margin: "0 0 5px 0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#20A698",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        margin: "0 0 2.5px 0",
        },
    },
    "& .Caracter": {
      margin: "0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#000000",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        },
    },   },
    ContenedorLectura:{
        background:'#fff',
        width:'1160px',
        height:'100%',
        padding:'0',
        [theme.breakpoints.down(1161)]:{
            width:'769px',
          },
          [theme.breakpoints.down(769)]:{
            width:'350px',
          },
        '& .Escritorio':{
            display:'block',
            [theme.breakpoints.down(1161)]:{
                display:'none'
            },
        },
        '& .Tablet':{
            display:'none',
            [theme.breakpoints.down(1161)]:{
                display:'block'
            },
        }
    },

    Parrafo1:{
        position:'relative',
        [theme.breakpoints.down(350)]:{
            display:'none',
        },
        '& .Pos':{
            position:'absolute',
            margin:'0',
        },
        '& .Titulo':{
            font:'101px Fredoka One',
            color:'#FFFFFF',
            lineHeigth:'75px',
            top:'45px',
            [theme.breakpoints.down(1161)]:{
                font:'44px Fredoka One',
                top:'15px',
            },
            [theme.breakpoints.down(769)]:{
                font:'16px Fredoka One',
                top:'15px',

            },
        },
        '& .Subtitulo':{
            font:'29px Fredoka One',
            color:'#707070',
            lineHeigth:'35px',
            left:'315px',
            [theme.breakpoints.down(1161)]:{
                font:'16px Fredoka One',
                left:'205px',
            },
            [theme.breakpoints.down(769)]:{
                font:'10px Fredoka One',
                left:'95px',
            },
            
        },
        '& .Sub1':{
            top:'305px',
            [theme.breakpoints.down(1161)]:{
                top:'153px',
            },
            [theme.breakpoints.down(769)]:{
                top:'68px',
            },

        },
        '& .Sub2':{
            top:'353px',
            [theme.breakpoints.down(1161)]:{
                top:'183px',
            },
            [theme.breakpoints.down(769)]:{
                top:'97px',
            },

        },
        '& .Sub3':{
            top:'402px',
            [theme.breakpoints.down(1161)]:{
                top:'217px',
            },
            [theme.breakpoints.down(769)]:{
                top:'83px',
            },

        },
        '& .Cuerpo':{
            font:'22px lato',
            color:'##000000',
            top:'481px',
            left:'275px',
            letterSpacing:'.21px',
            lineHeigth:'30px',
            width:'824px',
            [theme.breakpoints.down(1161)]:{
                width:'492px',
                font:'18px lato',
                top:'285px',
                left:'185px',
            },
            [theme.breakpoints.down(768)]:{
                width:'255px',
                font:'10px lato',
                top:'125px',
                left:'80px',
            },
        },
        '& .Derechos':{
            position:'absolute',
            bottom:'35px',
            textAlign:"center",
            margin:"0px",
            font:" 15px lato",
            color:"#717476",
            [theme.breakpoints.down(1161)]:{
                display:'none'
            },
        },
        '& .Imagen':{
            width:'100%'
        },
    },
    
    Imagen2:{
        display:'none',
        [theme.breakpoints.down(350)]:{
            display:'flex',
        width: "100%",
        },
    },

}));

const Corre_electronico = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
             <Grid container item justifyContent="center" className={classes.ContainerInfoLectura}>
               <Grid container className="BarraInfoLectura"></Grid>
               <Grid container item className="InfoLectura">
                    <Grid container item direction="column" xs={6} justifyContent="center">
                        <p className="Dato">Grado</p>
                        <p className="Caracter">1</p>
                    </Grid>
                    <Grid container item direction="column" xs={6} justifyContent="center" className="Sesion">
                        <p className="Dato">Sesión</p>
                        <p className="Caracter">52</p>
                    </Grid>
               </Grid>
            </Grid>

            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                <h2 className='Pos Titulo'>Correo electrónico</h2>
                <h3 className='Pos Subtitulo Sub1'>Partituras</h3>
                <h3 className='Pos Subtitulo Sub2'>soyneli@micorreo.com.mx</h3>
                <h3 className='Pos Subtitulo Sub3'>Lucy@micorreo.com.mx</h3>
                <p  className='Pos Cuerpo'>
                    <b>Hola, Lucy:</b><br/><br/>

                    ¿Cómo estás?<br/><br/>
                    
                    Éste es mi correo electrónico. Por acá te compartiré todas las partituras. Así las 
                    podrás ver mejor desde la computadora.<br/><br/>

                    Extraño mucho visitarte y tocar juntas como antes de la pandemia. Ojalá pronto 
                    podamos ir a visitarte a ti y a mis abuelos. Mientras, tendré que conformarme con 
                    verlos desde el celular de Gina. Ella te extraña también, dice que eres su prima 
                    favorita.<br/><br/>

                    La partitura que te mando es de un compositor que vamos a estudiar el lunes. Se 
                    llama Tchaikovski. La obra es <em>El lago de los cisnes</em>. ¿Sabes de qué trata? Propongo que 
                    busquemos un poco de la obra y lo hablemos por mensaje de texto. ¿Qué piensas?<br/><br/>
                    
                    ¿Te imaginas que pudiéramos tocar por videollamada? ¡Creo que sería el primer 
                    concierto que se hace desde dos ciudades distintas! Revisa la partitura y dime qué 
                    piensas. Podría ser que nos organicemos para hacerlo esta Navidad. Practicaré
                    mucho para ayudarte si lo necesitas.<br/><br/>
                     
                    Tengo que ir a comer. Salúdame a mis tíos y a mis abuelos.<br/><br/>
                    
                    Te quiero, prima.<br/><br/>

                    Neli
                </p>
                <img className='Imagen Escritorio' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img1ro/Sesion52/1.png" alt="Tarjeta" />
                <img className='Imagen Tablet' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img1ro/Sesion52/1R.png" alt="Tarjeta" />

                <p className="Derechos">
                    © 2022. Todos los derechos reservados, Pearson, Circuito de lectores.
                </p>                
                </Grid>
                <img className={classes.Imagen2} src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img1ro/Sesion52/F.png" alt="Tarjeta" />

        </Grid>
    </Grid>
    );
}
 
export default Corre_electronico ;
