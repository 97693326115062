import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
    ContenedorLectura:{
        width:'502px',
        [theme.breakpoints.down(502)]:{
            width:'350px',
        },
    },
    ContainerTitulo:{
        margin:'0 0 38px 0',
        '& .Titulo':{
            letterSpacing: '0px',
            color: '#5A8FA3',
            margin:'0',
            font:'43px fredoka one',
            lineHeight:'52px',
            textAlign:'center',
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 22px 0',
        '& .Lectura':{
            margin:'0 0 36px 0 ',
            color:'#000',
            font:'18px lato',
            letterSpacing:'.17px',
            lineHeight:'28px',
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .Lectura2':{
            margin:'0',
        },
        '& .Imagen':{
            margin:'0 0 36px 0',
            width:'90%',
        },
        '& .Imagen2':{
            width:'100%',
        },

    },
    Cuadro:{
        position:'relative',
        margin:'0 0 47px 0',
        boxSizing:'border-box',
        background:`Url(https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion41/2.png)`,
        backgroundRepeat:'no-repeat',
        backgroundSize:'100% 100%',
        padding:'49px 32px 0',
        '& .Lectura3':{
            margin:'0 0 21px 0',
        },
    },


}));

const El_hogar_de_las_palabras = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >
                        El hogar de las palabras
                    </h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura Lectura2">
                            En el principio fue el olvido. La sabiduría y las historias de nuestros ancestros 
                            pasaban de una generación a otra de boca en boca. Los hijos retenían en la memoria 
                            lo que sus padres y sus abuelos les habían contado alguna noche alrededor del fuego. <br/><br/>
                            
                            Con el fin de mantener vivo el pasado, en las escuelas, ciertas personas se dedicaban 
                            exclusivamente a memorizar todo lo que los seres humanos sabían hasta ese 
                            momento. Cuando estaban listos, viajaban por el mundo para difundir sus 
                            conocimientos. En cada pueblo que visitaban, reunían a la gente en las plazas y 
                            comenzaban a cantar su sabiduría. <br/><br/>
                            
                            En aquella época, esos hombres y mujeres pusieron un alto al olvido. Aunque poco a 
                            poco surgieron dos problemas. Primero, el conocimiento que debían memorizar no 
                            paraba de acumularse. Segundo, cuando esos especialistas del recuerdo morían, una 
                            parte de la humanidad moría con ellos: nombres, mitos, recetas, amores, sueños… 
                            Todo se perdía para siempre.<br/><br/>
                            
                            Entonces, los seres humanos del pasado comprendieron que nadie podía aprenderlo 
                            todo y que las palabras tienen alas y vuelan, son como el viento. Las palabras nacen 
                            del silencio y en el silencio se extinguen.<br/><br/>
                            
                            “¿Cómo haremos para capturar las palabras?”, se preguntaban los sabios cuando se 
                            reunían en las plazas. Pero nadie encontraba la respuesta. De pronto, una noche, 
                            después de horas y horas de participar sin resultado en el concejo, una sabia mujer
                            del Sur que se había ido a descansar, empezó a soñar:
                        </p>
                    </Grid>
                </Grid>

                <Grid container justifyContent="center" className={`${classes.Parrafo1}  ${classes.Cuadro}`} sm={12}>
                            <p className='Lectura Lectura3'><em>
                            Un joven pastor salía todas las mañanas con su rebaño. Cada noche, al 
                            regresar a casa, tenía la sospecha de que le faltaban ovejas. Pero no podía 
                            estar seguro, pues eran tantas que la ausencia de dos o tres casi no se notaba 
                            a simple vista. <br/><br/>
                            
                            Una mañana, para estar tranquilo, tomó una vasija de barro y un puñado de 
                            semillas. Se paró en la puerta del corral y cada que una oveja salía colocaba 
                            una semilla dentro de la vasija. Así siguió hasta que juntó 120. Dejó la vasija 
                            en casa y partió con sus ovejas. <br/><br/>
                            
                            En la noche, de regreso a casa, hizo el movimiento contrario: tomó la vasija 
                            que había dejado en casa, y cada que una oveja ingresaba al corral, sacaba 
                            una semilla de esa vasija. Cuando las ovejas terminaron de entrar, el pastor se 
                            dio cuenta de que le quedaban semillas dentro de la vasija. Así descubrió que 
                            en el camino había extraviado cinco ovejas. Entonces, antes de dormir se dijo 
                            que tendría más cuidado.</em></p><br/>
                            
                            <img  className='Imagen ' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion41/1.png"  alt="Tarjeta" />

                            <p className='Lectura Lectura3'><em>
                            Al día siguiente, intentó lo mismo. Pero alguien en la casa, sin avisarle al
                            pastor, se comió unas cuantas semillas de la vasija que había dejado. Esto 
                            provocó que las semillas se acabaran antes que las ovejas. Cuando se dio 
                            cuenta de lo que había pasado, el pastor, sin meditarlo, impulsado por el 
                            deseo de no perder más ovejas, tomó una piedra del suelo y con ella trazó una 
                            raya en la vasija por cada oveja que salía. Las marcas en el barro no se 
                            borraron y el pastor quedó muy contento con su descubrimiento, pues así ya 
                            no necesitaría semillas para llevar la cuenta y eso le ayudaría a saber si 
                            extraviaba ovejas en el camino.</em></p><br/>
                    </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                        A la mañana siguiente, la sabia mujer del Sur despertó. Sin decirle nada a nadie, tomó 
                        una vasija de barro y con una piedra hizo en su superficie unos trazos que 
                        <em> simbolizaban</em> al pastor de su sueño parado en la puerta del corral. No era 
                        propiamente un dibujo, sino signos acomodados uno tras otro que <em>hablaban</em> de su 
                        sueño. La mujer se dio cuenta de que así nunca lo olvidaría. Y si algún día le sucedía, 
                        bastaba con observar los trazos que había inventado para recordarlo.<br/><br/>
                        
                        Por la tarde, acudió emocionada al concejo de sabios con la vasija en las manos, y 
                        cuando llegó su turno, mientras mostraba sus trazos les dijo: “Encontré una casa para 
                        los recuerdos. En el barro podemos guardar nuestras historias y nuestros 
                        conocimientos. Así, jamás volveremos a olvidar nada, porque todo estará grabado en 
                        la memoria de las piedras. Al fin, las palabras tienen forma y cuerpo”.<br/><br/>
                        
                        Poco después, en las escuelas, los jóvenes ya no se preparaban para memorizarlo 
                        todo. Ahora aprendían a hacer trazos sobre tablillas de barro. Cuando estaban listos, 
                        viajaban por el mundo para escuchar las historias de los pueblos y grabarlas para 
                        siempre en las piedras. <br/><br/>
                        
                        Por eso los libros son la memoria del universo. En los libros descansan las palabras, 
                        vive la sabiduría y muere el olvido.
                        </p>
                    </Grid>
                    <Grid container  sm={12}>
                            <Grid container>
                                <img className="Imagen2" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion41/3.png"  alt="Tarjeta" />
                            </Grid>
                    </Grid>
                </Grid>
        </Grid>
    </Grid>
    );
}
 
export default El_hogar_de_las_palabras;
