import * as React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";

import { GridCloseIcon } from "@mui/x-data-grid";
import { makeStyles } from "@material-ui/core";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialog-paper": {
    borderRadius: "12.1px",
    [theme.breakpoints.up("sm")]: {
      // margin: "20px 0",
      minWidth: "320px",
    },
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(2),
    marginBottom: "32px",
    justifyContent: "space-evenly",
  },
}));

const useStyles = makeStyles((theme) => ({
  containerContent: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.up("sm")]: {
      // margin: "20px 0",
    },
  },
  button: {
    backgroundColor: "#B965D3",
    borderRadius: "4px",
    boxSizing: "borderBox",
    outline: "0",
    border: "0",
  },
  text: {
    // width: "364px",
    // height: "36px",
    // margin: "11px 63.7px 18px 35.3px",
    fontFamily: "Fredoka One",
    margin: 0,
    // fontFamily: "Fredoka One",
    maxWidth: "40ch",
    fontSize: "16px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "0.23px",
    textAlign: "center",
    color: "#000",
    [theme.breakpoints.up("lg")]: {
      fontSize: "24px",
      margin: "20px",
      // maxWidth: "320px",
      minWidth: "320px",
    },
  },
}));

const PurpleButton = styled(Button)(({ theme }) => ({
  color: "#FFFFFF",
  backgroundColor: "#9051b1",
  border: "1px solid #9051b1",
  textTransform: "unset",
  borderRadius: "30px",
  // margin: "0 auto",
  width: "50%",
  maxWidth: "130px",
  "&:hover": {
    backgroundColor: "#B965D3 ",
  },
}));
const WitheButton = styled(Button)(({ theme }) => ({
  color: "#9051b1",
  // borderColor: "#9051b1",
  border: "1px solid #9051b1",
  backgroundColor: "#FFFFFF",
  textTransform:'capitalize',
  borderRadius: "30px",
  // margin: "0 auto",
  width: "50%",
  maxWidth: "130px",
  "&:hover": {
    backgroundColor: "#B965D310 ",
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 4 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <GridCloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const ModalMessage = ({ open, handleClose, title, valid, navigate }) => {
  const classes = useStyles();
  return (
    <div>
      <BootstrapDialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} maxWidth="md">
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}></BootstrapDialogTitle>
        <DialogContent>
          <div className={classes.containerContent}>
            <h3 className={classes.text}>{title}</h3>
          </div>
        </DialogContent>
        <DialogActions>
          {!valid && (
            <WitheButton onClick={handleClose} className={classes.button} variant="contained">
              Aceptar
            </WitheButton>
          )}
          {valid && (
            <PurpleButton onClick={navigate} className={classes.button} variant="contained">
              Ir
            </PurpleButton>
          )}
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
};
export default ModalMessage;
