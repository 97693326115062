import { Grid,makeStyles}              from '@material-ui/core';
import React, {useState}               from 'react';

const useStyles =  makeStyles (theme =>({
     Contenedor:{
        background:'#E9FBF9',
        width:'100vw',
        overflowX:'clip',
        [theme.breakpoints.down(1160)]:{
            background:'none',
        },
        [theme.breakpoints.down(600)]:{
            marginTop: '79.51px',
        }
    },
  ContainerInfoLectura: {
    position: "relative",
    height: "86px",
    [theme.breakpoints.down(502)]:{
        height: "43px",
    },
    background: "#76D4C3",
    "& .BarraInfoLectura": {
      height: "86px",
      background: `Url(https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Barras/Azul.png)`,
        [theme.breakpoints.down(502)]:{
            height: "43px",
        },
    },
    "& .InfoLectura": {
      position: "absolute",
      height: "86px",
      width: "219px",
      boxSizing: "border-box",
      padding: "17.2px 0",
      background: "#FAFAFA",
      borderRadius: "50px",
      boxShadow: "2px 2px 2px 2px #00000029",
      zIndex: "4",
        [theme.breakpoints.down(502)]:{
            height: "43px",
            width: "109px",
            padding: "8.6px 0",
            borderRadius: "25px",
        },
    },
    "& .InfoLectura:before": {
      position: "absolute",
      display: "flex",
      boxSizing: "border-box",
      padding: "0 0 0 10px",
      justifyContent: "center",
      alignItems: "center",
      height: "39.88px",
      width: "39.88px",
      borderRadius: "50%",
      content: '""',
      boxShadow: "3px 0px .5px 0px #00000029",
      background: "#FAFAFA",
      left: "90px",
      top: "65px",
      transform: "rotate(0.25turn)",
      [theme.breakpoints.down(502)]:{
        left: "45px",
        top: "30px",
        height: "20.88px",
        width: "20.88px",
        },
    },
    "& .InfoLectura:after": {
      content: '""',
      height: "15px",
      width: "15px",
      borderTop: "2px solid #20A698",
      borderLeft: "2px solid #20A698",
      position: "absolute",
      bottom: "-10px",
      left: "Calc(50% - 8px)",
      transform: "rotate(0.625turn)",
      [theme.breakpoints.down(502)]:{
        height: "10px",
        width: "10px",
        left: "Calc(50% - 4.5px)",
        bottom: "-3px",
        borderTop: "1px solid #20A698",
        borderLeft: "1px solid #20A698",
        },
    },
    "& .Sesion": {
      borderLeft: "2px solid #D8D8D8",
    },
    "& .Dato": {
      margin: "0 0 5px 0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#20A698",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        margin: "0 0 2.5px 0",
        },
    },
    "& .Caracter": {
      margin: "0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#000000",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        },
    },   },
    ContenedorLectura:{
        background:'#fff',
        width:'1160px',
        height:'100%',
        padding:'0',
        [theme.breakpoints.down(1160)]:{
            width:'768px',
          },
          [theme.breakpoints.down(769)]:{
            width:'350px',
          },
        '& .Escritorio':{
            display:'block',
            [theme.breakpoints.down(1160)]:{
                display:'none'
            },
        },
        '& .Tablet':{
            display:'none',
            [theme.breakpoints.down(1160)]:{
                display:'block'
            },
        }
    },

    Parrafo1:{
        position:'relative',
        [theme.breakpoints.down(1160)]:{
            display:'none'
        },
        '& .Pos':{
            position:'absolute',
            margin:'0',
        },
        '& .Titulo':{
            color:'#D8A811',
            font:'48px Fredoka One',
            lineHeigth:'58px',
            top:'2.91%',
            left:'14.48%',
            [theme.breakpoints.down(1160)]:{
                font:'43px Fredoka One',
                lineHeigth:'52px',
            },
            [theme.breakpoints.down(769)]:{
                font:'18px Fredoka One',
                lineHeigth:'29px',
            },
        },
        
        '& .SubTitulo':{
            color:'#000000',
            textAlign:'center',
            font:'61px Fredoka One',
            lineHeigth:'73px',
            top:'218.35%',
            [theme.breakpoints.down(1160)]:{
                font:'32px Fredoka One',
                lineHeigth:'39px',
            },
            [theme.breakpoints.down(769)]:{
                font:'21px Fredoka One',
                lineHeigth:'28px',
            },
        },
        
        '& .Lectura':{
            margin:'0',
            color:'#000000',
            font:'22px lato',
            lineHeigth:'30px',
            letterSpacing:'.17px',
            zIndex:2,
            [theme.breakpoints.down(1160)]:{
                 font:'16px lato',
                lineHeigth:'24px',
                letterSpacing:'.12px',
            },
            [theme.breakpoints.down(769)]:{
                font:'8px lato',
                lineHeigth:'10px',
                letterSpacing:'.09px',
            },
        },
        '& .Blanco':{
            color:'#FFFFFF',
        },
        '& .Peludos':{
            left:'30%',
            width:'50%',
            left:'35.5%',
        },

        '& .Lec1':{
            width:'40%',
            top:'10.77%',
            left:'14.48%',
            [theme.breakpoints.down(1160)]:{
                width:'35.1%',
            }
        },

        '& .Lec2':{
            top:'41.8%',
            display:(props)=> props.Boton1 ? 'none':'block',
        },
        '& .Lec3':{
            top:'56.8%',
            display:(props)=> props.Boton2 ? 'none':'block',
        },
        '& .Lec4':{
            top:'69.8%',
            display:(props)=> props.Boton3 ? 'none':'block',
        },
        '& .Lec5':{
            top:'84.8%',
            display:(props)=> props.Boton4 ? 'none':'block',
        },
        
        '& .Boton':{
            position:'absolute',
            height:'8.78%',
            width:'14.65%',
            cursor:'pointer',
        },
        '& .Prender':{
            top:'35.8%',
            left:'2%',
            display:(props)=> props.Boton1 ? 'flex':'none',
        },

        '& .Llama1':{
            position:'absolute',
            cursor:'pointer',
            top:'40.8%',
            left:'5%',
            display:(props)=> props.Boton1 ? 'none':'flex',
            zIndex:1,
        },

        '& .Prender2':{
            top:'60%',
            left:'15%',
            display:(props)=> props.Boton2 ? 'flex':'none',
        },

        '& .Llama2':{
            position:'absolute',
            cursor:'pointer',
            top:'55.8%',
            left:'2%',
            display:(props)=> props.Boton2 ? 'none':'flex',
            zIndex:1,
        },

        '& .Prender3':{
            top:'72%',
            left:'15%',
            display:(props)=> props.Boton3 ? 'flex':'none',
        },

        '& .Llama3':{
            position:'absolute',
            cursor:'pointer',
            top:'70%',
            left:'2%',
            display:(props)=> props.Boton3 ? 'none':'flex',
            zIndex:1,
        },

        '& .Prender4':{
            top:'85%',
            left:'46%',
            display:(props)=> props.Boton4 ? 'flex':'none',
        },

        '& .Llama4':{
            position:'absolute',
            cursor:'pointer',
            top:'84.5%',
            left:'2%',
            display:(props)=> props.Boton4 ? 'none':'flex',
            zIndex:1,
        },
        
        '& .Derechos':{
            position:'absolute',
            bottom:'16px',
            textAlign:"center",
            margin:"0px",
            font:" 15px lato",
            color:"#717476",
            [theme.breakpoints.down(1160)]:{
                display:'none'
            },
        },
        '& .Imagen':{
            width:'100%'
        },
        
    },
    
    Imagen:{
        width:'100%'
    },

}));

const Los_Peludos_Camellos = () => {
    const [Boton1,Btn1] = useState(true);
    const [Boton2,Btn2] = useState(true);
    const [Boton3,Btn3] = useState(true);
    const [Boton4,Btn4] = useState(true);

    const classes = useStyles ({
        Boton1,Boton2,Boton3,Boton4
    })

    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
             <Grid container item justifyContent="center" className={classes.ContainerInfoLectura}>
               <Grid container className="BarraInfoLectura"></Grid>
               <Grid container item className="InfoLectura">
                    <Grid container item direction="column" xs={6} justifyContent="center">
                        <p className="Dato">Grado</p>
                        <p className="Caracter">4</p>
                    </Grid>
                    <Grid container item direction="column" xs={6} justifyContent="center" className="Sesion">
                        <p className="Dato">Sesión</p>
                        <p className="Caracter">52</p>
                    </Grid>
               </Grid>
            </Grid>

            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                <h2 className='Pos Titulo'>Los peludos compañeros<br/> de los Andes</h2>
                


                <p className='Pos Lectura Lec1 '>
                    Él es Camello Elegante. Aburrido de recorrer 
                    Egipto una y otra vez, decidió volar estas 
                    vacaciones a Sudamérica y conocer a sus 
                    primos lejanos. El primer problema que tuvo al 
                    llegar fue que sus parientes se la pasaban 
                    saltando por todas partes y era difícil seguirles 
                    la pista. Así que les pidió un mapa para saber en 
                    dónde podría encontrarlos en caso de perder su 
                    rastro. Esto es lo que le dibujaron:
                </p>

                <p className='Pos Lectura Lec2 Peludos Blanco'>
                    <h2>Llama (<em>lama glama</em>):</h2>

                    Soy un animal fuerte y vivo en lo alto de la cordillera de 
                    los Andes. Los indígenas me domesticaron aquí hace cientos de 
                    años para cargar objetos pesados, utilizar mis pieles para cobijarse
                    del frío y comer mi carne, entre otras funciones. En el mapa, 
                    pinté de color anaranjado los lugares en los que vivo.
                </p>

                <p className='Pos Lectura Lec3 Peludos Blanco'>
                    <h2>Alpaca (<em>vicugna pacos/lama pacos</em>):</h2>

                    Soy parecida a mi hermana, la llama. Pero ¡cuidado! No somos 
                    idénticas. Yo soy más chica, no puedo cargar cosas sobre mi espalda 
                    y tengo la cabellera alborotada, por lo cual mi lana es útil para hacer 
                    ropa de invierno. Pinté con azul los lugares que habito.
                </p>

                <p className='Pos Lectura Lec4 Peludos Blanco'>
                    <h2>Vicuña (<em>vicugna vicugna</em>):</h2>
                    Yo no he sido domesticada, a diferencia de mis hermanas. 
                    Soy pequeña, mido menos de un metro. Mi pelo es corto y es 
                    color café, excepto por el que tengo en el cuello y la panza, 
                    que es blanco. Algo curioso de mi aspecto es que dos de mis 
                    dientes salen de mi hocico, como si fuera un hámster. Pinté de 
                    verde en el mapa los sitios en los que puedes encontrarme.
                </p>

                <p className='Pos Lectura Peludos Lec5 '>
                    <h2>Guanaco (<em>lama guanicoe</em>):</h2>
                    Yo soy esbelto y tengo el pelo un poco más corto que la llama. 
                    Hoy existen muchos guanacos como yo, aunque estuvimos cerca de 
                    extinguirnos. Somos importantes para las culturas de los pueblos 
                    indígenas de Sudamérica, como los tehuelches. Coloreé de amarillo 
                    las partes en las que vivo.
                </p>

                <div className="Pos Boton Prender" onClick={()=> {Btn1(false); } }></div> 

                <div className="Pos Boton Prender2" onClick={()=> {Btn2(false); } }></div> 

                <div className="Pos Boton Prender3" onClick={()=> {Btn3(false); } }></div> 

                <div className="Pos Boton Prender4" onClick={()=> {Btn4(false); } }></div> 

                <img className='Pos Escritorio Llama1' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img4to/Sesion52/2.png" alt="Tarjeta" onClick={()=> {Btn1(true); } }/>
                <img className='Pos Tablet Llama1-1' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img4to/Sesion52/2R.png" alt="Tarjeta" onClick={()=> {Btn1(true); } }/>
                <img className='Pos Escritorio Llama2' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img4to/Sesion52/3.png" alt="Tarjeta"  onClick={()=> {Btn2(true); } }/>
                <img className='Pos Tablet Llama2-1' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img4to/Sesion52/3R.png" alt="Tarjeta"  onClick={()=> {Btn2(true); } }/>
                <img className='Pos Escritorio Llama3' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img4to/Sesion52/4.png" alt="Tarjeta"  onClick={()=> {Btn3(true); } }/>
                <img className='Pos Tablet Llama3-1' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img4to/Sesion52/4R.png" alt="Tarjeta"  onClick={()=> {Btn3(true); } }/>
                <img className='Pos Escritorio Llama4' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img4to/Sesion52/5.png" alt="Tarjeta"  onClick={()=> {Btn4(true); } }/>
                <img className='Pos Tablet Llama4-1' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img4to/Sesion52/5R.png" alt="Tarjeta"  onClick={()=> {Btn4(true); } }/>

                <img className='Imagen Escritorio' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img4to/Sesion52/1.png" alt="Tarjeta" />
                <img className='Imagen Tablet' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img4to/Sesion52/1R.png" alt="Tarjeta" />
                <p className="Derechos">
                    © 2022. Todos los derechos reservados, Pearson, Circuito de lectores.
                </p> 
                </Grid>
                <Grid justifyContent="center">
                    <img className={classes.Imagen} src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img4to/Sesion52/F.png" alt="Tarjeta" />
                </Grid>
               
            </Grid>
    </Grid>
    );
}
 
export default Los_Peludos_Camellos ;
