import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
    ContenedorLectura:{
        width:'502px',
                  [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
    },
    ContainerTitulo:{
        margin:'0 0 34px 0',
        '& .Titulo':{
            letterSpacing: '0px',
            color: '#FF0000',
            margin:'0',
            textAlign:'center',
            font:'43px fredoka one',
            lineHeight:'52px',
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            margin:'0 0 36px 0',
            color:'#000',
            font:'18px lato',
            lineHeight :'28px',
            letterSpacing: '0.17px',
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .Cuadro':{
            position:'relative'
        },
        '& .Lectura2':{
            position:'absolute',
            top:'54px',
            margin:'0',
            width:'85.25%',
            [theme.breakpoints.down(502)]:{
                width:'90%',
                top:'20px',
            },

        },
        '& .SubTitulo':{
            color:'#49A331',
            margin:'0',
            lineHeight :'28px',
            font:'bold 26px lato',
            letterSpacing: '0.24px',
            [theme.breakpoints.down(502)]:{
                font:'bold 18px lato',
                lineHeight :'24px',
                letterSpacing: '0.21px',
            },
        },
        '& .TextImg':{
            color:'#717476',
            margin:'24px 0 0 0',
            font:' 16px lato',
            lineHeight :'25px',
            letterSpacing: '0.15px',
            [theme.breakpoints.down(502)]:{
                font:' 11px lato',
                lineHeight :'14px',
                letterSpacing: '0.10px',
            },
        },

        '& .Imagen':{
            width:'100%',
        },  
      
    }
}));

const Las_bacterias_aliadas = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>

            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                        <h2 className="Titulo">Las bacterias aliadas</h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                       <p className='Lectura'>
                        Billones de bacterias viven en cada uno de nosotros. Para que tengas una mejor idea de 
                        esto te presentamos un ejemplo: en un adulto de 70 kg representan entre 2.5 y 3 
                        kg de su masa total. Pero esto no es nada que deba preocuparnos. Es posible que 
                        al pensar en las bacterias sientas desagrado o preocupación porque las relacionas 
                        con las enfermedades. Si es así, ten en cuenta que no todas las bacterias son <em>patógenas</em>.
                       </p>                   
                    </Grid>
                    <Grid container justifyContent='center' className='Cuadro'>
                        <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion50/3R.png" alt="Tarjeta" />
                        <p className='Lectura Lectura2'>
                            En griego antiguo la palabra <em>páthos</em> a veces significaba “sentimiento” o 
                            “sufrimiento” (en la actualidad aún hay gente que la usa con ese sentido). 
                            Otras veces quería decir “síntoma” o “enfermedad”. Por su parte, la 
                            terminación <em>-genos</em> significa “lo que produce algo”. Por eso en medicina 
                            <em> patógeno</em> es aquello que provoca una enfermedad.
                        </p>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container >
                        <p className='Lectura'>
                            Hay muchas bacterias que no causan enfermedades. Por el contrario, son benéficas 
                            para nuestro cuerpo, pues cumplen con funciones que nuestras células no pueden 
                            llevar a cabo por sí solas.<br/><br/>
                            
                            Las bacterias son diminutas. Llegan a ser 10 veces más pequeñas que las células del
                            cuerpo humano. Por eso, aunque apenas representan un par de kilogramos de
                            nuestra masa, se calcula que son mucho más numerosas que nuestras propias células. 
                            Este gran ejército colabora con las defensas del cuerpo para evitar la entrada de 
                            organismos patógenos.<br/><br/>
                             
                            Asimismo, ayudan a producir y a absorber ciertas vitaminas. Algunos científicos 
                            afirman que sin las bacterias no podríamos absorber la cantidad suficiente de 
                            nutrientes para seguir vivos. <br/><br/>
                            
                            Por mucho tiempo los microorganismos que viven en nuestro intestino fueron 
                            llamados <em>flora intestina</em>l, ya que se consideraban plantas. Pero el término más exacto 
                            es <em>microbiota intestinal</em>.
                        </p>
                    </Grid>
                    <Grid container justifyContent='center'>
                        <Grid container justifyContent='center'>
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion50/1.png" alt="Tarjeta" />
                        </Grid>
                        <Grid container >
                            <p className="TextImg">
                                Los <em> lactobacilos</em> forman parte de una microbiota intestinal sana. ¡Sigue leyendo para conocer más al respecto!
                            </p>
                        </Grid>
                    </Grid>

                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container >
                        <p className='Lectura'>
                            Cada persona tiene un número distinto de bacterias de diversos tipos en su cuerpo.
                            La microbiota es como nuestra huella digital: algo único y personal en donde 
                            podemos ver una forma de identidad. Nuestra microbiota se desarrolla a lo largo de 
                            la vida y depende de los hábitos alimenticios.<br/><br/> 
                            
                            <h3 className='SubTitulo'>Cuidando a nuestras bacterias</h3><br/>
                        
                            ¿Qué funciones realizan en nuestro cuerpo estas bacterias benéficas? Si alguna vez 
                            recibiste medicinas contra una infección intestinal e inmediatamente después 
                            bebiste leche, es posible que te haya dolido el estómago. Esto se debe a que los 
                            antibióticos con los que se tratan las infecciones eliminan tanto a las bacterias 
                            patógenas como a las que nos ayudan a digerir los alimentos.<br/><br/> 
                            
                            Si queremos tener un aparato digestivo saludable debemos proporcionarles a 
                            nuestras bacterias benéficas todo lo que necesitan para desarrollarse. Pero ¿cómo 
                            hacemos esto? En los supermercados y en las tiendas se venden muchos productos 
                            anunciados como <em>probióticos</em> y <em>prebióticos</em>. 
                            <br/><br/> 

                            Los <em>probióticos</em> son alimentos que contienen bacterias de diferentes tipos, como los 
                            <em> lactobacilos</em>. Estas bacterias son importantes para la digestión de la lactosa y el
                            azúcar de la leche. Sin ellas, al consumir lácteos experimentaríamos diversos
                            malestares, desde un leve dolor abdominal hasta diarrea o infecciones. Al consumir 
                            probióticos nos aseguramos de tener bacterias suficientes para ayudarnos en la 
                            digestión. Por otra parte, los <em>prebióticos</em> sirven como alimento para las bacterias 
                            benéficas que ya se encuentran en nuestro organismo. En ocasiones un mismo 
                            alimento contiene tanto a las bacterias como a las sustancias que les permiten un
                            buen crecimiento.
                        </p>
                    </Grid>
                    <Grid container justifyContent='center'>
                        <Grid container justifyContent='center'>
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion50/2.png" alt="Tarjeta" />
                        </Grid>
                        <Grid container >
                            <p className="TextImg">
                                También podemos cuidar nuestra microbiota intestinal incluyendo ajo y remolacha en nuestra dieta, 
                                porque estos alimentos cubren las necesidades de las bacterias.
                            </p>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container >
                        <p className='Lectura'>
                            <h3 className='SubTitulo'>Siempre bajo supervisión</h3> <br/>
                            Recientemente se ha descubierto que ciertos padecimientos (como la obesidad) se 
                            relacionan con una mala salud de la microbiota intestinal. ¿Cómo podemos evitarlos? 
                            Ten en cuenta que no debemos consumir antibióticos con frecuencia, porque 
                            eliminan grandes cantidades de bacterias. Además es importante que consultemos a 
                            un médico antes de ingerir cualquier medicamento. Usar fármacos sin receta y sin 
                            supervisión se llama automedicarse, y puede tener consecuencias graves para la 
                            salud.
                        </p>
                    </Grid>
                </Grid>
        </Grid>
    </Grid>
    );
}
 
export default Las_bacterias_aliadas;
