import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({

   ContenedorLectura:{
       width:'590px',
        [theme.breakpoints.down(590)]:{
            width:'350px',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
        },
   },
    ContainerTitulo:{
        margin:'0 0 46px 0',
        '& .Titulo':{
            color: '#0650DD',
            margin:'0',
            textAlign:'center',
            font:'43px fredoka one',
            lineHeight:'52px',
            [theme.breakpoints.down(590)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        position:'relative',
        '& .Lectura':{
            margin:'0 0 36px 0 ',
            color:'#000',
            font:'18px lato',
            lineHeight :'28px',
            letterSpacing: '0.17px',
            width:'498px',
            [theme.breakpoints.down(590)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
                width:'298px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'13.8px',
                letterSpacing: '0.09px',
                width:'273px',
            },
        },
        '& .SubTitulo':{
            color:'#FF0000',
            textAlign:'center',
            margin:'0',
            font:'31px Fredoka One',
            lineHeight :'31px',
            letterSpacing: '0.29px',
            [theme.breakpoints.down(590)]:{
                font:'18px Fredoka One',
                lineHeight :'20px',
                letterSpacing: '0.16px',
            },
            [theme.breakpoints.down(350)]:{
                font:'14px Fredoka One',
                lineHeight :'16px',
                letterSpacing: '0.16px',
            },
        },
        '& .Esp':{
            boxSizing:'border-box',
            padding:'0 7% 0 22%',
            [theme.breakpoints.down(350)]:{
                padding:'0 0% 0 21%',
            },
        },
        '& .Esp2':{
            textAlign:'left',
        },
       '& .TextImg':{
            margin:'0 0 36px 0',
            alignSelf:'flex-start',
            color:'#717476',
            textAlign:'left',
            font:' 16px lato',
            lineHeight :'25px',
            letterSpacing: '0.15px',
            [theme.breakpoints.down(590)]:{
                margin:'0 0 15px 0',
                font:' 12px lato',
                lineHeight :'14px',
                letterSpacing: '0.15px',
            },
            [theme.breakpoints.down(350)]:{
                margin:'0 0 15px 0',
                font:' 10px lato',
                lineHeight :'12px',
                letterSpacing: '0.15px',
            },
        },
        '& .Pos':{
            position:'absolute',
            top:'55px',
            [theme.breakpoints.down(590)]:{
                top:'2.6%',
            },
        },
        '& .Pos2':{
            position:'absolute',
            top:'2434px',
            [theme.breakpoints.down(590)]:{
                top:'92.5%',
            },
        },
        '& .Imagen':{
            [theme.breakpoints.down(590)]:{
                width:'385px',
            },
            [theme.breakpoints.down(350)]:{
                width:'330px',
            },
        },
        
    },
    

}));

const Los_animales_también_sufren = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >
                        Los animales<br/> también sufren
                    </h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img4to/Sesion36/1R.png"  alt="Tarjeta" />
                    <Grid container item xs={12} justifyContent="center"  className='Pos'>
                        <p className='Lectura'>
                            <h3 className='SubTitulo'>
                                Voluntarios rescatan<br /> a dos cachorros que<br/> encontraron en la basura
                            </h3> <br/>

                            <div className='Esp'>
                                os voluntarios que cuidan animales rescatados encontraron el 
                                pasado sábado a dos cachorros abandonados en un contenedor 
                                de basura. Fueron trasladados a 
                            </div>
                            <div>
                                instalaciones a cargo de su 
                                protección. Recibieron alimentos y limpieza, y permanecerán allí 
                                hasta que una familia quiera adoptarlos.
                            </div>

                            <br/>
                            Noticias como ésta son cada vez más frecuentes. Más de 50 % de los perros 
                            de todo el mundo son abandonados, según datos de la Organización Mundial 
                            de la Salud. El número de rescatados es mayor al número que logra conseguir 
                            un hogar, a pesar de que existen muchas organizaciones mundiales que 
                            tratan de salvarlos. Muchos de ellos reciben una mala calidad de vida 
                            mientras están con sus dueños.<br/><br/> 
                            
                            <h3 className='SubTitulo Esp2'>El maltrato</h3><br/>
                            
                            El desamparo de mascotas es sólo una de las muchas prácticas de maltrato. 
                            Los seres humanos los han usado para alcanzar diversos fines a lo largo de 
                            la historia. Han sido fuente de alimento, ropa, fuerza de trabajo, transporte e 
                            incluso entretenimiento.<br/><br/>
                            
                            Por muchos siglos se consideró que eran seres inferiores que estaban a 
                            nuestro servicio. Por lo tanto, no importaba el modo en que fueran tratados, 
                            siempre y cuando se alcanzaran los fines. El desarrollo social ha aumentado 
                            la necesidad de usarlos, y con ello crece también su sufrimiento.<br/><br/>
                            
                            <h3 className='SubTitulo Esp2'>El reconocimiento<br/> del sufrimiento </h3><br/>

                            La biología reconoció desde hace varias décadas que son capaces de sentir 
                            dolor. Esto parece claro en la actualidad, pero hubo épocas en que no fue así. 
                            Existen personas que aún niegan este hecho, a pesar de que la ciencia 
                            demuestre lo contrario. Sus sistemas nerviosos son semejantes a los 
                            humanos y sienten la misma angustia que nosotros ante determinadas 
                            circunstancias. Si una persona se golpea la pierna, le duele. Si un animal se 
                            golpea en la pata, también le duele. <br/><br/>
                            
                            Las investigaciones no sólo han demostrado que se acongojan, también ha 
                            comprobado que son conscientes de ello. Un amplio grupo de científicos de
                            diferentes universidades estableció la llamada <em>Declaración de Cambridge </em> 
                            sobre la conciencia, en la que manifiestan que un gran número de especies 
                            tienen conciencia similar a la humana. Por lo tanto, pueden sentir afecto y 
                            reconocen el sufrimiento cuando lo padecen. <br/><br/>
                            
                            <h3 className='SubTitulo Esp2'>Sus derechos</h3><br/>
                            
                            La Declaración de Cambridge sobre la conciencia mostró la importancia de 
                            debatir sobre los derechos de los animales y diseñar acuerdos 
                            internacionales que los defiendan.<br/><br/>
                             
                            En 1978 se proclamó la Declaración Universal de los Derechos de los 
                            Animales, en la que se les considera seres merecedores de respeto y con 
                            derecho a no ser heridos ni explotados. Pero dicho documento carece de 
                            vinculación legal desde su publicación, por lo que no puede lucharse por sus 
                            derechos de manera formal.
                        </p>
                    </Grid>
                    <Grid container item xs={12} justifyContent="center"  className='Pos2'>
                        <Grid>
                            <p className='Lectura'>
                                <p className='TextImg'>Es decisión nuestra tomar acciones para cuidar y proteger a las especies.</p>
                            
                                Este debate sigue abierto y las nuevas generaciones tendrán un papel fundamental en la creación de una verdadera lucha que prohíba definitivamente el maltrato.
                            </p>
                        </Grid>
                    </Grid>
                    
                </Grid>
        </Grid>
    </Grid>
    );
}
 
export default Los_animales_también_sufren;
