import types from "./types";

export const chatInitialState = {
  chats: [],
  socket: null,
};
export const ChatReducer = (state, action) => {
  switch (action.type) {
    case types.connectToSocket: {
      return {
        ...state,
        socket: action.payload,
      };
    }
    case types.disconnectFromSocket: {
      return {
        socket: null,
        chats: [],
      };
    }
    case types.actualizeChatList: {
      return {
        ...state,
        chats: action.payload,
      };
    }
    case types.loadMessages: {
      return {
        ...state,
        chats: state.chats.map((chat) => {
          return chat.sala === action.payload.sala
            ? {
                ...chat,
                mensajes: action.payload.historial,
                paginacion: action.payload?.paginacion,
              }
            : chat;
        }),
      };
    }
    case types.loadMoreMessages: {
      return {
        ...state,
        chats: state.chats.map((chat) => {
          return chat.sala === action.payload.sala
            ? {
                ...chat,
                mensajes: [...chat.mensajes, ...action.payload.historial],
                paginacion: action.payload?.paginacion,
              }
            : chat;
        }),
      };
    }

    case types.sendToSpecificChat: {
      return {
        ...state,
        chats: state.chats
          .map((chat) => {
            if (chat.sala === action.payload?.id_chat) {
              const messageExist = chat.mensajes.find(
                (mensaje) =>
                  mensaje.id_mensaje === action.payload.mensaje.id_mensaje
              );
              if (messageExist) {
                return chat;
              } else
                return {
                  ...chat,
                  mensajes: [action.payload.mensaje, ...chat.mensajes],
                  ultimo_mensaje: action.payload.mensaje.fecha,
                };
            } else {
              return chat;
            }
          })
          .sort((a, b) => b?.ultimo_mensaje - a?.ultimo_mensaje),
      };
    }
    case types.actualizeOnlineList: {
      return {
        ...state,
        chats: state.chats.map((chat) => {
          if (chat.id_usuario === action.payload?.id_usuario) {
            return {
              ...chat,
              conectado: action.payload?.conectado,
            };
          } else {
            return chat;
          }
        }),
      };
    }
    case types.removeAlert: {
      return {
        ...state,
        chats: state.chats.map((chat) => {
          return chat.sala === action.payload
            ? {
                ...chat,
                notificaciones: 0,
              }
            : chat;
        }),
      };
    }
    case types.addAlert: {
      return {
        ...state,
        chats: state.chats.map((chat) => {
          return chat.sala === action.payload
            ? {
                ...chat,
                notificaciones: chat.notificaciones + 1,
              }
            : chat;
        }),
      };
    }
    case types.addMessageToFavorite: {
      return {
        ...state,
        chats: state.chats.map((chat) => {
          if (chat.sala === action.payload?.sala) {
            return {
              ...chat,
              mensajes: chat.mensajes.map((mensaje) => {
                if (mensaje.id_mensaje === action.payload?.id_mensaje) {
                  return {
                    ...mensaje,
                    favorito: action.payload.favorito,
                  };
                } else {
                  return mensaje;
                }
              }),
            };
          } else {
            return chat;
          }
        }),
      };
    }
    default:
      return state;
  }
};
