import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
    Contenedor:{
        background:'#E9FBF9',
        width:'100vw',
        [theme.breakpoints.down(1161)]:{
            background:'none',
        },
        [theme.breakpoints.down(600)]:{
            marginTop: '79.51px',
        }
    },
    ContainerInfoLectura:{
        position:'relative',
        height:'86px',
        background:'#F6664D',
        '& .BarraInfoLectura':{
            height:'86px',
            background:`Url(https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Barras/Naranja.png)`,
        },
        '& .InfoLectura':{
            position:'absolute',
            height:'86px',
            width:'219px',
            boxSizing:'border-box',
            padding:'17.2px 0',
            background:'#FAFAFA',
            borderRadius:'50px',
            boxShadow: '2px 2px 2px 2px #00000029',
            zIndex:'4',
        },
        '& .InfoLectura:before':{
            position:'absolute',
            display:'flex',
            boxSizing:'border-box',
            padding:'0 0 0 10px',
            justifyContent:'center',
            alignItems:'center',
            height:'39.88px',
            width:'39.88px',
            borderRadius:'50%',
            content:'""',
            boxShadow: '3px 0px .5px 0px #00000029',
            background:'#FAFAFA',
            left:'90px',
            top:'65px',
            transform: 'rotate(0.25turn)',
        },
        '& .InfoLectura:after':{
            content:'""',
            height:'15px',
            width:'15px',
            borderTop:'2px solid #F6664D',
            borderLeft:'2px solid #F6664D',
            position:'absolute',
            bottom:'-10px',
            left:'Calc(50% - 8px)',
            transform: 'rotate(0.625turn)',
        },
        '& .Sesion':{
            borderLeft:'2px solid #D8D8D8',
        },
        '& .Dato':{
            margin:'0 0 5px 0',
            textAlign:'center',
            font:'bold 17px lato',
            color:'#F6664D',
        },
        '& .Caracter':{
            margin:'0',
            textAlign:'center',
            font:'bold 17px lato',
            color:'#000000',
        },
    },
    ContenedorLectura:{
        background:'#fff',
        width:'1160px',
        height:'100%',
        padding:'60px 168px 35px ',
        [theme.breakpoints.down(1161)]:{
            width:'502px',
            padding:'60px 0 0 0',
          },
        [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
        '& .ContainerDerechos':{
            bottom:'80px',
            [theme.breakpoints.down(1161)]:{
                display:'none',
            },
        },
        '& .Derechos':{
            textAlign:"center",
            margin:"0px",
            font:" 15px lato",
            color:"#717476",
        },
        '& .Escritorio':{
            display:'block',
            [theme.breakpoints.down(1161)]:{
                display:'none'
            },
        },
        '& .Tablet':{
            display:'none',
            [theme.breakpoints.down(1161)]:{
                display:'block'
            },
        }
    },
    ContainerTitulo:{
        margin:'0 0 34px 0',
        [theme.breakpoints.down(1161)]:{
            margin:'0 0 34px 0',
        },
        '& .Titulo':{
            letterSpacing: '0px',
            color: '#FF0027',
            margin:'0',
            textAlign:'center',
            font:'48px fredoka one',
            lineHeight:'58px',
            [theme.breakpoints.down(1161)]:{
                font:'43px fredoka one',
                lineHeight:'52px',
            },
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            margin:'0 0 36px 0',
            color:'#000',
            font:'22px lato',
            lineHeight : '30px',
            letterSpacing: '0.20px',
            [theme.breakpoints.down(1161)]:{
                font:'18px lato',
                lineHeight :'28px',
                letterSpacing: '0.17px',
            },
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .TextImg':{
            color:'#717476',
            margin:'20px 0 0 0',
            font:'20px lato',
            letterSpacing:'.19px',
            lineHeight:'30px',
            [theme.breakpoints.down(1161)]:{
                font:' 16px lato',
                lineHeight :'25px',
                letterSpacing: '0.15px',
            },
            [theme.breakpoints.down(502)]:{
                font:' 11px lato',
                lineHeight :'14px',
                letterSpacing: '0.10px',
            },
        },

        '& .Imagen':{
            [theme.breakpoints.down(1161)]:{
                width:'100%',
            },
        }, 

    }
}));

const El_talento_no_lo_es_todo = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
             <Grid container item justifyContent="center" className={classes.ContainerInfoLectura}>
               <Grid container className="BarraInfoLectura"></Grid>
               <Grid container item className="InfoLectura">
                    <Grid container item direction="column" xs={6} justifyContent="center">
                        <p className="Dato">Grado</p>
                        <p className="Caracter">4</p>
                    </Grid>
                    <Grid container item direction="column" xs={6} justifyContent="center" className="Sesion">
                        <p className="Dato">Sesión</p>
                        <p className="Caracter">56R</p>
                    </Grid>
               </Grid>
            </Grid>

            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >
                        El talento no lo es todo                  
                    </h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                      <p className="Lectura">
                        En sus orígenes, la palabra <em>talento</em> se refería a las monedas de oro que eran utilizadas
                        como pago durante la época clásica y la Edad Media. El término fue evolucionando 
                        con el tiempo y su significado cambió, como es común que ocurra con muchos 
                        conceptos. Hoy en día lo usamos para referirnos a la capacidad que tiene alguien 
                        para hacer un producto o servicio de manera fácil, rápida y con excelentes 
                        resultados.<br/><br/> 
                        
                        El talento se necesita para la vida diaria, a veces incluso para las cosas más simples. 
                        Todos tenemos talento en algo, sólo que a veces no lo hemos descubierto. Las 
                        personas emplean los suyos en diversos trabajos y actividades: cada producto que 
                        posees es la suma de visiones y horas de trabajo de muchas personas. Pero si hay una 
                        profesión que exige su presencia más que otras, es el arte. Se trata de un campo que 
                        requiere todo el tiempo de ingenio, por eso exige que sus creadores sean versátiles y 
                        estén en constante cambio. <br/><br/>
                        
                        El talento pasó de ser una moneda de cambio a ser una característica exclusiva e 
                        individual debido a esta necesidad constante. Diferentes individuos tuvieron que 
                        encontrar la manera de convertir sus destrezas en dinero para poder vivir gracias a 
                        ellas. <br/><br/>
                        
                        Mucha gente ha demostrado tenerlo en distintas ramas, desde los investigadores de 
                        ciencia hasta los cocineros. Pero es algo que no se aprende, sino que se da 
                        naturalmente. Alguien puede descubrir que posee uno o varios a lo largo de su vida, o 
                        incluso hay quienes piensan que no tienen ni uno, pero es únicamente porque no lo 
                        han encontrado.
                      </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <Grid container justifyContent="center" >
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img4to/Sesion56R/1.png" alt="Tarjeta" />
                        </Grid>
                        <Grid container justifyContent="center" >
                            <p className="TextImg">
                                El talento es un don nato.                            
                            </p>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                            De hecho, es común que probemos suerte en una actividad y después veamos que no 
                            somos buenos en ella, por más que nos guste. Los artistas experimentan con varias 
                            áreas y técnicas hasta encontrar una en la que puedan desenvolverse de manera 
                            exitosa. Necesitan descubrir qué pueden hacer mejor, por ejemplo, hacer una pintura 
                            mural, diseñar una prenda o caracterizar a un personaje.<br/><br/>
                            
                            Esto sucede porque no basta con que algo nos sea llamativo, sino que hay que tener 
                            aptitudes para ello. Todos los artistas necesitan aptitudes, sin importar la disciplina 
                            que realicen. ¿Pero esto es suficiente para ser exitoso este mundo? La respuesta es 
                            un rotundo no.<br/><br/>
                            
                            Algunos creadores descubren su pasión a una edad temprana, pero tener pasión no 
                            es una garantía para triunfar. Las habilidades necesitan ser explotadas y afinadas día 
                            con día para que se pueda construir un camino sólido con ellas. En una carrera de 
                            este tipo siempre serán necesarias dos cosas: la educación y la práctica. Si una de las 
                            dos falta, la otra sería inútil y nada podría construirse.
                        </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <Grid container justifyContent="center" >
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img4to/Sesion56R/2.png" alt="Tarjeta" />
                        </Grid>
                        <Grid container justifyContent="center" >
                            <p className="TextImg">
                                El verdadero artista combina su talento con educación y práctica.
                            </p>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                            La creación no puede basarse únicamente en el ingenio, sino que requiere de 
                            educación profesional que brinde técnicas, soluciones y otros conocimientos. Existen 
                            numerosas universidades y escuelas que se enfocan en estas áreas. Es importante 
                            acudir a ellas, pero también es fundamental comprometer tiempo para practicar y así 
                            mejorar la técnica.<br/><br/> 
                            
                            ¡Ahora has conocido un poco más sobre el talento? ¿Alguna vez imaginaste que era 
                            tan importante? ¿Tú has descubierto alguno?
                        </p>
                    </Grid>
                </Grid>

                <Grid container item xs={12} justifyContent="center" className="ContainerDerechos">
                    <p className="Derechos">
                        © 2022. Todos los derechos reservados, Pearson, Circuito de lectores.
                    </p>                
                </Grid>
        </Grid>
    </Grid>
    );
}
 
export default El_talento_no_lo_es_todo ;
