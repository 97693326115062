import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
    ContenedorLectura:{
        width:'502px',
                  [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
    },
    ContainerTitulo:{
        margin:'0 0 46px 0',
        '& .Titulo':{
            letterSpacing: '0px',
            color: '#817FD9',
            margin:'0',
            textAlign:'center',
            font:'43px fredoka one',
            lineHeight:'52px',
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            margin:'0 0 36px 0',
            color:'#000',
            font:'18px lato',
            lineHeight :'28px',
            letterSpacing: '0.17px',
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .SubTitulo':{
            color:'#FF0000',
            margin:'0',
            font:'bold 26px lato',
            lineHeight :'28px',
            letterSpacing: '0.24px',
            [theme.breakpoints.down(502)]:{
                padding:'19px 0 12px 0',
                font:'bold 22px lato',
                lineHeight :'24px',
                letterSpacing: '0.21px',
            },
        },
        "& .SubTitulo2": {
            color: "#0CB5D6",
            margin: "0",
            font: "bold 22px lato",
            lineHeight: "24px",
            letterSpacing: "0.21px",
            [theme.breakpoints.down(502)]: {
              font: "bold 18px lato",
              lineHeight: "20px",
              letterSpacing: "0.16px",
            },
        },
        '& .Esp':{
            margin:'0'
        },
        '& .ul':{
            padding:'0 0 0 19px',
            margin:0,
        },
        '& .li':{
            color:'#817FD9',
            margin:'0 0 4px 0 ',
        },

        '& .TextImg':{
            color:'#717476',
            margin:'24px 0 0 0',
            font:' 16px lato',
            lineHeight :'25px',
            letterSpacing: '0.15px',
            [theme.breakpoints.down(502)]:{
                font:' 11px lato',
                lineHeight :'14px',
                letterSpacing: '0.10px',
            },
        },
        '& .Imagen':{
            width:'100%',
        },  
        '& .Imagen2':{
            width:'94.22%',
        }, 
      
    },

}));

const La_dignidad_humana_a_través_del_autocuidado_y_los_límites = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>

            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo " >
                        La dignidad humana a<br/> través del autocuidado<br/> y los límites
                    </h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container xs={12} >
                      <p className="Lectura ">
                        <h3 className='SubTitulo'>¿Qué es la <em>dignidad humana</em>?</h3><br/>
                        
                        La <em>dignidad humana</em> está estrechamente ligada a los derechos humanos. En esta 
                        ocasión nos enfocaremos en los de los niños, como tú.<br/><br/>
                        
                        La dignidad humana se relaciona con la forma en que te ves y te tratas, y cómo
                        promueves o exiges que te traten. Cuando te respetas y te valoras, la estás poniendo
                        en práctica. Esto también ocurre cuando procuras que las demás personas te
                        respeten y valoren por lo que eres: un ser humano. Todos tenemos la responsabilidad
                        de tratar dignamente a los otros: con igualdad y haciendo valer sus derechos (que
                        son los mismos que tú tienes).<br/><br/> 
                        
                        <h3 className='SubTitulo2'>Autocuidado</h3><br/>
                        
                        El <em>autocuidado</em> consiste en cuidarte a ti mismo en todos los aspectos. Comienza 
                        con tu cuerpo y tu salud: come alimentos saludables, haz ejercicio y mantén una buena 
                        higiene personal. También implica que no te hagas daño intencionadamente ni te 
                        expongas a situaciones peligrosas.<br/><br/> 
                        
                        Existen muchas formas de autocuidado, como leer, dormir bien, pasar el menor 
                        tiempo posible frente a una pantalla, convivir con tus seres queridos, mantener
                        limpios y agradables tus espacios… ¿Qué otras medidas se te ocurren?
                      </p>
                    </Grid>
                    <Grid container xs={12} >
                        <Grid container justifyContent="center" >
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img2do/Sesion40/1.png" alt="Tarjeta" />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container xs={12} >
                      <p className="Lectura ">
                        
                        <h3 className='SubTitulo2'>Límites</h3><br/>
                        
                        La dignidad humana implica que los otros también te respeten y valoren. Esto incluye 
                        tu cuerpo. Por lo tanto, nadie puede tocarte sin tu permiso, del mismo modo en que 
                        tú no debes tocar a alguien sin su consentimiento. Eso es <em>poner y respetar límites</em>. Es 
                        una acción que puedes aplicar respecto a tu cuerpo, a tus sentimientos y a tu espacio 
                        personal.<br/><br/>
                        
                        Una manera de poner límites es decir “No”. Por ejemplo, cuando no te gusta la forma 
                        en que se dirigen a ti, no te hablan por tu nombre o no respetan tus emociones.

                      </p>
                    </Grid>
                    <Grid container xs={12} >
                        <Grid container justifyContent="center">
                            <img className="Imagen2" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img2do/Sesion40/2.png" alt="Tarjeta" />
                        </Grid>
                        <Grid container justifyContent="center" >
                            <p className='TextImg'>
                                Poner y respetar límites es fundamental para el bienestar y el desarrollo de los niños.
                            </p>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container xs={12} >
                      <p className="Lectura ">
                        <h3 className='SubTitulo'>
                            Algunas recomendaciones para el autocuidado de los niños 
                            durante la pandemia de Covid-19
                        </h3><br/>
                        
                         Durante la pandemia de Covid-19, la Organización Mundial de la Salud propuso:<br/><br/>

                         <ul className='ul'>
                            <li className='li'>
                                <p className='Lectura Esp'>Lavarse las manos con frecuencia.</p>    
                            </li>
                            <li className='li'>
                                <p className='Lectura Esp'>Usar correctamente el cubrebocas.</p>    
                            </li>
                            <li className='li'>
                                <p className='Lectura Esp'>Mantener una distancia de al menos metro y medio con otras personas.</p>    
                            </li>
                        </ul>
                      </p>
                    </Grid>
                    <Grid container xs={12} >
                        <Grid container justifyContent="center" >
                            <img className="Imagen2" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img2do/Sesion40/3.png" alt="Tarjeta" />
                        </Grid>
                        <Grid container justifyContent="center" >
                            <p className='TextImg'>
                            Seguir las recomendaciones durante una pandemia te ayuda a estar más seguro.                            
                            </p>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container xs={12} >
                      <p className="Lectura ">
                        Estas medidas son de autocuidado porque los niños deben seguirlas por sí mismos, y 
                        ayudan a preservar la dignidad en la niñez.
                      </p>
                    </Grid>
                </Grid>
        </Grid>
    </Grid>
    );
}
 
export default La_dignidad_humana_a_través_del_autocuidado_y_los_límites;
