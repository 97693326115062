import React from "react";
import { Grid, makeStyles, Dialog, Button, styled } from "@material-ui/core";
// import GenericDropdownMenu from "../../../ui/generic_dropdown_menu";
// import MenuItem from "@material-ui/core/MenuItem";
// import ListItemText from "@material-ui/core/ListItemText";
// import ListItemIcon from "@material-ui/core/ListItemIcon";
import competencias from "../img/Malla_de_competencias.png";
import manual from "../img/Manual_del_usuario_estudiante.png";
import sugerencias from "../img/Sugerencias_didacticas.png";
import VideoResource from "./Video_Resource/Video_Resource";

const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: "300px",
    // height:'60px',
    // borderRadius: "15px",
    // marginBottom:"20px",
    // border: "3px #1DA698 solid",
    background: "#F4F5D4 0% 0% no-repeat padding-box",
    boxShadow: ({ focus }) =>
      focus ? "0px 5px 20px 15px #F1CB5A" : "0px 5px 9px #00000029",
    overflow: "hidden",
    borderRadius: "38px",
    alignItems: "center",
    // padding: "5px",
    boxSizing: "border-box",
    // display: "flex",
    flexDirection: "column",
    borderBottomLeftRadius: "0",
    borderBottomRightRadius: "0",
    justifyContent: "space-between",
    display: "grid",
    gridTemplateRows: "auto 50px",
    gridTemplateColumns: "1fr",
  },

  containerImg: {
    display: "flex",
    width: "auto",
    padding: "25px",
    justifyContent: "center",
  },
  img: {
    width: "100%",
    // height: '150px',
  },
  // Icon:{
  //   cursor:'pointer',
  //   fontSize:'30px',
  //   color:'#F6644B',
  //   padding: '5px'
  // },
  TituloLecturas: {
    margin: 0,
    // margin: "5px",
    width: "100%",

    maxHeight: "60px",
    font: "normal 18px lato",
    // textAlign: "start",
    letterSpacing: "0.67px",
    textAlign: "center",
    // font: "normal normal bold 30px/40px Lato",
    // letterSpacing: "0px",
    color: " #FFFFFF",
    // color: "#F27964",
    [theme.breakpoints.up("lg")]: {
      font: "normal 20px lato",
    },

    overflow: "hidden",
  },
  TituloTipoDocumento: {
    margin: "0",
    font: "bold 20px lato",
    textAlign: "center",
    letterSpacing: " 0.67px",
    color: "black",
    overflow: "hidden",
  },
  containerBack: {
    backgroundColor: ({ backgroundColor }) => backgroundColor,
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "0 20px",
    boxSizing: "border-box",
    // "&:nth-of-type(1)": {
    //   backgroundColor: "#F78766",
    // },
    // "&:nth-of-type(2)": {
    //   backgroundColor: "#3CBE7D",
    // },
  },

  objectContainer: {
    margin: "0px",
    minHeight: "35vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    width: "100%",
    [theme.breakpoints.up("lg")]: {
      minHeight: "70vh",
    },
  },
  link: {
    cursor: "pointer",
    textDecoration: "none",
    // height: "31px",
    // width: "190px",
  },
}));
const PurpleButton = styled(Button)(({ theme }) => ({
  color: "#FFFFFF",
  backgroundColor: "#9051b1",
  borderRadius: "30px",
  margin: "0 auto",
  width: "100%",
  minWidth: "160px",
  maxWidth: "204px",
  textTransform: "unset",
  "&:hover": {
    backgroundColor: "#B965D3 ",
  },
}));

const ResourceCard = (props) => {
  const { title, data, type, content, focus } = props;
  const contenido = {
    competencias: {
      backgroundColor: "#3CBE7D",
      img: competencias,
    },
    manual: {
      backgroundColor: "#F78766",
      img: manual,
    },
    sugerencias: {
      backgroundColor: "#1B85BE",
      img: sugerencias,
    },
    video: {
      backgroundColor: "#B64F88",
      img: data,
    },
  };
  const backgroundColor = contenido[content]?.backgroundColor;

  const classes = useStyles({ backgroundColor, focus });

  const [open, setOpen] = React.useState(false);
  const openInNewTab = (url) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  return (
    <>
      <Grid
        container
        justifyContent="center"
        className={classes.container}
        onClick={() => {
          if (window.innerWidth > 1024) {
            if (type !== "video") setOpen(true);
          } else {
            openInNewTab(data);
          }
        }}
      >
        <Grid container className={classes.containerImg}>
          {type !== "video" && (
            <img src={contenido[content]?.img} alt="" className={classes.img} />
          )}
          {/* <p className={classes.TituloTipoDocumento}>{type}</p> */}
          {type === "video" && (
            <Grid
              container
              direction="column"
              item
              sm={12}
              alignItems="center"
              className={classes.containerVideo}
            >
              <VideoResource src={contenido[content]?.img} />
            </Grid>
          )}
        </Grid>
        <Grid className={classes.containerBack}>
          <p className={classes.TituloLecturas}>{title}</p>
          {/* <p className={classes.TituloTipoDocumento} >{num}</p> */}
        </Grid>
      </Grid>
      {data && (
        <Dialog
          open={open}
          onClose={() => {
            setOpen(false);
          }}
          maxWidth="md"
          fullWidth={true}
        >
          {type === "PDF" ? (
            <>
              <object
                // style={{ width: "100%", minHeight: "70vh", margin: "0px" }}
                data={data}
                type="application/pdf"
                title={title}
                // frameborder="0"
                className={classes.objectContainer}
              >
                <p>Ha ocurrido un error inesperado</p>
                <a
                  href={data}
                  target="_blank"
                  rel="noreferrer"
                  className={classes.link}
                >
                  <PurpleButton>Ir al archivo</PurpleButton>
                </a>
              </object>
            </>
          ) : (
            <>
              <Grid container justifyContent="center">
                {/* <p className={classes.TituloTipoDocumento} style={{ marginTop: "30px" }}>
                  Descargando...
                </p> */}
                <object
                  data={data}
                  title={title}
                  className={classes.objectContainer}
                >
                  {/* <p>Ha ocurrido un error inesperado</p> */}
                  {/* <embed src={data} style={{ margin: "0px", height: "100%", width: "100%" }}></embed> */}
                </object>
              </Grid>
              {/* <object style={{ width: "200px", height: "50px", margin: "0px" }} data={data}></object> */}
            </>
          )}
        </Dialog>
      )}
    </>
  );
};

export default ResourceCard;
