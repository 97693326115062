import { Button, Grid, makeStyles } from "@material-ui/core";
import React, { useContext, useEffect } from "react";
import DrawerContext from "../../../../../usability/DrawerContext";
import { useOnLoadImages } from "../../../../hooks/useOnLoadImages";
import Loader from "../../../../Loader/Loader";
import Drawer from "../../ReadingTools/Drawer";

const useStyles =  makeStyles (theme =>({
      Contenedor:{
        background:'#E9FBF9',
        width:'100vw',
        overflowX:'clip',
        [theme.breakpoints.down(1161)]:{
            background:'none',
           overflowX:'unset',
        },
        [theme.breakpoints.down(600)]:{
            marginTop: '79.51px',
        }
    },
  ContainerInfoLectura: {
    position: "relative",
    height: "86px",
    [theme.breakpoints.down(502)]:{
        height: "43px",
    },
    background: "#76D4C3",
    "& .BarraInfoLectura": {
      height: "86px",
      background: `Url(https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Barras/Azul.png)`,
        [theme.breakpoints.down(502)]:{
            height: "43px",
        },
    },
    "& .InfoLectura": {
      position: "absolute",
      height: "86px",
      width: "219px",
      boxSizing: "border-box",
      padding: "17.2px 0",
      background: "#FAFAFA",
      borderRadius: "50px",
      boxShadow: "2px 2px 2px 2px #00000029",
      zIndex: "4",
        [theme.breakpoints.down(502)]:{
            height: "43px",
            width: "109px",
            padding: "8.6px 0",
            borderRadius: "25px",
        },
    },
    "& .InfoLectura:before": {
      position: "absolute",
      display: "flex",
      boxSizing: "border-box",
      padding: "0 0 0 10px",
      justifyContent: "center",
      alignItems: "center",
      height: "39.88px",
      width: "39.88px",
      borderRadius: "50%",
      content: '""',
      boxShadow: "3px 0px .5px 0px #00000029",
      background: "#FAFAFA",
      left: "90px",
      top: "65px",
      transform: "rotate(0.25turn)",
      [theme.breakpoints.down(502)]:{
        left: "45px",
        top: "30px",
        height: "20.88px",
        width: "20.88px",
        },
    },
    "& .InfoLectura:after": {
      content: '""',
      height: "15px",
      width: "15px",
      borderTop: "2px solid #20A698",
      borderLeft: "2px solid #20A698",
      position: "absolute",
      bottom: "-10px",
      left: "Calc(50% - 8px)",
      transform: "rotate(0.625turn)",
      [theme.breakpoints.down(502)]:{
        height: "10px",
        width: "10px",
        left: "Calc(50% - 4.5px)",
        bottom: "-3px",
        borderTop: "1px solid #20A698",
        borderLeft: "1px solid #20A698",
        },
    },
    "& .Sesion": {
      borderLeft: "2px solid #D8D8D8",
    },
    "& .Dato": {
      margin: "0 0 5px 0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#20A698",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        margin: "0 0 2.5px 0",
        },
    },
    "& .Caracter": {
      margin: "0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#000000",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        },
    },
       '& .Regresar':{
        color: '#F2624F',
        border: '#F2624F 1px solid',
        height: '30px',
        width:'100px',
        borderRadius: '20px',
        letterSpacing: '0.14px',
        textTransform: 'none',
        background:'#FEFAF1',
        top:'calc(50% - 15px)',
        left:'5%',
        zIndex:4,
        [theme.breakpoints.down(502)]: {
          font:'bold 10px lato',
          width: "60px",
        },
      },
  },
    ContenedorLectura:{
        background:'#fff',
        width:'1160px',
        height:'100%',
        padding:'57px 168px 35px' ,
        
        transform: (props) =>
        props.scale > 1 ? `scale(${props.scale}) translate(0px, 10%)` : `none`,
        [theme.breakpoints.down(1161)]:{
            width:'502px',
            padding:'60px 0 0',
        },
        [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
        '& .ContainerDerechos':{
            bottom:'80px',
            [theme.breakpoints.down(1161)]:{
                display:'none',
            }
        },
        '& .Derechos':{
            textAlign:"center",
            margin:"0px",
            font:" 15px lato",
            color:"#717476",
        },
        '& .Escritorio':{
            display:'block',
            [theme.breakpoints.down(1161)]:{
                display:'none'
            },
        },
        '& .Tablet':{
            display:'none',
            [theme.breakpoints.down(1161)]:{
                display:'block'
            },
        },
    },
    ContainerTitulo:{
        margin:'0 0 45px 0',
        [theme.breakpoints.down(1161)]:{
            margin:'0 0 38px 0',
        },
        '& .Titulo':{
            font:'48px fredoka one',
            letterSpacing: '0px',
            color: '#AD486A',
            margin:'0',
            textAlign:'center',
            [theme.breakpoints.down(1161)]:{
                font:'43px fredoka one',
                lineHeight:'52px',
                textAlign:'center',
            },
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            lineHeight : '30px',
            margin:'0 0 36px 0 ',
            font:'22px lato',
            color:'#000',
            letterSpacing: '0.20px',
            [theme.breakpoints.down(1161)]:{
                font:'18px lato',
                letterSpacing:'.17px',
                lineHeight:'28px',
            },
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .SubTitulo':{
            font:'bold 30px lato',
            margin:'0',
            letterSpacing:'.1px',
            lineHeight:'30px',
            color:'#005DA5',
            [theme.breakpoints.down(1161)]:{
                font:'bold 26px lato',
                letterSpacing:'.24px',
                lineHeight:'28px',
            },
            [theme.breakpoints.down(502)]:{
                font:'bold 22px lato',
                lineHeight:'24px',
            },
            [theme.breakpoints.down(350)]:{
                font:'bold 18px lato',
                lineHeight:'24px',
            },
        },
        '& .TextImg':{
            margin:'24px 0 0 0',
            font:'20px lato',
            alignSelf:'flex-start',
            color:'#717476',
            letterSpacing:'.19px',
            [theme.breakpoints.down(1161)]:{
                font:'16px lato',
                letterSpacing:'.15px',
                lineHeight:'25px',
            },
            [theme.breakpoints.down(502)]:{
                font:' 11px lato',
                lineHeight :'14px',
                letterSpacing: '0.10px',
            },
        },
        '& .Imagen':{
            [theme.breakpoints.down(1161)]:{
                width:'100%',
            },
        },
       

    },

}));

const Existe_vida_en_otros_planetas = (props) => {

  const {activeStep, setActiveStep, handlePreviewStep, id_actividad} = props

  const handleContinue = () => {
    setActiveStep(activeStep - 1)
    handlePreviewStep()
  }

    const {
    handleSize,
    disabledZoom,
    closeZoom,
    handleZoom,
    scale
  }= useContext(DrawerContext);
  const [loading, setLoading] = React.useState(true);
  const wrapperRef = React.useRef(null);
  const imagesLoaded = useOnLoadImages(wrapperRef);

  const detectSize = () => {
    const lectura = document.getElementById("lectura");
    const newWidth = lectura?.offsetWidth;
    const newHeight = lectura?.offsetHeight;
    setLoading(false);
    if (lectura) handleSize(newHeight, newWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", detectSize);
    return () => {
      handleZoom()
      window.removeEventListener("resize", detectSize);
      closeZoom()
    };
  }, []);

  const classes = useStyles({ disabledZoom,scale });
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
             <Grid container item justifyContent="center" className={classes.ContainerInfoLectura}>
               <Grid container className="BarraInfoLectura">
                    <Button className="Regresar" onClick={handleContinue}>
                        Regresar
                    </Button>
                </Grid>
               <Grid container item className="InfoLectura">
                    <Grid container item direction="column" xs={6} justifyContent="center">
                        <p className="Dato">Grado</p>
                        <p className="Caracter">6</p>
                    </Grid>
                    <Grid container item direction="column" xs={6} justifyContent="center" className="Sesion">
                        <p className="Dato">Sesión</p>
                        <p className="Caracter">42</p>
                    </Grid>
               </Grid>
            </Grid>
{!imagesLoaded && <Loader />}
        <Grid
            container
            item
            className={classes.ContenedorLectura}
            justifyContent="center"
            id="lectura"
            onLoad={detectSize}
            ref={wrapperRef}
        >
<Drawer id_lectura={id_actividad} />
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo Escritorio" >
                        ¿Existe vida en otros planetas?
                    </h2>
                    <h2 className="Titulo Tablet" >
                        ¿Existe vida <br/>en otros planetas?
                    </h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                            Seguramente alguna vez te has preguntado si los seres humanos estamos solos en el 
                            universo. ¿Acaso seremos la única civilización avanzada o habrá otras más 
                            inteligentes? O, por el contrario, ¿habrá alguna forma de vida más básica que 
                            nosotros? <br/><br/>
                            
                            Conforme la ciencia ha avanzado, paulatinamente se ha conocido con mayor 
                            profundidad la extensión del universo y sus secretos. Por estos motivos, parece
                            imposible que solamente un planeta pueda ser capaz de albergar vida; sin embargo, 
                            hasta la fecha no hay señal de que exista otra civilización tan avanzada como la 
                            nuestra. A esta contradicción se le conoce como la <em>Paradoja de Fermi</em>. <br/><br/>
                            
                            Enrico Fermi fue un científico italiano del siglo <span style={{fontVariant: "all-small-caps"}}>XX</span>. Él creía incongruente que, a pesar 
                            del esfuerzo de la humanidad por comunicarse con vida inteligente fuera de la Tierra,
                            aún no hubiera sido posible establecer contacto con alguien. Por tal motivo, se 
                            nombró así a la paradoja. Para que comprendas mejor esto, a continuación, analiza 
                            algunos datos sobre el universo. <br/><br/>
                            
                            <h3 className='SubTitulo'>La grandeza del universo</h3> <br/>
                            
                            Se estima que en el universo conocido existen alrededor de 100 billones de galaxias. 
                            Recuerda que una galaxia es la acumulación de miles de millones de estrellas, las 
                            cuales tienen sus propios sistemas solares. <br/><br/>
                            
                            Por su parte, en nuestra galaxia, la Vía Láctea, existen 20 billones de estrellas 
                            parecidas a nuestro Sol, con sus propios planetas girando alrededor de ellas. Para 
                            que dimensiones la cantidad de estrellas que eso significa, ten en cuenta que 20 
                            billones es equivalente a tres veces la población de la Tierra. De esa cantidad, se 
                            calcula que en la quinta parte de esos sistemas por lo menos existe un planeta con las 
                            condiciones parecidas a la Tierra.
                        </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <Grid container  sm={12}>
                            <img className='Imagen' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion42/1.png"  alt="Tarjeta" />
                        </Grid>
                        <Grid container justifyContent='center'>
                            <p className='TextImg'>
                                La Vía Láctea recibe su nombre porque la forma y el color que se aprecia hacen parecer que 
                                es leche derramada. Su nombre viene de la mitología griega.
                            </p>
                        </Grid>
                    </Grid>
                    
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                            Si los cálculos fueran aún más pesimistas y se redujera la cantidad de planetas donde 
                            es probable albergar vida, como por ejemplo el 0.1% de ellos, aun así el resultado 
                            significaría que en un millón de planetas sería posible. Por cierto, no olvides que sólo
                            son cálculos de la Vía Láctea, que es una entre 100 billones de galaxias. ¿Ahora te 
                            parece más evidente por qué parece una contradicción? Tantos millones de posibles 
                            planetas habitables y aún no se conoce vida inteligente fuera de la Tierra. <br/><br/>
                            
                            <h3 className='SubTitulo'>Teorías de por qué no se ha encontrado vida fuera de la Tierra</h3><br/>
                            
                            La Vía Láctea se creó hace 13 billones de años y la Tierra tiene aproximadamente 
                            4 billones de años. Por ello, existe la posibilidad de que en algunos de los planetas la 
                            vida ya haya terminado. O, por el contrario, si albergan vida, es posible que ésta aún 
                            sea tan simple como lo fue cuando empezó a formarse en la Tierra. <br/><br/>
                            
                            Otra explicación es que durante la vida de cualquier planeta ocurren eventos que 
                            actúan como filtros que definen si la vida en un planeta continúa o no. Por ejemplo, 
                            en la Tierra ya han sucedido por lo menos cinco, pues ha sido golpeada por 
                            meteoritos, como el que extinguió a los dinosaurios, se han vivido epidemias y ha 
                            existido una actividad volcánica muy violenta. A pesar de ello, la vida ha continuado 
                            hasta la actualidad. Pero esa misma suerte podría no haber existido en otros 
                            planetas. <br/><br/>
                            
                            Una teoría más que explica la falta de hallazgos de vida señala los límites de la 
                            tecnología con la que contamos. Actualmente, no existen los avances suficientes para 
                            crear una nave que permita explorar planetas y estrellas lejanas. Y quizá si otras 
                            civilizaciones no han encontrado la Tierra sea por el mismo motivo.<br/><br/>
                            
                            Existe también la posibilidad de que el lenguaje sea un problema. En 1972 y 1977, se 
                            enviaron mensajes hacia el espacio que incluían la ubicación de la Tierra, retratos de 
                            los humanos e incluso el mensaje “Saludos de la Tierra” en 55 lenguas distintas. 
                            Lamentablemente, no garantiza que alguna otra civilización lo sepa leer. Incluso 
                            quizá ni siquiera puedan decodificar los sonidos de las grabaciones que enviamos. Lo 
                            mismo podría pasar si alguien más ha enviado mensajes a la Tierra. Quizá ya ha 
                            sucedido y la humanidad no se ha percatado, por ignorar otras formas de 
                            comunicación.
                        </p>
                    </Grid>
                    <Grid container  sm={12}>
                            <Grid container>
                                <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion42/2.png"  alt="Tarjeta" />
                            </Grid>
                            <Grid>
                                <p className='TextImg'>Los seres humanos seguirán comunicándose de distintas formas para intentar establecer 
                                contacto con otros mundos.</p>
                            </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                            A pesar de haber mencionado varias explicaciones, existe la posibilidad de que haya 
                            que tomar en cuenta la más sencilla: en realidad la Tierra es el único planeta que 
                            alberga vida, por lo menos inteligente. Si esto fuera una realidad, ¡la Tierra sería la 
                            casa de toda la vida existente en el universo! <br/><br/>

                            Pero esto podría no durar para siempre, ya que pronto la Tierra tendrá que superar 
                            otro filtro llamado <em>cambio climático</em>. En nuestras manos está cuidar toda la vida del 
                            universo, por lo menos toda la vida que nosotros conocemos.

                        </p>
                    </Grid>
                </Grid>
        </Grid>
        <Grid container item style={{ marginTop: 80}} ></Grid>
    </Grid>
    );
}
 
export default Existe_vida_en_otros_planetas;
