import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
    ContenedorLectura:{
        width:'502px',
                  [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
    },
    ContainerTitulo:{
        margin:'0 0 34px 0',
        '& .Titulo':{
            letterSpacing: '0px',
            color: '#AE705E',
            margin:'0',
            textAlign:'center',
            font:'43px fredoka one',
            lineHeight:'52px',
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            margin:'0 0 36px 0',
            color:'#000',
            font:'18px lato',
            lineHeight :'28px',
            letterSpacing: '0.17px',
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .Lectura2':{
            margin:'0',
            color:'#D1270A',
            font:'14px Fredoka One',
            letterSpacing: '0.17px',
            [theme.breakpoints.down(502)]:{
                font:'10px Fredoka One',
                letterSpacing: '0.09px',
            },
        },
        '& .Chocolate':{
            color:'#AE705E',
        },
        '& .Chocolates':{
            position:'relative',
        },
        '& .Pos':{
           position:'absolute'
        },
        '& .Pos1':{
            left:'24px',
            top:'20px',
            [theme.breakpoints.down(502)]:{
                left:'14px',
                top:'14px',
            },
        },
        '& .Pos2':{
            left:'388px',
            top:'166px',
            [theme.breakpoints.down(502)]:{
                left:'270px',
                top:'116px',
            },
        },
        '& .Pos3':{
            left:'400px',
            top:'218px',
            [theme.breakpoints.down(502)]:{
                left:'280px',
                top:'153px',
            },
        },
        '& .TextImg':{
            color:'#717476',
            margin:'17px 0 0 0',
            font:' 16px lato',
            lineHeight :'25px',
            letterSpacing: '0.15px',
            [theme.breakpoints.down(502)]:{
                font:' 11px lato',
                lineHeight :'14px',
                letterSpacing: '0.10px',
            },
        },

        '& .Imagen':{
            width:'100%',
        },  
      
    }
}));

const Chocolates_deliciosos_pero_sanos = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >
                        Chocolates deliciosos, pero ¿sanos?
                    </h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                      <p className="Lectura">
                        ¿Sabías que el chocolate es originario de América? Los antiguos pobladores de este 
                        continente reconocieron que el chocolate tiene propiedades medicinales y energizantes.<br/><br/>

                        Hoy sabemos que el chocolate contiene sustancias llamadas <em>antioxidantes</em> que 
                        ayudan a mantener la salud del corazón. También contribuye a acelerar algunas 
                        funciones de tu cerebro. Por ejemplo, eleva la capacidad de tu memoria y te ayuda a 
                        aprender mejor.
                      </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <Grid container justifyContent="center" className='Chocolates'>
                            <p className='Lectura2 Pos Pos1'>El <span className='Chocolate'>Chocolate </span><br/>ayuda a mejorar<br/> tu memoría </p>
                            <p className='Lectura2 Pos Pos2'>El <span className='Chocolate'>Chocolate </span><br/>mantiene tu <br/>corazón</p>
                            <p className='Lectura2 Pos Pos3'>saludable</p>
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img1ro/Sesion47/1.png" alt="Tarjeta" />
                        </Grid>
                        <Grid container justifyContent="center" >
                            <p className="TextImg">
                                El chocolate trae beneficios para tu corazón y tu cerebro.
                            </p>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                      <p className="Lectura">
                        No obstante, si bien el chocolate es nutritivo, también aporta muchas calorías. Ésta 
                        es la principal razón por la que se le asocia con problemas de sobrepeso y por lo cual 
                        su consumo no se recomienda a personas con enfermedades como la diabetes.
                      </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <Grid container justifyContent="center" >
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img1ro/Sesion47/2.png" alt="Tarjeta" />
                        </Grid>
                        <Grid container justifyContent="center" >
                            <p className="TextImg">
                                Estudia las etiquetas y consume chocolate con moderación.
                            </p>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                      <p className="Lectura">
                        Si quieres aprovechar todos los beneficios de comer chocolate sin caer en sus 
                        impactos negativos, es importante que te fijes en su calidad. Muchas de las 
                        propiedades benéficas están en el grano y otras en la manteca de cacao que se 
                        adiciona a los productos. Sin embargo, en algunas ocasiones, al procesar el chocolate, 
                        se le agrega azúcar en exceso y grasas no recomendadas, lo que no ocurre con el 
                        chocolate oscuro.<br/><br/>

                        Por eso es importante que consultes las etiquetas del chocolate de tu preferencia 
                        para saber exactamente lo que consumes. ¿Listo? ¡Vamos por un rico chocolate!
                      </p>
                    </Grid>
                </Grid>
        </Grid>
    </Grid>
    );
}
 
export default Chocolates_deliciosos_pero_sanos ;
