import { Button, makeStyles, Grid } from "@material-ui/core";
import React from "react";
import { Link } from "react-router-dom";

import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";

import DialogTitle from "@material-ui/core/DialogTitle";

import AuthContext from "../../../../auth/AuthContext";
import UsabilityContext from "../../../../usability/UsabilityContext";

import iconStudent from "../../../imgs/icon-student.png";
import iconTeacher from "../../../imgs/icon-teacher.png";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    padding: "48px 0 48px 0",
    background:
      "linear-gradient(217deg, rgba(255,194,0,0.4962185557816877) 2%, rgba(255,255,255,1) 25%, rgba(255,255,255,0.4962185557816877) 81%, rgba(144,81,177,1) 98%)",
    minHeight: "calc(100vh - 80px)",
  },
  boxContainer: {
    background: "#fff",
    width: "90%",
    gap: "20px",
    maxWidth: "1216px",
    minHeight: "60vh",
    borderRadius: "42px",
    padding: "30px",
    alignItems: "center",
    boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.5)",
    [theme.breakpoints.up("sm")]: {
      width: "auto",
      minWidth: "50%",
    },
    "& .Titulo": {
      font: "42px fredoka one",
      // margin: "0 0 84px 34px",
      textAlign: "center",
      color: "#40ADA3",
      [theme.breakpoints.down(425)]: {
        font: "30px fredoka one",
      },
    },
  },
  buttonsContainer: {
    width: "100%",
    gap: "20px",
    [theme.breakpoints.up("sm")]: {
      width: "60%",
    },
  },
  ContainerLinkinsiginas: {
    textDecoration: "none",
    // marginLeft: "44px",
    // marginBottom: "40px",
    "& .ButtonIsg": {
      color: "black",
      textTransform: "none",
      font: " 22px lato",
      gap: "10px",
      letterSpacing: "0.36px",
      [theme.breakpoints.down(425)]: {
        font: " 16px lato",
      },
    },
    "& p": {
      textAlign: "start",
    },
    "& .icon": {
      color: "purple",
      marginRight: "20px",
      fontSize: "75px",
      height: "75px",
      [theme.breakpoints.down(425)]: {
        fontSize: "50px",
      },
    },
  },

  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const SettingsTeacher = () => {
  const classes = useStyles();
  const { user } = React.useContext(AuthContext);
  const { handleGroupToEdit } = React.useContext(UsabilityContext);

  const [open, setOpen] = React.useState(false);
  const [school, setSchool] = React.useState("");

  const hasGroups = user?.grupos?.length > 0;

  const handleClose = () => {
    setOpen(false);
  };

  const selectChange = (event) => {
    setSchool(event.target.value);
    handleGroupToEdit(event.target.value);
  };

  return (
    <Grid
      container
      item
      sm={12}
      alignItems="center"
      justifyContent="center"
      className={classes.mainContainer}
    >
      <Grid container item direction="column" className={classes.boxContainer}>
        <h2 className="Titulo">Configuración</h2>
        <Grid
          container
          item
          direction="row"
          alignItems="center"
          className={classes.buttonsContainer}
        >
          <Grid container item direction="row" alignItems="center">
            <Link
              className={classes.ContainerLinkinsiginas}
              to="/docente/editar"
            >
              <Button className="ButtonIsg">
                <img src={iconTeacher} alt="" className="icon" />
                <p> Editar perfil del docente</p>
              </Button>
            </Link>
          </Grid>
          {hasGroups && (
            <Grid container item direction="row" alignItems="center">
              <Grid
                className={classes.ContainerLinkinsiginas}
                onClick={() => {
                  setOpen(true);
                }}
              >
                <Button className="ButtonIsg">
                  <img src={iconStudent} alt="" className="icon" />
                  <p> Editar perfil de estudiantes </p>
                </Button>
              </Grid>
              <Dialog open={open} onClose={handleClose}>
                <DialogTitle>
                  <p> Selecciona el colegio</p>
                </DialogTitle>
                <DialogContent>
                  <Grid container alignItems="center" justifyContent="center">
                    <FormControl className={classes.formControl}>
                      <InputLabel id="demo-simple-select-label">
                        Colegio
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={school}
                        onChange={selectChange}
                      >
                        {user.grupos.map((grupo) => (
                          <Link
                            to={`/docente/configuracion/alumno/${grupo.id_distribucion_escuela}`}
                            style={{ textDecoration: "none", color: "#000" }}
                            key={grupo.id_distribucion_escuela}
                          >
                            <MenuItem value={grupo.id_distribucion_escuela}>
                              {grupo.escuela}, grado: {grupo.id_grado}
                            </MenuItem>
                          </Link>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={() => {
                      setOpen(false);
                    }}
                    className="ButtonIsg"
                  >
                    <p> Cancelar</p>
                  </Button>
                </DialogActions>
              </Dialog>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SettingsTeacher;
