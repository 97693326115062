import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
    ContenedorLectura:{
        height:'100%',
        width:'502px',
                  [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
    },
    ContainerTitulo:{
        margin:'0 0 34px 0',
        '& .Titulo':{
            letterSpacing: '0px',
            color: '#F6664D',
            margin:'0',
            textAlign:'center',
            font:'43px fredoka one',
            lineHeight:'52px',
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            margin:'0 0 36px 0',
            color:'#000',
            font:'18px lato',
            lineHeight :'28px',
            letterSpacing: '0.17px',
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .TextImg':{
            color:'#717476',
            margin:'24px 0 0 0',
            font:' 16px lato',
            lineHeight :'25px',
            letterSpacing: '0.15px',
            [theme.breakpoints.down(502)]:{
                font:' 11px lato',
                lineHeight :'14px',
                letterSpacing: '0.10px',
            },
        },

        '& .Imagen':{
            width:'100%',
        },  

      
    }
}));

const  El_español_una_lengua_con_hambre = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                        <h2 className="Titulo ">El español, una lengua con hambre</h2>

                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                       <p className='Lectura'>
                        Por un momento imagina a la lengua española como un monstruo con mucha hambre 
                        de palabras. Y piensa en cada nueva palabra para el español como su alimento. ¡El 
                        monstruo del español tiene tanta hambre que come todas las palabras que
                        encuentra!<br/><br/>

                        Durante más de 500 años, este idioma se ha alimentado de palabras de más de 400 
                        idiomas originarios de América. Las lenguas que más han aportado palabras al 
                        español han sido el náhuatl, el maya, el aymara, el quechua y el guaraní.
                       </p>                   
                    </Grid>
                    <Grid container justifyContent='center'>
                        <Grid container item justifyContent='center'>
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img1ro/Sesion15/1.png" alt="Tarjeta" />
                        </Grid>
                        <Grid container justifyContent='center' >
                            <p className="TextImg">
                                Día a día, el contacto entre culturas enriquece al español.
                            </p>
                        </Grid>
                    </Grid>
                    
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container >
                        <p className='Lectura'>
                            El náhuatl en México proporcionó al español palabras como <em> aguacate, tomate, 
                            cacahuate, chocolate, chicle </em>(goma de mascar), <em>huarache, tianguis, petate</em> y muchas más. 
                            De la lengua maya, con presencia en Guatemala, Honduras y el sur de México, 
                            obtuvimos términos como <em>cigarro, cachito, chamaco, patatús,</em> por mencionar algunas. 
                            La lengua quechua, idioma oficial de Perú y Bolivia, nos dio palabras como <em>cóndor, 
                            cancha, carpa, cura, gaucho, poncho, guano,</em> entre otras más.
                        </p>
                    </Grid>
                    <Grid container justifyContent='center'>
                        <Grid container justifyContent='center'>
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img1ro/Sesion15/2.png" alt="Tarjeta" />
                        </Grid>
                        <Grid container justifyContent='center' >
                            <p className="TextImg">
                                Este mapa muestra algunas de las contribuciones de los pueblos originarios al español.                         
                            </p>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container >
                        <p className='Lectura'>
                            Cuando el español se come a una palabra indígena la mantiene viva. Tal vez la piensa 
                            y la pronuncia de manera diferente, pero le da la oportunidad de existir en nuevas 
                            bocas y en nuevos lugares.
                        </p>
                    </Grid>
                </Grid>
            </Grid>
    </Grid>
    );
}
 
export default  El_español_una_lengua_con_hambre;
