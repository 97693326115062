import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
    Contenedor:{
       background:'#E9FBF9',
       width:'100vw',
       [theme.breakpoints.down(1161)]:{
           background:'none',
       },
       [theme.breakpoints.down(600)]:{
           marginTop: '79.51px',
       }
   },
  ContainerInfoLectura: {
    position: "relative",
    height: "86px",
    [theme.breakpoints.down(502)]:{
        height: "43px",
    },
    background: "#76D4C3",
    "& .BarraInfoLectura": {
      height: "86px",
      background: `Url(https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Barras/Azul.png)`,
        [theme.breakpoints.down(502)]:{
            height: "43px",
        },
    },
    "& .InfoLectura": {
      position: "absolute",
      height: "86px",
      width: "219px",
      boxSizing: "border-box",
      padding: "17.2px 0",
      background: "#FAFAFA",
      borderRadius: "50px",
      boxShadow: "2px 2px 2px 2px #00000029",
      zIndex: "4",
        [theme.breakpoints.down(502)]:{
            height: "43px",
            width: "109px",
            padding: "8.6px 0",
            borderRadius: "25px",
        },
    },
    "& .InfoLectura:before": {
      position: "absolute",
      display: "flex",
      boxSizing: "border-box",
      padding: "0 0 0 10px",
      justifyContent: "center",
      alignItems: "center",
      height: "39.88px",
      width: "39.88px",
      borderRadius: "50%",
      content: '""',
      boxShadow: "3px 0px .5px 0px #00000029",
      background: "#FAFAFA",
      left: "90px",
      top: "65px",
      transform: "rotate(0.25turn)",
      [theme.breakpoints.down(502)]:{
        left: "45px",
        top: "30px",
        height: "20.88px",
        width: "20.88px",
        },
    },
    "& .InfoLectura:after": {
      content: '""',
      height: "15px",
      width: "15px",
      borderTop: "2px solid #20A698",
      borderLeft: "2px solid #20A698",
      position: "absolute",
      bottom: "-10px",
      left: "Calc(50% - 8px)",
      transform: "rotate(0.625turn)",
      [theme.breakpoints.down(502)]:{
        height: "10px",
        width: "10px",
        left: "Calc(50% - 4.5px)",
        bottom: "-3px",
        borderTop: "1px solid #20A698",
        borderLeft: "1px solid #20A698",
        },
    },
    "& .Sesion": {
      borderLeft: "2px solid #D8D8D8",
    },
    "& .Dato": {
      margin: "0 0 5px 0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#20A698",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        margin: "0 0 2.5px 0",
        },
    },
    "& .Caracter": {
      margin: "0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#000000",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        },
    },
  },
   ContenedorLectura:{
       background:'#fff',
       width:'1160px',
       height:'100%',
       padding:'60px 168px 35px ',
       [theme.breakpoints.down(1161)]:{
           width:'502px',
           padding:'60px 0 0 0',
         },
                [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
       '& .ContainerDerechos':{
           bottom:'80px',
           [theme.breakpoints.down(1161)]:{
               display:'none',
           },
       },
       '& .Derechos':{
           textAlign:"center",
           margin:"0px",
           font:" 15px lato",
           color:"#717476",
       },
       '& .Escritorio':{
           display:'block',
           [theme.breakpoints.down(1161)]:{
               display:'none'
           },
       },
       '& .Tablet':{
           display:'none',
           [theme.breakpoints.down(1161)]:{
               display:'block'
           },
       }
   },
    ContainerTitulo:{
        margin:'0 0 46px 0',
        '& .Titulo':{
            letterSpacing: '0px',
            color: '#E1AF10',
            margin:'0',
            textAlign:'center',
            font:'48px fredoka one',
            [theme.breakpoints.down(1161)]:{
                font:'43px fredoka one',
                lineHeight:'52px',
            },
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            margin:'0 0 36px 0 ',
            color:'#000',
            font:'22px lato',
            lineHeight : '30px',
            letterSpacing: '0.20px',
            [theme.breakpoints.down(1161)]:{
                font:'18px lato',
                lineHeight :'28px',
                letterSpacing: '0.17px',
            },
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .SubTitulo':{
            color:'#0C879F',
            margin:'0',
            font:'bold 30px lato',
            letterSpacing:'.28px',
            lineHeight:'30px',
            [theme.breakpoints.down(1161)]:{
                font:'bold 22px lato',
                lineHeight :'24px',
                letterSpacing: '0.21px',
            },
            [theme.breakpoints.down(502)]:{
                font:'bold 18px lato',
                lineHeight :'20px',
                letterSpacing: '0.16px',
            },
        },
        "& .SubTitulo2": {
            color: "#E1AF10",
            margin: "0",
            font: "bold 26px lato",
            letterSpacing: ".24px",
            lineHeight: "28px",
            [theme.breakpoints.down(1161)]: {
              font: "bold 22px lato",
              lineHeight: "24px",
              letterSpacing: "0.21px",
            },
            [theme.breakpoints.down(502)]: {
              font: "bold 18px lato",
              lineHeight: "20px",
              letterSpacing: "0.16px",
            },
          },
        '& .Cuadro':{
            position:'relative',
        },
        '& .Esp':{
            position:'absolute',
            margin:'53px 0 0 0',
            width:'750px',
            [theme.breakpoints.down(1161)]:{
                width:'85.25%',
            },
            [theme.breakpoints.down(502)]:{
                margin:'30px 0 0 0',
            },
            [theme.breakpoints.down(350)]:{
                margin:'15px 0 0 0',
            },
        },
        
        '& .Imagen':{
            [theme.breakpoints.down(1161)]:{
                width:'100%'
            },
        },
    },
    

}));

const Qué_son_las_artes_plásticas = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
             <Grid container item justifyContent="center" className={classes.ContainerInfoLectura}>
               <Grid container className="BarraInfoLectura"></Grid>
               <Grid container item className="InfoLectura">
                    <Grid container item direction="column" xs={6} justifyContent="center">
                        <p className="Dato">Grado</p>
                        <p className="Caracter">3</p>
                    </Grid>
                    <Grid container item direction="column" xs={6} justifyContent="center" className="Sesion">
                        <p className="Dato">Sesión</p>
                        <p className="Caracter">39</p>
                    </Grid>
               </Grid>
            </Grid>

            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >
                        ¿Qué son las <br className='Tablet'/>artes plásticas?
                    </h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container item  sm={12}>
                        <p className="Lectura">
                            Cuando escuchas la palabra <em>plástico</em>, probablemente piensas en los múltiples objetos 
                            elaborados con este material. Pero las <em>artes plásticas</em> no están del todo relacionadas 
                            con ese producto flexible y de uso cotidiano. A continuación, conocerás sobre las 
                            distintas técnicas para producir piezas artísticas.
                        </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <img className='Imagen' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img3ro/Sesion39/1.png"  alt="" />
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} >
                    <Grid container item sm={12}>
                        <p className="Lectura ">
                            <h3 className='SubTitulo'>Transformar para embellecer</h3><br/> 
                            Se entiende por <em>arte</em> toda actividad humana dedicada a la creación de cosas bellas. La 
                            finalidad de un artista es convertir un objeto común en algo bello a través de 
                            diferentes procesos. Existen siete disciplinas: pintura, escultura, dibujo, grabado, 
                            arquitectura, orfebrería y cerámica, conocidas como <em>artes plásticas</em>.
                        </p>
                    </Grid>
                    <Grid container item className='Cuadro' justifyContent='center'>
                        <p className='Lectura Esp'>
                            Las artes plásticas forman parte de las <em>bellas artes </em>junto con lamúsica, la 
                            danza, la literatura y el teatro. Estas ramas tienen en común que expresan un 
                            mensaje y que sus elementos son armónicos.
                        </p>
                        <img className='Imagen Escritorio' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img3ro/Sesion39/3.png"  alt="" />
                        <img className='Imagen Tablet' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img3ro/Sesion39/3R.png"  alt="" />
                    </Grid>
                       
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent='center'>
                    <Grid container item sm={12}>
                        <p className="Lectura ">
                            <h3 className="SubTitulo">El aspecto de la forma</h3><br/>
                            
                            La finalidad del arte es dar un mensaje. Por ejemplo, la pintura <em>El grito</em>, hecha por 
                            Edvard Munch, transmite desesperación y soledad. Cada creador emplea un sinfín de 
                            técnicas y habilidades para comunicar su idea, como las que se explican a 
                            continuación. <br/><br/>
                            
                            <h3 className="SubTitulo2">Pintura</h3><br/>
                            
                            Consiste en distribuir un colorante artificial o natural sobre una superficie, como 
                            tela, papel, madera o piedra. Este método ha acompañado a la humanidad desde hace 
                            siglos, prueba de ello son las pinturas rupestres que hay en las cuevas. <br/><br/>
                            
                            <h3 className='SubTitulo2'>Escultura</h3><br/>
                            
                            Se basa en manejar elementos para construir una figura en tercera dimensión. Suele 
                            utilizar madera, metal, plástico, piedra o cartón. Hay muchas técnicas de escultura y 
                            el tamaño de las obras es variado.
                        </p>
                    </Grid>
                    <Grid container item  justifyContent='center'>
                        <img className='Imagen' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img3ro/Sesion39/2.png"  alt="" />
                    </Grid>
                    
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent='center'>
                    <Grid container item sm={12}>
                        <p className="Lectura ">
                            <h3 className='SubTitulo2'>Dibujo</h3><br/>
                            
                            Su base son los trazos, que pueden hacerse sobre cualquier superficie. Casi siempre, 
                            se reproducen a partir de líneas suaves y figuras geométricas. Sus piezas están 
                            elaboradas con un solo color o con lápices. Las demás obras plásticas son creadas a 
                            partir de dibujos. <br/><br/>
                            
                            <h3 className='SubTitulo2'>Grabado</h3><br/>
                            
                            Lo conforman dos procesos. Primero, se talla una imagen sobre metal, piedra o 
                            madera. Después, se impregna con tinta y se coloca sobre papel o tela. Al separar 
                            ambas partes, la imagen quedará marcada gracias a la tinta. Se trata de una 
                            impresión o sello, como los que utilizan los profesores para calificar las tareas.<br/><br/>
                            
                            <h3 className='SubTitulo2'>Arquitectura</h3><br/>
                            
                            Es una muestra de lo que buscan las artes plásticas: la transformación de materiales. 
                            En ella se toman piedras, madera, metal o vidrio y se los convierte en objetos
                            admirables, como monumentos, iglesias, palacios o casas. Además, en una pieza 
                            arquitectónica intervienen otras disciplinas. Por ejemplo, el diseño, que se encarga 
                            de hacer los planos de las construcciones. <br/><br/>
                            
                            Como ves, las artes plásticas buscan causar reflexiones sobre la belleza. ¿Qué 
                            disciplina te parece más llamativa? No te quedes con las ganas y aprende lo 
                            necesario para convertirte en un artista plástico. ¡Inténtalo!
                        </p>
                    </Grid>
                </Grid>


                
                <Grid container item xs={12} justifyContent="center" className="ContainerDerechos">
                    <p className="Derechos">
                        © 2022. Todos los derechos reservados, Pearson, Circuito de lectores.
                    </p>                
                </Grid>
        </Grid>
    </Grid>
    );
}
 
export default Qué_son_las_artes_plásticas;
