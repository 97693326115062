import { Button, Grid, makeStyles } from "@material-ui/core";
import React, { useContext, useEffect } from "react";
import DrawerContext from "../../../../../usability/DrawerContext";
import { useOnLoadImages } from "../../../../hooks/useOnLoadImages";
import Loader from "../../../../Loader/Loader";
import Drawer from "../../ReadingTools/Drawer";

const useStyles =  makeStyles (theme =>({
      Contenedor:{
        background:'#E9FBF9',
        width:'100vw',
        overflowX:'clip',
        [theme.breakpoints.down(1161)]:{
            background:'none',
           overflowX:'unset',
        },
        [theme.breakpoints.down(600)]:{
            marginTop: '79.51px',
        }
    },
  ContainerInfoLectura: {
    position: "relative",
    height: "86px",
    [theme.breakpoints.down(502)]:{
        height: "43px",
    },
    background: "#76D4C3",
    "& .BarraInfoLectura": {
      height: "86px",
      background: `Url(https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Barras/Azul.png)`,
        [theme.breakpoints.down(502)]:{
            height: "43px",
        },
    },
    "& .InfoLectura": {
      position: "absolute",
      height: "86px",
      width: "219px",
      boxSizing: "border-box",
      padding: "17.2px 0",
      background: "#FAFAFA",
      borderRadius: "50px",
      boxShadow: "2px 2px 2px 2px #00000029",
      zIndex: "4",
        [theme.breakpoints.down(502)]:{
            height: "43px",
            width: "109px",
            padding: "8.6px 0",
            borderRadius: "25px",
        },
    },
    "& .InfoLectura:before": {
      position: "absolute",
      display: "flex",
      boxSizing: "border-box",
      padding: "0 0 0 10px",
      justifyContent: "center",
      alignItems: "center",
      height: "39.88px",
      width: "39.88px",
      borderRadius: "50%",
      content: '""',
      boxShadow: "3px 0px .5px 0px #00000029",
      background: "#FAFAFA",
      left: "90px",
      top: "65px",
      transform: "rotate(0.25turn)",
      [theme.breakpoints.down(502)]:{
        left: "45px",
        top: "30px",
        height: "20.88px",
        width: "20.88px",
        },
    },
    "& .InfoLectura:after": {
      content: '""',
      height: "15px",
      width: "15px",
      borderTop: "2px solid #20A698",
      borderLeft: "2px solid #20A698",
      position: "absolute",
      bottom: "-10px",
      left: "Calc(50% - 8px)",
      transform: "rotate(0.625turn)",
      [theme.breakpoints.down(502)]:{
        height: "10px",
        width: "10px",
        left: "Calc(50% - 4.5px)",
        bottom: "-3px",
        borderTop: "1px solid #20A698",
        borderLeft: "1px solid #20A698",
        },
    },
    "& .Sesion": {
      borderLeft: "2px solid #D8D8D8",
    },
    "& .Dato": {
      margin: "0 0 5px 0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#20A698",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        margin: "0 0 2.5px 0",
        },
    },
    "& .Caracter": {
      margin: "0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#000000",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        },
    },
       '& .Regresar':{
        color: '#F2624F',
        border: '#F2624F 1px solid',
        height: '30px',
        width:'100px',
        borderRadius: '20px',
        letterSpacing: '0.14px',
        textTransform: 'none',
        background:'#FEFAF1',
        top:'calc(50% - 15px)',
        left:'5%',
        zIndex:4,
        [theme.breakpoints.down(502)]: {
          font:'bold 10px lato',
          width: "60px",
        },
      },
  },
    ContenedorLectura:{
        background:'#fff',
        width:'1160px',
        height:'100%',
        padding:'60px 168px 35px' ,
        
        transform: (props) =>
        props.scale > 1 ? `scale(${props.scale}) translate(0px, 10%)` : `none`,
        [theme.breakpoints.down(1161)]:{
            width:'502px',
            padding:'60px 0 0',
          },
          [theme.breakpoints.down(502)]:{
            width:'350px',
            padding:'60px 0 0',
          },
          '& .ContainerDerechos':{
            bottom:'80px',
            [theme.breakpoints.down(1161)]:{
                display:'none',
            }
        },
        '& .Derechos':{
            textAlign:"center",
            margin:"0px",
            font:" 15px lato",
            color:"#717476",
        } ,
        '& .Escritorio':{
            display:'block',
            [theme.breakpoints.down(1161)]:{
                display:'none'
            },
        },
        '& .Tablet':{
            display:'none',
            [theme.breakpoints.down(1161)]:{
                display:'block'
            },
        },
    },
    ContainerTitulo:{
        margin:'0 0 46px 0',
        [theme.breakpoints.down(1161)]:{
            margin:'0 0 38px 0',
        },
        '& .Titulo':{
            font:'48px fredoka one',
            letterSpacing: '0px',
            color: '#0CB5D6',
            margin:'0',
            textAlign:'center',
            [theme.breakpoints.down(1161)]:{
                font:'43px fredoka one',
                lineHeight:'52px',
                textAlign:'center',
            },
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
      
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            lineHeight : '30px',
            margin:'0 0 36px 0 ',
            font:'22px lato',
            color:'#000',
            letterSpacing: '0.20px',
            [theme.breakpoints.down(1161)]:{
                lineHeight :'28px',
                font:'18px lato',
                letterSpacing: '0.16px',
            },
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },

        '& .ContainerCuadro':{
            position:'relative',
        },
        '& .Esp':{
            position:'absolute',
            margin:'53px 0 0 0 ',
            width:'750px',
            [theme.breakpoints.down(1161)]:{
                width:'85%',
            },
            [theme.breakpoints.down(502)]:{
                margin:'25px 0 0 0',
                lineHeight :'17px!important',
            },
        },
        '& .Imagen':{
            [theme.breakpoints.down(1161)]:{
                width:'100%',
            },
        },
        '& .Imagen2':{
            [theme.breakpoints.down(1161)]:{
                width:'100%',
            },
            [theme.breakpoints.down(502)]:{
                height:'105%',
            },
        },
    },
    
    

}));

const La_tecnología_y_el_arte_una_unión_original = (props) => {

  const {activeStep, setActiveStep, handlePreviewStep, id_actividad} = props

  const handleContinue = () => {
    setActiveStep(activeStep - 1)
    handlePreviewStep()
  }

    const {
    handleSize,
    disabledZoom,
    closeZoom,
    handleZoom,
    scale
  }= useContext(DrawerContext);
  const [loading, setLoading] = React.useState(true);
  const wrapperRef = React.useRef(null);
  const imagesLoaded = useOnLoadImages(wrapperRef);

  const detectSize = () => {
    const lectura = document.getElementById("lectura");
    const newWidth = lectura?.offsetWidth;
    const newHeight = lectura?.offsetHeight;
    setLoading(false);
    if (lectura) handleSize(newHeight, newWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", detectSize);
    return () => {
      handleZoom()
      window.removeEventListener("resize", detectSize);
      closeZoom()
    };
  }, []);

  const classes = useStyles({ disabledZoom,scale });
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
             <Grid container item justifyContent="center" className={classes.ContainerInfoLectura}>
               <Grid container className="BarraInfoLectura">
                    <Button className="Regresar" onClick={handleContinue}>
                        Regresar
                    </Button>
                </Grid>
               <Grid container item className="InfoLectura">
                    <Grid container item direction="column" xs={6} justifyContent="center">
                        <p className="Dato">Grado</p>
                        <p className="Caracter">6</p>
                    </Grid>
                    <Grid container item direction="column" xs={6} justifyContent="center" className="Sesion">
                        <p className="Dato">Sesión</p>
                        <p className="Caracter">21</p>
                    </Grid>
               </Grid>
            </Grid>
{!imagesLoaded && <Loader />}
        <Grid
            container
            item
            className={classes.ContenedorLectura}
            justifyContent="center"
            id="lectura"
            onLoad={detectSize}
            ref={wrapperRef}
        >
<Drawer id_lectura={id_actividad} />
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >
                    La tecnología y el arte: <br/>una unión original
                    </h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura ">
                            La historia de la humanidad podría resumirse en una palabra: cambio. Desde la 
                            aparición de los seres humanos en la Tierra ha existido un proceso continuo de 
                            evoluciones. Piensa, por ejemplo, en la manera en que se vestían en la época de las 
                            cavernas, con pieles de animales apenas tratadas. En la actualidad es diferente, pues 
                            se utilizan diferentes materiales textiles con diversos diseños. Puedes recordar 
                            también el caso de las representaciones de imágenes. Primero antiguas pinturas 
                            rupestres en cuevas, luego retratos hiperrealistas, hasta llegar ahora a la fotografía y 
                            a la impresión incluso en tercera dimensión. <br/><br/>
                            
                            Podrías hacer un recuento interminable de ejemplos, pero los dos casos anteriores 
                            son emblemáticos porque intervienen en ellos dos tipos de conocimientos que 
                            podrían parecer muy distintos: arte y tecnología. Pero la realidad es que siempre han 
                            permanecido juntos, apoyándose mutuamente. Para entender la relación entre los 
                            dos, observa sus definiciones.
                        </p>
                    </Grid>
                    <Grid container justifyContent='center' className='ContainerCuadro'>
                        <img className="Imagen2 Escritorio" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion21/1.png" alt="Tarjeta" />
                        <img className="Imagen2 Tablet" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion21/1R.png" alt="Tarjeta" />
                        <p className='Lectura Esp'>
                            El <em>arte</em> es una actividad que permite al ser humano expresar su imaginación o 
                            una parte de la realidad, con una finalidad estética, mediante diversos 
                            materiales, imágenes o sonidos.                        
                        </p>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container justifyContent='center' className='ContainerCuadro'>
                        <img className="Imagen2 Escritorio" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion21/2.png" alt="Tarjeta" />
                        <img className="Imagen2 Tablet" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion21/2R.png" alt="Tarjeta" />
                        <p className='Lectura Esp'>
                            La <em>tecnología</em> es la suma de técnicas, habilidades, métodos y procesos 
                            utilizados en la producción de bienes o servicios.                      
                        </p>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                        <Grid container sm={12}>
                            <p className="Lectura">
                                Ahora que conoces las definiciones vuelve a considerar los ejemplos iniciales. Para 
                                que ahora puedas vestir la ropa que tienes (y no las pieles de la Antigüedad) se 
                                debieron inventar máquinas que convirtieran algodón en tela. Y aunque pienses que 
                                un pantalón no es arte, valdría la pena que recordaras la industria de la moda, una 
                                expresión artística que conjunta las experiencias de color, textura y formas de la ropa 
                                en pasarelas de diseñadores famosos. En el caso de la pintura el paso entre pintar un 
                                mamut en una cueva y tener una fotografía de un elefante en alta definición no pudo 
                                haber sido posible si la representación artística no hubiese estado apoyada por la 
                                tecnología.
                            </p>
                        </Grid>
                        <Grid container justifyContent="center" sm={12}>
                            <Grid container >
                                <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion21/3.png" alt="Tarjeta" />
                            </Grid>
                        </Grid>
                </Grid>
                
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura ">
                            Piensa en otro tipo de expresiones artísticas como la música o el cine. Anteriormente 
                            la música ni siquiera tenía la posibilidad de grabarse para reproducirse en diversos 
                            dispositivos. Después la música sólo se podía escuchar en vivo. Luego los métodos de 
                            grabación sólo permitían grabar una sola vez, por lo que no podía haber errores. 
                            Ahora los músicos, junto con sus productores e ingenieros de sonido, graban 
                            exhaustivamente de manera digital hasta conseguir lo que ellos consideran 
                            resultados perfectos. <br/><br/>
                            
                            Por su parte se encuentra el cine, al que se considera el séptimo arte. En sus orígenes 
                            eran fotografías en movimiento. Éste ha evolucionado tanto hasta el punto de 
                            producir películas completamente en computadora. Ejemplo de ellas son las 
                            animadas, en las que el lápiz y el papel se complementaron o sustituyeron con 
                            programas de animación. También está el caso de los efectos especiales, que serían 
                            costosísimos o imposibles sin la tecnología. <br/><br/>
                            
                            La unión entre la tecnología y el arte no sólo se basa en la creación, también en la 
                            distribución, gracias a internet. El ejemplo más reciente son las plataformas 
                            <em> streaming</em>, que se han multiplicado por decenas, lo cual ha permitido a los artistas 
                            llevar su arte a cualquier parte del mundo de manera inmediata, permitiéndoles 
                            obtener ganancias por ello. <br/><br/>
                            
                            Además las plataformas, junto con las redes sociales, permiten que diversos artistas 
                            nuevos puedan ser conocidos sin depender de grandes compañías que los 
                            promuevan inicialmente. Así, cualquier persona puede compartir su contenido en la 
                            red, con la posibilidad de ser reconocida paulatinamente. <br/><br/>
                            
                            La tecnología conlleva transformación, pero hay personas que se resisten a los 
                            cambios o que critican sus implicaciones. Por ejemplo, se dice que la inclusión de la 
                            tecnología ha hecho que los artistas no sepan dibujar como tradicionalmente se 
                            hacía, que los músicos ya no sean expertos en tocar instrumentos o que los cantantes 
                            ya no tengan que ser afinados.
                        </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <Grid container >
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion21/4.png" alt="Tarjeta" />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                            La supuesta falta de talento, combinada con la posibilidad de publicar sin 
                            restricciones en diversas plataformas o redes sociales, ha permitido que la gente se 
                            haga famosa sin desarrollar la tradicional técnica rigurosa que anteriormente se 
                            exigía para siquiera ser publicado y posteriormente sobresalir. Sin embargo, quizá 
                            habría que reflexionar si el correcto manejo de la tecnología implica en sí mismo un 
                            cierto tipo de arte. <br/><br/>
                            
                            Las opiniones opuestas siempre han existido, en especial cuando se trata de arte. 
                            Pero es importante resaltar que ya sea con o sin apoyo de la tecnología se necesita 
                            talento y disciplina. Por ello, si un artista sabe cómo incluir las herramientas digitales 
                            en su trabajo diario, no sólo logrará mejorarlo sino también agilizarlo. Así podrá 
                            aumentar y extender la difusión de su creatividad hacia todo el mundo.
                        </p>
                    </Grid>
                </Grid>
        </Grid>
        <Grid container item style={{ marginTop: 80 }}></Grid>
    </Grid>
    );
}
 
export default La_tecnología_y_el_arte_una_unión_original;
