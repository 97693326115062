import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
    ContenedorLectura:{
        width:'502px',
                  [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
    },
    ContainerTitulo:{
        margin:'0 0 34px 0',
        '& .Titulo':{
            letterSpacing: '0px',
            color: '#088857',
            margin:'0',
            textAlign:'center',
            font:'43px fredoka one',
            lineHeight:'52px',
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            margin:'0 0 36px 0',
            color:'#000',
            font:'18px lato',
            lineHeight :'28px',
            letterSpacing: '0.17px',
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .Esp':{
            margin:'0 0 36px 0'
        },
        '& .Esp2':{
            margin:0,
        },

        '& .Imagen':{
             width:'63.74%',

        }, 
        '& .Imagen2':{
            width:'80.07%',
        }, 
      
    }
}));

const El_sombrerero_loco = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>

            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >
                        El sombrerero loco                   
                    </h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                      <p className="Lectura Esp">
                        David llevaba casi un año trabajando en una fábrica de sombreros en Francia. Siempre 
                        había apreciado la profesión porque su padre se había dedicado a ella toda su vida y lo 
                        inspiró a seguir sus pasos. <br/><br/>

                        Pero desde hacía unos días se sentía extraño, más triste de lo normal y abatido, sin 
                        lograr encontrar una explicación para esa situación. Además, se le olvidaban las cosas 
                        y eso lo irritaba. Pero lo más extraño es que su padre también había tenido estos 
                        comportamientos cuando trabajaba en la fábrica.

                      </p>
                    </Grid>
                    <Grid container justifyContent="center"  sm={12}>
                        <Grid container justifyContent="center" >
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img3ro/Sesion44/1.png" alt="Tarjeta" />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container xs={12}>
                      <p className="Lectura">
                        Corría el año 1850 y ésta era una 
                        ocupación muy bien remunerada y 
                        popular, porque todos querían un tocado 
                        decorativo para lucir sobre sus cabezas. 
                        Las personas más importantes mandaban 
                        a hacerlos a su medida y gusto. El material 
                        más buscado era el fieltro, que se hacía 
                        con pelo de conejo o de castor. Utilizaban 
                        una sustancia de color naranja que ponían 
                        a calentar para remojar el fieltro. Así 
                        conseguían la textura que tanto gustaba a 
                        la gente.<br/><br/>

                        David utilizaba esta técnica a menudo, porque sus creaciones se vendían mejor y 
                        ganaba más dinero. El negocio iba bien y era admirado por la sociedad, pero él 
                        comenzó a actuar de manera extraña después de unos meses laborando en ese lugar. 
                        Parecía que hablaba solo o que huía de alguien. Casi no conversaba con nadie en la 
                        calle, se volvió huraño y tímido. Su cambio fue tan notable que la gente de la ciudad 
                        comentaba que había perdido la cordura, igual que su padre y muchas otras personas 
                        que se dedicaban a ese oficio.<br/><br/>
                      
                        Hasta 1869 se supo la razón de este padecimiento que afectaba solamente a los
                        fabricantes de sombreros. La famosa sustancia naranja tenía un ingrediente llamado 
                        <em> nitrato mercúrico</em>, que soltaba gases tóxicos de mercurio cuando se calentaba, los 
                        cuales causaban una enfermedad llamada <em>hidrargiria</em> al ser respirados. Este metal 
                        puede envenenar a la gente que tiene contacto con él continuamente, tal como 
                        sucedía con quienes tocaban el fieltro todo el tiempo. Francia prohibió el uso de la 
                        sustancia naranja cerca de 1898 para proteger a los empleados, pero en muchos 
                        países no fue así sino hasta años después.<br/><br/> 
                        
                        Estos famosos personajes dementes no fueron inventados en el cuento de <em>Alicia en el 
                        país de las maravillas.</em> Dicho mal era tan común antes que la gente decía frases como 
                        “está tan loco como un sombrerero” para referirse a alguien que había perdido la razón.
                        
                      </p>
                    </Grid>
                    <Grid container justifyContent="center" xs={12}>
                        <img className="Imagen2" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img3ro/Sesion44/2.png" alt="Tarjeta" />
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container xs={12}>
                      <p className="Lectura">

                        Ver a alguien con sombrero es poco común en la actualidad. El mercurio ya no se 
                        utiliza para crearlos, pero sigue usándose en distintas actividades industriales. Por 
                        ejemplo, en la manufactura de objetos como los termómetros, que empleamos para 
                        medir la temperatura corporal cuando tenemos una enfermedad.
                        
                      </p>
                    </Grid>
                </Grid>
            
        </Grid>
    </Grid>
    );
}
 
export default El_sombrerero_loco ;
