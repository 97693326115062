import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
    ContenedorLectura:{
        width:'502px',
                  [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
    },
    ContainerTitulo:{
        margin:'0 0 34px 0',
        '& .Titulo':{
            letterSpacing: '0px',
            color: '#926DD1',
            margin:'0',
            textAlign:'center',
            font:'43px fredoka one',
            lineHeight:'52px',
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        position:'relative',
        margin:' 0 0 36px 0',
        '& .Lectura':{
            margin:'0',
            color:'#000',
            font:'18px lato',
            lineHeight :'28px',
            letterSpacing: '0.17px',
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .Lectura2':{
            margin:'0',
            color:'#000',
            font:'18px lato',
            lineHeight :'28px',
            letterSpacing: '0.17px',
            [theme.breakpoints.down(502)]:{
                font:'13px lato',
                lineHeight :'18px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'11px lato',
                lineHeight :'12px',
                letterSpacing: '0.09px',
            },
        },

        '& .SubTitulo1':{
            color:'#FFFFFF',
            font:'27px Fredoka One',
            lineHeight:'32px',
            [theme.breakpoints.down(502)]:{
                font:'18px Fredoka One',
                lineHeight:'23px',
            },
            [theme.breakpoints.down(350)]:{
                font:'16px Fredoka One',
                lineHeight:'18px',
            },
        },

        '& .SubTitulo2':{
            color:'#FFFFFF',
            font:'39px Fredoka One',
            lineHeight:'47px',
            [theme.breakpoints.down(502)]:{
                font:'23px Fredoka One',
                lineHeight:'28px',
            },
            [theme.breakpoints.down(350)]:{
                font:'20px Fredoka One',
                lineHeight:'24px',
              },
        },

        '& .SubTitulo3':{
            color:'#FFFFFF',
            font:'33px Fredoka One',
            lineHeight:'40px',
            [theme.breakpoints.down(502)]:{
                font:'20px Fredoka One',
                lineHeight:'24px',
            },
            [theme.breakpoints.down(350)]:{
                font:'18px Fredoka One',
                lineHeight:'20px',
            },
        },

        '& .SubTitulo4':{
            color:'#FFFFFF',
            font:'41px Fredoka One',
            lineHeight:'50px',
            [theme.breakpoints.down(502)]:{
                font:'26px Fredoka One',
                lineHeight:'30px',
            },
            [theme.breakpoints.down(350)]:{
              font:'22px Fredoka One',
              lineHeight:'28px',
            },
        },

        '& .SubTitulo5':{
            color:'#FFFFFF',
            font:'53px Fredoka One',
            lineHeight:'65px',
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'31px',
            },
            [theme.breakpoints.down(350)]:{
              font:'26px fredoka one',
              lineHeight:'20px',
            },
        },

        '& .Arte1':{
            position:'absolute',
            top:'95px',
            left:'35px',
            [theme.breakpoints.down(502)]: {
                top: "90px",
                left: "20px",
              },
              [theme.breakpoints.down(350)]: {
                top: "77px",
                left: "18px",
              },
        },
        '& .Arte2':{
            position:'absolute',
            top:'450px',
            left:'350px',
            [theme.breakpoints.down(502)]: {
                top: "330px",
                left: "226px",
              },
              [theme.breakpoints.down(350)]: {
                top: "279px",
                left: "196px",
              },
        },
        '& .Arte3':{
            position:'absolute',
            top:'841px',
            left:'66px',
            [theme.breakpoints.down(502)]: {
                top: "580px",
                left: "37px",
              },
              [theme.breakpoints.down(350)]: {
                top: '499px',
                left: '31px',
              },
        },
        '& .Arte4':{
            position:'absolute',
            top:'1185px',
            left:'300px',
            [theme.breakpoints.down(502)]: {
                top: "785px",
                left: "197px",
            },
            [theme.breakpoints.down(350)]: {
                top: '679px',
                left: '169px',
            },
        },
        '& .Arte5':{
            position:'absolute',
            top:'1485px',
            left:'80px',
            [theme.breakpoints.down(502)]: {
                top: "980px",
                left: "45px",
            },
            [theme.breakpoints.down(350)]: {
                top: '847px',
                left: '40px',
            },
        },
        '& .Arte6':{
            position:'absolute',
            top:'1865px',
            left:'330px',
            [theme.breakpoints.down(502)]: {
                top: "1213px",
                left: "201px",
              },
              [theme.breakpoints.down(350)]: {
                top: '1052px',
                left: '177px',
              },
        },
        '& .Arte7':{
            position:'absolute',
            top:'2284px',
            left:'95px',
            [theme.breakpoints.down(502)]: {
                top: "1475px",
                left: "54px",
              },
              [theme.breakpoints.down(350)]: {
                top: '1285px',
                left: '49px',
              },

        },

        '& .ArtLec1':{
            position:'absolute',
            width:'218px',
            top:'192px',
            left:'53px',
            [theme.breakpoints.down(502)]:{
                width:'143px',
                top:'155px',
                left:'43px',
            },
            [theme.breakpoints.down(350)]: {
                top: '140px',
                left: '23px',
                width: '137px',
            },
        },

        '& .ArtLec2':{
            position:'absolute',
            width:'218px',
            top:'589px',
            left:'288px',
            [theme.breakpoints.down(502)]:{
                top: '405px',
                left: '178px',
                width: '145px',
            },
            [theme.breakpoints.down(350)]: {
                top: '352px',
                left: '160px',
                width: '118px',
            },
        },

        '& .ArtLec3':{
            position:'absolute',
            top: '932px',
            left: '45px',
            width: '166px',
            [theme.breakpoints.down(502)]:{
                top: '636px',
                left: '20px',
                width: '118px',
            },
            [theme.breakpoints.down(350)]: {
                top: '560px',
                left: '20px',
                width: '88px',
            },
        },

        '& .ArtLec4':{
            position:'absolute',
            top: '1295px',
            left: '291px',
            width: '215px',
            [theme.breakpoints.down(502)]:{
                top: '865px',
                left: '182px',
                width: '155px',
            },
            [theme.breakpoints.down(350)]: {
                top: '740px',
                left: '159px',
                width: '121px',
              },
        },

        '& .ArtLec5':{
            position:'absolute',
            top: '1600px',
            left: '68px',
            width: '213px',
            [theme.breakpoints.down(502)]:{
                top: '1060px',
                left: '35px',
                width: '152px',
            },
            [theme.breakpoints.down(350)]: {
                top: '915px',
                left: '29px',
                width: '122px',
            },
            
        },

        '& .ArtLec6':{
            position:'absolute',
            top: '1980px',
            left: '327px',
            width: '196px',
            [theme.breakpoints.down(502)]:{
                top: '1300px',
                left: '190px',
                width: '137px',
            },
            [theme.breakpoints.down(350)]: {
                top: '1127px',
                left: '165px',
                width: '110px',
            },
        },

        '& .ArtLec7':{
            position:'absolute',
            top: '2430px',
            left: '60px',
            width: '248px',
            [theme.breakpoints.down(502)]:{
                top: '1572px',
                left: '26px',
                width: '169px',
            },
            [theme.breakpoints.down(350)]: {
                top: '1356px',
                left: '25px',
                width: '140px',
              },
        },

        '& .TextImg':{
            color:'#717476',
            margin:'24px 0 0 0',
            font:' 16px lato',
            lineHeight :'25px',
            letterSpacing: '0.15px',
            [theme.breakpoints.down(502)]:{
                font:' 11px lato',
                lineHeight :'14px',
                letterSpacing: '0.10px',
            },
            [theme.breakpoints.down(502)]: {
                font: " 14px lato",
                lineHeight: "21px",
                letterSpacing: "0.15px",
              },
              [theme.breakpoints.down(350)]: {
                font: " 10px lato",
                lineHeight: "21px",
                letterSpacing: "0.15px",
              },
        },
        '& .Pos1':{
            position:'absolute',
            top:'365px',
            left:'355px',
            [theme.breakpoints.down(502)]:{
                top:'260px',
                left:'210px',
            },
            [theme.breakpoints.down(350)]: {
                top: "216px",
                left: "210px",
            },
        },
        '& .Pos2':{
            position:'absolute',
            top:'785px',
            left:'105px',
            [theme.breakpoints.down(502)]:{
                top:'522px',
                left:'70px',
            },
            [theme.breakpoints.down(350)]: {
                top: '444px',
                left: '51px',
            },
        },
        '& .Pos3':{
            position:'absolute',
            top:'1100px',
            left:'380px',
            [theme.breakpoints.down(502)]:{
                top:'720px',
                left:'228px',
            },
            [theme.breakpoints.down(350)]: {
                top: '619px',
                left: '195px',
            },
        },
        '& .Pos4':{
            position:'absolute',
            top:'1400px',
            left:'110px',
            [theme.breakpoints.down(502)]:{
                top:'925px',
                left:'68px',
            },
            [theme.breakpoints.down(350)]: {
                top: '794px',
                left: '56px',
            },
        },
        '& .Pos5':{
            position:'absolute',
            top:'1770px',
            left:'435px',
            [theme.breakpoints.down(502)]:{
                top:'1150px',
                left:'258px',
            },
            [theme.breakpoints.down(350)]: {
                top: '988px',
                left: '220px',
            },
        },
        '& .Pos6':{
            position:'absolute',
            top:'2180px',
            left:'115px',
            [theme.breakpoints.down(502)]:{
                top:'1410px',
                left:'68px',
            },
            [theme.breakpoints.down(350)]: {
                top: '1215px',
                left: '52px',
            },
        },
        '& .Pos7':{
            position:'absolute',
            top:'2595px',
            left:'450px',
            [theme.breakpoints.down(502)]:{
                top:'1660px',
                left:'260px',
            },
            [theme.breakpoints.down(350)]: {
                top: '1433px',
                left: '222px',
            },
        },

        '& .Imagen':{
            margin:'0 0 20px 0',
            [theme.breakpoints.down(502)]:{
                width:'100%'
            },
        },
        '& .Imagen3':{
            margin:'0 0 35px 0',
            [theme.breakpoints.down(502)]:{
                width:'100%'
            },
        },



      
    }
}));

const Las_siete_Bellas_Artes = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >
                        Las siete <br/>Bellas Artes
                    </h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                        La división de las artes proviene de la cultura griega. Con el paso de los años, esta 
                        clasificación de las artes ha cambiado y ahora tenemos siete Bellas Artes. ¿Las 
                        conoces?
                        </p>
                        <img className="Imagen " src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img1ro/Sesion56/1R.png" alt="Tarjeta" />
                        <img className="Imagen " src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img1ro/Sesion56/2R.png" alt="Tarjeta" />
                        <img className="Imagen  Imagen3" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img1ro/Sesion56/3R.png" alt="Tarjeta" />
                        <img className="Imagen " src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img1ro/Sesion56/4R.png" alt="Tarjeta" />
                        <img className="Imagen " src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img1ro/Sesion56/5R.png" alt="Tarjeta" />
                        <img className="Imagen " src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img1ro/Sesion56/6R.png" alt="Tarjeta" />
                        <img className="Imagen " src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img1ro/Sesion56/7R.png" alt="Tarjeta" />

                        <h3 className="SubTitulo1 Arte1">
                            Arquitectura
                        </h3>
                        <h3 className="SubTitulo2 Arte2">
                            Danza
                        </h3>
                        <h3 className="SubTitulo1 Arte3">
                            Escultura
                        </h3>
                        <h3 className="SubTitulo3 Arte4">
                            Pintura
                        </h3>
                        <h3 className="SubTitulo4 Arte5">
                            Música
                        </h3>
                        <h3 className="SubTitulo2 Arte6">
                            Literatura
                        </h3>
                        <h3 className="SubTitulo5 Arte7">
                            Cine
                        </h3>

                        <p className='Lectura2 ArtLec1'>
                            Este arte consiste en diseñar casas, edificios 
                            y otros espacios como parques y jardines. Su 
                            objetivo es llenar de belleza una ciudad.
                        </p>

                        <p className='Lectura2 ArtLec2'>
                            Este arte emplea el cuerpo humano y el ritmo 
                            musical como una forma de expresión. Los bailarines 
                            comunican emociones y sensaciones.
                        </p>

                        <p className='Lectura2 ArtLec3'>
                            Los artistas de esta 
                            disciplina crean piezas en 
                            tres dimensiones con una 
                            variedad de materiales.
                        </p>

                        <p className='Lectura2 ArtLec4'>
                            En este arte, tintas y 
                            pigmentos se aplican sobre 
                            lienzos y otras superficies 
                            para crear escenas, 
                            personajes o paisajes.
                        </p>

                        <p className='Lectura2 ArtLec5'>
                            Mediante diversos instrumentos, 
                            este arte crea ritmos y melodías que 
                            provocan diferentes sensaciones. 
                            ¡Desde música para bailar en tu 
                            cuarto hasta una ópera!
                        </p>

                        <p className='Lectura2 ArtLec6'>
                            En este arte, los escritores usan 
                            el lenguaje para crear cuentos, 
                            poesía o ensayos. A través de 
                            sus palabras, nos hacen sentir.
                        </p>

                        <p className='Lectura2 ArtLec7'>
                            En esta disciplina se mezclan 
                            fotografía, literatura, teatro y 
                            música para contar historias que 
                            entretengan y capten la atención 
                            de un espectador.
                        </p>

                        <p className="TextImg Pos1">
                            Arquitectura
                        </p>
                        <p className="TextImg Pos2">
                            Danza
                        </p>
                        <p className="TextImg Pos3">
                            Escultura
                        </p>
                        <p className="TextImg Pos4">
                            Pintura
                        </p>
                        <p className="TextImg Pos5">
                            Música
                        </p>
                        <p className="TextImg Pos6">
                            Literatura
                        </p>
                        <p className="TextImg Pos7">
                            Cine
                        </p>


                    </Grid>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <p className="Lectura">
                    Ahora que conoces la clasificación de las Bellas Artes, ¡puedes ir y crear tu propia obra maestra!
                    </p>
                </Grid>
        </Grid>
    </Grid>
    );
}
 
export default Las_siete_Bellas_Artes ;
