import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
    ContenedorLectura:{
        width:'502px',
                  [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
    },
    ContainerTitulo:{
        margin:'0 0 46px 0',
        '& .Titulo':{
            letterSpacing: '0px',
            color: '#F6644B',
            margin:'0',
            textAlign:'center',
            font:'43px fredoka one',
            lineHeight:'52px',
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            margin:'0 0 36px 0 ',
            color:'#000',
            font:'18px lato',
            lineHeight :'28px',
            letterSpacing: '0.17px',
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .SubTitulo':{
            color:'#00ADB6',
            margin:'0',
            font:'bold 22px lato',
            lineHeight :'24px',
            letterSpacing: '0.21px',
            [theme.breakpoints.down(502)]:{
                font:'bold 18px lato',
                lineHeight :'20px',
                letterSpacing: '0.16px',
            },
        },
        '& .Cuadro':{
            position:'relative',
        },
        '& .Esp':{
            position:'absolute',
            margin:'53px 0 0 0',
            width:'85.25%',
            [theme.breakpoints.down(502)]:{
                margin:'25px 0 0 0',
                lineHeight :'17px!important',
            },
        },
        
        '& .Imagen':{
            width:'100%',
        },
    },
    

}));

const Hablar_con_emojis = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >
                        Hablar con emojis
                    </h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                            En los últimos años internet y las redes sociales como Facebook, Instagram, Twitter, 
                            TikTok y WhatsApp han cambiado nuestra escritura y, por lo tanto, nuestra forma de 
                            comunicación. Una de las particularidades que han traído consigo es el uso de los 
                            llamados <em>emojis</em>. Hay diversas opiniones respecto a ellos. A algunas personas les 
                            agrada formar frases con estos símbolos, pues los reconocen como útiles o 
                            atractivos. En cambio a otras les disgusta verlos en los mensajes que envían o 
                            reciben, porque prefieren sólo el texto.<br/><br/> 
                            
                            Se trata de íconos que representan seres, ideas, objetos, lugares o estados de ánimo.
                            El término proviene de la palabra japonesa <em>emoji</em>, conformada por <em>e</em>, que significa 
                            “dibujo”, y <em>moji</em>, que quiere decir “carácter”: <em>dibujo del carácter</em>. Antes de que 
                            surgieran, cuando los teléfonos móviles apenas estaban comenzando a actualizarse 
                            como dispositivos inteligentes, las personas escribían con emoticones. Es muy 
                            probable que los adultos que te rodean usaran en aquel entonces las letras de su 
                            teclado para crear caras como :D, xD, :) y o.O. ¿Te has fijado si alguno de ellos aún los 
                            usa para comunicarse? Pregúntales a tus conocidos y familiares si los recuerdan. 
                            ¡Quizá hasta te enseñen otros!
                        </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img3ro/Sesion30/1.png" alt="Tarjeta" />
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                            Pero ¿sabías que los seres humanos han escrito así desde la época de las cavernas? 
                            Cuando no existía internet ni los dispositivos inteligentes. Hoy los conocemos como 
                            <em> jeroglíficos</em>.<br/><br/> 
                            
                            <h3 className='SubTitulo'>Jeroglíficos antiguos</h3><br/>
                            
                            Las primeras civilizaciones empleaban jeroglíficos para representar ideas o palabras 
                            con dibujos cuando la humanidad comenzó a utilizar la escritura para comunicarse. 
                            En la actualidad empleamos nuevamente imágenes y símbolos en nuestros mensajes 
                            escritos. <br/><br/>
                            
                            Imagina que alguna cultura como los antiguos mayas hubiera dominado el mundo, y 
                            en lugar de los <em>emojis</em> empleáramos glifos en nuestros celulares, computadoras y 
                            tabletas. Los textos que intercambias con tus amigos y conocidos lucirían más o 
                            menos así:
                        </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img3ro/Sesion30/2.png"  alt="Tarjeta" />
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                        La imagen anterior ilustra glifos mayas. Éstos son más complejos porque cada uno 
                        representaba una sílaba o una palabra. No son representaciones de estados de 
                        ánimo, como la pereza [🥱], la vergüenza [😥] o el enojo [😡].
                        </p>
                    </Grid>
                    <Grid container justifyContent="center" className='Cuadro' sm={12}>
                        <img className="Imagen " src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img3ro/Sesion30/3R.png" alt="Tarjeta" />
                        <p className='Lectura Esp'>
                            Un estudio reveló que nuestro cerebro reacciona igual al ver un <em>emoji</em> y un 
                            rostro humano. Por eso se han vuelto tan populares: porque son muy útiles y 
                            prácticos para transmitir un mensaje.
                        </p>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                            Hoy somos capaces de identificar alrededor de 800 tipos de <em>emojis</em> diferentes. 
                            Nuestros ojos los miran con frecuencia y los reconocen rápidamente, incluso con más 
                            velocidad que las señales de tránsito o los anuncios publicitarios. <br/><br/>
                            
                            Estamos experimentando un cambio comunicativo. Los <em>emojis</em> están construyendo un 
                            nuevo idioma junto con nuestras palabras, el lenguaje de la era digital. ¿Cuáles 
                            recuerdas tú? ¿Con cuántos puedes formar un mensaje? ¿Con qué frecuencia los usas 
                            y en qué situaciones?
                        </p>
                    </Grid>
                </Grid>
        </Grid>
    </Grid>
    );
}
 
export default Hablar_con_emojis;
