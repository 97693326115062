import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
    ContenedorLectura:{
        height:'100%',
        width:'502px',
                  [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
    },
    ContainerTitulo:{
        margin:'0 0 34px 0',
        '& .Titulo':{
            letterSpacing: '0px',
            color: '#6DCFF6',
            margin:'0',
            textAlign:'center',
            font:'43px fredoka one',
            lineHeight:'52px',
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            margin:'0 0 36px 0',
            color:'#000',
            font:'18px lato',
            lineHeight :'28px',
            letterSpacing: '0.17px',
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .TextImg':{
            color:'#717476',
            margin:'24px 0 0 0',
            font:' 16px lato',
            lineHeight :'25px',
            letterSpacing: '0.15px',
            [theme.breakpoints.down(502)]:{
                font:' 11px lato',
                lineHeight :'14px',
                letterSpacing: '0.10px',
            },
        },

        '& .Imagen':{
            width:'100%',
            
        },  
      
    }
}));

const El_viaje_de_la_papa = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                        <h2 className="Titulo">El viaje de la papa</h2>

                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                       <p className='Lectura'>
                        ¿Te gustan las papas fritas? ¿Qué tal el puré de papa o la sopa de papa? Pues no eres 
                        el único al que le gustan, ya que la papa es el tubérculo más cultivado y consumido en 
                        el planeta.
                       </p>                   
                    </Grid>
                    <Grid container justifyContent='center'>
                        <Grid container item justifyContent='center'>
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img1ro/Sesion11/1.png" alt="Tarjeta" />
                        </Grid>
                        <Grid container justifyContent='center' >
                            <p className="TextImg">
                            Este mapa muestra cómo la papa viajó alrededor del mundo.
                            </p>
                        </Grid>
                    </Grid>
                    
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container >
                        <p className='Lectura'>
                            ¿Sabías que los dos países donde más se cultivan papas son China e India? Pero su 
                            origen está lejos de esta parte del mundo. El lugar de nacimiento de este delicioso 
                            tubérculo es América. La antigua cultura inca ya cultivaba la papa blanca como parte 
                            de su alimentación diaria. Fue a partir del contacto con los españoles que éstos 
                            llevaron la papa a Europa. <br/><br/>
                            
                            En un principio los europeos no consideraban la papa como un alimento para seres 
                            humanos y se la daban a los animales. Pero poco a poco se fueron dando cuenta de lo 
                            nutritiva que es y de lo fácil que resulta cultivarla. Después de ganar popularidad en 
                            ese continente, la papa fue llevada al resto del mundo. Incluso fue traída de regreso a 
                            América a lugares donde no se conocía anteriormente, como Estados Unidos y 
                            Canadá.                        
                        </p>
                    </Grid>
                    <Grid container justifyContent='center'>
                        <Grid container justifyContent='center'>
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img1ro/Sesion11/2.png" alt="Tarjeta" />
                        </Grid>
                        <Grid container justifyContent='center' >
                            <p className="TextImg">
                                Hoy en día, desde la Patagonia hasta Corea, podemos encontrar diversos platillos hechos con papas. ¿Cuál es tu manera favorita de comer papas?                          
                            </p>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
    </Grid>
    );
}
 
export default El_viaje_de_la_papa;