import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
     Contenedor:{
        background:'#E9FBF9',
        width:'100vw',
        overflowX:'clip',
        [theme.breakpoints.down(1161)]:{
            background:'none',
           overflowX:'unset',
        },
        [theme.breakpoints.down(600)]:{
            marginTop: '79.51px',
        }
    },
  ContainerInfoLectura: {
    position: "relative",
    height: "86px",
    [theme.breakpoints.down(502)]:{
        height: "43px",
    },
    background: "#76D4C3",
    "& .BarraInfoLectura": {
      height: "86px",
      background: `Url(https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Barras/Azul.png)`,
        [theme.breakpoints.down(502)]:{
            height: "43px",
        },
    },
    "& .InfoLectura": {
      position: "absolute",
      height: "86px",
      width: "219px",
      boxSizing: "border-box",
      padding: "17.2px 0",
      background: "#FAFAFA",
      borderRadius: "50px",
      boxShadow: "2px 2px 2px 2px #00000029",
      zIndex: "4",
        [theme.breakpoints.down(502)]:{
            height: "43px",
            width: "109px",
            padding: "8.6px 0",
            borderRadius: "25px",
        },
    },
    "& .InfoLectura:before": {
      position: "absolute",
      display: "flex",
      boxSizing: "border-box",
      padding: "0 0 0 10px",
      justifyContent: "center",
      alignItems: "center",
      height: "39.88px",
      width: "39.88px",
      borderRadius: "50%",
      content: '""',
      boxShadow: "3px 0px .5px 0px #00000029",
      background: "#FAFAFA",
      left: "90px",
      top: "65px",
      transform: "rotate(0.25turn)",
      [theme.breakpoints.down(502)]:{
        left: "45px",
        top: "30px",
        height: "20.88px",
        width: "20.88px",
        },
    },
    "& .InfoLectura:after": {
      content: '""',
      height: "15px",
      width: "15px",
      borderTop: "2px solid #20A698",
      borderLeft: "2px solid #20A698",
      position: "absolute",
      bottom: "-10px",
      left: "Calc(50% - 8px)",
      transform: "rotate(0.625turn)",
      [theme.breakpoints.down(502)]:{
        height: "10px",
        width: "10px",
        left: "Calc(50% - 4.5px)",
        bottom: "-3px",
        borderTop: "1px solid #20A698",
        borderLeft: "1px solid #20A698",
        },
    },
    "& .Sesion": {
      borderLeft: "2px solid #D8D8D8",
    },
    "& .Dato": {
      margin: "0 0 5px 0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#20A698",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        margin: "0 0 2.5px 0",
        },
    },
    "& .Caracter": {
      margin: "0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#000000",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        },
    },   },
    ContenedorLectura:{
        position:'relative',
        background:'#fff',
        width:'1160px',
        height:'100%',
        padding:'60px 168px 35px ',
        [theme.breakpoints.down(1161)]:{
            width:'502px',
            padding:'60px 0 0 0',
          },
        [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
        '& .ContainerDerechos':{
            bottom:'80px',
            [theme.breakpoints.down(1161)]:{
                display:'none',
            },
        },
        '& .Derechos':{
            textAlign:"center",
            margin:"0px",
            font:" 15px lato",
            color:"#717476",
        },
        '& .Escritorio':{
            display:'block',
            [theme.breakpoints.down(1161)]:{
                display:'none'
            },
        },
        '& .Tablet':{
            display:'none',
            [theme.breakpoints.down(1161)]:{
                display:'block'
            },
        }
    },
    
    ContainerTitulo:{
        margin:'0 0 46px 0',
        '& .Titulo':{
            letterSpacing: '0px',
            color: '#0899D9',
            margin:'0',
            textAlign:'center',
            font:'48px fredoka one',
            [theme.breakpoints.down(1161)]:{
                font:'43px fredoka one',
                lineHeight:'52px',
            },
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            margin:'0 0 36px 0',
            color:'#000',
            font:'22px lato',
            lineHeight : '30px',
            letterSpacing: '0.22px',
            [theme.breakpoints.down(1161)]:{
                font:'18px lato',
                lineHeight :'28px',
                letterSpacing: '0.17px',
            },
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },

        '& .Esp1':{
            margin:0,
        },
        '& .Esp':{
            margin:0,
            boxSizing:'border-box',
            padding:'0 57px 0 0',
            [theme.breakpoints.down(1161)]:{
                padding:'0 ',
                margin:'0 0 36px 0',
            },
        },
        '& .TextImg':{
            margin:'11px 0 0 0',
            color:'#717476',
            font:'20px lato',
            lineHeight :'30px',
            letterSpacing: '0.19px',
            [theme.breakpoints.down(1161)]:{
                font:' 16px lato',
                lineHeight :'20px',
                letterSpacing: '0.15px',
            },
            [theme.breakpoints.down(502)]:{
                font:' 14px lato',
                lineHeight :'18px',
                letterSpacing: '0.10px',

            },
        },
        '& .Cuadro':{
            position:'relative',
        },
        '& .Pos':{
            position:'absolute',
            margin:'53px 0 0 0',
            width:'750px',
            [theme.breakpoints.down(1161)]:{
                margin:'40px 0 0 0',
                width:'90.24%'
            },
            [theme.breakpoints.down(502)]:{
                margin:'30px 0 0 0',

            }
        },
        '& .Imagen':{
            [theme.breakpoints.down(1161)]:{
                width:'100%'
            },
        },
        '& .Imagen2':{
            [theme.breakpoints.down(1161)]:{
                width:'100%'
            },
            [theme.breakpoints.down(502)]:{
                height:'175px'
            }
        },

    },
    

}));

const El_mundo_que_queremos_dejar = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
             <Grid container item justifyContent="center" className={classes.ContainerInfoLectura}>
               <Grid container className="BarraInfoLectura"></Grid>
               <Grid container item className="InfoLectura">
                    <Grid container item direction="column" xs={6} justifyContent="center">
                        <p className="Dato">Grado</p>
                        <p className="Caracter">4</p>
                    </Grid>
                    <Grid container item direction="column" xs={6} justifyContent="center" className="Sesion">
                        <p className="Dato">Sesión</p>
                        <p className="Caracter">28</p>
                    </Grid>
               </Grid>
            </Grid>

            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >
                        El mundo<br className='Tablet'/> que queremos dejar
                    </h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container justifyContent="center" xs={12}>
                        <p className='Lectura Esp1'>
                            ¿Alguna vez te has preguntado cómo será el mundo en el futuro? No en uno tan 
                            lejano como doscientos años, sino en sólo diez o quince. ¿Qué tipos de avances 
                            tecnológicos te imaginas que habrá? ¿Crees que los seres humanos podrán vivir en 
                            Marte o teletransportarse de un lugar a otro? ¿Cómo nos comunicaremos? ¿Y cuál 
                            será la situación del medio ambiente?
                        </p>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container md={12} lg={7}>
                        <p className="Lectura Esp">
                            Nos encontramos en un momento decisivo 
                            para la vida en el planeta. Esto se debe a 
                            que nuestras habilidades sociales y 
                            emocionales no se han desarrollado tan 
                            rápido como los avances tecnológicos. De 
                            hecho, en algunos sentidos hemos 
                            permitido que el mundo sea un lugar poco 
                            armónico y no tan limpio.<br/><br/> 
                            
                            La raza humana ha transformado su 
                            entorno para adaptarlo a sus necesidades. 
                            Se han cubierto de asfalto lugares 
                            naturales para que los autos se desplacen 
                            fácilmente. Bosques enteros se talan para 
                            construir muebles, cuadernos y libros. El 
                            agua y el aire están contaminados por las 
                            industrias y los medios de transporte. 
                            Además, cuando consumimos productos de 
                            forma desmedida favorecemos la 
                            desigualdad social y contribuimos a la 
                            extinción de varias especies.
                        </p>
                    </Grid>
                    <Grid container justifyContent='center' md={12} lg={5}>
                        <Grid container item justifyContent='center' xs={12}>
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img4to/Sesion28/1.png" alt="Tarjeta" />
                        </Grid>
                        <Grid container justifyContent='center' xs={12}>
                            <p className='TextImg'>Hoy en día, las ciudades son el lugar idóneo para comenzar a cambiar nuestros hábitos de consumo y reducir la contaminación.</p>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container xs={12} >
                        <p className="Lectura">
                            Pero las nuevas generaciones se han vuelto más conscientes del impacto que nuestra 
                            especie ocasiona en el planeta. Ha aumentado lentamente la sensibilidad de proteger 
                            los recursos naturales y mejorar nuestra relación con el mundo y los animales. Dos 
                            ejemplos de ello son las jóvenes Greta Thunberg y Malalah Yusafzay. Greta ha 
                            defendido el medio ambiente desde que tenía 15 años, y Malalah es una luchadora 
                            social que ha apoyado el derecho a estudiar de las niñas en el Medio Oriente, 
                            también a la misma edad. Ellas levantaron la voz y representan a toda una generación 
                            que anhela hacer las cosas de mejor manera.<br/><br/>
                            
                            Para miles de personas alrededor del mundo es necesario trabajar desde hoy por el 
                            futuro, uno en el que todos los que existimos y aquellos que vivirán después puedan 
                            gozar de una vida digna, plena y llena de oportunidades. El tiempo de actuar es 
                            ahora. Mahatma Gandhi fue una de las personas más importantes en la 
                            independencia de la India. Él decía lo siguiente: “Sé el cambio que quieres ver en el
                            mundo”. Es una invitación a tomar medidas y a empezar a hacer las cosas que nos 
                            parecen importantes para cambiar la situación del mundo.
                        </p>
                    </Grid>
                    <Grid container justifyContent='center' xs={12} >
                        <Grid container item justifyContent='center' xs={12}>
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img4to/Sesion28/2.png" alt="Tarjeta" />
                        </Grid>
                        <Grid container justifyContent='center' xs={12}>
                            <p className='TextImg'>Está en nuestras manos decidir el futuro que queremos tener y el mundo que queremos dejar a las futuras generaciones.</p>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container xs={12} >
                        <p className="Lectura">
                            Lee este fragmento de la canción <em>Hoy hace un buen día</em>, escrita por el mexicano 
                            Fernando Delgadillo; en ella podrás vislumbrar una visión del futuro que podemos 
                            lograr:
                        </p>
                    </Grid>
                    <Grid container justifyContent='center' className='Cuadro' xs={12} >
                        <img className="Imagen Escritorio" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img4to/Sesion28/3.png" alt="Tarjeta" />
                        <img className="Imagen2 Tablet" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img4to/Sesion28/3R.png" alt="Tarjeta" />
                        <p className='Pos Lectura Escritorio'>
                            “Y si ellos miran hacia atrás de lo que les toca empezar<br/> 
                            y nos hallan a nosotros que formamos un lugar<br/> 
                            que un buen día nos marcharemos y tal vez podrán decir<br/> 
                            en verdad que fueron grandes los viajeros que cruzaron por aquí.”<br/>
                        </p>
                        <p className='Pos Lectura Tablet'>
                            “Y si ellos miran hacia atrás de lo que les toca empezar 
                            y nos hallan a nosotros que formamos un lugar 
                            que un buen día nos marcharemos y tal vez podrán decir 
                            en verdad que fueron grandes los viajeros que cruzaron por aquí.”
                        </p>

                    </Grid>
                </Grid>

                <Grid container item xs={12} justifyContent="center" className="ContainerDerechos">
                    <p className="Derechos">
                        © 2022. Todos los derechos reservados, Pearson, Circuito de lectores.
                    </p>                
                </Grid>
        </Grid>
    </Grid>
    );
}
 
export default El_mundo_que_queremos_dejar;
