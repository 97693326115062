import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
    ContenedorLectura:{
        width:'502px',
                  [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
    },
    ContainerTitulo:{
        margin:'0 0 34px 0',
        '& .Titulo':{
            letterSpacing: '0px',
            color: '#81A247',
            margin:'0',
            textAlign:'center',
            font:'43px fredoka one',
            lineHeight:'52px',
            [theme.breakpoints.down(1161)]:{
                font:'38px fredoka one',
                lineHeight:'52px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            margin:'0 0 36px 0',
            color:'#000',
            boxSizing:'border-box',
            font:'18px lato',
            lineHeight :'28px',
            letterSpacing: '0.17px',
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .Lectura2':{
            color:'#000000',
            font:'18px lato',
            lineHeight :'28px',
            letterSpacing: '0.17px',
            margin:'0 0 5px 0',
            [theme.breakpoints.down(502)]:{
                font:'14px lato',
                lineHeight :'17px',
                letterSpacing: '0.10px',
            },
        },
        '& .SubTitulo':{
            color:'#CF3E2A',
            margin:'0',
            lineHeight :'28px',
            font:'bold 26px lato',
            letterSpacing: '0.24px',
            [theme.breakpoints.down(502)]:{
                font:'bold 18px lato',
                lineHeight :'24px',
                letterSpacing: '0.21px',
            },
        },
        '& .SubTitulo2':{
            color:'#FFFFFF',
            margin:'0',
            textAlign:'center',
            width:'100%',
            lineHeight :'30px',
            font:' 21px Fredoka One',
            letterSpacing: '0.2px',
            [theme.breakpoints.down(502)]:{
                font:' 18px Fredoka One',
                lineHeight :'24px',
            },
        },
        '& .Cuadro':{
            margin:'0 0 21px 0'
        },
        '& .Tribu':{
            position:'relative',
            margin:'0 0 53px 0'
        },
        '& .Pos':{
            position:'absolute',
        } ,
        '& .Tribu1':{
            top:'1%',
            left:'0',
        },
        '& .Tribu2':{
            top:'33.5%',
            left:'0px',
            [theme.breakpoints.down(502)]:{
                top:'34.7%',
                left:'0px',
            },
        },
        '& .Tribu3':{
            top:'69.2%',
            left:'0px',
        },
        '& .Tribu1Ul':{
            margin:0,
            padding:'0 0 0 15px',
            width:'93%',
            top:'5.5%',
            left:'3%',
            color:'#82CBCD',
            [theme.breakpoints.down(502)]:{
                padding:'0 0 0 15px',
                width:'93%',
                top:'5%',
            },
        },
        '& .Tribu2Ul':{
            margin:0,
            padding:'0 0 0 15px',
            color:'#2DDAC9',
            width:'90%',
            top:'37.5%',
            left:'3%',
            [theme.breakpoints.down(502)]:{
                padding:'0 0 0 15px',
                top:'38%',
                width:'94%',
            },
        },
        '& .Tribu3Ul':{
            margin:0,
            padding:'0 0 0 15px',
            color:'#E64E2E',
            width:'93%',
            top:'73.5%',
            left:'3%',
            [theme.breakpoints.down(502)]:{
                padding:'0 0 0 15px',
                width:'94%',
                top:'73%',

            },
            
        },

        '& .TextImg':{
            color:'#717476',
            margin:'24px 0 0 0',
            padding:'0 ',
            font:' 16px lato',
            lineHeight :'25px',
            letterSpacing: '0.15px',
            [theme.breakpoints.down(502)]:{
                font:' 11px lato',
                lineHeight :'14px',
                letterSpacing: '0.10px',
            },
        },

        '& .Imagen':{
            width:'100%',
 
        },  
        '& .Imagen2':{
            width:'100%',
            [theme.breakpoints.down(502)]:{
                height:'275px',
            },
            
        }, 
      
    }
}));

const Los_nómadas_de_la_Amazonia = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>

            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                        <h2 className="Titulo ">Los nómadas<br/> de la Amazonia</h2>

                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                       <p className='Lectura'>
                        <h3 className='SubTitulo'>Sedentarios y nómadas</h3><br/>
                        
                        La mayoría de las comunidades humanas modernas son <em>sedentarias</em>. 
                        Eso quiere decir que se establecen en un lugar determinado y 
                        allí hacen su vida. Las personas migran con cierta frecuencia (como individuos), 
                        pero los grupos completos sólo lo hacen en casos extraordinarios.<br/><br/> 

                        Asimismo, existen comunidades que no viven en un lugar fijo, sino que se 
                        mueven de un lugar a otro constantemente. En la mayoría de los casos 
                        se desplazan para obtener alimento y otros productos que les permitan 
                        sobrevivir. Los llamamos <em>nómadas</em>. <br/><br/>
                        
                        <h3 className='SubTitulo'>Tipos de nómadas</h3><br/>

                        Existen tres clases de nómadas: los de pastoreo, los cazadores-recolectores y los viajeros.
                        
                       </p>                   
                    </Grid>
                    <Grid container justifyContent='space-between' className='Tribu'>

                        <img className=' Cuadro Imagen2' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion11/5R.png" alt="Tarjeta" />
                        <img className=' Cuadro Imagen2' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion11/6R.png" alt="Tarjeta" />
                        <img className=' Imagen2' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion11/7R.png" alt="Tarjeta" />


                        <h3 className='Pos SubTitulo2 Tribu1 '>Nómadas de pastoreo</h3>
                        <h3 className='Pos SubTitulo2 Tribu2 '>Cazadores-recolectores</h3>
                        <h3 className='Pos SubTitulo2 Tribu3 '>Viajeros</h3>

                        <ul className='Pos Tribu1Ul '>
                            <li><p className='Lectura2'>Se desplazan porque buscan pasto para alimentar a sus animales.</p></li>
                            <li><p className='Lectura2'>Llegan a establecer relaciones comerciales con poblaciones sedentarias. Intercambian sus productos ganaderos por productos agrícolas u otras cosas.</p></li>
                            <li><p className='Lectura2'>Se localizan en algunas partes de Asia Oriental, África y Medio Oriente, y en algunas regiones montañosas de Sudamérica y Europa.</p></li>
                            <li><p className='Lectura2'>Existen entre 30 y 50 millones de nómadas de pastoreo.</p></li>
                        </ul>

                        <ul className='Pos Tribu2Ul '>
                            <li><p className='Lectura2'>Se mueven en busca de alimento para sí mismos.</p></li>
                            <li><p className='Lectura2'>A veces se mueven siguiendo a los animales que cazan. En otras ocasiones viajan en busca de lugares con más recursos (como plantas comestibles).</p></li>
                            <li><p className='Lectura2'>Llegan a modificar su entorno al plantar cultivos medicinales o comestibles para su consumo.</p></li>
                            <li><p className='Lectura2'>Se localizan en lugares como el Ártico, la Amazonia, Asia Oriental, la India y en el sur y el este de África.</p></li>
                            <li><p className='Lectura2'>Se calcula que hay cerca de 250 000 alrededor del mundo.</p></li>
                        </ul>

                        <ul className='Pos Tribu3Ul '>
                            <li><p className='Lectura2'>Por lo general viven mezclados con poblaciones sedentarias (lo que algunas veces genera conflictos).</p></li>
                            <li><p className='Lectura2'>Existen dos grandes grupos: los romaníes y los viajeros irlandeses.</p></li>
                            <li><p className='Lectura2'>Los romaníes son el grupo más conocido y numeroso. Se calcula que hay 12 millones en todo el mundo.</p></li>
                            <li><p className='Lectura2'>Los viajeros irlandeses se localizan principalmente en Irlanda y el Reino Unido.</p></li>
                            <li><p className='Lectura2'>Suelen vivir en casas rodantes o botes.</p></li>
                        </ul>

                    </Grid>
                    <Grid>
                        <Grid container >
                            <img className='Imagen' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion11/1.png" alt="Tarjeta" />   
                        </Grid>
                        <Grid container>
                            <p className='TextImg'>
                            Los grupos romaníes frecuentemente son llamados <em>gitanos</em>. Esta palabra viene de <em>egiptano</em>, y se les llama 
                            así porque a finales de la Edad Media y principios de la modernidad se creía que venían de Egipto.
                            </p>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container >
                        <p className='Lectura'>
                            <h3 className='SubTitulo'>Resistencia en movimiento</h3><br/>
                            
                            En América Latina existen algunas tribus nómadas, como las localizadas en la
                            Amazonia. La mayoría de ellas comparte una cultura común basada en la caza 
                            y el cultivo de huertos para autoconsumo. También hay algunos grupos que
                            no cultivan y se dedican sólo a la recolección, como los nukak, un pueblo 
                            de Colombia. Hay también algunos que son seminómadas: aunque tienen aldeas, 
                            pasan una parte del año recorriendo la selva. En esta categoría están 
                            los kayapo, de Brasil.
                        </p>
                    </Grid>
                    <Grid container justifyContent='center'>
                        <Grid container justifyContent='center'>
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion11/2.png" alt="Tarjeta" />
                        </Grid>
                        <Grid container >
                            <p className="TextImg">
                                ¿Has escuchado alguna vez sobre los nukak? Te invitamos a investigar sobre su modo de vida.
                            </p>
                        </Grid>
                    </Grid>

                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container >
                        <p className='Lectura'>
                            Estudios recientes indican que quizá algunas de las poblaciones nómadas 
                            de la Amazonia eran sedentarias, pero la llegada de los europeos al 
                            continente las obligó a internarse en la selva y a transformar su forma 
                            de vida para protegerse. Desde entonces la cultura en esa zona puede 
                            describirse como una convivencia entre las poblaciones originarias y las 
                            derivadas de la colonización. Las primeras luchan por mantener su cultura, 
                            en tanto que las segundas han intentado adaptarlas a su forma de vida.
                        </p>
                    </Grid>
                    <Grid container justifyContent='center'>
                        <Grid container justifyContent='center'>
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion11/3.png" alt="Tarjeta" />
                        </Grid>
                        <Grid container >
                            <p className="TextImg">
                                Llos kayapo han tenido que luchar para preservar la selva.
                            </p>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container >
                        <p className='Lectura'>
                            En la actualidad las poblaciones sedentarias se extienden y cada vez
                            utilizan más terreno para actividades agrícolas o industriales. Como 
                            consecuencia, los territorios habitados por las tribus nómadas se han 
                            reducido. Esto ha provocado que algunas poblaciones nómadas se integren 
                            a la vida sedentaria, pero también ha propiciado que muchas otras se 
                            involucren en la defensa del medio ambiente.
                        </p>
                    </Grid>
                    <Grid container justifyContent='center'>
                        <Grid container justifyContent='center'>
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion11/4.png" alt="Tarjeta" />
                        </Grid>
                        <Grid container >
                            <p className="TextImg">
                                La acción de las poblaciones sedentarias ha afectado terriblemente el ecosistema del Amazonas, del que dependían pueblos como los nukak y los kayapo.
                            </p>
                        </Grid>
                    </Grid>
                </Grid>
        </Grid>
    </Grid>
    );
}
 
export default Los_nómadas_de_la_Amazonia;
