import React from "react";

import ErrorBoundarieComponent from "./Component/ErrorBoundarieComponent";
class ErrorBoundary extends React.Component {
  state = {
    hasError: false,
  };

  static getDerivedStateFromError = error => {
    return { hasError: true };
  };

  componentDidCatch = (error, info) => {
    this.setState({ error, info });
  };

  render() {
    const { hasError } = this.state;
    const { children } = this.props;

    return hasError ? <ErrorBoundarieComponent>
      {children}
    </ErrorBoundarieComponent> : children;
  }
}

export default ErrorBoundary