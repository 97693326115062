import React, { Suspense, useEffect } from 'react';
import ContainerDocente from '../ContainerDocente';
import ReturnButton from './ReturnButton';
// import lectura from '../../'


const Lectura = (props) => {
  // const {grade, sesion}  = useParams()
  const grade = props.location.state.grade
  const sesion = props.location.state.sesion

  const [gradoLecturas, setGradoLecturas] = React.useState("")

  useEffect(()=>{
    switch (grade) {
      case 6:
        setGradoLecturas("Sexto")
      break;
      case 2:
        setGradoLecturas("Segundo")
      break;
      case 3:
        setGradoLecturas("Tercero")
      break;
      case 4:
        setGradoLecturas("Cuarto")
      break;
      case 5:
        setGradoLecturas("Quinto")
      break;
    
      default:
        setGradoLecturas("Primero")
        break;
    }
  },[])

  const OtherComponent = React.lazy(() =>   
  gradoLecturas && sesion &&
      // import(`../../LecturasActividad/Lecturas${gradoLecturas}/Sesion${sesion}/Lectura`)
      import(`../../LecturasBiblioteca/Lecturas${gradoLecturas}/Sesion${sesion}/Lectura`)
         
  )
 
  return ( 
      <ContainerDocente >
        <Suspense fallback={<div>Cargando...</div>}>
          {gradoLecturas 
            && sesion 
            && 
            <>
            <ReturnButton/>
            <OtherComponent style={{marginTop: "0px"}}/>
            </>}
        </Suspense>
      </ContainerDocente>
   );
}

 
export default Lectura;
