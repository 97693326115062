import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
    ContenedorLectura:{
        width:'502px',
        [theme.breakpoints.down(502)]:{
            width:'350px',
        },
    },
    ContainerTitulo:{
        margin:'0 0 34px 0',
        '& .Titulo':{
            letterSpacing: '0px',
            color: '#788FDC',
            margin:'0',
            textAlign:'center',
            font:'43px fredoka one',
            lineHeight:'52px',
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            margin:'0 0 36px 0',
            color:'#000',
            font:'18px lato',
            lineHeight :'28px',
            letterSpacing: '0.17px',
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .SubTitulo':{
            color:'#FF0000',
            margin:'0',
            lineHeight :'28px',
            font:'bold 26px lato',
            letterSpacing: '0.24px',
            [theme.breakpoints.down(502)]:{
                font:'bold 18px lato',
                lineHeight :'24px',
                letterSpacing: '0.21px',
            },
        },
        '& .Esp':{
            padding:'0 ',
            margin:'0 0 36px 0',
        },
        '& .TextImg':{
            color:'#717476',
            margin:'24px 0 0 0',
            font:' 16px lato',
            lineHeight :'25px',
            letterSpacing: '0.15px',
            [theme.breakpoints.down(502)]:{
                font:' 11px lato',
                lineHeight :'14px',
                letterSpacing: '0.10px',
            },
        },
        '& .Esp2':{
            textAlign:'center',
            width:'100%',
        },
        '& .Esqueleto':{
            justifyContent:'center',
        },
        '& .Imagen':{
            width:'90%',
        },
        '& .Imagen2':{
            height:'796px',
            [theme.breakpoints.down(502)]:{
                width:'80%',
                height:'596px',
            },
        },   
      
    }
}));

const Diseñados_para_bailar = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                        <h2 className="Titulo">Diseñados para bailar</h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                       <p className='Lectura'>
                        <h3 className='SubTitulo'>El aparato locomotor</h3><br/>
                        
                        ¡El cuerpo humano es una máquina sorprendente! Tus piernas te ayudan a correr 
                        rápido mientras que tus brazos te hacen escalar más alto. Pero, ¿te has preguntado 
                        cómo podemos movernos de un lugar a otro?
                       </p>                   
                    </Grid>
                    <Grid>
                        <Grid container justifyContent='center'>
                            <img className="Imagen " src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img1ro/Sesion17/1R.png" alt="Tarjeta" />
                        </Grid>
                        <Grid container justifyContent='center'>
                            <p className='TextImg'>
                            Nuestro cuerpo está diseñado para el movimiento, pero, ¿cómo lo hacemos?
                            </p>
                        </Grid>
                    </Grid>
                    
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container xs={12} >
                        <p className='Lectura Esp'>
                            El aparato locomotor está a cargo de 
                            realizar todos los movimientos. Está 
                            formado por huesos, músculos, 
                            tendones y ligamentos. Tus 206 huesos 
                            son firmes y le dan estructura a tu 
                            cuerpo. Además, te ayudan a moverte y 
                            le dan soporte a tus 600 músculos, que 
                            se contraen para realizar todo tipo de 
                            movimientos.<br/><br/>
                            
                            Para ayudar a los huesos y a los 
                            músculos tenemos los tendones y los 
                            ligamentos. Los tendones son tejidos 
                            muy resistentes que unen a los 
                            músculos y a los huesos, mientras que 
                            los ligamentos son tejidos que ayudan a 
                            mantener a los huesos en su lugar. <br/><br/>
                            
                            Todos estas partes trabajan en 
                            conjunto para ayudarte a disfrutar lo 
                            que está alrededor de ti: desde los 
                            pequeños huesos de tus oídos, 
                            encargados de mantenerte en 
                            equilibrio, hasta los fuertes músculos 
                            de tus piernas, que te hacen moverte al 
                            ritmo de la música. ¡El aparato 
                            locomotor nos permite estar diseñados 
                            para bailar!
                        </p>
                    </Grid>
                    <Grid container xs={12} >
                        <Grid container className='Esqueleto'>
                            <img className="Imagen2 " src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img1ro/Sesion17/2R.png" alt="Tarjeta" />
                        </Grid>
                        <Grid container item className='Esqueleto '>
                            <p className='TextImg Esp2'>
                            El aparato locomotor está conformado por los huesos y los músculos.
                            </p>
                        </Grid>
                    </Grid>
                </Grid>
        </Grid>
    </Grid>
    );
}
 
export default Diseñados_para_bailar;
