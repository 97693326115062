import { makeStyles, Button } from "@material-ui/core";
import React from "react";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  backButton: {
    position: "absolute",
    display: "flex",
    zIndex: 4,
    margin: "2%",
    color: '#F2624F',
    border: '#F2624F 1px solid',
    height: '30px',
    width:'100px',
    borderRadius: '20px',
    letterSpacing: '0.14px',
    textTransform: 'none',
    background:'#FEFAF1',
    // top:'calc(50% - 15px)',
    // left:'5%',
    // zIndex:4,
    [theme.breakpoints.down(502)]: {
      font:'bold 10px lato',
      width: "60px",
    },
    "&:hover": {
      background: "#f4f4f4",
    },
  }
}))

const ReturnButton = (props) => {
 const classes = useStyles();
 const history = useHistory()
//  const toSearch = history?.location?.state?.toSearch
 
 const returnParams = () => {
  const stateByParams = history?.location?.state
  // history.goBack
  // history
  if(stateByParams.fromLibrary){
    history.push(
      {
      pathname: "/biblioteca",
      state: stateByParams
      }
    )
  } else {
      history.goBack()
  }

  // console.log(history)
 }


  return (
    <Button className={classes.backButton} onClick={returnParams} >Regresar</Button>
  );
}
 
export default ReturnButton;