import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
    ContenedorLectura:{
        width:'502px',
                  [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
    },

    ContainerTitulo:{
        margin:'0 0 46px 0',
        '& .Titulo':{
            letterSpacing: '0px',
            color: '#20A698',
            margin:'0',
            textAlign:'center',
            font:'43px fredoka one',
            lineHeight:'52px',
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        position:'relative',
        '& .Lectura':{
            margin:'0 0 36px 0',
            color:'#000',
            font:'18px lato',
            lineHeight :'28px',
            letterSpacing: '0.17px',
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .Lectura2':{
            margin:'0',
        },
        '& .Esp':{
            margin:'0 ',
            padding:'0',
        },
        '& .Esp2':{
            margin:'0 ',
            padding:'0',
        },

        '& .SubTitulo':{
            margin:'0',
            color:'#E97BA8',
            font:'bold 22px lato',
            lineHeight :'24px',
            letterSpacing: '0.21px',
            [theme.breakpoints.down(502)]:{
                font:'bold 18px lato',
                lineHeight :'20px',
                letterSpacing: '0.16px',
            },
        },
        '& .SubTitulo2':{
            margin:'0',
            color:'#823CE2',
            font: "bold 22px lato",
            lineHeight: "24px",
            letterSpacing: "0.21px",
            [theme.breakpoints.down(502)]: {
                font: "bold 18px lato",
                lineHeight: "20px",
                letterSpacing: "0.16px",
            },
        },
       '& .TextImg':{
            margin:'24px 0 0 0',
            alignSelf:'flex-start',
            color:'#717476',
            font:' 16px lato',
            lineHeight :'25px',
            letterSpacing: '0.15px',
            [theme.breakpoints.down(502)]:{
                font:' 11px lato',
                lineHeight :'14px',
                letterSpacing: '0.10px',
            },
        },
        '& .Imagen1':{ 
            margin:'0 0 36px 0',
            [theme.breakpoints.down(502)]: {
                width:'100%',
            },
        },
        '& .Imagen2':{
            width:'100%',
        },
        '& .Imagen3':{
            width:'489px',
            [theme.breakpoints.down(502)]: {
                width:'100%',
            },
        },
        '& .Imagen4':{
            width:'88.65%',
        },
    
    },
    

}));

const Hay_belleza_en_la_naturaleza = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >
                        ¿Hay belleza <br />en la naturaleza?
                    </h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura Lectura2">
                            <h3 className='SubTitulo'>La idea de lo bello</h3><br/>

                             Los diccionarios definen lo <em>bello</em> como alguien o algo que resalta por su hermosura. 
                             Pero no explican por qué o cómo se puede identificar si alguien o algo es bello o no. <br/><br/>

                             En realidad, no existe una manera precisa de medir la belleza, pues depende de quien 
                             lo ve: lo que para algunas personas resulta hermoso, para otras podría no serlo. La 
                             percepción depende de las vivencias y preferencias de cada uno.<br/><br/> 
                             
                             Pero existen ciertas características que a las personas en general les parecen 
                             agradables. Una de ellas es la <em>simetría</em>.
                        </p>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container xs={12} >
                        <p className="Lectura Esp">
                            <h3 className='SubTitulo2 '>¿Qué es la simetría?</h3><br/>

                            Un objeto se define como <em>simétrico </em> 
                            cuando hay una correspondencia exacta 
                            en la forma, el tamaño y la posición de 
                            sus partes a partir del centro. Por
                            ejemplo, si observas el rostro de un león 
                            e imaginas que una línea punteada lo 
                            atraviesa a la mitad, notarás que el lado 
                            derecho es simétrico con el izquierdo.
                            
                        </p>
                    </Grid>
                    <Grid container xs={12} >
                        <Grid container justifyContent="center" >
                            <img className="Imagen1" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img2do/Sesion35/1R.png"  alt="Tarjeta" />
                            Observa ahora el rostro de un tigre.
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <img className="Imagen2" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img2do/Sesion35/2.png"  alt="Tarjeta" />
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container xs={12}>
                        <p className="Lectura Esp2">
                            Las manchas de su lado izquierdo no 
                            corresponden simétricamente con las del 
                            derecho. Entonces, ¿es o no es bello? 
                            Discútelo con las personas a tu 
                            alrededor. <br/><br/>
                            
                            La respuesta suele ser que sí lo es. Así, 
                            comprobamos que la belleza se define 
                            por diversos factores. <br/><br/>
                            
                            Otra característica que resulta 
                            placentera para los sentidos es la 
                            cualidad que tienen algunos objetos
                            naturales de formar fractales. Los 
                            <em> fractales</em> son formas geométricas que se 
                            repiten constantemente con diferentes 
                            tamaños. Por ejemplo, un brócoli 
                            romanesco que muestra sus partes con 
                            un patrón en espiral.
                        </p>
                    </Grid>
                    <Grid container  xs={12} >
                        <Grid container justifyContent="center" >
                            <img className="Imagen3" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img2do/Sesion35/3.png"  alt="Tarjeta" />
                        </Grid>
                        <Grid container justifyContent="center">
                            <p className='TextImg' > 
                                El brócoli romanesco es una variedad<br className='Escritorio'/>
                                 de coliflor italiana.
                            </p>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                            Finalmente, según los expertos existe una propiedad de la belleza llamada <em>proporción </em> 
                            <em>áurea</em>, una relación matemática entre las partes de un objeto. Se representa con un 
                            rectángulo que contiene distintas figuras geométricas. La forma que tiene el 
                            caparazón de un caracol se asemeja a la representación de la proporción áurea.
                        </p>
                    </Grid>
                    <Grid container  sm={12}>
                        <Grid container justifyContent="center" >
                            <img className="Imagen4" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img2do/Sesion35/4.png"  alt="Tarjeta" />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                            Es probable que algo que te gusta tenga por lo menos una de estas características, 
                            aunque no lo notes a simple vista. <br/><br/>
                            
                            Después de este repaso, ¿cuál es tu conclusión? ¿Consideras que hay belleza en la 
                            naturaleza?
                        </p>
                    </Grid>
                </Grid>
        </Grid>
    </Grid>
    );
}
 
export default Hay_belleza_en_la_naturaleza;
