import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
      Contenedor:{
        background:'#E9FBF9',
        width:'100vw',
        overflowX:'clip',
        [theme.breakpoints.down(1161)]:{
            background:'none',
           overflowX:'unset',
        },
        [theme.breakpoints.down(600)]:{
            marginTop: '79.51px',
        }
    },
    ContainerInfoLectura:{
        position:'relative',
        height:'86px',
        background:'#76D4C3',
        '& .BarraInfoLectura':{
            height:'86px',
            background:`Url(https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Barras/Verde.png)`,
        },
        '& .InfoLectura':{
            position:'absolute',
            height:'86px',
            width:'219px',
            boxSizing:'border-box',
            padding:'17.2px 0',
            background:'#FAFAFA',
            borderRadius:'50px',
            boxShadow: '2px 2px 2px 2px #00000029',
            zIndex:'4',
        },
        '& .InfoLectura:before':{
            position:'absolute',
            display:'flex',
            boxSizing:'border-box',
            padding:'0 0 0 10px',
            justifyContent:'center',
            alignItems:'center',
            height:'39.88px',
            width:'39.88px',
            borderRadius:'50%',
            content:'""',
            boxShadow: '3px 0px .5px 0px #00000029',
            background:'#FAFAFA',
            left:'90px',
            top:'65px',
            transform: 'rotate(0.25turn)',
        },
        '& .InfoLectura:after':{
            content:'""',
            height:'15px',
            width:'15px',
            borderTop:'2px solid #20A698',
            borderLeft:'2px solid #20A698',
            position:'absolute',
            bottom:'-10px',
            left:'Calc(50% - 8px)',
            transform: 'rotate(0.625turn)',
        },
        '& .Sesion':{
            borderLeft:'2px solid #D8D8D8',
        },
        '& .Dato':{
            margin:'0 0 5px 0',
            textAlign:'center',
            font:'bold 17px lato',
            color:'#20A698',
        },
        '& .Caracter':{
            margin:'0',
            textAlign:'center',
            font:'bold 17px lato',
            color:'#000000',
        },
    },
    ContenedorLectura:{
        background:'#fff',
        width:'1160px',
        height:'100%',
        padding:'57px 168px 0' ,
        [theme.breakpoints.down(1161)]:{
            width:'502px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
        '& .ContainerDerechos':{
            bottom:'80px',
            [theme.breakpoints.down(1161)]:{
                display:'none',
            }
        },
        '& .Derechos':{
            textAlign:"center",
            margin:"0px",
            font:" 15px lato",
            color:"#717476",
        },
        '& .Escritorio':{
            display:'block',
            [theme.breakpoints.down(1161)]:{
                display:'none'
            },
        },
        '& .Tablet':{
            display:'none',
            [theme.breakpoints.down(1161)]:{
                display:'block'
            },
        },
    },

    ContainerTitulo:{
        margin:'0 0 45px 0',
        [theme.breakpoints.down(1161)]:{
            margin:'0 0 38px 0',
        },
        '& .Titulo':{
            font:'48px fredoka one',
            letterSpacing: '0px',
            color: '#E51333',
            margin:'0',
            textAlign:'center',
            [theme.breakpoints.down(1161)]:{
                font:'43px fredoka one',
                lineHeight:'52px',
                textAlign:'center',
            },
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
  
    Parrafo1:{
        margin:' 0 0 46px 0',
        '& .Lectura':{
            lineHeight : '30px',
            margin:'0 0 36px 0 ',
            font:'22px lato',
            color:'#000',
            letterSpacing: '0.20px',
            [theme.breakpoints.down(1161)]:{
                font:'18px lato',
                letterSpacing:'.17px',
                lineHeight:'28px',
            },
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .Lectura2':{
            padding:'0 27px',
            boxSizing:'border-box'
        },
        '& .Lectura3':{
            margin:'0 ',
        },
        '& .Container':{
            position:'relative',
            background:'#FDFAF0',
            boxSizing:'border-box',
        },
        '& .TextImg':{
            margin:'24px 0 134px 0',
            font:'20px lato',
            alignSelf:'flex-start',
            color:'#717476',
            letterSpacing:'.19px',
            boxSizing:'border-box',
            padding:'0 0 0 27px',
            [theme.breakpoints.down(1161)]:{
                font:'16px lato',
                letterSpacing:'.15px',
                lineHeight:'25px',
            },
            [theme.breakpoints.down(502)]:{
                font:' 11px lato',
                lineHeight :'14px',
                letterSpacing: '0.10px',
            },
        },
        '& .SubTitulo':{
            padding:'4px 0',
            boxSizing:'border-box',
            margin:'0 0 36px 0',
            height:'69px',
            width:'100%',
            font:'30px Fredoka One',
            color:'#FFFFFF',
            letterSpacing:'.28px',
            lineHeight:'30px',
            textAlign:'center',
            [theme.breakpoints.down(1161)]:{
                font:'26px Fredoka One',
                letterSpacing:'.24px',
                lineHeight:'24px',
            },
            [theme.breakpoints.down(502)]:{
                font:'22px Fredoka One',
                lineHeight:'24px',
            },
            [theme.breakpoints.down(350)]:{
                font:'19px Fredoka One',
                lineHeight:'24px',
            },
        },
        '& .Morado':{
            background:'#926DD1',
            [theme.breakpoints.down(1161)]:{
                height:'61px',
            },
        },
        '& .Azul':{
            background:'#0CB5D6',
            [theme.breakpoints.down(1161)]:{
                height:'61px',

            },
        },
        '& .Verde':{
            background:'#20A698',
            [theme.breakpoints.down(1161)]:{
                height:'91px',
                padding:'8px 0',
            },
        },
        '& .Naranja':{
            background:'#F6664D',
            [theme.breakpoints.down(1161)]:{
                height:'61px',
            },
        },
        '& .Rosa':{
            background:'#DF5DA7',
            [theme.breakpoints.down(1161)]:{
                height:'61px',
            },
        },
        '& .Imagen':{
            [theme.breakpoints.down(1161)]:{
                width:'100%',
            },
        }

    },
    Especial:{
        margin:' 0 ',
    },

}));

const Mujeres_artistas_latinoamericanas = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
             <Grid container item justifyContent="center" className={classes.ContainerInfoLectura}>
               <Grid container className="BarraInfoLectura"></Grid>
               <Grid container item className="InfoLectura">
                    <Grid container item direction="column" xs={6} justifyContent="center">
                        <p className="Dato">Grado</p>
                        <p className="Caracter">6</p>
                    </Grid>
                    <Grid container item direction="column" xs={6} justifyContent="center" className="Sesion">
                        <p className="Dato">Sesión</p>
                        <p className="Caracter">46</p>
                    </Grid>
               </Grid>
            </Grid>

            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >
                        Mujeres artistas latinoamericanas
                    </h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura Lectura3">
                            Si ahora te preguntaran por artistas reconocidos, seguramente recordarías por lo 
                            menos alguno famoso. Entre pintores, escultores o músicos que han aportado al 
                            mundo los estilos y técnicas que han marcado la historia del arte, quizá pensarías en 
                            Da Vinci, Picasso, Miguel Ángel o Beethoven, por mencionar algunos. <br/><br/>
                            
                            Ya sea que los tengas presentes en la memoria o no, esos personajes mundialmente 
                            reconocidos son quienes han sobresalido frente al resto. Todos ellos, así como la 
                            mayoría de los más famosos, tienen dos características comunes: son hombres y 
                            europeos. <br/><br/>
                            
                            Lo anterior no es negativo, su genialidad sostiene su fama. Pero es conveniente que 
                            sepas que ha habido bastantes más personas, sobre todo a partir del siglo <span style={{fontVariant: "all-small-caps"}}>XX</span>, que 
 
                            han irrumpido en el escenario artístico con méritos propios, pero que se salen del 
                            molde tradicional. Por ello, en este texto conocerás algunas artistas mujeres 
                            latinoamericanas que se encuentran en el mismo nivel que los más grandes de la 
                            historia el arte.
                        </p>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={`${classes.Parrafo1} ${classes.Especial}`} justifyContent="center">
                    <Grid container className='Container' sm={12}>
                        <h3 className='SubTitulo Morado'>María Izquierdo <br/> Pintora (México, 1902–1955)</h3>

                        <p className='Lectura Lectura2'>Nació en Jalisco, lejos de la capital mexicana, en un ambiente campirano 
                        rodeada de naturaleza. Por ese motivo, su obra retrata las costumbres, colores 
                        y formas de ver el mundo de los habitantes de las zonas rurales del país. <br/><br/>
                        
                        Desde los 25 años, ingresó en la Escuela Nacional de Bellas Artes para estudiar 
                        pintura. Ahí mostró un talento extraordinario, motivo por el cual se convirtió en 
                        la primera mujer mexicana en tener una exhibición fuera del país. Desarrolló 
                        gran parte de su carrera en una época en la que el arte mexicano tendía a ser 
                        nacionalista, es decir que buscaba describir lo mexicano.</p>

                        <h3 className='SubTitulo Azul'>Tarsila do Amaral <br/>Pintora (Brasil, 1886–1973)</h3>

                        <p className='Lectura Lectura2'>Nació en Capivari, una pequeña ciudad perteneciente a São Paulo, en una 
                        familia acomodada. A los 16 años, viajó a Europa y permaneció en un internado 
                        de Barcelona. Gracias a los aprendizajes que le brindó su estancia, 
                        posteriormente encabezó un movimiento artístico en Brasil. <br/><br/>
                        
                        Su obra junto con la de sus compañeros de generación revolucionó el arte 
                        sudamericano, combinando la influencia europea con temas tradicionales
                        brasileños, como contrastes de color y figuras geométricas. <br/><br/>
                        
                        Después de su muerte, el Museo de Arte Moderno de Nueva York le dedicó una 
                        exposición, con la que se convirtió en la primera mujer latinoamericana en 
                        conseguirlo.</p>

                        <h3 className='SubTitulo Verde Escritorio'>Raquel Forner <br/>Pintora y escultora (Argentina, 1902-1988)</h3>
                        <h3 className='SubTitulo Verde Tablet'>Raquel Forner <br/>Pintora y escultora<br/> (Argentina, 1902-1988)</h3>


                        <p className='Lectura Lectura2'>Nació en la capital argentina, Buenos Aires, pero a los 12 años viajó a España.
                        Allí cursó sus estudios básicos antes de regresar a Argentina para inscribirse en 
                        la Academia Nacional de Bellas Artes. Posteriormente, en ese lugar se convirtió 
                        en profesora de dibujo a sus escasos 20 años. <br/><br/>
                        
                        Durante sus primeros años, sobresalió por adoptar con maestría el estilo 
                        expresionista europeo. Éste consiste en que las pinturas mezclan emociones
                        con símbolos e intensidad de colores.<br/><br/>
                        
                        A lo largo de su vida, regresó viarias veces a Europa para continuar 
                        aprendiendo mientras ganaba reconocimiento internacional. Un año antes de 
                        morir, recibió la medalla de oro en la Exposición Internacional de París, presea 
                        que muy pocas mujeres latinoamericanas han recibido.</p>

                        <h3 className='SubTitulo Naranja'>Lily Garafulic <br/>Escultora (Chile, 1914–2012)</h3>

                        <p className='Lectura Lectura2'>Nació en la ciudad chilena Antofagasta, pero después se mudó a la capital 
                        chilena, donde estudió en la Escuela de Bellas Artes de la Universidad de Chile. 
                        También se formó profesionalmente en Nueva York y Europa. Incluso, en Italia 
                        se convirtió en profesora de mosaico, que es una técnica europea. <br/><br/>
                        
                        Desde su graduación universitaria, comenzó a diseñar esculturas que 
                        desafiaban las formas comunes, cualidad que la convirtió en una gran 
                        exponente de la escultura abstracta en Sudamérica. Dedicó gran parte de sus 
                        últimos años de vida a la difusión del arte desde organismos internacionales,
                        como la <span style={{fontVariant: "all-small-caps"}}>UNESCO</span>.</p>


                        <h3 className='SubTitulo Rosa'>Débora Arango <br/>Pintora (Colombia, 1907–2005)</h3>

                        <p className='Lectura Lectura2'>Nació en la ciudad de Medellín. Desde pequeña, supo que se dedicaría al arte. 
                        En su juventud pintaba cuadros con una fuerte crítica social hacia su país y 
                        hacia las ideologías latinoamericanas, sobre todo aquellas que trataban sobre 
                        el papel de las mujeres en la vida diaria. <br/><br/>
                        
                        Su espíritu rebelde y revolucionario fue la característica más importante de su 
                        obra, por lo que muchas veces fue excluida de museos e instituciones. Del 
                        mismo modo que la argentina Raquel Forner, fue una representante del 
                        expresionismo y su obra se caracterizó por el uso de la acuarela.</p>
                        
                        <Grid container  sm={12}>
                            <Grid container>
                                <img  className='Imagen' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion46/1.png"  alt="Tarjeta" />
                            </Grid>
                            <Grid>
                                <p className='TextImg'>Las mujeres latinoamericanas han sido fundamentales para el desarrollo artístico del continente.</p>
                            </Grid>
                        </Grid>
                        <Grid container item xs={12} justifyContent="center" className="ContainerDerechos">
                            <p className="Derechos">
                                © 2022. Todos los derechos reservados, Pearson, Circuito de lectores.
                            </p>                
                        </Grid>
                    </Grid>
                  
                </Grid>
        </Grid>
    </Grid>
    );
}
 
export default Mujeres_artistas_latinoamericanas;
