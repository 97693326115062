import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
     Contenedor:{
        background:'#E9FBF9',
        width:'100vw',
        overflowX:'clip',
        [theme.breakpoints.down(1161)]:{
            background:'none',
           overflowX:'unset',
        },
        [theme.breakpoints.down(600)]:{
            marginTop: '79.51px',
        }
    },
  ContainerInfoLectura: {
    position: "relative",
    height: "86px",
    [theme.breakpoints.down(502)]:{
        height: "43px",
    },
    background: "#76D4C3",
    "& .BarraInfoLectura": {
      height: "86px",
      background: `Url(https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Barras/Azul.png)`,
        [theme.breakpoints.down(502)]:{
            height: "43px",
        },
    },
    "& .InfoLectura": {
      position: "absolute",
      height: "86px",
      width: "219px",
      boxSizing: "border-box",
      padding: "17.2px 0",
      background: "#FAFAFA",
      borderRadius: "50px",
      boxShadow: "2px 2px 2px 2px #00000029",
      zIndex: "4",
        [theme.breakpoints.down(502)]:{
            height: "43px",
            width: "109px",
            padding: "8.6px 0",
            borderRadius: "25px",
        },
    },
    "& .InfoLectura:before": {
      position: "absolute",
      display: "flex",
      boxSizing: "border-box",
      padding: "0 0 0 10px",
      justifyContent: "center",
      alignItems: "center",
      height: "39.88px",
      width: "39.88px",
      borderRadius: "50%",
      content: '""',
      boxShadow: "3px 0px .5px 0px #00000029",
      background: "#FAFAFA",
      left: "90px",
      top: "65px",
      transform: "rotate(0.25turn)",
      [theme.breakpoints.down(502)]:{
        left: "45px",
        top: "30px",
        height: "20.88px",
        width: "20.88px",
        },
    },
    "& .InfoLectura:after": {
      content: '""',
      height: "15px",
      width: "15px",
      borderTop: "2px solid #20A698",
      borderLeft: "2px solid #20A698",
      position: "absolute",
      bottom: "-10px",
      left: "Calc(50% - 8px)",
      transform: "rotate(0.625turn)",
      [theme.breakpoints.down(502)]:{
        height: "10px",
        width: "10px",
        left: "Calc(50% - 4.5px)",
        bottom: "-3px",
        borderTop: "1px solid #20A698",
        borderLeft: "1px solid #20A698",
        },
    },
    "& .Sesion": {
      borderLeft: "2px solid #D8D8D8",
    },
    "& .Dato": {
      margin: "0 0 5px 0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#20A698",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        margin: "0 0 2.5px 0",
        },
    },
    "& .Caracter": {
      margin: "0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#000000",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        },
    },   },
    ContenedorLectura:{
        background:'#fff',
        width:'1160px',
        height:'100%',
        padding:'60px 168px 35px ',
        [theme.breakpoints.down(1161)]:{
            width:'502px',
            padding:'60px 0 0 0',
          },
        [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
        '& .ContainerDerechos':{
            bottom:'80px',
            [theme.breakpoints.down(1161)]:{
                display:'none',
            },
        },
        '& .Derechos':{
            textAlign:"center",
            margin:"0px",
            font:" 15px lato",
            color:"#717476",
        },
        '& .Escritorio':{
            display:'block',
            [theme.breakpoints.down(1161)]:{
                display:'none'
            },
        },
        '& .Tablet':{
            display:'none',
            [theme.breakpoints.down(1161)]:{
                display:'block'
            },
        }
    },
    ContainerTitulo:{
        margin:'0 0 34px 0',
        [theme.breakpoints.down(1161)]:{
            margin:'0 0 34px 0',
        },
        '& .Titulo':{
            letterSpacing: '0px',
            color: '#FC5E9E',
            margin:'0',
            textAlign:'center',
            font:'48px fredoka one',
            lineHeight:'58px',
            [theme.breakpoints.down(1161)]:{
                font:'43px fredoka one',
                lineHeight:'52px',
            },
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            margin:'0 0 36px 0',
            color:'#000',
            font:'22px lato',
            lineHeight : '30px',
            letterSpacing: '0.20px',
            [theme.breakpoints.down(1161)]:{
                font:'18px lato',
                lineHeight :'28px',
                letterSpacing: '0.17px',
            },
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },

        "& .SubTitulo": {
            color: "#087CF0",
            margin: "0",
            font: "35px Fredoka One",
            letterSpacing: ".33px",
            lineHeight: "30px",
            [theme.breakpoints.down(1161)]: {
              lineHeight: "28px",
              font: "30px Fredoka One",
              letterSpacing: "0.28px",
            },
            [theme.breakpoints.down(502)]: {
              font: "20px Fredoka One",
              lineHeight: "22px",
              letterSpacing: "0.21px",
            },
        },
        "& .SubTitulo2": {
            color: "#FC5E9E",
            margin: "0",
            font: "bold 30px lato",
            letterSpacing: ".28px",
            lineHeight: "30px",
            [theme.breakpoints.down(1161)]: {
              lineHeight: "28px",
              font: "bold 26px lato",
              letterSpacing: "0.24px",
            },
            [theme.breakpoints.down(502)]: {
              font: "bold 22px lato",
              lineHeight: "24px",
              letterSpacing: "0.21px",
            },
        },
        "& .SubTitulo3": {
            color: "#087CF0",
            margin: "0",
            font: "bold 26px lato",
            letterSpacing: ".24px",
            lineHeight: "28px",
            [theme.breakpoints.down(1161)]: {
              font: "bold 22px lato",
              lineHeight: "24px",
              letterSpacing: "0.21px",
            },
            [theme.breakpoints.down(502)]: {
              font: "bold 18px lato",
              lineHeight: "20px",
              letterSpacing: "0.16px",
            },
        },
        '& .Cuadro':{
            position:'relative',
        },
        '& .Esp':{
            position:'absolute',
            margin:'53px 0 0 0',
            width:'750px',
            [theme.breakpoints.down(1161)]: {
                width:'85.25%',
            },
            [theme.breakpoints.down(502)]: {
                margin:'35px 0 0 0',
            },
        },
        '& .Imagen':{
            [theme.breakpoints.down(1161)]:{
                width:'100%',
            },
        }, 
        '& .Imagen2':{
            [theme.breakpoints.down(502)]:{
                width:'100%',
                height:'450px',
            },
        }, 
      
      
    }
}));

const El_primer_alimento_de_nuestra_vida_la_leche = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
             <Grid container item justifyContent="center" className={classes.ContainerInfoLectura}>
               <Grid container className="BarraInfoLectura"></Grid>
               <Grid container item className="InfoLectura">
                    <Grid container item direction="column" xs={6} justifyContent="center">
                        <p className="Dato">Grado</p>
                        <p className="Caracter">3</p>
                    </Grid>
                    <Grid container item direction="column" xs={6} justifyContent="center" className="Sesion">
                        <p className="Dato">Sesión</p>
                        <p className="Caracter">47</p>
                    </Grid>
               </Grid>
            </Grid>

            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >
                    El primer alimento<br className='Escritorio'/> de<br className='Tablet'/> nuestra vida: la leche                    
                    </h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                      <p className="Lectura">
                        ¿Sabías que la leche es el primer alimento que consumen los mamíferos? De hecho, 
                        los mamíferos son llamados así porque las hembras tienen glándulas mamarias que 
                        producen comida para sus crías. Ejemplos de ello son las vacas, los perros, los 
                        caballos y demás especies en las que las madres se encargan de proveer toda la 
                        nutrición durante las primeras semanas de vida.<br/><br/> 
                        
                        Los bebés humanos la necesitan porque su aparato digestivo aún tiene que 
                        desarrollarse para procesar otro tipo de ingesta. Ésta es la única sustancia que les 
                        ayuda a crecer y contiene vitaminas que los protegen contra enfermedades y 
                        alergias. Es fundamental que la reciban para sobrevivir. <br/><br/>
                        
                        Se considera una bebida esencial en la infancia, porque tiene altas concentraciones 
                        de calcio, vitaminas, minerales y proteínas. Gracias a ello, fortalece los huesos y los 
                        dientes. También es una fuente de energía para hacer actividades diarias, como ir a la 
                        escuela y jugar.
                      </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img3ro/Sesion47/1.png" alt="Tarjeta" />
                    </Grid>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container item justifyContent='center' sm={12} className="Cuadro">
                        <img className="Imagen2 Escritorio" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img3ro/Sesion47/3.png" alt="Tarjeta" />
                        <img className="Imagen2 Tablet" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img3ro/Sesion47/3R.png" alt="Tarjeta" />
                        <p className='Lectura Esp'>
                            <h3 className='SubTitulo'>¿Desde hace cuánto se bebe?</h3><br/>

                            ¿Te has preguntado cómo se conseguía antes si no había tiendas ni 
                            supermercados? La leche de vaca empezó a ser consumida por los seres 
                            humanos hace 11 000 años. Muchas culturas aprendieron a domesticar el 
                            ganado y por ello se volvió popular, tanto así que ahora lo más común es 
                            cambiar la leche materna por la de vaca cuando crecemos. Su uso se 
                            generalizó gracias a que era fácil de conseguir. Sólo hacía falta ordeñar una 
                            vaca para tener alimento, incluso cuando escaseaban la carne y los cereales. 
                            Actualmente es envasada y procesada en grandes fábricas, para que dure
                            más tiempo en el refrigerador.
                        </p>
                    </Grid>
                </Grid>
                
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                      <p className="Lectura">
                        <h3 className='SubTitulo2'>¿Todos pueden tomarla?</h3><br/>
                        
                        Casi todas las personas la consumen en su dieta diaria. Pero se ha demostrado que 
                        no es esencial para la gente adulta. El cuerpo deja de procesar los nutrientes cuando 
                        envejece. Por esta razón muchos individuos se vuelven intolerantes a la lactosa y 
                        sufren alergias a ciertos componentes que inflaman el estómago y los intestinos.<br/><br/> 
                        
                        <h3 className='SubTitulo3'>¿Sabías que existen otros tipos?</h3><br/>
                        
                        Todos los beneficios que tiene la leche se encuentran en otros productos que el 
                        cuerpo adulto digiere mejor. Hoy en día existen muchas opciones para sustituirla con 
                        resultados parecidos. En el mercado actual se venden opciones de soya, almendra, 
                        coco o arroz. ¡Incluso existe una creada en laboratorios! <br/><br/>
                        
                        Se necesita mucho ganado para producir las cantidades que se consumen en el 
                        mundo. Los animales necesitan hierba, maíz y trigo para comer, lo que genera 
                        pérdida de vegetación. Su consumo se ha convertido en un problema ambiental, 
                        además de que provoca dolor a estos animales porque son ordeñados 
                        constantemente. Por ello, la ciencia está trabajando en una leche que no dañe al 
                        planeta. ¿La probarías?
                      </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img3ro/Sesion47/2.png" alt="Tarjeta" />
                    </Grid>
                </Grid>
                <Grid container item xs={12} justifyContent="center" className="ContainerDerechos">
                    <p className="Derechos">
                        © 2022. Todos los derechos reservados, Pearson, Circuito de lectores.
                    </p>                
                </Grid>
        </Grid>
    </Grid>
    );
}
 
export default El_primer_alimento_de_nuestra_vida_la_leche ;
