import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
    ContenedorLectura:{
        background:'#fff',
        height:'100%',
        width:'502px',
        [theme.breakpoints.down(502)]:{
        width:'350px',
        },
    },
    ContainerTitulo:{
        margin:'0 0 34px 0',
        '& .Titulo':{
            font:'43px fredoka one',
            letterSpacing: '0px',
            lineHeight:'52px',
            color: '#FFBD50',
            margin:'0',
            textAlign:'center',
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            lineHeight : '28px',
            margin:'0 0 36px 0',
            font:'18px lato',
            color:'#000',
            letterSpacing: '0.17px',
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .Esp':{
            padding:'0',
            margin:'0 0 36px 0',
        },
        '& .SubTitulo':{
            font:'bold 26px lato',
            color:'#926DD1',
            letterSpacing: '0.24px',
            lineHeight :'28px',
            margin:'0',
            [theme.breakpoints.down(502)]:{
                font:'bold 18px lato',
                lineHeight :'24px',
                letterSpacing: '0.21px',
            },
        },
        '& .SubTitulo2':{
            color:'#1DA698',
            margin:'0',
            font:'bold 22px lato',
            lineHeight :'24px',
            letterSpacing: '0.21px',
            [theme.breakpoints.down(502)]:{
                font:'bold 18px lato',
                lineHeight :'20px',
                letterSpacing: '0.16px',
            },
        },
        '& .TextImg':{
            color:'#717476',
            margin:'21px 0 0 0',
            font:' 16px lato',
            lineHeight :'25px',
            letterSpacing: '0.15px',
            [theme.breakpoints.down(502)]:{
                font:' 11px lato',
                lineHeight :'14px',
                letterSpacing: '0.10px',
            },
        },

        '& .Imagen1':{
            width:'100%',
        },  
        '& .Imagen':{
            width:'100%',
        },  
      
    }
}));

const De_qué_está_hecho_el_universo = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                        <h2 className="Titulo">¿De qué está hecho el universo?</h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container xs={12} alignContent="flex-end">
                       <p className='Lectura Esp'>
                        Hace millones de años hubo una gran 
                        explosión que formó el universo. Fue 
                        tan grande que expulsó toda la 
                        materia necesaria para formar los 
                        planetas y las estrellas.<br/><br/> 
                        
                        El Sol, la Luna, cada piedra, los 
                        animales y hasta los seres humanos 
                        estamos hechos de las mismas 
                        partículas que se produjeron en esa 
                        explosión. Pero, si todo viene de ahí, 
                        entonces ¿por qué el metal es tan 
                        diferente de la madera?
                       </p>                   
                    </Grid>
                    <Grid container item xs={12}  justifyContent='center'>
                        <Grid item justifyContent='center' >
                            <img className="Imagen " src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img1ro/Sesion4/2.png" alt="Tarjeta" />
                        </Grid>
                        <Grid item justifyContent='center'>
                            <p className='TextImg'>
                                Los elementos están presentes en todo lo que está a tu alrededor.
                            </p>
                        </Grid>

                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container >
                        <p className='Lectura'>
                        Las partículas que fueron lanzadas en esa explosión se llaman <em>átomos</em> y son tan 
                        pequeños que no podemos verlos a simple vista. Los átomos son la pieza principal de 
                        todos los materiales que conocemos y son diferentes en cada uno de ellos.<br/><br/> 
                        
                        En el mundo se conocen 118 tipos de átomos, es decir, 118 materiales diferentes. 
                        Estos materiales se llaman <em>elementos</em> y están organizados en un instrumento llamado 
                        <em> Tabla periódica</em>.<br/><br/> 
                        
                        Algunos de los elementos más conocidos son el oro, la plata y el oxígeno. Algunos 
                        son metales, otros son gases y otros pocos son tan extraños que se llaman <em>tierras 
                        raras</em>. Estos bloques componen todo lo que hay en el universo. Aunque ahora te 
                        pueden parecer muchos, es posible que todavía nos falten elementos por descubrir.
                        </p>
                    </Grid>
                </Grid>
            </Grid>
    </Grid>
    );
}
 
export default De_qué_está_hecho_el_universo;
