import React, {useContext, useEffect}                        from "react";
import { Button,makeStyles,Grid, Hidden}    from "@material-ui/core";
import { Link, useParams }                     from "react-router-dom";
// import Heraldo                      from "./Heraldos/Heraldo2.png";
import DiagnosticaHeraldo           from "../img/HeraldosG2/01_Heraldo_G2_Evaluacion_diagnostica.png";
import axios                        from "../../../../services/axios";
import ModalPoints                  from '../ModalPoints';
import ShowingBadge                  from '../ShowingBadge';
import badge1                        from '../../badges/images/segundo/CL_G2_insignia_evaluacion_diagnostica.png';

import types from "../../../../types/types";
import AuthContext from "../../../../auth/AuthContext";

const useStyles = makeStyles(theme =>({
  mainContainer: {
    height:'100%',
    
  },
  ContainerIzquierdo:{
    // position:'relative',
    background:'radial-gradient(circle, rgba(255,230,35,0.11246505438112742) 11%, rgba(200,105,205,0.20210090872286413) 53%)',
    height:'100%',
    maxHeight:"1024",
    width:'97%',
    [theme.breakpoints.down(1280)]:{
      width:'100%',
    },
  },
  ContainerEvaluacion:{
    minHeight:'92vh',
    padding: '8% 0 8% 10%',
    // position:'relative',
    [theme.breakpoints.down(1440)]:{
      padding: '5%',
    },
  },
  ContainerBarraInferior:{
    // position:'absolute',
    // bottom:'0px',
    background:'#823CE2',
    height:'8vh',
    [theme.breakpoints.down(850)]:{
      background: 'linear-gradient(0deg, #D3348F 0%, #D3368E 3%, #D74A87 46%, #5825AD 100%)',
    },
  },
  ContainerBarraDerecha:{
    background: 'linear-gradient(0deg, #D3348F 0%, #D3368E 3%, #D74A87 46%, #5825AD 100%)',
    width:'3%',
    height:'100vh',
    [theme.breakpoints.down(1280)]:{
      display:'none',
    },
  },
  ContainerTextoTitulo:{
    // width: '466px',
    [theme.breakpoints.down(600)]:{
      // width: '415px',
      marginTop: '70px'
    },
    '& .Titulo':{
      font:'45px Fredoka One',
      color:'#E35F95',
      margin:'0',
      [theme.breakpoints.down(1501)]:{
        font:'40px Fredoka One',
      },
      [theme.breakpoints.down(1001)]:{
        font:'35px Fredoka One',
      },
      [theme.breakpoints.down(600)]:{
        font:'30px Fredoka One',
      },
    },
  },
  ContainerTextoResultado:{
    // position:'absolute',
    // top:'275px',
    // left:'0px',
    width: '570px',
    height: '101px',
    [theme.breakpoints.down(620)]:{
      width: 'auto',
      margin: "7px",
    },
    '& .Resultado':{
      font:'22px lato',
      color:'#4B4A4E',
      letterSpacing: '0.21px',
      margin:'0',
      textAlign:'center',
      [theme.breakpoints.down(850)]:{
        font:'18px lato',
      },
    }
  },
  ContainerResultado:{
    position: 'relative',
    width: '283px',
    height: '153px',
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    boxShadow: '0px 2px 5px #00000029',
    border:' 3px solid #FBE6B9',
    borderRadius: '20px',
    
    '& .TextoPuntaje':{
      position:'absolute',
      top:'18.75px',
      textAlign:'center',
      height:'30px',
      font:'25px lato',
      color:'#1DA698',
      margin:'0',
    },
    '& .Divisor':{
      position:'absolute',
      top:'61.36px',
      width: '179px',
      height: '0px',
      border: '3px solid #FBE6B9',

    },
    '& .ResultadoPuntaje':{
      position:'absolute',
      top:'80.36px',
      textAlign: 'center',
      font: '900 45px Lato',
      letterSpacing: '0px',
      color: '#DE7495',
      margin:'0',
    },

  },
  ContainerHeraldo:{
    justifyContent: "flex-start",
    [theme.breakpoints.down(960)]:{
      display: 'none'
    },
    // position:'absolute',
    // top:'5px',
    // right:'0px',
    // width:'415px',
    // height:'522px',
    // [theme.breakpoints.down(1441)]:{
    //   maxHeight:'396px',
    // },
    '& .Heraldo':{
      // paddingRight:'20%',
      height:'100%',
      // width:'36',
    }
  },
  ContainerHeraldoImage: {
    width:'415px',
    height:'522px',
    // height:'100%',
    [theme.breakpoints.down(1440)]:{
      width:'315px',
      height:'422px',
    },
    [theme.breakpoints.down(960)]:{
      width:'265px',
      height:'372px',
    },
  },
  ContainerHeraldoHiddenImage: {
    marginTop: "20px",
    width:'auto',
    height:'312px',
    '& .Heraldo':{
      // paddingRight:'20%',
      height: "100%",
      // width:'36',
    }
  },

  link:{
    [theme.breakpoints.down(960)]:{
      marginTop: "40px", 
    },
    textDecoration:'none',
  },
  BotonContinuar:{
    textTransform: 'none',
    height:'58px',
    width:'261px',  
    background: '#7A54BA',
    boxShadow: '0px 3px 6px #00000029',
    borderRadius: '61px',
    color: '#FFFFFF',
    font: 'bold 20px lato ',
    '&:hover':{
        background: '#dd78fb',
      },
  },



}));

const FinalResultadoBuentrabajo = () => {
  const classes = useStyles()
  const { actividad, intento, preguntas} = useParams()
  const [finalScore, setFinalScore] =     React.useState(0)
  const [openOnce, setOpenOnce] = React.useState(false);
  const [openBadge, setOpenBadge] = React.useState(false);
  const [changeButtonType, setChangeButtonType] = React.useState(false);

    const { dispatch } = useContext(AuthContext);

  const handleClick = () => {
    setOpenOnce(true)
    setChangeButtonType(true)
  }

  const handleClose = () => {
    setOpenOnce(false);
  };

  const handleCloseBadge = () => {
    setOpenBadge(false);
  };

  useEffect(() =>{
    const myTimeout = setTimeout(() => {setOpenBadge(true)}, 1500) 
  }, [])

  useEffect(() =>{
    const body = {id_actividad: 3, intento: 1}
    axios
      .get(
        `/user/actividad/resume/get?id_actividad=${actividad}&intento=${intento}`,
        body
      )
      .then((response) => {
        setFinalScore(response.data.resultado.puntos_total);
        if (intento === 1) {
          dispatch({
            type: types.insignias,
            payload: {
              insignia: "evaluacion",
            },
          });
        }
      });
  }, [])

  return (
    <Grid 
      container
      direction="column"
      item sm={12}
      className={classes.mainContainer}
    >
      <Grid container direction="row" justifyContent="center" item sm={12} className={classes.ContainerIzquierdo}>
        <Grid container item md={7} direction="column" justifyContent="space-around" alignItems="center" className={classes.ContainerEvaluacion}>
          <Grid container item justifyContent="center" alignItems="center" className={classes.ContainerTextoTitulo}>
            <p className="Titulo">
              ¡Buen trabajo!
            </p>
          </Grid> 
          <Grid container item  className={classes.ContainerTextoResultado}>
            <p className="Resultado">
              Ahora sabemos qué habilidades has desarrollado mejor y en cuáles es necesario seguir trabajando.
            </p>
          </Grid>
          <Grid container item direction="column" alignItems="center" className={classes.ContainerResultado}>
            <p className="TextoPuntaje">Tu puntaje es:</p>
            <div className="Divisor"></div>
            <p className="ResultadoPuntaje">{finalScore}/{preguntas}</p>
          </Grid>
          <Hidden only={["md","xl", "lg"]}>
            <Grid className={classes.ContainerHeraldoHiddenImage}>
              <img className="Heraldo" src={DiagnosticaHeraldo} alt="diagn" />
            </Grid>
          </Hidden>
          {
          changeButtonType ?
          <Link className={classes.link} to='/mapa' >
            <Button onClick={handleClick} className={classes.BotonContinuar}>
              Continúa la aventura
            </Button>
          </Link>
        :
          <Grid className={classes.link}>
            <Button onClick={handleClick} className={classes.BotonContinuar}>
              Continúa la aventura
            </Button>
          </Grid>
        }
        </Grid>
        <Grid container item xs={12} md={5} alignItems="center" className={classes.ContainerHeraldo} > 
          <Grid className={classes.ContainerHeraldoImage}>
            <img className="Heraldo" src={DiagnosticaHeraldo} alt="diagn" />
          </Grid>
        </Grid>
        <Grid container direction="row-reverse" item sm={12} className={classes.ContainerBarraInferior}></Grid>
      </Grid>
      <Grid container item sm={12} className={classes.ContainerBarraDerecha}></Grid>
      <ModalPoints open={openOnce} setOpenOnce={setOpenOnce} handleClose={handleClose}/>
      <ShowingBadge open={openBadge} setOpenOnce={setOpenBadge} handleClose={handleCloseBadge} badge={badge1} badgeType={"Diagnóstica"}/>
    </Grid>
  );
}

export default FinalResultadoBuentrabajo;
