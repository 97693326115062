import React                        from "react";
import { Button,makeStyles,Grid}    from "@material-ui/core";

const useStyles = makeStyles(theme=>({
  welcomePageContainer: {
    background:'#FFFDFD',
    height:'calc(100vh - 79.5px)',
  },
  ContainerIzquierdo:{
    width:'97%',
    [theme.breakpoints.down(1160)]:{
      width:'100%',
    },
  },

  ContainerEstrategi:{
    position:'relative',
    width:'100vw',
    height:'calc(100vh - 79.5px - 79.5px)',
    overflow: 'auto',
  },
  ContainerSubHabilidades:{
    background:'none',
    boxSizing:'border-box',
    cursor:'pointer',
    textAlign:'center',
    display:'flex',
    padding:'43px 17px 9px 17px',
    alignItems:'center',
    position:'absolute',
    height:'40px',
    width:'62px',
    right:'3%',
    zIndex:1,
    [theme.breakpoints.down(1281)]:{
      left:'calc(50% - 31px)',
      padding:'0 0 0 62px',
    },
    [theme.breakpoints.down(600)]:{
      left:'0',
      padding:'0 15px 0 55px',
    },
        '& .Habilidades':{
      color:'#575757',
      font: 'normal 16px Lato',
      letterSpacing: '0.13px',
      display:'none',
      [theme.breakpoints.down(1281)]:{
        padding:'0 ', 
        margin:'0',
        font: ' 13px Lato',
      },
      [theme.breakpoints.down(600)]:{
        padding:'0 ', 
        margin:'0',
        font: ' 11px Lato',
      },
    },
    '&:hover':{
      border:' 5px solid #F26973',
      borderRadius: '20px 0px 20px 20px',
      background:'#fff',
      height:'215px',
      width:'233px',
      [theme.breakpoints.down(1281)]:{
        height:'75px',
        width:'350px',
        borderRadius: '0px 0px 20px 20px',
      },
      [theme.breakpoints.down(600)]:{
        height:'75px',
        width:'100%',
      },

      '& .Habilidades':{
        display:'block',
      },
      '&:before':{
        borderTop:' 0px ',
        borderRight:'0px'
      },
    },
    '&:before':{
      display:'flex',
      justifyContent:'center',
      alignItems:'center',
      content: '"CI"',
      position: 'absolute',
      right: '0',
      top: '0',
      font: '20px Fredoka One',
      background: '#FC725A',
      color: '#FFF',
      border:' 3px solid #F2624F',
      borderRadius: '0px 0px 20px 20px',
      height: '40px',
      width: '62px',
      [theme.breakpoints.down(1281)]:{
        left: '-5px',
        top: '0',
      },
      [theme.breakpoints.down(600)]:{
        height: '30px',
        width: '52px',
        font: '18px Fredoka One',
      },

    },
  },
  Estrategia:{
    margin:'0px',
    font:'38px Fredoka One',
    textAlign: 'center',
    letterSpacing: '0.28px',
    color: '#20A698',
    opacity: '1',
    [theme.breakpoints.down(1161)]:{
      font:'28px Fredoka One',
    },
    [theme.breakpoints.down(600)]:{
      font:'24px Fredoka One',
    },
  },

   TextStrg:{
      margin:'0', 
      font:'normal  22px Lato',
      letterSpacing: '0.10px',
      color: '#575757',
      opacity: 1,
      padding:'0 0 40px 0',
      boxSizing:'border-box',
      width:'760px',
      [theme.breakpoints.down(1161)]:{
        width:'650px',
        font:'normal  18px Lato',
      },
      [theme.breakpoints.down(600)]:{
        font:'normal  16px Lato',
        width:'325px',
      },
      [theme.breakpoints.down(325)]:{
        font:'normal  14px Lato',
        width:'300px',
      },
  },

   Titulo:{
    position:'absolute',
    top:'58px',
    [theme.breakpoints.down(1161)]:{
      top:'80px',
    },
  },

  Objet1:{
    position:'absolute',
    top:'130px',
    '& .Td':{
      border:'solid 1.5px #000',
      width:'390px',
      textAlign:'center',
      background:'#fff',
      height:'42px',
      font:'  20px Lato',
      letterSpacing: '0.10px',
      color: '#575757',
      opacity: 1,
      [theme.breakpoints.down(1161)]:{
        font:' 18px Lato',
      },
      [theme.breakpoints.down(600)]:{
        font:' 16px Lato',
      },
    },
    '& .Br':{
      margin:'0 0 20px 0'
    },
    '& .ul':{
      margin:'22px 0',
      padding:'0 0 0 22px',
    },
    '& .li':{
      marginBottom:'10px',
    },
   '& .Amarillo':{
    textAlign:'center',
    height:'42px',
    background:'#F7DF92',
    padding:'0 ',
   },
   '& .Verde':{
    textAlign:'center',
    height:'42px',
    background:'#84E9DF',
    padding:'0 ',
   },

  },

  ContainerBarraInferior:{
    position:'relative',
    background:'#F3CD52',
    height:'79.51px',
    [theme.breakpoints.up(1920)]:{
      height:'8vh',
    },
    [theme.breakpoints.down(1161)]:{
      background: 'linear-gradient(280deg, #D3348F 0%, #F1CB5A 100%) 0% 0% ',
    },
  },

  ContainerBarraDerecha:{
    background: 'linear-gradient(180deg, #D3348F 0%, #F1CB5A 100%) 0% 0%',
    width:'3%',
    height:'100vh',
    [theme.breakpoints.down(1161)]:{
      display:'none',
    },
  },
  
   BotonAvanza:{
    position:'absolute',
    bottom:'52px',
    right:'134px',
    textTransform:'none',
    textDecoration:'none',
    height:'62px',
    width:'279px',  
    background: '#7A54BA',
    boxShadow: '0px 3px 6px #00000029',
    borderRadius: '61px',
    color: '#FFFFFF',
    font: 'bold 20px lato ',
    '&:hover':{
        background: '#dd78fb',
      },
    [theme.breakpoints.down(1161)]:{
      position:'relative',
      bottom:'32px',
      left:'0',
      right:'0',
    },
  },
    
}));

const ReadingStrategys = (props) => {
  const classes = useStyles();
  const {activeStep, setActiveStep, handleNextStep} = props

  const handleContinue = () => {
    setActiveStep(activeStep + 1)
    handleNextStep()
  }

  return(

    <Grid  container direction="column" item sm={12} className={classes.welcomePageContainer} >
<div className={classes.ContainerSubHabilidades}>
          <p className="Habilidades">Establecer relaciones entre lo que sabe y la información del texto para determinar la confiabilidad de éste.</p>
      </div>
      <Grid container item sm={12} justifyContent="center" className={classes.ContainerIzquierdo} >

        <Grid container item className={classes.ContainerEstrategi } >

              <Grid container item justifyContent="center" alignItems="center" sm={12} className={classes.Titulo}> 
                <p className={classes.Estrategia} >Estrategia de lectura</p>
              </Grid>

              <Grid container item sm={12} alignItems="center" justifyContent="center" className={classes.Objet1}>
                  <p className={classes.TextStrg}>
                    ¿Te ha pasado que, al realizar una lectura, encuentras relación entre la 
                    información del texto leído y otros textos? Esto puede ser de mucha ayuda para 
                    comprender mejor su contenido. ¿Quieres verificarlo? Realiza la 
                    siguiente actividad.
                    <ul className="ul">
                      <li className="li">
                      Escoge un texto del tema que más llame tu atención.
                      </li>
                      <li className="li">
                      Toma una hoja y un lápiz y realiza el siguiente recuadro.
                      </li>
                    </ul>
 
                    <tr>
                      <td valign="middle" className="Td Verde"><b>Datos que <br/>conozco del <br/>texto</b>  </td>
                      <td valign="middle" className="Td Verde"><b>¿En dónde los <br/>aprendí?</b></td>
                      <td valign="middle" className="Td Verde"><b>Datos que no <br/>conozco y estoy <br/>aprendiendo</b></td>
                      <td valign="middle" className="Td Verde"><b>¿En dónde<br/> puedo aplicar <br/>estos nuevos <br/>conocimientos?</b></td>
                    </tr>
                    <tr>
                      <td className="Td"> </td>
                      <td className="Td"> </td>
                      <td className="Td"> </td>
                      <td className="Td"> </td>
                    </tr>
                    <tr>
                      <td className="Td"> </td>
                      <td className="Td"> </td>
                      <td className="Td"> </td>
                      <td className="Td"> </td>
                    </tr>
                    <tr>
                      <td className="Td"> </td>
                      <td className="Td"> </td>
                      <td className="Td"> </td>
                      <td className="Td"> </td>
                    </tr>

                    <ul className="ul">
                      <li className="li">
                        Elige un lugar tranquilo, ventilado, con luz y, sobre todo, cómodo. Lee sólo el  
                        título del texto. Ahora responde: 
                      </li>
                    </ul>

                    ¿Sobre qué crees que trata el tema?<br/>
                    ¿Crees que el contenido incluya información que ya conoces?
                    
                    
                    <ul className="ul">
                      <li className="li">
                        Realiza una primera lectura y pon mucha atención en cada detalle. Al terminar, 
                        escribe en la primera columna de la tabla que trazaste los datos que conozcas del 
                        texto. Pueden ser palabras, frases, fechas, personajes, etcétera. En la segunda, 
                        anota en dónde aprendiste los datos.
                      </li>
                      <li className="li">
                        Lleva a cabo una segunda lectura. Ahora te toca detectar qué datos no conoces, 
                        como palabras, frases, fechas, personajes, etcétera, y escríbelos en la tercera 
                        columna. Finalmente, en la cuarta anota en dónde crees que podrías aplicar 
                        esos conocimientos.
                      </li>
                      <li className="li">
                        Al terminar, cuestiónate lo siguiente: ¿Qué conocía del tema? ¿Para qué 
                        me servirá la información?
                      </li>
                    </ul>

                    Te diste cuenta de que la mayoría de la información que conoces la has aprendido 
                    en otros textos y esto te ha servido para comprender con más claridad otras 
                    lecturas. Así mismo, que lo que aprendiste del texto lo podrás aplicar para lograr 
                    interpretar distintos escritos. ¿En dónde aplicarías esos conocimientos?  
                    Recuerda ser constante en la práctica ¡Sigamos!

                  </p>
              </Grid>
                
        </Grid>
        
        <Grid container item sm={12} className={classes.ContainerBarraInferior} justifyContent="center">
            <Button id="BotonContinuarEstrategias" className={classes.BotonAvanza} onClick={handleContinue}>
              Continuar
            </Button>
        </Grid>
        
      </Grid>

      <Grid container item sm={12} className={classes.ContainerBarraDerecha}></Grid>

    </Grid>

  )
}

export default ReadingStrategys;
