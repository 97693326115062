import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
      Contenedor:{
        background:'#E9FBF9',
        width:'100vw',
        overflowX:'clip',
        [theme.breakpoints.down(1161)]:{
            background:'none',
           overflowX:'unset',
        },
        [theme.breakpoints.down(600)]:{
            marginTop: '79.51px',
        }
    },
    ContainerInfoLectura:{
        position:'relative',
        height:'86px',
        background:'#76D4C3',
        '& .BarraInfoLectura':{
            height:'86px',
            background:`Url(https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Barras/Verde.png)`,
        },
        '& .InfoLectura':{
            position:'absolute',
            height:'86px',
            width:'219px',
            boxSizing:'border-box',
            padding:'17.2px 0',
            background:'#FAFAFA',
            borderRadius:'50px',
            boxShadow: '2px 2px 2px 2px #00000029',
            zIndex:'4',
        },
        '& .InfoLectura:before':{
            position:'absolute',
            display:'flex',
            boxSizing:'border-box',
            padding:'0 0 0 10px',
            justifyContent:'center',
            alignItems:'center',
            height:'39.88px',
            width:'39.88px',
            borderRadius:'50%',
            content:'""',
            boxShadow: '3px 0px .5px 0px #00000029',
            background:'#FAFAFA',
            left:'90px',
            top:'65px',
            transform: 'rotate(0.25turn)',
        },
        '& .InfoLectura:after':{
            content:'""',
            height:'15px',
            width:'15px',
            borderTop:'2px solid #20A698',
            borderLeft:'2px solid #20A698',
            position:'absolute',
            bottom:'-10px',
            left:'Calc(50% - 8px)',
            transform: 'rotate(0.625turn)',
        },
        '& .Sesion':{
            borderLeft:'2px solid #D8D8D8',
        },
        '& .Dato':{
            margin:'0 0 5px 0',
            textAlign:'center',
            font:'bold 17px lato',
            color:'#20A698',
        },
        '& .Caracter':{
            margin:'0',
            textAlign:'center',
            font:'bold 17px lato',
            color:'#000000',
        },
    },
    ContenedorLectura:{
        background:'#fff',
        width:'1160px',
        height:'100%',
        padding:'57px 168px 35px' ,
        [theme.breakpoints.down(1161)]:{
            width:'502px',
            padding: '60px 0 0 0',
        },
                [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
        '& .ContainerDerechos':{
            bottom:'80px',
            [theme.breakpoints.down(1161)]:{
                display:'none',
            }
        },
        '& .Derechos':{
            textAlign:"center",
            margin:"0px",
            font:" 15px lato",
            color:"#717476",
        },
        '& .Escritorio':{
            display:'block',
            [theme.breakpoints.down(1161)]:{
                display:'none'
            },
        },
        '& .Tablet':{
            display:'none',
            [theme.breakpoints.down(1161)]:{
                display:'block'
            },
        },
    },
    ContainerTitulo:{
        margin:'0 0 45px 0',
        [theme.breakpoints.down(1161)]:{
            margin:'0 0 38px 0',
        },
        '& .Titulo':{
            font:'48px fredoka one',
            letterSpacing: '0px',
            color: '#7940DA',
            margin:'0',
            textAlign:'center',
            [theme.breakpoints.down(1161)]:{
                font:'43px fredoka one',
                lineHeight:'52px',
                textAlign:'center',
            },
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            lineHeight : '30px',
            margin:'0 0 36px 0 ',
            font:'22px lato',
            color:'#000',
            letterSpacing: '0.20px',
            [theme.breakpoints.down(1161)]:{
                font:'18px lato',
                letterSpacing:'.17px',
                lineHeight:'28px',
            },
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },

        },
        '& .SubTitulo':{
            font:'bold 30px lato',
            margin:'0',
            letterSpacing:'.28px',
            lineHeight:'30px',
            color:'#EA699D',
            [theme.breakpoints.down(1161)]:{
                font:'bold 26px lato',
                letterSpacing:'.24px',
                lineHeight:'28px',
            },
            [theme.breakpoints.down(502)]:{
                font:'bold 22px lato',
                lineHeight:'24px',
            },
            [theme.breakpoints.down(350)]:{
                font:'bold 18px lato',
                lineHeight:'24px',
            },
        },
        '& .Imagen':{
            [theme.breakpoints.down(1161)]:{
                width:'100%',
            },
        }


    },

}));

const El_lado_humano_en_las_catástrofes = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
             <Grid container item justifyContent="center" className={classes.ContainerInfoLectura}>
               <Grid container className="BarraInfoLectura"></Grid>
               <Grid container item className="InfoLectura">
                    <Grid container item direction="column" xs={6} justifyContent="center">
                        <p className="Dato">Grado</p>
                        <p className="Caracter">6</p>
                    </Grid>
                    <Grid container item direction="column" xs={6} justifyContent="center" className="Sesion">
                        <p className="Dato">Sesión</p>
                        <p className="Caracter">45</p>
                    </Grid>
               </Grid>
            </Grid>

            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo Escritorio" >
                        El lado humano en las catástrofes
                    </h2>
                    <h2 className="Titulo Tablet" >
                        El lado humano <br/>en las catástrofes
                    </h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                            <h3 className='SubTitulo'>El pueblo mexicano se muestra solidario en momentos de crisis</h3> <br/>
                            
                            Ciudad de México, México, 19 de septiembre de 1985, 7:19 am. <br/><br/>
                            
                            Se percibieron los primeros movimientos del sismo que inició a las 7:17, con 
                            epicentro en la costa del estado de Michoacán. Minutos después, la ciudad pasó por 
                            el movimiento telúrico más grave de su historia. Cientos de edificios se derrumbaron 
                            y, lamentablemente, miles de personas quedaron atrapadas en los escombros. 
                            Muchas murieron y otras lograron ser rescatadas. <br/><br/>
                            
                            Las 48 horas posteriores al sismo fueron catastróficas. El 20 de septiembre hubo una 
                            réplica que terminó por derrumbar algunos edificios que se habían mantenido en pie 
                            durante el temblor del día anterior. Media hora después del movimiento telúrico, ya
                            había personas removiendo escombros para rescatar a los sobrevivientes. <br/><br/>
                            
                            No existían protocolos para los desastres y las autoridades se mostraron 
                            incompetentes ante la destrucción. Pero la sociedad civil rápidamente se organizó 
                            para ayudar a la remoción de cascajo. Para transportar con velocidad los fragmentos
                            de muro o techo que tenía atrapadas a las personas, los vecinos formaban cadenas
                            humanas y se pasaban cubetas llenas de mano en mano.
                        </p>
                    </Grid>
                    <Grid container >
                        <Grid container  sm={12}>
                                <img className='Imagen' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion45/1.png"  alt="Tarjeta" />
                        </Grid>
                    </Grid>
                   
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                            También los medios de comunicación, la radio y la televisión ayudaron en la 
                            búsqueda de familiares. Informaban qué zonas de la ciudad estaban más afectadas y 
                            dónde se necesitaba ayuda para rescatar personas. <br/><br/>
                            
                            El gobierno había decretado que toda la población permaneciera en casa por su 
                            seguridad. Pero el número de edificios derrumbados y de personas atrapadas 
                            superaba por mucho a los soldados y demás autoridades capacitadas para el rescate 
                            de personas. Ante este panorama, la desobediencia de la sociedad fue clave para 
                            la sobrevivencia de muchas personas. Así, el pueblo mexicano demostró su capacidad 
                            de solidarizarse ante una situación de catástrofe. <br/><br/>
                            
                            Exactamente 32 años después del catastrófico sismo, la historia se repitió en la 
                            Ciudad de México. Un temblor de 7.2 grados sacudió la tierra, provocando el 
                            derrumbe de edificios y la activación de protocolos posteriores al sismo. Aunque la 
                            pérdida de vidas humanas también fue catastrófica, los habitantes de la ciudad 
                            habían aprendido de las experiencias pasadas, por lo que la organización no se dejó 
                            esperar. <br/><br/>
                            
                            <h3 className='SubTitulo'>La solidaridad se extiende más allá del pueblo mexicano</h3><br/>
                            
                            Puerto Príncipe, Haití, martes 12 de enero de 2010, 16:53 pm. <br/><br/>
                            
                            Un terremoto con epicentro a 15 km de la capital se percibió en el centro de la isla. El 
                            sismo de 7 grados ha sido el más mortífero de la década y está clasificado como uno 
                            de los 10 terremotos más mortales en la historia de la humanidad. Se recuperaron 
                            alrededor de 150 000 cuerpos, pero se estima que el aproximado de muertes ronda
                            los 200 000. <br/><br/>
                            
                            Ante tan magna catástrofe, fueron necesarias medidas humanitarias de toda índole. 
                            El pueblo de República Dominicana, que comparte la isla con los haitianos, se 
                            solidarizó y le abrió sus fronteras a los damnificados para que fueran atendidos en 
                            sus hospitales.
                        </p>
                    </Grid>
                    <Grid container  sm={12}>
                        <Grid container>
                            <img  className='Imagen' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion45/2.png"  alt="Tarjeta" />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                            La comunidad internacional, tanto de Latinoamérica como del resto del mundo, 
                            también otorgó ayuda mediante el envío de dinero, alimentos, personal voluntario
                            para las labores de rescate y hasta perros de búsqueda. Por ser uno de los países más 
                            pobres del continente, la ayuda fue crucial para que Haití se recuperara de ese duro 
                            golpe. <br/><br/>
                            
                            <h3 className='SubTitulo'>La tragedia se repite, pero el lado humano también</h3><br/>
                            
                            Chile, sábado 17 de febrero de 2010, 3:34 pm. <br/><br/>
                            
                            Casi el 80% del país quedó afectado después del terremoto que se originó en el mar y 
                            alcanzó la duración de 4 minutos en algunas zonas. Esa catástrofe se considera el 
                            segundo sismo más grande de su historia. De inmediato, la comunidad se organizó y 
                            fue un elemento clave para salvar vidas. Entre las muestras solidarias internacionales 
                            destaca la formación de organizaciones como Desafío Levantemos Chile, un grupo 
                            que se dedicó a recuperar las pérdidas materiales de los pescadores afectados y la 
                            reconstrucción de escuelas. <br/><br/>
                            
                            Como pudiste leer, América Latina es una de las zonas más sísmicas del mundo.
                            Lamentablemente, con la tecnología que se tiene en la actualidad aún no se puede 
                            predecir cuándo sucederá un terremoto. Pero lo que sí se puede saber con antelación 
                            es que la solidaridad humana siempre estará en los momentos más difíciles de cada 
                            sociedad.

                        </p>
                    </Grid>
                </Grid>

                <Grid container item xs={12} justifyContent="center" className="ContainerDerechos">
                    <p className="Derechos">
                        © 2022. Todos los derechos reservados, Pearson, Circuito de lectores.
                    </p>                
                </Grid>
        </Grid>
    </Grid>
    );
}
 
export default El_lado_humano_en_las_catástrofes;
