import React, { useContext } from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core";
import logoPea from "../../imgs/logoPea.png";
import Button from "@material-ui/core/Button";
import { Link, Redirect } from "react-router-dom";
import bgImage from "./backGroundImages/CL_G5_Login.png";
import axios from "../../../services/axios";
import AuthContext from "../../../auth/AuthContext";
import types from "../../../types/types";
import ModalLogin from "../login/ForgetPassword/Modals/ModalLogin";
import { studentData } from "../../models/login";
import useLoading from "../../hooks/useLoading";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    minHeight: "100vh",
    height: "100%",
    backgroundImage: `url(${bgImage})`,
    backgroundPosition: "75%",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    [theme.breakpoints.up("sm")]: {
      // backgroundPosition: "80%",
    },
    [theme.breakpoints.up("md")]: {
      alignContent: "center",
    },
    "@media(min-height:1024px)": {
      alignContent: "center",
    },
  },
  containerContent: {
    flexWrap: "nowrap",
    gap: "15px",
    [theme.breakpoints.up("sm")]: {
      // gap: "15x",
    },
    [theme.breakpoints.up("md")]: {
      gap: "15px",
      maxHeight: "1024px",
    },

    [theme.breakpoints.up("xl")]: {
      gap: "30px",
      alignContent: "center",
      maxHeight: "1440px",
    },
  },
  studentName: {
    font: "28px Fredoka One ",
    margin: "0",
    color: "#F3CD52",
    [theme.breakpoints.up("xl")]: {
      fontSize: "50px",
    },
  },
  welcomeText: {
    color: "#FFFFFF",
    font: " bold 16px Lato",
    textAlign: "center",
    width: "80%",
    maxWidth: "448px",
    [theme.breakpoints.up("xl")]: {
      fontSize: "36px",
    },
  },
  inputLoginPassword: {
    width: "80%",
    maxWidth: "320px",
    height: "45px",
    paddingLeft: "27px",
    borderRadius: "61px",
    border: "solid #F3CD52 3px ",
    background: "#FFFFFF",
    font: "Helvetica",
    fontSize: "16px",
    "&:focus": {
      outline: "none",
    },
    [theme.breakpoints.up("xl")]: {
      height: "81px",
      maxWidth: "421px",
    },
  },
  forgottenPassword: {
    color: "#FFFFFF",
    font: "20px Lato",
    letterSpacing: "0.36px",
  },
  inputLoginButton: {
    width: "80%",
    maxWidth: "320px",
    height: "50px",
    borderRadius: "61px",
    border: "none",
    background: "#65EAE4",
    color: "#353535",
    font: " bold 16px Lato",
    letterSpacing: "1.96px",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#6FDBED",
      fontWeight: "bold",
    },
    [theme.breakpoints.up("xl")]: {
      height: "81px",
      fontSize: "22px",
      maxWidth: "450px",
    },
  },
  userChangeButton: {
    width: "100%",
    height: "50px",
    borderRadius: "61px",
    border: "solid #1D2AAD 3px ",
    background: "#FFFFFF",
    color: "#353535",
    font: " bold 16px Lato",
    letterSpacing: "1.96px",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#EFEFEF",
      fontWeight: "bold",
    },
    [theme.breakpoints.up("xl")]: {
      height: "81px",
      fontSize: "22px",
    },
  },

  ContainerLogoClLeft: {
    display: "flex",
    alignItems: "center",
    marginBottom: "10%",
    justifyContent: "center",
    [theme.breakpoints.up("xl")]: {
      marginBottom: "20%",
    },
  },

  logoP: {
    marginTop: "10%",
    height: "98px",
    width: "104px",
  },
  rightsReserved: {
    color: "#FFFFFF",
    font: "14px Lato",
    letterSpacing: "0.53px",
    maxWidth: "80%",
    [theme.breakpoints.up("xl")]: {
      fontSize: "18px",
    },
  },
  logoEscuela: {
    width: "104px",
    height: "104px",
    borderRadius: "50%",
  },

  Link: {
    textDecoration: "none",
    width: "80%",
    maxWidth: "320px",
    [theme.breakpoints.up("xl")]: {
      maxWidth: "451px",
    },
  },
}));

const SinginSegundo = (props) => {
  const classes = useStyles();
  const {
    dispatch,
    user: { userName },
  } = useContext(AuthContext);
  const [oldSesionData, setoldSesionData] = React.useState({});

  React.useEffect(() => {
    const oldSesion = JSON.parse(localStorage.getItem("oldSesion"));
    setoldSesionData(oldSesion);
  }, []);

  const [password, setPassword] = React.useState("");

  const [open, setOpen] = React.useState(false);
  const [error, setError] = React.useState("");
  const dynamicYear = new Date().getFullYear();

  const [disabled, setDisabled] = React.useState(false);

  const { handleLoading } = useLoading();

  const handleClose = () => {
    setOpen(false);
  };

  const handlePassword = (event) => {
    setPassword(event.target.value);
  };

  const singInClick = () => {
    setDisabled(true);
    handleLoading(true);
    const body = {
      nombreUsuario: userName || oldSesionData?.userName,
      contraseña: password,
    };
    axios
      .post("/session/login", body)
      .then((response) => {
        if (response.status === 200) {
          localStorage.setItem("token", response.data.resultado.token_usuario);
          const data = response.data.resultado;
          dispatch({
            type: types.login,
            payload: studentData(data),
          });
        }
      })
      .catch((error) => {
        setOpen(true);
        setError(error.response?.data?.detalles?.msg);
        setDisabled(false);
      })
      .finally(() => {
        handleLoading(false);
      });
  };
  const userChangeClick = () => {
    localStorage.removeItem("oldSesion");
  };

  return (
    <Grid container className={classes.root}>
      <Grid
        container
        item
        sm={6}
        direction="column"
        justifyContent="center"
        alignItems="center"
      ></Grid>
      <Grid
        container
        item
        sm={6}
        direction="column"
        justifyContent="space-around"
        alignItems="center"
        className={classes.containerContent}
      >
        <img src={logoPea} alt="logoP" className={classes.logoP} />
        <h1 className={classes.studentName}>¡Hola, {oldSesionData?.name}!</h1>
        <div className={classes.welcomeText}>
          Es un gusto verte de nuevo.
          <br />
          Ingresa tu contraseña para iniciar tu sesión
        </div>
        <input
          type="password"
          placeholder="Contraseña"
          value={password}
          onChange={handlePassword}
          className={classes.inputLoginPassword}
        ></input>
        <Link className={classes.forgottenPassword} to="/password_recovery">
          Olvidé mi contraseña
        </Link>

        <Button
          onClick={singInClick}
          onDoubleClick={singInClick}
          disabled={disabled}
          className={classes.inputLoginButton}
        >
          Iniciar sesión
        </Button>

        <Link to="/" className={classes.Link}>
          <Button
            onClick={userChangeClick}
            className={classes.userChangeButton}
          >
            Cambiar de usuario
          </Button>
        </Link>
        {oldSesionData?.schoolLogo && (
          <img
            src={oldSesionData?.schoolLogo}
            alt="Logo de la Escuela"
            className={classes.logoEscuela}
          />
        )}

        <p className={classes.rightsReserved}>
          Derechos reservados y legales {dynamicYear} por Pearson
        </p>
        {!oldSesionData && <Redirect to={{ pathname: `/` }} />}
        {oldSesionData?.rol === "docente" && (
          <Redirect to={{ pathname: `/singin/docente` }} />
        )}
        {oldSesionData?.rol === "administrador institucional" && (
          <Redirect to={{ pathname: `/singin/admin` }} />
        )}
        {oldSesionData?.rol === "alumno" &&
          oldSesionData?.grade !== "quinto" && (
            <Redirect to={{ pathname: `/singin/${oldSesionData?.grade}` }} />
          )}
        {localStorage.token && (
          <Redirect to={{ pathname: `/perfil_del_alumno` }} />
        )}
        <ModalLogin open={open} error={error} handleClose={handleClose} />
      </Grid>
    </Grid>
  );
};

export default SinginSegundo;
