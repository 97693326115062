import { Button, Grid, makeStyles } from "@material-ui/core";
import React, { useContext, useEffect } from "react";
import DrawerContext from "../../../../../usability/DrawerContext";
import { useOnLoadImages } from "../../../../hooks/useOnLoadImages";
import Loader from "../../../../Loader/Loader";
import Drawer from "../../ReadingTools/Drawer";

const useStyles =  makeStyles (theme =>({
      Contenedor:{
        background:'#E9FBF9',
        width:'100vw',
        overflowX:'clip',
        [theme.breakpoints.down(1161)]:{
            background:'none',
           overflowX:'unset',
        },
        [theme.breakpoints.down(600)]:{
            marginTop: '79.51px',
        }
    },
  ContainerInfoLectura: {
    position: "relative",
    height: "86px",
    [theme.breakpoints.down(502)]:{
        height: "43px",
    },
    background: "#76D4C3",
    "& .BarraInfoLectura": {
      height: "86px",
      background: `Url(https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Barras/Azul.png)`,
        [theme.breakpoints.down(502)]:{
            height: "43px",
        },
    },
    "& .InfoLectura": {
      position: "absolute",
      height: "86px",
      width: "219px",
      boxSizing: "border-box",
      padding: "17.2px 0",
      background: "#FAFAFA",
      borderRadius: "50px",
      boxShadow: "2px 2px 2px 2px #00000029",
      zIndex: "4",
        [theme.breakpoints.down(502)]:{
            height: "43px",
            width: "109px",
            padding: "8.6px 0",
            borderRadius: "25px",
        },
    },
    "& .InfoLectura:before": {
      position: "absolute",
      display: "flex",
      boxSizing: "border-box",
      padding: "0 0 0 10px",
      justifyContent: "center",
      alignItems: "center",
      height: "39.88px",
      width: "39.88px",
      borderRadius: "50%",
      content: '""',
      boxShadow: "3px 0px .5px 0px #00000029",
      background: "#FAFAFA",
      left: "90px",
      top: "65px",
      transform: "rotate(0.25turn)",
      [theme.breakpoints.down(502)]:{
        left: "45px",
        top: "30px",
        height: "20.88px",
        width: "20.88px",
        },
    },
    "& .InfoLectura:after": {
      content: '""',
      height: "15px",
      width: "15px",
      borderTop: "2px solid #20A698",
      borderLeft: "2px solid #20A698",
      position: "absolute",
      bottom: "-10px",
      left: "Calc(50% - 8px)",
      transform: "rotate(0.625turn)",
      [theme.breakpoints.down(502)]:{
        height: "10px",
        width: "10px",
        left: "Calc(50% - 4.5px)",
        bottom: "-3px",
        borderTop: "1px solid #20A698",
        borderLeft: "1px solid #20A698",
        },
    },
    "& .Sesion": {
      borderLeft: "2px solid #D8D8D8",
    },
    "& .Dato": {
      margin: "0 0 5px 0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#20A698",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        margin: "0 0 2.5px 0",
        },
    },
    "& .Caracter": {
      margin: "0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#000000",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        },
    },
       '& .Regresar':{
        color: '#F2624F',
        border: '#F2624F 1px solid',
        height: '30px',
        width:'100px',
        borderRadius: '20px',
        letterSpacing: '0.14px',
        textTransform: 'none',
        background:'#FEFAF1',
        top:'calc(50% - 15px)',
        left:'5%',
        zIndex:4,
        [theme.breakpoints.down(502)]: {
          font:'bold 10px lato',
          width: "60px",
        },
      },
  },
    ContenedorLectura:{
        background:'#fff',
        width:'1160px',
        height:'100%',
        padding:'60px 113px 35px 168px' ,
        
        transform: (props) =>
        props.scale > 1 ? `scale(${props.scale}) translate(0px, 10%)` : `none`,
        [theme.breakpoints.down(1161)]:{
            width:'502px',
            padding:'60px 0 0',
        },
        [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
        '& .ContainerDerechos':{
            bottom:'80px',
            [theme.breakpoints.down(1161)]:{
                display:'none',
            }
        },
        '& .Derechos':{
            textAlign:"center",
            margin:"0px",
            font:" 15px lato",
            color:"#717476",
        },
        '& .Escritorio':{
            display:'block',
            [theme.breakpoints.down(1161)]:{
                display:'none'
            },
        },
        '& .Tablet':{
            display:'none',
            [theme.breakpoints.down(1161)]:{
                display:'block'
            },
        },  
    },
    ContainerTitulo:{
        margin:'0 0 46px 0',
        [theme.breakpoints.down(1161)]:{
            margin:'0 0 38px 0',
        },
        '& .Titulo':{
            font:'48px fredoka one',
            letterSpacing: '0px',
            color: '#F6664D',
            margin:'0',
            textAlign:'center',
            [theme.breakpoints.down(1161)]:{
                font:'43px fredoka one',
                lineHeight:'52px',
                textAlign:'center',
            },
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            lineHeight : '30px',
            margin:'0 0 36px 0 ',
            font:'22px lato',
            color:'#000',
            letterSpacing: '0.20px',
            [theme.breakpoints.down(1161)]:{
                font:'18px lato',
                letterSpacing:'.17px',
                lineHeight:'28px',
            },
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .Lectura2':{
            margin:' 0 ',
            color:'#717476!important',

        },
        '& .SubTitulo':{
            font:'bold 30px lato',
            letterSpacing:'.28px',
            lineHeight:'30px',
            color:'#1B75BA',
            margin:'0',
            [theme.breakpoints.down(1161)]:{
                font:'bold 26px lato',
                letterSpacing:'.24px',
                lineHeight:'28px',
            },
            [theme.breakpoints.down(502)]:{
                font:'bold 22px lato',
                lineHeight:'24px',
            },
            [theme.breakpoints.down(350)]:{
                font:'bold 18px lato',
                lineHeight:'24px',
            },
        },
        '& .Ul':{
            margin:'0',
            color:'#F6664D',
            font:'6px',
            padding:'0 0 0 15px'
        },
        '& .TextImg':{
            margin:'22px 0 0 0',
            font:'20px lato',
            alignSelf:'flex-start',
            color:'#717476',
            [theme.breakpoints.down(1161)]:{
                font:'16px lato',
                letterSpacing:'.15px',
                lineHeight:'25px',
            },
            [theme.breakpoints.down(502)]:{
                font:' 11px lato',
                lineHeight :'14px',
                letterSpacing: '0.10px',
            },
        },
        '& .Li':{
            margin:'0 0 8px 0',
            [theme.breakpoints.down(1161)]:{
                width:'478px',
            },
            [theme.breakpoints.down(502)]:{
                width:'100%',
            },
        },
        '& .Lectura3':{
            margin:'0 '
        },
        '& .Imagen':{
            [theme.breakpoints.down(1161)]:{
                width:'100%',
            },
        },

    },
    

}));

const Mamuts_de_nuevo_a_la_vida_Clonación = (props) => {

  const {activeStep, setActiveStep, handlePreviewStep, id_actividad} = props

  const handleContinue = () => {
    setActiveStep(activeStep - 1)
    handlePreviewStep()
  }

    const {
    handleSize,
    disabledZoom,
    closeZoom,
    handleZoom,
    scale
  }= useContext(DrawerContext);
  const [loading, setLoading] = React.useState(true);
  const wrapperRef = React.useRef(null);
  const imagesLoaded = useOnLoadImages(wrapperRef);

  const detectSize = () => {
    const lectura = document.getElementById("lectura");
    const newWidth = lectura?.offsetWidth;
    const newHeight = lectura?.offsetHeight;
    setLoading(false);
    if (lectura) handleSize(newHeight, newWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", detectSize);
    return () => {
      handleZoom()
      window.removeEventListener("resize", detectSize);
      closeZoom()
    };
  }, []);

  const classes = useStyles({ disabledZoom,scale });
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
             <Grid container item justifyContent="center" className={classes.ContainerInfoLectura}>
               <Grid container className="BarraInfoLectura">
                    <Button className="Regresar" onClick={handleContinue}>
                        Regresar
                    </Button>
                </Grid>
               <Grid container item className="InfoLectura">
                    <Grid container item direction="column" xs={6} justifyContent="center">
                        <p className="Dato">Grado</p>
                        <p className="Caracter">6</p>
                    </Grid>
                    <Grid container item direction="column" xs={6} justifyContent="center" className="Sesion">
                        <p className="Dato">Sesión</p>
                        <p className="Caracter">36</p>
                    </Grid>
               </Grid>
            </Grid>
{!imagesLoaded && <Loader />}
        <Grid
            container
            item
            className={classes.ContenedorLectura}
            justifyContent="center"
            id="lectura"
            onLoad={detectSize}
            ref={wrapperRef}
        >
<Drawer id_lectura={id_actividad} />
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo Escritorio" >
                    La felicidad en el mundo
                    </h2>
                    <h2 className="Titulo Tablet" >
                    La felicidad<br/> en el mundo
                    </h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                            <h3 className='SubTitulo'>La Red de Soluciones para el Desarrollo Sostenible publica el Informe Mundial de la Felicidad 2021</h3> <br/>
                            
                            Existen diferentes reportes que tratan de medir la felicidad en el mundo. Por 
                            ejemplo, desde 2011 la Red de Soluciones para el Desarrollo Sostenible (SDSN, por 
                            sus siglas en inglés) publica cada año el Informe Mundial de la Felicidad. Ésta es la 
                            lista con mayor reconocimiento mundial. <br/><br/>
                            
                            <h3 className='SubTitulo'>Informe Mundial de la Felicidad 2021</h3> <br/>
                            
                            En colaboración con la Organización de las Naciones Unidas, el Informe Mundial de 
                            la Felicidad es elaborado por un conjunto de investigadores independientes que 
                            establecen cada año la lista de los países más y menos felices del mundo. <br/><br/>
                            
                            El informe de 2021 determinó que los tres países más felices del mundo son 
                            Finlandia, Dinamarca y Suiza, mientras que los tres más infelices son Afganistán, 
                            Zimbabwe y Ruanda.
                        </p>
                    </Grid>
                    <Grid container  sm={12}>
                        <Grid container justifyContent="center" >
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion36/1.png"  alt="Tarjeta" />
                        </Grid>
                        <Grid container item  justifyContent="center">
                            <p className="TextImg"> 
                            Los ciudadanos de las naciones son encuestados de acuerdo con preguntas comunes.
                            </p>
                        </Grid>
                         
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura Lectura2">
                            Fuente: el informe sobre la felicidad es una encuesta realizada por las Naciones 
                            Unidas que mide cómo ha evolucionado la felicidad de los ciudadanos en los últimos
                            años en 156 países.<br/><br/>

                            Para elaborar el informe se utilizan los datos de la Encuesta Mundial de Gallup en la 
                            que se pide a los encuestados que califiquen su vida del cero al 10, siendo cero la 
                            peor vida posible y 10 la mejor vida posible.
                        </p>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                            <h3 className='SubTitulo'>Factores que se consideran</h3><br/> 

                            Para determinar sus conclusiones, los expertos encargados de realizar el Informe 
                            Mundial de la Felicidad toman en cuenta algunos factores como los siguientes:
                        </p>

                        <ul className='Ul'>
                            <li><p className="Lectura Li">ingreso promedio de las personas en el respectivo país</p></li>
                            <li><p className="Lectura Li">calidad de vida</p></li>
                            <li><p className="Lectura Li">acceso a los servicios de salud pública</p></li>
                            <li><p className="Lectura Li">acceso a la educación</p></li>
                            <li><p className="Lectura Li">sentimiento de libertad que sienten las personas para la toma de decisiones</p></li>
                            <li><p className="Lectura Lectura3 ">percepción de la población sobre la corrupción en el país</p></li>
                        </ul>

                        <p className='Lectura'>
                            <br/>
                            Aunque las variables anteriores han sido las tomadas en cuenta desde su comienzo 
                            en 2011, en 2021 se hizo una adecuación para considerar el impacto de la pandemia 
                            ocasionada por el Covid-19. <br/><br/>
                            
                            <h3 className='SubTitulo'>Bután, el primer país en medir la felicidad </h3><br/>
                            
                            Un pequeño país asiático situado entre las montañas del Himalaya llamado Bután fue 
                            uno de los precursores en las mediciones de la felicidad de la población, pues en 
                            1972 creó el indicador de Felicidad Nacional Bruta. <br/><br/>
                            
                            A partir de ese indicador estableció medidas para basar sus decisiones políticas en 
                            sus resultados, con el objetivo de que su población se sintiese cada vez más alegre. 
                            Debido a esto, Bután se conoce popularmente con el apodo de “el país de la 
                            felicidad”. <br/><br/>
                            
                            Después de Bután, otros países como Francia también comenzaron a medir la 
                            felicidad de su población, lo cual influyó posteriormente en la creación de índices 
                            internacionales, como el Informe Mundial de la Felicidad. <br/><br/>
                            
                            <h3 className='SubTitulo'>La felicidad personal</h3><br/> 
                            
                            Pero ¿cómo se puede medir la felicidad de una persona? ¿Qué responderías si te 
                            preguntaran qué tan feliz eres? ¿Podrías poner una puntuación a tu felicidad? ¿Qué 
                            se puede medir para determinar qué tan feliz es o no una persona?
                        </p>
                    </Grid>

                    <Grid container  sm={12}>
                        <Grid container >
                            <img className='Imagen' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion36/2.png"  alt="Tarjeta" />
                            
                        </Grid>
                        <Grid container justifyContent="center">
                            <p className='TextImg'>La felicidad es más profunda cuando se comparte con tus seres queridos.</p>
                        </Grid>
                    </Grid>
                
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                            Se podría pensar que una forma de determinar la felicidad sería contar las veces que 
                            alguien se ríe durante un día, el número de veces que prueba su comida favorita 
                            durante una semana o cuántas veces se puede estar o jugar con amigos o familiares. <br/><br/>

                            Sin duda, hacer las cosas que a alguien le gustan lo hacen feliz. Pero la felicidad no 
                            depende sólo de uno mismo y de hacer todo el tiempo lo que uno quiere y le gusta. 
                            Este sentimiento también depende de las personas de las que se rodea la gente. <br/><br/>
                            
                            En ocasiones hacer algo que a uno lo alegra puede hacer muy triste a otra persona. 
                            Por ejemplo, cuando un nieto prefiere jugar con su teléfono móvil en lugar de pasar 
                            tiempo con sus abuelos si ellos están de visita. <br/><br/>
                            
                            Vale la pena que reflexiones si puedes ser feliz cuando sabes que con tus actos has 
                            hecho que otra persona se sienta infeliz. <br/><br/>
                            
                            La felicidad no sólo consiste en que trates de hacer las cosas que te gustan, sino 
                            también en tomar en cuenta a las otras personas y lo que a ellas las hace sentirse
                            bien. De esta forma se puede encontrar un equilibrio que sea provechoso para todos. 
                            Ayudar a otra persona a ser feliz puede hacerte igual de feliz.
                        </p>
                    </Grid>
                </Grid>
        </Grid>
        <Grid container item style={{ marginTop: 80}} ></Grid>
    </Grid>
    );
}
 
export default Mamuts_de_nuevo_a_la_vida_Clonación;
