import { Button, Grid, makeStyles } from "@material-ui/core";
import React, { useContext, useEffect } from "react";
import DrawerContext from "../../../../../usability/DrawerContext";
import { useOnLoadImages } from "../../../../hooks/useOnLoadImages";
import Loader from "../../../../Loader/Loader";
import Drawer from "../../ReadingTools/Drawer";

const useStyles =  makeStyles (theme =>({
     Contenedor:{
        background:'#E9FBF9',
        width:'100vw',
        overflowX:'clip',
        [theme.breakpoints.down(1161)]:{
            background:'none',
           overflowX:'unset',
        },
        [theme.breakpoints.down(600)]:{
            marginTop: '79.51px',
        }
    },
  ContainerInfoLectura: {
    position: "relative",
    height: "86px",
    [theme.breakpoints.down(502)]:{
        height: "43px",
    },
    background: "#76D4C3",
    "& .BarraInfoLectura": {
      height: "86px",
      background: `Url(https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Barras/Azul.png)`,
        [theme.breakpoints.down(502)]:{
            height: "43px",
        },
    },
    "& .InfoLectura": {
      position: "absolute",
      height: "86px",
      width: "219px",
      boxSizing: "border-box",
      padding: "17.2px 0",
      background: "#FAFAFA",
      borderRadius: "50px",
      boxShadow: "2px 2px 2px 2px #00000029",
      zIndex: "4",
        [theme.breakpoints.down(502)]:{
            height: "43px",
            width: "109px",
            padding: "8.6px 0",
            borderRadius: "25px",
        },
    },
    "& .InfoLectura:before": {
      position: "absolute",
      display: "flex",
      boxSizing: "border-box",
      padding: "0 0 0 10px",
      justifyContent: "center",
      alignItems: "center",
      height: "39.88px",
      width: "39.88px",
      borderRadius: "50%",
      content: '""',
      boxShadow: "3px 0px .5px 0px #00000029",
      background: "#FAFAFA",
      left: "90px",
      top: "65px",
      transform: "rotate(0.25turn)",
      [theme.breakpoints.down(502)]:{
        left: "45px",
        top: "30px",
        height: "20.88px",
        width: "20.88px",
        },
    },
    "& .InfoLectura:after": {
      content: '""',
      height: "15px",
      width: "15px",
      borderTop: "2px solid #20A698",
      borderLeft: "2px solid #20A698",
      position: "absolute",
      bottom: "-10px",
      left: "Calc(50% - 8px)",
      transform: "rotate(0.625turn)",
      [theme.breakpoints.down(502)]:{
        height: "10px",
        width: "10px",
        left: "Calc(50% - 4.5px)",
        bottom: "-3px",
        borderTop: "1px solid #20A698",
        borderLeft: "1px solid #20A698",
        },
    },
    "& .Sesion": {
      borderLeft: "2px solid #D8D8D8",
    },
    "& .Dato": {
      margin: "0 0 5px 0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#20A698",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        margin: "0 0 2.5px 0",
        },
    },
    "& .Caracter": {
      margin: "0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#000000",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        },
    },
       '& .Regresar':{
        color: '#F2624F',
        border: '#F2624F 1px solid',
        height: '30px',
        width:'100px',
        borderRadius: '20px',
        letterSpacing: '0.14px',
        textTransform: 'none',
        background:'#FEFAF1',
        top:'calc(50% - 15px)',
        left:'5%',
        zIndex:4,
        [theme.breakpoints.down(502)]: {
          font:'bold 10px lato',
          width: "60px",
        },
      },
  },
    ContenedorLectura:{
        background:'#fff',
        width:'1160px',
        height:'100%',
        padding:'60px 168px 35px ',
        
        transform: (props) =>
        props.scale > 1 ? `scale(${props.scale}) translate(0px, 10%)` : `none`,
        [theme.breakpoints.down(1161)]:{
            width:'502px',
            padding:'60px 0 0 0',
          },
                [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },

        '& .Escritorio':{
            display:'block',
            [theme.breakpoints.down(1161)]:{
                display:'none'
            },
        },
        '& .Tablet':{
            display:'none',
            [theme.breakpoints.down(1161)]:{
                display:'block'
            },
        }
    },
    ContainerTitulo:{
        margin:'0 0 34px 0',
        [theme.breakpoints.down(1161)]:{
            margin:'0 0 34px 0',
        },
        '& .Titulo':{
            font:'48px fredoka one',
            letterSpacing: '0px',
            lineHeight:'58px',
            color: '#F6664D',
            margin:'0',
            textAlign:'center',
            [theme.breakpoints.down(1161)]:{
                font:'43px fredoka one',
                lineHeight:'52px',
            },
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            lineHeight : '30px',
            margin:'0 0 36px 0',
            font:'22px lato',
            color:'#000',
            letterSpacing: '0.20px',
            [theme.breakpoints.down(1161)]:{
                font:'18px lato',
                lineHeight :'28px',
                letterSpacing: '0.17px',
            },
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .Esp':{
            margin:'0 0 36px 0',
        },
        '& .Imagen':{
            [theme.breakpoints.down(1161)]:{
                width:'100%',
            },
        },  
      
    }
}));

const Quién_es_Maria_Goeppert_Mayer = (props) => {

  const {activeStep, setActiveStep, handlePreviewStep, id_actividad} = props
  
  const handleContinue = () => {
    setActiveStep(activeStep - 1)
    handlePreviewStep()
  }

    const {
      handleSize,
    disabledZoom,
    closeZoom,
    handleZoom,
    scale
  }= useContext(DrawerContext);
  const [loading, setLoading] = React.useState(true);
  const wrapperRef = React.useRef(null);
  const imagesLoaded = useOnLoadImages(wrapperRef);

  const detectSize = () => {
    const lectura = document.getElementById("lectura");
    const newWidth = lectura?.offsetWidth;
    const newHeight = lectura?.offsetHeight;
    setLoading(false);
    if (lectura) handleSize(newHeight, newWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", detectSize);
    return () => {
      handleZoom()
      window.removeEventListener("resize", detectSize);
      closeZoom()
    };
  }, []);

  const classes = useStyles({ disabledZoom,scale });
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
             <Grid container item justifyContent="center" className={classes.ContainerInfoLectura}>
                       <Grid container className="BarraInfoLectura">
            <Button className="Regresar" onClick={handleContinue}>
                Regresar
            </Button>
        </Grid>
               <Grid container item className="InfoLectura">
                    <Grid container item direction="column" xs={6} justifyContent="center">
                        <p className="Dato">Grado</p>
                        <p className="Caracter">5</p>
                    </Grid>
                    <Grid container item direction="column" xs={6} justifyContent="center" className="Sesion">
                        <p className="Dato">Sesión</p>
                        <p className="Caracter">44</p>
                    </Grid>
               </Grid>
            </Grid>
            {!imagesLoaded && <Loader />}
        <Grid
            container
            item
            className={classes.ContenedorLectura}
            justifyContent="center"
            id="lectura"
            onLoad={detectSize}
            ref={wrapperRef}
        >
<Drawer id_lectura={id_actividad} />
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                        <h2 className="Titulo">¿Quién es Maria Goeppert-Mayer?</h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container justifyContent='center'>
                        <img className="Imagen Esp" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion44/1.png" alt="Tarjeta" />
                    </Grid>
                    <Grid container sm={12}>
                       <p className='Lectura'>
                        Nací en un pueblo pequeño de Polonia, en 1906. Desde niña me sentí atraída por las 
                        ciencias naturales. Mi padre era médico. Durante casi toda su vida fue profesor en 
                        una importante universidad en Alemania. Constantemente me hablaba de su trabajo.
                        Crecí viendo la pasión con la que él aprendía y enseñaba cada detalle de su disciplina. 
                        Por eso quise seguir sus pasos.<br/><br/> 

                        A los 18 años entré a esa misma universidad. Empecé estudiando matemáticas, pero 
                        un gran profesor, el doctor Max Born, me invitó a trabajar con él en un proyecto de 
                        física cuántica. Al principio no estaba segura de querer cambiar de área, pero 
                        finalmente me enamoré de la física. Pasé años trabajando con el profesor Born 
                        y terminé mi doctorado cuando tenía 24.
                       </p>                   
                    </Grid>
                    <Grid container justifyContent='center'>
                        <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion44/2.png" alt="Tarjeta" />
                    </Grid>
                   
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container >
                        <p className='Lectura'>
                            Al terminar conocí a un joven químico estadounidense llamado Joseph Mayer. Nos 
                            casamos y decidimos mudarnos a Estados Unidos para formar una familia. Ahí 
                            tuvimos dos hijos: Maria Ann y Peter. La misma universidad le ofreció a Joseph un 
                            trabajo como profesor de Química y a mí un pequeño puesto de medio tiempo donde 
                            me pagaban poco. <br/><br/>
                            
                            Tres veces viajé a Alemania para ayudarle al doctor Born con su trabajo, hasta que el 
                            partido nazi tomó el poder. El profesor, sus colegas y yo perdimos nuestro progreso 
                            en el laboratorio. En esos años había muchas vidas en peligro. Como mi esposo y yo 
                            vivíamos en Estados Unidos, pude ayudar a muchas personas a salir de Alemania y 
                            refugiarse en otros países. <br/><br/>
                            
                            Cuando regresaba de uno de esos viajes me encontré con que Joseph había perdido 
                            su empleo. Nunca supimos la causa, pero quizá tuvo que ver con que a muchas 
                            personas no les gustaba que hubiera una mujer trabajando en el Laboratorio de 
                            Física de la universidad, mucho menos una tan cercana a los alemanes. No les 
                            importaba que mis amigos fueran inocentes. Para ellos todos los alemanes eran 
                            iguales. <br/><br/>
                            
                            Tuvimos que mudarnos a Nueva York. Allí conseguí un puesto en el Departamento de 
                            Física de una universidad, aunque no me pagaban por mis investigaciones. De 
                            cualquier manera, en esos años trabajé arduamente y conseguí que me seleccionaran 
                            como miembro de la Sociedad Americana de Física. Eso me abrió muchas puertas: 
                            por fin me ofrecieron algunos empleos remunerados.
                        </p>
                    </Grid>
                    <Grid container justifyContent='center'>
                        <Grid container justifyContent='center'>
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion44/3.png" alt="Tarjeta" />
                        </Grid>
                    </Grid>

                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container >
                        <p className='Lectura'>
                            Con una plaza en la Universidad de Chicago, pude trabajar como profesora e 
                            investigadora en el proyecto más importante de mi vida, junto a Jensen, un colega y 
                            amigo mío. En 1955 publicamos un libro con nuestras investigaciones. Nuestro 
                            <em> modelo de capas nuclear</em> describía algunas propiedades de la materia que no se 
                            comprendían completamente antes de nuestro trabajo. Gracias a esta investigación, 
                            en 1963 me ofrecieron el galardón más importante que puede ganar un científico: el 
                            Premio Nobel.
                        </p>
                    </Grid>
                    <Grid container justifyContent='center'>
                        <Grid container justifyContent='center'>
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion44/4.png" alt="Tarjeta" />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container >
                        <p className='Lectura'>
                            Fui la segunda mujer en ganar el Nobel de Física. Esperaba que fuera un motivo de 
                            orgullo para las instituciones en las que trabajé, pero en muchos periódicos ni 
                            siquiera escribieron mi nombre al dar la noticia. Ésa fue una de las muchas veces en 
                            las que me minimizaron por ser mujer. Pero logré sobreponerme. Para mí lo 
                            importante era la investigación y nunca dejé que nadie frenara mi deseo de aprender. 
                            Sé que mi historia ha inspirado a muchas mujeres para perseguir una carrera 
                            científica. Actualmente mi trabajo es bien reconocido. Incluso hay un premio que 
                            lleva mi nombre: se lo otorgan a las jóvenes físicas que han tenido una trayectoria 
                            académica destacada. <br/><br/>
                            
                            Aún hoy sigue siendo difícil para las mujeres estudiar matemáticas, física o química.
                            Muchas personas las sabotean y las tratan como si fueran menos capaces para la 
                            ciencia que los hombres. Por supuesto, esta idea es falsa y profundamente injusta. 
                            Cualquier persona puede destacar en un campo de investigación. ¿Quién sabe? Tal 
                            vez entre tus amigas y conocidas se encuentre la próxima ganadora de un Premio 
                            Nobel.
                        </p>
                    </Grid>
                </Grid>
        </Grid>
        <Grid container item style={{ marginTop: 80}} ></Grid>
    </Grid>
    );
}
 
export default Quién_es_Maria_Goeppert_Mayer;
