import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
    ContenedorLectura:{
        width:'502px',
                  [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
    },
    ContainerTitulo:{
        margin:'0 0 46px 0',
        '& .Titulo':{
            letterSpacing: '0px',
            color: '#0DA0B4',
            margin:'0',
            font:'43px fredoka one',
            lineHeight:'52px',
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            margin:'0 0 36px 0',
            color:'#000',
            font:'18px lato',
            lineHeight :'28px',
            letterSpacing: '0.17px',
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        
        '& .Imagen':{
            width:'100%',
        },
    },
}));


const Ya_no_hay_agua = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>

            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >
                            Ya no hay agua
                    </h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                      <p className="Lectura">
                      —Cuida el agua, no la desperdicies —solía decir su madre, y a Mía se le quedó 
                      grabada esa frase.<br/><br/> 
                      
                      Cierto día, por la mañana, guardó un poco de agua en un vaso y lo puso debajo de su 
                      cama para beberlo más tarde. Durante todo el día no volvió a ver agua por ningún lado.<br/><br/> 
                      
                      Fue a la escuela. La mañana transcurrió normal. Al mediodía su mamá pasó por ella y 
                      regresaron juntas a casa, cantando. Mía se distrajo un rato con sus juguetes, hasta 
                      que escuchó a su mamá gritarle que ya era hora de comer, que se lavara las manos.<br/><br/> 

                      Para su sorpresa, al abrir la llave del lavabo, en lugar de agua salió… ¡arena!
                      </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <Grid container >
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img2do/Sesion13/1.png" alt="Tarjeta" />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container sm={12}>
                      <p className="Lectura"> 
                        —¡Mamááá! —gritó asustada, mientras corría a la cocina. Con horror, vio que su 
                        mamá lavaba los trastes ¡con arena!—. ¿Qué está pasando?<br/><br/> 
                        
                        Su mamá se quitó la arena con un trapo de cocina y la abrazó, sin entender por qué 
                        lloraba.<br/><br/> 
                        
                        —¿Qué pasa, mi amor? Parece que viste un fantasma.<br/><br/> 
                        
                        Mía lloraba sin poder decir nada. Su mamá la calmó y la llevó al comedor. Donde la 
                        esperaban unos ricos fideos… ¡revueltos con arena!<br/><br/> 
                        
                        —¿Arena otra vez? —preguntó Mía, señalando la sopa.<br/><br/> 
                        
                        —Es para que crezcas, mi amor. Te hace bien.<br/><br/> 
                        
                        Mía no quiso ni probarla. Se fue directo al plato fuerte, que para su alivio era un 
                        pedazo seco de carne. Su mamá se enojó mucho y sentenció que la dejaría sin postre, 
                        pero a Mía no le importó, en especial cuando se enteró de que el postre era una 
                        gelatina de limón… con arena cuajada.<br/><br/> 
                        
                        Por la noche, antes de dormir, su mamá le ordenó que fuera a bañarse. De la ducha 
                        salieron finos granos de arena que llenaron la bañera lentamente. Mía, por supuesto, 
                        ni siquiera se metió.
                      </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <Grid container justifyContent="center" alignItems='flex-end'>
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img2do/Sesion13/2.png" alt="Tarjeta" />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container sm={12}>
                      <p className="Lectura"> 
                        Se fue a la cama sin bañar, angustiada por pensar que nunca más habría agua en su 
                        casa. De repente recordó el vaso que había debajo de la cama y le dio por asomarse, 
                        pero temía que fuera sólo un montoncito de arena.<br/><br/> 
                        
                        No había arena en el vaso. Tampoco había agua, sino que brotó una luz que fue 
                        creciendo y creciendo hasta iluminar la habitación entera. El rostro de Mía se iluminó 
                        también y se le dibujó una suave sonrisa.
                      </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <Grid container  >
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img2do/Sesion13/3.png" alt="Tarjeta" />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}
 
export default Ya_no_hay_agua;
