import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
    ContenedorLectura:{
        width:'502px',
                  [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
    },
    ContainerTitulo:{
        margin:'0 0 34px 0',
        '& .Titulo':{
            letterSpacing: '0px',
            color: '#B46D8C',
            margin:'0',
            textAlign:'center',
            font:'43px fredoka one',
            lineHeight:'52px',
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            margin:'0 0 36px 0',
            color:'#000',
            font:'18px lato',
            lineHeight :'28px',
            letterSpacing: '0.17px',
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .Esp':{
            padding:'0',
        },
        '& .Imagen':{
            width:'100%',
        }, 

    }
}));

const El_origen_del_mole = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >El origen del mole</h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                      <p className="Lectura">
                        Cuenta la leyenda que hace mucho tiempo, en el pueblo escondido de Molotitlán, la 
                        enojona esposa del alcalde, Eva de Alba y Bocachica, fue la responsable de la 
                        invención del mole. Ella se enojaba con su cocinera todo el tiempo. Un día, se molestó 
                        tanto que le dijo que no iba a tolerar más un platillo mal hecho.
                      </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <Grid container justifyContent="center" >
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img1ro/Sesion25/1.png" alt="Tarjeta" />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                      <p className="Lectura">
                        Al día siguiente, la cocinera agregó a su guisado algunas especias, como clavo, 
                        pimienta y comino. Sin embargo, la escena se repitió: enfurecida, doña Eva la llamó 
                        para decirle que su platillo estaba muy salado. Entonces, la cocinera añadió canela, 
                        anís y chocolate. Pero doña Eva dijo que no tenía sabor, así que la cocinera puso 
                        todos los chiles que encontró.
                      </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <Grid container justifyContent="center" >
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img1ro/Sesion25/2.png"alt="Tarjeta" />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                      <p className="Lectura Esp">
                        Y así, la cocinera fue añadiendo toda clase de ingredientes a su guisado. Pero 
                        doña Eva siempre encontraba una razón para enfadarse. Por último, la cocinera 
                        decidió moler todo junto y mezclarlo. El resultado fue inesperado. Se trataba de 
                        una salsa de color oscuro. ¡Finalmente, doña Eva pidió que le sirvieran otro plato 
                        de ese rico guiso!
                      </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <Grid container justifyContent="center" >
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img1ro/Sesion25/3.png" alt="Tarjeta" />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                      <p className="Lectura">
                        Desde entonces, doña Eva dejó de enojarse tanto. Empezó a ser más amable con su 
                        cocinera y a comer muchas porciones del nuevo guiso. Por cierto, el nuevo platillo fue 
                        llamado <em>mole</em>, por haberse creado en Molotitlán.
                      </p>
                    </Grid>
                </Grid>       
        </Grid>
    </Grid>
    );
}
 
export default El_origen_del_mole;
