import React, { useReducer, useEffect } from "react";
import AuthContext from "./auth/AuthContext";
import authReducer from "./auth/authReducer";

import BaseRoutes from "./components/routes/BaseRoutes";
import TemaProvider from "./TemaConfig";

const CircuitoDeLectoresApp = () => {
  const init = () => {
    return JSON.parse(localStorage.getItem("user")) || { logged: false };
  };
  const [user, dispatch] = useReducer(authReducer, {}, init);

  useEffect(() => {
    if (user.logged) {
      localStorage.setItem("user", JSON.stringify(user));
    }
  }, [user]);

  return (
    <AuthContext.Provider value={{ user, dispatch }}>
      <TemaProvider>
        <BaseRoutes />
      </TemaProvider>
    </AuthContext.Provider>
  );
};

export default CircuitoDeLectoresApp;
