import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
    ContenedorLectura:{
        width:'502px',
                  [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
    },
    ContainerTitulo:{
        margin:'0 0 34px 0',
        '& .Titulo':{
            letterSpacing: '0px',
            color: '#0CB5D6',
            margin:'0',
            textAlign:'center',
            font:'43px fredoka one',
            lineHeight:'52px',
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            margin:'0 0 36px 0',
            color:'#000',
            font:'18px lato',
            lineHeight :'28px',
            letterSpacing: '0.17px',
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .SubTitulo':{
            color:'#A061A7',
            margin:'0',
            lineHeight :'28px',
            font:'bold 26px lato',
            letterSpacing: '0.24px',
            [theme.breakpoints.down(502)]:{
                font:'bold 18px lato',
                lineHeight :'24px',
                letterSpacing: '0.21px',
            },
        },
        '& .TextImg':{
            color:'#717476',
            margin:'21px 0 0 0',
            font:' 16px lato',
            lineHeight :'25px',
            letterSpacing: '0.15px',
            [theme.breakpoints.down(502)]:{
                font:' 11px lato',
                lineHeight :'14px',
                letterSpacing: '0.10px',
            },
        },
        '& .Imagen':{
            width:'100%',
        },   
    }
}));


const La_importancia_del_calcio = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >Los portadores de luz</h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container sm={12}>
                      <p className="Lectura "> 
                        Muchos seres vivos producen luz en sus cuerpos para atraer a sus parejas y a sus 
                        presas, y también para ahuyentar a sus depredadores. La capacidad de generar luz 
                        que tienen algunos organismos, principalmente marinos, se conoce como 
                        bioluminiscencia.
                      </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <Grid container justifyContent="center">
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img4to/Sesion17/1.png" alt="Tarjeta" />
                        </Grid>
                        <Grid container item >
                            <p className='TextImg'>
                                Muchos turistas acuden a las playas a observar el fenómeno de la bioluminiscencia.
                            </p>
                        </Grid>
                    </Grid>
                </Grid>
               
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container sm={12}>
                        <p className="Lectura "> 
                            <h3 className='SubTitulo'>Luz en el fondo del mar</h3><br/>

                            En el océano, los rayos del Sol penetran hasta 200 metros de profundidad, según lo 
                            permitan el lugar, las corrientes y la contaminación del agua. Se conoce como <em>zona 
                             fótica</em> a todo el espacio marino en el que hay luz solar. La mayoría de los organismos 
                            acuáticos producen luz azul para iluminar sus hábitats durante la noche, o en los 
                            espacios donde hay oscuridad. Algunos de los animales que realizan esto son los 
                            peces, los moluscos, las estrellas de mar y las algas del tipo <em>dinoflageladas</em>.<br/><br/> 
                            
                            En la oscuridad de las profundidades, producir luz les ayuda a atraer a sus presas, 
                            desorientar a sus depredadores o atraer a sus parejas. En muchos casos, los 
                            organismos no hacen la luz por ellos mismos, sino que utilizan bacterias que viven en 
                            su piel. Algunos otros usan órganos especiales que parecen linternas.<br/><br/>

                            <h3 className='SubTitulo'>¿Cómo se produce la luz?</h3><br/>
                            
                            Algunos animales generan luz cuando se descompone una proteína llamada <em>luciferina</em>. 
                             Esta proteína crea luz cuando entra en contacto con el oxígeno. Para lograrlo, 
                            necesita la ayuda de otra proteína conocida como <em>luciferasa</em>. También requiere la 
                            energía que producen los seres vivos: el <em>ATP</em> (Adenosín trifosfato).<br/><br/> 
                            
                            <h3 className='SubTitulo'>¿Para qué producir luz?</h3><br/>
                            
                            En cuanto a la superficie terrestre, los seres que crean luz son las luciérnagas, las 
                            larvas de escarabajo y algunos hongos. Lo hacen con diferentes propósitos. Por 
                            ejemplo, las hembras de las luciérnagas la usan para indicar su ubicación a los 
                            machos. Para ello, permanecen en el suelo del bosque durante las noches de verano y 
                            emiten luz. Se trata de una forma de cortejo que permite la reproducción de la 
                            especie. <br/><br/>
                            
                            En el Congo, existen unos hongos denominados <em>fuego chimpancé</em> que producen luz 
                            verde. A diferencia de las luciérnagas, la utilizan para atraer a los insectos y a otros 
                            animales que toman sus esporas y las sueltan en el aire.
                        </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <Grid container justifyContent="center">
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img4to/Sesion17/2.png" alt="Tarjeta" />
                        </Grid>
                        <Grid container item >
                            <p className='TextImg'>
                                Existe una gran variedad de seres con bioluminiscencia, entre los que destacan los hongos que recurren a ello para esparcir sus esporas.
                            </p>
                        </Grid>
                    </Grid>
                </Grid>
                
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container sm={12}>
                        <p className="Lectura "> 
                            <h3 className='SubTitulo'>Los efectos del turismo</h3><br/>
                            
                            El turismo puede afectar la bioluminiscencia. En algunas playas se han puesto 
                            lámparas para alumbrar durante la noche y en las caminatas en el bosque se usan 
                            linternas. Esta luz artificial hace que las algas y los escarabajos dejen de generar su 
                            luz. Todo esto amenaza directamente la conservación de las especies.<br/><br/> 
                            
                            <h3 className='SubTitulo'>Las aplicaciones de la bioluminiscencia</h3><br/>
                            
                            Los seres humanos han aplicado esta capacidad para producir luz de algunos 
                            organismos en la medicina. Por ejemplo, se ha empleado en sustancias nombradas 
                            <em> marcadores</em>, que ayudan a identificar las células del cáncer. <br/><br/>
                            
                            Recientemente, se han modificado las características de algunas plantas para 
                            hacerlas producir luz natural. El proceso se inició para que estas iluminen las calles
                            en el futuro y ya no haya necesidad de instalar alumbrado público. <br/><br/>
                            
                            La bioluminiscencia representa un atractivo para el turismo. Pero es importante ser 
                            conscientes del impacto que provocamos en los hábitats de los organismos que la 
                            producen y busquemos formas de cuidarlos. <br/><br/>
                            
                            Ya has conocido varios ejemplos de bioluminiscencia y las funciones que esta tiene 
                            tanto en la tierra como en el agua. ¿Tú sabes de otros animales que tengan esta 
                            capacidad luminosa?
                        </p>
                    </Grid>
                </Grid>
        </Grid>
    </Grid>
    );
}
 
export default La_importancia_del_calcio;
