import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({

    ContenedorLectura:{
        width:'502px',
        [theme.breakpoints.down(502)]:{
            width:'350px',
        },
    },
    ContainerTitulo:{
        margin:'0 0 46px 0',
        '& .Titulo':{
            letterSpacing: '0px',
            color: '#00AEFC',
            margin:'0',
            textAlign:'center',
            font:'43px fredoka one',
            lineHeight:'52px',
            textAlign:'center',
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
        
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            margin:'0 0 36px 0',
            color:'#000',
            lineHeight :'28px',
            font:'18px lato',
            letterSpacing: '0.17px',
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .SubTitulo':{
            color:'#FF678B',
            margin:'0',
            font:'bold 26px lato',
            letterSpacing:'.24px',
            [theme.breakpoints.down(502)]:{
                font:'bold 18px lato',
                lineHeight :'24px',
                letterSpacing: '0.21px',
            },
        },
        '& .Imagen':{
            width:'100%',
        },
    },
}));

const La_música_y_los_festivales_de_hoy = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >
                    La música y los festivales de hoy
                    </h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura ">
                            La música electrónica es un fenómeno artístico relativamente nuevo que ha sido 
                            posible gracias al progreso de la tecnología. Sus mayores detractores afirman que 
                            sólo es música con ritmos pregrabados creados por computadoras o en su defecto 
                            mezclas de canciones viejas. En conclusión, opinan que no es un arte. Por otro lado, la 
                            creciente organización de festivales de música, el gran número de artistas y los 
                            críticos especializados hacen suponer lo contrario.
                        </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <Grid container justifyContent="center" alignItems='flex-end'>
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion12/1.png" alt="Tarjeta" />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container sm={12}>
                        <p className="Lectura"> 
                            Cada año, en Europa y en Estados Unidos de América se organizan los principales  
                            festivales de música electrónica. Estos eventos han hecho mucho más que sólo 
                            presentar a los artistas y su música. Se han convertido en verdaderas puestas en
                            escena, similar a lo que sucede en un teatro. Son una especie de ópera moderna, 
                            donde la música es acompañada con grandes escenografías, luces y baile, para crear
                            momentos artísticos inigualables. <br/><br/>
                            
                            Debido a la creciente importancia de los festivales, la revista más importante de 
                            música electrónica, <em>DJ Mag,</em> encuestó en 2019 a los mejores DJ para preguntarles 
                            cuáles consideraban eran los mejores festivales del mundo. A continuación 
                            encontrarás las respuestas más aceptadas. <br/><br/>
                            
                            <h3 className='SubTitulo'>1. Tomorrowland </h3><br/>

                            Este festival anual se lleva a cabo en el pueblo de Boom, Bélgica, desde 2005. 
                            Durante seis días del verano, los mejores DJ se reúnen ante aproximadamente 400 
                            mil personas. Esto le ha valido el título de festival más grande del mundo. <br/><br/>
                            
                            Es el único festival que ha podido reunir a los mejores DJ. Muchos de ellos incluso
                            crean música específica para ese evento o graban sets enteros en vivo desde ahí. <br/><br/>
                            
                            <h3 className='SubTitulo'>2. Glastonbury</h3><br/>
                            
                            Es uno de los festivales más viejos del mundo, pues existe desde la década de 1970. 
                            Se lleva a cabo en Inglaterra. Aunque en su origen no había música electrónica, ésta 
                            fue introducida a finales de la década de 1980. Con el paso del tiempo, ha ocupado 
                            un lugar importante, aunque el festival sigue sin estar enfocado en ese género 
                            musical. <br/><br/>
                            
                            El sitio donde se lleva a cabo es el más grande del mundo. La extensión de sus áreas 
                            verdes es ideal para disfrutar de las presentaciones de importantes DJ. <br/><br/>

                            <h3 className='SubTitulo'>3. Ultra Music</h3><br/>
                            
                            Este festival surgió en las playas de Miami en 1999. Aunque ha cambiado de sitio 
                            varias veces, se mantiene dentro de Florida. De la lista de festivales, es el único que 
                            ha hecho réplicas en distintos lugares de Latinoamérica, como Santiago, Lima, 
                            Buenos Aires, Asunción y la Ciudad de México. <br/><br/>

                            Esta apertura ha atraído a diversos artistas latinos, que preparan presentaciones 
                            exclusivas para su participación, lo que además ha generado la mezcla de ritmos 
                            latinos con la tradicional música electrónica. <br/><br/>
                            
                            <h3 className='SubTitulo'>4. Awakenings</h3><br/>
                            
                            Este festival tuvo sus inicios en 1997 en Ámsterdam. Se lleva a cabo dentro de una 
                            antigua fábrica de gas en las afueras de la ciudad. Esto ayuda a que el ambiente sea 
                            distinto y sorprendente, pues se mezcla lo viejo del edificio con lo moderno de la 
                            música. <br/>
                            
                            Con más de 80 mil asistentes y alrededor de 100 artistas, este festival crece en 
                            popularidad. Cabe añadir que es uno de los más importantes, especializado en 
                            música <em>techno</em>, que es una subdivisión de la música electrónica.
                        </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <Grid container justifyContent="center" alignItems='flex-end'>
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion12/2.png" alt="Tarjeta" />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container sm={12}>
                        <p className="Lectura"> 
                            <h3 className='SubTitulo'>5. Sónar</h3><br/> 
                            
                            La fama de este festival comenzó en Barcelona en 1994, lo que permitió que muchos 
                            DJ españoles mostraran su talento. A partir de ahí, su evolución ha sido muy rápida y 
                            ha sabido innovar tecnológicamente las presentaciones de los artistas. Incluyen 
                            proyecciones en tercera dimensión, videos, realidad aumentada y otros avances que
                            se fusionan con la música. <br/><br/>
                            
                            En la actualidad, ya no está a discusión si la música electrónica y sus diversas 
                            colaboraciones con más géneros son arte. Parece más bien una cuestión de gustos. 
                            Con esa legitimidad, los festivales se han multiplicado, lo que ha permitido expandir 
                            su alcance.
                        </p>    
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}
 
export default La_música_y_los_festivales_de_hoy;
