import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
    ContenedorLectura:{
        width:'502px',
                  [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
    },

    ContainerTitulo:{
        margin:'0 0 36px 0',
        '& .Titulo':{
            letterSpacing: '0px',
            color: '#0CB5D6',
            margin:'0',
            textAlign:'center',
            font:'43px fredoka one',
            lineHeight:'52px',
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            margin:'0 0 36px 0 ',
            color:'#000',
            font:'18px lato',
            lineHeight :'28px',
            letterSpacing: '0.17px',
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .Esp':{
            margin:'0',
        },

        '& .SubTitulo':{
            margin:'0',
            color:'#F6664D',
            font:'bold 22px lato',
            lineHeight :'24px',
            letterSpacing: '0.21px',
            [theme.breakpoints.down(502)]:{
                font:'bold 18px lato',
                lineHeight :'20px',
                letterSpacing: '0.16px',
            },
        },
        '& .TextImg':{
            margin:'22px 0 0 0',
            alignSelf:'flex-start',
            color:'#717476',
            font:' 16px lato',
            lineHeight :'25px',
            letterSpacing: '0.15px',
            [theme.breakpoints.down(502)]:{
                font:' 11px lato',
                lineHeight :'14px',
                letterSpacing: '0.10px',
            },
        },
        '& .Imagen1':{
            width:'87.64%'
        },
        '& .Imagen2':{
            width:'100%'
        },
        '& .Ul':{
            margin:'0',
            padding:'0 0 0 19px '
         },
         '& .li':{
            color:'#F6664D',
            margin:'0 0 8px 0',
            [theme.breakpoints.down(502)]: {
                margin:'0 0 4px 0',
            },
         },
    },
    

}));

const Qué_nos_hace_seres_humanos_responsables = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >
                        ¿Qué nos hace seres <br/>
                        humanos responsables?
                    </h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                            <h3 className="SubTitulo"> Una breve reflexión sobre la responsabilidad</h3><br/>

                            A lo largo de toda tu vida has dicho cosas y realizado acciones. Comunicarse y actuar
                             es parte de lo que distingue al ser humano.
                        </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <Grid container justifyContent="center" >
                            <img className="Imagen1" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img2do/Sesion24/1.png" alt="Tarjeta" />
                        </Grid>
                        <Grid container justifyContent="center"> 
                            <p className='TextImg'>El <em>diálogo</em> es la principal herramienta para la resolución de problemas.</p>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                            Varias de esas cosas las haces de manera <em>premeditada</em>, es decir, piensas las cosas 
                            antes de hacerlas o decirlas. Ése es el ideal de convivencia para que todos vivamos en 
                            armonía.<br/><br/> 
                            
                            ¿Alguna vez has hecho algo por accidente o en un momento en el que no te sientes 
                            del todo bien (como cuando estás triste o enojado)? Por ejemplo, si te tropiezas y 
                            derramas tu helado sobre otra persona es un accidente. No era tu intención manchar 
                            su ropa u ofenderla con esa acción. De hecho, también tú resultaste afectado.<br/><br/> 
                            
                            Pero si <em>no</em> es un accidente y de manera premeditada lanzas tu helado sobre otro, la 
                            intención <em>no</em> es buena. Por lo tanto, las consecuencias tampoco lo serán: se generará 
                            un problema que tal vez lastime sus emociones.<br/><br/> 
                            
                            En ambos casos es importante hacerse responsable de lo sucedido. En el primero, 
                            necesitas dejar claro que no lo hiciste a propósito, que lo sientes, y preguntar de qué 
                            manera puedes ayudar a la otra persona.<br/><br/> 
                            
                            En el segundo, aún podrías recapacitar acerca de tus emociones y sentimientos, 
                            sobre aquello que te llevó a actuar de esa forma. Tal vez estabas molesto con la otra 
                            persona. Si era así, lo mejor habría sido decírselo, hablarle de lo que te molestó: si fue 
                            algo que hizo o dijo. También es importante que no te expreses de manera ofensiva 
                            cuando lo hagas.
                        </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <Grid container justifyContent="center">
                            <img className="Imagen2" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img2do/Sesion24/2.png" alt="Tarjeta" />
                        </Grid>
                        <Grid container > 
                            <p className='TextImg'>
                                En el proceso del diálogo entran en juego las emociones. Por eso es importante que todas las 
                                partes colaboren. La responsabilidad de resolver amablemente un conflicto recae en cada 
                                uno de los involucrados.
                            </p>
                        </Grid>
                    </Grid>
                </Grid>

                
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                            En resumen:
                        </p>
                        <ul className='Ul'>
                            <li className='li'>
                                <p className="Lectura Esp">
                                Actuar incorrectamente de manera premeditada no es nada recomendable.
                                </p>
                            </li>
                            <li className='li'>
                                <p className="Lectura Esp">
                                Actuar sin pensar porque tienes emociones confusas puede resultar mal. Es mejor 
                                esperar a que te tranquilices.
                                </p>
                            </li>
                            <li className='li'>
                                <p className="Lectura Esp">
                                Pensar antes de hacer o decir las cosas es positivo.
                                </p>
                            </li>
                            <li className='li'>
                                <p className="Lectura Esp">
                                Hacer o decir las cosas sin lastimar ni ofender es lo mejor para una comunicación
                                fluida y una convivencia sana.
                                </p>
                            </li>
                        
                        </ul>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                            Recuerda: eres libre, pero tu libertad convive con la de todos los demás. El diálogo y 
                            el cuidado son algunos de los elementos que te convierten en un ser humano 
                            responsable.
                        </p>
                    </Grid>
                </Grid>
        </Grid>
    </Grid>
    );
}
 
export default Qué_nos_hace_seres_humanos_responsables;
