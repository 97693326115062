import React, { useContext } from "react";
import { makeStyles, Grid, styled, Dialog } from "@material-ui/core";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import { GridCloseIcon } from "@mui/x-data-grid";
import IconButton from "@mui/material/IconButton";
import PropTypes from "prop-types";
import UsabilityContext from "../../../usability/UsabilityContext";
import gifHeraldoG1 from "../../imgs/04_Heraldo_G1_Pantalla_final.gif";
import gifHeraldoG2 from "../../imgs/04_Heraldo_G2_Pantalla_final.gif";
import gifHeraldoG3 from "../../imgs/04_Heraldo_G3_Pantalla_final.gif";
import gifHeraldoG4 from "../../imgs/04_Heraldo_G4_Pantalla_final.gif";
import gifHeraldoG5 from "../../imgs/04_Heraldo_G5_Pantalla_final.gif";
import gifHeraldoG6 from "../../imgs/04_Heraldo_G6_Pantalla_final.gif";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-root": {
    backdropFilter: "blur(10px)",
  },
  "& .MuiDialogContent-root": {
    padding: "0",
    position: "relative",
    [theme.breakpoints.up("sm")]: {
      paddingTop: "0",
      paddingBottom: "0",
    },
  },
  "& .MuiDialog-paper": {
    borderRadius: "12.1px",
    backgroundColor: "transparent",
    boxShadow: "none",
    margin: "10px",
    [theme.breakpoints.up("lg")]: {
      margin: "32px",
    },
  },
  "& .MuiDialog-container": {
    backdropFilter: "blur(10px)",
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
    display: "flex",
    justifyContent: "center",
    gap: "5%",
  },
  "& .MuiDialogTitle-root": {
    [theme.breakpoints.up("sm")]: {
      // margin: "20px 0",
      padding: "22px",
    },
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 3 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <GridCloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const useStyles = makeStyles((theme) => ({
  evaluationsContainer: {
    // height: "100%",
    "@media(min-height:1024px)": {
      // height: "auto",
    },
  },
  ContainerCarta: {
    // padding: "40px 0 ",
    boxSizing: "border-box",
    // height: "calc(100vh - 79.5px)",
    overflow: "auto",
    // background: "black",
    [theme.breakpoints.down("sm")]: {
      "@media (orientation: landscape)": {
        paddingBottom: "10%",
      },
    },

    "& .Br": {
      [theme.breakpoints.down(732)]: {
        display: "none",
      },
    },
    "& .Texto": {
      color: "#D0F6FF",
      font: "normal 28px lato",
      lineHeight: "34px",
      margin: 0,
      textAlign: "center",
      width: "935px",
      [theme.breakpoints.down(1161)]: {
        font: "normal 22px lato",
        lineHeight: "28px",
        width: "732px",
      },
      [theme.breakpoints.down(732)]: {
        font: "bold 16px lato",
        lineHeight: "18px",
        width: "350px",
      },
    },
    "& .Texto2": {
      color: "#F4D99E",
      font: "normal 26px Helvetica",
      // font: "bold 26px Helvetica Neue",
      lineHeight: "34px",
      margin: 0,
      textAlign: "center",
      maxWidth: "801px",
      [theme.breakpoints.down(1161)]: {
        width: "532px",
        font: "normal 20px Helvetica",
        // font: "bold 20px Helvetica Neue",
        lineHeight: "28px",
      },
      [theme.breakpoints.down(732)]: {
        width: "350px",
        font: "normal 16px Helvetica",
        // font: "bold 16px Helvetica Neue",
        lineHeight: "16px",
      },
    },
    "& .Titulo": {
      color: "#FCDADE",
      font: "42px Fredoka One",
      lineHeight: "51px",
      margin: 0,
      [theme.breakpoints.down(1161)]: {
        font: "32px Fredoka One",
        lineHeight: "40px",
      },
      [theme.breakpoints.down(732)]: {
        font: "normal 26px Fredoka One",
        // lineHeight: "26px",
      },
    },
    "& .Titulo2": {
      color: "#DDF0DB",
      font: "36px Fredoka One",
      lineHeight: "42px",
      margin: 0,
      [theme.breakpoints.down(1161)]: {
        font: "26px Fredoka One",
        lineHeight: "34px",
      },
      [theme.breakpoints.down(732)]: {
        font: "normal 22px Fredoka One",
        lineHeight: "21px",
      },
    },
  },
  Img: {
    height: "310px",
    "@media(min-height:800px)": {
      height: "410px",
    },
    "@media(min-height:1200px)": {
      height: "550px",
    },
    "@media(min-height:1600px)": {
      height: "750px",
    },
  },
}));

const PantallaFinal = (props) => {
  const classes = useStyles();
  const {  grade } = props;
  const { openSesionFinalModal, handleSesionFinalModal } = useContext(UsabilityContext);

  const heraldos = {
    grado1: gifHeraldoG1,
    grado2: gifHeraldoG2,
    grado3: gifHeraldoG3,
    grado4: gifHeraldoG4,
    grado5: gifHeraldoG5,
    grado6: gifHeraldoG6,
  };

  return (
    <BootstrapDialog
      onClose={handleSesionFinalModal}
      aria-labelledby="customized-dialog-title"
      open={openSesionFinalModal}
      maxWidth="xl"
      fullScreen
    >
      <BootstrapDialogTitle id="customized-dialog-title" onClose={handleSesionFinalModal}></BootstrapDialogTitle>
      <DialogContent>
        <Grid container item xs={12} className={classes.evaluationsContainer}>
          <Grid container item justifyContent="center" className={classes.ContainerCarta}>
            <Grid container item justifyContent="center">
              <p className="Texto">
                <span className="Titulo">Concluiste la aventura</span>
                <br />
                <br />A lo largo de este recorrido fortaleciste tus competencias <span>&nbsp;</span>
                <br className="Br" />
                de comprensión lectora, así como tu capacidad para interactuar <br className="Br" />
                con los demás poniendo en práctica tus competencias socioemocionales.
              </p>
            </Grid>
            <img
              className={classes.Img}
              src={heraldos[grade]}
              alt="Heraldo celebrando"
            />
            <Grid container item justifyContent="center">
              <p className="Texto2">

                <h3 className="Titulo2">¡Esperamos que también hayas disfrutado tu viaje en la máquina del tiempo!</h3>
                <br />
                Recuerda que puedes volver al circuito para revivir esta travesía.
              </p>
            </Grid>
          </Grid>
        </Grid>
        <>
        </>
      </DialogContent>
    </BootstrapDialog>
  );
};

export default PantallaFinal;
