import React from "react";
import { Button, makeStyles, Grid } from "@material-ui/core";
import { Link } from "react-router-dom";
import Maquina from "../../imgs/MaquinaCable.png";
import TermsAndConditions from "../TermsAndConditions/TermsAndConditions";

const useStyles = makeStyles((theme) => ({
  ContainerWelcomePage: {
    height: "100%",
  },
  ContainerImgTxt: {
    position: "relative",
    height: "100%",
    width: "100%",
    overflow: "auto",
    maxHeight: "100vh",
    [theme.breakpoints.down(1280)]: {
      width: "100vw",
      minWidth: "320px",
    },
  },
  Bienvenida: {
    padding: "84px 0 0 250px",
    flexWrap: "nowrap",
    flexDirection: "column",
    overflow: "auto",
    height: "auto",
    [theme.breakpoints.up("lg")]: {
      padding: "84px 0 0 140px",
      // height: "993px",
      flexWrap: "wrap",
    },
    background:
      "linear-gradient(32deg, rgba(185,101,211,0.2105042700674019) 1%, rgba(255,255,255,1) 20%, rgba(255,255,255,1) 36%, rgba(254,254,254,1) 77%, rgba(252,227,80,0.2777311608237045) 100%)",
    [theme.breakpoints.down(1571)]: {
      padding: "84px 0 0 140px",
    },
    [theme.breakpoints.down(1441)]: {
      padding: "84px 0 0 120px",
    },
    [theme.breakpoints.down(1401)]: {
      padding: "84px 0 0 100px",
    },
    [theme.breakpoints.down(1350)]: {
      padding: "84px 0 0 80px",
    },
    [theme.breakpoints.down(1280)]: {
      padding: "43px 0 0 0",
      alignItems: "center",
      // height: "1080px",
    },
  },

  Titulo: {
    width: "693px",
    height: " 73px",
    [theme.breakpoints.down(1280)]: {
      justifyContent: "center",
      height: " 111px",
      width: "100%",
    },
    "& .Titulo1": {
      font: "40px Fredoka One",
      color: "#F2624F",
      margin: "0 12px 0 0 ",
      [theme.breakpoints.down(768)]: {
        margin: "0",
        height: "36px",
      },
    },
    "& .Titulo2": {
      font: "40px Fredoka One",
      color: "#DC5EA6",
      margin: "0",
    },
    "& .Title": {
      margin: "0",
      justifyContent: "center",
    },
    "& .escritorio": {
      [theme.breakpoints.down(768)]: {
        display: "none",
      },
    },
    "& .tablet": {
      font: "36px Fredoka One",
      [theme.breakpoints.up(768)]: {
        display: "none",
        width: "100%",
      },
    },
    "& .Pregunta": {
      position: "relative",
      bottom: "10px",
    },
  },

  Texto: {
    width: "670px",
    minHeight: "85vh",

    // height: " 620px",
    [theme.breakpoints.down(1024)]: {
      justifyContent: "center",
      width: "100%",
      height: "auto",
      minHeight: "90vh",
    },
    [theme.breakpoints.up("xl")]: {
      minHeight: "90vh",
    },

    "& .Texto1": {
      font: "22px lato",
      color: "#505759",
      margin: "0px",
      paddingBottom: "20vh",
      [theme.breakpoints.down(768)]: {
        display: "none",
      },
    },
    "& .Texto2": {
      font: "18px lato",
      color: "#505759",
      margin: "0px",
      padding: "20px",
      paddingBottom: "20vh",
      hyphens: "manual",
      [theme.breakpoints.up(768)]: {
        display: "none",
      },
    },
  },

  BotomBar: {
    position: "fixed",
    bottom: "0px",
    background: "#F5D568",
    height: "8vh",
  },
  BarraDerecha: {
    background:
      "transparent linear-gradient(180deg, #D3348F 0%, #F6644B 100%) 0% 0% no-repeat padding-box",
    width: "3%",
    height: "100vh",
    [theme.breakpoints.down(1280)]: {
      display: "none",
    },
  },
  Img: {
    position: "absolute",
    // left: "600px",
    right: "50px",
    top: "0px",
    height: "auto",
    "@media(min-height:960px)": {
      // height: "914px",
    },
    [theme.breakpoints.down(1571)]: {
      // left: "473px",
      top: "0px",
    },
    [theme.breakpoints.down(1441)]: {
      // left: "453px",
      top: "0px",
    },
    [theme.breakpoints.down(1401)]: {
      // left: "403px",
      top: "0px",
    },
    [theme.breakpoints.down(1350)]: {
      // left: "323px",
      top: "0px",
    },
    [theme.breakpoints.down(1280)]: {
      display: "none",
    },
    "& .Maquina": {
      height: "582px",
      width: "685.5px",
      "@media(min-height:960px)": {
        height: "776px",
        width: "914px",
      },
    },
  },
  link: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    right: "10%",
    bottom: "50%",
    height: "100%",
    width: "auto",
    minWidth: "238px",
    position: "absolute",
    textDecoration: "none",
    [theme.breakpoints.down("md")]: {
      minWidth: "200px",
      transform: "translate(50%)",
      right: "50%",
    },
    "& .BotonContinuar": {
      textTransform: "none",
      height: "8vh",
      maxHeight: "62px",
      width: "100%",
      maxWidth: "238px",
      background: "#5825AD",
      boxShadow: "0px 3px 6px #00000029",
      borderRadius: "61px",
      color: "#FFFFFF",
      font: "bold 20px lato ",
      "&:hover": {
        background: "#dd78fb",
      },
    },
  },
}));

const Welcome_Page = () => {
  const classes = useStyles();
  return (
    <Grid
      container
      item
      sm={12}
      direction="column"
      className={classes.ContainerWelcomePage}
    >
      <Grid
        container
        item
        sm={12}
        direction="row"
        className={classes.ContainerImgTxt}
      >
        <Grid container item direction="column" className={classes.Bienvenida}>
          <Grid container item className={classes.Titulo}>
            <b className="Titulo1 escritorio">¡Hola!, </b>
            <b className="Titulo2 escritorio">¿cómo estás?</b>

            <Grid container item className="Title">
              <Grid container justifyContent="center" className="tablet">
                <b className="Titulo1 tablet">¡Hola!,</b>
              </Grid>
              <Grid
                container
                justifyContent="center"
                className="Pregunta tablet"
              >
                <b className="Titulo2 tablet">¿cómo estás?</b>
              </Grid>
            </Grid>
          </Grid>
          <Grid container item className={classes.Texto}>
            <p className="Texto1">
              Te damos la bienvenida a <b>Circuito de lectores,</b> una
              propuesta
              <br />
              innovadora en donde los estudiantes desarrollarán habilidades de{" "}
              <br />
              comprensión lectora de una manera muy atractiva y llena de retos.{" "}
              <br />
              <br />
              A través de lecturas originales, aprenderán sobre diferentes
              temas <br />
              que les serán de utilidad en el ámbito escolar, en su vida
              cotidiana <br />
              y en el futuro. Los contenidos abarcan desde literatura,
              pensamiento
              <br />
              matemático, ciencias humanas, ciencias naturales, arte y ética.
              <br />
              Además, la plataforma cuenta con herramientas socioemocionales{" "}
              <br />
              que les permitirán desarrollar otras habilidades fundamentales:{" "}
              <br />
              el autoconocimiento, la autorregulación y la empatía.
              <br />
              <br></br>
              Te invitamos a ver el video de bienvenida y a explorar la
              plataforma
              <br />
              para que te familiarices con su estructura y rutas de acceso.
              <br />
              Después apóyate en el <b>Manual de usuario</b> y en las{" "}
              <b>Sugerencias</b>
              <br />
              <b>didácticas</b> para conocer las diferentes secciones y cómo{" "}
              <br />
              aprovecharlas al máximo.
              <br />
              <br></br>
              Esperamos que tu experiencia y la de tus estudiantes en{" "}
              <b>Circuito</b>
              <br />
              <b>de lectores</b> sea placentera, divertida y enriquecedora.
              <br />
              <br></br>
              ¡Comencemos!
            </p>

            <p className="Texto2">
              Te damos la bienvenida a <b>Circuito de lectores,</b> una
              propuesta innovadora en donde los estudiantes desarrollarán
              habilidades de comprensión lectora de una manera muy atractiva y
              llena de retos.
              <br />
              <br />
              A través de lecturas originales, aprenderán sobre diferentes temas
              que les serán de utilidad en el ámbito escolar, en su vida
              cotidiana y en el futuro. Los contenidos abarcan desde literatura,
              pensamiento matemático, ciencias humanas, ciencias naturales, arte
              y ética. Además, la plataforma cuenta con herramientas
              socioemocionales que les permitirán desarrollar otras habilidades
              fundamentales: <br />
              el autoconocimiento, la autorregulación y la empatía.
              <br />
              <br></br>
              Te invitamos a ver el video de bienvenida y a explorar la
              plataforma para que te familiarices con su estructura y rutas de
              acceso.
              <br />
              Después apóyate en el <b>Manual de usuario</b> y en las{" "}
              <b>Sugerencias</b>
              <b>didácticas</b> para conocer las diferentes secciones y cómo
              aprovecharlas al máximo.
              <br></br>
              Esperamos que tu experiencia y la de tus estudiantes en{" "}
              <b>Circuito</b>
              <b>de lectores</b> sea placentera, divertida y enriquecedora.
              <br />
              <br></br>
              ¡Comencemos!
            </p>
          </Grid>
          <Grid className={classes.Img}>
            <img className="Maquina" src={Maquina} alt="" />
          </Grid>
        </Grid>

        <Grid container item sm={12} className={classes.BotomBar}>
          <Link className={classes.link} to={"/docente/avatar"}>
            <Button className="BotonContinuar">Continuar</Button>
          </Link>
        </Grid>
      </Grid>

      <Grid container item sm={12} className={classes.BarraDerecha}></Grid>
      <TermsAndConditions />
    </Grid>
  );
};

export default Welcome_Page;
