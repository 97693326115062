import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
      Contenedor:{
        background:'#E9FBF9',
        width:'100vw',
        overflowX:'clip',
        [theme.breakpoints.down(1161)]:{
            background:'none',
           overflowX:'unset',
        },
        [theme.breakpoints.down(600)]:{
            marginTop: '79.51px',
        }
    },
    ContainerInfoLectura:{
        position:'relative',
        height:'86px',
        background:'#76D4C3',
        '& .BarraInfoLectura':{
            height:'86px',
            background:`Url(https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Barras/Verde.png)`,
        },
        '& .InfoLectura':{
            position:'absolute',
            height:'86px',
            width:'219px',
            boxSizing:'border-box',
            padding:'17.2px 0',
            background:'#FAFAFA',
            borderRadius:'50px',
            boxShadow: '2px 2px 2px 2px #00000029',
            zIndex:'4',
        },
        '& .InfoLectura:before':{
            position:'absolute',
            display:'flex',
            boxSizing:'border-box',
            padding:'0 0 0 10px',
            justifyContent:'center',
            alignItems:'center',
            height:'39.88px',
            width:'39.88px',
            borderRadius:'50%',
            content:'""',
            boxShadow: '3px 0px .5px 0px #00000029',
            background:'#FAFAFA',
            left:'90px',
            top:'65px',
            transform: 'rotate(0.25turn)',
        },
        '& .InfoLectura:after':{
            content:'""',
            height:'15px',
            width:'15px',
            borderTop:'2px solid #20A698',
            borderLeft:'2px solid #20A698',
            position:'absolute',
            bottom:'-10px',
            left:'Calc(50% - 8px)',
            transform: 'rotate(0.625turn)',
        },
        '& .Sesion':{
            borderLeft:'2px solid #D8D8D8',
        },
        '& .Dato':{
            margin:'0 0 5px 0',
            textAlign:'center',
            font:'bold 17px lato',
            color:'#20A698',
        },
        '& .Caracter':{
            margin:'0',
            textAlign:'center',
            font:'bold 17px lato',
            color:'#000000',
        },
    },
    ContenedorLectura:{
        background:'#fff',
        width:'1160px',
        height:'100%',
        padding:'57px 168px 35px' ,
        [theme.breakpoints.down(1161)]:{
            width:'502px',
            padding: '57px 0 0 0',
        },
        [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
        '& .ContainerDerechos':{
            bottom:'80px',
            [theme.breakpoints.down(1161)]:{
                display:'none',
            }
        },
        '& .Derechos':{
            textAlign:"center",
            margin:"0px",
            font:" 15px lato",
            color:"#717476",
        },
        '& .Escritorio':{
            display:'block',
            [theme.breakpoints.down(1161)]:{
                display:'none'
            },
        },
        '& .Tablet':{
            display:'none',
            [theme.breakpoints.down(1161)]:{
                display:'block'
            },
        },
    },
    ContainerTitulo:{
        margin:'0 0 45px 0',
        [theme.breakpoints.down(1161)]:{
            margin:'0 0 38px 0',
        },
        '& .Titulo':{
            font:'48px fredoka one',
            letterSpacing: '0px',
            color: '#4BA822',
            margin:'0',
            textAlign:'center',
            [theme.breakpoints.down(1161)]:{
                font:'43px fredoka one',
                lineHeight:'52px',
                textAlign:'center',
            },
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            lineHeight : '30px',
            margin:'0 0 36px 0 ',
            font:'22px lato',
            color:'#000',
            letterSpacing: '0.20px',
            [theme.breakpoints.down(1161)]:{
                font:'18px lato',
                letterSpacing:'.17px',
                lineHeight:'28px',
            },
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .TextImg':{
            margin:'24px 0 0 0',
            font:'20px lato',
            alignSelf:'flex-start',
            color:'#717476',
            letterSpacing:'.19px',
            [theme.breakpoints.down(1161)]:{
                font:'16px lato',
                letterSpacing:'.15px',
                lineHeight:'25px',
            },
            [theme.breakpoints.down(502)]:{
                font:' 11px lato',
                lineHeight :'14px',
                letterSpacing: '0.10px',
            },
        },
        '& .Container':{
            position:'relative',
            justifyContent:'space-around',
            [theme.breakpoints.down(1161)]:{
                justifyContent:'space-around',
            },
        },
        '& .Ul':{
            position:'absolute',
            top:'68px',
            margin:'0',
            padding:'0',
            color:'#4BA822',
            font:'6px',
            [theme.breakpoints.down(502)]:{
                fontSize:'11px!important',
            },
        },

        '& .Ul1':{
            left:'50px',
            [theme.breakpoints.down(1161)]:{
                left:'14%',
                top:'46px',
            },
            [theme.breakpoints.down(502)]:{
                left:'18.5%',
                top:'35px',
            },
        },
        '& .Ul2':{
            left:'255px',
            [theme.breakpoints.down(1161)]:{
                left:'66%',
                top:'46px',
            },
            [theme.breakpoints.down(502)]:{
                top:'35px',
                left:'67.5%',
            },
        },
        '& .Ul3':{
            left:'470px',
            [theme.breakpoints.down(1161)]:{
                left:'14%',
                top:'315px',
            },
            [theme.breakpoints.down(502)]:{
                left:'18.5%',
                top:'220px',
            }
        },

        '& .Ul4':{
            left:'670px',
            [theme.breakpoints.down(1161)]:{
                left:'66%',
                top:'315px',
            },
            [theme.breakpoints.down(502)]:{
                top:'220px',
                left:'67.5%',
            },
        },
        '& .Lectura2':{
            fonto:'18px lato',
            margin:'0 0 8px 0',
            letterSpacing:'.17px',
            color:'#000000',
            width:'118px',
            [theme.breakpoints.down(1161)]:{
                font:'17px lato',
                width:'117px',
                letterSpacing:'.16px',
                lineHeight:'18px',
            },
            [theme.breakpoints.down(502)]:{
                font:'10px lato',
                width:'80px',
                letterSpacing:'.12px',
                margin:'0',
                lineHeight:'13px!important',
            },
        },
        '& .Lectura3':{
            margin:'0 0 8px 0',
            [theme.breakpoints.down(502)]:{
                margin:'0',
                lineHeight:'13px!important',
            },
        },
        '& .Ul5':{
            margin:'0',
            padding:'0 0 0 20px',
            color:'#4BA822',
            font:'6px',
            [theme.breakpoints.down(502)]:{
                fontSize:'11px!important',
            },
        },
        
        '& .Imagen':{
            [theme.breakpoints.down(1161)]:{
                width:'100%',
            },
        },
        '& .Imagen1':{
            height:'313px',
            [theme.breakpoints.down(1161)]:{
                height:'250px',
                width:'35%',
            },
            [theme.breakpoints.down(502)]:{
                height:'175px',
                width:'125px',
            },
        },

    },

}));

const América_megadiversa_Cuál_es_nuestra_responsabilidad = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
             <Grid container item justifyContent="center" className={classes.ContainerInfoLectura}>
               <Grid container className="BarraInfoLectura"></Grid>
               <Grid container item className="InfoLectura">
                    <Grid container item direction="column" xs={6} justifyContent="center">
                        <p className="Dato">Grado</p>
                        <p className="Caracter">6</p>
                    </Grid>
                    <Grid container item direction="column" xs={6} justifyContent="center" className="Sesion">
                        <p className="Dato">Sesión</p>
                        <p className="Caracter">54</p>
                    </Grid>
               </Grid>
            </Grid>

            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo Escritorio" >
                        América megadiversa. ¿Cuál es <br/>
                        nuestra responsabilidad?
                    </h2>
                    <h2 className="Titulo Tablet" >
                        América megadiversa. <br/>¿Cuál es 
                        nuestra <br/>responsabilidad?
                    </h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                            La <em>biodiversidad</em> es la riqueza biológica que existe en una región en particular. Es 
                            decir, se trata del conjunto de especies, ecosistemas y características genéticas que 
                            coexisten en una misma zona geográfica. Ésta no aparece de la noche a la mañana, 
                            sino que es el resultado de la evolución biológica de la región que se estudie. <br/><br/>
                            
                            Se calcula que la Tierra tiene una edad aproximada de 4 500 millones de años. 
                            Precisamente, gracias a su evolución y transformación, se sabe que en la actualidad 
                            existen entre 1.5 y 2 millones de especies conocidas. Además de las muchas más por 
                            descubrir en lugares donde no ha llegado el ser humano, como el fondo del mar. <br/><br/>
                            
                            Aunque el número de especies es inmenso, su distribución no es equitativa en el 
                            mundo. Existen regiones que concentran mayor número, ya sea por sus 
                            características climáticas, la evolución o incluso por la conservación. En ese sentido, 
                            América se posiciona como un continente privilegiado. <br/><br/>
                            
                            Debido a la necesidad de estudiar y clasificar la biodiversidad, recientemente se ha 
                            creado el concepto <em>megadiversidad</em>. Éste refiere la concentración en regiones o países 
                            de una diversidad de ecosistemas, tanto terrestres como acuáticos. Éstos se 
                            distinguen por poseer una gran extensión territorial, climas distintos y una ubicación 
                            cerca del ecuador (regiones tropicales), además de encontrarse en aislamiento
                            geográfico.
                        </p>
                    </Grid>
                    <Grid container  sm={12}>
                            <Grid container>
                                <img className='Imagen' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion54/1.png"  alt="Tarjeta" />
                            </Grid>
                            <Grid container>
                                <p className='TextImg'>A pesar de la gran diversidad de especies que se conoce, se estima que aún faltan 8.7 
                                millones de especies por descubrir.</p>
                            </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                            Existen 17 países megadiversos. La mayoría de ellos se encuentran en el continente americano. A continuación, analiza la lista:
                        </p>
                    </Grid>
                    <Grid container className='Container' sm={12} >
                        <img className=' Imagen1 Escritorio' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion54/3.png"  alt="Tarjeta" />
                        <img className=' Imagen1 Escritorio' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion54/4.png"  alt="Tarjeta" />
                        <img className=' Imagen1 Escritorio' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion54/5.png"  alt="Tarjeta" />
                        <img className=' Imagen1 Escritorio' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion54/6.png"  alt="Tarjeta" />
                        <img className=' Imagen1 Tablet' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion54/3R.png"  alt="Tarjeta" />
                        <img className=' Imagen1 Tablet' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion54/4R.png"  alt="Tarjeta" />
                        <img className=' Imagen1 Tablet' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion54/5R.png"  alt="Tarjeta" />
                        <img className=' Imagen1 Tablet' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion54/6R.png"  alt="Tarjeta" />
                        <ul className='Pos Ul Ul1'>
                            <li><p className='Lectura2'>Madagascar</p></li>
                            <li><p className='Lectura2 '>República Democrática del Congo</p></li>
                            <li><p className='Lectura2'>Sudáfrica</p></li>
                        </ul>
                        <ul className='Pos Ul Ul2'>
                            <li><p className='Lectura2'>Brasil</p></li>
                            <li><p className='Lectura2'>Colombia</p></li>
                            <li><p className='Lectura2'>Ecuador</p></li>
                            <li><p className='Lectura2'>Estados Unidos</p></li>
                            <li><p className='Lectura2'>México</p></li>
                            <li><p className='Lectura2'>Perú</p></li>
                            <li><p className='Lectura2'>Venezuela</p></li>
                        </ul>
                        <ul className='Pos Ul Ul3'>
                            <li><p className='Lectura2'>China</p></li>
                            <li><p className='Lectura2'>Filipinas</p></li>
                            <li><p className='Lectura2'>India</p></li>
                            <li><p className='Lectura2'>Indonesia</p></li>
                            <li><p className='Lectura2'>Malasia</p></li>
                        </ul>
                        <ul className='Pos Ul Ul4'>
                            <li><p className='Lectura2'>Australia</p></li>
                            <li><p className='Lectura2'>Papúa <br/>Nueva Guinea</p></li>
                        </ul>

                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                            La biodiversidad refleja una buena salud de los ecosistemas. Derivado de ello, los 
                            países con estas características son ricos en recursos naturales. Lamentablemente, el 
                            ser humano es el principal consumidor y sobreexplotador de dichos recursos. Por lo 
                            tanto, el cuidado del ambiente es su responsabilidad. <br/><br/>
                            
                            Y aunque el compromiso de la humanidad con la naturaleza tendría que parecer 
                            obvio, los hechos dicen lo contrario. Los seres humanos intervienen en ella de 
                            manera negativa en muchos ámbitos. Por ejemplo, con la contaminación del 
                            ambiente provocada por la quema de combustibles fósiles, la cual produce 
                            alteraciones en el hábitat de los seres vivos y conlleva a la disminución de la 
                            reproducción de las especies. <br/><br/>
                            
                            Otro factor de riesgo es la caza no regulada para alimentación y para la obtención de 
                            pieles exóticas o algunas otras secciones de los cuerpos de los animales, como el 
                            marfil de los elefantes. Y no se puede ignorar el mercado ilegal de animales 
                            prohibidos como mascotas. <br/><br/>
                            
                            La presencia de depredadores y la falta de alimento o de condiciones climáticas
                            propicias ha orillado a muchas especies a migrar o a confinarse en sitios que no son
                            óptimos para su conservación. Pero este movimiento también ha sido provocado por 
                            la actividad humana, debido a la invasión de diversas áreas naturales, la tala 
                            indiscriminada y la utilización de campos para cultivo. <br/><br/>
                            
                            Para frenar la destrucción de los ecosistemas, los gobiernos deben regular la 
                            explotación de los recursos e imponer sanciones. Esto detendría a las industrias o a 
                            las personas que contaminen el ambiente, cacen o recolecten plantas en época de 
                            reproducción y vendan especies exóticas de manera ilegal. <br/><br/>
                            
                            Una forma de hacerlo es declarar como áreas naturales protegidas algunas regiones 
                            naturales. Esto significa que mediante mecanismos legales se evite el deterioro de 
                            los ecosistemas para que las especies que viven en esos lugares mantengan las 
                            condiciones necesarias para su reproducción y desarrollo. <br/><br/>
                            
                            Pero si bien es cierto que los gobiernos y las industrias son los primeros que tendrían 
                            que responsabilizarse por sus malas prácticas, tú, tus conocidos y la sociedad 
                            también podrían hacer lo posible por proteger el planeta. Por ejemplo, evitar 
                            incendios forestales, mantener bien afinados los autos para que contaminen menos o 
                            separar la basura para reciclarla. <br/><br/>
                            
                            ¿De qué manera crees que puedes contribuir a la conservación de la biodiversidad? 
                            ¿Qué otras acciones puedes realizar para prevenir o disminuir el daño del ambiente? 
                            Lee algunas otras propuestas:
                        </p>
                        <ul className='Ul5'>
                            <li><p className='Lectura Lectura3'>Desconecta los aparatos eléctricos cuando no los estés utilizando.</p></li>
                            <li><p className='Lectura Lectura3'>Utiliza bolsas, recipientes y botellas reutilizables para disminuir la producción de plásticos.</p></li>
                            <li><p className='Lectura Lectura3'>Apaga la luz cuando no estés en una habitación.</p></li>
                            <li><p className='Lectura Lectura3'>Reutiliza el papel y todos los recursos que tienes al alcance.</p></li>
                            <li><p className='Lectura '>Regala la ropa y juguetes que ya no utilices a otros niños que les puedan dar un nuevo uso.</p></li>
                        </ul>
                    </Grid>
                    <Grid container  sm={12}>
                            <Grid container>
                                <img className='Imagen' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion54/2.png"  alt="Tarjeta" />
                            </Grid>
                            <Grid container>
                                <p className='TextImg'>Para contribuir en el cuidado del ambiente, aplica las tres “r” en tu vida cotidiana: reduce, reutiliza y recicla.</p>
                            </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                            Todas las acciones, aunque sean mínimas, contribuirán a proteger los ecosistemas y 
                            su biodiversidad de la extinción.
                        </p>
                    </Grid>
                </Grid>

                <Grid container item xs={12} justifyContent="center" className="ContainerDerechos">
                    <p className="Derechos">
                        © 2022. Todos los derechos reservados, Pearson, Circuito de lectores.
                    </p>                
                </Grid>
        </Grid>
    </Grid>
    );
}
 
export default América_megadiversa_Cuál_es_nuestra_responsabilidad;
