import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
    ContenedorLectura:{
        width:'628px',
                  [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
    },
    ContainerTitulo:{
        margin:'0 0 18px 0',
        '& .Titulo':{
            letterSpacing: '0px',
            color: '#FF0000',
            margin:'0',
            textAlign:'center',
            font:'43px fredoka one',
            lineHeight:'52px',
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        boxSizing:'border-box',
        padding:'0 62px 0 62px',
        [theme.breakpoints.down(502)]:{
            padding:'0 32px 0 32px',
        },
        '& .Lectura':{
            margin:'0 0 36px 0',
            color:'#000',
            font:'18px lato',
            lineHeight :'28px',
            letterSpacing: '0.17px',
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .SubTitulo':{
            color:'#000000',
            margin:'0',
            textAlign:'center',
            width:'100%',
            padding:'38px 0 22px 0',
            borderBottom:'solid 7px #FF0000',
            font:'bold 24px lato',
            lineHeight :'28px',
            letterSpacing: '0.24px',
            [theme.breakpoints.down(502)]:{
                borderBottom:'solid 5px #FF0000',
                padding:'19px 0 12px 0',
                font:'bold 13px lato',
                lineHeight :'20px',
                letterSpacing: '0.17px',
            },
        },
        '& .TextImg':{
            color:'#717476',
            margin: "24px 0 36px 0",
            font:' 16px lato',
            lineHeight :'25px',
            letterSpacing: '0.15px',
            [theme.breakpoints.down(502)]:{
                font:' 11px lato',
                lineHeight :'14px',
                letterSpacing: '0.10px',
            },
        },
        '& .Imagen':{
            width:'100%',
        },  
      
    },
    Fondo:{
        background:`Url(https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img1ro/Sesion40/1.png)`,
        margin:'0 0 36px 0',
        width:'100%'

    },
    Esp:{
        padding:'0 48px 0 62px',
        [theme.breakpoints.down(502)]:{
            padding:'0 24px',
        },
    },
}));

const Volcanes_por_aquí_y_por_allá = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo " >
                        Volcanes por aquí<br/> y por allá
                    </h2>
                </Grid>
                <Grid className={classes.Fondo}>
                    <Grid container item sm={12} className={`${classes.Parrafo1}  ${classes.Esp}`} justifyContent="center">
                        <Grid container sm={12} justifyContent='center' className='SubTitulo'>
                            <em> En América hay más de 3 000 volcanes.<br/> Los científicos los consideran reguladores del clima.</em>
                        </Grid>
                    </Grid>
                    <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                        <Grid container sm={12} justifyContent='center' >
                            <p className='Lectura'>
                                Los geógrafos definen un volcán como un cerro o una montaña con una abertura por 
                                la que salen rocas y gases generalmente dañinos, mismos que son lanzados a grandes 
                                alturas. Hay algunos altos y otros no tanto, unos activos y otros que ya no lo están.<br/><br/> 

                                Cuando un volcán entra en erupción, causa temblores que pueden afectar los sitios a 
                                su alrededor. También puede ocasionar incendios, ya que la lava expulsada está tan 
                                caliente que, cuando corre por los lados de las montañas, quema todo lo que 
                                encuentra a su paso. 
                            </p>
                        </Grid>
                        <Grid container justifyContent="center" sm={12}>
                            <Grid container justifyContent="center" >
                                <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img1ro/Sesion50/1.png" alt="Tarjeta" />
                            </Grid>
                            <Grid container justifyContent="center" >
                                <p className="TextImg">
                                    Los volcanes son un elemento importante para el control del clima.
                                </p>
                            </Grid>
                        </Grid>
                        <Grid container sm={12} justifyContent='center' >
                            <p className='Lectura'>
                                Pero no todo acerca de los volcanes es negativo, pues éstos también pueden ser 
                                considerados como reguladores del clima. Los científicos piensan que los minerales 
                                expulsados pueden capturar el principal gas responsable del calentamiento global, el 
                                dióxido de carbono, y refrescar el clima de la Tierra.<br/><br/>

                                Los expertos aconsejan a las personas que identifiquen los volcanes más próximos a 
                                su localidad y que se mantengan pendientes de su actividad. También invitan a los 
                                niños a explorar su historia y así conocer los efectos que han tenido en su área.
                            </p>
                        </Grid>
                    </Grid>
                </Grid>
        </Grid>
    </Grid>
    );
}
 
export default Volcanes_por_aquí_y_por_allá ;
