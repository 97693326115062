import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
    ContenedorLectura:{
        width:'502px',
                  [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
    },
    ContainerTitulo:{
        margin:'0 0 34px 0',
        '& .Titulo':{
            letterSpacing: '0px',
            color: '#92BF37',
            margin:'0',
            textAlign:'center',
            font:'43px fredoka one',
            lineHeight:'52px',
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            margin:'0 0 36px 0',
            color:'#000',
            font:'18px lato',
            lineHeight :'28px',
            letterSpacing: '0.17px',
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .Imagen':{
            width:'100%',
        },   
    }
}));


const De_otro_planeta = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >De otro planeta</h2>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container sm={12}>
                      <p className="Lectura"> 
                        En el planeta Malvia, el pequeño Tirbo vivió una experiencia sorprendente. Sus 
                        padres le dieron tres orbis para conocer un dispositivo que recién se había 
                        inventado: el Luminaris.<br/><br/> 
                        
                        —¿Quieres saber cómo funciona? —preguntó el malviano de la entrada—. Como 
                        recordarás, la luz viaja a través del universo y contiene imágenes. El Luminaris ha 
                        logrado capturarlas para que puedas observar cómo viven los habitantes de otros 
                        planetas ubicados en el espacio exterior. <br/><br/> 
                        
                        La emoción que sentía Tirbo hizo que sin darse cuenta depositara los tres orbis en el 
                        succionador de la entrada. El malviano le pidió que colocara el Luminaris en su único 
                        ojo y guardara silencio al ingresar. Tirbo se colgó del techo para mirar cómodamente 
                        la pantalla. Luego, comenzó a saltar de planeta en planeta. <br/><br/> 
                        
                        Vio explosiones, criaturas de polvo y ríos de metal, pero se detuvo cuando miró un 
                        ser extraño de sólo dos brazos, dos piernas, dos ojos, dos orejas y ¡pelo en la cabeza! <br/><br/> 
                        
                        —¡Juan, ven a comer! —escuchó que le hablaban al pequeño ser, que comenzó a
                        desplazarse hasta unos objetos parecidos a las sillas y las mesas que hay en Malvia, 
                        pero de cuatro patas. <br/><br/> 
                        
                        Tirbo se dio cuenta de que este espécimen tenía una sola boca, que hacía caras muy 
                        extrañas y que cambiaba de color cuando introducía un objeto plateado en su boca. 
                        Observó con curiosidad que se le inflaban los cachetes y se volvía rojo, casi verde. 
                        Entonces, Tirbo pensó que estos seres cambiaban de color al comer. <br/><br/> 

                        Luego, Tirbo notó que una bola de pelos corría hacia esa fantástica especie. Parecía 
                        tener vida propia y lo pudo comprobar cuando vio que el pequeño ser le compartía 
                        de su comida.
                      </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img3ro/Sesion06/1.png" alt="Tarjeta" />
                    </Grid>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container sm={12}>
                      <p className="Lectura"> 
                        —¡Juan, es hora de dormir! —se escuchó de nuevo esa voz aguda. <br/><br/> 
                      
                        Entonces, Tirbo pensó que el enigmático ser comenzaba a derretirse, pues le salía 
                        agua de los ojos, su cara colgaba y su boca emitía sonidos extraños. ¡Se veía todavía 
                        más feo y Tirbo comenzó a asustarse! Para su suerte, el pequeño ser guardó silencio, 
                        luego de encontrar unos objetos que tenían su forma, pero de mucho menor tamaño. 
                        Desde su posición, no pudo distinguir si estaban vivos. <br/><br/> 
                        
                        —¡Pum! ¡Pam! ¡Zas! —decía mientras arrojaba aquellos objetos de un lado a otro. <br/><br/> 
                        
                        De pronto, el pequeño sacó otro objeto, pero de color verde, con un solo ojo, tres 
                        tentáculos y dos bocas. Muy parecido a Tirbo… Y dijo: <br/><br/> 
                        
                        —¡Llega ahora el terrible monstruo! ¡Tenemos que destruirlo! —y el pequeño ser 
                        arrojó el objeto al suelo. Después le lanzó encima los otros objetos que se parecían a 
                        él y brincó sobre ellos. <br/><br/> 
                        
                        —Glu-ru-ru-rur —gritó Tirbo asustado y en dos parpadeos se transportó a los 
                        tentáculos de su madre para contarle de ese pequeño ser que era un terrible 
                        monstruo.
                      </p>
                    </Grid>
                </Grid>
        </Grid>
    </Grid>
    );
}
 
export default De_otro_planeta;
