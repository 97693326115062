import React, { Suspense, useContext } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import LoginIndex from "../pages/login/LoginIndex";

import ProfileNative from "../pages/native/Perfil_del_alumnoNative";

import AuthContext from "../../auth/AuthContext";

import ForgetPassword from "../pages/login/ForgetPassword/ForgetPassword";
import PasswordRecovery from "../pages/login/ForgetPassword/PasswordRecovery";
import NewPassword from "../pages/login/ForgetPassword/NewPassword";
import SinginPrimero from "../pages/singin/SinginPrimero";
import SinginSegundo from "../pages/singin/SinginSegundo";
import SinginTercero from "../pages/singin/SinginTercero";
import SinginCuarto from "../pages/singin/SinginCuarto";
import SinginQuinto from "../pages/singin/SinginQuinto";
import SinginSexto from "../pages/singin/SinginSexto";
import AvatarsNative from "../pages/native/AvatarsNative";
import creditos from "../pages/Creditos";

import RegistroGDocente from "../pages/registro/RegistroDocente";
import RegistroGAlumno from "../pages/registro/RegistroEstudiante";
import Registro from "../pages/registro/RegistroIndex";

import signinTeacher from "../pages/Docente/SinginTeacher";
import SinginAdmin from "../pages/Admin_Institucional/SinginAdmin";

import ScrollToTop from "../hooks/useScrollToTop";
import SinginAdminPearson from "../pages/Admin_Pearson/SinginAdminPearson";
import LogoutNative from "../pages/native/LogoutNative";
import Error404 from "../pages/Errors/Error404";
import Loader from "../Loader/Loader";

import AdminInstituto from "./AdminInstituto";
import AdminPearson from "./AdminPearson";
import { ChatProvider } from "../pages/Chat/Context/ChatContext";
import ModalOrientation from "../Modals/ModalOrientation";
import Docente from "./Docente";
import Alumno from "./Alumno";
import SextoGrado from "./SextoGrado";
import QuintoGrado from "./QuintoGrado";
import CuartoGrado from "./CuartoGrado";
import TercerGrado from "./TercerGrado";
import SegundoGrado from "./SegundoGrado";
import PrimerGrado from "./PrimerGrado";
import { DrawerProvider } from "../../usability/DrawerContext";
import ErrorPersonalized from "../pages/Errors/ErrorPersonalized";
import { UsabilityProvider } from "../../usability/UsabilityContext";
import ErrorBoundary from "../../utilities/ErrorBoundaries";
import ErrorBoundaryComponent from "../pages/Errors/ErrorBoundary";

const BaseRoutes = () => {
  const {
    user: { grade, rol, logged },
  } = useContext(AuthContext);
  return (
    <Suspense fallback={Loader}>
      <Router>
      <ErrorBoundary>
        <UsabilityProvider>
          <DrawerProvider>
            <ChatProvider>
              {logged && <ModalOrientation />}
              <ScrollToTop>
                <Switch>
                  <Route exact path="/" component={LoginIndex} />
                  <Route
                    exact
                    path="/password_recovery"
                    component={ForgetPassword}
                  />
                  <Route
                    exact
                    path="/password_recovery/student"
                    component={PasswordRecovery}
                  />
                  <Route
                    exact
                    path="/password_recovery/docente"
                    component={PasswordRecovery}
                  />
                  <Route
                    exact
                    path="/password_recovery/newPassword"
                    component={NewPassword}
                  />
                  <Route exact path="/singin/sexto" component={SinginSexto} />
                  <Route exact path="/singin/quinto" component={SinginQuinto} />
                  <Route exact path="/singin/cuarto" component={SinginCuarto} />
                  <Route
                    exact
                    path="/singin/tercero"
                    component={SinginTercero}
                  />
                  <Route
                    exact
                    path="/singin/segundo"
                    component={SinginSegundo}
                  />
                  <Route
                    exact
                    path="/singin/primero"
                    component={SinginPrimero}
                  />
                  <Route
                    exact
                    path="/singin/docente"
                    component={signinTeacher}
                  />
                  <Route
                    exact
                    path="/singin/admin-inst"
                    component={SinginAdmin}
                  />
                  <Route
                    exact
                    path="/singin/admin"
                    component={SinginAdminPearson}
                  />
                  <Route exact path="/creditos" component={creditos} />
                  <Route
                    exact
                    path="/native/Perfil"
                    component={ProfileNative}
                  />
                  <Route
                    exact
                    path="/native/Avatars"
                    component={AvatarsNative}
                  />
                  <Route exact path="/native/logout" component={LogoutNative} />
                  <Route exact path="/registro" component={Registro} />
                  <Route
                    exact
                    path="/registro/alumno"
                    component={RegistroGAlumno}
                  />
                  <Route
                    exact
                    path="/registro/docente"
                    component={RegistroGDocente}
                  />
                  {/* {!localStorage.token && <Route path="/" component={LoginIndex} />} */}
                  {/* <Route exact path="/"                               component={ LoginIndex }/> */}
                  {/* <ProtectedRoute path='*'                            component={ DashboardsIndex } /> */}
                  <Route path="/error/error" component={ErrorBoundaryComponent} />
                  <Route path="/error/inicio-sesion" component={Error404} />
                  <Route path="/error/:error" component={ErrorPersonalized} />
                  {rol === "docente" && Docente}
                  {rol === "administrador institucional" && AdminInstituto}
                  {rol === "administrador pearson" && AdminPearson}

                  {rol === "alumno" && Alumno}
                  {grade === "sexto" && SextoGrado}
                  {grade === "quinto" && QuintoGrado}
                  {grade === "cuarto" && CuartoGrado}
                  {grade === "tercero" && TercerGrado}
                  {grade === "segundo" && SegundoGrado}
                  {grade === "primero" && PrimerGrado}
                  <Route path="*" component={Error404} />
                </Switch>
              </ScrollToTop>
            </ChatProvider>
          </DrawerProvider>
        </UsabilityProvider>
        </ErrorBoundary>
      </Router>
    </Suspense>
  );
};

export default BaseRoutes;
