import React from "react";
import roteImg from "../imgs/rote.svg";
import warningImg from "../imgs/warning.svg";

import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100vw",
    height: "100vh",
    position: "fixed",
    zIndex: "2000",
    display: "none",
    "@media (orientation: landscape)": {
      "@media(max-height:389px)": {
        display: "flex",
      },
    },
  },
  content: {
    backdropFilter: "blur(11px)",
    // backgroundColor: "#84E9DF",
    backgroundColor: "#fff",
    padding: "30px",
    boxSizing: "border-box",
    overflow: "hidden",
    width: "100%",
    height: "100%",
    display: "grid",
    gridTemplateColumns: "0.66fr 1fr",
    justifyItems: "center",
    zIndex: "2000",
    alignContent: "center",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  containerImages: {
    display: "flex",
    width: "80%",
    justifyContent: "flex-start",
    boxSizing: "border-box",
  },

  imgWarning: {
    width: "157px",
    objectFit: "contain",
  },
  containerImageMobile: {
    position: "relative",
  },
  imgMobile: {
    width: "118px",
    height: "123px",
    objectFit: "contain",
    position: "absolute",
    left: "-50px",
    top: "50px",
  },

  text: {
    fontFamily: "Lato",
    margin: 0,
    fontSize: "24px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "0.23px",
    textAlign: "center",
    // color: "#9051b1",
    color: "#000",
  },
}));
const ModalOrientation = () => {
  const classes = useStyles();
  return (
    <>
      <div className={classes.container}>
        <div className={classes.content}>
          <div className={classes.containerImages}>
            <img src={warningImg} alt="" className={classes.imgWarning} />
            <div className={classes.containerImageMobile}>
              <img src={roteImg} alt="" className={classes.imgMobile} />
            </div>
          </div>
          <h1 className={classes.text}>
            <b>Gira tu disposivo</b> a modo vertical para continuar
            diviritiéndote y aprendiendo con el circuito de lectores
          </h1>
        </div>
      </div>
    </>
  );
};

export default ModalOrientation;
