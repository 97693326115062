import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
    ContenedorLectura:{
        width:'502px',
                  [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
    },
    ContainerTitulo:{
        margin:'0 0 34px 0',
        '& .Titulo':{
            letterSpacing: '0px',
            color: '#D1270A',
            margin:'0',
            textAlign:'center',
            font:'43px fredoka one',
            lineHeight:'52px',
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            margin:'0 0 36px 0',
            color:'#000',
            font:'18px lato',
            lineHeight :'28px',
            letterSpacing: '0.17px',
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .TextImg':{
            color:'#717476',
            margin:'17px 0 0 0',
            font:' 16px lato',
            lineHeight :'25px',
            letterSpacing: '0.15px',
            [theme.breakpoints.down(502)]:{
                font:' 11px lato',
                lineHeight :'14px',
                letterSpacing: '0.10px',
            },
        },
        '& .Imagen':{
            width:'100%',
        },  
    }
}));

const Cuál_es_la_civilización_más_antigua_del_mundo = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >
                    ¿Cuál es la civilización<br/> más antigua del mundo?
                    </h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                      <p className="Lectura">
                        ¿Alguna vez te has preguntado cuál de todas las grandes civilizaciones antiguas fue la 
                        primera? Pues… depende de a quién le preguntes. De acuerdo con el Instituto de la 
                        Historia de las Nacionalidades de China, la civilización china es la más antigua. Para 
                        tener este título, se puede considerar como punto de inicio la dinastía Xia, que fue la 
                        primera. ¡Ésta gobernó China en el 22 000 a. n. e.! Imagínalo: 15 reinos, 22 dinastías 
                        y dos repúblicas después, la cultura china continúa siendo fuerte y próspera. Nada 
                        mal, ¿no te parece?
                      </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <Grid container justifyContent="center" >
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img1ro/Sesion53/1.png" alt="Tarjeta" />
                        </Grid>
                        <Grid container justifyContent="center" >
                            <p className="TextImg">
                                Yu el Grande fue el primer emperador de la dinastía Xia.
                            </p>
                        </Grid>
                    </Grid>
                </Grid>
            
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                      <p className="Lectura">
                        Pero hay nuevos estudios que sugieren que había civilizaciones aún más antiguas. 
                        ¿Has oído hablar de los sumerios, asentados en la antigua Mesopotamia? ¿O qué tal 
                        de los harappa, en India y Pakistán? ¿Y qué decir de los caral, en Perú?<br/><br/>
                        
                        Lo cierto es que no hay una repuesta definitiva sobre cuál de todas estas 
                        civilizaciones fue la primera, pero sí podemos estar seguros de que todas tuvieron un 
                        impacto importante en la sociedad, para que ahora sea tal como la conocemos.
                      </p>
                    </Grid>
                </Grid>
        </Grid>
    </Grid>
    );
}
 
export default Cuál_es_la_civilización_más_antigua_del_mundo ;
