import { makeStyles, Grid } from "@material-ui/core";
import React, { useState } from "react";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

import axios from "../../../../../services/axios";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import ModalConfirm from "../../../../Modals/ModalConfirm";
import ModalMessage from "../../../../Modals/ModalMessage";


const useStyles = makeStyles((theme) => ({
  mainContainer: {
    padding: "48px 0 48px 0",
    width: "100vw",
    minHeight: "92vh",
    marginTop: "8vh",
    background:
      "linear-gradient(217deg, rgba(255,194,0,0.4962185557816877) 2%, rgba(255,255,255,1) 25%, rgba(255,255,255,0.4962185557816877) 81%, rgba(144,81,177,1) 98%)",
    [theme.breakpoints.up("sm")]: {
      marginTop: "0",
    },
  },
  boxContainer: {
    background: "#fff",
    width: "80%",
    height: "auto",
    borderRadius: "42px",
    padding: "20px",
    alignItems: "center",
    justifyContent: "center",
    gap: "20px",
    boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.5)",
    [theme.breakpoints.up("md")]: {
      padding: "48px 20px 48px 20px",
    },
  },
  titleContainer: {
    margin: "20px 0",
    [theme.breakpoints.up("xl")]: {
      margin: "30px 0",
    },
  },
  title: {
    font: "28px Fredoka One ",
    margin: "0",
    textAlign: "center",
    color: " #DC5EA6",
    [theme.breakpoints.up("md")]: {
      fontSize: "36px",
    },
    [theme.breakpoints.up("xl")]: {
      fontSize: "42px",
    },
  },
  link: {
    cursor: "pointer",
    textDecoration: "none",
  },
  backButtonContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.up("sm")]: {
      justifyContent: "flex-start",
    },
  },
  backButton: {
    cursor: "pointer",
    // height: "35px",
    width: "80%",
    maxWidth: "200px",
    backgroundColor: "#FDEFF7",
    color: "#DC5EA6",
    border: "#DC5EA6 1px solid",
    borderRadius: "20px",
    textTransform: "none",
    font: "20px Lato",
    letterSpacing: "0.14px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
  },

  studentListContainer: {
    gap: "20px",
  },
  inputLoginUser: {
    width: "80%",
    maxWidth: "280px",
    height: "40px",
    paddingLeft: "27px",
    borderRadius: "61px",
    // border: " solid #1D2AAD 3px ",
    border: "none",
    background: "#FFFFFF",
    font: "Helvetica",
    fontSize: "16px",
    //    width: '449px',
    // height: '72px',
    // margin: '43px 139px 19px 66px',
    // padding: '26px 24px 25px',
    backgroundColor: "#f0f0f0",
    "&:focus": {
      outline: "none",
    },
    [theme.breakpoints.up("lg")]: {
      height: "60px",
      maxWidth: "449px",
      fontSize: "18px",
    },
  },
  PurpleButton: {
    color: "#FFFFFF",
    backgroundColor: "#9051b1",
    borderRadius: "30px",
    margin: "0 auto",
    minWidth: "200px",
    width: "100%",
    maxWidth: "238px",
    minHeight: "45px",
    border: "none",
    textTransform: "unset",
    cursor: "pointer",
    fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
    fontWeight: "500",
    padding: "6px 16px",
    lineHeight: "1.75",
    transition:
      "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    boxShadow:
      "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
    "&:hover": {
      backgroundColor: "#B965D3 ",
    },
  },
  WitheButton: {
    color: "#9051b1",
    // borderColor: "#9051b1",
    border: "1px solid #9051b1",
    backgroundColor: "#FFFFFF",
    borderRadius: "30px",
    // margin: "0 auto",
    minWidth: "200px",
    width: "100%",
    maxWidth: "238px",
    minHeight: "45px",
    textTransform: "uppercase",
    cursor: "pointer",
    fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
    fontWeight: "500",
    padding: "6px 16px",
    lineHeight: "1.75",
    transition:
      "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    boxShadow:
      "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
    "&:hover": {
      backgroundColor: "#B965D330 ",
    },
  },
  linkToUploader: {
    textDecoration: "none",
    // width: '80%',
    width: "100%",
    maxWidth: "238px",
    margin: "0 auto",
    // marginLeft: "44px",
    // marginBottom: "40px",
    "& .ButtonIsg": {
      // color: "blue",
      // textTransform: "none",
      // font: " 22px lato",
      // letterSpacing: "0.36px",
      // width: '102px',
      // height: '21px',
      // background: '#FEFAF1 0% 0% no-repeat padding-box',
      // boxShadow: '0px 1px 2px #00000029',
      // border: '1px solid #F2624F',
      // borderRadius: '10px',
    },
    "& .icon": {
      // color: "purple",
      // marginRight: "20px",
      // fontSize: "18px",
      margin: "0",
      [theme.breakpoints.up("lg")]: {
        // fontSize: "30px",
      },
    },
  },
  formContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    maxWidth: "560px",
    boxSizing: "border-box",
    alignItems: "flex-start",
    gap: "20px",
  },
  formItemContainer: {
    gap: "10px",
  },
  input: {
    width: "100%",
    height: "40px",
    paddingLeft: "27px",
    paddingRight: "27px",
    borderRadius: "61px",
    boxSizing: "border-box",
    // border: " solid #1D2AAD 3px ",
    border: "none",
    background: "#FFFFFF",
    font: "Helvetica",
    fontSize: "16px",

    backgroundColor: "#f0f0f0",
    "&:focus": {
      outline: "none",
    },
    [theme.breakpoints.up("lg")]: {
      height: "60px",
      // maxWidth: "449px",
      fontSize: "18px",
    },
  },
  label: {
    fontWeight: "bold",
    fontFamily: "Lato, sans-serif",
  },
}));

const Edit = () => {
  const classes = useStyles();
  const history = useHistory();
  const { id_usuario } = useParams();
  const state = history.location.state;
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [modalMessageOpen, setModalMessageOpen] = useState(false);
  const [confirmChangesResult, setConfirmChangesResult] = useState("");
  const [formData, setFormData] = useState({
    nombre: state?.firstName,
    apellido_paterno: state?.firstLastName,
    apellido_materno: state?.secondLastName,
    grupo: state.id_grupo,
    escuela: state?.school,
    grado: state?.grade,
    pais: state?.country,
  });
  const handleCloseConfirmationModal = () => {
    setConfirmationModalOpen(!confirmationModalOpen);
  };
  const handleCloseMessageModal = () => {
    setModalMessageOpen(!modalMessageOpen);
  };

  const handleForm = (e) => {
    setFormData({
      ...formData,
      [e.target.name.toLowerCase()]: e.target.value,
    });
  };

  const confirmChanges = () => {
    if (
      formData?.nombre &&
      formData?.apellido_paterno &&
      formData?.apellido_materno
    ) {
      const body = {
        id_usuario: Number(id_usuario),
        nombre: formData?.nombre,
        apellido_paterno: formData?.apellido_paterno,
        apellido_materno: formData?.apellido_materno || "",
      };
      axios
        .put("/listado/nombre", body)
        .then((response) => {
          if (response.status === 200) {
            setConfirmationModalOpen(false);
            setModalMessageOpen(true);
            setConfirmChangesResult(
              "Los datos se han actualizado correctamente"
            );
          }
        })
        .catch((error) => {
          setConfirmationModalOpen(false);
          setModalMessageOpen(true);
          setConfirmChangesResult(
            "Ha ocurrido un error al actualizar los datos. Intente de nuevo más tarde."
          );
        });
    } else {
      setConfirmationModalOpen(false);
      setModalMessageOpen(true);
      setConfirmChangesResult("Asegúrese de llenar todos los datos");
    }
  };

  return (
    <Grid
      container
      item
      sm={12}
      alignItems="center"
      justifyContent="center"
      className={classes.mainContainer}
    >
      <Grid container item direction="column" className={classes.boxContainer}>
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="center"
        >
          <Grid item className={classes.backButtonContainer}>
            <button
              className={classes.backButton}
              onClick={() => history.goBack()}
            >
              <ArrowBackIcon /> Estudiantes
            </button>
          </Grid>

          <Grid
            container
            item
            alignItems="center"
            justifyContent="center"
            className={classes.titleContainer}
          >
            <h2 className={classes.title}>Editar perfil del estudiante</h2>
          </Grid>
        </Grid>
        <form
          className={classes.formContainer}
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <Grid
            container
            direction="column"
            className={classes.formItemContainer}
          >
            <label htmlFor="input-name" className={classes.label}>
              Nombre(s)
            </label>
            <input
              type="text"
              className={classes.input}
              id="input-name"
              name="nombre"
              value={formData.nombre}
              onChange={handleForm}
              required
            />
          </Grid>
          <Grid
            container
            direction="column"
            className={classes.formItemContainer}
          >
            <label htmlFor="input-lastName" className={classes.label}>
              Primer apellido
            </label>
            <input
              type="text"
              className={classes.input}
              id="input-lastName"
              name="apellido_paterno"
              value={formData.apellido_paterno}
              onChange={handleForm}
              required
            />
          </Grid>
          <Grid
            container
            direction="column"
            className={classes.formItemContainer}
          >
            <label htmlFor="input-secondLastName" className={classes.label}>
              Segundo apellido
            </label>
            <input
              type="text"
              name="apellido_materno"
              value={formData.apellido_materno}
              onChange={handleForm}
              className={classes.input}
              id="input-secondLastName"
            />
          </Grid>
          <Grid
            container
            direction="column"
            className={classes.formItemContainer}
          >
            <label htmlFor="input-school" className={classes.label}>
              Escuela
            </label>
            <input
              type="text"
              name="escuela"
              value={formData.escuela}
              onChange={handleForm}
              className={classes.input}
              id="input-school"
              disabled
            />
          </Grid>
          <Grid
            container
            direction="column"
            className={classes.formItemContainer}
          >
            <label htmlFor="input-grade" className={classes.label}>
              Grado
            </label>
            <input
              type="text"
              name="grado"
              value={formData.grado}
              onChange={handleForm}
              className={classes.input}
              id="input-grade"
              disabled
            />
          </Grid>
          <Grid
            container
            direction="column"
            className={classes.formItemContainer}
          >
            <label htmlFor="input-country" className={classes.label}>
              País
            </label>
            <input
              type="text"
              name="pais"
              value={formData.pais}
              onChange={handleForm}
              className={classes.input}
              id="input-country"
              disabled
            />
          </Grid>
          <button
            className={classes.PurpleButton}
            onClick={handleCloseConfirmationModal}
          >
            Confirmar cambios
          </button>
        </form>

        <ModalConfirm
          open={confirmationModalOpen}
          handleClose={handleCloseConfirmationModal}
          handleConfirm={confirmChanges}
        />

        <ModalMessage
          open={modalMessageOpen}
          handleClose={handleCloseMessageModal}
          title={confirmChangesResult}
        />
      </Grid>
    </Grid>
  );
};

export default Edit;
