import React from "react";
import ProtectedRoute from "./ProtectedRoute";

import LectS_3_20 from "../pages/Container/ContainerLecturasBiblioteca3ro/Sesion20";
import LectS_3_21R from "../pages/Container/ContainerLecturasBiblioteca3ro/Sesion21R";
import LectS_3_38 from "../pages/Container/ContainerLecturasBiblioteca3ro/Sesion38";
import LectS_3_39R from "../pages/Container/ContainerLecturasBiblioteca3ro/Sesion39R";
import LectS_3_56 from "../pages/Container/ContainerLecturasBiblioteca3ro/Sesion56";
import LectS_3_56R from "../pages/Container/ContainerLecturasBiblioteca3ro/Sesion56R";

import LectS_3_3 from "../pages/Container/ContainerLecturasBiblioteca3ro/Sesion3";
import LectS_3_4 from "../pages/Container/ContainerLecturasBiblioteca3ro/Sesion4";
import LectS_3_5 from "../pages/Container/ContainerLecturasBiblioteca3ro/Sesion5";
import LectS_3_6 from "../pages/Container/ContainerLecturasBiblioteca3ro/Sesion6";
import LectS_3_7 from "../pages/Container/ContainerLecturasBiblioteca3ro/Sesion7";
import LectS_3_8 from "../pages/Container/ContainerLecturasBiblioteca3ro/Sesion8";
import LectS_3_9 from "../pages/Container/ContainerLecturasBiblioteca3ro/Sesion9";
import LectS_3_10 from "../pages/Container/ContainerLecturasBiblioteca3ro/Sesion10";
import LectS_3_11 from "../pages/Container/ContainerLecturasBiblioteca3ro/Sesion11";
import LectS_3_12 from "../pages/Container/ContainerLecturasBiblioteca3ro/Sesion12";
import LectS_3_13 from "../pages/Container/ContainerLecturasBiblioteca3ro/Sesion13";
import LectS_3_14 from "../pages/Container/ContainerLecturasBiblioteca3ro/Sesion14";
import LectS_3_15 from "../pages/Container/ContainerLecturasBiblioteca3ro/Sesion15";
import LectS_3_16 from "../pages/Container/ContainerLecturasBiblioteca3ro/Sesion16";
import LectS_3_17 from "../pages/Container/ContainerLecturasBiblioteca3ro/Sesion17";
import LectS_3_18 from "../pages/Container/ContainerLecturasBiblioteca3ro/Sesion18";
import LectS_3_19 from "../pages/Container/ContainerLecturasBiblioteca3ro/Sesion19";
import LectS_3_21 from "../pages/Container/ContainerLecturasBiblioteca3ro/Sesion21";
import LectS_3_22 from "../pages/Container/ContainerLecturasBiblioteca3ro/Sesion22";
import LectS_3_23 from "../pages/Container/ContainerLecturasBiblioteca3ro/Sesion23";
import LectS_3_24 from "../pages/Container/ContainerLecturasBiblioteca3ro/Sesion24";
import LectS_3_25 from "../pages/Container/ContainerLecturasBiblioteca3ro/Sesion25";
import LectS_3_26 from "../pages/Container/ContainerLecturasBiblioteca3ro/Sesion26";
import LectS_3_27 from "../pages/Container/ContainerLecturasBiblioteca3ro/Sesion27";
import LectS_3_28 from "../pages/Container/ContainerLecturasBiblioteca3ro/Sesion28";
import LectS_3_29 from "../pages/Container/ContainerLecturasBiblioteca3ro/Sesion29";
import LectS_3_30 from "../pages/Container/ContainerLecturasBiblioteca3ro/Sesion30";
import LectS_3_31 from "../pages/Container/ContainerLecturasBiblioteca3ro/Sesion31";
import LectS_3_32 from "../pages/Container/ContainerLecturasBiblioteca3ro/Sesion32";
import LectS_3_33 from "../pages/Container/ContainerLecturasBiblioteca3ro/Sesion33";
import LectS_3_34 from "../pages/Container/ContainerLecturasBiblioteca3ro/Sesion34";
import LectS_3_35 from "../pages/Container/ContainerLecturasBiblioteca3ro/Sesion35";
import LectS_3_36 from "../pages/Container/ContainerLecturasBiblioteca3ro/Sesion36";
import LectS_3_37 from "../pages/Container/ContainerLecturasBiblioteca3ro/Sesion37";
import LectS_3_39 from "../pages/Container/ContainerLecturasBiblioteca3ro/Sesion39";
import LectS_3_40 from "../pages/Container/ContainerLecturasBiblioteca3ro/Sesion40";
import LectS_3_41 from "../pages/Container/ContainerLecturasBiblioteca3ro/Sesion41";
import LectS_3_42 from "../pages/Container/ContainerLecturasBiblioteca3ro/Sesion42";
import LectS_3_43 from "../pages/Container/ContainerLecturasBiblioteca3ro/Sesion43";
import LectS_3_44 from "../pages/Container/ContainerLecturasBiblioteca3ro/Sesion44";
import LectS_3_45 from "../pages/Container/ContainerLecturasBiblioteca3ro/Sesion45";
import LectS_3_46 from "../pages/Container/ContainerLecturasBiblioteca3ro/Sesion46";
import LectS_3_47 from "../pages/Container/ContainerLecturasBiblioteca3ro/Sesion47";
import LectS_3_48 from "../pages/Container/ContainerLecturasBiblioteca3ro/Sesion48";
import LectS_3_49 from "../pages/Container/ContainerLecturasBiblioteca3ro/Sesion49";
import LectS_3_50 from "../pages/Container/ContainerLecturasBiblioteca3ro/Sesion50";
import LectS_3_51 from "../pages/Container/ContainerLecturasBiblioteca3ro/Sesion51";
import LectS_3_52 from "../pages/Container/ContainerLecturasBiblioteca3ro/Sesion52";
import LectS_3_53 from "../pages/Container/ContainerLecturasBiblioteca3ro/Sesion53";
import LectS_3_54 from "../pages/Container/ContainerLecturasBiblioteca3ro/Sesion54";
import LectS_3_55 from "../pages/Container/ContainerLecturasBiblioteca3ro/Sesion55";
import LectS_3_57 from "../pages/Container/ContainerLecturasBiblioteca3ro/Sesion57";
import LectS_3_58 from "../pages/Container/ContainerLecturasBiblioteca3ro/Sesion58";

const routes = [
  <ProtectedRoute
    exact
    path="/Todos_en_uno_y_uno_en_todos"
    component={LectS_3_20}
  />,
  <ProtectedRoute exact path="/Viajes_en_el_tiempo" component={LectS_3_21R} />,
  <ProtectedRoute
    exact
    path="/Los_derechos_de_los_consumidores"
    component={LectS_3_38}
  />,
  <ProtectedRoute exact path="/El_Galeón_de_Manila" component={LectS_3_39R} />,
  <ProtectedRoute
    exact
    path="/El_caballo_hace_un_recorrido_semimágico"
    component={LectS_3_55}
  />,
  <ProtectedRoute exact path="/La_cocina_como_arte" component={LectS_3_56R} />,
  <ProtectedRoute exact path="/277" component={LectS_3_3} />,
  //Arma_tu_propia_alcancía"
  <ProtectedRoute exact path="/278" component={LectS_3_4} />,
  //Las_abejas_y_la_polinización"
  <ProtectedRoute exact path="/279" component={LectS_3_5} />,
  //Para_qué_sirve_un_museo"
  <ProtectedRoute exact path="/280" component={LectS_3_6} />,
  //De_otro_planeta"
  <ProtectedRoute exact path="/281" component={LectS_3_7} />,
  //Los_anteojos_una_historia_inesperada"
  <ProtectedRoute exact path="/282" component={LectS_3_8} />,
  //Operaciones_con_conjuntos"
  <ProtectedRoute exact path="/283" component={LectS_3_9} />,
  //Países_en_distintas_vías_de_desarrollo"
  <ProtectedRoute exact path="/284" component={LectS_3_10} />,
  //Las_torres_de_Hanói"
  <ProtectedRoute exact path="/285" component={LectS_3_11} />,
  //La_carrera_espacial"
  <ProtectedRoute exact path="/286" component={LectS_3_12} />,
  //El_Festival_Cervantino_en_época_virtual"
  <ProtectedRoute exact path="/287" component={LectS_3_13} />,
  //La_hoja_mágica"
  <ProtectedRoute exact path="/289" component={LectS_3_15} />,
  //Economía_naranja_creativa_y_sostenible"
  <ProtectedRoute exact path="/288" component={LectS_3_14} />,
  //Muse_del_juego"
  <ProtectedRoute exact path="/290" component={LectS_3_16} />,
  //La_misión_de_las_nubes"
  <ProtectedRoute exact path="/291" component={LectS_3_17} />,
  //La_importancia_del_calcio"
  <ProtectedRoute exact path="/292" component={LectS_3_18} />,
  //Diferentes_reglas"
  <ProtectedRoute exact path="/293" component={LectS_3_19} />,
  //Todo_a_su_lugar"
  <ProtectedRoute exact path="/296" component={LectS_3_21} />,
  //El_teatro_y_su_historia"
  <ProtectedRoute exact path="/297" component={LectS_3_22} />,
  //Qué_hace_un_arqueólogo"
  <ProtectedRoute exact path="/298" component={LectS_3_23} />,
  //El_extranjero"
  <ProtectedRoute exact path="/299" component={LectS_3_24} />,
  //La_educación_es_un_derecho_para_todos"
  <ProtectedRoute exact path="/300" component={LectS_3_25} />,
  //El_secreto_de_la_vida"
  <ProtectedRoute exact path="/301" component={LectS_3_26} />,
  //Hipatia_de_Alejandría_y_las_matemáticas"
  <ProtectedRoute exact path="/302" component={LectS_3_27} />,
  //El_Coco"
  <ProtectedRoute exact path="/303" component={LectS_3_28} />,
  //Igualdad_y_equidad"
  <ProtectedRoute exact path="/304" component={LectS_3_29} />,
  //Niños_de_azúcar"
  <ProtectedRoute exact path="/305" component={LectS_3_30} />,
  //Hablar_con_emojis"
  <ProtectedRoute exact path="/306" component={LectS_3_31} />,
  //Siempre_al_mar"
  <ProtectedRoute exact path="/307" component={LectS_3_32} />,
  //Tecnología_digital"
  <ProtectedRoute exact path="/308" component={LectS_3_33} />,
  //Viajeros_en_el_espacio_asteroides_y_cometas"
  <ProtectedRoute exact path="/309" component={LectS_3_34} />,
  //Las_Vitaminas_se_comen"
  <ProtectedRoute exact path="/310" component={LectS_3_35} />,
  //Energía_limpia_para_el_planeta_Tierra"
  <ProtectedRoute exact path="/311" component={LectS_3_36} />,
  //Declaración_Universal_de_los_Derechos_Humanos"
  <ProtectedRoute exact path="/312" component={LectS_3_37} />,
  //Jugando_con_la_historia"
  <ProtectedRoute exact path="/315" component={LectS_3_39} />,
  //Qué_son_las_artes_plásticas"
  <ProtectedRoute exact path="/316" component={LectS_3_40} />,
  //La_libertad_de_expresión"
  <ProtectedRoute exact path="/317" component={LectS_3_41} />,
  //Viaje_a_lo_invisible"
  <ProtectedRoute exact path="/318" component={LectS_3_42} />,
  //Fuegos_artificiales_y_su_contaminación"
  <ProtectedRoute exact path="/319" component={LectS_3_43} />,
  //Anuncio_impresora"
  <ProtectedRoute exact path="/320" component={LectS_3_44} />,
  //El_sombrerero_loco"
  <ProtectedRoute exact path="/321" component={LectS_3_45} />,
  //Göbekli_Tepe"
  <ProtectedRoute exact path="/322" component={LectS_3_46} />,
  //El_arte_de_la_pintura"
  <ProtectedRoute exact path="/323" component={LectS_3_47} />,
  //El_primer_alimento_de_nuestra_vida_la_leche"
  <ProtectedRoute exact path="/324" component={LectS_3_48} />,
  //Go_el_juego_de_mesa_hino"
  <ProtectedRoute exact path="/325" component={LectS_3_49} />,
  //Una_fogata_en_el_cielo"
  <ProtectedRoute exact path="/326" component={LectS_3_50} />,
  //La_importancia_del_sueño"
  <ProtectedRoute exact path="/327" component={LectS_3_51} />,
  //Derecho_a_la_salud"
  <ProtectedRoute exact path="/328" component={LectS_3_52} />,
  //Horario_de_vuelos"
  <ProtectedRoute exact path="/329" component={LectS_3_53} />,
  //Distanciamiento_social_y_acercamiento_virtual"
  <ProtectedRoute exact path="/330" component={LectS_3_54} />,
  //El_reciclaje"
  <ProtectedRoute exact path="/333" component={LectS_3_56} />,
  //El_origen_del_cine"
  <ProtectedRoute exact path="/334" component={LectS_3_57} />,
  //Una_obra_de_teatro"
  <ProtectedRoute exact path="/335" component={LectS_3_58} />,
  //Los_números_triangulares"
];

const Lectura3ro = routes.map((route, key) => {
  return { ...route, key: key };
});

export default Lectura3ro;
