import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
    ContenedorLectura:{
        width:'502px',
                  [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
    },
    ContainerTitulo:{
        margin:'0 0 34px 0',
        '& .Titulo':{
            letterSpacing: '0px',
            color: '#285FDB',
            margin:'0',
            textAlign:'center',
            font:'43px fredoka one',
            lineHeight:'52px',
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            margin:'0 0 36px 0',
            color:'#000',
            font:'18px lato',
            lineHeight :'28px',
            letterSpacing: '0.17px',
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .SubTitulo':{
            color:'#F6664D',
            margin:'0',
            lineHeight :'28px',
            font:'bold 26px lato',
            letterSpacing: '0.24px',
            [theme.breakpoints.down(502)]:{
                font:'bold 18px lato',
                lineHeight :'24px',
                letterSpacing: '0.21px',
            },
        },
        '& .SubTitulo2':{
            color:'#926DD1',
            margin:'0',
            font:'bold 22px lato',
            lineHeight :'24px',
            letterSpacing: '0.21px',
            [theme.breakpoints.down(502)]:{
                font:'bold 18px lato',
                lineHeight :'20px',
                letterSpacing: '0.16px',
            },
        },
        '& .TextImg':{
            color:'#717476',
            margin:'24px 0 0 0',
            font:' 16px lato',
            lineHeight :'25px',
            letterSpacing: '0.15px',
            [theme.breakpoints.down(502)]:{
                font:' 11px lato',
                lineHeight :'14px',
                letterSpacing: '0.10px',
            },
        },

        '& .Imagen':{
            width:'100%',
        },  
      
    }
}));

const La_lucha_contra_la_obsolescencia_programada = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>

            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                        <h2 className="Titulo">La lucha contra <br/>la obsolescencia programada</h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                       <p className='Lectura'>
                        <h3 className='SubTitulo'>¿Qué es y cómo funciona la obsolescencia programada?</h3><br/>

                        En la actualidad, la vida útil de muchos aparatos es cada vez más corta, sobre todo la 
                        de los electrónicos y los electrodomésticos. ¿Por qué pasa esto? El fenómeno que lo 
                        causa se llama <em>obsolescencia programada</em>. Una empresa incurre en esta práctica 
                        cuando intencionalmente fabrica productos con una vida útil reducida para obligar a 
                        los consumidores a reemplazarlos al poco tiempo. ¿Cómo lo hace y por qué?
                       </p>                   
                    </Grid>
                    <Grid container justifyContent='center'>
                        <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion36/1.png" alt="Tarjeta" />
                    </Grid>
                    <Grid container >
                        <p className="TextImg">
                            ¿Sabes cuántos aparatos electrónicos ha dejado de usar tu familia durante los últimos cinco años?
                        </p>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container >
                        <p className='Lectura'>
                            Por una parte, los fabricantes utilizan materiales de baja calidad para que los 
                            productos finales no duren mucho tiempo. De esta forma, el consumidor tendrá que 
                            reemplazarlos o repararlos pronto. <br/><br/>
                            
                            Por otro lado, las empresas buscan evitar que los usuarios soliciten reparaciones 
                            para sus productos averiados. Lo consiguen cambiando constantemente las piezas 
                            que usan para fabricar versiones distintas. Así, las que se necesitarían para la 
                            reparación solicitada por el usuario estarán agotadas. <br/><br/>
                            
                            Esto puede notarse bien con los dispositivos electrónicos. Además de las piezas 
                            físicas (y de su calidad), utilizan sistemas operativos, programas y aplicaciones que la 
                            industria cambia a un ritmo muy acelerado. Con frecuencia, las actualizaciones sólo 
                            están disponibles para los últimos modelos. Con el tiempo, cada aparato va 
                            perdiendo parte de sus funciones, hasta que deja de ser útil. <br/><br/>
                            
                            <h3 className='SubTitulo'>Las consecuencias de la obsolescencia programada</h3><br/>
                            
                            Los fabricantes tienen un objetivo concreto: obligar al usuario a consumir más 
                            productos y a comprar aparatos nuevos con frecuencia, porque así incrementan sus 
                            ganancias. Pero ¿cómo crees que afecta esto a los consumidores? <br/><br/>
                            
                            <h3 className='SubTitulo2'>Consecuencias para los individuos</h3><br/>
                            
                            Mientras que un aparato podría tener una vida útil de 10 años, la obsolescencia 
                            programada obliga al consumidor a comprar uno nuevo cada tres o cuatro. Y el 
                            presupuesto que debía destinarse para tener el aparato se incrementa 
                            considerablemente. La obsolescencia programada está en todas partes: en casi todo 
                            lo que usamos. En otras palabras, gastamos más dinero del necesario. Si no 
                            invirtiéramos tanto en esos productos probablemente tendríamos ahorros mayores 
                            o mejores adquisiciones.<br/><br/>
                            
                            Si estamos perdiendo tanto dinero, ¿por qué permitimos que la obsolescencia 
                            programada siga? Porque quienes se enriquecen con ella tienen formas de ocultarla. 
                            Constantemente, en la publicidad y en los medios de comunicación se presentan las 
                            pequeñas mejoras que tiene un dispositivo con respecto a su versión anterior, pero 
                            los fabricantes lo anuncian como un modelo muy diferente, transformando esas 
                            modificaciones mínimas en grandes beneficios. Además, venden la idea de que la 
                            personalidad individual se muestra a partir de los productos que adquirimos. <br/><br/>
                            
                            <h3 className='SubTitulo2'>Consecuencias para la sociedad</h3><br/>
                            
                            El problema más serio que la obsolescencia produce a gran escala es la 
                            contaminación. Cuando se remplazan innecesariamente tantos aparatos es común 
                            que se desechen los anteriores, lo cual genera una gran cantidad de basura.
                        </p>
                    </Grid>
                    <Grid container justifyContent='center'>
                        <Grid container justifyContent='center'>
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion36/2.png" alt="Tarjeta" />
                        </Grid>
                        <Grid container >
                            <p className="TextImg">
                                Tratar la basura electrónica es muy peligroso y dañino para la salud. Las empresas 
                                de los países ricos delegan esta tarea a diferentes comunidades desprotegidas 
                                alrededor del globo.
                            </p>
                        </Grid>
                    </Grid>

                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container >
                        <p className='Lectura'>
                            Los culpables directos de esta situación son las grandes empresas, pero a nosotros 
                            nos toca ser conscientes del fenómeno y consumir con responsabilidad. Por su parte, 
                            los gobiernos deben establecer leyes para que los fabricantes hagan productos con 
                            mayor durabilidad. <br/><br/>
                            
                            Muchos organismos y gobiernos ya se han fijado ese propósito. Por ejemplo, la Comisión 
                            Europea presentó una iniciativa para que las marcas que fabrican electrodomésticos 
                            (como lavadoras o lavavajillas) estén obligadas a tener piezas de repuesto de todos 
                            sus productos durante al menos 10 años, a partir de 2021. ¿Qué otras soluciones ves 
                            tú al problema de la obsolescencia programada?
                        </p>
                    </Grid>
                </Grid>
        </Grid>
    </Grid>
    );
}
 
export default La_lucha_contra_la_obsolescencia_programada;
