import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
     Contenedor:{
        background:'#E9FBF9',
        width:'100vw',
        overflowX:'clip',
        [theme.breakpoints.down(1161)]:{
            background:'none',
           overflowX:'unset',
        },
        [theme.breakpoints.down(600)]:{
            marginTop: '79.51px',
        }
    },
    ContainerInfoLectura:{
        position:'relative',
        height:'86px',
        background:'#817FD9',
        '& .BarraInfoLectura':{
            height:'86px',
            background:`Url(https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Barras/Morada.png)`,
        },
        '& .InfoLectura':{
            position:'absolute',
            height:'86px',
            width:'219px',
            boxSizing:'border-box',
            padding:'17.2px 0',
            background:'#FAFAFA',
            borderRadius:'50px',
            boxShadow: '2px 2px 2px 2px #00000029',
            zIndex:'4',
        },
        '& .InfoLectura:before':{
            position:'absolute',
            display:'flex',
            boxSizing:'border-box',
            padding:'0 0 0 10px',
            justifyContent:'center',
            alignItems:'center',
            height:'39.88px',
            width:'39.88px',
            borderRadius:'50%',
            content:'""',
            boxShadow: '3px 0px .5px 0px #00000029',
            background:'#FAFAFA',
            left:'90px',
            top:'65px',
            transform: 'rotate(0.25turn)',
        },
        '& .InfoLectura:after':{
            content:'""',
            height:'15px',
            width:'15px',
            borderTop:'2px solid #817FD9',
            borderLeft:'2px solid #817FD9',
            position:'absolute',
            bottom:'-10px',
            left:'Calc(50% - 8px)',
            transform: 'rotate(0.625turn)',
        },
        '& .Sesion':{
            borderLeft:'2px solid #D8D8D8',
        },
        '& .Dato':{
            margin:'0 0 5px 0',
            textAlign:'center',
            font:'bold 17px lato',
            color:'#817FD9',
        },
        '& .Caracter':{
            margin:'0',
            textAlign:'center',
            font:'bold 17px lato',
            color:'#000000',
        },
    },
    ContenedorLectura:{
        background:'#fff',
        width:'1160px',
        height:'100%',
        padding:'60px 168px 35px ',
        [theme.breakpoints.down(1161)]:{
            width:'502px',
            padding:'60px 0 0 0',
          },
        [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
        '& .ContainerDerechos':{
            bottom:'80px',
            [theme.breakpoints.down(1161)]:{
                display:'none',
            },
        },
        '& .Derechos':{
            textAlign:"center",
            margin:"0px",
            font:" 15px lato",
            color:"#717476",
        },
        '& .Escritorio':{
            display:'block',
            [theme.breakpoints.down(1161)]:{
                display:'none'
            },
        },
        '& .Tablet':{
            display:'none',
            [theme.breakpoints.down(1161)]:{
                display:'block'
            },
        }
    },
    ContainerTitulo:{
        margin:'0 0 39px 0',
        '& .Titulo':{
            letterSpacing: '0px',
            color: '#FC5E9E',
            margin:'0',
            textAlign:'center',
            font:'48px fredoka one',
            [theme.breakpoints.down(1161)]:{
                font:'43px fredoka one',
                lineHeight:'52px',
            },
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            lineHeight : '30px',
            margin:'0 0 36px 0 ',
            font:'22px lato',
            color:'#000',
            letterSpacing: '0.20px',
            [theme.breakpoints.down(1161)]:{
                font:'18px lato',
                lineHeight :'28px',
                letterSpacing: '0.17px',
            },
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .ul':{
            padding:'0 0 0 19px'
        },
        '& .li':{
            color:'#FC5E9E'
        },
        '& .Esp':{
            margin:'0'
        },
        
        '& .Imagen':{
            [theme.breakpoints.down(1161)]:{
                width:'100%',
            },
        },


    },

}));

const Las_8_reinas = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
             <Grid container item justifyContent="center" className={classes.ContainerInfoLectura}>
               <Grid container className="BarraInfoLectura"></Grid>
               <Grid container item className="InfoLectura">
                    <Grid container item direction="column" xs={6} justifyContent="center">
                        <p className="Dato">Grado</p>
                        <p className="Caracter">4</p>
                    </Grid>
                    <Grid container item direction="column" xs={6} justifyContent="center" className="Sesion">
                        <p className="Dato">Sesión</p>
                        <p className="Caracter">55</p>
                    </Grid>
               </Grid>
            </Grid>

            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >
                        Las 8 reinas
                    </h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                            El ajedrez es uno de los juegos de estrategia más populares. Está conformado por un 
                            tablero de 64 casillas (32 blancas y 32 negras), acomodadas en 8 columnas y 8 filas. 
                            Además, tiene 32 piezas: 16 blancas y 16 negras.
                        </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <img className='Imagen' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img4to/Sesion55/1.png"  alt="Tarjeta" />
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                            Para ganar el juego, lo más importante es cuidar al rey. Sin embargo, la pieza más 
                            poderosa es la conocida como reina (aunque realmente se llame <em>dama</em>), pues su 
                            movimiento es el más versátil de todos. Se desplaza en cualquier dirección y 
                            cualquier número de casillas. Entonces, se vuelve importante preguntarse: ¿en qué 
                            casillas tendrían que estar las dos reinas para que no se ataquen mutuamente? Las 
                            opciones son bastantes, en la siguiente imagen se muestra una de ellas.
                        </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                            <img className='Imagen' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img4to/Sesion55/2.png"  alt="Tarjeta" />
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                            Si el tablero tiene 8 filas y 8 columnas, ¿es posible colocar 8 reinas de tal forma que 
                            no se enfrenten? La respuesta es sí, aunque no es fácil hacerlo. De hecho, hay 92 
                            maneras. La siguiente imagen muestra una distribución que cumple esta condición.
                            ¿Puedes encontrar otra forma?
                        </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                            <img className='Imagen' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img4to/Sesion55/3.png"  alt="Tarjeta" />
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                            ¡Usemos números para facilitar las cosas! Y consideremos lo siguiente:

                            <ul className='ul'>
                                <li className='li'>
                                <p className='Lectura Esp'>Dos o más reinas no pueden compartir una misma fila, de lo contrario se enfrentarían.</p>
                                </li>
                                <li className='li'>
                                <p className='Lectura Esp'>Tampoco pueden compartir una misma columna.</p>
                                </li>
                                <li className='li'>
                                <p className='Lectura Esp'>Las columnas estarán numeradas del 1 al 8.</p>
                                </li>
                            </ul>
                            
                            Entonces, buscamos formar una sucesión de dígitos, donde el primero indique la 
                            columna que ocupará la primera reina, el segundo el de la segunda, y así 
                            sucesivamente.<br/><br/> 
                            
                            En el arreglo de arriba la secuencia es 3, 6, 8, 2, 4, 1, 7, 5. Por lo tanto, hay que 
                            encontrar sucesiones de números del 1 al 8 en el orden que sea, sin repetir 
                            cantidades. ¡Pero no olvides las diagonales! Por ejemplo, si la primera reina está en la 
                            columna 1, la segunda no puede estar en la columna 2.

                        </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                            <img className='Imagen' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img4to/Sesion55/4.png"  alt="Tarjeta" />
                    </Grid>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                            Para saber si dos reinas comparten diagonal, basta hacer una suma o una resta:

                            <ul className='ul'>
                                <li className='li'>
                                    <p className='Lectura Esp'>Si la resta del número de columna y el de fila es la misma, ambas están en una diagonal. De hecho, están en una diagonal de izquierda a derecha.</p>
                                </li>
                                <li className='li'>
                                    <p className='Lectura Esp'>Si la suma del número de la columna y el de la fila coinciden, están en una diagonal. Esta vez, de derecha a izquierda.</p>
                                </li>
                            </ul>

                            En la imagen anterior, la primera pieza está en el 1, entonces 1 – 1 = 0. La segunda 
                            está en el 2, así que 2 – 2 = 0. Las dos restas dan 0 como resultado y por lo tanto 
                            ambas reinas estaban en la misma diagonal de izquierda a derecha.<br/><br/>
                            
                            ¿Qué pasa si la primera reina está en el 7 y la segunda en el 6? Pues a la primera le 
                            correspondería la suma 7 + 1 = 8, y a la segunda, 6 + 2 = 8. De nuevo, las sumas son 
                            iguales y las piezas están en una misma diagonal de derecha a izquierda.
                        </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                            <img className='Imagen' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img4to/Sesion55/5.png"  alt="Tarjeta" />
                    </Grid>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                            ¡Listo! Ya tienes las herramientas necesarias para encontrar un arreglo más para las 
                            8 reinas. Para motivarte a comprender la jugada, te contaremos que hay un famoso 
                            problema relacionado. Se trata de una situación hipotética en la que hay que colocar 
                            1 000 reinas, por supuesto, en un tablero de 1 000 × 1 000, y quien logre resolverlo, 
                            ¡ganará un millón de dólares!<br/><br/> 
                            
                            ¿Qué dices? ¿Te animarías a intentar resolver esta interrogante? ¡Puedes emplear el 
                            aprendizaje que acabas de adquirir!
                        </p>
                    </Grid>
                </Grid>

                <Grid container item xs={12} justifyContent="center" className="ContainerDerechos">
                    <p className="Derechos">
                        © 2022. Todos los derechos reservados, Pearson, Circuito de lectores.
                    </p>                
                </Grid>
        </Grid>
    </Grid>
    );
}
 
export default Las_8_reinas;
