import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
    ContenedorLectura:{
        width:'502px',
                  [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
    },
    ContainerTitulo:{
        margin:'0 0 34px 0',
        '& .Titulo':{
            letterSpacing: '0px',
            color: '#176FFC',
            margin:'0',
            textAlign:'center',
            font:'43px fredoka one',
            lineHeight:'52px',
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            margin:'0 0 36px 0',
            color:'#000',
            font:'18px lato',
            lineHeight :'28px',
            letterSpacing: '0.17px',
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        "& .SubTitulo": {
            color: "#FC5E9E",
            margin: "0",
            lineHeight: "28px",
            font: "bold 26px lato",
            letterSpacing: "0.24px",
            [theme.breakpoints.down(502)]: {
              font: "bold 22px lato",
              lineHeight: "24px",
              letterSpacing: "0.21px",
            },
          },
        '& .Imagen':{
            width:'100%',
        }, 
      
    }
}));

const Fuegos_artificiales_y_su_contaminación = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >
                        Fuegos artificiales<br/> y su contaminación                    
                    </h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                      <p className="Lectura">
                        Todas las culturas tienen días festivos y formas especiales de conmemorarlos. Un 
                        recurso común a varias regiones son los fuegos artificiales, destinados para las 
                        fechas más importantes. Una de las celebraciones en las que se emplean más es el 
                        Año Nuevo. En varios países del mundo se organizan espectáculos de este tipo para 
                        festejarlo en grande. ¿Alguna vez los has visto?<br/><br/> 
                        
                        <h3 className='SubTitulo'>Pirotecnia y algunos datos más</h3><br/> 
                        
                        La pirotecnia existe desde la Antigüedad, pues la pólvora se inventó hace más de 10 
                        siglos. La cultura árabe fue la primera en utilizarla como un instrumento de fiesta. 
                        Hoy en día sigue cumpliendo este propósito en muchas partes. Los fuegos artificiales 
                        son un montaje planeado en el que los colores y las luces se conjugan para crear 
                        obras artísticas en el cielo.
                      </p> 
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img3ro/Sesion42/1.png" alt="Tarjeta" />
                    </Grid>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                      <p className="Lectura">
                        ¿Te has preguntado cómo se crean esos colores? La pólvora se mezcla con materiales 
                        específicos para obtener un tono en especial. Por ejemplo, se usa sal de estroncio 
                        para generar el rojo, sal de sodio para el amarillo y sal de cobre para el azul. Después, 
                        éstos se combinan a su vez con más tintes y elementos para originar toda la gama de 
                        colores que observamos.<br/> <br/> 
                        
                        ¿Conoces los fuegos artificiales que se lanzan a medianoche por el Año Nuevo? Sin
                        duda, son una demostración impresionante, pero ¿has observado el cielo a la mañana 
                        siguiente? Por lo general se encuentra nublado y gris, incluso se percibe un ligero
                        olor a quemado en el ambiente. Eso se debe a la contaminación que provocan y ésta 
                        puede quedarse en el aire que respiramos hasta por tres días antes de disiparse.<br/> <br/> 
                        
                        No solemos notar su gravedad, pero los fuegos artificiales causan efectos negativos 
                        en el cuerpo humano y en el planeta. Los principales componentes de la polución son 
                        monóxido de carbono, dióxido de carbono, percloratos y todas las sustancias que 
                        pigmentan las luces. Estas partículas son tóxicas si se respiran en altas 
                        concentraciones. Ahora recuerda que la celebración se hace al mismo tiempo en 
                        lugares cercanos. ¡La cantidad de contaminación resultante permanece en el mismo 
                        espacio! <br/> <br/> 
                        
                        En algunas ciudades de Europa y Estados Unidos de América se tiene prohibido el 
                        uso de fuegos artificiales por estas razones. Ha habido otros países que no han 
                        tomado decisiones tan severas, pero sí adoptaron otras medidas para reducir el 
                        impacto. Por ejemplo, en Suecia y Alemania las personas necesitan un permiso 
                        especial para comprar pirotecnia. De esta forma se controla su uso y se reduce la 
                        contaminación. ¿Consideras que todas las naciones tendrían que implementar 
                        acciones similares para reducir los efectos de estos espectáculos? ¿Qué soluciones 
                        propondrías tú para generar un cambio dentro de tu comunidad?
                      </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img3ro/Sesion42/2.png" alt="Tarjeta" />
                    </Grid>
                </Grid>
        </Grid>
    </Grid>
    );
}
 
export default Fuegos_artificiales_y_su_contaminación ;
