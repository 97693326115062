import React                from "react";
import { makeStyles,Grid }  from '@material-ui/core';

import evaluacion1            from './colorlessImages/Insignias_evaluacion1A.png'
import evaluacion2            from './colorlessImages/Insignias_evaluacion2A.png'
import evaluacion3            from './colorlessImages/Insignias_evaluacion3A.png';
import evaluacionFinal        from './colorlessImages/Insignia_FinalA.png';
import evaluacionDiagnostica  from './colorlessImages/Insignia_DiagnosticaA.png';
import hologramaDiagnostica   from './badgesImages/Insignias_evaluacionDiagnostica.png'
import hologramaFinal         from './badgesImages/Insignias_evaluacionFinal.png';
import holograma1             from './badgesImages/Insignias_evaluacion01.png'
import holograma2             from './badgesImages/Insignias_evaluacion02.png'
import holograma3             from './badgesImages/Insignias_evaluacion03.png'

import EvaluationBadge               from "./EvaluationBadge";

const useStyles = makeStyles(theme =>({
  mainContainer: {
    width:'100vw',
    padding: "62px 0 0 0",
    [theme.breakpoints.down(1440)]:{
      padding:'52px 0 0 0 ',
    },
    [theme.breakpoints.down(700)]:{
      padding:'17px 0 0 00px ',
    },
    
  },
  mainContainer2:{
    borderRadius: "20px",
    border: "#1F9EC4 3px solid",
    boxShadow: '0px 2px 5px #0000006C',
    maxWidth: '1380px',
    // width:"1380px",
    // height: "433px",
  },
  mainContainer3:{
    borderRadius: "20px",
    border: "#1F9EC4 3px solid",
    width:"80%",
    height: "100%",
    [theme.breakpoints.down(700)]:{
      width:"100%",
    },
  },
  // Bola:{
  //   backgroundColor: '#EB7CBC',
  //   border: "#707070 1px solid",
  //   height:'201px',
  //   width: "198px"
  // },
  bola:{
    backgroundColor: '#69C8D8',
    border: "#707070 1px solid",
    borderRadius: "180px",
    height:'233px',
    width: "233px"
  },
  badgesTitle:{
    font:'38px Fredoka One',
    letterSpacing: '0.28px',
    color: '#1F9EC4',
    
    [theme.breakpoints.down(700)]:{
      font:'30px Fredoka One',
    },
    [theme.breakpoints.down(500)]:{
      font:'24px Fredoka One',
    },
  },
  badgesName:{
    font:'24px Fredoka One',
    letterSpacing: '0.18px',
    color: '#55A7A9',
    margin:'0px',
    [theme.breakpoints.down(700)]:{
      font:'18px Fredoka One',
    },
  },
  badgesContainer:{
    width:"300px",
    height: "300px"
  },
  masterText:{
    font:'21px Fredoka One',
    letterSpacing: '0.38px',
    color: '#5CAE9A',
    margin:'0px',
  },

}));

const BadgesSocioEmocional = (props) => {
  const classes = useStyles()
  const { evaluationBadges } = props

  return(
    <>
    <Grid 
      container
      direction="column"
      item 
      sm={12}
      className={classes.mainContainer}
      justifyContent="flex-start" 
      alignItems="center"
    >
      <Grid container justifyContent="flex-start" alignItems="center" className={classes.mainContainer2}> 
        <Grid container item xs={12} justifyContent="center" alignItems="center">
          <h2 className={classes.badgesTitle}>Insignias de evaluaciones</h2>
        </Grid>
        <Grid container item xs={12} justifyContent="space-around" direction="row" >
          {/* <Grid container item xs={1}></Grid> */}
          <EvaluationBadge
            badgeImage={evaluationBadges > 0 ? hologramaDiagnostica : evaluacionDiagnostica}
            badgeText={"Diagnóstica"}
          />
          <EvaluationBadge
            badgeImage={evaluationBadges > 1 ? holograma1 : evaluacion1}
            badgeText={"Formativa 1"}
          />
          <EvaluationBadge
            badgeImage={evaluationBadges > 2 ? holograma2 : evaluacion2}
            badgeText={"Formativa 2"}
          />
          <EvaluationBadge
            badgeImage={evaluationBadges > 3 ? holograma3 : evaluacion3}
            badgeText={"Formativa 3"}
          />
          <EvaluationBadge
            badgeImage={evaluationBadges > 4 ? hologramaFinal : evaluacionFinal}
            badgeText={"Final"}
          />
        </Grid>
      </Grid>
    </Grid>
    {/* <Grid container item xs={12} direction="row" justifyContent="center" alignItems="center">
        <Grid container item xs={6}>
          <Grid className={classes.badgesContainer}>
            <img className={classes.badgesContainer} src={maestra} alt="Insignia Maestra" />
          </Grid>
        </Grid>
        <Grid container item xs={6}></Grid>
      </Grid> */}
    </>
  )
}

export default BadgesSocioEmocional;