import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
      Contenedor:{
        background:'#E9FBF9',
        width:'100vw',
        overflowX:'clip',
        [theme.breakpoints.down(1161)]:{
            background:'none',
           overflowX:'unset',
        },
        [theme.breakpoints.down(600)]:{
            marginTop: '79.51px',
        }
    },
   ContainerInfoLectura: {
    position: "relative",
    height: "86px",
    [theme.breakpoints.down(502)]:{
        height: "43px",
    },
    background: "#76D4C3",
    "& .BarraInfoLectura": {
      height: "86px",
      background: `Url(https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Barras/Azul.png)`,
        [theme.breakpoints.down(502)]:{
            height: "43px",
        },
    },
    "& .InfoLectura": {
      position: "absolute",
      height: "86px",
      width: "219px",
      boxSizing: "border-box",
      padding: "17.2px 0",
      background: "#FAFAFA",
      borderRadius: "50px",
      boxShadow: "2px 2px 2px 2px #00000029",
      zIndex: "4",
        [theme.breakpoints.down(502)]:{
            height: "43px",
            width: "109px",
            padding: "8.6px 0",
            borderRadius: "25px",
        },
    },
    "& .InfoLectura:before": {
      position: "absolute",
      display: "flex",
      boxSizing: "border-box",
      padding: "0 0 0 10px",
      justifyContent: "center",
      alignItems: "center",
      height: "39.88px",
      width: "39.88px",
      borderRadius: "50%",
      content: '""',
      boxShadow: "3px 0px .5px 0px #00000029",
      background: "#FAFAFA",
      left: "90px",
      top: "65px",
      transform: "rotate(0.25turn)",
      [theme.breakpoints.down(502)]:{
        left: "45px",
        top: "30px",
        height: "20.88px",
        width: "20.88px",
        },
    },
    "& .InfoLectura:after": {
      content: '""',
      height: "15px",
      width: "15px",
      borderTop: "2px solid #20A698",
      borderLeft: "2px solid #20A698",
      position: "absolute",
      bottom: "-10px",
      left: "Calc(50% - 8px)",
      transform: "rotate(0.625turn)",
      [theme.breakpoints.down(502)]:{
        height: "10px",
        width: "10px",
        left: "Calc(50% - 4.5px)",
        bottom: "-3px",
        borderTop: "1px solid #20A698",
        borderLeft: "1px solid #20A698",
        },
    },
    "& .Sesion": {
      borderLeft: "2px solid #D8D8D8",
    },
    "& .Dato": {
      margin: "0 0 5px 0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#20A698",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        margin: "0 0 2.5px 0",
        },
    },
    "& .Caracter": {
      margin: "0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#000000",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        },
    },   },
    ContenedorLectura:{
        background:'#fff',
        width:'1160px',
        height:'100%',
        padding:'60px 168px 35px' ,
            transform: (props) =>
            props.scale > 1 ? ` 
            scale(${props.scale}) 
            translate(0px, 10%)` : `none`,[theme.breakpoints.down(1161)]:{
            width:'502px',
            padding:'60px 0 0',
            },
        [theme.breakpoints.down(502)]:{
        width:'350px',
        padding:'60px 0 0 0',
            },
        '& .ContainerDerechos':{
            bottom:'80px',
            [theme.breakpoints.down(1161)]:{
                display:'none',
            }
        },
        '& .Derechos':{
            textAlign:"center",
            margin:"0px",
            font:" 15px lato",
            color:"#717476",
        },
        '& .Escritorio':{
            display:'block',
            [theme.breakpoints.down(1161)]:{
                display:'none'
            },
        },
        '& .Tablet':{
            display:'none',
            [theme.breakpoints.down(1161)]:{
                display:'block'
            },
        },  
    },

    ContainerTitulo:{
        margin:'0 0 46px 0',
        [theme.breakpoints.down(1161)]:{
            margin:'0 0 38px 0',
        },
        '& .Titulo':{
            font:'48px fredoka one',
            letterSpacing: '0px',
            color: '#5B12BF',
            margin:'0',
            textAlign:'center',
            [theme.breakpoints.down(1161)]:{
                font:'43px fredoka one',
                lineHeight:'52px',
                textAlign:'center',
            },
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        position:'relative',
        '& .Lectura':{
            lineHeight : '30px',
            margin:'0 0 36px 0',
            font:'22px lato',
            color:'#000',
            letterSpacing: '0.20px',
            [theme.breakpoints.down(1161)]:{
                font:'18px lato',
                letterSpacing:'.17px',
                lineHeight:'28px',
            },
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .SubTitulo':{
            font:'bold 30px lato',
            color:'#31BD90',
            letterSpacing:'.28px',
            lineHeight:'30px',
            margin:'0',
            [theme.breakpoints.down(1161)]:{
                lineHeight :'28px',
                font:'bold 26px lato',
                letterSpacing: '0.24px',
            },
            [theme.breakpoints.down(502)]:{
                font:'bold 18px lato',
                lineHeight :'24px',
                letterSpacing: '0.21px',
            },
        },
        '& .TextImg':{
            font:'20px lato',
            color:'#717476',
            letterSpacing:'.19px',
            lineHeight:'30px',
            margin:'24px 0 0 0',
            [theme.breakpoints.down(1161)]:{
                font:' 16px lato',
                lineHeight :'25px',
                letterSpacing: '0.19px',
            },
            [theme.breakpoints.down(502)]:{
                font:' 11px lato',
                lineHeight :'14px',
                letterSpacing: '0.10px',
            },
        },
     '& .TextImg2':{
            boxSizing:'border-box',
            padding:'0 0 0 10px',
            [theme.breakpoints.down(1161)]:{
                boxSizing:'border-box',
                padding:'0 0 0 5px'
            },
            [theme.breakpoints.down(502)]:{
                margin:'0px!important',
            },
        },
        '& .Pos':{
            width:'750px',
            position:'absolute',
            margin:'53px 0 0 0',
            [theme.breakpoints.down(1116)]:{
                width:'85.25%',
            },
            [theme.breakpoints.down(502)]:{
                width:'86%',
                margin:'20px 0 0 0',
            },
        },

        '& .SubTitulo2':{
            font:'bold 26px lato',
            color:'#D9B13E',
            letterSpacing:'.24px',
            lineHeight:'28px',
            margin:'0',
            [theme.breakpoints.down(1161)]:{
                font:'bold 22px lato',
                lineHeight :'24px',
                letterSpacing: '0.21px',
            },
            [theme.breakpoints.down(502)]:{
                font:'bold 18px lato',
                lineHeight :'20px',
                letterSpacing: '0.16px',
            },
        },

        '& .Imagen':{
            [theme.breakpoints.down(1161)]:{
                width:'100%',
            },
        },
        '& .Imagen2':{
            [theme.breakpoints.down(1161)]:{
                width:'100%',
            },
        },
        '& .Imagen4':{
            [theme.breakpoints.down(1161)]:{
                width:'90%',
            },
            [theme.breakpoints.down(502)]:{
                height:'160.8px',
            },
        },
        '& .Imagen3':{
            margin:'36px 0 0 0',
            [theme.breakpoints.down(1161)]:{
                width:'63.15%',
            },
        },



    },
  
    

}));

const Siempre_es_posible_ganar_un_juego = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
             <Grid container item justifyContent="center" className={classes.ContainerInfoLectura}>
               <Grid container className="BarraInfoLectura"></Grid>
               <Grid container item className="InfoLectura">
                    <Grid container item direction="column" xs={6} justifyContent="center">
                        <p className="Dato">Grado</p>
                        <p className="Caracter">5</p>
                    </Grid>
                    <Grid container item direction="column" xs={6} justifyContent="center" className="Sesion">
                        <p className="Dato">Sesión</p>
                        <p className="Caracter">26</p>
                    </Grid>
               </Grid>
            </Grid>

            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >
                        ¿Siempre es posible <br/>ganar un juego?
                    </h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                            En juegos como el ajedrez, ganar depende de tu destreza mental. En otros, cuentan 
                            tus habilidades físicas. En el basquetbol, por ejemplo, influye también la estrategia y
                            el desempeño de tu equipo. Pero existen juegos donde ninguno de estos aspectos 
                            afecta el resultado. Usualmente involucran dados.<br/><br/>

                            <h3 className='SubTitulo'>Historia de los juegos de azar</h3><br/>
                            
                            Los egipcios y los griegos fueron los primeros en desarrollar un juego de dados, los 
                            astrágalos, pequeños huesos que se obtenían de las patas de animales como las 
                            ovejas. Como las caras de estos huesos tenían formas irregulares, podían caer en 
                            diferentes posiciones.
                        </p>
                    </Grid>
                    <Grid container xs={12} justifyContent='space-between'>
                        <Grid container justifyContent='center' xs={6}>
                            <img className="Imagen2" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion26/1.png" alt="Tarjeta" />
                            <p className='TextImg '> En una ciudad egipcia fue encontrado un sofisticado estuche con astrágalos. ¡Se diseñó hace más de treinta y cinco siglos!</p>
                        </Grid>
                        <Grid container justifyContent='center' xs={6}>
                            <img className="Imagen4" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion26/2.png" alt="Tarjeta" />
                            <p className='TextImg TextImg2'> Ésta es la <em>Astragalízousa</em> (jugadora de astrágalos). Es la copia romana de una escultura griega.</p>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                        <img className="Imagen Escritorio" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion26/4.png" alt="Tarjeta" />
                        <img className="Imagen Tablet" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion26/4R.png" alt="Tarjeta" />
                        <p className='Pos Lectura '> 
                            Por otro lado, el término <em>azar</em> viene del árabe. La palabra <em>az-zahr</em> significaba
                            “la flor” y hacía referencia sobre todo a una flor blanca y de olor fuerte que 
                            todavía hoy llamamos <em>azahar</em>.
                        </p>



                        <img className="Imagen3" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion26/3.png" alt="Tarjeta" />
                        <p className='TextImg'> 
                            Sabemos que a los dados se les decía “los azares” probablemente porque tenían estas flores pintadas en 
                            sus caras. A partir de allí, <em>azar</em> pasó a significar “buena o mala suerte”.
                        </p>

                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura ">
                            Con el tiempo, la popularidad de estos juegos aumentó. En ciertos periodos de la 
                            historia fueron prohibidos, ya que su principal atractivo eran las apuestas. Pero 
                            ¿por qué prohibir las apuestas? ¡Te invitamos a reflexionar e investigarlo!<br/><br/>  
                            
                            <h3 className='SubTitulo'>Las matemáticas del azar</h3><br/> 
                            Luca Pacioli fue uno de los primeros matemáticos en estudiar estos juegos. En 1949 
                            planteó un problema que reproducimos aquí de forma resumida:
                        </p>
                    </Grid>
                    <Grid container justifyContent='center' >
                        <img className="Imagen Escritorio" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion26/5.png" alt="Tarjeta" />
                        <img className="Imagen Tablet" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion26/5R.png" alt="Tarjeta" />
                        <p className='Pos Lectura '> 
                            En cierto juego de pelota se necesitan 60 puntos para ganar. El ganador se 
                            llevará 22 ducados. Pero, por algún motivo, el juego se interrumpe cuando un 
                            jugador tiene 50 puntos y el otro 30. ¿Qué parte del premio le corresponde a 
                            cada uno?
                        </p>

                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                            Los matemáticos enfocaron problemas como éste, nacidos de los juegos de azar, y los 
                            usaron como base para desarrollar la <em>teoría de la probabilidad</em>. Esta disciplina estudia 
                            qué tan factible es que ocurra un evento, asignándole un valor entre 0 y 1. Un evento 
                            tiene <em>probabilidad 0</em> cuando es imposible que ocurra, y tiene <em>probabilidad 1</em> cuando va 
                            a ocurrir forzosamente, sin importar lo que pase. Por ejemplo, la probabilidad de que 
                            el Sol salga por el este es 1. La probabilidad de que salga por el oeste es 0.<br/><br/> 
                            
                            Supongamos que ha llovido mucho últimamente. El cielo está nublado y las noticias 
                            dicen que la probabilidad de que hoy llueva es de 60%. Es muy probable que suceda, 
                            aunque no es completamente seguro: puede ser que al final el cielo se despeje. El 60% 
                            es igual a 0.6. Este número está entre 0 y 1. <br/><br/>
                            
                            <h3 className='SubTitulo2'>Lanzando los dados</h3><br/>
                            
                            Cuando lanzas un dado, forzosamente debe salir una de sus seis caras. Eso tiene una 
                            probabilidad de 1. Pero dicha probabilidad está dividida entre el número de caras. La 
                            probabilidad de obtener un número en específico es de 1/6 = 0.1666… <br/><br/>
                            
                            Tomemos por ejemplo la cara 5. Acabamos de ver que con un solo lanzamiento la
                            probabilidad de obtener 5 es de 0.1666… ¿Qué significa esto y cómo podemos 
                            comprobarlo? Si continuamos lanzando el dado y registramos los resultados, la 
                            cantidad de veces que sacamos 5 se aproximará cada vez más a 16.66…% Puedes 
                            comprobar esto tirando un dado y anotando los resultados. <br/><br/>
                            
                            Pero ¿cómo podríamos estar seguros de que la probabilidad de obtener 5 sigue siendo 
                            0.1666…, aunque lancemos el dado, por ejemplo, unas 10 000 veces? ¡Registrar tantos
                            números exige mucho tiempo y paciencia! Por suerte, en internet hay simuladores que 
                            lanzan y registran los resultados por ti. Con ellos puedes saber en un segundo cuántos 
                            5 salieron en 10 000 lanzamientos. ¡Busca estos simuladores y comprueba con ellos tus 
                            primeros conocimientos de probabilidad!
                        </p>
                    </Grid>
                </Grid>


                

                <Grid container item xs={12} justifyContent="center" className="ContainerDerechos">
                    <p className="Derechos">
                        © 2022. Todos los derechos reservados, Pearson, Circuito de lectores.
                    </p>                
                </Grid>
        </Grid>
    </Grid>
    );
}
 
export default Siempre_es_posible_ganar_un_juego;
