import { makeStyles } from "@material-ui/core";
import React, { useMemo } from "react";
import Chart from "react-apexcharts";
import DownloadSelect from "../pages/Docente/Reportes/descargas/DownloadSelect";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    alignContent: "center",
    flexWrap: "nowrap",
    overflowX: "auto",
    overflowY: "hidden",
    position: 'relative',
  },
  Chart: {
    maxWidth: "800px",
    height: "380px",
    opacity: "0.9",
    width: "100%",
    minWidth: "320px",
  },
  button:{
    justifyContent: "flex-start",
    alignItems: "flex-start",
    position: 'absolute',
    top: '0',
    right: '0',
  }
}));



const SesionesAlDia = (props) => {
  const {studentsList, moda, downloadData,} = props
  const classes = useStyles();
  const list = useMemo(() => studentsList.slice(0, 10), [studentsList]);

  const dataList = [
    {
      name: "Sesión actual",
      data: list.map((student) => {
        return(
          {
            x: student.nombre + " " + student.apellido_paterno,
            y: student.sesion,
            goals: [
              {
                name: 'Esperado',
                value: moda,
                strokeWidth: 2,
                strokeHeight: 50,
                strokeColor: '#775DD0'
              }
            ]
          }
        )
      }) || []   
    }
  ]


  const [series, setSeries] = React.useState(dataList);
  const categories = ["Formativa 1", "Formativa 2", "Formativa 3"]
 
  // const [file, setFile] = useState(null);

  const options = ({
    chart: {
      id: "reactchart",
      height: 350,
      width: 800,
      type: 'bar',
      toolbar: {
        show: true,
        offsetX: 35,
        offsetY: 210,
        tools: {
          download: true,
          selection: false,
          zoom: false,
          zoomin: false,
          zoomout: false,
          pan: false,
          reset: false,
          customIcons: [
          ]
        },
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
        value: 60,
      }
    },
    colors: ['#00E396'],
    //     yaxis: {
    //   min: 0,
    //   max: 10,
    //   logBase: 10,
    //   tickAmount: 10,
    // },
    // dataLabels: {
    //   formatter: function(val, opt) {
    //     const goals =
    //       opt.w.config.series[opt.seriesIndex].data[opt.dataPointIndex]
    //         .goals
    
    //     if (goals && goals.length) {
    //       return `${val} / ${goals[0].value}`
    //     }
    //     return val
    //   }
    // },
    legend: {
      show: true,
      showForSingleSeries: true,
      customLegendItems: ['Actual', 'Esperada'],
      markers: {
        fillColors: ['#00E396', '#775DD0']
      }
    },
    noData: {
      text: "Selecciona un estudiante",
      align: 'center',
      verticalAlign: 'middle',
      offsetX: 0,
      offsetY: 0,
      style: {
        color: undefined,
        fontSize: '14px',
        fontFamily: undefined
      }
    },
     responsive: [
      {
        breakpoint: 767,
        options: {
          plotOptions: {
            bar: {
              horizontal: true,
            },
          },
          legend: {
            position: "bottom",
            fontSize: "14px",
            fontFamily: "Helvetica, Arial",
            fontWeight: 500,
            itemMargin: {
              horizontal: 5,
              vertical: 5,
            },
            markers: {
              width: 5,
              height: 5,
            },
          },
        },
      },
    ],  
  });

  React.useEffect(() => {
    // setStudentsList(studentsList)
    setSeries(dataList)

  }, [studentsList]);

  return (
    <div className={classes.container}>
      {categories && series && (
        
        <Chart
          options={options}
          series={series}
          type="bar"
          className={classes.Chart}
          height={"100%"}
          id="chart"
        >
        </Chart>
      )}
      <DownloadSelect
        downloadData={downloadData}
        className={classes.button}
      />
    </div>
  );
};

export default SesionesAlDia;
