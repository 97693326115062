import React from "react";
import ProtectedRoute from "./ProtectedRoute";

import LectS_2_3 from "../pages/Container/ContainerLecturasBiblioteca2do/Sesion3";
import LectS_2_4 from "../pages/Container/ContainerLecturasBiblioteca2do/Sesion4";
import LectS_2_5 from "../pages/Container/ContainerLecturasBiblioteca2do/Sesion5";
import LectS_2_6 from "../pages/Container/ContainerLecturasBiblioteca2do/Sesion6";
import LectS_2_7 from "../pages/Container/ContainerLecturasBiblioteca2do/Sesion7";
import LectS_2_8 from "../pages/Container/ContainerLecturasBiblioteca2do/Sesion8";
import LectS_2_9 from "../pages/Container/ContainerLecturasBiblioteca2do/Sesion9";
import LectS_2_10 from "../pages/Container/ContainerLecturasBiblioteca2do/Sesion10";
import LectS_2_11 from "../pages/Container/ContainerLecturasBiblioteca2do/Sesion11";
import LectS_2_12 from "../pages/Container/ContainerLecturasBiblioteca2do/Sesion12";
import LectS_2_13 from "../pages/Container/ContainerLecturasBiblioteca2do/Sesion13";
import LectS_2_14 from "../pages/Container/ContainerLecturasBiblioteca2do/Sesion14";
import LectS_2_15 from "../pages/Container/ContainerLecturasBiblioteca2do/Sesion15";
import LectS_2_16 from "../pages/Container/ContainerLecturasBiblioteca2do/Sesion16";
import LectS_2_17 from "../pages/Container/ContainerLecturasBiblioteca2do/Sesion17";
import LectS_2_18 from "../pages/Container/ContainerLecturasBiblioteca2do/Sesion18";
import LectS_2_19 from "../pages/Container/ContainerLecturasBiblioteca2do/Sesion19";
import LectS_2_21 from "../pages/Container/ContainerLecturasBiblioteca2do/Sesion21";
import LectS_2_22 from "../pages/Container/ContainerLecturasBiblioteca2do/Sesion22";
import LectS_2_23 from "../pages/Container/ContainerLecturasBiblioteca2do/Sesion23";
import LectS_2_24 from "../pages/Container/ContainerLecturasBiblioteca2do/Sesion24";
import LectS_2_25 from "../pages/Container/ContainerLecturasBiblioteca2do/Sesion25";
import LectS_2_26 from "../pages/Container/ContainerLecturasBiblioteca2do/Sesion26";
import LectS_2_27 from "../pages/Container/ContainerLecturasBiblioteca2do/Sesion27";
import LectS_2_28 from "../pages/Container/ContainerLecturasBiblioteca2do/Sesion28";
import LectS_2_29 from "../pages/Container/ContainerLecturasBiblioteca2do/Sesion29";
import LectS_2_30 from "../pages/Container/ContainerLecturasBiblioteca2do/Sesion30";
import LectS_2_31 from "../pages/Container/ContainerLecturasBiblioteca2do/Sesion31";
import LectS_2_32 from "../pages/Container/ContainerLecturasBiblioteca2do/Sesion32";
import LectS_2_33 from "../pages/Container/ContainerLecturasBiblioteca2do/Sesion33";
import LectS_2_34 from "../pages/Container/ContainerLecturasBiblioteca2do/Sesion34";
import LectS_2_35 from "../pages/Container/ContainerLecturasBiblioteca2do/Sesion35";
import LectS_2_36 from "../pages/Container/ContainerLecturasBiblioteca2do/Sesion36";
import LectS_2_37 from "../pages/Container/ContainerLecturasBiblioteca2do/Sesion37";
import LectS_2_39 from "../pages/Container/ContainerLecturasBiblioteca2do/Sesion39";
import LectS_2_40 from "../pages/Container/ContainerLecturasBiblioteca2do/Sesion40";
import LectS_2_41 from "../pages/Container/ContainerLecturasBiblioteca2do/Sesion41";
import LectS_2_42 from "../pages/Container/ContainerLecturasBiblioteca2do/Sesion42";
import LectS_2_43 from "../pages/Container/ContainerLecturasBiblioteca2do/Sesion43";
import LectS_2_44 from "../pages/Container/ContainerLecturasBiblioteca2do/Sesion44";
import LectS_2_45 from "../pages/Container/ContainerLecturasBiblioteca2do/Sesion45";
import LectS_2_46 from "../pages/Container/ContainerLecturasBiblioteca2do/Sesion46";
import LectS_2_47 from "../pages/Container/ContainerLecturasBiblioteca2do/Sesion47";
import LectS_2_48 from "../pages/Container/ContainerLecturasBiblioteca2do/Sesion48";
import LectS_2_49 from "../pages/Container/ContainerLecturasBiblioteca2do/Sesion49";
import LectS_2_50 from "../pages/Container/ContainerLecturasBiblioteca2do/Sesion50";
import LectS_2_51 from "../pages/Container/ContainerLecturasBiblioteca2do/Sesion51";
import LectS_2_52 from "../pages/Container/ContainerLecturasBiblioteca2do/Sesion52";
import LectS_2_53 from "../pages/Container/ContainerLecturasBiblioteca2do/Sesion53";
import LectS_2_54 from "../pages/Container/ContainerLecturasBiblioteca2do/Sesion54";
import LectS_2_56 from "../pages/Container/ContainerLecturasBiblioteca2do/Sesion56";
import LectS_2_57 from "../pages/Container/ContainerLecturasBiblioteca2do/Sesion57";
import LectS_2_58 from "../pages/Container/ContainerLecturasBiblioteca2do/Sesion58";

const routes = [
  <ProtectedRoute exact path="/216" component={LectS_2_3} />,
  <ProtectedRoute exact path="/217" component={LectS_2_4} />,
  <ProtectedRoute exact path="/218" component={LectS_2_5} />,
  <ProtectedRoute exact path="/219" component={LectS_2_6} />,
  <ProtectedRoute exact path="/220" component={LectS_2_7} />,
  <ProtectedRoute exact path="/221" component={LectS_2_8} />,
  <ProtectedRoute exact path="/222" component={LectS_2_9} />,
  <ProtectedRoute exact path="/223" component={LectS_2_10} />,
  <ProtectedRoute exact path="/224" component={LectS_2_11} />,
  <ProtectedRoute exact path="/225" component={LectS_2_12} />,
  <ProtectedRoute exact path="/226" component={LectS_2_13} />,
  <ProtectedRoute exact path="/227" component={LectS_2_14} />,
  <ProtectedRoute exact path="/228" component={LectS_2_15} />,
  <ProtectedRoute exact path="/229" component={LectS_2_16} />,
  <ProtectedRoute exact path="/230" component={LectS_2_17} />,
  <ProtectedRoute exact path="/231" component={LectS_2_18} />,
  <ProtectedRoute exact path="/232" component={LectS_2_19} />,
  <ProtectedRoute exact path="/235" component={LectS_2_21} />,
  <ProtectedRoute exact path="/236" component={LectS_2_22} />,
  <ProtectedRoute exact path="/237" component={LectS_2_23} />,
  <ProtectedRoute exact path="/238" component={LectS_2_24} />,
  <ProtectedRoute exact path="/239" component={LectS_2_25} />,
  <ProtectedRoute exact path="/240" component={LectS_2_26} />,
  <ProtectedRoute exact path="/241" component={LectS_2_27} />,
  <ProtectedRoute exact path="/242" component={LectS_2_28} />,
  <ProtectedRoute exact path="/243" component={LectS_2_29} />,
  <ProtectedRoute exact path="/244" component={LectS_2_30} />,
  <ProtectedRoute exact path="/245" component={LectS_2_31} />,
  <ProtectedRoute exact path="/246" component={LectS_2_32} />,
  <ProtectedRoute exact path="/247" component={LectS_2_33} />,
  <ProtectedRoute exact path="/248" component={LectS_2_34} />,
  <ProtectedRoute exact path="/249" component={LectS_2_35} />,
  <ProtectedRoute exact path="/250" component={LectS_2_36} />,
  <ProtectedRoute exact path="/251" component={LectS_2_37} />,
  <ProtectedRoute exact path="/254" component={LectS_2_39} />,
  <ProtectedRoute exact path="/255" component={LectS_2_40} />,
  <ProtectedRoute exact path="/256" component={LectS_2_41} />,
  <ProtectedRoute exact path="/257" component={LectS_2_42} />,
  <ProtectedRoute exact path="/258" component={LectS_2_43} />,
  <ProtectedRoute exact path="/259" component={LectS_2_44} />,
  <ProtectedRoute exact path="/260" component={LectS_2_45} />,
  <ProtectedRoute exact path="/261" component={LectS_2_46} />,
  <ProtectedRoute exact path="/262" component={LectS_2_47} />,
  <ProtectedRoute exact path="/263" component={LectS_2_48} />,
  <ProtectedRoute exact path="/264" component={LectS_2_49} />,
  <ProtectedRoute exact path="/265" component={LectS_2_50} />,
  <ProtectedRoute exact path="/266" component={LectS_2_51} />,
  <ProtectedRoute exact path="/267" component={LectS_2_52} />,
  <ProtectedRoute exact path="/268" component={LectS_2_53} />,
  <ProtectedRoute exact path="/269" component={LectS_2_54} />,
  <ProtectedRoute exact path="/272" component={LectS_2_56} />,
  <ProtectedRoute exact path="/273" component={LectS_2_57} />,
  <ProtectedRoute exact path="/274" component={LectS_2_58} />,
];

const Lectura2do = routes.map((route, key) => {
  return { ...route, key: key };
});

export default Lectura2do;
