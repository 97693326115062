import React               from "react";
import {Grid, makeStyles}                 from '@material-ui/core';

const useStyles = makeStyles(theme =>({
  questionContainer:{
    width: "100%",
    height:"100%",
  },
  reading: {
    width: "80%",
    margin:"10px 0 10px 0 ",
    font: '22px lato',
    letterSpacing: '0.21px',
    [theme.breakpoints.down(1400)]:{
      font: " 18px lato",
      width: "85%",
    },
    [theme.breakpoints.down(1310)]:{
      font: "500 16px lato",
      // width: "80%",
      margin:"10px 0 10px 0"
    },
    [theme.breakpoints.down(925)]:{
      font: "500 14px lato",
      // width: "70%",
      margin:"6px 0 6px 0"
    },
    [theme.breakpoints.down(426)]:{
      font: "500 12px lato",
      // width: "70%",
      margin:"6px 0 6px 5%"
    }, 
  },
  table: {
    width: "100%",
    font: '22px lato',
    [theme.breakpoints.down(1400)]:{
      font: " 18px lato",
      width: "85%",
    },
    [theme.breakpoints.down(1310)]:{
      font: "500 16px lato",
      // width: "80%",
      margin:"10px 0 10px 0"
    },
    [theme.breakpoints.down(925)]:{
      font: "500 14px lato",
      // width: "70%",
      margin:"6px 0 6px 0"
    },
    [theme.breakpoints.down(426)]:{
      font: "500 12px lato",
      // width: "70%",
      margin:"6px 0 6px 5%"
    }, 
  },
  tdTable: {
    padding: "0 1%",
    // border: '1px solid black',
  }
}));

const QuestionTable = (props) => {
  const classes = useStyles()

  return(
    <Grid container className={classes.questionContainer}>
      <p
        className={classes.reading}
      >
        1. Relaciona los conceptos con sus descripciones.
      </p>
      <br/>
      <table className={classes.table}>
        <thead>
          <th style={{width: "40%",}}><b>Conceptos</b></th>
          <th style={{width: "60%",}}><b>Descripción</b></th>
        </thead>
        <tbody>
          <tr>
            <td className={classes.tdTable}>
              <p>
                1. Desextinción
              </p><br/>
            </td>
            <td className={classes.tdTable}>
              <p>
                a. Características biológicas que los individuos de una especie transmiten a su descendencia a través de la herencia.
              </p>
            </td>
          </tr>
          <tr>
            <td className={classes.tdTable}>
              <p>
                2. Clonación
              </p><br/>
            </td>
            <td className={classes.tdTable}>
              <p>
                b. Técnicas que permiten revivir una especie extinta.
              </p>
            </td>
          </tr>
          <tr>
            <td className={classes.tdTable}>
              <p>
                3. Información genética
              </p><br/>
            </td>
            <td className={classes.tdTable}>
              <p>
                c. Proceso utilizado para crear una copia genética exacta de una secuencia de <span style={{fontVariant: "all-small-caps"}}>ADN</span>, célula u organismo.
              </p>
            </td>
          </tr>
        </tbody>
      </table>
    </Grid>
  )
}

export default QuestionTable;
