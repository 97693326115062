import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
    ContenedorLectura:{
        width:'502px',
                  [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
    },
    ContainerTitulo:{
        margin:'0 0 39px 0',
        '& .Titulo':{
            letterSpacing: '0px',
            color: '#A0278F',
            margin:'0',
            textAlign:'center',
            font:'43px fredoka one',
            lineHeight:'52px',
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            margin:'0 0 36px 0 ',
            color:'#000',
            font:'18px lato',
            lineHeight :'28px',
            letterSpacing: '0.17px',
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .SubTitulo':{
            color:'#00ADB6',
            margin:'0',
            font:'bold 22px lato',
            lineHeight :'24px',
            letterSpacing: '0.21px',
            [theme.breakpoints.down(502)]:{
                font:'bold 18px lato',
                lineHeight :'20px',
                letterSpacing: '0.16px',
            },
        },
        '& .Imagen':{
            width:'100%',
        },


    },

}));

const Líneas_y_puntos_para_multiplicar = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>

            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >
                        Líneas y puntos <br/>para multiplicar
                    </h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                            La <em>multiplicación</em> es una de las operaciones aritméticas más sencillas y útiles. Permite 
                            calcular de manera más rápida el resultado de aquellas sumas en las que una misma 
                            cantidad se repite varias veces. Por ejemplo, la suma 7 + 7 + 7 + 7 + 7 se puede 
                            expresar como la multiplicación 5 × 7, donde el símbolo × se puede leer como <em>veces </em>
                            (es decir, 5 veces 7).<br/><br/> 
                            
                            Hay que aprenderse las tablas de multiplicar para resolver multiplicaciones, y eso 
                            requiere mucho tiempo, práctica y sobre todo paciencia. ¡Tendrás que poner de tu 
                            parte para lograrlo! Es necesario repasar continuamente hasta que logres 
                            dominarlas. Pero verás que un día serás capaz de decirlas de memoria y sin dudar. <br/><br/>
                            
                            La dificultad de las multiplicaciones aumenta cuando se trata de números de dos 
                            cifras, porque también tendrás que saber cómo funciona esa multiplicación. Existe un 
                            método conocido como <em>multiplicación japonesa</em> para esos casos, el cual simplifica 
                            bastante el cálculo. ¡Y sólo requiere que dibujes líneas! Increíble, ¿cierto?<br/><br/>
                            
                            Considera la multiplicación 32 × 12. El 32 lo representarás con tres (3) y dos (2) 
                            líneas inclinadas de derecha a izquierda, que trazarás de arriba hacia abajo. Esto es 
                            debido a que está formado por un 3 y un 2.
                        </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                            <img className='Imagen' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img2do/Sesion55/1.png"  alt="Tarjeta" />
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                            El 12 lo representarás con una (1) y dos (2) líneas inclinadas de izquierda a derecha. 
                            En este caso, lo harás de abajo hacia arriba, de tal forma que se crucen con las 
                            anteriores.
                        </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                            <img className='Imagen' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img2do/Sesion55/2.png"  alt="Tarjeta" />
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                            Ahora, tienes que separar los extremos con arcos de circunferencia. Después, cuenta 
                            todos los puntos en los que se intersecaron las líneas. Los puntos de en medio se 
                            suman. Por lo tanto, 32 × 12 = 384.
                        </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                            <img className='Imagen' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img2do/Sesion55/3.png"  alt="Tarjeta" />
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                            ¿Y qué pasa si los puntos de en medio suman más de 10? La cifra de la decena se 
                            suma a los puntos de la izquierda.<br/><br/>
                            
                            Considera la multiplicación 34 × 12.
                        </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                            <img className='Imagen' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img2do/Sesion55/4.png"  alt="Tarjeta" />
                    </Grid>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                            El resultado es 408. <br/><br/>
                            
                            ¡Ahora sabes un nuevo método para multiplicar! Practícalo con tus amigos y 
                            conviértanse en maestros del método japonés.
                        </p>
                    </Grid>
                </Grid>
        </Grid>
    </Grid>
    );
}
 
export default Líneas_y_puntos_para_multiplicar;
