import { makeStyles, Grid } from "@material-ui/core";
import React, { useContext, useEffect } from "react";
import AuthContext from "../../../auth/AuthContext";
import defaultImg from "./img/default_avatar.png";
import axios from "../../../services/axios";
import LecturaButton from "../../LecturaButton/LeccturaButton";
import types from "../../../types/types";
import UsabilityContext from "../../../usability/UsabilityContext";
import ContainerDocente from "./ContainerDocente";

const useStyles = makeStyles((theme) => ({
  PerfilContainer: {
    width: "100%",
    // marginTop: "80px",
    maxHeight: "calc(100vh - 80px)",

    [theme.breakpoints.up("sm")]: {
      marginTop: "0",
    },
    [theme.breakpoints.up("lg")]: {
      // flexDirection: "column",
      // alignContent: "center",
    },
  },
  Izquierda: {
    boxSizing: "border-box",
    padding: "35px 0 0 0",
    [theme.breakpoints.down(1280)]: {
      padding: "0",
      zIndex: "2",
    },
  },
  fotoDocente: {
    marginTop: "30px",
    height: "200px",
    width: "200px",
    borderRadius: "50%",
    objectFit: "cover",
    [theme.breakpoints.up("xl")]: {
      "@media(min-height:1080px)": {
        height: "250px",
        width: "250px",

      },
    },
  },
  CuadroDocente: {
    position: "relative",
    height: "840px",
    width: "551px",
    background: "#F3CD52",
    borderRadius: "54px",
    boxSizing: "border-box",
    padding: "63px 88px 44px ",
    [theme.breakpoints.down(1280)]: {
      width: "100%",
      borderRadius: "0",
      height: "436px",
    },

    "& .NombreDocente": {
      font: "34px Fredoka One",
      color: "#000",
      textAlign: "center",
      letterSpacing: "0.25px",
      margin: "0 0 80px 0",
      [theme.breakpoints.down(1280)]: {
        position: "absolute",
        top: "294px",
      },
    },
  },
  DatosDocente: {
    height: "180px",
    width: "376px",
    background: "#fff",
    borderRadius: "15px",
    boxSizing: "border-box",
    padding: "33px 24px ",
    margin: "0 0 56px 0 ",
    [theme.breakpoints.down(1280)]: {
      position: "absolute",
      top: "410px",
      height: "216px",
      width: "451px",
      margin: "0 ",
    },
    [theme.breakpoints.down(461)]: {
      width: "350px",
    },
    "& .texto": {
      font: "18px lato",
      margin: "0 0 0 2px ",
    },
    "& .negritas": {
      font: "bold 18px lato",
    },
  },
  Derecha: {
    width: "100%",
    padding: "35px 0 ",
    minHeight: "46vh",
    background:
      "linear-gradient(32deg, rgba(185,101,211,0.2105042700674019) 1%, rgba(255,255,255,1) 20%, rgba(255,255,255,1) 36%, rgba(254,254,254,1) 77%, rgba(252,227,80,0.2777311608237045) 100%)",
    [theme.breakpoints.up("md")]: {
      padding: "20px 0",
      // minHeight: "92vh",
      "@media(min-height:2024px)": {
        alignContent: "center",
        gap: "25%",
      },
    },
  },
  Top: {
    // height: "235px",
    // width: "133px",
    border: "solid 1px rgba(46, 49, 60, 0.1)",
    background: "#fff",
    margin: "0 24px 24px 0",
    borderRadius: "14px",
    "& .Lectura": {
      font: "bold 16px lato",
      letterSpacing: ".15px",
      margin: "0",
    },
    "& .Estrella": {
      height: "72px",
    },
  },
  Position7: {
    margin: "0 24px  24px 0",
    [theme.breakpoints.down(1280)]: {
      margin: "0 24px  24px 0",
    },
  },
  Position9: {
    margin: "0 24px  24px 0",
    [theme.breakpoints.down(1280)]: {
      margin: "0 24px  24px 0",
    },
  },
  Position8: {
    margin: "0 24px  24px 0",
  },
  Position10: {
    margin: "0 24px  24px 0",
    [theme.breakpoints.down(1280)]: {
      margin: "0 24px  24px 0",
    },
  },

  ContainerIzquierda: {
    background: "#F3CD52",
    width: "100%",
    padding: "35px 0 ",
    minHeight: "46vh",
    // paddingTop:'80px',
    [theme.breakpoints.up("lg")]: {
      minHeight: "calc(100vh - 80px)",
      "@media(min-height:1080px)": {
        padding: "20px",
      },
    },
    [theme.breakpoints.up("xl")]: {
      alignContent: "center",
      gap: "10%",
      "@media(min-height:1080px)": {
        padding: "0px",
      },
    },
  },
  CaontainerInfoAlumno: {
    position: "relative",
    boxSizing: "border-box",
    padding: "20.5px 20px 20.5px 20px",
    margin: "0 0 23px 0",
    // height: "422px",
    width: "80%",
    maxWidth: "326px",
    background: " #FFFFFF 0% 0% no-repeat padding-box",
    borderRadius: "200px 200px 25px 25px",
    boxShadow: "0px 2px 5px #00000029",
    border: "3px solid #FBE6B9",
    opacity: "1",
    [theme.breakpoints.up("xl")]: {
      maxWidth: "420px",
    },
    "& .Avatar": {
      paddingTop: "30px",
      height: "200px",
      [theme.breakpoints.up("xl")]: {
        "@media(min-height:1080px)": {
          paddingTop: "40px",
          height: "320px",
        },
      },
    },
  },
  ContainerNombreAlumno: {
    bottom: "180px",
    padding: "0 10px 0 10px",
    margin: "20px 0",
    textTransform: "capitalize",

    "& .Nombre": {
      margin: "0 ",
      textAlign: "center",
      height: "auto",
      width: "390px",
      letterSpacing: ".15px",
      color: "#353535",
      font: "20px Fredoka One",
      [theme.breakpoints.up("xl")]: {
        fontSize: "28px",
      },
    },
    "& .Apellido": {
      margin: "0 5px 0 0",
      textAlign: "center",
      height: "auto",
      width: "322px",
      letterSpacing: ".15px",
      color: "#353535",
      font: "20px Fredoka One",
      [theme.breakpoints.up("xl")]: {
        fontSize: "28px",
      },
    },
  },
  ContainerRegion: {
    font: "22px lato",
    color: "#000000",
    "& .Pais": {
      margin: "0",
      letterSpacing: "0.30px",
    },
    "& .Ciudad": {
      margin: "0",
      letterSpacing: "0.30px",
    },
    "& .Escuela": {
      margin: "0",
      letterSpacing: "0.30px",
    },
    "& .Grado": {
      margin: "0",
      letterSpacing: "0.30px",
      textTransform: "capitalize",
    },
    "& .Grupo": {
      margin: "0",
      letterSpacing: "0.30px",
    },
    "& .Clase": {
      margin: "0",
      letterSpacing: "0.30px",
    },
  },

  ContainerTop: {
    height: "auto",
    alignItems: "center",
    alignContent: "flex-start",
    [theme.breakpoints.up("sm")]: {
      marginTop: "50px",
      alignContent: "center",
    },
    [theme.breakpoints.up("md")]: {
      marginTop: "0",
    },
  },
  ContainerTop10: {
    margin: "30px 0",
    alignItems: "center",
  },
  Top10: {
    // position: "relative",
    // top: "55px",
    paddingRight: "1%",
    margin: "0px",
    font: "20px Fredoka One",
    letterSpacing: ".025px",
    color: "#4CAFA3",
    [theme.breakpoints.up("sm")]: {
      fontSize: "28px",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "34px",
    },
  },
  Top102: {
    // position: "relative",
    // top: "55px",
    margin: "0px",
    font: "20px Fredoka One",
    letterSpacing: ".025px",
    color: "#8E69C1",
    [theme.breakpoints.up("sm")]: {
      top: "45px",
      fontSize: "28px",
    },
    [theme.breakpoints.up("lg")]: {
      top: "45px",
      fontSize: "34px",
    },
  },
  ContainerStars: {
    boxSizing: "border-box",
    width: "80%",
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    gridTemplateRows: "repeat(5, 1fr)",
    gap: "10px",
    [theme.breakpoints.up("sm")]: {
      gridTemplateColumns: "repeat(5, 1fr)",
      gridTemplateRows: "repeat(2, 1fr)",
      // height: "128px",
      height: "180px",
      gap: "10px",
      maxWidth: "75%",
      "@media(min-height:1024px)": {
        height: "200px",
      },
      "@media(min-height:2024px)": {
        height: "300px",
      },
    },
  },
  Favoritos: {
    position: "relative",
    boxSizing: "boreder-box",
    "& .EstrellaFavorito": {
      position: "absolute",
      bottom: "40px",
      height: "38px",
      width: "40px",
    },
    "& .LecturaFavorita": {
      overflow: "hidden",
      position: "absolute",
      bottom: "0px",
      font: "700 12px lato",
      margin: "0",
      width: "70px",
      height: "40px",
      textAlign: "center",
    },
    height: "44%",
    width: "18%",
    margin: "1% 1%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    background: "#FFFFFF",
    boxShadow: "0px 2px 5px #00000029",
    border: "2px solid #2E313C1A",
    borderRadius: "15px",
    cursor: "pointer",
    "&:hover": {
      background: "#ED7C69",
    },
  },

  popper: {
    background: "orange",
    font: "700 20px lato",
  },
}));

const PerfilDocente = () => {
  const classes = useStyles();
  const {
    user: { name, firstLastName, secondLastName, grupos, favoriteList, avatar },
    dispatch,
  } = useContext(AuthContext);
  const { groupData } = React.useContext(UsabilityContext);

  const escuela = groupData?.escuela || grupos[0]?.escuela;
  const grado = groupData?.nombre || grupos[0]?.nombre;
  const grupo = groupData?.grupo || grupos[0]?.grupo;
  const pais = groupData?.pais || grupos[0]?.pais;
  const ciudad = groupData?.ciudad || grupos[0]?.ciudad;
  const codigo_clase = groupData?.codigo_clase || grupos[0]?.codigo_clase;

  const loadFavoriteList = () => {
    // const body = { nombreUsuario: userName, contraseña: password };
    axios
      .get(`/user/lectura/favorita/get`)
      .then((response) => {
        if (response.status === 200) {
          // localStorage.setItem("token", response.data.resultado.token_usuario);
          const favorites = response.data.resultado.resp;
          dispatch({
            type: types.loadReadings,
            payload: favorites,
          });
        }
      })
      // .catch((error) => console.log(error));
      .catch((error) => {
        dispatch({
          type: types.loadReadings,
          payload: [],
        });
      });
  };
  useEffect(() => {
    if (!favoriteList || favoriteList?.lenght === 0) loadFavoriteList();
  }, []);

  return (
    <>
      <ContainerDocente>
        <Grid container className={classes.PerfilContainer}>
          <Grid
            item
            container
            justifyContent="center"
            alignItems="center"
            md={12}
            lg={6}
            className={classes.ContainerIzquierda}
          >
            <Grid container justifyContent="center">
              <Grid
                container
                item
                justifyContent="center"
                className={classes.CaontainerInfoAlumno}
              >
                <Grid
                  container
                  item
                  justifyContent="center"
                  alignItems="flex-end"
                >
                  <img
                    className={classes.fotoDocente}
                    src={avatar?.path || defaultImg}
                    alt=""
                  />
                </Grid>
                <Grid
                  container
                  item
                  justifyContent="center"
                  className={classes.ContainerNombreAlumno}
                >
                  <p className="Nombre">{name} </p>
                  <p className="Apellido">
                    <b className="Apellido">{firstLastName} </b>
                    <b className="Apellido">{secondLastName} </b>
                  </p>
                </Grid>
                {grupos?.length > 0 ? (
                  <Grid
                    container
                    item
                    direction="column"
                    justifyContent="center"
                    className={classes.ContainerRegion}
                  >
                    <p className="Grado">
                      <b>Grado:</b> {grado}
                    </p>
                    <p className="Grado">
                      <b>Grupo:</b> {grupo}
                    </p>
                    <p className="Pais">
                      <b>País:</b> {pais}
                    </p>
                    <p className="Ciudad">
                      <b>Ciudad:</b> {ciudad}
                    </p>
                    <p className="Escuela">
                      <b>Colegio:</b> {escuela}
                    </p>
                    <p className="Clase">
                      <b>ID de clase:</b> {codigo_clase}
                    </p>
                  </Grid>
                ) : (
                  <>
                    <Grid
                      container
                      item
                      direction="column"
                      justifyContent="center"
                      className={classes.ContainerRegion}
                    >
                      <p>Aun no tienes grupos asignados.</p>
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid>
          </Grid>

          <Grid
            container
            item
            md={12}
            lg={6}
            justifyContent="center"
            className={classes.Derecha}
          >
            <Grid
              item
              container
              direcion="column"
              justifyContent="center"
              sm={12}
              className={classes.ContainerTop}
            >
              <Grid
                container
                direcion="column"
                justifyContent="center"
                className={classes.ContainerTop10}
              >
                <Grid
                  container
                  item
                  sm={12}
                  alignItems="center"
                  justifyContent="center"
                >
                  <p className={classes.Top10}>Top 10</p>
                  <p className={classes.Top102}>de lecturas favoritas</p>
                </Grid>
              </Grid>

              <Grid
                container
                item
                sm={12}
                justifyContent="center"
                className={classes.ContainerStars}
              >
                {favoriteList?.map((lectura) => {
                  return (
                    <LecturaButton
                      key={lectura.id_lectura}
                      id_lectura={lectura.id_lectura}
                      nombre={lectura.nombre}
                      grade={lectura.grado}
                      sesion={lectura.sesion}
                      rol="docente"
                    />
                  );
                })}
                {favoriteList?.length === 0 && (
                  <LecturaButton nombre="Sin asignar" />
                )}
                {favoriteList?.length <= 1 && (
                  <LecturaButton nombre="Sin asignar" />
                )}
                {favoriteList?.length <= 2 && (
                  <LecturaButton nombre="Sin asignar" />
                )}
                {favoriteList?.length <= 3 && (
                  <LecturaButton nombre="Sin asignar" />
                )}
                {favoriteList?.length <= 4 && (
                  <LecturaButton nombre="Sin asignar" />
                )}
                {favoriteList?.length <= 5 && (
                  <LecturaButton nombre="Sin asignar" />
                )}
                {favoriteList?.length <= 6 && (
                  <LecturaButton nombre="Sin asignar" />
                )}
                {favoriteList?.length <= 7 && (
                  <LecturaButton nombre="Sin asignar" />
                )}
                {favoriteList?.length <= 8 && (
                  <LecturaButton nombre="Sin asignar" />
                )}
                {favoriteList?.length <= 9 && (
                  <LecturaButton nombre="Sin asignar" />
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </ContainerDocente>
    </>
  );
};

export default PerfilDocente;
