import React from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core";

import MenuIzquierdo from "./MenuDocente";
import NavBar from "./NavBarDocente";

// import SettingsTeacher from "./SettingsTeacher";

const useStyles = makeStyles((theme) => ({
  grid1: {
    zIndex: "3",

  },
  grid2: {
    zIndex: "2",
  },
  grid3: {
    boxSizing: "border-box",
    zIndex: "1",
    paddingLeft: "0px",
    [theme.breakpoints.up("lg")]: {
      paddingLeft: "3%",
    },
  },
}));

function ContainerDocente({ children }) {
  const classes = useStyles();

  return (
    <>
      <Grid container>
        <Grid item className={classes.grid1} xs={12}>
          <NavBar />
        </Grid>
        <Grid item className={classes.grid2} xs={1}>
          <MenuIzquierdo />
        </Grid>
        <Grid item className={classes.grid3} xs={12}>
          {children}
        </Grid>
      </Grid>
    </>
  );
}

export default ContainerDocente;
