import React from "react";
import { styled } from "@mui/system";
import { FormControl, InputLabel, makeStyles, MenuItem, Select, Typography } from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  select: {
    width: "100%",
    // maxWidth: "280px",
    // maxWidth: "300px",
    [theme.breakpoints.up("md")]: {
      maxWidth: "400px",
    },
  },
}));

const SelectCustimized = styled(Select)(({ theme }) => ({
  // "& .MuiSelect-icon": {
  //   // color: "#EECD67",
  // },
  // "& .MuiSelect-select.MuiSelect-select": {
  //   padding: "11.5px",
  //   paddingLeft: "27px",
  //   paddingRight: "27px",
  //   // backgroundColor: "#f0f0f0",
  //   // paddingLeft: "27px",
  //   borderRadius: "61px",
  //   fontSize: "16px",
  //   boxSizing: "border-box",
  //   "& :focus": {
  //     backgroundColor: "transparent",
  //   },
  // },
  "&.MuiMenu-list": {
    width: "500px",
  },
  "&.MuiList-root": {
    width: "500px",
  },
}));
const FormControlCustimized = styled(FormControl)(({ theme }) => ({
  // "& .MuiInputLabel-formControl": {
  //   transform: "translate(0px, 0px) scale(1)",
  //   left: "27px",
  // },
  // "& .MuiFormLabel-root.Mui-focused": {
  //   transform: "translate(0px, -0px) scale(1)",
  //   color: "#000",
  // },
  "&.MuiMenu-list": {
    width: "500px",
  },
  "&.MuiFormControl-fullWidth": {
    width: "100%",
    // [theme.breakpoints.up("md")]: {
    //   width: "80%",
    // },
  },
}));
const MenuItemCustimized = styled(MenuItem)(({ theme }) => ({
  "&.MuiMenu-list": {
    width: "500px",
  },
  "&.MuiList-root": {
    width: "500px",
  },
  "&.MuiMenuItem-root": {
    width: "100%",
    // maxWidth: "280px",
    // overflow: "auto",
    textOverflow: "ellipsis",
    [theme.breakpoints.up("md")]: {
      maxWidth: "400px",
    },
  },
  // "& .MuiFormLabel-root.Mui-focused": {
  //   transform: "translate(0px, -0px) scale(1)",
  //   color: "#000",
  // },
}));

const SelectCustomized = ({ list = [], title = "", handleForm, setActiveStep, topRef}) => {
  const classes = useStyles();

  // const handleForm = () => {
  //   window.scrollTo({ top: topRef })
  // }

  
  return (
    <FormControlCustimized fullWidth  onFocus={()=>{setActiveStep(4)}}>
      <InputLabel id="demo-simple-select-label">{title}</InputLabel>
      <SelectCustimized
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={FormData?.[title.toLowerCase()]}
        label={title}
        // onChange={handleForm}
        className={classes.select}
        name={title}
        disableUnderline
        defaultValue={""}
      >
        {list.map((item, index) =>{ 
          const clickToRef = () => {
            console.log(item,"itm")
            // window.scrollTo({ top: topRef[item.id_pregunta].current.getBoundingClientRect().top + 79})
            topRef[item.id_pregunta].current.scrollIntoView({
              // behavior: 'smooth',
              block: 'center',
            })
            console.log(topRef[item.id_pregunta].current.getBoundingClientRect().top, "topRefMnu");
          }
          
          return (
            // <a href={`#${item.id_pregunta}-anchor`} >
          <MenuItemCustimized value={item?.id_pregunta} key={item?.id_pregunta + "-menuItem"} onClick={clickToRef}>
            <Typography variant="inherit" noWrap>
            <p 
              dangerouslySetInnerHTML={{__html: `${index + 1}. ${item?.pregunta}`.replace("10.", " ")}}
            >
            </p>
            </Typography>
          </MenuItemCustimized>
          // </a>
        )})}
      </SelectCustimized>
    </FormControlCustimized>
  );
};

export default SelectCustomized;
