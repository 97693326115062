import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
    ContenedorLectura:{
        width:'502px',
                  [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
    },
    ContainerTitulo:{
        margin:'0 0 34px 0',
        '& .Titulo':{
            letterSpacing: '0px',
            color: '#E1AF10',
            margin:'0',
            textAlign:'center',
            font:'43px fredoka one',
            lineHeight:'52px',
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        position:'relative',
        margin:' 0 0 36px 0',
        '& .Lectura':{
            margin:'0 0 36px 0',
            color:'#000',
            font:'18px lato',
            lineHeight :'28px',
            letterSpacing: '0.17px',
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .Imagen':{
            width:'100%',
        }, 
    }
}));

const La_quena_de_oro_Adaptación_de_una_leyenda_peruana = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >
                        La quena de oro. <br/>Adaptación de una <br/>leyenda peruana                    
                    </h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                      <p className="Lectura">
                        Era un día soleado. Todo el pueblo estaba reunido junto a la laguna de Lauricocha
                        para celebrar una fecha importante. Se trataba de la boda de la hija del jefe. Era una 
                        celebración lujosa en la que había muchas cosas de comer: trucha salada, quinua y 
                        carne de pato. También había abundante chicha de cora para beber y los invitados 
                        llevaban los vestuarios más elegantes.<br/><br/> 
                        
                        Todos disfrutaban de la fiesta sin reparar en lo que ocurría a su alrededor, cuando se 
                        acercó un anciano con apariencia de mendigo al lugar. El jefe lo miró, hizo un gesto de 
                        desaprobación y se acercó de inmediato para pedirle que se fuera porque él no era 
                        parte de la fiesta. El anciano respondió que solamente quería algo de comer porque 
                        tenía mucha hambre y pidió a todos que mostraran compasión por él, pues no era 
                        más que un hombre pobre que pendía de la caridad de las personas. Pero el jefe lo 
                        ignoró y nuevamente le solicitó que se retirara, esta vez con más insistencia. <br/><br/>
                        
                        El hombre estaba a punto irse cuando uno de los invitados corrió tras él, conmovido 
                        por su situación. Se trataba de un pastor pobre. Le ofreció su casa para que comiera y 
                        reposara con tranquilidad y confianza. Afirmó que ahí tenía agua y papas de su 
                        cosecha que podía compartirle. El mendigo aceptó agradecido el gesto y se dirigió al 
                        hogar del pastor. Una vez ahí, le ofreció permanecer el tiempo que deseara. <br/><br/>
                        
                        Cuando despertó a la mañana siguiente, vio que el anciano emitía un brillo tan fuerte 
                        que no podía verlo directamente. Entonces, éste confesó:<br/><br/>
                        
                        —Soy tu padre, el Sol. Me disfracé de esa manera para conocer el verdadero corazón 
                        de mis hijos. Tú fuiste bueno conmigo sin esperar nada a cambio. Eres una buena 
                        persona y por eso te regalo esta quena de oro. Cada vez que quieras algo, sopla en 
                        ella y tus deseos se cumplirán.
                      </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img4to/Sesion57/1.png" alt="Tarjeta" />
                    </Grid>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura ">
                            El pastor estaba a punto de agradecer el regalo cuando el Sol desapareció. Decidió 
                            probar su quena y la sopló, deseando tener más ovejas y carneros. Sus nuevos 
                            animales aparecieron como por arte de magia frente a él. Sopló una vez más, pero 
                            esta vez pidió más pasto para alimentar a su ganado. De repente, el campo se 
                            convirtió en un pastizal. <br/><br/>
                            
                            Nadie entendía por qué de un momento a otro tenía tantos animales si él era tan 
                            pobre. El jefe lo visitó para saciar su curiosidad. Cuando estaba por llegar, escuchó al 
                            pastor decir: <br/><br/>
                            
                            —Papá Inti, señor Sol, ahora necesito agua para mis animales.<br/><br/>
                            
                            Y el jefe vio surgir una gran laguna en medio del pastizal. <br/><br/>
                            
                            Se acercó a él asombrado por la magia y lo convenció de que le diera la quena. Dijo 
                            que él la utilizaría para dar riquezas a todos los habitantes del pueblo.<br/><br/>
                            
                            El pastor aceptó porque era generoso y ya tenía todo lo que anhelaba. El jefe sopló la 
                            quena para ordenar palacios y riquezas en cuanto la tuvo en sus manos. Pero nada 
                            apareció, por más que lo pidió hasta el cansancio. Lanzó la quena con enojo y se 
                            marchó.<br/><br/>
                            
                            Entonces, el pastor decidió esconderla para regresarla al Sol. Algún día, éste la 
                            obsequiará a alguien más que demuestre tener buen corazón.
                        </p>
                    </Grid>
                </Grid>
        </Grid>
    </Grid>
    );
}
 
export default La_quena_de_oro_Adaptación_de_una_leyenda_peruana ;
