import React from "react";
import { makeStyles,Grid}   from '@material-ui/core';
import NavBar               from '../../../../NavBar';
import MenuIzquierdo        from '../../../../MenuIzquierdo';
import ActivityEvaluation   from '../../ActivityEvaluation';
import QuestionText         from './QuestionText'
import QuestionProv         from "../../QuestionProv";
import Question             from "../../Question";
import useGetExcercisesData from "../../../../../hooks/useGetExcercisesData";

const useStyles = makeStyles(theme=>({
  grid1:{    
    zIndex:'3'
    
  },
  grid2:{    
    zIndex:'2'
    
  },
  grid3:{    
    boxSizing:'border-box',
    zIndex:'1',
    paddingLeft:'3%',
    [theme.breakpoints.down('md')]:{
      paddingLeft:'0px',
    }
    
  }
}))

const ActivityS02 = (props) => {
  const classes = useStyles()
  const activityId = 254;
  const { data, step, setStep, questionList, setQuestionList } =
    useGetExcercisesData(activityId);
  const baseNumber = () => {
    if(step > 14){
      return(
        3
      )
    } else if(step > 9){
      return(
        2
      )
    } else if(step > 4){
      return(
        1
      )
    } else {
      return(
        0
      )
    }
  }

  const renderQuestion = () => {
    return(
      step === 19 ?
      <QuestionProv
        pregunta={ questionList.length > 0 ? questionList[step].pregunta : "cargando..."}
        step={step}
        baseNumber={baseNumber()}
        stepsForText={5}
      />
    :
      <Question
        step={step}
        pregunta={ questionList.length > 0 ? questionList[step].pregunta : "cargando..."}
        baseNumber={baseNumber()}
        stepsForText={5}
      />
    )
  }

  const renderText = () => {
    return(
      <QuestionText
        questionText={questionList.length > 0 ? questionList[step].texto : "cargando..."}
      />
    )
  }

  return(
    <Grid container >
      <Grid item className={classes.grid1} sm={12}> 
        <NavBar/>
      </Grid>
      <Grid item className={classes.grid2} sm={1}> 
        <MenuIzquierdo/>
      </Grid>
      <Grid item className={classes.grid3} sm={12}>
        <ActivityEvaluation
          step={step}
          setStep={setStep}
          questionList={questionList}
          setQuestionList={setQuestionList}
          questionComponent={renderQuestion()}
          textComponent={renderText()}
          baseNumber={baseNumber()}
          activityNumber={activityId}
          enrollmendId={data && data.id_actividad_inscripcion}
          tryNumber={data && data.intento}
          evaluationType={"diagnostica"}
        />
      </Grid>
    </Grid>
  )
}

export default ActivityS02;
