import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({

    ContenedorLectura:{
        width:'100%',
          [theme.breakpoints.down(769)]:{
            width:'350px',
        },
        '& .Img':{
            width:'100%',
        },
    },
}));

const Prospecto = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <img className='Img' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion43/F.png" alt="Tarjeta" />
            </Grid>
    </Grid>
    );
}
 
export default Prospecto;
