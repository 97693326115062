import { Button, Grid, makeStyles } from "@material-ui/core";
import React from "react";
import { useOnLoadImages } from "../../../../hooks/useOnLoadImages";
import Loader from "../../../../Loader/Loader";

const useStyles = makeStyles((theme) => ({
  Contenedor: {
    background: "#E9FBF9",
    width: "100vw",
    [theme.breakpoints.down(1161)]: {
      background: "none",
    },
    [theme.breakpoints.down(600)]: {
      marginTop: "79.51px",
    },
  },
  ContainerInfoLectura: {
    position: "relative",
    height: "86px",
    [theme.breakpoints.down(502)]: {
      height: "43px",
    },
    background: "#76D4C3",
    "& .BarraInfoLectura": {
      height: "86px",
      background: `Url(https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Barras/Azul.png)`,
      [theme.breakpoints.down(502)]: {
        height: "43px",
      },
    },
    "& .InfoLectura": {
      position: "absolute",
      height: "86px",
      width: "219px",
      boxSizing: "border-box",
      padding: "17.2px 0",
      background: "#FAFAFA",
      borderRadius: "50px",
      boxShadow: "2px 2px 2px 2px #00000029",
      zIndex: "4",
      [theme.breakpoints.down(502)]: {
        height: "43px",
        width: "109px",
        padding: "8.6px 0",
        borderRadius: "25px",
      },
    },
    "& .InfoLectura:before": {
      position: "absolute",
      display: "flex",
      boxSizing: "border-box",
      padding: "0 0 0 10px",
      justifyContent: "center",
      alignItems: "center",
      height: "39.88px",
      width: "39.88px",
      borderRadius: "50%",
      content: '""',
      boxShadow: "3px 0px .5px 0px #00000029",
      background: "#FAFAFA",
      left: "90px",
      top: "65px",
      transform: "rotate(0.25turn)",
      [theme.breakpoints.down(502)]: {
        left: "45px",
        top: "30px",
        height: "20.88px",
        width: "20.88px",
      },
    },
    "& .InfoLectura:after": {
      content: '""',
      height: "15px",
      width: "15px",
      borderTop: "2px solid #20A698",
      borderLeft: "2px solid #20A698",
      position: "absolute",
      bottom: "-10px",
      left: "Calc(50% - 8px)",
      transform: "rotate(0.625turn)",
      [theme.breakpoints.down(502)]: {
        height: "10px",
        width: "10px",
        left: "Calc(50% - 4.5px)",
        bottom: "-3px",
        borderTop: "1px solid #20A698",
        borderLeft: "1px solid #20A698",
      },
    },
    "& .Sesion": {
      borderLeft: "2px solid #D8D8D8",
    },
    "& .Dato": {
      margin: "0 0 5px 0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#20A698",
      [theme.breakpoints.down(502)]: {
        font: "bold 12px lato",
        margin: "0 0 2.5px 0",
      },
    },
    "& .Caracter": {
      margin: "0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#000000",
      [theme.breakpoints.down(502)]: {
        font: "bold 12px lato",
      },
    },
    "& .Regresar": {
      color: "#F2624F",
      border: "#F2624F 1px solid",
      height: "30px",
      width: "100px",
      borderRadius: "20px",
      letterSpacing: "0.14px",
      textTransform: "none",
      background: "#FEFAF1",
      top: "calc(50% - 15px)",
      left: "5%",
      zIndex: 4,
      [theme.breakpoints.down(502)]: {
        font: "bold 10px lato",
        width: "60px",
      },
    },
  },

  ContenedorLectura: {
    background: "#fff",
    width: "1160px",
    height: "100%",

    transform: (props) =>
      props.scale > 1 ? `scale(${props.scale}) translate(0px, 10%)` : `none`,
    padding: "25px 22px 0",
    [theme.breakpoints.down(1161)]: {
      width: "660px",
      padding: "0",
    },
    [theme.breakpoints.down(660)]: {
      width: "350px",
      padding: "0",
    },
    "& .Escritorio": {
      display: "block",
      [theme.breakpoints.down(1161)]: {
        display: "none",
      },
    },
    "& .Tablet": {
      display: "none",
      [theme.breakpoints.down(1161)]: {
        display: "block",
      },
    },
  },

  Parrafo1: {
    position: "relative",
    margin: "0",
    [theme.breakpoints.down(350)]: {
      display: "none",
    },
    "& .Pos": {
      position: "absolute",
    },
    "& .Lectura": {
      font: "bold 19px lato",
      color: "#05435C",
      letterSpacing: "0.20px",
      lineHeight: "21px",
      margin: "0 0 14px 0",
      padding: "0 200px 0 0",
      zIndex: 6,
      [theme.breakpoints.down(1160)]: {
        padding: "0",
      },
      [theme.breakpoints.down(660)]: {
        font: "bold 10px lato",
        lineHeight: "16px",
        letterSpacing: "0.09px",
        margin: "0 0 7px 0",
      },
    },
    "& .Lectura2": {
      font: "28px Fredoka One",
      color: "#0078A7",
      lineHeight: "46px",
      margin: "0",
      [theme.breakpoints.down(660)]: {
        font: "14px Fredoka One",
        lineHeight: "25px",
        letterSpacing: "0.09px",
      },
    },
    "& .Lectura3": {
      color: "#05435C",
    },
    "& .SubTitulo": {
      font: " 36px Fredoka One",
      color: "#0078A7",
      letterSpacing: ".28px",
      lineHeight: "52px",
      margin: "0",
      top: "318px",
      left: "526px",
      width: "542px",
      borderBottom: "solid 5px #FFC538",
      [theme.breakpoints.down(1161)]: {
        font: " 25px Fredoka One",
        lineHeight: "28px",
        letterSpacing: "0.24px",
        top: "302px",
        left: "312px",
        width: "301px",
      },
      [theme.breakpoints.down(660)]: {
        font: " 12px Fredoka One",
        lineHeight: "18px",
        letterSpacing: "0.21px",
        top: "118px",
        left: "162px",
        width: "145px",
        borderBottom: "solid 3px #FFC538",
      },
    },

    "& .Cuadro": {
      top: "447px",
      left: "514px",
      width: "553px",
      height: "270px",
      boxSizing: "border-box",
      padding: "30px 83px 30px 30px",
      background: "rgba(255, 255, 255, 0.65)",
      borderRadius: "50px",
      backdropFilter: "blur(30px)",
      [theme.breakpoints.down(1161)]: {
        top: "439px",
        left: "95px",
        width: "495px",
        height: "295px",
        padding: "21px 20px 16px 35px",
        background: "none",
      },
      [theme.breakpoints.down(660)]: {
        top: "200px",
        left: "70px",
        width: "223px",
        height: "130px",
        padding: "0",
      },
    },

    "& .Imagen": {
      width: "100%",
      [theme.breakpoints.down(660)]: {
        width: "85.93%",
      },
    },
    "& .Derechos": {
      position: "absolute",
      bottom: "64px",
      textAlign: "center",
      margin: "0px",
      font: " 15px lato",
      color: "#05435C",
      [theme.breakpoints.down(1161)]: {
        display: "none",
      },
    },
  },

  dialog: {
    margin: "0 auto",
    backgroundColor: "#3f43b3",
    fontFamily: "Lato",
    letterSpacing: "0.36px",
    fontSize: "20px",
    color: "#ffffff",
    position: "fixed",
    top: "25px",
    width: "auto",
    minWidth: "360px",
    maxWidth: "420px",
    padding: "24px",
    boxSizing: "border-box",
    borderRadius: "21.25px",
    zIndex: "1500",
    "&:before": {
      // display: (props) => (props.openMenuInDesktop ? "none" : "flex"),
      display: "flex",
      zIndex: 1,
      textTransform: "none",
      justifyContent: "center",
      alignItems: "center",
      // borderRadius: "25px",
      position: "absolute",
      content: '""',
      border: "15px solid",
      borderTopColor: "#3f43b3",
      borderLeftColor: "transparent",
      borderBottomColor: "transparent",
      borderRightColor: "transparent",
      font: "bold 14px lato",
      right: "calc(50% - 15px)",
      bottom: "-28px",
    },
  },
  Parrafo1WithStep: {
    position: "relative",
    margin: "0",
    maxHeight: "calc(100vh - 80px - 86px - 25px - 90px)",
    //Viwport Height - Header - ActivityBar - Padding - ToolBar
    overflow: "hidden",
    "&:before": {
      display: (props) => (!props.imagesLoaded ? "none" : "flex"),
      zIndex: 1300,
      textTransform: "none",
      justifyContent: "center",
      alignItems: "center",
      // borderRadius: "25px",
      position: "absolute",
      content: '""',
      font: "bold 14px lato",
      width: "calc(100% - 20px)",
      height: "calc(100% - 10px)",
      top: "0",
      left: "0",
      boxShadow: "0px 0px 10px 15px #fff",
      // backgroundColor: "#fff",
    },
    "&:after": {
      // display: (props) => (props.openMenuInDesktop ? "none" : "flex"),
      display: "flex",
      zIndex: 1300,
      textTransform: "none",
      justifyContent: "center",
      alignItems: "center",
      // borderRadius: "25px",
      position: "fixed",
      content: '""',
      font: "bold 14px lato",
      width: "100vw",
      height: "100vh",
      top: "0",
      left: "0",
      boxShadow: "inset 1vw 3vw 0vw 10vw rgb(0 0 0 / 50%)",
      // backgroundColor: "#fff",
    },
    [theme.breakpoints.down(350)]: {
      display: "none",
    },
    "& .Pos": {
      position: "absolute",
    },
    "& .Lectura": {
      font: "bold 19px lato",
      color: "#05435C",
      letterSpacing: "0.20px",
      lineHeight: "21px",
      margin: "0 0 14px 0",
      padding: "0 200px 0 0",
      zIndex: 6,
      [theme.breakpoints.down(1160)]: {
        padding: "0",
      },
      [theme.breakpoints.down(660)]: {
        font: "bold 10px lato",
        lineHeight: "16px",
        letterSpacing: "0.09px",
        margin: "0 0 7px 0",
      },
    },
    "& .Lectura2": {
      font: "28px Fredoka One",
      color: "#0078A7",
      lineHeight: "46px",
      margin: "0",
      [theme.breakpoints.down(660)]: {
        font: "14px Fredoka One",
        lineHeight: "25px",
        letterSpacing: "0.09px",
      },
    },
    "& .Lectura3": {
      color: "#05435C",
    },
    "& .SubTitulo": {
      font: " 36px Fredoka One",
      color: "#0078A7",
      letterSpacing: ".28px",
      lineHeight: "52px",
      margin: "0",
      top: "318px",
      left: "526px",
      width: "542px",
      borderBottom: "solid 5px #FFC538",
      [theme.breakpoints.down(1161)]: {
        font: " 25px Fredoka One",
        lineHeight: "28px",
        letterSpacing: "0.24px",
        top: "302px",
        left: "312px",
        width: "301px",
      },
      [theme.breakpoints.down(660)]: {
        font: " 12px Fredoka One",
        lineHeight: "18px",
        letterSpacing: "0.21px",
        top: "118px",
        left: "162px",
        width: "145px",
        borderBottom: "solid 3px #FFC538",
      },
    },

    "& .Cuadro": {
      top: "447px",
      left: "514px",
      width: "553px",
      height: "270px",
      boxSizing: "border-box",
      padding: "30px 83px 30px 30px",
      background: "rgba(255, 255, 255, 0.65)",
      borderRadius: "50px",
      backdropFilter: "blur(30px)",
      [theme.breakpoints.down(1161)]: {
        top: "439px",
        left: "95px",
        width: "495px",
        height: "295px",
        padding: "21px 20px 16px 35px",
        background: "none",
      },
      [theme.breakpoints.down(660)]: {
        top: "200px",
        left: "70px",
        width: "223px",
        height: "130px",
        padding: "0",
      },
    },

    "& .Imagen": {
      width: "100%",
      [theme.breakpoints.down(660)]: {
        width: "85.93%",
      },
    },
    "& .Derechos": {
      position: "absolute",
      bottom: "64px",
      textAlign: "center",
      margin: "0px",
      font: " 15px lato",
      color: "#05435C",
      [theme.breakpoints.down(1161)]: {
        display: "none",
      },
    },
  },
  Imagen2: {
    display: "none",
    [theme.breakpoints.down(350)]: {
      width: "100%",
      display: "flex",
    },
  },
}));

const Credencia_escolar = (props) => {
  const { activeStep, setActiveStep, handlePreviewStep, step, handleStep } =
    props;

  const handleContinue = () => {
    setActiveStep(activeStep - 1);
    handlePreviewStep();
  };
  const wrapperRef = React.useRef(null);
  const imagesLoaded = useOnLoadImages(wrapperRef);

  const activeStepClass = step?.reading.one === false;
  const classes = useStyles({ imagesLoaded });

  return (
    <Grid
      container
      item
      justifyContent="center"
      xs={12}
      className={classes.Contenedor}
    >
      <Grid
        container
        item
        justifyContent="center"
        className={classes.ContainerInfoLectura}
      >
        <Grid container className="BarraInfoLectura">
          <Button className="Regresar" onClick={handleContinue}>
            Regresar
          </Button>
        </Grid>
        <Grid container item className="InfoLectura">
          <Grid
            container
            item
            direction="column"
            xs={6}
            justifyContent="center"
          >
            <p className="Dato">Grado</p>
            <p className="Caracter">1</p>
          </Grid>
          <Grid
            container
            item
            direction="column"
            xs={6}
            justifyContent="center"
            className="Sesion"
          >
            <p className="Dato">Sesión</p>
            <p className="Caracter">3</p>
          </Grid>
        </Grid>
      </Grid>
      {!imagesLoaded && <Loader />}
      <Grid
        container
        item
        className={classes.ContenedorLectura}
        justifyContent="center"
        ref={wrapperRef}
      >
        <Grid
          container
          item
          sm={12}
          className={
            activeStepClass ? classes.Parrafo1WithStep : classes.Parrafo1
          }
          justifyContent="center"
          onClick={() => {
            handleStep({
              ...step,
              reading: {
                ...step?.reading,
                one: true,
              },
            });
          }}
        >
          {activeStepClass && imagesLoaded && (
            <p className={classes.dialog}>
              Ésta es la lectura de la sesión, puedes leerla cuantas veces
              quieras, te servirá para poder responder los reactivos siguientes.
            </p>
          )}

          <img
            className="Imagen Escritorio"
            src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img1ro/Sesion3/1.png"
            alt="Tarjeta"
          />
          <img
            className="Imagen Tablet"
            src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img1ro/Sesion3/1R.png"
            alt="Tarjeta"
          />
          <h3 className="Pos SubTitulo">
            Colegio Héroes de Lima
            <p className="Lectura">
              Jirón Moquegua #63, Cercado de Lima, C. P. 15002, Lima, Perú.
            </p>
          </h3>
          <Grid container item alignContent="center" className="Pos Cuadro">
            <p className="Lectura2">
              Nombre: <span className="Lectura3">Nicolás Ugarte Ochoa</span>
              <br />
              Teléfono: <span className="Lectura3">462-800-02</span>
              <br />
              Tutor: <span className="Lectura3">Georgina Ochoa </span>
              <br />
              Registro escolar o DNI: <span className="Lectura3">337812CL</span>
              <br />
              Grado/Grupo o Sección: <span className="Lectura3">1º B</span>
              <br />
            </p>
          </Grid>
        </Grid>
        <img
          className={classes.Imagen2}
          src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img1ro/Sesion3/F1.png"
          alt="Tarjeta"
        />
      </Grid>
      <Grid container item style={{ marginTop: 90 }}></Grid>
    </Grid>
  );
};

export default Credencia_escolar;
