import React, { useState, useEffect } from "react";
import { makeStyles, Grid } from "@material-ui/core";
import NavBar from "../../../../NavBar";

import Emotions from "./Emotions";
import Introduction from "./Introduction";
import Instructions from "./Instructions";
import ReadingStrategys from "./ReadingStrategys";
import Lectura from "./Lectura";
import ReadingTools from "./ReadingTools";
import axios from "../../../../../services/axios";
import MenuIzquierdo from "../../MenuIzquierdo";

const useStyles = makeStyles((theme) => ({
  grid1: {
    // zIndex: "3",
  },
  grid2: {
    // zIndex: "2",
  },
  grid3: {
    boxSizing: "border-box",
    // zIndex: "1",
    paddingLeft: "3%",
    [theme.breakpoints.down("md")]: {
      paddingLeft: "0px",
    },
    marginTop: "80px",
    [theme.breakpoints.up("sm")]: {
      marginTop: "0px",
    },
    width:'100%',
  },
  ToolBar: {
    position: "relative",
    top: "150px",
  },

  restartStyles: {
    display: "flex",
    height: "100%",
    width: "100%",
    position: "inherit",
    // overflow: "auto",

    // "& div": {
    //   tansform: "unset",
    // },
  },

  restartStylesContent: {
    transform: "none!important",
    // transform: (props) => (props.disabledZoom ? "none!important" : "none"),
  },
}));

const ContainerSession3Tutorial = (props) => {
  const classes = useStyles({});
  const [activeStep, setActiveStep] = useState(0);
  const [select, setSelect] = useState();
  const id_actividad = 3;

  const [step, setStep] = useState({
    emotion: {
      one: false,
      two: false,
    },
    introduction: {
      one: false,
      two: false,
      three: false,
      four: false,
    },
    emotionEnd: {
      one: false,
    },
    generalInstructions: {
      one: false,
    },
    readingStrategys: {
      one: false,
    },
    reading: {
      one: false,
      two: false,
      three: false,
    },
  });

  const handleStep = (newStep) => {
    setStep(newStep);
  };

  const handleNextStep = () => {
    setActiveStep(activeStep + 1);
  };

  const handlePreviewStep = () => {
    const body = {
      id_actividad: id_actividad,
    };
    axios
      .put(`/user/down/stepper/actividad`, body)
      .then((response) => {
        if (response.status === 200) {
          console.log(response, "down");
          // setActiveStep(response.resultado.step)
        }
      })
      .catch((error) => {
        console.log(error, "ERRdwn!");
      });
  };

  useEffect(() => {
    const element = document.getElementById("transform");
    if (element) element.removeProperty("transform");
  }, []);

  return (
    <Grid container>
      <Grid item className={classes.grid1} sm={12}>
        <NavBar />
      </Grid>
      <Grid item className={classes.grid2} sm={1}>
        <MenuIzquierdo />
      </Grid>
      <Grid item className={classes.grid3} sm={12}>
        {activeStep === 0 && (
          <Emotions
            activeStep={activeStep}
            handleNextStep={handleNextStep}
            select={select}
            setSelect={setSelect}
            step={step}
            handleStep={handleStep}
          />
        )}
        {activeStep === 1 && (
          <Introduction
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            handleNextStep={handleNextStep}
            step={step}
            handleStep={handleStep}
          />
        )}
        {activeStep === 2 && (
          <Emotions
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            handleNextStep={handleNextStep}
            select={select}
            setSelect={setSelect}
            step={step}
            handleStep={handleStep}
          />
        )}
        {activeStep === 3 && (
          <Instructions
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            handleNextStep={handleNextStep}
            step={step}
            handleStep={handleStep}
          />
        )}
        {activeStep === 4 && (
          <ReadingStrategys
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            handleNextStep={handleNextStep}
            step={step}
            handleStep={handleStep}
          />
        )}
        {activeStep === 5 && (
          <>
            <Lectura
              activeStep={activeStep}
              setActiveStep={setActiveStep}
              handlePreviewStep={handlePreviewStep}
              id_actividad={id_actividad}
              step={step}
              handleStep={handleStep}
            />
            <ReadingTools
              toActivity={"actividad03"}
              step={step}
              handleStep={handleStep}
            />
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default ContainerSession3Tutorial;
