import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
    ContenedorLectura:{
        width:'502px',
                  [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
    },
    ContainerTitulo:{
        margin:'0 0 34px 0',
        '& .Titulo':{
            letterSpacing: '0px',
            color: '#2763A0',
            margin:'0',
            textAlign:'center',
            font:'43px fredoka one',
            lineHeight:'52px',
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            margin:'0 0 36px 0',
            color:'#000',
            font:'18px lato',
            lineHeight :'28px',
            letterSpacing: '0.17px',
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .Cuadro':{
            position:'relative',
        },
        '& .Lectura2':{
            margin:'53px 0 0 0',
            width:'85.25%',
            [theme.breakpoints.down(502)]:{
                margin:'15px 0 0 0',
            },
        },

        '& .SubTitulo':{
            color:'#FF8900',
            margin:'0',
            lineHeight :'28px',
            font:'bold 26px lato',
            letterSpacing: '0.24px',
            [theme.breakpoints.down(502)]:{
                font:'bold 18px lato',
                lineHeight :'24px',
                letterSpacing: '0.21px',
            },
        },
        '& .TextImg':{
            color:'#717476',
            margin:'24px 0 0 0',
            font:' 16px lato',
            lineHeight :'25px',
            letterSpacing: '0.15px',
            [theme.breakpoints.down(502)]:{
                font:' 11px lato',
                lineHeight :'14px',
                letterSpacing: '0.10px',
            },
        },
        '& .ul':{
            color:'#FC5E9E',
            padding:'0 0 0 19px',
            margin:'0 0 36px 0'
        },
        '& .li':{
            margin:'0 0 8px 0'
        },
        '& .Lectura3':{
            margin:'0 '
        },
        '& .Imagen':{
            width:'100%',
        },  
      
    }
}));

const En_busca_de_un_arte_nuevo = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>

            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                        <h2 className="Titulo">En busca de un arte nuevo</h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                       <p className='Lectura'>
                        <h3 className='SubTitulo'>Vanguardias</h3><br/>

                        A principios del siglo <span style={{fontVariant: "all-small-caps"}}>XX</span> inició en Europa un periodo de la historia del arte conocido 

                        como <em>vanguardismo</em>. Las <em>vanguardias</em> fueron movimientos de artistas jóvenes que 
                        buscaban cambiar radicalmente las maneras de pintar, esculpir y escribir.
                       </p>                   
                    </Grid>
                    <Grid container justifyContent='center' className='Cuadro'>
                        <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion46/6R.png" alt="Tarjeta" />
                        <p className='Lectura Lectura2'>
                            En un ejército, la <em>vanguardia</em> es el grupo colocado al frente; por lo tanto, es el primero que se enfrenta al enemigo. Las vanguardias artísticas buscaban iniciar una guerra contra el arte tradicional.

                        </p>

                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container >
                        <p className='Lectura'>
                            En las artes plásticas se destacaron tres movimientos: <em>impresionismo, expresionismo</em> y 
                            <em> cubismo</em>. Los tres rechazaban las reglas establecidas de armonía y perspectiva. 
                            Durante la segunda mitad del siglo <span style={{fontVariant: "all-small-caps"}}>XIX</span> lo común en el arte eran las <em>representaciones
                            realistas, </em> es decir, las obras buscaban copiar con exactitud y fidelidad a sus modelos. 
                            Todos los artistas de vanguardia tenían claro que no querían seguir ese camino, pero 
                            cada uno tenía sus razones y sus preferencias.
                        </p>
                    </Grid>
                    <Grid container justifyContent='center'>
                        <Grid container justifyContent='center'>
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion46/1.png" alt="Tarjeta" />
                        </Grid>
                        <Grid container >
                            <p className="TextImg">
                                Los <em>impresionistas</em>, como Monet, Renoir y Degas, apostaban por representaciones difusas y 
                                luminosas que transmitieran sensaciones de forma vívida.
                            </p>
                        </Grid>
                    </Grid>

                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container justifyContent='center'>
                        <Grid container justifyContent='center'>
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion46/2.png" alt="Tarjeta" />
                        </Grid>
                        <Grid container >
                            <p className="TextImg">
                                Los <em>expresionistas</em>, como Munch, Kirchner, Grosz y Shiele, buscaban personajes o situaciones 
                                que transmitieran emociones fuertes. En sus cuadros parece que lo representado es tan intenso que 
                                modifica los colores y las formas convencionales.
                            </p>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container justifyContent='center'>
                        <Grid container justifyContent='center'>
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion46/3.png" alt="Tarjeta" />
                        </Grid>
                        <Grid container >
                            <p className="TextImg">
                                Los <em>cubistas</em>, como Picasso, Duchamp y Metzinger, buscaban nuevas formas para representar el movimiento. 
                                Plasmaban, simultáneamente, distintas facetas de un objeto.
                            </p>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                       <p className='Lectura'>
                        <h3 className='SubTitulo'>Arte abstracto</h3><br/>

                         Junto a las vanguardias aparecieron las primeras obras de <em>arte abstracto</em>, una 
                         tendencia que dejó los personajes, paisajes, ambientes y sucesos definidos, para 
                         componer cuadros con formas y trazos de colores. El impresionismo, el 
                         expresionismo y el cubismo no representaban las situaciones de forma realista. En 
                         cambio el arte abstracto ya no buscaba <em>representar</em> cosas, sino <em>estudiar</em> la armonía entre los colores, las formas, las líneas y los puntos.
                       </p>                   
                    </Grid>
                    <Grid container justifyContent='center' className='Cuadro'>
                        <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion46/7R.png" alt="Tarjeta" />
                        <p className='Lectura Lectura2'>
                            <em>Figurar</em> es sinónimo de <em>representar</em>. Por eso el arte tradicional y las 
                            vanguardias que aún representan cosas se llaman <em>arte figurativo</em>, mientras que el 
                            arte abstracto se reconoce como <em>no figurativo</em> .
                        </p>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                       <p className='Lectura'>
                        En esta época de experimentación los artistas ensayaban sus propias teorías, pero 
                        también se enriquecían con los descubrimientos y las propuestas de otros. Muchos 
                        pintores abstractos produjeron cuadros figurativos, en los cuales se acercaban a 
                        movimientos como el impresionismo.<br/><br/> 
                        
                        Vasili Kandinski y Kazimir Malevich, en Rusia, y Piet Mondrian, en Holanda, fueron 
                        los primeros pintores en realizar cuadros con figuras geométricas, colores primarios 
                        y fondos lisos, generalmente sin perspectiva.
                       </p>                   
                    </Grid>
                    <Grid container justifyContent='center' className='Cuadro'>
                        <img className="Imagen " src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion46/4.png" alt="Tarjeta" />
                    </Grid>
                </Grid>

                
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                       <p className='Lectura'>
                        Después de ellos, una larga tradición de pintores abstractos pobló el siglo <span style={{fontVariant: "all-small-caps"}}>XX</span>. Por 
                        ejemplo, el estadounidense Jackson Pollock se volvió muy popular gracias al uso de 
                        una técnica llamada <em>action painting</em>, que consiste en derramar pintura sobre el lienzo 
                        para ir creando trazos.
                       </p>                   
                    </Grid>
                    <Grid container justifyContent='center' className='Cuadro'>
                        <img className="Imagen " src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion46/5.png" alt="Tarjeta" />
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                       <p className='Lectura'>
                        La abstracción en la historia del arte ha estado presente desde hace más de 100 años 
                        y continúa siendo bastante popular.<br/><br/>
                        
                        <h3 className='SubTitulo'>El arte y tú: ¿cómo analizar un cuadro abstracto?</h3><br/>
                        
                        Crear arte abstracto exige tanta técnica, esfuerzo mental, disciplina y originalidad 
                        como crear una obra figurativa. Sin embargo, existen muchos prejuicios en su contra. 
                        Algunas personas piensan que sólo son rayones o formas sin sentido. <br/><br/>
                        
                        Recuerda: cuando entres en contacto con cualquier forma de arte debes darte la 
                        oportunidad de analizarla con atención. Cuando uno se encuentra frente a una obra 
                        abstracta puede empezar preguntándose:
                       </p>    

                        <ul className='ul'>
                            <li className='li'>
                                <p className='Lectura Lectura3'>
                                ¿Qué elementos utiliza? ¿Colores, líneas, texturas…?
                                </p>
                            </li>
                            <li className='li'>
                                <p className='Lectura Lectura3'>
                                ¿Cómo interactúan?
                                </p>
                            </li>
                            <li className='li'>
                                <p className='Lectura Lectura3'>
                                ¿Qué me transmite cada uno de ellos?
                                </p>
                            </li>
                            <li className='li'>
                                <p className='Lectura Lectura3'>
                                ¿Qué me transmite la obra entera?
                                </p>
                            </li>
                        </ul>   
                        <p className='Lectura'>
                            En ocasiones no basta con observar la pintura para encontrarle significado: también 
                            hay que conocer a fondo el mundo del artista. ¿Cuál era su perspectiva del arte? ¿Se 
                            sentía parte de un movimiento? ¿Se oponía a algún estilo? ¿Cómo y por qué? ¿Cuáles 
                            eran sus intereses y sus proyectos? Las exposiciones en los museos frecuentemente 
                            agregan fichas que explican a grandes rasgos las obras, la técnica con la que fueron 
                            creadas y qué implicaciones tuvieron en su contexto.<br/><br/> 
                            
                            Si piensas que no entiendes una obra ¡busca información! Es probable que te guste 
                            más si la comprendes mejor.
                        </p>
                                    
                    </Grid>
                </Grid>
        </Grid>
    </Grid>
    );
}
 
export default En_busca_de_un_arte_nuevo;
