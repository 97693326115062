import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
     Contenedor:{
        background:'#E9FBF9',
        width:'100vw',
        overflowX:'clip',
        [theme.breakpoints.down(1161)]:{
            background:'none',
           overflowX:'unset',
        },
        [theme.breakpoints.down(600)]:{
            marginTop: '79.51px',
        }
    },
  ContainerInfoLectura: {
    position: "relative",
    height: "86px",
    [theme.breakpoints.down(502)]:{
        height: "43px",
    },
    background: "#76D4C3",
    "& .BarraInfoLectura": {
      height: "86px",
      background: `Url(https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Barras/Azul.png)`,
        [theme.breakpoints.down(502)]:{
            height: "43px",
        },
    },
    "& .InfoLectura": {
      position: "absolute",
      height: "86px",
      width: "219px",
      boxSizing: "border-box",
      padding: "17.2px 0",
      background: "#FAFAFA",
      borderRadius: "50px",
      boxShadow: "2px 2px 2px 2px #00000029",
      zIndex: "4",
        [theme.breakpoints.down(502)]:{
            height: "43px",
            width: "109px",
            padding: "8.6px 0",
            borderRadius: "25px",
        },
    },
    "& .InfoLectura:before": {
      position: "absolute",
      display: "flex",
      boxSizing: "border-box",
      padding: "0 0 0 10px",
      justifyContent: "center",
      alignItems: "center",
      height: "39.88px",
      width: "39.88px",
      borderRadius: "50%",
      content: '""',
      boxShadow: "3px 0px .5px 0px #00000029",
      background: "#FAFAFA",
      left: "90px",
      top: "65px",
      transform: "rotate(0.25turn)",
      [theme.breakpoints.down(502)]:{
        left: "45px",
        top: "30px",
        height: "20.88px",
        width: "20.88px",
        },
    },
    "& .InfoLectura:after": {
      content: '""',
      height: "15px",
      width: "15px",
      borderTop: "2px solid #20A698",
      borderLeft: "2px solid #20A698",
      position: "absolute",
      bottom: "-10px",
      left: "Calc(50% - 8px)",
      transform: "rotate(0.625turn)",
      [theme.breakpoints.down(502)]:{
        height: "10px",
        width: "10px",
        left: "Calc(50% - 4.5px)",
        bottom: "-3px",
        borderTop: "1px solid #20A698",
        borderLeft: "1px solid #20A698",
        },
    },
    "& .Sesion": {
      borderLeft: "2px solid #D8D8D8",
    },
    "& .Dato": {
      margin: "0 0 5px 0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#20A698",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        margin: "0 0 2.5px 0",
        },
    },
    "& .Caracter": {
      margin: "0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#000000",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        },
    },   },
    ContenedorLectura:{
        background:'#fff',
        width:'1160px',
        height:'100%',
        padding:'60px 168px 35px ',
        [theme.breakpoints.down(1161)]:{
            width:'502px',
            padding:'60px 0 0 0',
          },
        [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
        '& .ContainerDerechos':{
            bottom:'80px',
            [theme.breakpoints.down(1161)]:{
                display:'none',
            },
        },
        '& .Derechos':{
            textAlign:"center",
            margin:"0px",
            font:" 15px lato",
            color:"#717476",
        },
        '& .Escritorio':{
            display:'block',
            [theme.breakpoints.down(1161)]:{
                display:'none'
            },
        },
        '& .Tablet':{
            display:'none',
            [theme.breakpoints.down(1161)]:{
                display:'block'
            },
        }
    },
    ContainerTitulo:{
        margin:'0 0 34px 0',
        [theme.breakpoints.down(1161)]:{
            margin:'0 0 34px 0',
        },
        '& .Titulo':{
            letterSpacing: '0px',
            color: '#4196F8',
            margin:'0',
            textAlign:'center',
            font:'48px fredoka one',
            lineHeight:'58px',
            [theme.breakpoints.down(1161)]:{
                font:'43px fredoka one',
                lineHeight:'52px',
            },
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            margin:'0 0 36px 0',
            color:'#000',
            font:'22px lato',
            lineHeight : '30px',
            letterSpacing: '0.20px',
            [theme.breakpoints.down(1161)]:{
                font:'18px lato',
                lineHeight :'28px',
                letterSpacing: '0.17px',
            },
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .Fondo':{
            position:'relative'
        },
        '& .Esp':{
            margin:'0',
        },
        '& .Esp2':{
            margin:'0',
            boxSizing:'border-box',
            padding:'0 3px 0 0',
            [theme.breakpoints.down(1161)]:{
                padding:'0',
                margin:'0 0 36px 0',
            },
        },
        '& .Esp2-1':{
            margin:'0',
            boxSizing:'border-box',
            padding:'0 11px 0 0 ',
            [theme.breakpoints.down(1161)]:{
                padding:'0',
                margin:'0 0 36px 0',

            },
        },
        
        '& .Esp3':{
            position:'absolute',
            margin:'36px 0 0 0',
            boxSizing:'border-box',
            padding:'0 24% 0 0',
            [theme.breakpoints.down(1161)]:{
                margin:'0',
            },
        },
        '& .Imagen-1':{
            [theme.breakpoints.down(1161)]:{
                width:'100%',
            },
        },
        '& .Imagen':{
            [theme.breakpoints.down(1161)]:{
                width:'100%',
            },
        },   
        '& .ImgEsp':{
            width:'365px',
            [theme.breakpoints.down(1161)]:{
                width:'100%',
            },
        },
        '& .ImgEsp2':{
            [theme.breakpoints.down(502)]:{
                margin:'50px 0 0 0',
            },
        },
    }
}));


const Puentes = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
             <Grid container item justifyContent="center" className={classes.ContainerInfoLectura}>
               <Grid container className="BarraInfoLectura"></Grid>
               <Grid container item className="InfoLectura">
                    <Grid container item direction="column" xs={6} justifyContent="center">
                        <p className="Dato">Grado</p>
                        <p className="Caracter">4</p>
                    </Grid>
                    <Grid container item direction="column" xs={6} justifyContent="center" className="Sesion">
                        <p className="Dato">Sesión</p>
                        <p className="Caracter">6</p>
                    </Grid>
               </Grid>
            </Grid>

            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >Puentes</h2>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container sm={12}>
                      <p className="Lectura Esp"> 
                        Minueto se cree pájaro. Da vueltas en el patio y aletea, porque, para él, es como volar 
                        entre nubes. A Minueto no le gustan los abrazos ni los besos. Se parece a mi papá.
                      </p>
                    </Grid>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container md={12} lg={6}>
                      <p className="Lectura Esp2-1"> 
                        Papá me acaricia la cabeza para decirme 
                        “Te quiero”. A Minueto nunca. Mi 
                        hermanito tampoco sabe decir “Te 
                        quiero” ni reírse, pero un día vi que una 
                        catarina se paró en su brazo y él se puso 
                        a girar como remolino. Descubrí que ésa 
                        era su manera de reír.
                      </p>
                    </Grid>
                    <Grid container item  md={12} lg={6}>
                        <img className="ImgEsp Escritorio" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img4to/Sesion06/1.png" alt="Tarjeta" />
                        <img className="Imagen Tablet" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img4to/Sesion06/1R.png" alt="Tarjeta" />

                    </Grid>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container xs={12}>
                      <p className="Lectura Esp"> 
                        En cambio, papá nunca ríe. Normalmente se despierta muy temprano y regresa hasta 
                        la noche. Al llegar del trabajo, suele preguntarme si ya hice la tarea. A Minueto no le 
                        habla. Tal vez sabe que a mi hermano no le gusta contar lo que ve.<br/><br/> 
                      
                        A veces no es fácil estar contenta con Minueto. Como la tarde que fuimos al parque y 
                        me caí de la bicicleta. Mi rodilla sangraba y lloré.<br/><br/> 
                      
                        —¡Ve por mamá, Minueto! —le dije, pero no me oía a pesar de estar a su lado. <br/><br/> 
                        
                        Decidí irme a casa, cojeando. Minueto no quería partir. Lo jalé y comenzó a gritar 
                        como si quisiera raptarlo. Todos en el parque voltearon a verme. Me dio mucha 
                        vergüenza y me fui sin él. <br/><br/> 
                        
                        Cuando llegué a casa lo primero que me preguntó mamá fue: <br/><br/> 
                        
                        —¿Y Minueto? <br/><br/> 
                        
                        —Se quedó en el parque —respondí molesta, y ella salió corriendo a buscarlo. A nadie 
                        le importó mi caída. <br/><br/> 
                        
                        Dejé de hablarle a Minueto. Estaba molesta con él como lo está papá porque es 
                        diferente, pero al final hice las paces. Entiendo que él vive entre las nubes y desde su 
                        vuelo no vio ni escuchó mi caída. Mamá curó mi rodilla y papá observó mi raspón 
                        como si no lo comprendiera. Él no lo sabe, pero a veces también mira como Minueto.
                      </p>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container md={12} lg={5}>
                      <p className="Lectura Esp2"> 
                        Un día, papá llegó contento del 
                        trabajo. Sonaba feliz al hablar de 
                        máquinas que suben y bajan a la 
                        tierra. Supongo que para él son 
                        como las catarinas de Minueto. 
                        Dijo que construirá un puente 
                        grande, que atravesará la avenida y 
                        ya no recuerdo más porque me 
                        aburrí, pero Minueto estaba muy 
                        derechito, escuchándolo.<br/><br/> 
                        
                        A Minueto le gusta dibujar. Dibuja 
                        en las paredes, puertas y ventanas. 
                        Dice que cada ventana es el sol y 
                        las puertas son la noche.<br/><br/> 
                        
                        —¿Y las estrellas? —pregunté.<br/><br/> 
                        
                        —Son llaves —contestó sin dudar.
                      </p>
                    </Grid>
                    <Grid container item justifyContent='flex-end' md={12} lg={7}>
                        <img className="Imagen Escritorio" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img4to/Sesion06/2.png" alt="Tarjeta" />
                        <img className="Imagen Tablet" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img4to/Sesion06/2R.png" alt="Tarjeta" />
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container xs={12} >
                      <p className="Lectura Esp"> 
                        Supongo que Minueto es tan callado porque se la pasa imaginando cosas fantásticas. 
                        Pero a papá le disgusta tener una casa llena de soles y oscuridad en las paredes, así 
                        que a cada rato las pinta de nuevo. Cuando las paredes quedan lisas y blancas, 
                        Minueto se angustia y vuelve a dibujar puertas y ventanas en cuanto puede.
                      </p>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container xs={12} className="Fondo">
                      <p className="Lectura Esp3"> 
                        Una noche, Minueto dibujó un puente en la pared. Era un dibujo 
                        distinto a las puertas y ventanas que hacía. Se podían ver 
                        muchos detalles. Cuando papá llegó, se quedó mirando ese 
                        puente en vez de decir “Es necesario pintar”.
                      </p>
                      <img className="Imagen ImgEsp2" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img4to/Sesion06/3.png" alt="Tarjeta" />

                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container xs={12} >
                      <p className="Lectura "> 
                        Al otro día llevó a Minueto a su trabajo, para sorpresa de mamá y mía. Desde 
                        entonces, Minueto y papá tienen una misión secreta y se entienden sin hablar. Papá 
                        ya sacude el pelo de Minueto. “Lo quiere”, pienso.<br/><br/>     
                        
                        Minueto traza el puente de papá en todas las paredes y él ya no pide borrarlos, más 
                        bien viaja en ellos. Yo intento viajar también, tal vez en una de ésas pueda entrar al
                        mundo de papá, como lo hizo Minueto, y sonreír a su manera.
                      </p>
                    </Grid>
                </Grid>

                <Grid container item xs={12} justifyContent="center" className="ContainerDerechos">
                    <p className="Derechos">
                        © 2022. Todos los derechos reservados, Pearson, Circuito de lectores.
                    </p>                
                </Grid>
        </Grid>
    </Grid>
    );
}
 
export default Puentes;
