import React, { useContext, useMemo } from "react";
import { makeStyles, Grid, Button } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { Link } from "react-router-dom";
import ReporteContainer from "./Reporte_Container";
import AccordionStudents from "../../../filters/AccordionStudents";
import AccordionSesion from "../../../filters/AccordionSesion";
import AccordionSkills from "../../../filters/AccordionSkills";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";

import ReporteFallos from "../../../Charts/ReporteFallos";
import { useGetReporte2Data } from "../../../hooks/Reportes/useGetReporte2Data";
import AuthContext from "../../../../auth/AuthContext";
import UsabilityContext from "../../../../usability/UsabilityContext";

const useStyles = makeStyles((theme) => ({
  Container: {
    padding: "48px 5% 48px 5%",
    background:
      "linear-gradient(217deg, rgba(255,194,0,0.4962185557816877) 2%, rgba(255,255,255,1) 25%, rgba(255,255,255,0.4962185557816877) 81%, rgba(144,81,177,1) 98%)",
    minHeight: "calc(100vh - 80px)",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    display: "grid",
    position: "relative",
    gap: "20px",
    gridTemplateColumns: "1fr",
    gridTemplateAreas: (props) => (props.showFilter ? `"filter"` : `"data"`),
    [theme.breakpoints.up("md")]: {
      gridTemplateAreas: (props) =>
        props.showFilter ? `"filter data"` : `"filter data"`,
      gridTemplateColumns: (props) =>
        props.showFilter ? `250px 1fr` : `100px 1fr`,
    },
  },
  ContainerReportes: {
    background: "#fff",
    width: "100%",
    minHeight: "70vh",
    borderRadius: "42px",
    gridArea: "data",
    padding: "30px",
    boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.5)",
  },
  ContainerSerchBar: {
    gap: "10%",
    padding: "10px 0",
    justifyContent: "space-between",
  },
  SerchBar: {
    outline: "none",
    width: "100%",
    borderRadius: "36px",
    padding: "5px 32px",
    border: "4px solid #EA9D90",
    textAlign: "left",
    letterSpacing: "0.63px",
    color: "#7A54BA",
    font: "600 20px lato",
    // margin: " 0 10%",
    [theme.breakpoints.up("lg")]: {
      height: "auto",
      padding: "10px 32px",
    },
  },
  SerchBarIcon: {
    right: "23px",
    // top: "25px",
    position: "absolute",
    fontSize: "20px",
    color: "#7A54BA",
  },
  titleContainer: {
    margin: "20px 0",
    [theme.breakpoints.up("lg")]: {
      margin: "10px 0",
    },
  },
  reading: {
    width: "60%",
    margin: "0px ",
    font: "18px lato",
    letterSpacing: "0.21px",
    [theme.breakpoints.down(1400)]: {
      font: " 14px lato",
    },
    [theme.breakpoints.down(650)]: {
      font: "10px lato",
    },
  },
  title: {
    // margin: "0 0 84px 34px",
    font: "22px fredoka one",
    textAlign: "center",
    [theme.breakpoints.up("xl")]: {
      font: "26px fredoka one",
    },
  },
  PurpleButton: {
    color: "#FFFFFF",
    backgroundColor: "#9051b1",
    borderRadius: "30px",
    // margin: "0 auto",
    // width: "100%",
    minWidth: "75px",
    maxWidth: "238px",
    // minHeight: "45px",
    border: "none",
    textTransform: "unset",
    cursor: "pointer",
    fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
    fontWeight: "500",
    padding: "4px 14px",
    lineHeight: "1.5",
    transition:
      "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    boxShadow:
      "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
    "&:hover": {
      backgroundColor: "#B965D3 ",
    },
  },
  WitheButton: {
    color: "#9051b1",
    // borderColor: "#9051b1",
    border: "1px solid #9051b1",
    backgroundColor: "#FFFFFF",
    borderRadius: "30px",
    // margin: "0 auto",
    // width: "100%",
    minWidth: "75px",
    maxWidth: "238px",
    // minHeight: "45px",
    textTransform: "unset",
    cursor: "pointer",
    fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
    fontWeight: "500",
    padding: "4px 14px",
    lineHeight: "1.5",
    transition:
      "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    boxShadow:
      "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
    [theme.breakpoints.up("lg")]: {
      minWidth: "100px",
    },

    "&:hover": {
      backgroundColor: "#B965D330 ",
    },
  },
  link: {
    cursor: "pointer",
    textDecoration: "none",
    // height: "31px",
    // width: "190px",
  },
  backButtonContainer: {
    width: "auto",
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.up("sm")]: {
      justifyContent: "flex-start",
    },
  },
  backButton: {
    cursor: "pointer",
    height: "35px",
    // width: "100%",
    // maxWidth: "200px",
    backgroundColor: "#FDEFF7",
    color: "#DC5EA6",
    border: "#DC5EA6 1px solid",
    borderRadius: "20px",
    textTransform: "none",
    font: "20px Lato",
    letterSpacing: "0.14px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
  },
  buttonToggle: {
    color: "#9051b1",
    width: "auto",
    textTransform: "unset",
  },
  buttonToggleXs: {
    width: "auto",
    textTransform: "unset",
    color: "#9051b1",
    fontSize: "18px",
    fontFamily: "fredoka one",
    [theme.breakpoints.up("md")]: {
      display: "none",
      transform: "rotation",
    },
  },
  buttonToggleIcon: {
    transform: "rotate(90deg)",

    [theme.breakpoints.up("md")]: {
      transform: (props) =>
        props.showFilter ? `rotate(0deg)` : `rotate(180deg)`,
      fontSize: "36px",
    },
  },
  buttonToggleXsIcon: {
    transform: "rotate(270deg)",
  },

  ContainerFiltro: {
    zIndex: 2,
    display: (props) => (props.showFilter ? `flex` : `none`),
    width: "100%",
    alignItems: "center",
    gap: "10px",
    boxSizing: "border-box",
    gridArea: "filter",
    justifyContent: "space-between",
    paddingBottom: "20px",
    overflowY: "auto",
    overflowX: "hidden",
    position: (props) => (props.showFilter ? `fixed` : "static"),
    top: "80px",
    bottom: "0",
    right: "0",
    left: "0",
    background:
      "linear-gradient(217deg, rgba(255,194,0,1) 2%, rgba(255,255,255,1) 25%, rgba(255,255,255,1) 81%, rgba(144,81,177,1) 98%)",
    [theme.breakpoints.up("md")]: {
      position: () => "sticky",
      background: "transparent",
    },
  },
  ContainerFiltroUp: {
    gap: "10px",
    padding: "20px",
    [theme.breakpoints.up("md")]: {
      padding: "0px",
    },
  },
  containerFiltroTitle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  filtroTitle: {
    font: "22px fredoka one",
    textAlign: "center",
    width: "auto",
    margin: "0",
    [theme.breakpoints.up("sm")]: {
      width: "auto",
    },
    [theme.breakpoints.up("md")]: {
      font: "28px fredoka one",
      width: "auto",
    },
  },
  ContainerAlumnosInfo: {
    display: "flex",
    width: "100%",
    gap: "10px",
  },
  ContainerInput: {
    position: "relative",
    width: "250px",
  },
}));

const Reporte2 = () => {
  const { user } = useContext(AuthContext);
  const { groupData } = useContext(UsabilityContext);
  const group = useMemo(() => groupData || user?.grupos[0], [groupData, user]);

  const idDistribEsc = useMemo(() => group?.id_distribucion_escuela, [group]);
  const {
    studentsGraphList,
    studentsList,
    sesionName,
    sessionData,
    skillsData,
    downloadData,
    socioSkills,
    comprenSkills,
    showFilter,
    //Handlers
    handleToggle,
    getFullList,
    //Setters
    setStudentsGraphList,
    setSesionName,
    setSessionData,
    setSkillsData,
  } = useGetReporte2Data(idDistribEsc);

  const classes = useStyles({ showFilter });
  return (
    <ReporteContainer>
      <Grid
        container
        item
        sm={12}
        className={classes.Container}
        alignItems="center"
        justifyContent="center"
      >
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Button className={classes.buttonToggle} onClick={handleToggle}>
            {showFilter ? "Buscar por filtro" : "Filtrar"}
            <ArrowRightIcon className={classes.buttonToggleIcon} />
          </Button>
          <Grid
            container
            item
            direction="column"
            className={classes.ContainerFiltro}
          >
            <Grid
              container
              item
              direction="column"
              className={classes.ContainerFiltroUp}
            >
              <Grid
                container
                item
                direction="row"
                className={classes.containerFiltroTitle}
              >
                <Button
                  className={classes.buttonToggleXs}
                  onClick={handleToggle}
                >
                  {showFilter ? "Retraer" : "Ampliar"}
                  <ArrowRightIcon className={classes.buttonToggleXsIcon} />
                </Button>
              </Grid>
              <Grid
                container
                item
                direction="column"
                className={classes.ContainerAlumnosInfo}
              >
                <Grid>
                  <Button onClick={getFullList}>
                    Limpiar todos los filtros
                  </Button>
                </Grid>

                {studentsList.length ? (
                  <>
                    <AccordionStudents
                      studentsList={studentsList}
                      studentsGraphList={studentsGraphList}
                      setStudentsGraphList={setStudentsGraphList}
                    />
                    <AccordionSesion
                      skillsData={sesionName}
                      setSkillsData={setSesionName}
                      sesionData={sessionData}
                      setSesionData={setSessionData}
                      studentsList={studentsList}
                    />
                  </>
                ) : null}

                {skillsData && (
                  <AccordionSkills
                    skillsData={skillsData}
                    setSkillsData={setSkillsData}
                    comprenSkills={comprenSkills}
                    socioSkills={socioSkills}
                  />
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          item
          direction="column"
          className={classes.ContainerReportes}
        >
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            className={classes.backButtonContainer}
          >
            <Link className={classes.link} to="/docente/reportes">
              <button className={classes.backButton}>
                <ArrowBackIcon /> Reportes
              </button>
            </Link>
          </Grid>
          <Grid
            container
            item
            direction="column"
            alignItems="center"
            justifyContent="center"
            className={classes.titleContainer}
          >
            <h2 className={classes.title}>Reporte 2: Habilidades</h2>
            <p className={classes.reading}>
              Este reporte te muestra el porcentaje de aciertos y errores que
              tuvieron los estudiantes por cada una de las competencias
              evaluadas a lo largo del circuito.
            </p>
          </Grid>

          <Grid container item direction="row" alignItems="center">
            {skillsData.length && downloadData ? (
              <ReporteFallos
                skillsData={skillsData}
                setSkillsData={setSkillsData}
                downloadData={downloadData}
              />
            ) : null}
          </Grid>
        </Grid>
      </Grid>
    </ReporteContainer>
  );
};

export default Reporte2;
