import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
    ContenedorLectura:{
        width:'502px',
                  [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
    },
    ContainerTitulo:{
        margin:'0 0 34px 0',
        '& .Titulo':{
            letterSpacing: '0px',
            color: '#926DD1',
            margin:'0',
            textAlign:'center',
            font:'43px fredoka one',
            lineHeight:'52px',
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            margin:'0 0 36px 0',
            color:'#000',
            font:'18px lato',
            lineHeight :'28px',
            letterSpacing: '0.17px',
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .Imagen':{
            width:'100%',
        },  

      
    }
}));

const El_sueño_de_Roo = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >
                        El sueño de Roo
                    </h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container xs={12}>
                      <p className="Lectura ">
                        —Quiero un ejemplar de todo lo que existe en el mundo —dijo Roo—. Y de lo que más 
                        me guste. ¡Deseo tener una copia de todo por si perdiera el original!<br/><br/> 
                        
                        Roo había ganado el concurso del príncipe Tah, que era el hombre más poderoso del 
                        mundo. El premio era que el monarca le haría realidad un sueño, cualquiera que él 
                        tuviera. Pero el príncipe intentó persuadirlo de cambiar su voluntad cuando escuchó 
                        el deseo de Roo. <br/><br/>
                        
                        —Piénsalo mejor, elige algo que te haga feliz y que no te provoque ningún mal. ¿Se te 
                        ocurre qué puede ser? <br/><br/>
                        
                        —¡No! Yo quiero eso. ¡Estoy muy seguro! <br/>—exclamó Roo enérgicamente. <br/><br/>

                        —Que se haga como deseas —dijo Tah, convocando a su ejército para la misión con 
                        una mueca de angustia. <br/><br/>
                        
                        Inmediatamente, el príncipe dio instrucciones a sus súbditos para que llevasen 
                        cuantos objetos encontraran. Roo comenzó a recibir juguetes, consolas para 
                        videojuegos, bicicletas, un auto, un avión… <br/><br/>
                        
                        Las cosas satisfacían a Roo. Siempre había querido tener mucho dinero para 
                        comprarlas y poseer lo que nunca había podido disfrutar. Él había sido un joven 
                        sencillo, amable, con muchos amigos. Pero siempre tuvo un inevitable deseo de 
                        adquirir más. Constantemente vivía momentos de infelicidad al no contar con los 
                        recursos para satisfacer sus anhelos, que sus amistades trataban de aminorar 
                        compartiendo sus pertenencias y pasando el tiempo con él. <br/><br/>
                        
                        Todo marchaba bien al inicio. Los súbditos del príncipe Tah le dieron juguetes que lo 
                        entretuvieron. Pero de pronto comenzó a recibir cosas que nunca había deseado, ni 
                        siquiera imaginado. Un día le obsequiaron un sarcófago, una mesa para treinta 
                        personas, un violín, una caja de herramientas, un espejo en forma de unicornio, tres 
                        vestidos, un salvavidas, un despertador, un abrelatas, una carpa de circo y una 
                        armadura militar del siglo XII. No dudó en recibirlo al principio, pero comenzó a 
                        sentirse incómodo conforme iba adquiriendo más, pues necesitaba mayor espacio.
                      </p>
                    </Grid>
                    <Grid container justifyContent="center" xs={12} >
                        <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img4to/Sesion51/1.png" alt="Tarjeta" />
                    </Grid>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                      <p className="Lectura">
                        Poco a poco se vio rodeado de muchas cosas y poca gente. Eran tantos los objetos 
                        que no podía ver su propia casa y su espacio se redujo cada vez más. Se encontró 
                        amurallado por paredes de todo tipo de un momento a otro. Perdió contacto con las 
                        personas, su mundo se disipó. El paisaje cambió. El cielo y las montañas que solía 
                        mirar desde su ventana ahora eran montones de cajas y empaques de plástico. <br/><br/>
                        
                        —¿Hay alguien ahí? —gritó. <br/><br/>
                        
                        Al no escuchar respuesta, exploró todo lo que hallaba a su paso. Videojuegos, 
                        artículos deportivos, modelos para armar, patinetas, bicicletas y un auto eléctrico. 
                        Experimentaba más insatisfacción mientras más objetos encontraba. Se sintió 
                        aburrido en ese mundo sin compañía. <br/><br/>
                        
                        Pasaron los días y Roo seguía en jornadas interminables de inspeccionar cosas que 
                        no lo hacían feliz y que formaban un desierto a su alrededor. <br/><br/>
                        
                        ¡Ring, ring! <br/><br/>
                        
                        —¡Roo, despierta, se hace tarde para ir a la escuela! —gritó su mamá al ingresar a su 
                        habitación. <br/><br/>
                        
                        Se levantó de un salto y se apresuró para llegar a la escuela. Sus amigos 
                        intercambiaban experiencias sobre el fin de semana. Ahí estaba Tah, su mejor amigo, 
                        al que abrazó fuertemente cuando lo vio.<br/><br/>
                        
                        —¿Te encuentras bien? —preguntó Tah sorprendido. <br/><br/>
                        
                        —Tuve un sueño muy raro en el que tú eras un poderoso príncipe.<br/><br/>
                        
                        —¡Qué extraño! ¡Cuéntame qué pasó en tu sueño!
                      </p>
                    </Grid>
                </Grid>

                
        </Grid>
    </Grid>
    );
}
 
export default El_sueño_de_Roo ;
