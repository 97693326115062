import React from 'react';
import { makeStyles, Grid, Divider } from "@material-ui/core";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SkillsFilter     from './SkillsFilter';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    columns: '3 ',
    columnWidth: '30px',
    columnGap: '20px',
    [theme.breakpoints.down(980)]: {
      columns: '2 auto',
    },
    [theme.breakpoints.down(620)]: {
      columns: '1 auto',
    },
  },
  heading: {
    font: " 22px lato",
    letterSpacing: "0.36px",
    color: 'purple',
    margin: '0px',
    [theme.breakpoints.down(425)]: {
      font: " 14px lato",
    },
  },
  textName: {
    font: " 22px lato",
    letterSpacing: "0.36px",
    color: '#7A54BA',
    margin: '0px',
    padding:"5px",
    [theme.breakpoints.down(425)]: {
      font: " 14px lato",
    },
  },
  PurpleButton: {
    marginTop: '10px',
    color: "#FFFFFF",
    backgroundColor: "#9051b1",
    borderRadius: "30px",
    textTransform: "unset",
    // margin: "0 auto",
    width: "100%",
    maxWidth: "238px",
    minHeight: "45px",
    border: "none",
    // textTransform: "uppercase",
    cursor: "pointer",
    fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
    fontWeight: "500",
    padding: "6px 16px",
    lineHeight: "1.75",
    transition:
      "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    boxShadow: "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
    "&:hover": {
      backgroundColor: "#B965D3 ",
    },
  },
  gridItem:{
    height: '48px',
    overflow: "hidden",
    border: "1px solid #F27964",
  }
}));

const AccordionFilters = (props) => {
  // const{ studentsList, setStudentsList, studentsGraphList, setStudentsGraphList } = props
  const { skillsData, setSkillsData, comprenSkills, socioSkills} = props
  const classes = useStyles();

    return ( 
    <Grid container item xs={12} alignItems="center" justifyContent="center">
      <Accordion style={{width: '300px'}}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Grid container item xs={12} justifyContent="flex-start"> 
            <h3 className={classes.heading} >Habilidades</h3>
            <Divider variant="fullWidth"/>
          </Grid>
          
        </AccordionSummary>
        <AccordionDetails>
          {
            <SkillsFilter 
            skillsData={skillsData}
            setSkillsData={setSkillsData}
            comprenSkills={comprenSkills}
            socioSkills={socioSkills}
            />
          }
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
}
 
export default AccordionFilters;
