import { Button, Grid, makeStyles } from "@material-ui/core";
import React, { useContext, useEffect } from "react";
import DrawerContext from "../../../../../usability/DrawerContext";
import { useOnLoadImages } from "../../../../hooks/useOnLoadImages";
import Loader from "../../../../Loader/Loader";
import Drawer from "../../ReadingTools/Drawer";

const useStyles =  makeStyles (theme =>({
      Contenedor:{
        background:'#E9FBF9',
        width:'100vw',
        overflowX:'clip',
        [theme.breakpoints.down(1161)]:{
            background:'none',
           overflowX:'unset',
        },
        [theme.breakpoints.down(600)]:{
            marginTop: '79.51px',
        }
    },
  ContainerInfoLectura: {
    position: "relative",
    height: "86px",
    [theme.breakpoints.down(502)]:{
        height: "43px",
    },
    background: "#76D4C3",
    "& .BarraInfoLectura": {
      height: "86px",
      background: `Url(https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Barras/Azul.png)`,
        [theme.breakpoints.down(502)]:{
            height: "43px",
        },
    },
    "& .InfoLectura": {
      position: "absolute",
      height: "86px",
      width: "219px",
      boxSizing: "border-box",
      padding: "17.2px 0",
      background: "#FAFAFA",
      borderRadius: "50px",
      boxShadow: "2px 2px 2px 2px #00000029",
      zIndex: "4",
        [theme.breakpoints.down(502)]:{
            height: "43px",
            width: "109px",
            padding: "8.6px 0",
            borderRadius: "25px",
        },
    },
    "& .InfoLectura:before": {
      position: "absolute",
      display: "flex",
      boxSizing: "border-box",
      padding: "0 0 0 10px",
      justifyContent: "center",
      alignItems: "center",
      height: "39.88px",
      width: "39.88px",
      borderRadius: "50%",
      content: '""',
      boxShadow: "3px 0px .5px 0px #00000029",
      background: "#FAFAFA",
      left: "90px",
      top: "65px",
      transform: "rotate(0.25turn)",
      [theme.breakpoints.down(502)]:{
        left: "45px",
        top: "30px",
        height: "20.88px",
        width: "20.88px",
        },
    },
    "& .InfoLectura:after": {
      content: '""',
      height: "15px",
      width: "15px",
      borderTop: "2px solid #20A698",
      borderLeft: "2px solid #20A698",
      position: "absolute",
      bottom: "-10px",
      left: "Calc(50% - 8px)",
      transform: "rotate(0.625turn)",
      [theme.breakpoints.down(502)]:{
        height: "10px",
        width: "10px",
        left: "Calc(50% - 4.5px)",
        bottom: "-3px",
        borderTop: "1px solid #20A698",
        borderLeft: "1px solid #20A698",
        },
    },
    "& .Sesion": {
      borderLeft: "2px solid #D8D8D8",
    },
    "& .Dato": {
      margin: "0 0 5px 0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#20A698",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        margin: "0 0 2.5px 0",
        },
    },
    "& .Caracter": {
      margin: "0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#000000",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        },
    },
       '& .Regresar':{
        color: '#F2624F',
        border: '#F2624F 1px solid',
        height: '30px',
        width:'100px',
        borderRadius: '20px',
        letterSpacing: '0.14px',
        textTransform: 'none',
        background:'#FEFAF1',
        top:'calc(50% - 15px)',
        left:'5%',
        zIndex:4,
        [theme.breakpoints.down(502)]: {
          font:'bold 10px lato',
          width: "60px",
        },
      },
  },
    ContenedorLectura:{
        background:'#fff',
        width:'1160px',
        height:'100%',
        padding:'60px 168px 35px' ,
        
        transform: (props) =>
        props.scale > 1 ? `scale(${props.scale}) translate(0px, 10%)` : `none`,
        [theme.breakpoints.down(1161)]:{
            width:'502px',
            padding:'60px 0 0',
          },
          [theme.breakpoints.down(502)]:{
            width:'350px',
            padding:'60px 0 0',
          },
        '& .ContainerDerechos':{
            bottom:'80px',
            [theme.breakpoints.down(1161)]:{
                display:'none',
            }
        },
        '& .Derechos':{
            textAlign:"center",
            margin:"0px",
            font:" 15px lato",
            color:"#717476",
        },
        '& .Escritorio':{
            display:'block',
            [theme.breakpoints.down(1161)]:{
                display:'none'
            },
        },
        '& .Tablet':{
            display:'none',
            [theme.breakpoints.down(1161)]:{
                display:'block'
            },
        },
    },
    ContainerTitulo:{
        margin:'0 0 36px 0',
        [theme.breakpoints.down(1161)]:{
            margin:'0 0 38px 0',
        },
        '& .Titulo':{
            font:'48px fredoka one',
            letterSpacing: '0px',
            color: '#FC5E9E',
            margin:'0',
            textAlign:'center',
            [theme.breakpoints.down(1161)]:{
                font:'43px fredoka one',
                lineHeight:'52px',
                textAlign:'center',
            },
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            lineHeight : '30px',
            margin:'0 0 36px 0 ',
            font:'22px lato',
            color:'#000',
            letterSpacing: '0.20px',
            [theme.breakpoints.down(1161)]:{
                lineHeight :'28px',
                font:'18px lato',
                letterSpacing: '0.16px',
            },
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .Esp':{
            margin:'0',
        },
        '& .Esp2':{
            margin:'0',
            boxSizing:'border-box',
            padding:'0 25px 0 0',
            [theme.breakpoints.down(1161)]:{
                margin:'0 0 36px 0',
                padding:'0',
            },
        },
        '& .Esp3':{
            boxSizing:'border-box',
            padding:'0 40px 0 0',
            [theme.breakpoints.down(1161)]:{
                padding:'0',
            },
        },
        '& .TextImg':{
            margin:'22px 0 0 0',
            font:'20px lato',
            alignSelf:'flex-start',
            color:'#717476',
            [theme.breakpoints.down(1161)]:{
                font:'16px lato',
                letterSpacing:'.15px',
            },
            [theme.breakpoints.down(502)]:{
                font:' 11px lato',
                lineHeight :'14px',
                letterSpacing: '0.10px',
            },
        },
        '& .Imagen':{
            [theme.breakpoints.down(1161)]:{
                width:'100%',
            },
        },
    },

   
}));

const El_aburrimiento = (props) => {

  const {activeStep, setActiveStep, handlePreviewStep, id_actividad} = props

  const handleContinue = () => {
    setActiveStep(activeStep - 1)
    handlePreviewStep()
  }

    const {
    handleSize,
    disabledZoom,
    closeZoom,
    handleZoom,
    scale
  }= useContext(DrawerContext);
  const [loading, setLoading] = React.useState(true);
  const wrapperRef = React.useRef(null);
  const imagesLoaded = useOnLoadImages(wrapperRef);

  const detectSize = () => {
    const lectura = document.getElementById("lectura");
    const newWidth = lectura?.offsetWidth;
    const newHeight = lectura?.offsetHeight;
    setLoading(false);
    if (lectura) handleSize(newHeight, newWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", detectSize);
    return () => {
      handleZoom()
      window.removeEventListener("resize", detectSize);
      closeZoom()
    };
  }, []);

  const classes = useStyles({ disabledZoom,scale });
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
             <Grid container item justifyContent="center" className={classes.ContainerInfoLectura}>
               <Grid container className="BarraInfoLectura">
                    <Button className="Regresar" onClick={handleContinue}>
                        Regresar
                    </Button>
                </Grid>
               <Grid container item className="InfoLectura">
                    <Grid container item direction="column" xs={6} justifyContent="center">
                        <p className="Dato">Grado</p>
                        <p className="Caracter">6</p>
                    </Grid>
                    <Grid container item direction="column" xs={6} justifyContent="center" className="Sesion">
                        <p className="Dato">Sesión</p>
                        <p className="Caracter">22</p>
                    </Grid>
               </Grid>
            </Grid>
{!imagesLoaded && <Loader />}
        <Grid
            container
            item
            className={classes.ContenedorLectura}
            justifyContent="center"
            id="lectura"
            onLoad={detectSize}
            ref={wrapperRef}
        >
<Drawer id_lectura={id_actividad} />
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >
                        El aburrimiento
                    </h2>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura Esp">
                            ¿Cuántas veces a la semana te llegas a sentir aburrido? Seguro te ha sucedido alguna 
                            vez lo siguiente. Estás en casa, sentado simplemente “pasando el tiempo”. Entonces 
                            se acerca un algún familiar y te dice que te pongas a hacer algo, ¿cierto? Desde la 
                            perspectiva de esas personas es mejor rechazar el aburrimiento en lugar de 
                            aceptarlo como parte de las emociones y estados de ánimo que experimenta el ser 
                            humano.
                        </p>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container item sm={12} justifyContent="center" > 
                        <Grid container md={12} lg={6}  alignItems='flex-end' >
                            <p className="Lectura Esp2">
                                Hay una falsa creencia de que para “ser 
                                bueno en algo” debes estar siempre 
                                ocupado practicándolo. Por ello estar 
                                aburrido por no hacer nada parece algo 
                                malo. Reflexiona lo siguiente: cuando 
                                ves en internet, el cine o la televisión a 
                                una persona exitosa, generalmente es 
                                retratada como alguien con muchos 
                                negocios, que hace muchas cosas. Esto 
                                ha generado una idea equivocada de la 
                                felicidad, en la que no tienen cabida 
                                emociones que se han calificado como 
                                negativas, como es el caso del 
                                aburrimiento. Pero este estado 
                                emocional es natural y no hay por qué 
                                desaprobarlo.
                            </p>
                        </Grid>
                        <Grid container justifyContent="center" md={12} lg={6}>
                            <Grid container justifyContent='center' >
                                <img className="Imagen Escritorio" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion22/1.png" alt="Tarjeta" />
                                <img className="Imagen Tablet" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion22/1R.png" alt="Tarjeta" />
                            </Grid>
                            <Grid container justifyContent="center">
                                <p className='TextImg Esp3'> 
                                    Reflexiona si el éxito que deseas obtener 
                                    debería ser como te lo pintan en los 
                                    medios de comunicación.
                                </p>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12} alignItems='flex-end'>
                        <p className="Lectura ">
                            La coordinadora del Centro de Trastorno por Déficit de Atención e Hiperactividad y 
                            Trastornos de la Conducta del Instituto Mental de la Niñez en Estados Unidos, la 
                            doctora en psicología Stephanie Lee, opina que el aburrimiento enseña a desarrollar 
                            valiosas habilidades. Por ejemplo, contribuye a ser tolerantes. Pues si bien el 
                            aburrimiento en sí mismo no es dañino, no es divertido cuando se experimenta. Por 
                            lo que hay que ser tolerante con uno mismo para salir de esa etapa sin angustiarse, 
                            de tal manera que uno aprenda a manejar mejor la frustración y poner en práctica la 
                            autorregulación. <br/><br/>
                            
                            De igual forma señala que, cuando uno se aburre, se genera una oportunidad que 
                            permite desarrollar estrategias de planeación y habilidades para resolver problemas. 
                            Cabe puntualizar que no es el aburrimiento lo que permite desarrollar dichas 
                            habilidades. Más bien éste crea el contexto ideal para el desarrollo de las mismas, 
                            puesto que permite que quien se aburra se vuelva creativo al generar pensamientos 
                            originales. <br/><br/>
                            
                            Aunque se ha dicho hasta aquí que estar aburrido no es un problema, es importante 
                            identificar qué implica “estar aburrido”. Sobre todo porque puede confundirse con 
                            alguna otra sensación, como tener hambre, buscar atención, tener curiosidad por lo 
                            que está haciendo alguien más, etcétera, sólo que no se sabe cómo expresarlo 
                            adecuadamente. <br/><br/>
                            
                            Es importante que cuando te sientas de esa manera te des el tiempo y el espacio para 
                            identificar qué estás sintiendo, para que así reconozcas tus propias necesidades y 
                            paulatinamente te sientas mejor. De esta manera podrías incluso darte la 
                            oportunidad de cambiar algo en tu vida que sientas que ya no está funcionando. <br/><br/>
                            
                            De igual forma, el aburrimiento abre un espacio para que estés en contacto contigo 
                            mismo y redescubras tus emociones y sueños. Es el pretexto para pensar en tu futuro 
                            y visualizarte en él. Piensa en lo que te gustaría hacer, lo que quieres lograr y lo que 
                            necesitas realizar para conseguirlo. ¡Es un espacio de creatividad!
                        </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <Grid container >
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion22/2.png" alt="Tarjeta" />
                        </Grid>
                        <Grid container >
                            <p className='TextImg '> 
                            Si te das espacios para estar contigo mismo descubrirás que tienes ideas grandiosas y sueños 
                            extraordinarios.
                            </p>

                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12} alignItems='flex-end'>
                        <p className="Lectura ">
                            Si todo el tiempo estás ocupado, “sin aburrirte”, no le das descanso a tu cerebro. 
                            Precisamente tomar un respiro te permitirá oxigenar tu mente, para que a partir del 
                            reposo se renueven energías que te permitan ser creativo y enfocar mejor tu 
                            atención. Piensa que por la misma razón es tan importante dormir. ¿Qué pasa cuando 
                            te desvelas o simplemente no duermes? Al día siguiente no puedes hacer muchas 
                            cosas. Pues estar sin hacer nada es como dormir despierto: descansas y te permite <br/>
                            concentrarte mejor después. <br/><br/>
                            
                            Recuerda que experimentar el aburrimiento ocasionalmente no representa un 
                            problema. Pero si los periodos en los que te sucede son cada vez más continuos o 
                            extensos, esto puede ser señal de que algo te esté pasando. Ante esta situación, la 
                            doctora Lee recomienda tener una lista de actividades para realizar cuando se 
                            presenten los estados prolongados de este sentimiento. <br/><br/>
                            
                            Todo dependerá de que analices cuáles son las actividades que te gusta hacer y que 
                            no requieren de grandes preparativos para realizarlas, como un juego de mesa, 
                            dibujar, leer, hacer deporte o compartir contenidos en redes sociales. Si después de 
                            intentar estas actividades persiste la sensación o sentimiento, coméntalo con algún 
                            familiar adulto o maestro. Ellos tendrán la experiencia o los conocimientos para 
                            guiarte. <br/><br/>
                            
                            Además de todo lo que aprendiste, vale la pena que recuerdes una frase que el 
                            filósofo holandés del siglo <span style={{fontVariant: "all-small-caps"}}>XVI</span> Erasmo de Rotterdam decía: “El que conoce el arte de 
                            vivir consigo mismo ignora el aburrimiento”.
                        </p>
                    </Grid>
                </Grid>
        </Grid>
        <Grid container item style={{ marginTop: 80 }}></Grid>
    </Grid>
    );
}
 
export default El_aburrimiento;
