import { Button, Grid, makeStyles } from "@material-ui/core";
import React, { useContext, useEffect } from "react";
import DrawerContext from "../../../../../usability/DrawerContext";
import { useOnLoadImages } from "../../../../hooks/useOnLoadImages";
import Loader from "../../../../Loader/Loader";
import Drawer from "../../ReadingTools/Drawer";

const useStyles =  makeStyles (theme =>({
     Contenedor:{
        background:'#E9FBF9',
        width:'100vw',
        overflowX:'clip',
        [theme.breakpoints.down(1161)]:{
            background:'none',
           overflowX:'unset',
        },
        [theme.breakpoints.down(600)]:{
            marginTop: '79.51px',
        }
    },
  ContainerInfoLectura: {
    position: "relative",
    height: "86px",
    [theme.breakpoints.down(502)]:{
        height: "43px",
    },
    background: "#76D4C3",
    "& .BarraInfoLectura": {
      height: "86px",
      background: `Url(https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Barras/Azul.png)`,
        [theme.breakpoints.down(502)]:{
            height: "43px",
        },
    },
    "& .InfoLectura": {
      position: "absolute",
      height: "86px",
      width: "219px",
      boxSizing: "border-box",
      padding: "17.2px 0",
      background: "#FAFAFA",
      borderRadius: "50px",
      boxShadow: "2px 2px 2px 2px #00000029",
      zIndex: "4",
        [theme.breakpoints.down(502)]:{
            height: "43px",
            width: "109px",
            padding: "8.6px 0",
            borderRadius: "25px",
        },
    },
    "& .InfoLectura:before": {
      position: "absolute",
      display: "flex",
      boxSizing: "border-box",
      padding: "0 0 0 10px",
      justifyContent: "center",
      alignItems: "center",
      height: "39.88px",
      width: "39.88px",
      borderRadius: "50%",
      content: '""',
      boxShadow: "3px 0px .5px 0px #00000029",
      background: "#FAFAFA",
      left: "90px",
      top: "65px",
      transform: "rotate(0.25turn)",
      [theme.breakpoints.down(502)]:{
        left: "45px",
        top: "30px",
        height: "20.88px",
        width: "20.88px",
        },
    },
    "& .InfoLectura:after": {
      content: '""',
      height: "15px",
      width: "15px",
      borderTop: "2px solid #20A698",
      borderLeft: "2px solid #20A698",
      position: "absolute",
      bottom: "-10px",
      left: "Calc(50% - 8px)",
      transform: "rotate(0.625turn)",
      [theme.breakpoints.down(502)]:{
        height: "10px",
        width: "10px",
        left: "Calc(50% - 4.5px)",
        bottom: "-3px",
        borderTop: "1px solid #20A698",
        borderLeft: "1px solid #20A698",
        },
    },
    "& .Sesion": {
      borderLeft: "2px solid #D8D8D8",
    },
    "& .Dato": {
      margin: "0 0 5px 0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#20A698",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        margin: "0 0 2.5px 0",
        },
    },
    "& .Caracter": {
      margin: "0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#000000",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        },
    },
       '& .Regresar':{
        color: '#F2624F',
        border: '#F2624F 1px solid',
        height: '30px',
        width:'100px',
        borderRadius: '20px',
        letterSpacing: '0.14px',
        textTransform: 'none',
        background:'#FEFAF1',
        top:'calc(50% - 15px)',
        left:'5%',
        zIndex:4,
        [theme.breakpoints.down(502)]: {
          font:'bold 10px lato',
          width: "60px",
        },
      },
  },
    ContenedorLectura:{
        background:'#fff',
        width:'1160px',
        height:'100%',
                padding:'60px 168px 35px ',
        
        transform: (props) =>
        props.scale > 1 ? `scale(${props.scale}) translate(0px, 10%)` : `none`,
        [theme.breakpoints.down(1161)]:{
            width:'502px',
            padding:'60px 0 0 0',
          },
        [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },

        '& .Escritorio':{
            display:'block',
            [theme.breakpoints.down(1161)]:{
                display:'none'
            },
        },
        '& .Tablet':{
            display:'none',
            [theme.breakpoints.down(1161)]:{
                display:'block'
            },
        }
    },
    ContainerTitulo:{
        margin:'0 0 34px 0',
        [theme.breakpoints.down(1161)]:{
            margin:'0 0 34px 0',
        },
        '& .Titulo':{
            font:'48px fredoka one',
            letterSpacing: '0px',
            lineHeight:'58px',
            color: '#D504B7',
            margin:'0',
            textAlign:'center',
            [theme.breakpoints.down(1161)]:{
                font:'43px fredoka one',
                lineHeight:'52px',
            },
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        position:'relative',
        '& .Lectura':{
            lineHeight : '30px',
            margin:'0 0 36px 0',
            font:'22px lato',
            color:'#000',
            letterSpacing: '0.20px',
            [theme.breakpoints.down(1161)]:{
                font:'18px lato',
                lineHeight :'28px',
                letterSpacing: '0.17px',
            },
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .SubTitulo':{
            font:'bold 30px lato',
            color:'#03A8FF',
            letterSpacing:'.28px',
            lineHeight:'30px',
            margin:'0',
            [theme.breakpoints.down(1161)]:{
                lineHeight :'28px',
                font:'bold 26px lato',
                letterSpacing: '0.24px',
            },
            [theme.breakpoints.down(502)]:{
                font:'bold 18px lato',
                lineHeight :'24px',
                letterSpacing: '0.21px',
            },
        },
        '& .SubTitulo2':{
            font:'bold 26px lato',
            color:'#F6644B',
            letterSpacing:'.24px',
            lineHeight:'28px',
            margin:'0',
            [theme.breakpoints.down(1161)]:{
                font:'bold 22px lato',
                lineHeight :'24px',
                letterSpacing: '0.21px',
            },
            [theme.breakpoints.down(502)]:{
                font:'bold 18px lato',
                lineHeight :'20px',
                letterSpacing: '0.16px',
            },
        },

        '& .Imagen':{
            [theme.breakpoints.down(1161)]:{
                width:'100%',
            },
        }, 
        '& .Imagen1':{
            position:'absolute',
            top:'80.13px',
            left:'56.47px',
            [theme.breakpoints.down(1281)]:{
                top:'50.13px',
            },
            [theme.breakpoints.down(502)]:{
                top:'35.13px',
                width:'76.68%',
                left:'36.47px',
            },
        },  
        '& .Imagen1F':{
            [theme.breakpoints.down(502)]:{
                width:'91.03%',
            },
        }, 
        '& .Imagen2':{
            position:'absolute',
            top:'34px',
            [theme.breakpoints.down(1161)]:{
                width:'76.09%',
            },
            [theme.breakpoints.down(502)]:{
                top:'14px',
            },
        },  
        '& .Imagen2F':{
            [theme.breakpoints.down(1161)]:{
                width:'100%',
            },
        }, 
        '& .Cuadro':{
            position:'relative',
            boxSizing:'border-box',
            padding:'0 0 0 28px',
            [theme.breakpoints.down(1281)]:{
                padding:'0',
                margin:'36px 0 0 0'
            },
        },
        '& .Cuadro2':{
            position:'relative',
        },
        '& .Esp':{
            margin:'0',
            padding:'0 8px 0 0',
            [theme.breakpoints.down(1281)]:{
                padding:'0',
            },
        }

      
    }
}));

const PIEl_número_interminable = (props) => {

  const {activeStep, setActiveStep, handlePreviewStep, id_actividad} = props
  
  const handleContinue = () => {
    setActiveStep(activeStep - 1)
    handlePreviewStep()
  }

    const {
      handleSize,
    disabledZoom,
    closeZoom,
    handleZoom,
    scale
  }= useContext(DrawerContext);
  const [loading, setLoading] = React.useState(true);
  const wrapperRef = React.useRef(null);
  const imagesLoaded = useOnLoadImages(wrapperRef);

  const detectSize = () => {
    const lectura = document.getElementById("lectura");
    const newWidth = lectura?.offsetWidth;
    const newHeight = lectura?.offsetHeight;
    setLoading(false);
    if (lectura) handleSize(newHeight, newWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", detectSize);
    return () => {
      handleZoom()
      window.removeEventListener("resize", detectSize);
      closeZoom()
    };
  }, []);

  const classes = useStyles({ disabledZoom,scale });
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
             <Grid container item justifyContent="center" className={classes.ContainerInfoLectura}>
                       <Grid container className="BarraInfoLectura">
            <Button className="Regresar" onClick={handleContinue}>
                Regresar
            </Button>
        </Grid>
               <Grid container item className="InfoLectura">
                    <Grid container item direction="column" xs={6} justifyContent="center">
                        <p className="Dato">Grado</p>
                        <p className="Caracter">5</p>
                    </Grid>
                    <Grid container item direction="column" xs={6} justifyContent="center" className="Sesion">
                        <p className="Dato">Sesión</p>
                        <p className="Caracter">58</p>
                    </Grid>
               </Grid>
            </Grid>
            {!imagesLoaded && <Loader />}
        <Grid
            container
            item
            className={classes.ContenedorLectura}
            justifyContent="center"
            id="lectura"
            onLoad={detectSize}
            ref={wrapperRef}
        >
<Drawer id_lectura={id_actividad} />
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                        <h2 className="Titulo">π (pi): el número interminable</h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container item md={12} lg={5}>
                       <p className='Lectura Esp'>
                        <h3 className='SubTitulo'>¿Qué es π (pi)?</h3><br/>

                        En matemáticas la letra griega π 
                        representa la cantidad de veces 
                        que cabe el diámetro de un círculo 
                        en su circunferencia. Los círculos 
                        pueden ser grandes o pequeños, 
                        pero <em>π</em> es siempre igual. No 
                        importa de qué tamaño sea el 
                        círculo que nos encontremos: 
                        siempre que dividamos la longitud 
                        de su circunferencia entre su 
                        diámetro obtendremos π.<br/><br/>
                        
                        Como puedes ver en este 
                        esquema, su valor aproximado es 
                        3.141592… Pero ¿cuál es 
                        el exacto? Ése es precisamente el 
                        problema: las cifras decimales de π 
                        son interminables, porque 
                        pertenece a los <em>números 
                        irracionales.</em> 
                       </p>                   
                    </Grid>
                    <Grid container item  md={12} lg={7} justifyContent='center' className='Cuadro'>
                        <Grid container item justifyContent='center'>
                            <img className="Imagen1 " src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion58/1.png" alt="Tarjeta" />
                        </Grid>
                        <Grid container item justifyContent='center' >
                            <img className="Imagen1F " src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion58/1F.png" alt="Tarjeta" />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container >
                        <p className='Lectura'>
                            Como puedes ver en este esquema, su valor aproximado es 3.141592… Pero ¿cuál es 
                            el exacto? Ése es precisamente el problema: las cifras decimales de π son 
                            interminables, porque pertenece a los <em>números irracionales</em>.<br/><br/>
                            
                            <h3 className='SubTitulo'>Los números irracionales: la familia de π</h3><br/>
                            
                            Ya has leído sobre los <em>números naturales</em> (como el 1, el 45 o el 377): son todos aquellos 
                            con los que podemos contar objetos. Conoces también los números enteros, que 
                            incluyen tanto a los naturales como a los enteros negativos (-1 y -20, por ejemplo). 
                            Pero ¿cuáles son los números irracionales? <br/><br/>
                            
                            Para comprenderlos antes tenemos que abordar a sus contrarios: los <em>números 
                            racionales. </em>Tú los conoces como <em>fracciones</em> (½ o ¾, por ejemplo). Un <em>número racional</em> es 
                            el que resulta cuando dividimos un número entero entre un número entero <em>positivo</em>. 
                            Por ejemplo, tanto el 1 como el 2 son números enteros positivos. Si dividimos el 1 
                            entre el 2 obtendremos la fracción ½. <br/><br/>
                            
                            Los números racionales también escribirse con decimales: ½ equivale a 0.5 y ambas 
                            son formas de representar el mismo número racional. Para hablar de la parte del 
                            número que queda a la derecha del punto utilizamos el término <em>expansión decimal</em>.
                        </p>
                    </Grid>
                    <Grid container item  xs={12} justifyContent='center' className='Cuadro2'>
                        <Grid container item justifyContent='center' >
                            <img className="Imagen2" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion58/2.png" alt="Tarjeta" />
                        </Grid>
                        <Grid container item justifyContent='center' >
                            <img className="Imagen2F" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion58/2F.png" alt="Tarjeta" />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container >
                        <p className='Lectura'>
                            Los números irracionales son una clase especial de números con expansión decimal y, 
                            en ellos, esta expansión puede crecer indefinidamente.<br/><br/> 
                            
                            <h3 className='SubTitulo2'>Dos tipos de cifras ilimitadas</h3><br/>
                            
                            ¿Alguna vez has intentado dividir 10 entre 3? Puedes resolver esta operación en una 
                            hoja de papel o con una calculadora. Encontrarás que el resultado es 3.333…, es 
                            decir, un 3 como parte entera y una cantidad infinita de treses como expansión 
                            decimal. <br/><br/>
                            
                            Éste es un <em>número racional</em>, porque viene de una división de números enteros
                            positivos. Sin embargo, sus cifras decimales son ilimitadas. Entonces ¿qué hace 
                            special a π y al resto de los irracionales? Nota que en 3.333… un solo número se 
                            repite. Cuando tenemos un número irracional las cifras decimales son siempre 
                            distintas e impredecibles.<br/><br/>
                            
                            <h3 className='SubTitulo'>Cifras y popularidad de π</h3><br/>
                            
                            Aunque aún no se conocen todas las cifras de π, el 17 de agosto de 2021 se 
                            calcularon 62.8 billones de ellas. Las encontró una computadora de la Universidad de 
                            Ciencias Aplicadas de los Grisones, después de pasar 108 días y nueve horas 
                            calculando. Con ello logró superar el récord mundial previo de 50 billones. <br/><br/>
                        
                            ¿Es necesario conocer tantas cifras decimales de π? No realmente. El número sirve 
                            para realizar cálculos relacionados con círculos y circunferencias: desde las elipses 
                            de las trayectorias espaciales hasta la fabricación de ruedas y balones de futbol. Pero
                            estos procedimientos apenas necesitan un par de cifras decimales para funcionar 
                            adecuadamente. Incluso la (<span style={{fontVariant: "all-small-caps"}}>NASA</span>) utiliza un valor aproximado: sólo las primeras 15 
                            cifras decimales. <br/><br/>
                            
                            Entonces ¿por qué se calculan tantas cifras decimales de π? En primer lugar porque 
                            es un reto para los matemáticos. En segundo porque este número es importante en 
                            muchas áreas del conocimiento y se ha vuelto simbólico. Incluso hay un día 
                            internacional para festejarlo: el 14 de marzo (o 3/14, porque en Estados Unidos las 
                            fechas se escriben empezando por el mes).<br/><br/> 
                            
                            <h3 className='SubTitulo'>π incluye tu cumpleaños</h3><br/>
                            
                            Como π se alarga indefinidamente, cualquier número natural puede aparecer en
                            algún punto de sus cifras. Por ejemplo, encontrarás muchas veces el 239. <br/><br/>
                            
                            Incluso podrías hallar tu fecha de nacimiento en su expansión decimal. Y también la 
                            de tus familiares, tus amigos, y la de cualquier persona que haya nacido en esta era. 
                            ¿Quieres comprobarlo? Escribe en un buscador de internet las palabras
                            “cumpleaños” y “pi” y encontrarás la página de la Sociedad Mexicana Matemática. Allí 
                            podrás encontrar tu cumpleaños en la expansión decimal de π.
                        </p>
                    </Grid>
                </Grid>
        </Grid>
        <Grid container item style={{ marginTop: 80}} ></Grid>
    </Grid>
    );
}
 
export default PIEl_número_interminable;
