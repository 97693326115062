import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
    ContenedorLectura:{
        width:'502px',
                  [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
    },
    ContainerTitulo:{
        margin:'0 0 34px 0',
        '& .Titulo':{
            letterSpacing: '0px',
            color: '#FF9D00',
            margin:'0',
            textAlign:'center',
            font:'43px fredoka one',
            lineHeight:'52px',
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
        "& .cel":{
            display:'none',
            [theme.breakpoints.down(502)]: {
              display:'block',
            },
          },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            margin:'0 0 36px 0',
            color:'#000',
            font:'18px lato',
            lineHeight :'28px',
            letterSpacing: '0.17px',
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .Esp':{
            margin:'0 0 36px ',
            padding:'0',
        },
        '& .Esp2':{
            margin:'0',
        },
        '& .TextImg':{
            color:'#717476',
            margin:'24px 0 0 0',
            font:' 16px lato',
            lineHeight :'25px',
            letterSpacing: '0.15px',
            [theme.breakpoints.down(502)]:{
                font:' 11px lato',
                lineHeight :'14px',
                letterSpacing: '0.10px',
            },
        },
        '& .Imagen':{
            width:'100%',
        }, 

    }
}));

const El_panel_de_abejas = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >El panal<br className='cel'/> de las abejas</h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container xs={12} >
                      <p className="Lectura Esp">
                        ¿Alguna vez te has fijado que las 
                        losetas colocadas en los pisos o los 
                        azulejos de las paredes tienen 
                        forma de cuadrados o 
                        rectángulos? Si observas bien, las 
                        losetas o azulejos cubren 
                        perfectamente la superficie plana 
                        sobre la que están colocados. Es 
                        decir, no hay espacios entre ellos, 
                        pues están completamente 
                        unidos. A esta forma de cubrir 
                        superficies planas con figuras 
                        geométricas regulares se le llama 
                        <em> teselado</em>. 
                      </p>
                    </Grid>
                    <Grid container direction="column" justifyContent="center" xs={12} >
                        <Grid container  justifyContent="center" >
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img1ro/Sesion26/1.png" alt="Tarjeta" />
                        </Grid>
                        <Grid container justifyContent="center" >
                            <p className="TextImg">
                            Un ejemplo de teselado con hexagónos.
                            </p>
                        </Grid>
                        
                    </Grid>
                </Grid>  
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container xs={12}>
                      <p className="Lectura Esp">
                        Además de los cuadrados, para 
                        hacer teselados pueden usarse 
                        triángulos equiláteros y 
                        hexágonos regulares.
                      </p>
                    </Grid>
                    <Grid container direction="column" justifyContent="center" xs={12}>
                        <Grid container  justifyContent="center" >
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img1ro/Sesion26/2.png" alt="Tarjeta" />
                        </Grid>
                        
                        <Grid container justifyContent="center" >
                            <p className="TextImg">
                            Un ejemplo de teselado con cuadrados.
                            </p>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                      <p className="Lectura">
                        En la imagen de la derecha, se 
                        muestra un ejemplo de teselado 
                        con hexágonos. Como puedes 
                        observar, no hay espacios, pues los 
                        hexágonos están perfectamente 
                        unidos por sus lados. Ahora 
                        sabemos que el teselado con 
                        hexágonos es la mejor opción.
                        Esta forma cubre una mayor área
                        con menos material. <br/> <br/>

                        Pero ¿esa imagen no te recuerda algo? ¡Claro! ¡Un panal de abejas! Al construir 
                        un panal con forma de hexágonos, las abejas utilizan menos cera que si lo 
                        hicieran con cuadrados o triángulos. Además, los hexágonos les permiten 
                        almacenar más miel. Qué inteligentes, ¿no crees?
                      </p>
                    </Grid>
                    <Grid container direction="column" sm={12}>
                        <Grid container justifyContent="center" >
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img1ro/Sesion26/3.png" alt="Tarjeta" />
                        </Grid>
                        
                        <Grid container justifyContent="center" >
                            <p className="TextImg Esp2">
                            Los panales de abejas son teselados hexagonales.
                            </p>
                        </Grid>
                        
                    </Grid>
                </Grid>        
        </Grid>
    </Grid>
    );
}
 
export default El_panel_de_abejas;
