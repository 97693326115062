import { Button, Grid, makeStyles } from "@material-ui/core";
import React, { useContext, useEffect } from "react";
import DrawerContext from "../../../../../usability/DrawerContext";
import { useOnLoadImages } from "../../../../hooks/useOnLoadImages";
import Loader from "../../../../Loader/Loader";
import Drawer from "../../ReadingTools/Drawer";

const useStyles =  makeStyles (theme =>({
     Contenedor:{
        background:'#E9FBF9',
        width:'100vw',
        overflowX:'clip',
        [theme.breakpoints.down(1161)]:{
            background:'none',
           overflowX:'unset',
        },
        [theme.breakpoints.down(600)]:{
            marginTop: '79.51px',
        }
    },
 ContainerInfoLectura: {
    position: "relative",
    height: "86px",
    [theme.breakpoints.down(502)]:{
        height: "43px",
    },
    background: "#76D4C3",
    "& .BarraInfoLectura": {
      height: "86px",
      background: `Url(https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Barras/Azul.png)`,
        [theme.breakpoints.down(502)]:{
            height: "43px",
        },
    },
    "& .InfoLectura": {
      position: "absolute",
      height: "86px",
      width: "219px",
      boxSizing: "border-box",
      padding: "17.2px 0",
      background: "#FAFAFA",
      borderRadius: "50px",
      boxShadow: "2px 2px 2px 2px #00000029",
      zIndex: "4",
        [theme.breakpoints.down(502)]:{
            height: "43px",
            width: "109px",
            padding: "8.6px 0",
            borderRadius: "25px",
        },
    },
    "& .InfoLectura:before": {
      position: "absolute",
      display: "flex",
      boxSizing: "border-box",
      padding: "0 0 0 10px",
      justifyContent: "center",
      alignItems: "center",
      height: "39.88px",
      width: "39.88px",
      borderRadius: "50%",
      content: '""',
      boxShadow: "3px 0px .5px 0px #00000029",
      background: "#FAFAFA",
      left: "90px",
      top: "65px",
      transform: "rotate(0.25turn)",
      [theme.breakpoints.down(502)]:{
        left: "45px",
        top: "30px",
        height: "20.88px",
        width: "20.88px",
        },
    },
    "& .InfoLectura:after": {
      content: '""',
      height: "15px",
      width: "15px",
      borderTop: "2px solid #20A698",
      borderLeft: "2px solid #20A698",
      position: "absolute",
      bottom: "-10px",
      left: "Calc(50% - 8px)",
      transform: "rotate(0.625turn)",
      [theme.breakpoints.down(502)]:{
        height: "10px",
        width: "10px",
        left: "Calc(50% - 4.5px)",
        bottom: "-3px",
        borderTop: "1px solid #20A698",
        borderLeft: "1px solid #20A698",
        },
    },
    "& .Sesion": {
      borderLeft: "2px solid #D8D8D8",
    },
    "& .Dato": {
      margin: "0 0 5px 0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#20A698",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        margin: "0 0 2.5px 0",
        },
    },
    "& .Caracter": {
      margin: "0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#000000",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        },
    },
       '& .Regresar':{
        color: '#F2624F',
        border: '#F2624F 1px solid',
        height: '30px',
        width:'100px',
        borderRadius: '20px',
        letterSpacing: '0.14px',
        textTransform: 'none',
        background:'#FEFAF1',
        top:'calc(50% - 15px)',
        left:'5%',
        zIndex:4,
        [theme.breakpoints.down(502)]: {
          font:'bold 10px lato',
          width: "60px",
        },
      },
  },
    ContenedorLectura:{
        background:'#fff',
        width:'1160px',
        height:'100%',
        padding:'60px 168px 35px ',
        
        transform: (props) =>
        props.scale > 1 ? `scale(${props.scale}) translate(0px, 10%)` : `none`,
        [theme.breakpoints.down(1161)]:{
            width:'502px',
            padding:'60px 0 0 0',
          },
        [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },

        '& .Escritorio':{
            display:'block',
            [theme.breakpoints.down(1161)]:{
                display:'none'
            },
        },
        '& .Tablet':{
            display:'none',
            [theme.breakpoints.down(1161)]:{
                display:'block'
            },
        }
    },
    ContainerTitulo:{
        margin:'0 0 46px 0',
        '& .Titulo':{
            font:'48px fredoka one',
            letterSpacing: '0px',
            color: '#823CE2',
            margin:'0',
            [theme.breakpoints.down(1161)]:{
                font:'43px fredoka one',
                lineHeight:'52px',
            },
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            lineHeight : '30px',
            margin:'0 0 36px 0',
            font:'22px lato',
            color:'#000',
            letterSpacing: '0.18px',
            [theme.breakpoints.down(1161)]:{
                font:'18px lato',
                lineHeight :'28px',
                letterSpacing: '0.17px',
            },
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .Cuadro':{
            position:'relative',
        },
        '& .Br':{
            margin:'0 0 36px 0',
            [theme.breakpoints.down(1161)]:{
                margin:'0 0 20px 0',
            },
            [theme.breakpoints.down(502)]:{
                margin:'0 0 10px 0',
            },
        },
        '& .Esp':{
            margin:'53px 0 0 0',
            position:'absolute',
            width:'739px',
            [theme.breakpoints.down(1161)]:{
                margin:'43px 0 0 0',
                width:'83.46%',
            },
            [theme.breakpoints.down(502)]:{
                margin:' 25px 0 0 0',
            },
        },
        
        '& .Imagen':{
            [theme.breakpoints.down(1161)]:{
                width:'100%',
            },
        },
    },
}));


const La_hoja_mágica = (props) => {

  const {activeStep, setActiveStep, handlePreviewStep, id_actividad} = props

  const handleContinue = () => {
    setActiveStep(activeStep - 1)
    handlePreviewStep()
  }

    const {
    handleSize,
    disabledZoom,
    closeZoom,
    handleZoom,
    scale
  }= useContext(DrawerContext);
  const [loading, setLoading] = React.useState(true);
  const wrapperRef = React.useRef(null);
  const imagesLoaded = useOnLoadImages(wrapperRef);

  const detectSize = () => {
    const lectura = document.getElementById("lectura");
    const newWidth = lectura?.offsetWidth;
    const newHeight = lectura?.offsetHeight;
    setLoading(false);
    if (lectura) handleSize(newHeight, newWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", detectSize);
    return () => {
      handleZoom()
      window.removeEventListener("resize", detectSize);
      closeZoom()
    };
  }, []);

  const classes = useStyles({ disabledZoom,scale });
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
             <Grid container item justifyContent="center" className={classes.ContainerInfoLectura}>
              <Grid container className="BarraInfoLectura">
                <Button className="Regresar" onClick={handleContinue}>
                    Regresar
                </Button>
              </Grid>
              <Grid container item className="InfoLectura">
                  <Grid container item direction="column" xs={6} justifyContent="center">
                      <p className="Dato">Grado</p>
                      <p className="Caracter">3</p>
                  </Grid>
                  <Grid container item direction="column" xs={6} justifyContent="center" className="Sesion">
                      <p className="Dato">Sesión</p>
                      <p className="Caracter">13</p>
                  </Grid>
              </Grid>
            </Grid>
            {!imagesLoaded && <Loader />}
        <Grid
            container
            item
            className={classes.ContenedorLectura}
            justifyContent="center"
            id="lectura"
            onLoad={detectSize}
            ref={wrapperRef}
        >
<Drawer id_lectura={id_actividad} />
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >
                    La hoja mágica
                    </h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container justifyContent='center' className='Cuadro' sm={12}>
                        <img className="Imagen Escritorio" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img3ro/Sesion13/2.png" alt="Tarjeta" />
                        <img className="Imagen Tablet" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img3ro/Sesion13/2R.png" alt="Tarjeta" />
                        <p className='Lectura Esp'>
                            Querida Sonia: <div className='Br'></div>
                            El monstruo cometrigo le obsequia esta hoja mágica. Acérquela a su juguete 
                            favorito y verá que cobra vida. <div className='Br'></div>
                            
                            Toda magia debe emplearse con cuidado. Úsela bajo su propio riesgo.
                        </p>
                    </Grid>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container sm={12}>
                      <p className="Lectura"> 
                        Sonia no lo podía creer. ¡Por fin su muñeca Juana podría hablar y moverse! <br/><br/>
                        
                        Subió de prisa a su recámara y colocó la hoja mágica en la frente de la muñeca. 
                        Cuando quiso tomarla del brazo para cargarla, sus dedos se hundieron. “¡Su piel es
                        blanda!”, advirtió. Sus párpados se movieron solos, sin que estuviera acostada o de
                        pie, y su boca se abrió para decir: <br/><br/>
                        
                        —¿Quién eres tú? <br/><br/>
                        
                        Sonia se incomodó. ¡Cómo que no sabía quién era ella! <br/><br/>
                        
                        —Soy Sonia, tu mejor amiga. <br/><br/>
                        
                        La muñeca dijo que no recordaba nada, ni siquiera su propio nombre. <br/><br/>
                        
                        —Te llamas Juana y eres mi muñeca.<br/><br/>
                        
                        —¿Juana? ¡Qué nombre tan feo! —exclamó—. Preferiría Charlotte. ¿Y dices que soy 
                        tu muñeca? Entonces tú tienes la culpa de que tenga este nombre tan horrible. <br/><br/>
                        
                        Sonia le propuso que ella eligiera su nombre.<br/><br/>
                        
                        —Llámame Charlotte.
                      </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <Grid container justifyContent="center" alignItems='flex-end'>
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img3ro/Sesion13/1.png" alt="Tarjeta" />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container sm={12}>
                      <p className="Lectura"> 
                        A la hora de dormir, Charlotte pidió la cama para ella sola. Sonia pasó muy mal la 
                        noche, porque el piso era incómodo, pero sobre todo porque deseaba que Charlotte 
                        volviera a ser Juana, la amiga que escuchaba sus anécdotas y no le gritaba. <br/><br/>
                        
                        A la mañana siguiente, Charlotte empezó a llorar pidiendo comida. Sonia salió de 
                        inmediato por unas galletas a la cocina. Abrió el paquete cuando regresó a su 
                        habitación y notó que las galletas tenían forma de estrella. De inmediato recordó dos 
                        cosas: que las estrellas cumplen deseos y que un monstruo cometrigo le regaló la 
                        hoja mágica.<br/><br/>
                        
                        Dejó que Charlotte se quedara en la cama y dedicó el día a recolectar todas las 
                        galletas de trigo que había en la casa. En la noche, las colocó en un plato acompañado 
                        de una nota que decía:
                      </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <Grid container item justifyContent='center' className='Cuadro'>
                            <img className="Imagen Escritorio" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img3ro/Sesion13/3.png" alt="Tarjeta" />
                            <img className="Imagen Tablet" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img3ro/Sesion13/3R.png" alt="Tarjeta" />
                            <p className='Lectura Esp'>
                                Querido monstruo cometrigo: <div className='Br'></div>

                                Gracias por la hoja mágica. Mi muñeca Juana (bueno, Chalotte) tiene vida,
                                pero creo que la prefería cuando era más educada. ¿Cómo puede volver a ser 
                                la de antes?  <div className='Br'></div>
                                
                                Prometo darle más galletas.
                            </p>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container sm={12}>
                      <p className="Lectura"> 
                        A la mañana siguiente, Sonia corrió a la puerta y en una envoltura de galletas 
                        encontró unos garabatos que decían: “Querida Sonia: sólo tiene que acercarle de 
                        nuevo la hoja mágica. Gracias por las galletas”.<br/><br/>
                        
                        Entonces, Sonia salió corriendo a su habitación y sin despertar a Charlotte le puso la 
                        hoja mágica en la frente. Esperó unos segundos y la llamó: <br/><br/>
                        
                        —Charlotte… Juana… —pero no obtuvo respuesta. <br/><br/>
                        
                        La muñeca sólo abrió los ojos cuando Sonia la levantó. Llena de contento, dijo: <br/><br/>
                        
                        —¡Juana, has regresado!<br/><br/>
                        
                        Esa noche, Sonia volvió a poner galletas para el monstruo cometrigo y prometió no 
                        volver a confiar en ningún regalo misterioso que apareciera en su puerta.
                      </p>
                    </Grid>
                </Grid>
            </Grid>
        <Grid container item style={{ marginTop: 80}} ></Grid>
        </Grid>
    );
}
 
export default La_hoja_mágica;
