import { Button, Grid, makeStyles } from "@material-ui/core";
import React, { useContext, useEffect } from "react";
import DrawerContext from "../../../../../usability/DrawerContext";
import { useOnLoadImages } from "../../../../hooks/useOnLoadImages";
import Loader from "../../../../Loader/Loader";
import Drawer from "../../ReadingTools/Drawer";

const useStyles =  makeStyles (theme =>({
      Contenedor:{
        background:'#E9FBF9',
        width:'100vw',
        overflowX:'clip',
        [theme.breakpoints.down(1161)]:{
            background:'none',
           overflowX:'unset',
        },
        [theme.breakpoints.down(600)]:{
            marginTop: '79.51px',
        }
    },
  ContainerInfoLectura: {
    position: "relative",
    height: "86px",
    [theme.breakpoints.down(502)]:{
        height: "43px",
    },
    background: "#76D4C3",
    "& .BarraInfoLectura": {
      height: "86px",
      background: `Url(https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Barras/Azul.png)`,
        [theme.breakpoints.down(502)]:{
            height: "43px",
        },
    },
    "& .InfoLectura": {
      position: "absolute",
      height: "86px",
      width: "219px",
      boxSizing: "border-box",
      padding: "17.2px 0",
      background: "#FAFAFA",
      borderRadius: "50px",
      boxShadow: "2px 2px 2px 2px #00000029",
      zIndex: "4",
        [theme.breakpoints.down(502)]:{
            height: "43px",
            width: "109px",
            padding: "8.6px 0",
            borderRadius: "25px",
        },
    },
    "& .InfoLectura:before": {
      position: "absolute",
      display: "flex",
      boxSizing: "border-box",
      padding: "0 0 0 10px",
      justifyContent: "center",
      alignItems: "center",
      height: "39.88px",
      width: "39.88px",
      borderRadius: "50%",
      content: '""',
      boxShadow: "3px 0px .5px 0px #00000029",
      background: "#FAFAFA",
      left: "90px",
      top: "65px",
      transform: "rotate(0.25turn)",
      [theme.breakpoints.down(502)]:{
        left: "45px",
        top: "30px",
        height: "20.88px",
        width: "20.88px",
        },
    },
    "& .InfoLectura:after": {
      content: '""',
      height: "15px",
      width: "15px",
      borderTop: "2px solid #20A698",
      borderLeft: "2px solid #20A698",
      position: "absolute",
      bottom: "-10px",
      left: "Calc(50% - 8px)",
      transform: "rotate(0.625turn)",
      [theme.breakpoints.down(502)]:{
        height: "10px",
        width: "10px",
        left: "Calc(50% - 4.5px)",
        bottom: "-3px",
        borderTop: "1px solid #20A698",
        borderLeft: "1px solid #20A698",
        },
    },
    "& .Sesion": {
      borderLeft: "2px solid #D8D8D8",
    },
    "& .Dato": {
      margin: "0 0 5px 0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#20A698",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        margin: "0 0 2.5px 0",
        },
    },
    "& .Caracter": {
      margin: "0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#000000",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        },
    },
       '& .Regresar':{
        color: '#F2624F',
        border: '#F2624F 1px solid',
        height: '30px',
        width:'100px',
        borderRadius: '20px',
        letterSpacing: '0.14px',
        textTransform: 'none',
        background:'#FEFAF1',
        top:'calc(50% - 15px)',
        left:'5%',
        zIndex:4,
        [theme.breakpoints.down(502)]: {
          font:'bold 10px lato',
          width: "60px",
        },
      },
  },
    ContenedorLectura:{
        background:'#fff',
        width:'1160px',
        height:'100%',
        padding:'57px 168px 35px' ,
        
        transform: (props) =>
        props.scale > 1 ? `scale(${props.scale}) translate(0px, 10%)` : `none`,
        [theme.breakpoints.down(1161)]:{
            width:'502px',
            padding: '0',
        },
        [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
        '& .ContainerDerechos':{
            bottom:'80px',
            [theme.breakpoints.down(1161)]:{
                display:'none',
            }
        },
        '& .Derechos':{
            textAlign:"center",
            margin:"0px",
            font:" 15px lato",
            color:"#717476",
        },
        '& .Escritorio':{
            display:'block',
            [theme.breakpoints.down(1161)]:{
                display:'none'
            },
        },
        '& .Tablet':{
            display:'none',
            [theme.breakpoints.down(1161)]:{
                display:'block'
            },
        },
    },
    ContainerTitulo:{
        margin:'0 0 45px 0',
        [theme.breakpoints.down(1161)]:{
            margin:'0 0 38px 0',
        },
        '& .Titulo':{
            font:'48px fredoka one',
            letterSpacing: '0px',
            color: '#FF0000',
            margin:'0',
            textAlign:'center',
            [theme.breakpoints.down(1161)]:{
                font:'43px fredoka one',
                lineHeight:'52px',
                textAlign:'center',
            },
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            lineHeight : '30px',
            margin:'0 0 36px 0 ',
            font:'22px lato',
            color:'#000',
            letterSpacing: '0.20px',
            [theme.breakpoints.down(1161)]:{
                font:'18px lato',
                letterSpacing:'.17px',
                lineHeight:'28px',
            },
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .SubTitulo':{
            font:'bold 30px lato',
            margin:'0',
            letterSpacing:'.28px',
            lineHeight:'30px',
            color:'#20A698',
            [theme.breakpoints.down(1161)]:{
                font:'bold 26px lato',
                letterSpacing:'.24px',
                lineHeight:'28px',
            },
            [theme.breakpoints.down(502)]:{
                font:'bold 22px lato',
                lineHeight:'24px',
            },
            [theme.breakpoints.down(350)]:{
                font:'bold 18px lato',
                lineHeight:'24px',
            },
        },
        '& .TextImg':{
            margin:'24px 0 0 0',
            font:'20px lato',
            alignSelf:'flex-start',
            color:'#717476',
            letterSpacing:'.19px',
            [theme.breakpoints.down(1161)]:{
                font:'16px lato',
                letterSpacing:'.15px',
                lineHeight:'25px',
            },
            [theme.breakpoints.down(502)]:{
                font:' 11px lato',
                lineHeight :'14px',
                letterSpacing: '0.10px',
            },
        },
        '& .Imagen':{
            [theme.breakpoints.down(1161)]:{
                width:'100%',
            },
        }

    },

}));

const Alquimia = (props) => {
    const {activeStep, setActiveStep, handlePreviewStep, id_actividad} = props

    const handleContinue = () => {
        setActiveStep(activeStep - 1)
        handlePreviewStep()
      }
  
      const {
      handleSize,
      disabledZoom,
      closeZoom,
      handleZoom,
     scale
    } = useContext(DrawerContext);
    const [loading, setLoading] = React.useState(true);
    const wrapperRef = React.useRef(null);
    const imagesLoaded = useOnLoadImages(wrapperRef);
  
    const detectSize = () => {
      const lectura = document.getElementById("lectura");
      const newWidth = lectura?.offsetWidth;
      const newHeight = lectura?.offsetHeight;
      setLoading(false);
      if (lectura) handleSize(newHeight, newWidth);
    };
    useEffect(() => {
      window.addEventListener("resize", detectSize);
      return () => {
        handleZoom()
        window.removeEventListener("resize", detectSize);
        closeZoom()
      };
    }, []);
  
    const classes = useStyles({ disabledZoom,scale });

    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
             <Grid container item justifyContent="center" className={classes.ContainerInfoLectura}>
               <Grid container className="BarraInfoLectura">
                    <Button className="Regresar" onClick={handleContinue}>
                        Regresar
                    </Button>
                </Grid>
               <Grid container item className="InfoLectura">
                    <Grid container item direction="column" xs={6} justifyContent="center">
                        <p className="Dato">Grado</p>
                        <p className="Caracter">6</p>
                    </Grid>
                    <Grid container item direction="column" xs={6} justifyContent="center" className="Sesion">
                        <p className="Dato">Sesión</p>
                        <p className="Caracter">44</p>
                    </Grid>
               </Grid>
            </Grid>
{!imagesLoaded && <Loader />}
        <Grid
            container
            item
            className={classes.ContenedorLectura}
            justifyContent="center"
            id="lectura"
            onLoad={detectSize}
            ref={wrapperRef}
        >
<Drawer id_lectura={id_actividad} />
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >
                        Alquimia
                    </h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                            A lo largo del siglo <span style={{fontVariant: "all-small-caps"}}>XVII</span>,  la humanidad fue testigo de grandes avances científicos.  
                            Para finales de ese periodo, en el continente europeo ya se conocía el microscopio, la 
                            electricidad y el telescopio. Además, habían nacido científicos renombrados como 
                            Galileo Galilei e Isaac Newton. <br/><br/>
                            
                            Pero la ciencia apenas estaba consolidando sus primeros cimientos. Para llegar hasta 
                            donde se encuentra hoy, la alquimia fue de suma importancia, por ejemplo, para el 
                            desarrollo de la química. El objetivo principal de la alquimia era purificar y 
                            perfeccionar ciertos materiales. Los alquimistas se empeñaban en crear un elíxir que 
                            permitiera la inmortalidad o en descubrir la piedra filosofal. Ésta era una sustancia 
                            imaginaria que se creía que era capaz de rejuvenecer a su portador y que también 
                            podía convertir en oro algunos metales como el hierro, el cobre y el plomo. <br/><br/>
                            
                            Pero a pesar de que los alquimistas tenían muchos propósitos, la creación de oro a 
                            partir de otros materiales siempre fue uno de los principales. Aunque no pudieron 
                            conseguirlo, en el proceso de búsqueda descubrieron otras sustancias químicas, 
                            como la pólvora, el arsénico, el fósforo y hasta la porcelana.
                        </p>
                    </Grid>

                    <Grid container  sm={12}>
                        <Grid container justifyContent="center" sm={12}>
                                <img className='Imagen' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion44/1.png"  alt="Tarjeta" />
                        </Grid>
                        <Grid container >
                            <p className='TextImg'>Aunque podría parecer magia, muchos alquimistas eran científicos que utilizaban métodos complejos.</p>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                            <h3 className='SubTitulo'>La alquimia y la porcelana</h3> <br/>

                            La porcelana era un material tan preciado y valioso como el oro, pero su composición 
                            y método de fabricación eran un misterio, puesto que sólo se fabricaba en China. Los 
                            monarcas de aquella época se volvieron locos por este material cuando llegó al 
                            continente europeo. Algunos incluso pensaban que era imposible envenenarse 
                            bebiendo de una taza de porcelana, por lo que muchos de ellos estaban empeñados 
                            en descubrir una manera de fabricarla. <br/><br/>
                            
                            En 1682, nació en Alemania Johann Friedrich Böttger, uno de los muchos alquimistas 
                            que perseguían con anhelo la creación de la piedra filosofal. En 1701, Böttger 
                            consiguió engañar a algunas personas “transformando” dos monedas de plata en dos 
                            monedas de oro. Evidentemente, esto sólo era un truco de magia, pero para los 
                            espectadores fue prueba indudable de que este joven de 19 años tenía el secreto 
                            para la riqueza inagotable. <br/><br/>
                            
                            Su éxito fue tal que el rey de aquellos tiempos, Federico I de Prusia, ordenó a sus 
                            discípulos que fueran en busca del joven alquimista para que hiciera oro para él.
                            Böttger logró escapar, pero al llegar a Dresden, en Alemania, fue apresado por
                            Augusto II de Polonia. El también llamado “Augusto, el fuerte” lo encerró en un
                            castillo y le ordenó hacer oro para él. <br/><br/>
                            
                            Pasaron casi siete años sin que Böttger fuera capaz de cumplir los deseos del rey, 
                            hasta que, con la ayuda del físico Ehrenfried Walther von Tschirnhaus, mandó 
                            construir un horno enorme en una montaña y experimentó con algunas mezclas. 
                            Finalmente, juntos encontraron la combinación perfecta de caolín, cuarzo y 
                            feldespato, creando así la porcelana roja y después la porcelana dura. De esta 
                            manera, Böttger pudo cubrir su deuda y salvar su vida.
                        </p>
                    </Grid>
                    <Grid container  sm={12}>
                            <Grid container>
                                <img  className='Imagen' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion44/2.png"  alt="Tarjeta" />
                            </Grid>
                            <Grid>
                                <p className='TextImg'>Los dos científicos tuvieron que experimentar arduamente hasta encontrar la mezcla 
                                perfecta.</p>
                            </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                            Aliviados tras haber logrado replicar la tan valiosa porcelana china, le comunicaron 
                            su descubrimiento al rey. Éste los felicitó y se dispuso a crear la primera fábrica de
                            porcelana en toda Europa. Böttger albergaba la esperanza de que su éxito le 
                            asegurara su libertad. Desafortunadamente, con la repentina muerte de von 
                            Tschirnhaus, Böttger era todavía más valioso para el rey, ya que era el único que 
                            conocía la fórmula para la fabricación de la porcelana. Así, este joven alquimista 
                            estuvo encarcelado hasta 1714, cinco años antes de su muerte. <br/><br/>
                            
                            A pesar de los esfuerzos del rey Augusto II por conservar en secreto la fórmula, ésta 
                            terminó por filtrarse. A pesar de ello, la fábrica que el rey construyó fue la primera en 
                            comercializar porcelana en Europa. Durante muchos años, Augusto II continuó 
                            fabricando porcelana e incluso construyó un palacio que sigue en pie, llamado <em>El 
                            Palacio Japonés</em>, donde guardó su gigantesca colección de figuras de porcelana.

                        </p>
                    </Grid>
                </Grid>
        </Grid>
        <Grid container item style={{ marginTop: 80}} ></Grid>
    </Grid>
    );
}
 
export default Alquimia;
