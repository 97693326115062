import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
    ContenedorLectura:{
        width:'502px',
        [theme.breakpoints.down(502)]:{
            width:'350px',
        },
    },

    ContainerTitulo:{
        margin:'0 0 38px 0',
        '& .Titulo':{
            letterSpacing: '0px',
            color: '#F05235',
            margin:'0',
            font:'43px fredoka one',
            lineHeight:'52px',
            textAlign:'center',
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            margin:'0 0 36px 0 ',
            color:'#000',
            font:'18px lato',
            letterSpacing:'.15px',
            lineHeight:'28px',
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .Lectura2':{
            margin:'25px 0 0 0',
        },
        '& .TextImg':{
            margin:'24px 0 0 0',
            alignSelf:'flex-start',
            color:'#717476',
            font:'16px lato',
            letterSpacing:'.15px',
            lineHeight:'25px',
            [theme.breakpoints.down(502)]:{
                font:' 11px lato',
                lineHeight :'14px',
                letterSpacing: '0.10px',
            },
        },
        '& .Cuadros':{
            position:'relative',
        },
        '& .Pos':{
            position:'absolute',
        },
        '& .Naranja':{
            left:'258px',
            [theme.breakpoints.down(502)]:{
                left:'196px',
            },
            [theme.breakpoints.down(350)]:{
                left:'165px',
            },
        },
        '& .SubTitulo':{
            font:'23px Fredoka One',
            color:'#FFFFFF',
            margin:'0',
            textAlign:'center',
            width:'258px',
            top:'2px',
            [theme.breakpoints.down(502)]:{
                font:'16px Fredoka One',
                lineHeight:'15px',
                width:'158px',
                top:'4px',
            },
            [theme.breakpoints.down(350)]:{
                font:'14px Fredoka One',
                lineHeight:'14px',
                width:'139px',
                top:'2px',
            },
        },

        '& .UlAzul':{
            margin:'0',
            padding:'0',
            color:'#0CB5D6',
            listStyle:'alphanumeric',
            font:'bold 18px lato',
            top:'65px',
            left:'40px',
            [theme.breakpoints.down(502)]:{
                font:'bold 14px lato',
                top:'40px',
                left:'30px',
            },
            [theme.breakpoints.down(350)]:{
                font:'bold 12px lato',
                top:'37px',
                left:'20px',
            },
        },

        '& .UlNaranja':{
            margin:'0',
            font:'bold 18px lato',
            top:'65px',
            left:'285px',
            width:'194px',
            textAlign:'center',
            [theme.breakpoints.down(502)]:{
                font:'bold 14px lato',
                top:'40px',
                width:'157px',
                left:'197px',
            },
            [theme.breakpoints.down(350)]:{
                font:'bold 12px lato',
                top:'40px',
                left: '197px',
                width: '77px',
            },
        },
        '& .Text':{
            font:'20px lato',
            letterSpacing:'.19px',
            color:'#000000',
            margin:'0 0 7px 0',
            [theme.breakpoints.down(502)]:{
                font:'14px lato',
                margin:'0 0 4px 0',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                margin:'0 0 2px 0',
            },
        },
        '& .SubTitulo2':{
            color:'#FF9800',
            font:'22px Fredoka One',
            margin:'0 0 37px 0',
            [theme.breakpoints.down(502)]:{
                font:'18px Fredoka One',
                margin:'0 0 27px 0',
            },
            [theme.breakpoints.down(350)]:{
                font:'16px Fredoka One',
                margin:'0 0 25px 0',
            },
        },


        '& .Imagen':{
            [theme.breakpoints.down(502)]:{
                width:'45%',
            },
        },
        '& .Imagen2':{
            width:'100%',
        },
    },
    

}));

const Litio_la_nueva_promesa_sustentable = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >
                        Litio: la nueva promesa sustentable
                    </h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura ">
                            La humanidad se encuentra en un punto crítico de su historia. Entre otras cosas, se 
                            enfrenta a dos problemas urgentes de resolver: el calentamiento global y el 
                            agotamiento de los recursos no renovables, como los combustibles fósiles y el agua. 
                            El problema de ello es que la vida y los medios para vivirla están basados hasta ahora 
                            en el consumo irresponsable de dichos recursos. Lo más grave es que algunos de 
                            ellos, como los combustibles, son además contaminantes. <br/><br/>
                            
                            Piensa, por ejemplo, en la luz de tu casa, en las baterías de los equipos móviles o en el 
                            combustible que se utiliza para que se mueva todo tipo de vehículos. La mayoría de 
                            esta energía se genera gracias a la combustión de productos fósiles no renovables, 
                            como el petróleo. Entonces no es tan sencillo simplemente reducir su consumo. Se 
                            deben encontrar opciones que los sustituyan y que sean amigables con el ambiente.<br/><br/>
                            
                            Dentro de la búsqueda de soluciones hay una que parece prometedora: el uso de 
                            baterías. Con ellas se dejarían de utilizar, por ejemplo, combustibles para los autos. 
                            Por ello cada vez es más común escuchar y ver automóviles híbridos o eléctricos. <br/><br/>
                            
                            Un automóvil híbrido o eléctrico es aquel que utiliza baterías para sustituir en parte 
                            o en su totalidad el uso de combustible. Estas baterías están hechas, entre otros 
                            elementos, de grafeno, cobalto o litio. En este texto aprenderás sobre el litio. <br/><br/>
                            
                            El <em>litio</em> es el metal más liviano del planeta y además es moderadamente abundante. 
                            Debido a sus propiedades para generar calor se ha convertido en uno de los metales 
                            más codiciados del mundo, pues se le han encontrado múltiples aplicaciones en la 
                            generación de baterías. No solamente en las automotrices, sino también en las de 
                            teléfonos móviles, computadoras, tabletas, etcétera. <br/><br/>
                            
                            Las reservas más grandes de litio en el mundo se concentran en seis países, aunque el 
                            30 % lo posee Bolivia. Observa la tabla de distribución:
                        </p>
                    </Grid>
                    <Grid container justifyContent="space-between" className='Cuadros' sm={12}>
                            <img className='Imagen ' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion30/1R.png" alt="Tarjeta" />
                            <img className='Imagen ' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion30/2R.png" alt="Tarjeta" />

                            <p className='Pos SubTitulo  '>Países con <br/>reservas de litio</p>
                            <p className='Pos SubTitulo Naranja '>Reservas <br/>en toneladas</p>

                            <ul className='Pos UlAzul'>
                                <li><p className='Text'>Bolivia</p></li>
                                <li><p className='Text'>Argentina</p></li>
                                <li><p className='Text'>Chile</p></li>
                                <li><p className='Text'>Estados Unidos</p></li>
                                <li><p className='Text'>Australia</p></li>
                                <li><p className='Text'>China</p></li>
                            </ul>   

                            <p className='Pos UlNaranja '>
                                <p className='Text'>21 millones</p>
                                <p className='Text'>17 millones</p>
                                <p className='Text'>9 millones</p>
                                <p className='Text'>6.8 millones</p>
                                <p className='Text'>6.3 millones</p>
                                <p className='Text'>5 millones</p>
                            </p>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container justifyContent="center" sm={12}>
                
                        <h3 className='SubTitulo2'>Recursos mundiales de litio por país</h3> 

                    </Grid>
                    <Grid container  sm={12}>
                        <img className="Imagen2" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion30/3.png" alt="Tarjeta" />
                        <Grid container>
                            <p className='Lectura Lectura2'> 
                                *Países del “Triángulo del litio”.<br/>
                                Explican el conjunto el 68% del total
                            </p>
                        </Grid>
                        
                        <Grid container justifyContent='center'>
                            <p className='TextImg '>
                                Fuente: Zicari, Fornillo y Gamba, 2019. (Con información del Ministerio de Energía y 
                                Minería de Argentina, 2017).
                            </p>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura ">
                            Todo luce muy bien en este panorama. Sin embargo su extracción dista mucho de ser 
                            sustentable, limpia y amigable con el ambiente, pues para hacerlo se requiere utilizar 
                            una inmensa cantidad de agua. Y como ya leíste al inicio de este texto, la falta de agua 
                            es otro de los grandes problemas que enfrenta la humanidad. <br/><br/>
                            
                            Para extraer una tonelada de litio se requieren aproximadamente 2 000 litros de 
                            agua, lo que casi equivale a una alberca olímpica. Pero una tonelada es insuficiente 
                            para satisfacer la demanda mundial. En realidad los consorcios extraen 
                            aproximadamente entre 17 000 y 30 000 toneladas diarias del metal, para las cuales 
                            utilizan alrededor de 226 millones de litros de agua, es decir, aproximadamente la 
                            capacidad de 500 albercas olímpicas.
                        </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <Grid container >
                            <img className='Imagen2' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion30/4.png" alt="Tarjeta" />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura ">
                            Debido a la cantidad de agua utilizada se produce un desequilibro en los 
                            ecosistemas, ya que se afectan las reservas de agua. Entonces no se puede hablar de 
                            un tipo de minería convencional como la del cobre o el oro, que no requieren tanta 
                            agua. Más bien se trata de una minería del agua, la cual no es posible recuperar. <br/><br/>
                            
                            Más que un beneficio, la extracción del litio representa una gran desventaja para los 
                            pobladores de las zonas cercanas a los sitios donde se concentra el metal, pues ellos 
                            viven de la producción de sal. <br/><br/>
                            
                            Los pueblos originarios que habitan en los tres principales países con reservas de 
                            litio, Argentina, Chile y Bolivia, visualizan la producción de litio como una amenaza 
                            más que como una promesa de prosperidad para el futuro. <br/><br/>
                            
                            Ante este panorama han surgido voces de activistas por la protección de los 
                            ecosistemas. Ellos expresan que la tierra es nuestra madre, a quien se debe respetar, 
                            proteger y valorar, pues es la que permite la vida. <br/><br/>
                            
                            Entonces, cabe hacer la siguiente pregunta: ¿el litio puede ser la gran promesa verde 
                            para disminuir el calentamiento global o es otra gran equivocación que a futuro 
                            costará muy caro para la supervivencia de la humanidad?
                        </p>
                    </Grid>
                </Grid>
        </Grid>
    </Grid>
    );
}
 
export default Litio_la_nueva_promesa_sustentable;
