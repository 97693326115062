import React                                        from "react";
import Grid             from '@material-ui/core/Grid'
import { makeStyles}    from '@material-ui/core';
import WelcomePage      from '../welcome/WelcomePage';


const useStyles = makeStyles(theme =>({
  grid2Left:{
    position:'absolute', 
    paddingRight:'41px',
    [theme.breakpoints.down('md')]:{
      paddingRight:'0px',
    }
  } 
}))


function ContenedorWelcomePage() {
  const classes= useStyles()
  return (
      <Grid container >
          <Grid container item  className={classes.grid2} sm={12}>
            
              <WelcomePage/>

          </Grid>

      </Grid>
  );
}

export default ContenedorWelcomePage;
