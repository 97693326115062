import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
   ContenedorLectura:{
        width:'502px',
         [theme.breakpoints.down(502)]:{
           width:'350px',
         },
   },
    ContainerTitulo:{
        margin:'0 0 46px 0',
        '& .Titulo':{
            letterSpacing: '0px',
            color: '#926DD1',
            margin:'0',
            textAlign:'center',
            font:'43px fredoka one',
            lineHeight:'52px',
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            margin:'0 0 36px 0 ',
            color:'#000',
            font:'18px lato',
            lineHeight :'28px',
            letterSpacing: '0.17px',
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .SubTitulo':{
            color:'#FF0000',
            margin:'0',
            font:'bold 22px lato',
            lineHeight :'24px',
            letterSpacing: '0.21px',
            [theme.breakpoints.down(502)]:{
                font:'bold 18px lato',
                lineHeight :'20px',
                letterSpacing: '0.16px',
            },
        },
        '& .SubTitulo2':{
            margin:'0',
            color:'#FC5E9E',
            font: "bold 22px lato",
            lineHeight: "24px",
            letterSpacing: "0.21px",
            [theme.breakpoints.down(502)]: {
                font: "bold 18px lato",
                lineHeight: "20px",
                letterSpacing: "0.16px",
            },
        },
        '& .Esp':{
            margin:'0'
        },
        '& .Esp2':{
            margin:'0 0 36px 0',
        },
       '& .TextImg':{
            margin:'24px 0 0 0',
            alignSelf:'flex-start',
            color:'#717476',
            font:' 16px lato',
            lineHeight :'25px',
            letterSpacing: '0.15px',
            [theme.breakpoints.down(502)]:{
                font:' 11px lato',
                lineHeight :'14px',
                letterSpacing: '0.10px',
            },
        },

        '& .Imagen':{
            width:'100%',
        },
        '& .Imagen2':{
            width:'100%',
            height:'492px',
            [theme.breakpoints.down(502)]:{
                height:'342px',
            },
        },
        '& .Link':{
            textDecoration:'none',
        },
    },
    

}));

const Declaración_Universal_de_los_Derechos_Humanos = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>

            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >
                        Declaración<br/> Universal de los <br/>Derechos Humanos
                    </h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                            ¿Sabías que existe un libro que contiene todos los derechos de las personas en el 
                            mundo? Es la Declaración Universal de los Derechos Humanos. Fue elaborada en 
                            1948 por representantes de casi todos los países en una asamblea de la 
                            Organización de las Naciones Unidas.<br/><br/> 
                            
                            Dicho documento establece que “Todos los seres humanos nacen libres e iguales en 
                            dignidad y derechos…” Esto significa que son capaces de elegir qué hacer, decir y 
                            pensar sólo por haber nacido. También que merecen ser tratados justa y 
                            respetuosamente, ya que cuentan con leyes que los protegen.
                        </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img3ro/Sesion36/1.png"  alt="Tarjeta" />
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura Esp">
                           <h3 className='SubTitulo'>Su origen</h3><br/>
                           
                            ¿Conoces todos tus derechos? ¿Sabías que otros niños antes de ti no los tuvieron? En 
                            la Antigüedad estaba permitido que los sujetos con poder fueran dueños de otros. La 
                            idea de que los seres humanos tienen necesidades, deseos y dudas no era común.<br/><br/>

                            Pero esta situación comenzó a cambiar en Europa a partir del Renacimiento. Los 
                            intelectuales de esa época consideraron al ser humano como el creador de la cultura.
                            Distintos pensadores construyeron grandes civilizaciones por medio del arte y la 
                            ciencia. A partir de esto surgieron distintos movimientos que prohibieron la 
                            esclavitud en la mayoría de los países durante los siglos <span style={{fontVariant:"all-small-caps"}}>XVIII</span> y <span style={{fontVariant:"all-small-caps"}}>XIX</span>.<br/><br/>
                            
                            Como resultado, surgieron dos de los derechos individuales más importantes que 
                            hoy aparecen en la mayoría de las constituciones políticas: el derecho a la identidad y 
                            al libre desarrollo de la personalidad.<br/><br/>  

                            <h3 className='SubTitulo2'>Identidad y libre desarrollo de la personalidad</h3>
                        </p>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container xs={12} >
                        <p className="Lectura Esp2">
                            
                            El <em>derecho</em> a la <em>identidad</em> otorga la oportunidad 
                            de poseer un nombre y una nacionalidad al 
                            nacer. Así lo explica el artículo 7 de la 
                            Convención sobre los Derechos del Niño: “El 
                            niño será registrado inmediatamente después 
                            de su nacimiento y tendrá derecho desde que 
                            nace a un nombre, a adquirir una nacionalidad 
                            y, en la medida de lo posible, a conocer a sus 
                            padres y a ser cuidado por ellos”.<br/><br/> 
                            
                            Con ello se comprueba que formas parte de 
                            una sociedad, que cuentas con derechos y 
                            obligaciones y que tienes acceso a los 
                            servicios que ofrece tu país para desarrollarte 
                            sanamente.
                        </p>
                    </Grid>
                    <Grid container xs={12} >
                        <img className="Imagen2" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img3ro/Sesion36/2.png"  alt="Tarjeta" />
                    </Grid>
                
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container xs={12} >
                        <p className="Lectura Esp">
                            Por su parte, el <em>derecho al libre desarrollo de la personalidad</em> comunica que todo 
                            individuo es capaz de hacer o dejar de hacer lo que desee. Siempre y cuando se 
                            mantenga dentro de la ley y respete a los demás. Puedes elegir tus pasatiempos, 
                            gustos y sueños gracias a él. Es importante cuidar que dichas decisiones no afecten a 
                            nadie.<br/><br/> 
                            
                            ¿Te gustaría conocer más sobre tus derechos? Te invitamos a leer al respecto en:
                        </p>
                        <p className='TextImg'>
                            <span style={{fontVariant:"all-small-caps"}}>UNICEF</span>/Quino, “10 derechos fundamentales de los niños, por Quino. <em>A 30 años de la 
                            Convención sobre los Derechos del Niño</em>”, <span style={{fontVariant:"all-small-caps"}}>UNICEF</span> América Latina y el Caribe. <a className='Link' href="https://www.unicef.org/lac/historias/10-derechos-fundamentales-de-los-ni%C3%B1os-por-quino" target="_blank" rel="noreferrer">https://
                            www.unicef.org/lac/historias/10-derechos-fundamentales-de-los-niños-por-
                            quinopor-quino</a>(Consultado el 21-9-2021)
                        </p>
                    </Grid>
                </Grid>
        </Grid>
    </Grid>
    );
}
 
export default Declaración_Universal_de_los_Derechos_Humanos;
