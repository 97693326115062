import { Button, Grid, makeStyles } from "@material-ui/core";
import React, { useContext, useEffect } from "react";
import DrawerContext from "../../../../../usability/DrawerContext";
import { useOnLoadImages } from "../../../../hooks/useOnLoadImages";
import Loader from "../../../../Loader/Loader";
import Drawer from "../../ReadingTools/Drawer";

const useStyles =  makeStyles (theme =>({
    Contenedor:{
       background:'#E9FBF9',
       width:'100vw',
       [theme.breakpoints.down(1161)]:{
           background:'none',
       },
       [theme.breakpoints.down(600)]:{
           marginTop: '79.51px',
       }
   },
  ContainerInfoLectura: {
    position: "relative",
    height: "86px",
    [theme.breakpoints.down(590)]:{
        height: "43px",
    },
    background: "#76D4C3",
    "& .BarraInfoLectura": {
      height: "86px",
      background: `Url(https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Barras/Azul.png)`,
        [theme.breakpoints.down(590)]:{
            height: "43px",
        },
    },
    "& .InfoLectura": {
      position: "absolute",
      height: "86px",
      width: "219px",
      boxSizing: "border-box",
      padding: "17.2px 0",
      background: "#FAFAFA",
      borderRadius: "50px",
      boxShadow: "2px 2px 2px 2px #00000029",
      zIndex: "4",
        [theme.breakpoints.down(590)]:{
            height: "43px",
            width: "109px",
            padding: "8.6px 0",
            borderRadius: "25px",
        },
    },
    "& .InfoLectura:before": {
      position: "absolute",
      display: "flex",
      boxSizing: "border-box",
      padding: "0 0 0 10px",
      justifyContent: "center",
      alignItems: "center",
      height: "39.88px",
      width: "39.88px",
      borderRadius: "50%",
      content: '""',
      boxShadow: "3px 0px .5px 0px #00000029",
      background: "#FAFAFA",
      left: "90px",
      top: "65px",
      transform: "rotate(0.25turn)",
      [theme.breakpoints.down(590)]:{
        left: "45px",
        top: "30px",
        height: "20.88px",
        width: "20.88px",
        },
    },
    "& .InfoLectura:after": {
      content: '""',
      height: "15px",
      width: "15px",
      borderTop: "2px solid #20A698",
      borderLeft: "2px solid #20A698",
      position: "absolute",
      bottom: "-10px",
      left: "Calc(50% - 8px)",
      transform: "rotate(0.625turn)",
      [theme.breakpoints.down(590)]:{
        height: "10px",
        width: "10px",
        left: "Calc(50% - 4.5px)",
        bottom: "-3px",
        borderTop: "1px solid #20A698",
        borderLeft: "1px solid #20A698",
        },
    },
    "& .Sesion": {
      borderLeft: "2px solid #D8D8D8",
    },
    "& .Dato": {
      margin: "0 0 5px 0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#20A698",
      [theme.breakpoints.down(590)]:{
        font: "bold 12px lato",
        margin: "0 0 2.5px 0",
        },
    },
    "& .Caracter": {
      margin: "0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#000000",
      [theme.breakpoints.down(590)]:{
        font: "bold 12px lato",
        },
    },
       '& .Regresar':{
        color: '#F2624F',
        border: '#F2624F 1px solid',
        height: '30px',
        width:'100px',
        borderRadius: '20px',
        letterSpacing: '0.14px',
        textTransform: 'none',
        background:'#FEFAF1',
        top:'calc(50% - 15px)',
        left:'5%',
        zIndex:4,
        [theme.breakpoints.down(590)]: {
          font:'bold 10px lato',
          width: "60px",
        },
      },
  },
   ContenedorLectura:{
       background:'#fff',
       width:'1160px',
       height:'100%',
       padding:'60px 168px 35px ',
        
        transform: (props) =>
        props.scale > 1 ? `scale(${props.scale}) translate(0px, 10%)` : `none`,
       [theme.breakpoints.down(1161)]:{
           width:'590px',
           padding:'60px 0 0 0',
         },
        [theme.breakpoints.down(590)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
       '& .ContainerDerechos':{
           bottom:'80px',
           [theme.breakpoints.down(1161)]:{
               display:'none',
           },
       },
       '& .Derechos':{
           textAlign:"center",
           margin:"0px",
           font:" 15px lato",
           color:"#717476",
       },
       '& .Escritorio':{
           display:'block',
           [theme.breakpoints.down(1161)]:{
               display:'none'
           },
       },
       '& .Tablet':{
           display:'none',
           [theme.breakpoints.down(1161)]:{
               display:'block'
           },
       }
   },
    ContainerTitulo:{
        margin:'0 0 46px 0',
        '& .Titulo':{
            letterSpacing: '0px',
            color: '#0650DD',
            margin:'0',
            textAlign:'center',
            font:'48px fredoka one',
            [theme.breakpoints.down(1161)]:{
                font:'43px fredoka one',
                lineHeight:'52px',
            },
            [theme.breakpoints.down(590)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        position:'relative',
        '& .Lectura':{
            margin:'0 0 36px 0 ',
            color:'#000',
            font:'22px lato',
            lineHeight : '30px',
            letterSpacing: '0.20px',
            width:'747px',
            [theme.breakpoints.down(1161)]:{
                font:'18px lato',
                lineHeight :'28px',
                letterSpacing: '0.17px',
                width:'498px',
            },
            [theme.breakpoints.down(590)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
                width:'298px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'13.8px',
                letterSpacing: '0.09px',
                width:'273px',
            },

        },
        '& .SubTitulo':{
            color:'#FF0000',
            textAlign:'center',
            margin:'0',
            font:'35px Fredoka One',
            letterSpacing:'.33px',
            lineHeight:'35px',
            [theme.breakpoints.down(1161)]:{
                font:'31px Fredoka One',
                lineHeight :'31px',
                letterSpacing: '0.29px',
            },
            [theme.breakpoints.down(590)]:{
                font:'18px Fredoka One',
                lineHeight :'20px',
                letterSpacing: '0.16px',
            },
            [theme.breakpoints.down(350)]:{
                font:'14px Fredoka One',
                lineHeight :'16px',
                letterSpacing: '0.16px',
            },
        },
        '& .Esp':{
            boxSizing:'border-box',
            padding:'0 0 0 116px',
            [theme.breakpoints.down(1161)]:{
                padding:'0 7% 0 22%',
            },
            [theme.breakpoints.down(350)]:{
                padding:'0 0% 0 21%',
            },
        },
        '& .Esp2':{
            textAlign:'left',
        },
       '& .TextImg':{
            margin:'0 0 36px 0',
            alignSelf:'flex-start',
            color:'#717476',
            font:'20px lato',
            letterSpacing:'.19px',
            lineHeight:'30px',
            textAlign:'center',
            [theme.breakpoints.down(1161)]:{
                textAlign:'left',
                font:' 16px lato',
                lineHeight :'25px',
                letterSpacing: '0.15px',
            },
            [theme.breakpoints.down(590)]:{
                margin:'0 0 15px 0',
                font:' 12px lato',
                lineHeight :'14px',
                letterSpacing: '0.15px',
            },
            [theme.breakpoints.down(350)]:{
                margin:'0 0 15px 0',
                font:' 10px lato',
                lineHeight :'12px',
                letterSpacing: '0.15px',
            },
        },
        '& .Pos':{
            position:'absolute',
            top:'55px',
            [theme.breakpoints.down(590)]:{
                top:'2.6%',
            },
        },
        '& .Pos2':{
            position:'absolute',
            top:'2398px',
            [theme.breakpoints.down(1161)]:{
                top:'2434px',
            },
            [theme.breakpoints.down(590)]:{
                top:'92.5%',
            },
        },
        '& .Imagen':{
            [theme.breakpoints.down(590)]:{
                width:'385px',
            },
            [theme.breakpoints.down(350)]:{
                width:'330px',
            },
        },
        
        
    },
    

}));

const Los_animales_también_sufren = (props) => {

  const {activeStep, setActiveStep, handlePreviewStep, id_actividad} = props
  
  const handleContinue = () => {
    setActiveStep(activeStep - 1)
    handlePreviewStep()
  }

  const {
    handleSize,
    disabledZoom,
    closeZoom,
    handleZoom,
    scale
  }= useContext(DrawerContext);
  const [loading, setLoading] = React.useState(true);
  const wrapperRef = React.useRef(null);
  const imagesLoaded = useOnLoadImages(wrapperRef);

  const detectSize = () => {
    const lectura = document.getElementById("lectura");
    const newWidth = lectura?.offsetWidth;
    const newHeight = lectura?.offsetHeight;
    setLoading(false);
    if (lectura) handleSize(newHeight, newWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", detectSize);
    return () => {
      handleZoom()
      window.removeEventListener("resize", detectSize);
      closeZoom()
    };
  }, []);

  const classes = useStyles({ disabledZoom,scale });
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
             <Grid container item justifyContent="center" className={classes.ContainerInfoLectura}>
                       <Grid container className="BarraInfoLectura">
            <Button className="Regresar" onClick={handleContinue}>
                Regresar
            </Button>
        </Grid>
               <Grid container item className="InfoLectura">
                    <Grid container item direction="column" xs={6} justifyContent="center">
                        <p className="Dato">Grado</p>
                        <p className="Caracter">4</p>
                    </Grid>
                    <Grid container item direction="column" xs={6} justifyContent="center" className="Sesion">
                        <p className="Dato">Sesión</p>
                        <p className="Caracter">36</p>
                    </Grid>
               </Grid>
            </Grid>
            {!imagesLoaded && <Loader />}
        <Grid
            container
            item
            className={classes.ContenedorLectura}
            justifyContent="center"
            id="lectura"
            onLoad={detectSize}
            ref={wrapperRef}
        >
<Drawer id_lectura={id_actividad} />
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >
                        Los animales<br className='Tablet'/> también sufren
                    </h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <img className="Imagen Escritorio" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img4to/Sesion36/1.png"  alt="Tarjeta" />
                    <img className="Imagen Tablet" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img4to/Sesion36/1R.png"  alt="Tarjeta" />
                    <Grid container item xs={12} justifyContent="center"  className='Pos'>
                        <p className='Lectura'>
                            <h3 className='SubTitulo'>
                                Voluntarios rescatan<br className='Tablet'/> a dos cachorros<br className='Escritorio'/> que<br className='Tablet'/> encontraron en la basura
                            </h3> <br/>
                            <div className='Esp Escritorio'>
                                os voluntarios que cuidan animales rescatados encontraron el 
                                pasado sábado a dos cachorros abandonados en un contenedor 
                                de basura. Fueron trasladados a instalaciones a cargo de su 
                                protección. Recibieron alimentos y limpieza, y permanecerán allí 
                                hasta que una familia quiera adoptarlos.
                            </div>

                            <div className='Esp Tablet'>
                                os voluntarios que cuidan animales rescatados encontraron el 
                                pasado sábado a dos cachorros abandonados en un contenedor 
                                de basura. Fueron trasladados a 
                            </div>
                            <div className='Tablet'>
                                instalaciones a cargo de su 
                                protección. Recibieron alimentos y limpieza, y permanecerán allí 
                                hasta que una familia quiera adoptarlos.
                            </div>

                            <br/>
                            Noticias como ésta son cada vez más frecuentes. Más de 50 % de los perros 
                            de todo el mundo son abandonados, según datos de la Organización Mundial 
                            de la Salud. El número de rescatados es mayor al número que logra conseguir 
                            un hogar, a pesar de que existen muchas organizaciones mundiales que 
                            tratan de salvarlos. Muchos de ellos reciben una mala calidad de vida 
                            mientras están con sus dueños.<br/><br/> 
                            
                            <h3 className='SubTitulo Esp2'>El maltrato</h3><br/>
                            
                            El desamparo de mascotas es sólo una de las muchas prácticas de maltrato. 
                            Los seres humanos los han usado para alcanzar diversos fines a lo largo de 
                            la historia. Han sido fuente de alimento, ropa, fuerza de trabajo, transporte e 
                            incluso entretenimiento.<br/><br/>
                            
                            Por muchos siglos se consideró que eran seres inferiores que estaban a 
                            nuestro servicio. Por lo tanto, no importaba el modo en que fueran tratados, 
                            siempre y cuando se alcanzaran los fines. El desarrollo social ha aumentado 
                            la necesidad de usarlos, y con ello crece también su sufrimiento.<br/><br/>
                            
                            <h3 className='SubTitulo Esp2'>El reconocimiento<br className='Tablet'/> del sufrimiento </h3><br/>

                            La biología reconoció desde hace varias décadas que son capaces de sentir 
                            dolor. Esto parece claro en la actualidad, pero hubo épocas en que no fue así. 
                            Existen personas que aún niegan este hecho, a pesar de que la ciencia 
                            demuestre lo contrario. Sus sistemas nerviosos son semejantes a los 
                            humanos y sienten la misma angustia que nosotros ante determinadas 
                            circunstancias. Si una persona se golpea la pierna, le duele. Si un animal se 
                            golpea en la pata, también le duele. <br/><br/>
                            
                            Las investigaciones no sólo han demostrado que se acongojan, también ha 
                            comprobado que son conscientes de ello. Un amplio grupo de científicos de
                            diferentes universidades estableció la llamada <em>Declaración de Cambridge </em> 
                            sobre la conciencia, en la que manifiestan que un gran número de especies 
                            tienen conciencia similar a la humana. Por lo tanto, pueden sentir afecto y 
                            reconocen el sufrimiento cuando lo padecen. <br/><br/>
                            
                            <h3 className='SubTitulo Esp2'>Sus derechos</h3><br/>
                            
                            La Declaración de Cambridge sobre la conciencia mostró la importancia de 
                            debatir sobre los derechos de los animales y diseñar acuerdos 
                            internacionales que los defiendan.<br/><br/>
                             
                            En 1978 se proclamó la Declaración Universal de los Derechos de los 
                            Animales, en la que se les considera seres merecedores de respeto y con 
                            derecho a no ser heridos ni explotados. Pero dicho documento carece de 
                            vinculación legal desde su publicación, por lo que no puede lucharse por sus 
                            derechos de manera formal.
                        </p>
                    </Grid>
                    <Grid container item xs={12} justifyContent="center"  className='Pos2'>
                        <Grid>
                            <p className='Lectura'>
                                <p className='TextImg'>Es decisión nuestra tomar acciones para cuidar y proteger a las especies.</p>
                            
                                Este debate sigue abierto y las nuevas generaciones tendrán un papel fundamental en la creación de una verdadera lucha que prohíba definitivamente el maltrato.
                            </p>
                        </Grid>
                    </Grid>
                    
                </Grid>
        </Grid>
        <Grid container item style={{ marginTop: 80}} ></Grid>
    </Grid>
    );
}
 
export default Los_animales_también_sufren;
