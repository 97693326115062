import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
    ContenedorLectura:{
        width:'502px',
          [theme.breakpoints.down(576)]:{
            width:'350px',
          },
        '& .Escritorio':{
            display:'block',
            [theme.breakpoints.down(1161)]:{
                display:'none'
            },
        },
        '& .Tablet':{
            display:'none',
            [theme.breakpoints.down(1161)]:{
                display:'block'
            },
        }
    },
    ContainerTitulo:{
        margin:'0 0 34px 0',
        '& .Titulo':{
            letterSpacing: '0px',
            color: '#89CD51',
            margin:'0',
            textAlign:'center',
            font:'43px fredoka one',
            lineHeight:'52px',
            [theme.breakpoints.down(576)]:{
                font:'38px fredoka one',
                lineHeight:'52px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            margin:'0 0 36px 0',
            color:'#000',
            font:'18px lato',
            lineHeight :'28px',
            letterSpacing: '0.17px',
            [theme.breakpoints.down(576)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
        },
        '& .Esp':{
            margin:'0'
        },
        '& .Imagen1':{
            width:'92.2%'
        }, 
        '& .Imagen2':{
            width:'86.3%'
        },  
        '& .Imagen3':{
            width:'96%'
        },  
      
    }
}));

const Únicos = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>

            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >Único</h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                      <p className="Lectura">
                        Había una vez un gato que se creía único. Cuando caminaba, extendía una pata, luego 
                        la otra y movía su cola al compás de una música que tarareaba en su cabeza. Sus 
                        movimientos suaves y su peculiar maullido hacían que todos voltearan a mirarlo con 
                        admiración.
                      </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <Grid container justifyContent="center" >
                            <img className="Imagen1" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img2do/Sesion6/1.png" alt="Tarjeta" />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                      <p className="Lectura Esp">
                        Un día se encontró con una serpiente y se quedó asombrado al ver que se parecía a 
                        su cola:<br/><br/>  

                        —¡¿Quién te autorizó para tener la forma y el ritmo de mi gran cola?! —le reclamó—.  
                        Busca tu propia manera.<br/> <br/>  
                        
                        —Yo siempre he sido así —rezongó la serpiente—. Toda yo soy una "S" que se traza con  
                        ritmo en la tierra. Además, la cuerda que usan los seres humanos también tiene mi  
                        forma y, “por lo que veo”, la de tu cola. Tal vez no eres tan único.<br/> <br/>  
                        
                        El gato fue a observar la cuerda. Notó que era delgada como su cola y que podía  
                        moverse igual que la serpiente. Además, podía amarrarse y dibujar otras siluetas. Le  
                        exigió que cambiara su forma pero la cuerda le dijo:<br/> <br/>  
                        
                        —Aunque me amarre y parezca otra, siempre me voy a parecer a alguien más. Como  
                        la mano que me anuda, que se parece a la rana.<br/> <br/>  

                        Y la rana, que se encontraba cerca y escuchó lo que dijo la cuerda, agregó:<br/> <br/>  
                        
                        —Y la forma de mis patas es como los pétalos abiertos de las flores.<br/> <br/>  
                        
                        Y de entre las flores, el clavel dijo:<br/> <br/>  
                        
                        —¡Y los pétalos de mi cabeza hacen que parezca un león con su melena!<br/> <br/>  
                        
                        Y el león dio un rugido y señaló:<br/> <br/>  
                        
                        —¡Y mi melena es igual a la de aquel niño despeinado!<br/> <br/>  
                        
                        —Pero ya me corté el pelo —dijo el niño—, y ahora mi cabeza se parece al pasto recién
                        podado.

                      </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <Grid container justifyContent="center" >
                            <img className="Imagen2" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img2do/Sesion6/2.png" alt="Tarjeta" />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                      <p className="Lectura">
                        El gato escuchó cómo todos se parecían y se preguntó si, entonces, nunca habría 
                        nada único en él. Miró sus patas. Después onduló su cola y finalmente dio un 
                        elegante maullido. Fue entonces cuando una idea lo hizo saltar y dibujar en el aire 
                        una ceja con sorpresa.
                      </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <Grid container justifyContent="center" >
                            <img className="Imagen3" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img2do/Sesion6/3.png" alt="Tarjeta" />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                      <p className="Lectura">
                        —¡Claro que soy único! —dijo—. No importa que en algunas cosas me parezca a otros, 
                        ¡soy único porque mi combinación es única!<br/><br/> 

                        Y se marchó orgulloso de ser quien era, moviendo la cola al compás de una música 
                        que tarareaba en su cabeza.
                      </p>
                    </Grid>
                </Grid>         
            </Grid>
        </Grid>
    );
}
 
export default Únicos;
