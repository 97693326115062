import React, {useEffect}               from "react";
import {Grid, makeStyles}               from '@material-ui/core';

const useStyles = makeStyles(theme =>({
  ContainerReading:{
    width: "100%",
    height:"90%",
    letterSpacing: '0.15px',
    boxSizing:'border-box',
    paddin:'0px 10px 0 10px',
    // overflowY: "scroll",
    // scrollbarColor: "#F6DE95",
    // scrollbarWidth: "10px",
    // '&::-webkit-scrollbar': {
    //   width: '15px',
    // },
    // '&::-webkit-scrollbar-track': {
    //   borderRadius: '25px',
    //   backgroundColor: "#F6DE95",
    //   webkitBoxShadow: 'inset 0 0 3px rgba(0,0,0,0.00)'
    // },
    // '&::-webkit-scrollbar-thumb': {
    //   backgroundColor: '#8144DF',
    //   borderRadius: '25px',
    // },
  },
  reading: {
    margin:'0px',
    font: '22px Lato;',
    [theme.breakpoints.down(1370)]:{
      font: "18px lato",
    },
    [theme.breakpoints.down(1200)]:{
      font: "16px lato",
    },
    [theme.breakpoints.down(740)]:{
      font: "14px lato",
    },
  },
}));

const QuestionProv = (props) => {
  const classes = useStyles()
  const { pregunta, step, baseNumber, stepsForText } = props
  const [questionNumber, setQuestionNumber] = React.useState(step)

  const evalNumber =  `${(step + 1) - (baseNumber * stepsForText)}.`

  useEffect(() => {
    if(baseNumber === undefined){
      setQuestionNumber("")
    } else {
      setQuestionNumber(evalNumber)
    }
  }, [step])

  return(
    <Grid className={classes.ContainerReading}>
      <p 
        dangerouslySetInnerHTML={{__html: `${pregunta}`}}
        className={classes.reading}
      >
      </p>
    </Grid>

  )

}

export default QuestionProv;
