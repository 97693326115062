import { Button, Grid, makeStyles } from "@material-ui/core";
import React, { useContext } from "react";
import DrawerContext from "../../../../../usability/DrawerContext";
import Drawer from "../../ReadingTools/Drawer";

const useStyles = makeStyles((theme) => ({
  Contenedor: {
    background: "#E9FBF9",
    width: "100vw",
    [theme.breakpoints.down(1161)]: {
      background: "none",
    },
    [theme.breakpoints.down(600)]: {
      marginTop: "79.51px",
    },
  },
  ContainerInfoLectura: {
    position: "relative",
    height: "86px",
    [theme.breakpoints.down(502)]:{
        height: "43px",
    },
    background: "#76D4C3",
    "& .BarraInfoLectura": {
      height: "86px",
      background: `Url(https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Barras/Azul.png)`,
        [theme.breakpoints.down(502)]:{
            height: "43px",
        },
    },
    "& .InfoLectura": {
      position: "absolute",
      height: "86px",
      width: "219px",
      boxSizing: "border-box",
      padding: "17.2px 0",
      background: "#FAFAFA",
      borderRadius: "50px",
      boxShadow: "2px 2px 2px 2px #00000029",
      zIndex: "4",
        [theme.breakpoints.down(502)]:{
            height: "43px",
            width: "109px",
            padding: "8.6px 0",
            borderRadius: "25px",
        },
    },
    "& .InfoLectura:before": {
      position: "absolute",
      display: "flex",
      boxSizing: "border-box",
      padding: "0 0 0 10px",
      justifyContent: "center",
      alignItems: "center",
      height: "39.88px",
      width: "39.88px",
      borderRadius: "50%",
      content: '""',
      boxShadow: "3px 0px .5px 0px #00000029",
      background: "#FAFAFA",
      left: "90px",
      top: "65px",
      transform: "rotate(0.25turn)",
      [theme.breakpoints.down(502)]:{
        left: "45px",
        top: "30px",
        height: "20.88px",
        width: "20.88px",
        },
    },
    "& .InfoLectura:after": {
      content: '""',
      height: "15px",
      width: "15px",
      borderTop: "2px solid #20A698",
      borderLeft: "2px solid #20A698",
      position: "absolute",
      bottom: "-10px",
      left: "Calc(50% - 8px)",
      transform: "rotate(0.625turn)",
      [theme.breakpoints.down(502)]:{
        height: "10px",
        width: "10px",
        left: "Calc(50% - 4.5px)",
        bottom: "-3px",
        borderTop: "1px solid #20A698",
        borderLeft: "1px solid #20A698",
        },
    },
    "& .Sesion": {
      borderLeft: "2px solid #D8D8D8",
    },
    "& .Dato": {
      margin: "0 0 5px 0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#20A698",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        margin: "0 0 2.5px 0",
        },
    },
    "& .Caracter": {
      margin: "0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#000000",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        },
    },
       '& .Regresar':{
        color: '#F2624F',
        border: '#F2624F 1px solid',
        height: '30px',
        width:'100px',
        borderRadius: '20px',
        letterSpacing: '0.14px',
        textTransform: 'none',
        background:'#FEFAF1',
        top:'calc(50% - 15px)',
        left:'5%',
        zIndex:4,
        [theme.breakpoints.down(502)]: {
          font:'bold 10px lato',
          width: "60px",
        },
      },
  },
  ContenedorLectura: {
    position: "relative",
    lineHeight: "30px",
    background: "#fff",
    width: "1160px",
    height: "100%",
    padding: "68px 168px 0px 168px ",
    position: "relative",
    transform: (props) =>
      props.scale > 1 ? `scale(${props.scale}) translate(0px, 10%)` : `none`,
    [theme.breakpoints.down(1160)]: {
      padding: "68px 10px 0px 10px  ",
      width: "502px",
    },
    [theme.breakpoints.down(502)]: {
      width: "350px",
      padding: "60px 0 0 0",
    },
    [theme.breakpoints.down(350)]: {
      width: "300px",
      padding: "30px 0 0 0",
    },
    "& .Atencion": {
      alignSelf: "center",
      display: " inline-block",
      margin: "0 0 15px 0",
      font: "20px Lato ",
      textAlign: "center",
      [theme.breakpoints.down(851)]: {
        font: "18px Lato ",
      },
      [theme.breakpoints.down(637)]: {
        font: "16px Lato ",
      },
    },
    "& .Titulo": {
      alignSelf: "center",
      display: " inline-block",
      margin: " 0 0 31px 0",
      font: "48px Fredoka One",
      color: "#63A97E",
      textAlign: "center",
      [theme.breakpoints.down(851)]: {
        font: "40px Fredoka One",
      },
      [theme.breakpoints.down(637)]: {
        font: "30px Fredoka One",
      },
    },
    "& .ContainerImagen": {
      position: "relative",
      bottom: "67px",
      [theme.breakpoints.down(851)]: {
        bottom: "35px",
      },
    },
    "& .Img1": {
      height: "757px",
      width: "870px",
      [theme.breakpoints.down(1160)]: {
        height: '425px',  
        width:'100%',
      },
      [theme.breakpoints.down(502)]:{
          height:'285px',
      },
      [theme.breakpoints.down(350)]:{
        height: '231px',
        margin: '50px 0 0 0',
      },
    },
    "& .ContainerDerechos": {
      marginBottom: "35px",
      textAlign: "center",
      font: " 15px lato",
      color: "#717476",
      margin: "0 0",
      [theme.breakpoints.down(1161)]: {
        display: "none",
      },
      "& .Derechos": {
        margin: "0",
      },
    },
    "& .Texto2": {
      textAlign: "left",
      flexDirection: "column",
      width: "329.733px",
      padding: "0 0 0 79px",
      margin: "0",
      boxSizing: "border-box",
      font: " 22px lato",
      [theme.breakpoints.down(851)]: {
        font: " 18px lato",
        width: "288.51px",
      },
    },
  },
  Lectura: {
    margin: "0",
    padding: "0 ",
    display: "flex",
    color: "#000000",
    flexDirection: "column",
    font: " 22px lato",
    counterReset: "numeros",
    listStyleType: "none",
    [theme.breakpoints.down(851)]: {
      font: " 18px lato",
    },
    "& .Texto": {
      position: "relative",
      lineHeight: "30px",
      letterSpacing: ".983px",
      padding: "0 0 0 79px",
      margin: "0 0 24px 0",
      [theme.breakpoints.down(851)]: {
        lineHeight: "27px",
      },
    },
    "& .Texto:before": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      content: "counter(numeros)",
      counterIncrement: "numeros",
      position: "absolute",
      left: "0",
      top: "0",
      font: "25px Fredoka One",
      background: "#F36539",
      color: "#FFF",
      borderRadius: "50% 50% 0% 50%",
      height: "43px",
      width: "42px",
      [theme.breakpoints.down(851)]: {
        height: "35px",
        width: "34px",
        font: "21px Fredoka One",
      },
    },
  },
  link: {
    textDecoration: "none",
    position: "absolute",
    top: "25px",
    right: "15px",
    "& .Button": {
      textTransform: "capitalize",
      height: "31px",
      width: "155px",
      background: "#FEFAF1",
      color: "#F6644B",
      font: "16px lato",
      borderRadius: "20px",
      border: " 1px solid #F2624F",
    },
  },
}));

const De_Panza_Pa_Arriba = (props) => {

  const {activeStep, setActiveStep, handlePreviewStep, id_actividad} = props
  
  const handleContinue = () => {
    setActiveStep(activeStep - 1)
    handlePreviewStep()
  }

  const {
    handleSize,
    disabledZoom,
    closeZoom,
    scale
  } = useContext(DrawerContext);
  const detectSize = () => {
    const lectura = document.getElementById("lectura");

    const newWidth = lectura?.offsetWidth;
    const newHeight = lectura?.offsetHeight;
    if (lectura) handleSize(newHeight, newWidth);
  };
  React.useEffect(() => {
    window.addEventListener("resize", detectSize);
    return () => {
      window.removeEventListener("resize", detectSize);
      closeZoom();
    };
  }, []);

  const classes = useStyles({ disabledZoom, scale });
  return (
    <Grid
      container
      item
      justifyContent="center"
      xs={12}
      className={classes.Contenedor}
    >
      <Grid
        container
        item
        justifyContent="center"
        className={classes.ContainerInfoLectura}
      >
        <Grid container className="BarraInfoLectura">
          <Button className="Regresar" onClick={handleContinue}>
            Regresar
          </Button>
        </Grid>
        <Grid container item className="InfoLectura">
          <Grid
            container
            item
            direction="column"
            xs={6}
            justifyContent="center"
          >
            <p className="Dato">Grado</p>
            <p className="Caracter">1</p>
          </Grid>
          <Grid
            container
            item
            direction="column"
            xs={6}
            justifyContent="center"
            className="Sesion"
          >
            <p className="Dato">Sesión</p>
            <p className="Caracter">16</p>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        item
        className={classes.ContenedorLectura}
        justifyContent="center"
        id="lectura"
        onLoad={detectSize}
      >
        <Drawer id_lectura={id_actividad} withoutTransform={true} />
        <Grid container item sm={12}>
          <Grid container justifyContent="center" sm={12}>
            <h2 className="Titulo">De panza pa’ arriba</h2>
          </Grid>
          <Grid container justifyContent="center" sm={12}>
            <ul className={classes.Lectura}>
              <li className="Texto">
                ¡Brrr, brrr!
                <br />
                Una lagartija
                <br />
                cantaba de frío
                <br />
                y al Sol esperaba
                <br />
                muy cerca de un río.
                <br />
              </li>
              <li className="Texto">
                Le pedía al Sol sus rayos
                <br />
                pa’ hacerse un abrigo.
                <br />
                Se subió a una roca
                <br />
                y esperó conmigo.
                <br />
              </li>
              <li className="Texto">
                Cuando el Sol llegaba
                <br />
                feliz sonreía,
                <br />
                porque el Sol tomaba
                <br />
                de panza pa’ arriba.
                <br />
              </li>
              <li className="Texto">
                Contenta bailaba
                <br />
                pues de vitaminas
                <br />
                el Sol la alimentaba,
                <br />
                el corazón le latía.
                <br />
              </li>
              <li className="Texto">
                También le daba calcio
                <br />
                a todos sus huesos
                <br />
                y gracias a eso
                <br />
                muy fuertes crecían.
                <br />
              </li>
              <li className="Texto">
                Pero ten cuidado,
                <br />
                yo le decía,
                <br />
                porque te hace daño
                <br />
                si así estás todo el día.
                <br />
              </li>
              <li className="Texto">
                Debes una hoja
                <br />
                usar de sombrilla,
                <br />
                pues, si no, los rayos
                <br />
                tu piel quemarían.
                <br />
              </li>
              <li className="Texto">
                Llegada la noche,
                <br />
                el Sol se dormía.
                <br />
                Y de nuevo temblaba,
                <br />
                pobre lagartija.
                <br />
              </li>
            </ul>
          </Grid>
          <Grid container justifyContent="center" sm={12}>
            <p className="Texto2">¡Brrr, brrr!</p>
          </Grid>
        </Grid>
        <Grid container justifyContent="center" className="ContainerImagen">
          <img
            className="Img1"
            src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img1ro/Sesion16/1.png"
            alt=""
          />
        </Grid>
      </Grid>
      <Grid container item style={{ marginTop: 80 }}></Grid>
    </Grid>
  );
};

export default De_Panza_Pa_Arriba;
