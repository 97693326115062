import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
    ContenedorLectura:{
        width:'502px',
        [theme.breakpoints.down(502)]:{
            width:'350px',
        },
    },
    ContainerTitulo:{
        margin:'0 0 46px 0',
        '& .Titulo':{
            letterSpacing: '0px',
            color: '#4E93CA',
            margin:'0',
            textAlign:'center',
            font:'43px fredoka one',
            lineHeight:'52px',
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },  
       
    },

    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            margin:'0 0 36px 0 ',
            color:'#000',
            lineHeight :'28px',
            font:'18px lato',
            letterSpacing: '0.15px',
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .Lectura2':{
            margin:'0 0 8px 0',
        },
        '& .SubTitulo':{
            color:'#E56D54',
            margin:'0',
            font:'bold 26px lato',
            letterSpacing:'.24px',
            [theme.breakpoints.down(502)]:{
                font:'bold 18px lato',
                lineHeight :'24px',
                letterSpacing: '0.21px',
            },
        },
        '& .Grafos':{
            position:'relative',
            margin:'0 0 30px'
        },
        '& .Grafo':{
            position:'absolute',
            margin:'0',
            font:'13px lato',
        },
        '& .Grafo1':{
            top:'130.92px',
            left:'24.28px',
            [theme.breakpoints.down(502)]:{
                top:'49px',
                left:'14px',
            },
        },
        '& .Grafo2':{
            top:'130.92px',
            left:'223.23px',
            [theme.breakpoints.down(502)]:{
                top:'49px',
                left:'156px',
            },
        },
        '& .Grafo3':{
            top:'130.92px',
            left:'420.25px',
            [theme.breakpoints.down(502)]:{
                top:'73px',
                left:'285px',
            },
        },
        '& .Grafo4':{
            top:'305px',
            left:'24.28px',
            [theme.breakpoints.down(502)]:{
                top:'215px',
                left:'14px',
            },
        },
        '& .Grafo5':{
            top:'305px',
            left:'223.23px',
            [theme.breakpoints.down(502)]:{
                top:'215px',
                left:'156px',
            },
        },
        '& .Grafo6':{
            top:'305px',
            left:'420.25px',
            [theme.breakpoints.down(502)]:{
                top:'215px',
                left:'285px',
            },
        },

        '& .TextImg':{
            margin:'22px 0 0 0',
            alignSelf:'flex-start',
            color:'#717476',
            font:' 16px lato',
            letterSpacing:'.12px',
            [theme.breakpoints.down(502)]:{
                font:' 11px lato',
                lineHeight :'14px',
                letterSpacing: '0.10px',
            },
        },
        '& .Ul':{
            margin:'0',
            padding:'0 0 0 18px',
            color:'#E56D54',
            font:' 16px lato',
            letterSpacing:'.15px',
        },
        '& .Ul2':{
            margin:'0',
            padding:'0 0 0 20px',
            color:'#591AAF',
            listStyle:'decimal',
            font:'bold 18px lato',
        },
        '& .Azul':{
            color:"#4E93CA",

        },
        '& .Naranja':{
            color:"#FF0000",
        },
        '& .Imagen':{
            width:'100%',
        },
    },
    

}));

const La_Teoría_de_Ramsey_en_ejemplos = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >
                        La Teoría de Ramsey <br/>en ejemplos
                    </h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        
                        <p className="Lectura">
                            <h3 className='SubTitulo'>Teorías sobre nidos y palomas </h3><br/>

                            Aunque a veces pensamos que las teorías y reglas de las ciencias son muy antiguas, 
                            en realidad no todas lo son. Por ejemplo, la Teoría de Ramsey es de las teorías 
                            relativamente más recientes en matemáticas y, a la vez, una de las más complejas. Se 
                            llama así en honor a Frank Ramsey, el inglés que la formuló.<br/><br/>
        
                            Esta teoría dice lo siguiente: cuando se tiene un conjunto muy grande, se obtienen 
                            por necesidad subconjuntos que cuentan con elementos de características similares 
                            entre ellos.<br/><br/>
                            
                            Para explicarlo, podemos recurrir al conocido Principio del palomar: si hay un 
                            número determinado de nidos y tenemos un número mayor de palomas, por lo 
                            menos en un nido dormirán dos palomas. Lo recuerdas, ¿cierto? Si tienes más 
                            elementos que opciones o características, al menos dos elementos compartirán 
                            alguna de éstas. <br/><br/>
                            
                            Este principio permite demostrar diversos asuntos. Por ejemplo, si hay 8 personas 
                            reunidas y platican acerca de sus días de nacimiento, al menos dos de ellas habrán 
                            nacido el mismo día de la semana. ¡Ojo! No estamos diciendo que el mismo día del 
                            año. (Los “nidos” serían los días de la semana, mientras que las “palomas”, las 
                            personas). <br/><br/>
                            
                            <h3 className='SubTitulo'>De la teoría a la gráfica</h3> <br/>
                            
                            Para entender los resultados de la Teoría de Ramsey, nos apoyaremos en la Teoría de 
                            grafos. Un <em>grafo</em> es una figura tridimensional en el que todos los vértices posibles 
                            están unidos. Puedes comprenderlo mejor con la siguiente imagen:<br/><br/>

                            <ul className='Ul'>
                                <li><p className="Lectura Lectura2">El grafo completo de un vértice lo llamamos K<sub>1</sub>. No tiene aristas, pues ese vértice
                                no tiene con qué unirse.</p></li>
                                <li><p className="Lectura Lectura2">El grafo completo K<sub>2</sub> está formado por dos vértices y una arista entre ellos.</p></li>
                                <li><p className="Lectura Lectura2">Analiza los siguientes grafos y verás los ejemplos de hasta seis vértices.</p></li>
                            </ul>
                        </p>

                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <Grid container justifyContent='center' className='Grafos'>
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion8/1.png" alt="Tarjeta" />
                            <p className='Grafo Grafo1'>Grafo K <sub>1</sub></p>
                            <p className='Grafo Grafo2'>Grafo K <sub>2</sub></p>
                            <p className='Grafo Grafo3'>Grafo K <sub>3</sub></p>
                            <p className='Grafo Grafo4'>Grafo K <sub>4</sub></p>
                            <p className='Grafo Grafo5'>Grafo K <sub>5</sub></p>
                            <p className='Grafo Grafo6'>Grafo K <sub>6</sub></p>
                        </Grid>
                        <Grid container >
                            <p className='TextImg'>
                                Mediante esta serie de grafos, puedes observar el aumento de aristas al aumentar la cantidad 
                                de vértices, así como la formación de figuras de mayor complejidad al aplicar esta teoría.
                            </p>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container sm={12}>
                    
                        <p className="Lectura"> 
                            Para entender mejor esta teoría, presentemos otro ejemplo. En un grupo de seis 
                            personas, existen sólo dos opciones:<br/><br/>

                            <ul className='Ul2'>
                                <li ><p className='Lectura Lectura2'>Que al menos tres o más de ellas se conozcan.</p></li>
                                <li ><p className='Lectura Lectura2'>Que tres o más no se conozcan.</p></li><br/>
                            </ul>
                            Piénsalo por un momento, si tuviéramos dos personas que no se conocen entre sí, 
                            por fuerza habrá cuatro que sí se conozcan. <br/><br/>
                            
                            Veremos cómo describir este problema en términos de coloración de las aristas de 
                            un grafo y observaremos que el resultado no es cierto si sólo hay cinco personas. <br/><br/>
                            
                            Empecemos recordando el grupo de seis personas. En términos de grafos, lo que
                            tenemos es un grafo completo K<sub>6</sub>, cuyas aristas coloreamos con dos colores, azul y 
                            rojo. Nosotros queremos probar que sea cual sea esa coloración siempre 
                            encontramos un K<sub>3</sub> (triángulo) rojo o un K<sub>3</sub> azul. En este caso, la prueba es muy 
                            sencilla: nombremos el conjunto de vértices como {"{a,b,c,d,e,f}"} y fijémonos en uno
                            de ellos, por ejemplo, “a”. Según el Principio del palomar, en el vértice “a” deben de 
                            coincidir al menos tres aristas rojas o bien al menos tres aristas azules (recordemos 
                            que llegan a cinco aristas en total). Supongamos que estamos en el primer caso y que 
                            las aristas rojas le unen con b, c y d, por ejemplo. Si alguna de las aristas entre estos 
                            tres vértices fuera roja, tendríamos un triángulo rojo. Y si no fuera así, lo que 
                            tendríamos sería uno azul con vértices b, c y d. El mismo argumento permite obtener 
                            la misma conclusión en el segundo caso, cuando “a” recibe al menos tres aristas 
                            azules (de hecho, hay al menos dos triángulos del mismo color). Es fácil darse cuenta 
                            de que no podemos concluir lo mismo si partimos de un K<sub>5</sub>, por lo que obtenemos que 
                            R (3, 3) = 6. <br/><br/>
                            
                            <h3 className='SubTitulo'>¿Cómo se escriben los números de Ramsey?</h3><br/>

                            ¡Hemos obtenido nuestro primer número de Ramsey! Éste se escribe así: <br/><br/>
                            
                            R (<b className='Naranja'>3</b>,<b className='Azul'>3</b>) = 6 <br/><br/>
                            
                            La R es de Ramsey, el 6 significa que necesitamos seis vértices para encontrar al 
                            menos un triángulo rojo (<b className='Naranja'>3</b>) o un triángulo azul (<b className='Azul'>3</b>). Como ambos son triángulos, el 
                            número de Ramsey también puede escribirse R (<b className='Azul'>3</b>,<b className='Naranja'>3</b>) = 6. <br/><br/>

                            Hoy en día, se conocen pocos números de Ramsey porque cada vez que aumentamos 
                            un vértice la gráfica completa crece demasiado y es difícil encontrar subgráficas 
                            completas de un solo color. La razón es que debe ser válido para <em>todas</em> las 
                            coloraciones que asignemos.
                        </p>
                    


                      
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <Grid container justifyContent='center'>
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion8/2.png" alt="Tarjeta" />
                        </Grid>
                            <p className='TextImg'>
                                Al juntar ambas gráficas, obtenemos K<sub>8</sub> coloreada, pero observa que no hay ni una K<sub>3</sub>
                                (triángulo) ni una K<sub>4</sub> (cuadrado) de rojo, mientras que, en la coloración azul, sí hay varias K<sub>3</sub>, 
                                pero no hay K<sub>4</sub>.
                            </p>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container sm={12}>
                        <p className="Lectura "> 
                            ¡Ahora puedes imaginar lo complicado que se puede volver encontrar números de 
                            Ramsey! Pero la belleza que implica la idea de su existencia es sorprendente, ya que 
                            prueban que el desorden absoluto es imposible.
                        </p>
                    </Grid>
                </Grid>
        </Grid>
    </Grid>
    );
}
 
export default La_Teoría_de_Ramsey_en_ejemplos;
