import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
      Contenedor:{
        background:'#E9FBF9',
        width:'100vw',
        overflowX:'clip',
        [theme.breakpoints.down(1161)]:{
            background:'none',
           overflowX:'unset',
        },
        [theme.breakpoints.down(600)]:{
            marginTop: '79.51px',
        }
    },
  ContainerInfoLectura: {
    position: "relative",
    height: "86px",
    [theme.breakpoints.down(502)]:{
        height: "43px",
    },
    background: "#76D4C3",
    "& .BarraInfoLectura": {
      height: "86px",
      background: `Url(https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Barras/Azul.png)`,
        [theme.breakpoints.down(502)]:{
            height: "43px",
        },
    },
    "& .InfoLectura": {
      position: "absolute",
      height: "86px",
      width: "219px",
      boxSizing: "border-box",
      padding: "17.2px 0",
      background: "#FAFAFA",
      borderRadius: "50px",
      boxShadow: "2px 2px 2px 2px #00000029",
      zIndex: "4",
        [theme.breakpoints.down(502)]:{
            height: "43px",
            width: "109px",
            padding: "8.6px 0",
            borderRadius: "25px",
        },
    },
    "& .InfoLectura:before": {
      position: "absolute",
      display: "flex",
      boxSizing: "border-box",
      padding: "0 0 0 10px",
      justifyContent: "center",
      alignItems: "center",
      height: "39.88px",
      width: "39.88px",
      borderRadius: "50%",
      content: '""',
      boxShadow: "3px 0px .5px 0px #00000029",
      background: "#FAFAFA",
      left: "90px",
      top: "65px",
      transform: "rotate(0.25turn)",
      [theme.breakpoints.down(502)]:{
        left: "45px",
        top: "30px",
        height: "20.88px",
        width: "20.88px",
        },
    },
    "& .InfoLectura:after": {
      content: '""',
      height: "15px",
      width: "15px",
      borderTop: "2px solid #20A698",
      borderLeft: "2px solid #20A698",
      position: "absolute",
      bottom: "-10px",
      left: "Calc(50% - 8px)",
      transform: "rotate(0.625turn)",
      [theme.breakpoints.down(502)]:{
        height: "10px",
        width: "10px",
        left: "Calc(50% - 4.5px)",
        bottom: "-3px",
        borderTop: "1px solid #20A698",
        borderLeft: "1px solid #20A698",
        },
    },
    "& .Sesion": {
      borderLeft: "2px solid #D8D8D8",
    },
    "& .Dato": {
      margin: "0 0 5px 0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#20A698",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        margin: "0 0 2.5px 0",
        },
    },
    "& .Caracter": {
      margin: "0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#000000",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        },
    },   },
    ContenedorLectura:{
        background:'#fff',
        width:'1160px',
        height:'100%',
        padding:'60px 154px 35px 168px',
        [theme.breakpoints.down(1161)]:{
            width:'502px',
            padding:'60px 0 0',
        },
        [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
          '& .ContainerDerechos':{
            bottom:'80px',
            [theme.breakpoints.down(1161)]:{
                display:'none',
              },
        },
        '& .Derechos':{
            textAlign:"center",
            margin:"0px",
            font:" 15px lato",
            color:"#717476",
        },
        '& .Escritorio':{
            display:'block',
            [theme.breakpoints.down(1161)]:{
                display:'none'
            },
        },
        '& .Tablet':{
            display:'none',
            [theme.breakpoints.down(1161)]:{
                display:'block'
            },
        }
    },
    ContainerTitulo:{
        margin:'0 0 46px 0',
        '& .Titulo':{
            textAlign:'center',
            font:'48px fredoka one',
            letterSpacing: '0px',
            color: '#0CBF88',
            margin:'0',
        },
        [theme.breakpoints.down(1161)]:{
            font:'43px fredoka one',
            lineHeight:'52px',
        },
        [theme.breakpoints.down(502)]:{
            font:'30px fredoka one',
            lineHeight:'32px',
        },
    },
    Parrafo1:{
        position:'relative',
        margin:' 0 0 36px 0',
        '& .Lectura':{
            lineHeight : '30px',
            margin:'0',
            font:'22px lato',
            color:'#000',
            letterSpacing: '.21px',
            [theme.breakpoints.down(1161)]:{
                lineHeight :'28px',
                font:'18px lato',
                letterSpacing: '0.17px',
            },
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .Lectura2':{
            lineHeight : '30px',
            margin:'0 0 0 14.14px',
            font:'22px lato',
            color:'#000',
            letterSpacing: '.21px',
            [theme.breakpoints.down(1161)]:{
                lineHeight :'28px',
                font:'18px lato',
                letterSpacing: '0.16px',
                margin:'0 0 0 7.5px',
            },
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .SubTitulo':{
            font:'bold 30px lato',
            color:'#FF0900',
            margin:'0',
            [theme.breakpoints.down(1161)]:{
                font:'bold 26px lato',
                letterSpacing:'.24px',
            },
            [theme.breakpoints.down(502)]:{
                font:'bold 18px lato',
                lineHeight :'24px',
                letterSpacing: '0.21px',
            },
        },
        '& .Niña':{
            font:'22px Fredoka One ',
            [theme.breakpoints.down(1161)]:{
                font:'18px  Fredoka One',
                letterSpacing:'.17px',
            },
            [theme.breakpoints.down(502)]:{
                font:'14px  Fredoka One',
                letterSpacing:'.17px',
            },
        },
        '& .Sofia':{
            color:'#F64B93',
        },
        '& .Fatima':{
            color:'#10C4B6',
        },
        '& .Amelia':{
            color:'#4500FF',
        },
        '& .Imagen':{
            width:'100%',
        },
        '& .Pos':{
            position:'absolute',
            [theme.breakpoints.down(1161)]:{
                width:'48.55px',
            },
        },
        '& .img':{
            bottom:'10px',
            [theme.breakpoints.down(1161)]:{
                bottom:'18px',
            }
        },
        '& .img2':{
            top:'-16px',
            [theme.breakpoints.down(1161)]:{
                top:'-6px',
            }
        },
        '& .img3':{
            bottom:'40px',
            [theme.breakpoints.down(1161)]:{
                bottom:'103px',
            }
        },
        '& .img4':{
            bottom:'45px',
        },
        '& .img5':{
            bottom:'75px',
        },
        '& .Linea':{
            height:'17.5px',
            borderBottom:'solid 6px #0CBF88',
        },

    }
}));

const La_despedida = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
            <Grid container item justifyContent="center" className={classes.ContainerInfoLectura}>
               <Grid container className="BarraInfoLectura"></Grid>
               <Grid container item className="InfoLectura">
                    <Grid container item direction="column" xs={6} justifyContent="center">
                        <p className="Dato">Grado</p>
                        <p className="Caracter">6</p>
                    </Grid>
                    <Grid container item direction="column" xs={6} justifyContent="center" className="Sesion">
                        <p className="Dato">Sesión</p>
                        <p className="Caracter">6</p>
                    </Grid>
               </Grid>
            </Grid>

            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item xs={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >La despedida</h2>
                </Grid>
                <Grid container item xs={12} className={classes.Parrafo1} >
                        <p className='Lectura'>
                            <h3 className='SubTitulo'>ACTO 1</h3><br/>
                            <em >Afuera del salón de clases. Se escucha a lo lejos un murmullo de niños jugando. Fátima y 
                            Sofía están sentadas en una banca, sosteniendo sus almuerzos. Es la hora del receso.
                            </em> 
                        </p>
                </Grid>
                <Grid container className='Escritorio'>
                    <Grid container item xs={12} className={classes.Parrafo1} >
                            <Grid container justifyContent="center" xs={1}><img className='Pos img' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion6/S.png" alt="" /></Grid>
                            <Grid container xs={11}>
                                <p className='Lectura2'>
                                    <b className='Sofia'>SOFÍA.</b> <em>(Sin comprender lo que acaba de escuchar)</em> ¿O sea que ya no nos<br/> veremos?
                                </p>
                            </Grid>
                            
                        </Grid>

                        <Grid container item xs={12} className={classes.Parrafo1} >
                            <Grid container justifyContent="center" xs={1}><img className='Pos img2' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion6/F.png" alt="" /></Grid>
                            <Grid container xs={11}>
                                <p className='Lectura2'>
                                    <b className='Niña Fatima'>FÁTIMA.</b> <em>(Triste)</em> No.
                                </p>
                            </Grid>
                        </Grid> 

                        <Grid container item xs={12} className={classes.Parrafo1} >
                            <Grid container justifyContent="center" xs={1}><img className='Pos img3' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion6/S.png" alt="" /></Grid>
                            <Grid container xs={11}>
                                <p className='Lectura2'>
                                    <b className='Niña Sofia'>SOFÍA.</b> <em>(Abraza con fuerza a Fátima)</em> ¡Noooo! ¡No te vayas! <em>(Se escucha que <br/>
                                    llora un poco. De repente, deja de abrazarla) </em> ¡Ya sé! ¡Tengo una idea! ¿Y si le digo<br/> 
                                    a mi mamá que también a mí me cambie de escuela? ¡Así nos vamos juntas!
                                </p>
                            </Grid>
                        </Grid>

                        <Grid container item xs={12} className={classes.Parrafo1} >
                            <Grid container justifyContent="center" xs={1}><img className='Pos img2' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion6/F.png" alt="" /></Grid>
                            <Grid container xs={11}>
                                <p className='Lectura2'>
                                    <b className='Niña Fatima'>FÁTIMA.</b> No creo que quieran mis papás.
                                </p>
                            </Grid>
                        </Grid> 

                        <Grid container item xs={12} className={classes.Parrafo1} >
                            <Grid container justifyContent="center" xs={1}><img className='Pos img' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion6/S.png" alt="" /></Grid>
                            <Grid container xs={11}>
                                <p className='Lectura2'>
                                    <b className='Niña Sofia'>SOFÍA.</b> <em>(Sorprendida)</em> Pero ¿por qué? ¿A tus papás no les gusta que yo sea tu ?<br/> amiga
                                </p>
                            </Grid>
                        </Grid>   
                </Grid>
                
                <Grid container className='Tablet'>
                    <Grid container item xs={12} className={classes.Parrafo1} >
                            <Grid container justifyContent="center" xs={1}><img className='Pos img' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion6/S.png" alt="" /></Grid>
                            <Grid container xs={11}>
                                <p className='Lectura2'>
                                    <b className='Sofia'>SOFÍA.</b> <em>(Sin comprender lo que acaba de escuchar)</em> ¿O sea  <br/>
                                    que ya no nos veremos?
                                </p>
                            </Grid>
                            
                    </Grid>

                    <Grid container item xs={12} className={classes.Parrafo1} >
                        <Grid container justifyContent="center" xs={1}><img className='Pos img2' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion6/F.png" alt="" /></Grid>
                        <Grid container xs={11}>
                            <p className='Lectura2'>
                                <b className='Niña Fatima'>FÁTIMA.</b> <em>(Triste)</em> No.
                            </p>
                        </Grid>
                    </Grid> 

                    <Grid container item xs={12} className={classes.Parrafo1} >
                        <Grid container justifyContent="center" xs={1}><img className='Pos img3' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion6/S.png" alt="" /></Grid>
                        <Grid container xs={11}>
                            <p className='Lectura2'>
                                <b className='Niña Sofia'>SOFÍA.</b> <em>(Abraza con fuerza a Fátima)</em> ¡Noooo! ¡No <br/>
                                te vayas! <em>(Se escucha que llora un poco. De repente, deja de<br/>
                                abrazarla) </em> ¡Ya sé! ¡Tengo una idea! ¿Y si le digo a mi<br/> 
                                mamá que también a mí me cambie de escuela? ¡Así nos <br/>
                                vamos juntas!
                            </p>
                        </Grid>
                    </Grid>

                    <Grid container item xs={12} className={classes.Parrafo1} >
                        <Grid container justifyContent="center" xs={1}><img className='Pos img2' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion6/F.png" alt="" /></Grid>
                        <Grid container xs={11}>
                            <p className='Lectura2'>
                                <b className='Niña Fatima'>FÁTIMA.</b> No creo que quieran mis papás.
                            </p>
                        </Grid>
                    </Grid> 

                    <Grid container item xs={12} className={classes.Parrafo1} >
                        <Grid container justifyContent="center" xs={1}><img className='Pos img' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion6/S.png" alt="" /></Grid>
                        <Grid container xs={11}>
                            <p className='Lectura2'>
                                <b className='Niña Sofia'>SOFÍA.</b> <em>(Sorprendida)</em> Pero ¿por qué? ¿A tus papás  <br/>
                                no les gusta que yo sea tu ? amiga
                            </p>
                        </Grid>
                    </Grid>   
                </Grid>

                                        
                <Grid container item xs={12} className={classes.Parrafo1} >
                    <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion6/1.png" alt="" />
                </Grid> 

                <Grid container className='Escritorio'>
                    <Grid container item xs={12} className={classes.Parrafo1} >
                        <Grid container justifyContent="center" xs={1}><img className='Pos img2' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion6/F.png" alt="" /></Grid>
                        <Grid container xs={11}>
                            <p className='Lectura2'>
                                <b className='Niña Fatima'>FÁTIMA.</b> No, no es eso. Le ofrecieron un trabajo a mi papá en otra ciudad.
                            </p>
                        </Grid>
                    </Grid> 

                    <Grid container item xs={12} className={classes.Parrafo1} >
                        <Grid container justifyContent="center" xs={1}><img className='Pos img' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion6/S.png" alt="" /></Grid>
                        <Grid container xs={11}>
                            <p className='Lectura2'>
                                <b className='Niña Sofia'>SOFÍA.</b> Pero puede irse él solo, ¿no? Así le hizo mi papá cuando nos dejó a mi  <br/>mamá y a mí.
                            </p>
                        </Grid>
                    </Grid>  

                    <Grid container item xs={12} className={classes.Parrafo1} >
                        <Grid container justifyContent="center" xs={1}><img className='Pos img2' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion6/F.png" alt="" /></Grid>
                        <Grid container xs={11}>
                            <p className='Lectura2'>
                                <b className='Niña Fatima'>FÁTIMA.</b> No. Él quiere que nos vayamos todos juntos.
                            </p>
                        </Grid>
                    </Grid> 
                    
                    <Grid container item xs={12} className={classes.Parrafo1} >
                        <Grid container justifyContent="center" xs={1}><img className='Pos img2' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion6/S.png" alt="" /></Grid>
                        <Grid container xs={11}>
                            <p className='Lectura2'>
                                <b className='Niña Sofia'>SOFÍA.</b> Pero… ¡Ay, Fátima, no te vayas!<em> (La abraza otra vez)</em>.
                            </p>
                        </Grid>
                    </Grid>  

                    <Grid container item xs={12} className={classes.Parrafo1} >
                        <Grid container justifyContent="center" xs={1}><img className='Pos img' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion6/F.png" alt="" /></Grid>
                        <Grid container xs={11}>
                            <p className='Lectura2'>
                                <b className='Niña Fatima'>FÁTIMA.</b> Yo tampoco quiero irme, pero dice mi mamá que allá conoceré a <br/>
                                más niños, que haré nuevos amigos.
                            </p> 
                        </Grid>
                    </Grid> 
                    
                    <Grid container item xs={12} className={classes.Parrafo1} >
                        <Grid container justifyContent="center" xs={1}><img className='Pos img2' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion6/S.png" alt="" /></Grid>
                        <Grid container xs={11}>
                            <p className='Lectura2'>
                                <b className='Niña Sofia'>SOFÍA.</b> <em>(Enojada)</em> ¿Y yo qué? Yo soy tu mejor amiga.
                            </p>
                        </Grid>
                    </Grid>  

                    <Grid container item xs={12} className={classes.Parrafo1} >
                        <Grid container justifyContent="center" xs={1}><img className='Pos img' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion6/F.png" alt="" /></Grid>
                        <Grid container xs={11}>
                            <p className='Lectura2'>
                                <b className='Niña Fatima'>FÁTIMA.</b> También podremos hablarnos. Le puedo pedir a mi mamá que me <br/>
                                preste su teléfono para llamarte. Mi mamá le pidió a tu mamá su número.
                            </p>
                        </Grid>
                    </Grid> 
                    
                    <Grid container item xs={12} className={classes.Parrafo1} >
                        <Grid container justifyContent="center" xs={1}><img className='Pos img2' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion6/S.png" alt="" /></Grid>
                        <Grid container xs={11}>
                            <p className='Lectura2'>
                                <b className='Niña Sofia'>SOFÍA.</b> <em>(Feliz)</em> ¡Sí! (Se entristece de repente) Pero no será lo mismo.
                            </p>
                        </Grid>
                    </Grid>  

                    <Grid container item xs={12} className={classes.Parrafo1} >
                        <Grid container justifyContent="center" xs={1}><img className='Pos img2' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion6/F.png" alt="" /></Grid>
                        <Grid container xs={11}>
                            <p className='Lectura2'>
                                <b className='Niña Fatima'>FÁTIMA.</b> En las vacaciones podría venir a visitarte. O… tú a mí.
                            </p>
                        </Grid>
                    </Grid> 
                    
                    <Grid container item xs={12} className={classes.Parrafo1} >
                        <Grid container justifyContent="center" xs={1}><img className='Pos img2' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion6/S.png" alt="" /></Grid>
                        <Grid container xs={11}>
                            <p className='Lectura2'>
                                <b className='Niña Sofia'>SOFÍA.</b> Sabes que no será lo mismo.
                            </p>
                        </Grid>
                    </Grid>  

                    <Grid container item xs={12} className={classes.Parrafo1} >
                        <Grid container justifyContent="center" xs={1}><img className='Pos img3' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion6/F.png" alt="" /></Grid>
                        <Grid container xs={11}>
                            <p className='Lectura2'>
                                <b className='Niña Fatima'>FÁTIMA.</b> Ya verás que será divertido cuando nos volvamos a ver. Nos contaremos muchas cosas y jugaremos mucho. (Le ofrece su almuerzo) ¿Quieres un poco?
                            </p>
                        </Grid>
                    </Grid> 

                    <Grid container item xs={12} className={classes.Parrafo1} >
                        <Grid container xs={12} justifyContent='center'>
                                <p className='Lectura2'> <em>Telón</em> </p>
                        </Grid>
                        <Grid container xs={12} className='Linea'>
                            
                        </Grid>
                    </Grid> 

                    <Grid container item xs={12} className={classes.Parrafo1} >
                        <p className='Lectura'>
                            <h3 className='SubTitulo'>ACTO 2</h3><br/>
                            <em>Afuera del salón de clases. Se escucha a lo lejos un murmullo de niños jugando. Sofía está 
                            sentada en una banca, comiendo su almuerzo y hablando consigo misma enojada.
                            </em> 
                        </p>
                    </Grid>

                    <Grid container item xs={12} className={classes.Parrafo1} >
                        <Grid container justifyContent="center" xs={1}><img className='Pos img3' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion6/S2.png" alt="" /></Grid>
                        <Grid container xs={11}>
                            <p className='Lectura2'>
                                <b className='Niña Sofia'>SOFÍA.</b> Uy, sí, muy divertido. Pero divertido para ti, porque yo me aburro<br/> 
                                mucho ahora que no estás. Y ya no tengo a nadie con quién hablar. Pero tú de <br/>
                                seguro ya hiciste nuevas amigas…
                            </p>
                        </Grid>
                    </Grid>  

                    <Grid container item xs={12} className={classes.Parrafo1} >
                            <p className='Lectura2'>
                                <em>Entra Amelia</em>
                            </p>
                    </Grid>  

                    <Grid container item xs={12} className={classes.Parrafo1} >
                        <Grid container justifyContent="center" xs={1}><img className='Pos img2' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion6/A.png" alt="" /></Grid>
                        <Grid container xs={11}>
                            <p className='Lectura2'>
                                <b className='Niña Amelia'>AMELIA.</b> ¿Con quién hablas?
                            </p>
                        </Grid>
                    </Grid>

                    <Grid container item xs={12} className={classes.Parrafo1} >
                        <Grid container justifyContent="center" xs={1}><img className='Pos img2' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion6/S2.png" alt="" /></Grid>
                        <Grid container xs={11}>
                            <p className='Lectura2'>
                                <b className='Niña Sofia'>SOFÍA.</b><em> (Apenada) </em>¡Eh! ¡No! ¡Con nadie!
                            </p>
                        </Grid>
                    </Grid>  

                    <Grid container item xs={12} className={classes.Parrafo1} >
                        <Grid container justifyContent="center" xs={1}><img className='Pos img2' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion6/A.png" alt="" /></Grid>
                        <Grid container xs={11}>
                            <p className='Lectura2'>
                                <b className='Niña Amelia'>AMELIA.</b> ¿Con Fátima?
                            </p>
                        </Grid>
                    </Grid>

                    <Grid container item xs={12} className={classes.Parrafo1} >
                        <Grid container justifyContent="center" xs={1}><img className='Pos img2' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion6/S2.png" alt="" /></Grid>
                        <Grid container xs={11}>
                            <p className='Lectura2'>
                                <b className='Niña Sofia'>SOFÍA.</b> ¡No, cómo crees! Si ella ya se fue.
                            </p>
                        </Grid>
                    </Grid> 

                    <Grid container item xs={12} className={classes.Parrafo1} >
                        <Grid container justifyContent="center" xs={1}><img className='Pos img2' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion6/A.png" alt="" /></Grid>
                        <Grid container xs={11}>
                            <p className='Lectura2'>
                                <b className='Niña Amelia'>AMELIA.</b> ¿Y no la extrañas? Yo siempre las veía juntas.
                            </p>
                        </Grid>
                    </Grid> 

                    <Grid container item xs={12} className={classes.Parrafo1} >
                        <Grid container justifyContent="center" xs={1}><img className='Pos img2' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion6/S2.png" alt="" /></Grid>
                        <Grid container xs={11}>
                            <p className='Lectura2'>
                                <b className='Niña Sofia'>SOFÍA.</b> No, no la extraño. Estoy bien sola.
                            </p>
                        </Grid>
                    </Grid>

                    <Grid container item xs={12} className={classes.Parrafo1} >
                        <Grid container justifyContent="center" xs={1}><img className='Pos img2' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion6/A.png" alt="" /></Grid>
                        <Grid container xs={11}>
                            <p className='Lectura2'>
                                <b className='Niña Amelia'>AMELIA.</b> ¿No te aburre estar sola?
                            </p>
                        </Grid>
                    </Grid>  
                    
                    <Grid container item xs={12} className={classes.Parrafo1} >
                        <Grid container justifyContent="center" xs={1}><img className='Pos img2' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion6/S2.png" alt="" /></Grid>
                        <Grid container xs={11}>
                            <p className='Lectura2'>
                                <b className='Niña Sofia'>SOFÍA.</b> No, para nada.
                            </p>
                        </Grid>
                    </Grid>

                    <Grid container item xs={12} className={classes.Parrafo1} >
                        <Grid container justifyContent="center" xs={1}><img className='Pos img2' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion6/A.png" alt="" /></Grid>
                        <Grid container xs={11}>
                            <p className='Lectura2'>
                                <b className='Niña Amelia'>AMELIA.</b> ¿Me puedo sentar? <em>(Se sienta junto a Sofía)</em> Yo también la extraño.
                            </p>
                        </Grid>
                    </Grid>

                    <Grid container item xs={12} className={classes.Parrafo1} >
                        <Grid container justifyContent="center" xs={1}><img className='Pos img2' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion6/S2.png" alt="" /></Grid>
                        <Grid container xs={11}>
                            <p className='Lectura2'>
                                <b className='Niña Sofia'>SOFÍA.</b> ¡Yo no la extraño! ¿Qué quieres?
                            </p>
                        </Grid>
                    </Grid>  

                    <Grid container item xs={12} className={classes.Parrafo1} >
                        <Grid container justifyContent="center" xs={1}><img className='Pos img2' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion6/A.png" alt="" /></Grid>
                        <Grid container xs={11}>
                            <p className='Lectura2'>
                                <b className='Niña Amelia'>AMELIA.</b> <em>(Señala el almuerzo de Sofía)</em> ¿Me das un poco?
                            </p>
                        </Grid>
                    </Grid>

                    <Grid container item xs={12} className={classes.Parrafo1} >
                        <Grid container justifyContent="center" xs={1}><img className='Pos img2' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion6/S2.png" alt="" /></Grid>
                        <Grid container xs={11}>
                            <p className='Lectura2'>
                                <b className='Niña Sofia'>SOFÍA.</b> ¡No! ¡Sólo le comparto a Fátima!
                            </p>
                        </Grid>
                    </Grid> 

                    <Grid container item xs={12} className={classes.Parrafo1} >
                        <Grid container justifyContent="center" xs={1}><img className='Pos img2' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion6/A.png" alt="" /></Grid>
                        <Grid container xs={11}>
                            <p className='Lectura2'>
                                <b className='Niña Amelia'>AMELIA.</b> ¿Quieres jugar? Nos falta una niña para completar.
                            </p>
                        </Grid>
                    </Grid>

                    <Grid container item xs={12} className={classes.Parrafo1} >
                        <Grid container justifyContent="center" xs={1}><img className='Pos img2' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion6/S2.png" alt="" /></Grid>
                        <Grid container xs={11}>
                            <p className='Lectura2'>
                                <b className='Niña Sofia'>SOFÍA.</b> ¿A qué están jugando?
                            </p>
                        </Grid>
                    </Grid> 

                    <Grid container item xs={12} className={classes.Parrafo1} >
                        <Grid container justifyContent="center" xs={1}><img className='Pos img2' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion6/A.png" alt="" /></Grid>
                        <Grid container xs={11}>
                            <p className='Lectura2'>
                                <b className='Niña Amelia'>AMELIA.</b> Ven, te enseño.
                            </p>
                        </Grid>
                    </Grid>                          
                </Grid>     

                <Grid container className='Tablet'>
                    <Grid container item xs={12} className={classes.Parrafo1} >
                        <Grid container justifyContent="center" xs={1}><img className='Pos img2' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion6/F.png" alt="" /></Grid>
                        <Grid container xs={11}>
                            <p className='Lectura2'>
                                <b className='Niña Fatima'>FÁTIMA.</b> No, no es eso. Le ofrecieron un trabajo a mi papá en otra ciudad.
                            </p>
                        </Grid>
                    </Grid> 

                    <Grid container item xs={12} className={classes.Parrafo1} >
                        <Grid container justifyContent="center" xs={1}><img className='Pos img' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion6/S.png" alt="" /></Grid>
                        <Grid container xs={11}>
                            <p className='Lectura2'>
                                <b className='Niña Sofia'>SOFÍA.</b> Pero puede irse él solo, ¿no? Así le hizo mi papá <br/> 
                                cuando nos dejó a mi  mamá y a mí.
                            </p>
                        </Grid>
                    </Grid>  

                    <Grid container item xs={12} className={classes.Parrafo1} >
                        <Grid container justifyContent="center" xs={1}><img className='Pos img2' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion6/F.png" alt="" /></Grid>
                        <Grid container xs={11}>
                            <p className='Lectura2'>
                                <b className='Niña Fatima'>FÁTIMA.</b> No. Él quiere que nos vayamos todos juntos.
                            </p>
                        </Grid>
                    </Grid> 
                    
                    <Grid container item xs={12} className={classes.Parrafo1} >
                        <Grid container justifyContent="center" xs={1}><img className='Pos img2' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion6/S.png" alt="" /></Grid>
                        <Grid container xs={11}>
                            <p className='Lectura2'>
                                <b className='Niña Sofia'>SOFÍA.</b> Pero… ¡Ay, Fátima, no te vayas!<em> (La abraza otra vez)</em>.
                            </p>
                        </Grid>
                    </Grid>  

                    <Grid container item xs={12} className={classes.Parrafo1} >
                        <Grid container justifyContent="center" xs={1}><img className='Pos img' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion6/F.png" alt="" /></Grid>
                        <Grid container xs={11}>
                            <p className='Lectura2'>
                                <b className='Niña Fatima'>FÁTIMA.</b> Yo tampoco quiero irme, pero dice mi mamá <br/> 
                                que allá conoceré a más niños, que haré nuevos amigos.
                            </p> 
                        </Grid>
                    </Grid> 
                    
                    <Grid container item xs={12} className={classes.Parrafo1} >
                        <Grid container justifyContent="center" xs={1}><img className='Pos img2' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion6/S.png" alt="" /></Grid>
                        <Grid container xs={11}>
                            <p className='Lectura2'>
                                <b className='Niña Sofia'>SOFÍA.</b> <em>(Enojada)</em> ¿Y yo qué? Yo soy tu mejor amiga.
                            </p>
                        </Grid>
                    </Grid>  

                    <Grid container item xs={12} className={classes.Parrafo1} >
                        <Grid container justifyContent="center" xs={1}><img className='Pos img4' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion6/F.png" alt="" /></Grid>
                        <Grid container xs={11}>
                            <p className='Lectura2'>
                                <b className='Niña Fatima'>FÁTIMA.</b> También podremos hablarnos. Le puedo pedir <br/>
                                a mi mamá que me  preste su teléfono para llamarte. Mi <br/>
                                mamá le pidió a tu mamá su número.
                            </p>
                        </Grid>
                    </Grid> 
                    
                    <Grid container item xs={12} className={classes.Parrafo1} >
                        <Grid container justifyContent="center" xs={1}><img className='Pos img2' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion6/S.png" alt="" /></Grid>
                        <Grid container xs={11}>
                            <p className='Lectura2'>
                                <b className='Niña Sofia'>SOFÍA. </b><em>(Feliz)</em> ¡Sí! (Se entristece de repente) Pero no será<br/>lo mismo.
                            </p>
                        </Grid>
                    </Grid>  

                    <Grid container item xs={12} className={classes.Parrafo1} >
                        <Grid container justifyContent="center" xs={1}><img className='Pos img2' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion6/F.png" alt="" /></Grid>
                        <Grid container xs={11}>
                            <p className='Lectura2'>
                                <b className='Niña Fatima'>FÁTIMA.</b> En las vacaciones podría venir a visitarte. O… <br/>
                                tú a mí.
                            </p>
                        </Grid>
                    </Grid> 
                    
                    <Grid container item xs={12} className={classes.Parrafo1} >
                        <Grid container justifyContent="center" xs={1}><img className='Pos img2' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion6/S.png" alt="" /></Grid>
                        <Grid container xs={11}>
                            <p className='Lectura2'>
                                <b className='Niña Sofia'>SOFÍA.</b> Sabes que no será lo mismo.
                            </p>
                        </Grid>
                    </Grid>  

                    <Grid container item xs={12} className={classes.Parrafo1} >
                        <Grid container justifyContent="center" xs={1}><img className='Pos img5' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion6/F.png" alt="" /></Grid>
                        <Grid container xs={11}>
                            <p className='Lectura2'>
                                <b className='Niña Fatima'>FÁTIMA.</b> Ya verás que será divertido cuando nos <br/>
                                volvamos a ver. Nos contaremos muchas cosas y <br/>
                                jugaremos mucho. (Le ofrece su almuerzo) ¿Quieres un <br/>
                                poco?
                            </p>
                        </Grid>
                    </Grid> 

                    <Grid container item xs={12} className={classes.Parrafo1} >
                        <Grid container xs={12} justifyContent='center'>
                                <p className='Lectura2'> <em>Telón</em> </p>
                        </Grid>
                        <Grid container xs={12} className='Linea'>
                            
                        </Grid>
                    </Grid> 

                    <Grid container item xs={12} className={classes.Parrafo1} >
                        <p className='Lectura'>
                            <h3 className='SubTitulo'>ACTO 2</h3><br/>
                            <em>Afuera del salón de clases. Se escucha a lo lejos un murmullo de  
                            niños jugando. Sofía está sentada en una banca, comiendo su 
                            almuerzo y hablando consigo misma enojada.
                            </em> 
                        </p>
                    </Grid>

                    <Grid container item xs={12} className={classes.Parrafo1} >
                        <Grid container justifyContent="center" xs={1}><img className='Pos img5' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion6/S2.png" alt="" /></Grid>
                        <Grid container xs={11}>
                            <p className='Lectura2'>
                                <b className='Niña Sofia'>SOFÍA.</b> Uy, sí, muy divertido. Pero divertido para ti,<br/> 
                                porque yo me aburro mucho ahora que no estás. Y ya no <br/> 
                                tengo a nadie con quién hablar. Pero tú de seguro ya<br/>
                                hiciste nuevas amigas…
                            </p>
                        </Grid>
                    </Grid>  

                    <Grid container item xs={12} className={classes.Parrafo1} >
                            <p className='Lectura2'>
                                <em>Entra Amelia</em>
                            </p>
                    </Grid>  

                    <Grid container item xs={12} className={classes.Parrafo1} >
                        <Grid container justifyContent="center" xs={1}><img className='Pos img2' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion6/A.png" alt="" /></Grid>
                        <Grid container xs={11}>
                            <p className='Lectura2'>
                                <b className='Niña Amelia'>AMELIA.</b> ¿Con quién hablas?
                            </p>
                        </Grid>
                    </Grid>

                    <Grid container item xs={12} className={classes.Parrafo1} >
                        <Grid container justifyContent="center" xs={1}><img className='Pos img2' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion6/S2.png" alt="" /></Grid>
                        <Grid container xs={11}>
                            <p className='Lectura2'>
                                <b className='Niña Sofia'>SOFÍA.</b><em> (Apenada) </em>¡Eh! ¡No! ¡Con nadie!
                            </p>
                        </Grid>
                    </Grid>  

                    <Grid container item xs={12} className={classes.Parrafo1} >
                        <Grid container justifyContent="center" xs={1}><img className='Pos img2' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion6/A.png" alt="" /></Grid>
                        <Grid container xs={11}>
                            <p className='Lectura2'>
                                <b className='Niña Amelia'>AMELIA.</b> ¿Con Fátima?
                            </p>
                        </Grid>
                    </Grid>

                    <Grid container item xs={12} className={classes.Parrafo1} >
                        <Grid container justifyContent="center" xs={1}><img className='Pos img2' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion6/S2.png" alt="" /></Grid>
                        <Grid container xs={11}>
                            <p className='Lectura2'>
                                <b className='Niña Sofia'>SOFÍA.</b> ¡No, cómo crees! Si ella ya se fue.
                            </p>
                        </Grid>
                    </Grid> 

                    <Grid container item xs={12} className={classes.Parrafo1} >
                        <Grid container justifyContent="center" xs={1}><img className='Pos img2' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion6/A.png" alt="" /></Grid>
                        <Grid container xs={11}>
                            <p className='Lectura2'>
                                <b className='Niña Amelia'>AMELIA.</b> ¿Y no la extrañas? Yo siempre las veía juntas.
                            </p>
                        </Grid>
                    </Grid> 

                    <Grid container item xs={12} className={classes.Parrafo1} >
                        <Grid container justifyContent="center" xs={1}><img className='Pos img2' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion6/S2.png" alt="" /></Grid>
                        <Grid container xs={11}>
                            <p className='Lectura2'>
                                <b className='Niña Sofia'>SOFÍA.</b> No, no la extraño. Estoy bien sola.
                            </p>
                        </Grid>
                    </Grid>

                    <Grid container item xs={12} className={classes.Parrafo1} >
                        <Grid container justifyContent="center" xs={1}><img className='Pos img2' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion6/A.png" alt="" /></Grid>
                        <Grid container xs={11}>
                            <p className='Lectura2'>
                                <b className='Niña Amelia'>AMELIA.</b> ¿No te aburre estar sola?
                            </p>
                        </Grid>
                    </Grid>  
                    
                    <Grid container item xs={12} className={classes.Parrafo1} >
                        <Grid container justifyContent="center" xs={1}><img className='Pos img2' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion6/S2.png" alt="" /></Grid>
                        <Grid container xs={11}>
                            <p className='Lectura2'>
                                <b className='Niña Sofia'>SOFÍA.</b> No, para nada.
                            </p>
                        </Grid>
                    </Grid>

                    <Grid container item xs={12} className={classes.Parrafo1} >
                        <Grid container justifyContent="center" xs={1}><img className='Pos img2' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion6/A.png" alt="" /></Grid>
                        <Grid container xs={11}>
                            <p className='Lectura2'>
                                <b className='Niña Amelia'>AMELIA.</b> ¿Me puedo sentar? <em>(Se sienta junto a Sofía)</em> Yo <br/>también la extraño.
                            </p>
                        </Grid>
                    </Grid>

                    <Grid container item xs={12} className={classes.Parrafo1} >
                        <Grid container justifyContent="center" xs={1}><img className='Pos img2' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion6/S2.png" alt="" /></Grid>
                        <Grid container xs={11}>
                            <p className='Lectura2'>
                                <b className='Niña Sofia'>SOFÍA.</b> ¡Yo no la extraño! ¿Qué quieres?
                            </p>
                        </Grid>
                    </Grid>  

                    <Grid container item xs={12} className={classes.Parrafo1} >
                        <Grid container justifyContent="center" xs={1}><img className='Pos img2' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion6/A.png" alt="" /></Grid>
                        <Grid container xs={11}>
                            <p className='Lectura2'>
                                <b className='Niña Amelia'>AMELIA.</b> <em>(Señala el almuerzo de Sofía)</em> ¿Me das un poco?
                            </p>
                        </Grid>
                    </Grid>

                    <Grid container item xs={12} className={classes.Parrafo1} >
                        <Grid container justifyContent="center" xs={1}><img className='Pos img2' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion6/S2.png" alt="" /></Grid>
                        <Grid container xs={11}>
                            <p className='Lectura2'>
                                <b className='Niña Sofia'>SOFÍA.</b> ¡No! ¡Sólo le comparto a Fátima!
                            </p>
                        </Grid>
                    </Grid> 

                    <Grid container item xs={12} className={classes.Parrafo1} >
                        <Grid container justifyContent="center" xs={1}><img className='Pos img2' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion6/A.png" alt="" /></Grid>
                        <Grid container xs={11}>
                            <p className='Lectura2'>
                                <b className='Niña Amelia'>AMELIA.</b> ¿Quieres jugar? Nos falta una niña para <br/>completar.
                            </p>
                        </Grid>
                    </Grid>

                    <Grid container item xs={12} className={classes.Parrafo1} >
                        <Grid container justifyContent="center" xs={1}><img className='Pos img2' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion6/S2.png" alt="" /></Grid>
                        <Grid container xs={11}>
                            <p className='Lectura2'>
                                <b className='Niña Sofia'>SOFÍA.</b> ¿A qué están jugando?
                            </p>
                        </Grid>
                    </Grid> 

                    <Grid container item xs={12} className={classes.Parrafo1} >
                        <Grid container justifyContent="center" xs={1}><img className='Pos img2' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion6/A.png" alt="" /></Grid>
                        <Grid container xs={11}>
                            <p className='Lectura2'>
                                <b className='Niña Amelia'>AMELIA.</b> Ven, te enseño.
                            </p>
                        </Grid>
                    </Grid>                          
                </Grid>                        
                

                <Grid container item xs={12} className={classes.Parrafo1} >
                    <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion6/2.png" alt="" />
                </Grid>
                
                <Grid container className='Escritorio'>
                    <Grid container item xs={12} className={classes.Parrafo1} >
                        <Grid container justifyContent="center" xs={1}><img className='Pos img2' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion6/S2.png" alt="" /></Grid>
                        <Grid container xs={11}>
                            <p className='Lectura2'>
                                <b className='Niña Sofia'>SOFÍA.</b> ¿En serio puedo jugar con ustedes?
                            </p>
                        </Grid>
                    </Grid> 

                    <Grid container item xs={12} className={classes.Parrafo1} >
                        <Grid container justifyContent="center" xs={1}><img className='Pos img' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion6/A.png" alt="" /></Grid>
                        <Grid container xs={11}>
                            <p className='Lectura2'>
                                <b className='Niña Amelia'>AMELIA.</b> Sí, claro. Ni modo que te quedes todo el tiempo aquí, sin hacer nada. <br/>
                                O, si lo prefieres, te dejo estar sola.
                            </p>
                        </Grid>
                    </Grid>

                    <Grid container item xs={12} className={classes.Parrafo1} >
                        <Grid container justifyContent="center" xs={1}><img className='Pos img2' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion6/S2.png" alt="" /></Grid>
                        <Grid container xs={11}>
                            <p className='Lectura2'>
                                <b className='Niña Sofia'>SOFÍA.</b> ¡No, claro que no! Vamos.
                            </p>
                        </Grid>
                    </Grid> 

                    <Grid container item xs={12} className={classes.Parrafo1} >
                            <p className='Lectura2'>
                                <em>Sofía y Amelia salen. El almuerzo de Sofía se queda en la banca.</em>
                            </p>
                    </Grid> 

                    <Grid container item xs={12} className={classes.Parrafo1} >
                        <Grid container xs={12} justifyContent='center'>
                                <p className='Lectura2'> <em>Telón</em> </p>
                        </Grid>
                        <Grid container xs={12} className='Linea'>
                            
                        </Grid>
                    </Grid> 

                    <Grid container item xs={12} className={classes.Parrafo1} >
                        <p className='Lectura'>
                            <h3 className='SubTitulo'>ACTO 3</h3><br/>
                            <em>Sala-comedor de una casa. Sofía está recostada en un sillón mientras habla vía celular.</em> 
                        </p>
                    </Grid>

                    <Grid container item xs={12} className={classes.Parrafo1} >
                        <Grid container justifyContent="center" xs={1}><img className='Pos img' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion6/S3.png" alt="" /></Grid>
                        <Grid container xs={11}>
                            <p className='Lectura2'>
                                <b className='Niña Sofia'>SOFÍA.</b> …y la maestra Leticia sólo dijo: “¿Dónde están mis lentes?” <em>(Ríe)</em> Y <br/>
                                nadie le dijo que los traía en la cabeza.
                            </p>
                        </Grid>
                    </Grid> 
                    <Grid container item xs={12} className={classes.Parrafo1} >
                        <Grid container justifyContent="center" xs={1}><img className='Pos img2' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion6/F2.png" alt="" /></Grid>
                        <Grid container xs={11}>
                            <p className='Lectura2'>
                                <b className='Niña Fatima'>VOZ DE FÁTIMA.</b> <em>(Ríe)</em> De seguro le echó la culpa a Santiago.
                            </p>
                        </Grid>
                    </Grid> 

                    <Grid container item xs={12} className={classes.Parrafo1} >
                        <Grid container justifyContent="center" xs={1}><img className='Pos img2' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion6/S3.png" alt="" /></Grid>
                        <Grid container xs={11}>
                            <p className='Lectura2'>
                                <b className='Niña Sofia'>SOFÍA.</b> <em>(Deja de reír)</em> ¿Quién es Santiago?
                            </p>
                        </Grid>
                    </Grid> 
                    
                    <Grid container item xs={12} className={classes.Parrafo1} >
                        <Grid container justifyContent="center" xs={1}><img className='Pos img2' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion6/F2.png" alt="" /></Grid>
                        <Grid container xs={11}>
                            <p className='Lectura2'>
                                <b className='Niña Fatima'>VOZ DE FÁTIMA.</b> Ah, no, a Leonardo, quise decir.
                            </p>
                        </Grid>
                    </Grid> 

                    <Grid container item xs={12} className={classes.Parrafo1} >
                        <Grid container justifyContent="center" xs={1}><img className='Pos img2' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion6/S3.png" alt="" /></Grid>
                        <Grid container xs={11}>
                            <p className='Lectura2'>
                                <b className='Niña Sofia'>SOFÍA.</b> ¿Quién es Santiago?
                            </p>
                        </Grid>
                    </Grid> 

                    <Grid container item xs={12} className={classes.Parrafo1} >
                        <Grid container justifyContent="center" xs={1}><img className='Pos img2' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion6/F2.png" alt="" /></Grid>
                        <Grid container xs={11}>
                            <p className='Lectura2'>
                                <b className='Niña Fatima'>VOZ DE FÁTIMA.</b> Es un niño de mi escuela.
                            </p>
                        </Grid>
                    </Grid> 

                    <Grid container item xs={12} className={classes.Parrafo1} >
                        <Grid container justifyContent="center" xs={1}><img className='Pos img2' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion6/S3.png" alt="" /></Grid>
                        <Grid container xs={11}>
                            <p className='Lectura2'>
                                <b className='Niña Sofia'>SOFÍA.</b> ¡Ah…! ¿Y qué tal te va en tu nueva escuela?
                            </p>
                        </Grid>
                    </Grid> 

                    <Grid container item xs={12} className={classes.Parrafo1} >
                        <Grid container justifyContent="center" xs={1}><img className='Pos img2' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion6/F2.png" alt="" /></Grid>
                        <Grid container xs={11}>
                            <p className='Lectura2'>
                                <b className='Niña Fatima'>VOZ DE FÁTIMA.</b> Bien, pero te extraño.
                            </p>
                        </Grid>
                    </Grid> 

                    <Grid container item xs={12} className={classes.Parrafo1} >
                        <Grid container justifyContent="center" xs={1}><img className='Pos img2' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion6/S3.png" alt="" /></Grid>
                        <Grid container xs={11}>
                            <p className='Lectura2'>
                                <b className='Niña Sofia'>SOFÍA.</b> Yo también te extraño.
                            </p>
                        </Grid>
                    </Grid> 

                    <Grid container item xs={12} className={classes.Parrafo1} >
                        <Grid container justifyContent="center" xs={1}><img className='Pos img' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion6/F2.png" alt="" /></Grid>
                        <Grid container xs={11}>
                            <p className='Lectura2'>
                                <b className='Niña Fatima'>VOZ DE FÁTIMA.</b> ¿Y no juegas con nadie en el receso? ¿Con Amelia, por <br/>
                                ejemplo?
                            </p>
                        </Grid>
                    </Grid> 

                    <Grid container item xs={12} className={classes.Parrafo1} >
                        <Grid container justifyContent="center" xs={1}><img className='Pos img' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion6/S3.png" alt="" /></Grid>
                        <Grid container xs={11}>
                            <p className='Lectura2'>
                                <b className='Niña Sofia'>SOFÍA.</b> Esa niña… La otra vez me pidió que le compartiera de mi almuerzo, <br/>
                                ¿tú crees?
                            </p>
                        </Grid>
                    </Grid> 

                    <Grid container item xs={12} className={classes.Parrafo1} >
                        <Grid container justifyContent="center" xs={1}><img className='Pos img2' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion6/F2.png" alt="" /></Grid>
                        <Grid container xs={11}>
                            <p className='Lectura2'>
                                <b className='Niña Fatima'>VOZ DE FÁTIMA.</b> Sí, así ha sido siempre… ¿Y no juegas con ella?
                            </p>
                        </Grid>
                    </Grid> 

                    <Grid container item xs={12} className={classes.Parrafo1} >
                        <Grid container justifyContent="center" xs={1}><img className='Pos img2' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion6/S3.png" alt="" /></Grid>
                        <Grid container xs={11}>
                            <p className='Lectura2'>
                                <b className='Niña Sofia'>SOFÍA.</b> No, con nadie… ¿Y tú?
                            </p>
                        </Grid>
                    </Grid> 

                    <Grid container item xs={12} className={classes.Parrafo1} >
                        <Grid container justifyContent="center" xs={1}><img className='Pos img' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion6/F2.png" alt="" /></Grid>
                        <Grid container xs={11}>
                            <p className='Lectura2'>
                                <b className='Niña Fatima'>VOZ DE FÁTIMA.</b> No, cómo crees, con nadie… Bueno, hay una niña… Pero <br/>
                                no, no juego con ella…
                            </p>
                        </Grid>
                    </Grid> 

                    <Grid container item xs={12} className={classes.Parrafo1} >
                        <Grid container justifyContent="center" xs={1}><img className='Pos img2' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion6/S3.png" alt="" /></Grid>
                        <Grid container xs={11}>
                            <p className='Lectura2'>
                                <b className='Niña Sofia'>SOFÍA.</b> Lo bueno es que ya pronto serán vacaciones.
                            </p>
                        </Grid>
                    </Grid>

                    <Grid container item xs={12} className={classes.Parrafo1} >
                        <Grid container justifyContent="center" xs={1}><img className='Pos img' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion6/F2.png" alt="" /></Grid>
                        <Grid container xs={11}>
                            <p className='Lectura2'>
                                <b className='Niña Fatima'>VOZ DE FÁTIMA.</b> <em>(Emocionada)</em> ¡Sí! Les dije a mis papás que si podía visitarte <br/>
                                y me dijeron que sí.
                            </p>
                        </Grid>
                    </Grid> 

                    <Grid container item xs={12} className={classes.Parrafo1} >
                        <Grid container justifyContent="center" xs={1}><img className='Pos img2' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion6/S3.png" alt="" /></Grid>
                        <Grid container xs={11}>
                            <p className='Lectura2'>
                                <b className='Niña Sofia'>SOFÍA.</b> ¡Qué bueno! Oye, ya me voy, porque tengo que hacer la tarea.
                            </p>
                        </Grid>
                    </Grid>

                    <Grid container item xs={12} className={classes.Parrafo1} >
                        <Grid container justifyContent="center" xs={1}><img className='Pos img2' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion6/F2.png" alt="" /></Grid>
                        <Grid container xs={11}>
                            <p className='Lectura2'>
                                <b className='Niña Fatima'>VOZ DE FÁTIMA.</b> Sí, yo también. Luego hablamos. Adiós.
                            </p>
                        </Grid>
                    </Grid> 

                    <Grid container  className={classes.Parrafo1}>
                        <p className='Lectura'><em>(Sofía no contesta mientras toca el teléfono celular y hace algunos movimientos con su <br/>
                        dedo índice.)</em></p>
                    </Grid>

                    <Grid container item xs={12} className={classes.Parrafo1} >
                        <Grid container justifyContent="center" xs={1}><img className='Pos img2' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion6/F2.png" alt="" /></Grid>
                        <Grid container xs={11}>
                            <p className='Lectura2'>
                                <b className='Niña Fatima'>VOZ DE FÁTIMA.</b>¡Ay! ¿Por qué no contestas?
                            </p>
                        </Grid>
                    </Grid> 

                    <Grid container item xs={12} className={classes.Parrafo1} >
                        <Grid container justifyContent="center" xs={1}><img className='Pos img2' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion6/S3.png" alt="" /></Grid>
                        <Grid container xs={11}>
                            <p className='Lectura2'>
                                <b className='Niña Sofia'>SOFÍA.</b> <em>(Apenada)</em> Perdón… Es que estaba… haciendo la tarea… Adiós.
                            </p>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container className='Tablet'>
                    <Grid container item xs={12} className={classes.Parrafo1} >
                        <Grid container justifyContent="center" xs={1}><img className='Pos img2' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion6/S2.png" alt="" /></Grid>
                        <Grid container xs={11}>
                            <p className='Lectura2'>
                                <b className='Niña Sofia'>SOFÍA.</b> ¿En serio puedo jugar con ustedes?
                            </p>
                        </Grid>
                    </Grid> 

                    <Grid container item xs={12} className={classes.Parrafo1} >
                        <Grid container justifyContent="center" xs={1}><img className='Pos img4' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion6/A.png" alt="" /></Grid>
                        <Grid container xs={11}>
                            <p className='Lectura2'>
                                <b className='Niña Amelia'>AMELIA.</b> Sí, claro. Ni modo que te quedes todo el  <br/>
                                tiempo aquí, sin hacer nada. O, si lo prefieres, te dejo <br/>estar sola.
                            </p>
                        </Grid>
                    </Grid>

                    <Grid container item xs={12} className={classes.Parrafo1} >
                        <Grid container justifyContent="center" xs={1}><img className='Pos img2' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion6/S2.png" alt="" /></Grid>
                        <Grid container xs={11}>
                            <p className='Lectura2'>
                                <b className='Niña Sofia'>SOFÍA.</b> ¡No, claro que no! Vamos.
                            </p>
                        </Grid>
                    </Grid> 

                    <Grid container item xs={12} className={classes.Parrafo1} >
                            <p className='Lectura2'>
                                <em>Sofía y Amelia salen. El almuerzo de Sofía se queda en la banca.</em>
                            </p>
                    </Grid> 

                    <Grid container item xs={12} className={classes.Parrafo1} >
                        <Grid container xs={12} justifyContent='center'>
                                <p className='Lectura2'> <em>Telón</em> </p>
                        </Grid>
                        <Grid container xs={12} className='Linea'>
                            
                        </Grid>
                    </Grid> 

                    <Grid container item xs={12} className={classes.Parrafo1} >
                        <p className='Lectura'>
                            <h3 className='SubTitulo'>ACTO 3</h3><br/>
                            <em>Sala-comedor de una casa. Sofía está recostada en un sillón mientras habla vía celular.</em> 
                        </p>
                    </Grid>

                    <Grid container item xs={12} className={classes.Parrafo1} >
                        <Grid container justifyContent="center" xs={1}><img className='Pos img4' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion6/S3.png" alt="" /></Grid>
                        <Grid container xs={11}>
                            <p className='Lectura2'>
                                <b className='Niña Sofia'>SOFÍA.</b> …y la maestra Leticia sólo dijo: “¿Dónde están<br/>
                                 mis lentes?” <em>(Ríe)</em> Y nadie le dijo que los traía en la<br/>
                                 cabeza.
                            </p>
                        </Grid>
                    </Grid> 

                    <Grid container item xs={12} className={classes.Parrafo1} >
                        <Grid container justifyContent="center" xs={1}><img className='Pos img2' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion6/F2.png" alt="" /></Grid>
                        <Grid container xs={11}>
                            <p className='Lectura2'>
                                <b className='Niña Fatima'>VOZ DE FÁTIMA.</b> <em>(Ríe)</em> De seguro le echó la culpa a <br/>Santiago.
                            </p>
                        </Grid>
                    </Grid> 

                    <Grid container item xs={12} className={classes.Parrafo1} >
                        <Grid container justifyContent="center" xs={1}><img className='Pos img2' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion6/S3.png" alt="" /></Grid>
                        <Grid container xs={11}>
                            <p className='Lectura2'>
                                <b className='Niña Sofia'>SOFÍA.</b> <em>(Deja de reír)</em> ¿Quién es Santiago?
                            </p>
                        </Grid>
                    </Grid> 

                    <Grid container item xs={12} className={classes.Parrafo1} >
                        <Grid container justifyContent="center" xs={1}><img className='Pos img2' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion6/F2.png" alt="" /></Grid>
                        <Grid container xs={11}>
                            <p className='Lectura2'>
                                <b className='Niña Fatima'>VOZ DE FÁTIMA.</b> <em>(Ríe)</em> De seguro le echó la culpa a Santiago.
                            </p>
                        </Grid>
                    </Grid> 

                    <Grid container item xs={12} className={classes.Parrafo1} >
                        <Grid container justifyContent="center" xs={1}><img className='Pos img2' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion6/S3.png" alt="" /></Grid>
                        <Grid container xs={11}>
                            <p className='Lectura2'>
                                <b className='Niña Sofia'>SOFÍA.</b> <em>(Deja de reír)</em> ¿Quién es Santiago?
                            </p>
                        </Grid>
                    </Grid> 
                    
                    <Grid container item xs={12} className={classes.Parrafo1} >
                        <Grid container justifyContent="center" xs={1}><img className='Pos img2' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion6/F2.png" alt="" /></Grid>
                        <Grid container xs={11}>
                            <p className='Lectura2'>
                                <b className='Niña Fatima'>VOZ DE FÁTIMA.</b> Ah, no, a Leonardo, quise decir.
                            </p>
                        </Grid>
                    </Grid> 

                    <Grid container item xs={12} className={classes.Parrafo1} >
                        <Grid container justifyContent="center" xs={1}><img className='Pos img2' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion6/S3.png" alt="" /></Grid>
                        <Grid container xs={11}>
                            <p className='Lectura2'>
                                <b className='Niña Sofia'>SOFÍA.</b> ¿Quién es Santiago?
                            </p>
                        </Grid>
                    </Grid> 

                    <Grid container item xs={12} className={classes.Parrafo1} >
                        <Grid container justifyContent="center" xs={1}><img className='Pos img2' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion6/F2.png" alt="" /></Grid>
                        <Grid container xs={11}>
                            <p className='Lectura2'>
                                <b className='Niña Fatima'>VOZ DE FÁTIMA.</b> Es un niño de mi escuela.
                            </p>
                        </Grid>
                    </Grid> 

                    <Grid container item xs={12} className={classes.Parrafo1} >
                        <Grid container justifyContent="center" xs={1}><img className='Pos img2' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion6/S3.png" alt="" /></Grid>
                        <Grid container xs={11}>
                            <p className='Lectura2'>
                                <b className='Niña Sofia'>SOFÍA.</b> ¡Ah…! ¿Y qué tal te va en tu nueva escuela?
                            </p>
                        </Grid>
                    </Grid> 

                    <Grid container item xs={12} className={classes.Parrafo1} >
                        <Grid container justifyContent="center" xs={1}><img className='Pos img2' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion6/F2.png" alt="" /></Grid>
                        <Grid container xs={11}>
                            <p className='Lectura2'>
                                <b className='Niña Fatima'>VOZ DE FÁTIMA.</b> Bien, pero te extraño.
                            </p>
                        </Grid>
                    </Grid> 

                    <Grid container item xs={12} className={classes.Parrafo1} >
                        <Grid container justifyContent="center" xs={1}><img className='Pos img2' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion6/S3.png" alt="" /></Grid>
                        <Grid container xs={11}>
                            <p className='Lectura2'>
                                <b className='Niña Sofia'>SOFÍA.</b> Yo también te extraño.
                            </p>
                        </Grid>
                    </Grid> 

                    <Grid container item xs={12} className={classes.Parrafo1} >
                        <Grid container justifyContent="center" xs={1}><img className='Pos img' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion6/F2.png" alt="" /></Grid>
                        <Grid container xs={11}>
                            <p className='Lectura2'>
                                <b className='Niña Fatima'>VOZ DE FÁTIMA.</b> ¿Y no juegas con nadie en el receso? <br/>
                                ¿Con Amelia, por ejemplo?
                            </p>
                        </Grid>
                    </Grid> 

                    <Grid container item xs={12} className={classes.Parrafo1} >
                        <Grid container justifyContent="center" xs={1}><img className='Pos img' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion6/S3.png" alt="" /></Grid>
                        <Grid container xs={11}>
                            <p className='Lectura2'>
                                <b className='Niña Sofia'>SOFÍA.</b> Esa niña… La otra vez me pidió que le <br/>
                                compartiera de mi almuerzo, ¿tú crees?
                            </p>
                        </Grid>
                    </Grid> 

                    <Grid container item xs={12} className={classes.Parrafo1} >
                        <Grid container justifyContent="center" xs={1}><img className='Pos img2' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion6/F2.png" alt="" /></Grid>
                        <Grid container xs={11}>
                            <p className='Lectura2'>
                                <b className='Niña Fatima'>VOZ DE FÁTIMA.</b> Sí, así ha sido siempre… ¿Y no juegas <br/>con ella?
                            </p>
                        </Grid>
                    </Grid> 

                    <Grid container item xs={12} className={classes.Parrafo1} >
                        <Grid container justifyContent="center" xs={1}><img className='Pos img2' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion6/S3.png" alt="" /></Grid>
                        <Grid container xs={11}>
                            <p className='Lectura2'>
                                <b className='Niña Sofia'>SOFÍA.</b> No, con nadie… ¿Y tú?
                            </p>
                        </Grid>
                    </Grid> 

                    <Grid container item xs={12} className={classes.Parrafo1} >
                        <Grid container justifyContent="center" xs={1}><img className='Pos img' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion6/F2.png" alt="" /></Grid>
                        <Grid container xs={11}>
                            <p className='Lectura2'>
                                <b className='Niña Fatima'>VOZ DE FÁTIMA.</b> No, cómo crees, con nadie… Bueno, <br/>
                                hay una niña… Pero no, no juego con ella…
                                
                            </p>
                        </Grid>
                    </Grid> 

                    <Grid container item xs={12} className={classes.Parrafo1} >
                        <Grid container justifyContent="center" xs={1}><img className='Pos img2' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion6/S3.png" alt="" /></Grid>
                        <Grid container xs={11}>
                            <p className='Lectura2'>
                                <b className='Niña Sofia'>SOFÍA.</b> Lo bueno es que ya pronto serán vacaciones.
                            </p>
                        </Grid>
                    </Grid>

                    <Grid container item xs={12} className={classes.Parrafo1} >
                        <Grid container justifyContent="center" xs={1}><img className='Pos img' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion6/F2.png" alt="" /></Grid>
                        <Grid container xs={11}>
                            <p className='Lectura2'>
                                <b className='Niña Fatima'>VOZ DE FÁTIMA.</b> <em>(Emocionada)</em> ¡Sí! Les dije a mis<br/>
                                papás que si podía visitarte y me dijeron que sí. <br/>
                            </p>
                        </Grid>
                    </Grid> 

                    <Grid container item xs={12} className={classes.Parrafo1} >
                        <Grid container justifyContent="center" xs={1}><img className='Pos img' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion6/S3.png" alt="" /></Grid>
                        <Grid container xs={11}>
                            <p className='Lectura2'>
                                <b className='Niña Sofia'>SOFÍA.</b> ¡Qué bueno! Oye, ya me voy, porque tengo que <br/> hacer la tarea.
                            </p>
                        </Grid>
                    </Grid>

                    <Grid container item xs={12} className={classes.Parrafo1} >
                        <Grid container justifyContent="center" xs={1}><img className='Pos img' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion6/F2.png" alt="" /></Grid>
                        <Grid container xs={11}>
                            <p className='Lectura2'>
                                <b className='Niña Fatima'>VOZ DE FÁTIMA.</b> Sí, yo también. Luego hablamos. <br/>Adiós.
                            </p>
                        </Grid>
                    </Grid> 

                    <Grid container  className={classes.Parrafo1}>
                        <p className='Lectura'><em>(Sofía no contesta mientras toca el teléfono celular y hace algunos<br/>
                         movimientos con su dedo índice.)</em></p>
                    </Grid>

                    <Grid container item xs={12} className={classes.Parrafo1} >
                        <Grid container justifyContent="center" xs={1}><img className='Pos img2' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion6/F2.png" alt="" /></Grid>
                        <Grid container xs={11}>
                            <p className='Lectura2'>
                                <b className='Niña Fatima'>VOZ DE FÁTIMA.</b>¡Ay! ¿Por qué no contestas?
                            </p>
                        </Grid>
                    </Grid> 

                    <Grid container item xs={12} className={classes.Parrafo1} >
                        <Grid container justifyContent="center" xs={1}><img className='Pos img' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion6/S3.png" alt="" /></Grid>
                        <Grid container xs={11}>
                            <p className='Lectura2'>
                                <b className='Niña Sofia'>SOFÍA.</b> <em>(Apenada)</em> Perdón… Es que estaba… haciendo la <br/>tarea… Adiós.
                            </p>
                        </Grid>
                    </Grid>
                </Grid>
                

                <Grid container item xs={12} className={classes.Parrafo1} >
                    <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion6/3.png" alt="" />
                </Grid>
                       
                <Grid container item xs={12} justifyContent="center" className="ContainerDerechos">
                    <p className="Derechos">
                        © 2022. Todos los derechos reservados, Pearson, Circuito de lectores.
                    </p>                
                </Grid>
            </Grid>
        </Grid>
    );
}
 
export default La_despedida;
