import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
    ContenedorLectura:{
        width:'502px',
                  [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
    },
    ContainerTitulo:{
        margin:'0 0 36px 0',
        '& .Titulo':{
            letterSpacing: '0px',
            color: '#D6810C',
            margin:'0',
            textAlign:'center',
            font:'43px fredoka one',
            lineHeight:'52px',
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            margin:'0 0 36px 0',
            color:'#000',
            font:'18px lato',
            lineHeight :'28px',
            letterSpacing: '0.17px',
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .SubTitulo':{
            margin:'0 ',
            color:'#F78572',
            font:'bold 22px lato',
            lineHeight :'24px',
            letterSpacing: '0.21px',
            [theme.breakpoints.down(502)]:{
                font:'bold 18px lato',
                lineHeight :'20px',
                letterSpacing: '0.16px',
            },
        },
        '& .TextImg':{
            color:'#717476',
            margin:'22px 0 0 0',
            font:' 16px lato',
            lineHeight :'25px',
            letterSpacing: '0.15px',
            [theme.breakpoints.down(502)]:{
                font:' 11px lato',
                lineHeight :'14px',
                letterSpacing: '0.10px',
            },
        },
        '& .Imagen':{
            width:'100%',
        },
    },

}));

const Qué_hace_un_arqueólogo = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >
                        ¿Qué hace un<br/> arqueólogo?
                    </h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                            ¿Alguna vez has escuchado hablar sobre la arqueología? Es la ciencia que estudia las 
                            civilizaciones del pasado a través de sus restos, por ejemplo: los monumentos, las 
                            pinturas, los monolitos y otros objetos de distintos materiales. Un arqueólogo realiza 
                            diversas actividades: busca tesoros, explora las ruinas y elabora varios planos y 
                            maquetas. También analiza restos humanos, tales como huesos y objetos que la 
                            humanidad ha dejado a lo largo del tiempo como testimonio de su existencia. ¿No te 
                            parece emocionante? ¡Quién sabe qué tanto se podría encontrar en una sola 
                            excavación!
                        </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <Grid container >
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img3ro/Sesion22/1.png" alt="Tarjeta" />
                        </Grid>
                        <Grid container justifyContent="center">
                            <p className='TextImg'> 
                                Una excavación arqueológica puede tener grandes hallazgos, desde pequeñas casas hasta 
                                ciudades enteras que fueron sepultadas en el tiempo.
                            </p>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                            Como te das cuenta, la arqueología tiene mucho que ver con otras ciencias, como la 
                            historia y la geografía. Aunque cada una tenga nombres distintos se relacionan y se 
                            ayudan mutuamente. De hecho, muchos historiadores necesitan del trabajo de los 
                            arqueólogos para realizar sus propias investigaciones. Y éstos se sirven de lo que 
                            escriben los historiadores para guiar sus búsquedas y excavaciones. El mundo de las 
                            ciencias es enorme. ¡Forma una gran familia!<br/><br/> 
                            
                            Por otra parte, en las investigaciones de este campo se emplean herramientas y 
                            conocimientos de otras áreas, como la biología, la arquitectura y la informática. Se 
                            conoce como <em>registros arqueológicos</em> a los hallazgos que se hacen en dichas 
                            exploraciones. A ellos se les aplican pruebas físicas y químicas que ayudan a estudiar 
                            las costumbres y los estilos de vida que han existido a lo largo de la historia. ¡Es 
                            realmente emocionante ver cómo las distintas ciencias se combinan para obtener y 
                            registrar esta información!<br/><br/> 
                            
                            <h3 className='SubTitulo'>Tipos de arqueología</h3><br/>
                            
                            ¿Sabías que hay muchos tipos de arqueología? Cada uno de ellos se enfoca en temas 
                            diferentes. Existe la de campos de batalla, la cual estudia todo lo referente a las 
                            guerras. La histórica investiga evidencias que han quedado por escrito en diversos 
                            materiales, como papel, piedra y tela. La cognitiva busca comprender el pensamiento 
                            de las sociedades antiguas, para lo cual estudia su cultura y sus respectivos símbolos.
                            Y la de género observa el comportamiento de los hombres y las mujeres en estas 
                            mismas sociedades a lo largo del tiempo.
                        </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <Grid container >
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img3ro/Sesion22/2.png" alt="Tarjeta" />
                        </Grid>
                        <Grid container justifyContent="center">
                            <p className='TextImg'> 
                                Así luce un laboratorio arqueológico. Es realmente sorprendente, ¿no te parece? ¿A ti te 
                                gustaría visitar uno algún día?
                            </p>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                            Como puedes observar, esta ciencia sirve para recrear la historia. Gracias a todas las 
                            investigaciones que se han realizado, es posible que disfrutemos de piezas y objetos 
                            valiosos en los museos que existen en distintas partes del mundo. ¿Has visto alguna 
                            exposición donde exhiban estas piezas? ¿Qué es lo que más te ha llamado la atención 
                            de ellas?
                        </p>
                    </Grid>
                </Grid>
        </Grid>
    </Grid>
    );
}
 
export default Qué_hace_un_arqueólogo;
