import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
      Contenedor:{
        background:'#E9FBF9',
        width:'100vw',
        overflowX:'clip',
        [theme.breakpoints.down(1161)]:{
            background:'none',
           overflowX:'unset',
        },
        [theme.breakpoints.down(600)]:{
            marginTop: '79.51px',
        }
    },
    ContainerInfoLectura:{
        position:'relative',
        height:'86px',
        background:'#817FD9',
        '& .BarraInfoLectura':{
            height:'86px',
            background:`Url(https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Barras/Morada.png)`,

        },
        '& .InfoLectura':{
            position:'absolute',
            height:'86px',
            width:'219px',
            boxSizing:'border-box',
            padding:'17.2px 0',
            background:'#FAFAFA',
            borderRadius:'50px',
            boxShadow: '2px 2px 2px 2px #00000029',
            zIndex:'4',
        },
        '& .InfoLectura:before':{
            position:'absolute',
            display:'flex',
            boxSizing:'border-box',
            padding:'0 0 0 10px',
            justifyContent:'center',
            alignItems:'center',
            height:'39.88px',
            width:'39.88px',
            borderRadius:'50%',
            content:'">"',
            boxShadow: '3px 0px .5px 0px #00000029',
            background:'#FAFAFA',
            left:'90px',
            top:'65px',
            font:'bold 20px lato',
            color:'#926DD1',
            transform: 'rotate(0.25turn)',
            },
        '& .Sesion':{
            borderLeft:'2px solid #D8D8D8',
        },
        '& .Dato':{
            margin:'0 0 5px 0',
            textAlign:'center',
            font:'bold 17px lato',
            color:'#926DD1',
        },
        '& .Caracter':{
            margin:'0',
            textAlign:'center',
            font:'bold 17px lato',
            color:'#000000',
        },
    },
    ContenedorLectura:{
        background:'#fff',
        width:'1160px',
        height:'100%',
        padding:'57px 151px 35px' ,
        [theme.breakpoints.down(1161)]:{
            width:'502px',
            padding:'60px 0 0',
          },
          [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
        '& .ContainerDerechos':{
            bottom:'80px',
            [theme.breakpoints.down(1161)]:{
                display:'none',
            }
        },
        '& .Derechos':{
            textAlign:"center",
            margin:"0px",
            font:" 15px lato",
            color:"#717476",
        },
        '& .Escritorio':{
            display:'block',
            [theme.breakpoints.down(1161)]:{
                display:'none'
            },
        },
        '& .Tablet':{
            display:'none',
            [theme.breakpoints.down(1161)]:{
                display:'block'
            },
        },
    },
    ContainerTitulo:{
        margin:'0 0 47px 0',
        [theme.breakpoints.down(1161)]:{
            margin:'0 0 38px 0',
        },
        '& .Titulo':{
            font:'48px fredoka one',
            letterSpacing: '0px',
            color: '#161CB1',
            margin:'0',
            textAlign:'center',
            [theme.breakpoints.down(1161)]:{
                font:'43px fredoka one',
                lineHeight:'52px',
                textAlign:'center',
            },
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
      
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            lineHeight : '30px',
            margin:'0 0 36px 0 ',
            font:'22px lato',
            color:'#000',
            letterSpacing: '0.20px',
            [theme.breakpoints.down(1161)]:{
                lineHeight :'28px',
                font:'18px lato',
                letterSpacing: '0.16px',
            },
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .Imagen':{
            [theme.breakpoints.down(1161)]:{
                width:'100%',
            },
        },
    },

}));

const Principios_éticos_para_un_mundo_mejor = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
             <Grid container item justifyContent="center" className={classes.ContainerInfoLectura}>
               <Grid container className="BarraInfoLectura"></Grid>
               <Grid container item className="InfoLectura">
                    <Grid container item direction="column" xs={6} justifyContent="center">
                        <p className="Dato">Grado</p>
                        <p className="Caracter">6</p>
                    </Grid>
                    <Grid container item direction="column" xs={6} justifyContent="center" className="Sesion">
                        <p className="Dato">Sesión</p>
                        <p className="Caracter">20</p>
                    </Grid>
               </Grid>
            </Grid>

            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >
                        Ciudades inmortales
                    </h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura ">
                            Sofía Lem se interesó por el pasado desde pequeña. Tan pronto como aprendió a leer 
                            devoró todos los libros de Historia y Arqueología que su abuelo atesoraba en la 
                            biblioteca de su casa. Sofía le preguntaba a él cuando no entendía algo y siempre 
                            obtenía una respuesta satisfactoria. Su abuelo era el hombre más sabio que conocía. <br/><br/>
                            
                            —¿Tú eres historiador? —le preguntó un día mientras leían juntos en la biblioteca un 
                            libro sobre los hallazgos arqueológicos en Roma y Egipto. <br/><br/>
                            
                            —No, pequeña. Pero siempre me apasionó el pasado, como a ti. Por eso sé tantas 
                            cosas. <br/><br/>

                            —¡Eso significa que tú me heredaste el amor por la Historia! <br/><br/>
                            
                            —Sí, creo que así fue. <br/><br/>
                            
                            Sofía se quedó pensando un instante con la mirada perdida en sus ilusiones y 
                            después dijo: <br/><br/>
                            
                            —Abuelo, yo de grande quiero ser arqueóloga. <br/><br/>
                            
                            —Me parece maravilloso, pequeña. Hace falta alguien que rescate nuestra Historia 
                            desde lo profundo de la tierra. Estoy seguro de que en América y en nuestro país 
                            tenemos un pasado muy rico aún por descubrir —dijo y abrazó a su nieta.
                        </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                            <img className='Imagen' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion20/1.png"  alt="Tarjeta" />
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura ">
                            A partir de ese momento Sofía se dedicó en cuerpo y alma al estudio de la Historia. A 
                            los 22 años se convirtió en la primera arqueóloga de su país. En aquella época los 
                            arqueólogos de todo el mundo buscaban con fervor la mítica ciudad de Ligum. Según 
                            los textos antiguos, esta urbe fue construida en su mayoría con maderas preciosas 
                            perennes, pero algunos afirmaban que nunca había existido, pues ningún tipo de 
                            madera podría resistir tantos años debajo de la tierra.<br/><br/>
                            
                            —A menos que sea mágica —dijo Sofía Lem cuando anunció que dirigiría una 
                            excavación en su país para encontrar la ciudad de las maderas eternas. <br/><br/>
                            
                            La noticia causó revuelo en todo el mundo. Los arqueólogos más experimentados 
                            aseguraban que si Ligum existió realmente debía hallarse en China o en Europa 
                            oriental, pero jamás en América. En consecuencia, nadie confió en el proyecto de 
                            Sofía Lem.
                        </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                            <img className='Imagen' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion20/2.png"  alt="Tarjeta" />
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura ">
                            Al principio ni siquiera ella estaba segura del todo. Pero una vez más, su abuelo la 
                            orientó. <br/><br/>
                            
                            —¿Has oído hablar de los pinos longevos? <br/><br/>
                            
                            —No, abuelo. <br/><br/>
                            
                            —Son árboles que han vivido más de 5 000 años en perfectas condiciones. Así que 
                            ¿por qué no podría existir esa ciudad? <br/><br/>
                            
                            Esta conversación le infundió el valor que le faltaba. Retomó su investigación y visitó 
                            todas las bibliotecas en las que podía haber algún documento que hablara sobre 
                            Ligum. Cuando tuvo datos suficientes elaboró diagramas precisos de los ríos y las 
                            montañas que pudieron estar próximos a la ciudad de las maderas inmortales. Así 
                            concluyó que el lugar donde había que excavar era justamente su país. <br/><br/>
                            
                            Sofía Lem vivió meses enteros en casas de campaña con su equipo. Exploró el terreno 
                            palmo a palmo, comparó sus diagramas con el paisaje natural. Y un día que la lluvia 
                            despejó la ladera de una montaña encontró lo que parecía la cúpula de una 
                            construcción antigua. De inmediato ella y sus colaboradores acordonaron la zona. 
                            ¡Estaba segura de que se trataba de un hallazgo importante!
                        </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                            <img className='Imagen' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion20/3.png"  alt="Tarjeta" />
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura ">
                            —Mañana a primera hora que ya no llueva empezaremos a trabajar—dijo Sofía y se 
                            retiró a su casa de campaña. <br/><br/>
                            
                            Esa noche ningún miembro de la expedición pudo dormir por la emoción y los nervios 
                            que les provocaba la idea de estar encima de la ciudad de las maderas inmortales. Si 
                            sus estudios y sus cálculos no estaban equivocados, Sofía Lem se convertiría en una 
                            célebre arqueóloga. <br/><br/>
                            
                            “Y todo gracias a ti, abuelo”, pensó. <br/><br/>
                            
                            El trabajo duró mucho tiempo y fue muy difícil. Sin embargo, el esfuerzo tuvo su 
                            recompensa. Una tarde, cuando casi se ocultaba el sol, Sofía Lem y su equipo 
                            desenterraron una columna de madera de tres metros de altura. En ella estaba 
                            tallada una figura humana femenina. Gracias a sus dibujos supo que se trataba de 
                            Anquira, la diosa más importante de la ciudad de las maderas inmortales. <br/><br/>
                            
                            Sofía se tomó una fotografía junto a la columna, la imprimió y la envió a su abuelo. En 
                            una nota escribió: “Lo logramos, abuelo. Los sueños existen”.
                        </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                            <img className='Imagen' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion20/4.png"  alt="Tarjeta" />
                    </Grid>
                </Grid>

                <Grid container item xs={12} justifyContent="center" className="ContainerDerechos">
                    <p className="Derechos">
                        © 2022. Todos los derechos reservados, Pearson, Circuito de lectores.
                    </p>                
                </Grid>
        </Grid>
    </Grid>
    );
}
 
export default Principios_éticos_para_un_mundo_mejor;
