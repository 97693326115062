import React from 'react';
import { makeStyles, Grid, } from "@material-ui/core";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Respuestas from './Respuestas';
import RespuestasImg from './RespuestasImg';

import picture1531             from '../../../exercises/primero/activity_S05/image/CDL_G1_B1_S05_RL1_ARTE_Loscolores_01.png';
import picture1532             from '../../../exercises/primero/activity_S05/image/CDL_G1_B1_S05_RL1_ARTE_Loscolores_02.png';
import picture1533             from '../../../exercises/primero/activity_S05/image/CDL_G1_B1_S05_RL1_ARTE_Loscolores_03.png';
import picture1534             from '../../../exercises/primero/activity_S05/image/CDL_G1_B1_S05_RL1_ARTE_Loscolores_04.png';
import picture1551             from '../../../exercises/primero/activity_S05/image/CDL_G1_B1_S05_RL1_ARTE_Loscolores_05.png';
import picture1552             from '../../../exercises/primero/activity_S05/image/CDL_G1_B1_S05_RL1_ARTE_Loscolores_06.png';
import picture1553             from '../../../exercises/primero/activity_S05/image/CDL_G1_B1_S05_RL1_ARTE_Loscolores_07.png';
import picture1554             from '../../../exercises/primero/activity_S05/image/CDL_G1_B1_S05_RL1_ARTE_Loscolores_08.png';

import picture1861             from '../../../exercises/primero/activity_S08/image/CL_G1_B1_S08_RI24_MATE_Conjuntos01.png';
import picture1862             from '../../../exercises/primero/activity_S08/image/CL_G1_B1_S08_RI24_MATE_Conjuntos02.png';
import picture1863             from '../../../exercises/primero/activity_S08/image/CL_G1_B1_S08_RI24_MATE_Conjuntos03.png';
import picture1864             from '../../../exercises/primero/activity_S08/image/CL_G1_B1_S08_RI24_MATE_Conjuntos04.png';
import picture1871             from '../../../exercises/primero/activity_S08/image/CL_G1_B1_S08_RI24_MATE_Conjuntos05.png';
import picture1872             from '../../../exercises/primero/activity_S08/image/CL_G1_B1_S08_RI24_MATE_Conjuntos06.png';
import picture1873             from '../../../exercises/primero/activity_S08/image/CL_G1_B1_S08_RI24_MATE_Conjuntos07.png';
import picture1874             from '../../../exercises/primero/activity_S08/image/CL_G1_B1_S08_RI24_MATE_Conjuntos08.png';

import picture11121             from '../../../exercises/primero/activity_S11/image/CL_G1_B1_S11_RL2_HUM_ElViaje01.png';
import picture11122             from '../../../exercises/primero/activity_S11/image/CL_G1_B1_S11_RL2_HUM_ElViaje02.png';
import picture11123             from '../../../exercises/primero/activity_S11/image/CL_G1_B1_S11_RL2_HUM_ElViaje03.png';
import picture11124             from '../../../exercises/primero/activity_S11/image/CL_G1_B1_S11_RL2_HUM_ElViaje04.png';

import picture11661             from '../../../exercises/primero/activity_S16/image/CL_G1_B1_S16_RI22_NAT_De panza01.png';
import picture11662             from '../../../exercises/primero/activity_S16/image/CL_G1_B1_S16_RI22_NAT_De panza02.png';
import picture11663             from '../../../exercises/primero/activity_S16/image/CL_G1_B1_S16_RI22_NAT_De panza03.png';
import picture11664             from '../../../exercises/primero/activity_S16/image/CL_G1_B1_S16_RI22_NAT_De panza04.png';

import picture12041             from '../../../exercises/primero/activity_S20_Formativa/img/CL_G1_B1_S20_Form101.png'
import picture12042             from '../../../exercises/primero/activity_S20_Formativa/img/CL_G1_B1_S20_Form102.png'
import picture12043             from '../../../exercises/primero/activity_S20_Formativa/img/CL_G1_B1_S20_Form103.png'
import picture12044             from '../../../exercises/primero/activity_S20_Formativa/img/CL_G1_B1_S20_Form104.png'

import picture12160                 from '../../../exercises/primero/activity_S21/image/CL_G1_B2_S21_R120_ARTE_Las_emociones01.png';

import picture12621             from '../../../exercises/primero/activity_S26/img/CL_G1_B2_S26_RL2_PMate_El_panal01.png';
import picture12622             from '../../../exercises/primero/activity_S26/img/CL_G1_B2_S26_RL2_PMate_El_panal02.png';
import picture12623             from '../../../exercises/primero/activity_S26/img/CL_G1_B2_S26_RL2_PMate_El_panal03.png';
import picture12624             from '../../../exercises/primero/activity_S26/img/CL_G1_B2_S26_RL2_PMate_El_panal04.png';
import picture12631             from '../../../exercises/primero/activity_S26/img/CL_G1_B2_S26_RL2_PMate_El_panal05.png';
import picture12632             from '../../../exercises/primero/activity_S26/img/CL_G1_B2_S26_RL2_PMate_El_panal06.png';
import picture12633             from '../../../exercises/primero/activity_S26/img/CL_G1_B2_S26_RL2_PMate_El_panal07.png';
import picture12634             from '../../../exercises/primero/activity_S26/img/CL_G1_B2_S26_RL2_PMate_El_panal08.png';
import picture12640             from '../../../exercises/primero/activity_S26/img/CL_G1_B2_S26_RL2_PMate_El_panal09.png';
import picture12671            from '../../../exercises/primero/activity_S26/img/S26_R7_ILUS_Una_loseta_cuadrada.png';
import picture12672            from '../../../exercises/primero/activity_S26/img/S26_R7_ILUS_Una_loseta_de_triangulo_equilatero.png';
import picture12673            from '../../../exercises/primero/activity_S26/img/S26_R7_ILUS_Una_loseta_rectangular.png';
import picture12674            from '../../../exercises/primero/activity_S26/img/S26_R7_ILUS_Una_loseta_hexagonal.png';

import picture13421             from '../../../exercises/primero/activity_S34_funcional/image/CL_G1_B2_S34_IA28_FUNC_El_yo_yo_de_la_senora_Chu_01.png';
import picture13422             from '../../../exercises/primero/activity_S34_funcional/image/CL_G1_B2_S34_IA28_FUNC_El_yo_yo_de_la_senora_Chu_02.png';
import picture13423             from '../../../exercises/primero/activity_S34_funcional/image/CL_G1_B2_S34_IA28_FUNC_El_yo_yo_de_la_senora_Chu_03.png';
import picture13424             from '../../../exercises/primero/activity_S34_funcional/image/CL_G1_B2_S34_IA28_FUNC_El_yo_yo_de_la_senora_Chu_04.png';

import picture13671             from '../../../exercises/primero/activity_S36/image/01_36_CL_G1_B2_S36_RG18_Etica_La autodeterminacion_R7.png';
import picture13672             from '../../../exercises/primero/activity_S36/image/02_36_CL_G1_B2_S36_RG18_Etica_La autodeterminacion_R7.png';
import picture13673             from '../../../exercises/primero/activity_S36/image/03_36_CL_G1_B2_S36_RG18_Etica_La autodeterminacion_R7.png';
import picture13674             from '../../../exercises/primero/activity_S36/image/04_36_CL_G1_B2_S36_RG18_Etica_La autodeterminacion_R7.png';

import picture13951             from '../../../exercises/primero/activity_S39/image/CL_G1_B3_S39_RI20_ARTE_ArteEnMovimiento01.png';
import picture13952             from '../../../exercises/primero/activity_S39/image/CL_G1_B3_S39_RI20_ARTE_ArteEnMovimiento02.png';
import picture13953             from '../../../exercises/primero/activity_S39/image/CL_G1_B3_S39_RI20_ARTE_ArteEnMovimiento03.png';
import picture13954             from '../../../exercises/primero/activity_S39/image/CL_G1_B3_S39_RI20_ARTE_ArteEnMovimiento04.png';

import picture14471             from '../../../exercises/primero/activity_S44/image/CL_G1_B3_S44_CV43_NAT_animal_o_planta01.png';
import picture14472             from '../../../exercises/primero/activity_S44/image/CL_G1_B3_S44_CV43_NAT_animal_o_planta02.png';
import picture14473             from '../../../exercises/primero/activity_S44/image/CL_G1_B3_S44_CV43_NAT_animal_o_planta03.png';
import picture14474             from '../../../exercises/primero/activity_S44/image/CL_G1_B3_S44_CV43_NAT_animal_o_planta04.png';

import picture14811             from '../../../exercises/primero/activity_S48/image/CL_G1_B3_S48_R122_MATE_DeUnSolo01.png';
import picture14812             from '../../../exercises/primero/activity_S48/image/CL_G1_B3_S48_R122_MATE_DeUnSolo02.png';
import picture14813             from '../../../exercises/primero/activity_S48/image/CL_G1_B3_S48_R122_MATE_DeUnSolo03.png';
import picture14814             from '../../../exercises/primero/activity_S48/image/CL_G1_B3_S48_R122_MATE_DeUnSolo04.png';
import picture14820             from '../../../exercises/primero/activity_S48/image/CL_G1_B3_S48_R122_MATE_DeUnSolo05.png';
import picture14841             from '../../../exercises/primero/activity_S48/image/CL_G1_B3_S48_R122_MATE_DeUnSolo06.png';
import picture14842             from '../../../exercises/primero/activity_S48/image/CL_G1_B3_S48_R122_MATE_DeUnSolo07.png';
import picture14843             from '../../../exercises/primero/activity_S48/image/CL_G1_B3_S48_R122_MATE_DeUnSolo08.png';
import picture14844             from '../../../exercises/primero/activity_S48/image/CL_G1_B3_S48_R122_MATE_DeUnSolo09.png';
import picture14861            from '../../../exercises/primero/activity_S48/image/CL_G1_B3_S48_R122_MATE_DeUnSolo10.png';
import picture14862            from '../../../exercises/primero/activity_S48/image/CL_G1_B3_S48_R122_MATE_DeUnSolo11.png';
import picture14863            from '../../../exercises/primero/activity_S48/image/CL_G1_B3_S48_R122_MATE_DeUnSolo12.png';
import picture14864            from '../../../exercises/primero/activity_S48/image/CL_G1_B3_S48_R122_MATE_DeUnSolo13.png';

import picture15220             from '../../../exercises/primero/activity_S52_funcional/image/FUNCIONAL_G1_B3_S52_Correo_electronico.png'

import picture15821             from '../../../exercises/primero/activity_S58/image/G1_S58_R5.png'
import picture15810             from '../../../exercises/primero/activity_S58/image/CL_G1_B3_S58_RI24_Mate_Funciona_compartir01.png'
import picture15822             from '../../../exercises/primero/activity_S58/image/CL_G1_B3_S58_RI25.png'
import picture15823             from '../../../exercises/primero/activity_S58/image/CL_G1_B3_S58_RI26.png'
import picture15824             from '../../../exercises/primero/activity_S58/image/CL_G1_B3_S58_RI27.png'

import picture20720             from '../../../exercises/segundo/activity_S07/image/CL_G2_S07_RL11_Humanas_Personas_artesanas_01.png'
import picture20721             from '../../../exercises/segundo/activity_S07/image/CL_G2_S07_RL11_Humanas_Personas_artesanas_02.png'
import picture20722             from '../../../exercises/segundo/activity_S07/image/CL_G2_S07_RL11_Humanas_Personas_artesanas_03.png'
import picture20723             from '../../../exercises/segundo/activity_S07/image/CL_G2_S07_RL11_Humanas_Personas_artesanas_04.png'
import picture20724             from '../../../exercises/segundo/activity_S07/image/CL_G2_S07_RL11_Humanas_Personas_artesanas_05.png'

import picture20840             from '../../../exercises/segundo/activity_S08/image/CL_G2_B1_S08_IC36_Mate_Un_conjunto_imaginacion_01.png';

import picture21141             from '../../../exercises/segundo/activity_S11/image/CL_G2_B1S11_IC35_HUM_AvesSinFronteras_01.png';
import picture21142             from '../../../exercises/segundo/activity_S11/image/CL_G2_B1S11_IC35_HUM_AvesSinFronteras_02.png';
import picture21143             from '../../../exercises/segundo/activity_S11/image/CL_G2_B1S11_IC35_HUM_AvesSinFronteras_03.png';
import picture21144             from '../../../exercises/segundo/activity_S11/image/CL_G2_B1S11_IC35_HUM_AvesSinFronteras_04.png';

import picture21721             from '../../../exercises/segundo/activity_S17/images/CL_G2_B1_S17_RI22_Nat_LaEvolucionDeLas_01.png';
import picture21722             from '../../../exercises/segundo/activity_S17/images/CL_G2_B1_S17_RI22_Nat_LaEvolucionDeLas_02.png';
import picture21723             from '../../../exercises/segundo/activity_S17/images/CL_G2_B1_S17_RI22_Nat_LaEvolucionDeLas_03.png';
import picture21724             from '../../../exercises/segundo/activity_S17/images/CL_G2_B1_S17_RI22_Nat_LaEvolucionDeLas_04.png';
import picture21761             from '../../../exercises/segundo/activity_S17/images/CL_G2_B1_S17_RI22_Nat_LaEvolucionDeLas_05.png';
import picture21762             from '../../../exercises/segundo/activity_S17/images/CL_G2_B1_S17_RI22_Nat_LaEvolucionDeLas_06.png';
import picture21763             from '../../../exercises/segundo/activity_S17/images/CL_G2_B1_S17_RI22_Nat_LaEvolucionDeLas_07.png';
import picture21764             from '../../../exercises/segundo/activity_S17/images/CL_G2_B1_S17_RI22_Nat_LaEvolucionDeLas_08.png';

import picture22650             from '../../../exercises/segundo/activity_S26/image/CL_G2_B2_S26_IA30_El_tangram_01.png'
import picture22670             from '../../../exercises/segundo/activity_S26/image/CL_G2_B2_S26_IA30_El_tangram_02.png'

import picture23210             from '../../../exercises/segundo/activity_S32/image/CL_G2_B2_S32_IC35_Hum_Jue_jug_aprender_01.png';
import picture23244             from '../../../exercises/segundo/activity_S32/image/CL_G2_B2_S32_IC35_Hum_Jue_jug_aprender_02.png';

import picture24821             from '../../../exercises/segundo/activity_S48/image/CL_G2_B3_S48_IC33_MATE_logicacreatividad_02.png';
import picture24822             from '../../../exercises/segundo/activity_S48/image/CL_G2_B3_S48_IC33_MATE_logicacreatividad_03.png';
import picture24823             from '../../../exercises/segundo/activity_S48/image/CL_G2_B3_S48_IC33_MATE_logicacreatividad_04.png';
import picture24824             from '../../../exercises/segundo/activity_S48/image/CL_G2_B3_S48_IC33_MATE_logicacreatividad_05.png';
// import picture6             from '../../../exercises/segundo/activity_S48/image/CL_G2_B3_S48_IC33_MATE_LogicaCreatividad_06.png';
// import picture7             from '../../../exercises/segundo/activity_S48/image/CL_G2_B3_S48_IC33_MATE_LogicaCreatividad_07.png';
import picture24860             from '../../../exercises/segundo/activity_S48/image/CL_G2_B3_S48_IC33_MATE_LogicaCreatividad_08.png';
import picture7a            from '../../../exercises/segundo/activity_S48/image/CL_G2_B3_S48_IC33_MATE_LogicaCreatividad_07A.png';
import picture7b            from '../../../exercises/segundo/activity_S48/image/CL_G2_B3_S48_IC33_MATE_LogicaCreatividad_07B.png';
import picture7c            from '../../../exercises/segundo/activity_S48/image/CL_G2_B3_S48_IC33_MATE_LogicaCreatividad_07C.png';
import picture7d            from '../../../exercises/segundo/activity_S48/image/CL_G2_B3_S48_IC33_MATE_LogicaCreatividad_07D.png';
import picture6a            from '../../../exercises/segundo/activity_S48/image/CL_G2_B3_S48_IC33_MATE_LogicaCreatividad_06A.png';
import picture6b            from '../../../exercises/segundo/activity_S48/image/CL_G2_B3_S48_IC33_MATE_LogicaCreatividad_06B.png';
import picture6c            from '../../../exercises/segundo/activity_S48/image/CL_G2_B3_S48_IC33_MATE_LogicaCreatividad_06C.png';
import picture6d            from '../../../exercises/segundo/activity_S48/image/CL_G2_B3_S48_IC33_MATE_LogicaCreatividad_06D.png';

import picture40771             from '../../../exercises/cuarto/activity_S07/image/CL_G4_B1_S07_RL09_HUM_Lahistoriade_01.png';
import picture40772             from '../../../exercises/cuarto/activity_S07/image/CL_G4_B1_S07_RL09_HUM_Lahistoriade_02.png';
import picture40773             from '../../../exercises/cuarto/activity_S07/image/CL_G4_B1_S07_RL09_HUM_Lahistoriade_03.png';
import picture40774             from '../../../exercises/cuarto/activity_S07/image/CL_G4_B1_S07_RL09_HUM_Lahistoriade_04.png';
import picture40775             from '../../../exercises/cuarto/activity_S07/image/CL_G4_B1_S07_RL09_HUM_Lahistoriade_05.png';

import picture41021             from '../../../exercises/cuarto/activity_S10/image/CL_G4_B1_S10_IC35_Mate_GraficasEn_01.png';
import picture41022             from '../../../exercises/cuarto/activity_S10/image/CL_G4_B1_S10_IC35_Mate_GraficasEn_02.png';
import picture41023             from '../../../exercises/cuarto/activity_S10/image/CL_G4_B1_S10_IC35_Mate_GraficasEn_03.png';
import picture41024             from '../../../exercises/cuarto/activity_S10/image/CL_G4_B1_S10_IC35_Mate_GraficasEn_04.png';

import picture42481             from '../../../exercises/cuarto/activity_S24/images/CL_G4_B2_S24_RL10_ETICA_Lapobrezayel_01.png';
import picture42482             from '../../../exercises/cuarto/activity_S24/images/CL_G4_B2_S24_RL10_ETICA_Lapobrezayel_02.png';
import picture42483             from '../../../exercises/cuarto/activity_S24/images/CL_G4_B2_S24_RL10_ETICA_Lapobrezayel_03.png';
import picture42484             from '../../../exercises/cuarto/activity_S24/images/CL_G4_B2_S24_RL10_ETICA_Lapobrezayel_04.png';

import picture42641             from "../../../exercises/cuarto/activity_S26/image/CL_G4_B2_S26_RG08_Mate_Elnumero_01.png";
import picture42642             from "../../../exercises/cuarto/activity_S26/image/CL_G4_B2_S26_RG08_Mate_Elnumero_02.png";
import picture42643             from "../../../exercises/cuarto/activity_S26/image/CL_G4_B2_S26_RG08_Mate_Elnumero_03.png";
import picture42644             from "../../../exercises/cuarto/activity_S26/image/CL_G4_B2_S26_RG08_Mate_Elnumero_04.png";
import picture42645             from "../../../exercises/cuarto/activity_S26/image/CL_G4_B2_S26_RG08_Mate_Elnumero_05.png";
import picture42646             from "../../../exercises/cuarto/activity_S26/image/CL_G4_B2_S26_RG08_Mate_Elnumero_06.png";
import picture42647             from "../../../exercises/cuarto/activity_S26/image/CL_G4_B2_S26_RG08_Mate_Elnumero_07.png";
import picture42648             from "../../../exercises/cuarto/activity_S26/image/CL_G4_B2_S26_RG08_Mate_Elnumero_08.png";
import picture42649             from "../../../exercises/cuarto/activity_S26/image/CL_G4_B2_S26_RG08_Mate_Elnumero_09.png";
import picture426410            from "../../../exercises/cuarto/activity_S26/image/CL_G4_B2_S26_RG08_Mate_Elnumero_10.png";

import picture43230             from '../../../exercises/cuarto/activity_S32/image/CL_G4_B2_S32_IA28_HUM_Africacunade_01.png';

import picture45251             from '../../../exercises/cuarto/activity_S52_funcional/images/CL_G4_B3_S52_IA28_FUNC_Lospeludoscomp_01.png';
import picture45252             from '../../../exercises/cuarto/activity_S52_funcional/images/CL_G4_B3_S52_IA28_FUNC_Lospeludoscomp_02.png';
import picture45253             from '../../../exercises/cuarto/activity_S52_funcional/images/CL_G4_B3_S52_IA28_FUNC_Lospeludoscomp_03.png';
import picture45254             from '../../../exercises/cuarto/activity_S52_funcional/images/CL_G4_B3_S52_IA28_FUNC_Lospeludoscomp_04.png';

import picture45881             from '../../../exercises/cuarto/activity_S58/images/CL_G4_B3_S58_RG19_Mate_Fractales_01.png';
import picture45882             from '../../../exercises/cuarto/activity_S58/images/CL_G4_B3_S58_RG19_Mate_Fractales_02.png';
import picture45883             from '../../../exercises/cuarto/activity_S58/images/CL_G4_B3_S58_RG19_Mate_Fractales_03.png';
import picture45884             from '../../../exercises/cuarto/activity_S58/images/CL_G4_B3_S58_RG19_Mate_Fractales_04.png';
import picture45885             from '../../../exercises/cuarto/activity_S58/images/CL_G4_B3_S58_RG19_Mate_Fractales_05.png';

import picture52490             from '../../../exercises/quinto/activity_S24/image/CL_G5_B2_S24_Actividad_01.png';

import picture60822             from '../../../exercises/sexto/activity_S08/image/CL_G6_B1_S08_IC36_36_E2_lateoriaderamseyenejemplos_02.png'
import picture60823             from '../../../exercises/sexto/activity_S08/image/CL_G6_B1_S08_IC36_36_E2_lateoriaderamseyenejemplos_03.png'
import picture60824             from '../../../exercises/sexto/activity_S08/image/CL_G6_B1_S08_IC36_36_E2_lateoriaderamseyenejemplos_04.png'
import picture60825             from '../../../exercises/sexto/activity_S08/image/CL_G6_B1_S08_IC36_36_E2_lateoriaderamseyenejemplos_05.png'
import picture60827             from '../../../exercises/sexto/activity_S08/image/CL_G6_B1_S08_IC36_36_E2_lateoriaderamseyenejemplos_07.png'
import picture60828             from '../../../exercises/sexto/activity_S08/image/CL_G6_B1_S08_IC36_36_E2_lateoriaderamseyenejemplos_08.png'
import picture60829             from '../../../exercises/sexto/activity_S08/image/CL_G6_B1_S08_IC36_36_E2_lateoriaderamseyenejemplos_09.png'
import picture608210            from '../../../exercises/sexto/activity_S08/image/CL_G6_B1_S08_IC36_36_E2_lateoriaderamseyenejemplos_10.png'
import picture608211            from '../../../exercises/sexto/activity_S08/image/CL_G6_B1_S08_IC36_36_E2_lateoriaderamseyenejemplos_11.png'
import picture60826             from '../../../exercises/sexto/activity_S08/image/CL_G6_B1_S08_IC36_E2_lateoriaderamseyenejemplos_06.png'
import picture60820             from '../../../exercises/sexto/activity_S08/image/CL_G6_B1_S08_IC36_E2_lateoriaderamseyenejemplos_01.png'

import picture60971             from '../../../exercises/sexto/activity_S09/image/CL_G6_B1_S09_CI47_E2_Palabras_01.png'
import picture60972             from '../../../exercises/sexto/activity_S09/image/CL_G6_B1_S09_CI47_E2_Palabras_02.png'
import picture60973             from '../../../exercises/sexto/activity_S09/image/CL_G6_B1_S09_CI47_E2_Palabras_03.png'
import picture60974             from '../../../exercises/sexto/activity_S09/image/CL_G6_B1_S09_CI47_E2_Palabras_04.png'

import picture61031             from '../../../exercises/sexto/activity_S10/image/CL_G6_13_S10_IC34_E2_Elequilibriodenash_03.png';
import picture61032             from '../../../exercises/sexto/activity_S10/image/ra.png';
import picture61033             from '../../../exercises/sexto/activity_S10/image/rb.png';
import picture61034             from '../../../exercises/sexto/activity_S10/image/rc.png';
import picture61035             from '../../../exercises/sexto/activity_S10/image/rd.png';

import picture61261             from '../../../exercises/sexto/activity_S12/image/CL_G6_B1_S12_RI23_E2_La música y los festivales_01.png'
import picture61262             from '../../../exercises/sexto/activity_S12/image/CL_G6_B1_S12_RI23_E2_La música y los festivales_02.png'
import picture61263             from '../../../exercises/sexto/activity_S12/image/CL_G6_B1_S12_RI23_E2_La música y los festivales_03.png'
import picture61264             from '../../../exercises/sexto/activity_S12/image/CL_G6_B1_S12_RI23_E2_La música y los festivales_04.png'
import picture61271             from '../../../exercises/sexto/activity_S12/image/CL_G6_B1_S12_RI23_E2_Lamusicaylosfestivales_05.png'
import picture61272             from '../../../exercises/sexto/activity_S12/image/CL_G6_B1_S12_RI23_E2_Lamusicaylosfestivales_06.png'
import picture61273             from '../../../exercises/sexto/activity_S12/image/CL_G6_B1_S12_RI23_E2_Lamusicaylosfestivales_07.png'
import picture61274             from '../../../exercises/sexto/activity_S12/image/CL_G6_B1_S12_RI23_E2_Lamusicaylosfestivales_08.png'

import picture61361             from '../../../exercises/sexto/activity_S13/image/CL_G6_B1_S13_RG14_E2_Día de muertos_01.png'
import picture61362             from '../../../exercises/sexto/activity_S13/image/CL_G6_B1_S13_RG14_E2_Día de muertos_02.png'
import picture61363             from '../../../exercises/sexto/activity_S13/image/CL_G6_B1_S13_RG14_E2_Día de muertos_03.png'
import picture61364             from '../../../exercises/sexto/activity_S13/image/CL_G6_B1_S13_RG14_E2_Día de muertos_04.png'

import picture61941             from '../../../exercises/sexto/activity_S19/image/CL_G6_B1_S19_RI20_E2_Ritmo_01.png';
import picture61942             from '../../../exercises/sexto/activity_S19/image/CL_G6_B1_S19_RI20_E2_Ritmo_02.png';
import picture61943             from '../../../exercises/sexto/activity_S19/image/CL_G6_B1_S19_RI20_E2_Ritmo_03.png';
import picture61944             from '../../../exercises/sexto/activity_S19/image/CL_G6_B1_S19_RI20_E2_Ritmo_04.png';

import picture62261             from '../../../exercises/sexto/activity_S22/image/CL_G6_B2_S22_CV41_HUMANAS_ElAburrimiento_01.png';
import picture62262             from '../../../exercises/sexto/activity_S22/image/CL_G6_B2_S22_CV41_HUMANAS_ElAburrimiento_02.png';
import picture62263             from '../../../exercises/sexto/activity_S22/image/CL_G6_B2_S22_CV41_HUMANAS_ElAburrimiento_03.png';
import picture62264             from '../../../exercises/sexto/activity_S22/image/CL_G6_B2_S22_CV41_HUMANAS_ElAburrimiento_04.png';
import picture62265             from '../../../exercises/sexto/activity_S22/image/CL_G6_B2_S22_CV41_HUMANAS_ElAburrimiento_05.png';
import picture62266             from '../../../exercises/sexto/activity_S22/image/CL_G6_B2_S22_CV41_HUMANAS_ElAburrimiento_06.png';
import picture62267             from '../../../exercises/sexto/activity_S22/image/CL_G6_B2_S22_CV41_HUMANAS_ElAburrimiento_07.png';

import picture62430             from '../../../exercises/sexto/activity_S24/image/CL_G6_B2_S24_CC48_ÉTICA_DIVERSIDAD_01.png';

import picture63521             from '../../../exercises/sexto/activity_S35/image/CL_G6_B2_S35_RG14_E3_Mamuts_01.png'
import picture63522             from '../../../exercises/sexto/activity_S35/image/CL_G6_B2_S35_RG14_E3_Mamuts_02.png'
import picture63523             from '../../../exercises/sexto/activity_S35/image/CL_G6_B2_S35_RG14_E3_Mamuts_03.png'
import picture63524             from '../../../exercises/sexto/activity_S35/image/CL_G6_B2_S35_RG14_E3_Mamuts_04.png'
import picture56             from '../../../exercises/sexto/activity_S35/image/CL_G6_B2_S35_RG14_E3_Mamuts_05.png'
import picture66            from '../../../exercises/sexto/activity_S35/image/CL_G6_B2_S35_RG14_E3_Mamuts_06.png'
import picture76             from '../../../exercises/sexto/activity_S35/image/CL_G6_B2_S35_RG14_E3_Mamuts_07.png'
import picture86             from '../../../exercises/sexto/activity_S35/image/CL_G6_B2_S35_RG14_E3_Mamuts_08.png'
import picture96             from '../../../exercises/sexto/activity_S35/image/CL_G6_B2_S35_RG14_E3_Mamuts_09.png'
import picture106            from '../../../exercises/sexto/activity_S35/image/CL_G6_B2_S35_RG14_E3_Mamuts_10.png'

import picture1Q             from '../../../exercises/sexto/activity_S37/image/CL_G6_B2_S37_CV42_E3_El_precio_de_la_moda_01.png'
import picture2Q             from '../../../exercises/sexto/activity_S37/image/CL_G6_B2_S37_CV42_E3_El_precio_de_la_moda_02.png'
import picture3Q             from '../../../exercises/sexto/activity_S37/image/CL_G6_B2_S37_CV42_E3_El_precio_de_la_moda_03.png'
import picture4Q             from '../../../exercises/sexto/activity_S37/image/CL_G6_B2_S37_CV42_E3_El_precio_de_la_moda_04.png'
import Qpicture1             from '../../../exercises/sexto/activity_S37/image/CL_G6_B2_S37_CV42_E3_El_precio_de_la_moda_05.png'
import Qpicture2             from '../../../exercises/sexto/activity_S37/image/CL_G6_B2_S37_CV42_E3_El_precio_de_la_moda_06.png'
import Qpicture3             from '../../../exercises/sexto/activity_S37/image/CL_G6_B2_S37_CV42_E3_El_precio_de_la_moda_07.png'
import Qpicture4             from '../../../exercises/sexto/activity_S37/image/CL_G6_B2_S37_CV42_E3_El_precio_de_la_moda_08.png'

import picture63931          from '../../../exercises/sexto/activity_S39/image/CL_G6_B3_S39_RG13_E1_FOTO_01.png'
import picture63932          from '../../../exercises/sexto/activity_S39/image/CL_G6_B3_S39_RG13_E1_FOTO_02.png'
import picture63933          from '../../../exercises/sexto/activity_S39/image/CL_G6_B3_S39_RG13_E1_FOTO_03.png'
import picture63934          from '../../../exercises/sexto/activity_S39/image/CL_G6_B3_S39_RG13_E1_FOTO_04.png'

import picture638r1             from '../../../exercises/sexto/activity_S38_Formativa_remedial_02/image/CL_G6_B2_S39R_Rem2_01.png'
import picture638r2             from '../../../exercises/sexto/activity_S38_Formativa_remedial_02/image/CL_G6_B2_S39R_Rem2_02.png'
import picture638r3             from '../../../exercises/sexto/activity_S38_Formativa_remedial_02/image/CL_G6_B2_S39R_Rem2_03.png'
import picture638r4             from '../../../exercises/sexto/activity_S38_Formativa_remedial_02/image/CL_G6_B2_S39R_Rem2_04.png'

import picture64461             from '../../../exercises/sexto/activity_S44/image/CL_G6_B3_S44_CI47_NAT_Alquimia_01.png'
import picture64462             from '../../../exercises/sexto/activity_S44/image/CL_G6_B3_S44_CI47_NAT_Alquimia_02.png'
import picture64463             from '../../../exercises/sexto/activity_S44/image/CL_G6_B3_S44_CI47_NAT_Alquimia_03.png'
import picture64464             from '../../../exercises/sexto/activity_S44/image/CL_G6_B3_S44_CI47_NAT_Alquimia_04.png'

import picture64851            from '../../../exercises/sexto/activity_S48/image/CL_G6_B3_S48_CI43_E1_COLORES_01.png'
import picture64852             from '../../../exercises/sexto/activity_S48/image/CL_G6_B3_S48_CI43_E1_COLORES_02.png'
import picture64853             from '../../../exercises/sexto/activity_S48/image/CL_G6_B3_S48_CI43_E1_COLORES_03.png'
import picture64854             from '../../../exercises/sexto/activity_S48/image/CL_G6_B3_S48_CI43_E1_COLORES_04.png'

import picture65531             from '../../../exercises/sexto/activity_S55_Formativa/image/CL_G6_B3_Form3_01.png'
import picture65532             from '../../../exercises/sexto/activity_S55_Formativa/image/CL_G6_B3_Form3_02.png'
import picture65533             from '../../../exercises/sexto/activity_S55_Formativa/image/CL_G6_B3_Form3_03.png'
import picture65534             from '../../../exercises/sexto/activity_S55_Formativa/image/CL_G6_B3_Form3_04.png'
import picture65535             from '../../../exercises/sexto/activity_S55_Formativa/image/CL_G6_B3_Form3_05.png'

import picture20661             from '../../../exercises/segundo/activity_S06/image/CL_G2_B1_S06_IC35_LENGUA_unico_01.png'
import picture20662             from '../../../exercises/segundo/activity_S06/image/CL_G2_B1_S06_IC35_LENGUA_unico_02.png'
import picture20663             from '../../../exercises/segundo/activity_S06/image/CL_G2_B1_S06_IC35_LENGUA_unico_03.png'
import picture20664             from '../../../exercises/segundo/activity_S06/image/CL_G2_B1_S06_IC35_LENGUA_unico_04.png'
import picture20665             from '../../../exercises/segundo/activity_S06/image/CL_G2_B1_S06_IC35_LENGUA_unico_05.png'

import picture21450             from '../../../exercises/segundo/activity_S14_funcional/image/CL_G2_B1_S14_R30_Infografía_Diversas_a_mundo_01.png';

import picture23680             from '../../../exercises/segundo/activity_S36/image/CL_G2_B2_S36_IA30_Etica_El_poder_de_la_autorregulación_01.png';

import picture24254             from '../../../exercises/segundo/activity_S42/image/CL_G2_B3_S42_RI25_NAT_05.png';
import picture24251             from '../../../exercises/segundo/activity_S42/image/CL_G2_B3_S42_RI25_NAT_02.png';
import picture24252             from '../../../exercises/segundo/activity_S42/image/CL_G2_B3_S42_RI25_NAT_03.png';
import picture24253             from '../../../exercises/segundo/activity_S42/image/CL_G2_B3_S42_RI25_NAT_04.png';

import picture25580             from'../../../exercises/segundo/activity_S55_Formativa/image/CL_G2_B3_S55_Form3_Líneas_y_puntos_para_multiplicar_01.png';

import picture20970             from '../../../exercises/segundo/activity_S09/image/CL_G2_B1_S09_RI25_Etica_El_valor_de_una_persona_01.png';

import picture60531    from '../../../exercises/sexto/activity_S05/image/CL_G6_B1_S05_RI21_E1_Artesanías,_patrimonio_de_una_cultura_01.png'
import picture60532    from '../../../exercises/sexto/activity_S05/image/CL_G6_B1_S05_RI21_E1_Artesanías,_patrimonio_de_una_cultura_02.png'
import picture60533    from '../../../exercises/sexto/activity_S05/image/CL_G6_B1_S05_RI21_E1_Artesanías,_patrimonio_de_una_cultura_03.png'
import picture60534    from '../../../exercises/sexto/activity_S05/image/CL_G6_B1_S05_RI21_E1_Artesanías,_patrimonio_de_una_cultura_04.png'
import picture60535    from '../../../exercises/sexto/activity_S05/image/CL_G6_B1_S05_RI21_E1_Artesanías,_patrimonio_de_una_cultura_05.png'
import picture60536    from '../../../exercises/sexto/activity_S05/image/CL_G6_B1_S05_RI21_E1_Artesanías,_patrimonio_de_una_cultura_06.png'
import picture60537    from '../../../exercises/sexto/activity_S05/image/CL_G6_B1_S05_RI21_E1_Artesanías,_patrimonio_de_una_cultura_07.png'
import picture60538    from '../../../exercises/sexto/activity_S05/image/CL_G6_B1_S05_RI21_E1_Artesanías,_patrimonio_de_una_cultura_08.png'
import picture60539    from '../../../exercises/sexto/activity_S05/image/CL_G6_B1_S05_RI21_E1_Artesanías,_patrimonio_de_una_cultura_09.png'
import picture605310   from '../../../exercises/sexto/activity_S05/image/CL_G6_B1_S05_RI21_E1_Artesanías,_patrimonio_de_una_cultura_10.png'

import picture53891    from '../../../exercises/quinto/activity_S38_Formativa/image/CL_G5_B2_S38_Form2_01A.png';
import picture53892    from '../../../exercises/quinto/activity_S38_Formativa/image/CL_G5_B2_S38_Form2_01B.png';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  questionText: {
    // backgroundColor: "#FEF4F1",
    width: "100%",
    margin:"10px 0 10px 0 ",
    paddingLeft: "5px",
    font: '24px lato',
    letterSpacing: '0.21px',
    [theme.breakpoints.down(1400)]:{
      font: " 20px lato",
      width: "100%",
    },
    [theme.breakpoints.down(650)]:{
      font: "16px lato",
    },
  },
  wrongRetro:{
    width: "100%",
    margin:"10px 0 10px 0 ",
    paddingLeft: "10px",
    color: 'red',
  },
  correctRetro:{
    width: "100%",
    margin:"10px 0 10px 0 ",
    paddingLeft: "10px",
    color: 'green',
  },
    reading: {
    width: "100%",
    margin:"10px 0 10px 0 ",
    paddingLeft: "10px",
    font: '22px lato',
    letterSpacing: '0.21px',
    [theme.breakpoints.down(1400)]:{
      font: " 18px lato",
      width: "100%",
    },
    [theme.breakpoints.down(650)]:{
      font: "14px lato",
    },
  },
  correctFeedback: {
    color: "green",
    border: "#80D7C6 3px solid",
  },
  wrongFeedback: {
    color: "red",
    border: "#F89987 3px solid",

  },
  imgStyle: {
    alignItems: "center",
    width:'30%',
    // height:'50%',
  },
  table: {
    width: "100%",
    font: '22px lato',
    border: '1px solid black',
    [theme.breakpoints.down(1400)]:{
      font: " 18px lato",
      width: "95%",
    },
    [theme.breakpoints.down(1310)]:{
      font: "500 16px lato",
 
    },
    [theme.breakpoints.down(925)]:{
      font: "500 14px lato",

    },
    [theme.breakpoints.down(426)]:{
      font: "500 12px lato",

    }, 
  },
  tdTable: {
    padding: "0 1%",
    border: '1px solid black',
    textAlign: 'center'
  },
  ovalColorRed: {
    background: "red",
    border:  "solid 2px black",
    borderRadius:"90px",
    height: "40px",
    font: '22px lato',
    color: "white",
    [theme.breakpoints.down(1400)]:{
      font: " 18px lato",
    },
    [theme.breakpoints.down(1310)]:{
      font: "500 16px lato",
    },
    [theme.breakpoints.down(925)]:{
      font: "500 14px lato",
    },
    [theme.breakpoints.down(426)]:{
      font: "500 12px lato",
    }, 
  },
  ovalColorBlue: {
    background: "blue",
    border:  "solid 2px black",
    borderRadius:"90px",
    height: "40px",
    font: '22px lato',
    color: "white",
    [theme.breakpoints.down(1400)]:{
      font: " 18px lato",
    },
    [theme.breakpoints.down(1310)]:{
      font: "500 16px lato",
    },
    [theme.breakpoints.down(925)]:{
      font: "500 14px lato",
    },
    [theme.breakpoints.down(426)]:{
      font: "500 12px lato",
    }, 
  },
  ovalColorYellow: {
    background: "yellow",
    border:  "solid 2px black",
    borderRadius:"90px",
    height: "40px",
    font: '22px lato',
    [theme.breakpoints.down(1400)]:{
      font: " 18px lato",
    },
    [theme.breakpoints.down(1310)]:{
      font: "500 16px lato",
    },
    [theme.breakpoints.down(925)]:{
      font: "500 14px lato",
    },
    [theme.breakpoints.down(426)]:{
      font: "500 12px lato",
    }, 
  }
}));

const AccordionText = (props) => {
  const classes = useStyles();
  const {questionInfo, respuestas, question} = props

  const renderAnswerImages = () => {
    switch (question.id_pregunta) {
      case (1335):
        return (
          respuestas.map((question, index) => {
            const imageList =  {
              4966: picture1531,
              4967: picture1532,
              4968: picture1533,
              4969: picture1534,
            }
            return(
              <Grid container  item xs={6}>
              <h3 >{question.respuesta ? (question.correcta > 0 ? "correcta" : "incorrecta") : ""}</h3>
              <RespuestasImg
                key={"respuesta" + question.id_pregunta} 
                answer={question.respuesta}
                correct={question.correcta > 0}
                imageList={imageList}
                question={question}
              />
              
                <Grid >
                  <p 
                    dangerouslySetInnerHTML={{__html: `${question.feedback}`}}
                    // className={ question.reading}
                    // className={ question.correcta > 0 ? classes.correctFeedback : classes.wrongFeedback }
                  >
                  </p>
                </Grid>
              </Grid> 
          )})
        )
        
      case (1337):
        return (
          respuestas.map((question, index) => {
            const imageList =  {
              4974: picture1551,
              4975: picture1552,
              4976: picture1553,
              4977: picture1554,
            }
            return(
              <Grid container  item xs={6}>
              <h3 >{question.respuesta ? (question.correcta > 0 ? "correcta" : "incorrecta") : ""}</h3>
              <RespuestasImg
                key={"respuesta" + question.id_pregunta} 
                answer={question.respuesta}
                correct={question.correcta > 0}
                imageList={imageList}
                question={question}
              />
              
                <Grid >
                  <p 
                    dangerouslySetInnerHTML={{__html: `${question.feedback}`}}
                    // className={ question.reading}
                    // className={ question.correcta > 0 ? classes.correctFeedback : classes.wrongFeedback }
                  >
                  </p>
                </Grid>
              </Grid> 
          )})
        )
        
      case (1368):
        return (
          respuestas.map((question, index) => {
            const imageList =  {
              5089: picture1861,
              5090: picture1862,
              5091: picture1863,
              5092: picture1864,
            }
            return(
              <Grid container  item xs={6}>
              <h3 >{question.respuesta ? (question.correcta > 0 ? "correcta" : "incorrecta") : ""}</h3>
              <RespuestasImg
                key={"respuesta" + question.id_pregunta} 
                answer={question.respuesta}
                correct={question.correcta > 0}
                imageList={imageList}
                question={question}
              />
              
                <Grid >
                  <p 
                    dangerouslySetInnerHTML={{__html: `${question.feedback}`}}
                    // className={ question.reading}
                    // className={ question.correcta > 0 ? classes.correctFeedback : classes.wrongFeedback }
                  >
                  </p>
                </Grid>
              </Grid> 
          )})
        )
        
      case (1369):
      return (
        respuestas.map((question, index) => {
          const imageList =  {
            5093: picture1871,
            5094: picture1872,
            5095: picture1873,
            5096: picture1874,
          }
          return(
            <Grid container  item xs={6}>
            <h3 >{question.respuesta ? (question.correcta > 0 ? "correcta" : "incorrecta") : ""}</h3>
            <RespuestasImg
              key={"respuesta" + question.id_pregunta} 
              answer={question.respuesta}
              correct={question.correcta > 0}
              imageList={imageList}
              question={question}
            />
            
              <Grid >
                <p 
                  dangerouslySetInnerHTML={{__html: `${question.feedback}`}}
                  // className={ question.reading}
                  // className={ question.correcta > 0 ? classes.correctFeedback : classes.wrongFeedback }
                >
                </p>
              </Grid>
            </Grid> 
        )})
      )
      
      case (1394):
        return (
          respuestas.map((question, index) => {
            const imageList =  {
              5184: picture11121,
              5185: picture11122,
              5186: picture11123,
              5187: picture11124,
            }
            return(
              <Grid container  item xs={6}>
              <h3 >{question.respuesta ? (question.correcta > 0 ? "correcta" : "incorrecta") : ""}</h3>
              <RespuestasImg
                key={"respuesta" + question.id_pregunta} 
                answer={question.respuesta}
                correct={question.correcta > 0}
                imageList={imageList}
                question={question}
              />
              
                <Grid >
                  <p 
                    dangerouslySetInnerHTML={{__html: `${question.feedback}`}}
                    // className={ question.reading}
                    // className={ question.correcta > 0 ? classes.correctFeedback : classes.wrongFeedback }
                  >
                  </p>
                </Grid>
              </Grid> 
          )})
        )
      
      case (1448):
        return (
          respuestas.map((question, index) => {
            const imageList =  {
              5385: picture11661,
              5386: picture11662,
              5387: picture11663,
              5388: picture11664,
            }
            return(
              <Grid container  item xs={6}>
              <h3 >{question.respuesta ? (question.correcta > 0 ? "correcta" : "incorrecta") : ""}</h3>
              <RespuestasImg
                key={"respuesta" + question.id_pregunta} 
                answer={question.respuesta}
                correct={question.correcta > 0}
                imageList={imageList}
                question={question}
              />
              
                <Grid >
                  <p 
                    dangerouslySetInnerHTML={{__html: `${question.feedback}`}}
                    // className={ question.reading}
                    // className={ question.correcta > 0 ? classes.correctFeedback : classes.wrongFeedback }
                  >
                  </p>
                </Grid>
              </Grid> 
          )})
        )
      
      case (1486):
        return (
          respuestas.map((question, index) => {
            const imageList =  {
              5525: picture12041,
              5526: picture12042,
              5527: picture12043,
              5528: picture12044,
            }
            return(
              <Grid container  item xs={6}>
              <h3 >{question.respuesta ? (question.correcta > 0 ? "correcta" : "incorrecta") : ""}</h3>
              <RespuestasImg
                key={"respuesta" + question.id_pregunta} 
                answer={question.respuesta}
                correct={question.correcta > 0}
                imageList={imageList}
                question={question}
              />
              
                <Grid >
                  <p 
                    dangerouslySetInnerHTML={{__html: `${question.feedback}`}}
                    // className={ question.reading}
                    // className={ question.correcta > 0 ? classes.correctFeedback : classes.wrongFeedback }
                  >
                  </p>
                </Grid>
              </Grid> 
          )})
        )
      
      case (1554):
        return (
          respuestas.map((question, index) => {
            const imageList =  {
              5779: picture12621,
              5780: picture12622,
              5781: picture12623,
              5782: picture12624,
            }
            return(
              <Grid container  item xs={6}>
              <h3 >{question.respuesta ? (question.correcta > 0 ? "correcta" : "incorrecta") : ""}</h3>
              <RespuestasImg
                key={"respuesta" + question.id_pregunta} 
                answer={question.respuesta}
                correct={question.correcta > 0}
                imageList={imageList}
                question={question}
              />
              
                <Grid >
                  <p 
                    dangerouslySetInnerHTML={{__html: `${question.feedback}`}}
                    // className={ question.reading}
                    // className={ question.correcta > 0 ? classes.correctFeedback : classes.wrongFeedback }
                  >
                  </p>
                </Grid>
              </Grid> 
          )})
        )
      
      case (1555):
        return (
          respuestas.map((question, index) => {
            const imageList =  {
              5783: picture12631,
              5784: picture12632,
              5785: picture12633,
              5786: picture12634,
            }
            return(
              <Grid container  item xs={6}>
              <h3 >{question.respuesta ? (question.correcta > 0 ? "correcta" : "incorrecta") : ""}</h3>
              <RespuestasImg
                key={"respuesta" + question.id_pregunta} 
                answer={question.respuesta}
                correct={question.correcta > 0}
                imageList={imageList}
                question={question}
              />
              
                <Grid >
                  <p 
                    dangerouslySetInnerHTML={{__html: `${question.feedback}`}}
                    // className={ question.reading}
                    // className={ question.correcta > 0 ? classes.correctFeedback : classes.wrongFeedback }
                  >
                  </p>
                </Grid>
              </Grid> 
          )})
        )
      
      case (1559):
        return (
          respuestas.map((question, index) => {
            const imageList =  {
              5799: picture12671,
              5800: picture12672,
              5801: picture12673,
              5802: picture12674,
            }
            return(
              <Grid container  item xs={6}>
              <h3 >{question.respuesta ? (question.correcta > 0 ? "correcta" : "incorrecta") : ""}</h3>
              <RespuestasImg
                key={"respuesta" + question.id_pregunta} 
                answer={question.respuesta}
                correct={question.correcta > 0}
                imageList={imageList}
                question={question}
              />
              
                <Grid >
                  <p 
                    dangerouslySetInnerHTML={{__html: `${question.feedback}`}}
                    // className={ question.reading}
                    // className={ question.correcta > 0 ? classes.correctFeedback : classes.wrongFeedback }
                  >
                  </p>
                </Grid>
              </Grid> 
          )})
        )
      
      case (1634):
        return (
          respuestas.map((question, index) => {
            const imageList =  {
              6075: picture13421,
              6076: picture13422,
              6077: picture13423,
              6078: picture13424,
            }
            return(
              <Grid container  item xs={6}>
              <h3 >{question.respuesta ? (question.correcta > 0 ? "correcta" : "incorrecta") : ""}</h3>
              <RespuestasImg
                key={"respuesta" + question.id_pregunta} 
                answer={question.respuesta}
                correct={question.correcta > 0}
                imageList={imageList}
                question={question}
              />
              
                <Grid >
                  <p 
                    dangerouslySetInnerHTML={{__html: `${question.feedback}`}}
                    // className={ question.reading}
                    // className={ question.correcta > 0 ? classes.correctFeedback : classes.wrongFeedback }
                  >
                  </p>
                </Grid>
              </Grid> 
          )})
        )
      
      case (1659):
        return (
          respuestas.map((question, index) => {
            const imageList =  {
              6169: picture13671,
              6170: picture13672,
              6171: picture13673,
              6172: picture13674,
            }
            return(
              <Grid container  item xs={6}>
              <h3 >{question.respuesta ? (question.correcta > 0 ? "correcta" : "incorrecta") : ""}</h3>
              <RespuestasImg
                key={"respuesta" + question.id_pregunta} 
                answer={question.respuesta}
                correct={question.correcta > 0}
                imageList={imageList}
                question={question}
              />
              
                <Grid >
                  <p 
                    dangerouslySetInnerHTML={{__html: `${question.feedback}`}}
                    // className={ question.reading}
                    // className={ question.correcta > 0 ? classes.correctFeedback : classes.wrongFeedback }
                  >
                  </p>
                </Grid>
              </Grid> 
          )})
        )
      
      case (1697):
        return (
          respuestas.map((question, index) => {
            const imageList =  {
              6312: picture13951,
              6313: picture13952,
              6314: picture13953,
              6315: picture13954,
            }
            return(
              <Grid container  item xs={6}>
              <h3 >{question.respuesta ? (question.correcta > 0 ? "correcta" : "incorrecta") : ""}</h3>
              <RespuestasImg
                key={"respuesta" + question.id_pregunta} 
                answer={question.respuesta}
                correct={question.correcta > 0}
                imageList={imageList}
                question={question}
              />
              
                <Grid >
                  <p 
                    dangerouslySetInnerHTML={{__html: `${question.feedback}`}}
                    // className={ question.reading}
                    // className={ question.correcta > 0 ? classes.correctFeedback : classes.wrongFeedback }
                  >
                  </p>
                </Grid>
              </Grid> 
          )})
        )
      
      case (1749):
        return (
          respuestas.map((question, index) => {
            const imageList =  {
              6505: picture14471,
              6506: picture14472,
              6507: picture14473,
              6508: picture14474,
            }
            return(
              <Grid container  item xs={6}>
              <h3 >{question.respuesta ? (question.correcta > 0 ? "correcta" : "incorrecta") : ""}</h3>
              <RespuestasImg
                key={"respuesta" + question.id_pregunta} 
                answer={question.respuesta}
                correct={question.correcta > 0}
                imageList={imageList}
                question={question}
              />
              
                <Grid >
                  <p 
                    dangerouslySetInnerHTML={{__html: `${question.feedback}`}}
                    // className={ question.reading}
                    // className={ question.correcta > 0 ? classes.correctFeedback : classes.wrongFeedback }
                  >
                  </p>
                </Grid>
              </Grid> 
          )})
        )
      
      case (1783):
        return (
          respuestas.map((question, index) => {
            const imageList =  {
              6629: picture14811,
              6630: picture14812,
              6631: picture14813,
              6632: picture14814,
            }
            return(
              <Grid container  item xs={6}>
              <h3 >{question.respuesta ? (question.correcta > 0 ? "correcta" : "incorrecta") : ""}</h3>
              <RespuestasImg
                key={"respuesta" + question.id_pregunta} 
                answer={question.respuesta}
                correct={question.correcta > 0}
                imageList={imageList}
                question={question}
              />
              
                <Grid >
                  <p 
                    dangerouslySetInnerHTML={{__html: `${question.feedback}`}}
                    // className={ question.reading}
                    // className={ question.correcta > 0 ? classes.correctFeedback : classes.wrongFeedback }
                  >
                  </p>
                </Grid>
              </Grid> 
          )})
        )
      
      case (1786):
        return (
          respuestas.map((question, index) => {
            const imageList =  {
              6641: picture14841,
              6642: picture14842,
              6643: picture14843,
              6644: picture14844,
            }
            return(
              <Grid container  item xs={6}>
              <h3 >{question.respuesta ? (question.correcta > 0 ? "correcta" : "incorrecta") : ""}</h3>
              <RespuestasImg
                key={"respuesta" + question.id_pregunta} 
                answer={question.respuesta}
                correct={question.correcta > 0}
                imageList={imageList}
                question={question}
              />
              
                <Grid >
                  <p 
                    dangerouslySetInnerHTML={{__html: `${question.feedback}`}}
                    // className={ question.reading}
                    // className={ question.correcta > 0 ? classes.correctFeedback : classes.wrongFeedback }
                  >
                  </p>
                </Grid>
              </Grid> 
          )})
        )
      
      case (1788):
        return (
          respuestas.map((question, index) => {
            const imageList =  {
              6649: picture14861,
              6650: picture14862,
              6651: picture14863,
              6652: picture14864,
            }
            return(
              <Grid container  item xs={6}>
              <h3 >{question.respuesta ? (question.correcta > 0 ? "correcta" : "incorrecta") : ""}</h3>
              <RespuestasImg
                key={"respuesta" + question.id_pregunta} 
                answer={question.respuesta}
                correct={question.correcta > 0}
                imageList={imageList}
                question={question}
              />
              
                <Grid >
                  <p 
                    dangerouslySetInnerHTML={{__html: `${question.feedback}`}}
                    // className={ question.reading}
                    // className={ question.correcta > 0 ? classes.correctFeedback : classes.wrongFeedback }
                  >
                  </p>
                </Grid>
              </Grid> 
          )})
        )
      
      case (1894):
        return (
          respuestas.map((question, index) => {
            const imageList =  {
              7043: picture15821,
              7044: picture15824,
              7045: picture15823,
              7046: picture15822,
            }
            return(
              <Grid container  item xs={6}>
              <h3 >{question.respuesta ? (question.correcta > 0 ? "correcta" : "incorrecta") : ""}</h3>
              <RespuestasImg
                key={"respuesta" + question.id_pregunta} 
                answer={question.respuesta}
                correct={question.correcta > 0}
                imageList={imageList}
                question={question}
              />
              
                <Grid >
                  <p 
                    dangerouslySetInnerHTML={{__html: `${question.feedback}`}}
                    // className={ question.reading}
                    // className={ question.correcta > 0 ? classes.correctFeedback : classes.wrongFeedback }
                  >
                  </p>
                </Grid>
              </Grid> 
          )})
        )
      
      case (1968):
        return (
          respuestas.map((question, index) => {
            const imageList =  {
              7318: picture20721,
              7319: picture20722,
              7320: picture20723,
              7321: picture20724,
            }
            return(
              <Grid container  item xs={6}>
              <h3 >{question.respuesta ? (question.correcta > 0 ? "correcta" : "incorrecta") : ""}</h3>
              <RespuestasImg
                key={"respuesta" + question.id_pregunta} 
                answer={question.respuesta}
                correct={question.correcta > 0}
                imageList={imageList}
                question={question}
              />
              
                <Grid >
                  <p 
                    dangerouslySetInnerHTML={{__html: `${question.feedback}`}}
                    // className={ question.reading}
                    // className={ question.correcta > 0 ? classes.correctFeedback : classes.wrongFeedback }
                  >
                  </p>
                </Grid>
              </Grid> 
          )})
        )
      
      case (2068):
        return (
          respuestas.map((question, index) => {
            const imageList =  {
              7688: picture21722,
              7689: picture21723,
              7690: picture21721,
              7691: picture21724,
            }
            return(
              <Grid container  item xs={6}>
              <h3 >{question.respuesta ? (question.correcta > 0 ? "correcta" : "incorrecta") : ""}</h3>
              <RespuestasImg
                key={"respuesta" + question.id_pregunta} 
                answer={question.respuesta}
                correct={question.correcta > 0}
                imageList={imageList}
                question={question}
              />
              
                <Grid >
                  <p 
                    dangerouslySetInnerHTML={{__html: `${question.feedback}`}}
                    // className={ question.reading}
                    // className={ question.correcta > 0 ? classes.correctFeedback : classes.wrongFeedback }
                  >
                  </p>
                </Grid>
              </Grid> 
          )})
        )
      
      case (2072):
        return (
          respuestas.map((question, index) => {
            const imageList =  {
              7704: picture21761,
              7705: picture21762,
              7706: picture21763,
              7707: picture21764,
            }
            return(
              <Grid container  item xs={6}>
              <h3 >{question.respuesta ? (question.correcta > 0 ? "correcta" : "incorrecta") : ""}</h3>
              <RespuestasImg
                key={"respuesta" + question.id_pregunta} 
                answer={question.respuesta}
                correct={question.correcta > 0}
                imageList={imageList}
                question={question}
              />
              
                <Grid >
                  <p 
                    dangerouslySetInnerHTML={{__html: `${question.feedback}`}}
                    // className={ question.reading}
                    // className={ question.correcta > 0 ? classes.correctFeedback : classes.wrongFeedback }
                  >
                  </p>
                </Grid>
              </Grid> 
          )})
        )
      
      case (3211):
        return (
          respuestas.map((question, index) => {
            const imageList =  {
              11941: picture40774,
              11942: picture40772,
              11943: picture40775,
              11944: picture40773,
            }
            return(
              <Grid container  item xs={6}>
              <h3 >{question.respuesta ? (question.correcta > 0 ? "correcta" : "incorrecta") : ""}</h3>
              <RespuestasImg
                key={"respuesta" + question.id_pregunta} 
                answer={question.respuesta}
                correct={question.correcta > 0}
                imageList={imageList}
                question={question}
              />
              
                <Grid >
                  <p 
                    dangerouslySetInnerHTML={{__html: `${question.feedback}`}}
                    // className={ question.reading}
                    // className={ question.correcta > 0 ? classes.correctFeedback : classes.wrongFeedback }
                  >
                  </p>
                </Grid>
              </Grid> 
          )})
        )
      
      case (3235):
        return (
          respuestas.map((question, index) => {
            const imageList =  {
              12028: picture41021,
              12029: picture41022,
              12030: picture41023,
              12031: picture41024,
            }
            return(
              <Grid container  item xs={6}>
              <h3 >{question.respuesta ? (question.correcta > 0 ? "correcta" : "incorrecta") : ""}</h3>
              <RespuestasImg
                key={"respuesta" + question.id_pregunta} 
                answer={question.respuesta}
                correct={question.correcta > 0}
                imageList={imageList}
                question={question}
              />
              
                <Grid >
                  <p 
                    dangerouslySetInnerHTML={{__html: `${question.feedback}`}}
                    // className={ question.reading}
                    // className={ question.correcta > 0 ? classes.correctFeedback : classes.wrongFeedback }
                  >
                  </p>
                </Grid>
              </Grid> 
          )})
        )
      
      case (3391):
        return (
          respuestas.map((question, index) => {
            const imageList =  {
              12610: picture42481,
              12611: picture42482,
              12612: picture42483,
              12613: picture42484,
            }
            return(
              <Grid container  item xs={6}>
              <h3 >{question.respuesta ? (question.correcta > 0 ? "correcta" : "incorrecta") : ""}</h3>
              <RespuestasImg
                key={"respuesta" + question.id_pregunta} 
                answer={question.respuesta}
                correct={question.correcta > 0}
                imageList={imageList}
                question={question}
              />
              
                <Grid >
                  <p 
                    dangerouslySetInnerHTML={{__html: `${question.feedback}`}}
                    // className={ question.reading}
                    // className={ question.correcta > 0 ? classes.correctFeedback : classes.wrongFeedback }
                  >
                  </p>
                </Grid>
              </Grid> 
          )})
        )
      
      case (3407):
        return (
          respuestas.map((question, index) => {
            const imageList =  {
              12668: picture42641,
              12669: picture42642,
              12670: picture42643,
              12671: picture42644,
            }
            return(
              <Grid container  item xs={6}>
              <h3 >{question.respuesta ? (question.correcta > 0 ? "correcta" : "incorrecta") : ""}</h3>
              <RespuestasImg
                key={"respuesta" + question.id_pregunta} 
                answer={question.respuesta}
                correct={question.correcta > 0}
                imageList={imageList}
                question={question}
              />
              
                <Grid >
                  <p 
                    dangerouslySetInnerHTML={{__html: `${question.feedback}`}}
                    // className={ question.reading}
                    // className={ question.correcta > 0 ? classes.correctFeedback : classes.wrongFeedback }
                  >
                  </p>
                </Grid>
              </Grid> 
          )})
        )
      
      case (113):
        return (
          respuestas.map((question, index) => {
            const imageList =  {
              419: picture60823,
              420: picture60824,
              421: picture60825,
              422: picture60822,
            }
            return(
              <Grid container  item xs={6}>
              <h3 >{question.respuesta ? (question.correcta > 0 ? "correcta" : "incorrecta") : ""}</h3>
              <RespuestasImg
                key={"respuesta" + question.id_pregunta} 
                answer={question.respuesta}
                correct={question.correcta > 0}
                imageList={imageList}
                question={question}
              />
              
                <Grid >
                  <p 
                    dangerouslySetInnerHTML={{__html: `${question.feedback}`}}
                  >
                  </p>
                </Grid>
              </Grid> 
          )})
        )
            
      case (117):
        return (
          respuestas.map((question, index) => {
            const imageList =  {
              435: picture60828,
              436: picture60829,
              437: picture608210,
              438: picture608211,
            }
            return(
              <Grid container  item xs={6}>
              <h3 >{question.respuesta ? (question.correcta > 0 ? "correcta" : "incorrecta") : ""}</h3>
              <RespuestasImg
                key={"respuesta" + question.id_pregunta} 
                answer={question.respuesta}
                correct={question.correcta > 0}
                imageList={imageList}
                question={question}
              />
              
                <Grid >
                  <p 
                    dangerouslySetInnerHTML={{__html: `${question.feedback}`}}
                    // className={ question.reading}
                    // className={ question.correcta > 0 ? classes.correctFeedback : classes.wrongFeedback }
                  >
                  </p>
                </Grid>
              </Grid> 
          )})
        )
      
      case (127):
        return (
          respuestas.map((question, index) => {
            const imageList =  {
              472: picture60971,
              473: picture60972,
              474: picture60973,
              475: picture60974,
            }
            return(
              <Grid container  item xs={6}>
              <h3 >{question.respuesta ? (question.correcta > 0 ? "correcta" : "incorrecta") : ""}</h3>
              <RespuestasImg
                key={"respuesta" + question.id_pregunta} 
                answer={question.respuesta}
                correct={question.correcta > 0}
                imageList={imageList}
                question={question}
              />
              
                <Grid >
                  <p 
                    dangerouslySetInnerHTML={{__html: `${question.feedback}`}}
                  >
                  </p>
                </Grid>
              </Grid> 
          )})
        )
      
      case (156):
        return (
          respuestas.map((question, index) => {
            const imageList =  {
              579: picture61261,
              580: picture61262,
              581: picture61263,
              582: picture61264,
            }
            return(
              <Grid container  item xs={6}>
              <h3 >{question.respuesta ? (question.correcta > 0 ? "correcta" : "incorrecta") : ""}</h3>
              <RespuestasImg
                key={"respuesta" + question.id_pregunta} 
                answer={question.respuesta}
                correct={question.correcta > 0}
                imageList={imageList}
                question={question}
              />
              
                <Grid >
                  <p 
                    dangerouslySetInnerHTML={{__html: `${question.feedback}`}}
                    // className={ question.reading}
                    // className={ question.correcta > 0 ? classes.correctFeedback : classes.wrongFeedback }
                  >
                  </p>
                </Grid>
              </Grid> 
          )})
        )
      
      case (157):
        return (
          respuestas.map((question, index) => {
            const imageList =  {
              583: picture61271,
              584: picture61272,
              585: picture61273,
              586: picture61274,
            }
            return(
              <Grid container  item xs={6}>
              <h3 >{question.respuesta ? (question.correcta > 0 ? "correcta" : "incorrecta") : ""}</h3>
              <RespuestasImg
                key={"respuesta" + question.id_pregunta} 
                answer={question.respuesta}
                correct={question.correcta > 0}
                imageList={imageList}
                question={question}
              />
              
                <Grid >
                  <p 
                    dangerouslySetInnerHTML={{__html: `${question.feedback}`}}
                    // className={ question.reading}
                    // className={ question.correcta > 0 ? classes.correctFeedback : classes.wrongFeedback }
                  >
                  </p>
                </Grid>
              </Grid> 
          )})
        )
      
      case (166):
        return (
          respuestas.map((question, index) => {
            const imageList =  {
              616: picture61361,
              617: picture61362,
              618: picture61363,
              619: picture61364,
            }
            return(
              <Grid container  item xs={6}>
              <h3 >{question.respuesta ? (question.correcta > 0 ? "correcta" : "incorrecta") : ""}</h3>
              <RespuestasImg
                key={"respuesta" + question.id_pregunta} 
                answer={question.respuesta}
                correct={question.correcta > 0}
                imageList={imageList}
                question={question}
              />
              
                <Grid >
                  <p 
                    dangerouslySetInnerHTML={{__html: `${question.feedback}`}}
                    // className={ question.reading}
                    // className={ question.correcta > 0 ? classes.correctFeedback : classes.wrongFeedback }
                  >
                  </p>
                </Grid>
              </Grid> 
          )})
        )
      
      case (224):
        return (
          respuestas.map((question, index) => {
            const imageList =  {
              830: picture61941,
              831: picture61942,
              832: picture61943,
              833: picture61944,
            }
            return(
              <Grid container  item xs={6}>
              <h3 >{question.respuesta ? (question.correcta > 0 ? "correcta" : "incorrecta") : ""}</h3>
              <RespuestasImg
                key={"respuesta" + question.id_pregunta} 
                answer={question.respuesta}
                correct={question.correcta > 0}
                imageList={imageList}
                question={question}
              />
              
                <Grid >
                  <p 
                    dangerouslySetInnerHTML={{__html: `${question.feedback}`}}
                    // className={ question.reading}
                    // className={ question.correcta > 0 ? classes.correctFeedback : classes.wrongFeedback }
                  >
                  </p>
                </Grid>
              </Grid> 
          )})
        )
      
      case (266):
        return (
          respuestas.map((question, index) => {
            const imageList =  {
              989: picture62264,
              990: picture62265,
              991: picture62266,
              992: picture62267,
            }
            return(
              <Grid container  item xs={6}>
              <h3 >{question.respuesta ? (question.correcta > 0 ? "correcta" : "incorrecta") : ""}</h3>
              <RespuestasImg
                key={"respuesta" + question.id_pregunta} 
                answer={question.respuesta}
                correct={question.correcta > 0}
                imageList={imageList}
                question={question}
              />
              
                <Grid >
                  <p 
                    dangerouslySetInnerHTML={{__html: `${question.feedback}`}}
                    // className={ question.reading}
                    // className={ question.correcta > 0 ? classes.correctFeedback : classes.wrongFeedback }
                  >
                  </p>
                </Grid>
              </Grid> 
          )})
        )
      
      case (392):
        return (
          respuestas.map((question, index) => {
            const imageList =  {
              1454: picture63521,
              1455: picture63522,
              1456: picture63523,
              1457: picture63524,
            }
            return(
              <Grid container  item xs={6}>
              <h3 >{question.respuesta ? (question.correcta > 0 ? "correcta" : "incorrecta") : ""}</h3>
              <RespuestasImg
                key={"respuesta" + question.id_pregunta} 
                answer={question.respuesta}
                correct={question.correcta > 0}
                imageList={imageList}
                question={question}
              />
              
                <Grid >
                  <p 
                    dangerouslySetInnerHTML={{__html: `${question.feedback}`}}
                    // className={ question.reading}
                    // className={ question.correcta > 0 ? classes.correctFeedback : classes.wrongFeedback }
                  >
                  </p>
                </Grid>
              </Grid> 
          )})
        )
      
      case (417):
        return (
          respuestas.map((question, index) => {
            const imageList =  {
              1548: picture1Q,
              1549: picture2Q,
              1550: picture3Q,
              1551: picture4Q,
            }
            return(
              <Grid container  item xs={6}>
              <h3 >{question.respuesta ? (question.correcta > 0 ? "correcta" : "incorrecta") : ""}</h3>
              <RespuestasImg
                key={"respuesta" + question.id_pregunta} 
                answer={question.respuesta}
                correct={question.correcta > 0}
                imageList={imageList}
                question={question}
              />
              
                <Grid >
                  <p 
                    dangerouslySetInnerHTML={{__html: `${question.feedback}`}}
                    // className={ question.reading}
                    // className={ question.correcta > 0 ? classes.correctFeedback : classes.wrongFeedback }
                  >
                  </p>
                </Grid>
              </Grid> 
          )})
        )
      
      case (448):
        return (
          respuestas.map((question, index) => {
            const imageList =  {
              1666: picture63931,
              1667: picture63932,
              1668: picture63933,
              1669: picture63934,
            }
            return(
              <Grid container  item xs={6}>
              <h3 >{question.respuesta ? (question.correcta > 0 ? "correcta" : "incorrecta") : ""}</h3>
              <RespuestasImg
                key={"respuesta" + question.id_pregunta} 
                answer={question.respuesta}
                correct={question.correcta > 0}
                imageList={imageList}
                question={question}
              />
              
                <Grid >
                  <p 
                    dangerouslySetInnerHTML={{__html: `${question.feedback}`}}
                    // className={ question.reading}
                    // className={ question.correcta > 0 ? classes.correctFeedback : classes.wrongFeedback }
                  >
                  </p>
                </Grid>
              </Grid> 
          )})
        )

        case (667):
          return (
            respuestas.map((question, index) => {
                const imageList =  {
                  2481: picture638r1,
                  2482: picture638r2,
                  2483: picture638r3,
                  2484: picture638r4,
                }
              return(
                <Grid container  item xs={6}>
                <h3 >{question.respuesta ? (question.correcta > 0 ? "correcta" : "incorrecta") : ""}</h3>
                <RespuestasImg
                  key={"respuesta" + question.id_pregunta} 
                  answer={question.respuesta}
                  correct={question.correcta > 0}
                  imageList={imageList}
                  question={question}
                />
                
                  <Grid >
                    <p 
                      dangerouslySetInnerHTML={{__html: `${question.feedback}`}}
                      // className={ question.reading}
                      // className={ question.correcta > 0 ? classes.correctFeedback : classes.wrongFeedback }
                    >
                    </p>
                  </Grid>
                </Grid> 
            )})
        )
        
      
      case (431):
        return (
          respuestas.map((question, index) => {
            const imageList =  {
              1598: picture638r1,
              1599: picture638r2,
              1600: picture638r3,
              1601: picture638r4,
            }
            return(
              <Grid container  item xs={6}>
              <h3 >{question.respuesta ? (question.correcta > 0 ? "correcta" : "incorrecta") : ""}</h3>
              <RespuestasImg
                key={"respuesta" + question.id_pregunta} 
                answer={question.respuesta}
                correct={question.correcta > 0}
                imageList={imageList}
                question={question}
              />
              
                <Grid >
                  <p 
                    dangerouslySetInnerHTML={{__html: `${question.feedback}`}}
                    // className={ question.reading}
                    // className={ question.correcta > 0 ? classes.correctFeedback : classes.wrongFeedback }
                  >
                  </p>
                </Grid>
              </Grid> 
          )})
        )
      
      case (496):
        return (
          respuestas.map((question, index) => {
            const imageList =  {
              1843: picture64461,
              1844: picture64462,
              1845: picture64463,
              1846: picture64464,
            }
            return(
              <Grid container  item xs={6}>
              <h3 >{question.respuesta ? (question.correcta > 0 ? "correcta" : "incorrecta") : ""}</h3>
              <RespuestasImg
                key={"respuesta" + question.id_pregunta} 
                answer={question.respuesta}
                correct={question.correcta > 0}
                imageList={imageList}
                question={question}
              />
              
                <Grid >
                  <p 
                    dangerouslySetInnerHTML={{__html: `${question.feedback}`}}
                    // className={ question.reading}
                    // className={ question.correcta > 0 ? classes.correctFeedback : classes.wrongFeedback }
                  >
                  </p>
                </Grid>
              </Grid> 
          )})
        )
      
      case (535):
        return (
          respuestas.map((question, index) => {
            const imageList =  {
              1986: picture64851,
              1987: picture64852,
              1988: picture64853,
              1989: picture64854,
            }
            return(
              <Grid container  item xs={6}>
              <h3 >{question.respuesta ? (question.correcta > 0 ? "correcta" : "incorrecta") : ""}</h3>
              <RespuestasImg
                key={"respuesta" + question.id_pregunta} 
                answer={question.respuesta}
                correct={question.correcta > 0}
                imageList={imageList}
                question={question}
              />
              
                <Grid >
                  <p 
                    dangerouslySetInnerHTML={{__html: `${question.feedback}`}}
                    // className={ question.reading}
                    // className={ question.correcta > 0 ? classes.correctFeedback : classes.wrongFeedback }
                  >
                  </p>
                </Grid>
              </Grid> 
          )})
        )
      
      case (603):
        return (
          respuestas.map((question, index) => {
            const imageList =  {
              2237: picture65532,
              2238: picture65533,
              2239: picture65534,
              2240: picture65535,
            }
            return(
              <Grid container  item xs={6}>
              <h3 >{question.respuesta ? (question.correcta > 0 ? "correcta" : "incorrecta") : ""}</h3>
              <RespuestasImg
                key={"respuesta" + question.id_pregunta} 
                answer={question.respuesta}
                correct={question.correcta > 0}
                imageList={imageList}
                question={question}
              />
              
                <Grid >
                  <p 
                    dangerouslySetInnerHTML={{__html: `${question.feedback}`}}
                    // className={ question.reading}
                    // className={ question.correcta > 0 ? classes.correctFeedback : classes.wrongFeedback }
                  >
                  </p>
                </Grid>
              </Grid> 
          )})
        )
      
      case (1962):
        return (
          respuestas.map((question, index) => {
            const imageList =  {
              7297: picture20662,
              7298: picture20663,
              7299: picture20664,
              7300: picture20665,
            }
            return(
              <Grid container  item xs={6}>
              <h3 >{question.respuesta ? (question.correcta > 0 ? "correcta" : "incorrecta") : ""}</h3>
              <RespuestasImg
                key={"respuesta" + question.id_pregunta} 
                answer={question.respuesta}
                correct={question.correcta > 0}
                imageList={imageList}
                question={question}
              />
              
                <Grid >
                  <p 
                    dangerouslySetInnerHTML={{__html: `${question.feedback}`}}
                    // className={ question.reading}
                    // className={ question.correcta > 0 ? classes.correctFeedback : classes.wrongFeedback }
                  >
                  </p>
                </Grid>
              </Grid> 
          )})
        )
      
      case (83):
        return (
          respuestas.map((question, index) => {
            const imageList =  {
              308: picture60531,
              309: picture60532,
              310: picture60533,
              311: picture60534,
            }
            return(
              <Grid container  item xs={6}>
              <h3 >{question.respuesta ? (question.correcta > 0 ? "correcta" : "incorrecta") : ""}</h3>
              <RespuestasImg
                key={"respuesta" + question.id_pregunta} 
                answer={question.respuesta}
                correct={question.correcta > 0}
                imageList={imageList}
                question={question}
              />
              
                <Grid >
                  <p 
                    dangerouslySetInnerHTML={{__html: `${question.feedback}`}}
                  >
                  </p>
                </Grid>
              </Grid> 
          )})
        )
      
      case (3751):
        return (
          respuestas.map((question, index) => {
            const imageList =  {
              13948: picture45882,
              13949: picture45883,
              13950: picture45884,
              13951: picture45885,
            }
            return(
              <Grid container  item xs={6}>
              <h3 >{question.respuesta ? (question.correcta > 0 ? "correcta" : "incorrecta") : ""}</h3>
              <RespuestasImg
                key={"respuesta" + question.id_pregunta} 
                answer={question.respuesta}
                correct={question.correcta > 0}
                imageList={imageList}
                question={question}
              />
              
                <Grid 
                container
                item
                alignItems="center"
                justifyContent="center"
                >
                  <p
                    style={{width: "90%"}} 
                    dangerouslySetInnerHTML={{__html: `${question.feedback}`}}
                  >
                  </p>
                </Grid>
              </Grid> 
          )})
        )
      
      default:
        return (
          respuestas.map((question, index) => {
            return(
              <Grid container  item xs={12}>
              <h3 >{question.respuesta ? (question.correcta > 0 ? "correcta" : "incorrecta") : ""}</h3>
              <Respuestas
                key={"respuesta" + index} 
                answer={question.respuesta}
                correct={question.correcta > 0}
              />
              
                <Grid >
                  <p 
                    dangerouslySetInnerHTML={{__html: `${question.feedback}`}}
                    // className={ question.reading}
                    // className={ question.correcta > 0 ? classes.correctFeedback : classes.wrongFeedback }
                  >
                  </p>
                </Grid>
              </Grid> 
          )})
        )
    }
  }

  const picturesTable1 = [
    picture62261,
    picture62262,
    picture62263,
  ]

  const picturesTable2 = [
    picture56,
    picture66,
    picture76,
    picture86,
    picture96,
    picture106,
  ]

  const picturesTableQ = [
    Qpicture1,
    Qpicture2,
    Qpicture3,
    Qpicture4

  ]

  const picturesTable605 = [
    picture60535,
    picture60536,
    picture60537,
    picture60538,
    picture60539,
    picture605310,
    ]

  
  const renderImagesQuestion = () => {
    switch (question.id_pregunta) {
      case(1508):
      return(
        <Grid container item xs={12} alignItems="center" justifyContent="center">
          <p           
            // dangerouslySetInnerHTML={{__html: `${questionInfo}`}}
            className={classes.questionText}
          >
            "A partir de lo que aprendiste en la lectura, ¿qué emoción crees que sentía el pintor al hacer 
            este cuadro?
          </p>
          <br/>
          <img alt="img" src={picture12160} className={classes.imgStyle}/>
          <p className={classes.questionText}>“Paisaje en Port-en-Bessin, Normandía” (1888), de Georges Seurat"</p>
        </Grid>
      )
      
      case(1556):
      return(
        <Grid container item xs={12} alignItems="center" justifyContent="center">
          <p           
            // dangerouslySetInnerHTML={{__html: `${questionInfo}`}}
            className={classes.questionText}
          >
            ¿Qué figura geométrica es ésta?
          </p>
          <br/>
          <img alt="img" src={picture12640} className={classes.imgStyle}/>
        </Grid>
      )
      
      case(1784):
      return(
        <Grid container item xs={12} alignItems="center" justifyContent="center">
          <p           
            // dangerouslySetInnerHTML={{__html: `${questionInfo}`}}
            className={classes.questionText}
          >
            ¿Cuántas aristas, o líneas, tiene el vértice que está marcado?
          </p>
          <br/>
          <img alt="img" src={picture14820} className={classes.imgStyle}/>
        </Grid>
      )
      
      case(1824):
      return(
        <Grid container item xs={12} alignItems="center" justifyContent="center">
          <p           
            // dangerouslySetInnerHTML={{__html: `${questionInfo}`}}
            className={classes.questionText}
          >
            ¿Qué información crees que sea ésta?
          </p>
          <br/>
          <img alt="img" src={picture15220} className={classes.imgStyle}/>
        </Grid>
      )
      
      case(1893):
      return(
        <Grid container item xs={12} alignItems="center" justifyContent="center">
          <p           
            // dangerouslySetInnerHTML={{__html: `${questionInfo}`}}
            className={classes.questionText}
          >
            ¿Con cuál párrafo tiene relación la primera imagen de la lectura?
          </p>
          <br/>
          <img alt="img" src={picture15810} className={classes.imgStyle}/>
        </Grid>
      )
      
      case(1897):
      return(
        <Grid container item xs={12} alignItems="center" justifyContent="center">
          <p           
            // dangerouslySetInnerHTML={{__html: `${questionInfo}`}}
            className={classes.questionText}
          >
             ¿Con cuál párrafo se relaciona la siguiente imagen?
          </p>
          <br/>
          <img alt="img" src={picture15821} className={classes.imgStyle}/>
        </Grid>
      )
      
      case(1968):
      return(
        <Grid container item xs={12} alignItems="center" justifyContent="center">
          <p           
            // dangerouslySetInnerHTML={{__html: `${questionInfo}`}}
            className={classes.questionText}
          >
            En la lectura se dice que las artesanías están hechas con los materiales que hay en cada comunidad. 
            Imagina que vas a un lugar como el de la ilustración. ¿Qué tipo de artesanías crees que haya?
          </p>
          <br/>
          <img alt="img" src={picture20720} className={classes.imgStyle}/>
        </Grid>
      )
      
      case(1980):
      return(
        <Grid container item xs={12} alignItems="center" justifyContent="center">
          <p           
            // dangerouslySetInnerHTML={{__html: `${questionInfo}`}}
            className={classes.questionText}
          >
            ¿Cuántos elementos conforman el siguiente conjunto de frutas?
          </p>
          <br/>
          <img alt="img" src={picture20840} className={classes.imgStyle}/>
        </Grid>
      )
      
      case(2010):
      return(
        <Grid container item xs={12} alignItems="center" justifyContent="center">
          <p           
            // dangerouslySetInnerHTML={{__html: `${questionInfo}`}}
            className={classes.questionText}
          >
            ¿Cuál de las acciones representadas en las siguientes ilustraciones afecta de manera negativa a las aves migratorias?
          </p>
          <br/>
          <img alt="img" src={picture21141} className={classes.imgStyle} style={{margin: "5px 10%"}}/>
          <img alt="img" src={picture21142} className={classes.imgStyle} style={{margin: "5px 10%"}}/>
          <img alt="img" src={picture21143} className={classes.imgStyle} style={{margin: "5px 10%"}}/>
          <img alt="img" src={picture21144} className={classes.imgStyle} style={{margin: "5px 10%"}}/>
        </Grid>
      )
      
      case(2171):
      return(
        <Grid container item xs={12} alignItems="center" justifyContent="center">
          <p           
            // dangerouslySetInnerHTML={{__html: `${questionInfo}`}}
            className={classes.questionText}
          >
            ¿Cuál de estas figuras es un trapecio?
          </p>
          <br/>
          <img alt="img" src={picture22650} className={classes.imgStyle}/>
        </Grid>
      )
      
      case(2173):
      return(
        <Grid container item xs={12} alignItems="center" justifyContent="center">
          <p           
            // dangerouslySetInnerHTML={{__html: `${questionInfo}`}}
            className={classes.questionText}
          >
            Observa la siguiente silueta y revisa el Tangram que se encuentra abajo. 
            ¿Cuál de las figuras marcadas es la que falta?
          </p>
          <br/>
          <img alt="img" src={picture22670} className={classes.imgStyle}/>
        </Grid>
      )
      
      case(2227):
      return(
        <Grid container item xs={12} alignItems="center" justifyContent="center">
          <p           
            // dangerouslySetInnerHTML={{__html: `${questionInfo}`}}
            className={classes.questionText}
          >
            Observa las siguientes imágenes y marca cuál es la que corresponde al tipo de juego descrito
          </p>
          <br/>
          <img alt="img" src={picture23210} className={classes.imgStyle} style={{width: "50%"}}/>
          <p className={classes.reading} >
          1. Juego de mesa
          </p>
          <p className={classes.reading} >
          2. Juego de rol
          </p>
          <p className={classes.reading} >
          3. Juguete
          </p>
          <p className={classes.reading} >
          4. Ronda
          </p>
        </Grid>
      )
      
      case(2230):
      return(
        <Grid container item xs={12} alignItems="center" justifyContent="center">
          <p           
            // dangerouslySetInnerHTML={{__html: `${questionInfo}`}}
            className={classes.questionText}
          >
            Observa detenidamente la siguiente imagen. ¿Conoces el juego? Imagina que tú y tus amigos lo 
            juegan, ¿qué habilidades crees que les fomente?
          </p>
          <br/>
          <img alt="img" src={picture23244} className={classes.imgStyle}/>
        </Grid>
      )
      
      case(2398):
      return(
        <Grid container item xs={12} alignItems="center" justifyContent="center">
          <p           
            // dangerouslySetInnerHTML={{__html: `${questionInfo}`}}
            className={classes.questionText}
          >
        ¿En cuál de las actividades que se muestran en las ilustraciones son indispensables las matemáticas?
      </p>
      <br/>
      <img alt="img" src={picture24821} className={classes.imgStyle} style={{margin: "5px 10%"}}/>
      <img alt="img" src={picture24822} className={classes.imgStyle} style={{margin: "5px 10%"}}/>
      <img alt="img" src={picture24823} className={classes.imgStyle} style={{margin: "5px 10%"}}/>
      <img alt="img" src={picture24824} className={classes.imgStyle} style={{margin: "5px 10%"}}/>
        </Grid>
      )
      
      case(2399):
      return(
        <Grid container item xs={12} alignItems="center" justifyContent="center">
          <p           
            // dangerouslySetInnerHTML={{__html: `${questionInfo}`}}
            className={classes.questionText}
          >
        Si las piezas de un pentaminó tienen cinco cuadrados iguales, ¿cuál de las siguientes 
        figuras geométricas es un <i>pentágono</i>?
      </p>
      <br/>
      <Grid container direction="column" item xs={6} justifyContent="center" alignItems="center" >
        <img alt="img" src={picture6a} className={classes.imgStyle}/>
        <p>a.</p>
      </Grid>
      <Grid container direction="column" item xs={6} justifyContent="center" alignItems="center" >
        <img alt="img" src={picture6b} className={classes.imgStyle}/>
        <p>b.</p>
      </Grid>
      <Grid container direction="column" item xs={6} justifyContent="center" alignItems="center" >
        <img alt="img" src={picture6c} className={classes.imgStyle}/>
        <p>c.</p>
      </Grid>
      <Grid container direction="column" item xs={6} justifyContent="center" alignItems="center" >
        <img alt="img" src={picture6d} className={classes.imgStyle}/>
        <p>d.</p>
      </Grid>
        </Grid>
      )
       
      case(2400):
      return(
        <Grid container item xs={12} alignItems="center" justifyContent="center">
          <p           
            // dangerouslySetInnerHTMsL={{__html: `${questionInfo}`}}
            className={classes.questionText}
          >
        ¿Cuál de las siguientes figuras es parte del pentaminó?
      </p>
      <br/>
      <Grid container direction="column" item xs={6} justifyContent="center" alignItems="center" >
        <img alt="img" src={picture7a} className={classes.imgStyle}/>
        <p>a.</p>
      </Grid>
      <Grid container direction="column" item xs={6} justifyContent="center" alignItems="center" >
        <img alt="img" src={picture7b} className={classes.imgStyle}/>
        <p>b.</p>
      </Grid>
      <Grid container direction="column" item xs={6} justifyContent="center" alignItems="center" >
        <img alt="img" src={picture7c} className={classes.imgStyle}/>
        <p>c.</p>
      </Grid>
      <Grid container direction="column" item xs={6} justifyContent="center" alignItems="center" >
        <img alt="img" src={picture7d} className={classes.imgStyle}/>
        <p>d.</p>
      </Grid>
        </Grid>
      )
      
      case(2402):
      return(
        <Grid container item xs={12} alignItems="center" justifyContent="center">
          <p           
            // dangerouslySetInnerHTML={{__html: `${questionInfo}`}}
            className={classes.questionText}
          >
            Observa la siguiente imagen. ¿Qué figura se debe utilizar para formar la pata delantera del cocodrilo?
          </p>
          <br/>
          <img alt="img" src={picture24860} className={classes.imgStyle}/>
        </Grid>
      )
      
      case(2546):
      return(
        <Grid container item xs={12} alignItems="center" justifyContent="center">
      <p
        className={classes.reading}
      >
        Clasifica los materiales de acuerdo con su uso.
      </p>
      <br/>
      <table className={classes.table}>
        <thead>
          <th style={{width: "50%", border: '1px solid black',}}><i>Materiales</i></th>
          <th style={{width: "50%", border: '1px solid black',}}><i>Uso</i></th>
        </thead>
        <tbody>
          <tr>
            <td className={classes.tdTable}>
              <p>
                periódico
              </p>
            </td>
            <td className={classes.tdTable}>
              <p>
                A. decorar
              </p>
            </td>
          </tr>
          <tr>
            <td className={classes.tdTable}>
              <p>
              cartón
              </p>
            </td>
            <td className={classes.tdTable}>
              <p>
              B. proteger
              </p>
            </td>
          </tr>
          <tr>
            <td className={classes.tdTable}>
              <p>
              pintura
              </p>
            </td>
            <td className={classes.tdTable}>
              <p>
              C. recortar
              </p>
            </td>
          </tr>
          <tr>
            <td className={classes.tdTable}>
              <p>
              tijeras
              </p>
            </td>
            <td className={classes.tdTable}>
              <p>
              D. pintar
              </p>
            </td>
          </tr>
        </tbody>
      </table>
        </Grid>
      )
      
      case(2607):
      return(
        <Grid container item xs={12} alignItems="center" justifyContent="center">
      <p
        className={classes.reading}
      >
         Relaciona los países de acuerdo con su clasificación
      </p>
      <br/>
      <table className={classes.table}>
        <thead>
          <th style={{width: "40%",border: '1px solid black', textAlign: 'center'}}><i>Clasificación</i></th>
          <th style={{width: "60%",border: '1px solid black', textAlign: 'center'}}><i>Países</i></th>
        </thead>
        <tbody>
          <tr>
            <td className={classes.tdTable}>
              <p>
                Desarrollado
              </p>
            </td>
            <td className={classes.tdTable}>
              <p className={classes.tdTable}>
                a. Venezuela
              </p>
              <p className={classes.tdTable}>
                b. Islandia
              </p>
              <p className={classes.tdTable}>
                c. Ghana
              </p>
            </td>
          </tr>
          <tr>
            <td className={classes.tdTable}>
              <p>
                Subdesarrollado
              </p>
            </td>
            <td className={classes.tdTable}>
              <p className={classes.tdTable}>
                d. Canadá
              </p>
              <p className={classes.tdTable}>
                e. Alemania
              </p>
              <p className={classes.tdTable}>
                f. Bolivia
              </p>
            </td>
          </tr>
        </tbody>
      </table>
        </Grid>
      )
      
      case(2646):
      return(
        <Grid container item xs={12} alignItems="center" justifyContent="center">
          <p
            className={classes.reading}
          >
            Relaciona cada idea principal con su respectiva idea secundaria.
          </p>
          <br/>
          <p
            className={classes.reading}
          >
            <b>Un monstruo cometrigo</b>
          </p>
          <p
            className={classes.reading}
          >
            <b>Una hoja mágica</b>
          </p>
          <p
            className={classes.reading}
          >
            <b>Una niña pequeña</b>
          </p>
          <p
            className={classes.reading}
          >
            <b>Una muñeca</b>
          </p>
          <table className={classes.table}>
            <thead>
              <th style={{ border: '1px solid black',}}><i>Idea secundaria</i></th>
              {/* <th style={{width: "50%", border: '1px solid black',}}><i>Uso</i></th> */}
            </thead>
            <tbody>
              <tr>
                <td className={classes.tdTable}>
                  <p>
                    A. quiere que su mejor amiga hable.
                  </p>
                </td>
              </tr>
              <tr>
                <td className={classes.tdTable}>
                  <p>
                    B. hace que los muñecos cobren vida.
                  </p>
                </td>
              </tr>
              <tr>
                <td className={classes.tdTable}>
                  <p>
                    C. tiene fascinación por las galletas.
                  </p>
                </td>
              </tr>
              <tr>
                <td className={classes.tdTable}>
                  <p>
                    D. cobra vida y no recuerda nada.
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
        </Grid>
      )
      
      case(2738):
      return(
        <Grid container item xs={12} alignItems="center" justifyContent="center">
          <p
              className={classes.reading}
            >
              Relaciona los géneros con sus respectivas características.
            </p>
            <br/>
            <table className={classes.table}>
              <thead>
                <th style={{width: "40%",border: '1px solid black', textAlign: 'center'}}>Géneros</th>
                <th style={{width: "60%",border: '1px solid black', textAlign: 'center'}}>Características</th>
              </thead>
              <tbody>
                <tr>
                  <td className={classes.tdTable}>
                    <p>
                      Tragedia
                    </p>
                  </td>
                  <td className={classes.tdTable}>
                    <p className={classes.tdTable}>
                      a. temas mitológicos
                    </p>
                    <p className={classes.tdTable}>
                      b. ambiente festivo
                    </p>
                    <p className={classes.tdTable}>
                      c. crítica social
                    </p>
                  </td>
                </tr>
                <tr>
                  <td className={classes.tdTable}>
                    <p>
                      Comedia
                    </p>
                  </td>
                  <td className={classes.tdTable}>
                    <p className={classes.tdTable}>
                      d. conflicto personal
                    </p>
                    <p className={classes.tdTable}>
                      e. personajes heroicos
                    </p>
                    <p className={classes.tdTable}>
                      f. personajes comunes
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
        </Grid>
      )
      
      case(2746):
      return(
        <Grid container item xs={12} alignItems="center" justifyContent="center">
          <p
            className={classes.reading}
          >
             Relaciona las actividades de un arqueólogo con su respectivo complemento.
          </p>
          <br/>
          <table className={classes.table}>
            <thead>
              <th style={{width: "50%",    border: '1px solid black',}}><b>Actividades</b></th>
              <th style={{width: "50%",    border: '1px solid black',}}><b>Complemento</b></th>
            </thead>
            <tbody>
              <tr>
                <td className={classes.tdTable}>
                  <p>
                  buscar
                  </p>
                </td>
                <td className={classes.tdTable}>
                  <p>
                  a. planos
                  </p>
                </td>
              </tr>
              <tr>
                <td className={classes.tdTable}>
                  <p>
                  explorar
                  </p>
                </td>
                <td className={classes.tdTable}>
                  <p>
                  b. tesoros
                  </p>
                </td>
              </tr>
              <tr>
                <td className={classes.tdTable}>
                  <p>
                  elaborar
                  </p>
                </td>
                <td className={classes.tdTable}>
                  <p>
                  c. restos
                  </p>
                </td>
              </tr>
              <tr>
                <td className={classes.tdTable}>
                  <p>
                  analizar
                  </p>
                </td>
                <td className={classes.tdTable}>
                  <p>
                  d. ruinas
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
        </Grid>
      )
      
      case(2750):
      return(
        <Grid container item xs={12} alignItems="center" justifyContent="center">
          <p
            className={classes.reading}
          >
            Relaciona los tipos de arqueología con su campo de estudio.
          </p>
          <br/>
          <table className={classes.table}>
            <thead>
              <th style={{width: "50%",    border: '1px solid black',}}><b>Tipos de arqueología</b></th>
              <th style={{width: "50%",    border: '1px solid black',}}><b>Campos de estudio</b></th>
            </thead>
            <tbody>
              <tr>
                <td className={classes.tdTable}>
                  <p>
                  batalla
                  </p>
                </td>
                <td className={classes.tdTable}>
                  <p>
                  a. guerras
                  </p>
                </td>
              </tr>
              <tr>
                <td className={classes.tdTable}>
                  <p>
                  histórica
                  </p>
                </td>
                <td className={classes.tdTable}>
                  <p>
                  b. escritos
                  </p>
                </td>
              </tr>
              <tr>
                <td className={classes.tdTable}>
                  <p>
                  cognitiva
                  </p>
                </td>
                <td className={classes.tdTable}>
                  <p>
                  c. pensamiento
                  </p>
                </td>
              </tr>
              <tr>
                <td className={classes.tdTable}>
                  <p>
                  género
                  </p>
                </td>
                <td className={classes.tdTable}>
                  <p>
                  d. comportamiento
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
        </Grid>
      )
      
      case(2820):
      return(
        <Grid container item xs={12} alignItems="center" justifyContent="center">
          <p
            className={classes.reading}
          >
            El narrador relaciona lo que le sucede con algo de su imaginación. 
            Relaciona esas ideas principales con su correspondiente idea secundaria. 
          </p>
          <br/>
          <table className={classes.table}>
            <thead>
              <th style={{width: "50%",    border: '1px solid black',}}><b>Idea principal</b></th>
              <th style={{width: "50%",    border: '1px solid black',}}><b>Idea secundaria</b></th>
            </thead>
            <tbody>
              <tr>
                <td className={classes.tdTable}>
                  <p>
                  El humo de la quema
                  </p>
                </td>
                <td className={classes.tdTable}>
                  <p>
                    a. Un tren
                  </p>
                </td>
              </tr>
              <tr>
                <td className={classes.tdTable}>
                  <p>
                  Las hojas de la caña
                  </p>
                </td>
                <td className={classes.tdTable}>
                  <p>
                  b. Medias lunas
                  </p>
                </td>
              </tr>
              <tr>
                <td className={classes.tdTable}>
                  <p>
                  La arena del mar
                  </p>
                </td>
                <td className={classes.tdTable}>
                  <p>
                  c. Una cama 
                  </p>
                </td>
              </tr>
              <tr>
                <td className={classes.tdTable}>
                  <p>
                  Las sonrisas
                  </p>
                </td>
                <td className={classes.tdTable}>
                  <p>
                  d. Azúcar
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
        </Grid>
      )
      
      case(2847):
      return(
        <Grid container item xs={12} alignItems="center" justifyContent="center">
           <p
            className={classes.reading}
          >
            Clasifica los siguientes ejemplos como ventaja o desventaja.
          </p>
          <br/>
          <table className={classes.table}>
            <thead>
              <th style={{width: "40%",border: '1px solid black', textAlign: 'center'}}><i>Valoración</i></th>
              <th style={{width: "60%",border: '1px solid black', textAlign: 'center'}}><i>Ejemplos</i></th>
            </thead>
            <tbody>
              <tr>
                <td className={classes.tdTable}>
                  <p>
                  Ventaja
                  </p>
                </td>
                <td className={classes.tdTable}>
                  <p className={classes.tdTable}>
                  a. interacción humana reducida
                  </p>
                  <p className={classes.tdTable}>
                  b. materiales educativos
                  </p>
                </td>
              </tr>
              <tr>
                <td className={classes.tdTable}>
                  <p>
                  Desventaja
                  </p>
                </td>
                <td className={classes.tdTable}>
                  <p className={classes.tdTable}>
                  c. comunicación inmediata
                  </p>
                  <p className={classes.tdTable}>
                  d. ejercicio físico limitado
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
        </Grid>
      )
      
      case(2929):
      return(
        <Grid container item xs={12} alignItems="center" justifyContent="center">
        <p
            className={classes.reading}
          >
            Relaciona los materiales con su correspondiente transformación.
          </p>
          <br/>
          <table className={classes.table}>
            <thead>
              <th style={{width: "50%", border: '1px solid black',}}><i>MATERIALES</i></th>
              <th style={{width: "50%", border: '1px solid black',}}><i>DISCIPLINA ARTÍSTICA</i></th>
            </thead>
            <tbody>
              <tr>
                <td className={classes.tdTable}>
                  <p>
                    piedra
                  </p>
                </td>
                <td className={classes.tdTable}>
                  <p>
                  a. orfebrería
                  </p>
                </td>
              </tr>
              <tr>
                <td className={classes.tdTable}>
                  <p>
                    metal
                  </p>
                </td>
                <td className={classes.tdTable}>
                  <p>
                    b. escultura
                  </p>
                </td>
              </tr>
              <tr>
                <td className={classes.tdTable}>
                  <p>
                    papel
                  </p>
                </td>
                <td className={classes.tdTable}>
                  <p>
                    c. grabado
                  </p>
                </td>
              </tr>
              <tr>
                <td className={classes.tdTable}>
                  <p>
                    madera
                  </p>
                </td>
                <td className={classes.tdTable}>
                  <p>
                    d. dibujo
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
        </Grid>
      )
      
      case(3109):
      return(
        <Grid container item xs={12} alignItems="center" justifyContent="center">
            <p
              className={classes.reading}
            >
            Relaciona a cada personaje con su contribución.
            </p>
            <br/>
            <table className={classes.table}>
              <thead>
                <th style={{width: "50%",    border: '1px solid black',}}><b>Personaje</b></th>
                <th style={{width: "50%",    border: '1px solid black',}}><b>Contribución</b></th>
              </thead>
              <tbody>
                <tr>
                  <td className={classes.tdTable}>
                    <p>
                    Thomas Alva Edison
                    </p>
                  </td>
                  <td className={classes.tdTable}>
                    <p>
                    a. escenas cotidianas
                    </p>
                  </td>
                </tr>
                <tr>
                  <td className={classes.tdTable}>
                    <p>
                    Louis y Auguste Lumière
                    </p>
                  </td>
                  <td className={classes.tdTable}>
                    <p>
                    b. películas completas
                    </p>
                  </td>
                </tr>
                <tr>
                  <td className={classes.tdTable}>
                    <p>
                    Alice Guy
                    </p>
                  </td>
                  <td className={classes.tdTable}>
                    <p>
                    c. efectos especiales
                    </p>
                  </td>
                </tr>
                <tr>
                  <td className={classes.tdTable}>
                    <p>
                    Georges Méliès
                    </p>
                  </td>
                  <td className={classes.tdTable}>
                    <p>
                    d. ilustraciones móviles
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
        </Grid>
      )
      
      case(3210):
      return(
        <Grid container item xs={12} alignItems="center" justifyContent="center">
          <p           
            // dangerouslySetInnerHTML={{__html: `${questionInfo}`}}
            className={classes.questionText}
          >
            "Observa el organizador. Relaciona los incisos con las palabras para completarlo.
          </p>
          <br/>
          <img alt="img" src={picture40771} className={classes.imgStyle} style={{width: "70%"}} />
        </Grid>
      )
      
      case(3240):
      return(
        <Grid container item xs={12} alignItems="center" justifyContent="center">
          <p           
            // dangerouslySetInnerHTML={{__html: `${questionInfo}`}}
            className={classes.questionText}
          >
            Elige la opción que describe la imagen de acuerdo con el texto.
          </p>
          <br/>
          <img alt="img" src={picture41021} className={classes.imgStyle}/>
        </Grid>
      )
      
      case(3466):
      return(
        <Grid container item xs={12} alignItems="center" justifyContent="center">
          <p
            className={classes.reading}
          >
            ¿Qué función tienen los subtítulos en el texto?
          </p>
          <br/>
          <table className={classes.table}>
            <thead>
              <th style={{ width: "50%", border: '1px solid black',}}>Primer subtítulo</th>
              <th style={{width: "50%", border: '1px solid black',}}>Segundo subtítulo</th>
            </thead>
            <tbody>
              <tr>
                <td className={classes.tdTable}>
                  <p>
                    A 
                  </p>
                  <p>
                    Presentar información sobre cómo y cuándo surgió el lenguaje.
                  </p>
                </td>
                <td className={classes.tdTable}>
                  <p>
                    C 
                  </p>
                  <p>
                    Describir las  teorías  que  existen, pues   es   imposible   decirlo   con certeza.
                  </p>
                </td>
              </tr>
              <tr>
                <td className={classes.tdTable}>
                  <p>
                    B 
                  </p>
                  <p>
                    Exponer porqué   se   dice   que África es cuna de la humanidad.
                  </p>
                </td>
                <td className={classes.tdTable}>
                  <p>
                    D 
                  </p>
                  <p>
                    Mencionar cuáles fueron las primeras palabras de los homínidos.
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
        </Grid>
      )
      
      case(3471):
      return(
        <Grid container item xs={12} alignItems="center" justifyContent="center">
          <p           
            // dangerouslySetInnerHTML={{__html: `${questionInfo}`}}
            className={classes.questionText}
          >
            Explora el mapa conceptual. Elige la opción que lo completa.
          </p>
          <br/>
          <img alt="img" src={picture43230} className={classes.imgStyle} style={{width: "60%"}} />
        </Grid>
      )
      
      case(3545):
      return(
        <Grid container item xs={12} alignItems="center" justifyContent="center">
          <p
            className={classes.reading}
          >
            Relaciona el país o países con las palabras  clave  que corresponden, según el texto:
          </p>
          <br/>
          <table className={classes.table}>
            <thead>
              <th style={{ width: "50%", border: '1px solid black',}}><b>Países</b></th>
              <th style={{width: "50%", border: '1px solid black',}}><b>Palabras clave</b></th>
            </thead>
            <tbody>
              <tr>
                <td className={classes.tdTable}>
                  <p className={classes.marginCero}>
                    1) Argentina y Uruguay
                  </p>
                  <p className={classes.marginCero}>
                    2) Colombia
                  </p>
                  <p className={classes.marginCero}>
                    3) Costa Rica
                  </p>
                  <p className={classes.marginCero}>
                    4) Ecuador y Perú
                  </p>
                  <p className={classes.marginCero}>
                    5) México
                  </p>
                </td>
                <td className={classes.tdTable}>
                  <p className={classes.marginCero}>
                    a. comida, maíz, patrimonio
                  </p>
                  <p className={classes.marginCero}>
                    b. café, carreta, boyero
                  </p>
                  <p className={classes.marginCero}>
                    c. sabiduría, oral, selva
                  </p>
                  <p className={classes.marginCero}>
                    d. popular, baile, formal
                  </p>
                  <p className={classes.marginCero}>
                    e. festejo, música, fusión
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
        </Grid>
      )
      
      case(3678):
      return(
        <Grid container item xs={12} alignItems="center" justifyContent="center">
          <p 
            // dangerouslySetInnerHTML={{__html: `${step+1}. ${pregunta.replace( '\n', '<br/><br/>')}`}}
            className={classes.reading}
          >
            En el  documento se mencionan dos especies cuyas pieles son usadas por los habitantes de las zonas
            que habitan. ¿Cuáles son?
          </p>
          <Grid container direction="column" item xs={6} justifyContent="center" alignItems="center" >
            <img alt="img" src={picture45251} className={classes.imgStyle}/>
            <p>a.</p>
          </Grid>
          <Grid container direction="column" item xs={6} justifyContent="center" alignItems="center" >
            <img alt="img" src={picture45252} className={classes.imgStyle}/>
            <p>b.</p>
          </Grid>
          <Grid container direction="column" item xs={6} justifyContent="center" alignItems="center" >
            <img alt="img" src={picture45253} className={classes.imgStyle}/>
            <p>c.</p>
          </Grid>
          <Grid container direction="column" item xs={6} justifyContent="center" alignItems="center" >
            <img alt="img" src={picture45254} className={classes.imgStyle}/>
            <p>d.</p>
          </Grid>
        </Grid>
      )
      
      case(3751):
      return(
        <Grid container item xs={12} alignItems="center" justifyContent="center">
          <p           
            // dangerouslySetInnerHTML={{__html: `${questionInfo}`}}
            className={classes.questionText}
          >
            De acuerdo con lo que comprendiste de  los fractales, elige la figura quecompleta este modelo:
          </p>
          <br/>
          <img alt="img" src={picture45881} className={classes.imgStyle}/>
        </Grid>
      )
      
      case(919):
      return(
        <Grid container item xs={12} alignItems="center" justifyContent="center">
      <p 
        // dangerouslySetInnerHTML={{__html: `${step+1}. ${pregunta.replace( '\n', '<br/><br/>')}`}}
        className={classes.reading}
      >
        Observa las dos imágenes y selecciona la respuesta que contenga las diferencias entre ambas.
      </p>
      <br/>

        <img alt="img" src={picture52490} className={classes.imgStyle}/>
      <p className={classes.reading}>
      a. El conductor<br/>
      b. La cantidad de pasajeros<br/>
      c. El color de los asientos<br/>
      d. La cantidad de ventanas<br/>
      e. La forma de la puerta<br/>
      </p>
        </Grid>
      )
      
      case(96):
      return(
        <Grid container item xs={12} alignItems="center" justifyContent="center">
          <p
            className={classes.reading}
          >
          Relaciona el fragmento de la obra de teatro con el sentimiento que experimentan los personajes.
          </p>
          <br/>
          <table className={classes.table}>
            <tr>
              <th style={{width: "50%", border: '2px solid black',}}><b>Fragmento de la obra</b></th>
              <th style={{width: "50%", border: '2px solid black',}}><b>Sentimiento</b></th>
            </tr>
            <tr>
              <td className={classes.tdTable}>
                <text>
                  1. AMELIA. ¿Me puedo sentar? (<em>Se sienta junto a Sofía</em>) Yo también la extraño. ¿Sabes? Era muy divertida.
                </text><br/>
                <p>
                  SOFÍA. ¡Yo no la extraño! ¿Qué quieres?
                </p>
                <p>
                  AMELIA. (<em>Señala el almuerzo de Sofía</em>) ¿Me das un poco?
                </p>
                <p>
                  SOFÍA. ¡No! ¡Sólo le comparto a Fátima!
                </p>
                <text>
                  AMELIA. ¿Quieres jugar? Nos falta una niña para completar.
                </text>
              </td>
              <td className={classes.tdTable}>
                <p>
                  a. Temen decirse que están encontrando nuevas amistades para no lastimarse mutuamente.
                </p>
              </td>
            </tr>
            <tr>
              <td className={classes.tdTable}>
                <text>
                  2. VOZ DE FÁTIMA. ¿Y no juegas con nadie en el receso? ¿Con Amelia, por ejemplo?
                </text><br/>
                <p>
                  SOFÍA. Esa niña… la otra vez me pidió que le compartiera de mi almuerzo, ¿tú crees?
                </p>
                <p>
                  VOZ DE FÁTIMA. Sí, así ha sido siempre… ¿Y no juegas con ella?
                </p>
                <p>
                  SOFÍA. No, con nadie… ¿Y tú?
                </p>
                <text>
                  VOZ DE FÁTIMA. No, cómo crees, con nadie… Bueno, hay una niña… Pero no, no juego con ella… 
                </text>
              </td>
              <td className={classes.tdTable}>
                <p>
                  b. Empieza a superar la partida de su amiga.
                </p>
              </td>
            </tr>
            <tr>
            <td className={classes.tdTable}>
                <text>
                  3. <em>Sofía y Amelia salen. El almuerzo de Sofía se queda en la banca.</em> 
                </text><br/>
              </td>
              <td className={classes.tdTable}>
                <p>
                  c. Demuestra paciencia porque sabe que está pasando por un mal momento.
                </p>
              </td>
            </tr>
            <tr>
              <td className={classes.tdTable}>
                <text>
                  4. SOFÍA. (<em>Sin comprender lo que acaba de escuchar</em>) ¿O sea que ya no nos veremos?
                </text><br/>
                <p>
                  FÁTIMA. (<em>Triste</em>) No.
                </p>
                <text>
                  SOFÍA. (<em>Abraza con fuerza a Fátima</em>) ¡Noooo! ¡No te vayas! (<em>Se escucha que llora un poco. De repente, 
                  deja de abrazarla</em>) ¡Ya sé! ¡Tengo una idea! ¿Y si le digo a mi mamá que también a mí me cambie de escuela? 
                  ¡Así nos vamos juntas!.
                </text><br/>
              </td>
              <td className={classes.tdTable}>
                <p>
                  d. Es muy apegada a su mejor amiga.
                </p>
              </td>
            </tr>
          </table>

        </Grid>
      )
      
      case(128):
      return(
        <Grid container item xs={12} alignItems="center" justifyContent="center">
          <p
            className={classes.reading}
          >
          ¿Qué ejemplos muestran que el lenguaje sirve para mantener usos y costumbres y cuáles
          muestran que sirve para cambiarlos?
          </p>
          <br/>
          <table className={classes.table}>
            <tr>
              <th style={{width: "30%", textAlign: "left"}}><b>Función</b></th>
              <th style={{width: "70%", textAlign: "left"}}><b>Ejemplos</b></th>
            </tr>
            <tr>
              <td className={classes.tdTable}>
                <p >
                  1. Mantienen
                </p><br/>
              </td>
              <td className={classes.tdTable}>
                <p >
                  a. Personas realizan una manifestación con demandas y pancartas.
                </p>
              </td>
            </tr>
            <tr>
              <td className={classes.tdTable}>
                <p >
                  2. Cambian 
                </p><br/>
              </td>
              <td className={classes.tdTable}>
                <p >
                  b. Se promulga la Ley de Protección a los animales.
                </p>
              </td>
            </tr>
            <tr>
              <td className={classes.tdTable}>

              </td>
              <td className={classes.tdTable}>
                <p>
                  c. Personas rezan en una misa de Navidad.
                </p>
              </td>
            </tr>
            <tr>
              <td className={classes.tdTable}>

              </td>
              <td className={classes.tdTable}>
                <p> 
                  d. Invitados cantan una canción de cumpleaños en una fiesta.
                </p>
              </td>
            </tr>
          </table>
        </Grid>
      )
      
      case(112):
      return(
        <Grid container item xs={12} alignItems="center" justifyContent="center">
          <p           
            // dangerouslySetInnerHTML={{__html: `${questionInfo}`}}
            className={classes.questionText}
          >
            ¿Qué representa la imagen?
          </p>
          <br/>
          <img alt="img" src={picture60820} className={classes.imgStyle}/>
        </Grid>
      )
      
      case(115):
      return(
        <Grid container item xs={12} alignItems="center" justifyContent="center">
          <p           
            // dangerouslySetInnerHTML={{__html: `${questionInfo}`}}
            className={classes.questionText}
          >
            Elige la respuesta que menciona el número correcto de aristas y vértices que tiene la siguiente imagen.
          </p>
          <br/>
          <img alt="img" src={picture60826} className={classes.imgStyle}/>
        </Grid>
      )
      
      case(116):
      return(
        <Grid container item xs={12} alignItems="center" justifyContent="center">
          <p           
            // dangerouslySetInnerHTML={{__html: `${questionInfo}`}}
            className={classes.questionText}
          >
            ¿La imagen personifica la teoría de grafos? ¿Por qué?
          </p>
          <br/>
          <img alt="img" src={picture60827} className={classes.imgStyle}/>
        </Grid>
      )
      
      case(133):
      return(
        <Grid container item xs={12} alignItems="center" justifyContent="center">
      <p 
        // dangerouslySetInnerHTML={{__html: `${step+1}. ${pregunta.replace( '\n', '<br/><br/>')}`}}
        className={classes.reading}
      >
        Según las ideas secundarias subrayadas en el texto, 
        ¿cuál sería la idea principal que están complementando?
      </p>
      <br/>
      <img alt="img" src={picture61031} className={classes.imgStyle}/>
      <p className={classes.reading} >
        Hubo un tiempo en que <u>las matemáticas me parecían muy aburridas</u>. 
        No veía el caso de aprenderlas, hasta que quise ser chef repostera. Ahí me di cuenta 
        de que las matemáticas son muy importantes para la profesión y la vida diaria. 
        Por ejemplo, <u>cuando voy a comprar los ingredientes para los pasteles y postres 
        o cuando necesito saber cuánto cobrar, es importante saber sumar, restar, 
        multiplicar o dividir</u>. <u>También son indispensables para pesar ingredientes, pues 
        debo ser precisa</u> en las cantidades que uso para que no queden azucaradas o desabridas. 
        Así mismo, <u>uso los números al hornear</u>, porque, dependiendo de la cantidad de mis masas o líquidos,
        será el tiempo de cocción. <u>Si meto un pastel de 2 kilos al horno durante 10 minutos, 
        no se cocerá, pero si lo hago durante 2 horas, se quemará</u>.
      </p>
      <p className={classes.reading} >
      Ahora me doy cuenta de que las matemáticas no son malas. Ésta es mi experiencia con las 
      matemáticas.
      </p>
        </Grid>
      )
      
      case(136):
      return(
        <Grid container item xs={12} alignItems="center" justifyContent="center">
          <p
            className={classes.reading}
          >
          Relaciona las actividades con la manera en que usan las matemáticas.
          </p>
          <br/><br/>
          <table className={classes.table}>
            <thead>
              <th style={{width: "50%",}}><b>Actividades</b></th>
              <th style={{width: "50%",}}><b>Uso de las matemáticas</b></th>
            </thead>
            <tbody>
              <tr>
                <td className={classes.tdTable}>
                  <p className={classes.reading}>
                    1. Publicidad	
                  </p>
                </td>
                <td className={classes.tdTable}>
                  <p className={classes.reading} style={{width: "90%"}}>
                    a. Uso de la geometría para definir la forma espacial de una edificación.
                  </p>
                </td>
              </tr>
              <tr>
                <td className={classes.tdTable}>
                  <p className={classes.reading}>
                    2. Gastronomía 
                  </p>
                </td>
                <td className={classes.tdTable}>
                  <p className={classes.reading} style={{width: "90%"}}>
                    b. Uso de la estadística para optimizar las técnicas de los atletas.
                  </p>
                </td>
              </tr>
              <tr>
                <td className={classes.tdTable}>
                  <p className={classes.reading}>
                    3. Deporte
                  </p>
                </td>
                <td className={classes.tdTable}>
                  <p className={classes.reading} style={{width: "90%"}}>
                    c. Uso de la estadística para descubrir qué público es más probable que compre un producto.
                  </p>
                </td>
              </tr>
              <tr>
                <td className={classes.tdTable}>
                  <p className={classes.reading}>
                    4. Arquitectura
                  </p>
                </td>
                <td className={classes.tdTable}>
                  <p className={classes.reading} style={{width: "90%"}}>
                    d. Uso del cálculo y otras fórmulas para implementar procesos de elaboración de alimentos.
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
        </Grid>
      )
      
      case(138):
      return(
        <Grid container item xs={12} alignItems="center" justifyContent="center">
      <p 
        // dangerouslySetInnerHTML={{__html: `${step+1}. ${pregunta.replace( '\n', '<br/><br/>')}`}}
        className={classes.reading}>
        "Según la lectura, ¿en qué casos se representa de manera correcta la toma de decisiones que 
        se refiere a la Teoría de Juegos?"
      </p>
      <br/>
      <Grid container direction="column" item xs={6} justifyContent="center" alignItems="center" >
        <img alt="img" src={picture61032} className={classes.imgStyle} style={{width: "70%"}} />
        <p className={classes.noMargin}>a.</p>
      </Grid>
      <Grid container direction="column" item xs={6} justifyContent="center" alignItems="center" >
        <img alt="img" src={picture61033} className={classes.imgStyle} style={{width: "70%"}}/>
        <p className={classes.noMargin}>b.</p>
      </Grid>
      <Grid container direction="column" item xs={6} justifyContent="center" alignItems="center" >
        <img alt="img" src={picture61034} className={classes.imgStyle} style={{width: "70%"}}/>
        <p className={classes.noMargin}>c.</p>
      </Grid>
      <Grid container direction="column" item xs={6} justifyContent="center" alignItems="center" >
        <img alt="img" src={picture61035} className={classes.imgStyle} style={{width: "70%"}}/>
        <p className={classes.noMargin}>d.</p>
      </Grid>
        </Grid>
      )
      
      case(149):
      return(
        <Grid container item xs={12} alignItems="center" justifyContent="center">
          <p
            className={classes.reading}
          >
          Relaciona los aspectos que se mencionan en la columna de la 
          izquierda con los contenidos que aparecen en la columna de la derecha:
          </p>
          <br/><br/>
          <table className={classes.table}>
            <thead>
              {/* <th style={{width: "50%",}}><b>Actividades</b></th>
              <th style={{width: "50%",}}><b>Uso de las matemáticas</b></th> */}
            </thead>
            <tbody>
              <tr>
                <td className={classes.tdTable}>
                  <p className={classes.reading}>
                    1. Seguridad alimentaria
                  </p>
                </td>
                <td className={classes.tdTable}>
                  <p className={classes.reading}>
                  a. Su producción no requiere una gran cantidad de recursos y 
                  además es rica en proteína vegetal, contiene un balance adecuado de carbohidratos, grasas, vitaminas y minerales.
                  </p>
                </td>
              </tr>
              <tr>
                <td className={classes.tdTable}>
                  <p className={classes.reading}>
                    2. Arroz, frijol rojo y negro, cacahuate, guisantes, arroz japonés, lentejas, semilla de lino dorado y soya
                  </p>
                </td>
                <td className={classes.tdTable}>
                  <p className={classes.reading}>
                    b. Acceso físico, social y económico a alimentos suficientes, inocuos y nutritivos que te permiten llevar una vida sana.
                  </p>
                </td>
              </tr>
              <tr>
                <td className={classes.tdTable}>
                  <p className={classes.reading}>
                    3. Según el Mapa del Hambre de 2019
                  </p>
                </td>
                <td className={classes.tdTable}>
                  <p className={classes.reading}>
                    c. Su producción ha representado una gran contribución a la alimentación mundial.
                  </p>
                </td>
              </tr>
              <tr>
                <td className={classes.tdTable}>
                  <p className={classes.reading}>
                    4. La quinua
                  </p>
                </td>
                <td className={classes.tdTable}>
                  <p className={classes.reading}>
                    d. 821 millones de personas en el mundo la padecen.
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
        </Grid>
      )
      
      case(182):
      return(
        <Grid container item xs={12} alignItems="center" justifyContent="center">
          <p
            className={classes.reading}
          >
            Relaciona el tipo de narración con su definición. 
          </p>
          <br/>
          <table className={classes.table}>
            <thead>
              <th style={{width: "40%",}}><b>Factores</b></th>
              <th style={{width: "60%",}}><b>Descripción</b></th>
            </thead>
            <tbody>
              <tr>
                <td className={classes.tdTable}>
                  <p className={classes.tableText}>
                    1. Geográficos
                  </p>
                </td>
                <td className={classes.tdTable}>
                  <p className={classes.tableText}>
                    a. Depende de la época y la generación.
                  </p>
                </td>
              </tr>
              <tr>
                <td className={classes.tdTable}>
                  <p className={classes.tableText}>
                    2. Funcionales o contextuales
                  </p>
                </td>
                <td className={classes.tdTable}>
                  <p className={classes.tableText}>
                    b. Ubicación de donde son los hablantes.
                  </p>
                </td>
              </tr>
              <tr>
                <td className={classes.tdTable}>
                  <p className={classes.tableText}>
                    3. Socioculturales
                  </p>
                </td>
                <td className={classes.tdTable}>
                  <p className={classes.tableText}>
                    c. Uso formal o informal del lenguaje.
                  </p>
                </td>
              </tr>
              <tr>
                <td className={classes.tdTable}>
                  <p className={classes.tableText}>
                    4. Históricos
                  </p>
                </td>
                <td className={classes.tdTable}>
                  <p className={classes.tableText}>
                    d. Variación dependiendo del género, la condición social, el nivel académico o profesional, etcétera. 
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
        </Grid>
      )
      
      case(209):
      return(
        <Grid container item xs={12} alignItems="center" justifyContent="center">
          <p
            className={classes.reading}
          >
            Relaciona ambas columnas vinculando la descripción con el concepto que le corresponda.
          </p>
          <br/>
          <table className={classes.table}>
            <thead>
              <th style={{width: "60%", textAlign: "left"}}></th>
              <th style={{width: "40%", textAlign: "left"}}></th>
            </thead>
            <tbody>
              <tr>
                <td className={classes.tdTable}>
                  <p className={classes.reading}> 
                    1. Una propiedad de las moléculas del agua.
                  </p>
                </td>
                <td className={classes.tdTable}>
                  <p className={classes.reading}>
                    a. Capilaridad
                  </p>
                </td>
              </tr>
              <tr>
                <td className={classes.tdTable}>
                  <p className={classes.reading}>
                    2. Composición química del agua.
                  </p>
                </td>
                <td className={classes.tdTable}>
                  <p className={classes.reading}>
                    b. Sólido (hielo)
                  </p>
                </td>
              </tr>
              <tr>
                <td className={classes.tdTable}>
                  <p className={classes.reading}>
                    3. Estado del agua en el espacio.
                  </p>
                </td>
                <td className={classes.tdTable}>
                  <p className={classes.reading}>
                    c. Contaminación
                  </p>
                </td>
              </tr>
              <tr>
                <td className={classes.tdTable}>
                  <p className={classes.reading}>
                    4. Factor que ha contribuido para considerar al agua como un recurso no renovable.
                  </p>
                </td>
                <td className={classes.tdTable}>
                  <p className={classes.reading}>
                    d. H<span style={{fontVariant: "all-small-caps"}}>2</span>O
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
        </Grid>
      )
      
      case(213):
      return(
        <Grid container item xs={12} alignItems="center" justifyContent="center">
          <p
            className={classes.reading}
          >
            Relaciona los nombres de las mujeres de la historia que desafiaron los roles de genero con sus logros. 
          </p>
          <br/>
          <table className={classes.table}>
            <thead>
              <th style={{width: "40%", border: '1px solid black'}}><b>Mujeres de la historia</b></th>
              <th style={{width: "60%", border: '1px solid black'}}><b>Logros</b></th>
            </thead>
            <tbody>
              <tr>
                <td className={classes.tdTable}>
                  <p className={classes.reading}>
                    1. Marie Curie.
                  </p>
                </td>
                <td className={classes.tdTable}>
                  <p className={classes.reading} style={{width: "90%"}}>
                    a. Es la primera mujer que viajó al espacio.
                  </p>
                </td>
              </tr>
              <tr>
                <td className={classes.tdTable}>
                  <p className={classes.reading}>
                    2. Amelia Earhart
                  </p>
                </td>
                <td className={classes.tdTable}>
                  <p className={classes.reading} style={{width: "90%"}}>
                    b. Fue escritora, filósofa y profesora, precursora del feminismo.
                  </p>
                </td>
              </tr>
              <tr>
                <td className={classes.tdTable}>
                  <p className={classes.reading}>
                    3. Simone de Beauvoir
                  </p>
                </td>
                <td className={classes.tdTable}>
                  <p className={classes.reading} style={{width: "90%"}}>
                    c. Fue la primera mujer en recibir el premio Nobel y recibir dos premios Nobel en distintas disciplinas: Física y Química.
                  </p>
                </td>
              </tr>
              <tr>
                <td className={classes.tdTable}>
                  <p className={classes.reading}>
                    4. Valentina Tereshkova
                  </p>
                </td>
                <td className={classes.tdTable}>
                  <p className={classes.reading} style={{width: "90%"}}>
                    d. Es pionera en la aviación y la primera mujer en sobrevolar el Océano Atlántico sola.
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
        </Grid>
      )
      
      case(215):
      return(
        <Grid container item xs={12} alignItems="center" justifyContent="center">
          <p
            className={classes.reading}
          >
            Ordena correctamente el argumento que plantea una postura que desafía los roles de género.
          </p>
          <br/>
          <table className={classes.table}>
            <thead>
              <th style={{width: "40%", textAlign: "left" }}><b>Componentes</b></th>
              <th style={{width: "60%", textAlign: "left"}}><b>Argumento que desafía los roles de género</b></th>
            </thead>
            <tbody>
              <tr>
                <td className={classes.tdTable}>
                  <p>
                    <b>1.  Tema</b>
                  </p>
                </td>
                <td className={classes.tdTable}>
                  <p>
                    a. Por lo tanto, todos, hombres y mujeres, podemos realizar cualquier actividad  que nos guste sin importar nuestro sexo.
                  </p>
                </td>
              </tr>
              <tr>
                <td className={classes.tdTable}>
                  <p>
                    <b>2. Argumentos</b>
                  </p>
                </td>
                <td className={classes.tdTable}>
                  <p>
                    b. Las capacidades de las personas no dependen de su sexo. Todo depende de las acciones
                    y  el esfuerzo que realice para lograr lo  que se quiere.  Un   ejemplo   es Valentina,
                    la primera mujer que viajó al espacio.
                  </p>
                </td>
              </tr>
              <tr>
                <td className={classes.tdTable}>
                  <p>
                    <b> 3. Conclusión </b>
                  </p>
                </td>
                <td className={classes.tdTable}>
                  <p>
                    c. Las mujeres pueden desempeñar profesiones y actividades de hombres.
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
        </Grid>
      )
      
      case(229):
      return(
        <Grid container item xs={12} alignItems="center" justifyContent="center">
          <p
            className={classes.reading}
          >
            Relaciona los instrumentos con las partes que los conforman.
          </p>
          <br/>
          <table className={classes.table}>
            <tbody>
              <tr>
                <td className={classes.tdTable}>
                  <p>
                    1. Batería
                  </p>
                </td>
                <td className={classes.tdTable}>
                  <p>
                    a. Cuerdas, mástil, trastes, puente
                  </p>
                </td>
              </tr>
              <tr>
                <td className={classes.tdTable}>
                  <p>
                    2. Violín
                  </p>
                </td>
                <td className={classes.tdTable}>
                  <p>
                    b. Bombo, tarola, platillos, baquetas
                  </p>
                </td>
              </tr>
              <tr>
                <td className={classes.tdTable}>
                  <p>
                    3. Flauta
                  </p>
                </td>
                <td className={classes.tdTable}>
                  <p>
                    c. Puente, diapasón, cuerdas, clavijas
                  </p>
                </td>
              </tr>
              <tr>
                <td className={classes.tdTable}>
                  <p>
                    4. Guitarra
                  </p>
                </td>
                <td className={classes.tdTable}>
                  <p>
                    d. Boquilla, cuerpo, base, cabeza
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
        </Grid>
      )
      
      case(266):
      return(
        <Grid container item xs={12} alignItems="center" justifyContent="center">
          <p 
            // dangerouslySetInnerHTML={{__html: `${step+1}. ${pregunta.replace( '\n', '<br/><br/>')}`}}
            className={classes.reading}
          >
            Comprueba qué tan creativo eres, aun sin estar aburrido. 
            Elige la manualidad que podrías crear con los materiales que a continuación se presentan.
          </p>
          <br/>
          <table>
            <tbody>
              <tr>
              {
                picturesTable1.map((picture) => {
                  return(
                    <td>
                      <img alt="img" src={picture} className={classes.imgStyle}/>
                    </td>
                  )
                })
              }
              </tr>
            </tbody>
          </table>
        </Grid>
      )
      
      case(282):
      return(
        <Grid container item xs={12} alignItems="center" justifyContent="center">
      <p 
        // dangerouslySetInnerHTML={{__html: `${step+1}. ${pregunta.replace( '\n', '<br/><br/>')}`}}
        className={classes.reading}
      >
        Analiza el cartel que se presenta a continuación. Luego elige sobre qué tipo de
        mensaje hace énfasis.
      </p>
      <br/>
      <img alt="img" src={picture62430} className={classes.imgStyle}/>
        </Grid>
      )
      
      case(321):
      return(
        <Grid container item xs={12} alignItems="center" justifyContent="center">
          <p
            className={classes.reading}
          >
            1. Relaciona cada descripción con la teoría ética a la que corresponde.
          </p>
          <br/>
          <table className={classes.table}>
            <thead>
              <th style={{width: "40%", textAlign: 'center'}}><b>Teoría</b></th>
              <th style={{width: "60%", textAlign: 'center'}}><b>Descripción</b></th>
            </thead>
            <tbody>
              <tr>
                <td className={classes.tdTable}>
                  <p > 
                    1. Emotivismo
                  </p>
                </td>
                <td className={classes.tdTable}>
                  <p >
                    a. La convivencia se basa en el cumplimiento de reglas que aplican para todos
                  </p>
                </td>
              </tr>
              <tr>
                <td className={classes.tdTable}>
                  <p >
                    2. Utilitarismo
                  </p>
                </td>
                <td className={classes.tdTable}>
                  <p >
                    b. Una guía para saber cómo actuar es poner atención a qué sentimientos te genera ese actuar.
                  </p>
                </td>
              </tr>
              <tr>
                <td className={classes.tdTable}>
                  <p >
                    3. Racionalismo
                  </p>
                </td>
                <td className={classes.tdTable}>
                  <p >
                    c. La mejor decisión será la que aporte mayor beneficio al menor costo posible.
                  </p>
                </td>
              </tr>
              <tr>
                <td className={classes.tdTable}>
                  <p >
                    4. Ética del cuidado
                  </p>
                </td>
                <td className={classes.tdTable}>
                  <p >
                    d. La  mejor  acción  es  la  que  ayuda  a  preservar  las relaciones más valiosas entre las personas.
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
        </Grid>
      )
      
      case(364):
      return(
        <Grid container item xs={12} alignItems="center" justifyContent="center">
          <p
            className={classes.reading}
          >
            Relaciona cada tipo de comercio con su inconveniente.
          </p>
          <br/>
          <table className={classes.table}>
            <thead>
              <th style={{width: "40%", textAlign: 'center'}}><i>Método</i></th>
              <th style={{width: "60%", textAlign: 'center'}}><i>Inconveniente</i></th>
            </thead>
            <tbody>
              <tr>
                <td className={classes.tdTable}>
                  <p > 
                    1. Semillas
                  </p>
                </td>
                <td className={classes.tdTable}>
                  <p >
                    a. Ocupan mucho espacio y pesan.
                  </p>
                </td>
              </tr>
              <tr>
                <td className={classes.tdTable}>
                  <p >
                    2. Billetes
                  </p>
                </td>
                <td className={classes.tdTable}>
                  <p >
                    b. Son falsificables.
                  </p>
                </td>
              </tr>
              <tr>
                <td className={classes.tdTable}>
                  <p >
                    3. Monedas
                  </p>
                </td>
                <td className={classes.tdTable}>
                  <p >
                    c. Se echan a perder. 
                  </p>
                </td>
              </tr>
              <tr>
                <td className={classes.tdTable}>
                  <p >
                    4. Criptomonedas
                  </p>
                </td>
                <td className={classes.tdTable}>
                  <p >
                    d. Generan problemas ambientales.
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
        </Grid>
      )
      
      case(391):
      return(
        <Grid container item xs={12} alignItems="center" justifyContent="center">
          <p
            className={classes.reading}
          >
            Relaciona los conceptos con sus descripciones.
          </p>
          <br/>
          <table className={classes.table}>
            <thead>
              <th style={{width: "40%",}}><b>Conceptos</b></th>
              <th style={{width: "60%",}}><b>Descripción</b></th>
            </thead>
            <tbody>
              <tr>
                <td className={classes.tdTable}>
                  <p>
                    1. Desextinción
                  </p><br/>
                </td>
                <td className={classes.tdTable}>
                  <p>
                    a. Características biológicas que los individuos de una especie transmiten a su descendencia a través de la herencia.
                  </p>
                </td>
              </tr>
              <tr>
                <td className={classes.tdTable}>
                  <p>
                    2. Clonación
                  </p><br/>
                </td>
                <td className={classes.tdTable}>
                  <p>
                    b. Técnicas que permiten revivir una especie extinta.
                  </p>
                </td>
              </tr>
              <tr>
                <td className={classes.tdTable}>
                  <p>
                    3. Información genética
                  </p><br/>
                </td>
                <td className={classes.tdTable}>
                  <p>
                    c. Proceso utilizado para crear una copia genética exacta de una secuencia de <span style={{fontVariant: "all-small-caps"}}>ADN</span>, célula u organismo.
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
        </Grid>
      )
      
      case(396):
      return(
        <Grid container item xs={12} alignItems="center" justifyContent="center">
          <p 
            className={classes.reading}
          >
            Observa los animales y elige los que están extintos.
          </p>
          <br/>
          <table>
            <thead>
              <tr>
                <th>a</th>
                <th>b</th>
                <th>c</th>
                <th>d</th>
                <th>e</th>
                <th>f</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                {
                  picturesTable2.map((picture) => {
                    return(
                      <td>
                        <img alt="img" src={picture} className={classes.imgStyle} style={{width: "80%"}} />
                      </td>
                    )
                  })
                }
              </tr>
            </tbody>
          </table>
        </Grid>
      )
      
      case(418):
      return(
        <Grid container item xs={12} alignItems="center" justifyContent="center">
          <p 
            // dangerouslySetInnerHTML={{__html: `${step+1}. ${pregunta.replace( '\n', '<br/><br/>')}`}}
            className={classes.reading}
          >
            ¿Qué otras estrategias elegirías para disminuir los desechos y consecuencias ocasionadas por la moda rápida?
          </p>
          <br/>
          <table >
            <tbody>
            <tr>
                <td>
                  <p  >
                    a. quemarla
                  </p>
                </td>
                <td >
                  <p  >
                    b. hacer juguetes
                  </p>
                </td>
                <td >
                  <p  >
                    c. personalizarla
                  </p>
                </td>
                <td >
                  <p  >
                    d. tirarla
                  </p>
                </td>
              </tr>
              <tr>
                {
                  picturesTableQ.map((picture) => {
                    return(
                      <td style={{alignContent: "center"}}>
                        <img alt="img" src={picture} className={classes.imgStyle} style={{width: "50%"}} />
                      </td>
                    )
                  })
                }
              </tr>
            </tbody>
          </table>
        </Grid>
      )
      
      case(443):
      return(
        <Grid container item xs={12} alignItems="center" justifyContent="center">
          <p
            className={classes.reading}
          >
            Lee las oraciones y analiza los verbos subrayados para que los relaciones con el tiempo verbal en el que están conjugados.  
          </p>
          <br/>
          <table className={classes.table}>
            <thead>
              <th style={{width: "65%", border: '1px solid black'}}><b>Verbo</b></th>
              <th style={{width: "35%", border: '1px solid black'}}><b>Tiempo</b></th>
            </thead>
            <tbody>
              <tr>
                <td className={classes.tdTable}>
                  <p>
                    1. Hay efectos de las fotos que no <u>serían</u> posibles sin la tecnología digital.
                  </p>
                </td>
                <td className={classes.tdTable}>
                  <p>
                    a. Antepresente
                  </p>
                </td>
              </tr>
              <tr>
                <td className={classes.tdTable}>
                  <p>
                    2. Muchas técnicas se <u>han desarrollado</u> gracias a fotógrafos expertos. 
                  </p>
                </td>
                <td className={classes.tdTable}>
                  <p>
                    b. Antepospretérito
                  </p>
                </td>
              </tr>
              <tr>
                <td className={classes.tdTable}>
                  <p>
                    3. La primera foto no <u>habría existido</u> sin la paciencia de Niépce
                  </p>
                </td>
                <td className={classes.tdTable}>
                  <p>
                    c. Pospretérito
                  </p>
                </td>
              </tr>
              <tr>
                <td className={classes.tdTable}>
                  <p>
                    4. En aquel tiempo los fotógrafos <u>recubrían</u> las fotos con un aceite para revelado.   
                  </p>
                </td>
                <td className={classes.tdTable}>
                  <p>
                    d. Copretérito
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
        </Grid>
      )
      
      case(449):
      return(
        <Grid container item xs={12} alignItems="center" justifyContent="center">
          <p
            className={classes.reading}
          >
            Relaciona los conceptos con sus descripciones.
          </p>
          <br/>
          <table className={classes.table}>
            <tbody>
              <tr>
                <td className={classes.tdTable}>
                  <p>
                    1. Habilidad artística
                  </p>
                </td>
                <td className={classes.tdTable}>
                  <p>
                    a. Control adecuado de la iluminación, el tiempo de exposición y herramientas de revelado o edición digital.
                  </p>
                </td>
              </tr>
              <tr>
                <td className={classes.tdTable}>
                  <p>
                    2. Habilidad técnica 
                  </p>
                </td>
                <td className={classes.tdTable}>
                  <p>
                    b. Capacidad de conectar con los intereses de las personas para transmitir todo tipo de ideas.
                  </p>
                </td>
              </tr>
              <tr>
                <td className={classes.tdTable}>
                  <p>
                    3. Habilidad comunicativa
                  </p>
                </td>
                <td className={classes.tdTable}>
                  <p>
                    c. Apreciación de los elementos de la escena (luz, composición, texturas, etcétera) para transmitir emociones, historias 
                    o situaciones.
                  </p>
                </td>
              </tr>
              <tr>
                <td className={classes.tdTable}>
                  <p>
                    4. Equipo
                  </p>
                </td>
                <td className={classes.tdTable}>
                  <p>
                    d. Cámaras, lentes, <i>flashes</i>, rebotadores, etcétera
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
        </Grid>
      )
      
      case(491):
      return(
        <Grid container item xs={12} alignItems="center" justifyContent="center">
          <p
            className={classes.reading}
          >
            Relaciona a los científicos famosos con sus descubrimientos más sobresalientes.  
          </p>
          <br/>
          <table className={classes.table}>
            <thead>
              <th style={{width: "50%", textAlign: "center"}}>Científicos famosos</th>
              <th style={{width: "50%", textAlign: "center"}}>Descubrimientos científicos</th>
            </thead>
            <tbody>
              <tr>
                <td className={classes.tdTable}>
                  <p>
                    1. Galileo Galilei
                  </p>
                </td>
                <td className={classes.tdTable}>
                  <p>
                    a. Descubrió los elementos radiactivos, investigó el radio y sus compuestos.
                  </p>
                </td>
              </tr>
              <tr>
                <td className={classes.tdTable}>
                  <p>
                    2. Albert Einstein
                  </p>
                </td>
                <td className={classes.tdTable}>
                  <p>
                    b. Creó las Leyes de Newton.
                  </p>
                </td>
              </tr>
              <tr>
                <td className={classes.tdTable}>
                  <p>
                    3. Marie Curie
                  </p>
                </td>
                <td className={classes.tdTable}>
                  <p>
                    c. Planteó la teoría de la relatividad espacial.
                  </p>
                </td>
              </tr>
              <tr>
                <td className={classes.tdTable}>
                  <p>
                    4. Isaac Newton
                  </p>
                </td>
                <td className={classes.tdTable}>
                  <p>
                    d. Mejoró el telescopio y descubrió la dinámica y la cinemática.
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
        </Grid>
      )
      
      case(514):
      return(
        <Grid container item xs={12} alignItems="center" justifyContent="center">
          <p
            className={classes.reading}
          >
            Une a la artista con su profesión artística y su país de origen.
          </p>
          <br/>
          <table className={classes.table}>
            <thead>
              <th style={{width: "40%", textAlign: 'left'}}><i>Artista</i></th>
              <th style={{width: "60%", textAlign: 'left'}}><i>Profesión artística/país de origen</i></th>
            </thead>
            <tbody>
              <tr>
                <td className={classes.tdTable}>
                  <p> 
                    1. Raquel Forner
                  </p>
                </td>
                <td className={classes.tdTable}>
                  <p>
                    a. pintora (México)
                  </p>
                </td>
              </tr>
              <tr>
                <td className={classes.tdTable}>
                  <p>
                    2. María Izquierdo
                  </p>
                </td>
                <td className={classes.tdTable}>
                  <p>
                    b. pintora (Brasil)
                  </p>
                </td>
              </tr>
              <tr>
                <td className={classes.tdTable}>
                  <p>
                    3. Débora Arango
                  </p>
                </td>
                <td className={classes.tdTable}>
                  <p>
                    c. pintora y escultora (Argentina) 
                  </p>
                </td>
              </tr>
              <tr>
                <td className={classes.tdTable}>
                  <p>
                    4. Tarsila do Amaral
                  </p>
                </td>
                <td className={classes.tdTable}>
                  <p>
                    d. escultora (Chile)
                  </p>
                </td>
              </tr>
              <tr>
                <td className={classes.tdTable}>
                  <p>
                    5. Lily Garafulic
                  </p>
                </td>
                <td className={classes.tdTable}>
                  <p>
                    e. pintora (Colombia)
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
        </Grid>
      )
      
      case(519):
      return(
        <Grid container item xs={12} alignItems="center" justifyContent="center">
          <p
            className={classes.reading}
          >
            Escoge las respuestas correctas.
          </p>
          <p
            className={classes.reading}
          >
            Relaciona a la artista con los datos de su vida, según corresponda a cada caso:
          </p>
          <br/>
          <table className={classes.table}>
            <thead>
              <th style={{width: "40%", textAlign: 'left'}}><i></i></th>
              <th style={{width: "60%", textAlign: 'left'}}><i></i></th>
            </thead>
            <tbody>
              <tr>
                <td className={classes.tdTable}>
                  <p> 
                    1. María Izquierdo
                  </p>
                </td>
                <td className={classes.tdTable}>
                  <p>
                    a. Combinó la elementos europeos con temas tradicionales brasileños.
                  </p>
                </td>
              </tr>
              <tr>
                <td className={classes.tdTable}>
                  <p>
                    2. Tarsila do Amaral
                  </p>
                </td>
                <td className={classes.tdTable}>
                  <p>
                    b. Fue exponente de la escultura abstracta en Sudamérica.
                  </p>
                </td>
              </tr>
              <tr>
                <td className={classes.tdTable}>
                  <p>
                    3. Raquel Forner
                  </p>
                </td>
                <td className={classes.tdTable}>
                  <p>
                    c. Su  obra  de  juventud  muestra  una  perspectiva crítica hacia la sociedad colombiana.
                  </p>
                </td>
              </tr>
              <tr>
                <td className={classes.tdTable}>
                  <p>
                    4. Lily Garafulic
                  </p>
                </td>
                <td className={classes.tdTable}>
                  <p>
                    d. Es la primer mexicana en exhibir su obra fuera de México.
                  </p>
                </td>
              </tr>
              <tr>
                <td className={classes.tdTable}>
                  <p>
                    5. Débora Arango
                  </p>
                </td>
                <td className={classes.tdTable}>
                  <p>
                    e. Fue  galardonada  con  medalla  de  oro  en la Exposición Internacional de París
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
        </Grid>
      )
      
      case(603):
      return(
        <Grid container item xs={12} alignItems="center" justifyContent="center">
      <p 
        // dangerouslySetInnerHTML={{__html: `${step+1}. ${pregunta.replace( '\n', '<br/><br/>')}`}}
        className={classes.reading}
      >
        Observa las frutas.
      </p>
      <br/>
      <img alt="img" src={picture65531} className={classes.imgStyle} style={{width: "50%"}}/>
      <p className={classes.reading} >
        En esta actividad, jugarás a ser una red social que interpreta patrones. 
        Para ello, deberás predecir cuáles son las dos frutas que siguen en la secuencia anterior.
      </p>
        </Grid>
      )
      
      case(624):
      return(
        <Grid container item xs={12} alignItems="center" justifyContent="center">
          <p
            className={classes.reading}
          >
            Relaciona el tipo de narración con su definición. 
          </p>
          <br/>
          <table className={classes.table}>
            <thead>
              <th style={{width: "30%",}}><i>Tipo de narración</i></th>
              <th style={{width: "70%",}}><i>Definición</i></th>
            </thead>
            <tbody>
              <tr>
                <td className={classes.tdTable}>
                  <p>
                    1. Leyenda
                  </p>
                </td>
                <td className={classes.tdTable}>
                  <p>
                    a. Historia real o fantástica, hecha para entretener. Se define por sus personajes y ambientes. 
                    Puede encontrarse en libros, películas, series o novelas.
                  </p>
                </td>
              </tr>
              <tr>
                <td className={classes.tdTable}>
                  <p>
                    2. Didáctico
                  </p>
                </td>
                <td className={classes.tdTable}>
                  <p>
                    b. Narración de corte fantástico y sobrenatural, protagonizado por dioses, semidioses y héroes. 
                    No existe momento temporal en la historia. Ayuda a explicar los símbolos y las fuerzas de la naturaleza.
                  </p>
                </td>
              </tr>
              <tr>
                <td className={classes.tdTable}>
                  <p>
                    3. Mito
                  </p>
                </td>
                <td className={classes.tdTable}>
                  <p>
                    c. Está basada en un hecho real que se volvió fantasía. Explica o enaltece hechos inexplicables o admirables.
                    Es creada en comunidades específicas y transmitidas por la tradición oral.
                  </p>
                </td>
              </tr>
              <tr>
                <td className={classes.tdTable}>
                  <p>
                    4. Cuento
                  </p>
                </td>
                <td className={classes.tdTable}>
                  <p>
                    d. Texto protagonizado por animales o cosas que representan virtudes o valores. 
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
        </Grid>
      )
      
      case(625):
      return(
        <Grid container item xs={12} alignItems="center" justifyContent="center">
      <p
        className={classes.reading}
      >
        Relaciona las partes de la narración con su descripción. 
      </p>
      <br/>
      <table className={classes.table}>
        <thead>
          <th style={{width: "40%",}}><i>Partes de la narración</i></th>
          <th style={{width: "60%",}}><i>Descripción</i></th>
        </thead>
        <tbody>
          <tr>
            <td className={classes.tdTable}>
              <p>
                1. Desenlace
              </p>
            </td>
            <td className={classes.tdTable}>
              <p>
                a. Es la parte en la que se presenta el conflicto o el problema de la historia.
              </p>
            </td>
          </tr>
          <tr>
            <td className={classes.tdTable}>
              <p>
                2. Inicio
              </p>
            </td>
            <td className={classes.tdTable}>
              <p>
                b. Es la sección en la que los personajes resuelven el problema y los hechos concluyen.
              </p>
            </td>
          </tr>
          <tr>
            <td className={classes.tdTable}>
              <p>
                3. Nudo
              </p>
            </td>
            <td className={classes.tdTable}>
              <p>
                c. Aquí se dan a conocer los personajes, el lugar y el tiempo en el que se desarrollan los hechos.
              </p>
            </td>
          </tr>
        </tbody>
      </table>
        </Grid>
      )
      
      case(627):
      return(
        <Grid container item xs={12} alignItems="center" justifyContent="center">
          <p
            className={classes.reading}
          >
            Lee las escenas y relaciónalas con los tipos de narración que les corresponden.
          </p>
          <br/>
          <table className={classes.table}>
            <thead>
              <th style={{width: "60%", border: '1px solid black'}}><i>Descripción de escena</i></th>
              <th style={{width: "40%", border: '1px solid black'}}><i>Tipo de narración</i></th>
            </thead>
            <tbody>
              <tr>
                <td className={classes.tdTable}>
                  <p>
                    1. De las nubes, se asomó el Dios del trueno y castigó a todos aquellos que osaron ofenderlo con sus acciones.
                  </p>
                </td>
                <td className={classes.tdTable}>
                  <p>
                    a. didáctica
                  </p>
                </td>
              </tr>
              <tr>
                <td className={classes.tdTable}>
                  <p>
                  2. <br/>La tortuga caminó sin detenerse. Siempre esforzándose por ser constante, aunque se sintiera cansada.
                    La liebre, al abrir los ojos después de su descanso, se percató de que había perdido la carrera.
                  </p>
                </td>
                <td className={classes.tdTable}>
                  <p>
                    b. leyenda
                  </p>
                </td>
              </tr>
              <tr>
                <td className={classes.tdTable}>
                  <p>
                    3. Los dos hermanos, que se encontraban perdidos en el bosque, no podían creer que habían encontrado una 
                    deliciosa casa hecha de los dulces que más les gustaban. Pensaron que si entraban seguro podrían pasar 
                    la noche y alimentarse, antes de intentar regresar a casa.
                  </p>
                </td>
                <td className={classes.tdTable}>
                  <p>
                    c. cuento
                  </p>
                </td>
              </tr>
              <tr>
                <td className={classes.tdTable}>
                  <p>
                    4. Los habitantes del pueblo afirman que todas las noches camina tenebrosamente aquella mujer que murió
                    hace muchos años, esperanzada en volver a abrazar a sus hijos.
                  </p>
                </td>
                <td className={classes.tdTable}>
                  <p>
                    d. mito
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
        </Grid>
      )
      
      case(1961):
      return(
        <Grid container item xs={12} alignItems="center" justifyContent="center">
          <p           
            // dangerouslySetInnerHTML={{__html: `${questionInfo}`}}
            className={classes.questionText}
          >
        El gato encuentra a muchos personajes que le muestran que no es tan único como creía.
        ¿En qué orden hablan con el gato? Selecciona la secuencia que tenga el orden correcto. 
        Toma en cuenta que faltan algunos personajes.
        </p>
        <br/>
        <img alt="img" src={picture20661} className={classes.imgStyle} style={{width: "50%"}}/>
        </Grid>
      )
      
      case(2041):
      return(
        <Grid container item xs={12} alignItems="center" justifyContent="center">
          <p           
            // dangerouslySetInnerHTML={{__html: `${questionInfo}`}}
            className={classes.questionText}
          >
         En el texto se menciona la <em>Fórmula de las 3R</em>. Observa con atención la 
        siguiente ilustración para que respondas: ¿a qué procedimiento corresponde lo que está haciendo la niña?
      </p>
      <br/>
      <Grid container justifyContent="center" alignItems="center">
        <img alt="img" src={picture21450} className={classes.imgStyle}/>
      </Grid>
        </Grid>
      )
      
      case(2274):
      return(
        <Grid container item xs={12} alignItems="center" justifyContent="center">
          <p           
            // dangerouslySetInnerHTML={{__html: `${questionInfo}`}}
            className={classes.questionText}
          >
        Observa la siguiente imagen:
      </p>
      <br/>
      <img alt="img" src={picture23680} className={classes.imgStyle}/>

      <p className={classes.reading}>
      ¿Cómo reaccionaría ante esa situación un niño que sabe autorregularse?
      </p>
     
        </Grid>
      )
      
      case(2341):
      return(
        <Grid container item xs={12} alignItems="center" justifyContent="center">
          <p           
            // dangerouslySetInnerHTML={{__html: `${questionInfo}`}}
            className={classes.questionText}
          >
        El texto explica que la <i>aracnofobia</i> es el miedo irracional a las arañas. La palabra se compone 
        de dos partes: <i>aracno</i>, que significa “araña”, y <i>fobia</i>, “temor”. ¿Puedes identificar el resto de estas fobias?
      </p>
      <Grid container direction="column" item xs={6} justifyContent="center" alignItems="center" >
        <img alt="img" src={picture24251} className={classes.imgStyle}/>
        <p>1.</p>
      </Grid>
      <Grid container direction="column" item xs={6} justifyContent="center" alignItems="center" >
        <img alt="img" src={picture24252} className={classes.imgStyle}/>
        <p>2.</p>
      </Grid>
      <Grid container direction="column" item xs={6} justifyContent="center" alignItems="center" >
        <img alt="img" src={picture24253} className={classes.imgStyle}/>
        <p>3.</p>
      </Grid>
      <Grid container direction="column" item xs={6} justifyContent="center" alignItems="center" >
        <img alt="img" src={picture24254} className={classes.imgStyle}/>
        <p>4.</p>
      </Grid>
        <p>
        a. Vacunafobia<br/>
        b. Electrofobia<br/>
        c. Dentofobia<br/>
        d. Aerofobia<br/>
        </p>
        </Grid>
      )
      
      case(2474):
      return(
        <Grid container item xs={12} alignItems="center" justifyContent="center">
          <p           
            // dangerouslySetInnerHTML={{__html: `${questionInfo}`}}
            className={classes.questionText}
          >
          ¿Qué multiplicación está representada en el siguiente trazo?
            </p>

        <img alt="img" src={picture25580} className={classes.imgStyle}/>
        </Grid>
      )
      
      case(1993):
      return(
        <Grid container item xs={12} alignItems="center" justifyContent="center">
          <p           
            // dangerouslySetInnerHTML={{__html: `${questionInfo}`}}
            className={classes.questionText}
          >
            Muchas veces la gente nos valora por nuestras actitudes. ¿Cuál de ellas crees que valorarían 
            las personas de tu comunidad? Elige la imagen que la representa.
          </p>
          <br/>
          <Grid container justifyContent="center" alignItems="center">
            <img alt="img" src={picture20970} className={classes.imgStyle}/>
          </Grid>
        </Grid>
      )
      
      case(2707):
      return(
        <Grid container item xs={12} alignItems="center" justifyContent="center">
          <p           
            // dangerouslySetInnerHTML={{__html: `${questionInfo}`}}
            className={classes.questionText}
          >
             Relaciona los sujetos con su respectivo predicado.
            </p>
            <br/>
            <Grid container className={classes.ovalContainer}>
              <Grid container item xs={3} className={classes.ovalText} justifyContent="center" alignItems="center">
                <p
        
                >
                  <b>Tatiana</b>
                </p>
              </Grid>
              <Grid container item xs={3} className={classes.ovalText} justifyContent="center" alignItems="center">
                <p
          
                >
                  <b>La naturaleza</b>
                </p>
              </Grid>
              <Grid container item xs={3} className={classes.ovalText} justifyContent="center" alignItems="center">
                <p
              
                >
                  <b>Su abuela</b>
                </p>
              </Grid>
              <Grid container item xs={3} className={classes.ovalText} justifyContent="center" alignItems="center">
                <p
              
                >
                  <b>Nadie</b>
                </p>
              </Grid>
            </Grid>
            <table className={classes.table}>
              <thead>
                <th style={{ border: '1px solid black',}}><b>Predicado</b></th>
                {/* <th style={{width: "50%", border: '1px solid black',}}><i>Uso</i></th> */}
              </thead>
              <tbody>
                <tr>
                  <td className={classes.tdTable}>
                    <p>
                    A. ocupa el lugar de nadie.
                    </p>
                  </td>
                </tr>
                <tr>
                  <td className={classes.tdTable}>
                    <p>
                    B. por fin le contestó.
                    </p>
                  </td>
                </tr>
                <tr>
                  <td className={classes.tdTable}>
                    <p>
                    C. no comprendía lo que le pasaba.
                    </p>
                  </td>
                </tr>
                <tr>
                  <td className={classes.tdTable}>
                    <p>
                    D. hace a los bebés encantadores.
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
        </Grid>
      )
      
      case(2957):
      return(
        <Grid container item xs={12} alignItems="center" justifyContent="center">
          <p           
            // dangerouslySetInnerHTML={{__html: `${questionInfo}`}}
            className={classes.questionText}
          >
            Relaciona el color con la sal que necesita.
          </p>
          <br/>
          <Grid container direction="row" justifyContent="space-around" alignItems="center">
            <Grid container item xs={3} justifyContent="center" alignItems="center" className={classes.ovalColorRed}>
              Rojo
            </Grid>
            <Grid container item xs={3} justifyContent="center" alignItems="center" className={classes.ovalColorBlue}>
              Azul
            </Grid>
            <Grid container item xs={3} justifyContent="center" alignItems="center" className={classes.ovalColorYellow}>
              Amarillo
            </Grid>
          </Grid>
          <p
            className={classes.reading}
          >
            a. sodio<br/>
            b. cobre<br/>
            c. estroncio<br/>
          </p>
        </Grid>
      )
      
      case(87):
      return(
        <Grid container item xs={12} alignItems="center" justifyContent="center">
          <p           
            // dangerouslySetInnerHTML={{__html: `${questionInfo}`}}
            className={classes.questionText}
          >
            Observa las imágenes e identifica las tres artesanías mexicanas.
          </p>
          <br/>
          <table>
            <tr>
              <th>a</th>
              <th>b</th>
              <th>c</th>
              <th>d</th>
              <th>e</th>
              <th>f</th>
            </tr>
            <tr>
            {
              picturesTable605.map((picture) => {
                return(
                  <td>
                    <img alt="img" src={picture} className={classes.imgStyle} style={{width: "50%"}} />
                  </td>
                )
              })
            }
            </tr>
          </table>
        </Grid>
      )
      
      case(1059):
      return(
        <Grid container item xs={12} alignItems="center" justifyContent="center">
          <p           
            // dangerouslySetInnerHTML={{__html: `${questionInfo}`}}
            className={classes.questionText}
          >
            Observa las dos imágenes y encuentra las diferencias.
          </p>
          <br/>
          <img alt="img" src={picture53891} className={classes.imgStyle} style={{width: "27%"}} />
          <img alt="img" src={picture53892} className={classes.imgStyle2} style={{width: "40%"}} />

            <p className={classes.reading}>a. El tipo de escenario</p>
            <p className={classes.reading}>b. La forma de las nubes</p>
            <p className={classes.reading}>c. La cantidad de personajes</p>
            <p className={classes.reading}>d. El color de la ropa</p>
            </Grid>
          )
          
          case(3409):
          return(
            <Grid container item xs={12} alignItems="center" justifyContent="center">
              <p           
                // dangerouslySetInnerHTML={{__html: `${questionInfo}`}}
                className={classes.questionText}
              >
          Elige  las  imágenes  que ejemplifican la  siguiente afirmación: “esta  
          espiral aparece naturalmente.”
          </p>
          <br/>
          <table className={classes.table}>
            <thead>
              {/* <th style={{ border: '1px solid black',}}><i>Idea secundaria</i></th> */}
              {/* <th style={{width: "50%", border: '1px solid black',}}><i>Uso</i></th> */}
            </thead>
            <tbody>
              <tr>
                <td className={classes.tdTable}>
                  <p>
                    A 
                  </p>
                </td>
                <td className={classes.tdTable}>
                  <p>
                    B 
                  </p>
                </td>
                <td className={classes.tdTable}>
                  <p>
                    C 
                  </p>
                </td>
              </tr>
              <tr>
                <td className={classes.tdTable}>
                  <img src={picture42645} alt="" className={classes.imgStyle}/>
                </td>
                <td className={classes.tdTable}>
                  <img src={picture42646} alt="" className={classes.imgStyle}/>
                </td>
                <td className={classes.tdTable}>
                  <img src={picture42647} alt="" className={classes.imgStyle}/>
                </td>
              </tr>
              <tr>
                <td className={classes.tdTable}>
                  <p>
                    D 
                  </p>
                </td>
                <td className={classes.tdTable}>
                  <p>
                    E 
                  </p>
                </td>
                <td className={classes.tdTable}>
                  <p>
                    F 
                  </p>
                </td>
              </tr>
              <tr>
                <td className={classes.tdTable}>
                  <img src={picture42648} alt="" className={classes.imgStyle}/>
                </td>
                <td className={classes.tdTable}>
                  <img src={picture42649} alt="" className={classes.imgStyle}/>
                </td>
                <td className={classes.tdTable}>
                  <img src={picture426410} alt="" className={classes.imgStyle}/>
                </td>
              </tr>
            </tbody>
          </table>
          </Grid>
          )
          
          case(3120):
          return(
            <Grid container item xs={12} alignItems="center" justifyContent="center">
              <p           
                // dangerouslySetInnerHTML={{__html: `${questionInfo}`}}
                className={classes.questionText}
              >
                Relaciona los componentes de una ofrenda con su propósito.
              </p>
              <br/>
              <table className={classes.table}>
                <thead>
                  <th style={{width: "50%",    border: '1px solid black',}}><b>Componentes de una ofrenda</b></th>
                  <th style={{width: "50%",    border: '1px solid black',}}><b>Propósito</b></th>
                </thead>
                <tbody>
                  <tr>
                    <td className={classes.tdTable}>
                      <p>
                      agua
                      </p>
                    </td>
                    <td className={classes.tdTable}>
                      <p>
                      a. limpiar el ambiente
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td className={classes.tdTable}>
                      <p>
                      sal
                      </p>
                    </td>
                    <td className={classes.tdTable}>
                      <p>
                      b. guiar a los espíritus
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td className={classes.tdTable}>
                      <p>
                      velas
                      </p>
                    </td>
                    <td className={classes.tdTable}>
                      <p>
                      c. calmar la sed
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td className={classes.tdTable}>
                      <p>
                      incienso
                      </p>
                    </td>
                    <td className={classes.tdTable}>
                      <p>
                      d. purificar a los espíritus
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
              </Grid>
              )
              
          case(3094):
          return(
            <Grid container item xs={12} alignItems="center" justifyContent="center">
              <p           
                // dangerouslySetInnerHTML={{__html: `${questionInfo}`}}
                className={classes.questionText}
              >
                Relaciona cada arte con su respectivo material, tal como lo indica la lectura.
              </p>
              <br/>
              <table className={classes.table}>
                <thead>
                  <th style={{width: "50%",    border: '1px solid black',}}><b>Arte</b></th>
                  <th style={{width: "50%",    border: '1px solid black',}}><b>Material</b></th>
                </thead>
                <tbody>
                  <tr>
                    <td className={classes.tdTable}>
                      <p>
                      Cocina
                      </p>
                    </td>
                    <td className={classes.tdTable}>
                      <p>
                      a. ladrillos
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td className={classes.tdTable}>
                      <p>
                      Arquitectura
                      </p>
                    </td>
                    <td className={classes.tdTable}>
                      <p>
                      b. ingredientes
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td className={classes.tdTable}>
                      <p>
                      Poesía
                      </p>
                    </td>
                    <td className={classes.tdTable}>
                      <p>
                      c. metal
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td className={classes.tdTable}>
                      <p>
                      Escultura
                      </p>
                    </td>
                    <td className={classes.tdTable}>
                      <p>
                      d. palabras
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
              </Grid>
            )
            
            case(3541):
            return(
              <Grid container item xs={12} alignItems="center" justifyContent="center">
                <p           
                  // dangerouslySetInnerHTML={{__html: `${questionInfo}`}}
                  className={classes.questionText}
                >
                  Relaciona las sedes de los juegos panamericanos con el año en quese llevaron a cabo.
                </p>
                <br/>
                <table className={classes.table}>
                  <thead>
                    <th style={{ width: "70%", border: '1px solid black',}}>Sedes</th>
                    <th style={{width: "30%", border: '1px solid black',}}>Año</th>
                  </thead>
                  <tbody>
                    <tr>
                      <td className={classes.tdTable}>
                        <p className={classes.marginCero}>
                          1. Santiago, Chile
                        </p>
                      </td>
                      <td className={classes.tdTable}>
                        <p className={classes.marginCero}>
                          a. 2003
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td className={classes.tdTable}>
                        <p className={classes.marginCero}>
                          2. Río de Janeiro, Brasil
                        </p>
                      </td>
                      <td className={classes.tdTable}>
                        <p className={classes.marginCero}>
                          b. 2019
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td className={classes.tdTable}>
                        <p className={classes.marginCero}>
                          3. Mar de Plata, Argentina
                        </p>
                      </td>
                      <td className={classes.tdTable}>
                        <p className={classes.marginCero}>
                          c. 1999
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td className={classes.tdTable}>
                        <p className={classes.marginCero}>
                          4. Guadalajara, México
                        </p>
                      </td>
                      <td className={classes.tdTable}>
                        <p className={classes.marginCero}>
                          d. 2023
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td className={classes.tdTable}>
                        <p className={classes.marginCero}>
                          5. Lima, Perú
                        </p>
                      </td>
                      <td className={classes.tdTable}>
                        <p className={classes.marginCero}>
                          e. 2015
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td className={classes.tdTable}>
                        <p className={classes.marginCero}>
                          6. Toronto, Canadá
                        </p>
                      </td>
                      <td className={classes.tdTable}>
                        <p className={classes.marginCero}>
                          f. 2011
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td className={classes.tdTable}>
                        <p className={classes.marginCero}>
                          7. Ciudad de México, México
                        </p>
                      </td>
                      <td className={classes.tdTable}>
                        <p className={classes.marginCero}>
                          g. 2007
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </table>
                </Grid>
              )
              
          default:
            return(
              <p 
                
              dangerouslySetInnerHTML={{__html: `${questionInfo}`.replace("10.", " ")}}
              className={classes.questionText}
            >
            </p>
        )
    }
  }

  // console.log(question, "info")
  return (  
    <div className={classes.root} id={question.id_pregunta + "-anchor"}>
      <Accordion key={question.id_pregunta + "-accordionSummary"}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id={question.id_pregunta + "-accordion"}
          
        >
          {
            renderImagesQuestion()
          }
        </AccordionSummary>
        <AccordionDetails key={question.id_pregunta + "-accordionDetails"}>
          <Grid container item xs={12} alignItems="center" justifyContent="space-between">
            {
              renderAnswerImages()
            }
          </Grid>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
 
export default AccordionText;