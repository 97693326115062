import React from 'react';
import { makeStyles,Grid} from '@material-ui/core';
import NavBar from '../../NavBar';
import MenuIzquierdo from '../../MenuIzquierdo';
import Map5 from '../Map/map5';

const useStyles = makeStyles((theme) => ({
  grid1: {
    zIndex: "3",
    [theme.breakpoints.down(850)]: {
      // paddingLeft: "8vh",
    },
  },
  grid2: {
    zIndex: "2",
  },
  grid3: {
    boxSizing: "border-box",
    zIndex: "1",
    paddingLeft: "3%",
    [theme.breakpoints.down("md")]: {
      paddingLeft: "0px",
    },
  },
}));

function ContenedorMap() {
  const classes= useStyles()
  return (
    <Grid container>
      <Grid item className={classes.grid1} sm={12}>
        <NavBar />
      </Grid>
      <Grid item className={classes.grid2} sm={1}>
        <MenuIzquierdo />
      </Grid>
      <Grid item className={classes.grid3} sm={12}>
        <Map5 />
      </Grid>
    </Grid>
  );
}

export default ContenedorMap;
