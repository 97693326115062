import React, { useState, useContext, useEffect } from "react";
import axios                from "../../../../../services/axios";
import { Redirect } from 'react-router-dom';
import { makeStyles, Grid } from "@material-ui/core";
import NavBar from "../../../../NavBar";
import MenuIzquierdo from "../../../../MenuIzquierdo";
import Emotions from "../../Emotions";
import Introduction from "./Introduction";
import Instructions from "../../Instructions";
import ReadingStrategys from "./ReadingStrategys";
import Lectura from "./Lectura";
import ReadingTools from "../../ReadingTools/ReadingTools";
import DrawerContext from "../../../../../usability/DrawerContext";
import checkIfActivityIsUnlocked from "../../../../../utilities/FN/checkIfActivityIsUnlocked";

const useStyles = makeStyles((theme) => ({
  grid1: {
    zIndex: "3",
  },
  grid2: {
    zIndex: "2",
  },
  grid3: {
    boxSizing: "border-box",
    zIndex: "1",
    paddingLeft: "3%",
    [theme.breakpoints.down("md")]: {
      paddingLeft: "0px",
    },
  },
  ToolBar: {
    position: "relative",
    top: "150px",
  },

  restartStyles: {
    display: "flex",
    height: "100%",
    width: "100%",
    position: "inherit",
    // overflow: "auto",

    // "& div": {
    //   tansform: "unset",
    // },
  },

  restartStylesContent: {
    transform: "none!important",
    // transform: (props) => (props.disabledZoom ? "none!important" : "none"),
  },
}));

const ContainerSession3 = (props) => {
  const { disabledZoom } = useContext(DrawerContext);
  const classes = useStyles({ disabledZoom });

  const [sessionStatus, setSessionStatus] = useState()
  const [tryStep, setTryStep] = useState()
  const [activeStep, setActiveStep] = useState();
  const [select, setSelect] = useState();
  const id_actividad = 78

 const getStep = () =>{
    axios
      .get(`/user/stepper/actividad?id_actividad=${id_actividad}`,)
      .then((response) => {
        if (response.status === 200) {
          const step = response.data.resultado.stepper[0].step;
          const LIMIT = 5;
          if (step >= 0 && step <= LIMIT) {
            setActiveStep(step);
          } else if (step > LIMIT) {
            setActiveStep(LIMIT);
          } else {
            setActiveStep(0);
          }
          setTryStep(response.data.resultado.intento)
          setSessionStatus(response.data.resultado.stepper[0].actividad_status)
        }
      })
      .catch((error) => {
        console.log(error, "ERRstp!!")
      });
  }

  const handleNextStep = () => {
    const body = {
      id_actividad: id_actividad, 
    }
    axios
    .put(`/user/up/stepper/actividad`, body)
    .then((response) => {
      if (response.status === 200) {
          console.log(response, "up")
      }
    })
    .catch((error) => {
      console.log(error, "ERRup!!")
    });
  }

  const handlePreviewStep = () => {
    const body = {
      id_actividad: id_actividad, 
    }
    axios
    .put(`/user/down/stepper/actividad`, body)
    .then((response) => {
      if (response.status === 200) {
          console.log(response, "down")
      }
    })
    .catch((error) => {
      console.log(error, "ERRdwn!")
    });
  }

  const emotionsStar = () => {
    if(tryStep === 1){
      const body = {
      id_actividad: id_actividad, 
      id_sentimiento: select
      }
      axios
      .post(`/user/actividad/sentimiento/inicio`, body)
      .then((response) => {
        if (response.status === 200) {
            console.log(response, "emoStar")
        }
      })
      .catch((error) => {
        console.log(error, "ERRemoStar!!")
      });
    }
  }

  const emotionsEnd = () => {
    if(tryStep === 1){
      const body = {
      id_actividad: id_actividad, 
      id_sentimiento: select
      }
      axios
      .put(`/user/actividad/sentimiento/fin`, body)
      .then((response) => {
        if (response.status === 200) {
            console.log(response, "emoEnd")
        }
      })
      .catch((error) => {
        console.log(error, "ERRemoENd")
      });
    }
  }

  useEffect(() => {
    getStep();
    return () => {};
  }, []);;
  useEffect(() => {
    const element = document.getElementById("transform");
    if (element) element.removeProperty("transform");
  }, []);

  const forbidenSession = () => {
    if (checkIfActivityIsUnlocked(sessionStatus)) {
      switch (activeStep) {
        case 0:
          return(
            <Emotions 
            activeStep={activeStep} 
            setActiveStep={setActiveStep}
            handleNextStep={handleNextStep}
            emotionSave={emotionsStar}
            select={select}
            setSelect={setSelect}
          />
          )
        case 1:
          return(
            <Introduction 
            activeStep={activeStep} 
            setActiveStep={setActiveStep}
            handleNextStep={handleNextStep}
          />
          )
        case 2:
          return(
            <Emotions 
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            handleNextStep={handleNextStep}
            emotionSave={emotionsEnd}
            select={select}
            setSelect={setSelect}
          />
          )
        case 3:
          return(
            <Instructions 
            activeStep={activeStep} 
            setActiveStep={setActiveStep}
            handleNextStep={handleNextStep}
          />
          )
        case 4:
          return(
            <ReadingStrategys 
            activeStep={activeStep} 
            setActiveStep={setActiveStep}
            handleNextStep={handleNextStep} 
          />
          )
        case 5:
          return(
            <>
            <Lectura 
              activeStep={activeStep} 
              setActiveStep={setActiveStep}
              handlePreviewStep={handlePreviewStep}
              id_actividad={id_actividad}
            />
            <ReadingTools toActivity={"actividad15"} />
          </>
          )
        default:
          break;
      }
    } else {
      return(
      <Redirect  
        to={{
          pathname: "/error/sesion-bloqueada",
          state: {
            reason:'sesion-bloqueada'
          },
        }}
      />
      )
    }
  }
  
  return (
    <Grid container>
      <Grid item className={classes.grid1} sm={12}>
        <NavBar />
      </Grid>
      <Grid item className={classes.grid2} sm={1}>
        <MenuIzquierdo />
      </Grid>
      <Grid item className={classes.grid3} sm={12}>
        {sessionStatus && forbidenSession()}
      </Grid>
    </Grid>
  );
};

export default ContainerSession3;
