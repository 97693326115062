import React from "react";
import ProtectedRoute from "./ProtectedRoute";
// import PerfilAdmin from "../pages/Admin_Pearson/PerfilAdmin";
import WelcomeAdmin from "../pages/Admin_Pearson/WelcomeAdmin";
import EditTeacher from "../pages/Admin_Pearson/Editar/EditTeacher";
import EditPlataform from "../pages/Admin_Pearson/LMS/ModalEdit";
import OpcionesCargaDatos from "../pages/Admin_Pearson/CargarDatos/OpcionesCargaDatos";

import GruposInfo from "../pages/Admin_Pearson/grupos/GruposInfo";
import EscuelasLista from "../pages/Admin_Pearson/escuelas/index";
import PerfilAdministrador from "../pages/Admin_Pearson/PerfilAdmin";
import EscuelaInfo from "../pages/Admin_Pearson/escuelas/EscuelaInfo.jsx";
import UsersInfo from "../pages/Admin_Pearson/usuarios/UsersInfo";
import usersGroupInfo from "../pages/Admin_Pearson/grupos/usuarios/UsersInfo";
import LMS from "../pages/Admin_Pearson/LMS/index";

import Reporte1 from "../pages/Admin_Pearson/Reportes/Reporte_1";
import Reporte2 from "../pages/Admin_Pearson/Reportes/Reporte_2";
import Reporte3 from "../pages/Admin_Pearson/Reportes/Reporte_3";
import Reporte4 from "../pages/Admin_Pearson/Reportes/Reporte_4";
import Reporte5 from "../pages/Admin_Pearson/Reportes/Reporte_5";
import Reporte6 from "../pages/Admin_Pearson/Reportes/Reporte_6";
import Reporte8 from "../pages/Admin_Pearson/Reportes/Reporte_8";

import Reportes from "../pages/Admin_Pearson/Reportes";
import ReportesPearson from "../pages/Admin_Pearson/ReportesPearson";
import EditSchool from "../pages/Admin_Pearson/Editar/EditSchool";
import EditStudent from "../pages/Admin_Pearson/Editar/EditStudent";
import UsersManual from "../pages/Admin_Pearson/manual_de_usuario/UsersManual";
import CargarDatosAdminPerarson from "../pages/Admin_Pearson/CargarDatos/CargarDatos";
import CargarEscuelaIndividual from "../pages/Admin_Pearson/CargarDatos/CargarEscuelaIndividual";
import CargarUsuariosMasiva from "../pages/Admin_Pearson/CargarDatos/CargarUsuariosMasiva";
import CargarEscuelaMasiva from "../pages/Admin_Pearson/CargarDatos/CargarEscuelaMasiva";
import CargarUsuariosIndividual from "../pages/Admin_Pearson/CargarDatos/CargarUsuariosIndividual";
import CrearCodigos from "../pages/Admin_Pearson/CargarDatos/CrearCodigos/CrearCodigos";

const routes = [
  <ProtectedRoute exact path="/admin/welcome" component={WelcomeAdmin} />,
  <ProtectedRoute
    exact
    path="/admin/cargar-datos"
    component={OpcionesCargaDatos}
  />,
  <ProtectedRoute
    exact
    path="/admin/cargar-datos/escuelas/individual"
    component={CargarEscuelaIndividual}
  />,
  <ProtectedRoute
    exact
    path="/admin/cargar-datos/escuelas/masiva"
    component={CargarEscuelaMasiva}
  />,
  <ProtectedRoute
    exact
    path="/admin/cargar-datos/usuarios/individual"
    component={CargarUsuariosIndividual}
  />,
  <ProtectedRoute
    exact
    path="/admin/cargar-datos/usuarios/masiva"
    component={CargarUsuariosMasiva}
  />,
  <ProtectedRoute
    exact
    path="/admin/cargar-datos/usuarios"
    component={CargarDatosAdminPerarson}
  />,
  <ProtectedRoute
    exact
    path="/admin/cargar-datos/crear-codigos"
    component={CrearCodigos}
  />,
  <ProtectedRoute exact path="/admin/perfil" component={PerfilAdministrador} />,
  <ProtectedRoute
    exact
    path="/admin/manual_de_usuario"
    component={UsersManual}
  />,
  <ProtectedRoute exact path="/admin/escuelas" component={EscuelasLista} />,

  <ProtectedRoute exact path="/admin/escuela/:id" component={EscuelaInfo} />,
  <ProtectedRoute
    exact
    path="/admin/escuela/:id/grupos/:userId"
    component={GruposInfo}
  />,
  <ProtectedRoute
    exact
    path="/admin/escuela/:id/usuarios/:userId"
    component={UsersInfo}
  />,

  <ProtectedRoute
    exact
    path="/admin/grupo/:id/usuarios"
    component={usersGroupInfo}
  />,
  <ProtectedRoute
    path="/admin/escuela/:id_escuela/editar"
    component={EditSchool}
  />,

  <ProtectedRoute exact path="/admin/LMS" component={LMS} />,

  <ProtectedRoute path="/admin/LMS/:id_lti/editar" component={EditPlataform} />,

  <ProtectedRoute
    path="/admin/usuarios/docente/editar/:id_usuario"
    component={EditTeacher}
  />,
  <ProtectedRoute
    path="/admin/usuarios/alumno/editar/:id_usuario"
    component={EditStudent}
  />,
  <ProtectedRoute exact path="/admin/reportes" component={Reportes} />,
  <ProtectedRoute exact path="/reportes/1" component={Reporte1} />,
  <ProtectedRoute exact path="/reportes/2" component={Reporte2} />,
  <ProtectedRoute exact path="/reportes/3" component={Reporte3} />,
  <ProtectedRoute exact path="/reportes/4" component={Reporte4} />,
  <ProtectedRoute exact path="/reportes/5" component={Reporte5} />,
  <ProtectedRoute exact path="/reportes/6" component={Reporte6} />,
  <ProtectedRoute exact path="/reportes/8" component={Reporte8} />,

  <ProtectedRoute
    exact
    path="/admin/reportes_pearson"
    component={ReportesPearson}
  />,
];

const AdminPearson = routes.map((route, key) => {
  return { ...route, key: key };
});

export default AdminPearson;
