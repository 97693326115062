import React, { useEffect }         from "react";
import { makeStyles,Grid}           from "@material-ui/core";
import Corazon                      from "../../../../imgs/Corazon.png"
import Estrella                     from "../../../../imgs/Estrella.png"
import axios                        from "../../../../../services/axios";

const useStyles = makeStyles(theme =>({
  evaluationsContainer: {
    height:'100%',
    width:'1px  ',
  },
  ContainerIzquierdo:{
    position:'relative',
    background:'radial-gradient(circle, rgba(255,230,35,0.21246505438112742) 11%, rgba(45,218,201,0.20210090872286413) 53%)',
    height:'100%',
    width: '100%',
    placeSelf: 'center',
    [theme.breakpoints.down(760)]:{
      width: '678px',
      padding:' 0 0 0 10px',
    },
    [theme.breakpoints.down(678)]:{
      width: '325px',
      padding:' 0 0 0 10px',
    },
  },
  ContainerEvaluacion:{
      position:'relative',
      width:'100vw',
      gap: '20px',
      overflow: 'auto',
  },
  ContainerBarraInferior:{
    background:'#2DDAC9',
    height:'79.5px',
    [theme.breakpoints.down(850)]:{
      background: 'linear-gradient(90deg, #8144DF 0%, #2EDAC9 100%)',
    },
  },
  ContainerBarraDerecha:{
    background: 'linear-gradient(0deg, #8144DF 0%, #2EDAC9 100%)',
    width:'3%',
    height:'100vh',
    [theme.breakpoints.down(1024)]:{
      display:'none',
    },
  },
  ContainerTextoTitulo:{
    width: '466px',
    [theme.breakpoints.down(600)]:{
      width: '415px',
      marginTop: '90px'
    },
    '& .Titulo':{
      textAlign:'center',
      font:'45px Fredoka One',
      color:'#996DE3',
      margin:'0',
      [theme.breakpoints.down(1501)]:{
        font:'40px Fredoka One',
      },
      [theme.breakpoints.down(1001)]:{
        font:'30px Fredoka One',
      },
      [theme.breakpoints.down(600)]:{
        font:'24px Fredoka One',
      },
    },
  },
  ContainerTextoResultadoEst:{
    width: '520px',
    minHeight: '101px',
    [theme.breakpoints.down(600)]:{
      width: '315px',
    },
  },
  textoRetro: {
    font:'22px lato',
    color:'#4B4A4E',
    letterSpacing: '0.21px',
    margin:'0',
    textAlign:'center',
    [theme.breakpoints.down(1440)]:{
      font:'20px lato',
    },
    [theme.breakpoints.down(960)]:{
      font:'16px lato',
    },
  },
  ContainerTextoResultadoCor:{
    width: '535px',
    minHeight: '101px',
    [theme.breakpoints.down(600)]:{
      width: '315px',
    },
  },
  ContainerEstrella:{
    '& .Divisor':{
      width: '39px',
      height: '0px',
      border: '4px solid #F1CB5A',
      borderRadius:'25px',
      [theme.breakpoints.down(769)]:{
        width:'29px',
      },
    },
    '& .Estrella':{
      height:'60px',
      margin:'0 19.5px',
      [theme.breakpoints.down(769)]:{
        height:'40px',
      },
    },
  },
  ContainerResultado:{
    position:'relative',
    width: '283px',
    height: '153px',
    marginBottom: '40px',
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    boxShadow: '0px 2px 5px #00000029',
    border:' 3px solid #FBE6B9',
    borderRadius: '20px',
    [theme.breakpoints.down(769)]:{
      width: '233px',
      height: '103px',
      marginBottom: '20px', 
    },
    
    '& .TextoPuntaje':{
      position:'absolute',
      top:'18.75px',
      textAlign:'center',
      height:'30px',
      font:'25px lato',
      color:'#1DA698',
      margin:'0',
      [theme.breakpoints.down(769)]:{
        top:'8.75px',
        font:'20px lato',
      },
    },
    '& .Divisor':{
      position:'absolute',
      top:'61.36px',
      width: '179px',
      height: '0px',
      border: '3px solid #FBE6B9',
      [theme.breakpoints.down(769)]:{
        top:'41.36px',
        width: '129px',
      },
    },
    '& .ResultadoPuntaje':{
      position:'absolute',
      top:'73.24px',
      textAlign: 'center',
      font: '900 45px Lato',
      letterSpacing: '0px',
      color: '#DE7495',
      margin:'0',
      [theme.breakpoints.down(769)]:{
        font: '900 35px Lato',
        top:'53.24px',
      },
    },
  },
  
  ContainerHeraldo:{
    width:'267px',
    height:'300px',
    [theme.breakpoints.down(960)]:{
      display: 'none'
    },
    '& .Heraldo':{
      height:'100%',
    },    
  },
  HiddenContainerTextoResultadoCor: {
    height:'250px',
    marginBottom: "20px",
    [theme.breakpoints.down(769)]:{
      height:'210px',
    },
    '& .Heraldo':{
      height:'100%',
    },    
  },
  link:{
    textDecoration:'none',
    marginBottom: '20px',
  },
  BotonContinuar:{
    textTransform: 'none',
    height:'58px',
    width:'261px',  
    background: '#7A54BA',
    boxShadow: '0px 3px 6px #00000029',
    borderRadius: '61px',
    color: '#FFFFFF',
    font: 'bold 20px lato ',
    '&:hover':{
        background: '#dd78fb',
      },
    [theme.breakpoints.down(769)]:{
      font: 'bold 16px lato ',
      height:'48px',
      width:'221px',  
    },
  },
  ContainerTextoResultado:{
    // position:'absolute',
    // top:'275px',
    // left:'0px',
    width: '570px',
    height: '101px',
    [theme.breakpoints.down(620)]:{
      width: 'auto',
      margin: "7px",
    },
    '& .Resultado':{
      font:'22px lato',
      color:'#4B4A4E',
      letterSpacing: '0.21px',
      margin:'0',
      textAlign:'center',
      [theme.breakpoints.down(850)]:{
        font:'18px lato',
      },
    }
  },
}));

const FinalResultadoEvaluacion = (props) => {
  const classes = useStyles()
  const [finalText, setFinalText] = React.useState()
  const {id_actividad } = props
  const [finalScore, setFinalScore] = React.useState(0)

 
  useEffect(() =>{
    axios.get(`/user/actividad/resume/get?id_actividad=${id_actividad}&intento=${1}`)
    .then(response => {
      setFinalText(response.data.resultado)
      setFinalScore(response.data.resultado.puntos_total)
    })
    .catch((error) => {
      console.log(error, "heral");
    });
  }, [id_actividad])

  const renderFinalRetro = () => {
    switch (id_actividad) {
      case (2):
        return (
          <>
          <Grid container item justifyContent="center" alignItems="center" className={classes.ContainerTextoTitulo}>
            <p className="Titulo">
              ¡Buen trabajo!
            </p>
          </Grid> 
          <Grid container item  className={classes.ContainerTextoResultado}>
            <p className="Resultado">
              Ahora sabemos qué habilidades has desarrollado mejor y en cuáles es necesario seguir trabajando.
            </p>
          </Grid>
          </>
        )
      
      case (62):
        return (
          <>
          <Grid container item justifyContent="center" alignItems="center" className={classes.ContainerTextoTitulo}>
          <p className="Titulo">
            ¡Buen trabajo!
          </p>
        </Grid> 
        <Grid container item  className={classes.ContainerTextoResultado}>
          <p className="Resultado">
            Has completado ya un ciclo de aprendizaje. Ahora puedes ver cuánto has mejorado como lector y como persona. ¡Felicidades!
          </p>
        </Grid>
          </>
        )
        
      case (21 ):
        return (
          <>
            <Grid item xs={12} justifyContent="center" className={classes.ContainerTextoTitulo}>
              <p className="Titulo">
                ¡Has llegado al final <br/>
                de esta actividad!
              </p>
              </Grid> 
              <Grid container item direction="row" justifyContent="center" alignItems="flex-start">
                <Grid container item md={4}  justifyContent="center" className={classes.ContainerTextoResultadoEst}>
                  <Grid container item justifyContent="center" alignItems="center" className={classes.ContainerEstrella}>
                    <div className="Divisor"></div>
                    <img className="Estrella" src={Estrella} alt="" />
                    <div className="Divisor"></div>
                  </Grid>
                  < p className={classes.textoRetro}>
                    Al  hacer esta lectura, repasaste   las   estrategias   para   buscar   y   encontrar información en un texto,
                    comprender el sentido de las oraciones que lo forman y el significado de toda una lectura.
                  </p>
                </Grid>
          </Grid>
          </>
        )
      
      case (40 ):
        return (
          <>
            <Grid item xs={12} justifyContent="center" className={classes.ContainerTextoTitulo}>
              <p className="Titulo">
                ¡Has llegado al final <br/>
                de esta actividad!
              </p>
              </Grid> 
              <Grid container item direction="row" justifyContent="center" alignItems="flex-start">
                <Grid container item md={4}  justifyContent="center" className={classes.ContainerTextoResultadoEst}>
                  <Grid container item justifyContent="center" alignItems="center" className={classes.ContainerEstrella}>
                    <div className="Divisor"></div>
                    <img className="Estrella" src={Estrella} alt="" />
                    <div className="Divisor"></div>
                  </Grid>
                < p className={classes.textoRetro}>
                  Al realizar esta lectura, tuviste que prestar atención en la información más relevante, 
                  la cual debes tener clara para poder comprender el resto de las ideas.<br/><br/>
                  Además, identificaste información que ya conoces y que te sirve para comprender mejor lo 
                  que lees. ¡Sigue practicando estas habilidades!.
                </p>
              </Grid>
            </Grid>
          </>
        )
      
      case (58 ):
        return (
          <>
            <Grid item xs={12} justifyContent="center" className={classes.ContainerTextoTitulo}>
              <p className="Titulo">
                ¡Has llegado al final <br/>
                de esta actividad!
              </p>
              </Grid> 
              <Grid container item direction="row" justifyContent="center" alignItems="flex-start">
                <Grid container item md={4}  justifyContent="center" className={classes.ContainerTextoResultadoEst}>
                  <Grid container item justifyContent="center" alignItems="center" className={classes.ContainerEstrella}>
                    <div className="Divisor"></div>
                    <img className="Estrella" src={Estrella} alt="" />
                    <div className="Divisor"></div>
                  </Grid>
                < p className={classes.textoRetro}>
                  Al hacer esta lectura, repasaste las estrategias para comprender y valorar mejor la información presente en un texto. 
                  Entender las ideas que lo forman y el significado de toda una lectura es una gran herramienta.<br/><br/>
                  También aprendiste que el cine es una labor en equipo que requiere de lo mejor de cada persona.
                </p>
              </Grid>
            </Grid>
          </>
        )
      
      case( 20):
        return (
          <>    
            <Grid item xs={12} justifyContent="center" className={classes.ContainerTextoTitulo}>
            <p className="Titulo">
              ¡Has llegado al final <br/>
              de esta evaluación!
            </p>
              </Grid> 
              <Grid container item direction="row" justifyContent="center" alignItems="flex-start">
                <Grid container item md={4}  justifyContent="center" className={classes.ContainerTextoResultadoEst}>
                  <Grid container item justifyContent="center" alignItems="center" className={classes.ContainerEstrella}>
                    <div className="Divisor"></div>
                    <img className="Estrella" src={Estrella} alt="" />
                    <div className="Divisor"></div>
                  </Grid>
                  <p 
                    className={classes.textoRetro}
                  >
                    Seguro que con esta evaluación pudiste comprobar cuánto has aprendido y qué habilidades has desarrollado para comprender mejor 
                    una lectura. Tal vez hay cosas que aún debes mejorar, pero no te preocupes, recuerda que con práctica te volverás un gran lector.
                  </p>
                </Grid>
                <Grid container item md={4}  justifyContent="center" className={classes.ContainerTextoResultadoCor}>
                  <Grid container item justifyContent="center" alignItems="center" className={classes.ContainerEstrella}>
                    <div className="Divisor"></div>
                    <img className="Estrella" src={Corazon} alt="" />
                    <div className="Divisor"></div>
                  </Grid>
                  <p 
                    className={classes.textoRetro}
                  >
                    También considera que desarrollar habilidades socioemocionales, como la benevolencia y la generosidad, te ayudará a gestionar
                    mejor tus emociones y a tener una mejor convivencia con todos los que te rodean. ¡Te invitamos a seguir trabajando estas 
                    habilidades! 
                  </p>
                </Grid>
              </Grid>
          </>
        )
      
      case( 39):
      return (
        <>    
          <Grid item xs={12} justifyContent="center" className={classes.ContainerTextoTitulo}>
          <p className="Titulo">
            ¡Has llegado al final <br/>
            de esta evaluación!
          </p>
            </Grid> 
            <Grid container item direction="row" justifyContent="center" alignItems="flex-start">
              <Grid container item md={4}  justifyContent="center" className={classes.ContainerTextoResultadoEst}>
                <Grid container item justifyContent="center" alignItems="center" className={classes.ContainerEstrella}>
                  <div className="Divisor"></div>
                  <img className="Estrella" src={Estrella} alt="" />
                  <div className="Divisor"></div>
                </Grid>
                <p 
                  className={classes.textoRetro}
                >
                 Recuerda que las habilidades para entender un texto van desde el reconocimiento de palabras, párrafos o
             ideas hasta la comprensión global de la lectura. Esto lo irás haciendo cada vez mejor a lo largo de este curso.
                </p>
              </Grid>
              <Grid container item md={4}  justifyContent="center" className={classes.ContainerTextoResultadoCor}>
                <Grid container item justifyContent="center" alignItems="center" className={classes.ContainerEstrella}>
                  <div className="Divisor"></div>
                  <img className="Estrella" src={Corazon} alt="" />
                  <div className="Divisor"></div>
                </Grid>
                <p 
                  className={classes.textoRetro}
                >
                  También considera que desarrollar habilidades socioemocionales, como la empatía y el respeto, te ayudará a gestionar 
                  mejor tus emociones y a tener una mejor convivencia con todos los que te rodean. ¡Te invitamos a seguir trabajando estas
                  habilidades!
                </p>
              </Grid>
            </Grid>
          </>
        )
      
      case( 57):
      return (
        <>    
          <Grid item xs={12} justifyContent="center" className={classes.ContainerTextoTitulo}>
          <p className="Titulo">
            ¡Has llegado al final <br/>
            de esta evaluación!
          </p>
            </Grid> 
            <Grid container item direction="row" justifyContent="center" alignItems="flex-start">
              <Grid container item md={4}  justifyContent="center" className={classes.ContainerTextoResultadoEst}>
                <Grid container item justifyContent="center" alignItems="center" className={classes.ContainerEstrella}>
                  <div className="Divisor"></div>
                  <img className="Estrella" src={Estrella} alt="" />
                  <div className="Divisor"></div>
                </Grid>
                <p 
                  className={classes.textoRetro}
                >
                Con esta evaluación pudiste comprobar cuánto has aprendido y qué habilidades has desarrollado para 
                comprender mejor una lectura. Seguro hay detalles que todavía debes mejorar, pero no te preocupes, 
                recuerda que si practicas te volverás un gran lector.
                </p>
              </Grid>
              <Grid container item md={4}  justifyContent="center" className={classes.ContainerTextoResultadoCor}>
                <Grid container item justifyContent="center" alignItems="center" className={classes.ContainerEstrella}>
                  <div className="Divisor"></div>
                  <img className="Estrella" src={Corazon} alt="" />
                  <div className="Divisor"></div>
                </Grid>
                <p 
                  className={classes.textoRetro}
                >
                También considera que desarrollar habilidades socioemocionales, como la afinidad y la empatía, te ayudará 
                a gestionar mejor tus emociones y a tener una mejor convivencia con todos los que te rodean. 
                ¡Te invitamos a seguir trabajando estas habilidades!
                </p>
              </Grid>
            </Grid>
          </>
        )
      
      
      case (65):
        return (
          <>
          <Grid container item justifyContent="center" alignItems="center" className={classes.ContainerTextoTitulo}>
            <p className="Titulo">
              ¡Buen trabajo!
            </p>
          </Grid> 
          <Grid container item  className={classes.ContainerTextoResultado}>
            <p className="Resultado">
              Ahora sabemos qué habilidades has desarrollado mejor y en cuáles es necesario seguir trabajando.
            </p>
          </Grid>
          </>
        )
      
      case (125):
        return (
          <>
          <Grid container item justifyContent="center" alignItems="center" className={classes.ContainerTextoTitulo}>
          <p className="Titulo">
            ¡Buen trabajo!
          </p>
        </Grid> 
        <Grid container item  className={classes.ContainerTextoResultado}>
          <p className="Resultado">
            Has completado ya un ciclo de aprendizaje. Ahora puedes ver cuánto has mejorado como lector y como persona. ¡Felicidades!
          </p>
        </Grid>
          </>
        )
        
      case (84 ):
        return (
          <>
            <Grid item xs={12} justifyContent="center" className={classes.ContainerTextoTitulo}>
              <p className="Titulo">
                ¡Has llegado al final <br/>
                de esta actividad!
              </p>
              </Grid> 
              <Grid container item direction="row" justifyContent="center" alignItems="flex-start">
                <Grid container item md={4}  justifyContent="center" className={classes.ContainerTextoResultadoEst}>
                  <Grid container item justifyContent="center" alignItems="center" className={classes.ContainerEstrella}>
                    <div className="Divisor"></div>
                    <img className="Estrella" src={Estrella} alt="" />
                    <div className="Divisor"></div>
                  </Grid>
                  < p className={classes.textoRetro}>
                    Al hacer esta lectura aprendiste que algo cotidiano puede ser el tema de un poema, lo cual se logra a través de 
                    comparaciones con otros objetos.<br/><br/>
                    Además, entendiste que en poesía las palabras no siempre tienen el significado que se les ha dado originalmente.
                  </p>
                </Grid>
          </Grid>
          </>
        )
      
      case (103 ):
        return (
          <>
            <Grid item xs={12} justifyContent="center" className={classes.ContainerTextoTitulo}>
              <p className="Titulo">
                ¡Has llegado al final <br/>
                de esta actividad!
              </p>
              </Grid> 
              <Grid container item direction="row" justifyContent="center" alignItems="flex-start">
                <Grid container item md={4}  justifyContent="center" className={classes.ContainerTextoResultadoEst}>
                  <Grid container item justifyContent="center" alignItems="center" className={classes.ContainerEstrella}>
                    <div className="Divisor"></div>
                    <img className="Estrella" src={Estrella} alt="" />
                    <div className="Divisor"></div>
                  </Grid>
                < p className={classes.textoRetro}>
                Al hacer esta lectura tuviste que fijarte en la información más relevante. Y necesitas saber cuál es 
                para que comprendas el resto de las ideas.<br/><br/>
                Además, identificaste información que se relaciona con la idea principal. ¡Sigue practicando estas habilidades!
                </p>
              </Grid>
            </Grid>
          </>
        )
      
      case (121 ):
        return (
          <>
            <Grid item xs={12} justifyContent="center" className={classes.ContainerTextoTitulo}>
              <p className="Titulo">
                ¡Has llegado al final <br/>
                de esta actividad!
              </p>
              </Grid> 
              <Grid container item direction="row" justifyContent="center" alignItems="flex-start">
                <Grid container item md={4}  justifyContent="center" className={classes.ContainerTextoResultadoEst}>
                  <Grid container item justifyContent="center" alignItems="center" className={classes.ContainerEstrella}>
                    <div className="Divisor"></div>
                    <img className="Estrella" src={Estrella} alt="" />
                    <div className="Divisor"></div>
                  </Grid>
                < p className={classes.textoRetro}>
                Recuerda que las habilidades de reflexión acerca de un texto son necesarias para aprovechar la información
                que obtienes de la lectura. Además, siempre será importante relacionar los conocimientos que ya tienes 
                con lo que estás leyendo, de modo que tus propias experiencias y saberes enriquezcan esta actividad.
                </p>
              </Grid>
            </Grid>
          </>
        )
      
      case( 83):
        return (
          <>    
            <Grid item xs={12} justifyContent="center" className={classes.ContainerTextoTitulo}>
            <p className="Titulo">
              ¡Has llegado al final <br/>
              de esta evaluación!
            </p>
              </Grid> 
              <Grid container item direction="row" justifyContent="center" alignItems="flex-start">
                <Grid container item md={4}  justifyContent="center" className={classes.ContainerTextoResultadoEst}>
                  <Grid container item justifyContent="center" alignItems="center" className={classes.ContainerEstrella}>
                    <div className="Divisor"></div>
                    <img className="Estrella" src={Estrella} alt="" />
                    <div className="Divisor"></div>
                  </Grid>
                  <p 
                    className={classes.textoRetro}
                  >
                El primer paso para leer bien es tratar de entender lo que significan las palabras y las oraciones de un texto. 
                Si lo comprendes todo en este nivel, será más fácil que identifiques cómo se relacionan los personajes o 
                qué información es más importante. Sigue practicando tus habilidades para reconocer los significados 
                de lo que lees.
                  </p>
                </Grid>
                <Grid container item md={4}  justifyContent="center" className={classes.ContainerTextoResultadoCor}>
                  <Grid container item justifyContent="center" alignItems="center" className={classes.ContainerEstrella}>
                    <div className="Divisor"></div>
                    <img className="Estrella" src={Corazon} alt="" />
                    <div className="Divisor"></div>
                  </Grid>
                  <p 
                    className={classes.textoRetro}
                  >
                También considera que para enfrentar un conflicto es mejor dialogar y negociar que imponer tus ideas
                o atacar a los otros. Recuerda que es importante respetar las ideas y las necesidades de los demás 
                para llegar a acuerdos justos. 
                  </p>
                </Grid>
              </Grid>
          </>
        )
      
      case( 102):
      return (
        <>    
          <Grid item xs={12} justifyContent="center" className={classes.ContainerTextoTitulo}>
          <p className="Titulo">
            ¡Has llegado al final <br/>
            de esta evaluación!
          </p>
            </Grid> 
            <Grid container item direction="row" justifyContent="center" alignItems="flex-start">
              <Grid container item md={4}  justifyContent="center" className={classes.ContainerTextoResultadoEst}>
                <Grid container item justifyContent="center" alignItems="center" className={classes.ContainerEstrella}>
                  <div className="Divisor"></div>
                  <img className="Estrella" src={Estrella} alt="" />
                  <div className="Divisor"></div>
                </Grid>
                <p 
                  className={classes.textoRetro}
                >
              Identificar las ideas principales sirve para que tengas una comprensión general del texto, y 
              prestar atención a los detalles te ayuda a enriquecer tu lectura. Además, puedes pensar en ejemplos 
              de la vida cotidiana para entender los conceptos que aparecen mientras lees.
                </p>
              </Grid>
              <Grid container item md={4}  justifyContent="center" className={classes.ContainerTextoResultadoCor}>
                <Grid container item justifyContent="center" alignItems="center" className={classes.ContainerEstrella}>
                  <div className="Divisor"></div>
                  <img className="Estrella" src={Corazon} alt="" />
                  <div className="Divisor"></div>
                </Grid>
                <p 
                  className={classes.textoRetro}
                >
              Y recuerda, todos merecemos ser tratados con respeto y dignidad. Procurar el bienestar de los otros te 
              permitirá ser una mejor persona.
                </p>
              </Grid>
            </Grid>
          </>
        )
      
      case( 120):
      return (
        <>    
          <Grid item xs={12} justifyContent="center" className={classes.ContainerTextoTitulo}>
          <p className="Titulo">
            ¡Has llegado al final <br/>
            de esta evaluación!
          </p>
            </Grid> 
            <Grid container item direction="row" justifyContent="center" alignItems="flex-start">
              <Grid container item md={4}  justifyContent="center" className={classes.ContainerTextoResultadoEst}>
                <Grid container item justifyContent="center" alignItems="center" className={classes.ContainerEstrella}>
                  <div className="Divisor"></div>
                  <img className="Estrella" src={Estrella} alt="" />
                  <div className="Divisor"></div>
                </Grid>
                <p 
                  className={classes.textoRetro}
                >
                Recuerda que debe quedarte claro cómo funciona un método para poder aplicarlo.  Los dibujos que ilustran 
                una explicación siempre te ayudarán a entenderlo. Sigue practicando tus habilidades para relacionar 
                las explicaciones en tu vida cotidiana.
                </p>
              </Grid>
              <Grid container item md={4}  justifyContent="center" className={classes.ContainerTextoResultadoCor}>
                <Grid container item justifyContent="center" alignItems="center" className={classes.ContainerEstrella}>
                  <div className="Divisor"></div>
                  <img className="Estrella" src={Corazon} alt="" />
                  <div className="Divisor"></div>
                </Grid>
                <p 
                  className={classes.textoRetro}
                >
                No olvides que mostrar una actitud flexible ante los cambios y permitirte conocer otras opciones te ayudará
                en tu crecimiento personal.
                </p>
              </Grid>
            </Grid>
          </>
        )
      
      
      case (128):
        return (
          <>
          <Grid container item justifyContent="center" alignItems="center" className={classes.ContainerTextoTitulo}>
            <p className="Titulo">
              ¡Buen trabajo!
            </p>
          </Grid> 
          <Grid container item  className={classes.ContainerTextoResultado}>
            <p className="Resultado">
              Ahora sabemos qué habilidades has desarrollado mejor y en cuáles es necesario seguir trabajando.
            </p>
          </Grid>
          </>
        )
      
      case (188):
        return (
          <>
          <Grid container item justifyContent="center" alignItems="center" className={classes.ContainerTextoTitulo}>
          <p className="Titulo">
            ¡Buen trabajo!
          </p>
        </Grid> 
        <Grid container item  className={classes.ContainerTextoResultado}>
          <p className="Resultado">
            Has completado ya un ciclo de aprendizaje. Ahora puedes ver cuánto has mejorado como lector y como persona. ¡Felicidades!
          </p>
        </Grid>
          </>
        )
        
      case (147 ):
        return (
          <>
            <Grid item xs={12} justifyContent="center" className={classes.ContainerTextoTitulo}>
              <p className="Titulo">
                ¡Has llegado al final <br/>
                de esta actividad!
              </p>
              </Grid> 
              <Grid container item direction="row" justifyContent="center" alignItems="flex-start">
                <Grid container item md={4}  justifyContent="center" className={classes.ContainerTextoResultadoEst}>
                  <Grid container item justifyContent="center" alignItems="center" className={classes.ContainerEstrella}>
                    <div className="Divisor"></div>
                    <img className="Estrella" src={Estrella} alt="" />
                    <div className="Divisor"></div>
                  </Grid>
                  < p className={classes.textoRetro}>
                  Al hacer esta lectura aprendiste a poner atención a las palabras: entenderlas para emplearlas de mejor 
                manera según el contexto, elegir un sinónimo para expresar las cosas de otro modo o buscar en el diccionario
                el significado más apropiado.<br/><br/>
                Además, entendiste que las ideas no siempre se presentan de manera literal, a veces hay que analizar ciertos 
                detalles que están en el texto, pero no con las palabras exactas. A esto se le llama: “leer entre líneas”.
                  </p>
                </Grid>
          </Grid>
          </>
        )
      
      case (166 ):
        return (
          <>
            <Grid item xs={12} justifyContent="center" className={classes.ContainerTextoTitulo}>
              <p className="Titulo">
                ¡Has llegado al final <br/>
                de esta actividad!
              </p>
              </Grid> 
              <Grid container item direction="row" justifyContent="center" alignItems="flex-start">
                <Grid container item md={4}  justifyContent="center" className={classes.ContainerTextoResultadoEst}>
                  <Grid container item justifyContent="center" alignItems="center" className={classes.ContainerEstrella}>
                    <div className="Divisor"></div>
                    <img className="Estrella" src={Estrella} alt="" />
                    <div className="Divisor"></div>
                  </Grid>
                < p className={classes.textoRetro}>
                Al hacer esta lectura aprendiste que el Galeón de Manila era un tipo de barco que atravesaba el océano Pacífico,
                 desde Filipinas hasta México, y que gracias a esta ruta comenzó el comercio mundial, pues los productos se 
                 comerciaban en cuatro de los cinco continentes.<br/><br/>
                 Además entendiste que en una lectura las ideas no necesariamente están escritas de manera explícita, sino que se 
                 pueden deducir de la información que se brinda.
                </p>
              </Grid>
            </Grid>
          </>
        )
      
      case (184 ):
        return (
          <>
            <Grid item xs={12} justifyContent="center" className={classes.ContainerTextoTitulo}>
              <p className="Titulo">
                ¡Has llegado al final <br/>
                de esta actividad!
              </p>
              </Grid> 
              <Grid container item direction="row" justifyContent="center" alignItems="flex-start">
                <Grid container item md={4}  justifyContent="center" className={classes.ContainerTextoResultadoEst}>
                  <Grid container item justifyContent="center" alignItems="center" className={classes.ContainerEstrella}>
                    <div className="Divisor"></div>
                    <img className="Estrella" src={Estrella} alt="" />
                    <div className="Divisor"></div>
                  </Grid>
                < p className={classes.textoRetro}>
                Descubriste que la comida es arte porque transforma la esencia de los elementos que la conforman. Recuerda que 
                la comida siempre está presente en la cultura, porque se transmite de generación en generación.<br/><br/>
                Además, aprendiste ideas que no estaban escritas de manera explícita en la lectura. Como pudiste notar, 
                en ocasiones hace falta analizar y relacionar la información para comprender todo lo que nos quiere decir el texto.
                </p>
              </Grid>
            </Grid>
          </>
        )
      
      case( 146):
        return (
          <>    
            <Grid item xs={12} justifyContent="center" className={classes.ContainerTextoTitulo}>
            <p className="Titulo">
              ¡Has llegado al final <br/>
              de esta evaluación!
            </p>
              </Grid> 
              <Grid container item direction="row" justifyContent="center" alignItems="flex-start">
                <Grid container item md={4}  justifyContent="center" className={classes.ContainerTextoResultadoEst}>
                  <Grid container item justifyContent="center" alignItems="center" className={classes.ContainerEstrella}>
                    <div className="Divisor"></div>
                    <img className="Estrella" src={Estrella} alt="" />
                    <div className="Divisor"></div>
                  </Grid>
                  <p 
                    className={classes.textoRetro}
                  >
                Recuerda que identificar el significado apropiado para una palabra o elegir un sinónimo te ayudará 
                mucho a comprender textos, sencillos y complejos.
                  </p>
                </Grid>
                <Grid container item md={4}  justifyContent="center" className={classes.ContainerTextoResultadoCor}>
                  <Grid container item justifyContent="center" alignItems="center" className={classes.ContainerEstrella}>
                    <div className="Divisor"></div>
                    <img className="Estrella" src={Corazon} alt="" />
                    <div className="Divisor"></div>
                  </Grid>
                  <p 
                    className={classes.textoRetro}
                  >
                También considera que los factores externos obstaculizan nuestra capacidad para concentrarnos e 
                identificar los sentimientos que experimentamos al formar parte de nuestra familia o de nuestro 
                grupo de clases. ¡Bien hecho!
                  </p>
                </Grid>
              </Grid>
          </>
        )
      
      case( 165):
      return (
        <>    
          <Grid item xs={12} justifyContent="center" className={classes.ContainerTextoTitulo}>
          <p className="Titulo">
            ¡Has llegado al final <br/>
            de esta evaluación!
          </p>
            </Grid> 
            <Grid container item direction="row" justifyContent="center" alignItems="flex-start">
              <Grid container item md={4}  justifyContent="center" className={classes.ContainerTextoResultadoEst}>
                <Grid container item justifyContent="center" alignItems="center" className={classes.ContainerEstrella}>
                  <div className="Divisor"></div>
                  <img className="Estrella" src={Estrella} alt="" />
                  <div className="Divisor"></div>
                </Grid>
                <p 
                  className={classes.textoRetro}
                >
              Recuerda que identificar los conectores de las oraciones te hará comprender rápidamente cuál es la idea 
              principal o de qué manera están relacionadas las ideas formuladas en oraciones.
                </p>
              </Grid>
              <Grid container item md={4}  justifyContent="center" className={classes.ContainerTextoResultadoCor}>
                <Grid container item justifyContent="center" alignItems="center" className={classes.ContainerEstrella}>
                  <div className="Divisor"></div>
                  <img className="Estrella" src={Corazon} alt="" />
                  <div className="Divisor"></div>
                </Grid>
                <p 
                  className={classes.textoRetro}
                >
              También considera que la reflexión es una capacidad que evitará que actúes impulsivamente. Recuerda meditar antes 
              de actuar y hablar. Te invitamos a seguir aprendiendo acerca de ti y del mundo que te rodea. ¡Bien hecho!
                </p>
              </Grid>
            </Grid>
          </>
        )
      
      case( 183):
      return (
        <>    
          <Grid item xs={12} justifyContent="center" className={classes.ContainerTextoTitulo}>
          <p className="Titulo">
            ¡Has llegado al final <br/>
            de esta evaluación!
          </p>
            </Grid> 
            <Grid container item direction="row" justifyContent="center" alignItems="flex-start">
              <Grid container item md={4}  justifyContent="center" className={classes.ContainerTextoResultadoEst}>
                <Grid container item justifyContent="center" alignItems="center" className={classes.ContainerEstrella}>
                  <div className="Divisor"></div>
                  <img className="Estrella" src={Estrella} alt="" />
                  <div className="Divisor"></div>
                </Grid>
                <p 
                  className={classes.textoRetro}
                >
                Recuerda que identificar la idea principal y los detalles de un texto te ayudará a comprender las 
                lecturas que sean complejas o que contengan mucha información.
                </p>
              </Grid>
              <Grid container item md={4}  justifyContent="center" className={classes.ContainerTextoResultadoCor}>
                <Grid container item justifyContent="center" alignItems="center" className={classes.ContainerEstrella}>
                  <div className="Divisor"></div>
                  <img className="Estrella" src={Corazon} alt="" />
                  <div className="Divisor"></div>
                </Grid>
                <p 
                  className={classes.textoRetro}
                >
              También considera que la atención ayuda a prevenir accidentes, a aprender y ejecutar tareas sin 
              equivocaciones. Recuerda entrenar tu atención siempre que puedas. ¡Sigue así, bien hecho!
                </p>
              </Grid>
            </Grid>
          </>
        )
      
      
      case (191):
        return (
          <>
          <Grid container item justifyContent="center" alignItems="center" className={classes.ContainerTextoTitulo}>
            <p className="Titulo">
              ¡Buen trabajo!
            </p>
          </Grid> 
          <Grid container item  className={classes.ContainerTextoResultado}>
            <p className="Resultado">
              Ahora sabemos qué habilidades has desarrollado mejor y en cuáles es necesario seguir trabajando.
            </p>
          </Grid>
          </>
        )
      
      case (251):
        return (
          <>
          <Grid container item justifyContent="center" alignItems="center" className={classes.ContainerTextoTitulo}>
          <p className="Titulo">
            ¡Buen trabajo!
          </p>
        </Grid> 
        <Grid container item  className={classes.ContainerTextoResultado}>
          <p className="Resultado">
            Has completado ya un ciclo de aprendizaje. Ahora puedes ver cuánto has mejorado como lector y como persona. ¡Felicidades!
          </p>
        </Grid>
          </>
        )
        
      case (210 ):
        return (
          <>
            <Grid item xs={12} justifyContent="center" className={classes.ContainerTextoTitulo}>
              <p className="Titulo">
                ¡Has llegado al final <br/>
                de esta actividad!
              </p>
              </Grid> 
              <Grid container item direction="row" justifyContent="center" alignItems="flex-start">
                <Grid container item md={4}  justifyContent="center" className={classes.ContainerTextoResultadoEst}>
                  <Grid container item justifyContent="center" alignItems="center" className={classes.ContainerEstrella}>
                    <div className="Divisor"></div>
                    <img className="Estrella" src={Estrella} alt="" />
                    <div className="Divisor"></div>
                  </Grid>
                  < p className={classes.textoRetro}>
                  Al realizar esta lectura aprendiste a poner atención a los acontecimientos y los detalles. 
                ¡Lo hiciste magnífico!<br/><br/>
                Además, entendiste que la Historia es importante en la vida de los seres humanos, pues gracias a 
                ella es posible reconstruir el pasado para entender el presente. ¿Qué acontecimientos del pasado conoces?
                  </p>
                </Grid>
          </Grid>
          </>
        )
      
      case (229 ):
        return (
          <>
            <Grid item xs={12} justifyContent="center" className={classes.ContainerTextoTitulo}>
              <p className="Titulo">
                ¡Has llegado al final <br/>
                de esta actividad!
              </p>
              </Grid> 
              <Grid container item direction="row" justifyContent="center" alignItems="flex-start">
                <Grid container item md={4}  justifyContent="center" className={classes.ContainerTextoResultadoEst}>
                  <Grid container item justifyContent="center" alignItems="center" className={classes.ContainerEstrella}>
                    <div className="Divisor"></div>
                    <img className="Estrella" src={Estrella} alt="" />
                    <div className="Divisor"></div>
                  </Grid>
                < p className={classes.textoRetro}>
                Al hacer esta lectura aprendiste a hallar pistas e interpretar la información con tus conocimientos previos. También 
              conociste un poco sobre la historia de los Juegos Panamericanos y Parapanamericanos. ¿Verás su transmisión?<br/><br/>
              Además, entendiste que es importante leer entre líneas y extraer datos no mencionados en la lectura para interpretar mejor 
              los mensajes. ¡Lo hiciste excelente!
                </p>
              </Grid>
            </Grid>
          </>
        )
      
      case (247 ):
        return (
          <>
            <Grid item xs={12} justifyContent="center" className={classes.ContainerTextoTitulo}>
              <p className="Titulo">
                ¡Has llegado al final <br/>
                de esta actividad!
              </p>
              </Grid> 
              <Grid container item direction="row" justifyContent="center" alignItems="flex-start">
                <Grid container item md={4}  justifyContent="center" className={classes.ContainerTextoResultadoEst}>
                  <Grid container item justifyContent="center" alignItems="center" className={classes.ContainerEstrella}>
                    <div className="Divisor"></div>
                    <img className="Estrella" src={Estrella} alt="" />
                    <div className="Divisor"></div>
                  </Grid>
                < p className={classes.textoRetro}>
                Aprendiste a hallar pistas, valorar la información, interpretar el texto con tus conocimientos previos y 
                formar juicios propios acerca de los argumentos del autor.<br/><br/>
                Además, viste que el talento no funciona solo, pues el estudio y la práctica son esenciales para mejorarlo.
                Así que ya sabes qué hacer para perfeccionar tus aptitudes lectoras. ¡A leer! ¡Lo hiciste excelente!
                </p>
              </Grid>
            </Grid>
          </>
        )
      
      case( 209):
        return (
          <>    
            <Grid item xs={12} justifyContent="center" className={classes.ContainerTextoTitulo}>
            <p className="Titulo">
              ¡Has llegado al final <br/>
              de esta evaluación!
            </p>
              </Grid> 
              <Grid container item direction="row" justifyContent="center" alignItems="flex-start">
                <Grid container item md={4}  justifyContent="center" className={classes.ContainerTextoResultadoEst}>
                  <Grid container item justifyContent="center" alignItems="center" className={classes.ContainerEstrella}>
                    <div className="Divisor"></div>
                    <img className="Estrella" src={Estrella} alt="" />
                    <div className="Divisor"></div>
                  </Grid>
                  <p 
                    className={classes.textoRetro}
                  >
                Recuerda que las habilidades lectoras referenciales son esenciales para adquirir conocimientos.
                Te servirán para localizar información concreta, identificar elementos, detalles, personajes
                u objetos, reconocer las ideas principales y secundarias. Así que ¡te invitamos a seguir practicando!
                  </p>
                </Grid>
                <Grid container item md={4}  justifyContent="center" className={classes.ContainerTextoResultadoCor}>
                  <Grid container item justifyContent="center" alignItems="center" className={classes.ContainerEstrella}>
                    <div className="Divisor"></div>
                    <img className="Estrella" src={Corazon} alt="" />
                    <div className="Divisor"></div>
                  </Grid>
                  <p 
                    className={classes.textoRetro}
                  >
                También considera que es importante identificar tus emociones para que aprendas a controlarlas, con 
                el fin de que desarrolles una buena salud mental y emocional que te permitan entablar relaciones 
                personales positivas.
                  </p>
                </Grid>
              </Grid>
          </>
        )
      
      case( 228):
      return (
        <>    
          <Grid item xs={12} justifyContent="center" className={classes.ContainerTextoTitulo}>
          <p className="Titulo">
            ¡Has llegado al final <br/>
            de esta evaluación!
          </p>
            </Grid> 
            <Grid container item direction="row" justifyContent="center" alignItems="flex-start">
              <Grid container item md={4}  justifyContent="center" className={classes.ContainerTextoResultadoEst}>
                <Grid container item justifyContent="center" alignItems="center" className={classes.ContainerEstrella}>
                  <div className="Divisor"></div>
                  <img className="Estrella" src={Estrella} alt="" />
                  <div className="Divisor"></div>
                </Grid>
                <p 
                  className={classes.textoRetro}
                >
              Recuerda que las habilidades lectoras inferenciales son esenciales para adquirir conocimientos; éstas te servirán 
              para hallar claves importantes, encontrar varios significados en los textos leídos y localizar datos, ideas o 
              mensajes que el escritor quiso transmitir. Por ello es importante seguir practicando.
                </p>
              </Grid>
              <Grid container item md={4}  justifyContent="center" className={classes.ContainerTextoResultadoCor}>
                <Grid container item justifyContent="center" alignItems="center" className={classes.ContainerEstrella}>
                  <div className="Divisor"></div>
                  <img className="Estrella" src={Corazon} alt="" />
                  <div className="Divisor"></div>
                </Grid>
                <p 
                  className={classes.textoRetro}
                >
              Es fundamental asumir la responsabilidad en las tareas del bienestar comunitario. Al evaluar tu participación, 
              te darás cuenta si necesitas mejorar en ese aspecto. La responsabilidad es un valor que mejora el mundo, junto
              con otros, como el amor, el respeto, la empatía y la solidaridad.
                </p>
              </Grid>
            </Grid>
          </>
        )
      
      case( 246):
      return (
        <>    
          <Grid item xs={12} justifyContent="center" className={classes.ContainerTextoTitulo}>
          <p className="Titulo">
            ¡Has llegado al final <br/>
            de esta evaluación!
          </p>
            </Grid> 
            <Grid container item direction="row" justifyContent="center" alignItems="flex-start">
              <Grid container item md={4}  justifyContent="center" className={classes.ContainerTextoResultadoEst}>
                <Grid container item justifyContent="center" alignItems="center" className={classes.ContainerEstrella}>
                  <div className="Divisor"></div>
                  <img className="Estrella" src={Estrella} alt="" />
                  <div className="Divisor"></div>
                </Grid>
                <p 
                  className={classes.textoRetro}
                >
                Recuerda que las habilidades lectoras son esenciales para adquirir nuevos saberes, pues te servirán para valorar la 
                información y formar juicios propios. Así podrás opinar sobre el contenido de cualquier texto.
                </p>
              </Grid>
              <Grid container item md={4}  justifyContent="center" className={classes.ContainerTextoResultadoCor}>
                <Grid container item justifyContent="center" alignItems="center" className={classes.ContainerEstrella}>
                  <div className="Divisor"></div>
                  <img className="Estrella" src={Corazon} alt="" />
                  <div className="Divisor"></div>
                </Grid>
                <p 
                  className={classes.textoRetro}
                >
              Es importante que cuides de ti. Conocerte bien te ayudará a tomar mejores decisiones y a tener relaciones sociales sanas ya sea en tus
              actividades cotidianas o cuando compartes un juego. 
                </p>
              </Grid>
            </Grid>
          </>
        )
      
      
      case (254):
        return (
          <>
          <Grid container item justifyContent="center" alignItems="center" className={classes.ContainerTextoTitulo}>
            <p className="Titulo">
              ¡Buen trabajo!
            </p>
          </Grid> 
          <Grid container item  className={classes.ContainerTextoResultado}>
            <p className="Resultado">
              Ahora sabemos qué habilidades has desarrollado mejor y en cuáles es necesario seguir trabajando.
            </p>
          </Grid>
          </>
        )
      
      case (314):
        return (
          <>
          <Grid container item justifyContent="center" alignItems="center" className={classes.ContainerTextoTitulo}>
          <p className="Titulo">
            ¡Buen trabajo!
          </p>
        </Grid> 
        <Grid container item  className={classes.ContainerTextoResultado}>
          <p className="Resultado">
            Has completado ya un ciclo de aprendizaje. Ahora puedes ver cuánto has mejorado como lector y como persona. ¡Felicidades!
          </p>
        </Grid>
          </>
        )
        
      case (273 ):
        return (
          <>
            <Grid item xs={12} justifyContent="center" className={classes.ContainerTextoTitulo}>
              <p className="Titulo">
                ¡Has llegado al final <br/>
                de esta actividad!
              </p>
              </Grid> 
              <Grid container item direction="row" justifyContent="center" alignItems="flex-start">
                <Grid container item md={4}  justifyContent="center" className={classes.ContainerTextoResultadoEst}>
                  <Grid container item justifyContent="center" alignItems="center" className={classes.ContainerEstrella}>
                    <div className="Divisor"></div>
                    <img className="Estrella" src={Estrella} alt="" />
                    <div className="Divisor"></div>
                  </Grid>
                  < p className={classes.textoRetro}>
                  Aprendiste a identificar el verbo y sus conjugaciones, así como los diferentes complementos de una oración 
                y los campos semánticos de las palabras.
                  </p>
                </Grid>
          </Grid>
          </>
        )
      
      case (292 ):
        return (
          <>
            <Grid item xs={12} justifyContent="center" className={classes.ContainerTextoTitulo}>
              <p className="Titulo">
                ¡Has llegado al final <br/>
                de esta actividad!
              </p>
              </Grid> 
              <Grid container item direction="row" justifyContent="center" alignItems="flex-start">
                <Grid container item md={4}  justifyContent="center" className={classes.ContainerTextoResultadoEst}>
                  <Grid container item justifyContent="center" alignItems="center" className={classes.ContainerEstrella}>
                    <div className="Divisor"></div>
                    <img className="Estrella" src={Estrella} alt="" />
                    <div className="Divisor"></div>
                  </Grid>
                < p className={classes.textoRetro}>
                Comprendiste cómo se relacionan las ideas secundarias con la principal a lo largo del  texto. También 
                reconociste las semejanzas y diferencias entre los elementos mencionados en la lectura.<br/><br/>
                Además, detectaste las ideas que el autor no formuló explícitamente. Asi mismo, relacionaste tus  
                conocimientos previos con la información en el texto para comprenderlo mejor.
                </p>
              </Grid>
            </Grid>
          </>
        )
      
      case (310 ):
        return (
          <>
            <Grid item xs={12} justifyContent="center" className={classes.ContainerTextoTitulo}>
              <p className="Titulo">
                ¡Has llegado al final <br/>
                de esta actividad!
              </p>
              </Grid> 
              <Grid container item direction="row" justifyContent="center" alignItems="flex-start">
                <Grid container item md={4}  justifyContent="center" className={classes.ContainerTextoResultadoEst}>
                  <Grid container item justifyContent="center" alignItems="center" className={classes.ContainerEstrella}>
                    <div className="Divisor"></div>
                    <img className="Estrella" src={Estrella} alt="" />
                    <div className="Divisor"></div>
                  </Grid>
                < p className={classes.textoRetro}>
                Aprendiste a valorar un texto a partir de su estructura y su contenido. También relacionaste su información 
                con tus conocimientos previos para entender mejor la lectura. Asi mismo, determinaste cómo validar los datos en
                fuentes  externas. Finalmente, repasaste cómo producir textos creativos a partir de la lectura.
                </p>
              </Grid>
            </Grid>
          </>
        )
      
      case( 272):
        return (
          <>    
            <Grid item xs={12} justifyContent="center" className={classes.ContainerTextoTitulo}>
            <p className="Titulo">
              ¡Has llegado al final <br/>
              de esta evaluación!
            </p>
              </Grid> 
              <Grid container item direction="row" justifyContent="center" alignItems="flex-start">
                <Grid container item md={4}  justifyContent="center" className={classes.ContainerTextoResultadoEst}>
                  <Grid container item justifyContent="center" alignItems="center" className={classes.ContainerEstrella}>
                    <div className="Divisor"></div>
                    <img className="Estrella" src={Estrella} alt="" />
                    <div className="Divisor"></div>
                  </Grid>
                  <p 
                    className={classes.textoRetro}
                  >
              Recuerda que identificar el verbo y los complementos de una oración, así como las palabras que se repiten
              y su significado, es esencial para la buena comprensión de un texto.
                  </p>
                </Grid>
                <Grid container item md={4}  justifyContent="center" className={classes.ContainerTextoResultadoCor}>
                  <Grid container item justifyContent="center" alignItems="center" className={classes.ContainerEstrella}>
                    <div className="Divisor"></div>
                    <img className="Estrella" src={Corazon} alt="" />
                    <div className="Divisor"></div>
                  </Grid>
                  <p 
                    className={classes.textoRetro}
                  >
                También considera que es importante saber reconocer tus fortalezas y tus áreas de oportunidad para poder mejorar 
                a diario. Un ejemplo de ello es saber qué puedes hacer para optimizar tu desempeño en las tareas que te resultan 
                pesadas(como leer textos con palabras nuevas) o administrar tu tiempo de manera más efectiva.
                  </p>
                </Grid>
              </Grid>
          </>
        )
      
      case( 291):
      return (
        <>    
          <Grid item xs={12} justifyContent="center" className={classes.ContainerTextoTitulo}>
          <p className="Titulo">
            ¡Has llegado al final <br/>
            de esta evaluación!
          </p>
            </Grid> 
            <Grid container item direction="row" justifyContent="center" alignItems="flex-start">
              <Grid container item md={4}  justifyContent="center" className={classes.ContainerTextoResultadoEst}>
                <Grid container item justifyContent="center" alignItems="center" className={classes.ContainerEstrella}>
                  <div className="Divisor"></div>
                  <img className="Estrella" src={Estrella} alt="" />
                  <div className="Divisor"></div>
                </Grid>
                <p 
                  className={classes.textoRetro}
                >
              Recuerda que para la buena comprensión de un texto es esencial identificar la idea principal y su relación con las 
              secundarias, así como prestar atención a los detalles y a las ideas que el autor no formula explícitamente. Asi mismo, 
              es necesario que relaciones la información nueva con tus conocimientos previos.
                </p>
              </Grid>
              <Grid container item md={4}  justifyContent="center" className={classes.ContainerTextoResultadoCor}>
                <Grid container item justifyContent="center" alignItems="center" className={classes.ContainerEstrella}>
                  <div className="Divisor"></div>
                  <img className="Estrella" src={Corazon} alt="" />
                  <div className="Divisor"></div>
                </Grid>
                <p 
                  className={classes.textoRetro}
                >
             También es importante que establezcas límites para las actividades que disfrutas, de modo que no descuides
             otros aspectos de tu vida, como la familia, los amigos y la escuela.
                </p>
              </Grid>
            </Grid>
          </>
        )
      
      case( 309):
      return (
        <>    
          <Grid item xs={12} justifyContent="center" className={classes.ContainerTextoTitulo}>
          <p className="Titulo">
            ¡Has llegado al final <br/>
            de esta evaluación!
          </p>
            </Grid> 
            <Grid container item direction="row" justifyContent="center" alignItems="flex-start">
              <Grid container item md={4}  justifyContent="center" className={classes.ContainerTextoResultadoEst}>
                <Grid container item justifyContent="center" alignItems="center" className={classes.ContainerEstrella}>
                  <div className="Divisor"></div>
                  <img className="Estrella" src={Estrella} alt="" />
                  <div className="Divisor"></div>
                </Grid>
                <p 
                  className={classes.textoRetro}
                >
                Recuerda que identificar la idea principal y los detalles de un texto te ayudará a comprender las 
                lecturas que sean complejas o que contengan mucha información.
                </p>
              </Grid>
              <Grid container item md={4}  justifyContent="center" className={classes.ContainerTextoResultadoCor}>
                <Grid container item justifyContent="center" alignItems="center" className={classes.ContainerEstrella}>
                  <div className="Divisor"></div>
                  <img className="Estrella" src={Corazon} alt="" />
                  <div className="Divisor"></div>
                </Grid>
                <p 
                  className={classes.textoRetro}
                >
              También considera que la atención ayuda a prevenir accidentes, a aprender y ejecutar tareas sin 
              equivocaciones. Recuerda entrenar tu atención siempre que puedas. ¡Sigue así, bien hecho!
                </p>
              </Grid>
            </Grid>
          </>
        )
      
          
      case (317):
        return (
          <>
          <Grid container item justifyContent="center" alignItems="center" className={classes.ContainerTextoTitulo}>
            <p className="Titulo">
              ¡Buen trabajo!
            </p>
          </Grid> 
          <Grid container item  className={classes.ContainerTextoResultado}>
            <p className="Resultado">
              Ahora sabemos qué habilidades has desarrollado mejor y en cuáles es necesario seguir trabajando.
            </p>
          </Grid>
          </>
        )
      
      case (377):
        return (
          <>
          <Grid container item justifyContent="center" alignItems="center" className={classes.ContainerTextoTitulo}>
          <p className="Titulo">
            ¡Buen trabajo!
          </p>
        </Grid> 
        <Grid container item  className={classes.ContainerTextoResultado}>
          <p className="Resultado">
            Has completado ya un ciclo de aprendizaje. Ahora puedes ver cuánto has mejorado como lector y como persona. ¡Felicidades!
          </p>
        </Grid>
          </>
        )
        
      case (336 ):
        return (
          <>
            <Grid item xs={12} justifyContent="center" className={classes.ContainerTextoTitulo}>
              <p className="Titulo">
                ¡Has llegado al final <br/>
                de esta actividad!
              </p>
              </Grid> 
              <Grid container item direction="row" justifyContent="center" alignItems="flex-start">
                <Grid container item md={4}  justifyContent="center" className={classes.ContainerTextoResultadoEst}>
                  <Grid container item justifyContent="center" alignItems="center" className={classes.ContainerEstrella}>
                    <div className="Divisor"></div>
                    <img className="Estrella" src={Estrella} alt="" />
                    <div className="Divisor"></div>
                  </Grid>
                  < p className={classes.textoRetro}>
                  Al realizar esta lectura, aprendiste a reconocer elementos e identificar detalles en el texto. ¡Lo hiciste magnífico!<br/><br/>
              Además, entendiste que es muy importante el trabajo de los campesinos, porque lleva tiempo, dedicación y amor por la tierra.
                  </p>
                </Grid>
          </Grid>
          </>
        )
      
      case (355 ):
        return (
          <>
            <Grid item xs={12} justifyContent="center" className={classes.ContainerTextoTitulo}>
              <p className="Titulo">
                ¡Has llegado al final <br/>
                de esta actividad!
              </p>
              </Grid> 
              <Grid container item direction="row" justifyContent="center" alignItems="flex-start">
                <Grid container item md={4}  justifyContent="center" className={classes.ContainerTextoResultadoEst}>
                  <Grid container item justifyContent="center" alignItems="center" className={classes.ContainerEstrella}>
                    <div className="Divisor"></div>
                    <img className="Estrella" src={Estrella} alt="" />
                    <div className="Divisor"></div>
                  </Grid>
                < p className={classes.textoRetro}>
                Al hacer esta lectura aprendiste a hallar pistas e interpretar el texto con tus conocimientos previos.
              También conociste un poco sobre la historia del canal de Panamá y la revolución que causó en el comercio marítimo.<br/><br/>
              Además entendiste que es importante leer entre líneas y extraer información no mencionada en el texto para interpretar los
              mensajes que el autor quería transmitir. ¡Bien hecho!
                </p>
              </Grid>
            </Grid>
          </>
        )
      
      case (373 ):
        return (
          <>
            <Grid item xs={12} justifyContent="center" className={classes.ContainerTextoTitulo}>
              <p className="Titulo">
                ¡Has llegado al final <br/>
                de esta actividad!
              </p>
              </Grid> 
              <Grid container item direction="row" justifyContent="center" alignItems="flex-start">
                <Grid container item md={4}  justifyContent="center" className={classes.ContainerTextoResultadoEst}>
                  <Grid container item justifyContent="center" alignItems="center" className={classes.ContainerEstrella}>
                    <div className="Divisor"></div>
                    <img className="Estrella" src={Estrella} alt="" />
                    <div className="Divisor"></div>
                  </Grid>
                < p className={classes.textoRetro}>
                Al hacer esta lectura, aprendiste a hallar pistas, interpretar el texto con tus conocimientos previos,
                valorar la información y formar juicios propios acerca de su contenido y los argumentos del autor.<br/><br/>
                Además, conociste algo más sobre el arte y acerca de datos sobre los premios Nobel y Óscar. Ahora ya sabes 
                qué hay detrás de los premios y que es importante reflexionar sobre la opinión de los otros. Si tienes dudas, 
                porque crees que la calidad de una obra no se corresponde con los premios que ha recibido, puedes consultar 
                reseñas críticas, hechas por autores especializados.
                </p>
              </Grid>
            </Grid>
          </>
        )
      
      case( 335):
        return (
          <>    
            <Grid item xs={12} justifyContent="center" className={classes.ContainerTextoTitulo}>
            <p className="Titulo">
              ¡Has llegado al final <br/>
              de esta evaluación!
            </p>
              </Grid> 
              <Grid container item direction="row" justifyContent="center" alignItems="flex-start">
                <Grid container item md={4}  justifyContent="center" className={classes.ContainerTextoResultadoEst}>
                  <Grid container item justifyContent="center" alignItems="center" className={classes.ContainerEstrella}>
                    <div className="Divisor"></div>
                    <img className="Estrella" src={Estrella} alt="" />
                    <div className="Divisor"></div>
                  </Grid>
                  <p 
                    className={classes.textoRetro}
                  >
                    Recuerda que las habilidades lectoras son esenciales para adquirir conocimientos. Cuanto mejor comprendas
                    un texto, más fácil será aprender su contenido y los mensajes y las intenciones del autor. ¡Sigue practicando!
                  </p>
                </Grid>
                <Grid container item md={4}  justifyContent="center" className={classes.ContainerTextoResultadoCor}>
                  <Grid container item justifyContent="center" alignItems="center" className={classes.ContainerEstrella}>
                    <div className="Divisor"></div>
                    <img className="Estrella" src={Corazon} alt="" />
                    <div className="Divisor"></div>
                  </Grid>
                  <p 
                    className={classes.textoRetro}
                  >
                  También considera que desarrollar habilidades socioemocionales, como la resiliencia y la tenacidad, te ayudará 
                  a gestionar mejor tus emociones y a tener una mejor convivencia con todos los que te rodean. ¡Continúa trabajando estas habilidades!
                  </p>
                </Grid>
              </Grid>
          </>
        )
      
      case( 354):
      return (
        <>    
          <Grid item xs={12} justifyContent="center" className={classes.ContainerTextoTitulo}>
          <p className="Titulo">
            ¡Has llegado al final <br/>
            de esta evaluación!
          </p>
            </Grid> 
            <Grid container item direction="row" justifyContent="center" alignItems="flex-start">
              <Grid container item md={4}  justifyContent="center" className={classes.ContainerTextoResultadoEst}>
                <Grid container item justifyContent="center" alignItems="center" className={classes.ContainerEstrella}>
                  <div className="Divisor"></div>
                  <img className="Estrella" src={Estrella} alt="" />
                  <div className="Divisor"></div>
                </Grid>
                <p 
                  className={classes.textoRetro}
                >
             Recuerda que las habilidades lectoras inferenciales son esenciales para adquirir conocimientos. 
             Éstas te servirán para hallar claves o pistas importantes, encontrar varios significados en los
             textos leídos y localizar datos, ideas o mensajes que el escritor quiso transmitir, por lo que
             es importante seguir practicando.
                </p>
              </Grid>
              <Grid container item md={4}  justifyContent="center" className={classes.ContainerTextoResultadoCor}>
                <Grid container item justifyContent="center" alignItems="center" className={classes.ContainerEstrella}>
                  <div className="Divisor"></div>
                  <img className="Estrella" src={Corazon} alt="" />
                  <div className="Divisor"></div>
                </Grid>
                <p 
                  className={classes.textoRetro}
                >
             También considera que desarrollar habilidades socioemocionales, como el respeto y la benevolencia,
             te ayudará a gestionar mejor tus emociones y a tener una mejor convivencia con todos los que te 
             rodean tanto a nivel presencial como en línea. ¡Te invitamos a seguir trabajando estas habilidades!
                </p>
              </Grid>
            </Grid>
          </>
        )
      
      case( 372):
      return (
        <>    
          <Grid item xs={12} justifyContent="center" className={classes.ContainerTextoTitulo}>
          <p className="Titulo">
            ¡Has llegado al final <br/>
            de esta evaluación!
          </p>
            </Grid> 
            <Grid container item direction="row" justifyContent="center" alignItems="flex-start">
              <Grid container item md={4}  justifyContent="center" className={classes.ContainerTextoResultadoEst}>
                <Grid container item justifyContent="center" alignItems="center" className={classes.ContainerEstrella}>
                  <div className="Divisor"></div>
                  <img className="Estrella" src={Estrella} alt="" />
                  <div className="Divisor"></div>
                </Grid>
                <p 
                  className={classes.textoRetro}
                >
                Recuerda que las habilidades lectoras son esenciales para adquirir nuevos conocimientos. Éstas te servirán
                para valorar la información y forma juicios propios acerca de la lectura. Así podrás opinar sobre el contenido
                del texto, el autor, personajes y más. Tenlo presente siempre. ¡A seguir practicando!
                </p>
              </Grid>
              <Grid container item md={4}  justifyContent="center" className={classes.ContainerTextoResultadoCor}>
                <Grid container item justifyContent="center" alignItems="center" className={classes.ContainerEstrella}>
                  <div className="Divisor"></div>
                  <img className="Estrella" src={Corazon} alt="" />
                  <div className="Divisor"></div>
                </Grid>
                <p 
                  className={classes.textoRetro}
                >
              También considera que desarrollar habilidades socioemocionales, como la reflexión y el control de la impulsividad,
              te ayudará a gestionar mejor tus emociones y a tener una mejor convivencia con todos los que te rodean dentro y 
              fuera de las redes sociales. ¡Sigue trabajando estas habilidades!
                </p>
              </Grid>
            </Grid>
          </>
        )
      
      
      default:
        return (
          <>    
            <Grid item xs={12} justifyContent="center" className={classes.ContainerTextoTitulo}>
              <p className="Titulo">
                ¡Has llegado al final <br/>
                de esta sesión!
              </p>
              </Grid> 
              <Grid container item direction="row" justifyContent="center" alignItems="flex-start">
                <Grid container item md={4}  justifyContent="center" className={classes.ContainerTextoResultadoEst}>
                  <Grid container item justifyContent="center" alignItems="center" className={classes.ContainerEstrella}>
                    <div className="Divisor"></div>
                    <img className="Estrella" src={Estrella} alt="" />
                    <div className="Divisor"></div>
                  </Grid>
                  <p 
                    dangerouslySetInnerHTML={{__html: `${finalText && finalText.texto_retro_comprension_lectora}`}}
                    className={classes.textoRetro}
                  >
                  </p>
                </Grid>
                <Grid container item md={4}  justifyContent="center" className={classes.ContainerTextoResultadoCor}>
                  <Grid container item justifyContent="center" alignItems="center" className={classes.ContainerEstrella}>
                    <div className="Divisor"></div>
                    <img className="Estrella" src={Corazon} alt="" />
                    <div className="Divisor"></div>
                  </Grid>
                  <p 
                    dangerouslySetInnerHTML={{__html: `${finalText && finalText.texto_retro_socioemocional}`}}
                    className={classes.textoRetro}
                  >
                  </p>
                </Grid>
              </Grid>
        </>
      )
    }
  }

 console.log(id_actividad, "text")
  return (
    <Grid 
      container
      direction="column"
      item xs={12}
      className={classes.evaluationsContainer}
    >
     <Grid container justifyContent="center" item sm={12} className={classes.ContainerIzquierdo}>
        <Grid container item  justifyContent="space-around" alignItems="center" className={classes.ContainerEvaluacion}>
              {
                renderFinalRetro()
              }
              <Grid container item xs={12} direction='column' justifyContent="center" alignItems="center">
            </Grid>   
          </Grid>
        <Grid container direction="row-reverse" item sm={12} className={classes.ContainerBarraInferior}></Grid>
      </Grid>
      <Grid container item sm={12} className={classes.ContainerBarraDerecha}></Grid>
    </Grid>
    
  );
}

export default FinalResultadoEvaluacion;
