import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
     Contenedor:{
        background:'#E9FBF9',
        width:'100vw',
        overflowX:'clip',
        [theme.breakpoints.down(1161)]:{
            background:'none',
           overflowX:'unset',
        },
        [theme.breakpoints.down(600)]:{
            marginTop: '79.51px',
        }
    },
  ContainerInfoLectura: {
    position: "relative",
    height: "86px",
    [theme.breakpoints.down(502)]:{
        height: "43px",
    },
    background: "#76D4C3",
    "& .BarraInfoLectura": {
      height: "86px",
      background: `Url(https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Barras/Azul.png)`,
        [theme.breakpoints.down(502)]:{
            height: "43px",
        },
    },
    "& .InfoLectura": {
      position: "absolute",
      height: "86px",
      width: "219px",
      boxSizing: "border-box",
      padding: "17.2px 0",
      background: "#FAFAFA",
      borderRadius: "50px",
      boxShadow: "2px 2px 2px 2px #00000029",
      zIndex: "4",
        [theme.breakpoints.down(502)]:{
            height: "43px",
            width: "109px",
            padding: "8.6px 0",
            borderRadius: "25px",
        },
    },
    "& .InfoLectura:before": {
      position: "absolute",
      display: "flex",
      boxSizing: "border-box",
      padding: "0 0 0 10px",
      justifyContent: "center",
      alignItems: "center",
      height: "39.88px",
      width: "39.88px",
      borderRadius: "50%",
      content: '""',
      boxShadow: "3px 0px .5px 0px #00000029",
      background: "#FAFAFA",
      left: "90px",
      top: "65px",
      transform: "rotate(0.25turn)",
      [theme.breakpoints.down(502)]:{
        left: "45px",
        top: "30px",
        height: "20.88px",
        width: "20.88px",
        },
    },
    "& .InfoLectura:after": {
      content: '""',
      height: "15px",
      width: "15px",
      borderTop: "2px solid #20A698",
      borderLeft: "2px solid #20A698",
      position: "absolute",
      bottom: "-10px",
      left: "Calc(50% - 8px)",
      transform: "rotate(0.625turn)",
      [theme.breakpoints.down(502)]:{
        height: "10px",
        width: "10px",
        left: "Calc(50% - 4.5px)",
        bottom: "-3px",
        borderTop: "1px solid #20A698",
        borderLeft: "1px solid #20A698",
        },
    },
    "& .Sesion": {
      borderLeft: "2px solid #D8D8D8",
    },
    "& .Dato": {
      margin: "0 0 5px 0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#20A698",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        margin: "0 0 2.5px 0",
        },
    },
    "& .Caracter": {
      margin: "0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#000000",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        },
    },   },
    ContenedorLectura:{
        position:'relative',
        background:'#fff',
        width:'1160px',
        height:'100%',
        padding:'60px 168px 35px ',
        [theme.breakpoints.down(1161)]:{
            width:'502px',
            padding:'60px 0 0 0',
          },
        [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
        '& .ContainerDerechos':{
            bottom:'80px',
            [theme.breakpoints.down(1161)]:{
                display:'none',
            },
        },
        '& .Derechos':{
            textAlign:"center",
            margin:"0px",
            font:" 15px lato",
            color:"#717476",
        },
        '& .Escritorio':{
            display:'block',
            [theme.breakpoints.down(1161)]:{
                display:'none'
            },
        },
        '& .Tablet':{
            display:'none',
            [theme.breakpoints.down(1161)]:{
                display:'block'
            },
        }
    },
    
    ContainerTitulo:{
        margin:'0 0 46px 0',
        '& .Titulo':{
            letterSpacing: '0px',
            color: '#F6644B',
            margin:'0',
            textAlign:'center',
            font:'48px fredoka one',
            [theme.breakpoints.down(1161)]:{
                font:'43px fredoka one',
                lineHeight:'52px',
            },
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            margin:'0 ',
            color:'#000',
            font:'22px lato',
            lineHeight : '30px',
            letterSpacing: '0.22px',
            [theme.breakpoints.down(1161)]:{
                font:'18px lato',
                lineHeight :'28px',
                letterSpacing: '0.17px',
            },
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .SubTitulo':{
            margin:'0',
            color:'#3E8E4F',
            font:'bold 30px lato',
            letterSpacing:'.28px',
            lineHeight:'28px',
            [theme.breakpoints.down(1161)]:{
                font:'bold 22px lato',
                lineHeight :'24px',
                letterSpacing: '0.21px',
            },
            [theme.breakpoints.down(502)]:{
                font:'bold 18px lato',
                lineHeight :'20px',
                letterSpacing: '0.16px',
            },
        },
        '& .TextImg':{
            margin:'11px 0 0 0',
            color:'#717476',
            font:'20px lato',
            lineHeight :'30px',
            letterSpacing: '0.19px',
            [theme.breakpoints.down(1161)]:{
                font:' 16px lato',
                lineHeight :'20px',
                letterSpacing: '0.15px',
            },
            [theme.breakpoints.down(502)]:{
                font:' 14px lato',
                lineHeight :'18px',
                letterSpacing: '0.10px',

            },
        },
        '& .Imagen':{
            [theme.breakpoints.down(1161)]:{
                width:'100%'
            },
        },

    },
    

}));

const Igualdad_y_equidad = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
             <Grid container item justifyContent="center" className={classes.ContainerInfoLectura}>
               <Grid container className="BarraInfoLectura"></Grid>
               <Grid container item className="InfoLectura">
                    <Grid container item direction="column" xs={6} justifyContent="center">
                        <p className="Dato">Grado</p>
                        <p className="Caracter">3</p>
                    </Grid>
                    <Grid container item direction="column" xs={6} justifyContent="center" className="Sesion">
                        <p className="Dato">Sesión</p>
                        <p className="Caracter">28</p>
                    </Grid>
               </Grid>
            </Grid>

            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >
                        Igualdad y equidad
                    </h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container justifyContent="center" xs={12}>
                        <img className="Imagen Escritorio" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img3ro/Sesion28/1.png" alt="Tarjeta" />
                        <img className="Imagen Tablet" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img3ro/Sesion28/1R.png" alt="Tarjeta" />

                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                           Imagina que arman un torneo de carreritas en tu escuela. Primero correrán las 
                           y los niños por grado hasta que haya un ganador por grupo. Después los finalistas 
                           competirán para conocer al campeón de la escuela.<br/><br/> 
                           
                           Pero los jueces deciden que en el punto de partida los más pequeños estarán unos 
                           metros más adelante, además hay unos niños más altos que otros. ¿Quién crees que 
                           gane? ¿Piensas que los de atrás logren llegar antes? Si organizaras carreras con tus 
                           amigos, ¿cómo los acomodarías en la línea de salida?<br/><br/> 
                           
                           No es sencillo responder a esa pregunta, pues a veces existe más de una respuesta 
                           correcta. ¿Sabías que en las olimpiadas hay varias categorías de competencia, 
                           aunque sea el mismo deporte? Organizan a los atletas en grupos que compartan 
                           características físicas, como peso, altura y edad. ¿Por qué crees que sea?<br/><br/> 
                           
                           <h3 className='SubTitulo'>¿Igualdad y equidad significan lo mismo?</h3><br/>

                            Las distinciones entre concursantes es un ejemplo de lo que ocurre en las 
                            sociedades. Somos muchos seres humanos en situaciones diversas. Algunos grupos 
                            se encuentran en desventaja frente al resto por sus realidades socioeconómicas, 
                            como los participantes que empiezan más atrás. Lo ideal es que la carrera sea 
                            parecida para todos, pero en la práctica no siempre es así. Es decir que puede ser 
                            igualitaria, pero no equitativa. ¿Cuál es la diferencia? <br/><br/>
                            
                            La <em>igualdad</em> es el derecho que tenemos todos de ser reconocidos como los demás. 
                            Para ello no importan las creencias, edades, alturas o sexos. <br/><br/>
                            
                            Por otro lado, la <em>equidad</em> tiene que ver con la justicia y el modo en que se reparten los 
                            recursos materiales. Supone la eliminación de desigualdades reales entre distintos 
                            sectores de la sociedad, esto permite que las personas desarrollen sus capacidades 
                            para aprovechar las oportunidades que, por igualdad, les corresponden. <br/><br/>
                            
                            Las diferencias siempre han existido, pero la equidad pretende eliminarlas o 
                            reducirlas. Es necesario que los gobiernos elaboren planes para que esos grupos en 
                            desventaja accedan a las mismas oportunidades que los demás. La equidad permite 
                            acabar con los obstáculos que existan en la humanidad para que ésta sea realmente 
                            igualitaria. <br/><br/>
                            
                            Retomemos la anécdota del inicio. ¿Cómo organizarías tú la competencia para que 
                            todos estuvieran en las mismas circunstancias? Tal vez quienes están atrás podrían 
                            salir un poco antes. O quizá podrían hacer grupos con integrantes similares, como en 
                            las olimpiadas.<br/><br/>
                            
                            Hay muchos ejemplos como éste en la vida cotidiana. Cuando rompemos una piñata 
                            cada quien tiene habilidades distintas para reunir los dulces. En cambio, si los más 
                            pequeños se aventaran primero por los caramelos y después los más grandes, sería 
                            una práctica más igualitaria y equitativa.
                        </p>
                    </Grid>
                    <Grid container justifyContent='center' xs={12}>
                        <Grid container item justifyContent='center' xs={12}>
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img3ro/Sesion28/2.png" alt="Tarjeta" />
                        </Grid>
                        <Grid container justifyContent='center' xs={12}>
                            <p className='TextImg'>Igualdad y equidad.</p>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item xs={12} justifyContent="center" className="ContainerDerechos">
                    <p className="Derechos">
                        © 2022. Todos los derechos reservados, Pearson, Circuito de lectores.
                    </p>                
                </Grid>
        </Grid>
    </Grid>
    );
}
 
export default Igualdad_y_equidad;
