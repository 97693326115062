import { Button, Grid,makeStyles}              from '@material-ui/core';
import React, { useContext, useEffect } from "react";
import DrawerContext from "../../../../../usability/DrawerContext";
import { useOnLoadImages } from "../../../../hooks/useOnLoadImages";
import Loader from "../../../../Loader/Loader";
import Drawer from "../../ReadingTools/Drawer";

const useStyles =  makeStyles (theme =>({
    Contenedor:{
       background:'#E9FBF9',
       width:'100vw',
       [theme.breakpoints.down(1161)]:{
           background:'none',
       },
       [theme.breakpoints.down(600)]:{
           marginTop: '79.51px',
       }
   },
  ContainerInfoLectura: {
    position: "relative",
    height: "86px",
    [theme.breakpoints.down(502)]:{
        height: "43px",
    },
    background: "#76D4C3",
    "& .BarraInfoLectura": {
      height: "86px",
      background: `Url(https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Barras/Azul.png)`,
        [theme.breakpoints.down(502)]:{
            height: "43px",
        },
    },
    "& .InfoLectura": {
      position: "absolute",
      height: "86px",
      width: "219px",
      boxSizing: "border-box",
      padding: "17.2px 0",
      background: "#FAFAFA",
      borderRadius: "50px",
      boxShadow: "2px 2px 2px 2px #00000029",
      zIndex: "4",
        [theme.breakpoints.down(502)]:{
            height: "43px",
            width: "109px",
            padding: "8.6px 0",
            borderRadius: "25px",
        },
    },
    "& .InfoLectura:before": {
      position: "absolute",
      display: "flex",
      boxSizing: "border-box",
      padding: "0 0 0 10px",
      justifyContent: "center",
      alignItems: "center",
      height: "39.88px",
      width: "39.88px",
      borderRadius: "50%",
      content: '""',
      boxShadow: "3px 0px .5px 0px #00000029",
      background: "#FAFAFA",
      left: "90px",
      top: "65px",
      transform: "rotate(0.25turn)",
      [theme.breakpoints.down(502)]:{
        left: "45px",
        top: "30px",
        height: "20.88px",
        width: "20.88px",
        },
    },
    "& .InfoLectura:after": {
      content: '""',
      height: "15px",
      width: "15px",
      borderTop: "2px solid #20A698",
      borderLeft: "2px solid #20A698",
      position: "absolute",
      bottom: "-10px",
      left: "Calc(50% - 8px)",
      transform: "rotate(0.625turn)",
      [theme.breakpoints.down(502)]:{
        height: "10px",
        width: "10px",
        left: "Calc(50% - 4.5px)",
        bottom: "-3px",
        borderTop: "1px solid #20A698",
        borderLeft: "1px solid #20A698",
        },
    },
    "& .Sesion": {
      borderLeft: "2px solid #D8D8D8",
    },
    "& .Dato": {
      margin: "0 0 5px 0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#20A698",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        margin: "0 0 2.5px 0",
        },
    },
    "& .Caracter": {
      margin: "0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#000000",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        },
    },
       '& .Regresar':{
        color: '#F2624F',
        border: '#F2624F 1px solid',
        height: '30px',
        width:'100px',
        borderRadius: '20px',
        letterSpacing: '0.14px',
        textTransform: 'none',
        background:'#FEFAF1',
        top:'calc(50% - 15px)',
        left:'5%',
        zIndex:4,
        [theme.breakpoints.down(502)]: {
          font:'bold 10px lato',
          width: "60px",
        },
      },
  },
   ContenedorLectura:{
       background:'#fff',
       width:'1160px',
       height:'100%',
      padding:'60px 168px 35px ',
        
        transform: (props) =>
        props.scale > 1 ? `scale(${props.scale}) translate(0px, 10%)` : `none`,
       [theme.breakpoints.down(1161)]:{
           width:'502px',
           padding:'60px 0 0 0',
         },
                [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
       '& .ContainerDerechos':{
           bottom:'80px',
           [theme.breakpoints.down(1161)]:{
               display:'none',
           },
       },
       '& .Derechos':{
           textAlign:"center",
           margin:"0px",
           font:" 15px lato",
           color:"#717476",
       },
       '& .Escritorio':{
           display:'block',
           [theme.breakpoints.down(1161)]:{
               display:'none'
           },
       },
       '& .Tablet':{
           display:'none',
           [theme.breakpoints.down(1161)]:{
               display:'block'
           },
       }
   },
    ContainerTitulo:{
        margin:'0 0 46px 0',
        '& .Titulo':{
            font:'48px fredoka one',
            letterSpacing: '0px',
            color: '#F6644B',
            margin:'0',
            textAlign:'center',
            [theme.breakpoints.down(1161)]:{
                font:'43px fredoka one',
                lineHeight:'52px',
            },
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            lineHeight : '30px',
            margin:'0 0 36px 0 ',
            font:'22px lato',
            color:'#000',
            letterSpacing: '0.20px',
            [theme.breakpoints.down(1161)]:{
                font:'18px lato',
                lineHeight :'28px',
                letterSpacing: '0.17px',
            },
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .Esp':{
            [theme.breakpoints.down(1161)]:{
                margin:'0px!important'
            },
        },
        '& .SubTitulo':{
            font:'bold 30px lato',
            letterSpacing:'.28px',
            lineHeight:'30px',
            color:'#1DA698',
            margin:'0',
            [theme.breakpoints.down(1161)]:{
                font:'bold 22px lato',
                lineHeight :'24px',
                letterSpacing: '0.21px',
            },
            [theme.breakpoints.down(502)]:{
                font:'bold 18px lato',
                lineHeight :'20px',
                letterSpacing: '0.16px',
            },
        },
        '& .Imagen':{
            [theme.breakpoints.down(1161)]:{
                width:'100%'
            },
        },
    },
    

}));

const Los_instrumentos_en_una_orquesta = (props) => {

  const {activeStep, setActiveStep, handlePreviewStep, id_actividad} = props

  const handleContinue = () => {
    setActiveStep(activeStep - 1)
    handlePreviewStep()
  }

  const {
    handleSize,
    disabledZoom,
    closeZoom,
    handleZoom,
    scale
  }= useContext(DrawerContext);
  const [loading, setLoading] = React.useState(true);
  const wrapperRef = React.useRef(null);
  const imagesLoaded = useOnLoadImages(wrapperRef);

  const detectSize = () => {
    const lectura = document.getElementById("lectura");
    const newWidth = lectura?.offsetWidth;
    const newHeight = lectura?.offsetHeight;
    setLoading(false);
    if (lectura) handleSize(newHeight, newWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", detectSize);
    return () => {
      handleZoom()
      window.removeEventListener("resize", detectSize);
      closeZoom()
    };
  }, []);

  const classes = useStyles({ disabledZoom,scale });
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
             <Grid container item justifyContent="center" className={classes.ContainerInfoLectura}>
                       <Grid container className="BarraInfoLectura">
            <Button className="Regresar" onClick={handleContinue}>
                Regresar
            </Button>
        </Grid>
               <Grid container item className="InfoLectura">
                    <Grid container item direction="column" xs={6} justifyContent="center">
                        <p className="Dato">Grado</p>
                        <p className="Caracter">2</p>
                    </Grid>
                    <Grid container item direction="column" xs={6} justifyContent="center" className="Sesion">
                        <p className="Dato">Sesión</p>
                        <p className="Caracter">39</p>
                    </Grid>
               </Grid>
            </Grid>
            {!imagesLoaded && <Loader />}
            <Grid
            container
            item
            className={classes.ContenedorLectura}
            justifyContent="center"
            id="lectura"
            onLoad={detectSize}
            ref={wrapperRef}
            >
            <Drawer id_lectura={id_actividad} />
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >
                        Los instrumentos<br className='Tablet'/> en una orquesta
                    </h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container item  sm={12}>
                        <p className="Lectura">
                            <h3 className='SubTitulo'>Te presentamos una orquesta sinfónica</h3> 
                        </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <img className='Imagen' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img2do/Sesion39/1.png"  alt="Tarjeta" />
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} >
                        <p className="Lectura Esp">
                            ¿Sabes cuántos músicos hay en la orquesta de la ilustración? 
                            ¡Muchísimos! Una <em>orquesta sinfónica</em> cuenta con alrededor de 
                            85 integrantes, y cada uno es especialista en alguno de los 
                            instrumentos que la conforman. Las melodías que interpretan 
                            suelen ser compuestas o arregladas para que todos ellos 
                            puedan participar en armonía y ofrecer piezas musicales para 
                            el deleite del público.<br/><br/>
                            
                            Como puedes ver, los integrantes se agrupan u ordenan de 
                            acuerdo con el tipo de instrumento que tocan: de cuerda, de 
                            viento o de percusión. Se colocan en medialuna de frente al 
                            público y al director, quien se encarga de dar instrucciones 
                            para que los instrumentos <em>entren</em> en el momento preciso, 
                            suban y bajen el volumen, o se queden en silencio, según 
                            corresponda.
                        </p>
                        <img className='Imagen Escritorio' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img2do/Sesion39/2.png"  alt="" />
                        <img className='Imagen Tablet' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img2do/Sesion39/2R.png"  alt="" />
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent='center'>
                    <p className="Lectura ">
                        La persona que dirige la orquesta se vale de un instrumento 
                        llamado <em>batuta</em>, una especie de vara con la que indica lo que 
                        deben hacer los integrantes de la orquesta. También usan un 
                        cuaderno donde registran, en un lenguaje especializado para la 
                        música, las notas que deben tocar. Cada canción o melodía 
                        tiene su propio registro, llamado <em>partitura</em>. Por eso, cuando ves 
                        a los músicos tocando en vivo o en algún programa, tienen 
                        enfrente una estructura con un cuaderno abierto. Presta 
                        atención a ese detalle la próxima vez que veas músicos 
                        tocando.<br/><br/> 
                        
                        Escuchar a una orquesta tocar es una experiencia única y 
                        hermosa que todos necesitamos experimentar alguna vez en la 
                        vida. Te invitamos a que escuches un concierto, ya sea en vivo, 
                        en un video grabado, en la televisión o en internet. Seguro lo 
                        vas a disfrutar muchísimo.<br/><br/> 

                        Es probable que al menos una orquesta haya tocado música 
                        emblemática de tu país. Y estamos casi seguros de que alguna
                        ha interpretado la banda sonora de tu película, caricatura, 
                        videojuego o serie favorita. Busca o pide a alguien que te ayude 
                        a buscar un concierto. ¡Anímate! Te sorprenderás gratamente 
                        con lo que escuches. Además, es una actividad que se puede 
                        hacer en compañía de tu familia y amigos.
                    </p>
                </Grid>
        </Grid>
        <Grid container item style={{ marginTop: 80}} ></Grid>
    </Grid>
    );
}
 
export default Los_instrumentos_en_una_orquesta;
