import { useCallback, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import axios from "../services/axios";

import RedirecToErrorActivity from "../components/hooks/redirectToerrorActivity";

import UsabilityContext from "../usability/UsabilityContext";

const useGetExcercisesData = (id) => {
  const [step, setStep] = useState(0);
  const [questionList, setQuestionList] = useState([]);
  const [data, setData] = useState({});

  const { handleLoading } = useContext(UsabilityContext);

  const history = useHistory();

  const getExcercisesData = useCallback(() => {
    if (!questionList.length) {
      handleLoading(true);
      axios
        .get(`/actividad/preguntas/get?id_actividad=${id}`)
        .then((response) => {
          const result = response.data.resultado;
          setQuestionList(result.preguntas);
          setData(result);
          const newStep = result.preguntas.findIndex((pregunta) => !pregunta.respondida)
          if (result.respondidas !== result.preguntas_total) {
            setStep(newStep);
          } else {
            history.push(`/resultado/${id}/${result.intento}`)
            return
          }
          RedirecToErrorActivity(result, history);
        })
        .finally(() => {
          handleLoading(false);
        });
    }
  }, [history, id, questionList.length, handleLoading]);

  useEffect(() => {
    getExcercisesData();
  }, [getExcercisesData]);

  return {
    data,
    step,
    setStep,
    questionList,
    setQuestionList,
  };
};

export default useGetExcercisesData;
