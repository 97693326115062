import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
     Contenedor:{
        background:'#E9FBF9',
        width:'100vw',
        overflowX:'clip',
        [theme.breakpoints.down(1161)]:{
            background:'none',
           overflowX:'unset',
        },
        [theme.breakpoints.down(600)]:{
            marginTop: '79.51px',
        }
    },
  ContainerInfoLectura: {
    position: "relative",
    height: "86px",
    [theme.breakpoints.down(502)]:{
        height: "43px",
    },
    background: "#76D4C3",
    "& .BarraInfoLectura": {
      height: "86px",
      background: `Url(https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Barras/Azul.png)`,
        [theme.breakpoints.down(502)]:{
            height: "43px",
        },
    },
    "& .InfoLectura": {
      position: "absolute",
      height: "86px",
      width: "219px",
      boxSizing: "border-box",
      padding: "17.2px 0",
      background: "#FAFAFA",
      borderRadius: "50px",
      boxShadow: "2px 2px 2px 2px #00000029",
      zIndex: "4",
        [theme.breakpoints.down(502)]:{
            height: "43px",
            width: "109px",
            padding: "8.6px 0",
            borderRadius: "25px",
        },
    },
    "& .InfoLectura:before": {
      position: "absolute",
      display: "flex",
      boxSizing: "border-box",
      padding: "0 0 0 10px",
      justifyContent: "center",
      alignItems: "center",
      height: "39.88px",
      width: "39.88px",
      borderRadius: "50%",
      content: '""',
      boxShadow: "3px 0px .5px 0px #00000029",
      background: "#FAFAFA",
      left: "90px",
      top: "65px",
      transform: "rotate(0.25turn)",
      [theme.breakpoints.down(502)]:{
        left: "45px",
        top: "30px",
        height: "20.88px",
        width: "20.88px",
        },
    },
    "& .InfoLectura:after": {
      content: '""',
      height: "15px",
      width: "15px",
      borderTop: "2px solid #20A698",
      borderLeft: "2px solid #20A698",
      position: "absolute",
      bottom: "-10px",
      left: "Calc(50% - 8px)",
      transform: "rotate(0.625turn)",
      [theme.breakpoints.down(502)]:{
        height: "10px",
        width: "10px",
        left: "Calc(50% - 4.5px)",
        bottom: "-3px",
        borderTop: "1px solid #20A698",
        borderLeft: "1px solid #20A698",
        },
    },
    "& .Sesion": {
      borderLeft: "2px solid #D8D8D8",
    },
    "& .Dato": {
      margin: "0 0 5px 0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#20A698",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        margin: "0 0 2.5px 0",
        },
    },
    "& .Caracter": {
      margin: "0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#000000",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        },
    },   },
    ContenedorLectura:{
        background:'#fff',
        width:'1160px',
        height:'100%',
        padding:'60px 168px 35px ',
        [theme.breakpoints.down(1161)]:{
            width:'502px',
            padding:'60px 0 0 0',
          },
        [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
        '& .ContainerDerechos':{
            bottom:'80px',
            [theme.breakpoints.down(1161)]:{
                display:'none',
            },
        },
        '& .Derechos':{
            textAlign:"center",
            margin:"0px",
            font:" 15px lato",
            color:"#717476",
        },
        '& .Escritorio':{
            display:'block',
            [theme.breakpoints.down(1161)]:{
                display:'none'
            },
        },
        '& .Tablet':{
            display:'none',
            [theme.breakpoints.down(1161)]:{
                display:'block'
            },
        }
    },
    ContainerTitulo:{
        margin:'0 0 34px 0',
        [theme.breakpoints.down(1161)]:{
            margin:'0 0 34px 0',
        },
        '& .Titulo':{
            font:'48px fredoka one',
            letterSpacing: '0px',
            lineHeight:'58px',
            color: '#C365B3',
            margin:'0',
            textAlign:'center',
            [theme.breakpoints.down(1161)]:{
                font:'43px fredoka one',
                lineHeight:'52px',
            },
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            lineHeight : '30px',
            margin:'0 0 36px 0',
            font:'22px lato',
            color:'#000',
            letterSpacing: '0.20px',
            [theme.breakpoints.down(1161)]:{
                font:'18px lato',
                lineHeight :'28px',
                letterSpacing: '0.17px',
            },
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .Imagen':{
            [theme.breakpoints.down(1161)]:{
                width:'100%',
            },
        },  
      
    },

    Esp:{
        margin:'0 ',
    },
}));

const Un_elefante_estrellado = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
             <Grid container item justifyContent="center" className={classes.ContainerInfoLectura}>
               <Grid container className="BarraInfoLectura"></Grid>
               <Grid container item className="InfoLectura">
                    <Grid container item direction="column" xs={6} justifyContent="center">
                        <p className="Dato">Grado</p>
                        <p className="Caracter">5</p>
                    </Grid>
                    <Grid container item direction="column" xs={6} justifyContent="center" className="Sesion">
                        <p className="Dato">Sesión</p>
                        <p className="Caracter">13</p>
                    </Grid>
               </Grid>
            </Grid>

            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                        <h2 className="Titulo">Un elefante estrellado</h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                       <p className='Lectura'>
                        No quería pegarle, lo juro. Sí, tenía mi puño arriba (todo pasó muy rápido), pero vi su 
                        cara de miedo y me arrepentí. No iba a hacerle daño, pero me enfureció que 
                        rompiera mi elefante.<br/><br/>
                        
                        Alan no dejaba de molestar. Me preguntaba qué era esto, qué era lo otro, que
                        si jugábamos, que si me gustaban los tigres. A mí me costaba mantener los ojos 
                        sobre el videojuego y sólo le respondía “sí” o “no”, para que se callara y se fuera 
                        pronto. Y aun así él no se iba. Pero ni entonces ni después quise pegarle.<br/><br/>

                        No supe en qué momento intentó alcanzar mi elefante. Supongo que se paró de puntitas, 
                        porque yo lo había puesto en la repisa más alta para que nadie lo tocara, mucho menos 
                        él, que todo lo rompe. Quizá golpeó la repisa por accidente. Quizá el elefante se le 
                        resbaló de las manos. Yo sólo escuché el ruido que hizo al caer. El golpe retumbó dentro de mí.
                       </p>                   
                    </Grid>
                    <Grid container justifyContent='center'>
                        <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion13/1.png" alt="Tarjeta" />
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container >
                        <p className='Lectura'>
                        Cuando brinqué enfurecido de la silla no sabía qué había roto Alan. 
                        Ni siquiera estaba pensando que hubiera sido algo en especial. Sólo 
                        sabía que estaba harto. Entonces, lo vi en el suelo. Eso ya no era 
                        mi elefante: eran pedazos astillados, diminutos, imposibles de componer. 
                        Fue cuando, sin pensar, alcé mi brazo e iba a pegarle, pero no lo hice. Lo juro. <br/><br/>
                        
                        Yo quería mucho ese elefantito. Mi mamá decía que era una baratija fea. Tal vez sí, 
                        pero me lo regaló mi papá. Lo compró un día que fui con él a la plaza. No sé por 
                        qué esa vez quiso que lo acompañara. Casi no salíamos juntos. De repente, en un 
                        puesto vio el elefante con la trompa alzada y me preguntó si me gustaba. No tuve 
                        tiempo de contestarle. Lo compró sin dudar y me lo regaló. <br/><br/>

                        Era un elefante bastante feo, de esos que tienen las señoras viejitas
                        en su casa. Y no era un juguete, porque era de cerámica. No entendí 
                        por qué creyó que a mí me gustaría. Cuando papá se fue y no volvió, yo 
                        estaba más pequeño, así que supuse que era una clave para decirme que 
                        estaba en Tailandia cuidando elefantes y que pronto regresaría. Pero ese 
                        pronto no llegaba y mi mamá decidió casarse con el papá de Alan.
                        </p>
                    </Grid>
                    <Grid container justifyContent='center'>
                        <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion13/2.png" alt="Tarjeta" />
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={`${classes.Parrafo1}  ${classes.Esp}`} justifyContent="center" >
                    <Grid container >
                        <p className='Lectura'>
                            Si mi papá me hubiera regalado una pelota, un bate de beisbol o un guante, no se 
                            habrían roto. Pero lo único que me dio quedó hecho pedazos. <br/><br/>
                            
                            En las noches veía al elefantito con el cielo de fondo. Me preguntaba dónde 
                            estaría papá y si estaríamos viendo las mismas estrellas. Cuando estaba más 
                            chiquito incluso le hablaba al elefante para preguntarle cuándo regresaría. 
                            Imaginaba que era mágico o algo así. Papá ya se había ido antes y había regresado. 
                            No sé por qué mamá pensó que ya no volvería y se casó con el papá de Alan. A 
                            mí me enfureció que él viniera a vivir a mi casa. ¿Y si papá regresaba y lo 
                            encontraba ahí? Nadie entendía mi preocupación. Yo pensaba en eso cada vez 
                            que veía al elefantito y me angustiaba.
                        </p>
                    </Grid>
                    <Grid container justifyContent='center'>
                        <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion13/3.png" alt="Tarjeta" />
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container >
                        <p className='Lectura'>
                            Ahora está roto. Alan lo rompió. Por eso quise pegarle. Pero me miró con ojos muy 
                            abiertos, como si viera un monstruo. Bajé el brazo. De todos modos, él lloró. 
                            Cuando vino mamá, Alan no podía ni hablar. Todos creyeron que le había pegado. 
                            A nadie le importó mi elefantito roto. <br/><br/>
                            
                            Yo también estoy llorando. Los pedazos de cerámica del elefante reflejan la 
                            luz del foco y parecen estrellas. Alguien me dijo que las estrellas que vemos 
                            en realidad ya no existen, pero la luz tarda en llegar a nosotros y por eso 
                            aún podemos verlas. Es la luz vieja de estrellas que ya no están. Ahora voy 
                            a tirar los trozos a la basura. Las estrellas se irán con ellos, como papá. 
                            Mañana le pediré perdón a Alan.
                        </p>
                    </Grid>
                </Grid>

            <Grid container item xs={12} justifyContent="center" className="ContainerDerechos">
                <p className="Derechos">
                    © 2022. Todos los derechos reservados, Pearson, Circuito de lectores.
                </p>                
            </Grid>
        </Grid>
    </Grid>
    );
}
 
export default Un_elefante_estrellado;
