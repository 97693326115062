import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import axios from "../../../../services/axios";
import AuthContext from "../../../../auth/AuthContext";
import { Button, makeStyles } from "@material-ui/core";
import { Link } from "react-router-dom";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import IconButton from "@material-ui/core/IconButton";
import UsabilityContext from "../../../../usability/UsabilityContext";
import ModalConfirm from "../../../Modals/ModalConfirm";
import Loader from "../../../Loader/Loader";
import ModalMessage from "../../../Modals/ModalMessage";

const useStyles = makeStyles((theme) => ({
  PurpleButton: {
    color: "#FFFFFF",
    backgroundColor: "#9051b1",
    minWidth: "60px",
    width: "100%",
    maxWidth: "238px",
    border: "none",
    textTransform: "unset",
    cursor: "pointer",
    fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
    fontWeight: "500",
    padding: "6px 16px",
    lineHeight: "1.75",
    transition:
      "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    boxShadow:
      "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
    "&:hover": {
      backgroundColor: "#B965D3 ",
    },
  },
  WitheButton: {
    color: "#9051b1",
    // borderColor: "#9051b1",
    border: "1px solid #9051b1",
    backgroundColor: "#FFFFFF",
    width: "100%",
    minWidth: "60px",
    maxWidth: "238px",
    textTransform: "unset",
    cursor: "pointer",
    fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
    fontWeight: "500",
    padding: "6px 16px",
    lineHeight: "1.75",
    transition:
      "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    boxShadow:
      "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
    "&:hover": {
      backgroundColor: "#B965D330 ",
    },
  },
}));

const GroupsTable = () => {
  const { user } = React.useContext(AuthContext);
  const { handleUserData, search } = React.useContext(UsabilityContext);

  const [idSchoolToDelete, setIdSchoolToDelete] = React.useState(null);
  const [rows, setRows] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  const [modalMessageOpen, setModalMessageOpen] = React.useState(false);
  const [confirmChangesResult, setConfirmChangesResult] = React.useState("");

  const [confirmationModalOpen, setConfirmationModalOpen] =
    React.useState(false);

  const classes = useStyles();

  const handleCloseConfirmationModal = () => {
    setConfirmationModalOpen(!confirmationModalOpen);
  };

  const columns = [
    {
      field: "schoolName",
      headerName: "Nombre de la escuela",
      width: 300,
      editable: false,
    },
    {
      field: "id_school",
      headerName: "ID",
      width: 60,
      editable: false,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "contry",
      headerName: "País",
      width: 120,
      editable: false,
      headerAlign: "center",
    },
    {
      field: "city",
      headerName: "Ciudad",
      width: 120,
      editable: false,
      headerAlign: "center",
      flex: 1,
    },
    {
      field: "date",
      headerName: "Fecha de creación",
      width: 150,
      editable: false,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "vip",
      headerName: "VIP",
      width: 60,
      editable: false,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "look",
      headerName: "Ver",
      width: 70,
      editable: false,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <Link
          to={`/admin/escuela/${params.row?.id_school}`}
          style={{ alignItems: "center", textDecoration: "none" }}
        >
          <Button
            color="primary"
            size="small"
            style={{
              textTransform: "unset",
            }}
          >
            Ver
          </Button>
        </Link>
      ),
    },
    {
      field: "edit",
      headerName: "Editar",
      width: 100,
      editable: false,
      renderCell: (params) => (
        <Link
          to={`/admin/escuela/${params.row?.id_school}/editar`}
          style={{ textDecoration: "none" }}
        >
          <Button
            className={classes.PurpleButton}
            size="small"
            onClick={() => {
              const datatoEdit = rows.find((user) => {
                return user.id_user === Number(params.row?.id_user);
              });

              handleUserData({ ...datatoEdit });
            }}
          >
            Editar
          </Button>
        </Link>
      ),
      headerAlign: "center",
      align: "center",
    },
    {
      field: "delete",
      headerName: "Eliminar",
      width: 100,
      editable: false,
      renderCell: (params) => (
        <IconButton
          color="secondary"
          aria-label="delete"
          onClick={() => {
            handleDelete(params?.row?.id_school);
          }}
        >
          <DeleteForeverIcon />
        </IconButton>
      ),
    },
  ];

  const handleDelete = (id) => {
    setIdSchoolToDelete(id);
    setConfirmationModalOpen(true);
  };

  const handleCloseMessageModal = () => {
    setModalMessageOpen(!modalMessageOpen);
  };

  const confirmDelete = () => {
    setConfirmationModalOpen(false);
    setLoading(true);
    const body = {
      id_escuela: idSchoolToDelete,
    };
    axios
      .put("/listado/delete/escuela", body)
      .then((response) => {
        if (response.status === 200) {
          setConfirmationModalOpen(false);
          setModalMessageOpen(true);
          setConfirmChangesResult("La escuela ha sido eliminada");
          const listWithoutThisSchool = rows?.filter(
            (school) => school?.id_school !== idSchoolToDelete
          );
          setRows(listWithoutThisSchool);
        }
      })
      .catch((error) => {
        if (error.response) {
          // Request made and server responded
          const errorData = error.response.data;
          const mensaje =
            errorData?.detalles?.msg ||
            "Ha ocurrido un error al consumir los servicios, inténtelo de nuevo más tarde.";
          setConfirmChangesResult(mensaje);
          setConfirmationModalOpen(false);
          setModalMessageOpen(true);
        } else {
          // Something happened in setting up the request that triggered an Error
          const mensaje =
            "Ha ocurrido un error al consumir los servicios, inténtelo de nuevo más tarde.";
          setConfirmChangesResult(mensaje);
          setConfirmationModalOpen(false);
          setModalMessageOpen(true);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  React.useEffect(() => {
    setLoading(true);
    const body = {
      id_escuela: 1,
      id_usuario: 32,
    };
    axios
      .get(`/listado/escuelas?id_usuario=${user.userId}`, body)
      .then((response) => {
        if (response.status === 200) {
          const groupsList = response.data.resultado.map((school) => {
            return {
              id_school: school.id_escuela,
              schoolName: school.escuela,
              date: school.fechaCreacion,
              contry: school.pais,
              city: school.ciudad,
              id: school.id_escuela || school.id_usuario || Date.now(),
              vip: school?.tipo === "vip" ? "Si" : "No",
            };
          });

          setRows(groupsList);
        }
      })
      .catch((error) => {
        console.log(error, "error");
      })
      .finally(() => {
        setLoading(false);
      });

    return () => {};
  }, [user.userId]);

  return (
    <>
      <div
        style={{
          height: 630,
          width: "100%",
        }}
      >
        <DataGrid
          rows={rows.filter((row) => {
            if (search) {
              return (
                row?.schoolName?.toLowerCase().includes(search.toLowerCase()) ||
                row?.contry?.toLowerCase().startsWith(search.toLowerCase())
              );
            } else {
              return row;
            }
          })}
          // rows={rows}
          columns={columns}
          pageSize={10}
          rowsPerPageOptions={[10]}
          // checkboxSelection
          disableSelectionOnClick
          disableColumnFilter
          disableColumnMenu
        />
      </div>
      <ModalConfirm
        open={confirmationModalOpen}
        handleClose={handleCloseConfirmationModal}
        handleConfirm={confirmDelete}
        title="¿Estás seguro de eliminar esta institución? Ten en cuenta que se borrarán todos los estudiantes, docentes, administrador y los datos de cada uno."
      />
      <ModalMessage
        open={modalMessageOpen}
        handleClose={handleCloseMessageModal}
        title={confirmChangesResult}
        textOfButton="Aceptar"
      />
      {loading && <Loader />}
    </>
  );
};

export default GroupsTable;
