import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
    ContenedorLectura:{
        width:'502px',
        [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
    },
    ContainerTitulo:{
        margin:'0 0 34px 0',
        '& .Titulo':{
            letterSpacing: '0px',
            color: '#F78572',
            margin:'0',
            textAlign:'center',
            font:'43px fredoka one',
            lineHeight:'52px',
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            margin:'0 0 36px 0',
            color:'#000',
            font:'18px lato',
            lineHeight :'28px',
            letterSpacing: '0.17px',
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .Imagen':{
            width:'100%',
        },  

      
    }
}));

const Una_fogata_en_el_cielo = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >
                        Una fogata en el cielo
                    </h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                      <p className="Lectura">
                        Cuando los dioses se repartieron las tareas para formar el sistema solar, asignaron la 
                        Tierra al más descuidado de todos ellos. Debido a su distracción, se olvidó de dotarla 
                        de luz y calor. Por tal motivo, los seres humanos vivieron mucho tiempo envueltos en 
                        la oscuridad y tiritando de frío.<br/><br/> 
                        
                        Una noche, en el palacio de los dioses, el descuidado dios prendió una fogata en el 
                        jardín. Encontró unas pequeñas bolas de algodón y las acercó al fuego para 
                        alimentarlo, pero se incendiaron rápidamente y quemaron sus manos antes de que 
                        pudiera soltarlas. Una vez que pudo desprenderse de ellas, las arrojó al suelo.<br/><br/> 
                        
                        El piso celestial era el cielo de la Tierra. Por ello, esto fue percibido como una 
                        explosión de luces. Sus habitantes se miraron unos a otros y pudieron verse por 
                        primera vez gracias a este chisporroteo celeste. Ya no temían caer en un abismo, 
                        aunque seguían inmersos en una noche constante. Haber vislumbrado la llanura del 
                        paisaje les daba una mejor idea de su entorno y su camino.
                      </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img3ro/Sesion49/1.png" alt="Tarjeta" />
                    </Grid>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                      <p className="Lectura">
                        Poco después hubo una reunión importante en el palacio divino, en la que habló la 
                        deidad primigenia: <br/><br/> 
                        
                        —Cada uno se encargará totalmente de sus protegidos a partir de mañana. 
                        Recuerden ser responsables con sus criaturas y procurar su bienestar. Sólo de esa 
                        forma nos querrán y nos darán ofrendas. <br/><br/> 
                        
                        Todos asintieron con la cabeza y se marcharon a sus casas para planear las mejoras 
                        que harían en los mundos. Pero el dios descuidado llegó a su habitación y se durmió.<br/><br/> 
                        
                        El tiempo espacial es distinto. Unas cuantas horas para las deidades son años enteros 
                        para la humanidad. Por eso fue tanto el tiempo que transcurrió en el planeta sin que 
                        nada cambiara: las flores no abrían y los pájaros no cantaban, sólo se escuchaba el 
                        aullido de los lobos y el ulular de los búhos. Los seres humanos procuraban 
                        permanecer en sus cuevas por miedo a los animales nocturnos. Se olvidaron de rezar 
                        y no hicieron ninguna ofrenda, molestos por este abandono. <br/><br/> 
                        
                        Cuando el dios descuidado despertó, notó que los jardines de los otros dioses 
                        estaban colmados de regalos y ofrendas. Triste, convocó a algunos hombres y 
                        mujeres sabios para que le explicaran qué había hecho mal. <br/><br/> 
                        
                        —Llevamos mucho tiempo escondidos en las cavernas porque no tenemos suficiente 
                        luz ni calor. Necesitamos una fuente de energía —le respondieron un poco abatidos. <br/><br/> 
                        
                        Él se retiró a su jardín y volvió a encender una hoguera. Esta vez hizo una bola 
                        enorme de algodón, la incendió y la pateó con todas sus fuerzas hacia el infinito. Y en 
                        la Tierra se hizo la luz. Desde entonces, la bola de algodón vive suspendida en el cielo 
                        y se encarga de mantener la vida en el mundo.
                      </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img3ro/Sesion49/2.png" alt="Tarjeta" />
                    </Grid>
                </Grid>
        </Grid>
    </Grid>
    );
}
 
export default Una_fogata_en_el_cielo ;
