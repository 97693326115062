import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
     Contenedor:{
        background:'#E9FBF9',
        width:'100vw',
        overflowX:'clip',
        [theme.breakpoints.down(1161)]:{
            background:'none',
           overflowX:'unset',
        },
        [theme.breakpoints.down(600)]:{
            marginTop: '79.51px',
        }
    },
  ContainerInfoLectura: {
    position: "relative",
    height: "86px",
    [theme.breakpoints.down(502)]:{
        height: "43px",
    },
    background: "#76D4C3",
    "& .BarraInfoLectura": {
      height: "86px",
      background: `Url(https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Barras/Azul.png)`,
        [theme.breakpoints.down(502)]:{
            height: "43px",
        },
    },
    "& .InfoLectura": {
      position: "absolute",
      height: "86px",
      width: "219px",
      boxSizing: "border-box",
      padding: "17.2px 0",
      background: "#FAFAFA",
      borderRadius: "50px",
      boxShadow: "2px 2px 2px 2px #00000029",
      zIndex: "4",
        [theme.breakpoints.down(502)]:{
            height: "43px",
            width: "109px",
            padding: "8.6px 0",
            borderRadius: "25px",
        },
    },
    "& .InfoLectura:before": {
      position: "absolute",
      display: "flex",
      boxSizing: "border-box",
      padding: "0 0 0 10px",
      justifyContent: "center",
      alignItems: "center",
      height: "39.88px",
      width: "39.88px",
      borderRadius: "50%",
      content: '""',
      boxShadow: "3px 0px .5px 0px #00000029",
      background: "#FAFAFA",
      left: "90px",
      top: "65px",
      transform: "rotate(0.25turn)",
      [theme.breakpoints.down(502)]:{
        left: "45px",
        top: "30px",
        height: "20.88px",
        width: "20.88px",
        },
    },
    "& .InfoLectura:after": {
      content: '""',
      height: "15px",
      width: "15px",
      borderTop: "2px solid #20A698",
      borderLeft: "2px solid #20A698",
      position: "absolute",
      bottom: "-10px",
      left: "Calc(50% - 8px)",
      transform: "rotate(0.625turn)",
      [theme.breakpoints.down(502)]:{
        height: "10px",
        width: "10px",
        left: "Calc(50% - 4.5px)",
        bottom: "-3px",
        borderTop: "1px solid #20A698",
        borderLeft: "1px solid #20A698",
        },
    },
    "& .Sesion": {
      borderLeft: "2px solid #D8D8D8",
    },
    "& .Dato": {
      margin: "0 0 5px 0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#20A698",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        margin: "0 0 2.5px 0",
        },
    },
    "& .Caracter": {
      margin: "0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#000000",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        },
    },   },

    ContenedorLectura:{
        background:'#fff',
        width:'1160px',
        height:'100%',
        padding:'0 ',
        position:'relative',
            transform: (props) =>
      props.scale > 1 ? `scale(${props.scale}) translate(0px, 10%)` : `none`,[theme.breakpoints.down(1161)]:{
            width:'768px',
          },
          [theme.breakpoints.down(768)]:{
            width:'350px',
        },
        '& .Derechos':{
            position:'absolute',
            top:'3242px',
            textAlign:"center",
            margin:"0px",
            font:" 15px lato",
            color:"#FFFFFF",
            [theme.breakpoints.down(1161)]:{
                display:'none'
            },
        },
        '& .Escritorio':{
            display:'block',
            [theme.breakpoints.down(1161)]:{
                display:'none'
            },
        },
        '& .Tablet':{
            display:'none',
            [theme.breakpoints.down(1161)]:{
                display:'block'
            },
        },
        '& .Pos':{
            position:'absolute',
            margin:'0',
        },

        '& .Subtitulo':{
            color:'#FFFFFF',
            textAlign:'center',
            font:'24px Fredoka One',
            lineHeight:'30px',
            top:'16.12%',
            [theme.breakpoints.down(1161)]:{
                font:'18px Fredoka One',
                lineHeight:'24px',
            },
            [theme.breakpoints.down(768)]:{
                font:'7.5px Fredoka One',
                lineHeight:'10px',
            }
        },
        '& .Subtitulo2':{
            textAlign:'center',
            font:'30px Fredoka One',
            lineHeight:'30px',
            [theme.breakpoints.down(1161)]:{
                font:'22px Fredoka One',
                lineHeight:'23px',
            },
            [theme.breakpoints.down(768)]:{
                font:'10px Fredoka One',
                lineHeight:'10px',
            }
        },
        '& .Subtitulo3':{
            textAlign:'center',
            color:'#00CFFF',
            font:'25px Fredoka One',
            lineHeight:'30px',
            top:'42.62%',
            [theme.breakpoints.down(1161)]:{
                font:'22px Fredoka One',
                lineHeight:'23px',
            },
            [theme.breakpoints.down(768)]:{
                font:'10px Fredoka One',
                lineHeight:'12px',
            }
        },
        '& .Rosa':{
            color:'#FC5E9E',
        },
        '& .Sub2-1':{
            top:'20.70%',
        },
        '& .Sub2-2':{
            top:'35.35%',
        },
        '& .Sub2-3':{
            top:'55.45%',
        },
        '& .Sub2-4':{
            top:'65.60%',
        },
        
        '& .Imagen':{
            [theme.breakpoints.down(1161)]:{
                width:'100%'
            },
        },
    },

    
}));

const Diversas_acciones_para_salvar_el_mundo = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
             <Grid container item justifyContent="center" className={classes.ContainerInfoLectura}>
               <Grid container className="BarraInfoLectura"></Grid>
               <Grid container item className="InfoLectura">
                    <Grid container item direction="column" xs={6} justifyContent="center">
                        <p className="Dato">Grado</p>
                        <p className="Caracter">3</p>
                    </Grid>
                    <Grid container item direction="column" xs={6} justifyContent="center" className="Sesion">
                        <p className="Dato">Sesión</p>
                        <p className="Caracter">14</p>
                    </Grid>
               </Grid>
            </Grid>

            <Grid container item className={classes.ContenedorLectura} justifyContent="center">

                <img className='Imagen' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img3ro/Sesion14/F.png" alt="Fondo" />

      
            </Grid>

        </Grid>
    );
}
 
export default Diversas_acciones_para_salvar_el_mundo;
