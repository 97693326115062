import React, { useState } from "react";
import { createContext } from "react";
import rough from "roughjs/bundled/rough.esm";
import axios from "../services/axios";

const generator = rough.generator();

const DrawerContext = createContext();

const useHistory = (initialState) => {
  const [index, setIndex] = useState(0);
  const [history, setHistory] = useState([initialState]);

  const setState = (action, overwrite = false) => {
    const newState =
      typeof action === "function" ? action(history[index]) : action;
    if (overwrite) {
      const historyCopy = [...history];
      historyCopy[index] = newState;
      setHistory(historyCopy);
    } else {
      const updatedState = [...history].slice(0, index + 1);
      setHistory([...updatedState, newState]);
      setIndex((prevState) => prevState + 1);
    }
  };
  const undoForm = () => index > 0 && setIndex((prevState) => prevState - 1);
  const redoForm = () =>
    index < history.length - 1 && setIndex((prevState) => prevState + 1);

  return [history[index] || [], setState, undoForm, redoForm];
};

const useHistoryPizarra = (initialState) => {
  const [index, setIndex] = useState(0);
  const [history, setHistory] = useState([initialState]);

  const setState = (action, overwrite = false) => {
    const newState =
      typeof action === "function" ? action(history[index]) : action;
    if (overwrite) {
      const historyCopy = [...history];
      historyCopy[index] = newState;
      setHistory(historyCopy);
    } else {
      const updatedState = [...history].slice(0, index + 1);
      setHistory([...updatedState, newState]);
      setIndex((prevState) => prevState + 1);
    }
  };
  const undoForm = () => index > 0 && setIndex((prevState) => prevState - 1);
  const redoForm = () =>
    index < history.length - 1 && setIndex((prevState) => prevState + 1);

  return [history[index], setState, undoForm, redoForm];
};

const DrawerProvider = ({ children }) => {
  const [pizarraCanvas, setPizarraCanvas] = React.useState(false);
  const [pristinePizarra, setPristinePizarra] = React.useState(false);
  // const [elements, setElements,] = React.useState([]);
  const [elements, setElements, undoForm, redoForm] = useHistory([]);
  const [elementsPizarra, setElementsPizarra] = useHistoryPizarra([]);

  const [firstSave, setFirstSave] = useState(true);

  const [idNotes, setIdNotes] = useState(0);
  const [notes, setNotes] = useState([]);
  const [tool, setTool] = useState("selection");
  const [lastChange, setLastChange] = useState([]);
  const [activeErasedMode, setActiveErasedMode] = useState(false);
  const [habiliteCanvas, setHabiliteCanvas] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  const [reading_id, setReading_id] = useState(null);
  const [disabledZoom, setDisabledZoom] = useState(true);
  const [scale, setScale] = useState("1");
  const [positionPizarra, setPositionPizarra] = React.useState({
    x: 15,
    y: 25,
  });

  const handlePizarra = () => {
    setPizarraCanvas(!pizarraCanvas);
    setPristinePizarra(true);
  };

  const [openDrawTools, setOpenDrawTools] = useState(false);
  const [openMarkTools, setOpenMarkTools] = useState(false);
  const [colorDraw, setColorDraw] = useState("#575757");

  const [colorMarker, setColorMarker] = useState("#F8DCA950");

  const [habiliteDraw, setHabiliteDraw] = useState(false);
  const [habiliteMarker, setHabiliteMarker] = useState(false);

  const DisableCanvas = () => {
    setHabiliteDraw(false);
    setHabiliteMarker(false);
    setHabiliteCanvas(false);
    setDisabledZoom(true);
    setActiveErasedMode(false);
    setOpenDrawTools(false);
    setOpenMarkTools(false);
  };

  const handleZoom = (top) => {
    if (scale < 1.25) {
      setScale(1.25);
    } else {
      setScale(1);
    }
    setDisabledZoom(!disabledZoom);
    setOpenDrawTools(false);
    setOpenMarkTools(false);
    setHabiliteMarker(false);
    setHabiliteDraw(false);
    setHabiliteCanvas(false);
    // setDisabledZoom(!disabledZoom);
  };

  const closeZoom = () => {
    setScale(1);
    setDisabledZoom(true);
    setHabiliteMarker(false);
    setHabiliteDraw(false);
  };

  const handleSize = (newHeight, newWidth) => {
    setWidth(newWidth);
    setHeight(newHeight);
  };

  const handleReadingId = (id) => {
    setReading_id(id);
  };

  const handleHabiliteCanvas = () => {
    setHabiliteCanvas(true);
  };

  //DrawTools
  const habiliteDrawing = () => {
    setTool("pencil");
    setOpenDrawTools(!openDrawTools);
    setHabiliteDraw(true);
    setHabiliteMarker(false);
    handleHabiliteCanvas();
    // setDisabledCanvas2(true);
  };

  const habiliteDrawingLine = () => {
    setTool("line");
    // setHabiliteDraw(true);
    setOpenDrawTools(false);

    handleHabiliteCanvas();
  };
  const habiliteDrawingRectangle = () => {
    setTool("rectangle");
    // setHabiliteDraw(true);
    setOpenDrawTools(false);

    handleHabiliteCanvas();
  };
  const habiliteDrawingCircle = () => {
    setTool("ellipse");
    // setHabiliteDraw(true);
    setOpenDrawTools(false);

    handleHabiliteCanvas();
  };

  const handleHabiliteMarker = () => {
    // if (disabledCanvas) setDisabledCanvas(!disabledCanvas);
  };

  const undo = () => {
    undoForm();
  };

  const clear = () => {
    setElements([]);
    setNotes([]);
    setHabiliteCanvas(false);
  };

  const handleColor = (color) => {
    setColorDraw(color);
    setHabiliteDraw(true);
    setHabiliteMarker(false);
    if (habiliteDraw || habiliteDrawingLine || habiliteDrawingRectangle)
      setOpenDrawTools(!openDrawTools);
  };

  const handleErasedMode = (status) => {
    setActiveErasedMode(status);
  };

  const handleMarkerColor = (color) => {
    setColorMarker(color);
    // setDisabledCanvas(false);
    // setHabiliteDraw(true);
    // setHabiliteMarker(true);
    setOpenDrawTools(false);
    setOpenMarkTools(false);

    setHabiliteDraw(false);
    setHabiliteCanvas(true);
    setHabiliteMarker(true);
    setTool("pencil");
  };

  const handleDrawTools = () => {
    setOpenDrawTools(!openDrawTools);
    setOpenMarkTools(false);
  };
  const addNewNote = (e) => {
    const { pageX, pageY, clientY } = e;

    const newNote = {
      id: Date.now(),
      position: {
        x: pageX,
        y: pageY - clientY,
      },
      size: {
        width: "150px",
        height: "150px",
      },
      text: "",
    };
    const newList = [...notes, newNote];
    setNotes(newList);
  };
  const handleMoveOneNote = (id, data) => {
    let newList = notes.map((item) => {
      return item.id === id
        ? {
            ...item,
            position: data,
          }
        : item;
    });

    setNotes(newList);
  };

  const movePizarra = ({ x, y }) => {
    setPositionPizarra({
      x,
      y,
    });
  };

  const removeOneFromList = (id) => {
    const newList = notes.filter((item) => item.id !== id);
    setNotes(newList);
  };
  const handleResizeNote = (id, data) => {
    let newList = notes.map((item) => {
      return item.id === id
        ? {
            ...item,
            size: data,
          }
        : item;
    });
    setNotes(newList);
  };

  const handleTextOneNote = (id, text) => {
    let newList = notes.map((item) => {
      return item.id === id
        ? {
            ...item,
            text: text,
          }
        : item;
    });

    setNotes(newList);
  };

  const loadData = (newElements, newNotes) => {
    setElements([...elements, ...newElements] || []);

    setNotes([...notes, ...newNotes] || []);
  };

  const save = () => {
    const body = {
      notas: notes.map((note) => {
        return JSON.stringify(note);
      }),
      elements: elements.map((element) => {
        return JSON.stringify(element);
      }),
      id_actividad: reading_id,
      id_nota: idNotes,
    };
    if (firstSave) {
      axios
        .post(`/actividad/notas/set`, body)
        .then((response) => {
          if (response.status === 200) {
          }
        })
        .catch((error) => {
          console.log(error, "saveError");
        });
    } else {
      axios
        .put(`/actividad/notas/update`, body)
        .then((response) => {
          if (response.status === 200) {
          }
        })
        .catch((error) => {
          console.log(error, "updateError");
        });
    }

    // setDraw(newPic);
    // localStorage.setItem("Draw", newPic);
    // const newDataToBD = JSON.stringify({
    //   notes: notes,
    //   elements: elements,
    // });
    // localStorage.setItem(`lectura${reading_id}`, newDataToBD);
    // secondCanvas.current.loadSaveData(newPic, true);
  };

  const handleSave = () => {
    // localStorage.setItem("notes", JSON.stringify(notes));
    save();
  };

  const handleErase = () => {
    setNotes([]);
    clear();
  };

  const handleTurn = (canvas) => {
    setLastChange(canvas);
  };

  const habiliteEraseTool = () => {
    setTool("erase");
    setHabiliteDraw(false);
    setHabiliteMarker(false);
    setOpenDrawTools(!openDrawTools);
  };

  const handleMarkToolsOpen = () => {
    setOpenMarkTools(!openMarkTools);
    setOpenDrawTools(false);
  };

  const createElement = (id, x1, y1, x2, y2, type, color = colorDraw) => {
    // console.log(id, x1, y1, x2, y2, type, color);
    switch (type) {
      case "line":
      case "rectangle":
        const roughElement =
          type === "line"
            ? generator.line(x1, y1, x2, y2, {
                fillStyle: "solid",
                strokeWidth: 5,
                stroke: color,
                roughness: 0,
              })
            : generator.rectangle(x1, y1, x2 - x1, y2 - y1, {
                fillStyle: "solid",
                strokeWidth: 5,
                stroke: color,
                roughness: 0,
              });
        return { id, x1, y1, x2, y2, type, roughElement };

      case "ellipse":
        // const area = (x1 - x2 * y1 - y2 * Math.PI) * -1;

        const roughElementCircle = generator.ellipse(
          x1,
          y1,
          (x2 - x1) * 2,
          (y2 - y1) * 2,
          {
            fillStyle: "solid",
            strokeWidth: 5,
            stroke: color,
            roughness: 0,
          }
        );
        // console.log(roughElementCircle);
        return { id, x1, y1, x2, y2, type, roughElement: roughElementCircle };

      case "pencil":
        return { id, type, points: [{ x: x1, y: y1 }], color };

      default:
      // throw new Error(`Type not recognised: ${type}`);
    }
  };

  const data = {
    habiliteDraw,
    habiliteDrawing,

    habiliteEraseTool,
    createElement,
    undo,
    colorDraw,
    colorMarker,

    handleColor,
    habiliteMarker,
    handleHabiliteMarker,
    openMarkTools,
    handleMarkerColor,
    handleMarkToolsOpen,
    notes,
    addNewNote,
    handleMoveOneNote,
    handleResizeNote,
    handleTextOneNote,
    removeOneFromList,
    handleSave,
    handleErase,
    openDrawTools,
    handleDrawTools,
    lastChange,
    handleTurn,
    tool,
    habiliteDrawingLine,
    habiliteDrawingRectangle,
    habiliteDrawingCircle,
    elements,
    setElements,
    undoForm,
    redoForm,

    handleErasedMode,
    activeErasedMode,

    habiliteCanvas,
    handleSize,
    width,
    height,
    loadData,
    reading_id,
    handleReadingId,
    disabledZoom,
    handleZoom,
    closeZoom,
    scale,
    pizarraCanvas,
    setPizarraCanvas,
    handlePizarra,
    pristinePizarra,
    setPristinePizarra,
    DisableCanvas,
    movePizarra,
    positionPizarra,
    elementsPizarra,
    setElementsPizarra,
    setFirstSave,
    setIdNotes,
  };

  return (
    <DrawerContext.Provider value={data}>{children}</DrawerContext.Provider>
  );
};

export { DrawerProvider };

export default DrawerContext;
