import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
    ContenedorLectura:{
        width:'502px',
                  [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
    },
    ContainerTitulo:{
        margin:'0 0 34px 0',
        '& .Titulo':{
            letterSpacing:'0px',
            color: '#D31A3C',
            margin:'0',
            textAlign:'center',
            font:'43px fredoka one',
            lineHeight:'52px',
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        position:'relative',
        '& .Lectura':{
            margin:'0 0 36px 0',
            color:'#000',
            font:'18px lato',
            lineHeight :'28px',
            letterSpacing: '0.17px',
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .Esp':{
            margin:'0',
            padding:'0 8% 0 0'
        },
        '& .Conejo':{
            position:'relative'
        },
        '& .Esp2':{
            position:'absolute',
            right:'135px',
            top:'135px',
        },

        '& .Imagen':{
            width:'100%', 
            
        },  
        '& .Imagen2':{
            width:'100%',
            height:'312px',
            [theme.breakpoints.down(502)]:{
                height:'294px',
            },
            [theme.breakpoints.down(350)]: {
                height: "244px",
              },
        }, 
        '& .Imagen3':{
            width:'75%', 
        }, 
      
    }
}));

const Sin_importar_las_diferencias = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>

            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                        <h2 className="Titulo ">Sin importar las diferencias</h2>

                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                       <p className='Lectura'>
                        Era el primer día de clases. La lección de primer grado estaba a cargo del profesor 
                        Alejo, el conejo, quien dijo a sus alumnos: 
                        <br/><br/>
                        —¿Saben qué día es hoy? 
                        <br/><br/>
                        —¡Día de crecimiento de pelo! —contestaron los conejitos. 
                        <br/><br/>
                        —¡Así es! Tienen que encontrar un compañero para abrazarse. El abrazo es la mejor manera de hacer crecer nuestro pelo <br/>—dijo mientras daba una vuelta para que sus alumnos vieran su suave pelaje.
                       </p>                   
                    </Grid>
                    <Grid container item justifyContent='center'>
                        <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img1ro/Sesion13/1.png" alt="Tarjeta" />
                    </Grid>
                    
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container item >
                        <p className='Lectura'>
                            Los conejitos, un poco cegatones aún por la edad y chocando 
                            unos con otros, buscaban entre sus compañeros. Poco a poco, 
                            encontraron a quien abrazar. Todos, menos Irene, la liebre.
                        </p>
                    </Grid>

                    <Grid container xs={12}>
                    <Grid container xs={7}>
                        <p className="Lectura Esp">
                            Irene tenía el doble de tamaño de sus compañeros y un pelaje completo. Algunos conejitos como Bruno y Brenda tenían
                            un poco de miedo.
                            <br />
                            <br />
                            —¡No la quiero abrazar! —gritó Brenda. <br />
                            <br />
                            Triste, Irene decidió alejarse. Hasta que Tomasita le dijo:
                        </p>
                        </Grid>
                        <Grid container xs={5}>
                        <img
                            className="Imagen2"
                            src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img1ro/Sesion13/2.png"
                            alt="Tarjeta"
                        />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container item justifyContent='center' >
                        <p className='Lectura'>
                            —Yo no tengo muchos amigos porque soy más pequeña y casi 
                            no tengo pelo. ¿Quieres ser mi compañera? <br/><br/>
                            
                            —¡Claro! Lo importante no es si somos distintos, sino que nos 
                            aceptemos. <br/><br/>
                            
                            ¿Lista? Se abrazaron. Tomasita sintió una cálida sensación de amistad 
                            y se convirtió en la conejita con el pelaje más bonito.
                        </p>
                        <img className="Imagen3" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img1ro/Sesion13/3.png" alt="Tarjeta" />
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container >
                        <p className='Lectura'>
                            Irene y Tomasita entendieron que las diferencias no nos hacen extraños, sino 
                            únicos.
                        </p>
                    </Grid>
                    
                </Grid>
            </Grid>
    </Grid>
    );
}
 
export default Sin_importar_las_diferencias;
