import React, { useContext, useState } from "react";
import ModalCodigoClase from "./ModalIngresarCodigo";
import AuthContext from "../../../auth/AuthContext";
import axios from "../../../services/axios";
import Loader from "../../Loader/Loader";
import { completeRegisterByCode } from "../../models/login";
import types from "../../../types/types";
import ModalMessage from "../../Modals/ModalMessage";

const RegisterByCode = ({ modalCodeOpen, handleModalOpen }) => {
  const { user, dispatch } = useContext(AuthContext);

  const [modalMessageOpen, setModalMessageOpen] = useState(false);

  const [messageModal, setMessageModal] = useState("");

  const handleCode = (code) => {
    handleModalOpen(false);
    setLoading(true);
    const body = {
      codigo_clase: code,
      id_usuario: user?.userId,
      codigo: user?.codigo?.codigo,
    };
    axios
      .post(`/user/codes/inscripcion/set`, body)
      .then((response) => {
        if (response.status === 200) {
          const data = response.data.resultado?.userLogin;
          localStorage.setItem("token", data.token_usuario);
          dispatch({
            type: types.login,
            payload: completeRegisterByCode(data),
          });
        }
      })
      .catch((error) => {
        if (error.response) {
          // Request made and server responded
          const errorData = error.response.data;
          const mensaje =
            errorData?.detalles?.msg ||
            "Ha ocurrido un error al consumir los servicios, inténtelo de nuevo más tarde.";
          setMessageModal(mensaje);
          setModalMessageOpen(true);
        } else {
          // Something happened in setting up the request that triggered an Error
          setMessageModal("Ha ocurrido un error, inténtelo de nuevo más tarde");
          setModalMessageOpen(true);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const [loading, setLoading] = React.useState(false);

  return (
    <>
      <ModalCodigoClase
        open={modalCodeOpen}
        handleClose={handleModalOpen}
        handleCode={handleCode}
      />
      <ModalMessage
        open={modalMessageOpen}
        handleClose={() => {
          setModalMessageOpen(!modalMessageOpen);
        }}
        title={messageModal}
      />
      {loading && <Loader />}
    </>
  );
};

export default RegisterByCode;
