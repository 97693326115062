import React from 'react';
import { Grid,makeStyles, Button}       from "@material-ui/core";
import { withStyles } from '@material-ui/core/styles';
import Dialog               from '@material-ui/core/Dialog';
import DialogActions        from '@material-ui/core/DialogActions';
import DialogContent        from '@material-ui/core/DialogContent';
import DialogTitle          from '@material-ui/core/DialogTitle';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';

const StyledMenuItem = withStyles((theme) => ({
  root: {
    '&:focus': {
      backgroundColor: theme.palette.primary.main,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

const useStyles = makeStyles(theme =>({
  MuiDialogContainer: {
    borderRadius: '61px',
    height: "400px",
    width: "70%",
    backgroundColor: "#FEFAF1",
    border: "#707070 2px solid",
    backdropFilter: "blur(3px)",
    [theme.breakpoints.down(1568)]:{
      height: "300px",
      maxWidth: "75%",
    },
    [theme.breakpoints.down(468)]:{
      height: "250px",
      maxWidth: "80%",
    },
  },
  continueButton:{
    marginBottom:"20px",
    textTransform: 'none',
    height:'62px',
    width:'279px',  
    background: '#7A54BA',
    boxShadow: '0px 3px 6px #00000029',
    borderRadius: '61px',
    color: '#FFFFFF',
    font: 'bold 20px lato ',
    '&:hover':{
        background: '#dd78fb',
    },
    [theme.breakpoints.down(1368)]:{
      height:'52px',
      width:'229px',
    },
    [theme.breakpoints.down(1068)]:{
      height:'52px',
      width:'60%',
    },
    [theme.breakpoints.down(769)]:{
      width: "56.5%"
    },
  },
  gridAudioContainer: {
    height: "100%"
  },
  audioControls: {
    width: "80%",
    border: "2px solid #00DAC9",
    borderRadius: "35px",
    
    [theme.breakpoints.down(769)]:{
      width: "90%"
    },
  }

}))

const Practica = (props) => {
  const{ grade, source, index, } = props
  const classes = useStyles ()
  const [finished, setFinished] = React.useState(true)
  const [open, setOpen] = React.useState(false)
  const handleCloseDialog = () => {
    setOpen(false)
  }

  return(
    <>
      <StyledMenuItem key={index} onClick={()=> {setOpen(true)}} >
        <ListItemText primary={`${grade}° grado`}/>
      </StyledMenuItem>
      <Dialog open={open} classes={{paper: classes.MuiDialogContainer}} disableEscapeKeyDown >
        <DialogTitle>
          <Grid container justifyContent="center" alignItems="center">
            <p>Grado: {grade}</p>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Grid container justifyContent="center" alignItems="center" className={classes.gridAudioContainer}>
            <audio controls autoPlay controlsList="nodownload" onEnded={() =>{ setFinished(false)}} className={classes.audioControls}>
              <source src={source} type="audio/mp3"></source>
            </audio>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container justifyContent="center" alignItems="center">
            <Button onClick={ handleCloseDialog } disabled={finished} id="BotonVolver" className={classes.continueButton} >Volver</Button>
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default Practica;
