import React, { useContext } from "react";
import { makeStyles, Toolbar, AppBar, Hidden } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";

import PearsonResponsive from "../../imgs/PearsonLogo_responsive.svg";
import PearsonLogoNavbar from "../../imgs/PearsonLogo_Navbar.svg";
import cdlLogo from "../../imgs/CL_LOGO_FINAL_Login 1.png";

import { Link } from "react-router-dom";

import "animate.css";

import Popover from "@material-ui/core/Popover";
import AuthContext from "../../../auth/AuthContext";
import UsabilityContext from "../../../usability/UsabilityContext";

const useStyles = makeStyles((theme) => ({
  link: {
    textDecoration: "none",
  },
  offsete: {
    marginTop: "79.51px",
  },
  BotonMapa: {
    height: "34px",
    width: "101px",
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    border: "3px solid #F2624F",
    borderRadius: "20px",
    color: "#F2624F",
    textTransform: "capitalize",
    font: " 500 17px Lato;",
    "&:hover": {
      background: "#F2624F",
      color: "#FFFF",
    },
  },
  ContenedorImg: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "34px",
    width: "34px",
    right: "9px",
    borderRadius: "50%",
    position: "relative",
    background: "#F2624F",
  },
  Mapaimg: {
    width: "16.89px",
    height: "20.33px",
  },

  Nav: {
    width: "100%",
    padding: "0px",
    background: "#84E9DF",
    [theme.breakpoints.up(1920)]: {
      minHeight: "79.51px",
    },
    [theme.breakpoints.down(1161)]: {
      minHeight: "79.51px",
    },
    minHeight: "79.51px",
  },

  NavIzquierdo: {
    display: "flex",
    height: "79.51px",
    flexDirection: "row",
    alignItems: "center",
    flexGrow: "10",
    [theme.breakpoints.up(1920)]: {
      height: "79.51px",
    },
    [theme.breakpoints.down(1161)]: {
      height: "79.51px",
    },
    [theme.breakpoints.down("md")]: {
      justifyContent: "space-around",
      alignItems: "center",
    },
  },
  MenuIcon: {
    color: "#969D9F",
    fontSize: "40px",
  },

  logoPearson: {
    height: "70px",
    objectFit: "cover",
    // width: "121.6px",
    paddingRight: "70.89px",
    paddingLeft: "63px",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  logoPearsonResponsive: {
    display: "none",
    height: "55px",
    objectFit: "cover",
    // width: "51.17px",
    [theme.breakpoints.down("md")]: {
      display: "flex",
    },
  },
  insignias: {
    alignItems: "center",
    display: "flex",
    width: "87px",
    height: "34px",
    background: "#fff",
    marginRight: "17.85px",
    border: "2px solid",
    borderRadius: "20px",
    [theme.breakpoints.down(1367)]: {
      width: "77px",
      height: "24px",
    },
  },
  Estrella: {
    borderColor: "#935D8B",
    // "&:hover": {
    //   class: "animate__animated animate__bounce",
    //   width: "77px",
    //   height: "24px",
    // },
    // animate__animated: "animate__pulse",
  },
  Corazon: {
    borderColor: "#B64F88",
  },
  Corona: {
    borderColor: "#633F79",
  },
  NavCentro: {
    display: "flex",
    height: "79.51px",
    [theme.breakpoints.up(1920)]: {
      height: "79.51px",
    },
    [theme.breakpoints.down(1161)]: {
      height: "79.51px",
    },
    background: "#00DAC9",
    alignItems: "center",
    justifyContent: "center",
    flexGrow: "1",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  NavDerecho: {
    display: "flex",
    height: "79.51px",
    [theme.breakpoints.up(1920)]: {
      height: "79.51px",
    },
    [theme.breakpoints.down(1161)]: {
      height: "79.51px",
    },
    background: "#FDDC7A",
    flexGrow: "3",
    margin: "0px",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  NavDerechoTexto: {
    display: "flex",
    flexDirection: "column",
    flexGrow: "2",
    justifyContent: "center",
    textAlign: "right",
  },
  ContainerAvatar: {
    display: "flex",
    flexGrow: "1",
    alignItems: "center",
  },
  texto: {
    height: "19px",
    margin: "0px ",
    letterSpacing: "0.15px",
    color: "#2C2B2B",
    marginRight: "11px",
    font: " bold 16px Lato;",
  },
  Alumno: {
    letterSpacing: "0.15px",
    font: " 900 16px Lato;",
  },
  Avatar: {
    height: "49px",
    width: "49px",
    border: "3px solid #FFFFFF",
    borderRadius: "500px",
    [theme.breakpoints.down(1367)]: {
      height: "40px",
      width: "40px",
    },
    [theme.breakpoints.down(850)]: {
      height: "49px",
      width: "49px",
    },
  },

  insigniasimg: {
    right: "2px",
    position: "relative",
    width: "36.5px",
    height: "36.5px",
    [theme.breakpoints.down(1367)]: {
      width: "26.5px",
      height: "26.5px",
    },
  },

  puntos: {
    margin: "0px",
    width: "31px",
    height: "22px",
    font: " 600 18px Lato ",
    left: "6px",
    position: "relative",
    overflow: "hidden",
    color: "#000",
  },
  toggable: {
    position: "relative",
    minWidth: "320px",
    width: "100%",
  },

  popover: {
    pointerEvents: "none",
  },
  paper: {
    padding: theme.spacing(1),
    borderRadius: "0px 0px 30px 30px",
    [theme.breakpoints.down(650)]: {
      padding: theme.spacing(0),
    },
  },
  gridBadge: {
    width: "420px",
    marginBottom: "30px",
    height: "40px",
    [theme.breakpoints.down(650)]: {
      width: "420px",
      marginBottom: "25px",
      height: "30px",
    },
    [theme.breakpoints.down(425)]: {
      width: "310px",
    },
  },
  badgeText: {
    // width: "120px",
    font: " bold 22px Lato;",
    [theme.breakpoints.down(650)]: {
      font: " bold 16px Lato;",
    },
  },
  imgageBadge: {
    // right: "2px",
    // position: "relative",
    marginRight: "30px",
    width: "50px",
    height: "50px",
    [theme.breakpoints.down(650)]: {
      width: "35px",
      height: "35px",
      marginRight: "20px",
    },
  },
}));

const NavBar = () => {
  const classe = useStyles();
  const anchorEl = null

  const handlePopoverClose = () => {
  };

  const open = Boolean(anchorEl);
  const {
    user: { name, firstLastName, secondLastName, escuela },
  } = useContext(AuthContext);

  const {
    handleMenuInMobile,
    openMenuInMobile,
    handleMenuInDesktop,
    openMenuInDesktop,
  } = useContext(UsabilityContext);

  return (
    <>
      <AppBar id="navBar" className={classe.Nav}>
        <Toolbar className={classe.Nav}>
          <div className={classe.NavIzquierdo}>
            <Hidden only={["xl", "lg"]}>
              <MenuIcon
                className={classe.MenuIcon}
                onClick={handleMenuInMobile}
              />
            </Hidden>
            <img
              className={classe.logoPearson}
              src={PearsonLogoNavbar}
              alt=""
            />
            <img
              className={classe.logoPearsonResponsive}
              src={PearsonResponsive}
              alt=""
            />
            <Hidden only={["xl", "lg"]}>
              <Popover
                id="mouse-over-popover"
                className={classe.popover}
                classes={{
                  paper: classe.paper,
                }}
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
              ></Popover>
            </Hidden>

            <Hidden only={["xl", "lg"]}>
              <Link
                className={classe.link}
                to="/admin-inst/perfil"
                onClick={() => {
                  if (openMenuInMobile) handleMenuInMobile();
                  if (openMenuInDesktop) handleMenuInDesktop();
                }}
              >
                <img
                  className={classe.Avatar}
                  src={escuela?.avatar || cdlLogo}
                  alt="Avatar"
                />
              </Link>
            </Hidden>
          </div>

          <div className={classe.NavDerecho}>
            <div className={classe.NavDerechoTexto}>
              <p className={`${classe.Alumno} ${classe.texto}`}>
                {name} {firstLastName} {secondLastName}
              </p>
              <p className={`${classe.Grado} ${classe.texto}`}>
                Administrador Institucional
              </p>
            </div>
            <div className={classe.ContainerAvatar}>
              <Link
                className={classe.link}
                to="/admin-inst/perfil"
                onClick={() => {
                  if (openMenuInMobile) handleMenuInMobile();
                  if (openMenuInDesktop) handleMenuInDesktop();
                }}
              >
                <img
                  className={classe.Avatar}
                  src={escuela?.avatar || cdlLogo}
                  alt="Avatar"
                />
              </Link>
            </div>
          </div>
        </Toolbar>
      </AppBar>

      <div className={classe.offsete}></div>
    </>
  );
};

export default NavBar;
