import { makeStyles, Grid } from "@material-ui/core";
import React from "react";
import { Link } from "react-router-dom";
import StarIcon from "@mui/icons-material/Star";

import ChatContext from "./Context/ChatContext";
import ChatListItem from "./ChatListItem";
import ChatListItemDefault from "./ChatListItemDefault";
const useStyles = makeStyles((theme) => ({
  barContainer: {
    display: "flex",
    gridArea: "bar",
    width: "100%",
    flexWrap: "nowrap",
    height: "100%",
    background:
      "transparent linear-gradient(164deg, #F3CD52 0%, #9CE6DF 100%) 0% 0% no-repeat padding-box",
    boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.5)",
    [theme.breakpoints.up("lg")]: {
      display: "flex",
      flexWrap: "nowrap",
      overflow: "auto",
      "&::-webkit-scrollbar": {
        width: "auto",
      },
      "&::-webkit-scrollbar-track": {
        backgroundColor: "#84E9DF20",
        // backgroundColor: "#7A54BA20",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#84E9DF",
        // backgroundColor: "#7A54BA",
        borderRadius: 5,
      },
      // paddingTop: "25%",
    },
  },
  barContainerInChat: {
    display: "none",
    gridArea: "bar",
    width: "100%",
    flexWrap: "nowrap",
    height: "100%",
    background:
      "transparent linear-gradient(164deg, #F3CD52 0%, #9CE6DF 100%) 0% 0% no-repeat padding-box",
    boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.5)",
    [theme.breakpoints.up("lg")]: {
      display: "flex",
      flexWrap: "nowrap",
      overflow: "auto",
      // paddingTop: "25%",
      "&::-webkit-scrollbar": {
        width: "auto",
      },
      "&::-webkit-scrollbar-track": {
        backgroundColor: "#84E9DF20",
        // backgroundColor: "#7A54BA20",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#84E9DF",
        // backgroundColor: "#7A54BA",
        borderRadius: 5,
      },
    },
  },
  listChat: {
    width: "100%",
    height: "100%",
    background:
      "transparent linear-gradient(164deg, #F3CD52 0%, #9CE6DF 100%) 0% 0% no-repeat padding-box",
    boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.5)",
    alignContent: "flex-start",
    [theme.breakpoints.up("lg")]: {
      display: "flex",
      flexWrap: "nowrap",
      overflow: "auto",
      flexDirection: "column",
      justifyContent: "flex-start",
      // paddingTop: "25%",
      "&::-webkit-scrollbar": {
        width: "auto",
      },
      "&::-webkit-scrollbar-track": {
        backgroundColor: "#84E9DF20",
        // backgroundColor: "#7A54BA20",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#84E9DF",
        // backgroundColor: "#7A54BA",
        borderRadius: 5,
      },
    },
  },
  iconStar: {
    color: "#F3CD52",
  },
  iconStarOff: {
    color: "#00000050",
  },
  iconNewMessage: {
    transform: "rotate(0deg)",
    borderRadius: "24.6px",
    backgroundColor: "#F6644B",
    width: "20px",
    height: "20px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "8px",
    /* UI Properties */
    // background: "#f00 0% 0% no-repeat padding-box",
    color: "#fff",
    [theme.breakpoints.up("lg")]: {
      fontSize: "12px",
      minWidth: "25px",
      height: "25px",
      borderRadius: "25px",
    },
  },
  barFeaturedMessages: {
    background: "#E9FBF9 0% 0% no-repeat padding-box",
    boxShadow: "0px 3px 6px #00000029",
    display: "flex",
    gap: "20px",
    padding: "20px",
    justifyContent: "flex-start",
    color: "#007FA3",
  },
  //

  link: {
    cursor: "pointer",
    textDecoration: "none",
    width: "100%",
  },
}));
const ChatListBar = ({ inChat }) => {
  const classes = useStyles({});
  const { chats, handleFilterFavorites, filterFavorites } =
    React.useContext(ChatContext);
  return (
    <Grid
      container
      item
      direction="column"
      className={inChat ? classes.barContainerInChat : classes.barContainer}
    >
      <Link className={classes.link} to="#" onClick={handleFilterFavorites}>
        <Grid
          container
          item
          direction="row"
          justifyContent="center"
          className={classes.barFeaturedMessages}
        >
          <StarIcon
            className={filterFavorites ? classes.iconStar : classes.iconStarOff}
          />
          <span>Mensajes Destacados</span>
        </Grid>
      </Link>

      <Grid container item justifyContent="center" className={classes.listChat}>
        {chats.length > 0 ? (
          <>
            {chats?.map((chat, index) => (
              <ChatListItem chat={chat} key={index} />
            ))}
          </>
        ) : (
         <ChatListItemDefault/>
        )}
      </Grid>
    </Grid>
  );
};

export default ChatListBar;
