import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
    ContenedorLectura:{
            width:'502px',
                  [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
    },
    ContainerTitulo:{
        margin:'0 0 61px 0',
        '& .Titulo':{
            letterSpacing: '0px',
            color: '#FF0892',
            margin:'0',
            textAlign:'center',
            font:'43px fredoka one',
            lineHeight:'52px',
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            margin:'0 0 36px 0 ',
            color:'#000',
            font:'18px lato',
            lineHeight :'28px',
            letterSpacing: '0.17px',
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .Esp':{
            margin:' 0 ',
        },
        '& .SubTitulo':{
            color:'#7940DA',
            margin:'0',
            lineHeight :'28px',
            font:'bold 26px lato',
            letterSpacing: '0.24px',
            [theme.breakpoints.down(502)]:{
                font:'bold 18px lato',
                lineHeight :'24px',
                letterSpacing: '0.21px',
            },
        },
        '& .TextImg':{
            margin:'22px 0 0 0',
            alignSelf:'flex-start',
            color:'#717476',
            font:' 16px lato',
            lineHeight :'25px',
            letterSpacing: '0.15px',
            [theme.breakpoints.down(502)]:{
                font:' 11px lato',
                lineHeight :'14px',
                letterSpacing: '0.10px',
            },
        },

        '& .li':{
            margin:'0 0 4px 0',
        },

        '& .ul':{
            listStyle:'alphanumeric',
            margin:'0',
            color:'#823CE2',
            padding:'0 0 0 19px',
            font:'18px lato',
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
            }
        },

        '& .Imagen':{
            width:'543px',
            [theme.breakpoints.down(502)]:{
                width:'100%',
            },
        },
    },

}));

const Los_números_romanos = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>

            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >
                    Los números romanos
                    </h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                      <p className="Lectura">
                        <h3 className='SubTitulo'>Algunas curiosidades sobre su uso </h3><br/>

                        Como sabrás, actualmente utilizamos un sistema numérico llamado <em>decimal</em>, cuyos 
                        dígitos (del 0 al 9) nos permiten escribir cualquier número natural.<br/><br/>

                        Los <em>números romanos</em> son otro sistema que (como su nombre lo indica) se desarrolló 
                        en la antigua Roma. Aunque ahora ya no tienen una aplicación matemática, todavía 
                        podemos encontrarlos en ciertos ámbitos. Por ejemplo, aparecen en relojes de 
                        manecillas, tomos y capítulos de libros, nombres de reyes y papas, ediciones de 
                        eventos, celebraciones o aniversarios, entre otros. Se podría decir que son una 
                        manera elegante o culta de numerar en la actualidad.
                      </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <Grid container justifyContent="center" alignItems='flex-end'>
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img2do/Sesion10/2.png" alt="Tarjeta" />
                        </Grid>
                        <Grid container >
                            <p className='TextImg'> Te recomendamos que te aprendas al menos los números y combinaciones básicas del 
                            sistema romano, ya que lo seguirás hallando en diversos sitios.
                            </p>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                      <p className="Lectura">
                        <h3 className='SubTitulo'>Las complicaciones de los números romanos </h3><br/>
                         Este sistema consta de siete letras mayúsculas y a cada una se le asigna un valor, 
                         como puedes observar en esta tabla:
                      </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <Grid container justifyContent="center" alignItems='flex-end'>
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img2do/Sesion10/3.png" alt="Tarjeta" />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container item sm={12}>
                    <p className='Lectura'>El resto de los números se forma con base en las siguientes reglas:</p>       
                    <ul  className="ul">
                          <li className="li"><p className="Lectura Esp">Se suman los valores de cada letra. Por ejemplo: XV se compone de la suma de 10 + 5 y es igual a 15.</p></li>
                          <li className="li"><p className="Lectura Esp">Las letras de mayor valor se escriben siempre a la izquierda y las de menor, a la derecha. Por ejemplo: VII equivale a 5 + 2 y es igual a 7.</p></li>
                          <li className="li"><p className="Lectura Esp">Existen ciertas excepciones a la regla anterior: la letra de menor valor va a la izquierda cuando se trata del 4 (IV), el 9 (IX), el 40 (XL), el 90 (XC), el 400 (CD) y el 900 (CM). En estos casos a la letra de mayor valor se le resta el de la ubicada a su izquierda. Por ejemplo: IV representa la resta 5 – 1.</p></li>
                          <li className="li"><p className="Lectura Esp">Las letras I, X, C y M pueden repetirse hasta tres veces seguidas. Por ejemplo: XXXII, donde se suman 10 + 10 + 10 + 1 + 1 y es igual a 32.</p></li>
                          <li className="li"><p className="Lectura Esp">Las letras V, L y D sólo pueden escribirse una vez. Por ejemplo: LV equivale a 50 + 5 y es igual a 55.</p></li>
                      </ul>
                    </Grid>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                        En los números romanos no es posible representar el 0, ya que ninguna letra está 
                        asignada a ese valor.<br/><br/>
                        
                        Otra de las desventajas de este sistema es que la representación de algunos números 
                        puede llegar a ser muy larga. Por ejemplo: MMDCCCLXXXVIII corresponde a 2 888 
                        (dos mil ochocientos ochenta y ocho).<br/><br/> 
                        
                        ¿Qué opinas? ¿Crees que el sistema decimal es más sencillo y práctico?
                        </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <Grid container justifyContent="center" alignItems='flex-end'>
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img2do/Sesion10/1.png"alt="Tarjeta" />
                        </Grid>
                        <Grid container >
                            <p className='TextImg'> 
                            Se dice que con estos 10 números podemos escribir cualquier cantidad. ¿Quieres intentarlo?
                            </p>
                        </Grid>

                    </Grid>
                </Grid>
        </Grid>
    </Grid>
    );
}
 
export default Los_números_romanos;
