import React, { useContext }  from 'react';
import { makeStyles,Grid}     from '@material-ui/core';
import NavBar                 from '../../NavBar';
import MenuIzquierdo          from '../../MenuIzquierdo';
import BadgesReading          from './BadgesReading';
import BadgesSocioEmocional   from './BadgesSocioEmocional';
import BadgesQuiz             from './BadgesQuiz';
import BadgesMaster           from './BadgesMaster';
import { Link }               from "react-router-dom";
import AuthContext            from "../../../auth/AuthContext";

import QuizSegundo            from './QuizSegundo';
import QuizTercero            from './QuizTercero';
import QuizCuarto             from './QuizCuarto';
import QuizQuinto             from './QuizQuinto';
import QuizSexto              from './QuizSexto';

const useStyles = makeStyles(theme=>({
  grid1:{    
    zIndex:'3'
    
  },
  grid2:{    
    zIndex:'2'
    
  },
  grid3:{    
    boxSizing:'border-box',
    zIndex:'1',
    paddingLeft:'3%',
    [theme.breakpoints.down('md')]:{
      paddingLeft:'0px',
    }
    
  },
  link:{
    cursor:'pointer',
    textDecoration:'none',
    position:'relative',
    top:'15px',
    left:'15px',
    [theme.breakpoints.down(600)]:{
      top:'85px',
    },    
    '& .Button':{
        cursor:'pointer',
        height:'31px',
        width:'155px',
        background:'#FEFAF1',
        color:'#F6644B',
        font:'16px lato',
        borderRadius:'20px',
        border:' 1px solid #F2624F',
        [theme.breakpoints.down(600)]:{
          font:'14px lato',
          height:'31px',
          width:'135px',
        }, 
    }
},

}))

function BadgesContainer() {
  const classes= useStyles()
  const {
    user: { grade, insignias },
  } = useContext(AuthContext);

  const [lectora, emocional, evaluacion ] = insignias

  const renderQuizContainer = () => {
    switch (grade) {
      case "primero":
        return(
          <BadgesQuiz
            evaluationBadges={evaluacion?.total}
          />
        )
      
      case "segundo":
        return(
          <QuizSegundo
            evaluationBadges={evaluacion?.total}
          />
        )
      
      case "tercero":
        return(
          <QuizTercero
            evaluationBadges={evaluacion?.total}
          />
        )
      
      case "cuarto":
        return(
          <QuizCuarto
            evaluationBadges={evaluacion?.total}
          />
        )
      
      case "quinto":
        return(
          <QuizQuinto
            evaluationBadges={evaluacion?.total}
          />
        )
      
    
      default:
        return(
          <QuizSexto
            evaluationBadges={evaluacion?.total}
          />
        )
        
    }
  }

  console.log(insignias, "insgi")
  return (
    <Grid container >
      <Grid item className={classes.grid1} sm={12}> 
        <NavBar/>
      </Grid>
      <Grid item className={classes.grid2} sm={1}> 
        <MenuIzquierdo/>
      </Grid>
      <Grid item className={classes.grid3} xs={12}>
        <Link className={classes.link} to='Perfil_del_alumno'>
            <button className="Button">Regresar al perfil</button>
        </Link>
        <BadgesReading
          comprenBadges={lectora?.total}
        />
        <BadgesSocioEmocional
          socioBadges={emocional?.total}
        />
        {renderQuizContainer()}
        <BadgesMaster
          showMaster={lectora?.total > 364}
        />
      </Grid>
    </Grid>
  );
}

export default BadgesContainer;
