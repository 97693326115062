import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
    ContenedorLectura:{
        width:'502px',
                  [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
    },
    ContainerTitulo:{
        margin:'0 0 34px 0',
        '& .Titulo':{
            letterSpacing: '0px',
            color: '#F6644B',
            margin:'0',
            textAlign:'center',
            font:'43px fredoka one',
            lineHeight:'52px',
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 10px 0',
        '& .Lectura':{
            margin:'0',
            color:'#000',
            font:'18px lato',
            lineHeight :'28px',
            letterSpacing: '0.17px',
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .Lectura2':{
            margin:'0 0 36px 0',
            color:'#000',
            font:'bold 20px lato',
            padding:' 0 35px 0 0',
            lineHeight :'28px',
            letterSpacing: '0.17px',
            [theme.breakpoints.down(502)]:{
                padding:'0',
                margin:'0',
                font:'bold 18px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
        },
        '& .SubTitulo':{
            color:'#F6644B',
            lineHeigth:'35px',
            margin:'0',
            font:'bold 26px lato',
            [theme.breakpoints.down(502)]:{
                font:'bold 18px lato',
            },
        },
        '& .SubTitulo2':{
            position:'absolute',
            color:'#000000',
            lineHeigth:'35px',
            margin:'0',
            font:'bold 20px lato',
            [theme.breakpoints.down(502)]:{
                font:'bold 12px lato',
            },
        },
        '& .SubTitulo3':{
            color:'#8FB63E',
            lineHeigth:'35px',
            margin:'0',
            font:'bold 25px lato',
            [theme.breakpoints.down(502)]:{
                font:'bold 15px lato',
            },
        },
        '& .SubTitulo4':{
            color:'#8FB63E',
            lineHeigth:'35px',
            margin:'0',
            font:'bold 35px lato',
            [theme.breakpoints.down(502)]:{
                font:'bold 20px lato',
            },
        },
        '& .SubTitulo5':{
            color:'#8FB63E',
            lineHeigth:'35px',
            margin:'0 0 0 26%',
            font:'bold 50px lato',
            [theme.breakpoints.down(502)]:{
                font:'bold 30px lato',
            },
        },
        '& .SubTitulo6':{
            color:'#8FB63E',
            lineHeigth:'35px',
            margin:'0',
            font:'bold 90px lato',
            [theme.breakpoints.down(502)]:{
                font:'bold 60px lato',
            },
        },
        '& .SubTitulo7':{
            color:'#000000',
            lineHeigth:'35px',
            margin:'0',
            font:'bold 25px lato',
            [theme.breakpoints.down(502)]:{
                font:'bold 18px lato',
            },
        },
        '& .Pos1':{
            top:'311px',
            left:'7px',
            [theme.breakpoints.down(502)]:{
                top:'177px',
                left:'30px',
            },
        },
        '& .Pos2':{
            top:'67px',
            left:'324px',
            [theme.breakpoints.down(502)]:{
                top:'37px',
                left:'220px',
            },

        },
        '& .Pos3':{
            width:'238px',
            top:'480px',
            left:'281px',
            [theme.breakpoints.down(502)]:{
                width:'138px',
                top:'277px',
                left:'186px',
            },

        },

        '& .TextImg':{
            color:'#717476',
            font:' 16px lato',
            lineHeight :'25px',
            letterSpacing: '0.15px',
            margin:'0 0 20px 0',
            [theme.breakpoints.down(502)]:{
                font:' 11px lato',
                lineHeight :'14px',
                letterSpacing: '0.10px',
            },
        },
        '& .Serpiente':{
            position:'relative'
        },
        '& .Cuadro':{
            background:'#FCFAE6',
            borderRadius:'87px',
            boxSizing:'border-box',
            padding:'26.3px 29px 0px 28px',
            width:'95%',
            height:'416px',
            margin:'0 0 36px 0',
        [theme.breakpoints.down(502)]:{
            borderRadius:'40px',
            height:'370px',
        },
        },
        '& .Imagen':{
            [theme.breakpoints.down(502)]:{
                width:'100%',
            },
        }, 
        '& .Imagen2':{
            width:'95%',
        },  
      
    }
}));

const Animal_o_planta = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>

            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >
                        ¿Animal o planta?
                    </h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                      <p className="Lectura">
                         <h3 className='SubTitulo'>Venustiana</h3><br/> 
                        Antes que nada, buenos días, buenas tardes o buenas noches. Mucho gusto, me llamo 
                        Venustiana. ¿Que quién soy yo? Te cuento un poco. Soy pequeña, roja y vengo de 
                        Estados Unidos. ¿Ya adivinaste?
                      </p>
                    </Grid>
                    <Grid container justifyContent="center" className='Serpiente' sm={12}>
                        <img className="Imagen " src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img1ro/Sesion44/1R.png" alt="Tarjeta" />
                        <p className='Pos1 SubTitulo2'>
                            <span className="SubTitulo3">Bzzzzz</span><br/>
                            ¿Será lo que<br/>
                            creo que es?
                        </p>
                        <p className='Pos2 SubTitulo2'>
                            <span className="SubTitulo3">Bzzzzz</span><br/>
                            Espera.<br/>
                            ¿Escuchas eso?
                        </p>
                        <p className='Pos3 SubTitulo2'>
                            <span className="SubTitulo4">Bzzzzz</span><br/>
                            ¡Sí! ¡Justo lo que creía! ¡Mi comida!
                        </p>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">

                <Grid container sm={12} className="Cuadro">
                      <Grid container item >
                        <p className='SubTitulo5'>
                            Bzzzzz
                        </p>
                      </Grid>
                      <Grid container item >
                        <b className='Lectura2'>
                            Aguanta un poco. Voy a preparar una trampa. La haré roja y con 
                            un olor delicioso. Ahora que lo pienso, también la haré pegajosa
                            para que mi comida no vaya a ningún lado.
                        </b>
                      </Grid>
                      <Grid container item justifyContent='center' >
                        <h3 className='SubTitulo6'>
                            ¡Snap!
                        <h3 className='SubTitulo7'>
                            Mmm… ¡Deliciosa!
                        </h3>
                        </h3>
                      </Grid>
                    </Grid>

                    <Grid container sm={12}>
                      <p className="Lectura">
                        Si aún no sabes quién soy, tal vez me conozcas por mi otro nombre: Venus 
                        Atrapamoscas. ¡Así es! Soy una pequeña planta y mi comida favorita son las 
                        moscas. Algunas personas piensan que soy una planta carnívora y que me las voy a 
                        comer. Esto no es cierto, sólo como moscas para estar bien nutrida.<br/><br/>

                        Las plantas comunes pueden fabricar su comida usando los nutrientes de la tierra y 
                        la energía del Sol, pero la tierra en la que yo vivo es pobre y no me permite crecer 
                        sana. Por eso busco insectos, para estar fuerte.<br/><br/>

                        ¡Mucho gusto!
                      </p>
                    </Grid>

                    <Grid container item justifyContent='center'>
                        <img className="Imagen2" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img1ro/Sesion44/2.png" alt="Tarjeta" />
                    </Grid>

                </Grid>
        </Grid>
    </Grid>
    );
}
 
export default Animal_o_planta ;
