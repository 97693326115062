import { makeStyles, Grid, Button, withStyles } from "@material-ui/core";
import React from "react";
import { Link } from "react-router-dom";
import graph_1 from "./img/graph_1.png";
import graph_2 from "./img/graph_2.png";
import graph_3 from "./img/graph_3.png";
import graph_4 from "./img/graph_4.png";
import graph_5 from "./img/graph_5.png";
import graph_6 from "./img/graph_6.png";
import graph_7 from "./img/graph_7.png";
import graph_8 from "./img/graph_8.png";

import AuthContext from "../../../auth/AuthContext";
import axios from "../../../services/archivos";
import UsabilityContext from "../../../usability/UsabilityContext";

import Tooltip from "@mui/material/Tooltip";
import Typography from "@material-ui/core/Typography";
import ModalMessage from "../../Modals/ModalMessage";

const useStyles = makeStyles((theme) => ({
  Container: {
    padding: "48px 0 48px 0",
    background:
      "linear-gradient(217deg, rgba(255,194,0,0.4962185557816877) 2%, rgba(255,255,255,1) 25%, rgba(255,255,255,0.4962185557816877) 81%, rgba(144,81,177,1) 98%)",
    minHeight: "calc(100vh - 80px)",
  },
  ContainerReportes: {
    background: "#fff",
    maxWidth: "1216px",
    width: "90%",
    minHeight: "70vh",
    // maxHeight: "982px",
    gap: "10px",
    borderRadius: "42px",
    padding: "30px",
    boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.5)",
    "& .Titulo": {
      font: "42px fredoka one",
      margin: "0 0 84px 34px",
    },
  },
  ContainerBarra: {
    height: "498px",
    boxShadow: "0 0 30px 0 rgba(76, 147, 230, 0.1)",
    margin: "0 0 20px 0",
    borderRadius: "8px",
  },
  ContainerLinkinsiginas: {
    textDecoration: "none",

    textAlign: "left",
    "& .ButtonIsg": {
      color: "#000",
      textTransform: "none",
      font: "18px lato",
      letterSpacing: "0.36px",
      margin: "0",
      textAlign: "start",
      [theme.breakpoints.up("md")]: {
        font: " 20px lato",
      },
      [theme.breakpoints.up("lg")]: {
        font: " 22px lato",
      },
      "& p": {
        margin: 0,
      },
    },
    "& .icon": {
      color: "purple",
      marginRight: "20px",
      fontSize: "30px",
      width: "30px",
      height: "30px",
      [theme.breakpoints.up("md")]: {
        width: "40px",
        height: "40px",
        fontSize: "40px",
      },
      [theme.breakpoints.up("lg")]: {
        width: "55px",
        height: "55px",
        fontSize: "50px",
      },
    },
  },
  ButtonIsDowload: {
    textDecoration: "none",
    textAlign: "left",
    color: "#000",
    textTransform: "none",
    font: "18px lato",
    letterSpacing: "0.36px",
    margin: "0",
    [theme.breakpoints.up("md")]: {
      font: " 20px lato",
    },
    [theme.breakpoints.up("lg")]: {
      font: " 22px lato",
    },
    "& p": {
      margin: 0,
    },

    "& .icon": {
      color: "purple",
      marginRight: "20px",
      fontSize: "30px",
      width: "30px",
      height: "30px",
      [theme.breakpoints.up("md")]: {
        width: "40px",
        height: "40px",
        fontSize: "40px",
      },
      [theme.breakpoints.up("lg")]: {
        width: "55px",
        height: "55px",
        fontSize: "50px",
      },
    },
  },

  mainTitle: {
    font: "42px fredoka one",
    textAlign: "center",
    color: "#DC5EA6",
    margin: "10px 0",
    [theme.breakpoints.down("860")]: {
      font: "38px fredoka one",
    },
    [theme.breakpoints.down("321")]: {
      font: "28px fredoka one",
    },
  },
}));

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#f5f5f9",
    font: "34px Fredoka One",
    color: "blue",
    border: "1px solid #dadde9",
  },
}))(Tooltip);

const Reporte_Alumnos = () => {
  const classes = useStyles();
  const { groupToEdit, handleLoading } = React.useContext(UsabilityContext);
  const { user } = React.useContext(AuthContext);
  const [group, setGroup] = React.useState(user?.grupos[0] || {});

  const idEscuela = group?.id_escuela;
  const idUsuario = user?.userId;
  const idDistribEsc = group?.id_distribucion_escuela;

  const [modalMessageOpen, setModalMessageOpen] = React.useState(false);
  const [fileResult, setFileResult] = React.useState();

  const handleCloseMessageModal = () => {
    setModalMessageOpen(!modalMessageOpen);
  };

  React.useEffect(() => {
    setGroup(
      user.grupos.find(
        (grupo) => grupo?.id_distribucion_escuela === Number(groupToEdit)
      ) || user?.grupos[0]
    );
  }, [groupToEdit, user.grupos]);

  const downloadReport3 = () => {
    handleLoading(true);
    axios
      .get(`/profesor/getReport/3?id_distribucion_escuela=${idDistribEsc}`)
      .then((response) => {
        const path = response.data.resultado.path;
        const link = document.createElement("a");
        link.href = path;
        link.setAttribute("download", "image.jpg");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((error) => {
        if (error.response) {
          // Request made and server responded
          const errorData = error.response.data;
          const mensaje =
            errorData?.detalles?.msg ||
            "Ha ocurrido un error al consumir los servicios, inténtelo de nuevo más tarde.";
          setFileResult(mensaje);
          setModalMessageOpen(true);
        } else {
          // Something happened in setting up the request that triggered an Error
          const mensaje =
            "Ha ocurrido un error al consumir los servicios, inténtelo de nuevo más tarde.";
          setFileResult(mensaje);
          setModalMessageOpen(true);
        }
      })
      .finally(() => {
        handleLoading(false);
      });
  };
  const downloadReport7 = () => {
    handleLoading(true);
    axios
      .get(`/profesor/getReport/7?idDistribucionEscuela=${idDistribEsc}`)
      .then((response) => {
        const path = response.data.resultado.path;
        const link = document.createElement("a");
        link.href = path;
        link.setAttribute("download", "image.jpg");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((error) => {
        if (error.response) {
          // Request made and server responded
          const errorData = error.response.data;
          const mensaje =
            errorData?.detalles?.msg ||
            "Ha ocurrido un error al consumir los servicios, inténtelo de nuevo más tarde.";
          setFileResult(mensaje);
          setModalMessageOpen(true);
        } else {
          // Something happened in setting up the request that triggered an Error
          const mensaje =
            "Ha ocurrido un error al consumir los servicios, inténtelo de nuevo más tarde.";
          setFileResult(mensaje);
          setModalMessageOpen(true);
        }
      })
      .finally(() => {
        handleLoading(false);
      });
  };

  React.useEffect(() => {
    setGroup(
      user.grupos.find(
        (grupo) => grupo?.id_distribucion_escuela === Number(groupToEdit)
      ) || user?.grupos[0]
    );
  }, [groupToEdit, user.grupos]);

  return (
    <Grid
      container
      item
      sm={12}
      className={classes.Container}
      alignItems="center"
      justifyContent="center"
    >
      <Grid
        container
        item
        direction="column"
        className={classes.ContainerReportes}
      >
        <h2 className={classes.mainTitle}>Reportes de seguimiento </h2>
        <Grid container item direction="row" alignItems="center">
          <Link
            className={classes.ContainerLinkinsiginas}
            to={{
              pathname: "/docente/reportes/1",
              state: {
                idEscuela: idEscuela,
                idUsuario: idUsuario,
                idDistribEsc: idDistribEsc,
              },
            }}
          >
            <Button className="ButtonIsg">
              <img src={graph_1} alt="graph_1" className="icon" />
              <p>1. Avance general</p>
            </Button>
          </Link>
        </Grid>
        <Grid container item direction="row" alignItems="center">
          <Link
            className={classes.ContainerLinkinsiginas}
            to="/docente/reportes/2"
          >
            <Button className="ButtonIsg">
              <img
                src={graph_4}
                alt="graph_4"
                className="icon"
                style={{ font: "40px" }}
              />
              <p>2. Habilidades</p>
            </Button>
          </Link>
        </Grid>

        <Grid container item direction="row" alignItems="center">
          <HtmlTooltip
            title={
              <React.Fragment>
                <Typography color="inherit">
                  Este reporte te ofrece un seguimiento específico, por
                  estudiante, de cada una de las actividades y de los reactivos
                  que conforman las sesiones del circuito.
                </Typography>
              </React.Fragment>
            }
            placement="top"
            disableFocusListener
          >
            <Button
              className={classes.ButtonIsDowload}
              onClick={downloadReport3}
            >
              <img src={graph_5} alt="graph_3" className="icon" />
              3. Reactivos
            </Button>
          </HtmlTooltip>
        </Grid>

        <Grid container item direction="row" alignItems="center">
          <Link
            className={classes.ContainerLinkinsiginas}
            to="/docente/reportes/4"
          >
            <Button className="ButtonIsg">
              <img src={graph_3} alt="graph_3" className="icon" />
              <p>4. Evaluación diagnóstica</p>
            </Button>
          </Link>
        </Grid>
        <Grid container item direction="row" alignItems="center">
          <Link
            className={classes.ContainerLinkinsiginas}
            to="/docente/reportes/5"
          >
            <Button className="ButtonIsg">
              <img src={graph_2} alt="graph_2" className="icon" />
              <p>5. Evaluación formativa</p>
            </Button>
          </Link>
        </Grid>
        <Grid container item direction="row" alignItems="center">
          <Link
            className={classes.ContainerLinkinsiginas}
            to="/docente/reportes/6"
          >
            <Button className="ButtonIsg">
              <img src={graph_6} alt="graph_6" className="icon" />
              <p>6. Evaluación final</p>
            </Button>
          </Link>
        </Grid>

        <Grid container item direction="row" alignItems="center">
          <HtmlTooltip
            title={
              <React.Fragment>
                <Typography color="inherit">
                  Este reporte integra los resultados numéricos, es decir, los
                  puntos obtenidos a partir de las respuestas acertadas de cada
                  una de las actividades y evaluaciones.
                </Typography>
              </React.Fragment>
            }
            placement="top"
            disableFocusListener
          >
            <Button
              className={classes.ButtonIsDowload}
              onClick={downloadReport7}
            >
              <img src={graph_7} alt="graph_7" className="icon" />
              7. Libreta de notas
            </Button>
          </HtmlTooltip>
        </Grid>

        <Grid container item direction="row" alignItems="center">
          <Link
            className={classes.ContainerLinkinsiginas}
            to="/docente/reportes/8"
          >
            <Button className="ButtonIsg">
              <img src={graph_8} alt="graph_8" className="icon" />
              <p>8. Seguimiento socioemocional</p>
            </Button>
          </Link>
        </Grid>
        <ModalMessage
          open={modalMessageOpen}
          handleClose={handleCloseMessageModal}
          title={fileResult}
          textOfButton="Aceptar"
        />
      </Grid>
    </Grid>
  );
};

export default Reporte_Alumnos;
