import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
    ContenedorLectura:{
        width:'502px',
                  [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
    },
    ContainerTitulo:{
        margin:'0 0 46px 0',
        '& .Titulo':{
            letterSpacing: '0px',
            color: '#1666B3',
            margin:'0',
            font:'43px fredoka one',
            textAlign:'center',
            lineHeight:'52px',
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            margin:'0 0 36px 0',
            color:'#000',
            font:'18px lato',
            lineHeight :'28px',
            letterSpacing: '0.17px',
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },

        '& .SubTitulo':{
            color:'#F05B75',
            margin:'0',
            lineHeight :'28px',
            font:'bold 26px lato',
            letterSpacing: '0.24px',
            [theme.breakpoints.down(502)]:{
                font:'bold 18px lato',
                lineHeight :'24px',
                letterSpacing: '0.21px',
            },
        },
        '& .TextImg':{
            margin:'22px 0 0 0',
            alignSelf:'flex-start',
            color:'#717476',
            font:' 16px lato',
            lineHeight :'25px',
            letterSpacing: '0.15px',
            [theme.breakpoints.down(502)]:{
                font:' 11px lato',
                lineHeight :'14px',
                letterSpacing: '0.10px',
            },
        },
        '& .Imagen':{
            width:'100%',
        },
    },
}));

const Dos_mujeres_latinas_unen_su_talento_musical_para_alegrarnos_la_vida = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>

            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >
                    Dos mujeres latinas<br/> 
                    unen su talento musical<br/> 
                    para alegrarnos la vida
                    </h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                      <p className="Lectura">
                        <h3 className='SubTitulo'>El arte como un refugio en los momentos difíciles </h3><br/>

                         Como sabes, el mundo ha vivido un momento histórico bastante complicado debido a 
                         la pandemia por Covid-19 que inició en 2020. De alguna u otra manera, todas las 
                         personas hemos sufrido las consecuencias y hemos enfrentado situaciones dolorosas 
                         a causa de ella. En momentos así, el arte juega un papel importantísimo, pues nos 
                         brinda sensaciones agradables, recursos para canalizar nuestras emociones y 
                         mensajes de esperanza o alegría.<br/><br/> 
                         
                         Por ejemplo, en 2021 dos mujeres cantantes unieron su talento para invitarnos a 
                         bailar, a sonreír, a tomarnos un momento para apreciar todo aquello que nos rodea, 
                         incluso en los momentos difíciles.<br/><br/> 
                         
                         Nos referimos al dueto conformado por La Marisoul y Lila Downs. Ambas artistas 
                         tienen raíces mexicanas. La primera es la vocalista del grupo <em>folk </em> 
                         mexicoestadounidense La Santa Cecilia. La segunda es una cantautora mexicana que 
                         resalta las culturas y lenguas indígenas. Juntas lanzaron el tema musical titulado 
                         <em> Quiero verte feliz</em>.
                      </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <Grid container justifyContent="center" alignItems='flex-end'>
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img2do/Sesion12/1.png" alt="Tarjeta" />
                        </Grid>
                        <Grid container >
                            <p className='TextImg'>Las artes no distinguen fronteras ni culturas, sino que nos permiten comunicarnos en un 
                            lenguaje universal, como el baile.</p>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container sm={12}>
                      <p className="Lectura"> 
                        Te invitamos a buscar en las redes sociales el video musical de esta canción. Allí 
                        podrás observar la unión de sus estilos en la imagen, la música y el canto. Apreciarás 
                        la vestimenta que portan las cantantes y los músicos que las acompañan, así como los 
                        paisajes y alimentos que se muestran. También conocerás el sonido de la cumbia, un 
                        ritmo que se extiende prácticamente por toda Latinoamérica, y conocerás una 
                        expresión coloquial mexicana que, en el contexto de la canción, expresa euforia.<br/><br/> 
                        
                        ¿Qué te parece la canción? ¿Podrías crear una pieza musical con un mensaje positivo
                        para la gente alrededor del mundo? ¡Inténtalo!
                      </p>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}
 
export default Dos_mujeres_latinas_unen_su_talento_musical_para_alegrarnos_la_vida;
