import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
    ContenedorLectura:{
        width:'502px',
                  [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
    },
    ContainerTitulo:{
        margin:'0 0 34px 0',
        [theme.breakpoints.down(1161)]:{
            margin:'0 0 34px 0',
        },
        '& .Titulo':{
            letterSpacing: '0px',
            color: '#823CE2',
            margin:'0',
            textAlign:'center',
            font:'43px fredoka one',
            lineHeight:'52px',
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            margin:'0 0 36px 0',
            color:'#000',
            font:'18px lato',
            lineHeight :'28px',
            letterSpacing: '0.17px',
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .TextImg':{
            color:'#717476',
            margin:'20px 0 0 0',
            font:' 16px lato',
            lineHeight :'25px',
            letterSpacing: '0.15px',
            [theme.breakpoints.down(502)]:{
                font:' 11px lato',
                lineHeight :'14px',
                letterSpacing: '0.10px',
            },
        },
        '& .Imagen':{
            width:'100%',
        }, 
        '& .Imagen2':{
            width:'93.62%',
        }, 

    }
}));

const Ciudadanía_para_la_niñez = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >
                        Ciudadanía<br/> para la niñez                    
                    </h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                      <p className="Lectura">
                        Se dice que una persona es <em>ciudadana</em> cuando se establece una relación entre ella y el 
                        Estado, que se refuerza mediante los derechos y las obligaciones de ambos. Todas las 
                        personas somos ciudadanas en el mundo. Todos tenemos derechos y obligaciones 
                        que cumplir para llevar una vida plena y segura. Y el gobierno se encarga de 
                        procurarnos ciertos servicios para garantizar nuestro bienestar.<br/><br/>
                      
                        La ciudadanía se obtiene desde el momento en que nacemos. Pero la de los niños no 
                        es igual a la de los adultos, ya que los primeros no cuentan con las mismas 
                        herramientas que los segundos para ejercerla. En otras palabras, la niñez requiere 
                        del cuidado y la protección de las personas adultas: ellas se encargan de procurar sus 
                        derechos y orientarla para que participe y cumpla con lo que corresponde a su edad, 
                        como estudiar, jugar, colaborar con la comunidad, contar con los recursos básicos, 
                        etcétera.
                      </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <Grid container justifyContent="center" >
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img2do/Sesion53/1.png" alt="Tarjeta" />
                        </Grid>
                        <Grid container justifyContent="center" >
                            <p className="TextImg">
                                La ciudadanía y los derechos no hacen ninguna distinción: todos debemos poder ejercerlos.                            
                            </p>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                            Por generaciones, las voces, las ideas y las acciones de los niños en la sociedad han 
                            sido poco valoradas (o incluso ignoradas). Pero esta visión se ha modificado poco a 
                            poco: los actos privados y públicos, grandes o pequeños, por fin han comenzado a 
                            entenderse como parte de su ciudadanía.<br/><br/>
                            
                            También hay niños alrededor del mundo que están compartiendo abiertamente sus 
                            opiniones y mostrando su preocupación por los problemas sociales. Un ejemplo de 
                            ello es Francisco Vera, el joven activista de Colombia que defiende los derechos 
                            ambientales y de la niñez.                     
                        </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <Grid container justifyContent="center" >
                            <img className="Imagen2" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img2do/Sesion53/2.png" alt="Tarjeta" />
                        </Grid>
                        <Grid container justifyContent="center" >
                            <p className="TextImg">
                                Es importante que los niños manifiesten sus preocupaciones para que éstas sean escuchadas.                            
                            </p>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                            Cada vez encontramos a más jóvenes y niños ejerciendo públicamente su ciudadanía o
                            alzando la voz para proponer mejoras en el mundo.<br/><br/> 
                         
                            Por eso es importante que estés informado y que conozcas tus derechos, que 
                            converses con los adultos sobre las cosas que te preocupan y que busques maneras 
                            de participar en la mejora de tu entorno inmediato. Recuerda: la ciudadanía 
                            comienza en casa y se extiende a la sociedad. <br/><br/>
                         
                            ¿Alguna vez has participado en una actividad escolar (como un foro, un debate o 
                            alguna otra acción) para mejorar el espacio? ¿Podrías proponer algo para desarrollar 
                            en tu plantel?  
                        </p>
                    </Grid>
                </Grid>
        </Grid>
    </Grid>
    );
}
 
export default Ciudadanía_para_la_niñez ;
