import React, { useContext, useEffect, useState } from "react";
import { makeStyles, Grid } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

import axios from "../../../../services/axios";
import ContainerAdminPearson from "../ContainerAdminPearson";
import AuthContext from "../../../../auth/AuthContext";

import Loader from "../../../Loader/Loader";

import ModalMessage from "../../../Modals/ModalMessage";

import plantilla from "./docs/PlantillaCargaGrupos.csv";

import { Link } from "react-router-dom";
import DropZoneButton from "./DropZoneButton";
import SelectSchool from "./SelectSchool";
import ModalCargaMasiva from "../Modals/ModalCargaMasiva";
import ModalCargaMasivaError from "../Modals/ModalCargaMasivaError";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    padding: "48px 0 48px 0",
    width: "100vw",
    minHeight: "calc(100vh - 80px)",

    background:
      "linear-gradient(217deg, rgba(255,194,0,0.4962185557816877) 2%, rgba(255,255,255,1) 25%, rgba(255,255,255,0.4962185557816877) 81%, rgba(144,81,177,1) 98%)",
  },
  boxContainer: {
    background: "#fff",
    width: "80%",
    minHeight: "75vh",
    height: "auto",
    borderRadius: "42px",
    padding: "20px",
    alignItems: "center",
    gap: "20px",
    boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.5)",
    [theme.breakpoints.up("md")]: {
      padding: "48px 20px 48px 20px",
    },
  },
  titleContainer: {
    margin: 0,
    [theme.breakpoints.up("md")]: {
      fontSize: "36px",
    },
  },
  title: {
    font: "28px Fredoka One ",
    margin: "0",
    textAlign: "center",
    color: " #DC5EA6",
    [theme.breakpoints.up("md")]: {
      fontSize: "36px",
    },
    [theme.breakpoints.up("xl")]: {
      fontSize: "42px",
    },
  },
  link: {
    cursor: "pointer",
    textDecoration: "none",
  },
  backButtonContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.up("sm")]: {
      justifyContent: "flex-start",
    },
  },
  backButton: {
    cursor: "pointer",
    height: "35px",
    // width: "200px",
    backgroundColor: "#FDEFF7",
    color: "#DC5EA6",
    border: "#DC5EA6 1px solid",
    borderRadius: "20px",
    textTransform: "none",
    font: "20px Lato",
    letterSpacing: "0.14px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
  },

  studentListContainer: {
    gap: "20px",
  },
  PurpleButton: {
    color: "#FFFFFF",
    backgroundColor: "#9051b1",
    borderRadius: "30px",
    alignSelf: "center",
    // margin: "0 auto",

    width: "100%",
    maxWidth: "238px",
    minHeight: "45px",
    border: "none",
    textTransform: "unset",
    cursor: "pointer",
    fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
    fontWeight: "500",
    padding: "6px 16px",
    lineHeight: "1.75",
    transition:
      "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    boxShadow:
      "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
    "&:hover": {
      backgroundColor: "#B965D3 ",
    },
    [theme.breakpoints.up("lg")]: {
      gridArea: "button",
    },
  },
  formContainer: {
    display: "grid",
    gridTemplateColumns: "1fr",
    width: "80%",
    boxSizing: "border-box",
    maxWidth: "560px",
    alignItems: "flex-start",
    justifyItems: "center",
    gap: "30px",

    [theme.breakpoints.up("lg")]: {
      width: "100%",
      maxWidth: "680px",
      alignItems: "end",
      gridTemplateColumns: "1fr 1fr",
      gridTemplateAreas: `'input input'
    'button button'
    `,
    },
  },
  formItemContainer: {
    gap: "10px",
  },
  input: {
    width: "100%",
    height: "40px",
    paddingLeft: "27px",
    paddingRight: "27px",
    borderRadius: "61px",
    boxSizing: "border-box",
    border: "none",
    background: "#FFFFFF",
    font: "Helvetica",
    fontSize: "16px",
    backgroundColor: "#f0f0f0",
    "&:focus": {
      outline: "none",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "18px",
    },
  },
  label: {
    fontWeight: "bold",
    fontFamily: "Lato, sans-serif",
  },
  text: {
    fontFamily: "Lato",
    fontSize: "20px",
  },
}));

const CargarUsuariosMasiva = () => {
  const classes = useStyles();
  const { user } = useContext(AuthContext);
  const [modalMessageOpen, setModalMessageOpen] = useState(false);

  const [error, setError] = useState("");
  const [resultError, setResultError] = useState("");
  const [file, setFile] = useState(null);

  const [resultErrorModalOpen, setResultErrorModalOpen] = useState(false);
  const [modalCargaCompletaOpen, setModalCargaCompletaOpen] = useState(false);
  const [groupLoaded, setGroupLoaded] = useState({});

  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({});

  const [listOfSchools, setListOfSchools] = useState([]);
  const [schoolName, setSchoolName] = useState("");

  useEffect(() => {
    axios
      .get(`/listado/escuelas?id_usuario=${user.userId}`)
      .then((response) => {
        if (response.status === 200) {
          // console.log(response, "response");
          const list = response.data.resultado;
          setListOfSchools(list);
        }
      })
      .catch((error) => {
        console.log(error, "error");
      });

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCloseMessageModal = () => {
    setModalMessageOpen(!modalMessageOpen);
    setResultError("");
  };
  const handleResultErrorModal = () => {
    setResultErrorModalOpen(!resultErrorModalOpen);
  };
  const handleCargaCompletaModal = () => {
    setModalCargaCompletaOpen(!modalCargaCompletaOpen);
  };

  const handleDocument = (documentBase64) => {
    setFile(documentBase64);
  };

  const confirmChanges = () => {
    if (formData?.id_escuela && file) {
      setLoading(true);
      const body = {
        csv: file?.base64,
        id_escuela: formData?.id_escuela,
      };
      axios
        .post("/user/csv/grupos/set", body)
        .then((response) => {
          if (response.status === 200) {
            const data = response.data.resultado;
            setGroupLoaded(data);
            setModalCargaCompletaOpen(true);
            setLoading(false);
            setFile(null);
            setFormData({});
          }
        })
        .catch((error) => {
          setLoading(false);
          setResultErrorModalOpen(true);
          setResultError(
            "Algo salió mal al hacer la carga del documento,verifica que los datos en el documento cumplan con los parámetros correctos e intentalo nuevamente"
          );
        });
    } else {
      setModalMessageOpen(true);
      setError("Asegúrese de llenar todos los datos");
    }
  };

  const handleForm = (e) => {
    setFormData({
      ...formData,
      [e.target.name.toLowerCase()]: e.target.value,
    });
    const school = listOfSchools?.find(
      (escuela) => escuela?.id_escuela === e.target.value
    )?.escuela;
    setSchoolName(school);
  };

  return (
    <ContainerAdminPearson>
      <Grid
        container
        item
        sm={12}
        alignItems="center"
        justifyContent="center"
        className={classes.mainContainer}
      >
        <Grid
          container
          item
          direction="column"
          className={classes.boxContainer}
        >
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="center"
          >
            <Grid item className={classes.backButtonContainer}>
              <Link to="/admin/cargar-datos" className={classes.link}>
                <button className={classes.backButton}>
                  <ArrowBackIcon /> Cargar datos
                </button>
              </Link>
            </Grid>
            <Grid
              container
              item
              alignItems="center"
              justifyContent="center"
              className={classes.titleContainer}
            >
              <h2 className={classes.title}>Carga de usuarios: carga masiva</h2>
            </Grid>
            <p className={classes.text}>
              Puedes subir un archivo csv&nbsp;
              <a href={plantilla} download>
                (descargar plantilla)
              </a>
              &nbsp; y cargar simultáneamente varios usuarios
            </p>
          </Grid>
          <form
            className={classes.formContainer}
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <SelectSchool
              list={listOfSchools}
              label="Nombre de la escuela"
              fieldName="id_escuela"
              handleForm={handleForm}
              defaultValue={formData?.id_escuela}
              value={formData?.id_escuela}
              formData={formData}
            />
            <DropZoneButton
              title="Sube un archivo csv"
              type={".csv"}
              handleDocument={handleDocument}
              file={file}
            />

            <button className={classes.PurpleButton} onClick={confirmChanges}>
              Cargar usuarios
            </button>
          </form>
        </Grid>
      </Grid>
      <ModalCargaMasiva
        open={modalCargaCompletaOpen}
        handleClose={handleCargaCompletaModal}
        title={{
          ok: "Los grupos se han cargado correctamente",
          warningMessage: "Algunos usuarios no se cargaron correctamente",
        }}
        paragraph={{
          ok: "Descarga el documento con los usuarios y contraseñas temporales y verifica que los usuarios  no cargados  cumplan con los parámetros correctos para acceder al ",
          warningMessage: `Se dieron de alta ${groupLoaded?.exito} usuarios de manera exitosa para la escuela ${schoolName} y ${groupLoaded?.error} no se han podido cargar. Descarga el documento con los usuarios y contraseñas temporales y verifica que los usuarios  no cargados  cumplan con los parámetros correctos para acceder al `,
          errorMessage:
            "Descarga el documento, revisa los datos e intenta nuevamente para acceder al circuito de lectores",
        }}
        data={groupLoaded}
      />
      <ModalCargaMasivaError
        open={resultErrorModalOpen}
        handleClose={handleResultErrorModal}
        error={resultError}
      />
      <ModalMessage
        open={modalMessageOpen}
        handleClose={handleCloseMessageModal}
        title={error}
      />
      {loading && <Loader />}
    </ContainerAdminPearson>
  );
};

export default CargarUsuariosMasiva;
