import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
    ContenedorLectura:{
        width:'502px',
                  [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
    },
    ContainerTitulo:{
        margin:'0 0 46px 0',
        '& .Titulo':{
            letterSpacing: '0px',
            color: '#D7694D',
            margin:'0',
            textAlign:'center',
            font:'43px fredoka one',
            lineHeight:'52px',
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            margin:'0 0 36px 0',
            color:'#000',
            font:'18px lato',
            lineHeight :'28px',
            letterSpacing: '0.17px',
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .SubTitulo':{
            color:'#628D00',
            margin:'0',
            font:'bold 26px lato',
            lineHeight :'28px',
            letterSpacing: '0.24px',
            [theme.breakpoints.down(502)]:{
                padding:'19px 0 12px 0',
                font:'bold 22px lato',
                lineHeight :'24px',
                letterSpacing: '0.21px',
            },
        },
        "& .SubTitulo2": {
            color: "#3531FF",
            margin: "0",
            font: "bold 22px lato",
            lineHeight: "24px",
            letterSpacing: "0.21px",
            [theme.breakpoints.down(502)]: {
              font: "bold 18px lato",
              lineHeight: "20px",
              letterSpacing: "0.16px",
            },
        },
        '& .Imagen':{
            width:'100%',
        },  
      
    },
}));

const La_libertad_de_expresión = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>

            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >
                        La libertad de expresión
                    </h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container xs={12} >
                      <p className="Lectura ">
                        La libertad de expresión es el derecho que tiene una persona a hablar de sus ideas sin 
                        ser castigada por ello. La Declaración Universal de los Derechos Humanos establece 
                        que los individuos pueden comunicar sus opiniones e investigar sobre cualquier tema 
                        que les interese, sin que ello signifique un motivo para que sean perseguidos o 
                        castigados. También gozan de la posibilidad de hablar sobre sus ideas con quienes 
                        deseen, en los lugares que consideren adecuados y con los medios de comunicación 
                        que prefieran.
                      </p>
                    </Grid>
                    <Grid container xs={12} >
                        <Grid container justifyContent="center" >
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img3ro/Sesion40/1.png" alt="Tarjeta" />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container xs={12} >
                      <p className="Lectura ">
                        
                        <h3 className='SubTitulo'>El derecho a informar</h3><br/>
                         La libertad de expresión es un derecho, pero es ejercido de manera diferente en los 
                         distintos países, pues los gobiernos de cada uno determinan cuánta independencia 
                         conceden a sus habitantes y medios de difusión. La posibilidad de mantener 
                         informada a una nación es uno de los principales factores que permite determinar si 
                         se respeta este derecho en un país. Se plantean varias preguntas para establecerlo. 
                         Por ejemplo: ¿los periodistas pueden explicar cualquier acontecimiento que esté 
                         ocurriendo? ¿Tienen la oportunidad de decir la verdad y no ocultar datos 
                         importantes? ¿Pueden investigar cualquier cuestión y dar a conocer los resultados de 
                         sus investigaciones? Si la respuesta es sí, entonces se puede afirmar que dicha 
                         sociedad garantiza la libre expresión. <br/><br/>
                         
                         Existe una organización no gubernamental llamada Reporteros sin fronteras que 
                         cada año estudia la libertad de prensa en todo el mundo. Lo hace a través de las 
                         preguntas anteriores y considera varios factores más. Después, clasifica a las 
                         naciones y da a conocer sus análisis. Según el informe de 2021, Noruega, Finlandia y 
                         Suecia respetan más esta situación, mientras que Eritrea, Corea del Norte y 
                         Turkmenistán son las regiones que menos la promueven. <br/><br/>
                         
                         <h3 className='SubTitulo2'>¿Tiene límites?</h3><br/>
                        
                        Como ves, expresar ideas y pensamientos es un derecho que todos los seres 
                        humanos tienen durante su vida. Pero hay un asunto importante que tiene que 
                        recordarse siempre: no lastimar a nadie al expresar alguna idea. A veces, se pierde de 
                        vista que no todas las personas piensan o sienten de la misma manera. <br/><br/>

                        Es fundamental cuidar que nuestras palabras no afecten a nadie, pues no es correcto 
                        discriminar a ningún individuo por su género, religión u orientación política. Tenemos
                        que aprender a distinguir entre una perspectiva bien explicada y una ofensa, pues las 
                        agresiones que causan los comentarios no están justificadas por la libertad de 
                        expresión. Toda visión es importante y merece ser difundida, escuchada y apreciada, 
                        pero también está sujeta al análisis de los demás. Ten presente que todos forman 
                        parte de la sociedad y merecen recibir respeto e inclusión en ella.

                      </p>
                    </Grid>
                    <Grid container xs={12} >
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img3ro/Sesion40/2.png" alt="Tarjeta" />
                    </Grid>
                </Grid>
        </Grid>
    </Grid>
    );
}
 
export default La_libertad_de_expresión;
