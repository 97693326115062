import { Button, Grid, makeStyles } from "@material-ui/core";
import React, { useContext, useEffect } from "react";
import DrawerContext from "../../../../../usability/DrawerContext";
import { useOnLoadImages } from "../../../../hooks/useOnLoadImages";
import Loader from "../../../../Loader/Loader";
import Drawer from "../../ReadingTools/Drawer";

const useStyles =  makeStyles (theme =>({
     Contenedor:{
        background:'#E9FBF9',
        width:'100vw',
        overflowX:'clip',
        [theme.breakpoints.down(1161)]:{
            background:'none',
           overflowX:'unset',
        },
        [theme.breakpoints.down(600)]:{
            marginTop: '79.51px',
        }
    },
  ContainerInfoLectura: {
    position: "relative",
    height: "86px",
    [theme.breakpoints.down(502)]:{
        height: "43px",
    },
    background: "#76D4C3",
    "& .BarraInfoLectura": {
      height: "86px",
      background: `Url(https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Barras/Azul.png)`,
        [theme.breakpoints.down(502)]:{
            height: "43px",
        },
    },
    "& .InfoLectura": {
      position: "absolute",
      height: "86px",
      width: "219px",
      boxSizing: "border-box",
      padding: "17.2px 0",
      background: "#FAFAFA",
      borderRadius: "50px",
      boxShadow: "2px 2px 2px 2px #00000029",
      zIndex: "4",
        [theme.breakpoints.down(502)]:{
            height: "43px",
            width: "109px",
            padding: "8.6px 0",
            borderRadius: "25px",
        },
    },
    "& .InfoLectura:before": {
      position: "absolute",
      display: "flex",
      boxSizing: "border-box",
      padding: "0 0 0 10px",
      justifyContent: "center",
      alignItems: "center",
      height: "39.88px",
      width: "39.88px",
      borderRadius: "50%",
      content: '""',
      boxShadow: "3px 0px .5px 0px #00000029",
      background: "#FAFAFA",
      left: "90px",
      top: "65px",
      transform: "rotate(0.25turn)",
      [theme.breakpoints.down(502)]:{
        left: "45px",
        top: "30px",
        height: "20.88px",
        width: "20.88px",
        },
    },
    "& .InfoLectura:after": {
      content: '""',
      height: "15px",
      width: "15px",
      borderTop: "2px solid #20A698",
      borderLeft: "2px solid #20A698",
      position: "absolute",
      bottom: "-10px",
      left: "Calc(50% - 8px)",
      transform: "rotate(0.625turn)",
      [theme.breakpoints.down(502)]:{
        height: "10px",
        width: "10px",
        left: "Calc(50% - 4.5px)",
        bottom: "-3px",
        borderTop: "1px solid #20A698",
        borderLeft: "1px solid #20A698",
        },
    },
    "& .Sesion": {
      borderLeft: "2px solid #D8D8D8",
    },
    "& .Dato": {
      margin: "0 0 5px 0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#20A698",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        margin: "0 0 2.5px 0",
        },
    },
    "& .Caracter": {
      margin: "0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#000000",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        },
    },
       '& .Regresar':{
        color: '#F2624F',
        border: '#F2624F 1px solid',
        height: '30px',
        width:'100px',
        borderRadius: '20px',
        letterSpacing: '0.14px',
        textTransform: 'none',
        background:'#FEFAF1',
        top:'calc(50% - 15px)',
        left:'5%',
        zIndex:4,
        [theme.breakpoints.down(502)]: {
          font:'bold 10px lato',
          width: "60px",
        },
      },
  },
    ContenedorLectura:{
        background:'#fff',
        width:'1160px',
        height:'100%',
        padding:'60px 168px 35px ',
        
        transform: (props) =>
        props.scale > 1 ? `scale(${props.scale}) translate(0px, 10%)` : `none`,
        [theme.breakpoints.down(1161)]:{
            width:'502px',
            padding:'60px 0 0 0',
          },
                [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },

        '& .Escritorio':{
            display:'block',
            [theme.breakpoints.down(1161)]:{
                display:'none'
            },
        },
        '& .Tablet':{
            display:'none',
            [theme.breakpoints.down(1161)]:{
                display:'block'
            },
        }
    },
    ContainerTitulo:{
        margin:'0 0 34px 0',
        [theme.breakpoints.down(1161)]:{
            margin:'0 0 34px 0',
        },
        '& .Titulo':{
            font:'48px fredoka one',
            letterSpacing: '0px',
            lineHeight:'58px',
            color: '#0174FF',
            margin:'0',
            textAlign:'center',
            [theme.breakpoints.down(1161)]:{
                font:'43px fredoka one',
                lineHeight:'52px',
            },
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            lineHeight : '30px',
            margin:'0 0 36px 0',
            font:'22px lato',
            color:'#000',
            letterSpacing: '0.20px',
            [theme.breakpoints.down(1161)]:{
                font:'18px lato',
                lineHeight :'28px',
                letterSpacing: '0.17px',
            },
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .SubTitulo':{
            font:'bold 30px lato',
            color:'#DC10A5',
            letterSpacing:'.28px',
            lineHeight:'30px',
            margin:'0',
            [theme.breakpoints.down(1161)]:{
                lineHeight :'28px',
                font:'bold 26px lato',
                letterSpacing: '0.24px',
            },
            [theme.breakpoints.down(502)]:{
                font:'bold 18px lato',
                lineHeight :'24px',
                letterSpacing: '0.21px',
            },
        },
        '& .TextImg':{
            font:'20px lato',
            color:'#717476',
            letterSpacing:'.19px',
            lineHeight:'30px',
            margin:'24px 0 0 0',
            [theme.breakpoints.down(1161)]:{
                font:' 16px lato',
                lineHeight :'25px',
                letterSpacing: '0.15px',
            },
            [theme.breakpoints.down(502)]:{
                font:' 11px lato',
                lineHeight :'14px',
                letterSpacing: '0.10px',
            },
        },

        '& .Imagen':{
            [theme.breakpoints.down(1161)]:{
                width:'100%',
            },
        },  
      
    }
}));

const Libre_determinación = (props) => {

  const {activeStep, setActiveStep, handlePreviewStep, id_actividad} = props
  
  const handleContinue = () => {
    setActiveStep(activeStep - 1)
    handlePreviewStep()
  }

    const {
      handleSize,
    disabledZoom,
    closeZoom,
    handleZoom,
    scale
  }= useContext(DrawerContext);
  const [loading, setLoading] = React.useState(true);
  const wrapperRef = React.useRef(null);
  const imagesLoaded = useOnLoadImages(wrapperRef);

  const detectSize = () => {
    const lectura = document.getElementById("lectura");
    const newWidth = lectura?.offsetWidth;
    const newHeight = lectura?.offsetHeight;
    setLoading(false);
    if (lectura) handleSize(newHeight, newWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", detectSize);
    return () => {
      handleZoom()
      window.removeEventListener("resize", detectSize);
      closeZoom()
    };
  }, []);

  const classes = useStyles({ disabledZoom,scale });
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
             <Grid container item justifyContent="center" className={classes.ContainerInfoLectura}>
                       <Grid container className="BarraInfoLectura">
            <Button className="Regresar" onClick={handleContinue}>
                Regresar
            </Button>
        </Grid>
               <Grid container item className="InfoLectura">
                    <Grid container item direction="column" xs={6} justifyContent="center">
                        <p className="Dato">Grado</p>
                        <p className="Caracter">5</p>
                    </Grid>
                    <Grid container item direction="column" xs={6} justifyContent="center" className="Sesion">
                        <p className="Dato">Sesión</p>
                        <p className="Caracter">53</p>
                    </Grid>
               </Grid>
            </Grid>
            {!imagesLoaded && <Loader />}
        <Grid
            container
            item
            className={classes.ContenedorLectura}
            justifyContent="center"
            id="lectura"
            onLoad={detectSize}
            ref={wrapperRef}
        >
<Drawer id_lectura={id_actividad} />
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                        <h2 className="Titulo">Libre determinación</h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                       <p className='Lectura'>
                        El 8.5% de la población de América Latina está conformado por personas indígenas: 
                        un total de 45 millones. En esta región habitan alrededor de 800 pueblos originarios. 
                        Estas poblaciones tienen mayor probabilidad de vivir en pobreza extrema.<br/><br/> 
                        
                        En términos generales, dicha situación se deriva de problemas que iniciaron hace 
                        más de 500 años, con la ocupación europea del continente americano. Desde 
                        entonces los pueblos indígenas han sido despojados de sus tierras y recursos, y han 
                        recibido un trato discriminatorio. Esto ha impedido que puedan desarrollarse según 
                        sus propias necesidades e intereses. <br/><br/> 
                        
                        Durante las décadas de 1980 y 1990 ocurrió una serie de cambios políticos que 
                        permitieron la existencia de gobiernos más democráticos en la región. A su vez, esto 
                        posibilitó que se abrieran oportunidades para que las poblaciones indígenas alzaran 
                        su voz e iniciaran una lucha para que sus derechos fueran reconocidos y respetados.<br/><br/> 

                        Una de sus principales demandas ha sido el derecho a ejercer la libre determinación. 
                        Existe <em>libre determinación</em> cuando una comunidad elige y administra sus instituciones 
                        (especialmente su forma de gobierno), toma por sí misma las decisiones relevantes 
                        para su vida social, y éstas son respetadas por el gobierno del país en cuyo territorio 
                        vive dicha comunidad. <br/><br/> 
                        
                        El derecho a la libre determinación es fundamental para mejorar las condiciones de 
                        vida de las poblaciones indígenas, porque legitima su capacidad de decidir sobre los
                        problemas que enfrentan. La Declaración de las Naciones Unidas sobre los pueblos 
                        indígenas de 2007 reconoce este derecho como indispensable. Sin embargo, en la 
                        práctica ha encontrado obstáculos y resistencias por parte de algunos países. <br/><br/> 
                        
                        Los esfuerzos por redactar un documento que abordara la protección de los pueblos 
                        indígenas a nivel internacional inició en 1982. El proceso avanzó muy lentamente, 
                        porque varios Estados querían seguir controlando los recursos naturales en las 
                        tierras ancestrales de los pueblos indígenas. <br/><br/> 
                        
                        Entre otras razones, la violación de este derecho se debe a que la autodeterminación 
                        se confunde con independencia. Es decir, algunas personas piensan que reconocerla 
                        y respetarla es lo mismo que declarar a los pueblos indígenas como independientes
                        del Estado en donde se ubican. Sin embargo, no es así. Los pueblos indígenas no 
                        necesariamente buscan separase de los Estados a los cuales pertenecen, sino el 
                        derecho a regirse por sus propias leyes, a elegir a sus propias autoridades y a decidir 
                        sobre lo que pasa dentro del territorio que habitan. Buscan conquistar su autonomía. <br/><br/> 
                        
                        <h3 className='SubTitulo'>La experiencia panameña</h3><br/>
                        
                        En América Latina la primera experiencia de autonomía ocurrió en Panamá. Como 
                        consecuencia de la Revolución guna de 1925 el Estado reconoció la autonomía de 
                        este pueblo y le permitió fundar un territorio conocido como la comarca Guna Yale. 
                        A partir de esta primera comarca el resto de los pueblos indígenas de Panamá ha 
                        negociado la creación de comarcas regidas de acuerdo con sus costumbres y 
                        gobernadas por sus autoridades tradicionales. Además el Estado está obligado a 
                        consultar a los pueblos indígenas sobre la explotación de recursos naturales 
                        en sus territorios, lo cual les permite oponerse a los daños sociales y ambientales
                        derivados de la explotación.
                       </p>                   
                    </Grid>
                    <Grid containter justifyContent>
                        <Grid container justifyContent='center'>
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion53/1.png" alt="Tarjeta" />
                            <p className='TextImg'></p>
                        </Grid>
                        <Grid container >
                            <p className="TextImg">
                                La Revolución guna fue resultado de la occidentalización forzada a la que fueron sometidos los indígenas por el Estado panameño.
                            </p>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container >
                        <p className='Lectura'>
                            La experiencia panameña muestra que los pueblos indígenas siempre lucharán por su 
                            libre determinación. Reconocer y respetar este derecho reduce el conflicto entre el 
                            Estado y los pueblos originarios. Su autonomía crea las condiciones óptimas para una 
                            convivencia pacífica y justa, ya que les permite elegir libremente su destino.
                        </p>
                    </Grid>
                    <Grid container justifyContent='center'>
                        <Grid container justifyContent='center'>
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion53/2.png" alt="Tarjeta" />
                        </Grid>
                        <Grid container >
                            <p className="TextImg">
                                Las mujeres gunas son las guardianas de las <em>molas</em>, unas piezas textiles que cuentan historias y 
                                representan las costumbres y creencias de su pueblo. Su confección y diseño es una actividad 
                                en la que también participan los <em>omegiid</em> (los hombres que asumen una identidad femenina).
                            </p>
                        </Grid>
                    </Grid>

                </Grid>
        </Grid>
        <Grid container item style={{ marginTop: 80}} ></Grid>
    </Grid>
    );
}
 
export default Libre_determinación;
