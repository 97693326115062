import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
    ContenedorLectura:{
        width:'502px',
        [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
        '& .Escritorio':{
            display:'block',
            [theme.breakpoints.down(1161)]:{
                display:'none'
            },
        },
        '& .Tablet':{
            display:'none',
            [theme.breakpoints.down(1161)]:{
                display:'block'
            },
        }
    },
    ContainerTitulo:{
        margin:'0 0 46px 0',
        '& .Titulo':{
            letterSpacing: '0px',
            color: '#F6644B',
            margin:'0',
            font:'43px fredoka one',
            lineHeight:'52px',
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            margin:'0 0 36px 0',
            color:'#000',
            font:'18px lato',
            lineHeight :'28px',
            letterSpacing: '0.17px',
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .Imagen':{
             width:'100%',
        },
    },
}));

const Desde_otro_mundo = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>

            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >
                        Desde otro mundo
                    </h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                            Sharik me cuenta que él solía ser un espíritu. Dice que antes de nacer vivía feliz junto 
                            a seres mágicos en un bosque con enormes árboles donde jugaba, escondiéndose 
                            entre sus ramas y raíces. Dice que los bebés lloran cuando nacen porque dejan atrás 
                            ese lugar.<br/><br/> 
                            
                            Sharik nació en Etiopía. También me narra su vida allí, me habla sobre el hambre que 
                            tenía. Mientras su madre hacía viajes de tres días para vender sus productos, en casa 
                            se acababa la comida (que en general duraba poco). Entonces, él y sus hermanos 
                            salían a la calle a conseguir lo que fuera. Algunas veces se iban a pizcar algodón y a 
                            cuidar ovejas al campo, donde siempre les pagaban con alimentos.
                        </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <Grid container >
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img2do/Sesion19/1.png" alt="Tarjeta" />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                            Su padre se había ido tiempo atrás en busca de trabajo, y en ocasiones les enviaba 
                            cartas con dinero. Pero como a veces se los robaban decidieron huir, a pesar de que 
                            su madre juraba que no dejarían nunca su país.<br/> <br/>
                            
                            Sharik recuerda los días en los que tuvo que viajar escondido, ocultándose en la 
                            noche. Lloraba porque estaba acostumbrado a dormir, no a caminar en las horas 
                            oscuras sin saber cuándo llegaría a algún lado. Recuerda que tuvo que hacer silencio 
                            muchas veces sin entender por qué. Entre el miedo y la angustia, veía la mirada clara 
                            de su madre advirtiéndole que no abriera la boca, que no se moviera.<br/> <br/>
                            
                            “Por eso era mejor ser un espíritu”, dice Sharik. “En aquel bosque tenía libertad para 
                            jugar, reír y hacer ruido.”<br/> <br/>
                            
                            Ahora el hambre ha quedado atrás. Sharik y su familia se reunieron con su padre. Su 
                            madre ya no viaja durante tres días (aunque aún debe trabajar el día entero, al igual 
                            que su esposo).<br/> <br/>
                            
                            Sharik afirma que los niños como él importunan a sus papás porque siempre quieren 
                            volver a ser espíritus, porque no les gusta sentir dolor. Él ha tenido los pies 
                            adoloridos por mucho tiempo, por trabajar cosechando y cuidando ovejas. Dice que 
                            está cansado.<br/> <br/>
                            
                            Creo que a Sharik todavía le queda mucho camino por recorrer, igual que a mí. 
                            Mientras tanto, lo invito a jugar porque es el niño nuevo de la escuela y viene de otro 
                            país.
                        </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <Grid container >
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img2do/Sesion19/2.png" alt="Tarjeta" />
                        </Grid>
                    </Grid>
                </Grid>
        </Grid>
    </Grid>
    );
}
 
export default Desde_otro_mundo;
