import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
    Contenedor:{
       background:'#E9FBF9',
       width:'100vw',
       [theme.breakpoints.down(1280)]:{
           background:'none',
       },
       [theme.breakpoints.down(600)]:{
           marginTop: '79.51px',
       }
   },
  ContainerInfoLectura: {
    position: "relative",
    height: "86px",
    [theme.breakpoints.down(502)]:{
        height: "43px",
    },
    background: "#76D4C3",
    "& .BarraInfoLectura": {
      height: "86px",
      background: `Url(https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Barras/Azul.png)`,
        [theme.breakpoints.down(502)]:{
            height: "43px",
        },
    },
    "& .InfoLectura": {
      position: "absolute",
      height: "86px",
      width: "219px",
      boxSizing: "border-box",
      padding: "17.2px 0",
      background: "#FAFAFA",
      borderRadius: "50px",
      boxShadow: "2px 2px 2px 2px #00000029",
      zIndex: "4",
        [theme.breakpoints.down(502)]:{
            height: "43px",
            width: "109px",
            padding: "8.6px 0",
            borderRadius: "25px",
        },
    },
    "& .InfoLectura:before": {
      position: "absolute",
      display: "flex",
      boxSizing: "border-box",
      padding: "0 0 0 10px",
      justifyContent: "center",
      alignItems: "center",
      height: "39.88px",
      width: "39.88px",
      borderRadius: "50%",
      content: '""',
      boxShadow: "3px 0px .5px 0px #00000029",
      background: "#FAFAFA",
      left: "90px",
      top: "65px",
      transform: "rotate(0.25turn)",
      [theme.breakpoints.down(502)]:{
        left: "45px",
        top: "30px",
        height: "20.88px",
        width: "20.88px",
        },
    },
    "& .InfoLectura:after": {
      content: '""',
      height: "15px",
      width: "15px",
      borderTop: "2px solid #20A698",
      borderLeft: "2px solid #20A698",
      position: "absolute",
      bottom: "-10px",
      left: "Calc(50% - 8px)",
      transform: "rotate(0.625turn)",
      [theme.breakpoints.down(502)]:{
        height: "10px",
        width: "10px",
        left: "Calc(50% - 4.5px)",
        bottom: "-3px",
        borderTop: "1px solid #20A698",
        borderLeft: "1px solid #20A698",
        },
    },
    "& .Sesion": {
      borderLeft: "2px solid #D8D8D8",
    },
    "& .Dato": {
      margin: "0 0 5px 0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#20A698",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        margin: "0 0 2.5px 0",
        },
    },
    "& .Caracter": {
      margin: "0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#000000",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        },
    },
  },
   ContenedorLectura:{
       background:'#fff',
       width:'1160px',
       height:'100%',
       padding:'60px 168px 35px ',
       [theme.breakpoints.down(1280)]:{
           width:'502px',
           padding:'60px 0 0 0',
         },
                [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
       '& .ContainerDerechos':{
           bottom:'80px',
           [theme.breakpoints.down(1280)]:{
               display:'none',
           },
       },
       '& .Derechos':{
           textAlign:"center",
           margin:"0px",
           font:" 15px lato",
           color:"#717476",
       },
       '& .Escritorio':{
           display:'block',
           [theme.breakpoints.down(1280)]:{
               display:'none'
           },
       },
       '& .Tablet':{
           display:'none',
           [theme.breakpoints.down(1280)]:{
               display:'block'
           },
       }
   },
    ContainerTitulo:{
        margin:'0 0 46px 0',
        '& .Titulo':{
            letterSpacing: '0px',
            color: '#E60690',
            margin:'0',
            textAlign:'center',
            font:'48px fredoka one',
            [theme.breakpoints.down(1280)]:{
                font:'43px fredoka one',
                lineHeight:'52px',
            },
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            margin:'0 0 36px 0 ',
            color:'#000',
            font:'22px lato',
            lineHeight : '30px',
            letterSpacing: '0.20px',
            [theme.breakpoints.down(1280)]:{
                font:'18px lato',
                lineHeight :'28px',
                letterSpacing: '0.17px',
            },
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .SubTitulo':{
            color:'#00B638',
            margin:'0',
            font:'bold 30px lato',
            letterSpacing:'.28px',
            lineHeight:'30px',
            [theme.breakpoints.down(1280)]:{
                font:'bold 22px lato',
                lineHeight :'24px',
                letterSpacing: '0.21px',
            },
            [theme.breakpoints.down(502)]:{
                font:'bold 18px lato',
                lineHeight :'20px',
                letterSpacing: '0.16px',
            },
        },
        "& .Esp": {
            boxSizing:'border-box',
            padding:'0 56px 0 0',
            margin:'0',
            [theme.breakpoints.down(1280)]:{
                padding:'0',
                margin:'0 0 36px 0',
            },
        },
        "& .Esp2": {
            boxSizing:'border-box',
            padding:'0 14px 0 0',
            margin:'0',
            [theme.breakpoints.down(1280)]:{
                padding:'0',
                margin:'0 0 36px 0',
            },
        },
        '& .TextImg':{
            color:'#717476',
            margin:'0',
            font:'20px lato',
            lineHeight:'30px',
            letterSpacing:'.19px',
            [theme.breakpoints.down(1280)]:{
                margin:'21px 0 0 0',
                font:' 16px lato',
                lineHeight :'25px',
                letterSpacing: '0.15px',
            },
            [theme.breakpoints.down(502)]:{
                font:' 11px lato',
                lineHeight :'14px',
                letterSpacing: '0.10px',
            },
        },
        
        '& .Imagen':{
            [theme.breakpoints.down(1280)]:{
                width:'100%'
            },
        },
    },
    

}));

const El_folclor_latinoamericano = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
             <Grid container item justifyContent="center" className={classes.ContainerInfoLectura}>
               <Grid container className="BarraInfoLectura"></Grid>
               <Grid container item className="InfoLectura">
                    <Grid container item direction="column" xs={6} justifyContent="center">
                        <p className="Dato">Grado</p>
                        <p className="Caracter">4</p>
                    </Grid>
                    <Grid container item direction="column" xs={6} justifyContent="center" className="Sesion">
                        <p className="Dato">Sesión</p>
                        <p className="Caracter">39</p>
                    </Grid>
               </Grid>
            </Grid>

            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >
                        El folclor<br className='Tablet'/> latinoamericano
                    </h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container item  sm={12}>
                        <p className="Lectura">
                            En el pasado, en el idioma inglés había dos palabras que existían por separado. La 
                            primera es <em>folk</em>, que significa “pueblo”, y la segunda es <em>lore</em>, que quiere decir 
                            “conocimiento”. Con el paso de los años, ambos términos se unieron en uno solo para 
                            formar el concepto <em>folklore</em>, que también puede pronunciarse como <em>folclor</em>.<br/><br/> 
                            
                            Actualmente, se refiere a las cosas que se transmiten dentro de una comunidad y que 
                            son producto de la relación que tienen las distintas culturas con el mundo que las 
                            rodea. Por ejemplo, la manera en la que las personas se visten, la música que 
                            escuchan y cómo preparan los alimentos que comen. Eso es el folclor: el 
                            conocimiento popular que enriquece a todo el planeta.
                        </p>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} >
                    <Grid container item  md={12} lg={6}>
                        <p className="Lectura Esp">
                            <h3 className='SubTitulo'>El tango</h3><br/> 
                            El tango es un género musical que se 
                            gestó entre las clases populares de 
                            Argentina y Uruguay. Ocurrió 
                            principalmente en las zonas donde 
                            convivían los nativos, los migrantes 
                            europeos y los familiares de 
                            esclavos africanos. El principal 
                            instrumento que utiliza el tango es 
                            el bandoneón, acompañado de otros, 
                            como el violín y el piano. El 
                            vestuario tradicional suele incluir 
                            vestidos largos para las mujeres y 
                            trajes formales para los hombres.
                        </p>
                    </Grid>
                    <Grid container item justifyContent='center' md={12} lg={6} >
                        <Grid cotainer item justifyContent='center' xs={12}>
                            <img className='Imagen' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img4to/Sesion39/1.png"  alt="" />
                        </Grid>
                        <Grid cotainer item justifyContent='center' xs={12}>
                            <p className='TextImg'>
                                El tango es producto de las distintas culturas 
                                que llegaron a los países sudamericanos.
                            </p>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent='center'>
                    <Grid container item sm={12}>
                        <p className="Lectura ">
                            <h3 className="SubTitulo">La comida mexicana</h3><br/>
                            
                            La comida mexicana es la única en Latinoamérica que se ha clasificado como 
                            patrimonio, gracias a la diversidad de sabores que se mezclan en un platillo típico de 
                            dicha región. Este título significa que sus técnicas e ingredientes merecen ser 
                            conservados. Uno de los principales componentes es el maíz, que es la base de una 
                            gran parte de los platillos.
                        </p>
                    </Grid>
                    <Grid container item  justifyContent='center'>
                        <Grid cotainer item justifyContent='center' xs={12}>
                            <img className='Imagen' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img4to/Sesion39/2.png"  alt="" />
                        </Grid>
                        <Grid cotainer item justifyContent='center' xs={12}>
                            <p className='TextImg'>
                                La cocina mexicana es producto de la mezcla de ingredientes prehispánicos y coloniales.
                            </p>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} >
                    <Grid container item  md={12} lg={6}>
                        <p className="Lectura Esp2">
                            <h3 className='SubTitulo'>El carnaval de Barranquilla</h3><br/> 

                            Este festejo se celebra cada año en la 
                            ciudad de Barranquilla, en Colombia. 
                            Inicia cuatro días antes de la Semana 
                            Santa y en él se presenta una gran 
                            variedad de danzas y música de todas las 
                            provincias colombianas. La música del 
                            carnaval suele ser interpretada por 
                            conjuntos de tambores o de 
                            instrumentos de viento. Los danzantes 
                            portan artesanías y recorren bailando las 
                            calles de esa ciudad caribeña.<br/><br/> 
                            
                            Barranquilla es conocida por ser un lugar 
                            donde se fusionaron culturas indígenas, 
                            europeas y africanas.
                        </p>
                    </Grid>
                    <Grid container item justifyContent='center' md={12} lg={6} >
                        <Grid cotainer item justifyContent='center' xs={12}>
                            <img className='Imagen' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img4to/Sesion39/3.png"  alt="" />
                        </Grid>
                        <Grid cotainer item xs={12}>
                            <p className='TextImg'>
                                El carnaval es un momento de fiesta, baile y celebración.
                            </p>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent='center'>
                    <Grid container item sm={12}>
                        <p className="Lectura ">
                            <h3 className="SubTitulo">El patrimonio oral del pueblo zápara</h3><br/>
                            
                            El pueblo zápara vive en una región de la selva amazónica situada entre Perú y 
                            Ecuador. Ha desarrollado una tradición oral llena de sabiduría sobre la fauna de su 
                            entorno y las plantas medicinales de la selva. La importancia del patrimonio oral 
                            reside en que registra y transmite el conocimiento a través de mitos y leyendas.
                            Dichos saberes se enfocan en los tipos de plantas medicinales, las prácticas artísticas 
                            y las tradiciones culturales y provienen de tiempos ancestrales.
                        </p>
                    </Grid>
                    <Grid container item  justifyContent='center'>
                        <Grid cotainer item justifyContent='center' xs={12}>
                            <img className='Imagen' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img4to/Sesion39/4.png"  alt="" />
                        </Grid>
                        <Grid cotainer item justifyContent='center' xs={12}>
                            <p className='TextImg'>
                                Al no tener escritura, el pueblo zápara ha recurrido a su memoria para preservar su conocimiento ancestral.
                            </p>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent='center'>
                    <Grid container item sm={12}>
                        <p className="Lectura ">
                            <h3 className="SubTitulo">El boyeo y las carretas</h3><br/>
                            
                            Las figuras de carretas jaladas por bueyes son una de las artesanías más famosas de 
                            Costa Rica. Representan a las carretas que eran utilizadas anteriormente para 
                            transportar granos de café por todo el país. Éstas eran grandes y tenían ruedas
                            hechas especialmente para avanzar en medio del fango.<br/><br/> 
                            
                            La tradición de pintarlas y adornarlas comenzó años después. Normalmente, cada 
                            región del país tiene su propio diseño y éstos permiten identificar el origen de la
                            carreta. La persona que crea y conduce estos vehículos recibe el nombre de <em>boyero</em>.
                        </p>
                    </Grid>
                    <Grid container item  justifyContent='center'>
                        <Grid cotainer item justifyContent='center' xs={12}>
                            <img className='Imagen' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img4to/Sesion39/5.png"  alt="" />
                        </Grid>
                        <Grid cotainer item justifyContent='center' xs={12}>
                            <p className='TextImg'>
                                Las carretas surgieron para el comercio del café. Actualmente son motivo de orgullo regional.
                            </p>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent='center'>
                    <Grid container item sm={12}>
                        <p className="Lectura ">
                            Ahora que sabes más sobre las manifestaciones de las culturas que hay en el 
                            territorio latinoamericano podrás apreciarlas y preservarlas. ¿Conoces alguna 
                            tradición que dé identidad a tu comunidad?
                        </p>
                    </Grid>
                </Grid>


                
                <Grid container item xs={12} justifyContent="center" className="ContainerDerechos">
                    <p className="Derechos">
                        © 2022. Todos los derechos reservados, Pearson, Circuito de lectores.
                    </p>                
                </Grid>
        </Grid>
    </Grid>
    );
}
 
export default El_folclor_latinoamericano;
