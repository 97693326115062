import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';
const useStyles =  makeStyles (theme =>({
      Contenedor:{
        background:'#E9FBF9',
        width:'100vw',
        overflowX:'clip',
        [theme.breakpoints.down(1161)]:{
            background:'none',
           overflowX:'unset',
        },
        [theme.breakpoints.down(600)]:{
            marginTop: '79.51px',
        }
    },
    ContainerInfoLectura:{
        position:'relative',
        height:'86px',
        background:'#817FD9',
        '& .BarraInfoLectura':{
            height:'86px',
            background:`Url(https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Barras/Morada.png)`,
        },
        '& .InfoLectura':{
            position:'absolute',
            height:'86px',
            width:'219px',
            boxSizing:'border-box',
            padding:'17.2px 0',
            background:'#FAFAFA',
            borderRadius:'50px',
            boxShadow: '2px 2px 2px 2px #00000029',
        },
        '& .InfoLectura:before':{
            position:'absolute',
            display:'flex',
            boxSizing:'border-box',
            padding:'0 0 0 10px',
            justifyContent:'center',
            alignItems:'center',
            height:'39.88px',
            width:'39.88px',
            borderRadius:'50%',
            content:'">"',
            boxShadow: '3px 0px .5px 0px #00000029',
            background:'#FAFAFA',
            left:'90px',
            top:'65px',
            font:'bold 20px lato',
            color:'#926DD1',
            transform: 'rotate(0.25turn)',
            },
        '& .Sesion':{
            borderLeft:'2px solid #D8D8D8',
        },
        '& .Dato':{
            margin:'0 0 5px 0',
            textAlign:'center',
            font:'bold 17px lato',
            color:'#926DD1',
        },
        '& .Caracter':{
            margin:'0',
            textAlign:'center',
            font:'bold 17px lato',
            color:'#000000',
        },
    },
    ContenedorLectura:{
        background:'#fff',
        width:'1160px',
        height:'100%',
        padding:'57px 167px 35px' ,
        [theme.breakpoints.down(1161)]:{
            width:'502px',
            padding:'60px 0 0',
        },
        [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
        '& .ContainerDerechos':{
            bottom:'80px',
            [theme.breakpoints.down(1161)]:{
                display:'none',
            }
        },
        '& .Derechos':{
            textAlign:"center",
            margin:"0px",
            font:" 15px lato",
            color:"#717476",
        },
        '& .Escritorio':{
            display:'block',
            [theme.breakpoints.down(1161)]:{
                display:'none'
            },
        },
        '& .Tablet':{
            display:'none',
            [theme.breakpoints.down(1161)]:{
                display:'block'
            },
        },
    },
    ContainerTitulo:{
        margin:'0 0 47px 0',
        [theme.breakpoints.down(1161)]:{
            margin:'0 0 38px 0',
        },
        '& .Titulo':{
            font:'48px fredoka one',
            letterSpacing: '0px',
            color: '#161CB1',
            margin:'0',
            textAlign:'center',
            [theme.breakpoints.down(1161)]:{
                font:'43px fredoka one',
                lineHeight:'52px',
            },
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
      
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            lineHeight : '30px',
            margin:'0 0 36px 0 ',
            font:'22px lato',
            color:'#000',
            letterSpacing: '0.20px',
            [theme.breakpoints.down(1161)]:{
                font:'18px lato',
                letterSpacing:'.17px',
                lineHeight:'28px',
            },
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .SubTitulo':{
            font:'bold 30px lato',
            letterSpacing: '0px',
            color: '#00ADB6',
            margin:'0',
            [theme.breakpoints.down(1161)]:{
                font:'bold 26px lato',
                letterSpacing:'.24px',
                lineHeight:'28px',
            },
            [theme.breakpoints.down(502)]:{
                font:'bold 22px lato',
                lineHeight:'24px',
            },
            [theme.breakpoints.down(350)]:{
                font:'bold 18px lato',
                lineHeight:'24px',
            },
        },
        '& .Imagen':{
            [theme.breakpoints.down(1161)]:{
                width:'100%',
            },
        }

    },

}));

const Una_ética_para_el_mundo_digital = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
             <Grid container item justifyContent="center" className={classes.ContainerInfoLectura}>
               <Grid container className="BarraInfoLectura"></Grid>
               <Grid container item className="InfoLectura">
                    <Grid container item direction="column" xs={6} justifyContent="center">
                        <p className="Dato">Grado</p>
                        <p className="Caracter">6</p>
                    </Grid>
                    <Grid container item direction="column" xs={6} justifyContent="center" className="Sesion">
                        <p className="Dato">Sesión</p>
                        <p className="Caracter">38</p>
                    </Grid>
               </Grid>
            </Grid>

            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >
                        Una ética para el mundo digital
                    </h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                            <h3 className='SubTitulo'>
                                Eticistas concuerdan en que la ética digital
                                se vuelve más necesaria que nunca
                            </h3><br/>
                            Diferentes grupos de eticistas en diversas universidades del mundo discuten la 
                            necesidad de crear bases universales y sólidas para una ética digital.<br/><br/>

                            El mundo actual no se puede concebir sin el uso constante de internet. Sobre todo 
                            porque esta herramienta permite acceder rápidamente a una gran cantidad de 
                            información en poco tiempo. También es útil para diversas actividades que se han 
                            simplificado con su uso, como hacer compras, realizar operaciones bancarias y 
                            comunicarse con personas que están lejos.

                        </p>
                    </Grid>
                    <Grid container  sm={12}>
                            <img className='Imagen' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion38/1.png"  alt="Tarjeta" />
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                            Sin duda, internet y el mundo digital tienen muchos aspectos positivos, pero existe 
                            una contraparte negativa directamente relacionada con el comportamiento de las 
                            personas en la red.<br/><br/>

                            <h3 className='SubTitulo'>Acciones poco éticas en los entornos digitales</h3><br/>
                            
                            Una de las principales faltas que se comete por el uso de internet es el plagio. Como 
                            sabes, cuando buscas información para realizar alguna tarea o trabajo es fácil copiar 
                            y pegar la información tal como se presenta en la fuente. Sin embargo, utilizar la 
                            información sin complementarla con otras fuentes ni redactarla con tus propias 
                            palabras se convierte en un robo, es decir, en plagio.<br/><br/>

                            Estas faltas pueden ocurrir por el desconocimiento sobre cómo utilizar la 
                            información que encuentras en la red, pero también hay personas que incurren en la 
                            falta a propósito, porque creen que nadie se va a enterar. En cualquier caso, puede 
                            llegar incluso a ser un delito.<br/><br/>
                            
                            Lamentablemente, en la actualidad, el plagio es una práctica muy extendida entre 
                            los estudiantes de todos los niveles, pues muchos prefieren buscar y copiar las
                            tareas de la escuela que realizarlas por ellos mismos.<br/><br/>
                            
                            Otro problema importante derivado del uso de internet es el robo de información 
                            personal. En diferentes aplicaciones y sitios web se solicitan ciertos datos 
                            personales, los cuales pueden ser robados de diferentes maneras. A veces por 
                            <em>hackers</em> que se dedican a buscar ese tipo de información en la red. Otras, porque las 
                            empresas que solicitan nuestros datos los venden. Incluso porque las personas 
                            solemos publicar en las redes sociales demasiadas cuestiones personales, lo cual 
                            vuelve sencillo para los delincuentes enterarse de lo que hacemos y utilizar en 
                            nuestra contra la información que obtienen.
                        </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                            <img className='Imagen' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion38/2.png"  alt="Tarjeta" />
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                        <h3 className='SubTitulo'>Cómo combatir los abusos en la red</h3>
                        ¿Qué se puede hacer para evitar que estos malos comportamientos ocurran en la 
                        red? Por una parte, se está trabajando en crear un mayor número de candados 
                        digitales que vuelvan más difícil obtener la información sensible que la personas 
                        comparten en determinadas páginas. También se están estableciendo penas para 
                        quienes cometen delitos digitales, como el plagio, el robo de identidad y el <em>hackeo</em> 
                        de cuentas bancarias.<br/><br/>

                        Además de estas medidas tecnológicas y legales que poco a poco se van 
                        implementando, la verdadera clave radica en el propio comportamiento de los 
                        usuarios de la red, lo cual es un proceso complejo que lleva tiempo y observación.<br/><br/>

                        Por ello muchos eticistas plantean como objetivo de su trabajo la construcción de 
                        una ética digital que permita establecer ciertos principios y valores éticos que guíen 
                        a la gente a la hora de actuar en la red.<br/><br/>
                        
                        Durante siglos la ética ha intentado ayudar a los seres humanos a distinguir entre 
                        acciones correctas e incorrectas, buenas y malas. Las líneas que separan lo que está 
                        bien de lo que está mal parecen muy confusas y hasta invisibles cuando se trata 
                        de la red. Una persona puede tener claro que quedarse con un objeto que no es suyo 
                        es incorrecto, que es éticamente reprobable. Pero esa misma persona podría 
                        considerar que quedarse con palabras que no son suyas y que encontró en la red no 
                        es algo incorrecto, porque al fin y al cabo para eso está esa información en internet.<br/><br/>

                        Por ello se trabaja en el establecimiento de lineamientos básicos y universales sobre 
                        el comportamiento correcto en el uso de internet, acompañados de una necesaria 
                        campaña de educación ética.
                        </p>
                    </Grid>
                </Grid>

                <Grid container item xs={12} justifyContent="center" className="ContainerDerechos">
                    <p className="Derechos">
                        © 2022. Todos los derechos reservados, Pearson, Circuito de lectores.
                    </p>                
                </Grid>
        </Grid>
    </Grid>
    );
}
 
export default Una_ética_para_el_mundo_digital;
