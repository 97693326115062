import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
    ContenedorLectura:{
        width:'502px',
                  [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
    },
    ContainerTitulo:{
        margin:'0 0 34px 0',
        '& .Titulo':{
            letterSpacing: '0px',
            color: '#F6664D',
            margin:'0',
            textAlign:'center',
            font:'43px fredoka one',
            lineHeight:'52px',
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            margin:'0 0 36px 0',
            color:'#000',
            font:'18px lato',
            lineHeight :'28px',
            letterSpacing: '0.17px',
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .Imagen':{
            width:'100%',
        },  

      
    }
}));

const Lógica_creatividad_y_diversión_en_las_matemáticas = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >
                        Compañeros de juego
                    </h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                      <p className="Lectura">
                        En la Antigüedad sólo existían dos estaciones del año y eran el resultado del humor 
                        de los dos hijos menores del Gran Dios. Primavera era amable y cálida, sonriente y 
                        colorida como los campos de flores. En cambio, Invierno era frío, severo y gris como 
                        las tormentas de nieve.<br/><br/>
                        
                        Siempre estaban jugando, porque eran pequeños. El que ganaba transmitía su 
                        carácter a los seres humanos y a la Tierra. Si ella triunfaba, en el mundo salía el Sol, 
                        las plantas florecían, los pájaros cantaban y todo se teñía de colores alegres. Pero si
                        vencía él, el Sol se ocultaba detrás de las nubes, los campos se secaban, los pájaros se quedaban 
                        en silencio y el paisaje se tornaba sombrío.
                      </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img2do/Sesion49/1.png" alt="Tarjeta" />
                    </Grid>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                      <p className="Lectura">
                        Los cambios en la Tierra eran bruscos: un día hacía calor y al siguiente, de la nada, 
                        frío. Esto afectaba a las personas, ya que no podían sembrar y lo poco que cultivaban 
                        en un día caluroso se perdía enseguida con la helada invernal. <br/><br/>
                        
                        —Esto no puede seguir así —se dijeron los seres humanos.<br/><br/>

                        —No podemos vivir tranquilamente con estos cambios abruptos.<br/><br/>
                        
                        —Pero ¿qué podemos hacer? <br/><br/>
                        
                        Los ancianos aconsejaron hablar con el Gran Dios. Nombraron a una mensajera y la 
                        prepararon para el camino.
                      </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img2do/Sesion49/2.png" alt="Tarjeta" />
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                      <p className="Lectura">
                            Después de atravesar ríos, montañas, mares y desiertos, la mensajera llegó ante el 
                            Gran Dios y le pidió que detuviera esos cambios tan repentinos, porque afectaban la 
                            vida de las personas. El Gran Dios la escuchó con atención y le prometió que 
                            solucionaría el problema. <br/><br/>
                            
                            Llamó a sus hijos y les dijo: <br/><br/>
                            
                            —De ahora en adelante, tendrán dos compañeros de juego y cada uno compartirá 
                            con ustedes un poco de su carácter. <br/><br/>
                            
                            A uno lo llamó Verano y a la otra, Otoño. Los niños organizaron los juegos ya no para 
                            competir, sino para colaborar, de tal manera que Primavera le cedía su lugar a 
                            Verano, Verano a Otoño, y Otoño a Invierno.                      
                        </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img2do/Sesion49/3.png" alt="Tarjeta" />
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                            De este modo, los cambios de estación dejaron de ser drásticos, y los seres humanos 
                            pudieron prepararse y aprovecharlos. Aprendieron cuándo sembrar y cuándo cosechar, cuándo 
                            usar ropa ligera y cuándo abrigarse. <br/><br/>
                            
                            Esto hizo su vida más tranquila y alegre.                   
                        </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img2do/Sesion49/4.png" alt="Tarjeta" />
                    </Grid>
                </Grid>
        </Grid>
    </Grid>
    );
}
 
export default Lógica_creatividad_y_diversión_en_las_matemáticas ;
