import { Button, Grid, makeStyles } from "@material-ui/core";
import React, { useContext, useEffect } from "react";
import DrawerContext from "../../../../../usability/DrawerContext";
import { useOnLoadImages } from "../../../../hooks/useOnLoadImages";
import Loader from "../../../../Loader/Loader";
import Drawer from "../../ReadingTools/Drawer";

const useStyles =  makeStyles (theme =>({
      Contenedor:{
        background:'#E9FBF9',
        width:'100vw',
        overflowX:'clip',
        [theme.breakpoints.down(1161)]:{
            background:'none',
           overflowX:'unset',
        },
        [theme.breakpoints.down(600)]:{
            marginTop: '79.51px',
        }
    },
  ContainerInfoLectura: {
    position: "relative",
    height: "86px",
    [theme.breakpoints.down(502)]:{
        height: "43px",
    },
    background: "#76D4C3",
    "& .BarraInfoLectura": {
      height: "86px",
      background: `Url(https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Barras/Azul.png)`,
        [theme.breakpoints.down(502)]:{
            height: "43px",
        },
    },
    "& .InfoLectura": {
      position: "absolute",
      height: "86px",
      width: "219px",
      boxSizing: "border-box",
      padding: "17.2px 0",
      background: "#FAFAFA",
      borderRadius: "50px",
      boxShadow: "2px 2px 2px 2px #00000029",
      zIndex: "4",
        [theme.breakpoints.down(502)]:{
            height: "43px",
            width: "109px",
            padding: "8.6px 0",
            borderRadius: "25px",
        },
    },
    "& .InfoLectura:before": {
      position: "absolute",
      display: "flex",
      boxSizing: "border-box",
      padding: "0 0 0 10px",
      justifyContent: "center",
      alignItems: "center",
      height: "39.88px",
      width: "39.88px",
      borderRadius: "50%",
      content: '""',
      boxShadow: "3px 0px .5px 0px #00000029",
      background: "#FAFAFA",
      left: "90px",
      top: "65px",
      transform: "rotate(0.25turn)",
      [theme.breakpoints.down(502)]:{
        left: "45px",
        top: "30px",
        height: "20.88px",
        width: "20.88px",
        },
    },
    "& .InfoLectura:after": {
      content: '""',
      height: "15px",
      width: "15px",
      borderTop: "2px solid #20A698",
      borderLeft: "2px solid #20A698",
      position: "absolute",
      bottom: "-10px",
      left: "Calc(50% - 8px)",
      transform: "rotate(0.625turn)",
      [theme.breakpoints.down(502)]:{
        height: "10px",
        width: "10px",
        left: "Calc(50% - 4.5px)",
        bottom: "-3px",
        borderTop: "1px solid #20A698",
        borderLeft: "1px solid #20A698",
        },
    },
    "& .Sesion": {
      borderLeft: "2px solid #D8D8D8",
    },
    "& .Dato": {
      margin: "0 0 5px 0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#20A698",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        margin: "0 0 2.5px 0",
        },
    },
    "& .Caracter": {
      margin: "0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#000000",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        },
    },
       '& .Regresar':{
        color: '#F2624F',
        border: '#F2624F 1px solid',
        height: '30px',
        width:'100px',
        borderRadius: '20px',
        letterSpacing: '0.14px',
        textTransform: 'none',
        background:'#FEFAF1',
        top:'calc(50% - 15px)',
        left:'5%',
        zIndex:4,
        [theme.breakpoints.down(502)]: {
          font:'bold 10px lato',
          width: "60px",
        },
      },
  },
    ContenedorLectura:{
        background:'#fff',
        width:'1160px',
        height:'100%',
        padding:'60px 168px 35px' ,
        
        transform: (props) =>
        props.scale > 1 ? `scale(${props.scale}) translate(0px, 10%)` : `none`,
        [theme.breakpoints.down(1161)]:{
            width:'502px',
            padding:'60px 0 0',
        },
        [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
        '& .ContainerDerechos':{
            bottom:'80px',
            [theme.breakpoints.down(1161)]:{
                display:'none',
            }
        },
        '& .Derechos':{
            textAlign:"center",
            margin:"0px",
            font:" 15px lato",
            color:"#717476",
        },
        '& .Escritorio':{
            display:'block',
            [theme.breakpoints.down(1161)]:{
                display:'none'
            },
        },
        '& .Tablet':{
            display:'none',
            [theme.breakpoints.down(1161)]:{
                display:'block'
            },
        },

    },
    ContainerTitulo:{
        margin:'0 0 36px 0',
        [theme.breakpoints.down(1161)]:{
            margin:'0 0 38px 0',
        },
        '& .Titulo':{
            font:'48px fredoka one',
            letterSpacing: '0px',
            color: '#FF2B2B',
            margin:'0 0 15px 0',
            textAlign:'center',
            [theme.breakpoints.down(1161)]:{
                font:'43px fredoka one',
                lineHeight:'52px',
                textAlign:'center',
            },
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
        '& .SubTitulo':{
            font:'bold 30px lato',
            letterSpacing: '0px',
            color: '#FF2B2B',
            margin:'0',
            textAlign:'center',
            [theme.breakpoints.down(1161)]:{
                font:'bold 26px lato',
                letterSpacing:'.24px',
                lineHeight:'28px',
            },
            [theme.breakpoints.down(502)]:{
                font:'bold 22px lato',
                lineHeight:'24px',
            },
            [theme.breakpoints.down(350)]:{
                font:'bold 18px lato',
                lineHeight:'24px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            lineHeight : '30px',
            margin:'0 0 36px 0 ',
            font:'22px lato',
            color:'#000',
            letterSpacing: '0.20px',
            [theme.breakpoints.down(1161)]:{
                font:'18px lato',
                letterSpacing:'.17px',
                lineHeight:'28px',
            },
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .Imagen':{
           [theme.breakpoints.down(1161)]:{
            width:'100%',
        },
        },
    },
    

}));

const La_infanta_Castile_y_el_insólito_mundo_de_Anomalía_Capitulo_2 = (props) => {

  const {activeStep, setActiveStep, handlePreviewStep, id_actividad} = props

  const handleContinue = () => {
    setActiveStep(activeStep - 1)
    handlePreviewStep()
  }

    const {
    handleSize,
    disabledZoom,
    closeZoom,
    handleZoom,
    scale
  }= useContext(DrawerContext);
  const [loading, setLoading] = React.useState(true);
  const wrapperRef = React.useRef(null);
  const imagesLoaded = useOnLoadImages(wrapperRef);

  const detectSize = () => {
    const lectura = document.getElementById("lectura");
    const newWidth = lectura?.offsetWidth;
    const newHeight = lectura?.offsetHeight;
    setLoading(false);
    if (lectura) handleSize(newHeight, newWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", detectSize);
    return () => {
      handleZoom()
      window.removeEventListener("resize", detectSize);
      closeZoom()
    };
  }, []);

  const classes = useStyles({ disabledZoom,scale });
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
             <Grid container item justifyContent="center" className={classes.ContainerInfoLectura}>
               <Grid container className="BarraInfoLectura">
                    <Button className="Regresar" onClick={handleContinue}>
                        Regresar
                    </Button>
                </Grid>
               <Grid container item className="InfoLectura">
                    <Grid container item direction="column" xs={6} justifyContent="center">
                        <p className="Dato">Grado</p>
                        <p className="Caracter">6</p>
                    </Grid>
                    <Grid container item direction="column" xs={6} justifyContent="center" className="Sesion">
                        <p className="Dato">Sesión</p>
                        <p className="Caracter">25</p>
                    </Grid>
               </Grid>
            </Grid>
{!imagesLoaded && <Loader />}
        <Grid
            container
            item
            className={classes.ContenedorLectura}
            justifyContent="center"
            id="lectura"
            onLoad={detectSize}
            ref={wrapperRef}
        >
<Drawer id_lectura={id_actividad} />
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo Escritorio" >
                        La infanta Castile y el insólito<br/>
                        mundo de Anomalía
                    </h2>
                    <h2 className="Titulo Tablet" >
                        La infanta Castile  <br/>
                        y el insólito mundo <br/>
                        de Anomalía
                    </h2>
                    <h3 className='SubTitulo'>Fragmento 2 (capítulo 2)</h3>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura ">
                            El flacucho Camil tomó un saco mediano hecho de un material extraño. De su interior 
                            sacó una especie de esfera deforme y transparente, y la aventó a las manos de Wil.<br/><br/> 

                            —Es una lágrima de kraken —reveló—. ¿Sabes lo que es un 
                            kraken?<br/><br/>

                            Wil era un apasionado de las matemáticas, la física, la biología y  
                            la química. Todo mundo creía que de grande se convertiría en  
                            un científico o en un ingeniero, pero Wil en el fondo guardaba  
                            una pasión especial: la literatura. Cuando era niño su madre le 
                            obsequió una colección con las mejores obras de los escritores  
                            más importantes del mundo. Ahí se dejó maravillar por la 
                            literatura de viajes, su adicción. Por supuesto que Wil sabía lo 
                            que era un kraken: una bestia marina de la que muchos habían  
                            hablado sin poder comprobar su existencia. Por ello, cuando  
                            sostuvo aquella lágrima en sus manos sintió una fascinación absoluta. 
                        </p>
                    </Grid>
                    <Grid container  sm={12}>
                        <Grid container >
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion25/1.png" alt="Tarjeta" />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura ">
                            —Pero ¿qué tiene que ver la lágrima con la nave? —preguntó Wil, siempre buscando 
                            el sentido científico de todo. <br/><br/>

                            —¿Alguna vez te has preguntado cómo nacen las estrellas? Los marinos creemos que 
                            son lágrimas de kraken que se elevan cruzando todo el mar y continúan por la 
                            superficie hasta suspenderse en el cielo. Y es verdad. Por eso esta máquina no se 
                            hunde, porque esta lágrima la protege —le explicó Camil—. La mantengo conmigo 
                            dentro de ese bolso para evitar que se eleve, está hecho de piel de kraken. ¿Sabías 
                            que muda de piel como las serpientes?<br/><br/>

                            Por muy cautivadora que fuera toda aquella fantasía, un escalofrío recorrió el cuerpo 
                            de Wil cuando pensó en la posibilidad de que sus hermanas pudieran estar en 
                            peligro. <br/><br/>

                            —Tengo que ir a la superficie a buscar a mis hermanas —advirtió Wil a Camil. <br/><br/>

                            —Creí que eras un chico solitario como yo, ideal para la vida en el océano —replicó el 
                            buzo decepcionado. <br/><br/>

                            Camil se dirigió al lugar del capitán y se sentó. Su cuerpo parecía estar cada vez más 
                            débil y encorvado, semejante a una flor que no ha sido regada. <br/><br/>

                            —¿Te encuentras bien? —preguntó Wil. <br/><br/>

                            —He pasado tanto tiempo en las profundidades que mi cuerpo se averió. No es de 
                            oxígeno de lo único que vivimos, niño. También necesitamos luz —respondió Camil.<br/><br/> 

                            —Entonces deberíamos ir a la superficie —propuso el niño—. El sol te hará bien,
                            volverás a ser el de antes.<br/><br/>

                            —No es sólo de la luz solar de la que hablo, sino de esa que tú tienes de sobra. La luz 
                            de la compañía. Para mí ya es demasiado tarde —sentenció el joven resignado. <br/><br/>

                            No había que conocer demasiado a Camil para deducir que era un joven solitario y, 
                            aunque aparentaba ser fuerte, en el fondo su entereza se quebraba con el anhelo del 
                            abrazo de un ser querido. Wil sintió el deseo de ofrecerle su amistad, como hacía 
                            mucho no se la ofrecía a nadie por estar tan ocupado pensando en sí mismo, pero 
                            justo cuando procuró acercarse a Camil para estrechar su mano, un golpe seco y 
                            violento los sorprendió. La nave dio vueltas. <br/><br/>

                            —¡Es una medusa gigante! Tienes que apresurarte a salir de aquí —gritó el marino.<br/><br/>

                            Camil corrió hacia Wil y lo guio hacia el traje de buzo. <br/><br/>

                            —No puedo usarlo, no tiene sentido, no podré llegar a la superficie —le dijo Wil 
                            aterrado. <br/><br/>

                            Pero en tres hábiles movimientos Camil ya le había colocado la mayor parte del traje 
                            al chico. Un segundo golpe los sacudió y un brusco y continuo zigzagueo les reveló 
                            que aquel monstruo marino ya los tenía apresados entre sus tentáculos. <br/><br/>

                            —En cualquier momento nos empezará a atacar con choques eléctricos. No hay 
                            mucho tiempo —advirtió Camil.<br/><br/>

                            Y sus palabras se volvieron realidad: pronto comenzaron a sentir las sacudidas 
                            eléctricas que les provocaron dolorosos espasmos en el cuerpo. Camil le 
                            puso el casco a Wil y le entregó la lágrima de kraken. <br/><br/>

                            —Esto te llevará a la superficie, estamos cerca de donde hay tierra, podrás
                            arreglártelas. Toma. <br/><br/>

                            —No, te hundirás sin ella. <br/><br/>

                            —Es imposible que yo me hunda, muchacho. Mírame, yo ya soy el mar. <br/><br/>

                            Y de un golpe, Camil lo arrojó fuera de la nave. Wil, sosteniendo la lágrima, comenzó 
                            a elevarse dejando atrás a la medusa y a su amigo en un cruel y eléctrico abrazo. Todo 
                            sucedió como en sus libros, donde el capitán nunca abandona su nave y se mantiene 
                            firme con el honor propio de los valientes. 
                        </p>
                    </Grid>
                </Grid>
        </Grid>
        <Grid container item style={{ marginTop: 80 }}></Grid>
    </Grid>
    );
}
 
export default La_infanta_Castile_y_el_insólito_mundo_de_Anomalía_Capitulo_2;
