import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
     Contenedor:{
        background:'#E9FBF9',
        width:'100vw',
        overflowX:'clip',
        [theme.breakpoints.down(1161)]:{
            background:'none',
           overflowX:'unset',
        },
        [theme.breakpoints.down(600)]:{
            marginTop: '79.51px',
        }
    },
  ContainerInfoLectura: {
    position: "relative",
    height: "86px",
    [theme.breakpoints.down(502)]:{
        height: "43px",
    },
    background: "#76D4C3",
    "& .BarraInfoLectura": {
      height: "86px",
      background: `Url(https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Barras/Azul.png)`,
        [theme.breakpoints.down(502)]:{
            height: "43px",
        },
    },
    "& .InfoLectura": {
      position: "absolute",
      height: "86px",
      width: "219px",
      boxSizing: "border-box",
      padding: "17.2px 0",
      background: "#FAFAFA",
      borderRadius: "50px",
      boxShadow: "2px 2px 2px 2px #00000029",
      zIndex: "4",
        [theme.breakpoints.down(502)]:{
            height: "43px",
            width: "109px",
            padding: "8.6px 0",
            borderRadius: "25px",
        },
    },
    "& .InfoLectura:before": {
      position: "absolute",
      display: "flex",
      boxSizing: "border-box",
      padding: "0 0 0 10px",
      justifyContent: "center",
      alignItems: "center",
      height: "39.88px",
      width: "39.88px",
      borderRadius: "50%",
      content: '""',
      boxShadow: "3px 0px .5px 0px #00000029",
      background: "#FAFAFA",
      left: "90px",
      top: "65px",
      transform: "rotate(0.25turn)",
      [theme.breakpoints.down(502)]:{
        left: "45px",
        top: "30px",
        height: "20.88px",
        width: "20.88px",
        },
    },
    "& .InfoLectura:after": {
      content: '""',
      height: "15px",
      width: "15px",
      borderTop: "2px solid #20A698",
      borderLeft: "2px solid #20A698",
      position: "absolute",
      bottom: "-10px",
      left: "Calc(50% - 8px)",
      transform: "rotate(0.625turn)",
      [theme.breakpoints.down(502)]:{
        height: "10px",
        width: "10px",
        left: "Calc(50% - 4.5px)",
        bottom: "-3px",
        borderTop: "1px solid #20A698",
        borderLeft: "1px solid #20A698",
        },
    },
    "& .Sesion": {
      borderLeft: "2px solid #D8D8D8",
    },
    "& .Dato": {
      margin: "0 0 5px 0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#20A698",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        margin: "0 0 2.5px 0",
        },
    },
    "& .Caracter": {
      margin: "0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#000000",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        },
    },   },
    ContenedorLectura:{
        background:'#fff',
        width:'1160px',
        height:'100%',
        padding:'60px 168px 35px ',
        [theme.breakpoints.down(1161)]:{
            width:'502px',
            padding:'60px 0 0 0',
          },
        [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
        '& .ContainerDerechos':{
            bottom:'80px',
            [theme.breakpoints.down(1161)]:{
                display:'none',
            },
        },
        '& .Derechos':{
            textAlign:"center",
            margin:"0px",
            font:" 15px lato",
            color:"#717476",
        },
        '& .Escritorio':{
            display:'block',
            [theme.breakpoints.down(1161)]:{
                display:'none'
            },
        },
        '& .Tablet':{
            display:'none',
            [theme.breakpoints.down(1161)]:{
                display:'block'
            },
        }
    },
    ContainerTitulo:{
        margin:'0 0 34px 0',
        [theme.breakpoints.down(1161)]:{
            margin:'0 0 34px 0',
        },
        '& .Titulo':{
            letterSpacing: '0px',
            color: '#FF2364',
            margin:'0',
            textAlign:'center',
            font:'48px fredoka one',
            lineHeight:'58px',
            [theme.breakpoints.down(1161)]:{
                font:'43px fredoka one',
                lineHeight:'52px',
            },
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            font:'22px lato',
            color:'#000',
            margin:'0 0 36px 0',
            letterSpacing: '0.20px',
            lineHeight : '30px',
            [theme.breakpoints.down(1161)]:{
                font:'18px lato',
                lineHeight :'28px',
                letterSpacing: '0.17px',
            },
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .TextImg':{
            color:'#717476',
            margin:'21px 0 0 0',
            font:'20px lato',
            lineHeight:'30px',
            letterSpacing:'.19px',
            [theme.breakpoints.down(1161)]:{
                font:' 16px lato',
                lineHeight :'25px',
                letterSpacing: '0.15px',

            },
            [theme.breakpoints.down(502)]:{
                font:' 11px lato',
                lineHeight :'14px',
                letterSpacing: '0.10px',
            },
        },
        '& .Imagen':{
            [theme.breakpoints.down(1161)]:{
                width:'100%',
            },
        },   
    }
}));


const El_rey_Sol_autobiografía = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
             <Grid container item justifyContent="center" className={classes.ContainerInfoLectura}>
               <Grid container className="BarraInfoLectura"></Grid>
               <Grid container item className="InfoLectura">
                    <Grid container item direction="column" xs={6} justifyContent="center">
                        <p className="Dato">Grado</p>
                        <p className="Caracter">4</p>
                    </Grid>
                    <Grid container item direction="column" xs={6} justifyContent="center" className="Sesion">
                        <p className="Dato">Sesión</p>
                        <p className="Caracter">16</p>
                    </Grid>
               </Grid>
            </Grid>

            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >El rey Sol: autobiografía</h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container sm={12}>
                      <p className="Lectura"> 
                        Nací hace 4 600 millones de años en la Vía Láctea, específicamente en el brazo de 
                        Orión, un lugar lejano del centro de nuestra galaxia. Fui bautizado como Sol. Hace 
                        mucho tiempo, los griegos me llamaban <em>Helios</em>. ¿Será por eso que uno de los gases 
                        que forman parte de mí recibe el nombre de helio?<br/><br/> 
                        
                        Soy una estrella solitaria. Alrededor de mí giran ocho planetas, además de satélites y 
                        asteroides. En otros sistemas del universo puede haber más de uno como yo. Pero yo 
                        soy único, por eso me consideran un dios o un rey.
                      </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <Grid container justifyContent="center">
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img4to/Sesion16/1.png" alt="Tarjeta" />
                        </Grid>
                        <Grid container item >
                            <p className='TextImg'>
                                Nuestro Sol es solamente una entre tantas miles de estrellas en nuestra galaxia.
                            </p>
                        </Grid>
                    </Grid>
                </Grid>
               
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container sm={12}>
                      <p className="Lectura "> 
                        Soy redondo o casi redondo. Aunque me llaman <em>estrella enana</em>, mi superficie equivale 
                        a 109 planetas del tamaño de la Tierra. Yo soy la estrella más importante para mi 
                        familia, a pesar de que existan algunas más grandes que yo, como Betelgeuse o 
                        Aldebarán.<br/><br/> 
                        
                        Al igual que tú, estoy formado por elementos químicos. Tres cuartas partes de mi 
                        composición son hidrógeno, que es el elemento más simple del universo. También 
                        estoy hecho de helio y de otros elementos como el carbono y el hierro. En conclusión, 
                        estoy compuesto por algunos de los mismos elementos que tú y todos los seres vivos. <br/><br/>
                        
                        Mi cuerpo está formado por capas, como las de una cebolla. Mi capa más interna es el 
                        núcleo y tengo una corona que es radiante, como un rey. Pero mi cuerpo se divide en 
                        otras secciones y en cada una de ellas ocurre algo llamado <em>reacciones nucleares</em>. Las 
                        reacciones nucleares son fenómenos en los que los átomos unen sus núcleos. En mi 
                        caso, se unen dos núcleos de hidrógeno para formar un solo átomo de helio. Después 
                        de esa unión, suceden más y más, y esta suma produce una gran cantidad de energía.
                      </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <Grid container justifyContent="center">
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img4to/Sesion16/2.png" alt="Tarjeta" />
                        </Grid>
                        <Grid container item>
                            <p className='TextImg'>
                                El Sol está compuesto de distintas capas.
                            </p>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container sm={12}>
                      <p className="Lectura "> 
                        Aunque nací hace mucho tiempo, tengo mucha energía. Todas las reacciones y las 
                        explosiones que ocurren en mi superficie producen una temperatura de alrededor de 
                        5 500 ºC. ¡Y el núcleo incluso puede alcanzar los 15 000 ºC! Los físicos solares son 
                        como mis médicos y calculan que seguiré estando activo por otros 5 000 millones de 
                        años. Aún soy un muchacho, fuerte y activo.<br/><br/> 
                        
                        Mi familia se llama <em>Sistema solar</em> y estamos unidos por la fuerza de atracción que 
                        produce mi masa. ¡Soy tan grande que represento 99 % de toda la masa del sistema! 
                        La Tierra es el planeta en el que vives y orbita alrededor mío. Soy importante para 
                        todos, como ocurre con los reyes: envío energía a la Tierra en forma de luz y calor y 
                        ¡sin ella no podría existir la vida! <br/><br/>
                        
                        Con el calor ayudo a las plantas a realizar la fotosíntesis y doy energía a los animales.
                        Además, el clima de todos los planetas del Sistema solar depende de mí. A veces, soy 
                        un poco travieso y lanzo partículas en forma de vientos solares que pueden causar 
                        apagones o que los celulares no funcionen. ¡Qué desastre! <br/><br/>
                        
                        Me verás si volteas al cielo durante el día, pero procura hacerlo con lentes especiales 
                        para no lastimar tus ojos. Mi color suele cambiar dependiendo del lugar en que me 
                        mires, la hora del día y la estación del año. Unas veces luzco amarillo y otras, naranja.
                        Incluso puedo verme rojo o magenta y me convertiré en una estrella gigante roja 
                        cuando mi fin se acerque. <br/><br/>
                        
                        Un último dato sobre mí: la luz que envío a la Tierra necesita ocho minutos para 
                        llegar hasta ella, pues nos separan ¡150 millones de kilómetros!
                      </p>
                    </Grid>
                </Grid>
                
            <Grid container item xs={12} justifyContent="center" className="ContainerDerechos">
                <p className="Derechos">
                    © 2022. Todos los derechos reservados, Pearson, Circuito de lectores.
                </p>                
            </Grid>
        </Grid>
    </Grid>
    );
}
 
export default El_rey_Sol_autobiografía;
