import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
    ContenedorLectura:{
        width:'502px',
                  [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
    },
    ContainerTitulo:{
        margin:'0 0 61px 0',
        '& .Titulo':{
            letterSpacing: '0px',
            color: '#4D7BEB',
            margin:'0',
            textAlign:'center',
            font:'43px fredoka one',
            lineHeight:'52px',
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            margin:'0 0 36px 0 ',
            color:'#000',
            font:'18px lato',
            lineHeight :'28px',
            letterSpacing: '0.17px',
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .SubTitulo':{
            color:'#823CE2',
            margin:'0',
            lineHeight :'28px',
            font:'bold 26px lato',
            letterSpacing: '0.24px',
            [theme.breakpoints.down(502)]:{
                font:'bold 18px lato',
                lineHeight :'24px',
                letterSpacing: '0.21px',
            },
        },
        '& .Imagen':{
            width:'100%',
        },

        '& .Imagen2':{
            width:'69.72%',
        },
    },

}));

const Gráficas_en_todas_partes = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>

            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >
                        Gráficas<br className='Tablet'/> en todas partes
                    </h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                      <p className="Lectura">
                        En el mundo hay muchas cosas que a veces es necesario explicar. Una manera común 
                        de exponerlas es haciendo una <em>representación gráfica</em>. Pero, ¿qué es una
                        representación gráfica? Es una manera de presentar la información en las 
                        matemáticas por medio de líneas y palabras.<br/><br/> 
                        
                        Existen diferentes tipos de herramientas para hacer las representaciones gráficas. 
                        Por ejemplo, usando círculos, líneas o barras, según nos acomode más. En esta 
                        ocasión veremos la Teoría de Gráficas para explicar cómo funcionan las mismas.<br/><br/> 
                        
                        <h3 className='SubTitulo'>¿Cómo se ven las gráficas?</h3><br/>
                        Antes de empezar, es importante mencionar que las gráficas tienen dos partes que no pueden faltar. La primera tiene puntos y la segunda tiene líneas. De esta forma, cualquier gráfica se ve como un dibujo formado por puntos y líneas que los unen. ¿Conoces las constelaciones que hay en el cielo? Pues cada una de ellas podría verse como una gráfica porque las estrellas se dibujan como puntos y esos puntos forman figuras diferentes.
                      </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img4to/Sesion10/1.png" alt="Tarjeta" />
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                      <p className="Lectura">
                        <h3 className='SubTitulo'>¿Qué podemos representar con una gráfica?</h3><br/>
                        
                        Las gráficas pueden representar cualquier cosa del mundo. Para que una gráfica 
                        pueda leerse correctamente, es necesario aclarar qué significan los puntos y las
                        líneas. A continuación, te presentamos algunas representaciones para que conozcas 
                        su función. <br/><br/>
                        
                        Piensa en diferentes tipos de familias. La primera familia está formada por un padre 
                        soltero y dos hijas. La segunda familia tiene un padre, una madre y tres hijos. 
                        Finalmente, la tercera familia está conformada por dos madres, una hija y un hijo. 
                        Ahora, veamos cómo serían las gráficas que representan a cada una de estas familias.<br/><br/>
                        
                        En la primera hay tres personas: el padre y las dos hijas. A cada uno de ellos se le 
                        representará con un vértice. En este caso, te indicaremos qué significa cada vértice 
                        para que comprendas cómo está hecha la gráfica.
                      </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <img className="Imagen2" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img4to/Sesion10/2.png" alt="Tarjeta" />
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                      <p className="Lectura">
                        Ahora, busca una manera de unir los vértices. Dibuja una arista entre dos vértices 
                        donde un vértice represente al papá y otro represente a una hija. Siguiendo esta 
                        regla, se obtiene la siguiente gráfica:
                      </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <img className="Imagen2" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img4to/Sesion10/3.png" alt="Tarjeta" />
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                      <p className="Lectura">
                        Podrás continuar usando el mismo procedimiento para la segunda familia. Recuerda 
                        que esta familia tiene un padre, una madre y tres hijos. Une un vértice con otro 
                        cuando un vértice sea el papá y otro vértice sea un hijo. Tu gráfica quedaría así:
                      </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <img className="Imagen2" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img4to/Sesion10/4.png" alt="Tarjeta" />
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                      <p className="Lectura">
                        En la tercera familia hay dos madres, una hija y un hijo. Procura representar a todos 
                        los miembros con un vértice. Usa la misma regla que en las anteriores familias y une 
                        un vértice con otro donde uno sea una mamá y otro sea un hijo o una hija. De esta 
                        forma, tu gráfica sería la siguiente:
                      </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <img className="Imagen2" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img4to/Sesion10/5.png" alt="Tarjeta" />
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                      <p className="Lectura">
                        A continuación, usa otra gráfica para representar a las mismas familias. Cambia la 
                        manera de relacionar cada vértice. De esta forma, une un vértice con otro solamente 
                        si son hermanos. Las tres gráficas que se han visto anteriormente se transformarían 
                        para representar la relación entre hermanos:
                      </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <img className="Imagen2" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img4to/Sesion10/6.png" alt="Tarjeta" />
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                      <p className="Lectura">
                        Como puedes ver, no es necesario que todos los vértices estén unidos mediante una 
                        arista ni que las aristas sean líneas rectas. Esto sucede porque tú decides cómo 
                        dibujar las gráficas y qué reglas usar.<br/><br/> 
                        
                        <h3 className='SubTitulo'>Aplicaciones</h3><br/> 
                        
                        ¿Alguna vez has visto el mapa del metro en alguna ciudad? Pues bien, esos mapas 
                        también son gráficas. <br/> <br/> 
                        
                        La Teoría de Gráficas es una rama de las matemáticas con más aplicaciones en la vida 
                        cotidiana. Es útil porque permite representar una gran diversidad de situaciones y 
                        relaciones. Por ejemplo, ayuda a encontrar la ruta más corta entre dos ciudades o 
                        países y también aporta herramientas para estudiar idiomas, relacionar medios de 
                        comunicación y construir carreteras, entre muchas cosas más.
                      </p>
                    </Grid>
                </Grid>
        </Grid>
    </Grid>
    );
}
 
export default Gráficas_en_todas_partes;
