import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({

    ContenedorLectura:{
        width:'502px',
        [theme.breakpoints.down(502)]:{
            width:'350px',
        },
    },
    ContainerTitulo:{
        margin:'0 0 38px 0',
        '& .Titulo':{
            color: '#F78572',
            margin:'0',
            font:'43px fredoka one',
            lineHeight:'52px',
            textAlign:'center',
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 ',
        '& .Lectura':{
            margin:'0 0 36px 0 ',
            color:'#000',
            font:'18px lato',
            letterSpacing:'.17px',
            lineHeight:'28px',
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .SubTitulo':{
            background:'#69E6FF',
            borderRadius:'31px',
            alignSelf:'flex-start',
            color:'#FFFFFF',
            textAlign:'center',
            margin:'0',
            boxSizing:'border-box',
            height:'44px',
            width:'52px',
            font:'bold 26px lato',
            padding:'7px 0 ',
            [theme.breakpoints.down(502)]:{
                height:'34px',
                width:'42px',
                font:'bold 22px lato',
                padding:'3.5px 0 ',
            },
        },
        '& .Imagen':{
            width:'100%',
        }

    },

}));

const Postales_del_mar = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >
                        Postales del mar
                    </h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                            <h3 className='SubTitulo'>I</h3><br/>
                            El mar es un latido, <br/>
                            corazón de cristal y espuma, <br/>
                            espejo enfurecido del cielo.
                        </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                            <img className='Imagen' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion49/1.png"  alt="Tarjeta" />
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                            <h3 className='SubTitulo'>II</h3><br/>
                            El mar quiere consumirlo todo, <br/>
                            abrazarlo todo. <br/>
                            Por eso estira los brazos<br/>
                            con cada ola. <br/><br/>
                            
                            pero siempre se arrepiente<br/><br/>
                            
                             y retorna, <br/>
                             recoge los brazos, <br/>
                             guarda cada ola, <br/><br/>
                             
                             pero se arrepiente siempre <br/><br/>
                             
                             y vuelve, <br/>
                             estira los brazos, <br/>
                             regresa cada ola <br/><br/>

                             eternamente… <br/><br/>
                             
                             sin cansancio <br/>
                             porque el mar <br/>
                             no tiene memoria <br/>
                             ni historia: <br/>
                             nace cada día, <br/>
                             cada minuto. <br/><br/>
                             
                             El mar es un eterno recién nacido.
                        </p>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                            <h3 className='SubTitulo'>III</h3><br/>
                            Por el mar se llega al cielo: <br/>
                            si nadaras siempre en línea recta <br/>
                            hasta el horizonte, <br/>
                            en algún momento estarías en el cielo<br/>
                            entre nubes y estrellas, <br/>
                            como peces y espuma.
                        </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                            <img className='Imagen' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion49/2.png"  alt="Tarjeta" />
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                            <h3 className='SubTitulo'>IV</h3><br/>
                            El mar es también <br/>
                            una familia en la playa,<br/> 
                            pies en la arena dorada, <br/>
                            sol en la espalda, <br/>
                            luz en la mirada,<br/>
                            sonrisa en las estrellas, <br/>
                            palmeras, <br/>
                            gaviotas, <br/>
                            barcos, <br/>
                            despedidas… <br/><br/>
                            
                            El mar es todo. <br/>
                            El mar es nada. <br/><br/>

                            Un corazón, <br/>
                            latido de cristal y espuma.
                        
                        </p>
                    </Grid>
                </Grid>
        </Grid>
    </Grid>
    );
}
 
export default Postales_del_mar;
