import React               from "react";
import {Grid, makeStyles}                 from '@material-ui/core';

const useStyles = makeStyles(theme =>({
  questionContainer:{
    width: "100%",
    // height:"100%",
  },
  reading: {
    width: "80%",
    margin:"10px 0 10px 0 ",
    font: '22px lato',
    letterSpacing: '0.21px',
    [theme.breakpoints.down(1400)]:{
      font: " 18px lato",
      width: "85%",
    },
  },
  imgStyle: {
    alignItems: "center",
    width:'80%',
    // height:'110px',
    // [theme.breakpoints.down(1702)]:{
    //   width:'87px',
    //   height:'82px',
    // },
    // [theme.breakpoints.down(1404)]:{
    //   width:'77px',
    //   height:'72px',
    // },
    // [theme.breakpoints.down(1200)]:{
    //   width:'67px',
 
    // },
    // [theme.breakpoints.down(1064)]:{
    //   width:'100%',

    // },
    // [theme.breakpoints.down(460)]:{
    //   width:'100%',

    // },
  },
  tdTable: {
    padding: "0 1%",
    border: '1px solid black',
    textAlign: 'center'
  },
}));

const Question = (props) => {
  const classes = useStyles()
  // const { picturesTable } = props
  const {picture5,picture6,picture7,picture8,picture9,picture10} = props


  return(
    <div container className={classes.questionContainer}>
      <p 
        // dangerouslySetInnerHTML={{__html: `${step+1}. ${pregunta.replace( '\n', '<br/><br/>')}`}}
        className={classes.reading}
      >
        6. Observa los animales y elige los que están extintos.
      </p>
      <br/>
      <table>
      <thead>
          {/* <th style={{ border: '1px solid black',}}><i>Idea secundaria</i></th> */}
          {/* <th style={{width: "50%", border: '1px solid black',}}><i>Uso</i></th> */}
        </thead>
        <tbody>
          <tr>
            <td className={classes.tdTable}>
              <p>
                a 
              </p>
            </td>
            <td className={classes.tdTable}>
              <p>
                b 
              </p>
            </td>
            <td className={classes.tdTable}>
              <p>
                c 
              </p>
            </td>
          </tr>
          <tr>
            <td className={classes.tdTable}>
              <img src={picture5} alt="" className={classes.imgStyle}/>
            </td>
            <td className={classes.tdTable}>
              <img src={picture6} alt="" className={classes.imgStyle}/>
            </td>
            <td className={classes.tdTable}>
              <img src={picture7} alt="" className={classes.imgStyle}/>
            </td>
          </tr>
          <tr>
            <td className={classes.tdTable}>
              <p>
                d 
              </p>
            </td>
            <td className={classes.tdTable}>
              <p>
                e 
              </p>
            </td>
            <td className={classes.tdTable}>
              <p>
                f 
              </p>
            </td>
          </tr>
          <tr>
            <td className={classes.tdTable}>
              <img src={picture8} alt="" className={classes.imgStyle}/>
            </td>
            <td className={classes.tdTable}>
              <img src={picture9} alt="" className={classes.imgStyle}/>
            </td>
            <td className={classes.tdTable}>
              <img src={picture10} alt="" className={classes.imgStyle}/>
            </td>
          </tr>
        </tbody>
      </table>
        {/* <thead>
          <tr>
            <th>a</th>
            <th>b</th>
            <th>c</th>
            <th>d</th>
            <th>e</th>
            <th>f</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            {
              picturesTable.map((picture) => {
                // console.log(picture)
                return(
                  <td>
                    <img alt="img" src={picture} className={classes.imgStyle}/>
                  </td>
                )
              })
            }
          </tr>
        </tbody>
      </table> */}
    </div>
  )

}

export default Question;
