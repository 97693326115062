import { Button, makeStyles, Grid,  } from "@material-ui/core";
import React , {useState} from "react";
import SearchIcon from "@material-ui/icons/Search";
import TablaInfo from "./TablaInfo";
import ContainerAdmin from "../ContainerAdmin";
import UsabilityContext from "../../../../usability/UsabilityContext";
import ModalCrear from "./ModalCrear";

  
const useStyles = makeStyles((theme) => ({
  mainContainer: {
    position: "relative",
    minHeight: "92vh",
    padding: "48px 0 48px 0",
    background:
      "linear-gradient(217deg, rgba(255,194,0,0.4962185557816877) 2%, rgba(255,255,255,1) 25%, rgba(255,255,255,0.4962185557816877) 81%, rgba(144,81,177,1) 98%)",
  },
  boxContainer: {
    position:'relative',
    background: "#fff",
    width: "90%",
    gap: "20px",
    maxWidth: "1216px",
    maxHeight: "1000px",
    borderRadius: "42px",
    padding: "30px",
    boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.5)",
  },
  titleContainer: {
    // height: "60px",
    gap: "10px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    [theme.breakpoints.up("sm")]: {
      flexDirection: "row",
      justifyContent: "space-between",
    },
  },
  schoolName: {
    font: "22px fredoka one",
    margin: "0 ",
    // width: "100%",
    textAlign: "center",
    color: "#9051b1",
    [theme.breakpoints.up("lg")]: {
      // height: "31px",
      // width: "150px",
      fontSize: "28px",
    },
  },

  title: {
    font: "32px fredoka one",
    margin: "0 ",
    width: "100%",
    textAlign: "center",
    color: "#DC5EA6",
    [theme.breakpoints.up("lg")]: {
      // height: "31px",
      // width: "150px",
      fontSize: "42px",
    },
  },

  link: {
    cursor: "pointer",
    textDecoration: "none",
    height: "31px",
    width: "190px",
  },
  backButton: {
    cursor: "pointer",
    // minWidth: "100px",
    backgroundColor: "#FDEFF7",
    color: "#DC5EA6",
    border: "#DC5EA6 1px solid",
    borderRadius: "20px",
    textTransform: "none",
    font: "20px Lato",
    letterSpacing: "0.14px",
    display: "flex",
    alignItems: "center",

    // justifyContent: "space-around",
    [theme.breakpoints.down("770")]: {
      // height: "31px",
      // width: "150px",
      font: "16px Lato",
    },
  },
  addButton: {
    margin: "7px",
    cursor: "pointer",
    height: "35px",
    width: "180px",
    backgroundColor: "white",
    color: "#9051b1",
    // border: "#9051b1 1px solid",
    borderRadius: "20px",
    textTransform: "none",
    font: "20px Lato",
    letterSpacing: "0.14px",
    display: "flex",
    alignItems: "center",
    textDecoration: "none",

    // justifyContent: "space-around",
    [theme.breakpoints.down("770")]: {
      // height: "31px",
      // width: "150px",
      font: "16px Lato",
      margin: "5px",
    },
  },

  ContainerSerchBar: {
    display: "grid",
    gridTemplateColumns: "1fr",
    gridTemplateRows: "1fr",
    justifyItems: "center",
    alignItems: "center",
    gap: "10px",
    [theme.breakpoints.up("sm")]: {
      gridTemplateRows: "1fr",
      gridTemplateColumns: "1fr",
      justifyItems: "center",
    },
  },
  ContainerInput: {
    position: "relative",
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      height: "100%",
    },
    [theme.breakpoints.up("720")]: {
      width: "75%",
    },
    [theme.breakpoints.up("md")]: {
      width: "50%",
    },
  },

  SerchBar: {
    outline: "none",
    width: "100%",
    borderRadius: "36px",
    padding: "5px 32px",
    border: "4px solid #EA9D90",
    textAlign: "left",
    letterSpacing: "0.63px",
    color: "#7A54BA",
    font: "600 20px lato",
    // margin: " 0 10%",
    [theme.breakpoints.up("lg")]: {
      height: "auto",
      padding: "10px 32px",
    },
  },
  SerchBarIcon: {
    right: "23px",
    // top: "25px",
    position: "absolute",
    fontSize: "20px",
    color: "#7A54BA",
  },
  Boton:{
    position:'absolute',
    right:'30px',
    top:'50px',
    font:'lato 22px',
    textTransform:'none',
    color:'#fff',
    width:'180px',
    height:'40px',
    textAlign:'center',
    background:'#9051b1',
    borderRadius:'23px',
    '&:hover':{
      background:'#b459ed',
    },
  },


}));

const LMS = () => {
  const classes = useStyles();
  const { handleSearch, search, clearSearch } = React.useContext(UsabilityContext);
  
  const [openCrear, setOpenCrear] = useState(false);

  const handleOpenCrear = () => {
    setOpenCrear(true);
  };

  React.useEffect(() => {
    return () => {
      clearSearch();
    };
  }, []);

 

  return (
    <ContainerAdmin>
      <Grid container item sm={12} alignItems="center" justifyContent="center" className={classes.mainContainer}>
        <Grid container item direction="column" className={classes.boxContainer}>
          <Button className={classes.Boton} onClick={handleOpenCrear}>
              Agregar plataforma
          </Button>
          <h2 className={classes.title}>Integración LMS</h2>
          <Grid container direction="row" alignItems="center" justifyContent="center" className={classes.ContainerSerchBar}>
            <Grid container item justifyContent="center" alignItems="center" className={classes.ContainerInput}>
              <input
                className={classes.SerchBar}
                type="text"
                placeholder="Buscar plataformas"
                onChange={handleSearch}
                value={search}
              />
              <SearchIcon className={classes.SerchBarIcon} />
            </Grid>
          </Grid>
          <Grid container className={classes.studentListContainer} justifyContent="center" alignItems="center">
            <TablaInfo />
          </Grid>
        </Grid>
      </Grid>
      <ModalCrear open={openCrear} setOpen={setOpenCrear} />
    </ContainerAdmin>
  );
};

export default LMS;
