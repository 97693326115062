import { makeStyles, Grid, IconButton } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
// import StudentsList from "./StudentsList";

import axios from "../../../../services/axios";
import ContainerAdminPearson from "../ContainerAdminPearson";
import ModalConfirm from "../../../Modals/ModalConfirm";
import Loader from "../../../Loader/Loader";
import ModalError from "../../../Modals/ModalError";
import ModalErrorDetails from "../../../Modals/ModalErrorDetails";
import ModalMessage from "../../../Modals/ModalMessage";
import TeachersAvatarUploader from "../../Docente/TeachersAvatarUploader";
import { useParams, useHistory } from "react-router-dom";

import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import ModalConfirmErase from "../Modals/ModalConfirmErase";
import { DataGrid } from "@mui/x-data-grid";
import SelectGradeGroup from "./SelectGrades";
import ModalChangePasswordAdmin from "../../../Modals/ModalChangePasswordAdmin";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    padding: "48px 0 48px 0",
    width: "100vw",
    minHeight: "calc(100vh - 80px)",

    background:
      "linear-gradient(217deg, rgba(255,194,0,0.4962185557816877) 2%, rgba(255,255,255,1) 25%, rgba(255,255,255,0.4962185557816877) 81%, rgba(144,81,177,1) 98%)",
    [theme.breakpoints.up("sm")]: {
      // marginTop: "0",
    },
  },
  boxContainer: {
    background: "#fff",
    width: "80%",
    height: "auto",
    borderRadius: "42px",
    padding: "20px",
    alignItems: "center",
    gap: "20px",
    boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.5)",
    [theme.breakpoints.up("md")]: {
      padding: "48px 20px 48px 20px",
    },
  },
  titleContainer: {
    margin: "20px 0",
  },
  title: {
    font: "28px Fredoka One ",
    margin: "0",
    textAlign: "center",
    // marginBottom: "30px",
    color: " #DC5EA6",
    [theme.breakpoints.up("md")]: {
      fontSize: "36px",
    },
    [theme.breakpoints.up("xl")]: {
      fontSize: "42px",
    },
  },
  link: {
    cursor: "pointer",
    textDecoration: "none",
    // height: "31px",
    // width: "190px",
  },
  backButtonContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.up("sm")]: {
      justifyContent: "flex-start",
    },
  },
  backButton: {
    cursor: "pointer",
    height: "35px",
    // width: "200px",
    backgroundColor: "#FDEFF7",
    color: "#DC5EA6",
    border: "#DC5EA6 1px solid",
    borderRadius: "20px",
    textTransform: "none",
    font: "20px Lato",
    letterSpacing: "0.14px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
  },

  studentListContainer: {
    // margin: "10px",
    gap: "20px",
  },
  PurpleButton: {
    color: "#FFFFFF",
    backgroundColor: "#9051b1",
    borderRadius: "30px",
    alignSelf: "center",
    // margin: "0 auto",
    width: "100%",
    maxWidth: "238px",
    minHeight: "45px",
    border: "none",
    textTransform: "unset",
    cursor: "pointer",
    fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
    fontWeight: "500",
    padding: "6px 16px",
    lineHeight: "1.75",
    transition:
      "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    boxShadow:
      "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
    "&:hover": {
      backgroundColor: "#B965D3 ",
    },
  },
  WitheButton: {
    color: "#9051b1",
    // borderColor: "#9051b1",
    border: "1px solid #9051b1",
    backgroundColor: "#FFFFFF",
    borderRadius: "30px",
    alignSelf: "center",
    // margin: "0 auto",
    width: "100%",
    maxWidth: "238px",
    minHeight: "45px",
    textTransform: "unset",
    cursor: "pointer",
    fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
    fontWeight: "500",
    padding: "6px 16px",
    lineHeight: "1.75",
    transition:
      "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    boxShadow:
      "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
    "&:hover": {
      backgroundColor: "#B965D330 ",
    },
  },
  formContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    boxSizing: "border-box",
    alignItems: "center",
    gap: "20px",
  },
  formItemContainer: {
    gap: "10px",
    maxWidth: "560px",
  },
  formGroupsContainer: {
    gap: "20px",
    maxWidth: "560px",
  },
  input: {
    width: "100%",
    height: "40px",
    paddingLeft: "27px",
    paddingRight: "27px",
    borderRadius: "61px",
    boxSizing: "border-box",
    // border: " solid #1D2AAD 3px ",
    border: "none",
    background: "#FFFFFF",
    font: "Helvetica",
    fontSize: "16px",
    //    width: '449px',
    // height: '72px',
    // margin: '43px 139px 19px 66px',
    // padding: '26px 24px 25px',
    backgroundColor: "#f0f0f0",
    "&:focus": {
      outline: "none",
    },
    [theme.breakpoints.up("lg")]: {
      height: "42px",
      // maxWidth: "449px",
    },
  },
  label: {
    fontWeight: "bold",
    fontFamily: "Lato, sans-serif",
  },
  deleteButtonsContainer: {
    gap: "15px",
    // height: "580px",
  },
  buttonDelete: {
    width: "auto",
    backgroundColor: "#ffe0bb",
    borderRadius: "14px",
    fontFamily: "Lato",
    fontSize: "16px",
    lineHeight: "1.31",
    letterSpacing: "0.29px",
    color: "#000",
    "&:hover": {
      backgroundColor: "#ffe0bb70",
    },
  },
  table: {
    height: 580,
    width: "100%",
    "& .MuiDataGrid-root": {
      height: 580,
      width: "100%",
      "&$expanded": {
        height: 580,
      },
    },
    // "& .MuiDataGrid-cell": {
    //   overflowX: "visible",
    //   borderBottom: "1px solid rgba(224, 224, 224, 1)",
    //   "&$expanded": {
    //     overflow: "visible",
    //   },
    // },
  },
  buttonToggle: {
    cursor: "pointer",
    textDecoration: "underline",
  },
}));

const EditTeacher = () => {
  const classes = useStyles();
  const history = useHistory();
  const { id_usuario } = useParams();

  const [open, setOpen] = React.useState(false);

  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [modalMessageOpen, setModalMessageOpen] = useState(false);
  const [modalErrorDetailsOpen, setModalErrorDetailsOpen] = useState(false);
  const [modalErrorOpen, setModalErrorOpen] = useState(true);
  const [confirmChangesResult, setConfirmChangesResult] = useState("");
  const [resultError, setResultError] = useState("");

  const [openConfirmEraseModal, setOpenConfirmEraseModal] = useState(false);

  const [showGroupsToSelect, setShowGroupsToSelect] = useState(false);
  const [optionGrades, setOptionGrades] = useState([]);

  const [groupToErase, setGroupToErase] = useState(null);
  const [groupsErases, setGroupsErases] = useState([]);
  const [rows, setRows] = React.useState([]);

  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({});

  const [modalChangePasswordOpen, setModalChangePasswordOpen] = useState(false);

  const handleModalOpen = () => {
    setModalChangePasswordOpen(!modalChangePasswordOpen);
  };

  useEffect(() => {
    axios
      .get(`/listado/info/usuario?id_usuario=${id_usuario}`)
      .then((response) => {
        if (response.status === 200) {
          const data = response?.data?.resultado;
          setRows(
            data?.grupos?.map((grupo) => {
              return {
                ...grupo,
                asignado: "Asignado",
                id: grupo?.id_distribucion_escuela,
                combinacion: `${grupo?.grado}${grupo?.grupo}`,
              };
            })
          );
          setFormData({
            usuario: data?.nombre_usuario,
            nombre: data?.nombre,
            apellido_paterno: data?.apellido_paterno,
            apellido_materno: data?.apellido_materno,
            correo: data?.correo,
            id_escuela: data?.id_escuela,
          });
        }
      })
      .catch((error) => {
        console.log(error, "error");
      });
    return () => {};
  }, [id_usuario]);

  useEffect(() => {
    if (formData?.id_escuela) {
      setLoading(true);
      axios
        .get(`listado/grado/grupos?id_escuela=${formData?.id_escuela}`)
        .then((response) => {
          if (response.status === 200) {
            setOptionGrades(response?.data?.resultado?.listado);
          }
        })
        .catch((error) => {
          console.log(error, "error");
        });
      setLoading(false);
    }
  }, [formData?.id_escuela]);

  const handleCloseConfirmationModal = () => {
    setConfirmationModalOpen(!confirmationModalOpen);
  };
  const handleCloseMessageModal = () => {
    setModalMessageOpen(!modalMessageOpen);
    setResultError("");
  };
  const handleCloseErrorModal = () => {
    setModalErrorOpen(!modalErrorOpen);
  };
  const handleCloseErrorDetailsModal = () => {
    setModalErrorOpen(false);
    setModalErrorDetailsOpen(!modalErrorDetailsOpen);
  };

  const handleForm = (e) => {
    setFormData({
      ...formData,
      [e.target.name.toLowerCase()]: e.target.value,
    });
  };

  const handleGroups = () => {
    const groupsWithoutElement = rows.filter(
      (element) => element?.id !== groupToErase?.id
    );
    setRows(groupsWithoutElement);
    if (groupToErase?.id_distribucion_escuela) {
      setGroupsErases([...groupsErases, groupToErase]);
    }
    setOpenConfirmEraseModal(false);
  };

  const addGroupToList = () => {
    const groupToAdd = optionGrades
      .find((item) => item.id_grado === formData.grado)
      ?.grupos?.find((item) => item.id_grupo === formData?.grupo);

    //Total de docentes que posee el grupo segun la petición
    const totalTeachers = groupToAdd?.docentes;

    const newGroup = {
      grado: formData?.grado,
      id_grupo: formData?.grupo,
      id: Date?.now(),
      asignado: "Por asignar",
      combinacion: `${formData?.grado}${groupToAdd?.grupo}`,
      grupo: groupToAdd?.grupo,
      docentes: groupToAdd?.docentes,
    };
    const isInclude = rows.find(
      (row) => row?.combinacion === newGroup?.combinacion
    );
    if (!isInclude) {
      if (totalTeachers < 2) {
        setRows([...rows, newGroup]);
        setFormData({
          ...formData,
          grado: "",
          grupo: "",
        });
        setShowGroupsToSelect(false);
      } else {
        setModalMessageOpen(true);
        setConfirmChangesResult(
          "El grupo al que intentas vincular, ya cuenta con dos docentes."
        );
      }
    } else {
      setModalMessageOpen(true);
      setConfirmChangesResult(
        "El grupo que intenta agregar ya está en la lista"
      );
    }
  };

  const columns = [
    {
      field: "grado",
      headerName: "Grado",
      width: 150,
      headerAlign: "center",
      align: "center",
      editable: false,
    },
    {
      field: "grupo",
      headerName: "Grupo",
      width: 150,
      headerAlign: "center",
      align: "center",
      editable: false,
    },
    {
      field: "asignado",
      headerName: "Asignado",
      width: 120,
      editable: false,
    },
    {
      field: "delete",
      headerName: "Eliminar",
      width: 100,
      editable: false,
      renderCell: (params) => (
        <IconButton
          color="secondary"
          aria-label="delete"
          onClick={() => {
            console.log(params?.row);
            setGroupToErase(params?.row);
            setOpenConfirmEraseModal(true);
          }}
        >
          <DeleteForeverIcon />
        </IconButton>
      ),
    },
  ];

  const confirmChanges = () => {
    setConfirmationModalOpen(false);
    if (formData?.nombre && formData?.apellido_paterno && formData?.correo) {
      setLoading(true);
      const groupsToErase = groupsErases
        ?.map((grupo) => {
          return { id_distribucion_escuela: grupo?.id_distribucion_escuela };
        })
        .filter((group) => group?.id_distribucion_escuela);
      const newGroups = rows
        .filter((group) => !group?.id_distribucion_escuela)
        ?.map((group) => {
          return {
            id_grado: group?.grado,
            id_grupo: group?.id_grupo,
            // grupo: group?.grupo,
          };
        });

      const grupos = {
        añadir: newGroups,
        eliminar: groupsToErase,
      };
      const body = {
        id_escuela: formData?.id_escuela,
        id_usuario: Number(id_usuario),
        nombre: formData?.nombre,
        apellido_paterno: formData?.apellido_paterno,
        apellido_materno: formData?.apellido_materno,
        correo: formData?.correo,
        grupos,
      };
      axios
        .put("/listado/update/docente ", body)
        .then((response) => {
          if (response.status === 200) {
            setLoading(false);
            setConfirmationModalOpen(false);
            setModalMessageOpen(true);
            const newRows = rows.map((group) => {
              return {
                ...group,
                asignado: "Asignado",
              };
            });
            setRows(newRows);
            setConfirmChangesResult(
              "Los datos se han actualizado correctamente"
            );
          }
        })
        .catch((error) => {
          if (error.response) {
            // Request made and server responded
            const errorData = error.response.data;
          const mensaje =
            errorData?.detalles?.msg ||
            "Ha ocurrido un error al consumir los servicios, inténtelo de nuevo más tarde.";
            setConfirmChangesResult(mensaje);
            setConfirmationModalOpen(false);
            setModalMessageOpen(true);
          } else {
            // Something happened in setting up the request that triggered an Error
            const mensaje =
              "Ha ocurrido un error al consumir los servicios, inténtelo de nuevo más tarde.";
            setConfirmChangesResult(mensaje);
            setConfirmationModalOpen(false);
            setModalMessageOpen(true);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setConfirmationModalOpen(false);
      setModalMessageOpen(true);
      setConfirmChangesResult("Asegúrese de llenar todos los datos");
    }
  };

  return (
    <ContainerAdminPearson>
      <Grid
        container
        item
        sm={12}
        alignItems="center"
        justifyContent="center"
        className={classes.mainContainer}
      >
        <Grid
          container
          item
          direction="column"
          className={classes.boxContainer}
        >
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="center"
          >
            <Grid item className={classes.backButtonContainer}>
              <button
                className={classes.backButton}
                onClick={() => history.goBack()}
              >
                <ArrowBackIcon /> Volver
              </button>
            </Grid>
            <Grid
              container
              item
              alignItems="center"
              justifyContent="center"
              className={classes.titleContainer}
            >
              <h2 className={classes.title}>Editar perfil del docente</h2>
            </Grid>
          </Grid>
          <form
            className={classes.formContainer}
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <Grid
              container
              direction="column"
              className={classes.formItemContainer}
            >
              <label htmlFor="input-name" className={classes.label}>
                Nombre(s) *
              </label>
              <input
                type="text"
                className={classes.input}
                id="input-name"
                name="nombre"
                value={formData.nombre}
                onChange={handleForm}
                required
              />
            </Grid>
            <Grid
              container
              direction="column"
              className={classes.formItemContainer}
            >
              <label htmlFor="input-lastName" className={classes.label}>
                Primer apellido *
              </label>
              <input
                type="text"
                className={classes.input}
                id="input-lastName"
                name="apellido_paterno"
                value={formData.apellido_paterno}
                onChange={handleForm}
                required
              />
            </Grid>
            <Grid
              container
              direction="column"
              className={classes.formItemContainer}
            >
              <label htmlFor="input-secondLastName" className={classes.label}>
                Segundo apellido
              </label>
              <input
                type="text"
                name="apellido_materno"
                value={formData.apellido_materno}
                onChange={handleForm}
                className={classes.input}
                id="input-secondLastName"
                required
              />
            </Grid>

            <Grid
              container
              direction="column"
              className={classes.formItemContainer}
            >
              <label htmlFor="input-email" className={classes.label}>
                Correo electrónico *
              </label>
              <input
                type="email"
                className={classes.input}
                id="input-email"
                name="correo"
                value={formData.correo}
                onChange={handleForm}
                required
              />
            </Grid>
            <Grid
              container
              direction="column"
              className={classes.formItemContainer}
            >
              <label htmlFor="input-username" className={classes.label}>
                Usuario
              </label>
              <input
                type="text"
                placeholder="Nombre de usuario"
                className={classes.input}
                id="input-username"
                name="usuario"
                value={formData.usuario}
                onChange={handleForm}
                readOnly
                disabled
              />
            </Grid>
            <Grid
              container
              direction="column"
              className={classes.formGroupsContainer}
            >
              <label className={classes.label}>
                {!showGroupsToSelect && "Si deseas agregar otro grupo, pulsa "}
                <span
                  onClick={() => {
                    setShowGroupsToSelect(!showGroupsToSelect);
                  }}
                  className={classes.buttonToggle}
                >
                  {!showGroupsToSelect ? "aquí. " : "Cancelar"}
                </span>
              </label>
              {showGroupsToSelect && (
                <>
                  {optionGrades && (
                    <SelectGradeGroup
                      list={optionGrades}
                      title="Grado"
                      fieldName='grado'
                      handleForm={handleForm}
                      defaultValue={formData?.grado}
                      value={formData?.grado}
                      formData={formData}
                    />
                  )}
                  {optionGrades && (
                    <SelectGradeGroup
                      list={
                        optionGrades.find(
                          (item) => item.id_grado === formData.grado
                        )?.grupos
                      }
                      title="Grupo"
                      fieldName='grupo'
                      handleForm={handleForm}
                      defaultValue={formData?.grupo}
                      value={formData?.grupo}
                      formData={formData}
                    />
                  )}
                  <button
                    className={classes.PurpleButton}
                    onClick={addGroupToList}
                    type="button"
                    disabled={!formData?.grado || !formData?.grupo}
                  >
                    Añadir grupo
                  </button>
                </>
              )}
            </Grid>
            <Grid
              container
              direction="column"
              className={classes.formItemContainer}
            >
              <label htmlFor="input-username" className={classes.label}>
                Grupos
              </label>
              <Grid
                container
                direction="row"
                className={classes.deleteButtonsContainer}
              >
                <DataGrid
                  rows={rows}
                  // rows={rows}
                  columns={columns}
                  autoHeight={true}
                  classes={classes.table}
                  className={classes.table}
                  pageSize={10}
                  rowsPerPageOptions={[10]}
                  // checkboxSelection
                  disableSelectionOnClick
                  disableColumnFilter
                  disableColumnMenu
                />
                {/* {groups?.map((grupo) => (
                  <Button
                    className={classes.buttonDelete}
                    onClick={() => {
                      setGroupToErase(grupo);
                      setOpenConfirmEraseModal(true);
                    }}
                    key={grupo?.id_distribucion_escuela}
                  >
                    {`${grupo?.grado}${grupo?.grupo}`}
                    <DeleteForeverIcon color="secondary" aria-label="delete" />
                  </Button>
                ))} */}
              </Grid>
            </Grid>

            <button
              className={classes.PurpleButton}
              onClick={handleCloseConfirmationModal}
              type="button"
            >
              Guardar cambios
            </button>
          </form>
          <button className={classes.WitheButton} onClick={handleModalOpen}>
            Cambiar contraseña
          </button>
        </Grid>
      </Grid>
      <ModalConfirm
        open={confirmationModalOpen}
        handleClose={handleCloseConfirmationModal}
        handleConfirm={confirmChanges}
      />
      {loading && <Loader />}

      <ModalError
        open={modalErrorOpen}
        handleClose={handleCloseErrorModal}
        type={resultError}
        handleNext={handleCloseErrorDetailsModal}
      />
      <ModalErrorDetails
        open={modalErrorDetailsOpen}
        handleClose={handleCloseErrorDetailsModal}
      />
      <ModalMessage
        open={modalMessageOpen}
        handleClose={handleCloseMessageModal}
        title={confirmChangesResult}
             textOfButton="Aceptar"
      />
      <ModalConfirmErase
        grupo={groupToErase}
        open={openConfirmEraseModal}
        handleClose={() => {
          setOpenConfirmEraseModal(!openConfirmEraseModal);
        }}
        handleConfirm={handleGroups}
      />
      <ModalChangePasswordAdmin
        open={modalChangePasswordOpen}
        handleClose={handleModalOpen}
        id_usuario={Number(id_usuario)}
      />
      <TeachersAvatarUploader open={open} setOpen={setOpen} />
    </ContainerAdminPearson>
  );
};

export default EditTeacher;
