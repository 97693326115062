import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
    ContenedorLectura:{
        width:'502px',
                  [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
    },

    ContainerTitulo:{
        margin:'0 0 36px 0',
        '& .Titulo':{
            letterSpacing: '0px',
            color: '#F6664D',
            margin:'0',
            textAlign:'center',
            font:'43px fredoka one',
            lineHeight:'52px',
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            margin:'0 0 36px 0 ',
            color:'#000',
            font:'18px lato',
            lineHeight :'28px',
            letterSpacing: '0.17px',
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },

        '& .SubTitulo':{
            margin:'0',
            color:'#00AED2',
            font:'bold 22px lato',
            lineHeight :'24px',
            letterSpacing: '0.21px',
            [theme.breakpoints.down(502)]:{
                font:'bold 18px lato',
                lineHeight :'20px',
                letterSpacing: '0.16px',
            },
        },

        '& .Imagen':{
            width:'100%'
        },

    },
    

}));

const La_educación_es_un_derecho_para_todos = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>

            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >
                        La educación es un<br/> derecho para todos
                    </h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                            ¿Alguna vez te ha despertado un adulto para ir a la escuela y has deseado no ir? ¿Has
                            pensado que no es justo que te obliguen a estudiar? Todos podemos tener un mal día
                            en el que creemos cosas como ésas. Pero tienes que saber que estudiar no es una 
                            obligación, sino un derecho. ¿Sabes cuál es la diferencia?<br/><br/> 

                            Todas las personas tienen derechos desde el momento en que nacen y nadie se los 
                            puede prohibir o arrebatar. Por ejemplo, la ley dice que todos los seres humanos 
                            tienen derecho a ser tratados con respeto y a que nadie les haga daño. Esto aplica 
                            para todas las personas, sin que influya su lugar de origen o de vivienda, sus 
                            características físicas, su edad, su sexo o ninguna otra circunstancia.<br/><br/> 
                            
                            Pero todos los derechos llevan consigo ciertas responsabilidades. La sociedad está 
                            comprometida a tratarte con respeto, pero tú también tienes el compromiso de 
                            tratar así a la sociedad. Es más fácil que se cumpla la ley y se respeten los derechos si 
                            todos cumplimos con nuestros deberes. ¿Tú practicas este valor en tu casa o en la 
                            escuela?<br/><br/> 
                            
                            <h3 className='SubTitulo'>Cuidado de la infancia</h3><br/>
                            
                            ¿Sabes por qué los niños están protegidos por la ley? Muchos niños trabajaban con 
                            los adultos en condiciones injustas dentro de fábricas cuando comenzaron a surgir 
                            las ciudades. Pero hubo movimientos sociales que buscaron crear conciencia en la 
                            sociedad y lograron difundir la idea de que la infancia merece ser una etapa en la cual 
                            crecer libre y plenamente.<br/><br/> 
                            
                            El 10 de diciembre de 1948 se estableció en la ciudad de París la Declaración 
                            Universal de los Derechos Humanos. Como su nombre lo indica, es un documento 
                            que contiene la lista de derechos que adquieren absolutamente todas las personas 
                            por el hecho de haber nacido. El 20 de noviembre de 1959 se estableció la 
                            Declaración de los Derechos del Niño y la Niña para garantizarles a las infancias 
                            educación, salud, tiempo para jugar y un entorno que las apoye.
                        </p> 
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img3ro/Sesion/1.png" alt="Tarjeta" />
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                            Actualmente todos los pequeños del mundo tienen derecho a aprender. Imagina lo 
                            que ocurre cuando no los dejan ir nunca a la escuela. No saben leer, escribir ni hacer 
                            operaciones matemáticas. No conocen la escala musical ni el nombre de ningún 
                            escritor. No saben cómo se llaman los órganos del cuerpo humano ni los países del 
                            mundo. Imagina cómo será su vida cuando crezcan y no conozcan muchas cosas. 
                            ¿Cómo crees que sería la tuya si no supieras nada de lo que has aprendido hasta 
                            ahora?
                        </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img3ro/Sesion/2.png" alt="Tarjeta" />
                    </Grid>
                </Grid>

                
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                            La educación es un derecho fundamental. Es una oportunidad de aprender cosas 
                            esenciales para tu futuro, por ejemplo, tener conciencia de que todos tienen 
                            derechos y que nadie puede quitárselos. <br/><br/> 
                            
                            Ahora, ¡a hacer valer ese derecho!
                        </p>
                    
                    </Grid>
                </Grid>
        </Grid>
    </Grid>
    );
}
 
export default La_educación_es_un_derecho_para_todos;
