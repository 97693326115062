import { Button, Grid, makeStyles } from "@material-ui/core";
import React, { useContext, useEffect,useState } from "react";
import DrawerContext from "../../../../../usability/DrawerContext";
import { useOnLoadImages } from "../../../../hooks/useOnLoadImages";
import Loader from "../../../../Loader/Loader";
import Drawer from "../../ReadingTools/Drawer";

const useStyle =  makeStyles (theme =>({
      Contenedor:{
        background:'#E9FBF9',
        width:'100vw',
        overflowX:'clip',
        [theme.breakpoints.down(1161)]:{
            background:'none',
           overflowX:'unset',
        },
        [theme.breakpoints.down(600)]:{
            marginTop: '79.51px',
        }
    },
  ContainerInfoLectura: {
    position: "relative",
    height: "86px",
    [theme.breakpoints.down(502)]:{
        height: "43px",
    },
    background: "#76D4C3",
    "& .BarraInfoLectura": {
      height: "86px",
      background: `Url(https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Barras/Azul.png)`,
        [theme.breakpoints.down(502)]:{
            height: "43px",
        },
    },
    "& .InfoLectura": {
      position: "absolute",
      height: "86px",
      width: "219px",
      boxSizing: "border-box",
      padding: "17.2px 0",
      background: "#FAFAFA",
      borderRadius: "50px",
      boxShadow: "2px 2px 2px 2px #00000029",
      zIndex: "4",
        [theme.breakpoints.down(502)]:{
            height: "43px",
            width: "109px",
            padding: "8.6px 0",
            borderRadius: "25px",
        },
    },
    "& .InfoLectura:before": {
      position: "absolute",
      display: "flex",
      boxSizing: "border-box",
      padding: "0 0 0 10px",
      justifyContent: "center",
      alignItems: "center",
      height: "39.88px",
      width: "39.88px",
      borderRadius: "50%",
      content: '""',
      boxShadow: "3px 0px .5px 0px #00000029",
      background: "#FAFAFA",
      left: "90px",
      top: "65px",
      transform: "rotate(0.25turn)",
      [theme.breakpoints.down(502)]:{
        left: "45px",
        top: "30px",
        height: "20.88px",
        width: "20.88px",
        },
    },
    "& .InfoLectura:after": {
      content: '""',
      height: "15px",
      width: "15px",
      borderTop: "2px solid #20A698",
      borderLeft: "2px solid #20A698",
      position: "absolute",
      bottom: "-10px",
      left: "Calc(50% - 8px)",
      transform: "rotate(0.625turn)",
      [theme.breakpoints.down(502)]:{
        height: "10px",
        width: "10px",
        left: "Calc(50% - 4.5px)",
        bottom: "-3px",
        borderTop: "1px solid #20A698",
        borderLeft: "1px solid #20A698",
        },
    },
    "& .Sesion": {
      borderLeft: "2px solid #D8D8D8",
    },
    "& .Dato": {
      margin: "0 0 5px 0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#20A698",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        margin: "0 0 2.5px 0",
        },
    },
    "& .Caracter": {
      margin: "0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#000000",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        },
    },
       '& .Regresar':{
        color: '#F2624F',
        border: '#F2624F 1px solid',
        height: '30px',
        width:'100px',
        borderRadius: '20px',
        letterSpacing: '0.14px',
        textTransform: 'none',
        background:'#FEFAF1',
        top:'calc(50% - 15px)',
        left:'5%',
        zIndex:4,
        [theme.breakpoints.down(502)]: {
          font:'bold 10px lato',
          width: "60px",
        },
      },
  },
    ContenedorLectura:{
        background:'#fff',
        width:'1160px',
        height:'100%',
        padding:'60px 119px 35px 168px ',
        
        transform: (props) =>
        props.scale > 1 ? `scale(${props.scale}) translate(0px, 10%)` : `none`,
        [theme.breakpoints.down(1161)]:{
            width:'502px',
            padding:'70px 0 0',
          },
        [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
        '& .ContainerDerechos':{
            bottom:'80px',
            [theme.breakpoints.down(1161)]:{
                display:'none',
            },
        },
        '& .Derechos':{
            textAlign:"center",
            margin:"0px",
            font:" 15px lato",
            color:"#717476",
        } ,
        '& .Escritorio':{
            display:'flex',
            [theme.breakpoints.down(1161)]:{
                display:'none'
            },
        },
        '& .Tablet':{
            display:'none',
            [theme.breakpoints.down(1161)]:{
                display:'flex'
            },
        },
        '& .Escritorio2':{
            display:'block',
            [theme.breakpoints.down(1161)]:{
                display:'none'
            },
        },
        '& .Tablet2':{
            display:'none',
            [theme.breakpoints.down(1161)]:{
                display:'block'
            },
        }
    },
    ContainerTitulo:{
        margin:'0 0 46px 0',
        '& .Titulo':{
            font:'48px fredoka one',
            letterSpacing: '0px',
            color: '#3E99E0',
            margin:'0',
            [theme.breakpoints.down(1161)]:{
                font:'43px fredoka one',
                lineHeight:'52px',
                textAlign:'center',
            },
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            lineHeight : '30px',
            margin:'0',
            font:'22px lato',
            color:'#000',
            letterSpacing: '0.21px',
            [theme.breakpoints.down(1161)]:{
                lineHeight :'28px',
                font:'18px lato',
                letterSpacing: '0.17px',
            },
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .Lectura2':{
            margin:'0 ',
            width:'91.43%'
        },

        '& .Referencias':{
            lineHeight : '30px',
            margin:'0 ',
            font:'22px lato',
            color:'#717476',
            letterSpacing: '0.21px',
            [theme.breakpoints.down(1161)]:{
                lineHeight :'28px',
                font:'18px lato',
                letterSpacing: '0.17px',
            },
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },

        '& .SubTitulo':{
            font:'bold 30px lato',
            color:'#F6644B',
            letterSpacing:'.28px',
            margin:'0',
            lineHeight:'30px',
            [theme.breakpoints.down(1161)]:{
                font:'bold 26px lato',
                letterSpacing:'.24px',
            },
            [theme.breakpoints.down(502)]:{
                font:'bold 18px lato',
                lineHeight :'24px',
                letterSpacing: '0.21px',
            },
        },
        '& .SubTitulo2':{
            font:'bold 26px lato',
            color:'#1A20AF',
            letterSpacing:'.28px',
            margin:'0',
            [theme.breakpoints.down(1161)]:{
                font:'bold 22px lato',
                letterSpacing:'.21px',
            },
            [theme.breakpoints.down(502)]: {
                font: "bold 18px lato",
                lineHeight: "20px",
                letterSpacing: "0.16px",
              },
        },
        '& .SubTitulo3':{
            font:'20px Fredoka One',
            color:'#DF5DA7',
            letterSpacing:'.28px',
            margin:'0 0 17px 0',
            [theme.breakpoints.down(1161)]:{
                font:'11px Fredoka One',
            },
        },

        '& .TextImg':{
            margin:'24px 0 0 0',
            font:'20px lato',
            alignSelf:'flex-start',
            color:'#717476',
            [theme.breakpoints.down(1161)]:{
                font:' 16px lato',
                letterSpacing:'.12px',
            },
            [theme.breakpoints.down(502)]:{
                font:' 11px lato',
                lineHeight :'14px',
                letterSpacing: '0.10px',
            },
        },
        '& .TextImg2':{
            margin:'0',
            font:'20px lato',
            alignSelf:'flex-start',
            color:'#717476',
            [theme.breakpoints.down(1161)]:{
                font:' 16px lato',
                letterSpacing:'.12px',
            },
            [theme.breakpoints.down(502)]:{
                font:' 11px lato',
                lineHeight :'14px',
                letterSpacing: '0.10px',
            },
        },
        '& .TextImg3':{
            margin:'36px 0 0 0',
            font:'20px lato',
            alignSelf:'flex-start',
            color:'#717476',
            [theme.breakpoints.down(1161)]:{
                font:' 16px lato',
                letterSpacing:'.12px',
                margin:'24px 0 0 0',
            },
            [theme.breakpoints.down(502)]:{
                font:' 11px lato',
                lineHeight :'14px',
                letterSpacing: '0.10px',
            },
        },
        '& .Imagen1':{
            width:'364px',
            [theme.breakpoints.down(1161)]:{
                width:'234px',
            },
            [theme.breakpoints.down(502)]:{
                width:'100%',
            },
            
        },
        '& .Imagen2':{
            height:'398px',
            [theme.breakpoints.down(502)]:{
                width:'100%',
            },
        },
        '& .Imagen3':{
            width:'100%',
        },
        '& .cuadro':{
            height:'144px',
            background:'#FDF6E1',
        },
        '& .Url':{
            color:'#1A20AF',
            textDecoration:'none',
        },
        '& .ContainerMapa':{
            position:'relative',
            height:'483px',
            width:'824px',
            [theme.breakpoints.down(1161)]:{
                width:'486px',
                height:'285px',
            },
            [theme.breakpoints.down(502)]:{
                width:'100%',
                height:'200px',
            },
        },
        '& .Mapa':{
            background:`url(https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion11/2.png)`,
            width:'100%',
            height:'100%',
            position:'absolute',
        },
        '& .Mapa1':{
            display:(props)=> props.Reset ? 'none':'flex',
            backgroundSize:'100% 100%',
        },
        '& .Mapa2':{
            display:(props)=> props.Boton1 ? 'flex':'none',
            backgroundSize:'230% 230%',
        },
        '& .Mapa3':{
            display:(props)=> props.Boton2 ? 'flex':'none',
            backgroundSize:'235% 235%',
            backgroundPositionY:'-360px',
            [theme.breakpoints.down(1161)]:{
                backgroundPositionX:'-90px',
                backgroundPositionY:'-215px',
            },
            [theme.breakpoints.down(502)]:{
                backgroundPositionX:'-64px',
                backgroundPositionY:'-157px',
            },
        },
        '& .Mapa4':{
            display:(props)=> props.Boton3 ? 'flex':'none',
            backgroundSize:'225% 225%',
            backgroundPositionX:'-650px',
            backgroundPositionY:'-240px',
            [theme.breakpoints.down(1161)]:{
                backgroundPositionX:'-350px',
                backgroundPositionY:'-140px',
            },
            [theme.breakpoints.down(502)]:{
                backgroundPositionX:'-246px',
                backgroundPositionY:'-96px',
            },
        },   
        '& .Mapa5':{
            display:(props)=> props.Boton4 ? 'flex':'none',
            backgroundSize:'230% 230%',
            backgroundPositionX:'-700px',
            [theme.breakpoints.down(1161)]:{
                backgroundPositionX:'-375px',
            },
            [theme.breakpoints.down(502)]:{
                backgroundPositionX:'-273px',
                backgroundPositionY:'0px',
            },
        },
        '& .Mapa6':{
            display:(props)=> props.Boton5 ? 'flex':'none',
            backgroundSize:'230% 230%',
            backgroundPositionX:'-1020px',
            backgroundPositionY:'-80px',
            [theme.breakpoints.down(1161)]:{
                backgroundPositionX:'-605px',
                backgroundPositionY:'-43px',
            },
            [theme.breakpoints.down(502)]:{
                backgroundPositionX:'-450px',
                backgroundPositionY:'-33px',
            },
        },
        '& .Mapa7':{
            display:(props)=> props.Boton6 ? 'flex':'none',
            backgroundSize:'230% 230%',
            backgroundPositionX:'-1072px',
            backgroundPositionY:'-406px',
            [theme.breakpoints.down(1161)]:{
                backgroundPositionX:'-630px',
                backgroundPositionY:'-215px',
            },
            [theme.breakpoints.down(502)]:{
                backgroundPositionX:'-456px',
                backgroundPositionY:'-147px',
            },
        },
        
        '& .Reset':{
            display:(props)=> props.Reset ? 'flex':'none',
            height:'483px',
            width:'824px',
            cursor:'pointer',
            background:'none',
            border:'none',
            position:'absolute',
        },

        '& .Boton':{
            width:'23px',
            height:'23px',
            borderRadius:'50%',
            border:'solid 5px #fff',
            background:'#DF5DA7',
            boxShadow:' 10px 5px 7px #00000029',
            cursor:'pointer',
            position:'absolute',
            [theme.breakpoints.down(1161)]:{
                width:'18px',
                height:'18px',
                border:'solid 3px #fff',
            },
            [theme.breakpoints.down(502)]:{
                width:'10px',
                height:'13px',
                border:'solid 1px #fff',
            },
        },
        '& .Boton1':{
            display:(props)=> props.Reset ? 'none':'flex',
            left:'120px',
            top:'90px',
            [theme.breakpoints.down(1161)]:{
                left:'63px',
                top:'46px',
            },
            [theme.breakpoints.down(502)]:{
                left:'45px',
                top:'40px',
            },
        },
        '& .Boton2':{
            display:(props)=> props.Reset ? 'none':'flex',
            left:'191px',
            top:'248px',
            [theme.breakpoints.down(1161)]:{
                left:'116px',
                top:'141px',
            },
            [theme.breakpoints.down(1161)]:{
                left:'90px',
                top:'100px',
            },
        },
        '& .Boton3':{
            display:(props)=> props.Reset ? 'none':'flex',
            left:'405px',
            top:'248px',
            [theme.breakpoints.down(1161)]:{
                left:'231px',
                top:'144px',
            },
            [theme.breakpoints.down(502)]:{
                left:'170px',
                top:'100px',
            },
        },
        '& .Boton4':{
            display:(props)=> props.Reset ? 'none':'flex',
            left:'329px',
            top:'63px',
            [theme.breakpoints.down(1161)]:{
                left:'187px',
                top:'40px',
            },
            [theme.breakpoints.down(502)]:{
                left:'140px',
                top:'30px',
            },
        },
        '& .Boton5':{
            display:(props)=> props.Reset ? 'none':'flex',
            left:'720px',
            top:'63px',
            [theme.breakpoints.down(1161)]:{
                left:'441px',
                top:'75px',
            },
            [theme.breakpoints.down(502)]:{
                left:'320px',
                top:'50px',
            },
        },
        '& .Boton6':{
            display:(props)=> props.Reset ? 'none':'flex',
            left:'668px',
            top:'322px',
            [theme.breakpoints.down(1161)]:{
                left:'392px',
                top:'181px',
            },
            [theme.breakpoints.down(502)]:{
                left:'290px',
                top:'130px',
            },
        }
    },

}));

const Seguridad_alimentaria_y_quinua = (props) => {
    
    const {activeStep, setActiveStep, handlePreviewStep, id_actividad} = props

    const handleContinue = () => {
      setActiveStep(activeStep - 1)
      handlePreviewStep()
    }

  
      const {
      handleSize,
      disabledZoom,
      closeZoom,
      handleZoom,
     scale
    } = useContext(DrawerContext);
    const [loading, setLoading] = React.useState(true);
    const wrapperRef = React.useRef(null);
    const imagesLoaded = useOnLoadImages(wrapperRef);
  
    const detectSize = () => {
      const lectura = document.getElementById("lectura");
      const newWidth = lectura?.offsetWidth;
      const newHeight = lectura?.offsetHeight;
      setLoading(false);
      if (lectura) handleSize(newHeight, newWidth);
    };
    useEffect(() => {
      window.addEventListener("resize", detectSize);
      return () => {
        handleZoom()
        window.removeEventListener("resize", detectSize);
        closeZoom()
      };
    }, []);

    const [Reset,Zoom1] = useState(false);
    const [Boton1,Zoom2] = useState(false);
    const [Boton2,Zoom3] = useState(false);
    const [Boton3,Zoom4] = useState(false);
    const [Boton4,Zoom5] = useState(false);
    const [Boton5,Zoom6] = useState(false);
    const [Boton6,Zoom7] = useState(false);

    const classes = useStyle({
        Reset,Boton1,Boton2,Boton3,Boton4,Boton5,Boton6,
        disabledZoom,scale
        })

    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
             <Grid container item justifyContent="center" className={classes.ContainerInfoLectura}>
               <Grid container className="BarraInfoLectura">
                    <Button className="Regresar" onClick={handleContinue}>
                        Regresar
                    </Button>
                </Grid>
               <Grid container item className="InfoLectura">
                    <Grid container item direction="column" xs={6} justifyContent="center">
                        <p className="Dato">Grado</p>
                        <p className="Caracter">6</p>
                    </Grid>
                    <Grid container item direction="column" xs={6} justifyContent="center" className="Sesion">
                        <p className="Dato">Sesión</p>
                        <p className="Caracter">11</p>
                    </Grid>
               </Grid>
            </Grid>
{!imagesLoaded && <Loader />}
        <Grid
            container
            item
            className={classes.ContenedorLectura}
            justifyContent="center"
            id="lectura"
            onLoad={detectSize}
            ref={wrapperRef}
        >
<Drawer id_lectura={id_actividad} />
                <Grid container item xs={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo Escritorio" >Seguridad alimentaria y quinua</h2>
                    <h2 className="Titulo Tablet" >Seguridad alimentaria<br/> y quinua</h2>
                </Grid>

                <Grid container item xs={12} className={classes.Parrafo1} justifyContent="center">

                    <Grid container className='Escritorio'>
                        <Grid container xs={6} direction='column' justifyContent="flex-end">
                            <p className="Lectura">
                                Actualmente, uno de los mayores retos que <br/>
                                enfrenta la humanidad es conseguir la  <br/>
                                seguridad alimentaria. ¿Qué es esto? ¿Por  <br/>
                                qué es un asunto que nos tiene que <br/>
                                preocupar?  <br/> <br/>

                                El asunto es tan importante que la  <br/>
                                Organización de las Naciones Unidas  <br/>
                                cuenta con una dependencia que se  <br/>
                                encarga de ello. Se llama <span style={{fontVariant: "all-small-caps"}}>FAO</span>, por sus siglas  <br/>
                                en inglés: <em>Food and Agricultural Organization</em>.  <br/>
                                De acuerdo con ella, la seguridad  <br/>
                                alimentaria ocurre cuando:
                            </p>
                        </Grid>
                        <Grid container justifyContent="center" alignItems='flex-start' xs={6}>
                            <Grid container  >
                                <img className="Imagen1" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion11/1.png" alt="Tarjeta" />
                            </Grid>
                            <Grid>
                                <p className='TextImg2'>La frase <em>fiat panis</em> parte del escudo de la <br/>
                                FAO; significa “hágase el pan”, en latín.</p>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid container className='Tablet'>
                        <Grid container xs={12}>
                            <p className="Lectura">
                                Actualmente, uno de los mayores retos que enfrenta la 
                                humanidad es conseguir la seguridad alimentaria. ¿Qué es
                                esto? ¿Por qué es un asunto que nos tiene que preocupar?<br/><br/>
                            </p>
                        </Grid>
                        <Grid container xs={6} direction='column' justifyContent="flex-end">
                            <p className="Lectura ">
                                El asunto es tan importante  
                                que la Organización de las  
                                Naciones Unidas cuenta con  
                                una dependencia que se
                                encarga de ello. Se llama <span style={{fontVariant: "all-small-caps"}}>FAO</span>,  
                                por sus siglas en inglés: <em>Food
                                and Agricultural Organization</em>.
                                De acuerdo con ella, la  
                                seguridad alimentaria ocurre
                                cuando:
                            </p>
                        </Grid>
                        <Grid container justifyContent="center" alignItems='flex-start' xs={6}>
                            <Grid container  >
                                <img className="Imagen1" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion11/1.png" alt="Tarjeta" />
                            </Grid>
                            <Grid>
                                <p className='TextImg2 '>La frase <em>fiat panis</em> parte del escudo de la 
                                FAO; significa “hágase el pan”, en latín.</p>
                            </Grid>
                        </Grid>
                    </Grid>

                </Grid>
                   
                <Grid container item xs={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container xs={12} className='cuadro' justifyContent='center' alignContent='center' >
                        <p className="Lectura Lectura2 "> 
                            Todas las personas tienen en todo momento acceso físico,  
                            social y económico a alimentos suficientes y nutritivos  
                            que satisfacen las necesidades energéticas diarias y las 
                            preferencias alimentarias para llevar una vida sana.
                        </p>
                    </Grid>
                </Grid>
                <Grid container item xs={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container xs={12}>
                        <p className="Lectura">
                            <h3 className='SubTitulo'>
                            La insuficiencia de alimentos es un asunto real
                            </h3><br/>
                            Podrías pensar que el hecho de que nosotros y aquellos que nos rodean tengamos 
                            algo para comer y para beber no es un asunto que requiera de mucho esfuerzo, pero 
                            la realidad es que en estos momentos existen cerca de 690 millones de personas con 
                            hambre. Y se cree que en 2030 esta cifra llegará a los 840 millones de personas. <br/><br/>
                            
                            <h3 className='SubTitulo2'>Alimentos que nos aportan poco</h3><br/>
                            
                            Por otra parte, la seguridad alimentaria no sólo se relaciona con la falta de alimentos, 
                            sino también con la imposibilidad de acceder a alimentos nutritivos. Las personas 
                            pueden comer productos de bajo valor nutricional y que no son inocuos; es decir, que 
                            dañan la salud, como grasas y azúcares en exceso. Este tipo de ingredientes se suelen 
                            encontrar en cantidades no recomendables en alimentos procesados. Generalmente 
                            son baratos, pero no brindan los nutrientes necesarios para una vida saludable. <br/><br/>
                            
                            <h3 className='SubTitulo2'>Países y zonas de riesgo</h3><br/>
                            
                            La falta de seguridad alimentaria no afecta a todos los países del mundo por igual. 
                            Este problema es más frecuente en zonas del mundo con países de ingresos medios y 
                            bajos, como África, América Latina, el Caribe y algunas regiones de Asia. De todos 
                            éstos, África es el lugar en el que el problema de seguridad alimentaria es más grave. 
                            Lo anterior no quiere decir que en los países ricos no haya problemas, pero son 
                            menos comunes.
                        </p>
                        
                    </Grid>
                </Grid>

                <Grid container item xs={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container xs={12}>
                        <h3 className='SubTitulo3'>Mapa del hambre, 2019</h3>

                       <Grid className='ContainerMapa'>
                           <Grid className='Mapa Mapa1'></Grid>
                           <Grid className='Mapa Mapa2'></Grid>
                           <Grid className='Mapa Mapa3'></Grid>
                           <Grid className='Mapa Mapa4'></Grid>
                           <Grid className='Mapa Mapa5'></Grid>
                           <Grid className='Mapa Mapa6'></Grid>
                           <Grid className='Mapa Mapa7'></Grid>
                            <button className='Reset' onClick={()=> {
                                            Zoom1(false);
                                            Zoom2(false);
                                            Zoom3(false);
                                            Zoom4(false);
                                            Zoom5(false);
                                            Zoom6(false);
                                            Zoom7(false);
                                        } }></button>
                            <button className='Boton Boton1'  onClick={()=> {
                                            Zoom1(true);
                                            Zoom2(true);
                                            Zoom3(false);
                                            Zoom4(false);
                                            Zoom5(false);
                                            Zoom6(false);
                                            Zoom7(false);
                                        } }></button >
                            <button className='Boton Boton2'  onClick={()=> {
                                            Zoom1(true);
                                            Zoom2(false);
                                            Zoom3(true);
                                            Zoom4(false);
                                            Zoom5(false);
                                            Zoom6(false);
                                            Zoom7(false);
    
                                        } }></button >
                            <button className='Boton Boton3'  onClick={()=> {
                                            Zoom1(true);
                                            Zoom2(false);
                                            Zoom3(false);
                                            Zoom4(true);
                                            Zoom5(false);
                                            Zoom6(false);
                                            Zoom7(false);
                                        } }></button >
                            <button className='Boton Boton4'  onClick={()=> {
                                            Zoom1(true);
                                            Zoom2(false);
                                            Zoom3(false);
                                            Zoom4(false);
                                            Zoom5(true);
                                            Zoom6(false);
                                            Zoom7(false);
                                        } }></button >
                            <button className='Boton Boton5'  onClick={()=> {
                                            Zoom1(true);
                                            Zoom2(false);
                                            Zoom3(false);
                                            Zoom4(false);
                                            Zoom5(false);
                                            Zoom6(true);
                                            Zoom7(false);
                                        } }></button >
                            <button className='Boton Boton6'  onClick={()=> {
                                            Zoom1(true);
                                            Zoom2(false);
                                            Zoom3(false);
                                            Zoom4(false);
                                            Zoom5(false);
                                            Zoom6(false);
                                            Zoom7(true);
                                        } }></button >
                       </Grid>
                        <p className='TextImg '>
                           De acuerdo con el Mapa del Hambre 2019, unos 821 millones de 
                           personas (más de 1  de cada 9) pasan hambre en el mundo.
                        </p>
                    </Grid>

                </Grid>
                <Grid container item xs={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container xs={12}>
                        <p className="Lectura ">
                            <h3 className='SubTitulo'>
                            La quinua, ¿la solución al hambre del mundo?
                            </h3><br/>
                            Con el objetivo de enfrentar la inseguridad alimentaria, la <span style={{fontVariant: "all-small-caps"}}>FAO</span> ha propuesto 
                            diversas soluciones. Entre ellas, el cultivo de productos agrícolas con alto valor nutricional 
                            cuya producción no requiera gran cantidad de recursos, como agua y terrenos 
                            extensos. Un alimento que cumple con estas características es la quinua, también 
                            conocida como quinoa, quinigua o trigiullo.
                        </p>
                    </Grid>
                </Grid>

                <Grid container item xs={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container className="Escritorio">
                        <Grid container xs={6}>
                        <p className="Lectura">
                            <h3 className='SubTitulo2'>
                            El cultivo de este cereal
                            </h3><br/>
                            La quinua ha sido cultivada en la zona de <br/>
                            los Andes desde hace miles de años, <br/>
                            particularmente en Perú y Bolivia. <br/>
                            Además de estos países, en la actualidad <br/>
                            también se siembra en otros lugares del <br/>
                            Continente Americano, como Chile y el <br/>
                            norte de Colombia. En términos de <br/>
                            nutrientes, la quinua es una fuente rica en <br/>
                            proteína vegetal de alta calidad, <br/>
                            semejante a la de productos de origen <br/>
                            animal, como la leche. Asimismo, contiene <br/>
                            un balance adecuado de carbohidratos, <br/>
                            grasas, vitaminas y minerales. Respecto a <br/>
                            su producción, la quinua puede ser <br/>
                            cultivada en una diversidad de <br/>
                            condiciones climáticas, desde lugares muy <br/>
                            cercanos al mar hasta zonas montañosas. <br/>
                            Otra característica que muestra la <br/>
                            adaptabilidad de la quinua es su <br/>
                            resistencia a las heladas y a las sequías.
                        </p>
                        </Grid>
                        <Grid container justifyContent="center" alignItems='flex-end'xs={6}>
                            <Grid container alignItems='flex-end' justifyContent="center" xs={12}>
                                <img className="Imagen2" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion11/3.png" alt="Tarjeta" />
                                <p className='TextImg3'>
                                    Además de la quinua, otros cereales<br/> 
                                    contribuyen en gran medida a la<br/> 
                                    alimentación mundial.
                                </p>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid container className="Tablet">
                        <Grid container xs={12}>
                        <p className="Lectura">
                            <h3 className='SubTitulo2'>
                            El cultivo de este cereal
                            </h3><br/>
                            La quinua ha sido cultivada en la zona de los Andes desde hace 
                            miles de años, particularmente en Perú y Bolivia. Además de
                            estos países, en la actualidad también se siembra en otros 
                            lugares del Continente Americano, como Chile y el norte de 
                            Colombia. En términos de nutrientes, la quinua es una fuente 
                            rica en proteína vegetal de alta calidad, semejante a la de
                            productos de origen animal, como la leche. Asimismo, contiene 
                            un balance adecuado de carbohidratos, grasas, vitaminas y
                            minerales. Respecto a su producción, la quinua puede ser
                            cultivada en una diversidad de condiciones climáticas, desde 
                            lugares muy cercanos al mar hasta zonas montañosas. Otra
                            característica que muestra la adaptabilidad de la quinua es su 
                            resistencia a las heladas y a las sequías.<br/><br/>
                        </p>
                        </Grid>
                        <Grid container justifyContent="center" alignItems='flex-end'xs={12}>
                            <Grid container xs={12}>
                                <img className='Imagen3' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion11/3R.png" alt="Tarjeta" />
                                <p className='TextImg3'>
                                    Además de la quinua, otros cereales contribuyen en gran medida a la
                                    alimentación mundial.
                                </p>
                            </Grid>
                        </Grid>
                    </Grid>

                </Grid>

                <Grid container item xs={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container xs={12}>
                        
                        <p className="Lectura Lectura3">
                            Todas esas cualidades de la quinua hicieron que la <span style={{fontVariant: "all-small-caps"}}>FAO</span> la considerara un cultivo que 
                            puede ayudar a resolver los problemas de seguridad alimentaria en el mundo. <br/><br/>
                            
                            <h3 className='SubTitulo2'>Un año para aprender más de la quinua</h3><br/>

                            La <span style={{fontVariant: "all-small-caps"}}>FAO</span> declaró el año de 2013 como el Año Internacional de la Quinua. Con ello se 
                            buscaba reconocer a los pueblos andinos por la preservación de este alimento y dar a 
                            conocer todas sus propiedades y su potencial en la lucha contra el hambre y la 
                            desnutrición. La celebración del Año Internacional de la Quinua ayudó a generar 
                            conciencia sobre sus beneficios y a promover su cultivo en otras partes del mundo. <br/><br/>
                            
                            En Kurgistán y en siete países de África, la <span style={{fontVariant: "all-small-caps"}}>FAO</span> llevó a cabo un proyecto en el que se 
                            distribuyeron semillas de quinua de origen sudamericano y se enseñó a granjeros
                            locales cómo cultivarla. En África, el programa demostró la posibilidad de cultivar la 
                            quinua en el continente, siendo el nuevo reto introducirla e integrarla en los 
                            mercados y dietas del continente. <br/><br/>
                            
                            <h3 className='SubTitulo'>Te toca investigar</h3><br/>
                            
                            ¿Conoces alguna información acerca del cultivo y consumo de este cereal en tu 
                            comunidad? ¿Sabes en dónde puedes adquirirlo? Si quisieras contribuir con el 
                            esfuerzo global de enfrentar la inseguridad alimentaria, podrías comenzar a 
                            familiarizarte con el consumo de este alimento.
                        </p>
                        
                    </Grid>
                </Grid>

                <Grid container item xs={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container xs={12}>
                        <p className="Referencias ">
                                
                            <h3 className='SubTitulo2'>Referencias</h3><br/>

                            Drew, J., <em>et al</em>. (2017). “Ancient grains and new markets: the selling of quinoa as story 
                            and substance”. <em>Corporate Social Responsibility and Corporate Governance</em>. Emerald 
                            Publishing Limited. <br/><br/>
                            
                            <b className='Lectura4'>FAO, FIDA, OMS, PMA</b> y  <b className='Lectura4'>UNICEF</b> (2020). Versión resumida de <em>El estado de la seguridad 
                            alimentaria y la nutrición en el mundo 2020</em>. Transformación de los sistemas 
                            alimentarios para que promuevan dietas asequibles y saludables. Roma, <span style={{fontVariant: "all-small-caps"}}>FAO</span>. <a className="Url" href="https://doi.org/10.4060/ca9699es" target="_blank">https://
                            doi.org/10.4060/ca9699es</a> <br/><br/>
                            
                            <span style={{fontVariant: "all-small-caps"}}>FAO</span> (2016). “Pioneering Kyrgyzstan farmer catches the quinoa wave”. <a className='Url' href='http://www.fao.org/family-farming/detail/es/c/434112/' target="_blank">http://
                            www.fao.org/family-farming/detail/es/c/434112/</a> [Consultado el 22/08/21). <br/><br/>
                                
                            <span style={{fontVariant: "all-small-caps"}}>FAO</span> (2013). “Año Internacional de Quinua”. <a className='Url' href='http://www.fao.org/quinoa-2013/iyq/es/?no_mobile=1' target="_blank">http://www.fao.org/quinoa-2013/iyq/
                            es/?no_mobile=1</a> [Consultado el 22/08/21]. <br/><br/>
                            
                            <span style={{fontVariant: "all-small-caps"}}>FAO</span> (s. f.). <em>Una introducción a los conceptos básicos de la seguridad alimentaria</em>. <a className='Url' href='http://www.fao.org/3/al936s/al936s00.pdf' target="_blank">http://
                            www.fao.org/3/al936s/al936s00.pdf</a> [Consultado el 22/08/21]. <br/><br/>
                            
                            Mujica, A., y S. E Jacobsen (2006). “La quinua (Chenopodium quinoa Willd.) y sus
                            parientes silvestres”. <em>Bótanica económica de los Andes Centrales</em>, 32, 449-457.
                        </p>
                        
                    </Grid>
                </Grid>
                <Grid container item style={{ marginTop: 80 }}></Grid>
            </Grid>
        </Grid>
    );
}
 
export default Seguridad_alimentaria_y_quinua;
