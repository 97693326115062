import { Dialog, DialogContent, makeStyles, styled } from "@material-ui/core";
import React from "react";
import NavButton from "../../NavBar/NavButton";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-root": {
    // backdropFilter: "blur(10px)",
    height: "100%",
  },
  "& .MuiDialogContent-root": {
    padding: "0",
    position: "relative",
    height: "100%",
    // overflow: "hidden",
    [theme.breakpoints.up("sm")]: {
      // margin: "20px 0",
      paddingTop: "0",
      paddingBottom: "0",
    },
    [theme.breakpoints.up("sm")]: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      "@media(max-height:400px)": {
        justifyContent: "flex-start",
      },
    },
    [theme.breakpoints.up("xl")]: {
      justifyContent: "flex-start",
    },
  },
  "& .MuiDialog-paper": {
    borderRadius: "12.1px",
    backgroundColor: "transparent",
    boxShadow: "none",
    height: "100vh",
    width: "100vw",
    margin: "0px",
    maxHeight: "100vh",
    maxWidth: "1920px",
    [theme.breakpoints.up("lg")]: {
      margin: "0px",
    },
  },
  "& .MuiDialog-container": {
    // backdropFilter: "blur(10px)",
    alignItems: "flex-start",
    [theme.breakpoints.up("sm")]: {
      alignItems: "center",
    },
  },
}));

const useStyles = makeStyles((theme) => ({
  container: {
    position: "relative",
    width: "100%",
    justifyContent: "center",
    [theme.breakpoints.up(1024)]: {
      minWidth: "320px",
      justifyContent: "space-between",
    },
  },
  Barra: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "start",
    position: "fixed",
    maxWidth: "100vw",
    // overflow: "hidden",
    width: "280px",
    top: "80px",
    height: "calc(100vh - 80px)",
    // maxHeight: "1080px",
    padding: "0px",
    minWidth: "3%",

    [theme.breakpoints.down("md")]: {
      display: "flex",
      flexDirection: "column",
      position: "fixed",
      top: (props) => (props.openMenuInMobile ? "80px" : "-100%"),
      [theme.breakpoints.down(1161)]: {
        top: (props) => (props.openMenuInMobile ? "79.51px" : "-100%"),

        // opacity: (props) => (props.openMenuInMobile ? "100%" : "0"),
      },
      overflow: "auto",
      minHeight: "calc(100vh - 79.51px)",
      // minHeight: "0%",
      backgroundColor: "rgb(14,94,126)",
      background:
        "linear-gradient(180deg, rgba(14,94,126,1) 15%, rgba(35,177,174,1) 75%)",
      padding: "0px",
      // width: (props) => (props.isOpen ? '280px' : '3%'),
      minWidth: "320px",
      // width: (props) => (props.openInDesktop ? '100%' : '3%'),
      width: "100%",
    },
    [theme.breakpoints.up(1920)]: {
      top: (props) => (props.openMenuInDesktop ? "79.51px" : "79.51px"),
      // transition: "$slow 0.5s ease-out",
    },

    //
  },
  Button: {
    cursor: "pointer",
    border: "none",
    backgroundColor: "transparent",
    // position: "absolute",
    height: "37px",
    width: "37px",
    borderRadius: "50%",
    // top: "269.5px",
    // left: "329.5px",
    margin: "auto",
  },
  dialog: {
    backgroundColor: "#3f43b3",
    fontFamily: "Lato",
    letterSpacing: "0.36px",
    fontSize: "20px",
    color: "#ffffff",
    position: "absolute",
    left: "310px",
    top: "-25px",
    width: "390px",
    padding: "24px",
    boxSizing: "border-box",
    borderRadius: "21.25px",

    "&:before": {
      // display: (props) => (props.openMenuInDesktop ? "none" : "flex"),
      display: "flex",
      zIndex: 1,
      textTransform: "none",
      justifyContent: "center",
      alignItems: "center",
      // borderRadius: "25px",
      position: "absolute",
      content: '""',
      border: "15px solid",
      borderTopColor: "transparent",
      borderLeftColor: "transparent",
      borderBottomColor: "transparent",
      borderRightColor: "#3f43b3",
      font: "bold 14px lato",
      bottom: "calc(50% - 15px)",
      left: "-28px",
    },
  },
}));

const NavButtonTutorial = ({
  handleClose,
  open,
  openMenuInDesktop,
  openMenuInMobile,
  handleMenuInDesktop,
  handleMenuInMobile,
}) => {
  const handleCloseSubMenu = () => {
    handleMenuInMobile();
    if (openMenuInDesktop) handleMenuInDesktop();
  };
  const classes = useStyles({ openMenuInDesktop, openMenuInMobile });
  return (
    <>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogContent>
          {/* Simulación del Menú real con tamaño real pero en transparente, únicamente superponiendo el botón del mapa, dando la sensación del blur en el fondo */}
          <nav className={classes.container}>
            <div className={classes.Barra}>
              <NavButton
                className={classes.navButton}
                title="Mapa"
                to="/guia/mapa"
                handler={handleCloseSubMenu}
                icon={"MapaTutorial"}
                openMenuInDesktop={openMenuInDesktop}
                openMenuInMobile={openMenuInMobile}
              />
              <p className={classes.dialog}>
                En el menú haz clic en el botón <b>Mapa.</b>
              </p>
            </div>
          </nav>
        </DialogContent>
      </BootstrapDialog>
    </>
  );
};

export default NavButtonTutorial;
