import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
     Contenedor:{
        background:'#E9FBF9',
        width:'100vw',
        overflowX:'clip',
        [theme.breakpoints.down(1161)]:{
            background:'none',
           overflowX:'unset',
        },
        [theme.breakpoints.down(600)]:{
            marginTop: '79.51px',
        }
    },
  ContainerInfoLectura: {
    position: "relative",
    height: "86px",
    [theme.breakpoints.down(502)]:{
        height: "43px",
    },
    background: "#76D4C3",
    "& .BarraInfoLectura": {
      height: "86px",
      background: `Url(https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Barras/Azul.png)`,
        [theme.breakpoints.down(502)]:{
            height: "43px",
        },
    },
    "& .InfoLectura": {
      position: "absolute",
      height: "86px",
      width: "219px",
      boxSizing: "border-box",
      padding: "17.2px 0",
      background: "#FAFAFA",
      borderRadius: "50px",
      boxShadow: "2px 2px 2px 2px #00000029",
      zIndex: "4",
        [theme.breakpoints.down(502)]:{
            height: "43px",
            width: "109px",
            padding: "8.6px 0",
            borderRadius: "25px",
        },
    },
    "& .InfoLectura:before": {
      position: "absolute",
      display: "flex",
      boxSizing: "border-box",
      padding: "0 0 0 10px",
      justifyContent: "center",
      alignItems: "center",
      height: "39.88px",
      width: "39.88px",
      borderRadius: "50%",
      content: '""',
      boxShadow: "3px 0px .5px 0px #00000029",
      background: "#FAFAFA",
      left: "90px",
      top: "65px",
      transform: "rotate(0.25turn)",
      [theme.breakpoints.down(502)]:{
        left: "45px",
        top: "30px",
        height: "20.88px",
        width: "20.88px",
        },
    },
    "& .InfoLectura:after": {
      content: '""',
      height: "15px",
      width: "15px",
      borderTop: "2px solid #20A698",
      borderLeft: "2px solid #20A698",
      position: "absolute",
      bottom: "-10px",
      left: "Calc(50% - 8px)",
      transform: "rotate(0.625turn)",
      [theme.breakpoints.down(502)]:{
        height: "10px",
        width: "10px",
        left: "Calc(50% - 4.5px)",
        bottom: "-3px",
        borderTop: "1px solid #20A698",
        borderLeft: "1px solid #20A698",
        },
    },
    "& .Sesion": {
      borderLeft: "2px solid #D8D8D8",
    },
    "& .Dato": {
      margin: "0 0 5px 0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#20A698",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        margin: "0 0 2.5px 0",
        },
    },
    "& .Caracter": {
      margin: "0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#000000",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        },
    },   },
    ContenedorLectura:{
        background:'#fff',
        width:'1160px',
        height:'100%',
        padding:'60px 168px 35px ',
        [theme.breakpoints.down(1161)]:{
            width:'502px',
            padding:'60px 0 0 0',
          },
        [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
        '& .ContainerDerechos':{
            bottom:'80px',
            [theme.breakpoints.down(1161)]:{
                display:'none',
            },
        },
        '& .Derechos':{
            textAlign:"center",
            margin:"0px",
            font:" 15px lato",
            color:"#717476",
        },
        '& .Escritorio':{
            display:'block',
            [theme.breakpoints.down(1161)]:{
                display:'none'
            },
        },
        '& .Tablet':{
            display:'none',
            [theme.breakpoints.down(1161)]:{
                display:'block'
            },
        }
    },
    ContainerTitulo:{
        margin:'0 0 34px 0',
        [theme.breakpoints.down(1161)]:{
            margin:'0 0 34px 0',
        },
        '& .Titulo':{
            font:'48px fredoka one',
            letterSpacing: '0px',
            lineHeight:'58px',
            color: '#FF2B2B',
            margin:'0 0 5px 0',
            textAlign:'center',
            [theme.breakpoints.down(1161)]:{
                font:'43px fredoka one',
                lineHeight:'52px',
            },
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
        '& .Titulo2':{
            font:'bold 30px lato',
            letterSpacing: '.28px',
            lineHeight:'30px',
            color: '#FF2B2B',
            margin:'0',
            textAlign:'center',
            [theme.breakpoints.down(1161)]:{
                font:'bold 26px lato',
                letterSpacing: '.24px',
                lineHeight:'28px',
            },
            [theme.breakpoints.down(502)]:{
                font:'bold 22px lato',
                lineHeight:'24px',
            },
            [theme.breakpoints.down(350)]:{
                font:'bold 18px lato',
                lineHeight:'24px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        position:'relative',
        '& .Lectura':{
            lineHeight : '30px',
            margin:'0 0 36px 0',
            font:'22px lato',
            color:'#000',
            letterSpacing: '0.20px',
            [theme.breakpoints.down(1161)]:{
                font:'18px lato',
                lineHeight :'28px',
                letterSpacing: '0.17px',
            },
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .Imagen':{
            [theme.breakpoints.down(1161)]:{
                width:'100%',
            },
        },  
      
    }
}));

const Los_viajes_de_Maqui_Cap2 = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
             <Grid container item justifyContent="center" className={classes.ContainerInfoLectura}>
               <Grid container className="BarraInfoLectura"></Grid>
               <Grid container item className="InfoLectura">
                    <Grid container item direction="column" xs={6} justifyContent="center">
                        <p className="Dato">Grado</p>
                        <p className="Caracter">4</p>
                    </Grid>
                    <Grid container item direction="column" xs={6} justifyContent="center" className="Sesion">
                        <p className="Dato">Sesión</p>
                        <p className="Caracter">25</p>
                    </Grid>
               </Grid>
            </Grid>

            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                        <Grid container justifyContent="center" sm={12}>
                            <h2 className="Titulo">Los viajes de Maqui</h2>
                        </Grid>
                        <h3 className='Titulo2'>Fragmento 2 (capítulo 2)</h3>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                       <p className='Lectura'>
                        A lo lejos, veo que Maqui recorre el pasillo como sonámbula, hasta llegar al salón del piano. Con una patita rasca la puerta y alguien, a quien no logro distinguir, le abre. No entiendo qué está pasando, pero tengo varias teorías y creo que, al abrirse, la puerta de la recámara nos condujo a una época distinta. Confirmo mis sospechas cuando noto que las agujas del reloj giran en sentido contrario. Quiero regresar a la habitación, pero ya no puedo, ya que una ráfaga de viento cierra con fuerza la puerta.
                        <br/><br/>
                        Tengo que alcanzar a Maqui en el salón del piano. Recorro el pasillo desde la habitación y afuera la tormenta eléctrica arroja un relámpago tras otro. La luz que producen cae sobre los objetos que adornan el pasillo y pareciera que cobraran vida. Es realmente aterrador.
                        <br/><br/>
                        Finalmente, logro llegar al salón, acciono el picaporte y entreabro la puerta. Al fondo del recinto, percibo la sombra de Maqui y de una niña. Nadie debe verme, así que me escondo atrás del piano. El cabello de la niña es castaño, lacio y le llega hasta la cintura. Lleva un vestido de encaje hasta los tobillos. Su rostro luce pálido.
                        <br/><br/>
                        —Hola, Maqui, te estaba esperando —exclama la pequeña.
                        <br/><br/>
                        —Sólo puedo jugar un rato, no puedo demorarme demasiado.
                        <br/><br/>
                        —No importa, hace mucho tiempo que nadie me visitaba —el rostro de la nena entristece.
                        <br/><br/>
                        —Trataré de venir más seguido, te lo prometo.
                        <br/><br/>
                        La niña sonríe y saca a Codri de su bolsillo. Fue ella quien entró a nuestra habitación y lo escondió. La observo y su vestuario me parece anticuado. Hoy en día, ninguna niña usa vestidos así. Su rostro pálido me hace sospechar que tiene algún tipo de enfermedad. Me pregunto si ella vivía en esta casa antes de que mi familia se mudara. Tengo más preguntas que respuestas y sólo Maqui podría contestarlas.
                        <br/><br/>
                        El tiempo pasa rápidamente. Ellas se dedican a jugar con Codri, hasta que el viejo reloj emite una campanada. Maqui lame la mano de la pequeña y ella arroja a lo lejos a Codri. Cuando mi perrita regresa con el juguete, la niña ya no está y la casa vuelve a ser la misma que conozco.
                        <br/><br/>
                        La puerta de la habitación donde duermo con mi hermano se abre sin explicación.
                        <br/><br/>
                        Maqui regresa a la recámara y se acuesta en su camita. Yo permanezco afuera y decido esperar hasta que se duerma, para no levantar ninguna sospecha.
                       </p>                   
                    </Grid>
                    <Grid container justifyContent='center' >
                        <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img4to/Sesion25/1.png" alt="Tarjeta" />
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                       <p className='Lectura'>
                        A la mañana siguiente, antes del desayuno, mamá saca al cocodrilito de peluche de su bata de dormir. Maqui mueve su cola con mucha alegría.
                        <br/><br/>
                        —Mira, Maqui, lo que encontré en el salón del piano. Estaba extraviado.
                        <br/><br/>
                        Por lo que veo, nadie en mi familia se dio cuenta de lo ocurrido y la mañana transcurre sin pormenores. En la tarde, mi madre saca un álbum de fotos familiares. En una de sus páginas está pegado el retrato de la niña. Lleva el mismo vestido de encajes con el que la vi jugar.
                        <br/><br/>
                        —¿Quién es? —le pregunto a mi mamá.
                        <br/><br/>
                        —Es Irma, mi tía. Enfermó cuando todavía era una niña y la trajeron a vivir a esta casa. 
                        <br/><br/>
                        Mamá me cuenta que Irma tenía una perrita muy parecida a Maqui, de nombre Lorenza. Eran las mejores amigas. También me dice que la tía Irma falleció tristemente un día antes de su cumpleaños número diez, a causa de una neumonía. “Eso explica la palidez de su rostro”, pienso.
                       </p>                   
                    </Grid>
                </Grid>


                <Grid container item xs={12} justifyContent="center" className="ContainerDerechos">
                    <p className="Derechos">
                        © 2022. Todos los derechos reservados, Pearson, Circuito de lectores.
                    </p>                
                </Grid>
        </Grid>
    </Grid>
    );
}
 
export default Los_viajes_de_Maqui_Cap2;
