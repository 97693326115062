import React               from "react";
import {Grid, makeStyles}                 from '@material-ui/core';

const useStyles = makeStyles(theme =>({
  questionContainer:{
    width: "100%",
    height:"100%",
    overflowY: "scroll",
    scrollbarColor: "#F6DE95",
    scrollbarWidth: "10px",
    '&::-webkit-scrollbar': {
      width: '15px',
    },
    '&::-webkit-scrollbar-track': {
      borderRadius: '25px',
      backgroundColor: "#F6DE95",
      webkitBoxShadow: 'inset 0 0 3px rgba(0,0,0,0.00)'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#8144DF',
      borderRadius: '25px',
    },
  },
  reading: {
    width: "80%",
    margin:"10px 0 10px 0 ",
    font: '22px lato',
    letterSpacing: '0.21px',
    [theme.breakpoints.down(1400)]:{
      font: " 18px lato",
      width: "85%",
    },
  },
  table: {
    border: '2px solid black',
    width: "100%",
    font: '22px lato',
    [theme.breakpoints.down(1400)]:{
      font: " 18px lato",
    },
  },
  tdTable: {
    padding: "0 1%",
    border: '2px solid black',
  }
}));

const QuestionTable = (props) => {
  const classes = useStyles()
  const { picturesTable } = props


  return(
    <Grid container className={classes.questionContainer}>
      <p
        className={classes.reading}
      >
       6. Relaciona el fragmento de la obra de teatro con el sentimiento que experimentan los personajes.
      </p>
      <br/>
      <table className={classes.table}>
        <tr>
          <th style={{width: "50%", border: '2px solid black',}}><b>Fragmento de la obra</b></th>
          <th style={{width: "50%", border: '2px solid black',}}><b>Sentimiento</b></th>
        </tr>
        <tr>
          <td className={classes.tdTable}>
            <text>
              1. AMELIA. ¿Me puedo sentar? (<em>Se sienta junto a Sofía</em>) Yo también la extraño. ¿Sabes? Era muy divertida.
            </text><br/>
            <p>
              SOFÍA. ¡Yo no la extraño! ¿Qué quieres?
            </p>
            <p>
              AMELIA. (<em>Señala el almuerzo de Sofía</em>) ¿Me das un poco?
            </p>
            <p>
              SOFÍA. ¡No! ¡Sólo le comparto a Fátima!
            </p>
            <text>
              AMELIA. ¿Quieres jugar? Nos falta una niña para completar.
            </text>
          </td>
          <td className={classes.tdTable}>
            <p>
              a. Temen decirse que están encontrando nuevas amistades para no lastimarse mutuamente.
            </p>
          </td>
        </tr>
        <tr>
          <td className={classes.tdTable}>
            <text>
              2. VOZ DE FÁTIMA. ¿Y no juegas con nadie en el receso? ¿Con Amelia, por ejemplo?
            </text><br/>
            <p>
              SOFÍA. Esa niña… la otra vez me pidió que le compartiera de mi almuerzo, ¿tú crees?
            </p>
            <p>
              VOZ DE FÁTIMA. Sí, así ha sido siempre… ¿Y no juegas con ella?
            </p>
            <p>
              SOFÍA. No, con nadie… ¿Y tú?
            </p>
            <text>
              VOZ DE FÁTIMA. No, cómo crees, con nadie… Bueno, hay una niña… Pero no, no juego con ella… 
            </text>
          </td>
          <td className={classes.tdTable}>
            <p>
              b. Empieza a superar la partida de su amiga.
            </p>
          </td>
        </tr>
        <tr>
        <td className={classes.tdTable}>
            <text>
              3. <em>Sofía y Amelia salen. El almuerzo de Sofía se queda en la banca.</em> 
            </text><br/>
          </td>
          <td className={classes.tdTable}>
            <p>
              c. Demuestra paciencia porque sabe que está pasando por un mal momento.
            </p>
          </td>
        </tr>
        <tr>
          <td className={classes.tdTable}>
            <text>
              4. SOFÍA. (<em>Sin comprender lo que acaba de escuchar</em>) ¿O sea que ya no nos veremos?
            </text><br/>
            <p>
              FÁTIMA. (<em>Triste</em>) No.
            </p>
            <text>
              SOFÍA. (<em>Abraza con fuerza a Fátima</em>) ¡Noooo! ¡No te vayas! (<em>Se escucha que llora un poco. De repente, 
              deja de abrazarla</em>) ¡Ya sé! ¡Tengo una idea! ¿Y si le digo a mi mamá que también a mí me cambie de escuela? 
              ¡Así nos vamos juntas!.
            </text><br/>
          </td>
          <td className={classes.tdTable}>
            <p>
              d. Es muy apegada a su mejor amiga.
            </p>
          </td>
        </tr>
      </table>
    </Grid>
  )

}

export default QuestionTable;
