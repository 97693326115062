import { createTheme, ThemeProvider } from "@mui/material/styles";
import { esES } from "@mui/material/locale";
import { esES as esESTable } from "@mui/x-data-grid";

const theme = createTheme(
  {
    palette: {
      primary: { main: "#1976d2" },
    },
    components: {
      // Use `MuiDataGrid` on DataGrid, DataGridPro and DataGridPremium
      MuiDataGrid: {},
    },
  },
  esES,
  esESTable
);
const TemaProvider = ({ children }) => {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default TemaProvider;
