import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
   ContenedorLectura:{
        width:'502px',
         [theme.breakpoints.down(502)]:{
           width:'350px',
         },
       '& .Escritorio':{
           display:'block',
           [theme.breakpoints.down(1161)]:{
               display:'none'
           },
       },
       '& .Tablet':{
           display:'none',
           [theme.breakpoints.down(1161)]:{
               display:'block'
           },
       }
   },
    ContainerTitulo:{
        margin:'0 0 46px 0',
        '& .Titulo':{
            letterSpacing: '0px',
            color: '#0CB5D6',
            margin:'0',
            textAlign:'center',
            font:'43px fredoka one',
            lineHeight:'52px',
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            margin:'0 0 36px 0 ',
            color:'#000',
            font:'18px lato',
            lineHeight :'28px',
            letterSpacing: '0.17px',
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .Esp':{
            margin:'0',
        },
        '& .SubTitulo':{
            color:'#F6664D',
            margin:'0',
            font:'bold 22px lato',
            lineHeight :'24px',
            letterSpacing: '0.21px',
            [theme.breakpoints.down(502)]:{
                font:'bold 18px lato',
                lineHeight :'20px',
                letterSpacing: '0.16px',
            },
        },
       '& .TextImg':{
            margin:'24px 0 0 0',
            alignSelf:'flex-start',
            color:'#717476',
            font:' 16px lato',
            lineHeight :'25px',
            letterSpacing: '0.15px',
            [theme.breakpoints.down(502)]:{
                font:' 11px lato',
                lineHeight :'14px',
                letterSpacing: '0.10px',
            },
        },
        '& .Ul':{
            margin:'0 0 36px 0',
            padding:'0 0 0 19px '
         },
         '& .li':{
              color:'#0CB5D6',
             margin:'0 0 8px 0',
             [theme.breakpoints.down(502)]: {
                 margin:'0 0 4px 0',
             },
         },
        '& .Imagen':{
            width:'100%',

        },
    },
    

}));

const El_poder_de_la_autorregulación = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >
                    El poder de la<br/> autorregulación
                    </h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                            <h3 className='SubTitulo'>El caso del Hombre Cangrejo</h3><br/> 

                            “No sé qué pasó. No lo pude evitar. El enojo se apoderó de mí y lo único que deseaba 
                            era destruir la amenaza que me causó daño”, declaró el Hombre Cangrejo (el 
                            superhéroe de la comunidad costera de Sol Escondido) a la prensa después de 
                            destruir un árbol con sus supertenazas.<br/><br/>

                            Todo indica que la tarde de ese martes el Hombre Cangrejo se encontraba vigilando
                            las calles del Centro Histórico. De pronto, se tropezó sin querer con la raíz de un
                            árbol que sobresalía en la acera.
                        </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <Grid container >
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img2do/Sesion36/1.png"  alt="Tarjeta" />
                        </Grid>
                        <Grid container >
                            <p className='TextImg'>La autorregulación se ubica en el nivel de nuestras emociones y se asocia con conocernos a 
                            nosotros mismos.</p>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                            El golpe le ocasionó un dolor insoportable en el dedo meñique del pie. Dominado por 
                            el dolor, fue incapaz de mantener el control de sí mismo: estalló de ira, destruyendo 
                            un árbol que tenía más de 100 años. <br/><br/>
                            
                            Durante la entrevista que tuvimos con él, confesó (avergonzado) que todavía le falta 
                            desarrollar el superpoder más útil de todos: la autorregulación. Luego se disculpó y 
                            se marchó.
                        </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <Grid container >
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img2do/Sesion36/2.png"  alt="Tarjeta" />
                        </Grid>
                        <Grid container >
                            <p className='TextImg'>Cuando actuamos de manera impulsiva podemos hacer cosas de las cuales nos
                            arrepentiremos o avergonzaremos después, como dañar a alguien.</p>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                            <h3 className='SubTitulo'>¿Qué es la autorregulación?</h3><br/> 

                             Los expertos dicen que la <em>autorregulación</em> se relaciona con nuestra habilidad para 
                             reconocer lo que sentimos. También implica modular su intensidad y duración, así 
                             como prevenir lo que podría suceder si no lo hacemos. <br/><br/>
                             
                             Esto significa que si aprendes a regular tus emociones podrías disminuir su fuerza y 
                             la cantidad de tiempo en la que las experimentes (sobre todo si son negativas). Al 
                             tener un buen manejo de ellas, evitarás que se apoderen de ti y que termines 
                             haciendo destrozos como el Hombre Cangrejo.<br/><br/>

                            Algunas ventajas de la autorregulación son:
                        </p>
                        <ul className='Ul'>
                            <li className='li'><p className="Lectura Esp">Disminuye tus niveles de ansiedad.</p></li>
                            <li className='li'><p className="Lectura Esp">Te ayuda a permanecer en calma durante momentos difíciles.</p></li>
                            <li className='li'><p className="Lectura Esp">Te permite tomar mejores decisiones a tiempo.</p></li>
                            <li className='li'><p className="Lectura Esp">Te permite comunicarte mejor.</p></li>
                            <li className='li'><p className="Lectura Esp">Es una herramienta útil en la resolución de conflictos.</p></li>
                        </ul>
                        <p className='Lectura '>
                            Tomar distancia de la emoción que estás experimentando es una buena medida para 
                            autorregularte, sobre todo en los conflictos. Los expertos recomiendan contar hasta 
                            10 para darle tiempo a la emoción de bajar su intensidad y recordarte que los demás 
                            también son personas. <br/><br/>
                            
                            ¿Alguna vez has estado en una situación en la que debas regular lo que sientes? 
                            ¿Cómo lo resolviste? Conversa con otros y pregúntales sobre alguna experiencia 
                            positiva al respecto.
                        </p>
                    </Grid>
                </Grid>
        </Grid>
    </Grid>
    );
}
 
export default El_poder_de_la_autorregulación;
