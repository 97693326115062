import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
    ContenedorLectura:{
        width:'502px',
                  [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
    },
    ContainerTitulo:{
        margin:'0 0 34px 0',
        '& .Titulo':{
            letterSpacing: '0px',
            color: '#926DD1',
            margin:'0',
            textAlign:'center',
            font:'43px fredoka one',
            lineHeight:'52px',
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            margin:'0 0 36px 0',
            color:'#000',
            font:'18px lato',
            lineHeight :'28px',
            letterSpacing: '0.17px',
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .TextImg':{
            color:'#717476',
            margin:'24px 0 0 0',
            font:' 16px lato',
            lineHeight :'25px',
            letterSpacing: '0.15px',
            [theme.breakpoints.down(502)]:{
                font:' 11px lato',
                lineHeight :'14px',
                letterSpacing: '0.10px',
            },
        },
        '& .Imagen':{
            width:'88.4%',
        }, 

    }
}));

const Los_límites_de_la_libertad = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>

            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >Los límites <br/>de la libertad</h2>

                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                      <p className="Lectura">
                        ¿Alguna vez te has percatado de que es imposible jugar un juego cuando no existen 
                        instrucciones claras o cuando no hay reglas en lo absoluto? Imagina un juego en el 
                        que todos los jugadores son libres de hacer lo que quieran. ¿Qué pasaría?<br/><br/>
                        
                        Las personas somos seres libres, actuamos de acuerdo con nuestros intereses. 
                        Podemos decir que tenemos una experiencia de la libertad cuando, a partir de 
                        nuestros deseos, emociones y pensamientos, producimos cambios en el mundo.
                      </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <Grid container justifyContent="center" >
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img1ro/Sesion24/1.png" alt="Tarjeta" />
                        </Grid>
                        <Grid container justifyContent="center" >
                            <p className="TextImg">
                            La libertad nos permite disfrutar de nuestros deseos e intereses.
                            </p>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                      <p className="Lectura">
                        Pero ¿qué pasaría si lleváramos nuestra libertad al extremo? Seguramente, las cosas 
                        se saldrían de control. Viviríamos en un mundo en el que habría violencia, abusos y 
                        estaríamos con el miedo constante de saber que en cualquier momento alguien 
                        podría agredirnos.<br/> <br/>
                        
                        Afortunadamente, los seres humanos tenemos la capacidad de regular nuestros 
                        actos con normas morales. Éstas establecen los límites para la sana convivencia de 
                        las personas en la sociedad. Por eso, al igual que con las instrucciones y reglas, las 
                        normas morales nos permiten disfrutar más del juego.<br/>
                      </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <Grid container justifyContent="center" >
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img1ro/Sesion24/2.png" alt="Tarjeta" />
                        </Grid>
                        <Grid container justifyContent="center" >
                            <p className="TextImg">
                            Es importante que tengamos un balance entre libertad y límites.
                            </p>
                        </Grid>
                    </Grid>
                </Grid>    
        </Grid>
    </Grid>
    );
}
 
export default Los_límites_de_la_libertad;
