import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
    ContenedorLectura:{
        width:'502px',
                  [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
    },
    ContainerTitulo:{
        margin:'0 0 34px 0',
        '& .Titulo':{
            letterSpacing: '0px',
            color: '#591AAF',
            margin:'0',
            textAlign:'center',
            font:'43px fredoka one',
            lineHeight:'52px',
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            margin:'0 0 36px 0',
            color:'#000',
            font:'18px lato',
            lineHeight :'28px',
            letterSpacing: '0.17px',
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        "& .SubTitulo": {
            color: "#DF5DA7",
            margin: "0",
            lineHeight: "28px",
            font: "bold 26px lato",
            letterSpacing: "0.24px",
            [theme.breakpoints.down(502)]: {
              font: "bold 22px lato",
              lineHeight: "24px",
              letterSpacing: "0.21px",
            },
        },
        '& .TextImg':{
            color:'#717476',
            margin:'20px 0 0 0',
            font:' 16px lato',
            lineHeight :'25px',
            letterSpacing: '0.15px',
            [theme.breakpoints.down(502)]:{
                font:' 11px lato',
                lineHeight :'14px',
                letterSpacing: '0.10px',
            },
        },
        '& .Esp':{
            padding:'0',
        }, 
        '& .Imagen':{
            width:'100%',

        }, 
        '& .Imagen2':{
            width:'88.04%',
        }, 

    }
}));

const Los_cuidados_de_las_mascotas = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>

            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >
                        Los cuidados <br/>de las mascotas                    
                    </h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                      <p className="Lectura">
                        <h3 className='SubTitulo'>Caninos y mininos</h3><br/>
                        
                        Perros y gatos han estado cerca de las personas desde hace milenios. Son especies 
                        domesticadas: su relación con los humanos es estrecha, a tal grado que ha llegado a 
                        considerarse como amistad. Si has tenido alguna de estas mascotas, seguramente lo 
                        entiendes.

                      </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <Grid container justifyContent="center" >
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img2do/Sesion54/1.png" alt="Tarjeta" />
                        </Grid>
                        <Grid container justifyContent="center" >
                            <p className="TextImg Esp">
                                Estudios recientes han demostrado que la relación entre perros y humanos data de hace 27 000 o 40 000 años.                            
                            </p>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                        Pero tener un perro o un gato en casa conlleva una responsabilidad: son seres vivos 
                        (igual que tú) que requieren cuidados y atenciones específicas en su trato, sus 
                        condiciones de vida, su alimentación y su salud.
                        <br/><br/>
                        En las últimas décadas, las sociedades han ampliado su consciencia sobre el entorno 
                        y sus cuidados. Esto implica la relación que se tiene con las mascotas y otros 
                        animales. Por ejemplo, hace años era común que las familias ataran a su perro para 
                        que custodiara un lugar específico (como la entrada de la casa) o para que no se 
                        escapara. Eso es un tipo de maltrato, una relación injusta entre la mascota y su 
                        familia cuidadora.
                        <br/><br/>
                        Actualmente, se promueve la adopción de mascotas de una manera más consciente 
                        (incluso amorosa). Se espera que quien lleve una a casa garantice que le dará una 
                        vida digna.
                        <br/><br/>
                        
                        <h3 className='SubTitulo'>Otros tipos de mascotas</h3><br/>

                        Aunque es verdad que también se han adoptado otros tipos de mascotas (como 
                        reptiles o anfibios), los expertos recomiendan no adquirir estas especies, ya que 
                        tenerlas en una casa acondicionada es insuficiente. Su calidad de vida disminuye y 
                        su existencia se acorta en un espacio humano, porque sus prácticas son más libres y 
                        requieren de un ecosistema específico.
                        <br/><br/>
                        Asimismo, cada vez es menos común la presencia de aves enjauladas, pues 
                        representa el mismo problema que los reptiles: su calidad de vida es menor debido a 
                        que no están en su ecosistema natural, cubriendo las necesidades propias de su 
                        especie.
                        <br/><br/>
                        Si quieres adoptar una mascota, te recomendamos que sea una domesticada. 
                        Recuerda también que eso implica una responsabilidad importante: es un ser vivo a 
                        tu cargo, y necesitará tus cuidados y tu cariño.
                        <br/><br/>
                        Si ya tienes una, cuídala y procúrale un espacio reconfortante, en el que se sienta 
                        tan libre como sea posible.                   
                        </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <Grid container justifyContent="center" >
                            <img className="Imagen2" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img2do/Sesion54/2.png" alt="Tarjeta" />
                        </Grid>
                        <Grid container justifyContent="center" >
                            <p className="TextImg">
                                Es importante prevenir que nuestras mascotas representen un peligro para otras especies, como las aves. Por eso es necesario vigilarlas atentamente.
                            </p>
                        </Grid>
                    </Grid>
                </Grid>
        </Grid>
    </Grid>
    );
}
 
export default Los_cuidados_de_las_mascotas ;
