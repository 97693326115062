import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
    ContenedorLectura:{
        width:'502px',
                  [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
    },
    ContainerTitulo:{
        margin:'0 0 34px 0',
        '& .Titulo':{
            letterSpacing: '0px',
            color: '#D94C66',
            margin:'0',
            textAlign:'center',
            font:'43px fredoka one',
            lineHeight:'52px',
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
        "& .cel":{
            display:'none',
            [theme.breakpoints.down(502)]: {
              display:'block',
            },
          },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            margin:'0 0 36px 0',
            color:'#000',
            font:'18px lato',
            lineHeight :'28px',
            letterSpacing: '0.17px',
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .Esp':{
         padding:'0 ',

        },
        '& .SubTitulo':{
            color:'#26CE64',
            margin:'0',
            lineHeight :'28px',
            font:'bold 26px lato',
            letterSpacing: '0.24px',
            [theme.breakpoints.down(502)]:{
                font:'bold 18px lato',
                lineHeight :'24px',
                letterSpacing: '0.21px',
            },
        },
        
        '& .TextImg':{
            color:'#717476',
            font:' 16px lato',
            lineHeight :'25px',
            letterSpacing: '0.15px',
            [theme.breakpoints.down(502)]:{
                font:' 11px lato',
                lineHeight :'14px',
                letterSpacing: '0.10px',
            },
        },

        '& .Imagen':{
            width:'85.2%',

        },  
        '& .Imagen2':{
            width:'83.4%',

        }, 
      
    }
}));

const Cómo_diferenciar_entre_el_bien_y_el_mal = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                <h2 className="Titulo">
                    ¿Cómo diferenciar <br />
                    entre el bien <br className="cel"/>y el mal?
                </h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                      <p className="Lectura Esp">
                      ¿Te has preguntado qué pasaría si no estableciéramos ningún límite? Por ejemplo, 
                      imagina que estás jugando con un juguete. De pronto, un amigo tuyo se lo lleva sin 
                      pedirlo. ¿Cómo te sentirías? Seguramente pensarías que algo no está bien. <br/><br/>

                      <h3 className="SubTitulo" >La moral</h3>  <br/>

                      Todas nuestras acciones tienen consecuencias. Por lo tanto, es necesario 
                      establecer reglas que las limiten. Esta función la desempeña la moral. La <em>moral</em> es 
                      un conjunto de reglas o valores que regulan nuestra conducta. ¿Quién establece 
                      estas normas? Nuestra familia, la escuela o la sociedad en general.
                      </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <Grid container justifyContent="center" >
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img1ro/Sesion28/1.png" alt="Tarjeta" />
                        </Grid>
                        <Grid container justifyContent="center" >
                            <p className="TextImg">
                            Gracias a las normas morales, podemos convivir en armonía.
                            </p>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                      <p className="Lectura">

                      <h3 className="SubTitulo" >La ética</h3> <br/>

                      Ahora bien, ¿alguna vez te has dado cuenta de que a veces actúas de acuerdo con 
                      reglas o ideas que surgen en ti? ¿Reflexionas si es correcto hacer algo aun cuando las 
                      demás personas sí lo hacen? Por ejemplo, mentir para ayudar a un amigo. A esta
                      capacidad le llamamos <em>ética</em>. La ética es la capacidad de analizar si lo que hacemos 
                      está bien o mal.
                      </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <Grid container justifyContent="center" >
                            <img className="Imagen2" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img1ro/Sesion28/2.png" alt="Tarjeta" />
                        </Grid>
                        <Grid container justifyContent="center" >
                            <p className="TextImg">
                            La ética viene de nuestras propias ideas.
                            </p>
                        </Grid>
                    </Grid>
                </Grid> 
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                      <p className="Lectura">
                        Ahora que ya lo sabes, puedes reconocer de dónde vienen las ideas acerca del bien y   
                        el mal, reflexionar acerca de tus decisiones y actuar de manera correcta.
                      </p>
                    </Grid>
                </Grid>       
        </Grid>
    </Grid>
    );
}
 
export default Cómo_diferenciar_entre_el_bien_y_el_mal;
