import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
      Contenedor:{
        background:'#E9FBF9',
        width:'100vw',
        overflowX:'clip',
        [theme.breakpoints.down(1161)]:{
            background:'none',
           overflowX:'unset',
        },
        [theme.breakpoints.down(600)]:{
            marginTop: '79.51px',
        }
    },
    ContainerInfoLectura:{
        position:'relative',
        height:'86px',
        background:'#F6664D',
        '& .BarraInfoLectura':{
            height:'86px',
            background:`Url(https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Barras/Naranja.png)`,
        },
        '& .InfoLectura':{
            position:'absolute',
            height:'86px',
            width:'219px',
            boxSizing:'border-box',
            padding:'17.2px 0',
            background:'#FAFAFA',
            borderRadius:'50px',
            boxShadow: '2px 2px 2px 2px #00000029',
            zIndex:'4',
        },
        '& .InfoLectura:before':{
            position:'absolute',
            display:'flex',
            boxSizing:'border-box',
            padding:'0 0 0 10px',
            justifyContent:'center',
            alignItems:'center',
            height:'39.88px',
            width:'39.88px',
            borderRadius:'50%',
            content:'""',
            boxShadow: '3px 0px .5px 0px #00000029',
            background:'#FAFAFA',
            left:'90px',
            top:'65px',
            transform: 'rotate(0.25turn)',
        },
        '& .InfoLectura:after':{
            content:'""',
            height:'15px',
            width:'15px',
            borderTop:'2px solid #F6664D',
            borderLeft:'2px solid #F6664D',
            position:'absolute',
            bottom:'-10px',
            left:'Calc(50% - 8px)',
            transform: 'rotate(0.625turn)',
        },
        '& .Sesion':{
            borderLeft:'2px solid #D8D8D8',
        },
        '& .Dato':{
            margin:'0 0 5px 0',
            textAlign:'center',
            font:'bold 17px lato',
            color:'#F6664D',
        },
        '& .Caracter':{
            margin:'0',
            textAlign:'center',
            font:'bold 17px lato',
            color:'#000000',
        },
    },
    ContenedorLectura:{
        background:'#fff',
        width:'1160px',
        height:'100%',
        padding:'60px 168px 35px ',
        [theme.breakpoints.down(1161)]:{
            width:'502px',
            padding:'60px 0 0 0',
          },
        [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
        '& .ContainerDerechos':{
            bottom:'80px',
            [theme.breakpoints.down(1161)]:{
                display:'none',
            },
        },
        '& .Derechos':{
            textAlign:"center",
            margin:"0px",
            font:" 15px lato",
            color:"#717476",
        },
        '& .Escritorio':{
            display:'flex',
            [theme.breakpoints.down(1161)]:{
                display:'none'
            },
        },
        '& .Tablet':{
            display:'none',
            [theme.breakpoints.down(1161)]:{
                display:'flex'
            },
        }
    },
    ContainerTitulo:{
        margin:'0 0 34px 0',
        [theme.breakpoints.down(1161)]:{
            margin:'0 0 34px 0',
        },
        '& .Titulo':{
            letterSpacing: '0px',
            lineHeight:'58px',
            color: '#156A8D',
            margin:'0',
            textAlign:'center',
            font:'48px fredoka one',
            [theme.breakpoints.down(1161)]:{
                font:'43px fredoka one',
                lineHeight:'52px',
            },
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            margin:'0 0 36px 0',
            color:'#000',
            font:'22px lato',
            letterSpacing: '0.21px',
            lineHeight : '30px',
            [theme.breakpoints.down(1161)]:{
                font:'18px lato',
                lineHeight :'28px',
                letterSpacing: '0.17px',
            },
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .TextImg':{
            color:'#717476',
            margin:'24px 0 0 0',
            font:'20px lato',
            letterSpacing:'.19px',
            lineHeight:'30px',
            [theme.breakpoints.down(1161)]:{
                font:' 16px lato',
                lineHeight :'25px',
                letterSpacing: '0.15px',
            },
            [theme.breakpoints.down(502)]:{
                font:' 11px lato',
                lineHeight :'14px',
                letterSpacing: '0.10px',
            },
        },
        '& .Imagen':{
            [theme.breakpoints.down(1161)]:{
                width:'100%',
            },
        },  
      
    },

}));

const El_Galeón_de_Manila = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
             <Grid container item justifyContent="center" className={classes.ContainerInfoLectura}>
               <Grid container className="BarraInfoLectura"></Grid>
               <Grid container item className="InfoLectura">
                    <Grid container item direction="column" xs={6} justifyContent="center">
                        <p className="Dato">Grado</p>
                        <p className="Caracter">3</p>
                    </Grid>
                    <Grid container item direction="column" xs={6} justifyContent="center" className="Sesion">
                        <p className="Dato">Sesión</p>
                        <p className="Caracter">39R</p>
                    </Grid>
               </Grid>
            </Grid>

            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >
                        El Galeón de Manila
                    </h2>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container justifyContent='center' xs={12} >
                        <p className="Lectura ">
                            La Organización de las Naciones Unidas para la Educación, la Ciencia y la Cultura 
                            (UNESCO) decretó el 8 de octubre como el Día Internacional del Galeón de Manila. 
                            Este festejo fue establecido en 2009 con el fin de conmemorar el intercambio entre 
                            culturas y el inicio del comercio mundial entre los continentes de Asia, América, 
                            Europa y África.<br/><br/> 
                            
                            Se plantea que éste inició entre 1565 y 1815. Uno de los principales vehículos de 
                            transportación en esa época era un tipo particular de barco, llamados galeones. Éstos
                            navegaban el océano Pacífico llevando productos de un puerto a otro y haciendo 
                            posible el contacto entre comerciantes de diferentes partes del planeta.
                        </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12} >
                        <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img3ro/Sesion39R/1.png" alt="Tarjeta" />
                    </Grid>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    
                    <Grid container justifyContent="center" xs={12} >
                        <p className="Lectura ">
                            ¿Por qué esta celebración recibió dicho nombre? Se debe a un importante grupo de 
                            naves que cruzaba el mar, el cual era llamado Galeón de Manila. Solía viajar de 
                            Filipinas a la Nueva España y tomaba meses completar una sola vuelta. Las naves 
                            llegaban a lo que actualmente son las regiones de Acapulco, Nayarit y Baja California 
                            Sur, en México. Esta ruta comercial fue descubierta por el marinero español Andrés 
                            de Urdaneta el 8 de octubre de 1565. Desde ese momento el camino fue conocido 
                            como Ruta de Urdaneta o Tornaviaje, que significa “el viaje de regreso”. <br/><br/>
                            
                            El comercio entre Filipinas y la Nueva España inició formalmente en 1571, cuando se 
                            fundó la antigua ciudad de Manila, que es la capital de Filipinas. El intercambio se 
                            limitaba al inicio a cubrir las necesidades de los colonizadores europeos, pues eran 
                            ellos quienes supervisaban todo. Pero posteriormente se amplió la variedad y 
                            comenzaron a llevarse bienes de distintas partes de Asia hacia Acapulco, entre los 
                            que se encontraban sedas, telas de algodón, porcelana y marfil.
                        </p>
                    </Grid>

                    <Grid container xs={12} >
                        <Grid container justifyContent='center'>
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img3ro/Sesion39R/2.png" alt="Tarjeta" />
                        </Grid>
                        <Grid container item justifyContent='center'>
                            <p className='TextImg'>
                                Ruta de El Galeón de Manila.                            
                            </p>
                        </Grid>
                    </Grid>
                   
                </Grid>

                <Grid container item xs={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container item>
                        <p className="Lectura ">
                            Una vez que los objetos asiáticos llegaban a costas mexicanas eran distribuidos tanto 
                            en el interior de la Nueva España, como en Perú y los puertos españoles de Cádiz y 
                            Sevilla. Mientras tanto, el producto americano más codiciado en la otra parte del 
                            mundo era la plata, que usualmente provenía de los virreinatos de la Nueva España y 
                            Perú. <br/><br/>
                            
                            La ruta comercial duró 250 años y llegó a su fin cuando México consiguió su 
                            independencia de España. Ahora conoces un poco más acerca de la historia del 
                            Galeón de Manila y por qué es festejado el 8 de octubre. ¿Alguna vez habías 
                            escuchado de ella? ¿Puedes imaginarte cómo eran los mercados en esa época?
                        </p>
                    </Grid>
                </Grid>

                
                
                <Grid container item xs={12} justifyContent="center" className="ContainerDerechos">
                    <p className="Derechos">
                        © 2022. Todos los derechos reservados, Pearson, Circuito de lectores.
                    </p>                
                </Grid>
        </Grid>
    </Grid>
    );
}
 
export default El_Galeón_de_Manila;
