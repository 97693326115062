import { Button, Grid, makeStyles } from "@material-ui/core";
import React, { useContext, useEffect } from "react";
import DrawerContext from "../../../../../usability/DrawerContext";
import { useOnLoadImages } from "../../../../hooks/useOnLoadImages";
import Loader from "../../../../Loader/Loader";
import Drawer from "../../ReadingTools/Drawer";

const useStyles =  makeStyles (theme =>({
     Contenedor:{
        background:'#E9FBF9',
        width:'100vw',
        overflowX:'clip',
        [theme.breakpoints.down(1161)]:{
            background:'none',
           overflowX:'unset',
        },
        [theme.breakpoints.down(600)]:{
            marginTop: '79.51px',
        }
    },
  ContainerInfoLectura: {
    position: "relative",
    height: "86px",
    [theme.breakpoints.down(502)]:{
        height: "43px",
    },
    background: "#76D4C3",
    "& .BarraInfoLectura": {
      height: "86px",
      background: `Url(https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Barras/Azul.png)`,
        [theme.breakpoints.down(502)]:{
            height: "43px",
        },
    },
    "& .InfoLectura": {
      position: "absolute",
      height: "86px",
      width: "219px",
      boxSizing: "border-box",
      padding: "17.2px 0",
      background: "#FAFAFA",
      borderRadius: "50px",
      boxShadow: "2px 2px 2px 2px #00000029",
      zIndex: "4",
        [theme.breakpoints.down(502)]:{
            height: "43px",
            width: "109px",
            padding: "8.6px 0",
            borderRadius: "25px",
        },
    },
    "& .InfoLectura:before": {
      position: "absolute",
      display: "flex",
      boxSizing: "border-box",
      padding: "0 0 0 10px",
      justifyContent: "center",
      alignItems: "center",
      height: "39.88px",
      width: "39.88px",
      borderRadius: "50%",
      content: '""',
      boxShadow: "3px 0px .5px 0px #00000029",
      background: "#FAFAFA",
      left: "90px",
      top: "65px",
      transform: "rotate(0.25turn)",
      [theme.breakpoints.down(502)]:{
        left: "45px",
        top: "30px",
        height: "20.88px",
        width: "20.88px",
        },
    },
    "& .InfoLectura:after": {
      content: '""',
      height: "15px",
      width: "15px",
      borderTop: "2px solid #20A698",
      borderLeft: "2px solid #20A698",
      position: "absolute",
      bottom: "-10px",
      left: "Calc(50% - 8px)",
      transform: "rotate(0.625turn)",
      [theme.breakpoints.down(502)]:{
        height: "10px",
        width: "10px",
        left: "Calc(50% - 4.5px)",
        bottom: "-3px",
        borderTop: "1px solid #20A698",
        borderLeft: "1px solid #20A698",
        },
    },
    "& .Sesion": {
      borderLeft: "2px solid #D8D8D8",
    },
    "& .Dato": {
      margin: "0 0 5px 0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#20A698",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        margin: "0 0 2.5px 0",
        },
    },
    "& .Caracter": {
      margin: "0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#000000",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        },
    },
       '& .Regresar':{
        color: '#F2624F',
        border: '#F2624F 1px solid',
        height: '30px',
        width:'100px',
        borderRadius: '20px',
        letterSpacing: '0.14px',
        textTransform: 'none',
        background:'#FEFAF1',
        top:'calc(50% - 15px)',
        left:'5%',
        zIndex:4,
        [theme.breakpoints.down(502)]: {
          font:'bold 10px lato',
          width: "60px",
        },
      },
  },
    ContenedorLectura:{
        background:'#fff',
        width:'1160px',
        height:'100%',
        padding:'60px 168px 35px ',
        
        transform: (props) =>
        props.scale > 1 ? `scale(${props.scale}) translate(0px, 10%)` : `none`,
        [theme.breakpoints.down(1161)]:{
            width:'502px',
            padding:'60px 0 0 0',
          },
                [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },

        '& .Escritorio':{
            display:'block',
            [theme.breakpoints.down(1161)]:{
                display:'none'
            },
        },
        '& .Tablet':{
            display:'none',
            [theme.breakpoints.down(1161)]:{
                display:'block'
            },
        }
    },
    ContainerTitulo:{
        margin:'0 0 34px 0',
        [theme.breakpoints.down(1161)]:{
            margin:'0 0 34px 0',
        },
        '& .Titulo':{
            font:'48px fredoka one',
            letterSpacing: '0px',
            lineHeight:'58px',
            color: '#FC5E9E',
            margin:'0',
            textAlign:'center',
            [theme.breakpoints.down(1161)]:{
                font:'43px fredoka one',
                lineHeight:'52px',
            },
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .TextImg':{
            font:'20px lato',
            color:'#717476',
            letterSpacing:'.19px',
            lineHeight:'30px',
            margin:'17px 0 36px 0',
            [theme.breakpoints.down(1161)]:{
                font:' 16px lato',
                lineHeight :'25px',
                letterSpacing: '0.15px',
            },
            [theme.breakpoints.down(502)]:{
                font:' 11px lato',
                lineHeight :'14px',
                letterSpacing: '0.10px',
            },
        },
        '& .SubTitulo':{
            color:'#926DD1',
            margin:'0 0 36px 0',
            font:'bold 30px lato',
            letterSpacing:'.28px',
            lineHeight:'30px',
            padding:'0 0 0 62px',
            boxSizing:'border-box',
            [theme.breakpoints.down(1161)]:{
                lineHeight :'28px',
                font:'bold 26px lato',
                letterSpacing: '0.24px',
                padding:'0 0 0 46px',
            },
            [theme.breakpoints.down(502)]:{
                font:'bold 18px lato',
                lineHeight :'24px',
                letterSpacing: '0.21px',
            },
        },
        '& .Ul':{
            lineHeight : '30px',
            font:'22px lato',
            color:'#000',
            letterSpacing: '0.20px',
            margin:'0',
            display: "flex",
            padding:0,
            flexDirection: "column",
            counterReset: "numeros",
            listStyleType: "none",
            [theme.breakpoints.down(1161)]:{
                font:'18px lato',
                lineHeight :'28px',
                letterSpacing: '0.17px',
            },
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .Li':{
            position: "relative",
            margin:'0 0 36px 0',
            padding:'0 0 0 62px',
            boxSizing:'border-box',
            [theme.breakpoints.down(1161)]:{
                padding:'0 0 0 46px',
            },
        },
        "& .Li:before": {
            font: "25px Fredoka One",
            color: "#FFF",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            content: "counter(numeros)",
            counterIncrement: "numeros",
            position: "absolute",
            left: "0",
            top: "0",
            background: "#F36539",
            borderRadius: "50% 50% 0% 50%",
            height: "43px",
            width: "42px",
            [theme.breakpoints.down(1161)]: {
              height: "35px",
              width: "34px",
              font: "21px Fredoka One",
            },
        },  
        

        '& .Imagen':{
            [theme.breakpoints.down(1161)]:{
                width:'100%',
            },
        },  
        

    }
}));

const Funciona_compartir = (props) => {

   const {activeStep, setActiveStep, handlePreviewStep, id_actividad} = props
  
  const handleContinue = () => {
    setActiveStep(activeStep - 1)
    handlePreviewStep()
  }

    const {
      handleSize,
      disabledZoom,
      closeZoom,
      handleZoom,
     scale
    } = useContext(DrawerContext);
    const [loading, setLoading] = React.useState(true);
    const wrapperRef = React.useRef(null);
    const imagesLoaded = useOnLoadImages(wrapperRef);
  
    const detectSize = () => {
      const lectura = document.getElementById("lectura");
      const newWidth = lectura?.offsetWidth;
      const newHeight = lectura?.offsetHeight;
      setLoading(false);
      if (lectura) handleSize(newHeight, newWidth);
    };
    useEffect(() => {
      window.addEventListener("resize", detectSize);
      return () => {
        handleZoom()
        window.removeEventListener("resize", detectSize);
        closeZoom()
      };
    }, []);
  
    const classes = useStyles({ disabledZoom,scale });
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
             <Grid container item justifyContent="center" className={classes.ContainerInfoLectura}>
               <Grid container className="BarraInfoLectura">
            <Button className="Regresar" onClick={handleContinue}>
                Regresar
            </Button>
        </Grid>
               <Grid container item className="InfoLectura">
                    <Grid container item direction="column" xs={6} justifyContent="center">
                        <p className="Dato">Grado</p>
                        <p className="Caracter">1</p>
                    </Grid>
                    <Grid container item direction="column" xs={6} justifyContent="center" className="Sesion">
                        <p className="Dato">Sesión</p>
                        <p className="Caracter">58</p>
                    </Grid>
               </Grid>
            </Grid>
            {!imagesLoaded && <Loader />}
        <Grid
            container
            item
            className={classes.ContenedorLectura}
            justifyContent="center"
            id="lectura"
            onLoad={detectSize}
            ref={wrapperRef}
        >
        <Drawer id_lectura={id_actividad} />
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >
                    ¿Funciona compartir?
                    </h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                      
                        <ul className="Ul">
                            <li className='Li'>
                                Imagina que en casa tienes un mueble en el que acomodas tus zapatos, similar al que 
                                te presentamos aquí. Como puedes ver, todos los pares de zapatos tienen su propio 
                                espacio.
                            </li>

                            <Grid container justifyContent="center" sm={12}>
                                <Grid container justifyContent="center" >
                                    <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img1ro/Sesion58/1.png" alt="Tarjeta" />
                                </Grid>
                                <Grid container justifyContent="center" >
                                    <p className="TextImg">
                                    Cada par de zapatos tiene su propio lugar.
                                    </p>
                                </Grid>
                            </Grid>

                            <li className='Li'>
                            Ahora piensa qué harías si compras un nuevo par de zapatos. ¿Dónde los pondrías? 
                            ¡Exacto! El nuevo par de zapatos debe compartir espacio con otros.

                            </li>

                            <h3 className='SubTitulo'>El Principio del palomar</h3>
                            
                            <li className='Li'>
                                En matemáticas, esta idea es conocida como el <em>Principio del palomar</em>. Se le llama así
                                porque fue redactada de la siguiente manera: “Si hay más palomas que nidos, 
                                entonces en al menos un nido habrá dos palomas”.
                            </li>

                            <li className='Li'>
                                Por ejemplo, aquí tenemos cinco nidos y seis palomas. Ya que no hay suficientes 
                                nidos para cada una de las palomas, dos de ellas tienen que compartir el nido.
                            </li>

                            <Grid container justifyContent="center" sm={12}>
                                <Grid container justifyContent="center" >
                                    <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img1ro/Sesion58/2.png" alt="Tarjeta" />
                                </Grid>
                                <Grid container justifyContent="center" >
                                    <p className="TextImg">
                                    De acuerdo con el Principio del palomar, dos palomas deben compartir nido.
                                    </p>
                                </Grid>
                             </Grid>

                            <li className='Li'>
                            El Principio del palomar es una herramienta matemática que funciona para 
                            demostrar diversos resultados. Recuerda: la idea es que, si tienes más elementos que 
                            opciones o características, al menos dos elementos compartirán esa opción o 
                            característica.
                            </li>
                            <li className='Li'>
                                Ahora que ya lo sabes, prueba el Principio del palomar con la siguiente situación: 
                                tienes dos calcetas azules y dos calcetas rojas. ¿Qué sucede si eliges tres calcetas sin 
                                ver? ¡Compara tu respuesta con la de tus compañeros!
                            </li>

                        </ul>
                     
                    </Grid>

                </Grid>
        </Grid>
        <Grid container item style={{ marginTop: 80 }}></Grid>
    </Grid>
    );
}
 
export default Funciona_compartir ;
