import React, { Suspense, useEffect } from 'react';
import ReturnButton from './ReturnButton';
import NavBar           from '../../NavBar';
import MenuIzquierdo    from '../../MenuIzquierdo';
import Grid             from '@material-ui/core/Grid'
import { makeStyles}    from '@material-ui/core';

const useStyles = makeStyles(theme=>({
  grid1:{    
    zIndex:'3',
    
  },
  grid2:{    
    zIndex:'2'
    
  },
  grid3:{    
    boxSizing:'border-box',
    zIndex:'1',
    paddingLeft:'3%',
    [theme.breakpoints.down('md')]:{
      paddingLeft:'0px',
    }
  }
}))

const Lectura = (props) => {
  const classes= useStyles()
  // const {grade, sesion,}  = useParams()
  const grade = props.location.state.grade
  const sesion = props.location.state.sesion
  // const toSearch = props?.location?.state?.toSearch
  const [gradoLecturas, setGradoLecturas] = React.useState("")

  useEffect(()=>{
    switch (grade) {
      case 6:
        setGradoLecturas("Sexto")
      break;
      case 2:
        setGradoLecturas("Segundo")
      break;
      case 3:
        setGradoLecturas("Tercero")
      break;
      case 4:
        setGradoLecturas("Cuarto")
      break;
      case 5:
        setGradoLecturas("Quinto")
      break;
    
      default:
        setGradoLecturas("Primero")
        break;
    }
  },[])

  const OtherComponent = React.lazy(() =>   
  gradoLecturas && sesion &&
      // import(`../../LecturasActividad/Lecturas${gradoLecturas}/Sesion${sesion}/Lectura`)
      import(`../LecturasBiblioteca/Lecturas${gradoLecturas}/Sesion${sesion}/Lectura`)
         
  )
 
  // console.log(gradoLecturas,sesion, grade, "infoLect" )
  return (
    <Grid container >
    <Grid item className={classes.grid1} sm={12}> 
      <NavBar/>
    </Grid>
    <Grid item className={classes.grid2} sm={1}> 
      <MenuIzquierdo/>
    </Grid>
    <Grid item className={classes.grid3} sm={12}>
      <Suspense fallback={<div>Cargando...</div>}>
        {gradoLecturas 
          && sesion 
          && 
          <>
          <ReturnButton/>
          <OtherComponent style={{marginTop: "0px"}}/>
          </>}
      </Suspense>
    </Grid>
</Grid>

   );
}

 
export default Lectura;
