import  React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";

import { GridCloseIcon } from "@mui/x-data-grid";
import { makeStyles } from "@material-ui/core";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    // padding: theme.spacing(2),

    [theme.breakpoints.up("sm")]: {
      // margin: "20px 0",
      paddingTop: "0",
      paddingBottom: "0",
    },
  },
  "& .MuiDialog-paper": {
    borderRadius: "12.1px",
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
    display: "flex",
    justifyContent: "center",
    gap: "5%",
    marginBottom: "16px",
  },
  "& .MuiDialogTitle-root": {
    [theme.breakpoints.up("sm")]: {
      // margin: "20px 0",
      padding: "22px",
    },
  },
}));

const useStyles = makeStyles((theme) => ({
  containerContent: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    [theme.breakpoints.up("sm")]: {
      // margin: "20px 0",
      minWidth: "300px",
    },
    [theme.breakpoints.up("md")]: {
      // margin: "20px 0",
      minWidth: "520px",
    },
  },
  code: {
    fontFamily: "Fredoka One",
    margin: " 0 20px",
    // fontFamily: "Fredoka One",
    fontSize: "20px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "0.23px",
    textAlign: "center",
    color: "#9051b1",

    [theme.breakpoints.up("lg")]: {
      fontSize: "32px",
      margin: " 0 30px",
    },
  },
  containerButtons: {
    display: "flex",
    justifyContent: "center",
  },
  button: {
    backgroundColor: "#B965D3",
    borderRadius: "4px",
    boxSizing: "borderBox",
    outline: "0",
    border: "0",
  },
  title: {
    fontFamily: "Fredoka One",
    margin: 0,
    fontSize: "24px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "0.23px",
    textAlign: "center",
    marginBottom: "20px",
    color: "#000",
    [theme.breakpoints.up("lg")]: {
      fontSize: "32px",
      // margin: "10px",
    },
  },
  text: {
    fontFamily: "Lato",
    margin: "0",
    fontSize: "16px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "0.23px",
    textAlign: "center",
    color: "#000",
    [theme.breakpoints.up("lg")]: {
      fontSize: "22px",
      margin: "10px",
    },
  },
}));

const PurpleButton = styled(Button)(({ theme }) => ({
  color: "#FFFFFF",
  backgroundColor: "#9051b1",
  borderRadius: "30px",
  // margin: "0 auto",
  width: "50%",
  textTransform: "unset",
  maxWidth: "130px",
  "&:hover": {
    backgroundColor: "#B965D3 ",
  },
}));
const WitheButton = styled(Button)(({ theme }) => ({
  color: "#9051b1",
  // borderColor: "#9051b1",
  border: "1px solid #9051b1",
  backgroundColor: "#FFFFFF",
  textTransform: "unset",
  borderRadius: "30px",
  // margin: "0 auto",
  width: "50%",
  maxWidth: "130px",
  "&:hover": {
    backgroundColor: "#B965D310 ",
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 4 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <GridCloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const ModalCode = ({ open, handleClose, handleConfirm, data }) => {
  const { firstName, firstLastName, secondLastName, group, code } = data;
  const classes = useStyles();
  const [copiedActivated, setCopiedActivated] = React.useState(false);

  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(code);
      await setCopiedActivated(true);
    } catch (error) {}
  };

  React.useEffect(() => {
    const myTimeout = setTimeout(() => {
      if (copiedActivated) setCopiedActivated(false);
    }, 3000);
    return () => {
      clearTimeout(myTimeout);
    };
  }, [copiedActivated]);

  return (
    <>
      <BootstrapDialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} maxWidth="md">
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}></BootstrapDialogTitle>
        <DialogContent>
          <div className={classes.containerContent}>
            <h3 className={classes.title}>Se generó el código</h3>
            <h3 className={classes.code}>{code}</h3>
            <h4 className={classes.text}>Para el estudiante:</h4>
            <h3 className={classes.text}>{`${firstName} ${firstLastName} ${secondLastName}`}</h3>
            <h4 className={classes.text}>Grupo</h4>
            <h3 className={classes.text}>{group}</h3>
          </div>
        </DialogContent>
        <DialogActions disableSpacing={true} className={classes.containerButtons}>
          <WitheButton className={classes.button} variant="contained" onClick={handleClose}>
            Cerrar
          </WitheButton>
          <PurpleButton
            onClick={(event) => {
              copyToClipboard();
            }}
            className={classes.button}
            variant="contained"
          >
            {copiedActivated ? "Copiado" : "Copiar"}
          </PurpleButton>
        </DialogActions>
      </BootstrapDialog>
    </>
  );
};
export default ModalCode;
