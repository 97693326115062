import { Button, Grid,makeStyles}              from '@material-ui/core';
import React, { useContext, useEffect } from "react";
import DrawerContext from "../../../../../usability/DrawerContext";
import { useOnLoadImages } from "../../../../hooks/useOnLoadImages";
import Loader from "../../../../Loader/Loader";
import Drawer from "../../ReadingTools/Drawer";

const useStyles =  makeStyles (theme =>({
     Contenedor:{
        background:'#E9FBF9',
        width:'100vw',
        overflowX:'clip',
        [theme.breakpoints.down(1161)]:{
            background:'none',
           overflowX:'unset',
        },
        [theme.breakpoints.down(600)]:{
            marginTop: '79.51px',
        }
    },
  ContainerInfoLectura: {
    position: "relative",
    height: "86px",
    [theme.breakpoints.down(502)]:{
        height: "43px",
    },
    background: "#76D4C3",
    "& .BarraInfoLectura": {
      height: "86px",
      background: `Url(https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Barras/Azul.png)`,
        [theme.breakpoints.down(502)]:{
            height: "43px",
        },
    },
    "& .InfoLectura": {
      position: "absolute",
      height: "86px",
      width: "219px",
      boxSizing: "border-box",
      padding: "17.2px 0",
      background: "#FAFAFA",
      borderRadius: "50px",
      boxShadow: "2px 2px 2px 2px #00000029",
      zIndex: "4",
        [theme.breakpoints.down(502)]:{
            height: "43px",
            width: "109px",
            padding: "8.6px 0",
            borderRadius: "25px",
        },
    },
    "& .InfoLectura:before": {
      position: "absolute",
      display: "flex",
      boxSizing: "border-box",
      padding: "0 0 0 10px",
      justifyContent: "center",
      alignItems: "center",
      height: "39.88px",
      width: "39.88px",
      borderRadius: "50%",
      content: '""',
      boxShadow: "3px 0px .5px 0px #00000029",
      background: "#FAFAFA",
      left: "90px",
      top: "65px",
      transform: "rotate(0.25turn)",
      [theme.breakpoints.down(502)]:{
        left: "45px",
        top: "30px",
        height: "20.88px",
        width: "20.88px",
        },
    },
    "& .InfoLectura:after": {
      content: '""',
      height: "15px",
      width: "15px",
      borderTop: "2px solid #20A698",
      borderLeft: "2px solid #20A698",
      position: "absolute",
      bottom: "-10px",
      left: "Calc(50% - 8px)",
      transform: "rotate(0.625turn)",
      [theme.breakpoints.down(502)]:{
        height: "10px",
        width: "10px",
        left: "Calc(50% - 4.5px)",
        bottom: "-3px",
        borderTop: "1px solid #20A698",
        borderLeft: "1px solid #20A698",
        },
    },
    "& .Sesion": {
      borderLeft: "2px solid #D8D8D8",
    },
    "& .Dato": {
      margin: "0 0 5px 0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#20A698",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        margin: "0 0 2.5px 0",
        },
    },
    "& .Caracter": {
      margin: "0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#000000",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        },
    },
       '& .Regresar':{
        color: '#F2624F',
        border: '#F2624F 1px solid',
        height: '30px',
        width:'100px',
        borderRadius: '20px',
        letterSpacing: '0.14px',
        textTransform: 'none',
        background:'#FEFAF1',
        top:'calc(50% - 15px)',
        left:'5%',
        zIndex:4,
        [theme.breakpoints.down(502)]: {
          font:'bold 10px lato',
          width: "60px",
        },
      },
  },

    ContenedorLectura:{
        background:'#fff',
        width:'1160px',
        height:'100%',
        padding:'0 ',
        position:'relative',
        
        transform: (props) =>
        props.scale > 1 ? `scale(${props.scale}) translate(0px, 10%)` : `none`,
        [theme.breakpoints.down(1161)]:{
            width:'768px',
          },
          [theme.breakpoints.down(768)]:{
            width:'350px',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
        },
        '& .Derechos':{
            position:'absolute',
            top:'3242px',
            textAlign:"center",
            margin:"0px",
            font:" 15px lato",
            color:"#FFFFFF",
            [theme.breakpoints.down(1161)]:{
                display:'none'
            },
        },
        '& .Escritorio':{
            display:'block',
            [theme.breakpoints.down(1161)]:{
                display:'none'
            },
        },
        '& .Tablet':{
            display:'none',
            [theme.breakpoints.down(1161)]:{
                display:'block'
            },
        },
        '& .Pos':{
            position:'absolute',
            margin:'0',
        },
        '& .Sub':{
            font:'35px Fredoka One', 
            lineHeight : '42px',
            [theme.breakpoints.down(1161)]:{
                font:'23px Fredoka One', 
                lineHeight : '27px',
            },
            [theme.breakpoints.down(768)]:{
                font:'13px Fredoka One', 
                lineHeight : '14px',
            },
        },
        '& .Text':{
            font:'22px lato',
            color:'#000000', 
            letterSpacing: '0.21px',
            lineHeight : '30px',
            [theme.breakpoints.down(1161)]:{
                font:'14px lato',
                lineHeight : '19px',
            },
            [theme.breakpoints.down(768)]:{
                font:'5.5px lato',
                lineHeight :'9px',
            },
        },
        '& .Titulo':{
            font:'68px Fredoka One', 
            color:'#FFFFFF', 
            top:'32px',
            left:'152px',
            margin:'0',
            [theme.breakpoints.down(1161)]:{
                font:'44px Fredoka One', 
                top:'22px',
                left:'119px',
            },
            [theme.breakpoints.down(768)]:{
                font:'21px Fredoka One', 
                top:'12px',
                left:'50px',
            },
        },  

        '& .Titulo2':{
            font:'50px Fredoka One', 
            color:'#FFFFFF',
            margin:'0',
            [theme.breakpoints.down(1161)]:{
                font:'32px Fredoka One', 
            },
            [theme.breakpoints.down(1161)]:{
                font:'15px Fredoka One', 
            },
        },

        '& .Subtitulo':{
            color:'#FFFFFF',
            textAlign:'center',
            top:'227px',
            left:'276px',
            [theme.breakpoints.down(1161)]:{
                top:'154px',
                left:'183px',
            },
            [theme.breakpoints.down(768)]:{
                top:'68px',
                left:'68px',
            }
        },
        '& .Subtitulo2':{
            color:'#FFFFFF',
            textAlign:'center',
            top:'1112px',
            left:'531px',
            [theme.breakpoints.down(1161)]:{
                top:'741px',
                left:'356px',
            },
            [theme.breakpoints.down(768)]:{
                top:'333px',
                left:'155px',
            }
        },
        '& .Subtitulo3':{
            color:'#6B9986',
            textAlign:'center',
            top:'1329px',
            left:'191px',
            [theme.breakpoints.down(1161)]:{
                top:'891px',
                left:'126px',
            },
            [theme.breakpoints.down(768)]:{
                top:'400px',
                left:'50px',
            }
        },
        '& .Subtitulo4':{
            color:'#1DA698',
            textAlign:'center',
            top:'1723px',
            left:'190px',
            [theme.breakpoints.down(1161)]:{
                top:'1141px',
                left:'127px',
            },
            [theme.breakpoints.down(768)]:{
                top:'515px',
                left:'50px',
            }
        },
        '& .Subtitulo5':{
            color:'#2A7C55',
            textAlign:'center',
            top:'2029px',
            left:'190px',
            [theme.breakpoints.down(1161)]:{
                top:'1353px',
                left:'127px',
            },
            [theme.breakpoints.down(768)]:{
                top:'615px',
                left:'50px',
            } 
        },
        '& .Subtitulo6':{
            color:'#FCA605',
            textAlign:'center',
            top:'2029px',
            left:'762px',
            [theme.breakpoints.down(1161)]:{
                top:'1353px',
                left:'512px',
            },
            [theme.breakpoints.down(768)]:{
                top:'615px',
                left:'230px',
            } 
        },
        '& .Subtitulo7':{
            color:'#1DA698',
            textAlign:'center',
            top:'2483px',
            left:'475px',
            [theme.breakpoints.down(1161)]:{
                top:'1647px',
                left:'318px',
            },
            [theme.breakpoints.down(768)]:{
                top:'750px',
                left:'145px',
            }  
        },
        '& .Subtitulo8':{
            color:'#2A7C55',
            textAlign:'center',
            top:'2771px',
            left:'191px',
            [theme.breakpoints.down(1161)]:{
                top:'1842px',
                left:'126px',
            },
            [theme.breakpoints.down(768)]:{
                top:'835px',
                left:'50px',
            } 
        },
        '& .Subtitulo9':{
            color:'#FCA605',
            textAlign:'center',
            top:'2771px',
            left:'762px',
            [theme.breakpoints.down(1161)]:{
                top:'1842px',
                left:'512px',
            }, 
            [theme.breakpoints.down(768)]:{
                top:'835px',
                left:'230px',
            }
        },
        '& .Texto':{
            top:'407px',
            left:'166px',
            [theme.breakpoints.down(1161)]:{
                top:'261px',
                left:'106px',
            },
            [theme.breakpoints.down(768)]:{
                top:'122px',
                left:'50px',
            }     
        },
        '& .Texto2':{
            top:'537px',
            left:'256px',
            [theme.breakpoints.down(1161)]:{
                top:'356px',
                left:'174px',
            },
            [theme.breakpoints.down(768)]:{
                top:'160px',
                left:'70px',
            }       
        },
        '& .Texto3':{
            top:'616px',
            left:'256px',
            [theme.breakpoints.down(1161)]:{
                top:'407px',
                left:'174px',
            },
            [theme.breakpoints.down(768)]:{
                top:'185px',
                left:'70px',
            }    
        },
        '& .Texto4':{
            top:'705px',
            left:'256px',
            [theme.breakpoints.down(1161)]:{
                top:'467px',
                left:'174px',
            },
            [theme.breakpoints.down(768)]:{
                top:'211px',
                left:'70px',
            }       
        },
        '& .Texto5':{
            top:'763px',
            left:'256px',
            [theme.breakpoints.down(1161)]:{
                top:'508px',
                left:'174px',
            },
            [theme.breakpoints.down(768)]:{
                top:'230px',
                left:'70px',
            }      
        },
        '& .Texto6':{
            top:'842px',
            left:'256px',
            [theme.breakpoints.down(1161)]:{
                top:'559px',
                left:'174px',
            },
            [theme.breakpoints.down(768)]:{
                top:'255px',
                left:'70px',
            }      
        },
        '& .Texto7':{
            top:'1391px',
            left:'191px',
            [theme.breakpoints.down(1161)]:{
                top:'933px',
                left:'127px',
            },
            [theme.breakpoints.down(768)]:{
                top:'420px',
                left:'50px',
            }      
        },
        '& .Texto8':{
            top:'1785px',
            left:'191px',
            [theme.breakpoints.down(1161)]:{
                top:'1181px',
                left:'127px',
            },
            [theme.breakpoints.down(768)]:{
                top:'535px',
                left:'50px',
            }       
        },
        '& .Texto9':{
            top:'2091px',
            left:'191px',
            [theme.breakpoints.down(1161)]:{
                top:'1393px',
                left:'127px',
            },
            [theme.breakpoints.down(768)]:{
                top:'635px',
                left:'50px',
            }     
        },
        '& .Texto10':{
            top:'2091px',
            left:'762px',
            [theme.breakpoints.down(1161)]:{
                top:'1393px',
                left:'512px',
            },
            [theme.breakpoints.down(768)]:{
                top:'635px',
                left:'230px',
            }     
        },
        '& .Texto11':{
            top:'2545px',
            left:'475px',
            [theme.breakpoints.down(1161)]:{
                top:'1691px',
                left:'318px',
            },
            [theme.breakpoints.down(768)]:{
                top:'770px',
                left:'145px',
            }       
        },
        '& .Texto12':{
            top:'2833px',
            left:'191px',
            [theme.breakpoints.down(1161)]:{
                top:'1883px',
                left:'129px',
            },
            [theme.breakpoints.down(768)]:{
                top:'855px',
                left:'50px',
            }       
        },
        '& .Texto13':{
            top:'2833px',
            left:'762px',
            [theme.breakpoints.down(1161)]:{
                top:'1883px',
                left:'512px',
            },
            [theme.breakpoints.down(768)]:{
                top:'855px',
                left:'230px',
            }      
        },
        '& .Imagen':{
            [theme.breakpoints.down(768)]:{
                width:'100%'
            }  
        },
    },
    Esconder:{
        [theme.breakpoints.down(768)]:{
            display:'none',
        },
    },
    Imagen:{
        display:'none',
        [theme.breakpoints.down(768)]:{
            width:'100%',
            display:'flex',
        },
    },
    
}));

const Diversas_acciones_para_salvar_el_mundo = (props) => {

  const {activeStep, setActiveStep, handlePreviewStep, id_actividad} = props

  const handleContinue = () => {
    setActiveStep(activeStep - 1)
    handlePreviewStep()
  }

  const {
    handleSize,
    disabledZoom,
    closeZoom,
    handleZoom,
    scale
  }= useContext(DrawerContext);
  const [loading, setLoading] = React.useState(true);
  const wrapperRef = React.useRef(null);
  const imagesLoaded = useOnLoadImages(wrapperRef);

  const detectSize = () => {
    const lectura = document.getElementById("lectura");
    const newWidth = lectura?.offsetWidth;
    const newHeight = lectura?.offsetHeight;
    setLoading(false);
    if (lectura) handleSize(newHeight, newWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", detectSize);
    return () => {
      handleZoom()
      window.removeEventListener("resize", detectSize);
      closeZoom()
    };
  }, []);

  const classes = useStyles({ disabledZoom,scale });
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
             <Grid container item justifyContent="center" className={classes.ContainerInfoLectura}>
                       <Grid container className="BarraInfoLectura">
            <Button className="Regresar" onClick={handleContinue}>
                Regresar
            </Button>
        </Grid>
               <Grid container item className="InfoLectura">
                    <Grid container item direction="column" xs={6} justifyContent="center">
                        <p className="Dato">Grado</p>
                        <p className="Caracter">2</p>
                    </Grid>
                    <Grid container item direction="column" xs={6} justifyContent="center" className="Sesion">
                        <p className="Dato">Sesión</p>
                        <p className="Caracter">14</p>
                    </Grid>
               </Grid>
            </Grid>
            {!imagesLoaded && <Loader />}
            <Grid
            container
            item
            className={classes.ContenedorLectura}
            justifyContent="center"
            id="lectura"
            onLoad={detectSize}
            ref={wrapperRef}
            >
            <Drawer id_lectura={id_actividad} withoutTransform ={true} />
            <Grid container justifyContent="center" item className={classes.Esconder}>
                <h2 className='Pos Titulo'>
                    Diversas acciones<br/>
                    <h2 className=' Titulo2'>para salvar el mundo</h2>
                </h2>

                <h3 className='Pos Sub Subtitulo'>¡Tú puedes salvar <br/>el mundo!</h3>

                <p  className='Pos Text Texto'>
                    La Tierra presenta distintos problemas que tienen <br className='Escritorio'/> 
                    un <br className='Tablet'/>  efecto negativo sobre la vida de las plantas, los animales<br/> 
                    y los seres humanos. Algunos de ellos son:<br/>
                </p>
                <p  className='Pos Text Texto2'>
                    Aumento de la temperatura por causa del efecto invernadero aumentado,<br/> 
                    que se origina por el exceso de distintos gases contaminantes.
                </p>
                <p  className='Pos Text Texto3'>
                    Escasez de agua potable por sequías, y agua contaminada que no puede<br/> 
                    beberse ni usarse.
                </p>
                <p  className='Pos Text Texto4'>
                    Suelo contaminado que no sirve para el cultivo.
                </p>
                <p  className='Pos Text Texto5'>
                    Extinción de diversas especies de plantas y animales por no tener<br/> 
                    alimento ni hogar, o por la contaminación de su hábitat.
                </p>
                <p  className='Pos Text Texto6'>
                    Enfermedades en seres humanos y animales a causa de la contaminación<br/> 
                    del suelo, el aire y el agua.
                </p>
                <h3 className='Pos Sub Subtitulo2'>¿Quieres saber cómo <br/>salvar al mundo?</h3>
                <h3 className='Pos Sub Subtitulo3'>Evita desperdiciar</h3>
                <p  className='Pos Text Texto7'>
                    La comida y todos los objetos a tu alrededor se<br/> 
                    producen por medio de recursos, como agua,<br/> 
                    electricidad, animales y plantas.<br/> <br/>
                    
                    Desperdiciar obliga a aumentar la producción, a<br/>
                    gastar recursos y a usar ciertos procesos que<br/> 
                    podrían contaminar el suelo, el aire y el agua.<br/>
                </p>
                <h3 className='Pos Sub Subtitulo4'>Reduce, recicla y reutiliza (3)</h3>
                <p  className='Pos Text Texto8'>
                    1) Reduce el uso de materiales contaminantes. 2) Recicla un<br/> 
                    desecho para que se convierta en material para hacer objetos<br/> 
                    nuevos. 3) Reutiliza objetos para que tengan la misma función<br/> 
                    o una nueva.
                </p>
                <h3 className='Pos Sub Subtitulo5'>Reincorpora</h3>
                <p  className='Pos Text Texto9 Escritorio'>
                    Elabora compostas para<br/> 
                    nutrir el suelo y permitir<br/> 
                    que crezcan plantas y<br/> 
                    árboles. La composta se<br/> 
                    hace utilizando desperdicios<br/> 
                    de comida como cáscaras de<br/> 
                    frutas y verduras, hojas<br/> 
                    caídas de los árboles y<br/> 
                    tierra.
                </p>
                <p  className='Pos Text Texto9 Tablet'>
                    Elabora compostas para<br/> 
                    nutrir el suelo y permitir que<br/> 
                    crezcan plantas y árboles.<br/> 
                    La composta se hace<br/> 
                    utilizando desperdicios de<br/> 
                    comida como cáscaras de<br/> 
                    frutas y verduras, hojas<br/> 
                    caídas de los árboles y<br/> 
                    tierra.
                </p>
                <h3 className='Pos Sub Subtitulo6'>Ahorra</h3>
                <p  className='Pos Text Texto10'>
                    El agua es un recurso<br/> 
                    no renovable: no<br/> 
                    puede fabricarse ni<br/> 
                    multiplicarse.
                </p>
                <h3 className='Pos Sub Subtitulo7'>Vigila la calidad del aire</h3>
                <p  className='Pos Text Texto11'>
                    Identifica las fuentes de humo y gases<br/> 
                    contaminantes. Comenta este tema con los<br/> 
                    adultos que te rodean e invítalos a que<br/> 
                    reduzcan su uso.
                </p>
                <h3 className='Pos Sub Subtitulo8'>Cuida</h3>
                <p  className='Pos Text Texto12'>
                    Protege los<br/> 
                    ecosistemas. Tirar<br/> 
                    basura en lagos,<br/> 
                    ríos, mares, bosques,<br/> 
                    selvas y otros<br/> 
                    ambientes los daña,<br/> 
                    así como a los <br/>
                    organismos que<br/> 
                    viven en ellos.<br/>
                </p>
                <h3 className='Pos Sub Subtitulo9'>Informa</h3>
                <p  className='Pos Text Texto13'>
                    Lograremos resolver<br/> 
                    los problemas de la <br/>
                    Tierra si todos <br/>
                    cooperamos.<br/> 
                    Informa a tus<br/> 
                    familiares y amigos<br/> 
                    para que juntos<br/>
                    salvemos el mundo.<br/>
                </p>

                <img className='Escritorio' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img2do/Sesion14/1.png" alt="Fondo" />
                <img className='Imagen Tablet' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img2do/Sesion14/1R.png" alt="Fondo" />
                </Grid>
                <img className={classes.Imagen} src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img2do/Sesion14/F2.png" alt="Fondo" />
      
            </Grid>

            <Grid container item style={{ marginTop: 80}} ></Grid>
        </Grid>
    );
}
 
export default Diversas_acciones_para_salvar_el_mundo;
