import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
     Contenedor:{
        background:'#E9FBF9',
        width:'100vw',
        overflowX:'clip',
        [theme.breakpoints.down(1161)]:{
            background:'none',
           overflowX:'unset',
        },
        [theme.breakpoints.down(600)]:{
            marginTop: '79.51px',
        }
    },
  ContainerInfoLectura: {
    position: "relative",
    height: "86px",
    [theme.breakpoints.down(502)]:{
        height: "43px",
    },
    background: "#76D4C3",
    "& .BarraInfoLectura": {
      height: "86px",
      background: `Url(https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Barras/Azul.png)`,
        [theme.breakpoints.down(502)]:{
            height: "43px",
        },
    },
    "& .InfoLectura": {
      position: "absolute",
      height: "86px",
      width: "219px",
      boxSizing: "border-box",
      padding: "17.2px 0",
      background: "#FAFAFA",
      borderRadius: "50px",
      boxShadow: "2px 2px 2px 2px #00000029",
      zIndex: "4",
        [theme.breakpoints.down(502)]:{
            height: "43px",
            width: "109px",
            padding: "8.6px 0",
            borderRadius: "25px",
        },
    },
    "& .InfoLectura:before": {
      position: "absolute",
      display: "flex",
      boxSizing: "border-box",
      padding: "0 0 0 10px",
      justifyContent: "center",
      alignItems: "center",
      height: "39.88px",
      width: "39.88px",
      borderRadius: "50%",
      content: '""',
      boxShadow: "3px 0px .5px 0px #00000029",
      background: "#FAFAFA",
      left: "90px",
      top: "65px",
      transform: "rotate(0.25turn)",
      [theme.breakpoints.down(502)]:{
        left: "45px",
        top: "30px",
        height: "20.88px",
        width: "20.88px",
        },
    },
    "& .InfoLectura:after": {
      content: '""',
      height: "15px",
      width: "15px",
      borderTop: "2px solid #20A698",
      borderLeft: "2px solid #20A698",
      position: "absolute",
      bottom: "-10px",
      left: "Calc(50% - 8px)",
      transform: "rotate(0.625turn)",
      [theme.breakpoints.down(502)]:{
        height: "10px",
        width: "10px",
        left: "Calc(50% - 4.5px)",
        bottom: "-3px",
        borderTop: "1px solid #20A698",
        borderLeft: "1px solid #20A698",
        },
    },
    "& .Sesion": {
      borderLeft: "2px solid #D8D8D8",
    },
    "& .Dato": {
      margin: "0 0 5px 0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#20A698",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        margin: "0 0 2.5px 0",
        },
    },
    "& .Caracter": {
      margin: "0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#000000",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        },
    },   },
    ContenedorLectura:{
        background:'#fff',
        width:'1160px',
        height:'100%',
        padding:'60px 168px 35px ',
        [theme.breakpoints.down(1161)]:{
            width:'502px',
            padding:'60px 0 0 0',
          },
        [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
        '& .ContainerDerechos':{
            bottom:'80px',
            [theme.breakpoints.down(1161)]:{
                display:'none',
            },
        },
        '& .Derechos':{
            textAlign:"center",
            margin:"0px",
            font:" 15px lato",
            color:"#717476",
        },
        '& .Escritorio':{
            display:'block',
            [theme.breakpoints.down(1161)]:{
                display:'none'
            },
        },
        '& .Tablet':{
            display:'none',
            [theme.breakpoints.down(1161)]:{
                display:'block'
            },
        }
    },
    ContainerTitulo:{
        margin:'0 0 34px 0',
        [theme.breakpoints.down(1161)]:{
            margin:'0 0 34px 0',
        },
        '& .Titulo':{
            font:'48px fredoka one',
            letterSpacing: '0px',
            lineHeight:'58px',
            color: '#007CB2',
            margin:'0',
            textAlign:'center',
            [theme.breakpoints.down(1161)]:{
                font:'43px fredoka one',
                lineHeight:'52px',
            },
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            lineHeight : '30px',
            margin:'0 0 36px 0',
            font:'22px lato',
            color:'#000',
            letterSpacing: '0.20px',
            [theme.breakpoints.down(1161)]:{
                font:'18px lato',
                lineHeight :'28px',
                letterSpacing: '0.17px',
            },
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .SubTitulo':{
            font:'bold 30px lato',
            color:'#00ADB6',
            letterSpacing:'.28px',
            lineHeight:'30px',
            margin:'0',
            [theme.breakpoints.down(1161)]:{
                lineHeight :'28px',
                font:'bold 26px lato',
                letterSpacing: '0.24px',
            },
            [theme.breakpoints.down(502)]:{
                font:'bold 18px lato',
                lineHeight :'24px',
                letterSpacing: '0.21px',
            },
        },

        '& .TextImg':{
            font:'20px lato',
            color:'#717476',
            letterSpacing:'.19px',
            lineHeight:'30px',
            margin:'21px 0 0 0',
            [theme.breakpoints.down(1161)]:{
                font:' 16px lato',
                lineHeight :'25px',
                letterSpacing: '0.15px',
            },
            [theme.breakpoints.down(502)]:{
                font:' 11px lato',
                lineHeight :'14px',
                letterSpacing: '0.10px',
            },
        },
        '& .Imagen':{
            [theme.breakpoints.down(1161)]:{
                width:'100%',
            },
        },   
    }
}));


const Los_anteojos_una_historia_inesperada = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
             <Grid container item justifyContent="center" className={classes.ContainerInfoLectura}>
               <Grid container className="BarraInfoLectura"></Grid>
               <Grid container item className="InfoLectura">
                    <Grid container item direction="column" xs={6} justifyContent="center">
                        <p className="Dato">Grado</p>
                        <p className="Caracter">3</p>
                    </Grid>
                    <Grid container item direction="column" xs={6} justifyContent="center" className="Sesion">
                        <p className="Dato">Sesión</p>
                        <p className="Caracter">7</p>
                    </Grid>
               </Grid>
            </Grid>

            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >Los anteojos:<br className='Escritorio'/> una<br className='Tablet'/> historia inesperada</h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container sm={12}>
                      <p className="Lectura Esp"> 
                        Los descubrimientos y avances de la humanidad no sólo se tratan de grandes 
                        sucesos, como llegar a la Luna, lanzar cohetes al espacio o fabricar máquinas. 
                        También abarcan artefactos cotidianos, por ejemplo, relojes, radios y despertadores. 
                        ¿Imaginas cómo funcionan ciertos aparatos? ¿O cómo se inventaron? Por ejemplo, 
                        ¿sabes cómo se crearon los anteojos?<br/><br/> 
                        
                        Seguramente conoces a alguien que los usa para ver mejor o quizá tú mismo los 
                        necesitas. Todos funcionan de la misma manera: tienen vidrios con aumento y están 
                        montados en un armazón sencillo. ¿Te gustaría conocer su historia? <br/><br/>
                        
                        <h3 className='SubTitulo'>De cristales a anteojos: la evolución de los lentes</h3><br/> 
                        
                        No se sabe con exactitud dónde aparecieron los primeros, pero algunas historias 
                        hablan de ellos desde la Antigüedad. Hasta ahora, se sabe que la primera persona en 
                        escribir sobre los efectos ópticos fue el romano Séneca. Notó que los objetos se 
                        veían más grandes al mirarlos a través de un recipiente con agua. El primer lente 
                        perteneció al emperador romano Nerón, quien lo utilizó para ver mejor a los 
                        gladiadores. Se trataba de una esmeralda tallada en forma de luna cóncava que 
                        mejoraba su visión de lejos.
                      </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <Grid container item justifyContent='center'>
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img3ro/Sesion07/1.png" alt="Tarjeta" />
                        </Grid>
                        <Grid container item justifyContent='center'>
                            <p className='TextImg'>Réplica de las gafas más antiguas halladas en Europa. Se encuentran en el museo de Wienhausen.</p>
                        </Grid>
                    </Grid>
                </Grid>
               
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container sm={12}>
                      <p className="Lectura "> 
                        Durante la Edad Media, los religiosos ingleses copiaban libros escritos en árabe y 
                        otras lenguas en bibliotecas oscuras, por lo que sus ojos se cansaban. Uno de ellos se 
                        llamaba Roger Bacon y leyó en un manuscrito que los cristales funcionaban como un 
                        aumento para ver objetos. Entonces los monjes crearon algo que nombraron <em>piedra 
                        de lectura</em>, una especie de lupa que agrandaba las letras de los textos. De ella tallaron 
                        cristales más pequeños que denominaron <em>lentejas</em> y que usaban exclusivamente 
                        dentro de los monasterios.<br/><br/> 
                        
                        Su producción aumentó cuando más personas tuvieron acceso a los libros. La 
                        primera pintura en la que aparece este objeto retrata a un cardenal leyendo en su 
                        escritorio. Aunque también se tienen registros de que el poeta italiano Petrarca 
                        llevaba anteojos en el siglo <span style={{fontVariant: "all-small-caps"}}>XIV</span>.<br/><br/>
                        
                        Los materiales y las técnicas para construirlos mejoraron a partir de los primeros, 
                        como sucede con todas las invenciones. El alemán Nicolás de Cusa desarrolló lentes 
                        cóncavas que permitían ver mejor a la distancia. Y el inventor estadounidense 
                        Benjamin Franklin creó los lentes bifocales para ver bien tanto de lejos como de 
                        cerca. <br/><br/>
                        
                        Pero hasta ese momento seguían siendo únicamente fragmentos de cristal, aún no 
                        eran anteojos. Los primeros marcos fueron de hueso y madera. Los cristales con 
                        aumento montados en varillas, con puente para la nariz y patillas para las orejas
                        aparecieron hasta el siglo <span style={{fontVariant: "all-small-caps"}}>XVIII</span>. Eso los mantenía firmes en el rostro. <br/><br/>
                        
                        En el siglo <span style={{fontVariant: "all-small-caps"}}>XX</span> se descubrieron alternativas más finas y ligeras que el cristal. 
                        Actualmente se hacen con materiales como el policarbonato. Incluso se han dejado 
                        de lado los marcos y existen aquellos que se introducen directamente en los ojos,
                        conocidos como <em>de contacto</em>. Pero el método se mantuvo intacto desde la Roma 
                        antigua: usar lentes cóncavos o convexos que mejoren la visión.
                      </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img3ro/Sesion07/2.png" alt="Tarjeta" />
                    </Grid>
                </Grid>
                
            <Grid container item xs={12} justifyContent="center" className="ContainerDerechos">
                <p className="Derechos">
                    © 2022. Todos los derechos reservados, Pearson, Circuito de lectores.
                </p>                
            </Grid>
        </Grid>
    </Grid>
    );
}
 
export default Los_anteojos_una_historia_inesperada;
