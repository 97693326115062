import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
    ContenedorLectura:{
        width:'502px',
                  [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
    },

    ContainerTitulo:{
        margin:'0 0 46px 0',
        '& .Titulo':{
            letterSpacing: '0px',
            color: '#FF0000',
            margin:'0',
            textAlign:'center',
            font:'43px fredoka one',
            lineHeight:'52px',
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        position:'relative',
        '& .Lectura':{
            margin:'0 0 36px 0',
            color:'#000',
            font:'18px lato',
            lineHeight :'28px',
            letterSpacing: '0.17px',
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
       '& .TextImg':{
            margin:'24px 0 0 0',
            alignSelf:'flex-start',
            color:'#717476',
            font:' 16px lato',
            lineHeight :'25px',
            letterSpacing: '0.15px',
            [theme.breakpoints.down(502)]:{
                font:' 11px lato',
                lineHeight :'14px',
                letterSpacing: '0.10px',
            },
        },
        '& .Imagen':{ 
            width:'100%'
        },
        
    },
    

}));

const Cuáles_son_los_límites_de_la_tecnología = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>

            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >
                        ¿Cuáles son los límites<br/> de la tecnología?
                    </h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                            La humanidad comenzó a explotar los recursos naturales de manera extensiva 
                            aproximadamente en el año 1780. Por ejemplo, usó el carbón como combustible y los 
                            metales para elaborar herramientas. A ese periodo se le llama <em>Revolución Industrial</em> y, 
                            a partir de él, el avance de la ciencia fue imparable. La desproporción es grande si 
                            comparas estos 240 años de innovación con el tiempo total que los seres humanos 
                            han existido en la Tierra.<br/><br/> 
                            
                            Podría creerse que no existe un límite para el progreso logrado hasta hoy. La 
                            tecnología ha alcanzado nuevos niveles; ahora es una realidad tener conexión a 
                            internet en casi cualquier lugar del mundo. La medicina ha tenido desarrollos 
                            significativos, por ejemplo en nuevas vacunas. Existen videojuegos y realidades 
                            virtuales. Es normal pensar que la imaginación no tiene fronteras. <br/><br/>
                            
                            Entre los logros que podrán alcanzarse en los siguientes años se encuentran los 
                            viajes a Marte y la posibilidad de que en 2026 llegue la primera nave a ese planeta
                            con personas comunes a bordo. También se esperan mejores prótesis para las 
                            personas con discapacidades y energías renovables producidas naturalmente. <br/><br/>
                            
                            Pero el avance científico no es infinito, a pesar de que el futuro parece prometedor. 
                            Cada disciplina tiene sus límites. Por ejemplo, los viajes hacia otras estrellas son 
                            imposibles con los conocimientos actuales de matemáticas y física. Y tampoco se 
                            cuenta con vehículos adecuados para ello. Si bien llegar a Marte es un logro grande, 
                            es poco probable llegar a la estrella más cercana al Sol.
                        </p>
                    </Grid>
                    
                    <Grid container item sm={12} justifyContent="center">
                        <Grid container item sm={12} justifyContent="center">
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img4to/Sesion35/1.png"  alt="Tarjeta" />
                        </Grid>
                        <Grid container item sm={12} justifyContent="center">
                            <p className='TextImg'>Existe la posibilidad de que en un futuro no muy lejano lleguen las primeras personas al planeta Marte.</p>  
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container xs={12} >
                        <p className='Lectura'>
                            Los recursos naturales también son una limitante, pues no son eternos. Por ejemplo, 
                            los metales son uno de los materiales más usados, pero no son renovables. El 
                            progreso enfrentará problemas en algún momento por la poca abundancia de 
                            suministros. Es importante encontrar otras fuentes de energía para no frenar la 
                            innovación. <br/><br/>
                            
                            Existen fronteras en muchas áreas, aunque aún hay muchas cosas por descubrir. A 
                            pesar de que no nos es posible reproducir elementos que sólo brinda la naturaleza, sí 
                            tenemos la oportunidad de cuidar nuestros recursos. Está en nuestras manos reciclar 
                            y ser más conscientes de nuestro impacto en el planeta.
                        </p>
                    </Grid>
                </Grid>
        </Grid>
    </Grid>
    );
}
 
export default Cuáles_son_los_límites_de_la_tecnología;
