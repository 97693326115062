import React, { useContext } from "react";
import { Redirect, Link } from "react-router-dom";
import { Grid, makeStyles } from "@material-ui/core";
import Button from "@material-ui/core/Button";

import logoPea from "../../imgs/logoPea.png";
import logoDemo from "../../imgs/CL_LOGO_FINAL_Login 1.png";

import logoEscuela from "../Docente/img/logo_Mesa_de_trabajo.png";

import AuthContext from "../../../auth/AuthContext";
import axios from "../../../services/axios";
import types from "../../../types/types";
import ModalLogin from "../login/ForgetPassword/Modals/ModalLogin";
import { adminInstData } from "../../models/login";

import { dynamicYear } from "../../../utilities/getDynamicYear";
import useLoading from "../../hooks/useLoading";
import {
  backgroundColors,
  lgColors,
  loginBotton,
  txtColors,
} from "../../../constants/loginColors/colors";

const atRandom = parseInt(Math.random() * 6);

const randomColor = backgroundColors[atRandom];
const loginBottonColor = loginBotton[atRandom];
const textColor = lgColors[atRandom];
const smallText = txtColors[atRandom];

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100%",
    minHeight: "100vh",
    backgroundColor: (props) => props.randomColor,
    [theme.breakpoints.up("md")]: {
      alignContent: "center",
    },
  },
  containerContent: {
    flexWrap: "nowrap",
    gap: "15px",
    [theme.breakpoints.up("sm")]: {
      // gap: "15x",
    },
    [theme.breakpoints.up("md")]: {
      gap: "15px",
      maxHeight: "1024px",
      "@media(min-height:1024px)": {
        gap: "45px",
      },
    },

    [theme.breakpoints.up("xl")]: {
      gap: "30px",
      alignContent: "center",
      maxHeight: "1440px",
      "@media(min-height:1024px)": {
        gap: "45px",
      },
    },
  },
  ContainerLogin: {
    padding: "0 25px",
    // height: "975px",
  },
  ContainerLogoClLeft: {
    display: "flex",
    alignItems: "center",
    // marginBottom: "10%",
    justifyContent: "center",
    [theme.breakpoints.up("xl")]: {
      // marginBottom: "20%",
    },
  },

  LogoClLeft: {
    width: "60%",
    maxWidth: "483px",
    marginTop: "10%",
    [theme.breakpoints.up("md")]: {
      marginTop: "0",
    },
    [theme.breakpoints.up("lg")]: {
      width: "75%",
    },
    [theme.breakpoints.up("xl")]: {
      width: "100%",
    },
  },
  logoP: {
    width: "78px",
    height: "73.5px",
    marginTop: "10%",
    [theme.breakpoints.up("md")]: {
      width: "104px",
      height: "98px",
    },
    [theme.breakpoints.up("xl")]: {
      marginTop: "0%",
    },
  },
  studentName: {
    font: "28px Fredoka One ",
    margin: "0",
    textAlign: "center",
    color: (props) => props.textColor,
    [theme.breakpoints.up("xl")]: {
      fontSize: "50px",
    },
  },
  welcomeText: {
    font: " bold 18px Lato",
    textAlign: "center",
    width: "100%",
    maxWidth: "448px",
    color: (props) => props.smallText,
    [theme.breakpoints.up("xl")]: {
      fontSize: "24px",
    },
  },
  inputLoginPassword: {
    width: "80%",
    maxWidth: "280px",
    height: "50px",
    paddingLeft: "27px",
    borderRadius: "61px",
    border: "solid #F3CD52 3px ",
    background: "#FFFFFF",
    font: "Helvetica",
    fontSize: "16px",
    "&:focus": {
      outline: "none",
    },
    [theme.breakpoints.up("xl")]: {
      height: "81px",
      maxWidth: "421px",
      fontSize: "18px",
    },
  },
  forgottenPassword: {
    color: (props) => props.smallText,
    font: "16px Lato",
    letterSpacing: "0.36px",
    [theme.breakpoints.up("xl")]: {
      fontSize: "20px",
    },
  },
  inputLoginButton: {
    width: "100%",
    maxWidth: "320px",
    height: "50px",
    borderRadius: "61px",
    border: "none",
    backgroundColor: (props) => props.loginBottonColor,
    color: "#353535",
    font: "bold 16px Lato",
    letterSpacing: "1.96px",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#6FDBED",
      fontWeight: "bold",
    },
    [theme.breakpoints.up("xl")]: {
      height: "81px",
      fontSize: "22px",

      maxWidth: "421px",
    },
  },
  userChangeButton: {
    width: "100%",
    height: "50px",
    borderRadius: "61px",
    border: "solid #1D2AAD 3px ",
    background: "#FFFFFF",
    color: "#353535",
    font: " bold 16px Lato",
    letterSpacing: "1.96px",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#EFEFEF",
      fontWeight: "bold",
    },
    [theme.breakpoints.up("xl")]: {
      height: "81px",
      fontSize: "22px",
    },
  },

  rightsReserved: {
    font: "14px Lato",
    letterSpacing: "0.53px",
    maxWidth: "80%",
    color: (props) => props.smallText,
    [theme.breakpoints.up("xl")]: {
      fontSize: "18px",
    },
  },
  Link: {
    textDecoration: "none",
    width: "100%",
    maxWidth: "320px",
    [theme.breakpoints.up("xl")]: {
      maxWidth: "451px",
    },
  },
  logoEscuela: {
    width: "104px",
    height: "104px",
    borderRadius: "50%",
  },
  dialogForgottenPassword: {
    borderRadius: "61px",
    height: "80%",
    maxWidth: "80%",
    // backdropFilter: "blur(3px)",
  },
}));

const SinginAdmin = (props) => {
  const { dispatch } = useContext(AuthContext);
  const {
    user: { userName, name, firstLastName },
  } = useContext(AuthContext);

  const classes = useStyles({
    randomColor,
    textColor,
    loginBottonColor,
    smallText,
  });
  const [password, setPassword] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [error, setError] = React.useState("");

  const [disabled, setDisabled] = React.useState(false);
  const { handleLoading } = useLoading();

  const handleClose = () => {
    setOpen(false);
  };

  const handlePassword = (event) => {
    setPassword(event.target.value);
  };

  const singInClick = () => {
    setDisabled(true);
    handleLoading(true);
    const body = {
      nombreUsuario: userName || oldSesionData?.userName,
      contraseña: password,
    };
    axios
      .post("/session/login", body)
      .then((response) => {
        if (response.status === 200) {
          localStorage.setItem("token", response.data.resultado.token_usuario);
          const data = response.data.resultado;
          dispatch({
            type: types.login,
            payload: adminInstData(data),
          });
        }
      })
      .catch((error) => {
        setOpen(true);
        setError(error.response?.data?.detalles?.msg);
        setDisabled(false);
      })
      .finally(() => {
        handleLoading(false);
      });
  };

  const userChangeClick = () => {
    localStorage.removeItem("oldSesion");
  };
  const [oldSesionData, setoldSesionData] = React.useState({});

  React.useEffect(() => {
    const oldSesion = JSON.parse(localStorage.getItem("oldSesion"));
    setoldSesionData(oldSesion);
  }, []);

  return (
    <Grid container className={classes.root}>
      <Grid container item className={classes.ContainerLogin}>
        <Grid
          container
          item
          sm={6}
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item className={classes.ContainerLogoClLeft}>
            <img src={logoDemo} alt="logoDemo" className={classes.LogoClLeft} />
          </Grid>
        </Grid>
        <Grid
          container
          item
          sm={6}
          direction="column"
          justifyContent="space-around"
          alignItems="center"
          className={classes.containerContent}
        >
          <img className={classes.logoP} src={logoPea} alt="logoP" />
          <h1 className={classes.studentName}>{`¡Hola, ${
            name || oldSesionData?.name
          } ${firstLastName || oldSesionData?.firstLastName}!`}</h1>
          <div className={classes.welcomeText}>
            Es un gusto verte de nuevo.
            <br />
            Ingresa tu contraseña para iniciar sesión
          </div>
          <input
            type="password"
            placeholder="Contraseña"
            value={password}
            onChange={handlePassword}
            className={classes.inputLoginPassword}
          ></input>

          <Link className={classes.forgottenPassword} to="/password_recovery">
            Olvidé mi contraseña
          </Link>

          <Button
            onClick={singInClick}
            onDoubleClick={singInClick}
            disabled={disabled}
            className={classes.inputLoginButton}
          >
            Iniciar sesión
          </Button>

          <Link to="/" className={classes.Link}>
            <Button
              onClick={userChangeClick}
              className={classes.userChangeButton}
            >
              Cambiar de usuario
            </Button>
          </Link>
          {oldSesionData?.schoolLogo && (
            <img
              src={oldSesionData?.schoolLogo || logoEscuela}
              alt="Logo de la Escuela"
              className={classes.logoEscuela}
            />
          )}

          <p className={classes.rightsReserved}>
            Derechos reservados y legales {dynamicYear} por Pearson
          </p>
        </Grid>
      </Grid>

      <ModalLogin open={open} error={error} handleClose={handleClose} />
      {!oldSesionData && <Redirect to={{ pathname: `/` }} />}
      {oldSesionData?.rol === "docente" && (
        <Redirect to={{ pathname: `/singin/docente` }} />
      )}
      {oldSesionData?.rol === "administrador pearson" && (
        <Redirect to={{ pathname: `/singin/admin` }} />
      )}
      {oldSesionData?.rol === "alumno" && (
        <Redirect to={{ pathname: `/singin/${oldSesionData?.grade}` }} />
      )}
      {localStorage.token && (
        <Redirect to={{ pathname: `/admin-inst/perfil` }} />
      )}
    </Grid>
  );
};

export default SinginAdmin;
