import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
    ContenedorLectura:{
        width:'502px',
                  [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
    },
    ContainerTitulo:{
        margin:'0 0 34px 0',
        '& .Titulo':{
            letterSpacing: '0px',
            color: '#4196F8',
            margin:'0',
            textAlign:'center',
            font:'43px fredoka one',
            lineHeight:'52px',
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            margin:'0 0 36px 0',
            color:'#000',
            font:'18px lato',
            lineHeight :'28px',
            letterSpacing: '0.17px',
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .Fondo':{
            position:'relative'
        },
        '& .Esp':{
            margin:'0',
        },

        '& .Esp3':{
            position:'absolute',
            boxSizing:'border-box',
            padding:'0 24% 0 0',
            margin:'0',
        },
        '& .Imagen':{
            width:'100%',
        }, 
        '& .ImgEsp2':{
          [theme.breakpoints.down(502)]:{
              margin:'50px 0 0 0',
          },
      },  
    }
}));


const Puentes = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>

            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >Puentes</h2>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container sm={12}>
                      <p className="Lectura Esp"> 
                        Minueto se cree pájaro. Da vueltas en el patio y aletea, porque, para él, es como volar 
                        entre nubes. A Minueto no le gustan los abrazos ni los besos. Se parece a mi papá.
                      </p>
                    </Grid>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container xs={12}>
                      <p className="Lectura"> 
                        Papá me acaricia la cabeza para decirme 
                        “Te quiero”. A Minueto nunca. Mi 
                        hermanito tampoco sabe decir “Te 
                        quiero” ni reírse, pero un día vi que una 
                        catarina se paró en su brazo y él se puso 
                        a girar como remolino. Descubrí que ésa 
                        era su manera de reír.
                      </p>
                    </Grid>
                    <Grid container item  xs={12} >
                        <img className="Imagen " src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img4to/Sesion06/1R.png" alt="Tarjeta" />

                    </Grid>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container xs={12}>
                      <p className="Lectura Esp"> 
                        En cambio, papá nunca ríe. Normalmente se despierta muy temprano y regresa hasta 
                        la noche. Al llegar del trabajo, suele preguntarme si ya hice la tarea. A Minueto no le 
                        habla. Tal vez sabe que a mi hermano no le gusta contar lo que ve.<br/><br/> 
                      
                        A veces no es fácil estar contenta con Minueto. Como la tarde que fuimos al parque y 
                        me caí de la bicicleta. Mi rodilla sangraba y lloré.<br/><br/> 
                      
                        —¡Ve por mamá, Minueto! —le dije, pero no me oía a pesar de estar a su lado. <br/><br/> 
                        
                        Decidí irme a casa, cojeando. Minueto no quería partir. Lo jalé y comenzó a gritar 
                        como si quisiera raptarlo. Todos en el parque voltearon a verme. Me dio mucha 
                        vergüenza y me fui sin él. <br/><br/> 
                        
                        Cuando llegué a casa lo primero que me preguntó mamá fue: <br/><br/> 
                        
                        —¿Y Minueto? <br/><br/> 
                        
                        —Se quedó en el parque —respondí molesta, y ella salió corriendo a buscarlo. A nadie 
                        le importó mi caída. <br/><br/> 
                        
                        Dejé de hablarle a Minueto. Estaba molesta con él como lo está papá porque es 
                        diferente, pero al final hice las paces. Entiendo que él vive entre las nubes y desde su 
                        vuelo no vio ni escuchó mi caída. Mamá curó mi rodilla y papá observó mi raspón 
                        como si no lo comprendiera. Él no lo sabe, pero a veces también mira como Minueto.
                      </p>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container xs={12} >
                      <p className="Lectura "> 
                        Un día, papá llegó contento del 
                        trabajo. Sonaba feliz al hablar de 
                        máquinas que suben y bajan a la 
                        tierra. Supongo que para él son 
                        como las catarinas de Minueto. 
                        Dijo que construirá un puente 
                        grande, que atravesará la avenida y 
                        ya no recuerdo más porque me 
                        aburrí, pero Minueto estaba muy 
                        derechito, escuchándolo.<br/><br/> 
                        
                        A Minueto le gusta dibujar. Dibuja 
                        en las paredes, puertas y ventanas. 
                        Dice que cada ventana es el sol y 
                        las puertas son la noche.<br/><br/> 
                        
                        —¿Y las estrellas? —pregunté.<br/><br/> 
                        
                        —Son llaves —contestó sin dudar.
                      </p>
                    </Grid>
                    <Grid container item justifyContent='flex-end' xs={12} >
                        <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img4to/Sesion06/2R.png" alt="Tarjeta" />
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container xs={12} >
                      <p className="Lectura Esp"> 
                        Supongo que Minueto es tan callado porque se la pasa imaginando cosas fantásticas. 
                        Pero a papá le disgusta tener una casa llena de soles y oscuridad en las paredes, así 
                        que a cada rato las pinta de nuevo. Cuando las paredes quedan lisas y blancas, 
                        Minueto se angustia y vuelve a dibujar puertas y ventanas en cuanto puede.
                      </p>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container xs={12} className="Fondo">
                      <p className="Lectura Esp3"> 
                        Una noche, Minueto dibujó un puente en la pared. Era un dibujo 
                        distinto a las puertas y ventanas que hacía. Se podían ver 
                        muchos detalles. Cuando papá llegó, se quedó mirando ese 
                        puente en vez de decir “Es necesario pintar”.
                      </p>
                      <img className="Imagen ImgEsp2" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img4to/Sesion06/3.png" alt="Tarjeta" />

                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container xs={12} >
                      <p className="Lectura "> 
                        Al otro día llevó a Minueto a su trabajo, para sorpresa de mamá y mía. Desde 
                        entonces, Minueto y papá tienen una misión secreta y se entienden sin hablar. Papá 
                        ya sacude el pelo de Minueto. “Lo quiere”, pienso.<br/><br/>     
                        
                        Minueto traza el puente de papá en todas las paredes y él ya no pide borrarlos, más 
                        bien viaja en ellos. Yo intento viajar también, tal vez en una de ésas pueda entrar al
                        mundo de papá, como lo hizo Minueto, y sonreír a su manera.
                      </p>
                    </Grid>
                </Grid>
        </Grid>
    </Grid>
    );
}
 
export default Puentes;
