export const studentData = (data) => {
  return {
    logged: true,
    acceptedConditions: data?.usuario?.terminos_condiciones,
    userId: data.usuario.id_usuario,
    userName: data.usuario.nombreUsuario,
    name: data.usuario.nombre,
    firstLastName: data.usuario.apellidoPaterno,
    secondLastName: data.usuario.apellidoMaterno,
    year: data.escuela.año,
    cicle: data.escuela.ciclo,
    city: data.escuela.ciudad,
    grade: data.escuela.grado,
    group: data.escuela.grupo,
    country: data.escuela.pais,
    rol: data.usuario.rol,
    school: data.escuela.escuela,
    schoolLogo: data.escuela.logo,
    insignias: data.insignias || [],
    totalActividadesCompletas: data.actividadesCompletas?.actividades,
    avatar: data.avatar || "",
  };
};
export const studentDataRegisterByCode = (data) => {
  return {
    logged: true,
    acceptedConditions: data?.usuario?.terminos_condiciones,
    userId: data.usuario.id_usuario,
    userName: data.usuario.nombreUsuario,
    name: data.usuario.nombre,
    firstLastName: data.usuario.apellidoPaterno,
    secondLastName: data.usuario.apellidoMaterno,
    rol: data.usuario.rol,
    insignias: data.insignias || [],
    totalActividadesCompletas: data.actividadesCompletas?.actividades,
    avatar: data.avatar || "",
    codigo: data?.codigo,
  };
};
export const completeRegisterByCode = (data) => {
  return {
    logged: true,
    acceptedConditions: true,
    userId: data.usuario.id_usuario,
    userName: data.usuario.nombreUsuario,
    name: data.usuario.nombre,
    firstLastName: data.usuario.apellidoPaterno,
    secondLastName: data.usuario.apellidoMaterno,
    year: data.escuela.año,
    cicle: data.escuela.ciclo,
    city: data.escuela.ciudad,
    grade: data.escuela.grado,
    group: data.escuela.grupo,
    country: data.escuela.pais,
    rol: data.usuario.rol,
    school: data.escuela.escuela,
    schoolLogo: data.escuela.logo,
    insignias: data.insignias || [],
    totalActividadesCompletas: data.actividadesCompletas?.actividades,
    avatar: data.avatar || "",
  };
};
export const studentDataNative = (data) => {
  return {
    logged: true,
    acceptedConditions: data?.usuario?.terminos_condiciones,
    userId: data.usuario.id_usuario,
    userName: data.usuario.nombreUsuario,
    name: data.usuario.nombre,
    firstLastName: data.usuario.apellidoPaterno,
    secondLastName: data.usuario.apellidoMaterno,
    rol: data.usuario.rol,
    year: data.escuela.año,
    cicle: data.escuela.ciclo,
    city: data.escuela.ciudad,
    grade: data.escuela.grado,
    group: data.escuela.grupo,
    country: data.escuela.pais,
    school: data.escuela.escuela,
    id_escuela: data.escuela.id_disttribucion_escuela,
    id_inscripcion: data.escuela.id_inscripcion,
    avatar: data.avatar,
    insignias: data.insignias,
    totalActividadesCompletas: data.actividadesCompletas?.actividades,
  };
};

export const teacherData = (data) => {
  return {
    logged: true,
    acceptedConditions: data?.usuario?.terminos_condiciones,
    userId: data.usuario.id_usuario,
    userName: data.usuario.nombreUsuario,
    name: data.usuario.nombre,
    firstLastName: data.usuario.apellidoPaterno,
    secondLastName: data.usuario.apellidoMaterno,
    rol: data.usuario.rol,
    grupos: data.grupos,
    country: data?.grupos[0]?.pais,
    school: data?.grupos[0]?.escuela,
    avatar: {
      path: data.usuario.imagen_perfil,
    },
  };
};

export const adminInstData = (data) => {
  return {
    logged: true,
    acceptedConditions: data?.usuario?.terminos_condiciones,
    userId: data.usuario.id_usuario,
    userName: data.usuario.nombreUsuario,
    name: data.usuario.nombre,
    firstLastName: data.usuario.apellidoPaterno,
    secondLastName: data.usuario.apellidoMaterno,
    rol: data.usuario.rol,
    escuela: {
      adminInfo: data.adminInfo[0],
      escuela: data.escuela?.escuela,
      pais: data.escuela?.pais,
      ciudad: data.escuela?.ciudad,
      direccion: data.escuela?.direccion,
      avatar: data.escuela?.path_img_logo,
      id_escuela: data.escuela?.id_escuela,
      tipo: data.escuela?.tipo,
    },
  };
};

export const adminPearsonData = (data) => {
  return {
    logged: true,
    acceptedConditions: data?.usuario?.terminos_condiciones,
    userId: data.usuario.id_usuario,
    userName: data.usuario.nombreUsuario,
    name: data.usuario.nombre,
    firstLastName: data.usuario.apellidoPaterno,
    secondLastName: data.usuario.apellidoMaterno,
    rol: data.usuario.rol,
    correo: data.usuario.correo,
    avatar: {
      path: data.usuario.imagen_perfil,
    },
  };
};
