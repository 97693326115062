import { Button, Grid, makeStyles } from "@material-ui/core";
import React, { useContext, useEffect } from "react";
import DrawerContext from "../../../../../usability/DrawerContext";
import { useOnLoadImages } from "../../../../hooks/useOnLoadImages";
import Loader from "../../../../Loader/Loader";
import Drawer from "../../ReadingTools/Drawer";

const useStyles =  makeStyles (theme =>({
      Contenedor:{
        background:'#E9FBF9',
        width:'100vw',
        overflowX:'clip',
        [theme.breakpoints.down(1161)]:{
            background:'none',
           overflowX:'unset',
        },
        [theme.breakpoints.down(600)]:{
            marginTop: '79.51px',
        }
    },
    ContainerInfoLectura: {
    position: "relative",
    height: "86px",
    background: "#F6664D",
    [theme.breakpoints.down(502)]:{
        height: "43px",
    },
    "& .BarraInfoLectura": {
      height: "86px",
      background: `Url(https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Barras/Naranja.png)`,
      [theme.breakpoints.down(502)]:{
        height: "43px",
    },
    },
    "& .InfoLectura": {
      position: "absolute",
      height: "86px",
      width: "219px",
      boxSizing: "border-box",
      padding: "17.2px 0",
      background: "#FAFAFA",
      borderRadius: "50px",
      boxShadow: "2px 2px 2px 2px #00000029",
      zIndex: "4",
      [theme.breakpoints.down(502)]:{
        height: "43px",
        width: "109px",
        padding: "8.6px 0",
        borderRadius: "25px",
    },
    },
    "& .InfoLectura:before": {
      position: "absolute",
      display: "flex",
      boxSizing: "border-box",
      padding: "0 0 0 10px",
      justifyContent: "center",
      alignItems: "center",
      height: "39.88px",
      width: "39.88px",
      borderRadius: "50%",
      content: '""',
      boxShadow: "3px 0px .5px 0px #00000029",
      background: "#FAFAFA",
      left: "90px",
      top: "65px",
      transform: "rotate(0.25turn)",
      [theme.breakpoints.down(502)]:{
        left: "45px",
        top: "30px",
        height: "20.88px",
        width: "20.88px",
        },
    },
    "& .InfoLectura:after": {
      content: '""',
      height: "15px",
      width: "15px",
      borderTop: "2px solid #F6664D",
      borderLeft: "2px solid #F6664D",
      position: "absolute",
      bottom: "-10px",
      left: "Calc(50% - 8px)",
      transform: "rotate(0.625turn)",
      [theme.breakpoints.down(502)]:{
        height: "10px",
        width: "10px",
        left: "Calc(50% - 4.5px)",
        bottom: "-3px",
        borderTop: "1px solid #20A698",
        borderLeft: "1px solid #20A698",
        },
    },
    "& .Sesion": {
      borderLeft: "2px solid #D8D8D8",
    },
    "& .Dato": {
      margin: "0 0 5px 0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#F6664D",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        margin: "0 0 2.5px 0",
        },
    },
    "& .Caracter": {
      margin: "0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#000000",
    },
       '& .Regresar':{
        color: '#F2624F',
        border: '#F2624F 1px solid',
        height: '30px',
        width:'100px',
        borderRadius: '20px',
        letterSpacing: '0.14px',
        textTransform: 'none',
        background:'#FEFAF1',
        top:'calc(50% - 15px)',
        left:'5%',
        zIndex:4,
        [theme.breakpoints.down(502)]: {
          font:'bold 10px lato',
          width: "60px",
        },
      },
  },
    ContenedorLectura:{
        background:'#fff',
        width:'1160px',
        height:'100%',
                padding:'60px 168px 35px ',
        
        transform: (props) =>
        props.scale > 1 ? `scale(${props.scale}) translate(0px, 10%)` : `none`,
        [theme.breakpoints.down(1161)]:{
            width:'502px',
            padding:'60px 0 0 0',
          },
        [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },

        '& .Escritorio':{
            display:'flex',
            [theme.breakpoints.down(1161)]:{
                display:'none'
            },
        },
        '& .Tablet':{
            display:'none',
            [theme.breakpoints.down(1161)]:{
                display:'flex'
            },
        }
    },
    ContainerTitulo:{
        margin:'0 0 34px 0',
        [theme.breakpoints.down(1161)]:{
            margin:'0 0 34px 0',
        },
        '& .Titulo':{
            font:'48px fredoka one',
            letterSpacing: '0px',
            lineHeight:'58px',
            color: '#57AD00',
            margin:'0',
            textAlign:'center',
            [theme.breakpoints.down(1161)]:{
                font:'43px fredoka one',
                lineHeight:'52px',
            },
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            lineHeight : '30px',
            margin:'0 0 36px 0',
            font:'22px lato',
            color:'#000',
            letterSpacing: '0.21px',
            [theme.breakpoints.down(1161)]:{
                textAlign:'center',
                font:'18px lato',
                lineHeight :'28px',
                letterSpacing: '0.17px',
            },
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .Pos':{
            position:'relative',
            right:'50px',
        },
        '& .Lectura2':{
            margin:'0',
        },
        '& .Imagen':{
            [theme.breakpoints.down(1161)]:{
                width:'100%',
            },
        },  
      
    },

}));

const El_sueño_de_las_montañas = (props) => {

  const {activeStep, setActiveStep, handlePreviewStep, id_actividad} = props
  
  const handleContinue = () => {
    setActiveStep(activeStep - 1)
    handlePreviewStep()
  }

    const {
    handleSize,
    disabledZoom,
    closeZoom,
    handleZoom,
    scale,
    
    
    
    

  } = useContext(DrawerContext);
  const [loading, setLoading] = React.useState(true);
  const wrapperRef = React.useRef(null);
  const imagesLoaded = useOnLoadImages(wrapperRef);

  const detectSize = () => {
    const lectura = document.getElementById("lectura");
    const newWidth = lectura?.offsetWidth;
    const newHeight = lectura?.offsetHeight;
    setLoading(false);
    if (lectura) handleSize(newHeight, newWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", detectSize);
    return () => {
      handleZoom()
      window.removeEventListener("resize", detectSize);
      closeZoom()
    };
  }, []);

  const classes = useStyles({ disabledZoom,scale });
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
             <Grid container item justifyContent="center" className={classes.ContainerInfoLectura}>
                       <Grid container className="BarraInfoLectura">
            <Button className="Regresar" onClick={handleContinue}>
                Regresar
            </Button>
        </Grid>
               <Grid container item className="InfoLectura">
                    <Grid container item direction="column" xs={6} justifyContent="center">
                        <p className="Dato">Grado</p>
                        <p className="Caracter">5</p>
                    </Grid>
                    <Grid container item direction="column" xs={6} justifyContent="center" className="Sesion">
                        <p className="Dato">Sesión</p>
                        <p className="Caracter">21R</p>
                    </Grid>
               </Grid>
            </Grid>
            {!imagesLoaded && <Loader />}
        <Grid
            container
            item
            className={classes.ContenedorLectura}
            justifyContent="center"
            id="lectura"
            onLoad={detectSize}
            ref={wrapperRef}
        >
<Drawer id_lectura={id_actividad} />
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >
                        El sueño de las montañas
                    </h2>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={6} className='Escritorio'>
                        <p className="Lectura Lectura2">
                            Soñé con nuestro continente: <br/>
                            era un lagarto hermoso. <br/>
                            Y aunque estaba en reposo <br/>
                            se veía imponente. <br/><br/>
                            
                            Desde Alaska hasta la Tierra de Fuego, <br/>
                            su cuerpo descansaba <br/>
                            en la Historia. Y llegaba <br/>
                            hasta nuestros días su sosiego. <br/><br/>
                            
                            Por entero las escamas <br/>
                            cubrían su piel <br/>
                            y eran un espejo fiel <br/>
                            de todas nuestras montañas. <br/><br/>
                            
                            Escamas las cordilleras <br/>
                            marcaban su anatomía <br/>
                            dando a su fisonomía <br/>
                            montes, sueños y banderas.
                        </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={6} className='Escritorio'>
                        <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion21R/1.png" alt="Tarjeta" />
                    </Grid>

                    <Grid container sm={12} className='Tablet' justifyContent='center'>
                        <p className="Lectura Lectura2">
                            Soñé con nuestro continente: <br/>
                            era un lagarto hermoso. <br/>
                            Y aunque estaba en reposo <br/>
                            se veía imponente. <br/><br/>
                            
                            Desde Alaska hasta la Tierra de Fuego, <br/>
                            su cuerpo descansaba <br/>
                            en la Historia. Y llegaba <br/>
                            hasta nuestros días su sosiego. <br/><br/>
                            
                            Por entero las escamas <br/>
                            cubrían su piel <br/>
                            y eran un espejo fiel <br/>
                            de todas nuestras montañas. <br/><br/>
                            
                            Escamas las cordilleras <br/>
                            marcaban su anatomía <br/>
                            dando a su fisonomía <br/>
                            montes, sueños y banderas.
                        </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12} className='Tablet'>
                        <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion21R/1.png" alt="Tarjeta" />
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    
                    <Grid container justifyContent="center" sm={6} className='Escritorio'>
                        <img className="Imagen Pos" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion21R/2R.png" alt="Tarjeta" />
                    </Grid>
                    <Grid container sm={6} className='Escritorio'>
                        <p className="Lectura Lectura2">
                            Los Andes varios países atraviesan. <br/>
                            Argentina, Perú, Bolivia y Chile <br/>
                            celebran un gran desfile <br/>
                            y con orgullo su hermandad <br/>
                            confiesan. <br/><br/>
                            
                            Desde el Aconcagua argentino <br/>
                            acaricié una estrella <br/>
                            donde dejaron su huella <br/>
                            la altura y lo divino. <br/><br/>
                            
                            Mi viaje terminó allí, en sus volcanes, <br/>
                            hornos subterráneos, <br/>
                            donde espontáneos <br/>
                            crecen nuestros talismanes. <br/><br/>

                            Cuando desperté, radiante <br/>
                            me vi, celeste viento, <br/>
                            cóndor en mi pensamiento: <br/>
                            testigo del paraíso un instante.
                        </p>
                    </Grid>

                    
                    <Grid container sm={12} className='Tablet' justifyContent='center'>
                        <p className="Lectura Lectura2">
                            Los Andes varios países atraviesan. <br/>
                            Argentina, Perú, Bolivia y Chile <br/>
                            celebran un gran desfile <br/>
                            y con orgullo su hermandad <br/>
                            confiesan. <br/><br/>
                            
                            Desde el Aconcagua argentino <br/>
                            acaricié una estrella <br/>
                            donde dejaron su huella <br/>
                            la altura y lo divino. <br/><br/>
                            
                            Mi viaje terminó allí, en sus volcanes, <br/>
                            hornos subterráneos, <br/>
                            donde espontáneos <br/>
                            crecen nuestros talismanes. <br/><br/>

                            Cuando desperté, radiante <br/>
                            me vi, celeste viento, <br/>
                            cóndor en mi pensamiento: <br/>
                            testigo del paraíso un instante.
                        </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12} className='Tablet'>
                        <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion21R/2.png" alt="Tarjeta" />
                    </Grid>
                </Grid>
        </Grid>
        <Grid container item style={{ marginTop: 80}} ></Grid>
    </Grid>
    );
}
 
export default El_sueño_de_las_montañas;
