import React from "react";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";

import IconButton from "@mui/material/IconButton";

import { GridCloseIcon } from "@mui/x-data-grid";
import { makeStyles } from "@material-ui/core";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-root": {
    backdropFilter: "blur(10px)",
    height: "100%",
  },
  "& .MuiDialogContent-root": {
    // padding: theme.spacing(2),
    padding: "0",
    position: "relative",
    height: "100%",
    // [theme.breakpoints.down("sm")]: {
    //   "@media (orientation: landscape)": {
    //     position: "relative",
    //     display: "flex",
    //     flexDirection: "column",
    //     alignContent: "center",
    //     alignItems: "center",
    //   },
    // },
    [theme.breakpoints.up("sm")]: {
      // margin: "20px 0",
      paddingTop: "0",
      paddingBottom: "0",
    },
    [theme.breakpoints.up("sm")]: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      "@media(max-height:400px)": {
        justifyContent: "flex-start",
      },
    },
    [theme.breakpoints.up("xl")]: {
      justifyContent: "flex-start",
    },
  },
  "& .MuiDialog-paper": {
    borderRadius: "12.1px",
    backgroundColor: "transparent",
    boxShadow: "none",
    height: "100%",
    margin: "10px",
    maxHeight: "max-content",
    [theme.breakpoints.up("sm")]: {
      minHeight: "70%",
      "@media (min-height: 500px)": {
        height: "auto",
      },
    },
    [theme.breakpoints.up("lg")]: {
      margin: "32px",
    },
  },
  "& .MuiDialog-container": {
    backdropFilter: "blur(10px)",
    alignItems: "flex-start",
    [theme.breakpoints.up("sm")]: {
      alignItems: "center",
    },
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
    display: "flex",
    justifyContent: "center",
    gap: "5%",
  },
  "& .MuiDialogTitle-root": {
    [theme.breakpoints.up("sm")]: {
      // margin: "20px 0",
      padding: "22px",
    },
  },
}));

const useStyles = makeStyles((theme) => ({
  containerContent: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    position: "relative",
    [theme.breakpoints.up("sm")]: {
      // margin: "20px 0",
    },
  },

  img: {
    width: "auto",
    height: "auto",
    maxWidth: "75vw",
    maxHeight: "80vh",
    objectFit:"cover",
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 3 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <GridCloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function ImageModal({ open, handleClose, path }) {
  // const [animationType, setAnimationType] = React.useState("saludando");

  const classes = useStyles({});

  return (
    <>
      <BootstrapDialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} maxWidth="xl">
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}></BootstrapDialogTitle>
        <DialogContent>
          <div className={classes.containerContent}>
            <img src={path} alt={""} className={classes.img} />
          </div>

        </DialogContent>
      </BootstrapDialog>
    </>
  );
}
