import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
    ContenedorLectura:{
        width:'628px',
                  [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
        '& .Cel':{
            display:'none',
            [theme.breakpoints.down(1161)]:{
                display:'none'
            },
            [theme.breakpoints.down(502)]:{
                display:'block'
            },
        }
    },
    ContainerTitulo:{
        margin:'0 0 18px 0',
        '& .Titulo':{
            color: '#FF0000',
            margin:'0',
            textAlign:'center',
            font:'43px fredoka one',
            lineHeight:'52px',
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        boxSizing:'border-box',
        padding:'0 62px 0 62px',
        [theme.breakpoints.down(502)]:{
            padding:'0 32px 0 32px',
        },
        '& .Lectura':{
            margin:'0 0 36px 0',
            color:'#000',
            font:'18px lato',
            lineHeight :'28px',
            letterSpacing: '0.17px',
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .SubTitulo':{
            color:'#000000',
            margin:'0',
            textAlign:'center',
            width:'100%',
            padding:'38px 0 22px 0',
            borderBottom:'solid 7px #FF0000',
            font:'bold 24px lato',
            lineHeight :'28px',
            letterSpacing: '0.24px',
            [theme.breakpoints.down(502)]:{
                borderBottom:'solid 5px #FF0000',
                padding:'19px 0 12px 0',
                font:'bold 22px lato',
                lineHeight :'24px',
                letterSpacing: '0.21px',
            },
        },
        '& .TextImg':{
            color:'#717476',
            margin:'24px 0 0 0',
            font:' 16px lato',
            lineHeight :'25px',
            letterSpacing: '0.15px',
            [theme.breakpoints.down(502)]:{
                font:' 11px lato',
                lineHeight :'14px',
                letterSpacing: '0.10px',
            },
        },
        '& .Imagen':{
            width:'100%',
        },  
      
    },
    Fondo:{
        background:`Url(https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img1ro/Sesion40/1.png)`,
        margin:'0 0 36px 0',
    }
}));

const La_importancia_de_las_normas = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo " >
                        La importancia <br/>de las normas
                    </h2>
                </Grid>
                <Grid className={classes.Fondo}>
                    <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                        <Grid container sm={12} justifyContent='center' className='SubTitulo'>
                            <em > Se instalarán más semáforos para <br/>ayudar a cumplir las normas viales</em>
                            <em className='Cel'> Se instalarán más semáforos para ayudar a cumplir las normas viales</em>
                        </Grid>
                    </Grid>
                    <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                        <Grid container sm={12} justifyContent='center' >
                            <p className='Lectura'>
                                <b>1º de agosto.</b> El Ayuntamiento de la ciudad informó que en los próximos días se 
                                instalarán nuevos semáforos para ayudar al cumplimiento de las normas viales.<br/><br/> 
                                
                                Los responsables del Ayuntamiento han señalado la importancia de las normas 
                                cuando vivimos en sociedad: “Si no existieran los semáforos y cada persona, 
                                conductor o peatón hiciera lo que quisiera, la ciudad sería un caos, habría muchos 
                                accidentes y muchas personas resultarían lastimadas. Por eso es necesario aplicar 
                                algunas normas que todos nos comprometamos a cumplir y que ayuden a garantizar 
                                la seguridad y la armonía”, comunicó el responsable de cultura vial en una 
                                conferencia.<br/><br/>  
                                
                                Para asegurarse de que la niñez también cumpla con las normas viales, como no 
                                cruzar nunca si el semáforo no está en verde, se distribuirán folletos en las escuelas a 
                                fin de que los niños conozcan las normas más importantes. Adicionalmente, se 
                                organizarán talleres para reforzar las reglas viales. Éstos iniciarán el próximo mes en 
                                todas las escuelas primarias.<br/><br/> 
                                
                                A manera de conclusión, el responsable de cultura vial agregó: “Si todos seguimos las 
                                normas, todos nos veremos beneficiados y la vida será más pacífica y armónica. Las 
                                normas existen para ayudarnos a convivir y es responsabilidad de todos cumplirlas”.
                            </p>
                        </Grid>
                        <Grid container justifyContent="center" sm={12}>
                            <Grid container justifyContent="center" >
                                <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img1ro/Sesion40/2.png" alt="Tarjeta" />
                            </Grid>
                            <Grid container justifyContent="center" >
                                <p className="TextImg">
                                Los semáforos nos ayudan a cumplir las normas.
                                </p>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
        </Grid>
    </Grid>
    );
}
 
export default La_importancia_de_las_normas ;
