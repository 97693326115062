import { Button, Grid, makeStyles } from "@material-ui/core";
import React, { useContext, useEffect } from "react";
import DrawerContext from "../../../../../usability/DrawerContext";
import { useOnLoadImages } from "../../../../hooks/useOnLoadImages";
import Loader from "../../../../Loader/Loader";
import Drawer from "../../ReadingTools/Drawer";

const useStyles =  makeStyles (theme =>({
      Contenedor:{
        background:'#E9FBF9',
        width:'100vw',
        overflowX:'clip',
        [theme.breakpoints.down(1161)]:{
            background:'none',
           overflowX:'unset',
        },
        [theme.breakpoints.down(600)]:{
            marginTop: '79.51px',
        }
    },
  ContainerInfoLectura: {
    position: "relative",
    height: "86px",
    [theme.breakpoints.down(502)]:{
        height: "43px",
    },
    background: "#76D4C3",
    "& .BarraInfoLectura": {
      height: "86px",
      background: `Url(https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Barras/Azul.png)`,
        [theme.breakpoints.down(502)]:{
            height: "43px",
        },
    },
    "& .InfoLectura": {
      position: "absolute",
      height: "86px",
      width: "219px",
      boxSizing: "border-box",
      padding: "17.2px 0",
      background: "#FAFAFA",
      borderRadius: "50px",
      boxShadow: "2px 2px 2px 2px #00000029",
      zIndex: "4",
        [theme.breakpoints.down(502)]:{
            height: "43px",
            width: "109px",
            padding: "8.6px 0",
            borderRadius: "25px",
        },
    },
    "& .InfoLectura:before": {
      position: "absolute",
      display: "flex",
      boxSizing: "border-box",
      padding: "0 0 0 10px",
      justifyContent: "center",
      alignItems: "center",
      height: "39.88px",
      width: "39.88px",
      borderRadius: "50%",
      content: '""',
      boxShadow: "3px 0px .5px 0px #00000029",
      background: "#FAFAFA",
      left: "90px",
      top: "65px",
      transform: "rotate(0.25turn)",
      [theme.breakpoints.down(502)]:{
        left: "45px",
        top: "30px",
        height: "20.88px",
        width: "20.88px",
        },
    },
    "& .InfoLectura:after": {
      content: '""',
      height: "15px",
      width: "15px",
      borderTop: "2px solid #20A698",
      borderLeft: "2px solid #20A698",
      position: "absolute",
      bottom: "-10px",
      left: "Calc(50% - 8px)",
      transform: "rotate(0.625turn)",
      [theme.breakpoints.down(502)]:{
        height: "10px",
        width: "10px",
        left: "Calc(50% - 4.5px)",
        bottom: "-3px",
        borderTop: "1px solid #20A698",
        borderLeft: "1px solid #20A698",
        },
    },
    "& .Sesion": {
      borderLeft: "2px solid #D8D8D8",
    },
    "& .Dato": {
      margin: "0 0 5px 0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#20A698",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        margin: "0 0 2.5px 0",
        },
    },
    "& .Caracter": {
      margin: "0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#000000",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        },
    },
       '& .Regresar':{
        color: '#F2624F',
        border: '#F2624F 1px solid',
        height: '30px',
        width:'100px',
        borderRadius: '20px',
        letterSpacing: '0.14px',
        textTransform: 'none',
        background:'#FEFAF1',
        top:'calc(50% - 15px)',
        left:'5%',
        zIndex:4,
        [theme.breakpoints.down(502)]: {
          font:'bold 10px lato',
          width: "60px",
        },
      },
  },
    ContenedorLectura:{
        background:'#fff',
        width:'1160px',
        height:'100%',
        padding:'60px 107px 35px 146px' ,
        
        transform: (props) =>
        props.scale > 1 ? `scale(${props.scale}) translate(0px, 10%)` : `none`,
        [theme.breakpoints.down(1161)]:{
            width:'502px',
            padding:'60px 0 0',
        },
        [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
        '& .ContainerDerechos':{
            bottom:'80px',
            [theme.breakpoints.down(1161)]:{
                display:'none',
            },
        },
        '& .Derechos':{
            textAlign:"center",
            margin:"0px",
            font:" 15px lato",
            color:"#717476",
        } ,
        '& .Escritorio':{
            display:'block',
            [theme.breakpoints.down(1161)]:{
                display:'none'
            },
        },
        '& .Tablet':{
            display:'none',
            [theme.breakpoints.down(1161)]:{
                display:'block'
            },
        },
    },
    ContainerTitulo:{
        margin:'0 0 46px 0',
        [theme.breakpoints.down(1161)]:{
            margin:'0 0 38px 0',
        },
        '& .Titulo':{
            font:'48px fredoka one',
            letterSpacing: '0px',
            color: '#0CB5D6',
            margin:'0',
            textAlign:'center',
            [theme.breakpoints.down(1161)]:{
                font:'43px fredoka one',
                lineHeight:'52px',
                textAlign:'center',
            },
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            lineHeight : '30px',
            margin:'0 0 36px 0 ',
            font:'22px lato',
            color:'#000',
            letterSpacing: '0.20px',
            [theme.breakpoints.down(1161)]:{
                lineHeight :'28px',
                font:'18px lato',
                letterSpacing: '0.17px',
            },
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .SubTitulo':{
            font:'bold 30px lato',
            color:'#5F9F00',
            letterSpacing:'.28px',
            margin:'0',
            lineHeight:'30px',
            [theme.breakpoints.down(1161)]:{
                font:'bold 26px lato',
                letterSpacing:'.24px',
            },
            [theme.breakpoints.down(502)]:{
                font:'bold 18px lato',
                lineHeight :'24px',
                letterSpacing: '0.21px',
            },
        },

        '& .TextImg':{
            margin:'22px 0 0 0',
            font:'20px lato',
            alignSelf:'flex-start',
            color:'#717476',
            [theme.breakpoints.down(1161)]:{
                font:' 16px lato',
                letterSpacing:'.15px',
                margin:'12px 0 0 0',
            },
            [theme.breakpoints.down(502)]:{
                font:' 11px lato',
                lineHeight :'14px',
                letterSpacing: '0.10px',
            },
        },
       
        '& .Imagen':{
            [theme.breakpoints.down(1161)]:{
                width:'100%',
            },
        },
    },
    

}));

const Las_propiedades_insólitas_del_agua = (props) => {

  const {activeStep, setActiveStep, handlePreviewStep, id_actividad} = props

  const handleContinue = () => {
    setActiveStep(activeStep - 1)
    handlePreviewStep()
  }

    const {
    handleSize,
    disabledZoom,
    closeZoom,
    handleZoom,
    scale
  }= useContext(DrawerContext);
  const [loading, setLoading] = React.useState(true);
  const wrapperRef = React.useRef(null);
  const imagesLoaded = useOnLoadImages(wrapperRef);

  const detectSize = () => {
    const lectura = document.getElementById("lectura");
    const newWidth = lectura?.offsetWidth;
    const newHeight = lectura?.offsetHeight;
    setLoading(false);
    if (lectura) handleSize(newHeight, newWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", detectSize);
    return () => {
      handleZoom()
      window.removeEventListener("resize", detectSize);
      closeZoom()
    };
  }, []);

  const classes = useStyles({ disabledZoom,scale });
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
             <Grid container item justifyContent="center" className={classes.ContainerInfoLectura}>
               <Grid container className="BarraInfoLectura">
                    <Button className="Regresar" onClick={handleContinue}>
                        Regresar
                    </Button>
                </Grid>
               <Grid container item className="InfoLectura">
                    <Grid container item direction="column" xs={6} justifyContent="center">
                        <p className="Dato">Grado</p>
                        <p className="Caracter">6</p>
                    </Grid>
                    <Grid container item direction="column" xs={6} justifyContent="center" className="Sesion">
                        <p className="Dato">Sesión</p>
                        <p className="Caracter">17</p>
                    </Grid>
               </Grid>
            </Grid>
{!imagesLoaded && <Loader />}
        <Grid
            container
            item
            className={classes.ContenedorLectura}
            justifyContent="center"
            id="lectura"
            onLoad={detectSize}
            ref={wrapperRef}
        >
<Drawer id_lectura={id_actividad} />
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >
                    Las propiedades insólitas del agua
                    </h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura ">
                            ¿Alguna vez te has preguntado por qué el planeta en el que vivimos se llama Tierra, a 
                            pesar de que dos terceras partes de su superficie están cubiertas por agua? ¿No sería 
                            más conveniente que se llamara planeta Agua? Aunque esa pregunta es interesante, 
                            lo que importa en este texto es que analices la importancia del agua. <br/><br/>
                            
                            El agua es indispensable para la Tierra, pues fue necesaria para dar origen, desarrollo 
                            y conservación de todos los seres vivos que la habitan. Descubre sus propiedades a 
                            continuación.<br/><br/>
                            
                            <h3 className='SubTitulo'>¿Qué es el agua?</h3><br/>

                            El agua está formada por dos elementos químicos ligeros: hidrógeno y oxígeno. El 
                            primero es el más ligero que existe. Por separado, ambos se encuentran comúnmente 
                            en estado gaseoso, pero si se combinan dos átomos de hidrógeno y uno de oxígeno 
                            (H<sub>2</sub>0), entonces se forma agua. <br/><br/>
                            
                            Como el oxígeno es el gas que respiramos y la Tierra posee una cantidad enorme de 
                            agua, podrías concluir que la preocupación por su escasez es una exageración. Pero 
                            habría que considerar dos hechos: por un lado, la mayoría es agua salada y está en los 
                            océanos. Por el otro, gran parte del agua dulce se encuentra congelada en los 
                            casquetes polares, por lo que difícilmente puede ser utilizada. <br/><br/>
                            
                            <h3 className='SubTitulo'>¿De qué cantidad de agua disponemos?</h3> <br/>
                            
                            De toda el agua que existe, únicamente el 1 % es útil para el consumo humano. 
                            Aunque antiguamente se creía que era un recurso renovable, en la actualidad se 
                            considera no renovable, ya que las actividades humanas la contaminan a mayor 
                            velocidad de la que se purifica por medios naturales o artificiales. Por ello es 
                            indispensable evitar desperdiciarla o alterar su composición con desechos 
                            domésticos o industriales. <br/><br/>
                            
                            A pesar de la poca disponibilidad del agua y de la gravedad ambiental por el uso 
                            inadecuado que se le da, no se ha perdido ni una gota desde que se formó la Tierra. 
                            Toda ha circulado, se ha distribuido, purificado y transformado mediante el ciclo 
                            hidrológico. ¡Imagina que el agua que forma tu sangre quizá alguna vez fue parte del  
                            cuerpo de un dinosaurio!
                        </p>

                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <Grid container  alignItems='flex-end'>
                            <img className='Imagen' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion17/1.png" alt="Tarjeta" />
                        </Grid>
                        <Grid container justifyContent="center" >
                            <p className="TextImg"> 
                            Ciclo hidrológico
                            </p>
                            
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura ">
                            <h3 className='SubTitulo'>El agua y el espacio</h3><br/>

                            Durante mucho tiempo se creyó que el agua sólo existía en este planeta, pero ahora 
                            se sabe que el vapor de agua es de las especies más abundantes en el Universo. 
                            Actualmente se ha encontrado agua en la Luna, en Marte, en Plutón y en asteroides. 
                            Sin embargo, en la mayor parte de los casos se encuentra en estado sólido, es decir, 
                            congelada. <br/><br/>
                            
                            Precisamente porque se sabe que el agua existe también en meteoritos, una de las 
                            teorías más aceptadas sobre el origen del agua en la Tierra es que viene del espacio. 
                            Se postula que después de la formación del planeta algunos de los meteoritos que 
                            impactaron sobre la superficie terrestre hace millones de años contenían agua en 
                            forma de hielo. <br/><br/>
                            
                            <h3 className='SubTitulo'>Peculiaridades de las moléculas del agua</h3> <br/>
                            
                            Cuando baja la temperatura, las moléculas del agua se expanden, a diferencia de 
                            otras sustancias que se contraen. Por ejemplo, esto permite que al congelarse los 
                            mares y ríos sólo se forme hielo en la superficie, lo que posibilita la vida marina 
                            debajo de éste. Otra característica difícil de explicar y entender es que el agua 
                            caliente se congela mucho más rápido que el agua fría. <br/><br/>
                            
                            Aunque pudieras pensar que en estado líquido las moléculas del agua son débiles, 
                            sobre todo si las comparas con el hielo cuando están congeladas, la realidad es que se 
                            encuentran fuertemente unidas unas con otras. Tal es el caso que organismos ligeros 
                            como algunos mosquitos llamados patinadores pueden caminar sobre ríos, lagos e 
                            incluso charcos. <br/><br/>
                            
                            El agua puede subir a través de tubos incluso en contra de la gravedad. Esta 
                            propiedad, conocida como <em>capilaridad</em>, permite el crecimiento y conservación de las 
                            plantas, pues transporta el agua y los nutrientes desde las raíces hacia las hojas.
                        </p>

                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <Grid container justifyContent="center" alignItems='flex-end'>
                            <img className='Imagen'  src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion17/2.png" alt="Tarjeta" />
                        </Grid>
                        <Grid container justifyContent="center" >
                            <p className="TextImg"> 
                            El agua sube de la raíz hacia las hojas.
                            </p>
                            
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura ">
                            Las moléculas del agua, del mismo modo que los imanes, tienen una carga positiva y 
                            otra negativa, por lo que pueden disolver la mayoría de las sustancias. Es por eso que 
                            al agua se le conoce como el disolvente universal. Esta propiedad permite que sea 
                            utilizada en la limpieza, ya sea para usos domésticos, como en el aseo del cuerpo, la 
                            ropa y la casa, o para usos industriales. <br/><br/>
                            
                            Como has leído, el agua es un poco rebelde, pues no siempre sigue las leyes comunes 
                            de la física ni de la química. Pero es precisamente por esa característica que se pudo 
                            originar, diversificar y mantener en este planeta desde sus inicios hasta la actualidad.
                        </p>

                    </Grid>
                </Grid>
        </Grid>
        <Grid container item style={{ marginTop: 80 }}></Grid>
    </Grid>
    );
}
 
export default Las_propiedades_insólitas_del_agua;
