import { makeStyles, Grid, Button } from "@material-ui/core";
import React, { useState, useEffect, useContext, useCallback } from "react";

import axios from "../../../../services/axios";

import ContainerAdmin from "../ContainerAdmin";
import SelectTipo from "../../Admin_Pearson/CargarDatos/CrearCodigos/Selects/SelectTipo";
import SelectGradeGroup from "./SelectGrades";
import AuthContext from "../../../../auth/AuthContext";
import ModalUsuarioCargado from "../modals/ModalUsuarioCargado";
import Loader from "../../../Loader/Loader";
import ModalMessage from "../../../Modals/ModalMessage";
import { Redirect } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  Container: {
    padding: "48px 0 48px 0",
    background:
      "linear-gradient(217deg, rgba(255,194,0,0.4962185557816877) 2%, rgba(255,255,255,1) 25%, rgba(255,255,255,0.4962185557816877) 81%, rgba(144,81,177,1) 98%)",
    minHeight: "calc(100vh - 80px)",
  },
  ContainerReportes: {
    background: "#fff",
    maxWidth: "1216px",
    width: "90%",
    minHeight: "70vh",

    gap: "30px",
    borderRadius: "42px",
    padding: "30px",
    display: "flex",
    alignContent: "center",
    boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.5)",
    "& .Titulo": {
      font: "42px fredoka one",
      margin: "0 0 84px 34px",
    },
  },
  title: {
    font: "28px fredoka one",
    textAlign: "center",
    width: "100%",
    margin: "20px 0",
    color: "#40ADA3",
    [theme.breakpoints.up("sm")]: {
      width: "auto",
      // margin: "0 0 34px 14px",
    },
    [theme.breakpoints.up("md")]: {
      font: "32px fredoka one",
      width: "auto",
      // textAlign: "start",
      // margin: "0 0 34px 14px",
    },
    [theme.breakpoints.up("lg")]: {
      font: "38px fredoka one",
    },
  },
  formContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    maxWidth: "560px",
    alignItems: "flex-start",
    gap: "20px",
  },
  selectItem: {
    width: "80%",
    // backgroundColor: "#00000019",
    border: "none",
    borderRadius: "10px",
    boxSizing: "border-box",
    padding: "10px",
    overflow: "hidden",
    color: "#000",
    "-webkit-appearance": "none",
    " -moz-appearance": "none",

    fontStyle: "Helvética",
    fontSize: "16px",
    "&::after": {
      content: "▴",
      color: "rgb(238, 205, 103)",
      fontSize: "30px",

      lineHeight: "20px",
    },
    backgroundColor: "#f0f0f0",
    "&:focus": {
      outline: "none",
    },
  },
  input: {
    // maxWidth: "560px",
    width: "100%",
    height: "40px",
    backgroundColor: "#f0f0f0",
    padding: "5px 27px",
    borderRadius: "61px",
    borderBottom: "none",
    border: "none",
    // height: "40px",
    boxSizing: "border-box",
    fontSize: "16px",
    "&:focus": {
      outline: "none",
    },
    "& .MuiInput-underline:before": {
      width: "1px",
      borderBottom: "none",
    },
  },
  PurpleButton: {
    color: "#FFFFFF",
    backgroundColor: "#9051b1",
    borderRadius: "30px",
    margin: "0 auto",
    width: "100%",
    maxWidth: "238px",
    minHeight: "45px",
    border: "none",
    textTransform: "unset",
    cursor: "pointer",
    fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
    fontWeight: "500",
    padding: "6px 16px",
    lineHeight: "1.75",
    transition:
      "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    boxShadow:
      "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
    "&:hover": {
      backgroundColor: "#B965D3 ",
    },
  },
  label: {
    fontWeight: "bold",
    fontFamily: "Lato, sans-serif",
  },
  formItemContainer: {
    gap: "10px",
  },
}));

const CargarDatos = () => {
  const classes = useStyles();
  const { user } = useContext(AuthContext);
  const [optionGrades, setOptionGrades] = useState([]);
  const [profileTypes, setProfileTypes] = useState([]);
  const [responseModalOpen, setResponseModalOpen] = useState(false);
  const [responseData, setResponseData] = useState({});
  const [loading, setLoading] = useState(false);
  const [modalMessageOpen, setModalMessageOpen] = useState(false);

  const [error, setError] = useState("");

  const defaultForm = {
    perfil: "",
    grado: "",
    grupo: "",
    nombre: "",
    apellido_paterno: "",
    apellido_materno: "",
    correo: "",
  };
  const [formData, setFormData] = useState(defaultForm);

  const handleCloseMessageModal = () => {
    setModalMessageOpen(!modalMessageOpen);
  };

  const getRoles = () => {
    axios
      .get(`/catalogo/tipo/rol`)
      .then((response) => {
        if (response.status === 200) {
          // console.log(response, "response");
          const list = response.data.resultado?.tipo_usos;
          setProfileTypes(list);
        }
      })
      .catch((error) => {
        console.log(error, "error");
      });
  };

  const getGroups = useCallback(() => {
    axios
      .get(`listado/grado/grupos?id_escuela=${user.escuela.id_escuela}`)
      // .get(`listado/grado/grupos?id_escuela=${formData?.id_escuela}`)
      .then((response) => {
        if (response.status === 200) {
          setOptionGrades(response?.data?.resultado?.listado);
        }
      })
      .catch((error) => {
        console.log(error, "error");
      });
  }, [user.escuela.id_escuela]);

  useEffect(() => {
    getRoles();
  }, []);

  useEffect(() => {
    getGroups();
  }, [getGroups]);

  const handleForm = (e) => {
    if (e.target.name === "perfil") {
      setFormData({
        ...formData,
        [e.target.name.toLowerCase()]: e.target.value,
        grado: "",
        grupo: "",
      });
    } else if (e.target.name === "grado") {
      setFormData({
        ...formData,
        [e.target.name.toLowerCase()]: e.target.value,
        grupo: "",
      });
    } else {
      setFormData({
        ...formData,
        [e.target.name.toLowerCase()]: e.target.value,
      });
    }
  };

  const cargarDocente = () => {
    if (formData?.correo) {
      const body = {
        id_rol: formData?.perfil,
        id_escuela: user?.escuela?.id_escuela,
        id_grado: formData?.grado,
        grupo: formData?.grupo,
        nombre: formData?.nombre,
        apellido_paterno: formData?.apellido_paterno,
        apellido_materno: formData?.apellido_materno,
        correo: formData?.correo,
      };
      setLoading(true);
      axios
        .post("/listado/carga/usuario", body)
        .then((response) => {
          if (response.status === 200) {
            const data = response.data.resultado;
            setResponseData({
              status: "ok",
              nombre_usuario: data?.nombre_usuario,
              contraseña: data?.contraseña,
              error: null,
            });
            setResponseModalOpen(true);
            setFormData(defaultForm);
            getGroups();
          }
        })
        .catch((error) => {
          if (error.response) {
            // Request made and server responded
            const errorData = error.response.data;
            const mensaje =
              errorData?.detalles?.msg ||
              "Ha ocurrido un error al consumir los servicios, inténtelo de nuevo más tarde.";
            setResponseData({
              status: "error",
              error: mensaje,
            });
            setResponseModalOpen(true);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log("Error", error.message);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setModalMessageOpen(true);
      setError("Asegúrese de llenar todos los datos");
    }
  };

  const cargarAlumno = () => {
    const body = {
      id_rol: formData?.perfil,
      id_escuela: user?.escuela?.id_escuela,
      id_grado: formData?.grado,
      grupo: formData?.grupo,
      nombre: formData?.nombre,
      apellido_paterno: formData?.apellido_paterno,
      apellido_materno: formData?.apellido_materno,
      correo: formData?.correo,
    };
    setLoading(true);
    axios
      .post("/listado/carga/usuario", body)
      .then((response) => {
        if (response.status === 200) {
          const data = response.data.resultado;
          setResponseData({
            status: "ok",
            nombre_usuario: data?.nombre_usuario,
            contraseña: data?.contraseña,
            error: null,
          });
          setResponseModalOpen(true);
          setFormData(defaultForm);
          getGroups();
        }
      })
      .catch((error) => {
        if (error.response) {
          // Request made and server responded
          const errorData = error.response.data;
          const mensaje =
            errorData?.detalles?.msg ||
            "Ha ocurrido un error al consumir los servicios, inténtelo de nuevo más tarde.";
          setResponseData({
            status: "error",
            error: mensaje,
          });
          setResponseModalOpen(true);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      formData?.nombre &&
      formData?.apellido_paterno &&
      formData?.perfil &&
      formData?.grado &&
      formData?.grupo
    ) {
      if (formData?.perfil === 2) {
        cargarDocente();
      } else if (formData?.perfil === 1) {
        cargarAlumno();
      }
    } else {
      setModalMessageOpen(true);
      setError("Asegúrese de llenar todos los datos");
    }
  };

  return (
    <ContainerAdmin>
      <Grid
        container
        item
        sm={12}
        className={classes.Container}
        alignItems="center"
        justifyContent="center"
      >
        <Grid
          container
          item
          direction="column"
          className={classes.ContainerReportes}
        >
          <h2 className={classes.title}>Carga de usuarios</h2>
          <form onSubmit={handleSubmit} className={classes.formContainer}>
            <SelectTipo
              label="Perfil"
              fieldName={"perfil"}
              list={profileTypes}
              formData={formData}
              handleForm={handleForm}
              type="rol"
            />
            <SelectGradeGroup
              list={optionGrades}
              title="grado"
              handleForm={handleForm}
              defaultValue={formData?.grado}
              value={formData?.grado}
              formData={formData}
              disabled={!formData?.perfil}
            />
            <SelectGradeGroup
              list={
                optionGrades.find((item) => item.id_grado === formData.grado)
                  ?.grupos
              }
              title="grupo"
              handleForm={handleForm}
              defaultValue={formData?.grupo}
              value={formData?.grupo}
              formData={formData}
              disabled={!formData?.grado}
            />
            <label className={classes.label}>Datos del usuario</label>
            <Grid
              container
              direction="column"
              className={classes.formItemContainer}
            >
              <label htmlFor="input-name" className={classes.label}>
                Nombre(s)
              </label>
              <input
                type="text"
                id="input-name"
                name="nombre"
                onChange={handleForm}
                value={formData.nombre}
                className={classes.input}
                spellCheck="false"
                required
              />
            </Grid>
            <Grid
              container
              direction="column"
              className={classes.formItemContainer}
            >
              <label htmlFor="input-lastName" className={classes.label}>
                Primer apellido
              </label>
              <input
                type="text"
                name="apellido_paterno"
                id="input-lastName"
                onChange={handleForm}
                value={formData.apellido_paterno}
                className={classes.input}
                spellCheck="false"
                required
              />
            </Grid>
            <Grid
              container
              direction="column"
              className={classes.formItemContainer}
            >
              <label htmlFor="input-second-lastName" className={classes.label}>
                Segundo apellido
              </label>
              <input
                type="text"
                id="input-second-lastName"
                name="apellido_materno"
                onChange={handleForm}
                value={formData.apellido_materno}
                className={classes.input}
                spellCheck="false"
              />
            </Grid>

            {formData?.perfil === 2 && (
              <Grid
                container
                direction="column"
                className={classes.formItemContainer}
              >
                <label htmlFor="input-email" className={classes.label}>
                  Correo electrónico
                </label>
                <input
                  type="email"
                  id="input-email"
                  name="correo"
                  onChange={handleForm}
                  value={formData.correo}
                  className={classes.input}
                  spellCheck="false"
                  required
                  pattern="[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$"
                />
              </Grid>
            )}
            <Button
              className={classes.PurpleButton}
              onClick={handleSubmit}
              type="submit"
            >
              Cargar usuario
            </Button>
          </form>
          <ModalUsuarioCargado
            open={responseModalOpen}
            responseData={responseData}
            handleClose={() => {
              setResponseModalOpen(!responseModalOpen);
            }}
          />
          <ModalMessage
            open={modalMessageOpen}
            handleClose={handleCloseMessageModal}
            title={error}
            textOfButton="Aceptar"
          />
          {loading && <Loader />}
          {user?.escuela?.tipo !== "vip" && (
            <Redirect
              to={{
                pathname: `/error/vip`,
                state: {
                  reason: "vip",
                  rol: user?.rol,
                },
              }}
            />
          )}
        </Grid>
      </Grid>
    </ContainerAdmin>
  );
};

export default CargarDatos;
