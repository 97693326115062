import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
    ContenedorLectura:{
        width:'502px',
                  [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
    },
    ContainerTitulo:{
        margin:'0 0 61px 0',
        '& .Titulo':{
            letterSpacing: '0px',
            color: '#FF2700',
            margin:'0',
            textAlign:'center',
            font:'43px fredoka one',
            lineHeight:'52px',
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            margin:'0 0 36px 0 ',
            color:'#000',
            font:'18px lato',
            lineHeight :'28px',
            letterSpacing: '0.17px',
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .Esp':{
            margin:' 0 ',
        },
        '& .SubTitulo':{
            color:'#51AFB7',
            margin:'0',
            lineHeight :'28px',
            font:'bold 26px lato',
            letterSpacing: '0.24px',
            [theme.breakpoints.down(502)]:{
                font:'bold 18px lato',
                lineHeight :'24px',
                letterSpacing: '0.21px',
            },
        },
        '& .SubTitulo2':{
            color:'#FF2700',
            margin:'0',
            font:'bold 22px lato',
            lineHeight :'24px',
            letterSpacing: '0.21px',
            [theme.breakpoints.down(502)]:{
                font:'bold 18px lato',
                lineHeight :'20px',
                letterSpacing: '0.16px',
            },
        },
        '& .TextImg':{
            margin:'22px 0 0 0',
            alignSelf:'flex-start',
            color:'#717476',
            font:' 16px lato',
            lineHeight :'25px',
            letterSpacing: '0.15px',
            [theme.breakpoints.down(502)]:{
                font:' 11px lato',
                lineHeight :'14px',
                letterSpacing: '0.10px',
            },
        },

        '& .li':{
            margin:'0 0 8px 0',
            [theme.breakpoints.down(502)]:{
            margin:'0 0 4px 0',
            }
        },

        '& .ul':{
            margin:'0',
            color:'#51AFB7',
            padding:'0 0 0 19px',
        },

        '& .Imagen':{
            width:'476px',
            [theme.breakpoints.down(502)]:{
                width:'100%',
            },
        },
        '& .Imagen2':{
            width:'613px',
            [theme.breakpoints.down(502)]:{
                width:'100%',
            },
        },
    },

}));
const La_escritura_en_un_viaje_al_pasado = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>

            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >
                    La escritura <br/>en un viaje al pasado  
                    </h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                      <p className="Lectura">
                        El origen de la escritura que utilizamos actualmente puede rastrearse a través de las 
                        huellas que quedaron en las cavernas que habitaban nuestros antepasados. Éstas 
                        también eran utilizadas como lienzos donde las personas pintaban para narrar algo. 
                        Gracias a eso podemos saber cómo sobrevivían, pues allí dejaron plasmadas sus 
                        técnicas de caza, la presencia de ciertos animales y la formación de clanes.
                      </p><br/>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <Grid container justifyContent="center" alignItems='flex-end'>
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img2do/Sesion15/1.png" alt="Tarjeta" />
                        </Grid>
                        <Grid container item justifyContent='center' >
                            <p className="TextImg"> 
                            Las pinturas rupestres nos remiten tanto al origen de la escritura como al de las artes.
                            </p>
                            
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                      <p className="Lectura">
                        <h3 className='SubTitulo'>De los clanes a las sociedades </h3><br/>

                            Con el paso del tiempo la lengua evolucionó y se convirtió en un modo de 
                            comunicación más complejo. Eso dio paso a la creación de símbolos y 
                            representaciones gráficas de ideas o palabras.<br/><br/> 
                            
                            Las personas que se agrupaban en clanes durante la Prehistoria comenzaron a 
                            organizarse en comunidades o pueblos. Entonces surgieron culturas que propiciaron 
                            la creación de sistemas de escritura representados por grafías, es decir, letras. Así 
                            surgió lo que ahora llamamos escritura. Ésta nos permite comunicarnos a través de 
                            textos (como el que estás leyendo en este preciso momento). <br/><br/>

                            <h3 className='SubTitulo2'>Datos históricos </h3><br/>

                            <ul  className="ul">
                                <li className="li"><p className="Lectura Esp">Egipto es la cuna de la escritura, pues los arqueólogos han descubierto allí 
                                inscripciones gráficas en vasijas de más de 6000 años de antigüedad.</p></li>

                                <li className="li"><p className="Lectura Esp">Al principio, el sistema de escritura egipcio estaba basado en jeroglíficos y se 
                                reservaba para inscripciones en las paredes de los templos, tumbas y cerámicas.</p></li>

                                <li className="li"><p className="Lectura Esp">Después, su escritura evolucionó, pero sólo los sacerdotes, los escribas, los nobles
                                y el faraón sabían leer y escribir.</p></li>

                                <li className="li"><p className="Lectura Esp">La escritura <em>cuneiforme</em> (atribuida al pueblo sumerio) se distinguió por sus símbolos 
                                impresos en arcilla, piedra y madera. Se han descifrado ideas complejas, como la
                                expresión de operaciones aritméticas simples (suma, división y multiplicación).</p></li>

                                <li className="li"><p className="Lectura Esp">En China se desarrolló un sistema basado en <em>ideogramas</em> vigente hasta la fecha. 
                                Éstos representan símbolos que no están asociados a un sonido, sino a ideas.</p></li>

                                <li className="li"><p className="Lectura Esp">Las culturas prehispánicas dotaron a la humanidad de escrituras en <em>estelas</em> y 
                                templos, y códices en papel o piel de animal. Así narraban eventos astronómicos 
                                o documentaban su sistema numérico.</p></li>
                            </ul>
                      </p>

                     
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <Grid container justifyContent="center" alignItems='flex-end'>
                            <img className="Imagen2" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img2do/Sesion15/2.png" alt="Tarjeta" />
                        </Grid>
                        <Grid container  >
                            <p className="TextImg"> 
                            De acuerdo con las descripciones del texto, ubica cuál de estos símbolos corresponde a
                            cada tipo de escritura.
                            </p>
                            
                        </Grid>
                    </Grid>
                </Grid>
        </Grid>
    </Grid>
    );
}
 
export default La_escritura_en_un_viaje_al_pasado;
