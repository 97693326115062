import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
    ContenedorLectura:{
        width:'502px',
        [theme.breakpoints.down(502)]:{
            width:'350px',
        },
    },
    ContainerTitulo:{
        margin:'0 0 36px 0',
        '& .Titulo':{
            letterSpacing: '0px',
            color: '#926DD1',
            margin:'0',
            font:'43px fredoka one',
            lineHeight:'52px',
            textAlign:'center',
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            margin:'0 0 36px 0 ',
            color:'#000',
            lineHeight :'28px',
            font:'18px lato',
            letterSpacing: '0.17px',
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .SubTitulo':{
            color:'#0294FF',
            margin:'0',
            font:'bold 26px lato',
            letterSpacing:'.24px',
            [theme.breakpoints.down(502)]:{
                font:'bold 18px lato',
                lineHeight :'24px',
                letterSpacing: '0.21px',
            },

        },
        '& .TextImg':{
            margin:'22px 0 0 0',
            alignSelf:'flex-start',
            color:'#717476',
            font:' 16px lato',
            letterSpacing:'.15px',
            [theme.breakpoints.down(502)]:{
                font:' 11px lato',
                lineHeight :'14px',
                letterSpacing: '0.10px',
            },
        },
        '& .Imagen':{
            width:'100%',
        },
    },
    

}));

const El_español_de_aquí_y_el_español_de_allá_podremos_entendernos = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>

            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo " >
                        El español de aquí y el español de allá: ¿podremos entendernos?
                    </h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                      <p className="Lectura ">
                        El español es la segunda lengua con más hablantes nativos en el mundo. En 2020, el 
                        número de personas que hablaba este idioma como Lengua materna era
                        aproximadamente de 480 millones, repartidos geográficamente en Latinoamérica, 
                        España y algunos países de África. <br/><br/>
                        
                        En América se habla como lengua oficial desde el norte de México hasta la Patagonia 
                        en Argentina, con excepción de algunos países como Brasil o Jamaica. Pero a pesar 
                        de que es una misma lengua, las diferencias entre regiones llegan a ser muy 
                        marcadas: el español que se habla en Colombia no es el mismo que se habla en Chile
                        o Perú. Incluso el que se habla en la capital de un país es diferente al de una ciudad 
                        distante de la misma nación. <br/><br/>
                        
                        Es cierto que hablar una misma lengua en una comunidad de hablantes tan extensa 
                        como América facilita la comunicación. Pero también es necesario reconocer que la 
                        lengua refleja la cultura e identidad de cada país o región, por lo que las diferencias 
                        entre cómo se manifiesta esa misma lengua se hacen evidentes. <br/><br/>
                        
                        Para que comprendas mejor los motivos por los cuales hay diferencias entre una 
                        misma lengua, lee a continuación los cuatro factores principales que lo explican. <br/><br/>
                        
                        <h3 className='SubTitulo'>Variedad geográfica</h3> <br/>
                        
                        Como lo dice su nombre, esta diferencia se refiere la ubicación de donde son los 
                        hablantes. Esto da como resultado la formación de dialectos, es decir, las variantes 
                        de una misma lengua. Por lo tanto, el español tiene tantos dialectos como regiones 
                        donde cambie su uso. <br/><br/>
                        
                        Hay muchos ejemplos de ello. Un caso es la palabra que se emplea para nombrar el 
                        instrumento que se usa para escribir. En España se le conoce como <em>bolígrafo</em>, pero en 
                        México, <em>pluma</em>. Es más, en Argentina, Paraguay y Uruguay le dicen <em>lapicera</em> o <em>lapicero</em>.
                        Y en Venezuela se le nombra <em>esferográfico</em>.
                      </p>

                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <Grid container justifyContent="center" alignItems='flex-end'>
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion15/1.png" alt="Tarjeta" />
                        </Grid>
                        <Grid container  >
                            <p className="TextImg "> 
                                Aunque las cosas se nombren diferente, siempre existirá el contexto para poder entender lo
                                que los hablantes dicen.
                            </p>

                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                      <p className="Lectura ">
                       <h3 className='SubTitulo'>Variedad funcional o contextual</h3> <br/>
                       
                        Esta característica indica el uso que se le da al lenguaje, en función de si se realiza de
                        manera formal o informal. Es decir, depende de la manera en que se dirigen las 
                        personas de acuerdo con las relaciones de jerarquía entre ellas. Así, por ejemplo, te 
                        diriges a tus amigos con lenguaje informal, pero cuando lo haces con personas a las 
                        que se les debe guardar algún tipo de respeto (como el director de tu escuela o el 
                        presidente de un país) sueles hacerlo en lenguaje formal. <br/><br/>

                        <h3 className='SubTitulo'>Variedad sociocultural</h3> <br/>
                        
                        Este tipo de variación en la lengua depende del género, la edad, la condición social, el 
                        nivel académico o profesional y otros rasgos que definen al hablante en su 
                        interacción con los demás.<br/><br/>
                        
                        Es muy probable que para ti sea muy evidente esta característica cuando comparas 
                        las palabras que usas con tus amigos y las que usan tus papás. Seguramente cuando 
                        te escuchan hablar o si revisaran tus conversaciones en chats, hay muchas palabras
                         que no entenderían. A esto último se le conoce como <em>léxico generacional</em>. <br/><br/>
                         
                        <h3 className='SubTitulo'>Variedad histórica</h3> <br/>
                         
                        Esta explicación se relaciona con lo último que leíste en la variedad anterior. Cuando 
                        el <em>léxico generacional</em> se consolida y paulatinamente se agregan u olvidan palabras (es 
                        decir, caen en desuso) se dice que hay <em>variedad histórica</em>. Por ello, en los libros 
                        antiguos hallarás palabras que te costará trabajo entender, por ejemplo, <em>botica</em>, 
                        <em>enhebrar</em>, <em>fenecer</em>. <br/><br/>
                        
                        Si bien hasta ahora has visto que la diversidad de regiones hace que haya variantes 
                        del español de acuerdo con el contexto de cada zona geográfica, también existe el 
                        fenómeno opuesto: la convivencia entre distintas realidades hace que se adopten 
                        palabras y expresiones para hacerlas comunes. Es por ello que en América aún se 
                        conservan palabras de lenguas originarias prehispánicas y se han adoptado palabras 
                        de otras lenguas, como el inglés.<br/><br/>
                        
                        A las palabras provenientes de otra lengua se les conoce como extranjerismos. 
                        Ejemplos de ellos son <em>wifi, stalker, clic, hacker, follow, unfollow, hashtag, blog, hipster</em>. 
                        En estos casos, las palabras provienen de un fenómeno reciente: la tecnología y el 
                        internet, ámbitos donde la lengua más usada es el inglés, seguida del mandarín y el 
                        español.
                      </p>


                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <Grid container justifyContent="center" alignItems='flex-end'>
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion15/2.png" alt="Tarjeta" />
                        </Grid>
                       <Grid container item justifyContent='center' >
                            <p className="TextImg"> 
                            Los extranjerismos cada vez son más comunes por el fenómeno de la globalización.
                            </p>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                      <p className="Lectura ">
                        Cuando las palabras que se utilizan de otra lengua se convierten y utilizan de forma 
                        común para nombrar acciones o realidades, se convierten en <em>neologismos</em>; es decir, 
                        son palabras nuevas que se incorporan a una lengua. Por ejemplo, <em>chatear, crackear</em>, 
                        <em>escanear, customizar</em>.<br/><br/>
                        
                        Como has visto, una comunidad en constante comunicación e intercambio es una 
                        comunidad que se enriquece. Este proceso ha dado paso a la conformación de los 
                        diferentes idiomas que se hablan en el mundo. Así que quizá dentro de muchos siglos 
                        lo que ahora es una lengua única terminará por convertirse en varios idiomas.
                      </p>
                    </Grid>
                </Grid>
        </Grid>
    </Grid>
    );
}
 
export default El_español_de_aquí_y_el_español_de_allá_podremos_entendernos;
