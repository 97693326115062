import { Button, Grid, Hidden, makeStyles } from "@material-ui/core";
import React from "react";
import BlurContainer from "../../../BlurContainer";

import SaveIcon from "@mui/icons-material/Save";
import Dibujar from "../../../../../imgs/Dibujar.png";
import Marcatextos from "../../../../../imgs/Marcatextos.png";
import Zoom from "../../../../../imgs/Zoom.png";
import Deshacer from "../../../../../imgs/Deshacer.png";
import Pizarra from "../../../../../imgs/Pizarra.png";
import Limpiar from "../../../../../imgs/Limpiar.png";
import Notas from "../../../../../imgs/Notas.png";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  continuedivContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    alignContent: "center",
    width: "auto",
    gridArea: "button",
  },
  continuediv: {
    textTransform: "none",
    position: "relative",
    height: "40px",
    width: "130px",
    background: "#7A54BA",
    boxShadow: "0px 0px 15px 15px #fff",
    font: "bold 14px lato ",
    borderRadius: "61px",
    color: "#FFFFFF",
    margin: "0",
    [theme.breakpoints.down(600)]: {
      height: "30px",
      width: "100px",
      font: "bold 10px lato ",
    },
    "&:hover": {
      background: "#dd78fb",
    },
  },
  containerToolBar: {
    zIndex: 999,
    boxShadow: "0px 2px 5px #00000029",
    // height: "10%",

    // background: "#fff",
    display: "grid",
    gridTemplateAreas: `"toolbar button"`,
    gridTemplateColumns: "minmax(280px, auto) 140px",
    height: "90px",
    position: "fixed",
    bottom: "0",
    width: "100%",
    ////////////

    // display: "flex",
    flexWrap: "nowrap",
    flexDirection: "row",
    alignContent: "center",
    justifyContent: "center",
    alignItems: "center",
    gap: "20px",
    [theme.breakpoints.up("md")]: {
      justifyContent: "space-evenly",
      gap: "0px",
      paddingLeft: "4%",
      gridTemplateColumns: "minmax(380px, auto) 140px",
    },
    [theme.breakpoints.up("lg")]: {
      gridTemplateColumns: "minmax(610px, auto) 140px",
    },
  },

  containerTools: {
    // background: "#fff",
    height: "80px",
    width: "auto",
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
    flexWrap: "nowrap",
    gap: "1%",
    position: "relative",

    [theme.breakpoints.up("md")]: {
      width: "auto",
      display: "flex",
      justifyContent: "center",
      alignContent: "center",
      flexWrap: "nowrap",
      gap: "30px",
    },
    "&:before": {
      // display: (props) => (props.openMenuInDesktop ? "none" : "flex"),
      display: "flex",
      zIndex: -2,
      textTransform: "none",
      justifyContent: "center",
      alignItems: "center",
      // borderRadius: "25px",
      position: "absolute",
      content: '""',
      font: "bold 14px lato",
      width: "100%",
      height: "100%",
      boxShadow: "0px 0px 20px 20px #fff",
      backgroundColor: "#fff",
      [theme.breakpoints.up("lg")]: {
        width: "120%",
      },
    },
  },

  divContainer: {
    height: "100%",
    position: "relative",
    alignItems: "center",
    [theme.breakpoints.down(1161)]: {
      height: "75px",
    },
  },

  divToolBar: {
    height: "50px",
    width: "50px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "15px",
    border: "#EFEFEF 3px solid",
    backgroundColor: "#fff",
    cursor: "pointer",
    padding: "0",
    [theme.breakpoints.down(600)]: {
      height: "30px",
      width: "30px",
    },
    [theme.breakpoints.down(400)]: {
      height: "100%",
      width: "25px",
      borderRadius: "0",
    },
    "&:hover": {
      background: "#8AE9DF",
      border: "#F1CB5A 3px solid",
    },
    "& .Img": {
      height: "30px",
      width: "30px",
      [theme.breakpoints.down(600)]: {
        height: "20px",
        width: "20px",
      },
    },
  },

  Nota: {
    display: "flex",
    "&:hover": {
      "&:before": {
        textTransform: "none",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "absolute",
        content: '"Agregar notas"',
        textAlign: "center",
        bottom: "80px",
        width: "80px",
        background: "#fff",
        font: "bold 14px lato",
      },
    },
  },
  Dibujar: {
    display: "flex",
    "&:hover": {
      "&:before": {
        textTransform: "none",
        display: (props) => (props.openDrawTools ? "none" : "flex"),
        justifyContent: "center",
        alignItems: "center",
        position: "absolute",
        content: '"Dibujar"',
        textAlign: "center",
        bottom: "80px",
        width: "80px",
        background: "#fff",
        font: "bold 14px lato",
      },
    },
  },
  Marcatextos: {
    display: "flex",
    "&:hover": {
      "&:before": {
        textTransform: "none",
        display: (props) => (props.openMarkTools ? "none" : "flex"),
        justifyContent: "center",
        alignItems: "center",
        position: "absolute",
        content: '"Marcar texto"',
        textAlign: "center",
        bottom: "80px",
        width: "80px",
        background: "#fff",
        font: "bold 14px lato",
      },
    },
  },
  Zoom: {
    display: "flex",
    "&:hover": {
      "&:before": {
        textTransform: "none",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "absolute",
        content: '"Zoom"',
        textAlign: "center",
        bottom: "80px",
        width: "80px",
        background: "#fff",
        font: "bold 14px lato",
      },
    },
  },
  Deshacer: {
    display: "flex",
    "&:hover": {
      "&:before": {
        textTransform: "none",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "absolute",
        content: '"Deshacer"',
        textAlign: "center",
        bottom: "80px",
        width: "80px",
        background: "#fff",
        font: "bold 14px lato",
      },
    },
  },
  Pizarra: {
    display: "flex",
    "&:hover": {
      "&:before": {
        textTransform: "none",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "absolute",
        content: '"Pizarra"',
        textAlign: "center",
        bottom: "80px",
        width: "80px",
        background: "#fff",
        font: "bold 14px lato",
      },
      [theme.breakpoints.down(1280)]: {
        display: "none",
      },
    },
  },
  Limpiar: {
    display: "flex",
    "&:hover": {
      "&:before": {
        textTransform: "none",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "absolute",
        content: '"Limpiar pantalla"',
        textAlign: "center",
        bottom: "80px",
        width: "80px",
        background: "#fff",
        font: "bold 14px lato",
      },
    },
  },

  Guardar: {
    display: "flex",
    "&:hover": {
      "&:before": {
        textTransform: "none",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "absolute",
        content: '"Guardar"',
        textAlign: "center",
        bottom: "80px",
        width: "80px",
        background: "#fff",
        font: "bold 14px lato",
      },
    },
  },

  linkdiv: {
    textDecoration: "none",
  },

  BotonContinuar: {
    textTransform: "none",
    height: "62px",
    width: "238px",
    background: "#7A54BA",
    // boxShadow: "0px 3px 6px #00000029",
    borderRadius: "61px",
    color: "#FFFFFF",
    font: "bold 20px lato ",
    "&:hover": {
      background: "#dd78fb",
    },
    "&:first-letter": {
      textTransform: "uppercase",
    },
    boxShadow: "0px 0px 15px 20px white",
  },
  dialog: {
    bottom: "125%",
    margin: "0 auto",
    backgroundColor: "#3f43b3",
    fontFamily: "Lato",
    letterSpacing: "0.36px",
    fontSize: "20px",
    color: "#ffffff",
    position: "absolute",
    minWidth: "520px",
    width: "auto",
    maxWidth: "480px",
    padding: "24px",
    boxSizing: "border-box",
    borderRadius: "21.25px",

    "&:before": {
      // display: (props) => (props.openMenuInDesktop ? "none" : "flex"),
      display: "flex",
      zIndex: 1,
      textTransform: "none",
      justifyContent: "center",
      alignItems: "center",
      // borderRadius: "25px",
      position: "absolute",
      content: '""',
      border: "15px solid",
      borderTopColor: "#3f43b3",
      borderLeftColor: "transparent",
      borderBottomColor: "transparent",
      borderRightColor: "transparent",
      font: "bold 14px lato",
      right: "calc(50% - 15px)",
      bottom: "-28px",
    },
  },
  dialogContinue: {
    bottom: "100%",
    margin: "0 auto",
    backgroundColor: "#3f43b3",
    fontFamily: "Lato",
    letterSpacing: "0.36px",
    fontSize: "20px",
    color: "#ffffff",
    position: "absolute",
    width: "auto",
    minWidth: "360px",
    maxWidth: "420px",
    padding: "24px",
    boxSizing: "border-box",
    borderRadius: "21.25px",

    "&:before": {
      // display: (props) => (props.openMenuInDesktop ? "none" : "flex"),
      display: "flex",
      zIndex: 1,
      textTransform: "none",
      justifyContent: "center",
      alignItems: "center",
      // borderRadius: "25px",
      position: "absolute",
      content: '""',
      border: "15px solid",
      borderTopColor: "#3f43b3",
      borderLeftColor: "transparent",
      borderBottomColor: "transparent",
      borderRightColor: "transparent",
      font: "bold 14px lato",
      right: "calc(50% - 15px)",
      bottom: "-28px",
    },
  },
}));

const ToolbarStep = ({ step, handleStep, toActivity }) => {
  const classes = useStyles({});
  return (
    <>
      <BlurContainer handleClose open>
        <Grid
          container
          item
          direction="row"
          justifyContent="center"
          alignItems="center"
          className={classes.containerToolBar}
        >
          {!step?.reading.two && (
            <>
              <Grid
                container
                item
                direction="row"
                justifyContent="center"
                alignItems="center"
                className={classes.containerTools}
                onClick={() => {
                  handleStep({
                    ...step,
                    reading: {
                      ...step?.reading,
                      two: true,
                    },
                  });
                }}
              >
                <Grid container item className={classes.divContainer}>
                  <button
                    variant="outlined"
                    className={`${classes.divToolBar} ${classes.Nota}`}
                    // onClick={addNewNote}
                  >
                    <img className="Img" alt={"notes"} src={Notas} />
                  </button>
                </Grid>

                <Hidden mdDown>
                  <Grid container item className={classes.divContainer}>
                    {/* <DrawTools /> */}
                    <button
                      variant="outlined"
                      className={`${classes.divToolBar} ${classes.Dibujar}`}
                      // onClick={handleDrawTools}
                      // disabled={!disabledZoom}
                    >
                      <img className="Img" alt={"draw"} src={Dibujar} />
                    </button>
                  </Grid>
                </Hidden>

                <Hidden mdDown>
                  <Grid container item className={classes.divContainer}>
                    {/* <MarkTools /> */}
                    <button
                      variant="outlined"
                      className={`${classes.divToolBar} ${classes.Marcatextos}`}
                      // onClick={handleMarkToolsOpen}
                      // disabled={!disabledZoom}
                    >
                      <img
                        className="Img"
                        alt={"marcatextos"}
                        src={Marcatextos}
                      />
                    </button>
                  </Grid>
                </Hidden>

                <Grid container item className={classes.divContainer}>
                  <button
                    variant="outlined"
                    className={`${classes.divToolBar} ${classes.Zoom}`}
                    // onClick={() => {
                    //   if (disabledZoom) {
                    //     handleZoom();
                    //   } else {
                    //     handleZoom();
                    //   }
                    // }}
                  >
                    <img className="Img" alt={"zoom"} src={Zoom} />
                  </button>
                </Grid>

                <Grid container item className={classes.divContainer}>
                  <button
                    variant="outlined"
                    className={`${classes.divToolBar} ${classes.Deshacer}`}
                    // onClick={undo}
                  >
                    <img className="Img" alt={"deshacer"} src={Deshacer} />
                  </button>
                </Grid>

                <Hidden mdDown>
                  <Grid
                    container
                    item
                    alignItems="center"
                    className={classes.divContainer}
                  >
                    <button
                      variant="outlined"
                      className={`${classes.divToolBar} ${classes.Pizarra}`}
                      // onClick={handlePizarra}
                      // style={whiteboardSelected()}
                    >
                      <img className="Pizarra" alt={"pizarra"} src={Pizarra} />
                    </button>
                  </Grid>
                </Hidden>

                <Grid container item className={classes.divContainer}>
                  <button
                    variant="outlined"
                    className={`${classes.divToolBar} ${classes.Limpiar}`}
                    // onClick={handleErase}
                  >
                    <img className="Img" alt={"limpiar"} src={Limpiar} />
                  </button>
                </Grid>

                <Grid container item className={classes.divContainer}>
                  <button
                    variant="outlined"
                    className={`${classes.divToolBar} ${classes.Guardar}`}
                    // onClick={handleSave}
                  >
                    <SaveIcon className="Img" sx={{ color: "#20A698" }} />
                  </button>
                </Grid>

                <p className={classes.dialog}>
                  Puedes hacer anotaciones con las herramientas de lectura que
                  están en la parte inferior.
                </p>
              </Grid>
            </>
          )}
          {step?.reading.two && !step?.reading.three && (
            <Grid
              container
              item
              justifyContent="flex-start"
              alignItems="center"
              className={classes.continuedivContainer}
            >
              <Link className={classes.linkdiv} to={toActivity}>
                <Button id="BotonIrActividad" className={classes.continuediv}>
                  Ir a actividad ➜
                </Button>
              </Link>
              <p className={classes.dialogContinue}>
                Cuando termines de leer, haz clic en
                <b> Ir a la actividad.</b>
              </p>
            </Grid>
          )}
        </Grid>
      </BlurContainer>
    </>
  );
};

export default ToolbarStep;
