import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
     Contenedor:{
        background:'#E9FBF9',
        width:'100vw',
        overflowX:'clip',
        [theme.breakpoints.down(1229)]:{
            background:'none',
        },
        [theme.breakpoints.down(600)]:{
            marginTop: '79.51px',
        }
    },
  ContainerInfoLectura: {
    position: "relative",
    height: "86px",
    [theme.breakpoints.down(502)]:{
        height: "43px",
    },
    background: "#76D4C3",
    "& .BarraInfoLectura": {
      height: "86px",
      background: `Url(https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Barras/Azul.png)`,
        [theme.breakpoints.down(502)]:{
            height: "43px",
        },
    },
    "& .InfoLectura": {
      position: "absolute",
      height: "86px",
      width: "219px",
      boxSizing: "border-box",
      padding: "17.2px 0",
      background: "#FAFAFA",
      borderRadius: "50px",
      boxShadow: "2px 2px 2px 2px #00000029",
      zIndex: "4",
        [theme.breakpoints.down(502)]:{
            height: "43px",
            width: "109px",
            padding: "8.6px 0",
            borderRadius: "25px",
        },
    },
    "& .InfoLectura:before": {
      position: "absolute",
      display: "flex",
      boxSizing: "border-box",
      padding: "0 0 0 10px",
      justifyContent: "center",
      alignItems: "center",
      height: "39.88px",
      width: "39.88px",
      borderRadius: "50%",
      content: '""',
      boxShadow: "3px 0px .5px 0px #00000029",
      background: "#FAFAFA",
      left: "90px",
      top: "65px",
      transform: "rotate(0.25turn)",
      [theme.breakpoints.down(502)]:{
        left: "45px",
        top: "30px",
        height: "20.88px",
        width: "20.88px",
        },
    },
    "& .InfoLectura:after": {
      content: '""',
      height: "15px",
      width: "15px",
      borderTop: "2px solid #20A698",
      borderLeft: "2px solid #20A698",
      position: "absolute",
      bottom: "-10px",
      left: "Calc(50% - 8px)",
      transform: "rotate(0.625turn)",
      [theme.breakpoints.down(502)]:{
        height: "10px",
        width: "10px",
        left: "Calc(50% - 4.5px)",
        bottom: "-3px",
        borderTop: "1px solid #20A698",
        borderLeft: "1px solid #20A698",
        },
    },
    "& .Sesion": {
      borderLeft: "2px solid #D8D8D8",
    },
    "& .Dato": {
      margin: "0 0 5px 0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#20A698",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        margin: "0 0 2.5px 0",
        },
    },
    "& .Caracter": {
      margin: "0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#000000",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        },
    },   },
    ContenedorLectura:{
        position:'relative',
        background:'#fff',
        width:'1229px',
        padding:'0' ,
        [theme.breakpoints.down(1229)]:{
            width:'768px',
        },
          [theme.breakpoints.down(768)]:{
            width:'350px',
        },
        '& .Derechos':{
            textAlign:"center",
            margin:"0px",
            font:" 15px lato",
            color:"#717476",
            top:'2110px',
            [theme.breakpoints.down(1229)]:{
               display:'none'
            },
        },
        '& .Imagen1':{
            width:'100%'
        },
        '& .Pos':{
            position:'absolute',
            margin:'0',
        },
        '& .Tablet':{
            [theme.breakpoints.down(768)]:{
                display:'none'
             },
        },
        '& .Cel':{
            display:'flex',
            [theme.breakpoints.up(768)]:{
                display:'none'
             },
        },
        '& .Titulo':{
            color:'#353535',
            textAlign:'center',
            font:'60px Fredoka One',
            lineHeight:'56px',
            top:'4.85%',
            [theme.breakpoints.down(1229)]:{
                font:'36px Fredoka One',
                lineHeight:'34px',
            },
            [theme.breakpoints.down(768)]:{
                font:'18px Fredoka One',
                lineHeight:'20px',
            },
        },
        '& .SubTitulo1':{
            font:'44px Fredoka One',
            color:'#FFFFFF',
            textAlign:'center',
            top:'11.91%',
            [theme.breakpoints.down(1229)]:{
                font:'28px Fredoka One',
                lineHeight:'34px',
            },
            [theme.breakpoints.down(768)]:{
                font:'13px Fredoka One',
                lineHeight:'17px',
            },
        },
        '& .SubTitulo2':{
            font:'38px Fredoka One',
            color:'#FFFFFF',
            left:'21.96%',
            [theme.breakpoints.down(1229)]:{
                font:'23px Fredoka One',
                lineHeight:'27px',
            },
            [theme.breakpoints.down(768)]:{
                font:'10px Fredoka One',
                lineHeight:'12px',
            },
        },
        '& .SubTitulo2-1':{
            top:'15.65%',
        },
        '& .SubTitulo2-2':{
            top:'34.95%',
        },
        '& .SubTitulo2-3':{
            top:'62%',
        },
        '& .SubT1':{
            left:'28.88%',
        },
        '& .SubT2':{
            left:'44.34%',
        },
        '& .SubT3':{
            left:'28.48%',
        },
        '& .SubT4':{
            left:'46.70%',
        },
        '& .SubT5':{
            left:'28.47%',
        },
        '& .SubT6':{
            left:'46.70%',
        },
        '& .Horario':{
            font:'22px Fredoka One',
            color:'#FFFFFF',
            [theme.breakpoints.down(1229)]:{
                font:'16px Fredoka One',
                lineHeight:'20px',
            },
            [theme.breakpoints.down(768)]:{
                font:'7px Fredoka One',
                lineHeight:'7px',
            },
        },
        '& .Horario1':{
            left:'11.06%'
        },
        '& .Horario2':{
            left:'24.41%'
        },
        '& .Horario3':{
            left:'35.63%'
        },
        '& .Horario4':{
            left:'45.38%'
        },
        '& .Horario5':{
            left:'59.39%'
        },
        '& .Horario6':{
            left:'70.87%'
        },
        '& .Horario7':{
            left:'81.77%'
        },
        '& .Dia1':{
            top:'19.25%',
        },
        '& .Dia2':{
            top:'38.15%',
        },
        '& .Dia3':{
            top:'65.5%',
        },
        '& .Hora':{
            font:'bold 20px lato',
            color:'#FFFFFF',
            lineHeight:'24px',
            left:'12%',
            textAlign:'center',
            [theme.breakpoints.down(1229)]:{
                left:'10%',
                font:'bold 14px lato',
                lineHeight:'17px',
            },
            [theme.breakpoints.down(768)]:{
                font:'bold 6px lato',
                lineHeight:'7px',
            },
        },
        '& .Hora1':{
            top:'21.41%'
        },
        '& .Hora2':{
            top:'24.83%'
        },
        '& .Hora3':{
            top:'28.01%'
        },
        '& .Hora4':{
            top:'31%'
        },
        '& .Hora5':{
            top:'40.20%'
        },
        '& .Hora6':{
            top:'43.37%'
        },
        '& .Hora7':{
            top:'46.82%'
        },
        '& .Hora8':{
            top:'50.60%'
        },
        '& .Hora9':{
            top:'53.69%'
        },
        '& .Hora10':{
            top:'57.71%'
        },
        '& .Hora11':{
            top:'67.80%'
        },
        '& .Hora12':{
            top:'71.08%'
        },
        '& .Hora13':{
            top:'74.60%'
        },
        '& .Hora14':{
            top:'79.36%'
        },
        '& .Hora15':{
            top:'83.10%'
        },
        '& .Hora16':{
            top:'87.40%'
        },
        '& .Clase':{
            font:'bold 20px lato',
            color:'#FFFF',
            lineHeight:'19px',
            textAlign:'center',
            [theme.breakpoints.down(1229)]:{
                font:'bold 12.5px lato',
                lineHeight:'13px',
            },
            [theme.breakpoints.down(768)]:{
                font:'bold 5px lato',
                lineHeight:'6px',
            },
            
        },
        '& .Nota':{
            font:'bold 16px lato',
            color:'#FFFF',
            textAlign:'center',
            [theme.breakpoints.down(1229)]:{
                font:'bold 11px lato',
                lineHeight:'13px',
            },
            [theme.breakpoints.down(768)]:{
                font:'bold 5px lato',
                lineHeight:'5px',
            },
        },
        '& .Clase1':{
            top:'22.17%',
            left:'82.83%',
        },
        '& .Clase2':{
            top:'24.40%',
            left:'82.18%',
        },
        '& .Clase3':{
            top:'28.60%',
            left:'25.22%',
        },
        '& .Clase4':{
            top:'28.50%',
            left:'33.36%',
        },
        '& .Clase5':{
            top:'28.60%',
            left:'48.50%',
        },
        '& .Clase6':{
            top:'28.10%',
            left:'59.15%',
        },
        '& .Clase7':{
            top:'28.10%',
            left:'70%',
        },
        '& .Clase8':{
            top:'31.70%',
            left:'25.50%',
        },
        '& .Clase9':{
            top:'30.80%',
            left:'32.79%',
        },
        '& .Clase10':{
            top:'31.70%',
            left:'48.82%',
        },
        '& .Clase11':{
            top:'30.80%',
            left:'56.06%',
        },
        '& .Clase12':{
            top:'30.90%',
            left:'70.14%',
        },
        '& .Clase13':{
            top:'41.02%',
            left:'82.42%',
        },
        '& .Clase14':{
            top:'44.2%',
            left:'82.42%',
        },
        '& .Clase15':{
            top:'47.80%',
            left:'35.80%',
        },
        '& .Clase16':{
            top:'47.80%',
            left:'59.15%',
        },
        '& .Clase17':{
            top:'46.35%',
            left:'68.51%',
        },
        '& .Clase18':{
            top:'51.2%',
            left:'22.62%',
        },
        '& .Clase19':{
            top:'51.2%',
            left:'46.54%',
        },
        '& .Clase20':{
            top:'50.30%',
            left:'69.57%',
        },
        '& .Clase21':{
            top:'54.45%',
            left:'24.10%',
        },
        '& .Clase22':{
            top:'53.94%',
            left:'35.23%',
        },
        '& .Clase23':{
            top:'54.45%',
            left:'48.08%',
        },
        '& .Clase24':{
            top:'53.94%',
            left:'58.59%',
        },
        '& .Clase25':{
            top:'54.0%',
            left:'70.54%',
        },
        '& .Clase26':{
            top:'56.9%',
            left:'21.50%',
        },
        '& .Clase27':{
            top:'58.30%',
            left:'36%',
        },
        '& .Clase28':{
            top:'58.30%',
            left:'48.50%',
        },
        '& .Clase29':{
            top:'58.30%',
            left:'59.72%',
        },
        '& .Clase30':{
            top:'58.30%',
            left:'72.70%',
        },
        '& .Clase31':{
            top:'68.50%',
            left:'82.42%',
            
        },
        '& .Clase32':{
            top:'70.80%',
            left:'80.60%',
        },
                '& .Clase33':{
            top:'74.2%',
            left:'68.41%',
            [theme.breakpoints.down(1229)]:{
                top:'73.80%',
                left:'68.41%',
            },
        },
        '& .Clase34':{
            top:'78.50%',
            left:'22.87%',
        },
        '& .Clase35':{
            top:'78.50%',
            left:'46.54%',
        },
        '& .Clase37':{
            top:'79.20%',
            left:'69.30%',
        },
        '& .Clase38':{
            top:'83.50%',
            left:'22.87%',
        },
        '& .Clase39':{
            top:'83.50%',
            left:'35.89%',
        },
        '& .Clase40':{
            top:'83.50%',
            left:'46.80%',
        },
        '& .Clase41':{
            top:'83.50%',
            left:'58.74%',
        },
        '& .Clase42':{
            top:'83.0%',
            left:'70.30%',
        },
        '& .Clase43':{
            top:'86.60%',
            left:'21.40%',
        },
        '& .Clase44':{
            top:'86.84%',
            left:'35.60%',
        },
        '& .Clase45':{
            top:'86.74%',
            left:'46.80%',
        },
        '& .Clase46':{
            top:'86.84%',
            left:'58.2%',
        },
        '& .Clase47':{
            top:'86.74%',
            left:'70.9%',
        },


    },
    
}));

const Tabla_de_actividades_extraescolares = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
             <Grid container item justifyContent="center" className={classes.ContainerInfoLectura}>
               <Grid container className="BarraInfoLectura"></Grid>
               <Grid container item className="InfoLectura">
                    <Grid container item direction="column" xs={6} justifyContent="center">
                        <p className="Dato">Grado</p>
                        <p className="Caracter">2</p>
                    </Grid>
                    <Grid container item direction="column" xs={6} justifyContent="center" className="Sesion">
                        <p className="Dato">Sesión</p>
                        <p className="Caracter">34</p>
                    </Grid>
               </Grid>
            </Grid>

            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
            <Grid
                container
                item
                justifyContent="center"
                className='Tablet'
                >
                <h2 className='Pos Titulo'>Tabla de actividades <br/>extraescolares</h2>
                <h3 className='Pos SubTitulo1'>Ciclo escolar agosto-junio</h3>

                <h4 className='Pos SubTitulo2 SubTitulo2-1 SubT1'>Edad</h4>
                <h4 className='Pos SubTitulo2 SubTitulo2-1 SubT2'>Infantil 3 a 5 años</h4>
                <h4 className='Pos SubTitulo2 SubTitulo2-2 SubT3'>Grado</h4>
                <h4 className='Pos SubTitulo2 SubTitulo2-2 SubT4'>Primaria 1º y 2º</h4>
                <h4 className='Pos SubTitulo2 SubTitulo2-3 SubT5'>Grado</h4>
                <h4 className='Pos SubTitulo2 SubTitulo2-3 SubT6'>Primaria 3º a 6º</h4>

                <p className='Pos Horario Horario1 Dia1'>Horario</p>
                <p className='Pos Horario Horario2 Dia1'>Lunes</p>
                <p className='Pos Horario Horario3 Dia1'>Martes</p>
                <p className='Pos Horario Horario4 Dia1'>Miércoles</p>
                <p className='Pos Horario Horario5 Dia1'>Jueves</p>
                <p className='Pos Horario Horario6 Dia1'>Viernes</p>
                <p className='Pos Horario Horario7 Dia1'>Sábado</p>

                <p className='Pos Horario Horario1 Dia2'>Horario</p>
                <p className='Pos Horario Horario2 Dia2'>Lunes</p>
                <p className='Pos Horario Horario3 Dia2'>Martes</p>
                <p className='Pos Horario Horario4 Dia2'>Miércoles</p>
                <p className='Pos Horario Horario5 Dia2'>Jueves</p>
                <p className='Pos Horario Horario6 Dia2'>Viernes</p>
                <p className='Pos Horario Horario7 Dia2'>Sábado</p>

                <p className='Pos Horario Horario1 Dia3'>Horario</p>
                <p className='Pos Horario Horario2 Dia3'>Lunes</p>
                <p className='Pos Horario Horario3 Dia3'>Martes</p>
                <p className='Pos Horario Horario4 Dia3'>Miércoles</p>
                <p className='Pos Horario Horario5 Dia3'>Jueves</p>
                <p className='Pos Horario Horario6 Dia3'>Viernes</p>
                <p className='Pos Horario Horario7 Dia3'>Sábado</p>

                <p className='Pos Hora Hora1'>10:00 a<br/> 11:00 hrs</p>
                <p className='Pos Hora Hora2'>12:00 a<br/> 13:00 hrs</p>
                <p className='Pos Hora Hora3'>15:00 a<br/> 16:00 hrs</p>
                <p className='Pos Hora Hora4'>16:00 a<br/> 17:00 hrs</p>
                <p className='Pos Hora Hora5'>10:00 a<br/> 11:00 hrs</p>
                <p className='Pos Hora Hora6'>11:00 a<br/> 13:00 hrs</p>
                <p className='Pos Hora Hora7'>15:00 a<br/> 16:00 hrs</p>
                <p className='Pos Hora Hora8'>16:00 a<br/> 17:00 hrs</p>
                <p className='Pos Hora Hora9'>17:00 a<br/> 18:00 hrs</p>
                <p className='Pos Hora Hora10'>18:00 a<br/> 19:00 hrs</p>
                <p className='Pos Hora Hora11'>10:00 a<br/> 11:00 hrs</p>
                <p className='Pos Hora Hora12'>11:00 a<br/> 13:00 hrs</p>
                <p className='Pos Hora Hora13'>15:00 a<br/> 16:00 hrs</p>
                <p className='Pos Hora Hora14'>16:00 a<br/> 17:00 hrs</p>
                <p className='Pos Hora Hora15'>17:00 a<br/> 18:00 hrs</p>
                <p className='Pos Hora Hora16'>18:00 a<br/> 19:00 hrs</p>

                <p className='Pos Clase Clase1'>Inglés</p>
                <p className='Pos Clase Clase2'>Yoga kids<br/><b className='Nota'>(actividad <br/>familiar)</b></p>
                <p className='Pos Clase Clase3'>Ballet</p>
                <p className='Pos Clase Clase4'>Cuentacuentos</p>
                <p className='Pos Clase Clase5'>Ballet</p>
                <p className='Pos Clase Clase6'>Cocina <br/>creativa</p>
                <p className='Pos Clase Clase7'>Ajedrez<br/><b className='Nota'>(principiantes)</b> </p>
                <p className='Pos Clase Clase8'>Arte</p>
                <p className='Pos Clase Clase9'>Gimnasia rítmica <br/><b className='Nota'>(a partir<br/>del 5 de octubre)</b></p>
                <p className='Pos Clase Clase10'>Arte</p>
                <p className='Pos Clase Clase11'>Gimnasia rítmica <br/><b className='Nota'>(a partir<br/>del 5 de octubre)</b></p>
                <p className='Pos Clase Clase12'>Coro <br/><b className='Nota'>(todos los<br/>grados y grupos)</b></p>
                <p className='Pos Clase Clase13'>Inglés I</p>
                <p className='Pos Clase Clase14'>Inglés II</p>
                <p className='Pos Clase Clase15'>Karate</p>
                <p className='Pos Clase Clase16'>Karate</p>
                <p className='Pos Clase Clase17'>Ajedrez <br/><b className='Nota'>(principiantes, para<br/>todos los grados<br/>y grupos)</b></p>
                <p className='Pos Clase Clase18'>Robótica</p>
                <p className='Pos Clase Clase19'>Robótica</p>
                <p className='Pos Clase Clase20'>Coro<br/><b className='Nota'>(todos los grados<br/>y grupos)</b></p>
                <p className='Pos Clase Clase21'>Teatro</p>
                <p className='Pos Clase Clase22'>Danza<br/> moderna</p>
                <p className='Pos Clase Clase23'>Teatro</p>
                <p className='Pos Clase Clase24'>Danza<br/> moderna</p>
                <p className='Pos Clase Clase25'>Ajedrez<br/> <b className='Nota'>(intermedio)</b></p>
                <p className='Pos Clase Clase26'>Lengua<br/> de señas<br/><b className='Nota'>(todos los grados<br/>y grupos)</b></p>
                <p className='Pos Clase Clase27'>Futbol</p>
                <p className='Pos Clase Clase28'>Arte</p>
                <p className='Pos Clase Clase29'>Futbol</p>
                <p className='Pos Clase Clase30'>Arte</p>
                <p className='Pos Clase Clase31'>Inglés II</p>
                <p className='Pos Clase Clase32'>Inglés II<br/><b className='Nota'>Cuentacuentos <br/>en inglés</b></p>
                <p className='Pos Clase Clase33'>Ajedrez<br/><b className='Nota'> (principiantes, para <br/>todos los grados y <br/>grupos)</b></p>
                <p className='Pos Clase Clase34'>Teatro<br/> musical<br/> <b className='Nota'>(a partir <br/>de 4º grado)</b></p>
                <p className='Pos Clase Clase35'>Teatro<br/> musical<br/> <b className='Nota'>(a partir <br/>de 4º grado)</b></p>
                <p className='Pos Clase Clase37'>Coro<br/><b className='Nota'>(todos los grados<br/>y grupos)</b></p>
                <p className='Pos Clase Clase38'>Robótica</p>
                <p className='Pos Clase Clase39'>Patinaje</p>
                <p className='Pos Clase Clase40'>Robótica</p>
                <p className='Pos Clase Clase41'>Patinaje</p>
                <p className='Pos Clase Clase42'>Ajedrez <br/><b className='Nota'>(intermedio)</b></p>
                <p className='Pos Clase Clase43'>Lengua<br/>de señas<br/> <b className='Nota'>(todos los grados<br/> y grupos)</b></p>
                <p className='Pos Clase Clase44'>Esgrima<br/> <b className='Nota'>(a partir <br/>de 5º grado)</b> </p>
                <p className='Pos Clase Clase45'>Taller de <br/>escritura <br/>creativa<br/><b className='Nota'>(6º grado)</b></p>
                <p className='Pos Clase Clase46'>Esgrima<br/> <b className='Nota'>(a partir <br/>de 5º grado)</b> </p>
                <p className='Pos Clase Clase47'>Taller de <br/>escritura <br/>creativa<br/><b className='Nota'>(6º grado)</b></p>

                <p className="Pos Derechos">
                        © 2022. Todos los derechos reservados, Pearson, Circuito de lectores.
                </p> 
                <img className="Imagen1" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img2do/Sesion34/1.png"  alt="Tarjeta" />
                </Grid>
                <img className="Imagen1 Cel" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img2do/Sesion34/F3.png"  alt="Tarjeta" />

        </Grid>
    </Grid>
    );
}
 
export default Tabla_de_actividades_extraescolares;
