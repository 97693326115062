import React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

import { makeStyles } from "@material-ui/core";
import cdlLogo from "../../../imgs/CL_LOGO_FINAL_Login 1.png";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    // padding: theme.spacing(2),

    [theme.breakpoints.up("sm")]: {
      // margin: "20px 0",
      paddingTop: "0",
      paddingBottom: "0",
    },
  },
  "& .MuiDialog-paper": {
    borderRadius: "12.1px",
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
    display: "flex",
    justifyContent: "center",
    gap: "5%",
    marginBottom: "16px",
  },
  "& .MuiDialogTitle-root": {
    [theme.breakpoints.up("sm")]: {
      // margin: "20px 0",
      padding: "22px",
    },
  },
}));

const useStyles = makeStyles((theme) => ({
  containerContent: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    padding: "20px",
    gap: "10px",
    boxSizing: "border-box",
    [theme.breakpoints.up("sm")]: {
      // margin: "20px 0",
    },
  },
  containerButtons: {
    display: "flex",
    justifyContent: "center",
  },
  button: {
    backgroundColor: "#B965D3",
    borderRadius: "4px",
    boxSizing: "borderBox",
    outline: "0",
    border: "0",
  },
  textGreen: {
    color: "#00c851",
    fontFamily: "Lato",
    fontSize: "20px",
    textAlign: "center",
    margin: "0",
    fonWweight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
  },
  text: {
    // width: "364px",
    // height: "36px",
    // margin: "11px 63.7px 18px 35.3px",
    fontFamily: "Lato",
    margin: 0,
    // fontFamily: "Fredoka One",
    fontSize: "16px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "0.23px",
    textAlign: "center",
    color: "#000",
    [theme.breakpoints.up("lg")]: {
      fontSize: "20px",
      // margin: "20px",
      // fontWeight: "600",
      maxWidth: "40ch",
    },
  },
  title: {
    fontFamily: "Fredoka One",
    margin: 0,
    fontSize: "24px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "0.23px",
    textAlign: "center",
    // marginBottom: "20px",
    color: "#000",
    [theme.breakpoints.up("lg")]: {
      fontSize: "26px",
      // margin: "10px",
    },
  },
  img: {
    width: "124px",
    height: "124px",
    objectFit: "contain",
    borderRadius: "50%",
  },
  user: {
    fontFamily: "Fredoka One",
    margin: 0,
    fontSize: "24px",
  },
}));

const PurpleButton = styled(Button)(({ theme }) => ({
  color: "#FFFFFF",
  backgroundColor: "#9051b1",
  borderRadius: "30px",
  textTransform: "unset",
  // margin: "0 auto",
  width: "50%",
  maxWidth: "130px",
  "&:hover": {
    backgroundColor: "#B965D3 ",
  },
}));



const ModalEscuelaCargada = ({ open, handleClose, dataSchool, schoolName }) => {
  const classes = useStyles();
  return (
    <>
      <BootstrapDialog
        // onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        maxWidth="md"
      >
        <DialogContent>
          <div className={classes.containerContent}>
            <img
              src={dataSchool?.logo || cdlLogo}
              alt=""
              className={classes.img}
            />
            <p className={classes.textGreen}>
              La escuela ha sido cargada con éxito
            </p>
            <h3 className={classes.title}>
              {dataSchool?.escuela || schoolName || "Nombre de la escuela"}
            </h3>
            <p className={classes.text}>Usuario</p>
            <p className={classes.user}>{dataSchool?.nombre_usuario}</p>
            <p className={classes.text}>Contraseña</p>
            <p className={classes.user}>{dataSchool?.contraseña}</p>

            <p className={classes.text}>
              Ahora puedes dar de alta usuarios y/o generar códigos de
              activación para que esta escuela comience a usar{" "}
              <b>Circuito de lectores</b>.
            </p>
          </div>
        </DialogContent>
        <DialogActions
          disableSpacing={true}
          className={classes.containerButtons}
        >
          <PurpleButton
            onClick={handleClose}
            className={classes.button}
            variant="contained"
          >
            Continuar
          </PurpleButton>
        </DialogActions>
      </BootstrapDialog>
    </>
  );
};
export default ModalEscuelaCargada;
