import React, { useContext } from "react";
import { Button, makeStyles, Grid } from "@material-ui/core";

import { Link, Redirect } from "react-router-dom";
import DropZone from "./settings/teacher/DropZone";
import AuthContext from "../../../auth/AuthContext";
import types from "../../../types/types";
import axios from "../../../services/axios";

import Loader from "../../Loader/Loader";
import ModalErrorDetails from "../../Modals/ModalErrorDetails";
import ModalMessage from "../../Modals/ModalMessage";
import ModalError from "../../Modals/ModalError";



const useStyles = makeStyles((theme) => ({
  mainContainer: {
    padding: "48px 0 48px 0",
    minHeight: "100vh",
    background:
      "linear-gradient(217deg, rgba(255,194,0,0.4962185557816877) 2%, rgba(255,255,255,1) 25%, rgba(255,255,255,0.4962185557816877) 81%, rgba(144,81,177,1) 98%)",
    [theme.breakpoints.down(760)]: {
      width: "100vw",
    },
    // [theme.breakpoints.up(1920)]:{
    //     height:'92vh',
    // },
    // [theme.breakpoints.down(1920)]:{
    //     height:'92vh',
    // },
    // [theme.breakpoints.down(1811)]:{
    //     height:'1150px',
    // },
    // [theme.breakpoints.down(1487)]:{
    //     height:'1430px',
    // },
    // background: 'linear-gradient(217deg, rgba(255,194,0,0.4962185557816877) 2%, rgba(255,255,255,1) 25%, rgba(255,255,255,0.4962185557816877) 81%, rgba(144,81,177,1) 98%)',
  },
  boxContainer: {
    background: "#fff",
    width: "100%",
    maxWidth: "1216px",
    // height:'88vh',
    [theme.breakpoints.up("xs")]: {
      width: "90%",
    },
    [theme.breakpoints.up("sm")]: {
      width: "80%",
    },
    borderRadius: "42px",
    padding: "30px",
    boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.5)",
  },
  large: {
    // width: theme.spacing(40),
    // height: theme.spacing(40),
    width: "211px",
    height: "209px",
  },

  mainTitle: {
    font: "48px fredoka one",
    margin: "0px",
    textAlign: "center",
    color: "orange",
    [theme.breakpoints.down("760")]: {
      font: "36px fredoka one",
    },
    [theme.breakpoints.down("321")]: {
      font: "28px fredoka one",
    },
  },

  link: {
    // transform:'translate(-50%,-50%)',
    textDecoration: "none",

    [theme.breakpoints.up("xl")]: {
      marginTop: "40px",
    },
  },
  BotonAvanza: {
    textTransform: "none",
    marginTop: "40px",
    height: "62px",
    width: "279px",
    background: "#7A54BA",
    boxShadow: "0px 3px 6px #00000029",
    borderRadius: "61px",
    color: "#FFFFFF",
    font: "bold 20px lato ",
    "&:hover": {
      background: "#dd78fb",
    },
    [theme.breakpoints.down(1367)]: {
      height: "52px",
      width: "250px",
    },
    [theme.breakpoints.down("321")]: {
      // height: "62px",
      width: "198px",
      font: "bold 18px lato ",
      marginTop: "20px",
    },
  },
  instructionsText: {
    font: "26px lato",
    color: "#000",
    [theme.breakpoints.down("760")]: {
      font: "22px lato",
    },
    [theme.breakpoints.down("321")]: {
      font: "18px lato",
    },
  },
  featuresText: {
    font: "20px lato",
    color: "#505759",
    marginBottom: "10px",
    [theme.breakpoints.down("760")]: {
      font: "18px lato",
    },
    [theme.breakpoints.down("321")]: {
      font: "16px lato",
    },
  },
  iconImage: {
    position: "absolute",
    // botton: '100%',
    left: "100%",
  },
}));

const AvatarUploader = () => {
  const classes = useStyles();
  const [uploadComplete, setUploadComplete] = React.useState(false);
  const [image, setImage] = React.useState("");
  const [imageChanged, setImageChanged] = React.useState(false);
  const [modalMessageOpen, setModalMessageOpen] = React.useState(false);
  const [modalErrorDetailsOpen, setModalErrorDetailsOpen] = React.useState(false);
  const [confirmChangesResult, setConfirmChangesResult] = React.useState("");
  const [resultError, setResultError] = React.useState("");
  const [modalErrorOpen, setModalErrorOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const { user, dispatch } = useContext(AuthContext);

  React.useEffect(() => {
    changeImage();
  }, [image]);

  const changeImage = () => {
    if (image) {
      const imageToBase64= image.split(",")[1]
      const body = {
        idUsuario: user.userId,
        image: imageToBase64,
      };
      setLoading(true);
      axios
        .post("/profesor/setImage", body)
        .then((response) => {
          if (response.status === 200) {
            const newImage = response.data.resultado.path;
            dispatch({
              type: types.avatar,
              payload: {
                avatar: {
                  ...user.avatar,
                  path: newImage,
                },
              },
            });

            setModalMessageOpen(true);
            setConfirmChangesResult("Los datos se han actualizado correctamente");
          }
        })
        .catch((err) => {
          if (err.response) {
            const errorCode = err?.response?.data?.detalles?.error;
            if (errorCode === "00099") {
              setModalErrorOpen(true);
              setResultError("content");
              setImageChanged(false);
              setImage("");
            } else if (errorCode === "00096") {
              setModalErrorOpen(true);
              setResultError("limit");
              setImageChanged(false);
              setImage("");
            }
          } else {
            setModalMessageOpen(true);
            setImageChanged(false);
            setConfirmChangesResult("Ha ocurrido un error inesperado, por favor Intente de nuevo más tarde");
            setImage("");
          }
          setLoading(false);
        })
        .finally(() => {
          setImageChanged(true);
          setLoading(false);
        });
    }
  };

  const handleImage = (imageBase64) => {
    setImage(imageBase64);
  };

  const handleCloseMessageModal = () => {
    setModalMessageOpen(!modalMessageOpen);
    setResultError("");
  };
  const handleCloseErrorModal = () => {
    setModalErrorOpen(!modalErrorOpen);
  };
  const handleCloseErrorDetailsModal = () => {
    setModalErrorOpen(false);
    setModalErrorDetailsOpen(!modalErrorDetailsOpen);
  };

  // const [image, setImage] = React.useState("");

  // const onCompleteUpload = (data) => {
  //   setImage(data.avatarUpload.url);
  // };

  return (
    <Grid container item sm={12} alignItems="center" justifyContent="center" className={classes.mainContainer}>
      <Grid container item direction="column" alignItems="center" justifyContent="center" className={classes.boxContainer}>
        <h1 className={classes.mainTitle}>Sube tu fotografía</h1>
        <DropZone
          handleNext={() => {
            setImageChanged(false);
          }}
          handleImage={handleImage}
          image={image}
        />
        <p className={classes.instructionsText}>
          Personaliza tu usuario docente subiendo una fotografía
          <br />
          con las siguientes características:
        </p>
        <p className={classes.featuresText}>
          Retrato de frente y con luz adecuada para que se distinga tu rostro.
          <br />
          Formato .png o .jpg únicamente.
          <br />
          Peso máximo: 1 000 KB.
          <br />
        </p>
        <Link to={imageChanged ? "/docente/perfil" : "#"} style={{ textDecoration: "none" }}>
          <Button
            className={classes.BotonAvanza}
            disabled={!imageChanged}
            onClick={() => {
              setUploadComplete(true);
            }}
          >
            Continuar
          </Button>
        </Link>
        <Grid container item className={classes.link} justifyContent="center" alignItems="center">
          <Link to="/docente/perfil" style={{ textDecoration: "none" }}>
            <Button className={classes.BotonAvanza} style={{ backgroundColor: "white", color: "black" }}>
              Omitir
            </Button>
          </Link>
        </Grid>
        {loading && <Loader />}
        {/* <ModalConfirm open={confirmationModalOpen} handleClose={handleCloseConfirmationModal}  /> */}

        <ModalError
          open={modalErrorOpen}
          handleClose={handleCloseErrorModal}
          type={resultError}
          handleNext={handleCloseErrorDetailsModal}
        />
        <ModalErrorDetails open={modalErrorDetailsOpen} handleClose={handleCloseErrorDetailsModal} />
        <ModalMessage open={modalMessageOpen} handleClose={handleCloseMessageModal} title={confirmChangesResult} />
        {uploadComplete && <Redirect to={{ pathname: `/docente/perfil` }} />}
      </Grid>
    </Grid>
  );
};

export default AvatarUploader;
