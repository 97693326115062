import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
    ContenedorLectura:{
        width:'502px',
                  [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
    },
    ContainerTitulo:{
        margin:'0 0 46px 0',
        '& .Titulo':{
            letterSpacing: '0px',
            color: '#D63390',
            margin:'0',
            textAlign:'center',
            font:'43px fredoka one',
            lineHeight:'52px',
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            margin:'0 0 36px 0 ',
            color:'#000',
            font:'18px lato',
            lineHeight :'28px',
            letterSpacing: '0.17px',
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .SubTitulo':{
            margin:'0',
            color:'#823CE2',
            font:'bold 22px lato',
            lineHeight :'24px',
            letterSpacing: '0.21px',
            [theme.breakpoints.down(502)]:{
                font:'bold 18px lato',
                lineHeight :'20px',
                letterSpacing: '0.16px',
            },
        },
        '& .TextImg':{
            margin:'22px 0 0 0',
            color:'#717476',
            font:' 16px lato',
            lineHeight :'25px',
            letterSpacing: '0.15px',
            [theme.breakpoints.down(502)]:{
                font:' 11px lato',
                lineHeight :'14px',
                letterSpacing: '0.10px',
            },
        },
        '& .Imagen':{
            width:'100%'
        }
        

    },
    

}));

const Viajeros_en_el_espacio_asteroides_y_cometas = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>

            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >
                        Viajeros en el espacio:<br/> asteroides y cometas
                    </h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container justifyContent="center" sm={12}>
                        <Grid container item justifyContent='center' >
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img3ro/Sesion33/1.png"  alt="Tarjeta" />
                        </Grid>
                        <Grid container item justifyContent='center'>
                            <p className='TextImg'>
                            Ilustración de la Vía Láctea.
                            </p>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                            ¿Alguna vez has oído hablar de los cuerpos celestes? Son aquellos que se encuentran 
                            fuera de la atmósfera terrestre, como las estrellas, los meteoroides, los asteroides y 
                            los cometas, entre otros. Es fácil confundirlos, pero pueden diferenciarse gracias a su 
                            tamaño, sus materiales y el lugar donde se encuentran. Por ejemplo, los cometas, 
                            asteroides y meteoroides son restos de la formación del sistema solar, pero no se 
                            consideran planetas porque son muy pequeños.<br/><br/>

                            <h3 className='SubTitulo'>¿Asteroides o cometas?</h3><br/>
                            
                            Los meteoroides, cometas y asteroides generan interés y temor entre los 
                            investigadores. Esto se debe a la teoría de que un asteroide exterminó a los 
                            dinosaurios hace 65 millones de años. En consecuencia, un grupo de científicos vigila 
                            continuamente el cielo para detectar a los entes que podrían impactarse en el globo 
                            terráqueo y causar una catástrofe. Pero en realidad el riesgo es bajo, ya que gran
                            parte de los asteroides se desintegra al entrar en la atmósfera.<br/><br/>
                            
                            Los asteroides están formados principalmente de rocas y metales. Son más grandes 
                            que los cometas, pero más pequeños que los planetas. Tienen formas variadas, ¡tanto 
                            que no existen dos asteroides iguales! Y no tienen colas como los cometas. En 
                            conjunto, forman un cinturón compuesto por miles de asteroides que se encuentra 
                            entre los planetas Marte y Júpiter. <br/><br/>
                            
                            Los cometas están formados por hielo, roca, polvo y materia orgánica. Se estima que 
                            son cuerpos celestes aislados que giran alrededor del Sol en forma de dos cinturones. 
                            El primero está entre Neptuno y Plutón y el segundo se localiza fuera de los límites 
                            de nuestro sistema.<br/><br/>
                            
                            El camino que siguen los cometas y los asteroides se llama <em>órbita</em>, igual que el de los 
                            planetas. Las órbitas de estos tres se cruzan en muchas direcciones. Las de los 
                            cometas pueden alterarse por la acción de las estrellas, aunque éstos también 
                            pueden ser atraídos hacia el Sol por acción de la gravedad. <br/><br/>
                            
                            Los cometas se calientan cuando se acercan a esta gran estrella, entonces el hielo 
                            que los forma se convierte en vapor. Después desprenden gases y polvo que son 
                            iluminados por la luz solar. Cuando los miramos desde la superficie terrestre parecen 
                            tener una cola brillante, la cual se llama <em>coma</em>.<br/><br/>
                            
                            Con el tiempo se han identificado alrededor de 4 000 cometas. Uno de los más 
                            conocidos es el cometa Halley. En la Tierra puede ser visto viajando por el cielo 
                            nocturno cada 75 años aproximadamente, a pesar de encontrarse tan lejos de 
                            nosotros. <br/><br/>
                            
                            Observar el cielo nocturno es un espectáculo natural. ¿Alguna vez has visto una 
                            lluvia de meteoros?
                        </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <Grid container item justifyContent='center'>
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img3ro/Sesion33/2.png"  alt="Tarjeta" />
                        </Grid>
                        <Grid container item justifyContent='center'>
                            <p className='TextImg'>
                                Cuerpos celestes: cometa, meteoro y asteroide.
                            </p>
                        </Grid>
                    </Grid>
                </Grid>
        </Grid>

    </Grid>
    );
}
 
export default Viajeros_en_el_espacio_asteroides_y_cometas;
