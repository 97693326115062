import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
     Contenedor:{
        background:'#E9FBF9',
        width:'100vw',
        overflowX:'clip',
        [theme.breakpoints.down(1161)]:{
            background:'none',
           overflowX:'unset',
        },
        [theme.breakpoints.down(600)]:{
            marginTop: '79.51px',
        }
    },
  ContainerInfoLectura: {
    position: "relative",
    height: "86px",
    [theme.breakpoints.down(502)]:{
        height: "43px",
    },
    background: "#76D4C3",
    "& .BarraInfoLectura": {
      height: "86px",
      background: `Url(https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Barras/Azul.png)`,
        [theme.breakpoints.down(502)]:{
            height: "43px",
        },
    },
    "& .InfoLectura": {
      position: "absolute",
      height: "86px",
      width: "219px",
      boxSizing: "border-box",
      padding: "17.2px 0",
      background: "#FAFAFA",
      borderRadius: "50px",
      boxShadow: "2px 2px 2px 2px #00000029",
      zIndex: "4",
        [theme.breakpoints.down(502)]:{
            height: "43px",
            width: "109px",
            padding: "8.6px 0",
            borderRadius: "25px",
        },
    },
    "& .InfoLectura:before": {
      position: "absolute",
      display: "flex",
      boxSizing: "border-box",
      padding: "0 0 0 10px",
      justifyContent: "center",
      alignItems: "center",
      height: "39.88px",
      width: "39.88px",
      borderRadius: "50%",
      content: '""',
      boxShadow: "3px 0px .5px 0px #00000029",
      background: "#FAFAFA",
      left: "90px",
      top: "65px",
      transform: "rotate(0.25turn)",
      [theme.breakpoints.down(502)]:{
        left: "45px",
        top: "30px",
        height: "20.88px",
        width: "20.88px",
        },
    },
    "& .InfoLectura:after": {
      content: '""',
      height: "15px",
      width: "15px",
      borderTop: "2px solid #20A698",
      borderLeft: "2px solid #20A698",
      position: "absolute",
      bottom: "-10px",
      left: "Calc(50% - 8px)",
      transform: "rotate(0.625turn)",
      [theme.breakpoints.down(502)]:{
        height: "10px",
        width: "10px",
        left: "Calc(50% - 4.5px)",
        bottom: "-3px",
        borderTop: "1px solid #20A698",
        borderLeft: "1px solid #20A698",
        },
    },
    "& .Sesion": {
      borderLeft: "2px solid #D8D8D8",
    },
    "& .Dato": {
      margin: "0 0 5px 0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#20A698",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        margin: "0 0 2.5px 0",
        },
    },
    "& .Caracter": {
      margin: "0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#000000",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        },
    },   },
    ContenedorLectura:{
        background:'#fff',
        width:'1160px',
        height:'100%',
        padding:'60px 169px 35px ',
        [theme.breakpoints.down(1161)]:{
            width:'502px',
            padding:'60px 0 0 0',
          },
        [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
        '& .ContainerDerechos':{
            bottom:'80px',
            [theme.breakpoints.down(1161)]:{
                display:'none',
            },
        },
        '& .Derechos':{
            textAlign:"center",
            margin:"0px",
            font:" 15px lato",
            color:"#717476",
        },
        '& .Escritorio':{
            display:'block',
            [theme.breakpoints.down(1161)]:{
                display:'none'
            },
        },
        '& .Tablet':{
            display:'none',
            [theme.breakpoints.down(1161)]:{
                display:'block'
            },
        }
    },
    ContainerTitulo:{
        margin:'0 0 34px 0',
        [theme.breakpoints.down(1161)]:{
            margin:'0 0 34px 0',
        },
        '& .Titulo':{
            font:'48px fredoka one',
            letterSpacing: '0px',
            lineHeight:'58px',
            color: '#FF0076',
            margin:'0',
            textAlign:'center',
            [theme.breakpoints.down(1161)]:{
                font:'43px fredoka one',
                lineHeight:'52px',
            },
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            lineHeight : '30px',
            margin:'0 0 36px 0',
            font:'22px lato',
            color:'#000',
            letterSpacing: '0.20px',
            [theme.breakpoints.down(1161)]:{
                font:'18px lato',
                lineHeight :'28px',
                letterSpacing: '0.17px',
            },
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .Lectura-1':{
            margin:'0 ',
        },
        '& .Lectura2':{
            lineHeight : '30px',
            margin:'0 0 36px 0',
            font:'22px lato',
            color:'#000',
            letterSpacing: '0.20px',
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'20px',
                letterSpacing: '0.17px',
            },
        },
        '& .Lectura3':{
            position:'absolute',
            font:'20px lato',
            color:'#575757',
            letterSpacing:'.19px',
            lineHeight:'28px',
            margin:'0',
            top:'55px',
            [theme.breakpoints.down(1161)]:{
                top:'13%',
                font:' 16px lato',
                lineHeight :'21.5px',
                letterSpacing: '0.15px',
            },
            [theme.breakpoints.down(502)]:{
                top:'10%',
                font:' 12px lato',
                lineHeight :'24px',
                letterSpacing: '0.10px',
            },
        },
        
        '& .TextImg':{
            font:'20px lato',
            color:'#717476',
            letterSpacing:'.19px',
            lineHeight:'30px',
            margin:'24px 0 0 0',
            [theme.breakpoints.down(1161)]:{
                font:' 16px lato',
                lineHeight :'25px',
                letterSpacing: '0.15px',
            },
            [theme.breakpoints.down(502)]:{
                font:' 11px lato',
                lineHeight :'14px',
                letterSpacing: '0.10px',
            },
        },
        '& .Obra':{
            position:'relative'
        },
        '& .Pos':{
            position:'absolute',
            top:'46px',
            left:'168px',
            [theme.breakpoints.down(1161)]:{
                width:'100%',
            },
        },
        '& .Pos2':{
            position:'absolute',
            top:'10%',
            left:'35%',
        },
        '& .Pos3':{
            position:'absolute',
            top:'60%',
            left:'8%',
        },

        '& .Lupa':{
            position:'relative',
        },
        '& .Pos4':{
            position:'absolute',
            top:'-6%',
            [theme.breakpoints.down(502)]:{
                top:'-7%',
            },
        },

        '& .Imagen':{
            [theme.breakpoints.down(1161)]:{
                width:'100%',
            },
        },  
        '& .Imagen2':{
            [theme.breakpoints.down(502)]:{
                width:'100%',
                height:'400px',
            },
        }, 
      
    }
}));

const Romeo_y_Julieta_la_obra_eterna = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
             <Grid container item justifyContent="center" className={classes.ContainerInfoLectura}>
               <Grid container className="BarraInfoLectura"></Grid>
               <Grid container item className="InfoLectura">
                    <Grid container item direction="column" xs={6} justifyContent="center">
                        <p className="Dato">Grado</p>
                        <p className="Caracter">5</p>
                    </Grid>
                    <Grid container item direction="column" xs={6} justifyContent="center" className="Sesion">
                        <p className="Dato">Sesión</p>
                        <p className="Caracter">12</p>
                    </Grid>
               </Grid>
            </Grid>

            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                        <h2 className="Titulo Escritorio"><em>Romeo y Julieta,</em> la obra eterna</h2>
                        <h2 className="Titulo Tablet"><em>Romeo y Julieta,</em> <br/>la obra eterna</h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12} className='Obra'>
                        <img className="Escritorio" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion12/3.png" alt="Tarjeta" />
                        <img className="Tablet Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion12/3R.png" alt="Tarjeta" />
                        <p className='Pos Lectura Escritorio'>
                            William Shakespeare escribió esta obra en 1597. Desde entonces <br/>
                            se representa al menos una vez al año en algún escenario del <br/>
                            mundo. Más de 400 años nos separan de una historia que <br/>
                            continúa fascinándonos.
                        </p>
                        <p className='Pos2 Lectura2 Tablet'>
                            William Shakespeare <br/>
                            escribió esta obra en 1597.  <br/>
                            Desde entonces se <br/>
                            representa al menos una vez<br/>
                            al año en algún escenario
                        </p>
                        <p className='Pos3 Lectura2 Tablet'>
                            del mundo. Más de 400 años nos separan<br/>
                            de una historia que continúa<br/>
                            fascinándonos.
                        </p>
                              
                    </Grid>

                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container >
                        <p className='Lectura'>
                            The Shakespeare’s Globe es un teatro único. Construido en el corazón de 
                            Londres en 1997, es la réplica modernizada del teatro isabelino donde 
                            William Shakespeare montaba sus obras en el siglo XVI. Es un teatro 
                            circular, hecho completamente de madera. Está al aire libre y gran 
                            parte del público se sienta en bancos o se queda de pie durante 
                            toda la representación, pues se intenta recrear la experiencia 
                            de ir al teatro en esa época. Ya que el lugar es un homenaje a 
                            Shakespeare, la mayoría de las obras que se montan allí son 
                            suyas, aunque los directores le agregan a cada representación 
                            toques de actualidad.
                        </p>
                    </Grid>
                    <Grid container justifyContent='center'>
                        <Grid container justifyContent='center'>
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion12/1.png" alt="Tarjeta" />
                        </Grid>
                        <Grid container >
                            <p className="TextImg">
                                Nota cómo son los espacios destinados al público en The Shakespeare’s Globe. ¿Has visto el interior un teatro moderno? ¡Compáralos!
                            </p>
                        </Grid>
                    </Grid>

                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container >
                        <p className='Lectura Lectura-1'>
                            En 2021 se presentó en The Shakespeare’s Globe una nueva versión de 
                            <em> Romeo y Julieta</em>. Este texto, llamado por muchos 
                            <em> la historia de amor más famosa del mundo</em>, presenta a dos 
                            jóvenes que se enamoran sin importar el odio que existe entre sus familias.
                        </p>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container justifyContent="center" className='Lupa'>
                        <img className="Escritorio" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion12/4.png" alt="Tarjeta" />
                        <img className="Pos4" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion12/5.png" alt="Tarjeta" />
                        <img className="Tablet Imagen2" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion12/4R.png" alt="Tarjeta" />
                        <p className='Lectura3 Escritorio'>
                            Para hablar de teatro debemos conocer algunos términos. ¿Qué es <em>isabelino</em>? En el <br/>
                            siglo XVI la poesía y la dramaturgia (el arte de escribir obras de teatro) vivieron en <br/>
                            Inglaterra una época de florecimiento: se producía mucha y muy buena literatura. <br/>
                            Debido a que sucedió durante el reinado de Isabel I, se le llama <em>isabelino</em> a este<br/>
                            teatro, a esta poesía y a todo lo que tenga que ver con ellos. <br/><br/>
                            
                            Entre la gente que hace teatro, <em>montar</em> es el proceso que realizan los actores, el <br/>
                            director y los demás implicados (escenógrafos, musicalizadores, maquillistas, <br/>
                            vestuaristas, productores, entre otros) para preparar la representación de una obra. <br/>
                            El producto final se llama <em>montaje</em> y es la obra tal como se le presenta al público.
                        </p>
                        <p className='Lectura3 Tablet'>
                            Para hablar de teatro debemos conocer algunos términos. <br/>
                            ¿Qué es <em>isabelino</em>? En el siglo XVI la poesía y la dramaturgia <br/>
                            (el arte de escribir obras de teatro) vivieron en Inglaterra <br/>
                            una época de florecimiento: se producía mucha y muy <br/>
                            buena literatura. Debido a que sucedió durante el reinado <br/>
                            de Isabel I, se le llama <em>isabelino</em> a este teatro, a esta poesía <br/>
                            y a todo lo que tenga que ver con ellos. <br/><br/>
                            
                            Entre la gente que hace teatro, <em>montar</em> es el proceso que <br/>
                            realizan los actores, el director y los demás implicados <br/>
                            (escenógrafos, musicalizadores, maquillistas, vestuaristas,<br/>
                            productores, entre otros) para preparar la representación <br/>
                            de una obra. El producto final se llama <em>montaje</em> y es la obra <br/>
                            tal como se le presenta al público.
                        </p>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container >
                        <p className='Lectura'>
                            En la versión de 2021 <em>Romeo y Julieta</em> es protagonizada 
                            por dos famosos actores afrodescendientes: Alfred Enoch y Rebekah 
                            Murrell. Todos los personajes hablan y visten como lo haría cualquier 
                            persona en el siglo XXI. El lenguaje ha sido notoriamente 
                            modificado. Los movimientos, la escenografía y la música buscan 
                            hacer la obra tan actual como sea posible. Gran parte del montaje 
                            trata problemas comunes entre los adolescentes de hoy y hace 
                            referencias a elementos de su vida diaria, como las redes sociales.<br/><br/>
                            
                            Aunque es muy joven, la directora, Ola Ince, ya ha recibido premios importantes y ha 
                            dirigido a las más grandes compañías de teatro en el Reino Unido.
                        </p>
                    </Grid>
                    <Grid container justifyContent='center'>
                        <Grid container justifyContent='center'>
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion12/2.png" alt="Tarjeta" />
                        </Grid>
                        <Grid container >
                            <p className="TextImg">
                                En el segundo acto Fray Lorenzo casa a los enamorados. ¡La directora decidió que pudiéramos 
                                reconocer al personaje sólo por la Biblia que sostiene!
                            </p>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container >
                        <p className='Lectura'>
                            Este montaje fue especial también porque The Shakespeare’s Globe transmitió en 
                            vivo todas las funciones desde su página de internet. Quienes no pudieron 
                            desplazarse hasta el teatro también tuvieron acceso a esta versión moderna, 
                            en donde se cuestiona la diferencia de clases y la discriminación, 
                            problemas que afectan a miles de personas en todo el mundo.<br/><br/>

                            La historia de <em>Romeo y Julieta</em> ha vivido muchos cambios y adaptaciones. 
                            Ha llegado hasta libros para niños y películas de Hollywood. Esto nos dice que 
                            hay algo en ella que le fascina al público mundial y, quizá, especialmente al 
                            público inglés, que ama ver nuevas versiones de su dramaturgo más importante. 
                            Aun así, quedan preguntas en el aire: ¿<em>Romeo y Julieta</em> sigue siendo la misma 
                            obra cuando se modifica la mayor parte del texto? ¿Podríamos decir que 
                            todavía es la obra de Shakespeare? <br/><br/>
                            
                            A este montaje en The Shakespeare’s Globe seguramente le seguirán otros 
                            muy pronto. Las escuelas y las compañías de teatro siempre intentan adaptar 
                            esta historia a su tiempo y a su realidad. ¿Por qué será que, para hablar de 
                            temas modernos, adaptan una obra clásica en lugar de montar una nueva? 
                            ¡Te invitamos a reflexionarlo!
                        </p>
                    </Grid>
                </Grid>

                <Grid container item xs={12} justifyContent="center" className="ContainerDerechos">
                    <p className="Derechos">
                        © 2022. Todos los derechos reservados, Pearson, Circuito de lectores.
                    </p>                
                </Grid>
        </Grid>
    </Grid>
    );
}
 
export default Romeo_y_Julieta_la_obra_eterna;
