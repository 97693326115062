import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
    ContenedorLectura:{
        width:'502px',
                  [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
    },
    ContainerTitulo:{
        margin:'0 0 34px 0',
        '& .Titulo':{
            letterSpacing: '0px',
            color: '#D63390',
            margin:'0',
            textAlign:'center',
            font:'43px fredoka one',
            lineHeight:'52px',
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            margin:'0 0 36px 0',
            color:'#000',
            font:'18px lato',
            lineHeight :'28px',
            letterSpacing: '0.17px',
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .SubTitulo':{
            color:'#591AAF',
            margin:'0',
            lineHeight :'28px',
            font:'bold 26px lato',
            letterSpacing: '0.24px',
            [theme.breakpoints.down(502)]:{
                font:'bold 18px lato',
                lineHeight :'24px',
                letterSpacing: '0.21px',
            },
        },
        '& .Cuadro':{
            position:'relative',
        },
        '& .Esp':{
            margin:'0',
            letterSpacing: '0.24px!important',
        },
        '& .Esp2':{
            position:'absolute',
            margin:'53px 0 0 0',
            width:'85.25%',
            [theme.breakpoints.down(502)]:{
                margin:'13px 0 0 0',
            },
        },
        '& .ul':{
            margin:'0',
            padding:'0 0 0 19px',
            color:'#591AAF',
            [theme.breakpoints.down(502)]:{
                padding:'0 0 0 15px',
            },
        },
        '& .li':{
            margin:'0',
            padding:'0',
        },
        

        '& .TextImg':{
            color:'#717476',
            margin:'21px 0 0 0',
            font:' 16px lato',
            lineHeight :'25px',
            letterSpacing: '0.15px',
            [theme.breakpoints.down(502)]:{
                font:' 11px lato',
                lineHeight :'14px',
                letterSpacing: '0.10px',
            },
        },
        '& .Imagen':{
            width:'100%',
        },  
      
    }
}));


const Las_abejas_y_la_polinización = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >¿Para qué sirve <br/>un museo?</h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img3ro/Sesion05/1.png" alt="Tarjeta" />
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container sm={12}>
                      <p className="Lectura Esp"> 
                        Los museos tienen el propósito de conservar, seleccionar y exhibir objetos para que 
                        el público aprenda, estudie y disfrute del conocimiento y la belleza. Es importante
                        señalar que son lugares en los que se exponen piezas artísticas, científicas e 
                        históricas.<br/><br/> 
                         
                         <h3 className='SubTitulo'>Datos importantes</h3><br/>
                        
                        El primer museo moderno fue creado entre 1512 y 1543 en una población del norte 
                        de Italia. Se llamó Museo Gioviano, en nombre de Paolo Giovio, quien exhibió 500 
                        retratos de los hombres más famosos de la historia. Cada pintura aparecía 
                        acompañada de un texto que explicaba la importancia de estos individuos para la 
                        humanidad. Se puede decir que Paolo Giovio utilizó las <em>fichas técnicas</em> por primera vez.
                      </p>
                    </Grid>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container item justifyContent='center' sm={12} className="Cuadro">
                      <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img3ro/Sesion05/2R.png" alt="Tarjeta" />
                      <p className="Lectura Esp2">
                        Las <b>fichas técnicas</b> son notas generales sobre una pintura, una película o 
                        incluso un álbum de música. Estas fichas deben mencionar el nombre del 
                        autor, el título, el año y una breve descripción de la obra.
                      </p>
                    </Grid>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container sm={12}>
                      <p className="Lectura "> 
                        Los edificios o construcciones donde se encuentran los museos son diseñados, en su 
                        mayoría, por arquitectos muy creativos, y con el paso del tiempo se han convertido 
                        en un verdadero arte, ¿conoces alguno? A continuación, se enlistan algunos museos 
                        famosos de Iberoamérica, ya sea por su diseño o por las obras que exhiben:
                      </p>
                      <ul className='ul'>
                            <li className='li'><p className='Lectura'> <b>Museo Nacional de Antropología (Ciudad de México, México)</b> </p></li>
                            <p className='Lectura'>
                                Este museo fue diseñado por Pedro Ramírez Vázquez en 1964. En él se muestran 
                                piezas importantes para comprender las culturas mesoamericanas que habitaron el 
                                territorio mexicano antes de la conquista de los españoles. Es muy famoso por 
                                su enorme fuente en forma de paraguas.
                            </p> 
                            <li className='li'><p className='Lectura'> <b>Museo Guggenheim Bilbao (Bilbao, España)</b> </p></li>
                            <p className='Lectura'>
                                Este espacio fue diseñado por Franz Gehry en 1997. Tiene el objetivo de conservar 
                                y dar a conocer las obras de arte contemporáneo más importantes desde la 
                                segunda mitad del siglo <span style={{fontVariant: "all-small-caps"}}>XX</span> hasta nuestros días. Parece sacado de otro planeta por 
                                su recubrimiento metálico y las curvas de sus paredes.

                            </p> 
                      </ul>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <Grid container item justifyContent='center'>
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img3ro/Sesion05/3.png" alt="Tarjeta" />
                        </Grid>
                        <Grid container item justifyContent='center'>
                            <p className='TextImg'>Museo Guggenheim Bilbao</p>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container sm={12}>
                      <ul className='ul'>
                            <li className='li'><p className='Lectura'> <b>Museo de Arte Latinoamericano de Buenos Aires (Buenos Aires, Argentina)</b> </p></li>
                            <p className='Lectura'>
                                Este lugar fue diseñado por Gastón Atelman, Martín Fourcade y Alfredo Tapia en 
                                2001. Cuenta con una gran colección de los artistas latinoamericanos más 
                                sobresalientes de los siglos <span style={{fontVariant: "all-small-caps"}}>XX</span> y <span style={{fontVariant: "all-small-caps"}}>XXI</span>. Por fuera parece una enorme fortaleza de 
                                piedra.

                            </p> 
                            <li className='li'><p className='Lectura'> <b>Museo del Mañana (Río de Janeiro, Brasil) </b> </p></li>
                            <p className='Lectura'>
                                Este museo fue diseñado por Santiago Calatrava en 2015. Combina la ciencia y el 
                                diseño para transmitir un mensaje sobre el cuidado de nuestro planeta. Su principal 
                                objetivo es mejorar el futuro. Su forma está inspirada en los árboles de la selva.
                            </p> 
                      </ul>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <Grid container item justifyContent='center'>
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img3ro/Sesion05/4.png" alt="Tarjeta" />
                        </Grid>
                        <Grid container item justifyContent='center'>
                            <p className='TextImg'>Museo del Mañana</p>
                        </Grid>
                    </Grid>
                </Grid>
        </Grid>
    </Grid>
    );
}
 
export default Las_abejas_y_la_polinización;
