import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
    ContenedorLectura:{
        width:'502px',
        [theme.breakpoints.down(502)]:{
            width:'350px',
        },
        '& .Lectura':{
            margin:'0 0 36px 0 ',
            color:'#000',
            font:'18px lato',
            letterSpacing:'.17px',
            lineHeight:'28px',
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .SubTitulo':{
            margin:'0',
            color:'#823CE2',
            font:'bold 26px lato',
            letterSpacing:'.24px',
            lineHeight:'28px',
            [theme.breakpoints.down(502)]:{
                font:'bold 18px lato',
                lineHeight :'24px',
                letterSpacing: '0.21px',
            },
        },
        '& .Imagen':{
            width:'100%',
        },

    },
    ContainerTitulo:{
        margin:'0 0 38px 0',
        '& .Titulo':{
            letterSpacing: '0px',
            color: '#FC5E9E',
            margin:'0',
            font:'43px fredoka one',
            lineHeight:'52px',
            textAlign:'center',
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        position:'relative',
        '& .Pos':{
            position:'absolute',
            left:'0',
        },
        '& .L':{
            top:'30px',
        },
        '& .G':{
            top:'127px',
            [theme.breakpoints.down(502)]:{
                top:'100px',
                },
            
        },
        '& .B':{
            top:'221px',
            [theme.breakpoints.down(502)]:{
                top:'175px',
            },
        },
        '& .T':{
            top:'315px',
            [theme.breakpoints.down(502)]:{
                top:'250px',
            },
        },
        '& .I':{
            top:'549px',
            [theme.breakpoints.down(502)]:{
                top:'407px',
            },
        },
        '& .M':{
            top:'671px',
            [theme.breakpoints.down(502)]:{
                top:'502px',
            },
        },
        '& .Lectura2':{
            margin:'0',
            left:'80.7px',
            font:'18px lato',
            color:'#575757',
            letterSpacing:'.17px',
            lineHeight:'28px',
            [theme.breakpoints.down(502)]:{
                font:'14px lato',
                lineHeight :'18px',
                letterSpacing: '0.09px',
                left:'54px',

            },
        },
        '& .L1':{
            top:'33px',
            [theme.breakpoints.down(502)]:{
                top:'24px',
            },
        },
        '& .G1':{
            top:'127px',
            [theme.breakpoints.down(502)]:{
                top:'96px',
            },
        },
        '& .B1':{
            top:'221px',
            [theme.breakpoints.down(502)]:{
                top:'171px',
            },
        },
        '& .T1':{
            top:'315px',
            [theme.breakpoints.down(502)]:{
                top:'245px',
            },
        },
        '& .I1':{
            top:'549px',
            [theme.breakpoints.down(502)]:{
                top:'404px',
            },
        },
        '& .M1':{
            top:'671px',
            [theme.breakpoints.down(502)]:{
                top:'503px',
            },
        },
        '& .Genero':{
            width:'37px',
            font:'56px Fredoka One',
            color:'#FFFFFF',
            margin:'0',
            left:'10px',
            textAlign:'center',
            [theme.breakpoints.down(502)]:{
                font:'36px Fredoka One',
                left:'4px',
            },
        },
        '& .M2':{
            top:'665px',
            [theme.breakpoints.down(502)]:{
                top:'505px',
            },
        },
        '& .Circle':{
            [theme.breakpoints.down(502)]:{
                width:'45px',
            },
        },


    },
    Parrafo2:{
        margin:' 0 0 20px 0',
    },
    
    

}));

const Más_allá_de_las_etiquetas_está_la_diversidad = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo " >
                        Más allá de las <br/> 
                        etiquetas está la diversidad
                    </h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura ">
                            Seguramente has escuchado hablar sobre la comunidad <span style={{fontVariant: "all-small-caps"}}>LGBTI+</span>,  pero ¿sabes qué 
                            significan estas letras y por qué es importante reconocer a quiénes representan y sus 
                            características? <br/><br/>
                            
                            Para explicarlo será necesario que primero aprendas sobre tres conceptos que, 
                            aunque están relacionados, se refieren a cosas muy diferentes: <em>sexo</em>, <em>género</em> y 
                            <em> orientación sexual</em>.<br/><br/>
                            
                            <h3 className="SubTitulo"> Sexo</h3><br/>

                            Este concepto se refiere al conjunto de características biológicas determinadas 
                            desde la concepción de las especies animales, como la nuestra, que distingue a las 
                            hembras y a los machos. Ejemplo de ello son los genitales y la información genética, 
                            que es el <span style={{fontVariant: "all-small-caps"}}>ADN</span> que se encuentra en las células, responsable de determinar rasgos 
                            físicos como color de ojos, estatura, el desarrollo de las glándulas mamarias en las 
                            mujeres o el crecimiento de barba en los hombres.
                        </p>
                    </Grid>
                    <Grid container  sm={12}>
                        <Grid container  >
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion24/1.png" alt="Tarjeta" />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo2} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura ">
                            <h3 className='SubTitulo'>Género</h3><br/>

                            Esta definición es un poco más compleja, pues no depende de rasgos físicos que se 
                            puedan identificar con facilidad. Más bien es un conjunto de etiquetas o cualidades 
                            con las que la sociedad ha determinado la manera en la que se deben comportar las 
                            mujeres y los hombres. <br/><br/>
                            
                            Estas imposiciones sociales han dictado a lo largo de la historia que los hombres 
                            deberían ser fuertes, protectores, valientes, racionales y heterosexuales, además de 
                            que deberían ser ellos quienes resuelvan los problemas, sólo por mencionar algunas 
                            características. Por su parte, se espera que las mujeres sean delgadas pero 
                            voluptuosas, sumisas, delicadas, emocionales, que se dediquen a la crianza de los 
                            niños y que también sean heterosexuales. <br/><br/>
                            
                            De este modo, si un hombre quisiera usar falda o una mujer decidiera no depilarse las 
                            axilas, serían fuertemente juzgados por no cumplir con las expectativas de género 
                            que se han establecido para uno y otra. La idea de género, entonces, sólo es una 
                            construcción social. Es importante notar que, como se relaciona con el sexo con 
                            frecuencia, es fácil llegar a confundir ambos conceptos.<br/><br/>
                            
                            Como podrás imaginarte, hay muchas personas que no se sienten identificadas con 
                            estas exigencias de comportamiento, por lo que han decidido crear distintas maneras 
                            de expresarse, lejanas a lo que históricamente ha dictado la sociedad para su género. <br/><br/>
                            
                            <h3 className='SubTitulo'>Orientación sexual</h3> <br/>
                            
                            Este concepto se refiere al tipo de características sexuales hacia las cuales una 
                            persona se siente atraída física y emocionalmente. Antes se creía que lo más 
                            “natural” era que la gente sintiera atracción por el sexo opuesto, pues se pensaba que 
                            el propósito más importante de la unión entre dos personas era la reproducción. <br/><br/>
                            
                            En la actualidad poco a poco se ha logrado erradicar esa visión, pues la reproducción 
                            no siempre es el fin último de las relaciones entre personas. Existen factores como el 
                            amor, la atracción y la afinidad entre personas que dependen de las preferencias de 
                            cada uno. <br/><br/>
                            
                            <h3 className='SubTitulo'>Explicar <span style={{fontVariant: "all-small-caps"}}>LGBTI+</span></h3><br/>
                            
                            Ahora que conoces los conceptos anteriores ya puedes entender mejor las letras.
                        </p>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="flex-end">
                    <img className="Imagen " src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion24/3R.png" alt="Tarjeta" />
                    <img className="Pos L Circle" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion24/4.png" alt="Tarjeta" />
                    <img className="Pos G Circle" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion24/5.png" alt="Tarjeta" />
                    <img className="Pos B Circle" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion24/6.png" alt="Tarjeta" />
                    <img className="Pos T Circle" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion24/7.png" alt="Tarjeta" />
                    <img className="Pos I Circle" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion24/8.png" alt="Tarjeta" />
                    <img className="Pos M Circle" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion24/9.png" alt="Tarjeta" />

                    <p className='Pos Genero L'>L</p>
                    <p className='Pos Genero G'>G</p>
                    <p className='Pos Genero B'>B</p>
                    <p className='Pos Genero T'>T</p>
                    <p className='Pos Genero I'>I</p>
                    <p className='Pos Genero M2'>+</p>

                    <p className='Pos Lectura2 L1'>
                        es por <em>lesbiana</em>, una persona cuyo sexo es <br/>
                        femenino y su orientación sexual es hacia <br/>
                        personas de sexo femenino.
                    </p>
                    <p className='Pos Lectura2 G1'>
                        es por <em>gay</em>, una persona cuyo sexo es  <br/>
                        masculino y su orientación sexual también <br/>
                        es hacia otras personas de sexo masculino.
                    </p>
                    <p className='Pos Lectura2 B1'>
                        es por <em>bisexual</em>, una persona que se siente <br/>
                        igualmente atraída hacia personas del sexo<br/>
                        femenino y masculino.
                    </p>
                    <p className='Pos Lectura2 T1'>
                        es por las personas <em>transgénero</em>, es decir,  <br/>
                        aquellas que no se identifican con el género <br/>
                        que tradicionalmente se ha asignado al sexo  <br/>
                        con el que nacieron.  Derivado de su sentir, <br/>
                        deciden vestirse como tradicionalmente lo <br/>
                        hacen las personas del sexo opuesto <br/>
                        (travestis) o incluso deciden operarse para <br/>
                        cambiar de sexo (transexual).
                    </p>
                    <p className='Pos Lectura2 I1'>
                        hace referencia a los <em>intersexuales</em>, personas  <br/>
                        que nacieron con variaciones biológicas, es <br/>
                        decir, podrían tener ambos tipos de órganos <br/>
                        reproductivos.
                    </p>
                    <p className='Pos Lectura2 M1'>
                        se utiliza para incluir a otras poblaciones  <br/>
                        que no entren en estas categorías, pero que  <br/>
                        es importante considerar, pues la <br/>
                        sexualidad es mucho más flexible de lo que <br/>
                        la sociedad quiere dictar.
                    </p>


                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                    <p className="Lectura ">
                            Lo único que esta amplia comunidad desea es el respeto y la aceptación de la 
                            sociedad para vivir su vida de la mejor manera posible: la que han elegido. Un mundo 
                            capaz de abrazar la diversidad será un mundo en el que todas las personas puedan 
                            vivir sin miedo a ser juzgadas y en armonía. Ése es el mundo que merecemos y por él 
                            que tenemos que trabajar en conjunto. El amor y el entendimiento van más allá de 
                            cualquier etiqueta.
                        </p>
                    </Grid>
                    <Grid container sm={12}>
                        <Grid container >
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion24/2.png" alt="Tarjeta" />
                        </Grid>
                    </Grid>
                </Grid>
        </Grid>
    </Grid>
    );
}
 
export default Más_allá_de_las_etiquetas_está_la_diversidad;
