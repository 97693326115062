import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
     Contenedor:{
        background:'#E9FBF9',
        width:'100vw',
        overflowX:'clip',
        [theme.breakpoints.down(1161)]:{
            background:'none',
           overflowX:'unset',
        },
        [theme.breakpoints.down(600)]:{
            marginTop: '79.51px',
        }
    },
  ContainerInfoLectura: {
    position: "relative",
    height: "86px",
    [theme.breakpoints.down(502)]:{
        height: "43px",
    },
    background: "#76D4C3",
    "& .BarraInfoLectura": {
      height: "86px",
      background: `Url(https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Barras/Azul.png)`,
        [theme.breakpoints.down(502)]:{
            height: "43px",
        },
    },
    "& .InfoLectura": {
      position: "absolute",
      height: "86px",
      width: "219px",
      boxSizing: "border-box",
      padding: "17.2px 0",
      background: "#FAFAFA",
      borderRadius: "50px",
      boxShadow: "2px 2px 2px 2px #00000029",
      zIndex: "4",
        [theme.breakpoints.down(502)]:{
            height: "43px",
            width: "109px",
            padding: "8.6px 0",
            borderRadius: "25px",
        },
    },
    "& .InfoLectura:before": {
      position: "absolute",
      display: "flex",
      boxSizing: "border-box",
      padding: "0 0 0 10px",
      justifyContent: "center",
      alignItems: "center",
      height: "39.88px",
      width: "39.88px",
      borderRadius: "50%",
      content: '""',
      boxShadow: "3px 0px .5px 0px #00000029",
      background: "#FAFAFA",
      left: "90px",
      top: "65px",
      transform: "rotate(0.25turn)",
      [theme.breakpoints.down(502)]:{
        left: "45px",
        top: "30px",
        height: "20.88px",
        width: "20.88px",
        },
    },
    "& .InfoLectura:after": {
      content: '""',
      height: "15px",
      width: "15px",
      borderTop: "2px solid #20A698",
      borderLeft: "2px solid #20A698",
      position: "absolute",
      bottom: "-10px",
      left: "Calc(50% - 8px)",
      transform: "rotate(0.625turn)",
      [theme.breakpoints.down(502)]:{
        height: "10px",
        width: "10px",
        left: "Calc(50% - 4.5px)",
        bottom: "-3px",
        borderTop: "1px solid #20A698",
        borderLeft: "1px solid #20A698",
        },
    },
    "& .Sesion": {
      borderLeft: "2px solid #D8D8D8",
    },
    "& .Dato": {
      margin: "0 0 5px 0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#20A698",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        margin: "0 0 2.5px 0",
        },
    },
    "& .Caracter": {
      margin: "0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#000000",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        },
    },   },
    ContenedorLectura:{
        background:'#fff',
        width:'1160px',
        height:'100%',
        padding:'60px 168px ',
        [theme.breakpoints.down(1161)]:{
            width:'502px',
            padding:'60px 0 0 0',
          },
        [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
        '& .ContainerDerechos':{
            bottom:'80px',
            [theme.breakpoints.down(1161)]:{
                display:'none',
            },
        },
        '& .Derechos':{
            textAlign:"center",
            margin:"0px",
            font:" 15px lato",
            color:"#717476",
        },
        '& .Escritorio':{
            display:'block',
            [theme.breakpoints.down(1161)]:{
                display:'none'
            },
        },
        '& .Tablet':{
            display:'none',
            [theme.breakpoints.down(1161)]:{
                display:'block'
            },
        },
    },
    ContainerTitulo:{
        margin:'0 0 46px 0',
        '& .Titulo':{
            letterSpacing: '0px',
            lineHeight:'58px',
            color: '#D7694D',
            margin:'0',
            textAlign:'center',
            font:'48px fredoka one',
            [theme.breakpoints.down(1161)]:{
                font:'43px fredoka one',
                lineHeight:'52px',
            },
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            margin:'0 0 36px 0',
            color:'#000',
            font:'22px lato',
            lineHeight : '30px',
            letterSpacing: '0.20px',
            [theme.breakpoints.down(1161)]:{
                font:'18px lato',
                lineHeight :'28px',
                letterSpacing: '0.17px',
            },
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .SubTitulo':{
            color:'#628D00',
            margin:'0',
            font:'bold 30px lato',
            letterSpacing:'.28px',
            lineHeight:'30px',
            [theme.breakpoints.down(1161)]:{
                font:'bold 26px lato',
                lineHeight :'28px',
                letterSpacing: '0.24px',
            },
            [theme.breakpoints.down(502)]:{
                padding:'19px 0 12px 0',
                font:'bold 22px lato',
                lineHeight :'24px',
                letterSpacing: '0.21px',
            },
        },
        "& .SubTitulo2": {
            color: "#3531FF",
            margin: "0",
            font: "bold 26px lato",
            letterSpacing: ".24px",
            lineHeight: "28px",
            [theme.breakpoints.down(1161)]: {
              font: "bold 22px lato",
              lineHeight: "24px",
              letterSpacing: "0.21px",
            },
            [theme.breakpoints.down(502)]: {
              font: "bold 18px lato",
              lineHeight: "20px",
              letterSpacing: "0.16px",
            },
        },
        '& .Imagen':{
            [theme.breakpoints.down(1161)]:{
                width:'100%',
            },
        },  
    },
}));

const La_libertad_de_expresión = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
             <Grid container item justifyContent="center" className={classes.ContainerInfoLectura}>
               <Grid container className="BarraInfoLectura"></Grid>
               <Grid container item className="InfoLectura">
                    <Grid container item direction="column" xs={6} justifyContent="center">
                        <p className="Dato">Grado</p>
                        <p className="Caracter">3</p>
                    </Grid>
                    <Grid container item direction="column" xs={6} justifyContent="center" className="Sesion">
                        <p className="Dato">Sesión</p>
                        <p className="Caracter">40</p>
                    </Grid>
               </Grid>
            </Grid>

            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >
                        La libertad de expresión
                    </h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container xs={12} >
                      <p className="Lectura ">
                        La libertad de expresión es el derecho que tiene una persona a hablar de sus ideas sin 
                        ser castigada por ello. La Declaración Universal de los Derechos Humanos establece 
                        que los individuos pueden comunicar sus opiniones e investigar sobre cualquier tema 
                        que les interese, sin que ello signifique un motivo para que sean perseguidos o 
                        castigados. También gozan de la posibilidad de hablar sobre sus ideas con quienes 
                        deseen, en los lugares que consideren adecuados y con los medios de comunicación 
                        que prefieran.
                      </p>
                    </Grid>
                    <Grid container xs={12} >
                        <Grid container justifyContent="center" >
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img3ro/Sesion40/1.png" alt="Tarjeta" />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container xs={12} >
                      <p className="Lectura ">
                        
                        <h3 className='SubTitulo'>El derecho a informar</h3><br/>
                         La libertad de expresión es un derecho, pero es ejercido de manera diferente en los 
                         distintos países, pues los gobiernos de cada uno determinan cuánta independencia 
                         conceden a sus habitantes y medios de difusión. La posibilidad de mantener 
                         informada a una nación es uno de los principales factores que permite determinar si 
                         se respeta este derecho en un país. Se plantean varias preguntas para establecerlo. 
                         Por ejemplo: ¿los periodistas pueden explicar cualquier acontecimiento que esté 
                         ocurriendo? ¿Tienen la oportunidad de decir la verdad y no ocultar datos 
                         importantes? ¿Pueden investigar cualquier cuestión y dar a conocer los resultados de 
                         sus investigaciones? Si la respuesta es sí, entonces se puede afirmar que dicha 
                         sociedad garantiza la libre expresión. <br/><br/>
                         
                         Existe una organización no gubernamental llamada Reporteros sin fronteras que 
                         cada año estudia la libertad de prensa en todo el mundo. Lo hace a través de las 
                         preguntas anteriores y considera varios factores más. Después, clasifica a las 
                         naciones y da a conocer sus análisis. Según el informe de 2021, Noruega, Finlandia y 
                         Suecia respetan más esta situación, mientras que Eritrea, Corea del Norte y 
                         Turkmenistán son las regiones que menos la promueven. <br/><br/>
                         
                         <h3 className='SubTitulo2'>¿Tiene límites?</h3><br/>
                        
                        Como ves, expresar ideas y pensamientos es un derecho que todos los seres 
                        humanos tienen durante su vida. Pero hay un asunto importante que tiene que 
                        recordarse siempre: no lastimar a nadie al expresar alguna idea. A veces, se pierde de 
                        vista que no todas las personas piensan o sienten de la misma manera. <br/><br/>

                        Es fundamental cuidar que nuestras palabras no afecten a nadie, pues no es correcto 
                        discriminar a ningún individuo por su género, religión u orientación política. Tenemos
                        que aprender a distinguir entre una perspectiva bien explicada y una ofensa, pues las 
                        agresiones que causan los comentarios no están justificadas por la libertad de 
                        expresión. Toda visión es importante y merece ser difundida, escuchada y apreciada, 
                        pero también está sujeta al análisis de los demás. Ten presente que todos forman 
                        parte de la sociedad y merecen recibir respeto e inclusión en ella.

                      </p>
                    </Grid>
                    <Grid container xs={12} >
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img3ro/Sesion40/2.png" alt="Tarjeta" />
                    </Grid>
                </Grid>

                <Grid container item xs={12} justifyContent="center" className="ContainerDerechos">
                    <p className="Derechos">
                        © 2022. Todos los derechos reservados, Pearson, Circuito de lectores.
                    </p>                
                </Grid>
        </Grid>
    </Grid>
    );
}
 
export default La_libertad_de_expresión;
