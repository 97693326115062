import { Button, Grid, makeStyles } from "@material-ui/core";
import React, { useContext, useEffect } from "react";
import DrawerContext from "../../../../../usability/DrawerContext";
import { useOnLoadImages } from "../../../../hooks/useOnLoadImages";
import Loader from "../../../../Loader/Loader";
import Drawer from "../../ReadingTools/Drawer";



const useStyles =  makeStyles (theme =>({
     Contenedor:{
        background:'#E9FBF9',
        width:'100vw',
        overflowX:'clip',
        [theme.breakpoints.down(1161)]:{
            background:'none',
           overflowX:'unset',
        },
        [theme.breakpoints.down(600)]:{
            marginTop: '79.51px',
        }
    },
  ContainerInfoLectura: {
    position: "relative",
    height: "86px",
    [theme.breakpoints.down(502)]:{
        height: "43px",
    },
    background: "#76D4C3",
    "& .BarraInfoLectura": {
      height: "86px",
      background: `Url(https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Barras/Azul.png)`,
        [theme.breakpoints.down(502)]:{
            height: "43px",
        },
    },
    "& .InfoLectura": {
      position: "absolute",
      height: "86px",
      width: "219px",
      boxSizing: "border-box",
      padding: "17.2px 0",
      background: "#FAFAFA",
      borderRadius: "50px",
      boxShadow: "2px 2px 2px 2px #00000029",
      zIndex: "4",
        [theme.breakpoints.down(502)]:{
            height: "43px",
            width: "109px",
            padding: "8.6px 0",
            borderRadius: "25px",
        },
    },
    "& .InfoLectura:before": {
      position: "absolute",
      display: "flex",
      boxSizing: "border-box",
      padding: "0 0 0 10px",
      justifyContent: "center",
      alignItems: "center",
      height: "39.88px",
      width: "39.88px",
      borderRadius: "50%",
      content: '""',
      boxShadow: "3px 0px .5px 0px #00000029",
      background: "#FAFAFA",
      left: "90px",
      top: "65px",
      transform: "rotate(0.25turn)",
      [theme.breakpoints.down(502)]:{
        left: "45px",
        top: "30px",
        height: "20.88px",
        width: "20.88px",
        },
    },
    "& .InfoLectura:after": {
      content: '""',
      height: "15px",
      width: "15px",
      borderTop: "2px solid #20A698",
      borderLeft: "2px solid #20A698",
      position: "absolute",
      bottom: "-10px",
      left: "Calc(50% - 8px)",
      transform: "rotate(0.625turn)",
      [theme.breakpoints.down(502)]:{
        height: "10px",
        width: "10px",
        left: "Calc(50% - 4.5px)",
        bottom: "-3px",
        borderTop: "1px solid #20A698",
        borderLeft: "1px solid #20A698",
        },
    },
    "& .Sesion": {
      borderLeft: "2px solid #D8D8D8",
    },
    "& .Dato": {
      margin: "0 0 5px 0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#20A698",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        margin: "0 0 2.5px 0",
        },
    },
    "& .Caracter": {
      margin: "0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#000000",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        },
    },
       '& .Regresar':{
        color: '#F2624F',
        border: '#F2624F 1px solid',
        height: '30px',
        width:'100px',
        borderRadius: '20px',
        letterSpacing: '0.14px',
        textTransform: 'none',
        background:'#FEFAF1',
        top:'calc(50% - 15px)',
        left:'5%',
        zIndex:4,
        [theme.breakpoints.down(502)]: {
          font:'bold 10px lato',
          width: "60px",
        },
      },
  },
    ContenedorLectura:{
        background:'#fff',
        width:'1160px',
        height:'100%',
                padding:'60px 168px 35px ',
        
        transform: (props) =>
        props.scale > 1 ? `scale(${props.scale}) translate(0px, 10%)` : `none`,
        [theme.breakpoints.down(1161)]:{
            width:'502px',
            padding:'60px 0 0 0',
          },
          [theme.breakpoints.down(768)]:{
            width:'350px',
            padding:'60px 0 0 0',
          },
        '& .ContainerDerechos':{
            bottom:'80px',
            [theme.breakpoints.down(1161)]:{
                display:'none',
            },
        },
        '& .Derechos':{
            textAlign:"center",
            margin:"36px 0 0 0",
            font:" 15px lato",
            color:"#717476",
        },
        '& .Escritorio':{
            display:'block',
            [theme.breakpoints.down(1161)]:{
                display:'none'
            },
        },
        '& .Tablet':{
            display:'none',
            [theme.breakpoints.down(1161)]:{
                display:'block'
            },
        }
    },
    ContainerTitulo:{
        margin:'0 0 34px 0',
        [theme.breakpoints.down(1161)]:{
            margin:'0 0 34px 0',
        },
        '& .Titulo':{
            font:'48px fredoka one',
            letterSpacing: '0px',
            lineHeight:'58px',
            color: '#FF2B2B',
            margin:'0 0 5px 0',
            textAlign:'center',
            [theme.breakpoints.down(1161)]:{
                font:'43px fredoka one',
                lineHeight:'52px',
            },
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
        '& .Titulo2':{
            font:'bold 30px lato',
            letterSpacing: '.28px',
            lineHeight:'30px',
            color: '#FF2B2B',
            margin:'0',
            textAlign:'center',
            [theme.breakpoints.down(1161)]:{
                font:'bold 26px lato',
                letterSpacing: '.24px',
                lineHeight:'28px',
            },
            [theme.breakpoints.down(502)]:{
                font:'bold 22px lato',
                lineHeight:'24px',
            },
            [theme.breakpoints.down(350)]:{
                font:'bold 18px lato',
                lineHeight:'24px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 ',
        position:'relative',
        '& .Lectura':{
            lineHeight : '30px',
            margin:'0',
            font:'22px lato',
            color:'#000',
            letterSpacing: '0.20px',
            [theme.breakpoints.down(1161)]:{
                font:'18px lato',
                lineHeight :'28px',
                letterSpacing: '0.17px',
            },
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .Lectura2':{
            position:'absolute',
            width:'428px',
            [theme.breakpoints.down(1161)]:{
                margin:'0 0 36px 0',
                position:'relative',
                width:'100%',
            },
        },
        '& .Titulo3':{
            font:'bold 30px lato',
            letterSpacing: '.28px',
            lineHeight:'30px',
            color: '#00ADB6',
            margin:'0 0 47px 0',
            textAlign:'center',
            [theme.breakpoints.down(1161)]:{
                font:'bold 26px lato',
                letterSpacing: '.24px',
                lineHeight:'28px',
            },
            [theme.breakpoints.down(502)]:{
                font:'bold 22px lato',
                lineHeight:'24px',
            },
            [theme.breakpoints.down(350)]:{
                font:'bold 18px lato',
                lineHeight:'24px',
            },
        },
        '& .ContainerImagen':{
            zIndex:0,
            [theme.breakpoints.down(768)]:{
                width:'100%',
            },
        }, 
        '& .Imagen':{
            [theme.breakpoints.down(768)]:{
                width:'100%',
            },
        } 

      
    }
}));

const Pablo_y_el_intraterrestre_Cap_7 = (props) => {

  const {activeStep, setActiveStep, handlePreviewStep, id_actividad} = props
  
  const handleContinue = () => {
    setActiveStep(activeStep - 1)
    handlePreviewStep()
  }

    const {
      handleSize,
    disabledZoom,
    closeZoom,
    handleZoom,
    scale
  }= useContext(DrawerContext);
  const [loading, setLoading] = React.useState(true);
  const wrapperRef = React.useRef(null);
  const imagesLoaded = useOnLoadImages(wrapperRef);

  const detectSize = () => {
    const lectura = document.getElementById("lectura");
    const newWidth = lectura?.offsetWidth;
    const newHeight = lectura?.offsetHeight;
    setLoading(false);
    if (lectura) handleSize(newHeight, newWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", detectSize);
    return () => {
      handleZoom()
      window.removeEventListener("resize", detectSize);
      closeZoom()
    };
  }, []);

  const classes = useStyles({ disabledZoom,scale });
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
             <Grid container item justifyContent="center" className={classes.ContainerInfoLectura}>
                       <Grid container className="BarraInfoLectura">
            <Button className="Regresar" onClick={handleContinue}>
                Regresar
            </Button>
        </Grid>
               <Grid container item className="InfoLectura">
                    <Grid container item direction="column" xs={6} justifyContent="center">
                        <p className="Dato">Grado</p>
                        <p className="Caracter">5</p>
                    </Grid>
                    <Grid container item direction="column" xs={6} justifyContent="center" className="Sesion">
                        <p className="Dato">Sesión</p>
                        <p className="Caracter">31</p>
                    </Grid>
               </Grid>
            </Grid>
            {!imagesLoaded && <Loader />}
        <Grid
            container
            item
            className={classes.ContenedorLectura}
            justifyContent="center"
            id="lectura"
            onLoad={detectSize}
            ref={wrapperRef}
        >
<Drawer id_lectura={id_actividad} />
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                        <h2 className="Titulo">Pablo y el intraterrestre</h2>
                        <h3 className='Titulo2'>Fragmento 5 (capítulo 7)</h3>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                       <p className='Lectura '>
                        Como si hiciera una sentadilla, Pablo se incorporó, saltó y se alzó uno, dos, tres metros… y siguió subiendo. Ángel, Juan Carlos, la directora, don Raúl, miss Laura y algunos niños en las canchas lo miraron boquiabiertos. Ascendía a una velocidad increíble, con la agilidad de una libélula. Para Pablo, el tiempo transcurría mucho más lento de lo normal.
                        <br/><br/>
                        Desde arriba, todos parecían congelados, como una fotografía muy graciosa que Pablo quiso recordar para siempre. La directora perdió el equilibro y cayó sobre don Raúl, quien no logró sostenerla, así que los dos rodaron por el lodo. Miss Laura se quitó los lentes: la curiosidad científica brillaba más que nunca en sus ojos negros. Juan Carlos sonreía, tratando de fingir que ni siquiera eso lo asombraba. Ángel mordía su suéter, angustiado, porque pensaba que Pablo podía caerse en cualquier momento. Más arriba, Pablo vio la ciudad, el camino que unía su casa y la escuela, los jardines, los árboles. Todo parecía una maqueta ordenada y tranquila donde cada pieza encajaba bien.
                        <br/><br/>
                        —¿Vamos a estallar en el aire, Brimlog? La idea es que explote en la estratosfera y así logre salvar a los demás humanos, ¿verdad? Si es así, estoy de acuerdo —dijo Pablo, pensando que al menos moriría como un héroe. 
                        <br/><br/>
                        —Creo que sólo hay una cosa que puede salvar ahora a la humanidad —explicó Brimlog—. Una cosa muy humana que quizá puede detener la ira.
                        <br/><br/>
                        Rápido y lento a la vez, Pablo comenzó a descender, en línea recta, hasta caer frente a Mariana, quien del susto tiró su cigarro al piso. 
                        <br/><br/>
                        —Para ser honestos, Pablo, me hubiera gustado ver más crueldad, más barbarie, más de todas esas cosas que, según contaban, hacían del ser humano la criatura más peligrosa de la Tierra. Pero, en el fondo, ahora creo que la maldad humana es como tú, Pablo: inmadurez, irresponsabilidad… falta de experiencia. Despídete bien, Pablo. Ya no voy a ningún lado, gasté todo el combustible en esto, en que seas sincero con Mariana y le digas las cosas importantes que nunca le has dicho. No lo arruines, por favor, humano Pablo.
                        <br/><br/>
                        Y Brimlog obligó a Pablo a abrazar a su hermana con el resto de su energía.
                        <br/><br/>
                        —¿Qué dices, Brimlog? No puedes quedarte, también vas a estallar. —Pablo ya no sentía que Brimlog estuviera controlándolo—. ¿Brimlog? ¿Brimlog? ¿Brimlog? ¿Brimlog? ¿¿¡BRIMLOG???
                        <br/><br/>
                        ―Brimlog ya no me responde ―dijo Pablo asustado en voz alta mientras abrazaba a Mariana con fuerza.
                        <br/><br/>
                        ―¿De qué hablas? ¿Estás bien? ―Mariana lo estrujaba tan duro que dolía―. ¿Cómo hiciste eso? ¿En realidad te pasó algo, en realidad vas a estallar? Hay que llevarte a un doctor.
                        <br/><br/>
                        Mariana quiso tomar a Pablo para subirlo al coche y llevarlo al doctor, pero Pablo la apretó aún más, obligándola a permanecer en donde estaban.
                        <br/><br/>
                        ―Mariana ―dijo Pablo por voz propia. 
                        <br/><br/>
                        Él sabía que era hora de pedir perdón sin que Brimlog hiciera nada para obligarlo. En ese momento el intraterrestre sólo miraba desde el interior de Pablo, orgulloso y conmovido.
                        <br/><br/>
                        —Sé que no me pellizcaste ayer —confesó Pablo—. Lo sé desde la mañana, pero no dije nada porque no me gusta disculparme, y sí, en el fondo, me dio un poco de gusto que te pegaras. Odiaba tu celular, odio a tu novio, odio que te vayas a la universidad
                        el próximo año para dejarme solo con papá y mamá. No soy tonto, sé que ellos se odian entre sí, sé que la razón por 
                        la que no se divorcian soy yo, eso me enoja conmigo… Me enoja todo el tiempo, todos los días. De verdad no quise lastimar 
                        a Arturo. No soy un <em>bully</em>. Odio la palabra <em>bully</em>. Odio tantas cosas. Hay algo dentro de mí que me controla a veces. No es 
                        Brimlog, el intraterrestre. Es otra cosa que siempre ha estado allí. Rabia, calor, enojo.<br/><br/>

                        ―Todos sentimos esas cosas, Pablo ―dijo Mariana, acariciándole el rostro dulcemente.
                        
                       </p>                   
                    </Grid>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12} >
                    <p className='Lectura Lectura2 Escritorio'>
                    el próximo año para dejarme solo con papá y mamá. No soy tonto, sé que ellos se odian entre sí, sé que la razón por 
                    la que no se divorcian soy yo, eso me enoja conmigo… Me enoja todo el tiempo, todos los días. De verdad no quise lastimar 
                    a Arturo. No soy un <em>bully</em>. Odio la palabra <em>bully</em>. Odio tantas cosas. Hay algo dentro de mí que me controla a veces. No es 
                    Brimlog, el intraterrestre. Es otra cosa que siempre ha estado allí. Rabia, calor, enojo.<br/><br/>

                    ―Todos sentimos esas cosas, Pablo ―dijo Mariana, acariciándole el rostro dulcemente.
                    </p>
                    </Grid>
                    <Grid container justifyContent='center' className='ContainerImagen'>
                        <img className="Imagen Escritorio" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion31/1.png" alt="Tarjeta" />
                        <img className="Imagen Tablet" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion31/1R.png" alt="Tarjeta" />
                    </Grid>
                </Grid>
        </Grid>
        <Grid container item style={{ marginTop: 80}} ></Grid>
    </Grid>
    );
}
 
export default Pablo_y_el_intraterrestre_Cap_7;
