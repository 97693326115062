import { Button, Grid, makeStyles } from "@material-ui/core";
import React from "react";
import BlurContainer from "../../../BlurContainer";

const useStyles = makeStyles((theme) => ({
  Container: {
    height: "100%",
  },

  dialog: {
    bottom: "125%",
    margin: "0 auto",
    backgroundColor: "#3f43b3",
    fontFamily: "Lato",
    letterSpacing: "0.36px",
    fontSize: "20px",
    color: "#ffffff",
    position: "absolute",
    minWidth: "430px",
    width: "auto",
    maxWidth: "460px",
    padding: "24px",
    boxSizing: "border-box",
    borderRadius: "21.25px",

    "&:before": {
      // display: (props) => (props.openMenuInDesktop ? "none" : "flex"),
      display: "flex",
      zIndex: 1,
      textTransform: "none",
      justifyContent: "center",
      alignItems: "center",
      // borderRadius: "25px",
      position: "absolute",
      content: '""',
      border: "15px solid",
      borderTopColor: "#3f43b3",
      borderLeftColor: "transparent",
      borderBottomColor: "transparent",
      borderRightColor: "transparent",
      font: "bold 14px lato",
      right: "calc(50% - 15px)",
      bottom: "-28px",
    },
  },
  BotonContinuar: {
    textTransform: "none",
    height: "62px",
    width: "238px",
    background: "#7A54BA",
    // boxShadow: "0px 3px 6px #00000029",
    borderRadius: "61px",
    color: "#FFFFFF",
    font: "bold 20px lato ",
    "&:hover": {
      background: "#dd78fb",
    },
    "&:first-letter": {
      textTransform: "uppercase",
    },
    boxShadow: "0px 0px 15px 20px white",
  },

  evaluationsContainer: {
    height: "100%",
    width: "97%",
  },
  ContainerIzquierdo: {
    position: "relative",
    // background: "#7BD7C6",
    // backgroundImage: `url(${Verde})`,
    width: "97%",
    [theme.breakpoints.down(1161)]: {
      width: "100%",
    },
  },

  ContainerIntroduccion: {
    width: "100vw",
    height: "calc(100vh - 79.5px - 79.5px)",
    overflow: "auto",
  },

  ContainerBarraInferior: {
    position: "relative",
    bottom: "0px",
    // background: "#F3CD52",
    height: "79.51px",

    [theme.breakpoints.up(1920)]: {
      height: "8vh",
    },
    [theme.breakpoints.down(1161)]: {
      // background:
      //   "linear-gradient(280deg, rgba(182,79,136,1) 34%, rgba(246,100,75,1) 91%)",
    },
  },

  ContainerBarraDerecha: {
    background:
      "linear-gradient(180deg, rgba(182,79,136,1) 34%, rgba(246,100,75,1) 91%)",
    width: "3%",
    height: "100vh",
    [theme.breakpoints.down(1161)]: {
      display: "none",
    },
  },

  Evaluacion: {
    width: "310px",
    height: "79px",
    background: " #FFFFFF 0% 0% no-repeat padding-box",
    boxShadow: "0px 3px 5px #00000029",
    border: "3px solid #71D1C1",
    borderTop: "none",
    borderRadius: "0 0 61px 61px",
    [theme.breakpoints.down(1161)]: {
      width: "266px",
      height: "65px",
      borderRadius: "0 0 51px 51px",
    },
    [theme.breakpoints.down(600)]: {
      width: "180px",
      height: "65px",
      borderRadius: "0 0 51px 51px",
    },
  },
  TextoDiagnostico: {
    margin: 0,
    font: "28px Fredoka One",
    letterSpacing: " 0.21px",
    color: "#1DA698",
  },

  Instrucciones: {
    position: "relative",
    width: "776px",
    height: "649px",
    background: "#f7ffff",
    border: "3px solid #FBE2A7",
    borderRadius: "102px",
    [theme.breakpoints.down(821)]: {
      width: "502px",
      height: "649px",
    },
    [theme.breakpoints.down(600)]: {
      width: "350px",
      height: "549px",
      borderRadius: "50px",
    },
  },

  Titulo: {
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    height: "45px",
    width: "397px",
    font: "28px Fredoka One",
    textAlign: "center",
    borderRadius: "23px",
    border: "1px solid #FDEFED",
    [theme.breakpoints.down(600)]: {
      font: "18px Fredoka One",
      height: "25px",
      width: "250px",
    },
    "& .Morado": {
      margin: "0 10px 0 0",
      color: "#DF5DA7",
    },
    "& .Mostaza": {
      color: "#EEA90A",
    },
  },

  ul: {
    margin: 0,
    padding: "0",
    display: "flex",
    color: "#575757",
    flexDirection: "column",
    font: " 22px lato",
    counterReset: "numeros",
    listStyleType: "none",
    letterSpacing: " 0.21px",
    width: "625px",
    [theme.breakpoints.down(821)]: {
      font: " 18px lato",
      width: "445px",
    },
    [theme.breakpoints.down(600)]: {
      font: " 16px lato",
      width: "300px",
    },
    "& .li": {
      position: "relative",
      padding: "0 0 0 38px",
      marginBottom: "25px",
      [theme.breakpoints.down(600)]: {
        padding: "0 0 0 35px",
        marginBottom: "20px",
      },
    },
    "& .li:before": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      content: "counter(numeros)",
      counterIncrement: "numeros",
      position: "absolute",
      left: "0",
      top: "0",
      font: " 16px Fredoka One",
      background: "#DF5DA7",
      color: "#FBE2A7",
      borderRadius: "50%",
      height: "27px",
      width: "27px",
      [theme.breakpoints.down(600)]: {
        font: " 14px Fredoka One",
        height: "20px",
        width: "20px",
      },
    },
  },

  Boton: {
    position: "absolute",
    bottom: "-31px",
  },

  BotonAvanza: {
    position: "absolute",
    bottom: "52px",
    right: "134px",
    textTransform: "none",
    height: "62px",
    width: "279px",
    background: "#7A54BA",
    boxShadow: "0px 0px 15px 15px #fff",
    borderRadius: "61px",
    color: "#FFFFFF",
    font: "bold 20px lato ",
    "&:hover": {
      background: "#dd78fb",
    },
    [theme.breakpoints.down(1161)]: {
      position: "relative",
      bottom: "32px",
      left: "0",
      right: "0",
    },
  },
}));

const GeneralIntroductionsStep = ({
  handleContinue,
  instructions,
  strategys,
}) => {
  const classes = useStyles({});

  return (
    <>
      <BlurContainer handleClose open>
        <Grid
          container
          direction="column"
          item
          className={classes.evaluationsContainer}
        >
          <Grid
            container
            item
            className={classes.ContainerIzquierdo}
            justifyContent="center"
          >
            <Grid
              container
              item
              className={classes.ContainerIntroduccion}
              justifyContent="center"
            >
              <Grid container item justifyContent="center"></Grid>
            </Grid>

            <Grid
              container
              item
              className={classes.ContainerBarraInferior}
              justifyContent="center"
            >
              <Button className={classes.BotonAvanza} onClick={handleContinue}>
                Continuar
                {strategys && (
                  <p className={classes.dialog}>
                    Éstas son las estrategias de lectura, lee con atención,
                    cuando termines haz clic en el botón <b> Continuar. </b>
                  </p>
                )}
                {instructions && (
                  <p className={classes.dialog}>
                    Verás cómo el botón de la parte inferior derecha cambia y
                    ahora se llama <b> Continuar, </b> haz clic para avanzar.
                  </p>
                )}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </BlurContainer>
    </>
  );
};

export default GeneralIntroductionsStep;
