import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
     Contenedor:{
        background:'#E9FBF9',
        width:'100vw',
        overflowX:'clip',
        [theme.breakpoints.down(1161)]:{
            background:'none',
           overflowX:'unset',
        },
        [theme.breakpoints.down(600)]:{
            marginTop: '79.51px',
        }
    },
  ContainerInfoLectura: {
    position: "relative",
    height: "86px",
    [theme.breakpoints.down(502)]:{
        height: "43px",
    },
    background: "#76D4C3",
    "& .BarraInfoLectura": {
      height: "86px",
      background: `Url(https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Barras/Azul.png)`,
        [theme.breakpoints.down(502)]:{
            height: "43px",
        },
    },
    "& .InfoLectura": {
      position: "absolute",
      height: "86px",
      width: "219px",
      boxSizing: "border-box",
      padding: "17.2px 0",
      background: "#FAFAFA",
      borderRadius: "50px",
      boxShadow: "2px 2px 2px 2px #00000029",
      zIndex: "4",
        [theme.breakpoints.down(502)]:{
            height: "43px",
            width: "109px",
            padding: "8.6px 0",
            borderRadius: "25px",
        },
    },
    "& .InfoLectura:before": {
      position: "absolute",
      display: "flex",
      boxSizing: "border-box",
      padding: "0 0 0 10px",
      justifyContent: "center",
      alignItems: "center",
      height: "39.88px",
      width: "39.88px",
      borderRadius: "50%",
      content: '""',
      boxShadow: "3px 0px .5px 0px #00000029",
      background: "#FAFAFA",
      left: "90px",
      top: "65px",
      transform: "rotate(0.25turn)",
      [theme.breakpoints.down(502)]:{
        left: "45px",
        top: "30px",
        height: "20.88px",
        width: "20.88px",
        },
    },
    "& .InfoLectura:after": {
      content: '""',
      height: "15px",
      width: "15px",
      borderTop: "2px solid #20A698",
      borderLeft: "2px solid #20A698",
      position: "absolute",
      bottom: "-10px",
      left: "Calc(50% - 8px)",
      transform: "rotate(0.625turn)",
      [theme.breakpoints.down(502)]:{
        height: "10px",
        width: "10px",
        left: "Calc(50% - 4.5px)",
        bottom: "-3px",
        borderTop: "1px solid #20A698",
        borderLeft: "1px solid #20A698",
        },
    },
    "& .Sesion": {
      borderLeft: "2px solid #D8D8D8",
    },
    "& .Dato": {
      margin: "0 0 5px 0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#20A698",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        margin: "0 0 2.5px 0",
        },
    },
    "& .Caracter": {
      margin: "0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#000000",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        },
    },   },
    ContenedorLectura:{
        background:'#fff',
        width:'1161px',
        height:'100%',
        [theme.breakpoints.down(1161)]:{
            width:'768px',
          },
          [theme.breakpoints.down(768)]:{
            width:'350px',
          },
        '& .Derechos':{
            position:'absolute',
            textAlign:"center",
            margin:"0px",
            font:" 15px lato",
            color:"#F8F8F8",
            bottom:'79px',
            [theme.breakpoints.down(1161)]:{
                display:'none'
              },
        },
        '& .Escritorio':{
            display:'block',
            [theme.breakpoints.down(1161)]:{
                display:'none'
            },
        },
        '& .Tablet':{
            display:'none',
            [theme.breakpoints.down(1161)]:{
                display:'block'
            },
        }
    },

    Parrafo1:{
        position:'relative',
        [theme.breakpoints.down(768)]:{
            display:'none'
        },
        '& .Pos':{
            position:'absolute  '
        },
        
        '& .Texto':{
            margin:'0',
            color:'#FFFFFF',
            font:'29px lato',
            lineHeight:'41px',
            letterSpacing:'.27px',
            [theme.breakpoints.down(1161)]:{
                font:'18px lato',
                lineHeight:'25px',
                letterSpacing:'.17px',
            },
            [theme.breakpoints.down(768)]:{
                font:'8px lato',
                lineHeight:'12px',
                letterSpacing:'.10px',
            },
            [theme.breakpoints.down(350)]:{
                font:'6px lato',
                lineHeight:'8.5px',
                letterSpacing:'.10px',
            },
        },
        '& .Texto2':{
            margin:'0',
            color:'#000000',
            font:'25px lato',
            lineHeight:'31px',
            letterSpacing:'.23px',
            [theme.breakpoints.down(1161)]:{
                font:'16px lato',
                lineHeight:'20px',
                letterSpacing:'.15px',
            },
            [theme.breakpoints.down(768)]:{
                font:'7px lato',
                lineHeight:'9.5px',
                letterSpacing:'.10px',
            },
            [theme.breakpoints.down(350)]:{
                font:'6px lato',
                lineHeight:'8px',
                letterSpacing:'.10px',
            },
        },
        '& .Text':{
            width:'64.08%',
            top:'8.20%',
            [theme.breakpoints.down(1161)]:{
                width:'66.27%',
            },
        },
        '& .Text1':{
            top:'27.7%',
            left:'22.91%',
            lineHeight:'62px',
            [theme.breakpoints.down(1161)]:{
                lineHeight:'41px!important',
            },
            [theme.breakpoints.down(768)]:{
                top:'27.2%',
                lineHeight:'19px!important',
            },
            [theme.breakpoints.down(350)]:{
                top:'27.8%',
                lineHeight:'16px!important',
            },
        },
        '& .Text2':{
            left:'56%',
        },
        '& .Text3':{
            top:'37.95%',
            [theme.breakpoints.down(1161)]:{
                top:'37.95%',
            },
        },
        '& .Text4':{
            top:'63.65%',
            [theme.breakpoints.down(1161)]:{
                top:'63.7%',
            },
        },
        '& .Text5':{
            top:'40.58%',
            left:'29.80%',
        },
        '& .Text6':{
            top:'44.76%',
            left:'29.80%',
        },
        '& .Text7':{
            top:'48.91%',
            left:'32.47%',
        },
        '& .Text8':{
            top:'53.55%',
            left:'32.55%',
        },
        '& .Text9':{
            textAlign:'center',
            top:'57.1%',
            left:'28.25%',
            [theme.breakpoints.down(1161)]:{
                top:'57.3%',
            },
            [theme.breakpoints.down(1161)]:{
                top:'57.4%',
                left:'28.35%',
            },
        },
        '& .Text10':{
            top:'65.96%',
            left:'33.42%',
        },
        '& .Text11':{
            top:'69.13%',
            left:'31.78%',
        },
        '& .Text12':{
            color:'#FFFFFF!important',
            top:'72.81%',
            left:'33.59%',
        },
        '& .Text13':{
            color:'#FFFFFF!important',
            top:'76.49%',
            left:'28.35%',
        },
        '& .Text14':{
            color:'#FFFFFF!important',
            top:'80.59%',
            left:'32.39%',
        },
        '& .Text15':{
            color:'#FFFFFF!important',
            top:'85.7%',
            left:'33.76%',
        },
        '& .Text16':{
            color:'#FFFFFF!important',
            top:'90.1%',
            left:'31.51%',
        },
        '& .Text17':{
            color:'#FFFFFF!important',
            top:'94.5%',
            left:'28.94%',
        },
        '& .Text18':{
            top:'39.28%',
        },
        '& .Text19':{
            top:'43.32%',
        },
        '& .Text20':{
            top:'47.31%',
        },
        '& .Text21':{
            top:'51.32%',
        },
        '& .Text22':{
            top:'57.69%',
        },
        '& .Text23':{
            top:'65.1%',
        },
        '& .Text24':{
            top:'68.4%',
        },
        '& .Text25':{
            color:'#FFFFFF!important',
            top:'71.50%',
        },
        '& .Text26':{
            color:'#FFFFFF!important',
            top:'75.80%',
        },
        '& .Text27':{
            color:'#FFFFFF!important',
            top:'78.8%',
        },
        '& .Text28':{
            color:'#FFFFFF!important',
            top:'84.1%',
        },
        '& .Text29':{
            color:'#FFFFFF!important',
            top:'88.8%',
        },
        '& .Text30':{
            color:'#FFFFFF!important',
            top:'92.8%',
        },
        '& .Text-1':{
            left:'15.5%',
        },
        '& .Text-2':{
            left:'28.5%',
        },
        '& .Text-3':{
            left:'62.70%',
        },
        '& .Text-4':{
            left:'48.43%',
        },
        '& .Vit':{
            top:'60.50%',
        },
        '& .Imagen':{
            width:'100%',
        }
    },
   Imagen:{
        display:'none',
        [theme.breakpoints.down(768)]:{
            width:'100%',
            display:'flex',
        },
    },

}));

const Las_Vitaminas_se_comen = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
             <Grid container item justifyContent="center" className={classes.ContainerInfoLectura}>
               <Grid container className="BarraInfoLectura"></Grid>
               <Grid container item className="InfoLectura">
                    <Grid container item direction="column" xs={6} justifyContent="center">
                        <p className="Dato">Grado</p>
                        <p className="Caracter">3</p>
                    </Grid>
                    <Grid container item direction="column" xs={6} justifyContent="center" className="Sesion">
                        <p className="Dato">Sesión</p>
                        <p className="Caracter">34</p>
                    </Grid>
               </Grid>
            </Grid>

            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img3ro/Sesion34/1.png"  alt="Tarjeta" />
                
                <p className='Pos Texto Text'>
                    Las vitaminas son sustancias orgánicas que se encuentran 
                    en los alimentos. Son esenciales para el desarrollo del
                    metabolismo de los seres vivos. El cuerpo humano no puede 
                    producirlas, a excepción de la D. Y se incorporan en pequeñas 
                    cantidades por medio de la alimentación.
                </p>
                <p className='Pos Texto Text1'>
                    Piel<br/>
                    Huesos<br/>
                    Dientes<br/>
                    Memoria<br/>
                    Sistema visual<br/>
                    Aparato circulatorio<br/>
                    ADN (ácido desoxirribonucleico)<br/>
                    Sistema nervioso (central y periférico)<br/>
                </p>
                <p className='Pos Texto Text1 Text2'>
                    Funciones celulares<br/>
                    Obtención de energía<br/>
                    Sistema inmunológico<br/>
                    Sistema cardiovascular<br/>
                    Desarrollo del embrión<br/>
                </p>
                <p className='Pos Texto Vit'>
                    La vitamina B (complejo B) está dividida en otros ocho<br/> 
                    elementos que tienen distintas funciones.
                </p>
                <p className='Pos Texto2 Text3 Text-1'>
                    <b>Vitamina</b>
                </p>
                <p className='Pos Texto2 Text4 Text-1'>
                    <b>Vitamina</b>
                </p>
                <p className='Pos Texto2 Text3 Text-2'>
                    <b>Nombre científico</b>
                </p>
                <p className='Pos Texto2 Text4 Text-2'>
                    <b>Nombre científico</b>
                </p>
                <p className='Pos Texto2 Text3 Text-3'>
                    <b>Función</b>
                </p>
                <p className='Pos Texto2 Text4 Text-3'>
                    <b>Función</b>
                </p>
                <p className='Pos Texto2 Text5'>
                    Ácido retinoico
                </p>
                <p className='Pos Texto2 Text6'>
                    Ácido ascórbico
                </p>
                <p className='Pos Texto2 Text7'>
                    Calciferol
                </p>
                <p className='Pos Texto2 Text8'>
                    Tocoferol
                </p>
                <p className='Pos Texto2 Text9'>
                    Filoquinona (K1)<br/>
                    Menaquinona (K2)<br/>
                    Menadiona (K3)<br/>
                </p>
                <p className='Pos Texto2 Text10'>
                    Tiamina
                </p>
                <p className='Pos Texto2 Text11'>
                    Riboflavina
                </p>
                <p className='Pos Texto2 Text12'>
                    Niacina
                </p>
                <p className='Pos Texto2 Text13'>
                    Ácido pantoténico
                </p>
                <p className='Pos Texto2 Text14'>
                    Piridoxina
                </p>
                <p className='Pos Texto2 Text15'>
                    Biotina
                </p>
                <p className='Pos Texto2 Text16'>
                    Ácido fólico
                </p>
                <p className='Pos Texto2 Text17'>
                    Cianocobalamina
                </p>
                <p className='Pos Texto2 Text18 Text-4'>
                    Mantiene la vista saludable.<br/>
                    Ayuda en la formación de dientes<br/>
                    y huesos.<br/>
                    Contribuye a tener una piel saludable.<br/> 
                    Favorece a los pulmones.<br/>
                    Fortalece el sistema inmunológico.<br/>
                </p>
                <p className='Pos Texto2 Text19 Text-4'>
                    Refuerza las defensas naturales.<br/>
                    Mantiene la piel saludable.<br/>
                    Ayuda a sanar heridas.<br/>
                    Repara y conserva huesos y dientes.<br/>
                    Disminuye el riesgo de enfermedades<br/> 
                    del corazón.
                </p>
                <p className='Pos Texto2 Text20 Text-4'>
                    Auxilia al cuerpo en la absorción<br/>
                    de calcio.<br/>
                    Contribuye en la construcción<br/>
                    de huesos fuertes y sanos.<br/>
                    Combate infecciones.<br/>
                    Coopera en la salud del corazón.
                </p>
                <p className='Pos Texto2 Text21 Text-4'>
                    Tiene un efecto antioxidante.<br/>
                    Favorece el buen funcionamiento<br/> 
                    de los músculos y nervios.<br/>
                    Mantiene la estructura celular.<br/>
                    Apoya en las funciones de los ojos.<br/>
                    Previene enfermedades en los <br/>
                    vasos sanguíneos.<br/>
                    Evita que los músculos se dañen.<br/>
                    Fortalece el sistema inmunológico.
                </p>
                <p className='Pos Texto2 Text22 Text-4'>
                    Hace que la sangre coagule.<br/>
                    Mantiene fuertes a los huesos.
                </p>
                <p className='Pos Texto2 Text23 Text-4'>
                    Suministra energía en el cerebro<br/>
                    y el sistema nervioso.<br/>
                    Participa en el desarrollo de las células.<br/>
                    Hace que los músculos se contraigan.<br/>
                </p>
                <p className='Pos Texto2 Text24 Text-4'>
                    Colabora en el crecimiento del cuerpo.<br/>
                    Contribuye a la producción <br/>
                    de glóbulos rojos.<br/>
                    Libera energía de las proteínas.<br/>
                </p>
                <p className='Pos Texto2 Text25 Text-4'>
                    Contribuye para tener una piel <br/>
                    saludable. <br/>
                    Colabora en funciones de los <br/>
                    sistemas nervioso y digestivo.<br/>
                    Libera energía de las proteínas.<br/>
                    Controla el colesterol.<br/>
                </p>
                <p className='Pos Texto2 Text26 Text-4'>
                    Favorece al metabolismo.<br/>
                    Descompone grasas.<br/>
                    Cuida el colesterol.<br/>
                    Protege a las células de la oxidación.<br/>
                </p>
                <p className='Pos Texto2 Text27 Text-4'>
                    Reduce el deterioro de la memoria. <br/>
                    Interviene en la generación de <br/>
                    glóbulos rojos.<br/>
                    Ayuda en la producción de <br/>
                    anticuerpos.<br/>
                    Mantiene la función neurológica.<br/>
                    Influye en el desarrollo cerebral del <br/>
                    embrión.<br/>
                </p>
                <p className='Pos Texto2 Text28 Text-4'>
                    Transforma los alimentos en energía.<br/>
                    Regula los niveles de azúcar en la <br/>
                    sangre.<br/>
                    Combate enfermedades <br/>
                    cardiovasculares.<br/>
                    Influye en el crecimiento de los <br/>
                    músculos.<br/>
                </p>
                <p className='Pos Texto2 Text29 Text-4'>
                    Asiste en la producción de <span style={{fontVariant:"all-small-caps"}}>ADN</span>.<br/>
                    Crea células nuevas.<br/>
                    Contribuye a la formación de <br/>
                    glóbulos rojos.<br/>
                    Participa en varias funciones del <br/>
                    embarazo.<br/>
                </p>
                <p className='Pos Texto2 Text30 Text-4'>
                    Coopera en la producción de <span style={{fontVariant:"all-small-caps"}}>ADN</span>.<br/>
                    Colabora en la formación de glóbulos <br/>
                    rojos.<br/>
                    Esencial para algunas funciones <br/>
                    neurológicas.<br/>
                    Contribuye a la formación de <br/>
                    proteínas nuevas.
                </p>

                

                <p className="Derechos">
                    © 2022. Todos los derechos reservados, Pearson, Circuito de lectores.
                </p>  
                </Grid>
                <img className={classes.Imagen} src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img3ro/Sesion34/F.png"  alt="Tarjeta" />

        </Grid>
    </Grid>
    );
}
 
export default Las_Vitaminas_se_comen;
