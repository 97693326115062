import React from "react";
import ProtectedRoute from "./ProtectedRoute";

import MapG2 from "../pages/Container/ContenedorMapG2";

// import BadgesContainer from "../pages/badges/grado_segundo/BadgesContainer";

import ActivityS02 from "../pages/exercises/segundo/activity_S02_diagnostica/Activity_Container";
import ActivityS03 from "../pages/exercises/segundo/activity_S03_funcional/Activity_Container";
import ActivityS04 from "../pages/exercises/segundo/activity_S04/Activity_Container";
import ActivityS05 from "../pages/exercises/segundo/activity_S05/Activity_Container";
import ActivityS06 from "../pages/exercises/segundo/activity_S06/Activity_Container";
import ActivityS07 from "../pages/exercises/segundo/activity_S07/Activity_Container";
import ActivityS08 from "../pages/exercises/segundo/activity_S08/Activity_Container";
import ActivityS09 from "../pages/exercises/segundo/activity_S09/Activity_Container";
import ActivityS10 from "../pages/exercises/segundo/activity_S10/Activity_Container";
import ActivityS11 from "../pages/exercises/segundo/activity_S11/Activity_Container";
import ActivityS12 from "../pages/exercises/segundo/activity_S12/Activity_Container";
import ActivityS13 from "../pages/exercises/segundo/activity_S13/Activity_Container";
import ActivityS14 from "../pages/exercises/segundo/activity_S14_funcional/Activity_Container";
import ActivityS15 from "../pages/exercises/segundo/activity_S15/Activity_Container";
import ActivityS16 from "../pages/exercises/segundo/activity_S16/Activity_Container";
import ActivityS17 from "../pages/exercises/segundo/activity_S17/Activity_Container";
import ActivityS18 from "../pages/exercises/segundo/activity_S18/Activity_Container";
import ActivityS19 from "../pages/exercises/segundo/activity_S19/Activity_Container";
import ActivityS20 from "../pages/exercises/segundo/activity_S20_Formativa/Activity_Container";
import ActivityS20R from "../pages/exercises/segundo/activity_S20_Formativa_remedial/Activity_Container";

import ActivityS21 from "../pages/exercises/segundo/activity_S21/Activity_Container";
import ActivityS22 from "../pages/exercises/segundo/activity_S22/Activity_Container";
import ActivityS23 from "../pages/exercises/segundo/activity_S23/Activity_Container";
import ActivityS24 from "../pages/exercises/segundo/activity_S24/Activity_Container";
import ActivityS25 from "../pages/exercises/segundo/activity_S25/Activity_Container";
import ActivityS26 from "../pages/exercises/segundo/activity_S26/Activity_Container";
import ActivityS27 from "../pages/exercises/segundo/activity_S27/Activity_Container";
import ActivityS28 from "../pages/exercises/segundo/activity_S28/Activity_Container";
import ActivityS29 from "../pages/exercises/segundo/activity_S29/Activity_Container";
import ActivityS30 from "../pages/exercises/segundo/activity_S30/Activity_Container";
import ActivityS31 from "../pages/exercises/segundo/activity_S31/Activity_Container";
import ActivityS32 from "../pages/exercises/segundo/activity_S32/Activity_Container";
import ActivityS33 from "../pages/exercises/segundo/activity_S33/Activity_Container";
import ActivityS34 from "../pages/exercises/segundo/activity_S34_funcional/Activity_Container";
import ActivityS35 from "../pages/exercises/segundo/activity_S35/Activity_Container";
import ActivityS36 from "../pages/exercises/segundo/activity_S36/Activity_Container";
import ActivityS37 from "../pages/exercises/segundo/activity_S37/Activity_Container";
import ActivityS38 from "../pages/exercises/segundo/activity_S38_Formativa/Activity_Container";
import ActivityS38R from "../pages/exercises/segundo/activity_S38_Formativa_remedial/Activity_Container";
import ActivityS39 from "../pages/exercises/segundo/activity_S39/Activity_Container";
import ActivityS40 from "../pages/exercises/segundo/activity_S40/Activity_Container";
import ActivityS41 from "../pages/exercises/segundo/activity_S41/Activity_Container";
import ActivityS42 from "../pages/exercises/segundo/activity_S42/Activity_Container";
import ActivityS43 from "../pages/exercises/segundo/activity_S43_funcional/Activity_Container";
import ActivityS44 from "../pages/exercises/segundo/activity_S44/Activity_Container";
import ActivityS45 from "../pages/exercises/segundo/activity_S45/Activity_Container";
import ActivityS46 from "../pages/exercises/segundo/activity_S46/Activity_Container";
import ActivityS47 from "../pages/exercises/segundo/activity_S47/Activity_Container";
import ActivityS48 from "../pages/exercises/segundo/activity_S48/Activity_Container";
import ActivityS49 from "../pages/exercises/segundo/activity_S49/Activity_Container";
import ActivityS50 from "../pages/exercises/segundo/activity_S50/Activity_Container";
import ActivityS51 from "../pages/exercises/segundo/activity_S51/Activity_Container";
import ActivityS52 from "../pages/exercises/segundo/activity_S52_funcional/Activity_Container";
import ActivityS53 from "../pages/exercises/segundo/activity_S53/Activity_Container";
import ActivityS54 from "../pages/exercises/segundo/activity_S54/Activity_Container";
import ActivityS55 from "../pages/exercises/segundo/activity_S55_Formativa/Activity_Container";
import ActivityS55R from "../pages/exercises/segundo/activity_S55_Formativa_remedial/Activity_Container";
import ActivityS56 from "../pages/exercises/segundo/activity_S56/Activity_Container";
import ActivityS57 from "../pages/exercises/segundo/activity_S57/Activity_Container";
import ActivityS58 from "../pages/exercises/segundo/activity_S58/Activity_Container";
import ActivityS59 from "../pages/exercises/segundo/activity_S59_final/Activity_Container";

import ContainerSession02G2 from "../pages/activities/SegundoGrado/B1S02/ContainerSession02";
import ContainerSession03G2 from "../pages/activities/SegundoGrado/B1S03/ContainerSession03";
import ContainerSession04G2 from "../pages/activities/SegundoGrado/B1S04/ContainerSession04";
import ContainerSession05G2 from "../pages/activities/SegundoGrado/B1S05/ContainerSession05";
import ContainerSession06G2 from "../pages/activities/SegundoGrado/B1S06/ContainerSession06";
import ContainerSession07G2 from "../pages/activities/SegundoGrado/B1S07/ContainerSession07";
import ContainerSession08G2 from "../pages/activities/SegundoGrado/B1S08/ContainerSession08";
import ContainerSession09G2 from "../pages/activities/SegundoGrado/B1S09/ContainerSession09";
import ContainerSession10G2 from "../pages/activities/SegundoGrado/B1S10/ContainerSession10";
import ContainerSession11G2 from "../pages/activities/SegundoGrado/B1S11/ContainerSession11";
import ContainerSession12G2 from "../pages/activities/SegundoGrado/B1S12/ContainerSession12";
import ContainerSession13G2 from "../pages/activities/SegundoGrado/B1S13/ContainerSession13";
import ContainerSession14G2 from "../pages/activities/SegundoGrado/B1S14/ContainerSession14";
import ContainerSession15G2 from "../pages/activities/SegundoGrado/B1S15/ContainerSession15";
import ContainerSession16G2 from "../pages/activities/SegundoGrado/B1S16/ContainerSession16";
import ContainerSession17G2 from "../pages/activities/SegundoGrado/B1S17/ContainerSession17";
import ContainerSession18G2 from "../pages/activities/SegundoGrado/B1S18/ContainerSession18";
import ContainerSession19G2 from "../pages/activities/SegundoGrado/B1S19/ContainerSession19";
import ContainerSession20G2 from "../pages/activities/SegundoGrado/B1S20/ContainerSession20";
import ContainerSession21RG2 from "../pages/activities/SegundoGrado/B1S21R/ContainerSession21R";
import ContainerSession21G2 from "../pages/activities/SegundoGrado/B1S21/ContainerSession21";
import ContainerSession22G2 from "../pages/activities/SegundoGrado/B1S22/ContainerSession22";
import ContainerSession23G2 from "../pages/activities/SegundoGrado/B1S23/ContainerSession23";
import ContainerSession24G2 from "../pages/activities/SegundoGrado/B1S24/ContainerSession24";
import ContainerSession25G2 from "../pages/activities/SegundoGrado/B1S25/ContainerSession25";
import ContainerSession26G2 from "../pages/activities/SegundoGrado/B1S26/ContainerSession26";
import ContainerSession27G2 from "../pages/activities/SegundoGrado/B1S27/ContainerSession27";
import ContainerSession28G2 from "../pages/activities/SegundoGrado/B1S28/ContainerSession28";
import ContainerSession29G2 from "../pages/activities/SegundoGrado/B1S29/ContainerSession29";
import ContainerSession30G2 from "../pages/activities/SegundoGrado/B1S30/ContainerSession30";
import ContainerSession31G2 from "../pages/activities/SegundoGrado/B1S31/ContainerSession31";
import ContainerSession32G2 from "../pages/activities/SegundoGrado/B1S32/ContainerSession32";
import ContainerSession33G2 from "../pages/activities/SegundoGrado/B1S33/ContainerSession33";
import ContainerSession34G2 from "../pages/activities/SegundoGrado/B1S34/ContainerSession34";
import ContainerSession35G2 from "../pages/activities/SegundoGrado/B1S35/ContainerSession35";
import ContainerSession36G2 from "../pages/activities/SegundoGrado/B1S36/ContainerSession36";
import ContainerSession37G2 from "../pages/activities/SegundoGrado/B1S37/ContainerSession37";
import ContainerSession38G2 from "../pages/activities/SegundoGrado/B1S38/ContainerSession38";
import ContainerSession39RG2 from "../pages/activities/SegundoGrado/B1S39R/ContainerSession39R";
import ContainerSession39G2 from "../pages/activities/SegundoGrado/B1S39/ContainerSession39";
import ContainerSession40G2 from "../pages/activities/SegundoGrado/B1S40/ContainerSession40";
import ContainerSession41G2 from "../pages/activities/SegundoGrado/B1S41/ContainerSession41";
import ContainerSession42G2 from "../pages/activities/SegundoGrado/B1S42/ContainerSession42";
import ContainerSession43G2 from "../pages/activities/SegundoGrado/B1S43/ContainerSession43";
import ContainerSession44G2 from "../pages/activities/SegundoGrado/B1S44/ContainerSession44";
import ContainerSession45G2 from "../pages/activities/SegundoGrado/B1S45/ContainerSession45";
import ContainerSession46G2 from "../pages/activities/SegundoGrado/B1S46/ContainerSession46";
import ContainerSession47G2 from "../pages/activities/SegundoGrado/B1S47/ContainerSession47";
import ContainerSession48G2 from "../pages/activities/SegundoGrado/B1S48/ContainerSession48";
import ContainerSession49G2 from "../pages/activities/SegundoGrado/B1S49/ContainerSession49";
import ContainerSession50G2 from "../pages/activities/SegundoGrado/B1S50/ContainerSession50";
import ContainerSession51G2 from "../pages/activities/SegundoGrado/B1S51/ContainerSession51";
import ContainerSession52G2 from "../pages/activities/SegundoGrado/B1S52/ContainerSession52";
import ContainerSession53G2 from "../pages/activities/SegundoGrado/B1S53/ContainerSession53";
import ContainerSession54G2 from "../pages/activities/SegundoGrado/B1S54/ContainerSession54";
import ContainerSession55G2 from "../pages/activities/SegundoGrado/B1S55/ContainerSession55";
import ContainerSession56RG2 from "../pages/activities/SegundoGrado/B1S56R/ContainerSession56R";
import ContainerSession56G2 from "../pages/activities/SegundoGrado/B1S56/ContainerSession56";
import ContainerSession57G2 from "../pages/activities/SegundoGrado/B1S57/ContainerSession57";
import ContainerSession58G2 from "../pages/activities/SegundoGrado/B1S58/ContainerSession58";
import ContainerSession59G2 from "../pages/activities/SegundoGrado/B1S59/ContainerSession59";

import Carta from "../pages/Container/ContainterCartadeJugador";
import PFinal from "../pages/Container/ContainterFinal";

import RetroEvaluaciones from "../pages/FinalResultado/segundo/ContenedorResultadoDiag";
import RetroEvaluacionesF from "../pages/FinalResultado/segundo/ContenedorResultadoFina";
import RetroSesiones from "../pages/FinalResultado/segundo/ContenedorResultadoActividad";
import RetroFormativa1 from "../pages/FinalResultado/segundo/ContenedorResultadoFormativa1";
import RetroFormativa2 from "../pages/FinalResultado/segundo/ContenedorResultadoFormativa2";
import RetroFormativa3 from "../pages/FinalResultado/segundo/ContenedorResultadoFormativa3";
import RetroRemedial from "../pages/FinalResultado/segundo/ContenedorResultadoRemedial";
import RetroRemedial2 from "../pages/FinalResultado/segundo/ContenedorResultadoRemedial2";
import RetroRemedial3 from "../pages/FinalResultado/segundo/ContenedorResultadoRemedial3";

import ChatContainer from "../pages/Chat/Chat_Container";
import ListChatContainer from "../pages/Chat/OutChat/List_Chat_Container";
import Lecturas2do from "./Lecturas2do";
import Lecturas1ro from "./Lecturas1ro";
import FinalResultadoEvaluacion from "../pages/tutorial/activity/actividad03/FinalResultado/segundo/Sesiones";

const routes = [
  <ProtectedRoute exact path="/mapa" component={MapG2} />,
  <ProtectedRoute exact path="/Carta" component={Carta} />,
  <ProtectedRoute exact path="/PFinal" component={PFinal} />,
  // <ProtectedRoute exact path="/insignias" component={BadgesContainer} />,
  <ProtectedRoute
    exact
    path="/resultado/diagnostica/:actividad/:intento/:preguntas"
    component={RetroEvaluaciones}
  />,
  <ProtectedRoute
    exact
    path="/resultado/final/:actividad/:intento/:preguntas"
    component={RetroEvaluacionesF}
  />,
  <ProtectedRoute
    exact
    path="/resultado/:actividad/:intento"
    component={RetroSesiones}
  />,
  <ProtectedRoute
    exact
    path="/guia/resultado/:actividad/:intento"
    component={FinalResultadoEvaluacion}
  />,
  <ProtectedRoute
    exact
    path="/resultado/formativa1/:actividad/:intento"
    component={RetroFormativa1}
  />,
  <ProtectedRoute
    exact
    path="/resultado/formativa2/:actividad/:intento"
    component={RetroFormativa2}
  />,
  <ProtectedRoute
    exact
    path="/resultado/formativa3/:actividad/:intento"
    component={RetroFormativa3}
  />,
  <ProtectedRoute
    exact
    path="/retro/remedial1/:actividad/:intento"
    component={RetroRemedial}
  />,
  <ProtectedRoute
    exact
    path="/retro/remedial2/:actividad/:intento"
    component={RetroRemedial2}
  />,
  <ProtectedRoute
    exact
    path="/retro/remedial3/:actividad/:intento"
    component={RetroRemedial3}
  />,
  <ProtectedRoute exact path="/actividad02" component={ActivityS02} />,
  <ProtectedRoute exact path="/actividad03" component={ActivityS03} />,
  <ProtectedRoute exact path="/actividad04" component={ActivityS04} />,
  <ProtectedRoute exact path="/actividad05" component={ActivityS05} />,
  <ProtectedRoute exact path="/actividad06" component={ActivityS06} />,
  <ProtectedRoute exact path="/actividad07" component={ActivityS07} />,
  <ProtectedRoute exact path="/actividad08" component={ActivityS08} />,
  <ProtectedRoute exact path="/actividad09" component={ActivityS09} />,
  <ProtectedRoute exact path="/actividad10" component={ActivityS10} />,
  <ProtectedRoute exact path="/actividad11" component={ActivityS11} />,
  <ProtectedRoute exact path="/actividad12" component={ActivityS12} />,
  <ProtectedRoute exact path="/actividad13" component={ActivityS13} />,
  <ProtectedRoute exact path="/actividad14" component={ActivityS14} />,
  <ProtectedRoute exact path="/actividad15" component={ActivityS15} />,
  <ProtectedRoute exact path="/actividad16" component={ActivityS16} />,
  <ProtectedRoute exact path="/actividad17" component={ActivityS17} />,
  <ProtectedRoute exact path="/actividad18" component={ActivityS18} />,
  <ProtectedRoute exact path="/actividad19" component={ActivityS19} />,
  <ProtectedRoute exact path="/actividad20" component={ActivityS20} />,
  <ProtectedRoute
    exact
    path="/actividad21_remedial"
    component={ActivityS20R}
  />,
  <ProtectedRoute exact path="/actividad21" component={ActivityS21} />,
  <ProtectedRoute exact path="/actividad22" component={ActivityS22} />,
  <ProtectedRoute exact path="/actividad23" component={ActivityS23} />,
  <ProtectedRoute exact path="/actividad24" component={ActivityS24} />,
  <ProtectedRoute exact path="/actividad25" component={ActivityS25} />,
  <ProtectedRoute exact path="/actividad26" component={ActivityS26} />,
  <ProtectedRoute exact path="/actividad27" component={ActivityS27} />,
  <ProtectedRoute exact path="/actividad28" component={ActivityS28} />,
  <ProtectedRoute exact path="/actividad29" component={ActivityS29} />,
  <ProtectedRoute exact path="/actividad30" component={ActivityS30} />,
  <ProtectedRoute exact path="/actividad31" component={ActivityS31} />,
  <ProtectedRoute exact path="/actividad32" component={ActivityS32} />,
  <ProtectedRoute exact path="/actividad33" component={ActivityS33} />,
  <ProtectedRoute exact path="/actividad34" component={ActivityS34} />,
  <ProtectedRoute exact path="/actividad35" component={ActivityS35} />,
  <ProtectedRoute exact path="/actividad36" component={ActivityS36} />,
  <ProtectedRoute exact path="/actividad37" component={ActivityS37} />,
  <ProtectedRoute exact path="/actividad38" component={ActivityS38} />,
  <ProtectedRoute
    exact
    path="/actividad39_remedial"
    component={ActivityS38R}
  />,
  <ProtectedRoute exact path="/actividad39" component={ActivityS39} />,
  <ProtectedRoute exact path="/actividad40" component={ActivityS40} />,
  <ProtectedRoute exact path="/actividad41" component={ActivityS41} />,
  <ProtectedRoute exact path="/actividad42" component={ActivityS42} />,
  <ProtectedRoute exact path="/actividad43" component={ActivityS43} />,
  <ProtectedRoute exact path="/actividad44" component={ActivityS44} />,
  <ProtectedRoute exact path="/actividad45" component={ActivityS45} />,
  <ProtectedRoute exact path="/actividad46" component={ActivityS46} />,
  <ProtectedRoute exact path="/actividad47" component={ActivityS47} />,
  <ProtectedRoute exact path="/actividad48" component={ActivityS48} />,
  <ProtectedRoute exact path="/actividad49" component={ActivityS49} />,
  <ProtectedRoute exact path="/actividad50" component={ActivityS50} />,
  <ProtectedRoute exact path="/actividad51" component={ActivityS51} />,
  <ProtectedRoute exact path="/actividad52" component={ActivityS52} />,
  <ProtectedRoute exact path="/actividad53" component={ActivityS53} />,
  <ProtectedRoute exact path="/actividad54" component={ActivityS54} />,
  <ProtectedRoute exact path="/actividad55" component={ActivityS55} />,
  <ProtectedRoute
    exact
    path="/actividad56_remedial"
    component={ActivityS55R}
  />,
  <ProtectedRoute exact path="/actividad56" component={ActivityS56} />,
  <ProtectedRoute exact path="/actividad57" component={ActivityS57} />,
  <ProtectedRoute exact path="/actividad58" component={ActivityS58} />,
  <ProtectedRoute exact path="/actividad59" component={ActivityS59} />,

  <ProtectedRoute exact path="/session02" component={ContainerSession02G2} />,
  <ProtectedRoute exact path="/session03" component={ContainerSession03G2} />,
  <ProtectedRoute exact path="/session04" component={ContainerSession04G2} />,
  <ProtectedRoute exact path="/session05" component={ContainerSession05G2} />,
  <ProtectedRoute exact path="/session06" component={ContainerSession06G2} />,
  <ProtectedRoute exact path="/session07" component={ContainerSession07G2} />,
  <ProtectedRoute exact path="/session08" component={ContainerSession08G2} />,
  <ProtectedRoute exact path="/session09" component={ContainerSession09G2} />,
  <ProtectedRoute exact path="/session10" component={ContainerSession10G2} />,
  <ProtectedRoute exact path="/session11" component={ContainerSession11G2} />,
  <ProtectedRoute exact path="/session12" component={ContainerSession12G2} />,
  <ProtectedRoute exact path="/session13" component={ContainerSession13G2} />,
  <ProtectedRoute exact path="/session14" component={ContainerSession14G2} />,
  <ProtectedRoute exact path="/session15" component={ContainerSession15G2} />,
  <ProtectedRoute exact path="/session16" component={ContainerSession16G2} />,
  <ProtectedRoute exact path="/session17" component={ContainerSession17G2} />,
  <ProtectedRoute exact path="/session18" component={ContainerSession18G2} />,
  <ProtectedRoute exact path="/session19" component={ContainerSession19G2} />,
  <ProtectedRoute exact path="/session20" component={ContainerSession20G2} />,
  <ProtectedRoute
    exact
    path="/sessionRemedial-1"
    component={ContainerSession21RG2}
  />,
  <ProtectedRoute exact path="/session21" component={ContainerSession21G2} />,
  <ProtectedRoute exact path="/session22" component={ContainerSession22G2} />,
  <ProtectedRoute exact path="/session23" component={ContainerSession23G2} />,
  <ProtectedRoute exact path="/session24" component={ContainerSession24G2} />,
  <ProtectedRoute exact path="/session25" component={ContainerSession25G2} />,
  <ProtectedRoute exact path="/session26" component={ContainerSession26G2} />,
  <ProtectedRoute exact path="/session27" component={ContainerSession27G2} />,
  <ProtectedRoute exact path="/session28" component={ContainerSession28G2} />,
  <ProtectedRoute exact path="/session29" component={ContainerSession29G2} />,
  <ProtectedRoute exact path="/session30" component={ContainerSession30G2} />,
  <ProtectedRoute exact path="/session31" component={ContainerSession31G2} />,
  <ProtectedRoute exact path="/session32" component={ContainerSession32G2} />,
  <ProtectedRoute exact path="/session33" component={ContainerSession33G2} />,
  <ProtectedRoute exact path="/session34" component={ContainerSession34G2} />,
  <ProtectedRoute exact path="/session35" component={ContainerSession35G2} />,
  <ProtectedRoute exact path="/session36" component={ContainerSession36G2} />,
  <ProtectedRoute exact path="/session37" component={ContainerSession37G2} />,
  <ProtectedRoute exact path="/session38" component={ContainerSession38G2} />,
  <ProtectedRoute
    exact
    path="/sessionRemedial-2"
    component={ContainerSession39RG2}
  />,
  <ProtectedRoute exact path="/session39" component={ContainerSession39G2} />,
  <ProtectedRoute exact path="/session40" component={ContainerSession40G2} />,
  <ProtectedRoute exact path="/session41" component={ContainerSession41G2} />,
  <ProtectedRoute exact path="/session42" component={ContainerSession42G2} />,
  <ProtectedRoute exact path="/session43" component={ContainerSession43G2} />,
  <ProtectedRoute exact path="/session44" component={ContainerSession44G2} />,
  <ProtectedRoute exact path="/session45" component={ContainerSession45G2} />,
  <ProtectedRoute exact path="/session46" component={ContainerSession46G2} />,
  <ProtectedRoute exact path="/session47" component={ContainerSession47G2} />,
  <ProtectedRoute exact path="/session48" component={ContainerSession48G2} />,
  <ProtectedRoute exact path="/session49" component={ContainerSession49G2} />,
  <ProtectedRoute exact path="/session50" component={ContainerSession50G2} />,
  <ProtectedRoute exact path="/session51" component={ContainerSession51G2} />,
  <ProtectedRoute exact path="/session52" component={ContainerSession52G2} />,
  <ProtectedRoute exact path="/session53" component={ContainerSession53G2} />,
  <ProtectedRoute exact path="/session54" component={ContainerSession54G2} />,
  <ProtectedRoute exact path="/session55" component={ContainerSession55G2} />,
  <ProtectedRoute
    exact
    path="/sessionRemedial-3"
    component={ContainerSession56RG2}
  />,
  <ProtectedRoute exact path="/session56" component={ContainerSession56G2} />,
  <ProtectedRoute exact path="/session57" component={ContainerSession57G2} />,
  <ProtectedRoute exact path="/session58" component={ContainerSession58G2} />,
  <ProtectedRoute exact path="/session59" component={ContainerSession59G2} />,
  <ProtectedRoute exact path="/chat" component={ListChatContainer} />,
  <ProtectedRoute exact path="/chat/:id" component={ChatContainer} />,
  ...Lecturas1ro,
  ...Lecturas2do,
];

const SegundoGrado = routes.map((route, key) => {
  return { ...route, key: key };
});

export default SegundoGrado;
