import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
    ContenedorLectura:{
        width:'502px',
                  [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
    },
    ContainerTitulo:{
        margin:'0 0 34px 0',
        '& .Titulo':{
            letterSpacing: '0px',
            color: '#B733F1',
            margin:'0',
            textAlign:'center',
            font:'43px fredoka one',
            lineHeight:'52px',
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            margin:'0 0 36px 0',
            color:'#000',
            font:'18px lato',
            lineHeight :'28px',
            letterSpacing: '0.17px',
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .TextImg':{
            color:'#717476',
            margin:'17px 0 0 0',
            font:' 16px lato',
            lineHeight :'25px',
            letterSpacing: '0.15px',
            [theme.breakpoints.down(502)]:{
                font:' 11px lato',
                lineHeight :'14px',
                letterSpacing: '0.10px',
            },
        },
        '& .Imagen':{
            width:'100%',
        },  
    }
}));

const El_planeta_Zoo = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >
                        El planeta Zoo
                    </h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                      <p className="Lectura">
                        Había una vez un planeta llamado Zoo, gobernado por una computadora capaz de 
                        resolver cualquier problema. Para probar su poder, un día el colibrí escribió con su 
                        teclado: “¿Qué es lo que todos los habitantes de Zoo desean?” 
                        <br/><br/>
                        Entonces, ¡la computadora se quedó pasmada!
                      </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img1ro/Sesion51/1.png" alt="Tarjeta" />
                    </Grid>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                      <p className="Lectura">
                        El colibrí convocó a los representantes de cada especie animal y explicó: <br/><br/>
                        
                        —Para arreglar la computadora, debemos escribir la respuesta. <br/><br/>

                        —¡Riqueza! —dijo el hámster—. La riqueza permite comprar cosas y ser felices.<br/><br/>

                        —¡Fama! —dijo el pavorreal—. El reconocimiento te hace feliz.<br/><br/>
                        
                        —Un lugar tranquilo, la paz es felicidad —dijo el caracol, suavemente. <br/><br/>
                        
                        —Valentía. ¡No tener miedo me haría feliz! —dijo la oruga. <br/><br/>
                        
                        —Yo a nada temo. Lo que más me hace feliz es tener amigos —dijo el tigre.
                      </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img1ro/Sesion51/2.png" alt="Tarjeta" />
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                      <p className="Lectura">
                        Todos los animales dieron argumentos para probar su punto hasta que un 
                        comentario puso fin a la discusión: <br/><br/>
                        
                        —Veamos —dijo el búho—. La fama del pavorreal, la paz del caracol, el valor de la 
                        oruga y los amigos del tigre… ¡Todo lleva a la felicidad! Sensación que nuestra sabia 
                        computadora no puede experimentar.<br/><br/>
                        
                        Inmediatamente, el colibrí escribió “felicidad”, luego dio “enter” e incorporó la 
                        respuesta al universo de datos que permiten gobernar con sabiduría el planeta Zoo.
                      </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img1ro/Sesion51/3.png" alt="Tarjeta" />
                    </Grid>
                </Grid>
        </Grid>
    </Grid>
    );
}
 
export default El_planeta_Zoo ;
