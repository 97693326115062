import { Button, Grid, makeStyles } from "@material-ui/core";
import React, { useContext, useEffect } from "react";
import DrawerContext from "../../../../../usability/DrawerContext";
import { useOnLoadImages } from "../../../../hooks/useOnLoadImages";
import Loader from "../../../../Loader/Loader";
import Drawer from "../../ReadingTools/Drawer";

const useStyles = makeStyles((theme) => ({
  Contenedor: {
    background: "#E9FBF9",
    width: "100vw",
    [theme.breakpoints.down(1161)]: {
      background: "none",
    },
    [theme.breakpoints.down(600)]: {
      marginTop: "79.51px",
    },
  },
  ContainerInfoLectura: {
    position: "relative",
    height: "86px",
    [theme.breakpoints.down(502)]: {
      height: "43px",
    },
    background: "#76D4C3",
    "& .BarraInfoLectura": {
      height: "86px",
      background: `Url(https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Barras/Azul.png)`,
      [theme.breakpoints.down(502)]: {
        height: "43px",
      },
    },
    "& .InfoLectura": {
      position: "absolute",
      height: "86px",
      width: "219px",
      boxSizing: "border-box",
      padding: "17.2px 0",
      background: "#FAFAFA",
      borderRadius: "50px",
      boxShadow: "2px 2px 2px 2px #00000029",
      zIndex: "4",
      [theme.breakpoints.down(502)]: {
        height: "43px",
        width: "109px",
        padding: "8.6px 0",
        borderRadius: "25px",
      },
    },
    "& .InfoLectura:before": {
      position: "absolute",
      display: "flex",
      boxSizing: "border-box",
      padding: "0 0 0 10px",
      justifyContent: "center",
      alignItems: "center",
      height: "39.88px",
      width: "39.88px",
      borderRadius: "50%",
      content: '""',
      boxShadow: "3px 0px .5px 0px #00000029",
      background: "#FAFAFA",
      left: "90px",
      top: "65px",
      transform: "rotate(0.25turn)",
      [theme.breakpoints.down(502)]: {
        left: "45px",
        top: "30px",
        height: "20.88px",
        width: "20.88px",
      },
    },
    "& .InfoLectura:after": {
      content: '""',
      height: "15px",
      width: "15px",
      borderTop: "2px solid #20A698",
      borderLeft: "2px solid #20A698",
      position: "absolute",
      bottom: "-10px",
      left: "Calc(50% - 8px)",
      transform: "rotate(0.625turn)",
      [theme.breakpoints.down(502)]: {
        height: "10px",
        width: "10px",
        left: "Calc(50% - 4.5px)",
        bottom: "-3px",
        borderTop: "1px solid #20A698",
        borderLeft: "1px solid #20A698",
      },
    },
    "& .Sesion": {
      borderLeft: "2px solid #D8D8D8",
    },
    "& .Dato": {
      margin: "0 0 5px 0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#20A698",
      [theme.breakpoints.down(502)]: {
        font: "bold 12px lato",
        margin: "0 0 2.5px 0",
      },
    },
    "& .Caracter": {
      margin: "0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#000000",
      [theme.breakpoints.down(502)]: {
        font: "bold 12px lato",
      },
    },
    "& .Regresar": {
      color: "#F2624F",
      border: "#F2624F 1px solid",
      height: "30px",
      width: "100px",
      borderRadius: "20px",
      letterSpacing: "0.14px",
      textTransform: "none",
      background: "#FEFAF1",
      top: "calc(50% - 15px)",
      left: "5%",
      zIndex: 4,
      [theme.breakpoints.down(502)]: {
        font: "bold 10px lato",
        width: "60px",
      },
    },
  },
  ContenedorLectura: {
    background: "#fff",
    width: "1160px",
    height: "100%",
    padding: "0 ",
    
    transform: (props) =>
      props.scale > 1 ? `scale(${props.scale}) translate(0px, 10%)` : `none`,
    [theme.breakpoints.down(1161)]: {
      width: "766px",
    },
    [theme.breakpoints.down(766)]: {
      width: "350px",
    },
    "& .Escritorio": {
      display: "block",
      [theme.breakpoints.down(1161)]: {
        display: "none",
      },
    },
    "& .Tablet": {
      display: "none",
      [theme.breakpoints.down(1161)]: {
        display: "block",
      },
    },
  },

  Parrafo1: {
    position: "relative",
    [theme.breakpoints.down(768)]: {
      display: "none",
    },
    "& .Pos": {
      position: "absolute",
      margin: "0",
    },
    "& .Titulo": {
      margin: 0,
      textAlign: "center",
      color: "#FFFFFF",
      font: "71px Fredoka One",
      lineHeigt: "86px",
      top: ".88%",
      [theme.breakpoints.down(1161)]: {
        font: "46px Fredoka One",
        lineHeigt: "56px",
      },
      [theme.breakpoints.down(766)]: {
        font: "22px Fredoka One",
        lineHeigt: "25px",
      },
    },
    "& .Titulo2": {
      margin: 0,
      font: "39px Fredoka One",
      lineHeigt: "47px",
      color: "#926DD1",
      top: "23.63%",
      left: "18.23%",
      [theme.breakpoints.down(1161)]: {
        font: "26px Fredoka One",
        lineHeigt: "31px",
        top: "26.86%",
      },
      [theme.breakpoints.down(766)]: {
        font: "15px Fredoka One",
        lineHeigt: "17px",
      },
    },
    "& .SubTitulo": {
      margin: 0,
      font: "39px Fredoka One",
      lineHeigt: "47px",
      color: "#926DD1",
      top: "17.48%",
      left: "15%",
      [theme.breakpoints.down(1161)]: {
        font: "25px Fredoka One",
        lineHeigt: "30px",
      },
      [theme.breakpoints.down(766)]: {
        font: "12px Fredoka One",
        lineHeigt: "13px",
      },
    },
    "& .SubTitulo2": {
      margin: 0,
      font: " 30px Fredoka One",
      lineHeigt: "28px",
      color: "#700088",
      [theme.breakpoints.down(1161)]: {
        font: "18px Fredoka One",
        lineHeigt: "18px",
      },
      [theme.breakpoints.down(766)]: {
        font: "10px Fredoka One",
      },
    },
    "& .SubTitulo3": {
      margin: 0,
      position: "absolute",
      top: "87.7%",
      left: "36%",
      color: "#926DD1",
      font: "33px Fredoka One",
      lineHeigt: "40px",
      [theme.breakpoints.down(1161)]: {
        font: "20px Fredoka One",
        lineHeigt: "24px",
      },
      [theme.breakpoints.down(766)]: {
        font: "10px Fredoka One",
        lineHeigt: "15px",
      },
      [theme.breakpoints.down(350)]: {
        left: "35%",
      },
    },
    "& .Lectura": {
      margin: "0",
      color: "#000000",
      font: " 22px lato",
      letterSpacing: ".21px",
      lineHeigt: "30px",
      [theme.breakpoints.down(1161)]: {
        font: " 13px lato",
        letterSpacing: ".12px",
        lineHeigt: "18px",
      },
      [theme.breakpoints.down(766)]: {
        font: "5.5px lato",
        letterSpacing: ".08px",
        lineHeigt: "9px",
      },
    },
    "& .ul": {
      padding: "0 0 0 19px",
      margin: "0",
      color: "#FFFFFF",
      [theme.breakpoints.down(766)]: {
        padding: "0 0 0 10px",
      },
    },
    "& .li": {
      margin: "0 0 8px 0",
      font: "800 22px lato",
      letterSpacing: ".21px",
      lineHeigt: "30px",
      [theme.breakpoints.down(1161)]: {
        font: "800 13px lato",
        letterSpacing: ".12px",
        lineHeigt: "18px",
      },
      [theme.breakpoints.down(766)]: {
        font: "800 7px lato",
        letterSpacing: ".09px",
        lineHeigt: "10px",
        margin: "0 0 4px 0",
      },
      [theme.breakpoints.down(350)]: {
        font: "800 6px lato",
        letterSpacing: ".09px",
        lineHeigt: "9px",
        margin: "0 0 4px 0",
      },
    },
    "& .Paso": {
      left: "257px",
      [theme.breakpoints.down(1161)]: {
        left: "18.80%",
      },
    },
    "& .Lect": {
      top: "6.91%",
      left: "22.92%",
    },
    "& .Lec2": {
      top: "19.64%",
      left: "15.16%",
    },
    "& .Lec3": {
      top: "24.47%",
      left: "15.16%",
    },
    "& .Lec4": {
      top: "29.94%",
      left: "15.16%",
    },
    "& .Lec5": {
      top: "32%",
      left: "52.97%",
    },
    "& .Lec6": {
      top: "45.30%",
      left: "52.97%",
    },
    "& .Lec7": {
      top: "52.14%",
      left: "52.97%",
    },
    "& .Lec8": {
      top: "57.87%",
      left: "15.16%",
    },
    "& .Lec9": {
      top: "57.87%",
      left: "52.97%",
    },
    "& .Lec10": {
      top: "69.80%",
      left: "15.16%",
    },
    "& .Lec11": {
      top: "69.80%",
      left: "52.97%",
    },
    "& .Lec12": {
      top: "81.20%",
      left: "15.16%",
    },

    "& .Derechos": {
      position: "relative",
      bottom: "35px",
      textAlign: "center",
      margin: "0px",
      font: " 15px lato",
      color: "#717476",
      [theme.breakpoints.down(1161)]: {
        display: "none",
      },
    },
    "& .Imagen": {
      [theme.breakpoints.down(1161)]: {
        width: "100%",
      },
    },
  },
  Imagen: {
    display: "none",
    [theme.breakpoints.down(768)]: {
      width: "100%",
      display: "flex",
    },
  },
}));

const Conejo_separador_de_libros = (props) => {
  const { activeStep, setActiveStep, handlePreviewStep, id_actividad } = props;

  const handleContinue = () => {
    setActiveStep(activeStep - 1);
    handlePreviewStep();
  };

  const { handleSize, disabledZoom, closeZoom, handleZoom, scale } =
    useContext(DrawerContext);
  const [loading, setLoading] = React.useState(true);
  const wrapperRef = React.useRef(null);
  const imagesLoaded = useOnLoadImages(wrapperRef);

  const detectSize = () => {
    const lectura = document.getElementById("lectura");
    const newWidth = lectura?.offsetWidth;
    const newHeight = lectura?.offsetHeight;
    setLoading(false);
    if (lectura) handleSize(newHeight, newWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", detectSize);
    return () => {
      handleZoom();
      window.removeEventListener("resize", detectSize);
      closeZoom();
    };
  }, []);

  const classes = useStyles({ disabledZoom, scale });
  return (
    <Grid
      container
      item
      justifyContent="center"
      xs={12}
      className={classes.Contenedor}
    >
      <Grid
        container
        item
        justifyContent="center"
        className={classes.ContainerInfoLectura}
      >
        <Grid container className="BarraInfoLectura">
          <Button className="Regresar" onClick={handleContinue}>
            Regresar
          </Button>
        </Grid>
        <Grid container item className="InfoLectura">
          <Grid
            container
            item
            direction="column"
            xs={6}
            justifyContent="center"
          >
            <p className="Dato">Grado</p>
            <p className="Caracter">4</p>
          </Grid>
          <Grid
            container
            item
            direction="column"
            xs={6}
            justifyContent="center"
            className="Sesion"
          >
            <p className="Dato">Sesión</p>
            <p className="Caracter">3</p>
          </Grid>
        </Grid>
      </Grid>
      {!imagesLoaded && <Loader />}
      <Grid
        container
        item
        className={classes.ContenedorLectura}
        justifyContent="center"
        id="lectura"
        onLoad={detectSize}
        ref={wrapperRef}
      >
        <Drawer id_lectura={id_actividad} />
        <Grid
          container
          item
          sm={12}
          className={classes.Parrafo1}
          justifyContent="center"
        >
          <h2 className="Pos Titulo">
            Conejo separador
            <br /> de libros
          </h2>
          <p className="Pos Lectura Lect">
            <h3 className="Titulo2">Materiales:</h3>
            <br />
            <ul className="ul">
              <li className="li">
                Una hoja del color que desees para tu conejo
              </li>
              <li className="li">
                Una hoja rosa para la nariz y el interior de las orejas{" "}
              </li>
              <li className="li">Una hoja blanca para los dientes </li>
              <li className="li">Lápices de colores </li>
              <li className="li">Plumón negro </li>
              <li className="li">Pegamento </li>
              <li className="li">Tijeras </li>
            </ul>
          </p>
          <h3 className="Pos SubTitulo">Pasos</h3>

          <p className="Pos Lectura Lec2">
            <h3 className="SubTitulo2">Paso 1</h3>
            <br />
            Corta una hoja de papel para formar un
            <br />
            cuadrado. Dóblalo a la mitad, juntando dos
            <br />
            de sus esquinas. En este paso sólo es
            <br />
            necesario marcar el doblez que tendrá la hoja.
          </p>

          <p className="Pos Lectura Lec3">
            <h3 className="SubTitulo2">Paso 2</h3>
            <br />
            Dobla el papel otra vez a la mitad, usando
            <br />
            las otras dos esquinas del cuadrado. Así,
            <br />
            harás unos triángulos que te servirán de
            <br />
            guía, pues verás cuatro de estas figuras
            <br />
            en tu cuadrado al finalizar este paso.
          </p>

          <p className="Pos Lectura Lec4">
            <h3 className="SubTitulo2">Paso 3</h3>
            <br />
            Aquí se muestra el papel en dos colores
            <br />
            para que veas cuál es el frente y cuál el
            <br />
            revés.
            <br />
            <br />
            Ahora nuevamente dobla el papel a la <br />
            mitad, como hiciste en el paso 1. <br />
            Esta vez mantén el doblez, lo cual <br />
            formará un triángulo que tendrá la <br />
            base más grande que los lados.
          </p>

          <p className="Pos Lectura Lec5">
            <h3 className="SubTitulo2">Paso 4</h3>
            <br />
            Cuando hayas identificado la base del
            <br />
            triángulo, dobla una de sus puntas
            <br />
            superiores hacia abajo, hasta que toque
            <br />
            la mitad de la línea recta o base del
            <br />
            triángulo. Te puedes guiar con la línea
            <br />
            del doblez que hiciste en esa parte.
          </p>

          <p className="Pos Lectura Lec6">
            <h3 className="SubTitulo2">Paso 5</h3>
            <br />
            Ahora dobla las esquinas inferiores <br />
            hacia adentro. Las tres puntas del <br />
            triángulo se unirán en el centro del <br />
            triángulo. Esto es solamente para <br />
            marcar el doblez.
          </p>

          <p className="Pos Lectura Lec7">
            <h3 className="SubTitulo2">Paso 6</h3>
            <br />
            Al abrir las esquinas, notarás que se
            <br />
            marcaron dos triángulos más en la base
            <br />
            del triángulo.
          </p>

          <p className="Pos Lectura Lec8">
            <h3 className="SubTitulo2">Paso 7</h3>
            <br />
            Busca el costado derecho de la base
            <br />
            del triángulo inicial. Toma esta punta y<br />
            dóblala hacia arriba. Tocará la punta
            <br />
            superior del triángulo original,
            <br />
            siguiendo la línea recta que está en el
            <br />
            centro.
          </p>

          <p className="Pos Lectura Lec9">
            <h3 className="SubTitulo2">Paso 8</h3>
            <br />
            Una vez que ambas puntas se
            <br />
            encontraron, dobla e introduce con
            <br />
            cuidado la punta del triángulo inferior
            <br />
            derecho en el hueco del papel. Te darás
            <br />
            cuenta de que toca el centro de lo que
            <br />
            inicialmente fue el cuadrado.
          </p>

          <p className="Pos Lectura Lec10">
            <h3 className="SubTitulo2">Paso 9</h3>
            <br />
            Repite los pasos 7 y 8 ahora con la punta
            <br />
            inferior izquierda del triángulo inicial.
            <br />
            Observarás que se creó un rombo con
            <br />
            los triángulos doblados hacia el frente.
            <br />
            Esto construirá una especie de bolsa, tal
            <br />
            como aparece en la imagen.
          </p>

          <p className="Pos Lectura Lec11">
            <h3 className="SubTitulo2">Paso 10</h3>
            <br />
            Inserta también la punta superior del <br />
            triángulo inicial en la nueva bolsa, <br />
            tocando el centro del primer cuadrado. <br />
            Verás que se hizo un triángulo como el <br />
            del paso 3, pero con algunas cavidades.
          </p>

          <p className="Pos Lectura Lec12">
            <h3 className="SubTitulo2">Paso 11</h3>
            <br />
            Dibuja tres círculos de distintos colores con los <br />
            que harás los ojos del conejo, tal como aparece en <br />
            la imagen. Utiliza el mismo color de la cabeza para <br />
            las orejas. Haz el interior de las orejas y la nariz <br />
            con papel rosado. Finalmente, recorta un <br />
            pequeño cuadrado de color blanco para los <br />
            dientes. Añade los últimos detalles con el plumón.
          </p>

          <h3 className="SubTitulo3">¡Listo, tienes tu conejo separador!</h3>

          <img
            className="Imagen "
            src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img4to/Sesion03/1.png"
            alt="Tarjeta"
          />
        </Grid>
        <img
          className={classes.Imagen}
          src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img4to/Sesion03/F.png"
          alt="Tarjeta"
        />
      </Grid>
      <Grid container item style={{ marginTop: 100 }}></Grid>
    </Grid>
  );
};

export default Conejo_separador_de_libros;
