import React from 'react';
import Grid from '@material-ui/core/Grid'
import { makeStyles} from '@material-ui/core';
import NavBar from '../../../NavBar';
import Lectura from '../../LecturasBiblioteca/LecturasSexto/Sesion56R/Lectura'
import MenuIzquierdo from '../../../MenuIzquierdo';
const useStyles = makeStyles(theme=>({
  grid1:{    
    zIndex:'3',
    
  },
  grid2:{    
    zIndex:'2'
    
  },
  grid3:{    
    boxSizing:'border-box',
    zIndex:'1',
    paddingLeft:'3%',
    [theme.breakpoints.down('md')]:{
      paddingLeft:'0px',
    }
    
  }
}))

function ContenedorLectura() {
  const classes= useStyles()
  return (
      <Grid container >
          <Grid item className={classes.grid1} sm={12}> 
            <NavBar/>
          </Grid>
          <Grid item className={classes.grid2} sm={1}> 
            <MenuIzquierdo/>
          </Grid>
          <Grid item className={classes.grid3} sm={12}>
            <Lectura/>
          </Grid>
      </Grid>
  );
}

export default ContenedorLectura;
