import React                        from "react";
import { Button,makeStyles,Grid}    from "@material-ui/core";

const useStyles = makeStyles(theme=>({
  welcomePageContainer: {
    background:'#FFFDFD',
    height:'calc(100vh - 79.5px)',
  },
  ContainerIzquierdo:{
    width:'97%',
    [theme.breakpoints.down(1160)]:{
      width:'100%',
    },
  },

  ContainerEstrategi:{
    position:'relative',
    width:'100vw',
    height:'calc(100vh - 79.5px - 79.5px)',
    overflow: 'auto',
  },
  ContainerSubHabilidades:{
    background:'none',
    boxSizing:'border-box',
    cursor:'pointer',
    textAlign:'center',
    display:'flex',
    padding:'43px 17px 9px 17px',
    alignItems:'center',
    position:'absolute',
    height:'40px',
    width:'62px',
    right:'3%',
    zIndex:1,
    [theme.breakpoints.down(1281)]:{
      left:'calc(50% - 31px)',
      padding:'0 0 0 62px',
    },
    [theme.breakpoints.down(600)]:{
      left:'0',
      padding:'0 15px 0 55px',
    },
        '& .Habilidades':{
      color:'#575757',
      font: 'normal 16px Lato',
      letterSpacing: '0.13px',
      display:'none',
      [theme.breakpoints.down(1281)]:{
        padding:'0 ', 
        margin:'0',
        font: ' 13px Lato',
      },
      [theme.breakpoints.down(600)]:{
        padding:'0 ', 
        margin:'0',
        font: ' 11px Lato',
      },
    },
    '&:hover':{
      border:' 5px solid #F26973',
      borderRadius: '20px 0px 20px 20px',
      background:'#fff',
      height:'215px',
      width:'233px',
      [theme.breakpoints.down(1281)]:{
        height:'75px',
        width:'350px',
        borderRadius: '0px 0px 20px 20px',
      },
      [theme.breakpoints.down(600)]:{
        height:'75px',
        width:'100%',
      },

      '& .Habilidades':{
        display:'block',
      },
      '&:before':{
        borderTop:' 0px ',
        borderRight:'0px'
      },
    },
    '&:before':{
      display:'flex',
      justifyContent:'center',
      alignItems:'center',
      content: '"RG"',
      position: 'absolute',
      right: '0',
      top: '0',
      font: '20px Fredoka One',
      background: '#DC3F8C',
      color: '#FFF',
      border:' 3px solid #F2624F',
      borderRadius: '0px 0px 20px 20px',
      height: '40px',
      width: '62px',
      [theme.breakpoints.down(1281)]:{
        left: '-5px',
        top: '0',
      },
      [theme.breakpoints.down(600)]:{
        height: '30px',
        width: '52px',
        font: '18px Fredoka One',
      },

    },
  },

  Estrategia:{
    margin:'0px',
    font:'38px Fredoka One',
    textAlign: 'center',
    letterSpacing: '0.28px',
    color: '#20A698',
    opacity: '1',
    [theme.breakpoints.down(1161)]:{
      font:'28px Fredoka One',
    },
    [theme.breakpoints.down(600)]:{
      font:'24px Fredoka One',
    },
  },

   TextStrg:{
      margin:'0', 
      font:'normal  22px Lato',
      letterSpacing: '0.10px',
      color: '#575757',
      opacity: 1,
      padding:'0 0 40px 0',
      boxSizing:'border-box',
      width:'760px',
      [theme.breakpoints.down(1161)]:{
        width:'650px',
        font:'normal  18px Lato',
      },
      [theme.breakpoints.down(600)]:{
        font:'normal  16px Lato',
        width:'325px',
      },
      [theme.breakpoints.down(325)]:{
        font:'normal  14px Lato',
        width:'300px',
      },
  },

   Titulo:{
    position:'absolute',
    top:'58px',
    [theme.breakpoints.down(1161)]:{
      top:'80px',
    },
  },
  Objet1:{
    position:'absolute',
    top:'130px',
    '& .Br':{
      margin:'0 0 20px 0'
    },
    '& .ul':{
      margin:'22px 0',
      padding:'0 0 0 22px',
    },
    '& .li':{
      marginBottom:'10px',
    },
   '& .Amarillo':{
    textAlign:'center',
    height:'42px',
    background:'#F7DF92',
    padding:'0 ',
   },
   '& .Verde':{
    textAlign:'center',
    height:'42px',
    background:'#84E9DF',
    padding:'0 ',
   },

  },

  ContainerBarraInferior:{
    position:'relative',
    background:'#F3CD52',
    height:'79.51px',
    [theme.breakpoints.up(1920)]:{
      height:'8vh',
    },
    [theme.breakpoints.down(1161)]:{
      background: 'linear-gradient(280deg, #D3348F 0%, #F1CB5A 100%) 0% 0% ',
    },
  },

  ContainerBarraDerecha:{
    background: 'linear-gradient(180deg, #D3348F 0%, #F1CB5A 100%) 0% 0%',
    width:'3%',
    height:'100vh',
    [theme.breakpoints.down(1161)]:{
      display:'none',
    },
  },
  
   BotonAvanza:{
    position:'absolute',
    bottom:'52px',
    right:'134px',
    textTransform:'none',
    textDecoration:'none',
    height:'62px',
    width:'279px',  
    background: '#7A54BA',
    boxShadow: '0px 3px 6px #00000029',
    borderRadius: '61px',
    color: '#FFFFFF',
    font: 'bold 20px lato ',
    '&:hover':{
        background: '#dd78fb',
      },
    [theme.breakpoints.down(1161)]:{
      position:'relative',
      bottom:'32px',
      left:'0',
      right:'0',
    },
  },

    
}));

const ReadingStrategys = (props) => {
  const classes = useStyles();
  const {activeStep, setActiveStep, handleNextStep} = props

  const handleContinue = () => {
    setActiveStep(activeStep + 1)
    handleNextStep()
  }

  return(

    <Grid  container direction="column" item sm={12} className={classes.welcomePageContainer} >
<div className={classes.ContainerSubHabilidades}>
          <p className="Habilidades">Identificar el verbo (con persona, tiempo y modo) de una oración.</p>
      </div>
      <Grid container item sm={12} justifyContent="center" className={classes.ContainerIzquierdo} >

        <Grid container item className={classes.ContainerEstrategi } >

              <Grid container item justifyContent="center" alignItems="center" sm={12} className={classes.Titulo}> 
                <p className={classes.Estrategia} >Estrategia de lectura</p>
              </Grid>

              <Grid container item sm={12} alignItems="center" justifyContent="center" className={classes.Objet1}>
                  <p className={classes.TextStrg}>
                    Cuando lees un texto, la conjugación de los verbos es muy importante. ¿El evento ya pasó? ¿Pasará? ¿Pasará si se cumplen algunas condiciones? ¿Es imposible que pase? Todo esto te lo dice la conjugación del verbo.
                    <div className="Br"></div>
                    Normalmente, como hablante de una lengua, puedes entender estos datos de forma intuitiva, pero nunca está de más reflexionarlos un poco. Cada vez que te encuentres un verbo en la lectura pregúntate y anota en tu cuaderno qué características tiene:
                    
                    <ol type="d" start="1" className="ul">
                      <li className="li">
                        ¿Está conjugado? Si la respuesta es <em>no</em>, entonces debe estar en uno de estos tres modos:
                      </li>
                    </ol>

                    <ol type="a" start="a" className="ul">
                      <li className="li">
                       Infinitivo (-ar, -er, -ir), donde el verbo es un concepto. A veces aparece como sujeto de la oración (<em><u>Fumar</u> propicia enfermedades</em> ).
                      </li>
                      <li className="li">
                        Participio (-ado, -ido, -to, -so, -cho), donde el verbo se vuelve un adjetivo (<em>El libro <u>deshojado</u></em>).

                      </li>
                      <li className="li">
                        Gerundio (-ando, -iendo), donde el verbo se vuelve un complemento circunstancial de modo (<em>Regresé <u>caminando</u></em>).
                      
                      </li>
                    </ol>

                    <ol type="d" start="2" className="ul">
                      <li className="li">
                        Si el verbo está conjugado, debe estar en indicativo, imperativo o subjuntivo:
                      </li>
                    </ol>

                    <ol type="a" start="a" className="ul">
                      <li className="li">
                        El imperativo se usa para dar órdenes.
                      </li>
                      <li className="li">
                        El subjuntivo se usa en oraciones con más de un verbo (llamadas <em>compuestas</em>). Es difícil entender el subjuntivo suelto y suena más natural si se le agrega un que antes 
                        (<em>Me dijo <u>que hiciera</u> esto</em>).
                      </li>
                      <li className="li">
                        El indicativo no tiene el problema del subjuntivo: el verbo puede decirse solo (<em>Él <u>volverá</u></em>).
                      </li>
                    </ol>

                    <ol type="d" start="3" className="ul">
                      <li className="li">
                        ¿Es un verbo en singular o en plural? ¿Qué persona realiza el verbo?
                      </li>
                    </ol>

                    <ol type="a" start="a" className="ul">
                      <li className="li">
                        Yo (1ª persona del singular)
                      </li>
                      <li className="li">
                        Tú, vos o usted (2ª persona del singular)
                      </li>
                      <li className="li">
                        Él o ella (3ª persona del singular)
                      </li>
                      <li className="li">
                        Nosotros (1ª persona del plural)
                      </li>
                      <li className="li">
                        Ustedes o vosotros (2ª persona del plural)
                      </li>
                      <li className="li">
                        Ellos o ellas (3ª persona del plural)
                      </li>
                    </ol>

                    <ol type="d" start="4" className="ul">
                      <li className="li">
                        ¿En qué tiempo está el verbo? ¿Es presente, futuro, pretérito, copretérito o pospretérito?
                      </li>
                    </ol>

                    Si tienes dudas o dificultades para encontrar estas respuestas consulta con tu profesor.

                  </p>

              </Grid>
                
        </Grid>
        
        <Grid container item sm={12} className={classes.ContainerBarraInferior} justifyContent="center">
            <Button id="BotonContinuarEstrategias" className={classes.BotonAvanza} onClick={handleContinue}>
              Continuar
            </Button>
        </Grid>
        
      </Grid>

      <Grid container item sm={12} className={classes.ContainerBarraDerecha}></Grid>

    </Grid>

  )
}

export default ReadingStrategys;
