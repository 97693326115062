import { Button, Grid, makeStyles } from "@material-ui/core";
import React, { useContext, useEffect } from "react";
import DrawerContext from "../../../../../usability/DrawerContext";
import { useOnLoadImages } from "../../../../hooks/useOnLoadImages";
import Loader from "../../../../Loader/Loader";
import Drawer from "../../ReadingTools/Drawer";

const useStyles =  makeStyles (theme =>({
     Contenedor:{
        background:'#E9FBF9',
        width:'100vw',
        overflowX:'clip',
        [theme.breakpoints.down(1161)]:{
            background:'none',
           overflowX:'unset',
        },
        [theme.breakpoints.down(600)]:{
            marginTop: '79.51px',
        }
    },
  ContainerInfoLectura: {
    position: "relative",
    height: "86px",
    [theme.breakpoints.down(502)]:{
        height: "43px",
    },
    background: "#76D4C3",
    "& .BarraInfoLectura": {
      height: "86px",
      background: `Url(https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Barras/Azul.png)`,
        [theme.breakpoints.down(502)]:{
            height: "43px",
        },
    },
    "& .InfoLectura": {
      position: "absolute",
      height: "86px",
      width: "219px",
      boxSizing: "border-box",
      padding: "17.2px 0",
      background: "#FAFAFA",
      borderRadius: "50px",
      boxShadow: "2px 2px 2px 2px #00000029",
      zIndex: "4",
        [theme.breakpoints.down(502)]:{
            height: "43px",
            width: "109px",
            padding: "8.6px 0",
            borderRadius: "25px",
        },
    },
    "& .InfoLectura:before": {
      position: "absolute",
      display: "flex",
      boxSizing: "border-box",
      padding: "0 0 0 10px",
      justifyContent: "center",
      alignItems: "center",
      height: "39.88px",
      width: "39.88px",
      borderRadius: "50%",
      content: '""',
      boxShadow: "3px 0px .5px 0px #00000029",
      background: "#FAFAFA",
      left: "90px",
      top: "65px",
      transform: "rotate(0.25turn)",
      [theme.breakpoints.down(502)]:{
        left: "45px",
        top: "30px",
        height: "20.88px",
        width: "20.88px",
        },
    },
    "& .InfoLectura:after": {
      content: '""',
      height: "15px",
      width: "15px",
      borderTop: "2px solid #20A698",
      borderLeft: "2px solid #20A698",
      position: "absolute",
      bottom: "-10px",
      left: "Calc(50% - 8px)",
      transform: "rotate(0.625turn)",
      [theme.breakpoints.down(502)]:{
        height: "10px",
        width: "10px",
        left: "Calc(50% - 4.5px)",
        bottom: "-3px",
        borderTop: "1px solid #20A698",
        borderLeft: "1px solid #20A698",
        },
    },
    "& .Sesion": {
      borderLeft: "2px solid #D8D8D8",
    },
    "& .Dato": {
      margin: "0 0 5px 0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#20A698",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        margin: "0 0 2.5px 0",
        },
    },
    "& .Caracter": {
      margin: "0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#000000",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        },
    },
       '& .Regresar':{
        color: '#F2624F',
        border: '#F2624F 1px solid',
        height: '30px',
        width:'100px',
        borderRadius: '20px',
        letterSpacing: '0.14px',
        textTransform: 'none',
        background:'#FEFAF1',
        top:'calc(50% - 15px)',
        left:'5%',
        zIndex:4,
        [theme.breakpoints.down(502)]: {
          font:'bold 10px lato',
          width: "60px",
        },
      },
  },
    ContenedorLectura:{
        background:'#fff',
        width:'1160px',
        height:'100%',
        padding:'60px 168px 35px ', 
        
        transform: (props) =>
        props.scale > 1 ? `scale(${props.scale}) translate(0px, 10%)` : `none`,
        [theme.breakpoints.down(1161)]:{
            width:'502px',
            padding:'60px 0 0 0',
          },
        [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },

        '& .Escritorio':{
            display:'block',
            [theme.breakpoints.down(1161)]:{
                display:'none'
            },
        },
        '& .Tablet':{
            display:'none',
            [theme.breakpoints.down(1161)]:{
                display:'block'
            },
        }
    },
    ContainerTitulo:{
        margin:'0 0 34px 0',
        [theme.breakpoints.down(1161)]:{
            margin:'0 0 34px 0',
        },
        '& .Titulo':{
            font:'48px fredoka one',
            letterSpacing: '0px',
            lineHeight:'58px',
            color: '#81A247',
            margin:'0',
            textAlign:'center',
            [theme.breakpoints.down(1161)]:{
                font:'43px fredoka one',
                lineHeight:'52px',
            },
            [theme.breakpoints.down(1161)]:{
                font:'38px fredoka one',
                lineHeight:'52px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            lineHeight : '30px',
            margin:'0 0 36px 0',
            font:'22px lato',
            color:'#000',
            boxSizing:'border-box',
            letterSpacing: '0.20px',
            [theme.breakpoints.down(1161)]:{
                font:'18px lato',
                lineHeight :'28px',
                letterSpacing: '0.17px',
            },
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .Lectura2':{
            lineHeight : '28px',
            font:'20px lato',
            color:'#000000',
            letterSpacing: '0.20px',
            margin:'0 0 4px 0',
            [theme.breakpoints.down(1161)]:{
                font:'18px lato',
                lineHeight :'28px',
                letterSpacing: '0.17px',
                margin:'0 0 5px 0',
            },
            [theme.breakpoints.down(502)]:{
                font:'14px lato',
                lineHeight :'17px',
                letterSpacing: '0.14px',
                width:'315px',
            },
        },
        '& .SubTitulo':{
            font:'bold 30px lato',
            color:'#CF3E2A',
            letterSpacing:'.28px',
            lineHeight:'30px',
            margin:'0',
            [theme.breakpoints.down(1161)]:{
                lineHeight :'28px',
                font:'bold 26px lato',
                letterSpacing: '0.24px',
            },
            [theme.breakpoints.down(502)]:{
                font:'bold 18px lato',
                lineHeight :'24px',
                letterSpacing: '0.21px',
            },
        },
        '& .SubTitulo2':{
            font:' 23px Fredoka One',
            color:'#FFFFFF',
            letterSpacing:'.28px',
            lineHeight:'30px',
            margin:'0',
            textAlign:'center',
            width:'137px',
            [theme.breakpoints.down(1161)]:{
                width:'100%',
                lineHeight :'30px',
                font:' 21px Fredoka One',
                letterSpacing: '0.2px',
            },
            [theme.breakpoints.down(502)]:{
                font:' 18px Fredoka One',
                lineHeight :'24px',
            },
        },
        '& .Cuadro':{
            margin:'0 0 21px 0'
        },
        '& .Tribu':{
            position:'relative',
            margin:'0 0 53px 0'
        },
        '& .Pos':{
            position:'absolute',
        } ,
        '& .Tribu1':{
            top:'5px',
            left:'70px',
            [theme.breakpoints.down(1161)]:{
                top:'1%',
                left:'0',
            },
            
            
        },
        '& .Tribu2':{
            top:'5px',
            left:'345px',
            [theme.breakpoints.down(1161)]:{
                top:'33.5%',
                left:'0px',
            },
            [theme.breakpoints.down(502)]:{
                top:'34.7%',
                left:'0px',
            },
        },
        '& .Tribu3':{
            top:'20px',
            left:'620px',
            [theme.breakpoints.down(1161)]:{
                top:'69.2%',
                left:'0px',
            },
        },
        '& .Tribu1Ul':{
            margin:0,
            padding:'0 0 0 15px',
            color:'#BDD654',
            top:'84px',
            left:'10px',
            [theme.breakpoints.down(1161)]:{
                width:'93%',
                top:'5.5%',
                left:'3%',
                color:'#82CBCD',
            },
            [theme.breakpoints.down(502)]:{
                padding:'0 0 0 15px',
                width:'93%',
                top:'5%',
            },
        },
        '& .Tribu2Ul':{
            margin:0,
            padding:'0 0 0 15px',
            color:'#2DDAC9',
            top:'84px',
            left:'290px',
            [theme.breakpoints.down(1161)]:{
                width:'90%',
                top:'37.5%',
                left:'3%',
            },
            [theme.breakpoints.down(502)]:{
                padding:'0 0 0 15px',
                top:'38%',
                width:'94%',
            },
        },
        '& .Tribu3Ul':{
            margin:0,
            padding:'0 0 0 15px',
            color:'#E64E2E',
            top:'84px',
            left:'570px',
            [theme.breakpoints.down(1161)]:{
                width:'93%',
                top:'73.5%',
                left:'3%',
                
            },
            [theme.breakpoints.down(502)]:{
                padding:'0 0 0 15px',
                width:'94%',
                top:'73%',

            },
            
        },

        '& .TextImg':{
            font:'20px lato',
            color:'#717476',
            letterSpacing:'.19px',
            lineHeight:'30px',
            margin:'24px 0 0 0',
            [theme.breakpoints.down(1161)]:{
                padding:'0 ',
                font:' 16px lato',
                lineHeight :'25px',
                letterSpacing: '0.15px',
            },
            [theme.breakpoints.down(502)]:{
                font:' 11px lato',
                lineHeight :'14px',
                letterSpacing: '0.10px',
            },
        },

        '& .Imagen':{
            [theme.breakpoints.down(1161)]:{
                width:'100%',
            },
        },  
        '& .Imagen2':{
            [theme.breakpoints.down(1161)]:{
                width:'100%',
            },
            [theme.breakpoints.down(502)]:{
                height:'275px',
            },
            
        }, 
      
    }
}));

const Los_nómadas_de_la_Amazonia = (props) => {

  const {activeStep, setActiveStep, handlePreviewStep, id_actividad} = props
  
  const handleContinue = () => {
    setActiveStep(activeStep - 1)
    handlePreviewStep()
  }

    const {
      handleSize,
    disabledZoom,
    closeZoom,
    handleZoom,
    scale
  }= useContext(DrawerContext);
  const [loading, setLoading] = React.useState(true);
  const wrapperRef = React.useRef(null);
  const imagesLoaded = useOnLoadImages(wrapperRef);

  const detectSize = () => {
    const lectura = document.getElementById("lectura");
    const newWidth = lectura?.offsetWidth;
    const newHeight = lectura?.offsetHeight;
    setLoading(false);
    if (lectura) handleSize(newHeight, newWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", detectSize);
    return () => {
      handleZoom()
      window.removeEventListener("resize", detectSize);
      closeZoom()
    };
  }, []);

  const classes = useStyles({ disabledZoom,scale });
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
             <Grid container item justifyContent="center" className={classes.ContainerInfoLectura}>
                       <Grid container className="BarraInfoLectura">
            <Button className="Regresar" onClick={handleContinue}>
                Regresar
            </Button>
        </Grid>
               <Grid container item className="InfoLectura">
                    <Grid container item direction="column" xs={6} justifyContent="center">
                        <p className="Dato">Grado</p>
                        <p className="Caracter">5</p>
                    </Grid>
                    <Grid container item direction="column" xs={6} justifyContent="center" className="Sesion">
                        <p className="Dato">Sesión</p>
                        <p className="Caracter">11</p>
                    </Grid>
               </Grid>
            </Grid>
            {!imagesLoaded && <Loader />}
        <Grid
            container
            item
            className={classes.ContenedorLectura}
            justifyContent="center"
            id="lectura"
            onLoad={detectSize}
            ref={wrapperRef}
        >
<Drawer id_lectura={id_actividad} />
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                        <h2 className="Titulo Escritorio">Los nómadas de la Amazonia</h2>
                        <h2 className="Titulo Tablet">Los nómadas<br/> de la Amazonia</h2>

                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                       <p className='Lectura'>
                        <h3 className='SubTitulo'>Sedentarios y nómadas</h3><br/>
                        
                        La mayoría de las comunidades humanas modernas son <em>sedentarias</em>. 
                        Eso quiere decir que se establecen en un lugar determinado y 
                        allí hacen su vida. Las personas migran con cierta frecuencia (como individuos), 
                        pero los grupos completos sólo lo hacen en casos extraordinarios.<br/><br/> 

                        Asimismo, existen comunidades que no viven en un lugar fijo, sino que se 
                        mueven de un lugar a otro constantemente. En la mayoría de los casos 
                        se desplazan para obtener alimento y otros productos que les permitan 
                        sobrevivir. Los llamamos <em>nómadas</em>. <br/><br/>
                        
                        <h3 className='SubTitulo'>Tipos de nómadas</h3><br/>

                        Existen tres clases de nómadas: los de pastoreo, los cazadores-recolectores y los viajeros.
                        
                       </p>                   
                    </Grid>
                    <Grid container justifyContent='space-between' className='Tribu'>
                        <img className='Escritorio' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion11/5.png" alt="Tarjeta" />
                        <img className='Escritorio' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion11/6.png" alt="Tarjeta" />
                        <img className='Escritorio' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion11/7.png" alt="Tarjeta" />

                        <img className='Tablet Cuadro Imagen2' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion11/5R.png" alt="Tarjeta" />
                        <img className='Tablet Cuadro Imagen2' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion11/6R.png" alt="Tarjeta" />
                        <img className='Tablet Imagen2' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion11/7R.png" alt="Tarjeta" />

                        <h3 className='Pos SubTitulo2 Tribu1 Escritorio'>Nómadas de pastoreo</h3>
                        <h3 className='Pos SubTitulo2 Tribu2 Escritorio'>Cazadores-recolectores</h3>
                        <h3 className='Pos SubTitulo2 Tribu3 Escritorio'>Viajeros</h3>

                        <h3 className='Pos SubTitulo2 Tribu1 Tablet'>Nómadas de pastoreo</h3>
                        <h3 className='Pos SubTitulo2 Tribu2 Tablet'>Cazadores-recolectores</h3>
                        <h3 className='Pos SubTitulo2 Tribu3 Tablet'>Viajeros</h3>

                        <ul className='Pos Tribu1Ul Escritorio'>
                            <li><p className='Lectura2'>Se desplazan porque <br/>buscan pasto para <br/>alimentar a sus <br/>animales.</p></li>
                            <li><p className='Lectura2'>Llegan a establecer <br/>relaciones comerciales <br/>con poblaciones <br/>sedentarias. <br/>Intercambian sus <br/>productos ganaderos <br/>por productos agrícolas <br/>u otras cosas.</p></li>
                            <li><p className='Lectura2'>Se localizan en algunas<br/> partes de Asia Oriental, <br/>África y Medio Oriente, y <br/>en algunas regiones <br/>montañosas de <br/>Sudamérica y Europa.</p></li>
                            <li><p className='Lectura2'>Existen entre 30 y 50 <br/>millones de nómadas de <br/>pastoreo.</p></li>
                        </ul>
                        <ul className='Pos Tribu1Ul Tablet'>
                            <li><p className='Lectura2'>Se desplazan porque buscan pasto para alimentar a sus animales.</p></li>
                            <li><p className='Lectura2'>Llegan a establecer relaciones comerciales con poblaciones sedentarias. Intercambian sus productos ganaderos por productos agrícolas u otras cosas.</p></li>
                            <li><p className='Lectura2'>Se localizan en algunas partes de Asia Oriental, África y Medio Oriente, y en algunas regiones montañosas de Sudamérica y Europa.</p></li>
                            <li><p className='Lectura2'>Existen entre 30 y 50 millones de nómadas de pastoreo.</p></li>
                        </ul>
                        <ul className='Pos Tribu2Ul Escritorio'>
                            <li><p className='Lectura2'>Se mueven en busca de <br/>alimento para sí <br/>mismos.</p></li>
                            <li><p className='Lectura2'>A veces se mueven <br/>siguiendo a los animales <br/>que cazan. En otras <br/>ocasiones viajan en <br/>busca de lugares con <br/>más recursos (como <br/>plantas comestibles).</p></li>
                            <li><p className='Lectura2'>Llegan a modificar su <br/>entorno al plantar <br/>cultivos medicinales o <br/>comestibles para su <br/>consumo.</p></li>
                            <li><p className='Lectura2'>Se localizan en lugares <br/>como el Ártico, la <br/>Amazonia, Asia Oriental, <br/>la India y en el sur y el<br/> este de África.</p></li>
                            <li><p className='Lectura2'>Se calcula que hay cerca <br/>de 250 000 alrededor <br/>del mundo.</p></li>
                        </ul>
                        <ul className='Pos Tribu2Ul Tablet'>
                            <li><p className='Lectura2'>Se mueven en busca de alimento para sí mismos.</p></li>
                            <li><p className='Lectura2'>A veces se mueven siguiendo a los animales que cazan. En otras ocasiones viajan en busca de lugares con más recursos (como plantas comestibles).</p></li>
                            <li><p className='Lectura2'>Llegan a modificar su entorno al plantar cultivos medicinales o comestibles para su consumo.</p></li>
                            <li><p className='Lectura2'>Se localizan en lugares como el Ártico, la Amazonia, Asia Oriental, la India y en el sur y el este de África.</p></li>
                            <li><p className='Lectura2'>Se calcula que hay cerca de 250 000 alrededor del mundo.</p></li>
                        </ul>
                        <ul className='Pos Tribu3Ul Escritorio'>
                            <li><p className='Lectura2'>Por lo general viven <br/>mezclados con <br/>poblaciones <br/>sedentarias (lo que <br/>algunas veces genera <br/>conflictos).</p></li>
                            <li><p className='Lectura2'>Existen dos grandes <br/>grupos: los romaníes y <br/>los viajeros irlandeses.</p></li>
                            <li><p className='Lectura2'>Los romaníes son el <br/>grupo más conocido y<br/> numeroso. Se calcula que <br/>hay 12 millones en todo <br/>el mundo.</p></li>
                            <li><p className='Lectura2'>Los viajeros irlandeses <br/>se localizan <br/>principalmente en <br/>Irlanda y el Reino Unido.</p></li>
                            <li><p className='Lectura2'>Suelen vivir en casas <br/>rodantes o botes.</p></li>
                        </ul>
                        <ul className='Pos Tribu3Ul Tablet'>
                            <li><p className='Lectura2'>Por lo general viven mezclados con poblaciones sedentarias (lo que algunas veces genera conflictos).</p></li>
                            <li><p className='Lectura2'>Existen dos grandes grupos: los romaníes y los viajeros irlandeses.</p></li>
                            <li><p className='Lectura2'>Los romaníes son el grupo más conocido y numeroso. Se calcula que hay 12 millones en todo el mundo.</p></li>
                            <li><p className='Lectura2'>Los viajeros irlandeses se localizan principalmente en Irlanda y el Reino Unido.</p></li>
                            <li><p className='Lectura2'>Suelen vivir en casas rodantes o botes.</p></li>
                        </ul>

                    </Grid>
                    <Grid>
                        <Grid container >
                            <img className='Imagen' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion11/1.png" alt="Tarjeta" />   
                        </Grid>
                        <Grid container>
                            <p className='TextImg'>
                            Los grupos romaníes frecuentemente son llamados <em>gitanos</em>. Esta palabra viene de <em>egiptano</em>, y se les llama 
                            así porque a finales de la Edad Media y principios de la modernidad se creía que venían de Egipto.
                            </p>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container >
                        <p className='Lectura'>
                            <h3 className='SubTitulo'>Resistencia en movimiento</h3><br/>
                            
                            En América Latina existen algunas tribus nómadas, como las localizadas en la
                            Amazonia. La mayoría de ellas comparte una cultura común basada en la caza 
                            y el cultivo de huertos para autoconsumo. También hay algunos grupos que
                            no cultivan y se dedican sólo a la recolección, como los nukak, un pueblo 
                            de Colombia. Hay también algunos que son seminómadas: aunque tienen aldeas, 
                            pasan una parte del año recorriendo la selva. En esta categoría están 
                            los kayapo, de Brasil.
                        </p>
                    </Grid>
                    <Grid container justifyContent='center'>
                        <Grid container justifyContent='center'>
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion11/2.png" alt="Tarjeta" />
                        </Grid>
                        <Grid container >
                            <p className="TextImg">
                                ¿Has escuchado alguna vez sobre los nukak? Te invitamos a investigar sobre su modo de vida.
                            </p>
                        </Grid>
                    </Grid>

                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container >
                        <p className='Lectura'>
                            Estudios recientes indican que quizá algunas de las poblaciones nómadas 
                            de la Amazonia eran sedentarias, pero la llegada de los europeos al 
                            continente las obligó a internarse en la selva y a transformar su forma 
                            de vida para protegerse. Desde entonces la cultura en esa zona puede 
                            describirse como una convivencia entre las poblaciones originarias y las 
                            derivadas de la colonización. Las primeras luchan por mantener su cultura, 
                            en tanto que las segundas han intentado adaptarlas a su forma de vida.
                        </p>
                    </Grid>
                    <Grid container justifyContent='center'>
                        <Grid container justifyContent='center'>
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion11/3.png" alt="Tarjeta" />
                        </Grid>
                        <Grid container >
                            <p className="TextImg">
                                Llos kayapo han tenido que luchar para preservar la selva.
                            </p>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container >
                        <p className='Lectura'>
                            En la actualidad las poblaciones sedentarias se extienden y cada vez
                            utilizan más terreno para actividades agrícolas o industriales. Como 
                            consecuencia, los territorios habitados por las tribus nómadas se han 
                            reducido. Esto ha provocado que algunas poblaciones nómadas se integren 
                            a la vida sedentaria, pero también ha propiciado que muchas otras se 
                            involucren en la defensa del medio ambiente.
                        </p>
                    </Grid>
                    <Grid container justifyContent='center'>
                        <Grid container justifyContent='center'>
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion11/4.png" alt="Tarjeta" />
                        </Grid>
                        <Grid container >
                            <p className="TextImg">
                                La acción de las poblaciones sedentarias ha afectado terriblemente el ecosistema del Amazonas, del que dependían pueblos como los nukak y los kayapo.
                            </p>
                        </Grid>
                    </Grid>
                </Grid>
        </Grid>
        <Grid container item style={{ marginTop: 80}} ></Grid>
    </Grid>
    );
}
 
export default Los_nómadas_de_la_Amazonia;
