import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
     Contenedor:{
        background:'#E9FBF9',
        width:'100vw',
        overflowX:'clip',
        [theme.breakpoints.down(1161)]:{
            background:'none',
           overflowX:'unset',
        },
        [theme.breakpoints.down(600)]:{
            marginTop: '79.51px',
        }
    },
  ContainerInfoLectura: {
    position: "relative",
    height: "86px",
    [theme.breakpoints.down(502)]:{
        height: "43px",
    },
    background: "#76D4C3",
    "& .BarraInfoLectura": {
      height: "86px",
      background: `Url(https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Barras/Azul.png)`,
        [theme.breakpoints.down(502)]:{
            height: "43px",
        },
    },
    "& .InfoLectura": {
      position: "absolute",
      height: "86px",
      width: "219px",
      boxSizing: "border-box",
      padding: "17.2px 0",
      background: "#FAFAFA",
      borderRadius: "50px",
      boxShadow: "2px 2px 2px 2px #00000029",
      zIndex: "4",
        [theme.breakpoints.down(502)]:{
            height: "43px",
            width: "109px",
            padding: "8.6px 0",
            borderRadius: "25px",
        },
    },
    "& .InfoLectura:before": {
      position: "absolute",
      display: "flex",
      boxSizing: "border-box",
      padding: "0 0 0 10px",
      justifyContent: "center",
      alignItems: "center",
      height: "39.88px",
      width: "39.88px",
      borderRadius: "50%",
      content: '""',
      boxShadow: "3px 0px .5px 0px #00000029",
      background: "#FAFAFA",
      left: "90px",
      top: "65px",
      transform: "rotate(0.25turn)",
      [theme.breakpoints.down(502)]:{
        left: "45px",
        top: "30px",
        height: "20.88px",
        width: "20.88px",
        },
    },
    "& .InfoLectura:after": {
      content: '""',
      height: "15px",
      width: "15px",
      borderTop: "2px solid #20A698",
      borderLeft: "2px solid #20A698",
      position: "absolute",
      bottom: "-10px",
      left: "Calc(50% - 8px)",
      transform: "rotate(0.625turn)",
      [theme.breakpoints.down(502)]:{
        height: "10px",
        width: "10px",
        left: "Calc(50% - 4.5px)",
        bottom: "-3px",
        borderTop: "1px solid #20A698",
        borderLeft: "1px solid #20A698",
        },
    },
    "& .Sesion": {
      borderLeft: "2px solid #D8D8D8",
    },
    "& .Dato": {
      margin: "0 0 5px 0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#20A698",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        margin: "0 0 2.5px 0",
        },
    },
    "& .Caracter": {
      margin: "0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#000000",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        },
    },   },
    ContenedorLectura:{
        background:'#fff',
        width:'1160px',
        height:'100%',
        padding:'60px 168px 35px ',
        [theme.breakpoints.down(1161)]:{
            width:'502px',
            padding:'60px 0 0 0',
          },
        [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
        '& .ContainerDerechos':{
            bottom:'80px',
            [theme.breakpoints.down(1161)]:{
                display:'none',
            },
        },
        '& .Derechos':{
            textAlign:"center",
            margin:"0px",
            font:" 15px lato",
            color:"#717476",
        },
        '& .Escritorio':{
            display:'block',
            [theme.breakpoints.down(1161)]:{
                display:'none'
            },
        },
        '& .Tablet':{
            display:'none',
            [theme.breakpoints.down(1161)]:{
                display:'block'
            },
        }
    },
    ContainerTitulo:{
        margin:'0 0 36px 0',
        '& .Titulo':{
            letterSpacing: '0px',
            color: '#A4216C',
            margin:'0',
            textAlign:'center',
            font:'48px fredoka one',
            [theme.breakpoints.down(1161)]:{
                font:'43px fredoka one',
                lineHeight:'52px',
            },
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            margin:'0 0 36px 0',
            color:'#000',
            font:'22px lato',
            letterSpacing: '0.20px',
            lineHeight : '30px',
            [theme.breakpoints.down(1161)]:{
                font:'18px lato',
                lineHeight :'28px',
                letterSpacing: '0.17px',
            },
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .SubTitulo':{
            color:'#DF5DA7',
            margin:'0',
            font:'30px Fredoka One',
            letterSpacing:'.28px',
            [theme.breakpoints.down(1161)]:{
                font:'23px Fredoka One',
                lineHeight :'30px',
                letterSpacing: '0.22px',
            },
            [theme.breakpoints.down(502)]:{
                font:'18px Fredoka One',
                lineHeight :'20px',
                letterSpacing: '0.16px',
            },
        },
        '& .TextImg':{
            color:'#717476',
            font:'20px lato',
            textAlign:'center',
            margin:'22px 0 0 0',
            lineHeight :'30px',
            letterSpacing: '0.19px',
            [theme.breakpoints.down(1161)]:{
                font:' 16px lato',
                lineHeight :'25px',
                letterSpacing: '0.15px',
            },
            [theme.breakpoints.down(502)]:{
                font:' 11px lato',
                lineHeight :'14px',
                letterSpacing: '0.10px',
            },
        },
        '& .TextImg2':{
            color:'#717476',
            font:'20px lato',
            margin:'22px 0 0 0',
            lineHeight :'28px',
            letterSpacing: '0.19px',
            [theme.breakpoints.down(1161)]:{
                font:' 16px lato',
                lineHeight :'25px',
                letterSpacing: '0.15px',
            },
            [theme.breakpoints.down(502)]:{
                font:' 11px lato',
                lineHeight :'14px',
                letterSpacing: '0.10px',
            },
        },
        '& .Esp':{
            margin:'0',
            boxSizing:'border-box',
            padding:'0 19px 0 0',
            [theme.breakpoints.down(1161)]:{
                padding:'0 35px 0 0',
            },
            [theme.breakpoints.down(502)]:{
                padding:'0 35px 0 0',
            },
        },
        '& .Cuadro':{
            position:'relative',
        },
        '& .Esp2':{
            position:'absolute',
            margin:'78px 0 0 0',
            width:'739px',
            [theme.breakpoints.down(1161)]:{
                width:'77.45%',
            },
            [theme.breakpoints.down(502)]:{
                margin:' 55px 0 0 0',
                font:' 14px lato!important',
                lineHeight :'15px!important',
                letterSpacing: '0.10px',
            },
            [theme.breakpoints.down(350)]:{
                font:' 12px lato!important',
                lineHeight :'13px!important',
                letterSpacing: '0.10px',
            },
        },

        '& .Imagen':{
            [theme.breakpoints.down(1161)]:{
                width:'100%'
            },
        },
        '& .Imagen2':{
            [theme.breakpoints.down(1161)]:{
                width:'238px'
            },
            [theme.breakpoints.down(1161)]:{
                width:'165px'
            },
        },
        '& .ImgEsp':{
            [theme.breakpoints.down(1161)]:{
                width:'100%'
            },
            [theme.breakpoints.down(502)]:{
                height:'205px'
            },
            [theme.breakpoints.down(350)]:{
                height:'190px'
            },
        },
        '& .Link':{
            textDecoration:'none',
        }
    },

    
    

}));

const Hipatia_de_Alejandría_y_las_matemáticas = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
             <Grid container item justifyContent="center" className={classes.ContainerInfoLectura}>
               <Grid container className="BarraInfoLectura"></Grid>
               <Grid container item className="InfoLectura">
                    <Grid container item direction="column" xs={6} justifyContent="center">
                        <p className="Dato">Grado</p>
                        <p className="Caracter">3</p>
                    </Grid>
                    <Grid container item direction="column" xs={6} justifyContent="center" className="Sesion">
                        <p className="Dato">Sesión</p>
                        <p className="Caracter">26</p>
                    </Grid>
               </Grid>
            </Grid>

            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >
                        Hipatia de Alejandría<br/> y las matemáticas
                    </h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                            ¿Alguna vez te has preguntado quién inventó las matemáticas? Las que estudias en la 
                            escuela y las que utilizas diariamente, como cuando cuentas los días en el calendario. 
                            O cuando te diviertes con juegos como las Torres de Hanói. Pero ¿de dónde vienen
                            todos esos cálculos? Hay más de una respuesta correcta, pues fueron cientos de 
                            personas quienes gradualmente compartieron nuevos hallazgos a lo largo de los 
                            siglos. De hecho, ¡ahora mismo sigue sucediendo!<br/><br/> 
                            
                            A estos individuos los llamamos <em>matemáticos</em>. Pero no pienses que sólo han sido 
                            hombres. Las mujeres han sumado mucho en el desarrollo de esta ciencia exacta. Y 
                            hoy conoceremos a una de ellas: Hipatia de Alejandría.
                        </p>
                    </Grid>
                    <Grid container item justifyContent='center'>
                        <Grid container item justifyContent='center' xs={12}>
                            <img className='Imagen' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img3ro/Sesion26/1.png" alt="" />
                        </Grid>
                        <Grid container item justifyContent='center' xs={12}>
                            <p className='TextImg'>Hipatia de Alejandría</p>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                <Grid container item xs={12} className="Tablet">
                        <p className='Lectura '>
                           <h3 className='SubTitulo '>La primera mujer matemática</h3><br/> 
                            
                            Los habitantes de la antigua Grecia no tenían 
                            apellidos, por eso era común llamarlos por su 
                            nombre y decir la ciudad de la que provenían 
                            para distinguirlos entre sí. <em>Hipatia</em> era su 
                            nombre y nació en Alejandría en el año 355 de 
                            nuestra era. Parte de la información que hoy 
                            sabemos sobre ella fue encontrada en las 
                            cartas que intercambió con su alumno, el 
                            obispo Sinesio de Cirene.
                        </p>
                    </Grid>
                    <Grid container item xs={7}>
                        <p className='Lectura Esp'>
                            <span className="Escritorio"><h3 className='SubTitulo'>La primera mujer matemática</h3><br/> 
                            
                            Los habitantes de la antigua Grecia no tenían 
                            apellidos, por eso era común llamarlos por su 
                            nombre y decir la ciudad de la que provenían 
                            para distinguirlos entre sí. <em>Hipatia</em> era su 
                            nombre y nació en Alejandría en el año 355 de 
                            nuestra era. Parte de la información que hoy 
                            sabemos sobre ella fue encontrada en las 
                            cartas que intercambió con su alumno, el 
                            obispo Sinesio de Cirene.<br/><br/></span>
                            
                            Su padre fue un astrónomo que daba clases en 
                            el Museo de Alejandría. Fue él quien le inspiró 
                            el amor por las matemáticas, la astronomía y la 
                            filosofía. Hipatia hizo grandes contribuciones 
                            a la geometría, el álgebra y la astronomía. 
                            También construyó un astrolabio plano, un 
                            instrumento que sirve para ver la posición de 
                            las estrellas y los planetas.
                        </p>
                    </Grid>
                    <Grid container item alignContent='flex-end' xs={5}>
                        <Grid container item justifyContent='center' xs={12}>
                            <img className='Imagen2' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img3ro/Sesion26/2.png" alt="" />
                        </Grid>
                        <Grid container item justifyContent='center' xs={12}>
                            <p className='TextImg '>Un astrolabio como el que<br/> construyó Hipatia.</p>
                        </Grid>                    
                    </Grid>

                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                            Viajó a Atenas e Italia para completar sus estudios. Al volver comenzó a impartir 
                            lecciones de ciencia y filosofía a cualquiera que las pidiera, sin importar su religión.
                            En ese entonces en Alejandría había escuelas diferentes para los paganos, los judíos 
                            y los cristianos. Los primeros creían en los diferentes dioses griegos de la Antigüedad, 
                            por lo tanto, eran <em>politeístas</em>. Esta palabra proviene de <em>poli</em>, que significa “muchos”, y 
                            <em> théos</em>, que quiere decir “dios”. Es decir, <em>creer en muchos dioses</em>.<br/><br/> 
                            
                            Por otro lado, judíos y cristianos, con ciertas diferencias, adoraban a un solo dios, de 
                            manera que eran <em>monoteístas</em>. La religión cristiana comenzó a obtener más poder con 
                            el paso del tiempo y los paganos fueron perseguidos por tener otra fe. Entre ellos 
                            estaba Hipatia, quien fue asesinada en el año 415 por un grupo de cristianos que se 
                            oponía a su forma de pensar.<br/><br/>  
                            
                            Ahora conoces un poco más sobre la historia de los números y el estudio de los 
                            planetas. ¡Algunos de los aportes de Hipatia siguen usándose actualmente!
                        </p>
                    </Grid>
                    <Grid container justifyContent="center" className='Cuadro' sm={12}>
                        <img className='Imagen Escritorio' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img3ro/Sesion26/3.png" alt="" />
                        <img className='ImgEsp Tablet' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img3ro/Sesion26/3R.png" alt="" />
                        <p className='TextImg2 Esp2'>
                            Puedes aprender más sobre esta extraordinaria mujer pulsando el siguiente enlace: <br/>
                            Grupo Azarquiel. Hipatia de Alejandría (s/f). Chile: SM. Disponible en 
                            <a className='Link' href="https://centroderecursos.educarchile.cl/handle/20.500.12246/54491" target="_blank"> https://centroderecursos.educarchile.cl<br className='tablet'/>/handle/20.500.12246/54491</a><br className='tablet'/>  (Consulta: 18 de agosto de 2021).
                        </p>
                    </Grid>
                    
                </Grid>

                <Grid container item xs={12} justifyContent="center" className="ContainerDerechos">
                    <p className="Derechos">
                        © 2022. Todos los derechos reservados, Pearson, Circuito de lectores.
                    </p>                
                </Grid>
        </Grid>
    </Grid>
    );
}
 
export default Hipatia_de_Alejandría_y_las_matemáticas;
