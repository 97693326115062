import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
      Contenedor:{
        background:'#E9FBF9',
        width:'100vw',
        overflowX:'clip',
        [theme.breakpoints.down(1161)]:{
            background:'none',
           overflowX:'unset',
        },
        [theme.breakpoints.down(600)]:{
            marginTop: '79.51px',
        }
    },
  ContainerInfoLectura: {
    position: "relative",
    height: "86px",
    [theme.breakpoints.down(502)]:{
        height: "43px",
    },
    background: "#76D4C3",
    "& .BarraInfoLectura": {
      height: "86px",
      background: `Url(https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Barras/Azul.png)`,
        [theme.breakpoints.down(502)]:{
            height: "43px",
        },
    },
    "& .InfoLectura": {
      position: "absolute",
      height: "86px",
      width: "219px",
      boxSizing: "border-box",
      padding: "17.2px 0",
      background: "#FAFAFA",
      borderRadius: "50px",
      boxShadow: "2px 2px 2px 2px #00000029",
      zIndex: "4",
        [theme.breakpoints.down(502)]:{
            height: "43px",
            width: "109px",
            padding: "8.6px 0",
            borderRadius: "25px",
        },
    },
    "& .InfoLectura:before": {
      position: "absolute",
      display: "flex",
      boxSizing: "border-box",
      padding: "0 0 0 10px",
      justifyContent: "center",
      alignItems: "center",
      height: "39.88px",
      width: "39.88px",
      borderRadius: "50%",
      content: '""',
      boxShadow: "3px 0px .5px 0px #00000029",
      background: "#FAFAFA",
      left: "90px",
      top: "65px",
      transform: "rotate(0.25turn)",
      [theme.breakpoints.down(502)]:{
        left: "45px",
        top: "30px",
        height: "20.88px",
        width: "20.88px",
        },
    },
    "& .InfoLectura:after": {
      content: '""',
      height: "15px",
      width: "15px",
      borderTop: "2px solid #20A698",
      borderLeft: "2px solid #20A698",
      position: "absolute",
      bottom: "-10px",
      left: "Calc(50% - 8px)",
      transform: "rotate(0.625turn)",
      [theme.breakpoints.down(502)]:{
        height: "10px",
        width: "10px",
        left: "Calc(50% - 4.5px)",
        bottom: "-3px",
        borderTop: "1px solid #20A698",
        borderLeft: "1px solid #20A698",
        },
    },
    "& .Sesion": {
      borderLeft: "2px solid #D8D8D8",
    },
    "& .Dato": {
      margin: "0 0 5px 0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#20A698",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        margin: "0 0 2.5px 0",
        },
    },
    "& .Caracter": {
      margin: "0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#000000",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        },
    },   },
    ContenedorLectura:{
        background:'#fff',
        width:'1160px',
        height:'100%',
        padding:'60px 113px 35px 168px' ,
        [theme.breakpoints.down(1161)]:{
            width:'502px',
            padding:'60px 0 0',
        },
        [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
        '& .ContainerDerechos':{
            bottom:'80px',
            [theme.breakpoints.down(1161)]:{
                display:'none',
            }
        },
        '& .Derechos':{
            textAlign:"center",
            margin:"0px",
            font:" 15px lato",
            color:"#717476",
        },
        '& .Escritorio':{
            display:'block',
            [theme.breakpoints.down(1161)]:{
                display:'none'
            },
        },
        '& .Tablet':{
            display:'none',
            [theme.breakpoints.down(1161)]:{
                display:'block'
            },
        },  
    },
    ContainerTitulo:{
        margin:'0 0 46px 0',
        [theme.breakpoints.down(1161)]:{
            margin:'0 0 38px 0',
        },
        '& .Titulo':{
            font:'48px fredoka one',
            letterSpacing: '0px',
            color: '#D63390',
            margin:'0',
            textAlign:'center',
            [theme.breakpoints.down(1161)]:{
                font:'43px fredoka one',
                lineHeight:'52px',
                textAlign:'center',
            },
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            lineHeight : '30px',
            margin:'0 0 36px 0',
            font:'22px lato',
            color:'#000',
            letterSpacing: '-0.22px',
            [theme.breakpoints.down(1161)]:{
                font:'18px lato',
                letterSpacing:'.17px',
                lineHeight:'28px',
            },
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .SubTitulo':{
            margin:'0 0 10px 0',
            font:'bold 30px lato',
            letterSpacing:'.28px',
            lineHeight:'30px',
            color:'#F6644B',
            [theme.breakpoints.down(1161)]:{
                font:'bold 26px lato',
                letterSpacing:'.24px',
                lineHeight:'28px',
            },
            [theme.breakpoints.down(502)]:{
                font:'bold 22px lato',
                lineHeight:'24px',
            },
            [theme.breakpoints.down(350)]:{
                font:'bold 18px lato',
                lineHeight:'24px',
            },
        },
        '& .Imagen':{
            [theme.breakpoints.down(1161)]:{
             width:'100%',
            },
         },
    },
        
    

}));

const Cómo_debemos_vivir = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
             <Grid container item justifyContent="center" className={classes.ContainerInfoLectura}>
               <Grid container className="BarraInfoLectura"></Grid>
               <Grid container item className="InfoLectura">
                    <Grid container item direction="column" xs={6} justifyContent="center">
                        <p className="Dato">Grado</p>
                        <p className="Caracter">6</p>
                    </Grid>
                    <Grid container item direction="column" xs={6} justifyContent="center" className="Sesion">
                        <p className="Dato">Sesión</p>
                        <p className="Caracter">28</p>
                    </Grid>
               </Grid>
            </Grid>

            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >
                    ¿Cómo debemos vivir?
                    </h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container >
                        <p className="Lectura">
                            Imagina que la profesora le pide a todo el grupo traer de tarea material de casa para 
                            un experimento en clase. Pero el día de la actividad algunos estudiantes olvidan 
                            llevarlo, lo cual les imposibilita realizar la práctica. <br/><br/>
                            
                            Entonces la profesora los invita a buscar una solución grupal. De entre varias 
                            opciones, les propone dos. La primera es que sólo las personas que trajeron el 
                            material realicen la práctica. La segunda es que quienes llevaron material lo 
                            compartan con los que no. <br/><br/>
                            
                            Cada decisión implica inconvenientes. La primera opción dejaría sin trabajar a 
                            algunos compañeros. La segunda, que las personas que sí cumplieron no reciban 
                            todos los beneficios del experimento, pues tendrían que compartir su material con 
                            los demás. ¿Tú qué harías?<br/><br/>
                            
                            El ser humano es libre. Y ser consciente de ello le permite tomar decisiones de 
                            acuerdo con sus deseos e intereses. Mas no siempre es fácil saber qué hacer, pues las 
                            acciones que cada uno toma lo afectan, pero también a los demás. <br/><br/>
                            
                            Ante las diferentes preguntas que te puedes hacer en la vida existe una disciplina 
                            que puede orientarte en la toma de decisiones: la ética. Ésta estudia la capacidad que 
                            poseen los seres humanos de cuestionarse cómo se debe vivir y reflexionar sobre las 
                            acciones que realizamos frente a otras alternativas, así como los requisitos que 
                            deben tener para que sean correctas.
                        </p>
                    </Grid>
                    <Grid container  >
                        <Grid container  >
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion28/1.png" alt="Tarjeta" />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container >
                        <p className="Lectura ">
                            Aunque la ética permite valorar las distintas opciones, sus diversas respuestas sobre 
                            lo que es correcto pueden ser completamente opuestas entre sí. A los distintos tipos 
                            de análisis y sus posibles respuestas se les conoce como <em>teorías éticas</em>. A continuación 
                            conoce algunas de ellas. <br/><br/>
                            
                            <h3 className='SubTitulo'>Ética de la virtud</h3> <br/>
                            
                            Para esta teoría existen personas virtuosas, quienes son buenas y justas. Éstas 
                            tienden a actuar mejor que las personas no virtuosas. Por ejemplo, en una situación 
                            en la que se debe tomar una decisión que quizá afecte a dos o más personas, es más 
                            probable que una persona justa tome una buena decisión. <br/><br/>

                            Una persona no es virtuosa por casualidad, sino que lo consigue al practicar de 
                            manera cotidiana ciertas actitudes como la justicia, la prudencia o la valentía. De tal 
                            modo que cuando se le presenten verdaderos retos elija correctamente, de acuerdo 
                            con lo que ha practicado antes. <br/><br/>
                            
                            <h3 className='SubTitulo'>Emotivismo</h3> <br/>
                            
                            Para esta teoría las emociones y sentimientos son una guía para saber qué decidir. 
                            Por ejemplo, decir algo como: “No debemos compartir el material con los que no lo 
                            trajeron”, muestra las emociones que ciertas personas podrían considerar justas, de 
                            acuerdo con la situación, aunque parezcan negativas. En cambio, exponer ideas 
                            como: “Debemos compartir el material”, expresa emociones incluyentes frente al 
                            resto de los compañeros. <br/><br/>
                            
                            <h3 className='SubTitulo'>Racionalismo</h3> <br/>
                            
                            En este caso, una acción es correcta si está fundada en el cumplimiento del deber ser, 
                            generalmente basado en reglas estrictas y obligatorias para todos. Por ejemplo, no 
                            mentir, no robar y no lastimar. <br/><br/>
                            
                            Esta teoría considera que cualquier persona razonable estaría dispuesta a admitir 
                            que las reglas generales de convivencia aplican para todas las personas. Por lo tanto, 
                            su poder radica en que todos se traten como les gustaría ser tratados, de acuerdo 
                            con las reglas antes descritas. <br/><br/>
                            
                            <h3 className='SubTitulo'>Utilitarismo</h3> <br/>
                            
                            Esta teoría sostiene que una acción es correcta si los beneficios para la mayoría 
                            superan los costos. Es decir, si produce el mayor beneficio para el mayor número de 
                            personas con el menor daño posible. Esto significa que si se tuviera que elegir entre
                            dos acciones, se debe optar por la que favorezca a más personas. <br/><br/>
                            
                            <h3 className='SubTitulo'>Ética del cuidado</h3><br/>
                            
                            Para esta teoría se deben tomar en cuenta las relaciones personales más relevantes 
                            de cada persona, es decir, aquellas más valiosas que se desee preservar. Así, una 
                            acción es correcta si beneficia a las personas más importantes para cada individuo. <br/><br/>

                            Como has observado, cada teoría tiene diferentes respuestas posibles, que no 
                            necesariamente son compatibles entre sí. Lo importante es que estés seguro de cuál 
                            es la que más te convence y actúes de acuerdo con sus reglas. <br/><br/>

                            Ahora vuelve a pensar en el ejemplo inicial. Con base en la teoría que te haya llamado 
                            más la atención, responde: ¿compartirías el material con tus compañeros o no?

                        </p>
                    </Grid>
                    <Grid container justifyContent="center" >
                        <Grid container justifyContent='center' >
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion28/2.png" alt="Tarjeta" />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item xs={12} justifyContent="center" className="ContainerDerechos">
                    <p className="Derechos">
                        © 2022. Todos los derechos reservados, Pearson, Circuito de lectores.
                    </p>                
                </Grid>
        </Grid>
    </Grid>
    );
}
 
export default Cómo_debemos_vivir;
