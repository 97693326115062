import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
     Contenedor:{
        background:'#E9FBF9',
        width:'100vw',
        overflowX:'clip',
        [theme.breakpoints.down(1161)]:{
            background:'none',
           overflowX:'unset',
        },
        [theme.breakpoints.down(600)]:{
            marginTop: '79.51px',
        }
    },
  ContainerInfoLectura: {
    position: "relative",
    height: "86px",
    [theme.breakpoints.down(502)]:{
        height: "43px",
    },
    background: "#76D4C3",
    "& .BarraInfoLectura": {
      height: "86px",
      background: `Url(https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Barras/Azul.png)`,
        [theme.breakpoints.down(502)]:{
            height: "43px",
        },
    },
    "& .InfoLectura": {
      position: "absolute",
      height: "86px",
      width: "219px",
      boxSizing: "border-box",
      padding: "17.2px 0",
      background: "#FAFAFA",
      borderRadius: "50px",
      boxShadow: "2px 2px 2px 2px #00000029",
      zIndex: "4",
        [theme.breakpoints.down(502)]:{
            height: "43px",
            width: "109px",
            padding: "8.6px 0",
            borderRadius: "25px",
        },
    },
    "& .InfoLectura:before": {
      position: "absolute",
      display: "flex",
      boxSizing: "border-box",
      padding: "0 0 0 10px",
      justifyContent: "center",
      alignItems: "center",
      height: "39.88px",
      width: "39.88px",
      borderRadius: "50%",
      content: '""',
      boxShadow: "3px 0px .5px 0px #00000029",
      background: "#FAFAFA",
      left: "90px",
      top: "65px",
      transform: "rotate(0.25turn)",
      [theme.breakpoints.down(502)]:{
        left: "45px",
        top: "30px",
        height: "20.88px",
        width: "20.88px",
        },
    },
    "& .InfoLectura:after": {
      content: '""',
      height: "15px",
      width: "15px",
      borderTop: "2px solid #20A698",
      borderLeft: "2px solid #20A698",
      position: "absolute",
      bottom: "-10px",
      left: "Calc(50% - 8px)",
      transform: "rotate(0.625turn)",
      [theme.breakpoints.down(502)]:{
        height: "10px",
        width: "10px",
        left: "Calc(50% - 4.5px)",
        bottom: "-3px",
        borderTop: "1px solid #20A698",
        borderLeft: "1px solid #20A698",
        },
    },
    "& .Sesion": {
      borderLeft: "2px solid #D8D8D8",
    },
    "& .Dato": {
      margin: "0 0 5px 0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#20A698",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        margin: "0 0 2.5px 0",
        },
    },
    "& .Caracter": {
      margin: "0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#000000",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        },
    },   },
    ContenedorLectura:{
        background:'#fff',
        width:'1160px',
        height:'100%',
        padding:'0',
        [theme.breakpoints.down(1161)]:{
            width:'769px',
          },
          [theme.breakpoints.down(769)]:{
            width:'350px',
          },
        '& .Escritorio':{
            display:'block',
            [theme.breakpoints.down(1161)]:{
                display:'none'
            },
        },
        '& .Tablet':{
            display:'none',
            [theme.breakpoints.down(1161)]:{
                display:'block'
            },
        }
    },

    Parrafo1:{
        position:'relative',
        [theme.breakpoints.down(768)]:{
            display:'none'
        },
        '& .Pos':{
            position:'absolute',
            margin:'0',
        },
        '& .Titulo':{
            font:'62px Fredoka One',
            color:'#FFFFFF',
            top:'45px',
            left:'305px',
            [theme.breakpoints.down(1161)]:{
                font:'36px Fredoka One',
                left:'226px',
                top:'25px',
            },
            [theme.breakpoints.down(769)]:{
                font:'16px Fredoka One',
                left:'96px',
                top:'15px',

            },
        },
        '& .Subtitulo':{
            font:'29px Fredoka One',
            color:'#707070',
            left:'315px',
            [theme.breakpoints.down(1161)]:{
                font:'16px Fredoka One',
                left:'245px',
            },
            [theme.breakpoints.down(769)]:{
                font:'10px Fredoka One',
                left:'100px',
            },
            
        },
        '& .Sub1':{
            top:'210px',
            [theme.breakpoints.down(1161)]:{
                top:'113px',
            },
            [theme.breakpoints.down(769)]:{
                top:'62px',
            },

        },
        '& .Sub2':{
            top:'260px',
            [theme.breakpoints.down(1161)]:{
                top:'143px',
            },
            [theme.breakpoints.down(769)]:{
                top:'77px',
            },

        },
        '& .Sub3':{
            top:'309px',
            [theme.breakpoints.down(1161)]:{
                top:'170px',
            },
            [theme.breakpoints.down(769)]:{
                top:'90px',
            },

        },
        '& .Cuerpo':{
            font:'22px lato',
            color:'##000000',
            top:'392px',
            left:'275px',
            letterSpacing:'.21px',
            lineHeight :'30px',
            width:'824px',
            [theme.breakpoints.down(1161)]:{
                width:'495px',
                font:'18px lato',
                lineHeight :'28px',
                top:'210px',
                left:'203px',
            },
            [theme.breakpoints.down(768)]:{
                width:'255px',
                font:'10px lato',
                top:'125px',
                left:'80px',
            },
        },
        '& .Derechos':{
            position:'absolute',
            bottom:'35px',
            left:'380px',
            textAlign:"center",
            margin:"0px",
            font:" 15px lato",
            color:"#717476",
            [theme.breakpoints.down(1161)]:{
                display:'none'
            },
        },
        '& .Imagen':{
            margin:'0 0 150px 0',
            [theme.breakpoints.down(1161)]:{
                margin:'0',
            },
            [theme.breakpoints.down(768)]:{
                width:'100%',
            },

        },
    },
   Imagen:{
        display:'none',
        [theme.breakpoints.down(768)]:{
            width:'100%',
            display:'flex',
        },
    },

}));

const Corre_electronico = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
             <Grid container item justifyContent="center" className={classes.ContainerInfoLectura}>
               <Grid container className="BarraInfoLectura"></Grid>
               <Grid container item className="InfoLectura">
                    <Grid container item direction="column" xs={6} justifyContent="center">
                        <p className="Dato">Grado</p>
                        <p className="Caracter">2</p>
                    </Grid>
                    <Grid container item direction="column" xs={6} justifyContent="center" className="Sesion">
                        <p className="Dato">Sesión</p>
                        <p className="Caracter">3</p>
                    </Grid>
               </Grid>
            </Grid>

            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                <h2 className='Pos Titulo'>Correo electrónico formal</h2>
                <h3 className='Pos Subtitulo Sub1'>lmicheo@correo.com</h3>
                <h3 className='Pos Subtitulo Sub2'>edicion@revista.com</h3>
                <h3 className='Pos Subtitulo Sub3'>Artículo sobre las ranas del baño</h3>
                <p  className='Pos Cuerpo'>
                    <b>Estimada srita. Arrechea, editora de la revista <em>Animales</em>:</b><br/><br/>

                    Me llamo Leticia Micheo. Me pongo en contacto con usted para contarle que a mis 
                    hijos Paula y Carlos les gusta su revista. Disfrutan especialmente las fotografías de 
                    los animales bolivianos. Están aprendiendo a reconocer cuáles son y qué importancia 
                    tienen dentro de cada ecosistema.<br/><br/> 
                    
                    Por ello, siempre que visitamos a sus abuelos en el pueblo de Roboré, aprovecho para 
                    llevarlos a explorar los alrededores del Parque Nacional del Gran Chaco Kaa-Iya. Allí 
                    hemos observado algunos especímenes, entre ellos las <em>ranas del baño</em>, llamadas así 
                    porque suelen encontrarse en las zonas húmedas de las casas cercanas a los bosques 
                    tropicales.<br/><br/> 
                    
                    Mis hijos están muy interesados en que los pobladores de Roboré conozcan a esta 
                    especie (llamada científicamente <em>Scinax fuscovarius</em>) y eviten maltratarla cuando 
                    entre en sus casas. Lamentablemente, pocas personas son tolerantes con ella. 
                    Algunos incluso la consideran fea, lo cual nos entristece bastante. Pensamos que a 
                    través de su revista podríamos informar a la gente que estas ranas son inofensivas y 
                    muy útiles para el control de insectos y arañas. <br/><br/> 
                    
                    ¿Será posible que alguno de sus reporteros escriba un artículo al respecto? Con 
                    gusto podemos recibirlo en la casa de mis padres y llevarlo a las zonas donde hemos 
                    observado a las ranas para que tomen las mejores fotografías. Verá que son animales 
                    hermosos, dignos de apreciar. <br/><br/> 
                    
                    Sin más por el momento, agradezco su atención. Quedo atenta a sus comentarios.<br/><br/><br/><br/> 
                    
                    Reciba un cordial saludo, <br/><br/> 
                    
                    Leticia Micheo

                </p>
                <img className='Imagen Escritorio' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img2do/Sesion3/1.png" alt="Tarjeta" />
                <img className='Imagen Tablet' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img2do/Sesion3/1R.png" alt="Tarjeta" />

                <p className="Derechos">
                    © 2022. Todos los derechos reservados, Pearson, Circuito de lectores.
                </p>                
                </Grid>
                <img className={classes.Imagen} src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img2do/Sesion3/F1.png" alt="Tarjeta" />

        </Grid>
    </Grid>
    );
}
 
export default Corre_electronico ;
