import React, { useEffect, useLayoutEffect, useState, useContext } from "react";
import rough from "roughjs/bundled/rough.esm";
import getStroke from "perfect-freehand";
import DrawerContext from "../../../../usability/DrawerContext";
import eraseImg from "../../../imgs/Erase.png";
import { makeStyles } from "@material-ui/core";
import Note from "./Note";
import toMovePizarra from './images&icons/Combined_Shape.png'
import toDrawPizarra from './images&icons/icon_dibujar.png'
import IconButton from "@mui/material/IconButton";

import { GridCloseIcon } from "@mui/x-data-grid";
import axios from "../../../../services/axios";


const useStyles = makeStyles((theme) => ({
  container: {
    top: "100px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    flexWrap: "wrap",
    // border: "solid 1px #fff",
    backgroundColor: "#FEFAF1",
    // background: "transparent",
    outline: "none",
    overflow: "visible",
    zIndex: "98",
    border: "2px solid #20A698",
    borderRadius: "5px",
  },
  grid1: {
    zIndex: "3",
  },
  canvas: {
    position: "absolute",
    zIndex: (props) => (props.habiliteCanvas  ? 3 : 0),
    top: "0",
    left: "0",
    right: "0",
    bottom: "0",
    margin: "0 auto",
    transform: (props) =>
      `${
        props.scale < 1.25 ? "translate(0%, 0%)" : "translate(-1.5vw, -166px)"
      }`,
  },
  canvasWithoutTransform: {
    position: "absolute",
    zIndex: 3,
    top: "0",
    left: "0",
    right: "0",
    bottom: "0",
    margin: "0 auto",
  },
  headerContainer: {
    alignItems: "space-around",
    position: "relative",
    width: "100%",
  },
  iconTopPizarra: {
    width: "40px",
    height: "40px",
  },
}));

const getSvgPathFromStroke = (stroke) => {
  if (!stroke.length) return "";

  const d = stroke.reduce(
    (acc, [x0, y0], i, arr) => {
      const [x1, y1] = arr[(i + 1) % arr.length];
      acc.push(x0, y0, (x0 + x1) / 2, (y0 + y1) / 2);
      return acc;
    },
    ["M", ...stroke[0], "Q"]
  );

  d.push("Z");
  return d.join(" ");
};

const drawElement = (roughCanvas, context, element, color) => {
  if (element === undefined || element === null) return null;
  switch (element.type) {
    case "line":
    case "rectangle":
      roughCanvas.draw(element.roughElement);
      break;
    case "ellipse":
      roughCanvas.draw(element.roughElement);
      break;
    case "pencil":
      const stroke = getSvgPathFromStroke(
        getStroke(element.points, {
          size: 10,
          smoothing: 0.5,
          thinning: -0.1,
          streamline: 0.5,
        })
      );
      context.fillStyle = color;
      context.fill(new Path2D(stroke));
      break;

    default:
    // throw new Error(`Type not recognised: ${element.type}`);
  }
};

const Drawer = ({ id_lectura, withoutTransform }) => {
  // const [pizarraCanvas, setPizarraCanvas] = React.useState()
  const [action, setAction] = useState("none");
  const [selectedElement, setSelectedElement] = useState(null);
  const [canvasCache, setCanvasCache] = useState([]);
  const [canvasPizarraCache, setCanvasPizarraCache] = useState([]);
  const [disableMove, setDisableMove] = useState(true)
  const [primeroPizarraCanvas, setPrimeroPizarraCanvas] = useState(false)
  const [readyToLoad, setReadyToLoad] = useState(false)

  const {
    colorDraw,
    colorMarker,
    createElement,

    tool,
    elements,
    setElements,
    undoForm,
    habiliteCanvas,
    redoForm,
    handleErasedMode,
    habiliteDraw,
    habiliteMarker,
    height,
    width,
    handleReadingId,
    notes,
    scale,
    loadData,
    pizarraCanvas,
    setPizarraCanvas,
    pristinePizarra,
    setPristinePizarra,
    DisableCanvas,
    movePizarra,
    positionPizarra,
    setFirstSave,
    handleErase,
    setIdNotes
  } = useContext(DrawerContext);
  const classes = useStyles({ habiliteCanvas, scale });

  useEffect(() => {
    handleReadingId(id_lectura);
    // loadData([], []);
    // setElements([])
    // setNotes([])
    handleErase()
    setReadyToLoad(true)
  }, [id_lectura]);

  useEffect(() => {
    if(readyToLoad){
    axios
      .get(`/actividad/notas/get?id_actividad=${id_lectura}`)
      .then((response) => {
        if (response.status === 200) {

          setFirstSave(false)
          setIdNotes(response?.data?.resultado?.id_nota)
          const loadElements = response?.data?.resultado?.elements.map((element)=> {
            if(element.length){
              return(
                JSON.parse(element)
              )
            }
          })
          const loadNotes = response?.data?.resultado.notas.map((note)=> {
            if(note.length){
              return(
                JSON.parse(note)
              )
            }
          })
          loadData(loadElements, loadNotes);
 
        }
      })
      .catch((error) => {
        console.log(error, "GETload")
      });
    }
  }, [readyToLoad]);

  useLayoutEffect(() => {
    const canvas = pizarraCanvas
      ? document.getElementById("pizarra-canvas")
      : document.getElementById("canvas");
    // poner las dos opciones de canvas
    const context = canvas.getContext("2d");

    context.clearRect(0, 0, canvas.width, canvas.height);

    const roughCanvas = rough.canvas(canvas);
    //pinta los canvas aquÃ­, traer el canvas cache
    elements?.forEach((element) => {
      const lastColor = element?.roughElement?.options.stroke || element?.color;

      if (habiliteDraw && !habiliteMarker) {
        drawElement(
          roughCanvas,
          context,
          element,
          lastColor ? lastColor : colorDraw
        );
      } else {
        drawElement(
          roughCanvas,
          context,
          element,
          lastColor ? lastColor : colorMarker
        );
      }
    });
  }, [
    elements,
    action,
    selectedElement,
    colorDraw,
    colorMarker,
    habiliteDraw,
    habiliteMarker,
    height,
    notes,
  ]);

  useEffect(() => {
    const undoRedoFunction = (event) => {
      if ((event.metaKey || event.ctrlKey) && event.key === "z") {
        if (event.shiftKey) {
          redoForm();
        } else {
          undoForm();
        }
      }
    };

    document.addEventListener("keydown", undoRedoFunction);
    return () => {
      document.removeEventListener("keydown", undoRedoFunction);
    };
  }, [undoForm, redoForm]);

  const updateElement = (id, x1, y1, x2, y2, type, options) => {
    const elementsCopy = [...elements];

    switch (type) {
      case "line":
      case "rectangle":
        elementsCopy[id] = createElement(id, x1, y1, x2, y2, type, colorDraw);
        break;
      case "ellipse":
        elementsCopy[id] = createElement(id, x1, y1, x2, y2, type, colorDraw);
        break;

      case "pencil":
        elementsCopy[id].points = [
          ...elementsCopy[id].points,
          { x: x2, y: y2 },
        ];

        break;

      default:
      // throw new Error(`Type not recognised: ${type}`);
    }

    setElements(elementsCopy, true);
  };

  const nearPoint = (x, y, x1, y1, name) => {
    return Math.abs(x - x1) < 5 && Math.abs(y - y1) < 5 ? name : null;
  };

  const distance = (a, b) =>
    Math.sqrt(Math.pow(a.x - b.x, 2) + Math.pow(a.y - b.y, 2));
  const onLine = (x1, y1, x2, y2, x, y, maxDistance = 1) => {
    const a = { x: x1, y: y1 };
    const b = { x: x2, y: y2 };
    const c = { x, y };
    const offset = distance(a, b) - (distance(a, c) + distance(b, c));
    return Math.abs(offset) < maxDistance ? "inside" : null;
  };

  const positionWithinElement = (x, y, element) => {
    const { type, x1, x2, y1, y2 } = element;
    if (type === "line") {
      const on = onLine(x1, y1, x2, y2, x, y);
      const start = nearPoint(x, y, x1, y1, "start");
      const end = nearPoint(x, y, x2, y2, "end");
      return start || end || on;
    } else if (type === "rectangle") {
      const topLeft = nearPoint(x, y, x1, y1, "tl");
      const topRight = nearPoint(x, y, x2, y1, "tr");
      const bottomLeft = nearPoint(x, y, x1, y2, "bl");
      const bottomRight = nearPoint(x, y, x2, y2, "br");
      const inside = x >= x1 && x <= x2 && y >= y1 && y <= y2 ? "inside" : null;
      return topLeft || topRight || bottomLeft || bottomRight || inside;
    } else if (type === "ellipse") {
      const topLeft = nearPoint(x, y, x1, y1, "tl");
      const topRight = nearPoint(x, y, x2, y1, "tr");
      const bottomLeft = nearPoint(x, y, x1, y2, "bl");
      const bottomRight = nearPoint(x, y, x2, y2, "br");
      const inside = x >= x1 && x <= x2 && y >= y1 && y <= y2 ? "inside" : null;
      return topLeft || topRight || bottomLeft || bottomRight || inside;
    } else if (type === "pencil") {
      const betweenAnyPoint = element.points.some((point, index) => {
        const nextPoint = element.points[index + 1];
        if (!nextPoint) return false;
        return (
          onLine(point.x, point.y, nextPoint.x, nextPoint.y, x, y, 5) != null
        );
      });
      return betweenAnyPoint ? "inside" : null;
    } else {
      throw new Error(`Type not recognised: ${type}`);
    }
  };
  const getElementAtPosition = (x, y, elements) => {
    return elements
      .map((element) => {
        if (element.type === "ellipse") {
          const width = (element.x1 - element.x2) * 0.5;
          const height = (element.y1 - element.y2) * 0.6;
          return {
            ...element,
            position: positionWithinElement(x - width, y - height, element),
          };
        } else {
          return { ...element, position: positionWithinElement(x, y, element) };
        }
      })
      .sort((a, b) => {
        let sizeA = a.y2 - a.y1 + a.x2 - a.x1;
        let sizeB = b.y2 - b.y1 + b.x2 - b.x1;
        return sizeA - sizeB;
      })
      .find((element) => element.position !== null);
  };

  const cursorForPosition = (position) => {
    return `url(${eraseImg}), auto`;
  };
  const handleMouseDown = (event) => {
    if (habiliteCanvas) {

      const { layerX, layerY } = event.nativeEvent;

      if (tool === "erase") {
        const element = getElementAtPosition(layerX, layerY, elements);

        if (element) {
          if (element.type === "pencil") {
            // const xOffsets = element.points.map((point) => clientX - point.x);
            // const yOffsets = element.points.map((point) => layerY - point.y);

            setSelectedElement({ ...element });
          } else {
            const offsetX = layerX - element.x1;
            const offsetY = layerY - element.y1;

            setSelectedElement({ ...element, offsetX, offsetY });
          }
          // setElements((prevState) => prevState);

          if (element.position === "inside") {
            setAction("erasing");
          } else {
            setAction("resizing");
          }
        }
      } else {
        const id = Date.now();
        const element = createElement(
          id,
          layerX,
          layerY,
          layerX,
          layerY,
          tool,
          habiliteDraw ? colorDraw : colorMarker
        );
        //pinta y guarda la información de un canvas a otra
        if (pizarraCanvas) {
          setElements((prevState) => {
            let elements = [];
            if (pristinePizarra && canvasPizarraCache.length) {
              elements = [...canvasPizarraCache];
              setCanvasPizarraCache([])
              setPristinePizarra(true);
              setCanvasCache(prevState);
            } else if(pristinePizarra){
              setPrimeroPizarraCanvas(true)
              setCanvasCache(prevState);
              elements = [element];
            }else {
              elements = [...prevState, element];
              setCanvasPizarraCache(elements)
            }
            setPristinePizarra(false);
            return elements;
          });
          setSelectedElement(element);
        } else if (!pizarraCanvas && canvasCache.length) {
          setElements((prevState) => {
            let elements = [...canvasCache];
            setCanvasCache([]);
            return [...elements, element];
            
          });
          setSelectedElement(element);
          setPrimeroPizarraCanvas(false)
        } else {
          let elements = [];
          setElements((prevState) => {
            
            if(primeroPizarraCanvas){
              elements = [element];
              setPrimeroPizarraCanvas(false)
              return elements;
            } else {
              return [...prevState, element];
            }
          });
          setSelectedElement(element);
        }
        setAction(tool === "text" ? "writing" : "drawing");
      }
    }
  };
  const handleMouseMove = (event) => {
    const { layerX, layerY } = event.nativeEvent;
    if (tool === "erase") {
      const element = getElementAtPosition(layerX, layerY, elements);
      event.target.style.cursor = element
        ? cursorForPosition(element.position)
        : "default";
    }

    if (action === "drawing") {
      const index = elements.length - 1;

      const { x1, y1 } = elements[index];
      updateElement(index, x1, y1, layerX, layerY, tool);
    } else if (action === "erasing") {
      if (selectedElement.type === "pencil") {
        // const newPoints = selectedElement.points.map((_, index) => ({
        //   x: pageX - selectedElement.xOffsets[index],
        //   y: pageY - selectedElement.yOffsets[index],
        // }));
        // const elementsCopy = [...elements];
        // elementsCopy[selectedElement.id] = {
        //   ...elementsCopy[selectedElement.id],
        //   points: newPoints,
        // };
        // setElements(elementsCopy, true);
      }
    }
  };
  const handleMouseUp = (event) => {
    if (action === "erasing") {
      const newElementsWithoutErase = elements.filter(
        (el) => el.id !== selectedElement.id
      );
      handleErasedMode(false);
      setElements(newElementsWithoutErase);
    }

    if (action === "writing") return;
    setAction("none");
    setSelectedElement(null);
  };

  const handleDrag = (event) => {
    setDisableMove(!disableMove)
    DisableCanvas()
    if (action === "erasing") {
      const newElementsWithoutErase = elements.filter(
        (el) => el.id !== selectedElement.id
      );
      handleErasedMode(false);
      setElements(newElementsWithoutErase);
    }

    if (action === "writing") return;
    setAction("none");
    setSelectedElement(null);
  }

  const renderPizarracanvas = () => {
    return (
      pizarraCanvas && (
        <>
          <div
            draggable={!disableMove}
            className={classes.container}
            style={{left:  `${positionPizarra.x}%`, top: `${positionPizarra.y}%`, position: "fixed"}}
            onDragEnd={(e) => {
              const clientX = window.innerWidth 
              const clientY = window.innerHeight 
              const porcentX =  ((e.clientX /clientX ) * 100)?.toFixed(2)
              const porcentY =  ((e.clientY / clientY) * 100)?.toFixed(2)
              const stopMarginRight = (clientX / 504) * 100
              //ancho de la pantalla menos el ancho del elemento 504 / 100, setear rango seg
              e.stopPropagation();
              e.cancelBubble = true;
              if(!disableMove){
                
                if(porcentY < stopMarginRight ){
                  movePizarra({ x: porcentX, y: porcentY });
                }
              }
              
            }}
            onDragStart={(e) => {
              e.stopPropagation();
              e.cancelBubble = true;
            }}
        
            onMouseDown={(event) => {
              event.stopPropagation();
            }}
            onMouseUp={(event) => {
              event.stopPropagation();
            }}
          >
            <div className={classes.headerContainer} >
             
                <button onClick={handleDrag}>
                  {disableMove ? 
                    <img src={toDrawPizarra} className={classes.iconTopPizarra}/>
                  : 
                    <img src={toMovePizarra} className={classes.iconTopPizarra} />}
                </button>
                <IconButton
                aria-label="close"
                onClick={() => { setPizarraCanvas(false)}}
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                }}
              >
                <GridCloseIcon />
              </IconButton>
            </div>

            <canvas
              style={{
                backgroundColor: "#fff",
                zIndex: "999",
              }}
              id="pizarra-canvas"
              width={500}
              // height={2000}
              height={300}
              onMouseDown={handleMouseDown}
              onMouseMove={handleMouseMove}
              onMouseUp={handleMouseUp}
              className={classes.canvas2}
            >
              Canvas
            </canvas>
          </div>
        </>
      )
    );
  };

  return (
    <>
      <>
        {renderPizarracanvas()}
        <>
          {notes.map((cuadro) => {
            if(Object.keys(cuadro).length) {
              return <Note data={cuadro} key={cuadro.id} />;
            } else {
              return ""
            }
          })}
          <canvas
            // style={{backgroundColor: "#fff", border: "2px solid black", zIndex: "3"}}
            id="canvas"
            width={width}
            // height={2000}
            height={height}
            onMouseDown={handleMouseDown}
            onMouseMove={handleMouseMove}
            onMouseUp={handleMouseUp}
            className={withoutTransform ? classes.canvasWithoutTransform: classes.canvas}
          >
            Canvas
          </canvas>
        </>
      </>
    </>
  );
};

export default Drawer;
