import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
     Contenedor:{
        background:'#E9FBF9',
        width:'100vw',
        overflowX:'clip',
        [theme.breakpoints.down(1161)]:{
            background:'none',
           overflowX:'unset',
        },
        [theme.breakpoints.down(600)]:{
            marginTop: '79.51px',
        }
    },
  ContainerInfoLectura: {
    position: "relative",
    height: "86px",
    [theme.breakpoints.down(502)]:{
        height: "43px",
    },
    background: "#76D4C3",
    "& .BarraInfoLectura": {
      height: "86px",
      background: `Url(https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Barras/Azul.png)`,
        [theme.breakpoints.down(502)]:{
            height: "43px",
        },
    },
    "& .InfoLectura": {
      position: "absolute",
      height: "86px",
      width: "219px",
      boxSizing: "border-box",
      padding: "17.2px 0",
      background: "#FAFAFA",
      borderRadius: "50px",
      boxShadow: "2px 2px 2px 2px #00000029",
      zIndex: "4",
        [theme.breakpoints.down(502)]:{
            height: "43px",
            width: "109px",
            padding: "8.6px 0",
            borderRadius: "25px",
        },
    },
    "& .InfoLectura:before": {
      position: "absolute",
      display: "flex",
      boxSizing: "border-box",
      padding: "0 0 0 10px",
      justifyContent: "center",
      alignItems: "center",
      height: "39.88px",
      width: "39.88px",
      borderRadius: "50%",
      content: '""',
      boxShadow: "3px 0px .5px 0px #00000029",
      background: "#FAFAFA",
      left: "90px",
      top: "65px",
      transform: "rotate(0.25turn)",
      [theme.breakpoints.down(502)]:{
        left: "45px",
        top: "30px",
        height: "20.88px",
        width: "20.88px",
        },
    },
    "& .InfoLectura:after": {
      content: '""',
      height: "15px",
      width: "15px",
      borderTop: "2px solid #20A698",
      borderLeft: "2px solid #20A698",
      position: "absolute",
      bottom: "-10px",
      left: "Calc(50% - 8px)",
      transform: "rotate(0.625turn)",
      [theme.breakpoints.down(502)]:{
        height: "10px",
        width: "10px",
        left: "Calc(50% - 4.5px)",
        bottom: "-3px",
        borderTop: "1px solid #20A698",
        borderLeft: "1px solid #20A698",
        },
    },
    "& .Sesion": {
      borderLeft: "2px solid #D8D8D8",
    },
    "& .Dato": {
      margin: "0 0 5px 0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#20A698",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        margin: "0 0 2.5px 0",
        },
    },
    "& .Caracter": {
      margin: "0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#000000",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        },
    },   },
    ContenedorLectura:{
        background:'#fff',
        width:'1160px',
        height:'100%',
        padding:'60px 168px 35px ',
        [theme.breakpoints.down(1161)]:{
            width:'502px',
            padding:'60px 0 0 0',
          },
        [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
        '& .ContainerDerechos':{
            bottom:'80px',
            [theme.breakpoints.down(1161)]:{
                display:'none',
            },
        },
        '& .Derechos':{
            textAlign:"center",
            margin:"0px",
            font:" 15px lato",
            color:"#717476",
        },
        '& .Escritorio':{
            display:'block',
            [theme.breakpoints.down(1161)]:{
                display:'none'
            },
        },
        '& .Tablet':{
            display:'none',
            [theme.breakpoints.down(1161)]:{
                display:'block'
            },
        }
    },

    ContainerTitulo:{
        margin:'0 0 46px 0',
        '& .Titulo':{
            letterSpacing: '0px',
            color: '#4AD3DD',
            margin:'0',
            textAlign:'center',
            font:'48px fredoka one',
            [theme.breakpoints.down(1161)]:{
                font:'43px fredoka one',
                lineHeight:'52px',
            },
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        position:'relative',
        '& .Lectura':{
            lineHeight : '30px',
            margin:'0 0 36px 0',
            font:'22px lato',
            color:'#000',
            letterSpacing: '0.20px',
            [theme.breakpoints.down(1161)]:{
                font:'18px lato',
                lineHeight :'28px',
                letterSpacing: '0.17px',
            },
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .SubTitulo':{
            margin:'0',
            color:'#F6664D',
            font:'bold 30px lato',
            letterSpacing:'.28px',
            lineHeight:'30px',
            [theme.breakpoints.down(1161)]:{
                font:'bold 22px lato',
                lineHeight :'24px',
                letterSpacing: '0.21px',
            },
            [theme.breakpoints.down(502)]:{
                font:'bold 18px lato',
                lineHeight :'20px',
                letterSpacing: '0.16px',
            },
        },
        '& .SubTitulo2':{
            margin:'0',
            color:'#20A698',
            font:'bold 26px lato',
            letterSpacing:'.24px',
            lineHeight:'30px',
            [theme.breakpoints.down(1161)]: {
                font: "bold 22px lato",
                lineHeight: "24px",
                letterSpacing: "0.21px",
            },
            [theme.breakpoints.down(502)]: {
                font: "bold 18px lato",
                lineHeight: "20px",
                letterSpacing: "0.16px",
            },
        },
       '& .TextImg':{
            margin:'24px 0 0 0',
            alignSelf:'flex-start',
            color:'#717476',
            font:'20px lato',
            letterSpacing:'.19px',
            lineHeight:'30px',
            [theme.breakpoints.down(1161)]:{
                font:' 16px lato',
                lineHeight :'25px',
                letterSpacing: '0.15px',
            },
            [theme.breakpoints.down(502)]:{
                font:' 11px lato',
                lineHeight :'14px',
                letterSpacing: '0.10px',
            },
        },
        '& .Imagen':{ 
            [theme.breakpoints.down(1161)]: {
               width:'100%'
            },
        },
        
    },
    

}));

const Energía_limpia_para_el_planeta_Tierra = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
             <Grid container item justifyContent="center" className={classes.ContainerInfoLectura}>
               <Grid container className="BarraInfoLectura"></Grid>
               <Grid container item className="InfoLectura">
                    <Grid container item direction="column" xs={6} justifyContent="center">
                        <p className="Dato">Grado</p>
                        <p className="Caracter">3</p>
                    </Grid>
                    <Grid container item direction="column" xs={6} justifyContent="center" className="Sesion">
                        <p className="Dato">Sesión</p>
                        <p className="Caracter">35</p>
                    </Grid>
               </Grid>
            </Grid>

            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >
                        Energía limpia<br className='Escritorio'/> para<br className='Tablet'/> el planeta Tierra
                    </h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura Lectura2">
                            Todo en el mundo se mueve gracias a la energía. Tú también. ¿Alguna vez te has 
                            sentido con más ganas después de comer? ¿Imaginas qué hace a la Tierra moverse? 
                            Su principal fuente de energía es el Sol.<br/><br/> 
                            
                            ¿Alguna vez has comido algo que no es saludable para ti? ¿Qué sensaciones te 
                            provocó? ¿Qué crees que pasaría con la naturaleza si la Tierra consumiera recursos 
                            que no son buenos para ella? Vamos a descubrirlo.<br/><br/> 
                            
                            <h3 className='SubTitulo'>¿Qué contamina a la Tierra?</h3><br/>
                            
                            Los seres humanos emplean otro tipo de energía llamada <em>industrial</em>. Es utilizada como 
                            combustible por vehículos y máquinas para funcionar. Una de sus presentaciones es 
                            el petróleo, que tal vez conozcas como gasolina o diésel. Todas estas sustancias se 
                            liberan al medio ambiente y se mezclan con el aire, el agua y el suelo. <br/><br/>
                            
                            Existe un tipo de combustible llamado <em>fósil</em>, que también se conoce como <em>hidrocarburo</em>. 
                            Se formó hace siglos, después de la época de los dinosaurios. Se trata de un recurso 
                            natural no renovable, esto quiere decir que cuando se agoten no habrá más de ellos 
                            disponibles. <br/><br/>
                            
                            Los hidrocarburos producen dióxido de carbono. Éste crea una capa contaminante 
                            en la atmósfera que deja entrar los rayos del Sol, pero no les permite salir con 
                            facilidad. La consecuencia de que permanezcan dentro provoca el efecto 
                            invernadero, que genera a su vez el calentamiento global. Esto ha causado daños 
                            irreversibles en el planeta.
                        </p>
                    </Grid>
                    
                    <Grid container item sm={12} justifyContent="center">
                        <Grid container item sm={12} justifyContent="center">
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img3ro/Sesion35/1.png"  alt="Tarjeta" />
                        </Grid>
                        <Grid container item sm={12} justifyContent="center">
                            <p className='TextImg'>Efecto invernadero.</p>  
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container xs={12} >
                        <p className='Lectura'>
                        <h3 className='SubTitulo2'>Efecto invernadero</h3><br/>

                            El calentamiento global es el aumento de la temperatura del planeta. Causa que haya 
                            mayor cantidad de huracanes y que éstos sean más fuertes. También derrite los 
                            polos, aumenta la contaminación al elevarse la concentración del CO<sub>2</sub> en la 
                            atmósfera, y todo esto afecta de diversas maneras la salud de los seres vivos en 
                            general. <br/><br/>
                            
                            Los hidrocarburos son la principal fuente de potencia para la humanidad. Pero sus 
                            consecuencias negativas y el hecho de que se están terminando las reservas de 
                            petróleo hacen necesario buscar otras fuentes menos dañinas. ¿Dónde pueden 
                            encontrarse?<br/><br/>
                            
                            <h3 className='SubTitulo'>Mejores opciones</h3><br/>
                            
                            Las energías limpias son aquellas provenientes de fuentes naturales y cuyas 
                            emisiones no rebasan los límites establecidos en las disposiciones reglamentarias 
                            correspondientes. Entre ellas están la solar, brindada por el Sol. La eólica, que es 
                            generada por el viento. La geotérmica, provocada por el calor y el vapor que proviene 
                            del interior de la Tierra. Y la hidráulica, que provienen de la fuerza de una caída de 
                            agua en una presa hidroeléctrica o por la fuerza de las mareas, también llamada 
                            <em> energía maremotriz</em>. <br/><br/>
                            
                            Todas se pueden aprovechar y convertir en energía eléctrica, de movimiento o 
                            cualquier otro tipo. Su utilización es indispensable para reducir el uso de 
                            combustibles fósiles y disminuir así su impacto contaminante.
                        </p>
                    </Grid>

                    <Grid container item sm={12} justifyContent="center">
                        <Grid container item sm={12} justifyContent="center">
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img3ro/Sesion35/2.png"  alt="Tarjeta" />
                        </Grid>
                        <Grid container item sm={12} justifyContent="center">
                            <p className='TextImg'>Ejemplos de energías limpias.</p>  
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item xs={12} justifyContent="center" className="ContainerDerechos">
                    <p className="Derechos">
                        © 2022. Todos los derechos reservados, Pearson, Circuito de lectores.
                    </p>                
                </Grid>
        </Grid>
    </Grid>
    );
}
 
export default Energía_limpia_para_el_planeta_Tierra;
