import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
     Contenedor:{
        background:'#E9FBF9',
        width:'100vw',
        overflowX:'clip',
        [theme.breakpoints.down(1161)]:{
            background:'none',
           overflowX:'unset',
        },
        [theme.breakpoints.down(600)]:{
            marginTop: '79.51px',
        }
    },
  ContainerInfoLectura: {
    position: "relative",
    height: "86px",
    [theme.breakpoints.down(502)]:{
        height: "43px",
    },
    background: "#76D4C3",
    "& .BarraInfoLectura": {
      height: "86px",
      background: `Url(https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Barras/Azul.png)`,
        [theme.breakpoints.down(502)]:{
            height: "43px",
        },
    },
    "& .InfoLectura": {
      position: "absolute",
      height: "86px",
      width: "219px",
      boxSizing: "border-box",
      padding: "17.2px 0",
      background: "#FAFAFA",
      borderRadius: "50px",
      boxShadow: "2px 2px 2px 2px #00000029",
      zIndex: "4",
        [theme.breakpoints.down(502)]:{
            height: "43px",
            width: "109px",
            padding: "8.6px 0",
            borderRadius: "25px",
        },
    },
    "& .InfoLectura:before": {
      position: "absolute",
      display: "flex",
      boxSizing: "border-box",
      padding: "0 0 0 10px",
      justifyContent: "center",
      alignItems: "center",
      height: "39.88px",
      width: "39.88px",
      borderRadius: "50%",
      content: '""',
      boxShadow: "3px 0px .5px 0px #00000029",
      background: "#FAFAFA",
      left: "90px",
      top: "65px",
      transform: "rotate(0.25turn)",
      [theme.breakpoints.down(502)]:{
        left: "45px",
        top: "30px",
        height: "20.88px",
        width: "20.88px",
        },
    },
    "& .InfoLectura:after": {
      content: '""',
      height: "15px",
      width: "15px",
      borderTop: "2px solid #20A698",
      borderLeft: "2px solid #20A698",
      position: "absolute",
      bottom: "-10px",
      left: "Calc(50% - 8px)",
      transform: "rotate(0.625turn)",
      [theme.breakpoints.down(502)]:{
        height: "10px",
        width: "10px",
        left: "Calc(50% - 4.5px)",
        bottom: "-3px",
        borderTop: "1px solid #20A698",
        borderLeft: "1px solid #20A698",
        },
    },
    "& .Sesion": {
      borderLeft: "2px solid #D8D8D8",
    },
    "& .Dato": {
      margin: "0 0 5px 0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#20A698",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        margin: "0 0 2.5px 0",
        },
    },
    "& .Caracter": {
      margin: "0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#000000",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        },
    },   },
    ContenedorLectura:{
        background:'#fff',
        width:'1160px',
        height:'100%',
        padding:'60px 168px 35px ',
        [theme.breakpoints.down(1161)]:{
            width:'502px',
            padding:'60px 0 0 0',
          },
        [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
        '& .ContainerDerechos':{
            bottom:'80px',
            [theme.breakpoints.down(1161)]:{
                display:'none',
            },
        },
        '& .Derechos':{
            textAlign:"center",
            margin:"0px",
            font:" 15px lato",
            color:"#717476",
        },
        '& .Escritorio':{
            display:'block',
            [theme.breakpoints.down(1161)]:{
                display:'none'
            },
        },
        '& .Tablet':{
            display:'none',
            [theme.breakpoints.down(1161)]:{
                display:'block'
            },
        }
    },
    ContainerTitulo:{
        margin:'0 0 46px 0',
        '& .Titulo':{
            letterSpacing: '0px',
            color: '#8E385D',
            margin:'0',
            textAlign:'center',
            font:'48px fredoka one',
            [theme.breakpoints.down(1161)]:{
                font:'43px fredoka one',
                lineHeight:'52px',
            },
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            margin:'0 0 36px 0 ',
            color:'#000',
            font:'22px lato',
            lineHeight : '30px',
            letterSpacing: '0.20px',
            [theme.breakpoints.down(1161)]:{
                font:'18px lato',
                lineHeight :'28px',
                letterSpacing: '0.17px',
            },
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .TextImg':{
            margin:'11px 0 0 0',
            color:'#717476',
            font:'20px lato',
            lineHeight :'30px',
            letterSpacing: '0.19px',
            [theme.breakpoints.down(1161)]:{
                font:' 16px lato',
                lineHeight :'20px',
                letterSpacing: '0.15px',
            },
            [theme.breakpoints.down(502)]:{
                font:' 14px lato',
                lineHeight :'18px',
                letterSpacing: '0.10px',

            },
        },
        '& .Esp1':{
            margin:'0',
            [theme.breakpoints.down(1161)]:{
                margin:'0 0 36px 0',
            },
        },
        '& .Esp':{
            boxSizing:'border-box',
            padding:'0 20px 0 0',
            margin:'0',
        },
        
        '& .Imagen':{
            [theme.breakpoints.down(1161)]:{
                width:'100%',
            },
        },
    },
    

}));

const Sin_traducción_más_allá_de_la_palabra = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
             <Grid container item justifyContent="center" className={classes.ContainerInfoLectura}>
               <Grid container className="BarraInfoLectura"></Grid>
               <Grid container item className="InfoLectura">
                    <Grid container item direction="column" xs={6} justifyContent="center">
                        <p className="Dato">Grado</p>
                        <p className="Caracter">4</p>
                    </Grid>
                    <Grid container item direction="column" xs={6} justifyContent="center" className="Sesion">
                        <p className="Dato">Sesión</p>
                        <p className="Caracter">30</p>
                    </Grid>
               </Grid>
            </Grid>

            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >
                        Sin traducción:<br/> más allá de la palabra
                    </h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura Esp1">
                            Ella Frances Sanders dice que las palabras nunca pueden significar lo mismo dos 
                            veces, porque habitan cada lenguaje de forma diferente. Las lenguas amerindias 
                            guardan una gran sabiduría dentro de sus palabras, como todas las demás. Los 
                            intérpretes y los traductores las han estudiado desde hace tiempo y han servido de 
                            inspiración para guiones de películas, como <em>Avatar</em> de James Cameron.<br/><br/> 
                            
                            En maya un saludo se expresa con el concepto de unidad. Dos hablantes se dicen “<em>In 
                            kak’ech</em>” cuando se encuentran de frente, que significa “Yo soy otro tú”. Quien recibe 
                            esa frase responde “<em>Hala ken</em>”, que quiere decir “Tú eres otro yo”. La idea es que todos 
                            somos uno mismo y sigue presente en los hablantes de las lenguas mayas asentados 
                            a lo largo de México, Guatemala, Belice, Honduras y El Salvador. <br/><br/>
                            
                            Sisa Pacari es una escritora que habla kichwa, un idioma proveniente de Ecuador y el
                            norte de Argentina. Ella prefiere llamar <em>inga shimi</em> a su lengua, y esto significa “lengua 
                            del inca”. En una entrevista a un medio de comunicación inglés, contó que en su 
                            idioma existe una palabra que no tiene tiempo y se pronuncia <em>ñawpa</em>. Explicó que se 
                            puede entender como <em>un adelante</em> o como <em>un atrás</em>. Comentó: “Los hablantes de 
                            español se preguntan cuándo es pasado y cuándo es futuro, pero yo no puedo pensar 
                            así porque nosotros hablamos de la circularidad del ciclo de la vida. Pasado y futuro 
                            no son distintos para nosotros, uno baila entre pasado, presente y futuro”. <br/><br/>
                            
                            Por otro lado, la lengua aymara se habla en Chile, Bolivia y Perú y tiene vocablos 
                            largos, como <em>aruskipt’asiñanakasakipunirakispawa</em>. Es una palabra tan grande como un 
                            rascacielos, con 36 letras, 14 sílabas y 13 sufijos que expresan lo siguiente: “tenemos 
                            que conversar”, “no más”, “para siempre” y “conversar para resolver un conflicto”.<span className='Tablet'><br/></span>

                            <span className='Tablet'>
                            Pero también se puede decir mucho con pocas letras. Los hablantes del náhuatl en México lo 
                            saben. Para expresar la tristeza por la ausencia de un ser querido o decir que lo echan de 
                            menos dicen <em>mitztemoa noyollo</em>, que se traduce como “te busca mi corazón”. Tambien existe un 
                            término para las caricias que los padres hacen a sus hijos: <em>apapachoa</em>, que quiere decir 
                            “acariciar con el alma”. Este concepto fue incorporado al español de México, donde existen el 
                            verbo “apapachar” y el sustantivo “apapacho”.</span>

                           
                        </p>
                    </Grid>
                    <Grid container justifyContent="center" className='Tablet' xs={12}>
                        <Grid container item justifyContent="center" xs={12}>
                            <img className="Imagen " src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img4to/Sesion30/1.png"  alt="Tarjeta" />
                        </Grid>
                        <Grid container item justifyContent="center" xs={12}>
                            <p className='TextImg'>
                                Existe una gran cantidad de términos originarios de las lenguas amerindias que han enriquecido al español. ¿Tú conoces alguno?
                            </p>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                        <Grid container item xs={6} className="Escritorio">
                            <p className="Lectura Esp">
                                Pero también se puede decir mucho con 
                                pocas letras. Los hablantes del náhuatl 
                                en México lo saben. Para expresar la 
                                tristeza por la ausencia de un ser 
                                querido o decir que lo echan de menos 
                                dicen <em>mitztemoa noyollo</em>, que se traduce 
                                como “te busca mi corazón”. Tambien 
                                existe un término para las caricias que 
                                los padres hacen a sus hijos: <em>apapachoa</em>, 
                                que quiere decir “acariciar con el alma”. 
                                Este concepto fue incorporado al 
                                español de México, donde existen el 
                                verbo “apapachar” y el sustantivo 
                                “apapacho”. <br/><br/>
                                
                                El filósofo Ludwig Wittgenstein decía 
                                que los límites del lenguaje eran los 
                                límites de su mundo. Hay palabras de 
                                otras lenguas que no existen en español,
                                no tienen correspondencia, y se pierde 
                                su verdadero significado al traducirlas, 
                                lo cual altera el mensaje original. Ella 
                                Sanders escribió un libro sobre ello, que 
                                se titula <em>Perdidos en la traducción</em>. En él,
                                agrupa vocablos de diferentes culturas 
                                que aportan al mundo significados de 
                                realidades distintas. <br/><br/>
                                
                                Ahora sabes que las traducciones a 
                                veces no expresan lo que decían en su 
                                idioma original. ¿Sabes de alguna 
                                palabra que sea difícil de traducir al 
                                español?
                            </p>
                        </Grid>
                        <Grid container item  xs={6} justifyContent="center" className="Escritorio">
                            <Grid container item >
                                <img className="Imagen " src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img4to/Sesion30/1.png"  alt="Tarjeta" />
                            </Grid>
                            <Grid container item >
                                <p className='TextImg'>
                                    Existe una gran cantidad de términos originarios de las lenguas amerindias que han enriquecido al español. ¿Tú conoces alguno?
                                </p>
                            </Grid>
                        </Grid>
                    
                    <Grid cotainer item className='Tablet'>
                        <Grid container xs={12} >
                            <p className="Lectura ">
                                El filósofo Ludwig Wittgenstein decía 
                                que los límites del lenguaje eran los 
                                límites de su mundo. Hay palabras de 
                                otras lenguas que no existen en español,
                                no tienen correspondencia, y se pierde 
                                su verdadero significado al traducirlas, 
                                lo cual altera el mensaje original. Ella 
                                Sanders escribió un libro sobre ello, que 
                                se titula <em>Perdidos en la traducción</em>. En él,
                                agrupa vocablos de diferentes culturas 
                                que aportan al mundo significados de 
                                realidades distintas. <br/><br/>
                                
                                Ahora sabes que las traducciones a 
                                veces no expresan lo que decían en su 
                                idioma original. ¿Sabes de alguna 
                                palabra que sea difícil de traducir al 
                                español?
                            </p>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item xs={12} justifyContent="center" className="ContainerDerechos">
                    <p className="Derechos">
                        © 2022. Todos los derechos reservados, Pearson, Circuito de lectores.
                    </p>                
                </Grid>
        </Grid>
    </Grid>
    );
}
 
export default Sin_traducción_más_allá_de_la_palabra;
