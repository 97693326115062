import { Button, Grid, makeStyles } from "@material-ui/core";
import React, { useContext, useEffect } from "react";
import DrawerContext from "../../../../../usability/DrawerContext";
import { useOnLoadImages } from "../../../../hooks/useOnLoadImages";
import Loader from "../../../../Loader/Loader";
import Drawer from "../../ReadingTools/Drawer";

const useStyles = makeStyles((theme) => ({
  Contenedor: {
    background: "#E9FBF9",
    width: "100vw",
    overflowX: "clip",
    [theme.breakpoints.down(1161)]: {
      background: "none",
      overflowX: "unset",
    },
    [theme.breakpoints.down(600)]: {
      marginTop: "79.51px",
    },
  },
  ContainerInfoLectura: {
    position: "relative",
    height: "86px",
    [theme.breakpoints.down(502)]: {
      height: "43px",
    },
    background: "#76D4C3",
    "& .BarraInfoLectura": {
      height: "86px",
      background: `Url(https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Barras/Azul.png)`,
      [theme.breakpoints.down(502)]: {
        height: "43px",
      },
    },
    "& .InfoLectura": {
      position: "absolute",
      height: "86px",
      width: "219px",
      boxSizing: "border-box",
      padding: "17.2px 0",
      background: "#FAFAFA",
      borderRadius: "50px",
      boxShadow: "2px 2px 2px 2px #00000029",
      zIndex: "4",
      [theme.breakpoints.down(502)]: {
        height: "43px",
        width: "109px",
        padding: "8.6px 0",
        borderRadius: "25px",
      },
    },
    "& .InfoLectura:before": {
      position: "absolute",
      display: "flex",
      boxSizing: "border-box",
      padding: "0 0 0 10px",
      justifyContent: "center",
      alignItems: "center",
      height: "39.88px",
      width: "39.88px",
      borderRadius: "50%",
      content: '""',
      boxShadow: "3px 0px .5px 0px #00000029",
      background: "#FAFAFA",
      left: "90px",
      top: "65px",
      transform: "rotate(0.25turn)",
      [theme.breakpoints.down(502)]: {
        left: "45px",
        top: "30px",
        height: "20.88px",
        width: "20.88px",
      },
    },
    "& .InfoLectura:after": {
      content: '""',
      height: "15px",
      width: "15px",
      borderTop: "2px solid #20A698",
      borderLeft: "2px solid #20A698",
      position: "absolute",
      bottom: "-10px",
      left: "Calc(50% - 8px)",
      transform: "rotate(0.625turn)",
      [theme.breakpoints.down(502)]: {
        height: "10px",
        width: "10px",
        left: "Calc(50% - 4.5px)",
        bottom: "-3px",
        borderTop: "1px solid #20A698",
        borderLeft: "1px solid #20A698",
      },
    },
    "& .Sesion": {
      borderLeft: "2px solid #D8D8D8",
    },
    "& .Dato": {
      margin: "0 0 5px 0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#20A698",
      [theme.breakpoints.down(502)]: {
        font: "bold 12px lato",
        margin: "0 0 2.5px 0",
      },
    },
    "& .Caracter": {
      margin: "0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#000000",
      [theme.breakpoints.down(502)]: {
        font: "bold 12px lato",
      },
    },
    "& .Regresar": {
      color: "#F2624F",
      border: "#F2624F 1px solid",
      height: "30px",
      width: "100px",
      borderRadius: "20px",
      letterSpacing: "0.14px",
      textTransform: "none",
      background: "#FEFAF1",
      top: "calc(50% - 15px)",
      left: "5%",
      zIndex: 4,
      [theme.breakpoints.down(502)]: {
        font: "bold 10px lato",
        width: "60px",
      },
    },
  },
  ContenedorLectura: {
    position: "relative",
    background: "#fff",
    width: "1160px",
    height: "100%",
    padding: "60px 168px ",
    transform: (props) =>
      props.scale > 1 ? `scale(${props.scale}) translate(0px, 10%)` : `none`,
    [theme.breakpoints.down(1161)]: {
      width: "502px",
      padding: "60px 0 0 0",
    },
    [theme.breakpoints.down(502)]: {
      width: "350px",
      padding: "60px 0 0 0",
    },
    [theme.breakpoints.down(350)]: {
      width: "300px",
      padding: "30px 0 0 0",
    },

    "& .Escritorio": {
      display: "block",
      [theme.breakpoints.down(1161)]: {
        display: "none",
      },
    },
    "& .Tablet": {
      display: "none",
      [theme.breakpoints.down(1161)]: {
        display: "block",
      },
    },
  },
  ContainerTitulo: {
    margin: "0 0 46px 0",
    "& .Titulo": {
      letterSpacing: "0px",
      color: "#0093FF",
      margin: "0",
      textAlign: "center",
      font: "48px fredoka one",
      lineHeight: "58px",
      [theme.breakpoints.down(1161)]: {
        font: "43px fredoka one",
        lineHeight: "52px",
      },
      [theme.breakpoints.down(502)]: {
        font: "30px fredoka one",
        lineHeight: "32px",
      },
      [theme.breakpoints.down(350)]: {
        font: "28px fredoka one",
        lineHeight: "32px",
      },
    },
  },
  Parrafo1: {
    margin: " 0 0 36px 0",
    "& .Lectura": {
      margin: "0 0 36px 0",
      color: "#000",
      font: "22px lato",
      lineHeight: "35px",
      letterSpacing: "0.20px",
      [theme.breakpoints.down(1161)]: {
        font: "18px lato",
        lineHeight: "33px",
        letterSpacing: "0.17px",
      },
      [theme.breakpoints.down(502)]: {
        font: "14px lato",
        lineHeight: "26px",
        letterSpacing: "0.09px",
      },
    },
    "& .SubTitulo": {
      color: "#FFFFFF",
      margin: "0",
      borderRadius: "50%",
      background: "#823CE2",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      font: "bold 30px lato",
      letterSpacing: ".28px",
      lineHeight: "30px",
      height: "56px",
      width: "56px",
      [theme.breakpoints.down(1161)]: {
        font: "bold 26px lato",
        lineHeight: "28px",
        letterSpacing: "0.24px",
        height: "39px",
        width: "39px",
      },
      [theme.breakpoints.down(502)]: {
        font: "bold 22px lato",
        lineHeight: "24px",
        letterSpacing: "0.21px",
        height: "29px",
        width: "29px",
      },
    },
    "& .Imagen": {
      position: "absolute",
      top: "218px",
      left: "495px",
      [theme.breakpoints.down(1161)]: {
        top: "338px",
        left: "200px",
      },
      [theme.breakpoints.down(502)]: {
        display: "none",
      },
    },
    "& .Imagen2": {
      position: "absolute",
      display: "none",
      [theme.breakpoints.down(502)]: {
        display: "block",
        top: "310px",
        left: "145px",
        width: "230px",
      },
    },
    "& .Imagen3": {
      position: "absolute",
      display: "none",
      [theme.breakpoints.down(502)]: {
        display: "block",
        top: "500px",
        width: "250px",
        left: "150px",
      },
    },
    "& .Imagen4": {
      position: "absolute",
      display: "none",
      [theme.breakpoints.down(502)]: {
        display: "block",
        top: "1000px",
        width: "210px",
        left: "150px",
      },
    },
    "& .Imagen5": {
      position: "absolute",
      display: "none",
      [theme.breakpoints.down(502)]: {
        display: "block",
        top: "1220px",
        width: "265px",
        left: "120px",
      },
    },
  },
}));

const Viaje_a_lo_invisible = (props) => {

  const {activeStep, setActiveStep, handlePreviewStep, id_actividad} = props

  const handleContinue = () => {
    setActiveStep(activeStep - 1)
    handlePreviewStep()
  }

    const {
      handleSize,
    disabledZoom,
    closeZoom,
    handleZoom,
    scale
  }= useContext(DrawerContext);
  const [loading, setLoading] = React.useState(true);
  const wrapperRef = React.useRef(null);
  const imagesLoaded = useOnLoadImages(wrapperRef);

  const detectSize = () => {
    const lectura = document.getElementById("lectura");
    const newWidth = lectura?.offsetWidth;
    const newHeight = lectura?.offsetHeight;
    setLoading(false);
    if (lectura) handleSize(newHeight, newWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", detectSize);
    return () => {
      handleZoom()
      window.removeEventListener("resize", detectSize);
      closeZoom()
    };
  }, []);

  const classes = useStyles({ disabledZoom,scale });
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
             <Grid container item justifyContent="center" className={classes.ContainerInfoLectura}>
                       <Grid container className="BarraInfoLectura">
            <Button className="Regresar" onClick={handleContinue}>
                Regresar
            </Button>
        </Grid>
               <Grid container item className="InfoLectura">
                    <Grid container item direction="column" xs={6} justifyContent="center">
                        <p className="Dato">Grado</p>
                        <p className="Caracter">3</p>
                    </Grid>
                    <Grid container item direction="column" xs={6} justifyContent="center" className="Sesion">
                        <p className="Dato">Sesión</p>
                        <p className="Caracter">41</p>
                    </Grid>
               </Grid>
            </Grid>
            {!imagesLoaded && <Loader />}
        <Grid
            container
            item
            className={classes.ContenedorLectura}
            justifyContent="center"
            id="lectura"
            onLoad={detectSize}
            ref={wrapperRef}
        >
<Drawer id_lectura={id_actividad} withoutTransform={true}/>
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >
                        Viaje a lo invisible
                    </h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <img className="Imagen Escritorio" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img3ro/Sesion41/Esp.png" alt="Tarjeta" />
                    <img className="Imagen Tablet" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img3ro/Sesion41/EspR.png" alt="Tarjeta" />
                    <img className="Imagen2 " src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img3ro/Sesion41/1.png" alt="Tarjeta" />
                    <img className="Imagen3 " src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img3ro/Sesion41/2.png" alt="Tarjeta" />
                    <img className="Imagen4 " src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img3ro/Sesion41/3.png" alt="Tarjeta" />
                    <img className="Imagen5 " src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img3ro/Sesion41/4.png" alt="Tarjeta" />

                   

                    <Grid container xs={12} >
                        <Grid xs={12}>
                        <p className="Lectura ">
                            <h3 className='SubTitulo'>I</h3><br/>
                        
                            El universo en tamaño<br/> 
                            tiene dos grandes alas: <br/>
                            a un lado enormes escalas <br/>
                            y a otro un mínimo peldaño. <br/>
                            Gigantes de nombres extraños <br/>
                            y pequeños invisibles <br/>
                            de colores increíbles <br/>
                            habitan nuestro planeta. <br/>
                            Radiantes como un cometa, <br/>
                            a simple vista imperceptibles.
                        </p>

                        </Grid>
                        <Grid xs={12}>
                        <p className="Lectura ">
                            <h3 className='SubTitulo'>II</h3><br/>
                        
                            La orca tan reina de los mares, <br/>
                            la gran escala desciende <br/>
                            cuando en el aire se extiende, <br/>
                            aleteando saltos estelares. <br/>
                            Catorce espectaculares metros <br/>
                            dibujan su silueta <br/>
                            y hasta a una casa <br/>
                            en tamaño sobrepasa. <br/>
                            Esta montaña marina, <br/>
                            que en el mundo predomina, <br/>
                            a cualquiera rebasa.
                        </p>
                        </Grid>
                        <Grid xs={12}>
                        <p className="Lectura ">
                            <h3 className='SubTitulo'>III</h3><br/>
                        
                            La hormiga, aunque no lo sabe, <br/>
                            es una puerta de entrada <br/>
                            a un mundo en que la mirada <br/>
                            necesita más de una llave. <br/>
                            En él, poco o nada cabe. <br/>
                            Es por eso que la hormiga <br/>
                            va, viene y va sin fatiga, <br/>
                            una y otra vez en secreto: <br/>
                            mensajera por decreto <br/>
                            del misterio de la miga.
                        </p>
                        </Grid>
                        <Grid xs={12}>
                        <p className="Lectura ">
                            <h3 className='SubTitulo'>IV</h3><br/>
                        
                            Microscopios poderosos <br/>
                            apuntan a las estrellas, <br/>
                            y a constelaciones bellas <br/>
                            de organismos silenciosos. <br/>
                            Científicos poderosos, <br/>
                            astrónomos del subsuelo,<br/>
                            desean con mucho celo <br/>
                            convertir en macroscópico <br/>
                            el espejo microscópico <br/>
                            de lo que habita en el cielo.
                        </p>
                        </Grid>
                    </Grid>

                </Grid>
        </Grid>
        <Grid container item style={{ marginTop: 80}} ></Grid>
    </Grid>
    );
}
 
export default Viaje_a_lo_invisible;
