import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
    ContenedorLectura:{
        width:'502px',
                  [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
    },
    ContainerTitulo:{
        margin:'0 0 34px 0',
        '& .Titulo':{
            letterSpacing: '0px',
            color: '#D1270A',
            margin:'0',
            textAlign:'center',
            font:'43px fredoka one',
            lineHeight:'52px',
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
        '& .cel':{
            display:'none',
            [theme.breakpoints.down(502)]:{
                display:'flex',
            },
        },
        '& .hide':{
            display:'flex',
            [theme.breakpoints.down(502)]:{
                display:'none',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            margin:'0',
            color:'#000',
            font:'18px lato',
            lineHeight :'28px',
            letterSpacing: '0.17px',
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .TextImg':{
            color:'#717476',
            margin:'0',
            font:' 16px lato',
            lineHeight :'25px',
            letterSpacing: '0.15px',
            [theme.breakpoints.down(502)]:{
                font:' 11px lato',
                lineHeight :'14px',
                letterSpacing: '0.10px',
            },
        },
        '& .Llama':{
            margin:'0 0 36px 0'
        },  
        '& .Imagen':{
            width:'100%',
        },  
      
    },

}));

const Las_llamas_en_America = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo hide" >
                        Las llamas en América:<br/>
                        animales ancestrales
                    </h2>
                    <h2 className="Titulo cel" >
                        Las llamas en<br/> 
                        América: animales<br/>ancestrales
                    </h2>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container xs={12} >
                        <p className="Lectura Lectura2">
                            La llama es un animal originario de América, que ha sido importante para los seres 
                            humanos desde los tiempos del Imperio inca. Esto se debe a su fuerte anatomía, que 
                            las volvió valiosas para los habitantes de esta región, ya que dicha característica 
                            las convertía en una excelente opción para transportar productos y comida en mayores 
                            cantidades de las que podían cargar las personas. Además de esto, también sirvieron 
                            como alimento y tuvieron fines rituales, entre otras cosas.
                        </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12} >
                        <Grid container justifyContent='center'>
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img1ro/Sesion39R/1.png" alt="Tarjeta" />
                        </Grid>
                        <Grid container item justifyContent='center'>
                            <p className='TextImg'>
                                Las llamas provienen de Sudamérica.
                            </p>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    
                <Grid container justifyContent="center" sm={12} className="Llama">
                        <Grid container justifyContent='center'>
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img1ro/Sesion39R/2.png" alt="Tarjeta" />
                        </Grid>
                        <Grid container item justifyContent='center'>
                            <p className='TextImg'>
                                Las llamas provienen de Sudamérica.
                            </p>
                        </Grid>
                    </Grid>

                    <Grid container xs={12} >
                        <p className="Lectura Lectura2">
                            La llegada de los españoles a América representó una amenaza para estos animales. 
                            Durante los primeros 100 años después de la Conquista, el número de llamas disminuyó 
                            por las enfermedades y por el desplazamiento que trajo consigo la introducción de 
                            otros animales traídos de Europa, como ovejas y caballos.<br/><br/>

                            Tiempo después, su población aumentó gradualmente y fueron llevadas a otras partes 
                            del mundo. Actualmente, pueden encontrarse como ganado en países como Reino Unido, 
                            Canadá y Nueva Zelanda. ¡Dieron la vuelta al mundo!
                        </p>
                    </Grid>
                   
                </Grid>
        </Grid>
    </Grid>
    );
}
 
export default Las_llamas_en_America;
