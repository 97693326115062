import { Button, makeStyles, Grid } from "@material-ui/core";
import React from "react";
import { Link } from "react-router-dom";

import codesIcon from "../../../imgs/codes.svg";
import schoolIcon from "../../../imgs/schoolIcon.svg";
import userIcon from "../../../imgs/userIcon.svg";

import ContainerAdminPearson from "../ContainerAdminPearson";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    padding: "48px 0 48px 0",
    background:
      "linear-gradient(217deg, rgba(255,194,0,0.4962185557816877) 2%, rgba(255,255,255,1) 25%, rgba(255,255,255,0.4962185557816877) 81%, rgba(144,81,177,1) 98%)",
    minHeight: "calc(100vh - 80px)",
    // [theme.breakpoints.up(1920)]:{
    //     height:'92vh',
    // },
    // [theme.breakpoints.down(1920)]:{
    //     height:'92vh',
    // },
    // [theme.breakpoints.down(1811)]:{
    //     height:'1150px',
    // },
    // [theme.breakpoints.down(1487)]:{
    //     height:'1430px',
    // },
    // background: 'linear-gradient(217deg, rgba(255,194,0,0.4962185557816877) 2%, rgba(255,255,255,1) 25%, rgba(255,255,255,0.4962185557816877) 81%, rgba(144,81,177,1) 98%)',
    // [theme.breakpoints.down(1280)]:{
    //     background:'none',

    // },
  },
  boxContainer: {
    background: "#fff",
    width: "90%",
    display: "grid",
    gridTemplateRows: "auto 1fr",
    alignItems: "start",
    alignContent:'center',
    rowGap: "20px",
    maxWidth: "1216px",
    minHeight: "70vh",
    borderRadius: "42px",
    padding: "30px",
    boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.5)",
    [theme.breakpoints.up("sm")]: {
      width: "auto",
      minWidth: "75%",
    },
    "& .Titulo": {
      font: "42px fredoka one",
      // margin: "0 0 84px 34px",
      margin: 0,
      textAlign: "center",
      color: "#DC5EA6",
      [theme.breakpoints.down(425)]: {
        font: "30px fredoka one",
      },
    },
  },
  buttonsContainer: {
    width: "100%",
    display: "grid",
    rowGap: "20px",
    [theme.breakpoints.up("sm")]: {
      // width: "80%",
    },
  },
  ContainerLinkinsiginas: {
    textDecoration: "none",
    // marginLeft: "44px",
    // marginBottom: "40px",
    "& .ButtonIsg": {
      color: "black",
      textTransform: "none",
      font: " 22px lato",
      gap: "10px",
      letterSpacing: "0.36px",
      [theme.breakpoints.down(425)]: {
        font: " 16px lato",
      },
      // width: '102px',
      // height: '21px',
      // background: '#FEFAF1 0% 0% no-repeat padding-box',
      // boxShadow: '0px 1px 2px #00000029',
      // border: '1px solid #F2624F',
      // borderRadius: '10px',
    },
    "& p": {
      textAlign: "start",
    },
    "& .icon": {
      color: "purple",
      marginRight: "20px",
      fontSize: "75px",
      [theme.breakpoints.down(425)]: {
        fontSize: "50px",
        marginRight: "0px",
      },
    },
  },

  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  WitheButton: {
    color: "#9051b1",
    // borderColor: "#9051b1",
    border: "1px solid #9051b1",
    backgroundColor: "#FFFFFF",
    borderRadius: "30px",
    // margin: "0 auto",
    width: "100%",
    minWidth: "75px",
    maxWidth: "238px",
    minHeight: "45px",
    textTransform: "unset",
    cursor: "pointer",
    fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
    fontWeight: "500",
    padding: "6px 16px",
    lineHeight: "1.75",
    transition:
      "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    boxShadow:
      "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
    [theme.breakpoints.up("lg")]: {
      minWidth: "140px",
    },

    "&:hover": {
      backgroundColor: "#B965D330 ",
    },
  },
  sectionContainer: {
    display: "flex",
    flexDirection: "row",
    alignContent: "flex-start",
    alignItems: "flex-start",
    gap: "20px",
  },
  sectionContainerRight: {
    width: "auto",
    gap: "15px",
    alignItems: "flex-start",
  },
  icon: {
    width: "42px",
  },
  buttonHeader: {
    margin: 0,
    fontFamily: "Lato",
    fontSize: "20px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.5",
    letterSpacing: "0.23px",
    color: "#747f92",
  },
}));

const OpcionesCargaDatos = () => {
  const classes = useStyles();

  return (
    <ContainerAdminPearson>
      <Grid
        container
        item
        sm={12}
        alignItems="center"
        justifyContent="center"
        className={classes.mainContainer}
      >
        <Grid container item direction="column" className={classes.boxContainer}>
          <h2 className="Titulo">Carga de datos</h2>
          <Grid
            container
            item
            direction="row"
            alignItems="center"
            className={classes.buttonsContainer}
          >
            <Grid
              container
              item
              direction="row"
              alignItems="center"
              className={classes.sectionContainer}
            >
              <img src={schoolIcon} alt="" className={classes.icon} />
              <Grid
                container
                item
                direction="column"
                alignItems="center"
                className={classes.sectionContainerRight}
              >
                <h4 className={classes.buttonHeader}>Carga de escuelas:</h4>
                <Link
                  className={classes.ContainerLinkinsiginas}
                  to="/admin/cargar-datos/escuelas/individual"
                >
                  <Button className={classes.WitheButton}>Carga individual</Button>
                </Link>
                <Link
                  className={classes.ContainerLinkinsiginas}
                  to="/admin/cargar-datos/escuelas/masiva"
                >
                  <Button className={classes.WitheButton}>Carga masiva</Button>
                </Link>
              </Grid>
            </Grid>
            <Grid
              container
              item
              direction="row"
              alignItems="center"
              className={classes.sectionContainer}
            >
              <img src={userIcon} alt="" className={classes.icon} />
              <Grid
                container
                item
                direction="column"
                alignItems="center"
                className={classes.sectionContainerRight}
              >
                <h4 className={classes.buttonHeader}>Carga de usuarios:</h4>
                <Link
                  className={classes.ContainerLinkinsiginas}
                  to="/admin/cargar-datos/usuarios/individual"
                >
                  <Button className={classes.WitheButton}>Carga individual</Button>
                </Link>
                <Link
                  className={classes.ContainerLinkinsiginas}
                  to="/admin/cargar-datos/usuarios/masiva"
                >
                  <Button className={classes.WitheButton}>Carga masiva</Button>
                </Link>
              </Grid>
            </Grid>
            <Grid
              container
              item
              direction="row"
              alignItems="center"
              className={classes.sectionContainer}
            >
              <img src={codesIcon} alt="" className={classes.icon} />

              <Grid
                container
                item
                direction="column"
                alignItems="center"
                className={classes.sectionContainerRight}
              >
                <h4 className={classes.buttonHeader}>Crear códigos:</h4>
                <Link
                  className={classes.ContainerLinkinsiginas}
                  // to="#"
                  to="/admin/cargar-datos/crear-codigos"
                >
                  <Button className={classes.WitheButton}>Crear códigos</Button>
                </Link>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </ContainerAdminPearson>
  );
};

export default OpcionesCargaDatos;
