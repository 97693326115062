import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
   ContenedorLectura:{
        width:'502px',
         [theme.breakpoints.down(502)]:{
           width:'350px',
         },
       '& .Escritorio':{
           display:'block',
           [theme.breakpoints.down(1161)]:{
               display:'none'
           },
       },
       '& .Tablet':{
           display:'none',
           [theme.breakpoints.down(1161)]:{
               display:'block'
           },
       }
   },
    ContainerTitulo:{
        margin:'0 0 46px 0',
        '& .Titulo':{
            letterSpacing: '0px',
            color: '#F6644B',
            margin:'0',
            textAlign:'center',
            font:'43px fredoka one',
            lineHeight:'52px',
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            margin:'0 0 36px 0 ',
            color:'#000',
            font:'18px lato',
            lineHeight :'28px',
            letterSpacing: '0.17px',
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .Esp':{
            margin:'0px!important'
        },
        '& .SubTitulo':{
            color:'#1DA698',
            margin:'0',
            font:'bold 22px lato',
            lineHeight :'24px',
            letterSpacing: '0.21px',
            [theme.breakpoints.down(502)]:{
                font:'bold 18px lato',
                lineHeight :'20px',
                letterSpacing: '0.16px',
            },
        },
        '& .Imagen':{
            width:'100%'
        },
    },
    

}));

const Los_instrumentos_en_una_orquesta = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >
                        Los instrumentos<br /> en una orquesta
                    </h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container item  sm={12}>
                        <p className="Lectura">
                            <h3 className='SubTitulo'>Te presentamos una orquesta sinfónica</h3> 
                        </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <img className='Imagen' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img2do/Sesion39/1.png"  alt="Tarjeta" />
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} >
                        <p className="Lectura Esp">
                            ¿Sabes cuántos músicos hay en la orquesta de la ilustración? 
                            ¡Muchísimos! Una <em>orquesta sinfónica</em> cuenta con alrededor de 
                            85 integrantes, y cada uno es especialista en alguno de los 
                            instrumentos que la conforman. Las melodías que interpretan 
                            suelen ser compuestas o arregladas para que todos ellos 
                            puedan participar en armonía y ofrecer piezas musicales para 
                            el deleite del público.<br/><br/>
                            
                            Como puedes ver, los integrantes se agrupan u ordenan de 
                            acuerdo con el tipo de instrumento que tocan: de cuerda, de 
                            viento o de percusión. Se colocan en medialuna de frente al 
                            público y al director, quien se encarga de dar instrucciones 
                            para que los instrumentos <em>entren</em> en el momento preciso, 
                            suban y bajen el volumen, o se queden en silencio, según 
                            corresponda.
                        </p>
                        <img className='Imagen' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img2do/Sesion39/2R.png"  alt="" />
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent='center'>
                    <p className="Lectura ">
                        La persona que dirige la orquesta se vale de un instrumento 
                        llamado <em>batuta</em>, una especie de vara con la que indica lo que 
                        deben hacer los integrantes de la orquesta. También usan un 
                        cuaderno donde registran, en un lenguaje especializado para la 
                        música, las notas que deben tocar. Cada canción o melodía 
                        tiene su propio registro, llamado <em>partitura</em>. Por eso, cuando ves 
                        a los músicos tocando en vivo o en algún programa, tienen 
                        enfrente una estructura con un cuaderno abierto. Presta 
                        atención a ese detalle la próxima vez que veas músicos 
                        tocando.<br/><br/> 
                        
                        Escuchar a una orquesta tocar es una experiencia única y 
                        hermosa que todos necesitamos experimentar alguna vez en la 
                        vida. Te invitamos a que escuches un concierto, ya sea en vivo, 
                        en un video grabado, en la televisión o en internet. Seguro lo 
                        vas a disfrutar muchísimo.<br/><br/> 

                        Es probable que al menos una orquesta haya tocado música 
                        emblemática de tu país. Y estamos casi seguros de que alguna
                        ha interpretado la banda sonora de tu película, caricatura, 
                        videojuego o serie favorita. Busca o pide a alguien que te ayude 
                        a buscar un concierto. ¡Anímate! Te sorprenderás gratamente 
                        con lo que escuches. Además, es una actividad que se puede 
                        hacer en compañía de tu familia y amigos.
                    </p>
                </Grid>
        </Grid>
    </Grid>
    );
}
 
export default Los_instrumentos_en_una_orquesta;
