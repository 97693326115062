import { Button, Grid, makeStyles } from "@material-ui/core";
import React from "react";
import { Link } from "react-router-dom";
import BlurContainer from "../../../BlurContainer";

const useStyles = makeStyles((theme) => ({
  evaluationsContainer: {
    height: "100%",
  },
  ContainerIzquierdo: {
    position: "relative",
    // background:
    //   "radial-gradient(circle, rgba(255,230,35,0.21246505438112742) 11%, rgba(45,218,201,0.20210090872286413) 53%)",
    height: "100%",
    maxWidth: "97%",
    [theme.breakpoints.down(1024)]: {
      maxWidth: "100%",
    },
  },
  ContainerEvaluacion: {
    position: "relative",
    width: "97vw",
    minHeight: "calc(100vh - 79.5px - 79.5px)",
    overflow: "hidden",
    display: "grid",
    gridTemplateRows: "96px 300px 270px",
    alignItems: "center",
    justifyItems: "center",
    alignContent: "center",
    [theme.breakpoints.up("md")]: {
      "@media(max-height:720px)": {
        gridTemplateRows: "auto auto auto",
      },
    },
  },
  ContainerBarraInferior: {
    // background: "#2DDAC9",
    height: "79.5px",
    // [theme.breakpoints.down(850)]: {
    //   background: "linear-gradient(90deg, #8144DF 0%, #2EDAC9 100%)",
    // },
  },
  ContainerBarraDerecha: {
    // background: "linear-gradient(0deg, #8144DF 0%, #2EDAC9 100%)",
    width: "3%",
    height: "100vh",
    [theme.breakpoints.down(1024)]: {
      display: "none",
    },
  },
  ContainerTextoTitulo: {
    width: "466px",
    [theme.breakpoints.down(600)]: {
      width: "415px",
      marginTop: "90px",
    },
    "& .Titulo": {
      textAlign: "center",
      font: "45px Fredoka One",
      color: "#996DE3",
      margin: "0",
      [theme.breakpoints.down(1501)]: {
        font: "40px Fredoka One",
      },
      [theme.breakpoints.down(1001)]: {
        font: "30px Fredoka One",
      },
      [theme.breakpoints.down(600)]: {
        font: "24px Fredoka One",
      },
      [theme.breakpoints.up("md")]: {
        "@media(max-height:720px)": {
          font: "32px Fredoka One",
        },
      },
    },
  },
  ContainerTextoResultadoEst: {
    width: "520px",
    minHeight: "101px",
    [theme.breakpoints.down(600)]: {
      width: "315px",
    },
  },
  textoRetro: {
    font: "22px lato",
    color: "#4B4A4E",
    letterSpacing: "0.21px",
    margin: "0",
    textAlign: "center",
    [theme.breakpoints.down(1440)]: {
      font: "20px lato",
    },
    [theme.breakpoints.down(960)]: {
      font: "16px lato",
    },
    [theme.breakpoints.up("md")]: {
      "@media(max-height:720px)": {
        font: "16px lato",
      },
    },
  },
  ContainerTextoResultadoCor: {
    width: "535px",
    minHeight: "101px",
    [theme.breakpoints.down(600)]: {
      width: "315px",
    },
  },
  ContainerEstrella: {
    "& .Divisor": {
      width: "39px",
      height: "0px",
      border: "4px solid #F1CB5A",
      borderRadius: "25px",
      [theme.breakpoints.down(769)]: {
        width: "29px",
      },
      [theme.breakpoints.up("md")]: {
        "@media(max-height:720px)": {
          border: "2px solid #F1CB5A",
        },
      },
    },
    "& .Estrella": {
      height: "60px",
      margin: "0 19.5px",
      [theme.breakpoints.down(769)]: {
        height: "40px",
      },
      [theme.breakpoints.up("md")]: {
        "@media(max-height:720px)": {
          height: "40px",
        },
      },
    },
  },
  ContainerResultado: {
    position: "relative",
    width: "283px",
    height: "153px",
    marginBottom: "40px",
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    boxShadow: "0px 0px 15px 15px #fff",
    border: " 3px solid #FBE6B9",
    borderRadius: "20px",
    gridArea: "score",
    [theme.breakpoints.down(769)]: {
      width: "233px",
      height: "103px",
      marginBottom: "20px",
    },
    [theme.breakpoints.up("md")]: {
      "@media(max-height:720px)": {
        height: "123px",
        marginBottom: "0",
      },
    },

    "& .TextoPuntaje": {
      position: "absolute",
      top: "18.75px",
      textAlign: "center",
      height: "30px",
      font: "25px lato",
      color: "#1DA698",
      margin: "0",
      [theme.breakpoints.down(769)]: {
        top: "8.75px",
        font: "20px lato",
      },
      [theme.breakpoints.up("md")]: {
        "@media(max-height:720px)": {
          font: "20px lato",
        },
      },
    },
    "& .Divisor": {
      position: "absolute",
      top: "61.36px",
      width: "179px",
      height: "0px",
      border: "3px solid #FBE6B9",
      [theme.breakpoints.down(769)]: {
        top: "41.36px",
        width: "129px",
      },
      [theme.breakpoints.up("md")]: {
        "@media(max-height:720px)": {
          border: "2px solid #FBE6B9",
        },
      },
    },
    "& .ResultadoPuntaje": {
      position: "absolute",
      top: "73.24px",
      textAlign: "center",
      font: "900 45px Lato",
      letterSpacing: "0px",
      color: "#DE7495",
      margin: "0",
      [theme.breakpoints.down(769)]: {
        font: "900 35px Lato",
        top: "53.24px",
      },
      [theme.breakpoints.up("md")]: {
        "@media(max-height:720px)": {
          font: "900 35px Lato",
        },
      },
    },
  },

  ContainerHeraldo: {
    width: "267px",
    height: "300px",
    [theme.breakpoints.down(960)]: {
      display: "none",
    },
    "& .Heraldo": {
      height: "100%",
    },
    [theme.breakpoints.up("md")]: {
      "@media(max-height:720px)": {
        height: "200px",
      },
    },
  },
  HiddenContainerTextoResultadoCor: {
    height: "250px",
    marginBottom: "20px",
    [theme.breakpoints.down(769)]: {
      height: "210px",
    },
    "& .Heraldo": {
      height: "100%",
    },
  },
  score: {
    display: "grid",
    justifyItems: "center",
    gridTemplateRows: "193px 78px",
    gridTemplateAreas: ` "score"
                                    "button"`,
    [theme.breakpoints.up("md")]: {
      "@media(max-height:720px)": {
        gridTemplateRows: "150px 60px",
        gridGap: "20px",
      },
    },
  },
  link: {
    textDecoration: "none",
    marginBottom: "20px",
    gridArea: "button",
    position: "relative",
    display: "flex",
    justifyContent: "center",
  },
  BotonContinuar: {
    textTransform: "none",
    height: "58px",
    width: "261px",
    background: "#7A54BA",
    boxShadow: "0px 0px 15px 15px #fff",
    borderRadius: "61px",
    color: "#FFFFFF",
    zIndex: "2",
    font: "bold 20px lato ",
    "&:hover": {
      background: "#dd78fb",
    },
    [theme.breakpoints.down(769)]: {
      font: "bold 16px lato ",
      height: "48px",
      width: "221px",
    },
  },
  dialog: {
    zIndex: 3,
    bottom: "125%",
    margin: "0 auto",
    backgroundColor: "#3f43b3",
    fontFamily: "Lato",
    letterSpacing: "0.36px",
    fontSize: "20px",
    color: "#ffffff",
    position: "absolute",
    width: "480px",
    padding: "24px",
    boxSizing: "border-box",
    borderRadius: "21.25px",

    "&:before": {
      // display: (props) => (props.openMenuInDesktop ? "none" : "flex"),
      display: "flex",
      zIndex: 1,
      textTransform: "none",
      justifyContent: "center",
      alignItems: "center",
      // borderRadius: "25px",
      position: "absolute",
      content: '""',
      border: "15px solid",
      borderTopColor: "#3f43b3",
      borderLeftColor: "transparent",
      borderBottomColor: "transparent",
      borderRightColor: "transparent",
      font: "bold 14px lato",
      right: "calc(50% - 15px)",
      bottom: "-28px",
    },
  },
}));

const ResultadoStep = ({
  changeButtonType,
  finalScore,
  tutorialStep,
  handleTutorialStep,
  handleClick,
}) => {
  const classes = useStyles({});

  return (
    <>
      <BlurContainer handleClose open>
        <Grid
          container
          direction="column"
          item
          xs={12}
          className={classes.evaluationsContainer}
        >
          <Grid
            container
            justifyContent="center"
            item
            sm={12}
            className={classes.ContainerIzquierdo}
          >
            <Grid
              container
              item
              justifyContent="center"
              alignItems="center"
              className={classes.ContainerEvaluacion}
            >
              <Grid
                item
                xs={12}
                className={classes.ContainerTextoTitulo}
              >
                <br />
                <br />
                <br />
              </Grid>

              <Grid
                container
                item
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
              >
                <Grid
                  container
                  item
                  md={4}
                  justifyContent="center"
                  className={classes.ContainerTextoResultadoEst}
                ></Grid>

                <Grid
                  container
                  item
                  md={3}
                  justifyContent="center"
                  className={classes.ContainerHeraldo}
                ></Grid>

                <Grid
                  container
                  item
                  md={4}
                  justifyContent="center"
                  className={classes.ContainerTextoResultadoCor}
                >
                  <Grid
                    container
                    item
                    justifyContent="center"
                    alignItems="center"
                    className={classes.ContainerEstrella}
                  ></Grid>
                </Grid>
              </Grid>

              <Grid
                container
                item
                xs={12}
                direction="column"
                justifyContent="center"
                alignItems="center"
                className={classes.score}
              >
                {!tutorialStep.one && (
                  <Grid
                    container
                    item
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    className={classes.ContainerResultado}
                    onClick={() => {
                      handleTutorialStep({
                        ...tutorialStep,
                        one: true,
                      });
                    }}
                  >
                    <p className={classes.dialog}>
                      Al responder las 10 preguntas encontrarás una pantalla de
                      retroalimentación final, donde verás el puntaje obtenido
                      en esta actividad.
                    </p>
                    <p className="TextoPuntaje">Tu puntaje es:</p>
                    <div className="Divisor"></div>
                    <p className="ResultadoPuntaje">{finalScore}/10</p>
                  </Grid>
                )}

                {changeButtonType ? (
                  <Link className={classes.link} to="/mapa">
                    <Button
                      onClick={handleClick}
                      className={classes.BotonContinuar}
                    >
                      Continúa la aventura
                    </Button>
                  </Link>
                ) : (
                  <>
                    {tutorialStep.one && (
                      <Grid className={classes.link}>
                        <p className={classes.dialog}>
                          Para continuar, haz clic en el botón<b> Continúa </b>{" "}
                          la aventura.
                        </p>
                        <Button
                          onClick={handleClick}
                          className={classes.BotonContinuar}
                        >
                          Continúa la aventura
                        </Button>
                      </Grid>
                    )}
                  </>
                )}
              </Grid>
            </Grid>
            <Grid
              container
              direction="row-reverse"
              item
              sm={12}
              className={classes.ContainerBarraInferior}
            ></Grid>
          </Grid>

          <Grid
            container
            item
            sm={12}
            className={classes.ContainerBarraDerecha}
          ></Grid>
        </Grid>
      </BlurContainer>
    </>
  );
};

export default ResultadoStep;
