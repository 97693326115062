import React               from "react";
import {Grid, makeStyles}                 from '@material-ui/core';

const useStyles = makeStyles(theme =>({
  questionContainer:{
    width: "100%",
    height:"100%",
  },
  reading: {
    width: "98%",
    font: "22px lato",
    letterSpacing:'0',
    margin:"14px 0 14px 0",
    [theme.breakpoints.down(1370)]:{
      font: "500 16px lato",
      width: "90%",
      margin:"10px 0 10px 0"
    },
    [theme.breakpoints.down(1310)]:{
      font: "500 16px lato",
      // width: "80%",
      margin:"10px 0 10px 0"
    },
    [theme.breakpoints.down(925)]:{
      font: "500 14px lato",
      // width: "70%",
      margin:"6px 0 6px 0"
    },
    [theme.breakpoints.down(426)]:{
      font: "500 12px lato",
      // width: "70%",
      margin:"6px 0 6px 5%"
    }, 
  },
  tableText: {
    width: "80%",
    margin:"0",
    font: '22px lato',
    letterSpacing: '0.21px',
    textAlign: 'left',
    [theme.breakpoints.down(1400)]:{
      font: " 18px lato",
      width: "85%",
    },
    [theme.breakpoints.down(1310)]:{
      font: "500 16px lato",
      // width: "80%",
    },
    [theme.breakpoints.down(925)]:{
      font: "500 14px lato",
      // width: "70%",
    },
    [theme.breakpoints.down(426)]:{
      font: "500 12px lato",
      // width: "70%",
    },
  },
  table: {
    // border: '2px solid black',
    width: "100%",
    font: '22px lato',
    [theme.breakpoints.down(1400)]:{
      font: " 18px lato",
    },
    [theme.breakpoints.down(1370)]:{
      font: "16px lato",
      width: "90%",
    },
    [theme.breakpoints.down(1310)]:{
      font: "16px lato",
    },
    [theme.breakpoints.down(925)]:{
      font: "14px lato",
      marginLeft: '10px',
    },
    [theme.breakpoints.down(426)]:{
      font: "12px lato",
    }, 
  },
  tdTable: {
    padding: "0 1%",
    verticalAlign: 'text-top'
    // border: '1px solid black',
  },
}));

const QuestionTable = (props) => {
  const classes = useStyles()

  return(
    <Grid container className={classes.questionContainer}>
      <p
        className={classes.reading}
      >
        2. Relaciona el tipo de narración con su definición. 
      </p>
      <br/>
      <table className={classes.table}>
        <thead>
          <th style={{width: "40%",}}><b>Factores</b></th>
          <th style={{width: "60%",}}><b>Descripción</b></th>
        </thead>
        <tbody>
          <tr>
            <td className={classes.tdTable}>
              <p className={classes.tableText}>
                1. Geográficos
              </p>
            </td>
            <td className={classes.tdTable}>
              <p className={classes.tableText}>
                a. Depende de la época y la generación.
              </p>
            </td>
          </tr>
          <tr>
            <td className={classes.tdTable}>
              <p className={classes.tableText}>
                2. Funcionales o contextuales
              </p>
            </td>
            <td className={classes.tdTable}>
              <p className={classes.tableText}>
                b. Ubicación de donde son los hablantes.
              </p>
            </td>
          </tr>
          <tr>
            <td className={classes.tdTable}>
              <p className={classes.tableText}>
                3. Socioculturales
              </p>
            </td>
            <td className={classes.tdTable}>
              <p className={classes.tableText}>
                c. Uso formal o informal del lenguaje.
              </p>
            </td>
          </tr>
          <tr>
            <td className={classes.tdTable}>
              <p className={classes.tableText}>
                4. Históricos
              </p>
            </td>
            <td className={classes.tdTable}>
              <p className={classes.tableText}>
                d. Variación dependiendo del género, la condición social, el nivel académico o profesional, etcétera. 
              </p>
            </td>
          </tr>
        </tbody>
      </table>
    </Grid>
  )
}

export default QuestionTable;
