import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
    ContenedorLectura:{
        width:'502px',
                  [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
    },
    ContainerTitulo:{
        margin:'0 0 39px 0',
        '& .Titulo':{
            letterSpacing: '0px',
            color: '#FC5E9E',
            margin:'0',
            textAlign:'center',
            font:'43px fredoka one',
            lineHeight:'52px',
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            margin:'0 0 36px 0 ',
            color:'#000',
            font:'18px lato',
            lineHeight :'28px',
            letterSpacing: '0.17px',
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .Esp':{
            margin:'0',
        },
        '& .SubTitulo':{
            color:'#00ADB6',
            margin:'0',
            font:'bold 22px lato',
            lineHeight :'24px',
            letterSpacing: '0.21px',
            [theme.breakpoints.down(502)]:{
                font:'bold 18px lato',
                lineHeight :'20px',
                letterSpacing: '0.16px',
            },
        },
        '& .ul':{
            listStyle:'decimal',
            padding:' 0 0 0 20px',
            margin:'0',
        },
        '& .li':{
            listStyle:'decimal',
            color:'#2DDAC9',
            margin:'0 0 8px 0',
            [theme.breakpoints.down(502)]:{
                margin:'0 0 4px 0',
            },
        },
        '& .Imagen':{
            width:'100%',
        }


    },

}));

const Principios_éticos_para_un_mundo_mejor = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >
                    Principios éticos para <br/> un mundo mejor
                    </h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                            <h3 className='SubTitulo'>¿Qué es la bioética?</h3><br/> 
                             Llamamos ética a la capacidad de reflexionar sobre nuestras acciones diarias, así 
                             como de saber distinguir entre aquellas que son correctas e incorrectas. Actuar 
                             éticamente es pensar en nuestro propio bienestar, pero también es cuidar el de las 
                             demás personas a nuestro alrededor. <br/><br/>
                             
                             La bioética es una rama de esta disciplina. Esta palabra está compuesta por la raíz <em>bio</em>, 
                             que significa “vida” en griego, que también da origen al término <em>biología</em>. Por lo tanto, 
                             podemos inferir que se encarga de estudiar el comportamiento de los seres humanos 
                             en la ciencia y su relación con las otras especies del planeta, como los animales y las 
                             plantas. <br/><br/>
                            
                            <h3 className='SubTitulo'>Principios de la bioética </h3><br/> 

                            En la década de 1970 esta ciencia propuso cuatro principios básicos para la medicina 
                            con la finalidad de que los médicos den un trato digno a sus pacientes. Se ha 
                            entendido con el tiempo que estos pasos son aplicables a todas las profesiones y que 
                            podemos seguirlos en la vida diaria para tratar adecuadamente a todas las personas. <br/><br/>

                            Los cuatro principios son los siguientes:<br/><br/>

                            <ul className='ul'>
                                <li className='li'><p className="Lectura Esp">
                                Beneficencia. Establece que tienes que hacer cosas buenas por los demás 
                                cuando esté en tus manos. Por ejemplo, si un compañero tuyo necesita ayuda 
                                para comprender un tema o para hacer una tarea y tú puedes dársela, lo 
                                correcto sería que lo hicieras.</p></li>
                                <li className='li'><p className="Lectura Esp">No maleficencia. Se refiere a que no dañes a alguien de forma intencionada, ya 
                                sea física, verbal o psicológicamente.</p></li>
                                <li className='li'><p className="Lectura Esp">Autonomía. Busca que respetes la capacidad de decisión de las personas, del 
                                mismo modo en que ellas deben respetar la tuya. No olvides que todos somos 
                                seres autónomos.</p></li>
                                <li className='li'><p className="Lectura Esp">Justicia. Va de la mano con la igualdad, ya que procura que seas tratado 
                                equitativamente. También se ocupa de que los beneficios y las 
                                responsabilidades de cualquier ámbito (como los escolares o los sociales) se 
                                repartan por igual.</p></li>
                            </ul>

                        </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                            <img className='Imagen' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img2do/Sesion38/1.png"  alt="Tarjeta" />
                    </Grid>
                </Grid>
        </Grid>
    </Grid>
    );
}
 
export default Principios_éticos_para_un_mundo_mejor;
