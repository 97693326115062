import { makeStyles } from "@material-ui/core";
import React from "react";
import Chart from "react-apexcharts";
import DownloadSelect from "../pages/Docente/Reportes/descargas/DownloadSelect";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    alignContent: "center",
    flexWrap: "nowrap",
    overflowX: "auto",
    overflowY: "hidden",
    [theme.breakpoints.up("md")]: {
      justifyContent: "center",
    },
  },
  Chart: {
    maxWidth: "689px",
    height: "380px",
    opacity: "0.9",
    width: "100%",
    // minWidth: "320px",
  },
}));

const Progreso = (props) => {
  const { selectedSeries, downloadData, IdKey } = props
  // const [firstTime, setFirtTime] = React.useState([true])

  const classes = useStyles();
  
  const [series, setSeries] = React.useState([]);
  const categories  =["Formativa 1", "Formativa 2", "Formativa 3"];
  
  const options = {
    chart: {
      height: 350,
      type: "line",
      id: IdKey,
      // stacked: false,
      toolbar: {
        show: true,
        offsetX: 35,
        offsetY: 210,
        tools: {
          download: true,
          selection: false,
          // customIcons: [],
        },
      },
      tooltip: {
        enabled: true,
      },
      zoom: {
        enabled: false,
      },
    },
    forecastDataPoints: {
      count: 0,
      fillOpacity: 0.5,
      strokeWidth: undefined,
      dashArray: 4,
    },
    markers: {
      size: 5,
      hover: {
        size: undefined,
        sizeOffset: 2,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
      },
    },
    dataLabels: {
      enabled: true,
    },
    // stroke: {
    //   // curve: "smooth",
    //   curve: "straight",
    //   width: 3,
    // },
    // title: {
    //   text: "Product Trends by Month",
    //   align: "left",
    // },
    grid: {
      row: {
        colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
        opacity: 0.5,
      },
    },

    legend: {
      show: false,
      showForSingleSeries: true,
      showForNullSeries: true,
      showForZeroSeries: true,
      position: "right",
      fontSize: "16px",
      fontFamily: "Helvetica, Arial",
      fontWeight: 700,
      labels: {
        colors: ["#FC33FF", "#3C33FF", "#36FF33"], 
        useSeriesColors: true,
      },
      // onItemClick: {
      //   toggleDataSeries: true,
      // },
      // onItemHover: {
      //   highlightDataSeries: true,
      // },
      // itemMargin: {
      //   horizontal: 50,
      // },
      // markers: {
      //   width: 12,
      //   height: 12,
      //   strokeWidth: 0,
      //   strokeColor: "#fff",
      //   fillColors: undefined,
      //   radius: 12,
      //   customHTML: undefined,
      //   onClick: undefined,
      //   offsetX: 0,
      //   offsetY: 0,
      // },
    },
    noData: {
      text: "Selecciona un estudiante",
      align: 'center',
      verticalAlign: 'middle',
      offsetX: 0,
      offsetY: 0,
      style: {
        color: undefined,
        fontSize: '14px',
        fontFamily: undefined
      }
    },
    xaxis: {
      categories: categories,
      type: "category",
      // tickPlacement: 'on',
      tickPlacement: "between",
      // range: 5,
      min: 10,
      max: 10,
      width: "100%",
      labels: {
        show: true,
        align: "left",
        minWidth: 0,
        maxWidth: undefined,
        trim: true,
        style: {
          colors: [],
          fontSize: "12px",
          fontFamily: "Helvetica, Arial, sans-serif",
          fontWeight: 400,
          cssClass: "apexcharts-yaxis-label",
        },
        offsetX: 0,
        offsetY: 0,
        rotate: 0,
        // formatter: (value) => {
        //   return value;
        // },
        axisBorder: {
          show: false,
          color: "#78909C",
          height: 1,
          width: "100%",
          offsetX: 0,
          offsetY: 0,
        },
        axisTicks: {
          show: false,
          borderType: "solid",
          color: "#78909C",
          height: 6,
          offsetX: 0,
          offsetY: 0,
        },
        tooltip: {
          enabled: false,
          formatter: undefined,
          offsetY: 0,
          style: {
            fontSize: 0,
            fontFamily: 0,
          },
        },
      },
    },
    yaxis: {
      min: 0,
      max: 10,
      logBase: 10,
      tickAmount: 10,
    },
    responsive: [
      {
        breakpoint: 1024,
        options: {
          plotOptions: {
            bar: {
              horizontal: true,
            },
          },
          legend: {
            position: "bottom",
            fontSize: "14px",
            fontFamily: "Helvetica, Arial",
            fontWeight: 500,
            itemMargin: {
              horizontal: 5,
              vertical: 5,
            },
            markers: {
              width: 12,
              height: 12,
              strokeWidth: 0,
              strokeColor: "#fff",
              fillColors: undefined,
              radius: 12,
              customHTML: undefined,
              onClick: undefined,
              offsetX: 0,
              offsetY: 0,
            },
          },
        },
      },
    ],
  };

  React.useEffect(() => {
    // console.log( userData, "dataCHAr", defaultProps.userData, "///", series)
    // if(firstTime){
    //   setCategories(defaultProps.categories);
    //   setSeries([])
    //   setFirtTime(false)
    // } else {
    //   console.log(studentsList, "info")
      // setCategories(defaultProps.categories);
      const userData = 
      selectedSeries.map((student)=>{
          return(
            {
              name: student.nombre + " "+ student.apellido_paterno,
              data: [
                student.calificaciones.formativa_uno, 
                student.calificaciones.formativa_dos, 
                student.calificaciones.formativa_tres 
              ]
       
            }
          )
        })
      // eslint-disable-next-line react-hooks/exhaustive-deps
      setSeries(userData)
      
  }, [selectedSeries.length]);


  return (
    <div className={classes.container}>
      {categories && series && 
        <Chart
          key={IdKey}
          options={options} 
          series={series} 
          type="line" 
          className={classes.Chart} 
          height={"100%"} 
          id={IdKey}
        />
      }
      <DownloadSelect
        downloadData={downloadData}
        className={classes.button}
      />

    </div>
  );
};

export default Progreso;
