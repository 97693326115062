import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
      Contenedor:{
        background:'#E9FBF9',
        width:'100vw',
        overflowX:'clip',
        [theme.breakpoints.down(1161)]:{
            background:'none',
           overflowX:'unset',
        },
        [theme.breakpoints.down(600)]:{
            marginTop: '79.51px',
        }
    },
   ContainerInfoLectura: {
    position: "relative",
    height: "86px",
    [theme.breakpoints.down(502)]:{
        height: "43px",
    },
    background: "#76D4C3",
    "& .BarraInfoLectura": {
      height: "86px",
      background: `Url(https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Barras/Azul.png)`,
        [theme.breakpoints.down(502)]:{
            height: "43px",
        },
    },
    "& .InfoLectura": {
      position: "absolute",
      height: "86px",
      width: "219px",
      boxSizing: "border-box",
      padding: "17.2px 0",
      background: "#FAFAFA",
      borderRadius: "50px",
      boxShadow: "2px 2px 2px 2px #00000029",
      zIndex: "4",
        [theme.breakpoints.down(502)]:{
            height: "43px",
            width: "109px",
            padding: "8.6px 0",
            borderRadius: "25px",
        },
    },
    "& .InfoLectura:before": {
      position: "absolute",
      display: "flex",
      boxSizing: "border-box",
      padding: "0 0 0 10px",
      justifyContent: "center",
      alignItems: "center",
      height: "39.88px",
      width: "39.88px",
      borderRadius: "50%",
      content: '""',
      boxShadow: "3px 0px .5px 0px #00000029",
      background: "#FAFAFA",
      left: "90px",
      top: "65px",
      transform: "rotate(0.25turn)",
      [theme.breakpoints.down(502)]:{
        left: "45px",
        top: "30px",
        height: "20.88px",
        width: "20.88px",
        },
    },
    "& .InfoLectura:after": {
      content: '""',
      height: "15px",
      width: "15px",
      borderTop: "2px solid #20A698",
      borderLeft: "2px solid #20A698",
      position: "absolute",
      bottom: "-10px",
      left: "Calc(50% - 8px)",
      transform: "rotate(0.625turn)",
      [theme.breakpoints.down(502)]:{
        height: "10px",
        width: "10px",
        left: "Calc(50% - 4.5px)",
        bottom: "-3px",
        borderTop: "1px solid #20A698",
        borderLeft: "1px solid #20A698",
        },
    },
    "& .Sesion": {
      borderLeft: "2px solid #D8D8D8",
    },
    "& .Dato": {
      margin: "0 0 5px 0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#20A698",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        margin: "0 0 2.5px 0",
        },
    },
    "& .Caracter": {
      margin: "0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#000000",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        },
    },   },
    ContenedorLectura:{
        background:'#fff',
        width:'1160px',
        height:'100%',
        padding:'60px 168px 35px',
        [theme.breakpoints.down(1161)]:{
            width:'502px',
            padding:'70px 0 0',
          },
          [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
        '& .ContainerDerechos':{
            bottom:'80px',
            [theme.breakpoints.down(1161)]:{
                display:'none',
            },
        },
        '& .Derechos':{
            textAlign:"center",
            margin:"0px",
            font:" 15px lato",
            color:"#717476",
        },
        '& .Escritorio':{
            display:'block',
            [theme.breakpoints.down(1161)]:{
                display:'none'
            },
        },
        '& .Tablet':{
            display:'none',
            [theme.breakpoints.down(1161)]:{
                display:'block'
            },
        }
    },
    ContainerTitulo:{
        margin:'0 0 46px 0',
        '& .Titulo':{
            font:'48px fredoka one',
            letterSpacing: '0px',
            color: '#FF0000',
            margin:'0',
            textAlign:'center',
            [theme.breakpoints.down(1161)]:{
                font:'43px fredoka one',
                lineHeight:'52px',
            },
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        position:'relative',
        margin:' 0 0 36px 0',
        '& .Lectura':{
            lineHeight : '30px',
            margin:'0 0 36px 0 ',
            font:'22px lato',
            color:'#000',
            letterSpacing: '0.20px',
            [theme.breakpoints.down(1161)]:{
                lineHeight :'28px',
                font:'18px lato',
                letterSpacing: '0.17px',
            },
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .Lectura2':{
            margin:' 0 0 8px 0 ',
            [theme.breakpoints.down(1161)]:{
                margin:' 0 0 4px 0 ',
            },
        },
        '& .Lectura3':{
            margin:' 0',
        },
        '& .TextImg':{
            margin:'22px 0 0 0',
            font:'20px lato',
            alignSelf:'flex-start',
            color:'#717476',
            [theme.breakpoints.down(1161)]:{
                font:' 16px lato',
                letterSpacing:'.12px',
            },
            [theme.breakpoints.down(502)]:{
                font:' 11px lato',
                lineHeight :'14px',
                letterSpacing: '0.10px',
            },
        },
        '& .Ul':{
            margin:'0',
            padding:' 0 0 0 20px',
            font:'bold 22px lato',
            color:'#FF0000',
            listStyle:'lower-latin',
            [theme.breakpoints.down(1161)]:{
                font:' bold 18px lato',
                letterSpacing:'.17px',
            },
        },
        '& .Ul2':{
            margin:'0',
            padding:' 0 0 0 20px',
            font:'bold 22px lato',
            color:'#F6644B',
            listStyle:'alpha',
            [theme.breakpoints.down(1161)]:{
                font:' bold 18px lato',
                letterSpacing:'.17px',
            },
        },

        '& .ContainerCuadro':{
            position:'relative',
        },
        '& .Esp':{
            position:'absolute',
            margin:'35px 0 0 0 ',
            width:'750px',
            [theme.breakpoints.down(1161)]:{
                width:'85%',
            },
            [theme.breakpoints.down(502)]:{
                margin:'20px 0 0 0',
            },
        },
 
        '& .Imagen':{
            [theme.breakpoints.down(1161)]:{
                width:'100%',
            },
        },
        '& .Imagen2':{
            [theme.breakpoints.down(502)]:{
                width:'100%',
                height:'105%',
            },
        },


    },

}));

const El_equilibrio_de_Nash = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
             <Grid container item justifyContent="center" className={classes.ContainerInfoLectura}>
               <Grid container className="BarraInfoLectura"></Grid>
               <Grid container item className="InfoLectura">
                    <Grid container item direction="column" xs={6} justifyContent="center">
                        <p className="Dato">Grado</p>
                        <p className="Caracter">6</p>
                    </Grid>
                    <Grid container item direction="column" xs={6} justifyContent="center" className="Sesion">
                        <p className="Dato">Sesión</p>
                        <p className="Caracter">10</p>
                    </Grid>
               </Grid>
            </Grid>

            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >
                        El equilibrio de Nash
                    </h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                            Piensa en la cantidad de juegos con los que te has divertido desde pequeño hasta 
                            esta etapa de tu vida o incluso los que aún juegan los adultos. Aunque la mayoría 
                            parecen actividades sencillas, la realidad es que en muchos intervienen las 
                            matemáticas más de lo que podrías imaginar: ya sea en la manera en que fueron 
                            creados o en las estrategias necesarias para ganar. <br/><br/>
                            
                            Dos ejemplos de estos juegos son los siguientes. Uno, el lanzamiento de dados, en el 
                            que hay que calcular las probabilidades para que caiga algún resultado. Otro, las 
                            Torres de Hanói, que debes resolver en el menor número posible de pasos. Si nunca 
                            lo has intentado o no lo recuerdas, el juego consiste en pasar todos los discos 
                            apilados en un poste a cualquier otro, siguiendo tres reglas:<br/><br/>

                            <ul className='Ul'>
                                <li ><p className='Lectura Lectura2'>Mover un disco a la vez.</p></li>
                                <li ><p className='Lectura Lectura2'>Sólo se puede mover el disco superior.</p></li>
                                <li ><p className='Lectura Lectura2'>Un disco de mayor tamaño no puede estar encima de uno más pequeño.</p></li>
                            </ul>
                        </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <Grid container justifyContent="center" alignItems='flex-end'>
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion10/1.png" alt="Tarjeta" />
                        </Grid>
                        <Grid container justifyContent='center'>
                            <p className='TextImg'> Juego común de las Torres de Hanói.</p>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                            <p className="Lectura ">
                                Aunque podría parecer simple, la explicación para resolverlo en el menor número de 
                                pasos posibles está basada en cálculos matemáticos. <br/><br/>
                                
                                Debido a estos y otros casos es que en las décadas de los cuarenta y cincuenta del 
                                siglo pasado, surgió una rama de las matemáticas conocida como Teoría de Juegos. 
                                Los pioneros fueron los matemáticos John Von Neumann, John Nash y el economista 
                                Oskar Morgenstern. A continuación, lee la definición matemática de juego:
                            </p>
                    </Grid>
                    <Grid container justifyContent='center' className='ContainerCuadro'>
                        <img className="Imagen2 Escritorio" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion10/2.png" alt="Tarjeta" />
                        <img className="Imagen2 Tablet" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion10/2R.png" alt="Tarjeta" />
                        <p className='Lectura Esp'>
                            Toda aquella situación en la que dos o más personas interactúan, donde las decisiones de cada jugador son resultado de lo que espera que los otros hagan.
                        </p>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <p className="Lectura ">
                        Para explicarlo mejor, toma como ejemplo el juego “piedra, papel o tijeras”: tienes 
                        únicamente tres opciones para elegir, las cuales se basan en lo que esperas que el 
                        otro jugador decida. Es decir, cuando tú eliges piedra es porque esperas que la otra 
                        persona escoja tijeras.<br/><br/>
                        
                        El caso anterior se clasifica como <em>juego no cooperativo</em>, pues cada jugador toma sus 
                        decisiones de forma independiente, aunque con conocimiento de las estrategias que 
                        puede utilizar el otro. Para analizar esta subdivisión de los juegos, a continuación 
                        leerás “El dilema del prisionero”, un problema que se puede explicar a partir de las
                        matemáticas. <br/><br/>
                        
                        Imagina que dos sospechosos de un delito son detenidos e interrogados, pero no hay 
                        pruebas suficientes para castigarlos. Para presionarlos a que confiesen, la policía ha 
                        ideado un plan que incluye interrogarlos por separado, presentándoles los siguientes 
                        escenarios, sin la posibilidad de que se pongan de acuerdo:<br/><br/>
                        
                        <ul className='Ul2'>
                            <li ><p className='Lectura Lectura2'>Si ninguno confiesa, ambos irán a prisión durante dos años.</p></li>
                            <li ><p className='Lectura Lectura2'>Si uno confiesa en contra del otro, entonces uno quedará libre y a su compañero le 
                            tocará una condena de diez años.</p></li>
                            <li ><p className='Lectura Lectura2'>Si ambos se acusan mutuamente, los dos irán a prisión por seis años.</p></li>
                        </ul><br/>

                        En este juego, la estrategia ganadora es que ambos cooperen entre sí; es decir, que 
                        no confiesen, pues así recibirían el menor castigo. Sin embargo, esto no es posible, 
                        pues se trata de un <em>juego no cooperativo</em> en el que cada uno toma sus decisiones de 
                        manera independiente.<br/><br/>
                        
                        Lo anterior sucede porque cada uno tiene el riesgo de que si no confiesa contra su 
                        compañero, pero el otro decide traicionarlo, entonces pasará diez años en prisión. 
                        ¡Es mucho riesgo! En cambio, si toma la decisión de delatarlo tiene dos ventajas. La 
                        primera es que puede salir libre si el otro decide no acusarlo. La segunda es que si su 
                        compañero también lo delata la pena se reduce de diez a seis años. Al final, los dos 
                        terminarán delatándose. <br/><br/>
                        
                        A esta toma de decisiones y el resultado que generan se le conoce en la Teoría de 
                        Juegos como el Equilibro de Nash. <br/><br/>
                        
                        Esta explicación matemática se puede aplicar a muchas otras situaciones. Por 
                        ejemplo, en las decisiones tomadas durante la pandemia. El confinamiento es un caso 
                        similar: el no salir de casa es una afectación menor, por muy aburrido que pueda 
                        parecer, en comparación con que todos estén en la calle y se genere una mayor 
                        propagación del virus. Para profundizar en este asunto, toma en cuenta la siguiente 
                        infografía.
                    </p>
                    <Grid container item justifyContent="center" >
                        <img className="Imagen Escritorio" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion10/3I.png" alt="Tarjeta" />
                        <img className="Imagen Tablet" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion10/3R.png" alt="Tarjeta" />
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <p className="Lectura ">
                        Los datos indican que el mejor escenario es que si dos o más personas utilizan el 
                        cubrebocas y mantiene sana distancia, no hay posibilidad de que se contagien. En 
                        cambio, el peor escenario es donde ninguno coopera, entonces existe una posibilidad 
                        del 90 % de que las personas se infecten.
                    </p>
                </Grid>
                
                <Grid container item xs={12} justifyContent="center" className="ContainerDerechos">
                <p className="Derechos">
                    © 2022. Todos los derechos reservados, Pearson, Circuito de lectores.
                </p>                
            </Grid>
        </Grid>
    </Grid>
    );
}
 
export default El_equilibrio_de_Nash;
