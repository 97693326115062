import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
    ContenedorLectura:{
        width:'502px',
                  [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
    },
    ContainerTitulo:{
        margin:'0 0 34px 0',
        '& .Titulo':{
            letterSpacing: '0px',
            color: '#F6664D',
            margin:'0',
            textAlign:'center',
            font:'43px fredoka one',
            lineHeight:'52px',
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            margin:'0 0 36px 0',
            color:'#000',
            font:'18px lato',
            lineHeight :'28px',
            letterSpacing: '0.17px',
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .Esp':{
            padding:'0 ',
        },
        '& .SubTitulo':{
            color:'#26CE64',
            margin:'0',
            lineHeight :'28px',
            font:'bold 26px lato',
            letterSpacing: '0.24px',
            [theme.breakpoints.down(502)]:{
                font:'bold 18px lato',
                lineHeight :'24px',
                letterSpacing: '0.21px',
            },
        },
        '& .Imagen':{
            width:'100%',
        },  
      
    }
}));

const Nuevas_costumbres = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >Nuevas costumbres</h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                      <p className="Lectura">
                        El día en que el joven león abandonó la manada para formar su propia familia, su 
                        madre le dio algunos consejos.<br/> <br/>
                        
                        —Antes de que te vayas —le dijo—, quiero que recuerdes que cada una de las 
                        actividades de la casa debe hacerse en equipo. Así, el trabajo será menos pesado y la 
                        convivencia más agradable.
                      </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <Grid container justifyContent="center" >
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img1ro/Sesion31/1.png" alt="Tarjeta" />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                      <p className="Lectura">
                        El joven león se marchó. En el camino se dio cuenta de que no sabía cómo actuar 
                        fuera de su hogar. En su casa, su mamá cazaba, limpiaba y educaba a las demás 
                        leonas, mientras que su papá rugía cómodamente acostado en su roca favorita. Eso 
                        era lo único que sabía hacer.
                      </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <Grid container justifyContent="center" >
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img1ro/Sesion31/2.png" alt="Tarjeta" />
                        </Grid>
                    </Grid>
                </Grid>  
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                      <p className="Lectura">
                        “Aprenderé nuevas costumbres”, pensó. Pasó el tiempo y conoció a una joven leona. 
                        Ella era independiente y estaba acostumbrada a compartir las responsabilidades del 
                        hogar. Con su amor y compresión, el joven león aprendió a contribuir en el hogar.
                      </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <Grid container justifyContent="center" >
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img1ro/Sesion31/3.png" alt="Tarjeta" />
                        </Grid>
                    </Grid>
                </Grid>  
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                      <p className="Lectura">
                        Y así, poco después, se veía a la pareja de jóvenes leones siempre juntos; ambos 
                        limpiaban su casa y ambos educaban a sus cachorros. Al principio, los leones más 
                        viejos criticaron este comportamiento, pero con el tiempo otros leones machos 
                        siguieron su ejemplo. Ahora, los leones (tanto machos como hembras) son los felinos 
                        que mejor viven en compañía.
                      </p>
                    </Grid>
                </Grid>  
        </Grid>
    </Grid>
    );
}
 
export default Nuevas_costumbres;
