import React               from "react";
import {Grid, makeStyles}                 from '@material-ui/core';

const useStyles = makeStyles(theme =>({
  questionContainer:{
    width: "100%",
    height:"100%",
   },
  reading: {
    width: "80%",
    margin:"10px 0 10px 0 ",
    font: '22px lato',
    letterSpacing: '0.21px',
    [theme.breakpoints.down(1400)]:{
      font: " 18px lato",
      width: "85%",
    },
    [theme.breakpoints.down(1310)]:{
      font: "500 16px lato",
      // width: "80%",
      margin:"10px 0 10px 0"
    },
    [theme.breakpoints.down(925)]:{
      font: "500 14px lato",
      // width: "70%",
      margin:"6px 0 6px 0"
    },
    [theme.breakpoints.down(426)]:{
      font: "500 12px lato",
      // width: "70%",
      margin:"6px 0 6px 5%"
    }, 
  },
  table: {
    border: '1px solid black',
    width: "100%",
    font: '22px lato',
    [theme.breakpoints.down(1400)]:{
      font: " 18px lato",
      width: "85%",
    },
    [theme.breakpoints.down(1310)]:{
      font: "500 16px lato",
      // width: "80%",
      margin:"10px 0 10px 0"
    },
    [theme.breakpoints.down(925)]:{
      font: "500 14px lato",
      // width: "70%",
      margin:"6px 0 6px 0"
    },
    [theme.breakpoints.down(426)]:{
      font: "500 12px lato",
      // width: "70%",
      margin:"6px 0 6px 5%"
    }, 
  },
  tdTable: {
    padding: "0 1%",
    border: '1px solid black',
  },
  leftHead:{

  }
}));

const QuestionTable = (props) => {
  const classes = useStyles()


  return(
    <Grid container className={classes.questionContainer}>
      <p
        className={classes.reading}
      >
        3. Relaciona los nombres de las mujeres de la historia que desafiaron los roles de genero con sus logros. 
      </p>
      <br/>
      <table className={classes.table}>
        <thead>
          <th style={{width: "40%", border: '1px solid black'}}><b>Mujeres de la historia</b></th>
          <th style={{width: "60%", border: '1px solid black'}}><b>Logros</b></th>
        </thead>
        <tbody>
          <tr>
            <td className={classes.tdTable}>
              <p className={classes.reading}>
                1. Marie Curie.
              </p>
            </td>
            <td className={classes.tdTable}>
              <p className={classes.reading}>
                a. Es la primera mujer que viajó al espacio.
              </p>
            </td>
          </tr>
          <tr>
            <td className={classes.tdTable}>
              <p className={classes.reading}>
                2. Amelia Earhart
              </p>
            </td>
            <td className={classes.tdTable}>
              <p className={classes.reading}>
                b. Fue escritora, filósofa y profesora, precursora del feminismo.
              </p>
            </td>
          </tr>
          <tr>
            <td className={classes.tdTable}>
              <p className={classes.reading}>
                3. Simone de Beauvoir
              </p>
            </td>
            <td className={classes.tdTable}>
              <p className={classes.reading}>
                c. Fue la primera mujer en recibir el premio Nobel y recibir dos premios Nobel en distintas disciplinas: Física y Química.
              </p>
            </td>
          </tr>
          <tr>
            <td className={classes.tdTable}>
              <p className={classes.reading}>
                4. Valentina Tereshkova
              </p>
            </td>
            <td className={classes.tdTable}>
              <p className={classes.reading}>
                d. Es pionera en la aviación y la primera mujer en sobrevolar el Océano Atlántico sola.
              </p>
            </td>
          </tr>
        </tbody>
      </table>
    </Grid>
  )
}

export default QuestionTable;
