import React from "react";
import { Grid, makeStyles } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MoreVertIcon from "@material-ui/icons/MoreVert";

const useStyles = makeStyles((theme) => ({
  icon: {
    cursor: "pointer",
    fontSize: "30px",
    color: "#F6644B",
  },
}));

export default function CustomizedMenus(props) {
  const classes = useStyles();
  const { children, user } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Grid>
      <IconButton aria-label="more" aria-controls="long-menu" aria-haspopup="true" onClick={handleClick}>
        <MoreVertIcon className={classes.icon} />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        // placement=
        PaperProps={{
          style: {
            width: "175px",
            margin: 0,
            zIndex: "6",
          },
        }}
        MenuListProps={{
          style: {
            margin: 0,
            padding: 0,
            minHeight:'auto',
          },
        }}
        anchorOrigin={{ vertical: "top", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        {children &&
          children.map((child) =>
            React.cloneElement(
              child,
              {
                onClick: () => {
                  child.props.onClick && child.props.onClick(user);
                  handleClose();
                },
              },
              child.props.children
            )
          )}
      </Menu>
    </Grid>
  );
}
