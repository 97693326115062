import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
    ContenedorLectura:{
        width:'502px',
                  [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
    },
    ContainerTitulo:{
        margin:'0 0 34px 0',
        '& .Titulo':{
            letterSpacing: '0px',
            color: '#DF5DA7',
            margin:'0',
            textAlign:'center',
            font:'43px fredoka one',
            lineHeight:'52px',
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        position:'relative',
        '& .Lectura':{
            margin:'0 0 36px 0',
            font:'18px lato',
            lineHeight :'28px',
            letterSpacing: '0.17px',
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        "& .SubTitulo": {
            color: "#0CB5D6",
            margin: "0",
            lineHeight: "28px",
            font: "bold 26px lato",
            letterSpacing: "0.24px",
            [theme.breakpoints.down(502)]: {
              font: "bold 22px lato",
              lineHeight: "24px",
              letterSpacing: "0.21px",
            },
        },
        "& .SubTitulo2": {
            textAlign:'center',
            color: "#F6644B",
            margin:'0',
            font: "35px Fredoka One",
            lineHeight: "40px",
            [theme.breakpoints.down(502)]: {
              font: "25px Fredoka One",
              lineHeight: "22px",
            },
          },
        '& .TextImg':{
            color:'#717476',
            font:' 16px lato',
            lineHeight :'25px',
            letterSpacing: '0.15px',
            [theme.breakpoints.down(502)]:{
                font:' 11px lato',
                lineHeight :'14px',
                letterSpacing: '0.10px',
            },
        },

        '& .Imagen':{
            width:'100%',
        }, 

    }
}));

const El_origen_del_cine = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >
                     El origen del cine                
                    </h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                      <p className="Lectura">
                        El cine es conocido como el <em>séptimo arte</em>. El término es un acortamiento de 
                        <em> cinematógrafo</em>, palabra que proviene de dos términos de origen griego: <em>kínēma</em>, que 
                        significa “movimiento”, y <em>gráphein</em>, que quiere decir “escribir”. El concepto surgió de 
                        describir la manera en que funciona, pues relata una historia mediante imágenes que 
                        se mueven. ¿Te gustaría conocer su origen?<br/><br/> 
                        
                        Thomas Alva Edison fue un inventor estadounidense que creó un aparato llamado 
                        <em> quinetoscopio</em> en 1889. Éste tenía ilustraciones en su interior y hacía parecer que se 
                        movían. Todo comenzaba con una tira de fotografías perforadas, la cual era puesta 
                        sobre una luz con un obturador que tenía alta velocidad. Con él, se sentaron las bases 
                        para esta técnica artística, es decir, el movimiento de imágenes o ilustraciones. <br/><br/> 
                        
                        <h3 className='SubTitulo'>Las primeras películas del mundo</h3><br/>

                         Los hermanos Louis y Auguste Lumière perfeccionaron la máquina de Edison en 
                         1895 y crearon el cinematógrafo. Ese mismo año proyectaron las primeras escenas 
                         que grabaron con su invento. Éstas se titulaban <em>Salida de los obreros de una fábrica 
                         francesa en Lyon, El desayuno del bebé, El regador regado </em>y <em>La llegada de un tren a la 
                         estación de La Ciotat.</em> <br/><br/> 
                         
                         Todas eran películas mudas, en blanco y negro, que retrataron fragmentos de la vida 
                         cotidiana en Francia durante aquella época. Se dice que la gente que asistió al evento
                        salió huyendo de la sala cuando vio la escena en la que un tren avanzaba, pues 
                        creyeron que se saldría de la pantalla. Era la primera vez que observaban ese plano 
                        con movimiento.

                      </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <Grid container justifyContent="center" >
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img3ro/Sesion56/1.png" alt="Tarjeta" />
                        </Grid>
                        <Grid container justifyContent="center" >
                            <p className="TextImg">
                            Escena de <em>La llegada de un tren a la estación de La Ciotat</em>.                           
                            </p>
                        </Grid>
                    </Grid>
                </Grid>
                
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                            El arte de los hermanos Lumière sólo mostraba escenas aisladas, pero muy pronto 
                            surgieron los primeros directores que trabajaron en escenarios y tramas más 
                            elaborados: Alice Guy y Georges Méliès, quienes fueron dos de las figuras más 
                            relevantes en este campo.  
                        </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img3ro/Sesion56/3.png" alt="Tarjeta" />
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura ">
                            <h3 className='SubTitulo2'>Alice Guy</h3><br/>
                            Ella fue la primera mujer directora del mundo. Adaptó un cuento popular francés y 
                            filmó un cortometraje titulado <em>El hada de las coles</em> en 1896. Se convirtió en la primera 
                            obra de ficción hecha en un cinematógrafo. Tiene una duración de 60 segundos y 
                            presenta a un hada apareciendo bebés entre un campo de coles mientras baila 
                            alegremente.
                        </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img3ro/Sesion56/3.png" alt="Tarjeta" />                               
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura ">
                            <h3 className='SubTitulo2'>Georges Méliès</h3><br/>

                            Él fue el primer cineasta en utilizar efectos especiales. En 1902 proyectó el 
                            largometraje <em>Viaje a la Luna</em>, una de las cintas más icónicas de la historia. Narra el 
                            viaje fantástico de un grupo de astrónomos a la Luna, donde encuentran seres vivos. <br/><br/>
                            
                            En aquel entonces, el ser humano aún no exploraba el espacio, por lo que fue una 
                            obra futurista. Su escena más representativa es la del cohete incrustado en un ojo de 
                            la Luna. ¿Conoces esta famosa escena? ¡Aquí tienes el fotograma!
                        </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <Grid container justifyContent="center" sm={12}>
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img3ro/Sesion56/2.png" alt="Tarjeta" />                 
                        </Grid>
                        <Grid container justifyContent="center" >
                            <p className="TextImg">
                                Escena de <em>Viaje a la Luna</em>.                          
                            </p>
                        </Grid>                    
                    </Grid>
                </Grid>
        </Grid>
    </Grid>
    );
}
 
export default El_origen_del_cine ;
