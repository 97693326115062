import React, { useContext, useState } from "react";
import { makeStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import moment from "moment";
import ModalImage from "../../Modals/ModalImage";
import StarIcon from "@mui/icons-material/Star";

import pdfImage from "../../imgs/pdf.png";
import docImage from "../../imgs/Documento.png";
import ChatContext from "./Context/ChatContext";
import AuthContext from "../../../auth/AuthContext";
const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    display: "flex",
    alignContent: "center",
    flexDirection: "column",
    alignItems: "flex-end",
    // flexDirection: "row",
    justifyContent: "flex-end",
    gap: "2.5%",
    [theme.breakpoints.up("lg")]: {
      justifyContent: "flex-end",
    },
  },
  containerText: {
    backgroundColor: "#FFDF76",
    display: "flex",
    borderRadius: "20px",
    position: "relative",
    alignItems: "center",
    zIndex: 2,
    minHeight: "50px",
    boxSizing: "border-box",
    padding: "10px 20px",
    "&:before": {
      // display: (props) => (props.openMenuInDesktop ? "none" : "flex"),
      display: "flex",
      zIndex: -1,
      textTransform: "none",
      justifyContent: "center",
      alignItems: "center",
      // borderRadius: "25px",
      position: "absolute",
      content: '""',
      // height: "10px",
      // width: "10px",
      border: "10px solid",
      borderTopColor: "transparent",
      borderLeftColor: "transparent",
      borderRightColor: "#FFDF76",
      borderBottomColor: "#FFDF76",
      bottom: "15%",
      right: "-5px",
      transform: "rotate(330deg)",
    },
    [theme.breakpoints.up("lg")]: {
      padding: "20px",
      maxWidth: "80%",
    },
  },
  text: {
    font: "normal 16px Lato",
    textAlign: "left",
    margin: 0,
    // minHeight: "30px",
    minWidth: "5ch",
    overflowWrap: "anywhere",
  },
  image: {
    borderRadius: "50%",
    display: "none",
    width: "50px",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  timeAgo: {
    color: "#969D9F",
    // position: "absolute",
    width: "100%",
    textAlign: "right",
    fontSize: "10px",
    // bottom: "-20px",
    // left: 0,
  },
  containerDocument: {
    // backgroundColor: "#FFDF76",
    display: "flex",
    borderRadius: "20px",
    // position: "relative",

    zIndex: 2,
    flexDirection: "column",
    alignItems: "flex-end",
    boxSizing: "border-box",

    [theme.breakpoints.up("lg")]: {
      // padding: "20px",
      maxWidth: "80%",
    },
  },
  imageDocument: {
    width: "100%",
  },
  imageButton: {
    // padding: "10px 20px",
    zIndex: 2,
    position: "relative",
    width: "50%",
    boxSizing: "border-box",
    borderRadius: "20px",
    display: "flex",
    justifyContent: "flex-end",
  },
  timeAgoDocument: {
    color: "#969D9F",
    // position: "absolute",
    // width: "100%",
    textAlign: "left",
    fontSize: "10px",
  },
  imgContainer: {
    width: "75%",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    [theme.breakpoints.up("lg")]: {
      width: "25%",
    },
  },
  img: {
    width: "auto",
    maxHeight:"480px",
    objectFit: "cover",
    cursor: "pointer",
    border:"2.5px solid #007FA3"
  },
  favoritesButton: {
    border: "none",
    backgroundColor: "transparent",
    cursor: "pointer",
    outline: 'none',
    // [theme.breakpoints.up("lg")]: {
    //   display: "none",
    // },
  },
  iconStar: {
    color: "#F3CD52",
  },
  iconStarOff: {
    color: "#00000050",
  },
}));

const OwnMessage = ({ message, timestamp, type, id, favorite }) => {
  const classes = useStyles();
  moment.locale("es", {
    calendar: {
      lastDay: "[Yesterday] h:mm a",
      sameDay: "[Today] h:mm a",
      lastWeek: "[Last] dddd h:mm a",
      sameElse: "Do MMMM YYYY h:mm a",
    },
  });
  const now = moment(timestamp);
  const dateInString = now.calendar();
  const [open, setOpen] = useState(false);

  const { addMessageToFavorite } = useContext(ChatContext);
  const { user } = useContext(AuthContext);
  const addToFavorite = () => {
    addMessageToFavorite({
      id_mensaje: id,
      favorito: !message.favorito,
      sala: message?.sala,
    });
  };

  return (
    <>
      {type === "texto" && (
        <Grid className={classes.container}>
          <Grid container direction="row" justifyContent="flex-end" alignItems="center">
            {user?.rol === "docente" && (
              <button className={classes.favoritesButton} onClick={addToFavorite}>
                <StarIcon className={message?.favorito ? classes.iconStar : classes.iconStarOff} />
              </button>
            )}

            <Grid className={classes.containerText}>
              <p className={classes.text}>{message?.mensaje}</p>
            </Grid>
          </Grid>
          <span className={classes.timeAgo}>{dateInString}</span>
        </Grid>
      )}
      {type === "document" && (
        <Grid className={classes.container}>
          <Grid container direction="row" justifyContent="flex-end" alignItems="center">
            {user?.rol === "docente" && (
              <button className={classes.favoritesButton} onClick={addToFavorite}>
                <StarIcon className={message?.favorito ? classes.iconStar : classes.iconStarOff} />
              </button>
            )}
            <Grid className={classes.containerDocument}>
              <a href={message?.mensaje} download className={classes.imageButton} target="_blank" rel="noreferrer">
                <img src={message?.mensaje.includes("pdf") ? pdfImage : docImage} alt="" className={classes.imageDocument} />
              </a>
              <span className={classes.timeAgoDocument}>{dateInString}</span>
            </Grid>
          </Grid>
        </Grid>
      )}
      {type === "image" && (
        <Grid className={classes.container}>
          <Grid container direction="row" justifyContent="flex-end" alignItems="center">
            {user?.rol === "docente" && (
              <button className={classes.favoritesButton} onClick={addToFavorite}>
                <StarIcon className={message?.favorito ? classes.iconStar : classes.iconStarOff} />
              </button>
            )}
            <Grid className={classes.imgContainer}>
              <img
                src={message?.mensaje}
                alt=""
                className={classes.img}
                onClick={() => {
                  setOpen(!open);
                }}
              />
              <ModalImage
                path={message?.mensaje}
                open={open}
                handleClose={() => {
                  setOpen(!open);
                }}
              />
              <span className={classes.timeAgoDocument}>{dateInString}</span>
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default OwnMessage;
