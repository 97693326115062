import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
    ContenedorLectura:{
        width:'502px',
                  [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
    },
    ContainerTitulo:{
        margin:'0 0 34px 0',
        '& .Titulo':{
            letterSpacing: '0px',
            color: '#823CE2',
            margin:'0',
            textAlign:'center',
            font:'43px fredoka one',
            lineHeight:'52px',
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            margin:'0 0 36px 0',
            color:'#000',
            font:'18px lato',
            lineHeight :'28px',
            letterSpacing: '0.17px',
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        "& .SubTitulo": {
            color: "#DF5DA7",
            margin: "0",
            lineHeight: "28px",
            font: "bold 26px lato",
            letterSpacing: "0.24px",
            [theme.breakpoints.down(502)]: {
              font: "bold 22px lato",
              lineHeight: "24px",
              letterSpacing: "0.21px",
            },
          },

        '& .Imagen':{
            width:'100%',
        }, 
    }
}));

const Lógica_creatividad_y_diversión_en_las_matemáticas = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>

            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >
                        Lógica, creatividad <br/>y diversión en las <br/>matemáticas                 
                    </h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                      <p className="Lectura">
                        <h3 className='SubTitulo'>Los números en el día a día</h3><br/>
                        
                        Como podrás notar, las matemáticas se encuentran en todos los aspectos de tu vida. 
                        Las necesitas para llevar la contabilidad de tus gastos cuando vas a la tienda, para 
                        saber tu edad y qué tan numerosa es tu familia, para coleccionar objetos y para 
                        enumerar las reglas de los juegos que juegas. <br/><br/>
                        
                        Están presentes en todo lo que ves a tu alrededor. Por eso son tan importantes y 
                        llevas una materia en la escuela para aprender a estudiarlas. Tal vez has escuchado 
                        decir que son “aburridas”, pero queremos contarte que no es así: las matemáticas son 
                        divertidas y pueden despertar tu creatividad. <br/><br/>
                        
                        <h3 className='SubTitulo'>Del dominó al pentaminó</h3><br/>
                        
                        ¿Alguna vez has jugado al <em>dominó</em>? Es un juego de fichas rectangulares, divididas en 
                        dos cuadrados iguales que pueden contener de 0 a 6 puntos, como éste:
                      </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <Grid container justifyContent="center" >
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img2do/Sesion48/1.png" alt="Tarjeta" />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                            Existe un juego parecido llamado <em>pentaminó</em>. La primera parte de la palabra, <em>penta-</em>, 
                            significa “cinco”. Es decir, es una ficha de cinco cuadrados iguales que se unen por 
                            alguno de sus lados, y sólo existen 12 fichas distintas. Esto significa que cualquier 
                            pentaminó que se te ocurra será igual a una de ellas.                        
                        </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <Grid container justifyContent="center" >
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img2do/Sesion48/3.png" alt="Tarjeta" />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                            De las cinco fichas de la ilustración, sólo tres son diferentes, no importa la posición 
                            en la que las coloques. <br/><br/>
                            
                            En la siguiente ilustración podrás ver sus 12 variaciones. Observa con atención y 
                            mira si encuentras algo curioso en ellas:          
                        </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <Grid container justifyContent="center" >
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img2do/Sesion48/2.png" alt="Tarjeta" />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                            ¿Notaste el parecido que tienen las fichas con el alfabeto? ¡Observa de nuevo! Algunas se parecen a las letras de la T a la Z, mientras que otras 
                            tienen semejanza con F, I, L, P y N. <br/><br/>
                            
                            El pentaminó es un juego de lógica y creatividad. Con estas 12 piezas es posible 
                            armar una infinidad de rompecabezas, aunque nunca podrás formar cuadrados al 
                            utilizarlas todas. Si tratas de hacerlo, notarás que faltan o sobran piezas. Lo que sí 
                            puedes hacer es un rectángulo, y para eso existen varias soluciones.<br/><br/>
                            
                            ¡Inténtalo! Pasarás un rato ameno, y fortalecerás tu mente y tu creatividad. Elabora 
                            pentaminós con cartulinas o cartón reciclado y pon retos en casa.      
                        </p>
                    </Grid>
                </Grid>
        </Grid>
    </Grid>
    );
}
 
export default Lógica_creatividad_y_diversión_en_las_matemáticas ;
