import { Button, Grid, makeStyles } from "@material-ui/core";
import React, { useContext, useEffect } from "react";
import DrawerContext from "../../../../../usability/DrawerContext";
import { useOnLoadImages } from "../../../../hooks/useOnLoadImages";
import Loader from "../../../../Loader/Loader";
import Drawer from "../../ReadingTools/Drawer";

const useStyles =  makeStyles (theme =>({
    Contenedor:{
        background:'#E9FBF9',
        width:'100vw',
        [theme.breakpoints.down(1161)]:{
            background:'none',
        },
        [theme.breakpoints.down(600)]:{
            marginTop: '79.51px',
        }
    },
  ContainerInfoLectura: {
    position: "relative",
    height: "86px",
    [theme.breakpoints.down(502)]:{
        height: "43px",
    },
    background: "#76D4C3",
    "& .BarraInfoLectura": {
      height: "86px",
      background: `Url(https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Barras/Azul.png)`,
        [theme.breakpoints.down(502)]:{
            height: "43px",
        },
    },
    "& .InfoLectura": {
      position: "absolute",
      height: "86px",
      width: "219px",
      boxSizing: "border-box",
      padding: "17.2px 0",
      background: "#FAFAFA",
      borderRadius: "50px",
      boxShadow: "2px 2px 2px 2px #00000029",
      zIndex: "4",
        [theme.breakpoints.down(502)]:{
            height: "43px",
            width: "109px",
            padding: "8.6px 0",
            borderRadius: "25px",
        },
    },
    "& .InfoLectura:before": {
      position: "absolute",
      display: "flex",
      boxSizing: "border-box",
      padding: "0 0 0 10px",
      justifyContent: "center",
      alignItems: "center",
      height: "39.88px",
      width: "39.88px",
      borderRadius: "50%",
      content: '""',
      boxShadow: "3px 0px .5px 0px #00000029",
      background: "#FAFAFA",
      left: "90px",
      top: "65px",
      transform: "rotate(0.25turn)",
      [theme.breakpoints.down(502)]:{
        left: "45px",
        top: "30px",
        height: "20.88px",
        width: "20.88px",
        },
    },
    "& .InfoLectura:after": {
      content: '""',
      height: "15px",
      width: "15px",
      borderTop: "2px solid #20A698",
      borderLeft: "2px solid #20A698",
      position: "absolute",
      bottom: "-10px",
      left: "Calc(50% - 8px)",
      transform: "rotate(0.625turn)",
      [theme.breakpoints.down(502)]:{
        height: "10px",
        width: "10px",
        left: "Calc(50% - 4.5px)",
        bottom: "-3px",
        borderTop: "1px solid #20A698",
        borderLeft: "1px solid #20A698",
        },
    },
    "& .Sesion": {
      borderLeft: "2px solid #D8D8D8",
    },
    "& .Dato": {
      margin: "0 0 5px 0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#20A698",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        margin: "0 0 2.5px 0",
        },
    },
    "& .Caracter": {
      margin: "0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#000000",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        },
    },
       '& .Regresar':{
        color: '#F2624F',
        border: '#F2624F 1px solid',
        height: '30px',
        width:'100px',
        borderRadius: '20px',
        letterSpacing: '0.14px',
        textTransform: 'none',
        background:'#FEFAF1',
        top:'calc(50% - 15px)',
        left:'5%',
        zIndex:4,
        [theme.breakpoints.down(502)]: {
          font:'bold 10px lato',
          width: "60px",
        },
      },
  },
    ContenedorLectura:{
        background:'#fff',
        width:'1160px',
        height:'100%',
        padding:'60px 168px 35px ',
            transform: (props) =>
      props.scale > 1 ? `scale(${props.scale}) translate(0px, 10%)` : `none`,
        [theme.breakpoints.down(1161)]:{
            width:'502px',
            padding:'60px 0 0 0',
          },
        [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
        '& .ContainerDerechos':{
            bottom:'80px',
            [theme.breakpoints.down(1161)]:{
                display:'none',
            },
        },
        '& .Derechos':{
            textAlign:"center",
            margin:"0px",
            font:" 15px lato",
            color:"#717476",
        },
        '& .Escritorio':{
            display:'block',
            [theme.breakpoints.down(1161)]:{
                display:'none'
            },
        },
        '& .Tablet':{
            display:'none',
            [theme.breakpoints.down(1161)]:{
                display:'block'
            },
        }
    },
    ContainerTitulo:{
        margin:'0 0 34px 0',
        '& .Titulo':{
            font:'48px fredoka one',
            letterSpacing: '0px',
            lineHeight:'58px',
            color: '#5851A8',
            margin:'0',
            textAlign:'center',
            [theme.breakpoints.down(1161)]:{
                font:'43px fredoka one',
                lineHeight:'52px',
                display:'block',
            },
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            }
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            lineHeight : '30px',
            margin:'0',
            font:'22px lato',
            color:'#000',
            letterSpacing: '0.20px',
            [theme.breakpoints.down(1161)]:{
                lineHeight :'28px',
                margin:'0',
                font:'18px lato',
                letterSpacing: '0.17px',
            },
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .SubTitulo':{
            font:'bold 26px lato',
            color:'#F6644B',
            letterSpacing:'.24px',
            lineHeight:'28px',
            margin:'0',
            [theme.breakpoints.down(1161)]:{
                lineHeight :'28px',
                font:'bold 26px lato',
                letterSpacing: '0.24px',
            },
            [theme.breakpoints.down(502)]:{
                font:'bold 18px lato',
                lineHeight :'24px',
                letterSpacing: '0.21px',
            },
        },
        '& .Imagen':{
            width:'100%',
        },
        
        '& .Ul':{
            margin:'0 0 15px 0',
            padding:'0 0 0 15px',
            color:'#F6644B',
            font:'6px'
        },
        '& .Li':{
            margin:'0 0 8px 0',
        },
        '& .Purple':{
            color:'#5851A8',
        },
      
    }
}));

const El_consumismo_y_la_generación_de_basura = (props) => {

  const {activeStep, setActiveStep, handlePreviewStep, id_actividad} = props

  const handleContinue = () => {
    setActiveStep(activeStep - 1)
    handlePreviewStep()
  }

    const {
    handleSize,
    disabledZoom,
    closeZoom,
    handleZoom,
    scale
  }= useContext(DrawerContext);
  const [loading, setLoading] = React.useState(true);
  const wrapperRef = React.useRef(null);
  const imagesLoaded = useOnLoadImages(wrapperRef);

  const detectSize = () => {
    const lectura = document.getElementById("lectura");
    const newWidth = lectura?.offsetWidth;
    const newHeight = lectura?.offsetHeight;
    setLoading(false);
    if (lectura) handleSize(newHeight, newWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", detectSize);
    return () => {
      handleZoom()
      window.removeEventListener("resize", detectSize);
      closeZoom()
    };
  }, []);

  const classes = useStyles({ disabledZoom,scale });
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
             <Grid container item justifyContent="center" className={classes.ContainerInfoLectura}>
               <Grid container className="BarraInfoLectura">
                    <Button className="Regresar" onClick={handleContinue}>
                        Regresar
                    </Button>
                </Grid>
               <Grid container item className="InfoLectura">
                    <Grid container item direction="column" xs={6} justifyContent="center">
                        <p className="Dato">Grado</p>
                        <p className="Caracter">6</p>
                    </Grid>
                    <Grid container item direction="column" xs={6} justifyContent="center" className="Sesion">
                        <p className="Dato">Sesión</p>
                        <p className="Caracter">4</p>
                    </Grid>
               </Grid>
            </Grid>
{!imagesLoaded && <Loader />}
        <Grid
            container
            item
            className={classes.ContenedorLectura}
            justifyContent="center"
            id="lectura"
            onLoad={detectSize}
            ref={wrapperRef}
        >
<Drawer id_lectura={id_actividad} />
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <Grid className='Escritorio'>
                        <h2 className="Titulo">El consumismo<br/> y la generación de basura</h2>
                    </Grid>
                    <Grid className='Tablet'>
                        <h2 className="Titulo">El consumismo y la<br/> generación de basura</h2>
                    </Grid>

                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <p className="Lectura">
                        <h3 className='SubTitulo'>¿Qué consumes? </h3><br/>
                        Todos los seres humanos tenemos necesidades básicas, como la alimentación, el 
                        vestido, la vivienda, la educación, etcétera. Para cubrirlas, se consumen bienes, como 
                        agua, vegetales, frutos, carne, útiles escolares, pero también servicios, como luz, gas, 
                        internet, entre otras. <br/><br/>
                        
                        Los hábitos de consumo dependen de las costumbres, la cultura e incluso las 
                        creencias de cada persona. Aunque muchos de éstos son indispensables, existe una 
                        tendencia preocupante por adquirir bienes y servicios que son considerados no 
                        necesarios, sobre todo en países desarrollados. A este fenómeno se le conoce como 
                        <em> consumismo</em>.<br/><br/> 
                        
                        <h3 className='SubTitulo'>¿Por qué crees que la gente compra cosas que no necesita?</h3><br/>

                        Esta pregunta tiene varias respuestas desde distintos ámbitos, como el social, el
                        cultural o el económico, entre otros. Por ejemplo, piensa en la publicidad. Las marcas 
                        invierten mucho dinero para que recibas mensajes todo el tiempo desde distintos 
                        medios como la televisión, la radio, los espectaculares de la calle o el internet. ¿Te has 
                        dado cuenta de que los anuncios que te aparecen en internet suelen tener relación 
                        con lo que últimamente has estado explorando? Así es como te incitan a comprar, 
                        consciente o inconscientemente.<br/><br/>
                    </p>
                                        
                    <Grid container justifyContent="center" sm={12}>
                        <Grid container justifyContent="center" >
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion4/1.png" alt="Tarjeta" />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                        <p className="Lectura">
                            La presión social también impulsa a la gente a adquirir cosas que no son 
                            estrictamente necesarias, como la consola de videojuegos más reciente, el celular 
                            último modelo, la ropa de moda, etcétera. <br/><br/>
                            
                            Muchas veces, esto es posible por la facilidad que ofrecen los bancos o las tiendas
                            para adquirir los bienes o servicios a crédito. Es decir, para que las personas paguen 
                            en mensualidades, sin que necesariamente cuenten con el dinero para comprarlos. 
                            Así es como el consumismo se convierte también en un problema económico. <br/><br/>
                            
                            <h3 className='SubTitulo'>¿El consumismo tiene un impacto ambiental? </h3><br/>
        
                            Satisfacer las necesidades de los consumidores demanda utilizar muchos recursos 
                            naturales, que después se convierten en contaminantes. Sobre todo, si no es 
                            indispensable, cuantos más productos se consumen, mayor cantidad de residuos 
                            sólidos se generan, pues gran parte de éstos son desechables. Analiza algunos 
                            problemas derivados de los residuos sólidos:<br/><br/>
                        </p>
                        <ul className='Ul'>
                            <li className='Li'><p className="Lectura"><b className="Purple">Espacio.</b>Sobre todo en las grandes ciudades, los tiraderos de basura están saturados 
                            y cada vez hay menos lugares para destinar nuevos espacios.</p></li>
                            <li className='Li'><p className="Lectura"><b className="Purple">Contaminación de la naturaleza.</b>La descomposición de la basura genera 
                            contaminantes que pueden alterar la composición del aire, del agua o del suelo.</p></li>
                            <li className='Li'><p className="Lectura"><b className="Purple">Proliferación de enfermedades.</b>En los tiraderos de basura se incuban bacterias, 
                            hongos y otros microorganismos que pueden dispersarse por el viento o mediante 
                            animales e insectos como ratas, ratones y cucarachas.</p></li>
                            <li className='Li'><p className="Lectura"><b className="Purple">Empaques.</b>Gran parte de lo que se consume se encuentra envuelto en bolsas, 
                            cartón, unicel, plástico, etcétera. Entonces, además del producto que utilizas, que 
                            posteriormente será basura, el empaque también contamina.</p></li>
                        </ul>
                    <Grid container justifyContent="center" sm={12}>
                        <Grid container justifyContent="center" >
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion4/CDL_6_E1_B1_Naturales_T_El_consumismo_y_la_generaci%C3%B3n_de_basura_02.png" alt="Tarjeta" />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <p className="Lectura">
                        Un problema reciente es el de los aparatos electrónicos, pues las personas los 
                        cambian cada vez más rápido. Por ejemplo, aproximadamente la mitad de los 
                        usuarios de teléfonos celulares renuevan sus dispositivos cada dos años. <br/><br/>
                        
                        El principal inconveniente de la basura electrónica es el de las baterías, que 
                        comúnmente están compuestas de litio. Este elemento contamina los mantos 
                        acuíferos e incluso se ha comprobado que puede favorecer el desarrollo de algunos
                        tipos de cáncer. <br/><br/>
                        
                        <h3 className='SubTitulo'>¿Cómo contribuir a la disminución de los problemas generados por el consumismo?</h3><br/>
                        Como humanidad, se debe buscar el desarrollo sustentable o sostenible. Esto 
                        significa cubrir las necesidades de los habitantes actuales, garantizando las de las 
                        generaciones futuras. <br/><br/>
                        
                        Para lograr lo anterior, puedes contribuir con pequeñas acciones. Algunas de ellas 
                        pueden ser comprar únicamente lo que necesitas, donar objetos que ya no necesites
                        para que alguien más los reutilice o separar los desechos para que posteriormente se 
                        reciclen. En conclusión, analiza tus hábitos para disminuir la cantidad de basura que 
                        produces y platícalo con tu familia. <br/><br/>

                        <h3 className='SubTitulo'>¿Sabes qué es la huella ecológica?</h3><br/>
                        Es una medición que indica el área natural que se necesita para producir los recursos 
                        y bienes que se utilizan. En internet están disponibles varias calculadoras que 
                        pueden ayudarte a realizar estas mediciones. Busca alguna y mide la huella ecológica 
                        de lo que consumes para que tomes conciencia del daño que se le hace al planeta. Así 
                        podrás planear cómo ayudar a disminuir este deterioro y cómo convertir el mundo 
                        en un mejor sitio para vivir.
                    </p>
                </Grid>
        </Grid>
        <Grid container item style={{ marginTop: 80}} ></Grid>
    </Grid>
    );
}

export default El_consumismo_y_la_generación_de_basura;