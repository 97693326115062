import { useCallback, useEffect, useState } from "react";
import axios from "../services/axios";
import types from "../types/types";

const useGetMapData = (id_grado, handleLoading, dispatch, history) => {
  const [controller, setController] = useState(null);

  const handleSignal = () => {
    const abortController = new AbortController();
    setController(abortController);
    return abortController;
  };

  const loadActivitiesList = useCallback(
    (signal) => {
      handleLoading(true);
      axios
        .get(`/user/actividad/list?grado=${id_grado}`, {
          signal: signal ? signal : null,
        })
        .then((response) => {
          if (response.status === 200) {
            const list = response.data.resultado.lista;
            dispatch({
              type: types.loadActivitiesList,
              payload: list,
            });

            const actividadesCompletas = list
              ?.filter((sesion) => sesion?.actividad_status === "Completa")
              ?.filter(
                (sesion) => !sesion?.actividad_tipo?.includes("Remedial")
              )?.length;

            dispatch({
              type: types.totalActividades,
              payload: actividadesCompletas,
            });
          }
        })
        .catch((error) => {
          if (error.code === "ERR_CANCELED") return null;
          history.push({
            pathname: "/error/servicios",
            state: {
              reason: "servicios",
            },
          });
        })
        .finally(() => {
          handleLoading(false);
        });
    },
    [dispatch, history, id_grado, handleLoading]
  );

  const handleCancelRequest = (abortController) => {
    if (abortController) abortController.abort();
    if (controller) controller.abort();
  };

  useEffect(() => {
    const abortController = handleSignal();
    loadActivitiesList(abortController.signal);
    return () => {
      abortController.abort();
      handleLoading(false);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return { handleCancelRequest, loadActivitiesList };
};

export default useGetMapData;
