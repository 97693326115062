import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
    ContenedorLectura:{
        width:'502px',
                  [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
    },
    ContainerTitulo:{
        margin:'0 0 34px 0',
        '& .Titulo':{
            letterSpacing: '0px',
            color: '#0C61D6',
            margin:'0',
            textAlign:'center',
            font:'43px fredoka one',
            lineHeight:'52px',
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            margin:'0 0 36px 0',
            color:'#000',
            font:'18px lato',
            lineHeight :'28px',
            letterSpacing: '0.17px',
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        "& .SubTitulo": {
            color: "#F6664D",
            margin: "0",
            lineHeight: "28px",
            font: "bold 26px lato",
            letterSpacing: "0.24px",
            [theme.breakpoints.down(502)]: {
              font: "bold 22px lato",
              lineHeight: "24px",
              letterSpacing: "0.21px",
            },
        },
        "& .SubTitulo2": {
            color: "#823CE2",
            margin: "0",
            font: "bold 22px lato",
            lineHeight: "24px",
            letterSpacing: "0.21px",
            [theme.breakpoints.down(502)]: {
              font: "bold 18px lato",
              lineHeight: "20px",
              letterSpacing: "0.16px",
            },
          },
          '& .TextImg':{
            color:'#717476',
            font:' 16px lato',
            lineHeight :'25px',
            letterSpacing: '0.15px',
            margin:'20px 0 0 0',
            [theme.breakpoints.down(502)]:{
                font:' 11px lato',
                lineHeight :'14px',
                letterSpacing: '0.10px',
            },
        },
        '& .Imagen':{
            width:'100%',
        }, 
      
    }
}));

const El_viaje_de_Laika = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >
                        El viaje de Laika                    
                    </h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                      <p className="Lectura">
                        <h3 className='SubTitulo'>El primer ser vivo en orbitar la Tierra</h3><br/>
                        
                        El 3 de noviembre de 1957, la Unión Soviética envió su segundo navegador espacial a la 
                        órbita de la Tierra. Su única tripulante fue una perrita llamada Laika. Ella fue la 
                        precursora de los viajes espaciales. <br/><br/>
                        
                        Laika despegó a bordo del Sputnik 2 a las 05:30 a.m. de ese día. Se mantuvo con vida 
                        durante unas cuantas horas después de su lanzamiento al espacio, y alcanzó a darle
                        nueve vueltas al planeta. De este modo, se convirtió en el primer terrestre vivo en 
                        visitar el espacio, aunque murió a bordo del navegador. <br/><br/>
                        
                        ¿Sabías que el nombre original de Laika era <em>Kudryavka</em>? En ruso significa “pequeña de 
                        pelo rizado”.
                      </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <Grid container justifyContent="center" >
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img2do/Sesion45/1.png" alt="Tarjeta" />
                        </Grid>
                        <Grid container justifyContent="center" >
                            <p className='TextImg'>
                            Éste es un homenaje a Laika. Es importante recordar que era un ser vivo.
                            </p>                        
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                      <p className="Lectura">
                        Los hechos ocurrieron poco tiempo después de que el 4 de octubre se lanzara el 
                        primer cohete espacial: el Sputnik. Dicha nave llegó con éxito a su destino y funcionó 
                        como un satélite terrestre, es decir que orbitó alrededor del planeta durante tres 
                        meses. <br/><br/>
                        
                        <h3 className='SubTitulo2'>Perros cosmonautas</h3><br/> 
                        Laika no fue la primera canina en abordar una nave espacial. Algunos perros ya 
                        habían hecho pruebas de vuelo antes que ella, pero ninguno salió de la Tierra. Su 
                        entrenamiento consistía en encerrarlos en jaulas cada vez más pequeñas, para que se 
                        acostumbraran al espacio limitado de los transbordadores. También recibían comida 
                        especial en forma de gelatina, para cuando estuvieran solos arriba. <br/><br/>
                        
                        Se decidió que una hembra volara al espacio debido a que ellas no levantan la pata 
                        para orinar. Y Laika resultó seleccionada porque cumplió satisfactoriamente con su 
                        entrenamiento… aunque también influyó que era <em>fotogénica</em> (esto significa que salía 
                        bien en las fotografías). <br/><br/>
                        
                        Gracias a su sacrificio, los soviéticos pudieron observar qué condiciones debían 
                        mejorar para enviar humanos a explorar el universo. Con base en ello, desarrollaron 
                        tecnologías que les permitieron cumplir su cometido sin poner a la tripulación en 
                        peligro.<br/><br/>
                        
                        A pesar de que fue un gran logro para la humanidad, ahora sabemos que no es ético 
                        usar a los animales con fines científicos. <br/><br/>
                        
                        ¿Qué opinas de esta noticia? ¿Ya habías escuchado hablar sobre Laika?
                      </p>
                    </Grid>
                </Grid>
        </Grid>
    </Grid>
    );
}
 
export default El_viaje_de_Laika ;
