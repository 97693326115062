import React from "react";
import { Button, makeStyles, Grid } from "@material-ui/core";
import { Link } from "react-router-dom";
import Maquina from "../../imgs/MaquinaCable.png";

import TermsAndConditions from "../TermsAndConditions/TermsAndConditions";

const useStyles = makeStyles((theme) => ({
  ContainerWelcomePage: {
    height: "100%",
  },
  ContainerImgTxt: {
    position: "relative",
    height: "100%",
    width: "100%",
    overflow: "auto",
    maxHeight: "100vh",
    [theme.breakpoints.down(1280)]: {
      width: "100vw",
      minWidth: "320px",
    },
  },
  Bienvenida: {
    padding: "84px 0 0 250px",
    flexWrap: "nowrap",
    flexDirection: "column",
    overflow: "auto",
    height: "auto",
    gap: "20px",
    alignContent: "flex-start",
    [theme.breakpoints.up("lg")]: {
      padding: "84px 0 0 140px",
      // height: "993px",
      flexWrap: "wrap",
    },
    background:
      "linear-gradient(32deg, rgba(185,101,211,0.2105042700674019) 1%, rgba(255,255,255,1) 20%, rgba(255,255,255,1) 36%, rgba(254,254,254,1) 77%, rgba(252,227,80,0.2777311608237045) 100%)",
    [theme.breakpoints.down(1571)]: {
      padding: "84px 0 50px 140px",
    },
    [theme.breakpoints.down(1441)]: {
      padding: "84px 0 50px 120px",
    },
    [theme.breakpoints.down(1401)]: {
      padding: "84px 0 50px 100px",
    },
    [theme.breakpoints.down(1350)]: {
      padding: "84px 0 50px 80px",
    },
    [theme.breakpoints.down(1280)]: {
      padding: "43px 0 0 0",
      alignItems: "center",
      gap: "15px",
      // height: "1080px",
    },
  },

  Titulo: {
    // height: " 111px",
    justifyContent: "center",
    width: "100%",
    [theme.breakpoints.up("lg")]: {
      justifyContent: "flex-start",
      // gap: "15px",
      // justifyContent: "unset",
      width: "auto",
    },
    "& .Titulo1": {
      font: "36px Fredoka One",
      color: "#F2624F",
      // margin: "0 12px 0 0 ",
      margin: "0",
      [theme.breakpoints.up(768)]: {
        font: "40px Fredoka One",
      },
    },
    "& .Titulo2": {
      font: "36px Fredoka One",
      color: "#DC5EA6",
      margin: "0",
      [theme.breakpoints.up(768)]: {
        font: "40px Fredoka One",
      },
    },
    "& .Title": {
      margin: "0",
      justifyContent: "center",
    },
    "& .escritorio": {
      [theme.breakpoints.down(768)]: {
        display: "none",
      },
    },
    "& .tablet": {
      font: "36px Fredoka One",
      [theme.breakpoints.up(768)]: {
        display: "none",
        width: "100%",
      },
    },
    "& .Pregunta": {
      position: "relative",
      bottom: "10px",
    },
  },

  Texto: {
    width: "670px",
    minHeight: "92vh",

    // height: " 620px",
    [theme.breakpoints.down(1024)]: {
      justifyContent: "center",
      width: "100%",
      height: "auto",
      minHeight: "90vh",
      padding: "20px",
    },
    [theme.breakpoints.up("xl")]: {
      minHeight: "90vh",
    },

    "& .Texto1": {
      font: "22px lato",
      color: "#505759",
      margin: "0px",
      [theme.breakpoints.down(768)]: {
        // display: "none",
        paddingBottom: "20vh",
      },
    },
    "& .Texto2": {
      font: "18px lato",
      color: "#505759",
      margin: "0px",
      padding: "20px",
      paddingBottom: "20vh",
      hyphens: "manual",
      [theme.breakpoints.up(768)]: {
        display: "none",
      },
    },
  },

  BotomBar: {
    position: "fixed",
    bottom: "0px",
    background: "#F5D568",
    height: "8vh",
    display: "flex",
    justifyContent: "center",
  },
  BarraDerecha: {
    background:
      "transparent linear-gradient(180deg, #D3348F 0%, #F6644B 100%) 0% 0% no-repeat padding-box",
    width: "3%",
    height: "100vh",
    [theme.breakpoints.down(1280)]: {
      display: "none",
    },
  },
  Img: {
    position: "absolute",
    // left: "600px",
    right: "50px",
    top: "0px",
    height: "auto",
    "@media(min-height:960px)": {
      // height: "914px",
    },
    [theme.breakpoints.down(1571)]: {
      // left: "473px",
      top: "0px",
    },
    [theme.breakpoints.down(1441)]: {
      // left: "453px",
      top: "0px",
    },
    [theme.breakpoints.down(1401)]: {
      // left: "403px",
      top: "0px",
    },
    [theme.breakpoints.down(1350)]: {
      // left: "323px",
      top: "0px",
    },
    [theme.breakpoints.down(1280)]: {
      display: "none",
    },
    "& .Maquina": {
      height: "582px",
      width: "685.5px",
      "@media(min-height:960px)": {
        height: "776px",
        width: "914px",
      },
    },
  },
  link: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    right: "10%",
    bottom: "50%",
    height: "100%",
    width: "auto",
    position: "absolute",
    textDecoration: "none",
    [theme.breakpoints.down("md")]: {
      transform: "translate(50%)",
      right: "50%",
    },
    "& .BotonContinuar": {
      textTransform: "none",
      height: "8vh",
      maxHeight: "62px",
      width: "50vw",
      maxWidth: "238px",
      background: "#5825AD",
      boxShadow: "0px 3px 6px #00000029",
      borderRadius: "61px",
      color: "#FFFFFF",
      font: "bold 20px lato ",
      "&:hover": {
        background: "#dd78fb",
      },
    },
  },
}));

const Welcome_Page = () => {
  const classes = useStyles();

  return (
    <Grid
      container
      item
      sm={12}
      direction="column"
      className={classes.ContainerWelcomePage}
    >
      <Grid
        container
        item
        sm={12}
        direction="row"
        justifyContent="center"
        className={classes.ContainerImgTxt}
      >
        <Grid container item direction="column" className={classes.Bienvenida}>
          <Grid container item className={classes.Titulo}>
            <h1 className="Titulo1">¡Hola!, </h1>
            <h2 className="Titulo2">&nbsp;¿cómo estás?</h2>
          </Grid>
          <Grid container item className={classes.Texto}>
            <p className="Texto1">
              Te damos la bienvenida a <b>Circuito de lectores,</b> una
              propuesta innovadora en donde los estudiantes desarrollarán
              habilidades de comprensión lectora de una manera muy atractiva y
              llena de retos.
              <br></br>
              <br></br>A través de lecturas originales, aprenderán sobre
              diferentes temas que les serán de utilidad en el ámbito escolar,
              en su vida cotidiana y en el futuro. Los contenidos abarcan desde
              literatura, pensamiento matemático, ciencias humanas, ciencias
              naturales, arte y ética. Además, la plataforma cuenta con
              herramientas socioemocionales que les permitirán desarrollar otras
              habilidades fundamentales: el autoconocimiento, la autorregulación
              y la empatía.
              <br></br>
              <br></br>
              Te invitamos a explorar la plataforma para que te familiarices con
              su estructura y rutas de acceso. Además puedes apoyarte en el
              Manual de usuario para conocer las diferentes secciones y cómo
              aprovecharlas al máximo.
              <br></br>
              <br></br>
              ¡Qué disfrutes el recorrido!
            </p>
          </Grid>
          <Grid className={classes.Img}>
            <img className="Maquina" src={Maquina} alt="" />
          </Grid>
        </Grid>
      </Grid>
      <Grid container item sm={12} className={classes.BotomBar}>
        <Link className={classes.link} to="/admin-inst/perfil">
          <Button className="BotonContinuar">Continuar</Button>
        </Link>
      </Grid>
      <Grid container item sm={12} className={classes.BarraDerecha}></Grid>
      <TermsAndConditions />
    </Grid>
  );
};

export default Welcome_Page;
