import { Button, Grid, makeStyles } from "@material-ui/core";
import React, { useContext, useEffect } from "react";
import DrawerContext from "../../../../../usability/DrawerContext";
import { useOnLoadImages } from "../../../../hooks/useOnLoadImages";
import Loader from "../../../../Loader/Loader";
import Drawer from "../../ReadingTools/Drawer";

const useStyles =  makeStyles (theme =>({
     Contenedor:{
        background:'#E9FBF9',
        width:'100vw',
        overflowX:'clip',
        [theme.breakpoints.down(1161)]:{
            background:'none',
           overflowX:'unset',
        },
        [theme.breakpoints.down(600)]:{
            marginTop: '79.51px',
        }
    },
  ContainerInfoLectura: {
    position: "relative",
    height: "86px",
    [theme.breakpoints.down(502)]:{
        height: "43px",
    },
    background: "#76D4C3",
    "& .BarraInfoLectura": {
      height: "86px",
      background: `Url(https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Barras/Azul.png)`,
        [theme.breakpoints.down(502)]:{
            height: "43px",
        },
    },
    "& .InfoLectura": {
      position: "absolute",
      height: "86px",
      width: "219px",
      boxSizing: "border-box",
      padding: "17.2px 0",
      background: "#FAFAFA",
      borderRadius: "50px",
      boxShadow: "2px 2px 2px 2px #00000029",
      zIndex: "4",
        [theme.breakpoints.down(502)]:{
            height: "43px",
            width: "109px",
            padding: "8.6px 0",
            borderRadius: "25px",
        },
    },
    "& .InfoLectura:before": {
      position: "absolute",
      display: "flex",
      boxSizing: "border-box",
      padding: "0 0 0 10px",
      justifyContent: "center",
      alignItems: "center",
      height: "39.88px",
      width: "39.88px",
      borderRadius: "50%",
      content: '""',
      boxShadow: "3px 0px .5px 0px #00000029",
      background: "#FAFAFA",
      left: "90px",
      top: "65px",
      transform: "rotate(0.25turn)",
      [theme.breakpoints.down(502)]:{
        left: "45px",
        top: "30px",
        height: "20.88px",
        width: "20.88px",
        },
    },
    "& .InfoLectura:after": {
      content: '""',
      height: "15px",
      width: "15px",
      borderTop: "2px solid #20A698",
      borderLeft: "2px solid #20A698",
      position: "absolute",
      bottom: "-10px",
      left: "Calc(50% - 8px)",
      transform: "rotate(0.625turn)",
      [theme.breakpoints.down(502)]:{
        height: "10px",
        width: "10px",
        left: "Calc(50% - 4.5px)",
        bottom: "-3px",
        borderTop: "1px solid #20A698",
        borderLeft: "1px solid #20A698",
        },
    },
    "& .Sesion": {
      borderLeft: "2px solid #D8D8D8",
    },
    "& .Dato": {
      margin: "0 0 5px 0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#20A698",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        margin: "0 0 2.5px 0",
        },
    },
    "& .Caracter": {
      margin: "0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#000000",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        },
    },
       '& .Regresar':{
        color: '#F2624F',
        border: '#F2624F 1px solid',
        height: '30px',
        width:'100px',
        borderRadius: '20px',
        letterSpacing: '0.14px',
        textTransform: 'none',
        background:'#FEFAF1',
        top:'calc(50% - 15px)',
        left:'5%',
        zIndex:4,
        [theme.breakpoints.down(502)]: {
          font:'bold 10px lato',
          width: "60px",
        },
      },
  },
    ContenedorLectura:{
        background:'#fff',
        width:'1160px',
        height:'100%',
        padding:'60px 168px 35px ',
        
        transform: (props) =>
        props.scale > 1 ? `scale(${props.scale}) translate(0px, 10%)` : `none`,
        [theme.breakpoints.down(1161)]:{
            width:'502px',
            padding:'60px 0 0 0',
          },
                [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },

        '& .Escritorio':{
            display:'block',
            [theme.breakpoints.down(1161)]:{
                display:'none'
            },
        },
        '& .Tablet':{
            display:'none',
            [theme.breakpoints.down(1161)]:{
                display:'block'
            },
        }
    },
    ContainerTitulo:{
        margin:'0 0 34px 0',
        [theme.breakpoints.down(1161)]:{
            margin:'0 0 34px 0',
        },
        '& .Titulo':{
            letterSpacing: '0px',
            color: '#F6664D',
            margin:'0',
            textAlign:'center',
            font:'48px fredoka one',
            lineHeight:'58px',
            [theme.breakpoints.down(1161)]:{
                font:'43px fredoka one',
                lineHeight:'52px',
            },
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            margin:'0 0 36px 0',
            color:'#000',
            font:'22px lato',
            lineHeight : '30px',
            letterSpacing: '0.20px',
            [theme.breakpoints.down(1161)]:{
                font:'18px lato',
                lineHeight :'28px',
                letterSpacing: '0.17px',
            },
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        "& .SubTitulo": {
            color: "#823CE2",
            margin: "0",
            font: "bold 30px lato",
            letterSpacing: ".28px",
            lineHeight: "30px",
            [theme.breakpoints.down(1161)]: {
              lineHeight: "28px",
              font: "bold 26px lato",
              letterSpacing: "0.24px",
            },
            [theme.breakpoints.down(502)]: {
              font: "bold 22px lato",
              lineHeight: "24px",
              letterSpacing: "0.21px",
            },
        },
        "& .SubTitulo2": {
            color: "#FC5E9E",
            margin: "0",
            font: "bold 26px lato",
            letterSpacing: ".24px",
            lineHeight: "28px",
            [theme.breakpoints.down(1161)]: {
                font: "bold 22px lato",
                lineHeight: "24px",
                letterSpacing: "0.21px",
            },
            [theme.breakpoints.down(502)]: {
                font: "bold 18px lato",
                lineHeight: "20px",
                letterSpacing: "0.16px",
            },
        },
        "& .SubTitulo3": {
            color: "#823CE2",
            margin: "0",
            font: "bold 24px lato",
            letterSpacing: ".23px",
            lineHeight: "30px",
            [theme.breakpoints.down(1161)]: {
                font: "bold 20px lato",
                lineHeight: "28px",
                letterSpacing: "0.19px",
            },
            [theme.breakpoints.down(502)]: {
                font: "bold 16px lato",
                lineHeight: "18px",
                letterSpacing: "0.15px",
            },
        },
        '& .Cuadro':{
            position:'relative',
        },
        '& .Esp':{
            position:'absolute',
            margin:'53px 0 0 0',
            width:'750px',
            [theme.breakpoints.down(1161)]: {
                width:'85.25%',
            },
            [theme.breakpoints.down(502)]: {
                margin:'18px 0 0 0',
            },
        },

        '& .Imagen':{
            [theme.breakpoints.down(1161)]:{
                width:'100%',
            },
        }, 
    }
}));

const Go_el_juego_de_mesa_hino = (props) => {

  const {activeStep, setActiveStep, handlePreviewStep, id_actividad} = props

  const handleContinue = () => {
    setActiveStep(activeStep - 1)
    handlePreviewStep()
  }

    const {
      handleSize,
    disabledZoom,
    closeZoom,
    handleZoom,
    scale
  }= useContext(DrawerContext);
  const [loading, setLoading] = React.useState(true);
  const wrapperRef = React.useRef(null);
  const imagesLoaded = useOnLoadImages(wrapperRef);

  const detectSize = () => {
    const lectura = document.getElementById("lectura");
    const newWidth = lectura?.offsetWidth;
    const newHeight = lectura?.offsetHeight;
    setLoading(false);
    if (lectura) handleSize(newHeight, newWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", detectSize);
    return () => {
      handleZoom()
      window.removeEventListener("resize", detectSize);
      closeZoom()
    };
  }, []);

  const classes = useStyles({ disabledZoom,scale });
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
             <Grid container item justifyContent="center" className={classes.ContainerInfoLectura}>
                <Grid container className="BarraInfoLectura">
                    <Button className="Regresar" onClick={handleContinue}>
                        Regresar
                    </Button>
                </Grid>
               <Grid container item className="InfoLectura">
                    <Grid container item direction="column" xs={6} justifyContent="center">
                        <p className="Dato">Grado</p>
                        <p className="Caracter">3</p>
                    </Grid>
                    <Grid container item direction="column" xs={6} justifyContent="center" className="Sesion">
                        <p className="Dato">Sesión</p>
                        <p className="Caracter">48</p>
                    </Grid>
               </Grid>
            </Grid>
            {!imagesLoaded && <Loader />}
        <Grid
            container
            item
            className={classes.ContenedorLectura}
            justifyContent="center"
            id="lectura"
            onLoad={detectSize}
            ref={wrapperRef}
        >
<Drawer id_lectura={id_actividad} />
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >
                    Go: el juego <br className='Tablet'/>de mesa chino                    
                    </h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                      <p className="Lectura">
                        ¿Alguna vez te has preguntado a qué jugaban las personas antes de que existieran las 
                        consolas y el internet? ¿Con qué materiales estaban hechos sus pasatiempos? Las 
                        culturas antiguas inventaron los juegos de mesa y fabricaban sus piezas con piedras,
                        palos de madera, huesos, caparazones de tortuga y dientes de elefante. Después la 
                        humanidad evolucionó y las herramientas también, así que comenzaron a utilizar 
                        cobre, cristal, marfil e incluso mármol.<br/><br/> 
                        
                        Cada civilización inventó su propia diversión, con reglas y número de participantes 
                        específicos. Algunos juegos requerían habilidad manual, otros, destreza mental y 
                        unos más se basaban en el azar. Quizá el ajedrez sea el más famoso del segundo 
                        grupo. Fue conocido en Europa en el siglo <span style={{fontVariant: "all-small-caps"}}>XV</span>, hace cientos de años. Pero existe otro 
                        juego más antiguo y más difícil que es llamado <em>go</em>.<br/><br/> 
                        
                        <h3 className='SubTitulo'>La leyenda del origen</h3><br/>
                        
                        El go es denomidado de distintas maneras en Asia. En China lo nombran <em>wéiqí</em>. En 
                        Corea, <em>baduk</em>. Y en Japón, <em>igo</em>. Surgió en el año 550 a. n. e., pero se piensa que podría 
                        tener más de 4 000 años de antigüedad. Según una leyenda, fue diseñado a petición 
                        del emperador chino Yao, quien buscaba enseñar a su hijo cómo gobernar a través de 
                        una demostración. Pronto fue considerado una de las cuatro artes que todos los 
                        miembros de la aristocracia china debían estudiar. Las otras tres eran la 
                        caligrafía, la música y la pintura.
                      </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img3ro/Sesion48/1.png" alt="Tarjeta" />
                    </Grid>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                            <h3 className='SubTitulo2'>Las reglas</h3><br/>
                            
                            Las normas son sencillas. Se colocan piedras sobre el tablero, una por cada turno. Un 
                            concursante usa blancas y el otro, negras. El objetivo es <em>conquistar</em> territorios 
                            rodeándolos con las piedras propias. Gana quien obtenga un territorio mayor al de su 
                            oponente.<br/><br/>
                            
                            Esto puede parecer fácil, pero el desarrollo se complica por la variedad de opciones 
                            que hay en cada tirada. Es casi imposible que una partida termine igual a otra, pues 
                            las piedras se acomodan siempre de diferente manera. Ahí radica la diferencia con el 
                            ajedrez, donde algunas estrategias ya están determinadas. En él hay movimientos y 
                            planes específicos que pueden predecirse si somos hábiles. En el go eso no ocurre, 
                            aunque lo jueguen una persona experta y una principiante.
                        </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img3ro/Sesion48/2.png" alt="Tarjeta" />
                    </Grid>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                            <h3 className='SubTitulo3'>Diversión para todas las edades</h3><br/>
                            
                            Algunos estudios han mostrado que jugar go activa nuestro cerebro y previene 
                            enfermedades neurodegenerativas, como el Alzheimer. Hay aplicaciones digitales 
                            gratuitas para teléfonos inteligentes en las que puedes practicarlo e incluso competir 
                            con personas de otro país. Se agrupan por niveles, así que no importa si nunca has 
                            jugado. ¡Anímate a intentarlo!
                        </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img3ro/Sesion48/3.png" alt="Tarjeta" />
                    </Grid>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container item justifyContent='center' sm={12} className="Cuadro">
                        <img className="Imagen Escritorio" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img3ro/Sesion48/4.png" alt="Tarjeta" />
                        <img className="Imagen Tablet" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img3ro/Sesion48/4R.png" alt="Tarjeta" />
                        <p className='Lectura Esp'>
                            Su popularidad ha aumentado alrededor del mundo y se llevan a cabo dos 
                            torneos internacionales en los que participan jugadores ¡de más de 70 
                            naciones!
                        </p>
                    </Grid>
                </Grid>
        </Grid>
        <Grid container item style={{ marginTop: 80}} ></Grid>
    </Grid>
    );
}
 
export default Go_el_juego_de_mesa_hino ;
