import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
    ContenedorLectura:{
        width:'502px',
                  [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
    },
    ContainerTitulo:{
        margin:'0 0 34px 0',
        '& .Titulo':{
            letterSpacing: '0px',
            color: '#007CB2',
            margin:'0',
            textAlign:'center',
            font:'43px fredoka one',
            lineHeight:'52px',
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        position:'relative',
        '& .Lectura':{
            margin:'0 0 36px 0',
            color:'#000',
            font:'18px lato',
            lineHeight :'28px',
            letterSpacing: '0.17px',
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .Esp':{
            margin:'0',
        },
        '& .Esp2':{
            boxSizing:'border-box',
            padding:'0 17% 0 0',
        },
        '& .Esp3':{
            boxSizing:'border-box',
            padding:'0 74px 0 0',
        },
        
        '& .Imagen':{
            width:'100%',
        }, 
        '& .Imagen2':{
            position:'absolute',
            width:'270px',
            top: '0px',
            right: '-26px',
            [theme.breakpoints.down(502)]:{
                width:'200px',
            },
        },
  
    }
}));


const La_ñ_del_español = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>

            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >La <em>ñ</em> del español</h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container sm={12}>
                      <p className="Lectura "> 
                        Mi tío nos visita a mis hermanos y a mí cada año. Al llegar, él sacude nuestro cabello 
                        con su mano. A mi hermano Lorenzo apenas le da unas palmaditas en el pelo, pero al 
                        pequeño Rubén le sonríe y dice: “Este muchacho salió más blanquito”.
                      </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img4to/Sesion19/1.png" alt="Tarjeta" />
                    </Grid>
                </Grid>
               
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container sm={12}>
                      <p className="Lectura Esp"> 
                        Él es cariñoso, pero al mismo tiempo es… no sé cómo. A veces me mira fijamente y 
                        exclama:<br/><br/>
                        
                        —Sí que es negro tu pelo, ¿eh? <br/><br/>
                        
                        Cuando está con mi papá, se acuerda de sus abuelos, que eran españoles de piel 
                        blanca, y se siente orgulloso. <br/><br/>
                        
                        —Te pudieron haber salido más claritos los niños —señala de repente. <br/><br/>
                        
                        Entonces, mi papá hace una mueca y cambia de tema. Es cuando yo comienzo a 
                        preocuparme de lucir color café. <br/><br/>
                        
                        —Mamá, ¿mi piel es de un tono muy oscuro? <br/><br/>
                        
                        —Sí, hijo, como el café negro. <br/><br/>
                        
                        —¡Noooo! <br/><br/>
                        
                        —Bueno, como la canela. <br/><br/>
                        
                        Respondo con un gesto. <br/><br/>
                        
                       —¿El chocolate? ¿La tierra? ¿La montaña? <br/>—pregunta ella tratando de hacerme reír,
                        pero yo solo le contesto: <br/><br/>
                        
                        —¿No pude haber sido más clarito? <br/><br/>
                        
                        —¿Como la luna, el queso o el papel? ¡¿Como un fantasma?! <br/><br/>
                        
                        —No, como mi papá. Porque mi papá y mi tío son blancos. <br/><br/>
                        
                        —Y yo, negrita, hijo. Así que eres café con leche. <br/><br/>
                        
                        —Si me hubieras echado más leche, sería menos oscuro.<span className='Tablet'><br/></span>

                        —Pero así negro estás guapo. Estás fuerte y 
                        tienes carácter, como el café y el chocolate.<br/><br/>

                        Mi tío chasquea los labios cuando escucha que conversamos en 
                        <em>tu’un savi</em> y nos reclama por hablar en “ese dialecto”. Asegura 
                        que no sirve para nada y que nos impide aprender bien 
                        el español.Asegura que no sirve para nada y que nos 
                        impide aprender bien el español.<br/><br/>

                      </p>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} >
                    <Grid container xs={7} >
                      <p className="Lectura Esp2"> 
   
                         Yo siento feo porque no es un dialecto, es 
                         el idioma en el que me comunico con mi 
                         mamá. Ella respinga la nariz un poco 
                         cuando le platico lo que él comenta. Pero 
                         luego sonríe y me pide que lo ignore. <br/> <br/>

                        —Yo soy morena y mis palabras están en la lengua de los <em>ñuu savi</em>, el pueblo de la 
                        lluvia. El día que pienses que eso es malo, yo no existiré para ti.

                      </p>
                    </Grid>
                    <img className="Imagen2" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img4to/Sesion19/2.png" alt="Tarjeta" />
                    <Grid container xs={12} >
                      <p className="Lectura "> 
                        Una tarde, mi tío comenzó a platicar de su viaje por Europa. Contó que en Francia 
                        tuvo problemas para mandar correos porque los teclados de las computadoras no 
                        tenían ñ. Asombrado, afirmó que nunca había notado que la ñ fuera una letra tan 
                        española. Yo me acordé de la ñ, de su sonido, de mis palabras y de las palabras de 
                        lluvia de mamá, y le dije: <br/><br/>
                        
                        —También en el <em>tu’un sav</em>i existe. Escucha, nosotros somos <em>ñuu savi…</em> —pero no me 
                        escuchó y siguió su historia. <br/><br/>
                        
                        —También en el <em>tu’un savi</em> existe. Escucha, nosotros somos <em>ñññññuu savi</em> —repetí, 
                        alargando el sonido. Él apenas volteó. <br/><br/>
                        
                        Lo dije una vez más. Esta vez respondió: <br/><br/>
                        
                        —Sí, sí. Leí que los estadounidenses hicieron las computadoras y no querían incluir la 
                        letra <em>ñ</em> en ningún teclado del mundo. ¡Qué injusto! Nuestra lengua importa y merece 
                        ser incluida. <br/><br/>
                        
                        Mientras decía eso, me di cuenta de que mi mamá contemplaba mi frustración. Ella 
                        me miró con sus ojos que eran como charquitos que sonreían. Luego se rio un poco, 
                        con ese cuerpo de agua en el que todo se resbala. Y alzó los brazos para hacerme la 
                        seña de <em>ni modo</em>. Entonces, yo sonreí también y volteé a observar a mi tío, que no me 
                        veía.
                      </p>
                    </Grid>
                    
                </Grid>
        </Grid>
    </Grid>
    );
}
 
export default La_ñ_del_español;
