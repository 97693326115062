import React, { useEffect, useContext }    from "react";
import types                        from "../../../../types/types";
import AuthContext                  from "../../../../auth/AuthContext";
import { Button,makeStyles,Grid,Hidden}    from "@material-ui/core";
import { Link, useParams }                     from "react-router-dom";
import Heraldo                      from "../img/HeraldosG3/02_Heraldo_G3_Evaluacion_formativa.png";
import Corazon                      from "../../../imgs/Corazon.png"
import Estrella                     from "../../../imgs/Estrella.png"
import axios                        from "../../../../services/axios";
import ModalPoints                  from '../ModalPoints';
import ShowingBadge                 from '../ShowingBadge';
import badge1                       from '../../badges/images/tercero/CL_G3_insignia_evaluacion_formativa_2.png';

const useStyles = makeStyles(theme =>({
  evaluationsContainer: {
    height:'100%',
    
  },
  ContainerIzquierdo:{
    position:'relative',
    background:'radial-gradient(circle, rgba(255,230,35,0.21246505438112742) 11%, rgba(45,218,201,0.20210090872286413) 53%)',
    height:'100%',
    maxWidth:'97%',
    [theme.breakpoints.down(1024)]:{
      maxWidth:'100%',
    },
  },
  ContainerEvaluacion:{
    position:'relative',
    width:'100vw',
    minHeight:'calc(100vh - 79.5px - 79.5px)',
    overflow: 'auto',
  },
  ContainerBarraInferior:{
    background:'#2DDAC9',
    height:'79.5px',
    [theme.breakpoints.down(850)]:{
      background: 'linear-gradient(90deg, #8144DF 0%, #2EDAC9 100%)',
    },
  },
  ContainerBarraDerecha:{
    background: 'linear-gradient(0deg, #8144DF 0%, #2EDAC9 100%)',
    width:'3%',
    height:'100vh',
    [theme.breakpoints.down(1024)]:{
      display:'none',
    },
  },
  ContainerTextoTitulo:{
    width: '466px',
    [theme.breakpoints.down(600)]:{
      width: '415px',
      marginTop: '90px'
    },
    '& .Titulo':{
      textAlign:'center',
      font:'45px Fredoka One',
      color:'#996DE3',
      margin:'0',
      [theme.breakpoints.down(1501)]:{
        font:'40px Fredoka One',
      },
      [theme.breakpoints.down(1001)]:{
        font:'30px Fredoka One',
      },
      [theme.breakpoints.down(600)]:{
        font:'24px Fredoka One',
      },
    },
  },
  ContainerTextoResultadoEst:{
    width: '520px',
    minHeight: '101px',
    [theme.breakpoints.down(600)]:{
      width: '315px',
    },
  },
  textoRetro: {
    font:'22px lato',
    color:'#4B4A4E',
    letterSpacing: '0.21px',
    margin:'0',
    textAlign:'center',
    [theme.breakpoints.down(1440)]:{
      font:'20px lato',
    },
    [theme.breakpoints.down(960)]:{
      font:'16px lato',
    },
  },
  ContainerTextoResultadoCor:{
    // position:'absolute',
    // top:'275px',
    // right:'50px',
    width: '535px',
    minHeight: '101px',
    [theme.breakpoints.down(600)]:{
      width: '315px',
    },
  },
  ContainerEstrella:{
    '& .Divisor':{
      width: '39px',
      height: '0px',
      border: '4px solid #F1CB5A',
      borderRadius:'25px',
      [theme.breakpoints.down(769)]:{
        width:'29px',
      },
    },
    '& .Estrella':{
      height:'60px',
      margin:'0 19.5px',
      [theme.breakpoints.down(769)]:{
        height:'40px',
      },
    },
  },
  ContainerResultado:{
    position:'relative',
    width: '283px',
    height: '153px',
    marginBottom: '40px',
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    boxShadow: '0px 2px 5px #00000029',
    border:' 3px solid #FBE6B9',
    borderRadius: '20px',
    [theme.breakpoints.down(769)]:{
      width: '233px',
      height: '103px',
      marginBottom: '20px', 
    },
    
    '& .TextoPuntaje':{
      position:'absolute',
      top:'18.75px',
      textAlign:'center',
      height:'30px',
      font:'25px lato',
      color:'#1DA698',
      margin:'0',
      [theme.breakpoints.down(769)]:{
        top:'8.75px',
        font:'20px lato',
      },
    },
    '& .Divisor':{
      position:'absolute',
      top:'61.36px',
      width: '179px',
      height: '0px',
      border: '3px solid #FBE6B9',
      [theme.breakpoints.down(769)]:{
        top:'41.36px',
        width: '129px',
      },
    },
    '& .ResultadoPuntaje':{
      position:'absolute',
      top:'73.24px',
      textAlign: 'center',
      font: '900 45px Lato',
      letterSpacing: '0px',
      color: '#DE7495',
      margin:'0',
      [theme.breakpoints.down(769)]:{
        font: '900 35px Lato',
        top:'53.24px',
      },
    },
  },
  
  ContainerHeraldo:{
    width:'267px',
    height:'300px',
    marginBottom: "20px",
    [theme.breakpoints.down(960)]:{
      display: 'none'
    },
    '& .Heraldo':{
      height:'100%',
    },    
  },
  HiddenContainerTextoResultadoCor: {
    height:'250px',
    marginBottom: "20px",
    [theme.breakpoints.down(769)]:{
      height:'210px',
    },
    '& .Heraldo':{
      height:'100%',
    },    
  },
  link:{
    textDecoration:'none',
    marginBottom: '20px',
  },
  BotonContinuar:{
    textTransform: 'none',
    height:'58px',
    width:'261px',  
    background: '#7A54BA',
    boxShadow: '0px 3px 6px #00000029',
    borderRadius: '61px',
    color: '#FFFFFF',
    font: 'bold 20px lato ',
    '&:hover':{
        background: '#dd78fb',
      },
    [theme.breakpoints.down(769)]:{
      font: 'bold 16px lato ',
      height:'48px',
      width:'221px',  
    },
  },
}));



const FinalResultadoEvaluacion = () => {
  const classes = useStyles()
  const [finalText, setFinalText] = React.useState()
  const {actividad, intento,} = useParams()
  const [finalScore, setFinalScore] = React.useState(0)
  const [openOnce, setOpenOnce] = React.useState(false);
  const [openBadge, setOpenBadge] = React.useState(false);
  const [changeButtonType, setChangeButtonType] = React.useState(false);

  const {
    dispatch,
  } = useContext(AuthContext);

  const handleClick = () => {
    setOpenOnce(true)
    setChangeButtonType(true)
  }

  const handleClose = () => {
    setOpenOnce(false);
  };

  const handleCloseBadge = () => {
    setOpenBadge(false);
  };

  useEffect(() =>{
    const myTimeout = setTimeout(() => {setOpenBadge(true)}, 1500) 
  }, [])
   
    useEffect(() =>{
      const body = {id_actividad: 3, intento: 1}
      axios.get( `/user/actividad/resume/get?id_actividad=${actividad}&intento=${intento}`, body)
      .then(response => {
        setFinalText(response.data.resultado)
        setFinalScore(response.data.resultado.puntos_total)
        if(intento === 1){
          dispatch({
            type: types.insignias,
            payload: {
              insignia: "evaluacion",
            },
          }); 
        }
      })
    }, [])

 
  return (
    <Grid 
      container
      direction="column"
      item xs={12}
      className={classes.evaluationsContainer}
    >
      <Grid container justifyContent="center" item sm={12} className={classes.ContainerIzquierdo}>
        <Grid container item  justifyContent="center" alignItems="center" className={classes.ContainerEvaluacion}>
          <Grid item xs={12} justifyContent="center" className={classes.ContainerTextoTitulo}>
            <p className="Titulo">
              ¡Has llegado al final <br/>
              de esta sesión!
            </p>
          </Grid> 

          <Grid container item direction="row" justifyContent="center" alignItems="flex-start">
            <Grid container item md={4}  justifyContent="center" className={classes.ContainerTextoResultadoEst}>
              <Grid container item justifyContent="center" alignItems="center" className={classes.ContainerEstrella}>
                <div className="Divisor"></div>
                <img className="Estrella" src={Estrella} alt="" />
                <div className="Divisor"></div>
              </Grid>
              <p 
              // dangerouslySetInnerHTML={{__html: `${finalText && finalText.texto_retro_comprension_lectora}`}}
              className={classes.textoRetro}
            >
              Recuerda que identificar los conectores de las oraciones te hará comprender rápidamente cuál es la idea 
              principal o de qué manera están relacionadas las ideas formuladas en oraciones.
            </p>
            </Grid>

            <Grid container item md={3} justifyContent="center" className={classes.ContainerHeraldo} > 
              <img className="Heraldo" src={Heraldo} alt="" />
            </Grid>

            <Grid container item md={4}  justifyContent="center" className={classes.ContainerTextoResultadoCor}>
              <Grid container item justifyContent="center" alignItems="center" className={classes.ContainerEstrella}>
                <div className="Divisor"></div>
                <img className="Estrella" src={Corazon} alt="" />
                <div className="Divisor"></div>
              </Grid>
              <p 
              // dangerouslySetInnerHTML={{__html: `${finalText && finalText.texto_retro_socioemocional}`}}
              className={classes.textoRetro}
            >
              También considera que la reflexión es una capacidad que evitará que actúes impulsivamente. Recuerda meditar antes 
              de actuar y hablar. Te invitamos a seguir aprendiendo acerca de ti y del mundo que te rodea. ¡Bien hecho!
            </p>
            </Grid>
          </Grid>
          <Hidden only={["md","xl", "lg"]}>
            <Grid container item md={3} justifyContent="center" className={classes.HiddenContainerTextoResultadoCor}> 
              <img className="Heraldo" src={Heraldo} alt="" />
            </Grid>
          </Hidden>
          <Grid container item xs={12} direction='column' justifyContent="center" alignItems="center">
            <Grid container item direction="column" justifyContent="center" alignItems="center" className={classes.ContainerResultado}>
              <p className="TextoPuntaje">Tu puntaje es:</p>
              <div className="Divisor"></div>
              <p className="ResultadoPuntaje">{finalScore}/10</p>
            </Grid> 
            {
            changeButtonType ?
              <Link className={classes.link} to='/mapa' >
                <Button onClick={handleClick} className={classes.BotonContinuar}>
                  Continúa la aventura
                </Button>
              </Link>
            :
              <Grid className={classes.link}>
                <Button onClick={handleClick} className={classes.BotonContinuar}>
                  Continúa la aventura
                </Button>
              </Grid>
            }
          </Grid>   
        </Grid>
        <Grid container direction="row-reverse" item sm={12} className={classes.ContainerBarraInferior}></Grid>
      </Grid>

      <Grid container item sm={12} className={classes.ContainerBarraDerecha}></Grid>
      <ModalPoints open={openOnce} setOpenOnce={setOpenOnce} handleClose={handleClose}/>
      <ShowingBadge open={openBadge} setOpenOnce={setOpenBadge} handleClose={handleCloseBadge} badge={badge1} badgeType={"Formativa 2"}/>
    </Grid>
    
  );
}

export default FinalResultadoEvaluacion;
