import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({

    ContenedorLectura:{
        width:'502px',
        [theme.breakpoints.down(502)]:{
            width:'350px',
        },
    },

    ContainerTitulo:{
        margin:'0 0 38px 0',
        '& .Titulo':{
            letterSpacing: '0px',
            color: '#FC5E9E',
            margin:'0',
            font:'43px fredoka one',
            lineHeight:'52px',
            textAlign:'center',
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            margin:'0 0 36px 0',
            color:'#000',
            font:'18px lato',
            letterSpacing:'.17px',
            lineHeight:'28px',
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },

        '& .TextImg':{
            color:'#717476',
            margin:'24px 0 0 0',
            font:'16px lato',
            letterSpacing:'.15x',
            [theme.breakpoints.down(502)]:{
                font:' 11px lato',
                lineHeight :'14px',
                letterSpacing: '0.10px',
            },
        },

        '& .Imagen':{
            width:'100%',
        },
        '& .Imagen2':{
            width:'48%',
        },



    },
  
    

}));

const El_genio_de_las_matemáticas_modernas = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >
                        El genio de las <br/>matemáticas modernas
                    </h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura ">
                            ¿Alguna vez te has puesto a contar el número de días festivos que conoces? ¿Cómo 
                            crees que se determinan? Hay de todo tipo. Por ejemplo, en América se celebran las 
                            independencias de los países, aunque en diferentes fechas. Seguro en tu país hay 
                            varias festividades locales que se celebran anualmente. <br/><br/>
                            
                            Hay otro tipo de celebraciones que son más genéricas, como el Día de las Lenguas de 
                            Señas, que se celebra el 23 de septiembre, o el Día del Matemático, el 31 de mayo. Y 
                            es justo sobre esta fecha que vas a aprender. <br/><br/>
                            
                            Pero antes de conocer a la persona por la cual se eligió la fecha, observa las 
                            imágenes.
                        </p>
                    </Grid>
                    <Grid container >
                        <Grid container justifyContent='space-between'>
                            <img className="Imagen2" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion26/1.png" alt="Tarjeta" />
                            <img className="Imagen2" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion26/2.png" alt="Tarjeta" />
                        </Grid>
                        <Grid container justifyContent='center' >
                            <p className='TextImg'> Teselados.</p>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                            Ambas representan teselados que se forman al aplicar a ciertas figuras algunos tipos 
                            de transformaciones, como rotar, reflejar, trasladar y la combinación de las últimas 
                            dos. Todo esto con el objetivo de crear figuras que cubran completa y perfectamente 
                            una superficie plana, es decir, que no queden espacios entre ellas ni que las figuras se 
                            encimen entre sí. <br/><br/>
                            
                            A estas transformaciones que sirven para formar teselados se les llama <em>grupo de 
                            simetrías</em>. Justamente la palabra <em>grupo</em> es la que introduce a Évariste Galois, el 
                            creador de la teoría de grupos, y por quien se designó el 31 de mayo como el Día del 
                            Matemático. <br/><br/>
                            
                            Galois fue un matemático francés que nació el 25 de octubre de 1811. Desde los 14 
                            años inició su interés por las matemáticas. Tal era su pasión que intentó ingresar a 
                            una de las escuelas más prestigiosas de su tiempo y de la actualidad: L’École 
                            polytechnique (Escuela Politécnica). Pero no lo logró. <br/><br/>
                            
                            El rechazo no fue impedimento, por lo que continuó sus estudios en la École normale 
                            supérieure (Escuela Normal Superior). Ese lugar le dio el impulso necesario para que 
                            a los 18 años publicara su primer escrito. <br/><br/>
                            
                            Galois siguió con su preparación a la par que encontraba soluciones a problemas que 
                            ningún otro matemático antes que él había logrado. Así, estaba revolucionando el 
                            estudio y aproximación hacia las matemáticas. Eso lo incitó a contactar a expertos 
                            renombrados para que revisaran su trabajo, pero no lo comprendían, por lo que fue 
                            rechazado nuevamente. <br/><br/>
                            
                            Por desgracia, la vida de Galois no fue fácil debido a sus ideales políticos en una 
                            época muy conflictiva en Francia. Debido a ello pasó una época en la cárcel. Su 
                            situación empeoró cuando recuperó su libertad en 1832, a los 21 años, pues fue 
                            retado a un duelo de pistolas, aunque el motivo aún no es claro. <br/><br/>
                            
                            Al ser una época en la que negarse a ese tipo de enfrentamientos no era una opción, 
                            aceptó, pero estaba seguro de que no ganaría. Así, una noche antes del duelo se 
                            fraguó el hecho que pasaría a los libros de historia. Conocedor de su probable 
                            muerte, escribió un documento en el que plasmó las ideas que sentaron las bases de 
                            la teoría de grupos, que hasta ese momento sus colegas no habían entendido. <br/><br/>
                            
                            Desgraciadamente calculó bien sus probabilidades de fracaso en el duelo: murió el 
                            31 de mayo de 1832 a los 21 años.
                        </p>
                    </Grid>
                    <Grid container >
                        <Grid container justifyContent='space-between'>
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion26/3.png" alt="Tarjeta" />
                            
                        </Grid>
                        <Grid container justifyContent='center' >
                            <p className='TextImg'> Recreación ficticia del duelo de Évariste Galois.</p>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                            Pasaron 14 años de esa tragedia para que su documento fuera publicado, pues 
                            durante ese tiempo se siguió estudiando lo que escribió la noche previa a su muerte. 
                            Con las contribuciones de Galois surgió el álgebra moderna y la teoría de grupos, la 
                            cual actualmente es una de las ramas más complejas de las matemáticas. <br/><br/>
                            
                            Algunas de sus aplicaciones van desde soluciones del cubo Rubik, códigos binarios 
                            (los que se utilizan principalmente en las computadoras que constan sólo de dos 
                            números, 0 y 1), hasta usos en física, astrofísica y química. <br/><br/>
                            
                            ¿Te imaginas hasta dónde pudo haber llegado Galois si no hubiese muerto a los 21 
                            años? En menos de seis años descubrió y desarrolló una de las ramas más complejas 
                            de la matemática moderna. Es por su genialidad y aportaciones a las matemáticas 
                            que se eligió su aniversario luctuoso como el Día del Matemático.
                        </p>
                    </Grid>
                </Grid>
        </Grid>
    </Grid>
    );
}
 
export default El_genio_de_las_matemáticas_modernas;
