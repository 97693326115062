import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
    ContenedorLectura:{
        width:'502px',
        [theme.breakpoints.down(502)]:{
            width:'350px',
        },
    },
    ContainerTitulo:{
        margin:'0 0 38px 0',
        '& .Titulo':{
            letterSpacing: '0px',
            color: '#4F0F04',
            margin:'0',
            font:'43px fredoka one',
            lineHeight:'52px',
            textAlign:'center',
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            margin:'0 0 36px 0 ',
            color:'#000',
            lineHeight :'28px',
            font:'18px lato',
            letterSpacing: '0.16px',
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .Imagen':{
            width:'100%',
        },
    },

}));

const El_secreto_de_la_tierra = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >
                        El secreto de la tierra
                    </h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura ">
                            La noticia se publicó de inmediato en las redes sociales, los periódicos y los 
                            noticieros de radio y televisión: los agricultores de todo el mundo habían 
                            desaparecido. Nadie pudo explicar cómo ocurrió ni por qué. Los campos y las tierras 
                            de cultivo simplemente amanecieron deshabitadas, sin nadie que las cuidara ni las 
                            trabajara. <br/><br/>

                            Al principio, en las ciudades, la gente no entendió de qué forma este suceso podía 
                            afectar su vida, pues no estaba habituada a preguntarse de dónde provenían las 
                            frutas, los cereales y las verduras que comía a diario. Pero, conforme pasaron los 
                            días, el problema se hizo evidente. Primero se acabaron las reservas que cada familia 
                            tenía en su hogar. Después, los mercados y supermercados quedaron vacíos. Y un día 
                            ya no hubo productos agrícolas por ningún lado. ¡Dichoso el que bebió el último 
                            zumo de naranja o comió el último mango! Nadie estaba seguro de cuándo el mundo 
                            volvería a disfrutar los productos del campo.
                        </p>

                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                            <img className='Imagen' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion21R/1.png"  alt="Tarjeta" />
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura ">
                            Los gobiernos designaron a sus mejores investigadores para buscar a los 
                            agricultores. <br/><br/>
                            
                            —No puede desaparecer tanta gente sin dejar rastro —dijo alguien en la reunión de 
                            ministros y todos estuvieron de acuerdo. <br/><br/>
                            
                            A pesar de la lógica de sus palabras, los hechos demostraron que estaba equivocado. 
                            Después de meses de recorrer los continentes de arriba abajo, los investigadores 
                            reportaron el hallazgo de diversas herramientas de labranza abandonadas en los 
                            campos, como si alguien las hubiera olvidado. Los tractores permanecieron con el 
                            motor encendido hasta que los agentes los apagaron. Las cestas en las que se 
                            transportaban las frutas y las verduras recolectadas quedaron tiradas en los 
                            caminos. <br/><br/>
                            
                            —En conclusión —dijo el hombre que dirigía la pesquisa—, los campesinos, hombres y 
                            mujeres de todo el mundo se esfumaron sin dejar rastro. <br/><br/>
                            
                            Pronto, el mundo se dio cuenta de que las personas que trabajaban la tierra eran 
                            indispensables para la vida de todos. Sin ellas, tres cuartas partes de los alimentos 
                            que se consumían en todo el planeta dejarían de llegar a las cocinas y las mesas de las 
                            familias. <br/><br/>
                            
                            —Tenemos que hacer algo —dijo alguien en la reunión de presidentes. <br/><br/>
                            
                            —Pero ¿qué? —preguntó otro ministro. <br/><br/>
                            
                            —Debemos enseñarnos a sembrar y a cosechar. Tenemos que volvernos agricultores. <br/><br/>
                            
                            —¿Todos? Eso es imposible. Las labores del campo necesitan paciencia y 
                            conocimiento. No se aprenden de la noche a la mañana. <br/><br/>
                            
                            —Tiene usted razón. Entonces necesitamos gente que se especialice en ese trabajo. <br/><br/>

                            —Por supuesto, pero ¿quién va a querer hacerlo? Es un trabajo muy pesado.<br/><br/>
                            
                            —¡Hagamos una campaña en la prensa y solicitemos voluntarios!
                        </p>

                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                            <img className='Imagen' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion21R/2.png"  alt="Tarjeta" />
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura ">
                            La campaña tuvo éxito. Hombres, mujeres, jóvenes, adultos y ancianos se anotaron 
                            en las mesas de reclutamiento. Manos y entusiasmo no faltaron. Pero el campo tiene 
                            sus secretos y no los revela con facilidad a cualquiera. Como dijo el segundo ministro, 
                            la agricultura no se aprende de la noche a la mañana. Una vez que los voluntarios 
                            entendieron cómo se ara la tierra, tuvieron que aprender cuándo se debe sembrar y 
                            cuándo cosechar. También en qué lugares se da tal o cual producto y en cuáles no. 
                            Aprendieron, además, a observar el cielo, a alabar al sol y a agradecer la lluvia. Nunca 
                            se sintieron más cercanos a la tierra.
                        </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                            <img className='Imagen' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion21R/3.png"  alt="Tarjeta" />
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura ">
                            Tanto trabajo y esfuerzo tuvo su recompensa. Una mañana, una joven voluntaria 
                            notó que de la tierra brotaban unas hojas delgadas, de color claro en la base y verde 
                            en el tallo. La joven se acercó poco a poco, sorprendida, emocionada. Se puso en 
                            cuclillas y jaló una de las hojas. De la tierra brotó, como un sol naciente, una esfera 
                            blanca, una luna subterránea. La limpió con las manos y corrió a mostrársela a los 
                            demás. <br/><br/>
                            
                            —¡Una cebolla! ¡Coseché una cebolla! —gritó y todos se asomaron a ver el milagro de 
                            la tierra. <br/><br/>
        
                            —Más hermosa que un ave de plumas cegadoras —dijo un señor que, al ver la cebolla, 
                            recordó la oda de un viejo poeta. <br/><br/>
                            
                            A partir de ese momento, la tierra comenzó, generosa, a compartir de nuevo sus <br/>
                            frutos con los seres humanos. Y todos agradecieron de corazón que los campesinos <br/>
                            hubieran regresado.
                        </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                            <img className='Imagen' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion21R/4.png"  alt="Tarjeta" />
                    </Grid>
                </Grid>
        </Grid>
    </Grid>
    );
}
 
export default El_secreto_de_la_tierra;
