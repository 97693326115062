import React                from "react";
import { makeStyles,Grid }  from '@material-ui/core';

const useStyles = makeStyles(theme =>({
  badgesContainer:{
    width:"229px",
    height: "229px",
    [theme.breakpoints.down(1300)]:{
      width:"200px",
      height:"200px",
    },
    [theme.breakpoints.down(1024)]:{
      width:"150px",
      height:"150px",
    },
    [theme.breakpoints.down(700)]:{
      width:"80px",
      height:"80px",
    },
    [theme.breakpoints.down(400)]:{
      width:"60px",
      height:"60px",
    },
    // [theme.breakpoints.down(1300)]:{
    //   width:"200px",
    //   height:"200px",
    // },
  },
  badgeText:{
    font:'21px Fredoka One',
    letterSpacing: '0.38px',
    color: '#5CAE9A',
    margin:'0px',
    // justifyContent:"center",
    // alignItems:"center",
    [theme.breakpoints.down(1024)]:{
      font:'18px Fredoka One',
    },
    [theme.breakpoints.down(700)]:{
      font:'10px Fredoka One',
    },
    [theme.breakpoints.down(400)]:{
      font:'8px Fredoka One',

      // justifyContent:"flex-start",
    },
  },
  evaluationP:{
    margin:"8px 5px 8px 5px",
    [theme.breakpoints.down(700)]:{
      margin:"5px 3px 5px 3px",
    },
  },
  mainContainer: {
    justifyContent:"center",
    alignItems:"center",
    [theme.breakpoints.down(400)]:{
       justifyContent:"flex-start",
    },
  }

}))

const EvaluationBadge = (props) => {
  const classes = useStyles()
  const {badgeImage, badgeText} = props

  return(
    <Grid container item xs={2} direction="column" className={classes.mainContainer}>
      <Grid>
        <img className={classes.badgesContainer} src={badgeImage} alt="evaluacion1"/>
      </Grid>
      <Grid className={classes.badgeText}><p className={classes.evaluationP}>{badgeText}</p></Grid>
    </Grid>
  )
}

export default EvaluationBadge;