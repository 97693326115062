import React               from "react";
import {Grid, makeStyles}                 from '@material-ui/core';

const useStyles = makeStyles(theme =>({
  questionContainer:{
    width: "100%",
    height:"100%",
    // overflowY: "scroll",
    // scrollbarColor: "#F6DE95",
    // scrollbarWidth: "10px",
    // '&::-webkit-scrollbar': {
    //   width: '15px',
    // },
    // '&::-webkit-scrollbar-track': {
    //   borderRadius: '25px',
    //   backgroundColor: "#F6DE95",
    //   webkitBoxShadow: 'inset 0 0 3px rgba(0,0,0,0.00)'
    // },
    // '&::-webkit-scrollbar-thumb': {
    //   backgroundColor: '#8144DF',
    //   borderRadius: '25px',
    // },
  },
  reading: {
    width: "80%",
    margin:"10px 0 10px 0 ",
    font: '22px lato',
    letterSpacing: '0.21px',
    [theme.breakpoints.down(1400)]:{
      font: " 18px lato",
      width: "85%",
    },
  },
  imgStyle: {
    width:'100%',
    height:'100%',
    [theme.breakpoints.down(1400)]:{
      width:'82px',
      height:'82px',
    },
    [theme.breakpoints.down(1300)]:{
      width:'100%',
      height:'10%px',
    },
  },
}));

const Question = (props) => {
  const classes = useStyles()
  const { picturesTable } = props


  return(
    <Grid container className={classes.questionContainer}>
      <p 
        // dangerouslySetInnerHTML={{__html: `${step+1}. ${pregunta.replace( '\n', '<br/><br/>')}`}}
        className={classes.reading}
      >
        7. De acuerdo con el texto, ¿qué elementos deberías tomar en cuenta al momento de elaborar una artesanía representativa de tu localidad para que sea parte del patrimonio cultural?.
      </p>
      <br/>
    </Grid>
  )

}

export default Question;
