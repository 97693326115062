import { Button, Grid, makeStyles } from "@material-ui/core";
import React, { useContext, useEffect } from "react";
import DrawerContext from "../../../../../usability/DrawerContext";
import { useOnLoadImages } from "../../../../hooks/useOnLoadImages";
import Loader from "../../../../Loader/Loader";
import Drawer from "../../ReadingTools/Drawer";

const useStyles =  makeStyles (theme =>({
     Contenedor:{
        background:'#E9FBF9',
        width:'100vw',
        overflowX:'clip',
        [theme.breakpoints.down(1161)]:{
            background:'none',
           overflowX:'unset',
        },
        [theme.breakpoints.down(600)]:{
            marginTop: '79.51px',
        }
    },
ContainerInfoLectura: {
    position: "relative",
    height: "86px",
    background: "#817FD9",
    [theme.breakpoints.down(502)]:{
        height: "43px",
    },
    "& .BarraInfoLectura": {
      height: "86px",
      background: `Url(https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Barras/Morada.png)`,
      [theme.breakpoints.down(502)]:{
        height: "43px",
    },
    },
    "& .InfoLectura": {
      position: "absolute",
      height: "86px",
      width: "219px",
      boxSizing: "border-box",
      padding: "17.2px 0",
      background: "#FAFAFA",
      borderRadius: "50px",
      boxShadow: "2px 2px 2px 2px #00000029",
      zIndex: "4",
      [theme.breakpoints.down(502)]:{
        height: "43px",
        width: "109px",
        padding: "8.6px 0",
        borderRadius: "25px",
    },
    },
    "& .InfoLectura:before": {
      position: "absolute",
      display: "flex",
      boxSizing: "border-box",
      padding: "0 0 0 10px",
      justifyContent: "center",
      alignItems: "center",
      height: "39.88px",
      width: "39.88px",
      borderRadius: "50%",
      content: '""',
      boxShadow: "3px 0px .5px 0px #00000029",
      background: "#FAFAFA",
      left: "90px",
      top: "65px",
      transform: "rotate(0.25turn)",
      [theme.breakpoints.down(502)]:{
        left: "45px",
        top: "30px",
        height: "20.88px",
        width: "20.88px",
        },
    },
    "& .InfoLectura:after": {
      content: '""',
      height: "15px",
      width: "15px",
      borderTop: "2px solid #817FD9",
      borderLeft: "2px solid #817FD9",
      position: "absolute",
      bottom: "-10px",
      left: "Calc(50% - 8px)",
      transform: "rotate(0.625turn)",
      [theme.breakpoints.down(502)]:{
        height: "10px",
        width: "10px",
        left: "Calc(50% - 4.5px)",
        bottom: "-3px",
        borderTop: "1px solid #20A698",
        borderLeft: "1px solid #20A698",
        },
    },
    "& .Sesion": {
      borderLeft: "2px solid #D8D8D8",
    },
    "& .Dato": {
      margin: "0 0 5px 0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#817FD9",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        margin: "0 0 2.5px 0",
        },
    },
    "& .Caracter": {
      margin: "0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#000000",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        },
    },
       '& .Regresar':{
        color: '#F2624F',
        border: '#F2624F 1px solid',
        height: '30px',
        width:'100px',
        borderRadius: '20px',
        letterSpacing: '0.14px',
        textTransform: 'none',
        background:'#FEFAF1',
        top:'calc(50% - 15px)',
        left:'5%',
        zIndex:4,
        [theme.breakpoints.down(502)]: {
          font:'bold 10px lato',
          width: "60px",
        },
      },
  },
    ContenedorLectura:{
        background:'#fff',
        width:'1160px',
        height:'100%',
                padding:'60px 168px 35px ',
        
        transform: (props) =>
        props.scale > 1 ? `scale(${props.scale}) translate(0px, 10%)` : `none`,
        [theme.breakpoints.down(1161)]:{
            width:'502px',
            padding:'60px 0 0 0',
          },
        [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },

        '& .Escritorio':{
            display:'block',
            [theme.breakpoints.down(1161)]:{
                display:'none'
            },
        },
        '& .Tablet':{
            display:'none',
            [theme.breakpoints.down(1161)]:{
                display:'block'
            },
        }
    },
    ContainerTitulo:{
        margin:'0 0 34px 0',
        [theme.breakpoints.down(1161)]:{
            margin:'0 0 34px 0',
        },
        '& .Titulo':{
            font:'48px fredoka one',
            letterSpacing: '0px',
            lineHeight:'58px',
            color: '#E1AF10',
            margin:'0',
            textAlign:'center',
            [theme.breakpoints.down(1161)]:{
                font:'43px fredoka one',
                lineHeight:'52px',
            },
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            lineHeight : '30px',
            margin:'0 0 36px 0',
            font:'22px lato',
            color:'#000',
            letterSpacing: '0.21px',
            [theme.breakpoints.down(1161)]:{
                font:'18px lato',
                lineHeight :'28px',
                letterSpacing: '0.17px',
            },
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .TextImg':{
            font:'20px lato',
            color:'#717476',
            letterSpacing:'.19px',
            lineHeight:'30px',
            margin:'24px 0 0 0',
            [theme.breakpoints.down(1161)]:{
                font:' 16px lato',
                lineHeight :'25px',
                letterSpacing: '0.15px',
            },
            [theme.breakpoints.down(502)]:{
                font:' 11px lato',
                lineHeight :'14px',
                letterSpacing: '0.10px',
            },
        },
        '& .Lectura2':{
            margin:'0',
        },
        '& .Imagen':{
            [theme.breakpoints.down(1161)]:{
                width:'100%',
            },
        },  
      
    }
}));

const La_fila_más_rápida = (props) => {


  const {activeStep, setActiveStep, handlePreviewStep, id_actividad} = props
  
  const handleContinue = () => {
    setActiveStep(activeStep - 1)
    handlePreviewStep()
  }

    const {
      handleSize,
    disabledZoom,
    closeZoom,
    handleZoom,
    scale
  }= useContext(DrawerContext);
  const [loading, setLoading] = React.useState(true);
  const wrapperRef = React.useRef(null);
  const imagesLoaded = useOnLoadImages(wrapperRef);

  const detectSize = () => {
    const lectura = document.getElementById("lectura");
    const newWidth = lectura?.offsetWidth;
    const newHeight = lectura?.offsetHeight;
    setLoading(false);
    if (lectura) handleSize(newHeight, newWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", detectSize);
    return () => {
      handleZoom()
      window.removeEventListener("resize", detectSize);
      closeZoom()
    };
  }, []);

  const classes = useStyles({ disabledZoom,scale });
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
             <Grid container item justifyContent="center" className={classes.ContainerInfoLectura}>
                       <Grid container className="BarraInfoLectura">
            <Button className="Regresar" onClick={handleContinue}>
                Regresar
            </Button>
        </Grid>
               <Grid container item className="InfoLectura">
                    <Grid container item direction="column" xs={6} justifyContent="center">
                        <p className="Dato">Grado</p>
                        <p className="Caracter">5</p>
                    </Grid>
                    <Grid container item direction="column" xs={6} justifyContent="center" className="Sesion">
                        <p className="Dato">Sesión</p>
                        <p className="Caracter">55</p>
                    </Grid>
               </Grid>
            </Grid>
            {!imagesLoaded && <Loader />}
        <Grid
            container
            item
            className={classes.ContenedorLectura}
            justifyContent="center"
            id="lectura"
            onLoad={detectSize}
            ref={wrapperRef}
        >
<Drawer id_lectura={id_actividad} />
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                        <h2 className="Titulo">La fila más rápida</h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12} justifyContent='center'>
                       <p className='Lectura Lectura2'>
                        En matemáticas, la <em>teoría de filas</em> posee múltiples aplicaciones, que van desde los 
                        ordenadores, las telecomunicaciones y la ingeniería hasta modelar las filas de los 
                        supermercados, las de los automóviles en el tráfico y las que se hacen en los 
                        aeropuertos. Su objetivo es predecir la longitud de las filas y los tiempos de espera 
                        para que estos últimos se reduzcan.<br/><br/> 
                        
                        Su herramienta principal son las <em>cadenas de Márkov</em>, nombradas así en honor al 
                        matemático ruso Andréi Márkov. Son procesos aleatorios que varían con el tiempo, tales 
                        como el clima, las señales sísmicas o la población mundial. Formalmente se les 
                        llama <em>procesos estocásticos</em>, y a los fenómenos que estudian se les conoce como 
                        variables aleatorias
                       </p>                   
                    </Grid>
                    <Grid container justifyContent='center'>
                        <Grid container>
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion55/1.png" alt="Tarjeta" />
                        </Grid>
                        <Grid container item justifyContent='center'>
                            <p className='TextImg Lectura2'>
                                La teoría de filas se utiliza en diversos contextos.
                            </p>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container justifyContent='center' >
                        <p className='Lectura'>
                            La principal característica de una cadena de Márkov es que la probabilidad de que 
                            algo ocurra depende del estado actual de la variable, sin importar su pasado. Por 
                            ejemplo, lo que tarde una persona en pagar en la fila del supermercado no depende 
                            de cuánto tardaron las anteriores ni de cuánto tiempo lleva dando servicio esa caja. <br/><br/> 

                            Ahora imagina que estás en un supermercado, a punto de formarte para pagar. En
                            una caja hay muchos carritos, pero con pocas cosas cada uno. En otra hay pocos 
                            carritos, pero cada uno con mucha mercancía. ¿En cuál te formarías? De acuerdo con 
                            las matemáticas (y con Márkov), tendrías que hacerlo en la que haya pocos carritos, 
                            aunque vayan muy llenos. ¿Quieres saber por qué?
                        </p>
                    </Grid>
                    <Grid container justifyContent='center'>
                        <Grid container>
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion55/2.png" alt="Tarjeta" />
                        </Grid>
                        <Grid container item justifyContent='center'>
                            <p className='TextImg Lectura2'>
                                ¿En qué fila te formarías tú?
                            </p>
                        </Grid>
                    </Grid>

                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container justifyContent='center' >
                        <p className='Lectura Lectura2'>
                            Dentro del proceso de pasar por la caja existe un evento que puede salir mal o tardar 
                            más de lo esperado: el momento de pagar. Es decir, pueden ocurrir diferentes 
                            percances: no llevas cambio, no encuentras tu billetera, la tarjeta no pasa, etcétera. 
                            Por lo tanto, lo mejor es ir a la fila donde dicho evento ocurra menos veces. 
                            Considera que pasar los productos por el escáner es relativamente rápido, aunque 
                            éstos sean muchos. <br/><br/> 
                            
                            Ahora imagina que estás en tu automóvil, formado para pagar una caseta o peaje. 
                            Estás en una fila y te das cuenta de que las otras avanzan más rápido. ¿Qué harías? 
                            ¿Te cambiarías de fila? Según Márkov, esto no haría la diferencia: la probabilidad de 
                            que estés en la fila más lenta es la misma para cada una, pues en estos procesos no 
                            importa lo que ocurrió en el pasado. <br/><br/> 
                            
                            Entonces, ¿qué se podría hacer para agilizar el tránsito? La mayoría piensa que éste 
                            mejoraría si se agregara un nuevo carril, pero sorprendentemente no es así. Esto se 
                            conoce como la <em>paradoja de Braess</em>, y dice que “algunas veces añadir un carril nuevo 
                            empeora la situación”, ya que hace que el tránsito vaya más lento (incluso si el 
                            número de automóviles es el mismo). De hecho, en algunos casos se debería eliminar 
                            un carril. Por este motivo se le llama <em>paradoja</em>.
                        </p>
                    </Grid>
                    <Grid container justifyContent='center'>
                        <Grid container>
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion55/3.png" alt="Tarjeta" />
                        </Grid>
                        <Grid container item justifyContent='center'>
                            <p className='TextImg '>
                            La paradoja de Braess demuestra que no todo es lo que parece.
                            </p>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container justifyContent='center' >
                        <p className='Lectura'>
                            La clave aquí es la distribución, independientemente del número de cajas en el 
                            supermercado, del de casetas en la autopista o del de puestos de seguridad en el 
                            aeropuerto. Lo mejor es hacer una sola fila y distribuir a las personas al 
                            llegar: de esta manera todos los problemas o retrasos se reparten igual entre todos 
                            los puestos y el tiempo total de espera se reduce entre el número de los proveedores 
                            (cajas, casetas, puestos). Así es como funcionan algunos supermercados, bancos y la 
                            mayoría de los aeropuertos. De lo contrario, los controles de seguridad y los de 
                            inmigración serían mucho más lentos.
                        </p>
                    </Grid>
                </Grid>
        </Grid>
        <Grid container item style={{ marginTop: 80}} ></Grid>
    </Grid>
    );
}
 
export default La_fila_más_rápida;
