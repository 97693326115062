import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
    ContenedorLectura:{
        position:'relative',
        height:'100%',
        width:'502px',
        padding:'60px 0 0 0',
        [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
        '& .Escritorio':{
            display:'block',
            [theme.breakpoints.down(1161)]:{
                display:'none'
            },
        },
        '& .Tablet':{
            display:'none',
            [theme.breakpoints.down(1161)]:{
                display:'block'
            },
        }
    },

    ContainerTitulo:{
        margin:'0 0 42px 0',
        '& .Titulo':{
            letterSpacing: '0px',
            color: '#926DD1',
            margin:'0',
            textAlign:'center',
            font:'43px fredoka one',
            lineHeight:'52px',
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            margin:'0 ',
            color:'#000',
            font:'18px lato',
            lineHeight :'31.5px',
            letterSpacing: '0.12px',
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .Imagen1':{
            position:'absolute'
        },
        '& .img1':{
            height:'595px',
            top:'130px',
            [theme.breakpoints.down(502)]:{
                height:'351px',
                top:'123px',
                right:'0px',
            },
        },
        '& .img2':{
            height:'323px',
            top:'768px',
            [theme.breakpoints.down(502)]:{
                height:'178px',
                top:'494px',
                right:'0px',
            },
        },
        '& .img3':{
            height:'337px',
            top:'1455px',
            [theme.breakpoints.down(502)]:{
                height:'212px',
                top:'872px',
                right:'-15px',
            },

        },
        '& .img4':{
            height:'307px',
            top:'1933px',
            [theme.breakpoints.down(502)]:{
                height:'162px',
                top:'1156px',
                right:'0px',
            },
        },
        '& .img5':{
            height:'520px',
            top:'2300px',
            [theme.breakpoints.down(502)]:{
                height:'264px',
                top:'1350px',
                right:'0px',
            },
        },
        '& .img6':{
            height:'288px',
            top:'2882px',
            right:'0px',
            [theme.breakpoints.down(502)]:{
                height:'167px',
                top:'1692px',
            },
        },
        '& .img7':{
            height:'294px',
            top:'3455px',
            right:'0px',
            [theme.breakpoints.down(502)]:{
                height:'160px',
                top:'2017px',
            },
        },
        '& .img8':{
            height:'126px',
            top:'4115px',
             [theme.breakpoints.down(502)]:{
                height:'76px',
                top:'2770px',
                right:'0px',
            },
            [theme.breakpoints.down(350)]:{
                height:'76px',
                top:'2351px',
                right:'0px',
            },
        },
    },
    

}));

const Qué_cambio = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >
                        ¡Qué cambio!
                    </h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={5}>
                        <p className="Lectura">
                            <em>Me voy a la cama</em>.<br/>
                                Se enciende la noche.<br/>
                                Y los monstruos bailan<br/>
                                con sus garras negras.<br/><br />

                                Viene una estampida<br/>
                                de rinocerontes,<br/>
                                que con sus colores<br/>
                                cornean la luna.<br/><br />

                                Labios como faldas<br/>
                                se arrastran despacio.<br/>
                                Manos de gorilas,<br/>
                                fauces temerarias.<br/>
                                Colmillos de frente<br/>
                                y cuernos en las barbas.<br/><br />

                                “¡A la rueda, rueda!”,<br/>
                                cantan en un coro.<br/><br />

                                Yo soy la pupila<br/>
                                de sus ojos toscos.<br/>
                                En un parpadeo<br/>
                                ¡vienen y me atrapan!<br/><br />

                                Así que ahora formo<br/>
                                parte de la danza.<br/>
                                Brillan los espejos<br/>
                                de luna en el aire.<br/><br />

                                Allí me descubro.<br/>
                                Su luz me deslumbra.<br/>
                                Sus guiños urgentes<br/>
                                borran la amargura.<br/><br />

                                Ya no soy la niña<br/>
                                de baja estatura.<br/>
                                Soy el monstruo grande,<br/>
                                soy lobo plateado.<br/><br />

                                con manchas oscuras,<br/>
                                con los ojos negros,<br/>
                                con nariz aguda<br/>
                                y manos de tentáculo.<br/><br />

                                En el cielo brillan<br/>
                                infinitos pares<br/>
                                de ojitos de monstruos<br/>
                                todos como yo.<br/><br />

                                <em>Más tarde despierto</em>.<br/><br />

                                Gallo de metal<br/>
                                con alas brillantes<br/>
                                canta a la mañana<br/>
                                que ya me levante.<br/><br />

                                Su pico afilado<br/>
                                bosteza con hambre<br/>
                                y se traga el tiempo<br/>
                                de un solo bocado:<br/>
                                “Quiquiriquí”.<br/><br />

                                Entonces los monstruos<br/>
                                se ocultan de nuevo.<br/><br />

                                La luz me hace daño,<br/>
                                mi madre me grita:<br/>
                                “¡Ya es hora del baño!”<br/>
                                Yo corro con prisa.<br/><br />

                                De frente al espejo<br/>
                                voy y tuerzo el rostro.<br/>
                                Mi madre me grita:<br/>
                                “¡Pareces un monstruo!”<br/><br />

                                Arregla mis labios,<br/>
                                mi pelo de bestia<br/>
                                lo vuelve de humano.<br/>
                                También disimula<br/>
                                mis grandes orejas.<br/><br />

                                Primer día de clases.<br/>
                                Los niños me observan.<br/>
                                Yo busco las sombras<br/>
                                en cada rincón.<br/><br />

                                Me siento en el fondo<br/>
                                para ser discreta<br/>
                                y guardar mi monstruo<br/>
                                ante sus miradas.<br/><br />

                                Vuela un ave inmensa<br/>
                                sobre los asientos.<br/>
                                “Yo soy su maestra.<br/>
                                Hola, ¿cómo están?”<br/><br />

                                Me manda hasta el frente:<br/>
                                “Tú eres muy pequeña<br/>
                                y éste es tu lugar”.<br/><br />

                                <em>Hora del recreo</em>.<br/><br />

                                “¡Jugaremos en el bosque<br/>
                                mientras el lobo no está.”<br/><br /> 

                                Es hora del hambre.<br/>
                                Somos un satélite<br/>
                                de niños raros<br/>
                                cantando la ronda<br/>
                                mientras que, en el centro,<br/>
                                una niña curiosa<br/>
                                nos quiere atrapar.<br/><br />

                                Corremos. Me alcanza<br/>
                                con sus manos largas.<br/>
                                María se llama<br/>
                                y ríe sin parar.<br/><br />

                                Sus ojos de perla<br/>
                                que brillan me miran.<br/>
                                María sonríe,<br/>
                                me dejo cazar.<br/><br />

                                Ella me sujeta.<br/>
                                Nuestros roles cambian<br/>
                                y yo estoy contenta:<br/><br />

                                ¡Por fin soy el lobo!
                        </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={7}>
                        <img className="Imagen1 img1" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img2do/Sesion27/1.png" alt="Tarjeta" />
                        <img className="Imagen1 img2" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img2do/Sesion27/2.png" alt="Tarjeta" />
                        <img className="Imagen1 img3" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img2do/Sesion27/3.png" alt="Tarjeta" />
                        <img className="Imagen1 img4" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img2do/Sesion27/4.png" alt="Tarjeta" />
                        <img className="Imagen1 img5" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img2do/Sesion27/5.png" alt="Tarjeta" />
                        <img className="Imagen1 img6" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img2do/Sesion27/6.png" alt="Tarjeta" />
                        <img className="Imagen1 img7" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img2do/Sesion27/7.png" alt="Tarjeta" />
                        <img className="Imagen1 img8" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img2do/Sesion27/8.png" alt="Tarjeta" />
                    </Grid>
                </Grid>
        </Grid>
    </Grid>
    );
}
 
export default Qué_cambio;
