import { Grid,makeStyles}              from '@material-ui/core';
import {React}                from 'react';

const useStyles =  makeStyles (theme =>({
    ContenedorLectura:{
        width:'100%',
          [theme.breakpoints.down(768)]:{
            width:'350px',
          },
    },
    Parrafo1:{
        position:'relative',
    },
        
}));

const El_peso_de_los_perros = () => {
    const classes = useStyles ()
    
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion34/F.png" alt="Tarjeta" />
                </Grid>
            </Grid>
        </Grid>
    );
}
 
export default El_peso_de_los_perros;
