import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
     Contenedor:{
        background:'#E9FBF9',
        width:'100vw',
        overflowX:'clip',
        [theme.breakpoints.down(1161)]:{
            background:'none',
           overflowX:'unset',
        },
        [theme.breakpoints.down(600)]:{
            marginTop: '79.51px',
        }
    },
  ContainerInfoLectura: {
    position: "relative",
    height: "86px",
    [theme.breakpoints.down(502)]:{
        height: "43px",
    },
    background: "#76D4C3",
    "& .BarraInfoLectura": {
      height: "86px",
      background: `Url(https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Barras/Azul.png)`,
        [theme.breakpoints.down(502)]:{
            height: "43px",
        },
    },
    "& .InfoLectura": {
      position: "absolute",
      height: "86px",
      width: "219px",
      boxSizing: "border-box",
      padding: "17.2px 0",
      background: "#FAFAFA",
      borderRadius: "50px",
      boxShadow: "2px 2px 2px 2px #00000029",
      zIndex: "4",
        [theme.breakpoints.down(502)]:{
            height: "43px",
            width: "109px",
            padding: "8.6px 0",
            borderRadius: "25px",
        },
    },
    "& .InfoLectura:before": {
      position: "absolute",
      display: "flex",
      boxSizing: "border-box",
      padding: "0 0 0 10px",
      justifyContent: "center",
      alignItems: "center",
      height: "39.88px",
      width: "39.88px",
      borderRadius: "50%",
      content: '""',
      boxShadow: "3px 0px .5px 0px #00000029",
      background: "#FAFAFA",
      left: "90px",
      top: "65px",
      transform: "rotate(0.25turn)",
      [theme.breakpoints.down(502)]:{
        left: "45px",
        top: "30px",
        height: "20.88px",
        width: "20.88px",
        },
    },
    "& .InfoLectura:after": {
      content: '""',
      height: "15px",
      width: "15px",
      borderTop: "2px solid #20A698",
      borderLeft: "2px solid #20A698",
      position: "absolute",
      bottom: "-10px",
      left: "Calc(50% - 8px)",
      transform: "rotate(0.625turn)",
      [theme.breakpoints.down(502)]:{
        height: "10px",
        width: "10px",
        left: "Calc(50% - 4.5px)",
        bottom: "-3px",
        borderTop: "1px solid #20A698",
        borderLeft: "1px solid #20A698",
        },
    },
    "& .Sesion": {
      borderLeft: "2px solid #D8D8D8",
    },
    "& .Dato": {
      margin: "0 0 5px 0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#20A698",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        margin: "0 0 2.5px 0",
        },
    },
    "& .Caracter": {
      margin: "0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#000000",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        },
    },   },
    ContenedorLectura:{
        background:'#fff',
        width:'1160px',
        height:'100%',
        padding:'60px 168px 35px ',
        [theme.breakpoints.down(1161)]:{
            width:'502px',
            padding:'60px 0 0 0',
          },
        [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
        '& .ContainerDerechos':{
            bottom:'80px',
            [theme.breakpoints.down(1161)]:{
                display:'none',
            },
        },
        '& .Derechos':{
            textAlign:"center",
            margin:"0px",
            font:" 15px lato",
            color:"#717476",
        },
        '& .Escritorio':{
            display:'block',
            [theme.breakpoints.down(1161)]:{
                display:'none'
            },
        },
        '& .Tablet':{
            display:'none',
            [theme.breakpoints.down(1161)]:{
                display:'block'
            },
        }
    },
    ContainerTitulo:{
        margin:'0 0 61px 0',
        '& .Titulo':{
            letterSpacing: '0px',
            color: '#0D91E5',
            margin:'0',
            textAlign:'center',
            font:'48px fredoka one',
            [theme.breakpoints.down(1161)]:{
                font:'43px fredoka one',
                lineHeight:'52px',
            },
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            lineHeight : '30px',
            margin:'0 0 36px 0 ',
            font:'22px lato',
            color:'#000',
            letterSpacing: '0.20px',
            [theme.breakpoints.down(1161)]:{
                font:'18px lato',
                lineHeight :'28px',
                letterSpacing: '0.17px',
            },
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },

        '& .SubTitulo':{
            color:'#EAA304',
            margin:'0',
            font:'bold 30px lato',
            letterSpacing:'.28px',
            [theme.breakpoints.down(1161)]:{
                lineHeight :'28px',
                font:'bold 26px lato',
                letterSpacing: '0.24px',
            },
            [theme.breakpoints.down(502)]:{
                font:'bold 18px lato',
                lineHeight :'24px',
                letterSpacing: '0.21px',
            },
        },
        '& .TextImg':{
            color:'#717476',
            margin:'21px 0 0 0',
            font:'20px lato',
            lineHeight:'30px',
            letterSpacing:'.19px',
            [theme.breakpoints.down(1161)]:{
                font:' 16px lato',
                lineHeight :'25px',
                letterSpacing: '0.15px',
            },
            [theme.breakpoints.down(502)]:{
                font:' 11px lato',
                lineHeight :'14px',
                letterSpacing: '0.10px',
            },
        },
        '& .Esp1':{
            letterSpacing:'-.1px',

            [theme.breakpoints.down(1161)]:{
                position:'relative',
                width:'100%',
            }

        },
        '& .Esp2':{
            position:'absolute',
            width:'520px',
            [theme.breakpoints.down(1161)]:{
                position:'relative',
                width:'100%',
            }

        },
        '& .Imagen':{
            [theme.breakpoints.down(1161)]:{
                width:'100%',

            },
        },
        '& .Img':{
            position:'relative',
            left:'55px',
            top:'15px',
            [theme.breakpoints.down(1161)]:{
                left:'0',
                top:'0px',
            },
        },

    },

}));

const Arqueología_subacuática = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
             <Grid container item justifyContent="center" className={classes.ContainerInfoLectura}>
               <Grid container className="BarraInfoLectura"></Grid>
               <Grid container item className="InfoLectura">
                    <Grid container item direction="column" xs={6} justifyContent="center">
                        <p className="Dato">Grado</p>
                        <p className="Caracter">4</p>
                    </Grid>
                    <Grid container item direction="column" xs={6} justifyContent="center" className="Sesion">
                        <p className="Dato">Sesión</p>
                        <p className="Caracter">11</p>
                    </Grid>
               </Grid>
            </Grid>

            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >
                        Arqueología subacuática
                    </h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                      <p className="Lectura">
                        <span className='Esp1'>La <em>arqueología</em> es una disciplina que estudia los restos materiales de la vida y las 
                        actividades humanas del pasado. Al ser una ciencia, los arqueólogos siguen una serie de 
                        pasos y técnicas para analizar los objetos que encuentran. Éstos pueden ser cualquier 
                        cosa hecha por la humanidad, desde herramientas de piedra hasta asentamientos 
                        completos, como las pirámides de Egipto o la ciudadela de Machu Picchu.<br/><br/></span>

                        <span className='Esp2'>El campo de la arqueología no se limita a la superficie terrestre: también abarca lo que hay 
                        debajo del agua. Muchas actividades humanas están relacionadas con ella, como la pesca, 
                        el comercio, la guerra y los viajes. Actualmente, estos restos son estudiados por una rama 
                        que se llama <em>arqueología subacuática</em>.</span>
                      </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <Grid container item justifyContent='center' xs={12}>
                        <img className="Imagen Img" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img4to/Sesion11/1.png" alt="Tarjeta" />
                        </Grid>
                        <Grid container item justifyContent='center'>
                            <p className='TextImg'>Los objetos que transportaban los barcos en otras épocas y que están perdidos en el fondo del mar permiten observar cómo era la vida en otras épocas.</p>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                      <p className="Lectura">
                        <h3 className='SubTitulo'>El rompecabezas de la historia</h3><br/>
                        
                        Esta vertiente arqueológica comenzó a mediados del siglo <span style={{fontVariant: "all-small-caps"}}>XX</span>. Se desarrolló varios 
                        años después de la arqueología general, la cual surgió en el siglo <span style={{fontVariant: "all-small-caps"}}>XVIII</span>. Siempre había 
                        existido interés por conocer lo que había en las profundidades del mar, pero no se 
                        contaba con tecnología que permitiera inspeccionar esos y otros cuerpos acuáticos.<br/><br/> 
                        
                        La mayor necesidad era un invento que dejara respirar a los buzos por periodos más 
                        largos. Pero Jacques Cousteau y Émile Gagnan diseñaron un regulador de buceo 
                        conocido como el <em>pulmón acuático</em> en la década de los años cuarenta. Gracias a él, los 
                        exploradores tuvieron la posibilidad de moverse libremente y recorrer más 
                        distancias.<br/><br/> 
                        
                        Esta nueva herramienta ayudó a encontrar objetos perdidos y a conocer más sobre la 
                        historia de la humanidad por medio de ellos. Para la arqueología, los objetos son 
                        como piezas de un rompecabezas, las cuales brindan información sobre el pasado 
                        cuando se toma en cuenta el lugar donde fueron hallados y se estudian en conjunto. 
                        Los materiales encontrados bajo el agua no tienen valor por sí mismos para los 
                        arqueólogos, a diferencia de lo que ocurre con cazadores de tesoros.
                      </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <Grid container item justifyContent='center'>
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img4to/Sesion11/2.png" alt="Tarjeta" />
                        </Grid>
                        <Grid container item justifyContent='center'>
                            <p className='TextImg'>Hoy en día la arqueología subacuática ha cobrado gran importancia gracias al apoyo de los distintos gobiernos y entidades que buscan preservar los tesoros históricos.</p>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container item sm={12}>
                        <p className='Lectura '>
                            <h3 className='SubTitulo'>Los retos por vencer</h3><br/>
                            
                            Esta ciencia aún enfrenta algunos problemas, a pesar de sus avances tecnológicos. 
                            Por ejemplo, el hecho de que los buzos no pueden permanecer bajo el agua durante 
                            mucho tiempo y les es difícil ver la fauna del lugar con claridad. Otro inconveniente 
                            es que hay peces que pueden ser peligrosos, como el pez escorpión, que se oculta en 
                            los objetos y ataca cuando los tocan. Por eso, los investigadores procuran tener 
                            mucho cuidado al realizar sus búsquedas.<br/><br/> 
                            
                            En América Latina existen varios sitios arqueológicos subacuáticos. En ellos hay 
                            restos prehistóricos, precolombinos y de la época colonial. Un hallazgo de esta época 
                            son los barcos naufragados, que atraen tanto a científicos como a cazadores de 
                            tesoros. Estos últimos pueden perjudicar las investigaciones porque se llevan las 
                            piezas que encuentran y sin ellas los estudios pierden información valiosa. <br/><br/>
                            
                            Como puedes ver, los arqueólogos tienen la tarea de explicar parte de la historia 
                            humana con sus exploraciones, ¡pero también de proteger el patrimonio de los 
                            pueblos y de toda la humanidad!
                        </p>
                    </Grid>
                </Grid>
                <Grid container item xs={12} justifyContent="center" className="ContainerDerechos">
                    <p className="Derechos">
                        © 2022. Todos los derechos reservados, Pearson, Circuito de lectores.
                    </p>                
                </Grid>
        </Grid>
    </Grid>
    );
}
 
export default Arqueología_subacuática;
