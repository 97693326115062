import { makeStyles, Grid } from "@material-ui/core";
import React from "react";
import { Link } from "react-router-dom";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import SearchIcon from "@material-ui/icons/Search"
import StudentTable from "./StudentTable";

import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import UsabilityContext from "../../../../../usability/UsabilityContext";
import AuthContext from "../../../../../auth/AuthContext";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    minHeight: "92vh",
    padding: "48px 0 48px 0",
    background:
      "linear-gradient(217deg, rgba(255,194,0,0.4962185557816877) 2%, rgba(255,255,255,1) 25%, rgba(255,255,255,0.4962185557816877) 81%, rgba(144,81,177,1) 98%)",
    marginTop: "80px",
    [theme.breakpoints.up("sm")]: {
      marginTop: "0px",
    },
    // [theme.breakpoints.up(1920)]:{
    //     height:'92vh',
    // },
    // [theme.breakpoints.down(1920)]:{
    //     height:'92vh',
    // },
    // [theme.breakpoints.down(1811)]:{
    //     height:'1150px',
    // },
    // [theme.breakpoints.down(1487)]:{
    //     height:'1430px',
    // },
    // background: 'linear-gradient(217deg, rgba(255,194,0,0.4962185557816877) 2%, rgba(255,255,255,1) 25%, rgba(255,255,255,0.4962185557816877) 81%, rgba(144,81,177,1) 98%)',
    // [theme.breakpoints.down(1280)]:{
    //     background:'none',

    // },
  },
  boxContainer: {
    background: "#fff",
    width: "90%",
    maxWidth: "1216px",
    maxHeight: "1000px",
    borderRadius: "42px",
    padding: "30px",
    boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.5)",
  },
  titleContainer: {
    // height: "60px",
  },
  title: {
    font: "32px fredoka one",
    margin: "0 ",
    width: "100%",
    textAlign: "center",
    color: "#40ADA3",
    [theme.breakpoints.up("lg")]: {
      // height: "31px",
      // width: "150px",
      fontSize: "42px",
    },
  },
  link: {
    cursor: "pointer",
    textDecoration: "none",
    height: "31px",
    width: "190px",
  },
  containerButtonAndTitle: { gap: "10px" },
  backButtonContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.up("sm")]: {
      justifyContent: "flex-start",
    },
  },
  backButton: {
    cursor: "pointer",
    height: "35px",
    // width: "200px",
    backgroundColor: "#FDEFF7",
    color: "#DC5EA6",
    border: "#DC5EA6 1px solid",
    borderRadius: "20px",
    textTransform: "none",
    font: "20px Lato",
    letterSpacing: "0.14px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    [theme.breakpoints.down("770")]: {
      // height: "31px",
      // width: "150px",
      font: "16px Lato",
    },
  },

  ContainerSerchBar: {
    // gap: "10%",
    // padding: "10px 0",
    display: "grid",
    gridTemplateColumns: "1fr",
    gridTemplateRows: "auto 1fr",
    [theme.breakpoints.up("sm")]: {
      gridTemplateRows: "1fr",
      gridTemplateColumns: "auto 1fr",
      justifyItems: "center",
    },
  },
  ContainerInput: {
    position: "relative",
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: "-120px",
      width: "50%",
      // height: "auto",
    },
  },

  SerchBar: {
    outline: "none",
    width: "100%",
    borderRadius: "36px",
    padding: "5px 32px",
    border: "4px solid #EA9D90",
    textAlign: "left",
    letterSpacing: "0.63px",
    color: "#7A54BA",
    font: "600 20px lato",
    boxSizing: "border-box",
    // margin: " 0 10%",
    [theme.breakpoints.up("lg")]: {
      height: "auto",
      padding: "10px 32px",
    },
  },
  SerchBarIcon: {
    right: "12.5px",
    top: "12.5px",
    position: "absolute",
    fontSize: "20px",
    color: "#7A54BA",
    [theme.breakpoints.up("sm")]: {
      right: "12.5px",
      top: "12.5px",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "40px",
      right: "25px",
      top: "10px",
    },
  },
  studentListContainer: {},

  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },

  returnButton: {
    cursor: "pointer",
    textDecoration: "none",
    // height: "31px",
    // width: "190px",
    [theme.breakpoints.down("770")]: {
      // height: "31px",
      // width: "150px",
    },
  },
}));

const StudentsInfo = () => {
  const classes = useStyles();
  const { user } = React.useContext(AuthContext);
  const { handleGroupToEdit, handleSearch, search } = React.useContext(UsabilityContext);

  const [grade, setGrade] = React.useState("");
  const selectChange = (event) => {
    setGrade(event.target.value);
    handleGroupToEdit(event.target.value);
  };

  return (
    <Grid
      container
      item
      sm={12}
      alignItems="center"
      justifyContent="center"
      className={classes.mainContainer}
    >
      <Grid container item direction="column" className={classes.boxContainer}>
        <Grid
          container
          direction="row"
          className={classes.titleContainer}
          alignItems="center"
          justifyContent="center"
        >
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="flex-start"
            sx={{ gap: "10px" }}
            className={classes.containerButtonAndTitle}
          >
            <Grid item className={classes.backButtonContainer}>
              <Link className={classes.returnButton} to="/docente/configuracion">
                <button className={classes.backButton} onClick={() => handleGroupToEdit("")}>
                  <ArrowBackIcon /> Configuración
                </button>
              </Link>
            </Grid>

            {/* <Grid container item xs={6} alignItems="center" justifyContent="center"> */}
            <h2 className={classes.title}>Estudiantes</h2>
            {/* </Grid> */}
            {/* <Grid item xs={3}></Grid> */}
          </Grid>
        </Grid>
        <Grid
          container
          item
          justifyContent="center"
          alignItems="center"
          className={classes.ContainerSerchBar}
        >
          <FormControl className={classes.formControl}>
            <InputLabel id="demo-simple-select-label">Grado</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={grade}
              onChange={selectChange}
              defaultValue={grade}
            >
              {user.grupos.map((grupo) => (
                <Link
                  to={`/docente/configuracion/alumno/${grupo.id_distribucion_escuela}`}
                  style={{ textDecoration: "none", color: "#000" }}
                >
                  <MenuItem
                    value={grupo.id_distribucion_escuela}
                    key={grupo.id_distribucion_escuela}
                  >
                    {` Grado ${grupo.id_grado}`}
                  </MenuItem>
                </Link>
              ))}
            </Select>
          </FormControl>
          <Grid
            container
            item
            justifyContent="center"
            alignItems="center"
            className={classes.ContainerInput}
          >
            <input
              className={classes.SerchBar}
              type="text"
              placeholder="Buscar alumno"
              onChange={handleSearch}
              value={search}
            />
            <SearchIcon className={classes.SerchBarIcon} />
          </Grid>
        </Grid>
        <Grid
          container
          className={classes.studentListContainer}
          justifyContent="center"
          alignItems="center"
        >
          {/* <StudentsList/> */}
          <StudentTable />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default StudentsInfo;
