import { makeStyles, Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import ContainerAdmin from "../ContainerAdmin";

import axios from "../../../../services/axios";

import ModalConfirm from "../../../Modals/ModalConfirm";
import ModalMessage from "../../../Modals/ModalMessage";
import SelectGradeGroup from "../CargarDatos/SelectGrades";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    padding: "48px 0 48px 0",
    width: "100vw",
    minHeight: "calc(100vh - 80px)",

    background:
      "linear-gradient(217deg, rgba(255,194,0,0.4962185557816877) 2%, rgba(255,255,255,1) 25%, rgba(255,255,255,0.4962185557816877) 81%, rgba(144,81,177,1) 98%)",
  },
  boxContainer: {
    background: "#fff",
    width: "80%",
    minHeight: "80vh",
    height: "auto",
    borderRadius: "42px",
    padding: "20px",
    gap: "20px",
    boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.5)",
    [theme.breakpoints.up("md")]: {
      padding: "48px 20px 48px 20px",
    },
  },
  titleContainer: {
    margin: "20px 0",
    [theme.breakpoints.up("xl")]: {
      margin: "30px 0",
    },
  },
  title: {
    font: "28px Fredoka One ",
    margin: "0",
    textAlign: "center",
    color: "#DC5EA6",
    [theme.breakpoints.up("md")]: {
      fontSize: "36px",
    },
    [theme.breakpoints.up("xl")]: {
      fontSize: "42px",
    },
  },
  link: {
    cursor: "pointer",
    textDecoration: "none",
    // height: "31px",
    // width: "190px",
  },
  backButtonContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.up("sm")]: {
      justifyContent: "flex-start",
    },
  },
  backButton: {
    cursor: "pointer",
    // height: "35px",
    // width: "80%",
    // maxWidth: "200px",
    minWidth: "100px",
    backgroundColor: "#FDEFF7",
    color: "#DC5EA6",
    border: "#DC5EA6 1px solid",
    borderRadius: "20px",
    textTransform: "none",
    font: "20px Lato",
    letterSpacing: "0.14px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
  },
  studentListContainer: {
    gap: "20px",
  },
  PurpleButton: {
    color: "#FFFFFF",
    backgroundColor: "#9051b1",
    textTransform: "unset",
    borderRadius: "30px",
    // margin: "0 auto",
    minWidth: "200px",
    width: "100%",
    maxWidth: "238px",
    minHeight: "45px",
    border: "none",
    cursor: "pointer",
    fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
    fontWeight: "500",
    padding: "6px 16px",
    lineHeight: "1.75",
    transition:
      "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    boxShadow:
      "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
    "&:hover": {
      backgroundColor: "#B965D3 ",
    },
  },
  formContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    maxWidth: "560px",
    alignItems: "center",
    gap: "20px",
  },
  formItemContainer: {
    gap: "10px",
  },
  input: {
    // maxWidth: "560px",
    width: "100%",
    backgroundColor: "#f0f0f0",
    padding: "11.5px 27px",
    borderRadius: "61px",
    borderBottom: "none",
    boxSizing: "border-box",
    border: "none",
    // height: "40px",
    fontSize: "16px",
    "&:focus": {
      outline: "none",
    },
    "& .MuiInput-underline:before": {
      width: "1px",
      borderBottom: "none",
    },
  },
  label: {
    fontWeight: "bold",
    fontFamily: "Lato, sans-serif",
  },
}));

const EditStudent = () => {
  const classes = useStyles();
  const history = useHistory();
  const { id_usuario } = useParams();

  const [id_escuela, setId_escuela] = useState("");
  const [optionGrades, setOptionGrades] = useState([]);
  const [formData, setFormData] = React.useState({});
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [modalMessageOpen, setModalMessageOpen] = useState(false);
  const [confirmChangesResult, setConfirmChangesResult] = useState("");

  const handleCloseConfirmationModal = () => {
    setConfirmationModalOpen(!confirmationModalOpen);
  };
  const handleCloseMessageModal = () => {
    setModalMessageOpen(!modalMessageOpen);
  };

  const handleForm = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const confirmChanges = () => {
    if (
      formData?.nombre &&
      formData?.apellido_paterno &&
      formData?.grado &&
      formData?.grupo
    ) {
      const body = {
        id_grado: formData?.grado,
        grupo: formData?.grupo,
        nombre: formData?.nombre,
        apellido_paterno: formData?.apellido_paterno,
        apellido_materno: formData?.apellido_materno,
        id_usuario: Number(id_usuario),
      };
      axios
        .put("/listado/update/alumno", body)
        // .put("/listado/nombre", body)
        .then((response) => {
          if (response.status === 200) {
            setConfirmationModalOpen(false);
            setModalMessageOpen(true);
            setConfirmChangesResult(
              "Los datos se han actualizado correctamente"
            );
          }
        })
        .catch((error) => {
          setConfirmationModalOpen(false);
          setModalMessageOpen(true);
          setConfirmChangesResult(
            "Ha ocurrido un error al actualizar los datos. Intente de nuevo más tarde."
          );
        });
    } else {
      setConfirmationModalOpen(false);
      setModalMessageOpen(true);
      setConfirmChangesResult("Asegúrese de llenar todos los datos");
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();
  };

  useEffect(() => {
    axios
      .get(`/listado/info/usuario?id_usuario=${id_usuario}`)
      .then((response) => {
        if (response.status === 200) {
          const data = response.data.resultado;
          setId_escuela(data.id_escuela);
          setFormData({
            usuario: data.nombre_usuario,
            nombre: data.nombre,
            apellido_paterno: data.apellido_paterno,
            apellido_materno: data.apellido_materno,
            grupo: data.id_grupo || data.grupo,
            grado: data.id_grado || data?.grado,
            perfil: 1,
          });
        }
      })
      .catch((error) => {
        console.log(error, "error");
      });
    return () => {};
  }, [id_usuario]);

  useEffect(() => {
    if (id_escuela) {
      axios
        .get(`listado/grado/grupos?id_escuela=${id_escuela}`)
        .then((response) => {
          if (response.status === 200) {
            setOptionGrades(response?.data?.resultado?.listado);
          }
        })
        .catch((error) => {
          console.log(error, "error");
        });
    }
  }, [id_escuela]);

  return (
    <ContainerAdmin>
      <Grid
        container
        item
        sm={12}
        alignItems="center"
        justifyContent="center"
        className={classes.mainContainer}
      >
        <Grid
          container
          item
          direction="column"
          className={classes.boxContainer}
        >
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="center"
          >
            <Grid item className={classes.backButtonContainer}>
              <button
                className={classes.backButton}
                onClick={() => history.goBack()}
              >
                <ArrowBackIcon /> Usuarios
              </button>
            </Grid>

            <Grid
              container
              item
              alignItems="center"
              justifyContent="center"
              className={classes.titleContainer}
            >
              <h2 className={classes.title}>Editar perfil del alumno</h2>
            </Grid>
          </Grid>
          <Grid
            container
            direction="column"
            className={classes.studentListContainer}
            justifyContent="center"
            alignItems="center"
          >
            <form onSubmit={handleSubmit} className={classes.formContainer}>
              <Grid
                container
                direction="column"
                className={classes.formItemContainer}
              >
                <label htmlFor="input-name" className={classes.label}>
                  Nombre(s)
                </label>
                <input
                  type="text"
                  id="input-name"
                  placeholder="Nombre"
                  onChange={handleForm}
                  value={formData.nombre}
                  className={classes.input}
                />
              </Grid>
              <Grid
                container
                direction="column"
                className={classes.formItemContainer}
              >
                <label
                  htmlFor="input-apellido-paterno"
                  className={classes.label}
                >
                  Primer apellido
                </label>
                <input
                  type="text"
                  id="input-apellido-paterno"
                  name="apellido_paterno"
                  onChange={handleForm}
                  value={formData.apellido_paterno}
                  className={classes.input}
                />
              </Grid>
              <Grid
                container
                direction="column"
                className={classes.formItemContainer}
              >
                <label
                  htmlFor="input-apellido-materno'"
                  className={classes.label}
                >
                  Segundo apellido
                </label>
                <input
                  type="text"
                  id="input-apellido-materno"
                  name="apellido_materno"
                  onChange={handleForm}
                  value={formData.apellido_materno}
                  className={classes.input}
                />
              </Grid>

              {formData?.grado && optionGrades && (
                <SelectGradeGroup
                  list={optionGrades}
                  title="grado"
                  handleForm={handleForm}
                  defaultValue={formData?.grado}
                  value={formData?.grado}
                  formData={formData}
                />
              )}

              <SelectGradeGroup
                list={
                  optionGrades.find((item) => item.id_grado === formData.grado)
                    ?.grupos
                }
                title="grupo"
                handleForm={handleForm}
                defaultValue={formData?.grupo}
                value={formData?.grupo}
                formData={formData}
              />

              <Grid
                container
                direction="column"
                className={classes.formItemContainer}
              >
                <label htmlFor="input-username'" className={classes.label}>
                  Nombre de usuario
                </label>
                <input
                  type="text"
                  id="input-username"
                  name="usuario"
                  onChange={handleForm}
                  value={formData.usuario}
                  disabled
                  className={classes.input}
                />
              </Grid>
              <button
                className={classes.PurpleButton}
                onClick={handleCloseConfirmationModal}
                type="submit"
              >
                Confirmar cambios
              </button>
            </form>

            <ModalConfirm
              open={confirmationModalOpen}
              handleClose={handleCloseConfirmationModal}
              handleConfirm={confirmChanges}
              title="¿Está seguro de cambiar los cambios? Revisa con cuidado, ya que si cambias al alumno de grado se borrará todo su avance de sesiones."
            />

            <ModalMessage
              open={modalMessageOpen}
              handleClose={handleCloseMessageModal}
              title={confirmChangesResult}
              textOfButton="Aceptar"
            />
          </Grid>
        </Grid>
      </Grid>
    </ContainerAdmin>
  );
};

export default EditStudent;
