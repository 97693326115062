import React                            from 'react';
import { Grid,makeStyles, Button, Dialog}       from "@material-ui/core";
import DialogActions        from '@material-ui/core/DialogActions';
import DialogContent        from '@material-ui/core/DialogContent';
import DialogTitle          from '@material-ui/core/DialogTitle';

const useStyles = makeStyles(theme =>({
  imgs:{
    maxWidth: "300px",
    height:'100px',
    // borderRadius: "15px",
    marginBottom:"5%",
    // border: "3px #1DA698 solid",
    cursor: "pointer",
    background: "#F4F5D4 0% 0% no-repeat padding-box",
    boxShadow: "0px 5px 9px #00000029",
    overflow: "hidden",
    borderRadius: "38px",
    alignItems: "center",
    // padding: "5px",
    boxSizing: "border-box",
    // display: "flex",
    flexDirection: "column",
    borderBottomLeftRadius: "0",
    borderBottomRightRadius: "0",
    justifyContent: "space-between",
    display: "grid",
    gridTemplateRows: "auto 50px",
    gridTemplateColumns: "1fr",
  },
  TituloTipoDocumento:{
    margin:'0',
    font:'bold 20px lato',
    textAlign: 'center',
    letterSpacing:' 0.67px',
    color: 'black',
    overflow:'hidden',
  },

  MuiDialogContainer: {
    borderRadius: '61px',
    height: "400px",
    width: "70%",
    backgroundColor: "#FEFAF1",
    border: "#707070 2px solid",
    backdropFilter: "blur(3px)",
    [theme.breakpoints.down(1568)]:{
      height: "300px",
      maxWidth: "75%",
    },
    [theme.breakpoints.down(468)]:{
      height: "250px",
      maxWidth: "80%",
    },
  },
  continueButton:{
    marginBottom:"20px",
    textTransform: 'none',
    height:'62px',
    width:'279px',  
    background: '#7A54BA',
    boxShadow: '0px 3px 6px #00000029',
    borderRadius: '61px',
    color: '#FFFFFF',
    font: 'bold 20px lato ',
    '&:hover':{
        background: '#dd78fb',
    },
    [theme.breakpoints.down(1368)]:{
      height:'52px',
      width:'229px',
    },
    [theme.breakpoints.down(1068)]:{
      height:'52px',
      width:'60%',
    },
    [theme.breakpoints.down(769)]:{
      width: "56.5%"
    },
  },
  gridAudioContainer: {
    height: "100%"
  },
  audioControls: {
    width: "80%",
    border: "2px solid #00DAC9",
    borderRadius: "35px",
    
    [theme.breakpoints.down(769)]:{
      width: "90%"
    },
  },
  containerBack: {
    // backgroundColor: ({ backgroundColor }) => backgroundColor,
    backgroundColor: '#DC5EA6',
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "0 20px",
    boxSizing: "border-box",
    // "&:nth-of-type(1)": {
    //   backgroundColor: "#F78766",
    // },
    // "&:nth-of-type(2)": {
    //   backgroundColor: "#3CBE7D",
    // },
  },
  TituloLecturas: {
    margin: 0,
    // margin: "5px",
    width: "100%",

    maxHeight: "60px",
    font: "normal 18px lato",
    // textAlign: "start",
    letterSpacing: "0.67px",
    textAlign: "center",
    // font: "normal normal bold 30px/40px Lato",
    // letterSpacing: "0px",
    color: " #FFFFFF",
    // color: "#F27964",
    [theme.breakpoints.up("lg")]: {
      font: "normal 20px lato",
    },

    overflow: "hidden",
  },
}))

const ResourceCard = (props) => {
  const{ grade, resource } = props
  const classes = useStyles ()

  const [open, setOpen] = React.useState(false)
  const handleClose = () => {
    setOpen(false)
  }

  return (
      <>
      <Grid container justifyContent="center" onClick={()=> {setOpen(true)}} className={classes.imgs}>  
        <Grid item >
        
        </Grid>
        <Grid className={classes.containerBack}>
        <p className={classes.TituloLecturas} >Grado: {grade}</p>
          {/* <p className={classes.TituloTipoDocumento} >{num}</p> */}
      </Grid>
      </Grid>

      {/* {
        data &&  */}
        <Dialog open={open} onClose={() => {setOpen(false)}} classes={{paper: classes.MuiDialogContainer}}>
          <DialogTitle>
            <Grid container justifyContent="center" alignItems="center">
              <p>Práctica Grado: {grade}</p>
            </Grid>
          </DialogTitle>
          <DialogContent>
            <Grid container justifyContent="center" alignItems="center" className={classes.gridAudioContainer}>
              <audio controls autoPlay className={classes.audioControls}>
                <source src={resource} type="audio/mpeg"></source>
              </audio>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Grid container justifyContent="center" alignItems="center">
              <Button onClick={ handleClose } id="BotonVolver" className={classes.continueButton} >Volver</Button>
            </Grid>
          </DialogActions>
        </Dialog>
      {/* } */}
      </>

  );

};

export default ResourceCard;
