import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
    ContenedorLectura:{
        width:'502px',
                  [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
        '& .Escritorio':{
            display:'flex',
            [theme.breakpoints.down(1161)]:{
                display:'none'
            },
        },
        '& .Tablet':{
            display:'none',
            [theme.breakpoints.down(1161)]:{
                display:'flex'
            },
        }
    },
    ContainerTitulo:{
        margin:'0 0 34px 0',
        '& .Titulo':{
            letterSpacing: '0px',
            color: '#1DA698',
            margin:'0',
            textAlign:'center',
            font:'43px fredoka one',
            lineHeight:'52px',
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        position:'relative',
        '& .Lectura':{
            margin:'0',
            color:'#000',
            textAlign:'center',
            font:'18px lato',
            lineHeight :'28px',
            letterSpacing: '0.17px',
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .Imagen':{
            width:'66.53%',
        },  
        '& .Imagen2':{
            width:'100%',
        },  
      
    },

}));

const Los_disfraces_del_agua = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>

            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >
                        Los disfraces del agua
                    </h2>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container xs={12} justifyContent="center">
                        <p className="Lectura ">
                            Agua, sustancia primera,<br/>
                            fundamento de la vida,<br/>
                            cristal, diamante, bebida,<br/>
                            consuelo para cualquiera.<br/><br/>

                            Conservas siempre tu esencia,<br/>
                            a pesar de tus disfraces<br/>
                            y las máscaras fugaces<br/>
                            que dividen tu existencia.<br/><br/>

                            De olas y lluvia vestida,<br/>
                            tu presencia vuelve al mundo<br/>
                            un jardín verde y fecundo.<br/>
                            Tú nos alegras la vida.<br/><br/>
                        </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12} >
                        <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img2do/Sesion21R/1.png" alt="Tarjeta" />
                    </Grid>
                </Grid>

                <Grid container sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}  justifyContent='center'>
                        <p className="Lectura ">
                            Disminuyes el calor,<br/>
                            adelgazas hasta el viento<br/>
                            y de unos a otros momentos<br/>
                            eres un gas sin color.<br/><br/>

                            El frío de las alturas<br/>
                            congela tus finos dedos,<br/>
                            los vuelve de plata, hielos<br/>
                            sin posibles quebraduras.<br/><br/>

                            Y de pronto así regresas,<br/>
                            sólida, aquí a nuestras manos<br/>
                            desde lugares lejanos<br/>
                            con muchas nuevas promesas.<br/><br/>

                            Te purificas siempre<br/>
                            con tu cíclica figura.<br/>
                            Y cada día eres más pura,<br/>
                            dormitas en cualquier fuente.<br/><br/>

                            Esperas que te despierten<br/>
                            para de nuevo empezar<br/>
                            cristalina. Incierto azar<br/>
                            el que define tu suerte.<br/><br/>

                        </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12} >
                        <img className="Imagen2" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img2do/Sesion21R/2.png" alt="Tarjeta" />
                    </Grid>
                </Grid>
        </Grid>
    </Grid>
    );
}
 
export default Los_disfraces_del_agua;
