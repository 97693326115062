import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
    ContenedorLectura:{
        width:'502px',
                  [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
    },
    ContainerTitulo:{
        margin:'0 0 34px 0',
        '& .Titulo':{
            letterSpacing: '0px',
            color: '#FF2B2B',
            margin:'0 0 5px 0',
            textAlign:'center',
            font:'43px fredoka one',
            lineHeight:'52px',
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
        '& .Titulo2':{
            color: '#FF2B2B',
            margin:'0',
            textAlign:'center',
            font:'bold 26px lato',
            letterSpacing: '.24px',
            lineHeight:'28px',
            [theme.breakpoints.down(502)]:{
                font:'bold 22px lato',
                lineHeight:'24px',
            },
            [theme.breakpoints.down(350)]:{
                font:'bold 18px lato',
                lineHeight:'24px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        position:'relative',
        '& .Lectura':{
            margin:'0',
            color:'#000',
            font:'18px lato',
            lineHeight :'28px',
            letterSpacing: '0.17px',
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .Lectura2':{
            zIndex:1,
            margin:'0 0 36px 0',
            position:'relative',
            width:'100%',
        },
        '& .Titulo3':{
            color: '#00ADB6',
            margin:'0 0 47px 0',
            textAlign:'center',
            font:'bold 26px lato',
            letterSpacing: '.24px',
            lineHeight:'28px',
            [theme.breakpoints.down(502)]:{
                font:'bold 22px lato',
                lineHeight:'24px',
            },
            [theme.breakpoints.down(350)]:{
                font:'bold 18px lato',
                lineHeight:'24px',
            },
        },
        '& .ContainerImagen':{
            zIndex:0,
            width:'100%',
        }, 
        '& .Imagen':{
            width:'100%',
        } 

      
    }
}));

const Pablo_y_el_intraterrestre_Cap_6 = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>

            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                        <h2 className="Titulo">Pablo y el intraterrestre</h2>
                        <h3 className='Titulo2'>Fragmento 4 (capítulo 6)</h3>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                       <p className='Lectura '>
                            ―Es mi naturaleza ―dijo Ángel, riendo tímidamente, esquivando a Pablo con 
                            elegancia―. Me gusta hacer las cosas a mi manera. Si la rompemos a lo loco, se va a 
                            caer el azúcar y menos reactivos significarán menos resultados. Si me dejas hacerlo 
                            bien, combinando tu inteligencia y la mía, conseguiremos algo mejor. Eso le gustará a 
                            miss Laura ―al decir aquello, Ángel lanzó a Pablo una mirada cómplice. <br/><br/>
                            
                            ―Yo no soy un matadito como tú ―se quejó Pablo―. No me interesa caerle bien a 
                            los maestros.<br/><br/>
                            
                            ―No dije que fueras matadito. Dije que eres inteligente y creo que te gusta miss 
                            Laura, pero veo que no quieres que otros se den cuenta de ninguna de esas cosas. <br/><br/>
                            
                            Los ojos de Ángel sonreían luminosos. <br/><br/>
                            
                            ―¿Ah, sí? ―agregó Pablo, sintiéndose acorralado―. Pues estar con los Calaveras es 
                            parte de mi naturaleza, nos gustan las mismas cosas. <br/><br/>
                            
                            ―Tú no eres como ellos ―expresó Ángel con certeza mientras vaciaba el contenido 
                            del sobre perfectamente abierto en la botella. Luego se la dio a Pablo para que la 
                            agitara―. No creo eso que dicen, que dejaste ciego a un niño en tu otra escuela<br/>                             
                            ―dijo como si estuviera declarando en un juicio―. No eres un <em>bully</em>, se te nota. <br/><br/>
                            
                            La palabra <em>bully</em> despertó en el fondo de Pablo una rabia antigua, que entró a la 
                            Máquina Comeenojos como un vendaval. Los motores comenzaron a accionarse; el 
                            tono rojo vivo, color de cereza del río de furia en Pablo, comenzó a oscurecerse, 
                            volviéndose sombrío y viscoso. Oleadas de rabia viajaron en segundos del estómago 
                            al corazón de Pablo restaurando en un instante toda la energía que Brimlog había 
                            perdido con las risas de Pablo. <br/><br/>
                            
                            ―Mis amigos no son <em>bullies</em> ―decretó Pablo tomando la botella para comenzar a 
                            agitarla con todas sus fuerzas―. <br/><br/>
                            
                            Tú no los conoces. ―Tú no los conoces. A Juan Carlos le gusta jugar con las personas ―rebatió Ángel―. 
                            No va a pegarme, va a hacer que tú lo hagas. Te lo aseguro. <br/><br/>
                            
                            —¿Oyó lo que me dijo Juan Carlos? —se cuestionó a sí mismo Pablo. <br/><br/>
                            
                            —Negativo —respondió Brimlog—. Él ya no estaba en el perímetro cuando ocurrió 
                            esa conversación. <br/><br/>
                            
                            ―Tú acabas de llegar a esta escuela; yo llevo aquí toda mi vida ―continuó Ángel 
                            mientras terminaba de abrir el sobre de levadura con una calma absoluta. Se lo estiró 
                            a Pablo para que agregara su contenido a una de las botellas. Después tomó la 
                            botella que Pablo había terminado de agitar―. Si vas a pegarme, pégame, no te estoy 
                            diciendo nada de esto para salvarme. Sólo quería estar seguro de que lo hacías 
                            porque tú querías, no como el títere de otros. Por cierto, a mí sí me caes bien, Pablo. 
                            Después de que fuimos equipo en Español, pensé que podríamos ser amigos. <br/><br/>
                            
                            Ángel se quedó callado. No parecía estar mintiendo. Aceptaba que lo golpearían con 
                            una valentía indiferente, casi como el mismo Pablo había aceptado ya que estallaría 
                            tarde o temprano. <br/><br/>
                            
                            Pablo sintió que las palabras <em>el títere de otros</em> le decían más que lo literal. En silencio, 
                            terminó de agitar la botella con levadura y se la dio a Ángel. Él, con sus manos finas, 
                            puso los globos como tapas en ambas botellas. <br/><br/>
                            
                            ―Hoy vamos a aprender sobre la fermentación ―dijo miss Laura de pronto―. Las 
                            levaduras son organismos pequeñísimos, no podemos verlas a simple vista. Por eso 
                            las llamamos microorganismos. <br/><br/>
                            
                            ―¿Son parásitos? ―preguntó Stefanie, una niña pecosa que siempre quería hablar. <br/><br/>
                            
                            ―Algunos microorganismos, sí ―confirmó miss Laura―, y muy, muy peligrosos. 
                            Muchos científicos estamos convencidos de que el peligro más grande que 
                            enfrentará la humanidad será algo microscópico.
                       </p>                   
                    </Grid>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12} >
                    <p className='Lectura Lectura2'>
                    —Y tienen razón, ¿eh, Pablo? —bromeó 
                    Brimlog, quien comenzaba a disfrutar 
                    del humor humano. Pero Pablo no 
                    contestó a su chiste. <br/><br/>
                    
                    ―Las levaduras no son dañinas 
                    ―continuó miss Laura―. Al 
                    fermentarse, transforman el azúcar en 
                    alcohol etílico, dióxido de carbono y 
                    calor. <br/><br/>
                    
                    —¡Dióxido de carbono! Los humanos 
                    están contaminando la Tierra con esa 
                    cosa horrible.
                    </p>
                    </Grid>
                    <Grid container justifyContent='center' className='ContainerImagen'>
                        <img className="Imagen " src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion29/1.png" alt="Tarjeta" />
                    </Grid>
                </Grid>
        </Grid>
    </Grid>
    );
}
 
export default Pablo_y_el_intraterrestre_Cap_6;
