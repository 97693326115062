import React               from "react";
import {Grid, makeStyles}                 from '@material-ui/core';

const useStyles = makeStyles(theme =>({
  questionContainer:{
    width: "100%",
    height:"100%",
  },
  reading: {
    width: "80%",
    margin:"10px 0 10px 0 ",
    font: '22px lato',
    letterSpacing: '0.21px',
    [theme.breakpoints.down(1400)]:{
      font: " 18px lato",
      width: "95%",
    },
    [theme.breakpoints.down(1310)]:{
      font: "500 16px lato",

    },
    [theme.breakpoints.down(925)]:{
      font: "500 14px lato",

    },
    [theme.breakpoints.down(426)]:{
      font: "500 12px lato",
 
    }, 
  },
  ovalColorRed: {
    background: "red",
    border:  "solid 2px black",
    borderRadius:"90px",
    height: "40px",
    font: '22px lato',
    color: "white",
    [theme.breakpoints.down(1400)]:{
      font: " 18px lato",
    },
    [theme.breakpoints.down(1310)]:{
      font: "500 16px lato",
    },
    [theme.breakpoints.down(925)]:{
      font: "500 14px lato",
    },
    [theme.breakpoints.down(426)]:{
      font: "500 12px lato",
    }, 
  },
  ovalColorBlue: {
    background: "blue",
    border:  "solid 2px black",
    borderRadius:"90px",
    height: "40px",
    font: '22px lato',
    color: "white",
    [theme.breakpoints.down(1400)]:{
      font: " 18px lato",
    },
    [theme.breakpoints.down(1310)]:{
      font: "500 16px lato",
    },
    [theme.breakpoints.down(925)]:{
      font: "500 14px lato",
    },
    [theme.breakpoints.down(426)]:{
      font: "500 12px lato",
    }, 
  },
  ovalColorYellow: {
    background: "yellow",
    border:  "solid 2px black",
    borderRadius:"90px",
    height: "40px",
    font: '22px lato',
    [theme.breakpoints.down(1400)]:{
      font: " 18px lato",
    },
    [theme.breakpoints.down(1310)]:{
      font: "500 16px lato",
    },
    [theme.breakpoints.down(925)]:{
      font: "500 14px lato",
    },
    [theme.breakpoints.down(426)]:{
      font: "500 12px lato",
    }, 
  }
}));

const QuestionTable = (props) => {
  const classes = useStyles()

  return(
    <Grid container className={classes.questionContainer}>
      <p
        className={classes.reading}
      >
        4. Relaciona el color con la sal que necesita.
      </p>
      <br/>
      <Grid container direction="row" justifyContent="space-around" alignItems="center">
        <Grid container item xs={3} justifyContent="center" alignItems="center" className={classes.ovalColorRed}>
          Rojo
        </Grid>
        <Grid container item xs={3} justifyContent="center" alignItems="center" className={classes.ovalColorBlue}>
          Azul
        </Grid>
        <Grid container item xs={3} justifyContent="center" alignItems="center" className={classes.ovalColorYellow}>
          Amarillo
        </Grid>
      </Grid>
      <p
        className={classes.reading}
      >
        a. sodio<br/>
        b. cobre<br/>
        c. estroncio<br/>
      </p>
      {/* <table className={classes.table}>
        <thead>
          <th style={{width: "50%", border: '1px solid black',}}><i>MATERIALES</i></th>
          <th style={{width: "50%", border: '1px solid black',}}><i>DISCIPLINA ARTÍSTICA</i></th>
        </thead>
        <tbody>
          <tr>
            <td className={classes.tdTable}>
              <p>
                piedra
              </p>
            </td>
            <td className={classes.tdTable}>
              <p>
              a. orfebrería
              </p>
            </td>
          </tr>
          <tr>
            <td className={classes.tdTable}>
              <p>
                metal
              </p>
            </td>
            <td className={classes.tdTable}>
              <p>
                b. escultura
              </p>
            </td>
          </tr>
          <tr>
            <td className={classes.tdTable}>
              <p>
                papel
              </p>
            </td>
            <td className={classes.tdTable}>
              <p>
                c. grabado
              </p>
            </td>
          </tr>
          <tr>
            <td className={classes.tdTable}>
              <p>
                madera
              </p>
            </td>
            <td className={classes.tdTable}>
              <p>
                d. dibujo
              </p>
            </td>
          </tr>
        </tbody>
      </table> */}
    </Grid>
  )
}

export default QuestionTable;
