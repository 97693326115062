import React from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core";
import NavBar from "./NavBar";

import MenuIzquierdo from "./MenuIzquierdo";

const useStyles = makeStyles((theme) => ({
  grid1: {
    zIndex: "3",
  },
  grid2: {
    zIndex: "2",
  },
  grid3: {
    boxSizing: "border-box",
    zIndex: "1",
    width: "100%",
    display: "flex",
    paddingLeft: "0px",
    minHeight: "calc(100vh - 80px)",
    marginTop: "80px",
    [theme.breakpoints.up("sm")]: {
      marginTop: "0px",
    },
    [theme.breakpoints.up("lg")]: {
      paddingLeft: "3%",
    },
  },
}));

function ContainerAdminPearson({ children }) {
  const classes = useStyles();
  return (
    <Grid container>
      <Grid item className={classes.grid1} sm={12}>
        <NavBar />
      </Grid>
      <Grid item className={classes.grid2} sm={1}>
        <MenuIzquierdo />
      </Grid>
      <Grid item className={classes.grid3} sm={12}>
        {children}
      </Grid>
    </Grid>
  );
}

export default ContainerAdminPearson;
