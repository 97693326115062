import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
     Contenedor:{
        background:'#E9FBF9',
        width:'100vw',
        overflowX:'clip',
        [theme.breakpoints.down(1161)]:{
            background:'none',
           overflowX:'unset',
        },
        [theme.breakpoints.down(600)]:{
            marginTop: '79.51px',
        }
    },
  ContainerInfoLectura: {
    position: "relative",
    height: "86px",
    [theme.breakpoints.down(502)]:{
        height: "43px",
    },
    background: "#76D4C3",
    "& .BarraInfoLectura": {
      height: "86px",
      background: `Url(https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Barras/Azul.png)`,
        [theme.breakpoints.down(502)]:{
            height: "43px",
        },
    },
    "& .InfoLectura": {
      position: "absolute",
      height: "86px",
      width: "219px",
      boxSizing: "border-box",
      padding: "17.2px 0",
      background: "#FAFAFA",
      borderRadius: "50px",
      boxShadow: "2px 2px 2px 2px #00000029",
      zIndex: "4",
        [theme.breakpoints.down(502)]:{
            height: "43px",
            width: "109px",
            padding: "8.6px 0",
            borderRadius: "25px",
        },
    },
    "& .InfoLectura:before": {
      position: "absolute",
      display: "flex",
      boxSizing: "border-box",
      padding: "0 0 0 10px",
      justifyContent: "center",
      alignItems: "center",
      height: "39.88px",
      width: "39.88px",
      borderRadius: "50%",
      content: '""',
      boxShadow: "3px 0px .5px 0px #00000029",
      background: "#FAFAFA",
      left: "90px",
      top: "65px",
      transform: "rotate(0.25turn)",
      [theme.breakpoints.down(502)]:{
        left: "45px",
        top: "30px",
        height: "20.88px",
        width: "20.88px",
        },
    },
    "& .InfoLectura:after": {
      content: '""',
      height: "15px",
      width: "15px",
      borderTop: "2px solid #20A698",
      borderLeft: "2px solid #20A698",
      position: "absolute",
      bottom: "-10px",
      left: "Calc(50% - 8px)",
      transform: "rotate(0.625turn)",
      [theme.breakpoints.down(502)]:{
        height: "10px",
        width: "10px",
        left: "Calc(50% - 4.5px)",
        bottom: "-3px",
        borderTop: "1px solid #20A698",
        borderLeft: "1px solid #20A698",
        },
    },
    "& .Sesion": {
      borderLeft: "2px solid #D8D8D8",
    },
    "& .Dato": {
      margin: "0 0 5px 0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#20A698",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        margin: "0 0 2.5px 0",
        },
    },
    "& .Caracter": {
      margin: "0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#000000",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        },
    },   },
    ContenedorLectura:{
        background:'#fff',
        width:'1160px',
        height:'100%',
        padding:'60px 168px 35px ',
        [theme.breakpoints.down(1161)]:{
            width:'502px',
            padding:'60px 0 0 0',
          },
        [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
        '& .ContainerDerechos':{
            bottom:'80px',
            [theme.breakpoints.down(1161)]:{
                display:'none',
            },
        },
        '& .Derechos':{
            textAlign:"center",
            margin:"0px",
            font:" 15px lato",
            color:"#717476",
        },
        '& .Escritorio':{
            display:'flex',
            [theme.breakpoints.down(1161)]:{
                display:'none'
            },
        },
        '& .Tablet':{
            display:'none',
            [theme.breakpoints.down(1161)]:{
                display:'flex'
            },
        },
        '& .Escritorio2':{
            display:'block',
            [theme.breakpoints.down(1161)]:{
                display:'none'
            },
        },
        '& .Tablet2':{
            display:'none',
            [theme.breakpoints.down(1161)]:{
                display:'block'
            },
        }
    },
    ContainerTitulo:{
        margin:'0 0 34px 0',
        [theme.breakpoints.down(1161)]:{
            margin:'0 0 34px 0',
        },
        '& .Titulo':{
            font:'48px fredoka one',
            letterSpacing: '0px',
            lineHeight:'58px',
            color: '#FF0000',
            margin:'0',
            textAlign:'center',
            [theme.breakpoints.down(1161)]:{
                font:'43px fredoka one',
                lineHeight:'52px',
            },
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            lineHeight : '30px',
            margin:'0 0 36px 0',
            font:'22px lato',
            color:'#000',
            letterSpacing: '0.21px',
            [theme.breakpoints.down(1161)]:{
                font:'18px lato',
                lineHeight :'28px',
                letterSpacing: '0.17px',
            },
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .Lectura2':{
            margin:'0',
        },
        '& .SubTitulo':{
            font:'bold 30px lato',
            color:'#0CB5D6',
            letterSpacing:'.28px',
            lineHeight:'30px',
            margin:'0',
            [theme.breakpoints.down(1161)]:{
                lineHeight :'28px',
                font:'bold 26px lato',
                letterSpacing: '0.24px',
            },
            [theme.breakpoints.down(502)]:{
                font:'bold 18px lato',
                lineHeight :'24px',
                letterSpacing: '0.21px',
            },
        },
        '& .SubTitulo2':{
            font:'bold 26px lato',
            color:'#F6644B',
            letterSpacing:'.24px',
            lineHeight:'28px',
            margin:'0',
            [theme.breakpoints.down(1161)]:{
                font:'bold 22px lato',
                lineHeight :'24px',
                letterSpacing: '0.21px',
            },
            [theme.breakpoints.down(502)]:{
                font:'bold 18px lato',
                lineHeight :'20px',
                letterSpacing: '0.16px',
            },
        },
        '& .TextImg':{
            font:'20px lato',
            color:'#717476',
            letterSpacing:'.19px',
            lineHeight:'30px',
            margin:'19px 0 0 0',
            [theme.breakpoints.down(1161)]:{
                font:' 16px lato',
                lineHeight :'25px',
                letterSpacing: '0.15px',
                margin:'24px 0 0 0',

            },
            [theme.breakpoints.down(502)]:{
                font:' 11px lato',
                lineHeight :'14px',
                letterSpacing: '0.10px',
            },
        },
        '& .TextImg2':{
           margin:'32px 0 0 0',
           [theme.breakpoints.down(1161)]:{
            margin:'24px 0 0 0',
        },

        },
        '& .BarraRoja':{
            position:'relative',
            margin:'0 0 36px 0',

        },
        '& .BarraRoja2':{
            position:'relative',
        },
        '& .Pos':{
            position:'absolute',
            top:'53px',
            left:'39px',
            [theme.breakpoints.down(1161)]:{
                width:'85.25%',
                top:'27.46%',
            },
            [theme.breakpoints.down(502)]:{
                top:'18%',
                width:'88%',
                left:'25px',

            },
        },
        '& .Pos2':{
            position:'absolute',
            top:'53px',
            left:'39px',
            [theme.breakpoints.down(1161)]:{
                width:'85.25%',
                top:'27.46%',
            },
            [theme.breakpoints.down(502)]:{
                left:'25px',
            },
        },

        '& .Imagen':{
            [theme.breakpoints.down(1161)]:{
                width:'100%',
            },
        },  
        '& .Imagen2':{
            height:'302px',
            width:'343px',
        }, 
        '& .Imagen2-1':{
            height:'169px',
            width:'100%',
            [theme.breakpoints.down(1161)]:{
                height:'193px',
                width:'100%',
            },
        }, 
        '& .Imagen2-2':{
            height:'434px',
            width:'491px',
            [theme.breakpoints.down(1161)]:{
                width:'100%',
            },
        }, 
        '& .img':{
            height:'302px',
            width:'343px',
        }, 
        '& .Especial2':{
            margin:'0 0 36px 0 '
        },
        '& .Especial3':{
            margin:'0 0 36px 0 '
        },
      
    },
}));

const El_aire_los_seres_vivos_y_la_contaminación = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
             <Grid container item justifyContent="center" className={classes.ContainerInfoLectura}>
               <Grid container className="BarraInfoLectura"></Grid>
               <Grid container item className="InfoLectura">
                    <Grid container item direction="column" xs={6} justifyContent="center">
                        <p className="Dato">Grado</p>
                        <p className="Caracter">5</p>
                    </Grid>
                    <Grid container item direction="column" xs={6} justifyContent="center" className="Sesion">
                        <p className="Dato">Sesión</p>
                        <p className="Caracter">17</p>
                    </Grid>
               </Grid>
            </Grid>

            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                        <h2 className="Titulo ">El aire, los seres vivos <br/>y la contaminación</h2>

                </Grid>
                <Grid container item sm={12} className={`${classes.Parrafo1} ${classes.Especial}`} justifyContent="center">
                    <Grid container sm={12}>
                       <p className='Lectura Lectura2'>
                        La atmósfera es la capa gaseosa de la Tierra. Quizá sabes que está formada por una mezcla de gases
                         (como nitrógeno, oxígeno y dióxido de carbono), pero ¿sabías que no siempre ha estado compuesta 
                         por los mismos gases? Por ejemplo, el oxígeno no siempre existió en el planeta, en la forma y 
                         cantidad en que lo necesitamos los seres vivos. Entonces, ¿cómo era la atmósfera? ¿Qué la hizo cambiar?<span className="Tablet"><br/><br/></span> 

                         <h3 className='SubTitulo Tablet'>Historia de la Tierra</h3>

                       </p>                   
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container className='Escritorio' >

                        <Grid container item xs={5} alignContent='flex-end'>
                            <p className='Lectura Lectura2'>
                            <h3 className='SubTitulo'>Historia de la Tierra</h3><br/>

                            La Tierra se formó hace unos 4 <br/>
                            500 millones de años. Al <br/>
                            principio era sólo una masa de <br/>
                            gases, con temperaturas tan <br/>
                            elevadas que las rocas eran <br/>
                            líquidas, como la lava. Por eso <br/>
                            los científicos han analizado <br/>
                            los volcanes cuando han <br/>
                            querido darse una idea de las <br/>
                            condiciones originales de <br/>
                            nuestro planeta.
                            </p>
                        </Grid>
                        <Grid container item xs={7} justifyContent='center'>
                            <img className='img' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion17/4.png" alt="Tarjeta" />
                            <p className='TextImg'>Ésta es la Tierra en el periodo hádico, hace 45<br></br> millones de años. Muy distinta, ¿no?</p> 
                        </Grid>
                        
                    </Grid>

                    <Grid container className='Tablet' >
                        <Grid container item xs={12} justifyContent='center' className="Especial2">
                            <img  src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion17/1R.png" alt="Tarjeta" />
                            <p className='TextImg'>Ésta es la Tierra en el periodo hádico, hace 45 millones de años. Muy distinta, ¿no?</p> 
                        </Grid>
                        
                        <Grid container item xs={12} alignContent='flex-end'>
                            <p className='Lectura Lectura2'>
                            La Tierra se formó hace unos 4 
                            500 millones de años. Al 
                            principio era sólo una masa de 
                            gases, con temperaturas tan 
                            elevadas que las rocas eran 
                            líquidas, como la lava. Por eso 
                            los científicos han analizado 
                            los volcanes cuando han 
                            querido darse una idea de las 
                            condiciones originales de 
                            nuestro planeta.
                            </p>
                        </Grid>
                    </Grid>

                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container className='BarraRoja'>
                        <img className='Imagen2-1 Escritorio' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion17/6.png" alt="Tarjeta" />
                        <p className='Pos Lectura Lectura2  Escritorio'>
                            Los vulcanólogos llevan tanques de oxígeno, porque cerca de los cráteres es <br/>
                            casi imposible respirar. Los trajes especiales que usan los protegen de los <br/>
                            vapores de ácido sulfúrico, que producen quemaduras.
                        </p>
                        <img className='Tablet Imagen' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion17/3R.png" alt="Tarjeta" />
                        <p className='Pos Lectura Lectura2 Tablet'>
                            Los vulcanólogos llevan tanques de oxígeno, porque  
                            cerca de los cráteres es casi imposible respirar. Los  
                            trajes especiales que usan los protegen de los vapores 
                            de ácido sulfúrico, que producen quemaduras.
                        </p>
                        
                    </Grid>
                    <Grid container justifyContent='center'>
                        <img className='Imagen' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion17/1.png" alt="Tarjeta" />
                        <p className='TextImg'>¿A qué se dedica un vulcanólogo? ¿Qué datos recopilará para la investigación?</p>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container >
                        <p className='Lectura '>
                            <h3 className='SubTitulo2'>¿Cómo apareció el oxígeno?</h3><br/>
            
                            El oxígeno no existía como un gas independiente, sino que estaba unido 
                            con otros elementos a nivel molecular: había vapor de agua (compuesto de 
                            oxígeno e hidrógeno) y óxidos de carbono, gases densos y asfixiantes que 
                            contienen oxígeno en su composición, aunque no se parecen nada al oxígeno 
                            que respiramos. <br/><br/>
                            
                            Casi ningún organismo podría haber sobrevivido sin oxígeno como gas independiente.
                            Pero, con el tiempo, la temperatura del planeta bajó y el agua se volvió líquida. 
                            Fue allí donde surgieron las grandes vanguardistas de la vida: las algas 
                            microscópicas. Mientras ellas fabricaban sus alimentos con fotosíntesis, 
                            iban produciendo como desecho el oxígeno que necesitarían todos los demás 
                            organismos.
                        </p>
                    </Grid>

                    <Grid container justifyContent='center' className='BarraRoja2'>
                        <img className='Escritorio' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion17/6.png" alt="Tarjeta" />
                        <p className='Pos2 Lectura Lectura2 Escritorio2'>
                            ¿Qué significa <em>vanguardista</em>? ¿Por qué usamos esta palabra para referirnos a <br/>
                            las algas? ¡Te invitamos a investigarlo!</p>

                        <img className='Tablet Imagen' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion17/2R.png" alt="Tarjeta" />
                        <p className='Pos2 Lectura Lectura2 Tablet2'>
                            ¿Qué significa <em>vanguardista</em>? ¿Por qué usamos esta 
                            palabra para referirnos a las algas? ¡Te invitamos a
                            investigarlo!</p>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container >
                        <p className='Lectura '>
                            <h3 className='SubTitulo'>¿De qué se compone el aire?</h3><br/>
                            
                            El aire actual contiene sobre todo nitrógeno y oxígeno. El nitrógeno es más 
                            abundante. En el cuerpo humano está en las proteínas que forman los músculos. 
                            El oxígeno, por otro lado, es necesario para que todas las plantas y los animales, además
                            de ciertos hongos y bacterias, obtengan energía de los alimentos. Sólo algunas 
                            bacterias, hongos y otros seres microscópicos pueden vivir sin él. <br/><br/>
                             
                            Otros gases (dióxido de carbono, vapor de agua, óxidos de nitrógeno, neón y helio) se 
                            presentan en cantidades menores, aunque todos influyen en los seres vivos y en el 
                            clima.<br/><br/>
                             
                            <h3 className='SubTitulo'>¿Qué pasa si se cambia la composición del aire?</h3><br/>
                            
                            Una actividad humana <em>contamina</em> cuando altera la composición del aire, introduciendo 
                            gases nuevos o cambiando la cantidad de los gases existentes. Los gases contaminantes, 
                            mezclados con humo y polvo, forman el <em>smog</em>, que ocasiona desde ligeras alergias hasta 
                            enfermedades respiratorias mortales.
                        </p>
                    </Grid>

                    <Grid container justifyContent='center'>

                        <img className='Imagen' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion17/2.png" alt="Tarjeta" />
                        <p className='TextImg'>¿Has visto un día así en el lugar en el que vives?</p>

                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container >
                        <p className='Lectura Lectura2'>
                            Además, estos gases provocan problemas ambientales. El dióxido de carbono, tan 
                            necesario para la fotosíntesis, es también el principal responsable del calentamiento 
                            global. Todos los días escuchamos los efectos del cambio climático. Te recordamos 
                            sólo tres: los polos se están derritiendo, los huracanes cada vez son más intensos y 
                            las lluvias ácidas corroen cultivos y ecosistemas enteros.<br/><br/>
                            
                            <h3 className='SubTitulo'>¿Cuáles son las fuentes de contaminación del aire?</h3><br/>
                            
                            Los gases que causan efectos tan graves en la salud y el clima vienen de fábricas, 
                            automóviles, incendios, fogatas, quema de llantas y de basura.
                        </p>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container className='Escritorio' >

                        <Grid container item xs={5} >
                            <p className='Lectura Lectura2'>
                                Debemos evitar la <br/>
                                contaminación del aire  <br/>
                                disminuyendo el uso de autos,  <br/>
                                previniendo los incendios  <br/>
                                forestales, evitando la quema de  <br/>
                                basura y exigiéndoles a las  <br/>
                                empresas formas responsables  <br/>
                                de producción. Cuando el  <br/>
                                oxígeno apareció hace unos 2  <br/>
                                400 millones de años,  <br/>
                                probablemente ocasionó la  <br/>
                                muerte de muchas bacterias que  <br/>
                                no estaban adaptadas a su  <br/>
                                presencia. De la misma forma,  <br/>
                                los seres humanos no estamos  <br/>
                                adaptados para vivir en el  <br/>
                                planeta que estamos  <br/>
                                produciendo.
                            </p>
                        </Grid>
                        <Grid container item xs={7} justifyContent='center' alignContent='flex-end'>
                            <img className='Imagen2-2' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion17/3.png" alt="Tarjeta" />
                            <p className='TextImg TextImg2'>¡Cuánto humo despide la quema de llantas y se queda en el aire que respiramos!</p> 
                        </Grid>
                        
                    </Grid>

                    <Grid container className='Tablet' >
                        <Grid container item xs={12} justifyContent='center' className='Especial3' >
                            <img className='Imagen' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion17/3.png" alt="Tarjeta" />
                            <p className='TextImg '>Ésta es la Tierra en el periodo hádico, hace 45 millones de años. Muy distinta, ¿no?</p> 
                        </Grid>
                        
                        <Grid container item xs={12} alignContent='flex-end'>
                            <p className='Lectura Lectura2'>
                            Debemos evitar la contaminación del aire disminuyendo el uso de autos, previniendo los incendios forestales, evitando la quema de basura y exigiéndoles a las empresas formas responsables de producción. Cuando el oxígeno apareció hace unos 2 400 millones de años, probablemente ocasionó la muerte de muchas bacterias que no estaban adaptadas a su presencia. De la misma forma, los seres humanos no estamos adaptados para vivir en el planeta que estamos produciendo.
                            </p>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container item xs={12} justifyContent="center" className="ContainerDerechos">
                <p className="Derechos">
                    © 2022. Todos los derechos reservados, Pearson, Circuito de lectores.
                </p>                
            </Grid>
        </Grid>
    </Grid>
    );
}
 
export default El_aire_los_seres_vivos_y_la_contaminación;
