import React from "react";
import { styled } from "@mui/system";
import { FormControl, InputLabel, makeStyles, MenuItem, Select } from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  select: {
    width: "100%",
    // maxWidth: "560px",
    "&::after": {
      content: "▴",
      position: "absolute",
      color: "rgb(238, 205, 103)",
      fontSize: "30px",
      lineHeight: "20px",
      textTransform: "capitalize",
    },
  },
  menuItem: {
    textTransform: "capitalize",
  },
}));

const SelectCustimized = styled(Select)(({ theme }) => ({
  "& .MuiSelect-icon": {
    color: "#EECD67",
  },
  "& .MuiSelect-select.MuiSelect-select": {
    padding: "11.5px",
    paddingLeft: "27px",
    paddingRight: "27px",
    backgroundColor: "#f0f0f0",
    // paddingLeft: "27px",
    borderRadius: "61px",
    fontSize: "16px",
    boxSizing: "border-box",
    textTransform: "capitalize",

    "& :focus": {
      backgroundColor: "transparent",
    },
  },
}));
const FormControlCustimized = styled(FormControl)(({ theme }) => ({
  marginTop: "15px",
  "& .MuiInputLabel-formControl": {
    transform: "translate(0px, 0px) scale(1)",
    left: "0px",
    top: "-10px",
    color: "#000",
    fontFamily: "Lato, sans-serif",
    fontWeight: "bold",
  },
  "& .MuiFormLabel-root.Mui-focused": {
    transform: "translate(0px, -0px) scale(1)",
    top: "-10px",
    color: "#000",
    fontFamily: "Lato, sans-serif",
    fontWeight: "bold",
  },
}));

const SelectTipo = ({
  list = [],
  label,
  handleForm,
  formData,
  value,
  fieldName,
  disabled,
  type,
}) => {
  const classes = useStyles();
  return (
    <FormControlCustimized fullWidth>
      <InputLabel htmlFor={`demo-simple-select-${fieldName}`}>{label}</InputLabel>
      <SelectCustimized
        id={`demo-simple-select-${fieldName}`}
        value={formData[fieldName] || value || ""}
        // defaultValue={defaultValue}
        label={label}
        onChange={handleForm}
        className={classes.select}
        name={fieldName}
        disableUnderline
        disabled={disabled}
      >
        {type === "codigo" &&
          list.map((item, index) => (
            <MenuItem value={item?.id_tipo_codigo} key={index} className={classes.menuItem}>
              {item?.nombre}
            </MenuItem>
          ))}
        {type === "uso" &&
          list.map((item, index) => (
            <MenuItem value={item?.id_tipo_uso} key={index} className={classes.menuItem}>
              {item?.nombre}
            </MenuItem>
          ))}
        {type === "rol" &&
          list.map((item, index) => (
            <MenuItem value={item?.id_rol} key={index} className={classes.menuItem}>
              {item?.nombre}
            </MenuItem>
          ))}
      </SelectCustimized>
    </FormControlCustimized>
  );
};

export default SelectTipo;
