import React               from "react";
import {Grid, makeStyles}                 from '@material-ui/core';

const useStyles = makeStyles(theme =>({
  questionContainer:{
    width: "100%",
    height:"100%",
  },
  reading: {
    width: "98%",
    font: "22px lato",
    letterSpacing:'0',
    margin:"14px 0 14px 0",
    [theme.breakpoints.down(1370)]:{
      font: "500 16px lato",
      width: "90%",
      margin:"10px 0 10px 0"
    },
    [theme.breakpoints.down(1310)]:{
      font: "500 16px lato",
      // width: "80%",
      margin:"10px 0 10px 0"
    },
    [theme.breakpoints.down(925)]:{
      font: "500 14px lato",
      // width: "70%",
      margin:"6px 0 6px 0"
    },
    [theme.breakpoints.down(426)]:{
      font: "500 12px lato",
      // width: "70%",
      margin:"6px 0 6px 5%"
    }, 
  },
  table: {
    border: '1px solid black',
    width: "100%",
    font: '22px lato',
    [theme.breakpoints.down(1400)]:{
      font: " 18px lato",
    },
    [theme.breakpoints.down(1370)]:{
      font: "16px lato",
      width: "90%",
      // margin:"10px 0 10px 0"
    },
    [theme.breakpoints.down(1310)]:{
      font: "16px lato",
      // margin:"10px 0 10px 0"
    },
    [theme.breakpoints.down(925)]:{
      font: "14px lato",
      marginLeft: '10px',
      // width: "70%",
      // margin:"6px 0 6px 0"
    },
    [theme.breakpoints.down(426)]:{
      font: "12px lato",
      // width: "70%",
      // margin:"6px 0 6px 5%"
    }, 
  },
  tdTable: {
    padding: "0 1%",
    border: 'black 1px solid'
  }
}));

const QuestionTable = (props) => {
  const classes = useStyles()

  return(
    <Grid container className={classes.questionContainer}>
      <p
        className={classes.reading}
      >
       9. Relaciona los aspectos que se mencionan en la columna de la 
       izquierda con los contenidos que aparecen en la columna de la derecha:
      </p>
      <br/><br/>
      <table className={classes.table}>
        <thead>
          {/* <th style={{width: "50%",}}><b>Actividades</b></th>
          <th style={{width: "50%",}}><b>Uso de las matemáticas</b></th> */}
        </thead>
        <tbody>
          <tr>
            <td className={classes.tdTable}>
              <p className={classes.reading}>
                1. Seguridad alimentaria
              </p>
            </td>
            <td className={classes.tdTable}>
              <p className={classes.reading}>
              a. Su producción no requiere una gran cantidad de recursos y 
              además es rica en proteína vegetal, contiene un balance adecuado de carbohidratos, grasas, vitaminas y minerales.
              </p>
            </td>
          </tr>
          <tr>
            <td className={classes.tdTable}>
              <p className={classes.reading}>
                2. Arroz, frijol rojo y negro, cacahuate, guisantes, arroz japonés, lentejas, semilla de lino dorado y soya
              </p>
            </td>
            <td className={classes.tdTable}>
              <p className={classes.reading}>
                b. Acceso físico, social y económico a alimentos suficientes, inocuos y nutritivos que te permiten llevar una vida sana.
              </p>
            </td>
          </tr>
          <tr>
            <td className={classes.tdTable}>
              <p className={classes.reading}>
                3. Según el Mapa del Hambre de 2019
              </p>
            </td>
            <td className={classes.tdTable}>
              <p className={classes.reading}>
                c. Su producción ha representado una gran contribución a la alimentación mundial.
              </p>
            </td>
          </tr>
          <tr>
            <td className={classes.tdTable}>
              <p className={classes.reading}>
                4. La quinua
              </p>
            </td>
            <td className={classes.tdTable}>
              <p className={classes.reading}>
                d. 821 millones de personas en el mundo la padecen.
              </p>
            </td>
          </tr>
        </tbody>
      </table>
    </Grid>
  )

}

export default QuestionTable;
