import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
    ContenedorLectura:{
        width:'502px',
                  [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
    },
    ContainerTitulo:{
        margin:'0 0 34px 0',
        '& .Titulo':{
            letterSpacing: '0px',
            color: '#FF0000',
            margin:'0',
            textAlign:'center',
            font:'43px fredoka one',
            lineHeight:'52px',
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            margin:'0 0 36px 0',
            color:'#000',
            font:'18px lato',
            lineHeight :'28px',
            letterSpacing: '0.17px',
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .TextImg':{
            color:'#717476',
            margin:'20px 0 0 0',
            font:' 16px lato',
            lineHeight :'25px',
            letterSpacing: '0.15px',
            [theme.breakpoints.down(502)]:{
                font:' 11px lato',
                lineHeight :'14px',
                letterSpacing: '0.10px',
            },
        },
        '& .Esp':{
            margin:'0',
        }, 

        '& .Imagen':{
            width:'100%',
        }, 
    }
}));

const Las_abuelas_de_la_Plaza_de_Mayo = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >
                        Las abuelas<br/> de la Plaza de Mayo               
                    </h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                      <p className="Lectura">
                        Esto me lo relató mamá. Es la historia de mi <em>bisabuela</em> (es decir, la abuela de mamá). 
                        Se trata de un movimiento social que ella y otros cientos de mujeres argentinas 
                        crearon para encontrar a sus hijos y nietos desaparecidos a manos de un gobierno 
                        dictatorial.<br/><br/>
                        
                        Mi mamá me explicó que los <em>gobiernos dictatoriales</em> son aquellos que no les permiten a 
                        los ciudadanos pensar ni actuar por sí mismos. En cambio, les <em>dictan</em> comportamientos 
                        e ideas. Suelen ser violentos con quienes opinan distinto, como mi abuela y todas 
                        esas personas que fueron detenidas y desaparecidas. <br/><br/>
                        
                        Mamá me contó que la Plaza de Mayo (ubicada en Buenos Aires, Argentina) es un 
                        lugar simbólico y de mucho interés para nuestra memoria y para los derechos 
                        humanos. Durante la dictadura militar de 1976 a 1983 hubo un gran número de 
                        desapariciones forzadas de personas, así que las mujeres salieron a protestar para 
                        exigir justicia. <br/><br/>
                        
                        Estas madres y abuelas se reúnen cada jueves desde el 30 de abril de 1977 y
                         marchan alrededor de la pirámide de la Plaza de Mayo para manifestarse. Buscan y 
                         demandan respuestas sobre el paradero de sus seres queridos. <br/><br/>
                         
                         La historia de mamá, su abuela y mi abuela me pone muy triste. Pero también me 
                         llena de orgullo, porque se trata de mujeres fuertes, valientes e incansables.

                      </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <Grid container justifyContent="center" >
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img2do/Sesion57/1.png" alt="Tarjeta" />
                        </Grid>
                        <Grid container justifyContent="center" >
                            <p className="TextImg">
                            El paliacate en la cabeza de las madres y abuelas se volvió un símbolo para reconocer su movimiento.                            
                            </p>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura Esp">
                            Yo no puedo imaginar lo que sintió mi bisabuela al perder a su nieta, pero estoy feliz 
                            de que finalmente haya logrado encontrarla. Te explicaré cómo pasó:<br/><br/>
                            
                            Mi abuela estaba embarazada cuando la detuvieron. Por eso mamá nació en la 
                            prisión y fue entregada a otra pareja. Durante años, mamá no supo nada de su familia 
                            sanguínea, hasta que su abuela la localizó y demostró el parentesco mediante 
                            pruebas de <span style={{fontVariant: "all-small-caps"}}>ADN</span>.<br/><br/>
                            
                            Mi bisabuela murió antes de que yo naciera. No alcancé a conocerla, pero sí conozco 
                            su historia y su lucha. Mamá dice que necesitamos recordar y apoyar la causa de las 
                            abuelas de la Plaza de Mayo. Sobre todo, dice que no podemos olvidarla.               
                        </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <Grid container justifyContent="center" >
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img2do/Sesion57/2.png" alt="Tarjeta" />
                        </Grid>
                    </Grid>
                </Grid>
        </Grid>
    </Grid>
    );
}
 
export default Las_abuelas_de_la_Plaza_de_Mayo ;
