import axios from "axios";
import currentEnvironment from "./CONST";
// const instance = axios.create({
//   baseURL: "http://pearsondevcdl-1481070920.us-east-1.alb.amazonaws.com:3001/v1",
//   // baseURL: 'https://pearsondevcdl-1481070920.us-east-1.alb.amazonaws.com:3001/v1',Desarrollo
//   //baseURL: https://pearsoncdl-680365724.us-east-1.alb.amazonaws.com:3000/v1 produccion

//   timeout: 5000,
//   headers: {
//     "content-type": "application/json",
//     // 'content-type': `x-token`
//   },
//  alb es para bucket cdl-pearson
//  elb es para bucket cdl-pearsonqa
// });
const instance = axios.create({
  baseURL: currentEnvironment.main.baseURL,
  timeout: 30000,
  headers: currentEnvironment.main.headers,
});
axios.defaults.headers.common["Authorization"] =
  "Bearer " + localStorage.auth_token;

instance.interceptors.request.use(
  async (config) => {
    const accessToken = localStorage.getItem("token");
    config.headers = {
      "x-token": `${accessToken}`,
    };
    return config;
  },
  (error) => {
    throw error;
    // Promise.reject(error)
  }
);

export default instance;
