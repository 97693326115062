import React from "react";
import ProtectedRoute from "./ProtectedRoute";

import LectS_5_20 from "../pages/Container/ContainerLecturasBiblioteca5to/Sesion20";
import LectS_5_21R from "../pages/Container/ContainerLecturasBiblioteca5to/Sesion21R";
import LectS_5_38 from "../pages/Container/ContainerLecturasBiblioteca5to/Sesion38";
import LectS_5_39R from "../pages/Container/ContainerLecturasBiblioteca5to/Sesion39R";
import LectS_5_56 from "../pages/Container/ContainerLecturasBiblioteca5to/Sesion56";
import LectS_5_56R from "../pages/Container/ContainerLecturasBiblioteca5to/Sesion56R";

import LectS_5_3 from "../pages/Container/ContainerLecturasBiblioteca5to/Sesion3";
import LectS_5_4 from "../pages/Container/ContainerLecturasBiblioteca5to/Sesion4";
import LectS_5_5 from "../pages/Container/ContainerLecturasBiblioteca5to/Sesion5";
import LectS_5_6 from "../pages/Container/ContainerLecturasBiblioteca5to/Sesion6";
import LectS_5_7 from "../pages/Container/ContainerLecturasBiblioteca5to/Sesion7";
import LectS_5_8 from "../pages/Container/ContainerLecturasBiblioteca5to/Sesion8";
import LectS_5_9 from "../pages/Container/ContainerLecturasBiblioteca5to/Sesion9";
import LectS_5_10 from "../pages/Container/ContainerLecturasBiblioteca5to/Sesion10";
import LectS_5_11 from "../pages/Container/ContainerLecturasBiblioteca5to/Sesion11";
import LectS_5_12 from "../pages/Container/ContainerLecturasBiblioteca5to/Sesion12";
import LectS_5_13 from "../pages/Container/ContainerLecturasBiblioteca5to/Sesion13";
import LectS_5_14 from "../pages/Container/ContainerLecturasBiblioteca5to/Sesion14";
import LectS_5_15 from "../pages/Container/ContainerLecturasBiblioteca5to/Sesion15";
import LectS_5_16 from "../pages/Container/ContainerLecturasBiblioteca5to/Sesion16";
import LectS_5_17 from "../pages/Container/ContainerLecturasBiblioteca5to/Sesion17";
import LectS_5_18 from "../pages/Container/ContainerLecturasBiblioteca5to/Sesion18";
import LectS_5_19 from "../pages/Container/ContainerLecturasBiblioteca5to/Sesion19";
import LectS_5_21 from "../pages/Container/ContainerLecturasBiblioteca5to/Sesion21";
import LectS_5_22 from "../pages/Container/ContainerLecturasBiblioteca5to/Sesion22";
import LectS_5_23 from "../pages/Container/ContainerLecturasBiblioteca5to/Sesion23";
import LectS_5_24 from "../pages/Container/ContainerLecturasBiblioteca5to/Sesion24";
import LectS_5_25 from "../pages/Container/ContainerLecturasBiblioteca5to/Sesion25";
import LectS_5_26 from "../pages/Container/ContainerLecturasBiblioteca5to/Sesion26";
import LectS_5_27 from "../pages/Container/ContainerLecturasBiblioteca5to/Sesion27";
import LectS_5_28 from "../pages/Container/ContainerLecturasBiblioteca5to/Sesion28";
import LectS_5_29 from "../pages/Container/ContainerLecturasBiblioteca5to/Sesion29";
import LectS_5_30 from "../pages/Container/ContainerLecturasBiblioteca5to/Sesion30";
import LectS_5_31 from "../pages/Container/ContainerLecturasBiblioteca5to/Sesion31";
import LectS_5_32 from "../pages/Container/ContainerLecturasBiblioteca5to/Sesion32";
import LectS_5_33 from "../pages/Container/ContainerLecturasBiblioteca5to/Sesion33";
import LectS_5_34 from "../pages/Container/ContainerLecturasBiblioteca5to/Sesion34";
import LectS_5_35 from "../pages/Container/ContainerLecturasBiblioteca5to/Sesion35";
import LectS_5_36 from "../pages/Container/ContainerLecturasBiblioteca5to/Sesion36";
import LectS_5_37 from "../pages/Container/ContainerLecturasBiblioteca5to/Sesion37";
import LectS_5_39 from "../pages/Container/ContainerLecturasBiblioteca5to/Sesion39";
import LectS_5_40 from "../pages/Container/ContainerLecturasBiblioteca5to/Sesion40";
import LectS_5_41 from "../pages/Container/ContainerLecturasBiblioteca5to/Sesion41";
import LectS_5_42 from "../pages/Container/ContainerLecturasBiblioteca5to/Sesion42";
import LectS_5_43 from "../pages/Container/ContainerLecturasBiblioteca5to/Sesion43";
import LectS_5_44 from "../pages/Container/ContainerLecturasBiblioteca5to/Sesion44";
import LectS_5_45 from "../pages/Container/ContainerLecturasBiblioteca5to/Sesion45";
import LectS_5_46 from "../pages/Container/ContainerLecturasBiblioteca5to/Sesion46";
import LectS_5_47 from "../pages/Container/ContainerLecturasBiblioteca5to/Sesion47";
import LectS_5_48 from "../pages/Container/ContainerLecturasBiblioteca5to/Sesion48";
import LectS_5_49 from "../pages/Container/ContainerLecturasBiblioteca5to/Sesion49";
import LectS_5_50 from "../pages/Container/ContainerLecturasBiblioteca5to/Sesion50";
import LectS_5_51 from "../pages/Container/ContainerLecturasBiblioteca5to/Sesion51";
import LectS_5_52 from "../pages/Container/ContainerLecturasBiblioteca5to/Sesion52";
import LectS_5_53 from "../pages/Container/ContainerLecturasBiblioteca5to/Sesion53";
import LectS_5_54 from "../pages/Container/ContainerLecturasBiblioteca5to/Sesion54";
import LectS_5_55 from "../pages/Container/ContainerLecturasBiblioteca5to/Sesion55";
import LectS_5_57 from "../pages/Container/ContainerLecturasBiblioteca5to/Sesion57";
import LectS_5_58 from "../pages/Container/ContainerLecturasBiblioteca5to/Sesion58";

const routes = [
  <ProtectedRoute
    exact
    path="/Todos_en_uno_y_uno_en_todos"
    component={LectS_5_20}
  />,
  <ProtectedRoute exact path="/Viajes_en_el_tiempo" component={LectS_5_21R} />,
  <ProtectedRoute
    exact
    path="/Los_derechos_de_los_consumidores"
    component={LectS_5_38}
  />,
  <ProtectedRoute exact path="/El_Galeón_de_Manila" component={LectS_5_39R} />,
  <ProtectedRoute
    exact
    path="/El_caballo_hace_un_recorrido_semimágico"
    component={LectS_5_55}
  />,
  <ProtectedRoute exact path="/La_cocina_como_arte" component={LectS_5_56R} />,

  <ProtectedRoute exact path="/98" component={LectS_5_3} />,
  //Arma_tu_propia_alcancía"
  <ProtectedRoute exact path="/99" component={LectS_5_4} />,
  //Las_abejas_y_la_polinización"
  <ProtectedRoute exact path="/100" component={LectS_5_5} />,
  //Para_qué_sirve_un_museo"
  <ProtectedRoute exact path="/101" component={LectS_5_6} />,
  //De_otro_planeta"
  <ProtectedRoute exact path="/102" component={LectS_5_7} />,
  //Los_anteojos_una_historia_inesperada"
  <ProtectedRoute exact path="/103" component={LectS_5_8} />,
  //Operaciones_con_conjuntos"
  <ProtectedRoute exact path="/104" component={LectS_5_9} />,
  //Países_en_distintas_vías_de_desarrollo"
  <ProtectedRoute exact path="/105" component={LectS_5_10} />,
  //Las_torres_de_Hanói"
  <ProtectedRoute exact path="/106" component={LectS_5_11} />,
  //La_carrera_espacial"
  <ProtectedRoute exact path="/107" component={LectS_5_12} />,
  //El_Festival_Cervantino_en_época_virtual"
  <ProtectedRoute exact path="/108" component={LectS_5_13} />,
  //La_hoja_mágica"
  <ProtectedRoute exact path="/109" component={LectS_5_14} />,
  //Economía_naranja_creativa_y_sostenible"
  <ProtectedRoute exact path="/110" component={LectS_5_15} />,
  //Muse_del_juego"
  <ProtectedRoute exact path="/111" component={LectS_5_16} />,
  //La_misión_de_las_nubes"
  <ProtectedRoute exact path="/112" component={LectS_5_17} />,
  //La_importancia_del_calcio"
  <ProtectedRoute exact path="/113" component={LectS_5_18} />,
  //Diferentes_reglas"
  <ProtectedRoute exact path="/114" component={LectS_5_19} />,
  //Todo_a_su_lugar"
  <ProtectedRoute exact path="/117" component={LectS_5_21} />,
  //El_teatro_y_su_historia"
  <ProtectedRoute exact path="/118" component={LectS_5_22} />,
  //Qué_hace_un_arqueólogo"
  <ProtectedRoute exact path="/119" component={LectS_5_23} />,
  //El_extranjero"
  <ProtectedRoute exact path="/120" component={LectS_5_24} />,
  //La_educación_es_un_derecho_para_todos"
  <ProtectedRoute exact path="/121" component={LectS_5_25} />,
  //El_secreto_de_la_vida"
  <ProtectedRoute exact path="/122" component={LectS_5_26} />,
  //Hipatia_de_Alejandría_y_las_matemáticas"
  <ProtectedRoute exact path="/123" component={LectS_5_27} />,
  //El_Coco"
  <ProtectedRoute exact path="/124" component={LectS_5_28} />,
  //Igualdad_y_equidad"
  <ProtectedRoute exact path="/125" component={LectS_5_29} />,
  //Niños_de_azúcar"
  <ProtectedRoute exact path="/126" component={LectS_5_30} />,
  //Hablar_con_emojis"
  <ProtectedRoute exact path="/127" component={LectS_5_31} />,
  //Siempre_al_mar"
  <ProtectedRoute exact path="/128" component={LectS_5_32} />,
  //Tecnología_digital"
  <ProtectedRoute exact path="/129" component={LectS_5_33} />,
  //Viajeros_en_el_espacio_asteroides_y_cometas"   */}
  <ProtectedRoute exact path="/130" component={LectS_5_34} />,
  //Las_Vitaminas_se_comen"
  <ProtectedRoute exact path="/131" component={LectS_5_35} />,
  //Energía_limpia_para_el_planeta_Tierra"
  <ProtectedRoute exact path="/132" component={LectS_5_36} />,
  //Declaración_Universal_de_los_Derechos_Humanos" */}
  <ProtectedRoute exact path="/133" component={LectS_5_37} />,
  //Jugando_con_la_historia"
  <ProtectedRoute exact path="/136" component={LectS_5_39} />,
  //Qué_son_las_artes_plásticas"
  <ProtectedRoute exact path="/137" component={LectS_5_40} />,
  //La_libertad_de_expresión"
  <ProtectedRoute exact path="/138" component={LectS_5_41} />,
  //Viaje_a_lo_invisible"
  <ProtectedRoute exact path="/139" component={LectS_5_42} />,
  //Fuegos_artificiales_y_su_contaminación"
  <ProtectedRoute exact path="/140" component={LectS_5_43} />,
  //Anuncio_impresora"
  <ProtectedRoute exact path="/141" component={LectS_5_44} />,
  //El_sombrerero_loco"
  <ProtectedRoute exact path="/142" component={LectS_5_45} />,
  //Göbekli_Tepe"
  <ProtectedRoute exact path="/143" component={LectS_5_46} />,
  //El_arte_de_la_pintura"
  <ProtectedRoute exact path="/144" component={LectS_5_47} />,
  //El_primer_alimento_de_nuestra_vida_la_leche"   */}
  <ProtectedRoute exact path="/145" component={LectS_5_48} />,
  //Go_el_juego_de_mesa_hino"
  <ProtectedRoute exact path="/146" component={LectS_5_49} />,
  //Una_fogata_en_el_cielo"
  <ProtectedRoute exact path="/147" component={LectS_5_50} />,
  //La_importancia_del_sueño"
  <ProtectedRoute exact path="/148" component={LectS_5_51} />,
  //Derecho_a_la_salud"
  <ProtectedRoute exact path="/149" component={LectS_5_52} />,
  //Horario_de_vuelos"
  <ProtectedRoute exact path="/150" component={LectS_5_53} />,
  //Distanciamiento_social_y_acercamiento_virtual" */}
  <ProtectedRoute exact path="/151" component={LectS_5_54} />,
  //El_reciclaje"
  <ProtectedRoute exact path="/154" component={LectS_5_56} />,
  //El_origen_del_cine"
  <ProtectedRoute exact path="/155" component={LectS_5_57} />,
  //Una_obra_de_teatro"
  <ProtectedRoute exact path="/156" component={LectS_5_58} />,
  //Los_números_triangulares"
];

const Lectura5to = routes.map((route, key) => {
  return { ...route, key: key };
});
export default Lectura5to;
