import { makeStyles, Grid } from "@material-ui/core";
import React from "react";
import ChatListBar from "../ChatListBar";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    background: "#FFFAE5 0% 0% no-repeat padding-box",
    minHeight: "calc(100vh - 80px)",
    display: "grid",
    width: "100%",
    height: "calc(100vh - 80px)",
    gridTemplateAreas: `"bar"`,
    gridTemplateColumns: "1fr",
    gridTemplateRows: "1fr",
    // "@media (max-height: 400px)": {
    //   gridTemplateRows: "30% 1fr 30%",
    // },
    // "@media (max-height: 600px)": {
    //   gridTemplateRows: "30% 1fr 40%",
    // },
    [theme.breakpoints.up("lg")]: {
      gridTemplateAreas: `"bar header"`,
      gridTemplateColumns: "minmax(20%, 300px) 1fr",
      // gridTemplateRows: "15vh 65vh 15vh",
      // gridTemplateRows: "20% 1fr 12.5%",
      // margin: "10px 0",
    },
  },
  titleContainer: {
    textAlign: "center",
    color: "#7A54BA",
    gridArea: "header",
    display: "none",
    [theme.breakpoints.up("lg")]: {
      display: "flex",
    },
  },
  title: {
    textAlign: "center",
    color: "#7A54BA",
  },
}));

const ListChat = () => {
  const classes = useStyles();

  return (
    <Grid container item sm={12} alignItems="center" justifyContent="center" className={classes.mainContainer}>
      <ChatListBar inChat={false} />
      <Grid container item sm={12} alignItems="center" justifyContent="center" className={classes.titleContainer}>
        <h1 className={classes.title}>Pulse un chat para entrar a la conversación</h1>
      </Grid>
    </Grid>
  );
};

export default ListChat;
