import React               from "react";
import {Grid, makeStyles}                 from '@material-ui/core';

const useStyles = makeStyles(theme =>({
  questionContainer:{
    width: "100%",
    height:"100%",

  },
  reading: {
    width: "80%",
    margin:"10px 0 10px 0 ",
    font: '22px lato',
    letterSpacing: '0.21px',
    [theme.breakpoints.down(1400)]:{
      font: " 18px lato",
      width: "95%",
    },
    [theme.breakpoints.down(1310)]:{
      font: "500 16px lato",

    },
    [theme.breakpoints.down(925)]:{
      font: "500 14px lato",

    },
    [theme.breakpoints.down(426)]:{
      font: "500 12px lato",
 
    }, 
  },
  table: {
    width: "100%",
    font: '22px lato',
    border: '1px solid black',
    [theme.breakpoints.down(1400)]:{
      font: " 18px lato",
      width: "95%",
    },
    [theme.breakpoints.down(1310)]:{
      font: "500 16px lato",
 
    },
    [theme.breakpoints.down(925)]:{
      font: "500 14px lato",

    },
    [theme.breakpoints.down(426)]:{
      font: "500 12px lato",

    }, 
  },
  tdTable: {
    padding: "0 1%",
    border: '1px solid black',
    textAlign: 'center'
  },
  ovalText: {
    border: '2px solid black',
    borderRadius: "90px",
    font: '22px lato',
    textAlign: "center",
    [theme.breakpoints.down(1400)]:{
      font: " 18px lato",
    },
    [theme.breakpoints.down(1310)]:{
      font: "500 16px lato",
    },
    [theme.breakpoints.down(925)]:{
      font: "500 14px lato",
    },
    [theme.breakpoints.down(426)]:{
      font: "500 10px lato",
 
    }, 
  },
  ovalContainer: {
    marginBottom: "20px",
  }
}));

const QuestionTable = (props) => {
  const classes = useStyles()

  return(
    <Grid container className={classes.questionContainer}>
      <p
        className={classes.reading}
      >
        4.Relaciona los sujetos con su respectivo predicado.
      </p>
      <br/>
      <Grid container className={classes.ovalContainer}>
        <Grid container item xs={3} className={classes.ovalText} justifyContent="center" alignItems="center">
          <p
   
          >
            <b>Tatiana</b>
          </p>
        </Grid>
        <Grid container item xs={3} className={classes.ovalText} justifyContent="center" alignItems="center">
          <p
    
          >
            <b>La naturaleza</b>
          </p>
        </Grid>
        <Grid container item xs={3} className={classes.ovalText} justifyContent="center" alignItems="center">
          <p
        
          >
            <b>Su abuela</b>
          </p>
        </Grid>
        <Grid container item xs={3} className={classes.ovalText} justifyContent="center" alignItems="center">
          <p
         
          >
            <b>Nadie</b>
          </p>
        </Grid>
      </Grid>
      <table className={classes.table}>
        <thead>
          <th style={{ border: '1px solid black',}}><b>Predicado</b></th>
          {/* <th style={{width: "50%", border: '1px solid black',}}><i>Uso</i></th> */}
        </thead>
        <tbody>
          <tr>
            <td className={classes.tdTable}>
              <p>
              A. ocupa el lugar de nadie.
              </p>
            </td>
          </tr>
          <tr>
            <td className={classes.tdTable}>
              <p>
              B. por fin le contestó.
              </p>
            </td>
          </tr>
          <tr>
            <td className={classes.tdTable}>
              <p>
              C. no comprendía lo que le pasaba.
              </p>
            </td>
          </tr>
          <tr>
            <td className={classes.tdTable}>
              <p>
              D. hace a los bebés encantadores.
              </p>
            </td>
          </tr>
        </tbody>
      </table>
    </Grid>
  )
}

export default QuestionTable;
