import React from "react";
import ProtectedRoute from "./ProtectedRoute";

import LectS3 from "../pages/Container/ContainerLecturasBiblioteca1ro/Sesion3";
import LectS4 from "../pages/Container/ContainerLecturasBiblioteca1ro/Sesion4";
import LectS5 from "../pages/Container/ContainerLecturasBiblioteca1ro/Sesion5";
import LectS6 from "../pages/Container/ContainerLecturasBiblioteca1ro/Sesion6";
import LectS7 from "../pages/Container/ContainerLecturasBiblioteca1ro/Sesion7";
import LectS8 from "../pages/Container/ContainerLecturasBiblioteca1ro/Sesion8";
import LectS9 from "../pages/Container/ContainerLecturasBiblioteca1ro/Sesion9";
import LectS10 from "../pages/Container/ContainerLecturasBiblioteca1ro/Sesion10";
import LectS11 from "../pages/Container/ContainerLecturasBiblioteca1ro/Sesion11";
import LectS12 from "../pages/Container/ContainerLecturasBiblioteca1ro/Sesion12";
import LectS13 from "../pages/Container/ContainerLecturasBiblioteca1ro/Sesion13";
import LectS14 from "../pages/Container/ContainerLecturasBiblioteca1ro/Sesion14";
import LectS15 from "../pages/Container/ContainerLecturasBiblioteca1ro/Sesion15";
import LectS16 from "../pages/Container/ContainerLecturasBiblioteca1ro/Sesion16";
import LectS17 from "../pages/Container/ContainerLecturasBiblioteca1ro/Sesion17";
import LectS18 from "../pages/Container/ContainerLecturasBiblioteca1ro/Sesion18";
import LectS19 from "../pages/Container/ContainerLecturasBiblioteca1ro/Sesion19";
import LectS20 from "../pages/Container/ContainerLecturasBiblioteca1ro/Sesion20";
import LectS21 from "../pages/Container/ContainerLecturasBiblioteca1ro/Sesion21";
import LectS22 from "../pages/Container/ContainerLecturasBiblioteca1ro/Sesion22";
import LectS23 from "../pages/Container/ContainerLecturasBiblioteca1ro/Sesion23";
import LectS24 from "../pages/Container/ContainerLecturasBiblioteca1ro/Sesion24";
import LectS25 from "../pages/Container/ContainerLecturasBiblioteca1ro/Sesion25";
import LectS26 from "../pages/Container/ContainerLecturasBiblioteca1ro/Sesion26";
import LectS27 from "../pages/Container/ContainerLecturasBiblioteca1ro/Sesion27";
import LectS28 from "../pages/Container/ContainerLecturasBiblioteca1ro/Sesion28";
import LectS29 from "../pages/Container/ContainerLecturasBiblioteca1ro/Sesion29";
import LectS30 from "../pages/Container/ContainerLecturasBiblioteca1ro/Sesion30";
import LectS31 from "../pages/Container/ContainerLecturasBiblioteca1ro/Sesion31";
import LectS32 from "../pages/Container/ContainerLecturasBiblioteca1ro/Sesion32";
import LectS33 from "../pages/Container/ContainerLecturasBiblioteca1ro/Sesion33";
import LectS34 from "../pages/Container/ContainerLecturasBiblioteca1ro/Sesion34";
import LectS35 from "../pages/Container/ContainerLecturasBiblioteca1ro/Sesion35";
import LectS36 from "../pages/Container/ContainerLecturasBiblioteca1ro/Sesion36";
import LectS37 from "../pages/Container/ContainerLecturasBiblioteca1ro/Sesion37";
import LectS38 from "../pages/Container/ContainerLecturasBiblioteca1ro/Sesion38";
import LectS39 from "../pages/Container/ContainerLecturasBiblioteca1ro/Sesion39";
import LectS40 from "../pages/Container/ContainerLecturasBiblioteca1ro/Sesion40";
import LectS41 from "../pages/Container/ContainerLecturasBiblioteca1ro/Sesion41";
import LectS42 from "../pages/Container/ContainerLecturasBiblioteca1ro/Sesion42";
import LectS43 from "../pages/Container/ContainerLecturasBiblioteca1ro/Sesion43";
import LectS44 from "../pages/Container/ContainerLecturasBiblioteca1ro/Sesion44";
import LectS45 from "../pages/Container/ContainerLecturasBiblioteca1ro/Sesion45";
import LectS46 from "../pages/Container/ContainerLecturasBiblioteca1ro/Sesion46";
import LectS47 from "../pages/Container/ContainerLecturasBiblioteca1ro/Sesion47";
import LectS48 from "../pages/Container/ContainerLecturasBiblioteca1ro/Sesion48";
import LectS49 from "../pages/Container/ContainerLecturasBiblioteca1ro/Sesion49";
import LectS50 from "../pages/Container/ContainerLecturasBiblioteca1ro/Sesion50";
import LectS51 from "../pages/Container/ContainerLecturasBiblioteca1ro/Sesion51";
import LectS52 from "../pages/Container/ContainerLecturasBiblioteca1ro/Sesion52";
import LectS53 from "../pages/Container/ContainerLecturasBiblioteca1ro/Sesion53";
import LectS54 from "../pages/Container/ContainerLecturasBiblioteca1ro/Sesion54";
import LectS55 from "../pages/Container/ContainerLecturasBiblioteca1ro/Sesion55";
import LectS56 from "../pages/Container/ContainerLecturasBiblioteca1ro/Sesion56";
import LectS57 from "../pages/Container/ContainerLecturasBiblioteca1ro/Sesion57";
import LectS58 from "../pages/Container/ContainerLecturasBiblioteca1ro/Sesion58";

const routes = [
  <ProtectedRoute exact path="/157" component={LectS3} />,
  <ProtectedRoute exact path="/158" component={LectS4} />,
  <ProtectedRoute exact path="/159" component={LectS5} />,
  <ProtectedRoute exact path="/160" component={LectS6} />,
  <ProtectedRoute exact path="/161" component={LectS7} />,
  <ProtectedRoute exact path="/162" component={LectS8} />,
  <ProtectedRoute exact path="/163" component={LectS9} />,
  <ProtectedRoute exact path="/164" component={LectS10} />,
  <ProtectedRoute exact path="/165" component={LectS11} />,
  <ProtectedRoute exact path="/166" component={LectS12} />,
  <ProtectedRoute exact path="/167" component={LectS13} />,
  <ProtectedRoute exact path="/168" component={LectS14} />,
  <ProtectedRoute exact path="/169" component={LectS15} />,
  <ProtectedRoute exact path="/170" component={LectS16} />,
  <ProtectedRoute exact path="/171" component={LectS17} />,
  <ProtectedRoute exact path="/172" component={LectS18} />,
  <ProtectedRoute exact path="/173" component={LectS19} />,
  <ProtectedRoute exact path="/174" component={LectS20} />,
  // <ProtectedRoute exact path="/175" component={LectS21R} />,
  <ProtectedRoute exact path="/176" component={LectS21} />,
  <ProtectedRoute exact path="/177" component={LectS22} />,
  <ProtectedRoute exact path="/178" component={LectS23} />,
  <ProtectedRoute exact path="/179" component={LectS24} />,
  <ProtectedRoute exact path="/180" component={LectS25} />,
  <ProtectedRoute exact path="/181" component={LectS26} />,
  <ProtectedRoute exact path="/182" component={LectS27} />,
  <ProtectedRoute exact path="/183" component={LectS28} />,
  <ProtectedRoute exact path="/184" component={LectS29} />,
  <ProtectedRoute exact path="/185" component={LectS30} />,
  <ProtectedRoute exact path="/186" component={LectS31} />,
  <ProtectedRoute exact path="/187" component={LectS32} />,
  <ProtectedRoute exact path="/188" component={LectS33} />,
  <ProtectedRoute exact path="/189" component={LectS34} />,
  <ProtectedRoute exact path="/190" component={LectS35} />,
  <ProtectedRoute exact path="/191" component={LectS36} />,
  <ProtectedRoute exact path="/192" component={LectS37} />,
  <ProtectedRoute exact path="/193" component={LectS38} />,
  // <ProtectedRoute exact path="/194" component={LectS39R} />,
  <ProtectedRoute exact path="/195" component={LectS39} />,
  <ProtectedRoute exact path="/196" component={LectS40} />,
  <ProtectedRoute exact path="/197" component={LectS41} />,
  <ProtectedRoute exact path="/198" component={LectS42} />,
  <ProtectedRoute exact path="/199" component={LectS43} />,
  <ProtectedRoute exact path="/200" component={LectS44} />,
  <ProtectedRoute exact path="/201" component={LectS45} />,
  <ProtectedRoute exact path="/202" component={LectS46} />,
  <ProtectedRoute exact path="/203" component={LectS47} />,
  <ProtectedRoute exact path="/204" component={LectS48} />,
  <ProtectedRoute exact path="/205" component={LectS49} />,
  <ProtectedRoute exact path="/206" component={LectS50} />,
  <ProtectedRoute exact path="/207" component={LectS51} />,
  <ProtectedRoute exact path="/208" component={LectS52} />,
  <ProtectedRoute exact path="/209" component={LectS53} />,
  <ProtectedRoute exact path="/210" component={LectS54} />,
  <ProtectedRoute exact path="/211" component={LectS55} />,
  <ProtectedRoute exact path="/213" component={LectS56} />,
  // <ProtectedRoute exact path="/213" component={LectS56R} />,
  <ProtectedRoute exact path="/214" component={LectS57} />,
  <ProtectedRoute exact path="/215" component={LectS58} />,
];

const Lecturas1ro = routes.map((route, key) => {
  return { ...route, key: key };
});
export default Lecturas1ro;
