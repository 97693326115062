import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
     Contenedor:{
        background:'#E9FBF9',
        width:'100vw',
        overflowX:'clip',
        [theme.breakpoints.down(1161)]:{
            background:'none',
           overflowX:'unset',
        },
        [theme.breakpoints.down(600)]:{
            marginTop: '79.51px',
        }
    },
  ContainerInfoLectura: {
    position: "relative",
    height: "86px",
    [theme.breakpoints.down(502)]:{
        height: "43px",
    },
    background: "#76D4C3",
    "& .BarraInfoLectura": {
      height: "86px",
      background: `Url(https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Barras/Azul.png)`,
        [theme.breakpoints.down(502)]:{
            height: "43px",
        },
    },
    "& .InfoLectura": {
      position: "absolute",
      height: "86px",
      width: "219px",
      boxSizing: "border-box",
      padding: "17.2px 0",
      background: "#FAFAFA",
      borderRadius: "50px",
      boxShadow: "2px 2px 2px 2px #00000029",
      zIndex: "4",
        [theme.breakpoints.down(502)]:{
            height: "43px",
            width: "109px",
            padding: "8.6px 0",
            borderRadius: "25px",
        },
    },
    "& .InfoLectura:before": {
      position: "absolute",
      display: "flex",
      boxSizing: "border-box",
      padding: "0 0 0 10px",
      justifyContent: "center",
      alignItems: "center",
      height: "39.88px",
      width: "39.88px",
      borderRadius: "50%",
      content: '""',
      boxShadow: "3px 0px .5px 0px #00000029",
      background: "#FAFAFA",
      left: "90px",
      top: "65px",
      transform: "rotate(0.25turn)",
      [theme.breakpoints.down(502)]:{
        left: "45px",
        top: "30px",
        height: "20.88px",
        width: "20.88px",
        },
    },
    "& .InfoLectura:after": {
      content: '""',
      height: "15px",
      width: "15px",
      borderTop: "2px solid #20A698",
      borderLeft: "2px solid #20A698",
      position: "absolute",
      bottom: "-10px",
      left: "Calc(50% - 8px)",
      transform: "rotate(0.625turn)",
      [theme.breakpoints.down(502)]:{
        height: "10px",
        width: "10px",
        left: "Calc(50% - 4.5px)",
        bottom: "-3px",
        borderTop: "1px solid #20A698",
        borderLeft: "1px solid #20A698",
        },
    },
    "& .Sesion": {
      borderLeft: "2px solid #D8D8D8",
    },
    "& .Dato": {
      margin: "0 0 5px 0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#20A698",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        margin: "0 0 2.5px 0",
        },
    },
    "& .Caracter": {
      margin: "0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#000000",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        },
    },   },
    ContenedorLectura:{
        background:'#fff',
        width:'1160px',
        height:'100%',
        padding:'60px 168px 35px ',
        [theme.breakpoints.down(1161)]:{
            width:'502px',
            padding:'60px 0 0 0',
          },
        [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
        '& .ContainerDerechos':{
            bottom:'80px',
            [theme.breakpoints.down(1161)]:{
                display:'none',
            },
        },
        '& .Derechos':{
            textAlign:"center",
            margin:"0px",
            font:" 15px lato",
            color:"#717476",
        },
        '& .Escritorio':{
            display:'block',
            [theme.breakpoints.down(1161)]:{
                display:'none'
            },
        },
        '& .Tablet':{
            display:'none',
            [theme.breakpoints.down(1161)]:{
                display:'block'
            },
        }
    },
    ContainerTitulo:{
        margin:'0 0 34px 0',
        [theme.breakpoints.down(1161)]:{
            margin:'0 0 34px 0',
        },
        '& .Titulo':{
            font:'48px fredoka one',
            letterSpacing: '0px',
            lineHeight:'58px',
            color: '#F6644B',
            margin:'0',
            textAlign:'center',
            [theme.breakpoints.down(1161)]:{
                font:'43px fredoka one',
                lineHeight:'52px',
            },
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 10px 0',
        '& .Lectura':{
            lineHeight : '30px',
            margin:'0',
            font:'22px lato',
            color:'#000',
            letterSpacing: '0.20px',
            [theme.breakpoints.down(1161)]:{
                font:'18px lato',
                lineHeight :'28px',
                letterSpacing: '0.17px',
            },
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .Lectura2':{
            lineHeight : '30px',
            margin:'0 0 36px 0',
            font:'bold 24px lato',
            color:'#000',
            letterSpacing: '0.20px',
            [theme.breakpoints.down(1161)]:{
                font:'bold 20px lato',
                padding:' 0 35px 0 0',
                lineHeight :'28px',
                letterSpacing: '0.17px',
            },
            [theme.breakpoints.down(502)]:{
                padding:'0',
                margin:'0',
                font:'bold 18px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
        },
        '& .SubTitulo':{
            font:'bold 30px lato',
            color:'#F6644B',
            lineHeigth:'35px',
            margin:'0',
            [theme.breakpoints.down(1161)]:{
                font:'bold 26px lato',
            },
            [theme.breakpoints.down(502)]:{
                font:'bold 18px lato',
            },
        },
        '& .SubTitulo2':{
            position:'absolute',
            font:'bold 24px lato',
            color:'#000000',
            lineHeigth:'35px',
            margin:'0',
            [theme.breakpoints.down(1161)]:{
                font:'bold 20px lato',
            },
            [theme.breakpoints.down(502)]:{
                font:'bold 12px lato',
            },
        },
        '& .SubTitulo3':{
            font:'bold 35px lato',
            color:'#8FB63E',
            lineHeigth:'35px',
            margin:'0',
            [theme.breakpoints.down(1161)]:{
                font:'bold 25px lato',
            },
            [theme.breakpoints.down(502)]:{
                font:'bold 15px lato',
            },
        },
        '& .SubTitulo4':{
            font:'bold 50px lato',
            color:'#8FB63E',
            lineHeigth:'35px',
            margin:'0',
            [theme.breakpoints.down(1161)]:{
                font:'bold 35px lato',
            },
            [theme.breakpoints.down(502)]:{
                font:'bold 20px lato',
            },
        },
        '& .SubTitulo5':{
            font:'bold 65px lato',
            color:'#8FB63E',
            lineHeigth:'35px',
            margin:'0 0 0 26%',
            [theme.breakpoints.down(1161)]:{
                font:'bold 50px lato',
            },
            [theme.breakpoints.down(502)]:{
                font:'bold 30px lato',
            },
        },
        '& .SubTitulo6':{
            font:'bold 100px lato',
            color:'#8FB63E',
            lineHeigth:'35px',
            margin:'0',
            [theme.breakpoints.down(1161)]:{
                font:'bold 90px lato',
            },
            [theme.breakpoints.down(502)]:{
                font:'bold 60px lato',
            },
        },
        '& .SubTitulo7':{
            font:'bold 35px lato',
            color:'#000000',
            lineHeigth:'35px',
            margin:'0',
            [theme.breakpoints.down(1161)]:{
                font:'bold 25px lato',
            },
            [theme.breakpoints.down(502)]:{
                font:'bold 18px lato',
            },
        },
        '& .Pos1':{
            top:'177px',
            left:'26px',
            [theme.breakpoints.down(1161)]:{
                top:'311px',
                left:'7px',
            },
            [theme.breakpoints.down(502)]:{
                top:'177px',
                left:'30px',
            },
        },
        '& .Pos2':{
            top:'100px',
            left:'623px',
            [theme.breakpoints.down(1161)]:{
                top:'67px',
                left:'324px',
            },
            [theme.breakpoints.down(502)]:{
                top:'37px',
                left:'220px',
            },

        },
        '& .Pos3':{
            top:'640px',
            left:'300px',
            [theme.breakpoints.down(1161)]:{
                width:'238px',
                top:'480px',
                left:'281px',
            },
            [theme.breakpoints.down(502)]:{
                width:'138px',
                top:'277px',
                left:'186px',
            },

        },

        '& .TextImg':{
            font:'20px lato',
            color:'#717476',
            letterSpacing:'.19px',
            lineHeight:'30px',
            margin:'0',
            [theme.breakpoints.down(1161)]:{
                font:' 16px lato',
                lineHeight :'25px',
                letterSpacing: '0.15px',
                margin:'0 0 20px 0',
            },
            [theme.breakpoints.down(502)]:{
                font:' 11px lato',
                lineHeight :'14px',
                letterSpacing: '0.10px',
            },
        },
        '& .Serpiente':{
            position:'relative'
        },
        '& .Cuadro':{
           width:'100%',
           height:'435px', 
           background:'#FCFAE6',
           borderRadius:'87px',
           boxSizing:'border-box',
           padding:'12px 27px 34px 44px',
           margin:'0 0 36px 0',
           [theme.breakpoints.down(1161)]:{
            padding:'26.3px 29px 0px 28px',
            width:'95%',
            height:'416px',
        },
        [theme.breakpoints.down(502)]:{
            borderRadius:'40px',
            height:'370px',
        },
        },
        '& .Imagen':{
            [theme.breakpoints.down(502)]:{
                width:'100%',
            },
        }, 
        '& .Imagen2':{
            [theme.breakpoints.down(1161)]:{
                width:'95%',
            },
        },   
      
    }
}));

const Animal_o_planta = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
             <Grid container item justifyContent="center" className={classes.ContainerInfoLectura}>
               <Grid container className="BarraInfoLectura"></Grid>
               <Grid container item className="InfoLectura">
                    <Grid container item direction="column" xs={6} justifyContent="center">
                        <p className="Dato">Grado</p>
                        <p className="Caracter">1</p>
                    </Grid>
                    <Grid container item direction="column" xs={6} justifyContent="center" className="Sesion">
                        <p className="Dato">Sesión</p>
                        <p className="Caracter">44</p>
                    </Grid>
               </Grid>
            </Grid>

            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >
                        ¿Animal o planta?
                    </h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                      <p className="Lectura ">
                         <h3 className='SubTitulo'>Venustiana</h3><br/> 
                        Antes que nada, buenos días, buenas tardes o buenas noches. Mucho gusto, me llamo 
                        Venustiana. ¿Que quién soy yo? Te cuento un poco. Soy pequeña, roja y vengo de 
                        Estados Unidos. ¿Ya adivinaste?
                      </p>
                    </Grid>
                    <Grid container justifyContent="center" className='Serpiente' sm={12}>
                        <img className="Imagen Escritorio" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img1ro/Sesion44/1.png" alt="Tarjeta" />
                        <img className="Imagen Tablet" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img1ro/Sesion44/1R.png" alt="Tarjeta" />
                        <p className='Pos1 SubTitulo2'>
                            <span className="SubTitulo3">Bzzzzz</span><br/>
                            ¿Será lo que<br/>
                            creo que es?
                        </p>
                        <p className='Pos2 SubTitulo2'>
                            <span className="SubTitulo3">Bzzzzz</span><br/>
                            Espera.<br/>
                            ¿Escuchas eso?
                        </p>
                        <p className='Pos3 SubTitulo2'>
                            <span className="SubTitulo4">Bzzzzz</span><br/>
                            ¡Sí! ¡Justo lo que creía! ¡Mi comida!
                        </p>
                    </Grid>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12} className="Cuadro">
                        <Grid container item>
                        <p className="SubTitulo5">Bzzzzz</p>
                        </Grid>
                        <Grid container item>
                        <b className="Lectura2">
                            Aguanta un poco. Voy a preparar una trampa. La haré roja y con un olor delicioso. Ahora que lo pienso, también la
                            haré pegajosa para que mi comida no vaya a ningún lado.
                        </b>
                        </Grid>
                        <Grid container item justifyContent="center">
                        <h3 className="SubTitulo6">
                            ¡Snap!
                            <h3 className="SubTitulo7">Mmm… ¡Deliciosa!</h3>
                        </h3>
                        </Grid>
                    </Grid>
                    <Grid container sm={12}>
                      <p className="Lectura">
                        Si aún no sabes quién soy, tal vez me conozcas por mi otro nombre: Venus 
                        Atrapamoscas. ¡Así es! Soy una pequeña planta y mi comida favorita son las 
                        moscas. Algunas personas piensan que soy una planta carnívora y que me las voy a 
                        comer. Esto no es cierto, sólo como moscas para estar bien nutrida.<br/><br/>

                        Las plantas comunes pueden fabricar su comida usando los nutrientes de la tierra y 
                        la energía del Sol, pero la tierra en la que yo vivo es pobre y no me permite crecer 
                        sana. Por eso busco insectos, para estar fuerte.<br/><br/>

                        ¡Mucho gusto!
                      </p>
                    </Grid>

                    <Grid container item justifyContent='center'>
                        <img className="Imagen2" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img1ro/Sesion44/2.png" alt="Tarjeta" />
                    </Grid>

                </Grid>
                <Grid container item xs={12} justifyContent="center" className="ContainerDerechos">
                    <p className="Derechos">
                        © 2022. Todos los derechos reservados, Pearson, Circuito de lectores.
                    </p>                
                </Grid>
        </Grid>
    </Grid>
    );
}
 
export default Animal_o_planta ;
