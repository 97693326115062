import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
    ContenedorLectura:{
        width:'502px',
        [theme.breakpoints.down(502)]:{
            width:'350px',
        },

    },
    ContainerTitulo:{
        margin:'0 0 38px 0',
        '& .Titulo':{
            letterSpacing: '0px',
            color: '#F6664D',
            margin:'0',
            font:'43px fredoka one',
            lineHeight:'52px',
            textAlign:'center',
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            margin:'0 0 36px 0 ',
            color:'#000',
            font:'18px lato',
            letterSpacing:'.17px',
            lineHeight:'28px',
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .Lectura2':{
            margin:'0',
        },
        '& .SubTitulo':{
            color:'#926DD1',
            margin:'0',
            font:'bold 26px lato',
            letterSpacing:'.24px',
            lineHeight:'28px',
            [theme.breakpoints.down(502)]:{
                font:'bold 22px lato',
                lineHeight:'24px',
            },
            [theme.breakpoints.down(350)]:{
                font:'bold 18px lato',
                lineHeight:'24px',
            },
        },
        '& .SubTitulo2':{
            color:'#20A698',
            margin:'0',
            font:'bold 22px lato',
            letterSpacing:'.21px',
            lineHeight:'28px',
            [theme.breakpoints.down(1161)]:{
                font:'bold 18px lato',
                letterSpacing:'.21px',
                lineHeight:'28px',
            },
        },
        '& .Imagen':{
            width:'100%'
        },
        '& .TextImg':{
            margin:'22px 0 0 0',
            alignSelf:'flex-start',
            color:'#717476',
            font:'16px lato',
            letterSpacing:'.15px',
            lineHeight:'25px',
            [theme.breakpoints.down(502)]:{
                font:' 11px lato',
                lineHeight :'14px',
                letterSpacing: '0.10px',
            },
        },

    },

    

}));

const El_precio_de_la_moda = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >
                        El precio de la moda
                    </h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                            Si alguien te preguntara cómo escoges tu ropa ¿qué responderías?, ¿que tú la eliges o 
                            que alguien más lo hace por ti? Seguramente a tu edad ya has empezado a 
                            seleccionar lo que quieres que te compren y aún más cómo te vistes o la combinas 
                            diariamente. <br/><br/>
                            
                            Para adquirir ropa que te gusta ahora es normal que encuentres en tiendas artículos 
                            de moda que lucen bien, van de acuerdo con tus gustos y son relativamente 
                            accesibles. Pero antes había una división entre prendas y ropa de moda. La primera 
                            categoría se refería a la vestimenta de uso cotidiano y de bajo costo, mientras que la 
                            segunda se trataba de ropa confeccionada con muy buenos diseños y telas finas, pero 
                            caras. <br/><br/>
                            
                            El hecho de que en la actualidad puedas acceder a la segunda categoría se debe a un 
                            fenómeno comercial conocido como <em>fast fashion</em>, que se traduce como <em>moda rápida</em> o 
                            <em>moda pronta</em>. Esto está relacionado con el desarrollo y producción de textiles baratos, 
                            los cuales pueden ser naturales como el algodón o sintéticos como el poliéster. 
                            Aunque para ti sea una ventaja adquirir ropa con diseños que te gusten, existen 
                            varias consecuencias que son importantes que conozcas.
                        </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <Grid container >
                            <img className='Imagen' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion37/1.png"  alt="Tarjeta" />
                        </Grid>
                        <Grid container >
                            <p className='TextImg'>La globalización ha permitido que cada vez más marcas internacionales ofrezcan sus<br/> 
                            productos en mayor número de países.</p>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} >
                        <p className="Lectura ">
                            <h3 className='SubTitulo'>Consumismo</h3> <br/> 
                            
                            Actualmente el bajo costo y la calidad de la ropa, así como los rápidos cambios de  
                            tendencias en la moda, han tenido como consecuencia el aumento del consumo  
                            mundial de ropa. Por ejemplo, se calcula que en países como China, a principios del  
                            milenio, se utilizaba cualquier prenda en promedio 200 veces antes de desecharla. 
                            En la actualidad ha bajado a casi 60 veces. Como podrás imaginar, este fenómeno  
                            acarrea consecuencias económicas, sociales y ambientales. <br/> <br/> 
                            
                            <h3 className='SubTitulo2'>Consecuencias económicas</h3> <br/> 
                            
                            Para mantener precios competitivos las marcas deben reducir los costos de su  
                            producción, es decir, pagar menos por hacer sus productos. Para ello, por ejemplo,  
                            utilizan telas de menor calidad, lo cual por supuesto reduce la duración de la ropa.  
                            Además establecen sus fábricas en lugares en donde los salarios son bajos y las  
                            condiciones de seguridad laboral que dan a sus empleados no son muy estrictas. <br/> <br/> 
                            
                            Por ese motivo, si revisas las etiquetas de tu ropa, sobre todo si es de una marca  
                            reconocida internacionalmente, verás que los lugares de fabricación suelen ser  
                            países asiáticos o latinoamericanos. Esto se debe a que en ellos aún no se consiguen  
                            los mejores derechos para los trabajadores. <br/> <br/> 
                            
                            <h3 className='SubTitulo2'>Consecuencias sociales</h3> <br/> 

                            Las consecuencias sociales se relacionan en gran medida con las consecuencias  
                            económicas. Como se permite que los salarios sean bajos, se contribuye a que los  
                            obreros no puedan tener una estabilidad económica que les permita afrontar  
                            exitosamente los retos de la vida ni que tengan la posibilidad de disfrutar algunos  
                            gustos adicionales a sus necesidades básicas. <br/> <br/> 
                            
                            Además, las condiciones laborales no siempre son adecuadas. Suele suceder que no  
                            se ofrece seguridad suficiente por la labor que realizan los trabajadores. Incluso en  
                            ocasiones es excesivo el tiempo que deben dedicar al trabajo. <br/> <br/> 
                            
                            <h3 className='SubTitulo2'>Consecuencias ambientales</h3><br/> 
                            
                            Debido al tipo de procesos y desechos que genera la industria, la contaminación del  
                            agua es una de las principales afectaciones al medio ambiente. Actualmente es  
                            responsable de 20 % de la contaminación anual del agua. <br/> <br/> 
                            
                            Otro problema es la cantidad de basura que produce. El hecho de que la gente  
                            compre continuamente ropa genera bastantes desechos, ya sea porque se quiera  
                            tener nuevos atuendos o porque los que se usaban se desgastaron debido a la mala  
                            calidad. En promedio, una persona consume 8.5 kilos de ropa al año, pero se estima  
                            que para 2030 la cifra aumentará a 11.8. Imagina la cantidad de basura que eso  
                            supondrá anualmente si juntas a toda la humanidad.
                        </p>

                        <Grid container item sm={12} className={classes.Parrafo2} justifyContent='center'>
                        <img className='Imagen' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion37/2.png"  alt="" />
                        <Grid container justifyContent='center'>
                            <p className='TextImg'>En 2030 se podrían alcanzar los 102 millones de toneladas de desechos de ropa.</p>
                        </Grid>
                </Grid>
                </Grid>
                

                <Grid container item sm={12} className={classes.Parrafo1} >
                        <p className="Lectura ">
                        Toda la información anterior no implica una restricción para que ya no compres más 
                        ropa que la indispensable. Pero sí representa una invitación a que tomes conciencia 
                        de qué usas y cuánto tiempo lo haces. También a que pienses que valdría la pena 
                        utilizar otras estrategias al cambiar de ropa, en lugar de tirarla. Por ejemplo, donar lo 
                        que ya no te queda, reutilizar prendas para distintos fines o incluso aceptar ropa de 
                        alguien más.
                        </p>
                </Grid>
        </Grid>
    </Grid>
    );
}
 
export default El_precio_de_la_moda;
