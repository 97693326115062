import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({

    ContenedorLectura:{
        width:'502px',
                  [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
    },
    ContainerTitulo:{
        margin:'0 0 39px 0',
        '& .Titulo':{
            letterSpacing: '0px',
            color: '#A0278F',
            margin:'0',
            textAlign:'center',
            font:'43px fredoka one',
            lineHeight:'52px',
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            margin:'0 0 36px 0 ',
            color:'#000',
            font:'18px lato',
            lineHeight :'28px',
            letterSpacing: '0.17px',
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .Imagen':{
            width:'100%',
        },


    },

}));

const El_caballo_hace_un_recorrido_semimágico = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>

            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >
                        El caballo hace un <br/>recorrido semimágico
                    </h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                        Existen diferentes juegos de números, como el sudoku y el cuadrado mágico. 
                        ¿Conoces este último? Se trata de un cuadrado dividido en celdas pequeñas, en las 
                        que se colocan números, pero éstos no pueden repetirse. Se acomodan de tal forma 
                        que todas las columnas, filas y diagonales suman la misma cantidad.<br/><br/> 
                        
                        Por ejemplo, si el cuadrado tiene nueve divisiones, se colocarán los números del 1 al 
                        9 en éstas. Si el cuadrado está dividido en 16 celdas, se colocarán los números del 1 al 
                        16. Mira los siguientes dos ejemplos, que corresponden a juegos de nueve y 16 
                        celdas, respectivamente.
                        </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                            <img className='Imagen' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img3ro/Sesion55/1.png"  alt="Tarjeta" />
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                            Es fácil comprobar que se cumplen las propiedades de un cuadrado mágico: basta 
                            con sumar las casillas de cada fila y columna. ¡Y la manera de acomodarlas no es 
                            única! Puedes poner los números de otra manera y obtener un resultado diferente.<br/><br/> 

                            Una regla es mantener la misma cantidad de columnas y de filas. Por lo tanto, puedes 
                            fraccionar la figura en 3 × 3 celdas, 4 × 4, 5 × 5 celdas, etcétera. Existen algunos 
                            cuadrados de 8 × 8. ¿Puedes pensar en otro juego que tenga un tablero de 8 × 8? 
                            ¡Exactamente: el ajedrez!
                        </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                            <img className='Imagen' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img3ro/Sesion55/2.png"  alt="Tarjeta" />
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                            ¿Cuál es la relación que guardan estos dos pasatiempos? Primero, hablemos de uno 
                            de los problemas más famosos y antiguos del ajedrez: el movimiento del caballo. 
                            Hace siglos se planteó la interrogante de si el caballo podía recorrer todas y cada una 
                            de las casillas sin repetirlas, partiendo de cualquier sitio del tablero.<br/><br/> 
                            
                            Varias personas buscaron la solución, entre ellas, el matemático Leonard Euler (¡sí, el 
                            mismo de los números triangulares!). ¡Veamos cómo resolvió esta incógnita! Para 
                            empezar, debemos saber que el caballo avanza en cualquier dirección, pero en forma 
                            de L. Observa la imagen. En ella, esta pieza está en una posición específica y se 
                            señalan con puntos rojos los posibles destinos a los que puede llegar con sus 
                            movimientos.
                        </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                            <img className='Imagen' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img3ro/Sesion55/3.png"  alt="Tarjeta" />
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                            Pues bien, la teoría de Euler para esta interrogante es la que aparece a continuación. 
                            En ella, el caballo comienza en el punto azul y termina en el morado:
                        </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                            <img className='Imagen' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img3ro/Sesion55/4.png"  alt="Tarjeta" />
                    </Grid>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                            Si enumeramos las casillas según el orden en que las atraviesa, tendrías este 
                            resultado:
                        </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                            <img className='Imagen' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img3ro/Sesion55/5.png"  alt="Tarjeta" />
                    </Grid>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                        ¡El recorrido del caballo forma un cuadrado semimágico! ¿Por qué semimágico? 
                        Porque todas las filas y las columnas suman lo mismo, excepto las diagonales. Es <em>semi </em> 
                        porque no es totalmente mágico, pero sí lo es en la mayoría de las cuentas.<br/><br/> 
                        
                        Sin embargo, en 2013, con ayuda de una computadora, se demostró que no es 
                        posible que un recorrido del caballo de ajedrez produzca un cuadrado mágico. ¡Qué 
                        lástima!<br/><br/> 
                        
                        ¿Qué te pareció este aprendizaje? ¿Imaginabas que estos dos pasatiempos podrían 
                        estar conectados?
                        </p>
                    </Grid>
                </Grid>
        </Grid>
    </Grid>
    );
}
 
export default El_caballo_hace_un_recorrido_semimágico;
