import { Button, makeStyles } from "@material-ui/core";
import React from "react";
import axios from "../../../../../../services/axios";

import SelectGradeGroup from "../../SelectGrades";

import SelectTipo from "../Selects/SelectTipo";

const useStyles = makeStyles((theme) => ({
  input: {
    width: "100%",
    height: "40px",
    paddingLeft: "27px",
    paddingRight: "27px",
    borderRadius: "61px",
    boxSizing: "border-box",
    border: "none",
    background: "#FFFFFF",
    font: "Helvetica",
    fontSize: "16px",
    backgroundColor: "#f0f0f0",
    "&:focus": {
      outline: "none",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "18px",
    },
    "-moz-appearance": "Textfield",
    "&::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
    },
    "&::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
    },
  },
  label: {
    fontWeight: "bold",
    fontFamily: "Lato, sans-serif",
  },
  PurpleButton: {
    color: "#FFFFFF",
    backgroundColor: "#9051b1",
    borderRadius: "30px",
    alignSelf: "center",
    // margin: "0 auto",

    width: "100%",
    maxWidth: "238px",
    minHeight: "45px",
    border: "none",
    textTransform: "unset",
    cursor: "pointer",
    fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
    fontWeight: "500",
    padding: "6px 16px",
    lineHeight: "1.75",
    transition:
      "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    boxShadow:
      "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
    "&:hover": {
      backgroundColor: "#B965D3 ",
    },
    [theme.breakpoints.up("lg")]: {
      // gridArea: "button",
    },
  },
}));

const CodigoGenerico = ({
  formData,
  handleForm,
  profileTypes,
  useTypes,
  handleResponseModalData,
}) => {
  const classes = useStyles();
  const defaultGradeList = [
    {
      id_grado: 1,
      grado: "primero",
    },
    {
      id_grado: 2,
      grado: "segundo",
    },
    {
      id_grado: 3,
      grado: "tercero",
    },
    {
      id_grado: 4,
      grado: "cuarto",
    },
    {
      id_grado: 5,
      grado: "quinto",
    },
    {
      id_grado: 6,
      grado: "sexto",
    },
  ];

  const crearCodigoGenerico = () => {
    const body = {
      id_tipo_codigo: formData?.tipo_codigo,
      id_tipo_uso: formData?.tipo_uso,
      id_rol: formData?.perfil,
      usuarios: [
        {
          id_grado: formData?.grado,
          cantidad: formData?.cantidad_codigos_genericos || 1,
        },
      ],
    };
    if (formData?.cantidad_codigos_genericos > 0) {
      axios
        .post("/user/codes", body)
        .then((response) => {
          if (response.status === 200) {
            const data = response.data.resultado;
            handleResponseModalData(data);
          }
        })
        .catch((error) => {
          handleResponseModalData(
            "Ha ocurrido un error al actualizar los datos. Intente de nuevo más tarde.",
            true
          );
        });
    } else {
      handleResponseModalData("Asegúrese de llenar todos los datos", true);
    }
  };
  return (
    <>
      <label className={classes.label}>Datos para el código</label>
      <label className={classes.label}>Cantidad de códigos</label>
      <input
        type="number"
        className={classes.input}
        name="cantidad_codigos_genericos"
        placeholder="Cantidad de códigos"
        value={formData.cantidad_codigos_genericos}
        onChange={handleForm}
        required
        onWheel={(e) => {
          e.target.blur();
        }}
      />
      <SelectTipo
        label="Tipo de uso"
        fieldName={"tipo_uso"}
        list={useTypes}
        formData={formData}
        handleForm={handleForm}
        type="uso"
      />
      <SelectTipo
        label="Perfil"
        fieldName={"perfil"}
        list={profileTypes}
        formData={formData}
        handleForm={handleForm}
        type="rol"
      />

      <SelectGradeGroup
        list={defaultGradeList}
        title="grado"
        handleForm={handleForm}
        defaultValue={formData?.grado}
        value={formData?.grado}
        formData={formData}
      />
      <Button className={classes.PurpleButton} onClick={crearCodigoGenerico}>
        Crear códigos
      </Button>
    </>
  );
};

export default CodigoGenerico;
