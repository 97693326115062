import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
     Contenedor:{
        background:'#E9FBF9',
        width:'100vw',
        overflowX:'clip',
        [theme.breakpoints.down(1161)]:{
            background:'none',
           overflowX:'unset',
        },
        [theme.breakpoints.down(600)]:{
            marginTop: '79.51px',
        }
    },
  ContainerInfoLectura: {
    position: "relative",
    height: "86px",
    [theme.breakpoints.down(502)]:{
        height: "43px",
    },
    background: "#76D4C3",
    "& .BarraInfoLectura": {
      height: "86px",
      background: `Url(https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Barras/Azul.png)`,
        [theme.breakpoints.down(502)]:{
            height: "43px",
        },
    },
    "& .InfoLectura": {
      position: "absolute",
      height: "86px",
      width: "219px",
      boxSizing: "border-box",
      padding: "17.2px 0",
      background: "#FAFAFA",
      borderRadius: "50px",
      boxShadow: "2px 2px 2px 2px #00000029",
      zIndex: "4",
        [theme.breakpoints.down(502)]:{
            height: "43px",
            width: "109px",
            padding: "8.6px 0",
            borderRadius: "25px",
        },
    },
    "& .InfoLectura:before": {
      position: "absolute",
      display: "flex",
      boxSizing: "border-box",
      padding: "0 0 0 10px",
      justifyContent: "center",
      alignItems: "center",
      height: "39.88px",
      width: "39.88px",
      borderRadius: "50%",
      content: '""',
      boxShadow: "3px 0px .5px 0px #00000029",
      background: "#FAFAFA",
      left: "90px",
      top: "65px",
      transform: "rotate(0.25turn)",
      [theme.breakpoints.down(502)]:{
        left: "45px",
        top: "30px",
        height: "20.88px",
        width: "20.88px",
        },
    },
    "& .InfoLectura:after": {
      content: '""',
      height: "15px",
      width: "15px",
      borderTop: "2px solid #20A698",
      borderLeft: "2px solid #20A698",
      position: "absolute",
      bottom: "-10px",
      left: "Calc(50% - 8px)",
      transform: "rotate(0.625turn)",
      [theme.breakpoints.down(502)]:{
        height: "10px",
        width: "10px",
        left: "Calc(50% - 4.5px)",
        bottom: "-3px",
        borderTop: "1px solid #20A698",
        borderLeft: "1px solid #20A698",
        },
    },
    "& .Sesion": {
      borderLeft: "2px solid #D8D8D8",
    },
    "& .Dato": {
      margin: "0 0 5px 0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#20A698",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        margin: "0 0 2.5px 0",
        },
    },
    "& .Caracter": {
      margin: "0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#000000",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        },
    },   },
    ContenedorLectura:{
        background:'#fff',
        width:'1160px',
        height:'100%',
        padding:'60px 168px 35px ',
        [theme.breakpoints.down(1161)]:{
            width:'502px',
            padding:'60px 0 0 0',
          },
        [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
        '& .ContainerDerechos':{
            bottom:'80px',
            [theme.breakpoints.down(1161)]:{
                display:'none',
            },
        },
        '& .Derechos':{
            textAlign:"center",
            margin:"0px",
            font:" 15px lato",
            color:"#717476",
        },
        '& .Escritorio':{
            display:'block',
            [theme.breakpoints.down(1161)]:{
                display:'none'
            },
        },
        '& .Tablet':{
            display:'none',
            [theme.breakpoints.down(1161)]:{
                display:'block'
            },
        }
    },
    ContainerTitulo:{
        margin:'0 0 34px 0',
        [theme.breakpoints.down(1161)]:{
            margin:'0 0 34px 0',
        },
        '& .Titulo':{
            font:'48px fredoka one',
            letterSpacing: '0px',
            lineHeight:'58px',
            color: '#CB3B35',
            margin:'0',
            textAlign:'center',
            [theme.breakpoints.down(1161)]:{
                font:'43px fredoka one',
                lineHeight:'52px',
            },
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            lineHeight : '30px',
            margin:'0 0 36px 0',
            font:'22px lato',
            color:'#000',
            letterSpacing: '0.20px',
            [theme.breakpoints.down(1161)]:{
                font:'18px lato',
                lineHeight :'28px',
                letterSpacing: '0.17px',
            },
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .SubTitulo':{
            font:'bold 30px lato',
            color:'#E6B516',
            letterSpacing:'.28px',
            lineHeight:'30px',
            margin:'0',
            [theme.breakpoints.down(1161)]:{
                lineHeight :'28px',
                font:'bold 26px lato',
                letterSpacing: '0.24px',
            },
            [theme.breakpoints.down(502)]:{
                font:'bold 18px lato',
                lineHeight :'24px',
                letterSpacing: '0.21px',
            },
        },
        '& .Cuadro':{
            position:'relative',
            
        },
        '& .Esp':{
            margin:'0',
        },
        '& .Esp2':{
            position:'absolute',
            width:'750px',
            margin:'53px 0 0 0',
            [theme.breakpoints.down(1161)]:{
                width:'85.25%',
            },
            [theme.breakpoints.down(502)]:{
                margin:'30px 0 0 0',
            },
        },
        '& .Imagen':{
            [theme.breakpoints.down(1161)]:{
                width:'100%',
            },
        },  
        '& .Imagen2':{
            [theme.breakpoints.down(502)]:{
                height:'190px',
            },
        }, 
        '& .Imagen3':{
            [theme.breakpoints.down(502)]:{
                height:'245px',
            },
        }, 
      
    }
}));


const Las_abejas_y_la_polinización = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
             <Grid container item justifyContent="center" className={classes.ContainerInfoLectura}>
               <Grid container className="BarraInfoLectura"></Grid>
               <Grid container item className="InfoLectura">
                    <Grid container item direction="column" xs={6} justifyContent="center">
                        <p className="Dato">Grado</p>
                        <p className="Caracter">3</p>
                    </Grid>
                    <Grid container item direction="column" xs={6} justifyContent="center" className="Sesion">
                        <p className="Dato">Sesión</p>
                        <p className="Caracter">4</p>
                    </Grid>
               </Grid>
            </Grid>

            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >Las abejas<br className='Tablet'/> y la polinización</h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                      <p className="Lectura">
                        Las abejas están más presentes en tu vida de lo que crees. Por ejemplo, la miel que 
                        comes en el desayuno es producto del trabajo de estos insectos, pero también 
                        muchas de las verduras que acompañan tu almuerzo existen gracias a ellas.
                      </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img3ro/Sesion04/2.png" alt="Tarjeta" />
                    </Grid>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container sm={12}>
                      <p className="Lectura Esp"> 
                        Existen alrededor de 20 000 subespecies de abejas. Pese a esta gran diversidad, sólo 
                        siete de ellas pueden generar miel. Esta característica ha sido aprovechada por los 
                        seres humanos para la producción de cera, polen y propóleo. No obstante, la 
                        importancia de las abejas va más allá de la producción de miel. ¿Sabías que existen 
                        unas abejas conocidas como <em>meliponas</em> que no tienen aguijón y que son polinizadoras 
                        naturales?
                      </p>
                    </Grid>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container item justifyContent='center' sm={12} className="Cuadro">
                      <img className="Imagen Escritorio" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img3ro/Sesion04/3.png" alt="Tarjeta" />
                      <img className="Imagen Imagen2 Tablet" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img3ro/Sesion04/3R.png" alt="Tarjeta" />
                      <p className="Lectura Esp2">
                        El polen son granos microscópicos que se producen en las <em>anteras</em> o el 
                        <em> estambre</em> de las flores. Recuerda que estas estructuras corresponden a la 
                        parte masculina de una flor, por lo que tienen que llegar al <em>estigma</em> de otra flor 
                        para reproducirse.
                      </p>
                    </Grid>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container sm={12}>
                      <p className="Lectura "> 
                        ¿Y cómo se lleva a cabo la polinización? Primero, las abejas salen de su enjambre en 
                        busca de néctar para alimentarse. Luego, las plantas atraen a las meliponas por los 
                        colores radiantes de las flores o por sus aromas dulces o ácidos. Y mientras las abejas 
                        sorben el néctar de las plantas, el polen se fija en las vellosidades de su cuerpo. De 
                        esta manera, estos insectos transportan de una planta a otra los granitos que 
                        originarán una nueva vida.<br/><br/> 
                        
                        Las abejas obreras son las mejor equipadas para transportar el polen. Ellas prefieren
                        visitar flores de la misma especie, lo que incrementa la probabilidad de su 
                        reproducción. Y si una sola abeja es capaz de visitar hasta 7 000 flores, ¡imagina 
                        cuántas plantas nuevas originará el trabajo de toda una colmena!<br/><br/>

                        <h3 className='SubTitulo'>No todo es miel sobre hojuelas</h3><br/> 

                        Las meliponas se están extinguiendo, ya que las actividades del ser humano 
                        ocasionan la pérdida de su hábitat. Por ejemplo, algunos agricultores emplean 
                        insecticidas que las afectan. Por otra parte, el crecimiento de las ciudades ha 
                        eliminado plantas de las que se alimentan. Y, por si fuera poco, se sospecha que 
                        algunas ondas, como las del radio o las que emiten los teléfonos celulares, 
                        desorientan a las abejas y les impiden regresar a sus panales.
                      </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img3ro/Sesion04/1.png" alt="Tarjeta" />
                    </Grid>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container item justifyContent='center' sm={12} className="Cuadro">
                      <img className="Imagen Escritorio" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img3ro/Sesion04/4.png" alt="Tarjeta" />
                      <img className="Imagen Imagen3 Tablet " src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img3ro/Sesion04/4R.png" alt="Tarjeta" />
                      <p className="Lectura Esp2 ">
                        Junto con otros insectos como las mariposas, los escarabajos o las moscas, las 
                        meliponas intervienen en la reproducción de árboles, flores y plantas, como 
                        el cedro o el laurel. Otras especies que participan en este proceso son las 
                        lagartijas, los colibríes y los murciélagos, los cuales ayudan a la reproducción 
                        de frutos y hortalizas para nuestro consumo.
                      </p>
                    </Grid>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container sm={12}>
                      <p className="Lectura Esp"> 
                        Para recordarnos su importancia, la Organización de las Naciones Unidas para la 
                        Alimentación y la Agricultura decretó el 20 de mayo como el Día Internacional de las 
                        Abejas. ¿Cómo podemos contribuir a que las abejas continúen con su trabajo? Para 
                        empezar, tenemos que perder el pánico y evitar matarlas. También podemos sembrar 
                        plantas con flores, principalmente de color azul, morado o amarillo, que son sus 
                        favoritas.
                      </p>
                    </Grid>
                </Grid>
               
            <Grid container item xs={12} justifyContent="center" className="ContainerDerechos">
                <p className="Derechos">
                    © 2022. Todos los derechos reservados, Pearson, Circuito de lectores.
                </p>                
            </Grid>
        </Grid>
    </Grid>
    );
}
 
export default Las_abejas_y_la_polinización;
