import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
      Contenedor:{
        background:'#E9FBF9',
        width:'100vw',
        overflowX:'clip',
        [theme.breakpoints.down(1161)]:{
            background:'none',
           overflowX:'unset',
        },
        [theme.breakpoints.down(600)]:{
            marginTop: '79.51px',
        }
    },
    ContainerInfoLectura:{
        position:'relative',
        height:'86px',
        background:'#76D4C3',
        '& .BarraInfoLectura':{
            height:'86px',
            background:`Url(https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Barras/Azul.png)`,
        },
        '& .InfoLectura':{
            position:'absolute',
            height:'86px',
            width:'219px',
            boxSizing:'border-box',
            padding:'17.2px 0',
            background:'#FAFAFA',
            borderRadius:'50px',
            boxShadow: '2px 2px 2px 2px #00000029',
            zIndex:'4',
        },
        '& .InfoLectura:before':{
            position:'absolute',
            display:'flex',
            boxSizing:'border-box',
            padding:'0 0 0 10px',
            justifyContent:'center',
            alignItems:'center',
            height:'39.88px',
            width:'39.88px',
            borderRadius:'50%',
            content:'""',
            boxShadow: '3px 0px .5px 0px #00000029',
            background:'#FAFAFA',
            left:'90px',
            top:'65px',
            transform: 'rotate(0.25turn)',
        },
        '& .InfoLectura:after':{
            content:'""',
            height:'15px',
            width:'15px',
            borderTop:'2px solid #20A698',
            borderLeft:'2px solid #20A698',
            position:'absolute',
            bottom:'-10px',
            left:'Calc(50% - 8px)',
            transform: 'rotate(0.625turn)',
        },
        '& .Sesion':{
            borderLeft:'2px solid #D8D8D8',
        },
        '& .Dato':{
            margin:'0 0 5px 0',
            textAlign:'center',
            font:'bold 17px lato',
            color:'#20A698',
        },
        '& .Caracter':{
            margin:'0',
            textAlign:'center',
            font:'bold 17px lato',
            color:'#000000',
        },

    },
    ContenedorLectura:{
        background:'#fff',
        width:'1160px',
        height:'100%',
        padding:'60px 164px 35px ' ,
        [theme.breakpoints.down(1161)]:{
            width:'502px',
            padding:'60px 0 0',
          },
        [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
        '& .ContainerDerechos':{
            bottom:'80px',
            [theme.breakpoints.down(1161)]:{
                display:'none',
            },
        },
        '& .Derechos':{
            textAlign:"center",
            margin:"0px",
            font:" 15px lato",
            color:"#717476",
        },  
        '& .Escritorio':{
            display:'block',
            [theme.breakpoints.down(1161)]:{
                display:'none'
            },
        },
        '& .Tablet':{
            display:'none',
            [theme.breakpoints.down(1161)]:{
                display:'block'
            },
        },

    },
    ContainerTitulo:{
        margin:'0 0 46px 0',
        [theme.breakpoints.down(1161)]:{
            margin:'0 0 38px 0',
        },
        '& .Titulo':{
            font:'48px fredoka one',
            letterSpacing: '0px',
            color: '#F9521F',
            margin:'0',
            textAlign:'center',
            [theme.breakpoints.down(1161)]:{
                font:'43px fredoka one',
                lineHeight:'52px',
                textAlign:'center',
            },
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 46px 0',
        '& .Lectura':{
            lineHeight : '30px',
            margin:'0 0 ',
            font:'22px lato',
            color:'#000',
            letterSpacing: '0.21px',
            [theme.breakpoints.down(1161)]:{
                lineHeight :'28px',
                font:'18px lato',
                letterSpacing: '0.17px',
            },
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .Imagen':{
            [theme.breakpoints.down(1161)]:{
                width:'100%',
            },
        },
    
    }

}));

const Cómo_combatir_la_violencia_de_género = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
             <Grid container item justifyContent="center" className={classes.ContainerInfoLectura}>
               <Grid container className="BarraInfoLectura"></Grid>
               <Grid container item className="InfoLectura">
                    <Grid container item direction="column" xs={6} justifyContent="center">
                        <p className="Dato">Grado</p>
                        <p className="Caracter">6</p>
                    </Grid>
                    <Grid container item direction="column" xs={6} justifyContent="center" className="Sesion">
                        <p className="Dato">Sesión</p>
                        <p className="Caracter">18</p>
                    </Grid>
               </Grid>
            </Grid>

            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo}  justifyContent="center">
                    <h2 className="Titulo Escritorio" >
                        ¿Cómo combatir <br/>la violencia de género?
                    </h2>
                    <h2 className="Titulo Tablet" >
                        ¿Cómo combatir la <br/>violencia de género?
                    </h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className=" Lectura "> 
                            ¿Alguna vez has querido hacer algo, pero te arrepientes por miedo a que te juzguen y 
                            te digan que esa actividad no te corresponde a ti sino al sexo contrario? <br/><br/>
                            
                            Quizá hayas escuchado frases como “las niñas deben portarse bien” o “los niños no 
                            deben llorar”. Son ideas expresadas como si realmente existieran características que 
                            fueran únicas de hombres o de mujeres. <br/><br/>
        
                            Por ello, vale la pena que reflexiones si esa división entre lo que se considera 
                            femenino o masculino es natural, social o psicológica. También es necesario que 
                            pienses cómo esta definición determina muchas acciones de las personas, sus 
                            creencias, gustos y preferencias. <br/><br/>
                            
                            Desde su nacimiento, a la mayoría de las personas se le imponen conductas 
                            específicas de acuerdo con su sexo. A esto se le llama <em>rol de género</em>, el cual determina 
                            cosas simples, como el tipo de ropa y de juguetes que se utilizan, pero incluso 
                            cuestiones más serias. Por ejemplo, ¿sabías que de acuerdo con la Organización de 
                            las Naciones Unidas (<span style={{fontVariant: "all-small-caps"}}>ONU</span>) las mujeres ganan en promedio 23 % menos que los 
                            hombres por el mismo tipo de trabajo realizado?<br/><br/>
                        </p>

                    </Grid>
                    <Grid containter>
                        <img className='Imagen' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion18/1.png" alt="Tarjeta" />
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className=" Lectura "> 
                            Gran parte de estos comportamientos que se relacionan con los roles de género 
                            provienen de uno de los mitos culturales más poderosos: pensar que las mujeres son 
                            inferiores a los hombres en fuerza física y moral, madurez e inteligencia, así como en 
                            muchas otras habilidades. <br/><br/>

                            Las personas que defienden esa falsa idea sostienen que tal inferioridad se explica 
                            debido a la diferencia de órganos reproductores. Es decir, argumentan que las 
                            mujeres son menos fuertes debido a su baja producción de testosterona, que es una 
                            hormona fundamental que permite el incremento de la masa muscular. Además, 
                            creen que, como las mujeres pueden engendrar a un ser vivo, su naturaleza es más 
                            emocional y menos racional que la de los hombres. <br/><br/>
                            
                            Ambas posturas son limitadas en su visión y discriminatorias. Si bien es cierto que los 
                            órganos sexuales y la producción de hormonas son diferentes entre hombres y 
                            mujeres, éstos sólo representan una pequeña dimensión que no explica ni justifica 
                            los mecanismos de control y dominio que se llevan a cabo desde lo masculino hacia lo 
                            femenino.<br/><br/>
                            
                            Por otra parte, si se tratara de un rasgo genuino de la naturaleza humana, entonces 
                            tendría que cumplirse en todos los casos. Todos los hombres tendrían que ser más 
                            fuertes e inteligentes que todas las mujeres, una condición que está totalmente 
                            alejada de la realidad. Para muestra de ello, lee algunos ejemplos históricos. <br/><br/>
                            
                            En el área científica, está Marie Curie. Ella no sólo fue la primera mujer en recibir un 
                            premio Nobel, sino que también fue la primera persona (considerando también a los 
                            hombres) en recibir dos premios Nobel en distintas disciplinas, Física y Química.<br/><br/>
                            
                            En el caso de fortaleza física, se puede mencionar a Amelia Earhart. Se trata de una 
                            de las pioneras en aviación y la primera mujer en sobrevolar el océano Atlántico 
                            completamente sola. También está el caso de Valentina Tereshkova, la primera 
                            cosmonauta, quien demostró que las mujeres tienen la misma fuerza que los 
                            hombres para viajar al espacio.<br/><br/>
                            
                            En las humanidades, destaca Simone de Beauvoir, filósofa y escritora francesa, quien 
                            reflexionó sobre la situación de las mujeres en la historia. Concluyó que la idea de 
                            “mujer” es un producto de la cultura.
                        </p>

                    </Grid>
                    <Grid containter>
                        <img className='Imagen' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion18/2.png" alt="Tarjeta" />
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className=" Lectura "> 
                            Pero no es necesario alejarse tanto o acudir a la historia. Seguramente tú conoces a 
                            muchas mujeres, familiares o amigas que admiras por su destreza intelectual, fuerza 
                            física o fortaleza interior. ¿Te das cuenta? La capacidad de las personas no depende 
                            de si son hombres o mujeres, sino de sus propias acciones. <br/><br/>
                            
                            Hace poco menos de un siglo, en 1948, la <span style={{fontVariant: "all-small-caps"}}>ONU</span> aprobó la Declaración Universal de los 
                            Derechos Humanos. Entre otras cosas, este documento buscaba eliminar las 
                            injusticias que se han cometido históricamente por distinciones de género y 
                            discriminación racial. Actualmente, la mayoría de las personas en el mundo cuenta 
                            con derecho al voto y a la educación sin importar el género, como lo señalan los 
                            artículos 21 y 26. <br/><br/>
                            
                            Aunque los avances son muy importantes y significativos, todavía falta bastante por 
                            hacer, así que reflexiona: ¿de qué manera crees que podrías contribuir a eliminar la 
                            violencia de género en tu vida cotidiana?
                        </p>
                    </Grid>
                </Grid>

                <Grid container item xs={12} justifyContent="center" className="ContainerDerechos">
                    <p className="Derechos">
                        © 2022. Todos los derechos reservados, Pearson, Circuito de lectores.
                    </p>                
                </Grid>
        </Grid>
    </Grid>
    );
}
 
export default Cómo_combatir_la_violencia_de_género;
