import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
     Contenedor:{
        background:'#E9FBF9',
        width:'100vw',
        overflowX:'clip',
        [theme.breakpoints.down(1161)]:{
            background:'none',
           overflowX:'unset',
        },
        [theme.breakpoints.down(600)]:{
            marginTop: '79.51px',
        }
    },
  ContainerInfoLectura: {
    position: "relative",
    height: "86px",
    [theme.breakpoints.down(502)]:{
        height: "43px",
    },
    background: "#76D4C3",
    "& .BarraInfoLectura": {
      height: "86px",
      background: `Url(https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Barras/Azul.png)`,
        [theme.breakpoints.down(502)]:{
            height: "43px",
        },
    },
    "& .InfoLectura": {
      position: "absolute",
      height: "86px",
      width: "219px",
      boxSizing: "border-box",
      padding: "17.2px 0",
      background: "#FAFAFA",
      borderRadius: "50px",
      boxShadow: "2px 2px 2px 2px #00000029",
      zIndex: "4",
        [theme.breakpoints.down(502)]:{
            height: "43px",
            width: "109px",
            padding: "8.6px 0",
            borderRadius: "25px",
        },
    },
    "& .InfoLectura:before": {
      position: "absolute",
      display: "flex",
      boxSizing: "border-box",
      padding: "0 0 0 10px",
      justifyContent: "center",
      alignItems: "center",
      height: "39.88px",
      width: "39.88px",
      borderRadius: "50%",
      content: '""',
      boxShadow: "3px 0px .5px 0px #00000029",
      background: "#FAFAFA",
      left: "90px",
      top: "65px",
      transform: "rotate(0.25turn)",
      [theme.breakpoints.down(502)]:{
        left: "45px",
        top: "30px",
        height: "20.88px",
        width: "20.88px",
        },
    },
    "& .InfoLectura:after": {
      content: '""',
      height: "15px",
      width: "15px",
      borderTop: "2px solid #20A698",
      borderLeft: "2px solid #20A698",
      position: "absolute",
      bottom: "-10px",
      left: "Calc(50% - 8px)",
      transform: "rotate(0.625turn)",
      [theme.breakpoints.down(502)]:{
        height: "10px",
        width: "10px",
        left: "Calc(50% - 4.5px)",
        bottom: "-3px",
        borderTop: "1px solid #20A698",
        borderLeft: "1px solid #20A698",
        },
    },
    "& .Sesion": {
      borderLeft: "2px solid #D8D8D8",
    },
    "& .Dato": {
      margin: "0 0 5px 0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#20A698",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        margin: "0 0 2.5px 0",
        },
    },
    "& .Caracter": {
      margin: "0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#000000",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        },
    },   },
    ContenedorLectura:{
        position:'relative',
        background:'#fff',
        width:'1160px',
        height:'100%',
        padding:'60px 168px 35px ',
        [theme.breakpoints.down(1161)]:{
            width:'502px',
            padding:'60px 0 0 0',
          },
        [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
        '& .ContainerDerechos':{
            bottom:'80px',
            [theme.breakpoints.down(1161)]:{
                display:'none',
            },
        },
        '& .Derechos':{
            textAlign:"center",
            margin:"0px",
            font:" 15px lato",
            color:"#717476",
        },
        '& .Escritorio':{
            display:'block',
            [theme.breakpoints.down(1161)]:{
                display:'none'
            },
        },
        '& .Tablet':{
            display:'none',
            [theme.breakpoints.down(1161)]:{
                display:'block'
            },
        }
    },

    ContainerTitulo:{
        margin:'0 0 42px 0',
        '& .Titulo':{
            letterSpacing: '0px',
            color: '#EEA90A',
            margin:'0',
            textAlign:'center',
            font:'48px fredoka one',
            [theme.breakpoints.down(1161)]:{
                font:'43px fredoka one',
                lineHeight:'52px',
            },
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            margin:'0 0 36px 0 ',
            color:'#000',
            font:'22px lato',
            letterSpacing: '0.21px',
            lineHeight:'38px',
            [theme.breakpoints.down(1161)]:{
                font:'18px lato',
                lineHeight :'31.5px',
                letterSpacing: '0.12px',
            },
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .Imagen':{
            width:'100%'
        },
        
    },
    

}));

const El_Coco = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
             <Grid container item justifyContent="center" className={classes.ContainerInfoLectura}>
               <Grid container className="BarraInfoLectura"></Grid>
               <Grid container item className="InfoLectura">
                    <Grid container item direction="column" xs={6} justifyContent="center">
                        <p className="Dato">Grado</p>
                        <p className="Caracter">3</p>
                    </Grid>
                    <Grid container item direction="column" xs={6} justifyContent="center" className="Sesion">
                        <p className="Dato">Sesión</p>
                        <p className="Caracter">27</p>
                    </Grid>
               </Grid>
            </Grid>

            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >
                        El Coco
                    </h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                        —¡Qué panzota! Seguro tiene lombrices. ¿Por qué no lo purgas? —dijo mi abuela.<br/><br/> 
                        
                        —¿Tú crees? —se asustó mamá—. Últimamente no quiere comer.<br/><br/> 
                        
                        —Chamaco remilgoso… Seguro tiene lombrices —insistió mi abuela.<br/><br/>
                        
                        Yo comprimí el aire para que no se me notara la panza.
                        </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img3ro/Sesion27/1.png" alt="Tarjeta" />
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                            Mi abuela me asusta. Sus ojos me persiguen por la casa. Vigilan que no agarre nada. 
                            Por más que dejo en su lugar los muñecos de porcelana, siempre nota que los tomé. 
                            La otra vez me regañó por usar al pastor como contrincante en la lucha entre 
                            soldados. <br/><br/>
                            
                            —Si te portas mal te llevará el Coco —me amenaza siempre. <br/><br/>
                            
                            Paso cada tarde con ella. Insiste en que debo comer verduras, pero no me gustan. En 
                            cambio, pruebo a escondidas cucharadas de azúcar y chocolate. Pero desde hace 
                            tiempo ya no. Aquella noche me desperté con un sabor salado en la boca. Soñaba que 
                            era marino. El barco se mecía y me sentí mareado. ¡Quería vomitar! <br/><br/>
                            
                            Mamá se preocupó. Yo estaba muy cansado. Me encogí entre las cobijas. Ella tocó mi 
                            frente. <br/><br/>
                            
                            —Creo que estás enfermo —dijo y me llevó al doctor. <br/><br/>
                            
                            Cuando regresamos fui directo al cuarto. Desde ahí las escuché cuchichear. Tenía
                            miedo. Seguramente hice algo malo. Tal vez vendría el Coco. <br/><br/>
                            
                            —Mañana te haremos análisis —explicó mamá cuando me arropó—. Los doctores 
                            quieren saber si tienes algo más. Pero duerme tranquilo.<br/><br/>
                            
                            Cuando apagó la luz y se fue, lo supe. ¡Se me había metido el Coco! <br/><br/>
                            
                            Temía que mi abuela me reclamara por haberlo traído. Pero, lejos de enojarse, fue 
                            comprensiva y cariñosa. Eso sí, seguía insistiendo para que me acabara las verduras. 
                            No está tan mal. Aunque mamá cada día se ve más triste. También me he dado cuenta 
                            de que el Coco no me deja ni jugar. Me da mucho sueño y me han seguido picando 
                            con agujas. Dicen que es por lo que tengo. Yo creo que ya hasta se me nota porque 
                            otros niños se me quedan viendo cuando salgo. Dice mamá que estoy muy pálido.<br/><br/>
                            
                            —¿Por qué no corres al Coco? ¡Quítamelo! —le pedí. <br/><br/>
                            
                            Mamá me abrazó. Me explicó que eso no existía mientras se esforzaba por no llorar. <br/><br/>
                            
                            —Estás enfermo, por eso debes seguir comiendo bien, dejarte cuidar y escucharnos. <br/><br/>
                            
                            Esa tarde mi abuela me sirvió hígado encebollado y chayotes. Dijo que me harían 
                            sentir mejor. Fue difícil, no me gustan, pero disfrutaba ver a mi abuela contenta 
                            mientras los degustaba. Le prometí que siempre los comería, incluso cuando esté 
                            viejito. <br/><br/>
                            
                            Han transcurrido 184 días desde entonces. Los hígados me han hecho bien. Bueno, 
                            eso y todos los tratamientos que he soportado. Mamá se ve contenta. Dice que muy 
                            pronto los doctores me darán de alta y podremos viajar y comer helado.
                        </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img3ro/Sesion27/2.png" alt="Tarjeta" />
                    </Grid>
                </Grid>

                <Grid container item xs={12} justifyContent="center" className="ContainerDerechos">
                    <p className="Derechos">
                        © 2022. Todos los derechos reservados, Pearson, Circuito de lectores.
                    </p>                
                </Grid>
        </Grid>
    </Grid>
    );
}
 
export default El_Coco;
