import React from 'react';
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  PurpleButton: {
    color: "#FFFFFF",
    backgroundColor: "#9051b1",
    maxWidth: "238px",
    border: "none",
    textTransform: "unset",
    cursor: "pointer",
    fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
    fontWeight: "500",
    padding: "6px 16px",
    lineHeight: "1.75",
    transition:
      "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    boxShadow: "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
    "&:hover": {
      backgroundColor: "#B965D3 ",
    },
  },
  menuItem: {
    padding: "0px",
    minHeight: "auto",

  },
  downloadText: {
    // backgroundColor: "#FFEDEA",
    width: "110px",
    // height: "40px",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    font: "500 12px lato",
    display: "flex",
    margin: 0,
    border: "2px solid #FFFFFF",
    textTransform: "none",
    textDecoration: "none",
  },
  textName: {
    font: " bold 22px lato",
    letterSpacing: "0.36px",
    color: 'blue',
    margin: '0px',
    textAlign: 'center',
    [theme.breakpoints.down(1025)]: {
      font: " 18px lato",
    },
    [theme.breakpoints.down(769)]: {
      font: " 16px lato",
    },
    [theme.breakpoints.down(425)]: {
      font: " 14px lato",
    },
  },
  favoriteText: {
    // backgroundColor: "#FFF9EB",
    // width: "100px",
    // height: "40px",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    font: "bold 14px lato",
    // display: "flex",
    margin: 0,
    // border: "2px solid #FFFFFF",
  },
  linkItem: {
    textTransform: "none",
    textDecoration: "none",
  },
  copyButton: {
    width: "30px",
    backgroundColor: '#ffffff',
    border: '#ffffff',
    cursor: "pointer",
    marginBottom: "2px"
    // "&:hover": {
    //   backgroundColor: "black ",
    // },
  },
  downloadExcel: {
    maxWidth: '50px',
    // position: 'absolute',
    // top: '10px',
    // right: '50px',
}
}));

const DownloadButton = (props) => {
  const classes = useStyles();
  const {downloadData} = props

  return ( 
      <>
      <div style={{maxWidth: '50px',}} className={classes.downloadExcel}>
        <p className={classes.favoriteText} >Descargar</p>
        <a href={downloadData} className={classes.linkItem}>
          Excel
        </a>
      </div>

      </>
    );
}
 
export default DownloadButton;
