import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
     Contenedor:{
        background:'#E9FBF9',
        width:'100vw',
        overflowX:'clip',
        [theme.breakpoints.down(1161)]:{
            background:'none',
           overflowX:'unset',
        },
        [theme.breakpoints.down(600)]:{
            marginTop: '79.51px',
        }
    },
  ContainerInfoLectura: {
    position: "relative",
    height: "86px",
    [theme.breakpoints.down(502)]:{
        height: "43px",
    },
    background: "#76D4C3",
    "& .BarraInfoLectura": {
      height: "86px",
      background: `Url(https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Barras/Azul.png)`,
        [theme.breakpoints.down(502)]:{
            height: "43px",
        },
    },
    "& .InfoLectura": {
      position: "absolute",
      height: "86px",
      width: "219px",
      boxSizing: "border-box",
      padding: "17.2px 0",
      background: "#FAFAFA",
      borderRadius: "50px",
      boxShadow: "2px 2px 2px 2px #00000029",
      zIndex: "4",
        [theme.breakpoints.down(502)]:{
            height: "43px",
            width: "109px",
            padding: "8.6px 0",
            borderRadius: "25px",
        },
    },
    "& .InfoLectura:before": {
      position: "absolute",
      display: "flex",
      boxSizing: "border-box",
      padding: "0 0 0 10px",
      justifyContent: "center",
      alignItems: "center",
      height: "39.88px",
      width: "39.88px",
      borderRadius: "50%",
      content: '""',
      boxShadow: "3px 0px .5px 0px #00000029",
      background: "#FAFAFA",
      left: "90px",
      top: "65px",
      transform: "rotate(0.25turn)",
      [theme.breakpoints.down(502)]:{
        left: "45px",
        top: "30px",
        height: "20.88px",
        width: "20.88px",
        },
    },
    "& .InfoLectura:after": {
      content: '""',
      height: "15px",
      width: "15px",
      borderTop: "2px solid #20A698",
      borderLeft: "2px solid #20A698",
      position: "absolute",
      bottom: "-10px",
      left: "Calc(50% - 8px)",
      transform: "rotate(0.625turn)",
      [theme.breakpoints.down(502)]:{
        height: "10px",
        width: "10px",
        left: "Calc(50% - 4.5px)",
        bottom: "-3px",
        borderTop: "1px solid #20A698",
        borderLeft: "1px solid #20A698",
        },
    },
    "& .Sesion": {
      borderLeft: "2px solid #D8D8D8",
    },
    "& .Dato": {
      margin: "0 0 5px 0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#20A698",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        margin: "0 0 2.5px 0",
        },
    },
    "& .Caracter": {
      margin: "0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#000000",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        },
    },   },
    ContenedorLectura:{
        background:'#fff',
        width:'1160px',
        height:'100%',
        padding:'60px 168px 35px ',
        [theme.breakpoints.down(1161)]:{
            width:'502px',
            padding:'60px 0 0 0',
          },
        [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
        '& .ContainerDerechos':{
            bottom:'80px',
            [theme.breakpoints.down(1161)]:{
                display:'none',
            },
        },
        '& .Derechos':{
            textAlign:"center",
            margin:"0px",
            font:" 15px lato",
            color:"#717476",
        },
        '& .Escritorio':{
            display:'block',
            [theme.breakpoints.down(1161)]:{
                display:'none'
            },
        },
        '& .Tablet':{
            display:'none',
            [theme.breakpoints.down(1161)]:{
                display:'block'
            },
        }
    },
    ContainerTitulo:{
        margin:'0 0 34px 0',
        [theme.breakpoints.down(1161)]:{
            margin:'0 0 34px 0',
        },
        '& .Titulo':{
            letterSpacing: '0px',
            color: '#6AB2BF',
            margin:'0',
            textAlign:'center',
            font:'48px fredoka one',
            lineHeight:'58px',
            [theme.breakpoints.down(1161)]:{
                font:'43px fredoka one',
                lineHeight:'52px',
            },
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            lineHeight : '30px',
            margin:'0 0 36px 0',
            font:'22px lato',
            color:'#000',
            letterSpacing: '0.20px',
            [theme.breakpoints.down(1161)]:{
                font:'18px lato',
                lineHeight :'28px',
                letterSpacing: '0.17px',
            },
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .SubTitulo':{
            color:'#FC5E9E',
            margin:'0',
            font:'bold 30px lato',
            letterSpacing:'.28px',
            lineHeight:'30px',
            [theme.breakpoints.down(1161)]:{
                lineHeight :'28px',
                font:'bold 26px lato',
                letterSpacing: '0.24px',
            },
            [theme.breakpoints.down(502)]:{
                font:'bold 18px lato',
                lineHeight :'24px',
                letterSpacing: '0.21px',
            },
        },
        '& .Imagen':{
            width:'100%',
        },   
    }
}));


const El_mundo_que_estamos_recibiendo = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
             <Grid container item justifyContent="center" className={classes.ContainerInfoLectura}>
               <Grid container className="BarraInfoLectura"></Grid>
               <Grid container item className="InfoLectura">
                    <Grid container item direction="column" xs={6} justifyContent="center">
                        <p className="Dato">Grado</p>
                        <p className="Caracter">4</p>
                    </Grid>
                    <Grid container item direction="column" xs={6} justifyContent="center" className="Sesion">
                        <p className="Dato">Sesión</p>
                        <p className="Caracter">18</p>
                    </Grid>
               </Grid>
            </Grid>

            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >El mundo que<br/> estamos recibiendo</h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container sm={12}>
                      <p className="Lectura "> 
                            Es el primer día de clases y te entusiasma regresar a la escuela, pues has esperado 
                            todo el verano para esto. Te apresuras abriéndote paso entre mochilas y otros 
                            estudiantes igualmente emocionados. Al llegar al salón te sorprendes, ya que no es lo 
                            que esperabas. El piso se ve sucio, las bancas están rotas, las ventanas no abren y 
                            esto hace que el calor sea insoportable. Te diriges al sanitario y te das cuenta de que 
                            no hay agua en el lavamanos. Caminas hacia la cafetería, pero la fila es inmensa y el 
                            desayuno no alcanza para todos. Miras a tu alrededor y observas una montaña de 
                            basura desbordándose de un contenedor. Entonces te preguntas: “¿Qué ocurrió 
                            aquí?”<br/><br/> 
                            
                            Esta situación imaginaria es similar a lo que ocurre realmente en el mundo que 
                            heredamos: las ciudades, el campo, la sociedad y la cultura. Reflexionemos acerca de 
                            por qué se requieren acciones urgentes ante la gran cantidad de problemas que 
                            enfrentamos.
                      </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img4to/Sesion18/1.png" alt="Tarjeta" />
                    </Grid>
                </Grid>
               
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container item justifyContent='center' sm={12}>
                        <Grid container sm={12}>
                            <p className="Lectura "> 
                                <h3 className='SubTitulo'>Cambio climático</h3><br/>

                                Los seres vivos que habitamos este planeta podemos vivir bajo ciertas condiciones 
                                de clima. Un cambio en estas condiciones ocasiona problemas en los ecosistemas y 
                                puede llevar a la desaparición de una gran cantidad de especies de plantas y de 
                                animales. El cambio climático es un fenómeno producido principalmente por los 
                                gases de efecto invernadero, los cuales son generados por las actividades humanas. 
                                La consecuencia es que la temperatura del planeta ha aumentado, lo que ocasiona 
                                sequías, poca producción agrícola y la desaparición de hábitats, que hace migrar a los 
                                animales. Seguramente has visto videos que muestran que los glaciares en distintas 
                                zonas del mundo se están derritiendo, elevando así el nivel del mar por el hielo que 
                                se derrite. Es importante que los gobiernos tomen medidas inmediatas ante esta 
                                adversidad, pero ¿qué acciones puedes tomar tú en tu vida diaria para ayudar a 
                                combatir el cambio climático?<br/><br/>
                                
                                <h3 className='SubTitulo'>La escasez de recursos naturales</h3><br/>
                                
                                Los recursos que ofrece el planeta son limitados, por lo que un día podrían 
                                terminarse. Esto se debe a la explotación irresponsable de los recursos. Por ejemplo, 
                                se estima que 2,200 millones de personas no tienen acceso a agua potable. El 
                                problema del agua es aún más grave porque algún día podría terminarse si no 
                                hacemos algo ya. ¿Te has puesto a pensar qué sucedería si no hubiera agua? ¿Qué 
                                pasaría con la producción de alimentos? ¿Con la vida en general? <br/><br/>
                                
                                Otro problema es el acceso a los servicios médicos. Un ejemplo es la pandemia de 
                                Covid-19, pues podemos ver que todos los países tienen capacidades distintas para 
                                atender la salud de las personas. La diferencia se nota igualmente en la distribución 
                                de las vacunas, ya que no todos los países pueden asegurar que sus habitantes 
                                recibirán la vacuna. La mala distribución del dinero en el mundo hace que para 
                                algunas naciones sea difícil tener servicio médico.
                            </p>
                        </Grid>
                        <Grid container item justifyContent='center' sm={12}>
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img4to/Sesion18/2.png" alt="Tarjeta" />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container sm={12}>
                        <p className="Lectura "> 
                            Antes de alarmarnos, pensemos: ¿qué podemos hacer en este momento de nuestras vidas para reducir 
                            los problemas que nos fueron heredados? ¿Qué actividades consideras que pueden contribuir a
                            cambiar el mundo que estamos recibiendo? ¿Crees que algunas de las actividades que realizas 
                            día con día pueden contribuir a cambiar el mundo? ¿Cuáles serían? Por ejemplo, no desperdiciar 
                            tu almuerzo y compartirlo con algún compañero, ayudar a los adultos a reducir y separar los 
                            residuos que se generan, usar adecuadamente el agua. Todas estas acciones serán de gran ayuda 
                            para ti y para los demás, así que ¡manos a la obra!
                        </p>
                    </Grid>
                </Grid>

                <Grid container item xs={12} justifyContent="center" className="ContainerDerechos">
                    <p className="Derechos">
                        © 2022. Todos los derechos reservados, Pearson, Circuito de lectores.
                    </p>                
                </Grid>
            </Grid>
        </Grid>
    );
}
 
export default El_mundo_que_estamos_recibiendo;
