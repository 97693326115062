import React from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core";
import NavBar from "../../NavBar";
import Perfil from "../../Perfil_del_alumno";
import MenuIzquierdo from "../../MenuIzquierdo";
const useStyles = makeStyles((theme) => ({
  grid1: {
    zIndex: "3",
  },
  grid2: {
    zIndex: "2",
  },
  grid3: {
    boxSizing: "border-box",
    zIndex: "1",
    paddingLeft: "3%",
    width: "100%",
    display: "flex",
    minHeight:'calc(100vh - 80px)',
    // alignContent: "center",
    // justifyContent: "center",
    // alignItems: "center",
    [theme.breakpoints.down("md")]: {
      paddingLeft: "0px",
    },
  },
}));

function ContenedorPerfil_del_alumno() {
  const classes = useStyles();
  return (
    <Grid container>
      <Grid item className={classes.grid1} sm={12}>
        <NavBar />
      </Grid>
      <Grid item className={classes.grid2} sm={1}>
        <MenuIzquierdo />
      </Grid>
      <Grid item className={classes.grid3} sm={12}>
        <Perfil />
      </Grid>
    </Grid>
  );
}

export default ContenedorPerfil_del_alumno;
