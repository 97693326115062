import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core";
import { Link } from "react-router-dom";
import Mapa from "../imgs/Mapa.png";
import MapaActive from "../imgs/MapaActive.png";
import Lectura from "../imgs/Lectura.png";
import LecturaActive from "../imgs/LecturaActive.png";
import Chat from "../imgs/Chat.png";
import ChatActive from "../imgs/ChatActive.png";
import Practica from "../imgs/Practica.png";
import PracticaActive from "../imgs/PracticaActive.png";

import Legales from "../imgs/Legales.png";
import LegalesActive from "../imgs/LegalesActive.png";
import Manual from "../imgs/Manual.png";
import ManualActive from "../imgs/ManualActive.png";
import Salir from "../imgs/cerrar.png";
import SalirActive from "../imgs/cerrarActive.png";
import Creditos from "../imgs/Creditos.png";
import CreditosActive from "../imgs/creditosActive.png";
import Configuracion from "../imgs/Configuracion.png";
import ConfiguracionActive from "../imgs/ConfiguracionActive.png";
import Guia from "../imgs/Guia.png";
import GuiaActive from "../imgs/GuiaActive.png";
import Reporte from "../imgs/Reporte.png";
import ReporteActive from "../imgs/ReporteActive.png";
import Videos from "../imgs/Videos.png";
import VideosActive from "../imgs/VideosActive.png";

import tutorial from "../imgs/tutorial.png";
import tutorialActive from "../imgs/tutorialActive.png";

const useStyles = makeStyles((theme) => ({
  container: {
    position: "relative",
    width: "100%",
    textDecoration: "none",
  },
  button: {
    // display: "flex",
    minWidth: "100%",
    minHeight: "35px",
    alignItems: "center",
    cursor: "pointer",
    border: "none",
    backgroundColor: "#0F5F7F",
    backgroundRepeat: "no-repeat",
    backgroundSize: "22px 22px",
    backgroundPosition: "85% center",
    borderBottom: "4px solid",
    paddingLeft: "10%",
    textAlign: "start",
    borderRadius: "0px",

    // padding: "0 20px",
    // color: "red",
    position: "relative",
    "& span": {
      gap: "10px",
    },
    textDecoration: "none",
    textTransform: "none",
    margin: "0",
    color: "#FFFFFF",
    font: " 600 16px Lato;",

    [theme.breakpoints.up("sm")]: {
      paddingLeft: "15%",
    },
    // justifyContent: "center",
    [theme.breakpoints.up("lg")]: {
      padding: (props) => (props.openMenuInDesktop ? "0 10%" : "0"),
      // justifyContent: (props) =>
      //   props.openMenuInDesktop ? "space-between" : "center",
      fontSize: (props) => (props.openMenuInDesktop ? "16px" : "0"),
      backgroundPosition: (props) =>
        props.openMenuInDesktop ? "85% center" : "center",
    },
    "&:focus": {
      backgroundColor: "#FFF8F7 ",
      color: "#575757",
    },
    "@media(min-height:600px)": {
      minHeight: "40px",
    },
    "@media(min-height:720px)": {
      minHeight: "50px",
    },
    "@media(min-height:1024px)": {
      minHeight: "60px",
    },
  },

  Mapa: {
    borderColor: "#F2624F",
    backgroundImage: `url(${Mapa})`,
    "&:hover": {
      backgroundColor: "#ED7C69",
      "&:before": {
        display: (props) => (props.openMenuInDesktop ? "none" : "flex"),
        zIndex: 1,
        textTransform: "none",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "25px",
        position: "absolute",
        content: '"Mapa"',
        left: "105%",
        height: "16px",
        width: "80px",
        backgroundColor: "#fff",
        color: "#575757",
        font: "bold 14px lato",
      },
    },
    "&:focus": {
      backgroundColor: "#fff",
      backgroundImage: `url(${MapaActive})`,
    },
  },
  MapaTutorial: {
    borderColor: "#F2624F",
    backgroundImage: `url(${Mapa})`,
    boxShadow: "0px 0px 15px 20px #fff, inset 5px -1px 10px 0px #fff",
    "&:hover": {
      backgroundColor: "#ED7C69",
      "&:before": {
        display: (props) => (props.openMenuInDesktop ? "none" : "flex"),
        zIndex: 1,
        textTransform: "none",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "25px",
        position: "absolute",
        content: '"Mapa"',
        left: "105%",
        height: "16px",
        width: "80px",
        backgroundColor: "#fff",
        color: "#575757",
        font: "bold 14px lato",
      },
    },
    "&:focus": {
      backgroundColor: "#fff",
      backgroundImage: `url(${MapaActive})`,
    },
  },

  Chat: {
    borderColor: "yellow",
    backgroundImage: `url(${Chat})`,
    position: "relative",
    "&:hover": {
      backgroundColor: "#ffd700",
      "&:before": {
        display: (props) => (props.openMenuInDesktop ? "none" : "flex"),
        textTransform: "none",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "25px",
        position: "absolute",
        content: '"Chat"',
        left: "105%",
        height: "16px",
        width: "80px",
        backgroundColor: "#fff",
        color: "#575757",
        font: "bold 14px lato",
      },
    },
    "&:focus": {
      backgroundColor: "#fff",
      backgroundImage: `url(${ChatActive})`,
    },
  },
  Practicas: {
    backgroundImage: `url(${Practica})`,
    position: "relative",
    borderColor: "#FF5E93",
    "&:hover": {
      backgroundColor: "#ff3377",
      "&:before": {
        display: (props) => (props.openMenuInDesktop ? "none" : "flex"),
        textTransform: "none",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "25px",
        position: "absolute",
        content: '"Prácticas guiadas"',
        left: "105%",
        height: "16px",
        width: "160px",
        backgroundColor: "#fff",
        color: "#575757",
        font: "bold 14px lato",
      },
    },
    "&:focus": {
      backgroundColor: "#fff",
      backgroundImage: `url(${PracticaActive})`,
    },
  },
  Lectura: {
    borderColor: "#cd00cd",
    backgroundImage: `url(${Lectura})`,
    "&:hover": {
      backgroundColor: "#8B0C9B",
      "&:before": {
        display: (props) => (props.openMenuInDesktop ? "none" : "flex"),
        textTransform: "none",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "25px",
        position: "absolute",
        content: '"Biblioteca virtual"',
        left: "105%",
        height: "16px",
        width: "170px",
        backgroundColor: "#fff",
        color: "#575757",
        font: "bold 14px lato",
      },
    },
    "&:focus": {
      backgroundColor: "#fff",
      backgroundImage: `url(${LecturaActive})`,
    },
  },
  Manual: {
    backgroundImage: `url(${Manual})`,
    borderColor: "#5DD4AC",
    "&:hover": {
      backgroundColor: "#3DD593",
      "&:before": {
        display: (props) => (props.openMenuInDesktop ? "none" : "flex"),
        textTransform: "none",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "25px",
        position: "absolute",
        content: '"Manual de usuario"',
        left: "105%",
        height: "16px",
        width: "150px",
        backgroundColor: "#fff",
        color: "#575757",
        font: "bold 14px lato",
      },
    },
    "&:focus": {
      backgroundColor: "#fff",
      backgroundImage: `url(${ManualActive})`,
    },
  },
  Manuales: {
    backgroundImage: `url(${Manual})`,
    borderColor: "#5DD4AC",
    "&:hover": {
      backgroundColor: "#3DD593",
      "&:before": {
        display: (props) => (props.openMenuInDesktop ? "none" : "flex"),
        textTransform: "none",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "25px",
        position: "absolute",
        content: '"Manuales de usuario"',
        left: "105%",
        height: "16px",
        width: "150px",
        backgroundColor: "#fff",
        color: "#575757",
        font: "bold 14px lato",
      },
    },
    "&:focus": {
      backgroundColor: "#fff",
      backgroundImage: `url(${ManualActive})`,
    },
  },
  Sesiones: {
    backgroundImage: `url(${Legales})`,
    borderColor: "#F2624F",
    "&:hover": {
      backgroundColor: "#ED7C69 ",
      "&:before": {
        display: (props) => (props.openMenuInDesktop ? "none" : "flex"),
        textTransform: "none",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "25px",
        position: "absolute",
        content: '"Sesiones"',
        left: "105%",
        height: "16px",
        width: "80px",
        backgroundColor: "#fff",
        color: "#575757",
        font: "bold 14px lato",
      },
    },
    "&:focus": {
      backgroundColor: "#F8F8F8",
      backgroundImage: `url(${LegalesActive})`,
    },
  },
  Guia: {
    backgroundImage: `url(${Guia})`,
    borderColor: "#F1CB5A",
    "&:hover": {
      backgroundColor: "#D6AE2C",
      "&:before": {
        display: (props) => (props.openMenuInDesktop ? "none" : "flex"),
        textTransform: "none",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "25px",
        position: "absolute",
        content: '"Recursos del docente"',
        left: "105%",
        height: "16px",
        width: "170px",
        backgroundColor: "#fff",
        color: "#575757",
        font: "bold 14px lato",
      },
    },
    "&:focus": {
      backgroundColor: "#F8F8F8 ",
      backgroundImage: `url(${GuiaActive})`,
    },
  },
  Reporte: {
    backgroundImage: `url(${Reporte})`,
    borderColor: "#2EDAC9",
    "&:hover": {
      backgroundColor: "#25BAAC",
      "&:before": {
        display: (props) => (props.openMenuInDesktop ? "none" : "flex"),
        textTransform: "none",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "25px",
        position: "absolute",
        content: '"Reportes"',
        left: "105%",
        height: "16px",
        width: "80px",
        backgroundColor: "#fff",
        color: "#575757",
        font: "bold 14px lato",
      },
    },
    "&:focus": {
      backgroundColor: "#F8F8F8 ",
      backgroundImage: `url(${ReporteActive})`,
    },
  },
  Videos: {
    backgroundImage: `url(${Videos})`,
    borderColor: "#F2624F",
    "&:hover": {
      backgroundColor: "#F6644B",
      "&:before": {
        display: (props) => (props.openMenuInDesktop ? "none" : "flex"),
        textTransform: "none",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "25px",
        position: "absolute",
        content: '"Videos remediales"',
        left: "105%",
        height: "16px",
        width: "170px",
        backgroundColor: "#fff",
        color: "#575757",
        font: "bold 14px lato",
      },
    },
    "&:focus": {
      backgroundColor: "#F8F8F8 ",
      backgroundImage: `url(${VideosActive})`,
    },
  },
  Creditos: {
    backgroundImage: `url(${Creditos})`,
    borderColor: "#F1CB5A",
    "&:hover": {
      backgroundColor: "#D6AE2C",
      "&:before": {
        display: (props) => (props.openMenuInDesktop ? "none" : "flex"),
        textTransform: "none",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "25px",
        position: "absolute",
        content: '"Créditos"',
        left: "105%",
        height: "16px",
        width: "80px",
        backgroundColor: "#fff",
        color: "#575757",
        font: "bold 14px lato",
      },
    },
    "&:focus": {
      backgroundColor: "#F8F8F8 ",
      backgroundImage: `url(${CreditosActive})`,
    },
  },

  Configuración: {
    backgroundImage: `url(${Configuracion})`,
    borderColor: "#DC5EA6",
    "&:hover": {
      backgroundColor: "#E65FAC",
      "&:before": {
        display: (props) => (props.openMenuInDesktop ? "none" : "flex"),
        textTransform: "none",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "25px",
        position: "absolute",
        content: '"Configuración"',
        left: "105%",
        height: "16px",
        width: "100px",
        backgroundColor: "#fff",
        color: "#575757",
        font: "bold 14px lato",
      },
    },
    "&:focus": {
      backgroundColor: "#F8F8F8 ",
      backgroundImage: `url(${ConfiguracionActive})`,
    },
  },
  Legales: {
    backgroundImage: `url(${Legales})`,
    borderColor: "#FEFAF1",
    "&:hover": {
      backgroundColor: "#707070",
      "&:before": {
        display: (props) => (props.openMenuInDesktop ? "none" : "flex"),
        textTransform: "none",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "25px",
        position: "absolute",
        content: '"Legales"',
        left: "105%",
        height: "16px",
        width: "80px",
        backgroundColor: "#fff",
        font: "bold 14px lato",
      },
    },
    "&:focus": {
      backgroundColor: "#F8F8F8 ",
      backgroundImage: `url(${LegalesActive})`,
    },
  },

  Cerrar: {
    backgroundImage: `url(${Salir})`,
    "&:hover": {
      backgroundColor: "#D9DF61",
      "&:before": {
        display: (props) => (props.openMenuInDesktop ? "none" : "flex"),
        textTransform: "none",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "25px",
        position: "absolute",
        content: '"Cerrar sesión"',
        left: "105%",
        height: "16px",
        width: "110px",
        backgroundColor: "#fff",
        color: "#575757",
        font: "bold 14px lato",
      },
    },
    borderBottom: " 0px",
    backgroundColor: "#0F5F7F",
    width: (props) => (props.openMenuInDesktop ? 280 : "3%"),
    "& .text": {
      font: "bold 17px lato",
    },
    "&:focus": {
      backgroundColor: "#F8F8F8 ",
      backgroundImage: `url(${SalirActive})`,
    },
  },
  //Admin

  Perfil: {
    borderColor: "#F2624F",
    backgroundImage: `url(${Legales})`,
    "&:hover": {
      backgroundColor: "#ED7C69",
      "&:before": {
        display: (props) => (props.openMenuInDesktop ? "none" : "flex"),
        textTransform: "none",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "25px",
        position: "absolute",
        content: '"Perfil"',
        left: "105%",
        height: "16px",
        width: "70px",
        backgroundColor: "#fff",
        color: "#575757",
        font: "bold 14px lato",
      },
    },
    "&:focus": {
      backgroundColor: "#F8F8F8",
      backgroundImage: `url(${LegalesActive})`,
    },
  },
  Escuelas: {
    borderColor: "#F2624F",
    backgroundImage: `url(${Legales})`,

    "&:hover": {
      backgroundColor: "#ED7C69",
      "&:before": {
        display: (props) => (props.openMenuInDesktop ? "none" : "flex"),
        textTransform: "none",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "25px",
        position: "absolute",
        content: '"Escuelas"',
        left: "105%",
        height: "16px",
        width: "90px",
        backgroundColor: "#fff",
        color: "#575757",
        font: "bold 14px lato",
      },
    },
    "&:focus": {
      backgroundColor: "#F8F8F8",
      backgroundImage: `url(${LegalesActive})`,
    },
  },

  CargarDatos: {
    borderColor: "#B965D3",
    backgroundImage: `url(${Guia})`,
    "&:hover": {
      backgroundColor: "#823CE2",
      "&:before": {
        display: (props) => (props.openMenuInDesktop ? "none" : "flex"),
        textTransform: "none",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "25px",
        position: "absolute",
        content: '"Carga de datos"',
        left: "105%",
        height: "16px",
        width: "120px",
        backgroundColor: "#fff",
        color: "#575757",
        font: "bold 14px lato",
      },
    },
    "&:focus": {
      backgroundColor: "#F8F8F8",
      backgroundImage: `url(${GuiaActive})`,
    },
  },

  Lms: {
    borderColor: "#B965D3",
    backgroundImage: `url(${Mapa})`,
    "&:hover": {
      backgroundColor: "#823CE2",
      "&:before": {
        display: (props) => (props.openMenuInDesktop ? "none" : "flex"),
        textTransform: "none",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "25px",
        position: "absolute",
        content: '"Integración LMS"',
        left: "105%",
        height: "16px",
        width: "120px",
        backgroundColor: "#fff",
        color: "#575757",
        font: "bold 14px lato",
      },
    },
    "&:focus": {
      backgroundColor: "#F8F8F8",
      backgroundImage: `url(${MapaActive})`,
    },
  },
  iconNewMessage: {
    transform: "rotate(0deg)",
    borderRadius: "24.6px",
    backgroundColor: "#F6644B",
    width: "20px",
    height: "20px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "12px",
    position: "absolute",
    right: "9%",
    top: "14%",
    /* UI Properties */
    color: "#fff",
    [theme.breakpoints.up("lg")]: {
      fontSize: "10px",
      minWidth: "15px",
      height: "15px",
      borderRadius: "15px",
    },
  },
  tour: {
    backgroundImage: `url(${tutorial})`,
    borderColor: "#5DD4AC",
    display: "none",
    "&:hover": {
      backgroundColor: "#3DD593",
      "&:before": {
        display: (props) => (props.openMenuInDesktop ? "none" : "flex"),
        textTransform: "none",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "25px",
        position: "absolute",
        content: '"Tutorial"',
        left: "105%",
        height: "16px",
        width: "80px",
        backgroundColor: "#fff",
        color: "#575757",
        font: "bold 14px lato",
      },
    },
    "&:focus": {
      backgroundColor: "#fff",
      backgroundImage: `url(${tutorialActive})`,
    },
    [theme.breakpoints.up("lg")]: {
      display: "inline-block",
    },
  },
}));

const NavButton = ({
  title,
  to,
  handler,
  icon,
  openMenuInDesktop,
  openMenuInMobile,
  id,
  notifications,
}) => {
  const styles = useStyles({ openMenuInDesktop, openMenuInMobile });

  return (
    <>
      {to ? (
        <Link
          className={styles.container}
          to={{
            pathname: to,
          }}
          aria-label={`Botón de redirección a ${title}`}
        >
          <button
            className={clsx(`${styles[icon]} ${styles.button}`)}
            onClick={handler}
            id={id}
          >
            {title === "Chat" && (
              <>
                {notifications > 0 && (
                  <span className={styles.iconNewMessage}>{notifications}</span>
                )}
              </>
            )}
            {title}
          </button>
        </Link>
      ) : (
        <button
          className={clsx(`${styles[icon]} ${styles.button}`)}
          onClick={handler}
          id={id}
        >
          {title}
          {/* <span className="text">{}</span>
          <span className="icon" /> */}
        </button>
      )}
    </>
  );
};

export default NavButton;
