import { Button, Grid, makeStyles } from "@material-ui/core";
import React, { useContext, useEffect } from "react";
import DrawerContext from "../../../../../usability/DrawerContext";
import { useOnLoadImages } from "../../../../hooks/useOnLoadImages";
import Loader from "../../../../Loader/Loader";
import Drawer from "../../ReadingTools/Drawer";

const useStyles =  makeStyles (theme =>({
    Contenedor:{
       background:'#E9FBF9',
       width:'100vw',
       [theme.breakpoints.down(1280)]:{
           background:'none',
       },
       [theme.breakpoints.down(600)]:{
           marginTop: '79.51px',
       }
   },
  ContainerInfoLectura: {
    position: "relative",
    height: "86px",
    [theme.breakpoints.down(502)]:{
        height: "43px",
    },
    background: "#76D4C3",
    "& .BarraInfoLectura": {
      height: "86px",
      background: `Url(https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Barras/Azul.png)`,
        [theme.breakpoints.down(502)]:{
            height: "43px",
        },
    },
    "& .InfoLectura": {
      position: "absolute",
      height: "86px",
      width: "219px",
      boxSizing: "border-box",
      padding: "17.2px 0",
      background: "#FAFAFA",
      borderRadius: "50px",
      boxShadow: "2px 2px 2px 2px #00000029",
      zIndex: "4",
        [theme.breakpoints.down(502)]:{
            height: "43px",
            width: "109px",
            padding: "8.6px 0",
            borderRadius: "25px",
        },
    },
    "& .InfoLectura:before": {
      position: "absolute",
      display: "flex",
      boxSizing: "border-box",
      padding: "0 0 0 10px",
      justifyContent: "center",
      alignItems: "center",
      height: "39.88px",
      width: "39.88px",
      borderRadius: "50%",
      content: '""',
      boxShadow: "3px 0px .5px 0px #00000029",
      background: "#FAFAFA",
      left: "90px",
      top: "65px",
      transform: "rotate(0.25turn)",
      [theme.breakpoints.down(502)]:{
        left: "45px",
        top: "30px",
        height: "20.88px",
        width: "20.88px",
        },
    },
    "& .InfoLectura:after": {
      content: '""',
      height: "15px",
      width: "15px",
      borderTop: "2px solid #20A698",
      borderLeft: "2px solid #20A698",
      position: "absolute",
      bottom: "-10px",
      left: "Calc(50% - 8px)",
      transform: "rotate(0.625turn)",
      [theme.breakpoints.down(502)]:{
        height: "10px",
        width: "10px",
        left: "Calc(50% - 4.5px)",
        bottom: "-3px",
        borderTop: "1px solid #20A698",
        borderLeft: "1px solid #20A698",
        },
    },
    "& .Sesion": {
      borderLeft: "2px solid #D8D8D8",
    },
    "& .Dato": {
      margin: "0 0 5px 0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#20A698",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        margin: "0 0 2.5px 0",
        },
    },
    "& .Caracter": {
      margin: "0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#000000",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        },
    },
       '& .Regresar':{
        color: '#F2624F',
        border: '#F2624F 1px solid',
        height: '30px',
        width:'100px',
        borderRadius: '20px',
        letterSpacing: '0.14px',
        textTransform: 'none',
        background:'#FEFAF1',
        top:'calc(50% - 15px)',
        left:'5%',
        zIndex:4,
        [theme.breakpoints.down(502)]: {
          font:'bold 10px lato',
          width: "60px",
        },
      },
  },
   ContenedorLectura:{
       background:'#fff',
       width:'1160px',
       height:'100%',
       padding:'60px 168px 35px ',
        
        transform: (props) =>
        props.scale > 1 ? `scale(${props.scale}) translate(0px, 10%)` : `none`,
       [theme.breakpoints.down(1280)]:{
           width:'502px',
           padding:'60px 0 0 0',
         },
                [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
       '& .ContainerDerechos':{
           bottom:'80px',
           [theme.breakpoints.down(1280)]:{
               display:'none',
           },
       },
       '& .Derechos':{
           textAlign:"center",
           margin:"0px",
           font:" 15px lato",
           color:"#717476",
       },
       '& .Escritorio':{
           display:'block',
           [theme.breakpoints.down(1280)]:{
               display:'none'
           },
       },
       '& .Tablet':{
           display:'none',
           [theme.breakpoints.down(1280)]:{
               display:'block'
           },
       }
   },
    ContainerTitulo:{
        margin:'0 0 46px 0',
        '& .Titulo':{
            letterSpacing: '0px',
            color: '#926DD1',
            margin:'0',
            textAlign:'center',
            font:'48px fredoka one',
            [theme.breakpoints.down(1280)]:{
                font:'43px fredoka one',
                lineHeight:'52px',
            },
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            margin:'0 0 36px 0 ',
            color:'#000',
            font:'22px lato',
            lineHeight : '30px',
            letterSpacing: '0.20px',
            [theme.breakpoints.down(1280)]:{
                font:'18px lato',
                lineHeight :'28px',
                letterSpacing: '0.17px',
            },
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .Esp':{
            margin:'0',
        },
        '& .SubTitulo':{
            color:'#FF0000',
            margin:'0',
            font:'bold 30px lato',
            letterSpacing:'.28px',
            lineHeight:'30px',
            [theme.breakpoints.down(1280)]:{
                font:'bold 22px lato',
                lineHeight :'24px',
                letterSpacing: '0.21px',
            },
            [theme.breakpoints.down(502)]:{
                font:'bold 18px lato',
                lineHeight :'20px',
                letterSpacing: '0.16px',
            },
        },
        '& .SubTitulo2':{
            margin:'0',
            color:'#FC5E9E',
            font:'bold 26px lato',
            letterSpacing:'.24px',
            lineHeight:'30px',
            [theme.breakpoints.down(1280)]: {
                font: "bold 22px lato",
                lineHeight: "24px",
                letterSpacing: "0.21px",
            },
            [theme.breakpoints.down(502)]: {
                font: "bold 18px lato",
                lineHeight: "20px",
                letterSpacing: "0.16px",
            },
        },
        '& .Esp':{
            margin:'0'
        },
        '& .Esp2':{
            margin:'0',
            boxSizing:'border-box',
            padding:'0 19px 0 0',
            [theme.breakpoints.down(1280)]: {
                padding:'0',
                margin:'0 0 36px 0',
            },
        },
       '& .TextImg':{
            margin:'24px 0 0 0',
            alignSelf:'flex-start',
            color:'#717476',
            font:'20px lato',
            letterSpacing:'.19px',
            lineHeight:'30px',
            [theme.breakpoints.down(1280)]:{
                font:' 16px lato',
                lineHeight :'25px',
                letterSpacing: '0.15px',
            },
            [theme.breakpoints.down(502)]:{
                font:' 11px lato',
                lineHeight :'14px',
                letterSpacing: '0.10px',
            },
        },

        '& .Imagen':{
            [theme.breakpoints.down(1280)]:{
                width:'100%',
            },
        },
        '& .Imagen2':{
            width:'452px',
            height:'443px',
            [theme.breakpoints.down(1280)]:{
                width:'100%',
                height:'492px',
            },
            [theme.breakpoints.down(502)]:{
                width:'100%',
                height:'342px',
            },
        },
        '& .Link':{
            textDecoration:'none',
        },
    },
    

}));

const Declaración_Universal_de_los_Derechos_Humanos = (props) => {

  const {activeStep, setActiveStep, handlePreviewStep, id_actividad} = props

  const handleContinue = () => {
    setActiveStep(activeStep - 1)
    handlePreviewStep()
  }

    const {
      handleSize,
    disabledZoom,
    closeZoom,
    handleZoom,
    scale
  }= useContext(DrawerContext);
  const [loading, setLoading] = React.useState(true);
  const wrapperRef = React.useRef(null);
  const imagesLoaded = useOnLoadImages(wrapperRef);

  const detectSize = () => {
    const lectura = document.getElementById("lectura");
    const newWidth = lectura?.offsetWidth;
    const newHeight = lectura?.offsetHeight;
    setLoading(false);
    if (lectura) handleSize(newHeight, newWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", detectSize);
    return () => {
      handleZoom()
      window.removeEventListener("resize", detectSize);
      closeZoom()
    };
  }, []);

  const classes = useStyles({ disabledZoom,scale });
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
             <Grid container item justifyContent="center" className={classes.ContainerInfoLectura}>
                <Grid container className="BarraInfoLectura">
                    <Button className="Regresar" onClick={handleContinue}>
                        Regresar
                    </Button>
                </Grid>
               <Grid container item className="InfoLectura">
                    <Grid container item direction="column" xs={6} justifyContent="center">
                        <p className="Dato">Grado</p>
                        <p className="Caracter">3</p>
                    </Grid>
                    <Grid container item direction="column" xs={6} justifyContent="center" className="Sesion">
                        <p className="Dato">Sesión</p>
                        <p className="Caracter">36</p>
                    </Grid>
               </Grid>
            </Grid>
            {!imagesLoaded && <Loader />}
        <Grid
            container
            item
            className={classes.ContenedorLectura}
            justifyContent="center"
            id="lectura"
            onLoad={detectSize}
            ref={wrapperRef}
        >
<Drawer id_lectura={id_actividad} />
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >
                        Declaración<br className='Tablet'/> Universal<br className='Escritorio'/> de los <br className='Tablet'/>Derechos Humanos
                    </h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                            ¿Sabías que existe un libro que contiene todos los derechos de las personas en el 
                            mundo? Es la Declaración Universal de los Derechos Humanos. Fue elaborada en 
                            1948 por representantes de casi todos los países en una asamblea de la 
                            Organización de las Naciones Unidas.<br/><br/> 
                            
                            Dicho documento establece que “Todos los seres humanos nacen libres e iguales en 
                            dignidad y derechos…” Esto significa que son capaces de elegir qué hacer, decir y 
                            pensar sólo por haber nacido. También que merecen ser tratados justa y 
                            respetuosamente, ya que cuentan con leyes que los protegen.
                        </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img3ro/Sesion36/1.png"  alt="Tarjeta" />
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura Esp">
                           <h3 className='SubTitulo'>Su origen</h3><br/>
                           
                            ¿Conoces todos tus derechos? ¿Sabías que otros niños antes de ti no los tuvieron? En 
                            la Antigüedad estaba permitido que los sujetos con poder fueran dueños de otros. La 
                            idea de que los seres humanos tienen necesidades, deseos y dudas no era común.<br/><br/>

                            Pero esta situación comenzó a cambiar en Europa a partir del Renacimiento. Los 
                            intelectuales de esa época consideraron al ser humano como el creador de la cultura.
                            Distintos pensadores construyeron grandes civilizaciones por medio del arte y la 
                            ciencia. A partir de esto surgieron distintos movimientos que prohibieron la 
                            esclavitud en la mayoría de los países durante los siglos <span style={{fontVariant:"all-small-caps"}}>XVIII</span> y <span style={{fontVariant:"all-small-caps"}}>XIX</span>.<br/><br/>
                            
                            Como resultado, surgieron dos de los derechos individuales más importantes que 
                            hoy aparecen en la mayoría de las constituciones políticas: el derecho a la identidad y 
                            al libre desarrollo de la personalidad.<br/><br/>  

                            <h3 className='SubTitulo2'>Identidad y libre desarrollo de la personalidad</h3>
                        </p>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container md={12} lg={7}>
                        <p className="Lectura Esp2">
                            
                            El <em>derecho</em> a la <em>identidad</em> otorga la oportunidad 
                            de poseer un nombre y una nacionalidad al 
                            nacer. Así lo explica el artículo 7 de la 
                            Convención sobre los Derechos del Niño: “El 
                            niño será registrado inmediatamente después 
                            de su nacimiento y tendrá derecho desde que 
                            nace a un nombre, a adquirir una nacionalidad 
                            y, en la medida de lo posible, a conocer a sus 
                            padres y a ser cuidado por ellos”.<br/><br/> 
                            
                            Con ello se comprueba que formas parte de 
                            una sociedad, que cuentas con derechos y 
                            obligaciones y que tienes acceso a los 
                            servicios que ofrece tu país para desarrollarte 
                            sanamente.
                        </p>
                    </Grid>
                    <Grid container md={12} lg={5}>
                        <img className="Imagen2" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img3ro/Sesion36/2.png"  alt="Tarjeta" />
                    </Grid>
                
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container xs={12} >
                        <p className="Lectura Esp">
                            Por su parte, el <em>derecho al libre desarrollo de la personalidad</em> comunica que todo 
                            individuo es capaz de hacer o dejar de hacer lo que desee. Siempre y cuando se 
                            mantenga dentro de la ley y respete a los demás. Puedes elegir tus pasatiempos, 
                            gustos y sueños gracias a él. Es importante cuidar que dichas decisiones no afecten a 
                            nadie.<br/><br/> 
                            
                            ¿Te gustaría conocer más sobre tus derechos? Te invitamos a leer al respecto en:
                        </p>
                        <p className='TextImg'>
                            <span style={{fontVariant:"all-small-caps"}}>UNICEF</span>/Quino, “10 derechos fundamentales de los niños, por Quino. <em>A 30 años de la 
                            Convención sobre los Derechos del Niño</em>”, <span style={{fontVariant:"all-small-caps"}}>UNICEF</span> América Latina y el Caribe. <a className='Link' href="https://www.unicef.org/lac/historias/10-derechos-fundamentales-de-los-ni%C3%B1os-por-quino" target="_blank">https://
                            www.unicef.org/lac/historias/10-derechos-fundamentales-de-los-niños-por-
                            quinopor-quino</a>(Consultado el 21-9-2021)
                            
                        </p>
                    </Grid>
                </Grid>
        </Grid>
        <Grid container item style={{ marginTop: 80}} ></Grid>
    </Grid>
    );
}
 
export default Declaración_Universal_de_los_Derechos_Humanos;
