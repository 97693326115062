import React               from "react";
import {Grid, makeStyles}                 from '@material-ui/core';

const useStyles = makeStyles(theme =>({
  questionContainer:{
    width: "100%",
    height:"100%",
  },
  reading: {
    width: "80%",
    margin:"10px 0 10px 0 ",
    font: '22px lato',
    letterSpacing: '0.21px',
    [theme.breakpoints.down(1400)]:{
      font: " 18px lato",
      width: "95%",
    },
    [theme.breakpoints.down(1310)]:{
      font: "500 16px lato",
      // width: "80%",
      margin:"10px 0 10px 0"
    },
    [theme.breakpoints.down(925)]:{
      font: "500 14px lato",
      // width: "70%",
      margin:"6px 0 6px 0"
    },
    [theme.breakpoints.down(426)]:{
      font: "500 12px lato",
      // width: "70%",
      margin:"6px 0 6px 5%"
    }, 
  },
  imgStyle: {
    alignItems: "center",
    width:'100%',
    height:'100%',
    // [theme.breakpoints.down(1400)]:{
    //   width:'567px',
    //   height:'92px',
    // },
  },
  table: {
    // border: '1px solid black',
    width: "100%",
    font: '22px lato',
    [theme.breakpoints.down(1400)]:{
      font: " 18px lato",
    },
  },
  tdTable: {
    padding: "0 1%",
    // border: '1px solid black',
  }
}));

const Question = (props) => {
  const classes = useStyles()
  const { picture1 } = props


  return(
    <Grid container className={classes.questionContainer}>
      <p 
        // dangerouslySetInnerHTML={{__html: `${step+1}. ${pregunta.replace( '\n', '<br/><br/>')}`}}
        className={classes.reading}
      >
        4. Observa detenidamente la siguiente imagen. ¿Conoces el juego? Imagina que tú y tus amigos lo 
        juegan, ¿qué habilidades crees que les fomente?
      </p>
      <br/>
      <img alt="img" src={picture1} className={classes.imgStyle}/>
     
    </Grid>
  )

}

export default Question;
