import { Button, Grid, makeStyles } from "@material-ui/core";
import React, { useContext, useEffect } from "react";
import DrawerContext from "../../../../../usability/DrawerContext";
import { useOnLoadImages } from "../../../../hooks/useOnLoadImages";
import Loader from "../../../../Loader/Loader";
import Drawer from "../../ReadingTools/Drawer";

const useStyles =  makeStyles (theme =>({
     Contenedor:{
        background:'#E9FBF9',
        width:'100vw',
        overflowX:'clip',
        [theme.breakpoints.down(1161)]:{
            background:'none',
           overflowX:'unset',
        },
        [theme.breakpoints.down(600)]:{
            marginTop: '79.51px',
        }
    },
  ContainerInfoLectura: {
    position: "relative",
    height: "86px",
    [theme.breakpoints.down(502)]:{
        height: "43px",
    },
    background: "#76D4C3",
    "& .BarraInfoLectura": {
      height: "86px",
      background: `Url(https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Barras/Azul.png)`,
        [theme.breakpoints.down(502)]:{
            height: "43px",
        },
    },
    "& .InfoLectura": {
      position: "absolute",
      height: "86px",
      width: "219px",
      boxSizing: "border-box",
      padding: "17.2px 0",
      background: "#FAFAFA",
      borderRadius: "50px",
      boxShadow: "2px 2px 2px 2px #00000029",
      zIndex: "4",
        [theme.breakpoints.down(502)]:{
            height: "43px",
            width: "109px",
            padding: "8.6px 0",
            borderRadius: "25px",
        },
    },
    "& .InfoLectura:before": {
      position: "absolute",
      display: "flex",
      boxSizing: "border-box",
      padding: "0 0 0 10px",
      justifyContent: "center",
      alignItems: "center",
      height: "39.88px",
      width: "39.88px",
      borderRadius: "50%",
      content: '""',
      boxShadow: "3px 0px .5px 0px #00000029",
      background: "#FAFAFA",
      left: "90px",
      top: "65px",
      transform: "rotate(0.25turn)",
      [theme.breakpoints.down(502)]:{
        left: "45px",
        top: "30px",
        height: "20.88px",
        width: "20.88px",
        },
    },
    "& .InfoLectura:after": {
      content: '""',
      height: "15px",
      width: "15px",
      borderTop: "2px solid #20A698",
      borderLeft: "2px solid #20A698",
      position: "absolute",
      bottom: "-10px",
      left: "Calc(50% - 8px)",
      transform: "rotate(0.625turn)",
      [theme.breakpoints.down(502)]:{
        height: "10px",
        width: "10px",
        left: "Calc(50% - 4.5px)",
        bottom: "-3px",
        borderTop: "1px solid #20A698",
        borderLeft: "1px solid #20A698",
        },
    },
    "& .Sesion": {
      borderLeft: "2px solid #D8D8D8",
    },
    "& .Dato": {
      margin: "0 0 5px 0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#20A698",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        margin: "0 0 2.5px 0",
        },
    },
    "& .Caracter": {
      margin: "0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#000000",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        },
    },
       '& .Regresar':{
        color: '#F2624F',
        border: '#F2624F 1px solid',
        height: '30px',
        width:'100px',
        borderRadius: '20px',
        letterSpacing: '0.14px',
        textTransform: 'none',
        background:'#FEFAF1',
        top:'calc(50% - 15px)',
        left:'5%',
        zIndex:4,
        [theme.breakpoints.down(502)]: {
          font:'bold 10px lato',
          width: "60px",
        },
      },
  },
    ContenedorLectura:{
        background:'#fff',
        width:'1160px',
        height:'100%',
        padding:'60px 71px 35px 75px ',
        boxSizing:'border-box',
        
        transform: (props) =>
        props.scale > 1 ? `scale(${props.scale}) translate(0px, 10%)` : `none`,
        [theme.breakpoints.down(1161)]:{
            width:'768px',
            padding:'60px 16px 0 16px',
          },
          [theme.breakpoints.down(768)]:{
            width:'350px',
            padding:'60px 16px 0 16px',
          },

        '& .Escritorio':{
            display:'flex',
            [theme.breakpoints.down(1161)]:{
                display:'none'
            },
        },
        '& .Tablet':{
            display:'none',
            [theme.breakpoints.down(1161)]:{
                display:'flex'
            },
        }
    },
    Parrafo1:{
        margin:' 0 0 66px 0',
        position:'relative',
        [theme.breakpoints.down(768)]:{
            margin:' 0 0 33px 0',
        },
        '& .Titulo':{
            font:'99px fredoka one',
            letterSpacing: '0px',
            color: '#F78572',
            margin:'0 0 57px 0 ',
            textAlign:'center',
            [theme.breakpoints.down(1161)]:{
                font:'63px fredoka one',
                lineHeight:'76px',
            },
            [theme.breakpoints.down(768)]:{
                font:'43px fredoka one',
                lineHeight:'43px',
                margin:'0 0 37px 0 ',
            },
        },
        '& .Lectura':{
            lineHeight : '30px',
            margin:'0 ',
            font:'22px lato',
            color:'#000',
            letterSpacing: '0.20px',
            [theme.breakpoints.down(1161)]:{
                font:'18px lato',
                lineHeight :'28px',
                letterSpacing: '0.17px',
            },
            [theme.breakpoints.down(768)]:{
                font:'9px lato',
                lineHeight :'12px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'8px lato',
                lineHeight :'11px',
                letterSpacing: '0.09px',
            },
        },
        '& .Esp1':{
            width:'438px',
            margin:'0',
            [theme.breakpoints.down(1161)]:{
                width:'78.77%',
            },
            [theme.breakpoints.down(768)]:{
                width:'90%',
            },
        },
        '& .Pos':{
            position:'absolute',
        },
        '& .Imagen1':{
            right:'-15px',
            bottom:'-25px',
        },
        '& .Imagen1-1':{
            width:'73.79%',
        },
        '& .SubTitulo':{
            font:'35px Fredoka One',
            color:'#FFFFFF',
            lineHeight:'42px',
            margin:'0',
            [theme.breakpoints.down(1161)]:{
                font:'25px Fredoka One',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(768)]:{
                font:'14px Fredoka One',
                lineHeight :'22px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px Fredoka One',
                lineHeight :'18px',
            },
        },
        '& .Num':{
            width:' 79px',
            height: '79px',
            background:'#F7DF92',
            borderRadius:'50%',
            position:'absolute',
            left:'50px',
            font:'52px Fredoka One',
            color:'#20A698',
            lineHeight:'63px',
            margin:'0',
            [theme.breakpoints.down(1161)]:{
                font:'37px Fredoka One',
                lineHeight:'45px',
                width:' 56px',
                height: '56px',
                left:'60px',
            },
            [theme.breakpoints.down(768)]:{
                font:'12px Fredoka One',
                lineHeight :'20px',
                width:' 20px',
                height: '20px',
                left:'25px',
            },
        },
        '& .Num1':{
            top:'157px',
            [theme.breakpoints.down(1161)]:{
                top:'146px',
            },
            [theme.breakpoints.down(768)]:{
                top:'85px',
            },
        },
        '& .Num2':{
            top:'289px',
            [theme.breakpoints.down(1161)]:{
                top:'258px',
            },
            [theme.breakpoints.down(768)]:{
                top:'153px',
            },
        },
        '& .Num3':{
            top:'408px',
            [theme.breakpoints.down(1161)]:{
                top:'351px',
            },
            [theme.breakpoints.down(768)]:{
                top:'193px',
            },
        },
        '& .Num4':{
            top:'2237px',
            [theme.breakpoints.down(1161)]:{
                top:'1720px',
            },
            [theme.breakpoints.down(768)]:{
                top:'1005px',
            },
        },
        '& .Fon1':{
            position:'relative',
            top:'-35px',
            [theme.breakpoints.down(1161)]:{
                top:'-5px',
            },
        },
        '& .Fon2':{
            position:'relative',
            top:'-70px',
            [theme.breakpoints.down(1161)]:{
                top:'-10px',
            },
        },
        '& .Regla':{
            width: '488px',
            height: '60px',
            background:'#926DD1',
            borderRadius: '20px',
            position:'absolute',
            top:'-30px',
            [theme.breakpoints.down(1161)]:{
                width: '48.09%',
                height: '44px',
                top:'-22px',
            },
            [theme.breakpoints.down(768)]:{
                height: '28px',
                top:'-14px',
            },
        },
        '& .Instrucciones':{
            width: '768px',
            height: '60px',
            background:'#20A698',
            borderRadius: '20px',
            position:'absolute',
            top:'-30px',
            [theme.breakpoints.down(1161)]:{
                width: '71.05%',
                height: '44px',
                top:'-22px',
            },
            [theme.breakpoints.down(768)]:{
                height: '28px',
                width: '80%',
                top:'-14px',
            },
        },
        '& .Siluetas':{
            width: '282px',
            height: '60px',
            background:'#F78572',
            borderRadius: '20px',
            position:'absolute',
            top:'-30px',
            [theme.breakpoints.down(1161)]:{
                width: '27.85%',
                height: '44px',
                top:'-22px',
            },
            [theme.breakpoints.down(768)]:{
                height: '28px',
                top:'-14px',
            },
        },
        '& .Solucion':{
            width: '336px',
            height: '60px',
            background:'#DF5DA7',
            borderRadius: '20px',
            position:'absolute',
            top:'-30px',
            [theme.breakpoints.down(1161)]:{
                width: '33.15%',
                height: '44px',
                top:'-22px',
            },
            [theme.breakpoints.down(768)]:{
                height: '28px',
                top:'-14px',
            },
        },
        '& .Esp2':{
            textAlign:'center',
            width:'537px',
            [theme.breakpoints.down(1161)]:{
                width: '91.10%',
            },
        },
        '& .Esp3':{
            textAlign:'center',
            width:'567px',
            [theme.breakpoints.down(1161)]:{
                width: '95%',
            },
        },
        '& .Esp4':{
            textAlign:'center',
            width:'768px',
            [theme.breakpoints.down(1161)]:{
                width: '95%',
            },
        },
        '& .Esp5':{
            textAlign:'center',
            width:'575px',
            [theme.breakpoints.down(1161)]:{
                width: '90.48%',
            },
        },
        '& .Esp6':{
            width:'752px',
            position:'relative',
            left:'19px',
            [theme.breakpoints.down(1161)]:{
                width: '90.48%',
                left:'22px',
            },
            [theme.breakpoints.down(768)]:{
                width: '85%',
                left:'8px',
            },
        },
        '& .Esp7':{
            width:'479px',
            position:'relative',
            font:'19px lato',
            color:'#000',
            lineHeight : '28px',
            letterSpacing: '0.18px',
            margin:'0 ',
            width:'478px',
            [theme.breakpoints.down(1161)]:{
                width:'356px',
                font:'16px lato',
                lineHeight :'25px',
                letterSpacing: '0.15px',
            },
            [theme.breakpoints.down(768)]:{
                font:'9px lato',
                lineHeight :'12px',
                letterSpacing: '0.09px',
                width:'90%',
            },
            [theme.breakpoints.down(350)]:{
                font:'8px lato',
                lineHeight :'11px',
                letterSpacing: '0.09px',
                width:'90%',
            },
        },
        '& .Esp8':{
            width:'537px',
            textAlign:'center',
            [theme.breakpoints.down(1161)]:{
                width:'82.27%',
            },
        },
        '& .Esp9':{
            width:'436px',
            textAlign:'center',
            [theme.breakpoints.down(1161)]:{
                width:'77%',
            },
        },
        '& .Lect2':{
            width: '654px',
            height: '95px',
            background:'#F7DF92',
            borderRadius: '20px',
            position:'absolute',
            top:'49px',
            [theme.breakpoints.down(1161)]:{
                width: '64.53%',
                height: '76px',
                top:'43px',
            },
            [theme.breakpoints.down(768)]:{
                borderRadius: '10px',
                width: '73%',
                top:'23px',
                height: '30px',
            },
        },
        '& .Lect3':{
            width: '654px',
            height: '95px',
            background:'#FDF6E1',
            borderRadius: '20px',
            position:'absolute',
            top:'163px',
            [theme.breakpoints.down(1161)]:{
                width: '64.53%',
                height: '82px',
                top:'139px',
            },
            [theme.breakpoints.down(768)]:{
                borderRadius: '10px',
                width: '73%',
                top:'62px',
                height: '42px',
            },
        },
        '& .Lect4':{
            width: '654px',
            height: '59px',
            background:'#FDF6E1',
            borderRadius: '20px',
            position:'absolute',
            top:'273px',
            [theme.breakpoints.down(1161)]:{
                width: '64.53%',
                height: '43px',
                top:'241px',
            },
            [theme.breakpoints.down(768)]:{
                borderRadius: '10px',
                width: '73%',
                top:'115px',
                height: '20px',
            },
        },
        '& .Lect5':{
            width: '654px',
            height: '95px',
            background:'#FDF6E1',
            borderRadius: '20px',
            position:'absolute',
            top:'349px',
            [theme.breakpoints.down(1161)]:{
                width: '64.53%',
                height: '82px',
                top:'304px',
            },
            [theme.breakpoints.down(768)]:{
                borderRadius: '10px',
                width: '73%',
                height: '35px',
                top:'145px',
            },
        },
        '& .Lect6':{
            width: '654px',
            height: '126px',
            background:'#FDF6E1',
            borderRadius: '20px',
            position:'absolute',
            top:'463px',
            [theme.breakpoints.down(1161)]:{
                width: '64.53%',
                height: '106px',
                top:'406px',
            },
            [theme.breakpoints.down(768)]:{
                borderRadius: '10px',
                width: '73%',
                height: '58px',
                top:'190px',
            },
        },
        '& .Lect7':{
            width: '865px',
            height: '158px',
            background:'#F7DF92',
            borderRadius: '20px',
            position:'absolute',
            top:'615px',
            [theme.breakpoints.down(1161)]:{
                width: '85.32%',
                height: '134px',
                top:'541px',
            },
            [theme.breakpoints.down(768)]:{
                borderRadius: '10px',
                height: '70px',
                top:'260px',
            },
        },
        '& .Lect8':{
            width: '654px',
            height: '63px',
            background:'#F7DF92',
            borderRadius: '20px',
            position:'absolute',
            top:'49px',
            [theme.breakpoints.down(1161)]:{
                width: '72.82%',
                height: '54px',
                top:'43px',
            },
            [theme.breakpoints.down(768)]:{
                borderRadius: '10px',
                top:'23px',
                height: '30px',
            },
        },
        '& .Lect9':{
            width: '829px',
            height: '135px',
            background:'#FDF6E1',
            borderRadius: '20px',
            position:'absolute',
            top:'129px',
            [theme.breakpoints.down(1161)]:{
                width: '79.89%',
                height: '111px',
                top:'118px',
            },
            [theme.breakpoints.down(768)]:{
                borderRadius: '10px',
                top:'65px',
                height: '67px',
            },
        },
        '& .Lect10':{
            width: '829px',
            height: '102px',
            background:'#FDF6E1',
            borderRadius: '20px',
            position:'absolute',
            top:'281px',
            [theme.breakpoints.down(1161)]:{
                width: '79.89%',
                height: '72px',
                top:'250px',
            },
            [theme.breakpoints.down(768)]:{
                borderRadius: '10px',
                top:'147px',
                height: '30px',
            },
        },
        '& .Lect11':{
            width: '829px',
            height: '102px',
            background:'#FDF6E1',
            borderRadius: '20px',
            position:'absolute',
            top:'400px',
            [theme.breakpoints.down(1161)]:{
                borderRadius: '10px',
                width: '79.89%',
                height: '72px',
                top:'343px',
            },
            [theme.breakpoints.down(768)]:{
                top:'187px',
                height: '30px',
            },
        },
        '& .Lect12':{
            width: '829px',
            height: '102px',
            background:'#FDF6E1',
            borderRadius: '20px',
            position:'absolute',
            top:'2229px',
            [theme.breakpoints.down(1161)]:{
                borderRadius: '10px',
                width: '79.89%',
                height: '72px',
                top:'1712px',
            },
            [theme.breakpoints.down(768)]:{
                top:'1000px',
                height: '30px',
            },
        },
        '& .Lect13':{
            width: '654px',
            height: '95px',
            background:'#F7DF92',
            borderRadius: '20px',
            position:'absolute',
            top:'49px',
            [theme.breakpoints.down(1161)]:{
                width: '64.40%',
                height: '74px',
                top:'36px',
            },
            [theme.breakpoints.down(768)]:{
                height: '46px',
                top:'20px',
            },
        },
        '& .Pros':{
            height:'229px',
            width:'254px',
            position:'absolute',
            left:'89px',
            background:'#FFFDF8',
            borderRadius:'25px',
            border: '3px solid #f7df92',
            [theme.breakpoints.down(1161)]:{
                left:'62px',
                height:'169px',
                width:'180px',
            },
            [theme.breakpoints.down(768)]:{
                borderRadius:'15px',
                left:'25px',
                height:'100px',
                width:'110px',
            },
        },
        '& .Pros1':{
            top:'530px',
            [theme.breakpoints.down(1161)]:{
                top:'443px',
            },
            [theme.breakpoints.down(768)]:{
                top:'230px',
            },
        },
        '& .Pros2':{
            top:'818px',
            [theme.breakpoints.down(1161)]:{
                top:'655px',
            },
            [theme.breakpoints.down(768)]:{
                top:'360px',
            },
        },
        '& .Pros3':{
            top:'1106px',
            [theme.breakpoints.down(1161)]:{
                top:'871px',
            },
            [theme.breakpoints.down(768)]:{
                top:'490px',
            },
        },
        '& .Pros4':{
            top:'1393px',
            [theme.breakpoints.down(1161)]:{
                top:'1083px',
            },
            [theme.breakpoints.down(768)]:{
                top:'620px',
            },
        },
        '& .Pros5':{
            top:'1680px',
            [theme.breakpoints.down(1161)]:{
                top:'1295px',
            },
            [theme.breakpoints.down(768)]:{
                top:'750px',
            },
        },
        '& .Pros6':{
            top:'1967px',
            [theme.breakpoints.down(1161)]:{
                top:'1507px',
            },
            [theme.breakpoints.down(768)]:{
                top:'885px',
            },
        },
        '& .TextPros':{
            position:'absolute',
            left:'390px',
            background:'#FFFDF8',
            borderRadius:'20px',
            border: '3px solid #f7df92',
            width:'530px',
            [theme.breakpoints.down(1161)]:{
                width:'391px',
                left:'284px',
            },
            [theme.breakpoints.down(768)]:{
                borderRadius:'10px',
                width:'160px',
                left:'145px',
            },
            [theme.breakpoints.down(350)]:{
                borderRadius:'10px',
                width:'125px',
                left:'145px',
            },
        },
        '& .TextPros1':{
            height:'97px',
            top:'589px',
            [theme.breakpoints.down(1161)]:{
                height:'71.52px',
                top:'486.67px',
            },
            [theme.breakpoints.down(768)]:{
                height:'45px',
                top:'255px',
            },
        },
        '& .TextPros2':{
            height:'226px',
            top:'818px',
            [theme.breakpoints.down(1161)]:{
                height:'205px',
                top:'637.04px',
            },
            [theme.breakpoints.down(768)]:{
                height:'135px',
                top:'340px',
            },
        },
        '& .TextPros3':{
            height:'135px',
            top:'1155px',
            [theme.breakpoints.down(1161)]:{
                height:'103px',
                top:'908.7px',
            },
            [theme.breakpoints.down(768)]:{
                height:'65px',
                top:'505px',
            },
        },
        '& .TextPros4':{
            height:'111px',
            top:'1442px',
            [theme.breakpoints.down(1161)]:{
                height:'81px',
                top:'1120.7px',
            },
            [theme.breakpoints.down(768)]:{
                height:'50px',
                top:'645px',
            },
        },
        '& .TextPros5':{
            height:'140px',
            top:'1729px',
            [theme.breakpoints.down(1161)]:{
                height:'103px',
                top:'1331.7px',
            },
            [theme.breakpoints.down(768)]:{
                height:'65px',
                top:'770px',
            },
        },
        '& .TextPros6':{
            height:'140px',
            top:'2016px',
            [theme.breakpoints.down(1161)]:{
                height:'103px',
                top:'1543.7px',
            },
            [theme.breakpoints.down(768)]:{
                height:'65px',
                top:'905px',
            },
        },

        '& .ProsNum':{
            position:'absolute',
            left:'622px',
            height:'64px',
            width:'68px',
            background:`Url(https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion52/12.png)`,
            font:'35px Fredoka One',
            color:'#FFFFFF',
            lineHeight : '30px',
            margin:'0 ',
            [theme.breakpoints.down(1161)]:{
                font:'24px Fredoka One',
                lineHeight :'29px',
                left:'454.78px',
                height:'47.54px',
                width:'50.28px',
                backgroundSize: '100%',
            },
            [theme.breakpoints.down(768)]:{
                left:'210px',
                height:'27px',
                width:'30px',
                font:'13px Fredoka One',
                lineHeight :'18px',
            },
            [theme.breakpoints.down(350)]:{
                left:'195px',
            },
        },
        '& .ProsNum1':{
            top:'530px',
            [theme.breakpoints.down(1161)]:{
                top:'432.25px',
            },
            [theme.breakpoints.down(502)]:{
                top:'228px',
            },
        },
        '& .ProsNum2':{
            top:'759px',
            [theme.breakpoints.down(1161)]:{
                top:'593.89px',
            },
            [theme.breakpoints.down(502)]:{
                top:'313px',
            },
        },
        '& .ProsNum3':{
            top:'1096px',
            [theme.breakpoints.down(1161)]:{
                top:'865.04px',
            },
            [theme.breakpoints.down(502)]:{
                top:'478px',
            },
        },
        '& .ProsNum4':{
            top:'1383px',
            [theme.breakpoints.down(1161)]:{
                top:'1076.66px',
            },
            [theme.breakpoints.down(502)]:{
                top:'620px',
            },
        },
        '& .ProsNum5':{
            top:'1670px',
            [theme.breakpoints.down(1161)]:{
                top:'1283.28px',
            },
            [theme.breakpoints.down(502)]:{
                top:'743px',
            },
        },
        '& .ProsNum6':{
            top:'1957px',
            [theme.breakpoints.down(1161)]:{
                top:'1500px',
            },
            [theme.breakpoints.down(502)]:{
                top:'880px',
            },
        },
        '& .Animales':{
            position:'absolute',
            top:'159px',
            width:'947px',
            height:'703px',
            background:'#FFFEFC',
            border:'3px solid #F7DF92',
            borderRadius:'25px',
            [theme.breakpoints.down(1161)]:{
                top:'131px',
                width:'93.34%',
                height:'78.42%',
            },
            [theme.breakpoints.down(768)]:{
                top:'75px',
            },
        },
        '& .Img1':{
            [theme.breakpoints.down(1161)]:{
                width:'86.7%',
            },
        }, 
        '& .Img2':{
            [theme.breakpoints.down(1161)]:{
                width:'66%',
            },
        }, 
        '& .Img3':{
            [theme.breakpoints.down(1161)]:{
                width:'66%',
            },
        },
        '& .Img4':{
            [theme.breakpoints.down(1161)]:{
                width:'82.5%',
            },
        },
        '& .Img5':{
            [theme.breakpoints.down(1161)]:{
                width:'82.5%',
            },
        },
        '& .Img6':{
            [theme.breakpoints.down(1161)]:{
                width:'82.5%',
            },
        },
        '& .ImagenFondo':{
            [theme.breakpoints.down(1161)]:{
                width:'100%',
                height:'691px',
            },
            [theme.breakpoints.down(768)]:{
                width:'100%',
                height:'340px',
            },
        }, 
        '& .ImagenFondo3':{
            [theme.breakpoints.down(1161)]:{
                width:'100%',
                height:'604.33px',
            },
            [theme.breakpoints.down(768)]:{
                height:'360px',
            }
        }, 
        '& .ImagenFondo2':{
            width:'1014px',
            height:'895px',
            [theme.breakpoints.down(1161)]:{
                width:'100%',
                height:'649px',
            },
            [theme.breakpoints.down(768)]:{
                height:'349px',
            }
        }, 
        '& .AnimalesImg':{
            [theme.breakpoints.down(1161)]:{
                width:'95%',
                height:'95%',
            }
        },
    },
    Especial:{
        margin:0,
        [theme.breakpoints.down(1161)]:{
            margin:'0 0 50px 0',
        },
        [theme.breakpoints.down(768)]:{
            margin:'0 0 20px 0',
        }
    },
}));

const Tangram = (props) => {

  const {activeStep, setActiveStep, handlePreviewStep, id_actividad} = props
  
  const handleContinue = () => {
    setActiveStep(activeStep - 1)
    handlePreviewStep()
  }

    const {
      handleSize,
    disabledZoom,
    closeZoom,
    handleZoom,
    scale
  }= useContext(DrawerContext);
  const [loading, setLoading] = React.useState(true);
  const wrapperRef = React.useRef(null);
  const imagesLoaded = useOnLoadImages(wrapperRef);

  const detectSize = () => {
    const lectura = document.getElementById("lectura");
    const newWidth = lectura?.offsetWidth;
    const newHeight = lectura?.offsetHeight;
    setLoading(false);
    if (lectura) handleSize(newHeight, newWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", detectSize);
    return () => {
      handleZoom()
      window.removeEventListener("resize", detectSize);
      closeZoom()
    };
  }, []);

  const classes = useStyles({ disabledZoom,scale });
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
             <Grid container item justifyContent="center" className={classes.ContainerInfoLectura}>
                       <Grid container className="BarraInfoLectura">
            <Button className="Regresar" onClick={handleContinue}>
                Regresar
            </Button>
        </Grid>
               <Grid container item className="InfoLectura">
                    <Grid container item direction="column" xs={6} justifyContent="center">
                        <p className="Dato">Grado</p>
                        <p className="Caracter">5</p>
                    </Grid>
                    <Grid container item direction="column" xs={6} justifyContent="center" className="Sesion">
                        <p className="Dato">Sesión</p>
                        <p className="Caracter">52</p>
                    </Grid>
               </Grid>
            </Grid>
            {!imagesLoaded && <Loader />}
        <Grid
            container
            item
            className={classes.ContenedorLectura}
            justifyContent="center"
            id="lectura"
            onLoad={detectSize}
            ref={wrapperRef}
        >
<Drawer id_lectura={id_actividad} />
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container item xs={12} className='Escritorio'>
                       <p className='Lectura Esp1'>
                       <h2 className='Titulo'>Tangram </h2>
                        El <em>tangram</em> es un rompecabezas de origen 
                        chino. Su nombre original es <em>Chi Chiao Pan</em>, 
                        que significa “tabla de la sabiduría”. El juego 
                        se extendió por muchos países a través de la 
                        navegación. Su éxito se debe a su sencillez: 
                        se puede elaborar con materiales de muchos 
                        tipos y es fácil de transportar.
                       </p>       
                       <img className="Pos Imagen1" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion52/8.png" alt="Tarjeta" />
                    </Grid>

                    <Grid container item xs={12} justifyContent="center" className='Tablet'>
                        <h2 className='Titulo'>Tangram </h2>
                       <img className="Imagen1-1" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion52/8.png" alt="Tarjeta" />
                       <p className='Lectura Esp1'>
                        El <em>tangram</em> es un rompecabezas de origen 
                        chino. Su nombre original es <em>Chi Chiao Pan</em>, 
                        que significa “tabla de la sabiduría”. El juego 
                        se extendió por muchos países a través de la 
                        navegación. Su éxito se debe a su sencillez: 
                        se puede elaborar con materiales de muchos 
                        tipos y es fácil de transportar.
                       </p>       
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <img className="ImagenFondo" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion52/1.png" alt="Tarjeta" />
                    <Grid container item justifyContent='center' alignContent='center' className='Regla'>
                        <h3 className='SubTitulo'>
                            Reglas del juego
                        </h3>
                    </Grid>

                    <Grid container item justifyContent='center' alignContent='center' className='Lect2'>
                        <p className='Lectura Esp2'>
                            Se puede jugar de manera individual, pero también es posible desafiar a uno o más oponentes.
                        </p>
                    </Grid>

                    <Grid container item justifyContent='center' alignContent='center' className='Lect3'>
                        <p className='Lectura Esp2'>
                            En cada turno se deben colocar todas las piezas para construir diferentes formas. No puede sobrar ninguna.
                        </p>
                    </Grid>

                    <Grid container item justifyContent='center' alignContent='center' className='Lect4'>
                        <p className='Lectura Esp2'>
                            No está permitido poner las piezas unas sobre otras.
                        </p>
                    </Grid>

                    <Grid container item justifyContent='center' alignContent='center' className='Lect5'>
                        <p className='Lectura Esp2'>
                            Se pueden hacer varios intentos para construir la silueta. También es posible poner un límite de tiempo.
                        </p>
                    </Grid>

                    <Grid container item justifyContent='center' alignContent='center' className='Lect6'>
                        <p className='Lectura Esp3'>
                            El jugador debe decir qué representa la figura que construyó. El resto de los 
                            jugadores debe confirmar que las piezas efectivamente se parecen a la forma deseada.
                        </p>
                    </Grid>

                    <Grid container item justifyContent='center' alignContent='center' className='Lect7'>
                        <p className='Lectura Esp4'>
                            Para hacer el juego más interesante se puede desafiar al oponente a replicar una 
                            forma, mostrándole una imagen en la que sólo aparezca la silueta sin que se 
                            distinga cómo están colocadas las piezas. Al final los jugadores comprueban si 
                            la figura se construyó correctamente.
                        </p>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={`${classes.Parrafo1}  ${classes.Especial}`} justifyContent="center" >
                    <img className="ImagenFondo3" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion52/1.png" alt="Tarjeta" />
                    <img className="ImagenFondo3 Fon1" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion52/1.png" alt="Tarjeta" />
                    <img className="ImagenFondo3 Fon2" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion52/1.png" alt="Tarjeta" />

                 

                    <Grid container item justifyContent='center' alignContent='center' className='Instrucciones'>
                        <h3 className='SubTitulo'>
                            Instrucciones para hacer las piezas
                        </h3>
                    </Grid>

                    <Grid container item justifyContent='center' alignContent='center' className='Lect8'>
                        <p className='Lectura Esp5'>
                            ¿Quieres construir tu propio tangram? ¡Sigue estos pasos!
                        </p>
                    </Grid>

                    <Grid container item justifyContent='center' alignContent='center' className='Lect9'>
                        <p className='Lectura Esp6'>
                            Elige un material. Aunque hay piezas de madera o de plástico, es recomendable que busques 
                            materiales accesibles y fáciles de recortar, como el cartón o la goma Eva (también llamada <em>foamy</em>).
                        </p>
                    </Grid>

                    <Grid container item justifyContent='center' alignContent='center' className='Lect10'>
                        <p className='Lectura Esp6'>
                            Traza sobre el material un cuadrado. Puedes ayudarte con una escuadra.
                        </p>
                    </Grid>

                    <Grid container item justifyContent='center' alignContent='center' className='Lect11'>
                        <p className='Lectura Esp6'>
                            Dibuja cada una de las figuras. En los siguientes pasos verás cómo hacer un tangram de 120 × 120 mm.
                        </p>
                    </Grid>
                    <Grid container item justifyContent='center' alignContent='center' className='Lect12'>
                        <p className='Lectura Esp6'>
                            Corta con cuidado el material. Sigue las líneas de las figuras.
                        </p>
                    </Grid>
                    

                    <Grid container item justifyContent='center' alignContent='center' className='Num Num1'>
                            1
                    </Grid>
                    <Grid container item justifyContent='center' alignContent='center' className='Num Num2'>
                            2
                    </Grid>
                    <Grid container item justifyContent='center' alignContent='center' className='Num Num3'>
                            3
                    </Grid>
                    <Grid container item justifyContent='center' alignContent='center' className='Num Num4'>
                            4
                    </Grid>

                    <Grid container item justifyContent='center' alignContent='center' className='Pros Pros1'>
                        <img className="Imagen Img1" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion52/2.png" alt="Tarjeta" />
                    </Grid>

                    <Grid container item justifyContent='center' alignContent='center' className='Pros Pros2'>
                        <img className="Imagen Img2" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion52/3.png" alt="Tarjeta" />
                    </Grid>

                    <Grid container item justifyContent='center' alignContent='center' className='Pros Pros3'>
                        <img className="Imagen Img3" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion52/4.png" alt="Tarjeta" />
                    </Grid>

                    <Grid container item justifyContent='center' alignContent='center' className='Pros Pros4'>
                        <img className="Imagen Img4" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion52/5.png" alt="Tarjeta" />
                    </Grid>

                    <Grid container item justifyContent='center' alignContent='center' className='Pros Pros5'>
                        <img className="Imagen Img5" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion52/6.png" alt="Tarjeta" />
                    </Grid>

                    <Grid container item justifyContent='center' alignContent='center' className='Pros Pros6'>
                        <img className="Imagen Img6" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion52/7.png" alt="Tarjeta" />
                    </Grid>

                    <Grid container item justifyContent='center' alignContent='center' className='TextPros TextPros1'>
                        <p className='Esp7'>
                            Asegúrate de tener trazado el cuadrado. ¡Todas las figuras estarán dentro!
                        </p>
                    </Grid>

                    <Grid container item justifyContent='center' alignContent='center' className='TextPros TextPros2'>
                        <p className='Esp7'>
                            Encuentra cuál es la mitad del lado superior de tu cuadrado. Si tu cuadrado mide 
                            120 mm, eso tendría que ser a los 60 mm. Haz lo mismo con el lado derecho. Ahora 
                            debes trazar dos diagonales. La primera va de un vértice a otro (línea roja) y 
                            otra de la mitad del lado superior a la mitad del lado derecho (línea verde).
                        </p>
                    </Grid>

                    <Grid container item justifyContent='center' alignContent='center' className='TextPros TextPros3'>
                        <p className='Esp7'>
                            Empieza a trazar la otra diagonal del cuadrado, pero detente justo en la segunda 
                            diagonal que trazaste en el paso anterior.
                        </p>
                    </Grid>
                    
                    <Grid container item justifyContent='center' alignContent='center' className='TextPros TextPros4'>
                        <p className='Esp7'>
                            Mide la primera diagonal que trazaste en el paso 1 y divídela en cuartos. Márcalos con un punto.
                        </p>
                    </Grid>

                    <Grid container item justifyContent='center' alignContent='center' className='TextPros TextPros5'>
                        <p className='Esp7'>
                        Une con una línea el punto del primer cuarto y conéctalo con la segunda diagonal 
                        que trazaste, haciendo un triángulo.
                        </p>
                    </Grid>

                    <Grid container item justifyContent='center' alignContent='center' className='TextPros TextPros6'>
                        <p className='Esp7'>
                        Une con una línea el punto del segundo cuarto y conéctalo con la segunda diagonal que trazaste, 
                        haciendo un cuadrado.
                        </p>
                    </Grid>

                    <Grid container item justifyContent='center' alignContent='center' className='ProsNum ProsNum1'>
                        I
                    </Grid>
                    <Grid container item justifyContent='center' alignContent='center' className='ProsNum ProsNum2'>
                        II
                    </Grid>
                    <Grid container item justifyContent='center' alignContent='center' className='ProsNum ProsNum3'>
                        III
                    </Grid>
                    <Grid container item justifyContent='center' alignContent='center' className='ProsNum ProsNum4'>
                        IV
                    </Grid>
                    <Grid container item justifyContent='center' alignContent='center' className='ProsNum ProsNum5'>
                        V
                    </Grid>
                    <Grid container item justifyContent='center' alignContent='center' className='ProsNum ProsNum6'>
                        VI
                    </Grid>

                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <img className="ImagenFondo2" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion52/1E.png" alt="Tarjeta" />

                    <Grid container item justifyContent='center' alignContent='center' className='Siluetas'>
                        <h3 className='SubTitulo'>
                            Siluetas
                        </h3>
                    </Grid>

                    <Grid container item justifyContent='center' alignContent='center' className='Lect13'>
                        <p className='Lectura Esp8'>
                            ¿Quieres desafiar a tus amigos? ¡Aquí hay algunas siluetas con las que pueden empezar!
                        </p>
                    </Grid>

                    <Grid container item justifyContent='center' alignContent='center' className='Animales'>
                        <img className="AnimalesImg" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion52/10.png" alt="Tarjeta" />
                    </Grid>

                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <img className="ImagenFondo2" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion52/1E.png" alt="Tarjeta" />

                    <Grid container item justifyContent='center' alignContent='center' className='Solucion'>
                        <h3 className='SubTitulo'>
                            Soluciones
                        </h3>
                    </Grid>

                    <Grid container item justifyContent='center' alignContent='center' className='Lect13'>
                        <p className='Lectura Esp9'>
                            Al terminar pueden revisar si construyeron las formas correctamente.                       
                        </p>
                    </Grid>

                    <Grid container item justifyContent='center' alignContent='center' className='Animales'>
                    <img className="AnimalesImg" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion52/11.png" alt="Tarjeta" />
                    </Grid>

                </Grid>
                
        </Grid>
        <Grid container item style={{ marginTop: 80}} ></Grid>
    </Grid>
    );
}
 
export default Tangram;
