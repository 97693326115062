import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
    ContenedorLectura:{
        width:'502px',
                  [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
    },
    ContainerTitulo:{
        margin:'0 0 34px 0',
        '& .Titulo':{
            letterSpacing: '0px',
            color: '#D1270A',
            margin:'0',
            font:'43px fredoka one',
            lineHeight:'52px',
            textAlign:'center',
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            margin:'0',
            color:'#000',
            font:'18px lato',
            lineHeight :'28px',
            letterSpacing: '0.17px',
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .TextImg':{
            color:'#717476',
            margin:'0',
            font:' 16px lato',
            lineHeight :'25px',
            letterSpacing: '0.15px',
            [theme.breakpoints.down(502)]:{
                font:' 11px lato',
                lineHeight :'14px',
                letterSpacing: '0.10px',
            },
        },
        '& .Imagen':{
            width:'100%',
        },  
      
    },

}));

const El_cine_no_es_de_una_sola_persona = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >
                        El cine no es de una sola persona
                    </h2>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container xs={12} >
                        <p className="Lectura">
                            A veces, cuando se estrena una película, lo primero que nos llama la atención son los 
                            actores. Pero una película es el resultado de un trabajo en conjunto. Los actores se 
                            apoyan en los miembros de un equipo para llevar a cabo su trabajo. Es momento de 
                            que conozcas a algunas de las otras personas necesarias para hacer una película.<br/><br/> 
                            
                            Antes que nada, tenemos a los guionistas, quienes crean las historias que se van a 
                            contar. También están los directores de cámaras, quienes se encargan de la parte 
                            técnica para captar las escenas que imaginaron los guionistas. Por supuesto, hay que 
                            mencionar a los productores, quienes aportan el dinero para crear la obra, consiguen 
                            las locaciones, obtienen toda la utilería y más tarde se encargan de la distribución de 
                            las películas, entre otras labores. Por último, tenemos al director. Él es quien está al
                            mando de todos, quien asigna las labores específicas a cada equipo para que el 
                            público pueda ver un producto completo. Es ahí donde demuestra su talento para 
                            mezclar arte, técnica y liderazgo.
                        </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12} >
                        <Grid container justifyContent='center'>
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img1ro/Sesion56R/1.png" alt="Tarjeta" />
                        </Grid>
                        <Grid container item justifyContent='center'>
                            <p className='TextImg'>
                            ¿Conoces a otras personas cuyo trabajo esté detrás de cámaras? ¡Comparte tu conocimiento cinéfilo con tu grupo!
                            </p>
                        </Grid>
                    </Grid>
                </Grid>
        </Grid>
    </Grid>
    );
}
 
export default El_cine_no_es_de_una_sola_persona;
