import { Button, Grid,makeStyles}              from '@material-ui/core';
import React, { useContext, useEffect } from "react";
import DrawerContext from "../../../../../usability/DrawerContext";
import { useOnLoadImages } from "../../../../hooks/useOnLoadImages";
import Loader from "../../../../Loader/Loader";
import Drawer from "../../ReadingTools/Drawer";

const useStyles = makeStyles((theme) => ({
  Contenedor: {
    background: "#E9FBF9",
    width: "100vw",
    overflowX: "clip",
    [theme.breakpoints.down(1161)]: {
      background: "none",
      overflowX: "unset",
    },
    [theme.breakpoints.down(600)]: {
      marginTop: "79.51px",
    },
  },
  ContainerInfoLectura: {
    position: "relative",
    height: "86px",
    [theme.breakpoints.down(502)]: {
      height: "43px",
    },
    background: "#76D4C3",
    "& .BarraInfoLectura": {
      height: "86px",
      background: `Url(https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Barras/Azul.png)`,
      [theme.breakpoints.down(502)]: {
        height: "43px",
      },
    },
    "& .InfoLectura": {
      position: "absolute",
      height: "86px",
      width: "219px",
      boxSizing: "border-box",
      padding: "17.2px 0",
      background: "#FAFAFA",
      borderRadius: "50px",
      boxShadow: "2px 2px 2px 2px #00000029",
      zIndex: "4",
      [theme.breakpoints.down(502)]: {
        height: "43px",
        width: "109px",
        padding: "8.6px 0",
        borderRadius: "25px",
      },
    },
    "& .InfoLectura:before": {
      position: "absolute",
      display: "flex",
      boxSizing: "border-box",
      padding: "0 0 0 10px",
      justifyContent: "center",
      alignItems: "center",
      height: "39.88px",
      width: "39.88px",
      borderRadius: "50%",
      content: '""',
      boxShadow: "3px 0px .5px 0px #00000029",
      background: "#FAFAFA",
      left: "90px",
      top: "65px",
      transform: "rotate(0.25turn)",
      [theme.breakpoints.down(502)]: {
        left: "45px",
        top: "30px",
        height: "20.88px",
        width: "20.88px",
      },
    },
    "& .InfoLectura:after": {
      content: '""',
      height: "15px",
      width: "15px",
      borderTop: "2px solid #20A698",
      borderLeft: "2px solid #20A698",
      position: "absolute",
      bottom: "-10px",
      left: "Calc(50% - 8px)",
      transform: "rotate(0.625turn)",
      [theme.breakpoints.down(502)]: {
        height: "10px",
        width: "10px",
        left: "Calc(50% - 4.5px)",
        bottom: "-3px",
        borderTop: "1px solid #20A698",
        borderLeft: "1px solid #20A698",
      },
    },
    "& .Sesion": {
      borderLeft: "2px solid #D8D8D8",
    },
    "& .Dato": {
      margin: "0 0 5px 0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#20A698",
      [theme.breakpoints.down(502)]: {
        font: "bold 12px lato",
        margin: "0 0 2.5px 0",
      },
    },
    "& .Caracter": {
      margin: "0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#000000",
      [theme.breakpoints.down(502)]: {
        font: "bold 12px lato",
      },
    },
    "& .Regresar": {
      color: "#F2624F",
      border: "#F2624F 1px solid",
      height: "30px",
      width: "100px",
      borderRadius: "20px",
      letterSpacing: "0.14px",
      textTransform: "none",
      background: "#FEFAF1",
      top: "calc(50% - 15px)",
      left: "5%",
      zIndex: 4,
      [theme.breakpoints.down(502)]: {
        font: "bold 10px lato",
        width: "60px",
      },
    },
  },
  ContenedorLectura: {
    background: "#fff",
    width: "1160px",
    height: "100%",
    padding: "0",
    transform: (props) =>
      props.scale > 1 ? `scale(${props.scale}) translate(0px, 10%)` : `none`,
    [theme.breakpoints.down(1161)]: {
      width: "768px",
    },
    [theme.breakpoints.down(769)]: {
      width: "350px",
    },
    "& .Escritorio": {
      display: "block",
      [theme.breakpoints.down(1161)]: {
        display: "none",
      },
    },
    "& .Tablet": {
      display: "none",
      [theme.breakpoints.down(1161)]: {
        display: "block",
      },
    },
  },

  Parrafo1: {
    position: "relative",
    [theme.breakpoints.down(768)]: {
      display: "none",
    },
    "& .Pos": {
      position: "absolute",
      margin: "0",
    },
    "& .Titulo": {
      color: "#FFFFFF",
      font: "58px Fredoka One",
      lineHeigth: "70px",
      top: "51px",
      left: "80px",
      [theme.breakpoints.down(1161)]: {
        font: "37px Fredoka One",
        lineHeigth: "45px",
        top: "30px",
        left: "55px",
      },
      [theme.breakpoints.down(769)]: {
        font: "16px Fredoka One",
        top: "20px",
        left: "25px",
      },
    },
    "& .Titulo2": {
      color: "#FFFFFF",
      font: "45px Fredoka One",
      lineHeigth: "55px",
      top: "145px",
      left: "377px",
      [theme.breakpoints.down(1161)]: {
        font: "28px Fredoka One",
        lineHeigth: "34px",
        top: "96px",
        left: "246px",
      },
      [theme.breakpoints.down(769)]: {
        font: "14px Fredoka One",
        left: "115px",
        top: "43px",
      },
    },
    "& .SubTitulo": {
      color: "#000000",
      textAlign: "center",
      font: "bold 32px lato",
      lineHeigth: "44px",
      top: "229px",
      [theme.breakpoints.down(1161)]: {
        font: "bold 20px lato",
        lineHeigth: "28px",
        top: "150px",
      },
      [theme.breakpoints.down(769)]: {
        font: "bold 11px lato",
        lineHeigth: "14px",
        top: "70px",
      },
    },
    "& .SubTitulo2": {
      color: "#FFFFFF",
      textAlign: "center",
      font: "39px Fredoka One",
      lineHeigth: "47px",
      top: "362px",
      [theme.breakpoints.down(1161)]: {
        font: "25px Fredoka One",
        lineHeigth: "30px",
        top: "240px",
      },
      [theme.breakpoints.down(769)]: {
        font: "12px Fredoka One",
        lineHeigth: "16px",
        top: "105px",
      },
    },
    "& .SubTitulo3": {
      color: "#000000",
      textAlign: "center",
      font: "bold 22px lato",
      top: "505px",
      [theme.breakpoints.down(1161)]: {
        font: "bold 16px lato",
        lineHeigth: "24px",
        top: "331px",
      },
      [theme.breakpoints.down(769)]: {
        font: "bold 9px lato",
        lineHeigth: "12px",
        top: "145px",
      },
    },
    "& .SubTitulo4": {
      color: "#FFFFFF",
      textAlign: "center",
      font: "bold 26px lato",
      lineHeigth: "30px",
      letterSpacing: "-.27px",
      [theme.breakpoints.down(1161)]: {
        font: "bold 16px lato",
        lineHeigth: "19px",
        letterSpacing: "-.25px",
      },
      [theme.breakpoints.down(769)]: {
        font: "bold 8.5px lato",
        lineHeigth: "12px",
        letterSpacing: "-.35px",
      },
    },
    "& .Sub1": {
      top: "2471px",
      left: "218px",
      [theme.breakpoints.down(1161)]: {
        top: "1635px",
        left: "140px",
      },
      [theme.breakpoints.down(769)]: {
        top: "737px",
        left: "62px",
      },
    },
    "& .Sub2": {
      top: "2694px",
      left: "207px",
      [theme.breakpoints.down(1161)]: {
        top: "1782px",
        left: "133px",
      },
      [theme.breakpoints.down(769)]: {
        top: "804px",
        left: "60px",
      },
    },
    "& .Sub3": {
      top: "2919px",
      left: "211px",
      [theme.breakpoints.down(1161)]: {
        top: "1931px",
        left: "143px",
      },
      [theme.breakpoints.down(769)]: {
        top: "871px",
        left: "65px",
      },
    },
    "& .Sub4": {
      top: "3141px",
      left: "190px",
      [theme.breakpoints.down(1161)]: {
        top: "2078px",
        left: "125px",
      },
      [theme.breakpoints.down(769)]: {
        top: "938px",
        left: "55px",
      },
    },
    "& .Sub5": {
      top: "3391px",
      left: "180px",
      [theme.breakpoints.down(1161)]: {
        top: "2243px",
        left: "119px",
      },
      [theme.breakpoints.down(769)]: {
        top: "1012px",
        left: "54px",
      },
    },
    "& .Sub6": {
      top: "3617px",
      left: "175px",
      [theme.breakpoints.down(1161)]: {
        top: "2392px",
        left: "117px",
      },
      [theme.breakpoints.down(769)]: {
        top: "1079px",
        left: "52px",
      },
    },
    "& .Sub7": {
      top: "2471px",
      left: "645px",
      [theme.breakpoints.down(1161)]: {
        top: "1635px",
        left: "435px",
      },
      [theme.breakpoints.down(769)]: {
        top: "737px",
        left: "195px",
      },
    },
    "& .Sub8": {
      top: "2694px",
      left: "676px",
      [theme.breakpoints.down(1161)]: {
        top: "1782px",
        left: "448px",
      },
      [theme.breakpoints.down(769)]: {
        top: "804px",
        left: "200px",
      },
    },
    "& .Sub9": {
      top: "2919px",
      left: "709px",
      [theme.breakpoints.down(1161)]: {
        top: "1931px",
        left: "469px",
      },
      [theme.breakpoints.down(769)]: {
        top: "871px",
        left: "210px",
      },
    },
    "& .Sub10": {
      top: "3141px",
      left: "694px",
      [theme.breakpoints.down(1161)]: {
        top: "2078px",
        left: "459px",
      },
      [theme.breakpoints.down(769)]: {
        top: "938px",
        left: "205px",
      },
    },
    "& .Sub11": {
      top: "3391px",
      left: "690px",
      [theme.breakpoints.down(1161)]: {
        top: "2243px",
        left: "456px",
      },
      [theme.breakpoints.down(769)]: {
        top: "1012px",
        left: "205px",
      },
    },
    "& .SubTitulo5": {
      color: "#FFFFFF",
      textAlign: "center",
      font: " 39px Fredoka One",
      lineHeigth: "30px",
      top: "3815px",
      [theme.breakpoints.down(1161)]: {
        font: "18px Fredoka One",
        lineHeigth: "22px",
        top: "2532px",
      },
      [theme.breakpoints.down(769)]: {
        font: "10px Fredoka One",
        lineHeigth: "14px",
        top: "1140px",
      },
    },
    "& .Lectura": {
      margin: "0",
      color: "#000000",
      font: "22px lato",
      lineHeigth: "30px",
      letterSpacing: ".17px",
      [theme.breakpoints.down(1161)]: {
        font: "16px lato",
        lineHeigth: "24px",
        letterSpacing: ".12px",
      },
      [theme.breakpoints.down(769)]: {
        font: "8px lato",
        lineHeigth: "10px",
        letterSpacing: ".09px",
      },
    },
    "& .Lec1-1": {
      left: "116px",
      [theme.breakpoints.down(1161)]: {
        left: "77px",
      },
      [theme.breakpoints.down(769)]: {
        left: "40px",
      },
    },
    "& .Lec1-2": {
      left: "593px",
      [theme.breakpoints.down(1161)]: {
        left: "393px",
      },
      [theme.breakpoints.down(769)]: {
        left: "180px",
      },
    },
    "& .Lec1": {
      top: "2536px",
      [theme.breakpoints.down(1161)]: {
        top: "1674px",
      },
      [theme.breakpoints.down(769)]: {
        top: "760px",
      },
    },
    "& .Lec2": {
      top: "2759px",
      [theme.breakpoints.down(1161)]: {
        top: "1823px",
      },
      [theme.breakpoints.down(769)]: {
        top: "825px",
      },
    },
    "& .Lec3": {
      top: "2984px",
      [theme.breakpoints.down(1161)]: {
        top: "1971px",
      },
      [theme.breakpoints.down(769)]: {
        top: "890px",
      },
    },
    "& .Lec4": {
      top: "3206px",
      [theme.breakpoints.down(1161)]: {
        top: "2118px",
      },
      [theme.breakpoints.down(769)]: {
        top: "960px",
      },
    },
    "& .Lec5": {
      top: "3456px",
      [theme.breakpoints.down(1161)]: {
        top: "2283px",
      },
      [theme.breakpoints.down(769)]: {
        top: "1035px",
      },
    },
    "& .Lec6": {
      top: "3682px",
      [theme.breakpoints.down(1161)]: {
        top: "2433px",
      },
      [theme.breakpoints.down(769)]: {
        top: "1095px",
      },
    },
    "& .Derechos": {
      position: "absolute",
      bottom: "16px",
      textAlign: "center",
      margin: "0px",
      font: " 15px lato",
      color: "#717476",
      [theme.breakpoints.down(1161)]: {
        display: "none",
      },
    },
    "& .Imagen": {
      [theme.breakpoints.down(768)]: {
        width: "100%",
      },
    },
  },
  Imagen: {
    display: "none",
    [theme.breakpoints.down(768)]: {
      display: "flex",
      width: "100%",
    },
  },
}));

const Guia_del_zoo = (props) => {

  const {activeStep, setActiveStep, handlePreviewStep, id_actividad} = props

  const handleContinue = () => {
    setActiveStep(activeStep - 1)
    handlePreviewStep()
  }

  const {
    handleSize,
    disabledZoom,
    closeZoom,
    handleZoom,
    scale
  }= useContext(DrawerContext);
  const [loading, setLoading] = React.useState(true);
  const wrapperRef = React.useRef(null);
  const imagesLoaded = useOnLoadImages(wrapperRef);

  const detectSize = () => {
    const lectura = document.getElementById("lectura");
    const newWidth = lectura?.offsetWidth;
    const newHeight = lectura?.offsetHeight;
    setLoading(false);
    if (lectura) handleSize(newHeight, newWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", detectSize);
    return () => {
      handleZoom()
      window.removeEventListener("resize", detectSize);
      closeZoom()
    };
  }, []);

  const classes = useStyles({ disabledZoom,scale });
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
             <Grid container item justifyContent="center" className={classes.ContainerInfoLectura}>
                       <Grid container className="BarraInfoLectura">
            <Button className="Regresar" onClick={handleContinue}>
                Regresar
            </Button>
        </Grid>
               <Grid container item className="InfoLectura">
                    <Grid container item direction="column" xs={6} justifyContent="center">
                        <p className="Dato">Grado</p>
                        <p className="Caracter">2</p>
                    </Grid>
                    <Grid container item direction="column" xs={6} justifyContent="center" className="Sesion">
                        <p className="Dato">Sesión</p>
                        <p className="Caracter">52</p>
                    </Grid>
               </Grid>
            </Grid>
            {!imagesLoaded && <Loader />}
            <Grid
            container
            item
            className={classes.ContenedorLectura}
            justifyContent="center"
            id="lectura"
            onLoad={detectSize}
            ref={wrapperRef}
            >
            <Drawer id_lectura={id_actividad} />
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                <h2 className='Pos Titulo'>Un día como guía del zoológico</h2>
                <h3 className='Pos Titulo2'>¡Bienvenidos!</h3>
                <h3 className='Pos SubTitulo'>El Parque Zoológico Nacional te ofrece la oportunidad de acercarte<br/> a algunas de las especies animales más interesantes.</h3>
                <h3 className='Pos SubTitulo2'>¡Vive nuevas experiencias<br/>en familia!!</h3>
                <h3 className='Pos SubTitulo3'>Para que tu visita sea lo más agradable posible,<br/> te proponemos el siguiente recorrido:</h3>
                <h3 className='Pos SubTitulo4 Sub1'>Entrada del parque</h3>
                <h3 className='Pos SubTitulo4 Sub2'>El dominio del tigre</h3>
                <h3 className='Pos SubTitulo4 Sub3'>La sabana del león</h3>
                <h3 className='Pos SubTitulo4 Sub4'>El bosque del oso pardo</h3>
                <h3 className='Pos SubTitulo4 Sub5'>El jardín de los guepardos</h3>
                <h3 className='Pos SubTitulo4 Sub6'>El santuario de los elefantes</h3>
                <h3 className='Pos SubTitulo4 Sub7'>Trepando con monos aulladores</h3>
                <h3 className='Pos SubTitulo4 Sub8'>Almuerzo en la cafetería</h3>
                <h3 className='Pos SubTitulo4 Sub9'>El establo equino</h3>
                <h3 className='Pos SubTitulo4 Sub10'>El paseo de las jirafas</h3>
                <h3 className='Pos SubTitulo4 Sub11'>La jaula de los pájaros</h3>

                <p className='Pos Lectura Lec1 Lec1-1'>Adquiere tus boletos en la taquilla.<br/> Hay descuentos especiales para niños<br/> y personas de la tercera edad.</p>
                <p className='Pos Lectura Lec2 Lec1-1'>Los tigres provienen de Asia. Han sido <br/>venerados por las culturas china y <br/>japonesa durante siglos.</p>
                <p className='Pos Lectura Lec3 Lec1-1'>El león es llamado “el rey de la selva”, <br/>pero en realidad su hábitat natural es <br/>la sabana.</p>
                <p className='Pos Lectura Lec4 Lec1-1'>Los osos pardos tienen uno de los <br/>olfatos más poderosos del planeta. <br/>Alcanzan a detectar olores a <br/>30 km de distancia.</p>
                <p className='Pos Lectura Lec5 Lec1-1'>Los guepardos son los animales más <br/>veloces del mundo. Pueden alcanzar <br/>una velocidad de 115 km/h.</p>
                <p className='Pos Lectura Lec6 Lec1-1'>Los elefantes son tan amigables como <br/>los perros. También son sumamente <br/>inteligentes y sensibles.</p>
                <p className='Pos Lectura Lec1 Lec1-2'>Los monos aulladores provienen de <br/>Perú, Colombia, Ecuador y Venezuela.<br/> Son populares por su voz aguda.</p>
                <p className='Pos Lectura Lec2 Lec1-2'>Toma un descanso con tu familia <br/>mientras contemplan el lago de <br/>los flamencos.</p>
                <p className='Pos Lectura Lec3 Lec1-2'>Gracias a animales como los caballos, <br/>el ser humano puede viajar largas <br/>distancias.</p>
                <p className='Pos Lectura Lec4 Lec1-2'>Las jirafas tienen cuellos largos que les <br/>permiten alcanzar las hojas más <br/>tiernas de los árboles.</p>
                <p className='Pos Lectura Lec5 Lec1-2'>Cierra tu visita al zoológico tomando <br/>fotografías de las más de 80 especies <br/>que hay en el aviario.</p>

                <h3 className='Pos SubTitulo5 '>¿Disfrutaste la aventura? Esperamos que así sea.<br/>¡Nos vemos pronto!</h3>



                <img className='Imagen Escritorio' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img2do/Sesion52/1.png" alt="Tarjeta" />
                <img className='Imagen Tablet' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img2do/Sesion52/1R.png" alt="Tarjeta" />               
                </Grid>
                <img className={classes.Imagen} src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img2do/Sesion52/F.png" alt="Tarjeta" />

        </Grid>
        <Grid container item style={{ marginTop: 80}} ></Grid>
    </Grid>
    );
}
 
export default Guia_del_zoo ;
