import { makeStyles, Grid } from "@material-ui/core";
import React from "react";
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import ContainerAdmin from "../ContainerAdmin";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    padding: "48px 0 48px 0",
    background:
      "linear-gradient(217deg, rgba(255,194,0,0.4962185557816877) 2%, rgba(255,255,255,1) 25%, rgba(255,255,255,0.4962185557816877) 81%, rgba(144,81,177,1) 98%)",
    minHeight: "calc(100vh - 80px)",
  },
  boxContainer: {
    background: "#fff",
    width: "90%",
    // gap: "20px",
    maxWidth: "1216px",
    minHeight: "70vh",
    borderRadius: "42px",
    padding: "30px",
    alignItems: "center",
    boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.5)",
    [theme.breakpoints.up("sm")]: {
      width: "auto",
      minWidth: "75%",
    },
    "& .Titulo": {
      font: "28px fredoka one",
      textAlign: "center",
      width: "100%",
      margin: "20px 0",
      color: "#DC5EA6",
      [theme.breakpoints.up("sm")]: {
        width: "auto",
        // margin: "0 0 34px 14px",
      },
      [theme.breakpoints.up("md")]: {
        font: "32px fredoka one",
        width: "auto",
        // textAlign: "start",
        // margin: "0 0 34px 14px",
      },
      [theme.breakpoints.up("lg")]: {
        font: "38px Fredoka One",
      },
    },
    "& .SubTitulo": {
      font: "24px lato ",
      margin: "5px",
      textAlign: "center",
      color: "#DC5EA6",
      [theme.breakpoints.down(425)]: {
        font: "18px lato",
      },
    },
  },
  buttonsContainer: {
    width: "100%",
    gap: "20px",
    [theme.breakpoints.up("sm")]: {
      width: "60%",
    },
  },
  ContainerLinkinsiginas: {
    paddingLeft: "20px",
    textDecoration: "none",
    [theme.breakpoints.down(425)]: {
      paddingLeft: "0px",
    },
    // marginLeft: "44px",
    // marginBottom: "40px",
    "& .ButtonIsg": {
      textDecoration: "none",
      display: "flex",
      alignItems: "center",
      color: "black",
      textTransform: "none",
      font: " 22px lato",
      gap: "10px",
      letterSpacing: "0.36px",
      [theme.breakpoints.down(425)]: {
        font: " 16px lato",
      },
      // width: '102px',
      // height: '21px',
      // background: '#FEFAF1 0% 0% no-repeat padding-box',
      // boxShadow: '0px 1px 2px #00000029',
      // border: '1px solid #F2624F',
      // borderRadius: '10px',
    },
    "& p": {
      textAlign: "start",
    },
    "& .icon": {
      color: "purple",
      marginRight: "20px",
      fontSize: "75px",
      [theme.breakpoints.down(425)]: {
        fontSize: "50px",
        marginRight: "0px",
      },
    },
  },

  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const UsersManual = () => {
  const classes = useStyles();

  return (
    <ContainerAdmin>
      <Grid
        container
        item
        sm={12}
        alignItems="center"
        justifyContent="center"
        className={classes.mainContainer}
      >
        <Grid container item direction="column" className={classes.boxContainer}>
          <h2 className="Titulo">Manuales de usuario</h2>
          <p className="SubTitulo"> Descarga el siguiente material:</p>
          <Grid
            container
            className={classes.ContainerLinkinsiginas}
            item
            direction="row"
            alignItems="center"
            justifyContent="flex-start"
          >
            <a className="ButtonIsg" href="https://pearson-cdl-reportes-qa.s3.amazonaws.com/CDL_ADMIN_MANUAl.pdf" target="_blank" rel='noreferrer' download>
              <LibraryBooksIcon className="icon"/>
               Manual de administrador institucional
            </a>
          </Grid>
          <Grid
            container
            className={classes.ContainerLinkinsiginas}
            item
            direction="row"
            alignItems="center"
            justifyContent="flex-start"
          >
            <a className="ButtonIsg" href="https://pearson-cdl-reportes-qa.s3.amazonaws.com/CDL_DOCENTE_MANUAL.pdf" target="_blank" rel='noreferrer' download>
              <LibraryBooksIcon className="icon"/>
               Manual de docente
            </a>
          </Grid>
          <Grid
            container
            className={classes.ContainerLinkinsiginas}
            item
            direction="row"
            alignItems="center"
            justifyContent="flex-start"
          >
            <a className="ButtonIsg" href="https://pearson-cdl-reportes-qa.s3.amazonaws.com/CDL_ALUMNO_MANUAL.pdf" target="_blank" rel='noreferrer' download>
              <LibraryBooksIcon className="icon"/>
               Manual de estudiante
            </a>
          </Grid>
        </Grid>
      </Grid>
    </ContainerAdmin>
  );
};

export default UsersManual;
