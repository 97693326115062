import { Button, Grid, makeStyles } from "@material-ui/core";
import React, { useContext } from "react";
import DrawerContext from "../../../../../usability/DrawerContext";
import Drawer from "../../ReadingTools/Drawer";

const useStyles = makeStyles((theme) => ({
  Contenedor: {
    background: "#E9FBF9",
    width: "100vw",
    [theme.breakpoints.down(1280)]: {
      background: "none",
    },
    [theme.breakpoints.down(600)]: {
      marginTop: "79.51px",
    },
  },
  ContainerInfoLectura: {
    position: "relative",
    height: "86px",
    [theme.breakpoints.down(502)]:{
        height: "43px",
    },
    background: "#76D4C3",
    "& .BarraInfoLectura": {
      height: "86px",
      background: `Url(https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Barras/Azul.png)`,
        [theme.breakpoints.down(502)]:{
            height: "43px",
        },
    },
    "& .InfoLectura": {
      position: "absolute",
      height: "86px",
      width: "219px",
      boxSizing: "border-box",
      padding: "17.2px 0",
      background: "#FAFAFA",
      borderRadius: "50px",
      boxShadow: "2px 2px 2px 2px #00000029",
      zIndex: "4",
        [theme.breakpoints.down(502)]:{
            height: "43px",
            width: "109px",
            padding: "8.6px 0",
            borderRadius: "25px",
        },
    },
    "& .InfoLectura:before": {
      position: "absolute",
      display: "flex",
      boxSizing: "border-box",
      padding: "0 0 0 10px",
      justifyContent: "center",
      alignItems: "center",
      height: "39.88px",
      width: "39.88px",
      borderRadius: "50%",
      content: '""',
      boxShadow: "3px 0px .5px 0px #00000029",
      background: "#FAFAFA",
      left: "90px",
      top: "65px",
      transform: "rotate(0.25turn)",
      [theme.breakpoints.down(502)]:{
        left: "45px",
        top: "30px",
        height: "20.88px",
        width: "20.88px",
        },
    },
    "& .InfoLectura:after": {
      content: '""',
      height: "15px",
      width: "15px",
      borderTop: "2px solid #20A698",
      borderLeft: "2px solid #20A698",
      position: "absolute",
      bottom: "-10px",
      left: "Calc(50% - 8px)",
      transform: "rotate(0.625turn)",
      [theme.breakpoints.down(502)]:{
        height: "10px",
        width: "10px",
        left: "Calc(50% - 4.5px)",
        bottom: "-3px",
        borderTop: "1px solid #20A698",
        borderLeft: "1px solid #20A698",
        },
    },
    "& .Sesion": {
      borderLeft: "2px solid #D8D8D8",
    },
    "& .Dato": {
      margin: "0 0 5px 0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#20A698",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        margin: "0 0 2.5px 0",
        },
    },
    "& .Caracter": {
      margin: "0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#000000",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        },
    },
       '& .Regresar':{
        color: '#F2624F',
        border: '#F2624F 1px solid',
        height: '30px',
        width:'100px',
        borderRadius: '20px',
        letterSpacing: '0.14px',
        textTransform: 'none',
        background:'#FEFAF1',
        top:'calc(50% - 15px)',
        left:'5%',
        zIndex:4,
        [theme.breakpoints.down(502)]: {
          font:'bold 10px lato',
          width: "60px",
        },
      },
  },
  ContenedorLectura: {
    background: "#fff",
    width: "1160px",
    height: "100%",
    padding: "60px 168px 35px ",
    position: "relative",
    
    transform: (props) =>
      props.scale > 1 ? `scale(${props.scale}) translate(0px, 10%)` : `none`,
    [theme.breakpoints.down(1280)]: {
      width: "502px",
      padding: "60px 0 0 0",
    },
    [theme.breakpoints.down(502)]: {
      width: "350px",
      padding: "60px 0 0 0",
    },
    [theme.breakpoints.down(350)]: {
      width: "300px",
      padding: "30px 0 0 0",
    },
    "& .ContainerDerechos": {
      bottom: "80px",
      [theme.breakpoints.down(1280)]: {
        display: "none",
      },
    },
    "& .Derechos": {
      textAlign: "center",
      margin: "0px",
      font: " 15px lato",
      color: "#717476",
    },
    "& .Escritorio": {
      display: "block",
      [theme.breakpoints.down(1280)]: {
        display: "none",
      },
    },
    "& .Tablet": {
      display: "none",
      [theme.breakpoints.down(1280)]: {
        display: "block",
      },
    },
  },
  ContainerTitulo: {
    margin: "0 0 34px 0",
    [theme.breakpoints.down(1280)]: {
      margin: "0 0 34px 0",
    },
    "& .Titulo": {
      font: "48px fredoka one",
      letterSpacing: "0px",
      lineHeight: "58px",
      color: "#0CB5D6",
      margin: "0",
      textAlign: "center",
      [theme.breakpoints.down(1280)]: {
        font: "43px fredoka one",
        lineHeight: "52px",
      },
      [theme.breakpoints.down(502)]: {
        font: "30px fredoka one",
        lineHeight: "32px",
      },
    },
  },
  Parrafo1: {
    margin: " 0 0 36px 0",
    position: "relative",
    "& .Lectura": {
      lineHeight: "30px",
      margin: "0 0 36px 0",
      font: "22px lato",
      color: "#000",
      letterSpacing: "0.20px",
      [theme.breakpoints.down(1280)]: {
        font: "18px lato",
        lineHeight: "28px",
        letterSpacing: "0.17px",
      },
      [theme.breakpoints.down(502)]: {
        font: "12px lato",
        lineHeight :'18px',
        letterSpacing: '0.09px',
      },
    },
    "& .Esp": {
      padding: "0 52px 0 0",
      margin: "0",
      [theme.breakpoints.down(1280)]: {
        padding: "0 ",
        margin: "0 0 36px 0",
      },
    },
    "& .Esp2": {
      margin: "36px 0 ",
    },
    "& .Esp3": {
      margin: "0 ",
    },
    "& .Esp4": {
      margin: "0 0 30px 0",
    },
    "& .Tienda": {
      [theme.breakpoints.down(1280)]: {
        position: "relative",
        right: "150px",
        alignContent: "flex-end",
      },
      [theme.breakpoints.down(600)]: {
        right: "234px",
      },
      [theme.breakpoints.down(502)]: {
        right: "150px",
      },
    },
    "& .Imagen": {
      width: "392px",
      height: "725px",
      [theme.breakpoints.down(1280)]: {
        width: "336px",
        height: "622px",
      },
      [theme.breakpoints.down(502)]: {
        width: "236px",
        height: "483px",
      },
    },
    "& .Imagen2": {
      width: "100%",
    },
  },
}));

const Una_regla_para_todos = (props) => {

  const {activeStep, setActiveStep, handlePreviewStep, id_actividad} = props
  
  const handleContinue = () => {
    setActiveStep(activeStep - 1)
    handlePreviewStep()
  }

  const {
    handleSize,
    disabledZoom,
    closeZoom,
    scale
  } = useContext(DrawerContext);
  const detectSize = () => {
    const lectura = document.getElementById("lectura");

    const newWidth = lectura?.offsetWidth;
    const newHeight = lectura?.offsetHeight;
    if (lectura) handleSize(newHeight, newWidth);
  };
  React.useEffect(() => {
    window.addEventListener("resize", detectSize);
    return () => {
      window.removeEventListener("resize", detectSize);
      closeZoom();
    };
  }, []);

  const classes = useStyles({ disabledZoom, scale });
  return (
    <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
      <Grid container item justifyContent="center" className={classes.ContainerInfoLectura}>
        <Grid container className="BarraInfoLectura">
            <Button className="Regresar" onClick={handleContinue}>
                Regresar
            </Button>
        </Grid>
        <Grid container item className="InfoLectura">
          <Grid container item direction="column" xs={6} justifyContent="center">
            <p className="Dato">Grado</p>
            <p className="Caracter">1</p>
          </Grid>
          <Grid container item direction="column" xs={6} justifyContent="center" className="Sesion">
            <p className="Dato">Sesión</p>
            <p className="Caracter">18</p>
          </Grid>
        </Grid>
      </Grid>

      <Grid
        container
        item
        className={classes.ContenedorLectura}
        justifyContent="center"
        id="lectura"
        onLoad={detectSize}
      >
        <Drawer id_lectura={id_actividad} withoutTransform ={true}/>
        <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
          <h2 className="Titulo">Una regla para todos</h2>
        </Grid>

        <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
          <Grid container xs={10} sm={8} md={8} lg={7}>
            <p className="Lectura Esp Escritorio">
              Lupita entra a una tienda a comprar unas cosas para su casa. Mientras el encargado de la tienda busca lo que le
              pidió Lupita, ¡ella ve unos chocolates deliciosos en el mostrador! Lupita cuenta el dinero, pero se da cuenta de que
              no le alcanza para comprar uno.
              <br />
              <br />
              Lupita piensa: “Nadie me está viendo. Si tomara uno, nadie se daría cuenta, y si nadie se da cuenta, no pasa nada,
              ¿cierto?” <br />
              <br />
              ¿Alguna vez te has encontrado en una situación similar? Saber si algo está bien puede ser desafiante. Una manera
              fácil de saber si nuestras acciones son correctas es la “Regla para todos”. <br />
              <br />
              Lo que tienes que hacer es preguntarte: ¿qué sucedería si todas las personas del mundo hicieran lo mismo que quiero
              hacer? Si te das cuenta de que con esta acción no dañas a tus amigos o al ecosistema, ¡adelante! Esto significa que
              es buena. Pero si alguien resulta afectado, mejor detente.
            </p>
            <p className="Lectura Esp3 Tablet">
              <span>
                Lupita entra a una tienda a comprar
                <br />
                unas cosas para su casa. Mientras el
                <br />
                encargado de la tienda busca lo que le
                <br />
                pidió Lupita, ¡ella ve unos chocolates
                <br />
                deliciosos en el mostrador! Lupita
                <br />
                cuenta el dinero, pero se da cuenta de
                <br />
                que no le alcanza para comprar uno.
                <br />
              </span>
              <div className="Esp4"></div>
              <span>
                Lupita piensa: “Nadie
                <br />
                me está viendo. Si
                <br />
                tomara uno, nadie se
                <br />
                daría cuenta, y si nadie
                <br />
                se da cuenta, no pasa <br />
                nada, ¿cierto?”
                <br />
                <div className="Esp4"></div>
                ¿Alguna vez te has
                <br />
                encontrado en una
                <br />
                situación similar? Saber
                <br />
                si algo está bien puede
                <br />
                ser desafiante. Una
                <br />
                manera fácil de saber si
                <br />
                nuestras acciones son
                <br />
                correctas es la “Regla
                <br />
                para todos”.
                <br />
              </span>
            </p>
          </Grid>
          <Grid container xs={2} sm={4} md={4} lg={5} className="Tienda">
            <img
              className="Imagen "
              src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img1ro/Sesion18/1.png"
              alt="Tarjeta"
            />
          </Grid>
        </Grid>

        <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
          <Grid container sm={12} className="Tablet">
            <p className="Lectura ">
              Lo que tienes que hacer es preguntarte: ¿qué sucedería si todas las personas del mundo hicieran lo mismo que quiero
              hacer? Si te das cuenta de que con esta acción no dañas a tus amigos o al ecosistema, ¡adelante! Esto significa que
              es buena. Pero si alguien resulta afectado, mejor detente.
            </p>
          </Grid>
          <Grid container justifyContent="center">
            <img
              className="Imagen2"
              src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img1ro/Sesion18/2.png"
              alt="Tarjeta"
            />
          </Grid>
          <Grid container sm={12}>
            <p className="Lectura Esp2">
              Cuando Lupita usó la “Regla para todos” pudo imaginar lo que sucedería si todos tomáramos lo que no es nuestro: los
              encargados de las tiendas se verían afectados, los dueños de las fábricas de chocolate y la misma Lupita también.
              Lupita decidió mejor pedirle a su mamá que le comprara un chocolate.
            </p>
          </Grid>
        </Grid>
      </Grid>
      <Grid container item style={{ marginTop: 80 }}></Grid>
    </Grid>
  );
};

export default Una_regla_para_todos;
