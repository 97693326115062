import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
    ContenedorLectura:{
        width:'502px',
                  [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
    },
    ContainerTitulo:{
        margin:'0 0 34px 0',
        '& .Titulo':{
            letterSpacing: '0px',
            color: '#FF2B2B',
            margin:'0 0 5px 0',
            textAlign:'center',
            font:'43px fredoka one',
            lineHeight:'52px',
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
        '& .Titulo2':{
            color: '#FF2B2B',
            margin:'0',
            textAlign:'center',
            font:'bold 26px lato',
            letterSpacing: '.24px',
            lineHeight:'28px',
            [theme.breakpoints.down(502)]:{
                font:'bold 22px lato',
                lineHeight:'24px',
            },
            [theme.breakpoints.down(350)]:{
                font:'bold 18px lato',
                lineHeight:'24px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        position:'relative',
        '& .Lectura':{
            margin:'0 0 36px 0',
            color:'#000',
            font:'18px lato',
            lineHeight :'28px',
            letterSpacing: '0.17px',
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .Lectura2':{
            margin:'0 0 11px 0',
        },
        '& .Titulo3':{
            color: '#00ADB6',
            margin:'0 0 47px 0',
            textAlign:'center',
            font:'bold 26px lato',
            letterSpacing: '.24px',
            lineHeight:'28px',
            [theme.breakpoints.down(502)]:{
                font:'bold 22px lato',
                lineHeight:'24px',
            },
            [theme.breakpoints.down(350)]:{
                font:'bold 18px lato',
                lineHeight:'24px',
            },
        },
        '& .Imagen':{
            width:'100%',
        },  
      
    }
}));

const Pablo_y_el_intraterrestre_Cap_2y3 = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
   
            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                        <h2 className="Titulo">Pablo y el intraterrestre</h2>
                        <h3 className='Titulo2'>Fragmento 2 (capítulos 2-3)</h3>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                       <p className='Lectura Lectura2'>
                        A oscuras, solo en su cuarto, Pablo no dejaba de preguntarse qué le estaba pasando. 
                        Su cara parecía hervir. Los puños se cerraban con fuerza. La mandíbula rechinaba y 
                        los dientes le dolían. Por las mejillas le chorreaban sin control lágrimas de coraje. No 
                        podía destrabarse. “¿Me estaré transformando en un hombre lobo?”, se preguntó 
                        Pablo. Nunca había oído que esa fuera una forma de transformarse. “¿Y qué era esa 
                        bola de oscuridad?” <br/><br/>
                        
                        Pablo sintió un dolor extraño en el estómago, como si alguien le dejara caer una 
                        piedra adentro, pero en lugar de caer el golpe subió hasta la boca de su estómago y 
                        se le atoró allí. Trató de pedir ayuda nuevamente, pero cayó profundamente dormido 
                        al instante, aunque en realidad no tenía nada de sueño. Era como si algo o alguien 
                        hubiera apagado un interruptor dentro de él.
                       </p>                   
                    </Grid>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12} justifyContent='center'>
                       <h3 className='Titulo3'><b>Capítulo3</b><br/> Mientras tanto, dentro de Pablo</h3>                
                    </Grid>
                    <Grid container sm={12}>
                       <p className='Lectura'>
                        Cuando un ovni se estaciona en la tierra, aterriza. Cuando un cohete se estaciona en 
                        la luna, aluniza. Cuando Brimlog, el intraterrestre, se estacionó en las tripas de Pablo, 
                        entripó. <br/><br/>
                        
                        Tras el entripaje exitoso, Brimlog se fijó muy bien en lo que tenía a su alrededor para 
                        recordar dónde dejaba su nave. “A la izquierda del apéndice, antes del intestino 
                        grueso, donde hay un pedazo de queso amarillo sin digerir”, se dijo a sí mismo […] <br/><br/>
                        
                        Con un disparo, lanzó un arponazo hacia arriba. Aquél fue el momento en el que 
                        Pablo sintió un fuerte golpe en la boca del estómago y se quejó. Su lamento resonó 
                        por toda la cavidad torácica, como un eco dentro de una ballena. Así que Brimlog 
                        decidió que sería mejor dormir al humano pequeño mientras establecía su 
                        campamento. Soltó una granada de gas noqueador que dejó a Pablo inconsciente en 
                        menos de un minuto. <br/><br/>
                        
                        Con Pablo inconsciente, Brimlog instaló tranquilamente la Máquina Comeenojos en 
                        su estómago. Ese era el mejor lugar para encontrar la ira en los seres humanos. 
                        Instaló los mecanismos de la Bomba de Ira en el corazón, verificó que el suplemento 
                        de rabia llegara bien y que la bomba quedara atada a las emociones del humanito.<br/><br/>

                        Por último, regresó al estómago. Tras hacer un sonidito ronco, como de un gato viejo 
                        escupiendo una bola de pelos, la Máquina Comeenojos empezó a funcionar, 
                        transformando todo el enojo de Pablo en un río pesado, viscoso, semejante al magma 
                        de un volcán. Aquella sustancia tenía un brillante tono anaranjado y gracias a ella 
                        Brimlog pudo poner una tabla de flotación (parecida a una tabla de surf) y subir de la 
                        panza a la garganta, hasta el interior de la cabeza […] <br/><br/>
                        
                        Era hora de instalar la Consola de Mando. Mientras más se enojara el humano 
                        pequeño, mejor podría Brimlog usar esa consola para dominar sus acciones. “Pero 
                        ¿dónde ponerla?”, se cuestionó. Le pareció que el entrecejo era un lugar idóneo. 
                        Había notado que muchos humanos fruncían el ceño al enojarse y sospechaba que 
                        había un punto de ira cerca de ahí. <br/><br/>
                        
                        Instaló la consola con todas sus partes. ¿Visores para ocelos y asiento biselar? Listo. 
                        ¿Asiento del tórax? Hecho. ¿Recargaderas para tentáculos superiores? Puestas. 
                        ¿Pedales para tentáculos inferiores? Colocados. ¿Extensiones de pinzas bucales? 
                        Activadas. ¿Asiento de la cola…? Después del meneo y de unas cuantas sacudidas 
                        retráctiles, quedó instalado y en su lugar […] <br/><br/>
                        
                        Con cada gota que se filtraba, la luz en la base de Brimlog parpadeaba levemente, 
                        como si perdiera energía. Ese líquido misterioso interfería con sus sistemas. 
                        Preocupado, Brimlog consultó su manual de anatomía humana. La glándula se 
                        llamaba lagrimal y el líquido que emanaba eran lágrimas, hechas para mantener 
                        húmedos los ojos humanos. Brimlog revisó: no había ninguna conexión anatómica 
                        entre tripas y ojos, así que esa fuga no podía ser consecuencia del entripaje.<br/><br/>
                        
                        Al final de la página encontró una nota que advertía que los seres humanos 
                        limpiaban emociones (como el enojo, la tristeza o hasta la alegría) con esas lágrimas. 
                        Eso sonaba peligroso. Brimlog no podía quedarse quieto mientras perdía la energía 
                        que ya había cosechado. Decidió regresar a la Consola de Mando, donde se sentó y 
                        preparó su micrófono para emitir su primer mensaje: <br/><br/>
                        
                        —Hey, tú… Crío humano… —tardó un poco en recordar el nombre del humano que 
                        tanto habían gritado otros en el transporte familiar—. Humano Pablo, tienes una fuga 
                        de líquido ocular. Será mejor que la detengas antes de que se resequen tus ojos.
                       </p>                   
                    </Grid>
                    <Grid container justifyContent='center'>
                        <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion25/1.png" alt="Tarjeta" />
                    </Grid>
                </Grid>
        </Grid>
    </Grid>
    );
}
 
export default Pablo_y_el_intraterrestre_Cap_2y3;
