import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
    ContenedorLectura:{
        width:'502px',
                  [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
    },
    ContainerTitulo:{
        margin:'0 0 34px 0',
        '& .Titulo':{
            letterSpacing: '0px',
            color: '#474FA5',
            margin:'0',
            textAlign:'center',
            font:'43px fredoka one',
            lineHeight:'52px',
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            margin:'0 0 36px 0',
            color:'#000',
            font:'18px lato',
            lineHeight :'28px',
            letterSpacing: '0.17px',
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .SubTitulo':{
            color:'#F6644B',
            margin:'0',
            lineHeight :'28px',
            font:'bold 26px lato',
            letterSpacing: '0.24px',
            [theme.breakpoints.down(502)]:{
                font:'bold 18px lato',
                lineHeight :'24px',
                letterSpacing: '0.21px',
            },
        },

        '& .Imagen':{
             width:'100%',
        },   
    }
}));


const El_Festival_Cervantino_en_época_virtual = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >El Festival Cervantino<br/> en época virtual</h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container sm={12}>
                      <p className="Lectura Esp"> 
                        ¿Alguna vez habías escuchado la palabra <em>cervantino</em>? ¿Qué te imaginas que es? Pues 
                        bien, se trata de un festival cultural nombrado así por el apellido Cervantes. El 
                        Festival Internacional Cervantino es una importante celebración cultural en el 
                        mundo que se lleva a cabo cada año. Debido a la pandemia por coronavirus iniciada 
                        en 2020, los organizadores decidieron realizarlo de manera tanto presencial como 
                        virtual en 2021. Muchos eventos fueron transmitidos en vivo entre el 13 y el 31 de 
                        octubre.
                      </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img3ro/Sesion12/1.png" alt="Tarjeta" />
                    </Grid>
                </Grid>
               
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container sm={12}>
                      <p className="Lectura "> 
                       <h3 className='SubTitulo'>Festival híbrido</h3><br/>
                       
                        El Festival Internacional Cervantino se lleva a cabo desde 1972 en la ciudad de
                        Guanajuato. El festival fue nombrado así en honor a un escritor llamado Miguel de 
                        Cervantes Saavedra, quien vivió en España hace unos cuatrocientos años. Además de 
                        ser soldado, fue novelista, poeta y autor de obras de teatro. Esta fiesta cultural de 
                        Guanajuato inició con la representación de algunas de sus obras teatrales, por ello 
                        fue nombrado en su memoria.<br/><br/>
                        
                        El Cervantino fue obteniendo más importancia con el paso del tiempo hasta 
                        convertirse en la gran fiesta que es hoy, dirigida a toda clase de público. En 2021 
                        participaron artistas de más de 20 países distintos. Cada año hay un país que es el 
                        invitado de honor y todo gira en torno a su cultura. En esta ocasión fue el turno de 
                        Cuba.<br/><br/>
                        
                        Durante casi tres semanas se reúnen algunos de los mejores artistas del mundo de 
                        diferentes disciplinas artísticas: danza, teatro, música, literatura y algunas otras más.
                        ¿Te imaginas? Las calles de la ciudad se llenan de gente que disfruta las puestas en 
                        escena y los conciertos. <br/><br/>
                        
                        El Cervantino fue inaugurado el 13 de octubre en la explanada de la Alhóndiga de 
                        Granaditas. La apertura estuvo a cargo de la orquesta cubana Formell y los Van Van, 
                        que son íconos de la música tropical. También participó la Filarmónica del Desierto 
                        de Coahuila, una de las mejores orquestas mexicanas. Algunas personas disfrutaron 
                        los espectáculos en vivo y otras los vieron por medio de canales de internet o en 
                        televisión. <br/><br/>
                        
                        Los eventos más destacados de esa edición fueron las diferentes orquestas y bandas 
                        que tocaron música de Astor Piazzola, un importante compositor argentino del siglo 
                        pasado. Hubo presentaciones de música tradicional japonesa, música electrónica 
                        contemporánea de Alemania y un homenaje al mexicano Armando Manzanero, uno 
                        de los compositores más famosos de América Latina.
                      </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img3ro/Sesion12/2.png" alt="Tarjeta" />
                    </Grid>
                </Grid>
        </Grid>
    </Grid>
    );
}
 
export default El_Festival_Cervantino_en_época_virtual;
