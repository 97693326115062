import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
     Contenedor:{
        background:'#E9FBF9',
        width:'100vw',
        overflowX:'clip',
        [theme.breakpoints.down(1161)]:{
            background:'none',
           overflowX:'unset',
        },
        [theme.breakpoints.down(600)]:{
            marginTop: '79.51px',
        }
    },
  ContainerInfoLectura: {
    position: "relative",
    height: "86px",
    [theme.breakpoints.down(502)]:{
        height: "43px",
    },
    background: "#76D4C3",
    "& .BarraInfoLectura": {
      height: "86px",
      background: `Url(https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Barras/Azul.png)`,
        [theme.breakpoints.down(502)]:{
            height: "43px",
        },
    },
    "& .InfoLectura": {
      position: "absolute",
      height: "86px",
      width: "219px",
      boxSizing: "border-box",
      padding: "17.2px 0",
      background: "#FAFAFA",
      borderRadius: "50px",
      boxShadow: "2px 2px 2px 2px #00000029",
      zIndex: "4",
        [theme.breakpoints.down(502)]:{
            height: "43px",
            width: "109px",
            padding: "8.6px 0",
            borderRadius: "25px",
        },
    },
    "& .InfoLectura:before": {
      position: "absolute",
      display: "flex",
      boxSizing: "border-box",
      padding: "0 0 0 10px",
      justifyContent: "center",
      alignItems: "center",
      height: "39.88px",
      width: "39.88px",
      borderRadius: "50%",
      content: '""',
      boxShadow: "3px 0px .5px 0px #00000029",
      background: "#FAFAFA",
      left: "90px",
      top: "65px",
      transform: "rotate(0.25turn)",
      [theme.breakpoints.down(502)]:{
        left: "45px",
        top: "30px",
        height: "20.88px",
        width: "20.88px",
        },
    },
    "& .InfoLectura:after": {
      content: '""',
      height: "15px",
      width: "15px",
      borderTop: "2px solid #20A698",
      borderLeft: "2px solid #20A698",
      position: "absolute",
      bottom: "-10px",
      left: "Calc(50% - 8px)",
      transform: "rotate(0.625turn)",
      [theme.breakpoints.down(502)]:{
        height: "10px",
        width: "10px",
        left: "Calc(50% - 4.5px)",
        bottom: "-3px",
        borderTop: "1px solid #20A698",
        borderLeft: "1px solid #20A698",
        },
    },
    "& .Sesion": {
      borderLeft: "2px solid #D8D8D8",
    },
    "& .Dato": {
      margin: "0 0 5px 0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#20A698",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        margin: "0 0 2.5px 0",
        },
    },
    "& .Caracter": {
      margin: "0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#000000",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        },
    },   },
    ContenedorLectura:{
        background:'#fff',
        width:'1160px',
        height:'100%',
        padding:'60px 168px 35px ',
        [theme.breakpoints.down(1161)]:{
            width:'502px',
            padding:'60px 0 0 0',
          },
        [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
        '& .ContainerDerechos':{
            bottom:'80px',
            [theme.breakpoints.down(1161)]:{
                display:'none',
            },
        },
        '& .Derechos':{
            textAlign:"center",
            margin:"0px",
            font:" 15px lato",
            color:"#717476",
        },
        '& .Escritorio':{
            display:'block',
            [theme.breakpoints.down(1161)]:{
                display:'none'
            },
        },
        '& .Tablet':{
            display:'none',
            [theme.breakpoints.down(1161)]:{
                display:'block'
            },
        }
    },
    ContainerTitulo:{
        margin:'0 0 34px 0',
        [theme.breakpoints.down(1161)]:{
            margin:'0 0 34px 0',
        },
        '& .Titulo':{
            letterSpacing: '0px',
            color: '#619CC8',
            margin:'0',
            textAlign:'center',
            font:'48px fredoka one',
            lineHeight:'58px',
            [theme.breakpoints.down(1161)]:{
                font:'43px fredoka one',
                lineHeight:'52px',
            },
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            lineHeight : '30px',
            margin:'0 0 36px 0',
            font:'22px lato',
            color:'#000',
            letterSpacing: '0.20px',
            [theme.breakpoints.down(1161)]:{
                font:'18px lato',
                lineHeight :'28px',
                letterSpacing: '0.17px',
            },
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .TextImg':{
            font:'20px lato',
            color:'#717476',
            letterSpacing:'.19px',
            lineHeight:'30px',
            margin:'24px 0 0 0',
            [theme.breakpoints.down(1161)]:{
                font:' 16px lato',
                lineHeight :'25px',
                letterSpacing: '0.15px',
            },
            [theme.breakpoints.down(502)]:{
                font:' 11px lato',
                lineHeight :'14px',
                letterSpacing: '0.10px',
            },
        },
        '& .Imagen':{
            [theme.breakpoints.down(1161)]:{
                width:'100%',
            },
        }, 

    }
}));

const Animales_para_experimentos_científicos = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
             <Grid container item justifyContent="center" className={classes.ContainerInfoLectura}>
               <Grid container className="BarraInfoLectura"></Grid>
               <Grid container item className="InfoLectura">
                    <Grid container item direction="column" xs={6} justifyContent="center">
                        <p className="Dato">Grado</p>
                        <p className="Caracter">4</p>
                    </Grid>
                    <Grid container item direction="column" xs={6} justifyContent="center" className="Sesion">
                        <p className="Dato">Sesión</p>
                        <p className="Caracter">54</p>
                    </Grid>
               </Grid>
            </Grid>

            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >
                        ¿Animales para<br/> experimentos <br className='Tablet'/>científicos?                   
                    </h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                      <p className="Lectura">
                        La relación entre los seres humanos y los animales siempre ha sido cercana. Todas las 
                        sociedades del mundo los han utilizado para diversos fines, a veces benéficos para
                        ellos y a veces no. Muchos especímenes han atravesado esta situación. Se han usado 
                        para estudiar su estructura anatómica, probar diferentes vacunas y medicamentos, 
                        hacer pruebas de trasplantes de órganos e inclusive se han enviado a misiones 
                        espaciales para conocer los efectos de la falta de gravedad.<br/><br/>
                        
                        Un caso famoso sobre esto es el de la perrita Laika, una canina rusa que fue lanzada 
                        al espacio en un cohete espacial. La misión tenía el propósito de ver las 
                        consecuencias de los vuelos espaciales en las personas, pero era riesgoso enviar a 
                        una. Por eso se decidió abordar a Laika en la nave Sputnik 2 en el otoño de 1957. Ella 
                        se convirtió en el primer ser vivo de la Tierra en orbitar por fuera de ésta.<br/><br/>
                        
                        Se especula que la perrita murió horas después del despegue por un aumento 
                        intenso en su temperatura corporal y que experimentó mucho estrés y 
                        deshidratación. Distintas organizaciones de protección animal presentaron sus 
                        quejas y protestaron en las embajadas rusas antes del lanzamiento, pero fueron 
                        ignoradas. Laika ayudó a desarrollar cohetes mejor equipados para el espacio y sentó 
                        las bases para salvar otras vidas, pero incluso ahora sigue debatiéndose si fue ético 
                        someterla a ese dolor sin que ella supiera qué iba a suceder. <br/><br/>
                        
                        La ciencia moderna requiere probar sus productos antes de venderlos, es por ello 
                        que tiene que llevar a cabo estudios que garanticen que los objetos son seguros y no 
                        van a dañar a los usuarios. Ha optado por emplear animales para sus experimentos, 
                        que terminan asumiendo todo el riesgo y el daño que eso conlleva. <br/><br/>
                        
                        En ocasiones, son infectados con bacterias, virus o enfermedades para probar la 
                        efectividad de algún medicamento, como el cáncer o la diabetes. Frecuentemente 
                        mueren como resultado de los ensayos, además de haber sufrido los efectos de los 
                        tratamientos o el dolor ocasionado por los padecimientos. Algunos clones de 
                        mamíferos como la oveja Dolly también han muerto de forma prematura. Todavía se 
                        desconoce si la muerte fue causada por la clonación o por otro motivo. <br/><br/>
                        
                        Se cree que se emplean entre 50 y 100 millones de animales al año, entre simios,
                        chimpancés, peces, conejos, moscas de la fruta y roedores, criados especialmente 
                        para este propósito. El 90% de las pruebas se hace en roedores. Las pruebas de 
                        medicamentos, tratamientos, perfumes y cosméticos son necesarias para mantener 
                        un control de calidad. Hasta ahora no se cuenta con modelos artificiales en los que se 
                        puedan aplicar las pruebas, por lo que es difícil dejarlas atrás.
                      </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <Grid container justifyContent="center" >
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img4to/Sesion54/1.png" alt="Tarjeta" />
                        </Grid> 
                        <Grid container justifyContent="center" >
                            <p className='TextImg'>
                                Es poco probable eliminar esta experimentación por ahora, pero es importante buscar formas de disminuir la cantidad y eliminar el sufrimiento que les provoca.
                            </p>                        
                        </Grid>  
                    </Grid>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                        Se han promulgado leyes para la protección de mascotas desde el siglo <span style={{fontVariant: "all-small-caps"}}>XIX</span> y también 
                        se ha prohibido en muchos países la venta de cosméticos cuyos componentes hayan 
                        sido probados en animales. En la actualidad, se busca controlar las investigaciones 
                        genéticas que alteran las apariencias o funciones de esos seres. Los defensores de 
                        sus derechos proponen usar tecnología y modelos virtuales, lo cual evitaría estrés y
                        dolor.<br/><br/> 
                        
                        Ahora conoces la historia de Laika y aprendiste sobre los movimientos actuales que 
                        defienden a los animales. ¿Qué estrategias propondrías tú para disminuir esta 
                        problemática?

                        </p>
                    </Grid>
                </Grid>
                <Grid container item xs={12} justifyContent="center" className="ContainerDerechos">
                    <p className="Derechos">
                        © 2022. Todos los derechos reservados, Pearson, Circuito de lectores.
                    </p>                
                </Grid>
        </Grid>
    </Grid>
    );
}
 
export default Animales_para_experimentos_científicos ;
