import React, { useCallback, useContext, useEffect, useState } from "react";
import { createContext } from "react";
import { useHistory } from "react-router-dom";
import AuthContext from "../auth/AuthContext";
import Loader from "../components/Loader/Loader";
import ModalMessage from "../components/Modals/ModalMessage";
import useModalMessage from "../hooks/useModalMessage";

const UsabilityContext = createContext();

const UsabilityProvider = ({ children }) => {
  const { user } = useContext(AuthContext);
  const [openMenuInDesktop, setOpenMenuInDesktop] = useState(false);
  const [openMenuInMobile, setOpenMenuInMobile] = useState(false);

  const [openSesionFinalModal, setOpenSesionFinalModal] = useState(false);

  const [animationBadgeStar, setAnimationBadgeStart] = useState(false);
  const [animationBadgeHeart, setAnimationBadgeHeart] = useState(false);
  const [groupToEdit, setGroupToEdit] = useState("");
  const [userDataToEdit, setUserDataToEdit] = useState({});
  const [groupData, setGroupData] = useState(null);

  const [search, setSearch] = useState("");

  const history = useHistory();

  //Modals
  const [loading, setLoading] = useState(false);

  const handleLoading = useCallback(
    (option = false) => {
      setLoading(option);
    },
    []
  );

  const {
    modalMessageOpen,
    modalMessageText,
    handleModalMessage,
    clearTextModalMessage,
    closeModalMessage,
  } = useModalMessage();

  const handleGroupData = (data) => {
    setGroupData(data);
  };

  const handleUserData = (data) => {
    setUserDataToEdit(data);
  };

  const handleMenuInDesktop = () => {
    setOpenMenuInDesktop(!openMenuInDesktop);
  };

  const handleMenuInMobile = () => {
    setOpenMenuInMobile(!openMenuInMobile);
  };
  const handleSesionFinalModal = () => {
    setOpenSesionFinalModal(!openSesionFinalModal);
  };

  const handleGroupToEdit = (id) => {
    setGroupToEdit(id);
  };

  const handleSetStar = () => {
    setAnimationBadgeStart(true);
  };

  const handleSetHeart = () => {
    setAnimationBadgeHeart(true);
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  const clearSearch = useCallback(() => {
    setSearch("");
  }, []);

  useEffect(() => {
    if (groupData === null) handleGroupData(user?.grupos?.[0]);
  }, [user, groupData]);

  useEffect(() => {
    setOpenMenuInDesktop(false);
    setOpenMenuInMobile(false);
  }, [user.logged]);

  useEffect(() => {
    if (user.logged) {
      if (user.rol === "alumno") {
        if (!user.acceptedConditions || user.codigo?.codigo_alumno_generico) {
          history.push("/welcome_page");
        } else if (!user.avatar?.path) {
          history.push("/avatars");
        }
      } else if (user.rol === "docente") {
        if (!user.acceptedConditions) {
          history.push("/docente/welcome");
        }
      } else if (user.rol === "administrador pearson") {
        if (!user.acceptedConditions) {
          history.push("/admin/welcome");
        }
      }
    } else if (user.rol === "administrador institucional") {
      if (!user.acceptedConditions) {
        history.push("/admin-inst/welcome");
      }
    }
    return () => {};
  }, [history, user]);

  const data = {
    openMenuInDesktop,
    openMenuInMobile,
    handleMenuInDesktop,
    handleMenuInMobile,
    openSesionFinalModal,
    handleSesionFinalModal,
    animationBadgeStar,
    animationBadgeHeart,
    handleSetStar,
    handleSetHeart,
    setAnimationBadgeStart,
    setAnimationBadgeHeart,
    groupToEdit,
    handleGroupToEdit,
    userDataToEdit,
    handleUserData,
    groupData,
    handleGroupData,
    handleSearch,
    clearSearch,
    search,
    //Modals
    //Loader
    loading,
    handleLoading,

    //ModalMessage
    modalMessageOpen,
    handleModalMessage,
    closeModalMessage,
    clearTextModalMessage,
  };
  return (
    <UsabilityContext.Provider value={data}>
      {children}
      {loading && <Loader />}
      {modalMessageOpen && (
        <ModalMessage
          open={modalMessageOpen}
          handleClose={closeModalMessage}
          title={modalMessageText}
          textOfButton="Aceptar"
        />
      )}
    </UsabilityContext.Provider>
  );
};

export { UsabilityProvider };

export default UsabilityContext;