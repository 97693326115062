import { Button, Grid, makeStyles } from "@material-ui/core";
import React, { useContext, useEffect } from "react";
import DrawerContext from "../../../../../usability/DrawerContext";
import { useOnLoadImages } from "../../../../hooks/useOnLoadImages";
import Loader from "../../../../Loader/Loader";
import Drawer from "../../ReadingTools/Drawer";

const useStyles =  makeStyles (theme =>({
     Contenedor:{
        background:'#E9FBF9',
        width:'100vw',
        overflowX:'clip',
        [theme.breakpoints.down(1161)]:{
            background:'none',
           overflowX:'unset',
        },
        [theme.breakpoints.down(600)]:{
            marginTop: '79.51px',
        }
    },
      ContainerInfoLectura: {
    position: "relative",
    height: "86px",
    [theme.breakpoints.down(502)]:{
        height: "43px",
    },
    background: "#76D4C3",
    "& .BarraInfoLectura": {
      height: "86px",
      background: `Url(https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Barras/Azul.png)`,
        [theme.breakpoints.down(502)]:{
            height: "43px",
        },
    },
    "& .InfoLectura": {
      position: "absolute",
      height: "86px",
      width: "219px",
      boxSizing: "border-box",
      padding: "17.2px 0",
      background: "#FAFAFA",
      borderRadius: "50px",
      boxShadow: "2px 2px 2px 2px #00000029",
      zIndex: "4",
        [theme.breakpoints.down(502)]:{
            height: "43px",
            width: "109px",
            padding: "8.6px 0",
            borderRadius: "25px",
        },
    },
    "& .InfoLectura:before": {
      position: "absolute",
      display: "flex",
      boxSizing: "border-box",
      padding: "0 0 0 10px",
      justifyContent: "center",
      alignItems: "center",
      height: "39.88px",
      width: "39.88px",
      borderRadius: "50%",
      content: '""',
      boxShadow: "3px 0px .5px 0px #00000029",
      background: "#FAFAFA",
      left: "90px",
      top: "65px",
      transform: "rotate(0.25turn)",
      [theme.breakpoints.down(502)]:{
        left: "45px",
        top: "30px",
        height: "20.88px",
        width: "20.88px",
        },
    },
    "& .InfoLectura:after": {
      content: '""',
      height: "15px",
      width: "15px",
      borderTop: "2px solid #20A698",
      borderLeft: "2px solid #20A698",
      position: "absolute",
      bottom: "-10px",
      left: "Calc(50% - 8px)",
      transform: "rotate(0.625turn)",
      [theme.breakpoints.down(502)]:{
        height: "10px",
        width: "10px",
        left: "Calc(50% - 4.5px)",
        bottom: "-3px",
        borderTop: "1px solid #20A698",
        borderLeft: "1px solid #20A698",
        },
    },
    "& .Sesion": {
      borderLeft: "2px solid #D8D8D8",
    },
    "& .Dato": {
      margin: "0 0 5px 0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#20A698",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        margin: "0 0 2.5px 0",
        },
    },
    "& .Caracter": {
      margin: "0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#000000",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        },
    },
       '& .Regresar':{
        color: '#F2624F',
        border: '#F2624F 1px solid',
        height: '30px',
        width:'100px',
        borderRadius: '20px',
        letterSpacing: '0.14px',
        textTransform: 'none',
        background:'#FEFAF1',
        top:'calc(50% - 15px)',
        left:'5%',
        zIndex:4,
        [theme.breakpoints.down(502)]: {
          font:'bold 10px lato',
          width: "60px",
        },
      },
  },
    ContenedorLectura:{
        background:'#fff',
        width:'1160px',
        height:'100%',
        padding:'60px 168px 35px ',
        
        transform: (props) =>
        props.scale > 1 ? `scale(${props.scale}) translate(0px, 10%)` : `none`,
        [theme.breakpoints.down(1161)]:{
            width:'502px',
            padding:'60px 0 0 0',
          },
        [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },

        '& .Escritorio':{
            display:'block',
            [theme.breakpoints.down(1161)]:{
                display:'none'
            },
        },
        '& .Tablet':{
            display:'none',
            [theme.breakpoints.down(1161)]:{
                display:'block'
            },
        }
    },
    ContainerTitulo:{
        margin:'0 0 34px 0',
        [theme.breakpoints.down(1161)]:{
            margin:'0 0 34px 0',
        },
        '& .Titulo':{
            font:'48px fredoka one',
            letterSpacing: '0px',
            lineHeight:'58px',
            color: '#FF2B2B',
            margin:'0 0 5px 0',
            textAlign:'center',
            [theme.breakpoints.down(1161)]:{
                font:'43px fredoka one',
                lineHeight:'52px',
            },
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
        '& .Titulo2':{
            font:'bold 30px lato',
            letterSpacing: '.28px',
            lineHeight:'30px',
            color: '#FF2B2B',
            margin:'0',
            textAlign:'center',
            [theme.breakpoints.down(1161)]:{
                font:'bold 26px lato',
                letterSpacing: '.24px',
                lineHeight:'28px',
            },
            [theme.breakpoints.down(502)]:{
                font:'bold 22px lato',
                lineHeight:'24px',
            },
            [theme.breakpoints.down(350)]:{
                font:'bold 18px lato',
                lineHeight:'24px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        position:'relative',
        '& .Lectura':{
            lineHeight : '30px',
            margin:'0 0 36px 0',
            font:'22px lato',
            color:'#000',
            letterSpacing: '0.20px',
            [theme.breakpoints.down(1161)]:{
                font:'18px lato',
                lineHeight :'28px',
                letterSpacing: '0.17px',
            },
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .Imagen':{
            [theme.breakpoints.down(1161)]:{
                width:'100%',
            },
        },  
      
    }
}));

const Los_viajes_de_Maqui_Cap5 = (props) => {

  const {activeStep, setActiveStep, handlePreviewStep, id_actividad} = props
  
  const handleContinue = () => {
    setActiveStep(activeStep - 1)
    handlePreviewStep()
  }

  const {
    handleSize,
    disabledZoom,
    closeZoom,
    handleZoom,
    scale
  }= useContext(DrawerContext);
  const [loading, setLoading] = React.useState(true);
  const wrapperRef = React.useRef(null);
  const imagesLoaded = useOnLoadImages(wrapperRef);

  const detectSize = () => {
    const lectura = document.getElementById("lectura");
    const newWidth = lectura?.offsetWidth;
    const newHeight = lectura?.offsetHeight;
    setLoading(false);
    if (lectura) handleSize(newHeight, newWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", detectSize);
    return () => {
      handleZoom()
      window.removeEventListener("resize", detectSize);
      closeZoom()
    };
  }, []);

  const classes = useStyles({ disabledZoom,scale });
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
             <Grid container item justifyContent="center" className={classes.ContainerInfoLectura}>
                       <Grid container className="BarraInfoLectura">
            <Button className="Regresar" onClick={handleContinue}>
                Regresar
            </Button>
        </Grid>
               <Grid container item className="InfoLectura">
                    <Grid container item direction="column" xs={6} justifyContent="center">
                        <p className="Dato">Grado</p>
                        <p className="Caracter">4</p>
                    </Grid>
                    <Grid container item direction="column" xs={6} justifyContent="center" className="Sesion">
                        <p className="Dato">Sesión</p>
                        <p className="Caracter">31</p>
                    </Grid>
               </Grid>
            </Grid>
            {!imagesLoaded && <Loader />}
        <Grid
            container
            item
            className={classes.ContenedorLectura}
            justifyContent="center"
            id="lectura"
            onLoad={detectSize}
            ref={wrapperRef}
        >
<Drawer id_lectura={id_actividad} />
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                        <Grid container justifyContent="center" sm={12}>
                            <h2 className="Titulo">Los viajes de Maqui</h2>
                        </Grid>
                        <h3 className='Titulo2'>Fragmento 5 (capítulo 5)</h3>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                       <p className='Lectura'>
                        Tacha y Maqui entran en otra dimensión, en un mundo nuevo. Allá abajo hay una llanura de color verde y un avión con hélices esperando por ellas.
                        —¿Sabes volar aviones? —le pregunta Maqui a Tacha.
                        <br/><br/>
                        —No, ¿y tú?
                        <br/><br/>
                        —Claro.
                        <br/><br/>
                        Ambas se colocan unos cascos de cuero y lentes de aviación. Preparan el despegue y vuelan hacia la aventura. ¡Son un par de exploradoras!
                       </p>                   
                    </Grid>
                    <Grid container justifyContent='center' >
                        <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img4to/Sesion31/1.png" alt="Tarjeta" />
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                       <p className='Lectura'>
                        Aterrizo nuevamente en una nube. Noto que he aprendido a volar en ellas. Supongo que pilotear nubes es muy parecido a conducir autos, pero en el cielo. Voy tras las perritas, no se me pueden perder.
                        <br/><br/>
                        —Tacha, estamos en Colombia. Vamos a ir a un sitio inimaginable que se encuentra al fondo de esas cascadas —escucho decir a Maqui.
                        <br/><br/>
                        Maqui toma velocidad y vuela hacia la caída de agua. Vuelan tan rápido que ni siquiera se mojan. Recorren la selva y ven los pájaros de colores volar.
                        <br/><br/>
                        —Mira hacia el fondo.
                        <br/><br/>
                        Tacha ve una impresionante pirámide sobresalir del follaje. Sus ojos no pueden dar crédito al brillo que emana de la Ciudad Dorada.
                        <br/><br/>
                        —¿Dónde estamos, Maqui?
                        <br/><br/>
                        —Hemos llegado a las Pirámides Doradas. Y muy a tiempo para la ceremonia sagrada.
                        <br/><br/>
                        Mi perrita aterriza su avión de hélices cerca de un lago. Los pobladores del lugar la reciben con mucha alegría. Al parecer, no es la primera vez que viaja a ese sitio. Ella les presenta a Tacha y los niños comienzan a jugar con ella.
                        <br/><br/>
                        Mientras tanto, Maqui habla con el rey de la ciudad de oro. Le cuenta que su compañera está muy cansada y que le gustaría ofrendar unos aretes en honor a la salud de Tacha. El rey observa la ofrenda que Maqui tomó sin permiso de mi cofre de bisutería. Esos aretes me los regaló mi mamá, son de oro y son los únicos aretes valiosos que tengo. El gobernante acepta y llama a la ceremonia.
                        <br/><br/>
                        La gente de la aldea se reúne en la Laguna Sagrada. Llegan con cestas cargadas de obsequios. El rey ha cubierto su cuerpo con polvo de oro. Tocan música con tambores y otros instrumentos de viento, como caracoles marinos. Los niños le ponen a Tacha hermosos collares de flores de colores. Les piden a los dioses por la salud de la perrita.
                        <br/><br/>
                        Durante la ceremonia, Maqui mira con sorpresa las transformaciones de Tacha. Poco a poco las canas del hocico comienzan a desaparecer, sus patas se ven más fuertes y se encuentra más ligera. Ya no camina despacio, ahora puede correr y jugar. Se ve muy joven.

                        Maqui se acerca al gobernante y le pregunta por los cambios que sufre Tacha. Él le explica que en las Pirámides Doradas Tacha ha rejuvenecido y podrá ser eternamente joven. Los niños de la aldea la aman y quieren jugar con ella.
                        <br/><br/>
                        —¿Qué pasará cuando regresemos a nuestro mundo?
                        <br/><br/>
                        —Ella volverá al mismo estado en el que vino —le responde el rey a Maqui— Aunque su alma podría vivir en este lugar eternamente.
                        <br/><br/>
                        Maqui llama a Tacha. Es hora de regresar. Tacha se siente feliz y no quiere volver a enfermarse, pues se siente muy cansada.
                        —Maqui, gracias por traerme aquí. Tengo que despedirme.
                        <br/><br/>
                        Mi perrita no entiende y le ruega que regrese. Tacha no acepta, ella quiere quedarse en las Pirámides Doradas y ser feliz para siempre. Después de llorar un poco, ambas se despiden. El avión deberá regresar a casa sin una de nuestras compañeras…
                        <br/><br/>
                        Yo tampoco puedo creer que Tacha no quiera volver a casa, pues durante 14 años ha sido un miembro importante de nuestra familia.
                        <br/><br/>
                        ***
                        <br/><br/>
                        A la mañana siguiente, Maqui despierta sola. Hay mucho ruido en la habitación de Guille. Nos levantamos y vamos a la recámara de mi hermano. Nos encontramos con una escena desgarradora. Tacha yace en los brazos de Guille, su amigo humano. Ha muerto, pero la familia pudo despedirse de la perrita y acompañarla en sus últimos minutos.
                        <br/><br/>
                        —Guille la pudo escoltar al otro mundo —le digo a Maqui.
                        <br/><br/>
                        Sin embargo, Maqui sabe que Tacha no ha muerto, que se encuentra en un lugar allá por Colombia, cruzando las cascadas, en una ciudad de oro donde los niños la abrazan y juegan con ella. Donde estará saludable y será amada eternamente.
                       </p>                   
                    </Grid>
                </Grid>
        </Grid>
        <Grid container item style={{ marginTop: 80}} ></Grid>
    </Grid>
    );
}
 
export default Los_viajes_de_Maqui_Cap5;
