import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
    ContenedorLectura:{
        width:'502px',
                  [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
    },
    ContainerTitulo:{
        margin:'0 0 46px 0',
        '& .Titulo':{
            letterSpacing: '0px',
            color: '#FC5E9E',
            margin:'0',
            font:'43px fredoka one',
            lineHeight:'52px',
            textAlign:'center',
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            margin:'0',
            textAlign:'center',
            color:'#000',
            font:'18px lato',
            lineHeight :'28px',
            letterSpacing: '0.17px',
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .Imagen':{
            width:'100%',
        },
    },
}));


const Todos_en_uno_y_uno_en_todos = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>

            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >
                        Todos en uno<br/> y uno en todos
                    </h2>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container item justifyContent='center' xs={12} >
                        <p className="Lectura">
                            Yo soy hijo de las olas, del mar.<br/> 
                            Llevo en mi piel el color del sol<br/> 
                            y mi voz es la del caracol,<br/> 
                            y la brisa que duerme en un palmar.<br/><br/> 
                            
                            A mí me arrullaron las estrellas<br/> 
                            y mi cuna fue una montaña.<br/> 
                            A donde voy el frío me acompaña,<br/> 
                            aunque en mi corazón arden centellas.<br/><br/> 

                            Yo del horizonte vengo.<br/> 
                            Recorro a cielo abierto<br/> 
                            las dunas del desierto <br/>
                            y un oasis en las manos sostengo.
                        </p>
                    </Grid>
                    <Grid container justifyContent="center" xs={12}>
                            <img className='Imagen' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img3ro/Sesion20/1.png"  alt="Tarjeta" />
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container item justifyContent='center' xs={12}>
                        <p className="Lectura">
                            Yo del sur y tú del norte, <br/>
                            él de occidente, ella de oriente. <br/>
                            La geografía es un accidente <br/>
                            y un barco de papel el pasaporte. <br/><br/>
                            
                            Aunque diferentes, <br/>
                            todos somos uno, <br/>
                            y en mis venas reúno <br/>
                            sangre de todos los continentes. <br/><br/>
                            
                            Mar, selva, desierto, <br/>
                            montañas, pampas, llanuras… <br/>
                            todas las culturas <br/>
                            atracan en el mismo puerto.
                        </p>
                    </Grid>
                    <Grid container justifyContent="center" xs={12} >
                        <img className='Imagen' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img3ro/Sesion20/2.png"  alt="Tarjeta" />
                    </Grid>
                    
                </Grid>
        </Grid>
    </Grid>
    );
}
 
export default Todos_en_uno_y_uno_en_todos;
