import React, { useEffect } from "react";
import axios from "../../services/axios";
import { useHistory } from "react-router-dom";

const RedirecToErrorActivity = (data, history) => {
  if (
    data?.status.toLowerCase() === "contador" ||
    data?.status.toLowerCase() === "bloqueada" ||
    data?.status.toLowerCase() === "remedial"
  ) {
    history.push({
      pathname: "/error/sesion-bloqueada",
      state: {
        reason: "sesion-bloqueada",
      },
    });
  }
  // history.push({
  //   pathname: "/error/sesion-bloqueada",
  //   state: { reason: "sesion-bloqueada" },
  // });
};

const UseActivityData = ({ id_actividad }) => {
  const [step, setStep] = React.useState(0);
  const [questionList, setQuestionList] = React.useState([]);
  const [data, setData] = React.useState({});
  const history = useHistory();

  useEffect(() => {
    if (!questionList.length) {
      axios
        .get(`/actividad/preguntas/get?id_actividad=${id_actividad}`)
        .then((response) => {
          setQuestionList(response.data.resultado.preguntas);
          setData(response.data.resultado);
          setStep(
            response.data.resultado.preguntas.findIndex(
              (pregunta) => !pregunta.respondida
            )
          );
          RedirecToErrorActivity(response.data.resultado, history);
        });
    }
  }, [questionList.length, history, id_actividad]);

  return {
    step,
    setStep,
    questionList,
    setQuestionList,
    data,
  };
};

export default UseActivityData;
