import React from "react";
import { makeStyles, Grid } from "@material-ui/core";
import NavBar from "../../../../NavBar";
import MenuIzquierdo from "../../../../MenuIzquierdo";
import Activity from "../../Activity";
import Lectura from "../../../LecturasActividad/LecturasSegundo/Sesion8/Lectura";
import QuestionProv from "../../QuestionProv";
import Question from "../../Question";

import QuestionImg from "./QuestionImg";

import picture1 from "./image/CL_G2_B1_S08_IC36_Mate_Un_conjunto_imaginacion_01.png";
import UseActivityData from "../../../../hooks/UseActivityData";


const useStyles = makeStyles((theme) => ({
  grid1: {
    zIndex: "3",
  },
  grid2: {
    zIndex: "2",
  },
  grid3: {
    boxSizing: "border-box",
    zIndex: "1",
    paddingLeft: "3%",
    [theme.breakpoints.down("md")]: {
      paddingLeft: "0px",
    },
  },
}));

const ActivityS08 = (props) => {
  const classes = useStyles();

  const { step, setStep, questionList, setQuestionList, data } =
    UseActivityData({ id_actividad: 71 });

  const renderQuestion = () => {
    return step === 9 ? (
      <QuestionProv
        pregunta={
          questionList.length > 0 ? questionList[step].pregunta : "cargando..."
        }
        step={step}
      />
    ) : step === 3 ? (
      <QuestionImg picture1={picture1} />
    ) : (
      <Question
        step={step}
        pregunta={
          questionList.length > 0 ? questionList[step].pregunta : "cargando..."
        }
      />
    );
  };

  return (
    <Grid container>
      <Grid item className={classes.grid1} sm={12}>
        <NavBar />
      </Grid>
      <Grid item className={classes.grid2} sm={1}>
        <MenuIzquierdo />
      </Grid>
      <Grid item className={classes.grid3} sm={12}>
        <Activity
          step={step}
          setStep={setStep}
          questionList={questionList}
          setQuestionList={setQuestionList}
          questionComponent={renderQuestion()}
          Lectura={Lectura}
          // imageList={imageList}
          // picturesTable={picturesTable}
          toSession={"/session08"}
          activityNumber={71}
          enrollmendId={data && data.id_actividad_inscripcion}
          tryNumber={data && data.intento}
        />
      </Grid>
    </Grid>
  );
};

export default ActivityS08;
