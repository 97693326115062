import React from "react";
import { Grid, makeStyles } from "@material-ui/core";
import Loader from "../../Loader/Loader";

const useStyle = makeStyles((theme) => ({
  ContainerEmotios: {
    position: "relative",
    // height: "100%",
    height: "100vh",
    width: "100vw",
    flexWrap: "nowrap",
    background:
      "linear-gradient(32deg, rgba(185,101,211,0.2105042700674019) 1%, rgba(255,255,255,1) 20%, rgba(255,255,255,1) 36%, rgba(254,254,254,1) 77%, rgba(252,227,80,0.2777311608237045) 100%)",

    [theme.breakpoints.up("sm")]: {
      display: "grid",
      gridTemplateColumns: "1fr",
      gridTemplateRows: "auto 1fr",
      paddingBottom: "15vh",
      paddingTop: "8vh",
    },
  },
  Description: {
    margin: "0px",
    textAlign: "center",
    justifyContent: "center",
    font: "28px Fredoka One ",
    letterSpacing: "0.69px",
    color: "#1DA698",
    [theme.breakpoints.up("md")]: {
      fontSize: "42px",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "46px",
    },
  },
  Question: {
    margin: "0px",
    font: "32px Fredoka One",
    color: "#DC5EA6",
    letterSpacing: " 0px",
    textAlign: "center",
    [theme.breakpoints.up("md")]: {
      fontSize: "48px",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "52px",
    },
  },

  Avatars: {
    height: "992px",
    width: "100vw",
    [theme.breakpoints.down(1280)]: {
      height: "1080px",
    },
  },

  ContainerTexto: {
    // height: "20%",
    display: "flex",
    alignContent: "center",
    justifyContent: "center",
    flexDirection: "column",
  },

  input: {
    height: "25px",
    width: "25px",
    cursor: "pointer",
  },

  BottomBar: {
    position: "fixed",
    bottom: "0px",
    background: "#F5D568",
    height: "8vh",
    width: "100vw",
    alignContent: "center",
    justifyContent: "center",
  },
  link: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    // right: "10%",
    bottom: "50%",
    height: "100%",
    width: "100%",
    position: "absolute",
    textDecoration: "none",
    [theme.breakpoints.down("md")]: {
      transform: "translate(50%)",
      right: "50%",
    },
  },
  avatarsContainer: {
    width: "100%",
    display: "grid",
    gridTemplateColumns: "repeat(5, 1fr)",
    gridTemplateRows: "auto auto",
    alignContent: "center",
    // gridTemplateRows: "1fr 1fr",
    gap: "1%",
    margin: "0 auto",
    // marginBottom: "10vh",

    [theme.breakpoints.up("480")]: {
      // width: "70%",
      overflow: "auto",
    },
    [theme.breakpoints.up("sm")]: {
      // width: "80%",
      // maxWidth: "768px",
      // marginBottom: "15vh",
      overflow: "auto",
      alignItems: "center",
      "@media(min-height:1024px)": {
        width: "100%",
      },
      "@media (orientation: landscape)": {
        "@media(max-height:500px)": {
          // marginBottom: "20vh"
          alignContent: "stretch",
        },
        width: "100%",
        maxWidth: "1600px",
        marginBottom: "0vh",
      },
    },
    // [theme.breakpoints.up("sm")]: {
    //   margin: "0 auto",
    //   width: "60%",
    // },
    [theme.breakpoints.up("md")]: {
      margin: "0 auto",
      // marginBottom: "20vh",
      // gap: "5%",
    },
    [theme.breakpoints.up("1000")]: {
      // marginBottom: "10vh",
      maxWidth: "1024px",
      "@media(min-height:1024px)": {
        // margin: "5vh auto",
      },
    },
    [theme.breakpoints.up("xl")]: {
      // minHeight: "82vh",
      maxWidth: "1600px",
      "@media(max-height:1024px)": {
        alignContent: "stretch",
      },
    },
  },
  container: {
    [theme.breakpoints.up("sm")]: {
      margin: "0 auto",
      width: "60%",
    },
    [theme.breakpoints.up("md")]: {
      margin: "0 auto",
      width: "40%",
    },
    [theme.breakpoints.up("lg")]: {
      // minHeight: "82vh",
    },
  },
  BotonContinuar: {
    textTransform: "none",
    height: "8vh",
    maxHeight: "62px",
    width: "50%",
    maxWidth: "238px",
    background: "#5825AD",
    boxShadow: "0px 3px 6px #00000029",
    borderRadius: "61px",
    color: "#FFFFFF",
    font: "bold 18px lato ",
    [theme.breakpoints.up("lg")]: {
      font: "bold 20px lato ",
    },
    "&:hover": {
      background: "#dd78fb",
    },
  },
}));

const LogoutNative = () => {
  const classes = useStyle({});
  return (
    <>
      <Grid
        containter
        item
        container
        justifyContent="center"
        direction="column"
        className={classes.ContainerEmotios}
      >
        {<Loader />}
      </Grid>
    </>
  );
};

export default LogoutNative;
