import { Button, makeStyles } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";

import SelectSchool from "../../SelectSchool";
import axios from "../../../../../../services/axios";
import AuthContext from "../../../../../../auth/AuthContext";
import { Link } from "react-router-dom";
import TableTeacher from "./TableTeacher";
import TableStudent from "./TableStudent";
import SelectTipo from "../Selects/SelectTipo";
import SelectGradeGroup from "../Selects/SelectGrades";

const useStyles = makeStyles((theme) => ({
  input: {
    width: "100%",
    height: "40px",
    paddingLeft: "27px",
    paddingRight: "27px",
    borderRadius: "61px",
    boxSizing: "border-box",
    // border: " solid #1D2AAD 3px ",
    border: "none",
    background: "#FFFFFF",
    font: "Helvetica",
    fontSize: "16px",
    //    width: '449px',
    // height: '72px',
    // margin: '43px 139px 19px 66px',
    // padding: '26px 24px 25px',
    backgroundColor: "#f0f0f0",
    "&:focus": {
      outline: "none",
    },
    [theme.breakpoints.up("lg")]: {
      // height: "60px",
      // maxWidth: "449px",
      fontSize: "18px",
    },
    "&::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
    },
    "&::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
    },
  },
  label: {
    fontWeight: "bold",
    fontFamily: "Lato, sans-serif",
  },
  labelUrl: {
    fontFamily: "Lato, sans-serif",
    color: "#505759",
  },
  url: {
    color: "#3f43b3",
  },
  PurpleButton: {
    color: "#FFFFFF",
    backgroundColor: "#9051b1",
    borderRadius: "30px",
    alignSelf: "center",
    // margin: "0 auto",

    width: "100%",
    maxWidth: "238px",
    minHeight: "45px",
    border: "none",
    textTransform: "unset",
    cursor: "pointer",
    fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
    fontWeight: "500",
    padding: "6px 16px",
    lineHeight: "1.75",
    transition:
      "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    boxShadow:
      "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
    "&:hover": {
      backgroundColor: "#B965D3 ",
    },
    [theme.breakpoints.up("lg")]: {
      // gridArea: "button",
    },
  },
}));

const CodigoEspecifico = ({
  formData,
  handleForm,
  setFormData,
  useTypes,
  profileTypes,
  sendErrorToAdd,
  handleResponseModalData,
}) => {
  const classes = useStyles();
  const { user } = useContext(AuthContext);
  const [listOfSchools, setListOfSchools] = useState([]);
  const [optionGrades, setOptionGrades] = useState([]);
  const [teachers, setTeachers] = useState([]);
  const [students, setStudents] = useState([]);

  const addUser = () => {
    if (formData?.id_escuela && formData?.grado && formData?.grupo) {
      const userData = {
        grade: formData?.grado,
        group_id: formData?.grupo,
        group: optionGrades
          .find((item) => item.id_grado === formData.grado)
          ?.grupos.find((item) => item.id_grupo === formData.grupo)?.grupo,
        profile: formData?.perfil,
        school_id: formData?.id_escuela,
        school_name: listOfSchools?.find(
          (school) => school?.id_escuela === formData?.id_escuela
        )?.escuela,
        id: Date.now(),
      };

      if (formData?.perfil === 2) {
        setTeachers([...teachers, userData]);
        setFormData({
          ...formData,
          grado: "",
          grupo: "",
          id_escuela: "",
          cantidad_codigos_especificos: 1,
        });
      } else {
        setStudents([
          ...students,
          {
            ...userData,
            cant: formData?.cantidad_codigos_especificos || 1,
          },
        ]);
        setFormData({
          ...formData,
          grado: "",
          grupo: "",
          id_escuela: "",
          cantidad_codigos_especificos: 1,
          cantidad_codigos_generico: 1,
        });
      }
    } else {
      sendErrorToAdd();
    }
  };

  const removeUser = (id, profile) => {
    if (profile === 2) {
      const newRows = teachers.filter((row) => row?.id !== id);
      setTeachers(newRows);
    } else {
      const newRows = students.filter((row) => row?.id !== id);
      setStudents(newRows);
    }
  };

  useEffect(() => {
    axios
      .get(`/listado/escuelas?id_usuario=${user.userId}`)
      .then((response) => {
        if (response.status === 200) {
          const list = response.data.resultado;
          setListOfSchools(list);
        }
      })
      .catch((error) => {
        console.log(error, "error");
      });

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (formData?.id_escuela) {
      axios
        .get(`listado/grado/grupos?id_escuela=${formData?.id_escuela}`)
        .then((response) => {
          if (response.status === 200) {
            setOptionGrades(response?.data?.resultado?.listado);
          }
        })
        .catch((error) => {
          console.log(error, "error");
        });
    }
  }, [formData?.id_escuela]);

  const crearCodigoEspecificoDocente = () => {
    const body = {
      id_tipo_codigo: formData?.tipo_codigo,
      id_tipo_uso: formData?.tipo_uso,
      id_rol: formData?.perfil,
      usuarios: teachers.map((user) => {
        return {
          id_escuela: user.school_id,
          id_grado: user?.grade,
          id_grupo: user?.group_id,
        };
      }),
      cantidad: formData?.cantidad_codigos_genericos,
    };
    axios
      .post("/user/codes", body)
      .then((response) => {
        if (response.status === 200) {
          const data = response.data.resultado;
          handleResponseModalData(data);
        }
      })
      .catch((error) => {
        handleResponseModalData(
          "Ha ocurrido un error al actualizar los datos. Intente de nuevo más tarde.",
          true
        );
      });
  };

  const crearCodigoEspecificoAlumno = () => {
    const body = {
      id_tipo_codigo: formData?.tipo_codigo,
      id_tipo_uso: formData?.tipo_uso,
      id_rol: formData?.perfil,
      usuarios: students.map((user) => {
        return {
          id_escuela: user.school_id,
          id_grado: user?.grade,
          id_grupo: user?.group_id,
          cantidad: Number(user?.cant) || 1,
        };
      }),
    };
    axios
      .post("/user/codes", body)
      .then((response) => {
        if (response.status === 200) {
          const data = response.data.resultado;
          handleResponseModalData(data);
        }
      })
      .catch((error) => {
        handleResponseModalData(
          "Ha ocurrido un error al actualizar los datos. Intente de nuevo más tarde.",
          true
        );
      });
  };
  const handleSubmit = () => {
    if (formData?.perfil === 2) {
      crearCodigoEspecificoDocente();
    } else if (formData?.perfil === 1) {
      crearCodigoEspecificoAlumno();
    }
  };

  return (
    <>
      <label className={classes.label}>Datos para el código</label>
      <SelectTipo
        label="Tipo de uso"
        fieldName={"tipo_uso"}
        list={useTypes}
        formData={formData}
        handleForm={handleForm}
        type="uso"
      />
      <SelectTipo
        label="Perfil"
        fieldName={"perfil"}
        list={profileTypes}
        formData={formData}
        handleForm={handleForm}
        disabled={students.length > 0 || teachers.length > 0}
        type="rol"
      />
      <label className={classes.label}>Datos del usuario</label>
      {formData?.perfil ? (
        <>
          <label className={classes.labelUrl}>
            Si la escuela no está previamente registrada debes &nbsp;
            <Link
              to="/admin/cargar-datos/escuelas/individual"
              className={classes.url}
            >
              darla de alta aquí.
            </Link>
          </label>
          <SelectSchool
            list={listOfSchools}
            label="Institución / Escuela"
            fieldName="id_escuela"
            handleForm={handleForm}
            defaultValue={formData?.id_escuela}
            value={formData?.id_escuela}
            formData={formData}
            disabled={listOfSchools?.length === 0}
          />
          <SelectGradeGroup
            list={optionGrades}
            title="grado"
            handleForm={handleForm}
            defaultValue={formData?.grado}
            value={formData?.grado}
            formData={formData}
            disabled={!formData?.id_escuela}
          />
          <SelectGradeGroup
            list={
              optionGrades.find((item) => item.id_grado === formData.grado)
                ?.grupos
            }
            title="grupo"
            handleForm={handleForm}
            defaultValue={formData?.grupo}
            value={formData?.grupo}
            formData={formData}
            disabled={!formData?.grado}
          />
          {formData?.perfil === 1 && (
            <>
              <label className={classes.label}>Cantidad de códigos</label>
              <input
                type="number"
                className={classes.input}
                name="cantidad_codigos_especificos"
                placeholder="Cantidad de códigos"
                value={formData.cantidad_codigos_especificos}
                onChange={handleForm}
                required
                onWheel={(e) => {
                  e.target.blur();
                }}
              />
            </>
          )}
          {formData?.perfil === 1 && (
            <TableStudent
              rows={students}
              addUser={addUser}
              removeUser={removeUser}
            />
          )}
          {formData?.perfil === 2 && (
            <TableTeacher
              rows={teachers}
              addUser={addUser}
              removeUser={removeUser}
            />
          )}
          <Button className={classes.PurpleButton} onClick={handleSubmit}>
            Crear códigos
          </Button>
        </>
      ) : (
        <>
          <label className={classes.labelUrl}>
            Elige el perfil de usuario para cargar los datos.
          </label>
        </>
      )}
    </>
  );
};

export default CodigoEspecifico;
