import { makeStyles, Grid, Radio } from "@material-ui/core";
import React, { useState, useEffect } from "react";
// import StudentsList from "./StudentsList";
import axios from "../../../../services/axios";
import ContainerAdminPearson from "../ContainerAdminPearson";
import ModalConfirm from "../../../Modals/ModalConfirm";
import ModalError from "../../../Modals/ModalError";
import ModalErrorDetails from "../../../Modals/ModalErrorDetails";
import ModalMessage from "../../../Modals/ModalMessage";
import TeachersAvatarUploader from "../../Docente/TeachersAvatarUploader";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

import { useParams, useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    padding: "48px 0 48px 0",
    width: "100vw",
    minHeight: "calc(100vh - 80px)",
    // marginTop: "80px",
    // minHeight:'calc(100vh - 80px)',
    background:
      "linear-gradient(217deg, rgba(255,194,0,0.4962185557816877) 2%, rgba(255,255,255,1) 25%, rgba(255,255,255,0.4962185557816877) 81%, rgba(144,81,177,1) 98%)",
    [theme.breakpoints.up("sm")]: {
      // marginTop: "0",
    },
  },
  boxContainer: {
    background: "#fff",
    width: "80%",
    height: "auto",
    borderRadius: "42px",
    padding: "20px",
    alignItems: "center",
    gap: "50px",
    boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.5)",
    [theme.breakpoints.up("md")]: {
      padding: "48px 20px 48px 20px",
    },
  },
  titleContainer: {
    margin: "0",
  },
  title: {
    font: "28px Fredoka One ",
    margin: "0",
    textAlign: "center",
    color: " #DC5EA6",
    [theme.breakpoints.up("md")]: {
      fontSize: "36px",
    },
    [theme.breakpoints.up("xl")]: {
      fontSize: "42px",
    },
  },
  link: {
    cursor: "pointer",
    textDecoration: "none",
    // height: "31px",
    // width: "190px",
  },
  backButton: {
    cursor: "pointer",
    height: "35px",
    // width: "200px",
    backgroundColor: "#FDEFF7",
    color: "#DC5EA6",
    border: "#DC5EA6 1px solid",
    borderRadius: "20px",
    textTransform: "none",
    font: "20px Lato",
    letterSpacing: "0.14px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
  },
  backButtonContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.up("sm")]: {
      justifyContent: "flex-start",
    },
  },
  studentListContainer: {
    gap: "20px",
  },
  PurpleButton: {
    color: "#FFFFFF",
    backgroundColor: "#9051b1",
    borderRadius: "30px",
    alignSelf: "center",
    // margin: "0 auto",

    width: "100%",
    maxWidth: "238px",
    minHeight: "45px",
    border: "none",
    textTransform: "unset",
    cursor: "pointer",
    fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
    fontWeight: "500",
    padding: "6px 16px",
    lineHeight: "1.75",
    transition:
      "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    boxShadow:
      "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
    "&:hover": {
      backgroundColor: "#B965D3 ",
    },
  },
  formContainer: {
    // display: "flex",
    display: "grid",
    // flexDirection: "column"
    gridTemplateColumns: "1fr",
    width: "80%",
    boxSizing: "border-box",
    maxWidth: "560px",
    alignItems: "flex-start",
    justifyItems: "center",
    gap: "30px",
    [theme.breakpoints.up("lg")]: {
      width: "100%",
      maxWidth: "680px",
      gridTemplateColumns: "1fr 1fr",
      gridTemplateAreas: `
    "inputName usernameManager"
    "managerName managerLastName"
    " managerEmail radioVip"
    `,
    },
  },
  formItemContainer: {
    gap: "10px",
  },

  inputName: {
    gap: "10px",
    justifySelf: "flex-start",
    [theme.breakpoints.up("lg")]: {
      width: "100%",
      gridArea: "inputName",
    },
  },

  usernameEncargado: {
    gap: "10px",
    justifySelf: "flex-start",
    [theme.breakpoints.up("lg")]: {
      width: "100%",
      gridArea: "usernameManager",
    },
  },
  nombreEncargado: {
    gap: "10px",
    justifySelf: "flex-start",
    [theme.breakpoints.up("lg")]: {
      gridArea: "managerName",
    },
  },
  apellidoEncargado: {
    gap: "10px",
    justifySelf: "flex-start",
    [theme.breakpoints.up("lg")]: {
      gridArea: "managerLastName",
    },
  },

  schoolAddress: {
    gap: "10px",
    justifySelf: "flex-start",
    [theme.breakpoints.up("lg")]: {
      gridArea: "schoolAddress",
    },
  },
  vip: {
    gap: "10px",
    justifySelf: "flex-start",
    [theme.breakpoints.up("lg")]: {
      gridArea: "radioVip",
    },
  },

  radioVip: {
    "& ::after": {
      width: "15px",
      height: "15px",
      borderRadius: "15px",
      top: "-2px",
      left: "-1px",
      position: "relative",
      backgroundColor: "#d1d3d1",
      content: "",
      display: "inline-block",
      visibility: "visible",
      border: "2px solid white",
    },
    "&:checked:after": {
      width: "15px",
      height: "15px",
      borderRadius: "15px",
      top: "-2px",
      left: "-1px",
      position: "relative",
      backgroundColor: "#00ff00",
      content: `" "`,
      display: "inline-block",
      visibility: "visible",
      border: "2px solid white",
    },
  },
  managerEmail: {
    gap: "10px",
    justifySelf: "flex-start",
    [theme.breakpoints.up("lg")]: {
      gridArea: "managerEmail",
    },
  },
  input: {
    width: "100%",
    height: "40px",
    paddingLeft: "27px",
    paddingRight: "27px",
    borderRadius: "61px",
    boxSizing: "border-box",
    // border: " solid #1D2AAD 3px ",
    border: "none",
    background: "#FFFFFF",
    font: "Helvetica",
    fontSize: "16px",
    //    width: "449px",
    // height: "72px",
    // margin: '43px 139px 19px 66px',
    // padding: '26px 24px 25px',
    backgroundColor: "#f0f0f0",
    "&:focus": {
      outline: "none",
    },
    [theme.breakpoints.up("lg")]: {
      // height: "60px",
      // maxWidth: "449px",
      fontSize: "18px",
    },
  },
  radioContainer: {
    width: "auto",
    alignItems: "center",
  },
  radio: {
    color: "#222222",
    "&.Mui-checked": {
      color: "#5dc137",
    },
    "&:hover": {
      backgroundColor: "#22222210",
    },
    "&.Mui-checked:hover": {
      backgroundColor: "#5dc13720",
    },
  },
  label: {
    fontWeight: "bold",
    fontFamily: "Lato, sans-serif",
  },
  spacer: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
}));

const EditSchool = () => {
  const history = useHistory();
  const classes = useStyles();
  const { id_lti } = useParams();
  const [open, setOpen] = React.useState(false);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [modalMessageOpen, setModalMessageOpen] = useState(false);
  const [modalErrorDetailsOpen, setModalErrorDetailsOpen] = useState(false);
  const [modalErrorOpen, setModalErrorOpen] = useState(true);
  const [confirmChangesResult, setConfirmChangesResult] = useState("");
  const [resultError, setResultError] = useState("");

  const [formData, setFormData] = useState({});

  useEffect(() => {
    axios
      .get(`/lti/get?id_lti=${id_lti}`)
      .then((response) => {
        if (response.status === 200) {
          const data = response.data.resultado.plataforma;
          setFormData({
            ...formData,
            id_lti: data?.id_lti,
            nombre: data?.nombre,
            correo: data?.correo,
            llave: data?.llave,
            secreto: data?.secreto,
            habilitado: data?.habilitado,
            url: data?.url,
          });
        }
      })
      .catch((error) => {
        // console.log(error, "error");
      });
  }, []);

  const handleCloseConfirmationModal = () => {
    setConfirmationModalOpen(!confirmationModalOpen);
  };
  const handleCloseMessageModal = () => {
    setModalMessageOpen(!modalMessageOpen);
    setResultError("");
  };
  const handleCloseErrorModal = () => {
    setModalErrorOpen(!modalErrorOpen);
  };
  const handleCloseErrorDetailsModal = () => {
    setModalErrorOpen(false);
    setModalErrorDetailsOpen(!modalErrorDetailsOpen);
  };

  const confirmChanges = () => {
    setConfirmationModalOpen(false);
    console.log(formData.id_lti);
    console.log(formData.correo);
    console.log(formData.nombre);
    console.log(formData.habilitado);
    console.log(formData.url);
    console.log(formData);

    if (
      formData?.id_lti &&
      formData?.url &&
      formData?.correo &&
      formData?.habilitado !== undefined &&
      formData?.nombre
    ) {
      const body = {
        id_lti: formData?.id_lti,
        url: formData?.url,
        correo: formData?.correo,
        nombre: formData?.nombre,
        habilitado: formData?.habilitado,
      };
      console.log("entramos3", body);

      axios
        .put("/lti/update", body)
        .then((response) => {
          if (response.status === 200) {
            setModalMessageOpen(true);
            setConfirmChangesResult(
              "Los datos se han actualizado correctamente"
            );
            console.log(body);
          }
        })
        .catch((error) => {
          if (error.response) {
            // Request made and server responded
            const errorData = error.response.data;
            const mensaje =
              errorData?.detalles?.msg ||
              "Ha ocurrido un error al consumir los servicios, inténtelo de nuevo más tarde.";
            setConfirmChangesResult(mensaje);
            setModalMessageOpen(true);
          } else {
            const mensaje =
              "Ha ocurrido un error al consumir los servicios, inténtelo de nuevo más tarde.";
            setConfirmChangesResult(mensaje);
            setModalMessageOpen(true);
          }
        });
    } else {
      setModalMessageOpen(true);
      setConfirmChangesResult("Asegúrese de llenar todos los datos");
    }
  };

  const handleForm = (e) => {
    if (e.target.name === "habilitado") {
      const isTrue = e.target.value === "true";
      setFormData({
        ...formData,
        habilitado: isTrue,
      });
    } else {
      setFormData({
        ...formData,
        [e.target.name.toLowerCase()]: e.target.value,
      });
    }
  };

  return (
    <ContainerAdminPearson>
      <Grid
        container
        item
        sm={12}
        alignItems="center"
        justifyContent="center"
        className={classes.mainContainer}
      >
        <Grid
          container
          item
          direction="column"
          className={classes.boxContainer}
        >
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="center"
          >
          <Grid item className={classes.backButtonContainer}>
              <button
                className={classes.backButton}
                onClick={() => history.goBack()}
              >
                <ArrowBackIcon /> Plataformas
              </button>
            </Grid>
            <Grid
              container
              item
              alignItems="center"
              justifyContent="center"
              className={classes.titleContainer}
            >
              <h2 className={classes.title}>Editar plataforma</h2>
            </Grid>
          </Grid>
          <form
            className={classes.formContainer}
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <Grid container direction="column" className={classes.inputName}>
              <label hidden htmlFor="input-id_lti"></label>
              <input
                type="hidden"
                className={classes.input}
                id="input-id_lti"
                name="id_lti"
                value={formData.id_lti}
                onChange={handleForm}
                required
              />
              <label htmlFor="input-plataforma" className={classes.label}>
                URL de plataforma
              </label>
              <input
                type="text"
                className={classes.input}
                id="input-plataforma"
                name="url"
                value={formData.url}
                onChange={handleForm}
                required
              />
            </Grid>

            <Grid
              container
              direction="column"
              className={classes.usernameEncargado}
            >
              <label htmlFor="input-llave-lti" className={classes.label}>
                Llave del consumidor
              </label>
              <input
                type="text"
                className={classes.input}
                id="input-llave-lti"
                name="llave"
                value={formData.llave}
                onChange={handleForm}
                disabled
              />
            </Grid>

            <Grid
              container
              direction="column"
              className={classes.nombreEncargado}
            >
              <label htmlFor="input-secreto-lti" className={classes.label}>
                Secreto compartido
              </label>
              <input
                type="text"
                className={classes.input}
                id="input-secreto-lti"
                name="secreto"
                value={formData.secreto}
                onChange={handleForm}
                disabled
              />
            </Grid>
            <Grid
              container
              direction="column"
              className={classes.apellidoEncargado}
            >
              <label htmlFor="input-nombre-lti" className={classes.label}>
                Nombre
              </label>
              <input
                type="text"
                className={classes.input}
                id="input-nombre-lti"
                name="nombre"
                value={formData.nombre}
                onChange={handleForm}
                required
              />
            </Grid>

            <Grid container direction="column" className={classes.managerEmail}>
              <label htmlFor="input-correo-lti" className={classes.label}>
                Correo electrónico
              </label>
              <input
                type="email"
                className={classes.input}
                id="input-corre-lti"
                name="correo"
                value={formData.correo}
                onChange={handleForm}
                required
              />
            </Grid>

            <Grid container direction="column" className={classes.vip}>
              <label htmlFor="input-address" className={classes.label}>
                Habilitado
              </label>
              <Grid container alignItems="center" direction="row">
                <Grid container className={classes.radioContainer}>
                  <label htmlFor="option-vip-si" className={classes.label}>
                    Si
                  </label>
                  <Radio
                    className={classes.radio}
                    type="radio"
                    id="si"
                    name="habilitado"
                    value={true}
                    checked={formData.habilitado === true}
                    onChange={handleForm}
                  />
                </Grid>
                <Grid container className={classes.radioContainer}>
                  <label htmlFor="option-vip-no" className={classes.label}>
                    No
                  </label>

                  <Radio
                    className={classes.radio}
                    type="radio"
                    id="no"
                    name="habilitado"
                    value={false}
                    checked={formData.habilitado === false}
                    onChange={handleForm}
                  />
                </Grid>
              </Grid>
            </Grid>
          </form>
          <button
            className={classes.PurpleButton}
            onClick={handleCloseConfirmationModal}
          >
            Guardar cambios
          </button>
        </Grid>
      </Grid>
      <ModalConfirm
        open={confirmationModalOpen}
        handleClose={handleCloseConfirmationModal}
        handleConfirm={confirmChanges}
      />

      <ModalError
        open={modalErrorOpen}
        handleClose={handleCloseErrorModal}
        type={resultError}
        handleNext={handleCloseErrorDetailsModal}
      />
      <ModalErrorDetails
        open={modalErrorDetailsOpen}
        handleClose={handleCloseErrorDetailsModal}
      />
      <ModalMessage
        open={modalMessageOpen}
        handleClose={handleCloseMessageModal}
        title={confirmChangesResult}
      />
      <TeachersAvatarUploader open={open} setOpen={setOpen} />
    </ContainerAdminPearson>
  );
};

export default EditSchool;
