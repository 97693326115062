import React from "react";
import OtherPersonMessages from "./Chat_Other_Person_Message";
import OwnMessage from "./Chat_Own_Message";
import AuthContext from "../../../auth/AuthContext";
const MessageIndicator = ({ message, imagen_perfil }) => {
  const { user } = React.useContext(AuthContext);
  return (
    <>
      {message.id_usuario_origen === user.userId ? (
        <OwnMessage
          message={message || message}
          id={message.id_mensaje}
          timestamp={Number(message.timestamp) || Number(message.fecha)}
          key={`${message.id_mensaje || message?.fecha}`}
          type={message?.tipo}
          mediaData={message?.mediaData}
        />
      ) : (
        <OtherPersonMessages
          message={message}
          image={imagen_perfil}
          id={message.id_mensaje}
          timestamp={Number(message.timestamp) || Number(message.fecha)}
          key={`${message.id_mensaje || message?.fecha}`}
          type={message?.tipo}
          mediaData={message?.mediaData}
        />
      )}
    </>
  );
};

export default MessageIndicator;
