import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
    ContenedorLectura:{
        width:'502px',
        [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
    },
    ContainerTitulo:{
        margin:'0 0 46px 0',
        '& .Titulo':{
            letterSpacing: '0px',
            color: '#FF0000',
            margin:'0',
            textAlign:'center',
            font:'43px fredoka one',
            lineHeight:'52px',
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        position:'relative',
        margin:' 0 0 36px 0',
        '& .Lectura':{
            margin:'0 0 36px 0 ',
            color:'#000',
            lineHeight :'28px',
            font:'18px lato',
            letterSpacing: '0.17px',
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .Lectura2':{
            margin:' 0 0 4px 0 ',
        },
        '& .Lectura3':{
            margin:' 0',
        },
        '& .TextImg':{
            margin:'22px 0 0 0',
            alignSelf:'flex-start',
            color:'#717476',
            font:' 16px lato',
            letterSpacing:'.12px',
            [theme.breakpoints.down(502)]:{
                font:' 11px lato',
                lineHeight :'14px',
                letterSpacing: '0.10px',
            },
        },
        '& .Ul':{
            margin:'0',
            padding:' 0 0 0 20px',
            color:'#FF0000',
            listStyle:'lower-latin',
            font:' bold 18px lato',
            letterSpacing:'.17px',
        },
        '& .Ul2':{
            margin:'0',
            padding:' 0 0 0 20px',
            color:'#F6644B',
            listStyle:'alpha',
            font:' bold 18px lato',
            letterSpacing:'.17px',
        },

        '& .ContainerCuadro':{
            position:'relative',
        },
        '& .Esp':{
            position:'absolute',
            margin:'35px 0 0 0 ',
            width:'85%',
            [theme.breakpoints.down(502)]:{
                margin:'20px 0 0 0',
            },
        },
 
        '& .Imagen':{
            width:'100%',
        },
        '& .Imagen2':{
            [theme.breakpoints.down(502)]:{
                width:'100%',
                height:'105%',
            },
        },

    },

}));

const El_equilibrio_de_Nash = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >
                        El equilibrio de Nash
                    </h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                            Piensa en la cantidad de juegos con los que te has divertido desde pequeño hasta 
                            esta etapa de tu vida o incluso los que aún juegan los adultos. Aunque la mayoría 
                            parecen actividades sencillas, la realidad es que en muchos intervienen las 
                            matemáticas más de lo que podrías imaginar: ya sea en la manera en que fueron 
                            creados o en las estrategias necesarias para ganar. <br/><br/>
                            
                            Dos ejemplos de estos juegos son los siguientes. Uno, el lanzamiento de dados, en el 
                            que hay que calcular las probabilidades para que caiga algún resultado. Otro, las 
                            Torres de Hanói, que debes resolver en el menor número posible de pasos. Si nunca 
                            lo has intentado o no lo recuerdas, el juego consiste en pasar todos los discos 
                            apilados en un poste a cualquier otro, siguiendo tres reglas:<br/><br/>

                            <ul className='Ul'>
                                <li ><p className='Lectura Lectura2'>Mover un disco a la vez.</p></li>
                                <li ><p className='Lectura Lectura2'>Sólo se puede mover el disco superior.</p></li>
                                <li ><p className='Lectura Lectura2'>Un disco de mayor tamaño no puede estar encima de uno más pequeño.</p></li>
                            </ul>
                        </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <Grid container justifyContent="center" alignItems='flex-end'>
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion10/1.png" alt="Tarjeta" />
                        </Grid>
                        <Grid container justifyContent='center'>
                            <p className='TextImg'> Juego común de las Torres de Hanói.</p>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                            <p className="Lectura ">
                                Aunque podría parecer simple, la explicación para resolverlo en el menor número de 
                                pasos posibles está basada en cálculos matemáticos. <br/><br/>
                                
                                Debido a estos y otros casos es que en las décadas de los cuarenta y cincuenta del 
                                siglo pasado, surgió una rama de las matemáticas conocida como Teoría de Juegos. 
                                Los pioneros fueron los matemáticos John Von Neumann, John Nash y el economista 
                                Oskar Morgenstern. A continuación, lee la definición matemática de juego:
                            </p>
                    </Grid>
                    <Grid container justifyContent='center' className='ContainerCuadro'>
                        <img className="Imagen2" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion10/2R.png" alt="Tarjeta" />
                        <p className='Lectura Esp'>
                            Toda aquella situación en la que dos o más personas interactúan, donde las decisiones de cada jugador son resultado de lo que espera que los otros hagan.
                        </p>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <p className="Lectura ">
                        Para explicarlo mejor, toma como ejemplo el juego “piedra, papel o tijeras”: tienes 
                        únicamente tres opciones para elegir, las cuales se basan en lo que esperas que el 
                        otro jugador decida. Es decir, cuando tú eliges piedra es porque esperas que la otra 
                        persona escoja tijeras.<br/><br/>
                        
                        El caso anterior se clasifica como <em>juego no cooperativo</em>, pues cada jugador toma sus 
                        decisiones de forma independiente, aunque con conocimiento de las estrategias que 
                        puede utilizar el otro. Para analizar esta subdivisión de los juegos, a continuación 
                        leerás “El dilema del prisionero”, un problema que se puede explicar a partir de las
                        matemáticas. <br/><br/>
                        
                        Imagina que dos sospechosos de un delito son detenidos e interrogados, pero no hay 
                        pruebas suficientes para castigarlos. Para presionarlos a que confiesen, la policía ha 
                        ideado un plan que incluye interrogarlos por separado, presentándoles los siguientes 
                        escenarios, sin la posibilidad de que se pongan de acuerdo:<br/><br/>
                        
                        <ul className='Ul2'>
                            <li ><p className='Lectura Lectura2'>Si ninguno confiesa, ambos irán a prisión durante dos años.</p></li>
                            <li ><p className='Lectura Lectura2'>Si uno confiesa en contra del otro, entonces uno quedará libre y a su compañero le 
                            tocará una condena de diez años.</p></li>
                            <li ><p className='Lectura Lectura2'>Si ambos se acusan mutuamente, los dos irán a prisión por seis años.</p></li>
                        </ul><br/>

                        En este juego, la estrategia ganadora es que ambos cooperen entre sí; es decir, que 
                        no confiesen, pues así recibirían el menor castigo. Sin embargo, esto no es posible, 
                        pues se trata de un <em>juego no cooperativo</em> en el que cada uno toma sus decisiones de 
                        manera independiente.<br/><br/>
                        
                        Lo anterior sucede porque cada uno tiene el riesgo de que si no confiesa contra su 
                        compañero, pero el otro decide traicionarlo, entonces pasará diez años en prisión. 
                        ¡Es mucho riesgo! En cambio, si toma la decisión de delatarlo tiene dos ventajas. La 
                        primera es que puede salir libre si el otro decide no acusarlo. La segunda es que si su 
                        compañero también lo delata la pena se reduce de diez a seis años. Al final, los dos 
                        terminarán delatándose. <br/><br/>
                        
                        A esta toma de decisiones y el resultado que generan se le conoce en la Teoría de 
                        Juegos como el Equilibro de Nash. <br/><br/>
                        
                        Esta explicación matemática se puede aplicar a muchas otras situaciones. Por 
                        ejemplo, en las decisiones tomadas durante la pandemia. El confinamiento es un caso 
                        similar: el no salir de casa es una afectación menor, por muy aburrido que pueda 
                        parecer, en comparación con que todos estén en la calle y se genere una mayor 
                        propagación del virus. Para profundizar en este asunto, toma en cuenta la siguiente 
                        infografía.
                    </p>
                    <Grid container item justifyContent="center" >
                        <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion10/3R.png" alt="Tarjeta" />
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <p className="Lectura ">
                        Los datos indican que el mejor escenario es que si dos o más personas utilizan el 
                        cubrebocas y mantiene sana distancia, no hay posibilidad de que se contagien. En 
                        cambio, el peor escenario es donde ninguno coopera, entonces existe una posibilidad 
                        del 90 % de que las personas se infecten.
                    </p>
                </Grid>
                
        </Grid>
    </Grid>
    );
}
 
export default El_equilibrio_de_Nash;
