import { Button, makeStyles, Grid } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";

import Corazoni from "../../imgs/Corazoni.png";
import Estrellai from "../../imgs/Estrellai.png";
import Coronai from "../../imgs/Coronai.png";
import ProgresChart from "../../Charts/ProgresChart";

import { Link, Redirect } from "react-router-dom";

import Corazon from "../../pages/random/cora";
import Star from "../../pages/random/est";
import Corona from "../../pages/random/cor";

import AuthContext from "../../../auth/AuthContext";
import axios from "../../../services/axios";
import types from "../../../types/types";
import LecturaButton from "../../LecturaButton/LeccturaButton";
import { useLocation } from "react-router-dom";
import Loader from "../../Loader/Loader";
import ContenedorAlumno from "./ContenedorAlumno";
import { studentDataNative } from "../../models/login";

const useStyles = makeStyles((theme) => ({
  ContainerCorazon: {
    borderLeft: "2px solid #F3CD52",
    borderRight: "2px solid #F3CD52",
  },
  PerfilContainer: {
    // height: "92vh",
    width: "100%",
    marginTop: "80px",
    maxHeight: "calc(100vh - 80px)",

    [theme.breakpoints.up("sm")]: {
      marginTop: "0",
    },
    [theme.breakpoints.up("lg")]: {
      // flexDirection: "column",
      // alignContent: "center",
    },
  },
  ContainerIzquierda: {
    background: "#F3CD52",
    width: "100%",
    padding: "30px 0 ",
    // paddingTop:'80px',
    [theme.breakpoints.up("lg")]: {
      "@media(min-height:1080px)": {
        padding: "20px",
      },
    },
    [theme.breakpoints.up("xl")]: {
      alignContent: "center",
      gap: "10%",
      "@media(min-height:1080px)": {
        padding: "0px",
      },
    },
  },
  CaontainerInfoAlumno: {
    position: "relative",
    boxSizing: "border-box",
    padding: "0 15px 28.5px 19px",
    margin: "0 0 23px 0",
    // height: "422px",
    gap: "20px",
    width: "80%",
    maxWidth: "376px",
    background: " #FFFFFF 0% 0% no-repeat padding-box",
    borderRadius: "200px 200px 25px 25px",
    boxShadow: "0px 2px 5px #00000029",
    border: "3px solid #FBE6B9",
    opacity: "1",
    [theme.breakpoints.up("xl")]: {
      maxWidth: "480px",
    },
    "& .Avatar": {
      // position: "absolute",
      // bottom: "220px",
      paddingTop: "30px",
      height: "210px",
      [theme.breakpoints.up("xl")]: {
        "@media(min-height:1080px)": {
          paddingTop: "40px",
          height: "320px",
        },
      },
    },
  },
  Link: {
    margin: "0",
    textDecoration: "none",
    width: "100%",
    maxWidth: "238px",
  },
  BotonCambiarAvatar: {
    textTransform: "none",
    // height: "62px",
    maxWidth: "238px",
    width: "100%",
    background: "#84E9DF 0% 0% no-repeat padding-box;",
    boxShadow: "0px 3px 6px #00000029",
    borderRadius: "61px",
    color: "#000000",
    font: "bold 20px lato ",
    "&:hover": {
      background: "#84E9DF80",
    },
  },
  ContainerNombreAlumno: {
    // position: "absolute",
    bottom: "180px",
    padding: "0 10px 0 10px",
    textTransform: "capitalize",

    "& .Nombre": {
      margin: "0 ",
      textAlign: "center",
      height: "auto",
      width: "390px",
      letterSpacing: ".15px",
      color: "#353535",
      font: "20px Fredoka One",
      [theme.breakpoints.up("xl")]: {
        fontSize: "28px",
      },
    },
    "& .Apellido": {
      margin: "0 5px 0 0",
      textAlign: "center",
      height: "auto",
      width: "322px",
      letterSpacing: ".15px",
      color: "#353535",
      font: "20px Fredoka One",
      [theme.breakpoints.up("xl")]: {
        fontSize: "28px",
      },
    },
  },

  ContainerRegion: {
    // position: "absolute",
    bottom: "30px",
    // height: "140px",
    width: "100%",
    font: "20px lato",
    color: "#000000",
    [theme.breakpoints.up("md")]: {
      fontSize: "22px",
    },
    [theme.breakpoints.up("xl")]: {
      fontSize: "28px",
    },
    "& .Pais": {
      margin: "0",
      letterSpacing: "0.30px",
    },
    "& .Ciudad": {
      margin: "0",
      letterSpacing: "0.30px",
    },
    "& .Escuela": {
      margin: "0",
      letterSpacing: "0.30px",
    },
    "& .Grado": {
      margin: "0",
      letterSpacing: "0.30px",
    },
    "& .Grupo": {
      margin: "0",
      letterSpacing: "0.30px",
    },
  },

  ContainerInsignias: {
    boxSizing: "border-box",
    padding: "15px 0",
    width: "80%",
    maxWidth: "523px",
    // height: "205px",
    gap: "20px",
    minHeight: "160px",
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    boxShadow: "0px 2px 5px #00000029",
    border: "3px solid #FBE6B9",
    borderRadius: "20px",
    [theme.breakpoints.up("sm")]: {
      gap: "0px",
    },
    [theme.breakpoints.up("xl")]: {
      maxWidth: "860px",
    },
  },

  Insignia: {
    height: "75px",
    width: "75px",
    [theme.breakpoints.up("xl")]: {
      "@media(min-height:1080px)": {
        height: "100px",
        width: "100px",
      },
    },
  },

  Progreso: { display: "flex", justifyContent: "center" },

  ProgresoTexto: { margin: "0px", font: " 600 22px Lato;" },

  ProgresoTextoEstrella: { color: "#9051B1" },

  ProgresoTextoCorazon: { color: "#F6644B" },

  ProgresoTextoCorona: { color: "#177DB0" },

  ContainerDerecha: {
    // position: "relative",
    padding: "35px 0 ",
    background:
      "linear-gradient(32deg, rgba(185,101,211,0.2105042700674019) 1%, rgba(255,255,255,1) 20%, rgba(255,255,255,1) 36%, rgba(254,254,254,1) 77%, rgba(252,227,80,0.2777311608237045) 100%)",
    [theme.breakpoints.up("md")]: {
      padding: "20px 0",
      "@media(min-height:2024px)": {
        alignContent: "center",
        gap: "25%",
      },
    },
  },
  ContainerAvance: {
    // position: "absolute",
    // height: "41px",
    alignContent: "center",
  },
  Avance: {
    top: "35px",
    "& .Avance": {
      textAlign: "center",
      margin: "0",
      font: "34px Fredoka One ",
      color: "#F27964",
      [theme.breakpoints.up("xl")]: {
        fontSize: "50px",
      },
    },
  },
  ContainerTextoAvance: {
    // position: "absolute",
    top: "91px",
    // height: "55px",
    "& .TextoAvance": {
      textAlign: "center",
      margin: "0",
      font: "22px lato ",
      minHeight: "80px",
      padding: "0 20px",
      color: "#707070",
      [theme.breakpoints.up("xl")]: {
        fontSize: "36px",
      },
    },
  },
  ContainerChart: {
    position: "relative",
    width: "100%",
    // top: "110px",
    marginTop: "50px",
    // height: "468px",
    "& .TextProgres": {
      font: "bold 22px lato",
      letterSpacing: "0.21px",
      color: "#707070",
      margin: "0px",
    },
    "& .ProgresoAcumulado": {
      font: "52px Fredoka One",
      letterSpacing: "0.38px",
      color: "#1DA698",
      margin: "0px",
    },
    "& .ProgresDiv": {
      font: "52px Fredoka One",
      letterSpacing: "0.38px",
      color: "#5D6E79",
      margin: "0px",
    },
    "& .ProgresoFaltante": {
      font: "52px Fredoka One",
      letterSpacing: "0.38px",
      color: "#F57C67",
      margin: "0px",
    },

    "& .ContainerBarChart": {
      position: "relative",
      width: "100%",
      // height: "10px",
    },
    "& .Chart": {
      // position: "relative",
      position: "absolute",
      width: "100%",
      height: "100%",
      // top: "-250px",
      bottom: "85px",
      // overflow: "hidden",
      // overflow: "auto",
      // bottom: "50px",
      // top: "110px",

      [theme.breakpoints.up("md")]: {
        // top: "80px",
        // "@media(min-height:600px)": {
        //   // top: "12%",
        // },
      },
    },
    "& .Llevas": {
      // position: "absolute",
      // bottom: "260px",
    },
  },
  ContainerBoton: {
    // position: "absolute",
    bottom: "100px",
    textDecoration: "none",
  },
  BotonSalaDeLectura: {
    textTransform: "none",
    height: "62px",
    width: "238px",
    background: "#B965D3 0% 0% no-repeat padding-box;",
    boxShadow: "0px 3px 6px #00000029",
    borderRadius: "61px",
    color: "#FFFFFF",
    font: "bold 20px lato ",
    "&:hover": {
      background: "#dd78fb",
    },
  },
  ContainerTop: {
    height: "auto",
    marginTop: "50px",
    alignItems: "center",
    alignContent: "center",
    [theme.breakpoints.up("md")]: {
      marginTop: "0",
    },
  },

  Divisor: {
    alignSelf: "center",
    background: "#F3CD52",
    height: "126px",
    // border: '1px solid var(--unnamed-color-f3cd52)',
    border: "1px solid #F3CD52",
  },

  Diagonal: {
    fontSize: "22px",
    margin: "0px",
    color: "#707070",
  },
  ContainerLinkinsiginas: {
    width: "100%",
    display: "flex",
    padding: "0 20px",
    justifyContent: "center",
    [theme.breakpoints.up("md")]: {
      justifyContent: "flex-end",
    },
  },
  Linkinsiginas: {
    textDecoration: "none",
    margin: 0,
    [theme.breakpoints.up("md")]: {
      justifyContent: "flex-end",
    },
    "& .ButtonIsg": {
      color: "#F6644B",
      textTransform: "none",
      font: "14px lato",
      // width: "102px",
      margin: 0,
      padding: "5px 10px",
      // height: "21px",
      background: "#FEFAF1 0% 0% no-repeat padding-box",
      boxShadow: "0px 1px 2px #00000029",
      border: "1px solid #F2624F",
      borderRadius: "21px",
    },
  },

  ContainerTop10: {
    // height: "41px",
    margin: "30px 0",
    alignItems: "center",
  },
  Top10: {
    // position: "relative",
    // top: "55px",
    paddingRight: "1%",
    margin: "0px",
    font: "20px Fredoka One",
    letterSpacing: ".025px",
    color: "#4CAFA3",
    [theme.breakpoints.up("sm")]: {
      fontSize: "28px",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "34px",
    },
  },
  Top102: {
    // position: "relative",
    // top: "55px",
    margin: "0px",
    font: "20px Fredoka One",
    letterSpacing: ".025px",
    color: "#8E69C1",
    [theme.breakpoints.up("sm")]: {
      top: "45px",
      fontSize: "28px",
    },
    [theme.breakpoints.up("lg")]: {
      top: "45px",
      fontSize: "34px",
    },
  },
  ContainerStars: {
    // position: "absolute",
    // bottom: "35px",
    boxSizing: "border-box",
    width: "80%",
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    gridTemplateRows: "repeat(5, 1fr)",
    gap: "10px",
    [theme.breakpoints.up("sm")]: {
      gridTemplateColumns: "repeat(5, 1fr)",
      gridTemplateRows: "repeat(2, 1fr)",
      // height: "128px",
      height: "180px",
      gap: "10px",
      width: "75%",
      "@media(min-height:1024px)": {
        height: "200px",
      },
      "@media(min-height:2024px)": {
        height: "300px",
      },
    },
  },

  Favoritos: {
    position: "relative",
    // height: "83px",
    // width: "93px",
    boxSizing: "border-box",
    height: "44%",
    width: "100%",
    // width: "18%",
    margin: "1% 1%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    background: "#FFFFFF",
    boxShadow: "0px 2px 5px #00000029",
    border: "2px solid #2E313C1A",
    borderRadius: "15px",
    gap: "5%",
    "& .EstrellaFavorito": {
      // position: "absolute",
      // bottom: "40px",
      // height: "38px",
      width: "60%",
      // width: "40px",
    },
    "& .LecturaFavorita": {
      // position: "absolute",
      // bottom: "13px",
      // font: "700 12px lato",
      // margin: "0",
      overflow: "hidden",
      // position: "absolute",
      // bottom: "0px",
      font: "700 12px lato",
      margin: "0",
      maxWidth: "90%",
      // height: "40px",
      textAlign: "center",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
    },
  },
}));

const PerfilNative = () => {
  const classes = useStyles();
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(false);

  const location = useLocation();
  const {
    user: {
      name,
      firstLastName,
      secondLastName,
      grade,
      group,
      country,
      city,
      school,
      favoriteList,
      avatar,
      logged,
      totalActividadesCompletas,
    },
    dispatch,
  } = useContext(AuthContext);

  useEffect(() => {
    const params = location?.search;
    const urlParams = new URLSearchParams(params);
    const x = urlParams.get("x");
    const y = urlParams.get("y");
    const userDataByParams = {
      username: x,
      password: y,
    };
    if (userDataByParams.username && userDataByParams.password)
      setUserData(userDataByParams);

    return () => {};
  }, [location.search]);

  const login = () => {
    const body = {
      nombreUsuario: userData?.username,
      contraseña: userData?.password,
    };
    axios
      .post("/session/login", body)
      .then((response) => {
        if (response.status === 200) {
          localStorage.setItem("token", response.data.resultado.token_usuario);
          if (response.data.resultado.usuario.rol === "alumno") {
            const data = response.data.resultado;
            dispatch({
              type: types.login,
              payload: studentDataNative(data),
            });
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (userData && !logged) {
      setLoading(true);
      login();
    }
    return () => {};
  }, [userData, logged]);

  const loadFavoriteList = () => {
    if (userData) {
      axios
        .get(`/user/lectura/favorita/get?id_lectura=1`)
        .then((response) => {
          if (response.status === 200) {
            // localStorage.setItem("token", response.data.resultado.token_usuario);
            const favorites = response.data.resultado.resp;
            // console.log(favorites);
            dispatch({
              type: types.loadReadings,
              payload: favorites,
            });
          }
        })
        .catch((error) => {
          dispatch({
            type: types.loadReadings,
            payload: [],
          });
        });
      setLoading(false);
    }
  };
  useEffect(() => {
    loadFavoriteList();
  }, [logged]);

  return (
    <>
      {logged ? (
        <ContenedorAlumno>
          <Grid container item sm={12} className={classes.PerfilContainer}>
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              md={12}
              lg={6}
              className={classes.ContainerIzquierda}
            >
              <Grid container justifyContent="center" sm={12}>
                <Grid
                  container
                  item
                  justifyContent="center"
                  className={classes.CaontainerInfoAlumno}
                >
                  <Grid
                    container
                    item
                    justifyContent="center"
                    alignItems="flex-end"
                  >
                    <img
                      className="Avatar"
                      src={avatar?.path}
                      alt={avatar?.nombre}
                    />
                  </Grid>
                  <Grid
                    container
                    item
                    justifyContent="center"
                    alignItems="flex-end"
                  >
                    <Link to="/avatars" className={classes.Link}>
                      <Button className={classes.BotonCambiarAvatar}>
                        Cambiar avatar
                      </Button>
                    </Link>
                  </Grid>
                  <Grid
                    container
                    item
                    justifyContent="center"
                    className={classes.ContainerNombreAlumno}
                  >
                    <p className="Nombre">{name} </p>
                    <p className="Apellido">
                      <b className="Apellido">{firstLastName} </b>
                      <b className="Apellido">{secondLastName} </b>
                    </p>
                  </Grid>

                  <Grid
                    container
                    item
                    direction="column"
                    justifyContent="center"
                    className={classes.ContainerRegion}
                  >
                    <p className="Grado">
                      <b>Grado:</b> {grade}
                    </p>
                    <p className="Grado">
                      <b>Grupo:</b> {group}
                    </p>
                    <p className="Pais">
                      <b>País:</b> {country}
                    </p>
                    <p className="Ciudad">
                      <b>Ciudad:</b> {city}
                    </p>
                    <p className="Escuela">
                      <b>Colegio:</b> {school}
                    </p>
                  </Grid>
                </Grid>
              </Grid>

              <Grid container justifyContent="center" sm={12}>
                <Grid
                  container
                  item
                  justifyContent="center"
                  className={classes.ContainerInsignias}
                >
                  <Grid
                    container
                    item
                    sm={4}
                    justifyContent="center"
                    alignItems="center"
                  >
                    <img
                      className={classes.Insignia}
                      src={Estrellai}
                      alt="Star"
                    />
                    <Grid container item className={classes.Progreso}>
                      <p
                        className={`${classes.ProgresoTexto} ${classes.ProgresoTextoEstrella}`}
                      >
                        <Star />
                      </p>
                      <p className={classes.Diagonal}>/</p>
                      <p
                        className={`${classes.ProgresoTexto} ${classes.ProgresoTextoEstrella}`}
                      >
                        424
                      </p>
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    item
                    sm={4}
                    justifyContent="center"
                    alignItems="center"
                    className={classes.ContainerCorazon}
                  >
                    <img
                      className={classes.Insignia}
                      src={Corazoni}
                      alt="Heart"
                    />
                    <Grid container item className={classes.Progreso}>
                      <p
                        className={`${classes.ProgresoTexto} ${classes.ProgresoTextoCorazon}`}
                      >
                        <Corazon />
                      </p>
                      <p className={classes.Diagonal}>/</p>
                      <p
                        className={`${classes.ProgresoTexto} ${classes.ProgresoTextoCorazon}`}
                      >
                        106
                      </p>
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    item
                    sm={4}
                    justifyContent="center"
                    alignItems="center"
                  >
                    <img
                      className={classes.Insignia}
                      src={Coronai}
                      alt="crown"
                    />
                    <Grid container item className={classes.Progreso}>
                      <p
                        className={`${classes.ProgresoTexto} ${classes.ProgresoTextoCorona}`}
                      >
                        {" "}
                        <Corona />
                      </p>
                      <p className={classes.Diagonal}>/</p>
                      <p
                        className={`${classes.ProgresoTexto} ${classes.ProgresoTextoCorona}`}
                      >
                        36
                      </p>
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    item={12}
                    justifyContent="flex-end"
                    alignItems="center"
                    className={classes.ContainerLinkinsiginas}
                  >
                    <Link className={classes.Linkinsiginas} to="/insignias">
                      <Button className="ButtonIsg">Ver insignias</Button>
                    </Link>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              container
              justifyContent="center"
              md={12}
              lg={6}
              className={classes.ContainerDerecha}
            >
              <Grid
                container
                justifyContent="center"
                item
                sm={12}
                className={classes.ContainerAvance}
              >
                <Grid
                  container
                  justifyContent="center"
                  item
                  sm={12}
                  className={classes.Avance}
                >
                  <p className="Avance">Tu avance</p>
                </Grid>

                <Grid
                  container
                  justifyContent="center"
                  item
                  sm={12}
                  className={classes.ContainerTextoAvance}
                >
                  <p className="TextoAvance">
                    Completa las actividades, mejora tus estadísticas <br />y
                    continúa leyendo.
                  </p>
                </Grid>

                <Grid
                  container
                  justifyContent="center"
                  item
                  sm={12}
                  className={classes.ContainerChart}
                >
                  <Grid className="ContainerBarChart">
                    <Grid
                      container
                      justifyContent="center"
                      sm={12}
                      className="Chart"
                    >
                      <ProgresChart num={totalActividadesCompletas} />
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    container
                    direcion="column"
                    justifyContent="center"
                    sm={12}
                    className="Llevas"
                  >
                    <Grid item container sm={12} justifyContent="center">
                      {" "}
                      <p className="TextProgres">Llevas</p>
                    </Grid>
                    <Grid item container sm={12} justifyContent="center">
                      {" "}
                      <p>
                        <b className="ProgresoAcumulado">
                          {totalActividadesCompletas}
                        </b>
                        <b className="ProgresDiv">/</b>
                        <b className="ProgresoFaltante">60</b>
                      </p>
                    </Grid>
                    <Grid item container sm={12} justifyContent="center">
                      {" "}
                      <p className="TextProgres">actividades</p>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                item
                container
                direcion="column"
                justifyContent="center"
                sm={12}
                className={classes.ContainerTop}
              >
                <Link
                  className={classes.ContainerBoton}
                  to="/Biblioteca"
                  href="#"
                >
                  <Button className={classes.BotonSalaDeLectura}>
                    Biblioteca virtual
                  </Button>
                </Link>
                <Grid
                  container
                  direcion="column"
                  justifyContent="center"
                  sm={12}
                  className={classes.ContainerTop10}
                >
                  <p className={classes.Top10}>Top 10</p>
                  <p className={classes.Top102}>de lecturas favoritas</p>
                </Grid>

                <Grid
                  container
                  item
                  justifyContent="center"
                  className={classes.ContainerStars}
                >
                  {favoriteList?.map((lectura) => (
                    <LecturaButton
                      key={lectura.id_lectura}
                      id_lectura={lectura.id_lectura}
                      nombre={lectura.nombre}
                    />
                  ))}
                  {favoriteList?.length === 0 && (
                    <LecturaButton nombre="Sin asignar" />
                  )}
                  {favoriteList?.length <= 1 && (
                    <LecturaButton nombre="Sin asignar" />
                  )}
                  {favoriteList?.length <= 2 && (
                    <LecturaButton nombre="Sin asignar" />
                  )}
                  {favoriteList?.length <= 3 && (
                    <LecturaButton nombre="Sin asignar" />
                  )}
                  {favoriteList?.length <= 4 && (
                    <LecturaButton nombre="Sin asignar" />
                  )}
                  {favoriteList?.length <= 5 && (
                    <LecturaButton nombre="Sin asignar" />
                  )}
                  {favoriteList?.length <= 6 && (
                    <LecturaButton nombre="Sin asignar" />
                  )}
                  {favoriteList?.length <= 7 && (
                    <LecturaButton nombre="Sin asignar" />
                  )}
                  {favoriteList?.length <= 8 && (
                    <LecturaButton nombre="Sin asignar" />
                  )}
                  {favoriteList?.length <= 9 && (
                    <LecturaButton nombre="Sin asignar" />
                  )}
                </Grid>
              </Grid>
            </Grid>
            {loading && <Loader />}
            {logged && !avatar?.path && <Redirect to={"/avatars"} />}
          </Grid>
        </ContenedorAlumno>
      ) : (
        <>
          <Loader />
        </>
      )}
    </>
  );
};

export default PerfilNative;
