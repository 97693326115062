import { Button, Grid, makeStyles } from "@material-ui/core";
import React, { useContext } from "react";
import DrawerContext from "../../../../../usability/DrawerContext";
import Drawer from "../../ReadingTools/Drawer";

const useStyles = makeStyles((theme) => ({
  Contenedor: {
    background: "#E9FBF9",
    width: "100vw",
    [theme.breakpoints.down(1161)]: {
      background: "none",
    },
    [theme.breakpoints.down(600)]: {
      marginTop: "79.51px",
    },
  },
  ContainerInfoLectura: {
    position: "relative",
    height: "86px",
    [theme.breakpoints.down(502)]:{
        height: "43px",
    },
    background: "#76D4C3",
    "& .BarraInfoLectura": {
      height: "86px",
      background: `Url(https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Barras/Azul.png)`,
        [theme.breakpoints.down(502)]:{
            height: "43px",
        },
    },
    "& .InfoLectura": {
      position: "absolute",
      height: "86px",
      width: "219px",
      boxSizing: "border-box",
      padding: "17.2px 0",
      background: "#FAFAFA",
      borderRadius: "50px",
      boxShadow: "2px 2px 2px 2px #00000029",
      zIndex: "4",
        [theme.breakpoints.down(502)]:{
            height: "43px",
            width: "109px",
            padding: "8.6px 0",
            borderRadius: "25px",
        },
    },
    "& .InfoLectura:before": {
      position: "absolute",
      display: "flex",
      boxSizing: "border-box",
      padding: "0 0 0 10px",
      justifyContent: "center",
      alignItems: "center",
      height: "39.88px",
      width: "39.88px",
      borderRadius: "50%",
      content: '""',
      boxShadow: "3px 0px .5px 0px #00000029",
      background: "#FAFAFA",
      left: "90px",
      top: "65px",
      transform: "rotate(0.25turn)",
      [theme.breakpoints.down(502)]:{
        left: "45px",
        top: "30px",
        height: "20.88px",
        width: "20.88px",
        },
    },
    "& .InfoLectura:after": {
      content: '""',
      height: "15px",
      width: "15px",
      borderTop: "2px solid #20A698",
      borderLeft: "2px solid #20A698",
      position: "absolute",
      bottom: "-10px",
      left: "Calc(50% - 8px)",
      transform: "rotate(0.625turn)",
      [theme.breakpoints.down(502)]:{
        height: "10px",
        width: "10px",
        left: "Calc(50% - 4.5px)",
        bottom: "-3px",
        borderTop: "1px solid #20A698",
        borderLeft: "1px solid #20A698",
        },
    },
    "& .Sesion": {
      borderLeft: "2px solid #D8D8D8",
    },
    "& .Dato": {
      margin: "0 0 5px 0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#20A698",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        margin: "0 0 2.5px 0",
        },
    },
    "& .Caracter": {
      margin: "0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#000000",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        },
    },
       '& .Regresar':{
        color: '#F2624F',
        border: '#F2624F 1px solid',
        height: '30px',
        width:'100px',
        borderRadius: '20px',
        letterSpacing: '0.14px',
        textTransform: 'none',
        background:'#FEFAF1',
        top:'calc(50% - 15px)',
        left:'5%',
        zIndex:4,
        [theme.breakpoints.down(502)]: {
          font:'bold 10px lato',
          width: "60px",
        },
      },
  },
  ContenedorLectura: {
    background: "#fff",
    width: "1160px",
    height: "100%",
    padding: "60px 168px 35px ",
    position: "relative",
    
    transform: (props) =>
      props.scale > 1 ? `scale(${props.scale}) translate(0px, 10%)` : `none`,
    [theme.breakpoints.down(1161)]: {
      width: "502px",
      padding: "60px 0 0 0",
    },
    [theme.breakpoints.down(502)]: {
      width: "350px",
      padding: "60px 0 0 0",
    },
    [theme.breakpoints.down(350)]: {
      width: "300px",
      padding: "30px 0 0 0",
    },
    "& .ContainerDerechos": {
      bottom: "80px",
      [theme.breakpoints.down(1161)]: {
        display: "none",
      },
    },
    "& .Derechos": {
      textAlign: "center",
      margin: "0px",
      font: " 15px lato",
      color: "#717476",
    },
    "& .Escritorio": {
      display: "block",
      [theme.breakpoints.down(1161)]: {
        display: "none",
      },
    },
    "& .Tablet": {
      display: "none",
      [theme.breakpoints.down(1161)]: {
        display: "block",
      },
    },
  },
  ContainerTitulo: {
    margin: "0 0 34px 0",
    [theme.breakpoints.down(1161)]: {
      margin: "0 0 34px 0",
    },
    "& .Titulo": {
      font: "48px fredoka one",
      letterSpacing: "0px",
      lineHeight: "58px",
      color: "#926DD1",
      margin: "0",
      textAlign: "center",
      [theme.breakpoints.down(1161)]: {
        font: "43px fredoka one",
        lineHeight: "52px",
      },
      [theme.breakpoints.down(502)]: {
        font: "30px fredoka one",
        lineHeight: "32px",
      },
    },
  },
  Parrafo1:{
    position:'relative',
    margin:' 0 0 36px 0',
    '& .Lectura':{
        lineHeight : '30px',
        margin:'0',
        font:'22px lato',
        color:'#000',
        letterSpacing: '0.20px',
        [theme.breakpoints.down(1161)]:{
            font:'18px lato',
            lineHeight :'28px',
            letterSpacing: '0.17px',
        },
        [theme.breakpoints.down(502)]:{
            font:'16px lato',
            lineHeight :'21px',
            letterSpacing: '0.09px',
        },
        [theme.breakpoints.down(350)]:{
            font:'12px lato',
            lineHeight :'18px',
            letterSpacing: '0.09px',
        },
    },
    '& .Lectura2':{
        lineHeight : '30px',
        margin:'0',
        font:'22px lato',
        color:'#000',
        letterSpacing: '0.20px',
        [theme.breakpoints.down(1161)]:{
            font:'18px lato',
            lineHeight :'28px',
            letterSpacing: '0.17px',
        },
        [theme.breakpoints.down(502)]:{
            font:'13px lato',
            lineHeight :'18px',
            letterSpacing: '0.09px',
        },
        [theme.breakpoints.down(350)]:{
          font:'11px lato',
          lineHeight :'12px',
          letterSpacing: '0.09px',
      },
    },

    '& .SubTitulo1':{
        font:'41px Fredoka One',
        lineHeight:'50px',
        color:'#FFFFFF',
        [theme.breakpoints.down(1161)]:{
            font:'27px Fredoka One',
            lineHeight:'32px',
        },
        [theme.breakpoints.down(502)]:{
            font:'18px Fredoka One',
            lineHeight:'23px',
        },
        [theme.breakpoints.down(350)]:{
          font:'16px Fredoka One',
          lineHeight:'18px',
      },
    },

    '& .SubTitulo2':{
        font:'57px Fredoka One',
        lineHeight:'69px',
        color:'#FFFFFF',
        [theme.breakpoints.down(1161)]:{
            font:'39px Fredoka One',
            lineHeight:'47px',
        },
        [theme.breakpoints.down(502)]:{
            font:'23px Fredoka One',
            lineHeight:'28px',
        },
        [theme.breakpoints.down(350)]:{
          font:'20px Fredoka One',
          lineHeight:'24px',
        },
    },

    '& .SubTitulo3':{
        font:'52px Fredoka One',
        lineHeight:'63px',
        color:'#FFFFFF',
        [theme.breakpoints.down(1161)]:{
            font:'33px Fredoka One',
            lineHeight:'40px',
        },
        [theme.breakpoints.down(502)]:{
            font:'20px Fredoka One',
            lineHeight:'24px',
        },
        [theme.breakpoints.down(350)]:{
          font:'18px Fredoka One',
          lineHeight:'20px',
      },
    },

    '& .SubTitulo4':{
        font:'60px Fredoka One',
        lineHeight:'72px',
        color:'#FFFFFF',
        [theme.breakpoints.down(1161)]:{
            font:'41px Fredoka One',
            lineHeight:'50px',
        },
        [theme.breakpoints.down(502)]:{
            font:'26px Fredoka One',
            lineHeight:'30px',
        },
        [theme.breakpoints.down(350)]:{
          font:'22px Fredoka One',
          lineHeight:'28px',
      },
    },

    '& .SubTitulo5':{
        font:'75px Fredoka One',
        lineHeight:'91px',
        color:'#FFFFFF',
        [theme.breakpoints.down(1161)]:{
            font:'53px Fredoka One',
            lineHeight:'65px',
        },
        [theme.breakpoints.down(502)]:{
            font:'30px fredoka one',
            lineHeight:'31px',
        },
        [theme.breakpoints.down(350)]:{
          font:'26px fredoka one',
          lineHeight:'20px',
      },
    },

    "& .Arte1": {
        position: "absolute",
        top: "133px",
        left: "36px",
        [theme.breakpoints.down(1161)]: {
          top: "95px",
          left: "35px",
        },
        [theme.breakpoints.down(502)]: {
          top: "90px",
          left: "20px",
        },
        [theme.breakpoints.down(350)]: {
          top: "77px",
          left: "18px",
        },
      },

      "& .Arte2": {
        position: "absolute",
        top: "564px",
        left: "507px",
        [theme.breakpoints.down(1161)]: {
          top: "450px",
          left: "350px",
        },
        [theme.breakpoints.down(502)]: {
          top: "330px",
          left: "226px",
        },
        [theme.breakpoints.down(350)]: {
          top: "279px",
          left: "196px",
        },
      },
      "& .Arte3": {
        position: "absolute",
        top: "1140px",
        left: "69px",
        [theme.breakpoints.down(1161)]: {
          top: "841px",
          left: "66px",
        },
        [theme.breakpoints.down(502)]: {
          top: "580px",
          left: "37px",
        },
        [theme.breakpoints.down(350)]: {
          top: '499px',
          left: '31px',
        },
      },
      "& .Arte4": {
        position: "absolute",
        top: "1550px",
        left: "461px",
        [theme.breakpoints.down(1161)]: {
          top: "1170px",
          left: "300px",
        },
        [theme.breakpoints.down(502)]: {
          top: "785px",
          left: "197px",
        },
        [theme.breakpoints.down(350)]: {
          top: '679px',
          left: '169px',
        },
      },
      "& .Arte5": {
        position: "absolute",
        top: "2010px",
        left: "98px",
        [theme.breakpoints.down(1161)]: {
          top: "1465px",
          left: "80px",
        },
        [theme.breakpoints.down(502)]: {
          top: "980px",
          left: "45px",
        },
        [theme.breakpoints.down(350)]: {
          top: '847px',
          left: '40px',
        },
      },
      "& .Arte6": {
        position: "absolute",
        top: "2480px",
        left: "489px",
        [theme.breakpoints.down(1161)]: {
          top: "1850px",
          left: "330px",
        },
        [theme.breakpoints.down(502)]: {
          top: "1213px",
          left: "201px",
        },
        [theme.breakpoints.down(350)]: {
          top: '1052px',
          left: '177px',
        },
      },
      "& .Arte7": {
        position: "absolute",
        top: "2950px",
        left: "92px",
        [theme.breakpoints.down(1161)]: {
          top: "2264px",
          left: "95px",
        },
        [theme.breakpoints.down(502)]: {
          top: "1475px",
          left: "54px",
        },
        [theme.breakpoints.down(350)]: {
          top: '1285px',
          left: '49px',
        },
      },
  
      "& .ArtLec1": {
        position: "absolute",
        width: "367px",
        top: "290px",
        left: "60px",
        [theme.breakpoints.down(1161)]: {
          width: "218px",
          top: "192px",
          left: "53px",
        },
        [theme.breakpoints.down(502)]: {
          width: "143px",
          top: "155px",
          left: "43px",
        },
        [theme.breakpoints.down(350)]: {
          top: '140px',
          left: '23px',
          width: '137px',
        },

      },
  
      "& .ArtLec2": {
        position: "absolute",
        width: "292px",
        top: "756px",
        left: "411px",
        [theme.breakpoints.down(1161)]: {
          width: "218px",
          top: "589px",
          left: "288px",
        },
        [theme.breakpoints.down(502)]: {
          top: "405px",
          left: "178px",
          width: "145px",
        },
        [theme.breakpoints.down(350)]: {
          top: '352px',
          left: '160px',
          width: '118px',
        },
      },
  
      "& .ArtLec3": {
        position: "absolute",
        top: "1270px",
        left: "40px",
        width: "247px",
        [theme.breakpoints.down(1161)]: {
          top: "932px",
          left: "45px",
          width: "166px",
        },
        [theme.breakpoints.down(502)]: {
          top: "636px",
          left: "20px",
          width: "118px",
        },
        [theme.breakpoints.down(350)]: {
          top: '560px',
          left: '20px',
          width: '88px',
        },
      },
  
      "& .ArtLec4": {
        position: "absolute",
        top: "1715px",
        left: "450px",
        width: "264px",
        [theme.breakpoints.down(1161)]: {
          top: "1283px",
          left: "291px",
          width: "215px",
        },
        [theme.breakpoints.down(502)]: {
          top: "852px",
          left: "182px",
          width: "155px",
        },
        [theme.breakpoints.down(350)]: {
          top: '740px',
          left: '159px',
          width: '121px',
        },
      },
  
      "& .ArtLec5": {
        position: "absolute",
        top: "2185px",
        left: "43px",
        width: "352px",
        [theme.breakpoints.down(1161)]: {
          top: "1585px",
          left: "68px",
          width: "213px",
        },
        [theme.breakpoints.down(502)]: {
          top: "1045px",
          left: "35px",
          width: "152px",
        },
        [theme.breakpoints.down(350)]: {
          top: '915px',
          left: '29px',
          width: '122px',
        },
      },
  
      "& .ArtLec6": {
        position: "absolute",
        top: "2660px",
        left: "477px",
        width: "317px",
        [theme.breakpoints.down(1161)]: {
          top: "1980px",
          left: "327px",
          width: "196px",
        },
        [theme.breakpoints.down(502)]: {
          top: "1287px",
          left: "190px",
          width: "137px",
        },
        [theme.breakpoints.down(350)]: {
          top: '1127px',
          left: '165px',
          width: '110px',
        },
      },
  
      "& .ArtLec7": {
        position: "absolute",
        top: "3160px",
        left: "57px",
        width: "325px",
        [theme.breakpoints.down(1161)]: {
          top: "2407px",
          left: "60px",
          width: "248px",
        },
        [theme.breakpoints.down(502)]: {
          top: "1558px",
          left: "26px",
          width: "169px",
        },
        [theme.breakpoints.down(350)]: {
          top: '1356px',
          left: '25px',
          width: '140px',
        },
      },
  
      "& .TextImg": {
        font: "20px lato",
        color: "#717476",
        letterSpacing: ".19px",
        lineHeight: "30px",
        margin: "24px 0 0 0",
        [theme.breakpoints.down(1161)]: {
          font: " 16px lato",
          lineHeight: "25px",
          letterSpacing: "0.15px",
        },
        [theme.breakpoints.down(502)]: {
          font: " 14px lato",
          lineHeight: "21px",
          letterSpacing: "0.15px",
        },
        [theme.breakpoints.down(350)]: {
          font: " 10px lato",
          lineHeight: "21px",
          letterSpacing: "0.15px",
        },
      },
      "& .Pos1": {
        position: "absolute",
        top: "465px",
        left: "540px",
        [theme.breakpoints.down(1161)]: {
          top: "365px",
          left: "355px",
        },
        [theme.breakpoints.down(502)]: {
          top: "260px",
          left: "210px",
        },
        [theme.breakpoints.down(350)]: {
          top: "216px",
          left: "210px",
        },
      },
      "& .Pos2": {
        position: "absolute",
        top: "1030px",
        left: "140px",
        [theme.breakpoints.down(1161)]: {
          top: "775px",
          left: "105px",
        },
        [theme.breakpoints.down(502)]: {
          top: "522px",
          left: "70px",
        },
        [theme.breakpoints.down(350)]: {
          top: '444px',
          left: '51px',
        },
      },
      "& .Pos3": {
        position: "absolute",
        top: "1460px",
        left: "536px",
        [theme.breakpoints.down(1161)]: {
          top: "1090px",
          left: "380px",
        },
        [theme.breakpoints.down(502)]: {
          top: "720px",
          left: "228px",
        },
        [theme.breakpoints.down(350)]: {
          top: '619px',
          left: '195px',
        },
      },
      "& .Pos4": {
        position: "absolute",
        top: "1910px",
        left: "155px",
        [theme.breakpoints.down(1161)]: {
          top: "1390px",
          left: "110px",
        },
        [theme.breakpoints.down(502)]: {
          top: "915px",
          left: "68px",
        },
        [theme.breakpoints.down(350)]: {
          top: '794px',
          left: '56px',
        },
      },
      "& .Pos5": {
        position: "absolute",
        top: "2370px",
        left: "600px",
        [theme.breakpoints.down(1161)]: {
          top: "1760px",
          left: "435px",
        },
        [theme.breakpoints.down(502)]: {
          top: "1140px",
          left: "258px",
        },
        [theme.breakpoints.down(350)]: {
          top: '988px',
          left: '220px',
        },
      },
      "& .Pos6": {
        position: "absolute",
        top: "2860px",
        left: "180px",
        [theme.breakpoints.down(1161)]: {
          top: "2170px",
          left: "115px",
        },
        [theme.breakpoints.down(502)]: {
          top: "1400px",
          left: "68px",
        },
        [theme.breakpoints.down(350)]: {
          top: '1215px',
          left: '52px',
        },
      },
      "& .Pos7": {
        position: "absolute",
        top: "3400px",
        left: "580px",
        [theme.breakpoints.down(1161)]: {
          top: "2580px",
          left: "450px",
        },
        [theme.breakpoints.down(502)]: {
          top: "1650px",
          left: "260px",
        },
        [theme.breakpoints.down(350)]: {
          top: '1433px',
          left: '222px',
        },
      },

    '& .Imagen':{
        margin:'0 0 25px 0',
        [theme.breakpoints.down(1161)]:{
            margin:'0 0 20px 0',
        },
        [theme.breakpoints.down(502)]:{
            width:'100%'
        },
        
    },
    '& .Imagen3':{
        margin:'0 0 50px 0',
        [theme.breakpoints.down(1161)]:{
            margin:'0 0 35px 0',
        },
        [theme.breakpoints.down(502)]:{
            width:'100%'
        },
    },



  
  }
}));

const Las_siete_Bellas_Artes = (props) => {

   const {activeStep, setActiveStep, handlePreviewStep, id_actividad} = props
  
  const handleContinue = () => {
    setActiveStep(activeStep - 1)
    handlePreviewStep()
  }

 const {
   handleSize,
   disabledZoom,
   closeZoom,
   scale,
   
   
   
  
   
 } = useContext(DrawerContext);
 const detectSize = () => {
   const lectura = document.getElementById("lectura");

   const newWidth = lectura?.offsetWidth;
   const newHeight = lectura?.offsetHeight;
   if (lectura) handleSize(newHeight, newWidth);
 };
 React.useEffect(() => {
   window.addEventListener("resize", detectSize);
   return () => {
     window.removeEventListener("resize", detectSize);
     closeZoom();
   };
 }, []);

 const classes = useStyles({ disabledZoom, scale });
  return (
    <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
      <Grid container item justifyContent="center" className={classes.ContainerInfoLectura}>
        <Grid container className="BarraInfoLectura">
            <Button className="Regresar" onClick={handleContinue}>
                Regresar
            </Button>
        </Grid>
        <Grid container item className="InfoLectura">
          <Grid container item direction="column" xs={6} justifyContent="center">
            <p className="Dato">Grado</p>
            <p className="Caracter">1</p>
          </Grid>
          <Grid container item direction="column" xs={6} justifyContent="center" className="Sesion">
            <p className="Dato">Sesión</p>
            <p className="Caracter">56</p>
          </Grid>
        </Grid>
      </Grid>

      <Grid
        container
        item
        className={classes.ContenedorLectura}
        justifyContent="center"
        id="lectura"
        onLoad={detectSize}

      >
        <Drawer id_lectura={id_actividad} withoutTransform ={true}/>
        <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
          <h2 className="Titulo">Las siete <br className='Tablet'/>Bellas Artes</h2>
        </Grid>
        <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
          <Grid container sm={12}>
            <p className="Lectura">
              La división de las artes proviene de la cultura griega. Con el paso de los años, esta clasificación de las artes ha
              cambiado y ahora tenemos siete Bellas Artes. ¿Las conoces?
            </p>
            <img
              className="Imagen Escritorio "
              src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img1ro/Sesion56/1.png"
              alt="Tarjeta"
            />
            <img
              className="Imagen Escritorio "
              src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img1ro/Sesion56/2.png"
              alt="Tarjeta"
            />
            <img
              className="Imagen Escritorio  Imagen3"
              src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img1ro/Sesion56/3.png"
              alt="Tarjeta"
            />
            <img
              className="Imagen Escritorio "
              src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img1ro/Sesion56/4.png"
              alt="Tarjeta"
            />
            <img
              className="Imagen Escritorio "
              src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img1ro/Sesion56/5.png"
              alt="Tarjeta"
            />
            <img
              className="Imagen Escritorio "
              src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img1ro/Sesion56/6.png"
              alt="Tarjeta"
            />
            <img
              className="Imagen Escritorio "
              src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img1ro/Sesion56/7.png"
              alt="Tarjeta"
            />
            <img
              className="Imagen Tablet"
              src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img1ro/Sesion56/1R.png"
              alt="Tarjeta"
            />
            <img
              className="Imagen Tablet"
              src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img1ro/Sesion56/2R.png"
              alt="Tarjeta"
            />
            <img
              className="Imagen Tablet Imagen3"
              src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img1ro/Sesion56/3R.png"
              alt="Tarjeta"
            />
            <img
              className="Imagen Tablet"
              src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img1ro/Sesion56/4R.png"
              alt="Tarjeta"
            />
            <img
              className="Imagen Tablet"
              src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img1ro/Sesion56/5R.png"
              alt="Tarjeta"
            />
            <img
              className="Imagen Tablet"
              src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img1ro/Sesion56/6R.png"
              alt="Tarjeta"
            />
            <img
              className="Imagen Tablet"
              src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img1ro/Sesion56/7R.png"
              alt="Tarjeta"
            />

            <h3 className="SubTitulo1 Arte1">Arquitectura</h3>
            <h3 className="SubTitulo2 Arte2">Danza</h3>
            <h3 className="SubTitulo1 Arte3">Escultura</h3>
            <h3 className="SubTitulo3 Arte4">Pintura</h3>
            <h3 className="SubTitulo4 Arte5">Música</h3>
            <h3 className="SubTitulo2 Arte6">Literatura</h3>
            <h3 className="SubTitulo5 Arte7">Cine</h3>

            <p className="Lectura2 ArtLec1">
              Este arte consiste en diseñar casas, edificios y otros espacios como parques y jardines. Su objetivo es llenar de
              belleza una ciudad.
            </p>

            <p className="Lectura2 ArtLec2">
              Este arte emplea el cuerpo humano y el ritmo musical como una forma de expresión. Los bailarines comunican emociones
              y sensaciones.
            </p>

            <p className="Lectura2 ArtLec3">
              Los artistas de esta disciplina crean piezas en tres dimensiones con una variedad de materiales.
            </p>

            <p className="Lectura2 ArtLec4">
              En este arte, tintas y pigmentos se aplican sobre lienzos y otras superficies para crear escenas, personajes o
              paisajes.
            </p>

            <p className="Lectura2 ArtLec5">
              Mediante diversos instrumentos, este arte crea ritmos y melodías que provocan diferentes sensaciones. ¡Desde música
              para bailar en tu cuarto hasta una ópera!
            </p>

            <p className="Lectura2 ArtLec6">
              En este arte, los escritores usan el lenguaje para crear cuentos, poesía o ensayos. A través de sus palabras, nos
              hacen sentir.
            </p>

            <p className="Lectura2 ArtLec7">
              En esta disciplina se mezclan fotografía, literatura, teatro y música para contar historias que entretengan y capten
              la atención de un espectador.
            </p>

            <p className="TextImg Pos1">Arquitectura</p>
            <p className="TextImg Pos2">Danza</p>
            <p className="TextImg Pos3">Escultura</p>
            <p className="TextImg Pos4">Pintura</p>
            <p className="TextImg Pos5">Música</p>
            <p className="TextImg Pos6">Literatura</p>
            <p className="TextImg Pos7">Cine</p>
          </Grid>
        </Grid>
        <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
          <p className="Lectura">
            Ahora que conoces la clasificación de las Bellas Artes, ¡puedes ir y crear tu propia obra maestra!
          </p>
        </Grid>
      </Grid>
      <Grid container item style={{ marginTop: 80 }}></Grid>
    </Grid>
  );
};

export default Las_siete_Bellas_Artes;
