import { Button, Grid, makeStyles } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import DrawerContext from "../../../../../usability/DrawerContext";
import { useOnLoadImages } from "../../../../hooks/useOnLoadImages";
import Loader from "../../../../Loader/Loader";
import Drawer from "../../ReadingTools/Drawer";

const useStyles =  makeStyles (theme =>({
     Contenedor:{
        background:(props)=> props.Cerrar ? "none":"#E9FBF9",
        width:'100vw',
        [theme.breakpoints.down(1161)]:{
            background:'none',
        },
        [theme.breakpoints.down(600)]:{
            marginTop: '79.51px',
        }
    },
  ContainerInfoLectura: {
    position: "relative",
    height: "86px",
    [theme.breakpoints.down(502)]:{
        height: "43px",
    },
    background: "#76D4C3",
    "& .BarraInfoLectura": {
      height: "86px",
      background: `Url(https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Barras/Azul.png)`,
        [theme.breakpoints.down(502)]:{
            height: "43px",
        },
    },
    "& .InfoLectura": {
      position: "absolute",
      height: "86px",
      width: "219px",
      boxSizing: "border-box",
      padding: "17.2px 0",
      background: "#FAFAFA",
      borderRadius: "50px",
      boxShadow: "2px 2px 2px 2px #00000029",
      zIndex: "4",
        [theme.breakpoints.down(502)]:{
            height: "43px",
            width: "109px",
            padding: "8.6px 0",
            borderRadius: "25px",
        },
    },
    "& .InfoLectura:before": {
      position: "absolute",
      display: "flex",
      boxSizing: "border-box",
      padding: "0 0 0 10px",
      justifyContent: "center",
      alignItems: "center",
      height: "39.88px",
      width: "39.88px",
      borderRadius: "50%",
      content: '""',
      boxShadow: "3px 0px .5px 0px #00000029",
      background: "#FAFAFA",
      left: "90px",
      top: "65px",
      transform: "rotate(0.25turn)",
      [theme.breakpoints.down(502)]:{
        left: "45px",
        top: "30px",
        height: "20.88px",
        width: "20.88px",
        },
    },
    "& .InfoLectura:after": {
      content: '""',
      height: "15px",
      width: "15px",
      borderTop: "2px solid #20A698",
      borderLeft: "2px solid #20A698",
      position: "absolute",
      bottom: "-10px",
      left: "Calc(50% - 8px)",
      transform: "rotate(0.625turn)",
      [theme.breakpoints.down(502)]:{
        height: "10px",
        width: "10px",
        left: "Calc(50% - 4.5px)",
        bottom: "-3px",
        borderTop: "1px solid #20A698",
        borderLeft: "1px solid #20A698",
        },
    },
    "& .Sesion": {
      borderLeft: "2px solid #D8D8D8",
    },
    "& .Dato": {
      margin: "0 0 5px 0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#20A698",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        margin: "0 0 2.5px 0",
        },
    },
    "& .Caracter": {
      margin: "0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#000000",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        },
    },
    '& .Regresar':{
        color: '#F2624F',
        border: '#F2624F 1px solid',
        height: '30px',
        width:'100px',
        borderRadius: '20px',
        letterSpacing: '0.14px',
        textTransform: 'none',
        background:'#FEFAF1',
        top:'calc(50% - 15px)',
        left:'5%',
        zIndex:4,
        [theme.breakpoints.down(502)]: {
          font:'bold 10px lato',
          width: "60px",
        },
      },   
    },
    ContenedorLectura:{
        display:(props)=> props.Cerrar ? "none":"flex",
        background:'#fff',
        width:'1160px',
        height:'100%',
        padding:'0',
        
        transform: (props) =>
        props.scale > 1 ? `scale(${props.scale}) translate(0px, 10%)` : `none`,
        [theme.breakpoints.down(1161)]:{
            width:'768px',
          },
          [theme.breakpoints.down(768)]:{
            width:'350px',
          },
        '& .ContainerDerechos':{
            bottom:'80px',
            [theme.breakpoints.down(1161)]:{
                display:'none',
            },
        },
        '& .Derechos':{
            textAlign:"center",
            margin:"0 0 36px 0",
            font:" 15px lato",
            color:"#717476",
        },
        '& .Escritorio':{
            display:'block',
            [theme.breakpoints.down(1161)]:{
                display:'none'
            },
        },
        '& .Tablet':{
            display:'none',
            [theme.breakpoints.down(1161)]:{
                display:'block'
            },
        }
    },
    Parrafo1:{
        position:'relative',
        '& .Blanco':{
            height:'20px',
            width:'200px',
            top:'2790px',
            left:'0',
            background:'#fff',
            position:'absolute',
            [theme.breakpoints.down(1161)]:{
                display:'none'
            },
        },
        '& .Titulo':{
            top:'94px',
            font:'102px fredoka one',
            letterSpacing: '0px',
            lineHeight:'123px',
            color: '#FFFFFF',
            margin:'0',
            textAlign:'center',
            [theme.breakpoints.down(1161)]:{
                top:'2.5%',
                font:'67px fredoka one',
                lineHeight:'81px',
            },
            [theme.breakpoints.down(768)]:{
                font:'32px fredoka one',
                lineHeight:'39px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'35px',
            },
        },
        '& .SubTitulo':{
            top:'905px',
            font:' 44px Fredoka One',
            color:'#FFFFFF',
            letterSpacing:'.41px',
            lineHeight:'53px',
            margin:'0',
            [theme.breakpoints.down(1161)]:{
                top:'24.5%',
                font:' 28px Fredoka One',
                lineHeight :'32px',
                letterSpacing: '0.26px',
            },
            [theme.breakpoints.down(768)]:{
                top:'24.7%',
                font:' 16px Fredoka One',
                lineHeight :'24px',
                letterSpacing: '0.21px',
            },
            [theme.breakpoints.down(350)]:{
                top:'25.3%',
                font:' 14px Fredoka One',
                lineHeight :'18px',
                letterSpacing: '0.21px',
            },
        },
        '& .SubTitulo2':{
            left:'402.67px',
            font:' 22px Fredoka One',
            color:'#FFFFFF',
            lineHeight:'26px',
            margin:'0',
            [theme.breakpoints.down(1161)]:{
                left:'35%',
                font:' 14px Fredoka One',
                lineHeight :'18px',
            },
            [theme.breakpoints.down(768)]:{
                font:' 6px Fredoka One',
                lineHeight :'16px',                
                left:'35%',
            },
        },
        '& .SubTitulo3':{
            font:' 22px Fredoka One',
            color:'#575757',
            lineHeight:'26px',
            textAlign:'center',
            margin:'0',
            [theme.breakpoints.down(1161)]:{
                font:' 14px Fredoka One',
                lineHeight :'18px',
            },
            [theme.breakpoints.down(768)]:{
                font:' 8px Fredoka One',
                lineHeight :'8px',
            },
        },
        '& .Lectura':{
            width:'498px',
            top:'2870px',
            left:'603px',
            lineHeight : '30px',
            margin:'0',
            font:'22px lato',
            color:'#000',
            letterSpacing: '0.20px',
            [theme.breakpoints.down(1161)]:{
                width:'75.78%',
                top:'77.3%',
                left:'13%',
                font:'18px lato',
                lineHeight :'28px',
                letterSpacing: '0.17px',
            },
            [theme.breakpoints.down(768)]:{
                left:'5%',
                width:'90%',
                font:'11px lato',
                lineHeight :'12.2px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                left:'5%',
                width:'90%',
                font:'9px lato',
                lineHeight :'10px',
                letterSpacing: '0.09px',
            },
        },
        '& .Pos':{
            position:'absolute',
        },
        '& .Kg':{
            margin:'0 0 0 15px',
            [theme.breakpoints.down(1161)]:{
                margin:'0 0 0 10px',
            },
            [theme.breakpoints.down(768)]:{
                margin:'0 0 0 2px',
            },
        },
        '& .Peso':{
            top:'983px',
            [theme.breakpoints.down(1161)]:{
                top:'26.35%',
            },
            [theme.breakpoints.down(768)]:{
                top:'26.75%',
            },
            [theme.breakpoints.down(350)]:{
                top:'26.9%',
            },
        },
        '& .Peso1':{
            top:'1347px',
            [theme.breakpoints.down(1161)]:{
                top:'34.8%',
            },
            [theme.breakpoints.down(768)]:{
                top:'35.11%',
            },
            [theme.breakpoints.down(350)]:{
                top:'35.26%',
            },
        },
        '& .Peso2':{
            top:'1711px',
            [theme.breakpoints.down(1161)]:{
                top:'43.28%',
            },
            [theme.breakpoints.down(768)]:{
                top:'43.55%',
            },
            [theme.breakpoints.down(350)]:{
                top:'43.70%',
            },
        },
        '& .Peso3':{
            top:'2076px',
            [theme.breakpoints.down(1161)]:{
                top:'51.7%',
            },
            [theme.breakpoints.down(768)]:{
                top:'51.90%',
            },
            [theme.breakpoints.down(350)]:{
                top:'52.05%',
            },
        },
        '& .Peso4':{
            top:'2441px',
            [theme.breakpoints.down(1161)]:{
                top:'60.18%',
            },
            [theme.breakpoints.down(768)]:{
                top:'60.28%',
            },
            [theme.breakpoints.down(350)]:{
                top:'60.43%',
            },
        },
        '& .Perrito1':{
            top:'1080px',
            left:'223px',
            [theme.breakpoints.down(1161)]:{
                top:'28.5%',
                left:'19%',
            },
            [theme.breakpoints.down(768)]:{
                top:'29%',
                left:'17%',
            },
        },
        '& .Perrito2':{
            top:'1080px',
            left:'844px',
            [theme.breakpoints.down(1161)]:{
                top:'28.5%',
                left:'72.5%',
            },
            [theme.breakpoints.down(768)]:{
                top:'29%',
                left:'72%',
            },
        },
        '& .Perrito3':{
            top:'1390px',
            left:'84.76px',
            [theme.breakpoints.down(1161)]:{
                top:'35.8%',
                left:'7.5%',
            },
            [theme.breakpoints.down(768)]:{
                top:'36.2%',
                left:'6.5%',
            },
        },
        '& .Perrito4':{
            top:'1400px',
            left:'386.64px',
            [theme.breakpoints.down(1161)]:{
                top:'35.9%',
                left:'32.5%',
            },
            [theme.breakpoints.down(768)]:{
                top:'36.5%',
                left:'31%',
            },
        },
        '& .Perrito5':{
            top:'1440px',
            left:'755.87px',
            [theme.breakpoints.down(1161)]:{
                top:'36.8%',
                left:'65%',
            },
            [theme.breakpoints.down(768)]:{
                top:'37.3%',
                left:'64%',
            },
        },
        '& .Perrito6':{
            top:'1790px',
            left:'92.57px',
            [theme.breakpoints.down(1161)]:{
                top:'45%',
                left:'8%',
            },
            [theme.breakpoints.down(768)]:{
                top:'45.4%',
                left:'7%',
            },
        },
        '& .Perrito7':{
            top:'1770px',
            left:'360.94px',
            [theme.breakpoints.down(1161)]:{
                top:'44.6%',
                left:'30.5%',
            },
            [theme.breakpoints.down(768)]:{
                top:'45%',
                left:'30%',
            },
        },
        '& .Perrito8':{
            top:'1780px',
            left:'683px',
            [theme.breakpoints.down(1161)]:{
                top:'44.8%',
                left:'58.5%',
            },
            [theme.breakpoints.down(768)]:{
                top:'45.1%',
                left:'57.4%',
            },
        },
        '& .Perrito9':{
            top:'2339px',
            left:'87.5px',
            [theme.breakpoints.down(1161)]:{
                top:'57.8%',
                left:'7.8%',
            },
            [theme.breakpoints.down(768)]:{
                top:'58.2%',
                left:'7%',
            },
        },
        '& .Perrito10':{
            top:'2339px',
            left:'771px',
            [theme.breakpoints.down(1161)]:{
                top:'57.8%',
                left:'66%',
            },
            [theme.breakpoints.down(768)]:{
                top:'58.2%',
                left:'65%',
            },
        },
        '& .Perrito11':{
            top:'2100px',
            left:'1013px',
            [theme.breakpoints.down(1161)]:{
                top:'52.25%',
                left:'86.4%',
            },
            [theme.breakpoints.down(768)]:{
                top:'52.60%',
                left:'85.8%',
            },
        },
        '& .Perrito12':{
            top:'2765px',
            left:'43px',
            [theme.breakpoints.down(1161)]:{
                top:'67.6%',
                left:'5%',
            },
            [theme.breakpoints.down(768)]:{
                top:'67.8%',
                left:'4%',
            },
        },
        '& .Perrito13':{
            top:'2500px',
            left:'290px',
            [theme.breakpoints.down(1161)]:{
                top:'61.6%',
                left:'25.5%',
            },
            [theme.breakpoints.down(768)]:{
                top:'61.8%',
                left:'23%',
            },
        },
        '& .Perrito14':{
            top:'2470px',
            left:'971px',
            [theme.breakpoints.down(1161)]:{
                top:'60.9%',
                left:'83.5%',
            },
            [theme.breakpoints.down(768)]:{
                top:'61.2%',
                left:'83.5%',
            },
        },

        

        '& .TextImg':{
            font:'20px lato',
            width:'740px',
            color:'#717476',
            letterSpacing:'.19px',
            lineHeight:'30px',
            margin:'42px 0 71.8px 0',
            [theme.breakpoints.down(1161)]:{
                width:'65%',
                font:' 18px lato',
                lineHeight :'25px',
                letterSpacing: '0.15px',
            },
            [theme.breakpoints.down(768)]:{
                margin:'22px 0 31px 0',
                width:'85%',
                font:' 14px lato',
                lineHeight :'21px',
                letterSpacing: '0.15px',
            },
            [theme.breakpoints.down(350)]:{
                margin:'22px 0 31px 0',
                width:'85%',
                font:' 10px lato',
                lineHeight :'12px',
                letterSpacing: '0.15px',
            },
        },
        '& .link':{
            color:'#161CB1',
            textDecoration:'none',
        },
        '& .Boton1':{
            top:'1125px',
            left:'370px',
            height:'190px',
            width:'190px',
            cursor:'pointer',
            [theme.breakpoints.down(1161)]:{
                top:'29%',
                left:'30%',
                height:'120px',
                width:'110px',
            },
            [theme.breakpoints.down(768)]:{
                height:'65px',
                width:'50px',
            },
        },
        '& .Boton2':{
            top:'1125px',
            left:'647px',
            height:'190px',
            width:'190px',
            cursor:'pointer',
            [theme.breakpoints.down(1161)]:{
                top:'29%',
                left:'56%',
                height:'120px',
                width:'110px',
            },
            [theme.breakpoints.down(768)]:{
                height:'65px',
                width:'50px',
            },
        },
        '& .Boton3':{
            top:'1500px',
            left:'200px',
            height:'190px',
            width:'190px',
            cursor:'pointer',
            [theme.breakpoints.down(1161)]:{
                top:'38%',
                left:'17%',
                height:'130px',
                width:'110px',
            },
            [theme.breakpoints.down(768)]:{
                height:'68px',
                width:'50px',
            },
        },
        '& .Boton4':{
            top:'1420px',
            left:'500px',
            height:'260px',
            width:'250px',
            cursor:'pointer',
            [theme.breakpoints.down(1161)]:{
                top:'36.6%',
                left:'40%',
                height:'165px',
                width:'190px',
            },
            [theme.breakpoints.down(768)]:{
                height:'80px',
                width:'85px',
            },
        },
        '& .Boton5':{
            top:'1460px',
            left:'850px',
            height:'210px',
            width:'250px',
            cursor:'pointer',
            [theme.breakpoints.down(1161)]:{
                top:'37%',
                left:'71%',
                height:'150px',
                width:'160px',
            },
            [theme.breakpoints.down(768)]:{
                height:'75px',
                width:'70px',
            },
        },
        '& .Boton6':{
            top:'1805px',
            left:'190px',
            height:'210px',
            width:'165px',
            cursor:'pointer',
            [theme.breakpoints.down(1161)]:{
                top:'45.5%',
                left:'15%',
                height:'150px',
                width:'120px',
            },
            [theme.breakpoints.down(768)]:{
                height:'70px',
                width:'55px',
            },
        },

        '& .Boton7':{
            top:'1800px',
            left:'440px',
            height:'210px',
            width:'250px',
            cursor:'pointer',
            [theme.breakpoints.down(1161)]:{
                top:'45%',
                left:'40%',
                height:'150px',
                width:'160px',
            },
            [theme.breakpoints.down(768)]:{
                height:'70px',
                width:'70px',
            },
        },

        '& .Boton8':{
            top:'1820px',
            left:'800px',
            height:'210px',
            width:'250px',
            cursor:'pointer',
            [theme.breakpoints.down(1161)]:{
                top:'45%',
                left:'65%',
                height:'150px',
                width:'185px',
            },
            [theme.breakpoints.down(768)]:{
                height:'70px',
                width:'90px',
            },
        },
        '& .Boton9':{
            top:'2120px',
            left:'170px',
            height:'270px',
            width:'360px',
            cursor:'pointer',
            [theme.breakpoints.down(1161)]:{
                top:'53.5%',
                left:'15%',
                height:'160px',
                width:'225px',
            },
            [theme.breakpoints.down(768)]:{
                height:'80px',
                width:'105px',
            },
        },
        '& .Boton10':{
            top:'2125px',
            left:'550px',
            height:'270px',
            width:'220px',
            cursor:'pointer',
            [theme.breakpoints.down(1161)]:{
                top:'53%',
                left:'47%',
                height:'160px',
                width:'150px',
            },
            [theme.breakpoints.down(768)]:{
                height:'80px',
                width:'70px',
            },
        },
        '& .Boton11':{
            top:'2140px',
            left:'850px',
            height:'250px',
            width:'260px',
            cursor:'pointer',
            [theme.breakpoints.down(1161)]:{
                top:'53%',
                left:'72%',
                height:'160px',
                width:'150px',
            },
            [theme.breakpoints.down(768)]:{
                height:'80px',
                width:'70px',
            },
        },
        '& .Boton12':{
            top:'2480px',
            left:'180px',
            height:'270px',
            width:'200px',
            cursor:'pointer',
            borderRadius:'0 220px 0 0 ',
            [theme.breakpoints.down(1161)]:{
                borderRadius:'0 150px 0 0 ',
                top:'61.2%',
                left:'15%',
                height:'180px',
                width:'150px',
            },
            [theme.breakpoints.down(768)]:{
                height:'85px',
                width:'70px',
            },
        },
        '& .Boton13':{
            top:'2480px',
            left:'470px',
            height:'290px',
            width:'260px',
            cursor:'pointer',
            [theme.breakpoints.down(1161)]:{
                top:'61.5%',
                left:'39%',
                height:'180px',
                width:'190px',
            },
            [theme.breakpoints.down(768)]:{
                height:'85px',
                width:'85px',
            },
        },
        '& .Boton14':{
            top:'2500px',
            left:'780px',
            height:'290px',
            width:'350px',
            cursor:'pointer',
            [theme.breakpoints.down(1161)]:{
                top:'61.5%',
                left:'67%',
                height:'180px',
                width:'240px',
            },
            [theme.breakpoints.down(768)]:{
                height:'85px',
                width:'100px',
            },
        },
        '& .Imagen':{
            [theme.breakpoints.down(1161)]:{
                width:'100%',
            },
        },  
        '& .Esp':{
            margin:'47px 0',
            [theme.breakpoints.down(768)]:{
                margin:'27px 0'
            },
        },
    },
    ContenedorPerritos:{
        '& .Escritorio':{
            display:'block',
            [theme.breakpoints.down(1161)]:{
                display:'none'
            },
        },
        '& .Tablet':{
            display:'none',
            [theme.breakpoints.down(1161)]:{
                display:'block'
            },
        },
        display:(props)=> props.Cerrar ? "flex":"none",
        height:'1024px',
        [theme.breakpoints.down(768)]:{
            width:'100%',
            height:'calc(100vh - 79.5px)',
        },
        position:'relative',
        '& .Grande':{
            cursor:'pointer',
            position:'absolute',
            height:'690px',
            width:'690px',
            [theme.breakpoints.down(768)]:{
                width:'350px',
                height:'350px',
            },
            display:(props)=> props.Cerrar ? "flex":"none",
        },
        '& .Perro1':{
            display:(props)=> props.Boton1 ? "flex":"none",
        },
        '& .Perro2':{
            display:(props)=> props.Boton2 ? "flex":"none",
        },
        '& .Perro3':{
            display:(props)=> props.Boton3 ? "flex":"none",
        },
        '& .Perro4':{
            display:(props)=> props.Boton4 ? "flex":"none",
        },
        '& .Perro5':{
            display:(props)=> props.Boton5 ? "flex":"none",
        },
        '& .Perro6':{
            display:(props)=> props.Boton6 ? "flex":"none",
        },
        '& .Perro7':{
            display:(props)=> props.Boton7 ? "flex":"none",
        },
        '& .Perro8':{
            display:(props)=> props.Boton8 ? "flex":"none",
        },
        '& .Perro9':{
            display:(props)=> props.Boton9 ? "flex":"none",
        },
        '& .Perro10':{
            display:(props)=> props.Boton10 ? "flex":"none",
        },
        '& .Perro11':{
            display:(props)=> props.Boton11 ? "flex":"none",
        },
        '& .Perro12':{
            display:(props)=> props.Boton12 ? "flex":"none",
        },
        '& .Perro13':{
            display:(props)=> props.Boton13 ? "flex":"none",
        },
        '& .Perro14':{
            display:(props)=> props.Boton14 ? "flex":"none",
        },
        '& .Tipo1':{
            display:(props)=> props.Tipo1 ? "flex":"none",
        },
        '& .Tipo2':{
            display:(props)=> props.Tipo2 ? "flex":"none",
        },
        '& .Tipo3':{
            display:(props)=> props.Tipo3 ? "flex":"none",
        },
        '& .Tipo4':{
            display:(props)=> props.Tipo4 ? "flex":"none",
        },
        '& .Tipo5':{
            display:(props)=> props.Tipo5 ? "flex":"none",
        },
        '& .SubTitulo4':{
            top:'0',
            margin:'54px 0 0 0',
            position:'absolute',
            font:'60px Fredoka One',
            color:'#707070',
            [theme.breakpoints.down(1161)]:{
                font:'32px Fredoka One',
            },
            [theme.breakpoints.down(768)]:{
                font:'14px Fredoka One',
                margin:'24px 0 0 0',
            },
        },
        '& .SubTitulo5':{
            bottom:'0',
            margin:'0 0 54px 0',
            position:'absolute',
            font:'94px Fredoka One',
            color:'#707070',
            [theme.breakpoints.down(1161)]:{
                font:'61px Fredoka One',
            },
            [theme.breakpoints.down(768)]:{
                font:'31px Fredoka One',
                margin:'0 0 24px 0',
            },
        },
        '& .Fondo':{
            position:'absolute',
            top:'0',
            [theme.breakpoints.down(768)]:{
                width:'100%',
                height:'100%',
            },
        },
        '& .Imagen':{
        [theme.breakpoints.down(768)]:{
            width:'300px',
            height:'250px',
        },
        },  
    },
}));

const El_peso_de_los_perros = (props) => {
    const {activeStep, setActiveStep, handlePreviewStep, id_actividad} = props

    const handleContinue = () => {
      setActiveStep(activeStep - 1)
      handlePreviewStep()
    }

    
    const [Cerrar,Cierre] = useState(false);
    const [Boton1,Btn1] = useState(false);
    const [Boton2,Btn2] = useState(false);
    const [Boton3,Btn3] = useState(false);
    const [Boton4,Btn4] = useState(false);
    const [Boton5,Btn5] = useState(false);
    const [Boton6,Btn6] = useState(false);
    const [Boton7,Btn7] = useState(false);
    const [Boton8,Btn8] = useState(false);
    const [Boton9,Btn9] = useState(false);
    const [Boton10,Btn10] = useState(false);
    const [Boton11,Btn11] = useState(false);
    const [Boton12,Btn12] = useState(false);
    const [Boton13,Btn13] = useState(false);
    const [Boton14,Btn14] = useState(false);
    const [Tipo1,Tip1] = useState(false);
    const [Tipo2,Tip2] = useState(false);
    const [Tipo3,Tip3] = useState(false);
    const [Tipo4,Tip4] = useState(false);
    const [Tipo5,Tip5] = useState(false);

    const {
      handleSize,
      disabledZoom,
      closeZoom,
      handleZoom,
     scale
    } = useContext(DrawerContext);
    const [loading, setLoading] = React.useState(true);
    const wrapperRef = React.useRef(null);
    const imagesLoaded = useOnLoadImages(wrapperRef);
  
    const detectSize = () => {
      const lectura = document.getElementById("lectura");
      const newWidth = lectura?.offsetWidth;
      const newHeight = lectura?.offsetHeight;
      setLoading(false);
      if (lectura) handleSize(newHeight, newWidth);
    };
    useEffect(() => {
      window.addEventListener("resize", detectSize);
      return () => {
        handleZoom()
        window.removeEventListener("resize", detectSize);
        closeZoom()
      };
    }, []);
  
    const classes = useStyles
    ({ disabledZoom,scale,
        Cerrar,Boton1,Boton2,Boton3,Boton4,Boton5,Boton6,
        Boton7,Boton8,Boton9,Boton10,Boton11,Boton12,
        Boton13,Boton14,Tipo1,Tipo2,Tipo3,Tipo4,Tipo5 
    });
    
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
             <Grid container item justifyContent="center" className={classes.ContainerInfoLectura}>
                       <Grid container className="BarraInfoLectura">
            <Button className="Regresar" onClick={handleContinue}>
                Regresar
            </Button>
        </Grid>
               <Grid container item className="InfoLectura">
                    <Grid container item direction="column" xs={6} justifyContent="center">
                        <p className="Dato">Grado</p>
                        <p className="Caracter">5</p>
                    </Grid>
                    <Grid container item direction="column" xs={6} justifyContent="center" className="Sesion">
                        <p className="Dato">Sesión</p>
                        <p className="Caracter">34</p>
                    </Grid>
               </Grid>
            </Grid>
            {!imagesLoaded && <Loader />}
        <Grid
            container
            item
            className={classes.ContenedorLectura}
            justifyContent="center"
            id="lectura"
            onLoad={detectSize}
            ref={wrapperRef}
        >
<Drawer id_lectura={id_actividad} />
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <img className="Imagen Escritorio" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion34/1.png" alt="Tarjeta" />
                    <img className="Imagen Escritorio Esp" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion34/2.png" alt="Tarjeta" />
                    <img className="Imagen Escritorio" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion34/3.png" alt="Tarjeta" />
                    <img className="Imagen Escritorio" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion34/4.png" alt="Tarjeta" />
                    <img className="Imagen Tablet" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion34/1R.png" alt="Tarjeta" />
                    <img className="Imagen Tablet Esp" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion34/2.png" alt="Tarjeta" />
                    <img className="Imagen Tablet" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion34/3R.png" alt="Tarjeta" />
                    <img className="Imagen Tablet" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion34/4R.png" alt="Tarjeta" />
                    <h2 className='Pos Titulo'>El peso de los perros</h2>
                    <h3 className='Pos SubTitulo'>Algunas razas caninas según su peso</h3>
                    <p className='Pos SubTitulo3 Perrito1'>Poodle toy</p>
                    <p className='Pos SubTitulo3 Perrito2'>Morkie</p>
                    <p className='Pos SubTitulo3 Perrito3'>Bulldog <br/> francés</p>
                    <p className='Pos SubTitulo3 Perrito4'>Pastor de <br/>Shetland</p>
                    <p className='Pos SubTitulo3 Perrito5'>Terrier <br/> escocés</p>
                    <p className='Pos SubTitulo3 Perrito6'>Beagle</p>
                    <p className='Pos SubTitulo3 Perrito7'>Border <br/> collie</p>
                    <p className='Pos SubTitulo3 Perrito8'>Cocker spaniel <br/> inglés</p>
                    <p className='Pos SubTitulo3 Perrito9'>Pastor <br/> alemán</p>
                    <p className='Pos SubTitulo3 Perrito10'>Husky <br/> siberiano</p>
                    <p className='Pos SubTitulo3 Perrito11'>Bóxer</p>
                    <p className='Pos SubTitulo3 Perrito12'>Bloodhound</p>
                    <p className='Pos SubTitulo3 Perrito13'>San Bernardo</p>
                    <p className='Pos SubTitulo3 Perrito14'>Kuvasz</p>
                    <div className='Pos Blanco'></div>
                    <div className='Pos Boton Boton1'
                        onClick={()=> {
                        Cierre(true);
                        Btn1(true);  
                        Btn2(false);
                        Btn3(false);
                        Btn4(false);
                        Btn5(false);
                        Btn6(false);
                        Btn7(false);
                        Btn8(false);
                        Btn9(false);
                        Btn10(false);
                        Btn11(false);
                        Btn12(false);
                        Btn13(false);
                        Btn14(false);
                        Tip1(true);
                    } }
                    ></div>
                    <div className='Pos Boton Boton2'
                        onClick={()=> {
                        Cierre(true);
                        Btn1(false);  
                        Btn2(true);
                        Btn3(false);
                        Btn4(false);
                        Btn5(false);
                        Btn6(false);
                        Btn7(false);
                        Btn8(false);
                        Btn9(false);
                        Btn10(false);
                        Btn11(false);
                        Btn12(false);
                        Btn13(false);
                        Btn14(false);
                        Tip1(true);
                    } }
                    ></div>
                    <div className='Pos Boton Boton3'
                        onClick={()=> {
                        Cierre(true);
                        Btn1(false);  
                        Btn2(false);
                        Btn3(true);
                        Btn4(false);
                        Btn5(false);
                        Btn6(false);
                        Btn7(false);
                        Btn8(false);
                        Btn9(false);
                        Btn10(false);
                        Btn11(false);
                        Btn12(false);
                        Btn13(false);
                        Btn14(false);
                        Tip2(true);
                    } }
                    ></div>
                    <div className='Pos Boton Boton4'
                        onClick={()=> {
                        Cierre(true);
                        Btn1(false);  
                        Btn2(false);
                        Btn3(false);
                        Btn4(true);
                        Btn5(false);
                        Btn6(false);
                        Btn7(false);
                        Btn8(false);
                        Btn9(false);
                        Btn10(false);
                        Btn11(false);
                        Btn12(false);
                        Btn13(false);
                        Btn14(false);
                        Tip2(true);
                    } }
                    ></div>
                    <div className='Pos Boton Boton5'
                        onClick={()=> {
                        Cierre(true);
                        Btn1(false);  
                        Btn2(false);
                        Btn3(false);
                        Btn4(false);
                        Btn5(true);
                        Btn6(false);
                        Btn7(false);
                        Btn8(false);
                        Btn9(false);
                        Btn10(false);
                        Btn11(false);
                        Btn12(false);
                        Btn13(false);
                        Btn14(false);
                        Tip2(true);
                    } }
                    ></div>
                    <div className='Pos Boton Boton6'
                        onClick={()=> {
                        Cierre(true);
                        Btn1(false);  
                        Btn2(false);
                        Btn3(false);
                        Btn4(false);
                        Btn5(false);
                        Btn6(true);
                        Btn7(false);
                        Btn8(false);
                        Btn9(false);
                        Btn10(false);
                        Btn11(false);
                        Btn12(false);
                        Btn13(false);
                        Btn14(false);
                        Tip3(true);
                    } }
                    ></div>
                    <div className='Pos Boton Boton7'
                        onClick={()=> {
                        Cierre(true);
                        Btn1(false);  
                        Btn2(false);
                        Btn3(false);
                        Btn4(false);
                        Btn5(false);
                        Btn6(false);
                        Btn7(true);
                        Btn8(false);
                        Btn9(false);
                        Btn10(false);
                        Btn11(false);
                        Btn12(false);
                        Btn13(false);
                        Btn14(false);
                        Tip3(true);
                    } }
                    ></div>
                    <div className='Pos Boton Boton8'
                        onClick={()=> {
                        Cierre(true);
                        Btn1(false);  
                        Btn2(false);
                        Btn3(false);
                        Btn4(false);
                        Btn5(false);
                        Btn6(false);
                        Btn7(false);
                        Btn8(true);
                        Btn9(false);
                        Btn10(false);
                        Btn11(false);
                        Btn12(false);
                        Btn13(false);
                        Btn14(false);
                        Tip3(true);
                    } }
                    ></div>
                    <div className='Pos Boton Boton9'
                        onClick={()=> {
                        Cierre(true);
                        Btn1(false);  
                        Btn2(false);
                        Btn3(false);
                        Btn4(false);
                        Btn5(false);
                        Btn6(false);
                        Btn7(false);
                        Btn8(false);
                        Btn9(true);
                        Btn10(false);
                        Btn11(false);
                        Btn12(false);
                        Btn13(false);
                        Btn14(false);
                        Tip4(true);
                    } }
                    ></div>
                    <div className='Pos Boton Boton10'
                        onClick={()=> {
                        Cierre(true);
                        Btn1(false);  
                        Btn2(false);
                        Btn3(false);
                        Btn4(false);
                        Btn5(false);
                        Btn6(false);
                        Btn7(false);
                        Btn8(false);
                        Btn9(false);
                        Btn10(true);
                        Btn11(false);
                        Btn12(false);
                        Btn13(false);
                        Btn14(false);
                        Tip4(true);
                    } }
                    ></div>
                    <div className='Pos Boton Boton11'
                        onClick={()=> {
                        Cierre(true);
                        Btn1(false);  
                        Btn2(false);
                        Btn3(false);
                        Btn4(false);
                        Btn5(false);
                        Btn6(false);
                        Btn7(false);
                        Btn8(false);
                        Btn9(false);
                        Btn10(false);
                        Btn11(true);
                        Btn12(false);
                        Btn13(false);
                        Btn14(false);
                        Tip4(true);
                    } }
                    ></div>
                    <div className='Pos Boton Boton12'
                        onClick={()=> {
                        Cierre(true);
                        Btn1(false);  
                        Btn2(false);
                        Btn3(false);
                        Btn4(false);
                        Btn5(false);
                        Btn6(false);
                        Btn7(false);
                        Btn8(false);
                        Btn9(false);
                        Btn10(false);
                        Btn11(false);
                        Btn12(true);
                        Btn13(false);
                        Btn14(false);
                        Tip5(true);
                    } }
                    ></div>
                    <div className='Pos Boton Boton13'
                        onClick={()=> {
                        Cierre(true);
                        Btn1(false);  
                        Btn2(false);
                        Btn3(false);
                        Btn4(false);
                        Btn5(false);
                        Btn6(false);
                        Btn7(false);
                        Btn8(false);
                        Btn9(false);
                        Btn10(false);
                        Btn11(false);
                        Btn12(false);
                        Btn13(true);
                        Btn14(false);
                        Tip5(true);
                    } }
                    ></div>
                    <div className='Pos Boton Boton14'
                        onClick={()=> {
                        Cierre(true);
                        Btn1(false);  
                        Btn2(false);
                        Btn3(false);
                        Btn4(false);
                        Btn5(false);
                        Btn6(false);
                        Btn7(false);
                        Btn8(false);
                        Btn9(false);
                        Btn10(false);
                        Btn11(false);
                        Btn12(false);
                        Btn13(false);
                        Btn14(true);
                        Tip5(true);
                    } }
                    ></div>
                    <h4 className='Pos SubTitulo2 Peso '>Peso del perro adulto<span className='Kg'> 1 a 3 kg</span></h4>
                    <h4 className='Pos SubTitulo2 Peso1'>Peso del perro adulto<span className='Kg'> 3 a 10 kg</span></h4>
                    <h4 className='Pos SubTitulo2 Peso2'>Peso del perro adulto<span className='Kg'> 10 a 25 kg</span></h4>
                    <h4 className='Pos SubTitulo2 Peso3'>Peso del perro adulto<span className='Kg'> 25 a 45 kg</span></h4>
                    <h4 className='Pos SubTitulo2 Peso4'>Peso del perro adulto<span className='Kg'> 45 a 100 kg</span></h4>
                    <p className='Pos Lectura'>
                        Los perros considerados para elaborar esta gráfica forman parte de las  
                        <em> razas puras</em>. Es decir, son animales cuya reproducción ha sido controlada por los 
                        seres humanos a lo largo de cierto tiempo para conseguir determinadas 
                        características. Algunas personas (llamadas <em>canófilas</em>) valoran dichos rasgos 
                        porque piensan que vuelven superiores a los perros de raza en comparación con 
                        los otros, llamados <em>híbridos</em> o <em>sin raza</em>. <br/><br/>
                        
                        Es importante que sepas que el perro es una 
                        especie con muchas variantes que no se reconocen
                        como razas. Si adoptas uno de ésos, su peso dependerá 
                        de sus genes y de su alimentación. <br/><br/>
                        
                        ¿Crees que un ser vivo tenga menos valor 
                        que otro? Coméntalo con tus compañeros cuando 
                        tengan un momento juntos.
                    </p>


                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                   <p className='TextImg'>
                   La información con la que se elaboró esta gráfica proviene de: 
                   <a className='link' href="https://www.expertoanimal.com/razas-de-perros.html" target="blank"> &lt;https://www.expertoanimal.com/razas-de-perros.html.&gt; </a>                      
                   Consultado el 20 de septiembre de 2021. <br/><br/>

                   Recuerda que es importante dar siempre el crédito por la información que utilizas en tus investigaciones.
                   </p>
                </Grid>
                <Grid container item style={{ marginTop: 80}} ></Grid>
            </Grid>

            <Grid container item className={classes.ContenedorPerritos} justifyContent="center" alignContent='center'>
                    <h2 className='Pos SubTitulo4 Tipo1'>
                        Peso del perro adulto 1 a 3 kg
                    </h2>
                    <h2 className='Pos SubTitulo4 Tipo2'>
                        Peso del perro adulto 3 a 10 kg
                    </h2>
                    <h2 className='Pos SubTitulo4 Tipo3'>
                        Peso del perro adulto 10 a 25 kg
                    </h2>
                    <h2 className='Pos SubTitulo4 Tipo4'>
                        Peso del perro adulto 25 a 45 kg
                    </h2>
                    <h2 className='Pos SubTitulo4 Tipo5'>
                        Peso del perro adulto 45 a 100 kg
                    </h2>
                    <p className='Pos SubTitulo5 Perro1'>Poodle toy</p>
                    <p className='Pos SubTitulo5 Perro2'>Morkie</p>
                    <p className='Pos SubTitulo5 Perro3'>Bulldog francés</p>
                    <p className='Pos SubTitulo5 Perro4'>Pastor de Shetland</p>
                    <p className='Pos SubTitulo5 Perro5'>Terrier escocés</p>
                    <p className='Pos SubTitulo5 Perro6'>Beagle</p>
                    <p className='Pos SubTitulo5 Perro7'>Border collie</p>
                    <p className='Pos SubTitulo5 Perro8'>Cocker spaniel inglés</p>
                    <p className='Pos SubTitulo5 Perro9'>Pastor alemán</p>
                    <p className='Pos SubTitulo5 Perro10'>Husky siberiano</p>
                    <p className='Pos SubTitulo5 Perro11'>Bóxer</p>
                    <p className='Pos SubTitulo5 Perro12'>Bloodhound</p>
                    <p className='Pos SubTitulo5 Perro13'>San Bernardo</p>
                    <p className='Pos SubTitulo5 Perro14'>Kuvasz</p>

                    <img className="Imagen Perro1" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion34/P (1).png" alt="Tarjeta" />
                    <img className="Imagen Perro2" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion34/P (2).png" alt="Tarjeta" />
                    <img className="Imagen Perro3" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion34/P (3).png" alt="Tarjeta" />
                    <img className="Imagen Perro4" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion34/P (4).png" alt="Tarjeta" />
                    <img className="Imagen Perro5" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion34/P (5).png" alt="Tarjeta" />
                    <img className="Imagen Perro6" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion34/P (6).png" alt="Tarjeta" />
                    <img className="Imagen Perro7" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion34/P (7).png" alt="Tarjeta" />
                    <img className="Imagen Perro8" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion34/P (8).png" alt="Tarjeta" />
                    <img className="Imagen Perro9" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion34/P (9).png" alt="Tarjeta" />
                    <img className="Imagen Perro10" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion34/P (10).png" alt="Tarjeta" />
                    <img className="Imagen Perro11" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion34/P (11).png" alt="Tarjeta" />
                    <img className="Imagen Perro12" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion34/P (12).png" alt="Tarjeta" />
                    <img className="Imagen Perro13" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion34/P (13).png" alt="Tarjeta" />
                    <img className="Imagen Perro14" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion34/P (14).png" alt="Tarjeta" />
                    <img className="Fondo Escritorio" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion34/5.png" alt="Tarjeta" /> 
                    <img className="Fondo Tablet" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion34/5R.png" alt="Tarjeta" /> 


                    <div className='Grande'
                    onClick={()=> {
                        Cierre(false);
                        Btn1(false);  
                        Btn2(false);
                        Btn3(false);
                        Btn4(false);
                        Btn5(false);
                        Btn6(false);
                        Btn7(false);
                        Btn8(false);
                        Btn9(false);
                        Btn10(false);
                        Btn11(false);
                        Btn12(false);
                        Btn13(false);
                        Btn14(false);
                        Tip1(false);
                        Tip2(false);
                        Tip3(false);
                        Tip4(false);
                        Tip5(false);
                    } }>
                    </div>
            </Grid>
            <Grid container item style={{ marginTop: 80}} ></Grid>
    </Grid>
    );
}
 
export default El_peso_de_los_perros;
