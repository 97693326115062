import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
    ContenedorLectura:{
        width:'502px',
                  [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
    },
    ContainerTitulo:{
        margin:'0 0 34px 0',
        [theme.breakpoints.down(1161)]:{
            margin:'0 0 34px 0',
        },
        '& .Titulo':{
            letterSpacing: '0px',
            color: '#D63390',
            margin:'0',
            textAlign:'center',
            font:'43px fredoka one',
            lineHeight:'52px',
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            margin:'0 0 36px 0',
            color:'#000',
            font:'18px lato',
            lineHeight :'28px',
            letterSpacing: '0.17px',
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .SubTitulo':{
            color:'#823CE2',
            margin:'0',
            lineHeight :'28px',
            font:'bold 26px lato',
            letterSpacing: '0.24px',
            [theme.breakpoints.down(502)]:{
                font:'bold 18px lato',
                lineHeight :'24px',
                letterSpacing: '0.21px',
            },
        },
        '& .Cuadro':{
            position:'relative',
        },
        '& .Esp2':{
            position:'absolute',
            margin:'53px 0 0 0',
            width:'85.25%',
            [theme.breakpoints.down(502)]:{
                margin:'18px 0 0 0',
            },
            [theme.breakpoints.down(350)]:{
                margin:'20px 0 0 0',
            },
        },
        '& .Imagen':{
            width:'100%',
        },   
    }
}));


const La_importancia_del_calcio = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>

            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >La importancia<br/> del calcio</h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container sm={12}>
                      <p className="Lectura "> 
                      ¿Alguna vez has notado que las almejas y los caracoles tienen un caparazón que los 
                      protege? Se trata de una capa compuesta de calcio. El calcio es un mineral que 
                      abunda en el agua de los ríos y lagos, especialmente en las rocas y el coral. Pero 
                      ¿sabías que también protege tu cuerpo? A continuación, conocerás algunos de sus 
                      beneficios.
                      </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img3ro/Sesion17/1.png" alt="Tarjeta" />
                    </Grid>
                </Grid>
               
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container sm={12}>
                        <p className="Lectura "> 
                            <h3 className='SubTitulo'>Los aportes del calcio</h3><br/>
                        
                            El calcio aporta muchas cosas a los seres humanos. Por ejemplo, brinda apoyo en las
                            funciones de algunos órganos y mantiene fuertes los huesos. Gracias a esto, somos 
                            capaces de desempeñar múltiples actividades, como correr, caminar, brincar y jugar. 
                            ¡Es tan importante que en el esqueleto se concentra 99 % de todo el calcio que existe 
                            en el organismo! <br/><br/>
                            
                            En el sistema nervioso ayuda a transmitir al cerebro la información que obtenemos 
                            de nuestro entorno. También favorece que los músculos y los vasos sanguíneos se 
                            contraigan y se relajen. Al hacer esto, lanzan hormonas y enzimas que viajan por 
                            todo el cuerpo y envían mensajes a través de la sangre. <br/><br/>
                            
                            Como habrás notado, este mineral es esencial para la salud. Pero ¿de dónde se 
                            obtiene? Se encuentra en alimentos como la leche, el aguacate, el huevo, los nopales, 
                            el maíz y algunos pescados, como los charales y las sardinas.
                        </p>
                    </Grid>
                    <Grid container item justifyContent='center' sm={12}>
                        <Grid container item justifyContent='center' className='Cuadro' sm={12}>
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img3ro/Sesion17/2R.png" alt="Tarjeta" />
                            <p className='Lectura Esp2'>
                                La falta de calcio ocasiona <em>osteoporosis</em>, una enfermedad que cambia la masa
                                de los huesos y los hace débiles, especialmente vulnerables a las fracturas.
                                Para evitar esta situación, hay que hacer ejercicio 30 minutos al día y 
                                alimentarnos con productos naturales, pues esto facilita la absorción del calcio.
                            </p>
                        </Grid>
                    </Grid>
                </Grid>
                
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container item justifyContent='center' sm={12}>
                        <Grid container sm={12}>
                            <p className="Lectura "> 
                                Otra sustancia que ayuda a aprovechar el calcio es la vitamina D. Cuando ésta falta, 
                                no se producen las cantidades suficientes de una hormona llamada <em>calcitriol</em>, 
                                conocida como la <em>vitamina D activa</em>. Esto ocasiona que el calcio de los alimentos no se 
                                absorba. Entonces, el cuerpo tiene que usar las reservas de calcio que hay en el 
                                esqueleto y eso lo debilita. <br/><br/>
                                
                                Nuestro organismo produce vitamina D cuando estamos bajo la luz del sol y cuando 
                                ingerimos ciertos alimentos y suplementos. Por eso, ahora sabes que es necesario 
                                salir y estar en contacto con los rayos solares. ¡Recuerda hacerlo con moderación y
                                medidas de protección!
                            </p>
                        </Grid>
                    </Grid>
                </Grid>
        </Grid>
    </Grid>
    );
}
 
export default La_importancia_del_calcio;
