import React, { useContext } from "react";
import { Grid, makeStyles, Button } from "@material-ui/core";
import logoDemo from "../../../imgs/CL_LOGO_FINAL_Login 1.png";
import logoPea from "../../../imgs/logoPea.png";
import { Link, useHistory } from "react-router-dom";
import AuthContext from "../../../../auth/AuthContext";
import axios from "../../../../services/axios";
import ModalMessage from "../../../Modals/ModalMessage";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    minHeight: "100vh",
    height: "100%",
    background: (props) => (props.randomColor ? props.randomColor : "#65EAE4"),
    [theme.breakpoints.up("xl")]: {
      alignContent: "center",
    },
  },

  ContainerLogin: {
    // padding: "0 25px",
    // height: "975px",
  },
  ContainerLogoClLeft: {
    display: "flex",
    // alignItems: "center",
    // marginBottom: "10%",
    // justifyContent: "center",
    [theme.breakpoints.up("lg")]: {
      // marginBottom: "20%",
      marginBottom: "0%",
    },
  },
  LogoClLeft: {
    width: "50%",
    maxWidth: "483px",
    [theme.breakpoints.up("lg")]: {
      width: "40%",
    },
    [theme.breakpoints.up("xl")]: {
      width: "60%",
    },
  },
  logoP: {
    height: "98px",
    width: "104px",
    [theme.breakpoints.up("sm")]: {
      marginTop: "10%",
    },
  },
  containerRight: {
    gap: "15px",
    [theme.breakpoints.up("md")]: {
      gap: "0px",
    },
    [theme.breakpoints.up("xl")]: {
      gap: "30px",
      // alignContent: "center",
      maxHeight: "1440px",
    },
  },
  loginText: {
    font: "36px Fredoka One ",
    margin: "0",
    maxWidth: "80%",
    color: (props) => (props.textColor ? props.textColor : "#FFFFFF"),
    [theme.breakpoints.up("lg")]: {
      fontSize: "48px",
    },
  },

  inputLoginUser: {
    width: "418px",
    height: "81px",
    paddingLeft: "27px",
    borderRadius: "61px",
    border: " solid #1D2AAD 3px ",
    background: "#FFFFFF",
    font: "Helvetica",
    fontSize: "18px",
    textAlign: "left",
    "&:focus": {
      outline: "none",
    },
  },

  inputNewPassword: {
    width: "80%",
    maxWidth: "320px",
    height: "40px",
    paddingLeft: "27px",
    borderRadius: "61px",
    border: "solid #F3CD52 3px ",
    background: "#FFFFFF",
    font: "Helvetica",
    "&:focus": {
      outline: "none",
    },
    [theme.breakpoints.up("xl")]: {
      height: "81px",
      maxWidth: "421px",
      fontSize: "18px",
    },
  },
  indicationText: {
    font: " bold 16px Lato",
    textAlign: "justify",
    width: "80%",
    margin: 0,
    color: (props) => (props.smallText ? props.smallText : "#FFFFFF"),
    maxWidth: "448px",
    [theme.breakpoints.up("xl")]: {
      // height: "81px",
      fontSize: "28px",
    },
  },

  forgottenPassword: {
    color: "#FFFFFF",
    font: "20px Lato",
    letterSpacing: "0.36px",
  },
  ContainerButtons: {
    gap: "15px",
  },
  buttonRecoveryStudent: {
    width: "80%",
    maxWidth: "320px",
    borderRadius: "61px",
    border: "none",
    background: (props) =>
      props.colorButtonStudent ? props.colorButtonStudent : "#F3CD52",
    color: "#353535",
    font: " bold Lato",
    fontSize: "16px",
    letterSpacing: "1.96px",
    textTransform: "none",
    textDecoration: "none",
    textAlign: "center",
    padding: "5px 0",
    fontWeight: "500",
    // minHeight: "50px",
    "&:hover": {
      background: (props) =>
        props.colorButtonStudent
          ? `${props.colorButtonStudent}70`
          : "#F3CD5280",
      fontWeight: "bold",
    },
    [theme.breakpoints.up("lg")]: {
      padding: "10px 0",
      fontSize: "18px",
    },

    [theme.breakpoints.up("xl")]: {
      padding: "20px 0",
      fontSize: "22px",
      maxWidth: "450px",
    },
  },

  buttonRecoveryAdmin: {
    width: "80%",
    maxWidth: "320px",
    borderRadius: "61px",
    // height: "50px",
    border: "none",
    backgroundColor: (props) =>
      props.colorButtonAdmin ? props.colorButtonAdmin : "#3F42B3",
    color: (props) =>
      props.colorButtonAdmin === "#3F42B3" ? "#FFFFFF" : "#353535",
    font: " bold Lato",
    fontSize: "16px",
    letterSpacing: "1.96px",
    textTransform: "none",
    textDecoration: "none",
    textAlign: "center",
    padding: "5px 0",
    fontWeight: "500",
    // minHeight: "50px",
    "&:hover": {
      backgroundColor: (props) =>
        props.colorButtonAdmin ? `${props.colorButtonAdmin}70` : "#3F42B380",
      fontWeight: "bold",
    },
    [theme.breakpoints.up("lg")]: {
      padding: "10px 0",
      fontSize: "18px",
    },

    [theme.breakpoints.up("xl")]: {
      padding: "20px 0",
      fontSize: "22px",
      maxWidth: "450px",
    },
  },
  buttonReturn: {
    width: "80%",
    maxWidth: "320px",
    borderRadius: "61px",
    // height: "50px",
    border: "none",
    backgroundColor: "#FFFFFF",
    color: "#353535",
    font: " bold Lato",
    fontSize: "16px",
    letterSpacing: "1.96px",
    textTransform: "none",
    textDecoration: "none",
    textAlign: "center",
    padding: "5px 0",
    fontWeight: "500",
    // minHeight: "50px",s
    "&:hover": {
      backgroundColor: "#FFFFFF",
      fontWeight: "bold",
    },
    [theme.breakpoints.up("lg")]: {
      padding: "10px 0",
      fontSize: "18px",
    },

    [theme.breakpoints.up("xl")]: {
      padding: "20px 0",
      fontSize: "22px",
      maxWidth: "450px",
    },
  },

  rightsReserved: {
    color: (props) => (props.smallText ? props.smallText : "#FFFFFF"),
    font: "14px Lato",
    letterSpacing: "0.53px",
    maxWidth: "80%",
    [theme.breakpoints.up("lg")]: {
      fontSize: "16px",
    },
    [theme.breakpoints.up("xl")]: {
      fontSize: "22px",
    },
  },
}));

const NewPassword = (props) => {
  const {
    user: { passwordChange },
  } = useContext(AuthContext);
  const backgroundColors = [
    "#D63390",
    "#F6644B",
    "#F3CD52",
    "#65EAE4",
    "#591AAF",
    "#091590",
  ];
  const loginBotton = [
    "#65EAE4",
    "#65EAE4",
    "#65EAE4",
    "#F3CD52",
    "#65EAE4",
    "#65EAE4",
  ];
  const colorButtonStudentProfile = [
    "#F3CD52",
    "#F3CD52",
    "#65EAE4",
    "#F3CD52",
    "#F3CD52",
    "#F3CD52",
  ];
  const colorButtonAdminProfile = [
    "#3F42B3",
    "#3F42B3",
    "#3F42B3",
    "#3F42B3",
    "#65EAE4",
    "#65EAE4",
  ];
  const lgColors = [
    "#F3CD52",
    "#F3CD52",
    "#D63390",
    "##591AAF",
    "#F3CD52",
    "#F3CD52",
  ];
  const txtColors = [
    "#FFFFFF",
    "#FFFFFF",
    "#575757",
    "#575757",
    "#FFFFFF",
    "#FFFFFF",
  ];

  const atRandom = parseInt(Math.random() * 6);

  const [randomColor] = React.useState(backgroundColors[atRandom]);
  const [colorButtonStudent] = React.useState(
    colorButtonStudentProfile[atRandom]
  );
  const [colorButtonAdmin] = React.useState(colorButtonAdminProfile[atRandom]);
  const [loginBottonColor] = React.useState(loginBotton[atRandom]);
  const [textColor] = React.useState(lgColors[atRandom]);
  const [smallText] = React.useState(txtColors[atRandom]);
  const [password, setPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");
  const [passwordHasChanged, setPasswordHasChanged] = React.useState(false);

  const [open, setOpen] = React.useState(false);
  const [textModal, setTextModal] = React.useState("");
  const history = useHistory();
  const dynamicYear = new Date().getFullYear();
  const classes = useStyles({
    randomColor,
    textColor,
    loginBottonColor,
    colorButtonStudent,
    colorButtonAdmin,
    smallText,
  });

  const handlePassword = (e) => {
    setPassword(e.target.value);
  };

  const handleConfirmPassword = (e) => {
    setConfirmPassword(e.target.value);
  };

  const checkPassword = (password) => {
    const parameters = /^(?=\w*\d)(?=\w*[A-Z])(?=\w*[a-z])\S{8,16}$/;
    // const parameters = /^(?=\w*\d)(?=.*[!@#$&*])(?=\w*[A-Z])(?=\w*[a-z])\S{8,16}$/;
    const result = parameters.test(password);
    return result;
  };

  const handleClose = () => {
    setOpen(false);
  };

  const navigate = () => {
    history.push("/");
  };

  const changePassword = () => {
    const body = {
      id_usuario: passwordChange.id_usuario,
      id_recuperacion: passwordChange.id_recuperacion,
      contraseña: password,
    };

    let verificatePassword = checkPassword(password);
    if (password === confirmPassword) {
      if (verificatePassword) {
        axios
          .put("/user/info/password/set", body)
          .then((response) => {
            if (response.status === 200) {
              setPassword("");
              setConfirmPassword("");
              setTextModal(
                "Contraseña actualizada con éxito. Ve a login para iniciar sesión"
              );
              setOpen(true);
              setPasswordHasChanged(true);
            }
          })
          .catch((error) => {
            setTextModal(
              "El código que has introducido es incorrecto o a expirado, revísalo e inténtalo nuevamente."
            );
            setOpen(true);
          });
      } else {
        setTextModal(
          "Verificar que la nueva contraseña incluya ocho caracteres con un número y una mayúscula."
        );
        setOpen(true);
      }
    } else {
      setTextModal("Las contraseñas no coinciden, inténtalo de nuevo");
      setOpen(true);
    }
  };

  return (
    <Grid container className={classes.root} justifyContent="center">
      <Grid container item className={classes.ContainerLogin}>
        <Grid
          container
          item
          sm={6}
          direction="column"
          justifyContent="center"
          alignItems="center"
          className={classes.ContainerLogoClLeft}
        >
          {/* <Grid item className={classes.ContainerLogoClLeft}> */}
          <img src={logoDemo} alt="logoDemo" className={classes.LogoClLeft} />
          {/* </Grid> */}
        </Grid>
        <Grid
          container
          item
          sm={6}
          direction="column"
          justifyContent="space-around"
          alignItems="center"
          className={classes.containerRight}
        >
          <img src={logoPea} alt="logoP" className={classes.logoP} />

          <>
            <h1 className={classes.loginText}>¡Bienvenido!</h1>
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
              alignContent="space-evenly"
              className={classes.ContainerButtons}
            >
              <p className={classes.indicationText}>
                Escribe tu nueva contraseña y guárdala en un lugar seguro
              </p>
              <input
                type="password"
                className={classes.inputNewPassword}
                placeholder="Ingrese nueva contraseña"
                onChange={handlePassword}
                value={password}
                disabled={passwordHasChanged}
              />
              <input
                type="password"
                className={classes.inputNewPassword}
                placeholder="Ingrese confirmación de contraseña"
                onChange={handleConfirmPassword}
                value={confirmPassword}
                disabled={passwordHasChanged}
              />
              <Button
                className={classes.buttonRecoveryAdmin}
                onClick={changePassword}
                disabled={passwordHasChanged}
              >
                Cambiar contraseña
              </Button>
            </Grid>
          </>

          <Link
            to={!passwordHasChanged ? "/password_recovery" : "/"}
            className={classes.buttonReturn}
          >
            Atrás
          </Link>

          <p className={classes.rightsReserved}>
            Derechos reservados y legales {dynamicYear} por Pearson
          </p>
        </Grid>
      </Grid>

      <ModalMessage
        open={open}
        handleClose={handleClose}
        title={textModal}
        valid={passwordHasChanged}
        navigate={navigate}
        textOfButton="Aceptar"
      />
    </Grid>
  );
};

export default NewPassword;
