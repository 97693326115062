import { makeStyles } from "@material-ui/core";
import React from "react";
import Chart from "react-apexcharts";
// import ApexCharts from "apexcharts";
import DownloadSelect from "../pages/Docente/Reportes/descargas/DownloadSelect";
const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    alignContent: "center",
    flexWrap: "nowrap",
    overflowX: "auto",
    overflowY: "hidden",
    [theme.breakpoints.up("md")]: {
      justifyContent: "center",
    },
  },
  Chart: {
    maxWidth: "800px",
    height: "380px",
    opacity: "0.9",
    width: "100%",
    minWidth: "320px",
  },
}));

const ReporteFallos = (props) => {
  const { skillsData, downloadData } = props
  const classes = useStyles();
  // const [data, setData] = React.useState([]);

  const options = {
    series : [
      {
        name: "Porcentaje de aciertos",
        data: 
        skillsData.map((data)=>{
          return(
            data.porcentajePositiva
          )
        }),
      },
      {
        name: "Porcentaje de fallos",
        data:
        skillsData.map((data)=>{
          return(
            data.porcentajeNegativa
          )
        }), 
      }],
    chart: {
      type: "bar",
      id: "reactchart",
      width: 800,
      height: 498,
      stacked: true,
      stackType: '100%',
      colors: "fff",
      toolbar: {
        show: true,
        offsetX: 35,
        offsetY: 210,
        tools: {
          download: true,
          selection: true,
          // zoom: true,
          // zoomin: true,
          // zoomout: true,
          // customIcons: [],
        },
      },
    },
    colors: ["#00cc00", "#ff0000"],
    plotOptions: {
      bar: {
        horizontal: true,
      },
    },
    stroke: {
      width: 1,
      colors: ["#000"],
    },
    xaxis: {
        categories : 
          skillsData.map((data)=>{
            return(
              data.descripcion
            )
          }),
      width: "100%",
      tickPlacement: "between",
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return val + "%";
        },
      },
    },
    legend: {
      position: "top",
      horizontalAlign: "left",
      offsetX: 40,
    },
  };
  // const series = [
  //   {
  //     name: "Porcentaje de aciertos",
  //     data: 
  //     skillsData.map((data)=>{
  //       return(
  //         data.porcentajePositiva
  //       )
  //     }),
  //   },
  //   {
  //     name: "Porcentaje de fallos",
  //     data:
  //     skillsData.map((data)=>{
  //       return(
  //         data.porcentajeNegativa
  //       )
  //     }), 
  //   },
  // ];

  return (
    <div className={classes.container}>
      {skillsData && <Chart options={options} series={options.series} type="bar" className={classes.Chart} height={"100%"} />}
      <DownloadSelect
        downloadData={downloadData}
        className={classes.button}
      />
    </div>
  );
};

export default ReporteFallos;
