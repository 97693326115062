import  React from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import { GridCloseIcon } from "@mui/x-data-grid";
import { makeStyles } from "@material-ui/core";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    // padding: theme.spacing(2),

    [theme.breakpoints.up("sm")]: {
      // margin: "20px 0",
      paddingTop: "0",
      paddingBottom: "0",
    },
  },
  "& .MuiDialog-paper": {
    borderRadius: "12.1px",
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
    display: "flex",
    justifyContent: "center",
    gap: "5%",
    marginBottom: "16px",
  },
  "& .MuiDialogTitle-root": {
    [theme.breakpoints.up("sm")]: {
      // margin: "20px 0",
      padding: "22px",
    },
  },
}));

const useStyles = makeStyles((theme) => ({
  containerContent: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    padding: "20px",
    gap: "10px",
    boxSizing: "border-box",
    [theme.breakpoints.up("sm")]: {
      // margin: "20px 0",
    },
  },
  containerButtons: {
    display: "flex",
    justifyContent: "center",
  },
  button: {
    font: "bold 14px lato ",
    display:'flex',
    textAlign:'center',
    justifyContent:'center',
    alignItems:'center',
    boxSizing: "borderBox",
    outline: "0",
    border: "0",
    color: "#FFFFFF",
    backgroundColor: "#9051b1",
    borderRadius: "30px",
    textTransform: "unset",
    textDecoration:"none",
    height:'40px',
    width: "50%",
    maxWidth: "130px",
    "&:hover": {
    backgroundColor: "#B965D3 ",
    },
  },
  textGreen: {
    color: "#00c851",
    fontFamily: "Lato",
    fontSize: "20px",
    textAlign: "center",
    margin: "0",
    fonWweight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
  },
  text: {
    // width: "364px",
    // height: "36px",
    // margin: "11px 63.7px 18px 35.3px",
    fontFamily: "Lato",
    margin: 0,
    // fontFamily: "Fredoka One",
    fontSize: "16px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "0.23px",
    textAlign: "center",
    color: "#000",
    [theme.breakpoints.up("lg")]: {
      fontSize: "20px",
      // margin: "20px",
      // fontWeight: "600",
      maxWidth: "40ch",
    },
  },
  title: {
    fontFamily: "Fredoka One",
    margin: 0,
    fontSize: "24px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "0.23px",
    textAlign: "center",
    // marginBottom: "20px",
    color: "#000",
    [theme.breakpoints.up("lg")]: {
      fontSize: "26px",
      // margin: "10px",
    },
  },

  user: {
    fontFamily: "lato",
    margin: 0,
    fontSize: "18px",
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 4 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <GridCloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const ModalEscuelaCargada = ({ open, handleClose, dataPlatorma }) => {
  const classes = useStyles();
  return (
    <>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        maxWidth="md"
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        ></BootstrapDialogTitle>
        <DialogContent>
          <div className={classes.containerContent}>
            <div>
            <CheckCircleIcon style={{color:'#00c851', fontSize:'50px'}}></CheckCircleIcon>

            </div>
            <p className={classes.textGreen}>Estos son los datos de la plataforma</p> 
            {/* <p className={classes.user}><b>Nombre:</b>{dataPlatorma?.nombre}</p><br/> */}
            <p className={classes.user}><b>Llave de consumidor:</b><br/>{dataPlatorma?.llave}</p><br/>
            <p className={classes.user}><b>Secreto:</b><br/>{dataPlatorma?.llave}</p><br/>
            {/* <p className={classes.user}><b>Correo de contacto:</b>{dataPlatorma?.correo}</p> */}

            <p className={classes.text}>
              Envía la llave de consumidor y el secreto para que la escuela comience a usar el
              <b> Circuito de lectores</b>.
            </p>
          </div>
        </DialogContent>
        <DialogActions disableSpacing={true} className={classes.containerButtons}>
            <a className={classes.button} href="/admin/LMS">Aceptar</a>
        </DialogActions>
      </BootstrapDialog>
    </>
  );
};
export default ModalEscuelaCargada;
