import React               from "react";
import {Grid, makeStyles}                 from '@material-ui/core';

const useStyles = makeStyles(theme =>({
  questionContainer:{
    width: "100%",
    height:"100%",
  },
  reading: {
    margin:"0",
    font: '22px lato',
    letterSpacing: '0.21px',
    [theme.breakpoints.down(1400)]:{
      font: " 18px lato",
      width: "95%",
    },
    [theme.breakpoints.down(1310)]:{
      font: "500 16px lato",

    },
    [theme.breakpoints.down(925)]:{
      font: "500 14px lato",

    },
    [theme.breakpoints.down(426)]:{
      font: "500 12px lato",
 
    }, 
  },
  imgStyle: {
    alignItems: "center",
    width:'80%',
    height:'80%',
    [theme.breakpoints.down(1400)]:{
      width:'85%',
      height:'85%',
    },
    [theme.breakpoints.down(502)]:{
      width:'100%',

    },
  },
  table: {
    width: "100%",
    font: '22px lato',
    [theme.breakpoints.down(1400)]:{
      font: " 18px lato",
      width: "95%",
    },
    [theme.breakpoints.down(1310)]:{
      font: "500 16px lato",
 
    },
    [theme.breakpoints.down(925)]:{
      font: "500 14px lato",

    },
    [theme.breakpoints.down(426)]:{
      font: "500 12px lato",

    }, 
  },
  tdTable: {
    width:'20%',
    padding: "0 1% 1% ",
    textAlign:'center',
    [theme.breakpoints.down(502)]:{
      width:'24%',
    },
    // border: '1px solid black',
  }
}));

const Question = (props) => {
  const classes = useStyles()
  const { picturesTable } = props


  return(
    <div container className={classes.questionContainer}>
      <p 
        // dangerouslySetInnerHTML={{__html: `${step+1}. ${pregunta.replace( '\n', '<br/><br/>')}`}}
        className={classes.reading}
      >
        8. ¿Qué otras estrategias elegirías para disminuir los desechos y consecuencias ocasionadas por la moda rápida?
      </p>
      <br/>
      <table valign="middle" className={classes.table}>
        <tbody valign="middle">
        <tr>
            <td valign="middle" className={classes.tdTable}>
                a. quemarla
            </td>
            <td valign="middle" className={classes.tdTable}>
                b. hacer juguetes
            </td>
            <td valign="middle" className={classes.tdTable}>
                c. personalizarla
            </td>
            <td valign="middle" className={classes.tdTable}>
                d. tirarla
            </td>
          </tr>
          <tr>
            {
              picturesTable.map((picture) => {
                // console.log(picture)
                return(
                  <td valign="middle" className={classes.tdTable}>
                    <Grid container item justifyContent="center">
                      <img alt="img" src={picture} className={classes.imgStyle}/>
                    </Grid>
                  </td>
                )
              })
            }
          </tr>
        </tbody>
      </table>
    </div>
  )

}

export default Question;