import React                        from "react";
import { Button,makeStyles,Grid}    from "@material-ui/core";

const useStyles = makeStyles(theme=>({
  welcomePageContainer: {
    background:'#FFFDFD',
    height:'calc(100vh - 79.5px)',
  },
  ContainerIzquierdo:{
    width:'97%',
    [theme.breakpoints.down(1160)]:{
      width:'100%',
    },
  },
  ContainerEstrategi:{
    position:'relative',
    width:'100vw',
    height:'calc(100vh - 79.5px - 79.5px)',
    overflow: 'auto',
  },
  ContainerSubHabilidades:{
    background:'none',
    boxSizing:'border-box',
    cursor:'pointer',
    textAlign:'center',
    display:'flex',
    padding:'43px 17px 9px 17px',
    alignItems:'center',
    position:'absolute',
    height:'40px',
    width:'62px',
    right:'3%',
    zIndex:1,
    [theme.breakpoints.down(1281)]:{
      left:'calc(50% - 31px)',
      padding:'0 0 0 62px',
    },
    [theme.breakpoints.down(600)]:{
      left:'0',
      padding:'0 15px 0 55px',
    },
        '& .Habilidades':{
      color:'#575757',
      font: 'normal 16px Lato',
      letterSpacing: '0.13px',
      display:'none',
      [theme.breakpoints.down(1281)]:{
        padding:'0 ', 
        margin:'0',
        font: ' 13px Lato',
      },
      [theme.breakpoints.down(600)]:{
        padding:'0 ', 
        margin:'0',
        font: ' 11px Lato',
      },
    },
    '&:hover':{
      border:' 5px solid #F26973',
      borderRadius: '20px 0px 20px 20px',
      background:'#fff',
      height:'215px',
      width:'233px',
      [theme.breakpoints.down(1281)]:{
        height:'75px',
        width:'350px',
        borderRadius: '0px 0px 20px 20px',
      },
      [theme.breakpoints.down(600)]:{
        height:'75px',
        width:'100%',
      },

      '& .Habilidades':{
        display:'block',
      },
      '&:before':{
        borderTop:' 0px ',
        borderRight:'0px'
      },
    },
    '&:before':{
      display:'flex',
      justifyContent:'center',
      alignItems:'center',
      content: '"IC"',
      position: 'absolute',
      right: '0',
      top: '0',
      font: '20px Fredoka One',
      background: '#EE667A 0% 0% no-repeat padding-box',
      color: '#FFF',
      border:' 3px solid #F2624F',
      borderRadius: '0px 0px 20px 20px',
      height: '40px',
      width: '62px',
      [theme.breakpoints.down(1281)]:{
        left: '-5px',
        top: '0',
      },
      [theme.breakpoints.down(600)]:{
        height: '30px',
        width: '52px',
        font: '18px Fredoka One',
      },

    },
  },

  Estrategia:{
    margin:'0px',
    font:'38px Fredoka One',
    textAlign: 'center',
    letterSpacing: '0.28px',
    color: '#20A698',
    opacity: '1',
    [theme.breakpoints.down(1161)]:{
      font:'28px Fredoka One',
    },
    [theme.breakpoints.down(600)]:{
      font:'24px Fredoka One',
    },
  },

   TextStrg:{
      margin:'0', 
      font:'normal  22px Lato',
      letterSpacing: '0.10px',
      color: '#575757',
      opacity: 1,
      padding:'0 0 40px 0',
      boxSizing:'border-box',
      width:'760px',
      [theme.breakpoints.down(1161)]:{
        width:'650px',
        font:'normal  18px Lato',
      },
      [theme.breakpoints.down(600)]:{
        font:'normal  16px Lato',
        width:'325px',
      },
      [theme.breakpoints.down(325)]:{
        font:'normal  14px Lato',
        width:'300px',
      },
  },

   Titulo:{
    position:'absolute',
    top:'58px',
    [theme.breakpoints.down(1161)]:{
      top:'80px',
    },
  },
  Objet1:{
    position:'absolute',
    top:'130px',
    '& .Td':{
      background:'#fff',
      border:'solid 1.5px #000',
      width:'300px',
      font:' 20px lato',
      color: '#575757',
      padding:'0 0 5px 10px',
      boxSizing:'border-box',
      [theme.breakpoints.down(1161)]:{
        font:'18px lato',
        width:'300px',
      },
      [theme.breakpoints.down(600)]:{
        font:'16px lato',
        width:'150px',
      },
    },
    '& .Td2':{
      font:'bold  22px lato',
      [theme.breakpoints.down(1161)]:{
        font:'bold 18px lato',

      },
      [theme.breakpoints.down(600)]:{
        font:'bold 16px lato',
      },
    },
    '& .Td3':{
      height:'68px',
    },
    '& .Br':{
      margin:'0 0 20px 0'
    },
    '& .ul':{
      margin:'22px 0',
      padding:'0 0 0 22px',
    },
    '& .li':{
      marginBottom:'10px',
    },
   '& .Amarillo':{
    textAlign:'center',
    height:'42px',
    background:'#F7DF92',
    padding:'0 ',
   },
   '& .Verde':{
    textAlign:'center',
    height:'42px',
    background:'#84E9DF',
    padding:'0 ',
   },
   '& .Imagen':{
      height:'100px',
      width:'100px',
      margin:'5% 0 0 30%',
      [theme.breakpoints.down(600)]:{
        margin:'5% 0 0 10%',
      },
   },
  },


  ContainerBarraInferior:{
    position:'relative',
    background:'#F3CD52',
    height:'79.51px',
    [theme.breakpoints.up(1920)]:{
      height:'8vh',
    },
    [theme.breakpoints.down(1161)]:{
      background: 'linear-gradient(280deg, #D3348F 0%, #F1CB5A 100%) 0% 0% ',
    },
  },

  ContainerBarraDerecha:{
    background: 'linear-gradient(180deg, #D3348F 0%, #F1CB5A 100%) 0% 0%',
    width:'3%',
    height:'100vh',
    [theme.breakpoints.down(1161)]:{
      display:'none',
    },
  },
  
   BotonAvanza:{
    position:'absolute',
    bottom:'52px',
    right:'134px',
    textTransform:'none',
    textDecoration:'none',
    height:'62px',
    width:'279px',  
    background: '#7A54BA',
    boxShadow: '0px 3px 6px #00000029',
    borderRadius: '61px',
    color: '#FFFFFF',
    font: 'bold 20px lato ',
    '&:hover':{
        background: '#dd78fb',
      },
    [theme.breakpoints.down(1161)]:{
      position:'relative',
      bottom:'32px',
      left:'0',
      right:'0',
    },
  },

    
}));

const ReadingStrategys = (props) => {
  const classes = useStyles();
  const {activeStep, setActiveStep, handleNextStep} = props

  const handleContinue = () => {
    setActiveStep(activeStep + 1)
    handleNextStep()
  }

  return(

    <Grid  container direction="column" item sm={12} className={classes.welcomePageContainer} >
<div className={classes.ContainerSubHabilidades}>
          <p className="Habilidades">Comprender cómo se relaciona una idea secundaria con la idea principal del texto.</p>
      </div>
      <Grid container item sm={12} justifyContent="center" className={classes.ContainerIzquierdo} >

        <Grid container item className={classes.ContainerEstrategi } >

              <Grid container item justifyContent="center" alignItems="center" sm={12} className={classes.Titulo}> 
                <p className={classes.Estrategia} >Estrategia de lectura</p>
              </Grid>

              <Grid container item sm={12} alignItems="center" justifyContent="center" className={classes.Objet1}>
                  <p className={classes.TextStrg}>
                    Las ideas secundarias ayudan a resaltar las ideas principales de un texto, 
                    complementándolo con información adicional, imágenes, fechas, detalles, 
                    etcétera. Imagina un árbol de navidad. Éste es la idea principal, por sí solo
                    es muy bello, pero ¿qué pasa si se adorna el árbol? En este caso, los adornos 
                    representan las ideas secundarias.<br/><br/>

                    Cualquiera puede tener un árbol de navidad, pero con los adornos se agregan los 
                    detalles que lo hacen único. Lo mismo pasa con las ideas principales. Se pueden 
                    entender por sí solas, no necesitan nada más, pero agregar las secundarias da
                    más sentido al mensaje.<br/><br/>

                  <tr >
                    <tr >
                      <td valign="middle" className="Td Td2 Verde"><b>Idea principal</b>	</td>
                      <td valign="middle" className="Td Td2 Verde"><b>Ideas secundarias</b></td>
                    </tr>
                    <tr>
                      <td className="Td">
                        <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion10/1E.png" alt="Tarjeta" />
                      </td>
                      <td className="Td">
                        <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion10/2E.png" alt="Tarjeta" />
                      </td>
                    </tr>
                  </tr>
                    <ul className="ul">
                      <li className="li">Escoge un texto al azar que puedas rayar o marcar.</li>
                      <li className="li">Toma dos marcadores de distintos colores que más te gusten, hoja y un lápiz.</li>
                      <li className="li">Toma la hoja y haz un cuadro como el siguiente:</li>
                    </ul>

                  <tr >
                    <tr >
                      <td valign="middle" className="Td Td2 Verde"><b>Idea principal</b>	</td>
                      <td valign="middle" className="Td Td2 Verde"><b>Ideas secundarias</b></td>
                    </tr>
                    <tr>
                      <td className="Td Td3"></td>
                      <td className="Td Td3"></td>
                    </tr>
                  </tr>

                  <ul className="ul">
                    <li className="li">
                      Elige un color y subraya las ideas principales. Las identificarás con la pregunta:
                     ¿de qué trata el texto?
                    </li>
                    <li className="li">
                      Anota en el cuadro correspondiente de la hoja cada idea principal que <br/>
                      encontraste. Léelas con detenimiento.
                    </li>
                    <li className="li">
                      Al terminar, elige el otro color y relee el texto para que subrayes las ideas 
                      secundarias. Las identificarás como aquellas que detallan el tema principal con 
                      palabras, frases, imágenes, fechas, nombres y mapas que precisan, justifican o 
                      detallan el tema principal.
                    </li>
                    <li className="li">
                      Anota las ideas secundarias en la columna correspondiente de la tabla. 
                      Léelas en voz alta.
                    </li>
                    <li className="li">
                      Al final, analiza tu tabla. Tapa con una mano las ideas secundarias y lee las 
                      primarias en voz alta. ¿Crees que tendría sentido el texto si las ideas secundarias 
                      no existieran? ¿Por qué? ¿Qué le aportan las ideas secundarias al mensaje? 
                      Piénsalo muy bien.
                    </li>

                  </ul>

                    Al principio, puede parecer difícil. No te desanimes: “la práctica hace al maestro”, 
                    así que a seguir practicando.<br/><br/>

                    Es tiempo de seguir aprendiendo ¡Recuerda que tienes pendiente una cita con las
                    matemáticas! ¡Tienes que avanzar al siguiente nivel!

                  </p>
                  
              </Grid>
                
        </Grid>
        
        <Grid container item sm={12} className={classes.ContainerBarraInferior} justifyContent="center">
            <Button id="BotonContinuarEstrategias" className={classes.BotonAvanza} onClick={handleContinue}>
              Continuar
            </Button>
        </Grid>
        
      </Grid>

      <Grid container item sm={12} className={classes.ContainerBarraDerecha}></Grid>

    </Grid>

  )
}

export default ReadingStrategys;
