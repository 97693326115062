const bases = {
  prod: {
    main: {
      baseURL: "https://alb.cdl-pearson.com:3001/v1",
      headers: {
        "content-type": "application/json",
        "Access-Control-Allow-Origin":
          "http://cdl-pearson.com,https://cdl-pearson.com,https://alb.cdl-pearson.com:3001",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
      },
    },
    chat: {
      baseURL: "https://alb.cdl-pearson.com:3000",
    },
  },
  dev: {
    main: {
      baseURL: "https://elb.cdl-pearson.com:3001/v1",
      headers: {
        "content-type": "application/json",
        "Access-Control-Allow-Origin":
          "http://cdl-pearson.com,https://cdl-pearson.com,https://elb.cdl-pearson.com:3001",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
      },
    },
    chat: {
      baseURL: "https://elb.cdl-pearson.com:3000",
    },
  },
};

// Production: "prod"
// Development: "dev"
/* git  */

const currentEnvironment = bases.prod;
export default currentEnvironment;
  