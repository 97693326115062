import * as React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";

import { GridCloseIcon } from "@mui/x-data-grid";
import { Grid, makeStyles } from "@material-ui/core";

import ModalMessage from "./ModalMessage";
import { Link } from "react-router-dom";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    // padding: theme.spacing(2),

    [theme.breakpoints.up("sm")]: {
      // margin: "20px 0",
      paddingTop: "0",
      paddingBottom: "0",
    },
  },
  "& .MuiDialog-paper": {
    borderRadius: "12.1px",
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
    display: "flex",
    justifyContent: "center",
    gap: "5%",
    marginBottom: "16px",
  },
  "& .MuiDialogTitle-root": {
    [theme.breakpoints.up("sm")]: {
      // margin: "20px 0",
      padding: "22px",
    },
  },
}));

const useStyles = makeStyles((theme) => ({
  containerContent: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    marginBottom: "20px",
    gap: "20px",
    [theme.breakpoints.up("sm")]: {
      // margin: "20px 0",
    },
  },
  containerButtons: {
    display: "flex",
    justifyContent: "center",
  },
  text: {
    // width: "364px",
    // height: "36px",
    // margin: "11px 63.7px 18px 35.3px",
    fontFamily: "Lato",
    margin: 0,
    // fontFamily: "Fredoka One",
    fontSize: "16px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "0.23px",
    textAlign: "center",
    color: "#000",
    [theme.breakpoints.up("lg")]: {
      fontSize: "22px",
      margin: "20px",
      fontWeight: "600",
    },
  },
  title: {
    font: "24px Fredoka One ",
    margin: "0px auto",
    textAlign: "center",
    color: (props) => props.textColor,
    [theme.breakpoints.up("sm")]: {
      margin: "20px auto",
    },
    [theme.breakpoints.up("xl")]: {
      fontSize: "50px",
    },
  },
  welcomeText: {
    font: " bold 18px Lato",
    textAlign: "center",
    width: "100%",
    maxWidth: "448px",
    color: (props) => props.smallText,
    [theme.breakpoints.up("xl")]: {
      fontSize: "24px",
    },
  },

  button: {
    // backgroundColor: "#B965D3",
    // borderRadius: "4px",
    // boxSizing: "borderBox",
    // outline: "0",
    // border: "0",
    color: "#FFFFFF",
    // backgroundColor: "#9051b1",
    // borderRadius: "30px",
    // margin: "0 auto",
    // padding: "6px 16px",
    // width: "50%",
    // maxWidth: "180px",
    cursor: "pointer",
    textDecoration: "none",
    "&:hover": {
      // backgroundColor: "#B965D3 ",
    },
  },
}));

const PurpleButton = styled(Button)(({ theme }) => ({
  color: "#FFFFFF",
  backgroundColor: "#9051b1",
  borderRadius: "30px",
  // margin: "0 auto",

  textTransform: "unset",
  fontSize: "10px",
  padding: "6px 10px",
  width: "50%",
  maxWidth: "170px",
  [theme.breakpoints.up("sm")]: {
    fontSize: "12px",
    padding: "6px 10px",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "14px",
    padding: "6px 12px",
  },
  "&:hover": {
    backgroundColor: "#B965D3 ",
  },
}));
const WitheButton = styled(Button)(({ theme }) => ({
  color: "#9051b1",
  // borderColor: "#9051b1",
  border: "1px solid #9051b1",
  backgroundColor: "#FFFFFF",
  borderRadius: "30px",
  textTransform: "unset",
  // margin: "0 auto",
  fontSize: "10px",
  padding: "6px 10px",
  width: "50%",
  maxWidth: "160px",
  [theme.breakpoints.up("sm")]: {
    fontSize: "12px",
    padding: "6px 10px",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "14px",
    padding: "6px 12px",
  },
  "&:hover": {
    backgroundColor: "#B965D310 ",
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 4 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <GridCloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const TeacherRecoverySelectModal = ({ open, handleClose, nextStep }) => {
  const classes = useStyles();

  const [modalMessageOpen, setModalMessageOpen] = React.useState(false);

  const handleModalMessageOpen = () => {
    setModalMessageOpen(!modalMessageOpen);
  };

  return (
    <>
      <BootstrapDialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} maxWidth="sm" fullWidth={true}>
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}></BootstrapDialogTitle>
        <DialogContent>
          <Grid container justifyContent="center" alignItems="center">
            <Grid
              container
              item
              direction="column"
              justifyContent="space-around"
              alignItems="center"
              className={classes.containerContent}
            >
              <h1 className={classes.title}>¿Tienes un código de recuperación?</h1>
              {/* <p className={classes.text}></p> */}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions disableSpacing={true} className={classes.containerButtons}>
          <WitheButton onClick={nextStep} className={classes.button} variant="contained">
            Generar código
          </WitheButton>
          <PurpleButton onClick={handleClose} className={classes.button} variant="contained">
            <Link className={classes.button} to="/password_recovery/docente">
              Tengo un código
            </Link>
          </PurpleButton>
        </DialogActions>
      </BootstrapDialog>
      <ModalMessage open={modalMessageOpen} handleClose={handleModalMessageOpen}  />
    </>
  );
};
export default TeacherRecoverySelectModal;
