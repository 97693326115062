import React       from "react";
import  { makeStyles }     from '@material-ui/core';
import { OutlinedInput }        from "@material-ui/core";

const useStyles = makeStyles(theme =>({
  textFieldContainer:{
    margin: '3% 0 2% 0',
    width: "70%",
    maxHeight: "50%",
    background: "white",
    alignSelf:'center',
    font: '22px lato ',
    [theme.breakpoints.down(1370)]:{
      font: "18px lato",
      padding: "0 14px 5px 14px",
    },
    [theme.breakpoints.down(1070)]:{
      font: "16px lato",
      padding: "0 14px 5px 14px",
      width: "80%",
    },
    [theme.breakpoints.down(650)]:{
      font: "14px lato",
    },
  },
  innerText:{
    height: "70%",
  }
}));

const AnswerTetx = (props) => {
  const classes = useStyles();
  const { 
    setPristine, 
    questionId, 
    feedback, 
    setFeedback, 
    setValueAnswerText, 
    setCurrentAnswer,
  } = props




  const handleChange = (event) => {
    setCurrentAnswer(questionId)
    setValueAnswerText(event.target.value);
    setPristine(false)
    setFeedback(feedback)
    const value = event.target.value;
    const valueContainText =  value.trim().length > 0
    if (!valueContainText) setPristine(true);
  };


  return(
      <OutlinedInput
        id="outlined-multiline-static"
        multiline
        rows={8}
        onChange={handleChange}
        placeholder="Escribe aquí tu respuesta..."
        variant="outlined"
        // labelwidth={60}
        classes={{root: classes.textFieldContainer, input: classes.innerText}}
        // disableUnderline
        // className={classes.textFieldContainer}
      />

  )
}

export default AnswerTetx;