import { Button, Grid, makeStyles } from "@material-ui/core";
import React, { useContext, useEffect } from "react";
import DrawerContext from "../../../../../usability/DrawerContext";
import { useOnLoadImages } from "../../../../hooks/useOnLoadImages";
import Loader from "../../../../Loader/Loader";
import Drawer from "../../ReadingTools/Drawer";

const useStyles =  makeStyles (theme =>({
     Contenedor:{
        background:'#E9FBF9',
        width:'100vw',
        overflowX:'clip',
        [theme.breakpoints.down(1161)]:{
            background:'none',
           overflowX:'unset',
        },
        [theme.breakpoints.down(600)]:{
            marginTop: '79.51px',
        }
    },
ContainerInfoLectura: {
    position: "relative",
    height: "86px",
    background: "#817FD9",
    [theme.breakpoints.down(502)]:{
        height: "43px",
    },
    "& .BarraInfoLectura": {
      height: "86px",
      background: `Url(https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Barras/Morada.png)`,
      [theme.breakpoints.down(502)]:{
        height: "43px",
    },
    },
    "& .InfoLectura": {
      position: "absolute",
      height: "86px",
      width: "219px",
      boxSizing: "border-box",
      padding: "17.2px 0",
      background: "#FAFAFA",
      borderRadius: "50px",
      boxShadow: "2px 2px 2px 2px #00000029",
      zIndex: "4",
      [theme.breakpoints.down(502)]:{
        height: "43px",
        width: "109px",
        padding: "8.6px 0",
        borderRadius: "25px",
    },
    },
    "& .InfoLectura:before": {
      position: "absolute",
      display: "flex",
      boxSizing: "border-box",
      padding: "0 0 0 10px",
      justifyContent: "center",
      alignItems: "center",
      height: "39.88px",
      width: "39.88px",
      borderRadius: "50%",
      content: '""',
      boxShadow: "3px 0px .5px 0px #00000029",
      background: "#FAFAFA",
      left: "90px",
      top: "65px",
      transform: "rotate(0.25turn)",
      [theme.breakpoints.down(502)]:{
        left: "45px",
        top: "30px",
        height: "20.88px",
        width: "20.88px",
        },
    },
    "& .InfoLectura:after": {
      content: '""',
      height: "15px",
      width: "15px",
      borderTop: "2px solid #817FD9",
      borderLeft: "2px solid #817FD9",
      position: "absolute",
      bottom: "-10px",
      left: "Calc(50% - 8px)",
      transform: "rotate(0.625turn)",
      [theme.breakpoints.down(502)]:{
        height: "10px",
        width: "10px",
        left: "Calc(50% - 4.5px)",
        bottom: "-3px",
        borderTop: "1px solid #20A698",
        borderLeft: "1px solid #20A698",
        },
    },
    "& .Sesion": {
      borderLeft: "2px solid #D8D8D8",
    },
    "& .Dato": {
      margin: "0 0 5px 0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#817FD9",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        margin: "0 0 2.5px 0",
        },
    },
    "& .Caracter": {
      margin: "0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#000000",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        },
    },
       '& .Regresar':{
        color: '#F2624F',
        border: '#F2624F 1px solid',
        height: '30px',
        width:'100px',
        borderRadius: '20px',
        letterSpacing: '0.14px',
        textTransform: 'none',
        background:'#FEFAF1',
        top:'calc(50% - 15px)',
        left:'5%',
        zIndex:4,
        [theme.breakpoints.down(502)]: {
          font:'bold 10px lato',
          width: "60px",
        },
      },
  },
    ContenedorLectura:{
        background:'#fff',
        width:'1160px',
        height:'100%',
        padding:'60px 168px 35px ',
        
        transform: (props) =>
        props.scale > 1 ? `scale(${props.scale}) translate(0px, 10%)` : `none`,
        [theme.breakpoints.down(1161)]:{
            width:'502px',
            padding:'60px 0 0 0',
          },
                [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },

        '& .Escritorio':{
            display:'block',
            [theme.breakpoints.down(1161)]:{
                display:'none'
            },
        },
        '& .Tablet':{
            display:'none',
            [theme.breakpoints.down(1161)]:{
                display:'block'
            },
        }
    },
    ContainerTitulo:{
        margin:'0 0 34px 0',
        [theme.breakpoints.down(1161)]:{
            margin:'0 0 34px 0',
        },
        '& .Titulo':{
            font:'48px fredoka one',
            letterSpacing: '0px',
            lineHeight:'58px',
            color: '#F6644B',
            margin:'0',
            textAlign:'center',
            [theme.breakpoints.down(1161)]:{
                font:'43px fredoka one',
                lineHeight:'52px',
            },
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            lineHeight : '30px',
            margin:'0 0 36px 0',
            font:'22px lato',
            color:'#000',
            letterSpacing: '0.21px',
            [theme.breakpoints.down(1161)]:{
                font:'18px lato',
                lineHeight :'28px',
                letterSpacing: '0.17px',
            },
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .Lectura2':{
            margin:'0',
        },
        '& .SubTitulo':{
            font:'bold 30px lato',
            color:'#0061E5',
            letterSpacing:'.28px',
            lineHeight:'30px',
            margin:'0',
            [theme.breakpoints.down(1161)]:{
                lineHeight :'28px',
                font:'bold 26px lato',
                letterSpacing: '0.24px',
            },
            [theme.breakpoints.down(502)]:{
                font:'bold 18px lato',
                lineHeight :'24px',
                letterSpacing: '0.21px',
            },
        },
        '& .TextImg':{
            font:'20px lato',
            color:'#717476',
            letterSpacing:'.19px',
            lineHeight:'30px',
            margin:'24px 0 0 0',
            [theme.breakpoints.down(1161)]:{
                font:' 16px lato',
                lineHeight :'25px',
                letterSpacing: '0.15px',
            },
            [theme.breakpoints.down(502)]:{
                font:' 11px lato',
                lineHeight :'14px',
                letterSpacing: '0.10px',
            },
        },
        '& .Imagen':{
            [theme.breakpoints.down(1161)]:{
                width:'100%',
            },
        },  
      
    }
}));

const La_delgada_línea_que_separa_el_mundo_real_del_virtual= (props) => {



  const {activeStep, setActiveStep, handlePreviewStep, id_actividad} = props
  
  const handleContinue = () => {
    setActiveStep(activeStep - 1)
    handlePreviewStep()
  }

    const {
    handleSize,
    disabledZoom,
    closeZoom,
    handleZoom,
    scale
  }= useContext(DrawerContext);
  const [loading, setLoading] = React.useState(true);
  const wrapperRef = React.useRef(null);
  const imagesLoaded = useOnLoadImages(wrapperRef);

  const detectSize = () => {
    const lectura = document.getElementById("lectura");
    const newWidth = lectura?.offsetWidth;
    const newHeight = lectura?.offsetHeight;
    setLoading(false);
    if (lectura) handleSize(newHeight, newWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", detectSize);
    return () => {
      handleZoom()
      window.removeEventListener("resize", detectSize);
      closeZoom()
    };
  }, []);

  const classes = useStyles({ disabledZoom,scale });
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
             <Grid container item justifyContent="center" className={classes.ContainerInfoLectura}>
                <Grid container className="BarraInfoLectura">
                    <Button className="Regresar" onClick={handleContinue}>
                        Regresar
                    </Button>
                </Grid>
               <Grid container item className="InfoLectura">
                    <Grid container item direction="column" xs={6} justifyContent="center">
                        <p className="Dato">Grado</p>
                        <p className="Caracter">5</p>
                    </Grid>
                    <Grid container item direction="column" xs={6} justifyContent="center" className="Sesion">
                        <p className="Dato">Sesión</p>
                        <p className="Caracter">38</p>
                    </Grid>
               </Grid>
            </Grid>
            {!imagesLoaded && <Loader />}
        <Grid
            container
            item
            className={classes.ContenedorLectura}
            justifyContent="center"
            id="lectura"
            onLoad={detectSize}
            ref={wrapperRef}
        >
<Drawer id_lectura={id_actividad} />
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                        <h2 className="Titulo">La delgada línea que separa el mundo real del virtual</h2>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} >
                    <Grid container sm={6} justifyContent='flex-start' className='Escritorio'>
                       <p className='Lectura Lectura2'>
                            Un niño tuvo que ser hospitalizado<br/> 
                            durante dos meses en Castellón<br/> 
                            (España) a causa de su adicción a los<br/> 
                            videojuegos. Los médicos decidieron<br/> 
                            que necesitaba quedarse varias<br/> 
                            semanas internado cuando se dieron<br/> 
                            cuenta de los graves síntomas que<br/> 
                            sufría por pasar tantas horas diarias<br/> 
                            jugando. Su juego preferido era<br/> 
                            <em> Fortnite</em>. 
                            <br/><br/>
                            Se han desarrollado múltiples<br/> 
                            estudios sobre las consecuencias del<br/> 
                            uso continuo de aparatos electrónicos<br/> 
                            desde hace tiempo. Se cree que<br/> 
                            especialmente los videojuegos<br/> 
                            pueden tener repercusiones<br/> 
                            importantes en la salud infantil.<br/>
                       </p>                   
                    </Grid>
                    <Grid container sm={6} alignContent='flex-end' className='Escritorio'>
                        <Grid container justifyContent='center'>
                            <img className="Imagen " src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion38/1.png" alt="Tarjeta" />
                        </Grid><Grid container justifyContent='center'>
                           <p className='TextImg'>Los videojuegos son cada vez más populares. ¿Conoces alguno? ¿Cuál es tu favorito?</p>
                        </Grid>
                    </Grid>

                    <Grid container sm={12}  className='Tablet'>
                       <p className='Lectura Lectura2'>
                            Un niño tuvo que ser hospitalizado 
                            durante dos meses en Castellón 
                            (España) a causa de su adicción a los 
                            videojuegos. Los médicos decidieron 
                            que necesitaba quedarse varias 
                            semanas internado cuando se dieron 
                            cuenta de los graves síntomas que
                            sufría por pasar tantas horas diarias 
                            jugando. Su juego preferido era 
                            <em> Fortnite</em>. 
                            <br/><br/>
                            Se han desarrollado múltiples 
                            estudios sobre las consecuencias del 
                            uso continuo de aparatos electrónicos 
                            desde hace tiempo. Se cree que 
                            especialmente los videojuegos 
                            pueden tener repercusiones 
                            importantes en la salud infantil.
                       </p>                   
                    </Grid>
                    <Grid container sm={12}  className='Tablet'>
                        <Grid container justifyContent='center'>
                            <img className="Imagen " src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion38/1R.png" alt="Tarjeta" />
                        </Grid><Grid container justifyContent='center'>
                           <p className='TextImg'>Los videojuegos son cada vez más populares. ¿Conoces alguno? ¿Cuál es tu favorito?</p>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container justifyContent='center' >
                        <p className='Lectura'>
                            <h3 className='SubTitulo'>Adicciones</h3><br/>

                            El peligro más inmediato de pasar tantas horas jugando en dispositivos electrónicos 
                            es la adicción. La Organización Mundial de la Salud ha advertido sobre los peligros de 
                            la red desde 1995, cuando incluyó la adicción a internet en su listado oficial de 
                            trastornos del comportamiento. En años más recientes, la dependencia a los 
                            videojuegos ha entrado también en esa categoría debido a que niños y jóvenes cada 
                            vez pasan más tiempo frente a sus pantallas, navegando y jugando en línea. <br/><br/>
                            
                            Además de que esto repercute negativamente en su rendimiento académico, los 
                            lleva a aislarse, a hablar poco, y a no querer convivir con otros integrantes de su
                            familia ni con sus compañeros de la escuela. Algunos incluso ya no quieren jugar con 
                            sus amigos si no es a través de la red, alejándose de las amistades que no forman 
                            parte de estos círculos. Lo más alarmante para las autoridades y los padres de familia 
                            es que los niños se vuelven adictos sin darse cuenta, pues es un proceso lento.
                        </p>
                    </Grid>
                    <Grid container justifyContent='center'>
                        <Grid container justifyContent='center'>
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion38/2.png" alt="Tarjeta" />
                        </Grid>
                        <Grid container justifyContent='center'>
                           <p className='TextImg'>Muchos niños y jóvenes pasan cada vez más horas frente a sus pantallas.</p>
                        </Grid>
                    </Grid>

                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container justifyContent='center' >
                        <p className='Lectura'>
                            <h3 className='SubTitulo'>La realidad virtual</h3><br/>
                            
                            Pasar muchas horas en juegos virtuales provoca problemas que van más allá de la 
                            adicción. Muchos niños y jóvenes viven tanto tiempo en los mundos ficticios que 
                            comienzan a valorarlos más que los reales, lo cual provoca que deseen pasar más 
                            tiempo ahí. Viven los escenarios como si en verdad existieran y buscan desarrollar 
                            sus vidas en ellos. Interactúan con los personajes y con otros jugadores y terminan 
                            considerándolos amigos, aunque no los conozcan ni sepan sus nombres verdaderos. 
                            Algunos rechazan relacionarse en el mundo real, en la escuela o en el vecindario 
                            porque prefieren interactuar en la red. Asimismo, suelen asignar a su personaje del 
                            juego una identidad y una caracterización diferentes a las reales. En consecuencia, 
                            llegan a pensar que ésta es su verdadera personalidad. <br/><br/>
                            
                            La confusión entre el mundo real y el virtual deriva en serios problemas de conducta 
                            y personalidad. Cada vez se dan más casos de personas que quieren dejar de ser ellas 
                            mismas para convertirse en el personaje de un videojuego o de una película. Incluso 
                            se hacen cirugías y otras modificaciones corporales para dejar de lucir como seres 
                            humanos y parecerse a otro tipo de criaturas (como elfos, ninfas o duendes). A veces 
                            ni siquiera reconocen qué elementos o qué seres son parte del mundo virtual (es 
                            decir, no saben que no existen ni pueden hacerlo en el mundo real). <br/><br/>
                            
                            Por eso es muy importante controlar las horas que pasamos delante de las pantallas 
                            (sobre todo si jugamos en línea). También es fundamental que siempre tengamos 
                            presente la diferencia entre el mundo real y el virtual.
                        </p>
                    </Grid>
                </Grid>
        </Grid>
        <Grid container item style={{ marginTop: 80}} ></Grid>
    </Grid>
    );
}
 
export default La_delgada_línea_que_separa_el_mundo_real_del_virtual;
