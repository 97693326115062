import { Button, Grid, makeStyles } from "@material-ui/core";
import React, { useContext, useEffect } from "react";
import DrawerContext from "../../../../../usability/DrawerContext";
import { useOnLoadImages } from "../../../../hooks/useOnLoadImages";
import Loader from "../../../../Loader/Loader";
import Drawer from "../../ReadingTools/Drawer";

const useStyles =  makeStyles (theme =>({
     Contenedor:{
        background:'#E9FBF9',
        width:'100vw',
        overflowX:'clip',
        [theme.breakpoints.down(1161)]:{
            background:'none',
           overflowX:'unset',
        },
        [theme.breakpoints.down(600)]:{
            marginTop: '79.51px',
        }
    },
  ContainerInfoLectura: {
    position: "relative",
    height: "86px",
    [theme.breakpoints.down(502)]:{
        height: "43px",
    },
    background: "#76D4C3",
    "& .BarraInfoLectura": {
      height: "86px",
      background: `Url(https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Barras/Azul.png)`,
        [theme.breakpoints.down(502)]:{
            height: "43px",
        },
    },
    "& .InfoLectura": {
      position: "absolute",
      height: "86px",
      width: "219px",
      boxSizing: "border-box",
      padding: "17.2px 0",
      background: "#FAFAFA",
      borderRadius: "50px",
      boxShadow: "2px 2px 2px 2px #00000029",
      zIndex: "4",
        [theme.breakpoints.down(502)]:{
            height: "43px",
            width: "109px",
            padding: "8.6px 0",
            borderRadius: "25px",
        },
    },
    "& .InfoLectura:before": {
      position: "absolute",
      display: "flex",
      boxSizing: "border-box",
      padding: "0 0 0 10px",
      justifyContent: "center",
      alignItems: "center",
      height: "39.88px",
      width: "39.88px",
      borderRadius: "50%",
      content: '""',
      boxShadow: "3px 0px .5px 0px #00000029",
      background: "#FAFAFA",
      left: "90px",
      top: "65px",
      transform: "rotate(0.25turn)",
      [theme.breakpoints.down(502)]:{
        left: "45px",
        top: "30px",
        height: "20.88px",
        width: "20.88px",
        },
    },
    "& .InfoLectura:after": {
      content: '""',
      height: "15px",
      width: "15px",
      borderTop: "2px solid #20A698",
      borderLeft: "2px solid #20A698",
      position: "absolute",
      bottom: "-10px",
      left: "Calc(50% - 8px)",
      transform: "rotate(0.625turn)",
      [theme.breakpoints.down(502)]:{
        height: "10px",
        width: "10px",
        left: "Calc(50% - 4.5px)",
        bottom: "-3px",
        borderTop: "1px solid #20A698",
        borderLeft: "1px solid #20A698",
        },
    },
    "& .Sesion": {
      borderLeft: "2px solid #D8D8D8",
    },
    "& .Dato": {
      margin: "0 0 5px 0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#20A698",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        margin: "0 0 2.5px 0",
        },
    },
    "& .Caracter": {
      margin: "0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#000000",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        },
    },
       '& .Regresar':{
        color: '#F2624F',
        border: '#F2624F 1px solid',
        height: '30px',
        width:'100px',
        borderRadius: '20px',
        letterSpacing: '0.14px',
        textTransform: 'none',
        background:'#FEFAF1',
        top:'calc(50% - 15px)',
        left:'5%',
        zIndex:4,
        [theme.breakpoints.down(502)]: {
          font:'bold 10px lato',
          width: "60px",
        },
      },
  },
    ContenedorLectura:{
        background:'#fff',
        width:'1160px',
        height:'100%',
        padding:'60px 168px 35px ',
        
        transform: (props) =>
        props.scale > 1 ? `scale(${props.scale}) translate(0px, 10%)` : `none`,
        [theme.breakpoints.down(1161)]:{
            width:'502px',
            padding:'60px 0 0 0',
          },
                [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },

        '& .Escritorio':{
            display:'block',
            [theme.breakpoints.down(1161)]:{
                display:'none'
            },
        },
        '& .Tablet':{
            display:'none',
            [theme.breakpoints.down(1161)]:{
                display:'block'
            },
        }
    },
    ContainerTitulo:{
        margin:'0 0 34px 0',
        [theme.breakpoints.down(1161)]:{
            margin:'0 0 34px 0',
        },
        '& .Titulo':{
            font:'48px fredoka one',
            letterSpacing: '0px',
            lineHeight:'58px',
            color: '#136DE6',
            margin:'0',
            textAlign:'center',
            [theme.breakpoints.down(1161)]:{
                font:'43px fredoka one',
                lineHeight:'52px',
            },
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            lineHeight : '30px',
            margin:'0 0 36px 0',
            font:'22px lato',
            color:'#000',
            letterSpacing: '0.20px',
            [theme.breakpoints.down(1161)]:{
                font:'18px lato',
                lineHeight :'28px',
                letterSpacing: '0.17px',
            },
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .SubTitulo':{
            font:'bold 30px lato',
            color:'#B6005B',
            letterSpacing:'.28px',
            lineHeight:'30px',
            margin:'0',
            [theme.breakpoints.down(1161)]:{
                lineHeight :'28px',
                font:'bold 26px lato',
                letterSpacing: '0.24px',
            },
            [theme.breakpoints.down(502)]:{
                font:'bold 18px lato',
                lineHeight :'24px',
                letterSpacing: '0.21px',
            },
        },
        '& .SubTitulo2':{
            font:'bold 26px lato',
            color:'#136DE6',
            letterSpacing:'.24px',
            lineHeight:'28px',
            margin:'0',
            [theme.breakpoints.down(1161)]:{
                font:'bold 22px lato',
                lineHeight :'24px',
                letterSpacing: '0.21px',
            },
            [theme.breakpoints.down(502)]:{
                font:'bold 18px lato',
                lineHeight :'20px',
                letterSpacing: '0.16px',
            },
        },
        '& .TextImg':{
            font:'20px lato',
            color:'#717476',
            letterSpacing:'.19px',
            lineHeight:'30px',
            margin:'24px 0 0 0',
            [theme.breakpoints.down(1161)]:{
                font:' 16px lato',
                lineHeight :'25px',
                letterSpacing: '0.15px',
            },
            [theme.breakpoints.down(502)]:{
                font:' 11px lato',
                lineHeight :'14px',
                letterSpacing: '0.10px',
            },
        },

        '& .Imagen':{
            [theme.breakpoints.down(1161)]:{
                width:'100%',
            },
        },  
      
    }
}));

const Sí_o_no_a_los_alimentos_transgénicos = (props) => {

  const {activeStep, setActiveStep, handlePreviewStep, id_actividad} = props
  
  const handleContinue = () => {
    setActiveStep(activeStep - 1)
    handlePreviewStep()
  }

    const {
      handleSize,
    disabledZoom,
    closeZoom,
    handleZoom,
    scale
  }= useContext(DrawerContext);
  const [loading, setLoading] = React.useState(true);
  const wrapperRef = React.useRef(null);
  const imagesLoaded = useOnLoadImages(wrapperRef);

  const detectSize = () => {
    const lectura = document.getElementById("lectura");
    const newWidth = lectura?.offsetWidth;
    const newHeight = lectura?.offsetHeight;
    setLoading(false);
    if (lectura) handleSize(newHeight, newWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", detectSize);
    return () => {
      handleZoom()
      window.removeEventListener("resize", detectSize);
      closeZoom()
    };
  }, []);

  const classes = useStyles({ disabledZoom,scale });
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
             <Grid container item justifyContent="center" className={classes.ContainerInfoLectura}>
                       <Grid container className="BarraInfoLectura">
            <Button className="Regresar" onClick={handleContinue}>
                Regresar
            </Button>
        </Grid>
               <Grid container item className="InfoLectura">
                    <Grid container item direction="column" xs={6} justifyContent="center">
                        <p className="Dato">Grado</p>
                        <p className="Caracter">5</p>
                    </Grid>
                    <Grid container item direction="column" xs={6} justifyContent="center" className="Sesion">
                        <p className="Dato">Sesión</p>
                        <p className="Caracter">42</p>
                    </Grid>
               </Grid>
            </Grid>
            {!imagesLoaded && <Loader />}
        <Grid
            container
            item
            className={classes.ContenedorLectura}
            justifyContent="center"
            id="lectura"
            onLoad={detectSize}
            ref={wrapperRef}
        >
<Drawer id_lectura={id_actividad} />
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                        <h2 className="Titulo">¿Sí o no a los alimentos <br/>transgénicos?</h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                       <p className='Lectura'>
                        <h3 className='SubTitulo'>La comida, una vieja preocupación</h3><br/>
                        
                        ¿Cómo se pueden producir alimentos suficientes para abastecer a una población que 
                        crece constantemente? Aunque éste ha sido un gran reto para todas las 
                        civilizaciones, en el siglo <span style={{fontVariant: "all-small-caps"}}>XX</span> alcanzó una importancia enorme porque la humanidad 
                        experimentó el crecimiento más acelerado en la historia. <br/><br/>
                        
                        Quienes producen comida o comercian con ella tienen mucho en que pensar: ¿qué 
                        tan duradera o sustanciosa es? ¿Parece atractiva? ¿Cuántos recursos y cuánto tiempo 
                        se utilizan para producirla? ¿Será suficiente para cubrir las necesidades de la 
                        población? Por eso, a lo largo de los años, los productores han buscado formas de 
                        mejorar las cosechas y la cría de animales, modificando las especies poco a poco a lo 
                        largo del tiempo. <br/><br/>
                        
                        A partir de la segunda mitad del siglo <span style={{fontVariant: "all-small-caps"}}>XX</span> un gran descubrimiento científico 
                        revolucionó la forma en la que el ser humano alteraba los animales y vegetales que 
                        consumía. Se descifró la estructura y el funcionamiento del <span style={{fontVariant: "all-small-caps"}}>ADN</span>, el material celular
                        con el que están codificados todos los rasgos de los seres vivos. En la actualidad los 
                        especialistas pueden intervenirlo modificando los rasgos de las especies que se 
                        cultivan y crían para la alimentación. <br/><br/>
                        
                        Las ciencias que logran esto, la biotecnología y la ingeniería genética, han progresado
                        mucho desde finales del siglo <span style={{fontVariant: "all-small-caps"}}>XX</span>. Ambas producen organismos <em>transgénicos</em>, es decir, 
                        plantas y animales cuya genética ha sido modificada, pues se les agregaron 
                        artificialmente uno o más genes que no estaban en su material original. ¡Y a veces 
                        vienen de organismos muy distintos, como virus o bacterias!
                       </p>                   
                    </Grid>
                    <Grid container justifyContent='center'>
                        <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion42/1.png" alt="Tarjeta" />
                    </Grid>
                    <Grid container justifyContent='center' >
                        <p className="TextImg">
                        Los ingenieros en biotecnología buscan todo el tiempo formas de mejorar los cultivos.
                        </p>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container >
                        <p className='Lectura'>
                            <h3 className='SubTitulo'>Modificaciones genéticas</h3><br/>
                            
                            Quienes modifican algún espécimen buscan características deseables. Por ejemplo, 
                            se ha conseguido que algunos cultivos desarrollen propiedades insecticidas contra 
                            las plagas que los atacan, implantándoles genes de una bacteria que mata insectos.
                        </p>
                    </Grid>
                    <Grid container justifyContent='center'>
                        <Grid container justifyContent='center'>
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion42/2.png" alt="Tarjeta" />
                        </Grid>
                        <Grid container >
                            <p className="TextImg">
                                Los investigadores buscan que los transgénicos con propiedades insecticidas eliminen sólo 
                                plagas específicas. Muchos insectos que conviven con los cultivos (como arañas y avispas) son 
                                benéficos para ellos, y una parte importante de la biodiversidad.
                            </p>
                        </Grid>
                    </Grid>

                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container >
                        <p className='Lectura'>
                            También se han desarrollado variedades de cosechas que resisten mejor las sequías o 
                            que permanecen frescas en los almacenes por más tiempo. Ambas características 
                            ayudan a que las poblaciones tengan un abasto regular de alimentos. <br/><br/>
                            
                            A los animales se los altera para que acumulen más carne o para que crezcan más 
                            rápido. En el caso de las gallinas, para que pongan más huevos. <br/><br/>
                            
                            Tener cultivos más resistentes también puede ser positivo para el ambiente. Los 
                            cultivos naturales requieren grandes cantidades de agroquímicos (como fertilizantes 
                            e insecticidas) que son muy contaminantes. Sin embargo, casi todas estas 
                            modificaciones están encaminadas a aumentar la productividad y disminuir los 
                            costos de producción. <br/><br/>
                            
                            <h3 className='SubTitulo'>Transgénicos: ¿una comida peligrosa?</h3><br/>
                            
                            ¿Qué impacto tendrán en el ecosistema estos organismos modificados? ¿Podría 
                            ocasionar algún desequilibro que afecte en el futuro la producción de alimentos y de 
                            la biodiversidad? Por otro lado, ¿qué impacto tendrán en la nutrición y en la salud de 
                            los seres humanos? Aunque el tema todavía no se ha estudiado con profundidad, 
                            muchos científicos opinan que la modificación genética repercute en los 
                            consumidores de formas que aún es difícil ver y estudiar. Se desconoce cuáles serán 
                            sus efectos a largo plazo.<br/><br/>
                            
                            <h3 className='SubTitulo2'>Derechos alimenticios</h3><br/>
                            
                            Si hay algo peligroso en lo que comemos, es necesario que los productores lo digan 
                            con claridad. Los consumidores tenemos derecho a saber si estamos comprando e 
                            ingiriendo alimentos transgénicos. En la actualidad es posible que algunos tipos de 
                            tomate o de maíz disponibles para nuestro consumo hayan sido modificados 
                            genéticamente, sin que nosotros lo sepamos. <br/><br/>
                            
                            Por ello es importante que existan leyes que obliguen a las empresas a etiquetar 
                            con claridad sus productos. Así, cuando compres algún alimento, encontrarás etiquetas 
                            advirtiéndote que fue manipulado. Sólo cuando estés suficientemente bien informado 
                            de cuáles productos son transgénicos y cuáles no, podrás decidir de forma responsable 
                            si quieres consumirlos.
                        </p>
                    </Grid>
                </Grid>
        </Grid>
        <Grid container item style={{ marginTop: 80}} ></Grid>
    </Grid>
    );
}
 
export default Sí_o_no_a_los_alimentos_transgénicos;
