import { Button, Grid, makeStyles } from "@material-ui/core";
import React, { useContext } from "react";
import DrawerContext from "../../../../../usability/DrawerContext";
import Drawer from "../../ReadingTools/Drawer";

const useStyles = makeStyles((theme) => ({
  Contenedor: {
    background: "#E9FBF9",
    width: "100vw",
    [theme.breakpoints.down(1161)]: {
      background: "none",
    },
    [theme.breakpoints.down(600)]: {
      marginTop: "79.51px",
    },
  },
  ContainerInfoLectura: {
    position: "relative",
    height: "86px",
    [theme.breakpoints.down(502)]:{
        height: "43px",
    },
    background: "#76D4C3",
    "& .BarraInfoLectura": {
      height: "86px",
      background: `Url(https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Barras/Azul.png)`,
        [theme.breakpoints.down(502)]:{
            height: "43px",
        },
    },
    "& .InfoLectura": {
      position: "absolute",
      height: "86px",
      width: "219px",
      boxSizing: "border-box",
      padding: "17.2px 0",
      background: "#FAFAFA",
      borderRadius: "50px",
      boxShadow: "2px 2px 2px 2px #00000029",
      zIndex: "4",
        [theme.breakpoints.down(502)]:{
            height: "43px",
            width: "109px",
            padding: "8.6px 0",
            borderRadius: "25px",
        },
    },
    "& .InfoLectura:before": {
      position: "absolute",
      display: "flex",
      boxSizing: "border-box",
      padding: "0 0 0 10px",
      justifyContent: "center",
      alignItems: "center",
      height: "39.88px",
      width: "39.88px",
      borderRadius: "50%",
      content: '""',
      boxShadow: "3px 0px .5px 0px #00000029",
      background: "#FAFAFA",
      left: "90px",
      top: "65px",
      transform: "rotate(0.25turn)",
      [theme.breakpoints.down(502)]:{
        left: "45px",
        top: "30px",
        height: "20.88px",
        width: "20.88px",
        },
    },
    "& .InfoLectura:after": {
      content: '""',
      height: "15px",
      width: "15px",
      borderTop: "2px solid #20A698",
      borderLeft: "2px solid #20A698",
      position: "absolute",
      bottom: "-10px",
      left: "Calc(50% - 8px)",
      transform: "rotate(0.625turn)",
      [theme.breakpoints.down(502)]:{
        height: "10px",
        width: "10px",
        left: "Calc(50% - 4.5px)",
        bottom: "-3px",
        borderTop: "1px solid #20A698",
        borderLeft: "1px solid #20A698",
        },
    },
    "& .Sesion": {
      borderLeft: "2px solid #D8D8D8",
    },
    "& .Dato": {
      margin: "0 0 5px 0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#20A698",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        margin: "0 0 2.5px 0",
        },
    },
    "& .Caracter": {
      margin: "0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#000000",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        },
    },
       '& .Regresar':{
        color: '#F2624F',
        border: '#F2624F 1px solid',
        height: '30px',
        width:'100px',
        borderRadius: '20px',
        letterSpacing: '0.14px',
        textTransform: 'none',
        background:'#FEFAF1',
        top:'calc(50% - 15px)',
        left:'5%',
        zIndex:4,
        [theme.breakpoints.down(502)]: {
          font:'bold 10px lato',
          width: "60px",
        },
      },
  },
  ContenedorLectura: {
    background: "#fff",
    width: "1160px",
    height: "100%",
    padding: "60px 168px 35px ",
    position: "relative",
    
    transform: (props) =>
      props.scale > 1 ? `scale(${props.scale}) translate(0px, 10%)` : `none`,
    [theme.breakpoints.down(1161)]: {
      width: "502px",
      padding: "60px 0 0 0",
    },
    [theme.breakpoints.down(502)]: {
      width: "350px",
      padding: "60px 0 0 0",
    },
    [theme.breakpoints.down(350)]: {
      width: "300px",
      padding: "30px 0 0 0",
    },
    "& .ContainerDerechos": {
      bottom: "80px",
      [theme.breakpoints.down(1161)]: {
        display: "none",
      },
    },
    "& .Derechos": {
      textAlign: "center",
      margin: "0px",
      font: " 15px lato",
      color: "#717476",
    },
    "& .Escritorio": {
      display: "block",
      [theme.breakpoints.down(1161)]: {
        display: "none",
      },
    },
    "& .Tablet": {
      display: "none",
      [theme.breakpoints.down(1161)]: {
        display: "block",
      },
    },
  },
  ContainerTitulo: {
    margin: "0 0 34px 0",
    [theme.breakpoints.down(1161)]: {
      margin: "0 0 34px 0",
    },
    "& .Titulo": {
      font: "48px fredoka one",
      letterSpacing: "0px",
      lineHeight: "58px",
      color: "#F6664D",
      margin: "0",
      textAlign: "center",
      [theme.breakpoints.down(1161)]: {
        font: "43px fredoka one",
        lineHeight: "52px",
      },
      [theme.breakpoints.down(502)]: {
        font: "30px fredoka one",
        lineHeight: "32px",
      },
    },
  },
  Parrafo1: {
    margin: " 0 0 36px 0",
    "& .Lectura": {
      lineHeight: "30px",
      margin: "0 0 36px 0",
      font: "22px lato",
      color: "#000",
      letterSpacing: "0.20px",
      [theme.breakpoints.down(1161)]: {
        font: "18px lato",
        lineHeight: "28px",
        letterSpacing: "0.17px",
      },
      [theme.breakpoints.down(502)]: {
        font: "12px lato",
        lineHeight :'18px',
        letterSpacing: '0.09px',
      },
    },
    "& .TextImg": {
      font: "20px lato",
      color: "#717476",
      letterSpacing: ".19px",
      lineHeight: "30px",
      margin: "24px 0 0 0",
      [theme.breakpoints.down(1161)]: {
        font: " 16px lato",
        lineHeight: "25px",
        letterSpacing: "0.15px",
      },
      [theme.breakpoints.down(502)]: {
        font: " 14px lato",
        lineHeight: "21px",
        letterSpacing: "0.15px",
      },
    },
    "& .TextImg2": {
      margin: "0",
    },

    "& .Imagen": {
      width: "100%",
    },
  },
}));

const La_desaparición_de_los_mayas = (props) => {

   const {activeStep, setActiveStep, handlePreviewStep, id_actividad} = props
  
  const handleContinue = () => {
    setActiveStep(activeStep - 1)
    handlePreviewStep()
  }

  const {
    handleSize,
    disabledZoom,
    closeZoom,
    scale
  }= useContext(DrawerContext);
  const detectSize = () => {
    const lectura = document.getElementById("lectura");

    const newWidth = lectura?.offsetWidth;
    const newHeight = lectura?.offsetHeight;
    if (lectura) handleSize(newHeight, newWidth);
  };
  React.useEffect(() => {
    window.addEventListener("resize", detectSize);
    return () => {
      window.removeEventListener("resize", detectSize);
      closeZoom();
    };
  }, []);

  const classes = useStyles({ disabledZoom, scale });
  return (
    <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
      <Grid container item justifyContent="center" className={classes.ContainerInfoLectura}>
        <Grid container className="BarraInfoLectura">
            <Button className="Regresar" onClick={handleContinue}>
                Regresar
            </Button>
        </Grid>
        <Grid container item className="InfoLectura">
          <Grid container item direction="column" xs={6} justifyContent="center">
            <p className="Dato">Grado</p>
            <p className="Caracter">1</p>
          </Grid>
          <Grid container item direction="column" xs={6} justifyContent="center" className="Sesion">
            <p className="Dato">Sesión</p>
            <p className="Caracter">22</p>
          </Grid>
        </Grid>
      </Grid>

      <Grid
        container
        item
        className={classes.ContenedorLectura}
        justifyContent="center"
        id="lectura"
        onLoad={detectSize}

      >
        <Drawer id_lectura={id_actividad} withoutTransform ={true}/>
        <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
          <h2 className="Titulo Escritorio">La desaparición de los mayas</h2>
          <h2 className="Titulo Tablet">
            La desaparición
            <br /> de los mayas
          </h2>
        </Grid>
        <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
          <Grid container sm={12}>
            <p className="Lectura">
              Los mayas fueron una civilización prehispánica muy avanzada. Tenían una cultura compleja, eran excelentes astrónomos
              y vivían en grandes ciudades. Uno de los mayores misterios de Mesoamérica es su repentina desaparición.
            </p>
          </Grid>
          <Grid container justifyContent="center" sm={12}>
            <Grid container justifyContent="center">
              <img
                className="Imagen"
                src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img1ro/Sesion22/1.png"
                alt="Tarjeta"
              />
            </Grid>
            <Grid container justifyContent="center">
              <p className="TextImg">La cultura maya era importante en la Mesoamérica clásica.</p>
            </Grid>
          </Grid>
        </Grid>
        <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
          <Grid container sm={12}>
            <p className="Lectura">
              Algunas teorías sobre la desaparición de esta importante cultura tienen que ver con la destrucción del suelo, una
              guerra con otras tribus o incluso una revuelta entre la población. Sin embargo, no hay evidencia que las apoye.
              Actualmente, un profesor de anatomía del Medical College de Ohio, Frank P. Saul, tiene una nueva opinión. Él piensa
              que desafortunadamente los mayas desaparecieron a causa de una mala alimentación y enfermedades. Dado el frágil
              estado de salud de los mayas, Saul piensa que su civilización fue desapareciendo poco a poco, proceso que duró
              muchas décadas, quizá siglos. Para desarrollar la explicación de su teoría, el profesor se apoyó en la información
              que obtuvo de huesos de miles de años de antigüedad.
            </p>
          </Grid>
          <Grid container justifyContent="center" sm={12}>
            <Grid container justifyContent="center">
              <img
                className="Imagen"
                src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img1ro/Sesion22/2.png"
                alt="Tarjeta"
              />
            </Grid>
            <Grid container justifyContent="center">
              <p className="TextImg TextImg2">Los arqueólogos estudian restos óseos para desarrollar nuevas teorías.</p>
            </Grid>
          </Grid>
        </Grid>
        <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
          <Grid container sm={12}>
            <p className="Lectura">
              Todavía hay muchas dudas sobre cómo desaparecieron los antiguos mayas, pero no sobre su importancia en la cultura
              clásica de Mesoamérica. ¿Qué piensas tú? ¿Cuál es tu teoría?
            </p>
          </Grid>
        </Grid>
      </Grid>
      <Grid container item style={{ marginTop: 80 }}></Grid>
    </Grid>
  );
};

export default La_desaparición_de_los_mayas;
