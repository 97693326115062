import { Button, makeStyles, Grid, styled } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import axios from "../../../../services/axios";
import ModalChangePasswordAdmin from "../../../Modals/ModalChangePasswordAdmin";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import cdlLogo from "../../../imgs/CL_LOGO_FINAL_Login 1.png";
import clsx from "clsx";

// import AuthContext from "../../../auth/AuthContext";
import ContainerAdmin from "../ContainerAdmin";
import Loader from "../../../Loader/Loader";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    padding: "48px 0 48px 0",
    background:
      "linear-gradient(217deg, rgba(255,194,0,0.4962185557816877) 2%, rgba(255,255,255,1) 25%, rgba(255,255,255,0.4962185557816877) 81%, rgba(144,81,177,1) 98%)",
    minHeight: "100%",
  },
  boxContainer: {
    background: "#fff",
    width: "90%",
    maxWidth: "1216px",
    minHeight: "70vh",
    borderRadius: "42px",
    padding: "30px",
    boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.5)",
    gap: "20px",
    [theme.breakpoints.down(1280)]: {
      width: "860px",
      height: "100%",
    },
    [theme.breakpoints.down(870)]: {
      width: "420px",
    },
    [theme.breakpoints.down(425)]: {
      width: "300px",
    },
    "& .Titulo": {
      font: "24px fredoka one",
      textAlign: "center",
      color: "#40ADA3",
      margin: "0px",
      [theme.breakpoints.up("lg")]: {
        font: "42px fredoka one",
      },
      [theme.breakpoints.up("xl")]: {
        font: "42px fredoka one",
      },
    },
  },
  dataContainer: {
    width: "100%",
    alignContent: "center",
    justifyContent: "center",
    alignItems: "center",
    gap: "30px",
    flexDirection: "column",
    [theme.breakpoints.up("lg")]: {
      flexDirection: "unset",
    },
  },
  containerTable: {
    width: "100%",
    maxWidth: "576px",
    minHeight: "180px",
    borderRadius: "15px",
    border: "solid 1px rgba(46, 49, 60, 0.1)",
    backgroundColor: "#f5f5f5",
    justifyContent: "flex-end",
    [theme.breakpoints.down(870)]: {
      maxWidth: "420px",
    },
  },

  containerVipIndicator: {
    backgroundColor: "#ffc200",
    borderTopRightRadius: "15px",
    borderTopLeftRadius: "15px",
    padding: "10px 50px",
    textAlign: "center",
    [theme.breakpoints.up("lg")]: {
      width: "auto",
      borderTopLeftRadius: "0",
      borderBottomLeftRadius: "15px",
    },
  },
  vipIndicator: {
    margin: "0",
    font: "24px fredoka one",
    width: "100%",
  },
  table: {
    alignContent: "center",
    justifyContent: "center",
    alignItems: "start",
    padding: "20px",
    display: "grid",
    gap: "30px",
    gridTemplateColumns: "1fr",
    gridTemplateRows: "1fr 1fr",
    gridTemplateAreas: `'admin groups'
    'country teachers'
    'city students'
    'address address'
    `,
    [theme.breakpoints.up("lg")]: {
      gap: "30px",
      gridTemplateColumns: "1fr 1fr",
      gridTemplateRows: "1fr",
    },
  },

  dataTR: {
    width: "100%",
    display: "flex",
    alignContent: "center",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    flexDirection: "column",
    [theme.breakpoints.up("lg")]: {
      flexDirection: "row",
      alignItems: "center",
      gap: "5px",
    },
  },
  admin: {
    gridArea: "admin",
  },
  groups: {
    gridArea: "groups",
  },
  country: {
    gridArea: "country",
  },
  city: {
    gridArea: "city",
  },
  students: {
    gridArea: "students",
  },
  teachers: {
    gridArea: "teachers",
  },
  address: {
    gridArea: "address",
  },
  dataTH: {
    margin: "0",
    fontFamily: "Lato",
    fontSize: "16px",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    letterSpacing: "0.15px",
    color: "#505759",
  },

  dataTD: {
    margin: "0",
    fontFamily: "Lato",
    fontSize: "16px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    letterSpacing: "0.15px",
    color: "#505759",
  },

  avatar: {
    width: "163px",
    height: "163px",
    backgroundColor: "#c5c3c3",
    borderRadius: "50%",
  },
  containerButtons: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    [theme.breakpoints.up("lg")]: {
      gap: "20px",
      flexDirection: "row",
    },
  },
  button: {
    color: "#B965D3",
    cursor: "pointer",
    textDecoration: "none",
    "&:hover": {},
  },
  link: {
    width: "100%",
    color: "inherit",
    cursor: "pointer",
    textDecoration: "none",
  },
  WitheButton: {
    color: "#9051b1",
    // borderColor: "#9051b1",
    border: "1px solid #9051b1",
    backgroundColor: "#FFFFFF",
    borderRadius: "30px",
    fontSize: "12px",
    alignSelf: "center",
    // margin: "0 auto",
    width: "100%",
    maxWidth: "238px",
    // minHeight: "45px",
    textTransform: "unset",
    cursor: "pointer",
    fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
    fontWeight: "500",
    padding: "9.75px 12px",
    transition:
      "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    boxShadow:
      "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
    [theme.breakpoints.up("md")]: {
      fontSize: "14px",
    },
    "&:hover": {
      backgroundColor: "#B965D310 ",
    },
  },
  returnButton: {
    cursor: "pointer",
    textDecoration: "none",
    // height: "31px",
    // width: "190px",
    [theme.breakpoints.down("770")]: {
      // height: "31px",
      // width: "150px",
    },
  },
  backButton: {
    cursor: "pointer",
    // minWidth: "100px",
    backgroundColor: "#FDEFF7",
    color: "#DC5EA6",
    border: "#DC5EA6 1px solid",
    borderRadius: "20px",
    textTransform: "none",
    font: "20px Lato",
    letterSpacing: "0.14px",
    display: "flex",
    alignItems: "center",

    // justifyContent: "space-around",
    [theme.breakpoints.down("770")]: {
      // height: "31px",
      // width: "150px",
      font: "16px Lato",
    },
  },
}));

const WitheButton = styled(Button)(({ theme }) => ({
  color: "#9051b1",
  border: "1px solid #9051b1",
  backgroundColor: "#FFFFFF",
  borderRadius: "30px",
  fontSize: "12px",
  padding: "6px 10px",
  width: "50%",
  maxWidth: "160px",
  textTransform: "unset",
  [theme.breakpoints.up("sm")]: {
    padding: "6px 10px",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "14px",
    padding: "6px 12px",
  },
  "&:hover": {
    backgroundColor: "#B965D310 ",
  },
}));

const EscuelaInfo = () => {
  const classes = useStyles();
  const { id } = useParams();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);

  const [modalChangePasswordOpen, setModalChangePasswordOpen] = useState(false);

  const history = useHistory();

  const handleModalOpen = () => {
    setModalChangePasswordOpen(!modalChangePasswordOpen);
  };

  useEffect(() => {
    setLoading(true);
    axios
      .get(`/listado/info/escuela?id_escuela=${id}`)
      .then((response) => {
        if (response.status === 200) {
          setData(response.data.resultado);
        }
      })
      .catch((error) => {
        history.push({
          pathname: "/error/servicios",
          state: {
            reason: "servicios",
          },
        });
      })
      .finally(() => {
        setLoading(false);
      });

    return () => {};
  }, [history, id]);

  return (
    <ContainerAdmin>
      <Grid
        container
        item
        sm={12}
        alignItems="center"
        justifyContent="center"
        className={classes.mainContainer}
      >
        <Grid
          container
          item
          direction="column"
          className={classes.boxContainer}
        >
          <Link className={classes.returnButton} to={`/admin/escuelas`}>
            <button className={classes.backButton}>
              <ArrowBackIcon /> Escuelas
            </button>
          </Link>
          <h2 className="Titulo">{data?.escuela}</h2>
          <Grid
            container
            direction="row"
            alignItems="center"
            className={classes.dataContainer}
          >
            <img
              src={data?.logo || cdlLogo}
              alt="Logo de Escuela"
              className={classes.avatar}
            />
            <Grid
              container
              direction="row"
              alignItems="center"
              className={classes.containerTable}
            >
              {data?.tipo === "vip" && (
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  className={classes.containerVipIndicator}
                >
                  <p className={classes.vipIndicator}>VIP</p>
                </Grid>
              )}

              <Grid
                container
                direction="row"
                alignItems="center"
                className={classes.table}
              >
                <div className={clsx(`${classes.dataTR} ${classes.admin}`)}>
                  <p className={classes.dataTH}>Encargado:</p>
                  <p className={classes.dataTD}>
                    {data?.nombre} {data?.apellido_paterno}
                  </p>
                </div>
                <div className={clsx(`${classes.dataTR} ${classes.groups}`)}>
                  <p className={classes.dataTH}>Grupos:</p>
                  <p className={classes.dataTD}>{data?.grupos}</p>
                </div>
                <div className={clsx(`${classes.dataTR} ${classes.country} `)}>
                  <p className={classes.dataTH}>País: </p>
                  <p className={classes.dataTD}>{data?.pais}</p>
                </div>
                <div className={clsx(`${classes.dataTR} ${classes.teachers} `)}>
                  <p className={classes.dataTH}>Docentes:</p>
                  <p className={classes.dataTD}>{data?.profesores}</p>
                </div>
                <div className={clsx(`${classes.dataTR}${classes.city}`)}>
                  <p className={classes.dataTH}>Ciudad:</p>
                  <p className={classes.dataTD}>{data?.ciudad}</p>
                </div>

                <div className={clsx(`${classes.dataTR} ${classes.students}`)}>
                  <p className={classes.dataTH}>Estudiantes:</p>
                  <p className={classes.dataTD}>{data?.alumnos}</p>
                </div>
                <div className={clsx(`${classes.dataTR} ${classes.address}`)}>
                  <p className={classes.dataTH}>Dirección:</p>
                  <p className={classes.dataTD}>{data?.direccion}</p>
                </div>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            item
            direction="row"
            alignItems="center"
            justifyContent="center"
            className={classes.containerButtons}
          >
            <WitheButton className={classes.button} variant="contained">
              <Link
                className={classes.link}
                to={{
                  pathname: `/admin/escuela/${data?.id_escuela}/grupos/${data?.id_usuario}`,
                  state: { escuela: data?.escuela },
                }}
              >
                Ver grupos
              </Link>
            </WitheButton>
            <WitheButton className={classes.button} variant="contained">
              <Link
                className={classes.link}
                to={{
                  pathname: `/admin/escuela/${data?.id_escuela}/usuarios/${data?.id_usuario}`,
                  state: { escuela: data?.escuela },
                }}
              >
                Ver usuarios
              </Link>
            </WitheButton>
            <button className={classes.WitheButton} onClick={handleModalOpen}>
              Cambiar contraseña admin.
            </button>
          </Grid>
          <ModalChangePasswordAdmin
            open={modalChangePasswordOpen}
            handleClose={handleModalOpen}
            id_usuario={data?.id_usuario}
          />
          {loading && <Loader />}
        </Grid>
      </Grid>
    </ContainerAdmin>
  );
};

export default EscuelaInfo;
