import React from "react";
import { Grid, makeStyles, Button } from "@material-ui/core";
import { useState } from "react";
import Triste from "../Emotions/MoodImgs/triste_1.png";
import Triste2 from "../Emotions/MoodImgs/triste_2.png";
import Calmado from "../Emotions/MoodImgs/calmado_1.png";
import Calmado2 from "../Emotions/MoodImgs/calmado_2.png";
import Enojado from "../Emotions/MoodImgs/enojado_1.png";
import Enojado2 from "../Emotions/MoodImgs/enojado_2.png";
import Entusiasmado from "../Emotions/MoodImgs/entusiasmado_1.png";
import Entusiasmado2 from "../Emotions/MoodImgs/entusiasmado_2.png";
import Feliz from "../Emotions/MoodImgs/feliz_1.png";
import Feliz2 from "../Emotions/MoodImgs/feliz_2.png";
import Miedoso from "../Emotions/MoodImgs/miedoso_1.png";
import Miedoso2 from "../Emotions/MoodImgs/miedoso_2.png";

const useStyle = makeStyles((theme) => ({
  Container: {
    height: "100%",
  },
  ContainerEmotios: {
    height: "calc(100vh - 79.5px - 79.5px)",
    overflow: "auto",
    background:
      "linear-gradient(32deg, rgba(185,101,211,0.2105042700674019) 1%, rgba(255,255,255,1) 20%, rgba(255,255,255,1) 36%, rgba(254,254,254,1) 77%, rgba(252,227,80,0.2777311608237045) 100%)",
    [theme.breakpoints.down(1161)]: {
      padding: "26px 0 0 0",
    },
    "& .Boton": {
      [theme.breakpoints.down(1161)]: {
        padding: "26px 0 ",
      },
    },
  },
  Question: {
    font: "40px Fredoka One",
    color: "#E67196",
    letterSpacing: " 0px",
    margin: 0,
    textAlign: "center",
    [theme.breakpoints.down(1161)]: {
      font: "36px Fredoka One",
    },
    [theme.breakpoints.down(502)]: {
      font: "26px Fredoka One",
    },
  },

  Emocion: {
    cursor: "pointer",
    height: "105px",
    width: "105px",
    border: "none",
    alignSelf: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    "&:hover": {
      backgroundRepeat: "no-repeat",
    },
    [theme.breakpoints.up("460")]: {
      height: "115px",
      width: "115px",
    },
    [theme.breakpoints.up("sm")]: {
      height: "135px",
      width: "135px",
    },
    [theme.breakpoints.up("md")]: {
      height: "155px",
      width: "155px",
    },
  },

  Triste: {
    backgroundImage: (props) => (props.SelectTriste ? `url(${Triste2})` : `url(${Triste})`),
    "&:hover": {
      backgroundImage: `url(${Triste2})`,
    },
  },

  Miedoso: {
    backgroundImage: (props) => (props.SelectMiedoso ? `url(${Miedoso2})` : `url(${Miedoso})`),
    "&:hover": {
      backgroundImage: `url(${Miedoso2})`,
    },
  },

  Enojado: {
    backgroundImage: (props) => (props.SelectEnojado ? `url(${Enojado2})` : `url(${Enojado})`),
    "&:hover": {
      backgroundImage: `url(${Enojado2})`,
    },
  },

  Calmado: {
    backgroundImage: (props) => (props.SelectCalmado ? `url(${Calmado2})` : `url(${Calmado})`),
    "&:hover": {
      backgroundImage: `url(${Calmado2})`,
    },
  },
  Feliz: {
    backgroundImage: (props) => (props.SelectFeliz ? `url(${Feliz2})` : `url(${Feliz})`),
    "&:hover": {
      backgroundImage: `url(${Feliz2})`,
    },
  },
  Entusiasmado: {
    backgroundImage: (props) => (props.SelectEntusiasmado ? `url(${Entusiasmado2})` : `url(${Entusiasmado})`),
    "&:hover": {
      backgroundImage: `url(${Entusiasmado2})`,
    },
  },
  mood: {
    margin: "0px",
    font: "18px Fredoka One",
    textAlign: "center",
    letterSpacing: "0px",
    color: "#575757",
    [theme.breakpoints.up("sm")]: {
      font: "22.24px Fredoka One",
    },
  },
  BotonContinuar: {
    textTransform: "none",
    height: "62px",
    width: "238px",
    background: "#7A54BA",
    boxShadow: "0px 3px 6px #00000029",
    borderRadius: "61px",
    color: "#FFFFFF",
    font: "bold 20px lato ",
    "&:hover": {
      background: "#dd78fb",
    },
    "&:first-letter": {
      textTransform: "uppercase",
    },
  },

  ContainerBarraInferior: {
    background: "transparent linear-gradient(90deg, #DC5EA6 0%, #F58D7E 100%) 0% 0% no-repeat padding-box;",
    height: "79.5px",
  },
}));

const Emotions = (props) => {
  const { activeStep, setActiveStep, emotionSave, handleNextStep, setSelect } = props;
  const [SelectTriste, TristeSel] = useState(false);
  const [SelectEnojado, EnojadoSel] = useState(false);
  const [SelectMiedoso, MiedosoSel] = useState(false);
  const [SelectEntusiasmado, EntusiasmadoSel] = useState(false);
  const [SelectFeliz, FelizSel] = useState(false);
  const [SelectCalmado, CalmadoSel] = useState(false);
  const [pristine, setPristine] = useState(true);
 

  const saveEmotion = (select) => {
    setActiveStep(activeStep + 1);
    emotionSave()
    handleNextStep()
    setPristine(true)
  };

  // useEffect(()=> {

  // }, [select])

  const classes = useStyle({ SelectTriste, SelectEnojado, SelectMiedoso, SelectEntusiasmado, SelectFeliz, SelectCalmado });

  return (
    <Grid className={classes.Container}>
      <Grid container justifyContent="center" className={classes.ContainerEmotios}>
        <Grid container item sm={12} justifyContent="center">
          <Grid container item sm={12} justifyContent="center" alignContent="center">
            <p className={classes.Question}>{activeStep == 0 ? "¿Cómo te sientes hoy?" : "¿Cómo te sientes ahora?"}</p>
          </Grid>
          <Grid
            container
            item
            direction="column"
            lg={2}
            md={4}
            sm={4}
            xs={6}
            className={classes.Checkbox}
            onClick={() => {
              TristeSel(true);
              EnojadoSel(false);
              MiedosoSel(false);
              EntusiasmadoSel(false);
              FelizSel(false);
              CalmadoSel(false);
              setSelect(1);
              setPristine(false)
            }}
          >
            <Grid className={`${classes.Triste}  ${classes.Emocion}`}></Grid>
            <p className={classes.mood}>Triste</p>
          </Grid>
          <Grid
            container
            item
            direction="column"
            lg={2}
            md={4}
            sm={4}
            xs={6}
            className={classes.Checkbox}
            onClick={() => {
              TristeSel(false);
              EnojadoSel(false);
              MiedosoSel(true);
              EntusiasmadoSel(false);
              FelizSel(false);
              CalmadoSel(false);
              setSelect(2);
              setPristine(false)
            }}
          >
            <Grid className={`${classes.Miedoso}  ${classes.Emocion}`}></Grid>
            <p className={classes.mood}>Miedoso</p>
          </Grid>
          <Grid
            container
            item
            direction="column"
            lg={2}
            md={4}
            sm={4}
            xs={6}
            className={classes.Checkbox}
            onClick={() => {
              TristeSel(false);
              EnojadoSel(true);
              MiedosoSel(false);
              EntusiasmadoSel(false);
              FelizSel(false);
              CalmadoSel(false);
              setSelect(3);
              setPristine(false)
            }}
          >
            <div className={`${classes.Enojado}  ${classes.Emocion}`}></div>
            <p className={classes.mood}>Enojado</p>
          </Grid>
          <Grid
            container
            item
            direction="column"
            lg={2}
            md={4}
            sm={4}
            xs={6}
            className={classes.Checkbox}
            onClick={() => {
              TristeSel(false);
              EnojadoSel(false);
              MiedosoSel(false);
              EntusiasmadoSel(true);
              FelizSel(false);
              CalmadoSel(false);
              setSelect(4);
              setPristine(false)
            }}
          >
            <div className={`${classes.Entusiasmado}  ${classes.Emocion}`}></div>
            <p className={classes.mood}>Entusiasmado</p>
          </Grid>
          <Grid
            container
            item
            direction="column"
            lg={2}
            md={4}
            sm={4}
            xs={6}
            className={classes.Checkbox}
            onClick={() => {
              TristeSel(false);
              EnojadoSel(false);
              MiedosoSel(false);
              EntusiasmadoSel(false);
              FelizSel(true);
              CalmadoSel(false);
              setSelect(5);
              setPristine(false)
            }}
          >
            <div className={`${classes.Feliz}  ${classes.Emocion}`}></div>
            <p className={classes.mood}>Feliz</p>
          </Grid>
          <Grid
            container
            item
            direction="column"
            lg={2}
            md={4}
            sm={4}
            xs={6}
            className={classes.Checkbox}
            onClick={() => {
              TristeSel(false);
              EnojadoSel(false);
              MiedosoSel(false);
              EntusiasmadoSel(false);
              FelizSel(false);
              CalmadoSel(true);
              setSelect(6);
              setPristine(false)
            }}
          >
            <div className={`${classes.Calmado}  ${classes.Emocion}`}></div>
            <p className={classes.mood}>Calmado</p>
          </Grid>
        </Grid>
        <Grid container item lg={12} justifyContent="center" className="Boton">
          <Button onClick={saveEmotion} id="ContinuarCaritas" className={classes.BotonContinuar} disabled={pristine}>
            Continuar
          </Button>
        </Grid>
      </Grid>
      <Grid container item sm={12} justifyContent="center" className={classes.ContainerBarraInferior}></Grid>
    </Grid>
  );
};

export default Emotions;
