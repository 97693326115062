import React from "react";
import { makeStyles,Grid}   from '@material-ui/core';
import NavBar               from '../../../../NavBar';
import MenuIzquierdo        from '../../../../MenuIzquierdo';
import Activity             from '../../Activity';
import Lectura              from '../../../LecturasActividad/LecturasQuinto/Sesion39/Lectura';
import QuestionProv         from "../../QuestionProv";
import Question             from "../../Question";
import useGetExcercisesData from "../../../../../hooks/useGetExcercisesData";

const useStyles = makeStyles(theme=>({
  grid1:{    
    zIndex:'3'
    
  },
  grid2:{    
    zIndex:'2'
    
  },
  grid3:{    
    boxSizing:'border-box',
    zIndex:'1',
    paddingLeft:'3%',
    [theme.breakpoints.down('md')]:{
      paddingLeft:'0px',
    }
    
  }
}))

const ActivityS39 = (props) => {
  const classes = useStyles()
  const activityId = 293;
  const { data, step, setStep, questionList, setQuestionList } =
    useGetExcercisesData(activityId);
  const renderQuestion = () => {
    return(
      step === 9 ?
      <QuestionProv
      pregunta={ questionList.length > 0 ? questionList[step].pregunta : "cargando..."}
      step={step}/>
      :
      <Question
        step={step}
        pregunta={ questionList.length > 0 ? questionList[step].pregunta : "cargando..."}
      />
    )
  }

  return(
    <Grid container >
      <Grid item className={classes.grid1} sm={12}> 
        <NavBar/>
      </Grid>
      <Grid item className={classes.grid2} sm={1}> 
        <MenuIzquierdo/>
      </Grid>
      <Grid item className={classes.grid3} sm={12}>
        <Activity
          step={step}
          setStep={setStep}
          questionList={questionList}
          setQuestionList={setQuestionList}
          questionComponent={renderQuestion()}
          Lectura={Lectura}
          // imageList={imageList}
          // picturesTable={picturesTable}
          activityNumber={activityId}
          enrollmendId={data && data.id_actividad_inscripcion}
          tryNumber={data && data.intento}
          toSession={'/session39'}
        />
      </Grid>
    </Grid>
  )
}

export default ActivityS39;
