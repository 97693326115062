import React from "react";
import { Button, makeStyles } from "@material-ui/core";
import Dropzone from "react-dropzone";

import ModalMessage from "../../../Modals/ModalMessage";
import uploadIcon from "../../../imgs/upload.svg";
import CheckIcon from "@mui/icons-material/Check";

const useStyles = makeStyles((theme) => ({
  button: {
    backgroundColor: "#ffd963",
    borderRadius: "5px",
    width: "100%",
    height: "40px",
    padding: "0 20px",
    textTransform: "unset",
    fontSize: "12px",
    lineHeight: "1",
    [theme.breakpoints.up("md")]: {
      fontSize: "0.875rem",
    },
    "&:hover": {
      backgroundColor: "#ffd96380 ",
    },
    "& .MuiButton-label": {
      display: "flex",
      // justifyContent: "flex-start",
      // justifyContent: "center",
      justifyContent: (props) => (props.hasFile ? "flex-start" : "center"),
      gap: "20px",
      maxWidth: "18ch",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden",
      [theme.breakpoints.up("sm")]: {
        maxWidth: "250px",
      },
    },
  },
  container: {
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
    width: "100%",
    maxWidth: "475px",
  },
}));
const DropZoneButton = ({ handleDocument, file, title, type }) => {
  const hasFile = file !== null;
  const classes = useStyles({ hasFile });
  const [modalMessageOpen, setModalMessageOpen] = React.useState(false);
  const [confirmChangesResult, setConfirmChangesResult] = React.useState("");
  const limitInMb = 5;

  const handleCloseMessageModal = () => {
    setModalMessageOpen(!modalMessageOpen);
  };

  const formatBytes = (bytes) => {
    const limitInKb = limitInMb * 1000000;
    return bytes < limitInKb;
  };

  const uploadImage = async (file) => {
    const [selectedFile] = file;
    if (selectedFile) {
      if (formatBytes(selectedFile?.size)) {
        const base64 = await convertBase64(selectedFile);
        // handleNext();
        handleDocument({
          name: selectedFile?.name,
          size: selectedFile?.size,
          type: selectedFile?.type,
          base64: base64?.split(",")[1],
        });
      } else {
        setModalMessageOpen(true);
        setConfirmChangesResult(
          `La imagen seleccionada tiene que pesar menos de ${limitInMb} Mb.`
        );
      }
    } else {
      setModalMessageOpen(true);
      setConfirmChangesResult(
        `Asegúrese de que la imagen seleccionada tenga un formato ${type}.`
      );
    }
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };
  return (
    <>
      <Dropzone
        accept={type}
        multiple={false} // Only upload 1 file
        onDrop={(file) => {
          uploadImage(file);
        }}
      >
        {({ getRootProps, getInputProps }) => (
          <div {...getRootProps()} className={classes.container}>
            <input {...getInputProps()} />
            {!file ? (
              <Button className={classes.button} disabled={file}>
                <img src={uploadIcon} alt="" />
                {title}
              </Button>
            ) : (
              <>
                <Button className={classes.button}>
                  <CheckIcon color="success" />
                  {`${file?.name}`}
                </Button>
              </>
            )}
          </div>
        )}
      </Dropzone>
      <ModalMessage
        open={modalMessageOpen}
        handleClose={handleCloseMessageModal}
        title={confirmChangesResult}
      />
    </>
  );
};

export default DropZoneButton;
