import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
    ContenedorLectura:{
        width:'502px',
                  [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
        '& .Escritorio':{
            display:'flex',
            [theme.breakpoints.down(1161)]:{
                display:'none'
            },
        },
        '& .Tablet':{
            display:'none',
            [theme.breakpoints.down(1161)]:{
                display:'flex'
            },
        }
    },
    ContainerTitulo:{
        margin:'0 0 34px 0',
        '& .Titulo':{
            letterSpacing: '0px',
            color: '#57AD00',
            margin:'0',
            textAlign:'center',
            font:'43px fredoka one',
            lineHeight:'52px',
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            margin:'0 0 36px 0',
            color:'#000',
            textAlign:'center',
            font:'18px lato',
            lineHeight :'28px',
            letterSpacing: '0.17px',
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .Lectura2':{
            margin:'0',
        },
        '& .Pos':{
            position:'relative',
            right:'50px',
        },
        '& .Imagen':{
            width:'100%',
        },  
      
    },

}));

const El_sueño_de_las_montañas = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >
                        El sueño de las montañas
                    </h2>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                   
                    <Grid container sm={12}  justifyContent='center'>
                        <p className="Lectura Lectura2">
                            Soñé con nuestro continente: <br/>
                            era un lagarto hermoso. <br/>
                            Y aunque estaba en reposo <br/>
                            se veía imponente. <br/><br/>
                            
                            Desde Alaska hasta la Tierra de Fuego, <br/>
                            su cuerpo descansaba <br/>
                            en la Historia. Y llegaba <br/>
                            hasta nuestros días su sosiego. <br/><br/>
                            
                            Por entero las escamas <br/>
                            cubrían su piel <br/>
                            y eran un espejo fiel <br/>
                            de todas nuestras montañas. <br/><br/>
                            
                            Escamas las cordilleras <br/>
                            marcaban su anatomía <br/>
                            dando a su fisonomía <br/>
                            montes, sueños y banderas.
                        </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion21R/1.png" alt="Tarjeta" />
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    
                    <Grid container sm={12} justifyContent='center'>
                        <p className="Lectura Lectura2">
                            Los Andes varios países atraviesan. <br/>
                            Argentina, Perú, Bolivia y Chile <br/>
                            celebran un gran desfile <br/>
                            y con orgullo su hermandad <br/>
                            confiesan. <br/><br/>
                            
                            Desde el Aconcagua argentino <br/>
                            acaricié una estrella <br/>
                            donde dejaron su huella <br/>
                            la altura y lo divino. <br/><br/>
                            
                            Mi viaje terminó allí, en sus volcanes, <br/>
                            hornos subterráneos, <br/>
                            donde espontáneos <br/>
                            crecen nuestros talismanes. <br/><br/>

                            Cuando desperté, radiante <br/>
                            me vi, celeste viento, <br/>
                            cóndor en mi pensamiento: <br/>
                            testigo del paraíso un instante.
                        </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12} >
                        <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion21R/2.png" alt="Tarjeta" />
                    </Grid>
                </Grid>
                
        </Grid>
    </Grid>
    );
}
 
export default El_sueño_de_las_montañas;
