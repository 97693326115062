import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
    ContenedorLectura:{
        height:'100%',
        width:'520px',
                  [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
    },
    ContainerTitulo:{
        margin:'0 0 34px 0',
        '& .Titulo':{
            letterSpacing: '0px',
            color: '#3AB0E4',
            margin:'0',
            textAlign:'center',
            font:'43px fredoka one',
            lineHeight:'52px',
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            margin:'0 0 36px 0',
            color:'#000',
            font:'18px lato',
            lineHeight :'28px',
            letterSpacing: '0.17px',
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .SubTitulo':{
            color:'#FE581F',
            margin:'0',
            lineHeight :'28px',
            font:'bold 26px lato',
            letterSpacing: '0.24px',
            [theme.breakpoints.down(502)]:{
                font:'bold 18px lato',
                lineHeight :'24px',
                letterSpacing: '0.21px',
            },
        },
        '& .Esp':{
            margin:'0',
        },
        '& .Esp1':{
            margin:'0',
            padding:'0 25px 0 0',
            [theme.breakpoints.down(520)]:{
                padding:'0 ',
            },
        },
        '& .Esp2':{
            padding:'0 ',
        },
        '& .ul':{
            padding:'0 0 0 15px',
            margin:'0 0 36px 0',
            color:'#FE581F',
        },
        '& .li':{
            margin:'0 0 8px 0',
            [theme.breakpoints.down(502)]:{
                margin:'0 0 4px 0',
            },
        },
        '& .Color':{
            color:'#3AB0E4',
        },

        '& .Imagen':{
            width:'100%',
        },  
      
    }
}));

const Los_tipos_de_color = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>

            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                        <h2 className="Titulo">Los tipos de color</h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                       <p className='Lectura Esp1'>
                        <h3 className='SubTitulo '>El color</h3><br/>

                        ¿Te has puesto a pensar de qué color es el mundo? Tal vez pienses en el amarillo del 
                        Sol, el azul del mar o el rosa de las flores. Con tantos colores, ¡el mundo es un arcoíris 
                        único!<br/><br/>

                        Todos los colores del mundo se dividen en tres grupos: primarios, secundarios y terciarios.
                       </p>  

                        <ul className='ul'>
                            <li className='li'>
                                <p className='Lectura Esp'>
                                    <span className="Color">Colores primarios:</span> son tres colores y también se les llama colores básicos, 
                                    porque no hay otra mezcla que pueda crearlos o imitarlos. Éstos son el amarillo, como el Sol, el 
                                    azul, como el cielo, y el rojo, como una fresa.
                                </p>
                                
                            </li>
                            <li className='li'>
                                <p className='Lectura Esp'>
                                    <span className="Color"> Colores secundarios:</span> son el resultado de la mezcla de dos colores primarios. 
                                    Los colores secundarios son el violeta, que es una mezcla de rojo y azul, como el color de 
                                    una mora. El verde, que es una mezcla del amarillo y el azul, como el color del pasto. Y 
                                    el anaranjado, que es una mezcla del rojo y el amarillo, ¡como una naranja!
                                </p>
                            </li>
                            <li className='li'>
                                <p className='Lectura Esp'>
                                    <span className="Color">Colores terciarios:</span> son todos los demás colores que puedes ver. Estos colores son la 
                                    combinación de un color primario y uno secundario. Por ejemplo, si mezclamos el azul 
                                    con el verde tendremos un azul turquesa, como el color del mar.
                                </p>
                            </li>
                            
                        </ul>        
                        <p className='Lectura Esp2'>
                            <h3 className='SubTitulo'>El círculo cromático</h3><br/>

                            El círculo cromático es una herramienta que te puede ayudar a identificar los diferentes tipos de color. 
                            Este círculo organiza los colores primarios, secundarios y terciarios de acuerdo con sus posibles combinaciones.

                        </p>         
                    </Grid>
                    <Grid container justifyContent='center'>
                        <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img1ro/Sesion5/1.png" alt="Tarjeta" />
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container >
                        <p className='Lectura'>
                            Ahora que conocemos los tipos de colores, estás listo para dejar salir a tu artista interior.
                        </p>
                    </Grid>
                </Grid>
            </Grid>
    </Grid>
    );
}
 
export default Los_tipos_de_color;
