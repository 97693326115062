import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
    ContenedorLectura:{
        width:'502px',
        padding:'60px 0 0 0',
        [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
    },
    ContainerTitulo:{
        margin:'0 0 34px 0',
        '& .Titulo':{
            letterSpacing: '0px',
            color: '#E97BA8',
            margin:'0',
            textAlign:'center',
            font:'43px fredoka one',
            lineHeight:'52px',
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            margin:'0 0 36px 0',
            color:'#000',
            font:'18px lato',
            lineHeight :'28px',
            letterSpacing: '0.17px',
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .SubTitulo':{
            color:'#0CB5D6',
            margin:'0',
            lineHeight :'28px',
            font:'bold 26px lato',
            letterSpacing: '0.24px',
            [theme.breakpoints.down(502)]:{
                font:'bold 18px lato',
                lineHeight :'24px',
                letterSpacing: '0.21px',
            },
        },
        '& .SubTitulo2':{
            color:'#EA4649',
            margin:'0',
            font:'bold 22px lato',
            lineHeight :'24px',
            letterSpacing: '0.21px',
            [theme.breakpoints.down(502)]:{
                font:'bold 18px lato',
                lineHeight :'20px',
                letterSpacing: '0.16px',
            },
        },
        '& .TextImg':{
            color:'#717476',
            margin:'24px 0 0 0',
            font:' 16px lato',
            lineHeight :'25px',
            letterSpacing: '0.15px',
            [theme.breakpoints.down(502)]:{
                font:' 11px lato',
                lineHeight :'14px',
                letterSpacing: '0.10px',
            },
        },

        '& .Imagen':{
            width:'100%',
        },  
      
    }
}));

const Cómo_se_hace_la_ropa_que_traes_puesta = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                        <h2 className="Titulo">¿Cómo se hace la ropa <br/>que traes puesta?</h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                       <p className='Lectura'>
                        La ropa llega a ti de distintas maneras: te la regalan, la compras en una tienda, la 
                        heredas de alguien más. Pero el verdadero origen de estas prendas está en distintos 
                        materiales de la naturaleza, al igual que ocurre con el resto de los productos que 
                        consumes. Y debes saber que procesar estos materiales para producir ropa implica 
                        un impacto ambiental bastante alto.<br/><br/> 
                        
                        <h3 className='SubTitulo'>El proceso</h3><br/>
                        
                        Lo primero que se necesita para producir ropa es tela y fabricarla lleva un proceso 
                        bastante complicado. La tela es un entretejimiento de fibras naturales, artificiales o 
                        sintéticas. Las naturales se extraen de algunas plantas. La más popular es el algodón, 
                        pero también se usan el yute, el lino o el cáñamo. Otros materiales textiles se 
                        obtienen de animales como borregos y vacas. <br/><br/> 
                        
                        Las telas de fibras artificiales tienen el mismo origen que las naturales, pero se 
                        someten a ciertos tratamientos químicos. Por su parte, las sintéticas (el <em>spandex</em>, el 
                        <em> nylon</em> y el poliéster) casi siempre se generan a partir de derivados del petróleo y son 
                        bastante resistentes. Se hace mucha ropa con estas fibras, y es barata en 
                        comparación con las prendas de los dos tipos anteriores. <br/><br/> 
                        
                        Con las fibras se elaboran hilos, y éstos se tejen entre sí para formar la tela. 
                        Actualmente se usan máquinas industriales para este proceso. Luego se tiñe la tela y 
                        se le dan los últimos detalles mediante procesos químicos. Después se crean las 
                        prendas. Para eso se necesitan hilos, maquinaria y <em>manufactura</em> (es decir, trabajo 
                        humano).
                       </p>                   
                    </Grid>
                    <Grid container justifyContent='center'>
                        <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion45/1.png" alt="Tarjeta" />
                    </Grid>
                    <Grid container >
                        <p className="TextImg">
                        Aunque ahora los hilos se fabrican con máquinas automáticas, durante mucho tiempo se usaron dos artefactos manuales: el huso y la rueca.
                        </p>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container >
                        <p className='Lectura'>
                            <h3 className='SubTitulo'>Problemas y soluciones</h3><br/>
                            
                            Imagínate cuánta agua se utiliza para la producción de ropa. Se estima que para 
                            hacer unos tenis deportivos se gastan 4 400 litros y 1 500 para una playera. Piensa 
                            que esa agua se usa para teñir la tela y someterla a procesos químicos. ¿Cómo crees 
                            que terminará? ¿Cuántos contaminantes saldrán de las fábricas de ropa? <br/><br/>
                            
                            La industria textil ya está buscando nuevas maneras para producir ropa con fibras y 
                            materiales reciclados, lo cual es necesario ante la emergencia climática que atraviesa 
                            el planeta. Tú también puedes ayudar a reducir la contaminación que genera esta 
                            industria. Te sugerimos dos posibilidades: ropa de segunda mano y traspaso de
                            prendas. <br/><br/>
                            
                            <h3 className='SubTitulo2'>Segunda mano</h3><br/>
                            
                            La ropa sirve durante mucho tiempo. Cada vez más gente decide vender las prendas 
                            que ya no quiere en lugar de desecharlas, cuando éstas aún están en buenas 
                            condiciones. La ropa usada es mucho más barata que la nueva. Para comprar puedes 
                            investigar dónde hay bazares o estar atento a las ventas de garaje. ¡Incluso pueden 
                            adquirirse por internet!
                        </p>
                    </Grid>
                    <Grid container justifyContent='center'>
                        <Grid container justifyContent='center'>
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion45/2.png" alt="Tarjeta" />
                        </Grid>
                        <Grid container justifyContent='center'>
                            <p className="TextImg">
                                A veces los bazares de ropa también son ambientes agradables de convivencia.
                            </p>
                        </Grid>
                    </Grid>

                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container >
                        <p className='Lectura'>
                            <h3 className='SubTitulo2'>Traspaso</h3><br/>
                            
                            Cuando la ropa ya no te quede puedes regalársela a alguien en lugar de tirarla a la 
                            basura. Si te aburre tu guardarropa haz intercambio con tus conocidos, amigos o 
                            familiares. También préstense prendas entre ustedes.<br/><br/>
                            
                            <h3 className='SubTitulo2'>¿Más formas?</h3><br/>
                            
                            Incluso puedes ser creativo y convertir unos pantalones con agujeros en un 
                            pantaloncillo corto, o un vestido con mangas rotas en un vestido sin mangas.<br/><br/>
                            
                            ¿Se te ocurren otras maneras de reutilizar la ropa? ¿Hay otras formas en las que
                            podrías contribuir para minimizar la producción contaminante de la industria textil?
                        </p>
                    </Grid>
                </Grid>
        </Grid>
    </Grid>
    );
}
 
export default Cómo_se_hace_la_ropa_que_traes_puesta;
