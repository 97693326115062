import React                from "react";
import { makeStyles,Grid }  from '@material-ui/core';

const useStyles = makeStyles(theme =>({
  badgesContainer:{
    position:'relative',
    top:'10px',
    width:"160px",
    height: "160px",
    [theme.breakpoints.down(1024)]:{
      top:'5px',
      width:"120px",
      height:"120px",
    },
    [theme.breakpoints.down(700)]:{
      top:'0px',
      width:"60px",
      height:'63px',
    },
    // [theme.breakpoints.down(769)]:{
    //   top:'5px',
    //   width:"120px",
    //   height:"120px",
    // },
  },
  emotionalText:{
    border: "#1B21AE 1px solid",
    borderRadius: "25px",
    font:'bold 19px lato ',
    // color:"#625D5C",
    letterSpacing: '.723px',
    backgroundColor: "#FFFEBF",
    [theme.breakpoints.down(1024)]:{
      font:'bold 14px lato ',
    },
    [theme.breakpoints.down(700)]:{
      font:'bold 10px lato ',
    },
  },
  readingText:{
    border: "#1B21AE 1px solid",
    borderRadius: "25px",
    font:'bold 19px lato ',
    color:"#625D5C",
    letterSpacing: '.723px',
    [theme.breakpoints.down(1024)]:{
      font:'bold 14px lato ',
    },
    [theme.breakpoints.down(700)]:{
      font:'bold 10px lato ',
    },
  },
  noobP:{
    margin:"8px 5px 8px 5px",
    [theme.breakpoints.down(700)]:{
      margin: '5px 3px 5px 3px',
    },
  }

}))

const LevelBadge = (props) => {
  const classes = useStyles()
  const {badgeLevelImage, badgeLevelText, badgesStyle} = props

  const printStyle = () => {
    return(
      badgesStyle ? classes.emotionalText : classes.readingText
    )
  }

  return(
    <Grid container item xs={6} sm={3} justifyContent="center" alignItems="center">
      <Grid container direction="column" justifyContent="center" alignItems="center">
        <img className={classes.badgesContainer} src={badgeLevelImage} alt="principiante"/>
        <Grid className={badgesStyle ? classes.emotionalText : classes.readingText}><p className={classes.noobP}>{badgeLevelText}</p></Grid>
      </Grid>
    </Grid>
  )
}

export default LevelBadge