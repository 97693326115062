import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
    ContenedorLectura:{
        width:'502px',
                  [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
        '& .Escritorio':{
            display:'flex',
            [theme.breakpoints.down(1161)]:{
                display:'none'
            },
        },
        '& .Tablet':{
            display:'none',
            [theme.breakpoints.down(1161)]:{
                display:'flex'
            },
        }
    },
    ContainerTitulo:{
        margin:'0 0 34px 0',
        [theme.breakpoints.down(1161)]:{
            margin:'0 0 34px 0',
        },
        '& .Titulo':{
            letterSpacing: '0px',
            color: '#1DA698',
            margin:'0',
            textAlign:'center',
            font:'43px fredoka one',
            lineHeight:'52px',
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            margin:'0 0 36px 0',
            color:'#000',
            font:'18px lato',
            lineHeight :'28px',
            letterSpacing: '0.17px',
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .Esp':{
            padding:'0 ',
            margin:'0 0 36px 0',
        },

        '& .SubTitulo':{
            color:'#156A8D',
            margin:'0',
            textAlign:'center',
            font:'bold 22px lato',
            lineHeight :'24px',
            letterSpacing: '0.21px',
            [theme.breakpoints.down(502)]:{
                font:'bold 18px lato',
                lineHeight :'20px',
                letterSpacing: '0.16px',
            },
        },
        '& .TextImg':{
            color:'#717476',
            margin:'24px 0 0 0',
            font:' 16px lato',
            lineHeight :'25px',
            letterSpacing: '0.15px',
            [theme.breakpoints.down(502)]:{
                font:' 11px lato',
                lineHeight :'14px',
                letterSpacing: '0.10px',
            },
        },
        '& .Esp2':{
            padding:'0',
        },
        '& .Imagen':{
            width:'100%',
        },  
      
    },

}));

const Carretera_panamericana = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >
                    Carretera <br />Panamericana
                    </h2>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container justifyContent='center' xs={12} >
                        <p className="Lectura ">
                            <h3 className='SubTitulo'> 
                            La carretera más larga del mundo,<br/>
                            un escenario de récords mundiales
                            </h3>
                        </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12} >
                        <Grid container justifyContent='center'>
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img2do/Sesion39R/1.png" alt="Tarjeta" />
                        </Grid>
                        <Grid container item justifyContent='center'>
                            <p className='TextImg '>
                                La Carretera Panamericana pasa por Canadá, Estados Unidos, México, Guatemala, El Salvador, Honduras, 
                                Nicaragua, Costa Rica, Panamá, Colombia, Ecuador, Perú, Chile y Argentina.
                            </p>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    
                    <Grid container justifyContent="center" xs={12} >
                        <p className="Lectura Esp">
                            De acuerdo con el historiador 
                            norteamericano Eric Rutkow, la vía 
                            Panamericana representa “el sueño de 
                            una América unida por la amistad, el 
                            comercio y la movilidad”. Se compone 
                            por muchas carreteras y conecta los 
                            extremos norte y sur del continente, 
                            atravesando 14 países. Comienza en la
                            Bahía de Prudhoe, Alaska, y termina en 
                            Ushuaia, Argentina. <br/><br/>
                           
                            Tiene una longitud aproximada de 30 
                            000 km y se interrumpe en la frontera 
                            entre Panamá y Colombia. Ese tramo no 
                            ha sido construido por muchas razones, 
                            como la densa selva que predomina ahí, 
                            la presencia de comunidades indígenas y 
                            las actividades de grupos criminales en 
                            la zona. A pesar de esto, tiene el récord 
                            mundial por ser el camino más largo 
                            para vehículos motorizados 
                            (automóviles, motocicletas y camiones).
                        </p>
                    </Grid>

                    <Grid container xs={12} >
                        <Grid container justifyContent='center'>
                            <img className="Imagen " src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img2do/Sesion39R/2R.png" alt="Tarjeta" />
                        </Grid>
                        <Grid container item justifyContent='center'>
                            <p className='TextImg Esp2'>
                                Certificado de récord Guinness. Guinness World Records es una publicación anual en la que se registran récords mundiales de todo tipo.
                            </p>
                        </Grid>
                    </Grid>
                   
                </Grid>

                <Grid container item xs={12} className={classes.Parrafo1} justifyContent="center">
                
                    <Grid container item>
                        <p className="Lectura ">
                            Además, esta vía también ha sido el escenario de otros récords. Por ejemplo, en 2018 el 
                            exmilitar británico Dean Stott consiguió el viaje en bicicleta más rápido de la carretera: 
                            ¡tardó sólo 110 días en recorrerla! Anteriormente, la marca era del mexicano Carlos 
                            Santamaría Covarrubias, quien en 2016 hizo el recorrido en 117 días.<br/> <br/>
                            
                            Otro récord notable ocurrido en la vía Panamericana fue el de George Meegan. En 1983 
                            obtuvo un reconocimiento por haber realizado el recorrido más rápido a pie de la 
                            carretera. El viaje le tomó 2 426 días. Lo comenzó en Argentina el 26 de enero de 
                            1977 y lo concluyó en Alaska en 1983. Hasta la fecha nadie ha roto esta marca. ¿Te 
                            imaginas hacer un viaje así de largo caminando?
                        </p>
                    </Grid>
                </Grid>
        </Grid>
    </Grid>
    );
}
 
export default Carretera_panamericana;
