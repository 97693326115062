import React                from "react";
import { makeStyles,Grid }  from '@material-ui/core';
import Divider              from '@material-ui/core/Divider';


import emocional1                          from './badgesImages/Insignia_emocional1.png'
import emocional2                          from './badgesImages/Insignia_emocional2.png'
import emocional3                          from './badgesImages/Insignia_emocional3.png'

import holograma4                          from './badgesImages/Insignia_holograma4.png'
import holograma5                          from './badgesImages/Insignia_holograma5.png'
import holograma6                          from './badgesImages/Insignia_holograma6.png'

import principianteA                       from './badgesImages/Insignia_corazon1.png'
import aprendizA                           from './badgesImages/Insignia_corazon2.png'
import experimentadoA                      from './badgesImages/Insignia_corazon3.png'
import expertoA                            from './badgesImages/Insignia_corazon4.png'

import principiante                        from './colorlessImages/Insignia_corazon1A.png'
import aprendiz                            from './colorlessImages/Insignia_corazon2A.png'
import experimentado                       from './colorlessImages/Insignia_corazon3A.png'
import experto                             from './colorlessImages/Insignia_corazon4A.png'

import MainBadge                           from "./MaindBadge";
import LevelBadge                          from "./LevelBadge";


const useStyles = makeStyles(theme =>({
  mainContainer: {
    width:'100vw',
    padding: "62px 0 0 0",
    [theme.breakpoints.down(1440)]:{
      padding:'52px 0 0 20px ',
    },
    [theme.breakpoints.down(1024)]:{
      padding:'37px 0 0 0px ',
    },
    [theme.breakpoints.down(700)]:{
      padding:'17px 0 0 0px ',
    },
    
  },
  mainContainer2:{
    borderRadius: "20px",
    border: "#E24672 3px solid",
    boxShadow: '0px 2px 5px #0000006C',
    maxWidth: '1380px',
    // width:"1380px",
    // height: "901px",
  },
  cuadrado:{
    height:'201px',
    width: "198px"
  },
  bolita:{
    backgroundColor: '#E076B8',
    border: "#707070 1px solid",
    borderRadius: "90px",
    maxWidth: '1380px',
    // height:'138px',
    // width: "139px"
    
  },
  bolita2:{
    backgroundColor: '#CC8CB4',
    border: "#707070 1px solid",
    borderRadius: "90px",
    height:'138px',
    width: "139px"
  },
  bolita3:{
    backgroundColor: '#EFB6D9',
    border: "#707070 1px solid",
    borderRadius: "90px",
    height:'138px',
    width: "139px"
  },
  bolita4:{
    backgroundColor: '#EFB3D8',
    border: "#707070 1px solid",
    borderRadius: "90px",
    height:'138px',
    width: "139px"
  },
  autoconocimiento1: {
    width:"194px",
    height: "194px"
  },
  badgesTitle:{
    font:'38px Fredoka One',
    letterSpacing: '0.28px',
    color: '#E24672',
    marginBottom: '0',
    [theme.breakpoints.down(700)]:{
      font:'30px Fredoka One',
    },
    [theme.breakpoints.down(500)]:{
      font:'24px Fredoka One',
      textAlign: "center",
    },
  },
  title:{
    font:'21px Fredoka One',
    letterSpacing: '0.38px',
    color: '#5CAE9A',
    margin:'0px',
  },
  begginerText:{
    border: "#C75539 1px solid",
    borderRadius: "16px",
    font:'21px lato bold',
    color:"#625D5C",
    letterSpacing: '.723px',
  },
  noobP:{
    margin:"8px 5px 8px 5px"
  },
  divider:{
    marginLeft: "5%",
    marginRight: "5%",
    width:"90%",
    color: "#E24672",
    borderBottom: "#E24672 1px solid",
  },
  badgesContainer:{
    maxHeight: '350px',
    margin: '30px 0',
    [theme.breakpoints.down(600)]:{
      maxHeight: '250px',
      margin: '20px 0',
    },
  }
}));

const BadgesSocioEmocional = (props) => {
  const classes = useStyles()
  const { socioBadges } = props

  return(
    <Grid 
      container
      direction="column"
      item 
      sm={12}
      className={classes.mainContainer}
      justifyContent="center" 
      alignItems="center"
    >
      <Grid container className={classes.mainContainer2}> 
        <Grid container item xs={12}>
          <Grid container item xs={12} justifyContent="center" alignItems="center">
            <h2 className={classes.badgesTitle}>Insignias socioemocionales</h2>
          </Grid>
        </Grid>
        <Grid container item xs={12} direction="column" justifyContent="center" alignItems="center" className={classes.badgesContainer}>
          <Grid container item xs={3}>
            <MainBadge
              badgeImage={socioBadges > 79 ? holograma4 : emocional1}
              badgeText={"Autoconocimiento"}
            />
          </Grid>
          <Grid container item xs={8} direction="row" justifyContent="space-around">
            <LevelBadge
              badgeLevelImage={socioBadges > 4 ? principianteA  : principiante}
              badgeLevelText={"Principiante"}
              badgesStyle={socioBadges > 4}
            />
            <LevelBadge
              badgeLevelImage={socioBadges > 29 ? aprendizA : aprendiz}
              badgeLevelText={"Aprendiz"}
              badgesStyle={socioBadges > 29}
            />
            <LevelBadge
              badgeLevelImage={socioBadges > 54 ? experimentadoA :experimentado}
              badgeLevelText={"Experimentado"}
              badgesStyle={socioBadges > 54}
            />
            <LevelBadge
              badgeLevelImage={socioBadges > 79 ? expertoA : experto}
              badgeLevelText={"Experto"}
              badgesStyle={socioBadges > 79}
            />
          </Grid>
          {/* <Grid container item xs={1}></Grid> */}
        </Grid>
        <Divider classes={{root: classes.divider}}variant="middle" />
        <Grid container item xs={12} direction="column" justifyContent="center" alignItems="center" className={classes.badgesContainer}>
          <Grid container item xs={3}>
            <MainBadge
              badgeImage={socioBadges > 89 ? holograma5 : emocional2}
              badgeText={"Autorregulación"}
            />
          </Grid>
          <Grid container item xs={8} direction="row" justifyContent="space-around">
            <LevelBadge
              badgeLevelImage={socioBadges > 14 ? principianteA : principiante}
              badgeLevelText={"Principiante"}
              badgesStyle={socioBadges > 14}
            />
            <LevelBadge
              badgeLevelImage={socioBadges > 39 ? aprendizA : aprendiz}
              badgeLevelText={"Aprendiz"}
              badgesStyle={socioBadges > 39 }
            />
            <LevelBadge
              badgeLevelImage={socioBadges > 64 ? experimentadoA : experimentado}
              badgeLevelText={"Experimentado"}
              badgesStyle={socioBadges > 64}
            />
            <LevelBadge
              badgeLevelImage={socioBadges > 89 ? expertoA : experto}
              badgeLevelText={"Experto"}
              badgesStyle={socioBadges > 89}
            />
          </Grid>
        </Grid>
        <Divider classes={{root: classes.divider}}variant="middle" />
        <Grid container item xs={12} direction="column" justifyContent="center" alignItems="center" className={classes.badgesContainer}>
          <Grid container item xs={3}>
            <MainBadge
              badgeImage={socioBadges > 99 ? holograma6 : emocional3}
              badgeText={"Empatía"}
            />
          </Grid>
          <Grid container item xs={8} direction="row" justifyContent="space-around">
            <LevelBadge
              badgeLevelImage={socioBadges > 19 ? principianteA : principiante}
              badgeLevelText={"Principiante"}
              badgesStyle={socioBadges > 19}
            />
            <LevelBadge
              badgeLevelImage={socioBadges > 49 ? aprendizA : aprendiz}
              badgeLevelText={"Aprendiz"}
              badgesStyle={socioBadges > 49}
            />
            <LevelBadge
              badgeLevelImage={socioBadges > 74 ? experimentadoA : experimentado}
              badgeLevelText={"Experimentado"}
              badgesStyle={socioBadges > 74 }
            />
            <LevelBadge
              badgeLevelImage={socioBadges > 99 ? expertoA : experto}
              badgeLevelText={"Experto"}
              badgesStyle={socioBadges > 99 }
            />
          </Grid>
          {/* <Grid container item xs={1}></Grid> */}
        </Grid>
      </Grid>
    </Grid>
  )
}

export default BadgesSocioEmocional;
