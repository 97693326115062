import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
    ContenedorLectura:{
        height:'100%',
        width:'502px',
        [theme.breakpoints.down(502)]:{
        width:'350px',
        },
    },
    ContainerTitulo:{
        margin:'0 0 34px 0',
        '& .Titulo':{
            letterSpacing: '0px',
            color: '#0CB5D6',
            margin:'0',
            textAlign:'center',
            font:'43px fredoka one',
            lineHeight:'52px',
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            margin:'0 0 36px 0',
            color:'#000',
            font:'18px lato',
            lineHeight :'28px',
            letterSpacing: '0.17px',
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .TextImg':{
            color:'#717476',
            margin:'24px 0 0 0',
            font:' 16px lato',
            lineHeight :'25px',
            letterSpacing: '0.15px',
            [theme.breakpoints.down(502)]:{
                font:' 11px lato',
                lineHeight :'14px',
                letterSpacing: '0.10px',
            },
        },

        '& .Imagen':{
            width:'100%',
        },  
        '& .Imagen2':{
            width:'100%',
        },  
      
    }
}));

const Todos_queremos_lo_mismo = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                        <h2 className="Titulo ">Todos queremos<br/> lo mismo</h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                       <p className='Lectura'>
                        ¿Alguna vez te han tratado mal por ser diferente? Quizás algún compañero se burló 
                        de tu forma de caminar o de tu manera de hablar. Tal vez te hicieron un comentario 
                        desagradable por la ropa que traías puesta o incluso por tu estatura. Es posible que 
                        hayas recibido amenazas o hasta golpes. Y si a ti no te ha pasado, seguro conoces a 
                        alguien a quien sí. A todas estas formas de maltrato entre compañeros se les conoce 
                        como <em>acoso escolar</em> o <em>bullying</em>. Esta situación es grave y puede llegar a tener 
                        consecuencias importantes en la salud física y mental de todos.
                       </p>                   
                    </Grid>
                    <Grid container justifyContent='center'>
                        <Grid container item justifyContent='center'>
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img1ro/Sesion9/1.png" alt="Tarjeta" />
                        </Grid>
                        <Grid container justifyContent='center' >
                            <p className="TextImg">
                                El acoso escolar puede tomar muchas formas.
                            </p>
                        </Grid>
                    </Grid>
                    
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container >
                        <p className='Lectura'>
                            Para terminar con el acoso escolar es necesario tener en cuenta aquello en lo que 
                            todos nos parecemos. Comienza por pensar que todos buscamos lo mismo: 
                            queremos ser felices y sentirnos bien, ¿no crees? Piensa en esto: realizamos la 
                            mayoría de nuestras acciones para sentirnos bien. Si tenemos frío, nos tapamos. Si 
                            tenemos sed, tomamos agua. Si tenemos sueño, dormimos. 
                            <br/><br/>
                            Pero si pudiéramos entender que en el fondo todos somos iguales y que al igual que 
                            tú todos aquellos que nos rodean merecen sentirse bien, dejaríamos de hacerles 
                            daño o de permitir que alguien más les hiciera daño. ¡Sé feliz y permite a los demás 
                            ser felices!
                        </p>
                    </Grid>
                    <Grid container justifyContent='center'>
                        <Grid container justifyContent='center'>
                            <img className="Imagen2" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img1ro/Sesion9/2.png" alt="Tarjeta" />
                        </Grid>
                        <Grid container justifyContent='center' >
                            <p className="TextImg">
                                Al entender a los demás, nos permitimos ser felices.                            
                            </p>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
    </Grid>
    );
}
 
export default Todos_queremos_lo_mismo;
