import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
    ContenedorLectura:{
        position:'relative',
        width:'502px',
        [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
        '& .Escritorio':{
            display:'block',
            [theme.breakpoints.down(1161)]:{
                display:'none'
            },
        },
        '& .Tablet':{
            display:'none',
            [theme.breakpoints.down(1161)]:{
                display:'block'
            },
        }
    },
    ContainerTitulo:{
        margin:'0',
        '& .Titulo':{
            textAlign:'center',
            letterSpacing: '0px',
            color: '#926DD1',
            margin:'0',
            font:'43px fredoka one',
            lineHeight:'52px',
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Cauadro:{
        position:'absolute',
        border:'5px #fff solid',
        background:'rgba(255, 255, 255, 0.65)',
        borderRadius: '50px',
       ' -webkit-backdrop-filter': 'blur(30px)',
        backdropFilter:'blur(30px)',
        left:'32px',
        width: '438px',
        [theme.breakpoints.down(502)]:{
            borderRadius: '25px',
            left:'15px',
            width: '320px',
        },
        '& .TextoCuadro':{
            color:'#000',
            font:'18px lato',
            lineHeight :'28px',
            letterSpacing: '0.17px',
            margin:'15px 3px 0px 24px',
            [theme.breakpoints.down(502)]:{
                margin:'10px 3px 0px 10px',
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
        },
    },
    Cauadro1:{
        height:'220px',
        top:'489px',
        [theme.breakpoints.down(502)]:{
            height:'120px',
            top:'405px',
        },
    },
    Cauadro2:{
        '& .TextoCuadro':{
            margin:'10px 3px 0px 24px',
        },
        height:'85px',
        top:'1145px',
        [theme.breakpoints.down(502)]:{
            '& .TextoCuadro':{
                margin:'10px 3px 0px 10px',
            },
            height:'85px',
            top:'855px',
        },
    },

    Cauadro3:{
        '& .TextoCuadro3':{
            margin:'8px 5px 0 0px',
        },
        width:'487px',
        left:'7.5px',
        height:'53px',
        top:'1710px',
        [theme.breakpoints.down(502)]:{
            '& .TextoCuadro3':{
                margin:'8px 0px 0 8px',
            },
            left:'0px',
            width:'360px',
            height:'65px',
            top:'1250px',
        },
        

    },

    Cauadro4:{
        '& .TextoCuadro4':{
            margin:'10px 0 0 0',
        },
        width:'511px',
        left:'0px',
        height:'53px',
        top:'2127px',
        [theme.breakpoints.down(502)]:{
            '& .TextoCuadro4':{
                margin:'8px 0 0 8px',
            },
            left:'0px',
            width:'360px',
            height:'65px',
            top:'1632px',
        },
    },

    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Subtitulo':{
            font:'bold 30px lato',
            color:'#FF0000',
            margin:'0 0 ',
        },
        '& .Lectura':{
            margin:'0 0 28px',
            color:'#000',
            font:'18px lato',
            lineHeight :'28px',
            letterSpacing: '0.17px',
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .Lectura1':{
            margin:'0 0 8px',
            color:'#000',
            font:'18px lato',
            lineHeight :'28px',
            letterSpacing: '0.17px',
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .Imagen':{
            width:'100%'
        },
        '& .Imagen2':{
            margin:'145px 0 0 0',
            [theme.breakpoints.down(502)]:{
                margin:'100px 0 0 0'
            },
        },

    }
}));

const Otro_mirar = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >Otro mirar</h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container direction="column" justifyContent="center" sm={12}>
                        <Grid container  justifyContent="center" >
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img1ro/Sesion27/1.png" alt="Tarjeta" />
                        </Grid>
                         <Grid container item className={`${classes.Cauadro} ${classes.Cauadro1}`}>
                            <p className="TextoCuadro ">
                            —¿Cómo son los colores? —me preguntó.<br/> <br/>

                            —El amarillo es como la piña, la miel  —respondi—. Se siente como el Sol.<br/> <br/>
                            
                            —El verde es el limón, el pasto recién cortado. Pero es también el olor a
                            árboles y la forma de un trébol.
                            </p>
                         </Grid>
                        
                    </Grid>
                </Grid> 

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container direction="column" justifyContent="center" sm={12}>
                        <Grid container   justifyContent="center" >
                            <img className="Imagen Imagen2" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img1ro/Sesion27/2.png" alt="Tarjeta" />
                        </Grid>
                         <Grid container item className={`${classes.Cauadro} ${classes.Cauadro2}`}>
                            <p className="TextoCuadro">
                            —El azul se siente como la tristeza. Un día con nubes y lluvia. Suena 
                            como las olas del mar.
                            </p>
                         </Grid>
                    </Grid>
                </Grid>  

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container direction="column" justifyContent="center" sm={12}>
                        <Grid container   justifyContent="center" >
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img1ro/Sesion27/3.png" alt="Tarjeta" />
                        </Grid>
                         <Grid container item className={`${classes.Cauadro} ${classes.Cauadro3}`}>
                            <p className="TextoCuadro TextoCuadro3">
                            —El café es la tierra y se escucha como las hojas de otoño.
                            </p>
                         </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container direction="column" justifyContent="center" sm={12}>
                        <Grid container   justifyContent="center" >
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img1ro/Sesion27/4.png" alt="Tarjeta" />
                        </Grid>
                         <Grid container item className={`${classes.Cauadro} ${classes.Cauadro4}`}>
                            <p className="TextoCuadro TextoCuadro4">
                            —El rojo es el calor de un abrazo. Es las fresas y las frambuesas.
                            </p>
                         </Grid>
                    </Grid>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                      <p className="Lectura">
                        —El negro… —alcancé a decir, pero me interrumpió.<br/> <br/>
                        
                        —El negro lo conozco bien —añadió entusiasmada—. En él caben todos los colores, 
                        sus sonidos y sabores. Cuando los toco, algunos gritan y otros susurran. Unos son 
                        tersos y otros ásperos y granulosos. Los colores hablan con mis dedos y les 
                        cuentan que son duendes.
                      </p>
                    </Grid>
                    <Grid container direction="column" justifyContent="center" sm={12}>
                        <Grid container  justifyContent="center" >
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img1ro/Sesion27/5.png" alt="Tarjeta" />
                        </Grid>
                    </Grid>
                </Grid> 
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                      <p className="Lectura">
                        —¿Como un truco de magia? —pregunté—. Pero ella no me entendió porque nunca 
                        había visto uno.<br/> <br/>
                        
                        Ya no dije nada. Pensé en que ella tenía razón. Me han dicho que las mariposas ven 
                        muchos más colores que nosotros y que los perros ven menos. Entonces ¿de qué 
                        manera es que los colores son reales si cada uno de nosotros los ve diferente? Ella, 
                        que no ve, parece saberlo mejor que yo.
                      </p>
                    </Grid>
                </Grid>  
        </Grid>
    </Grid>
    );
}
 
export default Otro_mirar;
