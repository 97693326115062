import React from "react";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Button, makeStyles, Grid } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  MuiDialogContainer: {
    borderRadius: "61px",
    height: "90%",
    width: "90%",
    backgroundColor: "#FEFAF1",
    border: "#707070 2px solid",
    backdropFilter: "blur(3px)",
    maxWidth: "80%",
  },

  MuiContentRoot: {
    overflowY: "auto",
    [theme.breakpoints.up("lg")]: {
      "&::-webkit-scrollbar": {
        width: "auto",
      },
      "&::-webkit-scrollbar-track": {
        backgroundColor: "#7A54BA20",
        webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#7A54BA",
        borderRadius: "5px",
      },
    },
  },

  mainTitle: {
    font: "28px fredoka one",
    margin: "0px",
    textAlign: "center",
    [theme.breakpoints.up("md")]: {
      font: "36px fredoka one",
    },
    [theme.breakpoints.up("xl")]: {
      font: "42px fredoka one",
    },
  },
  section: {
    margin: 0,
    padding: "5px 0",
    boxSizing: "border-box",
  },
  titleWithNumber: {
    font: "bold 20px lato",
    [theme.breakpoints.down("760")]: {
      font: "bold 18px lato",
      margin: "5px 0 10px 0",
    },
    [theme.breakpoints.down("321")]: {
      font: "bold 16px lato",
    },
  },
  subTitle: {
    font: "bold 18px lato",
    margin: "0",
    [theme.breakpoints.down("760")]: {
      font: "bold 16px lato",
      margin: "5px 0 10px 0",
    },
    [theme.breakpoints.down("321")]: {
      font: "bold 14px lato",
    },
  },

  textPrincipal: {
    font: "16px lato",
    [theme.breakpoints.down("760")]: {
      font: "12px lato",
    },
    [theme.breakpoints.up("1440")]: {
      font: "20px lato",
    },
  },
  link: {
    font: "inherit",
    color: "inherit",
    textDecoration: "none",
    overflow: "hidden",
    whiteSpace: "pre",
    lineBreak: "anywhere",
  },
  paragraph: {
    textAlign: "justify",
    font: "16px lato",
    [theme.breakpoints.down("760")]: {
      font: "12px lato",
    },
    [theme.breakpoints.up("1920")]: {
      font: "20px lato",
    },
  },
  paragraphContact: {
    textAlign: "justify",
    font: "12px lato",
    [theme.breakpoints.up("760")]: {
      font: "16px lato",
      display: "inline",
    },
    [theme.breakpoints.up("1920")]: {
      font: "20px lato",
    },
  },
  decoratedLink: {
    color: "#007fa3",
    overflowWrap: "anywhere",
    display: "inline-block",
    textAlign: "justify",
    font: "16px lato",
    marginLeft: "5px",
    [theme.breakpoints.down("760")]: {
      font: "12px lato",
    },
    [theme.breakpoints.up("1920")]: {
      font: "20px lato",
    },
  },
  table: {
    borderCollapse: "collapse",
    border: "1px solid rgba(224, 224, 224, 1)",
    textAlign: "center",
    margin: "0 auto",
    padding: "5px",
    outline: "1pxpx solid slategrey",
    backgroundColor: "#FFF",
  },
  tableItem: {
    border: "1px solid rgba(224, 224, 224, 1)",
    padding: "20px",
  },
  listNumber: {
    padding: "0",
    listStylePosition: "inside",
    textAlign: "justify",
  },
  numberItem: {
    margin: "10px",
    textAlign: "justify",
    font: "16px lato",
    [theme.breakpoints.down("760")]: {
      font: "12px lato",
    },
    [theme.breakpoints.up("1920")]: {
      font: "20px lato",
    },
  },
  listBullet: {
    padding: "0",
    listStylePosition: "inside",
    textAlign: "justify",
    "& li": {},
  },
  listDash: {
    padding: "0",
    listStylePosition: "inside",
    listStyle: "none",
    textAlign: "justify",
  },

  dash: {
    font: "inherit",
    marginRight: "10px",
  },
  itemBullet: {
    listStylePosition: "inside",
    paddingLeft: "20px",
    font: "16px lato",
    margin: "10px",
    [theme.breakpoints.down("sm")]: {
      font: "12px lato",
      paddingLeft: "0",
    },
  },

  romanList: {
    padding: "0",
    listStylePosition: "inside",
    listStyle: "lower-roman",
    textAlign: "justify",
  },

  romanItem: {
    margin: "10px",
    textAlign: "justify",
    font: "16px lato",
    [theme.breakpoints.down("760")]: {
      font: "12px lato",
    },
    [theme.breakpoints.up("1920")]: {
      font: "20px lato",
    },
  },

  lettersList: {
    padding: "0",
    listStylePosition: "inside",
    listStyle: "lower-latin",
    textAlign: "justify",
  },
  letterItem: {
    margin: "10px",
    textAlign: "justify",
    font: "16px lato",
    [theme.breakpoints.down("760")]: {
      font: "12px lato",
    },
    [theme.breakpoints.up("1920")]: {
      font: "20px lato",
    },
  },

  BotonContinuar: {
    textTransform: "none",
    height: "42px",
    width: "138px",
    background: "#5825AD",
    boxShadow: "0px 3px 6px #00000029",
    borderRadius: "61px",
    color: "#FFFFFF",
    font: "bold 20px lato ",
    "&:hover": {
      background: "#dd78fb",
    },
    [theme.breakpoints.up("sm")]: {
      height: "62px",
      width: "238px",
      font: "bold 18px lato ",
    },
  },

  textChangeRegion: {
    textAlign: "justify",
    font: "16px lato",
    color: "#505759",
    [theme.breakpoints.down("760")]: {
      font: "12px lato",
    },
    [theme.breakpoints.up("1920")]: {
      font: "20px lato",
    },
  },

  buttonChangeRegion: {
    border: "none",
    backgroundColor: "transparent",
    textDecoration: "underline",
    cursor: "pointer",
    padding: 0,
    font: "inherit",
    color: "#007fa3",
  },
}));

const TermsAndConditionsPeru = (props) => {
  const {
    open,
    handleConfirm,
    changeToSelectRegion,
    accept,
    accepted,
  } = props;
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      classes={{ paper: classes.MuiDialogContainer }}
      disableEscapeKeyDown
    >
      <DialogTitle style={{ textAlign: "center" }}>
        <span className={classes.mainTitle}>
          Política de privacidad
          <br /> Pearson educación de Perú S.A.
        </span>
      </DialogTitle>
      <DialogContent classes={{ root: classes.MuiContentRoot }}>
        <p className={classes.textChangeRegion}>
          Si eres de otro país lee los términos y condiciones{" "}
          <button
            onClick={changeToSelectRegion}
            className={classes.buttonChangeRegion}
          >
            aquí
          </button>
        </p>
        <section className={classes.section}>
          <p className={classes.paragraph}>
            La presente política de privacidad (la “Política”) tiene por
            finalidad informarle la manera en que PEARSON EDUCACIÓN DE PERÚ
            S.A., con R.U.C. N° 20502875648, con domicilio en Calle Los Halcones
            275 Urb. Limatambo- Surquillo, provincia y departamento de Lima,
            conjuntamente con las empresas que forman nuestro grupo (Pearson),
            tratan la información y datos personales de sus Usuarios que
            recopilan a través de canales, plataformas, sitio web, aplicaciones
            móviles, redes sociales, formularios digitales así como cualquier
            medio electrónico o digital equivalente (el “Sitio Web, Plataforma y
            Aplicaciones”). La Política describe toda la tipología de
            información que se recaba de los Usuarios en los distintos puntos de
            captación anteriormente detallados y todos los tratamientos que se
            realizan con dicha información.
          </p>

          <p className={classes.paragraph}>
            Para efectos de esta Política toda referencia a “nos”, “nosotros” o
            “nuestra”, se refiere a Pearson, y cuando se refiera a “el Usuario”
            o “los Usuarios”, se entenderá a todos aquellos distintos a Pearson
            que naveguen, ingresen, revisen, realicen transacciones, interactúen
            o generen contenido dentro del Sitio Web, Plataforma y Aplicaciones.
          </p>
          <p className={classes.paragraph}>
            El Usuario declara expresamente haber leído y aceptado de manera
            previa al uso del Sitio Web, Plataforma y Aplicaciones, nuestra
            Política, sujetándose a todas sus disposiciones.
          </p>
        </section>
        <section className={classes.section}>
          <h3 className={classes.titleWithNumber}>
            1. Información que recolectamos:
          </h3>
          <p className={classes.paragraph}>
            El Usuario puede navegar en y por el Sitio Web, Plataforma y
            Aplicaciones libremente sin necesidad de registrarse y/o
            suscribirse. Sin embargo, en algunos casos se requerirá del registro
            y/o suscripción para acceder al contenido y el Usuario deberá
            completar un formulario proporcionando los datos personales
            solicitados.
          </p>
          <p className={classes.paragraph}>
            Asimismo, existen determinadas secciones dentro del Sitio Web,
            Plataforma y Aplicaciones en las que el Usuario puede crear o
            generar contenido, como las zonas de comentarios, blogs,
            conversaciones en foros, entre otros. En dichos casos, también se
            solicitará el registro del Usuario a través del llenado de un
            formulario proporcionando los datos personales solicitados.
          </p>

          <p className={classes.paragraph}>
            El Usuario también puede registrarse utilizando el nombre de usuario
            y clave utilizadas en servicios o plataformas de terceros
            (“Credenciales de Acceso”). Al ingresar estas Credenciales de Acceso
            el Usuario acepta nuestra Política, acepta que se regirá por los
            términos y condiciones de dichos servicios o plataformas de terceros
            en lo que resulte aplicable, y autoriza a Pearson a acceder a la
            información de la cuenta registrada en dicho servicio de acuerdo a
            los perfiles de privacidad del Usuario.
          </p>
          <p className={classes.paragraph}>
            El Pearson requiere realizar tratamiento de la siguiente información
            del Usuario:
          </p>
          <p className={classes.paragraph}>
            Datos personales que el Usuario proporcione libremente cuando se
            registra o suscribe al Sitio Web, Plataforma y Aplicaciones, tales
            como nombre, apellido, dirección de correo electrónico, número de
            DNI, entre otros (los “Datos personales”).
          </p>
          <p className={classes.paragraph}>
            Información sobre el comportamiento del Usuario en el Sitio Web,
            Plataforma y Aplicaciones (“Información de Uso”), entre la que se
            incluye:
          </p>
          <ol className={classes.romanList}>
            <li className={classes.romanItem}>
              Información sobre las actividades realizadas por el Usuario en el
              Sitio Web, Plataforma y Aplicaciones, cuando accede, visualiza,
              crea, comenta y comparte contenido, entre otros.
            </li>
            <li className={classes.romanItem}>
              La URL de la que proviene el Usuario (incluyendo las externas al
              Sitio Web, Plataforma y Aplicaciones).
            </li>
            <li className={classes.romanItem}>
              Las URLs más visitadas por el Usuario (incluyendo las externas al
              Sitio Web, Plataforma y Aplicaciones).
            </li>
            <li className={classes.romanItem}>Direcciones IP.</li>
            <li className={classes.romanItem}>
              Información sobre la ubicación del Usuario.
            </li>
            <li className={classes.romanItem}>
              Navegador que utiliza el Usuario.
            </li>
            <li className={classes.romanItem}>
              Información sobre aspectos operativos del Sitio Web, Plataforma y
              Aplicaciones, como estadísticas de navegación, tráfico, entre
              otros.
            </li>
          </ol>
          <p className={classes.paragraph}>
            El tratamiento de los Datos Personales y la Información de Uso
            (ambas “Información del Usuario”) se efectuará de acuerdo a las
            finalidades y los lineamientos establecidos en la presente Política.
          </p>
        </section>
        <section className={classes.section}>
          <h3 className={classes.titleWithNumber}>
            2. Sobre la veracidad de los datos personales:
          </h3>
          <p className={classes.paragraph}>
            El Usuario declara que toda información proporcionada es verdadera,
            completa y exacta. Cada Usuario es responsable por la veracidad,
            exactitud, vigencia y autenticidad de la información suministrada, y
            se compromete a mantenerla debidamente actualizada. Sin perjuicio de
            lo anterior, el Usuario autoriza a Pearson a verificar la veracidad
            de los Datos Personales facilitados a través de información obtenida
            de fuentes de acceso público o de entidades especializadas en la
            provisión de dicha información.
          </p>
          <p className={classes.paragraph}>
            Pearson no se hace responsable de la veracidad de la información que
            no sea de elaboración propia, por lo que tampoco asume
            responsabilidad alguna por posibles daños o perjuicios que pudieran
            originarse por el uso de dicha información.
          </p>
        </section>
        <section className={classes.section}>
          <h3 className={classes.titleWithNumber}>
            3. Incorporación de la información del usuario en un banco de datos:
          </h3>
          <p className={classes.paragraph}>
            De acuerdo a lo establecido en la Ley N° 29733, Ley de Protección de
            Datos Personales, y el Decreto Supremo N° 003-2013-JUS, por el que
            se aprueba su Reglamento, Pearson informa a los Usuarios del Sitio
            Web, Plataforma y Aplicaciones que la Información del Usuario será
            incorporada a los bancos de datos de titularidad de Pearson.
          </p>
          <p className={classes.paragraph}>
            A través de la presente Política de Privacidad el Usuario da su
            consentimiento expreso para la inclusión de su información en los
            mencionados bancos de datos.
          </p>
        </section>

        <section className={classes.section}>
          <h3 className={classes.titleWithNumber}>
            4. Utilización y tratamiento de la información del usuario:
          </h3>
          <p className={classes.paragraph}>
            La Información del Usuario será tratada a fin de:
          </p>

          <ol className={classes.romanList}>
            <li className={classes.romanItem}>
              Atender y procesar solicitudes de registro y/o suscripción y
              compras en línea de Usuarios, brindar soporte al Usuario, validar
              la veracidad de la información proporcionada y atender consultas
              del Usuario.{" "}
            </li>
            <li className={classes.romanItem}>
              Gestionar y administrar las cuentas personales y/o corporativas
              que los Usuarios mantengan en el Sitio Web, Plataforma y
              Aplicaciones, así como supervisar, monitorear y moderar el
              comportamiento y la actividad del Usuario en las secciones en las
              que el Usuario pueda crear o generar contenido, como las secciones
              de comentarios, blogs, conversaciones en foros, entre otros.
            </li>
            <li className={classes.romanItem}>
              Gestionar los concursos y promociones que se realicen con los
              Usuarios. Informar sobre los ganadores de premios, promociones,
              concursos y/o sorteos realizados. Los Usuarios que participen en
              las promociones, concursos o sorteos mencionados autorizan
              expresamente a Pearson responsable de la promoción que difunda, de
              manera gratuita y por los medios que estime convenientes, los
              datos personales e imágenes de los ganadores.
            </li>
            <li className={classes.romanItem}>
              Transferir la Información del Usuario a empresas que brinden
              servicios basados en infraestructura en la nube en el extranjero,
              a fin de poder alojar, almacenar, procesar y ejecutar la
              Información del Usuario.
            </li>
            <li className={classes.romanItem}>
              Tratamiento con fines comerciales, publicitarios y/o promocionales
              con las siguientes finalidades:
            </li>
            <li className={classes.romanItem}>
              Realizar estudios internos sobre los intereses, comportamientos y
              hábitos de conducta de los Usuarios a fin de poder enriquecer y
              complementar la Información del Usuario y de este modo ofrecer a
              los Usuarios un mejor servicio de acuerdo a sus necesidades
              específicas. Ello permitirá el envío de contenido personalizado
              sobre la base de sus intereses, ya sea a través del Sitio Web,
              Plataforma y Aplicaciones, como a través de otros medios no
              electrónicos.
            </li>
            <li className={classes.romanItem}>
              Perfilar al Usuario enriqueciendo la información del Usuario a
              través del procesamiento e interconexión de la misma con
              información obtenida de Pearson o de empresas terceras no
              vinculadas y poder enviarle noticias, promociones de productos y/o
              servicios, encuestas, publicidad, invitaciones a eventos, cursos y
              seminarios, campañas de captación y gestión comercial, email
              marketing, descuentos, cuponeras físicas y digitales, envíos de
              reportes de campaña y/o cualquier comunicación directa al usuario
              final, novedades y otros, propios y/o de terceros, a través de
              comunicaciones remitidas a la dirección de correo electrónico y/o
              direcciones físicas que el Usuario facilitó al momento de realizar
              el registro y/o suscripción, y por los medios que Pearson estime
              convenientes. Para el caso de los correos electrónicos
              comerciales, el Usuario reconoce y acepta que estos serán
              considerados solicitados y que no califican como “spam” bajo la
              normativa vigente. En todo caso, el Usuario podrá notificar su
              voluntad de no recibir más mensajes de correo electrónico enviando
              una solicitud a
              <a
                href="mailto: aviso.privacidad@pearson.com"
                target="_blank"
                rel="noreferrer"
                className={classes.decoratedLink}
              >
                aviso.privacidad@pearson.com
              </a>
            </li>
            <li className={classes.romanItem}>
              Transferir o compartir la Información del Usuario dentro de
              Pearson a fin de que las empresas que forman parte de dicho grupo
              puedan contactar y ofrecer al Usuario información comercial,
              publicitaria y/o promocional sobre sus servicios y/o productos, ya
              sea a través del Sitio Web, Plataforma y Aplicaciones como a
              través de otros medios no electrónicos. Puede consultar las
              empresas que conforman Pearson, así como sus productos y/o
              servicios en el siguiente enlace:
              <a
                href="https://www.pearsoneducacion.net/"
                target="_blank"
                rel="noreferrer"
                className={classes.decoratedLink}
              >
                https://www.pearsoneducacion.net/
              </a>{" "}
            </li>
            <li className={classes.romanItem}>
              Asimismo, la Información del Usuario podrá ser cedida o compartida
              con empresas terceras no vinculadas a Pearson a fin de que pueda
              contactar y ofrecer al Usuario cualquier información comercial,
              publicitaria y/o promocional sobre los productos y/o los servicios
              de empresas terceras de distintos sectores, incluyendo de manera
              enunciativa mas no limitativa, a los siguientes: inmobiliario,
              automotriz, servicios empresariales, entidades de gobierno,
              educación, banca y finanzas, grandes almacenes, entretenimiento,
              energía y minas, vivienda, construcción y decoración, consumo
              masivo, centros comerciales y comercios diversos, prendas de
              vestir y accesorios, salud, turismo, medios de comunicación,
              belleza, comunicación y telefonía, restaurantes, informática y
              tecnología, clasificados, comunicados y electrodomésticos, a
              través del Sitio Web, Plataforma y Aplicaciones o a través de
              medios no electrónicos.
            </li>
            <li className={classes.romanItem}>
              Ceder o compartir la Información del Usuario a proveedores de
              servicios Pearson, a fin de que utilicen dicha información para
              mejorar o facilitar la operatividad del Sitio Web, Plataforma y
              Aplicaciones de Pearson, así como permitir la ejecución de los
              servicios contratados por el Usuario.
            </li>
          </ol>

          <p className={classes.paragraph}>
            El Usuario manifiesta expresamente que ha sido debidamente informado
            de todas las finalidades antes mencionadas. Asimismo, a través de la
            aceptación de la presente Política de Privacidad, el Usuario
            autoriza y su consentimiento previo, libre, expreso e inequívoco y
            gratuito, para el tratamiento de la Información del Usuario, de
            conformidad con las finalidades antes descritas.
          </p>
          <p className={classes.paragraph}>
            Pearson le recuerda al Usuario que el tratamiento de la información
            necesaria para la ejecución de la relación contractual que vinculara
            al Usuario con Pearson no requiere de su consentimiento.
          </p>
        </section>

        <section className={classes.section}>
          <h3 className={classes.titleWithNumber}>
            5. Resguardo de la información del usuario:
          </h3>
          <p className={classes.paragraph}>
            Pearson adopta las medidas técnicas y organizativas necesarias para
            garantizar la protección de la Información del Usuario y evitar su
            alteración, pérdida, tratamiento y/o acceso no autorizado, habida
            cuenta del estado de la técnica, la naturaleza de los datos
            almacenados y los riesgos a que están expuestos, todo ello, conforme
            a lo establecido por la Ley de Protección de Datos Personales, su
            Reglamento y la Directiva de Seguridad.
          </p>
          <p className={classes.paragraph}>
            En este sentido, Pearson usará los estándares de la industria en
            materia de protección de la confidencialidad de la Información del
            Usuario.
          </p>
          <p className={classes.paragraph}>
            Pearson emplea diversas técnicas de seguridad para proteger tales
            datos de accesos no autorizados. Sin perjuicio de ello, Pearson no
            se hace responsable por interceptaciones ilegales o violación de sus
            sistemas o bases de datos por parte de personas no autorizadas, así
            como la indebida utilización de la información obtenida por esos
            medios, o de cualquier intromisión ilegítima que escape al control
            de Pearson y que no le sea imputable.
          </p>
          <p className={classes.paragraph}>
            Pearson tampoco se hace responsable de posibles daños o perjuicios
            que se pudieran derivar de interferencias, omisiones,
            interrupciones, virus informáticos, averías telefónicas o
            desconexiones en el funcionamiento operativo de este sistema
            electrónico, motivadas por causas ajenas a Pearson; de retrasos o
            bloqueos en el uso de la plataforma informática causados por
            deficiencias o sobrecargas en el Centro de Procesos de Datos, en el
            sistema de Internet o en otros sistemas electrónicos.
          </p>
        </section>
        <section className={classes.section}>
          <h3 className={classes.titleWithNumber}>
            6. Información del usuario solicitada por autoridades públicas
          </h3>
          <p className={classes.paragraph}>
            Pearson se compromete a no divulgar o compartir la Información del
            Usuario sin que se haya prestado el debido consentimiento para ello,
            con excepción de los siguientes casos:
          </p>

          <ol className={classes.romanList}>
            <li className={classes.romanItem}>
              Solicitud de información de autoridades públicas en ejercicio de
              sus funciones y el ámbito de sus competencias.
            </li>
            <li className={classes.romanItem}>
              Solicitud de información en virtud de órdenes judiciales.
            </li>
            <li className={classes.romanItem}>
              Solicitud de información en virtud de disposiciones legales.
            </li>
          </ol>
        </section>
        <section className={classes.section}>
          <h3 className={classes.titleWithNumber}>7. Cookies:</h3>
          <p className={classes.paragraph}>
            El Sitio Web, Plataforma y Aplicaciones utilizan cookies. Una
            “Cookie” es un pequeño archivo de texto que un sitio web almacena en
            el navegador del Usuario. Las cookies facilitan el uso y la
            navegación por una página web y son de importancia esencial para el
            buen funcionamiento de Internet, aportando innumerables ventajas en
            la prestación de servicios interactivos.
          </p>
          <p className={classes.paragraph}>
            A través de las cookies, el Sitio Web, Plataforma y Aplicaciones
            podrán utilizar la información de su visita para realizar
            evaluaciones y cálculos estadísticos sobre datos anónimos, así como
            para garantizar la continuidad del servicio o para realizar mejoras.
            Asimismo, el Sitio Web, Plataforma y Aplicaciones también utilizarán
            la información obtenida a través de las cookies para analizar los
            hábitos de navegación del Usuario y las búsquedas realizadas por
            éste, a fin de mejorar sus iniciativas comerciales y promocionales,
            mostrando publicidad que pueda ser de su interés y personalizando
            los contenidos.
          </p>
          <p className={classes.paragraph}>
            Las cookies pueden borrarse del disco duro si el Usuario así lo
            desea. La mayoría de los navegadores aceptan las cookies de forma
            automática, pero le permiten al Usuario cambiar la configuración de
            su navegador para que rechace la instalación de cookies, sin que
            ello perjudique su acceso y navegación por el Sitio Web, Plataforma
            y Aplicaciones.
          </p>
          <p className={classes.paragraph}>
            En el supuesto de que en el Sitio Web, Plataforma y Aplicaciones se
            dispusieran enlaces o hipervínculos hacia otros lugares de Internet
            propiedad de terceros que utilicen cookies, Pearson no se hace
            responsable ni controla el uso de cookies por parte de dichos
            terceros.
          </p>
        </section>
        <section className={classes.section}>
          <h3 className={classes.titleWithNumber}>
            8. Derechos de acceso, rectificación, cancelación y oposición de
            datos personales:
          </h3>
          <p className={classes.paragraph}>
            Pearson pone en conocimiento del Usuario sus derechos de acceso,
            rectificación, oposición y cancelación de su información personal,
            así como los demás derechos concedidos por la normativa de
            protección de datos personales, los mismos que se podrán ejercer
            mediante petición escrita a la siguiente dirección de correo
            electrónico:
            <a
              href="mailto: aviso.privacidad@pearson.com"
              target="_blank"
              rel="noreferrer"
              className={classes.decoratedLink}
            >
              aviso.privacidad@pearson.com
            </a>
          </p>
          <p className={classes.paragraph}>
            Sin perjuicio de lo anterior, Pearson podrá conservar determinada
            Información del Usuario que solicita la baja, a fin de que sirva de
            prueba ante una eventual reclamación contra Pearson por
            responsabilidades derivadas del tratamiento de dicha información. La
            duración de dicha conservación no podrá ser superior al plazo de
            prescripción legal de dichas responsabilidades.
          </p>
        </section>
        <section className={classes.section}>
          <h3 className={classes.titleWithNumber}>
            9. Cesión de posición contractual:
          </h3>
          <p className={classes.paragraph}>
            Los Usuarios autorizan expresamente la cesión de esta Política y de
            la Información del Usuario en favor de cualquier persona que (i)
            quede obligada por la presente Política; y/o (ii) sea el nuevo
            responsable de los bancos de datos que contengan la Información del
            Usuario. Luego de producida la cesión, Pearson no tendrá ninguna
            responsabilidad con respecto de cualquier hecho que ocurra partir de
            la fecha de la cesión. El nuevo responsable del banco de datos
            asumirá todas y cada una de las obligaciones de Pearson establecidas
            en la presente Política respecto al tratamiento, resguardo y
            conservación de la Información del Usuario.
          </p>
        </section>
        <section className={classes.section}>
          <h3 className={classes.titleWithNumber}>
            10. Modificaciones de las políticas de privacidad:
          </h3>
          <p className={classes.paragraph}>
            Pearson se reserva expresamente el derecho a modificar, actualizar o
            completar en cualquier momento la presente Política.
          </p>
          <p className={classes.paragraph}>
            Cualquier modificación, actualización o ampliación producida en la
            presente Política será inmediatamente publicada en el Sitio Web,
            Plataforma y Aplicaciones y se le solicitará su aceptación al
            ingresar a las mismas.
          </p>
        </section>

        <Grid
          container
          justifyContent="center"
          alignItems="center"
          style={{ marginBottom: "40px" }}
        >
          <input
            type="checkbox"
            id="agree"
            name="agree"
            checked={accepted}
            onChange={accept}
          />
          <label htmlFor="agree">He leído los términos y condiciones.</label>
          <br></br>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container justifyContent="center" alignItems="center">
          <Button
            className={classes.BotonContinuar}
            onClick={handleConfirm}
            disabled={!accepted}
          >
            Aceptar
          </Button>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default TermsAndConditionsPeru;
