import {
  Dialog,
  DialogContent,
  Grid,
  makeStyles,
  styled,
} from "@material-ui/core";
import React from "react";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-root": {
    // backdropFilter: "blur(3px)",
    height: "100%",
  },
  "& .MuiDialogContent-root": {
    padding: "0",
    position: "relative",
    height: "100%",
    // overflow: "hidden",
    [theme.breakpoints.up("sm")]: {
      // margin: "20px 0",
      paddingTop: "0",
      paddingBottom: "0",
    },
    [theme.breakpoints.up("sm")]: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      overflowY: "hidden",
      "@media(max-height:400px)": {
        justifyContent: "flex-start",
      },
    },
    [theme.breakpoints.up("xl")]: {
      justifyContent: "flex-start",
    },
  },
  "& .MuiDialog-paper": {
    borderRadius: "12.1px",
    backgroundColor: "transparent",
    boxShadow: "none",
    height: "100vh",
    width: "100vw",
    margin: "0px",
    maxHeight: "100vh",
    maxWidth: "100vw",
    [theme.breakpoints.up("lg")]: {
      margin: "0px",
    },
  },
  "& .MuiDialog-container": {
    // backdropFilter: "blur(3px)",
    alignItems: "flex-start",
    [theme.breakpoints.up("sm")]: {
      alignItems: "center",
    },
  },
}));

const useStyles = makeStyles((theme) => ({
  grid1: {
    zIndex: "3",
    minHeight: "80px",
  },
  grid2: {
    zIndex: "2",
  },
  grid3: {
    boxSizing: "border-box",
    zIndex: "1",
    width: "100%",
    display: "flex",
    paddingLeft: "0px",
    minHeight: "calc(100vh - 80px)",
    marginTop: "80px",
    [theme.breakpoints.up("sm")]: {
      marginTop: "0px",
    },
    [theme.breakpoints.up("lg")]: {
      paddingLeft: "3%",
    },
  },
  container: {
    width: "100vw",
    minHeight: "calc(100vh - 80px)",
    display: "grid",
    justifyContent: "start",
    overflow: "hidden",
    [theme.breakpoints.up("sm")]: {
      marginTop: "0px",
    },
    [theme.breakpoints.up("lg")]: {
      width: "100%",
    },
  },
  dialogPaper: {
    maxWidth: "unset",
  },
}));
const BlurContainer = ({ children }) => {
  const classes = useStyles();
  return (
    <>
      <BootstrapDialog

        aria-labelledby="customized-dialog-title"
        open={true}
        maxWidth={false}
      >
        <DialogContent>
          <Grid container>
            <Grid item className={classes.grid1} sm={12}>
              <div></div>
            </Grid>
            <Grid item className={classes.grid2} sm={1}>
              <div></div>
            </Grid>
            <Grid item className={classes.grid3} sm={12}>
              <Grid className={classes.container}>{children}</Grid>
            </Grid>
          </Grid>
        </DialogContent>
      </BootstrapDialog>
    </>
  );
};

export default BlurContainer;
