import React from "react";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Button, makeStyles, Grid } from "@material-ui/core";


const useStyles = makeStyles((theme) => ({
  MuiDialogContainer: {
    borderRadius: "61px",
    height: "90%",
    width: "90%",
    backgroundColor: "#FEFAF1",
    border: "#707070 2px solid",
    backdropFilter: "blur(3px)",
    maxWidth: "80%",
  },
  MuiContentRoot: {
    overflowY: "auto",
    [theme.breakpoints.up("lg")]: {
      "&::-webkit-scrollbar": {
        width: "auto",
      },
      "&::-webkit-scrollbar-track": {
        backgroundColor: "#7A54BA20",
        webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#7A54BA",
        borderRadius: "5px",
      },
    },
  },

  mainTitle: {
    font: "20px fredoka one",
    margin: "0px",
    textAlign: "center",
    [theme.breakpoints.up("sm")]: {
      font: "28px fredoka one",
    },
    [theme.breakpoints.up("md")]: {
      font: "32px fredoka one",
    },
    [theme.breakpoints.up("xl")]: {
      font: "36px fredoka one",
    },
  },
  section: {
    margin: 0,
    padding: "5px 0",
    boxSizing: "border-box",
  },
  titleWithNumber: {
    font: "bold 20px lato",
    [theme.breakpoints.down("760")]: {
      font: "bold 18px lato",
      margin: "5px 0 10px 0",
    },
    [theme.breakpoints.down("321")]: {
      font: "bold 16px lato",
    },
  },
  subTitle: {
    font: "bold 18px lato",
    margin: "0",
    [theme.breakpoints.down("760")]: {
      font: "bold 16px lato",
      margin: "5px 0 10px 0",
    },
    [theme.breakpoints.down("321")]: {
      font: "bold 14px lato",
    },
  },

  textPrincipal: {
    font: "16px lato",
    [theme.breakpoints.down("760")]: {
      font: "12px lato",
    },
    [theme.breakpoints.up("1440")]: {
      font: "20px lato",
    },
  },
  link: {
    font: "inherit",
    color: "inherit",
    textDecoration: "none",
    overflow: "hidden",
    whiteSpace: "pre",
    lineBreak: "anywhere",
  },
  paragraph: {
    textAlign: "justify",
    font: "16px lato",
    [theme.breakpoints.down("760")]: {
      font: "12px lato",
    },
    [theme.breakpoints.up("1920")]: {
      font: "20px lato",
    },
  },
  paragraphContact: {
    textAlign: "justify",
    font: "12px lato",
    [theme.breakpoints.up("760")]: {
      font: "16px lato",
      display: "inline",
    },
    [theme.breakpoints.up("1920")]: {
      font: "20px lato",
    },
  },
  decoratedLink: {
    color: "#007fa3",
    overflowWrap: "anywhere",
    display: "inline-block",
    textAlign: "justify",
    font: "16px lato",
    marginLeft: "5px",
    [theme.breakpoints.down("760")]: {
      font: "12px lato",
    },
    [theme.breakpoints.up("1920")]: {
      font: "20px lato",
    },
  },
  table: {
    borderCollapse: "collapse",
    border: "1px solid rgba(224, 224, 224, 1)",
    textAlign: "center",
    margin: "0 auto",
    padding: "5px",
    outline: "1pxpx solid slategrey",
    backgroundColor: "#FFF",
  },
  tableItem: {
    border: "1px solid rgba(224, 224, 224, 1)",
    padding: "20px",
  },
  listNumber: {
    padding: "0",
    listStylePosition: "inside",
    textAlign: "justify",
  },
  numberItem: {
    margin: "10px",
    textAlign: "justify",
    font: "16px lato",
    [theme.breakpoints.down("760")]: {
      font: "12px lato",
    },
    [theme.breakpoints.up("1920")]: {
      font: "20px lato",
    },
  },
  listBullet: {
    padding: "0",
    listStylePosition: "inside",
    textAlign: "justify",
    "& li": {},
  },
  listDash: {
    padding: "0",
    listStylePosition: "inside",
    listStyle: "none",
    textAlign: "justify",
  },

  dash: {
    font: "inherit",
    marginRight: "10px",
  },
  itemBullet: {
    listStylePosition: "inside",
    paddingLeft: "20px",
    font: "16px lato",
    margin: "10px",
    [theme.breakpoints.down("sm")]: {
      font: "12px lato",
      paddingLeft: "0",
    },
  },

  romanList: {
    padding: "0",
    listStylePosition: "inside",
    listStyle: "lower-roman",
    textAlign: "justify",
  },

  romanItem: {
    margin: "10px",
    textAlign: "justify",
    font: "16px lato",
    [theme.breakpoints.down("760")]: {
      font: "12px lato",
    },
    [theme.breakpoints.up("1920")]: {
      font: "20px lato",
    },
  },

  lettersList: {
    padding: "0",
    listStylePosition: "inside",
    listStyle: "lower-latin",
    textAlign: "justify",
  },
  letterItem: {
    margin: "10px",
    textAlign: "justify",
    font: "16px lato",
    [theme.breakpoints.down("760")]: {
      font: "12px lato",
    },
    [theme.breakpoints.up("1920")]: {
      font: "20px lato",
    },
  },

  BotonContinuar: {
    textTransform: "none",
    height: "42px",
    width: "138px",
    background: "#5825AD",
    boxShadow: "0px 3px 6px #00000029",
    borderRadius: "61px",
    color: "#FFFFFF",
    font: "bold 20px lato ",
    "&:hover": {
      background: "#dd78fb",
    },
    [theme.breakpoints.up("sm")]: {
      height: "62px",
      width: "238px",
      font: "bold 18px lato ",
    },
  },

  textChangeRegion: {
    textAlign: "justify",
    font: "16px lato",
    color: "#505759",
    [theme.breakpoints.down("760")]: {
      font: "12px lato",
    },
    [theme.breakpoints.up("1920")]: {
      font: "20px lato",
    },
  },
  buttonChangeRegion: {
    color: "#007fa3",
    border: "none",
    backgroundColor: "transparent",
    textDecoration: "underline",
    cursor: "pointer",
    padding: 0,
    font: "inherit",
  },
}));

const TermsAndConditionsColombia = (props) => {
  const {
    open,
    handleConfirm,
    changeToSelectRegion,
    accept,
    accepted,
  } = props;
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      classes={{ paper: classes.MuiDialogContainer }}
      disableEscapeKeyDown
    >
      <DialogTitle style={{ textAlign: "center" }}>
        <span className={classes.mainTitle}>
          Política de tratamiento de datos personales
          <br /> Pearson educación de Colombia S.A.S.
        </span>
      </DialogTitle>
      <DialogContent classes={{ root: classes.MuiContentRoot }}>
        <p className={classes.textChangeRegion}>
          Si eres de otro país lee los términos y condiciones{" "}
          <button
            onClick={changeToSelectRegion}
            className={classes.buttonChangeRegion}
          >
            aquí
          </button>
        </p>
        <section className={classes.section}>
          <p className={classes.paragraph}>
            Este documento contiene la Política para el Tratamiento de Datos
            Personales de los clientes, aliados comerciales, empleados,
            proveedores, divisiones y unidades, así como de los departamentos
            centralizados dentro y de la Compañía, y en general, de quien quiera
            que se encuentre en una base de datos (en adelante los “Titulares”)
            de la sociedad PEARSON EDUCACIÓN DE COLOMBIA S.A.S. (en adelante
            “PEARSON”), domiciliada en la ciudad de Bogotá en la Carrera 7 100 #
            156A – 68, cuyo correo electrónico es
            <a
              href="mailto: proteccion.datoscolombia@pearson.com"
              target="_blank"
              rel="noreferrer"
              className={classes.decoratedLink}
            >
              proteccion.datoscolombia@pearson.com
            </a>{" "}
            y teléfono
            <a
              href="tel:+5712940800"
              target="_blank"
              rel="noreferrer"
              className={classes.link}
            >
              {" "}
              +57 1 2940800
            </a>{" "}
            de conformidad con lo establecido en la normatividad vigente sobre
            protección de datos personales (en adelante la “Política”).
          </p>

          <p className={classes.paragraph}>
            En la presente Política, PEARSON incluye todos los lineamientos y
            medidas necesarias a nivel físico, técnico y jurídico para
            garantizar que los datos personales suministrados por los Titulares
            reciban un Tratamiento adecuado y en estricto cumplimiento de la
            normatividad de protección de datos en Colombia.
          </p>
          <p className={classes.paragraph}>
            La celebración de contratos, el diligenciamiento de formatos y de
            formularios, la realización de entrevistas, la participación a
            eventos, el acceso a los Servicios de la plataforma
            <a
              href="https://cdl-pearson.com/"
              target="_blank"
              rel="noreferrer"
              className={classes.decoratedLink}
            >
              www.cdl-pearson.com
            </a>
            , el ingreso a las instalaciones de PEARSON y/o la aceptación
            expresa o inequívoca de la presente Política, implican la aceptación
            de los Titulares de la Política de Tratamiento y Protección de Datos
            Personales y su autorización para los usos y otros tratamientos que
            aquí se describen.
          </p>
        </section>
        <section className={classes.section}>
          <h3 className={classes.titleWithNumber}>1. Propósito</h3>
          <p className={classes.paragraph}>
            En consideración al artículo 15 de la Constitución de la República
            de Colombia consagra el derecho de cualquier persona de conocer,
            actualizar y rectificar los datos personales que existan sobre ella
            en banco de datos o archivos de entidades públicas o privadas.
            Igualmente, ordena a quienes tienen datos personales de terceros
            respetar los derechos y garantía previstos en la Constitución cuando
            se recolecta, trata y circula esa clase de información.
          </p>
          <p className={classes.paragraph}>
            La Ley Estatutaria 1581 del 17 de octubre de 2012 establece las
            condiciones mínimas para realizar el tratamiento legítimo de los
            datos personales de los clientes, empleados y cualquier otra persona
            natural. Tanto los literales k) del artículo 17 como f) del artículo
            18 de dicha ley obliga a los Responsables y Encargados del
            tratamiento de datos personales a{" "}
            <i>
              “adoptar un manual interno de políticas y procedimientos para
              garantizar el adecuado cumplimiento de la presente ley y en
              especial, para la atención de consultas y reclamos”.
            </i>
          </p>

          <p className={classes.paragraph}>
            El artículo 25 de la misma ley ordena que las políticas de
            tratamiento de datos sean de obligatorio cumplimiento y que su
            desconocimiento acarreara sanciones. Dichas políticas no pueden
            garantizar un nivel de tratamiento inferior al establecido en la ley
            1581 de 2012.
          </p>
          <p className={classes.paragraph}>
            El capítulo III del Decreto 1377 del 27 de junio de 2013 reglamenta
            algunos aspectos relacionados con el contenido y requisitos de las
            Políticas de Tratamiento de Información y los Avisos de Privacidad.
          </p>
          <p className={classes.paragraph}>
            En desarrollo de lo anterior, PEARSON es responsable de proteger y
            garantizar los derechos de los titulares de los datos personales que
            recopila y usa en sus actividades.
          </p>
        </section>
        <section className={classes.section}>
          <h3 className={classes.titleWithNumber}>2. Autorización</h3>{" "}
          <p className={classes.paragraph}>
            La autorización de los Titulares de Datos Personales o de quien se
            encuentre legitimado de conformidad con lo establecido en las normas
            vigentes, es requisito indispensable para que PEARSON recolecte,
            almacene, consulte, use, procese y en general dé Tratamiento a su
            información personal. Esta autorización será otorgada por el Titular
            en forma escrita, oral o a través de conductas inequívocas del
            Titular que permitan concluir de forma razonable que otorgó la
            autorización.
          </p>
          <p className={classes.paragraph}>
            La autorización del Titular no será necesaria cuando se trate de:
          </p>
          <ul className={classes.listBullet}>
            <li className={classes.itemBullet}>
              Entrega de información requerida por una entidad pública o
              administrativa en ejercicio de sus funciones legales o por orden
              judicial.
            </li>
            <li className={classes.itemBullet}>
              Tratamiento de Datos Públicos.
            </li>
            <li className={classes.itemBullet}>
              Tratamiento en otros eventos previstos en las normas vigentes.
            </li>
          </ul>
        </section>
        <section className={classes.section}>
          <h3 className={classes.titleWithNumber}>3. Alcance</h3>
          <p className={classes.paragraph}>
            Esta política aplica para todos los clientes, aliados comerciales,
            empleados, proveedores, divisiones y unidades, así como a los
            departamentos centralizados dentro y fuera de la compañía.
          </p>
        </section>

        <section className={classes.section}>
          <h3 className={classes.titleWithNumber}>4. Definiciones</h3>
          <ul className={classes.listBullet}>
            <li className={classes.itemBullet}>
              <b>Autorización: </b>Consentimiento previo, expreso e informado
              del Titular del dato para llevar a cabo el tratamiento.
            </li>
            <li className={classes.itemBullet}>
              <b>Consulta: </b>Solicitud del Titular del dato o las personas
              autorizadas por éste o por la ley para conocer la información que
              reposa sobre ella en bases de datos o archivos.
            </li>
            <li className={classes.itemBullet}>
              <b>Dato personal: </b>Cualquier información vinculada o que pueda
              asociarse a una o varias personas naturales determinadas o
              determinables. Estos datos se clasifican en sensibles, públicos,
              privados y semiprivados.
            </li>
            <li className={classes.itemBullet}>
              <b>Dato personal sensible:</b>Información que afecta la intimidad
              de la persona o cuyo uso indebido puede generar su discriminación,
              tales como aquellos que revelen el origen racial o étnico, la
              orientación política, las convicciones religiosas o filosóficas,
              la pertenencia a sindicatos, organizaciones sociales, de derechos
              humanos o que promueva intereses de cualquier partido político o
              que garanticen los derechos y garantías de partidos políticos de
              oposición, así como los datos relativos a la salud, a la vida
              sexual y los datos biométricos (huellas dactilares, entre otros).
            </li>
            <li className={classes.itemBullet}>
              <b>Dato personal público: </b>Es el dato calificado como tal según
              los mandatos de la ley o de la Constitución Política, y todos
              aquellos que no sean semiprivados o privados. Son públicos, entre
              otros, los datos contenidos en documentos públicos, registros
              públicos, gacetas y boletines oficiales y sentencias judiciales
              debidamente ejecutoriadas que no estén sometidos a reserva, los
              relativos al estado civil de las personas, a su profesión u oficio
              y a su calidad de comerciante o de servidor público. Son públicos
              los datos personales existentes en el registro mercantil de las
              Cámaras de Comercio (Artículo 26 del C.Co). Estos datos pueden ser
              obtenidos y ofrecidos sin reserva alguna y sin importar si hacen
              alusión a información general, privada o personal.
            </li>
            <li className={classes.itemBullet}>
              <b>Dato personal privado:</b>Es el dato que por su naturaleza
              íntima o reservada sólo es relevante para la persona Titular del
              dato. Ejemplos: libros de los comerciantes, documentos privados,
              información extraída a partir de la inspección del domicilio.
            </li>
            <li className={classes.itemBullet}>
              <b>Dato personal semiprivado: </b> Es semiprivado el dato que no
              tiene naturaleza íntima, reservada, ni pública y cuyo conocimiento
              o divulgación puede interesar no sólo a su Titular sino a cierto
              sector o grupo de personas o a la sociedad en general, como, entre
              otros, el dato referente al cumplimiento e incumplimiento de las
              obligaciones financieras o los datos relativos a las relaciones
              con las entidades de la seguridad social.
            </li>
            <li className={classes.itemBullet}>
              <b> Encargado del tratamiento:</b> Persona que realiza el
              tratamiento de datos por cuenta del Responsable del tratamiento.
            </li>
            <li className={classes.itemBullet}>
              <b> Reclamo: </b>Solicitud del Titular del dato o las personas
              autorizadas por éste o por la ley para corregir, actualizar o
              suprimir sus datos personales.
            </li>
            <li className={classes.itemBullet}>
              <b>Responsable del tratamiento: </b>Persona que decide sobre,
              entre otras, la recolección y fines del tratamiento. Puede ser, a
              título de ejemplo, la empresa dueña de las bases de datos o
              sistema de información que contiene datos personales.
            </li>
            <li className={classes.itemBullet}>
              <b>Titular del dato: </b>Es la persona natural a que se refieren
              los datos.
            </li>
            <li className={classes.itemBullet}>
              <b>Tratamiento: </b>Cualquier operación o conjunto de operaciones
              sobre datos personales como, entre otros, la recolección, el
              almacenamiento, el uso, la circulación o supresión de esa clase de
              información.
            </li>
            <li className={classes.itemBullet}>
              <b>Transmisión: </b>Tratamiento de datos personales que implica la
              comunicación de los mismos dentro (transmisión nacional) o fuera
              de Colombia (transmisión internacional) y que tiene por objeto la
              realización de un tratamiento por el Encargado por cuenta o en
              nombre del Responsable.
            </li>
            <li className={classes.itemBullet}>
              <b>Transferencia: </b> Envío de datos personales que realiza el
              Responsable o el Encargado desde Colombia a un Responsable que se
              encuentra dentro (transferencia nacional) o fuera del país
              (transferencia internacional).
            </li>
          </ul>
        </section>

        <section className={classes.section}>
          <h3 className={classes.titleWithNumber}>
            5. Datos de contacto para consultas, rectificaciones y reclamos
          </h3>
          <p className={classes.paragraph}>
            PEARSON es el responsable del tratamiento de sus datos personales
            para el adecuado desarrollo de sus actividades. Así como para el
            fortalecimiento de sus relaciones con terceros, él procesa,
            recolecta, almacena, usa, circula, suprime, comparte, actualiza,
            transmite y/o transfiere a terceros países, datos personales
            correspondientes a personas naturales con quienes tiene o ha tenido
            relación.
          </p>

          <p className={classes.paragraph}>
            Por lo tanto, los Titulares podrán ejercer sus derechos a autorizar,
            conocer, actualizar, rectificar o suprimir la información
            suministrada mediante el envío de una comunicación escrita al correo
            electrónico:
            <a
              href="mailto: proteccion.datoscolombia@pearson.com"
              target="_blank"
              rel="noreferrer"
              className={classes.decoratedLink}
            >
              proteccion.datoscolombia@pearson.com
            </a>{" "}
          </p>
          <p className={classes.paragraph}>
            En este sentido, el Área encargada y/o el Oficial de datos de
            personales dentro de PEARSON quien atenderá los requerimientos de
            los Titulares, en cuanto al ejercicio de sus derechos, consultas y/o
            reclamos, es: Yury Vargas / Finanzas
          </p>
        </section>
        <section className={classes.section}>
          <h3 className={classes.titleWithNumber}>6. Finalidades</h3>
          <p className={classes.paragraph}>
            Los Datos Personales son objeto de Tratamiento por parte de PEARSON
            con las siguientes finalidades:
          </p>
          <ol className={classes.listNumber}>
            <li className={classes.numberItem}>
              El titular de los datos personales autoriza a PEARSON a incluir
              sus datos personales, actuales o los que se incorporen en un
              futuro, en las bases de datos de PEARSON, así como para su
              utilización y tratamiento automatizado o no, para la gestión y
              registro de todas las actividades que realice.
            </li>
            <li className={classes.numberItem}>
              El titular de los datos personales acepta el tratamiento de sus
              datos de carácter personal, con la finalidad de gestionar el envío
              por cualquier medio, incluida la vía electrónica o medios
              análogos, de publicidad o información comercial acerca de los
              productos, servicios y/o eventos relacionados con el objeto
              corporativo de PEARSON.
            </li>
            <li className={classes.numberItem}>
              Asimismo, el titular de los datos personales autoriza a PEARSON
              para que dichos datos puedan ser compartidos con terceros, y ser
              utilizados por éstos para llevar a cabo campañas de publicidad,
              marketing, estudios de mercado, ofertas de bienes y servicios.
            </li>
            <li className={classes.numberItem}>
              Garantizar el ejercicio de los derechos como afiliado, proveedor,
              cliente y/o usuario de los servicios ofrecidos por PEARSON.
            </li>
            <li className={classes.numberItem}>
              Lograr una eficiente comunicación relacionada con nuestros
              servicios, programas, alianzas, estudios, concursos, rifas,
              sorteos, promociones, para facilitarle el acceso general a la
              información de estos y demás actividades relacionadas con las
              funciones propias de PEARSON.
            </li>
            <li className={classes.numberItem}>
              Dar cumplimiento a obligaciones contraídas con nuestros clientes
              y/o usuarios, proveedores y empleados.
            </li>
            <li className={classes.numberItem}>
              Informar sobre modificaciones, cambios o novedades de nuestros
              servicios.
            </li>
            <li className={classes.numberItem}>
              Actualizar información referente a datos personales que se
              encuentren en nuestras bases de datos.
            </li>
            <li className={classes.numberItem}>
              Evaluar la calidad del servicio y realizar investigaciones de
              preferencias y hábitos de consumo sobre los diferentes productos y
              servicios ofrecidos por PEARSON, por medios propios o a través de
              terceros.
            </li>
            <li className={classes.numberItem}>
              Realizar el pago de las obligaciones contractuales
            </li>
            <li className={classes.numberItem}>
              Enviar la información a entidades gubernamentales,
              administrativas, judiciales o autoridad competente que lo solicite
              formalmente en cumplimiento de sus funciones, y conforme a las
              estipulaciones constitucionales y legales.
            </li>
            <li className={classes.numberItem}>
              Soportar los procesos de auditoría interna y externa.
            </li>
            <li className={classes.numberItem}>
              Enviar y recepcionar mensajes con fines comerciales, publicitarios
              y/o de atención al cliente, así como todos los relacionados y/o
              con ocasión de una relación legal y/o contractual existente.
            </li>
            <li className={classes.numberItem}>
              Realizar investigaciones de mercados para analizar, evaluar y
              generar datos estadísticos, indicadores y demás sobre la gestión
              de las actividades, servicios y/o productos que ofrece PEARSON.
            </li>
            <li className={classes.numberItem}>
              {" "}
              Registrar la información de empleados (activos e inactivos) en las
              bases de datos de PEARSON, según corresponda.
            </li>
            <li className={classes.numberItem}>
              Registrar la información de proveedores (activos e inactivos) en
              las bases de datos de PEARSON, según corresponda.
            </li>
            <li className={classes.numberItem}>
              Contactar a clientes, usuarios y proveedores para el envío de
              información referida a la relación contractual, comercial, entre
              otras, a que haya lugar.
            </li>
            <li className={classes.numberItem}>
              Recolectar datos para el cumplimiento de los deberes que como
              responsable de la información y de los datos personales, le
              corresponden a PEARSON en ejercicio de todas sus actividades.
            </li>
            <li className={classes.numberItem}>
              Cualquier otra finalidad que resulte en el desarrollo del contrato
              o de la relación legal que existe entre las personas naturales
              afiliadas, y demás grupos de interés.
            </li>
          </ol>
        </section>
        <section className={classes.section}>
          <h3 className={classes.titleWithNumber}>7. Datos sensibles</h3>
          <p className={classes.paragraph}>
            Es voluntario responder las eventuales preguntas que le hagamos
            sobre datos sensibles o sobre información de menores de 18 años;
            usted tiene los siguientes derechos:
          </p>

          <ol className={classes.listNumber}>
            <li className={classes.numberItem}>
              Conocer, actualizar y rectificar sus datos personales, así como
              oponerse al tratamiento de los mismos.
            </li>
            <li className={classes.numberItem}>
              Solicitar prueba de la presente autorización.
            </li>
            <li className={classes.numberItem}>
              Ser informado respecto del uso que se les ha dado a sus datos
              personales.
            </li>
            <li className={classes.numberItem}>
              Presentar ante la Superintendencia de Industria y Comercio quejas
              por infracciones a lo dispuesto en la ley 1581 de 2012 y las demás
              normas que la modifiquen, adicionen o complementen.
            </li>
            <li className={classes.numberItem}>
              Revocar la autorización y/o solicitar la supresión de sus datos.
            </li>
            <li className={classes.numberItem}>
              Acceder en forma gratuita a sus datos personales.
            </li>
          </ol>
        </section>
        <section className={classes.section}>
          <h3 className={classes.titleWithNumber}>
            8. Período de vigencia de las bases de datos
          </h3>
          <p className={classes.paragraph}>
            Las Bases de Datos a las cuales dará Tratamiento PEARSON, así como
            los Datos Personales debidamente autorizados incorporados en ellas
            estarán vigentes durante el plazo necesario para cumplir sus
            finalidades. Una vez cumplido este plazo, PEARSON procederá a
            eliminar la base de datos o eliminar los datos de los titulares,
            según correspondiere.
          </p>
        </section>
        <section className={classes.section}>
          <h3 className={classes.titleWithNumber}>9. Derechos del titular</h3>
          <p className={classes.paragraph}>
            En cuanto a las personas naturales cuyos datos personales sean
            objeto de tratamiento por parte de PEARSON, tienen los siguientes
            derechos, los cuales pueden ejercer en cualquier momento:
          </p>
          <ol className={classes.listNumber}>
            <li className={classes.numberItem}>
              Conocer los Datos Personales sobre los cuales PEARSON está
              realizando el Tratamiento. De igual manera, el Titular puede
              solicitar en cualquier momento que sus datos sean actualizados o
              rectificados, por ejemplo, si encuentra que sus datos son
              parciales, inexactos, incompletos, fraccionados, induzcan a error,
              o aquellos cuyo Tratamiento esté expresamente prohibido o no haya
              sido autorizado.
            </li>
            <li className={classes.numberItem}>
              Solicitar prueba de la autorización otorgada a PEARSON para el
              Tratamiento de sus Datos Personales.
            </li>
            <li className={classes.numberItem}>
              Ser informado por PEARSON, previa solicitud, respecto del uso que
              ésta le ha dado a sus Datos Personales.
            </li>
            <li className={classes.numberItem}>
              Presentar ante la Superintendencia de Industria y Comercio quejas
              por infracciones a lo dispuesto en la Ley de Protección de Datos
              Personales.
            </li>
            <li className={classes.numberItem}>
              Solicitar a PEARSON la supresión de sus Datos Personales y/o
              revocar la autorización otorgada para el Tratamiento de los
              mismos, mediante la presentación de un reclamo, de acuerdo con los
              procedimientos establecidos en la Política de Protección de Datos
              Personales de PEARSON. No obstante, la solicitud de supresión de
              la información y la revocatoria de la autorización no procederán
              cuando el Titular de la información tenga un deber legal o
              contractual de permanecer en la Base de Datos y/o Archivos, ni
              mientras se encuentre vigente la relación entre el Titular y
              PEARSON, en virtud de la cual fueron recolectados sus datos.
            </li>
            <li className={classes.numberItem}>
              Acceder de forma gratuita a sus Datos Personales objeto de
              Tratamiento.
            </li>
          </ol>
        </section>
        <section className={classes.section}>
          <h3 className={classes.titleWithNumber}>
            10. Medidas de seguridad para la protección de los datos personales
            y otra información
          </h3>
          <p className={classes.paragraph}>
            PEARSON tomará las precauciones razonables para mantener segura la
            información personal y para exigir a cualquier tercero que maneje o
            procese Datos Personales por encargo de PEARSON que haga lo mismo.
            El acceso a la información personal está razonablemente restringido
            para evitar accesos no autorizados, modificaciones o usos indebidos,
            y estará permitido su tratamiento será realizado únicamente por
            personas vinculadas a PEARSON o encargadas expresamente para ello.
          </p>
        </section>
        <section className={classes.section}>
          <h3 className={classes.titleWithNumber}>
            11. Procedimiento de consultas, rectificaciones y reclamos
          </h3>
          <p className={classes.paragraph}>
            A continuación, se señalan las acciones que pueden presentar los
            Titulares o sus Causahabientes en relación con las consultas
            rectificaciones y reclamos que se relacionen con los datos de los
            Titulares registrados en las Bases de Datos de PEARSON:
          </p>
          <h4 className={classes.subTitle}>11.1. Consultas:</h4>
          <p className={classes.paragraph}>
            Las consultas y solicitudes de los Titulares o sus causahabientes
            serán atendidas en un término máximo de diez (10) días hábiles
            contados a partir de la fecha de recibo de la misma. Cuando no fuere
            posible atender la consulta dentro de dicho término, se informará al
            interesado en la dirección de notificación que haya incluido en la
            respectiva consulta, expresando los motivos de la demora y señalando
            la fecha en que se atenderá su consulta, la cual en ningún caso
            podrá superar los cinco (5) días hábiles siguientes al vencimiento
            del primer término. La respuesta a las consultas que los Titulares o
            sus causahabientes presenten podrá ser entregada por cualquier medio
            físico o electrónico.
          </p>

          <h4 className={classes.subTitle}>
            11.2. Rectificaciones y reclamos:
          </h4>

          <p className={classes.paragraph}>
            El Titular o sus causahabientes que consideren que la información
            contenida en las Bases de Datos de PEARSON debe ser objeto de
            corrección, actualización o supresión, o cuando adviertan el
            presunto incumplimiento de cualquiera de los deberes contenidos en
            la ley, podrán presentar un reclamo ante PEARSON así:
          </p>

          <ol className={classes.listNumber}>
            <li className={classes.numberItem}>
              El reclamo se formulará mediante solicitud dirigida al área
              encargada, de acuerdo a lo establecido en el Numeral Cuarto de la
              presente Política de Privacidad, con la identificación del
              Titular, la descripción de los hechos que dan lugar al reclamo, la
              dirección, y acompañando los documentos que se quiera hacer valer.
              Si el reclamo resulta incompleto, se requerirá al interesado
              dentro de los cinco (5) días siguientes a la recepción del reclamo
              para que subsane las fallas. Transcurridos dos (2) meses desde la
              fecha del requerimiento, sin que el solicitante presente la
              información requerida, se entenderá que ha desistido del reclamo.
              <br />
              <br />
              En caso de que quien reciba el reclamo no sea competente para
              resolverlo, dará traslado a quien corresponda en un término máximo
              de dos (2) días hábiles e informará de la situación al interesado.
            </li>

            <li className={classes.numberItem}>
              Una vez recibido el reclamo completo, se incluirá en la base de
              datos una leyenda que diga "reclamo en trámite" y el motivo del
              mismo, en un término no mayor a dos (2) días hábiles. Dicha
              leyenda deberá mantenerse hasta que el reclamo sea decidido.{" "}
            </li>
            <li className={classes.numberItem}>
              El término máximo para resolver el reclamo será de quince (15)
              días hábiles contados a partir del día siguiente a la fecha de su
              recibo. Cuando no fuere posible atender el reclamo dentro de dicho
              término, se informará al interesado los motivos de la demora y la
              fecha en que se atenderá su reclamo, la cual en ningún caso podrá
              superar los ocho (8) días hábiles siguientes al vencimiento del
              primer término. La respuesta a las rectificaciones o reclamos que
              los Titulares o sus causahabientes presenten podrá ser entregada
              por cualquier medio físico o electrónico.
            </li>
          </ol>

          <h4 className={classes.subTitle}>
            11.3. Revocatoria de la autorización:
          </h4>
          <p className={classes.paragraph}>
            Los Titulares de los Datos Personales pueden revocar el
            consentimiento al tratamiento de sus Datos Personales en cualquier
            momento, siempre y cuando no lo impida la disposición legal o
            contractual. Para ello, el Titular deberá seguir el mismo
            procedimiento establecido para las Rectificaciones y Reclamos.
          </p>
          <p className={classes.paragraph}>
            Se deberá tener en cuenta que existen dos modalidades en las que la
            revocación del consentimiento puede darse: (i) Puede ser sobre la
            totalidad de las finalidades consentidas, en este sentido PEARSON
            y/o los Encargados del Tratamiento deben dejar de tratar por
            completo los datos del Titular, y (ii) puede ocurrir sobre tipos de
            tratamiento determinados.
          </p>
          <p className={classes.paragraph}>
            Por lo anterior, será necesario que el titular al momento de elevar
            la solicitud de revocatoria, indique en ésta si la revocación que
            pretende realizar es total o parcial. En el segundo caso, se deberá
            indicar con cuál Tratamiento el Titular no está conforme. En caso de
            que el Titular no lo indique con claridad, se entenderá que la
            solicitud es total.
          </p>
          <p className={classes.paragraph}>
            Los mecanismos o procedimiento que PEARSON establezca para atender
            las solicitudes de revocatoria no podrán exceder los plazos
            previstos para atender las reclamaciones conforme se señala en el
            artículo 15 de la ley 1581 de 2013.
          </p>

          <h4 className={classes.subTitle}>
            11.4. Requisito de procedibilidad:
          </h4>
          <p className={classes.paragraph}>
            De acuerdo con las normas vigentes, el Titular o causahabiente sólo
            podrá elevar queja ante la Superintendencia de Industria y Comercio
            una vez haya agotado el trámite de consulta, reclamo o de
            revocatoria de la autorización ante el área encargada, de acuerdo a
            lo establecido en el Numeral Cuarto de la presente Política de
            Privacidad.
          </p>
        </section>
        <section className={classes.section}>
          <h3 className={classes.titleWithNumber}>
            12. Transferencias y transmisiones nacionales o internacionales de
            datos
          </h3>

          <h4 className={classes.subTitle}>12.1. Transferencias</h4>
          <p className={classes.paragraph}>
            Es posible que PEARSON tenga que transferir información personal de
            los Titulares a Responsables en diferentes países. Al aceptar la
            presente Política para el Tratamiento de Datos Personales el Titular
            autoriza y da su consentimiento expreso para dicha Transferencia,
            dentro de Colombia, o a cualquier país o territorio.
          </p>
          <p className={classes.paragraph}>
            En particular, PEARSON podrá ceder o transferir la responsabilidad
            sobre sus bases de datos a entidades que resulten o sean
            beneficiarias de una escisión y/o fusión total o parcial del
            negocio, consolidaciones, cambio de control, reorganización o
            liquidación de todo o una parte del negocio. Con la autorización
            concedida a PEARSON para el tratamiento de sus datos el Titular
            expresamente manifiesta que la autorización concedida continuará
            vigente en caso que PEARSON llegare a fusionarse, escindirse o
            transformarse, por lo cual la autorización se entenderá otorgada a
            la compañía resultante de la escisión, fusión o transformación.
          </p>
          <p className={classes.paragraph}>
            Así mismo, autoriza para que PEARSON transfiera los datos a otras
            sociedades de su grupo empresarial para llevar a cabo cualquiera de
            las finalidades definidas en esta política.
          </p>
          <h4 className={classes.subTitle}>12.2. Transmisiones</h4>
          <p className={classes.paragraph}>
            Con la autorización otorgada por cualquiera de los medios previstos
            para tal fin, el Titular autoriza que PEARSON realice transmisiones
            nacionales e internacionales de Datos Personales a un Encargado,
            para permitir que el Encargado realice el Tratamiento por cuenta de
            PEARSON. Estas transmisiones no requerirán ser informadas al Titular
            ni contar con un consentimiento expreso adicional.
          </p>
        </section>
        <section className={classes.section}>
          <h3 className={classes.titleWithNumber}>
            13. Vigencia y modificaciones de la política para el tratamiento de
            datos personales
          </h3>
          <p className={classes.paragraph}>
            La presente Política para el Tratamiento de Datos Personales se
            encuentra vigente a partir de 12 de marzo de 2020. Cualquier cambio
            o modificación sustancial de la presente Política para el
            Tratamiento de Datos Personales será comunicado por PEARSON.
          </p>
        </section>

        <Grid
          container
          justifyContent="center"
          alignItems="center"
          style={{ marginBottom: "40px" }}
        >
          <input
            type="checkbox"
            id="agree"
            name="agree"
            checked={accepted}
            onChange={accept}
          />
          <label htmlFor="agree">He leído los términos y condiciones.</label>
          <br></br>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container justifyContent="center" alignItems="center">
          <Button
            className={classes.BotonContinuar}
            onClick={handleConfirm}
            disabled={!accepted}
          >
            Aceptar
          </Button>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default TermsAndConditionsColombia;
