import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
    ContenedorLectura:{
        width:'502px',
                  [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
    },
    ContainerTitulo:{
        margin:'0 0 34px 0',
        '& .Titulo':{
            letterSpacing: '0px',
            color: '#817FD9',
            margin:'0',
            textAlign:'center',
            font:'43px fredoka one',
            lineHeight:'52px',
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            margin:'0 0 36px 0',
            color:'#000',
            font:'18px lato',
            lineHeight :'28px',
            letterSpacing: '0.17px',
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .SubTitulo':{
            color:'#F6664D',
            margin:'0',
            lineHeight :'28px',
            font:'bold 26px lato',
            letterSpacing: '0.24px',
            [theme.breakpoints.down(502)]:{
                font:'bold 18px lato',
                lineHeight :'24px',
                letterSpacing: '0.21px',
            },
        },

        '& .TextImg':{
            color:'#717476',
            margin:'24px 0 0 0',
            font:' 16px lato',
            lineHeight :'25px',
            letterSpacing: '0.15px',
            [theme.breakpoints.down(502)]:{
                font:' 11px lato',
                lineHeight :'14px',
                letterSpacing: '0.10px',
            },
        },

        '& .Imagen':{
         width:'100%',
        },

    }
}));

const Vacas_y_vacunas = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo ">Vacas y vacunas</h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                       <p className='Lectura'>
                            ¿Alguna vez has sentido miedo antes de recibir una inyección? 
                            Quizá ver la aguja te incomoda o te hace pensar que podría 
                            pasarte algo malo. Pero es todo lo contrario: con estos 
                            tratamientos nos aseguramos de no contraer enfermedades que 
                            en otros tiempos fueron muy graves. <br/><br/>
                            
                            Gracias a las vacunas, la sociedad actual puede tratar enfermedades 
                            letales y epidemias. Aunque no siempre fue así. Hace apenas 200 
                            años la humanidad se encontraba indefensa ante ellas. ¿Qué sucedió? 
                            ¿Cómo cambiaron las cosas?<br/><br/> 
                            
                            <h3 className='SubTitulo'>Viruela vacuna</h3><br/>
                            
                            En el siglo <span style={{fontVariant: "all-small-caps"}}>XVIII</span>  una epidemia de viruela azotó al Reino Unido. 
                            Muchas personas murieron o quedaron desfiguradas por las cicatrices 
                            que dejaban las pústulas de esa enfermedad. Durante esa época la 
                            aristócrata inglesa Mary Wortley Montagu visitó Turquía y se 
                            percató de que allí tenían la situación controlada, porque los 
                            turcos estaban <em>inoculando</em> la viruela. Esto quiere decir que hacían
                            pequeñas incisiones en la piel de una persona sana para introducirle el 
                            líquido de una pústula. De esta manera, quienes recibían el tratamiento 
                            ya no contraían la enfermedad si entraban en contacto con alguien contagiado.<br/><br/>
                            
                            Montagu inoculó a sus hijos y difundió el método entre la aristocracia 
                            del Reino Unido y Europa. Sin embargo, éste no era muy efectivo, ya que 
                            en algunas ocasiones provocaba una enfermedad grave y más contagios. 
                            Después de todo, el tratamiento inoculaba la viruela misma. <br/><br/>
                            
                            En 1796 el método llegó a oídos del cirujano Edward Jenner, quien se 
                            decidió a adaptarlo. El médico había observado que algunas mujeres 
                            lecheras presentaban pústulas parecidas a las que provocaba la viruela, 
                            pero sin los síntomas graves. Además, no se contagiaban si tenían 
                            contacto con personas enfermas: estaban inmunizadas. Jenner concluyó 
                            que dichas mujeres habían contraído un tipo especial de viruela por 
                            medio de las ubres de vacas infectadas. Llamó a esa nueva enfermedad 
                            <em> viruela vacuna</em>.
                       </p>
                    </Grid>
                    <Grid container justifyContent='center'>
                        <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion7/1.png" alt="Tarjeta" />
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container >
                        <p className='Lectura'>
                            Jenner comenzó a experimentar. Tomó líquido de una pústula <em>vacuna</em> (o sea, 
                            proveniente de una vaca). Luego, le hizo dos cortes superficiales a un niño 
                            de ocho años y le aplicó el líquido. El niño se sintió mal a lo largo del día 
                            y manifestó síntomas leves: sus heridas adoptaron la forma de las pústulas de 
                            la viruela vacuna. Tras unos pocos días, se sintió mejor.<br/><br/>
                            
                            Pero todavía faltaba la segunda parte del experimento. Para comprobar 
                            que el niño fuera inmune, Jenner le hizo algunas incisiones en el cuerpo 
                            e introdujo en ellas el líquido de una pústula de viruela humana. El niño 
                            desarrolló algunas pústulas, pero los síntomas letales (fiebre, dolor de 
                            cabeza) nunca se presentaron. Al cabo de unos meses, el científico 
                            volvió a inyectarle viruela humana al niño y su cuerpo ya no 
                            presentó ninguna reacción.
                        </p>
                    </Grid>
                    <Grid container justifyContent='center'>
                        <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion7/2.png" alt="Tarjeta" />
                    </Grid>
                    
                    

                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container >
                        <p className='Lectura'>
                            <h3 className='SubTitulo'>La vacuna recibe su nombre</h3><br/>
                            
                            Después, en el siglo XIX, el científico francés Louis Pasteur 
                            desarrolló métodos de inoculación contra otras enfermedades 
                            graves que causaban epidemias: el cólera y la rabia. Fue él 
                            quien llamó vacuna al líquido que inyectaba, en honor a las 
                            aportaciones de Jenner. <br/><br/>
                            
                            <h3 className='SubTitulo'>Vacunas</h3><br/>
                            
                            Gracias a la vacunación se han controlado epidemias muy importantes. 
                            En el siglo XXI la viruela se considera una enfermedad erradicada. 
                            Asimismo, durante los años 2020 y 2021 los científicos tuvieron la 
                            tarea de controlar la epidemia causada por el coronavirus por medio 
                            de la vacunación. ¡Y pensar que todo empezó con unas vacas lecheras!
                        </p>
                    </Grid>
                </Grid>
        </Grid>
    </Grid>
    );
}
 
export default Vacas_y_vacunas ;
