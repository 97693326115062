import { Button, Grid,makeStyles}              from '@material-ui/core';
import React, { useContext, useEffect } from "react";
import DrawerContext from "../../../../../usability/DrawerContext";
import { useOnLoadImages } from "../../../../hooks/useOnLoadImages";
import Loader from "../../../../Loader/Loader";
import Drawer from "../../ReadingTools/Drawer";

const useStyles =  makeStyles (theme =>({
     Contenedor:{
        background:'#E9FBF9',
        width:'100vw',
        overflowX:'clip',
        [theme.breakpoints.down(1161)]:{
            background:'none',
           overflowX:'unset',
        },
        [theme.breakpoints.down(600)]:{
            marginTop: '79.51px',
        }
    },
  ContainerInfoLectura: {
    position: "relative",
    height: "86px",
    [theme.breakpoints.down(502)]:{
        height: "43px",
    },
    background: "#76D4C3",
    "& .BarraInfoLectura": {
      height: "86px",
      background: `Url(https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Barras/Azul.png)`,
        [theme.breakpoints.down(502)]:{
            height: "43px",
        },
    },
    "& .InfoLectura": {
      position: "absolute",
      height: "86px",
      width: "219px",
      boxSizing: "border-box",
      padding: "17.2px 0",
      background: "#FAFAFA",
      borderRadius: "50px",
      boxShadow: "2px 2px 2px 2px #00000029",
      zIndex: "4",
        [theme.breakpoints.down(502)]:{
            height: "43px",
            width: "109px",
            padding: "8.6px 0",
            borderRadius: "25px",
        },
    },
    "& .InfoLectura:before": {
      position: "absolute",
      display: "flex",
      boxSizing: "border-box",
      padding: "0 0 0 10px",
      justifyContent: "center",
      alignItems: "center",
      height: "39.88px",
      width: "39.88px",
      borderRadius: "50%",
      content: '""',
      boxShadow: "3px 0px .5px 0px #00000029",
      background: "#FAFAFA",
      left: "90px",
      top: "65px",
      transform: "rotate(0.25turn)",
      [theme.breakpoints.down(502)]:{
        left: "45px",
        top: "30px",
        height: "20.88px",
        width: "20.88px",
        },
    },
    "& .InfoLectura:after": {
      content: '""',
      height: "15px",
      width: "15px",
      borderTop: "2px solid #20A698",
      borderLeft: "2px solid #20A698",
      position: "absolute",
      bottom: "-10px",
      left: "Calc(50% - 8px)",
      transform: "rotate(0.625turn)",
      [theme.breakpoints.down(502)]:{
        height: "10px",
        width: "10px",
        left: "Calc(50% - 4.5px)",
        bottom: "-3px",
        borderTop: "1px solid #20A698",
        borderLeft: "1px solid #20A698",
        },
    },
    "& .Sesion": {
      borderLeft: "2px solid #D8D8D8",
    },
    "& .Dato": {
      margin: "0 0 5px 0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#20A698",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        margin: "0 0 2.5px 0",
        },
    },
    "& .Caracter": {
      margin: "0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#000000",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        },
    },
       '& .Regresar':{
        color: '#F2624F',
        border: '#F2624F 1px solid',
        height: '30px',
        width:'100px',
        borderRadius: '20px',
        letterSpacing: '0.14px',
        textTransform: 'none',
        background:'#FEFAF1',
        top:'calc(50% - 15px)',
        left:'5%',
        zIndex:4,
        [theme.breakpoints.down(502)]: {
          font:'bold 10px lato',
          width: "60px",
        },
      },
  },
    ContenedorLectura:{
        background:'#fff',
        width:'1160px',
        height:'100%',
       padding:'60px 168px 35px ',
        
        transform: (props) =>
        props.scale > 1 ? `scale(${props.scale}) translate(0px, 10%)` : `none`,
        [theme.breakpoints.down(1161)]:{
            width:'502px',
            padding:'60px 0 0 0',
          },
        [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },

        '& .Escritorio':{
            display:'block',
            [theme.breakpoints.down(1161)]:{
                display:'none'
            },
        },
        '& .Tablet':{
            display:'none',
            [theme.breakpoints.down(1161)]:{
                display:'block'
            },
        }
    },
    ContainerTitulo:{
        margin:'0 0 34px 0',
        [theme.breakpoints.down(1161)]:{
            margin:'0 0 34px 0',
        },
        '& .Titulo':{
            font:'48px fredoka one',
            letterSpacing: '0px',
            lineHeight:'58px',
            color: '#817FD9',
            margin:'0',
            textAlign:'center',
            [theme.breakpoints.down(1161)]:{
                font:'43px fredoka one',
                lineHeight:'52px',
            },
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            lineHeight : '30px',
            margin:'0 0 36px 0',
            font:'22px lato',
            color:'#000',
            letterSpacing: '0.20px',
            [theme.breakpoints.down(1161)]:{
                font:'18px lato',
                lineHeight :'28px',
                letterSpacing: '0.17px',
            },
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        "& .SubTitulo": {
            font: "bold 30px lato",
            color: "#20A698",
            letterSpacing: ".28px",
            lineHeight: "30px",
            margin: "0",
            [theme.breakpoints.down(1161)]: {
              lineHeight: "28px",
              font: "bold 26px lato",
              letterSpacing: "0.24px",
            },
            [theme.breakpoints.down(502)]: {
              font: "bold 22px lato",
              lineHeight: "24px",
              letterSpacing: "0.21px",
            },
        },
        "& .SubTitulo2": {
            font: "bold 26px lato",
            color: "#F6664D",
            letterSpacing: ".24px",
            lineHeight: "28px",
            margin: "0",
            [theme.breakpoints.down(1161)]: {
              font: "bold 22px lato",
              lineHeight: "24px",
              letterSpacing: "0.21px",
            },
            [theme.breakpoints.down(502)]: {
              font: "bold 18px lato",
              lineHeight: "20px",
              letterSpacing: "0.16px",
            },
        },
               '& .TextImg':{
            font:'20px lato',
            color:'#717476',
            letterSpacing:'.19px',
            lineHeight:'30px',
            margin:'20px 0 0 0',
            [theme.breakpoints.down(1161)]:{
                font:' 16px lato',
                lineHeight :'25px',
                letterSpacing: '0.15px',
            },
            [theme.breakpoints.down(502)]:{
                font:' 11px lato',
                lineHeight :'14px',
                letterSpacing: '0.10px',
            },
        },
        '& .Imagen':{
            [theme.breakpoints.down(1161)]:{
                width:'100%',
            },
        }, 
        '& .Imagen2':{
            [theme.breakpoints.down(1161)]:{
                width:'92.23%',
            },
        }, 
      
      
    }
}));

const Mucha_o_poca_energía_cuánto_podemos_comer = (props) => {

  const {activeStep, setActiveStep, handlePreviewStep, id_actividad} = props

  const handleContinue = () => {
    setActiveStep(activeStep - 1)
    handlePreviewStep()
  }

  const {
    handleSize,
    disabledZoom,
    closeZoom,
    handleZoom,
    scale
  }= useContext(DrawerContext);
  const [loading, setLoading] = React.useState(true);
  const wrapperRef = React.useRef(null);
  const imagesLoaded = useOnLoadImages(wrapperRef);

  const detectSize = () => {
    const lectura = document.getElementById("lectura");
    const newWidth = lectura?.offsetWidth;
    const newHeight = lectura?.offsetHeight;
    setLoading(false);
    if (lectura) handleSize(newHeight, newWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", detectSize);
    return () => {
      handleZoom()
      window.removeEventListener("resize", detectSize);
      closeZoom()
    };
  }, []);

  const classes = useStyles({ disabledZoom,scale });
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
             <Grid container item justifyContent="center" className={classes.ContainerInfoLectura}>
                       <Grid container className="BarraInfoLectura">
            <Button className="Regresar" onClick={handleContinue}>
                Regresar
            </Button>
        </Grid>
               <Grid container item className="InfoLectura">
                    <Grid container item direction="column" xs={6} justifyContent="center">
                        <p className="Dato">Grado</p>
                        <p className="Caracter">2</p>
                    </Grid>
                    <Grid container item direction="column" xs={6} justifyContent="center" className="Sesion">
                        <p className="Dato">Sesión</p>
                        <p className="Caracter">47</p>
                    </Grid>
               </Grid>
            </Grid>
            {!imagesLoaded && <Loader />}
            <Grid
            container
            item
            className={classes.ContenedorLectura}
            justifyContent="center"
            id="lectura"
            onLoad={detectSize}
            ref={wrapperRef}
            >
            <Drawer id_lectura={id_actividad} />
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >
                    Mucha o poca energía:<br/> ¿cuánto podemos<br className='Tablet'/> comer?                  
                    </h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                      <p className="Lectura">
                        <h3 className='SubTitulo'>La energía en la vida cotidiana</h3><br/>

                        Todas las actividades que realizas en tu vida cotidiana requieren un aporte constante
                        de energía. Incluso cuando estás durmiendo, cada una de tus células lo necesita para 
                        realizar sus funciones: los latidos del corazón, la circulación sanguínea o el 
                        funcionamiento de tu cerebro dependen de ello.<br/><br/>
                        
                        Como sucede con el resto de los animales, el cuerpo de los seres humanos obtiene su 
                        energía a partir de los alimentos que consume. Así consigue los nutrientes con los 
                        cuales las células producen la energía necesaria para llevar a cabo cualquier 
                        actividad. <br/><br/>
                        
                        Piensa un instante: ¿cuánta energía consideras que necesitas? Si eres muy activo, 
                        seguramente será mucha, ya que ésta se corresponde con nuestro estilo de vida, es 
                        decir, con nuestras costumbres y actividades: a mayor movimiento, mayor energía.
                      </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <Grid container justifyContent="center" >
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img2do/Sesion47/1.png" alt="Tarjeta" />
                        </Grid>
                        <Grid container justifyContent="center" >
                            <p className="TextImg">
                            La cantidad y los tipos de alimentos que consumes debe ser proporcional a la cantidad de actividades y movimiento que realizas.                            </p>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                      <p className="Lectura">
                        <h3 className='SubTitulo2'>Información valiosa</h3><br/>

                         La medida de la energía que proporciona un alimento se expresa en <em>calorías</em>. Cada 
                         alimento aporta cierta cantidad de calorías a tu cuerpo (algunos más y otros menos). 
                         Pero es importante que consideres su calidad con base en otras sustancias 
                         necesarias que no sólo son fuente de energía. Hay algunos cuyo contenido calórico 
                         es muy elevado, pero que no te aportan proteínas u otros nutrientes. Por eso se les 
                         conoce como <em>calorías vacías</em>.
                      </p>
                    </Grid>
                    <Grid container justifyContent="center" >
                        <img className="Imagen2" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img2do/Sesion47/2.png" alt="Tarjeta" />
                    </Grid>
                    <Grid container justifyContent="center" >
                        <p className="TextImg">
                            La comida chatarra es un ejemplo de calorías vacías. Por eso no se recomienda su consumo.                           
                        </p>
                    </Grid>
                </Grid>
                
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                      <p className="Lectura">
                        Es importante consumir alimentos de todos los grupos para tener un aporte 
                        suficiente de energía y de todos los demás nutrimentos necesarios para nuestro 
                        organismo. Éstos varían para cada persona, dependiendo de la edad, el sexo, la 
                        estatura y la actividad física que realiza. En otras palabras: la cantidad y el tipo de 
                        alimentos que consumes deben ser seleccionados cuidadosamente de acuerdo con 
                        tus necesidades particulares. Un nutriólogo podría ayudarte con eso.<br/><br/>
                        
                        Si se excede en el consumo de la cantidad de alimentos que requerimos (y, por lo 
                        tanto, en el aporte calórico), la energía se almacenará en forma de grasa. Esto 
                        propicia el sobrepeso y otras enfermedades relacionadas, como la obesidad y la 
                        diabetes. <br/><br/>
                        
                        ¿Qué tan saludable consideras que es tu dieta? ¿Crees que podrías mejorarla 
                        incorporando alimentos de tu entorno?
                      </p>
                    </Grid>
                </Grid>
                <Grid container item xs={12} justifyContent="center" className="ContainerDerechos">
                    <p className="Derechos">
                        © 2022. Todos los derechos reservados, Pearson, Circuito de lectores.
                    </p>                
                </Grid>
        </Grid>
    </Grid>
    );
}
 
export default Mucha_o_poca_energía_cuánto_podemos_comer ;
