import { Button, Grid, makeStyles } from "@material-ui/core";
import React, { useContext, useEffect } from "react";
import DrawerContext from "../../../../../usability/DrawerContext";
import { useOnLoadImages } from "../../../../hooks/useOnLoadImages";
import Loader from "../../../../Loader/Loader";
import Drawer from "../../ReadingTools/Drawer";

const useStyles =  makeStyles (theme =>({
     Contenedor:{
        background:'#E9FBF9',
        width:'100vw',
        overflowX:'clip',
        [theme.breakpoints.down(1161)]:{
            background:'none',
           overflowX:'unset',
        },
        [theme.breakpoints.down(600)]:{
            marginTop: '79.51px',
        }
    },
      ContainerInfoLectura: {
    position: "relative",
    height: "86px",
    [theme.breakpoints.down(502)]:{
        height: "43px",
    },
    background: "#76D4C3",
    "& .BarraInfoLectura": {
      height: "86px",
      background: `Url(https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Barras/Azul.png)`,
        [theme.breakpoints.down(502)]:{
            height: "43px",
        },
    },
    "& .InfoLectura": {
      position: "absolute",
      height: "86px",
      width: "219px",
      boxSizing: "border-box",
      padding: "17.2px 0",
      background: "#FAFAFA",
      borderRadius: "50px",
      boxShadow: "2px 2px 2px 2px #00000029",
      zIndex: "4",
        [theme.breakpoints.down(502)]:{
            height: "43px",
            width: "109px",
            padding: "8.6px 0",
            borderRadius: "25px",
        },
    },
    "& .InfoLectura:before": {
      position: "absolute",
      display: "flex",
      boxSizing: "border-box",
      padding: "0 0 0 10px",
      justifyContent: "center",
      alignItems: "center",
      height: "39.88px",
      width: "39.88px",
      borderRadius: "50%",
      content: '""',
      boxShadow: "3px 0px .5px 0px #00000029",
      background: "#FAFAFA",
      left: "90px",
      top: "65px",
      transform: "rotate(0.25turn)",
      [theme.breakpoints.down(502)]:{
        left: "45px",
        top: "30px",
        height: "20.88px",
        width: "20.88px",
        },
    },
    "& .InfoLectura:after": {
      content: '""',
      height: "15px",
      width: "15px",
      borderTop: "2px solid #20A698",
      borderLeft: "2px solid #20A698",
      position: "absolute",
      bottom: "-10px",
      left: "Calc(50% - 8px)",
      transform: "rotate(0.625turn)",
      [theme.breakpoints.down(502)]:{
        height: "10px",
        width: "10px",
        left: "Calc(50% - 4.5px)",
        bottom: "-3px",
        borderTop: "1px solid #20A698",
        borderLeft: "1px solid #20A698",
        },
    },
    "& .Sesion": {
      borderLeft: "2px solid #D8D8D8",
    },
    "& .Dato": {
      margin: "0 0 5px 0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#20A698",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        margin: "0 0 2.5px 0",
        },
    },
    "& .Caracter": {
      margin: "0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#000000",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        },
    },
       '& .Regresar':{
        color: '#F2624F',
        border: '#F2624F 1px solid',
        height: '30px',
        width:'100px',
        borderRadius: '20px',
        letterSpacing: '0.14px',
        textTransform: 'none',
        background:'#FEFAF1',
        top:'calc(50% - 15px)',
        left:'5%',
        zIndex:4,
        [theme.breakpoints.down(502)]: {
          font:'bold 10px lato',
          width: "60px",
        },
      },
  },
    ContenedorLectura:{
        background:'#fff',
        width:'1160px',
        height:'100%',
        padding:'60px 168px 35px ',
        
        transform: (props) =>
        props.scale > 1 ? `scale(${props.scale}) translate(0px, 10%)` : `none`,
        [theme.breakpoints.down(1161)]:{
            width:'502px',
            padding:'60px 0 0 0',
          },
        [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },

        '& .Escritorio':{
            display:'block',
            [theme.breakpoints.down(1161)]:{
                display:'none'
            },
        },
        '& .Tablet':{
            display:'none',
            [theme.breakpoints.down(1161)]:{
                display:'block'
            },
        }
    },
    ContainerTitulo:{
        margin:'0 0 34px 0',
        [theme.breakpoints.down(1161)]:{
            margin:'0 0 34px 0',
        },
        '& .Titulo':{
            font:'48px fredoka one',
            letterSpacing: '0px',
            lineHeight:'58px',
            color: '#591AAF',
            margin:'0',
            textAlign:'center',
            [theme.breakpoints.down(1161)]:{
                font:'43px fredoka one',
                lineHeight:'52px',
            },
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            lineHeight : '30px',
            margin:'0 0 36px 0',
            font:'22px lato',
            color:'#000',
            letterSpacing: '0.20px',
            [theme.breakpoints.down(1161)]:{
                font:'18px lato',
                lineHeight :'28px',
                letterSpacing: '0.17px',
            },
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .SubTitulo':{
            font:'bold 30px lato',
            color:'#D63390',
            letterSpacing:'.28px',
            lineHeight:'30px',
            margin:'0',
            [theme.breakpoints.down(1161)]:{
                lineHeight :'28px',
                font:'bold 26px lato',
                letterSpacing: '0.24px',
            },
            [theme.breakpoints.down(502)]:{
                font:'bold 18px lato',
                lineHeight :'24px',
                letterSpacing: '0.21px',
            },
        },
        '& .Esp':{
            margin:0,
            boxSizing:'border-box',
            padding:'0 15px 0 0',
            [theme.breakpoints.down(1161)]:{
                margin:'0 0 36px 0',
                padding:'0 ',
            },
        },

        '& .Imagen':{
            [theme.breakpoints.down(1161)]:{
                width:'100%',
            },
        },   
    }
}));


const Las_abejas_y_la_polinización = (props) => {

  const {activeStep, setActiveStep, handlePreviewStep, id_actividad} = props
  
  const handleContinue = () => {
    setActiveStep(activeStep - 1)
    handlePreviewStep()
  }

  const {
    handleSize,
    disabledZoom,
    closeZoom,
    handleZoom,
    scale
  }= useContext(DrawerContext);
  const [loading, setLoading] = React.useState(true);
  const wrapperRef = React.useRef(null);
  const imagesLoaded = useOnLoadImages(wrapperRef);

  const detectSize = () => {
    const lectura = document.getElementById("lectura");
    const newWidth = lectura?.offsetWidth;
    const newHeight = lectura?.offsetHeight;
    setLoading(false);
    if (lectura) handleSize(newHeight, newWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", detectSize);
    return () => {
      handleZoom()
      window.removeEventListener("resize", detectSize);
      closeZoom()
    };
  }, []);

  const classes = useStyles({ disabledZoom,scale });
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
             <Grid container item justifyContent="center" className={classes.ContainerInfoLectura}>
                       <Grid container className="BarraInfoLectura">
            <Button className="Regresar" onClick={handleContinue}>
                Regresar
            </Button>
        </Grid>
               <Grid container item className="InfoLectura">
                    <Grid container item direction="column" xs={6} justifyContent="center">
                        <p className="Dato">Grado</p>
                        <p className="Caracter">4</p>
                    </Grid>
                    <Grid container item direction="column" xs={6} justifyContent="center" className="Sesion">
                        <p className="Dato">Sesión</p>
                        <p className="Caracter">5</p>
                    </Grid>
               </Grid>
            </Grid>
            {!imagesLoaded && <Loader />}
        <Grid
            container
            item
            className={classes.ContenedorLectura}
            justifyContent="center"
            id="lectura"
            onLoad={detectSize}
            ref={wrapperRef}
        >
<Drawer id_lectura={id_actividad} />
            <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >¿Y estos edificios?</h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container sm={12}>
                      <p className="Lectura "> 
                        Una de las primeras cosas que se nos vienen a la mente cuando pensamos en una ciudad son 
                        sus edificios. Estas construcciones surgen de la imaginación de un arquitecto o ingeniero, 
                        quien los diseña considerando necesidades específicas de las personas. Pero también, de 
                        acuerdo con ideas que estos artistas han obtenido de su vida diaria, su imaginación y su 
                        educación. Por eso no es común encontrar un edificio igual a otro. Toda obra de arquitectura 
                        es arte, pues nace del ingenio de alguien que ve realizados sus sueños en tercera dimensión.
                        <br/><br/>
                        ¿En tu ciudad hay algún edificio representativo? A lo largo y ancho del planeta existen 
                        edificios con diseños impresionantes que se han vuelto muy famosos. Te mostraremos aquí
                        algunos ejemplos que distintos arquitectos e ingenieros han regalado al mundo para embellecer 
                        las ciudades con la fuerza de la piedra, el brillo del metal y la luz del vidrio.
                        <br/><br/>

                        <h3 className='SubTitulo'>Palacio de Bellas Artes</h3><br/>

                        Es uno de los lugares más importantes de la Ciudad de México. Su construcción comenzó a 
                        principios del siglo XX. Está hecho casi totalmente de mármol, una roca altamente 
                        valorada por su belleza y durabilidad. Es un edificio que bien podría encontrarse en 
                        Europa, pues su creador fue un italiano llamado Adamo Boari, que tiene múltiples elementos 
                        de la flora y fauna mexicana incorporados en su decoración. Un dato curioso es que el 
                        telón del teatro está conformado por más de 200 tableros hechos de cristal.
                      </p>
                    </Grid>
                    <Grid container item justifyContent='center' sm={12} >
                    <img className="Imagen " src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img4to/Sesion05/1.png" alt="Tarjeta" />
                    </Grid>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container md={12} lg={6}>
                      <p className="Lectura Esp"> 
                        <h3 className='SubTitulo'>Empire State</h3><br/>

                        Este edificio se puede ver en todas 
                        partes, incluso en películas de 
                        superhéroes, y ha acompañado a la 
                        ciudad de Nueva York desde 1931. El 
                        diseño fue considerado muy moderno 
                        para su época y su altura resultó 
                        impresionante, ya que era el primer 
                        edificio en la ciudad que tenía más de 
                        cien pisos. Su altura es incluso mayor a la 
                        de otras construcciones famosas, como la 
                        Torre Eiffel, en París. En su interior se 
                        ubican las oficinas de cientos de 
                        compañías de gran renombre.
                      </p>
                      
                    </Grid>
                    <Grid container md={12} lg={6}>
                        <img className="Imagen Escritorio" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img4to/Sesion05/2.png" alt="Tarjeta" />
                        <img className="Imagen Tablet" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img4to/Sesion05/2R.png" alt="Tarjeta" />
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container md={12} lg={6} >
                        <img className="Imagen Escritorio" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img4to/Sesion05/3.png" alt="Tarjeta" />
                        <p className="Lectura Esp Tablet"> 
                        <h3 className='SubTitulo'>Torres del Parque</h3><br/>

                        El arquitecto colombiano Rogelio 
                        Salmona fue el encargado de diseñar 
                        estas torres de departamentos en Bogotá,
                        la capital de su país, Colombia. Decidió 
                        seguir la tendencia de construir edificios 
                        en ladrillo, como la mayoría de los que 
                        existen en la ciudad. Quería continuar 
                        con este estilo y activar la economía, pues 
                        la fabricación de ladrillos daría trabajo a 
                        una gran cantidad de personas. Desde 
                        1970, estas torres son parte del paisaje 
                        de esa ciudad que se encuentra al pie de 
                        las montañas.

                      </p>
                    </Grid>
                    <Grid container md={12} lg={6} >
                      <p className="Lectura Esp Escritorio"> 
                        <h3 className='SubTitulo'>Torres del Parque</h3><br/>

                        El arquitecto colombiano Rogelio Salmona fue el encargado de diseñar estas torres de 
                        departamentos en Bogotá, la capital de su país, Colombia. Decidió seguir la tendencia 
                        de construir edificios en ladrillo, como la mayoría de los que existen en la ciudad. 
                        Quería continuar con este estilo y activar la economía, pues la fabricación de ladrillos 
                        daría trabajo a una gran cantidad de personas. Desde 1970, estas torres son parte del 
                        paisaje de esa ciudad que se encuentra al pie de las montañas.
                      </p>
                      <img className="Imagen Tablet" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img4to/Sesion05/3R.png" alt="Tarjeta" />
                    </Grid> 

                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container xs={12}>
                        <p className='Lectura '>
                            <h3 className='SubTitulo'>Hotel Burj Al Arab</h3><br/>
                            Su forma está inspirada en un velero y se ha hecho famoso en todo el mundo. Está 
                            ubicado en la ciudad de Dubái, en Emiratos Árabes Unidos. Su fama comenzó hace unas 
                            décadas, debido a su diseño moderno y su lujo. Fue construido en medio del mar 
                            y se llega a él atravesando un puente que lo conecta con la playa. El diseño 
                            estuvo a cargo del arquitecto inglés Tom Wright en 1999.
                        </p>
                    </Grid>
                    <Grid container xs={12}>
                        <img className="Imagen " src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img4to/Sesion05/4.png" alt="Tarjeta" />
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container xs={12}>
                        <p className='Lectura '>
                            <h3 className='SubTitulo'>Grand Santiago Tower</h3><br/>

                            Éste es el edificio más alto de Sudamérica con más de 300 metros de altura. Dentro 
                            de él se encuentran oficinas, tiendas y otros comercios. Su diseño es obra del 
                            argentino César Pelli, y es reconocido por su estilo moderno y sus ventanas que
                            reflejan las enormes montañas nevadas de la cordillera de los Andes que están en 
                            Santiago, la capital de Chile.
                        </p>
                    </Grid>
                    <Grid container xs={12}>
                        <img className="Imagen " src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img4to/Sesion05/5.png" alt="Tarjeta" />
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container xs={12}>
                        <p className='Lectura '>
                            Ahora que has visto cómo son algunos de algunos de los edificios más 
                            representativos de distintas ciudades del mundo, seguramente verás los edificios 
                            a tu alrededor con una mirada más curiosa y nueva, y sabrás que un arquitecto usó 
                            toda su creatividad para construirlos.
                        </p>
                    </Grid>
                </Grid>
        </Grid>
        <Grid container item style={{ marginTop: 80}} ></Grid>
    </Grid>
    );
}
 
export default Las_abejas_y_la_polinización;
