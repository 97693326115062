import { Grid, makeStyles } from "@material-ui/core";
import React, { useState } from "react";
import map from "./MapaG3.jpg";
import { useContext } from "react";
import AuthContext from "../../../auth/AuthContext";
import ButtonMap from "./Button/MapButton";
import ButtonMapS1 from "./Button/MapButtonS1";
import ButtonMapS60 from "./Button/MapButtonS60";
import ButtonMapS56 from "./Button/MapButtonS56";
import Loader from "../../Loader/Loader";
import { useHistory } from "react-router-dom";
import MapButtons from "./Button/MapButtons";
import useGetMapData from "../../../hooks/useGetMapData";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100vw",
    marginTop: "80px",
    minHeight: "calc(100vh - 80px)",
    overflowY: "auto",
    backgroundColor: "#8a9ab7 ",

    display: "grid",
    justifyContent: "start",
    [theme.breakpoints.up("sm")]: {
      marginTop: "0px",
      // top: "0",
    },
    [theme.breakpoints.up("lg")]: {
      width: "100%",
    },
    [theme.breakpoints.up("xl")]: {
      justifyContent: "center",
      alignItems: "center",
    },
  },
  containerMap: {
    // position: "absolute",
    // top: "80px",
    // left: "0",

    display: "flex",
    height: "1080px",
    // overflow: "hidden",
    width: "1920px",
    position: "relative",
    // marginTop: "80px",
    [theme.breakpoints.up("md")]: {
      marginTop: "0px",
      // top: "0",
    },
    "& .map": {
      height: "100%",
      width: "100%",
      // transform: "translate(3px, -4px)",
      boxShadow: "98px 117px 218px #8d868c, -101px 93px 162px #8d868c",
      // marginTop: "80px",
      [theme.breakpoints.up("md")]: {
        marginTop: "0px",
        // top: "0",
      },
    },
    // "& .BotonGrande": {
    //   cursor: "pointer",
    //   borderColor: "transparent",
    //   // backgroundColor: "transparent",
    //   position: "absolute",
    //   height: "55.7px",
    //   width: "55.7px",
    //   borderRadius: "50%",
    //   font: " 22px lato",
    //   "&:hover": {
    //     // border: "2px solid yellow",
    //   },
    // },
    // "& .Bienvenida": {
    //   top: "106.5px",
    //   left: "320px",
    //   backgroundColor: "transparent",
    //   "&:hover": {
    //     "&:before": {
    //       display: "flex",
    //       position: "relative",
    //       justifyContent: "center",
    //       alignItems: "center",
    //       bottom: "50px",
    //       content: '"Bienvenida"',
    //       background: "#fff",

    //       borderRadius: "25px",
    //       font: "bold 16px lato",
    //       height: "25px",
    //       width: "100px",
    //     },
    //   },
    //   "& .completa": {
    //     borderWidth: "2px",
    //     borderStyle: "solid",
    //     borderColor: "#008000",
    //     "&:hover": {},
    //   },
    // },
    // "& .Boton": {
    //   cursor: "pointer",
    //   border: "none",
    //   background: "none",
    //   position: "absolute",
    //   height: "37px",
    //   width: "37px",
    //   borderRadius: "50%",
    //   "&:hover": {
    //     // border: "2px solid yellow",
    //   },
    // },
  },
}));

const Map3 = () => {
  const { user, dispatch } = useContext(AuthContext);
  const [loading, setLoading] = useState(true);
  const [loadingInterface, setLoadingInterface] = useState(true);
  const history = useHistory();

  const { loadActivitiesList } = useGetMapData(
    3,
    setLoading,
    dispatch,
    history
  );

  const activitiesList = user.activitiesList || [];
  const classes = useStyles();
  return (
    <Grid className={classes.container}>
      <Grid className={classes.containerMap}>
        <img
          className="map"
          src={map}
          loading="lazy"
          alt="Mapa"
          onLoad={() => {
            setLoadingInterface(false);
          }}
        />
          {activitiesList.length ? (
          <MapButtons
            loadActivitiesList={loadActivitiesList}
            activitiesList={activitiesList}
            grade="grado3"
            ButtonMap={ButtonMap}
            ButtonMapS1={ButtonMapS1}
            ButtonMapS56={ButtonMapS56}
            ButtonMapS60={ButtonMapS60}
          />
        ) : null}

        {loading || loadingInterface ? <Loader /> : <></>}
      </Grid>
    </Grid>
  );
};

export default Map3;
