import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
     Contenedor:{
        background:'#E9FBF9',
        width:'100vw',
        overflowX:'clip',
        [theme.breakpoints.down(1161)]:{
            background:'none',
           overflowX:'unset',
        },
        [theme.breakpoints.down(600)]:{
            marginTop: '79.51px',
        }
    },
  ContainerInfoLectura: {
    position: "relative",
    height: "86px",
    [theme.breakpoints.down(502)]:{
        height: "43px",
    },
    background: "#76D4C3",
    "& .BarraInfoLectura": {
      height: "86px",
      background: `Url(https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Barras/Azul.png)`,
        [theme.breakpoints.down(502)]:{
            height: "43px",
        },
    },
    "& .InfoLectura": {
      position: "absolute",
      height: "86px",
      width: "219px",
      boxSizing: "border-box",
      padding: "17.2px 0",
      background: "#FAFAFA",
      borderRadius: "50px",
      boxShadow: "2px 2px 2px 2px #00000029",
      zIndex: "4",
        [theme.breakpoints.down(502)]:{
            height: "43px",
            width: "109px",
            padding: "8.6px 0",
            borderRadius: "25px",
        },
    },
    "& .InfoLectura:before": {
      position: "absolute",
      display: "flex",
      boxSizing: "border-box",
      padding: "0 0 0 10px",
      justifyContent: "center",
      alignItems: "center",
      height: "39.88px",
      width: "39.88px",
      borderRadius: "50%",
      content: '""',
      boxShadow: "3px 0px .5px 0px #00000029",
      background: "#FAFAFA",
      left: "90px",
      top: "65px",
      transform: "rotate(0.25turn)",
      [theme.breakpoints.down(502)]:{
        left: "45px",
        top: "30px",
        height: "20.88px",
        width: "20.88px",
        },
    },
    "& .InfoLectura:after": {
      content: '""',
      height: "15px",
      width: "15px",
      borderTop: "2px solid #20A698",
      borderLeft: "2px solid #20A698",
      position: "absolute",
      bottom: "-10px",
      left: "Calc(50% - 8px)",
      transform: "rotate(0.625turn)",
      [theme.breakpoints.down(502)]:{
        height: "10px",
        width: "10px",
        left: "Calc(50% - 4.5px)",
        bottom: "-3px",
        borderTop: "1px solid #20A698",
        borderLeft: "1px solid #20A698",
        },
    },
    "& .Sesion": {
      borderLeft: "2px solid #D8D8D8",
    },
    "& .Dato": {
      margin: "0 0 5px 0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#20A698",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        margin: "0 0 2.5px 0",
        },
    },
    "& .Caracter": {
      margin: "0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#000000",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        },
    },   },
    ContenedorLectura:{
        background:'#fff',
        width:'1160px',
        height:'100%',
        padding:'0',
        [theme.breakpoints.down(1161)]:{
            width:'768px',
          },
          [theme.breakpoints.down(768)]:{
            width:'350px',
          },
        '& .Derechos':{
            position:'absolute',
            bottom:'50px',
            textAlign:"center",
            margin:"0px",
            font:" 15px lato",
            color:"#717476",
            [theme.breakpoints.down(1161)]:{
                display:'none'
            },
        },
        '& .Escritorio':{
            display:'block',
            [theme.breakpoints.down(1161)]:{
                display:'none'
            },
        },
        '& .Tablet':{
            display:'none',
            [theme.breakpoints.down(1161)]:{
                display:'block'
            },
        }
    },
    Parrafo1: {
        position: "relative",
        "& .Titulo": {
          position: "absolute",
          font: "60px fredoka one",
          letterSpacing: "0px",
          lineHeight: "58px",
          color: "#74629A",
          margin: "0",
          top: "0.48%",
          left: "65px",
          top: "65px",
          [theme.breakpoints.down(1161)]: {
            font: "35px fredoka one",
            lineHeight: "53px",
            left: "35px",
            top: "35px",
          },
          [theme.breakpoints.down(768)]: {
            font: "18px fredoka one",
            lineHeight: "32px",
            left: "25px",
            top: "20px",
          },
        },
        "& .Lectura": {
          lineHeight: "30px",
          margin: "0 ",
          font: "22px lato",
          color: "#000",
          letterSpacing: "0.20px",
          [theme.breakpoints.down(1161)]: {
            font: "18px lato",
            lineHeight: "28px",
            letterSpacing: "0.17px",
          },
          [theme.breakpoints.down(768)]: {
            font: "10px lato",
            lineHeight: "10px",
            letterSpacing: "0.09px",
          },
        },
        "& .SubTitulo": {
          position: "absolute",
          font: "36px Fredoka One",
          color: "#FFFFFF",
          lineHeight: "52px",
          margin: "0",
          [theme.breakpoints.down(1161)]: {
            font: "23px Fredoka One",
            lineHeight: "34px",
          },
          [theme.breakpoints.down(768)]: {
            font: "13px Fredoka One",
            lineHeight: "16px",
            letterSpacing: "0.21px",
          },
        },
        "& .Pronostico1": {
          top: "1035px",
          [theme.breakpoints.down(1161)]: {
            top: "680px",
          },
          [theme.breakpoints.down(768)]: {
            top: "308px",
          },
        },
        "& .Pronostico2": {
          top: "2515px",
          [theme.breakpoints.down(1161)]: {
            top: "1647px",
          },
          [theme.breakpoints.down(768)]: {
            top: "749px",
          },
        },
        "& .Text": {
          position: "absolute",
          font: "bold 30px lato",
          color: "#353535",
          margin: "0",
          [theme.breakpoints.down(1161)]: {
            font: "bold 19px lato",
          },
          [theme.breakpoints.down(768)]: {
            font: "bold 9px lato",
          },
        },
        "& .Simbo1": {
          top: "2775px",
          left: "471px",
          [theme.breakpoints.down(1161)]: {
            top: "1813px",
            left: "323px",
          },
          [theme.breakpoints.down(768)]: {
            top: "827px",
            left: "145px",
          },
        },
        "& .Simbo2": {
          top: "2886px",
          left: "491px",
          [theme.breakpoints.down(1161)]: {
            top: "1885px",
            left: "343px",
          },
          [theme.breakpoints.down(768)]: {
            top: "859px",
            left: "153px",
          },
        },
        "& .Simbo3": {
          top: "2990px",
          left: "378px",
          [theme.breakpoints.down(1161)]: {
            top: "1957px",
            left: "274px",
          },
          [theme.breakpoints.down(768)]: {
            top: "891px",
            left: "116px",
          },
        },
        "& .Simbo4": {
          top: "3100px",
          left: "302px",
          [theme.breakpoints.down(1161)]: {
            top: "2028px",
            left: "223px",
          },
          [theme.breakpoints.down(768)]: {
            top: "921px",
            left: "97px",
          },
        },
        "& .Simbo5": {
          top: "3205px",
          left: "489px",
          [theme.breakpoints.down(1161)]: {
            top: "2098px",
            left: "323px",
          },
          [theme.breakpoints.down(768)]: {
            top: "953px",
            left: "145px",
          },
        },
        "& .Simbo6": {
          top: "3335px",
          left: "467px",
          [theme.breakpoints.down(1161)]: {
            top: "2181px",
            left: "323px",
          },
          [theme.breakpoints.down(768)]: {
            top: "993px",
            left: "150px",
          },
        },
        "& .Temp": {
          bottom: "200px",
          left: "470px",
          [theme.breakpoints.down(1161)]: {
            bottom: "92px",
            left: "307px",
          },
          [theme.breakpoints.down(768)]: {
            bottom: "40px",
            left: "135px",
          },
        },
        "& .Caliente": {
          color: "#DE0606",
        },
        "& .Frio": {
          color: "#3A57A7",
        },
    
        "& .Imagen": {
          [theme.breakpoints.down(1161)]: {
            width: "100%",
          },
        },
      },
    }));

const Pronostico_del_clima = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
             <Grid container item justifyContent="center" className={classes.ContainerInfoLectura}>
               <Grid container className="BarraInfoLectura"></Grid>
               <Grid container item className="InfoLectura">
                    <Grid container item direction="column" xs={6} justifyContent="center">
                        <p className="Dato">Grado</p>
                        <p className="Caracter">3</p>
                    </Grid>
                    <Grid container item direction="column" xs={6} justifyContent="center" className="Sesion">
                        <p className="Dato">Sesión</p>
                        <p className="Caracter">43</p>
                    </Grid>
               </Grid>
            </Grid>

            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container justifyContent='center'>
                        <img className="Imagen Escritorio" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img1ro/Sesion43/1.png" alt="Tarjeta" />
                        <img className="Imagen Tablet" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img1ro/Sesion43/1R.png" alt="Tarjeta" />
                        <h2 className='Pos Titulo'>
                           Pronóstico del clima<br/>
                           en América Latina <br/>
                           para este fin de semana
                        </h2>
                        <h3 className='Pos SubTitulo Pronostico1'>Pronóstico para México y Centroamérica</h3>
                        <h3 className='Pos SubTitulo Pronostico2'>Pronóstico para Sudamérica</h3>
                        <p className='Text Simbo1'>Cielo despejado</p>
                        <p className='Text Simbo2'>Cielo nuboso</p>
                        <p className='Text Simbo3'>Intervalos nubosos con lluvias débiles</p>
                        <p className='Text Simbo4'>Cielo nuboso con lluvias fuertes</p>
                        <p className='Text Simbo5'>Tormentas eléctricas</p>
                        <p className='Text Simbo6'>Nevada</p>
                        <p className='Text Temp '>
                            <span className="Caliente">Temperatura máxima en ºC</span><br/>
                            <span className='Frio'>Temperatura mínima en ºC</span>
                        </p>
                       
                        <p className="Derechos">
                                © 2022. Todos los derechos reservados, Pearson, Circuito de lectores.
                            </p>    
                        
                    </Grid>
                </Grid>

            
        </Grid>
    </Grid>
    );
}
 
export default Pronostico_del_clima;
