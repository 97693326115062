import { Button, Grid, makeStyles } from "@material-ui/core";
import React, { useContext, useEffect } from "react";
import DrawerContext from "../../../../../usability/DrawerContext";
import { useOnLoadImages } from "../../../../hooks/useOnLoadImages";
import Loader from "../../../../Loader/Loader";
import Drawer from "../../ReadingTools/Drawer";

const useStyles =  makeStyles (theme =>({
     Contenedor:{
        background:'#E9FBF9',
        width:'100vw',
        overflowX:'clip',
        [theme.breakpoints.down(1161)]:{
            background:'none',
           overflowX:'unset',
        },
        [theme.breakpoints.down(600)]:{
            marginTop: '79.51px',
        }
    },
  ContainerInfoLectura: {
    position: "relative",
    height: "86px",
    [theme.breakpoints.down(502)]:{
        height: "43px",
    },
    background: "#76D4C3",
    "& .BarraInfoLectura": {
      height: "86px",
      background: `Url(https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Barras/Azul.png)`,
        [theme.breakpoints.down(502)]:{
            height: "43px",
        },
    },
    "& .InfoLectura": {
      position: "absolute",
      height: "86px",
      width: "219px",
      boxSizing: "border-box",
      padding: "17.2px 0",
      background: "#FAFAFA",
      borderRadius: "50px",
      boxShadow: "2px 2px 2px 2px #00000029",
      zIndex: "4",
        [theme.breakpoints.down(502)]:{
            height: "43px",
            width: "109px",
            padding: "8.6px 0",
            borderRadius: "25px",
        },
    },
    "& .InfoLectura:before": {
      position: "absolute",
      display: "flex",
      boxSizing: "border-box",
      padding: "0 0 0 10px",
      justifyContent: "center",
      alignItems: "center",
      height: "39.88px",
      width: "39.88px",
      borderRadius: "50%",
      content: '""',
      boxShadow: "3px 0px .5px 0px #00000029",
      background: "#FAFAFA",
      left: "90px",
      top: "65px",
      transform: "rotate(0.25turn)",
      [theme.breakpoints.down(502)]:{
        left: "45px",
        top: "30px",
        height: "20.88px",
        width: "20.88px",
        },
    },
    "& .InfoLectura:after": {
      content: '""',
      height: "15px",
      width: "15px",
      borderTop: "2px solid #20A698",
      borderLeft: "2px solid #20A698",
      position: "absolute",
      bottom: "-10px",
      left: "Calc(50% - 8px)",
      transform: "rotate(0.625turn)",
      [theme.breakpoints.down(502)]:{
        height: "10px",
        width: "10px",
        left: "Calc(50% - 4.5px)",
        bottom: "-3px",
        borderTop: "1px solid #20A698",
        borderLeft: "1px solid #20A698",
        },
    },
    "& .Sesion": {
      borderLeft: "2px solid #D8D8D8",
    },
    "& .Dato": {
      margin: "0 0 5px 0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#20A698",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        margin: "0 0 2.5px 0",
        },
    },
    "& .Caracter": {
      margin: "0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#000000",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        },
    },
       '& .Regresar':{
        color: '#F2624F',
        border: '#F2624F 1px solid',
        height: '30px',
        width:'100px',
        borderRadius: '20px',
        letterSpacing: '0.14px',
        textTransform: 'none',
        background:'#FEFAF1',
        top:'calc(50% - 15px)',
        left:'5%',
        zIndex:4,
        [theme.breakpoints.down(502)]: {
          font:'bold 10px lato',
          width: "60px",
        },
      },
  },
    ContenedorLectura:{
        background:'#fff',
        width:'1160px',
        height:'100%',
        padding:'60px 168px 35px ',
        
        transform: (props) =>
        props.scale > 1 ? `scale(${props.scale}) translate(0px, 10%)` : `none`,
        [theme.breakpoints.down(1161)]:{
            width:'502px',
            padding:'60px 0 0 0',
          },
        [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },

        '& .Escritorio':{
            display:'block',
            [theme.breakpoints.down(1161)]:{
                display:'none'
            },
        },
        '& .Tablet':{
            display:'none',
            [theme.breakpoints.down(1161)]:{
                display:'block'
            },
        }
    },
    ContainerTitulo:{
        margin:'0 0 34px 0',
        [theme.breakpoints.down(1161)]:{
            margin:'0 0 34px 0',
        },
        '& .Titulo':{
            letterSpacing: '0px',
            color: '#D10AC2',
            margin:'0',
            textAlign:'center',
            font:'48px fredoka one',
            lineHeight:'58px',
            [theme.breakpoints.down(1161)]:{
                font:'43px fredoka one',
                lineHeight:'52px',
            },
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        position:'relative',
        '& .Lectura':{
            margin:'0',
            color:'#000',
            font:'22px lato',
            lineHeight : '30px',
            letterSpacing: '0.20px',
            [theme.breakpoints.down(1161)]:{
                font:'18px lato',
                lineHeight :'28px',
                letterSpacing: '0.10px',
            },
            [theme.breakpoints.down(502)]:{
                font:'12px lato',
                lineHeight :'13.5px',
                letterSpacing: '0.09px',
            },
        },
        "& .SubTitulo": {
            color: "#926DD1",
            margin: "0",
            font: " 38px Fredoka One",
            letterSpacing: "-.05px",
            lineHeight: "46px",
            [theme.breakpoints.down(1161)]: {
                font: " 30px Fredoka One",
                lineHeight: "36px",
                letterSpacing: "-.05px",
            },
            [theme.breakpoints.down(502)]: {
              font: " 15px Fredoka One",
              lineHeight: "15px",
              letterSpacing: "0.20px",
            },
          },
          "& .SubTitulo2": {
            color: "#D10AC2",
            margin: "0",
            font: "30px Fredoka One",
            letterSpacing: ".24px",
            lineHeight: "28px",
            [theme.breakpoints.down(1161)]: {
              font: "25px Fredoka One",
              lineHeight: "28px",
              letterSpacing: "0.23px",
            },
            [theme.breakpoints.down(502)]: {
              font: "12px Fredoka One",
              lineHeight: "13px",
              letterSpacing: "0.21px",
            },
          },
          '& .Esp':{
            width:'700px',
            [theme.breakpoints.down(1161)]:{
                width:'552px',
            },
            [theme.breakpoints.down(502)]:{
                width:'300px',
            },
          },
        '& .TextImg':{
            font:'20px lato',
            color:'#717476',
            letterSpacing:'.19px',
            lineHeight:'30px',
            margin:' 0',
            [theme.breakpoints.down(1161)]:{
                font:' 16px lato',
                lineHeight :'25px',
                letterSpacing: '0.15px',
            },
            [theme.breakpoints.down(502)]:{
                font:' 10px lato',
                lineHeight :'12px',
                letterSpacing: '0.15px',
            },
        },
        '& .Pos':{
            position:'absolute',
            top:'2.16%',
            left:'62px',
            [theme.breakpoints.down(1161)]:{
                top:'1.5%',
                left:'-30px',
            },
            [theme.breakpoints.down(502)]:{
                left:'33px',
            },
        },
        '& .Pos2':{
            position:'absolute',
            top:'21.18%',
            [theme.breakpoints.down(1161)]:{
                top:'21.18%',
            },
        },
        '& .Pos3':{
            position:'absolute',
            top:'22.45%',
            left:'62px',
            [theme.breakpoints.down(1161)]:{
                left:'-30px',
            },
            [theme.breakpoints.down(502)]:{
                left:'33px',
            },
        },
        '& .Pos4':{
            position:'absolute',
            top:'35.81%',
            [theme.breakpoints.down(502)]:{
                top:'35%',
            },
        },
        '& .Pos5':{
            position:'absolute',
            top:'37%',
            left:'62px',
            [theme.breakpoints.down(1161)]:{
                left:'-30px',
            },
            [theme.breakpoints.down(502)]:{
                left:'33px',
                top:'36%',
            },
        },
        '& .Pos6':{
            position:'absolute',
            top:'64.85%',
            [theme.breakpoints.down(502)]:{
                top:'64.25%',
            },
        },
        '& .Pos7':{
            position:'absolute',
            left:'62px',
            top:'66.20%',
            [theme.breakpoints.down(1161)]:{
                left:'-30px',
            },
            [theme.breakpoints.down(502)]:{
                top:'65.4%',
                left:'33px',
            },
        },
        '& .Pos8':{
            position:'absolute',
            top:'90.8%',
            [theme.breakpoints.down(1161)]:{
                top:'89%',
            },
        },
        '& .Pos9':{
            position:'absolute',
            left:'62px',
            top:'92%',
            [theme.breakpoints.down(1161)]:{
                left:'-30px',
                top:'90%',
            },
            [theme.breakpoints.down(502)]:{
                left:'33px',
            },
        },
        '& .Imagen':{
            [theme.breakpoints.down(1161)]:{
                width:'680px',
            },
            [theme.breakpoints.down(502)]:{
                width:'100%',
            },
        }, 
        '& .Ocultar':{
                [theme.breakpoints.down(350)]:{
                    display:'none',
                },
            }, 
        },
        Imagen:{
            display:'none',
            [theme.breakpoints.down(350)]:{
                display:'flex',
                width:'100%',
            },
        }, 
}));

const El_arte_de_la_pintura = (props) => {

  const {activeStep, setActiveStep, handlePreviewStep, id_actividad} = props

  const handleContinue = () => {
    setActiveStep(activeStep - 1)
    handlePreviewStep()
  }

    const {
      handleSize,
    disabledZoom,
    closeZoom,
    handleZoom,
    scale
  }= useContext(DrawerContext);
  const [loading, setLoading] = React.useState(true);
  const wrapperRef = React.useRef(null);
  const imagesLoaded = useOnLoadImages(wrapperRef);

  const detectSize = () => {
    const lectura = document.getElementById("lectura");
    const newWidth = lectura?.offsetWidth;
    const newHeight = lectura?.offsetHeight;
    setLoading(false);
    if (lectura) handleSize(newHeight, newWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", detectSize);
    return () => {
      handleZoom()
      window.removeEventListener("resize", detectSize);
      closeZoom()
    };
  }, []);

  const classes = useStyles({ disabledZoom,scale });
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
             <Grid container item justifyContent="center" className={classes.ContainerInfoLectura}>
                <Grid container className="BarraInfoLectura">
                    <Button className="Regresar" onClick={handleContinue}>
                        Regresar
                    </Button>
                </Grid>
               <Grid container item className="InfoLectura">
                    <Grid container item direction="column" xs={6} justifyContent="center">
                        <p className="Dato">Grado</p>
                        <p className="Caracter">3</p>
                    </Grid>
                    <Grid container item direction="column" xs={6} justifyContent="center" className="Sesion">
                        <p className="Dato">Sesión</p>
                        <p className="Caracter">46</p>
                    </Grid>
               </Grid>
            </Grid>
            {!imagesLoaded && <Loader />}
        <Grid
            container
            item
            className={classes.ContenedorLectura}
            justifyContent="center"
            id="lectura"
            onLoad={detectSize}
            ref={wrapperRef}
        >
<Drawer id_lectura={id_actividad} />
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >
                        El arte de la pintura                    
                    </h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container justifyContent='center' sm={12}>
                      <p className="Lectura">
                        Hoy aprenderemos sobre la pintura. Es un arte plástica en la que se distribuyen 
                        pigmentos sobre un soporte con el fin de expresar emociones o contar historias. La 
                        siguiente sección explica los elementos que conforman una pintura.
                      </p>
                    </Grid>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container item sm={12} justifyContent="center" className="Ocultar">
                    <img className="Imagen Escritorio" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img3ro/Sesion46/1.png" alt="Tarjeta" />
                    <img className="Imagen Tablet" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img3ro/Sesion46/1R.png" alt="Tarjeta" />

                        <p className="Lectura Pos Esp">
                            <h3 className='SubTitulo'>Elementos que conforman una pintura</h3><br/>

                            <h3 className='SubTitulo2'>Marco</h3><br/>

                            Protege y resalta una obra cuando contrasta con sus tonos. Existen de 
                            diferentes colores, texturas y materiales, pero principalmente se 
                            utilizan de madera y de metal.<br/><br/>

                            <h3 className='SubTitulo2'>Soporte</h3><br/>

                            Todo material es aplicado sobre una superficie, que es llamada <em>soporte</em>. 
                            Varía en material y textura. El más común es el lienzo de tela.     
                        </p>
                        <p className='TextImg Pos2'><em>Epopeya del pueblo mexicano</em>, Diego Rivera.</p>

                        <p className='Lectura Pos3 Esp'>
                            <h3 className='SubTitulo2'>Textura</h3><br/>

                            Es la cualidad de una superficie que puede ser vista o sentida. Hay dos 
                            tipos: visual y de tacto. Claude Monet perteneció a una corriente del 
                            siglo <span style={{fontVariant: "all-small-caps"}}>XIX</span> llamada <em>impresionismo</em> e intentaba dar la sensación de que sus 
                            cuadros se movían como el agua.
                        </p>

                        <p className='TextImg Pos4'><em>Nenúfares</em>, Claude, Claude Monet.</p>

                        
                        <p className='Lectura Pos5 Esp'>
                            <h3 className='SubTitulo2'>Técnica</h3><br/>

                            Es el tipo de material usado. Puede ser óleo, lápiz, acuarela, madera, 
                            cerámica, etcétera. En la <em>técnica mixta</em> se emplean varias al mismo 
                            tiempo. Andy Warhol utilizó una técnica moderna llamada <em>serigrafía</em>, 
                            que consiste en colocar sellos de varias capas y colores de una misma 
                            imagen.<br/><br/> 
                            
                            <h3 className='SubTitulo2'>Primer plano</h3><br/>
                            
                            El primer plano abarca las figuras humanas, animales, geométricas y 
                            trazos que se encuentran en la parte más frontal. <em>La Mona Lisa</em> o 
                            <em> Gioconda</em> es la obra más famosa de Leonardo da Vinci. En ella se aprecia 
                            bien su rostro, ya que la mujer retratada está en este plano y parece 
                            estar cerca del espectador.<br/><br/> 
                             
                            <h3 className='SubTitulo2'>Dimensiones o formato</h3><br/>

                            Toda pintura tiene medidas. Existen formatos grandes y pequeños; hay 
                            creaciones hechas sobre azulejos minúsculos y otras en muros enteros.
                            El italiano Miguel Ángel hizo una de las más célebres en el techo de una 
                            iglesia en el Vaticano, conocida como la Bóveda de la Capilla Sixtina de 
                            Roma.
                        </p>

                        <p className='TextImg Pos6'>Ilustración de la Bóveda de la Capilla Sixtina pintada por Miguel Ángel.</p>

                        <p className='Lectura Pos7 Esp'>
                            <h3 className='SubTitulo2'>Fondo</h3><br/>

                            Es el eje central de la pieza, pues en él se produce el efecto de profundidad. 
                            Los tonos se contrastan y otorgan contornos y sombras. Adam Coster
                            tiene una pintura llamada <em>Un hombre cantando a la luz de las velas</em>. En ella 
                            aparece un sujeto en un fondo completamente negro. La obra es famosa 
                            porque las sombras en la cara del personaje dan a entender que se 
                            encontraba en oscuridad total.
                        </p>

                        <p className='TextImg Pos8 '><em><em>Un hombre cantando a la luz de las velas, de Adam Costos.</em></em></p>

                        <p className='Lectura Pos9 Esp'>
                            <h3 className='SubTitulo2'>Tema</h3><br/>

                            Se forma con todos los elementos trazados. Los cuadros expresan una 
                            sensación, aunque se trate de figuras abstractas. Sandro Botticelli pintó  
                            <em> El nacimiento de Venus</em>, en el que representó la historia de dicha diosa.<br/><br/>
                             
                            <h3 className='SubTitulo2'>Serie</h3><br/> 
                            Algunas piezas forman parte de una colección. Por ejemplo, en la 
                            Bóveda de la Capilla Sixtina se aprecian muchos pasajes de la Biblia.
                        </p>
                    </Grid>
                    <img className={classes.Imagen} src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img3ro/Sesion46/F2.png" alt="Tarjeta" />
                    
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container justifyContent='center' sm={12}>
                      <p className="Lectura">
                        Ya cuentas con herramientas nuevas para estudiar mejor el arte. Ahora pondrás 
                        atención en todos los elementos que lo componen. Inténtalo con una obra de un 
                        museo virtual. ¡Adelante!
                      </p>
                    </Grid>
                </Grid>
        </Grid>
        <Grid container item style={{ marginTop: 80}} ></Grid>
    </Grid>
    );
}
 
export default El_arte_de_la_pintura ;
