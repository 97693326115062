import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
    ContenedorLectura:{
        width:'502px',
        [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },

    },
    ContainerTitulo:{
            margin:'0 0 38px 0',
        '& .Titulo':{
            letterSpacing: '0px',
            color: '#161CB1',
            margin:'0',
            font:'43px fredoka one',
            lineHeight:'52px',
            textAlign:'center',
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
      
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            margin:'0 0 36px 0 ',
            color:'#000',
            lineHeight :'28px',
            font:'18px lato',
            letterSpacing: '0.16px',
        [theme.breakpoints.down(502)]:{
            font:'16px lato',
            lineHeight :'21.5px',
            letterSpacing: '0.09px',
        },
        },
        '& .Imagen':{
            width:'100%',
        },
    },

}));

const Principios_éticos_para_un_mundo_mejor = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >
                        Ciudades inmortales
                    </h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura Escritorio">
                            Sofía Lem se interesó por el pasado desde pequeña. Tan pronto como aprendió a leer <br/>
                            devoró todos los libros de Historia y Arqueología que su abuelo atesoraba en la <br/>
                            biblioteca de su casa. Sofía le preguntaba a él cuando no entendía algo y siempre <br/>
                            obtenía una respuesta satisfactoria. Su abuelo era el hombre más sabio que conocía. <br/><br/>
                            
                            —¿Tú eres historiador? —le preguntó un día mientras leían juntos en la biblioteca un <br/>
                            libro sobre los hallazgos arqueológicos en Roma y Egipto. <br/><br/>
                            
                            —No, pequeña. Pero siempre me apasionó el pasado, como a ti. Por eso sé tantas <br/>
                            cosas. <br/><br/>

                            —¡Eso significa que tú me heredaste el amor por la Historia! <br/><br/>
                            
                            —Sí, creo que así fue. <br/><br/>
                            
                            Sofía se quedó pensando un instante con la mirada perdida en sus ilusiones y <br/>
                            después dijo: <br/><br/>
                            
                            —Abuelo, yo de grande quiero ser arqueóloga. <br/><br/>
                            
                            —Me parece maravilloso, pequeña. Hace falta alguien que rescate nuestra Historia <br/>
                            desde lo profundo de la tierra. Estoy seguro de que en América y en nuestro país <br/>
                            tenemos un pasado muy rico aún por descubrir —dijo y abrazó a su nieta.
                        </p>
                        <p className="Lectura Tablet">
                            Sofía Lem se interesó por el pasado desde pequeña. Tan pronto  <br/>
                            como aprendió a leer devoró todos los libros de Historia y<br/>
                            Arqueología que su abuelo atesoraba en la biblioteca de su <br/>
                            casa. Sofía le preguntaba a él cuando no entendía algo y  <br/>
                            siempre obtenía una respuesta satisfactoria. Su abuelo era el <br/>
                            hombre más sabio que conocía. <br/><br/>
                            
                            —¿Tú eres historiador? —le preguntó un día mientras leían  <br/>
                            juntos en la biblioteca un libro sobre los hallazgos <br/>
                            arqueológicos en Roma y Egipto. <br/><br/>
                            
                            —No, pequeña. Pero siempre me apasionó el pasado, como a ti. <br/>
                            Por eso sé tantas cosas. <br/><br/>
                            
                            —¡Eso significa que tú me heredaste el amor por la Historia! <br/><br/>
                            
                            —Sí, creo que así fue. <br/><br/>
                            
                            Sofía se quedó pensando un instante con la mirada perdida en <br/>
                            sus ilusiones y después dijo: <br/><br/>
                            
                            —Abuelo, yo de grande quiero ser arqueóloga. <br/><br/>
                            
                            —Me parece maravilloso, pequeña. Hace falta alguien que  <br/>
                            rescate nuestra Historia desde lo profundo de la tierra. Estoy  <br/>
                            seguro de que en América y en nuestro país tenemos un pasado <br/>
                            muy rico aún por descubrir —dijo y abrazó a su nieta.
                        </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                            <img className='Imagen' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion20/1.png"  alt="Tarjeta" />
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura Escritorio">
                            A partir de ese momento Sofía se dedicó en cuerpo y alma al estudio de la Historia. A <br/>
                            los 22 años se convirtió en la primera arqueóloga de su país. En aquella época los <br/>
                            arqueólogos de todo el mundo buscaban con fervor la mítica ciudad de Ligum. Según <br/>
                            los textos antiguos, esta urbe fue construida en su mayoría con maderas preciosas <br/>
                            perennes, pero algunos afirmaban que nunca había existido, pues ningún tipo de <br/>
                            madera podría resistir tantos años debajo de la tierra.<br/><br/>
                            
                            —A menos que sea mágica —dijo Sofía Lem cuando anunció que dirigiría una <br/>
                            excavación en su país para encontrar la ciudad de las maderas eternas. <br/><br/>
                            
                            La noticia causó revuelo en todo el mundo. Los arqueólogos más experimentados <br/>
                            aseguraban que si Ligum existió realmente debía hallarse en China o en Europa <br/>
                            oriental, pero jamás en América. En consecuencia, nadie confió en el proyecto de <br/>
                            Sofía Lem.
                        </p>

                        <p className="Lectura Tablet">
                            A partir de ese momento Sofía se dedicó en cuerpo y alma al  <br/>
                            estudio de la Historia. A los 22 años se convirtió en la primera  <br/>
                            arqueóloga de su país. En aquella época los arqueólogos de <br/>
                            todo el mundo buscaban con fervor la mítica ciudad de Ligum.  <br/>
                            Según los textos antiguos, esta urbe fue construida en su <br/>
                            mayoría con maderas preciosas perennes, pero algunos  <br/>
                            afirmaban que nunca había existido, pues ningún tipo de <br/>
                            madera podría resistir tantos años debajo de la tierra.<br/><br/>
                            
                            —A menos que sea mágica —dijo Sofía Lem cuando anunció que  <br/>
                            dirigiría una excavación en su país para encontrar la ciudad de <br/>
                            las maderas eternas. <br/><br/>
                            
                            La noticia causó revuelo en todo el mundo. Los arqueólogos <br/>
                            más experimentados aseguraban que si Ligum existió <br/>
                            realmente debía hallarse en China o en Europa oriental, pero<br/>
                            jamás en América. En consecuencia, nadie confió en el <br/>
                            proyecto de Sofía Lem.
                        </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                            <img className='Imagen' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion20/2.png"  alt="Tarjeta" />
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura Escritorio">
                            Al principio ni siquiera ella estaba segura del todo. Pero una vez más, su abuelo la <br/>
                            orientó. <br/><br/>
                            
                            —¿Has oído hablar de los pinos longevos? <br/><br/>
                            
                            —No, abuelo. <br/><br/>
                            
                            —Son árboles que han vivido más de 5 000 años en perfectas condiciones. Así que <br/>
                            ¿por qué no podría existir esa ciudad? <br/><br/>
                            
                            Esta conversación le infundió el valor que le faltaba. Retomó su investigación y visitó <br/>
                            todas las bibliotecas en las que podía haber algún documento que hablara sobre <br/>
                            Ligum. Cuando tuvo datos suficientes elaboró diagramas precisos de los ríos y las <br/>
                            montañas que pudieron estar próximos a la ciudad de las maderas inmortales. Así <br/>
                            concluyó que el lugar donde había que excavar era justamente su país. <br/><br/>
                            
                            Sofía Lem vivió meses enteros en casas de campaña con su equipo. Exploró el terreno <br/>
                            palmo a palmo, comparó sus diagramas con el paisaje natural. Y un día que la lluvia <br/>
                            despejó la ladera de una montaña encontró lo que parecía la cúpula de una <br/>
                            construcción antigua. De inmediato ella y sus colaboradores acordonaron la zona. <br/>
                            ¡Estaba segura de que se trataba de un hallazgo importante!
                        </p>

                        <p className="Lectura Tablet">
                            Al principio ni siquiera ella estaba segura del todo. Pero una  <br/>
                            vez más, su abuelo la orientó. <br/><br/>
                            
                            —¿Has oído hablar de los pinos longevos? <br/><br/>
                            
                            —No, abuelo. <br/><br/>
                            
                            —Son árboles que han vivido más de 5 000 años en perfectas  <br/>
                            condiciones. Así que ¿por qué no podría existir esa ciudad? <br/><br/>
                            
                            Esta conversación le infundió el valor que le faltaba. Retomó su<br/>
                            investigación y visitó  todas las bibliotecas en las que podía  <br/>
                            haber algún documento que hablara sobre Ligum. Cuando tuvo  <br/>
                            datos suficientes elaboró diagramas precisos de los ríos y las <br/>
                            montañas que pudieron estar próximos a la ciudad de las  <br/>
                            maderas inmortales. Así concluyó que el lugar donde había que <br/>
                            excavar era justamente su país. <br/><br/>
                            
                            Sofía Lem vivió meses enteros en casas de campaña con su  <br/>
                            equipo. Exploró el terreno palmo a palmo, comparó sus <br/>
                            diagramas con el paisaje natural. Y un día que la lluvia despejó<br/>
                            la ladera de una montaña encontró lo que parecía la cúpula de  <br/>
                            una construcción antigua. De inmediato ella y sus  <br/>
                            colaboradores acordonaron la zona. ¡Estaba segura de que se <br/>
                            trataba de un hallazgo importante!
                        </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                            <img className='Imagen' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion20/3.png"  alt="Tarjeta" />
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura Escritorio">
                            —Mañana a primera hora que ya no llueva empezaremos a trabajar—dijo Sofía y se <br/>
                            retiró a su casa de campaña. <br/><br/>
                            
                            Esa noche ningún miembro de la expedición pudo dormir por la emoción y los nervios <br/>
                            que les provocaba la idea de estar encima de la ciudad de las maderas inmortales. Si <br/>
                            sus estudios y sus cálculos no estaban equivocados, Sofía Lem se convertiría en una <br/>
                            célebre arqueóloga. <br/><br/>
                            
                            “Y todo gracias a ti, abuelo”, pensó. <br/><br/>
                            
                            El trabajo duró mucho tiempo y fue muy difícil. Sin embargo, el esfuerzo tuvo su <br/>
                            recompensa. Una tarde, cuando casi se ocultaba el sol, Sofía Lem y su equipo <br/>
                            desenterraron una columna de madera de tres metros de altura. En ella estaba <br/>
                            tallada una figura humana femenina. Gracias a sus dibujos supo que se trataba de <br/>
                            Anquira, la diosa más importante de la ciudad de las maderas inmortales. <br/><br/>
                            
                            Sofía se tomó una fotografía junto a la columna, la imprimió y la envió a su abuelo. En <br/>
                            una nota escribió: “Lo logramos, abuelo. Los sueños existen”.
                        </p>

                        <p className="Lectura Tablet">
                            —Mañana a primera hora que ya no llueva empezaremos a  <br/>
                            trabajar—dijo Sofía y se retiró a su casa de campaña. <br/><br/>
                            
                            Esa noche ningún miembro de la expedición pudo dormir por la  <br/>
                            emoción y los nervios que les provocaba la idea de estar  <br/>
                            encima de la ciudad de las maderas inmortales. Si sus estudios <br/>
                            y sus cálculos no estaban equivocados, Sofía Lem se convertiría  <br/>
                            en una célebre arqueóloga. <br/><br/>
                            
                            “Y todo gracias a ti, abuelo”, pensó. <br/><br/>
                            
                            El trabajo duró mucho tiempo y fue muy difícil. Sin embargo, el  <br/>
                            esfuerzo tuvo su recompensa. Una tarde, cuando casi se  <br/>
                            ocultaba el sol, Sofía Lem y su equipo desenterraron una <br/>
                            columna de madera de tres metros de altura. En ella estaba <br/>
                            tallada una figura humana femenina. Gracias a sus dibujos supo  <br/>
                            que se trataba de Anquira, la diosa más importante de la ciudad<br/>
                            de las maderas inmortales. <br/><br/>
                            
                            Sofía se tomó una fotografía junto a la columna, la imprimió y la  <br/>
                            envió a su abuelo. En una nota escribió: “Lo logramos, abuelo. <br/>
                            Los sueños existen”.
                        </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                            <img className='Imagen' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion20/4.png"  alt="Tarjeta" />
                    </Grid>
                </Grid>
        </Grid>
    </Grid>
    );
}
 
export default Principios_éticos_para_un_mundo_mejor;
