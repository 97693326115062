import React               from "react";
import {Grid, makeStyles}                 from '@material-ui/core';

const useStyles = makeStyles(theme =>({
  questionContainer:{
    width: "100%",
    height:"100%",
  },
  reading: {
    width: "80%",
    margin:"10px 0 10px 0 ",
    font: '22px lato',
    letterSpacing: '0.21px',
    [theme.breakpoints.down(1400)]:{
      font: " 18px lato",
      width: "95%",
    },
    [theme.breakpoints.down(1310)]:{
      font: "500 16px lato",

    },
    [theme.breakpoints.down(925)]:{
      font: "500 14px lato",

    },
    [theme.breakpoints.down(426)]:{
      font: "500 12px lato",
 
    }, 
  },
  table: {
    width: "100%",
    font: '22px lato',
    [theme.breakpoints.down(1400)]:{
      font: " 18px lato",
      width: "95%",
    },
    [theme.breakpoints.down(1310)]:{
      font: "500 16px lato",
 
    },
    [theme.breakpoints.down(925)]:{
      font: "500 14px lato",

    },
    [theme.breakpoints.down(426)]:{
      font: "500 12px lato",

    }, 
  },
  tdTable: {
    padding: "0 1%",
    backgroundColor: "#84E9DF"
    // border: '1px solid black',
  },
  tdTable2: {
    padding: "0 1%",
    backgroundColor: "#FDDC7A"
    // border: '1px solid black',
  }
}));

const QuestionTable = (props) => {
  const classes = useStyles()

  return(
    <Grid container className={classes.questionContainer}>
      <p
        className={classes.reading}
      >
        9. De acuerdo con la lectura, relaciona las teorías de los distintos autores con los ejemplos de aprendizaje.
      </p>
      <br/>
      <table className={classes.table}>
        <thead>
          <th style={{width: "30%", textAlign: "left"}}></th>
          <th style={{width: "70%", textAlign: "left"}}></th>
        </thead>
        <tbody>
          <tr>
            <td className={classes.tdTable}>
              <p>
                a. John Dewey
              </p>
            </td>
            <td className={classes.tdTable2}>
              <p>
                1. El salón de Pedro tiene mucho material didáctico, porque su profesora se basa en una teoría muy interesada en crear un entorno favorable, donde se pueda desarrollar mejor el aprendizaje.
              </p>
            </td>
          </tr>
          <tr>
            <td className={classes.tdTable}>
              <p>
                b. Jean Piaget
              </p>
            </td>
            <td className={classes.tdTable2}>
              <p>
                2. Irene acaba de cumplir 12 años y está muy emocionada porque por fin podrá inscribirse al taller de teatro. En su escuela, las actividades extracurriculares dependen de la edad, porque, según la teoría que siguen, los alumnos pueden adquirir habilidades o conocimientos según su etapa de desarrollo.
              </p>
            </td>
          </tr>
          <tr>
            <td className={classes.tdTable}>
              <p>
                c. Lev Vygotsky
              </p>
            </td>
            <td className={classes.tdTable2}>
              <p>
                3. En la escuela de Sandra, siempre hay actividades en equipo. Incluso con niños y niñas más grandes que ella que le enseñan cosas nuevas. En esos momentos, se divierte mucho y siente que aprende más que cuando la profesora dicta la clase en el pizarrón.
              </p>
            </td>
          </tr>
          <tr>
            <td className={classes.tdTable}>
              <p>
                d. Albert Bandura
              </p>
            </td>
            <td className={classes.tdTable2}>
              <p>
                4. En una clase, la profesora Irma orienta el proceso de aprendizaje preguntando a los alumnos qué saben sobre el tema que van a empezar. Los alumnos siempre levantan la mano antes de hablar y esperan su turno ordenadamente.
              </p>
            </td>
          </tr>
        </tbody>
      </table>
    </Grid>
  )
}

export default QuestionTable;
