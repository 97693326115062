import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
      Contenedor:{
        background:'#E9FBF9',
        width:'100vw',
        overflowX:'clip',
        [theme.breakpoints.down(600)]:{
            background:'none',
            marginTop: '79.51px',
        }
    },
   ContainerInfoLectura: {
    position: "relative",
    height: "86px",
    [theme.breakpoints.down(502)]:{
        height: "43px",
    },
    background: "#76D4C3",
    "& .BarraInfoLectura": {
      height: "86px",
      background: `Url(https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Barras/Azul.png)`,
        [theme.breakpoints.down(502)]:{
            height: "43px",
        },
    },
    "& .InfoLectura": {
      position: "absolute",
      height: "86px",
      width: "219px",
      boxSizing: "border-box",
      padding: "17.2px 0",
      background: "#FAFAFA",
      borderRadius: "50px",
      boxShadow: "2px 2px 2px 2px #00000029",
      zIndex: "4",
        [theme.breakpoints.down(502)]:{
            height: "43px",
            width: "109px",
            padding: "8.6px 0",
            borderRadius: "25px",
        },
    },
    "& .InfoLectura:before": {
      position: "absolute",
      display: "flex",
      boxSizing: "border-box",
      padding: "0 0 0 10px",
      justifyContent: "center",
      alignItems: "center",
      height: "39.88px",
      width: "39.88px",
      borderRadius: "50%",
      content: '""',
      boxShadow: "3px 0px .5px 0px #00000029",
      background: "#FAFAFA",
      left: "90px",
      top: "65px",
      transform: "rotate(0.25turn)",
      [theme.breakpoints.down(502)]:{
        left: "45px",
        top: "30px",
        height: "20.88px",
        width: "20.88px",
        },
    },
    "& .InfoLectura:after": {
      content: '""',
      height: "15px",
      width: "15px",
      borderTop: "2px solid #20A698",
      borderLeft: "2px solid #20A698",
      position: "absolute",
      bottom: "-10px",
      left: "Calc(50% - 8px)",
      transform: "rotate(0.625turn)",
      [theme.breakpoints.down(502)]:{
        height: "10px",
        width: "10px",
        left: "Calc(50% - 4.5px)",
        bottom: "-3px",
        borderTop: "1px solid #20A698",
        borderLeft: "1px solid #20A698",
        },
    },
    "& .Sesion": {
      borderLeft: "2px solid #D8D8D8",
    },
    "& .Dato": {
      margin: "0 0 5px 0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#20A698",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        margin: "0 0 2.5px 0",
        },
    },
    "& .Caracter": {
      margin: "0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#000000",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        },
    },   },
    ContenedorLectura:{
        position:'relative',
        background:'#fff',
        width:'1160px',
        height:'100%',
        padding:'0' ,
        [theme.breakpoints.down(1161)]:{
            width:'768px',
            padding:'60px 0 0',
        },
        [theme.breakpoints.down(768)]:{
            width:'350px',
            padding:'60px 0 0',
        },
        '& .Derechos':{
            textAlign:"center",
            margin:"0px",
            font:" 15px lato",
            color:"#717476",
            bottom:'59px',
            [theme.breakpoints.down(1161)]:{
                display:'none',
            }
        },
        '& .Pos':{
            position:'absolute',
            margin:'0',
        },
        '& .Titulo':{
            font:'48px Fredoka One',
            color:'#FC5E9E',
            textAlign:'center',
            top:'91px',
            lineHeight:'56px',
            [theme.breakpoints.down(1161)]:{
                font:'43px Fredoka One',
                top:'130px',
            },
            [theme.breakpoints.down(768)]:{
                font:'26px fredoka one',
                lineHeight:'32px',
                top:'100px',
            },
        },
        '& .SubTitulo':{
            font:'Italic 70px Fredoka One',
            color:'#926DD1',
            textAlign:'center',
            top:'149px',
            left:'339px',
            lineHeight:'70px',
            [theme.breakpoints.down(1161)]:{
                font:'Italic 56px Fredoka One',
                top:'180px',
                left:'180px',
            },
            [theme.breakpoints.down(768)]:{
                font:'Italic 30px Fredoka One',
                top:'133px',
                left:'70px',
            },
        },
        '& .Cuerpo':{
            font:' 22px lato',
            color:'#000000',
            lineHeight:'28.46px',
            top:'276px',
            left:'181px',
            letterSpacing:'.21px',
            [theme.breakpoints.down(1161)]:{
                font:' 18px lato',
                letterSpacing:'.17px',
                lineHeight:'27.4px',
                top:'285px',
                left:'115px',
            },
            [theme.breakpoints.down(768)]:{
                font:'10px lato',
                lineHeight :'15.8px',
                letterSpacing: '0.09px',
                top:'187px',
                left:'55px',
            },
        },
        '& .Remitente':{
            font:' 22px lato',
            color:'#000000',
            lineHeight:'28.46px',
            width:'824px',
            textAlign:'center',
            top:'1416px',
            left:'181px',
            [theme.breakpoints.down(1161)]:{
                font:' 18px lato',
                letterSpacing:'.17px',
                lineHeight:'27.4px',
                top:'1515px',
                left:'0px',
                width:'768px',
            },
            [theme.breakpoints.down(768)]:{
                font:'10px lato',
                lineHeight :'15.8px',
                letterSpacing: '0.09px',
                top:'897px',
                left:'55px',
                width:'224px',
            },
        },
        '& .Imagen1':{
            [theme.breakpoints.down(1161)]:{
             width:'768px',
             height:'1738px',
            },
            [theme.breakpoints.down(768)]:{
                width:'100%',
                height:'1000px',
               },
         },
         '& .Escritorio':{
            display:'block',
            [theme.breakpoints.down(1161)]:{
                display:'none'
            },
        },
        '& .Tablet':{
            display:'none',
            [theme.breakpoints.down(1161)]:{
                display:'block'
            },
        },  
    },
    Esconder: {
        [theme.breakpoints.down(768)]:{
            display:'none'
        },
    },
    Imagen: {
        display:'none',
        [theme.breakpoints.down(768)]:{
            width:'100%',
            display:'flex'
        },
    },
    
}));

const Tabla_de_actividades_extraescolares = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
             <Grid container item justifyContent="center" className={classes.ContainerInfoLectura}>
               <Grid container className="BarraInfoLectura"></Grid>
               <Grid container item className="InfoLectura">
                    <Grid container item direction="column" xs={6} justifyContent="center">
                        <p className="Dato">Grado</p>
                        <p className="Caracter">6</p>
                    </Grid>
                    <Grid container item direction="column" xs={6} justifyContent="center" className="Sesion">
                        <p className="Dato">Sesión</p>
                        <p className="Caracter">34</p>
                    </Grid>
               </Grid>
            </Grid>

            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item justifyContent="center" className={classes.Esconder}>
                <h2 className='Pos Titulo'>Videojuegos</h2>
                <h2 className='Pos SubTitulo'>Amazing rides</h2>
                <p className='Pos Cuerpo Escritorio'>
                    A quien corresponda: <br/><br/>

                    Mi nombre es Javier, tengo 11 años y vivo en Santa Isabel, provincia de Córdoba. Les<br/> 
                    escribo porque en mi cumpleaños mis tíos me obsequiaron una licencia para el juego <br/>
                    Superhéroes del espacio IV, pero he tenido varios problemas, desde el registro hasta <br/>
                    la activación. <br/><br/>
                    
                    Primero, mi tío José Luis me ayudó a dar de alta mi usuario como jugador, pero el <br/>
                    sitio del juego no reconocía mi correo electrónico. Tuvimos que cerrar y volver a <br/>
                    abrir la página de inicio cinco o seis veces para que llegara el correo con la <br/>
                    confirmación de alta de la cuenta. <br/><br/>
                    
                    Ya que dimos de alta mi cuenta, el sistema no me dejaba iniciar sesión. Aparecían <br/>
                    mensajes informando que mi usuario o contraseña eran incorrectos o que no había <br/>
                    ningún usuario registrado con mi nombre. Mi tía Abi me ayudó a borrar las cookies. <br/>
                    Yo no sabía que eso era necesario y sólo así pude entrar a mi cuenta. <br/><br/>
                    
                    Cuando entro a mi cuenta y quiero activar el juego aparece un mensaje señalando <br/>
                    que hay un error con el código de activación. No dice por qué, sólo no me permite <br/>
                    avanzar. <br/><br/>
                    
                    Tanto mis tíos como mi hermano Toño han intentado comunicarse con una persona <br/>
                    de servicio al cliente en su teléfono de contacto y por chat. Pero les dicen que por el <br/>
                    momento no hay personal disponible para atenderlos. También hemos intentado vía <br/>
                    mail, pero nadie responde. <br/><br/>
                    
                    Dice mi tío que es frustrante no saber qué pasa y yo me siento igual o peor, pues no <br/>
                    sabía que es tan difícil registrarse en un juego. A mis amigos nunca les ha pasado <br/>
                    esto. <br/><br/>
                    
                    En verdad necesito que me respondan. Por ello les escribo esta carta, para saber qué <br/>
                    puedo hacer, porque mi cumpleaños fue hace tres semanas y me gustaría mucho usar <br/>
                    mi regalo. <br/><br/>
                    
                    Sin más por el momento, espero su amable respuesta vía correo electrónico. <br/><br/>
                    
                    Gracias
                </p>
                <p className='Pos Cuerpo Tablet'>
                    A quien corresponda:<br/><br/>
                    
                    Mi nombre es Javier, tengo 11 años y vivo en Santa Isabel, <br/>
                    provincia de Córdoba. Les escribo porque en mi cumpleaños <br/>
                    mis tíos me obsequiaron una licencia para el juego Superhéroes <br/>
                    del espacio IV, pero he tenido varios problemas, desde el <br/>
                    registro hasta la activación. <br/><br/>
                    
                    Primero, mi tío José Luis me ayudó a dar de alta mi usuario <br/>
                    como jugador, pero el sitio del juego no reconocía mi correo<br/>
                    electrónico. Tuvimos que cerrar y volver a abrir la página de <br/>
                    inicio cinco o seis veces para que llegara el correo con la <br/>
                    confirmación de alta de la cuenta.<br/><br/>
                    
                    Ya que dimos de alta mi cuenta, el sistema no me dejaba iniciar <br/>
                    sesión. Aparecían mensajes informando que mi usuario o <br/>
                    contraseña eran incorrectos o que no había ningún usuario <br/>
                    registrado con mi nombre. Mi tía Abi me ayudó a borrar las <br/>
                    cookies. Yo no sabía que eso era necesario y sólo así pude <br/>
                    entrar a mi cuenta. <br/><br/>
                    
                    Cuando entro a mi cuenta y quiero activar el juego aparece un <br/>
                    mensaje señalando que hay un error con el código de <br/>
                    activación. No dice por qué, sólo no me permite avanzar. <br/><br/>
                    
                    Tanto mis tíos como mi hermano Toño han intentado <br/>
                    comunicarse con una persona de servicio al cliente en su <br/>
                    teléfono de contacto y por chat. Pero les dicen que por el <br/>
                    momento no hay personal disponible para atenderlos. También <br/>
                    hemos intentado vía mail, pero nadie responde. <br/><br/>

                    Dice mi tío que es frustrante no saber qué pasa y yo me siento <br/>
                    igual o peor, pues no sabía que es tan difícil registrarse en un <br/>
                    juego. A mis amigos nunca les ha pasado esto. <br/><br/>
                    
                    En verdad necesito que me respondan. Por ello les escribo esta<br/>
                    carta, para saber qué puedo hacer, porque mi cumpleaños fue <br/>
                    hace tres semanas y me gustaría mucho usar mi regalo.<br/><br/>

                    Sin más por el momento, espero su amable respuesta vía <br/>
                    correo electrónico. <br/><br/>
                    
                    Gracias
                </p>
                <p className='Pos Remitente'>
                    <b>Atentamente </b><br/>
                    Javier Villagrana <br/>
                    667 12 445 <br/>
                    javi-11@ecorreo.com <br/>
                    Córdoba, Argentina
                </p>
                <img className="Imagen1 Escritorio" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion34/1.png"  alt="Tarjeta" />
                <img className="Imagen1 Tablet" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion34/1R.png"  alt="Tarjeta" />
                </Grid>
                <img className={classes.Imagen} src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion34/F.png"  alt="Tarjeta" />
        </Grid>
    </Grid>
    );
}
 
export default Tabla_de_actividades_extraescolares;
