import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
    ContenedorLectura:{
        width:'502px',
                  [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
    },
    ContainerTitulo:{
        margin:'0 0 46px 0',
        '& .Titulo':{
            letterSpacing: '0px',
            color: '#0093FF',
            margin:'0',
            textAlign:'center',
            font:'43px fredoka one',
            lineHeight:'52px',
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        position:'relative',
        '& .Lectura':{
            margin:'0 0 36px 0',
            color:'#000',
            font:'18px lato',
            lineHeight :'33px',
            letterSpacing: '0.17px',
            [theme.breakpoints.down(502)]:{
                font:'14px lato',
                lineHeight :'26px',
                letterSpacing: '0.09px',
            },
        },
        '& .SubTitulo':{
            color:'#FFFFFF',
            margin:'0',
            borderRadius:'50%',
            background:'#823CE2',
            display:'flex',
            justifyContent:'center',
            alignItems:'center',
            font:'bold 26px lato',
            lineHeight :'28px',
            letterSpacing: '0.24px',
            height:'39px',
            width:'39px',
            [theme.breakpoints.down(502)]:{
                font:'bold 22px lato',
                lineHeight :'24px',
                letterSpacing: '0.21px',
                height:'29px',
                width:'29px',
            },
        },
        '& .Imagen':{
            position:'absolute',
            top:'198px',
            left:'185px',
            [theme.breakpoints.down(502)]:{
                display:'none'
            },
        },
        '& .Imagen2':{
            position:'absolute',
            display:'none',
            [theme.breakpoints.down(502)]:{
                display:'block',
                top: '310px',
                left: '145px',
                width: '230px',
            },
        },  
        '& .Imagen3':{
            position:'absolute',
            display:'none',
            [theme.breakpoints.down(502)]:{
                display:'block',
                top:'500px',
                width:'250px',
                left:'150px',
            },
        }, 
        '& .Imagen4':{
            position:'absolute',
            display:'none',
            [theme.breakpoints.down(502)]:{
                display:'block',
                top:'1000px',
                width:'210px',
                left:'150px',
            },
        }, 
        '& .Imagen5':{
            position:'absolute',
            display:'none',
            [theme.breakpoints.down(502)]:{
                display:'block',
                top:'1220px',
                width:'265px',
                left:'120px',
            },
        },   

    },
}));

const Viaje_a_lo_invisible = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >
                        Viaje a lo invisible
                    </h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <img className="Imagen " src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img3ro/Sesion41/EspR.png" alt="Tarjeta" />
                    <img className="Imagen2 " src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img3ro/Sesion41/1.png" alt="Tarjeta" />
                    <img className="Imagen3 " src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img3ro/Sesion41/2.png" alt="Tarjeta" />
                    <img className="Imagen4 " src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img3ro/Sesion41/3.png" alt="Tarjeta" />
                    <img className="Imagen5 " src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img3ro/Sesion41/4.png" alt="Tarjeta" />

                   

                    <Grid container xs={12} >
                        <Grid xs={12}>
                        <p className="Lectura ">
                            <h3 className='SubTitulo'>I</h3><br/>
                        
                            El universo en tamaño<br/> 
                            tiene dos grandes alas: <br/>
                            a un lado enormes escalas <br/>
                            y a otro un mínimo peldaño. <br/>
                            Gigantes de nombres extraños <br/>
                            y pequeños invisibles <br/>
                            de colores increíbles <br/>
                            habitan nuestro planeta. <br/>
                            Radiantes como un cometa, <br/>
                            a simple vista imperceptibles.
                        </p>

                        </Grid>
                        <Grid xs={12}>
                        <p className="Lectura ">
                            <h3 className='SubTitulo'>II</h3><br/>
                        
                            La orca tan reina de los mares, <br/>
                            la gran escala desciende <br/>
                            cuando en el aire se extiende, <br/>
                            aleteando saltos estelares. <br/>
                            Catorce espectaculares metros <br/>
                            dibujan su silueta <br/>
                            y hasta a una casa <br/>
                            en tamaño sobrepasa. <br/>
                            Esta montaña marina, <br/>
                            que en el mundo predomina, <br/>
                            a cualquiera rebasa.
                        </p>
                        </Grid>
                        <Grid xs={12}>
                        <p className="Lectura ">
                            <h3 className='SubTitulo'>III</h3><br/>
                        
                            La hormiga, aunque no lo sabe, <br/>
                            es una puerta de entrada <br/>
                            a un mundo en que la mirada <br/>
                            necesita más de una llave. <br/>
                            En él, poco o nada cabe. <br/>
                            Es por eso que la hormiga <br/>
                            va, viene y va sin fatiga, <br/>
                            una y otra vez en secreto: <br/>
                            mensajera por decreto <br/>
                            del misterio de la miga.
                        </p>
                        </Grid>
                        <Grid xs={12}>
                        <p className="Lectura ">
                            <h3 className='SubTitulo'>IV</h3><br/>
                        
                            Microscopios poderosos <br/>
                            apuntan a las estrellas, <br/>
                            y a constelaciones bellas <br/>
                            de organismos silenciosos. <br/>
                            Científicos poderosos, <br/>
                            astrónomos del subsuelo,<br/>
                            desean con mucho celo <br/>
                            convertir en macroscópico <br/>
                            el espejo microscópico <br/>
                            de lo que habita en el cielo.
                        </p>
                        </Grid>
                    </Grid>

                </Grid>
        </Grid>
    </Grid>
    );
}
 
export default Viaje_a_lo_invisible;
