import React from 'react';
import { makeStyles, Grid } from "@material-ui/core";
import Radio                            from '@material-ui/core/Radio';

const useStyles = makeStyles(theme =>({
  // questionContainer:{
  //   width: "100%",
  //   height:"90%",
  // },
  // questionText: {
  //   backgroundColor: "pink",
  //   width: "100%",
  //   margin:"10px 0 10px 0 ",
  //   paddingLeft: "5px",
  //   font: '24px lato',
  //   letterSpacing: '0.21px',
  //   [theme.breakpoints.down(1400)]:{
  //     font: " 20px lato",
  //     width: "100%",
  //   },
  //   [theme.breakpoints.down(650)]:{
  //     font: "16px lato",
  //   },
  // },
  reading: {
    width: "100%",
    margin:"10px 0 10px 0 ",
    paddingLeft: "10px",
    font: '22px lato',
    letterSpacing: '0.21px',
    [theme.breakpoints.down(1400)]:{
      font: " 18px lato",
      width: "100%",
    },
    [theme.breakpoints.down(650)]:{
      font: "14px lato",
    },
  },
  selectedStyle:{
    // border: "#4BBCF8 5px solid",
    // border: "#2E313C26 3px solid",
    border: "#2E313C26 3px solid",
    backgroundColor: "#ffffff",
    width: "82%",
    borderRadius: "5px",
    margin:'auto',
    [theme.breakpoints.down(1310)]:{
      font: "500 16px lato",
      width: "80%",
      // margin:"10px 0 10px 0"
    }, 
    [theme.breakpoints.down(700)]:{
      font: "500 12px lato",
      width: "80%",
      // margin:"10px 0 10px 0"
    }, 
  },
  selectedCorrect: {
    border: "#4BBCF8 5px solid",
    backgroundColor: "#DDF6FA",
    width: "82%",
    borderRadius: "5px",
    margin:'auto',
    [theme.breakpoints.down(1310)]:{
      font: "500 16px lato",
      width: "80%",
      // margin:"10px 0 10px 0"
    }, 
    [theme.breakpoints.down(700)]:{
      font: "500 12px lato",
      width: "80%",
      // margin:"10px 0 10px 0"
    }, 
  }
}));

const Respuestas = (props) => {
  const classes = useStyles()
  const {index, answer, correct} = props


  return ( 
      <>
        {
          answer ? 
          <Grid
          // key={}
          className={correct ? classes.selectedCorrect : classes.selectedStyle}
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
        <Grid container item xs={1} lg={1} justifyContent="center" alignItems="center">
          <Radio
            disabled
            checked={true}
            color="primary"
            name={"answer"+ index}
            classes={{root: classes.radioIcon}}
            inputProps={{ 'aria-label': 'A' }}
          />
          </Grid>
          <Grid container item xs={11} lg={11}>
            <p 
              dangerouslySetInnerHTML={{__html: `${answer}`}}
              className={classes.reading}
            >
            </p>
          </Grid>
        </Grid>
        :
        <h3 style={{color: "green"}}>Texto libre</h3>
        }
      </>
    );
}
 
export default Respuestas;