import { Button, Grid, makeStyles } from "@material-ui/core";
import React, { useContext, useEffect } from "react";
import DrawerContext from "../../../../../usability/DrawerContext";
import { useOnLoadImages } from "../../../../hooks/useOnLoadImages";
import Loader from "../../../../Loader/Loader";
import Drawer from "../../ReadingTools/Drawer";

const useStyles =  makeStyles (theme =>({
      Contenedor:{
        background:'#E9FBF9',
        width:'100vw',
        overflowX:'clip',
        [theme.breakpoints.down(1161)]:{
            background:'none',
           overflowX:'unset',
        },
        [theme.breakpoints.down(600)]:{
            marginTop: '79.51px',
        }
    },
  ContainerInfoLectura: {
    position: "relative",
    height: "86px",
    [theme.breakpoints.down(502)]:{
        height: "43px",
    },
    background: "#76D4C3",
    "& .BarraInfoLectura": {
      height: "86px",
      background: `Url(https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Barras/Azul.png)`,
        [theme.breakpoints.down(502)]:{
            height: "43px",
        },
    },
    "& .InfoLectura": {
      position: "absolute",
      height: "86px",
      width: "219px",
      boxSizing: "border-box",
      padding: "17.2px 0",
      background: "#FAFAFA",
      borderRadius: "50px",
      boxShadow: "2px 2px 2px 2px #00000029",
      zIndex: "4",
        [theme.breakpoints.down(502)]:{
            height: "43px",
            width: "109px",
            padding: "8.6px 0",
            borderRadius: "25px",
        },
    },
    "& .InfoLectura:before": {
      position: "absolute",
      display: "flex",
      boxSizing: "border-box",
      padding: "0 0 0 10px",
      justifyContent: "center",
      alignItems: "center",
      height: "39.88px",
      width: "39.88px",
      borderRadius: "50%",
      content: '""',
      boxShadow: "3px 0px .5px 0px #00000029",
      background: "#FAFAFA",
      left: "90px",
      top: "65px",
      transform: "rotate(0.25turn)",
      [theme.breakpoints.down(502)]:{
        left: "45px",
        top: "30px",
        height: "20.88px",
        width: "20.88px",
        },
    },
    "& .InfoLectura:after": {
      content: '""',
      height: "15px",
      width: "15px",
      borderTop: "2px solid #20A698",
      borderLeft: "2px solid #20A698",
      position: "absolute",
      bottom: "-10px",
      left: "Calc(50% - 8px)",
      transform: "rotate(0.625turn)",
      [theme.breakpoints.down(502)]:{
        height: "10px",
        width: "10px",
        left: "Calc(50% - 4.5px)",
        bottom: "-3px",
        borderTop: "1px solid #20A698",
        borderLeft: "1px solid #20A698",
        },
    },
    "& .Sesion": {
      borderLeft: "2px solid #D8D8D8",
    },
    "& .Dato": {
      margin: "0 0 5px 0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#20A698",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        margin: "0 0 2.5px 0",
        },
    },
    "& .Caracter": {
      margin: "0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#000000",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        },
    },
       '& .Regresar':{
        color: '#F2624F',
        border: '#F2624F 1px solid',
        height: '30px',
        width:'100px',
        borderRadius: '20px',
        letterSpacing: '0.14px',
        textTransform: 'none',
        background:'#FEFAF1',
        top:'calc(50% - 15px)',
        left:'5%',
        zIndex:4,
        [theme.breakpoints.down(502)]: {
          font:'bold 10px lato',
          width: "60px",
        },
      },
  },
    ContenedorLectura:{
        position:'relative',
        background:'#fff',
        width:'1160px',
        height:'100%',
        padding:'0' ,
        
        transform: (props) =>
        props.scale > 1 ? `scale(${props.scale}) translate(0px, 10%)` : `none`,
        [theme.breakpoints.down(1161)]:{
            width:'768px',
        },
        [theme.breakpoints.down(768)]:{
            width:'350px',
        },

        '& .Derechos':{
            bottom:'80px',
            position:'absolute',
            textAlign:"center",
            margin:"0px",
            font:" 15px lato",
            color:"#717476",
            [theme.breakpoints.down(1161)]:{
                display:'none',
            }
        },
        '& .Escritorio':{
            display:'block',
            [theme.breakpoints.down(1161)]:{
                display:'none'
            },
        },
        '& .Tablet':{
            display:'none',
            [theme.breakpoints.down(1161)]:{
                display:'block'
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        position:'relative',
        [theme.breakpoints.down(768)]:{
            display:'none'
        },
        '& .Pos':{
            position:'absolute'
        },
        '& .Img1':{
            position:'relative',
            [theme.breakpoints.down(768)]:{
                width:'100%',
            },
        },
        '& .Titulo':{
            font:'77px fredoka one',
            color: '#525FE1',
            margin:'0',
            textAlign:'center',
            top:'68px',
            left:'56px',
            [theme.breakpoints.down(1161)]:{
                font:'55px fredoka one',
                left:'36px',
            },
            [theme.breakpoints.down(768)]:{
                font:'28px fredoka one',
                left:'16px',
                top:'28px',
            }
        },
        '& .SubTitulo':{
            font:'31px fredoka one',
            color: '#F67002',
            margin:'0',
            textAlign:'center',
            top:'150px',
            left:'56px',
            [theme.breakpoints.down(1161)]:{
                font:'22px fredoka one',
                top:'140px',
                left:'36px',
            },
            [theme.breakpoints.down(768)]:{
                font:'11px fredoka one',
                left:'16px',
                top:'65px',
            }
        },
        '& .SubTitulo2':{
            font:'bold 30px lato',
            letterSpacing: '0.28px',
            color: '#0A0A0A',
            margin:'0',
            textAlign:'center',
            top:'286px',
            left:'56px',
            [theme.breakpoints.down(1161)]:{
                font:'22px fredoka one',
                top:'195px',
                left:'36px',
            },
            [theme.breakpoints.down(768)]:{
                font:'11px fredoka one',
                top:'85px',
                left:'16px',
            }
        },

        '& .SubTitulo3':{
            font:'31px Fredoka One',
            letterSpacing: '.28px',
            color: '#FFFFFF',
            margin:'0',
            textAlign:'center',
            top:'538px',
            left:'48px',
            [theme.breakpoints.down(1161)]:{
                font:'22px fredoka one',
                top:'340px',
                left:'75px',
            },
            [theme.breakpoints.down(768)]:{
                font:'11px fredoka one',
                top:'155px',
                left:'35px',
            }
            
        },
        '& .Gomitas':{
            font:'22px lato',
            letterSpacing: '0px',
            color: '#575757',
            margin:'0',
            textAlign:'center',
            top:'334px',
            left:'56px',
            [theme.breakpoints.down(1161)]:{
                font:'16px lato',
                letterSpacing:'.15px',
                top:'225px',
                left:'36px',
            },
            [theme.breakpoints.down(768)]:{
                font:'8px lato',
                letterSpacing:'.15px',
                top:'105px',
                left:'16px',
            }
        },
        '& .Text':{
            font:'bold 22px lato',
            letterSpacing: '0px',
            color: '#FFFFFF',
            margin:'0',
            letterSpacing:'.21px',
            lineHeight:'30px',
            left:'118px',
            [theme.breakpoints.down(1161)]:{
                font:'bold 18px lato',
                letterSpacing:'.17px',
                lineHeight:'28px',
                left:'85px',
            },
            [theme.breakpoints.down(768)]:{
                font:'bold 8px lato',
                letterSpacing:'.09px',
                lineHeight:'11px',
                left:'45px',
            }
        },

        '& .Text1':{
            top:'672px',
            [theme.breakpoints.down(1161)]:{
                top:'450px',
            },
            [theme.breakpoints.down(768)]:{
                top:'205px',
            },
        },
        '& .Text2':{
            top:'730px',
            [theme.breakpoints.down(1161)]:{
                top:'487px',
            },
            [theme.breakpoints.down(768)]:{
                top:'222px',
            },
        },
        '& .Text3':{
            top:'780px',
            [theme.breakpoints.down(1161)]:{
                top:'524px',
            },
            [theme.breakpoints.down(768)]:{
                top:'242px',
            },
        },
        '& .Text4':{
            top:'851px',
            [theme.breakpoints.down(1161)]:{
                top:'618px',
            },
            [theme.breakpoints.down(768)]:{
                top:'282px',
            },
        },
        
        '& .Lectura':{
            lineHeight : '30px',
            margin:'0 0 36px 0 ',
            font:' 22px lato',
            color:'#000',
            letterSpacing: '0.20px',
            lineHeight:'30px',
            [theme.breakpoints.down(1161)]:{
                font:' 18px lato',
                letterSpacing: '0.17px',
                lineHeight:'28px',
            },
            [theme.breakpoints.down(768)]:{
                font:'9px lato',
                letterSpacing:'.09px',
                lineHeight:'11px',
            },
        },
        '& .SubTitulo4':{
            font:'bold 30px lato',
            letterSpacing: '.28px',
            color: '#0A0A0A',
            margin:'0',
            top:'1022px',
            left:'71px',
            [theme.breakpoints.down(1161)]:{
                top:'756px',
                left:'49px',
                font:'bold 22px lato',
                letterSpacing: '.21px',
            },
            [theme.breakpoints.down(768)]:{
                top:'345px',
                left:'5px',
                font:'bold 11px lato',
                letterSpacing: '.21px',
            },
        },
        '& .Esp':{
            margin:'0 0 5px 0',
            [theme.breakpoints.down(768)]:{
                margin:'0 0 0px 0',
            },
        },
        '& .Numero':{
            font:' 40px Fredoka One',
            listStyle:'alphanumeric',
            color: '#F67002',
            margin:'0',
            top:'1098px',
            left:'58.54px',
            [theme.breakpoints.down(1161)]:{
                width:'57%',
                font:' 27px Fredoka One',
                top:'800px',
                left:'35px',
            },
            [theme.breakpoints.down(768)]:{
                font:'15px Fredoka One',
                padding:'0',
                top:'365px',
                left:'17px',
            }
        },
        '& .Preguntas':{
            position:'absolute',
            width:'84.48%',
            top:'1448px',
            left:'56px',
            [theme.breakpoints.down(1161)]:{
                top:'1065px',
            },
            [theme.breakpoints.down(768)]:{
                top:'502px',
                left:'3px',
            },
        },

        '& .SubParrafo':{
            padding:'0 0 0 59px',
            [theme.breakpoints.down(1161)]:{
                padding:'0 0 0 47.54px',
            }
        },
        '& .SubTituloS':{
            margin:'0',
            font:'bold 26px lato',
            letterSpacing:'.26px',
            lineHeight:'30px',
            '&:first-letter':{
                color:'#525FE1'
            },
            [theme.breakpoints.down(1161)]:{
                font:'bold 20px lato',
                letterSpacing:'.19px',
                lineHeight:'28px',
            },
            [theme.breakpoints.down(768)]:{
                font:'bold 10px lato',
                letterSpacing:'.19px',
                lineHeight:'11px',
            }
        },
        '& .Versal':{
            margin:'0',
            font:'30px Fredoka One',
            letterSpacing:'.26px',
            lineHeight:'30px',
            '&:first-letter':{
                color:'#525FE1'
            },
            [theme.breakpoints.down(1161)]:{
                font:'20px Fredoka One',
                letterSpacing:'.19px',
                lineHeight:'28px',
            },
            [theme.breakpoints.down(768)]:{
                font:'11px Fredoka One',
                letterSpacing:'.19px',
                lineHeight:'11px',
            },
        },
        '& .Ul':{
            color:'#F67002',
            margin:'0',
            padding:'0 0 0 18px'
        },
        '& .Li':{
            lineHeight : '30px',
            margin:'0 0 8px 0',
            font:' 22px lato',
            color:'#000',
            letterSpacing: '0.20px',
            lineHeight:'30px',
            [theme.breakpoints.down(1161)]:{
                font:' 18px lato',
                letterSpacing: '0.17px',
                lineHeight:'28px',
                margin:'0 0 4px 0',
            },
            [theme.breakpoints.down(768)]:{
                font:' 9px lato',
                letterSpacing: '0.17px',
                lineHeight:'11px',
                margin:'0 0 2px 0',
            }
        },
        '& .SubTitulo5':{
            font:'bold 22px lato',
            margin:'0',
            color:'#F67002',
            [theme.breakpoints.down(768)]:{
                font:'bold 11px lato',
            }
        }
      
    },
    Imagen:{
        display:'none',
        [theme.breakpoints.down(1161)]:{
            display:'flex',
            width:'100%',
        },
    },

}));

const Prospecto = (props) => {

    const {activeStep, setActiveStep, handlePreviewStep, id_actividad} = props

    const handleContinue = () => {
      setActiveStep(activeStep - 1)
      handlePreviewStep()
    }
  
      const {
      handleSize,
      disabledZoom,
      closeZoom,
      handleZoom,
     scale
    } = useContext(DrawerContext);
    const [loading, setLoading] = React.useState(true);
    const wrapperRef = React.useRef(null);
    const imagesLoaded = useOnLoadImages(wrapperRef);
  
    const detectSize = () => {
      const lectura = document.getElementById("lectura");
      const newWidth = lectura?.offsetWidth;
      const newHeight = lectura?.offsetHeight;
      setLoading(false);
      if (lectura) handleSize(newHeight, newWidth);
    };
    useEffect(() => {
      window.addEventListener("resize", detectSize);
      return () => {
        handleZoom()
        window.removeEventListener("resize", detectSize);
        closeZoom()
      };
    }, []);
  
    const classes = useStyles({ disabledZoom,scale });

    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
             <Grid container item justifyContent="center" className={classes.ContainerInfoLectura}>
               <Grid container className="BarraInfoLectura">
                    <Button className="Regresar" onClick={handleContinue}>
                        Regresar
                    </Button>
                </Grid>
               <Grid container item className="InfoLectura">
                    <Grid container item direction="column" xs={6} justifyContent="center">
                        <p className="Dato">Grado</p>
                        <p className="Caracter">6</p>
                    </Grid>
                    <Grid container item direction="column" xs={6} justifyContent="center" className="Sesion">
                        <p className="Dato">Sesión</p>
                        <p className="Caracter">43</p>
                    </Grid>
               </Grid>
            </Grid>
{!imagesLoaded && <Loader />}
        <Grid
            container
            item
            className={classes.ContenedorLectura}
            justifyContent="center"
            id="lectura"
            onLoad={detectSize}
            ref={wrapperRef}
        >
<Drawer id_lectura={id_actividad} withoutTransform ={true} />
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <img className='Pos Img1 Escritorio' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion43/1.png"  alt="Tarjeta" />   
                    <img className='Pos Img1 Tablet' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion43/1R.png"  alt="Tarjeta" />   

                        <h3 className='Pos Titulo'>Prospecto:</h3>
                        <h2 className='Pos SubTitulo'>Información para el paciente</h2>
                        <h2 className='Pos SubTitulo2'><em>Gomitas vitaminadas</em> para niños</h2>
                        <p className='Pos Gomitas'>Frasco con 80 gomitas de 2 g cada una, sabores frutales.</p>
                        <h2 className='Pos SubTitulo3 Escritorio'>
                            Lea todo el prospecto detenidamente antes de empezar a tomar este <br/>
                            suplemento, porque contiene información importante para usted.
                        </h2>
                        <h2 className='Pos SubTitulo3 Tablet'>
                            Lea todo el prospecto detenidamente antes <br/>
                            de empezar a tomar este  suplemento,<br/>
                            porque contiene información importante para usted.
                        </h2>
                        <p className='Pos Text Text1'>Conserve este prospecto, ya que puede tener que volver a leerlo.</p>
                        <p className='Pos Text Text2'>Si tiene alguna duda, consulte a su médico.</p>
                        <p className='Pos Text Text3 Escritorio'>
                            Este suplemento multivitamínico se le ha recetado solamente al paciente o persona indicada. No <br/>
                            debe dárselo a otras personas, aunque tengan los mismos síntomas, ya que puede perjudicarles.
                        </p>
                        <p className='Pos Text Text3 Tablet'>
                            Este suplemento multivitamínico se le ha recetado solamente al paciente o  <br/>
                            persona indicada. No debe dárselo a otras personas, aunque tengan los <br/>
                            mismos síntomas, ya que puede perjudicarles.
                        </p>
                        <p className='Pos Text Text4 Escritorio'>
                            Si experimenta efectos adversos, consulte a su médico, incluso si se trata de efectos adversos que no <br/>
                            aparecen en este prospecto (ver sección 4).
                        </p>
                        <p className='Pos Text Text4 Tablet'>
                            Si experimenta efectos adversos, consulte a su médico, incluso si se trata de  <br/>
                            efectos adversos que no aparecen en este prospecto (ver sección 4).
                        </p>

                        <h3 className='Pos SubTitulo4'>Contenido del prospecto</h3>

                        <ul className='Pos Numero'>
                            <li><p className='Lectura Esp'>Qué son las Gomitas vitaminadas y para qué se utilizan</p></li>
                            <li><p className='Lectura Esp'>Qué necesita saber antes de empezar a tomar <em>Gomitas vitaminadas</em></p></li>
                            <li><p className='Lectura Esp'>Cómo tomar las <em>Gomitas vitaminadas</em></p></li>
                            <li><p className='Lectura Esp'>Posibles efectos adversos</p></li>
                            <li><p className='Lectura Esp'>Conservación de las <em>Gomitas vitaminadas</em></p></li>
                            <li><p className='Lectura Esp'>Contenido del envase e información adicional</p></li>
                        </ul>
                        <Grid className='Preguntas'>
                            <p className='Lectura '>
                                <h3 className='SubTituloS'><span className="Versal">1.</span> Qué son las Gomitas vitaminadas y para qué se utilizan</h3><br/> 
                                
                                Las <em>Gomitas vitaminadas</em> son un suplemento multivitamínico indicado para niños en edad escolar  
                                (6 a 12 años) para complementar su ingesta diaria de nutrientes. <br/><br/> 

                                Se utilizan en el tratamiento de:<br/> <br/> 

                                <ul className='Ul'>
                                    <li><p className='Li'>Retraso en el crecimiento físico y del desarrollo.</p></li>
                                    <li><p className='Li'>Alergias a determinados alimentos que impiden su ingesta, ya que permiten el aporte de sus 
                                    nutrientes esenciales.</p></li>
                                    <li><p className='Li'>Carencias nutrimentales por dietas restrictivas.</p></li>
                                </ul><br/>

                                <h3 className='SubTituloS'><span className="Versal">2.</span> Qué necesita saber antes de empezar a tomar <em>Gomitas vitaminadas</em></h3><br/>

                                <b>Los niños con una dieta equilibrada no requieren de suplementos multivitamínicos. Su uso debe <br/>
                                estar indicado por el médico pediatra. <br/><br/>

                                No tome o suministre <em>Gomitas vitaminadas</em> en los siguientes casos:</b> <br/><br/>

                                <ul className='Ul'>
                                    <li><p className='Li'>Si no tiene una prescripción por un médico pediatra.</p></li>
                                    <li><p className='Li'>Si el niño es sensible, intolerante o alérgico a los ingredientes</p></li>
                                </ul><br/>

                                <b> Advertencias y precauciones</b><br/><br/>

                                Consulte a su médico pediatra antes de empezar a suministrar este medicamento.<br/><br/>

                                <ul className='Ul'>
                                    <li><p className='Li'>No deje las <em>Gomitas vitaminadas</em> al alcance de los niños. No son caramelos.</p></li>
                                    <li><p className='Li'>No exceda la dosis diaria recomendada.</p></li>
                                </ul><br/>

                                <b> Uso de Gomitas vitaminadas con otros medicamentos</b><br/><br/>

                                <ul className='Ul'>
                                    <li><p className='Li'>Informe a su médico si el niño toma o ha tomado recientemente cualquier otro medicamento o 
                                    suplemento con vitaminas o minerales, incluso los adquiridos sin receta.</p></li>
                                    <li><p className='Li'>Tenga especial precaución si el niño ya toma medicamentos que contienen vitaminas y minerales, ya 
                                    que podría superar la dosis diaria recomendada.</p></li>
                                </ul><br/>

                                <b>Los suplementos multivitamínicos no están libres de riesgos. Las dosis elevadas de vitaminas y
                                minerales que superan la recomendación diaria, según la edad del niño, pueden ser tóxicas.</b><br/><br/>

                                <h3 className='SubTituloS'><span className="Versal">3.</span> Cómo tomar las <em>Gomitas vitaminadas</em></h3><br/>

                                <ul className='Ul'>
                                    <li><p className='Li'>Siga exactamente las instrucciones contenidas en el envase y respete la dosis diaria
                                    recomendada.</p></li>
                                    <li><p className='Li'>La forma de administración es vía oral.</p></li>
                                    <li><p className='Li'>La dosis recomendada es de 4 gomitas al día para niños a partir de los 6 años.</p></li>
                                    <li><p className='Li'>Mastíquelas por completo antes de ingerirlas.</p></li>
                                    <li><p className='Li'>Tómelas preferentemente después de la ingesta de alimentos.</p></li>
                                    <li><p className='Li'>La duración del tratamiento debe ser indicada por el médico pediatra.</p></li>
                                    <li><p className='Li'>No suministre una dosis doble para compensar las dosis olvidadas.</p></li>
                                </ul><br/>

                                <h3 className='SubTituloS'><span className="Versal">4.</span> Posibles efectos adversos</h3><br/>

                                Al igual que todos los medicamentos, este suplemento multivitamínico puede producir efectos 
                                adversos, aunque no todas las personas los sufran. <br/><br/>
                                
                                Suspenda su uso y consulte al médico pediatra en caso de efectos adversos como:<br/><br/>

                                <ul className='Ul'>
                                    <li><p className='Li'>Dificultad para deglutir o sensación de un cuerpo extraño.</p></li>
                                    <li><p className='Li'>Urticaria y dificultad para respirar.</p></li>
                                    <li><p className='Li'>Cara, labios, lengua o garganta hinchados.</p></li>
                                </ul><br/>

                                <h3 className='SubTituloS'><span className="Versal">5.</span> Conservación de las <em>Gomitas vitaminadas</em></h3><br/>

                                <ul className='Ul'>
                                    <li><p className='Li'>Una vez abierto, consúmase preferentemente dentro de los siguientes 30 días.</p></li>
                                    <li><p className='Li'>Puede llegar a observar decoloración de los pigmentos naturales usados en la fórmula. Esto no 
                                    supone un cambio en las propiedades nutrimentales del producto.</p></li>
                                    <li><p className='Li'>Manténgase en un lugar fresco y seco a no más de 30 grados.</p></li>
                                    <li><p className='Li'>Conserve el envase bien cerrado, lejos de la luz y de las fuentes de calor.</p></li>
                                    <li><p className='Li'>No requieren de refrigeración.</p></li>
                                </ul><br/>

                                <h3 className='SubTituloS'><span className="Versal">6.</span> Contenido del envase e información adicional</h3><br/>

                                <em>Gomitas vitaminadas</em> en presentación de frasco con 80 piezas de 2 g cada una. <br/><br/>
                                
                                <h2 className='SubTitulo5'>Ingredientes:</h2><br/>
                                
                                Grenetina, jarabe de glucosa, agua purificada, ácido cítrico, aceite de linaza (<em>Linum usitatissimum</em>), 
                                pectina, citrato de sodio, saborizante natural de limón, saborizante natural de cereza, saborizante 
                                natural de naranja, saborizante natural de piña, gluconato de zinc, aceite de oliva (<em>Olea europaea</em>),
                                aceite de semilla de calabaza (<em>Cucurbita máxima</em>), vitamina C (ácido retinoico), vitamina E (acetato 
                                de DL-Alfa Tocoferol), vitamina B3 (niacinamida), vitamina B5 (pantotenato de calcio), vitamina B6 
                                (piridoxina), vitamina A (acetato de retinilo), ácido fólico, vitamina D3 (colecalciferol), vitamina 
                                B12 (cianocobalamina). <br/><br/>
                                
                                Verifique la integridad del envase antes de suministrar. No usar si el sello debajo de la tapa está 
                                roto o no está presente cuando se abra por primera vez.
                            </p>

                        </Grid> 

                </Grid>
                <img className={classes.Imagen} src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion43/F.png"  alt="Tarjeta" />   

        </Grid>
        <Grid container item style={{ marginTop: 80}} ></Grid>
    </Grid>
    );
}
 
export default Prospecto;
