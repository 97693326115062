import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
    ContenedorLectura:{
        width:'502px',
                  [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
    },
    ContainerTitulo:{
        margin:'0 0 46px 0',
        '& .Titulo':{
            letterSpacing: '0px',
            color: '#FC5E9E',
            margin:'0',
            textAlign:'center',
            font:'43px fredoka one',
            lineHeight:'52px',
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },

        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            margin:'0 0 36px 0 ',
            color:'#000',
            font:'18px lato',
            lineHeight :'28px',
            letterSpacing: '0.17px',
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .Esp':{
            margin:'0',
        },
        '& .SubTitulo':{
            color:'#F4BF1E',
            margin:'0',
            lineHeight :'28px',
            font:'bold 26px lato',
            letterSpacing: '0.24px',
            [theme.breakpoints.down(502)]:{
                font:'bold 18px lato',
                lineHeight :'24px',
                letterSpacing: '0.21px',
            },
        },
        '& .TextImg':{
            margin:'22px 0 0 0',
            alignSelf:'flex-start',
            color:'#717476',
            font:' 16px lato',
            lineHeight :'25px',
            letterSpacing: '0.15px',
            [theme.breakpoints.down(502)]:{
                font:' 11px lato',
                lineHeight :'14px',
                letterSpacing: '0.10px',
            },
        },
        '& .punto':{
            color:'#F4BF1E',
            font:'10px',
            margin:'4px',
        },
        '& .ul':{
            margin:'0 0 26px 0',
            padding:'0 0 0 19px'
        },
        '& .Imagen':{
                width:'100%',
        },  
    },
    

}));

const El_valor_de_una_persona = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >
                        El valor de una persona
                    </h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                      <p className="Lectura">
                        <h3 className='SubTitulo'>¿Cuánto vales? </h3><br/>
                         ¿Alguna vez te has preguntado cuál es tu valor como persona? Intenta calcularlo a 
                         partir de algo que te guste o te importe mucho. Por ejemplo, si eligieras tus juguetes 
                         como referencia, ¿cuántos juguetes crees que vales?<br/><br/> 
                         
                         En realidad, el valor de una persona no puede calcularse con objetos ni con dinero. Es
                         incalculable, porque las personas somos invaluables. Eso quiere decir que valemos 
                         muchísimo. ¿Sabes por qué? Porque somos únicos.<br/><br/> 
                         
                         Los objetos y las cosas se repiten, pero las personas no. Nuestra manera de vivir, lo 
                         que aportamos, lo que creamos y lo que damos, todo eso es invaluable. Nadie más 
                         hará exactamente lo mismo que cada uno de nosotros. Por ello no hay comparación.
                      </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <Grid container justifyContent="center" alignItems='flex-end'>
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img2do/Sesion9/1.png" alt="Tarjeta" />
                        </Grid>
                        <Grid container  >
                            <p className="TextImg"> 
                            Aunque todos somos parte de una comunidad o identidad colectiva, cada uno de nosotros 
                            posee características que lo distinguen de los demás.
                            </p>
                            
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                      <p className="Lectura">
                        <h3 className='SubTitulo'>Autoestima </h3><br/>
                        A veces es difícil apreciar nuestro valor, como cuando nos sentimos tristes o las cosas 
                        no nos salen como esperábamos. En momentos así es bueno recordar el impacto que 
                        tenemos a nuestro alrededor.<br/><br/>  
                        
                        Si algún día te sientes poco valorado o decaído, te recomendamos un ejercicio de
                        autoestima. Como su nombre lo indica, la <em>autoestima</em> es quererse uno mismo,
                        <em>estimarse</em>. El ejercicio consiste en pensar en tus seres queridos e imaginar cómo sería
                        su vida sin ti: sin tus palabras, tus ocurrencias, tus creaciones, tus ideas, tus juegos…
                        ¿Verdad que no sería lo mismo? Recuerda: lo que tú les aportas es único.<br/> <br/> 

                        Otra manera de valorarte es pensar en las cosas que haces. Por ejemplo:
                      </p>
                      <ul  className="ul">
                          <li className="punto"><p className="Lectura Esp">Tus esfuerzos en la escuela para continuar estudiando.</p></li>
                          <li className="punto"><p className="Lectura Esp">Las actitudes que tienes a diario con las demás personas (cordialidad, amabilidad, cariño, etcétera).</p></li>
                          <li className="punto"><p className="Lectura Esp">Las labores que desempeñas (como cooperar en casa o ayudar en tu comunidad).</p></li>
                          <li className="punto"><p className="Lectura Esp">Tus habilidades (como escribir o practicar alguna disciplina artística).</p></li>
                      </ul>
                      <p className="Lectura">
                        Reconoce lo que vales. Eres importante por todo lo que ya eres y por todo lo que 
                        llegarás a ser.
                      </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <Grid container justifyContent="center" alignItems='flex-end'>
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img2do/Sesion9/2.png"alt="Tarjeta" />
                        </Grid>
                        <Grid container item justifyContent='center' >
                            <p className="TextImg"> 
                            El amor propio es el primer paso para amar el entorno.
                            </p>
                            
                        </Grid>
                    </Grid>
                </Grid>
        </Grid>
    </Grid>
    );
}
 
export default El_valor_de_una_persona;
