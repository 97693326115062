import { makeStyles } from "@material-ui/core";
import React from "react";

import ToolBar from "./ToolBar";
const useStyles = makeStyles({
  container: {
    position: "relative",
  },
  containerDrawer: {
    position: "absolute",
    // top: "86px",
    top: "80px",
    // minHeight: "100vh",
    lefT: "3%",
    minHeight: "1000px",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "calc(10px + 2vmin)",
    color: "white",
  },
});

const ReadingTools = ({ toActivity, zoomReset }) => {
  const classes = useStyles({});
  return (
    <div className={classes.container}>
      <ToolBar toActivity={toActivity} zoomReset={zoomReset} />
    </div>
  );
};

export default ReadingTools;
