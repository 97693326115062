import { Button, Grid, makeStyles } from "@material-ui/core";
import React, { useContext, useEffect } from "react";
import DrawerContext from "../../../../../usability/DrawerContext";
import { useOnLoadImages } from "../../../../hooks/useOnLoadImages";
import Loader from "../../../../Loader/Loader";
import Drawer from "../../ReadingTools/Drawer";

const useStyles =  makeStyles (theme =>({
     Contenedor:{
        background:'#E9FBF9',
        width:'100vw',
        overflowX:'clip',
        [theme.breakpoints.down(1161)]:{
            background:'none',
           overflowX:'unset',
        },
        [theme.breakpoints.down(600)]:{
            marginTop: '79.51px',
        }
    },
  ContainerInfoLectura: {
    position: "relative",
    height: "86px",
    [theme.breakpoints.down(502)]:{
        height: "43px",
    },
    background: "#76D4C3",
    "& .BarraInfoLectura": {
      height: "86px",
      background: `Url(https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Barras/Azul.png)`,
        [theme.breakpoints.down(502)]:{
            height: "43px",
        },
    },
    "& .InfoLectura": {
      position: "absolute",
      height: "86px",
      width: "219px",
      boxSizing: "border-box",
      padding: "17.2px 0",
      background: "#FAFAFA",
      borderRadius: "50px",
      boxShadow: "2px 2px 2px 2px #00000029",
      zIndex: "4",
        [theme.breakpoints.down(502)]:{
            height: "43px",
            width: "109px",
            padding: "8.6px 0",
            borderRadius: "25px",
        },
    },
    "& .InfoLectura:before": {
      position: "absolute",
      display: "flex",
      boxSizing: "border-box",
      padding: "0 0 0 10px",
      justifyContent: "center",
      alignItems: "center",
      height: "39.88px",
      width: "39.88px",
      borderRadius: "50%",
      content: '""',
      boxShadow: "3px 0px .5px 0px #00000029",
      background: "#FAFAFA",
      left: "90px",
      top: "65px",
      transform: "rotate(0.25turn)",
      [theme.breakpoints.down(502)]:{
        left: "45px",
        top: "30px",
        height: "20.88px",
        width: "20.88px",
        },
    },
    "& .InfoLectura:after": {
      content: '""',
      height: "15px",
      width: "15px",
      borderTop: "2px solid #20A698",
      borderLeft: "2px solid #20A698",
      position: "absolute",
      bottom: "-10px",
      left: "Calc(50% - 8px)",
      transform: "rotate(0.625turn)",
      [theme.breakpoints.down(502)]:{
        height: "10px",
        width: "10px",
        left: "Calc(50% - 4.5px)",
        bottom: "-3px",
        borderTop: "1px solid #20A698",
        borderLeft: "1px solid #20A698",
        },
    },
    "& .Sesion": {
      borderLeft: "2px solid #D8D8D8",
    },
    "& .Dato": {
      margin: "0 0 5px 0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#20A698",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        margin: "0 0 2.5px 0",
        },
    },
    "& .Caracter": {
      margin: "0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#000000",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        },
    },
       '& .Regresar':{
        color: '#F2624F',
        border: '#F2624F 1px solid',
        height: '30px',
        width:'100px',
        borderRadius: '20px',
        letterSpacing: '0.14px',
        textTransform: 'none',
        background:'#FEFAF1',
        top:'calc(50% - 15px)',
        left:'5%',
        zIndex:4,
        [theme.breakpoints.down(502)]: {
          font:'bold 10px lato',
          width: "60px",
        },
      },
  },
    ContenedorLectura:{
        background:'#fff',
        width:'1160px',
        height:'100%',
        padding:'60px 168px 35px ',
        
        transform: (props) =>
        props.scale > 1 ? `scale(${props.scale}) translate(0px, 10%)` : `none`,
        [theme.breakpoints.down(1161)]:{
            width:'502px',
            padding:'60px 0 0 0',
          },
                [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },

        '& .Escritorio':{
            display:'block',
            [theme.breakpoints.down(1161)]:{
                display:'none'
            },
        },
        '& .Tablet':{
            display:'none',
            [theme.breakpoints.down(1161)]:{
                display:'block'
            },
        }
    },
    ContainerTitulo:{
        margin:'0 0 34px 0',
        [theme.breakpoints.down(1161)]:{
            margin:'0 0 34px 0',
        },
        '& .Titulo':{
            font:'48px fredoka one',
            letterSpacing: '0px',
            lineHeight:'58px',
            color: '#FF2B2B',
            margin:'0 0 5px 0',
            textAlign:'center',
            [theme.breakpoints.down(1161)]:{
                font:'43px fredoka one',
                lineHeight:'52px',
            },
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
        '& .Titulo2':{
            font:'bold 30px lato',
            letterSpacing: '.28px',
            lineHeight:'30px',
            color: '#FF2B2B',
            margin:'0',
            textAlign:'center',
            [theme.breakpoints.down(1161)]:{
                font:'bold 26px lato',
                letterSpacing: '.24px',
                lineHeight:'28px',
            },
            [theme.breakpoints.down(502)]:{
                font:'bold 22px lato',
                lineHeight:'24px',
            },
            [theme.breakpoints.down(350)]:{
                font:'bold 18px lato',
                lineHeight:'24px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        position:'relative',
        '& .Lectura':{
            lineHeight : '30px',
            margin:'0 0 36px 0',
            font:'22px lato',
            color:'#000',
            letterSpacing: '0.20px',
            [theme.breakpoints.down(1161)]:{
                font:'18px lato',
                lineHeight :'28px',
                letterSpacing: '0.17px',
            },
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .Lectura2':{
            margin:'0 0 11px 0',
        },
        '& .Titulo3':{
            font:'bold 30px lato',
            letterSpacing: '.28px',
            lineHeight:'30px',
            color: '#00ADB6',
            margin:'0 0 47px 0',
            textAlign:'center',
            [theme.breakpoints.down(1161)]:{
                font:'bold 26px lato',
                letterSpacing: '.24px',
                lineHeight:'28px',
            },
            [theme.breakpoints.down(502)]:{
                font:'bold 22px lato',
                lineHeight:'24px',
            },
            [theme.breakpoints.down(350)]:{
                font:'bold 18px lato',
                lineHeight:'24px',
            },
        },
        '& .Imagen':{
            [theme.breakpoints.down(1161)]:{
                width:'768px',
            },
            [theme.breakpoints.down(768)]:{
                width:'100%',
            },
        },  
      
    }
}));

const Pablo_y_el_intraterrestre_Cap_2y3 = (props) => {

    const {activeStep, setActiveStep, handlePreviewStep, id_actividad} = props
  
    const handleContinue = () => {
      setActiveStep(activeStep - 1)
      handlePreviewStep()
    }
    
    const {
      handleSize,
      disabledZoom,
      closeZoom,
      handleZoom,
     scale
    } = useContext(DrawerContext);
    const [loading, setLoading] = React.useState(true);
    const wrapperRef = React.useRef(null);
    const imagesLoaded = useOnLoadImages(wrapperRef);
  
    const detectSize = () => {
      const lectura = document.getElementById("lectura");
      const newWidth = lectura?.offsetWidth;
      const newHeight = lectura?.offsetHeight;
      setLoading(false);
      if (lectura) handleSize(newHeight, newWidth);
    };
    useEffect(() => {
      window.addEventListener("resize", detectSize);
      return () => {
        handleZoom()
        window.removeEventListener("resize", detectSize);
        closeZoom()
      };
    }, []);
  
    const classes = useStyles({ disabledZoom,scale });
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
             <Grid container item justifyContent="center" className={classes.ContainerInfoLectura}>
                       <Grid container className="BarraInfoLectura">
            <Button className="Regresar" onClick={handleContinue}>
                Regresar
            </Button>
        </Grid>
               <Grid container item className="InfoLectura">
                    <Grid container item direction="column" xs={6} justifyContent="center">
                        <p className="Dato">Grado</p>
                        <p className="Caracter">5</p>
                    </Grid>
                    <Grid container item direction="column" xs={6} justifyContent="center" className="Sesion">
                        <p className="Dato">Sesión</p>
                        <p className="Caracter">25</p>
                    </Grid>
               </Grid>
            </Grid>
            {!imagesLoaded && <Loader />}
        <Grid
            container
            item
            className={classes.ContenedorLectura}
            justifyContent="center"
            id="lectura"
            onLoad={detectSize}
            ref={wrapperRef}
        >
<Drawer id_lectura={id_actividad} />
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                        <h2 className="Titulo">Pablo y el intraterrestre</h2>
                        <h3 className='Titulo2'>Fragmento 2 (capítulos 2-3)</h3>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                       <p className='Lectura Lectura2'>
                        A oscuras, solo en su cuarto, Pablo no dejaba de preguntarse qué le estaba pasando. 
                        Su cara parecía hervir. Los puños se cerraban con fuerza. La mandíbula rechinaba y 
                        los dientes le dolían. Por las mejillas le chorreaban sin control lágrimas de coraje. No 
                        podía destrabarse. “¿Me estaré transformando en un hombre lobo?”, se preguntó 
                        Pablo. Nunca había oído que esa fuera una forma de transformarse. “¿Y qué era esa 
                        bola de oscuridad?” <br/><br/>
                        
                        Pablo sintió un dolor extraño en el estómago, como si alguien le dejara caer una 
                        piedra adentro, pero en lugar de caer el golpe subió hasta la boca de su estómago y 
                        se le atoró allí. Trató de pedir ayuda nuevamente, pero cayó profundamente dormido 
                        al instante, aunque en realidad no tenía nada de sueño. Era como si algo o alguien 
                        hubiera apagado un interruptor dentro de él.
                       </p>                   
                    </Grid>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12} justifyContent='center'>
                       <h3 className='Titulo3'><b>Capítulo3</b><br/> Mientras tanto, dentro de Pablo</h3>                
                    </Grid>
                    <Grid container sm={12}>
                       <p className='Lectura'>
                        Cuando un ovni se estaciona en la tierra, aterriza. Cuando un cohete se estaciona en 
                        la luna, aluniza. Cuando Brimlog, el intraterrestre, se estacionó en las tripas de Pablo, 
                        entripó. <br/><br/>
                        
                        Tras el entripaje exitoso, Brimlog se fijó muy bien en lo que tenía a su alrededor para 
                        recordar dónde dejaba su nave. “A la izquierda del apéndice, antes del intestino 
                        grueso, donde hay un pedazo de queso amarillo sin digerir”, se dijo a sí mismo […] <br/><br/>
                        
                        Con un disparo, lanzó un arponazo hacia arriba. Aquél fue el momento en el que 
                        Pablo sintió un fuerte golpe en la boca del estómago y se quejó. Su lamento resonó 
                        por toda la cavidad torácica, como un eco dentro de una ballena. Así que Brimlog 
                        decidió que sería mejor dormir al humano pequeño mientras establecía su 
                        campamento. Soltó una granada de gas noqueador que dejó a Pablo inconsciente en 
                        menos de un minuto. <br/><br/>
                        
                        Con Pablo inconsciente, Brimlog instaló tranquilamente la Máquina Comeenojos en 
                        su estómago. Ese era el mejor lugar para encontrar la ira en los seres humanos. 
                        Instaló los mecanismos de la Bomba de Ira en el corazón, verificó que el suplemento 
                        de rabia llegara bien y que la bomba quedara atada a las emociones del humanito.<br/><br/>

                        Por último, regresó al estómago. Tras hacer un sonidito ronco, como de un gato viejo 
                        escupiendo una bola de pelos, la Máquina Comeenojos empezó a funcionar, 
                        transformando todo el enojo de Pablo en un río pesado, viscoso, semejante al magma 
                        de un volcán. Aquella sustancia tenía un brillante tono anaranjado y gracias a ella 
                        Brimlog pudo poner una tabla de flotación (parecida a una tabla de surf) y subir de la 
                        panza a la garganta, hasta el interior de la cabeza […] <br/><br/>
                        
                        Era hora de instalar la Consola de Mando. Mientras más se enojara el humano 
                        pequeño, mejor podría Brimlog usar esa consola para dominar sus acciones. “Pero 
                        ¿dónde ponerla?”, se cuestionó. Le pareció que el entrecejo era un lugar idóneo. 
                        Había notado que muchos humanos fruncían el ceño al enojarse y sospechaba que 
                        había un punto de ira cerca de ahí. <br/><br/>
                        
                        Instaló la consola con todas sus partes. ¿Visores para ocelos y asiento biselar? Listo. 
                        ¿Asiento del tórax? Hecho. ¿Recargaderas para tentáculos superiores? Puestas. 
                        ¿Pedales para tentáculos inferiores? Colocados. ¿Extensiones de pinzas bucales? 
                        Activadas. ¿Asiento de la cola…? Después del meneo y de unas cuantas sacudidas 
                        retráctiles, quedó instalado y en su lugar […] <br/><br/>
                        
                        Con cada gota que se filtraba, la luz en la base de Brimlog parpadeaba levemente, 
                        como si perdiera energía. Ese líquido misterioso interfería con sus sistemas. 
                        Preocupado, Brimlog consultó su manual de anatomía humana. La glándula se 
                        llamaba lagrimal y el líquido que emanaba eran lágrimas, hechas para mantener 
                        húmedos los ojos humanos. Brimlog revisó: no había ninguna conexión anatómica 
                        entre tripas y ojos, así que esa fuga no podía ser consecuencia del entripaje.<br/><br/>
                        
                        Al final de la página encontró una nota que advertía que los seres humanos 
                        limpiaban emociones (como el enojo, la tristeza o hasta la alegría) con esas lágrimas. 
                        Eso sonaba peligroso. Brimlog no podía quedarse quieto mientras perdía la energía 
                        que ya había cosechado. Decidió regresar a la Consola de Mando, donde se sentó y 
                        preparó su micrófono para emitir su primer mensaje: <br/><br/>
                        
                        —Hey, tú… Crío humano… —tardó un poco en recordar el nombre del humano que 
                        tanto habían gritado otros en el transporte familiar—. Humano Pablo, tienes una fuga 
                        de líquido ocular. Será mejor que la detengas antes de que se resequen tus ojos.
                       </p>                   
                    </Grid>
                    <Grid container justifyContent='center'>
                        <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion25/1.png" alt="Tarjeta" />
                    </Grid>
                </Grid>
        </Grid>
        <Grid container item style={{ marginTop: 80}} ></Grid>
    </Grid>
    );
}
 
export default Pablo_y_el_intraterrestre_Cap_2y3;
