import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
    ContenedorLectura:{
        width:'502px',
                  [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
    },
    ContainerTitulo:{
        margin:'0 0 34px 0',
        '& .Titulo':{
            letterSpacing: '0px',
            color: '#7940DA',
            margin:'0',
            textAlign:'center',
            font:'43px fredoka one',
            lineHeight:'52px',
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        position:'relative',
        '& .Lectura':{
            margin:'0 0 36px 0',
            color:'#000',
            font:'18px lato',
            lineHeight :'28px',
            letterSpacing: '0.17px',
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .Esp':{
            margin:'0',
        },
        "& .SubTitulo": {
            color: "#FF1400",
            margin: "0",
            lineHeight: "28px",
            font: "bold 26px lato",
            letterSpacing: "0.24px",
            [theme.breakpoints.down(502)]: {
              font: "bold 22px lato",
              lineHeight: "24px",
              letterSpacing: "0.21px",
            },
        },
        "& .SubTitulo2": {
            color: "#7940DA",
            margin:'0 0 33px 0',
            font: "22px Fredoka One",
            lineHeight: "26px",
            [theme.breakpoints.down(502)]: {
              font: "18px Fredoka One",
              lineHeight: "22px",
                margin:'0 0 6px 0',
            },
          },
        '& .TextImg':{
            color:'#717476',
            margin:'20px 0 0 0',
            font:' 16px lato',
            lineHeight :'25px',
            letterSpacing: '0.15px',
            [theme.breakpoints.down(502)]:{
                font:' 11px lato',
                lineHeight :'14px',
                letterSpacing: '0.10px',
            },
        },
        '& .Cuadro':{
            position:'absolute',
            margin:'0px!important',
            top:'31px',
            width:'81.07%',
            [theme.breakpoints.down(502)]:{
                top:'15px',
            }
        },

        '& .Imagen':{
            width:'100%',
        }, 
        '& .Imagen2':{
            [theme.breakpoints.down(502)]:{
                width:'100%',
            },
        }, 

    }
}));

const El_cine_animado = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >
                        El cine animado                  
                    </h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                      <p className="Lectura">
                        <h3 className='SubTitulo'>El origen del cine</h3><br/>

                        El cine es una de las bellas artes. De hecho, se le conoce popularmente como el 
                        <em> séptimo arte</em>, debido a que surgió después del resto. <br/><br/>
                        
                        Se dice que Francia es la cuna del cine porque los hermanos Lumière, dos artistas de 
                        la fotografía, se inspiraron en inventos anteriores para crear el <em>cinematógrafo</em>: la 
                        primera versión de las cámaras de cine, con la que se podía filmar y proyectar 
                        películas. Su primera proyección se realizó el 28 de diciembre de 1895, en París.

                      </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <Grid container justifyContent="center" >
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img2do/Sesion56/1.png" alt="Tarjeta" />
                        </Grid>
                        <Grid container justifyContent="center" >
                            <p className="TextImg">
                                Ésta es la primera cámara de los hermanos Lumière.                          
                            </p>
                        </Grid>
                    </Grid>
                </Grid>
                
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                            <h3 className='SubTitulo'>¿Cómo funciona el cine animado?</h3><br/>
                        
                            Durante el siglo <span style={{fontVariant: "all-small-caps"}}>XX</span>, la cámara sirvió para documentar parte de la 
                            historia de la humanidad. <br/><br/>
                            
                            En la actualidad, el cine se divide en <em>géneros</em> (es decir, clasificación basada en 
                            aspectos formales, como estilo, ritmo y tono, por la ambientación, por el tema que 
                            tocan o por su formato), tales como el terror, la ciencia ficción, la fantasía, los 
                            superhéroes y la animación. ¿Has visto películas animadas? ¿Sabes cómo se crean? <br/><br/>
                            
                            Una película se construye mediante imágenes (o fotografías) colocadas en serie para 
                            dar la idea de movimiento. Eso es una <em>animación</em>, aunque la palabra se utiliza
                            mayormente para referirse a la ilustración y el dibujo. Las películas animadas se 
                            crean utilizando técnicas digitales para ordenar y colorear miles de dibujos trazados 
                            a mano, formar secuencias, y ponerles voz y música.                
                        </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <Grid container justifyContent="center" >
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img2do/Sesion56/2.png" alt="Tarjeta" />
                        </Grid>
                        <Grid container justifyContent="center" >
                            <p className="TextImg">
                                Aunque en la actualidad el cine animado cuenta con grandes avances tecnológicos, el origen de todo es la creatividad humana.                        
                            </p>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura Esp">
                            Además de Disney, existen diversas compañías especializadas en cine animado. A continuación, te mencionamos algunas:           
                        </p>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container item justifyContent='center' sm={12}>
                        <img className="Imagen2 " src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img2do/Sesion56/4.png" alt="Tarjeta" />
                
                        <p className="Lectura Cuadro">
                            <h3 className='SubTitulo2'>Pixar </h3>
                    
                            Esta compañía revolucionó la industria con el uso de la animación digital. Su primera película fue <em>Toy Story</em>, en 1995.
                        </p>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container item justifyContent='center' sm={12}>
                        <img className="Imagen2 " src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img2do/Sesion56/5.png" alt="Tarjeta" />
                
                        <p className="Lectura Cuadro">
                            <h3 className='SubTitulo2'>Studio Ghibli</h3>
                    
                            Es una productora japonesa responsable de grandes clásicos, como <em>El viaje 
                            de Chihiro</em> (2001) y <em>El increíble castillo vagabundo</em> (2004). Se creó en 1985 y 
                            es uno de los grandes estudios que todavía usan la animación tradicional.                        
                        </p>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container item justifyContent='center' sm={12}>
                        <img className="Imagen2 " src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img2do/Sesion56/6.png" alt="Tarjeta" />
                
                        <p className="Lectura Cuadro">
                            <h3 className='SubTitulo2'>Dreamworks</h3>
                    
                            Este estudio ha obtenido fama y prestigio gracias a películas como <em>Shrek</em> (2001),
                            <em> Kung Fu Panda</em> (2008) y <em>Cómo entrenar a tu dragón</em> (2010).
                        </p>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container item justifyContent='center' sm={12}>
                        <img className="Imagen2 " src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img2do/Sesion56/3.png" alt="Tarjeta" />
                
                        <p className="Lectura Cuadro">
                            <h3 className='SubTitulo2'>Laika</h3>
                    
                            Es un estudio de animación <em>stop motion</em>: crea películas cuadro por cuadro, a 
                            partir de las imágenes que obtiene de mover y filmar objetos poco a poco. 
                            Cuenta con películas como <em>Coraline y la puerta secreta</em> (2009), <em>El alucinante 
                            mundo de Norman</em> (2012) y <em>Kubo y la búsqueda del samurái </em>(2016).
                        </p>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container item justifyContent='center' sm={12}>
                        <p className="Lectura ">
                        ¿Conoces alguna de estas películas? ¿Cuál verás próximamente?
                        </p>
                    </Grid>
                </Grid>
        </Grid>
    </Grid>
    );
}
 
export default El_cine_animado ;
