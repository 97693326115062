import React from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core";

import NavBar from "../../../NavBar";
import MenuIzquierdo from "../../../MenuIzquierdo";
import ListChat from "./List_Chat";

// import SettingsTeacher from "./SettingsTeacher";

const useStyles = makeStyles((theme) => ({
  grid1: {
    zIndex: "3",
  },
  grid2: {
    zIndex: "2",
  },
  grid3: {
    boxSizing: "border-box",
    zIndex: "1",
    paddingLeft: "3%",
    [theme.breakpoints.down("md")]: {
      paddingLeft: "0px",
    },
  },
}));

function ListChatContainer() {
  const classes = useStyles();

  return (
    <Grid container>
      <Grid item className={classes.grid1} xs={12}>
        <NavBar />
      </Grid>
      <Grid item className={classes.grid2} xs={1}>
        <MenuIzquierdo />
      </Grid>
      <Grid item className={classes.grid3} xs={12}>
        <ListChat />
      </Grid>
    </Grid>
  );
}

export default ListChatContainer;
