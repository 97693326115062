import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
    ContenedorLectura:{
        width:'502px',
                  [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
    },
    ContainerTitulo:{
        margin:'0 0 34px 0',
        '& .Titulo':{
            letterSpacing: '0px',
            color: '#A0278F',
            margin:'0',
            textAlign:'center',
            font:'43px fredoka one',
            lineHeight:'52px',
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            margin:'0 0 36px 0',
            color:'#000',
            font:'18px lato',
            lineHeight :'28px',
            letterSpacing: '0.17px',
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },

        '& .Imagen':{
            width:'100%',
        },  

      
    }
}));

const Derecho_a_la_salud = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >
                        Derecho a la salud
                    </h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container xs={12}>
                      <p className="Lectura ">
                        ¿Sabes qué es el derecho a la salud? A 
                        continuación, conocerás la historia de 
                        Yolanda, una niña que luchó por 
                        conseguirlo para su familia y que
                        terminó beneficiando a toda su 
                        comunidad.<br/><br/> 
                        
                        Yolanda era la hija mayor de Saúl y 
                        Regina. Era una chica inteligente, 
                        hábil y decidida. Además, le 
                        encantaba ayudar a las personas que 
                        lo necesitaran. Un día regresaba de la 
                        escuela cuando encontró a su mamá 
                        cantando mientras miraba por una 
                        ventana de la casa. “Algo bueno 
                        ocurre… jamás canta”, pensó 
                        inmediatamente. Al saludarla, su 
                        madre le contó con felicidad que 
                        estaba embarazada nuevamente. Se 
                        abrazaron y durante los días 
                        siguientes todo fue felicidad.
                      </p>
                    </Grid>
                    <Grid container justifyContent="center" xs={12}>
                        <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img3ro/Sesion51/1.png" alt="Tarjeta" />
                    </Grid>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                      <p className="Lectura">
                        Pero pasaron dos meses y Regina empezó a sentirse mal.<br/><br/> 
                      
                        —Algo está mal con mi bebé —la escuchó decir Yolanda mientras ella conversaba con
                         Saúl.<br/><br/> 

                        Durante la merienda, Yolanda le sugirió ir a la clínica de la comunidad. Ella respondió 
                        que hacía ya un par de meses que el último doctor se había ido del pueblo y que no 
                        había nadie en su lugar. Yolanda se fue a dormir preocupada por su mamá y su futura 
                        hermana, pues ella tenía la seguridad de que sería una niña. <br/><br/> 
                        
                        Pensó en algo que aprendió en clase mientras daba vueltas en la cama: “Todos los 
                        niños, adolescentes y adultos tienen derecho a vivir en un entorno saludable y con 
                        mucho chocolate”. Eso había dicho la maestra, sí. “Bueno, quizás estoy inventando la 
                        parte del chocolate”, se dijo a sí misma mientras reía. Luego recordó otra cosa más: 
                        “Las mujeres tienen derecho a recibir la atención médica necesaria mientras están 
                        embarazadas o lactando”. Entonces, tuvo una idea. <br/><br/> 
                        
                        A la mañana siguiente, le dijo a Regina durante el desayuno: <br/><br/> 
                        
                        —Es tu derecho, mamá. Estarás bien. No te preocupes. Sólo tenemos que hacer valer 
                        el derecho a la salud que te garantiza la ley. <br/><br/> 
                        
                        Redactó una carta sobre la situación al llegar a la escuela, con toda la sabiduría que le 
                        daban sus nueve años de vida. Pidió a su maestra Martha que la revisara y le hiciera 
                        las correcciones necesarias al terminar.
                      </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img3ro/Sesion51/2.png" alt="Tarjeta" />
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                      <p className="Lectura">
                        La profesora corrigió la carta conmovida por la intención de la niña. Solicitó a la 
                        directora de la escuela hacer una asamblea con los padres de familia. Su intención 
                        era que ellos la firmaran y que después la llevaran a una dependencia del gobierno 
                        para exigir un médico que atendiera las necesidades de Regina y del pueblo.<br/><br/> 

                        Así lo hizo y un par de semanas más tarde llegó un doctor a la clínica, quien se 
                        encargó de asistir a Regina durante su embarazo y ayudó a nacer a Érika, una 
                        pequeña tan brillante como su hermana.
                      </p>
                    </Grid>
                </Grid>
        </Grid>
    </Grid>
    );
}
 
export default Derecho_a_la_salud ;
