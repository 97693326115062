import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
    ContenedorLectura:{
        width:'502px',
                  [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
    },
    ContainerTitulo:{
        margin:'0 0 46px 0',
        '& .Titulo':{
            letterSpacing: '0px',
            color: '#F6664D',
            margin:'0',
            textAlign:'center',
            font:'43px fredoka one',
            lineHeight:'52px',
            [theme.breakpoints.down(502)]:{
                font:'27px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            margin:'0 0 36px 0 ',
            color:'#000',
            font:'18px lato',
            lineHeight :'28px',
            letterSpacing: '0.17px',
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .Imagen':{
            width:'100%',
        },


    },
    

}));

const Tecnología_digital = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>

            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >
                        Tecnología digital:<br/>  
                        una época que los<br/> 
                        escritores de ciencia<br /> 
                        ficción imaginaron hace<br/>
                        más de un siglo
                    </h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                            ¿A qué te quieres dedicar cuando seas grande? ¿Cómo imaginas que será tu vida en el 
                            futuro? Probablemente tus papás pensaban en su infancia que cuando crecieran 
                            habría robots asistentes y autos voladores, o que existiría la teletransportación. 
                            Actualmente no hay tales objetos, pero existen automóviles eléctricos e híbridos, 
                            podemos hacer una videollamada al momento y usamos aparatos electrónicos 
                            inteligentes. ¿Te has preguntado por qué el ser humano inventa tantas cosas?<br/><br/> 
                            
                            La tecnología tiene el propósito de hacer nuestra vida más fácil. Por ejemplo, en la 
                            Antigüedad los seres humanos imaginaban volar y en 1903 los hermanos Wilbur y 
                            Orville Wright crearon su propia aeronave. El transporte evolucionó y ahora 
                            contamos con aviones para viajar más rápido. <br/><br/> 
                            
                            ¿Sabes cómo se comunicaban las personas cuando no existían los teléfonos 
                            celulares? Se enviaban cartas, pero a veces tardaban semanas o meses en llegar si el 
                            destinatario vivía lejos. Alexander Graham Bell inventó el teléfono en 1876 y éste 
                            también fue mejorando con el paso del tiempo. <br/><br/> 
                            
                            La sociedad buscaba mantenerse comunicada y en 1969 surgió el internet. Pero 
                            pasaron muchos años para que la gente pudiera mandar un mensaje desde un correo 
                            electrónico. ¿Tú utilizas el correo electrónico para comunicarte con tus seres 
                            queridos? ¿Qué otros medios usas?
                        </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <Grid container >
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img3ro/Sesion32/2.png"  alt="Tarjeta" />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                            En el 2020 surgió la pandemia de Covid-19, así que el mundo tuvo que emplear más 
                            herramientas para comunicarse, y la interacción con otros seres humanos se 
                            transformó una vez más. Aparecieron las clases en línea, aumentó la búsqueda de 
                            información en la red y la petición de comida y despensa en línea. Las relaciones 
                            sociales requirieron conexión a internet para poder existir.<br/><br/> 
                            
                            Lo anterior trajo consigo ventajas y desventajas. Un beneficio es que facilitó el 
                            intercambio de mensajes. También que el conocimiento, los materiales educativos, 
                            las clases virtuales y los tutoriales estén al alcance de muchas más personas. ¿Qué 
                            otras situaciones valiosas notas tú a partir de este cambio?
                        </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <Grid container >
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img3ro/Sesion32/1.png"  alt="Tarjeta" />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                    <p className="Lectura">
                        Las desventajas son varias. Una de ellas es querer usar todo el tiempo estos aparatos, 
                        lo cual disminuye la interacción humana en el mundo real y hace que pasemos más 
                        tiempo sin actividades físicas. Además, no todos tienen acceso a ellos y esa 
                        desigualdad se vuelve cada vez más grande. ¿Consideras que tú usas de manera 
                        responsable las tecnologías digitales? <br/><br/>
                        
                        En la actualidad vivimos una época que los escritores de ciencia ficción visualizaron 
                        hace más de un siglo. Desafortunadamente no todos los niños tienen las mismas 
                        oportunidades. Las sociedades modernas tienen el compromiso de procurar un 
                        futuro más equitativo para todos los habitantes del planeta.
                    </p>
                    </Grid>
                </Grid>
        </Grid>
    </Grid>
    );
}
 
export default Tecnología_digital;
