import { Button, Grid,makeStyles}              from '@material-ui/core';
import React, { useContext, useEffect } from "react";
import DrawerContext from "../../../../../usability/DrawerContext";
import { useOnLoadImages } from "../../../../hooks/useOnLoadImages";
import Loader from "../../../../Loader/Loader";
import Drawer from "../../ReadingTools/Drawer";

const useStyles =  makeStyles (theme =>({
     Contenedor:{
        background:'#E9FBF9',
        width:'100vw',
        overflowX:'clip',
        [theme.breakpoints.down(1161)]:{
            background:'none',
           overflowX:'unset',
        },
        [theme.breakpoints.down(600)]:{
            marginTop: '79.51px',
        }
    },
  ContainerInfoLectura: {
    position: "relative",
    height: "86px",
    [theme.breakpoints.down(502)]:{
        height: "43px",
    },
    background: "#76D4C3",
    "& .BarraInfoLectura": {
      height: "86px",
      background: `Url(https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Barras/Azul.png)`,
        [theme.breakpoints.down(502)]:{
            height: "43px",
        },
    },
    "& .InfoLectura": {
      position: "absolute",
      height: "86px",
      width: "219px",
      boxSizing: "border-box",
      padding: "17.2px 0",
      background: "#FAFAFA",
      borderRadius: "50px",
      boxShadow: "2px 2px 2px 2px #00000029",
      zIndex: "4",
        [theme.breakpoints.down(502)]:{
            height: "43px",
            width: "109px",
            padding: "8.6px 0",
            borderRadius: "25px",
        },
    },
    "& .InfoLectura:before": {
      position: "absolute",
      display: "flex",
      boxSizing: "border-box",
      padding: "0 0 0 10px",
      justifyContent: "center",
      alignItems: "center",
      height: "39.88px",
      width: "39.88px",
      borderRadius: "50%",
      content: '""',
      boxShadow: "3px 0px .5px 0px #00000029",
      background: "#FAFAFA",
      left: "90px",
      top: "65px",
      transform: "rotate(0.25turn)",
      [theme.breakpoints.down(502)]:{
        left: "45px",
        top: "30px",
        height: "20.88px",
        width: "20.88px",
        },
    },
    "& .InfoLectura:after": {
      content: '""',
      height: "15px",
      width: "15px",
      borderTop: "2px solid #20A698",
      borderLeft: "2px solid #20A698",
      position: "absolute",
      bottom: "-10px",
      left: "Calc(50% - 8px)",
      transform: "rotate(0.625turn)",
      [theme.breakpoints.down(502)]:{
        height: "10px",
        width: "10px",
        left: "Calc(50% - 4.5px)",
        bottom: "-3px",
        borderTop: "1px solid #20A698",
        borderLeft: "1px solid #20A698",
        },
    },
    "& .Sesion": {
      borderLeft: "2px solid #D8D8D8",
    },
    "& .Dato": {
      margin: "0 0 5px 0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#20A698",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        margin: "0 0 2.5px 0",
        },
    },
    "& .Caracter": {
      margin: "0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#000000",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        },
    },
       '& .Regresar':{
        color: '#F2624F',
        border: '#F2624F 1px solid',
        height: '30px',
        width:'100px',
        borderRadius: '20px',
        letterSpacing: '0.14px',
        textTransform: 'none',
        background:'#FEFAF1',
        top:'calc(50% - 15px)',
        left:'5%',
        zIndex:4,
        [theme.breakpoints.down(502)]: {
          font:'bold 10px lato',
          width: "60px",
        },
      },
  },
    ContenedorLectura:{
        background:'#fff',
        width:'1160px',
        height:'100%',
       padding:'60px 168px 35px ',
        
        transform: (props) =>
        props.scale > 1 ? `scale(${props.scale}) translate(0px, 10%)` : `none`,
        [theme.breakpoints.down(1161)]:{
            width:'502px',
            padding:'60px 0 0 0',
          },
        [theme.breakpoints.down(502)]:{
            width:'350px',
            padding:'60px 0px 0px ',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding:'30px 0px 0px ',
        },

        '& .Escritorio':{
            display:'block',
            [theme.breakpoints.down(1161)]:{
                display:'none'
            },
        },
        '& .Tablet':{
            display:'none',
            [theme.breakpoints.down(1161)]:{
                display:'block'
            },
        }
    },
    ContainerTitulo:{
        margin:'0 0 34px 0',
        [theme.breakpoints.down(1161)]:{
            margin:'0 0 34px 0',
        },
        '& .Titulo':{
            font:'48px fredoka one',
            letterSpacing: '0px',
            lineHeight:'58px',
            color: '#42B0E9',
            margin:'0',
            textAlign:'center',
            [theme.breakpoints.down(1161)]:{
                font:'43px fredoka one',
                lineHeight:'52px',
            },
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            lineHeight : '30px',
            margin:'0 0 36px 0',
            font:'22px lato',
            color:'#000',
            letterSpacing: '0.20px',
            [theme.breakpoints.down(1161)]:{
                font:'18px lato',
                lineHeight :'28px',
                letterSpacing: '0.17px',
            },
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .Esp':{
            padding:'0 64px 0 0',
            margin:'0',
            [theme.breakpoints.down(1281)]:{
                padding:'0',
                margin:'0 0 36px 0',
            },
        },
        '& .Esp2':{
            padding:'0 41px 0 0',
            margin:'0',
            [theme.breakpoints.down(1281)]:{
                padding:'0',
                margin:'0 0 36px 0',
            },
        },
        '& .Esp3':{
            padding:'0 18px 0 0',
            margin:'0',
            [theme.breakpoints.down(1281)]:{
                padding:'0',
                margin:'0 0 36px 0',
            },
        },
        '& .SubTitulo':{
            font:'bold 30px lato',
            color:'#FD5F5D',
            letterSpacing:'.28px',
            lineHeight:'30px',
            margin:'0',
            [theme.breakpoints.down(1161)]:{
                lineHeight :'28px',
                font:'bold 26px lato',
                letterSpacing: '0.24px',
            },
            [theme.breakpoints.down(502)]:{
                font:'bold 18px lato',
                lineHeight :'24px',
                letterSpacing: '0.21px',
            },
            
            
            
        },
        '& .SubTitulo2':{
            font:'bold 26px lato',
            color:'#D245AD',
            letterSpacing:'.24px',
            lineHeight:'28px',
            margin:'0',
            [theme.breakpoints.down(1161)]:{
                font:'bold 22px lato',
                lineHeight :'24px',
                letterSpacing: '0.21px',
            },
            [theme.breakpoints.down(502)]:{
                font:'bold 15px lato',
                lineHeight :'20px',
                letterSpacing: '0.16px',
            },
        },

        '& .SubTitulo3':{
            position:'absolute',
            font:'bold 24px lato',
            color:'#E8503E',
            lineHeight:'30px',
            margin:'0',
            [theme.breakpoints.down(1161)]:{
                font:'bold 20px lato',
                lineHeight :'28px',
            },
            [theme.breakpoints.down(502)]:{
                font:'bold 16px lato',
                lineHeight :'14px',
            },
        },
        '& .Pos1':{
            width:'189px',
            top:'98px',
            left:'427px',
            [theme.breakpoints.down(1161)]:{
                width:'101px',
                top:'59px',
                left:'260px',
            },
            [theme.breakpoints.down(502)]:{
                width:'69px',
                top:'43px',
                left:'181px',
            },
        },
        '& .Pos2':{
            width:'180px',
            top:'488px',
            left:'153px',
            [theme.breakpoints.down(1161)]:{
                width:'95px',
                top:'294px',
                left:'94px',
            },
            [theme.breakpoints.down(502)]:{
                width:'61px',
                top:'206px',
                left:'63px',
            },
        },
        '& .TextImg':{
            font:'20px lato',
            color:'#717476',
            letterSpacing:'.19px',
            lineHeight:'30px',
            margin:'21px 0 0 0',
            [theme.breakpoints.down(1161)]:{
                font:' 16px lato',
                lineHeight :'25px',
                letterSpacing: '0.15px',
            },
            [theme.breakpoints.down(502)]:{
                font:' 11px lato',
                lineHeight :'14px',
                letterSpacing: '0.10px',
            },
        },
        '& .Imagen':{
            [theme.breakpoints.down(1161)]:{
                width:'91%',
            },
        },  
        '& .Imagen2':{
            width:'100%',
        }, 
        '& .Imagen3':{
            [theme.breakpoints.down(1161)]:{
                width:'60%',
            },
        }, 
        '& .Imagen4':{
            [theme.breakpoints.down(1161)]:{
                width:'52%',
            },
        }, 
    }
}));


const Cómo_nació_el_arte = (props) => {

  const {activeStep, setActiveStep, handlePreviewStep, id_actividad} = props

  const handleContinue = () => {
    setActiveStep(activeStep - 1)
    handlePreviewStep()
  }
const {
        handleSize,
        disabledZoom,
        closeZoom,
        handleZoom,
        scale,
        
        
        
       
        
      } = useContext(DrawerContext);
      const [loading, setLoading] = React.useState(true);
      const wrapperRef = React.useRef(null);
      const imagesLoaded = useOnLoadImages(wrapperRef);
    
      const detectSize = () => {
        const lectura = document.getElementById("lectura");
        const newWidth = lectura?.offsetWidth;
        const newHeight = lectura?.offsetHeight;
        setLoading(false);
        if (lectura) handleSize(newHeight, newWidth);
      };
      useEffect(() => {
        window.addEventListener("resize", detectSize);
        return () => {
          handleZoom()
          window.removeEventListener("resize", detectSize);
          closeZoom()
        };
      }, []);
    
      const classes = useStyles({ disabledZoom,scale });
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
             <Grid container item justifyContent="center" className={classes.ContainerInfoLectura}>
                       <Grid container className="BarraInfoLectura">
            <Button className="Regresar" onClick={handleContinue}>
                Regresar
            </Button>
        </Grid>
               <Grid container item className="InfoLectura">
                    <Grid container item direction="column" xs={6} justifyContent="center">
                        <p className="Dato">Grado</p>
                        <p className="Caracter">2</p>
                    </Grid>
                    <Grid container item direction="column" xs={6} justifyContent="center" className="Sesion">
                        <p className="Dato">Sesión</p>
                        <p className="Caracter">5</p>
                    </Grid>
               </Grid>
            </Grid>
            {!imagesLoaded && <Loader />}
            <Grid
            container
            item
            className={classes.ContenedorLectura}
            justifyContent="center"
            id="lectura"
            onLoad={detectSize}
            ref={wrapperRef}
            >
            <Drawer id_lectura={id_actividad} />
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >¿Cómo nació el arte?</h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container md={12} lg={6}>
                      <p className="Lectura Esp">
                        <h3 className='SubTitulo'>Un viaje al pasado </h3><br/>

                         Los primeros seres humanos que 
                         habitaron la Tierra fueron también 
                         los primeros en crear lo que hoy 
                         conocemos como <em>arte</em>. Los 
                         instrumentos musicales antiguos 
                         que encontramos en los museos, así 
                         como las canciones, las películas, 
                         las series de televisión y otras obras 
                         de arte que vemos hoy tienen su 
                         origen en aquel lejano pasado. Pero 
                         si nuestros antepasados no 
                         contaban con las herramientas que 
                         tenemos ahora, ¿cómo lograban 
                         hacerlo? ¿Y para qué lo hacían?
                      </p>
                    </Grid>
                    <Grid container justifyContent="center" md={12} lg={6}>
                        <Grid container justifyContent="center" alignItems='flex-end'>
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img2do/Sesion5/1.png" alt="Tarjeta" />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container sm={12}>
                      <p className="Lectura"> 
                        La respuesta está en nuestras emociones. ¿Quién no ha sentido miedo, alegría, 
                        tristeza o enojo? Pues bien, el arte ha permitido que las personas expresemos todo 
                        eso que nos mueve por dentro cuando nos enfrentamos a ciertas situaciones.<br/><br/>
                        
                        <h3 className='SubTitulo2'>
                            Cuando no comunicamos nuestras emociones <br/>
                            podemos sentir frustración 
                        </h3><br/>

                         Piensa en los primeros pobladores antes de que pudieran comunicarse a través del  
                         habla. ¿Cómo imaginas que le decían a alguien que lo querían o que les gustaba?,  
                         ¿cómo darían a conocer su felicidad cuando nacía un nuevo miembro en la familia o  
                         cuando lograban una caza exitosa para comer durante una temporada?<br/><br/>   

                         La respuesta es que expresaban sus sentires y experiencias con ayuda de sonidos,  
                         danzas y pigmentos naturales para dibujar en las paredes de sus cuevas. 
                      </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12} className='Img'>
                        <Grid container justifyContent="center" alignItems='flex-end'>
                            <img className='Imagen2' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img2do/Sesion5/2.png" alt="Tarjeta" />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container md={12} lg={7}>
                      <p className="Lectura Esp2">
                        <h3 className='SubTitulo3'>Pinturas rupestres</h3><br/><br/>

                         A lo largo del tiempo se han hallado en el 
                         mundo cientos de pinturas dentro de las 
                         cuevas donde habitaban nuestros 
                         antepasados. A esas pinturas se les llama 
                         <em> rupestres</em> y se les reconoce como las primeras 
                         expresiones artísticas de las que se tiene 
                         registro. De hecho, hace poco tiempo se
                         encontró una de las más antiguas: la figura de 
                         un cerdo elaborada hace 45 500 años en las 
                         islas de Indonesia. 
                         
                      </p>
                    </Grid>
                    <Grid container justifyContent="center" md={12} lg={5}>
                        <Grid container justifyContent="center" alignItems='flex-end'>
                        <img className="Escritorio" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img2do/Sesion5/3.png" alt="Tarjeta" />
                            <img className="Imagen3 Tablet" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img2do/Sesion5/3R.png" alt="Tarjeta" />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container md={12} lg={7} alignItems='flex-end'>
                        <p className="Lectura Esp3">
                            También se sabe que los antiguos pobladores 
                            hacían sonidos (similares a las canciones que 
                            hoy conocemos) a través de instrumentos 
                            elaborados con huesos, madera, pieles o 
                            piedras. Estas melodías servían para 
                            acompañar algunos rituales. Los científicos y 
                            artistas lo saben gracias a que en Alemania 
                            encontraron una flauta (como las que se usan 
                            en la escuela) hecha con el hueso de un ave 
                            hace más de 36 000 años.
                         
                        </p>
                    </Grid>
                    <Grid container justifyContent="center" md={12} lg={5}>
                        <Grid container justifyContent="center" >
                            <img className="Escritorio" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img2do/Sesion5/4.png" alt="Tarjeta" />
                            <img className="Imagen4 Tablet" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img2do/Sesion5/4R.png" alt="Tarjeta" />

                        </Grid>
                    </Grid>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                        Como ves, la esencia del arte es la expresión de los sentimientos humanos.
                        </p>
                    </Grid>
                </Grid>
        </Grid>
        <Grid container item style={{ marginTop: 80}} ></Grid>
    </Grid>
    );
}
 
export default Cómo_nació_el_arte;
