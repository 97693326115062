import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
    ContenedorLectura:{
        width:'502px',
            [theme.breakpoints.down(502)]:{
            width:'350px',
            },
    },
    ContainerTitulo:{
        margin:'0 0 34px 0',
        '& .Titulo':{
            letterSpacing: '0px',
            color: '#F6664D',
            margin:'0',
            textAlign:'center',
            font:'43px fredoka one',
            lineHeight:'52px',
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            margin:'0 0 36px 0',
            color:'#000',
            font:'18px lato',
            lineHeight :'28px',
            letterSpacing: '0.17px',
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        "& .SubTitulo": {
            color: "#823CE2",
            margin: "0",
            lineHeight: "28px",
            font: "bold 26px lato",
            letterSpacing: "0.24px",
            [theme.breakpoints.down(502)]: {
              font: "bold 22px lato",
              lineHeight: "24px",
              letterSpacing: "0.21px",
            },
        },
        "& .SubTitulo2": {
            color: "#FC5E9E",
            margin: "0",
            font: "bold 22px lato",
            lineHeight: "24px",
            letterSpacing: "0.21px",
            [theme.breakpoints.down(502)]: {
                font: "bold 18px lato",
                lineHeight: "20px",
                letterSpacing: "0.16px",
            },
        },
        "& .SubTitulo3": {
            color: "#823CE2",
            margin: "0",
            font: "bold 20px lato",
            lineHeight: "28px",
            letterSpacing: "0.19px",
            [theme.breakpoints.down(502)]: {
                font: "bold 16px lato",
                lineHeight: "18px",
                letterSpacing: "0.15px",
            },
        },
        '& .Cuadro':{
            position:'relative',
        },
        '& .Esp':{
            position:'absolute',
            margin:'53px 0 0 0',
            width:'85.25%',
            [theme.breakpoints.down(502)]: {
                margin:'18px 0 0 0',
            },
        },

        '& .Imagen':{
            width:'100%',
        }, 
    }
}));

const Go_el_juego_de_mesa_hino = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>

            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >
                    Go: el juego <br/>de mesa chino                    
                    </h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                      <p className="Lectura">
                        ¿Alguna vez te has preguntado a qué jugaban las personas antes de que existieran las 
                        consolas y el internet? ¿Con qué materiales estaban hechos sus pasatiempos? Las 
                        culturas antiguas inventaron los juegos de mesa y fabricaban sus piezas con piedras,
                        palos de madera, huesos, caparazones de tortuga y dientes de elefante. Después la 
                        humanidad evolucionó y las herramientas también, así que comenzaron a utilizar 
                        cobre, cristal, marfil e incluso mármol.<br/><br/> 
                        
                        Cada civilización inventó su propia diversión, con reglas y número de participantes 
                        específicos. Algunos juegos requerían habilidad manual, otros, destreza mental y 
                        unos más se basaban en el azar. Quizá el ajedrez sea el más famoso del segundo 
                        grupo. Fue conocido en Europa en el siglo <span style={{fontVariant: "all-small-caps"}}>XV</span>, hace cientos de años. Pero existe otro 
                        juego más antiguo y más difícil que es llamado <em>go</em>.<br/><br/> 
                        
                        <h3 className='SubTitulo'>La leyenda del origen</h3><br/>
                        
                        El go es denomidado de distintas maneras en Asia. En China lo nombran <em>wéiqí</em>. En 
                        Corea, <em>baduk</em>. Y en Japón, <em>igo</em>. Surgió en el año 550 a. n. e., pero se piensa que podría 
                        tener más de 4 000 años de antigüedad. Según una leyenda, fue diseñado a petición 
                        del emperador chino Yao, quien buscaba enseñar a su hijo cómo gobernar a través de 
                        una demostración. Pronto fue considerado una de las cuatro artes que todos los 
                        miembros de la aristocracia china debían estudiar. Las otras tres eran la 
                        caligrafía, la música y la pintura.
                      </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img3ro/Sesion48/1.png" alt="Tarjeta" />
                    </Grid>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                            <h3 className='SubTitulo2'>Las reglas</h3><br/>
                            
                            Las normas son sencillas. Se colocan piedras sobre el tablero, una por cada turno. Un 
                            concursante usa blancas y el otro, negras. El objetivo es <em>conquistar</em> territorios 
                            rodeándolos con las piedras propias. Gana quien obtenga un territorio mayor al de su 
                            oponente.<br/><br/>
                            
                            Esto puede parecer fácil, pero el desarrollo se complica por la variedad de opciones 
                            que hay en cada tirada. Es casi imposible que una partida termine igual a otra, pues 
                            las piedras se acomodan siempre de diferente manera. Ahí radica la diferencia con el 
                            ajedrez, donde algunas estrategias ya están determinadas. En él hay movimientos y 
                            planes específicos que pueden predecirse si somos hábiles. En el go eso no ocurre, 
                            aunque lo jueguen una persona experta y una principiante.
                        </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img3ro/Sesion48/2.png" alt="Tarjeta" />
                    </Grid>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                            <h3 className='SubTitulo3'>Diversión para todas las edades</h3><br/>
                            
                            Algunos estudios han mostrado que jugar go activa nuestro cerebro y previene 
                            enfermedades neurodegenerativas, como el Alzheimer. Hay aplicaciones digitales 
                            gratuitas para teléfonos inteligentes en las que puedes practicarlo e incluso competir 
                            con personas de otro país. Se agrupan por niveles, así que no importa si nunca has 
                            jugado. ¡Anímate a intentarlo!
                        </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img3ro/Sesion48/3.png" alt="Tarjeta" />
                    </Grid>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container item justifyContent='center' sm={12} className="Cuadro">
                        <img className="Imagen " src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img3ro/Sesion48/4R.png" alt="Tarjeta" />
                        <p className='Lectura Esp'>
                            Su popularidad ha aumentado alrededor del mundo y se llevan a cabo dos 
                            torneos internacionales en los que participan jugadores ¡de más de 70 
                            naciones!
                        </p>
                    </Grid>
                </Grid>
        </Grid>
    </Grid>
    );
}
 
export default Go_el_juego_de_mesa_hino ;
