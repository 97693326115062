import { Button, Grid, makeStyles } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import DrawerContext from "../../../../../usability/DrawerContext";
import { useOnLoadImages } from "../../../../hooks/useOnLoadImages";
import Loader from "../../../../Loader/Loader";
import Drawer from "../../ReadingTools/Drawer";

const useStyles = makeStyles((theme) => ({
  Contenedor: {
    background: "#E9FBF9",
    width: "100vw",
    overflowX: "clip",
    [theme.breakpoints.down(1161)]: {
      background: "none",
      overflowX: "unset",
    },
    [theme.breakpoints.down(600)]: {
      marginTop: "79.51px",
    },
  },
  ContainerInfoLectura: {
    position: "relative",
    height: "86px",
    [theme.breakpoints.down(502)]: {
      height: "43px",
    },
    background: "#76D4C3",
    "& .BarraInfoLectura": {
      height: "86px",
      background: `Url(https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Barras/Azul.png)`,
      [theme.breakpoints.down(502)]: {
        height: "43px",
      },
    },
    "& .InfoLectura": {
      position: "absolute",
      height: "86px",
      width: "219px",
      boxSizing: "border-box",
      padding: "17.2px 0",
      background: "#FAFAFA",
      borderRadius: "50px",
      boxShadow: "2px 2px 2px 2px #00000029",
      zIndex: "4",
      [theme.breakpoints.down(502)]: {
        height: "43px",
        width: "109px",
        padding: "8.6px 0",
        borderRadius: "25px",
      },
    },
    "& .InfoLectura:before": {
      position: "absolute",
      display: "flex",
      boxSizing: "border-box",
      padding: "0 0 0 10px",
      justifyContent: "center",
      alignItems: "center",
      height: "39.88px",
      width: "39.88px",
      borderRadius: "50%",
      content: '""',
      boxShadow: "3px 0px .5px 0px #00000029",
      background: "#FAFAFA",
      left: "90px",
      top: "65px",
      transform: "rotate(0.25turn)",
      [theme.breakpoints.down(502)]: {
        left: "45px",
        top: "30px",
        height: "20.88px",
        width: "20.88px",
      },
    },
    "& .InfoLectura:after": {
      content: '""',
      height: "15px",
      width: "15px",
      borderTop: "2px solid #20A698",
      borderLeft: "2px solid #20A698",
      position: "absolute",
      bottom: "-10px",
      left: "Calc(50% - 8px)",
      transform: "rotate(0.625turn)",
      [theme.breakpoints.down(502)]: {
        height: "10px",
        width: "10px",
        left: "Calc(50% - 4.5px)",
        bottom: "-3px",
        borderTop: "1px solid #20A698",
        borderLeft: "1px solid #20A698",
      },
    },
    "& .Sesion": {
      borderLeft: "2px solid #D8D8D8",
    },
    "& .Dato": {
      margin: "0 0 5px 0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#20A698",
      [theme.breakpoints.down(502)]: {
        font: "bold 12px lato",
        margin: "0 0 2.5px 0",
      },
    },
    "& .Caracter": {
      margin: "0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#000000",
      [theme.breakpoints.down(502)]: {
        font: "bold 12px lato",
      },
    },
    "& .Regresar": {
      color: "#F2624F",
      border: "#F2624F 1px solid",
      height: "30px",
      width: "100px",
      borderRadius: "20px",
      letterSpacing: "0.14px",
      textTransform: "none",
      background: "#FEFAF1",
      top: "calc(50% - 15px)",
      left: "5%",
      zIndex: 4,
      [theme.breakpoints.down(502)]: {
        font: "bold 10px lato",
        width: "60px",
      },
    },
  },
  ContenedorLectura: {
    background: "#red",
    width: "1160px",
    height: "100%",
    padding: "0",
    transform: (props) =>
      props.scale > 1 ? `scale(${props.scale}) translate(0px, 10%)` : `none`,
    [theme.breakpoints.down(1161)]: {
      width: "768px",
    },
    [theme.breakpoints.down(768)]: {
      width: "350px",
    },
    "& .Derechos": {
      position: "absolute",
      bottom: "33px",
      textAlign: "center",
      margin: "0px",
      font: " 15px lato",
      color: "#FFFFFF",
      [theme.breakpoints.down(1161)]: {
        display: "none",
      },
    },
    "& .Escritorio": {
      display: "block",
      [theme.breakpoints.down(1161)]: {
        display: "none",
      },
    },
    "& .Tablet": {
      display: "none",
      [theme.breakpoints.down(1161)]: {
        display: "block",
      },
    },
  },
  Parrafo1: {
    position: "relative",
    "& .Paso1": {
      display: (props) => (props.Boton1 ? "flex" : "none"),
    },
    "& .Paso2": {
      display: (props) => (props.Boton2 ? "flex" : "none"),
    },
    "& .Paso3": {
      display: (props) => (props.Boton3 ? "flex" : "none"),
    },
    "& .Paso4": {
      display: (props) => (props.Boton4 ? "flex" : "none"),
    },
    "& .Paso5": {
      display: (props) => (props.Boton5 ? "flex" : "none"),
    },
    "& .Paso6": {
      display: (props) => (props.Boton6 ? "flex" : "none"),
    },
    "& .Pos": {
      position: "absolute",
    },
    "& .Btn": {
      position: "absolute",
      top: "3.28%",
      height: "3.51%",
      width: "4.20%",
      cursor: "pointer",
      "&:hover": {
        background: "#F78572",
      },
    },
    "& .Btn1": {
      left: "34.13%",
    },
    "& .Btn2": {
      left: "38.34%",
    },
    "& .Btn3": {
      left: "42.55%",
    },
    "& .Btn4": {
      left: "52.63%",
    },
    "& .Btn5": {
      left: "56.85%",
    },
    "& .Btn6": {
      left: "61.05%",
    },
    "& .Titulo": {
      font: "84px fredoka one",
      letterSpacing: "0px",
      lineHeight: "102px",
      color: "#374057",
      margin: "0",
      [theme.breakpoints.down(1161)]: {
        font: "55px fredoka one",
        lineHeight: "67px",
      },
      [theme.breakpoints.down(768)]: {
        font: "25px fredoka one",
        lineHeight: "30px",
      },
    },
    "& .Tit1": {
      top: "11.26%",
    },
    "& .Titulo2": {
      font: "114px fredoka one",
      letterSpacing: "0px",
      lineHeight: "138px",
      color: "#374057",
      margin: "0",
      [theme.breakpoints.down(1161)]: {
        font: "74px fredoka one",
        lineHeight: "89px",
      },
      [theme.breakpoints.down(768)]: {
        font: "30px fredoka one",
        lineHeight: "40px",
      },
    },
    "& .Tit2": {
      top: "10.48%",
    },
    "& .Titulo3": {
      position: "absolute",
      font: "48px fredoka one",
      letterSpacing: "0px",
      lineHeight: "58px",
      color: "#000000",
      margin: "0",
      top: "17%",
      [theme.breakpoints.down(1161)]: {
        font: "31px fredoka one",
        lineHeight: "37px",
      },
      [theme.breakpoints.down(768)]: {
        font: "20px fredoka one",
        lineHeight: "25px",
      },
    },
    "& .SubTitulo": {
      color: "#FFFFFF",
      margin: "0",
      font: "67px fredoka one",
      lineHeight: "81px",
      [theme.breakpoints.down(1161)]: {
        font: "44px fredoka one",
        lineHeight: "53px",
      },
      [theme.breakpoints.down(768)]: {
        font: "20px fredoka one",
        lineHeight: "25px",
      },
    },
    "& .Sub1": {
      top: "57.50%",
    },
    "& .Sub1-1": {
      top: "13.38%",
    },
    "& .SubTitulo2": {
      textAlign: "center",
      color: "#FFFFFF",
      margin: "0",
      font: "28px Fredoka One",
      lineHeight: "34px",
      [theme.breakpoints.down(1161)]: {
        font: "17px Fredoka One",
        lineHeight: "21px",
      },
      [theme.breakpoints.down(768)]: {
        font: "9px Fredoka One",
        lineHeight: "11px",
      },
    },
    "& .Sub2": {
      top: "16.69%",
    },
    "& .SubTitulo3": {
      textAlign: "center",
      color: "#000000",
      margin: "0",
      font: "bold 32px lato",
      lineHeight: "44px",
      [theme.breakpoints.down(1161)]: {
        font: "bold 20px lato",
        lineHeight: "28px",
      },
      [theme.breakpoints.down(768)]: {
        font: "bold 10px lato",
        lineHeight: "12px",
      },
    },
    "& .Sub3": {
      top: "71.48%",
    },
    "& .Sub3-1": {
      top: "81.71%",
    },
    "& .Sub3-2": {
      top: "91.83%",
    },
    "& .Sub3-3": {
      top: "22.42%",
    },
    "& .SubTitulo4": {
      textAlign: "center",
      color: "#FFFFFF",
      margin: "0",
      font: "32px Fredoka One",
      lineHeight: "39px",
      textAlign: "center",
      [theme.breakpoints.down(1161)]: {
        font: "20px Fredoka One",
        lineHeight: "24px",
      },
      [theme.breakpoints.down(768)]: {
        font: "10px Fredoka One",
        lineHeight: "12px",
      },
    },
    "& .Sub4": {
      top: "25.67%",
      left: "66.03%",
    },
    "& .Sub4-1": {
      top: "43.75%",
      left: "18.01%",
    },
    "& .Sub4-2": {
      top: "62.40%",
      left: "63.53%",
    },
    "& .Sub4-3": {
      top: "80.16%",
      left: "13.88%",
    },
    "& .SubTitulo5": {
      textAlign: "center",
      color: "#FFFFFF",
      margin: "0",
      font: "43px Fredoka One",
      lineHeight: "51px",
      [theme.breakpoints.down(1161)]: {
        font: "27px Fredoka One",
        lineHeight: "32px",
      },
      [theme.breakpoints.down(768)]: {
        font: "12px Fredoka One",
        lineHeight: "15px",
      },
    },
    "& .Sub5": {
      top: "18.1%",
    },
    "& .Sub5-1": {
      top: "65.35%",
    },
    "& .Sub5-2": {
      top: "29.75%",
    },
    "& .Sub5-3": {
      top: "80.63%",
      left: "22.42%",
    },
    "& .SubTitulo6": {
      color: "#FFFFFF",
      margin: "0",
      font: "31px Fredoka One",
      lineHeight: "37px",
      [theme.breakpoints.down(1161)]: {
        font: "20px Fredoka One",
        lineHeight: "24px",
      },
      [theme.breakpoints.down(768)]: {
        font: "9px Fredoka One",
        lineHeight: "10px",
      },
    },
    "& .Sub6": {
      top: "74.85%",
      left: "35.60%",
      width: "31.86%",
    },
    "& .Sub6-1": {
      top: "61.10%",
      left: "33.53%",
      width: "36.86%",
    },
    "& .Sub6-2": {
      top: "51.96%",
      left: "32.24%",
      width: "38.86%",
    },
    "& .Lectura": {
      margin: "0 ",
      textAlign: "center",
      color: "#000000",
      font: " 22px lato",
      lineHeight: "30px",
      letterSpacing: "0.21px",
      [theme.breakpoints.down(1161)]: {
        font: " 18px lato",
        lineHeight: "23px",
        letterSpacing: "0.17px",
      },
      [theme.breakpoints.down(768)]: {
        font: "8.5px lato",
        lineHeight: "12px",
        letterSpacing: "0.10px",
      },
    },
    "& .Lec1": {
      top: "29.75%",
      left: "61.46%",
    },
    "& .Lec1-1": {
      top: "47.83%",
      left: "11.99%",
    },
    "& .Lec1-2": {
      top: "68.50%",
      left: "61.46%",
    },
    "& .Lec1-3": {
      top: "86.26%",
      left: "12.15%",
    },
    "& .Lectura2": {
      margin: "0 ",
      color: "#000000",
      font: " 27px lato",
      lineHeight: "37px",
      letterSpacing: "0.25px",
      [theme.breakpoints.down(1161)]: {
        font: " 18px lato",
        lineHeight: "23px",
        letterSpacing: "0.17px",
      },
      [theme.breakpoints.down(768)]: {
        font: "8.5px lato",
        lineHeight: "12px",
        letterSpacing: "0.10px",
      },
    },
    "& .Lec2": {
      top: "12.34%",
      left: "22.42%",
    },
    "& .Lec2-1": {
      top: "80.52%",
      left: "22.42%",
    },
    "& .Lec2-2": {
      top: "66.32%",
      left: "22.42%",
    },
    "& .Lec2-3": {
      top: "56.97%",
      left: "22.42%",
    },
    "& .Azul": {
      color: "#186B7C",
    },
    "& .Naranja": {
      color: "#DF6D33",
      font: " bold 35px lato",
      [theme.breakpoints.down(1161)]: {
        font: " bold 22px lato",
      },
      [theme.breakpoints.down(768)]: {
        font: "bold 10px lato",
      },
    },
    "& .Espacio": {
      margin: "0 11% 0 0",
    },
    "& .Espacio2": {
      margin: "0 7% 0 0",
    },
    "& .Espacio3": {
      margin: "0 5% 0 0",
      [theme.breakpoints.down(768)]: {
        margin: "0 6% 0 0",
      },
    },
    "& .ul": {
      top: "86.51%",
      left: "24.42%",
      margin: "0",
      padding: "0",
      color: "#186B7C",
      font: " bold 27px lato",
      [theme.breakpoints.down(1161)]: {
        font: " bold 18px lato",
      },
      [theme.breakpoints.down(768)]: {
        font: "bold 8.5px lato",
      },
    },

    "& .Imagen": {
      width: "100%",
      font: " bold 35px lato",
    },
  },
}));

const Edu_can = (props) => {

    const {activeStep, setActiveStep, handlePreviewStep, id_actividad} = props
    const [Boton1,Btn1] = useState(true);
    const [Boton2,Btn2] = useState(false);
    const [Boton3,Btn3] = useState(false);
    const [Boton4,Btn4] = useState(false);
    const [Boton5,Btn5] = useState(false);
    const [Boton6,Btn6] = useState(false);

    const handleContinue = () => {
        setActiveStep(activeStep - 1)
        handlePreviewStep()
      }

    const {
      handleSize,
      disabledZoom,
      closeZoom,
      handleZoom,
     scale
    } = useContext(DrawerContext);
    const [loading, setLoading] = React.useState(true);
    const wrapperRef = React.useRef(null);
    const imagesLoaded = useOnLoadImages(wrapperRef);
  
    const detectSize = () => {
      const lectura = document.getElementById("lectura");
      const newWidth = lectura?.offsetWidth;
      const newHeight = lectura?.offsetHeight;
      setLoading(false);
      if (lectura) handleSize(newHeight, newWidth);
    };
    useEffect(() => {
      window.addEventListener("resize", detectSize);
      return () => {
        handleZoom()
        window.removeEventListener("resize", detectSize);
        closeZoom()
      };
    }, 
    
    []);

    const classes = useStyles({
        disabledZoom,scale, 
        Boton1,Boton2,Boton3,
        Boton4,Boton5,Boton6
    })

    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
             <Grid container item justifyContent="center" className={classes.ContainerInfoLectura}>
                       <Grid container className="BarraInfoLectura">
            <Button className="Regresar" onClick={handleContinue}>
                Regresar
            </Button>
        </Grid>
               <Grid container item className="InfoLectura">
                    <Grid container item direction="column" xs={6} justifyContent="center">
                        <p className="Dato">Grado</p>
                        <p className="Caracter">4</p>
                    </Grid>
                    <Grid container item direction="column" xs={6} justifyContent="center" className="Sesion">
                        <p className="Dato">Sesión</p>
                        <p className="Caracter">43</p>
                    </Grid>
               </Grid>
            </Grid>
            {!imagesLoaded && <Loader />}
        <Grid
            container
            item
            className={classes.ContenedorLectura}
            justifyContent="center"
            id="lectura"
            onLoad={detectSize}
            ref={wrapperRef}
        >
<Drawer id_lectura={id_actividad} />
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <div className="Btn Btn1" 
                        onClick={() => {
                            Btn1(true);
                            Btn2(false);
                            Btn3(false);
                            Btn4(false);
                            Btn5(false);
                            Btn6(false);
                        }}>
                    </div>
                    <div className="Btn Btn2" 
                        onClick={() => {
                            Btn1(false);
                            Btn2(true);
                            Btn3(false);
                            Btn4(false);
                            Btn5(false);
                            Btn6(false);
                        }}>
                    </div>
                    <div className="Btn Btn3" 
                        onClick={() => {
                            Btn1(false);
                            Btn2(false);
                            Btn3(true);
                            Btn4(false);
                            Btn5(false);
                            Btn6(false);
                        }}>
                    </div>
                    <div className="Btn Btn4" 
                        onClick={() => {
                            Btn1(false);
                            Btn2(false);
                            Btn3(false);
                            Btn4(true);
                            Btn5(false);
                            Btn6(false);
                        }}>
                    </div>
                    <div className="Btn Btn5" 
                        onClick={() => {
                            Btn1(false);
                            Btn2(false);
                            Btn3(false);
                            Btn4(false);
                            Btn5(true);
                            Btn6(false);
                        }}>
                    </div>
                    <div className="Btn Btn6" 
                        onClick={() => {
                            Btn1(false);
                            Btn2(false);
                            Btn3(false);
                            Btn4(false);
                            Btn5(false);
                            Btn6(true);
                        }}>
                    </div>
                    <Grid container justifyContent='center' className='Paso1'>
                        <img className='Imagen' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img4to/Sesion43/1.png"  alt="" />
                        <h2 className='Pos Titulo Tit1'>
                            Educa Canes
                        </h2>
                        <h3 className='Pos SubTitulo Sub1'>
                            Contacto
                        </h3>
                        <h3 className='Pos SubTitulo2 Sub2'>
                            Entrenamiento canino
                        </h3>
                        <h3 className='Pos SubTitulo3 Sub3'>
                            Calle 78, #26, Nunciatura, San José, Costa Rica
                        </h3>
                        <h3 className='Pos SubTitulo3 Sub3-1'>
                            Tel.: 223 456 789
                        </h3>
                        <h3 className='Pos SubTitulo3 Sub3-2'>
                            www.educanparaperros.com
                        </h3>
                    </Grid>
                    <Grid container justifyContent='center' className='Paso2'>
                        <img className='Imagen' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img4to/Sesion43/2.png"  alt="" />
                        <h3 className='Pos SubTitulo Sub1-1'>
                            Otros servicios
                        </h3>
                        <h3 className='Pos SubTitulo4 Sub4'>
                            Alojamiento
                        </h3>
                        <p className='Pos Lectura Lec1'>
                            Contamos con pensión canina<br/> 
                            para tu tranquilidad mientras<br/> 
                            sales de viaje.
                        </p>
                        <h3 className='Pos SubTitulo4 Sub4-1'>
                            Paseadores
                        </h3>
                        <p className='Pos Lectura Lec1-1'>
                            Si tienes poco tiempo para pasear<br/>  
                            a tu mascota, uno de nuestros<br/> 
                            expertos puede ayudarte<br/>  
                            en el horario que elijas.
                        </p>
                        <h3 className='Pos SubTitulo4 Sub4-2'>
                            Adiestramiento<br/> 
                            a domicilio
                        </h3>
                        <p className='Pos Lectura Lec1-2'>
                            Nosotros vamos a tu casa<br/> 
                            si no puedes asistir a nuestras<br/> 
                            instalaciones.
                        </p>
                        <h3 className='Pos SubTitulo4 Sub4-3'>
                            Pista<br/> 
                            de entrenamiento
                        </h3>
                        <p className='Pos Lectura Lec1-3'>
                            Ven a divertirte con tu can<br/> 
                            y aprovecha para practicar lo que<br/> 
                            aprendieron en nuestros cursos.
                        </p>
                    </Grid>
                    <Grid container justifyContent='center' className='Paso3'>
                        <img className='Imagen' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img4to/Sesion43/3.png"  alt="" />
                        <h2 className='Pos Titulo2 Tit2'>
                            Educa Canes
                        </h2>
                        <h3 className='Pos SubTitulo5 Sub5'>
                            Entrenamiento canino
                        </h3>
                        <h3 className='Pos SubTitulo3 Sub3-3'>
                            ¿Te gustaría desarrollar la inteligencia natural<br/> 
                            de tu perro, y hacer que sea sociable y obediente?
                        </h3>
                        <h3 className='Pos SubTitulo5 Sub5-1'>
                            ¡Nosotros te ayudamos!
                        </h3>
                    </Grid>
                    <Grid container justifyContent='center' className='Paso4'>
                        <img className='Imagen' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img4to/Sesion43/4.png"  alt="" />
                        <p className='Pos Lectura2 Lec2'>
                            <b className='Azul'>EduCan es una escuela para perros y amos con<br/>
                            diferentes necesidades.</b>  Nos enfocamos en estimular<br/> 
                            la inteligencia natural de tu mascota sin que pierda su <br/>
                            vigor. Harás que alcance un gran nivel de sociabilidad <br/>
                            y obediencia con nuestra asesoría.
                        </p>
                        <h3 className='Pos SubTitulo5 Sub5-2'>
                            Cursos por niveles
                        </h3>
                        <h3 className='Pos SubTitulo6 Sub6'>
                            Nivel 1 <span className='Espacio'></span> Cachorro feliz
                        </h3>
                        <p className='Pos Lectura2 Lec2-1'>
                            <b className='Azul'>Este curso consiste en ejercicios básicos de obediencia en<br/>
                            grupo.</b> El objetivo es que los animales sientan confianza y <br/>
                            convivan, lo cual reducirá su ansiedad y agresividad. Todas <br/>
                            las prácticas tienen un formato de juego y recompensa, <br/>
                            para que tanto las mascotas como los dueños se diviertan <br/>
                            mientras aprenden.
                        </p>
                    </Grid>
                    <Grid container justifyContent='center' className='Paso5'>
                        <img className='Imagen' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img4to/Sesion43/5.png"  alt="" />
                        <h3 className='Pos SubTitulo6 Sub6-1'>
                            Nivel 2 <span className='Espacio2'></span> Paseos divertidos
                        </h3>
                        <p className='Pos Lectura2 Lec2-2'>
                            <b className='Azul'>En estas lecciones se busca trabajar en un ambiente con<br/> 
                            más elementos distractores,</b> lo cual representa un reto<br/> 
                            para ellos.<br/><br/> 
                            Lograrán disfrutar de su tiempo fuera de casa, sin<br/> 
                            nerviosismo ni agresividad, a través de paseos en grupo,<br/> 
                            ejercicios de obediencia y el uso de la correa.<br/><br/>

                            En todas las salidas se contemplan momentos para <br/>
                            descansar y asegurarse de que se mantengan hidratados.<br/><br/>

                            <b className='Naranja'>Duración:</b> seis sesiones de dos horas cada una en dos<br/> 
                            horarios, ya sea en la mañana o por la tarde.
                        </p>
                    </Grid>
                    <Grid container justifyContent='center' className='Paso6'>
                        <img className='Imagen' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img4to/Sesion43/6.png"  alt="" />
                        <h3 className='Pos SubTitulo6 Sub6-2'>
                            Nivel 3 <span className='Espacio3'></span> Habilidades y juegos
                        </h3>
                        <p className='Pos Lectura2 Lec2-3'>
                            Esta fase funciona como un refuerzo de las anteriores y<br/> 
                            contribuye a que los perros asimilen las instrucciones de<br/> 
                            las personas. Se intenta crear diferentes juegos y desafíos,<br/> 
                            en los que sabrán ganarse recompensas.<br/><br/> 

                            Estos encuentros se llevan a cabo tanto en nuestra pista de <br/>
                            juegos como en la calle.<br/><br/>

                            <b className='Naranja'>Duración:</b> ocho sesiones de dos horas cada una.
                        </p>
                        <h3 className='Pos SubTitulo5 Azul Sub5-3'>
                            Requisitos para todos los cursos:
                        </h3>
                        <ul className='Pos ul'>
                            <li>
                                <p className='Lectura2'>
                                Edad mínima de cuatro meses.
                                </p>
                            </li>
                            <li>
                                <p className='Lectura2'>
                                Cartilla de vacunación en regla.
                                </p>
                            </li> <li>
                                <p className='Lectura2'>
                                Contar con collar y placa de identificación.
                                </p>
                            </li>
                        </ul>
                    </Grid>
                </Grid>
        </Grid>
        <Grid container item style={{ marginTop: 80}} ></Grid>
    </Grid>
    );
}
 
export default Edu_can;
