import React from "react";
import { makeStyles } from "@material-ui/core";

const useStyle = makeStyles((theme) => ({
  container: {
    display: "grid",
    gridTemplateRows: "1fr 25px",
    gridTemplateColumns: "1fr",
    // alignItems: "center",
    justifyContent: "center",
    alignContent: "center",
    justifyItems: "center",
    backgroundColor: "transparent",
    // minHeight: "100px",
    border: "none",
    outline: "none",
    cursor: "pointer",
    height: "100%",
    width: "100%",
    "@media (orientation: landscape)": {
      "@media(max-height:400px)": {
        alignContent: "normal",
        minHeight: "auto",
      },
    },
    [theme.breakpoints.up("sm")]: {
      alignItems: "end",
      gap: "5px",
    },
    [theme.breakpoints.up("xl")]: {
      alignItems: "end",
      gap: "5px",
      "@media(min-height:1600px)": {
        gridTemplateRows: "1fr 35px",
      },
    },
  },
  img: {
    height: "100%",
    objectFit: "contain",
    width: "85%",
    [theme.breakpoints.up("xs")]: {
      // height: "100%",
      // width: "80px",
      "@media (orientation: landscape)": {
        "@media(max-height:400px)": {
          width: "70px",
        },
      },
    },
    [theme.breakpoints.up("sm")]: {
      height: "auto",
      width: "100px",
      objectFit: "unset",
      "@media(min-height:860px)": {
        width: "120px",
      },
      "@media (orientation: landscape)": {
        "@media(max-height:400px)": {
          width: "70px",
        },
      },
    },
    [theme.breakpoints.up("md")]: {
      height: "auto",
      width: "130px",
    },
    [theme.breakpoints.up("lg")]: {
      height: "auto",
      // objectFit: "contain",
      width: "90px",
      alignSelf: "end",
      "@media (orientation: landscape)": {
        "@media(min-height:800px)": {
          width: "140px",
        },
        "@media(min-height:920px)": {
          width: "160px",
        },
        "@media(min-height:1024px)": {
          width: "180px",
        },
      },
    },
    [theme.breakpoints.up("xl")]: {
      height: "100%",
      // objectFit: "contain",
      width: "220px",
      "@media (orientation: landscape)": {
        "@media(min-height:800px)": {
          width: "140px",
        },
        "@media(min-height:920px)": {
          width: "160px",
          height: "auto",
        },
        "@media(min-height:1024px)": {
          width: "180px",
        },
        "@media(min-height:1280px)": {
          width: "220px",
        },
        "@media(min-height:1600px)": {
          width: "250px",
        },
      },
    },
    [theme.breakpoints.down("sm")]: {
      // width: "100%",
      // minHeight: "240px",
      "@media (orientation: landscape)": {
        // maxHeight: "70px",
      },
    },
  },

  input: {
    cursor: "pointer",
    height: "10px",
    width: "10px",
    minHeight: "10px",
    [theme.breakpoints.up("sm")]: {
      height: "15px",
      width: "15px",
    },
    background: (props) => (props.checked ? "#E87DBA" : "none"),
    borderRadius: "50%",
    border: " 2px solid #40DAC9",
    [theme.breakpoints.up("lg")]: {
      height: "25px",
      width: "25px",
      "@media (orientation: landscape)": {
        height: "20px",
        width: "20px",
        "@media(min-height:1600px)": {
          height: "30px",
          width: "30px",
        },
      },
    },
    // [theme.breakpoints.up("xl")]: {
    //   height: "37px",
    //   width: "37px",
    // },
  },
}));
const AvatarButtom = ({ title, img, checked, handler, grade }) => {
  const classes = useStyle({ checked });

  return (
    <>
      <button className={classes.container} onClick={handler}>
        <img src={img} alt={`Avatar ${title}`} className={classes.img} loading="lazy" />
        <div className={classes.input} />
      </button>
    </>
  );
};

export default AvatarButtom;
