import React from 'react';
import { Grid,makeStyles, Button}       from "@material-ui/core";
import Dialog               from '@material-ui/core/Dialog';
import DialogActions        from '@material-ui/core/DialogActions';
import DialogContent        from '@material-ui/core/DialogContent';

const useStyles = makeStyles(theme =>({
  MuiDialogContainer: {
    borderRadius: '61px',
    height: "70%",
 
    backgroundColor: "#FEFAF1",
    border: "#707070 2px solid",
    backdropFilter: "blur(3px)",
    overflow: 'auto',
    [theme.breakpoints.down(1568)]:{
      height: "500px",
      maxWidth: "75%",
    },
    [theme.breakpoints.down(468)]:{
      height: "350px",
      maxWidth: "80%",
    },
  },
  continueButton:{
    marginBottom:"20px",
    textTransform: 'none',
    height:'62px',
    width:'279px',  
    background: '#7A54BA',
    boxShadow: '0px 3px 6px #00000029',
    borderRadius: '61px',
    color: '#FFFFFF',
    font: 'bold 20px lato ',
    '&:hover':{
        background: '#dd78fb',
    },
    [theme.breakpoints.down(1368)]:{
      height:'52px',
      width:'229px',
    },
    [theme.breakpoints.down(1068)]:{
      height:'52px',
      width:'60%',
    },
    [theme.breakpoints.down(769)]:{
      width: "56.5%"
    },
  },

badgesContainer:{
  // width:"329px",
  height: "229px",
  [theme.breakpoints.down(1300)]:{
    width:"180px",
    height:"180px",
  },
  [theme.breakpoints.down(1024)]:{
    width:"150px",
    height:"150px",
  },
  [theme.breakpoints.down(600)]:{
    width:"130px",
    height:"130px",
  },
  // [theme.breakpoints.down(1300)]:{
  //   width:"200px",
  //   height:"200px",
  // },
},
reading: {
  width: "98%",
  font: "32px Fredoka One",
  color: '#1F9EC4',  
  letterSpacing:'0',
  textAlign: 'center',
  // margin:"14px 10px 14px 0",
  [theme.breakpoints.down(1370)]:{
    font: "500 28px Fredoka One",
    width: "90%",
    margin:"10px 10px 10px 0"
  },
  [theme.breakpoints.down(1310)]:{
    font: "500 24px Fredoka One",
    // width: "80%",
    margin:"10px 5px 10px 0"
  },
  [theme.breakpoints.down(925)]:{
    font: "500 20px Fredoka One",
    // width: "70%",
    margin:"6px 0 6px 0"
  },
  [theme.breakpoints.down(426)]:{
    font: "500 18px Fredoka One",
    // width: "70%",
    margin:"6px 0 6px 5%"
  },
},
badgeText:{
  font:'22px Fredoka One',
  letterSpacing: '0.38px',
  color: '#5CAE9A',
  margin:'0px',
  [theme.breakpoints.down(1024)]:{
    font:'18px Fredoka One',
  },
  [theme.breakpoints.down(700)]:{
    font:'12px Fredoka One',
  },
},
}))

const GenericActivityDialog = (props) => {
  const classes = useStyles()
  const { open, setOpen, badge, handleClose, badgeType } = props

  // const handleClose = () => {
  //   setOpen(false)
  // }

   // const myTimeout = setTimeout(() => {setOpen(false)}, 3000)

  return(
    <Dialog open={open} classes={{paper: classes.MuiDialogContainer}} disableEscapeKeyDown >
      {/* <DialogTitle>
        <Grid container justifyContent="center" alignItems="center">
          <p>Práctica 1</p>
        </Grid>
      </DialogTitle> */}
      <DialogContent>
        <Grid container justifyContent="center" alignItems="center" style={{height: "100%"}}>
          <h1 className={classes.reading}>¡Ganaste una insignia!</h1>
          <Grid container item xs={12} justifyContent="center" alignItems="center" >
            <img src={badge} alt="insignia" className={`${classes.badgesContainer} animate__animated animate__wobble`}/>
          </Grid>
          <p className={classes.badgeText}>{badgeType}</p>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container justifyContent="center" alignItems="center">
          <Button onClick={ handleClose } id="BotonVolver" className={classes.continueButton} >Volver</Button>
        </Grid>
      </DialogActions>
    </Dialog>
  )
}

export default GenericActivityDialog;