import React, {useEffect} from 'react';
import { makeStyles,Grid }  from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  readingDemoContainer: {
    // maxHeight: "1024px",
    marginTop:'0px',
    // boxSizing: "border-box",
    boxShadow: ' 5px 5px 10px 18px #00000029',
    padding: '3% 3% 3% 3%',
    backgroundColor: 'white',
    borderRadius: '20px 20px 20px 20px',
  },
  gridContainer: {
    height: '100%',
    display: 'flex',
    padding: '40px',
    justifyContent: 'space-between',
    alignItems: 'center',
  }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ReadingModal = (props) => {
  const classes = useStyles();
  const {open, setOpen, lectura: Lectura , baseNumber, step} = props

  const backgroundColors = ["#9051b1", "#ffc200", "#3f43b3", "#84e9df"];
  const [stepText, setStepText] = React.useState(backgroundColors[0]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    setStepText(backgroundColors[baseNumber])
  },[step])

  useEffect(() => {
    if(window.innerWidth < 1024) setOpen(true);
    
  },[baseNumber])

  // console.log(Lectura, "lec")
  return (
    <div>
      <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Volver a la actividad
            </Typography>
            {/* <Button autoFocus color="inherit" onClick={handleClose}>
              save
            </Button> */}
          </Toolbar>
        </AppBar>
          <Grid className={classes.gridContainer} style={{ background: stepText }}>
            <Grid container direction="column" justifyContent="center" alignItems="center" className={classes.readingDemoContainer}>
              {Lectura}
            </Grid>
          </Grid>
      </Dialog>
    </div>
  );
}

export default ReadingModal;
