import React from "react";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Button, makeStyles, Grid } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  MuiDialogContainer: {
    borderRadius: "61px",
    height: "90%",
    width: "90%",
    backgroundColor: "#FEFAF1",
    border: "#707070 2px solid",
    backdropFilter: "blur(3px)",
    maxWidth: "80%",
  },
  MuiContentRoot: {
    overflowY: "auto",
    [theme.breakpoints.up("lg")]: {
      "&::-webkit-scrollbar": {
        width: "auto",
      },
      "&::-webkit-scrollbar-track": {
        backgroundColor: "#7A54BA20",
        webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#7A54BA",
        borderRadius: "5px",
      },
    },
  },

  mainTitle: {
    font: "20px fredoka one",
    margin: "0px",
    textAlign: "center",
    [theme.breakpoints.up("sm")]: {
      font: "28px fredoka one",
    },
    [theme.breakpoints.up("md")]: {
      font: "32px fredoka one",
    },
    [theme.breakpoints.up("xl")]: {
      font: "36px fredoka one",
    },
  },
  section: {
    margin: 0,
    padding: "5px 0",
    boxSizing: "border-box",
  },

  titleWithNumber: {
    font: "bold 20px lato",
    [theme.breakpoints.down("760")]: {
      font: "bold 18px lato",
      margin: "5px 0 10px 0",
    },
    [theme.breakpoints.down("321")]: {
      font: "bold 16px lato",
    },
  },
  subTitle: {
    font: "bold 20px lato",
    margin: "0",
    [theme.breakpoints.down("760")]: {
      font: "bold 18px lato",
      margin: "5px 0 10px 0",
    },
    [theme.breakpoints.down("321")]: {
      font: "bold 16px lato",
    },
  },

  textPrincipal: {
    font: "16px lato",
    [theme.breakpoints.down("760")]: {
      font: "12px lato",
    },
    [theme.breakpoints.up("1440")]: {
      font: "20px lato",
    },
  },
  link: {
    font: "inherit",
    color: "inherit",
    textDecoration: "none",
    overflow: "hidden",
    whiteSpace: "pre",
    lineBreak: "anywhere",
  },
  paragraph: {
    textAlign: "justify",
    font: "16px lato",
    [theme.breakpoints.down("760")]: {
      font: "12px lato",
    },
    [theme.breakpoints.up("1920")]: {
      font: "20px lato",
    },
  },
  paragraphContact: {
    textAlign: "justify",
    font: "12px lato",
    [theme.breakpoints.up("760")]: {
      font: "16px lato",
      display: "inline",
    },
    [theme.breakpoints.up("1920")]: {
      font: "20px lato",
    },
  },
  decoratedLink: {
    color: "#007fa3",
    overflowWrap: "anywhere",
    display: "inline-block",
    textAlign: "justify",
    font: "16px lato",
    marginLeft: "5px",
    [theme.breakpoints.down("760")]: {
      font: "12px lato",
    },
    [theme.breakpoints.up("1920")]: {
      font: "20px lato",
    },
  },
  table: {
    borderCollapse: "collapse",
    border: "1px solid rgba(224, 224, 224, 1)",
    textAlign: "center",
    margin: "0 auto",
    padding: "5px",
    outline: "1pxpx solid slategrey",
    backgroundColor: "#FFF",
  },
  tableItem: {
    border: "1px solid rgba(224, 224, 224, 1)",
    padding: "20px",
  },
  listNumber: {
    padding: "0",
    listStylePosition: "inside",
    textAlign: "justify",
  },
  numberItem: {
    margin: "10px",
    textAlign: "justify",
    font: "16px lato",
    [theme.breakpoints.down("760")]: {
      font: "12px lato",
    },
    [theme.breakpoints.up("1920")]: {
      font: "20px lato",
    },
  },
  listBullet: {
    padding: "0",
    listStylePosition: "inside",
    textAlign: "justify",
    "& li": {},
  },
  listDash: {
    padding: "0",
    listStylePosition: "inside",
    listStyle: "none",
    textAlign: "justify",
  },

  dash: {
    font: "inherit",
    marginRight: "10px",
  },
  itemBullet: {
    listStylePosition: "inside",
    paddingLeft: "20px",
    font: "16px lato",
    margin: "10px",
    [theme.breakpoints.down("sm")]: {
      font: "12px lato",
      paddingLeft: "0",
    },
  },

  romanList: {
    padding: "0",
    listStylePosition: "inside",
    listStyle: "lower-roman",
    textAlign: "justify",
    marginLeft: "50px",
  },

  romanItem: {
    margin: "10px",
    textAlign: "justify",
    font: "16px lato",
    [theme.breakpoints.down("760")]: {
      font: "12px lato",
    },
    [theme.breakpoints.up("1920")]: {
      font: "20px lato",
    },
  },

  lettersList: {
    padding: "0",
    listStylePosition: "inside",
    listStyle: "lower-latin",
    textAlign: "justify",
  },
  letterItem: {
    margin: "10px",
    textAlign: "justify",
    font: "16px lato",
    [theme.breakpoints.down("760")]: {
      font: "12px lato",
    },
    [theme.breakpoints.up("1920")]: {
      font: "20px lato",
    },
  },

  BotonContinuar: {
    textTransform: "none",
    height: "42px",
    width: "138px",
    background: "#5825AD",
    boxShadow: "0px 3px 6px #00000029",
    borderRadius: "61px",
    color: "#FFFFFF",
    font: "bold 20px lato ",
    "&:hover": {
      background: "#dd78fb",
    },
    [theme.breakpoints.up("sm")]: {
      height: "62px",
      width: "238px",
      font: "bold 18px lato ",
    },
  },

  textChangeRegion: {
    textAlign: "justify",
    font: "16px lato",
    color: "#505759",
    [theme.breakpoints.down("760")]: {
      font: "12px lato",
    },
    [theme.breakpoints.up("1920")]: {
      font: "20px lato",
    },
  },

  buttonChangeRegion: {
    border: "none",
    backgroundColor: "transparent",
    textDecoration: "underline",
    cursor: "pointer",
    padding: 0,
    font: "inherit",
    color: "#007fa3",
  },
}));

const TermsAndConditionsHispanoamerica = (props) => {
  const {
    open,
    handleConfirm,
    changeToSelectRegion,
    accept,
    accepted,
  } = props;
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      classes={{ paper: classes.MuiDialogContainer }}
      disableEscapeKeyDown
    >
      <DialogTitle style={{ textAlign: "center" }}>
        <span className={classes.mainTitle}>
          Política de privacidad y protección de datos personales Hispanoamérica
        </span>
      </DialogTitle>
      <DialogContent classes={{ root: classes.MuiContentRoot }}>
        <p className={classes.textChangeRegion}>
          Si eres de otro país lee los términos y condiciones{" "}
          <button
            onClick={changeToSelectRegion}
            className={classes.buttonChangeRegion}
          >
            aquí
          </button>
        </p>
        <section className={classes.section}>
          <p className={classes.paragraph}>
            Este documento contiene la Política de Privacidad y Protección de
            Datos Personales de los clientes, aliados comerciales, empleados,
            proveedores, divisiones y unidades, y en general, de quien quiera
            que se encuentre en una base de datos (en adelante los “Titulares”)
            de PEARSON HISPANOAMÉRICA (véase la sección “Quienes Somos”).
          </p>

          <p className={classes.paragraph}>
            En la presente Política, PEARSON HISPANOAMÉRICA incluye todos los
            lineamientos y medidas necesarias a nivel físico, técnico y jurídico
            para garantizar que los datos personales suministrados por los
            Titulares reciban un Tratamiento adecuado y en estricto cumplimiento
            de la normatividad de protección de datos aplicable.
          </p>
          <p className={classes.paragraph}>
            La celebración de contratos, el diligenciamiento de formatos y de
            formularios, la realización de entrevistas, la participación a
            eventos, el acceso a los Servicios de la plataforma
            <a
              href="https://cdl-pearson.com/"
              target="_blank"
              rel="noreferrer"
              className={classes.decoratedLink}
            >
              www.cdl-pearson.com
            </a>{" "}
            (en adelante la Plataforma), la utilización e ingreso a plataformas
            y productos digitales, el ingreso a las instalaciones de PEARSON
            HISPANOAMÉRICA y/o la aceptación expresa o inequívoca de la presente
            Política, implican la aceptación de los Titulares de la Política de
            Privacidad y Protección de datos Personales y su autorización para
            los usos y tratamientos que aquí se describen.
          </p>
        </section>
        <section className={classes.section}>
          <h3 className={classes.titleWithNumber}>1. Propósito</h3>
          <p className={classes.paragraph}>
            Teniendo en cuenta el derecho de cualquier persona de conocer,
            actualizar y rectificar los datos personales que existan sobre ella
            en bancos de datos o archivos de entidades públicas o privadas,
            PEARSON HISPANOAMÉRICA, en busca del interés de los Titulares,
            establece esta Política de Privacidad unificada para la región de
            Hispanoamérica. Se entiende que la región de Hispanoamérica
            comprende los siguientes países:
          </p>
          <b>
            Colombia, México, Argentina, Ecuador, Bolivia, Uruguay, Paraguay,
            Chile, Perú, Costa Rica, Honduras, República Dominicana, El
            Salvador, Guatemala, Panamá y Nicaragua
          </b>
          <p className={classes.paragraph}>
            En desarrollo de lo anterior, PEARSON HISPANOAMÉRICA es responsable
            de proteger y garantizar los derechos de los Titulares de los datos
            personales que recopila y usa en sus actividades en estos países.
          </p>
        </section>
        <section className={classes.section}>
          <h3 className={classes.titleWithNumber}>2. Quiénes somos</h3>{" "}
          <p className={classes.paragraph}>
            PEARSON es un grupo empresarial multinacional de servicios y
            contenidos educativos, con presencia en más de 60 países alrededor
            del mundo. Para los efectos de esta Política de Privacidad y de
            Protección de Datos Personales, las siguientes sociedades serán
            entendidas, como PEARSON HISPANOAMÉRICA:
          </p>
          <ol className={classes.romanList}>
            <li className={classes.romanItem}>
              Pearson Educación de México S.A.
            </li>
            <li className={classes.romanItem}>
              Pearson Educación de Perú S.A.
            </li>
            <li className={classes.romanItem}>Pearson Education S.A.</li>
            <li className={classes.romanItem}>
              Pearson Education S.A. sucursal Uruguay
            </li>
            <li className={classes.romanItem}>
              Pearson Education S.A. sucursal Bolivia
            </li>
            <li className={classes.romanItem}>
              Pearson Education S.A. sucursal Paraguay
            </li>
            <li className={classes.romanItem}>
              Pearson Educación de Colombia S.A.
            </li>
            <li className={classes.romanItem}>Longmaned Ecuador S.A.</li>
            <li className={classes.romanItem}>
              Pearson Educación de Chile Limitada
            </li>
          </ol>
          <p className={classes.paragraph}>
            Cuando en esta Política se haga referencia a PEARSON, se entenderá
            que se trata de las sociedades que forman parte de PEARSON
            HISPANOAMÉRICA, Pearson Education Inc., Pearson PLC, y las empresas
            de su grupo empresarial, es decir, todas las empresas que sean
            propiedad o estén controladas por Pearson PLC.
          </p>
          <p className={classes.paragraph}>
            Se entiende que estas empresas únicamente serán referidas de forma
            unificada como PEARSON o PEARSON HISPANOAMÉRICA para los efectos de
            esta Política, pero en ningún momento podrán ser consideradas como
            grupo empresarial, como una única entidad en términos jurídicos o
            como solidariamente responsables por las obligaciones adquiridas
            aquí adquiridas. La responsabilidad de cada una de las sociedades
            estará sujeta a las leyes aplicables en su jurisdicción.
          </p>
        </section>
        <section className={classes.section}>
          <h3 className={classes.titleWithNumber}>3. Definiciones</h3>
          <p className={classes.paragraph}>
            <b>Autorización: </b>Consentimiento previo, expreso e informado del
            Titular del dato para llevar a cabo el tratamiento.
          </p>
          <p className={classes.paragraph}>
            <b>Consulta: </b>Solicitud del Titular del dato o las personas
            autorizadas por éste o por la ley para conocer la información que
            reposa sobre ella en bases de datos o archivos.
          </p>
          <p className={classes.paragraph}>
            <b>Dato personal: </b>Cualquier información vinculada o que pueda
            asociarse a una o varias personas naturales determinadas o
            determinables. Estos datos se clasifican en sensibles, públicos,
            privados y semiprivados.
          </p>
          <p className={classes.paragraph}>
            <b>Encargado del tratamiento: </b>Persona que realiza el tratamiento
            de datos por cuenta del Responsable del tratamiento.
          </p>
          <p className={classes.paragraph}>
            <b> Productos o plataformas digitales: </b>Cualquier herramienta
            digital de contenidos educativos dispuesta o creada por PEARSON,
            incluyendo, pero sin limitarse a Podcasts, Blogs, Videos,
            aplicaciones, Webinars, bibliotecas virtuales, entre otros.
          </p>
          <p className={classes.paragraph}>
            <b>Reclamo: </b> Solicitud del Titular del dato o las personas
            autorizadas por éste o por la ley para corregir, actualizar o
            suprimir sus datos personales.
          </p>
          <p className={classes.paragraph}>
            <b>Responsable del tratamiento: </b> Persona que decide sobre, entre
            otras, la recolección y fines del tratamiento. Puede ser, a título
            de ejemplo, la empresa dueña de las bases de datos o sistema de
            información que contiene datos personales.
          </p>
          <p className={classes.paragraph}>
            <b>Titular del dato: </b> Es la persona natural a que se refieren
            los datos.
          </p>
          <p className={classes.paragraph}>
            <b>Tratamiento: </b> Cualquier operación o conjunto de operaciones
            sobre datos personales como, entre otros, la recolección, el
            almacenamiento, el uso, la circulación o supresión de esa clase de
            información.
          </p>
          <p className={classes.paragraph}>
            <b>Transmisión: </b> Tratamiento de datos personales que implica la
            comunicación de los mismos a nivel nacional o internacional y que
            tiene por objeto la realización de un tratamiento por el Encargado
            por cuenta o en nombre del responsable.
          </p>
          <p className={classes.paragraph}>
            <b>Transferencia: </b> Envío de datos personales que realiza el
            Responsable o el Encargado a un Responsable que se encuentra dentro
            o fuera del país de origen de la información.
          </p>
        </section>
        <section className={classes.section}>
          <h3 className={classes.titleWithNumber}>
            4. Responsable del tratamiento
          </h3>
          <p className={classes.paragraph}>
            El responsable del Tratamiento será el encargado de decidir, entre
            otras, sobre la recolección y fines del tratamiento de los datos
            personales. Para los efectos de esta Política, el responsable del
            Tratamiento será PEARSON HISPANOAMÉRICA, a través de sus sociedades,
            subsidiarias o filiales, según sea el caso. Para conocer quién será
            el responsable para el tratamiento de los datos de cada
            jurisdicción, así como las reglas particulares aplicables a los
            titulares de dichas jurisdicciones, podrán consultar los enlaces a
            las Políticas particulares que se incluyen en el numeral 13.
          </p>
        </section>
        <section className={classes.section}>
          <h3 className={classes.titleWithNumber}>5. Autorización</h3>
          <p className={classes.paragraph}>
            La autorización de los Titulares de Datos Personales es requisito
            indispensable para que PEARSON HISPANOAMÉRICA recolecte, almacene,
            consulte, use, procese y en general dé otros tipos de Tratamiento a
            su información personal. Esta autorización será otorgada por el
            Titular en forma escrita, oral o a través de conductas inequívocas
            del Titular que permitan concluir de forma razonable que otorgó la
            autorización.
          </p>
        </section>
        <section className={classes.section}>
          <h3 className={classes.titleWithNumber}>
            6. Tratamiento de los datos
          </h3>
          <p className={classes.paragraph}>
            Los Titulares autorizan a PEARSON HISPANOAMÉRICA el Tratamiento de
            sus datos para las siguientes finalidades:
          </p>
          <ol className={classes.lettersList}>
            <li className={classes.letterItem}>
              Incluir los datos personales, actuales o los que se incorporen en
              un futuro, en las bases de datos de PEARSON, así como para su
              utilización y tratamiento automatizado o no, para la gestión y
              registro de todas las actividades que realice.
            </li>
            <li className={classes.letterItem}>
              Compartir datos con terceros y ser utilizados por éstos para
              llevar a cabo campañas de publicidad, marketing, estudios de
              mercado, ofertas de bienes y servicios.
            </li>
            <li className={classes.letterItem}>
              Garantizar el ejercicio de los derechos como afiliado, proveedor,
              cliente y/o usuario de los servicios ofrecidos por PEARSON.
            </li>
            <li className={classes.letterItem}>
              Lograr una eficiente comunicación relacionada con los servicios,
              programas, alianzas, estudios, concursos, rifas, sorteos,
              promociones, para facilitarle el acceso general a la información
              de éstos y demás actividades relacionadas con las funciones
              propias de PEARSON.
            </li>
            <li className={classes.letterItem}>
              Dar cumplimiento a obligaciones contraídas con los clientes y/o
              usuarios, proveedores y empleados e informar sobre modificaciones,
              cambios o novedades de los servicios.
            </li>
            <li className={classes.letterItem}>
              Actualizar información referente a datos personales que se
              encuentren en las bases de datos.
            </li>
            <li className={classes.letterItem}>
              Enviar la información a entidades gubernamentales,
              administrativas, judiciales o autoridad competente que lo solicite
              formalmente en cumplimiento de sus funciones, y conforme a las
              estipulaciones constitucionales y legales.
            </li>
            <li className={classes.letterItem}>
              Enviar y recibir mensajes con fines comerciales, publicitarios y/o
              de atención al cliente, así como todos los relacionados y/o con
              ocasión de una relación legal y/o contractual existente.
            </li>
            <li className={classes.letterItem}>
              Realizar investigaciones de mercados para analizar, evaluar y
              generar datos estadísticos, indicadores y demás sobre la gestión
              de las actividades, servicios y/o productos que ofrece PEARSON.
            </li>
            <li className={classes.letterItem}>
              Recolectar datos para el cumplimiento de los deberes que como
              responsable de la información y de los datos personales le
              corresponde a PEARSON HISPANOAMÉRICA en ejercicio de todas sus
              actividades.
            </li>
            <li className={classes.letterItem}>
              Cumplir las obligaciones del Registro Nacional de las bases de
              datos en las jurisdicciones que lo requiera
            </li>
            <li className={classes.letterItem}>
              Cualquier otra finalidad que resulte en el desarrollo del contrato
              o de la relación legal que existe entre las personas naturales
              afiliadas y demás grupos de interés.
            </li>
          </ol>
        </section>
        <section className={classes.section}>
          <h3 className={classes.titleWithNumber}>
            7. Datos recabados por Pearson Hispanoamérica
          </h3>
          <p className={classes.paragraph}>
            Los datos personales recabados por PEARSON HISPANOAMÉRICA pueden
            incluir su nombre, información de contacto (números de teléfono,
            direcciones de correo electrónico y otras direcciones), su fecha de
            nacimiento, trabajo, información sobre cursos, sus intereses
            personales, información de tarjetas de crédito o facturación,
            selecciones de compra y datos sobre las páginas que visita y otra
            actividad que realiza en el Plataforma, así como cualquier otro dato
            personal que usted proporcione. Así, dependiendo de su relación con
            PEARSON (por ejemplo, usuario, cliente, proveedor, contratista,
            intermediario o bien cualquier otra relación comercial o civil,
            asociada al ejercicio de la actividad de PEARSON), la información
            personal recopilada puede incluir su nombre, dirección, número de
            ip, correo electrónico, teléfono, sexo, estado civil, entorno
            familiar, fecha de nacimiento, contraseñas, metadatos de navegación,
            datos relacionados con el dispositivo electrónico utilizado
            (teléfono móvil, computadora, tableta, entre otros), información de
            uso de su dispositivo electrónico y comportamiento, datos de
            geolocalización, nivel educativo, registros de actividad, historial
            laboral o educativa, información financiera, habilidades y
            experiencia, licencias profesionales y afiliaciones, discapacidades,
            así como su número de identificación personal, número de pasaporte,
            número de identificación tributaria u otro número de identificación
            que se considere como público o esté disponible al público mediante
            mecanismos de consulta físicos o electrónicos, u otra información
            sensible como información biométrica o historial de litigios para el
            proceso de prevención, detección e investigación de fraudes. También
            se puede obtener información sensible si voluntariamente se
            proporciona por el Titular del dato (por ejemplo, si expresa sus
            preferencias con respecto al Tratamiento médico con base en sus
            creencias religiosas).
          </p>
          <p className={classes.paragraph}>
            Por la naturaleza de esta información, expresamente entiendo y
            acepto, al momento de aceptar esta Política, que estoy dando
            autorización y consentimiento informado a efectos que PEARSON
            HISPANOAMÉRICA pueda recolectar y usar dicha información personal
            que se califique como sensible dentro de los parámetros y criterios
            fijados en esta Política. Lo anterior sin perjuicio de que esto
            resulte necesario de conformidad con el régimen legal aplicable, en
            la jurisdicción donde se otorgue el consentimiento expreso para
            Tratar otra información calificada legalmente como sensible.
          </p>
        </section>
        <section className={classes.section}>
          <h3 className={classes.titleWithNumber}>
            8. Período de vigencia de las bases de datos
          </h3>
          <p className={classes.paragraph}>
            Las Bases de Datos a las cuales dará Tratamiento PEARSON
            HISPANOAMÉRICA, así como los Datos Personales debidamente
            autorizados incorporados en ellas estarán vigentes durante el plazo
            necesario para cumplir sus finalidades. Una vez cumplido este plazo,
            PEARSON HISPANOAMÉRICA procederá a eliminar la base de datos o
            eliminar los datos de los titulares, según correspondiere.
          </p>
        </section>
        <section className={classes.section}>
          <h3 className={classes.titleWithNumber}>9. Derechos del titular</h3>
          <p className={classes.paragraph}>
            Los Titulares tienen los siguientes derechos, los cuales pueden
            ejercer en cualquier momento:
          </p>
          <ol className={classes.listNumber}>
            <li className={classes.numberItem}>
              Conocer los Datos Personales sobre los cuales PEARSON
              HISPANOAMÉRICA está realizando el Tratamiento. De igual manera, el
              Titular puede solicitar en cualquier momento que sus datos sean
              actualizados o rectificados, por ejemplo, si encuentra que sus
              datos son parciales, inexactos, incompletos, fraccionados,
              induzcan a error, o aquellos cuyo Tratamiento esté expresamente
              prohibido o no haya sido autorizado.
            </li>
            <li className={classes.numberItem}>
              Solicitar a PEARSON HISPANOAMÉRICA la supresión de sus Datos
              Personales y/o revocar la autorización otorgada para el
              Tratamiento de los mismos, mediante la presentación de un reclamo,
              de acuerdo con los procedimientos establecidos en la Política de
              Protección de Datos Personales de PEARSON HISPANOAMÉRICA. No
              obstante, la solicitud de supresión de la información y la
              revocatoria de la autorización no procederán cuando el Titular de
              la información tenga un deber legal o contractual de permanecer en
              la Base de Datos y/o Archivos, ni mientras se encuentre vigente la
              relación entre el Titular y PEARSON HISPANOAMÉRICA, en virtud de
              la cual fueron recolectados sus datos.
            </li>
            <li className={classes.numberItem}>
              Acceder de forma gratuita a sus Datos Personales objeto de
              Tratamiento.
            </li>
          </ol>
        </section>
        <section className={classes.section}>
          <h3 className={classes.titleWithNumber}>
            10. Medidas de seguridad para la protección de los datos personales
            y otra información
          </h3>
          <p className={classes.paragraph}>
            PEARSON HISPANOAMÉRICA tomará las precauciones razonables para
            mantener segura la información personal y para exigir a cualquier
            tercero que maneje o procese Datos Personales por encargo de PEARSON
            HISPANOAMÉRICA que haga lo mismo. El acceso a la información
            personal está razonablemente restringido para evitar accesos no
            autorizados, modificaciones o usos indebidos, y su tratamiento será
            realizado únicamente por personas vinculadas a PEARSON
            HISPANOAMÉRICA o encargadas expresamente para ello
          </p>
        </section>
        <section className={classes.section}>
          <h3 className={classes.titleWithNumber}>
            11. Procedimiento de consultas, rectificaciones y reclamos
          </h3>
          <p className={classes.paragraph}>
            Cualquier consulta, rectificación o reclamo relacionado con los
            datos Personales que reposan en las bases de datos de PEARSON podrá
            dirigirse al correo electrónico:
          </p>
          <ul className={classes.listDash}>
            <li className={classes.listDashItem}>
              <span className={classes.dash}>-</span> Para Colombia:
              <a
                href="mailto: proteccion.datoscolombia@pearson.com"
                target="_blank"
                rel="noreferrer"
                className={classes.decoratedLink}
              >
                proteccion.datoscolombia@pearson.com
              </a>
            </li>
            <li className={classes.listDashItem}>
              <span className={classes.dash}>-</span> Para el resto de los
              países de PEARSON HISPANOAMÉRICA:
              <a
                href="mailto: aviso.privacidad@pearson.com"
                target="_blank"
                rel="noreferrer"
                className={classes.decoratedLink}
              >
                aviso.privacidad@pearson.com
              </a>
            </li>
          </ul>
        </section>
        <section className={classes.section}>
          <h3 className={classes.titleWithNumber}>
            12. Transferencias y transmisiones nacionales o internacionales de
            datos
          </h3>
          <p className={classes.paragraph}>
            Teniendo en cuenta que PEARSON es un grupo empresarial
            multinacional, es posible que PEARSON HISPANOAMÉRICA tenga que
            transferir información personal de los Titulares a Responsables en
            diferentes países, a sociedades de su mismo grupo empresarial o
            terceros. Al aceptar la presente Política el Titular autoriza y da
            su consentimiento expreso para dicha Transferencia a cualquier país
            o territorio.
          </p>
          <p className={classes.paragraph}>
            PEARSON HISPANOAMÉRICA puede tratar, compartir, transmitir,
            entregar, suministrar, transferir o divulgar la información
            personal, dentro y fuera del territorio donde se ha dado la
            autorización, conforme con las reglas del numeral 5, de acuerdo con
            las finalidades legales, contractuales con el titular, así como con
            las enunciadas en el numeral 6 de la presente Política, siempre y
            cuando se cuente con la autorización del titular y la posibilidad
            legal para hacerlo conforme con el régimen legal aplicable al
            titular. PEARSON HISPANOAMÉRICA podrá transferir o transmitir los
            datos personales asociados a la presente política.
          </p>
          <ol className={classes.lettersList}>
            <li className={classes.letterItem}>
              En el desarrollo de la comercialización, mercadeo, cumplimiento de
              los contratos, para lo cual PEARSON HISPANOAMÉRICA puede poner la
              información personal a disposición de terceros que ayuden al
              desarrollo misional de PEARSON HISPANOAMÉRICA y a otros agentes,
              representantes designados, socios comerciales e instituciones
              financieras, sociedades de valores y otros socios comerciales. En
              cualquiera de esos casos, PEARSON HISPANOAMÉRICA tomará las
              medidas necesarias para que éstos conozcan y se comprometan a
              observar esta Política, bajo el entendido de que la información
              personal que reciban únicamente podrá ser utilizada para asuntos
              directamente concernientes a la relación comercial con PEARSON
              HISPANOAMÉRICA y solamente mientras ésta esté vigente y no podrá
              ser usada o destinada para propósito o fin diferente.
            </li>
            <li className={classes.letterItem}>
              Terceros externos proveedores de servicios, tales como proveedores
              de servicios de centros de llamadas; proveedores de servicios de
              sistemas de tecnología de la información, apoyo, almacenamiento y
              alojamiento de la información; proveedores de servicios de
              impresión, publicidad, comercialización e investigación de mercado
              y análisis; terceros administradores de reclamaciones; proveedores
              de gestión de documentos y registros; traductores, y otros
              vendedores y proveedores de servicios externos que presten su
              colaboración para llevar a cabo las actividades comerciales de
              PEARSON HISPANOAMÉRICA. En cualquiera de esos casos y sin
              perjuicio de la obligación de observar el secreto profesional en
              los casos que así corresponda, PEARSON HISPANOAMÉRICA tomará las
              medidas necesarias para que esos proveedores de servicios conozcan
              y se comprometan a observar esta Política, bajo el entendido de
              que la información personal que reciban únicamente podrá ser
              utilizada para asuntos directamente concernientes a la relación
              comercial con PEARSON HISPANOAMÉRICA y solamente mientras ésta
              esté vigente y no podrá ser usada o destinada para propósito o fin
              diferente.
            </li>
            <li className={classes.letterItem}>
              PEARSON HISPANOAMÉRICA también podrá intercambiar información
              personal con autoridades gubernamentales o públicas de otro tipo y
              terceros participantes en procedimientos legales cuando sea
              necesario o apropiado para: (i) cumplir con las leyes vigentes;
              (ii) cumplir con procesos jurídicos, incluyendo reclamaciones
              asociadas con esta política de privacidad; (iii) responder las
              solicitudes de las autoridades públicas; (iv) hacer cumplir
              nuestros términos y condiciones; (v) proteger nuestras
              operaciones; (vi) proteger nuestros derechos, privacidad,
              seguridad o propiedad o los de terceros aliados; y (vii) obtener
              las indemnizaciones aplicables o limitar los daños y perjuicios
              que nos puedan afectar.
            </li>
            <li className={classes.letterItem}>
              PEARSON HISPANOAMÉRICA también podrá compartir información
              personal con los aliados estratégicos del negocio de acuerdo con
              lo que se establezca en los contratos o acuerdos celebrados con
              dichos Aliados.
            </li>
            <li className={classes.letterItem}>
              PEARSON HISPANOAMÉRICA podrá compartir información personal con
              beneficiarios, proveedores de emergencia (bomberos, policía y
              servicios médicos de emergencia), minoristas, redes de médicos,
              organizaciones y proveedores, operadores de viaje, centrales de
              riesgo, las agencias de informes de crédito, y otras personas
              involucradas en un incidente que sea objeto de una reclamación,
              así como compradores y potenciales compradores u otras partes de
              una reorganización, fusión, venta, empresa conjunta, cesión,
              transferencia, o cualquier otra transacción, real o propuesta, en
              relación con la totalidad o parte de nuestro negocio, activos o
              acciones.
            </li>
            <li className={classes.letterItem}>
              PEARSON HISPANOAMÉRICA podrá compartir información personal
              compartida por los Titulares de la información, en muros de
              mensajes, grupos de debate, páginas de perfiles y blogs y en otros
              servicios del Plataforma en los cuales puede publicarse
              información y materiales de PEARSON. Debe tenerse en cuenta que la
              información que sea publicada o revelada a través de estos
              servicios se convertirá en información pública y podría estar
              disponible para los visitantes del Plataforma y para el público en
              general.
            </li>
          </ol>
          <p className={classes.paragraph}>
            En el desarrollo de lo anterior, PEARSON HISPANOAMÉRICA cuenta con
            la autorización otorgada por cualquiera de los medios previstos para
            tal fin en la jurisdicción aplicable, de tal manera que el Titular
            autoriza que PEARSON HISPANOAMÉRICA realice transmisiones nacionales
            e internacionales de Datos Personales a un Encargado, para permitir
            que el Encargado realice el Tratamiento por cuenta de PEARSON
            HISPANOAMÉRICA. Estas transmisiones no requerirán ser informadas al
            Titular ni contar con un consentimiento expreso adicional, salvo que
            así lo definan las reglas aplicables en cada jurisdicción conforme
            con el numeral 13 de la presente política.
          </p>
        </section>
        <section className={classes.section}>
          <h3 className={classes.titleWithNumber}>
            13. Regulaciones especiales de cada jurisdicción
          </h3>
          <p className={classes.paragraph}>
            La presente Política de Privacidad y Protección de Datos Personales
            aplica para toda la región de Hispanoamérica, específicamente para
            los países enunciados en el numeral 1 y de este documento. Sin
            embargo, y con el fin de dar cumplimiento a las regulaciones
            especiales de cada jurisdicción, el tratamiento de los datos
            personales estará sujeto a la Política de Privacidad establecida
            para cada una de las jurisdicciones que exigen requisitos
            adicionales y/o particulares:{" "}
          </p>
          <a
            href="https://plc.pearson.com/corporate-policies?_gl=1*1a2k9xr*_ga*NDkxODk2Mzk2LjE2Mzk1MjgwMzU.*_ga_K8MHCWK5EH*MTY1OTQ0MjIwMy40My4wLjE2NTk0NDIyMDguMA..#business-partner-global-content-editorial-policy"
            target="_blank"
            rel="noreferrer"
            className={classes.decoratedLink}
          >
            https://plc.pearson.com/corporate-policies
          </a>
          {/* <table className={classes.table}>
            <tbody>
              <tr>
                <td className={classes.tableItem}>
                  <h4 className={classes.subTitle}>
                    Política de Privacidad Chile
                  </h4>
                  <a
                    href="http://www.pearson.cl/Politica_privacidad_Chile.pdf"
                    target="_blank"
                    rel="noreferrer"
                    className={classes.decoratedLink}
                  >
                    http://www.pearson.cl/Politica_privacidad_Chile.pdf
                  </a>
                </td>
              </tr>
              <tr>
                <td className={classes.tableItem}>
                  <h4 className={classes.subTitle}>
                    Política de Privacidad Perú
                  </h4>
                  <a
                    href="http://pearsonperu.pe/politicas/Pearson_Politica_de_Privacidad_Peru.pdf"
                    target="_blank"
                    rel="noreferrer"
                    className={classes.decoratedLink}
                  >
                    http://pearsonperu.pe/politicas/Pearson_Politica_de_Privacidad_Peru.pdf
                  </a>
                </td>
              </tr>
              <tr>
                <td className={classes.tableItem}>
                  <h4 className={classes.subTitle}>
                    Política de Privacidad Argentina
                  </h4>
                  <a
                    href="http://www.pearson.com.ar/politicas/Pearson_Politica_de_Privacidad.pdf"
                    target="_blank"
                    rel="noreferrer"
                    className={classes.decoratedLink}
                  >
                    http://www.pearson.com.ar/politicas/Pearson_Politica_de_Privacidad.pdf
                  </a>
                </td>
              </tr>
              <tr>
                <td className={classes.tableItem}>
                  <h4 className={classes.subTitle}>
                    Política de Privacidad Colombia
                  </h4>
                  <a
                    href="Https://www.pearsonenespanol.com/co/POLITICA_de_tratamiento_de_datos_COLOMBIA.pdf"
                    target="_blank"
                    rel="noreferrer"
                    className={classes.decoratedLink}
                  >
                    Https://www.pearsonenespanol.com/co/POLITICA_de_tratamiento_de_datos_COLOMBIA.pdf
                  </a>
                </td>
              </tr>
              <tr>
                <td className={classes.tableItem}>
                  <h4 className={classes.subTitle}>
                    Política de Privacidad México
                  </h4>
                  <a
                    href="https://www.pearsonenespanol.com/MX/Aviso_de_privacidad_Mexico.pdf"
                    target="_blank"
                    rel="noreferrer"
                    className={classes.decoratedLink}
                  >
                    https://www.pearsonenespanol.com/MX/Aviso_de_privacidad_Mexico.pdf
                  </a>
                </td>
              </tr>
            </tbody>
          </table> */}
        </section>
        <section className={classes.section}>
          <h3 className={classes.titleWithNumber}>
            14. Consultas y reclamos para las jurisdicciones que no tengan
            regulaciones especiales aplicables
          </h3>
          <p className={classes.paragraph}>
            Los Titulares o sus causahabientes podrán consultar la información
            personal del Titular que repose en cualquier base de datos, en poder
            de PEARSON HISPANOAMÉRICA y PEARSON HISPANOAMÉRICA, en su condición
            de Responsable y/o Encargado del tratamiento, deberá suministrar a
            éstos toda la información contenida en el registro individual o que
            esté vinculada con la identificación del Titular. La consulta se
            podrá formular por el medio habilitado por PEARSON HISPANOAMÉRICA.
          </p>
          <p className={classes.paragraph}>
            Verificada la identidad del Titular, PEARSON HISPANOAMÉRICA
            suministrará los Datos Personales requeridos conforme con el término
            máximo definidos en la Ley aplicable contados a partir de la fecha
            de recibo de la misma. Cuando no fuere posible atender la consulta
            dentro de dicho término, se le informará expresando los motivos de
            la demora y señalando la fecha en que se atenderá su consulta.
          </p>
          <p className={classes.paragraph}>
            Así mismo, como Titular si consideras que la información contenida
            en una base de datos que se encuentre en poder de PEARSON debe ser
            objeto de actualización, corrección o supresión, o cuando adviertan
            el presunto incumplimiento de cualquiera de los deberes contenidos
            en la Ley aplicable según la sección 13, podrás presentar un reclamo
            ante PEARSON HISPANOAMÉRICA en su calidad de Responsable y/o
            Encargada del Tratamiento.
          </p>
        </section>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          style={{ marginBottom: "40px" }}
        >
          <input
            type="checkbox"
            id="agree"
            name="agree"
            checked={accepted}
            onChange={accept}
          />
          <label htmlFor="agree">He leído los términos y condiciones.</label>
          <br></br>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container justifyContent="center" alignItems="center">
          <Button
            className={classes.BotonContinuar}
            onClick={handleConfirm}
            disabled={!accepted}
          >
            Aceptar
          </Button>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default TermsAndConditionsHispanoamerica;
