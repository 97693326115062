import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
     Contenedor:{
        background:'#E9FBF9',
        width:'100vw',
        overflowX:'clip',
        [theme.breakpoints.down(1161)]:{
            background:'none',
           overflowX:'unset',
        },
        [theme.breakpoints.down(600)]:{
            marginTop: '79.51px',
        }
    },
  ContainerInfoLectura: {
    position: "relative",
    height: "86px",
    [theme.breakpoints.down(502)]:{
        height: "43px",
    },
    background: "#76D4C3",
    "& .BarraInfoLectura": {
      height: "86px",
      background: `Url(https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Barras/Azul.png)`,
        [theme.breakpoints.down(502)]:{
            height: "43px",
        },
    },
    "& .InfoLectura": {
      position: "absolute",
      height: "86px",
      width: "219px",
      boxSizing: "border-box",
      padding: "17.2px 0",
      background: "#FAFAFA",
      borderRadius: "50px",
      boxShadow: "2px 2px 2px 2px #00000029",
      zIndex: "4",
        [theme.breakpoints.down(502)]:{
            height: "43px",
            width: "109px",
            padding: "8.6px 0",
            borderRadius: "25px",
        },
    },
    "& .InfoLectura:before": {
      position: "absolute",
      display: "flex",
      boxSizing: "border-box",
      padding: "0 0 0 10px",
      justifyContent: "center",
      alignItems: "center",
      height: "39.88px",
      width: "39.88px",
      borderRadius: "50%",
      content: '""',
      boxShadow: "3px 0px .5px 0px #00000029",
      background: "#FAFAFA",
      left: "90px",
      top: "65px",
      transform: "rotate(0.25turn)",
      [theme.breakpoints.down(502)]:{
        left: "45px",
        top: "30px",
        height: "20.88px",
        width: "20.88px",
        },
    },
    "& .InfoLectura:after": {
      content: '""',
      height: "15px",
      width: "15px",
      borderTop: "2px solid #20A698",
      borderLeft: "2px solid #20A698",
      position: "absolute",
      bottom: "-10px",
      left: "Calc(50% - 8px)",
      transform: "rotate(0.625turn)",
      [theme.breakpoints.down(502)]:{
        height: "10px",
        width: "10px",
        left: "Calc(50% - 4.5px)",
        bottom: "-3px",
        borderTop: "1px solid #20A698",
        borderLeft: "1px solid #20A698",
        },
    },
    "& .Sesion": {
      borderLeft: "2px solid #D8D8D8",
    },
    "& .Dato": {
      margin: "0 0 5px 0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#20A698",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        margin: "0 0 2.5px 0",
        },
    },
    "& .Caracter": {
      margin: "0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#000000",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        },
    },   },
    ContenedorLectura:{
        background:'#fff',
        width:'1160px',
        height:'100%',
        padding:'60px 168px 35px ',
        [theme.breakpoints.down(1161)]:{
            width:'502px',
            padding:'60px 0 0 0',
          },
        [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
        '& .ContainerDerechos':{
            bottom:'80px',
            [theme.breakpoints.down(1161)]:{
                display:'none',
            },
        },
        '& .Derechos':{
            textAlign:"center",
            margin:"0px",
            font:" 15px lato",
            color:"#717476",
        },
        '& .Escritorio':{
            display:'block',
            [theme.breakpoints.down(1161)]:{
                display:'none'
            },
        },
        '& .Tablet':{
            display:'none',
            [theme.breakpoints.down(1161)]:{
                display:'block'
            },
        }
    },
    ContainerTitulo:{
        margin:'0 0 34px 0',
        [theme.breakpoints.down(1161)]:{
            margin:'0 0 34px 0',
        },
        '& .Titulo':{
            font:'48px fredoka one',
            letterSpacing: '0px',
            lineHeight:'58px',
            color: '#F6644B',
            margin:'0',
            textAlign:'center',
            [theme.breakpoints.down(1161)]:{
                font:'43px fredoka one',
                lineHeight:'52px',
            },
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            lineHeight : '30px',
            margin:'0 0 36px 0',
            font:'22px lato',
            color:'#000',
            letterSpacing: '0.20px',
            [theme.breakpoints.down(1161)]:{
                font:'18px lato',
                lineHeight :'28px',
                letterSpacing: '0.17px',
            },
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .Imagen':{
            [theme.breakpoints.down(1161)]:{
                width:'100%',
            },
        },  
      
    }
}));

const Podemos_mudarnos_a_otros_planetas = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
             <Grid container item justifyContent="center" className={classes.ContainerInfoLectura}>
               <Grid container className="BarraInfoLectura"></Grid>
               <Grid container item className="InfoLectura">
                    <Grid container item direction="column" xs={6} justifyContent="center">
                        <p className="Dato">Grado</p>
                        <p className="Caracter">5</p>
                    </Grid>
                    <Grid container item direction="column" xs={6} justifyContent="center" className="Sesion">
                        <p className="Dato">Sesión</p>
                        <p className="Caracter">33</p>
                    </Grid>
               </Grid>
            </Grid>

            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                        <h2 className="Titulo">¿Podemos mudarnos <br/>a otros planetas?</h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                       <p className='Lectura'>
                        Esta historia empieza hace unos 300 000 años en África. El protagonista es el ser
                        humano. Este personaje se levanta con algunas preocupaciones: debe cazar algo para 
                        comer, encontrar agua fresca para beber, protegerse de los abrasadores rayos del Sol 
                        y evitar ser devorado por algún animal tan hambriento como él.
                       </p>                   
                    </Grid>
                    <Grid container justifyContent='center'>
                        <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion33/2.png" alt="Tarjeta" />
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container >
                        <p className='Lectura'>
                            Tiene días buenos y otros que no lo son tanto. En ocasiones no encuentra comida ni 
                            agua. Entonces, empieza a caminar. A veces le parece que esta búsqueda nunca se 
                            acaba. Pero de tanto en tanto encuentra paisajes nuevos, con clima diferente y un 
                            menú distinto. <br/><br/>
                            
                            Cuando viaja, lo hace en grupo: así es más fácil sobrevivir. Cada lugar, cada alimento, 
                            cada pequeña belleza desconocida es un nuevo reto. Cuando los domina, se vuelven 
                            una nueva técnica. Y cuando se le acaban, tiene una nueva búsqueda. <br/><br/>
                            
                            Así lo hizo durante unos 200 000 años y terminó por conocer gran parte del planeta. 
                            El mundo le ha ofrecido las condiciones propicias para sobrevivir. Pero el ser humano 
                            lo ha transformado para su beneficio. <br/><br/>
                            
                            Su gran ingenio le ha permitido dejar de caminar y establecerse en un lugar. Allí 
                            puede producir su alimento, resguardarse de la lluvia, el calor, el frío, el viento y todo 
                            lo que lo pudiera afectarlo. Desarrolla medicamentos que alargan su vida. Ya no 
                            muere de una simple gripe.
                        </p>
                    </Grid>
                    <Grid container justifyContent='center'>
                        <Grid container justifyContent='center'>
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion33/3.png" alt="Tarjeta" />
                        </Grid>
                    </Grid>

                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container >
                        <p className='Lectura'>
                            El ser humano ha obtenido de la Tierra todo lo que ha necesitado. Tala los árboles 
                            para construir sus casas, barcos y muebles. Extrae el petróleo para mover sus 
                            vehículos, pues ha empezado a cansarse con cualquier caminata. Habituado ya a 
                            tomar el oro para elaborar joyas, se ha puesto a fabricar con él dispositivos 
                            electrónicos avanzados. Y de tanto aprovecharse de lo que le ofrece la naturaleza ha 
                            dejado al planeta muy lastimado. <br/><br/>
                            
                            Millones de voces le dicen que debe cuidar el lugar que lo vio crecer, pero no ha 
                            escuchado. Su ingenio es tan grande que ha salido de este planeta y ha contemplado 
                            su hogar desde fuera. Como explorador incansable y frío colonizador ha llegado a 
                            decirse: <br/><br/>
                            
                            —¡Qué importa si este planeta se acaba! Tan sólo en nuestra galaxia hay 1 000 
                            millones de estrellas rodeadas de planetas a los que nos podríamos mudar.
                        </p>
                    </Grid>
                    <Grid container justifyContent='center'>
                        <Grid container justifyContent='center'>
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion33/1.png" alt="Tarjeta" />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container >
                        <p className='Lectura'>
                            Algunos afirman que para que la humanidad sobreviva debemos dejar atrás este 
                            planeta, como si fuera desechable. Pero ¿cómo se mudarían 7 700 millones de 
                            personas? ¿A dónde irían? ¿Cuánto tiempo duraría esta travesía? <br/><br/>
                            
                            Los planetas vecinos no son templados. Tampoco hay en ellos el aire adecuado ni 
                            agua en estado líquido para criar animales y cultivar plantas. Por eso muchos han 
                            dirigido su mirada más allá del sistema solar, hacia los exoplanetas, en busca de 
                            lugares similares a la Tierra. La estrella más cercana es Alpha Centauri. 
                            Orbitando a su alrededor hay al menos un planeta que podría convertirse en un 
                            nuevo hogar: Kepler-22b, al que se ha llamado <em>el gemelo de la Tierra</em>. Quizá 
                            podríamos dirigirnos hacia él. Pero para transportar a toda la humanidad se tendrían 
                            que fabricar unas 100 000 naves espaciales. El ser humano no tiene aluminio, energía 
                            ni tiempo suficiente para abandonar el planeta antes de que acabe con él. <br/><br/>
                            
                            Digamos que fuera posible llegar a Kepler-22b. ¿Qué pasaría allí con el cuerpo humano, 
                            adaptado a la gravedad que la Tierra ejerce sobre él? Sin este abrazo, el ser humano 
                            no dormiría bien. Sus huesos y músculos se volverían débiles. ¿Para qué buscaría la 
                            humanidad otro hogar si tiene éste que le ofrece todo lo que necesita? ¿No sería 
                            mejor cuidarlo y seguir disfrutando su belleza? Si quisiéramos ser responsables, ¿qué 
                            posición deberíamos tomar ante las iniciativas que gastan recursos examinando y 
                            contemplando la posibilidad de mudarnos? ¡Reflexiónalo!
                        </p>
                    </Grid>
                </Grid>

            <Grid container item xs={12} justifyContent="center" className="ContainerDerechos">
                <p className="Derechos">
                    © 2022. Todos los derechos reservados, Pearson, Circuito de lectores.
                </p>                
            </Grid>
        </Grid>
    </Grid>
    );
}
 
export default Podemos_mudarnos_a_otros_planetas;
