import { Button, Grid, makeStyles } from "@material-ui/core";
import React, { useContext, useEffect } from "react";
import DrawerContext from "../../../../../usability/DrawerContext";
import { useOnLoadImages } from "../../../../hooks/useOnLoadImages";
import Loader from "../../../../Loader/Loader";
import Drawer from "../../ReadingTools/Drawer";

const useStyles = makeStyles((theme) => ({
  Contenedor: {
    background: "#E9FBF9",
    width: "100vw",
    [theme.breakpoints.down(1161)]: {
      background: "none",
    },
    [theme.breakpoints.down(600)]: {
      marginTop: "79.51px",
    },
  },
  ContainerInfoLectura: {
    position: "relative",
    height: "86px",
    [theme.breakpoints.down(502)]: {
      height: "43px",
    },
    background: "#76D4C3",
    "& .BarraInfoLectura": {
      height: "86px",
      background: `Url(https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Barras/Azul.png)`,
      [theme.breakpoints.down(502)]: {
        height: "43px",
      },
    },
    "& .InfoLectura": {
      position: "absolute",
      height: "86px",
      width: "219px",
      boxSizing: "border-box",
      padding: "17.2px 0",
      background: "#FAFAFA",
      borderRadius: "50px",
      boxShadow: "2px 2px 2px 2px #00000029",
      zIndex: "4",
      [theme.breakpoints.down(502)]: {
        height: "43px",
        width: "109px",
        padding: "8.6px 0",
        borderRadius: "25px",
      },
    },
    "& .InfoLectura:before": {
      position: "absolute",
      display: "flex",
      boxSizing: "border-box",
      padding: "0 0 0 10px",
      justifyContent: "center",
      alignItems: "center",
      height: "39.88px",
      width: "39.88px",
      borderRadius: "50%",
      content: '""',
      boxShadow: "3px 0px .5px 0px #00000029",
      background: "#FAFAFA",
      left: "90px",
      top: "65px",
      transform: "rotate(0.25turn)",
      [theme.breakpoints.down(502)]: {
        left: "45px",
        top: "30px",
        height: "20.88px",
        width: "20.88px",
      },
    },
    "& .InfoLectura:after": {
      content: '""',
      height: "15px",
      width: "15px",
      borderTop: "2px solid #20A698",
      borderLeft: "2px solid #20A698",
      position: "absolute",
      bottom: "-10px",
      left: "Calc(50% - 8px)",
      transform: "rotate(0.625turn)",
      [theme.breakpoints.down(502)]: {
        height: "10px",
        width: "10px",
        left: "Calc(50% - 4.5px)",
        bottom: "-3px",
        borderTop: "1px solid #20A698",
        borderLeft: "1px solid #20A698",
      },
    },
    "& .Sesion": {
      borderLeft: "2px solid #D8D8D8",
    },
    "& .Dato": {
      margin: "0 0 5px 0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#20A698",
      [theme.breakpoints.down(502)]: {
        font: "bold 12px lato",
        margin: "0 0 2.5px 0",
      },
    },
    "& .Caracter": {
      margin: "0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#000000",
      [theme.breakpoints.down(502)]: {
        font: "bold 12px lato",
      },
    },
    "& .Regresar": {
      color: "#F2624F",
      border: "#F2624F 1px solid",
      height: "30px",
      width: "100px",
      borderRadius: "20px",
      letterSpacing: "0.14px",
      textTransform: "none",
      background: "#FEFAF1",
      top: "calc(50% - 15px)",
      left: "5%",
      zIndex: 4,
      [theme.breakpoints.down(502)]: {
        font: "bold 10px lato",
        width: "60px",
      },
    },
  },
  ContenedorLectura: {
    background: "#fff",
    width: "1160px",
    height: "100%",
    padding: "60px 168px 35px ",
    
    transform: (props) =>
      props.scale > 1 ? `scale(${props.scale}) translate(0px, 10%)` : `none`,
    [theme.breakpoints.down(1161)]: {
      width: "502px",
      padding: "60px 0 0 0",
    },
    [theme.breakpoints.down(350)]: {
      width: "300px",
      padding: "30px 0px 0px ",
    },

    "& .Escritorio": {
      display: "block",
      [theme.breakpoints.down(1161)]: {
        display: "none",
      },
    },
    "& .Tablet": {
      display: "none",
      [theme.breakpoints.down(1161)]: {
        display: "block",
      },
    },
  },
  ContainerTitulo: {
    margin: "0 0 34px 0",
    [theme.breakpoints.down(1161)]: {
      margin: "0 0 34px 0",
    },
    "& .Titulo": {
      letterSpacing: "0px",
      color: "#F6644B",
      margin: "0",
      textAlign: "center",
      font: "48px fredoka one",
      lineHeight: "58px",
      [theme.breakpoints.down(1161)]: {
        font: "43px fredoka one",
        lineHeight: "52px",
      },
      [theme.breakpoints.down(502)]: {
        font: "30px fredoka one",
        lineHeight: "32px",
      },
      [theme.breakpoints.down(350)]: {
        font: "28px fredoka one",
        lineHeight: "32px",
      },
    },
  },
  Parrafo1: {
    margin: " 0 0 36px 0",
    "& .Lectura": {
      lineHeight: "30px",
      margin: "0 0 36px 0",
      font: "22px lato",
      color: "#000",
      letterSpacing: "0.20px",
      [theme.breakpoints.down(1161)]: {
        font: "18px lato",
        lineHeight: "28px",
        letterSpacing: "0.17px",
      },
      [theme.breakpoints.down(502)]: {
        font: "16px lato",
        lineHeight: "21px",
        letterSpacing: "0.09px",
      },
      [theme.breakpoints.down(350)]: {
        font: "12px lato",
        lineHeight: "17px",
        letterSpacing: "0.09px",
      },
    },
    "& .SubTitulo": {
      color: "#591AAF",
      margin: "0",
      font: "bold 30px lato",
      letterSpacing: ".28px",
      lineHeight: "30px",
      [theme.breakpoints.down(1161)]: {
        lineHeight: "28px",
        font: "bold 26px lato",
        letterSpacing: "0.24px",
      },
      [theme.breakpoints.down(502)]: {
        font: "bold 18px lato",
        lineHeight: "24px",
        letterSpacing: "0.21px",
      },
    },
    "& .TextImg": {
      font: "20px lato",
      color: "#717476",
      letterSpacing: ".19px",
      lineHeight: "30px",
      margin: "21px 0 0 0",
      [theme.breakpoints.down(1161)]: {
        font: " 16px lato",
        lineHeight: "25px",
        letterSpacing: "0.15px",
      },
      [theme.breakpoints.down(502)]: {
        font: " 11px lato",
        lineHeight: "14px",
        letterSpacing: "0.10px",
      },
    },
    "& .Imagen": {
      [theme.breakpoints.down(1161)]: {
        width: "100%",
      },
    },
  },
}));

const Lo_que_nuestros_ojos_no_pueden_ver_la_utilidad_del_microscopio = (
  props
) => {
  const { activeStep, setActiveStep, handlePreviewStep, id_actividad } = props;

  const handleContinue = () => {
    setActiveStep(activeStep - 1);
    handlePreviewStep();
  };

  const {
    handleSize,
    disabledZoom,
    closeZoom,
    handleZoom,
    scale,

    
  } = useContext(DrawerContext);
  const [loading, setLoading] = React.useState(true);
  const wrapperRef = React.useRef(null);
  const imagesLoaded = useOnLoadImages(wrapperRef);

  const detectSize = () => {
    const lectura = document.getElementById("lectura");
    const newWidth = lectura?.offsetWidth;
    const newHeight = lectura?.offsetHeight;
    setLoading(false);
    if (lectura) handleSize(newHeight, newWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", detectSize);
    return () => {
      handleZoom();
      window.removeEventListener("resize", detectSize);
      closeZoom();
    };
  }, []);

  const classes = useStyles({ disabledZoom, scale });
  return (
    <Grid
      container
      item
      justifyContent="center"
      xs={12}
      className={classes.Contenedor}
    >
      <Grid
        container
        item
        justifyContent="center"
        className={classes.ContainerInfoLectura}
      >
        <Grid container className="BarraInfoLectura">
          <Button className="Regresar" onClick={handleContinue}>
            Regresar
          </Button>
        </Grid>
        <Grid container item className="InfoLectura">
          <Grid
            container
            item
            direction="column"
            xs={6}
            justifyContent="center"
          >
            <p className="Dato">Grado</p>
            <p className="Caracter">4</p>
          </Grid>
          <Grid
            container
            item
            direction="column"
            xs={6}
            justifyContent="center"
            className="Sesion"
          >
            <p className="Dato">Sesión</p>
            <p className="Caracter">4</p>
          </Grid>
        </Grid>
      </Grid>
      {!imagesLoaded && <Loader />}
      <Grid
        container
        item
        className={classes.ContenedorLectura}
        justifyContent="center"
        id="lectura"
        onLoad={detectSize}
                    ref={wrapperRef}
      >
        <Drawer id_lectura={id_actividad} />
        <Grid
          container
          item
          sm={12}
          className={classes.ContainerTitulo}
          justifyContent="center"
        >
          <h2 className="Titulo">
            Lo que nuestros ojos <br className="Tablet" />
            no pueden ver,
            <br className="Escritorio" /> la utilidad <br className="Tablet" />
            del microscopio
          </h2>
        </Grid>
        <Grid
          container
          item
          sm={12}
          className={classes.Parrafo1}
          justifyContent="center"
        >
          <Grid container sm={12}>
            <p className="Lectura">
              <h3 className="SubTitulo">Las características del ojo humano</h3>
              <br />
              Para todos los seres vivos es importante obtener información del
              medio que los rodea para sobrevivir. La mayoría de esa información
              la descubres gracias a tus ojos. Ellos están bien desarrollados,
              ya que pueden distinguir casi un millón de colores, aunque hay
              animales que ven mucho mejor que los humanos, como los búhos ¡que
              ven hasta 10 veces mejor!
              <br />
              <br />
              Hay cosas que no puedes ver, y que los mosquitos y las mariposas
              sí, como la luz infrarroja o los rayos ultravioleta. También
              existen objetos demasiado grandes o demasiado pequeños, difíciles
              de identificar a simple vista. Sobre la piel de un adulto y dentro
              de su cuerpo viven entre dos y tres kilogramos de bacterias. El
              número de bacterias que hay en el cuerpo humano puede superar al
              número de células. <br />
              <br />
              <h3 className="SubTitulo">La invención del microscopio</h3>
              <br />
              Los seres humanos supieron de la existencia de las bacterias hasta
              hace 300 años. Un vendedor de telas en Holanda quiso ver si las
              telas que tenía eran de buena calidad, entonces decidió raspar
              unos vidrios y ver los hilos a través de ellos. Los hilos pudieron
              verse más grandes gracias al raspado y él los observó mejor.
              Después de eso tuvo la idea de ver agua de lago con los vidrios,
              para saber si estaba limpia. Cuando lo hizo, vio pequeños seres
              moviéndose rápidamente en las gotas de agua y los llamó{" "}
              <em>animálculos</em>. <br />
              <br />
              La invención del microscopio ayudó a conocer más acerca de los
              seres vivos. En un inicio los microscopios estaban construidos por
              una sola lente y funcionaban como las lupas; estos son
              considerados microscopios simples. Después, se construyeron
              microscopios en los que se usaron dos o más lentes y así se
              pudieron ver mejor las cosas, por esto se llamaron{" "}
              <em>microscopios compuestos</em>.
            </p>
          </Grid>
          <Grid container justifyContent="center" sm={12}>
            <Grid container justifyContent="center" sm={12}>
              <img
                className="Imagen"
                src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img4to/Sesion04/1.png"
                alt="Tarjeta"
              />
            </Grid>
            <Grid container justifyContent="center" sm={12}>
              <p className="TextImg">
                Hans y Zacharias Jensen fueron quienes pulieron las primeras
                lentes, pero se reconoce como inventor del microscopio al
                holandés Anton van Leeuwenhoek. La palabra <em>microscopio</em>{" "}
                viene del griego <em>micros</em>, que significa “pequeño”, y{" "}
                <em>skopein</em>, que quiere decir “observar”. Se denominó
                <em> microorganismos</em> a los pequeños seres vivos que se
                descubrieron gracias al microscopio.
              </p>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          item
          sm={12}
          className={classes.Parrafo1}
          justifyContent="center"
        >
          <Grid container sm={12}>
            <p className="Lectura">
              La observación de los organismos microscópicos ha mejorado
              conforme han mejorado los microscopios. En la actualidad, los
              microscopios electrónicos modernos son más potentes y han
              permitido conocer a los virus, que son cientos o miles de veces
              más pequeños que las bacterias.
              <br />
              <br />
              <h3 className="SubTitulo">
                Diversidad dentro de los microorganismos
              </h3>
              <br />
              El grupo de los microorganismos incluye a las bacterias, algunos
              hongos, algas y protozoarios. Muchos de ellos viven en tu cuerpo y
              te ayudan a hacer varias funciones, como digerir lácteos y
              producir vitaminas. Otras bacterias, hongos y protozoarios pueden
              causar enfermedades, pero no todo es negativo, ya que el ser
              humano ha encontrado la forma de usarlos en su beneficio y de
              utilizarlos para elaborar algunos alimentos, como el pan, el queso
              y los yogurts.
            </p>
          </Grid>
          <Grid container justifyContent="center" sm={12}>
            <Grid container justifyContent="center" sm={12}>
              <img
                className="Imagen"
                src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img4to/Sesion04/2.png"
                alt="Tarjeta"
              />
            </Grid>
            <Grid container justifyContent="center" sm={12}>
              <p className="TextImg">
                Los microorganismos están en todas partes y cumplen diversas
                funciones en el ambiente. Se ha comprobado que pueden vivir en
                lugares sumamente fríos como los icebergs, en agua salada, en
                las profundidades del océano e incluso en los reactores
                nucleares.
              </p>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          item
          sm={12}
          className={classes.Parrafo1}
          justifyContent="center"
        >
          <Grid container sm={12}>
            <p className="Lectura">
              Ahora ya conoces un poco más sobre la vida microscópica que hay a
              tu alrededor y sabes que existen microorganismos, aunque no puedas
              verlos a simple vista. Sabes también que gracias a la invención
              del microscopio se conoce que participan en muchos procesos
              esenciales dentro del cuerpo humano, así como en el medio
              ambiente.
            </p>
          </Grid>
        </Grid>
      </Grid>
      <Grid container item style={{ marginTop: 80 }}></Grid>
    </Grid>
  );
};

export default Lo_que_nuestros_ojos_no_pueden_ver_la_utilidad_del_microscopio;
