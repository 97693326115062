import React  from "react";
import { makeStyles,Grid}   from '@material-ui/core';
import NavBar               from '../../../../NavBar';
import MenuIzquierdo        from '../../../../MenuIzquierdo';
import Activity             from '../../Activity';
import Lectura              from '../../../LecturasActividad/LecturasSexto/Sesion56/Lectura';
import Question             from "../../Question";
import QuestionProv         from "../../QuestionProv";

import QuestionTable4       from "./QuestionTable4";
import QuestionTable5       from "./QuestionTable5";
import QuestionTable7       from "./QuestionTable7";
import useGetExcercisesData from "../../../../../hooks/useGetExcercisesData";

const useStyles = makeStyles(theme=>({
  grid1:{    
    zIndex:'3'
    
  },
  grid2:{    
    zIndex:'2'
    
  },
  grid3:{    
    boxSizing:'border-box',
    zIndex:'1',
    paddingLeft:'3%',
    [theme.breakpoints.down('md')]:{
      paddingLeft:'0px',
    }
    
  }
}))

const ActivityS56 = (props) => {
  const classes = useStyles()
  const activityId = 374;
  const { data, step, setStep, questionList, setQuestionList } =
    useGetExcercisesData(activityId);
  const renderQuestion = () => {
    switch (step) {
    
      case (3):
        return (
          <QuestionTable4/>
        )
        
      case (4):
        return (
          <QuestionTable5/>
        )
        
      case (6):
        return (
          <QuestionTable7/>
        )
        
      case (9):
        return (
          <QuestionProv
            step={step}
            pregunta={ questionList.length > 0 ? questionList[step].pregunta : "cargando..."}
          />
        )
        
      default:
        return(
          <Question
            step={step}
            pregunta={ questionList.length > 0 ? questionList[step].pregunta : "cargando..."}
          />
        )
    }
  }

  return(
    <Grid container >
      <Grid item className={classes.grid1} sm={12}> 
        <NavBar/>
      </Grid>
      <Grid item className={classes.grid2} sm={1}> 
        <MenuIzquierdo/>
      </Grid>
      <Grid item className={classes.grid3} sm={12}>
        <Activity
          step={step}
          setStep={setStep}
          questionList={questionList}
          setQuestionList={setQuestionList}
          questionComponent={renderQuestion()}
          Lectura={Lectura}
          enrollmendId={data && data.id_actividad_inscripcion}
          tryNumber={data && data.intento}
          // imageList={imageList}
          // picturesTable={picturesTable}
          activityNumber={activityId}
          toSession={'/session56'}
        />
      </Grid>
    </Grid>
  )
}

export default ActivityS56;
