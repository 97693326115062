import React, { useContext } from "react";
import clsx from "clsx";
import { useState } from "react";
import { Button, makeStyles } from "@material-ui/core";
import { useHistory } from "react-router-dom";

import Salir from "../../imgs/cerrar.png";
import flecha from "../../imgs/adelante.png";

import NivelUno from "../practicas_guiadas/NivelUno";
import NivelDos from "../practicas_guiadas/NivelDos";
import NivelTres from "../practicas_guiadas/NivelTres";

import ModalCredits from "../ModalCredits";

import UsabilityContext from "../../../usability/UsabilityContext";
import NavButton from "../../NavBar/NavButton";
import AuthContext from "../../../auth/AuthContext";
import axios from "../../../services/axios";
import types from "../../../types/types";

const useStyles = makeStyles((theme) => ({
  container: {
    position: "relative",
    width: "100%",
    justifyContent: "center",
    [theme.breakpoints.up(1024)]: {
      minWidth: "320px",
      justifyContent: "space-between",
    },
  },
  Barra: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "start",
    position: "fixed",
    maxWidth: "100vw",
    // overflow: "hidden",

    top: "80px",
    height: "calc(100vh - 80px)",
    // maxHeight: "1080px",
    backgroundColor: "rgb(14,94,126)",
    background:
      "linear-gradient(180deg, rgba(14,94,126,1) 15%, rgba(35,177,174,1) 75%)",
    padding: "0px",
    minWidth: "3%",

    [theme.breakpoints.down("md")]: {
      display: "flex",
      flexDirection: "column",
      position: "fixed",
      top: (props) => (props.openMenuInMobile ? "80px" : "-100%"),
      [theme.breakpoints.down(1161)]: {
        top: (props) => (props.openMenuInMobile ? "79.51px" : "-100%"),

        // opacity: (props) => (props.openMenuInMobile ? "100%" : "0"),
      },
      overflow: "auto",
      minHeight: "calc(100vh - 79.51px)",
      // minHeight: "0%",
      backgroundColor: "rgb(14,94,126)",
      background:
        "linear-gradient(180deg, rgba(14,94,126,1) 15%, rgba(35,177,174,1) 75%)",
      padding: "0px",
      // width: (props) => (props.isOpen ? '280px' : '3%'),
      minWidth: "320px",
      // width: (props) => (props.openInDesktop ? '100%' : '3%'),
      width: "100%",
    },
    [theme.breakpoints.up(1920)]: {
      top: (props) => (props.openMenuInDesktop ? "79.51px" : "79.51px"),
      // transition: "$slow 0.5s ease-out",
    },

    //
  },
  link: {
    background: "transparent",
    // padding: '5px',
    textDecoration: "none",
    // width: (props) => (props.open ? 280 : '100%'),
    with: "100%",
    justifyContent: "space-between",
  },
  botonmenu: {
    display: "flex",
    minWidth: "100%",
    minHeight: "49px",
    backgroundColor: "#156A8D",
    // borderBottom: " 4px solid",
    borderRadius: "0px",
    colors: "red",
    justifyContent: "space-between",
    [theme.breakpoints.down(1161)]: {
      justifyContent: "center",
    },
    "& .text": {
      textDecoration: "none",
      textTransform: "none",
      margin: "0 0 0 0%",
      color: "#FFFFFF",
      font: " 600 16px Lato;",
      // display: (props) => (props.open ? 'flex' : 'none'),
    },
    "&:hover": {
      backgroundColor: "#3E42AD",
    },
    "&:focus": {
      background: "#FFF8F7 ",
      "& .text": {
        color: "#707070",
      },
    },
  },
  Cerrar: {
    "& .close": {
      backgroundRepeat: "no-repeat",
      backgroundImage: `url(${Salir})`,
      backgroundSize: "100% 100%",
      height: "30px",
      width: "30px",
      "&:hover": {
        "&:before": {
          textTransform: "none",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "25px",
          position: "absolute",
          content: '"Cerrar sesión"',
          left: "105%",
          height: "16px",
          width: "110px",
          background: "#fff",
          font: "bold 14px lato",
        },
      },
    },
    borderBottom: " 0px",
    background: "#0F5F7F",
    width: (props) => (props.open ? 280 : "3%"),
    "&:hover": {
      background: "#D9DF61",
      "& .text": {
        color: "#575757",
      },
    },
    "& .text": {
      font: "bold 17px lato",
    },
  },
  Abrir: {
    justifyContent: "center",
    background: "none",
    "&:focus": {
      background: "none ",
    },
    borderBottom: " 0px",
    padding: "0",
  },
  menuy: {
    display: (props) => (props.open ? "none" : "flex"),
  },
  menun: {
    display: (props) => (props.open ? "flex" : "none"),
  },
  hamburguesa: {
    padding: "0px",
    height: "30%",
    width: "100%",
    display: "none",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.up("lg")]: {
      display: "flex",
    },

    "& .buttonHandleMenu": {
      boxSizing: "border-box",
      cursor: "pointer",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      border: "none",
      outline: "none",
      backgroundColor: "transparent",
      // justifyContent: (props) => (props.open ? 'flex-end' : 'center'),
      // paddingRight: (props) => (props.open ? '22px' : '0'),
      height: "60px",
      width: "100%",
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
    "& .cerrar": {
      transform: "rotate(0.50turn)",
    },
  },

  espacio: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    alignItems: "center",
    flexGrow: "1",
  },
  containerCerrarSesion: {
    display: "flex",
    flexGrow: "1",
    justifyContent: "center",
    alignItems: "flex-end",
    "& div": {
      width: "100%",
    },
  },
  BarraPracticas: {
    display: (props) => (props.openPracticas ? "flex" : "none"),
    flexWrap: "wrap",
    [theme.breakpoints.up("sm")]: {
      flexDirection: "column",
      position: "absolute",
      backgroundColor: "#3E42AD",
      display: (props) => (props.openPracticas ? "flex" : "none"),
      width: (props) => (props.openMenuInDesktop ? 280 : "100%"),
      left: (props) => (props.openMenuInDesktop ? 280 : "100%"),
      top: "80px",
    },
  },
  BarraGrados: {
    display: (props) => (props.openGrados ? "flex" : "none"),
    flexWrap: "wrap",
    // [theme.breakpoints.up("sm")]: {
    //   flexDirection: "column",
    //   position: "absolute",
    //   backgroundColor: "#3E42AD",
    //   display: (props) => (props.openPracticas ? "flex" : "none"),
    //   width: (props) => (props.openMenuInDesktop ? 280 : "100%"),
    //   left: (props) => (props.openMenuInDesktop ? 280 : "100%"),
    //   top: "148px",
    // },
  },
  navButton: {
    textTransform: "none",
  },
  Practicas1: {
    background: "#D04C97",
  },
  Practicas2: {
    background: "#E352A5",
  },
  Practicas3: {
    background: "#E264AD",
  },
}));

const MenuIzquierdo = () => {
  const {
    user: {
      name,
      firstLastName,
      secondLastName,
      userName,
      grade,
      userId,
      rol,
      schoolLogo,
    },
    dispatch,
  } = useContext(AuthContext);

  const {
    openMenuInDesktop,
    openMenuInMobile,
    handleMenuInDesktop,
    handleMenuInMobile,
  } = useContext(UsabilityContext);
  const [openPracticas, setOpenPracticas] = useState(false);
  const [openCredits, setOpenCredits] = useState(false);

  const [anchorEl1, setAnchorEl1] = React.useState(null);
  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const [anchorEl3, setAnchorEl3] = React.useState(null);
  const [relajacionList, setRelajacionList] = React.useState([]);
  const [sostenidaList, setSostenidaList] = React.useState([]);
  const [autogestList, setAutogestList] = React.useState([]);

  const notificacions = 1;
  let history = useHistory();

  const iOS = () => {
    return (
      [
        "iPad Simulator",
        "iPhone Simulator",
        "iPod Simulator",
        "iPad",
        "iPhone",
        "iPod",
      ].includes(navigator.platform) ||
      // iPad on iOS 13 detection
      (navigator.userAgent.includes("Mac") && "ontouchend" in document)
    );
  };

  const classes = useStyles({
    openMenuInDesktop,
    openPracticas,
    openMenuInMobile,
  });

  const handleLogout = (e) => {
    const isiOS = iOS();
    dispatch({
      type: types.logout,
    });
    localStorage.clear();
    setOpenPracticas(false);
    handleMenuInMobile();
    if (!isiOS) {
      const oldSesion = {
        userId,
        userName,
        name,
        firstLastName,
        secondLastName,
        rol,
        schoolLogo,
        grade,
      };
      localStorage.removeItem("oldSesion");
      localStorage.setItem("oldSesion", JSON.stringify(oldSesion));
      history.push(`/singin/${grade}`);
    } else {
      history.push(`/`);
    }
  };

  const practicaRelajacion = () => {
    let grado = 0;
    switch (grade) {
      case "primero":
        grado = 1;
        break;
      case "segundo":
        grado = 2;
        break;
      case "tercero":
        grado = 3;
        break;
      case "cuarto":
        grado = 4;
        break;
      case "quinto":
        grado = 5;
        break;

      default:
        grado = 6;
        break;
    }
    const body = {
      grado: 2,
    };
    axios
      .get(`/actividad/recursos/audio/relajacion?grado=${grado}`, body)
      .then((response) => {
        if (response.status === 200) {
          setRelajacionList(response.data.resultado.practica_guiada);
        }
      })
      .catch((error) => {
        console.log(error, "ERROR!");
      });
  };

  const practicaSostenida = () => {
    let grado = 0;
    switch (grade) {
      case "primero":
        grado = 1;
        break;
      case "segundo":
        grado = 2;
        break;
      case "tercero":
        grado = 3;
        break;
      case "cuarto":
        grado = 4;
        break;
      case "quinto":
        grado = 5;
        break;

      default:
        grado = 6;
        break;
    }
    const body = {
      grado: 2,
    };
    axios
      .get(`/actividad/recursos/audio/atencion-sostenida?grado=${grado}`, body)
      .then((response) => {
        if (response.status === 200) {
          setSostenidaList(response.data.resultado.practica_guiada);
        }
      })
      .catch((error) => {
        console.log(error, "ERROR!");
      });
  };

  const practicaAutogest = () => {
    let grado = 0;
    switch (grade) {
      case "primero":
        grado = 1;
        break;
      case "segundo":
        grado = 2;
        break;
      case "tercero":
        grado = 3;
        break;
      case "cuarto":
        grado = 4;
        break;
      case "quinto":
        grado = 5;
        break;

      default:
        grado = 6;
        break;
    }
    const body = {
      grado: 2,
    };
    axios
      .get(`/actividad/recursos/audio/autogestion?grado=${grado}`, body)
      .then((response) => {
        if (response.status === 200) {
          setAutogestList(response.data.resultado.practica_guiada);
        }
      })
      .catch((error) => {
        console.log(error, "ERROR!");
      });
  };

  const handleCloseSubMenu = () => {
    setOpenPracticas(false);
    handleMenuInMobile();
    if (openMenuInDesktop) handleMenuInDesktop();
  };
  const handleOpenPracticas = () => {
    setOpenPracticas(!openPracticas);
  };
  const handleOpen1 = () => {
    // handleMenuInMobile();
    // handleMenuInDesktop();
    // setOpenPracticas(false);
    // setOpenDialog1(true)
    setAnchorEl1(document.getElementById("practica1"));
    // setOpenGrados(true);
    practicaRelajacion();
  };

  const handleOpen2 = () => {
    // handleMenuInDesktop();
    // setOpenPracticas(false);
    // setOpenDialog2(true)
    setAnchorEl2(document.getElementById("practica2"));
    practicaSostenida();
  };

  const handleOpen3 = () => {
    // handleMenuInDesktop();
    // setOpenPracticas(false);
    // setOpenDialog3(true)
    setAnchorEl3(document.getElementById("practica3"));
    practicaAutogest();
  };

  const handleOpenCredits = () => {
    setOpenCredits(true);
  };

  // React.useEffect(() =>{
  //   setRelajacionLIst()
  // },[relajacionLIst])

  return (
    <nav className={classes.container}>
      <div className={classes.Barra}>
        <>
          <NavButton
            className={classes.navButton}
            title="Mapa"
            to="/guia/mapa"
            handler={handleCloseSubMenu}
            icon={"Mapa"}
            openMenuInDesktop={openMenuInDesktop}
            openMenuInMobile={openMenuInMobile}
          />

          <NavButton
            className={classes.navButton}
            title="Chat"
            notifications={notificacions}
            to="/chat"
            handler={handleCloseSubMenu}
            icon={"Chat"}
            openMenuInDesktop={openMenuInDesktop}
            openMenuInMobile={openMenuInMobile}
          />

          <NavButton
            className={classes.navButton}
            title="Prácticas guiadas"
            to=""
            handler={handleOpenPracticas}
            icon={"Practicas"}
            openMenuInDesktop={openMenuInDesktop}
            openMenuInMobile={openMenuInMobile}
          />
          <div className={classes.BarraPracticas}>
            <Button
              id="practica1"
              className={clsx(
                `${classes.Practicas} ${classes.botonmenu} ${classes.Practicas1} `
              )}
              onClick={handleOpen1}
            >
              <p className="text">Relajación</p>
              <div className={"practic"}></div>
            </Button>
            <Button
              id="practica2"
              className={clsx(
                `${classes.Practicas} ${classes.botonmenu} ${classes.Practicas2} `
              )}
              onClick={handleOpen2}
            >
              <p className="text">Atención sostenida</p>
              <div className={"practic"}></div>
            </Button>
            <Button
              id="practica3"
              className={clsx(
                `${classes.Practicas} ${classes.botonmenu} ${classes.Practicas3} `
              )}
              onClick={handleOpen3}
            >
              <p className="text">Autogestión</p>
              <div className={"practic"}></div>
            </Button>
          </div>
          <div className={classes.BarraGrados}>
            {relajacionList.length > 0 && (
              <NivelUno
                anchorEl={anchorEl1}
                setAnchorEl={setAnchorEl1}
                openPracticas={openPracticas}
                setOpenPracticas={setOpenPracticas}
                grade={grade}
                relajacionList={relajacionList.length > 0 && relajacionList}
              />
            )}
            {sostenidaList.length > 0 && (
              <NivelDos
                anchorEl={anchorEl2}
                setAnchorEl={setAnchorEl2}
                openPracticas={openPracticas}
                setOpenPracticas={setOpenPracticas}
                grade={grade}
                sostenidaList={sostenidaList}
              />
            )}

            {autogestList.length > 0 && (
              <NivelTres
                anchorEl={anchorEl3}
                setAnchorEl={setAnchorEl3}
                openPracticas={openPracticas}
                setOpenPracticas={setOpenPracticas}
                grade={grade}
                autogestList={autogestList}
              />
            )}
          </div>

          <NavButton
            classes={{ root: classes.navButton }}
            title="Biblioteca virtual"
            to="/Biblioteca"
            handler={handleCloseSubMenu}
            icon={"Lectura"}
            openMenuInDesktop={openMenuInDesktop}
            openMenuInMobile={openMenuInMobile}
          />
          <a
            href="https://pearson-cdl-reportes-qa.s3.amazonaws.com/CDL_ALUMNO_MANUAL.pdf"
            target="_blank"
            rel="noreferrer"
            download
          >
            <NavButton
              title="Manual de usuario"
              handler={handleCloseSubMenu}
              icon={"Manual"}
              openMenuInDesktop={openMenuInDesktop}
              openMenuInMobile={openMenuInMobile}
            />
          </a>
          <NavButton
            title="Créditos"
            to=""
            handler={handleOpenCredits}
            icon={"Creditos"}
            openMenuInDesktop={openMenuInDesktop}
            openMenuInMobile={openMenuInMobile}
          />
          <NavButton
            className={classes.navButton}
            title="Guía"
            notifications={notificacions}
            to="/guia/perfil"
            handler={handleCloseSubMenu}
            icon={"Manual"}
            openMenuInDesktop={openMenuInDesktop}
            openMenuInMobile={openMenuInMobile}
          />
          <div className={clsx(`${classes.menuy} ${classes.hamburguesa}`)}>
            <button
              className="buttonHandleMenu"
              onClick={() => {
                handleMenuInDesktop();
                handleCloseSubMenu();
              }}
            >
              <img
                src={flecha}
                alt=""
                className={openMenuInDesktop ? "cerrar" : ""}
              />
            </button>
          </div>
          <div
            className={clsx(`${classes.menun} ${classes.hamburguesa}`)}
          ></div>

          <div className={classes.containerCerrarSesion}>
            <NavButton
              title="Cerrar sesión"
              to=""
              handler={handleLogout}
              icon={"Cerrar"}
              openMenuInDesktop={openMenuInDesktop}
              openMenuInMobile={openMenuInMobile}
              id="Logout-btn"
            />
          </div>
          <ModalCredits open={openCredits} setOpen={setOpenCredits} />
        </>
      </div>
    </nav>
  );
};

export default MenuIzquierdo;
