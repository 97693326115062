import {
  createContext,
  useContext,
  useEffect,
  useReducer,
  useState,
} from "react";
import { chatInitialState, ChatReducer } from "./ChatReducer";

import types from "./types";

import { io } from "socket.io-client";
import AuthContext from "../../../../auth/AuthContext";
import { useParams } from "react-router-dom";
import currentEnvironment from "../../../../services/CONST";

const ChatContext = createContext();

const ChatProvider = ({ children }) => {
  const [state, dispatch] = useReducer(ChatReducer, chatInitialState);
  const { chats, online, socket } = state;

  // const { mensajes, chats, online, socket } = state;
  const {
    user: { name, userId, rol },
  } = useContext(AuthContext);
  const accessToken = localStorage.getItem("token");
  const { id } = useParams();

  const connectToSocket = () => {
    const socket = io(currentEnvironment.chat.baseURL, {
      // const socket = io("http://ec2-18-219-57-179.us-east-2.compute.amazonaws.com:3000", {
      transports: ["websocket", "polling", "flashsocket"],
      auth: {
        "x-token": accessToken,
      },
      reconnection: true,
    });
    dispatch({ type: types.connectToSocket, payload: socket });
  };
  const [filterFavorites, setFilterFavorites] = useState(false);

  const disconnectFromSocket = () => {
    socket?.disconnect();
    dispatch({ type: types.disconnectFromSocket, payload: null });
  };

  const connectToChat = (id) => {
    const sala = {
      sala: id,
    };
    removeAlert(id);
    setFilterFavorites(false);
    socket?.emit("entrarChat", sala, function (resp) {
      // console.clear();
      // console.log("Entraste al chat, usuarios conectados", resp);
      // updateUserOnlineList(resp);
    });
  };

  const leaveFromChat = (usuario) => {
    socket?.emit("salirChat", usuario, function (resp) {
      // console.log("Saliste del chat, usuarios conectados", resp);
      updateUserOnlineList(resp);
    });
  };

  const sendMessage = (newMessage = {}, id) => {
    const setMessage = {
      nombre_usuario: name,
      mensaje: newMessage.mensaje,
      sala: id,
      tipo: "texto",
      id_usuario: userId,
    };
    socket?.emit("crearMensaje", setMessage, function (mensaje) {
      // console.log("Mensaje enviado", setMessage);
      enviarAChatEspecifico(mensaje, id);
    });
  };

  const actualizeListChat = (newList) => {
    if (Array.isArray(newList)) {
      dispatch({
        type: types.actualizeChatList,
        payload: newList?.map((chat) => {
          return { ...chat, mensajes: [] };
        }),
      });
    } else {
      const list = [newList];
      dispatch({
        type: types.actualizeChatList,
        payload: list?.map((chat) => {
          return { ...chat, mensajes: [] };
        }),
      });
    }
  };

  const enviarAChatEspecifico = (mensaje) => {
    dispatch({
      type: types.sendToSpecificChat,
      payload: { mensaje, id_chat: mensaje?.sala },
    });
  };

  const removeAlert = (id) => {
    dispatch({ type: types.removeAlert, payload: id });
  };

  const addAlert = (id) => {
    dispatch({ type: types.addAlert, payload: id });
  };

  const loadChatHistory = (sala, historial, paginacion) => {
    dispatch({
      type: types.loadMessages,
      payload: {
        sala,
        historial: historial,
        paginacion,
      },
    });
  };

  const loadMoreChatHistory = (sala, historial, paginacion) => {
    dispatch({
      type: types.loadMoreMessages,
      payload: {
        sala,
        historial: historial,
        paginacion,
      },
    });
  };

  const consultMoreHistory = (pagina, sala, filtro) => {
    const params = {
      pagina,
      sala,
      filtro,
    };
    socket?.emit("historialChat", params, function (resp) {});
  };

  const updateUserOnlineList = (userConnect = {}) => {
    dispatch({ type: types.actualizeOnlineList, payload: userConnect });
  };

  const handleSearch = (pagina, sala, search) => {
    const params = {
      pagina,
      sala,
      filtro: search,
    };
    socket?.emit("historialChat", params, function (resp) {
      // console.log("solicitud de mas mensajes", params);
      // console.clear();
    });
  };

  const addMessageToFavorite = (mensaje) => {
    socket?.emit("mensajeFavoritoChat", mensaje, function (resp) {
      dispatch({ type: types.addMessageToFavorite, payload: mensaje });
      // console.log(resp);
    });
  };

  const consultFavorites = (sala, pagina) => {
    const params = {
      sala,
      pagina,
    };
    socket?.emit("historialFavoritosChat", params, function (resp) {
      if (resp?.paginacion?.pagina === 1) {
        loadChatHistory(resp?.sala, resp?.historial, resp?.paginacion);
      } else {
        loadMoreChatHistory(resp?.sala, resp?.historial, resp?.paginacion);
      }
    });
  };

  const handleFilterFavorites = () => {
    if (!filterFavorites) {
      loadChatHistory();
    }
    setFilterFavorites(!filterFavorites);
  };

  useEffect(() => {
    if (rol === "docente" || rol === "alumno") {
      if (!socket || socket?.connected === false) connectToSocket();
    } else {
      return null;
    }
    // connectToSocket();
    return () => {
      disconnectFromSocket();
    };
  }, [rol]);

  useEffect(() => {
    socket?.on("connect", function (resp) {});
    socket?.on("entrarChat", function (resp) {
      loadChatHistory(resp?.sala, resp?.historial);
    });
    socket?.on("listaPersonasChat", function (resp) {
      // console.log("Lista personas chat, con quien puedes chatear", resp);
      actualizeListChat(resp);
    });
    socket?.on("salirChat", function (resp) {
      // console.log("Usuarios conectados", resp);
      updateUserOnlineList(resp);
    });
    socket?.on("historialChat", function (resp) {
      if (resp?.paginacion?.pagina === 1) {
        loadChatHistory(resp?.sala, resp?.historial, resp?.paginacion);
      } else {
        loadMoreChatHistory(resp?.sala, resp?.historial, resp?.paginacion);
      }
    });
    socket?.on("mensajeFavoritoChat", function (resp) {
      if (resp?.paginacion?.pagina === 1) {
        loadChatHistory(resp?.sala, resp?.historial, resp?.paginacion);
      } else {
        loadMoreChatHistory(resp?.sala, resp?.historial, resp?.paginacion);
      }
    });
    return () => {
      socket?.on("disconnect", () => {
        // console.log("Desconectado");
      });
      socket?.disconnect();
    };
  }, [socket, id]);

  useEffect(() => {
    socket?.on("crearMensaje", function (mensaje) {
      // console.log("Mensaje recibido", mensaje);
      // console.log("Mensaje recibido", mensaje);
      addAlert(mensaje?.sala);
      enviarAChatEspecifico(mensaje);
    });
  }, [socket]);

  useEffect(() => {
    socket?.on("estatusUsuarios", function (resp) {
      // console.log("Estatus usuarios", resp);
      updateUserOnlineList(resp);

      // updateUserOnlineList(resp);
    });
  }, [socket]);

  const sendImage = (mensaje) => {
    socket?.emit("crearMensaje", mensaje, function (mensaje) {
      // console.log("Mensaje enviado", setMessage);
      enviarAChatEspecifico(mensaje, id);
    });
  };

  const sendDocument = (mensaje) => {
    socket?.emit("crearMensaje", mensaje, function (mensaje) {
      // console.log("Mensaje enviado", setMessage);
      enviarAChatEspecifico(mensaje, id);
    });
  };

  const data = {
    chats,
    sendMessage,
    connectToChat,
    leaveFromChat,
    enviarAChatEspecifico,
    removeAlert,
    online,
    sendImage,
    sendDocument,
    consultMoreHistory,
    handleSearch,
    socket,
    filterFavorites,
    handleFilterFavorites,
    addMessageToFavorite,
    consultFavorites,
    disconnectFromSocket,
  };
  return <ChatContext.Provider value={data}>{children}</ChatContext.Provider>;
};

export { ChatProvider };

export default ChatContext;
