import { Button, Grid, makeStyles } from "@material-ui/core";
import React, { useContext, useEffect } from "react";
import DrawerContext from "../../../../../usability/DrawerContext";
import { useOnLoadImages } from "../../../../hooks/useOnLoadImages";
import Loader from "../../../../Loader/Loader";
import Drawer from "../../ReadingTools/Drawer";

const useStyles =  makeStyles (theme =>({
     Contenedor:{
        background:'#E9FBF9',
        width:'100vw',
        overflowX:'clip',
        [theme.breakpoints.down(1161)]:{
            background:'none',
           overflowX:'unset',
        },
        [theme.breakpoints.down(600)]:{
            marginTop: '79.51px',
        }
    },
  ContainerInfoLectura: {
    position: "relative",
    height: "86px",
    [theme.breakpoints.down(502)]:{
        height: "43px",
    },
    background: "#76D4C3",
    "& .BarraInfoLectura": {
      height: "86px",
      background: `Url(https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Barras/Azul.png)`,
        [theme.breakpoints.down(502)]:{
            height: "43px",
        },
    },
    "& .InfoLectura": {
      position: "absolute",
      height: "86px",
      width: "219px",
      boxSizing: "border-box",
      padding: "17.2px 0",
      background: "#FAFAFA",
      borderRadius: "50px",
      boxShadow: "2px 2px 2px 2px #00000029",
      zIndex: "4",
        [theme.breakpoints.down(502)]:{
            height: "43px",
            width: "109px",
            padding: "8.6px 0",
            borderRadius: "25px",
        },
    },
    "& .InfoLectura:before": {
      position: "absolute",
      display: "flex",
      boxSizing: "border-box",
      padding: "0 0 0 10px",
      justifyContent: "center",
      alignItems: "center",
      height: "39.88px",
      width: "39.88px",
      borderRadius: "50%",
      content: '""',
      boxShadow: "3px 0px .5px 0px #00000029",
      background: "#FAFAFA",
      left: "90px",
      top: "65px",
      transform: "rotate(0.25turn)",
      [theme.breakpoints.down(502)]:{
        left: "45px",
        top: "30px",
        height: "20.88px",
        width: "20.88px",
        },
    },
    "& .InfoLectura:after": {
      content: '""',
      height: "15px",
      width: "15px",
      borderTop: "2px solid #20A698",
      borderLeft: "2px solid #20A698",
      position: "absolute",
      bottom: "-10px",
      left: "Calc(50% - 8px)",
      transform: "rotate(0.625turn)",
      [theme.breakpoints.down(502)]:{
        height: "10px",
        width: "10px",
        left: "Calc(50% - 4.5px)",
        bottom: "-3px",
        borderTop: "1px solid #20A698",
        borderLeft: "1px solid #20A698",
        },
    },
    "& .Sesion": {
      borderLeft: "2px solid #D8D8D8",
    },
    "& .Dato": {
      margin: "0 0 5px 0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#20A698",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        margin: "0 0 2.5px 0",
        },
    },
    "& .Caracter": {
      margin: "0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#000000",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        },
    },
       '& .Regresar':{
        color: '#F2624F',
        border: '#F2624F 1px solid',
        height: '30px',
        width:'100px',
        borderRadius: '20px',
        letterSpacing: '0.14px',
        textTransform: 'none',
        background:'#FEFAF1',
        top:'calc(50% - 15px)',
        left:'5%',
        zIndex:4,
        [theme.breakpoints.down(502)]: {
          font:'bold 10px lato',
          width: "60px",
        },
      },
  },
    ContenedorLectura:{
        background:'#fff',
        width:'1160px',
        height:'100%',
                padding:'60px 168px 35px ',
        
        transform: (props) =>
        props.scale > 1 ? `scale(${props.scale}) translate(0px, 10%)` : `none`,
        [theme.breakpoints.down(1161)]:{
            width:'502px',
            padding:'60px 0 0 0',
          },
        [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },

        '& .Escritorio':{
            display:'block',
            [theme.breakpoints.down(1161)]:{
                display:'none'
            },
        },
        '& .Tablet':{
            display:'none',
            [theme.breakpoints.down(1161)]:{
                display:'block'
            },
        }
    },
    ContainerTitulo:{
        margin:'0 0 34px 0',
        [theme.breakpoints.down(1161)]:{
            margin:'0 0 34px 0',
        },
        '& .Titulo':{
            font:'48px fredoka one',
            letterSpacing: '0px',
            lineHeight:'58px',
            color: '#823CE2',
            margin:'0',
            textAlign:'center',
            [theme.breakpoints.down(1161)]:{
                font:'43px fredoka one',
                lineHeight:'52px',
            },
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            lineHeight : '30px',
            margin:'0 0 36px 0',
            font:'22px lato',
            color:'#000',
            padding:'0 0 0 13px',
            letterSpacing: '0.20px',
            [theme.breakpoints.down(1161)]:{
                font:'18px lato',
                lineHeight :'28px',
                letterSpacing: '0.17px',
            },
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .SubTitulo':{
            font:'bold 30px lato',
            color:'#FFFFFF',
            letterSpacing:'.28px',
            lineHeight:'30px',
            margin:'0',
            [theme.breakpoints.down(1161)]:{
                lineHeight :'28px',
                font:'bold 26px lato',
                letterSpacing: '0.24px',
            },
            [theme.breakpoints.down(502)]:{
                font:'bold 18px lato',
                lineHeight :'24px',
                letterSpacing: '0.21px',
            },
        },
        '& .Morado':{
            boxSizing:'border-box',
            padding:'0 0 0 13px',
            alignItems:'center',
            margin:' 0 0 34px 0',
            display:'flex',
            height:'46px',
            width:'55px',
            borderRadius: '11px 11px 35px 11px',
            background:'#B167E6',
        },
        '& .Esp':{
            margin:'34px 0 0 0'
        },
        '& .Imagen':{
            padding:'0 0 0 25px',
            [theme.breakpoints.down(1161)]:{
                padding:'0 0 0 13px',
                width:'100%',
            },
        },  
      
    }
}));

const Mundos = (props) => {

  const {activeStep, setActiveStep, handlePreviewStep, id_actividad} = props
  
  const handleContinue = () => {
    setActiveStep(activeStep - 1)
    handlePreviewStep()
  }

    const {
      handleSize,
    disabledZoom,
    closeZoom,
    handleZoom,
    scale
  }= useContext(DrawerContext);
  const [loading, setLoading] = React.useState(true);
  const wrapperRef = React.useRef(null);
  const imagesLoaded = useOnLoadImages(wrapperRef);

  const detectSize = () => {
    const lectura = document.getElementById("lectura");
    const newWidth = lectura?.offsetWidth;
    const newHeight = lectura?.offsetHeight;
    setLoading(false);
    if (lectura) handleSize(newHeight, newWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", detectSize);
    return () => {
      handleZoom()
      window.removeEventListener("resize", detectSize);
      closeZoom()
    };
  }, []);

  const classes = useStyles({ disabledZoom,scale });
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
             <Grid container item justifyContent="center" className={classes.ContainerInfoLectura}>
                       <Grid container className="BarraInfoLectura">
            <Button className="Regresar" onClick={handleContinue}>
                Regresar
            </Button>
        </Grid>
               <Grid container item className="InfoLectura">
                    <Grid container item direction="column" xs={6} justifyContent="center">
                        <p className="Dato">Grado</p>
                        <p className="Caracter">5</p>
                    </Grid>
                    <Grid container item direction="column" xs={6} justifyContent="center" className="Sesion">
                        <p className="Dato">Sesión</p>
                        <p className="Caracter">51</p>
                    </Grid>
               </Grid>
            </Grid>
            {!imagesLoaded && <Loader />}
        <Grid
            container
            item
            className={classes.ContenedorLectura}
            justifyContent="center"
            id="lectura"
            onLoad={detectSize}
            ref={wrapperRef}
        >
<Drawer id_lectura={id_actividad} />
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                        <h2 className="Titulo">Mundos</h2>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid xs={12} container >
                    <Grid xs={12} >
                        <div className='Morado'>
                            <h3 className='SubTitulo'>I</h3>
                        </div>
                    </Grid>
                    <Grid container sm={12}>
                        <p className='Lectura'>
                            ¿Recuerdas aquella ocasión en la que rompiste el florero por jugar a la pelota
                            adentro de la casa? Seguro aún no has olvidado esa lluvia de cristales al estrellarse 
                            con el suelo, golpeando tus oídos como el granizo en la ventana. Les llaman <em>añicos</em> a lo 
                            que terminó siendo el florero. Lo sabes porque, semanas después, te encontraste la 
                            palabra en el diccionario. “Como el florero de la abuela”, pensaste. Desde entonces 
                            las tres sílabas te vienen a la cabeza cada tanto, con su eñe de en medio.<br/><br/>
                            
                            El florero era una reliquia familiar que la abuela conservaba de sus padres. <br/><br/>

                            —Perdón, abuela —le dijiste. <br/><br/>
                            
                            —No pasa nada —te contestó, triste. <br/><br/>
                            
                            ¿Por qué no te culpaba y, aun así, estaba tan triste? Supongo que no querías pensar 
                            en eso. ¿Qué hace uno cuando comete un error y no encuentra palabras para seguir 
                            disculpándose? En todo caso, tú corriste a tu habitación, a tu refugio, a olvidarlo todo. 
                        </p>
                       
                    </Grid>
                    <Grid xs={12} >
                        <div className='Morado'>
                            <h3 className='SubTitulo'>II</h3>
                        </div>
                    </Grid>
                        <p className='Lectura'>
                            Frida entró en su habitación buscando cualquier actividad que pudiera hacerla sentir 
                            mejor. Recorrió toda la habitación con los ojos, pero el florero regresaba a su mente 
                            acompañado de unas terribles ganas de llorar. <br/><br/>
                            
                            Mecánicamente, sacó la tableta que guardaba en el cajón del escritorio. Ninguna 
                            aplicación la atraía (todas las conocía demasiado bien ya), pero al final encontró una 
                            llamada <em>Mundos</em>. No recordaba haberla descargado. Entonces, sin pensarlo, ingresó. <br/><br/>

                            La aplicación consistía en crear distintos mundos y personajes que habitaran en ellos.<br/><br/>
                            
                            —A ver si esto me distrae —pensó. <br/><br/>
                            
                            Comenzó a explorar. Inició con colores. Agregó verde y el escenario se llenó de pasto.
                            Usó azul para formar el cielo. Se dejó llevar por toda la gama hasta formar el 
                            escenario del mundo. Con el recuerdo de sus padres creó una casa y comenzó a 
                            llenarla de emociones, sentimientos, deseos y recuerdos. La memoria de sus amigas 
                            del colegio le permitió crear toda clase de juegos al interior de la casa. Su miedo a las 
                            alturas introdujo un tobogán gigante y su miedo a las arañas fundió un foco.                  

                        </p>
                    </Grid>
                    <Grid container justifyContent='center'>
                        <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion51/1.png" alt="Tarjeta" />
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid xs={12} container >
                        <p className='Lectura'>
                            Mientras más tiempo pasaba en <em>Mundos</em>, más elementos llenaban la casa. Era 
                            momento de poblarla.<br/><br/> 
                            
                            El primero en aparecer fue su gato, que representaba la amistad. Después creó a sus 
                            padres, que simbolizaban amor, fortaleza y confianza. Su hermano pequeño era allí la 
                            alegría y la ternura, pero también el enojo, porque a veces era demandante y 
                            berrinchudo. Faltaba su abuela. Pensó mucho antes de llevarla a la casa, no porque 
                            no quisiera, sino porque aún se sentía mal por lo que había pasado. Entonces respiró.
                            Una serie de recuerdos enternecedores la envolvieron para crear al ser maravilloso 
                            que siempre le había brindado seguridad, atención y cuidado.  
                        </p>

                        <Grid xs={12} >
                        <div className='Morado'>
                            <h3 className='SubTitulo'>III</h3>
                        </div>
                        </Grid>
                        <p className='Lectura'>
                            La familia estaba casi completa, sólo faltaba Frida. Dudó mucho antes de dar 
                            cualquier dato para que <em>Mundos</em> la creara. Se percató, por primera vez en su vida, de 
                            que nunca se había detenido a pensar quién era ella. Como no lo sabía, decidió crear 
                            una versión de Frida que tuviera sólo lo que ella considerara mejor. Reuniendo todas 
                            sus fuerzas, presionó “Guardar cambios”. El mundo estaba completo. Una paz 
                            maravillosa la envolvió. En esa aplicación que alguien había instalado para ella podía 
                            ser como quisiera. Ya no necesitaba nada, allí podía hacerlo todo. Se dirigió con 
                            seguridad y soltura a la estancia de su casa virtual en ese mundo recién creado, pero 
                            a su paso volvió a romper el florero de la abuela. Vio la lluvia de cristales estrellarse 
                            con el suelo y el sonido golpeó sus tímpanos como el granizo en la ventana.               
                        </p>
                        
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid xs={12} container >
                        <Grid container justifyContent='center' className='Imagen'>
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion51/2.png" alt="Tarjeta" />
                        </Grid>
                        <p className='Lectura Esp'>
                            Frida rompió a llorar y dejó la tableta a un lado. Cerró los ojos e intentó pensar en qué quería decirle a su 
                            abuela. Quería hablar. Anhelaba encontrar aquello que deseaba que su abuela escuchara para que pudiera 
                            entenderla de verdad y decirle que todo estaba bien, como siempre hacía.            
                        </p>
                        
                    </Grid>
                </Grid>
        </Grid>
        <Grid container item style={{ marginTop: 80}} ></Grid>
    </Grid>
    );
}
 
export default Mundos;
