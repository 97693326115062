import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
   ContenedorLectura:{
        width:'502px',
         [theme.breakpoints.down(502)]:{
           width:'350px',
         },
   },
    ContainerTitulo:{
        margin:'0 0 46px 0',
        '& .Titulo':{
            letterSpacing: '0px',
            color: '#26A9E0',
            margin:'0',
            textAlign:'center',
            font:'43px fredoka one',
            lineHeight:'52px',
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            margin:'0 0 36px 0 ',
            color:'#000',
            font:'18px lato',
            lineHeight :'28px',
            letterSpacing: '0.17px',
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .SubTitulo':{
            color:'#F6664D',
            margin:'0',
            font:'bold 22px lato',
            lineHeight :'24px',
            letterSpacing: '0.21px',
            [theme.breakpoints.down(502)]:{
                font:'bold 18px lato',
                lineHeight :'20px',
                letterSpacing: '0.16px',
            },
        },
        '& .TextImg':{
            margin:'24px 0 0 0',
            alignSelf:'flex-start',
            color:'#717476',
            font:' 16px lato',
            lineHeight :'25px',
            letterSpacing: '0.15px',
            [theme.breakpoints.down(502)]:{
                font:' 11px lato',
                lineHeight :'14px',
                letterSpacing: '0.10px',
            },
        },
        '& .Imagen':{
            width:'100%'
        },

    },
    

}));

const Problemas_sociales_derivados_de_la_pandemia_en_américa_latina = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>

            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >
                        Problemas sociales<br/> derivados de la<br/> pandemia<br/> en América Latina
                    </h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                            La pandemia por Covid-19 ha causado millones de muertes y ha agravado algunos 
                            problemas sociales. Ningún país está libre de ellos, pero hay partes del mundo que se 
                            han visto más afectadas que otras. Entre las primeras se encuentra América Latina, 
                            que ha sido la región en desarrollo más dañada por la pandemia.<br/><br/>
                            
                            La Comisión Económica para América Latina y el Caribe (<span style={{fontVariant: "all-small-caps"}}>CEPAL</span>) anunció que en esta  
                            zona vive 8.4 % de la población mundial, y ha registrado 32.5 % de las muertes por 
                            Covid-19. Esto quiere decir que la cantidad de fallecimientos ha sido alta para el 
                            número de personas que viven ahí.<br/><br/> 
                             
                            Las medidas sanitarias agudizaron problemas sociales como la pobreza y el 
                            desempleo. Pero no todos tuvieron los mismos daños. Por ejemplo, en términos de 
                            grupos étnicos, las comunidades indígenas y las afrodescendientes han tenido más 
                            estragos que las demás. Con respecto al género, el impacto ha sido mayor en las 
                            mujeres que en los hombres. Finalmente, la edad. Los niños y adolescentes 
                            enfrentaron más consecuencias negativas.<br/><br/>
                            
                            <h3 className='SubTitulo'>Pobreza y hambre</h3><br/>
                            
                            La <span style={{fontVariant: "all-small-caps"}}>CEPAL</span>, la Organización de la Naciones Unidas para la Alimentación y la  
                            Agricultura, y otros organismos internacionales alertaron sobre el aumento de la 
                            pobreza extrema en America Latina y el Caribe. Consideran que el número de 
                            personas en dichas condiciones podría llegar a 86 millones. El padecimiento de 
                            hambre es la consecuencia directa de ese hecho.
                        </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <Grid container justifyContent='center' >
                            <img className='Imagen' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img4to/Sesion37/1.png"  alt="Tarjeta" />
                        </Grid>
                        <Grid container item justifyContent='center' >
                            <p className='TextImg'>La población que vive en pobreza y pobreza extrema en Latinoamérica creció rápidamente durante la pandemia.</p>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} >
                    <p className="Lectura ">
                        Los organismos internacionales afirman que la emergencia sanitaria podría 
                        ocasionar una crisis alimentaria. Se estima que los grupos más afectados serían los 
                        niños menores de cinco años y las mujeres embarazadas y en lactancia.<br/><br/> 
                        
                        <h3 className='SubTitulo'>Desempleo</h3><br/>

                        La ampliación general del desempleo es otro de los efectos negativos. La 
                        Organización Internacional del Trabajo calculó que 26 millones de individuos 
                        perdieron su trabajo durante 2020. Asimismo, señaló que el grupo más grande 
                        fueron los jóvenes de entre 18 y 29 años. Se espera un progreso lento en este campo, 
                        como consecuencia de la paulatina recuperación de la economía.
                    </p>
                </Grid>
        </Grid>
    </Grid>
    );
}
 
export default Problemas_sociales_derivados_de_la_pandemia_en_américa_latina;
