import React, { useContext } from "react";
import clsx from "clsx";
import { useState } from "react";
import { makeStyles } from "@material-ui/core";
import Salir from "../../imgs/cerrar.png";

import { useHistory } from "react-router-dom";
import flecha from "../../imgs/adelante.png";
import UsabilityContext from "../../../usability/UsabilityContext";
import NavButton from "../../NavBar/NavButton";
import AuthContext from "../../../auth/AuthContext";

import ModalCredits from "../ModalCredits";
import types from "../../../types/types";

const useStyles = makeStyles((theme) => ({
  container: {
    position: "relative",
    width: "100%",
    justifyContent: "center",
    [theme.breakpoints.up(1024)]: {
      minWidth: "320px",
      justifyContent: "space-between",
    },
  },
  Barra: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "start",
    position: "fixed",

    top: "80px",
    height: "calc(100vh - 80px)",
    backgroundColor: "rgb(14,94,126)",
    background:
      "linear-gradient(180deg, rgba(14,94,126,1) 15%, rgba(35,177,174,1) 75%)",
    padding: "0px",
    minWidth: "3%",

    [theme.breakpoints.down("md")]: {
      display: "flex",
      flexDirection: "column",
      position: "fixed",
      top: (props) => (props.openMenuInMobile ? "80px" : "-100%"),
      [theme.breakpoints.down(1161)]: {
        top: (props) => (props.openMenuInMobile ? "79.51px" : "-100%"),
      },
      overflow: "auto",
      minHeight: "calc(100vh - 79.51px)",
      backgroundColor: "rgb(14,94,126)",
      background:
        "linear-gradient(180deg, rgba(14,94,126,1) 15%, rgba(35,177,174,1) 75%)",
      padding: "0px",
      minWidth: "320px",
      width: "100%",
    },
    [theme.breakpoints.up(1920)]: {
      top: (props) => (props.openMenuInDesktop ? "79.51px" : "79.51px"),
    },
  },
  link: {
    background: "transparent",
    textDecoration: "none",
    with: "100%",
    justifyContent: "space-between",
  },
  botonmenu: {
    display: "flex",
    minWidth: "100%",
    minHeight: "49px",
    backgroundColor: "#156A8D",
    borderRadius: "0px",
    colors: "red",
    justifyContent: "space-between",
    [theme.breakpoints.down(1161)]: {
      justifyContent: "center",
    },
    "& .text": {
      textDecoration: "none",
      textTransform: "none",
      margin: "0 0 0 0%",
      color: "#FFFFFF",
      font: " 600 16px Lato;",
    },
    "&:hover": {
      backgroundColor: "#3E42AD",
    },
    "&:focus": {
      background: "#FFF8F7 ",
      "& .text": {
        color: "#707070",
      },
    },
  },
  Cerrar: {
    "& .close": {
      backgroundRepeat: "no-repeat",
      backgroundImage: `url(${Salir})`,
      backgroundSize: "100% 100%",
      height: "30px",
      width: "30px",
      "&:hover": {
        "&:before": {
          textTransform: "none",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "25px",
          position: "absolute",
          content: '"Cerrar sesión"',
          left: "105%",
          height: "16px",
          width: "110px",
          background: "#fff",
          font: "bold 14px lato",
        },
      },
    },
    borderBottom: " 0px",
    background: "#0F5F7F",
    width: (props) => (props.open ? 280 : "3%"),
    "&:hover": {
      background: "#D9DF61",
      "& .text": {
        color: "#575757",
      },
    },
    "& .text": {
      font: "bold 17px lato",
    },
  },
  Abrir: {
    justifyContent: "center",
    background: "none",
    "&:focus": {
      background: "none ",
    },
    borderBottom: " 0px",
    padding: "0",
  },
  menuy: {
    display: (props) => (props.open ? "none" : "flex"),
  },
  menun: {
    display: (props) => (props.open ? "flex" : "none"),
  },
  hamburguesa: {
    padding: "0px",
    height: "30%",
    width: "100%",
    display: "none",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.up("lg")]: {
      display: "flex",
    },

    "& .buttonHandleMenu": {
      boxSizing: "border-box",
      cursor: "pointer",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      border: "none",
      outline: "none",
      backgroundColor: "transparent",
      height: "60px",
      width: "100%",
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
    "& .cerrar": {
      transform: "rotate(0.50turn)",
    },
  },

  espacio: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    alignItems: "center",
    flexGrow: "1",
  },
  containerCerrarSesion: {
    display: "flex",
    flexGrow: "1",
    justifyContent: "center",
    alignItems: "flex-end",
    "& div": {
      width: "100%",
    },
  },
  BarraPracticas: {
    display: (props) => (props.openPracticas ? "flex" : "none"),
    flexWrap: "wrap",
    [theme.breakpoints.up("sm")]: {
      flexDirection: "column",
      position: "absolute",
      backgroundColor: "#3E42AD",
      display: (props) => (props.openPracticas ? "flex" : "none"),
      width: (props) => (props.openMenuInDesktop ? 280 : "100%"),
      left: (props) => (props.openMenuInDesktop ? 280 : "100%"),
      top: "148px",
    },
  },
  BarraGrados: {
    display: (props) => (props.openGrados ? "flex" : "none"),
    flexWrap: "wrap",
  },
  navButton: {
    textTransform: "none",
  },
  Practicas1: {
    background: "#D04C97",
  },
  Practicas2: {
    background: "#E352A5",
  },
  Practicas3: {
    background: "#E264AD",
  },
}));

const MenuIzquierdo = () => {
  const {
    openMenuInDesktop,
    openMenuInMobile,
    handleMenuInDesktop,
    handleMenuInMobile,
  } = useContext(UsabilityContext);
  const {
    user: {
      name,
      firstLastName,
      secondLastName,
      userName,
      userId,
      rol,
      escuela,
    },
    dispatch,
  } = useContext(AuthContext);

  const [openPracticas, setOpenPracticas] = useState(false);
  const [openCredits, setOpenCredits] = useState(false);

  const history = useHistory();

  const classes = useStyles({
    openMenuInDesktop,
    openPracticas,
    openMenuInMobile,
  });

  const handleLogout = () => {
    localStorage.clear();

    dispatch({
      type: types.logout,
    });
    const oldSesion = {
      userId,
      userName,
      name,
      firstLastName,
      secondLastName,
      rol,
      schoolLogo: escuela?.avatar,
    };
    localStorage.removeItem("oldSesion");
    localStorage.setItem("oldSesion", JSON.stringify(oldSesion));
    setOpenPracticas(false);
    handleMenuInMobile();
    history.push({ pathname: `/singin/admin-inst` });
  };

  const handleCloseSubMenu = () => {
    setOpenPracticas(false);
    handleMenuInMobile();
    if (openMenuInDesktop) handleMenuInDesktop();
  };
  const handleOpenCredits = () => {
    setOpenCredits(true);
  };

  return (
    <nav className={classes.container}>
      <div className={classes.Barra}>
        <>
          <NavButton
            className={classes.navButton}
            title="Perfil"
            to="/admin-inst/perfil"
            handler={handleCloseSubMenu}
            icon={"Perfil"}
            openMenuInDesktop={openMenuInDesktop}
            openMenuInMobile={openMenuInMobile}
          />

          {escuela?.tipo === "vip" && (
            <NavButton
              classes={{ root: classes.navButton }}
              title="Cargar datos"
              to="/admin-inst/cargar_datos"
              handler={handleCloseSubMenu}
              icon={"CargarDatos"}
              openMenuInDesktop={openMenuInDesktop}
              openMenuInMobile={openMenuInMobile}
            />
          )}

          <NavButton
            title="Manual de Usuario"
            to="/admin-inst/manual_de_usuario"
            handler={handleCloseSubMenu}
            icon={"Manual"}
            openMenuInDesktop={openMenuInDesktop}
            openMenuInMobile={openMenuInMobile}
          />
          <NavButton
            title="Creditos"
            to=""
            handler={handleOpenCredits}
            icon={"Creditos"}
            openMenuInDesktop={openMenuInDesktop}
            openMenuInMobile={openMenuInMobile}
          />
          <div className={clsx(`${classes.menuy} ${classes.hamburguesa}`)}>
            <button
              className="buttonHandleMenu"
              onClick={() => {
                handleMenuInDesktop();
                handleCloseSubMenu();
              }}
            >
              <img
                src={flecha}
                alt=""
                className={openMenuInDesktop ? "cerrar" : ""}
              />
            </button>
          </div>
          <div
            className={clsx(`${classes.menun} ${classes.hamburguesa}`)}
          ></div>

          <div className={classes.containerCerrarSesion}>
            <NavButton
              title="Cerrar sesión"
              to=""
              handler={handleLogout}
              icon={"Cerrar"}
              openMenuInDesktop={openMenuInDesktop}
              openMenuInMobile={openMenuInMobile}
              id="Logout-btn"
            />
          </div>
          <ModalCredits open={openCredits} setOpen={setOpenCredits} />
        </>
      </div>
    </nav>
  );
};

export default MenuIzquierdo;
