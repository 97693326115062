import React, { useEffect } from "react";
import { makeStyles, Grid } from "@material-ui/core";
import NavBar from "../../../../NavBar";

import Activity from "./Activity";

import MenuIzquierdo from "../../MenuIzquierdo";

import Question from "../../../exercises/Question";
import QuestionProv from "../../../exercises/QuestionProv";
import Lectura from "../../../LecturasActividad/LecturasPrimero/Sesion3/Lectura";

const useStyles = makeStyles((theme) => ({
  grid1: {
    zIndex: "3",
  },
  grid2: {
    zIndex: "2",
  },
  grid3: {
    boxSizing: "border-box",
    zIndex: "1",
    paddingLeft: "3%",
    [theme.breakpoints.down("md")]: {
      paddingLeft: "0px",
    },
  },
}));

const dataActivity = {
  status: "Desbloqueada ",
  respondidas: 0,
  intento: 1,
  preguntas_total: 1,
  id_actividad_inscripcion: 66,
  id_actividad: 3,
  preguntas: [
    {
      id_pregunta: 1313,
      pregunta:
        "Este documento sirve para identificar a una persona. ¿Qué parte te ayuda a lograr esto?",
      puntos: 1,
      texto: "",
      habilidad: "IA",
      insignia: "comprension lectora",
      tipo_pregunta: "OpcMult",
      respondida: true,
      respuestas: [
        {
          id_respuesta: 4884,
          respuesta: "Las letras más grandes.",
          feedback:
            "Al ver letras más grandes, no podemos saber si sirve para eso.",
          correcta: 0,
        },
        {
          id_respuesta: 4887,
          respuesta: "El logotipo.",
          feedback:
            "Este elemento nos ayuda a identificar a una institución, no a una persona.",
          correcta: 0,
        },
        {
          id_respuesta: 4885,
          respuesta: "La fotografía.",
          feedback:
            "Así es, al ver la foto de una persona podemos identificarla.",
          correcta: 1,
        },
        {
          id_respuesta: 4886,
          respuesta: "La forma que tiene.",
          feedback: "La forma no ayuda a identificar a una persona.",
          correcta: 0,
        },
      ],
    },
    /*
    {
      id_pregunta: 1314,
      pregunta: "¿Qué tipo de documento es éste?",
      puntos: 1,
      texto: "",
      habilidad: "IA",
      insignia: "comprension lectora",
      tipo_pregunta: "OpcMult",
      respondida: false,
      respuestas: [
        {
          id_respuesta: 4890,
          respuesta: "Un recado.",
          feedback:
            "¡No! Tal vez te confundiste porque los recados también son textos breves, pero no tienen fotografías ni distintos tipos de letras.",
          correcta: 0,
        },
        {
          id_respuesta: 4889,
          respuesta: "Una credencial.",
          feedback:
            "¡Así es! Ésta es una credencial que sirve para identificar a una persona.",
          correcta: 1,
        },
        {
          id_respuesta: 4888,
          respuesta: "Un informe de calificaciones.",
          feedback:
            "¡No es así! No hay ningún registro de calificaciones aquí.",
          correcta: 0,
        },
        {
          id_respuesta: 4891,
          respuesta: "Una licencia para conducir automóviles.",
          feedback:
            "Aunque estos dos documentos se parecen, fíjate en la foto. ¡Un niño no puede conducir un automóvil!",
          correcta: 0,
        },
      ],
    },
    {
      id_pregunta: 1315,
      pregunta: "¿A quién pertenece esta credencial?",
      puntos: 1,
      texto: "",
      habilidad: "RI",
      insignia: "comprension lectora",
      tipo_pregunta: "OpcMult",
      respondida: false,
      respuestas: [
        {
          id_respuesta: 4892,
          respuesta: "Jirón Moquegua",
          feedback:
            "¡No es así! Éste es el nombre de una calle y es parte de la dirección.",
          correcta: 0,
        },
        {
          id_respuesta: 4894,
          respuesta: "Georgina Ochoa",
          feedback: "¡No! Esta persona no es la dueña de la credencial.",
          correcta: 0,
        },
        {
          id_respuesta: 4893,
          respuesta: "Nicolás Ugarte Ochoa",
          feedback:
            "¡Así es! Nicolás es un alumno y ésta es una credencial para identificar a los estudiantes de una escuela.",
          correcta: 1,
        },
        {
          id_respuesta: 4895,
          respuesta: "Cercado de Lima.",
          feedback: "¡Cuidado! Éste es el nombre de un barrio.",
          correcta: 0,
        },
      ],
    },
    {
      id_pregunta: 1316,
      pregunta:
        "Un documento es elaborado por alguien. ¿Quién hizo este documento?",
      puntos: 1,
      texto: "",
      habilidad: "IC",
      insignia: "comprension lectora",
      tipo_pregunta: "OpcMult",
      respondida: false,
      respuestas: [
        {
          id_respuesta: 4897,
          respuesta: "Una escuela",
          feedback:
            "Exactamente, por eso el nombre de la escuela está arriba, escrito con letras diferentes.",
          correcta: 1,
        },
        {
          id_respuesta: 4896,
          respuesta: "El gobierno de una ciudad",
          feedback:
            "En ningún lugar figura el nombre o el logotipo del gobierno de una ciudad.",
          correcta: 0,
        },
        {
          id_respuesta: 4899,
          respuesta: "Un club deportivo",
          feedback:
            "Los clubes deportivos también hacen credenciales como éstas, pero aquí aparece el nombre de una escuela.",
          correcta: 0,
        },
        {
          id_respuesta: 4898,
          respuesta: "Un niño",
          feedback:
            "No, un niño no puede elaborar una credencial que sea aceptada por una escuela.",
          correcta: 0,
        },
      ],
    },
    {
      id_pregunta: 1317,
      pregunta: "¿Para qué sirven las fotos en las credenciales?",
      puntos: 1,
      texto: "",
      habilidad: "IC",
      insignia: "comprension lectora",
      tipo_pregunta: "OpcMult",
      respondida: false,
      respuestas: [
        {
          id_respuesta: 4902,
          respuesta:
            "Para que los adultos puedan reconocer a sus hijos cuando van por ellos a la escuela.",
          feedback: "Esto no es necesario. ¡Las familias se conocen muy bien!",
          correcta: 0,
        },
        {
          id_respuesta: 4903,
          respuesta:
            "Para que las personas puedan comprobar a quién le pertenece la credencial.",
          feedback:
            "¡Exacto! Cuando muestras una credencial con tu foto, las personas leen tu nombre y confirman que eres tú.",
          correcta: 1,
        },
        {
          id_respuesta: 4901,
          respuesta: "Para que se vea mejor decorada y más elegante.",
          feedback:
            "Esta foto no es para decorar; sirve para comprobar quién es el dueño de la credencial.",
          correcta: 0,
        },
        {
          id_respuesta: 4900,
          respuesta: "Para que la pueda entender alguien que no sabe leer.",
          feedback:
            "Esto no sería útil, pues, aunque viera la foto no sabría el nombre de la persona.",
          correcta: 0,
        },
      ],
    },
    {
      id_pregunta: 1318,
      pregunta:
        "Si el dueño de esta credencial se sintiera enfermo en la escuela, ¿a quién llamarían?",
      puntos: 1,
      texto: "",
      habilidad: "IC",
      insignia: "comprension lectora",
      tipo_pregunta: "OpcMult",
      respondida: false,
      respuestas: [
        {
          id_respuesta: 4906,
          respuesta: "Al director del Colegio Héroes de Lima",
          feedback:
            "No sería necesario llamar a esta persona, porque el director ya está en la escuela.",
          correcta: 0,
        },
        {
          id_respuesta: 4905,
          respuesta: "A Jirón Moquegua",
          feedback:
            "¡Cuidado! Éste es el nombre de la calle en la que se encuentra la escuela.",
          correcta: 0,
        },
        {
          id_respuesta: 4904,
          respuesta: "A Georgina Ochoa",
          feedback:
            "¡Sí! Ella es la tutora de Nicolás, el dueño de la credencial. Esto quiere decir que Georgina es la responsable de Nicolás.",
          correcta: 1,
        },
        {
          id_respuesta: 4907,
          respuesta: "A Nicolás Ugarte Ochoa",
          feedback: "Incorrecto. Él es el dueño de la credencial.",
          correcta: 0,
        },
      ],
    },
    {
      id_pregunta: 1319,
      pregunta:
        "¿Cuál de las siguientes claves sirve para reconocer a cada alumno?",
      puntos: 1,
      texto: "",
      habilidad: "IC",
      insignia: "comprension lectora",
      tipo_pregunta: "OpcMult",
      respondida: false,
      respuestas: [
        {
          id_respuesta: 4908,
          respuesta: "15002",
          feedback:
            "Éste es el C. P., es decir, el código postal, un número que sirve para saber a qué parte de un país llevar el correo.",
          correcta: 0,
        },
        {
          id_respuesta: 4909,
          respuesta: "462-800-02",
          feedback: "Éste es el teléfono de la familia del alumno.",
          correcta: 0,
        },
        {
          id_respuesta: 4910,
          respuesta: "337812CL",
          feedback:
            "¡Muy bien! Este número, acompañado de letras, es la clave que identifica a cada alumno.",
          correcta: 1,
        },
        {
          id_respuesta: 4911,
          respuesta: "1º B",
          feedback:
            "Ésta es la clave para identificar el grupo escolar al que pertenece el alumno.",
          correcta: 0,
        },
      ],
    },
    {
      id_pregunta: 1320,
      pregunta:
        "En estos lugares, una credencial es útil. ¿En cuál no hace falta credencial?",
      puntos: 1,
      texto: "",
      habilidad: "CV",
      insignia: "comprension lectora",
      tipo_pregunta: "OpcMult",
      respondida: false,
      respuestas: [
        {
          id_respuesta: 4914,
          respuesta: "Un club deportivo",
          feedback:
            "No es la respuesta correcta. Una credencial es necesaria para entrar al club y usar la alberca o las canchas.",
          correcta: 0,
        },
        {
          id_respuesta: 4912,
          respuesta: "Un supermercado",
          feedback:
            "¡Así es! No es necesario usar una credencial en el supermercado, pues cualquiera puede entrar.",
          correcta: 1,
        },
        {
          id_respuesta: 4915,
          respuesta: "Una academia de arte",
          feedback:
            "Al igual que en una escuela, una credencial es necesaria para identificarte como alumno de las clases de arte. ",
          correcta: 0,
        },
        {
          id_respuesta: 4913,
          respuesta: "Una biblioteca",
          feedback:
            "¡No es así! Una credencial de biblioteca es muy útil para pedir libros prestados.",
          correcta: 0,
        },
      ],
    },
    {
      id_pregunta: 1321,
      pregunta:
        "¿Qué puedes hacer para relacionar en tu mente la información gráfica con la escrita de un documento?",
      puntos: 1,
      texto: "",
      habilidad: "AG",
      insignia: "socio emocional",
      tipo_pregunta: "OpcMult",
      respondida: false,
      respuestas: [
        {
          id_respuesta: 4916,
          respuesta: "Revisar por separado las imágenes y el texto.",
          feedback:
            "Esto puede ser útil la primera vez que ves el documento, pero no te ayudará a relacionar toda la información que contiene.",
          correcta: 0,
        },
        {
          id_respuesta: 4917,
          respuesta:
            "Hacer un esquema en una hoja con la información que recuerdes.",
          feedback: "¡Cuidado! Esto podría confundirte.",
          correcta: 0,
        },
        {
          id_respuesta: 4918,
          respuesta:
            "Identificar qué información escrita corresponde directamente con cada imagen que aparece en el documento.",
          feedback:
            "¡Muy bien! Así podrás relacionar el texto con las imágenes de forma ordenada y coherente.",
          correcta: 1,
        },
        {
          id_respuesta: 4919,
          respuesta: "Buscar otros documentos parecidos.",
          feedback:
            "Si no consigues entender la información de un documento que contiene texto e imágenes, puedes confundirte más si ves muchos documentos parecidos. ¡Mejor ve de uno en uno! ",
          correcta: 0,
        },
      ],
    },
    {
      id_pregunta: 1322,
      pregunta:
        "Las credenciales son documentos muy prácticos por dos razones: nos proporcionan mucha información con un solo vistazo y nos facilitan cosas de la vida cotidiana, como permitirnos la entrada a ciertos lugares, mostrar nuestros intereses y obtener descuentos, entre otros. <br><br>10. Anota qué credenciales posees y para qué te sirven. Si no cuentas con ninguna, escribe cuáles te gustaría tener y por qué; por ejemplo: de alguna biblioteca, de un deportivo o de un centro cultural.",
      puntos: 1,
      texto: "",
      habilidad: "AUT",
      insignia: "socio emocional",
      tipo_pregunta: "CuaText",
      respondida: false,
      respuestas: [
        {
          id_respuesta: 4920,
          respuesta: "",
          feedback:
            "¡Bien hecho! Recuerda que el desarrollo de las habilidades socioemocionales debe ser constante, por lo que te invitamos a seguir escribiendo al respecto",
          correcta: 0,
        },
      ],
    },
    */
  ],
};

const ActivityS03Tutorial = (props) => {
  const classes = useStyles();
  const [step, setStep] = React.useState(0);
  const [questionList, setQuestionList] = React.useState([]);
  const [data, setData] = React.useState({});


  useEffect(() => {
    setQuestionList(dataActivity.preguntas);
    setData(dataActivity);
    setStep(dataActivity.respondidas);
  }, [questionList.length]);

  const renderQuestion = () => {
    return step === 9 ? (
      <>
        <QuestionProv
          pregunta={
            questionList.length > 0
              ? questionList[step].pregunta
              : "cargando..."
          }
          step={step}
        />
      </>
    ) : (
      <>
        <Question
          step={step}
          pregunta={
            questionList?.length > 0
              ? questionList?.[step]?.pregunta
              : "cargando..."
          }
        />
      </>
    );
  };

  return (
    <Grid container>
      <Grid item className={classes.grid1} sm={12}>
        <NavBar />
      </Grid>
      <Grid item className={classes.grid2} sm={1}>
        <MenuIzquierdo />
      </Grid>
      <Grid item className={classes.grid3} sm={12}>
        <Activity
          step={step}
          setStep={setStep}
          questionList={questionList}
          setQuestionList={setQuestionList}
          questionComponent={renderQuestion()}
          Lectura={Lectura}
          // imageList={imageList}
          // picturesTable={picturesTable}
          activityNumber={3}
          enrollmendId={data && data.id_actividad_inscripcion}
          funcional={step === 0}
          toSession={"/guia/session03"}
          tryNumber={data && data.intento}
        />
      </Grid>
    </Grid>
  );
};

export default ActivityS03Tutorial;
