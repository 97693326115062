import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
    ContenedorLectura:{
        width:'502px',
                  [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
    },

    ContainerTitulo:{
        margin:'0 0 36px 0',
        '& .Titulo':{
            letterSpacing: '0px',
            color: '#FF0000',
            margin:'0',
            textAlign:'center',
            font:'43px fredoka one',
            lineHeight:'52px',
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            margin:'0 0 36px 0 ',
            color:'#000',
            font:'18px lato',
            lineHeight :'28px',
            letterSpacing: '0.17px',
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },

        '& .TextImg':{
            color:'#717476',
            margin:'22px 0 0 0',
            font:' 16px lato',
            lineHeight :'25px',
            letterSpacing: '0.15px',
            [theme.breakpoints.down(502)]:{
                font:' 11px lato',
                lineHeight :'14px',
                letterSpacing: '0.10px',
            },
        },

        '& .Imagen':{
            width:'100%'
        },

    },
    

}));

const La_pobreza_y_el_altruismo = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >
                        La pobreza y el altruismo                    
                    </h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                            ¿Alguna vez has notado que hay personas tan pobres en el mundo que no pueden 
                            alimentarse ni vestirse bien? Muchas tampoco tienen acceso a servicios educativos 
                            ni de salud. En contraste, hay otras que logran satisfacer sus necesidades básicas 
                            y además tienen la posibilidad de gastar dinero en lujos y acumular <b>riquezas</b>.<br/><br/> 
                            
                            Vivimos en una sociedad en la que esto resulta normal. Pero las cosas pudieron ser 
                            distintas y los seres humanos tenían la oportunidad de construir un entorno más 
                            equitativo, donde hubiera pocas diferencias. La <b>desigualdad</b> comenzó en algún 
                            momento de la historia, pero es difícil decir exactamente cuándo.<br/><br/> 
                            
                            Jean-Jacques Rousseau fue un pensador suizo del siglo <span style={{fontVariant: "all-small-caps"}}>XVIII</span>  que planteó esta 
                            cuestión en uno de sus libros. Sugirió que el fenómeno de la <b>pobreza</b> es un accidente 
                            y que no existiría si las sociedades se hubieran formado de manera distinta. ¿Te has 
                            preguntado cuáles son sus consecuencias?<br/><br/>
                            
                            Imagina que estás en clase de educación física y la profesora pide a los estudiantes 
                            que vayan al almacén y tome una pelota cada quien. Pero uno se lleva muchas y deja 
                            a varios sin nada. A él solamente le correspondía una, por lo tanto su conducta 
                            <b> perjudica</b> a los demás. Entonces, otro compañero decide <b>compartir</b> su pelota con 
                            quienes no alcanzaron.
                        </p> 
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <Grid container justifyContent='center' sm={12}>
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img4to/Sesion24/1.png" alt="Tarjeta" />
                        </Grid>
                        <Grid container justifyContent='center' sm={12}>
                            <p className='TextImg'>¿A qué crees que se deba la desigualdad de recursos? ¿Piensas que hay solución?</p>                          
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                            Se llama <em>altruismo</em> al comportamiento generoso de la persona que compartió lo que 
                            tenía. Es la actitud de contribuir al bienestar de los demás de manera <b>desinteresada</b>. 
                            ¿Cuál de los dos estudiantes actuó de manera ética? Uno reunió cosas sin que hiciera 
                            falta, a costa de los demás. El otro compartió sus recursos para <b>beneficiar</b> a quienes 
                            lo requerían.<br/><br/> 
                            
                            El altruismo es una actitud ética y puede ayudar a erradicar las carencias en el 
                            planeta. La gente más pobre sería capaz de cubrir sus necesidades si los que tienen 
                            más dejaran libres los recursos que no ocupan. Pero la labor de <b>compartir</b> no sólo 
                            corresponde a ellos: todos podemos dar algo para ayudar.
                        </p>
                    </Grid>
                </Grid>
        </Grid>
    </Grid>
    );
}
 
export default La_pobreza_y_el_altruismo;
