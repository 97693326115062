import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
    ContenedorLectura:{
        width:'502px',
                  [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
    },
    ContainerTitulo:{
        margin:'0 0 46px 0',
        '& .Titulo':{
            letterSpacing: '0px',
            color: '#EEA90A',
            margin:'0',
            textAlign:'center',
            font:'43px fredoka one',
            lineHeight:'52px',
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            margin:'0 0 36px 0 ',
            color:'#000',
            font:'18px lato',
            lineHeight :'28px',
            letterSpacing: '0.17px',
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .SubTitulo':{
            margin:'0',
            color:'#D1270A',
            font:'bold 22px lato',
            lineHeight :'24px',
            letterSpacing: '0.21px',
            [theme.breakpoints.down(502)]:{
                font:'bold 18px lato',
                lineHeight :'20px',
                letterSpacing: '0.16px',
            },
        },
        '& .TextImg':{
            margin:'22px 0 0 0',
            alignSelf:'flex-start',
            color:'#717476',
            font:' 16px lato',
            lineHeight :'25px',
            letterSpacing: '0.15px',
            [theme.breakpoints.down(502)]:{
                font:' 11px lato',
                lineHeight :'14px',
                letterSpacing: '0.10px',
            },

        },
        '& .Imagen':{
            width:'100%'
        }
        

    },
    

}));

const La_curiosa_supervivencia_de_las_plantas = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >
                        La curiosa supervivencia<br/>de las plantas
                    </h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                            <h3 className='SubTitulo'>¿Cómo se reproducen las plantas?</h3><br/>

                            Las plantas florales son tan antiguas como los dinosaurios. Han sobrevivido y 
                            evolucionado hasta nuestros días gracias a estrategias muy ingeniosas.
                        </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <Grid container >
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img2do/Sesion33/1.png"  alt="Tarjeta" />
                        </Grid>
                        <Grid container>
                            <p className='TextImg'>
                                Los fósiles de plantas son un elemento crucial para comprender cómo fue su vida y cómo ha 
                                sido su evolución.
                            </p>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                        Como sabes, la reproducción es una característica que les permite a todos los 
                        organismos continuar existiendo como especie y no desaparecer de la Tierra. Para 
                        las plantas este proceso resulta una aventura de colores y aromas que involucra la 
                        participación de otros seres vivos.<br/><br/>
                        
                        Pero si ellas no pueden moverse para buscar pareja y reproducirse, ¿cómo lo logran? 
                        Hay distintas maneras de hacerlo, dependiendo de la especie. A continuación te lo 
                        mostramos.<br/><br/>
                        
                        Algunas pueden hacerlo a partir de sus propias ramas. Cuando se desprenden, éstas 
                        echan raíces y generan una planta nueva. Eso sucede, por ejemplo, con las cactáceas.<br/><br/>
                        
                        Otras logran la unión de dos células especiales que crean un fruto. Éste protege a las 
                        semillas a partir de las cuales crecerá la planta. Tal es el caso de los árboles y las 
                        plantas frutales.<br/><br/>
                        
                        En el caso de las florales (llamadas <em>angiospermas</em>), las flores son su aparato 
                        reproductor y el polen es la semilla que fecunda a otras plantas. Debido a que están 
                        fijas en el suelo es difícil que su polen les llegue a las demás. Por eso les piden ayuda a 
                        otros seres vivos (como las abejas) y a los elementos (como el agua o el aire).<br/><br/>
                        
                        La labor de convencimiento de las plantas para recibir ayuda no es fácil. Las flores 
                        deben ser atractivas a la vista o al olfato. Muchas tienen pétalos de colores 
                        llamativos y brillantes, con formas distintas. Las blancas, en cambio, producen olores 
                        que atraen a los insectos, a las aves y hasta a los murciélagos. Después de consumir 
                        su néctar, estos animales visitan otras flores y llevan consigo el polen que las 
                        fecundará.<br/><br/>
                        
                        Por supuesto, también existen plantas que no son florales. Éstas producen semillas 
                        llamadas <em>esporas</em>, cuyas formas les permiten transportarse con ayuda del viento.<br/><br/>
                        
                        Fascinante, ¿no? Piensa en todo esto cuando sientas que la gran diversidad de la flora 
                        terrestre te asombra.
                        </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <Grid container >
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img2do/Sesion33/2.png"  alt="Tarjeta" />
                        </Grid>
                        <Grid container>
                            <p className='TextImg'>
                                Las formas de reproducción de las plantas son tan variadas como su color. Mira a tu
                                alrededor: ¿qué forma de reproducción utilizan las plantas que hay cerca de ti?
                            </p>
                        </Grid>
                    </Grid>
                </Grid>
        </Grid>
    </Grid>
    );
}
 
export default La_curiosa_supervivencia_de_las_plantas;
