import React               from "react";
import {Grid, makeStyles}                 from '@material-ui/core';

const useStyles = makeStyles(theme =>({
  questionContainer:{
    width: "100%",
    height:"100%",
  },
  reading: {
    width: "80%",
    margin:"10px 0 10px 0 ",
    font: '22px lato',
    letterSpacing: '0.21px',
    [theme.breakpoints.down(1400)]:{
      font: " 18px lato",
      width: "95%",
    },
    [theme.breakpoints.down(1310)]:{
      font: "500 16px lato",

    },
    [theme.breakpoints.down(925)]:{
      font: "500 14px lato",

    },
    [theme.breakpoints.down(426)]:{
      font: "500 12px lato",
 
    }, 
  },
  table: {
    width: "100%",
    font: '22px lato',
    [theme.breakpoints.down(1400)]:{
      font: " 18px lato",
      width: "95%",
    },
    [theme.breakpoints.down(1310)]:{
      font: "500 16px lato",
 
    },
    [theme.breakpoints.down(925)]:{
      font: "500 14px lato",

    },
    [theme.breakpoints.down(426)]:{
      font: "500 12px lato",

    }, 
  },
  tdTable: {
    padding: "0 1%",
    backgroundColor: "#84E9DF"
    // border: '1px solid black',
  },
  tdTable2: {
    padding: "0 1%",
    backgroundColor: "#FDDC7A"
    // border: '1px solid black',
  }
}));

const QuestionTable = (props) => {
  const classes = useStyles()

  return(
    <Grid container className={classes.questionContainer}>
      <p
        className={classes.reading}
      >
        5. Relaciona a cada autor con una idea distintiva de su teoría.
      </p>
      <br/>
      <table className={classes.table}>
        <thead>
          <th style={{width: "30%", textAlign: "left"}}>Científicos:</th>
          <th style={{width: "70%", textAlign: "left"}}>Ideas:</th>
        </thead>
        <tbody>
          <tr>
            <td className={classes.tdTable}>
              <p>
                1. Dewey
              </p>
            </td>
            <td className={classes.tdTable2}>
              <p>
                a. Descripción de las etapas del desarrollo cognitivo
              </p>
            </td>
          </tr>
          <tr>
            <td className={classes.tdTable}>
              <p>
                2. Piaget
              </p>
            </td>
            <td className={classes.tdTable2}>
              <p>
                b. Interacción aprendiz-entorno
              </p>
            </td>
          </tr>
          <tr>
            <td className={classes.tdTable}>
              <p>
                3. Vygotsky
              </p>
            </td>
            <td className={classes.tdTable2}>
              <p>
                c. Convivencia con más personas durante el aprendizaje
              </p>
            </td>
          </tr>
          <tr>
            <td className={classes.tdTable}>
              <p>
                4. Bandura
              </p>
            </td>
            <td className={classes.tdTable2}>
              <p>
                d. La escuela como un sitio ordenado en el mundo
              </p>
            </td>
          </tr>
        </tbody>
      </table>
    </Grid>
  )
}

export default QuestionTable;
