import React                from "react";
import { makeStyles,Grid }  from '@material-ui/core';

const useStyles = makeStyles(theme =>({
  badgeContainer:{
    height:'201px',
    width: "198px",
    [theme.breakpoints.down(1024)]:{
      height:'151px',
      width: "148px",
    },
    [theme.breakpoints.down(700)]:{
      width:"100px",
      height:'103px',
    },
  },
  autoconocimiento1: {
    width:"194px",
    height: "194px",
    [theme.breakpoints.down(1024)]:{
      height:'154px',
      width: "154px",
    },
    [theme.breakpoints.down(700)]:{
      width:"100px",
      height:'103px',
    },
  },
  title:{
    font:'21px Fredoka One',
    letterSpacing: '0.38px',
    color: '#5CAE9A',
    margin:'0px',
    textAlign: 'center',
    [theme.breakpoints.down(1024)]:{
      font:'18px Fredoka One'
    },
    [theme.breakpoints.down(700)]:{
      font:'16px Fredoka One'
    },
    [theme.breakpoints.down(500)]:{
      font:'14px Fredoka One'
    },
  },

}))

const MainBadge = (props) => {
  const classes = useStyles()
  const {badgeImage, badgeText} = props

  return(
    <Grid container direction="column" justifyContent="center" alignItems="center">
      <Grid className={classes.badgeContainer}>
        <img className={classes.autoconocimiento1} src={badgeImage} alt="autoconocimiento1" />
      </Grid>
      <p className={classes.title}>{badgeText}</p>
    </Grid>
  )
}

export default MainBadge;