import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
      Contenedor:{
        background:'#E9FBF9',
        width:'100vw',
        overflowX:'clip',
        [theme.breakpoints.down(1161)]:{
            background:'none',
           overflowX:'unset',
        },
        [theme.breakpoints.down(600)]:{
            marginTop: '79.51px',
        }
    },
    ContainerInfoLectura:{
        position:'relative',
        height:'86px',
        background:'#76D4C3',
        '& .BarraInfoLectura':{
            height:'86px',
            background:`Url(https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Barras/Azul.png)`,
        },
        '& .InfoLectura':{
            position:'absolute',
            height:'86px',
            width:'219px',
            boxSizing:'border-box',
            padding:'17.2px 0',
            background:'#FAFAFA',
            borderRadius:'50px',
            boxShadow: '2px 2px 2px 2px #00000029',
        },
        '& .InfoLectura:before':{
            position:'absolute',
            display:'flex',
            boxSizing:'border-box',
            padding:'0 0 0 10px',
            justifyContent:'center',
            alignItems:'center',
            height:'39.88px',
            width:'39.88px',
            borderRadius:'50%',
            content:'""',
            boxShadow: '3px 0px .5px 0px #00000029',
            background:'#FAFAFA',
            left:'90px',
            top:'65px',
            transform: 'rotate(0.25turn)',
        },
        '& .InfoLectura:after':{
            content:'""',
            height:'15px',
            width:'15px',
            borderTop:'2px solid #20A698',
            borderLeft:'2px solid #20A698',
            position:'absolute',
            bottom:'-10px',
            left:'Calc(50% - 8px)',
            transform: 'rotate(0.625turn)',
        },
        '& .Sesion':{
            borderLeft:'2px solid #D8D8D8',
        },
        '& .Dato':{
            margin:'0 0 5px 0',
            textAlign:'center',
            font:'bold 17px lato',
            color:'#20A698',
        },
        '& .Caracter':{
            margin:'0',
            textAlign:'center',
            font:'bold 17px lato',
            color:'#000000',
        },
    },
    ContenedorLectura:{
        background:'#fff',
        width:'1160px',
        height:'100%',
        padding:'60px 168px 35px  ' ,
        [theme.breakpoints.down(1161)]:{
            width:'502px',
            padding:'60px 0 0',
          },
        [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
        '& .ContainerDerechos':{
            bottom:'80px',
            [theme.breakpoints.down(1161)]:{
                display:'none',
            }
        },
        '& .Derechos':{
            textAlign:"center",
            margin:"0px",
            font:" 15px lato",
            color:"#717476",
        },
        '& .Escritorio':{
            display:'block',
            [theme.breakpoints.down(1161)]:{
                display:'none'
            },
        },
        '& .Tablet':{
            display:'none',
            [theme.breakpoints.down(1161)]:{
                display:'block'
            },
        },  
    },
    ContainerTitulo:{
        margin:'0 0 46px 0',
        [theme.breakpoints.down(1161)]:{
            margin:'0 0 38px 0',
        },
        '& .Titulo':{
            font:'48px fredoka one',
            letterSpacing: '0px',
            color: '#DF01B9',
            margin:'0',
            textAlign:'center',
            [theme.breakpoints.down(1161)]:{
                font:'43px fredoka one',
                lineHeight:'52px',
                textAlign:'center',
            },
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            lineHeight : '30px',
            margin:'0 0 36px 0 ',
            font:'22px lato',
            color:'#000',
            letterSpacing: '0.20px',
            [theme.breakpoints.down(1161)]:{
                font:'18px lato',
                letterSpacing:'.17px',
                lineHeight:'28px',
            },
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .SubTitulo':{
            margin:'0',
            font:'bold 30px lato',
            lineHeight:'30px',
            letterSpacing:'.28px',
            color:'#3C658C',
            [theme.breakpoints.down(1161)]:{
                letterSpacing:'.24px',
                font:'bold 26px lato',
            },
            [theme.breakpoints.down(502)]:{
                font:'bold 22px lato',
                lineHeight:'24px',
            },
            [theme.breakpoints.down(350)]:{
                font:'bold 18px lato',
                lineHeight:'24px',
            },
            
        },
        '& .SubTitulo2':{
            margin:'0',
            font:'bold 26px lato',
            lineHeight:'30px',
            letterSpacing:'.28px',
            color:'#8AA387',
            [theme.breakpoints.down(1161)]:{
                font:'bold 22px lato',
                letterSpacing:'.21px',
                lineHeight:'28px',
            },
            [theme.breakpoints.down(1161)]:{
                font:'bold 18px lato',
                letterSpacing:'.21px',
                lineHeight:'28px',
            },
        },
        
        '& .TextImg':{
            margin:'22px 0 0 0',
            font:'20px lato',
            alignSelf:'flex-start',
            color:'#717476',
            letterSpacing:'.19px',
            [theme.breakpoints.down(1161)]:{
                font:'16px lato',
                letterSpacing:'.15px',
                lineHeight:'25px',
            },
            [theme.breakpoints.down(502)]:{
                font:' 11px lato',
                lineHeight :'14px',
                letterSpacing: '0.10px',
            },
        },

        '& .Imagen1':{
            [theme.breakpoints.down(1161)]:{
             width:'90%',
            },
         },
         '& .Imagen':{
            [theme.breakpoints.down(1161)]:{
             width:'100%',
            },
         },

    },
    

}));

const En_busca_de_una_solución_para_el_hambre_mundial = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
             <Grid container item justifyContent="center" className={classes.ContainerInfoLectura}>
               <Grid container className="BarraInfoLectura"></Grid>
               <Grid container item className="InfoLectura">
                    <Grid container item direction="column" xs={6} justifyContent="center">
                        <p className="Dato">Grado</p>
                        <p className="Caracter">6</p>
                    </Grid>
                    <Grid container item direction="column" xs={6} justifyContent="center" className="Sesion">
                        <p className="Dato">Sesión</p>
                        <p className="Caracter">33</p>
                    </Grid>
               </Grid>
            </Grid>

            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo Escritorio" >
                        En busca de una solución <br/>para el hambre mundial
                    </h2>
                    <h2 className="Titulo Tablet" >
                        En busca de una <br/>solución para el  <br/>hambre mundial
                    </h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                            <h3 className='SubTitulo'>Algunos datos alarmantes</h3><br/>

                            ¿Sabías que cada año se desperdician aproximadamente 1 300 millones de toneladas 
                            de alimentos y que esta cantidad sería suficiente para alimentar a toda la población 
                            en situación de hambre? Tanto ha crecido la población mundial que la Organización 
                            de las Naciones Unidas para la Alimentación y la Agricultura (FAO, por sus siglas en 
                            inglés) afirma que si no se desperdiciaran tantos alimentos en forma de residuos o 
                            desechos en ciertos lugares, la producción global sería suficiente para alimentar a 
                            todos los habitantes de este planeta. <br/><br/>
                            
                            Durante mucho tiempo la humanidad creyó que los recursos naturales eran 
                            inagotables a la vez que accesibles, pero eso no es cierto. Los daños al ambiente que 
                            la humanidad ha causado han mermado los recursos que naturalmente podíamos 
                            obtener de la tierra. Ahora nos hemos dado cuenta de que los recursos no son 
                            inagotables. Peor aún, los alimentos que se generan naturalmente o mediante el 
                            esfuerzo humano no se distribuyen de una forma equitativa. Mientras que en 
                            algunos países desarrollados se desperdicia comida, hay muchas personas en los 
                            países en vías de desarrollo que no tienen nada para comer o cuya alimentación es 
                            deficiente. <br/><br/>
                            
                            <h3 className='SubTitulo2'>No hablamos de la misma <em>hambre</em></h3><br/>
                            
                            Es diferente hablar de <em>hambre</em> de acuerdo con el contexto en el que se use la palabra. 
                            De manera general, el <em>hambre</em> es una sensación física desagradable y dolorosa. Pero 
                            cuando hablamos de hambre en una macroescala (o de hambruna) nos referimos a 
                            esos millones de personas en el mundo que tienen muy poco o nada que comer. Ellos 
                            sienten hambre todo el tiempo, están desnutridos y en consecuencia probablemente 
                            enfermos.
                        </p>
                    </Grid>
                    <Grid container  sm={12}>
                        <Grid container justifyContent="center">
                            <img className="Imagen1" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion33/1.png"  alt="Tarjeta" />
                        </Grid>
                        <Grid container>
                            <p className='TextImg'>
                                El acceso a los alimentos por sí mismo no solucionaría el problema. Lo que se debe buscar es 
                                que todos podamos consumir alimentos nutricionalmente valiosos.
                            </p>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                            <h3 className='SubTitulo'>¡Qué desperdicio!</h3> <br/>
                            
                            Desperdiciamos mucho, casi la mitad de los 4 000 millones de toneladas de
                            alimentos que se producen van a la basura. Las causas de este desperdicio, como lo 
                            mencionamos antes, tienen que ver en muchas ocasiones con una distribución
                            desigual de los recursos, pues el derroche de éstos se da en algunas partes del 
                            mundo, mientras que en otras la falta de agua o el clima no permiten la producción de 
                            vegetales y frutos. <br/><br/>
                            
                            Otra de las razones es que el costo del transporte de los productos es muy elevado, 
                            es decir, sale muy caro llevar productos de donde se producen hacia donde se 
                            consumen. Además, los procesos de control de calidad desechan vegetales o frutas 
                            que, a pesar de estar en buenas condiciones, se tiran porque no lucen bien. <br/><br/>
                            
                            Pero también el desperdicio se da en tu círculo cercano. Recuerda cuánta comida se 
                            echa a perder en tu casa cuando compran de más o, por ejemplo, cuando vas a un 
                            restaurante y no consumes el platillo completo se desecha, pues no se lo pueden dar 
                            a otro cliente.
                        </p>
                    </Grid>
                    <Grid container  sm={12}>
                        <Grid container >
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion33/2.png"  alt="Tarjeta" />
                        </Grid>
                        <Grid container>
                            <p className='TextImg'>
                                La Asamblea General de las Naciones Unidas designó el 29 de septiembre como el Día 
                                Internacional de Conciencia de la Pérdida y el Desperdicio de Alimentos. Es un día para 
                                pensar en la producción sostenible de alimentos, en la promoción de la seguridad alimentaria 
                                y en la nutrición.
                            </p>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                            <h3 className='SubTitulo'>Una estrategia en busca de soluciones</h3> <br/>
                            
                             La FAO reportó que durante el 2020 alrededor de 800 millones de personas 
                             sufrieron hambre. En algunas regiones del mundo en las que la sequía impide el 
                             cultivo de plantas y la cría de animales el hambre afecta mucho a la población. ¿Por 
                             qué no transferir a esos sitios los alimentos que en otros lugares se desperdician? El 
                             problema son los costos de transporte o que las poblaciones se ubican en lugares 
                             inaccesibles. Seguramente muchos alimentos se echarían a perder durante el 
                             traslado. <br/><br/>
                             
                             Existen muchas organizaciones que se proponen recuperar alimentos que serían 
                             desechados por los productores, los departamentos de control de calidad y los 
                             supermercados y llevarlos a lugares en los que se necesitan. Pero la solución sería 
                             local, el gran reto es llevar alimentos a lugares de difícil acceso a países que a veces 
                             están en guerra y a los que es muy difícil llegar. <br/><br/>
                             
                             Aunque no desperdiciar es un gran inicio, vale la pena dedicar algo de nuestro tiempo 
                             a intentar solucionar esta problemática. Elaborar y compartir nuevas ideas podría 
                             guiarnos a una de las tantas respuestas que podrían terminar con la hambruna 
                             mundial.
                        </p>
                    </Grid>
                </Grid>

                <Grid container item xs={12} justifyContent="center" className="ContainerDerechos">
                    <p className="Derechos">
                        © 2022. Todos los derechos reservados, Pearson, Circuito de lectores.
                    </p>                
                </Grid>
        </Grid>
    </Grid>
    );
}
 
export default En_busca_de_una_solución_para_el_hambre_mundial;
