import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
    ContenedorLectura:{
        width:'502px',
                  [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
    },
    ContainerTitulo:{
        margin:'0 0 34px 0',
        '& .Titulo':{
            letterSpacing: '0px',
            color: '#D00CB4',
            margin:'0',
            textAlign:'center',
            font:'43px fredoka one',
            lineHeight:'52px',
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            margin:'0 0 36px 0',
            color:'#000',
            font:'18px lato',
            lineHeight :'28px',
            letterSpacing: '0.17px',
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        "& .SubTitulo": {
            color: "#F6644B",
            margin: "0",
            lineHeight: "28px",
            font: "bold 26px lato",
            letterSpacing: "0.24px",
            [theme.breakpoints.down(502)]: {
              font: "bold 22px lato",
              lineHeight: "24px",
              letterSpacing: "0.21px",
            },
        },
        '& .Imagen':{
            width:'77.88%',
        }, 

    }
}));

const Triángulos_de_colores = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >
                        Triángulos de colores                  
                    </h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                      <p className="Lectura">
                        <h3 className='SubTitulo'>Te proponemos un juego</h3><br/>
                        
                         Los juegos matemáticos te ayudan a desarrollar habilidades y destrezas mentales 
                         para reaccionar ágilmente ante ciertas situaciones que se te presentan en la vida 
                         diaria. Por ejemplo, además de ser divertido, el siguiente juego se trata de hallar 
                         estrategias para alcanzar el objetivo. Es muy conocido y ha perdurado en la tradición 
                         escolar durante mucho tiempo. ¿Ya sabes a cuál nos estamos refiriendo? <br/><br/>
                         
                         Elaborarlo es sencillo. Necesitarás un tablero, pero no te preocupes: tú mismo 
                         puedes dibujarlo en una hoja cuadriculada, marcando distintos ángulos para 
                         que formen un rectángulo, tal como se muestra en la ilustración:
                      </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img2do/Sesion58/1.png" alt="Tarjeta" />
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                      <p className="Lectura">
                            Debe haber al menos dos participantes: uno jugará con un color azul y el otro, con 
                            uno rojo. <br/><br/>
                            
                            El objetivo es que alguno de los dos logre trazar un triángulo de su color, pero sólo 
                            podrá dibujar una línea por turno. Cada jugador intentará unir los trazos para formar 
                            su propio triángulo mientras evita que el otro lo consiga. <br/><br/>
                            
                            Imaginemos que el primer turno lo tiene el jugador azul y dibuja la siguiente línea:
                      </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img2do/Sesion58/2.png" alt="Tarjeta" />
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                      <p className="Lectura">
                        Pero luego el jugador rojo traza esta otra:
                      </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img2do/Sesion58/3.png" alt="Tarjeta" />
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                      <p className="Lectura">
                      Continúa el azul y dibuja una línea más:
                      </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img2do/Sesion58/4.png" alt="Tarjeta" />
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                      <p className="Lectura">
                      Ahora el rojo tiene una sola opción. De lo contrario, el azul habrá ganado:
                      </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img2do/Sesion58/5.png" alt="Tarjeta" />
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                      <p className="Lectura">
                        En este punto, el juego ha terminado, pues ninguno tiene ya oportunidad de 
                        completar un triángulo. Compruébalo jugando con alguien más. Confirmen que no 
                        lograrán dibujar la figura, sin importar las líneas que tracen. <br/><br/>
                        
                        La siguiente parte del juego depende de la creatividad y la voluntad para seguir 
                        explorando las posibilidades de que alguno de los jugadores forme un triángulo de su 
                        color. <br/><br/>
                        
                        Pueden agregar puntos para que el tablero crezca e invitar a otros participantes. Eso 
                        volverá más emocionante y divertido el juego. <br/><br/>
                        
                        ¿Con cuántos puntos lograste ganar o ganó alguno de los participantes? <br/><br/>
                        
                        Te invitamos a jugar en casa o en la escuela. Es una actividad bastante sencilla de 
                        preparar, y con materiales simples. Además, te ofrece grandes posibilidades de 
                        desarrollar estrategias y de usar las matemáticas en situaciones divertidas y 
                        agradables.
                      </p>
                    </Grid>
                </Grid>
        </Grid>
    </Grid>
    );
}
 
export default Triángulos_de_colores ;
