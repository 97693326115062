import React               from "react";
import {Grid, makeStyles}                 from '@material-ui/core';

const useStyles = makeStyles(theme =>({
  questionContainer:{
    width: "100%",
    height:"100%",
  },
  reading: {
    width: "98%",
    font: "22px lato",
    letterSpacing:'0',
    margin:"14px 10px 14px 0",
    [theme.breakpoints.down(1370)]:{
      font: "500 16px lato",
      width: "90%",
      margin:"10px 10px 10px 0"
    },
    [theme.breakpoints.down(1310)]:{
      font: "500 16px lato",
      // width: "80%",
      margin:"10px 5px 10px 0"
    },
    [theme.breakpoints.down(925)]:{
      font: "500 14px lato",
      // width: "70%",
      margin:"6px 0 6px 0"
    },
    [theme.breakpoints.down(426)]:{
      font: "500 12px lato",
      // width: "70%",
      margin:"6px 0 6px 5%"
    },
  },
  imgStyle: {
    alignItems: "center",
    width:'50%',
    height:'50%',
    // [theme.breakpoints.down(1400)]:{
    //   width:'90%',
    //   height:'90%',
    //   [theme.breakpoints.down(1000)]:{
    //     width:'80%',
    //     height:'80%',
    //   },
    // },
  },
}));

const Question = (props) => {
  const classes = useStyles()
  const { picture1 } = props


  return(
    <Grid container justifyContent="center" alignItems="center" className={classes.questionContainer}>
      <p 
        // dangerouslySetInnerHTML={{__html: `${step+1}. ${pregunta.replace( '\n', '<br/><br/>')}`}}
        className={classes.reading}
      >
        3. Según las ideas secundarias subrayadas en el texto, 
        ¿cuál sería la idea principal que están complementando?
      </p>
      <br/>
      <img alt="img" src={picture1} className={classes.imgStyle}/>
      <p className={classes.reading} >
        Hubo un tiempo en que <u>las matemáticas me parecían muy aburridas</u>. 
        No veía el caso de aprenderlas, hasta que quise ser chef repostera. Ahí me di cuenta 
        de que las matemáticas son muy importantes para la profesión y la vida diaria. 
        Por ejemplo, <u>cuando voy a comprar los ingredientes para los pasteles y postres 
        o cuando necesito saber cuánto cobrar, es importante saber sumar, restar, 
        multiplicar o dividir</u>. <u>También son indispensables para pesar ingredientes, pues 
        debo ser precisa</u> en las cantidades que uso para que no queden azucaradas o desabridas. 
        Así mismo, <u>uso los números al hornear</u>, porque, dependiendo de la cantidad de mis masas o líquidos,
        será el tiempo de cocción. <u>Si meto un pastel de 2 kilos al horno durante 10 minutos, 
        no se cocerá, pero si lo hago durante 2 horas, se quemará</u>.
      </p>
      <p className={classes.reading} >
      Ahora me doy cuenta de que las matemáticas no son malas. Ésta es mi experiencia con las 
      matemáticas.
      </p>
     
    </Grid>
  )

}

export default Question;
