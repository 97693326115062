import { makeStyles, Grid } from "@material-ui/core";
import React from "react";
import { Link } from "react-router-dom";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

import ReporteContainer from "./Reporte_Container";


const useStyles = makeStyles((theme) => ({
  mainContainer: {
    padding: "48px 0 48px 0",
    background:
      "linear-gradient(217deg, rgba(255,194,0,0.4962185557816877) 2%, rgba(255,255,255,1) 25%, rgba(255,255,255,0.4962185557816877) 81%, rgba(144,81,177,1) 98%)",
    minHeight: "92vh",
  },
  boxContainer: {
    background: "#fff",
    width: "80%",
    maxWidth: "1216px",
    minHeight: "70vh",
    height: "auto",
    borderRadius: "42px",
    padding: "20px",
    gap: "20px",
    boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.5)",
    [theme.breakpoints.up("md")]: {
      padding: "48px 20px 48px 20px",
      gap: "0px",
    },

    // [theme.breakpoints.down(1280)]: {
    //   width: "860px",
    //   height: "100%",
    // },
    // [theme.breakpoints.down(870)]: {
    //   width: "420px",
    // },
    // [theme.breakpoints.down(425)]: {
    //   width: "300px",
    // },
  },
  title: {
    font: "20px fredoka one",
    textAlign: "center",
    [theme.breakpoints.up("xl")]: {
      font: "24px fredoka one",
    },
  },
  backButtonContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.up("sm")]: {
      justifyContent: "flex-start",
    },
  },
  backButton: {
    cursor: "pointer",
    height: "35px",
    backgroundColor: "#FDEFF7",
    color: "#DC5EA6",
    border: "#DC5EA6 1px solid",
    borderRadius: "20px",
    textTransform: "none",
    font: "20px Lato",
    letterSpacing: "0.14px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
  },
  link: {
    cursor: "pointer",
    textDecoration: "none",
    // height: "31px",
    // width: "190px",
  },
  ContainerLinkinsiginas: {
    textDecoration: "none",
    marginLeft: "44px",
    marginBottom: "40px",
    "& .ButtonIsg": {
      color: "blue",
      textTransform: "none",
      font: " 22px lato",
      letterSpacing: "0.36px",
      [theme.breakpoints.down(425)]: {
        font: " 16px lato",
      },
      // width: '102px',
      // height: '21px',
      // background: '#FEFAF1 0% 0% no-repeat padding-box',
      // boxShadow: '0px 1px 2px #00000029',
      // border: '1px solid #F2624F',
      // borderRadius: '10px',
    },
    "& p": {
      textAlign: "start",
    },
    "& .icon": {
      color: "purple",
      marginRight: "20px",
      fontSize: "50px",
      [theme.breakpoints.down(425)]: {
        fontSize: "30px",
        marginRight: "0px",
      },
    },
  },

  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const Reporte3 = () => {
  const classes = useStyles();
  return (
    <ReporteContainer>
      <Grid container item sm={12} alignItems="center" justifyContent="center" className={classes.mainContainer}>
        <Grid container item direction="column" className={classes.boxContainer}>
          <Grid container direction="row" alignItems="center" justifyContent="center">
            <Grid item className={classes.backButtonContainer}>
              <Link className={classes.link} to="/admin/reportes">
                <button className={classes.backButton}>
                  <ArrowBackIcon /> Reportes
                </button>
              </Link>
            </Grid>
            <Grid container item alignItems="center" justifyContent="center" className={classes.titleContainer}>
              <h2 className={classes.title}>Reporte 3: Reactivos</h2>
            </Grid>
          </Grid>
          <Grid container item direction="row" alignItems="center">
            {/* <ReporteFallos /> */}
          </Grid>
        </Grid>
      </Grid>
    </ReporteContainer>
  );
};

export default Reporte3;
