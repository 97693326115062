import { Button, Grid, makeStyles } from "@material-ui/core";
import React, { useContext } from "react";
import DrawerContext from "../../../../../usability/DrawerContext";
import Drawer from "../../ReadingTools/Drawer";

const useStyles = makeStyles((theme) => ({
  Contenedor: {
    background: "#E9FBF9",
    width: "100vw",
    [theme.breakpoints.down(1161)]: {
      background: "none",
    },
    [theme.breakpoints.down(600)]: {
      marginTop: "79.51px",
    },
  },
  ContainerInfoLectura: {
    position: "relative",
    height: "86px",
    [theme.breakpoints.down(502)]: {
      height: "43px",
    },
    background: "#76D4C3",
    "& .BarraInfoLectura": {
      height: "86px",
      background: `Url(https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Barras/Azul.png)`,
      [theme.breakpoints.down(502)]: {
        height: "43px",
      },
    },
    "& .InfoLectura": {
      position: "absolute",
      height: "86px",
      width: "219px",
      boxSizing: "border-box",
      padding: "17.2px 0",
      background: "#FAFAFA",
      borderRadius: "50px",
      boxShadow: "2px 2px 2px 2px #00000029",
      zIndex: "4",
      [theme.breakpoints.down(502)]: {
        height: "43px",
        width: "109px",
        padding: "8.6px 0",
        borderRadius: "25px",
      },
    },
    "& .InfoLectura:before": {
      position: "absolute",
      display: "flex",
      boxSizing: "border-box",
      padding: "0 0 0 10px",
      justifyContent: "center",
      alignItems: "center",
      height: "39.88px",
      width: "39.88px",
      borderRadius: "50%",
      content: '""',
      boxShadow: "3px 0px .5px 0px #00000029",
      background: "#FAFAFA",
      left: "90px",
      top: "65px",
      transform: "rotate(0.25turn)",
      [theme.breakpoints.down(502)]: {
        left: "45px",
        top: "30px",
        height: "20.88px",
        width: "20.88px",
      },
    },
    "& .InfoLectura:after": {
      content: '""',
      height: "15px",
      width: "15px",
      borderTop: "2px solid #20A698",
      borderLeft: "2px solid #20A698",
      position: "absolute",
      bottom: "-10px",
      left: "Calc(50% - 8px)",
      transform: "rotate(0.625turn)",
      [theme.breakpoints.down(502)]: {
        height: "10px",
        width: "10px",
        left: "Calc(50% - 4.5px)",
        bottom: "-3px",
        borderTop: "1px solid #20A698",
        borderLeft: "1px solid #20A698",
      },
    },
    "& .Sesion": {
      borderLeft: "2px solid #D8D8D8",
    },
    "& .Dato": {
      margin: "0 0 5px 0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#20A698",
      [theme.breakpoints.down(502)]: {
        font: "bold 12px lato",
        margin: "0 0 2.5px 0",
      },
    },
    "& .Caracter": {
      margin: "0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#000000",
      [theme.breakpoints.down(502)]: {
        font: "bold 12px lato",
      },
    },
    "& .Regresar": {
      color: "#F2624F",
      border: "#F2624F 1px solid",
      height: "30px",
      width: "100px",
      borderRadius: "20px",
      letterSpacing: "0.14px",
      textTransform: "none",
      background: "#FEFAF1",
      top: "calc(50% - 15px)",
      left: "5%",
      zIndex: 4,
      [theme.breakpoints.down(502)]: {
        font: "bold 10px lato",
        width: "60px",
      },
    },
  },
  ContenedorLectura: {
    background: "#fff",
    width: "1160px",
    height: "100%",
    padding: "60px 168px 35px ",
    position: "relative",
    
    transform: (props) =>
      props.scale > 1 ? `scale(${props.scale}) translate(0px, 10%)` : `none`,
    [theme.breakpoints.down(1161)]: {
      width: "502px",
      padding: "60px 0 0 0",
    },
    [theme.breakpoints.down(502)]: {
      width: "350px",
      padding: "60px 0 0 0",
    },
    [theme.breakpoints.down(350)]: {
      width: "300px",
      padding: "30px 0 0 0",
    },
    "& .ContainerDerechos": {
      bottom: "80px",
      [theme.breakpoints.down(1161)]: {
        display: "none",
      },
    },
    "& .Derechos": {
      textAlign: "center",
      margin: "0px",
      font: " 15px lato",
      color: "#717476",
    },
    "& .Escritorio": {
      display: "block",
      [theme.breakpoints.down(1161)]: {
        display: "none",
      },
    },
    "& .Tablet": {
      display: "none",
      [theme.breakpoints.down(1161)]: {
        display: "block",
      },
    },
  },
  ContainerTitulo: {
    margin: "0 0 16px 0",
    "& .Titulo": {
      textAlign: "center",
      font: "48px fredoka one",
      letterSpacing: "0px",
      color: "#80C6AF",
      margin: "0",
      [theme.breakpoints.down(1161)]: {
        font: "43px fredoka one",
        lineHeight: "52px",
      },
      [theme.breakpoints.down(502)]: {
        font: "30px fredoka one",
        lineHeight: "32px",
      },
    },
  },
  Parrafo1: {
    borderBottom: "2px dashed #80C6AF",
    border: "0 0 1px 0 solid",
    padding: " 0",
    height: "235px",
    [theme.breakpoints.down(1161)]: {
      height: "300px",
    },
    [theme.breakpoints.down(502)]: {
      height: "250px",
    },
    "& .Lectura": {
      lineHeight: "30px",
      margin: "0",
      font: "22px lato",
      color: "#000",
      letterSpacing: "0.21px",
      [theme.breakpoints.down(1161)]: {
        font: "18px lato",
        lineHeight: "28px",
        letterSpacing: "0.17px",
      },
      [theme.breakpoints.down(502)]: {
        font: "12px lato",
        lineHeight: "18px",
        letterSpacing: "0.09px",
      },
    },
    "& .Lectura1": {
      width: "595px",
      [theme.breakpoints.down(1161)]: {
        width: "310px",
      },
      [theme.breakpoints.down(502)]: {
        width: "200px",
      },
    },
    "& .Lectura2": {
      width: "577px",
      [theme.breakpoints.down(1161)]: {
        width: "263px",
      },
      [theme.breakpoints.down(502)]: {
        width: "200px",
      },
    },
    "& .Subtitulo": {
      lineHeight: "35px",
      font: "25px Fredoka One",
      color: "#F37C6E",
      [theme.breakpoints.down(1161)]: {
        font: "21px Fredoka One",
        lineHeight: "45px",
      },
      [theme.breakpoints.down(502)]: {
        font: "18px Fredoka One",
        lineHeight: "35px",
      },
    },
    "& .Subtitulo2": {
      lineHeight: "35px",
      font: "25px Fredoka One",
      color: "#8DB5A8",
      [theme.breakpoints.down(1161)]: {
        font: "21px Fredoka One",
        lineHeight: "45px",
      },
      [theme.breakpoints.down(502)]: {
        font: "18px Fredoka One",
        lineHeight: "35px",
      },
    },
    "& .Imagen1": {
      [theme.breakpoints.down(1161)]: {
        height: "166px",
        width: "166px",
      },
      [theme.breakpoints.down(502)]: {
        height: "106px",
        width: "106px",
      },
    },

    "& .TextImg": {
      margin: "22px 0 0 0",
      font: "20px lato",
      alignSelf: "flex-start",
      color: "#717476",
    },
  },
  Parrafo: {
    borderBottom: "none",
  },

  Parrafo3: {
    margin: "0 0 67px 0",
    "& .Imagen2": {
      width: "100%",
    },
  },
}));

const Ratón_de_campo_y_Ratón_de_ciudad = (props) => {

   const {activeStep, setActiveStep, handlePreviewStep, id_actividad} = props
  
  const handleContinue = () => {
    setActiveStep(activeStep - 1)
    handlePreviewStep()
  }

 const {
   handleSize,
   disabledZoom,
   closeZoom,
   scale,
   
   
   
  
   
 } = useContext(DrawerContext);
 const detectSize = () => {
   const lectura = document.getElementById("lectura");

   const newWidth = lectura?.offsetWidth;
   const newHeight = lectura?.offsetHeight;
   if (lectura) handleSize(newHeight, newWidth);
 };
 React.useEffect(() => {
   window.addEventListener("resize", detectSize);
   return () => {
     window.removeEventListener("resize", detectSize);
     closeZoom();
   };
 }, []);

 const classes = useStyles({ disabledZoom, scale });
  return (
    <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
      <Grid container item justifyContent="center" className={classes.ContainerInfoLectura}>
        <Grid container className="BarraInfoLectura">
            <Button className="Regresar" onClick={handleContinue}>
                Regresar
            </Button>
        </Grid>
        <Grid container item className="InfoLectura">
          <Grid container item direction="column" xs={6} justifyContent="center">
            <p className="Dato">Grado</p>
            <p className="Caracter">1</p>
          </Grid>
          <Grid container item direction="column" xs={6} justifyContent="center" className="Sesion">
            <p className="Dato">Sesión</p>
            <p className="Caracter">29</p>
          </Grid>
        </Grid>
      </Grid>

      <Grid
        container
        item
        className={classes.ContenedorLectura}
        justifyContent="center"
        id="lectura"
        onLoad={detectSize}

      >
        <Drawer id_lectura={id_actividad} withoutTransform ={true}/>
        <Grid container item xs={12} className={classes.ContainerTitulo} justifyContent="center">
          <h2 className="Titulo Escritorio">Ratón de campo y Ratón de ciudad</h2>
          <h2 className="Titulo Tablet">
            Ratón de campo
            <br /> y Ratón de ciudad
          </h2>
        </Grid>

        <Grid container item xs={12} className={classes.Parrafo1} alignItems="center">
          <Grid container xs={4}>
            <Grid container xs={12} justifyContent="center">
              <img
                className="Imagen1"
                src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img1ro/Sesion29/1.png"
                alt="Tarjeta"
              />
            </Grid>
          </Grid>

          <Grid container justifyContent="flex-end" xs={8}>
            <p className="Lectura Lectura1">
              <b className="Subtitulo">Ratón de campo</b>
              <br />
              —¿Y de verdad te gusta la ciudad? Hay mucho tráfico, ruido y contaminación. Hay gatos que se esconden en las
              sombras. ¿Por qué no vienes a vivir al campo? Comes lo que te da la tierra y no necesitas nada más.
            </p>
          </Grid>
        </Grid>

        <Grid container item xs={12} className={classes.Parrafo1} alignItems="center" justifyContent="center">
          <Grid container justifyContent="center" xs={8}>
            <p className="Lectura Lectura1">
              <b className="Subtitulo2">Ratón de ciudad</b>
              <br />
              —Bueno, en realidad hay más cosas en la ciudad. No todo es malo. Me gusta salir y divertirme, ir al teatro y a
              restaurantes.
            </p>
          </Grid>

          <Grid container xs={4}>
            <Grid container justifyContent="center">
              <img
                className="Imagen1"
                src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img1ro/Sesion29/2.png"
                alt="Tarjeta"
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid container item xs={12} className={classes.Parrafo1} alignItems="center" justifyContent="center">
          <Grid container xs={4}>
            <Grid container justifyContent="center">
              <img
                className="Imagen1"
                src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img1ro/Sesion29/1.png"
                alt="Tarjeta"
              />
            </Grid>
          </Grid>

          <Grid container justifyContent="flex-end" xs={8}>
            <p className="Lectura Lectura1">
              <b className="Subtitulo">Ratón de campo</b>
              <br />
              —Pues acá no sentimos nada de presión. A mí no me gusta la ciudad. Aunque admito que, a veces, me doy una vuelta
              para conocer los museos y hasta ir a bailar. Pero vivir ahí, ¡ni pensarlo!
            </p>
          </Grid>
        </Grid>

        <Grid container item xs={12} className={classes.Parrafo1} alignItems="center" justifyContent="center">
          <Grid container xs={8}>
            <p className="Lectura Lectura2">
              <b className="Subtitulo2">Ratón de ciudad</b> <br />
              —Yo tampoco sé si podría vivir en el campo, pero sí disfruto cuando lo visito. Sentir el olor de los árboles, ver el
              horizonte, oír los sonidos queditos y armoniosos de la naturaleza. Aunque creo que extrañaría las comodidades de la
              ciudad.
            </p>
          </Grid>

          <Grid container xs={4}>
            <Grid container justifyContent="center">
              <img
                className="Imagen1"
                src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img1ro/Sesion29/2.png"
                alt="Tarjeta"
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid
          container
          item
          xs={12}
          className={`${classes.Parrafo1} ${classes.Parrafo}`}
          alignItems="center"
          justifyContent="center"
        >
          <Grid container xs={4}>
            <Grid container justifyContent="center">
              <img
                className="Imagen1"
                src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img1ro/Sesion29/1.png"
                alt="Tarjeta"
              />
            </Grid>
          </Grid>

          <Grid container justifyContent="flex-end" xs={8}>
            <p className="Lectura Lectura1">
              <b className="Subtitulo">Ratón de campo</b> <br />
              —Está bien, está bien. Cada uno de nosotros necesita distintas cosas. Pero, ahora que lo pienso, podemos visitarnos
              y así disfrutar del campo y de la ciudad.
            </p>
          </Grid>
        </Grid>
        <Grid container item xs={12} className={classes.Parrafo3} alignItems="center" justifyContent="center">
          <img
            className="Imagen2"
            src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img1ro/Sesion29/3.png"
            alt="Tarjeta"
          />
        </Grid>
      </Grid>
      <Grid container item style={{ marginTop: 80 }}></Grid>
    </Grid>
  );
};

export default Ratón_de_campo_y_Ratón_de_ciudad;
