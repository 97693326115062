import { Button, Grid, makeStyles } from "@material-ui/core";
import React, { useContext, useEffect } from "react";
import DrawerContext from "../../../../../usability/DrawerContext";
import { useOnLoadImages } from "../../../../hooks/useOnLoadImages";
import Loader from "../../../../Loader/Loader";
import Drawer from "../../ReadingTools/Drawer";

const useStyles =  makeStyles (theme =>({
      Contenedor:{
        background:'#E9FBF9',
        width:'100vw',
        overflowX:'clip',
        [theme.breakpoints.down(1161)]:{
            background:'none',
           overflowX:'unset',
        },
        [theme.breakpoints.down(600)]:{
            marginTop: '79.51px',
        }
    },
  ContainerInfoLectura: {
    position: "relative",
    height: "86px",
    [theme.breakpoints.down(502)]:{
        height: "43px",
    },
    background: "#76D4C3",
    "& .BarraInfoLectura": {
      height: "86px",
      background: `Url(https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Barras/Azul.png)`,
        [theme.breakpoints.down(502)]:{
            height: "43px",
        },
    },
    "& .InfoLectura": {
      position: "absolute",
      height: "86px",
      width: "219px",
      boxSizing: "border-box",
      padding: "17.2px 0",
      background: "#FAFAFA",
      borderRadius: "50px",
      boxShadow: "2px 2px 2px 2px #00000029",
      zIndex: "4",
        [theme.breakpoints.down(502)]:{
            height: "43px",
            width: "109px",
            padding: "8.6px 0",
            borderRadius: "25px",
        },
    },
    "& .InfoLectura:before": {
      position: "absolute",
      display: "flex",
      boxSizing: "border-box",
      padding: "0 0 0 10px",
      justifyContent: "center",
      alignItems: "center",
      height: "39.88px",
      width: "39.88px",
      borderRadius: "50%",
      content: '""',
      boxShadow: "3px 0px .5px 0px #00000029",
      background: "#FAFAFA",
      left: "90px",
      top: "65px",
      transform: "rotate(0.25turn)",
      [theme.breakpoints.down(502)]:{
        left: "45px",
        top: "30px",
        height: "20.88px",
        width: "20.88px",
        },
    },
    "& .InfoLectura:after": {
      content: '""',
      height: "15px",
      width: "15px",
      borderTop: "2px solid #20A698",
      borderLeft: "2px solid #20A698",
      position: "absolute",
      bottom: "-10px",
      left: "Calc(50% - 8px)",
      transform: "rotate(0.625turn)",
      [theme.breakpoints.down(502)]:{
        height: "10px",
        width: "10px",
        left: "Calc(50% - 4.5px)",
        bottom: "-3px",
        borderTop: "1px solid #20A698",
        borderLeft: "1px solid #20A698",
        },
    },
    "& .Sesion": {
      borderLeft: "2px solid #D8D8D8",
    },
    "& .Dato": {
      margin: "0 0 5px 0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#20A698",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        margin: "0 0 2.5px 0",
        },
    },
    "& .Caracter": {
      margin: "0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#000000",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        },
    },
       '& .Regresar':{
        color: '#F2624F',
        border: '#F2624F 1px solid',
        height: '30px',
        width:'100px',
        borderRadius: '20px',
        letterSpacing: '0.14px',
        textTransform: 'none',
        background:'#FEFAF1',
        top:'calc(50% - 15px)',
        left:'5%',
        zIndex:4,
        [theme.breakpoints.down(502)]: {
          font:'bold 10px lato',
          width: "60px",
        },
      },
  },
    ContenedorLectura:{
        background:'#fff',
        width:'1160px',
        height:'100%',
        padding:'60px 113px 35px 168px' ,
        
        transform: (props) =>
        props.scale > 1 ? `scale(${props.scale}) translate(0px, 10%)` : `none`,
                [theme.breakpoints.down(1161)]:{
            width:'507px',
            padding:'60px 0 0',
        },
        [theme.breakpoints.down(507)]:{
            width:'350px',
            padding:'60px 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding:'60px 0 0',
        },
        '& .ContainerDerechos':{
            bottom:'80px',
            [theme.breakpoints.down(1161)]:{
                display:'none',
            }
        },
        '& .Derechos':{
            textAlign:"center",
            margin:"0px",
            font:" 15px lato",
            color:"#717476",
        }, 
        '& .Escritorio':{
            display:'block',
            [theme.breakpoints.down(1161)]:{
                display:'none'
            },
        },
        '& .Tablet':{
            display:'none',
            [theme.breakpoints.down(1161)]:{
                display:'block'
            },
        },  
    },
    ContainerTitulo:{
        margin:'0 0 36px 0',
        [theme.breakpoints.down(1161)]:{
            margin:'0 0 38px 0',
        },
        '& .Titulo':{
            font:'48px fredoka one',
            letterSpacing: '0px',
            color: '#FF2B2B',
            margin:'0 0 15px 0',
            textAlign:'center',
            [theme.breakpoints.down(1161)]:{
                font:'35px fredoka one',
                lineHeight:'52px',
                textAlign:'center',
            },
            [theme.breakpoints.down(502)]:{
                font:'24px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'20px fredoka one',
                lineHeight:'22px',
            },
        },
        '& .SubTitulo':{
            font:'bold 30px lato',
            letterSpacing: '0px',
            color: '#FF2B2B',
            margin:'0',
            textAlign:'center',
            [theme.breakpoints.down(1161)]:{
                font:'bold 26px lato',
                letterSpacing:'.24px',
                lineHeight:'28px',
            },
            [theme.breakpoints.down(502)]:{
                font:'bold 20px lato',
                lineHeight:'24px',
            },
            [theme.breakpoints.down(350)]:{
                font:'bold 18px lato',
                lineHeight:'24px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            lineHeight : '30px',
            margin:'0 0 36px 0 ',
            font:'22px lato',
            color:'#000',
            letterSpacing: '0.20px',
            [theme.breakpoints.down(1161)]:{
                font:'18px lato',
                letterSpacing:'.17px',
                lineHeight:'28px',
            },
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },

        '& .Imagen1':{
           [theme.breakpoints.down(1161)]:{
            width:'100%',
           },
        },

    },
    

}));

const La_infanta_Castile_y_el_insólito_mundo_de_Anomalía_Capitulo_5 = (props) => {

  const {activeStep, setActiveStep, handlePreviewStep, id_actividad} = props

  const handleContinue = () => {
    setActiveStep(activeStep - 1)
    handlePreviewStep()
  }

    const {
    handleSize,
    disabledZoom,
    closeZoom,
    handleZoom,
    scale
  }= useContext(DrawerContext);
  const [loading, setLoading] = React.useState(true);
  const wrapperRef = React.useRef(null);
  const imagesLoaded = useOnLoadImages(wrapperRef);

  const detectSize = () => {
    const lectura = document.getElementById("lectura");
    const newWidth = lectura?.offsetWidth;
    const newHeight = lectura?.offsetHeight;
    setLoading(false);
    if (lectura) handleSize(newHeight, newWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", detectSize);
    return () => {
      handleZoom()
      window.removeEventListener("resize", detectSize);
      closeZoom()
    };
  }, []);

  const classes = useStyles({ disabledZoom,scale });
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
             <Grid container item justifyContent="center" className={classes.ContainerInfoLectura}>
               <Grid container className="BarraInfoLectura">
                    <Button className="Regresar" onClick={handleContinue}>
                        Regresar
                    </Button>
                </Grid>
               <Grid container item className="InfoLectura">
                    <Grid container item direction="column" xs={6} justifyContent="center">
                        <p className="Dato">Grado</p>
                        <p className="Caracter">6</p>
                    </Grid>
                    <Grid container item direction="column" xs={6} justifyContent="center" className="Sesion">
                        <p className="Dato">Sesión</p>
                        <p className="Caracter">31</p>
                    </Grid>
               </Grid>
            </Grid>
{!imagesLoaded && <Loader />}
        <Grid
            container
            item
            className={classes.ContenedorLectura}
            justifyContent="center"
            id="lectura"
            onLoad={detectSize}
            ref={wrapperRef}
        >
<Drawer id_lectura={id_actividad} />
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >
                        La infanta Castile y el insólito<br/>
                        mundo de Anomalía
                    </h2>
                    <h3 className='SubTitulo'>Fragmento 5 (capítulo 5)</h3>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura Escritorio">
                            —¡Hermana! —exclamó Luna. <br/><br/>
                            
                            —No es René —contestó Wil—. El agujero en el jardín… Creo que Ingrid escuchaba a <br/>
                            los alientos erebos ahí. Quieren abandonar este lugar e ir a nuestro mundo. <br/><br/>
                            
                            —Pero ¿cómo logró meterse en el cuerpo de René? <br/><br/>
                            
                            —Porque René se lo permitió. Ellos ya se conocían, ¿recuerdas? <br/><br/>
                            
                            Wil palpó su cabeza y en ella encontró un piojo cavilar. <br/><br/>
                            
                            —¡Alondra, el monocular! —gritó Wil. <br/><br/>
                            
                            El instrumento predilecto de Lus había quedado en el extremo opuesto de la bóveda <br/>
                            cuando fueron sorprendidos por el ataque de Minerva. Alondra miró a Wil desde <br/>
                            lejos y descifró las señas que él le hacía. Dio un brinco heroico y llegó hasta el <br/>
                            instrumento. Alondra le arrojó el monocular a Wil y él depositó el piojo en el interior. <br/>
                            La imagen que se proyectó enseguida fue reveladora. René estaba de pie en su <br/>
                            balcón mirando hacia abajo, con la mirada más triste de todas, cuando sus hermanos <br/>
                            entraron a su habitación.
                        </p>
                        <p className='Lectura Tablet'>
                            —¡Hermana! —exclamó Luna. 
                            <br/><br/>
                            —No es René —contestó Wil—. El agujero en el jardín… Creo que Ingrid escuchaba a los alientos erebos ahí. Quieren abandonar este lugar e ir a nuestro mundo. 
                            <br/><br/>
                            —Pero ¿cómo logró meterse en el cuerpo de René?
                            <br/><br/>
                            —Porque René se lo permitió. Ellos ya se conocían, ¿recuerdas?
                            <br/><br/>
                            Wil palpó su cabeza y en ella encontró un piojo cavilar. 
                            <br/><br/>
                            —¡Alondra, el monocular! —gritó Wil. 
                            <br/><br/>
                            El instrumento predilecto de Lus había quedado en el extremo opuesto de la bóveda cuando fueron sorprendidos por el ataque de Minerva. Alondra miró a Wil desde lejos y descifró las señas que él le hacía. Dio un brinco heroico y llegó hasta el instrumento. Alondra le arrojó el monocular a Wil y él depositó el piojo en el interior. La imagen que se proyectó enseguida fue reveladora. René estaba de pie en su balcón mirando hacia abajo, con la mirada más triste de todas, cuando sus hermanos entraron a su habitación. 
                        </p>
                    </Grid>
                    <Grid container  sm={12}>
                        <Grid container >
                            <img className="Imagen1" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion31/1.png" alt="Tarjeta" />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura Escritorio">
                            —Es el recuerdo después del accidente de mamá, cuando papá no asistió al hospital <br/>
                            —contó Wil. <br/><br/>
                            
                            —René estaba muy triste, la escuchamos llorar. Por eso fuimos a su habitación —dijo <br/>
                            Luna. <br/><br/>
                            
                            —Creo que René escuchó la voz mortífera de Murha, pero fue fuerte y la ignoró <br/>
                            —añadió Wil. <br/><br/>
                            
                            —¿Cómo logró vencerlo? —preguntó Alondra. <br/><br/>
                            
                            En aquel recuerdo, René soltaba algunas lágrimas y sus hermanos se acercaban <br/>
                            lentamente a ella. <br/><br/>
                            
                            —Lo venció al saber que no estaba sola —respondió Wil. <br/><br/>
                            
                            Luna y Wil se tomaron de la mano y avanzaron hacia René, pero ella hizo girar el<br/>
                            florete y lo apuntó hacia sus hermanos. Los chicos se detuvieron por un momento, <br/>
                            aunque después siguieron avanzando. Entonces se desató una batalla en el interior <br/>
                            de René. Se le vio contorsionarse de un lado al otro hasta que lanzó un grito que <br/>
                            inundó el lugar. Luego giró el florete y lo clavó en su propio pecho. <br/><br/>
                            
                            —¡No, René! —gritaron sus hermanos. <br/><br/>
                            
                            El cuerpo de la niña comenzó a convulsionarse y expulsó al último aliento de su <br/>
                            interior, una especie de masa oscura que se perdió en las Bóvedas. El fuego alrededor <br/>
                            de René y de los demás cesó y se concentró en una gran masa incandescente y vivaz. <br/>
                            Una gigantesca libélula de llamas voló dentro de las Bóvedas y les prendió fuego <br/>
                            hasta finalmente dirigirse al cielo oscuro y desaparecer abriendo un enorme agujero <br/>
                            de luz. <br/><br/>
                            
                            —El Fuego fatuo fue liberado. Pronto todo se convertirá en cenizas aquí, debemos <br/>
                            salir —ordenó Alondra, quien cargaba el cuerpo de Minerva en brazos. <br/><br/>
                            
                            Diora corrió a la salida ayudando al almohadero. Wil y Luna, arrinconados, <br/>
                            sostuvieron el cuerpo desvanecido de su hermana. Bartola y Lus se acercaron a los <br/>
                            chicos para ayudarles. <br/><br/>
                            
                            —Estará bien, Wil, eso del veneno mortal de vespa haddavi lo inventé yo para <br/>
                            espantarte. Ahora ustedes también deben irse, es el momento —dijo Lus señalando el <br/>
                            agujero en la superficie—. Nosotros estaremos bien, váyanse. <br/><br/>
                            
                            Wil sacó la lágrima de kraken y abrazó a sus hermanas. Los niños se suspendieron y <br/>
                            desaparecieron lentamente en el cielo.
                        </p>
                        <p className='Lectura Lectura2 Tablet'>
                            —Es el recuerdo después del accidente de mamá, cuando papá no asistió al hospital —contó Wil. 
                                <br/><br/>
                            —René estaba muy triste, la escuchamos llorar. Por eso fuimos a su habitación —dijo Luna. 
                                <br/><br/>
                            —Creo que René escuchó la voz mortífera de Murha, pero fue fuerte y la ignoró —añadió Wil.
                                <br/><br/>
                            —¿Cómo logró vencerlo? —preguntó Alondra. 
                                <br/><br/>
                            En aquel recuerdo, René soltaba algunas lágrimas y sus hermanos se acercaban lentamente a ella. 
                                <br/><br/>
                            —Lo venció al saber que no estaba sola —respondió Wil. 
                                <br/><br/>
                            Luna y Wil se tomaron de la mano y avanzaron hacia René, pero ella hizo girar el florete y lo apuntó hacia sus hermanos. Los chicos se detuvieron por un momento, aunque después siguieron avanzando. Entonces se desató una batalla en el interior de René. Se le vio contorsionarse de un lado al otro hasta que lanzó un grito que inundó el lugar. Luego giró el florete y lo clavó en su propio pecho. 
                                <br/><br/>
                            —¡No, René! —gritaron sus hermanos. 
                                <br/><br/>
                            El cuerpo de la niña comenzó a convulsionarse y expulsó al último aliento de su interior, una especie de masa oscura que se perdió en las Bóvedas. El fuego alrededor de René y de los demás cesó y se concentró en una gran masa incandescente y vivaz. Una gigantesca libélula de llamas voló dentro de las Bóvedas y les prendió fuego hasta finalmente dirigirse al cielo oscuro y desaparecer abriendo un enorme agujero de luz. 
                                <br/><br/>
                            —El Fuego fatuo fue liberado. Pronto todo se convertirá en cenizas aquí, debemos salir —ordenó Alondra, quien cargaba el cuerpo de Minerva en brazos. 
                                <br/><br/>
                            Diora corrió a la salida ayudando al almohadero. Wil y Luna, arrinconados, sostuvieron el cuerpo desvanecido de su hermana. Bartola y Lus se acercaron a los chicos para ayudarles. 
                                <br/><br/>
                            —Estará bien, Wil, eso del veneno mortal de vespa haddavi lo inventé yo para espantarte. Ahora ustedes también deben irse, es el momento —dijo Lus señalando el agujero en la superficie—. Nosotros estaremos bien, váyanse. 
                                <br/><br/>
                            Wil sacó la lágrima de kraken y abrazó a sus hermanas. Los niños se suspendieron y desaparecieron lentamente en el cielo. 

                        </p>
                    </Grid>
                </Grid>
        </Grid>
        <Grid container item style={{ marginTop: 80}} ></Grid>
    </Grid>
    );
}
 
export default La_infanta_Castile_y_el_insólito_mundo_de_Anomalía_Capitulo_5;
