import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: "1200px",
    margin: "0 auto",
  },
  table: {
    // minWidth: "320px",
    "&::-webkit-scrollbar": {
      width: "0.4em",
    },
    "&::-webkit-scrollbar-track": {
      "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,255,0,.1)",
      outline: "1px solid slategrey",
    },
  },
  cell: {
    whiteSpace: "pre-line",
    [theme.breakpoints.down("xs")]: {
      fontSize: "10px",
      padding: "5px",
    },
  },
  centerCell: {
    textAlign: "center",
    whiteSpace: "pre-line",
    [theme.breakpoints.down("xs")]: {
      fontSize: "12px",
      padding: "5px",
    },
  },
}));

const CustimizedTable = (props) => {
  const classes = useStyles();
  const {
    rows,
    firstColumn,
    secondColumn,
    thirdColumn,
    fourthColumn,
    fifthColumn,
    sixthColumn,
  } = props;

  return (
    <TableContainer component={Paper} className={classes.container}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell className={classes.cell}>{firstColumn}</TableCell>
            <TableCell className={classes.centerCell}>{secondColumn}</TableCell>
            <TableCell className={classes.centerCell}>{thirdColumn}</TableCell>
            <TableCell className={classes.centerCell}>{fourthColumn}</TableCell>
            {fifthColumn && (
              <TableCell className={classes.centerCell}>
                {fifthColumn}
              </TableCell>
            )}
            {sixthColumn && (
              <TableCell className={classes.centerCell}>
                {sixthColumn}
              </TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.item1}>
              <TableCell component="th" scope="row" className={classes.cell}>
                {row.item1}
              </TableCell>
              <TableCell className={classes.centerCell}>{row.item2}</TableCell>
              <TableCell className={classes.centerCell}>{row.item3}</TableCell>
              <TableCell className={classes.centerCell}>{row.item4}</TableCell>
              {fifthColumn && (
                <TableCell className={classes.centerCell}>
                  {row.item5}
                </TableCell>
              )}
              {sixthColumn && (
                <TableCell className={classes.centerCell}>
                  {row.item6}
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CustimizedTable;
