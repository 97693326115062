import {Button, Grid, makeStyles } from "@material-ui/core";
import React, { useContext } from "react";
import DrawerContext from "../../../../../usability/DrawerContext";
import Drawer from "../../ReadingTools/Drawer";

const useStyles = makeStyles((theme) => ({
  Contenedor: {
    background: "#E9FBF9",
    width: "100vw",
    [theme.breakpoints.down(1161)]: {
      background: "none",
    },
    [theme.breakpoints.down(600)]: {
      marginTop: "79.51px",
    },
  },
  ContainerInfoLectura: {
    position: "relative",
    height: "86px",
    [theme.breakpoints.down(502)]:{
        height: "43px",
    },
    background: "#76D4C3",
    "& .BarraInfoLectura": {
      height: "86px",
      background: `Url(https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Barras/Azul.png)`,
        [theme.breakpoints.down(502)]:{
            height: "43px",
        },
    },
    "& .InfoLectura": {
      position: "absolute",
      height: "86px",
      width: "219px",
      boxSizing: "border-box",
      padding: "17.2px 0",
      background: "#FAFAFA",
      borderRadius: "50px",
      boxShadow: "2px 2px 2px 2px #00000029",
      zIndex: "4",
        [theme.breakpoints.down(502)]:{
            height: "43px",
            width: "109px",
            padding: "8.6px 0",
            borderRadius: "25px",
        },
    },
    "& .InfoLectura:before": {
      position: "absolute",
      display: "flex",
      boxSizing: "border-box",
      padding: "0 0 0 10px",
      justifyContent: "center",
      alignItems: "center",
      height: "39.88px",
      width: "39.88px",
      borderRadius: "50%",
      content: '""',
      boxShadow: "3px 0px .5px 0px #00000029",
      background: "#FAFAFA",
      left: "90px",
      top: "65px",
      transform: "rotate(0.25turn)",
      [theme.breakpoints.down(502)]:{
        left: "45px",
        top: "30px",
        height: "20.88px",
        width: "20.88px",
        },
    },
    "& .InfoLectura:after": {
      content: '""',
      height: "15px",
      width: "15px",
      borderTop: "2px solid #20A698",
      borderLeft: "2px solid #20A698",
      position: "absolute",
      bottom: "-10px",
      left: "Calc(50% - 8px)",
      transform: "rotate(0.625turn)",
      [theme.breakpoints.down(502)]:{
        height: "10px",
        width: "10px",
        left: "Calc(50% - 4.5px)",
        bottom: "-3px",
        borderTop: "1px solid #20A698",
        borderLeft: "1px solid #20A698",
        },
    },
    "& .Sesion": {
      borderLeft: "2px solid #D8D8D8",
    },
    "& .Dato": {
      margin: "0 0 5px 0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#20A698",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        margin: "0 0 2.5px 0",
        },
    },
    "& .Caracter": {
      margin: "0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#000000",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        },
    },
       '& .Regresar':{
        color: '#F2624F',
        border: '#F2624F 1px solid',
        height: '30px',
        width:'100px',
        borderRadius: '20px',
        letterSpacing: '0.14px',
        textTransform: 'none',
        background:'#FEFAF1',
        top:'calc(50% - 15px)',
        left:'5%',
        zIndex:4,
        [theme.breakpoints.down(502)]: {
          font:'bold 10px lato',
          width: "60px",
        },
      },
  },

  ContenedorLectura: {
    background: "#fff",
    width: "1160px",
    height: "100%",
    position: "relative",
    
    transform: (props) =>
      props.scale > 1 ? `scale(${props.scale}) translate(0px, 10%)` : `none`,
    padding: "60px 168px 35px ",
    [theme.breakpoints.down(1161)]: {
      width: "502px",
      padding: "60px 0 0 0",
    },
    [theme.breakpoints.down(502)]: {
      width: "350px",
      padding: "60px 0 0 0",
    },
    [theme.breakpoints.down(350)]: {
      width: "300px",
      padding: "30px 0 0 0",
    },
    "& .ContainerDerechos": {
      bottom: "80px",
      [theme.breakpoints.down(1161)]: {
        display: "none",
      },
    },
    "& .Derechos": {
      textAlign: "center",
      margin: "0px",
      font: " 15px lato",
      color: "#717476",
    },
    "& .Escritorio": {
      display: "block",
      [theme.breakpoints.down(1161)]: {
        display: "none",
      },
    },
    "& .Tablet": {
      display: "none",
      [theme.breakpoints.down(1161)]: {
        display: "block",
      },
    },
  },
  ContainerTitulo: {
    margin: "0 0 34px 0",
    [theme.breakpoints.down(1161)]: {
      margin: "0 0 34px 0",
    },
    "& .Titulo": {
      font: "48px fredoka one",
      letterSpacing: "0px",
      lineHeight: "58px",
      color: "#FFBD50",
      margin: "0",
      textAlign: "center",
      [theme.breakpoints.down(1161)]: {
        font: "43px fredoka one",
        lineHeight: "52px",
      },
      [theme.breakpoints.down(502)]: {
        font: "30px fredoka one",
        lineHeight: "32px",
      },
    },
  },
  Parrafo1: {
    margin: " 0 0 36px 0",
    "& .Lectura": {
      lineHeight: "30px",
      margin: "0 0 36px 0",
      font: "22px lato",
      color: "#000",
      letterSpacing: "0.20px",
      [theme.breakpoints.down(1161)]: {
        font: "18px lato",
        lineHeight: "28px",
        letterSpacing: "0.17px",
      },
      [theme.breakpoints.down(502)]: {
        font: "12px lato",
        lineHeight :'18px',
        letterSpacing: '0.09px',
      },
    },
    "& .Esp": {
      padding: "0 36px 0 0",
      margin: "0",
      [theme.breakpoints.down(1281)]: {
        padding: "0",
        margin: "0 0 36px 0",
      },
    },
    "& .SubTitulo": {
      font: "bold 30px lato",
      color: "#926DD1",
      letterSpacing: ".28px",
      lineHeight: "30px",
      margin: "0",
      [theme.breakpoints.down(1161)]: {
        lineHeight: "28px",
        font: "bold 26px lato",
        letterSpacing: "0.24px",
      },
      [theme.breakpoints.down(502)]: {
        font: "bold 22px lato",
        lineHeight: "24px",
        letterSpacing: "0.21px",
      },
    },
    "& .SubTitulo2": {
      font: "bold 26px lato",
      color: "#1DA698",
      letterSpacing: ".24px",
      lineHeight: "28px",
      margin: "0",
      [theme.breakpoints.down(1161)]: {
        font: "bold 22px lato",
        lineHeight: "24px",
        letterSpacing: "0.21px",
      },
      [theme.breakpoints.down(502)]: {
        font: "bold 18px lato",
        lineHeight: "20px",
        letterSpacing: "0.16px",
      },
    },
    "& .TextImg": {
      font: "20px lato",
      color: "#717476",
      letterSpacing: ".19px",
      lineHeight: "30px",
      margin: "21px 0 0 0",
      [theme.breakpoints.down(1161)]: {
        font: " 16px lato",
        lineHeight: "25px",
        letterSpacing: "0.15px",
      },
      [theme.breakpoints.down(502)]: {
        font: " 14px lato",
        lineHeight: "21px",
        letterSpacing: "0.15px",
      },
    },

    "& .Imagen1": {
      height: "324px",
      width: "324px",
      [theme.breakpoints.down(1161)]: {
        width: "100%",
      },
    },
    "& .Imagen": {
      [theme.breakpoints.down(1161)]: {
        width: "100%",
      },
    },
  },
}));

const De_qué_está_hecho_el_universo = (props) => {

  const {activeStep, setActiveStep, handlePreviewStep, id_actividad} = props

  const handleContinue = () => {
    setActiveStep(activeStep - 1)
    handlePreviewStep()
  }
  
    const {
      handleSize,
      disabledZoom,
      closeZoom,
      scale,
    } = useContext(DrawerContext);
    const detectSize = () => {
      const lectura = document.getElementById("lectura");

      const newWidth = lectura?.offsetWidth;
      const newHeight = lectura?.offsetHeight;
      if (lectura) handleSize(newHeight, newWidth);
    };
    React.useEffect(() => {
      window.addEventListener("resize", detectSize);
      return () => {
        window.removeEventListener("resize", detectSize);
        closeZoom();
      };
    }, []);

  const classes = useStyles({ disabledZoom, scale });
  return (
    <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
      <Grid container item justifyContent="center" className={classes.ContainerInfoLectura}>
        <Grid container className="BarraInfoLectura">
            <Button className="Regresar" onClick={handleContinue}>
                Regresar
            </Button>
        </Grid>
        <Grid container item className="InfoLectura">
          <Grid container item direction="column" xs={6} justifyContent="center">
            <p className="Dato">Grado</p>
            <p className="Caracter">1</p>
          </Grid>
          <Grid container item direction="column" xs={6} justifyContent="center" className="Sesion">
            <p className="Dato">Sesión</p>
            <p className="Caracter">4</p>
          </Grid>
        </Grid>
      </Grid>

      <Grid
        container
        item
        className={classes.ContenedorLectura}
        justifyContent="center"
        id="lectura"
        onLoad={detectSize}
      >
        <Drawer id_lectura={id_actividad} withoutTransform ={true}/>
        {/* <Whiteboard /> */}
        <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
          <h2 className="Titulo">¿De qué está hecho el universo?</h2>
        </Grid>
        <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
          <Grid container md={12} lg={6} alignContent="flex-end">
            <p className="Lectura Esp">
              Hace millones de años hubo una gran explosión que formó el universo. Fue tan grande que expulsó toda la materia
              necesaria para formar los planetas y las estrellas.
              <br />
              <br />
              El Sol, la Luna, cada piedra, los animales y hasta los seres humanos estamos hechos de las mismas partículas que se
              produjeron en esa explosión. Pero, si todo viene de ahí, entonces ¿por qué el metal es tan diferente de la madera?
            </p>
          </Grid>
          <Grid container item md={12} lg={6} justifyContent="center">
            <Grid item justifyContent="center">
              <img
                className="Imagen1 Escritorio"
                src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img1ro/Sesion4/1.png"
                alt="Tarjeta"
              />
              <img
                className="Imagen Tablet"
                src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img1ro/Sesion4/2.png"
                alt="Tarjeta"
              />
            </Grid>
            <Grid item justifyContent="center">
              <p className="TextImg">Los elementos están presentes en todo lo que está a tu alrededor.</p>
            </Grid>
          </Grid>
        </Grid>

        <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
          <Grid container>
            <p className="Lectura">
              Las partículas que fueron lanzadas en esa explosión se llaman <em>átomos</em> y son tan pequeños que no podemos
              verlos a simple vista. Los átomos son la pieza principal de todos los materiales que conocemos y son diferentes en
              cada uno de ellos.
              <br />
              <br />
              En el mundo se conocen 118 tipos de átomos, es decir, 118 materiales diferentes. Estos materiales se llaman{" "}
              <em>elementos</em> y están organizados en un instrumento llamado
              <em> Tabla periódica</em>.<br />
              <br />
              Algunos de los elementos más conocidos son el oro, la plata y el oxígeno. Algunos son metales, otros son gases y
              otros pocos son tan extraños que se llaman <em>tierras raras</em>. Estos bloques componen todo lo que hay en el
              universo. Aunque ahora te pueden parecer muchos, es posible que todavía nos falten elementos por descubrir.
            </p>

          </Grid>
        </Grid>
      </Grid>
      <Grid container item style={{ marginTop: 80 }}></Grid>
    </Grid>
  );
};

export default De_qué_está_hecho_el_universo;
