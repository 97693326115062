import { makeStyles, Grid, Button } from "@material-ui/core";
import React, { useContext, useEffect } from "react";

import { Link, Redirect } from "react-router-dom";
import ContainerDocente from "../ContainerDocente";

import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import SelectCustomized from "./DetalleSesionFilter";
import axios from "../../../../services/axios";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";

import Reactivos from "./reactivos/Reactivos";
import Caritas from "./herramientas_socioem/Caritas";
import Lectura from "./lecturas/LecturaSesiones";
import Instructions from "./introduccion/Instructions";
import ReadingStrategys from "./estrategias/ReadingStrategys";

import PantallaFinal from "../sesiones/resultados/Sesiones";
import UsabilityContext from "../../../../usability/UsabilityContext";

const useStyles = makeStyles((theme) => ({
  Container: {
    padding: "48px 5% 48px 5%",
    background:
      "linear-gradient(217deg, rgba(255,194,0,0.4962185557816877) 2%, rgba(255,255,255,1) 25%, rgba(255,255,255,0.4962185557816877) 81%, rgba(144,81,177,1) 98%)",
    minHeight: "calc(100vh - 80px)",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    display: "grid",
    position: "relative",
    gap: "20px",
    gridTemplateColumns: "1fr",
    gridTemplateAreas: (props) =>
      props.showFilter
        ? `"filter"
"data"`
        : `"data"`,
    [theme.breakpoints.up("md")]: {
      gridTemplateAreas: (props) =>
        props.showFilter ? `"data filter"` : `"data"`,
      gridTemplateColumns: (props) => (props.showFilter ? `1fr 400px` : `1fr`),
    },
  },
  ContainerReportes: {
    background: "#fff",
    width: "100%",
    minHeight: "70vh",
    borderRadius: "42px",
    gridArea: "data",
    padding: "30px",
    boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.5)",
  },
  ContainerSerchBar: {
    gap: "10%",
    padding: "10px 0",
    justifyContent: "space-between",
  },
  ContainerInput: {
    position: "relative",
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "50%",
      height: "100%",
    },
  },

  SerchBar: {
    outline: "none",
    width: "100%",
    borderRadius: "36px",
    padding: "5px 32px",
    border: "4px solid #EA9D90",
    textAlign: "left",
    letterSpacing: "0.63px",
    color: "#7A54BA",
    font: "600 16px lato",
    // margin: " 0 10%",
    [theme.breakpoints.up("lg")]: {
      height: "auto",
      font: "600 20px lato",
      padding: "10px 32px",
    },
  },
  SerchBarIcon: {
    right: "12.5px",
    top: "10.5px",
    position: "absolute",
    fontSize: "20px",
    color: "#7A54BA",
    [theme.breakpoints.up("sm")]: {
      right: "12.5px",
      top: "12.5px",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "40px",
      right: "25px",
      top: "10px",
    },
  },
  ContainerBarra: {
    height: "498px",
    boxShadow: "0 0 30px 0 rgba(76, 147, 230, 0.1)",
    margin: "0 0 20px 0",
    borderRadius: "8px",
  },

  ContainerLinkinsiginas: {
    textDecoration: "none",
    // marginLeft: "44px",
    // marginBottom: "40px",
    textAlign: "left",
    "& .ButtonIsg": {
      color: "blue",
      textTransform: "none",
      font: "18px lato",
      letterSpacing: "0.36px",
      margin: "0",
      [theme.breakpoints.up("md")]: {
        font: " 20px lato",
      },
      [theme.breakpoints.up("lg")]: {
        font: " 22px lato",
      },
    },
    "& .icon": {
      color: "purple",
      marginRight: "20px",
      fontSize: "30px",
      width: "30px",
      height: "30px",
      [theme.breakpoints.up("md")]: {
        width: "40px",
        height: "40px",
        fontSize: "40px",
      },
      [theme.breakpoints.up("lg")]: {
        width: "55px",
        height: "55px",
        fontSize: "50px",
      },
    },
  },

  titleContainer: {
    margin: "20px 0",
    [theme.breakpoints.up("lg")]: {
      margin: "10px 0",
    },
  },

  title: {
    font: "28px fredoka one",
    textAlign: "center",
    width: "100%",
    margin: "0",
    color: "#DC5EA6",
    [theme.breakpoints.up("sm")]: {
      width: "auto",
      // margin: "0 0 34px 14px",
    },
    [theme.breakpoints.up("md")]: {
      font: "38px fredoka one",
      width: "auto",
      // margin: "0 0 34px 14px",
    },
    [theme.breakpoints.up("lg")]: {
      font: "42px fredoka one",
    },
  },

  linkToUploader: {
    textDecoration: "none",
    // width: '80%',
    // width: "100%",
    maxWidth: "238px",
    // margin: "0 auto",
    // marginLeft: "44px",
    // marginBottom: "40px",
    "& .ButtonIsg": {
      // color: "blue",
      // textTransform: "none",
      // font: " 22px lato",
      // letterSpacing: "0.36px",
      // width: '102px',
      // height: '21px',
      // background: '#FEFAF1 0% 0% no-repeat padding-box',
      // boxShadow: '0px 1px 2px #00000029',
      // border: '1px solid #F2624F',
      // borderRadius: '10px',
    },
    "& .icon": {
      // color: "purple",
      // marginRight: "20px",
      // fontSize: "18px",
      margin: "0",
      [theme.breakpoints.up("lg")]: {
        // fontSize: "30px",
      },
    },
  },
  PurpleButton: {
    color: "#FFFFFF",
    backgroundColor: "#9051b1",
    borderRadius: "30px",
    // margin: "0 auto",
    // width: "100%",
    minWidth: "75px",
    maxWidth: "238px",
    // minHeight: "45px",
    border: "none",
    textTransform: "unset",
    cursor: "pointer",
    fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
    fontWeight: "500",
    padding: "4px 14px",
    lineHeight: "1.5",
    transition:
      "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    boxShadow:
      "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
    "&:hover": {
      backgroundColor: "#B965D3 ",
    },
  },
  WitheButton: {
    color: "#9051b1",
    // borderColor: "#9051b1",
    border: "1px solid #9051b1",
    backgroundColor: "#FFFFFF",
    borderRadius: "30px",
    // margin: "0 auto",
    // width: "100%",
    minWidth: "75px",
    maxWidth: "238px",
    // minHeight: "45px",
    textTransform: "unset",
    cursor: "pointer",
    fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
    fontWeight: "500",
    padding: "4px 14px",
    lineHeight: "1.5",
    transition:
      "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    boxShadow:
      "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
    [theme.breakpoints.up("lg")]: {
      minWidth: "100px",
    },

    "&:hover": {
      backgroundColor: "#B965D330 ",
    },
  },
  link: {
    cursor: "pointer",
    textDecoration: "none",
    // height: "31px",
    // width: "190px",
  },
  backButtonContainer: {
    width: "auto",
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.up("sm")]: {
      justifyContent: "flex-start",
    },
  },
  backButton: {
    cursor: "pointer",
    height: "35px",
    // width: "100%",
    // maxWidth: "200px",
    backgroundColor: "#FDEFF7",
    color: "#DC5EA6",
    border: "#DC5EA6 1px solid",
    borderRadius: "20px",
    textTransform: "none",
    font: "20px Lato",
    letterSpacing: "0.14px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
  },
  buttonFiltro: {
    // gridArea: "button",
  },
  buttonToggle: {
    color: "#9051b1",
    width: "auto",
    textTransform: "unset",
  },
  buttonToggleXs: {
    width: "auto",
    textTransform: "unset",
    color: "#9051b1",
    fontSize: "18px",
    fontFamily: "fredoka one",
    [theme.breakpoints.up("md")]: {
      display: "none",
      transform: "rotation",
    },
  },
  buttonToggleIcon: {
    transform: "rotate(90deg)",

    [theme.breakpoints.up("md")]: {
      transform: (props) =>
        props.showFilter ? `rotate(0deg)` : `rotate(180deg)`,
      fontSize: "36px",
    },
  },
  buttonToggleXsIcon: {
    transform: "rotate(270deg)",
  },

  ContainerFiltro: {
    zIndex: 2,
    display: (props) => (props.showFilter ? `flex` : `none`),
    width: "100%",
    alignItems: "center",
    gap: "10px",
    boxSizing: "border-box",
    gridArea: "filter",
    justifyContent: "space-between",
    paddingBottom: "20px",
    overflowY: "auto",
    overflowX: "hidden",
    position: (props) => (props.showFilter ? `fixed` : "static"),
    top: "80px",
    bottom: "0",
    right: "0",
    left: "0",
    background:
      "linear-gradient(217deg, rgba(255,194,0,1) 2%, rgba(255,255,255,1) 25%, rgba(255,255,255,1) 81%, rgba(144,81,177,1) 98%)",
    [theme.breakpoints.up("md")]: {
      position: () => "sticky",
      background: "transparent",
    },
  },
  ContainerFiltroUp: {
    gap: "10px",
    padding: "20px",
    [theme.breakpoints.up("md")]: {
      padding: "0px",
    },
  },
  containerFiltroTitle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  filtroTitle: {
    font: "22px fredoka one",
    textAlign: "center",
    width: "auto",
    margin: "0",
    [theme.breakpoints.up("sm")]: {
      width: "auto",
    },
    [theme.breakpoints.up("md")]: {
      font: "28px fredoka one",
      width: "auto",
    },
  },
  ContainerAlumnosInfo: {
    display: "flex",
    width: "100%",
    gap: "10px",
  },
  ContainerAlumnosCount: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
  },
  alumnosTitle: {
    font: "18px fredoka one",
    textAlign: "center",
    margin: "0",
    [theme.breakpoints.up("sm")]: {
      width: "auto",
    },
    [theme.breakpoints.up("md")]: {
      font: "22px fredoka one",
      width: "auto",
    },
  },
  alumnosCount: {
    font: "16px fredoka one",
    textAlign: "center",
    margin: "0",
    [theme.breakpoints.up("sm")]: {
      width: "auto",
    },
    [theme.breakpoints.up("md")]: {
      font: "20px fredoka one",
      width: "auto",
    },
  },
  AlumnosCard: {
    display: "flex",
    width: "100%",
    backgroundColor: "#FFFFFF90",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "10px 20px",
    borderRadius: "21px",
  },
  CardCounter: {
    display: "flex",
  },
  CardCounterNumber: {
    font: "30px fredoka one",
    textAlign: "center",
    margin: "0",
    [theme.breakpoints.up("sm")]: {
      width: "auto",
    },
    [theme.breakpoints.up("md")]: {
      font: "32px fredoka one",
      width: "auto",
    },
  },
  CardCounterStatus: {
    font: "16px fredoka one",
    textAlign: "center",
    margin: "0",
    [theme.breakpoints.up("sm")]: {
      width: "auto",
    },
    [theme.breakpoints.up("md")]: {
      font: "22px fredoka one",
      width: "auto",
    },
  },
  subTitle: {
    font: "22px fredoka one",
    textAlign: "Left",
    width: "100%",
    margin: "0",
    color: "#DC5EA6",
    [theme.breakpoints.up("sm")]: {
      width: "auto",
    },
    [theme.breakpoints.up("md")]: {
      font: "32px fredoka one",
      width: "auto",
    },
    [theme.breakpoints.up("lg")]: {
      font: "38px fredoka one",
    },
  },
  gridQuestionTitle: {
    borderBottom: "2px solid black",
    width: "100%",
    margin: "0 0 15px 0",
  },
}));

const DetalleSesion = ({ location }) => {
  const [preguntasList, setPreguntasList] = React.useState([]);
  const [showFilter, setShowFilter] = React.useState(true);
  const classes = useStyles({ showFilter });
  const [activeStep, setActiveStep] = React.useState(0);
  const [sesionLectura, setSesionLectura] = React.useState();
  const [detailSesion, setDetailSesion] = React.useState({});
  const [fromReturn, setFromReturn] = React.useState(false);

  const [topRef, setTopRef] = React.useState({});

  const { handleModalMessage, handleLoading } = useContext(UsabilityContext);

  useEffect(() => {
    let listRef = {};
    for (let index = 0; index < preguntasList.length; index++) {
      let pregunta = preguntasList[index];
      listRef[pregunta.id_pregunta] = React.createRef();
    }

    setTopRef(listRef);
  }, [preguntasList]);

  const { id_grado, id_actividad, id_distribucion_escuela } = location?.state;

  const loadActivitiesList = () => {
    const body = {
      id_actividad: id_actividad,
      id_grado: id_grado,
    };
    handleLoading(true)
    axios
      .post(`/listado/detalle/sesiones`, body)
      .then((response) => {
        if (response.status === 200) {
          const data = response.data.resultado["detalle sesion"];
          const sesionInfo =
            response.data.resultado["detalle sesion"][0].sesion;
          const sesion =
            sesionInfo === "integer" ? String(sesionInfo) : sesionInfo;
          setPreguntasList(data);
          setSesionLectura(String(sesion).replace(" ", "_"));
        }
      })
      .catch((error) => {
        if (error.response) {
          // Request made and server responded
          const errorData = error.response.data;
          const mensaje =
            errorData?.detalles?.msg ||
            "Ha ocurrido un error al consumir los servicios, inténtelo de nuevo más tarde.";
          handleModalMessage({
            habilite: true,
            mensaje: mensaje,
          });
        } else {
          // Something happened in setting up the request that triggered an Error
          const mensaje =
            "Ha ocurrido un error al consumir los servicios, inténtelo de nuevo más tarde.";
          handleModalMessage({
            habilite: true,
            mensaje: mensaje,
          });
        }
      })
      .finally(() => {
        handleLoading(false);
      });
  };

  const loadDetailsSesion = () => {
    const body = {
      id_actividad: Number(id_actividad),
      id_distribucion_escuela: id_distribucion_escuela,
    };
    axios
      .post(`/listado/sesion/alumnos/status`, body)
      .then((response) => {
        if (response.status === 200) {
          const data = response.data.resultado?.Alumnos;
          setDetailSesion(data);
        }
      })
      .catch((error) => {
        console.log(error, "ERRORS");
      });
  };

  const renderGradoLectura = () => {
    switch (id_grado) {
      case 1:
        return "Primero";
      case 2:
        return "Segundo";
      case 3:
        return "Tercero";
      case 4:
        return "Cuarto";
      case 5:
        return "Quinto";
      case 6:
        return "Sexto";
      default:
        break;
    }
  };

  const handleNext = () => {
    setActiveStep(activeStep + 1);
    setFromReturn(false);
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
    setFromReturn(true);
  };

  const handleToggle = () => {
    setShowFilter(!showFilter);
  };

  React.useEffect(() => {
    loadActivitiesList();
    loadDetailsSesion();
  }, []);

  const renderReading = () => {
    if (sesionLectura === "2" || sesionLectura === "59") {
      fromReturn ? setActiveStep(2) : setActiveStep(4);
    } else {
      return (
        <>
          <Grid className={classes.gridQuestionTitle}></Grid>
          {sesionLectura && (
            <Lectura
              gradoLecturas={renderGradoLectura()}
              sesion={sesionLectura}
            />
          )}
        </>
      );
    }
  };

  const renderStategies = () => {
    if (
      sesionLectura === "20" ||
      sesionLectura === "38" ||
      sesionLectura === "55"
    ) {
      fromReturn ? setActiveStep(1) : setActiveStep(3);
    } else {
      return (
        <>
          <Grid className={classes.gridQuestionTitle}></Grid>
          {sesionLectura && (
            <ReadingStrategys
              grado={renderGradoLectura()}
              sesion={sesionLectura}
            />
          )}
        </>
      );
    }
  };

  return (
    <>
      <ContainerDocente>
        <Grid
          container
          item
          sm={12}
          className={classes.Container}
          alignItems="center"
          justifyContent="center"
        >
          <Grid
            container
            item
            direction="column"
            className={classes.ContainerReportes}
          >
            <Grid
              container
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Grid item className={classes.backButtonContainer}>
                <Link className={classes.link} to="/docente/sesiones">
                  <button className={classes.backButton}>
                    <ArrowBackIcon /> Sesiones
                  </button>
                </Link>
              </Grid>
              <Button className={classes.buttonToggle} onClick={handleToggle}>
                {showFilter ? "Ampliar" : "Retraer"}
                <ArrowRightIcon className={classes.buttonToggleIcon} />
              </Button>
              <Grid
                container
                item
                alignItems="center"
                justifyContent="center"
                className={classes.titleContainer}
              ></Grid>
              <Grid
                container
                item
                justifyContent="center"
                alignItems="center"
                className={classes.Sesion}
                sm={12}
              >
                {activeStep === 0 && (
                  <>
                    <Grid className={classes.gridQuestionTitle}></Grid>
                    <Caritas />
                  </>
                )}
                {activeStep === 1 && (
                  <>
                    <Grid className={classes.gridQuestionTitle}></Grid>
                    {sesionLectura ? (
                      <Instructions
                        grado={renderGradoLectura()}
                        sesion={sesionLectura}
                      />
                    ) : (
                      <Redirect
                        to={{
                          pathname: "/error/servicios",
                          state: {
                            reason: "servicios",
                          },
                        }}
                      />
                    )}
                  </>
                )}
                {activeStep === 2 && <>{renderStategies()}</>}
                {activeStep === 3 && <>{renderReading()}</>}
                {activeStep === 4 && (
                  <>
                    <Grid className={classes.gridQuestionTitle}></Grid>
                    {preguntasList.length > 0 && (
                      <Reactivos
                        // key={}
                        preguntasList={preguntasList}
                        evaluacion={preguntasList.length > 10}
                        setTopRef={setTopRef}
                        topRef={topRef}
                        sesionLectura={sesionLectura}
                        idGrado={id_grado}
                      />
                    )}
                  </>
                )}
                {activeStep === 5 && (
                  <>
                    <Grid className={classes.gridQuestionTitle}></Grid>
                    {preguntasList.length > 0 && (
                      <PantallaFinal id_actividad={id_actividad} />
                    )}
                  </>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            item
            direction="column"
            className={classes.ContainerFiltro}
          >
            <Grid
              container
              item
              direction="column"
              className={classes.ContainerFiltroUp}
            >
              <Grid
                container
                item
                direction="row"
                className={classes.containerFiltroTitle}
              >
                <Button
                  className={classes.buttonToggleXs}
                  onClick={handleToggle}
                >
                  {showFilter ? "Retraer" : "Ampliar"}
                  <ArrowRightIcon className={classes.buttonToggleXsIcon} />
                </Button>
              </Grid>
              <Grid
                item
                direction="row"
                className={classes.ContainerAlumnosCount}
              >
                <h4 className={classes.alumnosTitle}>Reactivos</h4>
                <span className={classes.alumnosCount}>
                  {preguntasList.length}
                </span>
              </Grid>
              <SelectCustomized
                list={preguntasList}
                setActiveStep={setActiveStep}
                title="Seleccionar"
                // handleForm={handleForm}
                setTopRef={setTopRef}
                topRef={topRef}
              />
              <Grid
                item
                direction="column"
                className={classes.ContainerAlumnosInfo}
              >
                <Grid
                  item
                  direction="row"
                  className={classes.ContainerAlumnosCount}
                >
                  <h4 className={classes.alumnosTitle}>Alumnos</h4>
                  <span className={classes.alumnosCount}>
                    {detailSesion?.alumnos}
                  </span>
                </Grid>
                <Grid item direction="row" className={classes.AlumnosCard}>
                  <Grid item direction="column" className={classes.CardCounter}>
                    <span className={classes.CardCounterNumber}>
                      {detailSesion?.aplicaron}
                    </span>
                    <span className={classes.CardCounterStatus}>Aplicaron</span>
                  </Grid>
                  <Link to="/docente/reportes/1">
                    <button className={classes.WitheButton}>Ver detalle</button>
                  </Link>
                </Grid>
                <Grid item direction="row" className={classes.AlumnosCard}>
                  <Grid item direction="column" className={classes.CardCounter}>
                    <span className={classes.CardCounterNumber}>
                      {" "}
                      {detailSesion?.noAplicaron}
                    </span>
                    <span className={classes.CardCounterStatus}>
                      No aplicaron
                    </span>
                  </Grid>
                  <Link to="/docente/reportes/1">
                    <button className={classes.WitheButton}>Ver detalle</button>
                  </Link>
                </Grid>
              </Grid>
              <Grid
                container
                item
                direction="row"
                justifyContent="space-evenly"
                className={classes.ContainerFiltroDown}
              >
                <button
                  className={classes.WitheButton}
                  onClick={handleBack}
                  disabled={activeStep === 0}
                >
                  Anterior
                </button>
                <button
                  className={classes.PurpleButton}
                  onClick={handleNext}
                  disabled={activeStep === 5}
                  style={
                    activeStep === 5
                      ? { color: "#B965D3 " }
                      : { color: "#FFFFFF" }
                  }
                >
                  Siguiente
                </button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </ContainerDocente>
    </>
  );
};

export default DetalleSesion;
