import { Button, Grid, makeStyles } from "@material-ui/core";
import React, { useContext, useEffect } from "react";
import DrawerContext from "../../../../../usability/DrawerContext";
import { useOnLoadImages } from "../../../../hooks/useOnLoadImages";
import Loader from "../../../../Loader/Loader";
import Drawer from "../../ReadingTools/Drawer";

const useStyles =  makeStyles (theme =>({
     Contenedor:{
        background:'#E9FBF9',
        width:'100vw',
        overflowX:'clip',
        [theme.breakpoints.down(1161)]:{
            background:'none',
           overflowX:'unset',
        },
        [theme.breakpoints.down(600)]:{
            marginTop: '79.51px',
        }
    },
  ContainerInfoLectura: {
    position: "relative",
    height: "86px",
    [theme.breakpoints.down(502)]:{
        height: "43px",
    },
    background: "#76D4C3",
    "& .BarraInfoLectura": {
      height: "86px",
      background: `Url(https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Barras/Azul.png)`,
        [theme.breakpoints.down(502)]:{
            height: "43px",
        },
    },
    "& .InfoLectura": {
      position: "absolute",
      height: "86px",
      width: "219px",
      boxSizing: "border-box",
      padding: "17.2px 0",
      background: "#FAFAFA",
      borderRadius: "50px",
      boxShadow: "2px 2px 2px 2px #00000029",
      zIndex: "4",
        [theme.breakpoints.down(502)]:{
            height: "43px",
            width: "109px",
            padding: "8.6px 0",
            borderRadius: "25px",
        },
    },
    "& .InfoLectura:before": {
      position: "absolute",
      display: "flex",
      boxSizing: "border-box",
      padding: "0 0 0 10px",
      justifyContent: "center",
      alignItems: "center",
      height: "39.88px",
      width: "39.88px",
      borderRadius: "50%",
      content: '""',
      boxShadow: "3px 0px .5px 0px #00000029",
      background: "#FAFAFA",
      left: "90px",
      top: "65px",
      transform: "rotate(0.25turn)",
      [theme.breakpoints.down(502)]:{
        left: "45px",
        top: "30px",
        height: "20.88px",
        width: "20.88px",
        },
    },
    "& .InfoLectura:after": {
      content: '""',
      height: "15px",
      width: "15px",
      borderTop: "2px solid #20A698",
      borderLeft: "2px solid #20A698",
      position: "absolute",
      bottom: "-10px",
      left: "Calc(50% - 8px)",
      transform: "rotate(0.625turn)",
      [theme.breakpoints.down(502)]:{
        height: "10px",
        width: "10px",
        left: "Calc(50% - 4.5px)",
        bottom: "-3px",
        borderTop: "1px solid #20A698",
        borderLeft: "1px solid #20A698",
        },
    },
    "& .Sesion": {
      borderLeft: "2px solid #D8D8D8",
    },
    "& .Dato": {
      margin: "0 0 5px 0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#20A698",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        margin: "0 0 2.5px 0",
        },
    },
    "& .Caracter": {
      margin: "0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#000000",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        },
    },
       '& .Regresar':{
        color: '#F2624F',
        border: '#F2624F 1px solid',
        height: '30px',
        width:'100px',
        borderRadius: '20px',
        letterSpacing: '0.14px',
        textTransform: 'none',
        background:'#FEFAF1',
        top:'calc(50% - 15px)',
        left:'5%',
        zIndex:4,
        [theme.breakpoints.down(502)]: {
          font:'bold 10px lato',
          width: "60px",
        },
      },
  },
    ContenedorLectura:{
        background:'#fff',
        width:'1160px',
        height:'100%',
        padding:'60px 168px 35px ',
        
        transform: (props) =>
        props.scale > 1 ? `scale(${props.scale}) translate(0px, 10%)` : `none`,
        [theme.breakpoints.down(1161)]:{
            width:'502px',
            padding:'60px 0 0 0',
          },
        [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },

        '& .Escritorio':{
            display:'block',
            [theme.breakpoints.down(1161)]:{
                display:'none'
            },
        },
        '& .Tablet':{
            display:'none',
            [theme.breakpoints.down(1161)]:{
                display:'block'
            },
        }
    },
    ContainerTitulo:{
        margin:'0 0 34px 0',
        [theme.breakpoints.down(1161)]:{
            margin:'0 0 34px 0',
        },
        '& .Titulo':{
            font:'48px fredoka one',
            letterSpacing: '0px',
            lineHeight:'58px',
            color: '#F6644B',
            margin:'0',
            textAlign:'center',
            [theme.breakpoints.down(1161)]:{
                font:'43px fredoka one',
                lineHeight:'52px',
            },
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            lineHeight : '30px',
            margin:'0 0 36px 0',
            font:'22px lato',
            color:'#000',
            letterSpacing: '0.20px',
            [theme.breakpoints.down(1161)]:{
                font:'18px lato',
                lineHeight :'28px',
                letterSpacing: '0.17px',
            },
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .SubTitulo':{
            font:'bold 30px lato',
            color:'#2FA6DE',
            letterSpacing:'.28px',
            lineHeight:'30px',
            margin:'0',
            [theme.breakpoints.down(1161)]:{
                lineHeight :'28px',
                font:'bold 26px lato',
                letterSpacing: '0.24px',
            },
            [theme.breakpoints.down(502)]:{
                font:'bold 18px lato',
                lineHeight :'24px',
                letterSpacing: '0.21px',
            },
        },

        '& .Amarillo':{
            background:'#FEF7E8',
            borderRadius:'100px',
            height:'59px',
            width:'602px',
            margin:'0 0 10px 0',
            [theme.breakpoints.down(1161)]:{
                width:'100%',
            },
            [theme.breakpoints.down(502)]:{
                borderRadius:'50px',
                height:'28px',
            },
        },
        '& .Amarillo2':{
            margin:'0 0 36px 0',
        },
        '& .Esp':{
           margin:'0', 
        },
        '& .Cuadro':{
            position:'relative',
        },
        '& .Esp2':{
            position:'absolute',
            width:'750px',
            margin:'53px 0 0 0',
            [theme.breakpoints.down(1161)]:{
                width:'85.25%',
            },
            [theme.breakpoints.down(502)]:{
                margin:'25px 0 0 0',
                lineHeight :'17px!important',
            },
        },
        '& .Imagen':{
            width:'100%',
        },   
        '& .ImgEsp':{
            width:'100%',
            [theme.breakpoints.down(502)]:{
                height:'195px',
            },
        }, 
    }
}));


const Operaciones_con_conjuntos = (props) => {

  const {activeStep, setActiveStep, handlePreviewStep, id_actividad} = props

  const handleContinue = () => {
    setActiveStep(activeStep - 1)
    handlePreviewStep()
  }

    const {
      handleSize,
    disabledZoom,
    closeZoom,
    handleZoom,
    scale
  }= useContext(DrawerContext);
  const [loading, setLoading] = React.useState(true);
  const wrapperRef = React.useRef(null);
  const imagesLoaded = useOnLoadImages(wrapperRef);

  const detectSize = () => {
    const lectura = document.getElementById("lectura");
    const newWidth = lectura?.offsetWidth;
    const newHeight = lectura?.offsetHeight;
    setLoading(false);
    if (lectura) handleSize(newHeight, newWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", detectSize);
    return () => {
      handleZoom()
      window.removeEventListener("resize", detectSize);
      closeZoom()
    };
  }, []);

  const classes = useStyles({ disabledZoom,scale });
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
             <Grid container item justifyContent="center" className={classes.ContainerInfoLectura}>
                       <Grid container className="BarraInfoLectura">
            <Button className="Regresar" onClick={handleContinue}>
                Regresar
            </Button>
        </Grid>
               <Grid container item className="InfoLectura">
                    <Grid container item direction="column" xs={6} justifyContent="center">
                        <p className="Dato">Grado</p>
                        <p className="Caracter">3</p>
                    </Grid>
                    <Grid container item direction="column" xs={6} justifyContent="center" className="Sesion">
                        <p className="Dato">Sesión</p>
                        <p className="Caracter">8</p>
                    </Grid>
               </Grid>
            </Grid>
            {!imagesLoaded && <Loader />}
        <Grid
            container
            item
            className={classes.ContenedorLectura}
            justifyContent="center"
            id="lectura"
            onLoad={detectSize}
            ref={wrapperRef}
        >
<Drawer id_lectura={id_actividad} />
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >Operaciones<br className='Tablet'/> con conjuntos</h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container sm={12}>
                      <p className="Lectura Esp"> 
                        Los colores del arcoíris son rojo, naranja, amarillo, verde, azul y violeta. Estos colores
                        tienen una relación entre sí y por eso forman un <em>conjunto</em>. De este conjunto podemos
                        hacer dos <em>subconjuntos</em>. Uno conformado por los colores primarios: rojo, amarillo y 
                        azul. Y otro, por los colores secundarios: naranja, verde y violeta.
                      </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img3ro/Sesion08/1.png" alt="Tarjeta" />
                    </Grid>
                </Grid>
               
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container sm={12}>
                      <p className="Lectura "> 
                        Es posible escribir matemáticamente este conjunto y sus dos subconjuntos. Para 
                        comenzar, hay que elegir tres letras mayúsculas, por ejemplo: A, P y S. Estas letras 
                        servirán para nombrar al conjunto y a los subconjuntos. Después, se tienen que 
                        escribir los elementos de cada conjunto dentro de unas llaves como éstas: &#123; &#125;. ¡No 
                        olvides usar comas para separar los elementos!
                      </p>
                    </Grid>
                    <Grid container item justifyContent='center' sm={12}>
                        <Grid container item justifyContent='center' alignContent='center' className='Amarillo'>
                            <p className="Lectura Esp"> 
                                A = &#123;rojo, naranja, amarillo, verde, azul, violeta&#125;
                            </p>
                        </Grid>
                        <Grid container item justifyContent='center' alignContent='center' className='Amarillo'>
                            <p className="Lectura Esp"> 
                                P = &#123;rojo, amarillo, azul&#125;
                            </p>
                        </Grid>
                        <Grid container item justifyContent='center' alignContent='center' className='Amarillo Amarillo2'>
                            <p className="Lectura Esp"> 
                                S = &#123;naranja, verde, violeta&#125;
                            </p>
                        </Grid>
                        <Grid container item justifyContent='center' className='Cuadro' sm={12}>
                            <img className="Imagen Escritorio" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img3ro/Sesion08/2.png" alt="Tarjeta" />
                            <img className="Imagen Tablet" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img3ro/Sesion08/2R.png" alt="Tarjeta" />
                            <p className='Lectura Esp2'>
                                A esta forma de escribir conjuntos se le llama por <em>extensión</em>, pues enumeramos una lista de todos sus elementos.
                            </p>
                        </Grid>
                    </Grid>
                </Grid>
                
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container item justifyContent='center' sm={12}>
                        <Grid container sm={12}>
                            <p className="Lectura "> 
                                <h3 className='SubTitulo'>Unión de conjuntos</h3><br/>
                                La operación que permite hacer operaciones entre conjuntos recibe el nombre de 
                                <em> unión</em>. Como su nombre lo indica, une dos o más conjuntos. La finalidad de unir los 
                                conjuntos es formar uno nuevo. Antes de continuar, recuerda el siguiente ejemplo:
                            </p>
                        </Grid>
                        <Grid container item justifyContent='center' className='Cuadro' sm={12}>
                            <img className="Imagen Escritorio" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img3ro/Sesion08/3.png" alt="Tarjeta" />
                            <img className="ImgEsp Tablet" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img3ro/Sesion08/3R.png" alt="Tarjeta" />
                            <p className='Lectura Esp2'>
                                Imagina que los conjuntos son como unas bolsas mágicas que crecen de 
                                tamaño cada vez que les agregas un objeto. Ahora bien, si tienes dos bolsas y 
                                quieres hacer una sola, ¿qué harías? ¡Exactamente! Abres ambas bolsas y 
                                vacías todos sus elementos dentro de una nueva.
                            </p>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container sm={12}>
                        <p className="Lectura "> 
                            Comencemos. Piensa en dos conjuntos: el de números pares y el de impares. El 
                            primer conjunto estará conformado por los números pares del 0 al 10 y el segundo 
                            por los números impares del 0 al 10. Éstos se escriben de la siguiente manera:
                        </p>
                    </Grid>
                    <Grid container item justifyContent='center' alignContent='center' className='Amarillo'>
                        <p className="Lectura Esp"> 
                            P = &#123;0, 2, 4, 6, 8, 10&#125;
                        </p>
                    </Grid>
                    <Grid container item justifyContent='center' alignContent='center' className='Amarillo'>
                        <p className="Lectura Esp"> 
                            I = &#123;1, 3 ,5 ,7, 9&#125;
                        </p>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container sm={12}>
                        <p className="Lectura "> 
                            Para unirlos, hay que realizar la “operación unión”. La unión puede representarse con 
                            el símbolo ∪. Entonces, la operación se escribiría así: P ∪ I. Y se leería de la siguiente 
                            manera: “P unión I”. Esta unión puede representarse así:
                        </p>
                    </Grid>
                    <Grid container item justifyContent='center' alignContent='center' className='Amarillo'>
                        <p className="Lectura Esp"> 
                            P ∪ I = &#123;0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10&#125;
                        </p>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container sm={12}>
                        <p className="Lectura "> 
                            Pero también podría escribirse con un orden distinto. Por ejemplo, podrían aparecer 
                            primero los elementos del conjunto P y luego los elementos del I:
                        </p>
                    </Grid>
                    <Grid container item justifyContent='center' alignContent='center' className='Amarillo'>
                        <p className="Lectura Esp"> 
                            P ∪ I = &#123;0, 2, 4, 6, 8, 10, 1, 3, 5, 7, 9&#125;
                        </p>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container item justifyContent='center' sm={12}>
                        <Grid container sm={12}>
                            <p className="Lectura Esp"> 
                                Recuerda: lo importante en la unión es que no falte ningún elemento de los conjuntos 
                                que se unen.<br/><br/>
                                
                                <h3 className='SubTitulo'>Intersección de conjuntos</h3><br/>
                                
                                Otra operación que permite realizar procedimientos entre conjuntos es la 
                                intersección. Ésta se forma a partir de los elementos que tienen en común dos o más 
                                conjuntos. Y también hace que dos conjuntos hagan uno nuevo.
                            </p>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container item justifyContent='center' className='Cuadro' sm={12}>
                        <img className="Imagen Escritorio" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img3ro/Sesion08/4.png" alt="Tarjeta" />
                        <img className="Imagen Tablet" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img3ro/Sesion08/4R.png" alt="Tarjeta" />
                        <p className='Lectura Esp2'>
                            Si los conjuntos son como bolsas mágicas, la <em>intersección</em> consistiría en abrir 
                            cada bolsa, extraer los objetos que se repiten y formar con éstos un nuevo conjunto.
                        </p>
                    </Grid>
                </Grid>
    
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container sm={12}>
                        <p className="Lectura "> 
                            Por ejemplo, imagina los siguientes conjuntos:
                        </p>
                    </Grid>
                    <Grid container item justifyContent='center' alignContent='center' className='Amarillo'>
                        <p className="Lectura Esp"> 
                            D =  &#123;1, 2, 3, 4, 5, 6 &#125;
                        </p>
                    </Grid>
                    <Grid container item justifyContent='center' alignContent='center' className='Amarillo'>
                        <p className="Lectura Esp"> 
                            E =  &#123;1, 2, 3, 5, 8, 13… &#125;
                        </p>
                    </Grid>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container sm={12}>
                        <p className="Lectura "> 
                            Los elementos que se repiten tanto en el conjunto D como en el E son: 1, 2, 3 y 5. La 
                            intersección puede representarse con el símbolo <span style={{fontVariant: "all-small-caps"}}>∩</span>. Entonces, la operación se 
                            escribiría así: D <span style={{fontVariant: "all-small-caps"}}>∩</span> E. Y se leería así: “D intersección E”. Esta intersección puede 
                            representarse de la siguiente manera:
                        </p>
                    </Grid>
                    <Grid container item justifyContent='center' alignContent='center' className='Amarillo'>
                        <p className="Lectura Esp"> 
                            D ∩ E =  &#123;1, 2, 3, 5 &#125;
                        </p>
                    </Grid>
                </Grid>
        </Grid>
        <Grid container item style={{ marginTop: 80}} ></Grid>
    </Grid>
    );
}
 
export default Operaciones_con_conjuntos;
