import React, { useCallback, useContext, useEffect, useState } from "react";
import { Grid, makeStyles } from "@material-ui/core";
import ContainerDocente from "../ContainerDocente";
import axios from "../../../../services/axios";
import UsabilityContext from "../../../../usability/UsabilityContext";
import ResourceCard from "../../Docente/recursos_docente/ResourceCard";

const useStyles = makeStyles((theme) => ({
  ContainerBiblioteca: {
    height: "100%",
    background: "#E9FBF9",
    [theme.breakpoints.up(1921)]: {
      height: "100vh",
    },
  },
  TopBar: {
    height: "172px",
    background:
      "transparent linear-gradient(103deg, #4EA398 0%, #7848DA 100%) 0% 0% no-repeat padding-box;",
  },
  ContainerLecturas: {
    position: "absolute",
    textAlign: "center",
    // height:'873px',
    width: "100%",
    maxWidth: "1160px",
    background: "#FFFFFF ",
    top: "12vh",
    boxShadow: "0px 5px 9px #00000029",
    borderRadius: "38px 38px 0 0",
    paddingTop: "3.73%",
    boxSizing: "border-box",
    "& .Titulo": {
      margin: "0",
      font: "48px Fredoka One",
      textSlign: "center",
      letterSpacing: "0px",
      color: "#7058CE",
      opacity: 1,
    },
    [theme.breakpoints.down(1280)]: {
      // width:'806px',
      borderRadius: "0",
    },
  },
  ContainerSerchBar: {
    position: "relative",
    height: "68px",
    marginBottom: "75px",
  },
  SerchBar: {
    outline: "none",
    height: "100%",
    width: "49.31%",
    borderRadius: "36px",
    padding: "0 32px",
    border: "4px solid #EA9D90",
    textAlign: "left",
    letterSpacing: "0.63px",
    color: "#7A54BA",
    font: "600 20px lato",
    margin: " 0 10%",
  },
  SerchBarIcon: {
    right: "25%",
    top: "25%",
    position: "absolute",
    fontSize: "40px",
    color: "#7A54BA",
  },
  ContainerLecturasImagenes: {
    minHeight: "60vh",
    padding: "0 50px",
  },
  emptyGrid: {
    margin: "10px",
  },
}));

const ResourceCardWithContainer = ({ video }) => {
  return (
    <>
      <Grid
        container
        item
        xs={12}
        md={4}
        justifyContent="center"
        alignItems="center"
      >
        <p>Grado: {video.grado}</p>
        <ResourceCard
          title={`Video ${video.nombre}`}
          data={video.recurso}
          type="video"
          num="4"
          content="video"
        />
      </Grid>
    </>
  );
};

const VideosRemediales = (props) => {
  const classes = useStyles();
  const [videosList, setVideosList] = useState([]);
  const { groupData, handleLoading, handleModalMessage } =
    useContext(UsabilityContext);

  const getListOfVideos = useCallback(() => {
    handleLoading(true);
    axios
      .get(`actividad/recursos/video/remedial?grado=${groupData?.id_grado}`)
      .then((response) => {
        if (response.status === 200) {
          // setVideosList([]);
          setVideosList(response.data.resultado.video_remedial);
        }
      })
      .catch((error) => {
        if (error.response) {
          // Request made and server responded
          const errorData = error.response.data;
          const mensaje =
            errorData?.detalles?.msg ||
            "Ha ocurrido un error al consumir los servicios, inténtelo de nuevo más tarde.";
          handleModalMessage({
            habilite: true,
            mensaje: mensaje,
          });
        } else {
          // Something happened in setting up the request that triggered an Error
          const mensaje =
            "Ha ocurrido un error al consumir los servicios, inténtelo de nuevo más tarde.";
          handleModalMessage({
            habilite: true,
            mensaje: mensaje,
          });
        }
      })
      .finally(() => {
        handleLoading(false);
      });
  }, [groupData?.id_grado, handleLoading, handleModalMessage]);

  useEffect(() => {
    if (groupData?.id_grado) getListOfVideos();
  }, [getListOfVideos, groupData]);

  return (
    <ContainerDocente>
      <Grid
        container
        item
        sm={12}
        justifyContent="center"
        className={classes.ContainerBiblioteca}
      >
        <Grid container item sm={12} className={classes.TopBar}></Grid>
        <Grid container item className={classes.ContainerLecturas}>
          <Grid container item sm={12} direction="row" justifyContent="center">
            <Grid container item sm={3} justifyContent="center"></Grid>
            <Grid container item sm={6} justifyContent="center">
              <p className="Titulo">Videos remediales</p>
            </Grid>
            <Grid container item sm={3} justifyContent="center"></Grid>
          </Grid>
          {/* <Grid container item sm={12} justifyContent="center" alignItems="center" className={classes.ContainerSerchBar}>
              </Grid> */}
          <Grid
            container
            item
            direction="row"
            sm={12}
            justifyContent="center"
            alignItems="center"
            className={classes.ContainerLecturasImagenes}
          >
            {videosList.length > 0
              ? videosList?.map((video) => {
                  return (
                    <ResourceCardWithContainer
                      key={video?.id_recurso_actividad}
                      video={video}
                    />
                  );
                })
              : null}
          </Grid>
          <Grid className={classes.emptyGrid}></Grid>
        </Grid>
      </Grid>
    </ContainerDocente>
  );
};

export default VideosRemediales;
