import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
    ContenedorLectura:{
        width:'502px',
                  [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
    },
    ContainerTitulo:{
        margin:'0 0 34px 0',
        '& .Titulo':{
            letterSpacing: '0px',
            color: '#4F0F04',
            margin:'0',
            textAlign:'center',
            font:'43px fredoka one',
            lineHeight:'52px',
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            margin:'0 0 36px 0',
            color:'#000',
            font:'18px lato',
            lineHeight :'28px',
            letterSpacing: '0.17px',
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .SubTitulo':{
            color:'#7C5805',
            margin:'0',
            lineHeight :'28px',
            font:'bold 26px lato',
            letterSpacing: '0.24px',
            [theme.breakpoints.down(502)]:{
                font:'bold 18px lato',
                lineHeight :'24px',
                letterSpacing: '0.21px',
            },
        },
        '& .Lectura2':{
            margin:'0',
        },
        '& .ul':{
            margin:'0 0 36px 0',
            padding:'0 0 0 19px',
            color:'#7C5805',
        },
        '& .li':{
            margin:'0 0 8px 0',
            [theme.breakpoints.down(502)]:{
                margin:'0 0 4px 0',
            },
        },
        '& .TextImg':{
            color:'#717476',
            margin:'24px 0 0 0',
            font:' 16px lato',
            lineHeight :'25px',
            letterSpacing: '0.15px',
            [theme.breakpoints.down(502)]:{
                font:' 11px lato',
                lineHeight :'14px',
                letterSpacing: '0.10px',
            },
        },
        '& .Pos':{
            position:'absolute',
            margin:'53px 0 0 0',
            width:'87.70%',
            [theme.breakpoints.down(502)]:{
                margin:'18px 0 0 0',
                width:'92%',
            },
        },  
        '& .Cuadro':{
            position:'relative'
        }, 
        '& .Imagen':{
            width:'100%',
        },  
      
    },

}));

const El_cacao_es_más_que_la_base_para_una_deliciosa_bebida = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
  
            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >
                    El cacao es más que la base para una deliciosa bebida
                    </h2>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container justifyContent="center" sm={12} >
                        <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion39R/1.png" alt="Tarjeta" />
                    </Grid>
                    <Grid container  sm={12} >
                        <p className='TextImg'>En Mesoamérica, los frutos del cacao y las bebidas que se elaboraban con él eran considerados un artículo de lujo.</p>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container justifyContent="center" sm={12} >
                        <p className='Lectura'>
                            El consumo de cacao en América comenzó hace milenios. Según un estudio publicado en 2018, 
                            la evidencia más antigua data de hace aproximadamente 5 300 años y se encontró en Santa Ana-La 
                            Florida, Ecuador. Esto prueba que el origen del árbol está en el sur del continente, no en el 
                            centro, y que el cacaotero se distribuyó gracias al comercio.
                        </p> 
                    </Grid>
                    <Grid container justifyContent="center" sm={12} className='Cuadro' >
                        <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion39R/3R.png" alt="Tarjeta" />
                        <p className='Lectura Pos'>
                            En Mesoamérica se desarrollaron distintas culturas que compartían varias características. La región abarcó Guatemala, Belice, El Salvador y Honduras, además de una parte de México y el occidente de Nicaragua y Costa Rica.
                        </p>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container justifyContent="center" sm={12} >
                        <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion39R/2.png" alt="Tarjeta" />
                    </Grid>
                    <Grid container  sm={12} >
                        <p className='TextImg'>
                            El árbol de cacao (o cacaotero) es originario de las zonas tropicales y subtropicales de América. 
                            De sus frutos se extraen las semillas de cacao con las cuales se elabora el chocolate.
                        </p>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container justifyContent="center" sm={12} >
                        <p className='Lectura'>
                            <h3 className='SubTitulo'>Una moneda muy antigua</h3><br/>

                            Quizás has oído que la semilla del cacao servía como moneda. Una moneda es una 
                            herramienta para intercambiar mercancías. Y la mercancía es un producto que se 
                            consigue porque sirve para algo. Por ejemplo, el chocolate tradicional es una bebida 
                            que sacia y da energía. Tiene una función y adquiere un valor por el hecho de que 
                            alguien la posea. La moneda también tiene un valor, pero es uno especial:
                        </p> 
                        <ul className='ul'>
                            <li className='li'>
                                <p className='Lectura Lectura2'>
                                    Sirve como unidad de medida para calcular el precio de las cosas. 
                                    Te ayuda a comparar qué tanto vale una cosa comparada con otras.
                                </p>
                            </li>
                            <li className='li'>
                                <p className='Lectura Lectura2'>
                                    Representa un valor, es decir, tiene el valor posible de las cosas que podrías comprar con ella.
                                </p>
                            </li>
                            <li className='li'>
                                <p className='Lectura Lectura2'>
                                    Es un intermediario en el comercio. Si tienes una moneda (cuyo valor resulta imaginario), 
                                    la puedes cambiar por un producto (el cual tiene una utilidad, es decir, un valor concreto).
                                </p>
                            </li>
                            <li className='li'>
                                <p className='Lectura Lectura2'>
                                Sirve como un medio para pagar deudas.
                                </p>
                            </li>
                            <li className='li'>
                                <p className='Lectura Lectura2'>
                                Funciona como reserva de valor: puede guardarse y posteriormente intercambiarse sin que pierda su valor.
                                </p>
                            </li>
                        </ul>
                        <p className='Lectura'>
                            Distintos objetos en diferentes épocas han sido utilizados como moneda alrededor del mundo. La civilización maya 
                            empezó a usar la semilla del cacao a partir del siglo VII, pero su empleo se generalizó en Mesoamérica para 
                            intercambiar productos en los mercados y pagar tributos hasta 100 años después. Aunque éste no era el único 
                            objeto utilizado como moneda por ellos, también usaban otros objetos valiosos, como textiles de algodón.
                        </p>
                    </Grid>
                    <Grid container justifyContent='center'>
                        <Grid container justifyContent="center" sm={12}  >
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion39R/4.png" alt="Tarjeta" />
                        </Grid>
                        <Grid container justifyContent="center" sm={12} className='Cuadro' >
                            <p className='TextImg'>
                                A diferencia de otros murales de la civilización maya, los de Calakmul representan 
                                escenas de la vida cotidiana (como las actividades comerciales).
                            </p>                        
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container justifyContent="center" sm={12} >
                        <p className='Lectura'>
                            Los murales son evidencia arqueológica crucial del uso del cacao como moneda. Un ejemplo 
                            de su utilidad en los mercados quedó plasmado en un mural de Calakmul, en México. En él 
                            se puede observar a una mujer ofreciendo un tazón con algo que parece chocolate a cambio
                            de masa para hacer tamales. Respecto a su uso en el pago de tributos, se pueden mencionar 
                            los murales de Bonampak, también localizados en México. En una de las escenas representadas 
                            se aprecia que se incluían sacos de cacao.
                        </p> 
                    </Grid>
                    <Grid container justifyContent="center" sm={12}  >
                        <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion39R/5.png" alt="Tarjeta" />
                    </Grid>
                </Grid>
        </Grid>
    </Grid>
    );
}
 
export default El_cacao_es_más_que_la_base_para_una_deliciosa_bebida;
