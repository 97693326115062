import { Button, Grid, makeStyles } from "@material-ui/core";
import React, { useContext, useEffect } from "react";
import DrawerContext from "../../../../../usability/DrawerContext";
import { useOnLoadImages } from "../../../../hooks/useOnLoadImages";
import Loader from "../../../../Loader/Loader";
import Drawer from "../../ReadingTools/Drawer";

const useStyles =  makeStyles (theme =>({
      Contenedor:{
        background:'#E9FBF9',
        width:'100vw',
        overflowX:'clip',
        [theme.breakpoints.down(1161)]:{
            background:'none',
           overflowX:'unset',
        },
        [theme.breakpoints.down(600)]:{
            marginTop: '79.51px',
        }
    },
  ContainerInfoLectura: {
    position: "relative",
    height: "86px",
    [theme.breakpoints.down(502)]:{
        height: "43px",
    },
    background: "#76D4C3",
    "& .BarraInfoLectura": {
      height: "86px",
      background: `Url(https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Barras/Azul.png)`,
        [theme.breakpoints.down(502)]:{
            height: "43px",
        },
    },
    "& .InfoLectura": {
      position: "absolute",
      height: "86px",
      width: "219px",
      boxSizing: "border-box",
      padding: "17.2px 0",
      background: "#FAFAFA",
      borderRadius: "50px",
      boxShadow: "2px 2px 2px 2px #00000029",
      zIndex: "4",
        [theme.breakpoints.down(502)]:{
            height: "43px",
            width: "109px",
            padding: "8.6px 0",
            borderRadius: "25px",
        },
    },
    "& .InfoLectura:before": {
      position: "absolute",
      display: "flex",
      boxSizing: "border-box",
      padding: "0 0 0 10px",
      justifyContent: "center",
      alignItems: "center",
      height: "39.88px",
      width: "39.88px",
      borderRadius: "50%",
      content: '""',
      boxShadow: "3px 0px .5px 0px #00000029",
      background: "#FAFAFA",
      left: "90px",
      top: "65px",
      transform: "rotate(0.25turn)",
      [theme.breakpoints.down(502)]:{
        left: "45px",
        top: "30px",
        height: "20.88px",
        width: "20.88px",
        },
    },
    "& .InfoLectura:after": {
      content: '""',
      height: "15px",
      width: "15px",
      borderTop: "2px solid #20A698",
      borderLeft: "2px solid #20A698",
      position: "absolute",
      bottom: "-10px",
      left: "Calc(50% - 8px)",
      transform: "rotate(0.625turn)",
      [theme.breakpoints.down(502)]:{
        height: "10px",
        width: "10px",
        left: "Calc(50% - 4.5px)",
        bottom: "-3px",
        borderTop: "1px solid #20A698",
        borderLeft: "1px solid #20A698",
        },
    },
    "& .Sesion": {
      borderLeft: "2px solid #D8D8D8",
    },
    "& .Dato": {
      margin: "0 0 5px 0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#20A698",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        margin: "0 0 2.5px 0",
        },
    },
    "& .Caracter": {
      margin: "0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#000000",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        },
    },
       '& .Regresar':{
        color: '#F2624F',
        border: '#F2624F 1px solid',
        height: '30px',
        width:'100px',
        borderRadius: '20px',
        letterSpacing: '0.14px',
        textTransform: 'none',
        background:'#FEFAF1',
        top:'calc(50% - 15px)',
        left:'5%',
        zIndex:4,
        [theme.breakpoints.down(502)]: {
          font:'bold 10px lato',
          width: "60px",
        },
      },
  },
    ContenedorLectura:{
        background:'#fff',
        width:'1160px',
        height:'100%',
        position:'relative',
        padding:'60px 168px 35px ',
        
        transform: (props) =>
        props.scale > 1 ? `scale(${props.scale}) translate(0px, 10%)` : `none`,
        [theme.breakpoints.down(1161)]:{
            width:'502px',
            padding:'60px 0 0',
          },
          [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
        '& .Img':{
            position:'absolute',
            left:'665.57px',
            top:'9px',
            height:'139.91px',
            [theme.breakpoints.down(1161)]:{
                left:'335px',
                top:'25px',
                height:'107px',
            },
            [theme.breakpoints.down(502)]:{
                left:'235px',
                top:'25px',
                height:'90px',
            },
            [theme.breakpoints.down(350)]:{
                left:'196px',
                top:'25px',
                height:'76px',
            },
        },
        '& .Img2':{
            position:'absolute',
            left:'100px',
            top:'1770px',
            height:'77.62px',
            [theme.breakpoints.down(1161)]:{
                left:'-50px',
                height:'60px',
                top:'1780px',
            },
            [theme.breakpoints.down(502)]:{
                top: '1574px',
                left: '-20px',
                height:'50px',
            },
            [theme.breakpoints.down(350)]:{
                top: '1234px',
                left: '-20px',
                height:'36px',
            },

        },
        '& .Img3':{
            position:'absolute',
            left:'899.74px',
            top:'2630px',
            height:'125.56px',
            [theme.breakpoints.down(1161)]:{
                left:'415px',
                height:'90px',
                top:'2800px',
            },
            [theme.breakpoints.down(502)]:{
                top: '2529px',
                left: '264px',
                height: '44px',
            },
            [theme.breakpoints.down(350)]:{
                top: '1982px',
                left: '216px',
                height: '40px',
            },
        },
        '& .ContainerDerechos':{
            bottom:'80px',
            [theme.breakpoints.down(1161)]:{
                display:'none',
            },
        },
        '& .Derechos':{
            textAlign:"center",
            margin:"0px",
            font:" 15px lato",
            color:"#717476",
            
        },
        '& .Escritorio':{
            display:'block',
            [theme.breakpoints.down(1161)]:{
                display:'none'
            },
        },
        '& .Tablet':{
            display:'none',
            [theme.breakpoints.down(1161)]:{
                display:'block'
            },
        },
     
    },
    ContainerTitulo:{
        margin:'0 0 46px 0',
        [theme.breakpoints.down(1161)]:{
            margin:'0 0 38px 0',
        },
        '& .Titulo':{
            font:'48px fredoka one',
            letterSpacing: '0px',
            color: '#53756E',
            margin:'0',
            textAlign:'center',
            [theme.breakpoints.down(1161)]:{
                font:'43px fredoka one',
                lineHeight:'52px',
                textAlign:'center',
            },
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        position:'relative',
        margin:'0 0 36px 0',
        '& .Lectura':{
            margin:'0 ',
            font:'22px lato',
            color:'#000',
            letterSpacing: '0.20px',
            lineHeight:'30px',
            [theme.breakpoints.down(1161)]:{
                lineHeight :'28px',
                font:'18px lato',
                letterSpacing: '0.17px',
            },
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        }, 
        '& .TextImg':{
            font:'20px lato',
            color:'#717476',
            margin:'24px 0 0 0 ',
            letterSpacing:'.19px',
            [theme.breakpoints.down(1161)]:{
                font:' 16px lato',
                letterSpacing:'.15px',
            },
            [theme.breakpoints.down(502)]:{
                font:' 11px lato',
                lineHeight :'14px',
                letterSpacing: '0.10px',
            },
        },
        '& .Imagen':{
            [theme.breakpoints.down(1161)]:{
                width:'100%',
            },
        },
    },
}));

const Zombie = (props) => {

  const {activeStep, setActiveStep, handlePreviewStep, id_actividad} = props

  const handleContinue = () => {
    setActiveStep(activeStep - 1)
    handlePreviewStep()
  }

    const {
    handleSize,
    disabledZoom,
    closeZoom,
    handleZoom,
    scale
  }= useContext(DrawerContext);
  const [loading, setLoading] = React.useState(true);
  const wrapperRef = React.useRef(null);
  const imagesLoaded = useOnLoadImages(wrapperRef);

  const detectSize = () => {
    const lectura = document.getElementById("lectura");
    const newWidth = lectura?.offsetWidth;
    const newHeight = lectura?.offsetHeight;
    setLoading(false);
    if (lectura) handleSize(newHeight, newWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", detectSize);
    return () => {
      handleZoom()
      window.removeEventListener("resize", detectSize);
      closeZoom()
    };
  }, []);

  const classes = useStyles({ disabledZoom,scale });
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
             <Grid container item justifyContent="center" className={classes.ContainerInfoLectura}>
               <Grid container className="BarraInfoLectura">
                    <Button className="Regresar" onClick={handleContinue}>
                        Regresar
                    </Button>
                </Grid>
               <Grid container item className="InfoLectura">
                    <Grid container item direction="column" xs={6} justifyContent="center">
                        <p className="Dato">Grado</p>
                        <p className="Caracter">6</p>
                    </Grid>
                    <Grid container item direction="column" xs={6} justifyContent="center" className="Sesion">
                        <p className="Dato">Sesión</p>
                        <p className="Caracter">16</p>
                    </Grid>
               </Grid>
            </Grid>
{!imagesLoaded && <Loader />}
        <Grid
            container
            item
            className={classes.ContenedorLectura}
            justifyContent="center"
            id="lectura"
            onLoad={detectSize}
            ref={wrapperRef}
        >
<Drawer id_lectura={id_actividad} withoutTransform ={true} />
            <img src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion16/1.png" className='Img' alt="Hormiga" />
            <img src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion16/3.png" className='Img2' alt="Hormiga" />
            <img src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion16/4.png" className='Img3' alt="Hormiga" />
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >
                    ¿Zombis?
                    </h2>
                </Grid>
                <Grid container item className={classes.Parrafo1} justifyContent="center" >
                    <p className='Lectura '>
                        El investigador Kong caminaba un día a través de la selva tailandesa en busca de las 
                        hormigas <em>Camponotus leonardi</em>, conocidas comúnmente como “hormigas zombis”. <br/><br/>
                        
                        Él se preguntaba la diferencia entre estar vivo y muerto, pues el nombre de 
                        “hormigas zombis” le parecía extraño. Recordaba lo que había hecho ese día. Por la
                        mañana, había buceado y observado peces, corales, medusas y un sinnúmero de 
                        seres que se movían de un lado a otro. Sin duda, eso era estar vivo. Por la tarde, había 
                        pescado y los peces que comió estaban muertos, por supuesto. Pero un zombi no es 
                        ni lo uno ni lo otro. <br/><br/>
                        
                        El brujo de la isla donde había pasado la noche le dijo que para encontrar a las 
                        famosas hormigas tendría que subir a las copas de los árboles, el lugar donde vivían. 
                        Cuando por fin llegó a un árbol que creyó el indicado, comenzó a escalarlo. <br/><br/>
                        
                        El brujo le había explicado que esas hormigas son llamadas zombis debido a que se 
                        infectan con un hongo científicamente conocido como <em>Ophiocordyceps unilateralis</em>, 
                        pero que él prefería llamar “Cordy”. Este hongo las intoxica de tal modo que se 
                        apropia de su voluntad y modifica su comportamiento para que bajen de los árboles o 
                        se caigan a un sitio más húmedo y con sombra, el cual es un lugar más adecuado para 
                        que ese tipo de hongo se reproduzca y crezca. <br/><br/>
                        
                        Kong no entendía cómo lograban tomar el control de las hormigas. El brujo le explicó 
                        que este tipo de hongo expulsa esporas; es decir, un tipo de partículas que sirven 
                        para reproducirse. Algunas veces, éstas caen en las hormigas y, una vez que están en 
                        ellas, entran en su cerebro para intoxicarlas. Entonces, las hormigas comienzan a 
                        actuar de manera cada vez más extraña y bajan de las copas de los árboles, lejos de 
                        sus nidos. Justo antes de morir, muerden una hoja ideal para que el hongo crezca y 
                        ahí se quedan pegadas.<br/><br/>
                    </p>

                    <img className='Imagen' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion16/2.png" alt="Hormiga" />
                    <Grid container item justifyContent="center" >
                       <p className='TextImg'>
                            Proceso de muerte de la hormiga y posterior crecimiento del hongo<br/><br/>
                        </p>
                    </Grid>
                    <p className='Lectura '> 
                        La hormiga muere para que el hongo crezca en ella y posteriormente encuentre a 
                        otras hormigas que lleven las esporas a más sitios para seguir reproduciéndose. <br/><br/>
                        
                        Kong habría buscado en el lugar equivocado si no hubiera escuchado a su amigo 
                        brujo. Ahora, tenía que escalar a lo más alto para encontrar una colonia de hormigas 
                        e iniciar la búsqueda de algunas que tuvieran un comportamiento diferente al resto. 
                        Así podría seguirlas en su probable descenso hacia su destino final. <br/><br/>
                        
                        Estas hormigas zombis recuerdan a los muertos vivientes, personajes inquietantes 
                        del cine de terror. En este tipo de películas, un mago o hechicero revive a los 
                        muertos, luego toma el control de su cerebro y de su voluntad para que después 
                        cumplan sus deseos. <br/><br/>
                        
                        Kong estaba entusiasmado porque era fanático de ese tipo de películas. Pensaba que 
                        al estudiar profundamente el fenómeno de las hormigas y los hongos podría 
                        encontrar la manera de llevar a cabo el mismo control en los humanos, de tal manera 
                        que hicieran su voluntad. Lamentablemente para él, eso no es posible. <br/><br/>
                        
                        Su amigo le platicó que en Haití algunos brujos que practican vudú utilizan un veneno 
                        producido por los peces globo, llamado <em>tetrodotoxina</em> y conocido comúnmente como 
                        TTX. Se cree que puede lograr que los seres humanos estén muertos aparentemente,
                        pero sin dejar de estar conscientes, para hacer la voluntad de quien los intoxique. Es 
                        decir, hacer que estén muertos en vida.<br/><br/>
                        
                        Al escuchar esto último, Kong se había vuelto a entusiasmar porque para él esto 
                        significaba que existía la posibilidad de crear este tipo de seres, tal como en las 
                        películas. Sin embargo, pronto el brujo le hizo ver que los zombis no eran posibles 
                        porque éstos no comen, no duermen, son inusualmente fuertes, se mueven con 
                        dificultad, su piel se cae a pedazos y responden únicamente a la voluntad de quien los 
                        reanimó. <br/><br/>
                        
                        En cambio, los seres humanos y otros seres vivos deben dormir y alimentarse para 
                        vivir, incluso los posiblemente intoxicados por <em>tetrodotoxina</em> en Haití, pues en el caso 
                        contrario morirían. Aunque parezca de película, lo que sucede con los hongos y las 
                        hormigas es simplemente un fenómeno de la naturaleza que permite a los hongos 
                        como “Cordy” asegurar su supervivencia y reproducción. Un fenómeno en que las 
                        hormigas inevitablemente morirán. <br/><br/>
                        
                        Después de recordar la desilusión que había tenido la noche anterior por percatarse 
                        de que no podría crear zombis, Kong terminó de escalar el árbol para encontrar 
                        finalmente la colonia de hormigas, con el ánimo de aprender mucho más de ellas.<br/><br/>
                    </p>

                    <img className='Imagen' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion16/5.png" alt="Hormiga" />
                    <Grid container item justifyContent="center">
                       <p className='TextImg'>
                            Kong por fin encontró a las hormigas zombis.
                        </p>
                    </Grid>
                </Grid>
            </Grid>
        <Grid container item style={{ marginTop: 80 }}></Grid>
        </Grid>
    );
}
 
export default Zombie;
