import { makeStyles, Grid, Radio } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import axios from "../../../../services/axios";
import ContainerAdminPearson from "../ContainerAdminPearson";
import ModalConfirm from "../../../Modals/ModalConfirm";
import Loader from "../../../Loader/Loader";
import ModalError from "../../../Modals/ModalError";

import ModalMessage from "../../../Modals/ModalMessage";

import { Link } from "react-router-dom";
import DropZoneButton from "./DropZoneButton";
import SelectCity from "./SelectCity";
import ModalEscuelaCargada from "../Modals/ModalEscuelaCargada";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    padding: "48px 0 48px 0",
    width: "100vw",
    minHeight: "calc(100vh - 80px)",
    background:
      "linear-gradient(217deg, rgba(255,194,0,0.4962185557816877) 2%, rgba(255,255,255,1) 25%, rgba(255,255,255,0.4962185557816877) 81%, rgba(144,81,177,1) 98%)",
    [theme.breakpoints.up("sm")]: {
      // marginTop: "0",
    },
  },
  boxContainer: {
    background: "#fff",
    width: "80%",
    minHeight: "75vh",
    height: "auto",
    borderRadius: "42px",
    padding: "20px",
    alignItems: "center",
    gap: "20px",
    boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.5)",
    [theme.breakpoints.up("md")]: {
      padding: "48px 20px 48px 20px",
    },
  },
  titleContainer: {
    margin: 0,
    [theme.breakpoints.up("md")]: {
      fontSize: "36px",
    },
  },
  title: {
    font: "28px Fredoka One ",
    margin: "0",
    textAlign: "center",
    color: " #DC5EA6",
    [theme.breakpoints.up("md")]: {
      fontSize: "36px",
    },
    [theme.breakpoints.up("xl")]: {
      fontSize: "42px",
    },
  },
  link: {
    cursor: "pointer",
    textDecoration: "none",
    // height: "31px",
    // width: "190px",
  },
  backButtonContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.up("sm")]: {
      justifyContent: "flex-start",
    },
  },
  backButton: {
    cursor: "pointer",
    height: "35px",
    // width: "200px",
    backgroundColor: "#FDEFF7",
    color: "#DC5EA6",
    border: "#DC5EA6 1px solid",
    borderRadius: "20px",
    textTransform: "none",
    font: "20px Lato",
    letterSpacing: "0.14px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
  },

  studentListContainer: {
    gap: "20px",
  },
  PurpleButton: {
    color: "#FFFFFF",
    backgroundColor: "#9051b1",
    borderRadius: "30px",
    alignSelf: "center",
    // margin: "0 auto",

    width: "100%",
    maxWidth: "238px",
    minHeight: "45px",
    border: "none",
    textTransform: "unset",
    cursor: "pointer",
    fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
    fontWeight: "500",
    padding: "6px 16px",
    lineHeight: "1.75",
    transition:
      "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    boxShadow:
      "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
    "&:hover": {
      backgroundColor: "#B965D3 ",
    },
  },
  formContainer: {
    // display: "flex",
    display: "grid",
    // flexDirection: "column"
    gridTemplateColumns: "1fr",
    width: "80%",
    boxSizing: "border-box",
    maxWidth: "560px",
    alignItems: "flex-start",
    justifyItems: "center",
    gap: "30px",

    [theme.breakpoints.up("lg")]: {
      width: "100%",
      maxWidth: "680px",
      alignItems: "end",
      gridTemplateColumns: "1fr 1fr",
    },
  },
  formItemContainer: {
    gap: "10px",
    position: "relative",
  },
  input: {
    width: "100%",
    height: "40px",
    paddingLeft: "27px",
    paddingRight: "27px",
    borderRadius: "61px",
    boxSizing: "border-box",
    // border: " solid #1D2AAD 3px ",
    border: "none",
    background: "#FFFFFF",
    font: "Helvetica",
    fontSize: "16px",
    position: "relative",
    //    width: '449px',
    // height: '72px',
    // margin: '43px 139px 19px 66px',
    // padding: '26px 24px 25px',
    backgroundColor: "#f0f0f0",
    "&:focus": {
      outline: "none",
    },
    [theme.breakpoints.up("lg")]: {
      // height: "60px",
      // maxWidth: "449px",
      fontSize: "18px",
    },
  },
  required: {
    position: "absolute",
    right: "-15px",
    bottom: "50%",
    top: "50%",
    fontWeight: "bold",
  },
  requiredWithoutLabel: {
    position: "absolute",
    right: "-15px",
    bottom: "10px",
    fontWeight: "bold",
  },
  label: {
    fontWeight: "bold",
    fontFamily: "Lato, sans-serif",
  },
  vip: {
    gap: "10px",
    justifySelf: "flex-start",
  },
  radioContainer: {
    width: "auto",
    alignItems: "center",
  },
  radio: {
    color: "#222222",
    "&.Mui-checked": {
      color: "#5dc137",
    },
    "&:hover": {
      backgroundColor: "#22222210",
    },
    "&.Mui-checked:hover": {
      backgroundColor: "#5dc13720",
    },
  },
}));

const CargarEscuelaIndividual = () => {
  const classes = useStyles();
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [modalMessageOpen, setModalMessageOpen] = useState(false);
  const [modalErrorDetailsOpen, setModalErrorDetailsOpen] = useState(false);
  const [modalErrorOpen, setModalErrorOpen] = useState(true);
  const [confirmChangesResult, setConfirmChangesResult] = useState("");
  const [loadSuccessfullyModal, setLoadSuccessfullyModal] = useState(false);
  const [resultError, setResultError] = useState("");
  const [file, setFile] = useState(null);

  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({});
  const [countrys, setCountrys] = useState([]);
  const [schoolDataUploaded, setSchoolDataUploaded] = useState({});

  const formRef = useRef(null);

  useEffect(() => {
    if (file) loadFile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file]);

  useEffect(() => {
    axios
      .get(`/listado/paises/ciudad`)
      .then((response) => {
        if (response.status === 200) {
          const data = response?.data?.resultado;
          setCountrys(data);
        }
      })
      .catch((error) => {
        console.log(error, "error");
      });
    return () => {};
  }, []);

  const handleCloseConfirmationModal = () => {
    if (
      formData?.nombre_escuela &&
      formData?.pais &&
      formData?.ciudad &&
      formData?.nombre_encargado &&
      formData?.apellido_encargado &&
      formData?.correo_encargado
    ) {
      setConfirmationModalOpen(!confirmationModalOpen);
    } else {
      setConfirmationModalOpen(false);
      setModalMessageOpen(true);
      setConfirmChangesResult("Asegúrese de llenar todos los datos");
    }
  };
  const handleCloseMessageModal = () => {
    setModalMessageOpen(!modalMessageOpen);
    setResultError("");
  };
  const handleCloseErrorModal = () => {
    setModalErrorOpen(!modalErrorOpen);
  };
  const handleCloseErrorDetailsModal = () => {
    setModalErrorOpen(false);
    setModalErrorDetailsOpen(!modalErrorDetailsOpen);
  };

  const loadFile = () => {
    setLoading(true);
    setLoading(false);
  };

  const confirmChanges = () => {
    if (
      formData?.nombre_escuela &&
      formData?.pais &&
      formData?.ciudad &&
      formData?.nombre_encargado &&
      formData?.apellido_encargado &&
      formData?.correo_encargado
    ) {
      setConfirmationModalOpen(false);
      setLoading(true);
      const body = {
        escuela: formData?.nombre_escuela,
        pais: formData?.pais,
        ciudad: formData?.ciudad,
        direccion: formData?.direccion || "",
        nombre: formData?.nombre_encargado,
        apellido_paterno: formData?.apellido_encargado,
        apellido_materno: formData?.segundo_apellido_encargado || "",
        correo: formData?.correo_encargado || "",
        image: file?.base64 || "",
        tipo: formData?.tipo || "normal",
      };
      axios
        .post("/listado/create/escuela", body)
        .then((response) => {
          if (response.status === 200) {
            const data = response.data.resultado;
            setLoading(false);
            setSchoolDataUploaded(data);
            setLoadSuccessfullyModal(true);
            formRef?.current?.reset();
            setFormData({});
            setFile(null);
          }
        })
        .catch((error) => {
          setConfirmationModalOpen(false);
          setLoading(false);
          setModalMessageOpen(true);
          setConfirmChangesResult(
            "Ha ocurrido un error al actualizar los datos. Intente de nuevo más tarde."
          );
        });
    } else {
      setConfirmationModalOpen(false);
      setModalMessageOpen(true);
      setConfirmChangesResult("Asegúrese de llenar todos los datos");
    }
  };
  const handleDocument = (documentBase64) => {
    setFile(documentBase64);
  };

  const handleForm = (e) => {
    setFormData({
      ...formData,
      [e.target.name.toLowerCase()]: e.target.value,
    });
  };
  return (
    <ContainerAdminPearson>
      <Grid
        container
        item
        sm={12}
        alignItems="center"
        justifyContent="center"
        className={classes.mainContainer}
      >
        <Grid
          container
          item
          direction="column"
          className={classes.boxContainer}
        >
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="center"
          >
            <Grid item className={classes.backButtonContainer}>
              <Link to="/admin/cargar-datos" className={classes.link}>
                <button className={classes.backButton}>
                  <ArrowBackIcon /> Cargar datos
                </button>
              </Link>
            </Grid>
            <Grid
              container
              item
              alignItems="center"
              justifyContent="center"
              className={classes.titleContainer}
            >
              <h2 className={classes.title}>
                Carga de escuelas: carga individual{" "}
              </h2>
            </Grid>
          </Grid>
          <form
            className={classes.formContainer}
            autoComplete="off"
            ref={formRef}
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <Grid
              container
              direction="column"
              className={classes.formItemContainer}
            >
              <label htmlFor="input-name" className={classes.label}>
                Datos de la escuela
              </label>
              <input
                type="text"
                className={classes.input}
                id="input-name"
                placeholder="Nombre de la escuela"
                name="nombre_escuela"
                value={formData.nombre_escuela}
                onChange={handleForm}
                required
              />
              <span className={classes.required}>*</span>
            </Grid>

            <DropZoneButton
              title="Subir logotipo de escuela"
              type={"image/*"}
              handleDocument={handleDocument}
              file={file}
            />
            <Grid
              container
              direction="column"
              className={classes.formItemContainer}
            >
              <SelectCity
                list={countrys}
                fieldName={"pais"}
                label={"País"}
                handleForm={handleForm}
                defaultValue={formData?.pais}
                value={formData?.pais}
                formData={formData}
              />
              <span className={classes.required}>*</span>
            </Grid>

            <Grid
              container
              direction="column"
              className={classes.formItemContainer}
            >
              <SelectCity
                list={
                  countrys.find((item) => item.pais === formData.pais)?.ciudades
                }
                fieldName={"ciudad"}
                label={"Ciudad"}
                handleForm={handleForm}
                defaultValue={formData?.ciudad}
                value={formData?.ciudad}
                formData={formData}
                disabled={!formData?.pais}
              />
              <span className={classes.required}>*</span>
            </Grid>

            <Grid
              container
              direction="column"
              className={classes.formItemContainer}
            >
              <input
                type="text"
                className={classes.input}
                id="input-address"
                name="direccion"
                placeholder="Dirección"
                value={formData.direccion}
                onChange={handleForm}
                required
              />
              <span className={classes.requiredWithoutLabel}>*</span>
            </Grid>
            <Grid container direction="column" className={classes.vip}>
              <label htmlFor="input-address" className={classes.label}>
                Escuela VIP
              </label>
              <Grid container alignItems="center" direction="row">
                <Grid container className={classes.radioContainer}>
                  <label htmlFor="option-vip-si" className={classes.label}>
                    Si
                  </label>
                  <Radio
                    name="tipo"
                    checked={formData?.tipo === "vip"}
                    value="vip"
                    onChange={handleForm}
                    className={classes.radio}
                  />
                </Grid>
                <Grid container className={classes.radioContainer}>
                  <label htmlFor="option-vip-no" className={classes.label}>
                    No
                  </label>

                  <Radio
                    name="tipo"
                    checked={formData?.tipo !== "vip"}
                    value="normal"
                    onChange={handleForm}
                    className={classes.radio}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid
              container
              direction="column"
              className={classes.formItemContainer}
            >
              <label htmlFor="input-nombre-encargado" className={classes.label}>
                Datos del encargado
              </label>
              <input
                type="text"
                className={classes.input}
                id="input-nombre-encargado"
                name="nombre_encargado"
                placeholder="Nombre(s)"
                value={formData.nombre_encargado}
                onChange={handleForm}
                required
              />
              <span className={classes.requiredWithoutLabel}>*</span>
            </Grid>
            <Grid
              container
              direction="column"
              className={classes.formItemContainer}
            >
              {/* <label htmlFor="input-primer-apellido-encargado" className={classes.label}>
                Primer apellido admin. Institucional
              </label> */}
              <input
                type="text"
                className={classes.input}
                id="input-primer-apellido-encargado"
                placeholder=" Primer apellido"
                name="apellido_encargado"
                value={formData.apellido_encargado}
                onChange={handleForm}
                required
              />
              <span className={classes.requiredWithoutLabel}>*</span>
            </Grid>
            <Grid
              container
              direction="column"
              className={classes.formItemContainer}
            >
              {/* <label htmlFor="input-segundo-apellido-encargado" className={classes.label}>
                Segundo apellido admin. Institucional
              </label> */}
              <input
                type="text"
                className={classes.input}
                id="input-segundo-apellido-encargado"
                placeholder="Segundo apellido"
                name="segundo_apellido_encargado"
                value={formData.segundo_apellido_encargado}
                onChange={handleForm}
                required
              />
            </Grid>

            <Grid
              container
              direction="column"
              className={classes.formItemContainer}
            >
              <input
                type="email"
                className={classes.input}
                id="input-email"
                name="correo_encargado"
                placeholder="Correo"
                value={formData.correo_encargado}
                onChange={handleForm}
                required
              />
              <span className={classes.requiredWithoutLabel}>*</span>
            </Grid>
          </form>
          <button
            className={classes.PurpleButton}
            onClick={handleCloseConfirmationModal}
          >
            Subir escuela
          </button>
        </Grid>
      </Grid>
      <ModalConfirm
        open={confirmationModalOpen}
        handleClose={handleCloseConfirmationModal}
        handleConfirm={confirmChanges}
        title={`¿Está seguro de crear la escuela "${
          formData?.nombre_escuela || ""
        }"?`}
      />
      {loading && <Loader />}

      <ModalError
        open={modalErrorOpen}
        handleClose={handleCloseErrorModal}
        type={resultError}
        handleNext={handleCloseErrorDetailsModal}
      />
      <ModalMessage
        open={modalMessageOpen}
        handleClose={handleCloseMessageModal}
        title={confirmChangesResult}
      />
      <ModalEscuelaCargada
        schoolName={formData?.nombre_escuela}
        open={loadSuccessfullyModal}
        handleClose={() => {
          setLoadSuccessfullyModal(!loadSuccessfullyModal);
        }}
        dataSchool={schoolDataUploaded}
      />
    </ContainerAdminPearson>
  );
};

export default CargarEscuelaIndividual;
