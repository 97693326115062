import React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { IconButton, makeStyles } from "@material-ui/core";
import ErrorIcon from "@mui/icons-material/Error";
import { GridCloseIcon } from "@mui/x-data-grid";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    // padding: theme.spacing(2),

    [theme.breakpoints.up("sm")]: {
      // margin: "20px 0",
      paddingTop: "0",
      paddingBottom: "0",
    },
  },
  "& .MuiDialog-paper": {
    borderRadius: "12.1px",
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
    display: "flex",
    justifyContent: "center",
    gap: "5%",
    marginBottom: "16px",
  },
  "& .MuiDialogTitle-root": {
    [theme.breakpoints.up("sm")]: {
      // margin: "20px 0",
      padding: "22px",
    },
  },
}));

const useStyles = makeStyles((theme) => ({
  containerContent: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    gap: "15px",
    alignItems: "center",
    [theme.breakpoints.up("md")]: {
      minWidth: "680px",
    },
  },
  containerButtons: {
    display: "flex",
    justifyContent: "center",
  },
  button: {
    backgroundColor: "#B965D3",
    borderRadius: "4px",
    boxSizing: "borderBox",
    outline: "0",
    border: "0",
  },

  title: {
    margin: 0,
    fontFamily: "Lato",
    fontSize: "20px",
    fontWeight: "normal",
    letterSpacing: "0.23px",
    textAlign: "center",
    color: "#ff9500",
    [theme.breakpoints.up("md")]: {
      fontSize: "24px",
    },
  },
  paragraph: {
    fontFamily: "Lato",
    fontSize: "16px",
    color: "#505759",
    fontWeight: "normal",
    margin: "20px 0",
    maxWidth: "60ch",
    textAlign: "center",
    [theme.breakpoints.up("md")]: {
      fontSize: "18px",
    },
  },
  link: {
    // width: "100%",
    textDecoration: "none",
    color: "inherit",
  },
}));

const PurpleButton = styled(Button)(({ theme }) => ({
  color: "#FFFFFF",
  backgroundColor: "#9051b1",
  borderRadius: "30px",
  textTransform: "unset",
  // margin: "0 auto",
  width: "100%",
  maxWidth: "130px",
  "&:hover": {
    backgroundColor: "#B965D3 ",
  },
}));

const IconError = styled(ErrorIcon)(({ theme }) => ({
  color: "#ff9500",
  fontSize: "80px",
  margin: "0 auto",

  [theme.breakpoints.up("md")]: {
    fontSize: "100px",
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 4, textAlign: "end" }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <GridCloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};
BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const ModalCargaMasivaError = ({ open, handleClose, data, error }) => {
  const classes = useStyles();
  return (
    <>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        maxWidth="md"
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        ></BootstrapDialogTitle>
        <DialogContent>
          <div className={classes.containerContent}>
            <IconError />
            <h3 className={classes.title}>Algo falló</h3>
            <p className={classes.paragraph}>{error}</p>
          </div>
        </DialogContent>
        <DialogActions disableSpacing={true} className={classes.containerButtons}>
          <PurpleButton onClick={handleClose} className={classes.button} variant="contained">
            Reintentar
          </PurpleButton>
        </DialogActions>
      </BootstrapDialog>
    </>
  );
};
export default ModalCargaMasivaError;
