import React, { useEffect } from 'react'

const ErrorBoundarieComponent = ({ children }) => {

  useEffect(() => {
    const anchor = document.createElement('a')
    anchor.href = '/error/error'
    anchor.click()

    return () => {

    }
  }, [])
  return (
    <>
      {children}
    </>
  )
}

export default ErrorBoundarieComponent