import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
    ContenedorLectura:{
        width:'502px',
                  [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
    },
    ContainerTitulo:{
        margin:'0 0 46px 0',
        '& .Titulo':{
            letterSpacing: '0px',
            color: '#926DD1',
            margin:'0',
            textAlign:'center',
            font:'43px fredoka one',
            lineHeight:'52px',
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },

        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            margin:'0 0 36px 0 ',
            color:'#000',
            font:'18px lato',
            lineHeight :'28px',
            letterSpacing: '0.17px',
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .Imagen':{
            width:'100%',
        },
    },
    

}));

const Niños_de_azúcar = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>

            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >
                        Niños de azúcar
                    </h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                            —La edad no importa mientras trabaje —dijo el capataz, poco preocupado por los 
                            ocho años de mi hermano. <br/><br/>
                            
                            Yo tengo once. Comenzamos a trabajar en la plantación de caña para ayudar a mis 
                            papás a conseguir comida. Le llaman <em>zafra</em>. Es difícil arrancar las hojas del tallo, pero 
                            continúo sonriendo.
                        </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <Grid container >
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img3ro/Sesion29/1.png" alt="Tarjeta" />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                            Mis amigos se han cortado la pierna o la mano, pero se vendan y al otro día vienen a 
                            trabajar. Dicen que cortar caña es peligroso, pero a mí nunca me ha pasado nada. Sé 
                            usar el machete y mi cuchillo. <br/><br/>
                            
                            No sé las multiplicaciones ni dividir. Ya no volví a la escuela. Me perdía los primeros 
                            meses por la zafra y mis papás dijeron que no tenía caso volver. Es tiempo de cosecha 
                            y con eso ayudamos. <br/><br/>
                            
                            Corro en la jaula verde persiguiendo a mi hermano. Nuestras caras se esconden. Se 
                            derrumban las cañas. Tomo una, la corto; tomo otra, la corto. Una y otra vez. Nos 
                            gusta jugar en la plantación. Competimos a ver quién corta más frutos. A veces no 
                            queremos porque tenemos sed o nos duelen los pies. Mis papás también trabajan 
                            aquí, pero ellos no juegan. Nosotros sí porque sólo somos ayudantes.
                        </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <Grid container >
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img3ro/Sesion29/2.png"  alt="Tarjeta" />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                            Ya cortamos cañas: ¡hora de jugar! A hacer la comidita como mamá. Agua con azúcar 
                            comeremos… A veces masticamos un pedazo de caña con una sopa de agua. <br/><br/>
                            
                            También la he plantado. Hay que perseguir a la máquina que abre los surcos para ir 
                            sembrando. Es cansado pero divertido. Los lentos pierden. Yo soy veloz, aunque 
                            luego me duele la espalda. Lo recuerdo mientras me tumbo entre las hojas caídas. No 
                            son cómodas. Lastiman la piel, pero yo resisto, no me quejo. Dicen que en otros 
                            lugares hay señores que se acuestan en camas de clavos. <br/><br/>
                            
                            Mi hermano y yo perseguimos las nubes de humo que deja la quema de caña para 
                            poder cortarla. Jugamos a encontrarle formas o pretendemos que es un tren que nos 
                            deja y debemos alcanzarlo. Las cañas se trizan. La saliva es azucarada porque 
                            respiramos el dulzor.
                        </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <Grid container >
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img3ro/Sesion29/3.png"  alt="Tarjeta" />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                            Cuando por fin llega el día de descanso, mi hermano y yo vamos a la playa. Jugamos a 
                            que el calor tostó el azúcar y la convirtió en arena. ¡Estas tierras son de azúcar! ¡Y 
                            nosotros, los reyes! <br/><br/>
                            
                            Mi hermano y yo la probamos. A lo lejos, una niña come helado. “¡La arena no es 
                            dulce!”, reclama mi hermano. En la boca nos queda un sabor salado, pero lo 
                            olvidamos porque enfrente está el mar llamándonos.<br/><br/>
                            
                            La playa es corta como la luz. La noche llega otra vez y nos vamos. Nosotros también 
                            somos dos noches que caminan. Dos noches con medias lunas de azúcar como 
                            sonrisas.
                        </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <Grid container >
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img3ro/Sesion29/4.png"  alt="Tarjeta" />
                        </Grid>
                    </Grid>
                </Grid>
        </Grid>
    </Grid>
    );
}
 
export default Niños_de_azúcar;
