import { Button, Grid, makeStyles } from "@material-ui/core";
import React, { useContext, useEffect } from "react";
import DrawerContext from "../../../../../usability/DrawerContext";
import { useOnLoadImages } from "../../../../hooks/useOnLoadImages";
import Loader from "../../../../Loader/Loader";
import Drawer from "../../ReadingTools/Drawer";

const useStyles =  makeStyles (theme =>({
     Contenedor:{
        background:'#E9FBF9',
        width:'100vw',
        overflowX:'clip',
        [theme.breakpoints.down(1161)]:{
            background:'none',
           overflowX:'unset',
        },
        [theme.breakpoints.down(600)]:{
            marginTop: '79.51px',
        }
    },
      ContainerInfoLectura: {
    position: "relative",
    height: "86px",
    [theme.breakpoints.down(502)]:{
        height: "43px",
    },
    background: "#76D4C3",
    "& .BarraInfoLectura": {
      height: "86px",
      background: `Url(https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Barras/Azul.png)`,
        [theme.breakpoints.down(502)]:{
            height: "43px",
        },
    },
    "& .InfoLectura": {
      position: "absolute",
      height: "86px",
      width: "219px",
      boxSizing: "border-box",
      padding: "17.2px 0",
      background: "#FAFAFA",
      borderRadius: "50px",
      boxShadow: "2px 2px 2px 2px #00000029",
      zIndex: "4",
        [theme.breakpoints.down(502)]:{
            height: "43px",
            width: "109px",
            padding: "8.6px 0",
            borderRadius: "25px",
        },
    },
    "& .InfoLectura:before": {
      position: "absolute",
      display: "flex",
      boxSizing: "border-box",
      padding: "0 0 0 10px",
      justifyContent: "center",
      alignItems: "center",
      height: "39.88px",
      width: "39.88px",
      borderRadius: "50%",
      content: '""',
      boxShadow: "3px 0px .5px 0px #00000029",
      background: "#FAFAFA",
      left: "90px",
      top: "65px",
      transform: "rotate(0.25turn)",
      [theme.breakpoints.down(502)]:{
        left: "45px",
        top: "30px",
        height: "20.88px",
        width: "20.88px",
        },
    },
    "& .InfoLectura:after": {
      content: '""',
      height: "15px",
      width: "15px",
      borderTop: "2px solid #20A698",
      borderLeft: "2px solid #20A698",
      position: "absolute",
      bottom: "-10px",
      left: "Calc(50% - 8px)",
      transform: "rotate(0.625turn)",
      [theme.breakpoints.down(502)]:{
        height: "10px",
        width: "10px",
        left: "Calc(50% - 4.5px)",
        bottom: "-3px",
        borderTop: "1px solid #20A698",
        borderLeft: "1px solid #20A698",
        },
    },
    "& .Sesion": {
      borderLeft: "2px solid #D8D8D8",
    },
    "& .Dato": {
      margin: "0 0 5px 0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#20A698",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        margin: "0 0 2.5px 0",
        },
    },
    "& .Caracter": {
      margin: "0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#000000",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        },
    },
       '& .Regresar':{
        color: '#F2624F',
        border: '#F2624F 1px solid',
        height: '30px',
        width:'100px',
        borderRadius: '20px',
        letterSpacing: '0.14px',
        textTransform: 'none',
        background:'#FEFAF1',
        top:'calc(50% - 15px)',
        left:'5%',
        zIndex:4,
        [theme.breakpoints.down(502)]: {
          font:'bold 10px lato',
          width: "60px",
        },
      },
  },
    ContenedorLectura:{
        background:'#fff',
        width:'1160px',
        height:'100%',
        padding:'60px 168px 35px ',
        
        transform: (props) =>
        props.scale > 1 ? `scale(${props.scale}) translate(0px, 10%)` : `none`,
        [theme.breakpoints.down(1161)]:{
            width:'502px',
            padding:'60px 0 0 0',
          },
        [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },

        '& .Escritorio':{
            display:'block',
            [theme.breakpoints.down(1161)]:{
                display:'none'
            },
        },
        '& .Tablet':{
            display:'none',
            [theme.breakpoints.down(1161)]:{
                display:'block'
            },
        }
    },
    ContainerTitulo:{
        margin:'0 0 34px 0',
        [theme.breakpoints.down(1161)]:{
            margin:'0 0 34px 0',
        },
        '& .Titulo':{
            letterSpacing: '0px',
            color: '#3A96FC',
            margin:'0',
            textAlign:'center',
            font:'48px fredoka one',
            lineHeight:'58px',
            [theme.breakpoints.down(1161)]:{
                font:'43px fredoka one',
                lineHeight:'52px',
            },
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            margin:'0 0 36px 0',
            color:'#000',
            font:'22px lato',
            lineHeight : '30px',
            letterSpacing: '0.20px',
            [theme.breakpoints.down(1161)]:{
                font:'18px lato',
                lineHeight :'28px',
                letterSpacing: '0.17px',
            },
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },

        '& .SubTitulo':{
            color:'#F6664D',
            margin:'0',
            font:'bold 30px lato',
            letterSpacing:'.28px',
            lineHeight:'30px',
            [theme.breakpoints.down(1161)]:{
                lineHeight :'28px',
                font:'bold 26px lato',
                letterSpacing: '0.24px',
            },
            [theme.breakpoints.down(502)]:{
                font:'bold 18px lato',
                lineHeight :'24px',
                letterSpacing: '0.21px',
            },
        },
        '& .TextImg':{
            font:'20px lato',
            color:'#717476',
            letterSpacing:'.19px',
            lineHeight:'30px',
            margin:'24px 0 0 0',
            [theme.breakpoints.down(1161)]:{
                font:' 16px lato',
                lineHeight :'25px',
                letterSpacing: '0.15px',
            },
            [theme.breakpoints.down(502)]:{
                font:' 11px lato',
                lineHeight :'14px',
                letterSpacing: '0.10px',
            },
        },
        '& .Imagen':{
            [theme.breakpoints.down(1161)]:{
                width:'100%',
            },
        }, 
    }
}));

const El_escudo_protector_la_piel = (props) => {

  const {activeStep, setActiveStep, handlePreviewStep, id_actividad} = props
  
  const handleContinue = () => {
    setActiveStep(activeStep - 1)
    handlePreviewStep()
  }

  const {
    handleSize,
    disabledZoom,
    closeZoom,
    handleZoom,
    scale
  }= useContext(DrawerContext);
  const [loading, setLoading] = React.useState(true);
  const wrapperRef = React.useRef(null);
  const imagesLoaded = useOnLoadImages(wrapperRef);

  const detectSize = () => {
    const lectura = document.getElementById("lectura");
    const newWidth = lectura?.offsetWidth;
    const newHeight = lectura?.offsetHeight;
    setLoading(false);
    if (lectura) handleSize(newHeight, newWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", detectSize);
    return () => {
      handleZoom()
      window.removeEventListener("resize", detectSize);
      closeZoom()
    };
  }, []);

  const classes = useStyles({ disabledZoom,scale });
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
             <Grid container item justifyContent="center" className={classes.ContainerInfoLectura}>
                       <Grid container className="BarraInfoLectura">
            <Button className="Regresar" onClick={handleContinue}>
                Regresar
            </Button>
        </Grid>
               <Grid container item className="InfoLectura">
                    <Grid container item direction="column" xs={6} justifyContent="center">
                        <p className="Dato">Grado</p>
                        <p className="Caracter">4</p>
                    </Grid>
                    <Grid container item direction="column" xs={6} justifyContent="center" className="Sesion">
                        <p className="Dato">Sesión</p>
                        <p className="Caracter">47</p>
                    </Grid>
               </Grid>
            </Grid>
            {!imagesLoaded && <Loader />}
        <Grid
            container
            item
            className={classes.ContenedorLectura}
            justifyContent="center"
            id="lectura"
            onLoad={detectSize}
            ref={wrapperRef}
        >
<Drawer id_lectura={id_actividad} />
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >
                        El escudo protector, <br className='Tablet'/>la piel
                    </h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                      <p className="Lectura">
                        El Sol es la estrella más cercana al planeta Tierra. Muchas culturas antiguas lo 
                        adoraron por la importancia que tiene para los seres humanos. Los seres vivos 
                        dependen de la energía que reciben de él para vivir y llevar a cabo sus tareas básicas.
                        Este gigante también determina el clima del mundo. El 21 de junio de cada año 
                        ocurre el solsticio de verano, por ello esa fecha fue elegida para celebrar el Día 
                        internacional del Sol desde el año 2019.<br/><br/> 
                        
                        Las plantas hacen fotosíntesis gracias a su presencia, pues les permite crecer y 
                        convertirse en alimento para otros seres. Debido a esto, podemos afirmar que el Sol 
                        es la base de las cadenas alimenticias, sin él no existirían. Los peces, anfibios y 
                        reptiles son organismos ectotermos, término mejor conocido como “de sangre fría”. 
                        Ellos requieren del Sol para regular su temperatura, hacer sus funciones vitales y 
                        reproducirse.
                      </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <Grid container justifyContent="center" >
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img4to/Sesion47/1.png" alt="Tarjeta" />
                        </Grid>
                        <Grid container justifyContent="center" >
                            <p className='TextImg'>
                                La energía que existe en el planeta depende directamente de la presencia del Sol.
                            </p>                        
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                      <p className="Lectura">
                        También los seres humanos recibimos beneficios de él. Entre ellos está que sus rayos 
                        nos ayudan a crear serotonina, la cual es un neurotransmisor que está involucrado 
                        con la sensación de bienestar, pues crea un buen estado de ánimo cuando está 
                        presente. Los días nublados nos hacen sentir tristeza a veces, pues la falta de luz 
                        solar ocasiona una menor generación de serotonina.<br/><br/> 
                        
                        La radiación es necesaria para la vitamina D. Ésta es indispensable para la absorción 
                        del calcio, que requieren los huesos para crecer y estar fuertes. Los músculos 
                        también la necesitan para estar tonificados. La deficiencia de ésta provoca que los 
                        huesos se deformen y sean débiles, lo que causa el surgimiento de enfermedades 
                        como el raquitismo. <br/><br/>
                        
                        También incrementa las células de la sangre que nos protegen de las enfermedades: 
                        los glóbulos blancos. Otros efectos positivos son que evita la multiplicación de las 
                        células cancerígenas, cicatriza algunas lesiones de la piel y fomenta la producción de
                        melatonina. Ésta es una sustancia que genera nuestro cuerpo para regular los ciclos 
                        del sueño, por lo tanto, nos resulta difícil dormir o descansar cuando no está 
                        presente. Reposar es importante para la vida diaria porque ayuda al cerebro a 
                        mantenerse alerta frente a las situaciones de peligro. <br/><br/>
                        
                        <h3 className='SubTitulo'>Consecuencias negativas</h3><br/>
                        
                        El Sol en exceso es dañino, como muchas otras cosas. Exponerse mucho tiempo a él 
                        puede causar reacciones, como irritación en la piel. Hay personas que son 
                        especialmente sensibles a ello y estar bajo sus efectos les ocasiona envejecimiento 
                        prematuro, cáncer de piel e incluso tumores. <br/><br/>
                        
                        La sobreexposición ocasiona quemaduras y deshidratación, pero la piel humana 
                        cuenta con un filtro natural, una sustancia conocida como melanina. Ésta determina 
                        el tono de la piel, pues se vuelve más oscuro cuando existe mayor cantidad de 
                        melanina, lo que también significa mayor protección. Es por eso que las personas con 
                        tonos de piel oscura resisten mejor la exposición al Sol.<br/><br/>
                        
                        Es importante asolearnos, pero siempre con moderación. Las recomendaciones de 
                        los científicos son las siguientes. Hacerlo por lo menos durante cinco minutos, pero 
                        nunca más de diez. Procurar que ocurra tres veces por semana como máximo. Y 
                        evitar las horas del día en las que la radiación es más alta, es decir, entre las diez de la 
                        mañana y las cuatro de la tarde. <br/><br/>
                        
                        Ahora conoces los efectos positivos y negativos de estar en contacto con los rayos 
                        solares. ¡Recuerda que el cuerpo los necesita, pero hay que tomarlos con precaución!
                      </p>
                    </Grid>
                </Grid>
        </Grid>
        <Grid container item style={{ marginTop: 80}} ></Grid>
    </Grid>
    );
}
 
export default El_escudo_protector_la_piel ;
