import { Button, Grid,makeStyles}              from '@material-ui/core';
import React, { useContext, useEffect } from "react";
import DrawerContext from "../../../../../usability/DrawerContext";
import { useOnLoadImages } from "../../../../hooks/useOnLoadImages";
import Loader from "../../../../Loader/Loader";
import Drawer from "../../ReadingTools/Drawer";

const useStyles =  makeStyles (theme =>({
     Contenedor:{
        background:'#E9FBF9',
        width:'100vw',
        overflowX:'clip',
        [theme.breakpoints.down(1161)]:{
            background:'none',
           overflowX:'unset',
        },
        [theme.breakpoints.down(600)]:{
            marginTop: '79.51px',
        }
    },
  ContainerInfoLectura: {
    position: "relative",
    height: "86px",
    [theme.breakpoints.down(502)]:{
        height: "43px",
    },
    background: "#76D4C3",
    "& .BarraInfoLectura": {
      height: "86px",
      background: `Url(https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Barras/Azul.png)`,
        [theme.breakpoints.down(502)]:{
            height: "43px",
        },
    },
    "& .InfoLectura": {
      position: "absolute",
      height: "86px",
      width: "219px",
      boxSizing: "border-box",
      padding: "17.2px 0",
      background: "#FAFAFA",
      borderRadius: "50px",
      boxShadow: "2px 2px 2px 2px #00000029",
      zIndex: "4",
        [theme.breakpoints.down(502)]:{
            height: "43px",
            width: "109px",
            padding: "8.6px 0",
            borderRadius: "25px",
        },
    },
    "& .InfoLectura:before": {
      position: "absolute",
      display: "flex",
      boxSizing: "border-box",
      padding: "0 0 0 10px",
      justifyContent: "center",
      alignItems: "center",
      height: "39.88px",
      width: "39.88px",
      borderRadius: "50%",
      content: '""',
      boxShadow: "3px 0px .5px 0px #00000029",
      background: "#FAFAFA",
      left: "90px",
      top: "65px",
      transform: "rotate(0.25turn)",
      [theme.breakpoints.down(502)]:{
        left: "45px",
        top: "30px",
        height: "20.88px",
        width: "20.88px",
        },
    },
    "& .InfoLectura:after": {
      content: '""',
      height: "15px",
      width: "15px",
      borderTop: "2px solid #20A698",
      borderLeft: "2px solid #20A698",
      position: "absolute",
      bottom: "-10px",
      left: "Calc(50% - 8px)",
      transform: "rotate(0.625turn)",
      [theme.breakpoints.down(502)]:{
        height: "10px",
        width: "10px",
        left: "Calc(50% - 4.5px)",
        bottom: "-3px",
        borderTop: "1px solid #20A698",
        borderLeft: "1px solid #20A698",
        },
    },
    "& .Sesion": {
      borderLeft: "2px solid #D8D8D8",
    },
    "& .Dato": {
      margin: "0 0 5px 0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#20A698",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        margin: "0 0 2.5px 0",
        },
    },
    "& .Caracter": {
      margin: "0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#000000",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        },
    },
       '& .Regresar':{
        color: '#F2624F',
        border: '#F2624F 1px solid',
        height: '30px',
        width:'100px',
        borderRadius: '20px',
        letterSpacing: '0.14px',
        textTransform: 'none',
        background:'#FEFAF1',
        top:'calc(50% - 15px)',
        left:'5%',
        zIndex:4,
        [theme.breakpoints.down(502)]: {
          font:'bold 10px lato',
          width: "60px",
        },
      },
  },
    ContenedorLectura:{
        background:'#fff',
        width:'1160px',
        height:'100%',
       padding:'60px 168px 35px ',
        
        transform: (props) =>
        props.scale > 1 ? `scale(${props.scale}) translate(0px, 10%)` : `none`,
        [theme.breakpoints.down(1161)]:{
            width:'502px',
            padding:'60px 0 0 0',
          },
        [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },

        '& .Escritorio':{
            display:'block',
            [theme.breakpoints.down(1161)]:{
                display:'none'
            },
        },
        '& .Escritorio2':{
            [theme.breakpoints.down(1161)]:{
                display:'none'
            }
        },
        '& .Tablet':{
            display:'none',
            [theme.breakpoints.down(1161)]:{
                display:'block'
            },
        }
    },
    ContainerTitulo:{
        margin:'0 0 36px 0',
        '& .Titulo':{
            font:'48px fredoka one',
            letterSpacing: '0px',
            color: '#FBA819',
            margin:'0',
            textAlign:'center',
            [theme.breakpoints.down(1161)]:{
                font:'43px fredoka one',
                lineHeight:'52px',
            },
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        '& .Lectura':{
            lineHeight : '30px',
            margin:'0',
            font:'22px lato',
            color:'#000',
            letterSpacing: '0.20px',
            [theme.breakpoints.down(1161)]:{
                font:'18px lato',
                lineHeight :'28px',
                letterSpacing: '0.17px',
            },
            [theme.breakpoints.down(502)]:{
                font:'14px lato',
                lineHeight :'18px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'16px',
                letterSpacing: '0.09px',
            },
        },
        '& .Esp':{
            margin:'0 0 36px 0',
        },
        position:'relative',
        margin:' 0 0 36px 0',
        '& .Pos':{
            margin:'0',
            position:'absolute',
        },
        '& .Subtitulo':{
            letterSpacing:'.28px',
            font:'30px Fredoka One',
            color:'#fff',
            top:'30px',
            textAlign:'center',
            [theme.breakpoints.down(1161)]:{
                font:'23px Fredoka One',
                lineHeight :'30px',
                letterSpacing: '0.22px',
                top:'11px',
            },
            [theme.breakpoints.down(502)]:{
                font:'18px Fredoka One',
                textAlign:'center',
                lineHeight :'20px',
                letterSpacing: '0.16px',
            },
        },
        '& .Texto':{
            top:'102px',
            left:'37px',
            width:'750px',
            [theme.breakpoints.down(1161)]:{
                top:'102px',
                left:'37px',
                width:'437px',
            },
            [theme.breakpoints.down(502)]:{
                lineHeight :'18px!important',
                top:'65px',
                left:'25px',
                width:'300px',
            },
        },
        '& .Text2':{
            left:'166px',
            width:'594px',
            [theme.breakpoints.down(1161)]:{
                left:'114px',
                width:'351px',
                
            },
            [theme.breakpoints.down(502)]:{
                lineHeight :'17px!important',
                left:'85px',
                width:'250px',
            },
        },
        '& .Texto2':{
            top:'195px',
            [theme.breakpoints.down(1161)]:{
                top:'206px',
            },
            [theme.breakpoints.down(502)]:{
                lineHeight :'18px!important',
                top:'140px',
            },
        },
        '& .Texto3':{
            top:'309px',
            [theme.breakpoints.down(1161)]:{
                top:'282px',
                padding:'0 7px 0 0',
                boxSizing:'border-box',
            },
            [theme.breakpoints.down(502)]:{
                lineHeight :'18px!important',
                top:'200px',
                padding:'0 11px 0 0',

            },
        },
        '& .Texto4':{
            top:'686px',
            [theme.breakpoints.down(1161)]:{
                top:'610.87px',
            },
            [theme.breakpoints.down(502)]:{
                lineHeight :'18px!important',
                top:'430px',
            },
        },
        '& .Texto5':{
            top:'1123px',
            [theme.breakpoints.down(1161)]:{
                top:'954px',
                padding:'0 35px 0 0',
                boxSizing:'border-box',
            },
            [theme.breakpoints.down(502)]:{
                lineHeight :'18px!important',
                top:'670px',
                padding:'0',
            },
        },
        '& .Texto6':{
            top:'1534px',
            [theme.breakpoints.down(1161)]:{
                top:'1276px',
            },
            [theme.breakpoints.down(502)]:{
                lineHeight :'18px!important',
                top:'886px',
                padding:'0 15px 0 0',
                boxSizing:'border-box',
            },
        },
        '& .Texto7':{
            top:'1933px',
            [theme.breakpoints.down(1161)]:{
                top:'1594px',
            },
            [theme.breakpoints.down(502)]:{
                lineHeight :'18px!important',
                top:'1111px',
                padding:'0 15px 0 0',
                boxSizing:'border-box',
            },
        },
        '& .Texto8':{
            top:'2351px',
            [theme.breakpoints.down(1161)]:{
                top:'1940px',
            },
            [theme.breakpoints.down(502)]:{
                top:'1350px',
                lineHeight :'18px!important',
            },
        },
        '& .Imagen':{
            [theme.breakpoints.down(502)]:{
                width:'100%'
            },
        },
        '& .Imagen2':{
            width:'100%'
        },
        '& .Ocultar':{
            [theme.breakpoints.down(350)]:{
                display:'none'
            },
        },
        '& .Imagen3':{
            width:'100%',
            display:'none',
            [theme.breakpoints.down(350)]:{
                display:'flex'
            },
        },
    },

    
    

}));

const El_Tangram = (props) => {

  const {activeStep, setActiveStep, handlePreviewStep, id_actividad} = props

  const handleContinue = () => {
    setActiveStep(activeStep - 1)
    handlePreviewStep()
  }

  const {
    handleSize,
    disabledZoom,
    closeZoom,
    handleZoom,
    scale
  }= useContext(DrawerContext);
  const [loading, setLoading] = React.useState(true);
  const wrapperRef = React.useRef(null);
  const imagesLoaded = useOnLoadImages(wrapperRef);

  const detectSize = () => {
    const lectura = document.getElementById("lectura");
    const newWidth = lectura?.offsetWidth;
    const newHeight = lectura?.offsetHeight;
    setLoading(false);
    if (lectura) handleSize(newHeight, newWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", detectSize);
    return () => {
      handleZoom()
      window.removeEventListener("resize", detectSize);
      closeZoom()
    };
  }, []);

  const classes = useStyles({ disabledZoom,scale });
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
             <Grid container item justifyContent="center" className={classes.ContainerInfoLectura}>
                       <Grid container className="BarraInfoLectura">
            <Button className="Regresar" onClick={handleContinue}>
                Regresar
            </Button>
        </Grid>
               <Grid container item className="InfoLectura">
                    <Grid container item direction="column" xs={6} justifyContent="center">
                        <p className="Dato">Grado</p>
                        <p className="Caracter">2</p>
                    </Grid>
                    <Grid container item direction="column" xs={6} justifyContent="center" className="Sesion">
                        <p className="Dato">Sesión</p>
                        <p className="Caracter">26</p>
                    </Grid>
               </Grid>
            </Grid>
            {!imagesLoaded && <Loader />}
            <Grid
            container
            item
            className={classes.ContenedorLectura}
            justifyContent="center"
            id="lectura"
            onLoad={detectSize}
            ref={wrapperRef}
            >
            <Drawer id_lectura={id_actividad} />
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >
                        El Tangram
                    </h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                            La creatividad es una gran herramienta para las matemáticas. Y algunos juegos te 
                            dan la oportunidad para ponerla en práctica, porque puedes elaborarlos en casa y 
                            usar tu imaginación para elegir los materiales. Te sorprenderá la cantidad de cosas 
                            que puedes crear con piezas de reúso o reciclaje. De esta manera podrías poner en 
                            práctica no sólo tus conocimientos de matemáticas, sino los de ciencias naturales y 
                            los de ética. Por ejemplo, podrías hacer un <em>Tangram</em> con materiales de reúso.<br/><br/> 
                            
                            Un Tangram es un rompecabezas conformado por diferentes figuras geométricas que 
                            caben en un cuadrado y con el cual podemos armar diversas formas usando nuestra 
                            imaginación.
                        </p>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container item justifyContent="center" className='Ocultar'>

                    <h3 className='Pos Subtitulo'>Instrucciones para hacer<br className='Tablet'/> un Tangram</h3>
                   
                    <p className='Pos Lectura Texto'>
                        Te invitamos a hacer uno observando las ilustraciones y también queremos 
                        acompañarte en el proceso. Sólo sigue estos sencillos pasos y disfruta:
                    </p>
                    <p className='Pos Lectura Text2 Texto2'>
                        Pide ayuda a una persona adulta, sobre todo porque tendrás 
                        que cortar los materiales<span className="Escritorio2">, pero también porque el trabajo 
                        colaborativo se disfruta muchísimo</span>.

                    </p>
                    <p className='Pos Lectura Text2 Texto3'>
                        Haz un cuadrado de papel o cartulina (recuerda que un 
                        cuadrado tiene sus cuatro lados iguales). Dobla la figura por 
                        una de sus diagonales (observa la línea punteada) y córtala: 
                    </p>
                    <p className='Pos Lectura Text2 Texto4'>
                        Dobla por la mitad uno de los triángulos que obtuviste y 
                        córtalo como se muestra en la ilustración:
                    </p>
                    <p className='Pos Lectura Text2 Texto5'>
                        Dobla el triángulo más grande, de tal manera que los puntos 
                        rojos coincidan y córtalo:
                    </p>
                    <p className='Pos Lectura Text2 Texto6'>
                        Esta figura alargada de cuatro lados es un <em>trapecio</em>. Dóblalo 
                        a la mitad y córtalo como se muestra enseguida:
                    </p>
                    <p className='Pos Lectura Text2 Texto7'>
                        Dobla una de las figuras resultantes del corte anterior, tal 
                        como lo indica la línea punteada. Obtendrás un cuadrado y 
                        un triángulo pequeños. Recórtalos:
                    </p>
                    <p className='Pos Lectura Text2 Texto8'>
                        Finalmente, haz coincidir los puntos rojos para que la figura 
                        se doble por la línea punteada y recórtala:
                    </p>
                  
                    <img className='Imagen Escritorio' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img2do/Sesion26/1.png" alt="" />
                    <img className='Imagen Tablet' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img2do/Sesion26/1R.png" alt="" />
                    </Grid>

                    <img className='Imagen3' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img2do/Sesion26/F.png" alt="" />

                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura Esp">
                            ¡Listo! Ya tienes tu Tangram. Con él puedes formar muchísimas figuras y estimular tu 
                            imaginación y tu creatividad. La única regla es que siempre debes usar todas las 
                            piezas.<br/><br/> 
                            
                            Aquí te mostramos algunas posibilidades para que realices figuras. ¡Intenta 
                            formarlas en casa!
                        </p>
                    </Grid>
                    <Grid contaier justifyContent='center'> 
                        <img className='Imagen2' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img2do/Sesion26/2.png" alt="" />
                    </Grid>
                </Grid>
        </Grid>
        <Grid container item style={{ marginTop: 80}} ></Grid>
    </Grid>
    );
}
 
export default El_Tangram;
