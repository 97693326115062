import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
    ContenedorLectura:{
        width:'502px',
                  [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
    },
    ContainerTitulo:{
        margin:'0 0 34px 0',
        '& .Titulo':{
            letterSpacing: '0px',
            color: '#926DD1',
            margin:'0',
            textAlign:'center',
            font:'43px fredoka one',
            lineHeight:'52px',
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            margin:'0 0 36px 0',
            color:'#000',
            font:'18px lato',
            lineHeight :'28px',
            letterSpacing: '0.17px',
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        "& .SubTitulo": {
            color: "#FFFFFF",
            padding:'0 0 0 14px',
            margin: "5px 0 30px 0",
            font: "31px Fredoka One",
            lineHeight: "37px",
            [theme.breakpoints.down(502)]: {
                padding:'0 0 0 5px',
                margin: "5px 0 15px 0",
                font: "16px Fredoka One",
                lineHeight: "20px",
            },
            [theme.breakpoints.down(350)]: {
                padding:'0 0 0 2px',
                margin: "3px 0 10px 0",
                font: "14px Fredoka One",
                lineHeight: "20px",
            },
        },
        '& .TextImg':{
            color:'#717476',
            margin:'24px 0 0 0',
            font:' 16px lato',
            lineHeight :'25px',
            letterSpacing: '0.15px',
            [theme.breakpoints.down(502)]:{
                font:' 11px lato',
                lineHeight :'14px',
                letterSpacing: '0.10px',
            },
        },
        '& .Cuadro':{
            position:'relative',
        },
        '& .Esp':{
            position:'absolute',
            margin:'7px 0 0 0',
            width:'100%',
                        [theme.breakpoints.down(502)]:{
                width:'80%',
                margin:'3px 0 0 0',
                font:'11px lato!important',
                lineHeight :'15px!important',
                letterSpacing: '0.09px!important',
            },
            [theme.breakpoints.down(350)]:{
                width:'80%',
                margin:'3px 0 0 0',
                font:'10px lato!important',
                lineHeight :'13px!important',
                letterSpacing: '0.09px!important',
            },
        },
        
        '& .Imagen1':{
            width:'100%',
        },

        '& .Imagen':{
            [theme.breakpoints.down(502)]:{
                width:'100%',
            },
        },
      
    },
    Especial:{
        margin:'0 0 27px 0',
    },
    
}));

const Géneros_musicales = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >
                        Géneros musicales              
                    </h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                      <p className="Lectura">
                        La música es una de las artes más antiguas. Ha evolucionado con el paso de los siglos 
                        y se ha hecho cada vez más diversa por distintos lugares y culturas. Estudiar su 
                        variedad es un reto para cualquier especialista, pero es posible reconocer las 
                        características de algunos géneros. A continuación, verás algunos de los más 
                        famosos y que la gente sigue consumiendo.
                      </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <Grid container justifyContent="center" >
                            <img className="Imagen1" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img4to/Sesion46/1.png" alt="Tarjeta" />
                        </Grid>
                        <Grid container justifyContent="center" >
                            <p className='TextImg'>
                                La música tiene una presencia importante en la vida humana y han existido numerosos exponentes de cada tipo.
                            </p>                        
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={`${classes.Parrafo1} ${classes.Especial}`} justifyContent="center">
                    <Grid container item justifyContent="center" className="Cuadro" sm={12}>
                        <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img4to/Sesion46/2R.png" alt="Tarjeta" />
                        <p className="Lectura Esp">
                            <h3 className='SubTitulo'>
                                Pop
                            </h3>
                            Es una de las categorías más célebres y abundantes. Su nombre proviene de la
                            palabra <em>popular</em>, que significa “que es conocido por el público en general”, pues ha 
                            sido comercial desde su aparición en la década de 1950. Suele utilizarse para bailar y
                            cantar, ya que su ritmo es armónico y sus letras son fáciles de recordar. En la 
                            actualidad, mezcla elementos electrónicos y urbanos, pero sin perder su esencia.
                        </p>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={`${classes.Parrafo1} ${classes.Especial}`} justifyContent="center">
                    <Grid container item justifyContent="center" className="Cuadro" sm={12}>
                        <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img4to/Sesion46/3R.png" alt="Tarjeta" />
                        <p className="Lectura Esp">
                            <h3 className='SubTitulo'>
                                Rock
                            </h3>
                            Su precursor fue el rock and roll, que fue hecho para bailar. Sus melodías fueron 
                            evolucionando y comenzaron a adaptarse a nuevos grupos sociales. El rock surgió 
                            como un movimiento de protesta que buscó transmitir ideales claros y fijos. Estuvo 
                            influido por sonidos urbanos y tiene raíces en la cultura afroamericana. Del rock 
                            original se derivaron otros tipos, como el punk, el metal y el rock clásico.
                        </p>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={`${classes.Parrafo1} ${classes.Especial}`} justifyContent="center">
                    <Grid container item justifyContent="center" className="Cuadro" sm={12}>
                        <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img4to/Sesion46/4R.png" alt="Tarjeta" />
                        <p className="Lectura Esp">
                            <h3 className='SubTitulo'>
                                Blues y jazz
                            </h3>
                            Se trata de dos de las categorías más sobresalientes. Los esclavos africanos crearon 
                            el blues cuando eran llevados a Estados Unidos a finales del siglo <span style={{fontVariant: "all-small-caps"}}>XIX</span>. Las canciones 
                            tenían letras melancólicas que hablaban de problemas sociales y cuestiones 
                            personales. Años después, los descendientes de dichas poblaciones tocaron música 
                            clásica europea y la mezclaron con el blues. Esto dio origen al jazz, que es una fusión 
                            de sonidos africanos y occidentales. Hoy en día es reproducido por todo tipo de
                            personas en el mundo.
                        </p>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={`${classes.Parrafo1} ${classes.Especial}`} justifyContent="center">
                    <Grid container item justifyContent="center" className="Cuadro" sm={12}>
                        <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img4to/Sesion46/5R.png" alt="Tarjeta" />
                        <p className="Lectura Esp">
                            <h3 className='SubTitulo'>
                                Folclórica
                            </h3>
                            Todas las culturas del planeta tienen una naturaleza que las caracteriza y las 
                            diferencia entre sí. Todas producen música única, a veces utilizada en ceremonias, 
                            fiestas religiosas y celebraciones tradicionales. Este género recibe el nombre 
                            <em> folclórico</em> porque sus melodías son conocidas por los miembros de la comunidad, 
                            aunque muchas veces se desconocen los compositores. Y son enseñadas de 
                            generación en generación. Ejemplos de ello son los villancicos navideños y las 
                            canciones de las tribus africanas.
                        </p>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={`${classes.Parrafo1} ${classes.Especial}`} justifyContent="center">
                    <Grid container item justifyContent="center" className="Cuadro" sm={12}>
                        <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img4to/Sesion46/6R.png" alt="Tarjeta" />
                        <p className="Lectura Esp">
                            <h3 className='SubTitulo'>
                                Clásica
                            </h3>
                            Muchas de sus piezas son compuestas para ser interpretadas por orquestas, 
                            conjuntos de cuerdas o concertistas de algún instrumento. Se originó durante la 
                            Edad Media, época en la que se inventó la mayor parte de los instrumentos que hoy 
                            se tocan en las orquestas. Su propósito era ambientar las ceremonias religiosas.
                        </p>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={`${classes.Parrafo1} ${classes.Especial}`} justifyContent="center">
                    <Grid container item justifyContent="center" className="Cuadro" sm={12}>
                        <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img4to/Sesion46/7R.png" alt="Tarjeta" />
                        <p className="Lectura Esp">
                            <h3 className='SubTitulo'>
                                Electrónica
                            </h3>
                            Gran parte de ella se crea con tecnología. Utiliza ritmos ya grabados que se mezclan 
                            en computadoras, las cuales se vuelven un instrumento musical. Sus primeras 
                            composiciones pueden ubicarse a inicios del siglo pasado en Europa. Pero su auge 
                            llegó casi cien años después gracias a los DJ, quienes lo difunden en clubes de baile, 
                            fiestas y conciertos.
                        </p>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={`${classes.Parrafo1} ${classes.Especial}`} justifyContent="center">
                    <Grid container item justifyContent="center" className="Cuadro" sm={12}>
                        <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img4to/Sesion46/8R.png" alt="Tarjeta" />
                        <p className="Lectura Esp">
                            <h3 className='SubTitulo'>
                            Urbana
                            </h3>
                            Esta categoría se intensificó a inicios de los años 2000 en los medios digitales y las 
                            redes sociales. Sus raíces son las culturas latinas y afroamericanas que convivían en 
                            Estados Unidos en la década de 1980. Conjunta ritmos como el hip hop o el reggae, y 
                            de ella se han generado otros estilos, como el reggaetón.
                        </p>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                      <p className="Lectura">
                        Ahora conoces más sobre las características de estos géneros musicales. ¿Cuál es tu favorito?
                      </p>
                    </Grid>
                </Grid>
        </Grid>
    </Grid>
    );
}
 
export default Géneros_musicales ;
