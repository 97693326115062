import { makeStyles, Grid, Button } from "@material-ui/core";
import React from "react";
import graph_1 from "../Docente/img/graph_1.png";
import graph_2 from "../Docente/img/graph_2.png";
import ContainerAdmin from "./ContainerAdmin";
import axios from "../../../services/axios";
import Loader from "../../Loader/Loader";
import ModalMessage from "../../Modals/ModalMessage";

const useStyles = makeStyles((theme) => ({
  Container: {
    padding: "48px 0 48px 0",
    background:
      "linear-gradient(217deg, rgba(255,194,0,0.4962185557816877) 2%, rgba(255,255,255,1) 25%, rgba(255,255,255,0.4962185557816877) 81%, rgba(144,81,177,1) 98%)",
    minHeight: "calc(100vh - 80px)",
  },
  ContainerReportes: {
    background: "#fff",
    maxWidth: "1216px",
    width: "75%",
    minHeight: "70vh",
    gap: "10px",
    borderRadius: "42px",
    padding: "30px",
    boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.5)",
    "& .Titulo": {
      font: "42px fredoka one",
      margin: "0 0 84px 34px",
    },
    "& .Link": {
      textDecoration: "none",
      textTransform: "unset",
      color: "#000",
    },
  },
  ContainerBarra: {
    height: "498px",
    boxShadow: "0 0 30px 0 rgba(76, 147, 230, 0.1)",
    margin: "0 0 20px 0",
    borderRadius: "8px",
  },

  ContainerLinkinsiginas: {
    textDecoration: "none",
    textTransform: "unset",
    // marginLeft: "44px",
    // marginBottom: "40px",
    textAlign: "left",
    "& .ButtonIsg": {},
  },
  button: {
    color: "#000",
    font: "18px lato",
    letterSpacing: "0.36px",
    margin: "0",
    textAlign: "start",
    textTransform: "unset",
    // width: '102px',
    // height: '21px',
    // background: '#FEFAF1 0% 0% no-repeat padding-box',
    // boxShadow: '0px 1px 2px #00000029',
    // border: '1px solid #F2624F',
    // borderRadius: '10px',
    [theme.breakpoints.up("md")]: {
      font: " 20px lato",
    },
    [theme.breakpoints.up("lg")]: {
      font: " 22px lato",
    },
    "& p": {
      margin: 0,
    },
  },
  icon: {
    color: "purple",
    marginRight: "20px",
    fontSize: "30px",
    width: "30px",
    height: "30px",
    [theme.breakpoints.up("md")]: {
      width: "40px",
      height: "40px",
      fontSize: "40px",
    },
    [theme.breakpoints.up("lg")]: {
      width: "55px",
      height: "55px",
      fontSize: "50px",
    },
  },

  mainTitle: {
    font: "42px fredoka one",
    textAlign: "center",
    color: "#DC5EA6",
    // textAlign: "left",
    margin: "10px 0",
    [theme.breakpoints.down("860")]: {
      font: "38px fredoka one",
      // margin: "0 0 34px 14px",
    },
    [theme.breakpoints.down("321")]: {
      font: "28px fredoka one",
    },
  },
}));

const Reporte_Alumnos = () => {
  const classes = useStyles();

  const [loading, setLoading] = React.useState(false);
  const [modalMessageOpen, setModalMessageOpen] = React.useState(false);
  const [fileResult, setFileResult] = React.useState();

  const handleCloseMessageModal = () => {
    setModalMessageOpen(!modalMessageOpen);
  };

  const downloadGrap2 = () => {
    setLoading(true);
    axios
      .get("/reporte/usuarios")
      .then((response) => {
        const path = response.data.resultado.path;
        const link = document.createElement("a");
        link.href = path;
        link.setAttribute("download", "image.jpg");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((error) => {
        if (error.response) {
          // Request made and server responded
          const errorData = error.response.data;
          const mensaje =
            errorData?.detalles?.msg ||
            "Ha ocurrido un error al consumir los servicios, inténtelo de nuevo más tarde.";
          setFileResult(mensaje);
          setModalMessageOpen(true);
        } else {
          // Something happened in setting up the request that triggered an Error
          const mensaje =
            "Ha ocurrido un error al consumir los servicios, inténtelo de nuevo más tarde.";
          setFileResult(mensaje);
          setModalMessageOpen(true);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <ContainerAdmin>
      <Grid
        container
        item
        sm={12}
        className={classes.Container}
        alignItems="center"
        justifyContent="center"
      >
        <Grid
          container
          item
          direction="column"
          className={classes.ContainerReportes}
        >
          <h2 className={classes.mainTitle}>Reportes</h2>

          <Grid container item direction="row" alignItems="center">
            <Button className={classes.button}>
              <img src={graph_2} alt="graph_2" className={classes.icon} />
              <a
                className="Link"
                href="https://analytics.google.com/analytics/web/provision/#/provision"
                target="_blank"
                rel="noreferrer"
              >
                1. Reportes Google Analytics
              </a>
            </Button>
          </Grid>

          <Grid container item direction="row" alignItems="center">
            <Button className={classes.button} onClick={downloadGrap2}>
              <img src={graph_1} alt="graph_1" className={classes.icon} />
              2. Reporte de usuarios totales
            </Button>
          </Grid>
        </Grid>
      </Grid>
      {loading && <Loader />}
      <ModalMessage
        open={modalMessageOpen}
        handleClose={handleCloseMessageModal}
        title={fileResult}
        textOfButton="Aceptar"
      />
    </ContainerAdmin>
  );
};

export default Reporte_Alumnos;
