import React               from "react";
import {Grid, makeStyles}                 from '@material-ui/core';

const useStyles = makeStyles(theme =>({
  questionContainer:{
    width: "100%",
    height:"100%",
  },
  reading: {
    width: "80%",
    margin:"10px 0 10px 0 ",
    font: '22px lato',
    letterSpacing: '0.21px',
    [theme.breakpoints.down(1400)]:{
      font: " 18px lato",
      width: "85%",
    },
    [theme.breakpoints.down(1310)]:{
      font: "500 16px lato",
      // width: "80%",
      margin:"10px 0 10px 0"
    },
    [theme.breakpoints.down(925)]:{
      font: "500 14px lato",
      // width: "70%",
      margin:"6px 0 6px 0"
    },
    [theme.breakpoints.down(426)]:{
      font: "500 12px lato",
      // width: "70%",
      margin:"6px 0 6px 5%"
    }, 
  },
  table: {
    border: '1px solid black',
    width: "100%",
    font: '22px lato',
    [theme.breakpoints.down(1400)]:{
      font: " 18px lato",
    },
  },
  tdTable: {
    padding: "0 1%",
    border: '1px solid black',
  }
}));

const QuestionTable = (props) => {
  const classes = useStyles()


  return(
    <Grid container className={classes.questionContainer}>
      <p
        className={classes.reading}
      >
        9. Relaciona ambas columnas vinculando la descripción con el concepto que le corresponda.
      </p>
      <br/>
      <table className={classes.table}>
        <thead>
          <th style={{width: "60%", textAlign: "left"}}></th>
          <th style={{width: "40%", textAlign: "left"}}></th>
        </thead>
        <tbody>
          <tr>
            <td className={classes.tdTable}>
              <p className={classes.reading}> 
                1. Una propiedad de las moléculas del agua.
              </p>
            </td>
            <td className={classes.tdTable}>
              <p className={classes.reading}>
                a. Capilaridad
              </p>
            </td>
          </tr>
          <tr>
            <td className={classes.tdTable}>
              <p className={classes.reading}>
                2. Composición química del agua.
              </p>
            </td>
            <td className={classes.tdTable}>
              <p className={classes.reading}>
                b. Sólido (hielo)
              </p>
            </td>
          </tr>
          <tr>
            <td className={classes.tdTable}>
              <p className={classes.reading}>
                3. Estado del agua en el espacio.
              </p>
            </td>
            <td className={classes.tdTable}>
              <p className={classes.reading}>
                c. Contaminación
              </p>
            </td>
          </tr>
          <tr>
            <td className={classes.tdTable}>
              <p className={classes.reading}>
                4. Factor que ha contribuido para considerar al agua como un recurso no renovable.
              </p>
            </td>
            <td className={classes.tdTable}>
              <p className={classes.reading}>
                d. H<span style={{fontVariant: "all-small-caps"}}>2</span>O
              </p>
            </td>
          </tr>
        </tbody>
      </table>
    </Grid>
  )
}

export default QuestionTable;
