import React, {useEffect}                        from "react";
import axios                        from "../../../../services/axios";
import { Link, useParams }                     from "react-router-dom";
import { Button,makeStyles,Grid, Hidden}    from "@material-ui/core";
import Heraldo                      from "../img/HeraldosG3/03_Heraldo_G3_Evaluacion_remedial.png";
import Estrella                     from "../../../imgs/Estrella.png"
import ModalPoints                  from '../ModalPoints';

const useStyles = makeStyles(theme =>({
  mainContainer: {
    height:'100%',
    
  },
  ContainerIzquierdo:{
    position:'relative',
    background:'radial-gradient(circle, rgba(255,230,35,0.11246505438112742) 11%, rgba(200,105,205,0.20210090872286413) 53%)',
    height:'100%',
    maxWidth:'97%',
    [theme.breakpoints.down(1024)]:{
      maxWidth:'100%',
    },
  },
  ContainerEvaluacion:{
    position:'relative',
    width:'100vw',
    minHeight:'calc(100vh - 79.5px - 79.5px)',
    overflow: 'auto',
  },
  ContainerBarraInferior:{
    background:'#2DDAC9',
    height:'79.5px',
    [theme.breakpoints.down(850)]:{
      background: 'linear-gradient(90deg, #D3348F 0%, #F1CB5A 100%)',
    },
  },
  ContainerBarraDerecha:{
    background: 'linear-gradient(180deg, #D3348F 0%, #F1CB5A 100%)',
    width:'3%',
    height:'100vh',
    [theme.breakpoints.down(1280)]:{
      display:'none',
    },
  },
  ContainerTextoTitulo:{
    width: '450px',
    [theme.breakpoints.down(600)]:{
      width: '415px',
      marginTop: '110px'
    },
    '& .Titulo':{
      font:'45px Fredoka One',
      color:'#E35F95',
      margin:'0',
      [theme.breakpoints.down(1501)]:{
        font:'40px Fredoka One',
      },
      [theme.breakpoints.down(1001)]:{
        font:'30px Fredoka One',
      },
      [theme.breakpoints.down(600)]:{
        font:'26px Fredoka One',
      },
    },
  },
  ContainerTextoResultado:{
    width: '570px',
    minHeight: '151px',
    marginBottom: '40px',
    [theme.breakpoints.down(1440)]:{
      marginBottom: '20px', 
    },
    [theme.breakpoints.down(600)]:{
      width: '315px',
    },
    '& .Resultado':{
      font:'20px lato',
      color:'#4B4A4E',
      letterSpacing: '0.21px',
      margin:'0',
      textAlign:'center',
      [theme.breakpoints.down(850)]:{
        font:'18px lato',
      },
      [theme.breakpoints.down(600)]:{
        font:'16px lato',
      },
    }
  },
  ContainerEstrella:{
    marginBottom: '10px',
    '& .Divisor':{
      width: '39px',
      height: '0px',
      border: '4px solid #F1CB5A',
      borderRadius:'25px',
      [theme.breakpoints.down(769)]:{
        width:'29px',
      },
    },
    '& .Estrella':{
      height:'60px',
      margin:'0 19.5px',
      [theme.breakpoints.down(769)]:{
        height:'40px',
      },
    },
  },
  ContainerResultado:{
    position:'relative',
    width: '283px',
    height: '153px',
    marginBottom: '40px',
     background: '#FFFFFF 0% 0% no-repeat padding-box',
    boxShadow: '0px 2px 5px #00000029',
    border:' 3px solid #FBE6B9',
    borderRadius: '20px',
    [theme.breakpoints.down(1440)]:{
      marginBottom: '20px', 
    },
    [theme.breakpoints.down(769)]:{
      width: '233px',
      height: '103px',
      marginBottom: '0px', 
    },
    
    '& .TextoPuntaje':{
      position:'absolute',
      top:'18.75px',
      textAlign:'center',
      height:'30px',
      font:'25px lato',
      color:'#1DA698',
      margin:'0',
      [theme.breakpoints.down(769)]:{
        top:'8.75px',
        font:'20px lato',
      },
    },
    '& .Divisor':{
      position:'absolute',
      top:'61.36px',
      width: '179px',
      height: '0px',
      border: '3px solid #FBE6B9',
      [theme.breakpoints.down(769)]:{
        top:'41.36px',
        width: '129px',
      },
    },
    '& .ResultadoPuntaje':{
      position:'absolute',
      top:'73.24px',
      textAlign: 'center',
      font: '900 45px Lato',
      letterSpacing: '0px',
      color: '#DE7495',
      margin:'0',
      [theme.breakpoints.down(769)]:{
        font: '900 35px Lato',
        top:'53.24px',
      },
    },
  },
  ContainerHeraldo:{
    justifyContent: "flex-start",
    [theme.breakpoints.down(960)]:{
      display: 'none'
    },

    '& .Heraldo':{
      height:'100%',
    }
  },
  ContainerHeraldoImage: {
    width:'415px',
    height:'522px',
    // height:'100%',
    [theme.breakpoints.down(1440)]:{
      width:'315px',
      height:'422px',
    },
    [theme.breakpoints.down(960)]:{
      width:'265px',
      height:'372px',
    },
  },
  ContainerHeraldoHiddenImage: {
    marginTop: "20px",
    width:'auto',
    height:'312px',
    '& .Heraldo':{
      // paddingRight:'20%',
      height: "100%",
      // width:'36',
    }
  },

  link:{
    [theme.breakpoints.down(1440)]:{
      margin: "20px", 
    },
    textDecoration:'none',
  },
  BotonContinuar:{
    textTransform: 'none',
    height:'58px',
    width:'261px',  
    background: '#7A54BA',
    boxShadow: '0px 3px 6px #00000029',
    borderRadius: '61px',
    color: '#FFFFFF',
    font: 'bold 20px lato ',
    '&:hover':{
        background: '#dd78fb',
      },
  },



}));

const FinalResultadoBuentrabajo = () => {
  const classes = useStyles()
  const {remedialNumber, actividad, intento} = useParams()
  const [openOnce, setOpenOnce] = React.useState(false);
  const [changeButtonType, setChangeButtonType] = React.useState(false);
  const [finalScore, setFinalScore] =     React.useState(0)

  useEffect(() =>{
    axios.get( `/user/actividad/resume/get?id_actividad=${actividad}&intento=${intento}`)
    .then(response => {
      setFinalScore(response.data.resultado.puntos_total)
      console.log(response.data.resultado, "heraldo")
    })
  }, [])


  const handleClick = () => {
    setOpenOnce(true)
    setChangeButtonType(true)
  }

  const handleClose = () => {
    setOpenOnce(false);
  };

  return (
    <Grid 
      container
      direction="column"
      item sm={12}
      className={classes.mainContainer}
    >
      <Grid container justifyContent="center" item sm={12} className={classes.ContainerIzquierdo}>
        <Grid container item justifyContent="center" alignItems="center" className={classes.ContainerEvaluacion}>
          <Grid container item xs={12} justifyContent="center" alignItems="center" className={classes.ContainerTextoTitulo}>
            <p className="Titulo">
            ¡Has llegado al final <br/>
              de esta actividad!
            </p>
          </Grid> 
          <Grid container item xs={12} md={7} direction="column" justifyContent="center" alignItems="center">
            <Grid container item  className={classes.ContainerTextoResultado}>
            <Grid container item justifyContent="center" alignItems="center" className={classes.ContainerEstrella}>
              <div className="Divisor"></div>
              <img className="Estrella" src={Estrella} alt="" />
              <div className="Divisor"></div>
            </Grid>
              <p className="Resultado">
                Descubriste que la comida es arte porque transforma la esencia de los elementos que la conforman. Recuerda que 
                la comida siempre está presente en la cultura, porque se transmite de generación en generación.<br/><br/>
                Además, aprendiste ideas que no estaban escritas de manera explícita en la lectura. Como pudiste notar, 
                en ocasiones hace falta analizar y relacionar la información para comprender todo lo que nos quiere decir el texto.
              </p>
            </Grid>
            <Grid container item direction="column" justifyContent="center" alignItems="center" className={classes.ContainerResultado}>
              <p className="TextoPuntaje">Tu puntaje es:</p>
              <div className="Divisor"></div>
              <p className="ResultadoPuntaje">{finalScore}/10</p>
            </Grid>
            <Hidden only={["md","xl", "lg"]}>
              <Grid className={classes.ContainerHeraldoHiddenImage}>
                <img className="Heraldo" src={Heraldo} alt="" />
              </Grid>
            </Hidden>
            {
              changeButtonType ?
              <Link className={classes.link} to='/mapa' >
                <Button onClick={handleClick} className={classes.BotonContinuar}>
                  Continúa la aventura
                </Button>
              </Link>
            :
              <Grid className={classes.link}>
                <Button onClick={handleClick} className={classes.BotonContinuar}>
                  Continúa la aventura
                </Button>
              </Grid>
            }
          </Grid>
          <Grid container item xs={12} md={5} alignItems="center" className={classes.ContainerHeraldo} > 
            <Grid className={classes.ContainerHeraldoImage}>
              <img className="Heraldo" src={Heraldo} alt="" />
            </Grid>
          </Grid>
        </Grid>
        <Grid container direction="row-reverse" item sm={12} className={classes.ContainerBarraInferior}></Grid>
      </Grid>
      <Grid container item sm={12} className={classes.ContainerBarraDerecha}></Grid>
      <ModalPoints open={openOnce} setOpenOnce={setOpenOnce} handleClose={handleClose}/>
    </Grid>
  );
}

export default FinalResultadoBuentrabajo;
