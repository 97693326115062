import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
    ContenedorLectura:{
        width:'502px',
                  [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
    },
    ContainerTitulo:{
        margin:'0 0 46px 0',
        '& .Titulo':{
            letterSpacing: '0px',
            color: '#FC5E9E',
            margin:'0',
            font:'43px fredoka one',
            lineHeight:'52px',
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            margin:'0 0 36px 0',
            color:'#000',
            font:'18px lato',
            lineHeight :'28px',
            letterSpacing: '0.17px',
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .Imagen':{
            width:'100%',
        },
    },
}));


const Gatos_y_ratones = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>

            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >
                    Gatos y ratones
                    </h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                            De un día a otro los ratones invadieron la granja de la señora Vélez. ¡Eran una pesadilla! 
                            Comían demasiado de todo y a todas horas. Dejaron sin alimento a las gallinas, arruinaron 
                            los muebles y acabaron con la despensa. Además, hacían mucho ruido por las noches.
                            <br/><br/>
                            —¡Esto ya es demasiado! —exclamó la señora una madrugada que despertó con la cola de un ratón 
                            en el rostro. Entonces decidió comprar dos gatos y traerlos a casa con el fin de que los 
                            ahuyentaran.
                        </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                            <img className='Imagen'   src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img2do/Sesion20/1.png"  alt="Tarjeta" />
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                            —Éstos son los mejores —señaló el hombre que los vendía—. Hacen un trabajo magnífico. 
                            En pocos días los ratones se habrán ido para siempre de su granja.
                            <br/><br/>
                            La señora Vélez observó a los gatos y se dio cuenta de que no se sentían cómodos juntos.
                            <br/><br/>
                            —¿Han trabajado en equipo? —preguntó al vendedor.
                            <br/><br/>
                            —Eh… No. Pero si por separado son muy buenos, ¡imagínese en conjunto!
                            <br/><br/>
                            Aunque no quedó convencida, se los llevó a su hogar.
                            Pero ese primer día bastó para confirmar sus sospechas. Desde que llegaron, se dieron 
                            la espalda y cada uno se fue por su lado. No se reunían ni para comer. “Bueno, no tienen 
                            que ser los mejores amigos”, pensó la señora Vélez. El verdadero problema era que nunca 
                            se ponían de acuerdo en lo que harían para ahuyentar a los ratones. Mientras uno los perseguía 
                            por la retaguardia, el otro los sorprendía por el frente. ¡Dos estrategias de caza opuestas! 
                            Así que los ratones se esparcían por todos lados y nunca abandonaban la casa.
                        </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                            <img className='Imagen'  src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img2do/Sesion20/2.png"  alt="Tarjeta" />
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                            En una ocasión los gatos hicieron tan mal las cosas que ellos mismos resultaron perseguidos 
                            por los ratones; estos últimos sí se organizaron y planearon todo minuciosamente.
                            <br/><br/>
                            —Es inútil —dijo la señora Vélez cuando le regresó los gatos al hombre—. Nunca quieren 
                            ponerse de acuerdo. ¡Ahora tengo más ratones que antes! Y todo porque los gatos no se 
                            comunican entre ellos.
                        </p>
                    </Grid>
                </Grid>
        </Grid>
    </Grid>
    );
}
 
export default Gatos_y_ratones;
