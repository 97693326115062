import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
      Contenedor:{
        background:'#E9FBF9',
        width:'100vw',
        overflowX:'clip',
        [theme.breakpoints.down(1161)]:{
            background:'none',
           overflowX:'unset',
        },
        [theme.breakpoints.down(600)]:{
            marginTop: '79.51px',
        }
    },
    ContainerInfoLectura:{
        position:'relative',
        height:'86px',
        background:'#F6664D',
        '& .BarraInfoLectura':{
            height:'86px',
            background:`Url(https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Barras/Naranja.png)`,
        },
        '& .InfoLectura':{
            position:'absolute',
            height:'86px',
            width:'219px',
            boxSizing:'border-box',
            padding:'17.2px 0',
            background:'#FAFAFA',
            borderRadius:'50px',
            boxShadow: '2px 2px 2px 2px #00000029',
            zIndex:'4',
        },
        '& .InfoLectura:before':{
            position:'absolute',
            display:'flex',
            boxSizing:'border-box',
            padding:'0 0 0 10px',
            justifyContent:'center',
            alignItems:'center',
            height:'39.88px',
            width:'39.88px',
            borderRadius:'50%',
            content:'""',
            boxShadow: '3px 0px .5px 0px #00000029',
            background:'#FAFAFA',
            left:'90px',
            top:'65px',
            transform: 'rotate(0.25turn)',
        },
        '& .InfoLectura:after':{
            content:'""',
            height:'15px',
            width:'15px',
            borderTop:'2px solid #F6664D',
            borderLeft:'2px solid #F6664D',
            position:'absolute',
            bottom:'-10px',
            left:'Calc(50% - 8px)',
            transform: 'rotate(0.625turn)',
        },
        '& .Sesion':{
            borderLeft:'2px solid #D8D8D8',
        },
        '& .Dato':{
            margin:'0 0 5px 0',
            textAlign:'center',
            font:'bold 17px lato',
            color:'#F6664D',
        },
        '& .Caracter':{
            margin:'0',
            textAlign:'center',
            font:'bold 17px lato',
            color:'#000000',
        },
    },
    ContenedorLectura:{
        background:'#fff',
        width:'1160px',
        height:'100%',
        padding:'57px 168px 35px' ,
        [theme.breakpoints.down(1161)]:{
            width:'502px',
            padding: '57px 0 0 0',
        },
        [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
        '& .ContainerDerechos':{
            bottom:'80px',
            [theme.breakpoints.down(1161)]:{
                display:'none',
            }
        },
        '& .Derechos':{
            textAlign:"center",
            margin:"0px",
            font:" 15px lato",
            color:"#717476",
        },
        '& .Escritorio':{
            display:'block',
            [theme.breakpoints.down(1161)]:{
                display:'none'
            },
        },
        '& .Tablet':{
            display:'none',
            [theme.breakpoints.down(1161)]:{
                display:'block'
            },
        },
    },
    ContainerTitulo:{
        margin:'0 0 45px 0',
        [theme.breakpoints.down(1161)]:{
            margin:'0 0 38px 0',
        },
        '& .Titulo':{
            font:'48px fredoka one',
            letterSpacing: '0px',
            color: '#00ADB6',
            margin:'0',
            textAlign:'center',
            [theme.breakpoints.down(1161)]:{
                font:'43px fredoka one',
                lineHeight:'52px',
                textAlign:'center',
            },
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            lineHeight : '30px',
            margin:'0 0 36px 0 ',
            font:'22px lato',
            color:'#000',
            letterSpacing: '0.20px',
            [theme.breakpoints.down(1161)]:{
                font:'18px lato',
                letterSpacing:'.17px',
                lineHeight:'28px',
            },
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .TextImg':{
            margin:'24px 0 0 0',
            font:'20px lato',
            alignSelf:'flex-start',
            color:'#717476',
            letterSpacing:'.19px',
            [theme.breakpoints.down(1161)]:{
                font:'16px lato',
                letterSpacing:'.15px',
                lineHeight:'25px',
            },
            [theme.breakpoints.down(502)]:{
                font:' 11px lato',
                lineHeight :'14px',
                letterSpacing: '0.10px',
            },
        },
        '& .Imagen':{
            [theme.breakpoints.down(1161)]:{
                width:'100%',

            },
        },

    },

}));

const Los_premios_las_trayectorias_y_el_dinero = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
             <Grid container item justifyContent="center" className={classes.ContainerInfoLectura}>
               <Grid container className="BarraInfoLectura"></Grid>
               <Grid container item className="InfoLectura">
                    <Grid container item direction="column" xs={6} justifyContent="center">
                        <p className="Dato">Grado</p>
                        <p className="Caracter">6</p>
                    </Grid>
                    <Grid container item direction="column" xs={6} justifyContent="center" className="Sesion">
                        <p className="Dato">Sesión</p>
                        <p className="Caracter">56R</p>
                    </Grid>
               </Grid>
            </Grid>

            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >
                    Los premios, las trayectorias <br/>
                    y el dinero
                    </h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                            En el mundo de las artes existen muchos reconocimientos a los creadores que día a 
                            día se superan a sí mismos y entregan obras extraordinarias al público. Esos premios 
                            buscan celebrar las creaciones que destacan de lo cotidiano para que todo mundo 
                            voltee a verlas, las aprecie y las aplauda. En estos honores, surge una pregunta: ¿esos 
                            reconocimientos realmente son verdaderos? Dicho de otra manera, ¿se está 
                            premiando lo que vale la pena? ¿De qué depende que un premio se le dé a un creador 
                            y no a otro? Las respuestas son y serán siempre subjetivas, es decir que dependerán 
                            de un jurado que intentará, en la medida de lo posible, ser lo más justo. <br/><br/>
                            
                            Los premios son numerosos para los pintores, los escritores y hasta los cineastas. De
                            hecho, cada año se añade un premio más a esta lista. ¿Pero se necesita premiar a 
                            tantas personas o tantas veces a una sola? ¿Cuál es el propósito? Para discutir esta 
                            cuestión, a continuación se plantean dos ejemplos. El primero es el Premio Nobel de 
                            Literatura, que es entregado anualmente por la Academia Sueca de las Letras y es 
                            uno de los honores más codiciados por los escritores de todo el globo terráqueo. El 
                            segundo es la famosa ceremonia de los Oscar, llevada a cabo cada año por la 
                            Academia de Cinematografía Estadounidense.
                        </p>
                    </Grid>
                    <Grid container  sm={12}>
                        <Grid container>
                            <img  className='Imagen' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion56R/1.png"  alt="Tarjeta" />
                        </Grid>
                        <Grid container justifyContent='center'>
                            <p className='TextImg'>Los premios son una manera de reconocer el trabajo de los artistas y creadores.</p>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                            El premio Nobel consta de una medalla y una cantidad de dinero aproximada de 1.1 
                            millones de dólares. Según las reglas de la Academia, este reconocimiento está 
                            destinado para aquel autor cuya trayectoria haya destacado a lo largo de la historia y 
                            cuya obra sea un patrimonio para la humanidad. ¿Pero cómo se puede determinar 
                            eso? ¿Quién decide qué es destacable y qué no lo es? Ésta es justamente una de las 
                            muchas críticas que se le hace al jurado compuesto por miembros de la Academia 
                            Sueca, quienes, por más que intenten no hacerlo, votan desde su perspectiva personal, 
                            pues no es posible hacerlo desde otra. Quizá este premio no esté del todo 
                            ligado a modas o tendencias, pero en 2016 algo inesperado pasó cuando ese jurado 
                            le entregó el premio a un cantante: Bob Dylan. <br/><br/>
                            
                            Ese año, la Fundación Nobel se envolvió en polémicas haciendo que muchos lectores 
                            reaccionaran ante la elección de Dylan como el ganador. Desde luego, sobran los 
                            escritores a los cuales premiar y por eso surgen todavía más preguntas: ¿Fue para 
                            generar nuevos consumidores de música? ¿Fue para revivir la carrera del cantante? 
                            ¿Bob Dylan necesitaba ese premio y ese dinero cuando ha tenido una trayectoria 
                            plagada de reconocimiento?
                        </p>
                    </Grid>
                    <Grid container  sm={12}>
                        <Grid container>
                            <img  className='Imagen' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion56R/2.png"  alt="Tarjeta" />
                        </Grid>
                        <Grid>
                            <p className='TextImg'>El premio Nobel de Literatura es uno de los reconocimientos más buscados por los escritores 
                            actuales.</p>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                            El caso de los Oscar quizá es más complejo, pues, al contrario de un 
                            Nobel, un creador se lo puede ganar más de una vez. Los Oscar gozan del glamur que envuelve 
                            al cine y a los directores y actores. A pesar de ello, no está alejado de la polémica ni 
                            de las preguntas críticas. <br/><br/>
                            
                            Aquí, quienes votan para saber quiénes se llevarán la estatuilla dorada son los 
                            cientos de miembros de la Academia, la cual está compuesta por productores, 
                            directores, actores, guionistas, etcétera. Aunque ellos, además de basarse en sus 
                            gustos y preferencias, se inclinan también por una cuestión de mercadotecnia. Esto 
                            es cuestionable cuando hay películas producidas por ciertos estudios, compañías, 
                            patrocinadores y demás que necesitan más apoyo. También se le ha acusado a este 
                            jurado de ser más político que artístico, pues se han premiado filmes que quizá no 
                            son buenos, pero que cumplen con cuestiones de género, temas sociales, 
                            sentimientos, etcétera.

                        </p>
                    </Grid>
                    <Grid container  sm={12}>
                        <Grid container>
                            <img  className='Imagen' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion56R/3.png"  alt="Tarjeta" />
                        </Grid>
                        <Grid>
                            <p className='TextImg'>Los Oscar tienden a favorecer un cierto tipo de ganador vez tras vez. Por ejemplo, directores 
                            hombres y blancos.</p>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                            Ambos ejemplos son sólo una pequeña muestra de las muchas ideas desde las cuales 
                            se parte para entregar un premio: la mercadotecnia, la política o el verdadero 
                            talento. Así que uno como consumidor de arte tiene que estar atento a la crítica, pero
                            también a sus propios gustos y sentimientos.
                        </p>
                    </Grid>
                </Grid>
                <Grid container item xs={12} justifyContent="center" className="ContainerDerechos">
                    <p className="Derechos">
                        © 2022. Todos los derechos reservados, Pearson, Circuito de lectores.
                    </p>                
                </Grid>
        </Grid>
    </Grid>
    );
}
 
export default Los_premios_las_trayectorias_y_el_dinero;
