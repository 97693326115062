import { Button, Grid, makeStyles } from "@material-ui/core";
import React, { useContext, useEffect } from "react";
import DrawerContext from "../../../../../usability/DrawerContext";
import { useOnLoadImages } from "../../../../hooks/useOnLoadImages";
import Loader from "../../../../Loader/Loader";
import Drawer from "../../ReadingTools/Drawer";

const useStyles =  makeStyles (theme =>({
     Contenedor:{
        background:'#E9FBF9',
        width:'100vw',
        overflowX:'clip',
        [theme.breakpoints.down(1161)]:{
            background:'none',
           overflowX:'unset',
        },
        [theme.breakpoints.down(600)]:{
            marginTop: '79.51px',
        }
    },
  ContainerInfoLectura: {
    position: "relative",
    height: "86px",
    [theme.breakpoints.down(502)]:{
        height: "43px",
    },
    background: "#76D4C3",
    "& .BarraInfoLectura": {
      height: "86px",
      background: `Url(https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Barras/Azul.png)`,
        [theme.breakpoints.down(502)]:{
            height: "43px",
        },
    },
    "& .InfoLectura": {
      position: "absolute",
      height: "86px",
      width: "219px",
      boxSizing: "border-box",
      padding: "17.2px 0",
      background: "#FAFAFA",
      borderRadius: "50px",
      boxShadow: "2px 2px 2px 2px #00000029",
      zIndex: "4",
        [theme.breakpoints.down(502)]:{
            height: "43px",
            width: "109px",
            padding: "8.6px 0",
            borderRadius: "25px",
        },
    },
    "& .InfoLectura:before": {
      position: "absolute",
      display: "flex",
      boxSizing: "border-box",
      padding: "0 0 0 10px",
      justifyContent: "center",
      alignItems: "center",
      height: "39.88px",
      width: "39.88px",
      borderRadius: "50%",
      content: '""',
      boxShadow: "3px 0px .5px 0px #00000029",
      background: "#FAFAFA",
      left: "90px",
      top: "65px",
      transform: "rotate(0.25turn)",
      [theme.breakpoints.down(502)]:{
        left: "45px",
        top: "30px",
        height: "20.88px",
        width: "20.88px",
        },
    },
    "& .InfoLectura:after": {
      content: '""',
      height: "15px",
      width: "15px",
      borderTop: "2px solid #20A698",
      borderLeft: "2px solid #20A698",
      position: "absolute",
      bottom: "-10px",
      left: "Calc(50% - 8px)",
      transform: "rotate(0.625turn)",
      [theme.breakpoints.down(502)]:{
        height: "10px",
        width: "10px",
        left: "Calc(50% - 4.5px)",
        bottom: "-3px",
        borderTop: "1px solid #20A698",
        borderLeft: "1px solid #20A698",
        },
    },
    "& .Sesion": {
      borderLeft: "2px solid #D8D8D8",
    },
    "& .Dato": {
      margin: "0 0 5px 0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#20A698",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        margin: "0 0 2.5px 0",
        },
    },
    "& .Caracter": {
      margin: "0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#000000",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        },
    },
       '& .Regresar':{
        color: '#F2624F',
        border: '#F2624F 1px solid',
        height: '30px',
        width:'100px',
        borderRadius: '20px',
        letterSpacing: '0.14px',
        textTransform: 'none',
        background:'#FEFAF1',
        top:'calc(50% - 15px)',
        left:'5%',
        zIndex:4,
        [theme.breakpoints.down(502)]: {
          font:'bold 10px lato',
          width: "60px",
        },
      },
  },
    ContenedorLectura:{
        background:'#fff',
        width:'1160px',
        height:'100%',
        padding:'60px 168px 35px ',
        
        transform: (props) =>
        props.scale > 1 ? `scale(${props.scale}) translate(0px, 10%)` : `none`,
        [theme.breakpoints.down(1161)]:{
            width:'502px',
            padding:'60px 0 0 0',
          },
                [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },

        '& .Escritorio':{
            display:'block',
            [theme.breakpoints.down(1161)]:{
                display:'none'
            },
        },
        '& .Tablet':{
            display:'none',
            [theme.breakpoints.down(1161)]:{
                display:'block'
            },
        }
    },
    ContainerTitulo:{
        margin:'0 0 34px 0',
        [theme.breakpoints.down(1161)]:{
            margin:'0 0 34px 0',
        },
        '& .Titulo':{
            font:'48px fredoka one',
            letterSpacing: '0px',
            lineHeight:'58px',
            color: '#489CFF',
            margin:'0',
            textAlign:'center',
            [theme.breakpoints.down(1161)]:{
                font:'43px fredoka one',
                lineHeight:'52px',
            },
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
        '& .Color':{
            color: '#F6664D',
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            lineHeight : '30px',
            margin:'0 0 36px 0',
            font:'22px lato',
            color:'#000',
            letterSpacing: '0.20px',
            [theme.breakpoints.down(1161)]:{
                font:'18px lato',
                lineHeight :'28px',
                letterSpacing: '0.17px',
            },
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .Lectura2':{
            margin:'0 ',
        },
        '& .Esp':{
            margin:'0 ',
            padding:'0 25px 0 0',
            [theme.breakpoints.down(1281)]:{
            padding:'0',
            },
        },
        '& .Esp2':{
            margin:'0 ',
            padding:'0 0 0 28px',
            [theme.breakpoints.down(1281)]:{
            padding:'0',
            },
        },
        '& .SubTitulo':{
            font:'bold 30px lato',
            color:'#ED3E3A',
            letterSpacing:'.28px',
            lineHeight:'30px',
            margin:'0',
            [theme.breakpoints.down(1161)]:{
                lineHeight :'28px',
                font:'bold 26px lato',
                letterSpacing: '0.24px',
            },
            [theme.breakpoints.down(502)]:{
                font:'bold 18px lato',
                lineHeight :'24px',
                letterSpacing: '0.21px',
            },
        },
        '& .SubTitulo2':{
            font:'bold 26px lato',
            color:'#FF7F00',
            letterSpacing:'.24px',
            lineHeight:'28px',
            margin:'0',
            [theme.breakpoints.down(1161)]:{
                font:'bold 22px lato',
                lineHeight :'24px',
                letterSpacing: '0.21px',
            },
            [theme.breakpoints.down(502)]:{
                font:'bold 18px lato',
                lineHeight :'20px',
                letterSpacing: '0.16px',
            },
        },
        '& .TextImg':{
            font:'20px lato',
            color:'#717476',
            letterSpacing:'.19px',
            lineHeight:'30px',
            margin:'24px 0 0 0',
            [theme.breakpoints.down(1161)]:{
                font:' 16px lato',
                lineHeight :'25px',
                letterSpacing: '0.15px',
            },
            [theme.breakpoints.down(502)]:{
                font:' 11px lato',
                lineHeight :'14px',
                letterSpacing: '0.10px',
            },
        },

        '& .Imagen':{
            [theme.breakpoints.down(1161)]:{
                width:'100%',
            },
        }, 
        '& .Imagen2':{
            [theme.breakpoints.down(502)]:{
                width:'80.87%',
            },
        }, 
      
    }
}));

const Di_sí_a_las_vacunas = (props) => {

  const {activeStep, setActiveStep, handlePreviewStep, id_actividad} = props
  
  const handleContinue = () => {
    setActiveStep(activeStep - 1)
    handlePreviewStep()
  }

    const {
      handleSize,
    disabledZoom,
    closeZoom,
    handleZoom,
    scale
  }= useContext(DrawerContext);
  const [loading, setLoading] = React.useState(true);
  const wrapperRef = React.useRef(null);
  const imagesLoaded = useOnLoadImages(wrapperRef);

  const detectSize = () => {
    const lectura = document.getElementById("lectura");
    const newWidth = lectura?.offsetWidth;
    const newHeight = lectura?.offsetHeight;
    setLoading(false);
    if (lectura) handleSize(newHeight, newWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", detectSize);
    return () => {
      handleZoom()
      window.removeEventListener("resize", detectSize);
      closeZoom()
    };
  }, []);

  const classes = useStyles({ disabledZoom,scale });
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
             <Grid container item justifyContent="center" className={classes.ContainerInfoLectura}>
                       <Grid container className="BarraInfoLectura">
            <Button className="Regresar" onClick={handleContinue}>
                Regresar
            </Button>
        </Grid>
               <Grid container item className="InfoLectura">
                    <Grid container item direction="column" xs={6} justifyContent="center">
                        <p className="Dato">Grado</p>
                        <p className="Caracter">5</p>
                    </Grid>
                    <Grid container item direction="column" xs={6} justifyContent="center" className="Sesion">
                        <p className="Dato">Sesión</p>
                        <p className="Caracter">54</p>
                    </Grid>
               </Grid>
            </Grid>
            {!imagesLoaded && <Loader />}
        <Grid
            container
            item
            className={classes.ContenedorLectura}
            justifyContent="center"
            id="lectura"
            onLoad={detectSize}
            ref={wrapperRef}
        >
<Drawer id_lectura={id_actividad} />
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                        <h2 className="Titulo">Di <span className="Color"> sí </span> a las vacunas</h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                       <p className='Lectura Lectura2'>
                        Durante muchos siglos el ser humano vivía en promedio 30 años. Hoy la esperanza 
                        de vida es casi tres veces mayor, porque los avances en la medicina han permitido 
                        curar enfermedades y prevenirlas. Uno de estos avances es la vacunación.<br/><br/> 
                        
                        <h3 className='SubTitulo'>Antecedentes e historia de las vacunas</h3><br/> 
                        
                        Antes de la vacunación algunos procedimientos perseguían fines parecidos. Por 
                        ejemplo, la variolización consistía en inhalar costras pulverizadas de viruela. Esto a 
                        veces activaba el sistema de defensa del cuerpo y lo preparaba para enfrentar al 
                        virus, pero en otras ocasiones la persona contraía la enfermedad y podía morir. <br/><br/>
                        
                        Edward Jenner fue el primero en conseguir un método seguro para inmunizar contra 
                        la viruela. Utilizó el pus de las lesiones de una enfermedad similar, pero mucho 
                        menos letal, llamada <em>variola vaccinia</em> (“viruela vacuna”, en latín). Se llamaba así porque 
                        las pústulas les salían a las personas que ordeñaban vacas. De allí viene también el 
                        nombre <em>vacuna</em>. <br/><br/>
                        
                        Asimismo, el microbiólogo francés Louis Pasteur inventó la vacuna contra la rabia y 
                        contribuyó al desarrollo de la inmunología. A partir de entonces se han realizado 
                        innumerables investigaciones que han permitido prevenir y erradicar muchas 
                        enfermedades. <br/><br/>
                        
                        <h3 className='SubTitulo2'>¿Cómo funciona una vacuna?</h3><br/>
                        
                        Una vacuna puede contener los mismos agentes que causan las enfermedades, pero 
                        <em> atenuados</em>, es decir, con poca capacidad para producirlas. El cuerpo activa las 
                        defensas contra estos agentes y los elimina. Después de dicho proceso las defensas 
                        conservan una <em>memoria inmunológica</em>: recuerdan a la enfermedad y cómo combatirla. 
                        Así, el cuerpo está listo para evitar la entrada o el desarrollo de los virus, bacterias o 
                        toxinas contra los que fue vacunado.
                       </p>                   
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container lg={7} md={12}>
                        <p className='Lectura Esp'>
                            La vacunación es una de las prácticas de salud que más beneficios ha brindado a 
                            la humanidad. Consiguió erradicar la viruela. Algunas otras enfermedades están 
                            en proceso de desaparecer, por ejemplo, la poliomielitis, que dejaba secuelas 
                            muy graves, como parálisis. También contribuye a curar o a prevenir enfermedades 
                            como la rabia. 
                            <br/><br/>
                            
                            La mayoría de los esquemas de vacunación está dirigida a los niños 
                            y a los adolescentes, pero es importante que los adultos también reciban protección 
                            contra las enfermedades propias de su edad. 
                            <br/><br/>

                            Aplicarse una vacuna puede tener algunos inconvenientes, ya que puede generar
                            algunos efectos secundarios, en su mayoría leves.
                        </p>
                    </Grid>
                    <Grid container lg={5} md={12}  >
                        <Grid container item justifyContent='center' >
                            <img className="Imagen2" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion54/1.png" alt="Tarjeta" />
                        </Grid>
                        <Grid container >
                            <p className='TextImg Esp2'> A veces la zona en la que se aplica la inyección presenta enrojecimiento o dolor.</p>
                        </Grid>

                    </Grid>

                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container >
                        <p className='Lectura'>
                            En ocasiones la vacuna produce fiebre ligera y dolor de cabeza. Y como cualquier 
                            fármaco, puede tener efectos secundarios graves, pero éstos ocurren con una 
                            frecuencia mínima y dependen de muchos factores, como la edad, las características 
                            físicas y la historia médica. Mucho peores son las enfermedades graves que 
                            podríamos contraer si no nos vacunáramos. Algunas de ellas dejan secuelas de por 
                            vida. <br/><br/>
                            
                            <h3 className='SubTitulo'>Antivacunas</h3><br/>
                            
                            Los efectos secundarios graves y ciertas creencias particulares han llevado a algunas 
                            personas a sostener que las vacunas no son efectivas ni beneficiosas. Incluso llegan a 
                            considerarlas perjudiciales. Por ejemplo, las relacionan infundadamente con el 
                            desarrollo de trastornos como el autismo. <br/><br/>
                            
                            A lo largo del tiempo, quienes sostienen esta postura (llamados <em>antivacunas</em>) han 
                            construido un movimiento. Tienen argumentos fijos, promueven sus ideas y las aplican con sus 
                            familias.
                        </p>
                    </Grid>
                    <Grid container item justifyContent='center' >
                        <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion54/2.png" alt="Tarjeta" />
                    </Grid>
                    <Grid container >
                        <p className='TextImg '> Los grupos antivacunas tienen eventos en los que exigen a los gobiernos el derecho a no vacunarse.</p>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container >
                        <p className='Lectura'>
                            Se ha dicho que la negativa de los padres antivacunas a inyectar a sus hijos causó que 
                            en 2019 aumentara el número de casos registrados de sarampión, una enfermedad 
                            bastante grave. <br/><br/>
                            
                            Los antivacunas surgieron cuando los gobiernos hicieron obligatoria la vacunación a 
                            mediados del siglo <span style={{fontVariant: "all-small-caps"}}>XIX</span>. Desde ese momento, estas personas han intentado conseguir 
                            razones que avalen su postura. Sin embargo, la Organización Mundial de la Salud ha
                            refutado científicamente sus argumentos: los beneficios de las vacunas son mucho 
                            mayores que sus riesgos, tanto para quien las recibe como para la población en 
                            general. <br/><br/>
                            
                            ¿Cuáles son los argumentos de los antivacunas? ¿Cómo han sido refutados? Te 
                            invitamos a investigar más sobre el tema para que tomes una postura informada.
                        </p>
                    </Grid>
                </Grid>
                
        </Grid>

        <Grid container item style={{ marginTop: 80}} ></Grid>
    </Grid>
    );
}
 
export default Di_sí_a_las_vacunas;
