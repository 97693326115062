import { makeStyles, Grid } from "@material-ui/core";
import React, { useContext, useState } from "react";

import ContainerAdminPearson from "./ContainerAdminPearson";
import AuthContext from "../../../auth/AuthContext";
import ModalPasswordChange from "../../Modals/ModalPasswordChange";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    padding: "48px 0 48px 0",
    width: "100vw",
    minHeight: "92vh",
    // marginTop: "8vh",
    background:
      "linear-gradient(217deg, rgba(255,194,0,0.4962185557816877) 2%, rgba(255,255,255,1) 25%, rgba(255,255,255,0.4962185557816877) 81%, rgba(144,81,177,1) 98%)",
    [theme.breakpoints.up("sm")]: {
      // marginTop: "0",
    },
    // [theme.breakpoints.up(1920)]:{
    //     height:'92vh',
    // },
    // [theme.breakpoints.down(1920)]:{
    //     height:'92vh',
    // },
    // [theme.breakpoints.down(1811)]:{
    //     height:'1150px',
    // },
    // [theme.breakpoints.down(1487)]:{
    //     height:'1430px',
    // },
    // background: 'linear-gradient(217deg, rgba(255,194,0,0.4962185557816877) 2%, rgba(255,255,255,1) 25%, rgba(255,255,255,0.4962185557816877) 81%, rgba(144,81,177,1) 98%)',
    // [theme.breakpoints.down(1280)]:{
    //     background:'none',

    // },
  },
  boxContainer: {
    background: "#fff",
    width: "80%",
    minHeight: "80vh",
    height: "auto",
    borderRadius: "42px",
    padding: "20px",
    gap: "20px",
    alignItems: "center",
    boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.5)",
    [theme.breakpoints.up("md")]: {
      padding: "48px 20px 48px 20px",
    },
  },
  titleContainer: {
    margin: "20px 0",
    [theme.breakpoints.up("xl")]: {
      margin: "30px 0",
    },
  },
  title: {
    font: "28px Fredoka One ",
    margin: "0",
    textAlign: "center",
    color: " #DC5EA6",
    [theme.breakpoints.up("md")]: {
      fontSize: "36px",
    },
    [theme.breakpoints.up("xl")]: {
      fontSize: "42px",
    },
  },
  link: {
    cursor: "pointer",
    textDecoration: "none",
    // height: "31px",
    // width: "190px",
  },
  formContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    maxWidth: "560px",
    alignItems: "flex-start",
    gap: "20px",
  },
  formItemContainer: {
    gap: "10px",
  },
  input: {
    width: "100%",
    height: "40px",
    paddingLeft: "27px",
    borderRadius: "61px",
    boxSizing: "border-box",
    // border: " solid #1D2AAD 3px ",
    border: "none",
    background: "#FFFFFF",
    font: "Helvetica",
    fontSize: "16px",
    //    width: '449px',
    // height: '72px',
    // margin: '43px 139px 19px 66px',
    // padding: '26px 24px 25px',
    backgroundColor: "#f0f0f0",
    "&:focus": {
      outline: "none",
    },
    [theme.breakpoints.up("lg")]: {
      height: "60px",
      // maxWidth: "449px",
      fontSize: "18px",
    },
  },
  label: {
    fontWeight: "bold",
    fontFamily: "Lato, sans-serif",
  },
  PurpleButton: {
    color: "#FFFFFF",
    backgroundColor: "#9051b1",
    borderRadius: "30px",
    // margin: "0 auto",
    minWidth: "200px",
    alignSelf: "center",
    width: "100%",
    maxWidth: "238px",
    minHeight: "45px",
    border: "none",
    textTransform: "unset",
    cursor: "pointer",
    fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
    fontWeight: "500",
    padding: "6px 16px",
    lineHeight: "1.75",
    transition:
      "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    boxShadow:
      "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
    "&:hover": {
      backgroundColor: "#B965D3 ",
    },
  },
  WitheButton: {
    color: "#9051b1",
    // borderColor: "#9051b1",
    border: "1px solid #9051b1",
    backgroundColor: "#FFFFFF",
    borderRadius: "30px",
    // margin: "0 auto",
    minWidth: "200px",
    width: "100%",
    maxWidth: "238px",
    minHeight: "45px",
    textTransform: "uppercase",
    cursor: "pointer",
    fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
    fontWeight: "500",
    padding: "6px 16px",
    lineHeight: "1.75",
    transition:
      "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    boxShadow:
      "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
    "&:hover": {
      backgroundColor: "#B965D330 ",
    },
  },
}));

const PerfilAdmin = () => {
  const classes = useStyles();
  const { user } = useContext(AuthContext);
  const [modalChangePasswordOpen, setModalChangePasswordOpen] = useState(false);
  const handleModalOpen = () => {
    setModalChangePasswordOpen(!modalChangePasswordOpen);
  };

  return (
    <ContainerAdminPearson>
      <Grid
        container
        item
        sm={12}
        alignItems="center"
        justifyContent="center"
        className={classes.mainContainer}
      >
        <Grid
          container
          item
          direction="column"
          className={classes.boxContainer}
        >
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="center"
          >
            <Grid
              container
              item
              alignItems="center"
              justifyContent="center"
              className={classes.titleContainer}
            >
              <h2 className={classes.title}>Perfil del administrador</h2>
            </Grid>
          </Grid>
          <Grid
            container
            direction="column"
            className={classes.formContainer}
            justifyContent="center"
            alignItems="center"
          >
            <Grid
              container
              direction="column"
              className={classes.formItemContainer}
            >
              <label htmlFor="input-name" className={classes.label}>
                Nombre(s)
              </label>
              <input
                type="text"
                value={user?.name}
                className={classes.input}
                id="input-name"
                disabled
                readOnly
              />
            </Grid>
            <Grid
              container
              direction="column"
              className={classes.formItemContainer}
            >
              <label htmlFor="input-lastName" className={classes.label}>
                Primer apellido
              </label>
              <input
                type="text"
                value={user?.firstLastName}
                className={classes.input}
                id="input-lastName"
                disabled
                readOnly
              />
            </Grid>
            <Grid
              container
              direction="column"
              className={classes.formItemContainer}
            >
              <label htmlFor="input-secondLastName" className={classes.label}>
                Segundo apellido
              </label>
              <input
                type="text"
                value={user?.secondLastName}
                className={classes.input}
                id="input-secondLastName"
                disabled
                readOnly
              />
            </Grid>

            <Grid
              container
              direction="column"
              className={classes.formItemContainer}
            >
              <label htmlFor="input-email" className={classes.label}>
                Correo electrónico
              </label>
              <input
                type="email"
                placeholder="email"
                value={user?.email || user?.correo}
                className={classes.input}
                id="input-email"
                disabled
                readOnly
              />
            </Grid>
            <Grid
              container
              direction="column"
              className={classes.formItemContainer}
            >
              <label htmlFor="input-username" className={classes.label}>
                Usuario
              </label>
              <input
                type="text"
                value={user?.userName}
                className={classes.input}
                id="input-username"
                disabled
                readOnly
              />
            </Grid>

            <button className={classes.PurpleButton} onClick={handleModalOpen}>
              Cambiar contraseña
            </button>
            <ModalPasswordChange
              open={modalChangePasswordOpen}
              handleClose={handleModalOpen}
            />
          </Grid>
        </Grid>
      </Grid>
    </ContainerAdminPearson>
  );
};

export default PerfilAdmin;
