import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
    ContenedorLectura:{
        width:'502px',
                  [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
    },
    ContainerTitulo:{
        margin:'0 0 34px 0',
        '& .Titulo':{
            letterSpacing: '0px',
            color: '#926DD1',
            margin:'0',
            textAlign:'center',
            font:'43px fredoka one',
            lineHeight:'52px',
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            margin:'0 0 36px 0',
            color:'#000',
            font:'18px lato',
            lineHeight :'28px',
            letterSpacing: '0.17px',
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .TextImg':{
            color:'#717476',
            margin:'24px 0 0 0',
            font:' 16px lato',
            lineHeight :'25px',
            letterSpacing: '0.15px',
            [theme.breakpoints.down(502)]:{
                font:' 11px lato',
                lineHeight :'14px',
                letterSpacing: '0.10px',
            },
        },
        '& .Imagen':{
            width:'100%',
        },  
    }
}));

const Leche_gatito_gatito = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >
                    Leche, gatito, gatito
                    </h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                      <p className="Lectura">
                        Los mamíferos como los perros, las vacas, los gatos y los seres humanos se 
                        caracterizan por alimentar a sus crías con un líquido rico en proteínas, grasa y 
                        azúcares. Este líquido es muy importante, ya que les brinda todos los nutrimentos 
                        para crecer en las primeras etapas de su vida. ¿Sabes qué es? ¡Claro! ¡Es la leche!
                      </p>
                    </Grid>
                    <Grid container item justifyContent="center" sm={12}>
                        <Grid container justifyContent="center" >
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img1ro/Sesion54/1.png" alt="Tarjeta" />
                        </Grid>
                        <Grid container item justifyContent="center" >
                            <p className="TextImg">
                            La leche es un alimento nutritivo y esencial para los pequeños mamíferos.
                            </p>
                        </Grid>
                    </Grid>
                </Grid>
            
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                      <p className="Lectura">
                        La mayor parte de los mamíferos pierde la capacidad para digerir los azúcares de la 
                        leche cuando son adultos. Un caso extraño son los seres humanos, pues 
                        aproximadamente la mitad de las personas pueden seguir digiriendo la leche aún 
                        después de haber dejado de ser niños.<br/><br/>
                        
                        La <em>lactosa</em> es la principal azúcar de la leche. Para digerirla, el cuerpo humano tiene 
                        que descomponerla en dos azúcares simples: la glucosa y la galactosa. Pero muchos 
                        adultos y algunos niños y adolescentes son incapaces de llevar a cabo este proceso.<br/><br/> 
                        
                        Cuando el intestino no puede digerir y absorber la lactosa, las bacterias que se
                        encuentran en él la fermentan y éste se inflama, lo cual causa malestar y diarrea. A 
                        esto se le llama <em>intolerancia a la lactosa</em> y puede presentarse en todas las edades.
                      </p>
                    </Grid>
                </Grid>
        </Grid>
    </Grid>
    );
}
 
export default Leche_gatito_gatito ;
