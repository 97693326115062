import React, { useCallback } from "react";
import { makeStyles, Grid, Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ReporteContainer from "./Reporte_Container";
import axios from "../../../../services/axios";
import AccordionStudents from '../../../filters/AccordionStudents'
import TablaSocioEmocional from "../../../Charts/TablaSocioEmocional";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";

const useStyles = makeStyles((theme) => ({
  Container: {
    padding: "48px 5% 48px 5%",
    background:
      "linear-gradient(217deg, rgba(255,194,0,0.4962185557816877) 2%, rgba(255,255,255,1) 25%, rgba(255,255,255,0.4962185557816877) 81%, rgba(144,81,177,1) 98%)",
    minHeight: "calc(100vh - 80px)",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    display: "grid",
    position: "relative",
    gap: "20px",
    gridTemplateColumns: "1fr",
    gridTemplateAreas: (props) => (props.showFilter ? `"filter"` : `"data"`),
    [theme.breakpoints.up("md")]: {
      gridTemplateAreas: (props) =>
        props.showFilter ? `"filter data"` : `"filter data"`,
      gridTemplateColumns: (props) =>
        props.showFilter ? `250px 1fr` : `100px 1fr`,
    },
  },
  ContainerReportes: {
    background: "#fff",
    width: "100%",
    minHeight: "70vh",
    borderRadius: "42px",
    gridArea: "data",
    padding: "30px",
    boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.5)",
  },
  ContainerSerchBar: {
    gap: "10%",
    padding: "10px 0",
    justifyContent: "space-between",
  },
  SerchBar: {
    outline: "none",
    width: "100%",
    borderRadius: "36px",
    padding: "5px 32px",
    border: "4px solid #EA9D90",
    textAlign: "left",
    letterSpacing: "0.63px",
    color: "#7A54BA",
    font: "600 20px lato",
    // margin: " 0 10%",
    [theme.breakpoints.up("lg")]: {
      height: "auto",
      padding: "10px 32px",
    },
  },
  SerchBarIcon: {
    right: "23px",
    // top: "25px",
    position: "absolute",
    fontSize: "20px",
    color: "#7A54BA",
  },
  titleContainer: {
    margin: "20px 0",
    [theme.breakpoints.up("lg")]: {
      margin: "10px 0",
    },
  },
  reading: {
    width: "60%",
    margin: "0px ",
    font: "18px lato",
    letterSpacing: "0.21px",
    [theme.breakpoints.down(1400)]: {
      font: "14px lato",
    },
    [theme.breakpoints.down(650)]: {
      font: "10px lato",
    },
  },
  title: {
    // margin: "0 0 84px 34px",
    font: "22px fredoka one",
    textAlign: "center",
    [theme.breakpoints.up("xl")]: {
      font: "26px fredoka one",
    },
  },
  PurpleButton: {
    color: "#FFFFFF",
    backgroundColor: "#9051b1",
    borderRadius: "30px",
    // margin: "0 auto",
    // width: "100%",
    minWidth: "75px",
    maxWidth: "238px",
    // minHeight: "45px",
    border: "none",
    textTransform: "unset",
    cursor: "pointer",
    fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
    fontWeight: "500",
    padding: "4px 14px",
    lineHeight: "1.5",
    transition:
      "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    boxShadow:
      "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
    "&:hover": {
      backgroundColor: "#B965D3 ",
    },
  },
  WitheButton: {
    color: "#9051b1",
    // borderColor: "#9051b1",
    border: "1px solid #9051b1",
    backgroundColor: "#FFFFFF",
    borderRadius: "30px",
    // margin: "0 auto",
    // width: "100%",
    minWidth: "75px",
    maxWidth: "238px",
    // minHeight: "45px",
    textTransform: "unset",
    cursor: "pointer",
    fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
    fontWeight: "500",
    padding: "4px 14px",
    lineHeight: "1.5",
    transition:
      "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    boxShadow:
      "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
    [theme.breakpoints.up("lg")]: {
      minWidth: "100px",
    },

    "&:hover": {
      backgroundColor: "#B965D330 ",
    },
  },
  link: {
    cursor: "pointer",
    textDecoration: "none",
  },
  buttonToggle: {
    color: "#9051b1",
    width: "auto",
    textTransform: "unset",
  },
  buttonToggleXs: {
    width: "auto",
    textTransform: "unset",
    color: "#9051b1",
    fontSize: "18px",
    fontFamily: "fredoka one",
    [theme.breakpoints.up("md")]: {
      display: "none",
      transform: "rotation",
    },
  },
  buttonToggleIcon: {
    transform: "rotate(90deg)",

    [theme.breakpoints.up("md")]: {
      transform: (props) =>
        props.showFilter ? `rotate(0deg)` : `rotate(180deg)`,
      fontSize: "36px",
    },
  },
  buttonToggleXsIcon: {
    transform: "rotate(270deg)",
  },

  ContainerFiltro: {
    zIndex: 2,
    display: (props) => (props.showFilter ? `flex` : `none`),
    width: "100%",
    alignItems: "center",
    gap: "10px",
    boxSizing: "border-box",
    gridArea: "filter",
    justifyContent: "space-between",
    paddingBottom: "20px",
    overflowY: "auto",
    overflowX: "hidden",
    position: (props) => (props.showFilter ? `fixed` : "static"),
    top: "80px",
    bottom: "0",
    right: "0",
    left: "0",
    background:
      "linear-gradient(217deg, rgba(255,194,0,1) 2%, rgba(255,255,255,1) 25%, rgba(255,255,255,1) 81%, rgba(144,81,177,1) 98%)",
    [theme.breakpoints.up("md")]: {
      position: () => "sticky",
      background: "transparent",
    },
  },
  ContainerFiltroUp: {
    gap: "10px",
    padding: "20px",
    [theme.breakpoints.up("md")]: {
      padding: "0px",
    },
  },
  containerFiltroTitle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  filtroTitle: {
    font: "22px fredoka one",
    textAlign: "center",
    width: "auto",
    margin: "0",
    [theme.breakpoints.up("sm")]: {
      width: "auto",
    },
    [theme.breakpoints.up("md")]: {
      font: "28px fredoka one",
      width: "auto",
    },
  },
  ContainerAlumnosInfo: {
    display: "flex",
    width: "100%",
    gap: "10px",
  },
  ContainerInput: {
    position: "relative",
    width: "250px",
  },
  mainContainer: {
    padding: "48px 0 48px 0",
    background:
      "linear-gradient(217deg, rgba(255,194,0,0.4962185557816877) 2%, rgba(255,255,255,1) 25%, rgba(255,255,255,0.4962185557816877) 81%, rgba(144,81,177,1) 98%)",
    minHeight: "92vh",
  },
  boxContainer: {
    background: "#fff",
    width: "80%",
    maxWidth: "1216px",
    minHeight: "70vh",
    height: "auto",
    borderRadius: "42px",
    padding: "20px",
    gap: "20px",
    boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.5)",
    [theme.breakpoints.up("md")]: {
      padding: "48px 20px 48px 20px",
      gap: "0px",
    },
  },
  backButtonContainer: {
    position: "relative",
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    paddingRight: "30px",
    [theme.breakpoints.up("sm")]: {
      justifyContent: "flex-start",
    },
    [theme.breakpoints.down(768)]: {
      paddingRight: "20px",
    },
  },
  backButton: {
    cursor: "pointer",
    height: "35px",
    backgroundColor: "#FDEFF7",
    color: "#DC5EA6",
    border: "#DC5EA6 1px solid",
    borderRadius: "20px",
    textTransform: "none",
    font: "20px Lato",
    letterSpacing: "0.14px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
  },
  ContainerLinkinsiginas: {
    textDecoration: "none",
    marginLeft: "44px",
    marginBottom: "40px",
    "& .ButtonIsg": {
      color: "blue",
      textTransform: "none",
      font: " 22px lato",
      letterSpacing: "0.36px",
      [theme.breakpoints.down(425)]: {
        font: " 16px lato",
      },
    },
    "& p": {
      textAlign: "start",
    },
    "& .icon": {
      color: "purple",
      marginRight: "20px",
      fontSize: "50px",
      [theme.breakpoints.down(425)]: {
        fontSize: "30px",
        marginRight: "0px",
      },
    },
  },

  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },

}));

const Reporte8 = ({ location }) => {
  const [showFilter, setShowFilter] = React.useState(true);
  const classes = useStyles({ showFilter });

  const [studentsList, setStudentsList] = React.useState([]);
  const [userIdList, setUserIdList] = React.useState([0]);
  const [downloadData, setDownloadData] = React.useState("");
  const [usersGraph8, setUsersGraph8] = React.useState("");
  const [studentsGraphList, setStudentsGraphList] = React.useState([])
  const { idDistribEsc } = location?.state;


  const fullList = useCallback(() => {
    axios
    .get(`/listado/reporte/8?id_distribucion_escuela=${idDistribEsc}`)
    .then((response) => {
      if (response.status === 200) {
        setUsersGraph8(response.data.resultado.Alumnos);
        setStudentsGraphList(response.data.resultado.Alumnos);
      }
    })
    .catch((error) => {});
  }, [idDistribEsc]);

  React.useEffect(() => {
    fullList();
  }, [userIdList, fullList]);

  React.useEffect(() => {
    axios
      .get(`/listado/getReport/8?id_distribucion_escuela=${idDistribEsc}`)
      .then((response) => {
        if (response.status === 200) {
          setDownloadData(response.data.resultado.path);
        }
      })
      .catch((error) => {
        console.log(error, "dwnldF");
      });
  }, [idDistribEsc]);

  React.useEffect(() => {
    const body = {
      id_distribucion_escuela: idDistribEsc,
    };
    axios
      .post(`/listado/alumnos`, body)
      .then((response) => {
        if (response.status === 200) {
          setStudentsList(response.data.resultado.alumnos);
          setUserIdList(
            response.data.resultado.alumnos.map((student) => {
              return student.id_usuario;
            })
          );
        }
      })
      .catch((error) => {
        console.log(error, "dwnldF");
      });
  }, [idDistribEsc]);

  const handleToggle = () => {
    setShowFilter(!showFilter);
  };

  return (
    <>
    <ReporteContainer>
    <Grid
      container
      item
      sm={12}
      className={classes.Container}
      alignItems="center"
      justifyContent="center"
    >
      <Grid
        container
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Button className={classes.buttonToggle} onClick={handleToggle}>
          {showFilter ? "Buscar por filtro" : "Filtrar"}
          <ArrowRightIcon className={classes.buttonToggleIcon} />
        </Button>
      <Grid
        container
        item
        direction="column"
        className={classes.ContainerFiltro}
      >
        <Grid
          container
          item
          direction="column"
          className={classes.ContainerFiltroUp}
        >
          <Grid
            container
            item
            direction="row"
            className={classes.containerFiltroTitle}
          >
            <Button
              className={classes.buttonToggleXs}
              onClick={handleToggle}
            >
              {showFilter ? "Retraer" : "Ampliar"}
              <ArrowRightIcon className={classes.buttonToggleXsIcon} />
            </Button>
          </Grid>
          <Grid
            item
            direction="column"
            className={classes.ContainerAlumnosInfo}
          >
            <Grid>
              <Button onClick={fullList}>
                Limpiar todos los filtros
              </Button>
            </Grid>
            {
            studentsList.length &&
              <AccordionStudents
                studentsList={usersGraph8}
                setStudentsList={setUsersGraph8}
                studentsGraphList={studentsGraphList}
                setStudentsGraphList={setStudentsGraphList}
                // studentsList={usersGraph8}
                // setStudentsList={setUsersGraph8}
              />
            }
          </Grid>
        </Grid>
      </Grid>

      </Grid>
      <Grid
        container
        item
        direction="column"
        className={classes.ContainerReportes}
      >
        <Grid container alignItems="center" justifyContent="space-between" className={classes.backButtonContainer}>
          <Link 
            className={classes.link} 
            to={{
              pathname: "/admin-inst/reportes",
              state: {
                id_distribucion_escuela: idDistribEsc,
              },
            }}
            >
            <button className={classes.backButton}>
              <ArrowBackIcon /> Reportes
            </button>
          </Link>
        </Grid>
        <Grid
              container
              item
              direction="column"
              alignItems="center"
              justifyContent="center"
              className={classes.titleContainer}
            >
              <h2 className={classes.title}>
                Reporte 8: Seguimiento socioemocional
              </h2>
              <p className={classes.reading}>
                Este reporte te mostrará las emociones que los estudiantes
                seleccionen en los dos momentos en los cuales aparece el
                termómetro de emociones: al iniciar cada sesión y al concluir la
                práctica guiada correspondiente.
              </p>
            </Grid>

        <Grid container item direction="row" alignItems="center">
            {usersGraph8.length && downloadData && (
              <TablaSocioEmocional
                usersGraph8={studentsGraphList}
                downloadData={downloadData}
              />
            )}
          </Grid>

      </Grid>
    </Grid>
  </ReporteContainer>
</>
  );
};

export default Reporte8;
