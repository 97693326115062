import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
    ContenedorLectura:{
        width:'502px',
                  [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
    },
    ContainerTitulo:{
        margin:'0 0 34px 0',
        '& .Titulo':{
            color: '#FF2B2B',
            margin:'0 0 5px 0',
            textAlign:'center',
            font:'43px fredoka one',
            lineHeight:'52px',
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
        '& .Titulo2':{
            color: '#FF2B2B',
            margin:'0',
            textAlign:'center',
            font:'bold 26px lato',
            letterSpacing: '.24px',
            lineHeight:'28px',
            [theme.breakpoints.down(502)]:{
                font:'bold 22px lato',
                lineHeight:'24px',
            },
            [theme.breakpoints.down(350)]:{
                font:'bold 18px lato',
                lineHeight:'24px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        position:'relative',
        '& .Lectura':{
            margin:'0 0 36px 0',
            color:'#000',
            font:'18px lato',
            lineHeight :'28px',
            letterSpacing: '0.17px',
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .Imagen':{
            width:'100%',
        },  
      
    }
}));

const Los_viajes_de_Maqui_Cap4 = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>

            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                        <Grid container justifyContent="center" sm={12}>
                            <h2 className="Titulo">Los viajes de Maqui</h2>
                        </Grid>
                        <h3 className='Titulo2'>Fragmento 4 (capítulo 4)</h3>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                       <p className='Lectura'>
                        Recorremos el parque con tranquilidad cuando me encuentro con el jardinero que corta el pasto. Después de saludarme, señala que la temperatura se eleva, a pesar de la lluvia. Luego, me explica que por el clima no habían podado el parque en toda la semana: 
                        <br/><br/>
                        —Parece que estamos en medio del Amazonas.
                        <br/><br/>
                        Maqui para la oreja y emite dos ladridos intermitentes. “Eso es, me trajiste a la selva amazónica”, me parece que dice. De pronto, tira de la correa con su patita y se zafa del arnés. En el interior de la correa hay un botón que no había notado. Tiene la misma forma que el bolígrafo láser.
                        <br/><br/>
                        Corro hasta Maqui y pongo mi mano en su espaldita. Veo cómo presiona el botón y enseguida las plantas y el pasto crecen. La maleza es tan grande ahora que no alcanzo a distinguir el horizonte: sólo veo tonos y tonos de verde. Y como es pleno verano y hay muchas lluvias, los insectos se reproducen con mayor rapidez.
                        <br/><br/>
                        —En el Amazonas hay tantos animales como en ningún otro lugar del mundo —me dice.
                        <br/><br/>
                        A lo lejos, se observan los animales de piedra. Maqui se sorprende muchísimo al ver al enorme elefante de color gris bañándose.
                        <br/><br/>
                        —¡Qué raro! Pensé que los elefantes sólo vivían en África, tal vez me equivoqué. Quiero verlos más de cerca.
                        <br/><br/>
                        —Espera, hay mucho fango y lodo. Te vas a ensuciar. —Maqui comienza a correr en dirección a los animales y yo la sigo por temor a extraviarla.
                        <br/><br/>
                        A mitad del camino, aparecen unas máquinas enormes con cuchillos, palas y dientes. Se acercan con ferocidad a los árboles y las plantas. Maqui se voltea y me señala que está a punto de comenzar una deforestación. Por su determinación, imagino que no lo va a permitir.
                        <br/><br/>
                        Como en esta ocasión Laila no nos acompaña, los aliados naturales de Maqui resultan ser las mariposas, las moscas, las lagartijas, las hormigas y algunos pájaros. Después de solicitar su apoyo en el lenguaje del reino animal, los animales del parque se reúnen en torno a Maqui.
                        <br/><br/>
                        —Cachorro, ¿qué ocurre? —preguntaron las mariposas.
                        <br/><br/>
                        —El perrito dice que los leñadores talarán los árboles y nos quedaremos sin hogar <br/>—contestaron las hormigas.
                        <br/><br/>
                        Todos estaban de acuerdo en defender sus tierras, así que idearon un plan. Los pájaros atacaron las podadoras, las moscas le cerraron el paso y los perros mordieron las llantas de los vehículos. Las máquinas persisten en cortar grandes ceibas, pero Maqui brinca y en el aire emite un ladrido supersónico que las hace volar como si fueran cascaritas de naranja al aire libre.
                        <br/><br/>
                        Los animales celebran y no nos damos cuenta de que Maqui está a punto de aterrizar en un banco de fango. Cuando cae, recuerdo que mi perrita no sabe nadar y corro por ella.
                        <br/><br/>
                        —¡Arenas movedizas! —Maqui grita con el cuerpo cubierto de lodo.
                        <br/><br/>
                        Extiendo mis brazos y alcanzo su patita derecha. Jalo con cuidado para no lastimarla. Ella se sumerge cada vez más. Le pido que no se mueva. Los pájaros y todos los insectos voladores acuden a ayudarme. La toman por las orejas y mueven sus alas hasta que Maqui deja por completo el banco.
                        <br/><br/>
                        —Gracias, amigos. Hemos salvado el Amazonas.
                       </p>                   
                    </Grid>
                    <Grid container justifyContent='center' >
                        <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img4to/Sesion29/1.png" alt="Tarjeta" />
                    </Grid>
                </Grid>
        </Grid>
    </Grid>
    );
}
 
export default Los_viajes_de_Maqui_Cap4;
