import { Button, Grid, makeStyles } from "@material-ui/core";
import React, { useContext, useEffect } from "react";
import DrawerContext from "../../../../../usability/DrawerContext";
import { useOnLoadImages } from "../../../../hooks/useOnLoadImages";
import Loader from "../../../../Loader/Loader";
import Drawer from "../../ReadingTools/Drawer";



const useStyles =  makeStyles (theme =>({
     Contenedor:{
        background:'#E9FBF9',
        width:'100vw',
        overflowX:'clip',
        [theme.breakpoints.down(1161)]:{
            background:'none',
           overflowX:'unset',
        },
        [theme.breakpoints.down(600)]:{
            marginTop: '79.51px',
        }
    },
  ContainerInfoLectura: {
    position: "relative",
    height: "86px",
    [theme.breakpoints.down(502)]:{
        height: "43px",
    },
    background: "#76D4C3",
    "& .BarraInfoLectura": {
      height: "86px",
      background: `Url(https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Barras/Azul.png)`,
        [theme.breakpoints.down(502)]:{
            height: "43px",
        },
    },
    "& .InfoLectura": {
      position: "absolute",
      height: "86px",
      width: "219px",
      boxSizing: "border-box",
      padding: "17.2px 0",
      background: "#FAFAFA",
      borderRadius: "50px",
      boxShadow: "2px 2px 2px 2px #00000029",
      zIndex: "4",
        [theme.breakpoints.down(502)]:{
            height: "43px",
            width: "109px",
            padding: "8.6px 0",
            borderRadius: "25px",
        },
    },
    "& .InfoLectura:before": {
      position: "absolute",
      display: "flex",
      boxSizing: "border-box",
      padding: "0 0 0 10px",
      justifyContent: "center",
      alignItems: "center",
      height: "39.88px",
      width: "39.88px",
      borderRadius: "50%",
      content: '""',
      boxShadow: "3px 0px .5px 0px #00000029",
      background: "#FAFAFA",
      left: "90px",
      top: "65px",
      transform: "rotate(0.25turn)",
      [theme.breakpoints.down(502)]:{
        left: "45px",
        top: "30px",
        height: "20.88px",
        width: "20.88px",
        },
    },
    "& .InfoLectura:after": {
      content: '""',
      height: "15px",
      width: "15px",
      borderTop: "2px solid #20A698",
      borderLeft: "2px solid #20A698",
      position: "absolute",
      bottom: "-10px",
      left: "Calc(50% - 8px)",
      transform: "rotate(0.625turn)",
      [theme.breakpoints.down(502)]:{
        height: "10px",
        width: "10px",
        left: "Calc(50% - 4.5px)",
        bottom: "-3px",
        borderTop: "1px solid #20A698",
        borderLeft: "1px solid #20A698",
        },
    },
    "& .Sesion": {
      borderLeft: "2px solid #D8D8D8",
    },
    "& .Dato": {
      margin: "0 0 5px 0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#20A698",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        margin: "0 0 2.5px 0",
        },
    },
    "& .Caracter": {
      margin: "0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#000000",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        },
    },
       '& .Regresar':{
        color: '#F2624F',
        border: '#F2624F 1px solid',
        height: '30px',
        width:'100px',
        borderRadius: '20px',
        letterSpacing: '0.14px',
        textTransform: 'none',
        background:'#FEFAF1',
        top:'calc(50% - 15px)',
        left:'5%',
        zIndex:4,
        [theme.breakpoints.down(502)]: {
          font:'bold 10px lato',
          width: "60px",
        },
      },
  },
    ContenedorLectura:{
        background:'#fff',
        width:'1160px',
        height:'100%',
        padding:'0',
        
        transform: (props) =>
        props.scale > 1 ? `scale(${props.scale}) translate(0px, 10%)` : `none`,
        [theme.breakpoints.down(1161)]:{
            width:'768px',
          },
          [theme.breakpoints.down(768)]:{
            width:'350px',
          },

        '& .Escritorio':{
            display:'block',
            [theme.breakpoints.down(1161)]:{
                display:'none'
            },
        },
        '& .Tablet':{
            display:'none',
            [theme.breakpoints.down(1161)]:{
                display:'block'
            },
        }
    },
    Parrafo1:{
        position:'relative',
        '& .Titulo':{
            font:'72px fredoka one',
            letterSpacing: '0px',
            lineHeight:'58px',
            color: '#FFFFFF',
            margin:'0',
            top:'0.50%',
            textAlign:'center',
            [theme.breakpoints.down(1161)]:{
                font:'47px fredoka one',
                lineHeight:'53px',
            },
            [theme.breakpoints.down(768)]:{
                font:'18px fredoka one',
                lineHeight:'32px',
            },
        },
        '& .Titulo2':{
            font:'85px',
            lineHeight:'85px',
            margin:'0',
            [theme.breakpoints.down(1161)]:{
                font:'56px',
                lineHeight:'53px',
            },
            [theme.breakpoints.down(768)]:{
                font:'23px',
                lineHeight:'13px',
            },
        },
        '& .Lectura':{
            lineHeight : '30px',
            margin:'0 ',
            font:'22px lato',
            color:'#000',
            letterSpacing: '0.20px',
            [theme.breakpoints.down(1161)]:{
                font:'18px lato',
                lineHeight :'28px',
                letterSpacing: '0.17px',
            },
            [theme.breakpoints.down(768)]:{
                font:'10px lato',
                lineHeight :'10px',
                letterSpacing: '0.09px',
            },
        },
        '& .Pos':{
            position:'absolute',
        },
        '& .Tipo1':{
            width:'738px',
            [theme.breakpoints.down(1161)]:{
                width:'64.04%',
            },
        },
        '& .Tipo2':{
            width:'795px',
            [theme.breakpoints.down(1161)]:{
                width:'69.10%',
            },
        },
        '& .Tipo3':{
            width:'701px',
            left:'280px',
            [theme.breakpoints.down(1161)]:{
                width:'60.02%',
                left:'24.70%',
            },
            
        },
        '& .Lectura1':{
            top:'4.47%',
            [theme.breakpoints.down(1161)]:{
                top:'3.47%',
            },
        },
        '& .Lectura2':{
            top:'9.44%',
            [theme.breakpoints.down(1161)]:{
                top:'8.94%',
            },
        },
        '& .Lectura3':{
            top:'27.42%',
            [theme.breakpoints.down(1161)]:{
                top:'23.92%',
            },
        },
        '& .Lectura4':{
            top:'44.40%',
            [theme.breakpoints.down(1161)]:{
                top:'38.80%',
            },
        },
        '& .Lectura5':{
            top:'50.1%',
            [theme.breakpoints.down(1161)]:{
                top:'44.2%',
            },
        },
        '& .Lectura6':{
            top:'53.83%',
            [theme.breakpoints.down(1161)]:{
                top:'48.6%',
            },
        },
        '& .Lectura7':{
            top:'59.55%',
            [theme.breakpoints.down(1161)]:{
                top:'55.6%',
            },
        },
        '& .Lectura8':{
            top:'64.77%',
            [theme.breakpoints.down(1161)]:{
                top:'61.6%',
            },
        },
        '& .Lectura9':{
            top:'69.07%',
            [theme.breakpoints.down(1161)]:{
                top:'65.9%',
            },
        },
        '& .Lectura10':{
            top:'72.80%',
            [theme.breakpoints.down(1161)]:{
                top:'70.1%',
            },
        },
        '& .Lectura11':{
            top:'77.92%',
            [theme.breakpoints.down(1161)]:{
                top:'76.2%',
            },
        },
        '& .Lectura12':{
            top:'81.83%',
            [theme.breakpoints.down(1161)]:{
                top:'80.7%',
            },
        },
        '& .Lectura13':{
            top:'85.89%',
            [theme.breakpoints.down(1161)]:{
                top:'84.9%',
            },
        },
        '& .Lectura14':{
            top:'89.60%',
            [theme.breakpoints.down(1161)]:{
                top:'89.30%',
            },
        },
        '& .Lectura15':{
            top:'92.69%',
            [theme.breakpoints.down(1161)]:{
                top:'92.69%',
            },
        },
        '& .Lectura16':{
            top:'97%',
            [theme.breakpoints.down(1161)]:{
                top:'97%',
            },
        },
        '& .SubTitulo':{
            font:'43px Fredoka One',
            color:'#FFFFFF',
            lineHeight:'52px',
            margin:'0',
            [theme.breakpoints.down(1161)]:{
                font:'28px Fredoka One',
                lineHeight :'34px',
            },
            [theme.breakpoints.down(768)]:{
                font:'13px Fredoka One',
                lineHeight :'16px',
                letterSpacing: '0.21px',
            },
        },
        '& .Actividad1':{
            top:'8.2%',
            [theme.breakpoints.down(1161)]:{
                top:'7.90%',
            },
        },
        '& .Actividad2':{
            top:'26.10%',
            [theme.breakpoints.down(1161)]:{
                top:'22.85%',
            },
        },
        '& .Actividad3':{
            top:'43%',
            [theme.breakpoints.down(1161)]:{
                top:'37.70%',
            },
        },

        '& .SubTitulo2':{
            font:'bold 26px lato',
            color:'#000000',
            letterSpacing:'.24px',
            lineHeight:'35px',
            margin:'0',
            top:'2810px',
            [theme.breakpoints.down(1161)]:{
                font:'bold 18px lato',
                lineHeight :'28px',
                letterSpacing: '0.17px',
                top:'41%',
            },
            [theme.breakpoints.down(768)]:{
                font:'bold 8.5px lato',
                lineHeight :'13px',
                letterSpacing: '0.10px',
            },
        },
        '& .SubTitulo3':{
            font:'35px Fredoka One',
            color:'#1DA698',
            letterSpacing:'.33px',
            lineHeight:'30px',
            margin:'0',
            [theme.breakpoints.down(1161)]:{
                font:'25px Fredoka One',
                lineHeight :'30px',
                letterSpacing: '0.23px',
            },
            [theme.breakpoints.down(768)]:{
                font:'18px Fredoka One',
                lineHeight :'20px',
                letterSpacing: '0.16px',
            },
        },
        '& .Niños':{
            font:'35px Fredoka One',
            color:'#000000',
            letterSpacing:'.33px',
            lineHeight:'30px',
            margin:'0',
            [theme.breakpoints.down(1161)]:{
                font:'25px Fredoka One',
                lineHeight :'30px',
                letterSpacing: '0.23px',
            },
            [theme.breakpoints.down(768)]:{
                font:'14px Fredoka One',
                lineHeight :'20px',
                letterSpacing: '0.16px',
            },
        },
        '& .Carlos1':{
            top:'2950px',
            left:'285px',
            [theme.breakpoints.down(1161)]:{
                top:'2220px',
                left:'185px',
            },
            [theme.breakpoints.down(768)]:{
                top:'1010px',
                left:'90px',
            },
        },
        '& .Carlos2':{
            top:'4105px',
            left:'401px',
            [theme.breakpoints.down(1161)]:{
                top:'3330px',
                left:'253px',
            },
            [theme.breakpoints.down(768)]:{
                top:'1515px',
                left:'120px',
            },

        },
        '& .Reina1':{
            top:'3180px',
            left:'401px',
            [theme.breakpoints.down(1161)]:{
                top:'2445px',
                left:'253px',
            },
            [theme.breakpoints.down(768)]:{
                top:'1110px',
                left:'120px',
            },
            

        },
        '& .Reina2':{
            top:'3835px',
            left:'401px',
            [theme.breakpoints.down(1161)]:{
                top:'3110px',
                left:'253px',
            },
            [theme.breakpoints.down(768)]:{
                top:'1415px',
                left:'120px',
            },

        },
        '& .Marcos1':{
            top:'3530px',
            left:'401px',
            [theme.breakpoints.down(1161)]:{
                top:'2805px',
                left:'253px',
            },
            [theme.breakpoints.down(768)]:{
                top:'1275px',
                left:'120px',
            },

        },
        '& .Marcos2':{
            top:'5535px',
            left:'401px',
            [theme.breakpoints.down(1161)]:{
                top:'4700px',
                left:'253px',
            },
            [theme.breakpoints.down(768)]:{
                top:'2138px',
                left:'120px',
            },

        },
        '& .Andrea1':{
            top:'4330px',
            left:'285px',
            [theme.breakpoints.down(1161)]:{
                top:'3545px',
                left:'185px',
            },
            [theme.breakpoints.down(768)]:{
                top:'1611px',
                left:'90px',
            },
        },
        '& .Andrea2':{
            top:'4875px',
            left:'401px',
            [theme.breakpoints.down(1161)]:{
                top:'4085px',
                left:'253px',
            },
            [theme.breakpoints.down(768)]:{
                top:'1858px',
                left:'120px',
            },
        },
        '& .Andrea3':{
            top:'5350px',
            left:'401px',
            [theme.breakpoints.down(1161)]:{
                top:'4525px',
                left:'253px',
            },
            [theme.breakpoints.down(768)]:{
                top:'2058px',
                left:'120px',
            },
        },
        '& .Pilar1':{
            top:'4645px',
            left:'401px',
            [theme.breakpoints.down(1161)]:{
                top:'3855px',
                left:'253px',
            },
            [theme.breakpoints.down(768)]:{
                top:'1754px',
                left:'120px',
            },
        },
        '& .Pilar2':{
            top:'5125px',
            left:'401px',
            [theme.breakpoints.down(1161)]:{
                top:'4300px',
                left:'253px',
            },
            [theme.breakpoints.down(768)]:{
                top:'1955px',
                left:'120px',
            },
        },
        '& .Pilar3':{
            top:'5790px',
            left:'401px',
            [theme.breakpoints.down(1161)]:{
                top:'4920px',
                left:'253px',
            },
            [theme.breakpoints.down(768)]:{
                top:'2238px',
                left:'120px',
            },
        },
        '& .Imagen':{
            [theme.breakpoints.down(1161)]:{
                width:'100%',
            },
        },  
      
    }
}));

const Un_fin_de_semana_divertido_casi_gratis = (props) => {

  const {activeStep, setActiveStep, handlePreviewStep, id_actividad} = props
  
  const handleContinue = () => {
    setActiveStep(activeStep - 1)
    handlePreviewStep()
  }

    const {
      handleSize,
    disabledZoom,
    closeZoom,
    handleZoom,
    scale
  }= useContext(DrawerContext);
  const [loading, setLoading] = React.useState(true);
  const wrapperRef = React.useRef(null);
  const imagesLoaded = useOnLoadImages(wrapperRef);

  const detectSize = () => {
    const lectura = document.getElementById("lectura");
    const newWidth = lectura?.offsetWidth;
    const newHeight = lectura?.offsetHeight;
    setLoading(false);
    if (lectura) handleSize(newHeight, newWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", detectSize);
    return () => {
      handleZoom()
      window.removeEventListener("resize", detectSize);
      closeZoom()
    };
  }, []);

  const classes = useStyles({ disabledZoom,scale });
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
             <Grid container item justifyContent="center" className={classes.ContainerInfoLectura}>
                       <Grid container className="BarraInfoLectura">
            <Button className="Regresar" onClick={handleContinue}>
                Regresar
            </Button>
        </Grid>
               <Grid container item className="InfoLectura">
                    <Grid container item direction="column" xs={6} justifyContent="center">
                        <p className="Dato">Grado</p>
                        <p className="Caracter">5</p>
                    </Grid>
                    <Grid container item direction="column" xs={6} justifyContent="center" className="Sesion">
                        <p className="Dato">Sesión</p>
                        <p className="Caracter">43</p>
                    </Grid>
               </Grid>
            </Grid>
            {!imagesLoaded && <Loader />}
        <Grid
            container
            item
            className={classes.ContenedorLectura}
            justifyContent="center"
            id="lectura"
            onLoad={detectSize}
            ref={wrapperRef}
        >
<Drawer id_lectura={id_actividad} />
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container justifyContent='center'>
                        <img className="Imagen Escritorio" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion43/1.png" alt="Tarjeta" />
                        <img className="Imagen Tablet" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion43/1R.png" alt="Tarjeta" />
                        <h2 className='Pos Titulo'>
                            Un fin de semana divertido, <br/> <h3 className='Titulo2'>¡casi gratis!</h3>
                        </h2>
                        <p className='Pos Lectura Lectura1 Tipo1'>
                            Hoy les hablaré a los adultos de familia. Como madre, sé que a veces llegamos cansados al fin de semana y nos gustaría quedarnos en cama viendo la televisión desde la noche del viernes hasta la madrugada del lunes. Pero es importante que pensemos en los niños. Ellos suelen aburrirse pronto de ese plan. Aquí les propondré algunas ideas para que pasen un rato agradable en familia gastando poco dinero.
                        </p>
                        <p className='Pos Lectura Lectura2 Tipo1'>
                            ¡Éste es un clásico! Empaca algunos bocadillos, una manta y dos o tres objetos para jugar. Disfruta una tarde al aire libre con los niños.                        
                        </p>
                        <p className='Pos Lectura Lectura3 Tipo1'>
                            Reta a los niños a un concurso: gana quien tome las mejores fotografías a lo largo del día. Acompaña esta actividad con un paseo por el pueblo, la ciudad o los alrededores. Finaliza el día proyectando las fotos en pantalla. ¡Disfruten y comenten la exposición!                   
                        </p>
                        <p className='Pos Lectura Lectura4 Tipo1'>
                            Reúnanse con otras familias. Organicen varias pistas, retos y acertijos ingeniosos que los lleven de un lugar a otro.                        
                        </p>
                        <h3 className='Pos SubTitulo Actividad1'>
                        Paseo por el parque
                        </h3>
                        <h3 className='Pos SubTitulo Actividad2'>
                        Concurso de fotografía
                        </h3>
                        <h3 className='Pos SubTitulo Actividad3'>
                        Rally
                        </h3>
                        <h4 className='Pos SubTitulo2'>¿Se les ocurre alguna otra idea? Comenten y compartan.</h4>

                        <p className='Pos Niños Carlos1'>Carlo Rgz</p>
                        <p className='Pos Niños Carlos2'>Carlo Rgz</p>
                        <p className='Pos Niños Reina1'>Reina Herrera</p>
                        <p className='Pos Niños Reina2'>Reina Herrera</p>
                        <p className='Pos Niños Marcos1'>Marcos G</p>
                        <p className='Pos Niños Marcos2'>Marcos G</p>
                        <p className='Pos Niños Andrea1'>Andrea González</p>
                        <p className='Pos Niños Andrea2'>Andrea González</p>
                        <p className='Pos Niños Andrea3'>Andrea González</p>
                        <p className='Pos Niños Pilar1'>Pilar PC</p>
                        <p className='Pos Niños Pilar2'>Pilar PC</p>
                        <p className='Pos Niños Pilar3'>Pilar PC</p>

                        



                        <p className='Pos Lectura Lectura5 Tipo2'>
                        ¡Excelente publicación! A veces gastamos de más y no la pasamos tan bien. 
                        ¿Me recomiendan algún parque cerca de Bogotá? Saludos.
                        </p>
                        <p className='Pos Lectura Lectura6 Tipo3'>
                            Hola, Carlo Rgz. A una hora de Bogotá se encuentra el Parque Natural Montañas de Chicaque: 10 km 
                            de senderos que puedes recorrer. ¡Hay vegetación en vías de extinción! Puedes acampar, montar a 
                            caballo, correr, hacer un picnic… Es un excelente lugar para el avistamiento de aves (¡tiene 
                            registradas 214 especie diferentes!). 🐦 Pero vayan descansados, porque la caminata es larga. 💪
                        </p>
                        <p className='Pos Lectura Lectura7 Tipo3'>
                            He vivido en Bogotá desde hace 20 años. Les recomiendo visitar el Embalse de Tominé. Está 
                            a menos de dos horas de camino y vale muchísimo la pena. Es un buen lugar para un campamento o 
                            picnic. Además, hay un lago de aguas verdes precioso donde se pueden hacer actividades 
                            acuáticas. ¡Pero vayan preparados para el frío! 🥶
                        </p>
                        <p className='Pos Lectura Lectura8 Tipo3'>
                            Tienes razón, Marcos G: ese lugar es bonito y hay muchas cosas por 
                            hacer. Cerca de ahí está la laguna de Guatavita. Se cree que es un lugar místico.
                        </p>
                        <p className='Pos Lectura Lectura9 Tipo3'>
                            Muchas gracias por las recomendaciones, Reina Herrera y Marcos G. 
                            Pronto me iré a vivir allá con mis dos hijos. Por eso pregunté.
                        </p>
                        <p className='Pos Lectura Lectura10 Tipo2'>
                            El año pasado intentamos hacer eso del <em>rally</em> para la fiesta 
                            de mi hija. Citamos a las familias de sus amigos. Fuimos a un parque 
                            grande que hay cerca de la casa. Dejamos algunas pistas en varios 
                            puestecitos y alrededor del lago. Las actividades fueron a pie y en 
                            bici. Todos se divirtieron. Al final sí gastamos un poco, pero 
                            porque comimos hamburguesas. 👨‍👩‍👧‍👦
                        </p>
                        <p className='Pos Lectura Lectura11 Tipo3'>
                            Hola, Andrea González. Me parece muy interesante tu experiencia. 
                            Quiero organizarle un festejo así a mi hijo. ¿Qué consejo puedes darme?
                        </p>
                        <p className='Pos Lectura Lectura12 Tipo3'>
                            Planifica con algo de tiempo los acertijos y pistas que darás. 
                            No tiene que ser algo tan elaborado. Utiliza refranes, adivinanzas y enigmas sencillos.
                        </p>
                        <p className='Pos Lectura Lectura13 Tipo3'>
                            ¡Gracias! A mis hijos les gustan los rompecabezas y los juegos como las 
                            sopas de letras. ¿Crees que se puedan integrar estas actividades al <em>rally</em>?
                        </p>
                        <p className='Pos Lectura Lectura14 Tipo3'>
                            ¡Por supuesto! Sólo procura que no sean muy largos.
                        </p>
                        <p className='Pos Lectura Lectura15 Tipo3'>
                            Hola, Pilar PC. Te recomiendo que busques páginas de internet con desafíos. Hay 
                            algunos acertijos para niños, y retos para que dibujen o hagan esculturas con 
                            plastilina.
                        </p>
                        <p className='Pos Lectura Lectura16 Tipo3'>
                            🙏 Es una excelente recomendación. ¡Los buscaré de inmediato!
                        </p>
                        
                    </Grid>
                </Grid>
        </Grid>
        <Grid container item style={{ marginTop: 80}} ></Grid>

    </Grid>
    );
}
 
export default Un_fin_de_semana_divertido_casi_gratis;
