import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
     Contenedor:{
        background:'#E9FBF9',
        width:'100vw',
        overflowX:'clip',
        [theme.breakpoints.down(1161)]:{
            background:'none',
           overflowX:'unset',
        },
        [theme.breakpoints.down(600)]:{
            marginTop: '79.51px',
        }
    },
  ContainerInfoLectura: {
    position: "relative",
    height: "86px",
    [theme.breakpoints.down(502)]:{
        height: "43px",
    },
    background: "#76D4C3",
    "& .BarraInfoLectura": {
      height: "86px",
      background: `Url(https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Barras/Azul.png)`,
        [theme.breakpoints.down(502)]:{
            height: "43px",
        },
    },
    "& .InfoLectura": {
      position: "absolute",
      height: "86px",
      width: "219px",
      boxSizing: "border-box",
      padding: "17.2px 0",
      background: "#FAFAFA",
      borderRadius: "50px",
      boxShadow: "2px 2px 2px 2px #00000029",
      zIndex: "4",
        [theme.breakpoints.down(502)]:{
            height: "43px",
            width: "109px",
            padding: "8.6px 0",
            borderRadius: "25px",
        },
    },
    "& .InfoLectura:before": {
      position: "absolute",
      display: "flex",
      boxSizing: "border-box",
      padding: "0 0 0 10px",
      justifyContent: "center",
      alignItems: "center",
      height: "39.88px",
      width: "39.88px",
      borderRadius: "50%",
      content: '""',
      boxShadow: "3px 0px .5px 0px #00000029",
      background: "#FAFAFA",
      left: "90px",
      top: "65px",
      transform: "rotate(0.25turn)",
      [theme.breakpoints.down(502)]:{
        left: "45px",
        top: "30px",
        height: "20.88px",
        width: "20.88px",
        },
    },
    "& .InfoLectura:after": {
      content: '""',
      height: "15px",
      width: "15px",
      borderTop: "2px solid #20A698",
      borderLeft: "2px solid #20A698",
      position: "absolute",
      bottom: "-10px",
      left: "Calc(50% - 8px)",
      transform: "rotate(0.625turn)",
      [theme.breakpoints.down(502)]:{
        height: "10px",
        width: "10px",
        left: "Calc(50% - 4.5px)",
        bottom: "-3px",
        borderTop: "1px solid #20A698",
        borderLeft: "1px solid #20A698",
        },
    },
    "& .Sesion": {
      borderLeft: "2px solid #D8D8D8",
    },
    "& .Dato": {
      margin: "0 0 5px 0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#20A698",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        margin: "0 0 2.5px 0",
        },
    },
    "& .Caracter": {
      margin: "0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#000000",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        },
    },   },
    ContenedorLectura:{
        background:'#fff',
        width:'1160px',
        height:'100%',
        padding:'60px 156px 35px ',
        [theme.breakpoints.down(1161)]:{
            width:'520px',
            padding:'60px 0 0 0',
          },
        [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
        '& .ContainerDerechos':{
            bottom:'80px',
            [theme.breakpoints.down(1161)]:{
                display:'none',
            },
        },
        '& .Derechos':{
            textAlign:"center",
            margin:"0px",
            font:" 15px lato",
            color:"#717476",
        },
        '& .Escritorio':{
            display:'block',
            [theme.breakpoints.down(1161)]:{
                display:'none'
            },
        },
        '& .Tablet':{
            display:'none',
            [theme.breakpoints.down(1161)]:{
                display:'block'
            },
        }
    },
    ContainerTitulo:{
        margin:'0 0 34px 0',
        [theme.breakpoints.down(1161)]:{
            margin:'0 0 34px 0',
        },
        '& .Titulo':{
            font:'48px fredoka one',
            letterSpacing: '0px',
            lineHeight:'58px',
            color: '#3AB0E4',
            margin:'0',
            textAlign:'center',
            [theme.breakpoints.down(1161)]:{
                font:'43px fredoka one',
                lineHeight:'52px',
            },
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            lineHeight : '30px',
            margin:'0 0 36px 0',
            font:'22px lato',
            color:'#000',
            letterSpacing: '0.20px',
            [theme.breakpoints.down(1161)]:{
                font:'18px lato',
                lineHeight :'28px',
                letterSpacing: '0.17px',
            },
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .SubTitulo':{
            font:'bold 30px lato',
            color:'#FE581F',
            letterSpacing:'.28px',
            lineHeight:'30px',
            margin:'0',
            [theme.breakpoints.down(1161)]:{
                lineHeight :'28px',
                font:'bold 26px lato',
                letterSpacing: '0.24px',
            },
            [theme.breakpoints.down(502)]:{
                font:'bold 18px lato',
                lineHeight :'24px',
                letterSpacing: '0.21px',
            },
        },
        '& .Esp':{
            margin:'0',
        },
        '& .Esp1':{
            margin:'0',
            [theme.breakpoints.down(1161)]:{
                padding:'0 25px 0 0',
            },
            [theme.breakpoints.down(520)]:{
                padding:'0 ',
            },
        },
        '& .Esp2':{
            padding:'0 25px 0 0',
            [theme.breakpoints.down(520)]:{
                padding:'0 ',
            },
        },
        '& .ul':{
            padding:'0 0 0 15px',
            margin:'0 0 36px 0',
            color:'#FE581F',
        },
        '& .li':{
            margin:'0 0 8px 0',
            [theme.breakpoints.down(502)]:{
                margin:'0 0 4px 0',
            },
        },
        '& .Color':{
            color:'#3AB0E4',
        },

        '& .Imagen':{
            width:'100%',
        },  
      
    }
}));

const Los_tipos_de_color = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
             <Grid container item justifyContent="center" className={classes.ContainerInfoLectura}>
               <Grid container className="BarraInfoLectura"></Grid>
               <Grid container item className="InfoLectura">
                    <Grid container item direction="column" xs={6} justifyContent="center">
                        <p className="Dato">Grado</p>
                        <p className="Caracter">1</p>
                    </Grid>
                    <Grid container item direction="column" xs={6} justifyContent="center" className="Sesion">
                        <p className="Dato">Sesión</p>
                        <p className="Caracter">5</p>
                    </Grid>
               </Grid>
            </Grid>

            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                        <h2 className="Titulo">Los tipos de color</h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                       <p className='Lectura Esp1'>
                        <h3 className='SubTitulo '>El color</h3><br/>

                        ¿Te has puesto a pensar de qué color es el mundo? Tal vez pienses en el amarillo del 
                        Sol, el azul del mar o el rosa de las flores. Con tantos colores, ¡el mundo es un arcoíris 
                        único!<br/><br/>

                        Todos los colores del mundo se dividen en tres grupos: primarios, secundarios y terciarios.
                       </p>  

                        <ul className='ul'>
                            <li className='li'>
                                <p className='Lectura Esp'>
                                    <span className="Color">Colores primarios:</span> son tres colores y también se les llama colores básicos, 
                                    porque no hay otra mezcla que pueda crearlos o imitarlos. Éstos son el amarillo, como el Sol, el 
                                    azul, como el cielo, y el rojo, como una fresa.
                                </p>
                                
                            </li>
                            <li className='li'>
                                <p className='Lectura Esp'>
                                    <span className="Color"> Colores secundarios:</span> son el resultado de la mezcla de dos colores primarios. 
                                    Los colores secundarios son el violeta, que es una mezcla de rojo y azul, como el color de 
                                    una mora. El verde, que es una mezcla del amarillo y el azul, como el color del pasto. Y 
                                    el anaranjado, que es una mezcla del rojo y el amarillo, ¡como una naranja!
                                </p>
                            </li>
                            <li className='li'>
                                <p className='Lectura Esp'>
                                    <span className="Color">Colores terciarios:</span> son todos los demás colores que puedes ver. Estos colores son la 
                                    combinación de un color primario y uno secundario. Por ejemplo, si mezclamos el azul 
                                    con el verde tendremos un azul turquesa, como el color del mar.
                                </p>
                            </li>
                            
                        </ul>        
                        <p className='Lectura Esp2'>
                            <h3 className='SubTitulo'>El círculo cromático</h3><br/>

                            El círculo cromático es una herramienta que te puede ayudar a identificar los diferentes tipos de color. 
                            Este círculo organiza los colores primarios, secundarios y terciarios de acuerdo con sus posibles combinaciones.

                        </p>         
                    </Grid>
                    <Grid container justifyContent='center'>
                        <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img1ro/Sesion5/1.png" alt="Tarjeta" />
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container >
                        <p className='Lectura'>
                            Ahora que conocemos los tipos de colores, estás listo para dejar salir a tu artista interior.
                        </p>
                    </Grid>
                </Grid>

                <Grid container item xs={12} justifyContent="center" className="ContainerDerechos">
                    <p className="Derechos">
                        © 2022. Todos los derechos reservados, Pearson, Circuito de lectores.
                    </p>                
                </Grid>
            </Grid>
    </Grid>
    );
}
 
export default Los_tipos_de_color;
