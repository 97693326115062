import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
    ContenedorLectura:{
        width:'502px',
                  [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
    },
    ContainerTitulo:{
        margin:'0 0 34px 0',
        '& .Titulo':{
            letterSpacing: '0px',
            color: '#E1AF10',
            margin:'0',
            textAlign:'center',
            font:'43px fredoka one',
            lineHeight:'52px',
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            margin:'0 0 36px 0',
            color:'#000',
            font:'18px lato',
            lineHeight :'28px',
            letterSpacing: '0.17px',
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .SubTitulo':{
            color:'#0CB5D6',
            margin:'0',
            lineHeight :'28px',
            font:'bold 26px lato',
            letterSpacing: '0.24px',
            [theme.breakpoints.down(502)]:{
                font:'bold 18px lato',
                lineHeight :'24px',
                letterSpacing: '0.21px',
            },
        },
        '& .TextImg':{
            color:'#717476',
            margin:'24px 0 0 0',
            font:' 16px lato',
            lineHeight :'25px',
            letterSpacing: '0.15px',
            [theme.breakpoints.down(502)]:{
                font:' 11px lato',
                lineHeight :'14px',
                letterSpacing: '0.10px',
            },
        },
        '& .TD':{
            background:'#E0CB28',
            height:'58px',
            color:'#FFFFFF',
            textAlign:'center',
            font:'18px Fredoka One',
            lineHeight:'20px',
            letterSpacing:'.17px',
            [theme.breakpoints.down(502)]:{
                font:'13px Fredoka One',
                lineHeight:'30px',
                letterSpacing:'.12px',
            },
        },
        '& .TD1':{
            borderRadius:'25px 0 0 0',
            borderRight: '1px solid #fff',
        },
        '& .TD2':{
            borderRadius:'0 25px 0 0',
            borderLeft: '1px solid #fff',
            
        },
        '& .Td':{
            background:'#FBF8E9',
            border: '1px solid #e0cb28',
            height:'58px',
            width:'412px',
            color:'#000000',
            textAlign:'center',
            font:'18px lato',
            lineHeight:'30px',
            letterSpacing:'.17px',
            [theme.breakpoints.down(502)]:{
                font:'14px lato',
                lineHeight:'30px',
                letterSpacing:'.12px',
            },
        },
        '& .Td1':{
            borderRadius:'0 0 0 25px',
        },
        '& .Td2':{
            borderRadius:'0 0 25px 0',
        },
        '& .Imagen':{
            width:'100%',
        },  
      
    }
}));

const Ecología_lingüística_lenguas_en_peligro = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>

            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                        <h2 className="Titulo">Ecología lingüística: <br/>lenguas en peligro</h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                       <p className='Lectura'>
                        A lo largo de la historia, miles de lenguas han muerto, es decir, se han quedado sin 
                        hablantes. En la actualidad, dicha extinción está sucediendo con una rapidez
                        preocupante. Se calcula que más de la mitad de las lenguas minoritarias en el mundo 
                        desaparecerá durante este siglo.<br/><br/> 
                        
                        <h3 className='SubTitulo'>Ecosistemas sociales</h3><br/> 
                        
                        Tal como sucede en la naturaleza, en la sociedad hay ecosistemas frágiles. Las 
                        lenguas viven en un espacio y se ven amenazadas cuando las desplazan de allí. <br/><br/>
                        
                        La <em>ecología lingüística</em> estudia el medio donde habitan los hablantes de una lengua.
                        Esta ciencia utiliza términos como <em>hábitat, coexistencia</em> y <em>lengua en peligro de extinción</em>. 
                        Como ves, son conceptos muy parecidos a los que la ecología usa para tratar la 
                        protección de animales y plantas, pero en este caso se aplican a la diversidad 
                        lingüística. <br/><br/>
                        
                        A veces parece que una lengua dominante amenaza a las minoritarias. Por ejemplo, 
                        alguien podría pensar que el español pone en peligro a las lenguas indígenas 
                        latinoamericanas, por imponerse como la lengua oficial o dominante. Pero no es la 
                        existencia de ciertas lenguas lo que amenaza a las otras: no es la lengua la que decide 
                        imponerse. En realidad, son las prácticas culturales y económicas de una sociedad las 
                        que discriminan a algunas comunidades en beneficio de otras. La mayoría de las 
                        veces esto lo promueven las autoridades, pues obligan a los hablantes a abandonar 
                        sus usos y sus costumbres. <br/><br/>
                        
                        <h3 className='SubTitulo'>Peligro de extinción</h3><br/>
                        
                        Una lengua que se queda sin hablantes es una lengua muerta. En Latinoamérica 
                        existen 248 lenguas en grave peligro de extinción. El <em>Atlas Interactivo UNESCO</em> de las 
                        <em> Lenguas del Mundo en Peligro</em> es una herramienta digital que puedes consultar para 
                        saber cuál es la situación de las lenguas más desfavorecidas de tu país y de tu región. 
                        De acuerdo con dicha fuente, éste es el número de lenguas en situación crítica y en 
                        grave peligro de extinción en cada país de Latinoamérica:
                       </p>                   
                    </Grid>
                    <Grid container justifyContent='center'>
                        <tr className='Tr'>
                            <tr>
                                <td valign="middle" className='TD TD1'>País</td>
                                <td valign="middle" className='TD TD2'>Lenguas en <br/>situación crítica</td>

                            </tr>
                            <tr>
                                <td valign="middle" className="Td">Argentina</td>
                                <td valign="middle" className="Td">6</td>
                            </tr>
                            <tr>
                                <td valign="middle" className="Td">Bolivia</td>
                                <td valign="middle" className="Td">18</td>
                            </tr>
                            <tr>
                                <td valign="middle" className="Td ">Costa Rica</td>
                                <td valign="middle" className="Td ">4</td>
                            </tr>
                            <tr>
                                <td valign="middle" className="Td ">Chile</td>
                                <td valign="middle" className="Td ">4</td>
                            </tr>
                            <tr>
                                <td valign="middle" className="Td ">Colombia</td>
                                <td valign="middle" className="Td ">24</td>
                            </tr>
                            <tr>
                                <td valign="middle" className="Td ">Ecuador</td>
                                <td valign="middle" className="Td ">6</td>
                            </tr>
                            <tr>
                                <td valign="middle" className="Td ">El Salvador</td>
                                <td valign="middle" className="Td ">1</td>
                            </tr>
                            <tr>
                                <td valign="middle" className="Td ">Honduras</td>
                                <td valign="middle" className="Td ">5</td>
                            </tr>
                            <tr>
                                <td valign="middle" className="Td ">Guatemala</td>
                                <td valign="middle" className="Td ">3</td>
                            </tr>
                            <tr>
                                <td valign="middle" className="Td ">México</td>
                                <td valign="middle" className="Td ">53</td>
                            </tr>
                            <tr>
                                <td valign="middle" className="Td ">Nicaragua</td>
                                <td valign="middle" className="Td ">4</td>
                            </tr>
                            <tr>
                                <td valign="middle" className="Td ">Panamá</td>
                                <td valign="middle" className="Td ">2</td>
                            </tr>
                            <tr>
                                <td valign="middle" className="Td ">Paraguay</td>
                                <td valign="middle" className="Td ">6</td>
                            </tr>
                            <tr>
                                <td valign="middle" className="Td ">Perú</td>
                                <td valign="middle" className="Td ">29</td>
                            </tr>
                            <tr>
                                <td valign="middle" className="Td ">Uruguay</td>
                                <td valign="middle" className="Td ">1</td>
                            </tr>
                            <tr>
                                <td valign="middle" className="Td Td1">Venezuela</td>
                                <td valign="middle" className="Td Td2">15</td>
                            </tr>
                        </tr>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container >
                        <p className='Lectura'>
                            Entre todas esas lenguas se encuentran, por ejemplo, el ayapaneco, el kaqchikel y el 
                            zapoteco (de México); el taushiro, el iñapari y el cauqui (de Perú); el baré y el baniva 
                            (de Venezuela); el baure, el maropa y el moré (de Bolivia), y el carabayo, el opón-
                            carare y el pijao (de Colombia).<br/><br/>
                            
                            Para proteger y revitalizar una lengua en peligro es necesario ofrecer a sus hablantes 
                            las mismas oportunidades que tienen quienes hablan una lengua dominante. Una vía 
                            para lograrlo es promover el <em>contacto recíproco</em> entre las lenguas. En otras palabras, si 
                            a los niños que hablan una lengua minoritaria se les enseña español y éste forma 
                            parte de su educación, lo correcto sería que también los niños hispanohablantes de 
                            las comunidades cercanas aprendieran alguna lengua minoritaria que se hable en su 
                            región. Iniciativas como ésta pueden beneficiar a ambas comunidades y generar un 
                            equilibrio en los ecosistemas lingüísticos. <br/><br/>
                            
                            Muchas lenguas minoritarias no cuentan con registros escritos ni tienen sistemas de 
                            escritura. Los especialistas y las comunidades pueden trabajar en conjunto para 
                            desarrollar un sistema que ayude a preservar las lenguas en peligro. También pueden 
                            recolectar textos (orales o escritos) y videos para dejar testimonio de su existencia y 
                            apoyar su revitalización.
                        </p>
                    </Grid>
                    <Grid container justifyContent='center'>
                        <Grid container justifyContent='center'>
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion32/1.png" alt="Tarjeta" />
                        </Grid>
                        <Grid container >
                            <p className="TextImg">
                                El tehuelche era una lengua indígena hablada en la Patagonia chilena y argentina. En 2019 
                                se extinguió tras el fallecimiento de su última hablante nativa, Dora Manchado. Sin embargo, 
                                ella permitió que se documentara su lengua a través de videos didácticos. Ahora el tehuelche 
                                está en proceso de revitalización en la comunidad a la que Dora pertenecía.
                            </p>
                        </Grid>
                    </Grid>
                </Grid>
        </Grid>
    </Grid>
    );
}
 
export default Ecología_lingüística_lenguas_en_peligro;
