import { Button, Grid, makeStyles } from "@material-ui/core";
import React, { useContext, useEffect } from "react";
import DrawerContext from "../../../../../usability/DrawerContext";
import { useOnLoadImages } from "../../../../hooks/useOnLoadImages";
import Loader from "../../../../Loader/Loader";
import Drawer from "../../ReadingTools/Drawer";

const useStyles =  makeStyles (theme =>({
     Contenedor:{
        background:'#E9FBF9',
        width:'100vw',
        overflowX:'clip',
        [theme.breakpoints.down(1161)]:{
            background:'none',
           overflowX:'unset',
        },
        [theme.breakpoints.down(600)]:{
            marginTop: '79.51px',
        }
    },
      ContainerInfoLectura: {
    position: "relative",
    height: "86px",
    [theme.breakpoints.down(502)]:{
        height: "43px",
    },
    background: "#76D4C3",
    "& .BarraInfoLectura": {
      height: "86px",
      background: `Url(https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Barras/Azul.png)`,
        [theme.breakpoints.down(502)]:{
            height: "43px",
        },
    },
    "& .InfoLectura": {
      position: "absolute",
      height: "86px",
      width: "219px",
      boxSizing: "border-box",
      padding: "17.2px 0",
      background: "#FAFAFA",
      borderRadius: "50px",
      boxShadow: "2px 2px 2px 2px #00000029",
      zIndex: "4",
        [theme.breakpoints.down(502)]:{
            height: "43px",
            width: "109px",
            padding: "8.6px 0",
            borderRadius: "25px",
        },
    },
    "& .InfoLectura:before": {
      position: "absolute",
      display: "flex",
      boxSizing: "border-box",
      padding: "0 0 0 10px",
      justifyContent: "center",
      alignItems: "center",
      height: "39.88px",
      width: "39.88px",
      borderRadius: "50%",
      content: '""',
      boxShadow: "3px 0px .5px 0px #00000029",
      background: "#FAFAFA",
      left: "90px",
      top: "65px",
      transform: "rotate(0.25turn)",
      [theme.breakpoints.down(502)]:{
        left: "45px",
        top: "30px",
        height: "20.88px",
        width: "20.88px",
        },
    },
    "& .InfoLectura:after": {
      content: '""',
      height: "15px",
      width: "15px",
      borderTop: "2px solid #20A698",
      borderLeft: "2px solid #20A698",
      position: "absolute",
      bottom: "-10px",
      left: "Calc(50% - 8px)",
      transform: "rotate(0.625turn)",
      [theme.breakpoints.down(502)]:{
        height: "10px",
        width: "10px",
        left: "Calc(50% - 4.5px)",
        bottom: "-3px",
        borderTop: "1px solid #20A698",
        borderLeft: "1px solid #20A698",
        },
    },
    "& .Sesion": {
      borderLeft: "2px solid #D8D8D8",
    },
    "& .Dato": {
      margin: "0 0 5px 0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#20A698",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        margin: "0 0 2.5px 0",
        },
    },
    "& .Caracter": {
      margin: "0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#000000",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        },
    },
       '& .Regresar':{
        color: '#F2624F',
        border: '#F2624F 1px solid',
        height: '30px',
        width:'100px',
        borderRadius: '20px',
        letterSpacing: '0.14px',
        textTransform: 'none',
        background:'#FEFAF1',
        top:'calc(50% - 15px)',
        left:'5%',
        zIndex:4,
        [theme.breakpoints.down(502)]: {
          font:'bold 10px lato',
          width: "60px",
        },
      },
  },
    ContenedorLectura:{
        background:'#fff',
        width:'1160px',
        height:'100%',
        padding:'60px 168px 35px ',
        
        transform: (props) =>
        props.scale > 1 ? `scale(${props.scale}) translate(0px, 10%)` : `none`,
        [theme.breakpoints.down(1161)]:{
            width:'502px',
            padding:'60px 0 0 0',
          },
        [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },

        '& .Escritorio':{
            display:'block',
            [theme.breakpoints.down(1161)]:{
                display:'none'
            },
        },
        '& .Tablet':{
            display:'none',
            [theme.breakpoints.down(1161)]:{
                display:'block'
            },
        }
    },
    ContainerTitulo:{
        margin:'0 0 34px 0',
        [theme.breakpoints.down(1161)]:{
            margin:'0 0 34px 0',
        },
        '& .Titulo':{
            letterSpacing: '0px',
            color: '#CE1FD5',
            margin:'0',
            textAlign:'center',
            font:'48px fredoka one',
            lineHeight:'58px',
            [theme.breakpoints.down(1161)]:{
                font:'43px fredoka one',
                lineHeight:'52px',
            },
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        position:'relative',
        '& .Lectura':{
            zIndex:1,
            margin:'0 0 36px 0',
            color:'#000',
            font:'22px lato',
            lineHeight : '30px',
            letterSpacing: '0.20px',
            [theme.breakpoints.down(1161)]:{
                font:'18px lato',
                lineHeight :'28px',
                letterSpacing: '0.17px',
            },
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        "& .SubTitulo": {
            color: "#926DD1",
            margin: "0",
            font: "bold 30px lato",
            letterSpacing: ".28px",
            lineHeight: "30px",
            [theme.breakpoints.down(1161)]: {
              lineHeight: "28px",
              font: "bold 26px lato",
              letterSpacing: "0.24px",
            },
            [theme.breakpoints.down(502)]: {
              font: "bold 22px lato",
              lineHeight: "24px",
              letterSpacing: "0.21px",
            },
        },
        '& .Esp':{
            boxSizing:'border-box',
            padding:'0 62px 0 0',
            [theme.breakpoints.down(1280)]: {
                padding:'0 ',
            },
        },
        '& .Esp2':{
            boxSizing:'border-box',
            padding:'0 63px 0 0',
            margin:'0 0 290px 0',
            [theme.breakpoints.down(1280)]: {
                padding:'0 49px 0 0',
                margin:'0 0 204px 0',
            },
            [theme.breakpoints.down(502)]: {
                padding:'0 0px 0 0',
                margin:'0 0 120px 0',
            },
        },
        '& .Imagen':{
            [theme.breakpoints.down(1161)]:{
                width:'100%',
            },
        },
        '& .ImagenEsp':{
            zIndex:0,
            position:'absolute',
            top:'15px',
            left:'25px',
            [theme.breakpoints.down(1161)]:{
                width:'600px',
                top:'25%',
                left:'0',
            },
            [theme.breakpoints.down(502)]:{
                top:'42%',
                width:'95%',
                left:'55px',
            },
        },
        '& .TextImg':{
            font:'20px lato',
            color:'#717476',
            letterSpacing:'.19px',
            lineHeight:'30px',
            margin:'24px 0 0 0',
            [theme.breakpoints.down(1161)]:{
                font:' 16px lato',
                lineHeight :'25px',
                letterSpacing: '0.15px',
            },
            [theme.breakpoints.down(502)]:{
                font:' 11px lato',
                lineHeight :'14px',
                letterSpacing: '0.10px',
            },
        },
    }
}));

const Cómo_se_comunican_las_ballenas = (props) => {

  const {activeStep, setActiveStep, handlePreviewStep, id_actividad} = props
  
  const handleContinue = () => {
    setActiveStep(activeStep - 1)
    handlePreviewStep()
  }

  const {
    handleSize,
    disabledZoom,
    closeZoom,
    handleZoom,
    scale
  }= useContext(DrawerContext);
  const [loading, setLoading] = React.useState(true);
  const wrapperRef = React.useRef(null);
  const imagesLoaded = useOnLoadImages(wrapperRef);

  const detectSize = () => {
    const lectura = document.getElementById("lectura");
    const newWidth = lectura?.offsetWidth;
    const newHeight = lectura?.offsetHeight;
    setLoading(false);
    if (lectura) handleSize(newHeight, newWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", detectSize);
    return () => {
      handleZoom()
      window.removeEventListener("resize", detectSize);
      closeZoom()
    };
  }, []);

  const classes = useStyles({ disabledZoom,scale });
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
             <Grid container item justifyContent="center" className={classes.ContainerInfoLectura}>
                       <Grid container className="BarraInfoLectura">
            <Button className="Regresar" onClick={handleContinue}>
                Regresar
            </Button>
        </Grid>
               <Grid container item className="InfoLectura">
                    <Grid container item direction="column" xs={6} justifyContent="center">
                        <p className="Dato">Grado</p>
                        <p className="Caracter">4</p>
                    </Grid>
                    <Grid container item direction="column" xs={6} justifyContent="center" className="Sesion">
                        <p className="Dato">Sesión</p>
                        <p className="Caracter">50</p>
                    </Grid>
               </Grid>
            </Grid>
            {!imagesLoaded && <Loader />}
        <Grid
            container
            item
            className={classes.ContenedorLectura}
            justifyContent="center"
            id="lectura"
            onLoad={detectSize}
            ref={wrapperRef}
        >
<Drawer id_lectura={id_actividad} />
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >
                        ¿Cómo se comunican<br className='Tablet'/> las ballenas?                  
                    </h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} >
                    <img className="ImagenEsp" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img4to/Sesion50/1.png" alt="Tarjeta" />
                    <Grid container item md={12} lg={7}>
                        <p className='Lectura Esp'>
                            Las ballenas, los delfines y muchas otras 
                            especies poseen una forma de 
                            comunicarse que ha sido imitada por la 
                            humanidad desde hace tiempo. Algunos
                            barcos y submarinos reproducen sonidos 
                            similares a los de estas especies, lo cual las 
                            desorienta, pues los confunden con 
                            mensajes de sus propias especies. 
                        </p>
                    </Grid>
                    <Grid container item xs={7}>
                        <p className='Lectura Esp2'>
                            Las ballenas son capaces de nadar en 
                            zonas profundas donde ya no hay luz solar 
                            y los murciélagos pueden volar a gran 
                            velocidad en sitios oscuros. Esquivan 
                            árboles y otros obstáculos en su camino, a 
                            pesar de que su visión nocturna no es tan 
                            buena. ¿Entonces cómo lo logran?
                        </p>
                    </Grid>
                    <Grid container justifyContent="center" xs={12}>
                        <Grid container justifyContent="center" >
                            <p className='TextImg'>
                                Muchos seres se desplazan y consiguen alimento emitiendo sonidos y escuchándolos regresar a ellos.
                            </p>                        
                        </Grid>                    
                    </Grid>

                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                            Esto es posible gracias a la <em>ecolocalización</em>. Se trata de una habilidad que tienen pocos 
                            animales. Consiste en producir ondas sonoras que rebotan en los objetos cercanos y 
                            regresan a quienes las emitieron. Por ejemplo, las ballenas, los delfines y las belugas 
                            lanzan sonidos. Éstos vuelven después y son captados por un depósito de grasa en su 
                            cabeza que recibe el nombre de <em>melón</em>. Gracias esta recepción de ondas conocen el 
                            entorno que los rodea, y así ubican el lugar exacto en el que se encuentran las cosas y 
                            los alimentos. La técnica es compartida por más de mil especímenes que la utilizan
                            para cazar, huir de sus depredadores o simplemente para desplazarse de un sitio a 
                            otro.<br/><br/> 
                            
                            La velocidad del sonido es cinco veces mayor en el océano que en el aire. Los 
                            murciélagos mueven sus alas y gritan hasta 190 veces por segundo, aunque el 
                            volumen es imperceptible para el oído humano. Los ecos llegan a sus orejas y les
                            ayudan a detectar diferentes cosas, como los delgados hilos de las redes que se 
                            colocan para cazarlos, de esta forma logran escapar de ellos. Con este proceso 
                            también cumplen su tarea en el ecosistema donde habitan, que es controlar las 
                            poblaciones de insectos y polinizar las plantas. <br/><br/>
                            
                            <h3 className='SubTitulo'>Aplicaciones</h3><br/>
                            
                            Entender la ecolocalización permitió a los ingenieros fabricar sonares que se utilizan 
                            en barcos y submarinos para navegar durante la noche. También los emplean cuando 
                            atraviesan aguas oscuras y en actividades militares. Pero dichos aparatos interfieren 
                            en la comunicación entre animales, quienes escuchan los ruidos y los confunden. Las 
                            consecuencias son múltiples, entre ellas está que se pierdan durante las migraciones 
                            que hacen para reproducirse y buscar comida. En ocasiones, puede llevarlos a aguas 
                            poco profundas de las cuales ya no pueden salir y mueren.              
                        </p>
                    </Grid>
                    <Grid container justifyContent="center" xs={12}>
                        <Grid container justifyContent="center" >
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img4to/Sesion50/2.png" alt="Tarjeta" />
                        </Grid>
                        <Grid container justifyContent="center" >
                            <p className='TextImg'>
                                Los sonares confunden a los mamíferos marinos al usar el mismo principio de emisión y rebote de ecos.
                            </p>                        
                        </Grid>                    
                    </Grid>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container item justifyContent='center'  sm={12}>
                        <p className="Lectura ">
                            Pero no todo es negativo en la imitación de la ecolocalización. Ha permitido a los 
                            seres humanos construir equipo médico para diagnosticar diferentes enfermedades. 
                            Por ejemplo, los tomógrafos, que producen ondas que chocan en los órganos y 
                            ayudan a formar imágenes del interior de nuestro cuerpo, así pueden identificarse
                            males que no son observables desde fuera.<br/><br/> 
                            
                            Otra aplicación es la delfinoterapia, en la cual los delfines interactúan con personas
                            en el momento de hacer ecolocalización. Estas ondas sonoras son percibidas por la 
                            gente, en quienes tienen un efecto positivo. Los científicos consideran que hay ruidos 
                            que los oídos no pueden percibir, pero el cerebro sí. En respuesta, genera sustancias 
                            químicas llamadas <em>endorfinas</em>, que están relacionadas con la sensación de bienestar. 
                            Se piensa que este fenómeno puede calmar el dolor, tranquilizar e incluso curar 
                            enfermedades. <br/><br/>
                            
                            Ahora conoces un poco más sobre este modo de comunicación que es empleado para 
                            trasladarse en los océanos. ¿Alguna vez has subido a un barco que lo use? 
                        </p>
                    </Grid>
                </Grid>
        </Grid>
        <Grid container item style={{ marginTop: 80}} ></Grid>
    </Grid>
    );
}
 
export default Cómo_se_comunican_las_ballenas ;
