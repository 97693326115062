import { Grid,makeStyles}              from '@material-ui/core';
import { padding } from '@mui/system';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
     Contenedor:{
        background:'#E9FBF9',
        width:'100vw',
        overflowX:'clip',
        [theme.breakpoints.down(1161)]:{
            background:'none',
           overflowX:'unset',
        },
        [theme.breakpoints.down(600)]:{
            marginTop: '79.51px',
        }
    },
  ContainerInfoLectura: {
    position: "relative",
    height: "86px",
    [theme.breakpoints.down(502)]:{
        height: "43px",
    },
    background: "#76D4C3",
    "& .BarraInfoLectura": {
      height: "86px",
      background: `Url(https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Barras/Azul.png)`,
        [theme.breakpoints.down(502)]:{
            height: "43px",
        },
    },
    "& .InfoLectura": {
      position: "absolute",
      height: "86px",
      width: "219px",
      boxSizing: "border-box",
      padding: "17.2px 0",
      background: "#FAFAFA",
      borderRadius: "50px",
      boxShadow: "2px 2px 2px 2px #00000029",
      zIndex: "4",
        [theme.breakpoints.down(502)]:{
            height: "43px",
            width: "109px",
            padding: "8.6px 0",
            borderRadius: "25px",
        },
    },
    "& .InfoLectura:before": {
      position: "absolute",
      display: "flex",
      boxSizing: "border-box",
      padding: "0 0 0 10px",
      justifyContent: "center",
      alignItems: "center",
      height: "39.88px",
      width: "39.88px",
      borderRadius: "50%",
      content: '""',
      boxShadow: "3px 0px .5px 0px #00000029",
      background: "#FAFAFA",
      left: "90px",
      top: "65px",
      transform: "rotate(0.25turn)",
      [theme.breakpoints.down(502)]:{
        left: "45px",
        top: "30px",
        height: "20.88px",
        width: "20.88px",
        },
    },
    "& .InfoLectura:after": {
      content: '""',
      height: "15px",
      width: "15px",
      borderTop: "2px solid #20A698",
      borderLeft: "2px solid #20A698",
      position: "absolute",
      bottom: "-10px",
      left: "Calc(50% - 8px)",
      transform: "rotate(0.625turn)",
      [theme.breakpoints.down(502)]:{
        height: "10px",
        width: "10px",
        left: "Calc(50% - 4.5px)",
        bottom: "-3px",
        borderTop: "1px solid #20A698",
        borderLeft: "1px solid #20A698",
        },
    },
    "& .Sesion": {
      borderLeft: "2px solid #D8D8D8",
    },
    "& .Dato": {
      margin: "0 0 5px 0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#20A698",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        margin: "0 0 2.5px 0",
        },
    },
    "& .Caracter": {
      margin: "0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#000000",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        },
    },   },
    ContenedorLectura:{
        background:'#fff',
        width:'1160px',
        height:'100%',
        padding:'60px 168px 35px ',
        [theme.breakpoints.down(1161)]:{
            width:'502px',
            padding:'60px 0 0 0',
          },
        [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
        '& .ContainerDerechos':{
            bottom:'80px',
            [theme.breakpoints.down(1161)]:{
                display:'none',
            },
        },
        '& .Derechos':{
            textAlign:"center",
            margin:"0px",
            font:" 15px lato",
            color:"#717476",
        },
        '& .Escritorio':{
            display:'block',
            [theme.breakpoints.down(1161)]:{
                display:'none'
            },
        },
        '& .Tablet':{
            display:'none',
            [theme.breakpoints.down(1161)]:{
                display:'block'
            },
        }
    },
    ContainerTitulo:{
        margin:'0 0 46px 0',
        '& .Titulo':{
            font:'48px fredoka one',
            letterSpacing: '0px',
            color: '#FC5E9E',
            margin:'0',
            textAlign:'center',
            [theme.breakpoints.down(1161)]:{
                font:'43px fredoka one',
                lineHeight:'52px',
            },
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },

        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            lineHeight : '30px',
            margin:'0 0 36px 0 ',
            font:'22px lato',
            color:'#000',
            letterSpacing: '0.20px',
            [theme.breakpoints.down(1161)]:{
                font:'18px lato',
                lineHeight :'28px',
                letterSpacing: '0.17px',
            },
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .Esp':{
            margin:'0',
        },
        '& .SubTitulo':{
            font:'bold 30px lato',
            color:'#F4BF1E',
            letterSpacing:'.28px',
            margin:'0',
            [theme.breakpoints.down(1161)]:{
                lineHeight :'28px',
                font:'bold 26px lato',
                letterSpacing: '0.24px',
            },
            [theme.breakpoints.down(502)]:{
                font:'bold 18px lato',
                lineHeight :'24px',
                letterSpacing: '0.21px',
            },
        },
        '& .TextImg':{
            margin:'22px 0 0 0',
            font:'20px lato',
            alignSelf:'flex-start',
            color:'#717476',
            [theme.breakpoints.down(1161)]:{
                font:' 16px lato',
                lineHeight :'25px',
                letterSpacing: '0.15px',
            },
            [theme.breakpoints.down(502)]:{
                font:' 11px lato',
                lineHeight :'14px',
                letterSpacing: '0.10px',
            },
        },
        '& .punto':{
            color:'#F4BF1E',
            font:'10px',
            margin:'8px',
            [theme.breakpoints.down(502)]:{
                margin:'4px',
            }
        },
        '& .ul':{
            margin:'0 0 26px 0',
            padding:'0 0 0 19px'    
        },
        '& .Imagen':{
            [theme.breakpoints.down(1161)]:{
                width:'100%',
            },
        },  
    },
    

}));

const El_valor_de_una_persona = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
             <Grid container item justifyContent="center" className={classes.ContainerInfoLectura}>
               <Grid container className="BarraInfoLectura"></Grid>
               <Grid container item className="InfoLectura">
                    <Grid container item direction="column" xs={6} justifyContent="center">
                        <p className="Dato">Grado</p>
                        <p className="Caracter">2</p>
                    </Grid>
                    <Grid container item direction="column" xs={6} justifyContent="center" className="Sesion">
                        <p className="Dato">Sesión</p>
                        <p className="Caracter">9</p>
                    </Grid>
               </Grid>
            </Grid>

            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >
                        El valor de una persona
                    </h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                      <p className="Lectura">
                        <h3 className='SubTitulo'>¿Cuánto vales? </h3><br/>
                         ¿Alguna vez te has preguntado cuál es tu valor como persona? Intenta calcularlo a 
                         partir de algo que te guste o te importe mucho. Por ejemplo, si eligieras tus juguetes 
                         como referencia, ¿cuántos juguetes crees que vales?<br/><br/> 
                         
                         En realidad, el valor de una persona no puede calcularse con objetos ni con dinero. Es
                         incalculable, porque las personas somos invaluables. Eso quiere decir que valemos 
                         muchísimo. ¿Sabes por qué? Porque somos únicos.<br/><br/> 
                         
                         Los objetos y las cosas se repiten, pero las personas no. Nuestra manera de vivir, lo 
                         que aportamos, lo que creamos y lo que damos, todo eso es invaluable. Nadie más 
                         hará exactamente lo mismo que cada uno de nosotros. Por ello no hay comparación.
                      </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <Grid container justifyContent="center" alignItems='flex-end'>
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img2do/Sesion9/1.png" alt="Tarjeta" />
                        </Grid>
                        <Grid container  >
                            <p className="TextImg"> 
                            Aunque todos somos parte de una comunidad o identidad colectiva, cada uno de nosotros 
                            posee características que lo distinguen de los demás.
                            </p>
                            
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                      <p className="Lectura">
                        <h3 className='SubTitulo'>Autoestima </h3><br/>
                        A veces es difícil apreciar nuestro valor, como cuando nos sentimos tristes o las cosas 
                        no nos salen como esperábamos. En momentos así es bueno recordar el impacto que 
                        tenemos a nuestro alrededor.<br/><br/>  
                        
                        Si algún día te sientes poco valorado o decaído, te recomendamos un ejercicio de
                        autoestima. Como su nombre lo indica, la <em>autoestima</em> es quererse uno mismo,
                        <em>estimarse</em>. El ejercicio consiste en pensar en tus seres queridos e imaginar cómo sería
                        su vida sin ti: sin tus palabras, tus ocurrencias, tus creaciones, tus ideas, tus juegos…
                        ¿Verdad que no sería lo mismo? Recuerda: lo que tú les aportas es único.<br/> <br/> 

                        Otra manera de valorarte es pensar en las cosas que haces. Por ejemplo:
                      </p>
                      <ul  className="ul">
                          <li className="punto"><p className="Lectura Esp">Tus esfuerzos en la escuela para continuar estudiando.</p></li>
                          <li className="punto"><p className="Lectura Esp">Las actitudes que tienes a diario con las demás personas (cordialidad, amabilidad, cariño, etcétera).</p></li>
                          <li className="punto"><p className="Lectura Esp">Las labores que desempeñas (como cooperar en casa o ayudar en tu comunidad).</p></li>
                          <li className="punto"><p className="Lectura Esp">Tus habilidades (como escribir o practicar alguna disciplina artística).</p></li>
                      </ul>
                      <p className="Lectura">
                        Reconoce lo que vales. Eres importante por todo lo que ya eres y por todo lo que 
                        llegarás a ser.
                      </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <Grid container justifyContent="center" alignItems='flex-end'>
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img2do/Sesion9/2.png"alt="Tarjeta" />
                        </Grid>
                           <Grid container item justifyContent='center'   >
                            <p className="TextImg"> 
                            El amor propio es el primer paso para amar el entorno.
                            </p>
                            
                        </Grid>
                    </Grid>
                </Grid>
            <Grid container item xs={12} justifyContent="center" className="ContainerDerechos">
                <p className="Derechos">
                    © 2022. Todos los derechos reservados, Pearson, Circuito de lectores.
                </p>                
            </Grid>
        </Grid>
    </Grid>
    );
}
 
export default El_valor_de_una_persona;
