import React                          from 'react';
import { makeStyles, Grid }           from "@material-ui/core";
import List                           from '@material-ui/core/List';
import ListItem                       from '@material-ui/core/ListItem';
import ListItemText                   from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'grid',
    gridTemplateColumns: '290',
    alignContent: 'space-evenly',
    justifyContent: 'start',
    // gridGap: "10px",
    // maxHeigth: "330px",
    // overflowY: "scroll",
    // [theme.breakpoints.down(980)]: {
    //   gridTemplateColumns: 'auto auto'
    // },
    // [theme.breakpoints.down(620)]: {
    //   gridTemplateColumns: 'auto'
    // },
  },
  heading: {
    font: " 22px lato",
    letterSpacing: "0.36px",
    color: 'purple',
    margin: '0px',
    [theme.breakpoints.down(425)]: {
      font: " 14px lato",
    },
  },
  heading2: {
    font: " 20px lato",
    letterSpacing: "0.36px",
    color: 'black',
    margin: '0px',
    [theme.breakpoints.down(425)]: {
      font: " 14px lato",
    },
  },
  textName: {
    font: " 22px lato",
    letterSpacing: "0.36px",
    color: '#7A54BA',
    margin: '0px',
    padding:"5px",
    [theme.breakpoints.down(425)]: {
      font: " 14px lato",
    },
  },
  selectedName:{
    backgroundColor: '#B965D3',
    font: " 22px lato",
    letterSpacing: "0.36px",
    color: '#ffffff',
    margin: '0px',
    padding:"5px",
    [theme.breakpoints.down(425)]: {
      font: " 14px lato",
    },
  },
  PurpleButton: {
    marginTop: '10px',
    color: "#FFFFFF",
    backgroundColor: "#9051b1",
    borderRadius: "30px",
    // margin: "0 auto",
    textTransform: "unset",
    width: "100%",
    maxWidth: "238px",
    minHeight: "45px",
    border: "none",
    // textTransform: "uppercase",
    cursor: "pointer",
    fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
    fontWeight: "500",
    padding: "6px 16px",
    lineHeight: "1.75",
    transition:
      "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    boxShadow: "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
    "&:hover": {
      backgroundColor: "#B965D3 ",
    },
    "&:disabled": {
      backgroundColor: "#FFFFFF",
      color: "#9051b1",
    },
  },
  gridItem:{
    height: 'auto',
    // overflow: "hidden",
    // border: "1px solid #F27964",
  },
  ListMainContainer:{
    // maxHeight: '500px',
    // overflowY: "scroll",
    width: 'auto'
  },
  listContainer:{
    // maxHeight: '500px',
    // overflowY: "scroll",

  }
}));

const StudentSelector = (props) => {
  const {obj, checked, setChecked, setPristine} = props
  const classes = useStyles();

  const handleToggle = (obj) => {
    const currentIndex = checked.indexOf(obj);

    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(obj);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setPristine(false)
    setChecked(newChecked);
  };

  return (
    <Grid 
      container 
      // alignItems="center" 
      justifyContent="flex-start" 
      className={classes.gridItem}
    >
      <ListItem 
        key={obj.id_habilidad + "-habilidad"} 
        // selected={selectedIndex === obj.id_usuario}
        selected={checked.indexOf(obj) !== -1}
        tabIndex={-1}
        dense 
        button
        onClick={() => {handleToggle(obj)}}
        disabled={checked.indexOf(obj) === -1 && checked.length === 10 }
        >
        <Checkbox checked={checked.indexOf(obj) !== -1}  />
        <ListItemText
          id={obj.id_habilidad} 
          primary={obj.descripcion} 
          className={classes.textName} 
        />
      </ListItem>
    </Grid>
  );
}

const StendentsList = (props) => {
  const { setSkillsData, comprenSkills, socioSkills} = props
  const classes = useStyles();
  const [checked1, setChecked1] = React.useState([])
  const [pristine1, setPristine1] = React.useState(true)
  const [checked2, setChecked2] = React.useState([])
  const [pristine2, setPristine2] = React.useState(true)
  // const filterList = () => {
  //   setStudentsList(checked)
  // }


  // React.useEffect(()=>{
  //   setChecked1([])
  //   setPristine1(true)
  // },[skillsData])

  React.useEffect(()=>{
    if(pristine1 === false){
      setSkillsData([...checked1])
    }
  },[checked1])

  React.useEffect(()=>{
    if(pristine2 === false){
      setSkillsData([...checked2,])
    }
  },[checked2])


  return ( 
    
      <Grid direction='column' container justifyContent="flex-start" className={classes.ListMainContainer}>
        <h5 className={classes.heading2}>Comprensión lectora</h5>
        <div className={classes.listContainer}>
          <List className={classes.root}>
          {comprenSkills.length > 0 && comprenSkills?.map((item, index) => {
              let obj = item.item || item
            return (
              <StudentSelector 
                key={index + "-comprenSkills"}
                obj={obj}
                checked={checked1}
                setChecked={setChecked1}
                setPristine={setPristine1}
              />
            )
          })}
          </List>
        </div>
        <h5 className={classes.heading2}>Socioemocionales</h5>
        <div className={classes.listContainer}>
          <List className={classes.root}>
          {socioSkills.length > 0 && socioSkills?.map((item, index) => {
              let obj = item.item || item
            return (
              <StudentSelector 
                key={index + "-socioSkills"}
                obj={obj}
                checked={checked2}
                setChecked={setChecked2}
                setPristine={setPristine2}
              />
            )
          })}
          </List>
        </div>
      </Grid>
  );
}
 
export default StendentsList;
