import { Button, Grid, makeStyles } from "@material-ui/core";
import React, { useContext, useEffect } from "react";
import DrawerContext from "../../../../../usability/DrawerContext";
import { useOnLoadImages } from "../../../../hooks/useOnLoadImages";
import Loader from "../../../../Loader/Loader";
import Drawer from "../../ReadingTools/Drawer";

const useStyles =  makeStyles (theme =>({
      Contenedor:{
        background:'#E9FBF9',
        width:'100vw',
        overflowX:'clip',
        [theme.breakpoints.down(1161)]:{
            background:'none',
           overflowX:'unset',
        },
        [theme.breakpoints.down(600)]:{
            marginTop: '79.51px',
        }
    },
  ContainerInfoLectura: {
    position: "relative",
    height: "86px",
    [theme.breakpoints.down(502)]:{
        height: "43px",
    },
    background: "#76D4C3",
    "& .BarraInfoLectura": {
      height: "86px",
      background: `Url(https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Barras/Azul.png)`,
        [theme.breakpoints.down(502)]:{
            height: "43px",
        },
    },
    "& .InfoLectura": {
      position: "absolute",
      height: "86px",
      width: "219px",
      boxSizing: "border-box",
      padding: "17.2px 0",
      background: "#FAFAFA",
      borderRadius: "50px",
      boxShadow: "2px 2px 2px 2px #00000029",
      zIndex: "4",
        [theme.breakpoints.down(502)]:{
            height: "43px",
            width: "109px",
            padding: "8.6px 0",
            borderRadius: "25px",
        },
    },
    "& .InfoLectura:before": {
      position: "absolute",
      display: "flex",
      boxSizing: "border-box",
      padding: "0 0 0 10px",
      justifyContent: "center",
      alignItems: "center",
      height: "39.88px",
      width: "39.88px",
      borderRadius: "50%",
      content: '""',
      boxShadow: "3px 0px .5px 0px #00000029",
      background: "#FAFAFA",
      left: "90px",
      top: "65px",
      transform: "rotate(0.25turn)",
      [theme.breakpoints.down(502)]:{
        left: "45px",
        top: "30px",
        height: "20.88px",
        width: "20.88px",
        },
    },
    "& .InfoLectura:after": {
      content: '""',
      height: "15px",
      width: "15px",
      borderTop: "2px solid #20A698",
      borderLeft: "2px solid #20A698",
      position: "absolute",
      bottom: "-10px",
      left: "Calc(50% - 8px)",
      transform: "rotate(0.625turn)",
      [theme.breakpoints.down(502)]:{
        height: "10px",
        width: "10px",
        left: "Calc(50% - 4.5px)",
        bottom: "-3px",
        borderTop: "1px solid #20A698",
        borderLeft: "1px solid #20A698",
        },
    },
    "& .Sesion": {
      borderLeft: "2px solid #D8D8D8",
    },
    "& .Dato": {
      margin: "0 0 5px 0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#20A698",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        margin: "0 0 2.5px 0",
        },
    },
    "& .Caracter": {
      margin: "0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#000000",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        },
    },
       '& .Regresar':{
        color: '#F2624F',
        border: '#F2624F 1px solid',
        height: '30px',
        width:'100px',
        borderRadius: '20px',
        letterSpacing: '0.14px',
        textTransform: 'none',
        background:'#FEFAF1',
        top:'calc(50% - 15px)',
        left:'5%',
        zIndex:4,
        [theme.breakpoints.down(502)]: {
          font:'bold 10px lato',
          width: "60px",
        },
      },
  },
    ContenedorLectura:{
        background:'#fff',
        width:'1160px',
        height:'100%',
padding:'60px 113px 35px 168px' ,
        
        transform: (props) =>
        props.scale > 1 ? `scale(${props.scale}) translate(0px, 10%)` : `none`,
        [theme.breakpoints.down(1161)]:{
            width:'502px',
            padding:'60px 0 0',
        },
        [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
        '& .ContainerDerechos':{
            bottom:'80px',
            [theme.breakpoints.down(1161)]:{
                display:'none',
            }
        },
        '& .Derechos':{
            textAlign:"center",
            margin:"0px",
            font:" 15px lato",
            color:"#717476",
        },
        '& .Escritorio':{
            display:'block',
            [theme.breakpoints.down(1161)]:{
                display:'none'
            },
        },
        '& .Tablet':{
            display:'none',
            [theme.breakpoints.down(1161)]:{
                display:'block'
            },
        },  
    },

    ContainerTitulo:{
        margin:'0 0 46px 0',
        [theme.breakpoints.down(1161)]:{
            margin:'0 0 38px 0',
        },
        '& .Titulo':{
            font:'48px fredoka one',
            letterSpacing: '0px',
            color: '#FC5E9E',
            margin:'0',
            textAlign:'center',
            [theme.breakpoints.down(1161)]:{
                font:'43px fredoka one',
                lineHeight:'52px',
                textAlign:'center',
            },
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            lineHeight : '30px',
            margin:'0 0 36px 0',
            font:'22px lato',
            color:'#000',
            letterSpacing: '0.20px',
            [theme.breakpoints.down(1161)]:{
                font:'18px lato',
                letterSpacing:'.17px',
                lineHeight:'28px',
            },
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },

        '& .TextImg':{
            font:'20px lato',
            color:'#717476',
            margin:'24px 0 0 0',
            letterSpacing:'.19px',
            [theme.breakpoints.down(1161)]:{
                font:'16px lato',
                letterSpacing:'.15x',
            },
            [theme.breakpoints.down(502)]:{
                font:' 11px lato',
                lineHeight :'14px',
                letterSpacing: '0.10px',
            },
        },

        '& .Imagen':{
            [theme.breakpoints.down(1161)]:{
                width:'100%',
            },
        },
        '& .Imagen2':{
            [theme.breakpoints.down(1161)]:{
                width:'48%',
            },
        },



    },
  
    

}));

const El_genio_de_las_matemáticas_modernas = (props) => {

  const {activeStep, setActiveStep, handlePreviewStep, id_actividad} = props

  const handleContinue = () => {
    setActiveStep(activeStep - 1)
    handlePreviewStep()
  }

    const {
    handleSize,
    disabledZoom,
    closeZoom,
    handleZoom,
    scale
  }= useContext(DrawerContext);
  const [loading, setLoading] = React.useState(true);
  const wrapperRef = React.useRef(null);
  const imagesLoaded = useOnLoadImages(wrapperRef);

  const detectSize = () => {
    const lectura = document.getElementById("lectura");
    const newWidth = lectura?.offsetWidth;
    const newHeight = lectura?.offsetHeight;
    setLoading(false);
    if (lectura) handleSize(newHeight, newWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", detectSize);
    return () => {
      handleZoom()
      window.removeEventListener("resize", detectSize);
      closeZoom()
    };
  }, []);

  const classes = useStyles({ disabledZoom,scale });
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
             <Grid container item justifyContent="center" className={classes.ContainerInfoLectura}>
               <Grid container className="BarraInfoLectura">
                    <Button className="Regresar" onClick={handleContinue}>
                        Regresar
                    </Button>
                </Grid>
               <Grid container item className="InfoLectura">
                    <Grid container item direction="column" xs={6} justifyContent="center">
                        <p className="Dato">Grado</p>
                        <p className="Caracter">6</p>
                    </Grid>
                    <Grid container item direction="column" xs={6} justifyContent="center" className="Sesion">
                        <p className="Dato">Sesión</p>
                        <p className="Caracter">26</p>
                    </Grid>
               </Grid>
            </Grid>
{!imagesLoaded && <Loader />}
        <Grid
            container
            item
            className={classes.ContenedorLectura}
            justifyContent="center"
            id="lectura"
            onLoad={detectSize}
            ref={wrapperRef}
        >
<Drawer id_lectura={id_actividad} />
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >
                        El genio de las <br/>matemáticas modernas
                    </h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura ">
                            ¿Alguna vez te has puesto a contar el número de días festivos que conoces? ¿Cómo 
                            crees que se determinan? Hay de todo tipo. Por ejemplo, en América se celebran las 
                            independencias de los países, aunque en diferentes fechas. Seguro en tu país hay 
                            varias festividades locales que se celebran anualmente. <br/><br/>
                            
                            Hay otro tipo de celebraciones que son más genéricas, como el Día de las Lenguas de 
                            Señas, que se celebra el 23 de septiembre, o el Día del Matemático, el 31 de mayo. Y 
                            es justo sobre esta fecha que vas a aprender. <br/><br/>
                            
                            Pero antes de conocer a la persona por la cual se eligió la fecha, observa las 
                            imágenes.
                        </p>
                    </Grid>
                    <Grid container >
                        <Grid container justifyContent='space-between'>
                            <img className="Imagen2" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion26/1.png" alt="Tarjeta" />
                            <img className="Imagen2" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion26/2.png" alt="Tarjeta" />
                        </Grid>
                        <Grid container justifyContent='center' >
                            <p className='TextImg'> Teselados.</p>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                            Ambas representan teselados que se forman al aplicar a ciertas figuras algunos tipos 
                            de transformaciones, como rotar, reflejar, trasladar y la combinación de las últimas 
                            dos. Todo esto con el objetivo de crear figuras que cubran completa y perfectamente 
                            una superficie plana, es decir, que no queden espacios entre ellas ni que las figuras se 
                            encimen entre sí. <br/><br/>
                            
                            A estas transformaciones que sirven para formar teselados se les llama <em>grupo de 
                            simetrías</em>. Justamente la palabra <em>grupo</em> es la que introduce a Évariste Galois, el 
                            creador de la teoría de grupos, y por quien se designó el 31 de mayo como el Día del 
                            Matemático. <br/><br/>
                            
                            Galois fue un matemático francés que nació el 25 de octubre de 1811. Desde los 14 
                            años inició su interés por las matemáticas. Tal era su pasión que intentó ingresar a 
                            una de las escuelas más prestigiosas de su tiempo y de la actualidad: L’École 
                            polytechnique (Escuela Politécnica). Pero no lo logró. <br/><br/>
                            
                            El rechazo no fue impedimento, por lo que continuó sus estudios en la École normale 
                            supérieure (Escuela Normal Superior). Ese lugar le dio el impulso necesario para que 
                            a los 18 años publicara su primer escrito. <br/><br/>
                            
                            Galois siguió con su preparación a la par que encontraba soluciones a problemas que 
                            ningún otro matemático antes que él había logrado. Así, estaba revolucionando el 
                            estudio y aproximación hacia las matemáticas. Eso lo incitó a contactar a expertos 
                            renombrados para que revisaran su trabajo, pero no lo comprendían, por lo que fue 
                            rechazado nuevamente. <br/><br/>
                            
                            Por desgracia, la vida de Galois no fue fácil debido a sus ideales políticos en una 
                            época muy conflictiva en Francia. Debido a ello pasó una época en la cárcel. Su 
                            situación empeoró cuando recuperó su libertad en 1832, a los 21 años, pues fue 
                            retado a un duelo de pistolas, aunque el motivo aún no es claro. <br/><br/>
                            
                            Al ser una época en la que negarse a ese tipo de enfrentamientos no era una opción, 
                            aceptó, pero estaba seguro de que no ganaría. Así, una noche antes del duelo se 
                            fraguó el hecho que pasaría a los libros de historia. Conocedor de su probable 
                            muerte, escribió un documento en el que plasmó las ideas que sentaron las bases de 
                            la teoría de grupos, que hasta ese momento sus colegas no habían entendido. <br/><br/>
                            
                            Desgraciadamente calculó bien sus probabilidades de fracaso en el duelo: murió el 
                            31 de mayo de 1832 a los 21 años.
                        </p>
                    </Grid>
                    <Grid container >
                        <Grid container justifyContent='space-between'>
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion26/3.png" alt="Tarjeta" />
                            
                        </Grid>
                        <Grid container justifyContent='center' >
                            <p className='TextImg'> Recreación ficticia del duelo de Évariste Galois.</p>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                            Pasaron 14 años de esa tragedia para que su documento fuera publicado, pues 
                            durante ese tiempo se siguió estudiando lo que escribió la noche previa a su muerte. 
                            Con las contribuciones de Galois surgió el álgebra moderna y la teoría de grupos, la 
                            cual actualmente es una de las ramas más complejas de las matemáticas. <br/><br/>
                            
                            Algunas de sus aplicaciones van desde soluciones del cubo Rubik, códigos binarios 
                            (los que se utilizan principalmente en las computadoras que constan sólo de dos 
                            números, 0 y 1), hasta usos en física, astrofísica y química. <br/><br/>
                            
                            ¿Te imaginas hasta dónde pudo haber llegado Galois si no hubiese muerto a los 21 
                            años? En menos de seis años descubrió y desarrolló una de las ramas más complejas 
                            de la matemática moderna. Es por su genialidad y aportaciones a las matemáticas 
                            que se eligió su aniversario luctuoso como el Día del Matemático.
                        </p>
                    </Grid>
                </Grid>
        </Grid>
        <Grid container item style={{ marginTop: 80 }}></Grid>
    </Grid>
    );
}
 
export default El_genio_de_las_matemáticas_modernas;
