import React from "react";

const MapButtons = ({
  loadActivitiesList,
  activitiesList,
  grade,
  ButtonMap,
  ButtonMapS1,
  ButtonMapS56,
  ButtonMapS60,
}) => {
  const activity1 = activitiesList?.find((activity) => activity.sesion === 1);
  const activity56 = activitiesList?.find((activity) => activity.sesion === 56);
  const activity60 = activitiesList?.find((activity) => activity.sesion === 60);

  const activites = activitiesList?.filter(
    (activity) =>
      activity.sesion !== 1 && activity.sesion !== 56 && activity.sesion !== 60
  );
  return (
    <>
      {/* Actividad de bienvenida, incluye dos videos.* */}
      {activity1 ? (
        <ButtonMapS1 data={activity1} actualizeList={loadActivitiesList} />
      ) : null}

      {/* Actividades regulares */}
      {activites.map((data) => (
        <ButtonMap
          key={data.id_actividad}
          data={data}
          actualizeList={loadActivitiesList}
          abbreviatedGrade={""}
          grade={grade}
        />
      ))}

      {/* Actividades distintas: 56, 60 *Ambas llevan intervencion y frame* */}

      {activity56 ? (
        <ButtonMapS56
          data={activity56}
          actualizeList={loadActivitiesList}
          abbreviatedGrade={""}
          grade={grade}
        />
      ) : null}

      {activity60 ? (
        <ButtonMapS60
          data={activity60}
          actualizeList={loadActivitiesList}
          abbreviatedGrade={""}
          grade={grade}
        />
      ) : null}
    </>
  );
};

export default MapButtons;
