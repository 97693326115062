import axios from "axios";
import currentEnvironment from "./CONST";
const reportes = axios.create({
  baseURL: currentEnvironment.main.baseURL,
  timeout: 60000,
  headers: currentEnvironment.main.headers,
});

axios.defaults.headers.common["Authorization"] =
  "Bearer " + localStorage.auth_token;
reportes.interceptors.request.use(
  async (config) => {
    const accessToken = localStorage.getItem("token");
    config.headers = {
      "x-token": `${accessToken}`,
    };
    return config;
  },
  (error) => {
    throw error;
    // Promise.reject(error)
  }
);

export default reportes;
