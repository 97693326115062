import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
    ContenedorLectura:{
        width:'502px',
        [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
    },
    ContainerTitulo:{
        margin:'0 0 38px 0',
        '& .Titulo':{
            letterSpacing: '0px',
            color: '#8C6FCB',
            margin:'0',
            font:'43px fredoka one',
            lineHeight:'52px',
            textAlign:'center',
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            margin:'0 0 36px 0 ',
            color:'#000',
            font:'18px lato',
            letterSpacing:'.17px',
            lineHeight:'28px',
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .SubTitulo':{
            alignSelf:'center',
            color:'#EA699D',
            margin:'0 ',
            font:'bold 26px lato',
            lineHeight:'28px',
            [theme.breakpoints.down(502)]:{
                font:'22px Fredoka One',
                lineHeight:'24px',
            },
        },
        '& .TextImg':{
            margin:'24px 0 0 0',
            alignSelf:'flex-start',
            color:'#717476',
            font:'16px lato',
            letterSpacing:'.15px',
            lineHeight:'25px',
            [theme.breakpoints.down(502)]:{
                font:' 11px lato',
                lineHeight :'14px',
                letterSpacing: '0.10px',
            },
        },
        '& .Imagen':{
            width:'100%',
        },

    },

}));

const Narraciones_orales_como_esencia_de_la_cultura = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo " >
                        Narraciones orales
                        como esencia<br/>de la cultura
                    </h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                            La ficción es un recurso que el ser humano ha encontrado para contar historias que 
                            no son verdaderas o que se basan en otras realidades para crear mundos alrededor 
                            de ellas. Entre otras cosas, este tipo de narración ha servido para intentar entender 
                            los fenómenos de la naturaleza y explicar el origen del mundo y el universo. <br/><br/>

                            Hay ejemplos de ficción en muchas partes de tu vida cotidiana: en los cuentos que te 
                            gustan, en las series que ves o en las películas que disfrutas. La ventaja de ello es que, 
                            sin importar lo que disfrutes, tienes acceso a ellas de una u otra manera. Pero éstas 
                            tienen un pasado muy lejano, incluso antes de que se pudieran escribir. La ficción 
                            proviene de la tradición oral, cuyas historias fueron transmitidas durante siglos de 
                            generación en generación <br/><br/>

                            Las narraciones, ya sean reales o de ficción, han sido fundamentales para la
                            humanidad, pues explican la forma de pensar de los pueblos, sus creencias y la 
                            manera en que conciben el mundo. Aunque no todas son parecidas o tienen la misma 
                            función. Por ello, a continuación, conocerás los cuatro grupos en que históricamente 
                            se han dividido y cuáles son sus características. <br/><br/>
                            
                            <h3 className='SubTitulo'>Mito</h3><br/>
                            
                            Es un tipo de narración de corte fantástico y sobrenatural. Usualmente es
                            protagonizado por dioses, semidioses y héroes, por lo cual no hay un momento 
                            temporal en la historia cronológica de la humanidad en que se puedan situar sus 
                            acontecimientos. <br/><br/>
                            
                            Aunque los mitos son atemporales, el origen de su invención es milenario, pues en la 
                            Antigüedad ayudaban a explicar el origen de todo aquello que la humanidad no 
                            comprendía, como los símbolos y las fuerzas de la naturaleza. Debido a estas 
                            características, contribuyeron a construir la visión de varias de las religiones del 
                            mundo. <br/><br/>
                            
                            <h3 className='SubTitulo'>Leyenda</h3><br/>
                            
                            Es un tipo de ficción que generalmente se fundamenta en algún hecho real, el cual 
                            con el tiempo se transformó en fantasía. Su objetivo es explicar o exaltar hechos 
                            inexplicables o admirables.
                        </p>
                    </Grid>
                    <Grid container  sm={12}>
                        <Grid container>
                            <img  className='Imagen' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion56/1.png"  alt="Tarjeta" />
                        </Grid>
                        <Grid>
                            <p className='TextImg'>En las culturas antiguas, los dioses y héroes han sido los protagonistas de la creación del 
                            mundo, así como de grandes hazañas.</p>
                        </Grid>
                    </Grid>
                 
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                            Las leyendas se crean y transmiten principalmente dentro comunidades específicas, 
                            pues tienen el objetivo de narrar hechos cuyo contexto es común a las personas de
                            algún lugar geográfico. Su origen y conservación ha dependido históricamente de la 
                            tradición oral. <br/><br/>
                            
                            <h3 className='SubTitulo'>Cuentos</h3><br/>

                             Este tipo de historias son las más comunes en la actualidad, pues no dependen más 
                             que de la imaginación de quien las crea. Pueden estar basadas en hechos reales o 
                             fantásticos y su principal objetivo es el entretenimiento. <br/><br/>
                             
                             Las características principales de los cuentos son sus personajes y los ambientes en 
                             los que se desarrollan. Por ejemplo, tú estás acostumbrado a este tipo de 
                             narraciones, pues las películas, series y libros que disfrutas son cuentos que se
                            hicieron más complejos conforme se iban construyendo y luego derivaron en 
                            novelas, libretos de cine, etcétera.<br/><br/>
                            
                            <h3 className='SubTitulo'>Narraciones didácticas</h3><br/>
                            
                            Existen muchas narraciones cuya finalidad es la educación. Estos textos, también
                            llamados <em>didácticos</em>, casi siempre son protagonizados por animales o cosas que 
                            simbolizan valores o virtudes. Están diseñados, principalmente, para que los más 
                            pequeños de una comunidad entiendan cómo ser mejores individuos mediante el 
                            entretenimiento que causan estas historias. Uno de los mejores ejemplos son las 
                            fábulas.

                        </p>
                    </Grid>
                    <Grid container  sm={12}>
                            <Grid container>
                                <img  className='Imagen' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion56/2.png"  alt="Tarjeta" />
                            </Grid>
                            <Grid>
                                <p className='TextImg'>Esopo escribió la fábula de la liebre y la tortuga. Este autor griego del siglo IV a. n. e. es el
                                primer y quizá máximo exponente del género de la fábula.</p>
                            </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                            Muchas de las historias que ahora conoces y que dan una interpretación al mundo 
                            vienen de la tradición oral. Lamentablemente, con el paso de los siglos, la práctica de
                            transmitir los conocimientos de generación en generación de manera verbal ha 
                            tendido a desaparecer, principalmente debido a la invención de la escritura, cuya
                            historia se remonta a la antigua Mesopotamia alrededor del año 4 000 a. n. e. <br/><br/>
                             
                            Sobre todo, con el avance de la alfabetización y la tecnología, el ser humano ha 
                            decidido contar y almacenar sus recuerdos para ser leídos, ya sea en libros o 
                            dispositivos electrónicos. Desde luego, no es algo negativo, sino simplemente una 
                            evolución de la narración. <br/><br/>
                            
                            Sin embargo, debido a la importancia de conservar el registro de las culturas y su 
                            manera de entender el universo, la Organización de las Naciones Unidas para la 
                            Educación, la Ciencia y la Cultura (<span style={{fontVariant: "all-small-caps"}}>UNESCO</span>, por sus siglas en inglés) declaró las 
                            tradiciones orales como parte del Patrimonio Intangible de la Humanidad. Por este
                            motivo, se han sumado los esfuerzos de diversos investigadores para conservar 
                            narraciones en grabaciones orales de pobladores originarios de diversas culturas.<br/><br/>

                            Así que recuerda: si alguna vez escuchas a alguna persona mayor contarte alguna 
                            narración, ponle atención, porque lo que realmente estás escuchando es una forma 
                            milenaria de transmitir conocimientos.
                        </p>
                    </Grid>
                </Grid>

        </Grid>
    </Grid>
    );
}
 
export default Narraciones_orales_como_esencia_de_la_cultura;
