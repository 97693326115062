import { Button, Grid, makeStyles } from "@material-ui/core";
import React, { useContext, useEffect } from "react";
import DrawerContext from "../../../../../usability/DrawerContext";
import { useOnLoadImages } from "../../../../hooks/useOnLoadImages";
import Loader from "../../../../Loader/Loader";
import Drawer from "../../ReadingTools/Drawer";

const useStyles =  makeStyles (theme =>({
      Contenedor:{
        background:'#E9FBF9',
        width:'100vw',
        overflowX:'clip',
        [theme.breakpoints.down(1161)]:{
            background:'none',
           overflowX:'unset',
        },
        [theme.breakpoints.down(600)]:{
            marginTop: '79.51px',
        }
    },
  ContainerInfoLectura: {
    position: "relative",
    height: "86px",
    [theme.breakpoints.down(502)]:{
        height: "43px",
    },
    background: "#76D4C3",
    "& .BarraInfoLectura": {
      height: "86px",
      background: `Url(https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Barras/Azul.png)`,
        [theme.breakpoints.down(502)]:{
            height: "43px",
        },
    },
    "& .InfoLectura": {
      position: "absolute",
      height: "86px",
      width: "219px",
      boxSizing: "border-box",
      padding: "17.2px 0",
      background: "#FAFAFA",
      borderRadius: "50px",
      boxShadow: "2px 2px 2px 2px #00000029",
      zIndex: "4",
        [theme.breakpoints.down(502)]:{
            height: "43px",
            width: "109px",
            padding: "8.6px 0",
            borderRadius: "25px",
        },
    },
    "& .InfoLectura:before": {
      position: "absolute",
      display: "flex",
      boxSizing: "border-box",
      padding: "0 0 0 10px",
      justifyContent: "center",
      alignItems: "center",
      height: "39.88px",
      width: "39.88px",
      borderRadius: "50%",
      content: '""',
      boxShadow: "3px 0px .5px 0px #00000029",
      background: "#FAFAFA",
      left: "90px",
      top: "65px",
      transform: "rotate(0.25turn)",
      [theme.breakpoints.down(502)]:{
        left: "45px",
        top: "30px",
        height: "20.88px",
        width: "20.88px",
        },
    },
    "& .InfoLectura:after": {
      content: '""',
      height: "15px",
      width: "15px",
      borderTop: "2px solid #20A698",
      borderLeft: "2px solid #20A698",
      position: "absolute",
      bottom: "-10px",
      left: "Calc(50% - 8px)",
      transform: "rotate(0.625turn)",
      [theme.breakpoints.down(502)]:{
        height: "10px",
        width: "10px",
        left: "Calc(50% - 4.5px)",
        bottom: "-3px",
        borderTop: "1px solid #20A698",
        borderLeft: "1px solid #20A698",
        },
    },
    "& .Sesion": {
      borderLeft: "2px solid #D8D8D8",
    },
    "& .Dato": {
      margin: "0 0 5px 0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#20A698",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        margin: "0 0 2.5px 0",
        },
    },
    "& .Caracter": {
      margin: "0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#000000",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        },
    },
       '& .Regresar':{
        color: '#F2624F',
        border: '#F2624F 1px solid',
        height: '30px',
        width:'100px',
        borderRadius: '20px',
        letterSpacing: '0.14px',
        textTransform: 'none',
        background:'#FEFAF1',
        top:'calc(50% - 15px)',
        left:'5%',
        zIndex:4,
        [theme.breakpoints.down(502)]: {
          font:'bold 10px lato',
          width: "60px",
        },
      },
  },
    ContenedorLectura:{
        background:'#fff',
        width:'1160px',
        height:'100%',
        padding:'57px 168px 35px' ,
        
        transform: (props) =>
        props.scale > 1 ? `scale(${props.scale}) translate(0px, 10%)` : `none`,
        [theme.breakpoints.down(1161)]:{
            width:'502px',
            padding:'60px 0 0',
        },
        [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
        '& .ContainerDerechos':{
            bottom:'80px',
            [theme.breakpoints.down(1161)]:{
                display:'none',
            }
        },
        '& .Derechos':{
            textAlign:"center",
            margin:"0px",
            font:" 15px lato",
            color:"#717476",
        },
        '& .Escritorio':{
            display:'block',
            [theme.breakpoints.down(1161)]:{
                display:'none'
            },
        },
        '& .Tablet':{
            display:'none',
            [theme.breakpoints.down(1161)]:{
                display:'block'
            },
        }, 
    },
    ContainerTitulo:{
        margin:'0 0 45px 0',
        [theme.breakpoints.down(1161)]:{
            margin:'0 0 38px 0',
        },
        '& .Titulo':{
            font:'48px fredoka one',
            letterSpacing: '0px',
            color: '#FF0000',
            margin:'0',
            textAlign:'center',
            [theme.breakpoints.down(1161)]:{
                font:'43px fredoka one',
                lineHeight:'52px',
                textAlign:'center',
            },
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },

    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            lineHeight : '30px',
            margin:'0 0 36px 0 ',
            font:'22px lato',
            color:'#000',
            letterSpacing: '0.20px',
            [theme.breakpoints.down(1161)]:{
                font:'18px lato',
                letterSpacing:'.17px',
                lineHeight:'28px',
            },
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .SubTitulo':{
            font:'bold 30px lato',
            margin:'0',
            letterSpacing:'.28px',
            lineHeight:'30px',
            color:'#0093FF',
            [theme.breakpoints.down(1161)]:{
                font:'bold 26px lato',
                letterSpacing:'.24px',
                lineHeight:'28px',
            },
            [theme.breakpoints.down(502)]:{
                font:'bold 22px lato',
                lineHeight:'24px',
            },
            [theme.breakpoints.down(350)]:{
                font:'bold 18px lato',
                lineHeight:'24px',
            },
        },
        '& .TextImg':{
            margin:'24px 0 0 0',
            font:'20px lato',
            alignSelf:'flex-start',
            color:'#717476',
            letterSpacing:'.19px',
            [theme.breakpoints.down(1161)]:{
                font:'16px lato',
                letterSpacing:'0px',
                lineHeight:'25px',
            },
            [theme.breakpoints.down(502)]:{
                font:' 11px lato',
                lineHeight :'14px',
                letterSpacing: '0.10px',
            },
        },
        '& .Imagen':{
            [theme.breakpoints.down(1161)]:{
                width:'100%'
            },
        },
       

    },

}));

const La_fotografía_profesional = (props) => {

  const {activeStep, setActiveStep, handlePreviewStep, id_actividad} = props

  const handleContinue = () => {
    setActiveStep(activeStep - 1)
    handlePreviewStep()
  }

    const {
    handleSize,
    disabledZoom,
    closeZoom,
    handleZoom,
    scale
  }= useContext(DrawerContext);
  const [loading, setLoading] = React.useState(true);
  const wrapperRef = React.useRef(null);
  const imagesLoaded = useOnLoadImages(wrapperRef);

  const detectSize = () => {
    const lectura = document.getElementById("lectura");
    const newWidth = lectura?.offsetWidth;
    const newHeight = lectura?.offsetHeight;
    setLoading(false);
    if (lectura) handleSize(newHeight, newWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", detectSize);
    return () => {
      handleZoom()
      window.removeEventListener("resize", detectSize);
      closeZoom()
    };
  }, []);

  const classes = useStyles({ disabledZoom,scale });
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
             <Grid container item justifyContent="center" className={classes.ContainerInfoLectura}>
               <Grid container className="BarraInfoLectura">
                    <Button className="Regresar" onClick={handleContinue}>
                        Regresar
                    </Button>
                </Grid>
               <Grid container item className="InfoLectura">
                    <Grid container item direction="column" xs={6} justifyContent="center">
                        <p className="Dato">Grado</p>
                        <p className="Caracter">6</p>
                    </Grid>
                    <Grid container item direction="column" xs={6} justifyContent="center" className="Sesion">
                        <p className="Dato">Sesión</p>
                        <p className="Caracter">39</p>
                    </Grid>
               </Grid>
            </Grid>
{!imagesLoaded && <Loader />}
        <Grid
            container
            item
            className={classes.ContenedorLectura}
            justifyContent="center"
            id="lectura"
            onLoad={detectSize}
            ref={wrapperRef}
        >
<Drawer id_lectura={id_actividad} />
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >
                        La fotografía profesional
                    </h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                           <h3 className='SubTitulo '>La fotografía profesional</h3><br/>

                            En la actualidad, el mundo está lleno de fotografías. Basta que abras cualquier red 
                            social para que veas que gran parte de las publicaciones tienen como elemento 
                            principal una imagen. Esto ha sido posible gracias al ingenio y a la tecnología, pues 
                            con un simple toque en la pantalla de un teléfono celular cualquiera puede obtener 
                            una fotografía. Pero ¿te has puesto a pensar en cuál es el origen? <br/><br/>
                            
                            Los inicios del proceso para obtener una fotografía se remontan al siglo <span style={{fontVariant: "all-small-caps"}}>XVI</span>, aunque 
                            no fue sino hasta 1826 cuando se tomó por primera vez una, en Francia. El 
                            responsable fue Joseph-Nicéphore Niépce, quien capturó un paisaje desde su 
                            ventana. De hecho, la fotografía se conoce como “Punto de vista desde la ventana en 
                            Le Gras”. En aquel tiempo, las imágenes se recubrían con un aceite similar al petróleo 
                            y se colocaban sobre una placa de plata. Después de varios días, las fotografías se 
                            revelaban.
                        </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <Grid container>
                            <img className='Imagen' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion39/1.png"  alt="Tarjeta" />
                        </Grid>
                        <Grid container justifyContent='center'>
                            <p className='TextImg'>Ilustración realista de la primera fotografía capturada en la historia.</p>
                        </Grid>
                           
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                            Con el transcurso de los años, la industria se profesionalizó. Por un lado, las 
                            compañías crearon paulatinamente mejores cámaras, incluso algunas muy costosas. 
                            Por otro, se refinaron procesos, de manera que las personas tuvieron que aprender a 
                            usarlas. Ahora, con el avance de la tecnología, las cámaras están al alcance de todos 
                            aquellos quienes puedan adquirir un teléfono móvil. Eso no quiere de decir que todos 
                            sean expertos. Por ello, vale la pena diferenciar entre dos tipos: profesionales y 
                            aficionados. <br/><br/>
                            
                            Un aficionado es cualquier persona que toma fotografías para guardarlas o 
                            compartirlas, sin una intención artística o preparación para ello. Por su parte, un 
                            profesional es aquel que estudia y se especializa para capturar las mejores tomas 
                            posibles. Además, conoce minuciosamente el funcionamiento de una cámara, las 
                            técnicas de revelado, impresión, edición, entre otras cosas. <br/><br/>
                            
                            Aunque estos dos perfiles de fotógrafos obtienen distintos tipos de resultados al 
                            realizar una fotografía, lo cierto es que ambos sienten la fascinación por guardar 
                            imágenes y apreciarlas, pues, como dice el dicho, una imagen vale más que mil 
                            palabras. <br/><br/>
                            
                            A continuación, conoce algunos recursos materiales y características personales que 
                            poseen o han desarrollado los fotógrafos profesionales. <br/><br/>
                            
                            <h3 className='SubTitulo'>Equipo</h3><br/>
                            
                            Existen cámaras de muchos tipos, modelos, precios y marcas. Para elegir una, sólo es 
                            necesario asesorarse y adaptarse a un presupuesto. Incluso, en la actualidad muchos 
                            de los fotógrafos profesionales utilizan cámaras menos sofisticadas, como las de los 
                            dispositivos móviles. Lo importante es tener la cámara correcta para el tipo de 
                            fotografía que se quiere obtener. <br/><br/>
                            
                            <h3 className='SubTitulo'>Habilidad artística</h3><br/> 
                            
                            Los fotógrafos son artistas que deben poseer creatividad para encontrar maneras de 
                            utilizar en su favor todos los elementos visuales de las imágenes. Necesitan aprender 
                            a apreciar los colores, la intensidad de la luz y la unión de los objetos. En una 
                            fotografía se cuentan y se transmiten emociones, historias y situaciones diferentes. 
                            Por ello, por ejemplo, esta técnica artística se puede utilizar en la publicidad, en la
                            que se necesita transmitir un mensaje, o también para narrar un suceso histórico. <br/><br/>
                             
                            <h3 className='SubTitulo'>Habilidad técnica </h3><br/>
                            
                            La iluminación es fundamental para obtener una buena fotografía, por lo que los 
                            artistas utilizan luces artificiales, <em>flashes</em>, telas, etcétera, para manipular o modificar 
                            la luz que requieren capturar. No es lo mismo una fotografía muy brillante que una 
                            más oscura, pues comunican mensajes y emociones diferentes.


                        </p>
                    </Grid>
                    <Grid container sm={12} justifyContent='center'>
                            <Grid container>
                                <img  className='Imagen' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion39/2.png"  alt="Tarjeta" />
                            </Grid>
                            <Grid containter justifyContent='center'>
                                <p className='TextImg'>Una buena iluminación permite que las fotografías sean más expresivas.</p>
                            </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                            Otra habilidad técnica es el revelado. Existen al menos dos formas de realizarlo: la
                            primera es la manera tradicional, en la que los artistas emplean y manipulan químicos 
                            para obtener sus fotografías. La segunda es la manera tecnológica, con las 
                            impresiones digitales que cada vez son más sofisticadas. Según la opción que se elija, 
                            se afecta el color e incluso la textura de una fotografía. <br/><br/>
                            
                            Con la tecnología y la era digital, una habilidad imprescindible es la edición de 
                            fotografías digitales. Gracias a programas de cómputo, se puede alterar imágenes
                            con distintos fines, desde corregir imperfecciones de la toma hasta la manipulación 
                            total de los elementos. <br/><br/>
                            
                            <h3 className='SubTitulo'>Habilidades de comunicación</h3><br/>
                            
                            Se podría pensar que gran parte de la labor del fotógrafo es individual, pero no es así. 
                            Cuando se es profesional, parte del trabajo es conocer las necesidades de otras 
                            personas. Por ejemplo, piensa de nuevo en la publicidad. En esos casos, los fotógrafos 
                            deben escuchar y proponer ideas que vayan acordes con los intereses de las 
                            personas o empresas con quienes trabajan. Por esta razón es que la fotografía suele 
                            ubicarse continuamente entre lo artístico y lo comercial.<br/><br/>
                            
                            En conclusión, la fotografía está al alcance de la mayoría de las personas, gracias a la 
                            tecnología. Pero dependerá de cada una de ellas si decide convertirse en profesional 
                            o si simplemente disfruta de ella como aficionado.
                        </p>
                    </Grid>
                </Grid>
        </Grid>
        <Grid container item style={{ marginTop: 80}} ></Grid>
    </Grid>
    );
}
 
export default La_fotografía_profesional;
