import { Button, Grid, makeStyles } from "@material-ui/core";
import React, { useContext, useEffect } from "react";
import DrawerContext from "../../../../../usability/DrawerContext";
import { useOnLoadImages } from "../../../../hooks/useOnLoadImages";
import Loader from "../../../../Loader/Loader";
import Drawer from "../../ReadingTools/Drawer";

const useStyles =  makeStyles (theme =>({
     Contenedor:{
        background:'#E9FBF9',
        width:'100vw',
        overflowX:'clip',
        [theme.breakpoints.down(1161)]:{
            background:'none',
           overflowX:'unset',
        },
        [theme.breakpoints.down(600)]:{
            marginTop: '79.51px',
        }
    },
  ContainerInfoLectura: {
    position: "relative",
    height: "86px",
    [theme.breakpoints.down(502)]:{
        height: "43px",
    },
    background: "#76D4C3",
    "& .BarraInfoLectura": {
      height: "86px",
      background: `Url(https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Barras/Azul.png)`,
        [theme.breakpoints.down(502)]:{
            height: "43px",
        },
    },
    "& .InfoLectura": {
      position: "absolute",
      height: "86px",
      width: "219px",
      boxSizing: "border-box",
      padding: "17.2px 0",
      background: "#FAFAFA",
      borderRadius: "50px",
      boxShadow: "2px 2px 2px 2px #00000029",
      zIndex: "4",
        [theme.breakpoints.down(502)]:{
            height: "43px",
            width: "109px",
            padding: "8.6px 0",
            borderRadius: "25px",
        },
    },
    "& .InfoLectura:before": {
      position: "absolute",
      display: "flex",
      boxSizing: "border-box",
      padding: "0 0 0 10px",
      justifyContent: "center",
      alignItems: "center",
      height: "39.88px",
      width: "39.88px",
      borderRadius: "50%",
      content: '""',
      boxShadow: "3px 0px .5px 0px #00000029",
      background: "#FAFAFA",
      left: "90px",
      top: "65px",
      transform: "rotate(0.25turn)",
      [theme.breakpoints.down(502)]:{
        left: "45px",
        top: "30px",
        height: "20.88px",
        width: "20.88px",
        },
    },
    "& .InfoLectura:after": {
      content: '""',
      height: "15px",
      width: "15px",
      borderTop: "2px solid #20A698",
      borderLeft: "2px solid #20A698",
      position: "absolute",
      bottom: "-10px",
      left: "Calc(50% - 8px)",
      transform: "rotate(0.625turn)",
      [theme.breakpoints.down(502)]:{
        height: "10px",
        width: "10px",
        left: "Calc(50% - 4.5px)",
        bottom: "-3px",
        borderTop: "1px solid #20A698",
        borderLeft: "1px solid #20A698",
        },
    },
    "& .Sesion": {
      borderLeft: "2px solid #D8D8D8",
    },
    "& .Dato": {
      margin: "0 0 5px 0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#20A698",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        margin: "0 0 2.5px 0",
        },
    },
    "& .Caracter": {
      margin: "0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#000000",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        },
    },
       '& .Regresar':{
        color: '#F2624F',
        border: '#F2624F 1px solid',
        height: '30px',
        width:'100px',
        borderRadius: '20px',
        letterSpacing: '0.14px',
        textTransform: 'none',
        background:'#FEFAF1',
        top:'calc(50% - 15px)',
        left:'5%',
        zIndex:4,
        [theme.breakpoints.down(502)]: {
          font:'bold 10px lato',
          width: "60px",
        },
      },
  },
    ContenedorLectura:{
        background:'#fff',
        width:'1160px',
        height:'100%',
        padding:'60px 168px 35px ',
        
        transform: (props) =>
        props.scale > 1 ? `scale(${props.scale}) translate(0px, 10%)` : `none`,
        [theme.breakpoints.down(1161)]:{
            width:'502px',
            padding:'60px 0 0 0',
          },
                [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },

        '& .Escritorio':{
            display:'block',
            [theme.breakpoints.down(1161)]:{
                display:'none'
            },
        },
        '& .Tablet':{
            display:'none',
            [theme.breakpoints.down(1161)]:{
                display:'block'
            },
        }
    },
    ContainerTitulo:{
        margin:'0 0 34px 0',
        [theme.breakpoints.down(1161)]:{
            margin:'0 0 34px 0',
        },
        '& .Titulo':{
            font:'48px fredoka one',
            letterSpacing: '0px',
            lineHeight:'58px',
            color: '#FF2B2B',
            margin:'0 0 5px 0',
            textAlign:'center',
            [theme.breakpoints.down(1161)]:{
                font:'43px fredoka one',
                lineHeight:'52px',
            },
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
        '& .Titulo2':{
            font:'bold 30px lato',
            letterSpacing: '.28px',
            lineHeight:'30px',
            color: '#FF2B2B',
            margin:'0',
            textAlign:'center',
            [theme.breakpoints.down(1161)]:{
                font:'bold 26px lato',
                letterSpacing: '.24px',
                lineHeight:'28px',
            },
            [theme.breakpoints.down(502)]:{
                font:'bold 22px lato',
                lineHeight:'24px',
            },
            [theme.breakpoints.down(350)]:{
                font:'bold 18px lato',
                lineHeight:'24px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        position:'relative',
        '& .Lectura':{
            lineHeight : '30px',
            margin:'0',
            font:'22px lato',
            color:'#000',
            letterSpacing: '0.20px',
            [theme.breakpoints.down(1161)]:{
                font:'18px lato',
                lineHeight :'28px',
                letterSpacing: '0.17px',
            },
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .Imagen':{
            [theme.breakpoints.down(1161)]:{
                width:'768px',
            },
            [theme.breakpoints.down(768)]:{
                width:'100%',
            },
        },  
      
    }
}));

const Pablo_y_el_intraterrestre_Cap_1 = (props) => {

  const {activeStep, setActiveStep, handlePreviewStep, id_actividad} = props
  
  const handleContinue = () => {
    setActiveStep(activeStep - 1)
    handlePreviewStep()
  }

    const {
      handleSize,
    disabledZoom,
    closeZoom,
    handleZoom,
    scale
  }= useContext(DrawerContext);
  const [loading, setLoading] = React.useState(true);
  const wrapperRef = React.useRef(null);
  const imagesLoaded = useOnLoadImages(wrapperRef);

  const detectSize = () => {
    const lectura = document.getElementById("lectura");
    const newWidth = lectura?.offsetWidth;
    const newHeight = lectura?.offsetHeight;
    setLoading(false);
    if (lectura) handleSize(newHeight, newWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", detectSize);
    return () => {
      handleZoom()
      window.removeEventListener("resize", detectSize);
      closeZoom()
    };
  }, []);

  const classes = useStyles({ disabledZoom,scale });
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
            <Grid container item justifyContent="center" className={classes.ContainerInfoLectura}>
            <Grid container className="BarraInfoLectura">
                <Button className="Regresar" onClick={handleContinue}>
                    Regresar
                </Button>
              </Grid>
              <Grid container item className="InfoLectura">
                <Grid container item direction="column" xs={6} justifyContent="center">
                    <p className="Dato">Grado</p>
                    <p className="Caracter">5</p>
                </Grid>
                <Grid container item direction="column" xs={6} justifyContent="center" className="Sesion">
                    <p className="Dato">Sesión</p>
                    <p className="Caracter">23</p>
                </Grid>
              </Grid>
            </Grid>
            {!imagesLoaded && <Loader />}
        <Grid
            container
            item
            className={classes.ContenedorLectura}
            justifyContent="center"
            id="lectura"
            onLoad={detectSize}
            ref={wrapperRef}
        >
<Drawer id_lectura={id_actividad} />
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                        <h2 className="Titulo">Pablo y el intraterrestre</h2>
                        <h3 className='Titulo2'>Fragmento 1 (capítulo 1)</h3>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                       <p className='Lectura'>
                        Él habría seguido jugando sin problema. El lodo no le molestaba y podía ignorar la 
                        lluvia. De hecho, los rayos le parecían geniales. Se dio cuenta de que los jugadores 
                        más cobardes se asustaban con los truenos, así que podría aprovechar para colarse 
                        en la defensa sin hacer faltas. Pero los adultos decidieron que era peligroso jugar en 
                        medio de la tormenta y el juego se suspendió. Ahora, la pelota recargada entre las 
                        piernas le lastimaba las rodillas.<br/><br/>
                        
                        Para empeorarlo todo, en ese preciso instante el comentarista de deportes de la 
                        radio anunció que el equipo favorito de Pablo, los Lobos Esteparios, se había 
                        quedado fuera de la copa. Él no quería apoyar a un montón de perdedores. Mañana 
                        en la escuela iba a ser la comidilla del resto de los Calaveras, su grupo de amigos.<br/><br/>
                        
                        Su padre apagó la radio de un sonoro botonazo y el coche se quedó más quieto y 
                        caluroso que nunca. Mirando alrededor, Pablo confirmó que él era el único que no
                        tenía nada en qué distraerse. Su madre leía los exámenes de sus alumnos; de vez en 
                        cuando, molesta, negaba con la cabeza y tachaba las hojas amarillas con su lápiz rojo. 
                        Definitivamente no era un buen momento para platicar con ella. Tampoco habría sido 
                        buena idea tratar de conversar con su padre, pues estaba sumido en el conflicto con 
                        los embotellamientos: tocaba el claxon y gritaba a otros conductores. <br/><br/>
                        
                        Sin duda, la peor de todos, la más odiosa esa tarde era Mariana. Desde hacía horas su 
                        hermana adolescente se reía sin parar de todo y de nada, como una ardilla loca, sin 
                        que Pablo pudiera entender los chistes, perdida en el brillo azul de su amadísimo 
                        celular nuevo. <br/><br/>
                        
                        De vez en cuando, Mariana decía algo intrigante, frases como “¡Es lo máximo!”, 
                        “¡Mira esto!”, “¡No puede ser!”, como si la cosa más especial del mundo estuviera en 
                        esa pantallita rectangular. Pablo volteó emocionado la primera vez, pero luego se dio 
                        cuenta de que Mariana hablaba por celular con un tal Pepe. <br/><br/>
                        
                        “El teléfono inteligente la está convirtiendo en una mensa”, se dijo a sí mismo. Le 
                        habría gustado tener alguien con quien compartir su chiste. <br/><br/>
                        
                        Pero Pablo no iba a quedarse sentado y aburrido todo el viaje de regreso. Se le 
                        ocurrió que podía abrir la ventana y sacar la cabeza […] <br/><br/>
                        
                        Apretó el botón del cinturón, tratando de disimular lo más posible el clic, 
                        controlando con el dedo gordo el botón rojo para que no saltara demasiado. Cuando 
                        vio que nadie reaccionó a esa operación, lenta y cuidadosamente deslizó el cinturón 
                        hacia atrás sosteniéndolo con su mano para que no hiciera ese ruido de bzbzbz al 
                        retraerse. <br/><br/>
                        
                        ¡Todo fue un éxito! Libre al fin, Pablo oprimió el botón de la ventanilla. Le preocupaba 
                        que lo descubrieran por el ruido incontenible de la ventana bajando 
                        automáticamente, pero todos los demás pasajeros viajaban como zombis con la cara 
                        perdida en la pantalla, en su trabajo o en la carretera.<br/><br/>
                        
                        […] Fascinado, disfrutando sacar la cabeza por la ventana y perderse un poco entre el 
                        miedo y el misterio, Pablo por fin pudo distraerse del calor y la frustración […] De un 
                        zarpazo, Mariana tomó a Pablo por la camiseta y lo jaló dentro del coche. Lo arrastró 
                        como si Pablo hubiera estado a punto de saltar de un edificio gigantesco […]<br/><br/>

                        ―Te vas a caer —lo reprendió Mariana―. ¡Ma, Pablo se quitó el cinturón!<br/><br/>

                        […]<br/><br/>

                        ―No es justo ―se quejó Pablo poniéndose el cinturón de nuevo―. Soy el único que 
                        no tiene nada que hacer […]<br/><br/>

                        —Tienes tus cómics, la tarea, colores y un montón de juguetes en la mochila. Tienes 
                        muchas cosas que no tienen otros niños. Sé más agradecido, Pablo.<br/><br/>

                        Los cómics no podía leerlos, la tarea no era algo para divertirse y no se podía colorear 
                        en el auto. “¿Y con quién voy a jugar con los juguetes? Ya no soy un bebé para jugar 
                        solo.”<br/><br/>

                        […]<br/><br/>

                        Furioso, Pablo tomó la pelota que estaba a su lado y la arrojó con todas sus fuerzas. 
                        La pelota alcanzó a escurrirse por el espacio entre los asientos de adelante y pegó
                        por dentro en el parabrisas, obligando a su padre a dar un terrible volantazo y orillar 
                        el coche. Las llantas rechinaron, el automóvil se zarandeó y todos gritaron asustados. 
                        Su papá se estacionó rápidamente en el acotamiento. El susto no había pasado a
                        mayores. Aun así, Pablo supo que era hombre muerto. ¡Estaría castigado hasta 
                        después del apocalipsis!
                       </p>                   
                    </Grid>
                    <Grid container justifyContent='center' >
                        <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion23/1.png" alt="Tarjeta" />
                    </Grid>
                </Grid>
        </Grid>
        <Grid container item style={{ marginTop: 80}} ></Grid>
    </Grid>
    );
}
 
export default Pablo_y_el_intraterrestre_Cap_1;
