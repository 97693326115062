import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
     Contenedor:{
        background:'#E9FBF9',
        width:'100vw',
        overflowX:'clip',
        [theme.breakpoints.down(1161)]:{
            background:'none',
           overflowX:'unset',
        },
        [theme.breakpoints.down(600)]:{
            marginTop: '79.51px',
        }
    },
  ContainerInfoLectura: {
    position: "relative",
    height: "86px",
    [theme.breakpoints.down(502)]:{
        height: "43px",
    },
    background: "#76D4C3",
    "& .BarraInfoLectura": {
      height: "86px",
      background: `Url(https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Barras/Azul.png)`,
        [theme.breakpoints.down(502)]:{
            height: "43px",
        },
    },
    "& .InfoLectura": {
      position: "absolute",
      height: "86px",
      width: "219px",
      boxSizing: "border-box",
      padding: "17.2px 0",
      background: "#FAFAFA",
      borderRadius: "50px",
      boxShadow: "2px 2px 2px 2px #00000029",
      zIndex: "4",
        [theme.breakpoints.down(502)]:{
            height: "43px",
            width: "109px",
            padding: "8.6px 0",
            borderRadius: "25px",
        },
    },
    "& .InfoLectura:before": {
      position: "absolute",
      display: "flex",
      boxSizing: "border-box",
      padding: "0 0 0 10px",
      justifyContent: "center",
      alignItems: "center",
      height: "39.88px",
      width: "39.88px",
      borderRadius: "50%",
      content: '""',
      boxShadow: "3px 0px .5px 0px #00000029",
      background: "#FAFAFA",
      left: "90px",
      top: "65px",
      transform: "rotate(0.25turn)",
      [theme.breakpoints.down(502)]:{
        left: "45px",
        top: "30px",
        height: "20.88px",
        width: "20.88px",
        },
    },
    "& .InfoLectura:after": {
      content: '""',
      height: "15px",
      width: "15px",
      borderTop: "2px solid #20A698",
      borderLeft: "2px solid #20A698",
      position: "absolute",
      bottom: "-10px",
      left: "Calc(50% - 8px)",
      transform: "rotate(0.625turn)",
      [theme.breakpoints.down(502)]:{
        height: "10px",
        width: "10px",
        left: "Calc(50% - 4.5px)",
        bottom: "-3px",
        borderTop: "1px solid #20A698",
        borderLeft: "1px solid #20A698",
        },
    },
    "& .Sesion": {
      borderLeft: "2px solid #D8D8D8",
    },
    "& .Dato": {
      margin: "0 0 5px 0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#20A698",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        margin: "0 0 2.5px 0",
        },
    },
    "& .Caracter": {
      margin: "0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#000000",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        },
    },   },
    ContenedorLectura:{
        background:'#fff',
        width:'1160px',
        height:'100%',
        padding:'60px 168px 35px ',
        [theme.breakpoints.down(1161)]:{
            width:'502px',
            padding:'60px 0 0 0',
          },
        [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
        '& .ContainerDerechos':{
            bottom:'80px',
            [theme.breakpoints.down(1161)]:{
                display:'none',
            },
        },
        '& .Derechos':{
            textAlign:"center",
            margin:"0px",
            font:" 15px lato",
            color:"#717476",
        },
        '& .Escritorio':{
            display:'block',
            [theme.breakpoints.down(1161)]:{
                display:'none'
            },
        },
        '& .Tablet':{
            display:'none',
            [theme.breakpoints.down(1161)]:{
                display:'block'
            },
        }
    },
    ContainerTitulo:{
        margin:'0 0 34px 0',
        [theme.breakpoints.down(1161)]:{
            margin:'0 0 34px 0',
        },
        '& .Titulo':{
            font:'48px fredoka one',
            letterSpacing: '0px',
            lineHeight:'58px',
            color: '#FC5E9E',
            margin:'0',
            textAlign:'center',
            [theme.breakpoints.down(1161)]:{
                font:'43px fredoka one',
                lineHeight:'52px',
            },
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            lineHeight : '30px',
            margin:'0 0 36px 0',
            font:'22px lato',
            color:'#000',
            letterSpacing: '0.20px',
            [theme.breakpoints.down(1161)]:{
                font:'18px lato',
                lineHeight :'28px',
                letterSpacing: '0.17px',
            },
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .SubTitulo':{
            font:'bold 30px lato',
            color:'#5C48FF',
            letterSpacing:'.28px',
            lineHeight:'30px',
            margin:'0',
            [theme.breakpoints.down(1161)]:{
                lineHeight :'28px',
                font:'bold 26px lato',
                letterSpacing: '0.24px',
            },
            [theme.breakpoints.down(502)]:{
                font:'bold 18px lato',
                lineHeight :'24px',
                letterSpacing: '0.21px',
            },
        },
        '& .SubTitulo2':{
            font:' 23px Fredoka One',
            color:'#FC5E9E',
            lineHeight:'20px',
            margin:'15px 0 0 0',
            [theme.breakpoints.down(1161)]:{
                lineHeight :'12px',
                font:' 14px Fredoka One',
            },
            [theme.breakpoints.down(502)]:{
                lineHeight :'12px',
                font:' 12px Fredoka One',
                margin:'5px 0 0 0',
            },
            
        },
        '& .Maquina':{
            position:'relative',
        },
        '& .Lectura2':{
            margin:'0',
            font:'18px lato',
            lineHeight : '20px',
            color:'#FFFFFF',
            [theme.breakpoints.down(1161)]:{
                font:'11px lato',
                lineHeight :'12px',
            },
            [theme.breakpoints.down(1161)]:{
                font:'8px lato',
                lineHeight :'12px',
            },
        },
        '& .Pos':{
            position:'absolute',
            left:'27.30%',
            top:'7.65%',
            [theme.breakpoints.down(502)]:{
                top:'2.5%',
            },
        },

        '& .Imagen':{
            [theme.breakpoints.down(1161)]:{
                width:'100%',
            },
        },  
      
    }
}));

const Esto_es_vida = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
             <Grid container item justifyContent="center" className={classes.ContainerInfoLectura}>
               <Grid container className="BarraInfoLectura"></Grid>
               <Grid container item className="InfoLectura">
                    <Grid container item direction="column" xs={6} justifyContent="center">
                        <p className="Dato">Grado</p>
                        <p className="Caracter">5</p>
                    </Grid>
                    <Grid container item direction="column" xs={6} justifyContent="center" className="Sesion">
                        <p className="Dato">Sesión</p>
                        <p className="Caracter">16</p>
                    </Grid>
               </Grid>
            </Grid>

            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                        <h2 className="Titulo">¿Esto es vida?</h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                       <p className='Lectura'>
                       <h3 className='SubTitulo'>Cron conoce la Tierra</h3><br/>
                       
                        Cuando llegó al planeta del que debía llevar muestras, notó que lo habían mandado a 
                        otro volcán gigante. Cron trabajaba para el GUSLAR, un grupo de científicos que 
                        le pedían muestras de los planetas y épocas distantes. Con su enorme biblioteca 
                        de muestras del pasado, el GUSLAR despertaba bastantes sospechas. Cron había 
                        escuchado varias teorías conspirativas al respecto, pero no le interesaban. 
                        A él le asignaban un planeta y un tiempo. Él tomaba las muestras y regresaba con 
                        ellas. Hacer preguntas no era parte de su trabajo, y si no le iban pagar por eso, 
                        ¿qué caso tenía? <br/><br/>
                        
                        La primera vez que Cron viajó en el tiempo la experiencia le 
                        pareció apasionante. Por eso había elegido ese trabajo. Pero se había vuelto 
                        repetitivo y triste. Intentando volver a emocionarse, Cron miró los controles 
                        de la nave:
                       </p>                   
                    </Grid>
                    <Grid container justifyContent='center' className='Maquina'> 
                        <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion16/1.png" alt="Tarjeta" />
                        <p className='Pos Lectura2'>
                            <h3 className='SubTitulo2'>Lugar:</h3> 
                            Vía Láctea. Brazo de Orión. 
                            <h3 className='SubTitulo2'>Datos del lugar:</h3> 
                            Sistema solar de formación <br/>reciente, tercer planeta a partir  <br/>del Sol. 
                            <h3 className='SubTitulo2'>Tiempo:</h3> 
                            4 500 millones de años antes  <br/>de los primeros animales civilizados.
                        </p>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                       <p className='Lectura'>
                        —¡Qué calor hace! Los sensores indican 999.99 grados Celsius —se quejó Cron. 
                        Luego agregó con cierto orgullo—: ¡Los materiales con los que está hecha 
                        mi preciosa nave resisten esto y más! <br/><br/>
                        
                        Anotó en la bitácora los datos indispensables. ¿Visibilidad? Poca: mucho 
                        humo. ¿Atmósfera? Cron tomó una muestra de aire y la pasó por el analizador: 
                        no hay oxígeno. Planeta muerto, para variar. <br/><br/>
                        
                        En ese momento un asteroide golpeó el planeta, que estremeció, como por un 
                        terremoto. Desde su nave Cron pudo ver cómo se derretía el asteroide. <br/><br/>
                        
                        —A esta temperatura ¿qué podría mantenerse sólido? <br/><br/>
                        
                        Este espectáculo le recordó a Cron sus primeros viajes, así que se 
                        permitió hacer una pequeña excepción. Aunque las reglas le prohibían 
                        explorar otras épocas de ese planeta, volvió a calibrar el tiempo en los 
                        controles y aceleró.
                       </p>                   
                    </Grid>
                    <Grid container justifyContent='center' className='Maquina'>
                        <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion16/1.png" alt="Tarjeta" />
                        <p className='Pos Lectura2'>
                            <h3 className='SubTitulo2'>Lugar:</h3> 
                            Vía Láctea. Brazo de Orión. 
                            <h3 className='SubTitulo2'>Datos del lugar:</h3> 
                            Sistema solar de formación <br/>reciente, tercer planeta a partir  <br/>del Sol. 
                            <h3 className='SubTitulo2'>Tiempo:</h3> 
                            3 500 millones de años antes  <br/>de los primeros animales civilizados.
                        </p>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                       <p className='Lectura'>
                        ¡Qué diferencia! Los sensores marcaban una temperatura agradable. El 
                        paisaje estaba despejado. El océano cubría casi todo. Alegre, Cron tomó 
                        muestras agua y aire. “Es curiosidad profesional”, se repetía, tratando de
                        entender qué llamaba tanto su atención. <br/><br/>
                        
                        Aún no había oxígeno en la atmósfera, pero la muestra de agua tibia tenía 
                        una apariencia peculiar. Cron decidió ponerla bajo el microscopio y observó 
                        diminutos seres que se movían ágilmente de un lado a otro, parecidos a las 
                        bacterias que él ya conocía.<br/><br/>
                        
                        —El medio en el que viven es como un gran caldo del que absorben todos los 
                        nutrientes —musitaba Cron, mientras interpretaba el análisis que le daba la 
                        nave—. Por eso pueden sobrevivir sin oxígeno. <br/><br/>
                        
                        ¡Sorprendente! Esto que era tan simple y tan distinto ¡ya era vida! <br/><br/>
                        
                        <h3 className='SubTitulo'>Cron vuelve a la Tierra</h3><br/>
                        
                        Cron siguió trabajando para el GUSLAR y visitó otros lugares de la galaxia durante 
                        varios años, pero nunca más buscó vida en ellos. Había sido una experiencia única y 
                        la recordaba cuando se sentía solo.<br/><br/>
                        
                        Un día, cuando ya estaba retirado, la curiosidad lo hizo regresar al mismo sitio.
                         Quería ver cómo estaban esos seres diminutos.
                       </p>                   
                    </Grid>
                    <Grid container justifyContent='center' className='Maquina'>
                        <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion16/1.png" alt="Tarjeta" />
                        <p className='Lectura2 Pos '> 
                            <h3 className='SubTitulo2'>Lugar:</h3> 
                            Vía Láctea. Brazo de Orión. 
                            <h3 className='SubTitulo2'>Datos del lugar:</h3> 
                            Sistema solar, tercer planeta a <br/>partir del Sol. 
                            <h3 className='SubTitulo2'>Tiempo:</h3> 
                            2 500 millones de años antes de <br/>los primeros animales civilizados
                        </p>
                        
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                       <p className='Lectura'>
                        A primera vista todo estaba igual, pero los niveles de oxígeno habían aumentado. Era 
                        como si el planeta lo recibiera. Cron abrió la escotilla y respiró. <br/><br/>
                        
                        Como la parte sólida del planeta aún no manifestaba ningún rastro de vida, Cron 
                        tomó una muestra de agua y regresó a su nave. Bajo el microscopio, observó que las 
                        diminutas bacterias habían incrementado su número. <br/><br/>
                        
                        —Con un lugar tan agradable para vivir —pensó— y con tanto alimento para crecer, 
                        ¿cómo no habrían de prosperar? <br/><br/>
                        
                        Cron colocó la muestra en el analizador. Resultó que era menos rica en nutrientes. 
                        Por esta razón, algunos de los seres diminutos habían tenido que encontrar la forma 
                        de producir su propio alimento: la fotosíntesis. Como resultado de ese proceso ahora 
                        había oxígeno. <br/><br/>
                        
                        Cron sintió una gran emoción ante este descubrimiento. Reajustó el tiempo varias 
                        veces. Iba viendo surgir miles de formas de vida y, llevado por la fascinación, quería 
                        ver más. En el último ajuste vio al ser humano aparecer y modificarlo todo. <br/><br/>

                        Ese día regresó a casa triste, reflexionando: <br/><br/>
                        
                        —¡Cómo cambiaron las cosas! ¡Cuánto bien le hacían a la vida estas 
                        bacterias que eran tan simples!
                       </p>                   
                    </Grid>
                </Grid>

                

            <Grid container item xs={12} justifyContent="center" className="ContainerDerechos">
                <p className="Derechos">
                    © 2022. Todos los derechos reservados, Pearson, Circuito de lectores.
                </p>                
            </Grid>
        </Grid>
    </Grid>
    );
}
 
export default Esto_es_vida;
