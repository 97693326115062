import { Grid,makeStyles}              from '@material-ui/core';
import React                          from 'react';

const useStyle =  makeStyles (theme =>({
   ContenedorLectura:{
        height:'100%',
        width:'100%',
          [theme.breakpoints.down(769)]:{
            width:'350px',
          },
    },
    Parrafo1:{
        position:'relative',
        '& .Imagen':{
            width:'100%'
        },

        
    },


}));

const Corrientes_marinas = () => {

    const classes = useStyle()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>

            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion3/F.png" alt="Tarjeta" />
                </Grid>
        </Grid>
    </Grid>
    );
}
 
export default Corrientes_marinas;
