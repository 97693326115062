import React               from "react";
import {Grid, makeStyles}                 from '@material-ui/core';

const useStyles = makeStyles(theme =>({
  questionContainer:{
    width: "100%",
    height:"100%",
  },
  reading: {
    width: "98%",
    font: "22px lato",
    letterSpacing:'0',
    margin:"14px 10px 14px 0",
    [theme.breakpoints.down(1370)]:{
      font: "500 18px lato",
      width: "100%",
      margin:"10px 10px 10px 0"
    },
    [theme.breakpoints.down(1310)]:{
      font: "500 16px lato",
      // width: "80%",
      margin:"10px 5px 10px 0"
    },
    [theme.breakpoints.down(925)]:{
      font: "500 14px lato",
      // width: "70%",
      margin:"6px 0 6px 0"
    },
    [theme.breakpoints.down(426)]:{
      font: "500 12px lato",
      // width: "70%",
      margin:"6px 0 6px 5%"
    },
  },
  imgStyle: {
    margin: '5%',
    alignItems: "center",
    width:'40%',
    height:'40%',
    // [theme.breakpoints.down(1400)]:{
    //   width:'85%px',
    //   height:'85%px',
    // },
  },
}));

const Question = (props) => {
  const classes = useStyles()
  const { picture1, picture2, picture3, picture4 } = props


  return(
    <Grid container className={classes.questionContainer}>
      <p 
        // dangerouslySetInnerHTML={{__html: `${step+1}. ${pregunta.replace( '\n', '<br/><br/>')}`}}
        className={classes.reading}>
        2. ¿En cuál de las actividades que se muestran en las ilustraciones son indispensables las matemáticas?
      </p>
      <br/>
      <img alt="img" src={picture1} className={classes.imgStyle}/>
      <img alt="img" src={picture2} className={classes.imgStyle}/>
      <img alt="img" src={picture3} className={classes.imgStyle}/>
      <img alt="img" src={picture4} className={classes.imgStyle}/>
    </Grid>
  )

}

export default Question;
