import { Button, Grid, makeStyles } from "@material-ui/core";
import React, { useContext, useEffect } from "react";
import DrawerContext from "../../../../../usability/DrawerContext";
import { useOnLoadImages } from "../../../../hooks/useOnLoadImages";
import Loader from "../../../../Loader/Loader";
import Drawer from "../../ReadingTools/Drawer";

const useStyles =  makeStyles (theme =>({
      Contenedor:{
        background:'#E9FBF9',
        width:'100vw',
        overflowX:'clip',
        [theme.breakpoints.down(1161)]:{
            background:'none',
           overflowX:'unset',
        },
        [theme.breakpoints.down(600)]:{
            marginTop: '79.51px',
        }
    },
    ContainerInfoLectura: {
    position: "relative",
    height: "86px",
    background: "#F6664D",
    [theme.breakpoints.down(502)]:{
        height: "43px",
    },
    "& .BarraInfoLectura": {
      height: "86px",
      background: `Url(https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Barras/Naranja.png)`,
      [theme.breakpoints.down(502)]:{
        height: "43px",
    },
    },
    "& .InfoLectura": {
      position: "absolute",
      height: "86px",
      width: "219px",
      boxSizing: "border-box",
      padding: "17.2px 0",
      background: "#FAFAFA",
      borderRadius: "50px",
      boxShadow: "2px 2px 2px 2px #00000029",
      zIndex: "4",
      [theme.breakpoints.down(502)]:{
        height: "43px",
        width: "109px",
        padding: "8.6px 0",
        borderRadius: "25px",
    },
    },
    "& .InfoLectura:before": {
      position: "absolute",
      display: "flex",
      boxSizing: "border-box",
      padding: "0 0 0 10px",
      justifyContent: "center",
      alignItems: "center",
      height: "39.88px",
      width: "39.88px",
      borderRadius: "50%",
      content: '""',
      boxShadow: "3px 0px .5px 0px #00000029",
      background: "#FAFAFA",
      left: "90px",
      top: "65px",
      transform: "rotate(0.25turn)",
      [theme.breakpoints.down(502)]:{
        left: "45px",
        top: "30px",
        height: "20.88px",
        width: "20.88px",
        },
    },
    "& .InfoLectura:after": {
      content: '""',
      height: "15px",
      width: "15px",
      borderTop: "2px solid #F6664D",
      borderLeft: "2px solid #F6664D",
      position: "absolute",
      bottom: "-10px",
      left: "Calc(50% - 8px)",
      transform: "rotate(0.625turn)",
      [theme.breakpoints.down(502)]:{
        height: "10px",
        width: "10px",
        left: "Calc(50% - 4.5px)",
        bottom: "-3px",
        borderTop: "1px solid #20A698",
        borderLeft: "1px solid #20A698",
        },
    },
    "& .Sesion": {
      borderLeft: "2px solid #D8D8D8",
    },
    "& .Dato": {
      margin: "0 0 5px 0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#F6664D",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        margin: "0 0 2.5px 0",
        },
    },
    "& .Caracter": {
      margin: "0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#000000",
    },
       '& .Regresar':{
        color: '#F2624F',
        border: '#F2624F 1px solid',
        height: '30px',
        width:'100px',
        borderRadius: '20px',
        letterSpacing: '0.14px',
        textTransform: 'none',
        background:'#FEFAF1',
        top:'calc(50% - 15px)',
        left:'5%',
        zIndex:4,
        [theme.breakpoints.down(502)]: {
          font:'bold 10px lato',
          width: "60px",
        },
      },
  },
    ContenedorLectura:{
        background:'#fff',
        width:'1160px',
        height:'100%',
        padding:'60px 168px 35px ',
        
        transform: (props) =>
        props.scale > 1 ? `scale(${props.scale}) translate(0px, 10%)` : `none`,
        [theme.breakpoints.down(1161)]:{
            width:'502px',
            padding:'60px 0 0 0',
          },
        [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },

        '& .Escritorio':{
            display:'flex',
            [theme.breakpoints.down(1161)]:{
                display:'none'
            },
        },
        '& .Tablet':{
            display:'none',
            [theme.breakpoints.down(1161)]:{
                display:'flex'
            },
        }
    },
    ContainerTitulo:{
        margin:'0 0 34px 0',
        [theme.breakpoints.down(1161)]:{
            margin:'0 0 34px 0',
        },
        '& .Titulo':{
            letterSpacing: '0px',
            lineHeight:'58px',
            color: '#156A8D',
            margin:'0',
            textAlign:'center',
            font:'48px fredoka one',
            [theme.breakpoints.down(1161)]:{
                font:'43px fredoka one',
                lineHeight:'52px',
            },
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            margin:'0',
            color:'#000',
            font:'22px lato',
            letterSpacing: '0.21px',
            lineHeight : '30px',
            [theme.breakpoints.down(1161)]:{
                font:'18px lato',
                lineHeight :'28px',
                letterSpacing: '0.17px',
            },
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .ul':{
            margin:'0 0 6px 0',
            padding:'0 0 0 19px',
            color:'#FF0027',
            [theme.breakpoints.down(502)]:{
                margin:'0 0 31px 0',
            },
        },
        '& .li':{
            margin:'0 0 30px 0',
            color:'#FF0027',
            [theme.breakpoints.down(1161)]:{
                margin:'0 0 5px 0',
            },
        },
        '& .TextImg':{
            color:'#717476',
            margin:'24px 0 0 0',
            font:'20px lato',
            letterSpacing:'.19px',
            lineHeight:'30px',
            [theme.breakpoints.down(1161)]:{
                font:' 16px lato',
                lineHeight :'25px',
                letterSpacing: '0.15px',
            },
            [theme.breakpoints.down(502)]:{
                font:' 11px lato',
                lineHeight :'14px',
                letterSpacing: '0.10px',
            },
        },
        "& .SubTitulo": {
            color: "#1A20AF",
            margin: "0",
            letterSpacing: ".24px",
            lineHeight: "28px",
            font: "bold 26px lato",
            [theme.breakpoints.down(1161)]: {
              font: "bold 22px lato",
              lineHeight: "24px",
              letterSpacing: "0.21px",
            },
            [theme.breakpoints.down(502)]: {
              font: "bold 18px lato",
              lineHeight: "20px",
              letterSpacing: "0.16px",
            },
        },
        '& .Rojo':{
            color:'#FF0027',
        },
        '& .Imagen':{
            [theme.breakpoints.down(1161)]:{
                width:'100%',
            },
        },  
      
    },

}));

const La_celebración_panamericana_del_deporte = (props) => {

  const {activeStep, setActiveStep, handlePreviewStep, id_actividad} = props
  
  const handleContinue = () => {
    setActiveStep(activeStep - 1)
    handlePreviewStep()
  }

  const {
    handleSize,
    disabledZoom,
    closeZoom,
    handleZoom,
    scale
  }= useContext(DrawerContext);
  const [loading, setLoading] = React.useState(true);
  const wrapperRef = React.useRef(null);
  const imagesLoaded = useOnLoadImages(wrapperRef);

  const detectSize = () => {
    const lectura = document.getElementById("lectura");
    const newWidth = lectura?.offsetWidth;
    const newHeight = lectura?.offsetHeight;
    setLoading(false);
    if (lectura) handleSize(newHeight, newWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", detectSize);
    return () => {
      handleZoom()
      window.removeEventListener("resize", detectSize);
      closeZoom()
    };
  }, []);

  const classes = useStyles({ disabledZoom,scale });
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
             <Grid container item justifyContent="center" className={classes.ContainerInfoLectura}>
                <Grid container className="BarraInfoLectura">
                    <Button className="Regresar" onClick={handleContinue}>
                        Regresar
                    </Button>
                </Grid>
               <Grid container item className="InfoLectura">
                    <Grid container item direction="column" xs={6} justifyContent="center">
                        <p className="Dato">Grado</p>
                        <p className="Caracter">4</p>
                    </Grid>
                    <Grid container item direction="column" xs={6} justifyContent="center" className="Sesion">
                        <p className="Dato">Sesión</p>
                        <p className="Caracter">39R</p>
                    </Grid>
               </Grid>
            </Grid>
            {!imagesLoaded && <Loader />}
        <Grid
            container
            item
            className={classes.ContenedorLectura}
            justifyContent="center"
            id="lectura"
            onLoad={detectSize}
            ref={wrapperRef}
        >
<Drawer id_lectura={id_actividad} />
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >
                        La celebración<br className='Tablet'/>  panamericana<br/> del deporte
                    </h2>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container justifyContent='center' xs={12} >
                        <ul className='ul'>
                            <li className='li'>
                                <p className='Lectura'>
                                    La sede de los próximos Juegos Panamericanos será la ciudad de Santiago de Chile.
                                </p>
                            </li>
                            <li className='li'>
                                <p className='Lectura'>
                                    Éste será el primer evento internacional realizado por ese país sudamericano desde 1962.
                                </p>
                            </li>
                            <li className='li'>
                                <p className='Lectura'>
                                    Los organizadores buscan dejar un legado en los espectadores y futuros deportistas de la nación chilena.
                                </p>
                            </li>
                        </ul>
                    </Grid>
                    <Grid container justifyContent="center" sm={12} >
                        <Grid>
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img4to/Sesion39R/1.png" alt="Tarjeta" />
                        </Grid>
                        <Grid>
                            <p className='TextImg'>
                                Los XIX Juegos Panamericanos y Parapanamericanos se llevarán a cabo en Santiago de Chile del 20 de octubre al 26 de noviembre de 2023.
                            </p>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    
                    <Grid container justifyContent="center" xs={12} >
                        <p className="Lectura ">
                            Los Juegos Panamericanos son preparados por la Organización Deportiva 
                            Panamericana (ODEPA), conocida desde 2017 como Panam Sports. La primera 
                            edición fue celebrada en Argentina en 1951. Su objetivo principal es fomentar las 
                            relaciones amistosas entre los países del continente, tal como ocurre a nivel mundial
                            con los Juegos Olímpicos.<br/><br/> 
                            
                            El Comité Paralímpico de las Américas es el encargado de la planeación de los Juegos 
                            Parapanamericanos, los cuales se comenzaron a realizar en 1999, en la misma ciudad 
                            en la que se celebraron los Panamericanos. En los Parapanamericanos participan 
                            atletas del continente americano con discapacidades físicas, visuales e intelectuales. <br/><br/>
                            
                            En 2017 se anunció que Santiago de Chile recibirá la edición XIX, en 2023. Su 
                            candidatura fue presentada en enero de 2017 y compitió contra la postulación de la 
                            ciudad de Buenos Aires. Pero esa localidad se retiró en abril de ese mismo año 
                            porque el costo del evento sobrepasaba su capacidad económica, situación que dejó 
                            a Santiago de Chile como único aspirante. Finalmente, fue seleccionado por la ODEPA 
                            el 4 de noviembre de 2017, durante su LV Asamblea General Ordinaria. <br/><br/>
                            
                            Es la primera vez que Chile será anfitrión de este suceso. En dos ocasiones anteriores 
                            fue nombrado para albergar el evento multideportivo, pero en ambas la Junta Militar 
                            encabezada por Augusto Pinochet decidió suspenderlos. La primera vez fue en 1975, 
                            pero hubo un golpe militar en 1973, por lo que se cancelaron. La segunda ocasión fue 
                            en 1987, pero fue obstruida por la crisis económica y las protestas en contra del 
                            gobierno de Pinochet. Ahora, Chile tiene la oportunidad de ser anfitrión 
                            nuevamente. <br/><br/>
                            
                            Esta tercera oportunidad planea llevar a cabo los Juegos Panamericanos del 20 de
                            octubre al 5 de noviembre. Mientras que a los Parapanamericanos se les asignó los 
                            días entre el 17 y el 26 de noviembre de 2023. La sede principal será la capital, 
                            Santiago. La localidad costera de Viña del Mar albergará el futbol, volibol de playa, 
                            surfeo y triatlón. En total, competirán 41 países del continente americano en 38 
                            deportes y 57 disciplinas. En esta versión de los Panamericanos se incluirán nuevas 
                            disciplinas como el monopatinaje (skateboarding) y la escalada. Por su parte, los 
                            Parapanamericanos estarán divididos en 17 deportes y 18 disciplinas. <br/><br/>
                            
                            La Corporación de Santiago liderará el evento. Declaró que su misión es realizar un 
                            festejo memorable y que busca que los participantes alcancen la excelencia. Su meta 
                            es generar motivación por la práctica deportiva entre la población tanto de su nación 
                            como de todo el continente. 
                        </p>
                    </Grid>
                    
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} >
                    <h3 className='SubTitulo'>Sedes de los Juegos Panamericanos<br/>y Parapanamericanos (1999-2023) </h3><br/> 
                </Grid>

                <Grid container item xs={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container item md={12} lg={6}>
                        <p className="Lectura Esp2">
                            <b className="Rojo">1999.</b> Ciudad de México, México 
                        </p>
                    </Grid>
                    <Grid container item md={12} lg={6}>
                        <p className="Lectura Esp2">
                            <b className="Rojo">2015.</b> Toronto Canadá
                        </p>
                    </Grid>
                    <Grid container item md={12} lg={6}>
                        <p className="Lectura Esp2">
                            <b className="Rojo">2003.</b> Mar de Plata, Argentina 
                        </p>
                    </Grid>
                    <Grid container item md={12} lg={6}>
                        <p className="Lectura Esp2">
                            <b className="Rojo">2019.</b> Lima, Perú
                        </p>
                    </Grid>
                    <Grid container item md={12} lg={6}>
                        <p className="Lectura Esp2">
                           <b className="Rojo">2007.</b> Río de Janeiro, Brasil
                        </p>
                    </Grid>
                    <Grid container item md={12} lg={6}>
                        <p className="Lectura Esp2">
                           <b className="Rojo">2023.</b> Santiago, Chile
                        </p>
                    </Grid>
                    <Grid container item xs={12} >
                        <p className="Lectura Esp2">
                           <b className="Rojo">2011.</b> Guadalajara, México
                        </p>
                    </Grid>
                </Grid>
        </Grid>
        <Grid container item style={{ marginTop: 80}} ></Grid>
    </Grid>
    );
}
 
export default La_celebración_panamericana_del_deporte;
