import React from "react";
import { Grid, makeStyles } from "@material-ui/core";
import AuthContext from "../../../auth/AuthContext";
import UsabilityContext from "../../../usability/UsabilityContext";

const useStyles = makeStyles((theme) => ({
  formControl: {
    maxWidth: "60px",
    overFlowX: "hidden",
  },
  mainContainer: {
    marginLeft: "20px",
    justifyContent: "center",
    "& .input": {
      font: "bold 16px lato",
    },
  },

  select: {
    border: "none",
    backgroundColor: "transparent",
    height: "20px",
    justifyContent: "center",
    font: "bold 16px lato",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "#2EDAC9",
    },
  },
}));

const GradeSelector = (props) => {
  const classes = useStyles();
  const { user } = React.useContext(AuthContext);
  const { handleGroupToEdit, handleGroupData, groupData } =
    React.useContext(UsabilityContext);

  const selectChange = (event) => {
    const id = parseInt(event.target.value);
    handleGroupToEdit(event.target.value);
    const newData = user.grupos.find(
      (grupo) => grupo.id_distribucion_escuela === id
    );
    handleGroupData(newData || user.grupos[0]);
  };

  React.useEffect(() => {
    handleGroupData(
      user.grupos.find(
        (grupo) =>
          grupo?.id_distribucion_escuela ===
          Number(groupData?.id_distribucion_escuela)
      ) || user?.grupos[0]
    );
  }, [user.grupos, groupData]);

  return (
    <Grid item className={classes.mainContainer}>
      {user?.grupos && (
        <select
          className={classes.select}
          onChange={selectChange}
          value={groupData?.id_distribucion_escuela}
        >
          {user?.grupos?.map((grupo, index) => {
            return (
              <option
                style={{
                  font: "bold 16px lato",
                  textAlign: "center",
                  verticalAlign: "middle",
                }}
                value={grupo.id_distribucion_escuela}
                key={index}
              >
                {grupo.id_grado}
                {grupo.grupo}
              </option>
            );
          })}
        </select>
      )}
    </Grid>
  );
};

export default GradeSelector;
