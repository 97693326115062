import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
    ContenedorLectura:{
        width:'502px',
                  [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
    },
    ContainerTitulo:{
        margin:'0 0 46px 0',
        '& .Titulo':{
            letterSpacing: '0px',
            color: '#007B37',
            margin:'0',
            textAlign:'center',
            font:'43px fredoka one',
            lineHeight:'52px',
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            margin:'0 0 36px 0 ',
            color:'#000',
            font:'18px lato',
            lineHeight :'28px',
            letterSpacing: '0.17px',
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .SubTitulo':{
            margin:'0',
            color:'#20A698',
            font:'bold 22px lato',
            lineHeight :'24px',
            letterSpacing: '0.21px',
            [theme.breakpoints.down(502)]:{
                font:'bold 18px lato',
                lineHeight :'20px',
                letterSpacing: '0.16px',
            },
        },
        '& .SubTitulo2':{
            margin:'0',
            color:'#0CB5D6',
            lineHeight :'30px',
            font:' 21px Fredoka One',
            letterSpacing: '0.2px',
            [theme.breakpoints.down(502)]:{
                font:' 18px Fredoka One',
                lineHeight :'24px',
            },
        },
        '& .Imagen':{
            width:'100%',
        },


    },
    

}));

const Juegos_y_juguetes_una_forma_de_aprender = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >
                        Juegos y juguetes: una<br/> forma de aprender
                    </h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                            <h3 className='SubTitulo'>La importancia de jugar </h3><br/>

                            ¿Alguna vez has jugado a algo? Existen muchas opciones para hacerlo: juegos de rol, 
                            rondas, juguetes, tableros y consolas. <br/><br/>

                            Jugar es una actividad importante para la vida. También es un derecho. Mientras 
                            juegas desarrollas diversas habilidades y aprendes valores, como poner y respetar 
                            normas, colaborar, ser creativo(a) y convivir sanamente en grupo. <br/><br/>
                            
                            A través del juego puedes adentrarte en alguna cultura o conocer más acerca de 
                            distintos pueblos del mundo. A continuación te mostraremos algunos juegos 
                            comunes en Latinoamérica. <br/><br/>
                            
                            <h3 className='SubTitulo2'>Juegos de América Latina</h3> <br/>
                            
                            En Perú, los niños shawi del Alto Amazonas fabrican trompos con semillas de ojo 
                            de toro. Mientras tanto, en Satipo usan las hojas secas del plátano como balón, y un 
                            pedazo de madera atado a un hilo les sirve para imitar el rugido del jaguar.
                        </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <Grid container >
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img2do/Sesion32/1.png"  alt="Tarjeta" />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                            En las montañas del norte de México las niñas tarahumaras juegan a la arihueta: 
                            forman dos equipos de cuatro jugadoras y cada uno de ellos tiene una vara y dos aros 
                            hechos con ramas. Ambos equipos corren en un tramo determinado del terreno y 
                            lanzan el aro con la vara lo más lejos posible para llevarlo del punto de salida a la 
                            meta. Es costumbre jugarla en eventos importantes. Incluso hay ocasiones en las que 
                            se hacen apuestas o se otorgan premios.
                        </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <Grid container >
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img2do/Sesion32/2.png"  alt="Tarjeta" />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                    <p className="Lectura">
                        En Colombia existe el cucunubá, conocido también como ratonera. Se juega con 
                        bolas de metal o canicas que deben introducirse en los orificios de un tablero, 
                        marcados con puntaje (el agujero del centro tiene el puntaje más alto). Se considera 
                        como un juego tradicional en peligro de extinción, así que actualmente se ha buscado 
                        que los niños de la zona lo retomen. Por medio de esta actividad conocen su pasado y 
                        estimulan su motricidad, su convivencia y su habilidad para resolver operaciones 
                        matemáticas.
                    </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <Grid container >
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img2do/Sesion32/3.png"  alt="Tarjeta" />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                    <p className="Lectura">
                        Los juegos y juguetes son una excelente opción para convivir, aprender, colaborar y 
                        potenciar la creatividad. <br/><br/>
                        
                        ¿Qué juegos conoces? ¿Sabes a qué jugaban tus familiares mayores cuando eran 
                        niños? ¿Cuáles juegos tradicionales se juegan donde vives? Pregúntales. Quizás 
                        alguno ya esté quedándose en el olvido y tú puedas contribuir a rescatarlo. Además, 
                        pasarás grandes momentos jugando.
                    </p>
                    </Grid>
                </Grid>
        </Grid>
    </Grid>
    );
}
 
export default Juegos_y_juguetes_una_forma_de_aprender;
