import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import axios from "../../../../../services/axios";

import { Button, makeStyles } from "@material-ui/core";
import { Link } from "react-router-dom";

import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import IconButton from "@material-ui/core/IconButton";
import UsabilityContext from "../../../../../usability/UsabilityContext";
import ModalConfirm from "../../../../Modals/ModalConfirm";
import ModalMessage from "../../../../Modals/ModalMessage";
import Loader from "../../../../Loader/Loader";

const useStyles = makeStyles((theme) => ({
  PurpleButton: {
    color: "#FFFFFF",
    backgroundColor: "#9051b1",
    width: "100%",
    maxWidth: "238px",
    border: "none",
    textTransform: "unset",
    cursor: "pointer",
    fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
    fontWeight: "500",
    padding: "6px 16px",
    lineHeight: "1.75",
    transition:
      "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    boxShadow:
      "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
    "&:hover": {
      backgroundColor: "#B965D3 ",
    },
  },
  WitheButton: {
    color: "#9051b1",
    // borderColor: "#9051b1",
    border: "1px solid #9051b1",
    backgroundColor: "#FFFFFF",
    width: "100%",
    maxWidth: "238px",
    textTransform: "unset",
    cursor: "pointer",
    fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
    fontWeight: "500",
    padding: "6px 16px",
    lineHeight: "1.75",
    transition:
      "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    boxShadow:
      "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
    "&:hover": {
      backgroundColor: "#B965D330 ",
    },
  },
}));

const GroupsTable = (props) => {
  const { escuelaId } = props;
  const { handleUserData, search } = React.useContext(UsabilityContext);
  const [confirmationModalOpen, setConfirmationModalOpen] =
    React.useState(false);
  const [rows, setRows] = React.useState([]);
  const classes = useStyles();

  const [loading, setLoading] = React.useState(false);
  const [idUserToDelete, setIdUserToDelete] = React.useState(null);

  const [modalMessageOpen, setModalMessageOpen] = React.useState(false);
  const [confirmChangesResult, setConfirmChangesResult] = React.useState("");

  const columns = [
    {
      field: "name",
      headerName: "Nombre",
      width: 200,
      editable: false,
      flex: 2,
      valueGetter: (params) =>
        `${params?.row?.name || ""} ${params?.row?.lastName || ""}`,
    },
    {
      field: "userName",
      headerName: "Usuario",
      width: 160,
      editable: false,
      flex: 2,
    },
    {
      field: "profile",
      headerName: "Perfil",
      width: 100,
      editable: false,
    },
    {
      field: "date",
      headerName: "Fecha de creación",
      width: 120,
      editable: false,
      flex: 1,
    },
    {
      field: "expire",
      headerName: "Vigencia",
      width: 120,
      editable: false,
      flex: 1,
    },
    {
      field: "class_name",
      headerName: "Clase",
      width: 70,
      editable: false,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "status",
      headerName: "Estatus",
      width: 100,
      editable: false,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "id_user",
      headerName: "ID",
      width: 60,
      editable: false,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "edit",
      headerName: "Editar",
      width: 90,
      editable: false,
      renderCell: (params) => (
        <Link
          to={`/admin/usuarios/${params?.row?.profile}/editar/${params.row?.id_user}`}
          style={{ textDecoration: "none" }}
        >
          <Button
            className={classes.PurpleButton}
            size="small"
            onClick={() => {
              const datatoEdit = rows.find((user) => {
                return user.id_user === Number(params.row?.id_user);
              });

              handleUserData({ ...datatoEdit });
            }}
          >
            Editar
          </Button>
        </Link>
      ),
      headerAlign: "center",
      align: "center",
    },
    {
      field: "delete",
      headerName: "Eliminar",
      width: 120,
      editable: false,
      renderCell: (params) => (
        <IconButton
          color="secondary"
          aria-label="delete"
          onClick={() => {
            handleDelete(params?.row?.id_user);
          }}
        >
          <DeleteForeverIcon />
        </IconButton>
      ),
      headerAlign: "center",
      align: "center",
    },
  ];

  const handleDelete = (id) => {
    setIdUserToDelete(id);
    setConfirmationModalOpen(true);
  };

  const handleCloseMessageModal = () => {
    setModalMessageOpen(!modalMessageOpen);
  };
  const handleCloseConfirmationModal = () => {
    setConfirmationModalOpen(!confirmationModalOpen);
  };

  const confirmDelete = () => {
    setConfirmationModalOpen(false);
    setLoading(true);
    const body = {
      id_usuario: idUserToDelete,
    };
    axios
      .put("/listado/delete/usuario", body)
      .then((response) => {
        if (response.status === 200) {
          setLoading(false);
          setConfirmationModalOpen(false);
          setModalMessageOpen(true);
          setConfirmChangesResult("El usuario ha sido eliminado");
          const listWithoutThisSchool = rows?.filter(
            (user) => user?.id_user !== idUserToDelete
          );
          setRows(listWithoutThisSchool);
        }
      })
      .catch((error) => {
        if (error.response) {
          // Request made and server responded
          const errorData = error.response.data;
          const mensaje =
            errorData?.detalles?.msg ||
            "Ha ocurrido un error al consumir los servicios, inténtelo de nuevo más tarde.";
          setConfirmChangesResult(mensaje);
          setConfirmationModalOpen(false);
          setModalMessageOpen(true);
        } else {
          // Something happened in setting up the request that triggered an Error
          const mensaje =
            "Ha ocurrido un error al consumir los servicios, inténtelo de nuevo más tarde.";
          setConfirmChangesResult(mensaje);
          setConfirmationModalOpen(false);
          setModalMessageOpen(true);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  React.useEffect(() => {
    const body = {
      id_escuela: 1,
      id_usuario: 32,
    };
    axios
      .get(`/listado/usuarios/grupo?id_distribucion_escuela=${escuelaId}`, body)
      .then((response) => {
        if (response.status === 200) {
          // console.log(response.data.resultado.usuarios, "usporgr");
          const usersList = response.data.resultado.usuarios.map((usuario) => {
            // console.log(usuario.id_usuario, usuario.id_distribucion_escuela);
            return {
              name: usuario.nombre,
              lastName: usuario.apellido_paterno,
              secondLastName: usuario.apellido_materno,
              userName: usuario.nombreUsuario,
              profile: usuario.tipoPerfil,
              date: usuario.fechaCreacion,
              expire: usuario.vigencia,
              id_group: usuario.id_grupo,
              group: usuario.grupo,
              // status: usuario.status,
              status: usuario.status,
              // class_name: usuario.nombreClase,
              class_name: usuario.id_distribucion_escuela,
              id_user: usuario.id_usuario,
              id_grade: usuario.id_grado,
              id:
                String(usuario.id_usuario) +
                String(usuario.id_distribucion_escuela),
            };
          });
          setRows(usersList);

          //  dispatch({
          //    type: types.loadActivitiesList,
          //    payload: listaSinRemediales,
          //  });
        }
      })
      .catch((error) => {
        console.log(error, "error");
      });

    return () => {};
  }, [escuelaId]);

  return (
    <div
      style={{
        height: 630,
        width: "100%",
      }}
    >
      <DataGrid
        rows={rows.filter((row) => {
          if (search) {
            return row?.name?.toLowerCase().startsWith(search.toLowerCase());
          } else {
            return row;
          }
        })}
        columns={columns}
        pageSize={10}
        rowsPerPageOptions={[10]}
        disableSelectionOnClick
        disableColumnFilter
        disableColumnMenu
      />

      <ModalConfirm
        open={confirmationModalOpen}
        handleClose={handleCloseConfirmationModal}
        handleConfirm={confirmDelete}
        title="¿Estás seguro de eliminar este usuario? Recuerda que al hacerlo borrarás toda la información vinculada. En caso de ser docente, verifica si tiene más grupos a su cargo."
      />
      <ModalMessage
        open={modalMessageOpen}
        handleClose={handleCloseMessageModal}
        title={confirmChangesResult}
        textOfButton="Aceptar"
      />
      {loading && <Loader />}
    </div>
  );
};

export default GroupsTable;
