import React, {
  useContext,
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from "react";
import { Link } from "react-router-dom";
import { makeStyles, Grid, Button } from "@material-ui/core";
import { DataGrid } from "@mui/x-data-grid";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";

import axios from "../../../../services/axios";
import ContainerAdminPearson from "../ContainerAdminPearson";
import AuthContext from "../../../../auth/AuthContext";

import ModalConfirm from "../../../Modals/ModalConfirm";
import Loader from "../../../Loader/Loader";
import ModalMessage from "../../../Modals/ModalMessage";
import ModalGrupoCargado from "../Modals/ModalGrupoCargado ";

import SelectSchool from "./SelectSchool";
import SelectGradeGroupCargaIndividual from "./SelectGradesCargaIndividual";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    padding: "48px 0 48px 0",
    width: "100vw",
    minHeight: "calc(100vh - 80px)",
    background:
      "linear-gradient(217deg, rgba(255,194,0,0.4962185557816877) 2%, rgba(255,255,255,1) 25%, rgba(255,255,255,0.4962185557816877) 81%, rgba(144,81,177,1) 98%)",
  },
  boxContainer: {
    background: "#fff",
    width: "80%",
    minHeight: "75vh",
    height: "auto",
    borderRadius: "42px",
    padding: "20px",
    alignItems: "center",
    gap: "50px",
    boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.5)",
    [theme.breakpoints.up("md")]: {
      padding: "48px 20px 48px 20px",
    },
  },
  titleContainer: {
    // margin: "30px 0",
  },
  title: {
    font: "28px Fredoka One ",
    margin: "0",
    textAlign: "center",
    color: " #DC5EA6",
    [theme.breakpoints.up("md")]: {
      fontSize: "36px",
    },
    [theme.breakpoints.up("xl")]: {
      fontSize: "42px",
    },
  },
  link: {
    cursor: "pointer",
    textDecoration: "none",
    // height: "31px",
    // width: "190px",
  },
  backButtonContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.up("sm")]: {
      justifyContent: "flex-start",
    },
  },
  backButton: {
    cursor: "pointer",
    height: "35px",
    // width: "200px",
    backgroundColor: "#FDEFF7",
    color: "#DC5EA6",
    border: "#DC5EA6 1px solid",
    borderRadius: "20px",
    textTransform: "none",
    font: "20px Lato",
    letterSpacing: "0.14px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
  },

  studentListContainer: {
    gap: "20px",
  },
  PurpleButton: {
    color: "#FFFFFF",
    backgroundColor: "#9051b1",
    borderRadius: "30px",
    alignSelf: "center",
    // margin: "0 auto",

    width: "100%",
    maxWidth: "238px",
    minHeight: "45px",
    border: "none",
    textTransform: "unset",
    cursor: "pointer",
    fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
    fontWeight: "500",
    padding: "6px 16px",
    lineHeight: "1.75",
    transition:
      "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    boxShadow:
      "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
    "&:hover": {
      backgroundColor: "#B965D3 ",
    },
    [theme.breakpoints.up("lg")]: {
      // gridArea: "button",
    },
  },
  WitheButton: {
    color: "#9051b1",
    // borderColor: "#9051b1",
    border: "1px solid #9051b1",
    backgroundColor: "#FFFFFF",
    borderRadius: "30px",
    // margin: "0 auto",
    minWidth: "200px",
    width: "100%",
    maxWidth: "238px",
    minHeight: "45px",
    textTransform: "unset",
    cursor: "pointer",
    fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
    fontWeight: "500",
    padding: "6px 16px",
    lineHeight: "1.75",
    transition:
      "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    boxShadow:
      "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
    "&:hover": {
      backgroundColor: "#B965D330 ",
    },
  },
  formContainer: {
    // display: "flex",
    display: "grid",
    // flexDirection: "column"
    gridTemplateColumns: "1fr",
    // width: "80%",
    boxSizing: "border-box",
    maxWidth: "560px",
    alignItems: "flex-start",
    justifyItems: "center",
    gap: "30px",
    [theme.breakpoints.up("lg")]: {
      width: "100%",
      maxWidth: "680px",
      gridTemplateColumns: "1fr 1fr",
    },
  },
  formItemContainer: {
    gap: "10px",
    position: "relative",
  },
  input: {
    width: "100%",
    height: "40px",
    paddingLeft: "27px",
    paddingRight: "27px",
    borderRadius: "61px",
    boxSizing: "border-box",
    // border: " solid #1D2AAD 3px ",
    border: "none",
    background: "#FFFFFF",
    font: "Helvetica",
    fontSize: "16px",
    //    width: '449px',
    // height: '72px',
    // margin: '43px 139px 19px 66px',
    // padding: '26px 24px 25px',
    backgroundColor: "#f0f0f0",
    "&:focus": {
      outline: "none",
    },
    [theme.breakpoints.up("lg")]: {
      // height: "60px",
      // maxWidth: "449px",
      fontSize: "18px",
    },
  },
  label: {
    fontWeight: "bold",
    fontFamily: "Lato, sans-serif",
  },
  required: {
    position: "absolute",
    right: "-15px",
    bottom: "50%",
    top: "50%",
    fontWeight: "bold",
  },
  requiredWithoutLabel: {
    position: "absolute",
    right: "-15px",
    bottom: "10px",
    fontWeight: "bold",
  },
  table: {
    width: "100%",
    minHeight: "480px",
  },

  schoolAddress: {
    color: "#323232",
    margin: 0,
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    font: "14px Lato",
    width: "100%",
    overflow: "hidden",
    maxWidth: "220px",
    [theme.breakpoints.up("md")]: {
      maxWidth: "40ch",
    },
  },

  spacer: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "unset",
    },
  },
}));

const CargarUsuariosIndividual = () => {
  const classes = useStyles();
  const { user } = useContext(AuthContext);

  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [modalMessageOpen, setModalMessageOpen] = useState(false);
  const [groupChargedModal, setGroupChargedModal] = useState(false);
  const [confirmChangesResult, setConfirmChangesResult] = useState("");
  const [loading, setLoading] = useState(false);

  const [listOfSchools, setListOfSchools] = useState([]);
  const [formData, setFormData] = useState({});
  const [optionGrades, setOptionGrades] = useState([]);
  const [rows, setRows] = useState([]);

  const [groupLoaded, setGroupLoaded] = useState({});
  const [totalStudents, setTotalStudents] = useState(0);

  const formRef = useRef(null);

  const teachersInBD = useMemo(
    () =>
      optionGrades
        .find((item) => item.id_grado === formData.grado)
        ?.grupos?.find((grupo) => grupo.id_grupo === formData.grupo)
        ?.docentes || 0,
    [optionGrades, formData?.grado, formData?.grupo]
  );

  const TeachersToAdd = useMemo(
    () => rows.filter((usuario) => usuario.profile === "Docente").length || 0,
    [rows]
  );

  const totalTeachers = teachersInBD + TeachersToAdd;

  const checkUsername = (userData) => {
    const regExp = /^[a-zA-Z0-9_.-]{3,30}$/;
    // Letras, numeros y caracteres _.-
    const testuserName = regExp.test(userData.username);

    if (testuserName) {
      const body = {
        nombre_usuario: userData?.username,
        correo: userData?.email,
      };
      axios
        .post("/user/info/usuario/is", body)
        .then((response) => {
          if (response.status === 200) {
            const res = response.data?.resultado;
            const existUsernameINBD = res?.existe_usuario;
            const existEmailInBD = res?.existe_correo;

            const existInBD = existUsernameINBD || existEmailInBD;

            const userInTable = rows.find(
              (row) =>
                row.username === userData.username ||
                row.email === userData.email
            );

            const existUsername =
              existUsernameINBD || userInTable?.username === userData?.username;
              const existEmail =
              existEmailInBD || userInTable?.email === userData?.email;

            const existInTable = Boolean(userInTable)
            const exist = existInTable || existInBD;
            if (!exist) {
              if (userData?.profile === "Estudiante") {
                setRows([...rows, userData]);
                setFormData({
                  ...formData,
                  nombre_estudiante: "",
                  apellido_estudiante: "",
                  segundo_apellido_estudiante: "",
                  username_estudiante: "",
                  password_estudiante: "",
                });
                setTotalStudents(totalStudents + 1);
              } else {
                setRows([...rows, userData]);
                setFormData({
                  ...formData,
                  nombre_docente: "",
                  apellido_docente: "",
                  segundo_apellido_docente: "",
                  correo_docente: "",
                  confirmar_correo_docente: "",
                  username_docente: "",
                  password_docente: "",
                });
              }
            } else {
              setModalMessageOpen(true);
              const getReason = () => {
                if (existUsername && !existEmail) {
                  return "El nombre de usuario no está disponible, por favor elija otro.";
                } else if (existEmail && !existUsername) {
                  return "El correo electrónico ingresado ya está siendo utilizado, por favor elija otro.";
                } else if (existEmail && existUsername) {
                  return "El nombre de usuario no está disponible y el correo electrónico ingresado ya está siendo utilizado, por favor modifica los datos e intenta nuevamente.";
                }
              };
              const reason = getReason();
              setConfirmChangesResult(reason);
            }
          }
        })
        .catch((error) => {
          setModalMessageOpen(true);
          setConfirmChangesResult(
            "Ha ocurrido un error, inténtelo de nuevo más tarde"
          );
        });
    } else {
      setModalMessageOpen(true);
      setConfirmChangesResult(
        `El nombre de usuario posee carácter(es) no válido. Asegúrate que solo posea letras, numeros o los caracteres "_.-" )`
      );
    }
  };

  const id_school = useMemo(() => formData?.id_escuela, [formData]);

  const getGroups = useCallback(() => {
    if (!id_school) return;
    axios
      .get(`listado/grado/grupos?id_escuela=${id_school}`)
      .then((response) => {
        if (response.status === 200) {
          setOptionGrades(response?.data?.resultado?.listado);
        }
      })
      .catch((error) => {
        console.log(error, "error");
      });
  }, [id_school]);

  const getSchoolList = useCallback(() => {
    axios
      .get(`/listado/escuelas?id_usuario=${user.userId}`)
      .then((response) => {
        if (response.status === 200) {
          // console.log(response, "response");
          const list = response.data.resultado;
          setListOfSchools(list);
        }
      })
      .catch((error) => {
        console.log(error, "error");
      });
  }, [user.userId]);

  const addStudent = () => {
    const userData = {
      name: formData?.nombre_estudiante,
      lastName: formData?.apellido_estudiante,
      secondLastName: formData?.segundo_apellido_estudiante,
      profile: "Estudiante",
      email: "",
      username: formData?.username_estudiante,
      password: formData?.password_estudiante,
      id: Date.now(),
    };
    if (
      userData.name &&
      userData.lastName &&
      userData?.username &&
      userData?.password
    ) {
      checkUsername(userData);
    } else {
      setConfirmationModalOpen(false);
      setModalMessageOpen(true);
      setConfirmChangesResult(
        "Asegúrate de llenar todos los datos del estudiante."
      );
    }
  };
  const addTeacher = () => {
    const userData = {
      name: formData?.nombre_docente,
      lastName: formData?.apellido_docente,
      secondLastName: formData?.segundo_apellido_docente,
      profile: "Docente",
      email: formData?.correo_docente,
      confirmEmail: formData?.confirmar_correo_docente,
      username: formData?.username_docente,
      password: formData?.password_docente,
      id: Date.now(),
    };
    if (
      userData.name &&
      userData.lastName &&
      userData?.email &&
      userData?.confirmEmail &&
      userData?.username &&
      userData?.password
    ) {
      if (userData?.email === userData?.confirmEmail) {
        checkUsername(userData);
      } else {
        setConfirmationModalOpen(false);
        setModalMessageOpen(true);
        setConfirmChangesResult(
          "Asegúrate de llenar todos los datos del docente o verifica que los datos de los campos de correo coincidan."
        );
      }
    } else {
      setConfirmationModalOpen(false);
      setModalMessageOpen(true);
      setConfirmChangesResult(
        "Asegúrate de llenar todos los datos del docente."
      );
    }
  };

  const removeUser = (id) => {
    const newRows = rows.filter((row) => row?.id !== id);
    setRows(newRows);
  };

  const columns = [
    {
      field: "name",
      headerName: "Nombre(s)",
      width: 150,
      editable: false,
    },
    {
      field: "lastName",
      headerName: "Primer apellido",
      width: 150,
      editable: false,
    },
    {
      field: "secondLastName",
      headerName: "Segundo apellido",
      width: 150,
      editable: false,
    },
    {
      field: "profile",
      headerName: "Perfil",
      width: 80,
      editable: false,
    },
    {
      field: "email",
      headerName: "Correo",
      width: 150,
      editable: false,
    },
    {
      field: "username",
      headerName: "Usuario",
      width: 100,
      editable: false,
    },
    {
      field: "password",
      headerName: "Contraseña",
      width: 100,
      editable: false,
    },

    {
      field: "delete",
      headerName: "Eliminar",
      width: 120,
      editable: false,
      renderCell: (params) => (
        <Button
          color="secondary"
          aria-label="delete"
          onClick={() => {
            removeUser(params?.row?.id, params?.row?.profile);
          }}
        >
          <DeleteForeverIcon />
        </Button>
      ),
    },
  ];

  useEffect(() => {
    getSchoolList();
  }, [getSchoolList]);

  useEffect(() => {
    getGroups();
  }, [getGroups]);

  const handleCloseConfirmationModal = () => {
    setConfirmationModalOpen(!confirmationModalOpen);
  };

  const handleCloseGroupChargedModal = () => {
    setGroupChargedModal(!groupChargedModal);
  };
  const handleCloseMessageModal = () => {
    setModalMessageOpen(!modalMessageOpen);
  };

  const handleForm = (e) => {
    if (e.target.name === "id_escuela") {
      setFormData({
        ...formData,
        [e.target.name.toLowerCase()]: e.target.value,
        grado: "",
        grupo: "",
      });
    } else if (e.target.name === "grado") {
      setFormData({
        ...formData,
        [e.target.name.toLowerCase()]: e.target.value,
        grupo: "",
      });
    } else {
      setFormData({
        ...formData,
        [e.target.name.toLowerCase()]: e.target.value,
      });
    }
  };

  const confirmChanges = () => {
    if (formData?.id_escuela && formData?.grado && formData?.grupo) {
      const body = {
        id_escuela: formData?.id_escuela,
        id_grado: formData?.grado,
        escuela: listOfSchools.find(
          (escuela) => escuela.id_escuela === formData?.id_escuela
        )?.escuela,
        id_grupo: formData?.grupo,
        docentes: rows
          .filter((usuario) => usuario.profile === "Docente")
          .map((docente) => {
            return {
              nombre: docente?.name,
              apellido1: docente?.lastName,
              apellido2: docente?.secondLastName || "",
              correo: docente?.email,
              nombreUsuario: docente?.username,
              contrasenia: docente?.password,
            };
          }),
        alumnos: rows
          .filter((usuario) => usuario.profile === "Estudiante")
          .map((alumno) => {
            return {
              nombre: alumno?.name,
              apellido1: alumno?.lastName,
              apellido2: alumno?.secondLastName || "",
              nombreUsuario: alumno?.username,
              contrasenia: alumno?.password,
            };
          }),
      };
      setConfirmationModalOpen(false);
      axios
        .post("/user/create/grupos", body)
        .then((response) => {
          if (response.status === 200) {
            setGroupLoaded(response.data.resultado);
            handleCloseGroupChargedModal();
            setFormData({});
            formRef?.current?.reset();
            setRows([]);
          }
        })
        .catch((error) => {
          setConfirmationModalOpen(false);

          setModalMessageOpen(true);
          setConfirmChangesResult(
            "Ha ocurrido un error intentando cargar los datos. Intente de nuevo más tarde."
          );
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setConfirmationModalOpen(false);
      setModalMessageOpen(true);
      setConfirmChangesResult("Asegúrese de llenar todos los datos");
    }
  };

  const schoolAddress = useMemo(
    () =>
      listOfSchools.find((item) => item.id_escuela === id_school)?.direccion,
    [id_school, listOfSchools]
  );

  return (
    <ContainerAdminPearson>
      <Grid
        container
        item
        sm={12}
        alignItems="center"
        justifyContent="center"
        className={classes.mainContainer}
      >
        <Grid
          container
          item
          direction="column"
          className={classes.boxContainer}
        >
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="center"
          >
            <Grid item className={classes.backButtonContainer}>
              <Link to="/admin/cargar-datos" className={classes.link}>
                <button className={classes.backButton}>
                  <ArrowBackIcon /> Cargar datos
                </button>
              </Link>
            </Grid>
            <Grid
              container
              item
              alignItems="center"
              justifyContent="center"
              className={classes.titleContainer}
            >
              <h2 className={classes.title}>
                Carga de usuarios: carga individual
              </h2>
            </Grid>
          </Grid>
          <form
            className={classes.formContainer}
            autoComplete="off"
            ref={formRef}
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <Grid
              container
              direction="column"
              className={classes.formItemContainer}
            >
              <label htmlFor="input-name" className={classes.label}>
                Datos del grupo:
              </label>
            </Grid>
            <br className={classes.spacer} />
            <Grid
              container
              direction="column"
              className={classes.formItemContainer}
            >
              <Grid
                container
                direction="column"
                className={classes.formItemContainer}
              >
                <SelectSchool
                  list={listOfSchools}
                  label="Nombre de la escuela"
                  fieldName="id_escuela"
                  handleForm={handleForm}
                  defaultValue={formData?.id_escuela}
                  value={formData?.id_escuela}
                  formData={formData}
                  disabled={
                    listOfSchools?.length === 0 ||
                    (formData?.id_escuela && rows?.length > 0)
                  }
                />
                <p className={classes.schoolAddress}>{schoolAddress}</p>
                <span className={classes.required}>*</span>
              </Grid>
            </Grid>
            <br className={classes.spacer} />
            <Grid
              container
              direction="column"
              className={classes.formItemContainer}
            >
              {" "}
              <SelectGradeGroupCargaIndividual
                list={optionGrades}
                title="grado"
                handleForm={handleForm}
                defaultValue={formData?.grado}
                value={formData?.grado}
                formData={formData}
                disabled={
                  listOfSchools?.length === 0 ||
                  !formData?.id_escuela ||
                  rows?.length > 0
                }
              />
              <span className={classes.required}>*</span>
            </Grid>
            <Grid
              container
              direction="column"
              className={classes.formItemContainer}
            >
              {" "}
              <SelectGradeGroupCargaIndividual
                // list={defaultGroupList}
                list={
                  optionGrades.find((item) => item.id_grado === formData.grado)
                    ?.grupos
                }
                title="grupo"
                handleForm={handleForm}
                defaultValue={formData?.grupo}
                value={formData?.grupo}
                formData={formData}
                disabled={!formData?.grado || rows?.length > 0}
              />
              <span className={classes.required}>*</span>
            </Grid>

            <Grid
              container
              direction="column"
              className={classes.formItemContainer}
            >
              <label htmlFor="input-nombre-docente" className={classes.label}>
                Datos del docente:
              </label>
            </Grid>

            <br className={classes.spacer} />
            <Grid
              container
              direction="column"
              className={classes.formItemContainer}
            >
              <input
                type="text"
                className={classes.input}
                id="input-nombre-docente"
                name="nombre_docente"
                placeholder="Nombre(s)"
                autoComplete="off"
                value={formData.nombre_docente}
                onChange={handleForm}
                required
              />
              <span className={classes.requiredWithoutLabel}>*</span>
            </Grid>
            <br className={classes.spacer} />
            <Grid
              container
              direction="column"
              className={classes.formItemContainer}
            >
              <input
                type="text"
                className={classes.input}
                name="apellido_docente"
                value={formData.apellido_docente}
                onChange={handleForm}
                required
                placeholder="Primer apellido"
                autoComplete="off"
              />
              <span className={classes.requiredWithoutLabel}>*</span>
            </Grid>
            <Grid
              container
              direction="column"
              className={classes.formItemContainer}
            >
              <input
                type="text"
                className={classes.input}
                name="segundo_apellido_docente"
                value={formData.segundo_apellido_docente}
                placeholder="Segundo apellido"
                autoComplete="off"
                onChange={handleForm}
                required
              />
            </Grid>

            <Grid
              container
              direction="column"
              className={classes.formItemContainer}
            >
              <input
                type="email"
                className={classes.input}
                name="correo_docente"
                value={formData.correo_docente}
                onChange={handleForm}
                placeholder="Correo"
                autoComplete="off"
                required
              />
              <span className={classes.requiredWithoutLabel}>*</span>
            </Grid>
            <Grid
              container
              direction="column"
              className={classes.formItemContainer}
            >
              <input
                type="email"
                className={classes.input}
                name="confirmar_correo_docente"
                value={formData.confirmar_correo_docente}
                onChange={handleForm}
                placeholder="Confirmar correo"
                autoComplete="off"
                required
              />
              <span className={classes.requiredWithoutLabel}>*</span>
            </Grid>
            <Grid
              container
              direction="column"
              className={classes.formItemContainer}
            >
              <input
                type="text"
                className={classes.input}
                name="username_docente"
                value={formData.username_docente}
                onChange={handleForm}
                placeholder="Nombre de usuario"
                autoComplete="off"
                required
              />
              <span className={classes.requiredWithoutLabel}>*</span>
            </Grid>

            <Grid
              container
              direction="column"
              className={classes.formItemContainer}
            >
              <input
                type="text"
                id="input-password-teacher"
                name="password_docente"
                placeholder="Contraseña"
                className={classes.input}
                onChange={handleForm}
                value={formData?.password_docente}
                autoComplete="off"
                required
                // disabled={passwordHasChanged}
              />
              <span className={classes.requiredWithoutLabel}>*</span>
            </Grid>

            {totalTeachers < 2 ? (
              <Button
                className={classes.WitheButton}
                onClick={addTeacher}
                disabled={!formData?.grupo}
              >
                Añadir docente
              </Button>
            ) : (
              <label htmlFor="input-name" className={classes.label}>
                El grupo ya posee dos docentes
              </label>
            )}

            <br className={classes.spacer} />
            <Grid
              container
              direction="column"
              className={classes.formItemContainer}
            >
              <label htmlFor="input-nombre-docente" className={classes.label}>
                Datos del estudiante:
              </label>
            </Grid>
            <br className={classes.spacer} />
            <Grid
              container
              direction="column"
              className={classes.formItemContainer}
            >
              <input
                type="text"
                className={classes.input}
                name="nombre_estudiante"
                value={formData.nombre_estudiante}
                placeholder="Nombre(s)"
                autoComplete="off"
                onChange={handleForm}
                required
              />
              <span className={classes.requiredWithoutLabel}>*</span>
            </Grid>
            <br className={classes.spacer} />
            <Grid
              container
              direction="column"
              className={classes.formItemContainer}
            >
              <input
                type="text"
                className={classes.input}
                name="apellido_estudiante"
                value={formData.apellido_estudiante}
                placeholder="Primer apellido"
                autoComplete="off"
                onChange={handleForm}
                required
              />
              <span className={classes.requiredWithoutLabel}>*</span>
            </Grid>
            <Grid
              container
              direction="column"
              className={classes.formItemContainer}
            >
              <input
                type="text"
                className={classes.input}
                name="segundo_apellido_estudiante"
                value={formData.segundo_apellido_estudiante}
                placeholder="Segundo apellido"
                autoComplete="off"
                onChange={handleForm}
                required
              />
            </Grid>
            <Grid
              container
              direction="column"
              className={classes.formItemContainer}
            >
              <input
                type="text"
                className={classes.input}
                name="username_estudiante"
                value={formData.username_estudiante}
                onChange={handleForm}
                placeholder="Nombre de usuario"
                autoComplete="off"
                required
              />
              <span className={classes.requiredWithoutLabel}>*</span>
            </Grid>

            <Grid
              container
              direction="column"
              className={classes.formItemContainer}
            >
              <input
                type="text"
                id="input-password-student"
                name="password_estudiante"
                placeholder="Contraseña"
                className={classes.input}
                onChange={handleForm}
                value={formData?.password_estudiante}
                autoComplete="off"
                required
                // disabled={passwordHasChanged}
              />
              <span className={classes.requiredWithoutLabel}>*</span>
            </Grid>
            <Button
              className={classes.WitheButton}
              onClick={addStudent}
              disabled={!formData?.grupo}
            >
              Añadir estudiante
            </Button>
          </form>
          <DataGrid
            // {rows && (
            //   <DataGrid
            className={classes.table}
            rows={rows}
            // rows={rows?.sort((a, b) => a?.id - b?.id)}
            columns={columns}
            pageSize={10}
            rowsPerPageOptions={[10]}
            disableSelectionOnClick
            disableColumnFilter
            disableColumnMenu
          />

          <Button
            className={classes.PurpleButton}
            onClick={handleCloseConfirmationModal}
            disabled={
              !formData?.id_escuela || !formData?.grado || !formData?.grupo
            }
          >
            Cargar usuarios
          </Button>
        </Grid>
      </Grid>
      <ModalConfirm
        open={confirmationModalOpen}
        handleClose={handleCloseConfirmationModal}
        handleConfirm={confirmChanges}
        title={`¿Está seguro de crear el grupo?`}
      />
      {loading && <Loader />}

      <ModalGrupoCargado
        open={groupChargedModal}
        handleClose={handleCloseGroupChargedModal}
        data={groupLoaded}
      />
      <ModalMessage
        open={modalMessageOpen}
        handleClose={handleCloseMessageModal}
        title={confirmChangesResult}
        textOfButton="Aceptar"
      />
    </ContainerAdminPearson>
  );
};

export default CargarUsuariosIndividual;
