import  React from "react";
import { DataGrid } from "@mui/x-data-grid";

import ModalMessage from "../../../../Modals/ModalMessage";
import ModalCode from "../../../../Modals/ModalCode";
import axios from "../../../../../services/axios";

import AuthContext from "../../../../../auth/AuthContext";
import UsabilityContext from "../../../../../usability/UsabilityContext";
import { Button, makeStyles } from "@material-ui/core";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import ModalConfirm from "../../../../Modals/ModalConfirm";

const useStyles = makeStyles((theme) => ({
  PurpleButton: {
    color: "#FFFFFF",
    backgroundColor: "#9051b1",

    width: "100%",
    maxWidth: "238px",
    border: "none",
    textTransform: "unset",
    cursor: "pointer",
    fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
    fontWeight: "500",
    padding: "6px 16px",
    lineHeight: "1.75",
    transition:
      "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    boxShadow:
      "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
    "&:hover": {
      backgroundColor: "#B965D3 ",
    },
  },
  WitheButton: {
    color: "#9051b1",
    // borderColor: "#9051b1",
    border: "1px solid #9051b1",
    backgroundColor: "#FFFFFF",

    width: "100%",
    maxWidth: "238px",

    textTransform: "unset",
    // textTransform: "uppercase",
    cursor: "pointer",
    fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
    fontWeight: "500",
    padding: "6px 16px",
    lineHeight: "1.75",
    transition:
      "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    boxShadow:
      "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
    "&:hover": {
      backgroundColor: "#B965D330 ",
    },
  },
}));

const StudentTable = () => {
  const { user } = React.useContext(AuthContext);
  const { groupToEdit, search } =
    React.useContext(UsabilityContext);
  const [confirmationModalOpen, setConfirmationModalOpen] =
    React.useState(false);
  const [modalMessageOpen, setModalMessageOpen] = React.useState(false);
  const [confirmChangesResult, setConfirmChangesResult] = React.useState("");
  const [studentName, setStudentName] = React.useState("");
  const [codeModalOpen, setCodeModalOpen] = React.useState(false);
  const [rows, setRows] = React.useState([]);
  const [code, setCode] = React.useState("");
  const [codesList, setCodesList] = React.useState([]);
  const [id, setId] = React.useState("");
  let { id_distribucion_escuela } = useParams();
  const classes = useStyles();

  const columns = [
    {
      field: "firstName",
      headerName: "Nombre(s)",
      width: 150,
      editable: true,
    },
    {
      field: "firstLastName",
      headerName: "Apellido paterno",
      width: 150,
      editable: true,
    },
    {
      field: "secondLastName",
      headerName: "Apellido materno",
      width: 150,
      editable: true,
    },
    {
      field: "school",
      headerName: "Escuela",
      width: 150,
      editable: false,
      headerAlign: "center",
    },
    {
      field: "country",
      headerName: "País",
      width: 100,
      editable: false,
    },
    {
      field: "city",
      headerName: "Ciudad",
      width: 80,
      editable: false,
    },
    {
      field: "grade",
      headerName: "Grado",
      width: 80,
      editable: false,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "group",
      headerName: "Grupo",
      width: 80,
      editable: false,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "assingCode",
      headerName: "Código",
      width: 90,
      editable: false,
      renderCell: (params) => (
        <Button
          className={classes.WitheButton}
          size="small"
          onClick={() => assingCode(params)}
        >
          Código
        </Button>
      ),
    },
    {
      field: "edit",
      headerName: "Editar",
      width: 100,
      editable: false,
      renderCell: (params) => (
        <Link
          to={{
            pathname: `/docente/editar/alumno/${params.id}`,
            state: {
              ...params.row,
            },
          }}
          style={{ textDecoration: "none" }}
        >
          <Button className={classes.PurpleButton} size="small">
            Editar
          </Button>
        </Link>
      ),
    },
  ];
  const handleCloseConfirmationModal = () => {
    setConfirmationModalOpen(!confirmationModalOpen);
  };

  const handleCloseMessageModal = () => {
    setModalMessageOpen(!modalMessageOpen);
  };

  const assingCode = (params) => {
    const { id, firstName, firstLastName } = params.row;
    setStudentName(`${firstName} ${firstLastName}`);
    setId(id);

    if (params.field === "assingCode") {
      let actualCode = codesList.find((el) => el.id === id);
      if (actualCode) {
        setCodeModalOpen(true);
        setCode(actualCode);
      } else {
        handleCloseConfirmationModal();
      }
    }
  };

  const confirmChanges = () => {
    const body = {
      id_usuario: id,
    };

    axios
      .post("/session/recover", body)
      .then((response) => {
        if (response.status === 200) {
          const temporalCode = response.data.resultado.contraseña_temporal;
          setCode(temporalCode);
          setConfirmationModalOpen(false);
          // setModalMessageOpen(true);
          setCodeModalOpen(true);

          setConfirmationModalOpen(false);
          setCodeModalOpen(true);
          const { firstName, firstLastName, secondLastName, group } = rows.find(
            (el) => el.id === id
          );
          const newData = {
            id,
            code: temporalCode,
            firstName,
            firstLastName,
            secondLastName,
            group,
          };

          setCode(newData);
          const newList = [...codesList, newData];

          setCodesList(newList);
          // setConfirmChangesResult("Los datos se han actualizado correctamente");
        }
      })
      .catch((error) => {
        setConfirmationModalOpen(false);
        setModalMessageOpen(true);
        setConfirmChangesResult("Asegúrese de llenar todos los datos");
      });
  };

  React.useEffect(() => {
    const body = {
      id_usuario: user.userId,
      id_distribucion_escuela:
        parseInt(groupToEdit) || parseInt(id_distribucion_escuela),
    };
    axios
      .post(`/listado/alumnos`, body)
      .then((response) => {
        if (response.status === 200) {
          const studentList = response.data.resultado.alumnos.map((student) => {
            return {
              id: student.id_usuario,
              firstLastName: student.apellido_paterno,
              secondLastName: student.apellido_materno,
              firstName: student.nombre,
              city: student.ciudad || student.pais,
              grade: student.grado,
              group: student.grupo,
              country: student.pais,
              school: student.escuela,
              assingCode: "asignar",
            };
          });

          setRows(studentList);

          //  dispatch({
          //    type: types.loadActivitiesList,
          //    payload: listaSinRemediales,
          //  });
        }
      })
      .catch((error) => {
        //  dispatch({
        //    type: types.loadActivitiesList,
        //    payload: [],
        //  });
      });

    return () => {};
  }, [groupToEdit, id_distribucion_escuela, user.userId]);

  return (
    <div style={{ height: 630, width: "100%", marginTop: "50px" }}>
      <DataGrid
        rows={rows.filter((row) => {
          if (search) {
            return (
              row?.firstName?.toLowerCase().startsWith(search.toLowerCase()) ||
              row?.firstLastName?.toLowerCase().startsWith(search) ||
              row?.secondLastName?.toLowerCase().startsWith(search)
            );
          } else {
            return row;
          }
        })}
        columns={columns}
        pageSize={10}
        rowsPerPageOptions={[10]}
        // checkboxSelection
        disableSelectionOnClick
        disableColumnFilter
        disableColumnMenu
        // onCellClick={(params, event) => {
        // }}
      />
      <ModalConfirm
        open={confirmationModalOpen}
        handleClose={handleCloseConfirmationModal}
        handleConfirm={confirmChanges}
        title={`¿Está seguro de crear un código de recuperación para el usuario ${studentName}?`}
      />
      <ModalCode
        data={code}
        open={codeModalOpen}
        handleClose={() => {
          setCodeModalOpen(!codeModalOpen);
        }}
      />
      <ModalMessage
        open={modalMessageOpen}
        title={confirmChangesResult}
        handleClose={handleCloseMessageModal}
      />
    </div>
  );
};

export default StudentTable;
