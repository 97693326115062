import React               from "react";
import {Grid, makeStyles}                 from '@material-ui/core';

const useStyles = makeStyles(theme =>({
  questionContainer:{
    width: "100%",
    height:"100%",
  },
  reading: {
    width: "98%",
    font: "22px lato",
    letterSpacing:'0',
    margin:"14px 10px 14px 0",
    [theme.breakpoints.down(1370)]:{
      font: "500 18px lato",
      width: "100%",
      margin:"10px 10px 10px 0"
    },
    [theme.breakpoints.down(1310)]:{
      font: "500 16px lato",
      // width: "80%",
      margin:"10px 5px 10px 0"
    },
    [theme.breakpoints.down(925)]:{
      font: "500 14px lato",
      // width: "70%",
      margin:"6px 0 6px 0"
    },
    [theme.breakpoints.down(426)]:{
      font: "500 12px lato",
      // width: "70%",
      margin:"6px 0 6px 5%"
    },
  },
  imgStyle: {
    alignItems: "center",
    width:'90%',
    height:'90%',
    // [theme.breakpoints.down(1400)]:{
    //   width:'85%px',
    //   height:'85%px',
    // },
  },
  noMargin: {
    margin: '0px',
  }
}));

const Question = (props) => {
  const classes = useStyles()
  const { pictureA, pictureB, pictureC, pictureD } = props


  return(
    <Grid container justifyContent="center" alignItems="center" className={classes.questionContainer}>
      <p 
        // dangerouslySetInnerHTML={{__html: `${step+1}. ${pregunta.replace( '\n', '<br/><br/>')}`}}
        className={classes.reading}>
        8. "Según la lectura, ¿en qué casos se representa de manera correcta la toma de decisiones que 
        se refiere a la Teoría de Juegos?"
      </p>
      <br/>
      <Grid container direction="column" item xs={6} justifyContent="center" alignItems="center" >
        <img alt="img" src={pictureA} className={classes.imgStyle}/>
        <p className={classes.noMargin}>a.</p>
      </Grid>
      <Grid container direction="column" item xs={6} justifyContent="center" alignItems="center" >
        <img alt="img" src={pictureB} className={classes.imgStyle}/>
        <p className={classes.noMargin}>b.</p>
      </Grid>
      <Grid container direction="column" item xs={6} justifyContent="center" alignItems="center" >
        <img alt="img" src={pictureC} className={classes.imgStyle}/>
        <p className={classes.noMargin}>c.</p>
      </Grid>
      <Grid container direction="column" item xs={6} justifyContent="center" alignItems="center" >
        <img alt="img" src={pictureD} className={classes.imgStyle}/>
        <p className={classes.noMargin}>d.</p>
      </Grid>

    </Grid>
  )

}

export default Question;
