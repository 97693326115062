import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
    ContenedorLectura:{
        width:'502px',
                  [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
    },
    ContainerTitulo:{
        margin:'0 0 34px 0',
        '& .Titulo':{
            letterSpacing: '0px',
            color: '#FF2B2B',
            margin:'0 0 5px 0',
            textAlign:'center',
            font:'43px fredoka one',
            lineHeight:'52px',
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
        '& .Titulo2':{
            color: '#FF2B2B',
            margin:'0',
            textAlign:'center',
            font:'bold 26px lato',
            letterSpacing: '.24px',
            lineHeight:'28px',
            [theme.breakpoints.down(502)]:{
                font:'bold 22px lato',
                lineHeight:'24px',
            },
            [theme.breakpoints.down(350)]:{
                font:'bold 18px lato',
                lineHeight:'24px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        position:'relative',
        '& .Lectura':{
            margin:'0',
            color:'#000',
            font:'18px lato',
            lineHeight :'28px',
            letterSpacing: '0.17px',
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .Lectura2':{
            zIndex:1,
            margin:'0 0 36px 0',
            position:'relative',
            width:'100%',
        },
        '& .Titulo3':{
            color: '#00ADB6',
            margin:'0 0 47px 0',
            textAlign:'center',
            font:'bold 26px lato',
            letterSpacing: '.24px',
            lineHeight:'28px',
            [theme.breakpoints.down(502)]:{
                font:'bold 22px lato',
                lineHeight:'24px',
            },
            [theme.breakpoints.down(350)]:{
                font:'bold 18px lato',
                lineHeight:'24px',
            },
        },
        '& .ContainerImagen':{
            zIndex:0,
            width:'100%',

        }, 
        '& .Imagen':{
            width:'100%',
        } 

      
    }
}));

const Pablo_y_el_intraterrestre_Cap_6 = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                        <h2 className="Titulo">Pablo y el intraterrestre</h2>
                        <h3 className='Titulo2'>Fragmento 3 (capítulos 4-5)</h3>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                       <p className='Lectura '>
                            ¡No podía esperar a llegar al punto de acecho! Si iba a estallar en algún lugar del 
                            mundo, quería hacerlo en la guarida o en el punto de acecho […]<br/><br/> 
                            
                            El punto de acecho era un resquicio entre la columna del extinguidor y la puerta del 
                            baño de niños. <br/><br/> 
                            
                            —Yo descubrí este lugar: está en un punto estratégico entre nuestros tres salones. 
                            Allí están los cuartos, los quintos y sexto B, el salón de Juan Carlos. Sexto A está 
                            después del corredor, hacia allá, pero eso no importa. A las niñas las ahuyenta el olor 
                            de los baños. Doblando esa esquina está la sala de descanso de los maestros. Nadie 
                            espera que los niños problemáticos estén cerca de la sala de maestros; por eso es un 
                            escondite perfecto: mantén a tus enemigos cerca y a tus amigos… más cerca. <br/><br/> 
                            
                            Pablo estaba seguro de que la frase no iba así, pero ya se había equivocado y no iba a 
                            corregirse frente a Brimlog. <br/><br/> 
                            
                            —Algo como tú y yo —acotó Brimlog.<br/><br/> 
                            
                            —Exactamente. <br/><br/> 
                            
                            Cuando Pablo y Brimlog llegaron al punto de acecho, el resto de los Calaveras ya 
                            estaban allí. Marco, larguirucho, amarillento, con su cara tan cuadrada como sus 
                            lentes, rellenaba su botella de agua en el filtro de los maestros. Hacía eso casi diario, 
                            aunque dos o tres veces por semana lo sorprendían y entonces terminaban todos en 
                            la oficina del director. Agustín se rascaba la nariz de bolita, demasiado pequeña para 
                            su cara redonda de gigantescos cachetes rojos y bronceados. Preparaba su dedo para 
                            una exploración de sus fosas nasales, espectáculo que Pablo esperaba no tener que 
                            ver. <br/><br/> 
                            
                            Juan Carlos, un niño enorme y pálido, con manos gigantes y ojos tristes, jugaba con 
                            un insecto. Era tan alto que tenía que encorvarse todo el tiempo para comunicarse 
                            con sus compañeros. Como se cansaba de doblarse, se la vivía recargado sobre los 
                            muebles y las paredes, lo cual le daba un aire de oso bonachón y somnoliento. En ese 
                            momento, quieto y en reposo, Juan Carlos se recargaba en la columna del extintor, 
                            lugar donde había encontrado al insecto con el que jugaba. Con pasitos rápidos, el 
                            animalejo movía todas sus patitas, recorriendo una y otra vez el mismo camino a lo 
                            largo de las manos de Juan Carlos. Para cuando el bicho finalmente llegaba de un 
                            extremo al otro de esa eterna hilera de dedos gruesos como salchichas, Juan Carlos 
                            ponía su otra mano enfrente y así las patitas diminutas continuaban caminando por 
                            siempre. <br/><br/> 
                            
                            Todos estaban discutiendo sobre el partido que Pablo no había visto por estar 
                            atorado en el tráfico y cuyo horrible marcador sólo había escuchado por la radio.<br/><br/> 

                            […]<br/><br/> 
                            
                            ―¿Y ese es tu dizque equipo, Pablo? ―preguntó Agustín, burlón, y todos voltearon a 
                            verlo. <br/><br/> 
                            
                            ―Era… ―agregó Marco continuando la broma. <br/><br/> 
                            
                            ―El día que se organicen, van a aniquilar a tus gatitos ―contestó amenazante Pablo. <br/><br/> 
                            
                            ―No hay posibilidades ―se burló Marco―. Tienen un goleador y un portero, pero 
                            eso no hace un equipo. Un equipo es más que dos jugadores buenos. <br/><br/> 
                            
                            ―Lo imposible pasa ―dijo Pablo, pensando que, si en ese momento él tenía a 
                            Brimlog en la cabeza, no era imposible que los Lobos Esteparios se estructuraran 
                            mejor. <br/><br/> 
                            
                            ―¡Je! Hasta tú dices que es imposible ―se rio Juan Carlos. <br/><br/> 
                            
                            Luego, Marco, Agustín y finalmente el mismo Pablo se unieron a la carcajada. <br/><br/> 
                            
                            ―Se vale soñar ―dijo Pablo encogiéndose de hombros. <br/><br/> 
                            
                            —No lo entiendo, humano Pablo, ¿tus propios colegas están criticando tus gustos? —
                            exclamó Brimlog francamente ofendido.<br/><br/> 
                            
                            —Es carrilla, Brimlog. El que se enoja pierde —contestó Pablo alegre y sonriente. <br/><br/> 
                            
                            Brimlog no tenía una traducción al intraterrestre para la palabra <em>carrilla</em>, pero le 
                            pareció que sonaba a algo horrible y problemático […]<br/><br/>  

                            En ese instante, un niño bajito y tan delgado como una escoba, dobló la esquina […]<br/><br/> 

                            ―¡Hey! ―dijo Pablo al niño flaco―. Éste es nuestro rincón. No puedes pasar por 
                            aquí.
                       </p>                   
                    </Grid>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12} >
                    <p className='Lectura Lectura2'>
                        ―Sólo voy al baño, Pablo. […]<br/><br/>

                        Juan Carlos se paró de golpe, irguiéndose 
                        imponente, pasando de un oso agradable a 
                        un oso enojado. En ese instante sonó la 
                        primera campana de entrada. 
                        Aprovechando la conmoción, el flaquito 
                        salió corriendo hacia su salón.<br/><br/>

                        ―¿Quién es ése? ―preguntó Juan Carlos a Pablo.
                    </p>
                    </Grid>
                    <Grid container justifyContent='center' className='ContainerImagen'>
                        <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion27/1R.png" alt="Tarjeta" />

                    </Grid>
                </Grid>
        </Grid>
    </Grid>
    );
}
 
export default Pablo_y_el_intraterrestre_Cap_6;
