import React, {useEffect}               from "react";
import {Grid, makeStyles}               from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
  questionContainer: {
    width: "100%",
    height: "90%",
    // overflowY: "scroll",
    // scrollbarColor: "#F6DE95",
    // scrollbarWidth: "10px",
    // '&::-webkit-scrollbar': {
    //   width: '15px',
    // },
    // '&::-webkit-scrollbar-track': {
    //   borderRadius: '25px',
    //   backgroundColor: "#F6DE95",
    //   webkitBoxShadow: 'inset 0 0 3px rgba(0,0,0,0.00)'
    // },
    // '&::-webkit-scrollbar-thumb': {
    //   backgroundColor: '#8144DF',
    //   borderRadius: '25px',
    // },
  },
  reading: {
    width: "100%",
    margin: "10px 0 10px 0 ",
    font: "22px lato",
    letterSpacing: "0.21px",
    [theme.breakpoints.down(1400)]: {
      font: " 18px lato",
      width: "100%",
    },
    [theme.breakpoints.down(650)]: {
      font: "14px lato",
    },
    "@media(max-height: 500px)": {
      font: "16px lato",
    },
  },
}));

const Question = (props) => {
  const classes = useStyles()
  const { pregunta, step, baseNumber, stepsForText } = props
  const [questionNumber, setQuestionNumber] = React.useState(step)

  const evalNumber = (step + 1) - (baseNumber * stepsForText)

  useEffect(() => {
    if(baseNumber === undefined){
      setQuestionNumber(Number(step + 1))
    } else {
      setQuestionNumber(evalNumber)
    }
  }, [step])


  return(
    <Grid container alignItems="center" className={classes.questionContainer}>
      <p 
        dangerouslySetInnerHTML={{__html: `${questionNumber}. ${pregunta}`}}
        className={classes.reading}
      >
      </p>
    </Grid>
  )

}

export default Question;
