import React, {useEffect, useState}    from 'react';
import { makeStyles,Grid}   from '@material-ui/core';
import NavBar               from '../../../../NavBar';
import MenuIzquierdo        from '../../../../MenuIzquierdo';
import Emotions             from '../../Emotions';
import Introduction         from './Introduction';
import ReadingStrategys     from './ReadingStrategys';
import axios                from "../../../../../services/axios";
import { Redirect } from 'react-router-dom';
import checkIfActivityIsUnlocked from "../../../../../utilities/FN/checkIfActivityIsUnlocked";

const useStyles = makeStyles(theme=>({
  grid1:{    
    zIndex:'3'
  },
  grid2:{    
    zIndex:'2'
  },
  grid3:{    
    boxSizing:'border-box',
    zIndex:'1',
    paddingLeft:'3%',
    [theme.breakpoints.down('md')]:{
      paddingLeft:'0px',
    }
  },
}))

const ContainerSession2 = (props) => {
  const classes = useStyles()
  const [sessionStatus, setSessionStatus] = useState()
  const [tryStep, setTryStep] = useState()
  const [activeStep, setActiveStep] = useState();
  const [select, setSelect] = useState();
  const id_actividad = 2

  const getStep = () =>{
    axios
      .get(`/user/stepper/actividad?id_actividad=${id_actividad}`,)
      .then((response) => {
        if (response.status === 200) {
          console.log(response, "data")
          const step = response.data.resultado.stepper[0].step;
          const LIMIT = 3;
          if (step >= 0 && step <= LIMIT) {
            setActiveStep(step);
          } else if (step > LIMIT) {
            setActiveStep(LIMIT);
          } else {
            setActiveStep(0);
          }
          setTryStep(response.data.resultado.intento)
          setSessionStatus(response.data.resultado.stepper[0].actividad_status)
        }
      })
      .catch((error) => {
        console.log(error, "ERRstp!!")
      });
  }

  const handleNextStep = () => {
    const body = {
      id_actividad: id_actividad, 
    }
    axios
    .put(`/user/up/stepper/actividad`, body)
    .then((response) => {
      if (response.status === 200) {
          console.log(response, "up")
          // setActiveStep(response.resultado.step)
      }
    })
    .catch((error) => {
      console.log(error, "ERRup!!")
    });
  }

  const handlePreviewStep = () => {
    const body = {
      id_actividad: id_actividad, 
    }
    axios
    .put(`/user/down/stepper/actividad`, body)
    .then((response) => {
      if (response.status === 200) {
          console.log(response, "down")
          // setActiveStep(response.resultado.step)
      }
    })
    .catch((error) => {
      console.log(error, "ERRdwn!")
    });
  }

  const emotionsStar = () => {
    if(tryStep === 1){
      const body = {
      id_actividad: id_actividad, 
      id_sentimiento: select
      }
      axios
      .post(`/user/actividad/sentimiento/inicio`, body)
      .then((response) => {
        if (response.status === 200) {
            console.log(response, "emoStar")
        }
      })
      .catch((error) => {
        console.log(error, "ERRemoStar!!")
      });
    }
  }

  const emotionsEnd = () => {
    if(tryStep === 1){
      const body = {
      id_actividad: id_actividad, 
      id_sentimiento: select
      }
      axios
      .put(`/user/actividad/sentimiento/fin`, body)
      .then((response) => {
        if (response.status === 200) {
            console.log(response, "emoEnd")
        }
      })
      .catch((error) => {
        console.log(error, "ERRemoENd")
      });
    }
  }

  useEffect(() => {
    getStep();
    return () => {};
  }, []);

  const forbidenSession = () => {
    if (checkIfActivityIsUnlocked(sessionStatus)) {
      switch (activeStep) {
        case 0:
          return(
            <Emotions 
              activeStep={activeStep} 
              setActiveStep={setActiveStep}
              handleNextStep={handleNextStep}
              emotionSave={emotionsStar}
              select={select}
              setSelect={setSelect}
            />
          )

        case 1:
          return(
            <Introduction
              activeStep={activeStep}
              setActiveStep={setActiveStep}
              handleNextStep={handleNextStep}
            />
          )

        case 2:
          return(
            <Emotions
              activeStep={activeStep}
              setActiveStep={setActiveStep}
              handleNextStep={handleNextStep}
              emotionSave={emotionsEnd}
              select={select}
              setSelect={setSelect}
            />
          )

        case 3:
          return(
            <ReadingStrategys toActivity={"actividad2"}
              activeStep={activeStep}
              setActiveStep={setActiveStep}
              handleNextStep={handleNextStep}
            />
          )
      
        default:
          break;
      }
    } else {
      return(
        <Redirect  
          to={{
            pathname: "/error/sesion-bloqueada",
            state: {
              reason:'sesion-bloqueada'
            },
          }}
        />
      )
    }
  }

  // {(forbidenSession() && activeStep === 0 && 
  //   <Emotions 
  //     activeStep={activeStep} 
  //     setActiveStep={setActiveStep}
  //     handleNextStep={handleNextStep}
  //     emotionSave={emotionsStar}
  //     select={select}
  //     setSelect={setSelect}
  //   />) || <p>No tienes la sesión previa</p>
  // }
  //   { ( activeStep === 1) && (
  //     <Introduction
  //       activeStep={activeStep}
  //       setActiveStep={setActiveStep}
  //       handleNextStep={handleNextStep}
  //     />
  //   )}
  //   { (activeStep === 2) && (
  //     <Emotions
  //       activeStep={activeStep}
  //       setActiveStep={setActiveStep}
  //       handleNextStep={handleNextStep}
  //       emotionSave={emotionsEnd}
  //       select={select}
  //       setSelect={setSelect}
  //     />
  //   )}
  //   { (activeStep === 3) && (
  //     <ReadingStrategys toActivity={"actividad2"}
  //       activeStep={activeStep}
  //       setActiveStep={setActiveStep}
  //       handleNextStep={handleNextStep}
  //     />
  //   )}

  console.log(sessionStatus, "sesion2")
  return(
    <Grid container >
      <Grid item className={classes.grid1} sm={12}> 
        <NavBar/>
      </Grid>
      <Grid item className={classes.grid2} sm={1}> 
        <MenuIzquierdo/>
      </Grid>
      <Grid item className={classes.grid3} sm={12}>
    
      {sessionStatus && forbidenSession()}
      </Grid>
    </Grid>
  )
}

export default ContainerSession2;
