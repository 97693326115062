import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
    ContenedorLectura:{
            width:'502px',
                  [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
    },
    ContainerTitulo:{
        margin:'0 0 34px 0',
        '& .Titulo':{
            letterSpacing: '0px',
            color: '#0B413C',
            margin:'0',
            textAlign:'center',
            font:'43px fredoka one',
            lineHeight:'52px',
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            margin:'0 0 36px 0',
            color:'#000',
            font:'18px lato',
            lineHeight :'28px',
            letterSpacing: '0.17px',
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .Imagen':{
            width:'100%',
        },  
      
    }
}));

const El_viajero_de_Hispania = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                        <h2 className="Titulo">El viajero de Hispania</h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12} justifyContent='center'>
                       <p className='Lectura'>
                        El viajero vio frente a él la torre del faro de la ciudad de Alejandría, su destino. 
                        Llevaba meses navegando por el Mediterráneo para transportar hasta la biblioteca 
                        un rollo de papiro que había protegido con celo. Se trataba de la única obra filosófica 
                        con la que no contaba aquel sitio.
                       </p>                   
                    </Grid>
                    <Grid container justifyContent='center'>
                        <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img1ro/Sesion20/1.png" alt="Tarjeta" />
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container justifyContent='center' >
                        <p className='Lectura'>
                        —Esto no puede ser —dijo el administrador cuando supo que en la lejana Hispania 
                        existía un tratado de filosofía desconocido en Alejandría.<br/><br/> 
                        
                        Los bibliotecarios se sentían orgullosos de cuidar todos los libros del mundo y todo el 
                        conocimiento universal, así que no podían permitir ese faltante. Entonces pidieron al 
                        rey que solicitara una copia al gobernante de Hispania.
                        </p>
                    </Grid>
                    <Grid container justifyContent='center'>
                        <Grid container justifyContent='center'>
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img1ro/Sesion20/2.png" alt="Tarjeta" />
                        </Grid>
                    </Grid>

                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container justifyContent='center' >
                        <p className='Lectura'>
                            El navegante desembarcó cansado pero satisfecho por la misión cumplida. La escolta 
                            real lo condujo hasta el edificio. Ahí lo esperaban los sabios que harían la copia en el 
                            museo, justo al lado de la gran biblioteca. <br/><br/>
                            
                            —¡Bienvenido a la casa de los libros!
                        </p>
                    </Grid>
                </Grid>
            </Grid>
    </Grid>
    );
}
 
export default El_viajero_de_Hispania;
