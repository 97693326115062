import React, { useContext, useEffect, useMemo } from "react";
import { makeStyles, Grid } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import CorrectFeedback from "./CorrectFeedback";
import WrongFeedback from "./WrongFeedback";

import Answers from "./Answers";
import { Link, useHistory } from "react-router-dom";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ReadingModal from "./ReadingModal";
import axios from "../../../services/axios";
import UsabilityContext from "../../../usability/UsabilityContext";

const useStyles = makeStyles((theme) => ({
  layout: {
    minHeight: "92vh",
    width: "100%",
  },
  evaluationsGrid: {
    height: "90%",
    boxSizing: "border-box",
  },
  mainTextContainer: {
    position: "relative",
    maxHeight: "94vh",
    width: "100%",
    [theme.breakpoints.down(1024)]: {
      display: "none",
    },
  },
  questionContainer: {
    backgroundColor: "#FEFAF1",
    maxHeight: "92vh",
    width: "100%",
    overflowY: "auto",
    [theme.breakpoints.down(1025)]: {
      width: "100%",
    },
    [theme.breakpoints.down(961)]: {
      width: "80%",
    },
    [theme.breakpoints.down(768)]: {
      padding: "0%",
    },
    [theme.breakpoints.down(620)]: {
      width: "90%",
    },
    [theme.breakpoints.down(570)]: {
      width: "100%",
    },
  },
  instructionsContainer: {
    width: "80%",
    minHeight: "12vh",
  },
  FinalInstructionsContainer: {
    width: "80%",
    minHeight: "40%",
  },
  buttonContainer: {
    width: "80%",
    height: "15vh",
  },
  reading: {
    textAlign: "justify",
    width: "80%",
    font: "22px Lato;",
    letterSpacing: "0.21px",
  },
  backButton: {
    height: "30px",
    backgroundColor: "#FEFAF1",
    color: "#F2624F",
    border: "#F2624F 1px solid",
    borderRadius: "20px",
    textTransform: "none",
    font: "regular 20px Lato",
    letterSpacing: "0.14px",
  },
  arrowBackIcon: {
    marginRight: "5px",
  },
  continueButton: {
    // marginTop: "6%",
    // marginBottom: "6%",
    textTransform: "none",
    height: "62px",
    width: "279px",
    [theme.breakpoints.down(1368)]: {
      height: "52px",
      width: "229px",
    },
    [theme.breakpoints.down(1068)]: {
      height: "52px",
      width: "60%",
    },
    [theme.breakpoints.down(769)]: {
      width: "56.5%",
    },
    background: "#7A54BA",
    boxShadow: "0px 3px 6px #00000029",
    borderRadius: "61px",
    color: "#FFFFFF",
    font: "bold 20px lato ",
    "&:hover": {
      background: "#dd78fb",
    },
  },
  answerContainer: {
    minHeight: "47vh",
  },
  answerTextContainer: {
    maxHeight: "30%",
  },
  progressLinearContainer: {
    height: "5vh",
    width: "72%",
    [theme.breakpoints.down(1024)]: {
      width: "82%",
    },
  },
  progressLinearBar: {
    width: "90%",
    height: "8%",
  },
  readingDemoContainer: {
    // maxHeight: "1024px",
    margin: "8% 0 8% 1%",
    overflowY: "scroll",
    scrollbarColor: "#F6DE95",
    scrollbarWidth: "15px",
    "&::-webkit-scrollbar": {
      width: "15px",
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "#F6DE95",
      webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#8144DF",
      borderRadius: "61px",
    },
  },
  arrowButton: {
    position: "absolute",
    left: "30px",
    margin: "2% 0 1% 0",
  },
  progress: {
    borderRadius: "7px",
    width: "95%",
    height: "15px",
    marginLeft: -"11.5%",
    boxShadow: " 1px 1px 4px rgba( 0, 0, 0, 0.2 )",
    "&::-webkit-progress-bar": {
      backgroundColor: "#F2624F",
      borderRadius: "7px",
    },
    "&::-webkit-progress-value": {
      backgroundColor: "#84E9DF",
      borderRadius: "7px",
      // boxShadow: "1px 1px 5px 3px rgba( 255, 0, 0, 0.8 )",
    },
  },
  link: {
    transform: "translate(-50%,-50%)",
    // right:'0%',
    // position:'absolute',
    textDecoration: "none",
  },
  readingButton: {
    display: "none",
    [theme.breakpoints.down(1024)]: {
      display: "grid",
    },
  },
  showReadingButton: {
    marginTop: "10px",
    textTransform: "none",
    font: "24px Fredoka One",
    color: "white",
    backgroundColor: "#F78572",
    borderRadius: "50px 50px 0 0",
    width: "411px",
    height: "58px",
    paddingTop: "20px",
    [theme.breakpoints.down(700)]: {
      width: "250px",
    },
  },
  emotionalTagContainer: {
    width: "47px",
    height: "47px",
    [theme.breakpoints.down(1400)]: {
      width: "37px",
      height: "37px",
    },
    [theme.breakpoints.down(1020)]: {
      height: "32px",
    },
  },
}));

const Activity = (props) => {
  const classes = useStyles();
  const {
    step,
    setStep,
    questionList,
    Lectura,
    questionComponent: QuestionComponent,
    imageList,
    enrollmendId,
    activityNumber,
    remedialNumber,
    toSession,
    tryNumber,
  } = props;

  const [openRight, setOpenRight] = React.useState(false);
  const [openWrong, setOpenWrong] = React.useState(false);
  const [isCorrect, setIsCorrect] = React.useState(false);
  const [pristine, setPristine] = React.useState(true);
  const [feedback, setFeedback] = React.useState("");
  const [answerText, setAnswerText] = React.useState("");
  const [answerImage, setAnswerImage] = React.useState("");
  const [currentAnswer, setCurrentAnswer] = React.useState("");
  const [openFinal, setOpenFinal] = React.useState(false);
  const [totalPoints, setTotalPoints] = React.useState(0);
  const [valueAnswerText, setValueAnswerText] = React.useState("");
  const [feedBackImage, setFeedBackImage] = React.useState(false);
  const [questionId, setQuestionId] = React.useState(0);
  const [openReading, setOpenReading] = React.useState(false);
  const [idPregunta, setIdPregunta] = React.useState(0);
  const [stopQuestion, setStopQuestion] = React.useState(false);
  const [disabledButton, setDisabledButton] = React.useState(false);



  const { handleLoading, handleModalMessage, modalMessageOpen } = useContext(UsabilityContext);

  const history = useHistory();

  const currentQuestion = useMemo(
    () => questionList[step],
    [questionList, step]
  );

  useEffect(() => {
    setIdPregunta(currentQuestion && currentQuestion.id_pregunta);
    setAnswerText("");
    setValueAnswerText("");
  }, [currentQuestion]);

  useEffect(() => {
    setDisabledButton(false);
  }, [step]);

  useEffect(() => {
    if (!modalMessageOpen) setDisabledButton(false);

    return () => {};
  }, [modalMessageOpen]);



  const postQuestionResponse = () => {
    handleLoading(true);
    const body = {
      id_actividad_inscripcion: enrollmendId,
      id_respuesta: currentAnswer,
      id_pregunta: idPregunta,
      intento: tryNumber,
      puntos: Number(isCorrect),
      respuesta_usuario: valueAnswerText || answerText,
    };
    axios
      .post("/respuesta/actividad/set", body)
      .then((response) => {
        if (response.status === 200) {
          isCorrect ? setOpenRight(true) : setOpenWrong(true);
          isCorrect && setTotalPoints(totalPoints + 1);
        }
      })
      .catch((error) => {
        if (!error.response.status) {
          handleModalMessage({
            habilite: true,
            mensaje: "Comprueba tu conexión a internet y vuelve a intentarlo.",
          })
        } else if (error.response.status === 500) {
          history.push({
            pathname: "/error/servicios",
            state: {
              reason: "sesion-servicios",
            },
          });
        } else if (error.response.status === 400) {
          if (step !== questionList.length - 1) {
            isCorrect ? setOpenRight(true) : setOpenWrong(true);
            isCorrect && setTotalPoints(totalPoints + 1);
          } else {
            setOpenFinal(true);
            setTotalPoints(totalPoints + 1);
            history.push({
              pathname: `/retro/${remedialNumber}/${activityNumber}/1`,
            });
          }
        }
      })
      .finally(() => {
        handleLoading(false);
      });
  }

  const feedBackContinue = () => {
    setStopQuestion(false);
    setDisabledButton(true);
    postQuestionResponse();
  };

  const nextQuestionIndex = useMemo(
    () =>
      questionList.findIndex(
        (question, index) => index > step && !question.respondida
      ),
    [questionList, step]
  );

  return (
    <>
      <Grid
        container
        direction="row"
        item
        sm={12}
        className={classes.layout}
        justifyContent="center"
      >
        <Grid
          container
          item
          md={6}
          direction="column"
          justifyContent="flex-start"
          alignItems="flex-start"
          className={classes.mainTextContainer}
        >
          <Grid className={classes.arrowButton}>
            <Link className={classes.link} to={toSession}>
              <Button className={classes.backButton}>
                {" "}
                <ArrowBackIcon /> Lectura original
              </Button>
            </Link>
          </Grid>
          <Grid
            container
            item
            xs={12}
            justifyContent="center"
            alignItems="center"
            className={classes.readingDemoContainer}
          >
            <Lectura />
          </Grid>
        </Grid>
        <Grid
          container
          item
          md={6}
          justifyContent="center"
          alignItems="center"
          className={classes.questionContainer}
        >
          <Grid
            container
            item
            xs={12}
            className={classes.emotionalTagContainer}
          ></Grid>
          <Grid
            container
            item
            justifyContent="flex-start"
            alignItems="center"
            className={classes.instructionsContainer}
          >
            {QuestionComponent}
          </Grid>
          <Grid
            container
            item
            justifyContent="center"
            alignItems="center"
            className={classes.answerContainer}
          >
            {questionList && questionList?.length>0 && (
              <Answers
                respuestas={questionList[step].respuestas}
                setPristine={setPristine}
                setIsCorrect={setIsCorrect}
                setFeedback={setFeedback}
                setAnswerText={setAnswerText}
                setCurrentAnswer={setCurrentAnswer}
                currentAnswer={currentAnswer}
                setAnswerImage={setAnswerImage}
                setTotalPoints={setTotalPoints}
                totalPoints={totalPoints}
                setValueAnswerText={setValueAnswerText}
                valueAnswerText={valueAnswerText}
                questionType={questionList[step].tipo_pregunta}
                feedBackImage={feedBackImage}
                setFeedBackImage={setFeedBackImage}
                questionId={questionId}
                setQuestionId={setQuestionId}
                imageList={imageList}
              />
            )}
          </Grid>
          <Grid
            container
            item
            justifyContent="center"
            alignItems="center"
            className={classes.buttonContainer}
          >
            {
              <Button
                id="BotonContinuarActividad"
                name="BotonContinuarActividad"
                className={classes.continueButton}
                disabled={disabledButton || pristine}
                onClick={feedBackContinue}
              >
                Continuar
              </Button>
            }
          </Grid>
          <Grid
            container
            item
            justifyContent="center"
            alignItems="center"
            className={classes.progressLinearContainer}
          >
            <progress
              value={step * 10}
              max={100}
              className={classes.progress}
            ></progress>
          </Grid>
          <Grid
            container
            item
            xs={12}
            justifyContent="center"
            alignItems="center"
            className={classes.readingButton}
          >
            <Button
              className={classes.showReadingButton}
              onClick={() => {
                setOpenReading(true);
              }}
            >
              Ver la lectura
            </Button>
          </Grid>
          <ReadingModal
            open={openReading}
            setOpen={setOpenReading}
            lectura={<Lectura />}
          />
        </Grid>
        {/* </Grid> */}
        <CorrectFeedback
          open={openRight}
          setOpen={setOpenRight}
          pregunta={QuestionComponent}
          feedback={feedback}
          answerText={answerText}
          setStep={setStep}
          step={step}
          setPristine={setPristine}
          feedBackImage={feedBackImage}
          setFeedBackImage={setFeedBackImage}
          questionId={questionId}
          imageList={imageList}
          remedialFinal={step === 9}
          remedialNumber={remedialNumber}
          stopQuestion={stopQuestion}
          setStopQuestion={setStopQuestion}
          tryNumber={tryNumber}
          nextQuestionIndex={nextQuestionIndex}
          activityNumber={activityNumber}
          totalQuestions={questionList.length}
          isRemedial={true}
        />
        <WrongFeedback
          open={openWrong}
          setOpen={setOpenWrong}
          pregunta={QuestionComponent}
          feedback={feedback}
          answerText={answerText}
          setStep={setStep}
          step={step}
          setPristine={setPristine}
          feedBackImage={feedBackImage}
          setFeedBackImage={setFeedBackImage}
          questionId={questionId}
          imageList={imageList}
          remedialFinal={step === 9}
          remedialNumber={remedialNumber}
          stopQuestion={stopQuestion}
          setStopQuestion={setStopQuestion}
          tryNumber={tryNumber}
          nextQuestionIndex={nextQuestionIndex}
          activityNumber={activityNumber}
          totalQuestions={questionList.length}
          isRemedial={true}
        />
      </Grid>
    </>
  );
};

export default Activity;
