const types = {
  connectToSocket: "connectToSocket",
  disconnectFromSocket: "disconnectFromSocket",
  actualizeChatList: "actualizeChatList",
  loadMessages: "loadMessages",
  loadMoreMessages: "loadMoreMessages",
  actualizeOnlineList: "actualizeOnlineList",
  sendToSpecificChat: "sendToSpecificChat",
  removeAlert: "removeAlert",
  addAlert: "addAlert",
  addMessageToFavorite: "addMessageToFavorite",
};

export default types;
