import React  from "react";
import { makeStyles,Grid}   from '@material-ui/core';
import NavBar               from '../../../../NavBar';
import MenuIzquierdo        from '../../../../MenuIzquierdo';
import Activity             from '../../Activity';
import Lectura              from '../../../LecturasActividad/LecturasSexto/Sesion35/Lectura';
import QuestionProv         from "../../QuestionProv";
import Question             from "../../Question";

import QuestionTable        from './QuestionTable'
import QuestionTableImg     from './QuestionTableImg'

import picture1             from './image/CL_G6_B2_S35_RG14_E3_Mamuts_01.png'
import picture2             from './image/CL_G6_B2_S35_RG14_E3_Mamuts_02.png'
import picture3             from './image/CL_G6_B2_S35_RG14_E3_Mamuts_03.png'
import picture4             from './image/CL_G6_B2_S35_RG14_E3_Mamuts_04.png'
import picture5             from './image/CL_G6_B2_S35_RG14_E3_Mamuts_05.png'
import picture6             from './image/CL_G6_B2_S35_RG14_E3_Mamuts_06.png'
import picture7             from './image/CL_G6_B2_S35_RG14_E3_Mamuts_07.png'
import picture8             from './image/CL_G6_B2_S35_RG14_E3_Mamuts_08.png'
import picture9             from './image/CL_G6_B2_S35_RG14_E3_Mamuts_09.png'
import picture10             from './image/CL_G6_B2_S35_RG14_E3_Mamuts_10.png'
import useGetExcercisesData from "../../../../../hooks/useGetExcercisesData";

const useStyles = makeStyles(theme=>({
  grid1:{    
    zIndex:'3'
    
  },
  grid2:{    
    zIndex:'2'
    
  },
  grid3:{    
    boxSizing:'border-box',
    zIndex:'1',
    paddingLeft:'3%',
    [theme.breakpoints.down('md')]:{
      paddingLeft:'0px',
    }
    
  }
}))

const ActivityS35 = (props) => {
  const classes = useStyles()
  const activityId = 351;
  const { data, step, setStep, questionList, setQuestionList } =
    useGetExcercisesData(activityId);

  const imageList = {
    1454: picture1,
    1455: picture2,
    1456: picture3,
    1457: picture4,
  }

  const picturesTable = [
    picture5,
    picture6,
    picture7,
    picture8,
    picture9,
    picture10,
  ]

  const renderQuestion = () => {
    return(
      step === 9 ?
      <QuestionProv
      pregunta={ questionList.length > 0 ? questionList[step].pregunta : "cargando..."}
      step={step}/>
    :
      step === 0 ?
        <QuestionTable
        />
        :
        step === 5 ?
        <QuestionTableImg
          // picturesTable={picturesTable}
          picture5={picture5}
          picture6={picture6}
          picture7={picture7}
          picture8={picture8}
          picture9={picture9}
          picture10={picture10}
        />
        :
        <Question
          step={step}
          pregunta={ questionList.length > 0 ? questionList[step].pregunta : "cargando..."}
        />
    )
  }

  return(
    <Grid container >
      <Grid item className={classes.grid1} sm={12}> 
        <NavBar/>
      </Grid>
      <Grid item className={classes.grid2} sm={1}> 
        <MenuIzquierdo/>
      </Grid>
      <Grid item className={classes.grid3} sm={12}>
        <Activity
          step={step}
          setStep={setStep}
          questionList={questionList}
          setQuestionList={setQuestionList}
          questionComponent={renderQuestion()}
          Lectura={Lectura}
          imageList={imageList}
          picturesTable={picturesTable}
          activityNumber={activityId}
          enrollmendId={data && data.id_actividad_inscripcion}
          tryNumber={data && data.intento}
          toSession={'/session35'}
        />
      </Grid>
    </Grid>
  )
}

export default ActivityS35;
