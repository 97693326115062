import React, {useEffect}   from "react";
import RedirecToErrorActivity from "../../../../hooks/redirectToerrorActivity";
import { useHistory }         from 'react-router-dom';
import { makeStyles,Grid}   from '@material-ui/core';
import NavBar               from '../../../../NavBar';
import MenuIzquierdo        from '../../../../MenuIzquierdo';
import Activity             from '../../Activity';
import Lectura              from '../../../LecturasActividad/LecturasPrimero/Sesion26/Lectura';
import axios                from "../../../../../services/axios";
import QuestionProv         from "../../QuestionProv";
import Question             from "../../Question";

import QuestionImg             from "./QuestionImg"

import picture1             from './img/CL_G1_B2_S26_RL2_PMate_El_panal01.png';
import picture2             from './img/CL_G1_B2_S26_RL2_PMate_El_panal02.png';
import picture3             from './img/CL_G1_B2_S26_RL2_PMate_El_panal03.png';
import picture4             from './img/CL_G1_B2_S26_RL2_PMate_El_panal04.png';
import picture5             from './img/CL_G1_B2_S26_RL2_PMate_El_panal05.png';
import picture6             from './img/CL_G1_B2_S26_RL2_PMate_El_panal06.png';
import picture7             from './img/CL_G1_B2_S26_RL2_PMate_El_panal07.png';
import picture8             from './img/CL_G1_B2_S26_RL2_PMate_El_panal08.png';
import picture9             from './img/CL_G1_B2_S26_RL2_PMate_El_panal09.png';
import picture10            from './img/S26_R7_ILUS_Una_loseta_cuadrada.png';
import picture11            from './img/S26_R7_ILUS_Una_loseta_de_triangulo_equilatero.png';
import picture12            from './img/S26_R7_ILUS_Una_loseta_rectangular.png';
import picture13            from './img/S26_R7_ILUS_Una_loseta_hexagonal.png';

const useStyles = makeStyles(theme=>({
  grid1:{    
    zIndex:'3'
    
  },
  grid2:{    
    zIndex:'2'
    
  },
  grid3:{    
    boxSizing:'border-box',
    zIndex:'1',
    paddingLeft:'3%',
    [theme.breakpoints.down('md')]:{
      paddingLeft:'0px',
    }
    
  }
}))

const ActivityS26 = (props) => {
  const classes = useStyles()
  const history = useHistory()
  const [step, setStep]                     = React.useState(0)
  const [questionList, setQuestionList]     = React.useState([])
  const [data, setData]                     = React.useState({})
  
  useEffect(() => {
    const body = {"id_actividad": 27};
    if(!questionList.length){
      axios.get( '/actividad/preguntas/get?id_actividad=27', body)
      .then(response => {
        setQuestionList(response.data.resultado.preguntas)
        setData(response.data.resultado)
        setStep(response.data.resultado.preguntas.findIndex(pregunta => !pregunta.respondida))
        RedirecToErrorActivity(response.data.resultado, history)
      })
    }
  }, [questionList.length, history ])

  const renderQuestion = () => {
    return(
      step === 9 ?
      <QuestionProv
      pregunta={ questionList.length > 0 ? questionList[step].pregunta : "cargando..."}
      step={step}/>
      :
      step === 3 ?
      <QuestionImg
        picture1={picture9}
      />
      :
      <Question
        step={step}
        pregunta={ questionList.length > 0 ? questionList[step].pregunta : "cargando..."}
      />
    )
  }

  const imageList = {
    5779: picture1,
    5780: picture2,
    5781: picture3,
    5782: picture4,
    5783: picture5,
    5784: picture6,
    5785: picture7,
    5786: picture8,
    5799: picture10,
    5800: picture11,
    5801: picture12,
    5802: picture13,
  }

  return(
    <Grid container >
      <Grid item className={classes.grid1} sm={12}> 
        <NavBar/>
      </Grid>
      <Grid item className={classes.grid2} sm={1}> 
        <MenuIzquierdo/>
      </Grid>
      <Grid item className={classes.grid3} sm={12}>
        <Activity
          step={step}
          setStep={setStep}
          questionList={questionList}
          setQuestionList={setQuestionList}
          questionComponent={renderQuestion()}
          Lectura={Lectura}
          imageList={imageList}
          // picturesTable={picturesTable}
          activityNumber={27}
          enrollmendId={data && data.id_actividad_inscripcion}
          tryNumber={data && data.intento}
          toSession={'/session26'}
        />
      </Grid>
    </Grid>
  )
}

export default ActivityS26;
