import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
      Contenedor:{
        background:'#E9FBF9',
        width:'100vw',
        overflowX:'clip',
        [theme.breakpoints.down(1161)]:{
            background:'none',
           overflowX:'unset',
        },
        [theme.breakpoints.down(600)]:{
            marginTop: '79.51px',
        }
    },
    ContainerInfoLectura:{
        position:'relative',
        height:'86px',
        background:'#76D4C3',
        '& .BarraInfoLectura':{
            height:'86px',
            background:`Url(https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Barras/Azul.png)`,

        },
        '& .InfoLectura':{
            position:'absolute',
            height:'86px',
            width:'219px',
            boxSizing:'border-box',
            padding:'17.2px 0',
            background:'#FAFAFA',
            borderRadius:'50px',
            boxShadow: '2px 2px 2px 2px #00000029',
            zIndex:'4',
        },
        '& .InfoLectura:before':{
            position:'absolute',
            display:'flex',
            boxSizing:'border-box',
            padding:'0 0 0 10px',
            justifyContent:'center',
            alignItems:'center',
            height:'39.88px',
            width:'39.88px',
            borderRadius:'50%',
            content:'""',
            boxShadow: '3px 0px .5px 0px #00000029',
            background:'#FAFAFA',
            left:'90px',
            top:'65px',
            transform: 'rotate(0.25turn)',
        },
        '& .InfoLectura:after':{
            content:'""',
            height:'15px',
            width:'15px',
            borderTop:'2px solid #20A698',
            borderLeft:'2px solid #20A698',
            position:'absolute',
            bottom:'-10px',
            left:'Calc(50% - 8px)',
            transform: 'rotate(0.625turn)',
        },
        '& .Sesion':{
            borderLeft:'2px solid #D8D8D8',
        },
        '& .Dato':{
            margin:'0 0 5px 0',
            textAlign:'center',
            font:'bold 17px lato',
            color:'#20A698',
        },
        '& .Caracter':{
            margin:'0',
            textAlign:'center',
            font:'bold 17px lato',
            color:'#000000',
        },
    },
    ContenedorLectura:{
        background:'#fff',
        width:'1160px',
        height:'100%',
        padding:'60px 168px 35px',
        [theme.breakpoints.down(1161)]:{
            width:'502px',
            padding:'70px 0 0',
          },
        [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },

        '& .ContainerDerechos':{
            bottom:'80px',
            [theme.breakpoints.down(1161)]:{
                display:'none',
            },
        },
        '& .Derechos':{
            textAlign:"center",
            margin:"0px",
            font:" 15px lato",
            color:"#717476",
        } ,
        '& .Escritorio':{
            display:'block',
            [theme.breakpoints.down(1161)]:{
                display:'none'
            },
        },
        '& .Tablet':{
            display:'none',
            [theme.breakpoints.down(1161)]:{
                display:'block'
            },
        }

    },
    ContainerTitulo:{
        margin:'0 0 46px 0',
        '& .Titulo':{
            font:'48px fredoka one',
            letterSpacing: '0px',
            color: '#F6664D',
            margin:'0',
            textAlign:'center',
            [theme.breakpoints.down(1161)]:{
                font:'43px fredoka one',
                lineHeight:'52px',
            },
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
        
    },

    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            lineHeight : '30px',
            margin:'0 0 36px 0 ',
            font:'22px lato',
            color:'#000',
            letterSpacing: '0.20px',
            [theme.breakpoints.down(1161)]:{
                lineHeight :'28px',
                font:'18px lato',
                letterSpacing: '0.17px',
            },
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .Lectura2':{
            letterSpacing:'.19',
            margin:' 0',
        },
        '& .Lectura3':{
            margin:' 0',
        },
        '& .TextImg':{
            margin:'22px 0 0 0',
            font:'20px lato',
            alignSelf:'flex-start',
            color:'#717476',
            [theme.breakpoints.down(1161)]:{
                font:' 16px lato',
                letterSpacing:'.12px',
            },
            [theme.breakpoints.down(502)]:{
                font:' 11px lato',
                lineHeight :'14px',
                letterSpacing: '0.10px',
            },
        },
        '& .Imagen':{
            [theme.breakpoints.down(1161)]:{
                width:'100%',
            },
        },

    },
    

}));

const Las_palabras_importan = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
             <Grid container item justifyContent="center" className={classes.ContainerInfoLectura}>
               <Grid container className="BarraInfoLectura"></Grid>
               <Grid container item className="InfoLectura">
                    <Grid container item direction="column" xs={6} justifyContent="center">
                        <p className="Dato">Grado</p>
                        <p className="Caracter">6</p>
                    </Grid>
                    <Grid container item direction="column" xs={6} justifyContent="center" className="Sesion">
                        <p className="Dato">Sesión</p>
                        <p className="Caracter">9</p>
                    </Grid>
               </Grid>
            </Grid>

            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >
                        Las palabras importan
                    </h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                            Imagina esta curiosa escena que bien pudo haber sucedido en una hermosa y fría 
                            madrugada del año 1600, debajo de un cielo completamente despejado y sobre una 
                            pequeña colina frente al río Moldava en Praga. <br/><br/>
                            
                            Sentados sobre el pasto, hombro con hombro, se distingue la silueta de dos de los 
                            más importantes astrónomos de todos los tiempos observando el amanecer. Podrías 
                            pensar que ambos interpretan de la misma manera lo que sucede en ese mismo 
                            instante, pero no es así.<br/><br/>

                            El mayor de los dos es Tycho Brahe, un hombre maduro de 54 años que ha dedicado 
                            más de 30 años al estudio de los astros. Él está convencido de que el espectáculo que 
                            están presenciando es el resultado del movimiento del Sol alrededor de la Tierra. 
                            Para Tycho, nuestro planeta es el centro inmóvil del universo, de manera que el Sol y 
                            la Luna giran alrededor de él. <br/><br/>
                            
                            Su acompañante es Johannes Kepler, un joven de tan sólo 29 años que ya muestra 
                            rasgos de una genialidad que lo harán destacar como una de las mentes más 
                            brillantes de la historia. Su idea es opuesta, pues cree que la Tierra es la que se 
                            encuentra en movimiento. Por lo tanto, rechaza que sea el centro del universo, tal 
                            como también lo había expresado Copérnico algunos años atrás.<br/><br/>
                        </p>
                    </Grid>

                    <Grid container justifyContent="center" sm={12}>
                        <Grid container justifyContent="center" alignItems='flex-end'>
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion9/1.png" alt="Tarjeta" />
                        </Grid>
                        <Grid container >
                            <p className="TextImg"> 
                            Johannes Kepler fue uno de los científicos que cuestionó la posición del Sol 
                            con respecto a los planetas de nuestro sistema.
                            </p>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                            Lo que sucedió hace tantos siglos entre Brahe y Kepler es algo que acontece todos 
                            los días: las ideas, creencias y valores le dan forma y sentido a nuestro mundo a partir 
                            del lenguaje. Para que entiendas mejor esta idea, observa otro ejemplo que se 
                            relaciona también con el Universo, pero que es más reciente.<br/><br/>
                            
                            Durante siglos se dijo que los planetas de nuestro sistema solar eran nueve: 
                            Mercurio, Venus, Tierra, Marte, Júpiter, Saturno, Urano, Neptuno y Plutón. Pero 
                            apenas a inicios de este siglo, ciertos astrónomos descubrieron más cuerpos celestes 
                            alrededor de Plutón que tenían prácticamente su mismo tamaño y masa. Esto generó 
                            un gran problema para la comunidad astronómica: o se les daba a todos esos cuerpos 
                            la etiqueta de planeta o se le tenía que quitar esa categoría a Plutón. El veredicto fue 
                            la segunda opción.<br/><br/>
                            
                            Este ejemplo permite explicar que la realidad depende de cómo se le nombre. En el 
                            caso de Plutón y de Kepler, lo que ahora entendemos como real dependió de lo que 
                            se nombró en su momento. <br/><br/>
                            
                            Durante mucho tiempo se creyó que la realidad estaba afuera y que su existencia no 
                            dependía de las personas que la observaran, ni del lenguaje que utilizaran para 
                            nombrarla. Pero sobre todo a partir del  siglo <span style={{fontVariant: "all-small-caps"}}>XX</span> se ha llegado a la conclusión de que 
                            el observador participa en la <em>construcción</em> de la realidad por medio del lenguaje. Es 
                            decir, constantemente se les da valor y significado al mundo y al Universo a partir de 
                            la manera en que se les nombra.
                        </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <Grid container justifyContent="center" alignItems='flex-end'>
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion9/2.png"alt="Tarjeta" />
                        </Grid>
                        <Grid container >
                            <p className="TextImg"> 
                                En otras palabras, el lenguaje no habla tanto sobre lo que hay en el mundo. Más bien, explica la 
                                forma en que los individuos lo piensan, interpretan y viven. Lo cual no sólo aplica para las 
                                cosas que los rodean, sino para la manera en la que se construyen de manera personal y social.
                            </p>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura ">
                            Reflexiona que las palabras, por un lado, te permiten convertirte en lo que dices que 
                            eres. Por ello, si dices de ti constantemente que eres una persona resiliente, capaz de 
                            cumplir metas, lo más seguro es que no sea fácil darte por vencido en momentos 
                            difíciles. Por otro lado, el lenguaje también sirve para mantener o transformar los 
                            usos y las costumbres del entorno en el que se vive. Un ejemplo reciente de ello es la 
                            fuerza con la que paulatinamente se está generalizando el lenguaje inclusivo en cada 
                            vez más círculos de la sociedad. <br/><br/>
                            
                            En conclusión, el lenguaje no sólo describe la realidad, sino que también la construye. 
                            Y mientras se utilice de manera correcta, se podrá moldear un mundo más justo y 
                            equitativo. En su novela <em>Partir</em>, Lucía Baskaran escribe esta frase incisiva: “Lo que no 
                            se nombra no existe, o peor aún, se lo llama con un nombre que no tiene nada que ver 
                            con lo nombrado”.
                        </p>

                    </Grid>
                </Grid>
            <Grid container item xs={12} justifyContent="center" className="ContainerDerechos">
                <p className="Derechos">
                    © 2022. Todos los derechos reservados, Pearson, Circuito de lectores.
                </p>                
            </Grid>
        </Grid>
    </Grid>
    );
}
 
export default Las_palabras_importan;
