import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
    ContenedorLectura:{
        height:'100%',
        width:'100%',
        [theme.breakpoints.down(660)]:{
        width:'350px',
        padding:'0',    
        },
    },

    Parrafo1:{
        '& .Imagen':{
            width:'100%'
        },
    }
}));

const Credencia_escolar = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img1ro/Sesion52/F.png" alt="Tarjeta" />
                </Grid>
            </Grid>
    </Grid>
    );
}
 
export default Credencia_escolar;
