import React               from "react";
import {Grid, makeStyles}                 from '@material-ui/core';

const useStyles = makeStyles(theme =>({
  questionContainer:{
    width: "100%",
    height:"100%",
    // overflowY: "scroll",
    // scrollbarColor: "#F6DE95",
    // scrollbarWidth: "10px",
    // '&::-webkit-scrollbar': {
    //   width: '15px',
    // },
    // '&::-webkit-scrollbar-track': {
    //   borderRadius: '25px',
    //   backgroundColor: "#F6DE95",
    //   webkitBoxShadow: 'inset 0 0 3px rgba(0,0,0,0.00)'
    // },
    // '&::-webkit-scrollbar-thumb': {
    //   backgroundColor: '#8144DF',
    //   borderRadius: '25px',
    // },
  },
  reading: {
    width: "98%",
    font: "22px lato",
    letterSpacing:'0',
    margin:"14px 0 14px 0",
    [theme.breakpoints.down(1370)]:{
      font: "500 16px lato",
      width: "90%",
      margin:"10px 0 10px 0"
    },
    [theme.breakpoints.down(1310)]:{
      font: "500 16px lato",
      // width: "80%",
      margin:"10px 0 10px 0"
    },
    [theme.breakpoints.down(925)]:{
      font: "500 14px lato",
      // width: "70%",
      margin:"6px 0 6px 0"
    },
    [theme.breakpoints.down(426)]:{
      font: "500 12px lato",
      // width: "70%",
      margin:"6px 0 6px 5%"
    }, 
  },
  tableText: {
    width: "80%",
    margin:"0",
    font: '22px lato',
    letterSpacing: '0.21px',
    textAlign: 'left',
    [theme.breakpoints.down(1400)]:{
      font: " 18px lato",
      width: "85%",
    },
    [theme.breakpoints.down(1310)]:{
      font: "500 16px lato",
      // width: "80%",
    },
    [theme.breakpoints.down(925)]:{
      font: "500 14px lato",
      // width: "70%",
    },
    [theme.breakpoints.down(426)]:{
      font: "500 12px lato",
      // width: "70%",
    },
  },
  table: {
    // border: '1px solid black',
    width: "100%",
    font: '22px lato',
    [theme.breakpoints.down(1400)]:{
      font: " 18px lato",
    },
  },
  tdTable: {
    padding: "0 1%",
    // border: '1px solid black',
  }
}));

const QuestionTable = (props) => {
  const classes = useStyles()

  return(
    <Grid container className={classes.questionContainer}>
      <p
        className={classes.reading}
      >
        3. Lee la oración para que elijas la relación que describe correctamente el verbo <i>recibas</i>.
      </p>
      <p className={classes.reading}>
        Las marcas invierten mucho dinero para que <b>recibas</b> mensajes todo el tiempo.
      </p>
      <br/>
      <table className={classes.table}>
        <thead>
          <th style={{width: "50%", textAlign: "left"}}><b>Modo</b></th>
          <th style={{width: "50%", textAlign: "left"}}><b>Tiempo</b></th>
        </thead>
        <tbody>
          <tr>
            <td className={classes.tdTable}>
              <p className={classes.tableText}>
                1. Indicativo
              </p>
            </td>
            <td className={classes.tdTable}>
              <p className={classes.tableText}>
                a. Presente
              </p>
            </td>
          </tr>
          <tr>
            <td className={classes.tdTable}>
              <p className={classes.tableText}>
                2. Subjuntivo
              </p>
            </td>
            <td className={classes.tdTable}>
              <p className={classes.tableText}>
                b. Pasado
              </p>
            </td>
          </tr>
          <tr>
            <td className={classes.tdTable}>
              <p>

              </p>
            </td>
            <td className={classes.tdTable}>
              <p className={classes.tableText}>
                c. Futuro
              </p>
            </td>
          </tr>
        </tbody>
      </table>
    </Grid>
  )
}

export default QuestionTable;
