import React from "react";
import { Grid, makeStyles, Button } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

import Creditos from "./Creditos";

const useStyles = makeStyles((theme) => ({
  MuiDialogContainer: {
    borderRadius: "61px",
    height: "90%",
    width: "90%",
    backgroundColor: "#FEFAF1",
    border: "#707070 2px solid",
    backdropFilter: "blur(3px)",
    // [theme.breakpoints.down(1300)]:{
    //     width:'100%',
    // },
    // [theme.breakpoints.down(468)]:{
    //   height: "250px",
    //   maxWidth: "80%",
    // },
  },
  MuiContentRoot: {
    overflowY: "auto",
    [theme.breakpoints.up("lg")]: {
      "&::-webkit-scrollbar": {
        width: "auto",
      },
      "&::-webkit-scrollbar-track": {
        backgroundColor: "#7A54BA20",
        webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#7A54BA",
        borderRadius: "5px",
      },
    },
  },
  continueButton: {
    marginBottom: "20px",
    textTransform: "none",
    height: "62px",
    width: "279px",
    background: "#7A54BA",
    boxShadow: "0px 3px 6px #00000029",
    borderRadius: "61px",
    color: "#FFFFFF",
    font: "bold 20px lato ",
    "&:hover": {
      background: "#dd78fb",
    },
    [theme.breakpoints.down(1368)]: {
      height: "52px",
      width: "229px",
    },
    [theme.breakpoints.down(1068)]: {
      height: "52px",
      width: "60%",
    },
    [theme.breakpoints.down(769)]: {
      width: "56.5%",
    },
  },
  mainTitle: {
    font: "28px fredoka one",
    margin: "0px",
    textAlign: "center",
    [theme.breakpoints.up("md")]: {
      font: "36px fredoka one",
    },
    [theme.breakpoints.up("xl")]: {
      font: "42px fredoka one",
    },
  },
}));

const GenericActivityDialog = (props) => {
  const classes = useStyles();
  const { open, setOpen } = props;

  const handleClose = () => {
    setOpen(false);
  };

  // const myTimeout = setTimeout(() => {setOpen(false)}, 3000)

  return (
    <Dialog
      open={open}
      classes={{
        paper: classes.MuiDialogContainer,
      }}
      disableEscapeKeyDown
      maxWidth="lg"
    >
      <DialogTitle style={{ textAlign: "center" }}>
        <span className={classes.mainTitle}>Créditos</span>
      </DialogTitle>
      <DialogContent
        classes={{
          root: classes.MuiContentRoot,
        }}
      >
        <Creditos />
      </DialogContent>
      <DialogActions>
        <Grid container justifyContent="center" alignItems="center">
          <Button onClick={handleClose} className={classes.continueButton}>
            Volver
          </Button>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default GenericActivityDialog;
