import { activityStatus } from "../../constants/activities/status";

const checkIfActivityIsUnlocked = (sessionStatus) => {
  const sessionStatusLowercase = sessionStatus?.toLowerCase();
  console.log(sessionStatusLowercase)
  return (
    sessionStatusLowercase === activityStatus.desbloqueada ||
    sessionStatusLowercase === activityStatus.completa
  );
};

export default checkIfActivityIsUnlocked;
