import React   from "react";
import { makeStyles,Grid}   from '@material-ui/core';
import NavBar               from '../../../../NavBar';
import MenuIzquierdo        from '../../../../MenuIzquierdo';
import Activity             from '../../Activity';
import Lectura              from '../../../LecturasActividad/LecturasSexto/Sesion8/Lectura';
import QuestionProv         from "../../QuestionProv";
import Question             from "../../Question";
import QuestionImg          from './QuestionImg'
import QuestionImg2         from './QuestionImg2'
import QuestionImg3         from './QuestionImg3'

import picture2             from './image/CL_G6_B1_S08_IC36_36_E2_lateoriaderamseyenejemplos_02.png'
import picture3             from './image/CL_G6_B1_S08_IC36_36_E2_lateoriaderamseyenejemplos_03.png'
import picture4             from './image/CL_G6_B1_S08_IC36_36_E2_lateoriaderamseyenejemplos_04.png'
import picture5             from './image/CL_G6_B1_S08_IC36_36_E2_lateoriaderamseyenejemplos_05.png'
import picture7             from './image/CL_G6_B1_S08_IC36_36_E2_lateoriaderamseyenejemplos_07.png'
import picture8             from './image/CL_G6_B1_S08_IC36_36_E2_lateoriaderamseyenejemplos_08.png'
import picture9             from './image/CL_G6_B1_S08_IC36_36_E2_lateoriaderamseyenejemplos_09.png'
import picture10            from './image/CL_G6_B1_S08_IC36_36_E2_lateoriaderamseyenejemplos_10.png'
import picture11            from './image/CL_G6_B1_S08_IC36_36_E2_lateoriaderamseyenejemplos_11.png'
import picture6             from './image/CL_G6_B1_S08_IC36_E2_lateoriaderamseyenejemplos_06.png'
import picture1             from './image/CL_G6_B1_S08_IC36_E2_lateoriaderamseyenejemplos_01.png'
import useGetExcercisesData from "../../../../../hooks/useGetExcercisesData";

const useStyles = makeStyles(theme=>({
  grid1:{    
    zIndex:'3'
    
  },
  grid2:{    
    zIndex:'2'
    
  },
  grid3:{    
    boxSizing:'border-box',
    zIndex:'1',
    paddingLeft:'3%',
    [theme.breakpoints.down('md')]:{
      paddingLeft:'0px',
    }
    
  }
}))

const ActivityS08 = (props) => {
  const classes = useStyles()
  const activityId = 323;
  const { data, step, setStep, questionList, setQuestionList } =
    useGetExcercisesData(activityId);

  const imageList = {
    419: picture3,
    420: picture4,
    421: picture5,
    422: picture2,
    435: picture8,
    436: picture9,
    437: picture10,
    438: picture11,
  }

  const renderQuestion = () => {
    switch (step) {
      case (1):
        return (
          <QuestionImg
            picture={picture1}
          />
        )
      case (4):
        return (
          <QuestionImg2
            picture={picture6}
          />
        )
      case (5):
        return (
          <QuestionImg3
            picture={picture7}
          />
        )
      case (9):
        return (
          <QuestionProv
          pregunta={ questionList.length > 0 ? questionList[step].pregunta : "cargando..."}
          step={step}/>
        )
      default:
        return (
          <Question
            step={step}
            pregunta={ questionList.length > 0 ? questionList[step].pregunta : "cargando..."}
          />
        )
    }
  }

  return(
    <Grid container >
      <Grid item className={classes.grid1} sm={12}> 
        <NavBar/>
      </Grid>
      <Grid item className={classes.grid2} sm={1}> 
        <MenuIzquierdo/>
      </Grid>
      <Grid item className={classes.grid3} sm={12}>
        <Activity
          step={step}
          setStep={setStep}
          questionList={questionList}
          setQuestionList={setQuestionList}
          questionComponent={renderQuestion()}
          Lectura={Lectura}
          imageList={imageList}
          // picturesTable={picturesTable}
          activityNumber={activityId}
          enrollmendId={data && data.id_actividad_inscripcion}
          tryNumber={data && data.intento}
          toSession={'/session08'}
        />
      </Grid>
    </Grid>
  )
}

export default ActivityS08;
