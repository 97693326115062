import { makeStyles, Grid, Button } from "@material-ui/core";
import React from "react";
import { Link } from "react-router-dom";
import DocenteEspecifico from "./DocenteEspecifico";
import DocenteGenerico from "./DocenteGenerico";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    padding: "48px 0 48px 0",
    width: "100vw",
    gap: "20px",
    minHeight: "100vh",
    background:
      "linear-gradient(217deg, rgba(255,194,0,0.4962185557816877) 2%, rgba(255,255,255,1) 25%, rgba(255,255,255,0.4962185557816877) 81%, rgba(144,81,177,1) 98%)",
    [theme.breakpoints.up("sm")]: {},
  },
  boxContainer: {
    background: "#fff",
    width: "80%",
    height: "auto",
    borderRadius: "42px",
    padding: "20px",
    alignItems: "center",
    gap: "20px",
    boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.5)",
    [theme.breakpoints.up("md")]: {
      padding: "48px 20px 48px 20px",
    },
  },
  titleContainer: {
    margin: 0,
    [theme.breakpoints.up("md")]: {
      fontSize: "36px",
    },
  },
  title: {
    font: "28px Fredoka One ",
    margin: "0",
    textAlign: "center",
    color: " #DC5EA6",
    [theme.breakpoints.up("md")]: {
      fontSize: "36px",
    },
    [theme.breakpoints.up("xl")]: {
      fontSize: "42px",
    },
  },
  indicationsText: {
    font: "20px Lato",
  },
  link: {
    cursor: "pointer",
    textDecoration: "none",
    minWidth: "238px",
  },
  buttonReturn: {
    width: "238px",
    // maxWidth: "320px",
    borderRadius: "61px",
    border: "3px solid #9051b1",
    backgroundColor: "#FFFFFF",
    color: "#353535",
    font: " bold Lato",
    fontSize: "16px",
    letterSpacing: "1.96px",
    textTransform: "none",
    textDecoration: "none",
    textAlign: "center",
    padding: "5px 0",
    marginTop: "20px",
    fontWeight: "500",
    "&:hover": {
      backgroundColor: "#FFFFFF",
      fontWeight: "bold",
    },
    [theme.breakpoints.up("lg")]: {
      padding: "10px 0",
      fontSize: "18px",
    },
    [theme.breakpoints.up("xl")]: {
      padding: "20px 0",
      fontSize: "22px",
      maxWidth: "450px",
    },
  },
  WitheButton: {
    color: "#9051b1",
    // borderColor: "#9051b1",
    border: "1px solid #9051b1",
    backgroundColor: "#FFFFFF",
    borderRadius: "30px",
    // margin: "0 auto",
    width: "100%",
    maxWidth: "238px",
    minHeight: "45px",
    textTransform: "unset",
    cursor: "pointer",
    fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
    fontWeight: "500",
    padding: "6px 16px",
    lineHeight: "1.75",
    transition:
      "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    boxShadow:
      "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
    "&:hover": {
      backgroundColor: "#B965D330 ",
    },
  },
}));

const RegistroDocente = ({ location }) => {
  const data = location?.state?.data;
  const classes = useStyles();

  return (
    <>
      <Grid
        container
        item
        sm={12}
        alignItems="center"
        justifyContent="center"
        className={classes.mainContainer}
      >
        <Grid
          container
          item
          direction="column"
          className={classes.boxContainer}
        >
          <Grid
            container
            item
            alignItems="center"
            justifyContent="center"
            className={classes.titleContainer}
          >
            <h2 className={classes.title}>Acceso perfil docente</h2>
          </Grid>
          <Grid
            container
            item
            alignItems="center"
            justifyContent="center"
            className={classes.indicationsText}
          >
            <p>
              Comprueba los datos y completa los que te falten para poder
              acceder a <b>Circuito de lectores.</b>
            </p>
          </Grid>

          {data?.id_tipo_codigo === 1 && <DocenteEspecifico data={data} />}
          {data?.id_tipo_codigo === 2 && <DocenteGenerico data={data} />}

          <Grid
            container
            item
            alignItems="center"
            justifyContent="center"
            className={classes.titleContainer}
          >
            <Link className={classes.link} to="/registro">
              <Button className={classes.WitheButton}>Salir</Button>
            </Link>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default RegistroDocente;
