import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
    Contenedor:{
       background:'#E9FBF9',
       width:'100vw',
       [theme.breakpoints.down(1161)]:{
           background:'none',
       },
       [theme.breakpoints.down(600)]:{
           marginTop: '79.51px',
       }
   },
  ContainerInfoLectura: {
    position: "relative",
    height: "86px",
    [theme.breakpoints.down(502)]:{
        height: "43px",
    },
    background: "#76D4C3",
    "& .BarraInfoLectura": {
      height: "86px",
      background: `Url(https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Barras/Azul.png)`,
        [theme.breakpoints.down(502)]:{
            height: "43px",
        },
    },
    "& .InfoLectura": {
      position: "absolute",
      height: "86px",
      width: "219px",
      boxSizing: "border-box",
      padding: "17.2px 0",
      background: "#FAFAFA",
      borderRadius: "50px",
      boxShadow: "2px 2px 2px 2px #00000029",
      zIndex: "4",
        [theme.breakpoints.down(502)]:{
            height: "43px",
            width: "109px",
            padding: "8.6px 0",
            borderRadius: "25px",
        },
    },
    "& .InfoLectura:before": {
      position: "absolute",
      display: "flex",
      boxSizing: "border-box",
      padding: "0 0 0 10px",
      justifyContent: "center",
      alignItems: "center",
      height: "39.88px",
      width: "39.88px",
      borderRadius: "50%",
      content: '""',
      boxShadow: "3px 0px .5px 0px #00000029",
      background: "#FAFAFA",
      left: "90px",
      top: "65px",
      transform: "rotate(0.25turn)",
      [theme.breakpoints.down(502)]:{
        left: "45px",
        top: "30px",
        height: "20.88px",
        width: "20.88px",
        },
    },
    "& .InfoLectura:after": {
      content: '""',
      height: "15px",
      width: "15px",
      borderTop: "2px solid #20A698",
      borderLeft: "2px solid #20A698",
      position: "absolute",
      bottom: "-10px",
      left: "Calc(50% - 8px)",
      transform: "rotate(0.625turn)",
      [theme.breakpoints.down(502)]:{
        height: "10px",
        width: "10px",
        left: "Calc(50% - 4.5px)",
        bottom: "-3px",
        borderTop: "1px solid #20A698",
        borderLeft: "1px solid #20A698",
        },
    },
    "& .Sesion": {
      borderLeft: "2px solid #D8D8D8",
    },
    "& .Dato": {
      margin: "0 0 5px 0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#20A698",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        margin: "0 0 2.5px 0",
        },
    },
    "& .Caracter": {
      margin: "0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#000000",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        },
    },
  },
   ContenedorLectura:{
       background:'#fff',
       width:'1160px',
       height:'100%',
       padding:'44px 168px 35px ',
       [theme.breakpoints.down(1161)]:{
           width:'502px',
           padding:'30px 0 0 0',
         },
         [theme.breakpoints.down(502)]:{
           width:'350px',
           padding:'40px 0 0 0',
         },
         [theme.breakpoints.down(350)]:{
            width:'300px',
            padding:'40px 0 0 0',
          },
       '& .ContainerDerechos':{
           bottom:'80px',
           [theme.breakpoints.down(1161)]:{
               display:'none',
           },
       },
       '& .Derechos':{
           textAlign:"center",
           margin:"0px",
           font:" 15px lato",
           color:"#717476",
       },
       '& .Escritorio':{
           display:'block',
           [theme.breakpoints.down(1161)]:{
               display:'none'
           },
       },
       '& .Tablet':{
           display:'none',
           [theme.breakpoints.down(1161)]:{
               display:'block'
           },
       }
   },
   Parrafo1:{
    margin:' 0 0 36px 0',
    position:'relative',
    '& .Titulo':{
        letterSpacing: '0px',
        color: '#86BDD3',
        margin:'0 0 53px 0',
        textAlign:'center',
        font:'58px fredoka one',
        lineHeight:'70px',
        [theme.breakpoints.down(1161)]:{
            font:'43px fredoka one',
            lineHeight:'52px',
        },
        [theme.breakpoints.down(502)]:{
            font:'26px fredoka one',
            lineHeight:'32px',
            margin:'0 0 15px 0',
        },
        [theme.breakpoints.down(350)]:{
            font:'22px fredoka one',
            lineHeight:'28px',
            margin:'0 0 8px 0',
        },
    },
    '& .Lectura':{
        margin:'0 0 36px 0 ',
        color:'#000',
        font:'22px lato',
        lineHeight : '30px',
        letterSpacing: '0.20px',
        width:'763px',
        [theme.breakpoints.down(1161)]:{
            font:'18px lato',
            lineHeight :'28px',
            letterSpacing: '0.17px',
            width:'502px',
        },
        [theme.breakpoints.down(502)]:{
            font:'11px lato',
            lineHeight :'14.2px',
            letterSpacing: '0.09px',
            width:'300px',
        },
        [theme.breakpoints.down(350)]:{
            font:'10px lato',
            lineHeight :'11.2px',
            letterSpacing: '0.09px',
            width:'250px',
        },
    },
    '& .SubTitulo':{
        color:'#A26F5A',
        margin:'0',
        font:'bold 30px lato',
        letterSpacing:'.28px',
        lineHeight:'30px',
        [theme.breakpoints.down(1161)]:{
            lineHeight :'28px',
            font:'bold 26px lato',
            letterSpacing: '0.24px',
        },
        [theme.breakpoints.down(502)]:{
            font:'bold 16px lato',
            lineHeight :'18px',
            letterSpacing: '0.21px',
        },
        [theme.breakpoints.down(350)]:{
            font:'bold 14px lato',
            lineHeight :'18px',
            letterSpacing: '0.21px',
        },
    },
   '& .TextImg':{
        margin:'0 0 36px 0',
        alignSelf:'flex-start',
        color:'#717476',
        font:'20px lato',
        letterSpacing:'.19px',
        lineHeight:'30px',
        [theme.breakpoints.down(1161)]:{
            textAlign:'left',
            font:' 16px lato',
            lineHeight :'25px',
            letterSpacing: '0.15px',
        },
        [theme.breakpoints.down(502)]:{
            margin:'0 0 12px 0',
            font:' 12px lato',
            lineHeight :'14px',
            letterSpacing: '0.15px',
        },
        [theme.breakpoints.down(350)]:{
            margin:'0 0 10px 0',
            font:' 10px lato',
            lineHeight :'11px',
            letterSpacing: '0.15px',
        },
    },
    '& .Pos':{
        position:'absolute',
        top:'33px',
        [theme.breakpoints.down(502)]:{
            top:'20px',
        },
    },
    '& .Pos2':{
        position:'absolute',
        top:'1608px',
        [theme.breakpoints.down(1161)]:{
            top:'1400px',
        },
        [theme.breakpoints.down(502)]:{
            top:'53%',
        },
    },
    '& .Imagen':{
        [theme.breakpoints.down(502)]:{
            width:'110%',
        },
    },
    
},
    

}));

const La_escasez_de_recursos = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
             <Grid container item justifyContent="center" className={classes.ContainerInfoLectura}>
               <Grid container className="BarraInfoLectura"></Grid>
               <Grid container item className="InfoLectura">
                    <Grid container item direction="column" xs={6} justifyContent="center">
                        <p className="Dato">Grado</p>
                        <p className="Caracter">4</p>
                    </Grid>
                    <Grid container item direction="column" xs={6} justifyContent="center" className="Sesion">
                        <p className="Dato">Sesión</p>
                        <p className="Caracter">40</p>
                    </Grid>
               </Grid>
            </Grid>

            <Grid container item className={classes.ContenedorLectura} justifyContent="center">

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <img className="Imagen Escritorio" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img4to/Sesion40/1.png"  alt="Tarjeta" />
                    <img className="Imagen Tablet" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img4to/Sesion40/1R.png"  alt="Tarjeta" />
                    <Grid container item xs={12} justifyContent="center"  className='Pos'>
                        <p className='Lectura'>
                            <h2 className="Titulo" >
                                La escasez de recursos
                            </h2>
                            Los seres humanos usan diferentes recursos proporcionados por la naturaleza 
                            y el medio en el que viven. Por ejemplo, necesitan plantas y animales para 
                            alimentarse y ocupan materiales como árboles, agua y minerales para elaborar 
                            productos. Día a día, se explotan recursos para satisfacer las necesidades de la 
                            sociedad.<br/><br/>
                            
                            Esta situación existe desde hace siglos y la mayoría de las personas no 
                            reconoce la gravedad. No todos los recursos son renovables, es decir, que no 
                            todos pueden nacer nuevamente. Eso significa que ya no habrá más 
                            suministros cuando algunos se terminen. Por eso es necesario cuidarlos, 
                            utilizarlos responsablemente y generar consciencia sobre su uso. <br/><br/>
                            
                            <h3 className='SubTitulo'>La escasez del agua</h3><br/>
                            
                            El planeta se compone en su gran mayoría de agua, pero no toda es apta para 
                            ser bebida. Ésta es llamada <em>potable</em>, pero hay poca en el mundo y es posible que 
                            se agote en nuestra era. Mucha está gravemente contaminada por actividades 
                            industriales y resulta difícil y costoso regresarla a su estado natural Además, 
                            diariamente se utilizan cantidades inmensas para prácticas como la ganadería. 
                            El agua es el elemento más importante para nosotros, por eso lo utilizamos 
                            tanto, por eso es fundamental comenzar a preservarla desde este momento.
                        </p>
                    </Grid>

                    <Grid container item xs={12} justifyContent="center"  className='Pos2'>
                        <Grid>
                            <p className='Lectura'>
                                <p className='TextImg'>Participa en el cuidado del agua tomando duchas cortas y evitando lavar objetos que no lo necesiten.</p>
                            
                                Este recurso está distribuido de una manera poco igualitaria, como ocurre con 
                                muchos otros. Existen países que sufren escasez de agua desde hace años o 
                                incluso décadas, especialmente aquellos con índices de pobreza altos. Hay 
                                personas que no pueden hacer actividades tan esenciales como asearse, lavar 
                                su ropa y sus utensilios de cocina. Mientras tanto, hay otras naciones que 
                                acaparan este elemento y permiten a sus habitantes usar cuanta deseen, 
                                incluso si la desperdician o la contaminan.<br/><br/> 
                                
                                Es importante tomar acciones desde este momento para evitar que esto siga 
                                sucediendo.<br/><br/>
                                
                                <h3 className='SubTitulo'>Sin helio, se acabó la fiesta</h3><br/>
                                
                                ¿Alguna vez has tenido un globo que flota por sí solo y se va si no lo sujetas con 
                                algo? Globos como éste son inflados con un gas llamado <em>helio</em>, y no pueden 
                                llenarse con nuestro propio aire, como ocurre con los demás globos. Se trata 
                                de un gas especial, que no hay mucho en el planeta. Y a su escasez se suma el 
                                hecho de que no hay forma de recuperarlo cuando se escapa porque se eleva al 
                                cielo, atraviesa la atmósfera y se pierde en el espacio exterior.<br/><br/>
                                
                                En la medicina es importante porque hay algunos estudios que lo utilizan, por 
                                ejemplo en las resonancias magnéticas. ¿Te imaginas que todo el helio que hay 
                                en la Tierra se terminara en globos? Ya no existiría la oportunidad de hacer
                                estudios médicos que son necesarios para diagnosticar y tratar enfermedades 
                                como el cáncer. Por eso algunos investigadores y científicos piden que no se 
                                utilice para cosas innecesarias. Además, existen otras opciones para la 
                                decoración de fiestas que son más amigables con el planeta y tienen un menor 
                                impacto en el medio ambiente.<br/><br/>
                                
                                Hay materiales que no pueden recuperarse, por eso reciben el nombre de 
                                <em> recursos no renovables</em>. Existen varios, por eso es un fundamental evitar su 
                                desaparición. Ahora que conoces más sobre esta situación, ¿se te ocurre algún 
                                plan para cuidar el agua y el helio?                            </p>
                        </Grid>
                    </Grid>
                    
                </Grid>

                <Grid container item xs={12} justifyContent="center" className="ContainerDerechos">
                    <p className="Derechos">
                        © 2022. Todos los derechos reservados, Pearson, Circuito de lectores.
                    </p>                
                </Grid>
        </Grid>
    </Grid>
    );
}
 
export default La_escasez_de_recursos;
