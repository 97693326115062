import React, { useContext, useState } from "react";
import { Grid, makeStyles, withStyles, Button } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

import Menu from "@material-ui/core/Menu";
import ListItemText from "@material-ui/core/ListItemText";
import MenuItem from "@material-ui/core/MenuItem";
import AuthContext from "../../../../../auth/AuthContext";

const useStyles = makeStyles((theme) => ({
  BotonAvanza: {
    position: "absolute",
    bottom: "52px",
    right: "134px",
    textTransform: "none",
    textDecoration: "none",
    height: "62px",
    width: "279px",
    background: "#7A54BA",
    boxShadow: "0px 3px 6px #00000029",
    borderRadius: "61px",
    color: "#FFFFFF",
    font: "bold 20px lato ",
    "&:hover": {
      background: "#dd78fb",
    },
    [theme.breakpoints.down(1161)]: {
      position: "relative",
      bottom: "32px",
      left: "0",
      right: "0",
    },
  },
  BotonPractica: {
    position: "absolute",
    bottom: "52px",
    right: "134px",
    textTransform: "none",
    textDecoration: "none",
    height: "62px",
    width: "279px",
    background: "#7A54BA",
    boxShadow: "0px 0px 15px 15px #fff",
    borderRadius: "61px",
    color: "#FFFFFF",
    font: "bold 20px lato ",
    "&:hover": {
      background: "#dd78fb",
    },
    [theme.breakpoints.down(1161)]: {
      position: "relative",
      bottom: "32px",
      left: "0",
      right: "0",
    },
  },
  MuiDialogContainer: {
    borderRadius: "61px",
    height: "400px",
    width: "70%",
    backgroundColor: "#FEFAF1",
    border: "#707070 2px solid",
    backdropFilter: "blur(3px)",
    overflow: "visible",
    [theme.breakpoints.down(1568)]: {
      height: "300px",
      maxWidth: "75%",
    },
    [theme.breakpoints.down(468)]: {
      height: "250px",
      maxWidth: "80%",
    },
  },
  shadowBar: {
    // display: (props) => (props.openMenuInDesktop ? "none" : "flex"),
    width: "100%",
    height: "100%",
    position: "absolute",
    backgroundColor: "rgba(0, 0, 0, .5)",
    borderRadius: "59px",
  },
  continueButton: {
    marginBottom: "20px",
    textTransform: "none",
    height: "62px",
    width: "279px",
    background: "#7A54BA",
    boxShadow: "0px 0px 15px 20px #fff",
    borderRadius: "61px",
    color: "#FFFFFF",
    font: "bold 20px lato ",
    position: "relative",
    zIndex: 999,
    "&:hover": {
      background: "#dd78fb",
    },
    [theme.breakpoints.down(1368)]: {
      height: "52px",
      width: "229px",
    },
    [theme.breakpoints.down(1068)]: {
      height: "52px",
      width: "60%",
    },
    [theme.breakpoints.down(769)]: {
      width: "56.5%",
    },
  },
  gridAudioContainer: {
    height: "100%",
  },
  audioControls: {
    width: "80%",
    border: "2px solid #00DAC9",
    borderRadius: "35px",
    zIndex: 999,

    [theme.breakpoints.down(769)]: {
      width: "90%",
    },
  },
  menu: {
    display: "flex",
    flexDirection: "column",
    overflow: "visible",
    alignItems: "center",
  },
  paper: {
    overflow: "visible",
  },
  dialogButtonMenuItems: {
    bottom: "115%",
    margin: "0 auto",
    backgroundColor: "#3f43b3",
    fontFamily: "Lato",
    letterSpacing: "0.36px",
    fontSize: "20px",
    color: "#ffffff",
    position: "absolute",
    minWidth: "430px",
    width: "auto",
    maxWidth: "460px",
    padding: "24px",
    boxSizing: "border-box",
    borderRadius: "21.25px",

    "&:before": {
      // display: (props) => (props.openMenuInDesktop ? "none" : "flex"),
      display: "flex",
      zIndex: 1,
      textTransform: "none",
      justifyContent: "center",
      alignItems: "center",
      // borderRadius: "25px",
      position: "absolute",
      content: '""',
      border: "15px solid",
      borderTopColor: "#3f43b3",
      borderLeftColor: "transparent",
      borderBottomColor: "transparent",
      borderRightColor: "transparent",
      font: "bold 14px lato",
      right: "calc(50% - 15px)",
      bottom: "-28px",
    },
  },
  dialogButtonContinuar: {
    backgroundColor: "#3f43b3",
    fontFamily: "Lato",
    letterSpacing: "0.36px",
    fontSize: "20px",
    color: "#ffffff",
    position: "absolute",
    top: "-45px",
    minWidth: "480px",
    right: "110%",
    width: "auto",
    maxWidth: "520px",
    padding: "24px",
    boxSizing: "border-box",
    borderRadius: "21.25px",
    [theme.breakpoints.down(1160)]: {
      bottom: "100%",
      right: "unset",
      top: "unset",
    },

    "&:before": {
      // display: (props) => (props.openMenuInDesktop ? "none" : "flex"),
      display: "flex",
      zIndex: 1,
      textTransform: "none",
      justifyContent: "center",
      alignItems: "center",
      // borderRadius: "25px",
      position: "absolute",
      content: '""',
      border: "15px solid",
      borderTopColor: "transparent",
      borderLeftColor: "#3f43b3",
      borderBottomColor: "transparent",
      borderRightColor: "transparent",
      font: "bold 14px lato",
      top: "calc(50% - 15px)",
      right: "-28px",
      [theme.breakpoints.down(1160)]: {
        right: "calc(50% - 15px)",
        top: "unset",
        bottom: "-28px",
        borderLeftColor: "transparent",
        borderTopColor: "#3f43b3",
      },
    },
  },
  dialogModalButtonVolver: {
    backgroundColor: "#3f43b3",
    fontFamily: "Lato",
    letterSpacing: "0.36px",
    fontSize: "20px",
    color: "#ffffff",
    position: "absolute",
    minWidth: "430px",
    top: "125%",
    width: "auto",
    maxWidth: "460px",
    padding: "24px",
    boxSizing: "border-box",
    borderRadius: "21.25px",

    "&:before": {
      // display: (props) => (props.openMenuInDesktop ? "none" : "flex"),
      display: "flex",
      zIndex: 1,
      textTransform: "none",
      justifyContent: "center",
      alignItems: "center",
      // borderRadius: "25px",
      position: "absolute",
      content: '""',
      border: "15px solid",
      borderTopColor: "transparent",
      borderLeftColor: "transparent",
      borderBottomColor: "#3f43b3",
      borderRightColor: "transparent",
      font: "bold 14px lato",
      right: "calc(50% - 15px)",
      top: "-28px",
    },
  },
}));

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    "&:focus": {
      backgroundColor: theme.palette.primary.main,
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

const Practica = (props) => {
  const { grade, source, index, setNextStepButton, setAnchorEl, step, cb } =
    props;
  const classes = useStyles();
  const [finished, setFinished] = React.useState(true);
  const [open, setOpen] = React.useState(false);
  const handleCloseDialog = () => {
    setOpen(false);
    setNextStepButton(false);
    setAnchorEl(null);
  };

  return (
    <>
      <StyledMenuItem
        key={index}
        onClick={() => {
          setOpen(true);
        }}
      >
        <ListItemText primary={`${grade}° grado`} onClick={cb} />
      </StyledMenuItem>
      <Dialog
        open={open}
        classes={{ paper: classes.MuiDialogContainer }}
        disableEscapeKeyDown
      >
        <DialogTitle>
          <Grid container justifyContent="center" alignItems="center">
            <p>Grado: {grade}</p>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            className={classes.gridAudioContainer}
          >
            <audio
              controls
              autoPlay
              controlsList="nodownload"
              onEnded={() => {
                setFinished(false);
              }}
              className={classes.audioControls}
            >
              <source src={source} type="audio/mp3"></source>
            </audio>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container justifyContent="center" alignItems="center">
            <Button
              onClick={handleCloseDialog}
              disabled={finished}
              className={classes.continueButton}
            >
              Volver
              {!step?.introduction.three && (
                <p className={classes.dialogModalButtonVolver}>
                  Después se abrirá una ventana donde escucharás la práctica,
                  cuando el audio termine se activará el botón <b> Volver, </b>
                  haz clic sobre éste.
                </p>
              )}
            </Button>
          </Grid>
        </DialogActions>
        <div className={classes.shadowBar}></div>
      </Dialog>
    </>
  );
};

const PracticasUno = (props) => {
  const classes = useStyles();
  const { activeStep, setActiveStep, handleNextStep, step, handleStep } = props;

  const {
    user: { grade },
  } = useContext(AuthContext);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [relajacionList, setRelajacionList] = React.useState([]);
  const [nextStepButton, setNextStepButton] = useState(true);

  const practicaRelajacion = () => {
    const practica_guiada_list = [
      {
        id_recurso_actividad: 4,
        grado: 1,
        tipo: "relajacion",
        nombre: "Practica guiada 1 minuto",
        recurso:
          "https://pearson-cdl-contenidos-qa.s3.amazonaws.com/G1/practica/G1_1_min_El_Globo_relajacion.mp3",
        id_actividad: null,
      },
      {
        id_recurso_actividad: 10,
        grado: 2,
        tipo: "relajacion",
        nombre: "Practica guiada 1 minuto",
        recurso:
          "https://pearson-cdl-contenidos-qa.s3.amazonaws.com/G2/practica/G2_1_min_La goma_relajacion.mp3",
        id_actividad: null,
      },
      {
        id_recurso_actividad: 16,
        grado: 3,
        tipo: "relajacion",
        nombre: "Practica guiada 1 minuto",
        recurso:
          "https://pearson-cdl-contenidos-qa.s3.amazonaws.com/G3/practica/G3_1_min_La_taza_y_la_flor_relajacion.mp3",
        id_actividad: null,
      },
      {
        id_recurso_actividad: 22,
        grado: 4,
        tipo: "relajacion",
        nombre: "Practica guiada 1 minuto",
        recurso:
          "https://pearson-cdl-contenidos-qa.s3.amazonaws.com/G4/practica/G4_1min_Tension_suavidad_relajacion.mp3",
        id_actividad: null,
      },
      {
        id_recurso_actividad: 28,
        grado: 5,
        tipo: "relajacion",
        nombre: "Practica guiada 1 minuto",
        recurso:
          "https://pearson-cdl-contenidos-qa.s3.amazonaws.com/G5/practica/G5_1min_El_limon_relajacion.mp3",
        id_actividad: null,
      },
      {
        id_recurso_actividad: 34,
        grado: 6,
        tipo: "relajacion",
        nombre: "Practica guiada 1 minuto",
        recurso:
          "https://pearson-cdl-contenidos-qa.s3.amazonaws.com/G6/practica/G6_1min_Sonidos_relajacion.mp3",
        id_actividad: null,
      },
    ];
    let grado = 0;
    switch (grade) {
      case "primero":
        grado = 1;
        break;
      case "segundo":
        grado = 2;
        break;
      case "tercero":
        grado = 3;
        break;
      case "cuarto":
        grado = 4;
        break;
      case "quinto":
        grado = 5;
        break;

      default:
        grado = 6;
        break;
    }

    setRelajacionList(
      practica_guiada_list.filter((practica) => practica.grado === grado)
    );
  };

  const handleOpen1 = (event) => {
    // setAnchorEl1(document.getElementById("practicaB1"));
    handleStep({
      ...step,
      introduction: {
        ...step.introduction,
        one: true,
      },
    });
    setAnchorEl(event.currentTarget);
    practicaRelajacion();
  };

  const handleClose = () => {
    setAnchorEl(null);
    handleStep({
      ...step,
      introduction: {
        ...step.introduction,
        two: false,
        one:false,
      },
    });
  };

  const handleContinue = () => {
    setActiveStep(activeStep + 1);
    handleNextStep();
  };

  return (
    <>
      {nextStepButton ? (
        <>
          <Button
            id="practicaB1"
            className={
              !step?.introduction.one
                ? classes.BotonPractica
                : classes.BotonAvanza
            }
            onClick={handleOpen1}
          >
            Práctica guiada
            {!step?.introduction.one && (
              <p className={classes.dialogButtonContinuar}>
                Esta es la pantalla de Introducción a la lección, lee con
                atención el texto, cuando termines, haz clic en el botón
                Práctica guiada.
              </p>
            )}
          </Button>
        </>
      ) : (
        <Button
          className={
            !step?.introduction.four
              ? classes.BotonPractica
              : classes.BotonAvanza
          }
          onClick={handleContinue}
        >
          Continuar
          {!step?.introduction.four && (
            <p className={classes.dialogButtonContinuar}>
              Verás cómo el botón de la parte inferior derecha cambia y ahora se
              llama <b> Continuar, </b> haz clic para avanzar.
            </p>
          )}
        </Button>
      )}

      <div>
        <StyledMenu
          id="customized-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          classes={{ list: classes.menu, paper: classes.paper }}
        >
          {!step?.introduction.two && (
            <p className={classes.dialogButtonMenuItems}>
              Se abrirá un menú, donde seleccionarás el
              <b> grado </b>
              de la práctica que te indicó tu docente.
            </p>
          )}
          {relajacionList &&
            relajacionList.map((relajacion, index) => {
              return (
                <Practica
                  key={index + "-relajacion"}
                  index={index}
                  source={relajacion?.recurso}
                  grade={relajacion?.grado}
                  nextStepButton={nextStepButton}
                  setNextStepButton={setNextStepButton}
                  setAnchorEl={setAnchorEl}
                  step={step}
                  cb={() => {
                    handleStep({
                      ...step,
                      introduction: {
                        ...step.introduction,
                        two: true,
                      },
                    });
                  }}
                  // handleClose={handleClose}
                />
              );
            })}
        </StyledMenu>
      </div>
    </>
  );
};

export default PracticasUno;
