import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
    ContenedorLectura:{
        width:'502px',
                  [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
    },
    ContainerTitulo:{
        margin:'0 0 61px 0',
        '& .Titulo':{
            letterSpacing: '0px',
            color: '#0D91E5',
            margin:'0',
            textAlign:'center',
            font:'43px fredoka one',
            lineHeight:'52px',
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            margin:'0 0 36px 0 ',
            color:'#000',
            font:'18px lato',
            lineHeight :'28px',
            letterSpacing: '0.17px',
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },

        '& .SubTitulo':{
            color:'#EAA304',
            margin:'0',
            lineHeight :'28px',
            font:'bold 26px lato',
            letterSpacing: '0.24px',
            [theme.breakpoints.down(502)]:{
                font:'bold 18px lato',
                lineHeight :'24px',
                letterSpacing: '0.21px',
            },
        },
        '& .TextImg':{
            color:'#717476',
            margin:'21px 0 0 0',
            font:' 16px lato',
            lineHeight :'25px',
            letterSpacing: '0.15px',
            [theme.breakpoints.down(502)]:{
                font:' 11px lato',
                lineHeight :'14px',
                letterSpacing: '0.10px',
            },
        },
        '& .Imagen':{
            width:'100%',
        },

    },

}));

const Arqueología_subacuática = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >
                        Arqueología subacuática
                    </h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                      <p className="Lectura">
                        La <em>arqueología</em> es una disciplina que estudia los restos materiales de la vida y las 
                        actividades humanas del pasado. Al ser una ciencia, los arqueólogos siguen una serie de 
                        pasos y técnicas para analizar los objetos que encuentran. Éstos pueden ser cualquier 
                        cosa hecha por la humanidad, desde herramientas de piedra hasta asentamientos 
                        completos, como las pirámides de Egipto o la ciudadela de Machu Picchu.<br/><br/>

                        El campo de la arqueología no se limita a la superficie terrestre: también abarca lo que hay 
                        debajo del agua. Muchas actividades humanas están relacionadas con ella, como la pesca, 
                        el comercio, la guerra y los viajes. Actualmente, estos restos son estudiados por una rama 
                        que se llama <em>arqueología subacuática</em>.
                      </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <Grid container item justifyContent='center' xs={12}>
                        <img className="Imagen " src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img4to/Sesion11/1.png" alt="Tarjeta" />
                        </Grid>
                        <Grid container item justifyContent='center'>
                            <p className='TextImg'>Los objetos que transportaban los barcos en otras épocas y que están perdidos en el fondo del mar permiten observar cómo era la vida en otras épocas.</p>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                      <p className="Lectura">
                        <h3 className='SubTitulo'>El rompecabezas de la historia</h3><br/>
                        
                        Esta vertiente arqueológica comenzó a mediados del siglo <span style={{fontVariant: "all-small-caps"}}>XX</span>. Se desarrolló varios 
                        años después de la arqueología general, la cual surgió en el siglo <span style={{fontVariant: "all-small-caps"}}>XVIII</span>. Siempre había 
                        existido interés por conocer lo que había en las profundidades del mar, pero no se 
                        contaba con tecnología que permitiera inspeccionar esos y otros cuerpos acuáticos.<br/><br/> 
                        
                        La mayor necesidad era un invento que dejara respirar a los buzos por periodos más 
                        largos. Pero Jacques Cousteau y Émile Gagnan diseñaron un regulador de buceo 
                        conocido como el <em>pulmón acuático</em> en la década de los años cuarenta. Gracias a él, los 
                        exploradores tuvieron la posibilidad de moverse libremente y recorrer más 
                        distancias.<br/><br/> 
                        
                        Esta nueva herramienta ayudó a encontrar objetos perdidos y a conocer más sobre la 
                        historia de la humanidad por medio de ellos. Para la arqueología, los objetos son 
                        como piezas de un rompecabezas, las cuales brindan información sobre el pasado 
                        cuando se toma en cuenta el lugar donde fueron hallados y se estudian en conjunto. 
                        Los materiales encontrados bajo el agua no tienen valor por sí mismos para los 
                        arqueólogos, a diferencia de lo que ocurre con cazadores de tesoros.
                      </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <Grid container item justifyContent='center'>
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img4to/Sesion11/2.png" alt="Tarjeta" />
                        </Grid>
                        <Grid container item justifyContent='center'>
                            <p className='TextImg'>Hoy en día la arqueología subacuática ha cobrado gran importancia gracias al apoyo de los distintos gobiernos y entidades que buscan preservar los tesoros históricos.</p>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container item sm={12}>
                        <p className='Lectura '>
                            <h3 className='SubTitulo'>Los retos por vencer</h3><br/>
                            
                            Esta ciencia aún enfrenta algunos problemas, a pesar de sus avances tecnológicos. 
                            Por ejemplo, el hecho de que los buzos no pueden permanecer bajo el agua durante 
                            mucho tiempo y les es difícil ver la fauna del lugar con claridad. Otro inconveniente 
                            es que hay peces que pueden ser peligrosos, como el pez escorpión, que se oculta en 
                            los objetos y ataca cuando los tocan. Por eso, los investigadores procuran tener 
                            mucho cuidado al realizar sus búsquedas.<br/><br/> 
                            
                            En América Latina existen varios sitios arqueológicos subacuáticos. En ellos hay 
                            restos prehistóricos, precolombinos y de la época colonial. Un hallazgo de esta época 
                            son los barcos naufragados, que atraen tanto a científicos como a cazadores de 
                            tesoros. Estos últimos pueden perjudicar las investigaciones porque se llevan las 
                            piezas que encuentran y sin ellas los estudios pierden información valiosa. <br/><br/>
                            
                            Como puedes ver, los arqueólogos tienen la tarea de explicar parte de la historia 
                            humana con sus exploraciones, ¡pero también de proteger el patrimonio de los 
                            pueblos y de toda la humanidad!
                        </p>
                    </Grid>
                </Grid>
        </Grid>
    </Grid>
    );
}
 
export default Arqueología_subacuática;
