import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
    ContenedorLectura:{
        width:'502px',
                  [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
    },
    ContainerTitulo:{
        margin:'0 0 46px 0',
        '& .Titulo':{
            letterSpacing: '0px',
            color: '#E1AF10',
            margin:'0',
            textAlign:'center',
            font:'43px fredoka one',
            lineHeight:'52px',
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            margin:'0 0 36px 0 ',
            color:'#000',
            font:'18px lato',
            lineHeight :'28px',
            letterSpacing: '0.17px',
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .SubTitulo':{
            color:'#F78572',
            margin:'0',
            font:'bold 22px lato',
            lineHeight :'24px',
            letterSpacing: '0.21px',
            [theme.breakpoints.down(502)]:{
                font:'bold 18px lato',
                lineHeight :'20px',
                letterSpacing: '0.16px',
            },
        },
        '& .TextImg':{
            margin:'24px 0 0 0',
            alignSelf:'flex-start',
            color:'#717476',
            font:' 16px lato',
            lineHeight :'25px',
            letterSpacing: '0.15px',
            [theme.breakpoints.down(502)]:{
                font:' 11px lato',
                lineHeight :'14px',
                letterSpacing: '0.10px',
            },
        },
        '& .Imagen':{
            width:'100%',
        },
    },
    

}));

const Qué_hacían_los_niños_hace_10000_años = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >
                        ¿Qué hacían los niños<br/>
                         hace 10 000 años?
                    </h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                            A medida que pasan los años, las sociedades cambian. Eso incluye las cosas que 
                            hacen los niños. Por ejemplo, en la actualidad son hábiles en el uso de aparatos y 
                            tecnología, pues es algo que está a su alrededor. Aunque cada uno de ellos es 
                            distinto, existen gustos e intereses generacionalmente comunes.<br/><br/> 
                            
                            Saber lo que hacían los niños en la Prehistoria es un tema que genera curiosidad. Y 
                            gracias a la <em>arqueología</em> (que se encarga de estudiar el paso de la humanidad a través 
                            de la historia) se han podido responder algunas preguntas puntuales.<br/><br/> 
                            
                            <h3 className='SubTitulo'>¿Qué comían?</h3> <br/>

                            En Alemania y Austria (en Europa) se han encontrado objetos que se piensa que son 
                            biberones prehistóricos, mismos que datan de hasta 5 000 años a. n. e. Son pequeñas 
                            vasijas de arcilla en forma de animales extraños con dos patas. Tienen una 
                            embocadura provista de un pequeño orificio por donde se cree que salía el líquido. <br/><br/>
                            
                            Estos biberones se encontraron en tumbas de niños en edades de entre seis meses y 
                            tres años. En ellos descubrieron trazas de grasa animal, es decir, leche de distintos 
                            animales (como vaca, cerdo y cabra). El consumo de ésta en la Prehistoria mejoró 
                            la nutrición humana y eso, a su vez, permitió aumentar la población.
                        </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <Grid container >
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img2do/Sesion30/1.png" alt="Tarjeta" />
                        </Grid>
                        <Grid container>
                            <p className='TextImg'>
                                Los objetos hallados en cuevas permiten explorar la historia y conocer más acerca de quiénes 
                                somos y de dónde venimos.</p>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                        <h3 className='SubTitulo'>¿Cuáles eran sus juegos?</h3> <br/>

                            A los niños del Neolítico y la Edad de Bronce les gustaba imitar lo que hacían sus 
                            padres. Se han encontrado herramientas muy pequeñas para enderezar las lanzas 
                            usadas como juguetes. Esto los preparaba para su primera caza.
                        </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <Grid container >
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img2do/Sesion30/2.png"  alt="Tarjeta" />
                        </Grid>
                        <Grid container>
                            <p className='TextImg'>
                                Durante la niñez solemos representar mediante juegos de roles lo que hacen los adultos que 
                                nos rodean. Es importante que recordemos esto para que seamos más conscientes del 
                                ejemplo que les damos.
                                </p>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                        <h3 className='SubTitulo'>¿Qué les gustaba dibujar?</h3> <br/>

                            En 2006 algunos arqueólogos ingleses y australianos encontraron trazos blancos de 
                            13 000 años de antigüedad en una cueva de Francia. Sugirieron que unos fueron 
                            realizados por niños de entre 2 y 5 años y que otros fueron hechos en su mayoría por 
                            una niña de entre 5 y 7. Esto los llevó a concluir que los niños paleolíticos fueron 
                            responsables de mucho más arte de lo que se creía inicialmente.
                        </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <Grid container >
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img2do/Sesion30/3.png"  alt="Tarjeta" />
                        </Grid>
                        <Grid container>
                            <p className='TextImg'>
                                Las ilustraciones en las cuevas son como breves relatos que hablan de lo que sucedía en otras
                                eras.
                            </p>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura ">
                            ¿Te imaginas vivir en aquella época? ¿Cómo imaginas que serían tu vida y tus juegos 
                             en las cavernas?
                        </p>
                    </Grid>
                </Grid>
        </Grid>
    </Grid>
    );
}
 
export default Qué_hacían_los_niños_hace_10000_años;
