import React, { useContext, useState } from "react";
import SelectRegionModal from "./SelectRegion";
import TermsAndConditionsArg from "./Argentina";
import TermsAndConditionsMexico from "./Mexico";
import AuthContext from "../../../auth/AuthContext";
import TermsAndConditionsHispanoamerica from "./Hispanoamerica";
import TermsAndConditionsColombia from "./Colombia";
import TermsAndConditionsChile from "./Chile";
import TermsAndConditionsPeru from "./Peru";
import types from "../../../types/types";
import axios from "../../../services/axios";
import UsabilityContext from "../../../usability/UsabilityContext";

const TermsAndConditions = () => {
  const {
    user: { acceptedConditions, userId },
    dispatch,
  } = useContext(AuthContext);

  const { handleLoading, handleModalMessage } = useContext(UsabilityContext);

  const [modalMexicoOpen, setModalMexicoOpen] = useState(!acceptedConditions);
  const [modalArgentinaOpen, setModalArgentinaOpen] = useState(false);
  const [modalColombiaOpen, setModalColombiaOpen] = useState(false);
  const [modalChileOpen, setModalChileOpen] = useState(false);
  const [modalPeruOpen, setModalPeruOpen] = useState(false);
  const [modalHispanoamericaOpen, setModalHispanoamericaOpen] = useState(false);
  const [TermsConditionsOptionsModal, setTermsConditionsOptionsModal] =
    useState(false);

  const [accepted, setAccepted] = useState(acceptedConditions);

  const changeToSelectRegion = () => {
    dispatch({
      type: types.termsAndConditions,
      payload: {
        acceptedConditions: false,
      },
    });
    setAccepted(false);
    setModalMexicoOpen(false);
    setModalArgentinaOpen(false);
    setModalChileOpen(false);
    setModalPeruOpen(false);
    setModalHispanoamericaOpen(false);
    setModalColombiaOpen(false);
    setTermsConditionsOptionsModal(true);
  };

  const accept = () => {
    setAccepted(!accepted);
  };

  const confirmAccept = () => {
    setTermsConditionsOptionsModal(false);
    handleLoading(true);
    const body = { terminos: true, id_usuario: userId };
    axios
      .put(`/user/terms/conditions`, body)
      .then((response) => {
        if (response.status === 200) {
        }
      })
      .catch((error) => {
        if (error.response) {
          const mensaje = "Ha ocurrido un error, inténtelo de nuevo más tarde";

          handleModalMessage({ habilite: true, mensaje: mensaje });
        } else {
          // Something happened in setting up the request that triggered an Error
          const mensaje = "Ha ocurrido un error, inténtelo de nuevo más tarde";
          handleModalMessage({ habilite: true, mensaje: mensaje });
        }
      })
      .finally(() => {
        handleLoading(false);
        dispatch({
          type: types.termsAndConditions,
          payload: {
            acceptedConditions: true,
          },
        });
      });
  };

  const confirmAcceptMexico = () => {
    setModalMexicoOpen(false);
    confirmAccept();
  };
  const confirmAcceptArgentina = () => {
    setModalArgentinaOpen(false);
    confirmAccept();
  };
  const confirmAcceptColombia = () => {
    setModalColombiaOpen(false);
    confirmAccept();
  };
  const confirmAcceptChile = () => {
    setModalChileOpen(false);
    confirmAccept();
  };
  const confirmAcceptHA = () => {
    setModalHispanoamericaOpen(false);
    confirmAccept();
  };
  const confirmAcceptPeru = () => {
    setModalPeruOpen(false);
    confirmAccept();
  };

  return (
    <>
      <TermsAndConditionsMexico
        open={modalMexicoOpen}
        handleConfirm={confirmAcceptMexico}
        changeToSelectRegion={changeToSelectRegion}
        accept={accept}
        acceptedConditions={acceptedConditions}
        accepted={accepted}
      />
      <TermsAndConditionsArg
        open={modalArgentinaOpen}
        handleConfirm={confirmAcceptArgentina}
        changeToSelectRegion={changeToSelectRegion}
        accept={accept}
        acceptedConditions={acceptedConditions}
        accepted={accepted}
      />
      <TermsAndConditionsHispanoamerica
        open={modalHispanoamericaOpen}
        handleConfirm={confirmAcceptHA}
        changeToSelectRegion={changeToSelectRegion}
        accept={accept}
        acceptedConditions={acceptedConditions}
        accepted={accepted}
      />
      <TermsAndConditionsColombia
        open={modalColombiaOpen}
        handleConfirm={confirmAcceptColombia}
        changeToSelectRegion={changeToSelectRegion}
        accept={accept}
        acceptedConditions={acceptedConditions}
        accepted={accepted}
      />
      <TermsAndConditionsChile
        open={modalChileOpen}
        handleConfirm={confirmAcceptChile}
        changeToSelectRegion={changeToSelectRegion}
        accept={accept}
        acceptedConditions={acceptedConditions}
        accepted={accepted}
      />
      <TermsAndConditionsPeru
        open={modalPeruOpen}
        handleConfirm={confirmAcceptPeru}
        changeToSelectRegion={changeToSelectRegion}
        accept={accept}
        acceptedConditions={acceptedConditions}
        accepted={accepted}
      />
      <SelectRegionModal
        open={TermsConditionsOptionsModal}
        handleOpen={() => {
          setTermsConditionsOptionsModal(!TermsConditionsOptionsModal);
        }}
        setModalArgentinaOpen={setModalArgentinaOpen}
        setModalMexicoOpen={setModalMexicoOpen}
        setModalChileOpen={setModalChileOpen}
        setModalPeruOpen={setModalPeruOpen}
        setModalColombiaOpen={setModalColombiaOpen}
        setModalHispanoamericaOpen={setModalHispanoamericaOpen}
      />
    </>
  );
};

export default TermsAndConditions;
