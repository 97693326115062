import { useCallback, useContext, useEffect, useState } from "react";
import UsabilityContext from "../../../usability/UsabilityContext";
import axios from "../../../services/axios";

const LISTA_SESIONES = {
  sesion: [
    {
      descripcion: "Actividades",
      id_sesion: 3,
    },
    {
      descripcion: "Evaluaciones formativas",
      id_sesion: 4,
    },
    {
      descripcion: "Prácticas remediales",
      id_sesion: 5,
    },
  ],
};
const SESION_ID_LIST = LISTA_SESIONES.sesion.map((sesion) => {
  return sesion.id_sesion;
});
export const useGetReporte2Data = (idDistribEsc) => {
  const [showFilter, setShowFilter] = useState(true);
  const [studentsList, setStudentsList] = useState([]);
  const [studentsGraphList, setStudentsGraphList] = useState([]);
  const [userIdList, setUserIdList] = useState([]);
  const [skillsData, setSkillsData] = useState([]);
  const [downloadData, setDownloadData] = useState("");
  const [socioSkills, setSocioSkills] = useState([]);
  const [comprenSkills, setComprenSkills] = useState([]);

  const [sessionData, setSessionData] = useState(SESION_ID_LIST);

  const [sesionName, setSesionName] = useState(LISTA_SESIONES.sesion);

  const { handleLoading, handleModalMessage } = useContext(UsabilityContext);

  const handleToggle = () => {
    setShowFilter(!showFilter);
  };

  const getReportPath = useCallback((students) => {
    const body = {
      idUsuarioAlumnos: students,
      idTipoActividad: [3, 4, 5],
      idDistribucionEscuela: idDistribEsc,
      // idUsuario: userIdList,
    };
    axios
      .post(`/profesor/getReport/2`, body)
      .then((response) => {
        if (response.status === 200) {
          setDownloadData(response.data.resultado.path);
        }
      })
      .catch((error) => {
        console.log(error, "dwnldF");
      });
  }, [idDistribEsc]);

  const getFullList = useCallback(() => {
    const body = {
      id_distribucion_escuela: idDistribEsc,
      // id_usuario: idUsuario,
    };
    handleLoading(true);
    axios
      .post(`/listado/alumnos`, body)
      .then((response) => {
        if (response.status === 200) {
          setStudentsList(response.data.resultado.alumnos);
          setStudentsGraphList(response.data.resultado.alumnos);
          setUserIdList(
            response.data.resultado.alumnos.map((student) => {
              return student.id_usuario;
            })
          );
          getReportPath(response.data.resultado.alumnos);
        }
      })
      .catch((error) => {
        if (error.response) {
          // Request made and server responded
          const errorData = error.response.data;
          const mensaje =
            errorData?.detalles?.msg ||
            "Ha ocurrido un error al consumir los servicios, inténtelo de nuevo más tarde.";
          handleModalMessage({
            habilite: true,
            mensaje: mensaje,
          });
        } else {
          // Something happened in setting up the request that triggered an Error
          const mensaje =
            "Ha ocurrido un error al consumir los servicios, inténtelo de nuevo más tarde.";
          handleModalMessage({
            habilite: true,
            mensaje: mensaje,
          });
        }
      })
      .finally(() => {
        handleLoading(false);
      });
  }, [idDistribEsc, getReportPath, handleLoading, handleModalMessage]);

  const getReportData = useCallback(() => {
    const defaultData = {
      userIdList: studentsList.map((student) => student.id_usuario),
      sessionData: SESION_ID_LIST,
    };
    if (studentsList.length > 0) {
      const body = {
        idTipoActividad:
          sessionData.length > 0 ? sessionData : defaultData.sessionData,
        id_distribucion_escuela: idDistribEsc,
        id_usuarioAlumnos:
          userIdList.length > 0 ? userIdList : defaultData.userIdList,
      };
      handleLoading(true);
      axios
        .post(`/listado/reporte/2`, body)
        .then((response) => {
          if (response.status === 200) {
            const data = response.data.resultado;
            const [comprenSkillsData = {}, socioSkillsData = {}] = data;
            const arraySocioSkills = socioSkillsData?.datos || [];
            const arrayComprenSkills = comprenSkillsData?.datos || [];
            const arraySkills = [...arrayComprenSkills, ...arraySocioSkills];
            setSocioSkills(arraySocioSkills);
            setComprenSkills(arrayComprenSkills);
            setSkillsData(arraySkills);
          }
        })
        .catch((error) => {
          if (error.response) {
            // Request made and server responded
            const errorData = error.response.data;
            const mensaje =
              errorData?.detalles?.msg ||
              "Ha ocurrido un error al consumir los servicios, inténtelo de nuevo más tarde.";
            handleModalMessage({
              habilite: true,
              mensaje: mensaje,
            });
          } else {
            // Something happened in setting up the request that triggered an Error
            const mensaje =
              "Ha ocurrido un error al consumir los servicios, inténtelo de nuevo más tarde.";
            handleModalMessage({
              habilite: true,
              mensaje: mensaje,
            });
          }
          const skillsWithoutData = skillsData.map((skill) => {
            return { ...skill, porcentajePositiva: "", porcentajeNegativa: "" };
          });
          if (skillsData.length === 11) {
            setSkillsData(skillsWithoutData);
          } else {
            setSkillsData([]);
          }
        })
        .finally(() => {
          handleLoading(false);
        });
    }
  }, [userIdList, sessionData, handleLoading, handleModalMessage, idDistribEsc]);

  useEffect(() => {
    getReportData();
  }, [getReportData]);

  useEffect(() => {
    setUserIdList(
      studentsGraphList.map((student) => {
        return student.id_usuario;
      })
    );
  }, [studentsGraphList]);

  useEffect(() => {
    setSessionData(
      sesionName.map((sesion) => {
        return sesion.id_sesion;
      })
    );
  }, [sesionName]);

  useEffect(() => {
    getFullList();
  }, [getFullList]);

  return {
    studentsGraphList,
    studentsList,
    sesionName,
    sessionData,
    skillsData,
    downloadData,
    socioSkills,
    comprenSkills,
    showFilter,
    //Handlers
    handleToggle,
    getFullList,
    //Setters
    setStudentsGraphList,
    setSesionName,
    setSessionData,
    setSkillsData,
  };
};
