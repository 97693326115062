import React, { useContext } from "react";
import { makeStyles, Grid, withStyles } from "@material-ui/core";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@material-ui/core/Typography";
import downloadIcon from "./descargar.png";
import favoritesIcon from "./estrella_blanca.png";
import { Link } from "react-router-dom";
import GenericDropdownMenu from "../../../ui/generic_dropdown_menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import axios from "../../../../services/axios";
import AuthContext from "../../../../auth/AuthContext";
import types from "../../../../types/types";

import ModalMessage from "./ModalContents/ModalMessage";
import ModalConfirm from "./ModalContents/ModalConfirm";

const useStyles = makeStyles((theme) => ({
  Lectura: {
    width: "300px",
    // height:'40.6%',
    margin: "0% 26.5px 54px 26.5px",
    [theme.breakpoints.down(820)]: {
      width: "230px",
      margin: "0px",
    },
  },
  TituloLecturas: {
    margin: "0",
    width: "210px",
    maxHeight: "50px",
    font: "bold 20px lato",
    textAlign: "start",
    letterSpacing: " 0.67px",
    color: "#1DA698",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
    [theme.breakpoints.down(820)]: {
      // padding: '0 0 0 20px',
      font: "bold 14px lato",
      height: "34px",
    },
  },
  ContainerTitulo: {
    width: "100%",
    position: "relative",
    // height:'20%',
    // [theme.breakpoints.down(820)]: {
    //   width: "220px",
    // },
  },
  ContainerImg: {
    objectFit: "cover",
    height: "80%",
  },
  Icon: {
    cursor: "pointer",
    fontSize: "30px",
    color: "#F6644B",
    padding: "0px",
    [theme.breakpoints.down(820)]: {
      fontSize: "20px",
    },
  },
  imgs: {
    width: "300px",
    height: "300px",
    borderRadius: "5%",
    marginBottom: "20px",
    border: "3px #1DA698 solid",
    [theme.breakpoints.down(820)]: {
      width: "200px",
      height: "200px",
    },
  },
  menuItem: {
    padding: "0px",
    minHeight: "auto",
  },
  favorite: {
    backgroundColor: "#FDBB25 ",
    width: "40px",
    height: "40px",
    alignItems: "center",
    justifyContent: "center",
  },
  download: {
    backgroundColor: "#F26C55 ",
    width: "40px",
    height: "40px",
    alignItems: "center",
    justifyContent: "center",
  },
  favoriteText: {
    backgroundColor: "#FFF9EB",
    width: "100px",
    height: "40px",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    font: "bold 14px lato",
    display: "flex",
    margin: 0,
  },
  downloadText: {
    backgroundColor: "#FFEDEA",
    width: "100px",
    height: "40px",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    font: "bold 14px lato",
    display: "flex",
    margin: 0,
  },
  link: {
    cursor: "pointer",
    textDecoration: "none",
    color: "black",
    // width: "190px",
  },
}));

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#f5f5f9",
    font: "34px Fredoka One",
    color: "blue",
    border: "1px solid #dadde9",
  },
}))(Tooltip);

const ReadingCard = (props) => {
  const { title, image, id, downloader, sesion, grade, toSearch, page, gradeFilter, firstTimeLibrary } = props;
  const [modalMessage, setModalMessage] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [modalConfirmOpen, setModalConfirmOpen] = React.useState(false);

  const {
    user: { favoriteList },
    dispatch,
  } = useContext(AuthContext);

  const handleClose = () => {
    setOpen(false);
  };

  const classes = useStyles();
  const addToFavorites = () => {
    const body = { id_lectura: id };
    axios
      .post(`user/lectura/favorita/set?id_lectura=${id}`, body)
      .then((response) => {
        if (response.status === 200) {
          setOpen(true);
          setModalMessage("Se ha agregado la lectura a favoritos");
          dispatch({
            type: types.addOneFromFavorites,
            payload: {
              id_lectura: id,
              nombre: title,
              grado: grade,
              sesion: sesion,
            },
          });
        }
      })
      .catch((error) => {
        const status = error.toJSON().status;
        if (status === 400) {
          setOpen(true);
          setModalMessage(
            "Ha ocurrido un error intentando agregar la lectura a favoritos"
          );
        }
      });
  };

  const removeFromFavorites = () => {
    const body = { id_lectura: id };
    // console.log("Hecho");
    setModalConfirmOpen();
    axios
      .delete(`user/lectura/favorita/delete?id_lectura=${id}`, body)
      .then((response) => {
        if (response.status === 200) {
          dispatch({
            type: types.removeOneFromFavorites,
            payload: id,
          });
          setOpen(true);
          setModalMessage("Se ha eliminado la lectura de favoritos");
        } else if (response.status === 400) {
        }
      })
      .catch((error) => {
        const status = error.toJSON().status;
        if (status === 400) {
          setOpen(true);
          setModalMessage(
            "Ha ocurrido un error intentando agregar la lectura a favoritos"
          );
        }
      });
  };

  const cancelRemove = () => {
    setModalConfirmOpen(false);
  };

  const handleFavoritos = () => {
    const isIncludes = favoriteList.filter(
      (reading) => reading.id_lectura === id
    );
    if (isIncludes.length > 0) {
      setModalConfirmOpen(true);
    } else {
      if (favoriteList.length < 10) {
        addToFavorites();
      } else {
        setOpen(true);
        setModalMessage(
          "Haz alcanzado el límite de lecturas 10 lecturas favoritas"
        );
      }
    }
  };

  // console.log(id, "lectura")
  return (
    <>
      <Grid
        container
        item
        direction="row"
        className={classes.Lectura}
        justifyContent="center"
        alignItems="center"
      >
        <Grid
          container
          item
          sm={12}
          className={classes.ContainerTitulo}
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid
            container
            item
            xs={10}
            justifyContent="flex-start"
            alignItems="flex-end"
          >
            <HtmlTooltip
              title={
                <React.Fragment>
                  <Typography color="inherit">{title}</Typography>
                </React.Fragment>
              }
              placement="top"
              disableFocusListener
            >
              <p className={classes.TituloLecturas}>{title}</p>
            </HtmlTooltip>
          </Grid>
          <Grid
            container
            item
            xs={2}
            justifyContent="center"
            alignItems="center"
          >
            <GenericDropdownMenu className={classes.Icon}>
              <MenuItem
                key={id + "favorite"}
                className={classes.menuItem}
                onClick={handleFavoritos}
              >
                <ListItemIcon className={classes.favorite}>
                  <img src={favoritesIcon} alt="favorites" />
                </ListItemIcon>
                <ListItemText
                  className={classes.favoriteText}
                  primary={favoriteList.some((reading) => reading.id_lectura === id) ? "Eliminar" : "Agregar"}
                />
              </MenuItem>
              <a
                href={downloader}
                download
                target="_blank"
                rel="noreferrer"
                className={classes.link}
              >
                <MenuItem key={id + "download"} className={classes.menuItem}>
                  <ListItemIcon className={classes.download}>
                    <img src={downloadIcon} alt="download" />
                  </ListItemIcon>
                  <ListItemText
                    primary="Descargar"
                    className={classes.downloadText}
                  />
                </MenuItem>
              </a>
            </GenericDropdownMenu>
          </Grid>

        </Grid>
        <Grid className={classes.ContainerImg}>
          <Link
            className={classes.link}
            to={
              {
                pathname: `/lecturas/${grade}/${sesion}`,
                state: { 
                  sesion: sesion, 
                  grade: grade, 
                  toSearch: toSearch, 
                  fromLibrary: firstTimeLibrary,
                  page: page,
                  gradeFilter : gradeFilter,
                },
              }
            }
          >
            <img className={classes.imgs} src={image} alt={title} />
          </Link>
        </Grid>
      </Grid>
      <ModalConfirm
        handleClose={cancelRemove}
        open={modalConfirmOpen}
        titleReading={title}
        handleRemove={removeFromFavorites}
      />
      <ModalMessage
        handleClose={handleClose}
        open={open}
        title={modalMessage}
      />
    </>
  );
};

export default ReadingCard;
