import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
    ContenedorLectura:{
        width:'502px',
                  [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
    },

    ContainerTitulo:{
        margin:'0 0 34px 0',
        '& .Titulo':{
            letterSpacing: '0px',
            color: '#6126D1',
            margin:'0',
            textAlign:'center',
            font:'43px fredoka one',
            lineHeight:'52px',
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            margin:'0 0 36px 0',
            color:'#000',
            font:'18px lato',
            lineHeight :'28px',
            letterSpacing: '0.17px',
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .SubTitulo':{
            color:'#F6664D',
            margin:'0',
            lineHeight :'28px',
            font:'bold 26px lato',
            letterSpacing: '0.24px',
            [theme.breakpoints.down(502)]:{
                font:'bold 18px lato',
                lineHeight :'24px',
                letterSpacing: '0.21px',
            },
        },
        '& .Imagen':{
            width:'100%',
        },  
      
    }
}));

const El_piloto_automático_de_un_Tesla = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                        <h2 className="Titulo ">El piloto automático <br/>de un Tesla</h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                       <p className='Lectura'>
                        El futuro de la tecnología tiene muchas sorpresas. Algunas de ellas son evidentes: 
                        mejores medicinas, distintas formas de comunicación y nuevas diversiones. En 
                        cambio, otras todavía no están bien definidas. Sin embargo, es casi seguro que 
                        involucrarán a la inteligencia artificial.<br/><br/> 
                        
                        <h3 className='SubTitulo'>Inteligencia artificial y problemas éticos</h3><br/>
                        
                        La <em>inteligencia artificial</em> es la capacidad que tiene una máquina para copiar ciertas 
                        habilidades racionales, tales como entender y solucionar conflictos, aprender cosas 
                        por sí misma o incluso resolver problemas éticos. <br/><br/>
                        
                        La <em>ética</em> es una disciplina que se dedica a estudiar qué hay detrás de nuestras 
                        elecciones. ¿Qué ideas tiene cada persona acerca de lo correcto y lo incorrecto? 
                        ¿Qué valores usa para decidir entre hacer algo o no hacerlo? Estas ideas componen 
                        su código moral. Son resultado de un aprendizaje que empieza en casa y se vuelve 
                        más complejo mientras convivimos con otras personas.<br/><br/>
                        
                        Cada sociedad y cada grupo poseen códigos morales específicos. Dos personas 
                        pueden tener ideas muy distintas de lo que está bien, es decir, pueden tener códigos 
                        morales diferentes. Por esa razón, puede ser muy difícil que lleguen a un acuerdo en 
                        ciertos temas delicados. Por eso existe la ética, una disciplina que intenta separarse 
                        de los códigos individuales y sociales para producir acuerdos razonables. <br/><br/>
                        
                        Por ejemplo, existe un problema ético cuando te encuentras ante una situación que 
                        pone a competir dos o más de tus valores, dificultándote distinguir cuál de las 
                        posibilidades es la más adecuada. <br/><br/>
                        
                        <h3 className='SubTitulo'>El piloto automático duda</h3><br/>
                        
                        Planteemos un caso concreto: seguramente has escuchado hablar sobre Tesla, la 
                        compañía tecnológica que produce vehículos y baterías para almacenar energía que 
                        cuiden el medio ambiente.
                       </p>                   
                    </Grid>
                    <Grid container justifyContent='center'>
                        <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion24/1.png" alt="Tarjeta" />
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container >
                        <p className='Lectura'>
                            Uno de los proyectos más ambiciosos de Tesla es crear un automóvil que se pueda manejar por sí mismo sin 
                            la ayuda de un ser humano. ¿Puedes imaginar un mundo en el que los coches se conduzcan completamente 
                            solos? ¿Consideras que esto resultaría útil para la humanidad? Quizá sí. Sin embargo, los expertos 
                            encargados de programar la inteligencia artificial para estos coches se han encontrado con distintos 
                            problemas éticos que parten de una misma pregunta: ¿a quién hay que tratar de proteger ante una 
                            situación de riesgo? ¿A los pasajeros del vehículo o a los peatones? <br/><br/>
                            
                            Imagina que un automóvil Tesla tripulado por tres personas se desplaza a 100 km/h en una vía rápida. 
                            De pronto aparece en el camino un chico en patineta. Debido a la velocidad y la distancia entre el 
                            coche y el muchacho es imposible frenar. Sólo quedan dos opciones. La primera sería seguir de frente. 
                            Eso lastimaría gravemente al chico, pero los pasajeros saldrían ilesos. La segunda sería dar un fuerte 
                            giro al volante, estrellando el coche. Aunque es probable que los pasajeros queden heridos, esto protegería 
                            la vida del peatón. Aquí hay un valor en conflicto, ya que el código moral de una persona (y de un auto 
                            Tesla, si así se le programa) puede ser “debo preservar la salud y el bienestar de cualquier persona”. 
                            ¿Qué alternativa elegir en un caso como éste, si con cualquiera de las dos elecciones posibles alguien 
                            resultará lastimado?
                        </p>
                    </Grid>
                    <Grid container justifyContent='center'>
                        <Grid container justifyContent='center'>
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion24/2.png" alt="Tarjeta" />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container >
                        <p className='Lectura'>
                            Existen diferentes posturas y propuestas éticas para dicho dilema. Una es salvar al mayor 
                            número de personas. Otra, tomar en cuenta el daño que sufriría cada uno de los involucrados. 
                            ¿Tú qué respuesta darías? Los seres humanos tenemos muchos debates internos cuando se trata 
                            de tomar decisiones así. ¿Te puedes imaginar los que tendría una máquina?
                        </p>
                    </Grid>
                </Grid>
        </Grid>
    </Grid>
    );
}
 
export default El_piloto_automático_de_un_Tesla;
