import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
     Contenedor:{
        background:'#E9FBF9',
        width:'100vw',
        overflowX:'clip',
        [theme.breakpoints.down(1161)]:{
            background:'none',
           overflowX:'unset',
        },
        [theme.breakpoints.down(600)]:{
            marginTop: '79.51px',
        }
    },
  ContainerInfoLectura: {
    position: "relative",
    height: "86px",
    [theme.breakpoints.down(502)]:{
        height: "43px",
    },
    background: "#76D4C3",
    "& .BarraInfoLectura": {
      height: "86px",
      background: `Url(https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Barras/Azul.png)`,
        [theme.breakpoints.down(502)]:{
            height: "43px",
        },
    },
    "& .InfoLectura": {
      position: "absolute",
      height: "86px",
      width: "219px",
      boxSizing: "border-box",
      padding: "17.2px 0",
      background: "#FAFAFA",
      borderRadius: "50px",
      boxShadow: "2px 2px 2px 2px #00000029",
      zIndex: "4",
        [theme.breakpoints.down(502)]:{
            height: "43px",
            width: "109px",
            padding: "8.6px 0",
            borderRadius: "25px",
        },
    },
    "& .InfoLectura:before": {
      position: "absolute",
      display: "flex",
      boxSizing: "border-box",
      padding: "0 0 0 10px",
      justifyContent: "center",
      alignItems: "center",
      height: "39.88px",
      width: "39.88px",
      borderRadius: "50%",
      content: '""',
      boxShadow: "3px 0px .5px 0px #00000029",
      background: "#FAFAFA",
      left: "90px",
      top: "65px",
      transform: "rotate(0.25turn)",
      [theme.breakpoints.down(502)]:{
        left: "45px",
        top: "30px",
        height: "20.88px",
        width: "20.88px",
        },
    },
    "& .InfoLectura:after": {
      content: '""',
      height: "15px",
      width: "15px",
      borderTop: "2px solid #20A698",
      borderLeft: "2px solid #20A698",
      position: "absolute",
      bottom: "-10px",
      left: "Calc(50% - 8px)",
      transform: "rotate(0.625turn)",
      [theme.breakpoints.down(502)]:{
        height: "10px",
        width: "10px",
        left: "Calc(50% - 4.5px)",
        bottom: "-3px",
        borderTop: "1px solid #20A698",
        borderLeft: "1px solid #20A698",
        },
    },
    "& .Sesion": {
      borderLeft: "2px solid #D8D8D8",
    },
    "& .Dato": {
      margin: "0 0 5px 0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#20A698",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        margin: "0 0 2.5px 0",
        },
    },
    "& .Caracter": {
      margin: "0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#000000",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        },
    },   },
    ContenedorLectura:{
        background:'#fff',
        width:'1160px',
        height:'100%',
        padding:'60px 168px 35px ',
        [theme.breakpoints.down(1161)]:{
            width:'502px',
            padding:'60px 0 0 0',
          },
        [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
        '& .ContainerDerechos':{
            bottom:'80px',
            [theme.breakpoints.down(1161)]:{
                display:'none',
            },
        },
        '& .Derechos':{
            textAlign:"center",
            margin:"0px",
            font:" 15px lato",
            color:"#717476",
        },
        '& .Escritorio':{
            display:'block',
            [theme.breakpoints.down(1161)]:{
                display:'none'
            },
        },
        '& .Tablet':{
            display:'none',
            [theme.breakpoints.down(1161)]:{
                display:'block'
            },
        }
    },
    ContainerTitulo:{
        margin:'0 0 34px 0',
        [theme.breakpoints.down(1161)]:{
            margin:'0 0 34px 0',
        },
        '& .Titulo':{
            font:'48px fredoka one',
            letterSpacing: '0px',
            lineHeight:'58px',
            color: '#FF0027',
            margin:'0',
            textAlign:'center',
            [theme.breakpoints.down(1161)]:{
                font:'43px fredoka one',
                lineHeight:'52px',
            },
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        background:'#F5FDFF',
        padding:'33px 26px 0 26px',
        borderRadius:'20px',
        [theme.breakpoints.down(1161)]:{
            padding:'32px 21px 0 24px',

        },
        [theme.breakpoints.down(502)]:{
            borderRadius:'20px',
        },
        '& .Lectura':{
            lineHeight : '30px',
            margin:'0 0 36px 0',
            font:'22px lato',
            color:'#000',
            letterSpacing: '0.20px',
            [theme.breakpoints.down(1161)]:{
                font:'18px lato',
                lineHeight :'28px',
                letterSpacing: '0.15px',
            },
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .SubTitulo':{
            font:'30px Fredoka One',
            color:'#FF0027',
            letterSpacing:'.28px',
            lineHeight:'30px',
            margin:'0',
            [theme.breakpoints.down(1161)]:{
                lineHeight :'28px',
                font:'26px Fredoka One',
                letterSpacing: '0.24px',
            },
            [theme.breakpoints.down(502)]:{
                font:'18px Fredoka One',
                lineHeight :'24px',
                letterSpacing: '0.21px',
            },
        },
        '& .SubTitulo2':{
            position:'absolute',
            font:'16px Fredoka One',
            lineHeight:'19px',
            margin:'0',
            [theme.breakpoints.down(1161)]:{
                font:'11px Fredoka One',
                lineHeight :'13px',
            },
            [theme.breakpoints.down(502)]:{
                font:'7px Fredoka One',
                lineHeight :'10px',
            },
        },
        '& .Pos1':{
            left:'10px',
            top:'320px',
            width:'116px',
            [theme.breakpoints.down(1161)]:{
                left:'5px',
                top:'190px',
                width:'80px',
            },
            [theme.breakpoints.down(502)]:{
                left:'0px',
                top:'125px',
                width:'52px',
            },
        },
        '& .Pos2':{
            left:'615px',
            top:'335px',
            width:'127px',
            [theme.breakpoints.down(1161)]:{
                left:'375px',
                top:'195px',
                width:'88px',
            },
            [theme.breakpoints.down(502)]:{
                top: '135px',
                left: '260px',
                width: '60px',
            },
        },
        '& .Granja':{
           position:'relative',
           left:'-26px',
           [theme.breakpoints.down(1161)]:{
            left:'-21',
        }
        }, 
        '& .Imagen':{
            [theme.breakpoints.down(1161)]:{
                width:'502px',
            },
            [theme.breakpoints.down(520)]:{
                width:'350px',
            },
        },  
      
    }
}));

const Conjuntos = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
             <Grid container item justifyContent="center" className={classes.ContainerInfoLectura}>
               <Grid container className="BarraInfoLectura"></Grid>
               <Grid container item className="InfoLectura">
                    <Grid container item direction="column" xs={6} justifyContent="center">
                        <p className="Dato">Grado</p>
                        <p className="Caracter">1</p>
                    </Grid>
                    <Grid container item direction="column" xs={6} justifyContent="center" className="Sesion">
                        <p className="Dato">Sesión</p>
                        <p className="Caracter">8</p>
                    </Grid>
               </Grid>
            </Grid>

            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                        <h2 className="Titulo">Conjuntos</h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container item justifyContent='center'>
                        <p className="Lectura">
                        Imagina que visitas una granja donde hay todo tipo de animales: caballos, 
                        vacas, burros, puercos, gallos, gallinas, pollos, patos, ovejas y hasta un perro y 
                        un gato. El granjero te pide ayuda para clasificar a los animales en pequeños 
                        grupos.<br/><br/>

                        ¿Cómo los podemos organizar? ¿Qué podrían tener en común? 
                        <br/><br/>

                        Pensemos en lo más sencillo, ¿qué son todos ellos? Exacto, animales. Ésa es la 
                        primera característica que comparten. ¿Podrían compartir algo más? Sí, son 
                        animales de granja. ¿Algo más? Sí, todos están domesticados, es decir, son 
                        animales acostumbrados a convivir con seres humanos.<br/><br/>

                        Pues bien, nuestro ejemplo es un conjunto de animales. Un <b>conjunto</b> es una 
                        colección de cosas, animales o personas que comparten una característica en 
                        común. En nuestro caso, cada animal es un <b>elemento</b> del conjunto.<br/><br/>

                        Además de este gran conjunto, podríamos formar otros más pequeños, a los 
                        que llamamos subconjuntos. Por ejemplo, el subconjunto de animales 
                        pequeños incluye solamente a los gallos, las gallinas, los pollos, los patos y el 
                        gato. Por su lado, el subconjunto de animales grandes incluye a los caballos y a 
                        la vaca. Y entonces, ¿quiénes formarían el subconjunto de animales medianos? 
                        Exacto, los puercos, las ovejas y el perro.<br/><br/>

                        ¡Ahora ya lo sabes! Al encontrar características en común, puedes formar 
                        conjuntos y subconjuntos de todo lo que se te ocurra.
                        </p>
                        <h3 className='SubTitulo'> Conjunto de animales de granja</h3>
                    </Grid>
                    <Grid container item className='Granja'>
                        <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img1ro/Sesion8/1.png" alt="Tarjeta" />
                        <p className='SubTitulo2 Pos1'>
                            Subconjunto 
                            de animales de 
                            granja grandes
                        </p>
                        <p className='SubTitulo2 Pos2'>
                            Subconjunto de 
                            animales de 
                            granja pequeños
                        </p>

                    </Grid>
                    
                </Grid>
                

            <Grid container item xs={12} justifyContent="center" className="ContainerDerechos">
                <p className="Derechos">
                    © 2022. Todos los derechos reservados, Pearson, Circuito de lectores.
                </p>                
            </Grid>
        </Grid>
    </Grid>
    );
}
 
export default Conjuntos;

