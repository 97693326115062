import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
    ContenedorLectura:{
        width:'502px',
                  [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
        '& .Escritorio':{
            display:'flex',
            [theme.breakpoints.down(1161)]:{
                display:'none'
            },
        },
        '& .Tablet':{
            display:'none',
            [theme.breakpoints.down(1161)]:{
                display:'flex'
            },
        }
    },
    ContainerTitulo:{
        margin:'0 0 34px 0',
        '& .Titulo':{
            letterSpacing: '0px',
            color: '#FC5E9E',
            margin:'0',
            textAlign:'center',
            font:'43px fredoka one',
            lineHeight:'52px',
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            margin:'0 0 36px 0',
            color:'#000',
            font:'18px lato',
            lineHeight :'28px',
            letterSpacing: '0.17px',
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },

        '& .TextImg':{
            color:'#717476',
            margin:'24px 0 0 0',
            font:' 16px lato',
            lineHeight :'25px',
            letterSpacing: '0.15px',
            [theme.breakpoints.down(502)]:{
                font:' 11px lato',
                lineHeight :'14px',
                letterSpacing: '0.10px',
            },
        },

        '& .Imagen':{
            width:'100%',

        },  
      
    },

}));

const Carretera_panamericana = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>

            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >
                    Arte y cambio climático
                    </h2>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container justifyContent='center' xs={12} >
                        <p className="Lectura ">
                            Desde la década de 1970, el cambio climático ha sido un tema de interés social en 
                            varias partes de la Tierra. Muchos campos de estudio lo han abordado para 
                            reflexionar acerca de él. Por ejemplo, la geografía estudia los cambios en las 
                            regiones. La biología examina las alteraciones que produce en las distintas especies y 
                            ecosistemas. La sociología observa el comportamiento de los seres humanos ante 
                            esta situación. Y el arte retoma este tema para crear consciencia sobre su gravedad, 
                            como ha hecho con muchos otros temas a lo largo de la historia.<br/><br/> 
                            
                            Varios artistas piensan que tienen la responsabilidad de expresar sentimientos y 
                            situaciones ligadas a esta crisis para que su visión sea conocida por más personas. 
                            Entonces, toman sus herramientas, eligen una técnica y producen obras relacionadas 
                            con el tema. Este tipo de arte tiene como objetivo hacer reflexionar a los 
                            espectadores, enfrentarlos con sus propias creencias y despertar su curiosidad para 
                            que se involucren cada vez más. <br/><br/>
                            
                            Han existido diversos creadores que han abordado este tema y algunas piezas fueron 
                            tan exitosas que fueron valoradas por especialistas de otras áreas. Algunas incluso se 
                            han incluido en textos educativos y en exposiciones de ciencia y tecnología. El arte es 
                            un medio para plasmar ideas y perspectivas. Muchas veces ayuda a otras áreas a 
                            ilustrar mejor lo que éstas explican con palabras y términos especializados.
                        </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12} >
                        <Grid container justifyContent='center'>
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img2do/Sesion56R/1.png" alt="Tarjeta" />
                        </Grid>
                        <Grid container item justifyContent='center'>
                            <p className='TextImg '>
                                Los artistas invitan a reflexionar sobre el cambio climático a través de sus obras y las ideas que transmiten.
                            </p>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    
                    <Grid container justifyContent="center" xs={12} >
                        <p className="Lectura ">
                        Las obras de este tipo tocan puntos como el derretimiento de los polos, la falta de 
                        agua, el aumento de la temperatura promedio del planeta, las especies desplazadas 
                        de sus hábitats y la deforestación. Los artistas elaboran sus obras para invitar a los 
                        demás a hacer conciencia y buscar opciones para cambiar sus hábitos diarios. <br/><br/>
                        
                        ¡Ahora sabes un poco más acerca de la relación entre arte y ciencia? ¿Conoces alguna 
                        pintura, película o canción que hable sobre ello? ¡Compártela con tus compañeros 
                        para que también puedan disfrutarla!
                        </p>
                    </Grid>

                </Grid>
        </Grid>
    </Grid>
    );
}
 
export default Carretera_panamericana;
