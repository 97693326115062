import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
    ContenedorLectura:{
        width:'502px',
                  [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
    },
    ContainerTitulo:{
        margin:'0 0 34px 0',
        '& .Titulo':{
            letterSpacing: '0px',
            color: '#E1AF10',
            margin:'0',
            textAlign:'center',
            font:'43px fredoka one',
            lineHeight:'52px',
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            margin:'0 0 36px 0',
            color:'#000',
            textAlign:'center',
            font:'18px lato',
            lineHeight :'28px',
            letterSpacing: '0.17px',
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .Imagen':{
            width:'100%',
        },  
      
    }
}));

const La_danza_del_tiempo = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>

            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                        <h2 className="Titulo">La danza del tiempo</h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12} justifyContent='center'>
                       <p className='Lectura'>
                        El tiempo ya camina en línea recta <br/>
                        y nunca la mirada va a voltear: <br/>
                        ignora aquello que deja detrás, <br/>
                        pues sueña con la vereda perfecta.<br/><br/>
                        
                        Es flecha que el horizonte atraviesa:<br/>
                        cometa desbocado,<br/>
                        un rayo disparado, <br/>
                        que va y jamás regresa.

                       </p>                   
                    </Grid>
                    <Grid container justifyContent='center'>
                        <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion20/1.png" alt="Tarjeta" />
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container justifyContent='center' >
                        <p className='Lectura'>
                            Es el tiempo de los seres humanos: <br/>
                            Grave, definitivo. <br/>
                            Pero existe otro que es más primitivo <br/>
                            y no lo cuentan relojes cotidianos. <br/><br/>
                            
                            El círculo es su espejo. <br/>
                            Y el sol todos los días <br/>
                            le da mucha energía <br/>
                            para que se convierta en su reflejo. <br/><br/>
                            
                            Es el tiempo de la magia y lo eterno,<br/>
                            de los astros y las flores <br/>
                            que renuevan colores <br/>
                            perdidos en invierno.
                        </p>
                    </Grid>
                    <Grid container justifyContent='center'>
                        <Grid container justifyContent='center'>
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion20/2.png" alt="Tarjeta" />
                        </Grid>
                        
                    </Grid>

                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container justifyContent='center' >
                        <p className='Lectura'>
                        Suyas son todas las cuatro estaciones <br/>
                        del año, noche y día, <br/>
                        también la melodía<br/>
                        de la naturaleza con sus dones. <br/><br/>
                         
                        Tiempo que vuelve y va, <br/>
                        como nuestra esperanza,<br/>
                        en esa hermosa danza  <br/>
                        de ola que vuelve y va.
                        </p>
                    </Grid>
                </Grid>
        </Grid>
    </Grid>
    );
}
 
export default La_danza_del_tiempo;
