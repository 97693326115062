import React                        from "react";
import { Button,makeStyles,Grid}    from "@material-ui/core";

const useStyles = makeStyles(theme=>({
  welcomePageContainer: {
    background:'#FFFDFD',
    height:'calc(100vh - 79.5px)',
  },
  ContainerIzquierdo:{
    width:'97%',
    [theme.breakpoints.down(1160)]:{
      width:'100%',
    },
  },

    ContainerEstrategi:{
    position:'relative',
    width:'100vw',
      height:'calc(100vh - 79.5px - 79.5px)',
      overflow: 'auto',
  },
  ContainerSubHabilidades:{
    background:'none',
    boxSizing:'border-box',
    cursor:'pointer',
    textAlign:'center',
    display:'flex',
    padding:'43px 17px 9px 17px',
    alignItems:'center',
    position:'absolute',
    height:'40px',
    width:'62px',
    right:'3%',
    zIndex:1,
    [theme.breakpoints.down(1281)]:{
      left:'calc(50% - 31px)',
      padding:'0 0 0 62px',
    },
    [theme.breakpoints.down(600)]:{
      left:'0',
      padding:'0 15px 0 55px',
    },
        '& .Habilidades':{
      color:'#575757',
      font: 'normal 16px Lato',
      letterSpacing: '0.13px',
      display:'none',
      [theme.breakpoints.down(1281)]:{
        padding:'0 ', 
        margin:'0',
        font: ' 13px Lato',
      },
      [theme.breakpoints.down(600)]:{
        padding:'0 ', 
        margin:'0',
        font: ' 11px Lato',
      },
    },
    '&:hover':{
      border:' 5px solid #F26973',
      borderRadius: '20px 0px 20px 20px',
      background:'#fff',
      height:'215px',
      width:'233px',
      [theme.breakpoints.down(1281)]:{
        height:'75px',
        width:'350px',
        borderRadius: '0px 0px 20px 20px',
      },
      [theme.breakpoints.down(600)]:{
        height:'75px',
        width:'100%',
      },

      '& .Habilidades':{
        display:'block',
      },
      '&:before':{
        borderTop:' 0px ',
        borderRight:'0px'
      },
    },
    '&:before':{
      display:'flex',
      justifyContent:'center',
      alignItems:'center',
      content: '"RI"',
      position: 'absolute',
      right: '0',
      top: '0',
      font: '20px Fredoka One',
      background: '#E24C8B',
      color: '#FFF',
      border:' 3px solid #F2624F',
      borderRadius: '0px 0px 20px 20px',
      height: '40px',
      width: '62px',
      [theme.breakpoints.down(1281)]:{
        left: '-5px',
        top: '0',
      },
      [theme.breakpoints.down(600)]:{
        height: '30px',
        width: '52px',
        font: '18px Fredoka One',
      },

    },
  },

  Estrategia:{
    margin:'0px',
    font:'38px Fredoka One',
    textAlign: 'center',
    letterSpacing: '0.28px',
    color: '#20A698',
    opacity: '1',
    [theme.breakpoints.down(1161)]:{
      font:'28px Fredoka One',
    },
    [theme.breakpoints.down(600)]:{
      font:'24px Fredoka One',
    },
  },

   TextStrg:{
      margin:'0', 
      font:'normal  22px Lato',
      letterSpacing: '0.10px',
      color: '#575757',
      opacity: 1,
      padding:'0 0 40px 0',
      boxSizing:'border-box',
      width:'760px',
      [theme.breakpoints.down(1161)]:{
        width:'650px',
        font:'normal  18px Lato',
      },
      [theme.breakpoints.down(600)]:{
        font:'normal  16px Lato',
        width:'325px',
      },
      [theme.breakpoints.down(325)]:{
        font:'normal  14px Lato',
        width:'300px',
      },
  },

   Titulo:{
    position:'absolute',
    top:'58px',
    [theme.breakpoints.down(1161)]:{
      top:'80px',
    },
  },
  Objet1:{
    position:'absolute',
    top:'130px',
    '& .Td':{
      background:'#fff',
      border:'solid 1.5px #000',
      margin:'0', 
      font:'bold  20px Lato',
      letterSpacing: '0.10px',
      color: '#575757',
      textAlign:'center',
      [theme.breakpoints.down(1161)]:{
        font:'bold  18px Lato',
      },
      [theme.breakpoints.down(600)]:{
        font:'bold  16px Lato',
      },
    },
    '& .Td2':{
      border:'solid 1.5px #000',
      margin:'0', 
      letterSpacing: '0.10px',
      [theme.breakpoints.down(1161)]:{
        font:' 18px Lato',
      },
      [theme.breakpoints.down(600)]:{
        font:' 16px Lato',
      },
      color: '#575757',
      opacity: 1,
    },
    '& .TD1':{
      width:'90px',
      [theme.breakpoints.down(600)]:{
        width:'55px',
      },
    },
    '& .TD2':{
      width:'200px',
      [theme.breakpoints.down(600)]:{
        width:'125px',
      },
    },
    '& .TD3':{
      width:'300px',
      [theme.breakpoints.down(600)]:{
        width:'125px',
      },
    },
    '& .Br':{
      margin:'0 0 20px 0'
    },
    '& .ul':{
      margin:'22px 0',
      padding:'0 0 0 22px',
    },
    '& .li':{
      marginBottom:'10px',
    },
   '& .Amarillo':{
    textAlign:'center',
    height:'42px',
    background:'#F7DF92',
    padding:'0 ',
   },
   '& .Verde':{
    textAlign:'center',
    height:'42px',
    background:'#84E9DF',
    padding:'0 ',
   },
  },


  ContainerBarraInferior:{
    position:'relative',
    background:'#F3CD52',
    height:'79.51px',
    [theme.breakpoints.up(1920)]:{
      height:'8vh',
    },
    [theme.breakpoints.down(1161)]:{
      background: 'linear-gradient(280deg, #D3348F 0%, #F1CB5A 100%) 0% 0% ',
    },
  },

  ContainerBarraDerecha:{
    background: 'linear-gradient(180deg, #D3348F 0%, #F1CB5A 100%) 0% 0%',
    width:'3%',
    height:'100vh',
    [theme.breakpoints.down(1161)]:{
      display:'none',
    },
  },
  
   BotonAvanza:{
    position:'absolute',
    bottom:'52px',
    right:'134px',
    textTransform:'none',
    textDecoration:'none',
    height:'62px',
    width:'279px',  
    background: '#7A54BA',
    boxShadow: '0px 3px 6px #00000029',
    borderRadius: '61px',
    color: '#FFFFFF',
    font: 'bold 20px lato ',
    '&:hover':{
        background: '#dd78fb',
      },
    [theme.breakpoints.down(1161)]:{
      position:'relative',
      bottom:'32px',
      left:'0',
      right:'0',
    },
  },

    
}));

const ReadingStrategys = (props) => {
  const classes = useStyles();
  const {activeStep, setActiveStep, handleNextStep} = props

  const handleContinue = () => {
    setActiveStep(activeStep + 1)
    handleNextStep()
  }

  return(

    <Grid  container direction="column" item sm={12} className={classes.welcomePageContainer} >
<div className={classes.ContainerSubHabilidades}>
          <p className="Habilidades">Leer los primeros y últimos párrafos para identificar los conceptos más importantes de la lectura (<em>skimming</em>).</p>
      </div>
      <Grid container item sm={12} justifyContent="center" className={classes.ContainerIzquierdo} >

        <Grid container item className={classes.ContainerEstrategi } >

              <Grid container item justifyContent="center" alignItems="center" sm={12} className={classes.Titulo}> 
                <p className={classes.Estrategia} >Estrategia de lectura</p>
              </Grid>

              <Grid container item sm={12} alignItems="center" justifyContent="center" className={classes.Objet1}>
                  <p className={classes.TextStrg}>
                    Los textos comunican diversos temas, pero a veces es difícil comprenderlos 
                    totalmente. Tu misión será realizar la siguiente actividad para estar preparado y 
                    enfrentar cualquier desafío en las lecturas que realices.

                    <ul className="ul">
                      <li className="li">
                        Escoge un texto que llame tu atención.
                      </li>
                      <li className="li">
                        Toma una hoja, un lápiz y un marcador del color que más te guste.
                      </li>
                      <li className="li">
                        Traza la siguiente tabla. Si es necesario, coloca más filas:
                      </li>
                    </ul>
                  <tr>
                    <tr>
                      <td valign="middle" className="Td TD1 Verde"><b>Párrafo</b></td>
                      <td valign="middle" className="Td TD2 Verde"><b>Primera oración del <br/>párrafo</b></td>
                      <td valign="middle" className="Td TD3 Verde"><b>Última oración del párrafo</b></td>
                    </tr>
                    <tr>
                      <td className="Td TD1 Td2">1</td>
                      <td className="Td TD2 Td2"></td>
                      <td className="Td TD3 Td2"></td>
                    </tr>
                    <tr>
                      <td className="Td TD1 Td2">2</td>
                      <td className="Td TD2 Td2"></td>
                      <td className="Td TD3 Td2"></td>
                    </tr>
                    <tr>
                      <td className="Td TD1 Td2">3</td>
                      <td className="Td TD2 Td2"></td>
                      <td className="Td TD3 Td2"></td>
                    </tr>
                    <tr>
                      <td className="Td TD1 Td2">4</td>
                      <td className="Td TD2 Td2"></td>
                      <td className="Td TD3 Td2"></td>
                    </tr>
                  </tr>
                
                    <ul className="ul">
                      <li className="li">
                        Realiza una lectura global del texto, pero no te concentres tanto en ella. Sólo
                        será para que tengas una idea general de él.
                      </li>
                      <li className="li">
                        Subraya en el texto la primera y la última oración de cada párrafo.
                      </li>
                      <li className="li">
                        Al terminar, transcríbelas en la tabla en el lugar que le corresponda a cada una.
                      </li>
                      <li className="li">
                        Ahora, lee las oraciones de cada párrafo con detenimiento.
                      </li>
                      <li className="li">
                        Realiza una síntesis de la primera y última oración del párrafo final. Léela 
                        con mucha concentración. ¿De qué trata el texto?
                      </li>
                    </ul>
     
                    ¡Lo has hecho excelente! No olvides practicar. Si lo haces, seguramente te harás 
                    un experto. ¡Sigamos adelante para conocer acerca de una de las expresiones 
                    artísticas que activa el sentido del oído!

                  </p>
              </Grid>
                
        </Grid>
        
        <Grid container item sm={12} className={classes.ContainerBarraInferior} justifyContent="center">
            <Button id="BotonContinuarEstrategias" className={classes.BotonAvanza} onClick={handleContinue}>
              Continuar
            </Button>
        </Grid>
        
      </Grid>

      <Grid container item sm={12} className={classes.ContainerBarraDerecha}></Grid>

    </Grid>

  )
}

export default ReadingStrategys;
