import { Button, Grid, makeStyles } from "@material-ui/core";
import React, { useContext, useEffect } from "react";
import DrawerContext from "../../../../../usability/DrawerContext";
import { useOnLoadImages } from "../../../../hooks/useOnLoadImages";
import Loader from "../../../../Loader/Loader";
import Drawer from "../../ReadingTools/Drawer";

const useStyles =  makeStyles (theme =>({
      Contenedor:{
        background:'#E9FBF9',
        width:'100vw',
        overflowX:'clip',
        [theme.breakpoints.down(1161)]:{
            background:'none',
           overflowX:'unset',
        },
        [theme.breakpoints.down(600)]:{
            marginTop: '79.51px',
        }
    },
    ContainerInfoLectura: {
    position: "relative",
    height: "86px",
    background: "#F6664D",
    [theme.breakpoints.down(502)]:{
        height: "43px",
    },
    "& .BarraInfoLectura": {
      height: "86px",
      background: `Url(https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Barras/Naranja.png)`,
      [theme.breakpoints.down(502)]:{
        height: "43px",
    },
    },
    "& .InfoLectura": {
      position: "absolute",
      height: "86px",
      width: "219px",
      boxSizing: "border-box",
      padding: "17.2px 0",
      background: "#FAFAFA",
      borderRadius: "50px",
      boxShadow: "2px 2px 2px 2px #00000029",
      zIndex: "4",
      [theme.breakpoints.down(502)]:{
        height: "43px",
        width: "109px",
        padding: "8.6px 0",
        borderRadius: "25px",
    },
    },
    "& .InfoLectura:before": {
      position: "absolute",
      display: "flex",
      boxSizing: "border-box",
      padding: "0 0 0 10px",
      justifyContent: "center",
      alignItems: "center",
      height: "39.88px",
      width: "39.88px",
      borderRadius: "50%",
      content: '""',
      boxShadow: "3px 0px .5px 0px #00000029",
      background: "#FAFAFA",
      left: "90px",
      top: "65px",
      transform: "rotate(0.25turn)",
      [theme.breakpoints.down(502)]:{
        left: "45px",
        top: "30px",
        height: "20.88px",
        width: "20.88px",
        },
    },
    "& .InfoLectura:after": {
      content: '""',
      height: "15px",
      width: "15px",
      borderTop: "2px solid #F6664D",
      borderLeft: "2px solid #F6664D",
      position: "absolute",
      bottom: "-10px",
      left: "Calc(50% - 8px)",
      transform: "rotate(0.625turn)",
      [theme.breakpoints.down(502)]:{
        height: "10px",
        width: "10px",
        left: "Calc(50% - 4.5px)",
        bottom: "-3px",
        borderTop: "1px solid #20A698",
        borderLeft: "1px solid #20A698",
        },
    },
    "& .Sesion": {
      borderLeft: "2px solid #D8D8D8",
    },
    "& .Dato": {
      margin: "0 0 5px 0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#F6664D",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        margin: "0 0 2.5px 0",
        },
    },
    "& .Caracter": {
      margin: "0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#000000",
    },
       '& .Regresar':{
        color: '#F2624F',
        border: '#F2624F 1px solid',
        height: '30px',
        width:'100px',
        borderRadius: '20px',
        letterSpacing: '0.14px',
        textTransform: 'none',
        background:'#FEFAF1',
        top:'calc(50% - 15px)',
        left:'5%',
        zIndex:4,
        [theme.breakpoints.down(502)]: {
          font:'bold 10px lato',
          width: "60px",
        },
      },
  },
    ContenedorLectura:{
        background:'#fff',
        width:'1160px',
        height:'100%',
        padding:'60px 168px 35px ',
        
        transform: (props) =>
        props.scale > 1 ? `scale(${props.scale}) translate(0px, 10%)` : `none`,
        [theme.breakpoints.down(1161)]:{
            width:'502px',
            padding:'60px 0 0 0',
          },
                [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },

        '& .Escritorio':{
            display:'flex',
            [theme.breakpoints.down(1161)]:{
                display:'none'
            },
        },
        '& .Tablet':{
            display:'none',
            [theme.breakpoints.down(1161)]:{
                display:'flex'
            },
        }
    },
    ContainerTitulo:{
        margin:'0 0 34px 0',
        [theme.breakpoints.down(1161)]:{
            margin:'0 0 34px 0',
        },
        '& .Titulo':{
            font:'48px fredoka one',
            letterSpacing: '0px',
            lineHeight:'58px',
            color: '#00ADB6',
            margin:'0',
            textAlign:'center',
            [theme.breakpoints.down(1161)]:{
                font:'43px fredoka one',
                lineHeight:'52px',
            },
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            lineHeight : '30px',
            margin:'0 0 36px 0',
            font:'22px lato',
            color:'#000',
            letterSpacing: '0.21px',
            [theme.breakpoints.down(1161)]:{
                font:'18px lato',
                lineHeight :'28px',
                letterSpacing: '0.17px',
            },
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .Lectura2':{
            margin:'0',
        },
        '& .TextImg':{
            font:'20px lato',
            color:'#717476',
            letterSpacing:'.19px',
            lineHeight:'30px',
            margin:'24px 0 0 0',
            [theme.breakpoints.down(1161)]:{
                font:' 16px lato',
                lineHeight :'25px',
                letterSpacing: '0.15px',
            },
            [theme.breakpoints.down(502)]:{
                font:' 11px lato',
                lineHeight :'14px',
                letterSpacing: '0.10px',
            },
        }, 
        '& .Imagen':{
            [theme.breakpoints.down(1161)]:{
                width:'120%',
            },
        },  
      
    },

}));

const El_teatro_la_suma_de_las_artes = (props) => {

  const {activeStep, setActiveStep, handlePreviewStep, id_actividad} = props
  
  const handleContinue = () => {
    setActiveStep(activeStep - 1)
    handlePreviewStep()
  }

    const {
    handleSize,
    disabledZoom,
    closeZoom,
    handleZoom,
    scale
  }= useContext(DrawerContext);
  const [loading, setLoading] = React.useState(true);
  const wrapperRef = React.useRef(null);
  const imagesLoaded = useOnLoadImages(wrapperRef);

  const detectSize = () => {
    const lectura = document.getElementById("lectura");
    const newWidth = lectura?.offsetWidth;
    const newHeight = lectura?.offsetHeight;
    setLoading(false);
    if (lectura) handleSize(newHeight, newWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", detectSize);
    return () => {
      handleZoom()
      window.removeEventListener("resize", detectSize);
      closeZoom()
    };
  }, []);

  const classes = useStyles({ disabledZoom,scale });
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
            <Grid container item justifyContent="center" className={classes.ContainerInfoLectura}>
                <Grid container className="BarraInfoLectura">
                    <Button className="Regresar" onClick={handleContinue}>
                        Regresar
                    </Button>
                </Grid>
               <Grid container item className="InfoLectura">
                    <Grid container item direction="column" xs={6} justifyContent="center">
                        <p className="Dato">Grado</p>
                        <p className="Caracter">5</p>
                    </Grid>
                    <Grid container item direction="column" xs={6} justifyContent="center" className="Sesion">
                        <p className="Dato">Sesión</p>
                        <p className="Caracter">56R</p>
                    </Grid>
               </Grid>
            </Grid>
            {!imagesLoaded && <Loader />}
        <Grid
            container
            item
            className={classes.ContenedorLectura}
            justifyContent="center"
            id="lectura"
            onLoad={detectSize}
            ref={wrapperRef}
        >
<Drawer id_lectura={id_actividad} />
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >
                    El teatro: la suma <br className='Tablet'/>de las artes
                    </h2>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container>
                        <p className='Lectura Lectura2'>
                            La historia de la humanidad podría contarse a través del teatro. Desde tiempos muy 
                            antiguos los griegos desarrollaron esta actividad artística a partir de sus ceremonias 
                            religiosas y de la necesidad de expresar los sentimientos de la sociedad. El teatro 
                            griego sentó las bases de esta disciplina y muchas de ellas continúan vigentes en la 
                            actualidad. <br/><br/>
                            
                            Las obras tenían varias características, cierta extensión, ciertos criterios para los 
                            personajes y hablaban de temas variados. La historia siempre tenía que llevarse a 
                            cabo arriba de un escenario y la misión más importante era causar emociones en los 
                            espectadores. De hecho, su finalidad era provocar una catarsis, que es el momento 
                            en que la historia alcanzaba su punto más tenso. La acumulación de esa tensión y la 
                            trama de la historia hacían que los espectadores sintieran un gran alivio cuando 
                            llegaba la resolución de la historia.
                        </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12} >
                        <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion56R/1.png" alt="Tarjeta" />
                    </Grid>
                    <Grid container  sm={12} >
                        <p className='TextImg'>Los griegos usaban el teatro para expresar sus sentimientos y generar catarsis en el público.</p>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container justifyContent="center" sm={12} >
                        <p className='Lectura Lectura2'>
                            Usualmente nos fijamos sólo en los actores, pues son a quienes vemos en el 
                            escenario. Muchas veces ellos se llevan todo el crédito, por eso es importante 
                            conocer que hay muchas más personas involucradas en este proceso, aunque no 
                            podamos verlas y desconozcamos sus rostros. El teatro es una enorme maquinaria 
                            donde se involucran muchos creadores. Todos operan como un engranaje para que 
                            todo funcione y la historia sea realizada de forma exitosa. A continuación veremos 
                            algunos de los individuos relacionados con el teatro, que son igual de importantes 
                            que el elenco.<br/><br/>
                            
                            Primero se necesita que alguien escriba la historia, pues si no hay historia que contar,
                            todos los demás se quedarían sin trabajo. Esa tarea es desarrollada por los
                            dramaturgos. Ellos eligen qué historia quieren relatar, cómo hacerlo y qué extensión 
                            tendrá. Definen a los personajes y las situaciones en las cuales se involucrarán éstos. 
                            A través de la escritura describen personas, lugares, objetos, sentimientos y
                            situaciones. <br/><br/>
                            
                            Una vez que han terminado hacen llegar sus escritos a los actores que darán vida a 
                            los personajes con su cuerpo y su voz. Ellos necesitan hacer pruebas de vestuario, de 
                            maquillaje y de peinado para caracterizarse adecuadamente. Después de encontrar 
                            un atuendo acorde con el papel de cada quien, practican largas horas los diálogos 
                            para aprenderlos de memoria y poder decirlos al momento de actuar frente a la 
                            gente. <br/><br/>
                            
                            Luego está la labor de los músicos, escenógrafos y diseñadores de luz. En sus manos 
                            está ambientar el escenario para provocar las sensaciones correctas. Cada nota 
                            musical, ilustración y color de luz contribuye a recrear los lugares que los escritores 
                            imaginan en su mente cuando crean la historia. Todas estas disciplinas trabajan en 
                            conjunto armónicamente. Por último, gracias a la visión de los directores, el relato 
                            toma una perspectiva única, definida por el gusto y los matices que ellos decidan 
                            imprimir.
                        </p> 
                    </Grid>
                    <Grid container  sm={12} >
                        <Grid container justifyContent="center" sm={12} >
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion56R/2.png" alt="Tarjeta" />
                        </Grid>
                        <Grid container justifyContent="center" sm={12} >
                            <p className='TextImg'>Una compañía de teatro está conformada por varios artistas.</p>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container justifyContent="center" sm={12} >
                        <p className='Lectura'>
                            Hay muchos artistas famosos que han colaborado en representaciones teatrales. Por ejemplo, 
                            Elton John ha compuesto música para teatro; el diseñador Valentino hizo ropa para la ópera
                            de Milán; el pintor Antoni Tapiès revolucionó el decorado del teatro vanguardista en España,
                            y el también pintor Marc Chagall decoró los techos de un teatro francés. Este arte se ha nutrido 
                            de múltiples disciplinas y personas desde hace bastante tiempo. Con ello se demuestra cuán 
                            necesarios resultan la complicidad, el apoyo y la fusión para que todo funcione en la enorme maquinaria teatral.                        
                        </p> 
                    </Grid>
                </Grid>
        </Grid>
        <Grid container item style={{ marginTop: 80}} ></Grid>
    </Grid>
    );
}
 
export default El_teatro_la_suma_de_las_artes;
