import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
     Contenedor:{
        background:'#E9FBF9',
        width:'100vw',
        overflowX:'clip',
        [theme.breakpoints.down(1161)]:{
            background:'none',
           overflowX:'unset',
        },
        [theme.breakpoints.down(600)]:{
            marginTop: '79.51px',
        }
    },
  ContainerInfoLectura: {
    position: "relative",
    height: "86px",
    [theme.breakpoints.down(502)]:{
        height: "43px",
    },
    background: "#76D4C3",
    "& .BarraInfoLectura": {
      height: "86px",
      background: `Url(https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Barras/Azul.png)`,
        [theme.breakpoints.down(502)]:{
            height: "43px",
        },
    },
    "& .InfoLectura": {
      position: "absolute",
      height: "86px",
      width: "219px",
      boxSizing: "border-box",
      padding: "17.2px 0",
      background: "#FAFAFA",
      borderRadius: "50px",
      boxShadow: "2px 2px 2px 2px #00000029",
      zIndex: "4",
        [theme.breakpoints.down(502)]:{
            height: "43px",
            width: "109px",
            padding: "8.6px 0",
            borderRadius: "25px",
        },
    },
    "& .InfoLectura:before": {
      position: "absolute",
      display: "flex",
      boxSizing: "border-box",
      padding: "0 0 0 10px",
      justifyContent: "center",
      alignItems: "center",
      height: "39.88px",
      width: "39.88px",
      borderRadius: "50%",
      content: '""',
      boxShadow: "3px 0px .5px 0px #00000029",
      background: "#FAFAFA",
      left: "90px",
      top: "65px",
      transform: "rotate(0.25turn)",
      [theme.breakpoints.down(502)]:{
        left: "45px",
        top: "30px",
        height: "20.88px",
        width: "20.88px",
        },
    },
    "& .InfoLectura:after": {
      content: '""',
      height: "15px",
      width: "15px",
      borderTop: "2px solid #20A698",
      borderLeft: "2px solid #20A698",
      position: "absolute",
      bottom: "-10px",
      left: "Calc(50% - 8px)",
      transform: "rotate(0.625turn)",
      [theme.breakpoints.down(502)]:{
        height: "10px",
        width: "10px",
        left: "Calc(50% - 4.5px)",
        bottom: "-3px",
        borderTop: "1px solid #20A698",
        borderLeft: "1px solid #20A698",
        },
    },
    "& .Sesion": {
      borderLeft: "2px solid #D8D8D8",
    },
    "& .Dato": {
      margin: "0 0 5px 0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#20A698",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        margin: "0 0 2.5px 0",
        },
    },
    "& .Caracter": {
      margin: "0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#000000",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        },
    },   },
    ContenedorLectura:{
        background:'#fff',
        width:'1160px',
        height:'100%',
        padding:'60px 168px 35px ',
        [theme.breakpoints.down(1161)]:{
            width:'502px',
            padding:'60px 0 0 0',
          },
        [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
        '& .ContainerDerechos':{
            bottom:'80px',
            [theme.breakpoints.down(1161)]:{
                display:'none',
            },
        },
        '& .Derechos':{
            textAlign:"center",
            margin:"0px",
            font:" 15px lato",
            color:"#717476",
        },
        '& .Escritorio':{
            display:'flex',
            [theme.breakpoints.down(1161)]:{
                display:'none'
            },
        },
        '& .Tablet':{
            display:'none',
            [theme.breakpoints.down(1161)]:{
                display:'flex'
            },
        }
    },
    ContainerTitulo:{
        margin:'0 0 34px 0',
        [theme.breakpoints.down(1161)]:{
            margin:'0 0 34px 0',
        },
        '& .Titulo':{
            font:'48px fredoka one',
            letterSpacing: '0px',
            lineHeight:'58px',
            color: '#D31A3C',
            margin:'0',
            textAlign:'center',
            [theme.breakpoints.down(1161)]:{
                font:'43px fredoka one',
                lineHeight:'52px',
            },
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        position:'relative',
        '& .Lectura':{
            lineHeight : '30px',
            margin:'0 0 36px 0',
            font:'22px lato',
            color:'#000',
            letterSpacing: '0.20px',
            [theme.breakpoints.down(1161)]:{
                font:'18px lato',
                lineHeight :'28px',
                letterSpacing: '0.17px',
            },
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .Esp':{
            margin:'0',
            [theme.breakpoints.down(1161)]:{
                padding:'0 8% 0 0'
            },
        },
        '& .Conejo':{
            position:'relative'
        },
        '& .Esp2':{
            position:'absolute',
            right:'135px',
            top:'135px',
        },

        '& .Imagen':{
            width:'100%', 
            [theme.breakpoints.down(1161)]:{
                width:'100%', 
            },
            
        },  
        "& .Imagen2": {
            width: "375px",
            height: "463px",
            [theme.breakpoints.down(1161)]: {
              width: "100%",
              height: "312px",
            },
            [theme.breakpoints.down(502)]: {
              height: "294px",
            },
            [theme.breakpoints.down(350)]: {
              height: "244px",
            },
          },
        '& .Imagen3':{
            width:'77.5%', 
            [theme.breakpoints.down(1161)]:{
                width:'75%', 
            },
        }, 
      
    }
}));

const Sin_importar_las_diferencias = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
             <Grid container item justifyContent="center" className={classes.ContainerInfoLectura}>
               <Grid container className="BarraInfoLectura"></Grid>
               <Grid container item className="InfoLectura">
                    <Grid container item direction="column" xs={6} justifyContent="center">
                        <p className="Dato">Grado</p>
                        <p className="Caracter">1</p>
                    </Grid>
                    <Grid container item direction="column" xs={6} justifyContent="center" className="Sesion">
                        <p className="Dato">Sesión</p>
                        <p className="Caracter">13</p>
                    </Grid>
               </Grid>
            </Grid>

            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                        <h2 className="Titulo ">Sin importar las diferencias</h2>

                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                       <p className='Lectura'>
                        Era el primer día de clases. La lección de primer grado estaba a cargo del profesor 
                        Alejo, el conejo, quien dijo a sus alumnos: 
                        <br/><br/>
                        —¿Saben qué día es hoy? 
                        <br/><br/>
                        —¡Día de crecimiento de pelo! —contestaron los conejitos. 
                        <br/><br/>
                        —¡Así es! Tienen que encontrar un compañero para abrazarse. El abrazo es la mejor manera de hacer crecer nuestro pelo —dijo mientras daba una vuelta para que sus alumnos vieran su suave pelaje.
                       </p>                   
                    </Grid>
                    <Grid container item justifyContent='center'>
                        <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img1ro/Sesion13/1.png" alt="Tarjeta" />
                    </Grid>
                    
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container xs={6} className='Escritorio'>
                        <p className='Lectura Esp'>
                            Los conejitos, un poco cegatones aún<br/>
                            por la edad y chocando unos con <br/>
                            otros, buscaban entre sus <br/>
                            compañeros. Poco a poco, <br/>
                            encontraron a quien abrazar. Todos, <br/>
                            menos Irene, la liebre. <br/><br/>
                             
                            Irene tenía el doble de tamaño de sus <br/>
                            compañeros y un pelaje completo. <br/>
                            Algunos conejitos como Bruno y <br/>
                            Brenda tenían un poco de miedo.<br/><br/>
                             
                            —¡No la quiero abrazar! —gritó<br/>
                            Brenda.<br/><br/>

                            Triste, Irene decidió alejarse. Hasta <br/>
                            que Tomasita le dijo:
                        </p>
                    </Grid>
                    <Grid container xs={6} justifyContent='center' className='Escritorio'>
                        <img className="Imagen2" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img1ro/Sesion13/2.png" alt="Tarjeta" />
                    </Grid>
                    <Grid container item className='Tablet'>
                        <p className='Lectura'>
                            Los conejitos, un poco cegatones aún por la edad y chocando 
                            unos con otros, buscaban entre sus compañeros. Poco a poco, 
                            encontraron a quien abrazar. Todos, menos Irene, la liebre.
                        </p>
                    </Grid>

                    <Grid container xs={12} className='Tablet'>
                    <Grid container xs={7}>
                        <p className="Lectura Esp">
                            Irene tenía el doble de tamaño de sus compañeros y un pelaje completo. Algunos conejitos como Bruno y Brenda tenían
                            un poco de miedo.
                            <br />
                            <br />
                            —¡No la quiero abrazar! —gritó Brenda. <br />
                            <br />
                            Triste, Irene decidió alejarse. Hasta que Tomasita le dijo:
                        </p>
                        </Grid>
                        <Grid container xs={5}>
                        <img
                            className="Imagen2"
                            src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img1ro/Sesion13/2.png"
                            alt="Tarjeta"
                        />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container className='Conejo Escritorio' >
                        <p className='Lectura'>
                            —Yo no tengo muchos amigos porque soy más pequeña y casi no tengo pelo. ¿Quieres ser mi compañera?
                        </p>
                    </Grid>
                    <Grid container item xs={12} justifyContent='center' className='Conejo Escritorio'>
                        <img className="Imagen3" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img1ro/Sesion13/3.png" alt="Tarjeta" />
                        <p className='Lectura Esp2 Escritorio'>
                            —¡Claro! Lo importante no<br/> 
                            es si somos distintos, sino<br/> 
                            que nos aceptemos. ¿Lista?
                        </p>
                    </Grid>
                    <Grid container item justifyContent='center' className='Tablet'>
                        <p className='Lectura'>
                            —Yo no tengo muchos amigos porque soy más pequeña y casi 
                            no tengo pelo. ¿Quieres ser mi compañera? <br/><br/>
                            
                            —¡Claro! Lo importante no es si somos distintos, sino que nos 
                            aceptemos. <br/><br/>
                            
                            ¿Lista? Se abrazaron. Tomasita sintió una cálida sensación de amistad 
                            y se convirtió en la conejita con el pelaje más bonito.
                        </p>
                        <img className="Imagen3" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img1ro/Sesion13/3.png" alt="Tarjeta" />
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container >
                        <p className='Lectura'>
                            <span className="Escritorio">Se abrazaron. Tomasita sintió una cálida sensación de amistad y se convirtió en la 
                            conejita con el pelaje más bonito. <br/><br/></span>
                            
                            Irene y Tomasita entendieron que las diferencias no nos hacen extraños, sino 
                            únicos.
                        </p>
                    </Grid>
                    
                </Grid>

                <Grid container item xs={12} justifyContent="center" className="ContainerDerechos">
                    <p className="Derechos">
                        © 2022. Todos los derechos reservados, Pearson, Circuito de lectores.
                    </p>                
                </Grid>
            </Grid>
    </Grid>
    );
}
 
export default Sin_importar_las_diferencias;
