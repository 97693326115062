import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
     Contenedor:{
        background:'#E9FBF9',
        width:'100vw',
        overflowX:'clip',
        [theme.breakpoints.down(1161)]:{
            background:'none',
           overflowX:'unset',
        },
        [theme.breakpoints.down(600)]:{
            marginTop: '79.51px',
        }
    },
  ContainerInfoLectura: {
    position: "relative",
    height: "86px",
    [theme.breakpoints.down(502)]:{
        height: "43px",
    },
    background: "#76D4C3",
    "& .BarraInfoLectura": {
      height: "86px",
      background: `Url(https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Barras/Azul.png)`,
        [theme.breakpoints.down(502)]:{
            height: "43px",
        },
    },
    "& .InfoLectura": {
      position: "absolute",
      height: "86px",
      width: "219px",
      boxSizing: "border-box",
      padding: "17.2px 0",
      background: "#FAFAFA",
      borderRadius: "50px",
      boxShadow: "2px 2px 2px 2px #00000029",
      zIndex: "4",
        [theme.breakpoints.down(502)]:{
            height: "43px",
            width: "109px",
            padding: "8.6px 0",
            borderRadius: "25px",
        },
    },
    "& .InfoLectura:before": {
      position: "absolute",
      display: "flex",
      boxSizing: "border-box",
      padding: "0 0 0 10px",
      justifyContent: "center",
      alignItems: "center",
      height: "39.88px",
      width: "39.88px",
      borderRadius: "50%",
      content: '""',
      boxShadow: "3px 0px .5px 0px #00000029",
      background: "#FAFAFA",
      left: "90px",
      top: "65px",
      transform: "rotate(0.25turn)",
      [theme.breakpoints.down(502)]:{
        left: "45px",
        top: "30px",
        height: "20.88px",
        width: "20.88px",
        },
    },
    "& .InfoLectura:after": {
      content: '""',
      height: "15px",
      width: "15px",
      borderTop: "2px solid #20A698",
      borderLeft: "2px solid #20A698",
      position: "absolute",
      bottom: "-10px",
      left: "Calc(50% - 8px)",
      transform: "rotate(0.625turn)",
      [theme.breakpoints.down(502)]:{
        height: "10px",
        width: "10px",
        left: "Calc(50% - 4.5px)",
        bottom: "-3px",
        borderTop: "1px solid #20A698",
        borderLeft: "1px solid #20A698",
        },
    },
    "& .Sesion": {
      borderLeft: "2px solid #D8D8D8",
    },
    "& .Dato": {
      margin: "0 0 5px 0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#20A698",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        margin: "0 0 2.5px 0",
        },
    },
    "& .Caracter": {
      margin: "0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#000000",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        },
    },   },
    ContenedorLectura:{
        background:'#fff',
        width:'1160px',
        height:'100%',
        padding:'60px 168px 35px ',
        [theme.breakpoints.down(1161)]:{
            width:'502px',
            padding:'60px 0 0 0',
          },
        [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
        '& .ContainerDerechos':{
            bottom:'80px',
            [theme.breakpoints.down(1161)]:{
                display:'none',
            },
        },
        '& .Derechos':{
            textAlign:"center",
            margin:"0px",
            font:" 15px lato",
            color:"#717476",
        },
        '& .Escritorio':{
            display:'block',
            [theme.breakpoints.down(1161)]:{
                display:'none'
            },
        },
        '& .Tablet':{
            display:'none',
            [theme.breakpoints.down(1161)]:{
                display:'block'
            },
        }
    },
    ContainerTitulo:{
        margin:'0 0 34px 0',
        [theme.breakpoints.down(1161)]:{
            margin:'0 0 34px 0',
        },
        '& .Titulo':{
            font:'48px fredoka one',
            letterSpacing: '0px',
            lineHeight:'58px',
            color: '#FF2B2B',
            margin:'0 0 5px 0',
            textAlign:'center',
            [theme.breakpoints.down(1161)]:{
                color: '#F6644B',
                font:'43px fredoka one',
                lineHeight:'52px',
            },
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
        '& .Titulo2':{
            font:'bold 30px lato',
            letterSpacing: '.28px',
            lineHeight:'30px',
            color: '#FF2B2B',
            margin:'0',
            textAlign:'center',
            [theme.breakpoints.down(1161)]:{
                color: '#F6644B',
                font:'bold 26px lato',
                letterSpacing: '.24px',
                lineHeight:'28px',
            },
            [theme.breakpoints.down(502)]:{
                font:'bold 22px lato',
                lineHeight:'24px',
            },
            [theme.breakpoints.down(350)]:{
                font:'bold 18px lato',
                lineHeight:'24px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        position:'relative',
        '& .Lectura':{
            lineHeight : '30px',
            margin:'0 0 36px 0',
            font:'22px lato',
            color:'#000',
            letterSpacing: '0.20px',
            [theme.breakpoints.down(1161)]:{
                font:'18px lato',
                lineHeight :'28px',
                letterSpacing: '0.17px',
            },
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .Imagen':{
            [theme.breakpoints.down(1161)]:{
                width:'100%',
            },
        },  
      
    }
}));

const Los_viajes_de_Maqui_Cap1 = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
             <Grid container item justifyContent="center" className={classes.ContainerInfoLectura}>
               <Grid container className="BarraInfoLectura"></Grid>
               <Grid container item className="InfoLectura">
                    <Grid container item direction="column" xs={6} justifyContent="center">
                        <p className="Dato">Grado</p>
                        <p className="Caracter">4</p>
                    </Grid>
                    <Grid container item direction="column" xs={6} justifyContent="center" className="Sesion">
                        <p className="Dato">Sesión</p>
                        <p className="Caracter">23</p>
                    </Grid>
               </Grid>
            </Grid>

            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                        <Grid container justifyContent="center" sm={12}>
                            <h2 className="Titulo">Los viajes de Maqui</h2>
                        </Grid>
                        <h3 className='Titulo2'>Fragmento 1 (capítulo 1)</h3>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                       <p className='Lectura'>
                        La posición de las estrellas les indica que es medianoche. Desde el navío se escuchan las campanadas de la iglesia. Es hora de ir a la playa. Los animales suben a sus embarcaciones en silencio y llegan remando hasta el puerto. Allí, amarran sus naves. Maqui va al frente con Laila y un cocodrilo. Por su aspecto, intuyo que se trata de Codri, el muñeco de peluche de Maqui, que en este mundo tiene vida.
                        <br/><br/>
                        Los corsarios se apresuran a buscar los cofres en los barcos españoles. Coordinados, recorren cada uno de los camarotes, hasta que Codri encuentra el escondite del tesoro. Cada animalito carga sobre su lomo un baúl y Laila les ordena que abandonen los navíos cuanto antes. Al parecer, nadie los vio.
                        <br/><br/>
                        A través de la noche, los corsarios corren aprisa hasta la iglesia, donde un párroco los espera. Maqui y sus aliados aún no se percatan de que un soplón los sigue desde que bajaron de los barcos. Aparentemente, este sujeto es un navegante que estaba dormido dentro de una embarcación porque se le subieron las copas.
                        <br/><br/>
                        Mientras tanto, las cosas se ponen difíciles en mi nube. El calor de la noche y la humedad están derritiendo el pedacito de algodón, y si no encuentro otro sitio, caeré al mar. En ese momento, veo que el soplón entra a una taberna y delata a los corsarios de Maqui. ¡Es necesario que le advierta que los españoles saben dónde resguardan el tesoro!
                        <br/><br/>
                        La nube está a punto de romperse, no resiste más. Miro al agua y sé que el mar es profundo. Recuerdo mis clases de natación, tomo aire y salto de un clavado. El agua está tibia, pero muy salada. Los ojos me arden muchísimo, por lo que nado rápido hacia la orilla.
                        <br/><br/>
                        —¿Quién puede ser a esta hora? —pregunta el párroco, cuando llaman a la puerta de la iglesia.
                        <br/><br/>
                        —¿Nos habrán seguido hasta aquí?
                        <br/><br/>
                        —¡Maqui, soy yo, Laura!
                        <br/><br/>
                        Los corsarios y el mismo clérigo se quedan perplejos. Una humana del futuro no debe viajar al pasado, está prohibido. Me advierten que 1531 es un año muy lejano y mi presencia podría cambiar el curso de los acontecimientos.
                        <br/><br/>
                        —¿Qué haces aquí, no ves que seguirme es peligroso? —dice mi perrita, enojada.
                        <br/><br/>
                        —Vine a avisarte que los españoles saben que ustedes recuperaron los cofres y se dirigen hacia acá.
                        <br/><br/>
                        No hay tiempo para más explicaciones, el párroco saca de su túnica un par de llaves y nos lleva hasta el sótano, de donde salen unos túneles que recorren la ciudad hasta la aldea maya Kaan Peech, que ha logrado sobrevivir a la Conquista.
                        <br/><br/>
                        —¡Abran! ¡Sabemos que tienen el tesoro! Si no nos apresuramos a cruzar los túneles, no podremos arribar a nuestro destino y posiblemente seremos ejecutados.
                        <br/><br/>
                        El sacerdote abre la puerta de los subterráneos. Yo ayudo a cargar los cofres con el botín. Escuchamos ruidos en la planta de arriba e intuimos que los españoles encontraron la entrada de la iglesia y vienen por nosotros. Los corsarios son tan pequeños que les es difícil avanzar grandes distancias con sus patitas cortas. Afortunadamente, he traído mi mochila del colegio, que resulta de bastante ayuda, pues le cabe de todo. Meto varios objetos de valor y queda espacio de sobra. Hay lugar incluso para Maqui.
                       </p>                   
                    </Grid>
                    <Grid container justifyContent='center' >
                        <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img4to/Sesion23/1.png" alt="Tarjeta" />
                    </Grid>
                </Grid>


            <Grid container item xs={12} justifyContent="center" className="ContainerDerechos">
                <p className="Derechos">
                    © 2022. Todos los derechos reservados, Pearson, Circuito de lectores.
                </p>                
            </Grid>
        </Grid>
    </Grid>
    );
}
 
export default Los_viajes_de_Maqui_Cap1;
