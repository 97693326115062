import React from "react";
import ProtectedRoute from "./ProtectedRoute";

import ListChatContainer from "../pages/Docente/Chat/OutChat/List_Chat_Container";
import ChatContainer from "../pages/Docente/Chat/Chat_Container";

import SettingsContainer from "../pages/Docente/settings/SettingsContainer";
import UsersManual from "../pages/Docente/manual_de_usuario/UsersManualContainer";
import EditStudentContainer from "../pages/Docente/settings/students/EditStudentContainer";

import EditTeacherContainer from "../pages/Docente/settings/teacher/EditContainer";
import EditAlumnContainer from "../pages/Docente/settings/students/EditContainer";

import Char from "../Charts/BarChar";
import Char2 from "../Charts/BarChar2";
// import Reporte4 from "../Charts/Fallos";
import Fortalezas from "../Charts/Fortalezas";

import Sesiones from "../pages/Docente/sesiones/Sesiones";

import Reportes from "../pages/Container/ContenedorReporte";
import WelcomeTeacher from "../pages/Docente/WelcomeTeacher";
import AvatarUploader from "../pages/Docente/AvatarUploader";
import TeachersGuide from "../pages/Docente/recursos_docente/TeachersGuideContainer";
import GuidedPractices from "../pages/Docente/practicas_guiadas/GuidedPractices";
import VideosRemediales from "../pages/Docente/videos_remediales/VideosRemediales";
// import EditAlumnContainer from "../pages/Docente/settings/students/EditContainer";

import Reporte1 from "../pages/Docente/Reportes/Reporte_1";
import AsignarRemedial from "../pages/Docente/sesiones/AsignarRemedial";
import DetalleSesion from "../pages/Docente/sesiones/DetalleSesion";

import Reporte5 from "../pages/Docente/Reportes/Reporte_5";
import Reporte2 from "../pages/Docente/Reportes/Reporte_2";
import Reporte4 from "../pages/Docente/Reportes/Reporte_4";
import Reporte6 from "../pages/Docente/Reportes/Reporte_6";
import Reporte8 from "../pages/Docente/Reportes/Reporte_8";


import TeachersReading from "../pages/Docente/biblioteca_docente/TeachersReading";
// import Reporte5 from "../pages/Docente/Reportes/Reporte_5";

import Biblioteca from "../pages/Docente/biblioteca_docente/library";
import PerfilDocente from "../pages/Docente/PerfilDocente";

const routes = [
  <ProtectedRoute
    exact
    path="/docente/welcome"
    component={WelcomeTeacher}
    key="/docente/welcome"
  />,
  <ProtectedRoute
    exact
    path="/docente/perfil"
    component={PerfilDocente}
    key="/docente/perfil"
  />,
  <ProtectedRoute exact path="/docente/recursos" component={TeachersGuide} />,
  <ProtectedRoute
    exact
    path="/docente/practicas-guiadas"
    component={GuidedPractices}
  />,
  <ProtectedRoute exact path="/docente/biblioteca" component={Biblioteca} />,
  <ProtectedRoute
    exact
    path="/docente/videos_remediales"
    component={VideosRemediales}
  />,

  <ProtectedRoute exact path="/docente/avatar" component={AvatarUploader} />,
  <ProtectedRoute
    exact
    path="/docente/configuracion"
    component={SettingsContainer}
  />,
  <ProtectedRoute
    exact
    path="/docente/manual_de_usuario"
    component={UsersManual}
  />,

  <ProtectedRoute
    exact
    path="/docente/editar"
    component={EditTeacherContainer}
  />,
  <ProtectedRoute
    path="/docente/editar/alumno/:id_usuario"
    component={EditAlumnContainer}
  />,
  <ProtectedRoute
    path="/docente/configuracion/alumno/:id_distribucion_escuela"
    component={EditStudentContainer}
  />,

  <ProtectedRoute exact path="/docente/reportes" component={Reportes} />,
  <ProtectedRoute exact path="/docente/reportes/1" component={Reporte1} />,
  <ProtectedRoute exact path="/docente/reportes/2" component={Reporte2} />,
  <ProtectedRoute exact path="/docente/reportes/4" component={Reporte4} />,

  <ProtectedRoute exact path="/docente/reportes/5" component={Reporte5} />,
  <ProtectedRoute exact path="/docente/reportes/6" component={Reporte6} />,
  <ProtectedRoute exact path="/docente/reportes/8" component={Reporte8} />,

  <ProtectedRoute exact path="/Char" component={Char} />,
  <ProtectedRoute exact path="/Char2" component={Char2} />,
  <ProtectedRoute exact path="/reporte_3" component={Fortalezas} />,

  <ProtectedRoute exact path="/chat" component={ListChatContainer} />,
  <ProtectedRoute path="/chat/:id" component={ChatContainer} />,
  <ProtectedRoute exact path="/docente/sesiones" component={Sesiones} />,
  <ProtectedRoute
    path="/docente/asignar_remedial/:idGrupo/:numeroRemedial"
    component={AsignarRemedial}
  />,
  <ProtectedRoute
    exact
    path="/docente/sesiones/detalle"
    component={DetalleSesion}
  />,
  <ProtectedRoute
    path="/lecturas/:id_grado/:id_actividad"
    component={TeachersReading}
  />,
];

const Docente = routes.map((route, key) => {
  return { ...route, key: key };
});

export default Docente;
