import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
     Contenedor:{
        background:'#E9FBF9',
        width:'100vw',
        overflowX:'clip',
        [theme.breakpoints.down(1161)]:{
            background:'none',
           overflowX:'unset',
        },
        [theme.breakpoints.down(600)]:{
            marginTop: '79.51px',
        }
    },
  ContainerInfoLectura: {
    position: "relative",
    height: "86px",
    [theme.breakpoints.down(502)]:{
        height: "43px",
    },
    background: "#76D4C3",
    "& .BarraInfoLectura": {
      height: "86px",
      background: `Url(https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Barras/Azul.png)`,
        [theme.breakpoints.down(502)]:{
            height: "43px",
        },
    },
    "& .InfoLectura": {
      position: "absolute",
      height: "86px",
      width: "219px",
      boxSizing: "border-box",
      padding: "17.2px 0",
      background: "#FAFAFA",
      borderRadius: "50px",
      boxShadow: "2px 2px 2px 2px #00000029",
      zIndex: "4",
        [theme.breakpoints.down(502)]:{
            height: "43px",
            width: "109px",
            padding: "8.6px 0",
            borderRadius: "25px",
        },
    },
    "& .InfoLectura:before": {
      position: "absolute",
      display: "flex",
      boxSizing: "border-box",
      padding: "0 0 0 10px",
      justifyContent: "center",
      alignItems: "center",
      height: "39.88px",
      width: "39.88px",
      borderRadius: "50%",
      content: '""',
      boxShadow: "3px 0px .5px 0px #00000029",
      background: "#FAFAFA",
      left: "90px",
      top: "65px",
      transform: "rotate(0.25turn)",
      [theme.breakpoints.down(502)]:{
        left: "45px",
        top: "30px",
        height: "20.88px",
        width: "20.88px",
        },
    },
    "& .InfoLectura:after": {
      content: '""',
      height: "15px",
      width: "15px",
      borderTop: "2px solid #20A698",
      borderLeft: "2px solid #20A698",
      position: "absolute",
      bottom: "-10px",
      left: "Calc(50% - 8px)",
      transform: "rotate(0.625turn)",
      [theme.breakpoints.down(502)]:{
        height: "10px",
        width: "10px",
        left: "Calc(50% - 4.5px)",
        bottom: "-3px",
        borderTop: "1px solid #20A698",
        borderLeft: "1px solid #20A698",
        },
    },
    "& .Sesion": {
      borderLeft: "2px solid #D8D8D8",
    },
    "& .Dato": {
      margin: "0 0 5px 0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#20A698",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        margin: "0 0 2.5px 0",
        },
    },
    "& .Caracter": {
      margin: "0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#000000",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        },
    },   },
    ContenedorLectura:{
        background:'#fff',
        width:'1160px',
        height:'100%',
        padding:'60px 168px 35px ',
        [theme.breakpoints.down(1161)]:{
            width:'502px',
            padding:'60px 0 0 0',
          },
        [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
        '& .ContainerDerechos':{
            bottom:'80px',
            [theme.breakpoints.down(1161)]:{
                display:'none',
            },
        },
        '& .Derechos':{
            textAlign:"center",
            margin:"0px",
            font:" 15px lato",
            color:"#717476",
        },
        '& .Escritorio':{
            display:'block',
            [theme.breakpoints.down(1161)]:{
                display:'none'
            },
        },
        '& .Tablet':{
            display:'none',
            [theme.breakpoints.down(1161)]:{
                display:'block'
            },
        }
    },
    ContainerTitulo:{
        margin:'0 0 36px 0',
        '& .Titulo':{
            letterSpacing: '0px',
            color: '#F6644B',
            margin:'0',
            textAlign:'center',
            font:'48px fredoka one',
            [theme.breakpoints.down(1161)]:{
                font:'43px fredoka one',
                lineHeight:'52px',
            },
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            margin:'0 0 36px 0',
            color:'#000',
            font:'22px lato',
            letterSpacing: '0.20px',
            lineHeight : '30px',
            [theme.breakpoints.down(1161)]:{
                font:'18px lato',
                lineHeight :'28px',
                letterSpacing: '0.17px',
            },
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .SubTitulo':{
            color:'#DF5DA7',
            margin:'0',
            font:'30px Fredoka One',
            letterSpacing:'.28px',
            [theme.breakpoints.down(1161)]:{
                font:'23px Fredoka One',
                lineHeight :'30px',
                letterSpacing: '0.22px',
            },
            [theme.breakpoints.down(502)]:{
                font:'18px Fredoka One',
                lineHeight :'20px',
                letterSpacing: '0.16px',
            },
        },
        '& .TextImg':{
            color:'#717476',
            font:'20px lato',
            margin:'22px 0 0 0',
            lineHeight :'30px',
            letterSpacing: '0.19px',
            [theme.breakpoints.down(1161)]:{
                font:' 16px lato',
                lineHeight :'25px',
                letterSpacing: '0.15px',
            },
            [theme.breakpoints.down(502)]:{
                font:' 11px lato',
                lineHeight :'14px',
                letterSpacing: '0.10px',
            },
        },
        
        '& .Imagen':{
            [theme.breakpoints.down(1161)]:{
                width:'100%'
            },
        },
        '& .Imagen2':{
            [theme.breakpoints.down(1161)]:{
                width:'49.60%'
            },
        },
        '& .Imagen3':{
            [theme.breakpoints.down(1161)]:{
                width:'80.87%'
            },
        },
    },

    
    

}));

const El_numero_0 = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
             <Grid container item justifyContent="center" className={classes.ContainerInfoLectura}>
               <Grid container className="BarraInfoLectura"></Grid>
               <Grid container item className="InfoLectura">
                    <Grid container item direction="column" xs={6} justifyContent="center">
                        <p className="Dato">Grado</p>
                        <p className="Caracter">4</p>
                    </Grid>
                    <Grid container item direction="column" xs={6} justifyContent="center" className="Sesion">
                        <p className="Dato">Sesión</p>
                        <p className="Caracter">26</p>
                    </Grid>
               </Grid>
            </Grid>

            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >
                        El número Ø
                    </h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                            Hasta ahora has estudiado únicamente números naturales, pero existen más tipos. 
                            En esta lección conocerás uno nuevo. ¡Comencemos!<br/><br/> 
                            
                            Este número tiene correspondencia con algo que se conoce como proporción. La 
                            proporción es la relación que guardan dos medidas entre sí. Este tipo de unión es 
                            constante, lo que significa que no cambia. <br/><br/>
                            
                            Veamos un ejemplo. En las recetas de los platillos usualmente se indica para cuántas 
                            personas rendirá cada uno. Imagina que tenemos una bolsa de pasta que hace la 
                            cantidad suficiente para cuatro personas. Si quisiéramos hacer comida para ocho 
                            personas, ¿cuántas bolsas necesitaríamos? ¡Exacto! Dos bolsas.<br/><br/>
                            
                            Se mantiene constante la relación entre el número de bolsas de pasta y la cantidad 
                            de personas para las que alcanza. Eso es la proporción y podemos ver cómo funciona 
                            en la siguiente tabla.
                        </p>
                    </Grid>
                    <Grid container item justifyContent='center'>
                        <img className='Imagen' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img4to/Sesion26/1.png" alt="" />
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container item justifyContent='center' xs={12}>

                        <p className='Lectura '>
                            <h3 className='SubTitulo'>La proporción áurea</h3><br/>
                            
                            Los antiguos griegos descubrieron y estudiaron una proporción en especial. Imagina 
                            que tomamos sólo una parte de una línea y la dividimos en dos secciones desiguales. 
                            Al hacerlo, una queda más grande que la otra. Ellos se dieron cuenta de que la 
                            relación que guardaba la zona pequeña con la grande era la misma que tenía la zona 
                            grande con la línea original. <br/><br/>
                            
                            Por ejemplo, tomemos un segmento de una línea, en este caso usaremos el color 
                            verde para marcarlo. Después, lo fraccionamos en dos regiones que no sean 
                            idénticas. Utilizaremos azul para la región más amplia y rojo para la menor. De esta 
                            forma, el procedimiento se vería así.
                        </p>
                    </Grid>

                    <Grid container item justifyContent='center' xs={12}>
                        <Grid container item justifyContent='center' xs={12}>
                            <img className='Imagen' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img4to/Sesion26/2.png" alt="" />
                        </Grid> 
                        <Grid container justifyContent='center' item  xs={12}>
                            <p className='TextImg'>
                                La proporción que guarda el segmento más pequeño con el más grande es la misma que guarda el segmento más grande con el segmento original.
                            </p>
                        </Grid>
                    </Grid>
                   
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                            Si las longitudes azul y roja se relacionan de la siguiente manera, se dice que están en 
                            <em> proporción áurea</em>.
                        </p>
                    </Grid>
                    <Grid container item justifyContent='center' xs={12}>
                        <Grid container item justifyContent='center' xs={12}>
                            <img className='Imagen2' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img4to/Sesion26/3.png" alt="" />
                        </Grid> 
                        <Grid container justifyContent='center' item  xs={12}>
                            <p className='TextImg'>
                                Una proporción es la relación que guarda una parte de algo con el todo.
                            </p>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                        La proporción áurea es conocida como el número <em>ϕ</em>. Este símbolo es la 
                        representación de la letra griega phi (<em>fi</em>). Se nombró así en honor al antiguo escultor 
                        Fidias, quien la usó en sus obras.<br/><br/>
                        
                         Este número se relacionó con la belleza en el arte durante el Renacimiento europeo. 
                         Por eso, el pintor Leonardo da Vinci lo llamó el <em>número de oro</em>. Los matemáticos y 
                         artistas de la época representaron la proporción áurea con una espiral. Dentro de 
                         ella, se dibuja un rectángulo que se divide en varias secciones. La particularidad es 
                         que al interior del rectángulo se conserva siempre la misma proporción en cada 
                         sección nueva que se haga.
                        </p>
                    </Grid>
                    <Grid container item justifyContent='center' xs={12}>
                        <Grid container item justifyContent='center' xs={12}>
                            <img className='Imagen3' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img4to/Sesion26/4.png" alt="" />
                        </Grid> 
                        <Grid container item  xs={12}>
                            <p className='TextImg'>
                                La curva que se obtiene al seguir los rectángulos con esta proporción se conoce como <em>espiral 
                                dorada</em>.
                            </p>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                            <h3 className='SubTitulo'>Aplicaciones</h3><br/>
                            
                            Actualmente, podemos encontrar la proporción áurea en artes como la 
                            pintura, la escultura y la fotografía. También es usada para diseñar logos de marcas. 
                            Curiosamente, esta espiral aparece naturalmente en muchas partes del cuerpo 
                            humano y del animal, en diversas plantas, en fenómenos naturales como huracanes y 
                            hasta en las formas de las galaxias. Es decir, ¡en lugares en los que los seres humanos 
                            no pueden intervenir!
                        </p> 
                    </Grid>
                    <Grid container item justifyContent='center' xs={12}>
                        <Grid container item justifyContent='center' xs={12}>
                            <img className='Imagen' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img4to/Sesion26/5.png" alt="" />
                        </Grid> 
                        <Grid container item  xs={12}>
                            <p className='TextImg'>
                                La proporción áurea está presente tanto en algunas plantas y animales como en algunos objetos hechos por los seres humanos.
                            </p>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                            Ahora conoces el número áureo y sabes dónde encontrarlo. ¿Alguna vez lo has 
                            notado? ¿En qué objetos de tu alrededor podría estar?
                        </p> 
                    </Grid>
                </Grid>

                <Grid container item xs={12} justifyContent="center" className="ContainerDerechos">
                    <p className="Derechos">
                        © 2022. Todos los derechos reservados, Pearson, Circuito de lectores.
                    </p>                
                </Grid>
        </Grid>
    </Grid>
    );
}
 
export default El_numero_0;
