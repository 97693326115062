import React, { useState } from "react";
import { makeStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import moment from "moment";
import pdfImage from "../../imgs/pdf.png";
import docImage from "../../imgs/Documento.png";
import ModalImage from "../../Modals/ModalImage";
import StarIcon from "@mui/icons-material/Star";
const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    display: "flex",
    alignContent: "center",
    alignItems: "flex-start",
    // flexDirection: "column",
    justifyContent: "flex-start",
    flexDirection: "row",
    gap: "2.5%",
    [theme.breakpoints.up("lg")]: {
      justifyContent: "flex-start",
    },
  },
  containerText: {
    backgroundColor: "#9AF0E8",
    display: "flex",
    borderRadius: "20px",
    flexDirection: "column",
    position: "relative",
    minHeight: "50px",
    justifyContent: "center",
    zIndex: 2,
    boxSizing: "border-box",
    padding: "10px 20px",
    alignItems: "center",
    "&:before": {
      // display: (props) => (props.openMenuInDesktop ? "none" : "flex"),
      display: "flex",
      zIndex: -1,
      textTransform: "none",
      justifyContent: "center",
      alignItems: "center",
      // borderRadius: "25px",
      position: "absolute",
      content: '""',
      // height: "10px",
      // width: "10px",
      border: "10px solid",
      borderTopColor: "transparent",
      borderRightColor: "transparent",
      borderLeftColor: "#9AF0E8",
      borderBottomColor: "#9AF0E8",
      bottom: "15%",
      left: "-5px",
      transform: "rotate(30deg)",
    },
    [theme.breakpoints.up("lg")]: {
      padding: "20px",
      maxWidth: "80%",
    },
  },
  text: {
    font: "normal 16px Lato",
    textAlign: "left",
    margin: 0,
    // minHeight: "30px",
    minWidth: "5ch",
    overflowWrap: "anywhere",
  },
  image: {
    borderRadius: "50%",
    display: "none",
    width: "50px",
    [theme.breakpoints.up("sm")]: {
      display: "flex",
    },
  },
  timeAgo: {
    color: "#969D9F",
    // position: "absolute",
    width: "100%",
    textAlign: "left",
    fontSize: "10px",
    // bottom: "-20px",
    // left: 0,
  },
  containerDocument: {
    // backgroundColor: "#FFDF76",
    display: "flex",
    borderRadius: "20px",
    // position: "relative",
    zIndex: 2,
    flexDirection: "column",
    alignItems: "flex-start",
    boxSizing: "border-box",

    [theme.breakpoints.up("lg")]: {
      // padding: "20px",
      maxWidth: "80%",
    },
  },
  imageDocument: {
    width: "100%",
  },
  imageButton: {
    // padding: "10px 20px",
    zIndex: 2,
    position: "relative",
    boxSizing: "border-box",
    width: "50%",
    borderRadius: "20px",
    display: "flex",
    justifyContent: "flex-start",
  },
  timeAgoDocument: {
    color: "#969D9F",
    // position: "absolute",
    // width: "100%",
    textAlign: "left",
    fontSize: "10px",
  },

  imgContainer: {
    width: "75%",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    [theme.breakpoints.up("lg")]: {
      width: "25%",
    },
  },
  img: {
    width: "auto",
    maxHeight:"480px",
    objectFit: "cover",
    cursor: "pointer",
    border:"2.5px solid #007FA3"
  },
  iconStar: {
    color: "#F3CD52",
    alignSelf: "center",
  },
  iconStarOff: {
    color: "#00000050",
    alignSelf: "center",
  },
}));

const OtherPersonMessages = ({ message, image, timestamp, type, mediaData }) => {
  moment.locale("es", {
    calendar: {
      lastDay: "[Yesterday] h:mm a",
      sameDay: "[Today] h:mm a",
      lastWeek: "[Last] dddd h:mm a",
      sameElse: "Do MMMM YYYY h:mm a",
    },
  });

  const now = moment(timestamp);
  const dateInString = now.calendar();
  const [open, setOpen] = useState(false);

  const classes = useStyles();

  return (
    <>
      {type === "texto" && (
        <Grid className={classes.container}>
          <Grid className={classes.containerImg}>
            <img src={image} alt="" className={classes.image} />
          </Grid>
          <Grid container direction="row" justifyContent="flex-end" alignItems="center" style={{ width: "auto" }}>
            <Grid container>
              <Grid className={classes.containerText}>
                <p className={classes.text}>{message?.mensaje}</p>
              </Grid>
              <span className={classes.timeAgo}>{dateInString}</span>
            </Grid>
          </Grid>
          {message.favorito && <StarIcon className={classes.iconStar} />}
        </Grid>
      )}
      {type === "document" && (
        <Grid className={classes.container}>
          <Grid className={classes.containerImg}>
            <img src={image} alt="" className={classes.image} />
          </Grid>
          <Grid className={classes.containerDocument}>
            <a href={message?.mensaje} download={true} className={classes.imageButton} target="_blank" rel="noreferrer">
              <img src={message.mensaje?.includes("pdf") ? pdfImage : docImage} alt="" className={classes.imageDocument} />
            </a>
            <span className={classes.timeAgoDocument}>{dateInString}</span>
          </Grid>
          {message.favorito && <StarIcon className={classes.iconStar} />}
        </Grid>
      )}
      {type === "image" && (
        <Grid className={classes.container}>
          <Grid className={classes.containerImg}>
            <img src={image} alt="" className={classes.image} />
          </Grid>
          <Grid className={classes.imgContainer}>
            <img
              src={message?.mensaje || "http"}
              alt=""
              className={classes.img}
              onClick={() => {
                setOpen(!open);
              }}
            />
            <ModalImage
              path={message?.mensaje || "http"}
              open={open}
              handleClose={() => {
                setOpen(!open);
              }}
            />
            <span className={classes.timeAgoDocument}>{dateInString}</span>
          </Grid>
          {message.favorito && <StarIcon className={classes.iconStar} />}
        </Grid>
      )}
    </>
  );
};

export default OtherPersonMessages;
