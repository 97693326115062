import { makeStyles } from "@material-ui/core";
import React from "react";

import { Link } from "react-router-dom";
import ContadorModal from "../Modals/ContadorModal";

const useStyles = makeStyles((theme) => ({
  link: {
    "& .BotonGrande": {
      cursor: "pointer",
      border: "none",
      backgroundColor: "transparent",
      position: "absolute",
      height: "55.7px",
      width: "55.7px",
      borderRadius: "50%",
      font: " 22px lato",
      borderWidth: "3px",
      borderStyle: "solid",
      borderColor: "#800080",
      zIndex: "999",
      "&:hover": {
        // borderWidth: "2px",
        // borderStyle: "solid",
        // borderColor: (props) => props.color,
      },
    },
    "& .Remedial-1": {
      top: "854.5px",
      left: "652.5px",
      "&:hover": {
        "&:before": {
          display: "flex",
          position: "relative",
          justifyContent: "center",
          alignItems: "center",
          bottom: "50px",
          content: '"Evaluación Remedial 1"',
          background: "#fff",
          borderRadius: "25px",
          font: "bold 16px lato",
          height: "25px",
          width: "195px",
        },
      },
    },
    "& .Remedial-2": {
      top: "206.5px",
      left: "994.5px",
      "&:hover": {
        "&:before": {
          display: "flex",
          position: "relative",
          justifyContent: "center",
          alignItems: "center",
          bottom: "50px",
          content: '"Evaluación Remedial 2"',
          background: "#fff",
          borderRadius: "25px",
          font: "bold 16px lato",
          height: "25px",
          width: "195px",
        },
      },
    },
    "& .Remedial-3": {
      top: "593.5px",
      left: "1543.5px",
      "&:hover": {
        "&:before": {
          display: "flex",
          position: "relative",
          justifyContent: "center",
          alignItems: "center",
          bottom: "50px",
          content: '"Evaluación Remedial 3"',
          background: "#fff",
          borderRadius: "25px",
          font: "bold 16px lato",
          height: "25px",
          width: "195px",
        },
      },
    },
    "& .bloqueada": {
      borderWidth: "2px",
      borderStyle: "solid",
      borderColor: "#808080",
      backgroundColor: "#80808080",

      "&:hover": {
        cursor: "default",
      },
    },
    "& .completa": {
      borderWidth: "2px",
      borderStyle: "solid",
      borderColor: "#008000",
      "&:hover": {},
    },
    "& .contador": {
      borderWidth: "2px",
      borderStyle: "solid",
      borderColor: "#FFCE30",
      "&:hover": {},
    },
  },
}));

const MapButtonRemedial = ({ data, actualizeList }) => {
  const { sesion, actividad_tipo } = data;
  const [frame, setFrame] = React.useState(false);
  const [contadorOpen, setContadorOpen] = React.useState(false);

  const handleFrame = () => {
    setFrame(!frame);
  };
  const handleContadorOpen = () => {
    setContadorOpen(!contadorOpen);
  };

  const classes = useStyles({});

  return (
    <>
      {data?.actividad_status.toLowerCase() === "sesión desbloqueada" && (
        <>
          <Link className={classes.link} to={`/session${data?.actividad_tipo}`}>
            <button
              className={`BotonGrande ${actividad_tipo}`}
              onClick={handleFrame}
              aria-label={`Actividad ${sesion}`}
            />
          </Link>
        </>
      )}
      {data?.actividad_status.toLowerCase() === "contador" && (
        <>
          <Link className={classes.link} to="#">
            <button
              className={`BotonGrande ${actividad_tipo} contador`}
              onClick={handleContadorOpen}
              aria-label={`Actividad ${sesion}`}
            ></button>
          </Link>
          <ContadorModal
            open={contadorOpen}
            handleClose={handleContadorOpen}
            timestamp={data.tiempo_espera}
            actualizeList={actualizeList}
          />
        </>
      )}
      {/* {data?.actividad_status.toLowerCase() === "bloqueada" && (
        <>
          <Link className={classes.link} to="#">
            <button
              className={`BotonGrande ${actividad_tipo} bloqueada`}
              onClick={handleContadorOpen}
              disabled
            ></button>
          </Link>
        </>
      )} */}
    </>
  );
};

export default MapButtonRemedial;
