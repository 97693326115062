import { Button, makeStyles, Grid } from "@material-ui/core";
import React from "react";
import Dialog from "@material-ui/core/Dialog";
import manualImg from "../img/Manual_del_usuario_estudiante.png";
import downloadIcon from "../biblioteca_docente/descargar.png";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    padding: "48px 0 48px 0",
    background:
      "linear-gradient(217deg, rgba(255,194,0,0.4962185557816877) 2%, rgba(255,255,255,1) 25%, rgba(255,255,255,0.4962185557816877) 81%, rgba(144,81,177,1) 98%)",
    minHeight: "calc(100vh - 80px)",
  },
  boxContainer: {
    background: "#fff",
    width: "90%",
    gap: "20px",
    maxWidth: "1160px",
    minHeight: "70vh",
    borderRadius: "42px",
    padding: "30px",
    alignItems: "center",
    boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.5)",
    [theme.breakpoints.up("sm")]: {
      width: "auto",
      minWidth: "75%",
    },
    "& .Titulo": {
      font: "48px Fredoka One",
      margin: "0",
      textAlign: "center",
      color: "#a10684",
      [theme.breakpoints.down(425)]: {
        font: "32px Fredoka One",
      },
    },
  },
  buttonsContainer: {
    width: "100%",
    gap: "20px",
    [theme.breakpoints.up("sm")]: {
      width: "60%",
    },
  },
  ContainerLinkinsiginas: {
    textDecoration: "none",
    "& .ButtonIsg": {
      color: "black",
      textTransform: "none",
      font: " 22px lato",
      gap: "10px",
      letterSpacing: "0.36px",
      [theme.breakpoints.down(425)]: {
        font: " 16px lato",
      },
    },
    "& p": {
      textAlign: "start",
    },
    "& .icon": {
      color: "purple",
      marginRight: "20px",
      fontSize: "75px",
      [theme.breakpoints.down(425)]: {
        fontSize: "50px",
        marginRight: "0px",
      },
    },
  },

  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  containerImg: {
    display: "flex",
    width: "auto",
    padding: "25px",
    justifyContent: "center",
  },
  manualImg: {
    width: "60%",
  },
  textDownloadInfo: {
    font: "300 22x lato ",
    margin: "20px 0 20px 0",
    textAlign: "center",
    color: "#a10684",
    [theme.breakpoints.down(425)]: {
      font: "16px lato",
    },
  },
  downloadButtonIcon: {
    backgroundColor: "#F26C55 ",
    width: "40px",
    height: "40px",
    alignItems: "center",
    justifyContent: "center",
    color: "black",
    textTransform: "none",
    font: " 22px lato",
    gap: "10px",
    letterSpacing: "0.36px",
    [theme.breakpoints.down(425)]: {
      font: " 16px lato",
    },
    "&:hover": {
      color: "black",
      backgroundColor: "orange ",
    },
  },
}));

const SettingsTeacher = () => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Grid
      container
      item
      sm={12}
      alignItems="center"
      justifyContent="center"
      className={classes.mainContainer}
    >
      <Grid container item direction="column" className={classes.boxContainer}>
        <h2 className="Titulo">Manual de usuario</h2>
        <Grid
          container
          className={classes.ContainerLinkinsiginas}
          item
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          {/* <Button className="ButtonIsg" onClick={() => {setOpen(true)}}>
              <LibraryBooksIcon className="icon" />
              <p> Descargar manual</p>
            </Button> */}
          <Grid
            container
            item
            direction="column"
            alignItems="center"
            justifyContent="center"
            className={classes.containerImg}
          >
            <img src={manualImg} alt="" className={classes.manualImg} />

            <p className={classes.textDownloadInfo}>
              {" "}
              Descarga manual de docente:
            </p>
            <Button
              className={classes.downloadButtonIcon}
              onClick={() => {
                setOpen(true);
              }}
            >
              <img src={downloadIcon} alt="download" />
            </Button>
          </Grid>
        </Grid>

        <Dialog open={open} onClose={handleClose}>
          <object
            style={{ width: "500px", height: "400px", margin: "0px" }}
            data="https://pearson-cdl-reportes-qa.s3.amazonaws.com/CDL_DOCENTE_MANUAL.pdf"
            type="application/pdf"
          ></object>
        </Dialog>
        {/* </Grid> */}
      </Grid>
    </Grid>
  );
};

export default SettingsTeacher;
