import { Grid, makeStyles } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({
  Contenedor: {
    // width:'100vw',
  },
  Creditos: {
    width: "100%",
    height: "100%",
    "& .Titulo": {
      font: "48px fredoka one",
      letterSpacing: "0px",
      lineHeight: "58px",
      color: "#000",
      margin: "0 0 36px 0",
      textAlign: "center",
      [theme.breakpoints.down(1280)]: {
        font: "43px fredoka one",
        lineHeight: "52px",
      },
      [theme.breakpoints.down(502)]: {
        font: "30px fredoka one",
        lineHeight: "32px",
      },
    },
    "& .SubTitulo": {
      font: "38px Fredoka One",
      lineHeight: "46px",
      color: "#9051b1",
      margin: "0 0 25px 0",
      [theme.breakpoints.down(1280)]: {
        font: "33px Fredoka One",
        lineHeight: "41px",
      },
      [theme.breakpoints.down(520)]: {
        font: "26px Fredoka One",
        textAlign:'center',
      },
    },
    "& .Morado": {
      color: "#9051b1",
    },
    "& .Amarillo": {
      borderRight: "solid 3px #ffc200",
      boxSizing: "border-box",
      padding: "0 10px 0 0",
      justifyContent: "flex-end",
      fontFamily: "lato",
      [theme.breakpoints.down(1280)]: {
        border: "none",
        padding: "0 ",
        justifyContent: "center",
      },
      [theme.breakpoints.down(729)]: {
        justifyContent: "flex-start",
      },
    },
    "& .Persona": {
      fontFamili: "lato bold",
      boxSizing: "border-box",
      padding: "0 0 0 10px",
      margin: "0 0 15px 0",
      [theme.breakpoints.down(1280)]: {
        justifyContent: "center",
        padding: "0 ",
      },
      [theme.breakpoints.down(729)]: {
        justifyContent: "flex-start",
      },
    },

    "& .Lectura": {
      margin: "0",
      fontSize: "17px",
      lineHeight: "19px",
      color: "#77838",
      letterSpacing: "0.17px",
      [theme.breakpoints.down(1280)]: {
        fontSize: "16px",
        lineHeight: "18px",
        letterSpacing: "0.17px",
      },
      [theme.breakpoints.down(729)]: {
        fontSize: "14px",
        lineHeight: "16px",
        letterSpacing: "0.12px",
        justifyContent: "flex-start",
      },
    },

    "& .TextImg": {
      font: "20px lato",
      color: "#717476",
      letterSpacing: ".19px",
      lineHeight: "30px",
      margin: "24px 0 0 0",
      [theme.breakpoints.down(1280)]: {
        font: " 16px lato",
        lineHeight: "25px",
        letterSpacing: "0.15px",
      },
      [theme.breakpoints.down(502)]: {
        font: " 14px lato",
        lineHeight: "21px",
        letterSpacing: "0.15px",
      },
    },
  },
}));

const Creditos = () => {
  const classes = useStyles();
  return (
    <Grid container item justifyContent="center" className={classes.Contenedor}>
     
      <Grid container item justifyContent="center" className={classes.Creditos}>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Vicepresidencia de producto LATAM:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Juliano de Melo Costa </p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Dirección de portafolio K-12 HA & HED LATAM:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Jorge Luis Íñiguez </p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Gerencia de contenidos K-12:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Teresa Islas Paredes </p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Coordinación de arte y diseño:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Mónica Galván Alvarez </p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Especialización en contenidos de aprendizaje K-12:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Malineli Guerrero Martínez y Carolina Vega López </p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Supervisión de gamificación:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura"> Salua Aramoni Quintero </p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Líder de desarrollo digital:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Janeth Gutiérrez </p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Arquitectos de solución digital:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Karol Cruz Cano y Cristian Ruiz Castillo de Lerin </p>
        </Grid>

        <Grid container item justifyContent="center" sm={12}>
          <p>***</p>
        </Grid>

        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Diseño de la solución:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura"> Julieta López Olalde </p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Asesoría en habilidades socioemocionales:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura"> Raúl Fernando López Romero </p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Coordinación externa de cuidado editorial:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura"> José Nava Díaz </p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Coordinación externa de diseño:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura"> Iván Daniel Moreno Aguilar </p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Coordinación de lecturas de seguridad y aseguramiento de la calidad (QA):</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura"> Ricardo Gallardo </p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Aseguramiento de la calidad de diseño (QAD): </b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura"> Iván Daniel Moreno Aguilar </p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Asistencia editorial:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura"> Anayte Fernanda Pérez Jiménez </p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Ilustraciones de plataforma: </b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura"> Ramón García Martínez y Daniel Olmos Luna </p>
        </Grid>

        <Grid container item justifyContent="center" sm={12}>
          <p>***</p>
        </Grid>

        <Grid container item sm={12} justifyContent="center">
          <h3 className="SubTitulo">Equipo de desarrollo Codetidy</h3>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Founders</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura"> Adrián Nava y Jawer Nava </p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Coordinación de proyecto:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura"> Valery Betancourt y Julio Omaña </p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Diseño UX:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura"> Gabriela Gavidia </p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Arquitectura de soluciones y diseño de AWS</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura"> Selene Moy </p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Desarrollo Web Front end:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura"> Sergio Arteaga, Carlos Gutiérrez y Julio Quintero </p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Desarrollo Web Back end:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura"> Jordan Dimas y Juan Daniel Álvarez </p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Especialización en pruebas y tester de software:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Nahani Elianne, Ezequiel Saldaña y Jatziri Sotelo</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Autoría de narrativas 6 grados:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Tania Castro</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Desarrollo móvil Android:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura"> Rafael Claudies </p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Desarrollo móvil iOS:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura"> Luis Alberto Pérez  </p>
        </Grid>

        <Grid container item justifyContent="center" sm={12}>
          <p>***</p>
        </Grid>

        <Grid container item sm={12} justifyContent="center">
          <h3 className="SubTitulo">GRADO 1</h3>
        </Grid>

        <Grid container item sm={12} justifyContent="center">
          <b className="Lectura">
            <br />
            -Textos-
            <br />
            <br />
          </b>
        </Grid>

        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Autoría de lengua materna:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura"> Carlos Alberto López Navarrete y Alejandro Demian Marín Bello </p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Autoría de pensamiento matemático:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">José Eduardo Sánchez Vizcaíno </p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Autoría de ciencias humanas:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">
            
            Gerardo Andrés Bolívar Arévalo, Sandra Cara Camarena, Aura García Junco Moreno y Ana Victoria Gaxiola Lazcano
          </p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Autoría de ciencias naturales:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">María Antonieta Duarte Romero y Andrea Paola Torres Flores </p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Autoría de arte:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura"> Armando Reyes Arratia </p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Autoría de ética:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Camino Aparicio Barragán, Raúl Fernando López Romero y Jorge Isaac Sevchovicius Macías </p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Corrección de estilo:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura"> Lesvia Guadalupe Ramos Sánchez, Diana Magali Vázquez Gasca y Jesús Humberto Zúñiga Azuara </p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Edición de desarrollo:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura"> Olivia Margarita Ceballos Dueñas </p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Revisión técnica:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">
            Dolores Adriana Navarro Herrera, Ariadna Angélica Navarro Rangel, Daniel Alejandro Ramirez Hernández y Elik Germán
            Troconis Martínez
          </p>
        </Grid>

        <Grid container item sm={12} justifyContent="center">
          <b className="Lectura">
            <br />
            -Actividades-
            <br />
            <br />
          </b>
        </Grid>

        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Autoría de reactivos de comprensión lectora:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Julieta López Olalde</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Autoría de reactivos socioemocionales:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura"> Camino Aparicio Barragán </p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Corrección de estilo:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura"> Lesvia Guadalupe Ramos Sánchez, Diana Magali Vázquez Gasca y Jesús Humberto Zúñiga Azuara </p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Edición de desarrollo:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura"> Rubí Alicia Fernández Nava </p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Revisión técnica:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura"> Ariadna Angélica Navarro Rangel y Elik Germán Troconis Martínez </p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Revisión pedagógica: </b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura"> Rubí Alicia Fernández Nava </p>
        </Grid>

        <Grid container item sm={12} justifyContent="center">
          <b className="Lectura">
            <br />
            -Evaluaciones-
            <br />
            <br />
          </b>
        </Grid>

        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Autoría de textos: </b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">
            
            Camino Aparicio Barragán, María Antonieta Duarte Romero, Carlos Alberto López Navarrete y José Eduardo Sánchez
            Vizcaíno
          </p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Autoría de reactivos de comprensión lectora: </b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura"> Alexandra Astrid Ruiz Surget y Eliana Sotomayor Martínez </p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Autoría de reactivos socioemocionales: </b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura"> Camino Aparicio Barragán</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Edición de desarrollo:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura"> Olivia Margarita Ceballos Dueñas y Luis Santiago Reza Calvillo </p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Revisión técnica: </b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Elik Germán Troconis Martínez </p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Revisión pedagógica:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Elik Germán Troconis Martínez </p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Lecturas de seguridad:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Gizella Garciarena Hugyecz y Esther Norma Minor Montiel </p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Aseguramiento de calidad (QA):</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">María Dolores Romo Montiel, Yeniza Guadalupe Sánchez Torres, Rubí Alicia Fernández Nava y Miguel Guerrero Seguro</p>
        </Grid>

        <Grid container item sm={12} justifyContent="center">
          <b className="Lectura">
            <br />
            -Video de bienvenida-
            <br />
            <br />
          </b>
        </Grid>

        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Autoría de guion:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Malineli Guerrero Martínez y Carolina Vega López </p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Edición de desarrollo: </b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Ricardo Gallardo Sánchez</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Corrección de estilo: </b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Ricardo Gallardo Sánchez</p>
        </Grid>

        <Grid container item sm={12} justifyContent="center">
          <b className="Lectura">
            <br />
            -Videos de prácticas remediales-
            <br />
            <br />
          </b>
        </Grid>

        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Autoría de guiones:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Alexandra Astrid Ruiz Surget </p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Diseño instruccional:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Viridiana Arvizu Carrillo </p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Edición de desarrollo:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Rubí Alicia Fernández Nava </p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Corrección de estilo:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">María Cristina Armenta Llamas </p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Iconografía:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Noemí González González </p>
        </Grid>

        <Grid container item sm={12} justifyContent="center">
          <b className="Lectura">
            <br />
            -Audios de prácticas guiadas-
            <br />
            <br />
          </b>
        </Grid>

        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Autoría de guiones:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Thalía Fernanda Castro Gutiérrez </p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Revisión técnica:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Raúl Fernando López Romero </p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Voz:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Malineli Guerrero Martínez </p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Edición de desarrollo:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Rubí Alicia Fernández Nava </p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Corrección de estilo:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">María Cristina Armenta Llamas </p>
        </Grid>

        <Grid container item sm={12} justifyContent="center">
          <b className="Lectura">
            <br />
            -Sugerencias didácticas-
            <br />
            <br />
          </b>
        </Grid>

        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Autoría de estrategias competencias de comprensión lectora:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Camino Aparicio Barragán</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Autoría estrategias de competencias socioemocionales:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Julieta López Olalde</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Edición de desarrollo:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Daniel Wence Partida y Ariadna Angélica Navarro Rangel</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Corrección de estilo:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Lesvia Guadalupe Ramos Sánchez y Jesús Humberto Zúñiga Azuara </p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Revisión técnica:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Malineli Guerrero Martínez y Raúl Fernando López Romero</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Revisión pedagógica:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Malineli Guerrero Martínez y Mariana Ruíz Montell</p>
        </Grid>

        <Grid container item sm={12} justifyContent="center">
          <b className="Lectura">
            <br />
            -Ilustraciones-
            <br />
            <br />
          </b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Ilustraciones de narrativa: </b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Ramón García Martínez y Daniel Olmos Luna</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Sesiones 3, 4, 5, 8, 10, 45:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Rosina Marisin Gómez Nava</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Sesión 5:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Jesús Daniel Fortiz Canales</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Sesiones 6, 39, 56:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Jorge Mendoza Guzmán</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Sesiones 7, 8, 18:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Víctor Eduardo Sandoval Ibáñez</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Sesión 9:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Jacqueline Velázquez González </p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Sesión 10,20, 26, 36, 43, 48, 52:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Iván Daniel Moreno Aguilar</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Sesiones 11, 15, 30:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">José Luis Paniagua Torres</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Sesiones 11, 20, 42:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Oliver David Flores Contreras</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Sesión 11:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Ismael Segura Posadas</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Sesiones 12, 55, 56R:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Ángel Campos Frías </p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Sesiones 13, 16, 21, 26, 39R, 44:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Marcela Gómez Ruenes</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Sesión 14:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Laura Olivia Mancilla Céspedes</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Sesiones 15, 25, 39R, 58:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Israel Emilio Ramírez Sánchez </p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Sesión 17:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Eduardo Molina</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Sesiones 19, 24, 31, 54:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Tania Edith Juárez Ceciliano </p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Sesiones: 21R, 22, 30:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Carlos Augusto Gutiérrez Mora </p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Sesiones 23, 28, 34:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Blanca Judith Cerna Hernández </p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Sesión 27, 51:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Mónica Alejandra Cahue Morales </p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Sesiones 29, 41:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Marco Antonio Enciso Álvarez</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Sesiones 32, 46, 47:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">David Octavio Yáñez Rivas </p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Sesión 33:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Ismael David Nieto Vital</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Sesión 35:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Idalia Candelas Monroy</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Sesiones 36, 38:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">José Francisco Daniel Báez</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Sesiones 37:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Vicente Martí Solar</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Sesiones 40:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Eduardo Cruz Santos</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Sesiones 49:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Isidro Antonio Reyes Esquivel </p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Sesiones 50:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Ismael Segura Posada</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Sesiones 53:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Alhelí Virginia Ochoa Espinoza</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Sesiones 57:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Mónica Herrera Quant</p>
        </Grid>

        <Grid container item justifyContent="center" sm={12}>
          <p>***</p>
        </Grid>

        <Grid container item sm={12} justifyContent="center">
          <h3 className="SubTitulo">GRADO 2</h3>
        </Grid>

        <Grid container item sm={12} justifyContent="center">
          <b className="Lectura">
            <br />
            -Textos-
            <br />
            <br />
          </b>
        </Grid>

        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Autoría de lengua materna:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Carlos Alberto López Navarrete y Alejandro Demian Marín Bello </p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Autoría de pensamiento matemático:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura"> José Eduardo Sánchez Vizcaíno </p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Autoría de ciencias humanas:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">
            Gerardo Andrés Bolívar Arévalo, Sandra Cara Camarena, Aura García Junco Moreno y Ana Victoria Gaxiola Lazcano
          </p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Autoría de ciencias naturales:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">María Antonieta Duarte Romero y Andrea Paola Torres Flores </p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Autoría de arte:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Armando Reyes Arratia </p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Autoría de ética:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Camino Aparicio Barragán, Raúl Fernando López Romero y Jorge Isaac Sevchovicius Macías </p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Corrección de estilo:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Lesvia Guadalupe Ramos Sánchez, Diana Magali Vázquez Gasca y Jesús Humberto Zúñiga Azuara </p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Edición de desarrollo:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Daniel Wence Partida</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Revisión técnica:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">
            Dolores Adriana Navarro Herrera, Ariadna Angélica Navarro Rangel, Daniel Alejandro Ramirez Hernández y Elik Germán Troconis Martínez
          </p>
        </Grid>

        <Grid container item sm={12} justifyContent="center">
          <b className="Lectura">
            <br />
            -Actividades-
            <br />
            <br />
          </b>
        </Grid>

        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Autoría de reactivos de comprensión lectora:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Eliana Sotomayor Martínez</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Autoría de reactivos socioemocionales:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">María Mayela Hernández Montes </p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Corrección de estilo:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Lesvia Guadalupe Ramos Sánchez, Diana Magali Vázquez Gasca y Jesús Humberto Zúñiga Azuara </p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Edición de desarrollo:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Daniel Wence Partida</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Revisión técnica:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Ariadna Angélica Navarro Rangel y Elik Germán Troconis Martínez </p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Revisión pedagógica:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Elik Germán Troconis Martínez </p>
        </Grid>

        <Grid container item sm={12} justifyContent="center">
          <b className="Lectura">
            <br />
            -Evaluaciones-
            <br />
            <br />
          </b>
        </Grid>

        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Autoría de textos:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">
          Camino Aparicio Barragán, María Antonieta Duarte Romero, Carlos Alberto López Navarrete y José Eduardo Sánchez Vizcaíno
          </p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Autoría de reactivos de comprensión lectora:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Alexandra Astrid Ruiz Surget</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Autoría de reactivos socioemocionales:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">María Mayela Hernández Montes </p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Edición de desarrollo:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Olivia Margarita Ceballos Dueñas y Luis Santiago Reza Calvillo </p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Revisión técnica:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Elik Germán Troconis Martínez </p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Revisión pedagógica:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Elik Germán Troconis Martínez </p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Lecturas de seguridad:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">René Isaías Acuña Sánchez y Esther Norma Minor Montiel</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Aseguramiento de calidad (QA):</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Yeniza Guadalupe Sánchez Torres, Mariana Ruiz Montell, Rubí Alicia Fernández Nava y Pamela Sánchez Zúñiga </p>
        </Grid>

        <Grid container item sm={12} justifyContent="center">
          <b className="Lectura">
            <br />
            -Video de bienvenida-
            <br />
            <br />
          </b>
        </Grid>

        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Autoría de guion:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Malineli Guerrero Martínez y Carolina Vega López</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Edición de desarrollo:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Ricardo Gallardo Sánchez</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Corrección de estilo: </b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Ricardo Gallardo Sánchez</p>
        </Grid>

        <Grid container item sm={12} justifyContent="center">
          <b className="Lectura">
            <br />
            -Videos de prácticas remediales-
            <br />
            <br />
          </b>
        </Grid>

        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Autoría de guiones:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Eliana Sotomayor Martínez</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Diseño instruccional:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Viridiana Arvizu Carrillo </p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Edición de desarrollo: </b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Rubí Alicia Fernández Nava </p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Corrección de estilo:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">María Cristina Armenta Llamas </p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Iconografía:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Noemí González González </p>
        </Grid>

        <Grid container item sm={12} justifyContent="center">
          <b className="Lectura">
            <br />
            -Audios de prácticas guiadas-
            <br />
            <br />
          </b>
        </Grid>

        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Autoría de guiones:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Thalía Fernanda Castro Gutiérrez </p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Revisión técnica:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Raúl Fernando López Romero </p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Voz: </b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Malineli Guerrero Martínez</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Edición de desarrollo:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Rubí Alicia Fernández Nava </p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Corrección de estilo:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">María Cristina Armenta Llamas </p>
        </Grid>

        <Grid container item sm={12} justifyContent="center">
          <b className="Lectura">
            <br />
            -Sugerencias didácticas-
            <br />
            <br />
          </b>
        </Grid>

        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Autoría de estrategias competencias de comprensión lectora:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Eliana Sotomayor Martínez  </p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Autoría de estrategias competencias socioemocionales:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">María Mayela Hernández Montes </p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Edición de desarrollo:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Daniel Wence Partida y Ariadna Angélica Navarro Rangel </p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Corrección de estilo:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Lesvia Guadalupe Ramos Sánchez y Jesús Humberto Zúñiga Azuara  </p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Revisión técnica:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Malineli Guerrero Martínez y Raúl Fernando López Romero </p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Revisión pedagógica:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Malineli Guerrero Martínez y Mariana Ruíz Montell </p>
        </Grid>

        <Grid container item sm={12} justifyContent="center">
          <b className="Lectura">
            <br />
            -Ilustraciones-
            <br />
            <br />
          </b>
        </Grid>

        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Ilustraciones de narrativa: </b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Ramón García Martínez y Daniel Olmos Luna</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Sesiones 3, 14, 26, 28, 34, 42, 48, 55, 56, 58:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Iván Daniel Moreno Aguilar </p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Sesiones 4, 17: </b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Jesús Enrique Gil De María y Campos </p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Sesiones 5, 39:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Jesús Daniel Fortiz Canales </p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Sesiones 6, 11: </b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Alhelí Virginia Ochoa Espinoza </p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Sesiones 7, 8, 15, 53: </b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Aleida Rocío Ocegueda Escamilla </p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Sesiones 8:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Ismael Segura Posada </p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Sesiones 9, 10, 48:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Ismael David Nieto Vital </p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Sesiones 9, 12, 14, 40: </b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Diana Stephanie Zela Vázquez </p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Sesiones 11, 56R: </b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Tania Edith Juárez Ceciliano</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Sesiones 13, 46: </b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Marcela Gómez Ruenes</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Sesiones 16, 27, 50: </b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Isidro Antonio Reyes Esquivel </p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Sesiones 17, 22, 39R: </b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Berenice Espíndola Piña</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Sesiones 18, 23, 24, 42, 47, 48:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Rosina Marisin Gómez Nava</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Sesión 19: </b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">María Isabel Gómez Guizar</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Sesiones 20, 41: </b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Blanca Judith Cerna Hernández</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Sesiones 21R, 32:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Israel Emilio Ramírez Sánchez</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Sesión 21:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Víctor Eduardo Sandoval Ibáñez</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Sesión 25:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Idalia Candelas Monroy</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Sesión 29: </b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Jacqueline Velázquez González</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Sesiones 30, 31:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Mónica Herrera Quant</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Sesión 33:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Oliver David Flores Contreras</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Sesión 35: </b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Flor de Vita Amaro Zilli</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Sesión 36:  </b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Jorge Mendoza Guzmán</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Sesión 37, 44:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Paulina Barraza Gómez</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Sesión 39R: </b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">José Luis Paniagua Torres</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Sesión 42: </b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">José Francisco Daniel Báez</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Sesiones 43, 52:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Laura Olivia Mancilla Céspedes</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Sesión 45: </b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">José Guillermo Castellanos Frías</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Sesión 49: </b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Marco Antonio Enciso Álvarez</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Sesión 51: </b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Nuri Rubín Melgarejo</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Sesión 54: </b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Carlos Augusto Gutiérrez Mora</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Sesión 57: </b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Vicente Martí Solar</p>
        </Grid>

        <Grid container item justifyContent="center" sm={12}>
          <p>***</p>
        </Grid>

        <Grid container item sm={12} justifyContent="center">
          <h3 className="SubTitulo">GRADO 3</h3>
        </Grid>

        <Grid container item sm={12} justifyContent="center">
          <b className="Lectura">
            <br />
            -Textos-
            <br />
            <br />
          </b>
        </Grid>

        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Autoría de lengua materna:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura"> Carlos Alberto López Navarrete y Alejandro Demian Marín Bello </p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Autoría de pensamiento matemático:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">José Eduardo Sánchez Vizcaíno </p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Autoría de ciencias humanas:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">
            Gerardo Andrés Bolívar Arévalo, Sandra Cara Camarena, Aura García Junco Moreno y Ana Victoria Gaxiola Lazcano
          </p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Autoría de ciencias naturales:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">María Antonieta Duarte Romero y Andrea Paola Torres Flores </p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Autoría de arte:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura"> Armando Reyes Arratia </p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Autoría de ética:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Camino Aparicio Barragán, Raúl Fernando López Romero y Jorge Isaac Sevchovicius Macías </p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Corrección de estilo:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Lesvia Guadalupe Ramos Sánchez, Diana Magali Vázquez Gasca y Jesús Humberto Zúñiga Azuara </p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Edición de desarrollo:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Araceli Jimena Hernández Blengio y Humberto Xocoyotzin Calles Guerrero </p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Revisión técnica:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">
            Dolores Adriana Navarro Herrera, Ariadna Angélica Navarro Rangel y Daniel Alejandro Ramirez Hernández 
          </p>
        </Grid>

        <Grid container item sm={12} justifyContent="center">
          <b className="Lectura">
            <br />
            -Actividades-
            <br />
            <br />
          </b>
        </Grid>

        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Autoría de reactivos de comprensión lectora:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Yeniza Guadalupe Sánchez Torres</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Autoría de reactivos socioemocionales:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Estephanie González Elizalde</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Corrección de estilo:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura"> Lesvia Guadalupe Ramos Sánchez, Diana Magali Vázquez Gasca y Jesús Humberto Zúñiga Azuara </p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Edición de desarrollo:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Gissella Moreno Mejía</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Revisión técnica:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Ariadna Angélica Navarro Rangel</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Revisión pedagógica: </b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Rubí Alicia Fernández Nava y Gissella Moreno Mejía </p>
        </Grid>

        <Grid container item sm={12} justifyContent="center">
          <b className="Lectura">
            <br />
            -Evaluaciones-
            <br />
            <br />
          </b>
        </Grid>

        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Autoría de textos: </b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">
            Camino Aparicio Barragán, María Antonieta Duarte Romero, Carlos Alberto López Navarrete y José Eduardo Sánchez
            Vizcaíno
          </p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Autoría de reactivos de comprensión lectora: </b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Yeniza Guadalupe Sánchez Torres</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Autoría de reactivos socioemocionales: </b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Estephanie González Elizalde</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Edición de desarrollo:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura"> Olivia Margarita Ceballos Dueñas y Luis Santiago Reza Calvillo </p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Revisión técnica: </b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Elik Germán Troconis Martínez </p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Revisión pedagógica:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Elik Germán Troconis Martínez </p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Lecturas de seguridad:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">René Isaías Acuña Sánchez y Gizella Garciarena Hugyecz </p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Aseguramiento de calidad (QA):</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Aída Mariana Orozco Arreola, Miguel Guerrero Seguro y Mariana Ruiz Montell</p>
        </Grid>

        <Grid container item sm={12} justifyContent="center">
          <b className="Lectura">
            <br />
            -Video de bienvenida-
            <br />
            <br />
          </b>
        </Grid>

        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Autoría de guion:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Malineli Guerrero Martínez y Carolina Vega López </p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Edición de desarrollo: </b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Ricardo Gallardo Sánchez </p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Corrección de estilo: </b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Ricardo Gallardo Sánchez </p>
        </Grid>
        
        <Grid container item sm={12} justifyContent="center">
          <b className="Lectura">
            <br />
            -Videos de prácticas remediales-
            <br />
            <br />
          </b>
        </Grid>

        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Autoría de guiones:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Carlos Alberto López Navarrete y Yeniza Guadalupe Sánchez Torres</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Diseño instruccional:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Norma Tatiana López García</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Edición de desarrollo:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Rubí Alicia Fernández Nava</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Corrección de estilo:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">María Cristina Armenta Llamas</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Iconografía:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Noemí González González</p>
        </Grid>

        <Grid container item sm={12} justifyContent="center">
          <b className="Lectura">
            <br />
            -Audios de prácticas guiadas-
            <br />
            <br />
          </b>
        </Grid>

        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Autoría de guiones:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Thalía Fernanda Castro Gutiérrez </p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Revisión técnica:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Raúl Fernando López Romero </p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Voz:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Malineli Guerrero Martínez </p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Edición de desarrollo:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Rubí Alicia Fernández Nava </p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Corrección de estilo:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">María Cristina Armenta Llamas </p>
        </Grid>

        <Grid container item sm={12} justifyContent="center">
          <b className="Lectura">
            <br />
            -Sugerencias didácticas-
            <br />
            <br />
          </b>
        </Grid>

        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Autoría de estrategias competencias de comprensión lectora:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Carlos Alberto López Navarrete</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Autoría de estrategias competencias socioemocionales:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Estephanie González Elizalde</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Edición de desarrollo:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Clemens Paul Nava Kopp y Elik Germán Troconis Martínez</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Corrección de estilo:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Lesvia Guadalupe Ramos Sánchez y Jesús Humberto Zúñiga Azuara </p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Revisión técnica:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Malineli Guerrero Martínez y Raúl Fernando López Romero</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Revisión pedagógica:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Malineli Guerrero Martínez y Mariana Ruíz Montell</p>
        </Grid>

        <Grid container item sm={12} justifyContent="center">
          <b className="Lectura">
            <br />
            -Ilustraciones-
            <br />
            <br />
          </b>
        </Grid>

        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Ilustraciones de narrativa: </b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Ramón García Martínez y Daniel Olmos Luna</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Sesiones 3, 44, 56R: </b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Jesús Enrique Gil De María y Campos</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Sesiones 4, 16, 21R: </b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Carlos Augusto Gutiérrez Mora</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Sesión 5: </b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Clemens Paul Nava Kopp</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Sesiones 6, 18, 27, 28, 41, 42: </b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">David Octavio Yáñez Rivas</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Sesiones 7, 19, 35: </b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Ismael Segura Posada</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Sesiones 8, 10, 14, 34, 52, 55, 58:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Iván Daniel Moreno Aguilar</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Sesión 9: </b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Blanca Judith Cerna Hernández</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Sesión 11: </b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Carlos Alberto Banda de la Concha</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Sesiones 12, 22, 39R, 45: </b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Oliver David Flores Contreras</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Sesiones 13:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">José Guillermo Castellanos Frías</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Sesiones 15, 30, 43: </b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Vicente Martí Solar</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Sesiones 17, 29, 37: </b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Eduardo Molina</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Sesiones 19, 51: </b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Jacqueline Velázquez González</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Sesión 20: </b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Rosina Marisin Gómez Nava</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Sesión 21: </b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Ángel Campos Frías</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Sesiones 23, 56: </b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Alhelí Virginia Ochoa Espinoza</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Sesiones 24, 20: </b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">José Francisco Daniel Báez</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Sesiones 25, 26, 49: </b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Idalia Candelas Monroy</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Sesión 31: </b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Mónica Alejandra Cahue Morales</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Sesiones 32:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Aleida Rocío Ocegueda Escamilla</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Sesiones 33, 36: </b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Marcela Gómez Ruenes</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Sesiones 38, 48: </b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Flor de Vita Amaro Zilli</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Sesiones 39, 54: </b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Mónica Herrera Quant</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Sesión 47: </b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">María Isabel Gómez Guizar</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Sesiones 50, 57: </b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Ismael David Nieto Vital</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Sesión 53: </b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Víctor Eduardo Sandoval Ibáñez</p>
        </Grid>
      
        <Grid container item justifyContent="center" sm={12}>
          <p>***</p>
        </Grid>

        <Grid container item sm={12} justifyContent="center">
          <h3 className="SubTitulo">GRADO 4</h3>
        </Grid>

        <Grid container item sm={12} justifyContent="center">
          <b className="Lectura">
            <br />
            -Textos-
            <br />
            <br />
          </b>
        </Grid>

        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Autoría de lengua materna:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura"> Carlos Alberto López Navarrete y Alejandro Demian Marín Bello </p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Autoría de pensamiento matemático:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">José Eduardo Sánchez Vizcaíno </p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Autoría de ciencias humanas:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">
            Gerardo Andrés Bolívar Arévalo, Sandra Cara Camarena, Aura García Junco Moreno y Ana Victoria Gaxiola Lazcano
          </p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Autoría de ciencias naturales:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">María Antonieta Duarte Romero y Andrea Paola Torres Flores </p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Autoría de arte:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura"> Armando Reyes Arratia </p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Autoría de ética:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Camino Aparicio Barragán, Raúl Fernando López Romero y Jorge Isaac Sevchovicius Macías </p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Corrección de estilo:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Lesvia Guadalupe Ramos Sánchez, Diana Magali Vázquez Gasca y Jesús Humberto Zúñiga Azuara </p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Edición de desarrollo:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Clemens Paul Nava Kopp</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Revisión técnica:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">
            Dolores Adriana Navarro Herrera, Ariadna Angélica Navarro Rangel y Daniel Alejandro Ramirez Hernández 
          </p>
        </Grid>

        <Grid container item sm={12} justifyContent="center">
          <b className="Lectura">
            <br />
            -Novelas-
            <br />
            <br />
          </b>
        </Grid>

        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Autoría: </b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">
            Laura Evita Martínez-Lara
          </p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Edición de desarrollo: </b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">
            Humberto Xocoyotzin Calles Guerrero
          </p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Lectura de primeras: </b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">
            Gissella Moreno Mejía
          </p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Lectura de segundas: </b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">
            Gissella Moreno Mejía
          </p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Lectura de finas:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">
            Gissella Moreno Mejía
          </p>
        </Grid>

        <Grid container item sm={12} justifyContent="center">
          <b className="Lectura">
            <br />
            -Actividades-
            <br />
            <br />
          </b>
        </Grid>

        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Autoría de reactivos de comprensión lectora:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Adriana González Méndez e Iliana Sahagún Angulo</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Autoría de reactivos socioemocionales:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">María del Rocío Verónica Castrejón Román</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Corrección de estilo:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Lesvia Guadalupe Ramos Sánchez, Diana Magali Vázquez Gasca y Jesús Humberto Zúñiga Azuara</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Edición de desarrollo:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Clemens Paul Nava Kopp</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Revisión técnica:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Ariadna Angélica Navarro Rangel</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Revisión pedagógica: </b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Ariadna Angélica Navarro Rangel </p>
        </Grid>

        <Grid container item sm={12} justifyContent="center">
          <b className="Lectura">
            <br />
            -Evaluaciones-
            <br />
            <br />
          </b>
        </Grid>

        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Autoría de textos: </b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">
            Camino Aparicio Barragán, María Antonieta Duarte Romero, Carlos Alberto López Navarrete y José Eduardo Sánchez
            Vizcaíno
          </p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Autoría de reactivos de comprensión lectora: </b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Maribel Ariadna De la Cruz Hernández</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Autoría de reactivos socioemocionales: </b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">María del Rocío Verónica Castrejón Román</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Edición de desarrollo:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura"> Olivia Margarita Ceballos Dueñas y Luis Santiago Reza Calvillo </p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Revisión técnica: </b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Elik Germán Troconis Martínez </p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Revisión pedagógica:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Elik Germán Troconis Martínez </p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Lecturas de seguridad:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Gizella Garciarena Hugyecz  y Esther Norma Minor Montiel</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Aseguramiento de calidad (QA):</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Pamela Sánchez Zúñiga, Miguel Guerrero Seguro y Jesús Ángel Flores Rodríguez</p>
        </Grid>

        <Grid container item sm={12} justifyContent="center">
          <b className="Lectura">
            <br />
            -Video de bienvenida-
            <br />
            <br />
          </b>
        </Grid>

        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Autoría de guion:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Malineli Guerrero Martínez y Carolina Vega López </p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Edición de desarrollo: </b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Ricardo Gallardo Sánchez </p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Corrección de estilo: </b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Ricardo Gallardo Sánchez </p>
        </Grid>
        
        <Grid container item sm={12} justifyContent="center">
          <b className="Lectura">
            <br />
            -Videos de prácticas remediales-
            <br />
            <br />
          </b>
        </Grid>

        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Autoría de guiones:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Carlos Alberto López Navarrete y Yeniza Guadalupe Sánchez Torres</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Diseño instruccional:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Norma Tatiana López García</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Edición de desarrollo:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Rubí Alicia Fernández Nava</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Corrección de estilo:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">María Cristina Armenta Llamas</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Iconografía:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Noemí González González</p>
        </Grid>

        <Grid container item sm={12} justifyContent="center">
          <b className="Lectura">
            <br />
            -Audios de prácticas guiadas-
            <br />
            <br />
          </b>
        </Grid>

        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Autoría de guiones:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Thalía Fernanda Castro Gutiérrez </p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Revisión técnica:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Raúl Fernando López Romero </p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Voz:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Raúl Fernando López Romero</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Edición de desarrollo:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Rubí Alicia Fernández Nava </p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Corrección de estilo:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">María Cristina Armenta Llamas </p>
        </Grid>

        <Grid container item sm={12} justifyContent="center">
          <b className="Lectura">
            <br />
            -Sugerencias didácticas-
            <br />
            <br />
          </b>
        </Grid>

        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Autoría de estrategias competencias de comprensión lectora:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Iliana Sahagún Angulo</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Autoría de estrategias competencias socioemocionales:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">María del Rocío Verónica Castrejón Román</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Edición de desarrollo:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Clemens Paul Nava Kopp y Elik Germán Troconis Martínez</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Corrección de estilo:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Lesvia Guadalupe Ramos Sánchez y Jesús Humberto Zúñiga Azuara </p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Revisión técnica:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Malineli Guerrero Martínez y Raúl Fernando López Romero</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Revisión pedagógica:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Malineli Guerrero Martínez y Mariana Ruíz Montell</p>
        </Grid>

        <Grid container item sm={12} justifyContent="center">
          <b className="Lectura">
            <br />
            -Ilustraciones-
            <br />
            <br />
          </b>
        </Grid>

        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Ilustraciones de narrativa: </b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Ramón García Martínez y Daniel Olmos Luna</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Sesiones 3, 7, 8, 9, 10, 26, 32, 34, 37, 45, 52, 55, 58: </b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Iván Daniel Moreno Aguilar</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Sesiones 4, 32, 39R, 44, 50: </b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Jesús Enrique Gil De María y Campos</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Sesión 5: </b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Clemens Paul Nava Kopp</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Sesión 6: </b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Flor de Vita Amaro Zilli</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Sesión 7: </b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Berenice Espíndola Piña</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Sesiones 9, 47, 53: </b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Jesús Daniel Fortiz Canales</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Sesiones 10, 56R: </b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Marcela Gómez Ruenes</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Sesiones 11, 35: </b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Vicente Martí Solar</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Sesión 13: </b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">María Isabel Gómez Guizar</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Sesiones 14, 43: </b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Laura Olivia Mancilla Céspedes</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Sesiones 15: </b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">José Francisco Daniel Báez</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Sesiones 16: </b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Carlos Alberto Banda de la Concha</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Sesiones 17: </b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Blanca Judith Cerna Hernández</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Sesiones 18, 42, 56: </b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Eduardo Cruz Santos</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Sesiones 19, 48, 51, 57:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Gerardo Suzán Prone</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Sesión 20: </b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">David Octavio Yáñez Rivas</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Sesiones 21R, 23, 25, 27, 29, 31, 36: </b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Diana Stephanie Zela Vázquez</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Sesiones 21, 41: </b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Eduardo Molina</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Sesión 22: </b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">José Luis Paniagua Torress</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Sesiones 24, 52: </b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Israel Emilio Ramírez Sánchez</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Sesiones 26, 54: </b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Alhelí Virginia Ochoa Espinoza</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Sesión 26: </b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Ismael Segura Posadas</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Sesiones 28, 58: </b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Ángel Campos Frías</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Sesión 30: </b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Oliver David Flores Contreras</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Sesión 33: </b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Aleida Rocío Ocegueda Escamilla</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Sesión 38: </b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Berenice Espíndola Piña</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Sesiones 39, 40: </b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Idalia Candelas Monroy</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Sesión 45: </b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Aleida Rocío Ocegueda Escamilla</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Sesión 46: </b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Rosina Marisin Gómez Nava</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Sesión 49: </b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Nuri Rubín Melgarejo</p>
        </Grid>
      
        <Grid container item justifyContent="center" sm={12}>
          <p>***</p>
        </Grid>

        <Grid container item sm={12} justifyContent="center">
          <h3 className="SubTitulo">GRADO 5</h3>
        </Grid>

        <Grid container item sm={12} justifyContent="center">
          <b className="Lectura">
            <br />
            -Textos-
            <br />
            <br />
          </b>
        </Grid>

        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Autoría de lengua materna:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura"> Carlos Alberto López Navarrete y Alejandro Demian Marín Bello </p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Autoría de pensamiento matemático:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">José Eduardo Sánchez Vizcaíno </p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Autoría de ciencias humanas:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">
            Gerardo Andrés Bolívar Arévalo, Sandra Cara Camarena, Aura García Junco Moreno y Ana Victoria Gaxiola Lazcano
          </p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Autoría de ciencias naturales:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">María Antonieta Duarte Romero y Andrea Paola Torres Flores </p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Autoría de arte:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura"> Armando Reyes Arratia </p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Autoría de ética:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Camino Aparicio Barragán, Raúl Fernando López Romero y Jorge Isaac Sevchovicius Macías </p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Corrección de estilo:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Lesvia Guadalupe Ramos Sánchez, Diana Magali Vázquez Gasca y Jesús Humberto Zúñiga Azuara </p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Edición de desarrollo:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Luis Santiago Reza Calvillo</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Revisión técnica:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">
            Dolores Adriana Navarro Herrera, Ariadna Angélica Navarro Rangel y Daniel Alejandro Ramirez Hernández  
          </p>
        </Grid>

        <Grid container item sm={12} justifyContent="center">
          <b className="Lectura">
            <br />
            -Novelas-
            <br />
            <br />
          </b>
        </Grid>

        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Autoría: </b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">
            Alejandra Castro Romero
          </p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Edición de desarrollo: </b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">
            Elik Germán Troconis Martínez
          </p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Lectura de primeras: </b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">
            Ariadna Angélica Navarro Rangel
          </p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Lectura de segundas: </b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">
            Gissella Moreno Mejía
          </p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Lectura de finas:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">
            Gissella Moreno Mejía
          </p>
        </Grid>

        <Grid container item sm={12} justifyContent="center">
          <b className="Lectura">
            <br />
            -Actividades-
            <br />
            <br />
          </b>
        </Grid>

        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Autoría de reactivos de comprensión lectora:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Iván Sierra Martínez</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Autoría de reactivos socioemocionales:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Gilberto Antonio Nava Rosales y Mariana Ruiz Montell</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Corrección de estilo:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Lesvia Guadalupe Ramos Sánchez, Diana Magali Vázquez Gasca y Jesús Humberto Zúñiga Azuara</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Edición de desarrollo:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Luis Santiago Reza Calvillo</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Revisión técnica:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Ariadna Angélica Navarro Rangel y Erika Montserrat Jasso Hernan D'Borneville</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Revisión pedagógica: </b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Erika Montserrat Jasso Hernan D'Borneville</p>
        </Grid>

        <Grid container item sm={12} justifyContent="center">
          <b className="Lectura">
            <br />
            -Evaluaciones-
            <br />
            <br />
          </b>
        </Grid>

        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Autoría de textos: </b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">
            Camino Aparicio Barragán, María Antonieta Duarte Romero, Carlos Alberto López Navarrete y José Eduardo Sánchez Vizcaíno
          </p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Autoría de reactivos de comprensión lectora: </b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Iván Sierra Martínez</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Autoría de reactivos socioemocionales: </b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Gilberto Antonio Nava Rosales</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Edición de desarrollo:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Olivia Margarita Ceballos Dueñas y Luis Santiago Reza Calvillo</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Revisión técnica: </b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Elik Germán Troconis Martínez </p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Revisión pedagógica:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Elik Germán Troconis Martínez </p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Lecturas de seguridad:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">René Isaías Acuña Sánchez y Esther Norma Minor Montiel </p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Aseguramiento de calidad (QA):</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Yeniza Guadalupe Sánchez Torres, Iovanka Nicté De Paz López, Maribel Rincón Puón, Carlos Alberto López Navarrete y Elik Germán Troconis Martínez</p>
        </Grid>

        <Grid container item sm={12} justifyContent="center">
          <b className="Lectura">
            <br />
            -Video de bienvenida-
            <br />
            <br />
          </b>
        </Grid>

        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Autoría de guion:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Malineli Guerrero Martínez y Carolina Vega López </p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Edición de desarrollo: </b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Ricardo Gallardo Sánchez </p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Corrección de estilo: </b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Ricardo Gallardo Sánchez </p>
        </Grid>
        
        <Grid container item sm={12} justifyContent="center">
          <b className="Lectura">
            <br />
            -Videos de prácticas remediales-
            <br />
            <br />
          </b>
        </Grid>

        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Autoría de guiones:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Iván Sierra Martínez</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Diseño instruccional:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Viridiana Arvizu Carrillo y Magnolia Itzel López Samperio</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Edición de desarrollo:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Rubí Alicia Fernández Nava</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Corrección de estilo:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">María Cristina Armenta Llamas</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Iconografía:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Noemí González González</p>
        </Grid>

        <Grid container item sm={12} justifyContent="center">
          <b className="Lectura">
            <br />
            -Audios de prácticas guiadas-
            <br />
            <br />
          </b>
        </Grid>

        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Autoría de guiones:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Thalía Fernanda Castro Gutiérrez </p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Revisión técnica:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Raúl Fernando López Romero </p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Voz:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Raúl Fernando López Romero</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Edición de desarrollo:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Rubí Alicia Fernández Nava </p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Corrección de estilo:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">María Cristina Armenta Llamas </p>
        </Grid>

        <Grid container item sm={12} justifyContent="center">
          <b className="Lectura">
            <br />
            -Sugerencias didácticas-
            <br />
            <br />
          </b>
        </Grid>

        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Autoría de estrategias competencias de comprensión lectora:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Iván Sierra Martínez</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Autoría de estrategias competencias socioemocionales:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Gilberto Antonio Nava Rosales</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Edición de desarrollo:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Luis Santiago Reza Calvillo y Erika Montserrat Jasso Hernan D'Borneville</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Corrección de estilo:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Lesvia Guadalupe Ramos Sánchez y Jesús Humberto Zúñiga Azuara </p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Revisión técnica:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Malineli Guerrero Martínez y Raúl Fernando López Romero</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Revisión pedagógica:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Malineli Guerrero Martínez y Mariana Ruíz Montell</p>
        </Grid>

        <Grid container item sm={12} justifyContent="center">
          <b className="Lectura">
            <br />
            -Ilustraciones-
            <br />
            <br />
          </b>
        </Grid>

        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Ilustraciones de narrativa: </b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Ramón García Martínez y Daniel Olmos Luna</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Sesiones 3: </b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">José Luis Paniagua Torres</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Sesiones 4, 14, 33, 42: </b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Ismael Segura Posadas</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Sesión 5: </b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Eduardo Molina</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Sesiones 6, 37, 51, 53: </b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Mónica Herrera Quant</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Sesiones 7, 9: </b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">David Octavio Yáñez Rivas</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Sesiones 8, 38, 47, 55, 58: </b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Iván Daniel Moreno Aguilar</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Sesiones 10, 16, 24, 38: </b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Vicente Martí Solar</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Sesión 11: </b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Isidro Antonio Reyes Esquivel</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Sesiones 12, 57: </b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Idalia Candelas Monroy</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Sesiones 13, 52: </b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Mónica Alejandra Cahue Morales</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Sesiones 15, 48: </b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">José Francisco Daniel Báez</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Sesiones 17, 34, 35: </b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Jesús Enrique Gil De María y Campos</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Sesiones 18, 55, 56R: </b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Diana Stephanie Zela Vázquez</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Sesiones 19, 32: </b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Oliver David Flores Contreras</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Sesión 20: </b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Mónica Herrera Quant</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Sesión 21R: </b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Jorge Mendoza Guzmán</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Sesiones 21, 41: </b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Tania Edith Juárez Ceciliano</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Sesión 22: </b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Berenice Espíndola Piña</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Sesiones 23, 25, 27, 29, 30, 31:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Víctor Eduardo Sandoval Ibáñez</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Sesiones 6, 44: </b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Ángel Campos Frías</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Sesiones 28, 54: </b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">María Isabel Gómez Guizar</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Sesión 36: </b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Eduardo Molina</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Sesiones 39R, 43: </b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Aleida Rocío Ocegueda Escamilla</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Sesión 39: </b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Carlos Augusto Gutiérrez Mora</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Sesión 40: </b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Paulina Barraza Gómez</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Sesión 45:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Alhelí Virginia Ochoa Espinoza</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Sesiones 49, 50:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Jesús Daniel Fortiz Canales</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Sesión 56: </b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Flor de Vita Amaro Zilli</p>
        </Grid>
              
        <Grid container item justifyContent="center" sm={12}>
          <p>***</p>
        </Grid>

        <Grid container item sm={12} justifyContent="center">
          <h3 className="SubTitulo">GRADO 6</h3>
        </Grid>

        <Grid container item sm={12} justifyContent="center">
          <b className="Lectura">
            <br />
            -Textos-
            <br />
            <br />
          </b>
        </Grid>

        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Autoría de lengua materna:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura"> Carlos Alberto López Navarrete y Alejandro Demian Marín Bello </p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Autoría de pensamiento matemático:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">José Eduardo Sánchez Vizcaíno </p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Autoría de ciencias humanas:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">
            Gerardo Andrés Bolívar Arévalo, Sandra Cara Camarena, Aura García Junco Moreno y Ana Victoria Gaxiola Lazcano
          </p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Autoría de ciencias naturales:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">María Antonieta Duarte Romero y Andrea Paola Torres Flores </p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Autoría de arte:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura"> Armando Reyes Arratia </p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Autoría de ética:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Camino Aparicio Barragán, Raúl Fernando López Romero y Jorge Isaac Sevchovicius Macías </p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Corrección de estilo:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Lesvia Guadalupe Ramos Sánchez, Diana Magali Vázquez Gasca y Jesús Humberto Zúñiga Azuara </p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Edición de desarrollo:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Ricardo Covarrubias Lonngi</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Revisión técnica:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">
            Dolores Adriana Navarro Herrera, Ariadna Angélica Navarro Rangel y Daniel Alejandro Ramirez Hernández  
          </p>
        </Grid>

        <Grid container item sm={12} justifyContent="center">
          <b className="Lectura">
            <br />
            -Novelas-
            <br />
            <br />
          </b>
        </Grid>

        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Autoría: </b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">
            Josué Elí Almanza Farias
          </p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Edición de desarrollo: </b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">
            Ariadna Angélica Navarro Rangel
          </p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Lectura de primeras: </b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">
            Elik Germán Troconis Martínez
          </p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Lectura de segundas: </b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">
            Gissella Moreno Mejía
          </p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Lectura de finas:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">
            Gissella Moreno Mejía
          </p>
        </Grid>

        <Grid container item sm={12} justifyContent="center">
          <b className="Lectura">
            <br />
            -Actividades-
            <br />
            <br />
          </b>
        </Grid>

        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Autoría de reactivos de comprensión lectora:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Maribel Ariadna De la Cruz Hernández y Alexandra Astrid Ruiz Surget</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Autoría de reactivos socioemocionales:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">José Jakousi Castañeda Vázquez y Raúl Fernando López Romero</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Corrección de estilo:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Lesvia Guadalupe Ramos Sánchez, Diana Magali Vázquez Gasca y Jesús Humberto Zúñiga Azuara </p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Edición de desarrollo:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Ricardo Covarrubias Lonngi</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Revisión técnica:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Ariadna Angélica Navarro Rangel y Erika Montserrat Jasso Hernan D'Borneville</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Revisión pedagógica: </b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Erika Montserrat Jasso Hernan D'Borneville</p>
        </Grid>

        <Grid container item sm={12} justifyContent="center">
          <b className="Lectura">
            <br />
            -Evaluaciones-
            <br />
            <br />
          </b>
        </Grid>

        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Autoría de textos: </b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">
            Camino Aparicio Barragán, María Antonieta Duarte Romero, Carlos Alberto López Navarrete y José Eduardo Sánchez Vizcaíno
          </p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Autoría de reactivos de comprensión lectora: </b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Maribel Ariadna De la Cruz Hernández</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Autoría de reactivos socioemocionales: </b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Camino Aparicio Barragán</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Edición de desarrollo:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Olivia Margarita Ceballos Dueñas y Luis Santiago Reza Calvillo</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Revisión técnica: </b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Elik Germán Troconis Martínez </p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Revisión pedagógica:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Elik Germán Troconis Martínez </p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Lecturas de seguridad:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">René Isaías Acuña Sánchez y Esther Norma Minor Montiel </p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Aseguramiento de calidad (QA):</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Iovanka Nicté De Paz López, Maribel Rincón Puón, Carlos Alberto López Navarrete y Elik Germán Troconis Martínez</p>
        </Grid>

        <Grid container item sm={12} justifyContent="center">
          <b className="Lectura">
            <br />
            -Video de bienvenida-
            <br />
            <br />
          </b>
        </Grid>

        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Autoría de guion:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Malineli Guerrero Martínez y Carolina Vega López </p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Edición de desarrollo: </b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Ricardo Gallardo Sánchez </p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Corrección de estilo: </b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Ricardo Gallardo Sánchez </p>
        </Grid>
        
        <Grid container item sm={12} justifyContent="center">
          <b className="Lectura">
            <br />
            -Videos de prácticas remediales-
            <br />
            <br />
          </b>
        </Grid>

        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Autoría de guiones:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Carlos Alberto López Navarrete</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Diseño instruccional:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Norma Tatiana López García y Magnolia Itzel López Samperio</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Edición de desarrollo:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Rubí Alicia Fernández Nava</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Corrección de estilo:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">María Cristina Armenta Llamas</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Iconografía:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Noemí González González</p>
        </Grid>

        <Grid container item sm={12} justifyContent="center">
          <b className="Lectura">
            <br />
            -Audios de prácticas guiadas-
            <br />
            <br />
          </b>
        </Grid>

        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Autoría de guiones:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Thalía Fernanda Castro Gutiérrez </p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Revisión técnica:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Raúl Fernando López Romero </p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Voz:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Raúl Fernando López Romero</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Edición de desarrollo:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Rubí Alicia Fernández Nava </p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Corrección de estilo:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">María Cristina Armenta Llamas </p>
        </Grid>

        <Grid container item sm={12} justifyContent="center">
          <b className="Lectura">
            <br />
            -Sugerencias didácticas-
            <br />
            <br />
          </b>
        </Grid>

        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Autoría de estrategias competencias de comprensión lectora:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Alexandra Astrid Ruiz Surget</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Autoría de estrategias competencias socioemocionales:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Raúl Fernando López Romero</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Edición de desarrollo:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Luis Santiago Reza Calvillo y Erika Montserrat Jasso Hernan D'Borneville</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Corrección de estilo:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Lesvia Guadalupe Ramos Sánchez y Jesús Humberto Zúñiga Azuara </p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Revisión técnica:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Malineli Guerrero Martínez</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Revisión pedagógica:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Malineli Guerrero Martínez y Mariana Ruíz Montell</p>
        </Grid>

        <Grid container item sm={12} justifyContent="center">
          <b className="Lectura">
            <br />
            -Ilustraciones-
            <br />
            <br />
          </b>
        </Grid>
        
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Ilustraciones de narrativa: </b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Ramón García Martínez y Daniel Olmos Luna</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Sesiones 3, 8, 14, 26, 34, 39R, 40, 43, 48, 54, 59: </b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Iván Daniel Moreno Aguilar</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Sesiones 4, 13, 32, 56R:</b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Carlos Augusto Gutiérrez Mora</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Sesiones 5, 46: </b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Aleida Rocío Ocegueda Escamilla</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Sesiones 6, 15, 57: </b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Paulina Barraza Gómez</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Sesiones 7, 8, 10, 12, 38, 52: </b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Rosina Marisin Gómez Nava</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Sesiones 9, 18:  </b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Vicente Martí Solar</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Sesiones 9, 41: </b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Blanca Judith Cerna Hernández</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Sesión 10: </b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Flor de Vita Amaro Zilli</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Sesión 11, 26: </b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Alhelí Virginia Ochoa Espinoza</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Sesiones 11, 30, 35, 50, 55: </b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Jesús Enrique Gil De María y Campos</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Sesiones 12, 19, 39: </b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Carlos Alberto Banda de la Concha</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Sesiones 12, 39R, 48: </b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">José Luis Paniagua Torres</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Sesiones 13, 19, 33, 36, 39:  </b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">David Octavio Yáñez Rivas</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Sesión 16: </b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Oliver David Flores Contreras</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Sesiones 5, 17, 47, 51, 58: </b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Ismael Segura Posadas</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Sesiones 20, 28:  </b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Víctor Eduardo Sandoval Ibáñez</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Sesiones 21R, 23, 25, 27, 29, 31, 39R, 44: </b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Eduardo Molina</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Sesiones 21, 37: </b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Ángel Campos Frías</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Sesión 22: </b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Isidro Antonio Reyes Esquivel</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Sesión 24: </b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Mónica Herrera Quant</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Sesión 37: </b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Jesús Daniel Fortiz Canales</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Sesiones 40, 54: </b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Israel Emilio Ramírez Sánchez</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Sesión 42: </b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">María Isabel Gómez Guizar</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Sesión 44: </b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Marcela Gómez Ruenes</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Sesión 45: </b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">José Francisco Daniel Báez</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Sesión 49: </b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Mónica Alejandra Cahue Morales</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Sesión 53: </b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Jacqueline Velázquez González</p>
        </Grid>
        <Grid container item sm={12} lg={6} className="Amarillo">
          <b className="Lectura">Sesión 56: </b>
        </Grid>
        <Grid container item sm={12} lg={6} className="Persona">
          <p className="Lectura">Diana Stephanie Zela Vázquez</p>
        </Grid>
              
        <Grid container item justifyContent="center" sm={12}>
          <p>***</p>
        </Grid>
                
      </Grid>
    </Grid>
  );
};

export default Creditos;
