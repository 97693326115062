import React               from "react";
import {Grid, makeStyles}                 from '@material-ui/core';

const useStyles = makeStyles(theme =>({
  questionContainer:{
    width: "100%",
    // height:"100%",
  },
  reading: {
    width: "80%",
    margin:"10px 0 10px 0 ",
    font: '22px lato',
    letterSpacing: '0.21px',
    [theme.breakpoints.down(1400)]:{
      font: " 18px lato",
      width: "95%",
    },
    [theme.breakpoints.down(1310)]:{
      font: "500 16px lato",

    },
    [theme.breakpoints.down(925)]:{
      font: "500 14px lato",

    },
    [theme.breakpoints.down(426)]:{
      font: "500 12px lato",
 
    }, 
  },
  imgStyle: {
    alignItems: "center",
    width:'207px',

    [theme.breakpoints.down(500)]:{
      width:'80%',

    },
  },
  table: {
    width: "100%",
    font: '22px lato',
    [theme.breakpoints.down(1400)]:{
      font: " 18px lato",
      width: "95%",
    },
    [theme.breakpoints.down(1310)]:{
      font: "500 16px lato",
 
    },
    [theme.breakpoints.down(925)]:{
      font: "500 14px lato",

    },
    [theme.breakpoints.down(426)]:{
      font: "500 12px lato",

    }, 
  },
  tdTable: {
    padding: "0 1%",
    // border: '1px solid black',
  }
}));

const Question = (props) => {
  const classes = useStyles()
  const { picture1, picture2, picture3, picture4 } = props


  return(
    <Grid container className={classes.questionContainer}>
      <p 
        // dangerouslySetInnerHTML={{__html: `${step+1}. ${pregunta.replace( '\n', '<br/><br/>')}`}}
        className={classes.reading}
      >
        5. El texto explica que la <i>aracnofobia</i> es el miedo irracional a las arañas. La palabra se compone 
        de dos partes: <i>aracno</i>, que significa “araña”, y <i>fobia</i>, “temor”. ¿Puedes identificar el resto de estas fobias?
      </p>
      <Grid container direction="column" item xs={6} justifyContent="center" alignItems="center" >
        <img alt="img" src={picture1} className={classes.imgStyle}/>
        <p>1.</p>
      </Grid>
      <Grid container direction="column" item xs={6} justifyContent="center" alignItems="center" >
        <img alt="img" src={picture2} className={classes.imgStyle}/>
        <p>2.</p>
      </Grid>
      <Grid container direction="column" item xs={6} justifyContent="center" alignItems="center" >
        <img alt="img" src={picture3} className={classes.imgStyle}/>
        <p>3.</p>
      </Grid>
      <Grid container direction="column" item xs={6} justifyContent="center" alignItems="center" >
        <img alt="img" src={picture4} className={classes.imgStyle}/>
        <p>4.</p>
      </Grid>
        <p>
        a. Vacunafobia<br/>
        b. Electrofobia<br/>
        c. Dentofobia<br/>
        d. Aerofobia<br/>
        </p>
    </Grid>
  )

}

export default Question;
