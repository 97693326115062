import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
      Contenedor:{
        background:'#E9FBF9',
        width:'100vw',
        overflowX:'clip',
        [theme.breakpoints.down(1161)]:{
            background:'none',
           overflowX:'unset',
        },
        [theme.breakpoints.down(600)]:{
            marginTop: '79.51px',
        }
    },
    ContainerInfoLectura:{
        position:'relative',
        height:'86px',
        background:'#76D4C3',
        '& .BarraInfoLectura':{
            height:'86px',
            background:`Url(https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Barras/Verde.png)`,
        },
        '& .InfoLectura':{
            position:'absolute',
            height:'86px',
            width:'219px',
            boxSizing:'border-box',
            padding:'17.2px 0',
            background:'#FAFAFA',
            borderRadius:'50px',
            boxShadow: '2px 2px 2px 2px #00000029',
            zIndex:'4',
        },
        '& .InfoLectura:before':{
            position:'absolute',
            display:'flex',
            boxSizing:'border-box',
            padding:'0 0 0 10px',
            justifyContent:'center',
            alignItems:'center',
            height:'39.88px',
            width:'39.88px',
            borderRadius:'50%',
            content:'""',
            boxShadow: '3px 0px .5px 0px #00000029',
            background:'#FAFAFA',
            left:'90px',
            top:'65px',
            transform: 'rotate(0.25turn)',
        },
        '& .InfoLectura:after':{
            content:'""',
            height:'15px',
            width:'15px',
            borderTop:'2px solid #20A698',
            borderLeft:'2px solid #20A698',
            position:'absolute',
            bottom:'-10px',
            left:'Calc(50% - 8px)',
            transform: 'rotate(0.625turn)',
        },
        '& .Sesion':{
            borderLeft:'2px solid #D8D8D8',
        },
        '& .Dato':{
            margin:'0 0 5px 0',
            textAlign:'center',
            font:'bold 17px lato',
            color:'#20A698',
        },
        '& .Caracter':{
            margin:'0',
            textAlign:'center',
            font:'bold 17px lato',
            color:'#000000',
        },
    },
    ContenedorLectura:{
        background:'#fff',
        width:'1160px',
        height:'100%',
        padding:'57px 168px 35px' ,
        [theme.breakpoints.down(1161)]:{
            width:'502px',
            padding:'60px 0 0',
        },
        [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
        '& .ContainerDerechos':{
            bottom:'80px',
            [theme.breakpoints.down(1161)]:{
                display:'none',
            }
        },
        '& .Derechos':{
            textAlign:"center",
            margin:"0px",
            font:" 15px lato",
            color:"#717476",
        },
        '& .Escritorio':{
            display:'block',
            [theme.breakpoints.down(1161)]:{
                display:'none'
            },
        },
        '& .Tablet':{
            display:'none',
            [theme.breakpoints.down(1161)]:{
                display:'block'
            },
        },
    },
    
    ContainerTitulo:{
        margin:'0 0 45px 0',
        [theme.breakpoints.down(1161)]:{
            margin:'0 0 38px 0',
        },
        '& .Titulo':{
            font:'48px fredoka one',
            letterSpacing: '0px',
            color: '#823CE2',
            margin:'0',
            textAlign:'center',
            [theme.breakpoints.down(1161)]:{
                font:'43px fredoka one',
                lineHeight:'52px',
                textAlign:'center',
            },
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            lineHeight : '30px',
            margin:'0 0 36px 0 ',
            font:'22px lato',
            color:'#000',
            letterSpacing: '0.20px',
            [theme.breakpoints.down(1161)]:{
                font:'18px lato',
                letterSpacing:'.17px',
                lineHeight:'28px',
            },
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .SubTitulo':{
            font:'bold 30px lato',
            margin:'0',
            letterSpacing:'.28px',
            lineHeight:'30px',
            color:'#158AFF',
            [theme.breakpoints.down(1161)]:{
                font:'bold 26px lato',
                letterSpacing:'.24px',
                lineHeight:'28px',
            },
            [theme.breakpoints.down(502)]:{
                font:'bold 22px lato',
                lineHeight:'24px',
            },
            [theme.breakpoints.down(350)]:{
                font:'bold 18px lato',
                lineHeight:'24px',
            },
        },
        '& .TextImg':{
            margin:'24px 0 0 0',
            font:'20px lato',
            alignSelf:'flex-start',
            color:'#717476',
            letterSpacing:'.19px',
            [theme.breakpoints.down(1161)]:{
                font:'16px lato',
                letterSpacing:'0px',
                lineHeight:'25px',
            },
            [theme.breakpoints.down(502)]:{
                font:' 11px lato',
                lineHeight :'14px',
                letterSpacing: '0.10px',
            },
        },
        '& .Text':{
            margin:'0',
            font:'18px lato',
            alignSelf:'flex-start',
            color:'#000000',
            letterSpacing:'.19px',
            [theme.breakpoints.down(1161)]:{
                font:'14px lato',
                letterSpacing:'.13px',
                lineHeight:'25px',
            },
            [theme.breakpoints.down(502)]:{
                font:'10px lato',
                letterSpacing:'.09px',
                lineHeight:'15px',
            },
        },
        '& .Paises':{
            position:'relative',
        },
        '& .Pos':{
            position:'absolute',
            bottom:'0',
        },
        '& .Imagen':{
            [theme.breakpoints.down(1161)]:{
                width:'100%',
            },
        },
        '& .Imagen2':{
            [theme.breakpoints.down(1161)]:{
                width:'85%',
            },
        },
        

    },

}));

const El_amor_no_discrimina = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
             <Grid container item justifyContent="center" className={classes.ContainerInfoLectura}>
               <Grid container className="BarraInfoLectura"></Grid>
               <Grid container item className="InfoLectura">
                    <Grid container item direction="column" xs={6} justifyContent="center">
                        <p className="Dato">Grado</p>
                        <p className="Caracter">6</p>
                    </Grid>
                    <Grid container item direction="column" xs={6} justifyContent="center" className="Sesion">
                        <p className="Dato">Sesión</p>
                        <p className="Caracter">40</p>
                    </Grid>
               </Grid>
            </Grid>

            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >
                        El amor no discrimina
                    </h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                            La asamblea legislativa de la Ciudad de México aprobó el matrimonio igualitario el 
                            21 de diciembre de 2009. ¿Qué quiere decir esto? Tradicionalmente, la ley sólo 
                            permitía que se casaran parejas de personas de diferente sexo, esto es, una mujer 
                            con un hombre. Pero el amor tiene formas diferentes en las que puede manifestarse, 
                            por lo que no exclusivamente un hombre y una mujer pueden estar enamorados, 
                            querer casarse, vivir juntos o formar una familia. <br/><br/>
                            
                            Aunque las leyes sólo permitieran la unión legal de un hombre con una mujer, eso no 
                            significaba que personas del mismo sexo no se enamoraran. El problema era que no 
                            podían manifestar libremente su amor, pues sufrían discriminación y persecución. 
                            Por ello, el hecho de que las leyes permitan uniones homosexuales contribuye a 
                            disminuir el prejuicio social. <br/><br/>
                            
                            El punto es que el matrimonio no sólo es un acto en el que se celebra una fiesta y los 
                            protagonistas se dan anillos. Éste significa beneficios y responsabilidades legales 
                            importantes para la vida cotidiana de los involucrados. Por ello es tan importante 
                            este tipo de sucesos. Debido a lo que representa, desde hace ya algunos años 
                            diferentes países y ciudades han aprobado el matrimonio igualitario o matrimonio
                            entre personas homosexuales. <br/><br/>
                            
                            <h3 className='SubTitulo'>El matrimonio igualitario en el mundo</h3> <br/>
                            
                            Cuando la Ciudad de México aprobó el matrimonio igualitario en 2009, sólo había 
                            siete países en el mundo en el que era legal ese tipo de unión. Holanda había sido el 
                            primer país del mundo en aprobarlo en 2001, seguido por Bélgica, España y Canadá. <br/><br/>
                            
                            El hecho de que todavía hubiera tan pocos países que permitieran la unión legal 
                            entre parejas de personas del mismo sexo convirtió a la Ciudad de México en uno de 
                            los lugares pioneros en la defensa de los derechos de los homosexuales. Pero ya que 
                            sólo se legalizó en la capital y no en todo el país, México todavía no se encuentra 
                            entre los países del mundo en los que los matrimonios igualitarios son legales. <br/><br/>
                            
                            En la actualidad hay alrededor de 30 países en los que ya se ha legalizado el
                            matrimonio entre personas del mismo sexo. Pero también existen todavía más de 30 
                            países en los que este tipo de unión no sólo no está permitida, sino que además es 
                            ilegal. 
                        </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <Grid container className='Paises' sm={12}>
                                <img className='Imagen ' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion40/2.png"  alt="Tarjeta" />
                                <p className='Text'>* Datos de junio de 2021. En México el matrimonio igualitario es legal en algunos estados. En Suiza el Parlamento legalizó el matrimonio igualitario en diciembre de 2020, pero su aprobación final está pendiente de referéndum</p>
                        </Grid>
                        <Grid container justifyContent='center' sm={12}>
                               <p className='TextImg'>Países que han aceptado el matrimonio igualitario.</p>
                        </Grid>
       
                    </Grid>

                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                            <h3 className='SubTitulo'>La homosexualidad</h3> <br/> 

                            Recuerda que la homosexualidad se refiere a la relación afectiva entre personas del  
                            mismo sexo, preferencia determinada por las circunstancias de vida de cada persona. 
                            Es importante reconocer la libertad de ejercerla y el compromiso de la sociedad por 
                            respetarla. <br/> <br/> 
                            
                            La homosexualidad ha existido y se ha manifestado desde hace siglos. Por ejemplo,  
                            en la antigua Grecia, hace más de dos mil años, era una práctica normal, que no sólo  
                            no estaba mal vista, sino todo lo contrario. Durante la época del Imperio romano, la  
                            homosexualidad también era algo normal entre la sociedad. <br/> <br/> 
                            
                            A pesar de las enseñanzas del pasado, con el transcurrir del tiempo y sobre todo  
                            debido a diversos motivos religiosos, se comenzó a valorar negativamente la  
                            homosexualidad. Se consideró que no era algo natural y que había que prohibirlo e,  
                            incluso, castigarlo. <br/> <br/> 
                            
                            Por concepciones como la anterior, la comunidad homosexual ha tenido que luchar  
                            paulatinamente por sus derechos civiles, entre los cuales se encuentra su seguridad.  
                            Durante muchos años también han sido maltratados físicamente porque la gente no  
                            entiende que el hecho de que las personas piensen y sientan distinto no significa que  
                            estén mal o que tengan el derecho de maltratarlas. <br/> <br/> 
                            
                            Todavía hoy en día hay lugares en los que se discrimina a las personas por este 
                            motivo y se les trata como si no fuesen individuos con los mismos derechos que los  
                            demás. Es por ello que los derechos humanos reconocen la libertad y el derecho de  
                            todas las personas a amar libre y abiertamente a quien quieran. Porque, si de amor se  
                            trata, ¿quién puede considerar que el amor es algo malo?
                        </p>
                    </Grid>
                    <Grid container  sm={12}>
                            <Grid container justifyContent='center'>
                                <img className='Imagen2'  src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion40/1.png"  alt="Tarjeta" />
                            </Grid>
                            <Grid container justifyContent='center'>
                                <p className='TextImg'>El amor es universal, sin importar quién lo experimente y por quién lo haga.</p>
                            </Grid>
                    </Grid>
                </Grid>

                <Grid container item xs={12} justifyContent="center" className="ContainerDerechos">
                    <p className="Derechos">
                        © 2022. Todos los derechos reservados, Pearson, Circuito de lectores.
                    </p>                
                </Grid>
        </Grid>
    </Grid>
    );
}
 
export default El_amor_no_discrimina;
