import { makeStyles } from "@material-ui/core";
// import ApexCharts from "apexcharts";
import React from "react";
import Chart from "react-apexcharts";
import DownloadSelect from "../pages/Docente/Reportes/descargas/DownloadSelect";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    alignContent: "center",
    flexWrap: "nowrap",
    overflowX: "auto",
    overflowY: "hidden",
    [theme.breakpoints.up("md")]: {
      justifyContent: "center",
    },
  },
  Chart: {
    maxWidth: "800px",
    height: "380px",
    opacity: "0.9",
    width: "100%",
    minWidth: "320px",
  },
}));

const Fortalezas = (props) => {
  const { skillsData, downloadData, evaluationType } = props;
  const classes = useStyles();
  const [data, setData] = React.useState([]);

  // const [file, setFile] = useState(null);
  
  const options = {
    chart: {
      type: "bar",
      id: "reactchart",
      width: "100%",
      height: 498,
      stacked: true,
      toolbar: {
        show: true,
        offsetX: 35,
        offsetY: 210,
        tools: {
          download: true,
          selection: true,
        },
        export: {
          csv: {
            show: false,
            filename: undefined,
            columnDelimiter: ',',
            headerCategory: 'category',
            headerValue: 'value',
            dateFormatter(timestamp) {
              return new Date(timestamp).toDateString()
            }
          },
          svg: {
            filename: undefined,
          },
          png: {
            filename: undefined,
          }
        },
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
      },
    },
    dataLabels: {
      enabled: false,
    },
    grid: {
      xaxis: {
        lines: {
          show: true,
        },
      },
      yaxis: {
        lines: {
          show: true,
        },
        min: 0,
        max: 100,
      },
    },
    xaxis: {
      min: 0,
      max: 100,
      logBase: 10,
      tickAmount: 10,
      tickPlacement: "on",
      categories: skillsData.map((data) => {
        return data.descripcion;
      }),
      style: {
        fontSize: "24px",
      },
      labels: {
        formatter: function (val) {
          return val + "%";
        },
      },
    },
    legend: {
      onItemClick: {
        toggleDataSeries: false,
      },
      onItemHover: {
        highlightDataSeries: false,
      },
    },
    yaxis: {
      // min: 0,
      // max: 10,
      // logBase: 10,
      tickAmount: 14,
    },
  };

  const series = [
    {
      name:  `Porcentaje de aciertos (${evaluationType})`,
      data: skillsData.map((data) => {
        return data.porcentaje;
      }),
      color: "#DBB843",
      // onHover: "#F5DD8D",
    },
    {
      name: "",
      data: [],
      color: "",
    },
  ];

  React.useEffect(() => {
    
    // const algo = skillsData?.map((data) => {
    //   return data.porcentaje;
    // });


    setData(skillsData)
  }, [skillsData]);

  // const getDataUri = () => {
  //   ApexCharts.exec("reactchart", "dataURI").then(({ imgURI, blob }) => {
  //     console.log(imgURI, "url")
  //     setDownloadImage(imgURI);
  //   });
  // };

  // useEffect(() => {
  //   getDataUri();
  //   return () => {};
  // }, [series]);

  return (
    <>
      <div className={classes.container}>
        {data && (
          <>
            <Chart
              options={options}
              series={series}
              type="bar"
              className={classes.Chart}
              height={"100%"}
            />
          </>
        )}
        <DownloadSelect
          downloadData={downloadData}
          className={classes.button}
        />
      </div>
    </>
  );
};

export default Fortalezas;
