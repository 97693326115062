import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
    ContenedorLectura:{
        width:'502px',
        [theme.breakpoints.down(502)]:{
            width:'350px',
        },
    },
    ContainerTitulo:{
        margin:'0 0 34px 0',
        '& .Titulo':{
            letterSpacing: '0px',
            color: '#0CB5D6',
            margin:'0',
            textAlign:'center',
            font:'43px fredoka one',
            lineHeight:'52px',
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        position:'relative',
        '& .Lectura':{
            color:'#000',
            font:'18px lato',
            lineHeight :'28px',
            letterSpacing: '0.17px',
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .Esp':{
            padding:'0 ',
            margin:'0 0 36px 0',
        },
        '& .Esp2':{
            margin:'36px 0 ',
        },
        '& .Esp3':{
            margin:'0 ',
        },
        '& .Esp4':{
            margin:'0 0 30px 0',
        },
        '& .Tienda':{
            position:'relative',
            right:'150px',
            alignContent:'flex-end',
            [theme.breakpoints.down(600 )]:{
                right:'234px',
            },
            [theme.breakpoints.down(502)]:{
                right:'150px',
            },
        },
        '& .Imagen':{
            width: '336px',
            height: '622px',
            [theme.breakpoints.down(502)]:{
                width: '236px',
                height: '483px',
            },
        },
        '& .Imagen2':{
            width:'100%',
        },   
      
    }
}));

const Una_regla_para_todos = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>

            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                        <h2 className="Titulo">Una regla para todos</h2>
                </Grid>
                
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container xs={10} sm={8} >
                        <p className='Lectura Esp3'>
                            <span >
                            Lupita entra a una tienda a comprar<br/> 
                            unas cosas para su casa. Mientras el<br/> 
                            encargado de la tienda busca lo que le<br/> 
                            pidió Lupita, ¡ella ve unos chocolates<br/> 
                            deliciosos en el mostrador! Lupita<br/> 
                            cuenta el dinero, pero se da cuenta de<br/> 
                            que no le alcanza para comprar uno.<br/>
                            </span>
                            <div className='Esp4'></div>
                            <span>
                            Lupita piensa: “Nadie<br/> 
                            me está viendo. Si<br/> 
                            tomara uno, nadie se<br/> 
                            daría cuenta, y si nadie<br/>
                            se da cuenta, no pasa <br/> 
                            nada, ¿cierto?”<br/> 
                            <div className='Esp4'></div>
                            ¿Alguna vez te has<br/> 
                            encontrado en una<br/> 
                            situación similar? Saber<br/> 
                            si algo está bien puede<br/> 
                            ser desafiante. Una<br/> 
                            manera fácil de saber si<br/> 
                            nuestras acciones son<br/> 
                            correctas es la “Regla<br/> 
                            para todos”.<br/>
                            </span>
                        </p>

                    </Grid>
                    <Grid container xs={2} sm={4} className='Tienda'>
                        <img className="Imagen " src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img1ro/Sesion18/1.png" alt="Tarjeta" />
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12} >
                        <p className='Lectura '>
                            Lo que tienes que hacer es preguntarte: ¿qué sucedería si todas 
                            las personas del mundo hicieran lo mismo que quiero hacer? Si 
                            te das cuenta de que con esta acción no dañas a tus amigos o al 
                            ecosistema, ¡adelante! Esto significa que es buena. Pero si 
                            alguien resulta afectado, mejor detente.
                        </p>                   
                     </Grid>
                    <Grid container justifyContent='center'>
                        <img className="Imagen2" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img1ro/Sesion18/2.png" alt="Tarjeta" />
                    </Grid>
                    <Grid container sm={12}>
                       <p className='Lectura Esp2'>
                        Cuando Lupita usó la “Regla para todos” pudo imaginar lo que sucedería si todos 
                        tomáramos lo que no es nuestro: los encargados de las tiendas se verían afectados, 
                        los dueños de las fábricas de chocolate y la misma Lupita también. Lupita decidió 
                        mejor pedirle a su mamá que le comprara un chocolate.
                       </p>                   
                    </Grid>
                </Grid>
        </Grid>
    </Grid>
    );
}
 
export default Una_regla_para_todos;
