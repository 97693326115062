import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
    ContenedorLectura:{
        width:'502px',
                  [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
    },
    ContainerTitulo:{
        margin:'0 0 34px 0',
        '& .Titulo':{
            letterSpacing: '0px',
            color: '#FC5E9E',
            margin:'0',
            textAlign:'center',
            font:'43px fredoka one',
            lineHeight:'52px',
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            margin:'0 0 36px 0',
            color:'#000',
            font:'18px lato',
            lineHeight :'28px',
            letterSpacing: '0.17px',
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .TextImg':{
            color:'#717476',
            margin:'24px 0 0 0',
            font:' 16px lato',
            lineHeight :'25px',
            letterSpacing: '0.15px',
            [theme.breakpoints.down(502)]:{
                font:' 11px lato',
                lineHeight :'14px',
                letterSpacing: '0.10px',
            },
        },
        '& .Imagen':{
            width:'100%',

        },  
      
    }
}));

const Cuentos_y_fábulas_tradición_oral = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >
                    Cuentos y fábulas: tradición oral
                    </h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                      <p className="Lectura">
                      Los humanos siempre han tenido la necesidad de compartir sus historias y 
                      sentimientos. Pero ¿cómo lo hacían las civilizaciones antiguas? Antes de que existiera 
                      la escritura, los pueblos transmitían su historia a través de la narración oral. 
                      Contaban cuentos y leyendas, recitaban poemas o entonaban canciones para relatar 
                      su historia.<br/> <br/>
                      
                      Los viejos patriarcas difundían la historia de su cultura y sus tradiciones a los más 
                      jóvenes mediante relatos milenarios y cuentos mágicos. Estos relatos trataban sobre 
                      otros mundos, sobre sus antepasados, sus héroes, sus dioses, sus miedos o sus 
                      monstruos. Eran una manera de dejar su marca en las generaciones más jóvenes.
                      </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <Grid container justifyContent="center" >
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img1ro/Sesion32/1.png" alt="Tarjeta" />
                        </Grid>
                        <Grid container justifyContent="center" >
                            <p className="TextImg">
                            Los ancianos compartían sus conocimientos a través de la narración oral.
                            </p>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                      <p className="Lectura">
                        Hoy en día, muchas lenguas originarias en el mundo no tienen un sistema de 
                        escritura y continúan contando su historia de manera oral. Así que es a través de la 
                        narración de cuentos, leyendas y fábulas que los narradores orales resguardan su 
                        tradición para la siguiente generación.<br/><br/> 
                        
                        ¿Conoces algún cuento de tradición oral? ¡Conviértete en un narrador y compártelo 
                        con tus amigos!
                      </p>
                    </Grid>
                </Grid>
        </Grid>
    </Grid>
    );
}
 
export default Cuentos_y_fábulas_tradición_oral;
