import { Checkbox, FormControlLabel, makeStyles } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({
  itemDisabled: {
    "&:has(.Mui-disabled)": {
      position: "relative",
      "&:hover": {
        // backgroundColor: "#3DD593",
        "&:before": {
          opacity: "0.9",
          zIndex: "100",
          textTransform: "none",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "10px",
          position: "absolute",
          content: (props) => `"${props.mensaje}"`,
          top: "105%",
          width: "100%",
          // maxWidth: "300px",
          fontWeight: "normal",
          color: "#fff",
          backgroundColor: "#575757",
          font: "bold 12px lato",
          padding: "10px",
          boxSizing: "border-box",
          [theme.breakpoints.up("sm")]: {
            width: "300px",
            left: "105%",
            top: "unset",
            font: "bold 14px lato",
          },
          [theme.breakpoints.up("md")]: {
            left: "105%",
            top: "unset",
            width: "500px",
            font: "bold 14px lato",
          },
        },
      },
    },
  },
  item: {
    width: "100%",
    marginRight: "unset",
    marginLeft: "unset",
    fontSize: "14px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.71",
    textTransform: "capitalize",
    letterSpacing: "0.1px",
    fontFamily: "Roboto",
    color: "#44444f",
    margin: 0,
    gap: "10px",
    "& 	.MuiTypography-body1": {
      fontSize: "20px",
    },
    [theme.breakpoints.up("sm")]: {
      width: "auto",
    },
  },
}));

const AsignarRemedialItem = ({ alumn, selectedList, setSelectedList }) => {
  const mensaje = alumn?.mensaje;
  const classes = useStyles({ mensaje });
  return (
    <>
      <FormControlLabel
        className={classes.item}
        disabled={alumn.estatus === false}
        classes={{ disabled: classes.itemDisabled }}
        control={
          <Checkbox
            checked={selectedList.includes(alumn?.id_inscripcion)}
            sx={{
              color: "#9051b1",
              "&.Mui-checked": {
                color: "#9051b1",
              },
            }}
            value={alumn?.id_inscripcion}
            onChange={() => {
              if (selectedList.includes(alumn?.id_inscripcion)) {
                setSelectedList(
                  selectedList.filter(
                    (element) => element !== alumn.id_inscripcion
                  )
                );
              } else {
                setSelectedList([...selectedList, alumn?.id_inscripcion]);
              }
            }}
          />
        }
        label={`${alumn?.nombre ? alumn.nombre : ""} ${
          alumn?.apellido_paterno ? alumn.apellido_paterno : ""
        }`}
        key={alumn?.id_usuario}
      />
    </>
  );
};

export default AsignarRemedialItem;
