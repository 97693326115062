import React from "react";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Button, makeStyles, Grid } from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  MuiDialogContainer: {
    borderRadius: "61px",
    height: "90%",
    width: "90%",
    backgroundColor: "#FEFAF1",
    border: "#707070 2px solid",
    backdropFilter: "blur(3px)",
    maxWidth: "80%",
  },
  MuiContentRoot: {
    overflowY: "auto",
    [theme.breakpoints.up("lg")]: {
      "&::-webkit-scrollbar": {
        width: "auto",
      },
      "&::-webkit-scrollbar-track": {
        backgroundColor: "#7A54BA20",
        webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#7A54BA",
        borderRadius: "5px",
      },
    },
  },
  mainTitle: {
    font: "28px fredoka one",
    margin: "0px",
    textAlign: "center",
    [theme.breakpoints.up("md")]: {
      font: "36px fredoka one",
    },
    [theme.breakpoints.up("xl")]: {
      font: "42px fredoka one",
    },
  },
  section: {
    margin: 0,
    padding: "5px 0",
    boxSizing: "border-box",
  },

  titleWithNumber: {
    font: "bold 20px lato",
    [theme.breakpoints.down("760")]: {
      font: "bold 18px lato",
      margin: "5px 0 10px 0",
    },
    [theme.breakpoints.down("321")]: {
      font: "bold 16px lato",
    },
  },
  subTitle: {
    font: "bold 20px lato",
    [theme.breakpoints.down("760")]: {
      font: "bold 18px lato",
      margin: "5px 0 10px 0",
    },
    [theme.breakpoints.down("321")]: {
      font: "bold 16px lato",
    },
  },

  textPrincipal: {
    font: "16px lato",
    [theme.breakpoints.down("760")]: {
      font: "12px lato",
    },
    [theme.breakpoints.up("1440")]: {
      font: "20px lato",
    },
  },
  link: {
    font: "inherit",
    color: "inherit",
    textDecoration: "none",
    overflow: "hidden",
    whiteSpace: "pre",
    lineBreak: "anywhere",
  },
  paragraph: {
    textAlign: "justify",
    font: "16px lato",
    [theme.breakpoints.down("760")]: {
      font: "12px lato",
    },
    [theme.breakpoints.up("1920")]: {
      font: "20px lato",
    },
  },
  paragraphContact: {
    textAlign: "justify",
    font: "12px lato",
    [theme.breakpoints.up("760")]: {
      font: "16px lato",
      display: "inline",
    },
    [theme.breakpoints.up("1920")]: {
      font: "20px lato",
    },
  },
  decoratedLink: {
    color: "#007fa3",
    overflowWrap: "anywhere",
    display: "inline-block",
    textAlign: "justify",
    font: "16px lato",
    marginLeft: "5px",
    [theme.breakpoints.down("760")]: {
      font: "12px lato",
    },
    [theme.breakpoints.up("1920")]: {
      font: "20px lato",
    },
  },
  table: {
    borderCollapse: "collapse",
    border: "1px solid black",
    textAlign: "center",
    margin: "0 auto",
    padding: "5px",
  },
  listNumber: {
    padding: "0",
    listStylePosition: "inside",
    textAlign: "justify",
  },
  listBullet: {
    padding: "0",
    listStylePosition: "inside",
    textAlign: "justify",
  },
  listDash: {
    padding: "0",
    listStylePosition: "inside",
    listStyle: "none",
    textAlign: "justify",
  },

  dash: {
    marginRight: "10px",
    textAlign: "justify",
    font: "16px lato",
    [theme.breakpoints.down("760")]: {
      font: "12px lato",
    },
    [theme.breakpoints.up("1920")]: {
      font: "20px lato",
    },
  },
  itemBullet: {
    listStylePosition: "inside",
    paddingLeft: "20px",
    font: "16px lato",
    margin: "10px",
    [theme.breakpoints.down("sm")]: {
      font: "12px lato",
      paddingLeft: "0",
    },
  },

  BotonContinuar: {
    textTransform: "none",
    height: "42px",
    width: "138px",
    background: "#5825AD",
    boxShadow: "0px 3px 6px #00000029",
    borderRadius: "61px",
    color: "#FFFFFF",
    font: "bold 20px lato ",
    "&:hover": {
      background: "#dd78fb",
    },
    [theme.breakpoints.up("sm")]: {
      height: "62px",
      width: "238px",
      font: "bold 18px lato ",
    },
  },

  textChangeRegion: {
    textAlign: "justify",
    font: "16px lato",
    color: "#505759",
    [theme.breakpoints.down("760")]: {
      font: "12px lato",
    },
    [theme.breakpoints.up("1920")]: {
      font: "20px lato",
    },
  },

  buttonChangeRegion: {
    border: "none",
    backgroundColor: "transparent",
    textDecoration: "underline",
    cursor: "pointer",
    padding: 0,
    font: "inherit",
    color: "#007fa3",
  },
}));

const TermsAndConditionsChile = (props) => {
  const {
    open,
    handleConfirm,
    changeToSelectRegion,
    accept,
    accepted,
  } = props;
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      classes={{ paper: classes.MuiDialogContainer }}
      disableEscapeKeyDown
    >
      <DialogTitle style={{ textAlign: "center" }}>
        <span className={classes.mainTitle}>
          Política de Privacidad
          <br />
          Chile
        </span>
      </DialogTitle>
      <DialogContent classes={{ root: classes.MuiContentRoot }}>
        <p className={classes.textChangeRegion}>
          Si eres de otro país lee los términos y condiciones{" "}
          <button
            onClick={changeToSelectRegion}
            className={classes.buttonChangeRegion}
          >
            aquí
          </button>
        </p>{" "}
        <section className={classes.section}>
          <p className={classes.paragraph}>Fecha: 24 de febrero de 2020</p>
          <p className={classes.paragraph}>
            La presente política de privacidad (en adelante, la “Política de
            Privacidad”) describe el tipo de datos de carácter personal que
            Pearson Educación de Chile Limitada (en adelante, “Pearson”) recaba
            a través de la plataforma
            <a
              href="https://cdl-pearson.com/"
              target="_blank"
              rel="noreferrer"
              className={classes.decoratedLink}
            >
              www.cdl-pearson.com
            </a>
            (en adelante, la “Plataforma”), cómo protegemos su información, a
            quién se la comunicamos, para qué la utilizamos, así como los
            derechos que ponemos a disposición de los usuarios de nuestra
            Plataforma, entre otros temas de interés referidos a la privacidad.
          </p>
          <p className={classes.paragraph}>
            Dado el carácter de nuestras actividades, en ocasiones necesitamos
            ciertos datos personales de nuestros clientes y usuarios de la
            Plataforma. Por tal motivo, también describimos en la presente
            Política de Privacidad las medidas implementadas para proteger la
            seguridad de los datos personales recabados y la forma en que los
            usuarios pueden contactarse con nosotros para informarse sobre
            nuestras prácticas en materia de privacidad. La entidad responsable
            del tratamiento de los datos personales es Pearson Educación de
            Chile Limitada.
          </p>
          <p className={classes.paragraph}>
            Los datos personales que los usuarios comparten con nosotros, junto
            con la información relativa a la manera en la que usan nuestro
            Sitios Web (lo que buscan y las preferencias que escogen, por
            ejemplo) nos ayudan a entender mejor a nuestros clientes tanto a
            nivel grupal como individual. A su vez, ello nos ayuda a brindar una
            gama de productos y servicios que satisfagan las necesidades y
            preferencias de cada usuario.
          </p>
          <p className={classes.paragraph}>
            En conformidad al artículo 4° de la Ley N° 19.628 sobre Protección
            de la Vida Privada (en adelante, la “Ley N°19.628”), el usuario de
            la Plataforma consiente expresa, informada, voluntaria e
            inequívocamente la inclusión de todos sus datos personales
            facilitados a Pearson en las bases de datos de propiedad y
            responsabilidad de Pearson, así como al tratamiento de todos sus
            datos personales por medios automatizados y no automatizados para
            los fines descritos en los siguientes apartados.
          </p>
          <p className={classes.paragraph}>
            Asimismo, al aceptar la presente Política de Privacidad confirma que
            la ha leído, entendido y que autoriza el tratamiento de sus datos
            personales en conformidad a la misma.
          </p>
        </section>
        <section className={classes.section}>
          <h3 className={classes.subTitle}>Cómo protegemos sus datos</h3>
          <p className={classes.paragraph}>
            Su privacidad es de suma importancia para nosotros. Nos
            comprometemos a proteger todos los datos personales que nos haya
            proporcionado y a cumplir con toda la legislación aplicable en
            materia de protección de datos vigente, en particular con la Ley N°
            19.628.
          </p>
          <p className={classes.paragraph}>Ello significa que: </p>

          <p className={classes.paragraph}>
            Asumimos plena responsabilidad respecto a su información que se
            encuentra en nuestra posesión;
          </p>

          <p className={classes.paragraph}>
            Protegeremos su privacidad en todo momento;
          </p>

          <p className={classes.paragraph}>
            No comercializaremos sus datos personales.
          </p>
          <p className={classes.paragraph}>
            Ya sea que nos haya proporcionado sus datos personales en línea, por
            teléfono o mensaje de texto, por correo electrónico o por carta,
            nunca los usaremos con una finalidad distinta a las indicadas en la
            presente Política de Privacidad. En Pearson, sabemos que es
            importante cómo se utilizan sus datos personales y le agradecemos
            que confíe en el cuidado y la sensatez con la que lo hacemos. Esta
            Política de Privacidad está diseñada para ayudarle a entender cómo
            nosotros y otras empresas de nuestro grupo recabamos y utilizamos
            sus datos personales. Queremos que tome decisiones fundamentadas al
            usar nuestra Plataforma y cualquiera de sus funciones, incluido todo
            servicio de registro y comercio electrónico.
          </p>
          <p className={classes.paragraph}>
            Esta Política de Privacidad se aplica a todas las páginas de nuestra
            Plataforma con enlace a esta Política de Privacidad y a todas sus
            funciones, al comercio electrónico, las compras u otras secciones
            que requieran inscripción en nuestra Plataforma. No obstante, no se
            aplicará a ninguna página Web de terceros que esté vinculada a
            nuestro Plataforma. No asumimos ninguna responsabilidad sobre el
            contenido de los sitios Web distintos al nuestro, así como tampoco
            respecto del uso que se lleve a cabo de tales sitios Web ni de las
            prácticas de privacidad de los mismos. Por tal motivo, le
            recomendamos que lea la política de privacidad de los sitios web que
            visite.
          </p>
        </section>
        <section className={classes.section}>
          <h3 className={classes.subTitle}>
            ¿Qué datos personales recaba Pearson sobre mí?
          </h3>
          <p className={classes.paragraph}>
            A través de nuestra Plataforma recabamos determinados datos,
            incluidos datos de carácter personal. La información de carácter
            personal es información que puede identificarlo a usted. Los datos
            personales que recabamos pueden incluir su nombre, información de
            contacto (números de teléfono, direcciones de correo electrónico y
            otras direcciones), su fecha de nacimiento, trabajo, información
            sobre cursos, sus intereses personales, información de tarjetas de
            crédito o facturación, selecciones de compra y datos sobre las
            páginas que visita y otra actividad que realiza en nuestro
            Plataforma, así como cualquier otro dato personal sobre usted que
            nos proporcione.
          </p>
          <p className={classes.paragraph}>
            Algunas páginas de nuestro Plataforma también recaban otros datos
            personales que usted nos proporciona, como información relativa a su
            educación, sus capacidades o experiencia laboral, cuando sea
            pertinente en el contexto de la Plataforma o de los servicios
            prestados a través de la Plataforma. Salvo cuando se indique lo
            contrario en la Plataforma, cuando nos proporcione dichos datos
            personales solo los trataremos para los fines para los cuales nos
            los remitió. Usted es responsable de la exactitud de la información
            personal que usted proporciona a Pearson y será responsable de las
            consecuencias de proporcionar información falsa o inexacta.
          </p>
        </section>
        <section className={classes.section}>
          <h3 className={classes.subTitle}>
            ¿Cómo hace Pearson para recabar mis datos personales?
          </h3>
          <p className={classes.paragraph}>
            Recabamos sus datos personales de dos maneras:
          </p>

          <p className={classes.paragraph}>
            Datos personales que Usted nos brinda directamente. Puede, por
            ejemplo, darnos información al publicar algo en nuestra Plataforma,
            al registrarse para alguna función de nuestro sitio que requiere
            inscripción, al ponerse en contacto con nosotros (en línea o fuera
            de línea) o cuando realiza pedidos para obtener nuestros productos o
            servicios.
          </p>
          <p className={classes.paragraph}>
            Datos personales que recabamos de forma automática cuando interactúa
            con nuestra Plataforma o al abrir nuestras comunicaciones por correo
            electrónico. Usamos cookies (un pequeño fragmento de código
            informático que almacenamos durante un período de tiempo en su
            ordenador o dispositivo móvil) y otras tecnologías similares para
            recoger esta información. Para más información, visite nuestra
            página de{" "}
            <a
              href="https://www.pearson.com/corporate/cookies.html"
              target="_blank"
              rel="noreferrer"
              className={classes.decoratedLink}
            >
              Política sobre cookies.
            </a>
          </p>
          <p className={classes.paragraph}>
            Asimismo, para poder utilizar todas las funcionalidades disponibles
            de la Plataforma usted deberá crear una cuenta, a cuyo fin deberá
            proporcionar los siguientes datos: nombre completo, documento de
            identidad, email, teléfono, dirección, ciudad, país, institución a
            donde pertenece.
          </p>
          <p className={classes.paragraph}>
            A través de nuestra Plataforma podemos además usar servicios de
            análisis de actividad online provistos por terceros. Los proveedores
            de servicios de análisis de actividad en la Plataforma utilizan
            tecnologías tales como cookies, web beacons, registros de servidores
            de red y balizas web que nos ayudan a analizar el uso que los
            usuarios hacen de nuestra Plataforma. La información recogida por
            estos medios incluye su dirección IP, su tipo de navegador, datos de
            la Plataforma y la aplicación o el mensaje de correo electrónico en
            el que estaba antes de ingresar a nuestra Plataforma. Esta
            información se utiliza con los siguientes fines:
          </p>
          <p className={classes.paragraph}>
            averiguar cuántas veces se visitó nuestro sitio;
          </p>
          <p className={classes.paragraph}>
            registrar qué secciones de nuestro sitio fueron visitadas a fin de
            mejorar el contenido y la disposición del sitio;
          </p>
          <p className={classes.paragraph}>
            comprender la eficacia de nuestros mensajes de correo electrónico.
          </p>
          <p className={classes.paragraph}>
            La información recogida por estos medios puede ponerse en
            conocimiento de los proveedores de tales servicios, así como de
            otros terceros que vayan a hacer un uso de la información recabada
            para, entre otros fines, evaluar el uso de la Plataforma. En estos
            casos, se aplicarán procedimientos de disociación a los efectos de
            proteger los datos personales de los usuarios.
          </p>
        </section>
        <section className={classes.section}>
          <h3 className={classes.subTitle}>
            ¿Cómo hace Pearson para recabar mis datos personales?
          </h3>
          <p className={classes.paragraph}>
            Nosotros y las empresas de nuestro grupo utilizaremos sus datos
            personales para los siguientes fines:
          </p>
          <p className={classes.paragraph}>
            para proporcionarle los servicios pertinentes a través de nuestra
            Plataforma;
          </p>
          <p className={classes.paragraph}>
            para procesar sus compras en línea;
          </p>
          <p className={classes.paragraph}>
            para informarle y proporcionarle más información sobre productos y
            servicios que nosotros y las empresas de nuestro grupo ofrecemos;
          </p>
          <p className={classes.paragraph}>
            para invitarle a participar en encuestas, debates y sorteos de
            premios y pedirle una opinión sobre nuestros productos y/o servicios
            mediante encuestas en línea y foros de debate;
          </p>
          <p className={classes.paragraph}>
            para mejorar los productos y servicios que le ofrecemos;
          </p>
          <p className={classes.paragraph}>
            con fines estadísticos o de análisis;
          </p>
          <p className={classes.paragraph}>
            para detectar, prevenir o investigar violaciones de la seguridad o
            fraude;
          </p>
          <p className={classes.paragraph}>
            para satisfacer mejor las necesidades y preferencias de nuestros
            clientes.
          </p>
          <p className={classes.paragraph}>
            En ningún momento permitiremos que terceros utilicen sus datos
            personales con fines de comercialización sin su consentimiento.
            También usamos datos personales para identificar los tipos de
            usuarios, auditar cómo se utiliza nuestra Plataforma y contribuir al
            desarrollo estratégico de los mismos.
          </p>
          <p className={classes.paragraph}>
            La participación en las encuestas y en los foros de debate es
            totalmente voluntaria. Usted tiene la posibilidad de cancelar la
            suscripción en cualquier momento para no ser contactado con esa
            finalidad. La información de las encuestas se utilizará en estudios
            de mercado a fin de mejorar nuestros servicios.
          </p>
          <p className={classes.paragraph}>
            Asimismo, mostraremos su nombre de usuario en todo contenido que
            presente o publique en nuestra Plataforma (incluidos los debates,
            los tablones de anuncios y los foros).
          </p>
          <p className={classes.paragraph}>
            Para el caso de que utilicemos sus datos personales de forma
            distinta a la descrita anteriormente solicitaremos su aceptación de
            forma expresa en el momento de recolectarlos.
          </p>
        </section>
        <section className={classes.section}>
          <h3 className={classes.subTitle}>
            ¿Pearson compartirá mis datos personales con alguien más?
          </h3>
          <p className={classes.paragraph}>
            Los datos personales de los usuarios que recabamos a través de la
            Plataforma podrán revelarse a terceros únicamente de acuerdo con lo
            descrito en la presente Política de Privacidad. Los datos personales
            recolectados a través de la Plataforma podrán ser comunicados para
            los fines anteriormente descritos a:
          </p>
          <p className={classes.paragraph}>las empresas de nuestro grupo;</p>
          <p className={classes.paragraph}>
            toda entidad que adquiera alguna parte de nuestras actividades y/o
            parte o la totalidad del paquete accionario de nuestra empresa;
          </p>
          <p className={classes.paragraph}>
            nuestros proveedores de servicios (incluidos, por ejemplo, los
            organismos de referencia de crédito y los procesadores de pagos, los
            distribuidores/agentes de nuestros productos, los proveedores que
            desarrollan o alojan nuestros sitios);
          </p>
          <p className={classes.paragraph}>
            motores de búsqueda de terceros que pueden indexar páginas de
            perfiles de usuarios y nombres de usuarios como parte del URL de la
            página de perfil de usuario por defecto o si la legislación lo exige
            o lo permite;
          </p>
          <p className={classes.paragraph}>
            terceros que tengan una necesidad o un interés en acceder a los
            mismos (como su empleador, su escuela, su proveedor de servicios de
            internet, los servicios de seguridad) si creemos razonablemente que
            puede estar violando la ley (por ejemplo, si ha publicado contenido
            difamatorio en nuestros sitios) o si debemos hacerlo de conformidad
            con la legislación aplicable;{" "}
          </p>
          <p className={classes.paragraph}>
            autoridades gubernamentales correspondientes;
          </p>
          <p className={classes.paragraph}>
            otros terceros con su consentimiento.
          </p>

          <p className={classes.paragraph}>
            No autorizaremos a los proveedores de servicios a revelar o divulgar
            los datos personales de los usuarios salvo para aquellos usos que
            sean estrictamente necesarios para la prestación de los servicios
            contratados o para dar cumplimiento a obligaciones legales.
          </p>
          <p className={classes.paragraph}>
            Pearson comprende la importancia de mantener la confidencialidad de
            todos los datos que pudieran ser recolectados. En consecuencia,
            Pearson se compromete, con el fin de garantizar dicha
            confidencialidad, a suscribir convenios de confidencialidad con cada
            una de las partes que lleven a cabo un tratamiento de datos
            personales. Pearson y quienes intervengan en cualquier fase del
            tratamiento de datos personales están obligados al secreto
            profesional respecto de los mismos.
          </p>
        </section>
        <section className={classes.section}>
          <h3 className={classes.subTitle}>
            Marketing por correo electrónico y exclusión voluntaria
          </h3>
          <p className={classes.paragraph}>
            No le enviaremos mensajes de correo electrónico con fines de
            marketing si ha optado por no recibirlos. Todo correo electrónico de
            marketing que le enviemos incluirá un enlace de cancelación de la
            suscripción al final del mensaje.
          </p>
          <p className={classes.paragraph}>
            Algunos Sitios Web de Pearson también proporcionan información de
            contacto que puede utilizar si desea contactarnos por correo
            electrónico, correo postal o teléfono a fin de cancelar la
            suscripción. Asimismo, algunos sitios de Pearson tienen una página
            llamada “Mi cuenta” en la que puede escoger si desea o no recibir
            mensajes de marketing de nuestra parte.
          </p>
        </section>
        <section className={classes.section}>
          <h3 className={classes.subTitle}>
            ¿Cómo protege Pearson mis datos personales?
          </h3>
          <p className={classes.paragraph}>
            Tratamos de proteger la seguridad de todos sus datos personales
            mediante la implementación de técnicas y medidas organizativas
            adecuadas. Por ejemplo, siempre que su navegador acepte cifrado
            HTTPS (Protocolo Seguro de Transferencia de Hipertexto),
            utilizaremos esta tecnología para tratar de proteger sus datos
            personales de pago contra el acceso no autorizado a través de un
            servidor seguro.
          </p>
          <p className={classes.paragraph}>
            Así, para prevenir la destrucción, pérdida, acceso, alteración,
            comunicación o uso accidental, ilegal o no autorizado Pearson ha
            implementado ciertos medios físicos, electrónicos, administrativos y
            procedimientos de seguridad. Nosotros resguardamos los datos de
            carácter personal de acuerdo a estándares y procedimientos de
            seguridad establecidos y continuamente evaluamos nueva tecnología
            para proteger esa información. Pearson garantiza que los procesos
            internos propios de las bases de datos cumplen con las obligaciones
            legales de seguridad y confidencialidad impuestas por la Ley N°
            19.628.
          </p>
          <p className={classes.paragraph}>
            Sin embargo, usted reconoce que los medios técnicos existentes que
            brindan seguridad no son infalibles y que aun cuando se adopten
            todos los recaudos razonables de seguridad es posible sufrir
            manipulaciones, destrucción y/o pérdida de información. Si usted
            cree que su interacción con nosotros ya no es segura (por ejemplo,
            si cree que la seguridad de una cuenta que usted tiene con nosotros
            se ha puesto en riesgo), notifíquenos inmediatamente del problema
            usando las vías de comunicación proporcionadas en la sección
            “Ponerse en contacto con Pearson” de esta Política de Privacidad.
          </p>
          <p className={classes.paragraph}>
            Asimismo, todo dato personal que usted haya publicado de forma
            voluntaria en una sección pública de nuestra Plataforma, como un
            tablón de anuncios o un foro de debate, o en una sección interactiva
            que requiera inscripción y/o suscripción, podrá ser recabada y
            utilizada por otras personas. De modo que el suministro de sus datos
            personales bajo esta modalidad es realizado por el usuario bajo su
            exclusivo riesgo. Pearson no se responsabiliza por las acciones de
            los otros usuarios en relación a los datos personales que usted
            revela de esta forma.
          </p>
        </section>
        <section className={classes.section}>
          <h3 className={classes.subTitle}>
            ¿Pearson enviará mis datos personales a otro país?
          </h3>
          <p className={classes.paragraph}>
            Sus datos personales pueden ser transferidos para los fines
            anteriormente descritos a otros países del mundo, que no posean las
            mismas leyes en materia de protección de datos que las del país
            desde el que accede a nuestros Sitios Web. Cuando transfiramos sus
            datos personales a países fuera de Chile tomaremos las medidas
            adecuadas para garantizar que a sus datos personales se le otorgue
            el mismo nivel de protección que el descrito en esta Política de
            Privacidad. Al manifestar su aceptación a la Política de Privacidad
            usted consiente expresamente que sus datos personales sean
            transferidos a dichos destinos bajo los estándares de protección
            mencionados.
          </p>
        </section>
        <section className={classes.section}>
          <h3 className={classes.subTitle}>
            ¿Durante cuánto tiempo conservará Pearson mis datos personales?
          </h3>
          <p className={classes.paragraph}>
            Conservaremos sus datos personales solo el tiempo que sea necesario
            a fin de cumplir los objetivos descritos en la presente Política de
            Privacidad, salvo que la ley permita o exija un plazo más
            prolongado. Por ejemplo, puede que conservemos sus datos personales
            para garantizar la calidad del servicio que hemos prestado o que
            debamos conservarla a fin de defendernos contra eventuales demandas.
          </p>
        </section>
        <section className={classes.section}>
          <h3 className={classes.subTitle}>¿Y si soy menor de 18 años?</h3>
          <p className={classes.paragraph}>
            Si es menor de edad o carece de capacidad para contratar de
            conformidad con la legislación aplicable, asegúrese de leer esta
            Política de Privacidad con su padre, madre, tutor o representante
            legal y de preguntarles todo aquello que no entienda. Los menores de
            edad o incapaces deben obtener previamente permiso de sus padres,
            tutores o representantes legales, quienes serán considerados
            responsables de todos los actos realizados por las personas a su
            cargo.
          </p>
        </section>
        <section className={classes.section}>
          <h3 className={classes.subTitle}>¿Cuáles son mis derechos? </h3>
          <p className={classes.paragraph}>
            Usted podrá ejercer los siguientes derechos a su solo requerimiento
            y sin costo alguno: solicitar acceso y obtener una copia de sus
            datos personales;
          </p>
          <p className={classes.paragraph}>
            rectificar o actualizar sus datos personales si ya no son exactos o
            solicitar la supresión de sus datos personales; y
          </p>
          <p className={classes.paragraph}>
            limitar u oponerse al tratamiento de sus datos personales en
            determinadas circunstancias;
          </p>
          <p className={classes.paragraph}>
            Si desea ejercer cualquiera de sus derechos en relación con sus
            datos personales, envíenos una solicitud con los datos especificados
            en la sección “Ponerse en contacto con Pearson” que figura a
            continuación. Frente a cualquier solicitud, usted deberá
            suministrarnos un medio para poder identificarlo y así contactarlo
            de ser necesario. Examinaremos toda solicitud y actuaremos de
            conformidad con la legislación aplicable en materia de protección de
            datos.
          </p>
        </section>
        <section className={classes.section}>
          <h3 className={classes.subTitle}>Uso indebido de datos personales</h3>
          <p className={classes.paragraph}>
            Toda recopilación inadecuada o todo uso indebido de datos personales
            infringe la presente Política de Privacidad. En caso de detectar una
            recopilación inadecuada o un uso indebido notifíquelo a través de un
            mensaje de correo a
            <a
              href="mailto: aviso.privacidad@pearson.com."
              target="_blank"
              rel="noreferrer"
              className={classes.decoratedLink}
            >
              aviso.privacidad@pearson.com.
            </a>{" "}
          </p>
          <p className={classes.paragraph}>
            Usted acepta no usar indebidamente ningún dato personal disponible
            en nuestra Plataforma, recabarlo ni utilizar robots ni ningún otro
            script automatizado, código ni funcionalidad destinado a tal fin.
          </p>
          <p className={classes.paragraph}>
            Pearson se reserva el derecho de suspender o cancelar el acceso a
            nuestra Plataforma sin previo aviso a todos aquellos usuarios que
            infrinjan el Aviso Legal o esta Política de Privacidad.
          </p>
        </section>
        <section className={classes.section}>
          <h3 className={classes.subTitle}>
            Cambios a esta Política de Privacidad
          </h3>
          <p className={classes.paragraph}>
            La presente Política de Privacidad podrá ser objeto de actualización
            periódica para reflejar los cambios introducidos en nuestras
            prácticas en materia de datos personales. Toda modificación de esta
            Política de Privacidad será comunicada por correo electrónico a la
            dirección reportada al momento de registrar su cuenta y entrará en
            vigencia cuando publiquemos la actualización de la Política de
            Privacidad. Su interacción con nosotros después de la introducción
            de estas modificaciones significará que usted acepta dichas
            modificaciones.
          </p>
        </section>
        <section className={classes.section}>
          <h3 className={classes.subTitle}>Ponerse en contacto con Pearson</h3>
          <p className={classes.paragraph}>
            Si tiene alguna pregunta sobre nuestra Política de Privacidad, no
            dude en contactarnos a la siguiente dirección:
            <br />
            <br />
            Oficina N°117, edificio Casa Colorada,
            <br />
            calle Merced N°838-A
            <br />
            Santiago Centro, Santiago, Chile
            <br />
            T:{" "}
            <a
              href="tel:+56227567119"
              target="_blank"
              rel="noreferrer"
              className={classes.link}
            >
              {" "}
              +56227567119
            </a>{" "}
            <br />o envíenos un correo electrónico a
            <a
              href="mailto: aviso.privacidad@pearson.com"
              target="_blank"
              rel="noreferrer"
              className={classes.decoratedLink}
            >
              aviso.privacidad@pearson.com
            </a>
          </p>
        </section>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          style={{ marginBottom: "40px" }}
        >
          <input
            type="checkbox"
            id="agree"
            name="agree"
            checked={accepted}
            onChange={accept}
          />
          <label htmlFor="agree">He leído los términos y condiciones.</label>
          <br></br>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container justifyContent="center" alignItems="center">
          <Button
            className={classes.BotonContinuar}
            onClick={handleConfirm}
            disabled={!accepted}
          >
            Aceptar
          </Button>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default TermsAndConditionsChile;
