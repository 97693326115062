import { makeStyles, Grid, Button } from "@material-ui/core";
import React, { useCallback } from "react";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { Link } from "react-router-dom";
import ReporteContainer from "./Reporte_Container";
import SesionesAlDia from "../../../Charts/Sesiones_Dia";
import AccordionStudents from "../../../filters/AccordionStudents";

import axios from "../../../../services/axios";

import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { useContext } from "react";
import UsabilityContext from "../../../../usability/UsabilityContext";

const useStyles = makeStyles((theme) => ({
  Container: {
    padding: "48px 5% 48px 5%",
    background:
      "linear-gradient(217deg, rgba(255,194,0,0.4962185557816877) 2%, rgba(255,255,255,1) 25%, rgba(255,255,255,0.4962185557816877) 81%, rgba(144,81,177,1) 98%)",
    minHeight: "calc(100vh - 80px)",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    display: "grid",
    position: "relative",
    gap: "20px",
    gridTemplateColumns: "1fr",
    gridTemplateAreas: (props) => (props.showFilter ? `"filter"` : `"data"`),
    [theme.breakpoints.up("md")]: {
      gridTemplateAreas: (props) =>
        props.showFilter ? `"filter data"` : `"filter data"`,
      gridTemplateColumns: (props) =>
        props.showFilter ? `250px 1fr` : `100px 1fr`,
    },
  },
  ContainerReportes: {
    background: "#fff",
    width: "100%",
    minHeight: "70vh",
    borderRadius: "42px",
    gridArea: "data",
    padding: "30px",
    boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.5)",
  },
  ContainerSerchBar: {
    gap: "10%",
    padding: "10px 0",
    justifyContent: "space-between",
  },
  SerchBar: {
    outline: "none",
    width: "100%",
    borderRadius: "36px",
    padding: "5px 32px",
    border: "4px solid #EA9D90",
    textAlign: "left",
    letterSpacing: "0.63px",
    color: "#7A54BA",
    font: "600 20px lato",
    // margin: " 0 10%",
    [theme.breakpoints.up("lg")]: {
      height: "auto",
      padding: "10px 32px",
    },
  },
  SerchBarIcon: {
    right: "23px",
    // top: "25px",
    position: "absolute",
    fontSize: "20px",
    color: "#7A54BA",
  },
  titleContainer: {
    margin: "20px 0",
    [theme.breakpoints.up("lg")]: {
      margin: "10px 0",
    },
  },
  reading: {
    width: "60%",
    margin: "0px ",
    font: "18px lato",
    letterSpacing: "0.21px",
    [theme.breakpoints.down(1400)]: {
      font: " 14px lato",
    },
    [theme.breakpoints.down(650)]: {
      font: "10px lato",
    },
  },
  title: {
    // margin: "0 0 84px 34px",
    font: "22px fredoka one",
    textAlign: "center",
    [theme.breakpoints.up("xl")]: {
      font: "26px fredoka one",
    },
  },
  PurpleButton: {
    color: "#FFFFFF",
    backgroundColor: "#9051b1",
    borderRadius: "30px",
    // margin: "0 auto",
    // width: "100%",
    minWidth: "75px",
    maxWidth: "238px",
    // minHeight: "45px",
    border: "none",
    textTransform: "unset",
    cursor: "pointer",
    fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
    fontWeight: "500",
    padding: "4px 14px",
    lineHeight: "1.5",
    transition:
      "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    boxShadow:
      "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
    "&:hover": {
      backgroundColor: "#B965D3 ",
    },
  },
  WitheButton: {
    color: "#9051b1",
    // borderColor: "#9051b1",
    border: "1px solid #9051b1",
    backgroundColor: "#FFFFFF",
    borderRadius: "30px",
    // margin: "0 auto",
    // width: "100%",
    minWidth: "75px",
    maxWidth: "238px",
    // minHeight: "45px",
    textTransform: "unset",
    cursor: "pointer",
    fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
    fontWeight: "500",
    padding: "4px 14px",
    lineHeight: "1.5",
    transition:
      "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    boxShadow:
      "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
    [theme.breakpoints.up("lg")]: {
      minWidth: "100px",
    },

    "&:hover": {
      backgroundColor: "#B965D330 ",
    },
  },
  link: {
    cursor: "pointer",
    textDecoration: "none",
    // height: "31px",
    // width: "190px",
  },
  backButtonContainer: {
    width: "auto",
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.up("sm")]: {
      justifyContent: "flex-start",
    },
  },
  backButton: {
    cursor: "pointer",
    height: "35px",
    // width: "100%",
    // maxWidth: "200px",
    backgroundColor: "#FDEFF7",
    color: "#DC5EA6",
    border: "#DC5EA6 1px solid",
    borderRadius: "20px",
    textTransform: "none",
    font: "20px Lato",
    letterSpacing: "0.14px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
  },
  buttonToggle: {
    color: "#9051b1",
    width: "auto",
    textTransform: "unset",
  },
  buttonToggleXs: {
    width: "auto",
    textTransform: "unset",
    color: "#9051b1",
    fontSize: "18px",
    fontFamily: "fredoka one",
    [theme.breakpoints.up("md")]: {
      display: "none",
      transform: "rotation",
    },
  },
  buttonToggleIcon: {
    transform: "rotate(90deg)",

    [theme.breakpoints.up("md")]: {
      transform: (props) =>
        props.showFilter ? `rotate(0deg)` : `rotate(180deg)`,
      fontSize: "36px",
    },
  },
  buttonToggleXsIcon: {
    transform: "rotate(270deg)",
  },

  ContainerFiltro: {
    zIndex: 2,
    display: (props) => (props.showFilter ? `flex` : `none`),
    width: "100%",
    alignItems: "center",
    gap: "10px",
    boxSizing: "border-box",
    gridArea: "filter",
    justifyContent: "space-between",
    paddingBottom: "20px",
    overflowY: "auto",
    overflowX: "hidden",
    position: (props) => (props.showFilter ? `fixed` : "static"),
    top: "80px",
    bottom: "0",
    right: "0",
    left: "0",
    background:
      "linear-gradient(217deg, rgba(255,194,0,1) 2%, rgba(255,255,255,1) 25%, rgba(255,255,255,1) 81%, rgba(144,81,177,1) 98%)",
    [theme.breakpoints.up("md")]: {
      position: () => "sticky",
      background: "transparent",
    },
  },
  ContainerFiltroUp: {
    gap: "10px",
    padding: "20px",
    [theme.breakpoints.up("md")]: {
      padding: "0px",
    },
  },
  containerFiltroTitle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  filtroTitle: {
    font: "22px fredoka one",
    textAlign: "center",
    width: "auto",
    margin: "0",
    [theme.breakpoints.up("sm")]: {
      width: "auto",
    },
    [theme.breakpoints.up("md")]: {
      font: "28px fredoka one",
      width: "auto",
    },
  },
  ContainerAlumnosInfo: {
    display: "flex",
    width: "100%",
    gap: "10px",
  },
  ContainerInput: {
    position: "relative",
    width: "250px",
    // [theme.breakpoints.up("sm")]: {
    //   height: "100%",
    // },
    // [theme.breakpoints.up("720")]: {
    //   width: "75%",
    // },
    // [theme.breakpoints.up("md")]: {
    //   width: "50%",
    // },
  },
}));

const DetalleSesion = ({ location }) => {
  const [showFilter, setShowFilter] = React.useState(true);
  const classes = useStyles({ showFilter });

  const [studentsList, setStudentsList] = React.useState([]);
  const [moda, setModa] = React.useState();
  const [studentsGraphList, setStudentsGraphList] = React.useState([]);
  const [downloadData, setDownloadData] = React.useState("");
  const { idDistribEsc } = location?.state;
  const { handleModalMessage, handleLoading } = useContext(UsabilityContext);

  const handleToggle = () => {
    setShowFilter(!showFilter);
  };

  const fullList = useCallback(() => {
    handleLoading(true);
    const body = {
      id_distribucion_escuela: idDistribEsc,
      // id_usuario: idUsuario,
    };
    axios
      .post(`/listado/reporte/1?id_distribucion_escuela=${idDistribEsc}`, body)
      .then((response) => {
        if (response.status === 200) {
          setStudentsGraphList(response.data.resultado?.Alumnos?.slice(0, 10));
          setStudentsList(response.data.resultado.Alumnos);
          setModa(response.data.resultado.moda);
        }
      })
      .catch((error) => {
        if (error.response) {
          // Request made and server responded
          const errorData = error.response.data;
          const mensaje =
            errorData?.detalles?.msg ||
            "Ha ocurrido un error al consumir los servicios, inténtelo de nuevo más tarde.";
          handleModalMessage({
            habilite: true,
            mensaje: mensaje,
          });
        } else {
          // Something happened in setting up the request that triggered an Error
          const mensaje =
            "Ha ocurrido un error al consumir los servicios, inténtelo de nuevo más tarde.";
          handleModalMessage({
            habilite: true,
            mensaje: mensaje,
          });
        }
        setStudentsGraphList([]);
      })
      .finally(() => {
        handleLoading(false);
      });
  }, [idDistribEsc]);

  React.useEffect(() => {
    const body = {
      idDistribucionEscuela: 1,
    };
    axios
      .get(`/profesor/getReport/1?idDistribucionEscuela=${idDistribEsc}`, body)
      .then((response) => {
        if (response.status === 200) {
          setDownloadData(response.data.resultado.path);
        }
      })
      .catch((error) => {
        console.log(error, "dwnldF");
      });
  }, [idDistribEsc]);

  React.useEffect(() => {
    fullList();
  }, [idDistribEsc, fullList]);

  React.useEffect(() => {
    setStudentsGraphList(studentsGraphList);
  }, [studentsGraphList.length, studentsGraphList]);

  return (
    <>
      <ReporteContainer>
        <Grid
          container
          item
          sm={12}
          className={classes.Container}
          alignItems="center"
          justifyContent="center"
        >
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Button className={classes.buttonToggle} onClick={handleToggle}>
              {showFilter ? "Buscar por filtro" : "Filtrar"}
              <ArrowRightIcon className={classes.buttonToggleIcon} />
            </Button>
            <Grid
              container
              item
              direction="column"
              className={classes.ContainerFiltro}
            >
              <Grid
                container
                item
                direction="column"
                className={classes.ContainerFiltroUp}
              >
                <Grid
                  container
                  item
                  direction="row"
                  className={classes.containerFiltroTitle}
                >
                  <Button
                    className={classes.buttonToggleXs}
                    onClick={handleToggle}
                  >
                    {showFilter ? "Retraer" : "Ampliar"}
                    <ArrowRightIcon className={classes.buttonToggleXsIcon} />
                  </Button>
                </Grid>
                <Grid
                  item
                  direction="column"
                  className={classes.ContainerAlumnosInfo}
                >
                  <Grid>
                    <Button onClick={fullList}>
                      Limpiar todos los filtros
                    </Button>
                  </Grid>
                  {studentsList.length ? (
                    <AccordionStudents
                      studentsList={studentsList}
                      setStudentsList={setStudentsList}
                      studentsGraphList={studentsGraphList}
                      setStudentsGraphList={setStudentsGraphList}
                    />
                  ) : null}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            item
            direction="column"
            className={classes.ContainerReportes}
          >
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              className={classes.backButtonContainer}
            >
              <Link
                className={classes.link}
                to={{
                  pathname: "/admin/reportes",
                  state: {
                    id_distribucion_escuela: idDistribEsc,
                  },
                }}
              >
                <button className={classes.backButton}>
                  <ArrowBackIcon /> Reportes
                </button>
              </Link>
            </Grid>
            <Grid
              container
              item
              direction="column"
              alignItems="center"
              justifyContent="center"
              className={classes.titleContainer}
            >
              <h2 className={classes.title}>Reporte 1: Avance general</h2>
              <p className={classes.reading}>
                Este reporte te permite conocer en qué sesión se encuentran
                trabajando los estudiantes al día de la consulta. La línea
                vertical que cruza la gráfica indica la sesión promedio en la
                que se encuentra el grupo.
              </p>
            </Grid>

            <Grid container item direction="row" alignItems="center">
              {studentsGraphList.length && moda && studentsList.length ? (
                <SesionesAlDia
                  studentsList={studentsGraphList}
                  setStudentsList={setStudentsGraphList}
                  moda={moda}
                  downloadData={downloadData}
                />
              ) : null}
            </Grid>
          </Grid>
        </Grid>
      </ReporteContainer>
    </>
  );
};

export default DetalleSesion;
