import { Button, Grid, makeStyles } from "@material-ui/core";
import React, { useContext, useEffect } from "react";
import DrawerContext from "../../../../../usability/DrawerContext";
import { useOnLoadImages } from "../../../../hooks/useOnLoadImages";
import Loader from "../../../../Loader/Loader";
import Drawer from "../../ReadingTools/Drawer";

const useStyles =  makeStyles (theme =>({
     Contenedor:{
        background:'#E9FBF9',
        width:'100vw',
        overflowX:'clip',
        [theme.breakpoints.down(1161)]:{
            background:'none',
           overflowX:'unset',
        },
        [theme.breakpoints.down(600)]:{
            marginTop: '79.51px',
        }
    },
  ContainerInfoLectura: {
    position: "relative",
    height: "86px",
    [theme.breakpoints.down(502)]:{
        height: "43px",
    },
    background: "#76D4C3",
    "& .BarraInfoLectura": {
      height: "86px",
      background: `Url(https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Barras/Azul.png)`,
        [theme.breakpoints.down(502)]:{
            height: "43px",
        },
    },
    "& .InfoLectura": {
      position: "absolute",
      height: "86px",
      width: "219px",
      boxSizing: "border-box",
      padding: "17.2px 0",
      background: "#FAFAFA",
      borderRadius: "50px",
      boxShadow: "2px 2px 2px 2px #00000029",
      zIndex: "4",
        [theme.breakpoints.down(502)]:{
            height: "43px",
            width: "109px",
            padding: "8.6px 0",
            borderRadius: "25px",
        },
    },
    "& .InfoLectura:before": {
      position: "absolute",
      display: "flex",
      boxSizing: "border-box",
      padding: "0 0 0 10px",
      justifyContent: "center",
      alignItems: "center",
      height: "39.88px",
      width: "39.88px",
      borderRadius: "50%",
      content: '""',
      boxShadow: "3px 0px .5px 0px #00000029",
      background: "#FAFAFA",
      left: "90px",
      top: "65px",
      transform: "rotate(0.25turn)",
      [theme.breakpoints.down(502)]:{
        left: "45px",
        top: "30px",
        height: "20.88px",
        width: "20.88px",
        },
    },
    "& .InfoLectura:after": {
      content: '""',
      height: "15px",
      width: "15px",
      borderTop: "2px solid #20A698",
      borderLeft: "2px solid #20A698",
      position: "absolute",
      bottom: "-10px",
      left: "Calc(50% - 8px)",
      transform: "rotate(0.625turn)",
      [theme.breakpoints.down(502)]:{
        height: "10px",
        width: "10px",
        left: "Calc(50% - 4.5px)",
        bottom: "-3px",
        borderTop: "1px solid #20A698",
        borderLeft: "1px solid #20A698",
        },
    },
    "& .Sesion": {
      borderLeft: "2px solid #D8D8D8",
    },
    "& .Dato": {
      margin: "0 0 5px 0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#20A698",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        margin: "0 0 2.5px 0",
        },
    },
    "& .Caracter": {
      margin: "0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#000000",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        },
    },
       '& .Regresar':{
        color: '#F2624F',
        border: '#F2624F 1px solid',
        height: '30px',
        width:'100px',
        borderRadius: '20px',
        letterSpacing: '0.14px',
        textTransform: 'none',
        background:'#FEFAF1',
        top:'calc(50% - 15px)',
        left:'5%',
        zIndex:4,
        [theme.breakpoints.down(502)]: {
          font:'bold 10px lato',
          width: "60px",
        },
      },
  },
    ContenedorLectura:{
        background:'#fff',
        width:'1160px',
        height:'100%',
                padding:'60px 168px 35px ',
        
        transform: (props) =>
        props.scale > 1 ? `scale(${props.scale}) translate(0px, 10%)` : `none`,
        [theme.breakpoints.down(1161)]:{
            width:'502px',
            padding:'60px 0 0 0',
          },
        [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },

        '& .Escritorio':{
            display:'block',
            [theme.breakpoints.down(1161)]:{
                display:'none'
            },
        },
        '& .Tablet':{
            display:'none',
            [theme.breakpoints.down(1161)]:{
                display:'block'
            },
        }
    },
    ContainerTitulo:{
        margin:'0 0 34px 0',
        [theme.breakpoints.down(1161)]:{
            margin:'0 0 34px 0',
        },
        '& .Titulo':{
            font:'48px fredoka one',
            letterSpacing: '0px',
            lineHeight:'58px',
            color: '#688BE9',
            margin:'0',
            textAlign:'center',
            [theme.breakpoints.down(1161)]:{
                font:'43px fredoka one',
                lineHeight:'52px',
            },
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            lineHeight : '30px',
            margin:'0 0 36px 0',
            font:'22px lato',
            color:'#000',
            letterSpacing: '0.20px',
            [theme.breakpoints.down(1161)]:{
                font:'18px lato',
                lineHeight :'28px',
                letterSpacing: '0.17px',
            },
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .SubTitulo':{
            font:'bold 30px lato',
            color:'#FF906F',
            letterSpacing:'.28px',
            lineHeight:'30px',
            margin:'0',
            [theme.breakpoints.down(1161)]:{
                lineHeight :'28px',
                font:'bold 26px lato',
                letterSpacing: '0.24px',
            },
            [theme.breakpoints.down(502)]:{
                font:'bold 18px lato',
                lineHeight :'24px',
                letterSpacing: '0.21px',
            },
        },
        '& .Lectura2':{
            font:'bold 22px lato',
            color:'#688BE9',
            letterSpacing:'.24px',
            lineHeight:'28px',
            margin:'0',
            [theme.breakpoints.down(1161)]:{
                font:'bold 18px lato',
                lineHeight :'28px',
                letterSpacing: '0.17px',
            },
                        [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .Lectura3':{
            margin:'0',
        },
        '& .Ul':{
            color:'#FF906F',
            margin:'36px 0',
            padding:'0 0 0 19px'
        },
        '& .Li':{
            margin:'0 0 8px 0',
            [theme.breakpoints.down(1161)]:{
                margin:'0 0 4px 0',
            },
        },

        '& .TextImg':{
            font:'20px lato',
            color:'#717476',
            letterSpacing:'.19px',
            lineHeight:'30px',
            margin:'24px 0 0 0',
            [theme.breakpoints.down(1161)]:{
                font:' 16px lato',
                lineHeight :'25px',
                letterSpacing: '0.15px',
            },
            [theme.breakpoints.down(502)]:{
                font:' 11px lato',
                lineHeight :'14px',
                letterSpacing: '0.10px',
            },
        },

        '& .Imagen':{
            [theme.breakpoints.down(1161)]:{
                width:'100%',
            },
        },  
      
    }
}));

const Aprendiendo_a_convivir_en_paz = (props) => {

  const {activeStep, setActiveStep, handlePreviewStep, id_actividad} = props
  
  const handleContinue = () => {
    setActiveStep(activeStep - 1)
    handlePreviewStep()
  }

    const {
      handleSize,
    disabledZoom,
    closeZoom,
    handleZoom,
    scale
  }= useContext(DrawerContext);
  const [loading, setLoading] = React.useState(true);
  const wrapperRef = React.useRef(null);
  const imagesLoaded = useOnLoadImages(wrapperRef);

  const detectSize = () => {
    const lectura = document.getElementById("lectura");
    const newWidth = lectura?.offsetWidth;
    const newHeight = lectura?.offsetHeight;
    setLoading(false);
    if (lectura) handleSize(newHeight, newWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", detectSize);
    return () => {
      handleZoom()
      window.removeEventListener("resize", detectSize);
      closeZoom()
    };
  }, []);

  const classes = useStyles({ disabledZoom,scale });
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
             <Grid container item justifyContent="center" className={classes.ContainerInfoLectura}>
                       <Grid container className="BarraInfoLectura">
            <Button className="Regresar" onClick={handleContinue}>
                Regresar
            </Button>
        </Grid>
               <Grid container item className="InfoLectura">
                    <Grid container item direction="column" xs={6} justifyContent="center">
                        <p className="Dato">Grado</p>
                        <p className="Caracter">5</p>
                    </Grid>
                    <Grid container item direction="column" xs={6} justifyContent="center" className="Sesion">
                        <p className="Dato">Sesión</p>
                        <p className="Caracter">22</p>
                    </Grid>
               </Grid>
            </Grid>
            {!imagesLoaded && <Loader />}
        <Grid
            container
            item
            className={classes.ContenedorLectura}
            justifyContent="center"
            id="lectura"
            onLoad={detectSize}
            ref={wrapperRef}
        >
<Drawer id_lectura={id_actividad} />
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                        <h2 className="Titulo">Aprendiendo <br className="Tablet"/>a convivir en paz</h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                       <p className='Lectura'>
                        ¿Qué significa <em>convivir en paz</em>? Hasta hace unos años, la paz se asociaba con el fin de 
                        alguna guerra o de algún gran conflicto. Era el momento en el que los países o los 
                        grupos con intereses contrarios llegaban por fin a un acuerdo y éste beneficiaba más 
                        a unos que a otros.<br/><br/>
                        
                        Sin embargo, piensa en las guerras como formas extremas de conflicto. Quizá 
                        podríamos entenderlas mejor si recordamos que entre los individuos también surgen 
                        problemas y que éstos también pueden llegar a la violencia. Educar a las poblaciones 
                        para evitarlo se llama <em>cultura de la paz</em>.
                       </p>                   
                    </Grid>
                    <Grid container justifyContent='center'>
                        <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion22/1.png" alt="Tarjeta" />
                    </Grid>
                    <Grid container item justifyContent="center" >
                        <p className="TextImg">
                            La paloma blanca es uno de los símbolos más comunes para representar la paz.
                        </p>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container >
                        <p className='Lectura'>
                            Nuestra cultura propone una nueva forma de resolver los conflictos: todas las partes 
                            pueden ganar. Eso se logra recordándole a cada una de las partes en el conflicto que 
                            los otros son tan dignos como ellos mismos. La cultura de la paz se centra en el 
                            reconocimiento de los derechos humanos, que establecen que toda persona debe 
                            tener la posibilidad de desarrollarse con plenitud. <br/><br/>
                            
                            <h3 className='SubTitulo'>Cultura de la paz</h3><br/>
                            
                            Para practicar y promover la cultura de la paz es muy importante que sepas cómo 
                            comunicarte con personas diferentes a ti, entendiéndolas y respetándolas. Las 
                            formas más positivas de comunicación empiezan por comprender y aceptar que 
                            todos somos distintos y que tenemos diferentes formas de pensar y de 
                            comportarnos ante todo tipo de situaciones. Desde esta diferencia podemos 
                            construir algo en conjunto. Pero ¿cómo puedes estar seguro de que escuchas y 
                            comprendes a las otras personas sin ofenderlas? Para eso puedes preguntarte si
                            practicas los cinco valores que promueve la cultura para la paz:

                            <ul className='Ul'>
                                <li className='Li'>
                                    <p className='Lectura Lectura3'>
                                        <b className='Lectura2'>Igualdad: </b>¿Estoy reconociendo los mismos derechos y oportunidades a todos los seres humanos?
                                    </p>
                                </li> 
                                <li className='Li'>
                                    <p className='Lectura Lectura3'>
                                        <b className='Lectura2'>Justicia: </b> ¿Doy a cada uno lo que le corresponde? ¿Conozco y hago valer los derechos de las personas?
                                    </p>
                                </li> 
                                <li className='Li'>
                                    <p className='Lectura Lectura3'>
                                        <b className='Lectura2'>Tolerancia: </b> ¿Reconozco y respeto a los demás por ser distintos y tener opiniones diversas?
                                    </p>
                                </li> 
                                <li className='Li'>
                                    <p className='Lectura Lectura3'>
                                        <b className='Lectura2'>Solidaridad: </b> ¿Intento ayudar y colaborar con los demás por un bien común?
                                    </p>
                                </li> 
                                <li className='Li'>
                                    <p className='Lectura Lectura3'>
                                        <b className='Lectura2'>Libertad: </b> ¿Actúo según mi propia opinión y mi propio deseo, reconociendo y defendiendo también la libertad ajena?
                                    </p>
                                </li> 
                            </ul>

                            Asimismo, es importante poner en práctica habilidades sociales que permiten una sana convivencia en cualquier contexto 
                            de la vida diaria. Algunas de éstas son:

                            <ul className='Ul'>
                                <li className='Li'>
                                    <p className='Lectura Lectura3'>
                                    Escuchar
                                    </p>
                                </li>
                                <li className='Li'>
                                    <p className='Lectura Lectura3'>
                                    Iniciar y/o mantener una conversación
                                    </p>
                                </li>
                                <li className='Li'>
                                    <p className='Lectura Lectura3'>
                                    Dar las gracias
                                    </p>
                                </li>
                                <li className='Li'>
                                    <p className='Lectura Lectura3'>
                                    Ayudar a los demás
                                    </p>
                                </li>
                                <li className='Li'>
                                    <p className='Lectura Lectura3'>
                                    Negociar para que todas las partes estén satisfechas
                                    </p>
                                </li>
                                <li className='Li'>
                                    <p className='Lectura Lectura3'>
                                    Practicar el autocontrol
                                    </p>
                                </li>
                                <li className='Li'>
                                    <p className='Lectura Lectura3'>
                                    Defender los derechos propios y ajenos
                                    </p>
                                </li>
                                <li className='Li'>
                                    <p className='Lectura Lectura3'>
                                    Pensar sobre la causa de los problemas
                                    </p>
                                </li>
                                <li className='Li'>
                                    <p className='Lectura Lectura3'>
                                    Tomar decisiones
                                    </p>
                                </li>
                            </ul>

                            <h3 className='SubTitulo'> La paz en el conflicto </h3><br/>

                           ¿Qué hacer cuando un conflicto se complica y amenaza con volverse violento? La 
                           cultura de la paz te ayuda a prevenirlo y transformarlo. Debes buscar soluciones que 
                           te permitan vivir en armonía. Éstas dependen de que todos los involucrados en el 
                           conflicto estén dispuestos a conceder algo, a escuchar a los otros y a poner el bien 
                           común por encima de sus intereses personales.<br/><br/>
                           
                            La cultura de la paz te vuelve consciente para que tengas un pensamiento crítico 
                            durante los conflictos. Así podrás hacerte preguntas como las siguientes: ¿cuáles son 
                            las causas? ¿Cuál es la mejor forma de tratar a los involucrados? ¿Hay alguien que ya 
                            está cediendo o todas las partes siguen buscando solamente sus propios intereses?

                        </p>
                    </Grid>
                    <Grid container justifyContent='center'>
                        <Grid container justifyContent='center'>
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion22/2.png" alt="Tarjeta" />
                        </Grid>
                    </Grid>

                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container >
                        <p className='Lectura'>
                            Es importante proteger a quienes sufren violencia y examinar cómo podrían cumplirse mejor la justicia y la 
                            tolerancia. ¿Conoces algún conflicto que puedas usar como ejemplo? ¿Qué relación tiene con los valores 
                            antes mencionados?
                        </p>
                    </Grid>
                </Grid>
        </Grid>
        <Grid container item style={{ marginTop: 80}} ></Grid>
    </Grid>
    );
}
export default Aprendiendo_a_convivir_en_paz;
