import React from "react";
import { Grid, makeStyles } from "@material-ui/core";
import AuthContext from "../../../auth/AuthContext";
import UsabilityContext from "../../../usability/UsabilityContext";
import { Link } from "react-router-dom";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import defaultImg from "./img/default_avatar.png";
import { useEffect } from "react";
import { useContext } from "react";
import { useState } from "react";
const useStyles = makeStyles((theme) => ({
  avatar: {
    height: "49px",
    width: "49px",
    border: "3px solid #FFFFFF",
    borderRadius: "500px",
    objectFit: "cover",

    [theme.breakpoints.down(1367)]: {
      height: "40px",
      width: "40px",
    },
    // [theme.breakpoints.down(850)]:{
    //   height:'49px',
    //   width:'49px',
    // },
  },
  title: {
    fontFamily: "Fredoka One",
    fontSize: "18px",
    lineHeight: 2,
    letterSpacing: "0.56px",
    color: " #000",
    fontWeight: "normal",
    textAlign: "center",
  },
  mainContainer: {
    justifyContent: "flex-start",
    borderTopLeftRadius: "50px",
    borderTopRightRadius: "50px",
    padding: "20px",
    gap: "10px",
    flexWrap: "nowrap",
    "& .input": {
      font: "bold 16px lato",
    },
    height: "60vh",
    width: "100%",
    left: "0",
    backgroundColor: "#fafafa",
    position: "fixed",
    bottom: "0",
    [theme.breakpoints.up("lg")]: {
      display: "none",
    },
  },
  select: {
    border: "none",
    backgroundColor: "transparent",
    height: "20px",
    justifyContent: "center",
    font: "bold 16px lato",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "#2EDAC9",
    },
  },

  link: {
    textDecoration: "none",
    fontFamily: "Lato",
    color: "#007fa3",
    fontSize: "16px",
  },

  button: {
    fontFamily: "Lato",
    fontSize: "16px",
    color: "#007fa3",
    backgroundColor: "transparent",
    width: "100%",
    border: "none",
    textAlign: "start",
    padding: "0",
    margin: "0",
    display: "flex",
    alignContent: "center",
    alignItems: "center",
    justifyContent: "space-between",
  },

  icon: {
    color: "#ffc200",
  },

  divisor: {
    color: "#979797",
    width: "100%",
    margin: "10px 0",
  },

  selectsContainer: {
    width: "100%",
    padding: "0px 20px",
  },

  buttonSelect: {
    backgroundColor: "transparent",
    border: "none",
    fontFamily: "Lato",
    fontSize: "16px",
    width: "100%",
    textAlign: "start",
    cursor: "pointer",
    display: "flex",
    padding: "10px",
    justifyContent: "space-between",
    borderBottom: "1px solid #979797",
  },
  iconSelected: {
    backgroundColor: "#7b55b6",
    color: "#ffffff",
    padding: " 2px 7px 3px 11px",
    borderRadius: "21px",
    position: "relative",
    "&:before": {
      // display: (props) => (props.openMenuInDesktop ? "none" : "flex"),
      display: "flex",
      zIndex: 1,
      textTransform: "none",
      justifyContent: "center",
      alignItems: "center",
      position: "absolute",
      content: '""',
      height: "0px",
      border: "10px solid",
      borderTopColor: "transparent",
      borderLeftColor: "transparent",
      borderBottomColor: "transparent",
      borderRightColor: "#7b55b6",
      font: "bold 14px lato",
      bottom: "calc(50% - 10px)",
      left: "-15px",
    },
  },
}));

const GradeSelectorDrawer = (props) => {
  const classes = useStyles();
  const { user } = React.useContext(AuthContext);
  const {
    handleGroupToEdit,
    handleGroupData,
    groupData,
    openMenuInMobile,
    handleMenuInMobile,
  } = useContext(UsabilityContext);

  const [menuActive, setMenuActive] = useState(false);
  const [showSelectors, setShowSelectors] = useState(false);

  const toggleMenu = () => {
    setMenuActive(!menuActive);
    if (openMenuInMobile) handleMenuInMobile();
  };
  const toggleSelectors = () => {
    setShowSelectors(!showSelectors);
  };

  useEffect(() => {
    if (openMenuInMobile) setMenuActive(false);
    setShowSelectors(false);
    return () => {};
  }, [openMenuInMobile]);

  const selectChange = (event) => {
    const id = parseInt(event.target.value);
    handleGroupToEdit(event.target.value);
    const newData = user.grupos.find(
      (grupo) => grupo.id_distribucion_escuela === id
    );
    handleGroupData(newData || user.grupos[0]);
    toggleMenu();
    setShowSelectors(false);
  };

  return (
    <>
      <img
        className={classes.avatar}
        src={user?.avatar?.path || defaultImg}
        alt="userAvatar"
        onClick={toggleMenu}
      />
      <>
        {menuActive && (
          <Grid container className={classes.mainContainer} direction="column">
            <h3 className={classes.title}>¿Qué quieres hacer?</h3>
            <Link
              className={classes.link}
              to="/docente/perfil"
              onClick={toggleMenu}
            >
              Ir al Perfil
            </Link>
            <hr className={classes.divisor} />

            <button className={classes.button} onClick={toggleSelectors}>
              Cambiar de grupo
              <ArrowDropDownIcon className={classes.icon} />
            </button>
            {showSelectors && (
              <Grid container className={classes.selectsContainer}>
                {user?.grupos?.map((grupo, index) => {
                  return (
                    <>
                      <button
                        className={classes.buttonSelect}
                        value={grupo.id_distribucion_escuela}
                        key={index}
                        onClick={selectChange}
                      >
                        {` Grado ${grupo.id_grado}`}
                        {groupData?.id_distribucion_escuela ===
                          grupo?.id_distribucion_escuela && (
                          <span className={classes.iconSelected}>Activo</span>
                        )}
                      </button>
                    </>
                  );
                })}
              </Grid>
            )}
          </Grid>
        )}
      </>
    </>
  );
};

export default GradeSelectorDrawer;
