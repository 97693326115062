import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
    ContenedorLectura:{
        width:'502px',
                  [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
    },
    ContainerTitulo:{
        margin:'0 0 34px 0',
        '& .Titulo':{
            letterSpacing: '0px',
            color: '#823CE2',
            margin:'0',
            textAlign:'center',
            font:'43px fredoka one',
            lineHeight:'52px',
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        position:'relative',
        margin:' 0 0 36px 0',
        '& .Lectura':{
            margin:'0 0 36px 0',
            font:'18px lato',
            lineHeight :'28px',
            letterSpacing: '0.17px',
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        // '& .SubTitulo':{
        //     margin:'0',
        //     textAlign:'center',
        //     color:'#823CE2',
        //     font:'32px Fredoka One',
        //     lineHeight:'39px',
        //     letterSpacing:'.3px',
        //     [theme.breakpoints.down(1161)]:{
        //         font:'26px Fredoka One',
        //         lineHeight:'28px',
        //         letterSpacing:'.24px',
        //     },
        //     [theme.breakpoints.down(502)]:{
        //         font:'18px Fredoka One',
        //         lineHeight:'23px',
        //         letterSpacing:'.20px',
        //     },
        // },
        // '& .SubTitulo2':{
        //     margin:'0',
        //     color:'#FFFFFF',
        //     font:'bold 26px lato',
        //     lineHeight:'30px',
        //     letterSpacing:'.26px',
        //     [theme.breakpoints.down(1161)]:{
        //         font:'bold 22px lato',
        //         lineHeight:'28px',
        //         letterSpacing:'.21px',
        //     },
        //     [theme.breakpoints.down(502)]:{
        //         font:'bold 18px lato',
        //         lineHeight:'23px',
        //         letterSpacing:'.20px',
        //     },
        // },
        // '& .SubTitulo3':{
        //     margin:'0',
        //     font:'25px Fredoka One',
        //     lineHeight:'30px',
        //     letterSpacing:'.23px',
        //     [theme.breakpoints.down(1161)]:{
        //         font:'21px Fredoka One',
        //         lineHeight:'28px',
        //         letterSpacing:'.2px',
        //     },
        //     [theme.breakpoints.down(502)]:{
        //         font:'17px Fredoka One',
        //         lineHeight:'20px',
        //         letterSpacing:'.18px',
        //     },
        // },
        // '& .Naranja':{
        //     colore:'#F6664D'
        // },
        // '& .Rosa':{
        //     colore:'#FC5E9E'
        // },
        // '& .Morado':{
        //     colore:'#926DD1'
        // },
        // '& .Verde':{
        //     colore:'#20A698'
        // },

        '& .Esp':{
            margin:'0',
        }, 

        '& .Imagen':{
            width:'100%',
        }, 
    }
}));

const Una_obra_de_teatro = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>

            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >
                     Una obra de teatro:<br/> Juan y la tradición <br/>familiar de la ofrenda<br/> de Día de Muertos            
                    </h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                      <p className="Lectura">
                        ¿Sabías que antes de que existieran las películas la gente iba al teatro a ver 
                        actuaciones en vivo? Este tipo de espectáculos se conoce como <em>obra de teatro</em>. Es un 
                        género literario que cuenta una historia y la divide en <em>actos</em>.

                      </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img3ro/Sesion57/1.png" alt="Tarjeta" />
                    </Grid>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura Esp">
                            Este tipo de eventos son llamados también <em>puesta en escen</em>a, pues todo está colocado 
                            en un escenario. Ahí se combinan la música, la ambientación y la coreografía. A 
                            continuación, leerás el guion de una obra de teatro sobre el Día de Muertos. Tercera 
                            llamada… ¡comenzamos!   
                        </p>
                    </Grid>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img3ro/Sesion57/2.png" alt="Tarjeta" />
                    
                </Grid>
        </Grid>
    </Grid>
    );
}
 
export default Una_obra_de_teatro ;
