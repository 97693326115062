import { makeStyles, Grid } from "@material-ui/core";
import React from "react";
import { Link } from "react-router-dom";
import SearchIcon from "@material-ui/icons/Search";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import StarIcon from "@mui/icons-material/Star";
import ChatContext from "./Context/ChatContext";

const useStyles = makeStyles((theme) => ({
  headerContainer: {
    gridArea: "header",
    background: "transparent",
    width: "100%",
    height: "100%",
    gap: "10px",
    padding: "10px 0",
    flexWrap: "nowrap",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    alignContent: "center",
    [theme.breakpoints.up("lg")]: {
      padding: "10px 0",
    },
    [theme.breakpoints.up("xl")]: {
      padding: "15px 0",
    },
  },
  containerRight: {},
  inputAndButtonContainer: {
    display: "flex",
    width: "100%",
    padding: "0 20px",
    boxSizing: "border-box",
    justifyContent: "space-between",
    [theme.breakpoints.up("lg")]: {
      justifyContent: "unset",
    },
  },
  inputContainer: {
    gridArea: "input",
    background: "transparent",
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    alignContent: "center",
    [theme.breakpoints.up("lg")]: {
      justifyContent: "center",
    },
  },

  backButtonContainer: {
    width: "auto",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.up("lg")]: {
      display: "none",
      justifyContent: "flex-start",
    },
  },
  backButton: {
    cursor: "pointer",
    height: "35px",
    backgroundColor: "#FEFAF1",
    color: "#007fa3",
    border: "#007fa3 1px solid",
    borderRadius: "20px",
    textTransform: "none",
    font: "15px Lato",
    letterSpacing: "0.14px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
  },
  link: {
    cursor: "pointer",
    textDecoration: "none",
  },
  ContainerSerchBar: {
    gap: "10%",
    padding: "10px 0",
  },
  ContainerInput: {
    position: "relative",
    width: "75%",
    [theme.breakpoints.up("sm")]: {
      width: "60%",
    },
    [theme.breakpoints.up("lg")]: {
      margin: "0 auto",
      width: "50%",
      // height: "100%",
    },
  },
  SerchBar: {
    outline: "none",
    width: "100%",
    borderRadius: "36px",
    padding: "5px 24px",
    border: "3px solid #EECD67",
    boxShadow: "0px 3px 2px #00000029",
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    textAlign: "left",
    letterSpacing: "0.63px",
    color: "#7A54BA",
    minHeight: "35px",
    [theme.breakpoints.up("sm")]: {
      padding: "5px 32px",
    },
    [theme.breakpoints.up("lg")]: {
      height: "auto",
      padding: "10px 32px",
    },
  },
  SerchBarIcon: {
    right: "8px",
    top: "18.5px",
    position: "absolute",
    fontSize: "16px",
    color: "#7A54BA",
    [theme.breakpoints.up("sm")]: {
      fontSize: "20px",
      right: "12.5px",
      top: "15.5px",
    },
    [theme.breakpoints.up("lg")]: {
      right: "12.5px",
      top: "20.5px",
    },
  },
  statusContainer: {
    width: "50%",
    margin: "0 auto",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    alignContent: "center",
    gap: "10px",
    [theme.breakpoints.up("lg")]: {
      justifyContent: "flex-start",
      // height: "100%",
    },
  },
  teacherName: {
    fontSize: "12px",
    textAlign: "left",
    letterSpacing: "0.15px",
    fontWeight: 500,
    color: "#1DA698",
    margin: "0",
    [theme.breakpoints.up("lg")]: {
      fontSize: "19px",
    },
  },
  statusText: {
    fontSize: "10px",
    textAlign: "left",
    letterSpacing: "0.1px",
    color: "#969D9F",
    margin: "0",
    [theme.breakpoints.up("lg")]: {
      fontSize: "17px",
    },
  },
  onlineIcon: {
    width: "10px",
    height: "10px",
    borderRadius: "10px",
    /* UI Properties */
    background: "#20F7B5 0% 0% no-repeat padding-box",
    [theme.breakpoints.up("lg")]: {
      width: "12px",
      height: "12px",
    },
  },
  offlineIcon: {
    width: "10px",
    height: "10px",
    borderRadius: "10px",
    /* UI Properties */
    background: "#F6644B 0% 0% no-repeat padding-box",
    [theme.breakpoints.up("lg")]: {
      width: "12px",
      height: "12px",
    },
  },
  favoritesButton: {
    border: "none",
    backgroundColor: "transparent",
    outline: "none",
    [theme.breakpoints.up("lg")]: {
      display: "none",
    },
  },
  iconStar: {
    color: "#F3CD52",
  },
  iconStarOff: {
    color: "#00000050",
  },
}));
const ChatHeader = ({ name, conected, handleFilter, chatData }) => {
  const classes = useStyles();
  const { filterFavorites, handleFilterFavorites } =
    React.useContext(ChatContext);
  return (
    <>
      <Grid
        container
        item
        direction="column"
        className={classes.headerContainer}
      >
        <Grid className={classes.inputAndButtonContainer}>
          <Grid item className={classes.backButtonContainer}>
            <Link className={classes.link} to="/chat">
              <button className={classes.backButton}>
                <ArrowBackIcon />
              </button>
            </Link>
          </Grid>

          <Grid container item justifyContent="center">
            <button
              className={classes.favoritesButton}
              onClick={handleFilterFavorites}
            >
              <StarIcon
                className={
                  filterFavorites ? classes.iconStar : classes.iconStarOff
                }
              />
            </button>

            <Grid
              container
              item
              justifyContent="center"
              alignItems="center"
              className={classes.ContainerInput}
            >
              <input
                className={classes.SerchBar}
                type="text"
                placeholder="Buscar en conversación"
                onChange={handleFilter}
                disabled={filterFavorites}
              />
              <SearchIcon className={classes.SerchBarIcon} />
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          item
          justifyContent="center"
          alignItems="center"
          className={classes.statusContainer}
        >
          <p className={classes.teacherName}>{name}</p>
          {conected ? (
            <>
              <span className={classes.statusText}>en línea</span>
              <div className={classes.onlineIcon}></div>
            </>
          ) : (
            <>
              <span className={classes.statusText}>fuera de línea</span>
              <div className={classes.offlineIcon}></div>
            </>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default ChatHeader;
