import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
     Contenedor:{
        background:'#E9FBF9',
        width:'100vw',
        overflowX:'clip',
        [theme.breakpoints.down(1161)]:{
            background:'none',
           overflowX:'unset',
        },
        [theme.breakpoints.down(600)]:{
            marginTop: '79.51px',
        }
    },
  ContainerInfoLectura: {
    position: "relative",
    height: "86px",
    [theme.breakpoints.down(502)]:{
        height: "43px",
    },
    background: "#76D4C3",
    "& .BarraInfoLectura": {
      height: "86px",
      background: `Url(https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Barras/Azul.png)`,
        [theme.breakpoints.down(502)]:{
            height: "43px",
        },
    },
    "& .InfoLectura": {
      position: "absolute",
      height: "86px",
      width: "219px",
      boxSizing: "border-box",
      padding: "17.2px 0",
      background: "#FAFAFA",
      borderRadius: "50px",
      boxShadow: "2px 2px 2px 2px #00000029",
      zIndex: "4",
        [theme.breakpoints.down(502)]:{
            height: "43px",
            width: "109px",
            padding: "8.6px 0",
            borderRadius: "25px",
        },
    },
    "& .InfoLectura:before": {
      position: "absolute",
      display: "flex",
      boxSizing: "border-box",
      padding: "0 0 0 10px",
      justifyContent: "center",
      alignItems: "center",
      height: "39.88px",
      width: "39.88px",
      borderRadius: "50%",
      content: '""',
      boxShadow: "3px 0px .5px 0px #00000029",
      background: "#FAFAFA",
      left: "90px",
      top: "65px",
      transform: "rotate(0.25turn)",
      [theme.breakpoints.down(502)]:{
        left: "45px",
        top: "30px",
        height: "20.88px",
        width: "20.88px",
        },
    },
    "& .InfoLectura:after": {
      content: '""',
      height: "15px",
      width: "15px",
      borderTop: "2px solid #20A698",
      borderLeft: "2px solid #20A698",
      position: "absolute",
      bottom: "-10px",
      left: "Calc(50% - 8px)",
      transform: "rotate(0.625turn)",
      [theme.breakpoints.down(502)]:{
        height: "10px",
        width: "10px",
        left: "Calc(50% - 4.5px)",
        bottom: "-3px",
        borderTop: "1px solid #20A698",
        borderLeft: "1px solid #20A698",
        },
    },
    "& .Sesion": {
      borderLeft: "2px solid #D8D8D8",
    },
    "& .Dato": {
      margin: "0 0 5px 0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#20A698",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        margin: "0 0 2.5px 0",
        },
    },
    "& .Caracter": {
      margin: "0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#000000",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        },
    },   },
    ContenedorLectura:{
        background:'#fff',
        width:'1160px',
        height:'100%',
        padding:'60px 168px 35px ',
        [theme.breakpoints.down(1161)]:{
            width:'502px',
            padding:'60px 0 0 0',
          },
        [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
        '& .ContainerDerechos':{
            bottom:'80px',
            [theme.breakpoints.down(1161)]:{
                display:'none',
            },
        },
        '& .Derechos':{
            textAlign:"center",
            margin:"0px",
            font:" 15px lato",
            color:"#717476",
        },
        '& .Escritorio':{
            display:'block',
            [theme.breakpoints.down(1161)]:{
                display:'none'
            },
        },
        '& .Tablet':{
            display:'none',
            [theme.breakpoints.down(1161)]:{
                display:'block'
            },
        }
    },

    ContainerTitulo:{
        margin:'0 0 36px 0',
        '& .Titulo':{
            font:'48px fredoka one',
            letterSpacing: '0px',
            color: '#823CE2',
            margin:'0',
            textAlign:'center',
            [theme.breakpoints.down(1161)]:{
                font:'43px fredoka one',
                lineHeight:'52px',
            },
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    ContainerPoema:{
        position:'relative',
        '& .Lectura':{
            position:'absolute',
            lineHeight : '30px',
            margin:'0 ',
            font:'22px lato',
            color:'#000',
            textAlign:'center',
            letterSpacing: '0.18px',
            lineHeight:'30px',
            [theme.breakpoints.down(1161)]:{
                font:'18px lato',
                lineHeight :'28px',
                letterSpacing: '0.17px',
            },
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
            
        },  
        '& .Lectura1':{
            top:'52px',
            [theme.breakpoints.down(1161)]:{
                top:'47px',
            },
            [theme.breakpoints.down(502)]:{
                top:'32px',
            },
        },
        '& .Lectura2':{
            top:'702px',
            [theme.breakpoints.down(1161)]:{
                top:'587px',
            },
            [theme.breakpoints.down(502)]:{
                top:'400px',
            },
        },
        '& .Imagen':{
            position:'absolute',
        },
        '& .Imagen2':{
            top:'292px',
            [theme.breakpoints.down(1161)]:{
                width:'71.11%',
                top:'275px',
            },
            [theme.breakpoints.down(502)]:{
                top:'190px',
            },
        },
        '& .Imagen3':{
            top:'1383px',
            [theme.breakpoints.down(1161)]:{
                width:'68.32%',
                top:'1235px',
            },
            [theme.breakpoints.down(502)]:{
                top:'870px',
            },
        },
        '& .Imagen4':{
            top:'2213px',
            [theme.breakpoints.down(1161)]:{
                width:'81.87%',
                top:'2010px',
            },
            [theme.breakpoints.down(502)]:{
                top:'1400px',
            },
        },

        '& .Lectura3':{
            top:'1772px',
            [theme.breakpoints.down(1161)]:{
                top:'1595px',
            },
            [theme.breakpoints.down(502)]:{
                top:'1100px',
            },
        },
        '& .Fondo':{
            [theme.breakpoints.down(502)]:{
                width:'100%',

            },
        },
        
    },
}));

const Vuela_vuela_colibrí = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
             <Grid container item justifyContent="center" className={classes.ContainerInfoLectura}>
               <Grid container className="BarraInfoLectura"></Grid>
               <Grid container item className="InfoLectura">
                    <Grid container item direction="column" xs={6} justifyContent="center">
                        <p className="Dato">Grado</p>
                        <p className="Caracter">2</p>
                    </Grid>
                    <Grid container item direction="column" xs={6} justifyContent="center" className="Sesion">
                        <p className="Dato">Sesión</p>
                        <p className="Caracter">16</p>
                    </Grid>
               </Grid>
            </Grid>

            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >
                    Vuela, vuela, colibrí
                    </h2>
                </Grid>
                <Grid container item className={classes.ContainerPoema} justifyContent="center" >
                    <p className='Lectura Lectura1'>
                        De una hermosa piedra verde<br/> 
                        un colibrí fue creado.<br/> 
                        Así lo hicieron los dioses,<br/> 
                        pues el maíz se acabaron.<br/><br/> 
                        
                        Me escondo tras una flor,<br/> 
                        y allí llega un colibrí.<br/>
                    </p>
                    <img src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img2do/Sesion16/1.png" alt="Colibri" className='Imagen Imagen2'/>
                    <p className='Lectura Lectura2'>
                        Ve flores que son brillantes<br/>
                        y vuela para adelante.<br/>
                        Y cuando mira un alcatraz,<br/>
                        volando lo hace hacia atrás.<br/><br/>
                           
                        Hay una leyenda que cuenta<br/>
                        que esta misión le dejaron:<br/>
                        traernos mensajes y nuevas <br/>
                        de los que se adelantaron.<br/><br/> 

                        Me escondo tras otra flor<br/> 
                        y llega otro colibrí.<br/> <br/>
                        
                        Ve flores de tonos brillantes<br/>
                         y vuela para adelante.<br/> 
                         Y cuando mira un alcatraz,<br/> 
                         volando lo hace hacia atrás.<br/><br/> 
                         
                         Sus alas de arcoíris veo<br/> 
                         y va veloz a todos lados<br/> 
                         con más de cincuenta aleteos<br/> 
                         que en un segundo ha logrado.<br/>
                    </p>
                    <img src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img2do/Sesion16/2.png" alt="Colibri" className='Imagen Imagen3'/>
                    <p className='Lectura Lectura3'>
                        Me escondo tras una flor,<br/> 
                        y allí llega un colibrí.<br/><br/>

                        Ve flores de tonos brillantes,<br/> 
                        y vuela para adelante.<br/> 
                        Y cuando mira un alcatraz,<br/> 
                        de gusto, lo hace hacia atrás.<br/><br/> 
                        
                        El néctar que hay en las flores<br/> 
                        lo puede beber volando.<br/> 
                        Yo confundo sus colores <br/>
                        cuando en mi jardín bailando<br/> 
                        me escondo tras una flor<br/> 
                        y allí llega un colibrí.
                    </p>
                    <img src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img2do/Sesion16/3.png" alt="Colibri" className='Imagen Imagen4'/>
                    <img className='Escritorio' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img2do/Sesion16/4.png" alt="Fondo" />
                    <img className='Fondo Tablet' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img2do/Sesion16/4R.png" alt="Fondo" />

                </Grid>
                
                <Grid container item xs={12} justifyContent="center" className="ContainerDerechos">
                    <p className="Derechos">
                        © 2022. Todos los derechos reservados, Pearson, Circuito de lectores.
                    </p>                
                </Grid>
            </Grid>
        </Grid>
    );
}
 
export default Vuela_vuela_colibrí;
