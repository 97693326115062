import React from "react";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles } from "@material-ui/core";
import flag_argentina from "./img/argentina.png";
import flag_chile from "./img/chile.png";
import flag_colombia from "./img/colombia.png";
import flag_hispanoamerica from "./img/hispanoamerica.png";
import flag_peru from "./img/peru.png";
import flag_mexico from "./img/mexico.png";

const useStyles = makeStyles((theme) => ({
  MuiDialogContainer: {
    borderRadius: "61px",
    height: "90%",
    width: "90%",
    backgroundColor: "#FEFAF1",
    border: "#707070 2px solid",
    backdropFilter: "blur(3px)",
    maxWidth: "80%",
  },

  MuiContentRoot: {
    overflowY: "auto",
    [theme.breakpoints.up("lg")]: {
      "&::-webkit-scrollbar": {
        width: "auto",
      },
      "&::-webkit-scrollbar-track": {
        backgroundColor: "#7A54BA20",
        webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#7A54BA",
        borderRadius: "5px",
      },
    },
  },

  mainTitle: {
    font: "28px fredoka one",
    margin: "0px",
    textAlign: "center",
    [theme.breakpoints.up("md")]: {
      font: "36px fredoka one",
    },
    [theme.breakpoints.up("xl")]: {
      font: "42px fredoka one",
    },
  },

  container: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    alignItems: "flex-start",
    margin: "0 auto",
    [theme.breakpoints.up("lg")]: {
      width: "80%",
    },
  },
  containerButtons: {
    display: "grid",
    width: "100%",
    boxSizing: "border-box",
    gridTemplateColumns: "1fr",
    gridTemplateRows: "auto auto auto auto auto auto",
    gridGap: "20px",
    maxWidth: "900px",
    // margin: "0 auto",

    [theme.breakpoints.up("sm")]: {
      padding: "20px",
      gridTemplateColumns: "repeat(2, auto)",
      gridTemplateRows: "repeat(3, auto)",
    },
    [theme.breakpoints.up("md")]: {
      justifyContent: "space-between",
      gridTemplateColumns: "repeat(3,minmax(200px, auto))",
      gridTemplateRows: "repeat(2, auto)",
    },
    [theme.breakpoints.up("lg")]: {
      gridTemplateColumns: "repeat(3, 250px)",
    },
  },

  buttonRegion: {
    width: "100%",
    padding: "13px 14px 14px 13px",
    boxSizing: "border-box",
    cursor: "pointer",
    boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.5)",
    border: "none",
    backgroundColor: "#fff",
    fontFamily: "fredoka one",
    fontSize: "16px",
    letterSpacing: "0.23px",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: "10px",
    [theme.breakpoints.up("lg")]: {
      fontSize: "18px",
    },
  },

  flagIcon: {
    width: "35px",
    [theme.breakpoints.up("md")]: {
      width: "55px",
    },
    [theme.breakpoints.up("lg")]: {
      width: "75px",
    },
  },
}));

const SelectRegionModal = (props) => {
  const {
    open,
    handleOpen,
    setModalArgentinaOpen,
    setModalMexicoOpen,
    setModalChileOpen,
    setModalPeruOpen,
    setModalColombiaOpen,
    setModalHispanoamericaOpen,
  } = props;
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      classes={{ paper: classes.MuiDialogContainer }}
      disableEscapeKeyDown
    >
      <DialogTitle style={{ textAlign: "center" }}>
        <span className={classes.mainTitle}>Términos y Condiciones</span>
        {/* <span className={classes.mainTitle}>Términos y Condiciones</span> */}
      </DialogTitle>
      <DialogContent classes={{ root: classes.MuiContentRoot }}>
        <div className={classes.container}>
          <p className={classes.titleWithNumber}>Selecciona tu pais o región</p>
          <div className={classes.containerButtons}>
            <button
              className={classes.buttonRegion}
              onClick={() => {
                setModalArgentinaOpen(true);
              }}
            >
              <img
                src={flag_argentina}
                alt="bandera argentina"
                className={classes.flagIcon}
              />
              Argentina
            </button>
            <button
              className={classes.buttonRegion}
              onClick={() => {
                setModalHispanoamericaOpen(true);
              }}
            >
              <img
                src={flag_hispanoamerica}
                alt="Bandera Hispanoamerica"
                className={classes.flagIcon}
              />
              Hispanoamérica
            </button>
            <button
              className={classes.buttonRegion}
              onClick={() => {
                setModalColombiaOpen(true);
              }}
            >
              <img
                src={flag_colombia}
                alt="Bandera Colombia"
                className={classes.flagIcon}
              />
              Colombia
            </button>
            <button
              className={classes.buttonRegion}
              onClick={() => {
                setModalChileOpen(true);
              }}
            >
              <img
                src={flag_chile}
                alt="Bandera Chile"
                className={classes.flagIcon}
              />
              Chile
            </button>
            <button
              className={classes.buttonRegion}
              onClick={() => {
                setModalPeruOpen(true);
              }}
            >
              <img
                src={flag_peru}
                alt="Bandera Perú"
                className={classes.flagIcon}
              />
              Perú
            </button>
            <button
              className={classes.buttonRegion}
              onClick={() => {
                setModalMexicoOpen(true);
              }}
            >
              <img
                src={flag_mexico}
                alt="Bandera México"
                className={classes.flagIcon}
              />
              México
            </button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default SelectRegionModal;
