import React               from "react";
import {Grid, makeStyles}  from '@material-ui/core';

const useStyles = makeStyles(theme =>({
  ContainerReading:{

    font: '24px Lato;',
    letterSpacing: '0.15px',
    boxSizing:'border-box',
    paddin:'0px 10px 0 10px',

    [theme.breakpoints.down(1400)]:{
      font: " 20px lato",
      width: "100%",
    },
    [theme.breakpoints.down(425)]:{
      font: " 16px lato",
    },
  },
  reading: {
    margin:'0px',
  },
}));

const QuestionText = (props) => {
  const classes = useStyles()
  const { questionText } = props

  return(
    <Grid className={classes.ContainerReading}>
      <p 
        dangerouslySetInnerHTML={{__html: `${questionText}`}}
        className={classes.reading}
      >
      </p>
    </Grid>

  )

}

export default QuestionText;
