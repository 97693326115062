import React, { Suspense } from 'react';


const ReadingStrategy = (props) => {
  const {grado, sesion} = props

  const OtherComponent = React.lazy(() => import(`../introduccion/${grado}/${sesion}`))
 
  return ( 
    <div>
      <Suspense fallback={<div>Cargando...</div>}>
        <OtherComponent />
      </Suspense>
    </div>
   );
}
 
export default ReadingStrategy;