import React from "react";
import ProtectedRoute from "./ProtectedRoute";

import LectS_6_20 from "../pages/Container/ContainerLecturasBiblioteca6to/Sesion20";
import LectS_6_21R from "../pages/Container/ContainerLecturasBiblioteca6to/Sesion21R";
import LectS_6_38 from "../pages/Container/ContainerLecturasBiblioteca6to/Sesion38";
import LectS_6_39R from "../pages/Container/ContainerLecturasBiblioteca6to/Sesion39R";
import LectS_6_56 from "../pages/Container/ContainerLecturasBiblioteca6to/Sesion56";
import LectS_6_56R from "../pages/Container/ContainerLecturasBiblioteca6to/Sesion56R";

import LectS_6_3 from "../pages/Container/ContainerLecturasBiblioteca6to/Sesion3";
import LectS_6_4 from "../pages/Container/ContainerLecturasBiblioteca6to/Sesion4";
import LectS_6_5 from "../pages/Container/ContainerLecturasBiblioteca6to/Sesion5";
import LectS_6_6 from "../pages/Container/ContainerLecturasBiblioteca6to/Sesion6";
import LectS_6_7 from "../pages/Container/ContainerLecturasBiblioteca6to/Sesion7";
import LectS_6_8 from "../pages/Container/ContainerLecturasBiblioteca6to/Sesion8";
import LectS_6_9 from "../pages/Container/ContainerLecturasBiblioteca6to/Sesion9";
import LectS_6_10 from "../pages/Container/ContainerLecturasBiblioteca6to/Sesion10";
import LectS_6_11 from "../pages/Container/ContainerLecturasBiblioteca6to/Sesion11";
import LectS_6_12 from "../pages/Container/ContainerLecturasBiblioteca6to/Sesion12";
import LectS_6_13 from "../pages/Container/ContainerLecturasBiblioteca6to/Sesion13";
import LectS_6_14 from "../pages/Container/ContainerLecturasBiblioteca6to/Sesion14";
import LectS_6_15 from "../pages/Container/ContainerLecturasBiblioteca6to/Sesion15";
import LectS_6_16 from "../pages/Container/ContainerLecturasBiblioteca6to/Sesion16";
import LectS_6_17 from "../pages/Container/ContainerLecturasBiblioteca6to/Sesion17";
import LectS_6_18 from "../pages/Container/ContainerLecturasBiblioteca6to/Sesion18";
import LectS_6_19 from "../pages/Container/ContainerLecturasBiblioteca6to/Sesion19";
import LectS_6_21 from "../pages/Container/ContainerLecturasBiblioteca6to/Sesion21";
import LectS_6_22 from "../pages/Container/ContainerLecturasBiblioteca6to/Sesion22";
import LectS_6_23 from "../pages/Container/ContainerLecturasBiblioteca6to/Sesion23";
import LectS_6_24 from "../pages/Container/ContainerLecturasBiblioteca6to/Sesion24";
import LectS_6_25 from "../pages/Container/ContainerLecturasBiblioteca6to/Sesion25";
import LectS_6_26 from "../pages/Container/ContainerLecturasBiblioteca6to/Sesion26";
import LectS_6_27 from "../pages/Container/ContainerLecturasBiblioteca6to/Sesion27";
import LectS_6_28 from "../pages/Container/ContainerLecturasBiblioteca6to/Sesion28";
import LectS_6_29 from "../pages/Container/ContainerLecturasBiblioteca6to/Sesion29";
import LectS_6_30 from "../pages/Container/ContainerLecturasBiblioteca6to/Sesion30";
import LectS_6_31 from "../pages/Container/ContainerLecturasBiblioteca6to/Sesion31";
import LectS_6_32 from "../pages/Container/ContainerLecturasBiblioteca6to/Sesion32";
import LectS_6_33 from "../pages/Container/ContainerLecturasBiblioteca6to/Sesion33";
import LectS_6_34 from "../pages/Container/ContainerLecturasBiblioteca6to/Sesion34";
import LectS_6_35 from "../pages/Container/ContainerLecturasBiblioteca6to/Sesion35";
import LectS_6_36 from "../pages/Container/ContainerLecturasBiblioteca6to/Sesion36";
import LectS_6_37 from "../pages/Container/ContainerLecturasBiblioteca6to/Sesion37";
import LectS_6_39 from "../pages/Container/ContainerLecturasBiblioteca6to/Sesion39";
import LectS_6_40 from "../pages/Container/ContainerLecturasBiblioteca6to/Sesion40";
import LectS_6_41 from "../pages/Container/ContainerLecturasBiblioteca6to/Sesion41";
import LectS_6_42 from "../pages/Container/ContainerLecturasBiblioteca6to/Sesion42";
import LectS_6_43 from "../pages/Container/ContainerLecturasBiblioteca6to/Sesion43";
import LectS_6_44 from "../pages/Container/ContainerLecturasBiblioteca6to/Sesion44";
import LectS_6_45 from "../pages/Container/ContainerLecturasBiblioteca6to/Sesion45";
import LectS_6_46 from "../pages/Container/ContainerLecturasBiblioteca6to/Sesion46";
import LectS_6_47 from "../pages/Container/ContainerLecturasBiblioteca6to/Sesion47";
import LectS_6_48 from "../pages/Container/ContainerLecturasBiblioteca6to/Sesion48";
import LectS_6_49 from "../pages/Container/ContainerLecturasBiblioteca6to/Sesion49";
import LectS_6_50 from "../pages/Container/ContainerLecturasBiblioteca6to/Sesion50";
import LectS_6_51 from "../pages/Container/ContainerLecturasBiblioteca6to/Sesion51";
import LectS_6_52 from "../pages/Container/ContainerLecturasBiblioteca6to/Sesion52";
import LectS_6_53 from "../pages/Container/ContainerLecturasBiblioteca6to/Sesion53";
import LectS_6_54 from "../pages/Container/ContainerLecturasBiblioteca6to/Sesion54";
import LectS_6_55 from "../pages/Container/ContainerLecturasBiblioteca6to/Sesion55";
import LectS_6_57 from "../pages/Container/ContainerLecturasBiblioteca6to/Sesion57";
import LectS_6_58 from "../pages/Container/ContainerLecturasBiblioteca6to/Sesion58";

const routes = [
  <ProtectedRoute
    exact
    path="/Todos_en_uno_y_uno_en_todos"
    component={LectS_6_20}
  />,
  <ProtectedRoute exact path="/Viajes_en_el_tiempo" component={LectS_6_21R} />,
  <ProtectedRoute
    exact
    path="/Los_derechos_de_los_consumidores"
    component={LectS_6_38}
  />,
  <ProtectedRoute exact path="/El_Galeón_de_Manila" component={LectS_6_39R} />,
  <ProtectedRoute
    exact
    path="/El_caballo_hace_un_recorrido_semimágico"
    component={LectS_6_55}
  />,
  <ProtectedRoute exact path="/La_cocina_como_arte" component={LectS_6_56R} />,
  <ProtectedRoute exact path="/39" component={LectS_6_3} />,
  //Arma_tu_propia_alcancía"
  <ProtectedRoute exact path="/40" component={LectS_6_4} />,
  //Las_abejas_y_la_polinización"
  <ProtectedRoute exact path="/41" component={LectS_6_5} />,
  //Para_qué_sirve_un_museo"
  <ProtectedRoute exact path="/42" component={LectS_6_6} />,
  //De_otro_planeta"
  <ProtectedRoute exact path="/43" component={LectS_6_7} />,
  //Los_anteojos_una_historia_inesperada"
  <ProtectedRoute exact path="/44" component={LectS_6_8} />,
  //Operaciones_con_conjuntos"
  <ProtectedRoute exact path="/45" component={LectS_6_9} />,
  //Países_en_distintas_vías_de_desarrollo"
  <ProtectedRoute exact path="/46" component={LectS_6_10} />,
  //Las_torres_de_Hanói"
  <ProtectedRoute exact path="/47" component={LectS_6_11} />,
  //La_carrera_espacial"
  <ProtectedRoute exact path="/48" component={LectS_6_12} />,
  //El_Festival_Cervantino_en_época_virtual"
  <ProtectedRoute exact path="/49" component={LectS_6_13} />,
  //La_hoja_mágica"
  <ProtectedRoute exact path="/51" component={LectS_6_15} />,
  //Economía_naranja_creativa_y_sostenible"
  <ProtectedRoute exact path="/50" component={LectS_6_14} />,
  //Muse_del_juego"
  <ProtectedRoute exact path="/52" component={LectS_6_16} />,
  //La_misión_de_las_nubes"
  <ProtectedRoute exact path="/53" component={LectS_6_17} />,
  //La_importancia_del_calcio"
  <ProtectedRoute exact path="/54" component={LectS_6_18} />,
  //Diferentes_reglas"
  <ProtectedRoute exact path="/55" component={LectS_6_19} />,
  //Todo_a_su_lugar"
  <ProtectedRoute exact path="/58" component={LectS_6_21} />,
  //El_teatro_y_su_historia"
  <ProtectedRoute exact path="/59" component={LectS_6_22} />,
  //Qué_hace_un_arqueólogo"
  <ProtectedRoute exact path="/60" component={LectS_6_23} />,
  //El_extranjero"
  <ProtectedRoute exact path="/61" component={LectS_6_24} />,
  //La_educación_es_un_derecho_para_todos"
  <ProtectedRoute exact path="/62" component={LectS_6_25} />,
  //El_secreto_de_la_vida"
  <ProtectedRoute exact path="/63" component={LectS_6_26} />,
  //Hipatia_de_Alejandría_y_las_matemáticas"
  <ProtectedRoute exact path="/64" component={LectS_6_27} />,
  //El_Coco"
  <ProtectedRoute exact path="/65" component={LectS_6_28} />,
  //Igualdad_y_equidad"
  <ProtectedRoute exact path="/66" component={LectS_6_29} />,
  //Niños_de_azúcar"
  <ProtectedRoute exact path="/67" component={LectS_6_30} />,
  //Hablar_con_emojis"
  <ProtectedRoute exact path="/68" component={LectS_6_31} />,
  //Siempre_al_mar"
  <ProtectedRoute exact path="/69" component={LectS_6_32} />,
  //Tecnología_digital"
  <ProtectedRoute exact path="/70" component={LectS_6_33} />,
  //Viajeros_en_el_espacio_asteroides_y_cometas"
  <ProtectedRoute exact path="/71" component={LectS_6_34} />,
  //Las_Vitaminas_se_comen"
  <ProtectedRoute exact path="/72" component={LectS_6_35} />,
  //Energía_limpia_para_el_planeta_Tierra"
  <ProtectedRoute exact path="/73" component={LectS_6_36} />,
  //Declaración_Universal_de_los_Derechos_Humanos"
  <ProtectedRoute exact path="/74" component={LectS_6_37} />,
  //Jugando_con_la_historia"
  <ProtectedRoute exact path="/77" component={LectS_6_39} />,
  //Qué_son_las_artes_plásticas"
  <ProtectedRoute exact path="/78" component={LectS_6_40} />,
  //La_libertad_de_expresión"
  <ProtectedRoute exact path="/79" component={LectS_6_41} />,
  //Viaje_a_lo_invisible"
  <ProtectedRoute exact path="/80" component={LectS_6_42} />,
  //Fuegos_artificiales_y_su_contaminación"
  <ProtectedRoute exact path="/81" component={LectS_6_43} />,
  //Anuncio_impresora"
  <ProtectedRoute exact path="/82" component={LectS_6_44} />,
  //El_sombrerero_loco"
  <ProtectedRoute exact path="/83" component={LectS_6_45} />,
  //Göbekli_Tepe"
  <ProtectedRoute exact path="/84" component={LectS_6_46} />,
  //El_arte_de_la_pintura"
  <ProtectedRoute exact path="/85" component={LectS_6_47} />,
  //El_primer_alimento_de_nuestra_vida_la_leche"
  <ProtectedRoute exact path="/86" component={LectS_6_48} />,
  //Go_el_juego_de_mesa_hino"
  <ProtectedRoute exact path="/87" component={LectS_6_49} />,
  //Una_fogata_en_el_cielo"
  <ProtectedRoute exact path="/88" component={LectS_6_50} />,
  //La_importancia_del_sueño"
  <ProtectedRoute exact path="/89" component={LectS_6_51} />,
  //Derecho_a_la_salud"
  <ProtectedRoute exact path="/90" component={LectS_6_52} />,
  //Horario_de_vuelos"
  <ProtectedRoute exact path="/91" component={LectS_6_53} />,
  //Distanciamiento_social_y_acercamiento_virtual"
  <ProtectedRoute exact path="/92" component={LectS_6_54} />,
  //El_reciclaje"
  <ProtectedRoute exact path="/96" component={LectS_6_56} />,
  //El_origen_del_cine"
  <ProtectedRoute exact path="/95" component={LectS_6_57} />,
  //Una_obra_de_teatro"
  <ProtectedRoute exact path="/97" component={LectS_6_58} />,
  //Los_números_triangulares"
];

const Lectura6to = routes.map((route, key) => {
  return { ...route, key: key };
});
export default Lectura6to;
