import types from "../types/types";

const authReducer = (state = {}, action) => {
  switch (action.type) {
    case types.login:
      return {
        ...action.payload,
        logged: true,
      };
    case types.logout:
      return {
        logged: false,
      };
    case types.reLogin:
      return {
        ...state,
        user: {
          ...action.payload,
        },
        logged: true,
      };
    case types.passwordChange:
      return {
        ...state,
        passwordChange: action.payload,
      };
    case types.perfilChanges:
      return {
        ...state,
        nombre: action.payload.name,
        apelllidoPaterno: action.payload.lastName,
        apellidoMaterno: action.payload.secondLastName,
        name: action.payload.name,
        firstLastName: action.payload.lastName,
        secondLastName: action.payload.secondLastName,
      };
    case types.avatar:
      return {
        ...state,
        ...action.payload,
      };
    case types.totalActividades:
      return {
        ...state,
        totalActividadesCompletas: action.payload,
      };

    case types.termsAndConditions:
      return {
        ...state,
        ...action.payload,
      };
    case types.loadActivitiesList:
      // console.log("autAct", action.payload)
      return {
        ...state,
        activitiesList: action.payload,
      };
    case types.loadReadings:
      return {
        ...state,
        favoriteList: action.payload,
      };
    case types.addOneFromFavorites: {
      return {
        ...state,
        favoriteList: [...state.favoriteList, action.payload],
      };
    }
    case types.removeOneFromFavorites: {
      // let itemToDelete = state.user.favoriteList.find((item) => item.id === action.payload);
      return {
        ...state,
        favoriteList: [
          ...state.favoriteList?.filter(
            (item) => item.id_lectura !== action.payload
          ),
        ],
      };
    }
    case types.insignias: {
      // console.log("autAct", action.payload)
      return {
        ...state,
        insignias: state.insignias.map((insignia) => {
          return insignia.nombre === action.payload.insignia
            ? { ...insignia, total: insignia.total + 1 }
            : insignia;
        }),
      };
    }
    default:
      return state;
  }
};

export default authReducer;
