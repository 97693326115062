import React, { useContext } from "react";
import { Grid, makeStyles, Button } from "@material-ui/core";
import logoDemo from "../../imgs/CL_LOGO_FINAL_Login 1.png";
import logoPea from "../../imgs/logoPea.png";
import { Link, useHistory } from "react-router-dom";
import AuthContext from "../../../auth/AuthContext";
import types from "../../../types/types";
import axios from "../../../services/axios";
import { Redirect } from "react-router-dom";
import ModalLogin from "./ForgetPassword/Modals/ModalLogin";
import {
  adminInstData,
  adminPearsonData,
  studentData,
  studentDataRegisterByCode,
  teacherData,
} from "../../models/login";
import { dynamicYear } from "../../../utilities/getDynamicYear";
import useLoading from "../../hooks/useLoading";
import {
  backgroundColors,
  lgColors,
  loginBotton,
  txtColors,
} from "../../../constants/loginColors/colors";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100%",
    minHeight: "100vh",
    backgroundColor: (props) => props.randomColor,
    [theme.breakpoints.up("md")]: {
      alignContent: "center",
    },
  },

  ContainerLogin: {
    padding: "0 25px",
    // height: "975px",
    "@media(min-height:1024px)": {
      alignContent: "center",
    },
  },
  ContainerLogoClLeft: {
    display: "flex",
    alignItems: "center",
    // marginBottom: "10%",
    justifyContent: "center",
    [theme.breakpoints.up("xl")]: {
      // marginBottom: "20%",
    },
  },

  LogoClLeft: {
    width: "60%",
    maxWidth: "483px",
    marginTop: "10%",
    [theme.breakpoints.up("md")]: {
      marginTop: "0",
    },
    [theme.breakpoints.up("lg")]: {
      width: "75%",
    },
    [theme.breakpoints.up("xl")]: {
      width: "100%",
    },
  },
  logoP: {
    width: "78px",
    height: "73.5px",
    marginTop: "5%",
    [theme.breakpoints.up("md")]: {
      width: "104px",
      height: "98px",
    },
    [theme.breakpoints.up("xl")]: {
      marginTop: "0%",
    },
  },

  containerContent: {
    flexWrap: "nowrap",
    gap: "15px",
    [theme.breakpoints.up("sm")]: {
      // gap: "15x",
    },
    [theme.breakpoints.up("md")]: {
      gap: "15px",
      maxHeight: "1024px",
      "@media(min-height:1024px)": {
        gap: "45px",
      },
    },

    [theme.breakpoints.up("xl")]: {
      gap: "30px",
      alignContent: "center",
      maxHeight: "1440px",
      "@media(min-height:1024px)": {
        gap: "45px",
      },
    },
  },
  loginText: {
    font: "26px Fredoka One ",
    margin: "0",
    // color: "#F3CD52",
    color: (props) => props.textColor,
    [theme.breakpoints.up("lg")]: {
      fontSize: "48px",
    },
  },

  welcomeText: {
    font: " bold 18px Lato",
    textAlign: "center",
    width: "100%",
    maxWidth: "448px",
    color: (props) => props.smallText,
    [theme.breakpoints.up("xl")]: {
      fontSize: "24px",
    },
  },

  inputLoginUser: {
    width: "80%",
    maxWidth: "320px",
    height: "50px",
    paddingLeft: "27px",
    borderRadius: "61px",
    border: "solid #1D2AAD 3px ",
    background: "#FFFFFF",
    font: "Helvetica",
    fontSize: "16px",
    textAlign: "left",
    "&:focus": {
      outline: "none",
    },
    [theme.breakpoints.up("xl")]: {
      height: "71px",
      maxWidth: "421px",
      fontSize: "18px",
    },
  },
  inputLoginPassword: {
    width: "80%",
    maxWidth: "320px",
    height: "50px",
    paddingLeft: "27px",
    borderRadius: "61px",
    border: "solid #F3CD52 3px ",
    background: "#FFFFFF",
    font: "Helvetica",
    fontSize: "16px",
    "&:focus": {
      outline: "none",
    },
    [theme.breakpoints.up("xl")]: {
      height: "71px",
      maxWidth: "421px",
      fontSize: "18px",
    },
  },
  forgottenPassword: {
    color: (props) => props.smallText,
    font: "16px Lato",
    letterSpacing: "0.36px",
    [theme.breakpoints.up("xl")]: {
      fontSize: "20px",
    },
  },

  inputLoginButton: {
    width: "100%",
    maxWidth: "320px",
    height: "50px",
    borderRadius: "61px",
    border: "none",
    backgroundColor: (props) => props.loginBottonColor,
    color: "#353535",
    font: "bold 16px Lato",
    letterSpacing: "1.96px",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#6FDBED",
      fontWeight: "bold",
    },
    [theme.breakpoints.up("xl")]: {
      height: "71px",
      fontSize: "22px",
    },
  },
  codeAccesButton: {
    textAlign: "center",
    textDecoration: "none",
    textTransform: "none",
    width: "100%",
    maxWidth: "320px",
    height: "50px",
    borderRadius: "61px",
    border: "3px solid #9051b1",
    backgroundColor: "#FFFFFF",
    color: "#9051b1",
    font: "bold 16px Lato",
    letterSpacing: "1.96px",
    lineHeight: "3",
    "&:hover": {
      backgroundColor: "#6FDBED",
      fontWeight: "bold",
    },
    [theme.breakpoints.up("xl")]: {
      height: "71px",
      fontSize: "22px",
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  rightsReserved: {
    font: "14px Lato",
    letterSpacing: "0.53px",
    maxWidth: "80%",
    color: (props) => props.smallText,
    [theme.breakpoints.up("xl")]: {
      fontSize: "18px",
    },
  },
  link: {
    textDecoration: "none",
    textTransform: "none",
  },
}));

const atRandom = parseInt(Math.random() * 6);

const randomColor = backgroundColors[atRandom];
const loginBottonColor = loginBotton[atRandom];
const textColor = lgColors[atRandom];
const smallText = txtColors[atRandom];

const LoginIndex = (props) => {
  const {
    user: { logged, rol },
    dispatch,
  } = useContext(AuthContext);

  const [username, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [error, setError] = React.useState("");

  const [disabled, setDisabled] = React.useState(false);
  const { handleLoading } = useLoading();

  const history = useHistory();

  const classes = useStyles({
    randomColor,
    textColor,
    smallText,
    loginBottonColor,
  });

  const handleUser = (event) => {
    setUsername(event.target.value);
  };

  const handlePassword = (event) => {
    setPassword(event.target.value);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const singInClick = () => {
    handleLoading(true);
    setDisabled(true);
    const body = { nombreUsuario: username, contraseña: password };
    axios
      .post("/session/login", body, {
        headers: {
          "content-type": "application/json",
          "Access-Control-Allow-Origin":
            "http://cdl-pearson.com,https://cdl-pearson.com",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
          "x-origin": "web",
        },
      })
      .then((response) => {
        if (response.status === 200) {
          localStorage.setItem("token", response.data.resultado.token_usuario);
          const data = response.data.resultado;
          // setLogin(true)
          if (data.usuario.rol === "docente") {
            dispatch({
              type: types.login,
              payload: teacherData(data),
            });
            if (
              !data?.usuario?.imagen_perfil &&
              !data?.usuario?.terminos_condiciones
            ) {
              history.push({ pathname: `/docente/avatar` });
            } else {
              history.push({ pathname: `/docente/perfil` });
            }
          } else if (data.usuario.rol === "administrador institucional") {
            dispatch({
              type: types.login,
              payload: adminInstData(data),
            });
            history.push({ pathname: `/admin-inst/perfil` });
          } else if (data.usuario.rol === "administrador pearson") {
            dispatch({
              type: types.login,
              payload: adminPearsonData(data),
            });
            history.push({ pathname: `/admin/perfil` });
          } else {
            if (!data?.codigo?.codigo_alumno_generico) {
              dispatch({
                type: types.login,
                payload: studentData(data),
              });
            } else {
              dispatch({
                type: types.login,
                payload: studentDataRegisterByCode(data),
              });
            }
            history.push({ pathname: `/perfil_del_alumno` });
          }
        }
      })
      .catch((error) => {
        setOpen(true);
        setError(error.response?.data?.detalles?.msg);
        setDisabled(false);
      })
      .finally(() => {
        handleLoading(false);
      });
  };

  const routesToRedirect = {
    docente: "/docente/perfil",
    "administrador institucional": "/admin-inst/perfil",
    "administrador pearson": "/admin/perfil",
    alumno: "/Perfil_del_alumno",
  };

  return (
    <Grid container className={classes.root} justifyContent="center">
      <Grid container item className={classes.ContainerLogin}>
        <Grid
          container
          item
          sm={6}
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item className={classes.ContainerLogoClLeft}>
            <img src={logoDemo} alt="logoDemo" className={classes.LogoClLeft} />
          </Grid>
        </Grid>
        <Grid
          container
          item
          sm={6}
          direction="column"
          justifyContent="space-around"
          alignItems="center"
          className={classes.containerContent}
        >
          <img src={logoPea} alt="logoP" className={classes.logoP} />
          <h1 className={classes.loginText}>¡Bienvenido!</h1>
          <div className={classes.welcomeText}>
            Es un gusto verte.
            <br />
            Escribe tu usuario y contraseña
            <br />
            para entrar a la plataforma.
          </div>
          <input
            type="text"
            placeholder="Usuario"
            value={username}
            onChange={handleUser}
            className={classes.inputLoginUser}
          ></input>
          <input
            type="password"
            placeholder="Contraseña"
            value={password}
            onChange={handlePassword}
            className={classes.inputLoginPassword}
            helpertext="Incorrect entry."
          ></input>
          <Link className={classes.forgottenPassword} to="/password_recovery">
            Olvidé mi contraseña
          </Link>
          <Button
            onClick={singInClick}
            onDoubleClick={singInClick}
            className={classes.inputLoginButton}
            disabled={disabled}
          >
            Iniciar sesión
          </Button>
          <Link className={classes.codeAccesButton} to="/registro">
            Acceder con código
          </Link>
          <p className={classes.rightsReserved}>
            Derechos reservados y legales {dynamicYear} por Pearson
          </p>
        </Grid>
      </Grid>

      {logged && !username && !password && (
        <Redirect to={{ pathname: routesToRedirect?.[rol] }} />
      )}

      {/* {oldSesionData && (
        <>
          {oldSesionData?.rol === "docente" && <Redirect to={{ pathname: `/singin/docente` }} />}
          {oldSesionData?.rol === "administrador institucional" && <Redirect to={{ pathname: `/singin/admin` }} />}
          {oldSesionData?.rol === "alumno" && <Redirect to={{ pathname: `/singin/${oldSesionData?.grade}` }} />}
        </>
      )} */}

      <ModalLogin open={open} error={error} handleClose={handleClose} />
    </Grid>
  );
};

export default LoginIndex;
