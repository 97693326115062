import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
     Contenedor:{
        background:'#E9FBF9',
        width:'100vw',
        overflowX:'clip',
        [theme.breakpoints.down(1161)]:{
            background:'none',
           overflowX:'unset',
        },
        [theme.breakpoints.down(600)]:{
            marginTop: '79.51px',
        }
    },
  ContainerInfoLectura: {
    position: "relative",
    height: "86px",
    [theme.breakpoints.down(502)]:{
        height: "43px",
    },
    background: "#76D4C3",
    "& .BarraInfoLectura": {
      height: "86px",
      background: `Url(https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Barras/Azul.png)`,
        [theme.breakpoints.down(502)]:{
            height: "43px",
        },
    },
    "& .InfoLectura": {
      position: "absolute",
      height: "86px",
      width: "219px",
      boxSizing: "border-box",
      padding: "17.2px 0",
      background: "#FAFAFA",
      borderRadius: "50px",
      boxShadow: "2px 2px 2px 2px #00000029",
      zIndex: "4",
        [theme.breakpoints.down(502)]:{
            height: "43px",
            width: "109px",
            padding: "8.6px 0",
            borderRadius: "25px",
        },
    },
    "& .InfoLectura:before": {
      position: "absolute",
      display: "flex",
      boxSizing: "border-box",
      padding: "0 0 0 10px",
      justifyContent: "center",
      alignItems: "center",
      height: "39.88px",
      width: "39.88px",
      borderRadius: "50%",
      content: '""',
      boxShadow: "3px 0px .5px 0px #00000029",
      background: "#FAFAFA",
      left: "90px",
      top: "65px",
      transform: "rotate(0.25turn)",
      [theme.breakpoints.down(502)]:{
        left: "45px",
        top: "30px",
        height: "20.88px",
        width: "20.88px",
        },
    },
    "& .InfoLectura:after": {
      content: '""',
      height: "15px",
      width: "15px",
      borderTop: "2px solid #20A698",
      borderLeft: "2px solid #20A698",
      position: "absolute",
      bottom: "-10px",
      left: "Calc(50% - 8px)",
      transform: "rotate(0.625turn)",
      [theme.breakpoints.down(502)]:{
        height: "10px",
        width: "10px",
        left: "Calc(50% - 4.5px)",
        bottom: "-3px",
        borderTop: "1px solid #20A698",
        borderLeft: "1px solid #20A698",
        },
    },
    "& .Sesion": {
      borderLeft: "2px solid #D8D8D8",
    },
    "& .Dato": {
      margin: "0 0 5px 0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#20A698",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        margin: "0 0 2.5px 0",
        },
    },
    "& .Caracter": {
      margin: "0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#000000",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        },
    },   },
    ContenedorLectura:{
        background:'#fff',
        width:'1160px',
        height:'100%',
        padding:'60px 168px 35px ',
        [theme.breakpoints.down(1161)]:{
            width:'502px',
            padding:'60px 0 0 0',
          },
        [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
        '& .ContainerDerechos':{
            bottom:'80px',
            [theme.breakpoints.down(1161)]:{
                display:'none',
            },
        },
        '& .Derechos':{
            textAlign:"center",
            margin:"0px",
            font:" 15px lato",
            color:"#717476",
        },
        '& .Escritorio':{
            display:'block',
            [theme.breakpoints.down(1161)]:{
                display:'none'
            },
        },
        '& .Tablet':{
            display:'none',
            [theme.breakpoints.down(1161)]:{
                display:'block'
            },
        }
    },
    ContainerTitulo:{
        margin:'0 0 34px 0',
        [theme.breakpoints.down(1161)]:{
            margin:'0 0 34px 0',
        },
        '& .Titulo':{
            letterSpacing: '0px',
            color: '#ED1C24',
            margin:'0',
            textAlign:'center',
            font:'48px fredoka one',
            lineHeight:'58px',
            [theme.breakpoints.down(1161)]:{
                font:'43px fredoka one',
                lineHeight:'52px',
            },
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            lineHeight : '30px',
            margin:'0 0 36px 0',
            font:'22px lato',
            color:'#000',
            letterSpacing: '0.20px',
            [theme.breakpoints.down(1161)]:{
                font:'18px lato',
                lineHeight :'28px',
                letterSpacing: '0.17px',
            },
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .SubTitulo':{
            color:'#DF5DA7',
            margin:'0',
            font:'bold 30px lato',
            letterSpacing:'.28px',
            lineHeight:'30px',
            [theme.breakpoints.down(1161)]:{
                lineHeight :'28px',
                font:'bold 26px lato',
                letterSpacing: '0.24px',
            },
            [theme.breakpoints.down(502)]:{
                font:'bold 18px lato',
                lineHeight :'24px',
                letterSpacing: '0.21px',
            },
        },
        "& .SubTitulo2": {
            color: "#1C75BC",
            margin: "0",
            letterSpacing: ".24px",
            lineHeight: "28px",
            font: "bold 26px lato",
            [theme.breakpoints.down(1161)]: {
              font: "bold 22px lato",
              lineHeight: "24px",
              letterSpacing: "0.21px",
            },
            [theme.breakpoints.down(502)]: {
              font: "bold 18px lato",
              lineHeight: "20px",
              letterSpacing: "0.16px",
            },
        },

        '& .Imagen':{
            width:'100%',
        },   
        '& .Imagen2':{
            [theme.breakpoints.down(1161)]: {
                width:'74.50%',
              },
        }, 
        '& .Imagen3':{
            [theme.breakpoints.down(1161)]: {
                width:'85.65%',
              },
        }, 
    }
}));


const La_Teoría_de_conjuntos = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
             <Grid container item justifyContent="center" className={classes.ContainerInfoLectura}>
               <Grid container className="BarraInfoLectura"></Grid>
               <Grid container item className="InfoLectura">
                    <Grid container item direction="column" xs={6} justifyContent="center">
                        <p className="Dato">Grado</p>
                        <p className="Caracter">4</p>
                    </Grid>
                    <Grid container item direction="column" xs={6} justifyContent="center" className="Sesion">
                        <p className="Dato">Sesión</p>
                        <p className="Caracter">8</p>
                    </Grid>
               </Grid>
            </Grid>

            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >La Teoría de conjuntos</h2>
                </Grid>
                
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container sm={12}>
                      <p className="Lectura "> 
                        En esta ocasión veremos la <em>Teoría de conjuntos.</em> Esta teoría es una manera de 
                        representar conjuntos y subconjuntos mediante un <em>diagrama</em>.<br/><br/> 
                        
                        Para empezar, pensemos que todos los países del mundo son un mismo conjunto.
                        Ahora, llamaremos Universo a este conjunto y lo representaremos con la letra U
                        mayúscula. La característica que tienen en común todos los elementos del grupo es 
                        que <em>son un país del mundo</em>.<br/><br/>
                        
                        Es posible formar nuevos conjuntos a partir del conjunto Universo (U). Por ejemplo, 
                        podemos agrupar solamente a los países que están en Europa o a los que tienen el 
                        español como lengua oficial. Otro tipo de agrupación podría ser países cuya capital 
                        es Moscú. <br/><br/>
                        
                        Asignaremos una letra a cada grupo para identificarlo fácilmente. Demos una A 
                        mayúscula al conjunto de los países de Europa, una B al conjunto de países con el 
                        español como idioma oficial y una C mayúscula para los países cuya capital es Moscú:<br/><br/>
                        
                        <b>A =</b> &#123;Reino Unido, Portugal, España, Francia, Países Bajos, Alemania, Italia, Grecia, 
                        Suecia, Dinamarca, Bélgica, etcétera&#125; <br/><br/>

                        <b>B =</b> &#123;México, Chile, Argentina, Ecuador, Perú, Bolivia, Colombia, Guatemala, El 
                        Salvador, Nicaragua, Panamá, etcétera&#125; <br/><br/>
                        
                        Por último, usaremos una C mayúscula para los países cuya capital es Moscú:<br/><br/>
                        
                        <b>C =</b> &#123;Rusia&#125; <br/><br/>
                        
                        Usaremos un rectángulo para representar al conjunto Universo. Después, 
                        dibujaremos círculos dentro de él para representar a los otros subconjuntos.
                      </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img4to/Sesion08/1.png" alt="Tarjeta" />
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura "> 
                            Este diagrama solamente representa algunos elementos de los conjuntos U, A, B y C, 
                            pues son muchos nombres y no podríamos escribirlos todos. Los otros países están 
                            fuera de las circunferencias porque el conjunto Universo representa a <em>todos</em> los 
                            países del mundo. Por lo tanto, los demás países siguen estando dentro del 
                            rectángulo.<br/><br/> 
                            
                            Es posible hacer conjuntos más pequeños dentro de nuestro primer conjunto. 
                            Veamos cómo hacerlo. <br/><br/>
                            
                            Hagamos otro conjunto del conjunto B. La mayoría de los países en los que se habla 
                            el español están en el continente americano. Haremos tres conjuntos, ya que América 
                            tiene tres partes geográficas. Usaremos D para los países que estén en América del 
                            Norte y una E para los de Centroamérica. Por último, aquellos que estén en América 
                            del Sur serán nombrados con una F: <br/><br/>
                            
                            <b>D =</b> &#123;México&#124;<br/><br/>
                            
                            <b>E =</b>  &#123;Guatemala, El Salvador, Nicaragua, Panamá, etcétera&#124; <br/><br/>
                            
                            <b>F =</b> &#123;Chile, Argentina, Ecuador, Perú, Bolivia, Colombia, etcétera&#124; <br/><br/>
                            
                            Entonces, éste sería el diagrama del conjunto B y de los subconjuntos D, E y F:
                        </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <img className="Imagen2" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img4to/Sesion08/2.png" alt="Tarjeta" />
                    </Grid>
                </Grid>
                
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container item justifyContent='center' sm={12}>
                        <p className="Lectura "> 
                            <h3 className='SubTitulo'>Diagramas de Venn</h3><br/>
                            Los diagramas que acabas de ver reciben el nombre de <em>diagramas de Venn</em>. Fueron 
                            inventados en 1880 por el matemático y filósofo británico John Venn y sirven para 
                            representar la unión y la intersección de dos o más conjuntos. <br/><br/>
                            
                            <h3 className='SubTitulo2'>Unión</h3><br/>
                            
                            Recordemos que la unión agrupa a dos o más conjuntos para formar uno nuevo. En 
                            los diagramas de Venn, la unión de dos conjuntos se representa de la siguiente 
                            manera:
                        </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <img className="Imagen3" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img4to/Sesion08/3.png" alt="Tarjeta" />
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container item justifyContent='center' sm={12}>
                        <p className="Lectura "> 
                            Se colorean de amarillo los dos conjuntos que se unen. Esto se hace con la finalidad 
                            de indicar que ambos conjuntos forman uno mismo.<br/><br/> 
                            
                            <h3 className='SubTitulo2'>Intersección</h3><br/>

                            Por otro lado, la intersección de dos conjuntos se crea al agrupar solamente los
                            elementos que tengan en común ambos conjuntos. En los diagramas de Venn, la 
                            intersección de dos conjuntos se representa de la siguiente forma:
                        </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <img className="Imagen3" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img4to/Sesion08/4.png" alt="Tarjeta" />
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container item justifyContent='center' sm={12}>
                        <p className="Lectura "> 
                            En este caso, se colorea de amarillo sólo la parte en la que se cruzan los dos 
                            conjuntos. Cuando los conjuntos no comparten ningún elemento, se dice que la 
                            intersección está vacía. <br/><br/>
                            
                            Este tipo de diagramas son útiles para representar gráficamente grupos con ciertas 
                            características. Ayudan a entender mejor las semejanzas y diferencias de cada uno 
                            de los grupos. <br/><br/>
                            
                            Ahora que has visto cómo utilizar los diagramas de Venn, serás capaz de representar 
                            subconjuntos, así como las similitudes y diferencias que guarden entre sí.
                        </p>
                    </Grid>
                </Grid>

                <Grid container item xs={12} justifyContent="center" className="ContainerDerechos">
                    <p className="Derechos">
                        © 2022. Todos los derechos reservados, Pearson, Circuito de lectores.
                    </p>                
                </Grid>
        </Grid>
    </Grid>
    );
}
 
export default La_Teoría_de_conjuntos;
