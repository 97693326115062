import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
    ContenedorLectura:{
        width:'502px',
                  [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
    },
    ContainerTitulo:{
        margin:'0 0 61px 0',
        '& .Titulo':{
            letterSpacing: '0px',
            color: '#F6644B',
            margin:'0',
            textAlign:'center',
            font:'43px fredoka one',
            lineHeight:'52px',
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            margin:'0 0 36px 0 ',
            color:'#000',
            font:'18px lato',
            lineHeight :'28px',
            letterSpacing: '0.17px',
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .Cuadro':{
            position:'relative',
        },
        '& .Esp':{
            margin:' 0 0 36px 0 ',
            padding:'0',
        },
        '& .SubTitulo':{
            color:'#7F6CAA',
            margin:'0',
            lineHeight :'28px',
            font:'bold 26px lato',
            letterSpacing: '0.24px',
            [theme.breakpoints.down(502)]:{
                font:'bold 18px lato',
                lineHeight :'24px',
                letterSpacing: '0.21px',
            },
        },

        '& .Imagen':{
            width:'476px',
            [theme.breakpoints.down(502)]:{
                width:'100%',
            },
        },
        '& .Imagen2':{
            width:'100%',
        },
    },

}));
const Economía_naranja_creativa_y_sostenible = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >
                    Economía naranja: <br/>creativa y sostenible
                    </h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12} className="Cuadro">
                      <p className="Lectura Esp">
                        La <em>economía creativa</em> o 
                        naranja existe desde hace 
                        tiempo. De hecho, es 
                        posible que ya seas parte de 
                        ella sin saberlo. Este 
                        concepto cambió el 
                        consumo en todo el planeta 
                        y probablemente aumentó 
                        tras la pandemia de 
                        Covid-19. Su impacto fue 
                        tan grande que la <span style={{fontVariant: "all-small-caps"}}>ONU </span>
                        designó 2021 como el año 
                        de la economía creativa.
                      </p>
                    </Grid>
                    <Grid container justifyContent="center"  sm={12}>
                        <Grid container justifyContent="center" alignItems='flex-end'>
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img3ro/Sesion15/1R.png" alt="Tarjeta" />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                      <p className="Lectura">
                        <h3 className='SubTitulo'>¿Qué es la economía naranja?</h3><br/>
                        
                        Tiene que ver con las actividades comerciales que se basan en conocimientos e 
                        inteligencia. Sucede cuando un creador transforma sus ideas para ofrecer servicios 
                        relacionados con la cultura. Los creadores son las personas que inventan cosas, como 
                        artistas, diseñadores, escritores, artesanos, cantantes, músicos y bailarines. Este 
                        negocio también está formado por otras industrias, como la publicidad, el diseño, la 
                        investigación científica y los medios de comunicación. <br/><br/>
                        
                        ¿Cómo te imaginas que es este trabajo? Primero, quienes crean piensan en una idea y 
                        la desarrollan poco a poco hasta transformarla en una “pieza”. Después, alguien la 
                        conoce, paga por ella y hace que llegue a más personas. Esta actividad fomenta la 
                        imaginación para que sea novedosa y aporte soluciones sociales.<br/><br/>
                        
                        ¿Cuándo utilizamos los servicios de los creadores? Por ejemplo, cuando jugamos 
                        videojuegos. También cuando pedimos un libro por internet o lo consultamos en una
                        biblioteca digital. Su uso más común en la actualidad es al rentar o comprar 
                        programas de televisión, películas o conciertos en una plataforma en línea. <br/><br/>
                        
                        ¿Sabías que puedes consumir sus productos sin gastar dinero? Por ejemplo, las 
                        bibliotecas son gratuitas y brindan un servicio importante al atender necesidades 
                        educativas y culturales. Probablemente tengan que adaptarse a los cambios de este 
                        siglo, pues están compitiendo contra los sitios de información digital, como el 
                        internet.<br/><br/>
                    
                        Las bibliotecas son importantes y es necesario que cada vez más personas tengan 
                        acceso a la información y conocimiento que hay en los libros. Una de las propuestas 
                        de la economía creativa es ayudar a terminar con las desigualdades en la sociedad.<br/><br/>

                        ¡Ya has aprendido sobre un nuevo tema! Ahora sabes que cuando ayudes a una 
                        persona a estudiar, ¡estarás iniciando nuevas oportunidades para todos!
                      </p>

                     
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <img className="Imagen2" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img3ro/Sesion15/2.png" alt="Tarjeta" />
                    </Grid>
                </Grid>
        </Grid>
    </Grid>
    );
}
 
export default Economía_naranja_creativa_y_sostenible;
