import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
    ContenedorLectura:{
        width:'502px',
                  [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
    },
    ContainerTitulo:{
        margin:'0 0 36px 0',
        '& .Titulo':{
            letterSpacing: '0px',
            color: '#FC28AC',
            margin:'0',
            textAlign:'center',
            font:'43px fredoka one',
            lineHeight:'52px',
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            margin:'0 0 36px 0',
            color:'#000',
            font:'18px lato',
            lineHeight :'28px',
            letterSpacing: '0.17px',
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .SubTitulo':{
            margin:'0 ',
            color:'#9E75FF',
            font:'bold 22px lato',
            lineHeight :'24px',
            letterSpacing: '0.21px',
            [theme.breakpoints.down(502)]:{
                font:'bold 18px lato',
                lineHeight :'20px',
                letterSpacing: '0.16px',
            },
        },
        '& .SubTitulo2':{
            margin:'0 ',
            color:'#F6664D',
            font: "bold 22px lato",
            lineHeight: "24px",
            letterSpacing: "0.21px",
              [theme.breakpoints.down(502)]: {
                font: "bold 18px lato",
                lineHeight: "20px",
                letterSpacing: "0.16px",
              },
        },
        '& .Esp':{
            margin:'0',
        },
        '& .Ul':{
           margin:'0',
           padding:'0 0 0 19px '
        },
        '& .li':{
            color:'#FC5E9E',
            margin:'0 0 8px 0',
            [theme.breakpoints.down(502)]: {
                margin:'0 0 4px 0',
            },
        },
        '& .TextImg':{
            margin:'22px 0 0 0',
            alignSelf:'flex-start',
            color:'#717476',
            font:' 16px lato',
            lineHeight :'25px',
            letterSpacing: '0.15px',
            [theme.breakpoints.down(502)]:{
                font:' 11px lato',
                lineHeight :'14px',
                letterSpacing: '0.10px',
            },
        },
        '& .Imagen':{
            width:'100%',
        },
    },

}));

const La_historia_de_las_palabras = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >
                        La historia<br/> de las palabras
                    </h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                            <h3 className='SubTitulo'>El gran invento</h3><br/>

                             De todos los inventos del ser humano, la palabra es uno de los más asombrosos y 
                             creativos.<br/><br/> 
                             
                             En el transcurso de la historia, las palabras han motivado el desarrollo de la 
                             humanidad y han dejado evidencias de sus culturas. Gracias a ellas podemos decir 
                             muchas cosas. Las palabras son sorprendentes, porque nos ayudan a crear historias, 
                             como las que leemos en los libros o vemos en la televisión. Pueden agradar o 
                             incomodar, según las intenciones con que las digamos. Y también sirven para 
                             transmitir sentimientos.<br/>
                        </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <Grid container >
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img2do/Sesion22/1.png" alt="Tarjeta" />
                        </Grid>
                        <Grid container justifyContent="center">
                            <p className='TextImg'> En América Latina se hablan alrededor de 500 lenguas además del español.</p>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                            <h3 className='SubTitulo2'>Teorías sobre el origen de las palabras</h3><br/>

                            Existen diversas teorías sobre cómo y cuándo surgieron las palabras. A continuación
                            te presentamos algunas:

                        </p>
                        <ul className='Ul'>
                            <li className='li'><p className="Lectura Esp">Algunas religiones consideran que el origen de las palabras es divino, es decir, 
                            que fueron otorgadas por un dios.</p></li>

                            <li className='li'><p className="Lectura Esp">Los <em>antropólogos</em> y <em>filólogos</em> (personas que estudian la historia de las 
                            culturas y de las lenguas, respectivamente) creen que el lenguaje y las palabras surgieron casi a la 
                            par debido a la necesidad de una comunicación clara.</p></li>

                            <li className='li'><p className="Lectura Esp">Hay <em>lingüistas</em> (personas que estudian las lenguas y su uso) que aseguran que hubo 
                            un único idioma antiguo del cual nacieron todos los que conocemos en la actualidad.</p></li>

                            <li className='li'><p className="Lectura Esp">Otros lingüistas sugieren que el lenguaje surgió cuando nuestros antepasados 
                            imitaban ruidos y sonidos de su entorno.</p></li>  
                        </ul>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                            Las sociedades serían muy distintas sin el uso de la palabra: ya no tendrían una 
                            comunicación efectiva ni las herramientas para crear arte o para pensar.
                        </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <Grid container >
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img2do/Sesion22/2.png" alt="Tarjeta" />
                        </Grid>
                        <Grid container >
                            <p className='TextImg'> 
                                Una manera de preservar y difundir la palabra es a través de la oralidad, que es la forma
                                 original y más antigua de narrar. Así se transmiten conocimientos e historias de generación en 
                                generación.
                            </p>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                            Para explicarlo de otro modo, piensa en las materias que llevas en la escuela y en lo 
                            que haces en casa o con tus amistades. ¿Cuál de esas actividades podría existir si no 
                            contáramos con las palabras?<br/><br/> 
                            
                            Cada idioma tiene su propio alfabeto y todos están en constante evolución, aunque a 
                            veces no se note. Así como evolucionamos nosotros y la tecnología, la lengua 
                            también se adapta a cada época. Gracias a eso existe la diversidad de idiomas.<br/><br/> 
                            
                            ¿Cuáles de las palabras que conoces crees que no se utilizaban hace 10 años? Piensa 
                            en las palabras relacionadas con la tecnología y las redes sociales. ¿Cuántas son? 
                            Pregunta a un adulto si existían cuando tenía tu edad.
                        </p>
                    </Grid>
                </Grid>
        </Grid>
    </Grid>
    );
}
 
export default La_historia_de_las_palabras;
