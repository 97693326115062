import React               from "react";
import {Grid, makeStyles}                 from '@material-ui/core';

const useStyles = makeStyles(theme =>({
  questionContainer:{
    width: "100%",
    height:"100%",
  },
  reading: {
    width: "98%",
    font: "22px lato",
    letterSpacing:'0',
    margin:"14px 10px 14px 0",
    [theme.breakpoints.down(1370)]:{
      font: "500 18px lato",
      width: "90%",
      margin:"10px 10px 10px 0"
    },
    [theme.breakpoints.down(1310)]:{
      font: "500 16px lato",
      // width: "80%",
      margin:"10px 5px 10px 0"
    },
    [theme.breakpoints.down(925)]:{
      font: "500 14px lato",
      // width: "70%",
      margin:"6px 0 6px 0"
    },
    [theme.breakpoints.down(426)]:{
      font: "500 12px lato",
      // width: "70%",
      margin:"6px 0 6px 5%"
    },
  },
  imgStyle: {
    alignItems: "center",
    width:'90%',
    height:'90%',
    // [theme.breakpoints.down(1400)]:{
    //   width:'90%',
    //   height:'90%',
    //   [theme.breakpoints.down(1000)]:{
    //     width:'80%',
    //     height:'80%',
    //   },
    // },
  },
}));

const Question = (props) => {
  const classes = useStyles()
  const { picture1 } = props


  return(
    <Grid container justifyContent="center" alignItems="center" className={classes.questionContainer}>
      <p 
        // dangerouslySetInnerHTML={{__html: `${step+1}. ${pregunta.replace( '\n', '<br/><br/>')}`}}
        className={classes.reading}
      >
        5. El gato encuentra a muchos personajes que le muestran que no es tan único como creía.
        ¿En qué orden hablan con el gato? Selecciona la secuencia que tenga el orden correcto. 
        Toma en cuenta que faltan algunos personajes.
      </p>
      <br/>
      <img alt="img" src={picture1} className={classes.imgStyle}/>
      
    </Grid>
  )

}

export default Question;
