import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
    ContenedorLectura:{
        width:'502px',
                  [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
    },
    ContainerTitulo:{
        margin:'0 0 34px 0',
        '& .Titulo':{
            letterSpacing: '0px',
            color: '#0CB5D6',
            margin:'0',
            textAlign:'center',
            font:'43px fredoka one',
            lineHeight:'52px',
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            margin:'0 0 36px 0',
            color:'#000',
            font:'18px lato',
            lineHeight :'28px',
            letterSpacing: '0.17px',
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .TextImg':{
            color:'#717476',
            margin:'24px 0 0 0',
            font:' 16px lato',
            lineHeight :'25px',
            letterSpacing: '0.15px',
            [theme.breakpoints.down(502)]:{
                font:' 11px lato',
                lineHeight :'14px',
                letterSpacing: '0.10px',
            },
        },
        '& .TextImg2':{
            margin:'0',

        },

        '& .Imagen':{
            width:'100%',
        }, 
        '& .Imagen2':{
            width:'100%',
            height:'385px',
            [theme.breakpoints.down(502)]:{
                height:'235px'
            },
        },
    }
}));

const Las_emociones_en_el_arte = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >Las emociones<br/> en el arte</h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                      <p className="Lectura">
                        Las emociones pueden considerarse la base del arte. Cuadros, películas y esculturas 
                        nos transmiten los sentimientos de los artistas que las realizan. Por ejemplo, si un 
                        escultor se sentía triste o emocionado, es muy probable que su obra nos haga sentir 
                        así.<br/><br/>
                        
                        Para ayudarnos a comprender esto, pensemos lo siguiente. Una piedra o un bote 
                        de pintura no son arte en sí mismos. El arte sucede cuando un pintor usa esos materiales 
                        para crear escenas o figuras que le den forma a lo que él siente en ese momento.<br/><br/>

                        Para notarlo, basta con ver una pintura como El <em>grito</em>, en la que la figura central está 
                        gritando y haciendo una mueca de miedo o desesperación. No hay forma de 
                        equivocarnos acerca de los sentimientos que la obra transmite. Lo mismo sucede con 
                        <em> Los lirios acuáticos</em>, en la cual la tranquilidad de los colores y los trazos imita 
                        un movimiento suave que nos provoca sensaciones de paz y armonía.
                      </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <Grid container justifyContent="center" >
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img1ro/Sesion21/1.png" alt="Tarjeta" />
                        </Grid>
                        <Grid container justifyContent="center" >
                            <p className="TextImg">
                                <em>El grito</em> es un cuadro del pintor Edvard Munch.
                            </p>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container justifyContent="center" sm={12}>
                        <Grid container justifyContent="center" >
                            <img className="Imagen2" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img1ro/Sesion21/2.png" alt="Tarjeta" />
                        </Grid>
                        <Grid container justifyContent="center" >
                            <p className="TextImg ">
                                Claude Monet pintó <em>Los lirios acuáticos</em> en el jardín de su casa, en Francia.
                            </p>
                        </Grid>
                    </Grid>
                   
                </Grid>  
            <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                <p className="Lectura">
                    Así que la próxima vez que te encuentres frente a una obra de arte reflexiona sobre 
                    cuál es la emoción que te transmite.
                </p>
            </Grid>  
        </Grid>
    </Grid>
    );
}
 
export default Las_emociones_en_el_arte;
