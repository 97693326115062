import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
    ContenedorLectura:{
        width:'502px',
                  [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
    },
    ContainerTitulo:{
        margin:'0 0 34px 0',
        '& .Titulo':{
            letterSpacing: '0px',
            color: '#F6644B',
            margin:'0',
            textAlign:'center',
            font:'43px fredoka one',
            lineHeight:'52px',
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            margin:'0 0 36px 0',
            color:'#000',
            font:'18px lato',
            lineHeight :'28px',
            letterSpacing: '0.17px',
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .SubTitulo':{
            color:'#591AAF',
            margin:'0',
            lineHeight :'28px',
            font:'bold 26px lato',
            letterSpacing: '0.24px',
            [theme.breakpoints.down(502)]:{
                font:'bold 18px lato',
                lineHeight :'24px',
                letterSpacing: '0.21px',
            },
        },
        '& .TextImg':{
            color:'#717476',
            margin:'21px 0 0 0',
            font:' 16px lato',
            lineHeight :'25px',
            letterSpacing: '0.15px',
            [theme.breakpoints.down(502)]:{
                font:' 11px lato',
                lineHeight :'14px',
                letterSpacing: '0.10px',
            },
        },
        '& .Imagen':{
            width:'100%',
        },  

      
    }
}));


const Lo_que_nuestros_ojos_no_pueden_ver_la_utilidad_del_microscopio = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >Lo que nuestros ojos no<br/> pueden ver, la utilidad <br/>del microscopio</h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                      <p className="Lectura">
                        <h3 className='SubTitulo'>Las características del ojo humano</h3><br/>
                        
                        Para todos los seres vivos es importante obtener información del medio que los 
                        rodea para sobrevivir. La mayoría de esa información la descubres gracias a tus ojos. 
                        Ellos están bien desarrollados, ya que pueden distinguir casi un millón de colores, 
                        aunque hay animales que ven mucho mejor que los humanos, como los búhos ¡que 
                        ven hasta 10 veces mejor!<br/><br/> 
                        
                        Hay cosas que no puedes ver, y que los mosquitos y las mariposas sí, como la luz 
                        infrarroja o los rayos ultravioleta. También existen objetos demasiado grandes o 
                        demasiado pequeños, difíciles de identificar a simple vista. Sobre la piel de un adulto 
                        y dentro de su cuerpo viven entre dos y tres kilogramos de bacterias. El número de 
                        bacterias que hay en el cuerpo humano puede superar al número de células. <br/><br/>
                        
                        <h3 className='SubTitulo'>La invención del microscopio</h3><br/>
                        
                        Los seres humanos supieron de la existencia de las bacterias hasta hace 300 años. Un 
                        vendedor de telas en Holanda quiso ver si las telas que tenía eran de buena calidad, 
                        entonces decidió raspar unos vidrios y ver los hilos a través de ellos. Los hilos 
                        pudieron verse más grandes gracias al raspado y él los observó mejor. Después de 
                        eso tuvo la idea de ver agua de lago con los vidrios, para saber si estaba limpia. 
                        Cuando lo hizo, vio pequeños seres moviéndose rápidamente en las gotas de agua y 
                        los llamó <em>animálculos</em>. <br/><br/>
                        
                        La invención del microscopio ayudó a conocer más acerca de los seres vivos. En un 
                        inicio los microscopios estaban construidos por una sola lente y funcionaban como 
                        las lupas; estos son considerados microscopios simples. Después, se construyeron 
                        microscopios en los que se usaron dos o más lentes y así se pudieron ver mejor las 
                        cosas, por esto se llamaron <em>microscopios compuestos</em>.
                      </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <Grid container justifyContent="center" sm={12}>
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img4to/Sesion04/1.png" alt="Tarjeta" />
                        </Grid>
                        <Grid container justifyContent="center" sm={12}>
                            <p className='TextImg'>
                                Hans y Zacharias Jensen fueron quienes pulieron las primeras lentes, pero se reconoce como 
                                inventor del microscopio al holandés Anton van Leeuwenhoek. La palabra <em>microscopio</em> viene 
                                del griego <em>micros</em>, que significa “pequeño”, y <em>skopein</em>, que quiere decir “observar”. Se denominó 
                                <em> microorganismos</em> a los pequeños seres vivos que se descubrieron gracias al microscopio.
                            </p>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                      <p className="Lectura">
                        La observación de los organismos microscópicos ha mejorado conforme han 
                        mejorado los microscopios. En la actualidad, los microscopios electrónicos modernos 
                        son más potentes y han permitido conocer a los virus, que son cientos o miles de 
                        veces más pequeños que las bacterias.<br/><br/> 
                        
                        <h3 className='SubTitulo'>Diversidad dentro de los microorganismos</h3><br/> 
                        
                        El grupo de los microorganismos incluye a las bacterias, algunos hongos, algas y 
                        protozoarios. Muchos de ellos viven en tu cuerpo y te ayudan a hacer varias 
                        funciones, como digerir lácteos y producir vitaminas. Otras bacterias, hongos y 
                        protozoarios pueden causar enfermedades, pero no todo es negativo, ya que el ser 
                        humano ha encontrado la forma de usarlos en su beneficio y de utilizarlos para 
                        elaborar algunos alimentos, como el pan, el queso y los yogurts.
                      </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <Grid container justifyContent="center" sm={12}>
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img4to/Sesion04/2.png" alt="Tarjeta" />
                        </Grid>
                        <Grid container justifyContent="center" sm={12}>
                            <p className='TextImg'>
                                Los microorganismos están en todas partes y cumplen diversas funciones en el ambiente. Se 
                                ha comprobado que pueden vivir en lugares sumamente fríos como los icebergs, en agua 
                                salada, en las profundidades del océano e incluso en los reactores nucleares.
                            </p>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                      <p className="Lectura">
                        Ahora ya conoces un poco más sobre la vida microscópica que hay a tu alrededor y 
                        sabes que existen microorganismos, aunque no puedas verlos a simple vista. Sabes 
                        también que gracias a la invención del microscopio se conoce que participan en 
                        muchos procesos esenciales dentro del cuerpo humano, así como en el medio
                        ambiente.
                      </p>
                    </Grid>
                </Grid>
        </Grid>
    </Grid>
    );
}
 
export default Lo_que_nuestros_ojos_no_pueden_ver_la_utilidad_del_microscopio;
