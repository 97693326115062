import  React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { makeStyles } from "@material-ui/core";
import UsabilityContext from "../../usability/UsabilityContext";
import { useEffect } from "react";
import EmocionCell from "./EmocionesCell";

const useStyles = makeStyles((theme) => ({
  emotionsContainer: {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    padding: "20px 0",
    gridTemplateRows: "1fr 1fr 1fr",
    justifyContent: "end",
    alignItems: "center",
    width: "100%",
    boxSizing: "border-box",
    [theme.breakpoints.up("sm")]: {
      gridTemplateColumns: "repeat(3, 1fr)",
      gridTemplateRows: "1fr 1fr",
    },
    [theme.breakpoints.up("lg")]: {
      gridTemplateColumns: "repeat(6, 1fr)",
      gridTemplateRows: "1fr",
    },
  },
  emotionItemContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    // padding: "0 20px",
    boxSizing: "border-box",
    width: "100%",
    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
    },
    [theme.breakpoints.up("lg")]: {
      justifyContent: "flex-end",
    },
  },
  emotion: {
    width: "40px",
    height: "40px",
    objectFit: "cover",
  },

  emotionText: {
    font: "18px lato",
    margin: " 0px",
    letterSpacing: "0.21px",
  },

  table: {
    height: 580,
    width: "100%",
    "& .MuiDataGrid-cell--textLeft.MuiDataGrid-cell": {
      overflow: "visible",
      "&$expanded": {
        overflow: "visible",
      },
    },
    "& .MuiDataGrid-cell": {
      overflowX: "visible",
      borderBottom: "1px solid rgba(224, 224, 224, 1)",
      "&$expanded": {
        overflow: "visible",
      },
    },
  },
}));

const defaultColumnsArray = [
  2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22,
  23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41,
  42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59,
];

// const lista = defaultColumnsArray.reduce(function (target, key, index) {
//   target[key?.sesion] = {
//     field: key.sesion,
//     headerName: `S${key?.sesion}`,
//     width: 100,
//     editable: false,
//     renderCell: (params) => (
//       <>
//       span
//         <Button color="primary" size="small">
//           {key?.inicio}
//         </Button>
//         <Button color="primary" size="small">
//           {key?.fin}
//         </Button>
//       </>
//     ),
//   };
//   return target;
// }, {});

const TablaSocioEmocional = (props) => {
  const { search } = React.useContext(UsabilityContext);
  const { usersGraph8 } = props;
  // const { usersGraph8, downloadData } = props;
  const [rows, setRows] = React.useState([]);

  const classes = useStyles();
  const sesionesHeaders = defaultColumnsArray.map((el) => {
    return {
      field: el,
      headerName: `Sesión ${el}`,
      width: 120,
      editable: false,
      renderCell: (params) => (
        <>
          <EmocionCell
            row={params.row?.[el]}
            name={`${params?.row?.name} ${params.row?.lastName
              ?.charAt(0)
              ?.toUpperCase()}.`}
          />
        </>
      ),
    };
  });

  function getFullName(params) {
    return `${params.row.name || ""} ${params.row.lastName || ""} ${
      params.row.secondLastName || ""
    }  `;
  }
  const columns = [
    {
      field: "name",
      headerName: "Nombre del estudiante",
      width: 300,
      editable: false,
      valueGetter: getFullName,
    },
    ...sesionesHeaders,
  ];

  useEffect(() => {
    const data = {
      alumnos: usersGraph8.map((alumno) => {
        return {
          id_usuario: alumno.id_usuario,
          nombre: alumno.nombre,
          apellidoPaterno: alumno.apellidoPaterno,
          apellidoMaterno: alumno.apellidoMaterno,
          grado: alumno.grado,
          grupo: alumno.grupo,
          bloque: alumno.bloque.map((item) => {
            return {
              bloque: item.bloque,
              sesion: item.sesion,
              sentimientoInicio: item.sentimientoInicio,
              sentimientoFin: item.sentimientoFin,
            };
          }),
        };
      }),
    };
    const dataRow = data.alumnos.map((user) => {
      return {
        name: user?.nombre,
        lastName: user?.apellidoPaterno,
        secondLastName: user?.apellidoMaterno,
        id: user?.id_usuario,
        ...user?.bloque?.reduce(function (target, key) {
          target[key?.sesion] = key;
          return target;
        }, {}),
      };
    });

    setRows(dataRow);

    return () => {};
  }, [usersGraph8]);

  return (
    <>
      {/* <div>
        <img src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Moods/Triste.png" className="entusiasmado"/>
        <p>"Entusiasmado"</p>
      </div> */}
      <div className={classes.emotionsContainer}>
        <article className={classes.emotionItemContainer}>
          <img
            src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Moods/Triste.png"
            alt=""
            className={classes.emotion}
          />
          <h6 className={classes.emotionText}>Triste</h6>
        </article>

        <article className={classes.emotionItemContainer}>
          <img
            src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Moods/Miedo.png"
            alt=""
            className={classes.emotion}
          />
          <h6 className={classes.emotionText}>Miedoso</h6>
        </article>

        <article className={classes.emotionItemContainer}>
          <img
            src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Moods/Enojado.png"
            alt=""
            className={classes.emotion}
          />
          <h6 className={classes.emotionText}>Enojado</h6>
        </article>

        <article className={classes.emotionItemContainer}>
          <img
            src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Moods/Entusiasmado.png"
            alt=""
            className={classes.emotion}
          />
          <h6 className={classes.emotionText}>Entusiasmado</h6>
        </article>
        
        <article className={classes.emotionItemContainer}>
          <img
            src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Moods/Feliz.png"
            alt=""
            className={classes.emotion}
          />
          <h6 className={classes.emotionText}>Feliz</h6>
        </article>

        <article className={classes.emotionItemContainer}>
          <img
            src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Moods/Calmado.png"
            alt=""
            className={classes.emotion}
          />
          <h6 className={classes.emotionText}>Calmado</h6>
        </article>
      </div>

      <DataGrid
        className={classes.table}
        autoHeight={true}
        rows={rows?.filter((row) => {
          if (search) {
            return row?.name?.toLowerCase().startsWith(search.toLowerCase());
          } else {
            return row;
          }
        })}
        columns={columns}
        disableSelectionOnClick
        disableColumnFilter
        disableColumnMenu
        rowsPerPageOptions={[10, 20, 30]}
        pageSize={10}
        // initialState={{
        //   pinnedColumns: { left: ['name'] },
        // }}
      />
    </>
  );
};

export default TablaSocioEmocional;
