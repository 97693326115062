import { Button, Grid, makeStyles } from "@material-ui/core";
import React, { useContext, useEffect } from "react";
import DrawerContext from "../../../../../usability/DrawerContext";
import { useOnLoadImages } from "../../../../hooks/useOnLoadImages";
import Loader from "../../../../Loader/Loader";
import Drawer from "../../ReadingTools/Drawer";

const useStyles =  makeStyles (theme =>({
     Contenedor:{
        background:'#E9FBF9',
        width:'100vw',
        overflowX:'clip',
        [theme.breakpoints.down(1161)]:{
            background:'none',
           overflowX:'unset',
        },
        [theme.breakpoints.down(600)]:{
            marginTop: '79.51px',
        }
    },
    ContainerInfoLectura:{
        position:'relative',
        height:'86px',
        background:'#817FD9',
        '& .BarraInfoLectura':{
            height:'86px',
            background:`Url(https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Barras/Morada.png)`,
        },
        '& .InfoLectura':{
            position:'absolute',
            height:'86px',
            width:'219px',
            boxSizing:'border-box',
            padding:'17.2px 0',
            background:'#FAFAFA',
            borderRadius:'50px',
            boxShadow: '2px 2px 2px 2px #00000029',
            zIndex:'4',
        },
        '& .InfoLectura:before':{
            position:'absolute',
            display:'flex',
            boxSizing:'border-box',
            padding:'0 0 0 10px',
            justifyContent:'center',
            alignItems:'center',
            height:'39.88px',
            width:'39.88px',
            borderRadius:'50%',
            content:'""',
            boxShadow: '3px 0px .5px 0px #00000029',
            background:'#FAFAFA',
            left:'90px',
            top:'65px',
            transform: 'rotate(0.25turn)',
        },
        '& .InfoLectura:after':{
            content:'""',
            height:'15px',
            width:'15px',
            borderTop:'2px solid #817FD9',
            borderLeft:'2px solid #817FD9',
            position:'absolute',
            bottom:'-10px',
            left:'Calc(50% - 8px)',
            transform: 'rotate(0.625turn)',
        },
        '& .Sesion':{
            borderLeft:'2px solid #D8D8D8',
        },
        '& .Dato':{
            margin:'0 0 5px 0',
            textAlign:'center',
            font:'bold 17px lato',
            color:'#817FD9',
        },
        '& .Caracter':{
            margin:'0',
            textAlign:'center',
            font:'bold 17px lato',
            color:'#000000',
        },
        '& .Regresar':{
            color: '#F2624F',
            border: '#F2624F 1px solid',
            height: '30px',
            width:'100px',
            borderRadius: '20px',
            letterSpacing: '0.14px',
            textTransform: 'none',
            background:'#FEFAF1',
            top:'calc(50% - 15px)',
            left:'5%',
            zIndex:4,
            [theme.breakpoints.down(502)]: {
              font:'bold 10px lato',
              width: "60px",
            },
          },
    },
    ContenedorLectura:{
        background:'#fff',
        width:'1160px',
        height:'100%',
        padding:'60px 168px 35px ',
        
        transform: (props) =>
        props.scale > 1 ? `scale(${props.scale}) translate(0px, 10%)` : `none`,
        
        transform: (props) =>
        props.scale > 1 ? `scale(${props.scale}) translate(0px, 10%)` : `none`,
        [theme.breakpoints.down(1161)]:{
            width:'502px',
            padding:'60px 0 0 0',
          },
        [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },

        '& .Escritorio':{
            display:'block',
            [theme.breakpoints.down(1161)]:{
                display:'none'
            },
        },
        '& .Tablet':{
            display:'none',
            [theme.breakpoints.down(1161)]:{
                display:'block'
            },
        }
    },
    ContainerTitulo:{
        margin:'0 0 39px 0',
        '& .Titulo':{
            letterSpacing: '0px',
            color: '#0061E5',
            margin:'0',
            textAlign:'center',
            font:'48px fredoka one',

            [theme.breakpoints.down(1161)]:{
                font:'43px fredoka one',
                lineHeight:'52px',
            },
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            margin:'0 0 36px 0 ',
            color:'#000',
            font:'22px lato',
            letterSpacing: '0.20px',
            lineHeight : '30px',
            [theme.breakpoints.down(1161)]:{
                font:'18px lato',
                lineHeight :'28px',
                letterSpacing: '0.17px',
            },
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .SubTitulo':{
            color:'#00ADB6',
            margin:'0',
            font:'bold 30px lato',
            letterSpacing:'.28px',
            lineHeight:'30px',
            [theme.breakpoints.down(1161)]:{
                font:'bold 22px lato',
                lineHeight :'24px',
                letterSpacing: '0.21px',
            },
            [theme.breakpoints.down(502)]:{
                font:'bold 18px lato',
                lineHeight :'20px',
                letterSpacing: '0.16px',
            },
        },
        '& .Imagen':{
            width:'100%',
        }

    },

}));

const Los_derechos_de_los_consumidores = (props) => {


  const {activeStep, setActiveStep, handlePreviewStep, id_actividad} = props
  
  const handleContinue = () => {
    setActiveStep(activeStep - 1)
    handlePreviewStep()
  }
    
    const {
      handleSize,
      disabledZoom,
      closeZoom,
      handleZoom,
     scale
    } = useContext(DrawerContext);
    const [loading, setLoading] = React.useState(true);
    const wrapperRef = React.useRef(null);
    const imagesLoaded = useOnLoadImages(wrapperRef);
  
    const detectSize = () => {
      const lectura = document.getElementById("lectura");
      const newWidth = lectura?.offsetWidth;
      const newHeight = lectura?.offsetHeight;
      setLoading(false);
      if (lectura) handleSize(newHeight, newWidth);
    };
    useEffect(() => {
      window.addEventListener("resize", detectSize);
      return () => {
        handleZoom()
        window.removeEventListener("resize", detectSize);
        closeZoom()
      };
    }, []);
  
    const classes = useStyles({ disabledZoom,scale });

    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
             <Grid container item justifyContent="center" className={classes.ContainerInfoLectura}>
                <Grid container className="BarraInfoLectura">
                    <Button className="Regresar" onClick={handleContinue}>
                        Regresar
                    </Button>
                </Grid>
               <Grid container item className="InfoLectura">
                    <Grid container item direction="column" xs={6} justifyContent="center">
                        <p className="Dato">Grado</p>
                        <p className="Caracter">3</p>
                    </Grid>
                    <Grid container item direction="column" xs={6} justifyContent="center" className="Sesion">
                        <p className="Dato">Sesión</p>
                        <p className="Caracter">38</p>
                    </Grid>
               </Grid>
            </Grid>
            {!imagesLoaded && <Loader />}
        <Grid
            container
            item
            className={classes.ContenedorLectura}
            justifyContent="center"
            id="lectura"
            onLoad={detectSize}
            ref={wrapperRef}
        >
<Drawer id_lectura={id_actividad} />
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >
                        Los derechos de los<br className='Tablet'/> consumidores                    
                    </h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                            Se les llama consumidores a las personas que adquieren bienes o servicios de 
                            fabricantes para satisfacer alguna necesidad humana y compran productos como 
                            comida, ropa, juguetes, entradas para eventos o medicamentos, entre otros. Detrás 
                            de cada artículo que posees hubo un proceso de vigilancia y cuidado, pues es 
                            importante que se respeten ciertas normas que aseguren que éstos tengan calidad.<br/><br/> 
                            
                            ¿Sabías que también cuentas con derechos como consumidor? En 1973 se redactó la 
                            Carta Europea de Protección de los Consumidores. Se trata del primer documento 
                            en la historia que reconoció estos derechos. Se han establecido diferentes leyes y 
                            normas jurídicas en la mayoría de los países a partir de él. Por lo tanto, es un escrito 
                            importante en muchas partes del mundo. A continuación se explican algunos
                            derechos.<br/><br/>
                            
                            El primero es que los clientes merecen obtener un precio justo y razonable por los 
                            artículos que adquieran. Por eso se sanciona a las instituciones o tiendas que venden 
                            productos más caros de lo normal sin justificación alguna. El segundo consiste en 
                            garantizar que éstos no causen daño de ninguna manera al individuo que los utilice. 
                            Para ello son importantes los exámenes de calidad, ya que es riesgoso vender algo 
                            que puede atentar contra la salud o la integridad de la gente. <br/><br/>
                            
                            El tercero es que se tiene que recibir la información necesaria sobre el objeto, desde 
                            los materiales o ingredientes con los que está elaborado, hasta las indicaciones sobre 
                            sus funciones, pues toda esta información contribuye a generar una mejor 
                            experiencia de uso. Imagina que tienes un juguete y no sabes cómo usarlo. Sería 
                            aburrido, ¿cierto? <br/><br/>
                            
                            Por último, este ejemplo de derecho es colectivo. Las personas tienen la oportunidad
                            de organizarse y formar grupos para hacer valer estas leyes y luchar contra quienes 
                            las incumplan. Si sufren injusticias o compran objetos que no cuentan con todo lo 
                            anteriormente descrito, pueden buscar ayuda y hacerse escuchar.
                        </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                            <img className='Imagen' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img3ro/Sesion38/1.png"  alt="Tarjeta" />
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                           <h3 className='SubTitulo'>¿Qué pasa con los niños?</h3><br/>
                           
                            Los niños y las niñas también son consumidores porque usan productos y servicios, 
                            aunque la mayoría de las veces no sean quienes paguen por ellos. Por ejemplo, 
                            utilizan la televisión, el internet, los alimentos y los juguetes. Gozan de las mismas 
                            oportunidades que los adultos, pero son más vulnerables que ellos porque tienen 
                            menor acceso a la información. Por eso las leyes son más especiales con su seguridad.<br/><br/> 
                            
                            Ahora ya conoces algunos de los derechos que posees al hacer tus compras. ¿Alguna 
                            vez imaginaste que la ley te protege cuando lo haces? ¡Pues así es!
                        </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                            <img className='Imagen' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img3ro/Sesion38/2.png"  alt="Tarjeta" />
                    </Grid>
                </Grid>
        </Grid>
        <Grid container item style={{ marginTop: 80}} ></Grid>
    </Grid>
    );
}
 
export default Los_derechos_de_los_consumidores;
