import { Button, Grid, makeStyles } from "@material-ui/core";
import React, { useContext, useEffect } from "react";
import DrawerContext from "../../../../../usability/DrawerContext";
import { useOnLoadImages } from "../../../../hooks/useOnLoadImages";
import Loader from "../../../../Loader/Loader";
import Drawer from "../../ReadingTools/Drawer";

const useStyles =  makeStyles (theme =>({
     Contenedor:{
        background:'#E9FBF9',
        width:'100vw',
        overflowX:'clip',
        [theme.breakpoints.down(1280)]:{
            background:'none',
        },
        [theme.breakpoints.down(600)]:{
            marginTop: '79.51px',
        }
    },
  ContainerInfoLectura: {
    position: "relative",
    height: "86px",
    [theme.breakpoints.down(502)]:{
        height: "43px",
    },
    background: "#76D4C3",
    "& .BarraInfoLectura": {
      height: "86px",
      background: `Url(https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Barras/Azul.png)`,
        [theme.breakpoints.down(502)]:{
            height: "43px",
        },
    },
    "& .InfoLectura": {
      position: "absolute",
      height: "86px",
      width: "219px",
      boxSizing: "border-box",
      padding: "17.2px 0",
      background: "#FAFAFA",
      borderRadius: "50px",
      boxShadow: "2px 2px 2px 2px #00000029",
      zIndex: "4",
        [theme.breakpoints.down(502)]:{
            height: "43px",
            width: "109px",
            padding: "8.6px 0",
            borderRadius: "25px",
        },
    },
    "& .InfoLectura:before": {
      position: "absolute",
      display: "flex",
      boxSizing: "border-box",
      padding: "0 0 0 10px",
      justifyContent: "center",
      alignItems: "center",
      height: "39.88px",
      width: "39.88px",
      borderRadius: "50%",
      content: '""',
      boxShadow: "3px 0px .5px 0px #00000029",
      background: "#FAFAFA",
      left: "90px",
      top: "65px",
      transform: "rotate(0.25turn)",
      [theme.breakpoints.down(502)]:{
        left: "45px",
        top: "30px",
        height: "20.88px",
        width: "20.88px",
        },
    },
    "& .InfoLectura:after": {
      content: '""',
      height: "15px",
      width: "15px",
      borderTop: "2px solid #20A698",
      borderLeft: "2px solid #20A698",
      position: "absolute",
      bottom: "-10px",
      left: "Calc(50% - 8px)",
      transform: "rotate(0.625turn)",
      [theme.breakpoints.down(502)]:{
        height: "10px",
        width: "10px",
        left: "Calc(50% - 4.5px)",
        bottom: "-3px",
        borderTop: "1px solid #20A698",
        borderLeft: "1px solid #20A698",
        },
    },
    "& .Sesion": {
      borderLeft: "2px solid #D8D8D8",
    },
    "& .Dato": {
      margin: "0 0 5px 0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#20A698",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        margin: "0 0 2.5px 0",
        },
    },
    "& .Caracter": {
      margin: "0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#000000",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        },
    },
       '& .Regresar':{
        color: '#F2624F',
        border: '#F2624F 1px solid',
        height: '30px',
        width:'100px',
        borderRadius: '20px',
        letterSpacing: '0.14px',
        textTransform: 'none',
        background:'#FEFAF1',
        top:'calc(50% - 15px)',
        left:'5%',
        zIndex:4,
        [theme.breakpoints.down(502)]: {
          font:'bold 10px lato',
          width: "60px",
        },
      },
  },
    ContenedorLectura:{
        background:'#fff',
        width:'1160px',
        height:'100%',
        padding:'60px 168px 35px ',
        
        transform: (props) =>
        props.scale > 1 ? `scale(${props.scale}) translate(0px, 10%)` : `none`,
        [theme.breakpoints.down(1280)]:{
            width:'502px',
            padding:'60px 0 0 0',
          },
        [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
        '& .ContainerDerechos':{
            bottom:'80px',
            [theme.breakpoints.down(1280)]:{
                display:'none',
            },
        },
        '& .Derechos':{
            textAlign:"center",
            margin:"0px",
            font:" 15px lato",
            color:"#717476",
        },
        '& .Escritorio':{
            display:'block',
            [theme.breakpoints.down(1280)]:{
                display:'none'
            },
        },
        '& .Tablet':{
            display:'none',
            [theme.breakpoints.down(1280)]:{
                display:'block'
            },
        }
    },
    ContainerTitulo:{
        margin:'0 0 34px 0',
        [theme.breakpoints.down(1280)]:{
            margin:'0 0 34px 0',
        },
        '& .Titulo':{
            letterSpacing: '0px',
            color: '#088857',
            margin:'0',
            textAlign:'center',
            font:'48px fredoka one',
            lineHeight:'58px',
            [theme.breakpoints.down(1280)]:{
                font:'43px fredoka one',
                lineHeight:'52px',
            },
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            margin:'0 0 36px 0',
            color:'#000',
            font:'22px lato',
            lineHeight : '30px',
            letterSpacing: '0.12px',
            [theme.breakpoints.down(1280)]:{
                font:'18px lato',
                lineHeight :'28px',
                letterSpacing: '0.17px',
            },
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .Esp':{
            margin:0,
            [theme.breakpoints.down(1280)]:{
                margin:'0 0 36px 0'

            },
        },
        '& .Esp2':{
            margin:0,
        },

        '& .Imagen':{
            [theme.breakpoints.down(1280)]:{
                width:'63.74%',
            },
        }, 
        '& .Imagen2':{
            [theme.breakpoints.down(1280)]:{
                width:'80.07%',
            },
        }, 
      
    }
}));

const El_sombrerero_loco = (props) => {

  const {activeStep, setActiveStep, handlePreviewStep, id_actividad} = props

  const handleContinue = () => {
    setActiveStep(activeStep - 1)
    handlePreviewStep()
  }

    const {
      handleSize,
    disabledZoom,
    closeZoom,
    handleZoom,
    scale
  }= useContext(DrawerContext);
  const [loading, setLoading] = React.useState(true);
  const wrapperRef = React.useRef(null);
  const imagesLoaded = useOnLoadImages(wrapperRef);

  const detectSize = () => {
    const lectura = document.getElementById("lectura");
    const newWidth = lectura?.offsetWidth;
    const newHeight = lectura?.offsetHeight;
    setLoading(false);
    if (lectura) handleSize(newHeight, newWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", detectSize);
    return () => {
      handleZoom()
      window.removeEventListener("resize", detectSize);
      closeZoom()
    };
  }, []);

  const classes = useStyles({ disabledZoom,scale });
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
             <Grid container item justifyContent="center" className={classes.ContainerInfoLectura}>
                       <Grid container className="BarraInfoLectura">
            <Button className="Regresar" onClick={handleContinue}>
                Regresar
            </Button>
        </Grid>
               <Grid container item className="InfoLectura">
                    <Grid container item direction="column" xs={6} justifyContent="center">
                        <p className="Dato">Grado</p>
                        <p className="Caracter">3</p>
                    </Grid>
                    <Grid container item direction="column" xs={6} justifyContent="center" className="Sesion">
                        <p className="Dato">Sesión</p>
                        <p className="Caracter">44</p>
                    </Grid>
               </Grid>
            </Grid>
            {!imagesLoaded && <Loader />}
        <Grid
            container
            item
            className={classes.ContenedorLectura}
            justifyContent="center"
            id="lectura"
            onLoad={detectSize}
            ref={wrapperRef}
        >
<Drawer id_lectura={id_actividad} />
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >
                        El sombrerero loco                   
                    </h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                      <p className="Lectura Esp">
                        David llevaba casi un año trabajando en una fábrica de sombreros en Francia. Siempre 
                        había apreciado la profesión porque su padre se había dedicado a ella toda su vida y lo 
                        inspiró a seguir sus pasos. 

                        <span className="Tablet">
                        <br/>
                        Pero desde hacía unos días se sentía extraño, más triste de lo normal y abatido, sin 
                        lograr encontrar una explicación para esa situación. Además, se le olvidaban las cosas 
                        y eso lo irritaba. Pero lo más extraño es que su padre también había tenido estos 
                        comportamientos cuando trabajaba en la fábrica.
                        </span>
                      </p>
                    </Grid>
                    <Grid container justifyContent="center" className='Tablet' sm={12}>
                        <Grid container justifyContent="center" >
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img3ro/Sesion44/1.png" alt="Tarjeta" />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container md={12} lg={6}>
                      <p className="Lectura Esp2">
                        <span className="Escritorio">
                        Pero desde hacía unos días se sentía 
                        extraño, más triste de lo normal y abatido, 
                        sin lograr encontrar una explicación para 
                        esa situación. Además, se le olvidaban las 
                        cosas y eso lo irritaba. Pero lo más extraño 
                        es que su padre también había tenido 
                        estos comportamientos cuando trabajaba 
                        en la fábrica.<br/><br/> 
                        </span>
                        
                        Corría el año 1850 y ésta era una 
                        ocupación muy bien remunerada y 
                        popular, porque todos querían un tocado 
                        decorativo para lucir sobre sus cabezas. 
                        Las personas más importantes mandaban 
                        a hacerlos a su medida y gusto. El material 
                        más buscado era el fieltro, que se hacía 
                        con pelo de conejo o de castor. Utilizaban 
                        una sustancia de color naranja que ponían 
                        a calentar para remojar el fieltro. Así 
                        conseguían la textura que tanto gustaba a 
                        la gente.

                      </p>
                    </Grid>
                    <Grid container justifyContent="center" className='Escritorio' md={12} lg={6}>
                        <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img3ro/Sesion44/1.png" alt="Tarjeta" />
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container xs={12}>
                      <p className="Lectura">

                        David utilizaba esta técnica a menudo, porque sus creaciones se vendían mejor y 
                        ganaba más dinero. El negocio iba bien y era admirado por la sociedad, pero él 
                        comenzó a actuar de manera extraña después de unos meses laborando en ese lugar. 
                        Parecía que hablaba solo o que huía de alguien. Casi no conversaba con nadie en la 
                        calle, se volvió huraño y tímido. Su cambio fue tan notable que la gente de la ciudad 
                        comentaba que había perdido la cordura, igual que su padre y muchas otras personas 
                        que se dedicaban a ese oficio.<br/><br/>
                      
                        Hasta 1869 se supo la razón de este padecimiento que afectaba solamente a los
                        fabricantes de sombreros. La famosa sustancia naranja tenía un ingrediente llamado 
                        <em> nitrato mercúrico</em>, que soltaba gases tóxicos de mercurio cuando se calentaba, los 
                        cuales causaban una enfermedad llamada <em>hidrargiria</em> al ser respirados. Este metal 
                        puede envenenar a la gente que tiene contacto con él continuamente, tal como 
                        sucedía con quienes tocaban el fieltro todo el tiempo. Francia prohibió el uso de la 
                        sustancia naranja cerca de 1898 para proteger a los empleados, pero en muchos 
                        países no fue así sino hasta años después.<br/><br/> 
                        
                        Estos famosos personajes dementes no fueron inventados en el cuento de <em>Alicia en el 
                        país de las maravillas.</em> Dicho mal era tan común antes que la gente decía frases como 
                        “está tan loco como un sombrerero” para referirse a alguien que había perdido la razón.
                        
                      </p>
                    </Grid>
                    <Grid container justifyContent="center" xs={12}>
                        <img className="Imagen2" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img3ro/Sesion44/2.png" alt="Tarjeta" />
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container xs={12}>
                      <p className="Lectura">

                        Ver a alguien con sombrero es poco común en la actualidad. El mercurio ya no se 
                        utiliza para crearlos, pero sigue usándose en distintas actividades industriales. Por 
                        ejemplo, en la manufactura de objetos como los termómetros, que empleamos para 
                        medir la temperatura corporal cuando tenemos una enfermedad.
                        
                      </p>
                    </Grid>
                </Grid>
        </Grid>
        <Grid container item style={{ marginTop: 80}} ></Grid>
    </Grid>
    );
}
 
export default El_sombrerero_loco ;
