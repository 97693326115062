import React from "react";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Button, makeStyles, Grid } from "@material-ui/core";

import CustimizedTable from "../../ui/CustomizedTable";

import rotateImg from "./img/TYC_001.png";
import sizesImg from "./img/TYC_002.png";

const useStyles = makeStyles((theme) => ({
  MuiDialogContainer: {
    borderRadius: "61px",
    height: "90%",
    width: "90%",
    backgroundColor: "#FEFAF1",
    border: "#707070 2px solid",
    backdropFilter: "blur(3px)",
    maxWidth: "80%",
  },
  MuiContentRoot: {
    overflowY: "auto",
    [theme.breakpoints.up("lg")]: {
      "&::-webkit-scrollbar": {
        width: "auto",
      },
      "&::-webkit-scrollbar-track": {
        backgroundColor: "#7A54BA20",
        webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#7A54BA",
        borderRadius: "5px",
      },
    },
  },

  mainTitle: {
    font: "28px fredoka one",
    margin: "0px",
    textAlign: "center",
    [theme.breakpoints.up("md")]: {
      font: "36px fredoka one",
    },
    [theme.breakpoints.up("xl")]: {
      font: "42px fredoka one",
    },
  },
  section: {
    margin: 0,
    padding: "5px 0",
    boxSizing: "border-box",
  },

  titleWithNumber: {
    font: "bold 20px lato",
    [theme.breakpoints.down("760")]: {
      font: "bold 18px lato",
      margin: "5px 0 10px 0",
    },
    [theme.breakpoints.down("321")]: {
      font: "bold 16px lato",
    },
  },
  subTitle: {
    font: "bold 20px lato",
    [theme.breakpoints.down("760")]: {
      font: "bold 18px lato",
      margin: "5px 0 10px 0",
    },
    [theme.breakpoints.down("321")]: {
      font: "bold 16px lato",
    },
  },

  textPrincipal: {
    font: "16px lato",
    [theme.breakpoints.down("760")]: {
      font: "12px lato",
    },
    [theme.breakpoints.up("1440")]: {
      font: "20px lato",
    },
  },
  link: {
    font: "16px lato",
    overflow: "hidden",
    whiteSpace: "'pre",
    lineBreak: "anywhere",
    [theme.breakpoints.down("760")]: {
      font: "12px lato",
    },
  },
  paragraph: {
    textAlign: "justify",
    font: "16px lato",
    [theme.breakpoints.down("760")]: {
      font: "12px lato",
    },
    [theme.breakpoints.up("1920")]: {
      font: "20px lato",
    },
  },
  paragraphContact: {
    textAlign: "justify",
    font: "12px lato",
    [theme.breakpoints.up("760")]: {
      font: "16px lato",
      display: "inline",
    },
    [theme.breakpoints.up("1920")]: {
      font: "20px lato",
    },
  },
  decoratedLink: {
    color: "#007fa3",
    display: "inline-block",
    textAlign: "justify",
    font: "16px lato",
    marginLeft: "5px",
    overflowWrap: "anywhere",
    [theme.breakpoints.down("760")]: {
      font: "12px lato",
    },
    [theme.breakpoints.up("1920")]: {
      font: "20px lato",
    },
  },
  table: {
    borderCollapse: "collapse",
    border: "1px solid black",
    textAlign: "center",
    margin: "0 auto",
    padding: "5px",
  },
  listNumber: {
    padding: "0",
    listStylePosition: "inside",
    textAlign: "justify",
    marginLeft: "5px",
  },
  numberItem: {
    textAlign: "justify",
    marginLeft: "10px",
    font: "16px lato",
    [theme.breakpoints.down("760")]: {
      font: "12px lato",
    },
    [theme.breakpoints.up("1920")]: {
      font: "20px lato",
    },
  },
  listBullet: {
    padding: "0",
    listStylePosition: "inside",
    textAlign: "justify",
    "& li": {},
  },
  itemBullet: {
    listStylePosition: "inside",
    paddingLeft: "20px",
    font: "16px lato",
    margin: "10px",
    [theme.breakpoints.down("sm")]: {
      font: "12px lato",
      paddingLeft: "0",
    },
  },

  BotonContinuar: {
    textTransform: "none",
    height: "42px",
    width: "138px",
    background: "#5825AD",
    boxShadow: "0px 3px 6px #00000029",
    borderRadius: "61px",
    color: "#FFFFFF",
    font: "bold 20px lato ",
    "&:hover": {
      background: "#dd78fb",
    },
    [theme.breakpoints.up("sm")]: {
      height: "62px",
      width: "238px",
      font: "bold 18px lato ",
    },
  },

  textChangeRegion: {
    textAlign: "justify",
    font: "16px lato",
    color: "#505759",
    [theme.breakpoints.down("760")]: {
      font: "12px lato",
    },
    [theme.breakpoints.up("1920")]: {
      font: "20px lato",
    },
  },

  buttonChangeRegion: {
    border: "none",
    backgroundColor: "transparent",
    textDecoration: "underline",
    cursor: "pointer",
    padding: 0,
    font: "inherit",
    color: "#007fa3",
  },

  img: {
    width: "100%",
    maxWidth: "480px",
    display: "block",
    margin: "20px auto",
  },
  tip: {
    marginLeft: "50px",
  },
}));

const TermsAndConditionsMexico = (props) => {
  const {
    open,
    handleConfirm,
    changeToSelectRegion,
    accept,
    accepted,
  } = props;
  const classes = useStyles();

 function createData(item1, item2, item3, item4, item5, item6) {
    return { item1, item2, item3, item4, item5, item6 };
  }
  const firstTable = [
    createData(
      `Apple®/ Mac OS®`,
      "4gb",
      "Intel Core i3",
      "Gráficos integrados o superior",
      "MacOS Sierra",
      "10mbps"
    ),
    createData(
      `Microsoft® Windows®`,
      "4gb",
      "Pentium AMD A6",
      "Gráficos integrados o superior",
      "Windows 7",
      "10mbps"
    ),
  ];
  const secondTable = [
    createData(`Apple®/ Mac OS®`, "Sí", "Sí", "Sí", "Sí"),
    createData(`Microsoft® Windows®`, "No", "Sí", "Sí", "Sí"),
  ];

  const thirdTable = [
    createData(
      `Apple® IOS®`,
      "1gb",
      "A8",
      "Gráficos integrados",
      `iOS 12.5.2 26
      (a partir del iPhone 7)`,
      "10mbps"
    ),
    createData(
      "Android",
      "3gb",
      "Snapdragon 430 o equivalente",
      "Gráficos integrados",
      "Android 5 (5.0 Lollipop)",
      "10mbps"
    ),
  ];

  const fourthTable = [
    createData(`Apple® iOS® para iPhone® y iPad®`, "Sí", "No", "No", "No"),
    createData(
      `Android™ OS para teléfonos y tabletas Android`,
      "No",
      "Sí",
      "No",
      "No"
    ),
  ];

  return (
    <Dialog
      open={open}
      classes={{ paper: classes.MuiDialogContainer }}
      disableEscapeKeyDown
    >
      <DialogTitle style={{ textAlign: "center" }}>
        <span className={classes.mainTitle}>Términos y Condiciones México</span>
        {/* <span className={classes.mainTitle}>Términos y Condiciones</span> */}
      </DialogTitle>
      <DialogContent classes={{ root: classes.MuiContentRoot }}>
        <p className={classes.textChangeRegion}>
          Si eres de otro país lee los términos y condiciones{" "}
          <button
            onClick={changeToSelectRegion}
            className={classes.buttonChangeRegion}
          >
            aquí
          </button>
        </p>{" "}
        <section className={classes.section}>
          {/* section1 */}
          <h3 className={classes.titleWithNumber}>1. General</h3>

          <p className={classes.paragraph}>
            Este sitio (la “Plataforma”), sus aplicaciones móviles (“Apps”), así
            como los servicios relacionados con el mismo (referidos en conjunto
            con la Plataforma y las Apps como los “Servicios”), son provistos
            por Pearson Educación de México, S.A de C.V. (en adelante “Pearson”
            y/o “Nosotros”).
          </p>
          <p className={classes.paragraph}>
            El acceso y uso de los Servicios está sujeto a los siguientes
            términos y condiciones de servicio (los “Términos y Condiciones”)
            por lo que el acceso, navegación y/o uso de cualquiera de los
            Servicios, representa que usted (en adelante referido
            indistintamente como “Usted” o el “Usuario”) ha leído y entendido
            los mismos, declarando de forma expresa su aceptación, en el
            entendido que no existe error, dolo, mala fe o cualquier otro vicio
            de la voluntad que pudiera nulificar la validez de los mismos.
          </p>

          <p className={classes.paragraph}>
            Si el Usuario no acepta en forma absoluta y completa los Términos y
            Condiciones de este documento, deberá abstenerse de acceder, usar,
            navegar, aceptar productos o de otra forma utilizar servicios
            asociados con la Plataforma.
          </p>
          <p className={classes.paragraph}>
            Para el caso que el Usuario continúe en el uso de la Plataforma,
            dicha acción se considerará como su absoluta y expresa aceptación a
            los Términos y Condiciones aquí estipulados.
          </p>

          <p className={classes.paragraph}>
            El Usuario acepta y entiende que, para utilizar los servicios de la
            Plataforma, se requiere tener capacidad legal para contratar. No
            podrán acceder a los servicios quienes carezcan de ella, los que
            hayan sido suspendidos o inhabilitados, ni los menores de edad. Los
            padres, tutores o responsables de los menores de edad o incapaces
            que utilicen la Plataforma serán responsables por dicho uso,
            incluyendo cualquier daño que se derive de él.
          </p>

          <p className={classes.paragraph}>
            Cualquier intento por parte del Usuario de alterar, suprimir o
            modificar estos términos y condiciones se considerará una alteración
            a este acuerdo y, por consecuencia, será nulo e inválido.
          </p>

          <p className={classes.paragraph}>
            El Usuario reconoce y acepta que diversas partes de la Plataforma
            podrán tener términos y condiciones particulares que regularán
            secciones específicas del mismo, las cuales sustituirán,
            complementarán y/o modificarán los presentes Términos y Condiciones.
          </p>

          <p className={classes.paragraph}>
            Pearson se reserva el derecho de actualizar o cambiar estos Términos
            y condiciones, obligándose a notificar al Usuario publicando un
            aviso en la Plataforma, enviando un correo sin que esto signifique
            que el Usuario pueda evitar que las modificaciones entren en vigor.
          </p>

          <p className={classes.paragraph}>
            El Usuario declara y garantiza a Pearson que su acceso y uso de los
            Servicios estará de acuerdo con estos Términos y Condiciones y con
            todas las leyes, normas y regulaciones aplicables de la República
            Mexicana y cualquier otra jurisdicción relevante, vigente y
            aplicable, en el entendido que Pearson estará facultado para
            suspender temporal o definitivamente al Usuario en caso de
            incumplimiento de los presentes Términos y Condiciones.
          </p>
        </section>
        <section className={classes.section}>
          <h3 className={classes.titleWithNumber}>
            2. Alcance y descripción de los Servicios
          </h3>

          <p className={classes.paragraph}>
            Los Servicios ofrecidos por Pearson dentro de la Plataforma permiten
            al Usuario a acceder y utilizar la Plataforma y los contenidos de la
            misma dentro de Latinoamérica.
          </p>

          <p className={classes.paragraph}>
            El Usuario acepta y entiende que Pearson podrá, a su exclusivo
            criterio y en cualquier momento, actualizar, cambiar, suspender,
            realizar mejoras o suspender cualquier aspecto de los Servicios,
            temporal o permanentemente.
          </p>

          <p className={classes.paragraph}>
            El Usuario acepta y entiende que pueden existir distintos tipos de
            servicios a los que se accederá a través del registro en la
            Plataforma pero que no son administrados por Pearson, sino por
            terceros. En estos casos, el Usuario deberá aceptar los presentes
            Términos y Condiciones de uso por lo que respecta a su registro en
            la Plataforma y acepar los términos y condiciones de uso de los
            servicios ofrecidos por terceros, en el entendido que Pearson no
            será bajo ninguna circunstancia responsable de los servicios o
            productos ofrecidos por dichos terceros o de los productos o
            servicios de terceros alojados en la Plataforma, en dichos casos la
            Plataforma sirve exclusivamente como parte del registro del Usuario.
          </p>

          <p className={classes.paragraph}>
            Pearson se reserva el derecho de interrumpir, suspender o modificar
            en cualquier momento los servicios ofrecidos en la Plataforma, ya
            sea en forma permanente o transitoria. No se requerirá la
            conformidad del Usuario, en el entendido que no será necesario aviso
            previo alguno.
          </p>
        </section>
        <section className={classes.section}>
          <h3 className={classes.titleWithNumber}>
            3. Registro, acceso y uso de los Servicios
          </h3>
          <p className={classes.paragraph}>
            El registro de los usuarios se llevará a cabo, según la Plataforma,
            de conformidad con las siguientes modalidades:
          </p>

          <ol className={classes.listNumber}>
            <li className={classes.numberItem}>
              “Formato de creación de usuarios” Pearson tomando en cuenta los
              datos proporcionados por los Usuarios o su organización llevará a
              cabo el registro del usuario.
            </li>
            <li className={classes.numberItem}>
              “Códigos de acceso”: se proporciona un código alfanumérico para
              que el Usuario final ingrese sus datos en un formulario para
              accesar y hacer uso de los Servicios.
            </li>
            <li className={classes.numberItem}>
              Integración LTI: este estándar tiene como objetivo ofrecer un
              marco único para integrar cualquier producto LMS con cualquier
              aplicación de aprendizaje identificando: (i) La identidad del
              usuario; (ii) El contexto del curso.
            </li>
          </ol>
          <p className={classes.paragraph}>
            En relación con el registro, Usted acepta proporcionar información
            precisa, actual y completa sobre Usted y/o su organización según lo
            solicitado por Pearson a efecto de poder crear y darle acceso a una
            cuenta de usuario.
          </p>

          <p className={classes.paragraph}>
            A cada Usuario se le otorgará una cuenta de usuario y una contraseña
            para accesar y hacer uso de los Servicios, en el entendido que
            podrán existir diversos tipos de configuraciones de usuarios que
            darán permisos y/o niveles de acceso/acción distintos.
          </p>
          <p className={classes.paragraph}>
            El Usuario deberá contar con una cuenta de Usuario y contraseña
            válidos para accesar a la Plataforma y hacer uso de los Servicios.{" "}
          </p>
          <p className={classes.paragraph}>
            El Usuario es el único responsable del uso que se haga de su cuenta
            de usuario y contraseña y deberá tener presente que, si otras
            personas tienen o pueden tener en el futuro acceso a la cuenta de
            e-mail consignada como propia en el formulario de registro, éstas
            también podrían solicitar su clave de acceso y nombre de Usuario,
            por lo que es obligación exclusiva del Usuario tomar las medidas
            pertinentes para que esto no suceda.
          </p>
          <p className={classes.paragraph}>
            En caso de que la contraseña sea olvidada por el Usuario, Pearson
            cuenta con un servicio de ayuda para recuperarla. Para esto se
            deberá hacer “clic” en la opción correspondiente e ingresar el
            nombre de usuario que le fue asignado. Al finalizar, el sistema
            enviará de manera confidencial la forma de recuperar su contraseña.
          </p>
          <p className={classes.paragraph}>
            El Usuario se compromete a notificar inmediatamente y de manera
            fehaciente a Pearson cualquier uso no autorizado de su cuenta de
            usuario, y a mantenerlo indemne en el caso de que esto produzca
            algún daño a Pearson y/o a terceros.
          </p>
          <p className={classes.paragraph}>
            La Plataforma puede contener diferentes tipos de servicios y
            contenidos, los cuales deberán ser empleados de manera correcta,
            diligente, lícita y conforme a su objeto, sin infringir el orden
            público, la legislación vigente en nuestro país o cualquier código
            de conducta u otras directrices fijados en los presentes Términos y
            Condiciones.
          </p>
          <p className={classes.paragraph}>
            Cualquier colocación de dispositivos técnicos de enlace con la
            Plataforma, así la colocación y/o utilización de los Servicios y/o
            contenidos de la Plataforma en otros sitios o páginas de Internet,
            deberá ser solicitada por escrito para que en su caso, se conceda la
            autorización respectiva.
          </p>
          <p className={classes.paragraph}>
            El Usuario acepta que Pearson puede establecer límites respecto al
            uso, reproducción y/o divulgación de cualquier contenido y servicio
            de la Plataforma, como el número máximo de días que un Servicio
            estará disponible, entre otros.
          </p>
          <p className={classes.paragraph}>
            El Usuario declara y garantiza que ha creado o es propietario de
            cualquier material que envíe o cargue a través de los Servicios y
            que tiene el derecho, transformarlo, explotarlo, reproducirlo y/o
            comunicarlo a través de los Servicios, en el entendido que el
            Usuario será el único responsable respecto de los archivos y el
            contenido de los mismos
          </p>
        </section>
        {/* section4 */}
        <section className={classes.section}>
          <h3 className={classes.titleWithNumber}>4. Uso inapropiado</h3>
          <p className={classes.paragraph}>
            El Usuario solo podrá accesar y hacer uso de los Servicios de forma
            lícita y para los fines para los que fueron creados.{" "}
          </p>
          <p className={classes.paragraph}>
            El Usuario deberá abstenerse de realizar cualquiera de las
            siguientes acciones: piratería de nombres de dominio, linking,
            framing, metatagging, cybertstuffing, spamming, inlining, targeted
            pop-ups ads, cataloging sites, mousetrapping y/o web rings,
            cualquier tipo de ingeniería inversa, desarmado o descompilado de la
            Plataforma.
          </p>
          <p className={classes.paragraph}>
            Usted se obliga a no cargar, mostrar o proveer de ningún otro modo a
            través de la Plataforma, contenido que: (i) sea difamatorio,
            abusivo, amenazante, acosador, de odio, ofensivo o de otra manera
            viole cualquier ley o infrinja el derecho de un tercero (incluidos
            derechos de autor, propiedad industria, privacidad, u otros derechos
            personales o de propiedad); o que (ii) a juicio exclusivo de
            Pearson, es objetable o que restringe o inhibe a cualquier otra
            persona del uso del Servicio o que puede exponer a Pearson o sus
            usuarios a cualquier daño o responsabilidad de cualquier tipo.
          </p>
          <p className={classes.paragraph}>
            Pearson se reserva el derecho, a su entera discreción, a cancelar o
            suspender en parte, o en su totalidad el acceso a los Servicios, en
            cualquier momento, sin previo aviso, por iniciativa propia o a
            petición de terceros, a cualquier Usuario que haga uso indebido de
            los Servicios.
          </p>
        </section>
        {/* section5 */}
        <section className={classes.section}>
          <h3 className={classes.titleWithNumber}>
            5. Enlaces, sitios y servicios de terceros
          </h3>

          <p className={classes.paragraph}>
            Los Servicios pueden contener dispositivos técnicos de enlace, tales
            como hipervínculos (links), anuncios, botones, imágenes, directorios
            y/o herramientas de búsqueda, entre otros, que les permiten acceder
            a otros sitios de Internet (“Sitios Vinculados”) que no son
            propiedad ni están controlados por Pearson. No respaldamos ni
            asumimos ninguna responsabilidad por dichos sitios, información,
            materiales, productos o servicios de terceros. Si accede a un sitio
            web, servicio o contenido de terceros desde la Plataforma, comprende
            que estos Términos y Condiciones, así como nuestra Política de
            privacidad no se aplican a su uso de dichos sitios. Usted reconoce y
            acepta expresamente que Pearson no será responsable, directa o
            indirectamente, de ningún daño o pérdida que surja de su uso de un
            sitio web, servicio o contenido de terceros.
          </p>
        </section>
        {/* section6 */}
        <section className={classes.section}>
          <h3 className={classes.titleWithNumber}>
            6. Requerimientos técnicos mínimos
          </h3>

          <p className={classes.paragraph}>
            El Usuario acepta y entiende que deberá cumplir con los presentes
            requerimientos técnicos mínimos para poder accesar y hacer uso de
            los Servicios.
          </p>
          <CustimizedTable
            createData={createData}
            rows={firstTable}
            firstColumn="Plataforma"
            secondColumn="RAM"
            thirdColumn="CPU"
            fourthColumn="Gráficos"
            fifthColumn="Sistema operativo"
            sixthColumn="Internet (ancho de banda)"
          />
          <h4 className={classes.titleWithNumber}>
            Compatibilidad del explorador
          </h4>
          <p className={classes.paragraph}>
            Para obtener una experiencia óptima que ofrezca un mejor
            rendimiento, mayor accesibilidad y seguridad, se recomienda que
            todos los usuarios accedan al entorno de aprendizaje de Circuito de
            lectores con la última versión de alguno de los navegadores que se
            mencionaran a continuación.
          </p>
          <p className={classes.paragraph}>
            <strong>Consejo: &nbsp; </strong>
            De manera predeterminada, la mayoría de los navegadores se
            actualizan de manera automática a la última versión. Para asegurarse
            de que usa la última versión del navegador, CDL recomienda que
            configure su navegador para que se actualice de manera automática.
          </p>

          <p className={classes.tip}>Tenga en cuenta lo siguiente:</p>
          <ul className={classes.listBullet}>
            <li className={classes.itemBullet}>
              Verifique que JavaScript y las cookies estén habilitadas en su
              navegador.
            </li>
          </ul>

          <h4 className={classes.titleWithNumber}>Navegadores compatibles:</h4>
          <p className={classes.paragraph}>
            El entorno de aprendizaje de CDL es compatible con las últimas
            versiones de los navegadores soportados, en equipos de escritorio:
          </p>
          <CustimizedTable
            createData={createData}
            rows={secondTable}
            firstColumn="Plataforma"
            secondColumn="Apple® Safari®"
            thirdColumn="Google® Chrome™"
            fourthColumn="Microsoft® Edge"
            fifthColumn="Mozilla® Firefox®"
          />
          <br />
          <p className={classes.paragraph}>
            <b>Nota:</b> Se recomienda ampliamente el uso de Chrome como
            navegador determinado para acceder a esta plataforma.
          </p>
          <br />
          <h4 className={classes.titleWithNumber}>
            Requisitos mínimos en Tabletas y dispositivos móviles:
            <br />
          </h4>
          <CustimizedTable
            createData={createData}
            rows={thirdTable}
            firstColumn="Plataforma"
            secondColumn="RAM"
            thirdColumn="CPU"
            fourthColumn="Gráficos"
            fifthColumn="Sistema operativo"
            sixthColumn="Internet (ancho de banda)"
          />
          <br />
          <br />
          <CustimizedTable
            createData={createData}
            rows={fourthTable}
            firstColumn="Plataforma"
            secondColumn="Apple® Safari®"
            thirdColumn="Google® Chrome™"
            fourthColumn="Microsoft® Edge"
            fifthColumn="Mozilla® Firefox®"
          />
          <br />

          <h4 className={classes.subTitle}>
            Consideraciones para usuarios de dispositivos móviles:
          </h4>
          <p className={classes.paragraph}>
            Para visualizar de manera horizontal en dispositivos móviles, tomar
            en cuenta las siguientes consideraciones.
          </p>
          <p className={classes.paragraph}>
            La plataforma Circuito de lectores bloqueará la rotación en la app
            para dispositivos móviles.{" "}
          </p>
          <p className={classes.paragraph}>
            Cuando se visualice en un navegador de dispositivo móvil con
            pantalla menor a 6 pulgadas, el usuario recibirá un mensaje como el
            que se muestra a continuación para que gire su dispositivo de forma
            vertical y tenga así una mejor experiencia de usuario.{" "}
          </p>
          <img src={rotateImg} alt="" className={classes.img} />
          <img src={sizesImg} alt="" className={classes.img} />
          <p className={classes.paragraph}>
            Algunos ejemplos de dispositivos móviles con menos de 6 pulgadas en
            pantalla son el móvil Pixie 4 o iPhone 12 mini y de móviles con 6
            pulgadas o más son Galaxy 20s ultra o iPhone 12 pro.
          </p>
          <p className={classes.paragraph}>
            También es importante considerar que en los dispositivos móviles con
            sistema operativo iOS no será posible consultar ni las antologías ni
            las novelas disponibles en formato digital en Vital Source (material
            que el usuario también tiene en formato impreso). Los usuarios de
            dichos dispositivos podrán tener acceso a estos materiales en su
            versión digital si acceden a la plataforma desde el navegador.
          </p>

          <h4 className={classes.titleWithNumber}>Navegadores antiguos:</h4>
          <p className={classes.paragraph}>
            Actualmente, la comprobación de la versión del navegador muestra una
            advertencia para navegadores anteriores a las siguientes versiones:
          </p>
          <ul className={classes.listBullet}>
            <li className={classes.itemBullet}>
              Chrome 85 (publicado en agosto del 2020)
            </li>
            <li className={classes.itemBullet}>
              Safari 13 (publicado en septiembre del 2019)
            </li>
            <li className={classes.itemBullet}>
              Mobile Safari 12 (publicado en octubre del 2018)
            </li>
            <li className={classes.itemBullet}>
              Firefox 80 (publicado en agosto del 2020)
            </li>
            <li className={classes.itemBullet}>
              Microsoft Edge 85 basado en Chromium (publicado en agosto del
              2020)
            </li>
            <li className={classes.itemBullet}>
              Todas las versiones de Microsoft Edge Legacy preparadas para el
              fin del soporte de Microsoft en marzo del 2021
            </li>
          </ul>
          <h3 className={classes.titleWithNumber}>
            Navegadores no compatibles:
          </h3>
          <p className={classes.paragraph}>
            Si un usuario intenta conectarse a una instancia de CDL con un
            navegador no compatible, la página no cargará, o presentará
            problemas en los estilos y la carga de los datos no será la óptima,
            por eso es recomendable seguir las instrucciones proporcionadas en
            este documento.
          </p>
          <br />
        </section>
        {/* section7 */}
        <section className={classes.section}>
          <h3 className={classes.titleWithNumber}>
            7. Límite de responsabilidad e indemnidad{" "}
          </h3>

          <p className={classes.paragraph}>
            Pearson no garantiza la disponibilidad y continuidad de la
            operación, ni el funcionamiento de la Plataforma. Por lo tanto,
            Pearson no garantiza el acceso o uso permanente de la misma, ya que
            ésta podría interrumpirse por cuestiones técnicas ajenas a Pearson.
            Si la suspensión o interrupción mencionada no obedeciere a razones
            de fuerza mayor o caso fortuito, Pearson se compromete a cumplir las
            prestaciones que estuvieran pendientes al momento de la suspensión o
            interrupción.
          </p>
          <p className={classes.paragraph}>
            El Usuario expresamente comprende y conviene que el uso de la
            Plataforma es bajo su propio riesgo y bajo su exclusiva y estricta
            responsabilidad. PEARSON, sus licenciantes y los terceros
            proveedores de contenidos y servicios no realizan ninguna
            manifestación ni otorgan garantía expresa sobre la veracidad,
            puntualidad, idoneidad, fiabilidad, disponibilidad, oportunidad,
            seguridad o continuidad de la operación y funcionamiento de los
            Servicios y sus contenidos, ni sobre la ausencia de virus u otros
            componentes dañinos, exactitud, utilidad de los contenidos y/o
            servicios de la Plataforma.{" "}
          </p>
          <p className={classes.paragraph}>
            Pearson no garantiza que los archivos que se suban por parte de los
            Usuarios a la Plataforma se encuentren libre de virus, gusanos o
            cualquier otro elemento que pueda llegar a dañar o alterar el normal
            funcionamiento de un equipo informático. Es responsabilidad y
            obligación exclusiva del Usuario contar con las herramientas
            adecuadas para detectar, desinfectar y/o prevenir cualquier tipo de
            elementos y/o posibles daños de esta naturaleza.
          </p>
          <p className={classes.paragraph}>
            Los contenidos y/o servicios de la Plataforma se proporcionan “tal
            cual” sin garantía de ningún tipo. El Usuario acepta que Pearson,
            sus subsidiarias, compañías afiliadas, directivos y empleados no
            serán responsables, en la medida permitida por las leyes aplicables,
            de cualquier responsabilidad por daños y perjuicios de cualquier
            naturaleza frente al Usuario o frente a terceros, de manera
            enunciativa daños y perjuicios ocasionados por: a) la pérdida de
            uso, de datos o de beneficios derivados o relacionados con el uso o
            ejecución de los sitios, contenidos o servicios de la Plataforma, b)
            la demora o la imposibilidad de poder usar los sitios, contenidos o
            servicios de la Plataforma c) la prestación o no prestación de
            servicios, o con cualquier información, servicios y gráficos
            relacionados que se obtengan a través de la Plataforma, o que de
            otra forma se originen en el uso del sitio o servicios de la
            Plataforma.
          </p>
          <p className={classes.paragraph}>
            Usted acepta defender, indemnizar y eximir de responsabilidad a
            Pearson y sus directores, funcionarios, empleados, contratistas,
            agentes, proveedores, licenciantes y/o cesionarios, de todas y cada
            una de las pérdidas, reclamos, acciones, obligaciones,
            responsabilidades y daños de cualquier tipo, incluidos los
            honorarios de abogados, derivados o relacionados con su acceso y/o
            uso de los Servicios.
          </p>
        </section>
        {/* section8 */}
        <section className={classes.section}>
          <h3 className={classes.titleWithNumber}>8. Política de reembolsos</h3>
          <p className={classes.paragraph}>
            En el caso de que Pearson suspenda o cancele su acceso a los
            Servicios por incumplir con estos Términos y Condiciones o bien, si
            Usted cerrare su cuenta voluntariamente, Usted, comprende y acepta
            que Pearson no estará obligado a hacer ningún reembolso o cambio de
            ningún tipo.
          </p>
        </section>
        {/* section9 */}
        <section className={classes.section}>
          <h3 className={classes.titleWithNumber}>9. Propiedad intelectual </h3>

          <p className={classes.paragraph}>
            El Usuario reconoce y acepta que Pearson, sus licenciantes y/o
            terceros proveedores de servicios y contenidos que sean expresamente
            reconocidos como tales, son los únicos titulares de los derechos de
            propiedad intelectual de los Servicios y el contenido disponible a
            través de estos.
          </p>
          <p className={classes.paragraph}>
            Los derechos de propiedad industrial se refieren a todas las marcas
            registradas y/o usadas en México o en el extranjero, así como todos
            los derechos sobre invenciones (patentadas o no), diseños
            industriales, modelos de utilidad, información confidencial, nombres
            comerciales, avisos comerciales, reservas de derechos, nombres de
            dominio, así como todo tipo de derechos patrimoniales sobre obras y
            creaciones por derechos de autor y demás formas de propiedad
            industrial o intelectual reconocida o que lleguen a reconocer las
            leyes correspondientes
          </p>
          <p className={classes.paragraph}>
            Por lo anterior, el Usuario acepta y entiende que ningún contenido
            puede ser modificado, copiado, distribuido, enmarcado, reproducido,
            republicado, descargado, raspado, exhibido, publicado, transmitido o
            vendido de ninguna forma o por ningún medio, en su totalidad o en
            parte, excepto lo expresamente permitido en estos Términos y
            Condiciones y que el acceso y uso de los Servicios no le otorga ni
            le otorga bajo ninguna circunstancia el derecho o la licencia para
            reproducir o utilizar ninguno de los derechos de propiedad
            industrial de Pearson o de terceros.
          </p>
          <p className={classes.paragraph}>
            Si el Usuario considera que los Servicios y/o su contenido violan o
            atentan de algún modo contra derechos de propiedad intelectual
            propios o de terceros, deberá notificarlo a Pearson en la dirección
            indicada en los presentes Términos y Condiciones, acompañando toda
            la información necesaria para sustentar su reporte.
          </p>
        </section>
        {/* section10 */}
        <section className={classes.section}>
          <h3 className={classes.titleWithNumber}>
            10. Privacidad de datos personales y confidencialidad
          </h3>
          <p className={classes.paragraph}>
            La información proporcionada por el Usuario a Pearson con motivo de
            su registro y/o uso de los Servicios, se regirá por lo dispuesto en
            la Ley Federal de Protección de Datos Personales en Posesión de los
            Particulares, su reglamento, lineamientos y cualquier otra
            disposición aplicable (en lo sucesivo las “Leyes de Protección de
            Datos”).
          </p>
          <p className={classes.paragraph}>
            Usted podrá informarse acerca del tratamiento que Pearson le otorgue
            a sus datos personales, así como conocer los medios y procedimientos
            para el ejercicio de sus derechos ARCO consultando nuestro Aviso de
            Privacidad a través del siguiente enlace:
          </p>
          <a
            href="http://www.pearsoneducacion.net/docs/librariesprovider5/documentosmexico/aviso-de-privacidad-Pearson.pdf?"
            className={classes.decoratedLink}
            target="_blank"
            rel="noreferrer"
          >
            http://www.pearsoneducacion.net/docs/librariesprovider5/documentosmexico/aviso-de-privacidad-Pearson.pdf?
          </a>
          <p className={classes.paragraph}>
            El Usuario en este acto autoriza a Pearson a hacer uso de los datos,
            registros, información y materiales generados a partir de su acceso
            y uso de los Servicios, para fines estadísticos y de mejora, en el
            entendido que Pearson se obliga a tratar los datos personales del
            Usuario en cumplimiento a las Leyes de Privacidad de Datos.
          </p>
        </section>
        {/* section11 */}
        <section className={classes.section}>
          <h3 className={classes.titleWithNumber}>11. Vigencia</h3>
          <p className={classes.paragraph}>
            La prestación de los Servicios y el tiempo en que estos estarán en
            Internet tendrán una duración indefinida. Pearson podrá dar por
            terminada, suspender o interrumpir en cualquier momento y sin
            necesidad de previo aviso la prestación de los Servicios y el
            alojamiento de la Plataforma en Internet.
          </p>
        </section>
        {/* section12 */}
        <section className={classes.section}>
          <h3 className={classes.titleWithNumber}>12. Cookies</h3>
          <p className={classes.paragraph}>
            El Usuario acepta y entiende que el servidor de datos de Pearson
            enviará datos al navegador del Usuario para que se almacenen en el
            disco duro de su computadora y nos permita identificar las
            computadoras utilizadas por los usuarios al tener acceso a la página
            de Pearson. Este proceso es conocido como el uso de "cookies" o
            "direcciones de IP". Mediante el uso de "cookies" o "direcciones de
            IP" no se identifica personalmente a los usuarios, pero sí sus
            computadoras.
          </p>
        </section>
        {/* section13 */}
        <section className={classes.section}>
          <h3 className={classes.titleWithNumber}>13. Violaciones </h3>
          <p className={classes.paragraph}>
            En caso de que el Usted conozca cualquier violación a los presentes
            Términos y Condiciones o que considere que le han violado sus
            derechos, deberá notificar a Pearson a la dirección indicada en
            estos Términos y Condiciones, para tratar de llegar a una solución.
          </p>
        </section>
        {/* section14 */}
        <section className={classes.section}>
          <h3 className={classes.titleWithNumber}>14. Contacto Pearson: </h3>
          <p className={classes.paragraph}>
            Calle Antonio Dovalí Jaime núm. 70, piso 6, Torre B, Santa Fe,
            Edificio Corporativo Samara, Delegación Álvaro Obregón, Código
            Postal 01210, Ciudad de México, México. Tel. (5255) 5387 0700,
            e-mail:
            <a
              href={`mailto: soporte@pearson.com}`}
              className={classes.decoratedLink}
            >
              soporte@pearson.com
            </a>
          </p>
        </section>
        {/* section15 */}
        <section className={classes.section}>
          <h3 className={classes.titleWithNumber}>15. Misceláneos </h3>
          <p className={classes.paragraph}>
            Estos Términos y Condiciones constituyen el acuerdo completo entre
            Usted y Pearson con respecto al acceso y uso de los Servicios. En
            caso de que alguna parte de los presentes Términos y Condiciones sea
            considerada no ejecutable o se declare nula o inválida de acuerdo a
            la legislación aplicable, dicha parte será sustituida por un término
            o condición que sea válido y que pueda cumplir con el objetivo de la
            disposición no ejecutable o que haya sido declarada nula o inválida,
            en tanto que los demás Términos y Condiciones continuarán en plena
            vigencia y efecto.
          </p>
          <p className={classes.paragraph}>
            Cualquier derecho que no se haya conferido expresamente en este
            documento se entiende reservado a Pearson.
          </p>
          <p className={classes.paragraph}>
            Pearson se reserva el derecho de efectuar en cualquier momento y sin
            previo aviso las modificaciones que considere oportunas a la
            Plataforma, las Apps, los Servicios y/o a los presentes Términos y
            Condiciones, modificando o actualizando los contenidos,
            configuración, servicios y cualquier aspecto de los Servicios.{" "}
          </p>
          <p className={classes.paragraph}>
            Pearson podrá ceder sus obligaciones y derechos bajo estos Términos
            y Condiciones a cualquiera de sus empresas afiliadas, subsidiarias,
            asociadas o a su casa matriz sin necesidad de cumplir con estos
            Términos y Condiciones serán vinculantes y en beneficio del Usuario
            y de Pearson, así como de sus respectivos sucesores y cesionarios
            permitidos.
          </p>
        </section>
        {/* section16 */}
        <section className={classes.section}>
          <h3 className={classes.titleWithNumber}>
            16. Leyes aplicables y jurisdicción
          </h3>
          <p className={classes.paragraph}>
            La relación entre el Usuario y Pearson se rige por las leyes
            Federales de los Estados Unidos Mexicanos y cualquier controversia
            se someterá a los Juzgados y Tribunales de la Ciudad de México,
            México, renunciando expresamente ambas partes a cualquier otro fuero
            o jurisdicción que pudiera corresponderles en razón de sus
            domicilios presentes o futuros.
          </p>
          <p className={classes.paragraph}>
            Asimismo, el Usuario acepta que una versión impresa de los presentes
            Términos y Condiciones, y de cualquier comunicación enviada y/o
            recibida en forma electrónica será admisible como medio probatorio
            en cualquier procedimiento judicial, administrativo y/o arbitral.
          </p>
        </section>
        {/* Fin de las sectiones. */}
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          style={{ marginBottom: "40px" }}
        >
          <input
            type="checkbox"
            id="agree"
            name="agree"
            checked={accepted}
            onChange={accept}
          />
          <label htmlFor="agree">He leído los términos y condiciones.</label>
          <br></br>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container justifyContent="center" alignItems="center">
          <Button
            className={classes.BotonContinuar}
            onClick={handleConfirm}
            disabled={!accepted}
          >
            Aceptar
          </Button>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default TermsAndConditionsMexico;
