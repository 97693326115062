import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
    ContenedorLectura:{
        width:'502px',
                  [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
    },
    
    ContainerTitulo:{
        margin:'0 0 46px 0',
        '& .Titulo':{
            letterSpacing: '0px',
            color: '#0899D9',
            margin:'0',
            textAlign:'center',
            font:'43px fredoka one',
            lineHeight:'52px',
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            margin:'0 0 36px 0',
            color:'#000',
            font:'18px lato',
            lineHeight :'28px',
            letterSpacing: '0.17px',
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },

        '& .Esp1':{
            margin:0,
        },
        '& .TextImg':{
            margin:'11px 0 0 0',
            color:'#717476',
            font:' 16px lato',
            lineHeight :'20px',
            letterSpacing: '0.15px',
            [theme.breakpoints.down(502)]:{
                font:' 14px lato',
                lineHeight :'18px',
                letterSpacing: '0.10px',

            },
        },
        '& .Cuadro':{
            position:'relative',
        },
        '& .Pos':{
            position:'absolute',
            margin:'40px 0 0 0',
            width:'90.24%',
            [theme.breakpoints.down(502)]:{
                margin:'30px 0 0 0',

            }
        },
        '& .Imagen':{
            width:'100%'
        },
        '& .Imagen2':{
            width:'100%',
            [theme.breakpoints.down(502)]:{
                height:'175px'
            }
        },

    },
    

}));

const El_mundo_que_queremos_dejar = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >
                        El mundo<br /> que queremos dejar
                    </h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container justifyContent="center" xs={12}>
                        <p className='Lectura Esp1'>
                            ¿Alguna vez te has preguntado cómo será el mundo en el futuro? No en uno tan 
                            lejano como doscientos años, sino en sólo diez o quince. ¿Qué tipos de avances 
                            tecnológicos te imaginas que habrá? ¿Crees que los seres humanos podrán vivir en 
                            Marte o teletransportarse de un lugar a otro? ¿Cómo nos comunicaremos? ¿Y cuál 
                            será la situación del medio ambiente?
                        </p>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container xs={12} >
                        <p className="Lectura ">
                            Nos encontramos en un momento decisivo 
                            para la vida en el planeta. Esto se debe a 
                            que nuestras habilidades sociales y 
                            emocionales no se han desarrollado tan 
                            rápido como los avances tecnológicos. De 
                            hecho, en algunos sentidos hemos 
                            permitido que el mundo sea un lugar poco 
                            armónico y no tan limpio.<br/><br/> 
                            
                            La raza humana ha transformado su 
                            entorno para adaptarlo a sus necesidades. 
                            Se han cubierto de asfalto lugares 
                            naturales para que los autos se desplacen 
                            fácilmente. Bosques enteros se talan para 
                            construir muebles, cuadernos y libros. El 
                            agua y el aire están contaminados por las 
                            industrias y los medios de transporte. 
                            Además, cuando consumimos productos de 
                            forma desmedida favorecemos la 
                            desigualdad social y contribuimos a la 
                            extinción de varias especies.
                        </p>
                    </Grid>
                    <Grid container justifyContent='center' xs={12} >
                        <Grid container item justifyContent='center' xs={12}>
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img4to/Sesion28/1.png" alt="Tarjeta" />
                        </Grid>
                        <Grid container justifyContent='center' xs={12}>
                            <p className='TextImg'>Hoy en día, las ciudades son el lugar idóneo para comenzar a cambiar nuestros hábitos de consumo y reducir la contaminación.</p>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container xs={12} >
                        <p className="Lectura">
                            Pero las nuevas generaciones se han vuelto más conscientes del impacto que nuestra 
                            especie ocasiona en el planeta. Ha aumentado lentamente la sensibilidad de proteger 
                            los recursos naturales y mejorar nuestra relación con el mundo y los animales. Dos 
                            ejemplos de ello son las jóvenes Greta Thunberg y Malalah Yusafzay. Greta ha 
                            defendido el medio ambiente desde que tenía 15 años, y Malalah es una luchadora 
                            social que ha apoyado el derecho a estudiar de las niñas en el Medio Oriente, 
                            también a la misma edad. Ellas levantaron la voz y representan a toda una generación 
                            que anhela hacer las cosas de mejor manera.<br/><br/>
                            
                            Para miles de personas alrededor del mundo es necesario trabajar desde hoy por el 
                            futuro, uno en el que todos los que existimos y aquellos que vivirán después puedan 
                            gozar de una vida digna, plena y llena de oportunidades. El tiempo de actuar es 
                            ahora. Mahatma Gandhi fue una de las personas más importantes en la 
                            independencia de la India. Él decía lo siguiente: “Sé el cambio que quieres ver en el
                            mundo”. Es una invitación a tomar medidas y a empezar a hacer las cosas que nos 
                            parecen importantes para cambiar la situación del mundo.
                        </p>
                    </Grid>
                    <Grid container justifyContent='center' xs={12} >
                        <Grid container item justifyContent='center' xs={12}>
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img4to/Sesion28/2.png" alt="Tarjeta" />
                        </Grid>
                        <Grid container justifyContent='center' xs={12}>
                            <p className='TextImg'>Está en nuestras manos decidir el futuro que queremos tener y el mundo que queremos dejar a las futuras generaciones.</p>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container xs={12} >
                        <p className="Lectura">
                            Lee este fragmento de la canción <em>Hoy hace un buen día</em>, escrita por el mexicano 
                            Fernando Delgadillo; en ella podrás vislumbrar una visión del futuro que podemos 
                            lograr:
                        </p>
                    </Grid>
                    <Grid container justifyContent='center' className='Cuadro' xs={12} >
                        <img className="Imagen2 " src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img4to/Sesion28/3R.png" alt="Tarjeta" />
                        <p className='Pos Lectura '>
                            “Y si ellos miran hacia atrás de lo que les toca empezar 
                            y nos hallan a nosotros que formamos un lugar 
                            que un buen día nos marcharemos y tal vez podrán decir 
                            en verdad que fueron grandes los viajeros que cruzaron por aquí.”
                        </p>

                    </Grid>
                </Grid>
        </Grid>
    </Grid>
    );
}
 
export default El_mundo_que_queremos_dejar;
