const types = {
  login: "[auth] login",
  logout: "[auth] logout",
  reLogin: "relogin",
  passwordChange: "passwordChange",
  perfilChanges: "perfilChanges",
  totalActividades:'totalActividades',
  loadReadings: "loadReadings",
  addOneFromFavorites: "addOneFromFavorites",
  removeOneFromFavorites: "removeOneFromFavorites",
  loadActivitiesList: "loadActivitiesList",
  insignias: "insignias",
};

export default types;
