import React, { useEffect } from "react";
import { Grid, makeStyles, Button } from "@material-ui/core";
import axios from "../../../../services/axios";

import SearchIcon from "@material-ui/icons/Search";
import MenuItem from "@material-ui/core/MenuItem";
import Pagination from "@material-ui/lab/Pagination";
import ReadingCard from "./Reading";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

import VitalSourceAnt from "./CL_VTSL_antologias.png";
import VitalSourceNov from "./CL_VTSL_novelas.png";

import ContainerDocente from "../ContainerDocente";

import UsabilityContext from "../../../../usability/UsabilityContext";
import Loader from "../../../Loader/Loader";

const useStyles = makeStyles((theme) => ({
  ContainerBiblioteca: {
    height: "100%",
    background: "#E9FBF9",
    [theme.breakpoints.up(1921)]: {
      height: "100vh",
    },
  },

  TopBar: {
    height: "172px",
    background: "transparent linear-gradient(103deg, #4EA398 0%, #7848DA 100%) 0% 0% no-repeat padding-box;",
    [theme.breakpoints.down(600)]: {
      display: "none",
    },
  },

  ContainerLecturas: {
    position: "absolute",

    textAlign: "center",
    // height:'873px',
    gap: "15px",
    width: "1160px",
    background: "#FFFFFF ",
    top: "12vh",
    boxShadow: "0px 5px 9px #00000029",
    borderRadius: "38px 38px 0 0",
    paddingTop: "3.73%",
    boxSizing: "border-box",
    "& .Titulo": {
      margin: "0",
      font: "48px Fredoka One",
      textSlign: "center",
      letterSpacing: "0px",
      color: "#7058CE",
      opacity: 1,
      [theme.breakpoints.down(820)]: {
        font: "30px Fredoka One",
      },
    },
    [theme.breakpoints.down(1280)]: {
      width: "806px",
    },
    [theme.breakpoints.down(820)]: {
      width: "520px",
    },
    [theme.breakpoints.down(600)]: {
      // width: "520px",
      top: "80px",
      width: "100%",
      left: "0",
      // position: "relative",
    },
  },

  ContainerSerchBar: {
    position: "relative",
    height: "68px",
    marginBottom: "75px",
    justifyContent: "center",
    [theme.breakpoints.down(1280)]: {
      marginBottom: "45px",
    },
    [theme.breakpoints.down(820)]: {
      marginBottom: "15px",
      justifyContent: "space-around",
    },
  },

  SerchBar: {
    outline: "none",
    height: "100%",
    width: "49.31%",
    borderRadius: "36px",
    padding: "0 32px",
    border: "4px solid #EA9D90",
    textAlign: "left",
    letterSpacing: "0.63px",
    color: "#7A54BA",
    font: "600 20px lato",
    margin: " 0 10%",
    [theme.breakpoints.down(820)]: {
      margin: " 0",
      font: "600 16px lato",
      padding: "0 17px",
      height: "80%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "40%",
    },
  },

  SerchBarIcon: {
    right: "25%",
    // top: "25%",
    position: "absolute",
    fontSize: "40px",
    color: "#7A54BA",
    [theme.breakpoints.down(820)]: {
      right: "24%",
    },
    [theme.breakpoints.down(420)]: {
      right: "22%",
    },
  },

  ContainerLecturasImagenes: {
    minHeight: "75.95%",
    padding: "0 50px",
    [theme.breakpoints.down(820)]: {
      minHeight: "95%%",
      padding: "0 20px",
    },
  },
  ContainerTitulo: {
    // height: "20%",
    display: "grid",
    gridTemplateAreas: `"icon"
    "title"`,
    gridTemplateRows: "1fr 1fr",
    [theme.breakpoints.up("sm")]: {
      gridTemplateRows: "1fr",
      gridTemplateAreas: `". title icon"`,
      gridTemplateColumns: "25% 1fr 25%",
    },
  },
  title: {
    gridArea: "title",
  },
  containerVitalSource: {
    gridArea: "icon",
  },
  ContainerImg: {
    objectFit: "cover",
    height: "80%",
  },
  Icon: {
    cursor: "pointer",
    fontSize: "30px",
    color: "#F6644B",
  },
  linkButtonVitalSource: {
    position: "absolute",
    width: "200px",
    height: "60px",
    top: "10px",
    [theme.breakpoints.down(1280)]: {
      width: "150px",
      height: "40px",
    },
    [theme.breakpoints.down(600)]: {
      position: "unset",
    },
    [theme.breakpoints.down(520)]: {
      height: "auto",
    },
  },
  ButtonVitalSource: {
    // borderRadius:'50px',
    // border: '3px #7A54BA solid',
  },
  imgsVitalSource: {
    width: "180px",
    objectFit: "contain",
    [theme.breakpoints.down(1439)]: {
      height: "80%",
    },
    [theme.breakpoints.down(1280)]: {
      width: "150px",
      height: "40px",
    },
    [theme.breakpoints.down(820)]: {
      width: "60%",
      // height: "40px",
    },
    [theme.breakpoints.down(520)]: {
      width: "150px",
      // height: "40px",
    },
  },
  formControl: {
    // marginRight: "30px",
    [theme.breakpoints.down(820)]: {
      // marginRight: "5px",
      // height: "40px",
    },
    "& .Mostrar": {
      font: "bold 22px lato",
      color: "#7058CE",
      [theme.breakpoints.down("sm")]: {
        font: "bold 18px lato",
      },
    },
    "& .input": {
      font: "bold 18px lato",
      color: "#7058CE",
      [theme.breakpoints.down("sm")]: {
        font: "bold 12px lato",
      },
    },
  },
  Lecturas: {
    margin: "0 0 0 15px",
    font: "bold 20px lato",
    color: "#7058CE",
  },
  Paginacion: {
    "& .MuiPaginationItem-root": {
      height: "27px",
      minWidth: "27px",
      [theme.breakpoints.up("md")]: {
        height: "32px",
        minWidth: "32px",
      },
    },
  },
}));


const TeachersLibrary = ({location}) => {
  var LTI1 = "https://resolver.vitalsource.com/?context_token=8de3d0f0-c28f-013a-7721-1ea111954ca9"
  var LTI2 = "https://resolver.vitalsource.com/?context_token=71cc3840-c28f-013a-7723-1ea111954ca9"
  const classes = useStyles();
  const { groupData } = React.useContext(UsabilityContext);
  // const [group, setGroup] = React.useState(groupToEdit);
  const [loading, setLoading] = React.useState();

  const [per, setPer] = React.useState(6);
  const [totalCount, setTotalCount] = React.useState(0);
  const [page, setPage] = React.useState(1);
  const [filteredList, setFilteredList] = React.useState();
  const [gradeFilter, setGradeFilter]     = React.useState(0);
  const [toSearch, setToSearch] = React.useState("");
  const [readingTotals, setReadingTotals] = React.useState(0);
  const [optionGrades, setOptionGrades]   = React.useState([0]);
  const [imgToShow, setImgToShow] = React.useState(VitalSourceAnt);
  const [linkVt, setLink]                 = React.useState(LTI1);

  const [firstTimeLibrary, setFirstTimeLibrary] = React.useState(false);

  // const [forceFlattenGradesList, setForceFlattenGradesList] = React.useState(true);

  const [grade, setGrade] = React.useState(groupData?.id_grado || 0);

  useEffect(() => {
    switch (grade) {
      case 6:
        setOptionGrades([0,1,2,3,4,5,6])
      break;
      case 2:
        setOptionGrades([0,1,2])
      break;
      case 3:
        setOptionGrades([0,1,2,3])
      break;
      case 4:
        setOptionGrades([0,1,2,3,4])
      break;
      case 5:
        setOptionGrades([0,1,2,3,4,5])
      break;
    
      default:
        setOptionGrades([0])
        break;
    }
  }, [grade]); 

  useEffect(() => {
    if((grade === 4) || (grade === 5) || (grade === 6)){
       return(
        setImgToShow(VitalSourceNov),
        setLink(LTI2)

       )
    } else {
      return(
        setImgToShow(VitalSourceAnt),
        setLink(LTI1)

      )
    }

}, [])


  const handlegoToSearch = () => {

    setPage(1)
    // gradeFilter > 0 ?
    setLoading(true)
    axios
    .get(`/lectura/list?pagina=${1}&articulos=${per}&filtro=${toSearch}&grado=${gradeFilter || ""}`)
    .then((response) => {
      setFilteredList(response.data.resultado.lectura_lista);
      setTotalCount(response.data.resultado.paginacion.totalPaginas);
      setPage(response.data.resultado.paginacion.pagina)
      setReadingTotals(response.data.resultado.paginacion.totalRegistros);

    })
    .catch((error) => {
      console.log(error, "load")
    })
    .finally(() => {
        setLoading(false)
    })
  }

  const goToSearch = (changingParameter = {}) => {
    // setPage(1)
    // gradeFilter > 0 ?
    setLoading(true)
    axios
    .get(`/lectura/list?pagina=${changingParameter.page || page}&articulos=${changingParameter.per || per}&filtro=${toSearch || ""}&grado=${changingParameter.gradeFilter || gradeFilter || ""}`)
    .then((response) => {
      setFilteredList(response.data.resultado.lectura_lista);
      setTotalCount(response.data.resultado.paginacion.totalPaginas);
      // setPage(response.data.resultado.paginacion.pagina)
      setReadingTotals(response.data.resultado.paginacion.totalRegistros);

    })
    .catch((error) => {
      console.log(error, "load")
    })
    .finally(() => {
        setLoading(false)
    })
  }

  const backingFromLibrary = (changingParameter = {}) => {
    // setPage(1)
    // gradeFilter > 0 ?
  
    setLoading(true)
    axios
    .get(`/lectura/list?pagina=${changingParameter.page || page}&articulos=${changingParameter.per || per}&filtro=${changingParameter.toSearch || ""}&grado=${changingParameter.gradeFilter || gradeFilter || ""}`)
    .then((response) => {
      setFilteredList(response.data.resultado.lectura_lista);
      setTotalCount(response.data.resultado.paginacion.totalPaginas);
      // setPage(response.data.resultado.paginacion.pagina)
      setReadingTotals(response.data.resultado.paginacion.totalRegistros);

    })
    .catch((error) => {
      console.log(error, "load")
    })
    .finally(() => {
        setLoading(false)
    })
  }

  React.useEffect(() => {

    if(location?.state?.fromLibrary){

      setToSearch(location?.state?.toSearch)
      setPage(location?.state?.page)
      setGradeFilter(location?.state?.gradeFilter)

      backingFromLibrary({
        page: location?.state?.page ,
        gradeFilter: location?.state?.gradeFilter,
        toSearch: location?.state?.toSearch
      })
      window.history.replaceState({}, document.title)
      setFirstTimeLibrary(false)
    } else {

      goToSearch()
    }

  }, []);

  // React.useEffect(() => {
  //   goToSearch()
  // }, []);

  // const goToSearch = (toSearch) => {
  //   setPage(1)
  //   gradeFilter > 0 ?
  //   axios
  //   .get(`/lectura/list?pagina=${page}&articulos=${per}&filtro=${toSearch}&grado=${gradeFilter}`)
  //   .then((response) => {
  //     setFilteredList(response.data.resultado.lectura_lista);
  //     setTotalCount(response.data.resultado.paginacion.totalPaginas);
  //   })
  //   :
  //   axios
  //   .get(`/lectura/list?pagina=${page}&articulos=${per}&filtro=${toSearch}`)
  //   .then((response) => {
  //     setFilteredList(response.data.resultado.lectura_lista);
  //     setTotalCount(response.data.resultado.paginacion.totalPaginas);
  //   })
  // }

  // useEffect(() => {
  //   setFilteredList()
  //   const body = { grade: 1 };
  //   gradeFilter > 0 ?
  //     axios
  //     .get(`/lectura/list?pagina=${page}&articulos=${per}&grado=${gradeFilter}`)
  //     .then((response) => {
  //       setFilteredList(response.data.resultado.lectura_lista);
  //       setReadingTotals(response.data.resultado.paginacion.totalRegistros);
  //       setTotalCount(response.data.resultado.paginacion.totalPaginas);
  //       setPage(1)
  //     })
  //     :
  //     axios.get(`/lectura/list?pagina=${page}&articulos=${per}`, body).then((response) => {

  //       setFilteredList(response.data.resultado.lectura_lista);
  //       setReadingTotals(response.data.resultado.paginacion.totalRegistros);
  //       setTotalCount(response.data.resultado.paginacion.totalPaginas);
  //       setPage(1)
  //     });
  // }, [gradeFilter]);

  // useEffect(() => {
  //   handleToSearch()
  // }, []);


  // useEffect(() => {
  //   const body = { grade: 1 };
  //   gradeFilter > 0 ?
  //   axios
  //   .get(`/lectura/list?pagina=${page}&articulos=${per}&grado=${gradeFilter}`)
  //   .then((response) => {
  //     setFilteredList(response.data.resultado.lectura_lista);
  //     setTotalCount(response.data.resultado.paginacion.totalPaginas);
  //     setPage(1)

  //   })
  //   :
  //   axios.get(`/lectura/list?pagina=${page}&articulos=${per}`)
  //   .then((response) => {
  //     setFilteredList(response.data.resultado.lectura_lista);
  //     setTotalCount(response.data.resultado.paginacion.totalPaginas);
  //     setPage(1);

  //   });
  // }, [per]);

  // useEffect(() => {
  //   const body = { grade: 1 };
  //     gradeFilter > 0 ?
  //     axios
  //     .get(`/lectura/list?pagina=${page}&articulos=${per}&grado=${gradeFilter}`, body)
  //     .then((response) => {
  //       setFilteredList(response.data.resultado.lectura_lista);
    
  //     })
  //     :
  //     axios
  //     .get(`/lectura/list?pagina=${page}&articulos=${per}`, body)
  //     .then((response) => {
  //       setFilteredList(response.data.resultado.lectura_lista);
   
  //     });
  // }, [page]);


  const handleSearch = (event) => {
    setToSearch(event.target.value);
    
  };

  const changePer = (event) => {
    setPer(event.target.value);
    goToSearch({per: event.target.value})
  };

  const changeGradeFilter = (event) => {
    setPage(1);
    setGradeFilter(event.target.value);
    goToSearch({gradeFilter: event.target.value, page: 1})

  };

  const handleChange = (event,value) => {
    setPage(value);
    goToSearch({page: value})
    window.scroll({
      top: 100,
      left: 100,
    })
  };
  
  // React.useEffect(() => {
  //   setGroup(groupData.grupo);
  // }, [groupData.grupo]);

  // useEffect(() => {
  //   const body = { grade: 1 };
  //   !filteredList && 
  //     axios.get(`/lectura/list?pagina=${page}&articulos=${per}`, body).then((response) => {
  //       // console.log(response.data.resultado, "-----")
  //       setFilteredList(response.data.resultado.lectura_lista);
  //       setReadingTotals(response.data.resultado.paginacion.totalRegistros);
  //       setTotalCount(response.data.resultado.paginacion.totalPaginas);
  //     });
  // }, [group.grado]);

  React.useEffect(() => {
    setGrade(groupData?.id_grado);
  }, [groupData?.id_grado]);

  

  return (
    <ContainerDocente>
      {/* <Library /> */}
      <Grid container item xs={12} justifyContent="center" className={classes.ContainerBiblioteca}>
        <Grid container item xs={12} className={classes.TopBar}></Grid>
        <Grid container item className={classes.ContainerLecturas}>
          <Grid container item direction="row" justifyContent="center" className={classes.ContainerTitulo}>
            <Grid container item justifyContent="center" className={classes.title}>
              <p className="Titulo">Biblioteca virtual</p>
            </Grid>
            <Grid container item justifyContent="center" className={classes.containerVitalSource}>
              <a className={classes.linkButtonVitalSource} href={linkVt} target="blank">
                <Button className={classes.ButtonVitalSource}>
                  <img className={classes.imgsVitalSource} src={VitalSourceAnt} alt="" />
                </Button>
              </a>
            </Grid>
          </Grid>

          <Grid container item xs={12} alignItems="center" className={classes.ContainerSerchBar}>
            <FormControl className={classes.formControl}>
              <InputLabel className="Mostrar" id="demo-simple-select-label">
                Ver
              </InputLabel>
              <Select className="input" labelId="demo-simple-select-label" id="demo-simple-select" value={per} onChange={changePer}>
                <MenuItem value={6}>6</MenuItem>
                <MenuItem value={12}>12</MenuItem>
                <MenuItem value={24}>24</MenuItem>
              </Select>
            </FormControl>
            <input className={classes.SerchBar} type="text" placeholder="Buscar lectura" onChange={handleSearch} value={toSearch}/>
            <Button className={classes.SerchBarIcon} onClick={handlegoToSearch}>
              <SearchIcon />
            </Button>
            <FormControl className={classes.formControl}>
              <InputLabel className="Mostrar" id="demo-simple-select-grado">
                Grado
              </InputLabel>
              <Select className="input" labelId="demo-simple-select-grado" id="demo-simple-grado" value={gradeFilter} onChange={changeGradeFilter}>
                {
                  optionGrades.map((grado, index)=> {
                    return(
                      <MenuItem value={index}>{index === 0 ? 'Todo' : `${grado} °` }</MenuItem>
                    )
                  })
                }
                {/* <MenuItem value={0}>Todo</MenuItem>
                <MenuItem value={1}>1°</MenuItem>
                <MenuItem value={2}>2°</MenuItem>
                <MenuItem value={3}>3°</MenuItem>
                <MenuItem value={4}>4°</MenuItem> */}
              </Select>
            </FormControl>
{/* 
            <GradeSelector /> */}
             {/* <Button onClick={addToTheList}>xXx</Button> */}
          </Grid>
          <Grid container item xs={12} className={classes.ContainerLecturasImagenes} justifyContent="center" alignItems="center">
            {filteredList &&
              filteredList.map((reading) => {
                const el = reading.item || reading;
                return (
                  <ReadingCard
                    key={el.id_lectura + "reading"}
                    id={el.id_lectura}
                    title={el.nombre}
                    grade={el.grado}
                    image={el.img_min}
                    downloader={el.pdf}
                    sesion={el.sesion}
                    toSearch={toSearch}
                    page={page}
                    gradeFilter={gradeFilter}
                    firstTimeLibrary={!firstTimeLibrary}
                  />
                );
              })}
          </Grid>
          <Grid container justifyContent="space-around" alignItems="center">
            <Pagination
              className={classes.Paginacion}
              page={page}
              count={totalCount}
              onChange={handleChange}
              variant="outlined"
              color="primary"
            />
            <p className={classes.Lecturas}>{`Se encontraron ${readingTotals} lecturas`}</p>
          </Grid>
        </Grid>
      </Grid>
      {loading && <Loader/>}
    </ContainerDocente>
  );
};

export default TeachersLibrary;
