import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
   ContenedorLectura:{
        width:'502px',
         [theme.breakpoints.down(502)]:{
           width:'350px',
         },
   },
    ContainerTitulo:{
        margin:'0 0 46px 0',
        '& .Titulo':{
            letterSpacing: '0px',
            color: '#FF7B8B',
            margin:'0',
            textAlign:'center',
            font:'43px fredoka one',
            lineHeight:'52px',
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            margin:'0 0 36px 0 ',
            color:'#000',
            font:'18px lato',
            lineHeight :'28px',
            letterSpacing: '0.17px',
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .SubTitulo':{
            color:'#926DD1',
            margin:'0',
            font:'bold 22px lato',
            lineHeight :'24px',
            letterSpacing: '0.21px',
            [theme.breakpoints.down(502)]:{
                font:'bold 18px lato',
                lineHeight :'20px',
                letterSpacing: '0.16px',
            },
        },
        '& .TextImg':{
            margin:'24px 0 0 0',
            alignSelf:'flex-start',
            color:'#717476',
            font:' 16px lato',
            lineHeight :'25px',
            letterSpacing: '0.15px',
            [theme.breakpoints.down(502)]:{
                font:' 11px lato',
                lineHeight :'14px',
                letterSpacing: '0.10px',
            },
        },
        '& .Imagen':{
            width:'100%'
        },

    },
    

}));

const Jugando_con_la_historia = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>

            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >
                        Jugando con la historia
                    </h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                            ¿Los videojuegos y la historia se relacionan? Quizá esto te resulte poco evidente a 
                            primera vista, pues parecen dos temas completamente distintos. Pero te 
                            sorprenderá saber que comparten más cosas de las que imaginas. Existen al menos 
                            tres maneras en las que dichas áreas se vinculan. En esta lección las conocerás.<br/><br/> 
                            
                            <h3 className='SubTitulo'>La relación entre los videojuegos y la historia</h3><br/>
                            
                            Los videojuegos son un producto cultural de la actualidad y en el futuro brindarán 
                            bastante información a los estudios que se hagan sobre nuestro tiempo. Es por eso 
                            que la importancia de conservarlos comienza a ser aceptada poco a poco. Así, por 
                            ejemplo, en 2019 el Archivo Nacional de Cine y Sonido de Australia se convirtió en el 
                            primero en incluir videojuegos en su numerosa colección.
                        </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <Grid container >
                            <img className='Imagen' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img3ro/Sesion37/1.png"  alt="Tarjeta" />
                        </Grid>
                        <Grid container item justifyContent='center' >
                            <p className='TextImg'>Archivo Nacional de Cine y Sonido de Australia.</p>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} >
                    <p className="Lectura Esp">
                        Varios de ellos tienen relatos que se desarrollan en tiempos pasados. Para ello es 
                        necesario hacer investigaciones por medio de libros y documentos históricos. Los 
                        desarrolladores requieren saber cómo se vestía la gente en una época determinada, 
                        cómo eran sus ciudades y qué tipo de tecnología utilizaba, entre otros muchos 
                        detalles. Hay ejemplos de algunos que han hecho un trabajo extraordinario al recrear 
                        la vida en otros tiempos y lugares. <br/><br/>
                        
                        Uno de ellos es <em>Valiant Heart</em>s (Corazones valientes). La trama se lleva a cabo en 
                        Europa durante la Primera Guerra Mundial, la cual ocurrió entre 1914 y 1918. A 
                        diferencia de otros juegos que se sitúan en esa época, <em>Valiant Hearts</em> no centra su 
                        atención en las batallas, sino en la vida de las personas que se quedaron atrapadas en 
                        la guerra. De acuerdo con el especialista James Hornfischer, está basado en cartas 
                        reales que fueron escritas en las trincheras. Por eso recrea de una manera tan 
                        precisa dicha etapa.
                    </p>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent='center'>
                    <Grid container >
                        <img className='Imagen' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img3ro/Sesion37/2.png"  alt="Tarjeta" />
                    </Grid>                    
                    <Grid container item justifyContent='center'>
                        <p className='TextImg'>Una escena del videojuego <em>Valiant Hearts</em>.</p>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} >
                    <p className="Lectura ">
                        Asimismo, <em>Assassin’s Creed (El credo de los asesinos)</em> es reconocido por la exactitud de 
                        sus escenarios, particularmente de las casas y los edificios. Es una serie compuesta
                         por más de 11 partes. Cada una de ellas se ambienta en distintos periodos de la 
                         humanidad, desde el antiguo Egipto hasta la Revolución Industrial en el Reino Unido.<br/><br/> 
                         
                         Los videojuegos tienen la posibilidad de crear virtualmente distintos escenarios y 
                         tiempos históricos. Ello los convierte en una herramienta útil para la enseñanza de la 
                         historia. Algunos expertos como Hornfisher los consideran una fuente de interés para 
                         los estudiantes porque despiertan su curiosidad por aprender la materia. <br/><br/>
                         
                         Si pudieras producir un videojuego sobre cualquier momento histórico y temática, 
                         ¿cuál elegirías? ¿Por qué? Coméntalo con el resto de la clase e intercambien ideas. 
                         También puedes pedir ayuda a los adultos que te rodean.
                    </p>
                </Grid>
        
        </Grid>
    </Grid>
    );
}
 
export default Jugando_con_la_historia;
