import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
     Contenedor:{
        background:'#E9FBF9',
        width:'100vw',
        overflowX:'clip',
        [theme.breakpoints.down(1161)]:{
            background:'none',
           overflowX:'unset',
        },
        [theme.breakpoints.down(600)]:{
            marginTop: '79.51px',
        }
    },
  ContainerInfoLectura: {
    position: "relative",
    height: "86px",
    [theme.breakpoints.down(502)]:{
        height: "43px",
    },
    background: "#76D4C3",
    "& .BarraInfoLectura": {
      height: "86px",
      background: `Url(https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Barras/Azul.png)`,
        [theme.breakpoints.down(502)]:{
            height: "43px",
        },
    },
    "& .InfoLectura": {
      position: "absolute",
      height: "86px",
      width: "219px",
      boxSizing: "border-box",
      padding: "17.2px 0",
      background: "#FAFAFA",
      borderRadius: "50px",
      boxShadow: "2px 2px 2px 2px #00000029",
      zIndex: "4",
        [theme.breakpoints.down(502)]:{
            height: "43px",
            width: "109px",
            padding: "8.6px 0",
            borderRadius: "25px",
        },
    },
    "& .InfoLectura:before": {
      position: "absolute",
      display: "flex",
      boxSizing: "border-box",
      padding: "0 0 0 10px",
      justifyContent: "center",
      alignItems: "center",
      height: "39.88px",
      width: "39.88px",
      borderRadius: "50%",
      content: '""',
      boxShadow: "3px 0px .5px 0px #00000029",
      background: "#FAFAFA",
      left: "90px",
      top: "65px",
      transform: "rotate(0.25turn)",
      [theme.breakpoints.down(502)]:{
        left: "45px",
        top: "30px",
        height: "20.88px",
        width: "20.88px",
        },
    },
    "& .InfoLectura:after": {
      content: '""',
      height: "15px",
      width: "15px",
      borderTop: "2px solid #20A698",
      borderLeft: "2px solid #20A698",
      position: "absolute",
      bottom: "-10px",
      left: "Calc(50% - 8px)",
      transform: "rotate(0.625turn)",
      [theme.breakpoints.down(502)]:{
        height: "10px",
        width: "10px",
        left: "Calc(50% - 4.5px)",
        bottom: "-3px",
        borderTop: "1px solid #20A698",
        borderLeft: "1px solid #20A698",
        },
    },
    "& .Sesion": {
      borderLeft: "2px solid #D8D8D8",
    },
    "& .Dato": {
      margin: "0 0 5px 0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#20A698",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        margin: "0 0 2.5px 0",
        },
    },
    "& .Caracter": {
      margin: "0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#000000",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        },
    },   
},
    ContenedorLectura:{
        background:'#fff',
        width:'1160px',
        height:'100%',
        padding:'0',
        [theme.breakpoints.down(1161)]:{
            width:'768px',
          },
          [theme.breakpoints.down(768)]:{
            width:'350px',
          },
        '& .ContainerDerechos':{
            position:'absolute',
            bottom:'35px',
            [theme.breakpoints.down(1161)]:{
                display:'none',
            },
        },
        '& .Derechos':{
            textAlign:"center",
            margin:"0px",
            font:" 15px lato",
            color:"#000000",
        },
        '& .Escritorio':{
            display:'block',
            [theme.breakpoints.down(1161)]:{
                display:'none'
            },
        },
        '& .Tablet':{
            display:'none',
            [theme.breakpoints.down(1161)]:{
                display:'block'
            },
        }
    },
    Parrafo1:{
        position:'relative',
        '& .Pos':{
            position:'absolute'
        },
        '& .Tabla1':{
            top:'44.34%',
            left:'6.03%',
            [theme.breakpoints.down(1161)]:{
            top:'40.25%',
            },
        },
        '& .Tabla2':{
            top:'44.34%',
            left:'50.86%',
            [theme.breakpoints.down(1161)]:{
            top:'40.25%',
            },
        },
        '& .Titulo':{
            font:'43px fredoka one',
            letterSpacing: '0px',
            lineHeight:'52px',
            color: '#000000',
            margin:'0',
            top:'5.70%',
            textAlign:'center',
            [theme.breakpoints.down(1161)]:{
                font:'28px fredoka one',
                lineHeight:'34px',
                top:'4.03%',
            },
            [theme.breakpoints.down(768)]:{
                font:'14px fredoka one',
                lineHeight:'21px',
            },
        },
        '& .Texto':{
            lineHeight : '30px',
            font:'22px lato',
            color:'#000',
            margin:'0',
            letterSpacing: '0.20px',
            [theme.breakpoints.down(1161)]:{
                font:'18px lato',
                lineHeight :'28px',
                letterSpacing: '0.17px',
            },
            [theme.breakpoints.down(768)]:{
                font:'8px lato',
                lineHeight :'10px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'7.5px lato',
                lineHeight :'8px',
                letterSpacing: '0.09px',
            },
        },
        '& .SubTitulo':{
            font:'bold 30px lato',
            letterSpacing:'.24px',
            lineHeight:'30px',
            margin:'0',
            color:'#FCAC86',
            [theme.breakpoints.down(1161)]:{
                lineHeight :'28px',
                font:'bold 26px lato',
                letterSpacing: '0.24px',
            },
            [theme.breakpoints.down(768)]:{
                font:'bold 13px lato',
                lineHeight :'16px',
                letterSpacing: '0.21px',
            },
        },

        '& .SubTitulo2':{
            font:'bold 26px lato',
            letterSpacing:'.24px',
            lineHeight:'30px',
            margin:'0',
            color:'#76AEB4',
            [theme.breakpoints.down(1161)]:{
                lineHeight :'22px',
                font:'bold 18px lato',
                letterSpacing: '0.24px',
            },
            [theme.breakpoints.down(768)]:{
                textAlign:'center',
                font:'bold 12px lato',
                lineHeight :'16px',
                letterSpacing: '0.21px',
                width:'100px',
            },
            [theme.breakpoints.down(350)]:{
                textAlign:'center',
                font:'bold 11px lato',
                lineHeight :'14px',
            },
        },
        
        '& .SubTitulo3':{
            font:'bold 26px lato',
            letterSpacing:'.24px',
            lineHeight:'30px',
            margin:'0',
            color:'#FFFFFF',
            [theme.breakpoints.down(1161)]:{
                lineHeight :'28px',
                font:'bold 22px lato',
                letterSpacing: '0.24px',
                textAlign:'center',
            },
            [theme.breakpoints.down(768)]:{
                font:'bold 13px lato',
                lineHeight :'13px',
                letterSpacing: '0.21px',
            },
            [theme.breakpoints.down(350)]:{
                font:'bold 11px lato',
                lineHeight :'12px',
                letterSpacing: '0.21px',
            },
        },

        '& .Sub1':{
            top:'21.70%',
            [theme.breakpoints.down(1161)]:{
                top:'21.60%',
            },
            [theme.breakpoints.down(768)]:{
                top:'19.60%',
            },
        },
        '& .Sub2':{
            top:'24.39%',
            left:'12.41%',
            [theme.breakpoints.down(1161)]:{
                left:'8%',
            },
            [theme.breakpoints.down(768)]:{
                top:'22.39%',
                left:'15%',
            },
        },
        '& .Sub3':{
            top:'24.39%',
            left:'56.12%',
            [theme.breakpoints.down(1161)]:{
                left:'55.6%',
                top:'24.39%',
            },
            [theme.breakpoints.down(768)]:{
                top:'22.39%',
                left:'58%',
            },
        },
        '& .Sub4':{
            top:'41.09%',
            [theme.breakpoints.down(1161)]:{
                top:'36.81%',
            },
        },
        '& .Sub5':{
            top:'978px',
            left:'127px',
            [theme.breakpoints.down(1161)]:{
                top:'41.04%',
                width:'35.67%',
                left:'9.05%',

            },
        },
        '& .Sub6':{
            top:'978px',
            left:'671px',
            [theme.breakpoints.down(1161)]:{
                top:'41.04%',
                width:'32.16%',
                left:'57.03%',
            },
            
        },
        '& .Text1':{
            textAlign:'center',
            width:'71.03%',
            top:'10.06%',
            [theme.breakpoints.down(1161)]:{
                width:'65.36%',
                top:'7.13%',
            },
            [theme.breakpoints.down(768)]:{
                width:'85%',
            },
        },

        '& .Nube1':{
            top:'171px',
            left:'95px',
            [theme.breakpoints.down(1161)]:{
                width:'12.63%',
                top:'5.75%',
                left:'8.20%',
            },
        },

        '& .Nube2':{
            top:'171px',
            right:'91px',
            [theme.breakpoints.down(1161)]:{
                width:'12.63%',
                top:'5.75%',
                right:'7.30%',
            },
        },

        '& .Cuadro1':{
            top:'580px',
            left:'85px',
            [theme.breakpoints.down(1161)]:{
                left:'7.42%',
                top:'26.08%',
                width:'41.40%',
            },
        },
        '& .Cuadro2':{
            top:'580px',
            right:'97px',
            [theme.breakpoints.down(1161)]:{
                right:'7.81%',
                top:'26.08%',
                width:'41.40%',
            },
        },

        '& .Img1':{
            top:'593px',
            left:'90px',
            [theme.breakpoints.down(1161)]:{
                left:'7.81%',
                top:'26.08%',
                width:'40.36%',
            },
        },
        '& .Img2':{
            top:'573px',
            right:'107px',
            [theme.breakpoints.down(1161)]:{
                right:'7.81%',
                top:'26.08%',
                width:'40.36%',
            },
        },
        '& .Gris':{
            borderBottom:'solid 1.5px #353535',
            height:'1px',
            width:'423px',
            left:'108.5px',
            [theme.breakpoints.down(1161)]:{
                left:'9.50%',
                width:'36.58%',
            },
        },
        '& .Verde':{
            borderBottom:'solid 1.5px #20A698',
            height:'1px',
            width:'423px',
            left:'629px',
            [theme.breakpoints.down(1161)]:{
                left:'54.55%',
                width:'36.58%',
            },
            
        },
        '& .ulGris':{
            color:'#A3A3A3',
            font:'bold 22px lato',
            margin:'0',
            left:'109px',
            padding:'0 0 0 25px',
            [theme.breakpoints.down(1161)]:{
                font:'bold 18px lato',
                left:'8.50%',
            },
            [theme.breakpoints.down(768)]:{
                font:'bold 10px lato',
                padding:'0 0 0 10px',
                left:'8.5%',
            },
        },

        '& .ulVerde':{
            color:'#74D399',
            font:'bold 22px lato',
            margin:'0',
            left:'629px',
            padding:'0 0 0 25px',
            [theme.breakpoints.down(1161)]:{
                font:'bold 18px lato',
                left:'53.55%',
            },
            [theme.breakpoints.down(768)]:{
                font:'bold 10px lato',
                padding:'0 0 0 10px',
                left:'53.55%',
            },

        },
        '& .ulVerde1':{
            color:'#74D399',
            font:'bold 22px lato',
            width:'361px',
            padding:'0 0 0 15px',
            [theme.breakpoints.down(1161)]:{
                font:'bold 18px lato',
                width:'83%',
            },
            [theme.breakpoints.down(768)]:{
                font:'bold 11px lato',
                padding:'0 0 0 10px',
            },
            [theme.breakpoints.down(350)]:{
                width:'80%',
            },
        },
        '& .ul1':{
            width:'395px',
            [theme.breakpoints.down(1161)]:{
                width:'34.11%',
            },
        },
        '& .ul2':{
            width:'395px',
            [theme.breakpoints.down(1161)]:{
                width:'36.19%',
            },
            [theme.breakpoints.down(768)]:{
                width:'37%',
            },
        },
        '& .ul3':{
            width:'395px',
            [theme.breakpoints.down(1161)]:{
                width:'33.33%',
            },
        },
        '& .ul4':{
            width:'395px',
            [theme.breakpoints.down(1161)]:{
                width:'32.03%',
            },
            [theme.breakpoints.down(768)]:{
                width:'34%',
            },
        },
        '& .ul5':{
            width:'391px',
            [theme.breakpoints.down(1161)]:{
                width:'35.41%',
            },
        },
        '& .ul6':{
            width:'391px',
            [theme.breakpoints.down(1161)]:{
                width:'34.63%',
            },
        },
        '& .ul7':{
            width:'391px',
            [theme.breakpoints.down(1161)]:{
                width:'36.06%',
            },
        },
        '& .ul8':{
            width:'370px',
            [theme.breakpoints.down(1161)]:{
                width:'36.97%',
            },
            [theme.breakpoints.down(768)]:{
                width:'36%',
            },
        },
        '& .Linea1':{
            top:'1182.5px',
            [theme.breakpoints.down(1161)]:{
                top:'53.39%',
            },
        },
        '& .Linea2':{
            top:'1297.5px',
            [theme.breakpoints.down(1161)]:{
                top:'59.20%',
            },
        },
        '& .Linea3':{
            top:'1755.5px',
            [theme.breakpoints.down(1161)]:{
                top:'85.87%',
            },
        },
        '& .Ul1':{
            top:'1053px',
            [theme.breakpoints.down(1161)]:{
                top:'45.81%',
            },
            [theme.breakpoints.down(768)]:{
                top:'45.21%',
            },
        },
        '& .Ul2':{
            top:'1195px',
            [theme.breakpoints.down(1161)]:{
                top:'54.37%',
            },
            [theme.breakpoints.down(768)]:{
                top:'53.80%',
            },
        },
        '& .Ul3':{
            top:'1313px',
            [theme.breakpoints.down(1161)]:{
                top:'60.18%',
            },
            [theme.breakpoints.down(768)]:{
                top:'59.5%',
            },
        },
        '& .Ul4':{
            top:'1771px',
            [theme.breakpoints.down(1161)]:{
                top:'86.76%',
            },
            [theme.breakpoints.down(768)]:{
                top:'86.56%',
            },
        },
        '& .Imagen':{
            [theme.breakpoints.down(768)]:{
                width:'100%'
            },
        },
        '& .Imagen2':{
            [theme.breakpoints.down(768)]:{
                width:'43.35%'
            },
        }


      
    }
}));

const Redes_eléctricas_inteligentes = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
             <Grid container item justifyContent="center" className={classes.ContainerInfoLectura}>
               <Grid container className="BarraInfoLectura"></Grid>
               <Grid container item className="InfoLectura">
                    <Grid container item direction="column" xs={6} justifyContent="center">
                        <p className="Dato">Grado</p>
                        <p className="Caracter">5</p>
                    </Grid>
                    <Grid container item direction="column" xs={6} justifyContent="center" className="Sesion">
                        <p className="Dato">Sesión</p>
                        <p className="Caracter">14</p>
                    </Grid>
               </Grid>
            </Grid>

            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                <img className="Escritorio" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion14/6.png" alt="Tarjeta" />
                <img className="Tablet Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion14/6R.png" alt="Tarjeta" />
                <h2 className="Pos Titulo">Redes eléctricas inteligentes</h2>
                <img className='Pos Tabla1 Imagen2 Escritorio' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion14/4.png" alt="Tarjeta" />
                <img className='Pos Tabla2 Imagen2 Escritorio' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion14/5.png" alt="Tarjeta" />
                <img className='Pos Tabla1 Imagen2 Tablet' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion14/4R.png" alt="Tarjeta" />
                <img className='Pos Tabla2 Imagen2 Tablet' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion14/5R.png" alt="Tarjeta" />
                <img className='Pos Nube1' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion14/3.png" alt="Tarjeta" />
                <img className='Pos Nube2' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion14/8.png" alt="Tarjeta" />
                <img className='Pos Imagen Cuadro1' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion14/7.png" alt="Tarjeta" />
                <img className='Pos Imagen Cuadro2' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion14/7.png" alt="Tarjeta" />
                <img className='Pos Imagen Img1' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion14/1.png" alt="Tarjeta" />
                <img className='Pos Imagen Img2' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion14/2.png" alt="Tarjeta" />
                
                <p className='Pos Texto Text1'>
                <h3 className='SubTitulo '>La energía del futuro</h3><br/>
                    Se estima que para el año 2050, el 65 % de la población mundial vivirá en ciudades. En ese escenario, la demanda de electricidad y la contaminación aumentarán bastante. Una buena alternativa para evitarlo son las redes eléctricas inteligentes. En este modelo, la energía eléctrica no proviene de una sola planta, sino que la red misma la produce, la almacena y la distribuye a todo aquél que la necesite.
                </p>

                <h3 className='Pos SubTitulo Sub1'>Niveles de aplicación</h3>
                <h3 className='Pos SubTitulo2 Sub2'>Red inteligente a nivel de hogar</h3>
                <h3 className='Pos SubTitulo2 Sub3'>Red inteligente a nivel ciudad</h3>
                <h3 className='Pos SubTitulo Sub4'>Diferencias entre los dos sistemas</h3>
                <h3 className='Pos SubTitulo3 Sub5 Escritorio'>Sistemas eléctricos tradicionales</h3>
                <h3 className='Pos SubTitulo3 Sub6 Escritorio'>Redes eléctricas inteligentes</h3>
                <h3 className='Pos SubTitulo3 Sub5 Tablet'>Sistemas eléctricos <br/>tradicionales</h3>
                <h3 className='Pos SubTitulo3 Sub6 Tablet'>Redes eléctricas <br/>inteligentes</h3>

                <div className='Pos Linea1 Gris'></div>
                <div className='Pos Linea2 Gris'></div>
                <div className='Pos Linea3 Gris'></div>
                <div className='Pos Linea1 Verde'></div>
                <div className='Pos Linea2 Verde'></div>
                <div className='Pos Linea3 Verde'></div>

                <ol type="d" start="1" className='Pos ulGris Ul1 ul1'>
                    <li className='li'>
                        <p className='Texto'>La energía que generan se pierde si no se utiliza.</p>
                    </li>
                </ol>
                <ol type="d" start="2" className='Pos ulGris Ul2 ul2'>
                    <li className='li'>
                        <p className='Texto'>Sus fuentes de energía principales (carbón, petróleo, gas natural y uranio) no son renovables.</p>
                    </li>
                </ol>
                <ol type="d" start="3" className='Pos ulGris Ul3 ul3'>
                    <li className='li'>
                        <p className='Texto'>Son la primera fuente de emisiones de dióxido de carbono y tienen graves consecuencias ambientales: lluvia ácida, cambio climático y deforestación.</p>
                    </li>
                </ol>
                <ol type="d" start="4" className='Pos ulGris Ul4 ul4'>
                    <li className='li'>
                        <p className='Texto'>El usuario tiene poco control de su consumo y no suele relacionarse con la producción de la energía.</p>
                    </li>
                </ol>


                <ol type="d" start="1" className='Pos ulVerde Ul1 ul5'>
                    <li className='li'>
                        <p className='Texto'>Almacenan energía y gestionan cuánta enviar a cada zona. Además, son más eficientes: presentan menos pérdidas y desperdicios de energía.</p>
                    </li>
                </ol>
                <ol type="d" start="2" className='Pos ulVerde Ul2 ul6'>
                    <li className='li'>
                        <p className='Texto'>Muchas de sus fuentes son renovables.</p>
                    </li>
                </ol>
                <ol type="d" start="3" className='Pos ulVerde Ul3 ul7'>
                    <li className='li'>
                        <p className='Texto'>Ayudan a disminuir las emisiones de dióxido de carbono y, con ello, el calentamiento global, contribuyendo de las siguientes formas:</p>
                    </li>
                    <ol type="a" start="a" className='Pos ulVerde1 '>
                        <li className='li'>
                            <p className='Texto'>Están adaptadas a las necesidades de los autos eléctricos.</p>
                        </li>
                        <li className='li'>
                            <p className='Texto'>La energía no procede de una sola empresa proveedora, sino de varias. El sistema es más flexible y puede integrar diversas fuentes de energía alternativa y limpia, tales como la eólica, la solar (generada por celdas fotovoltaicas y calentadores solares) y la biomasa.</p>
                        </li>
                    
                    </ol>
                </ol>
                <ol type="d" start="4" className='Pos ulVerde Ul4 ul8'>
                    <li className='li'>
                        <p className='Texto'>Le permiten al usuario saber en todo momento cuánto consume y cuánto le cuesta. Asimismo, lo invitan a relacionarse con la producción de energía, para hacerlo un usuario responsable o un posible productor en la red.</p>
                    </li>
                </ol>




                <Grid container item xs={12} justifyContent="center" className="ContainerDerechos">
                    <p className="Derechos">
                        © 2022. Todos los derechos reservados, Pearson, Circuito de lectores.
                    </p>                
                </Grid>
                </Grid>

                
            </Grid>
    </Grid>
    );
}
 
export default Redes_eléctricas_inteligentes;
