import { Button, Grid,makeStyles}              from '@material-ui/core';
import React, { useContext, useEffect } from "react";
import DrawerContext from "../../../../../usability/DrawerContext";
import { useOnLoadImages } from "../../../../hooks/useOnLoadImages";
import Loader from "../../../../Loader/Loader";
import Drawer from "../../ReadingTools/Drawer";

const useStyles =  makeStyles (theme =>({
     Contenedor:{
        background:'#E9FBF9',
        width:'100vw',
        overflowX:'clip',
        [theme.breakpoints.down(1161)]:{
            background:'none',
           overflowX:'unset',
        },
        [theme.breakpoints.down(600)]:{
            marginTop: '79.51px',
        }
    },
  ContainerInfoLectura: {
    position: "relative",
    height: "86px",
    background: "#76D4C3",
    "& .BarraInfoLectura": {
      height: "86px",
      background: `Url(https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Barras/Azul.png)`,
    },
    "& .InfoLectura": {
      position: "absolute",
      height: "86px",
      width: "219px",
      boxSizing: "border-box",
      padding: "17.2px 0",
      background: "#FAFAFA",
      borderRadius: "50px",
      boxShadow: "2px 2px 2px 2px #00000029",
      zIndex: "4",
    },
    "& .InfoLectura:before": {
      position: "absolute",
      display: "flex",
      boxSizing: "border-box",
      padding: "0 0 0 10px",
      justifyContent: "center",
      alignItems: "center",
      height: "39.88px",
      width: "39.88px",
      borderRadius: "50%",
      content: '""',
      boxShadow: "3px 0px .5px 0px #00000029",
      background: "#FAFAFA",
      left: "90px",
      top: "65px",
      transform: "rotate(0.25turn)",
    },
    "& .InfoLectura:after": {
      content: '""',
      height: "15px",
      width: "15px",
      borderTop: "2px solid #20A698",
      borderLeft: "2px solid #20A698",
      position: "absolute",
      bottom: "-10px",
      left: "Calc(50% - 8px)",
      transform: "rotate(0.625turn)",
    },
    "& .Sesion": {
      borderLeft: "2px solid #D8D8D8",
    },
    "& .Dato": {
      margin: "0 0 5px 0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#20A698",
    },
    "& .Caracter": {
      margin: "0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#000000",
    },
       '& .Regresar':{
        color: '#F2624F',
        border: '#F2624F 1px solid',
        height: '30px',
        width:'100px',
        borderRadius: '20px',
        letterSpacing: '0.14px',
        textTransform: 'none',
        background:'#FEFAF1',
        top:'calc(50% - 15px)',
        left:'5%',
        zIndex:4,
        [theme.breakpoints.down(502)]: {
          font:'bold 10px lato',
          width: "60px",
        },
      },
    '&.Regresar':{
      color: '#F2624F',
      border: '#F2624F 1px solid',
      height: '30px',
      width:'100px',
      borderRadius: '20px',
      letterSpacing: '0.14px',
      textTransform: 'none',
      background:'#FEFAF1',
      top:'calc(50% - 15px)',
      left:'5%',
      [theme.breakpoints.down(502)]: {
        width: "75px",
      },
    },
  },
    ContenedorLectura:{
        background:'#fff',
        width:'1160px',
        height:'100%',
       padding:'60px 168px 35px ',
        
        transform: (props) =>
        props.scale > 1 ? `scale(${props.scale}) translate(0px, 10%)` : `none`,
        [theme.breakpoints.down(1161)]:{
            width:'502px',
            padding:'60px 0 0 0',
          },
        [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },

        '& .Escritorio':{
            display:'block',
            [theme.breakpoints.down(1161)]:{
                display:'none'
            },
        },
        '& .Tablet':{
            display:'none',
            [theme.breakpoints.down(1161)]:{
                display:'block'
            },
        }
    },
    ContainerTitulo:{
        margin:'0 0 46px 0',
        '& .Titulo':{
            font:'48px fredoka one',
            letterSpacing: '0px',
            color: '#EEA90A',
            margin:'0',
            textAlign:'center',
            [theme.breakpoints.down(1161)]:{
                font:'43px fredoka one',
                lineHeight:'52px',
            },
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            lineHeight : '30px',
            margin:'0 0 36px 0 ',
            font:'22px lato',
            color:'#000',
            letterSpacing: '0.21px',
            lineHeight:'30px',
            [theme.breakpoints.down(1161)]:{
                font:'18px lato',
                lineHeight :'28px',
                letterSpacing: '0.17px',
            },
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .SubTitulo':{
            margin:'0',
            font:'bold 30px lato',
            lineHeight:'30px',
            letterSpacing:'.28px',
            color:'#D1270A',
            [theme.breakpoints.down(1161)]:{
                font:'bold 22px lato',
                lineHeight :'24px',
                letterSpacing: '0.21px',
            },
            [theme.breakpoints.down(502)]:{
                font:'bold 18px lato',
                lineHeight :'20px',
                letterSpacing: '0.16px',
            },
        },
        '& .TextImg':{
            margin:'22px 0 0 0',
            font:'20px lato',
            alignSelf:'flex-start',
            color:'#717476',
            letterSpacing:'.19px',
            [theme.breakpoints.down(1161)]:{
                font:' 16px lato',
                lineHeight :'25px',
                letterSpacing: '0.15px',
            },
            [theme.breakpoints.down(502)]:{
                font:' 11px lato',
                lineHeight :'14px',
                letterSpacing: '0.10px',
            },
        },
        '& .Imagen':{
            [theme.breakpoints.down(1161)]:{
                width:'100%'
            },
        }
        

    },
    

}));

const La_curiosa_supervivencia_de_las_plantas = (props) => {

  const {activeStep, setActiveStep, handlePreviewStep, id_actividad} = props

  const handleContinue = () => {
    setActiveStep(activeStep - 1)
    handlePreviewStep()
  }

  const {
    handleSize,
    disabledZoom,
    closeZoom,
    handleZoom,
    scale
  }= useContext(DrawerContext);
  const [loading, setLoading] = React.useState(true);
  const wrapperRef = React.useRef(null);
  const imagesLoaded = useOnLoadImages(wrapperRef);

  const detectSize = () => {
    const lectura = document.getElementById("lectura");
    const newWidth = lectura?.offsetWidth;
    const newHeight = lectura?.offsetHeight;
    setLoading(false);
    if (lectura) handleSize(newHeight, newWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", detectSize);
    return () => {
      handleZoom()
      window.removeEventListener("resize", detectSize);
      closeZoom()
    };
  }, []);

  const classes = useStyles({ disabledZoom,scale });
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
             <Grid container item justifyContent="center" className={classes.ContainerInfoLectura}>
                       <Grid container className="BarraInfoLectura">
            <Button className="Regresar" onClick={handleContinue}>
                Regresar
            </Button>
        </Grid>
               <Grid container item className="InfoLectura">
                    <Grid container item direction="column" xs={6} justifyContent="center">
                        <p className="Dato">Grado</p>
                        <p className="Caracter">2</p>
                    </Grid>
                    <Grid container item direction="column" xs={6} justifyContent="center" className="Sesion">
                        <p className="Dato">Sesión</p>
                        <p className="Caracter">33</p>
                    </Grid>
               </Grid>
            </Grid>
            {!imagesLoaded && <Loader />}
            <Grid
            container
            item
            className={classes.ContenedorLectura}
            justifyContent="center"
            id="lectura"
            onLoad={detectSize}
            ref={wrapperRef}
            >
            <Drawer id_lectura={id_actividad} />
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >
                        La curiosa supervivencia  <br/>de las plantas
                    </h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                            <h3 className='SubTitulo'>¿Cómo se reproducen las plantas?</h3><br/>

                            Las plantas florales son tan antiguas como los dinosaurios. Han sobrevivido y 
                            evolucionado hasta nuestros días gracias a estrategias muy ingeniosas.
                        </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <Grid container >
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img2do/Sesion33/1.png"  alt="Tarjeta" />
                        </Grid>
                        <Grid container>
                            <p className='TextImg'>
                                Los fósiles de plantas son un elemento crucial para comprender cómo fue su vida y cómo ha 
                                sido su evolución.
                            </p>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                        Como sabes, la reproducción es una característica que les permite a todos los 
                        organismos continuar existiendo como especie y no desaparecer de la Tierra. Para 
                        las plantas este proceso resulta una aventura de colores y aromas que involucra la 
                        participación de otros seres vivos.<br/><br/>
                        
                        Pero si ellas no pueden moverse para buscar pareja y reproducirse, ¿cómo lo logran? 
                        Hay distintas maneras de hacerlo, dependiendo de la especie. A continuación te lo 
                        mostramos.<br/><br/>
                        
                        Algunas pueden hacerlo a partir de sus propias ramas. Cuando se desprenden, éstas 
                        echan raíces y generan una planta nueva. Eso sucede, por ejemplo, con las cactáceas.<br/><br/>
                        
                        Otras logran la unión de dos células especiales que crean un fruto. Éste protege a las 
                        semillas a partir de las cuales crecerá la planta. Tal es el caso de los árboles y las 
                        plantas frutales.<br/><br/>
                        
                        En el caso de las florales (llamadas <em>angiospermas</em>), las flores son su aparato 
                        reproductor y el polen es la semilla que fecunda a otras plantas. Debido a que están 
                        fijas en el suelo es difícil que su polen les llegue a las demás. Por eso les piden ayuda a 
                        otros seres vivos (como las abejas) y a los elementos (como el agua o el aire).<br/><br/>
                        
                        La labor de convencimiento de las plantas para recibir ayuda no es fácil. Las flores 
                        deben ser atractivas a la vista o al olfato. Muchas tienen pétalos de colores 
                        llamativos y brillantes, con formas distintas. Las blancas, en cambio, producen olores 
                        que atraen a los insectos, a las aves y hasta a los murciélagos. Después de consumir 
                        su néctar, estos animales visitan otras flores y llevan consigo el polen que las 
                        fecundará.<br/><br/>
                        
                        Por supuesto, también existen plantas que no son florales. Éstas producen semillas 
                        llamadas <em>esporas</em>, cuyas formas les permiten transportarse con ayuda del viento.<br/><br/>
                        
                        Fascinante, ¿no? Piensa en todo esto cuando sientas que la gran diversidad de la flora 
                        terrestre te asombra.
                        </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <Grid container >
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img2do/Sesion33/2.png"  alt="Tarjeta" />
                        </Grid>
                        <Grid container>
                            <p className='TextImg'>
                                Las formas de reproducción de las plantas son tan variadas como su color. Mira a tu
                                alrededor: ¿qué forma de reproducción utilizan las plantas que hay cerca de ti?
                            </p>
                        </Grid>
                    </Grid>
                </Grid>
        </Grid>
        <Grid container item style={{ marginTop: 80}} ></Grid>
    </Grid>
    );
}
 
export default La_curiosa_supervivencia_de_las_plantas;
