import { makeStyles, withStyles } from "@material-ui/core";
import React from "react";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@material-ui/core/Typography";
import Estrella from "../imgs/estrella amarilla.png";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  Favoritos: {
    position: "relative",
    // height: "83px",
    // width: "93px",
    boxSizing: "border-box",
    height: "100%",
    width: "100%",
    // width: "18%",
    // margin: "1% 1%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    background: "#FFFFFF",
    boxShadow: "0px 2px 5px #00000029",
    border: "2px solid #2E313C1A",
    borderRadius: "15px",
    gap: "10px",
    padding: "5px",
    textDecoration: "none",
    "&:hover": {
      background: "#84E9DF",
      // '&:before':{
      //     display:(props)=> props.open ? 'none': 'flex',
      //     textTransform: 'none',
      //     justifyContent:'center',
      //     alignItems:'center',
      //     borderRadius:'25px',
      //     position:'relative',
      //     content:'"Fix el elefante',
      //     top:'-40px',
      //     height:'100px',
      //     width:'100px',
      //     background:'orange',
      //     font:'bold 14px lato'
      // },
    },
    "& .EstrellaFavorito": {
      // position: "absolute",
      // bottom: "40px",
      // height: "38px",
      width: "30%",
      // width: "40px",
    },
    "& .LecturaFavorita": {
      font: "700 12px lato",
      // position: "absolute",
      // bottom: "13px",
      // font: "700 12px lato",
      // margin: "0",
      overflow: "hidden",
      // position: "absolute",
      // bottom: "0px",
      margin: "0",
      // height: "40px",
      color: "#000000",
      maxWidth: "100px",
      textAlign: "center",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      [theme.breakpoints.up("sm")]: {
        fontSize: "12px",
        maxWidth: "65px",
      },
    },
  },
  default: {
    position: "relative",
    // height: "83px",
    // width: "93px",
    boxSizing: "border-box",
    height: "100%",
    width: "100%",
    // width: "18%",
    // margin: "1% 1%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    background: "#99999940",
    boxShadow: "0px 2px 5px #00000029",
    border: "2px solid #2E313C1A",
    borderRadius: "15px",
    gap: "10px",
    padding: "5px",
    textDecoration: "none",
    "& .EstrellaFavorito": {
      // position: "absolute",
      // bottom: "40px",
      // height: "38px",
      width: "30%",
      // width: "40px",
    },
    "& .LecturaFavorita": {
      font: "700 12px lato",
      // position: "absolute",
      // bottom: "13px",
      // font: "700 12px lato",
      // margin: "0",
      overflow: "hidden",
      // position: "absolute",
      // bottom: "0px",
      margin: "0",
      // height: "40px",
      color: "#000000",
      maxWidth: "100px",
      textAlign: "center",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      [theme.breakpoints.up("sm")]: {
        fontSize: "12px",
        maxWidth: "65px",
      },
    },
  },
}));

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#f5f5f9",
    font: "34px Fredoka One",
    color: "blue",
    border: "1px solid #dadde9",
  },
}))(Tooltip);

const LecturaButton = ({ id_lectura, nombre, grade, sesion, rol }) => {
  const classes = useStyles();
  return (
    <>
      {id_lectura ? (

 
            <HtmlTooltip
              key={id_lectura}
              title={
                <React.Fragment>
                  <Typography color="inherit">{nombre}</Typography>
                </React.Fragment>
              }
              placement="top"
              disableFocusListener
            >
              <Link
                className={classes.Favoritos}
                // to={`/${id_lectura}`}
                to={{
                  pathname: `/lecturas/${grade}/${sesion}`,
                  state: { sesion: sesion, grade: grade },
                }}
              >
                <img
                  className="EstrellaFavorito"
                  src={Estrella}
                  alt="Favorite"
                />
                <p className="LecturaFavorita">{nombre}</p>
              </Link>
            </HtmlTooltip>

      ) : (
        <>
          <button className={classes.default} disabled>
            <img className="EstrellaFavorito" src={Estrella} alt="Favorite" />
            <p className="LecturaFavorita">{nombre}</p>
          </button>
        </>
      )}
    </>
  );
};

export default LecturaButton;
