import React, { useEffect } from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";

import { GridCloseIcon } from "@mui/x-data-grid";
import { makeStyles } from "@material-ui/core";
import { Grid } from "@mui/material";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    // padding: theme.spacing(2),

    [theme.breakpoints.up("sm")]: {
      // margin: "20px 0",
      paddingTop: "0",
      paddingBottom: "0",
    },
  },
  "& .MuiDialog-paper": {
    borderRadius: "12.1px",
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
    display: "flex",
    justifyContent: "center",
    gap: "5%",
  },
  "& .MuiDialogTitle-root": {
    [theme.breakpoints.up("sm")]: {
      // margin: "20px 0",
      padding: "22px",
    },
  },
}));

const useStyles = makeStyles((theme) => ({
  containerContent: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    gap: "20px",
    [theme.breakpoints.up("md")]: {
      minWidth: "680px",
    },
  },
  button: {
    backgroundColor: "#B965D3",
    borderRadius: "4px",
    boxSizing: "borderBox",
    outline: "0",
    border: "0",
  },
  text: {
    fontFamily: "Fredoka One",
    margin: 0,
    fontSize: "24px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "0.23px",
    textAlign: "center",
    color: "#000",
    [theme.breakpoints.up("lg")]: {
      fontSize: "32px",
    },
  },
  counter: {
    fontFamily: "Fredoka One",
    margin: 0,
    fontSize: "20px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "0.23px",
    textAlign: "center",
    color: "#9051b1",

    [theme.breakpoints.up("lg")]: {
      fontSize: "32px",
    },
  },

  counter_min: {
    fontFamily: "Fredoka One",
    margin: 0,
    fontSize: "14px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "0.23px",
    textAlign: "center",
    color: "#9051b1",
    [theme.breakpoints.up("lg")]: {
      fontSize: "20px",
    },
  },
  paragraph: {
    fontFamily: "Lato",
    fontSize: "15px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "0.23px",
    textAlign: "center",
    [theme.breakpoints.up("sm")]: {
      fontSize: "18px",
    },
  },
}));

const PurpleButton = styled(Button)(({ theme }) => ({
  color: "#FFFFFF",
  backgroundColor: "#9051b1",
  borderRadius: "30px",
  margin: "0 auto",
  textTransform: "unset",
  width: "50%",
  maxWidth: "364px",
  "&:hover": {
    backgroundColor: "#B965D3 ",
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 3 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <GridCloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function ContadorModal({ open, handleClose, timestamp, actualizeList }) {
  const [hours, setHours] = React.useState("");
  const [minutes, setMinutes] = React.useState("");
  const [seconds, setSeconds] = React.useState("");

  useEffect(() => {
    let complete = false;
    let actualiceTime = setInterval(() => {
      const coolDown = timestamp - Date.now();
      // const coolDown = timestamp - Date.now();
      const hoursInterval = ("0" + Math.floor(coolDown / (1000 * 60 * 60))).slice(-2);
      const minutesInterval = ("0" + Math.floor((coolDown % (1000 * 60 * 60)) / (1000 * 60))).slice(
        -2
      );
      const secondsInterval = ("0" + Math.floor((coolDown % (1000 * 60)) / 1000)).slice(-2);
      if (coolDown > 0) {
        setHours(hoursInterval);
        setMinutes(minutesInterval);
        setSeconds(secondsInterval);
      } else {
        complete = true;
        actualizeList();
      }
    }, 1000);
    if (complete) {
      clearInterval(actualiceTime);
    }

    return () => {
      clearInterval(actualiceTime);
    };
  }, [timestamp, actualizeList]);

  const classes = useStyles();

  return (
    <>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        maxWidth="md"
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        ></BootstrapDialogTitle>
        <DialogContent>
          <div className={classes.containerContent}>
            <h2 className={classes.text}>Regresa en</h2>
            {timestamp && (
              <Grid container direction="row" justifyContent="center">
                <div>
                  <h4 className={classes.counter}>{hours}</h4>
                  <h3 className={classes.counter_min}>horas</h3>
                </div>

                <span className={classes.counter}>:</span>

                <div>
                  <h4 className={classes.counter}>{minutes}</h4>
                  <h3 className={classes.counter_min}>minutos</h3>
                </div>

                <span className={classes.counter}>:</span>

                <div>
                  <h4 className={classes.counter}>{seconds}</h4>
                  <h3 className={classes.counter_min}>segundos</h3>
                </div>
              </Grid>
            )}

            <p className={classes.paragraph}>para comenzar la siguiente actividad.</p>
          </div>
        </DialogContent>
        <DialogActions>
          <PurpleButton
            onClick={handleClose}
            className={classes.button}
            variant="contained"
            sx={{ marginBottom: "16px" }}
          >
            Salir
          </PurpleButton>
        </DialogActions>
      </BootstrapDialog>
    </>
  );
}
