import React                        from "react";
import { Button,makeStyles,Grid}    from "@material-ui/core";

const useStyles = makeStyles(theme=>({
  welcomePageContainer: {
    background:'#FFFDFD',
    height:'calc(100vh - 79.5px)',
  },
  ContainerIzquierdo:{
    width:'97%',
    [theme.breakpoints.down(1160)]:{
      width:'100%',
    },
  },

  ContainerEstrategi:{
    position:'relative',
    width:'100vw',
    height:'calc(100vh - 79.5px - 79.5px)',
    overflow: 'auto',
  },
  ContainerSubHabilidades:{
    background:'none',
    boxSizing:'border-box',
    cursor:'pointer',
    textAlign:'center',
    display:'flex',
    padding:'43px 17px 9px 17px',
    alignItems:'center',
    position:'absolute',
    height:'40px',
    width:'62px',
    right:'3%',
    zIndex:1,
    [theme.breakpoints.down(1281)]:{
      left:'calc(50% - 31px)',
      padding:'0 0 0 62px',
    },
    [theme.breakpoints.down(600)]:{
      left:'0',
      padding:'0 15px 0 55px',
    },
        '& .Habilidades':{
      color:'#575757',
      font: 'normal 16px Lato',
      letterSpacing: '0.13px',
      display:'none',
      [theme.breakpoints.down(1281)]:{
        padding:'0 ', 
        margin:'0',
        font: ' 13px Lato',
      },
      [theme.breakpoints.down(600)]:{
        padding:'0 ', 
        margin:'0',
        font: ' 11px Lato',
      },
    },
    '&:hover':{
      border:' 5px solid #F26973',
      borderRadius: '20px 0px 20px 20px',
      background:'#fff',
      height:'215px',
      width:'233px',
      [theme.breakpoints.down(1281)]:{
        height:'75px',
        width:'350px',
        borderRadius: '0px 0px 20px 20px',
      },
      [theme.breakpoints.down(600)]:{
        height:'75px',
        width:'100%',
      },

      '& .Habilidades':{
        display:'block',
      },
      '&:before':{
        borderTop:' 0px ',
        borderRight:'0px'
      },
    },
    '&:before':{
      display:'flex',
      justifyContent:'center',
      alignItems:'center',
      content: '"CC"',
      position: 'absolute',
      right: '0',
      top: '0',
      font: '20px Fredoka One',
      background: '#F77862',
      color: '#FFF',
      border:' 3px solid #F2624F',
      borderRadius: '0px 0px 20px 20px',
      height: '40px',
      width: '62px',
      [theme.breakpoints.down(1281)]:{
        left: '-5px',
        top: '0',
      },
      [theme.breakpoints.down(600)]:{
        height: '30px',
        width: '52px',
        font: '18px Fredoka One',
      },

    },
  },
  Estrategia:{
    margin:'0px',
    font:'38px Fredoka One',
    textAlign: 'center',
    letterSpacing: '0.28px',
    color: '#20A698',
    opacity: '1',
    [theme.breakpoints.down(1161)]:{
      font:'28px Fredoka One',
    },
    [theme.breakpoints.down(600)]:{
      font:'24px Fredoka One',
    },
  },

   TextStrg:{
      margin:'0', 
      font:'normal  22px Lato',
      letterSpacing: '0.10px',
      color: '#575757',
      opacity: 1,
      padding:'0 0 40px 0',
      boxSizing:'border-box',
      width:'760px',
      [theme.breakpoints.down(1161)]:{
        width:'650px',
        font:'normal  18px Lato',
      },
      [theme.breakpoints.down(600)]:{
        font:'normal  16px Lato',
        width:'325px',
      },
      [theme.breakpoints.down(325)]:{
        font:'normal  14px Lato',
        width:'300px',
      },
  },

   Titulo:{
    position:'absolute',
    top:'58px',
    [theme.breakpoints.down(1161)]:{
      top:'80px',
    },
  },

  Objet1:{
    position:'absolute',
    top:'130px',
    '& .Br':{
      margin:'0 0 20px 0'
    },
    '& .ul':{
      margin:'22px 0',
      padding:'0 0 0 22px',
    },    
    '& .ul2':{
      margin:'14px 0',
    },
    '& .li':{
      marginBottom:'10px',
    },
   '& .Amarillo':{
    textAlign:'center',
    height:'42px',
    background:'#F7DF92',
    padding:'0 ',
   },
   '& .Verde':{
    textAlign:'center',
    height:'42px',
    background:'#84E9DF',
    padding:'0 ',
   },
  },

  ContainerBarraInferior:{
    position:'relative',
    background:'#F3CD52',
    height:'79.51px',
    [theme.breakpoints.up(1920)]:{
      height:'8vh',
    },
    [theme.breakpoints.down(1161)]:{
      background: 'linear-gradient(280deg, #D3348F 0%, #F1CB5A 100%) 0% 0% ',
    },
  },

  ContainerBarraDerecha:{
    background: 'linear-gradient(180deg, #D3348F 0%, #F1CB5A 100%) 0% 0%',
    width:'3%',
    height:'100vh',
    [theme.breakpoints.down(1161)]:{
      display:'none',
    },
  },
  
   BotonAvanza:{
    position:'absolute',
    bottom:'52px',
    right:'134px',
    textTransform:'none',
    textDecoration:'none',
    height:'62px',
    width:'279px',  
    background: '#7A54BA',
    boxShadow: '0px 3px 6px #00000029',
    borderRadius: '61px',
    color: '#FFFFFF',
    font: 'bold 20px lato ',
    '&:hover':{
        background: '#dd78fb',
      },
    [theme.breakpoints.down(1161)]:{
      position:'relative',
      bottom:'32px',
      left:'0',
      right:'0',
    },
  },
    
}));

const ReadingStrategys = (props) => {
  const classes = useStyles();
  const {activeStep, setActiveStep, handleNextStep} = props

  const handleContinue = () => {
    setActiveStep(activeStep + 1)
    handleNextStep()
  }

  return(

    <Grid  container direction="column" item sm={12} className={classes.welcomePageContainer} >
<div className={classes.ContainerSubHabilidades}>
          <p className="Habilidades">Producir textos argumentativos críticos a partir de lo leído.</p>
      </div>
      <Grid container item sm={12} justifyContent="center" className={classes.ContainerIzquierdo} >

        <Grid container item className={classes.ContainerEstrategi } >

              <Grid container item justifyContent="center" alignItems="center" sm={12} className={classes.Titulo}> 
                <p className={classes.Estrategia} >Estrategia de lectura</p>
              </Grid>

              <Grid container item sm={12} alignItems="center" justifyContent="center" className={classes.Objet1}>
                  <p className={classes.TextStrg}>
                    Hacer una crítica sobre un texto es importante, pues ello te ayuda a reflexionar 
                    sobre lo leído y a darte cuenta de si en verdad has comprendido la lectura. Para 
                    aprender cómo hacerlo, realiza el siguiente ejercicio. La intención es que  
                    aprendas a analizar los textos, profundices en los detalles y puedas emitir tu 
                    opinión sobre él. ¡Es hora de comenzar!

                    <ul className="ul">
                      <li className="li">
                        Lo primero que deberás hacer es elegir un lugar para leer, cómodo, ventilado,
                        iluminado, donde puedas concentrarte. 
                      </li>
                      <li className="li">
                        A continuación, escoge un texto del tema que más llame tu atención.
                      </li>
                      <li className="li">
                        Toma una hoja y un lápiz.
                      </li>
                      <li className="li">
                        Lleva a cabo la lectura tomando en cuenta los siguientes puntos:
                      </li>
                      <ul className="ul2">
                        <li className="li">
                        Realiza un escaneo de la lectura.
                        </li>
                        <li className="li">
                        Lee lentamente. 
                        </li>
                        <li className="li">
                        Contextualiza la lectura: en que época fue escrita, qué razón tuvo el autor 
                        para escribirla, cómo son los personajes, etcétera.
                        </li>
                        <li className="li">
                        Analiza cada párrafo, enunciado, palabra, y lo que quiere dar a entender el 
                        autor, sus motivos para escribir. Analiza todo lo que te parezca importante.
                        </li>
                        <li className="li">
                        Marca las ideas principales y secundarias.
                        </li>
                        <li className="li">
                        Realiza un diagrama o resumen.
                        </li>
                        <li className="li">
                        Si tienes dudas, investiga sobre lo que se describe en el texto.
                        </li>
                        <li className="li">
                        Hecha a andar tus conocimientos previos, te serán muy útiles.
                        </li>
                        <li className="li">
                        Anota palabras o términos que no conozcas para después buscarlos.
                        </li>
                        <li className="li">
                        Lee el texto las veces que sean necesarias, hasta que te encuentres 
                        seguro de haberlo comprendido.
                        </li>
                      </ul>
                      <li className="li"> 
                        Es tiempo de realizar una crítica sobre el texto. Puedes apoyarte en las 
                        siguientes preguntas y formular todas las que creas necesarias.
                      </li>
                      <ul className="ul2">
                        <li className="li">
                          ¿Qué te pareció el texto?
                        </li>
                        <li className="li">
                        ¿Qué opinas de lo que dice el autor?
                        </li>
                        <li className="li">
                        ¿Cómo calificarías lo que se expresa?
                        </li>
                        <li className="li">
                        ¿Estás de acuerdo o en contra de lo que se dice? ¿Por qué?
                        </li>
                        <li className="li">
                        ¿El texto es real o fantástico?
                        </li>
                      </ul>
                    </ul>

                    Realiza todas las preguntas que sean necesarias. Siempre investiga un poco más 
                    acerca del tema. Platica o intercambia ideas con tus familiares, maestros o  
                    amigos acerca de los textos leídos. Esto ayudará a soltarte más cuando realices  
                    una crítica sobre alguna lectura. Es importante ser constante en la práctica.  
                    ¡A dar el siguiente paso!



                  </p>
              </Grid>
                
        </Grid>
        
        <Grid container item sm={12} className={classes.ContainerBarraInferior} justifyContent="center">
            <Button id="BotonContinuarEstrategias" className={classes.BotonAvanza} onClick={handleContinue}>
              Continuar
            </Button>
        </Grid>
        
      </Grid>

      <Grid container item sm={12} className={classes.ContainerBarraDerecha}></Grid>

    </Grid>

  )
}

export default ReadingStrategys;
