import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
    ContenedorLectura:{
        width:'502px',
        [theme.breakpoints.down(502)]:{
            width:'350px',
        },
    },
    ContainerTitulo:{
        margin:'0 0 34px 0',
        '& .Titulo':{
            letterSpacing: '0px',
            color: '#F6644B',
            margin:'0',
            textAlign:'center',
            font:'43px fredoka one',
            lineHeight:'52px',
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        position:'relative',
        '& .Lectura':{
            color:'#000',
            font:'18px lato',
            lineHeight :'30px',
            letterSpacing: '0.17px',
            margin:'0 0 36px 0',
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .Esp':{
            margin:'0px!important'
        },
        '& .SubTitulo':{
            color:'#823CE2',
            margin:'0 0 36px 0',
            lineHeight :'28px',
            font:'bold 26px lato',
            letterSpacing: '0.24px',
            [theme.breakpoints.down(502)]:{
                font:'bold 18px lato',
                lineHeight :'24px',
                letterSpacing: '0.21px',
            },
        },
        '& .SubTitulo2':{
            margin:'0 0 22px 0',
        },
        '& .TextImg':{
            color:'#717476',
            margin:'24px 0 0 0',
            font:' 16px lato',
            lineHeight :'25px',
            letterSpacing: '0.15px',
            [theme.breakpoints.down(502)]:{
                font:' 11px lato',
                lineHeight :'14px',
                letterSpacing: '0.10px',
            },
        },
        '& .Imagen':{
            width:'100%'
        },
        '& .Luna':{
            margin:'36px 0 0 0'
        }
    }
}));

const Décimas_de_noche_y_día = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>

            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                        <h2 className="Titulo">Décimas de noche y día</h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} >
                    <Grid sm={12}>
                        <h3 className='SubTitulo'>
                            I
                        </h3>
                    </Grid>
                    <Grid container md={12} lg={5} >
                       <p className='Lectura Esp'>
                            Cae la luz. Como una daga<br/> 
                            corta el aire matutino.<br/> 
                            Por donde pasa el cuchillo,<br/> 
                            la noche se hace mañana.<br/> 
                            En el jardín, que ya cuaja,<br/> 
                            tiemblan de color las flores.<br/> 
                            Nace el trazo y surge el borde.<br/>
                            Donde la noche hizo un nido<br/> 
                            de confusión y vacío,<br/> 
                            germinan los girasoles.
                       </p>                   
                    </Grid>
                    <Grid container md={12} lg={7} justifyContent='center' >
                        <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion41/1.png" alt="Tarjeta" />
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container md={12} lg={5} >
                       <p className='Lectura'>
                       <h3 className='SubTitulo'>
                        II
                        </h3>
                        Todas las cosas, unidas<br/> 
                        bajo la sábana negra,<br/> 
                        eran comunión y mezcla.<br/> 
                        En la noche, indefinidas,<br/> 
                        su unidad era tan lisa<br/> 
                        que integraban una espalda<br/> 
                        y un pecho en el que roncaban:<br/> 
                        era el mundo sin abrirse,<br/> 
                        sin las claras cicatrices<br/> 
                        de una luz de madrugada.<br/>
                       </p>                   
                    </Grid>
                    <Grid container md={12} lg={7} justifyContent='center' className='Luna'>
                        <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion41/2.png" alt="Tarjeta" />
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} >
                    <Grid sm={12}>
                        <h3 className='SubTitulo SubTitulo2'>
                        III
                        </h3>
                    </Grid>
                    <Grid container md={12} lg={5}  alignContent='flex-end'>
                       <p className='Lectura'>
                        Ahora, si las cosas quieren<br/> 
                        volver a sentirse juntas<br/> 
                        —la abeja sobre la fruta<br/> 
                        y la tierra bajo el césped—,<br/> 
                        deben plantarse, oponerse,<br/> 
                        y declararle la lucha<br/>
                        a la luz que las separa<br/> 
                        por sus nombres y sus caras.<br/>
                        Enlazarse de una en una.<br/>
                       </p>                   
                    </Grid>
                    <Grid container md={12} lg={7} justifyContent='flex-end' >
                        <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion41/3.png" alt="Tarjeta" />
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container md={12} lg={5} >
                       <p className='Lectura'>
                       <h3 className='SubTitulo'>
                        IV
                        </h3>
                        En el árbol que recorre, <br/>
                        se trenza la zarigüeya.<br/> 
                        Por el rojo se conectan <br/>
                        manzana, clavel y cobre.<br/> 
                        Aunque, pasada la noche,<br/> 
                        despertaron divididos, <br/>
                        los objetos tienen hilos <br/>
                        y entre dos se tejen uno:<br/> 
                        piedra sobre piedra el muro,<br/>
                        rosa sobre rosa el frío.<br/>
                       </p>                   
                    </Grid>
                    <Grid container md={12} lg={7} justifyContent='center' >
                        <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion41/4.png" alt="Tarjeta" />
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container md={12} lg={5} >
                       <p className='Lectura'>
                       <h3 className='SubTitulo'>
                        V
                        </h3>
                        Pienso que la luz divide<br/> 
                        y que los objetos tratan, <br/>
                        en la odiosa madrugada, <br/>
                        de volver siempre a reunirse. <br/>
                        Y yo, en esta inmensa y triste <br/>
                        comunión de los objetos <br/>
                        tengo un breve pensamiento: <br/>
                        siento que en el alba hueca,<br/>
                        solamente tú despiertas, <br/>
                        solamente yo amanezco.
                       </p>                   
                    </Grid>
                    <Grid container md={12} lg={7} justifyContent='center' >
                        <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion41/5.png" alt="Tarjeta" />
                    </Grid>
                </Grid>
        </Grid>
    </Grid>
    );
}
 
export default Décimas_de_noche_y_día;
