import { Button, Grid, makeStyles } from "@material-ui/core";
import React, { useContext, useEffect } from "react";
import DrawerContext from "../../../../../usability/DrawerContext";
import { useOnLoadImages } from "../../../../hooks/useOnLoadImages";
import Loader from "../../../../Loader/Loader";
import Drawer from "../../ReadingTools/Drawer";

const useStyles =  makeStyles (theme =>({
     Contenedor:{
        background:'#E9FBF9',
        width:'100vw',
        overflowX:'clip',
        [theme.breakpoints.down(1161)]:{
            background:'none',
           overflowX:'unset',
        },
        [theme.breakpoints.down(600)]:{
            marginTop: '79.51px',
        }
    },
  ContainerInfoLectura: {
    position: "relative",
    height: "86px",
    [theme.breakpoints.down(502)]:{
        height: "43px",
    },
    background: "#76D4C3",
    "& .BarraInfoLectura": {
      height: "86px",
      background: `Url(https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Barras/Azul.png)`,
        [theme.breakpoints.down(502)]:{
            height: "43px",
        },
    },
    "& .InfoLectura": {
      position: "absolute",
      height: "86px",
      width: "219px",
      boxSizing: "border-box",
      padding: "17.2px 0",
      background: "#FAFAFA",
      borderRadius: "50px",
      boxShadow: "2px 2px 2px 2px #00000029",
      zIndex: "4",
        [theme.breakpoints.down(502)]:{
            height: "43px",
            width: "109px",
            padding: "8.6px 0",
            borderRadius: "25px",
        },
    },
    "& .InfoLectura:before": {
      position: "absolute",
      display: "flex",
      boxSizing: "border-box",
      padding: "0 0 0 10px",
      justifyContent: "center",
      alignItems: "center",
      height: "39.88px",
      width: "39.88px",
      borderRadius: "50%",
      content: '""',
      boxShadow: "3px 0px .5px 0px #00000029",
      background: "#FAFAFA",
      left: "90px",
      top: "65px",
      transform: "rotate(0.25turn)",
      [theme.breakpoints.down(502)]:{
        left: "45px",
        top: "30px",
        height: "20.88px",
        width: "20.88px",
        },
    },
    "& .InfoLectura:after": {
      content: '""',
      height: "15px",
      width: "15px",
      borderTop: "2px solid #20A698",
      borderLeft: "2px solid #20A698",
      position: "absolute",
      bottom: "-10px",
      left: "Calc(50% - 8px)",
      transform: "rotate(0.625turn)",
      [theme.breakpoints.down(502)]:{
        height: "10px",
        width: "10px",
        left: "Calc(50% - 4.5px)",
        bottom: "-3px",
        borderTop: "1px solid #20A698",
        borderLeft: "1px solid #20A698",
        },
    },
    "& .Sesion": {
      borderLeft: "2px solid #D8D8D8",
    },
    "& .Dato": {
      margin: "0 0 5px 0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#20A698",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        margin: "0 0 2.5px 0",
        },
    },
    "& .Caracter": {
      margin: "0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#000000",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        },
    },
       '& .Regresar':{
        color: '#F2624F',
        border: '#F2624F 1px solid',
        height: '30px',
        width:'100px',
        borderRadius: '20px',
        letterSpacing: '0.14px',
        textTransform: 'none',
        background:'#FEFAF1',
        top:'calc(50% - 15px)',
        left:'5%',
        zIndex:4,
        [theme.breakpoints.down(502)]: {
          font:'bold 10px lato',
          width: "60px",
        },
      },
  },
    ContenedorLectura:{
        background:'#fff',
        width:'1160px',
        height:'100%',
                padding:'60px 168px 35px ',
        
        transform: (props) =>
        props.scale > 1 ? `scale(${props.scale}) translate(0px, 10%)` : `none`,
        [theme.breakpoints.down(1161)]:{
            width:'502px',
            padding:'60px 0 0 0',
          },
        [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },

        '& .Escritorio':{
            display:'block',
            [theme.breakpoints.down(1161)]:{
                display:'none'
            },
        },
        '& .Tablet':{
            display:'none',
            [theme.breakpoints.down(1161)]:{
                display:'block'
            },
        }
    },
    ContainerTitulo:{
        margin:'0 0 34px 0',
        [theme.breakpoints.down(1161)]:{
            margin:'0 0 34px 0',
        },
        '& .Titulo':{
            font:'48px fredoka one',
            letterSpacing: '0px',
            lineHeight:'58px',
            color: '#2763A0',
            margin:'0',
            textAlign:'center',
            [theme.breakpoints.down(1161)]:{
                font:'43px fredoka one',
                lineHeight:'52px',
            },
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            lineHeight : '30px',
            margin:'0 0 36px 0',
            font:'22px lato',
            color:'#000',
            letterSpacing: '0.20px',
            [theme.breakpoints.down(1161)]:{
                font:'18px lato',
                lineHeight :'28px',
                letterSpacing: '0.17px',
            },
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .Cuadro':{
            position:'relative',
        },
        '& .Lectura2':{
            width:'750px',
            position:'absolute',
            margin:'53px 0 0 0',
            [theme.breakpoints.down(1161)]:{
                margin:'18px 0 0 0',
                width:'85.25%',
            },
            [theme.breakpoints.down(502)]:{
                margin:'15px 0 0 0',
            },
        },

        '& .SubTitulo':{
            font:'bold 30px lato',
            color:'#FF8900',
            letterSpacing:'.28px',
            lineHeight:'30px',
            margin:'0',
            [theme.breakpoints.down(1161)]:{
                lineHeight :'28px',
                font:'bold 26px lato',
                letterSpacing: '0.24px',
            },
            [theme.breakpoints.down(502)]:{
                font:'bold 18px lato',
                lineHeight :'24px',
                letterSpacing: '0.21px',
            },
        },
        '& .TextImg':{
            font:'20px lato',
            color:'#717476',
            letterSpacing:'.19px',
            lineHeight:'30px',
            margin:'24px 0 0 0',
            [theme.breakpoints.down(1161)]:{
                font:' 16px lato',
                lineHeight :'25px',
                letterSpacing: '0.15px',
            },
            [theme.breakpoints.down(502)]:{
                font:' 11px lato',
                lineHeight :'14px',
                letterSpacing: '0.10px',
            },
        },
        '& .ul':{
            color:'#FC5E9E',
            padding:'0 0 0 19px',
            margin:'0 0 36px 0'
        },
        '& .li':{
            margin:'0 0 8px 0'
        },
        '& .Lectura3':{
            margin:'0 '
        },


        '& .Imagen':{
            [theme.breakpoints.down(1161)]:{
                width:'100%',
            },
        },  
      
    }
}));

const En_busca_de_un_arte_nuevo = (props) => {

  const {activeStep, setActiveStep, handlePreviewStep, id_actividad} = props
  
  const handleContinue = () => {
    setActiveStep(activeStep - 1)
    handlePreviewStep()
  }

    const {
      handleSize,
    disabledZoom,
    closeZoom,
    handleZoom,
    scale
  }= useContext(DrawerContext);
  const [loading, setLoading] = React.useState(true);
  const wrapperRef = React.useRef(null);
  const imagesLoaded = useOnLoadImages(wrapperRef);

  const detectSize = () => {
    const lectura = document.getElementById("lectura");
    const newWidth = lectura?.offsetWidth;
    const newHeight = lectura?.offsetHeight;
    setLoading(false);
    if (lectura) handleSize(newHeight, newWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", detectSize);
    return () => {
      handleZoom()
      window.removeEventListener("resize", detectSize);
      closeZoom()
    };
  }, []);

  const classes = useStyles({ disabledZoom,scale });
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
             <Grid container item justifyContent="center" className={classes.ContainerInfoLectura}>
                       <Grid container className="BarraInfoLectura">
            <Button className="Regresar" onClick={handleContinue}>
                Regresar
            </Button>
        </Grid>
               <Grid container item className="InfoLectura">
                    <Grid container item direction="column" xs={6} justifyContent="center">
                        <p className="Dato">Grado</p>
                        <p className="Caracter">5</p>
                    </Grid>
                    <Grid container item direction="column" xs={6} justifyContent="center" className="Sesion">
                        <p className="Dato">Sesión</p>
                        <p className="Caracter">46</p>
                    </Grid>
               </Grid>
            </Grid>
            {!imagesLoaded && <Loader />}
        <Grid
            container
            item
            className={classes.ContenedorLectura}
            justifyContent="center"
            id="lectura"
            onLoad={detectSize}
            ref={wrapperRef}
        >
<Drawer id_lectura={id_actividad} />
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                        <h2 className="Titulo">En busca<br className='Tablet'/> de un arte nuevo</h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                       <p className='Lectura'>
                        <h3 className='SubTitulo'>Vanguardias</h3><br/>

                        A principios del siglo <span style={{fontVariant: "all-small-caps"}}>XX</span> inició en Europa un periodo de la historia del arte conocido 

                        como <em>vanguardismo</em>. Las <em>vanguardias</em> fueron movimientos de artistas jóvenes que 
                        buscaban cambiar radicalmente las maneras de pintar, esculpir y escribir.
                        
                       </p>                   
                    </Grid>
                    <Grid container justifyContent='center' className='Cuadro'>
                        <img className="Imagen Escritorio" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion46/6.png" alt="Tarjeta" />
                        <img className="Imagen Tablet" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion46/6R.png" alt="Tarjeta" />
                        <p className='Lectura Lectura2'>
                            En un ejército, la <em>vanguardia</em> es el grupo colocado al frente; por lo tanto, es el primero que se enfrenta al enemigo. Las vanguardias artísticas buscaban iniciar una guerra contra el arte tradicional.

                        </p>

                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container >
                        <p className='Lectura'>
                            En las artes plásticas se destacaron tres movimientos: <em>impresionismo, expresionismo</em> y 
                            <em> cubismo</em>. Los tres rechazaban las reglas establecidas de armonía y perspectiva. 
                            Durante la segunda mitad del siglo <span style={{fontVariant: "all-small-caps"}}>XIX</span> lo común en el arte eran las <em>representaciones
                            realistas, </em> es decir, las obras buscaban copiar con exactitud y fidelidad a sus modelos. 
                            Todos los artistas de vanguardia tenían claro que no querían seguir ese camino, pero 
                            cada uno tenía sus razones y sus preferencias.
                        </p>
                    </Grid>
                    <Grid container justifyContent='center'>
                        <Grid container justifyContent='center'>
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion46/1.png" alt="Tarjeta" />
                        </Grid>
                        <Grid container >
                            <p className="TextImg">
                                Los <em>impresionistas</em>, como Monet, Renoir y Degas, apostaban por representaciones difusas y 
                                luminosas que transmitieran sensaciones de forma vívida.
                            </p>
                        </Grid>
                    </Grid>

                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container justifyContent='center'>
                        <Grid container justifyContent='center'>
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion46/2.png" alt="Tarjeta" />
                        </Grid>
                        <Grid container >
                            <p className="TextImg">
                                Los <em>expresionistas</em>, como Munch, Kirchner, Grosz y Shiele, buscaban personajes o situaciones 
                                que transmitieran emociones fuertes. En sus cuadros parece que lo representado es tan intenso que 
                                modifica los colores y las formas convencionales.
                            </p>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container justifyContent='center'>
                        <Grid container justifyContent='center'>
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion46/3.png" alt="Tarjeta" />
                        </Grid>
                        <Grid container >
                            <p className="TextImg">
                                Los <em>cubistas</em>, como Picasso, Duchamp y Metzinger, buscaban nuevas formas para representar el movimiento. 
                                Plasmaban, simultáneamente, distintas facetas de un objeto.
                            </p>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                       <p className='Lectura'>
                        <h3 className='SubTitulo'>Arte abstracto</h3><br/>

                         Junto a las vanguardias aparecieron las primeras obras de <em>arte abstracto</em>, una 
                         tendencia que dejó los personajes, paisajes, ambientes y sucesos definidos, para 
                         componer cuadros con formas y trazos de colores. El impresionismo, el 
                         expresionismo y el cubismo no representaban las situaciones de forma realista. En 
                         cambio el arte abstracto ya no buscaba <em>representar</em> cosas, sino <em>estudiar</em> la armonía entre los colores, las formas, las líneas y los puntos.
                       </p>                   
                    </Grid>
                    <Grid container justifyContent='center' className='Cuadro'>
                        <img className="Imagen Escritorio" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion46/7.png" alt="Tarjeta" />
                        <img className="Imagen Tablet" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion46/7R.png" alt="Tarjeta" />
                        <p className='Lectura Lectura2'>
                            <em>Figurar</em> es sinónimo de <em>representar</em>. Por eso el arte tradicional y las 
                            vanguardias que aún representan cosas se llaman <em>arte figurativo</em>, mientras que el 
                            arte abstracto se reconoce como <em>no figurativo</em> .
                        </p>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                       <p className='Lectura'>
                        En esta época de experimentación los artistas ensayaban sus propias teorías, pero 
                        también se enriquecían con los descubrimientos y las propuestas de otros. Muchos 
                        pintores abstractos produjeron cuadros figurativos, en los cuales se acercaban a 
                        movimientos como el impresionismo.<br/><br/> 
                        
                        Vasili Kandinski y Kazimir Malevich, en Rusia, y Piet Mondrian, en Holanda, fueron 
                        los primeros pintores en realizar cuadros con figuras geométricas, colores primarios 
                        y fondos lisos, generalmente sin perspectiva.
                       </p>                   
                    </Grid>
                    <Grid container justifyContent='center' className='Cuadro'>
                        <img className="Imagen " src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion46/4.png" alt="Tarjeta" />
                    </Grid>
                </Grid>

                
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                       <p className='Lectura'>
                        Después de ellos, una larga tradición de pintores abstractos pobló el siglo <span style={{fontVariant: "all-small-caps"}}>XX</span>. Por 
                        ejemplo, el estadounidense Jackson Pollock se volvió muy popular gracias al uso de 
                        una técnica llamada <em>action painting</em>, que consiste en derramar pintura sobre el lienzo 
                        para ir creando trazos.
                       </p>                   
                    </Grid>
                    <Grid container justifyContent='center' className='Cuadro'>
                        <img className="Imagen " src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion46/5.png" alt="Tarjeta" />
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                       <p className='Lectura'>
                        La abstracción en la historia del arte ha estado presente desde hace más de 100 años 
                        y continúa siendo bastante popular.<br/><br/>
                        
                        <h3 className='SubTitulo'>El arte y tú: ¿cómo analizar un cuadro abstracto?</h3><br/>
                        
                        Crear arte abstracto exige tanta técnica, esfuerzo mental, disciplina y originalidad 
                        como crear una obra figurativa. Sin embargo, existen muchos prejuicios en su contra. 
                        Algunas personas piensan que sólo son rayones o formas sin sentido. <br/><br/>
                        
                        Recuerda: cuando entres en contacto con cualquier forma de arte debes darte la 
                        oportunidad de analizarla con atención. Cuando uno se encuentra frente a una obra 
                        abstracta puede empezar preguntándose:
                       </p>    

                        <ul className='ul'>
                            <li className='li'>
                                <p className='Lectura Lectura3'>
                                ¿Qué elementos utiliza? ¿Colores, líneas, texturas…?
                                </p>
                            </li>
                            <li className='li'>
                                <p className='Lectura Lectura3'>
                                ¿Cómo interactúan?
                                </p>
                            </li>
                            <li className='li'>
                                <p className='Lectura Lectura3'>
                                ¿Qué me transmite cada uno de ellos?
                                </p>
                            </li>
                            <li className='li'>
                                <p className='Lectura Lectura3'>
                                ¿Qué me transmite la obra entera?
                                </p>
                            </li>
                        </ul>   
                        <p className='Lectura'>
                            En ocasiones no basta con observar la pintura para encontrarle significado: también 
                            hay que conocer a fondo el mundo del artista. ¿Cuál era su perspectiva del arte? ¿Se 
                            sentía parte de un movimiento? ¿Se oponía a algún estilo? ¿Cómo y por qué? ¿Cuáles 
                            eran sus intereses y sus proyectos? Las exposiciones en los museos frecuentemente 
                            agregan fichas que explican a grandes rasgos las obras, la técnica con la que fueron 
                            creadas y qué implicaciones tuvieron en su contexto.<br/><br/> 
                            
                            Si piensas que no entiendes una obra ¡busca información! Es probable que te guste 
                            más si la comprendes mejor.
                        </p>
                                    
                    </Grid>
                </Grid>
        </Grid>
        <Grid container item style={{ marginTop: 80}} ></Grid>
    </Grid>
    );
}
 
export default En_busca_de_un_arte_nuevo;
