import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
    ContenedorLectura:{
        width:'502px',
                  [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
    },
    ContainerTitulo:{
        margin:'0 0 34px 0',
        '& .Titulo':{
            letterSpacing: '0px',
            color: '#FF2B2B',
            margin:'0 0 5px 0',
            textAlign:'center',
            font:'43px fredoka one',
            lineHeight:'52px',
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
        '& .Titulo2':{
            color: '#FF2B2B',
            margin:'0',
            textAlign:'center',
            font:'bold 26px lato',
            letterSpacing: '.24px',
            lineHeight:'28px',
            [theme.breakpoints.down(502)]:{
                font:'bold 22px lato',
                lineHeight:'24px',
            },
            [theme.breakpoints.down(350)]:{
                font:'bold 18px lato',
                lineHeight:'24px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        position:'relative',
        '& .Lectura':{
            margin:'0',
            color:'#000',
            font:'18px lato',
            lineHeight :'28px',
            letterSpacing: '0.17px',
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .Imagen':{
            width:'768px',
            [theme.breakpoints.down(768)]:{
                width:'100%',
            },
        },  
      
    }
}));

const Pablo_y_el_intraterrestre_Cap_1 = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>

            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                        <h2 className="Titulo">Pablo y el intraterrestre</h2>
                        <h3 className='Titulo2'>Fragmento 1 (capítulo 1)</h3>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                       <p className='Lectura'>
                        Él habría seguido jugando sin problema. El lodo no le molestaba y podía ignorar la 
                        lluvia. De hecho, los rayos le parecían geniales. Se dio cuenta de que los jugadores 
                        más cobardes se asustaban con los truenos, así que podría aprovechar para colarse 
                        en la defensa sin hacer faltas. Pero los adultos decidieron que era peligroso jugar en 
                        medio de la tormenta y el juego se suspendió. Ahora, la pelota recargada entre las 
                        piernas le lastimaba las rodillas.<br/><br/>
                        
                        Para empeorarlo todo, en ese preciso instante el comentarista de deportes de la 
                        radio anunció que el equipo favorito de Pablo, los Lobos Esteparios, se había 
                        quedado fuera de la copa. Él no quería apoyar a un montón de perdedores. Mañana 
                        en la escuela iba a ser la comidilla del resto de los Calaveras, su grupo de amigos.<br/><br/>
                        
                        Su padre apagó la radio de un sonoro botonazo y el coche se quedó más quieto y 
                        caluroso que nunca. Mirando alrededor, Pablo confirmó que él era el único que no
                        tenía nada en qué distraerse. Su madre leía los exámenes de sus alumnos; de vez en 
                        cuando, molesta, negaba con la cabeza y tachaba las hojas amarillas con su lápiz rojo. 
                        Definitivamente no era un buen momento para platicar con ella. Tampoco habría sido 
                        buena idea tratar de conversar con su padre, pues estaba sumido en el conflicto con 
                        los embotellamientos: tocaba el claxon y gritaba a otros conductores. <br/><br/>
                        
                        Sin duda, la peor de todos, la más odiosa esa tarde era Mariana. Desde hacía horas su 
                        hermana adolescente se reía sin parar de todo y de nada, como una ardilla loca, sin 
                        que Pablo pudiera entender los chistes, perdida en el brillo azul de su amadísimo 
                        celular nuevo. <br/><br/>
                        
                        De vez en cuando, Mariana decía algo intrigante, frases como “¡Es lo máximo!”, 
                        “¡Mira esto!”, “¡No puede ser!”, como si la cosa más especial del mundo estuviera en 
                        esa pantallita rectangular. Pablo volteó emocionado la primera vez, pero luego se dio 
                        cuenta de que Mariana hablaba por celular con un tal Pepe. <br/><br/>
                        
                        “El teléfono inteligente la está convirtiendo en una mensa”, se dijo a sí mismo. Le 
                        habría gustado tener alguien con quien compartir su chiste. <br/><br/>
                        
                        Pero Pablo no iba a quedarse sentado y aburrido todo el viaje de regreso. Se le 
                        ocurrió que podía abrir la ventana y sacar la cabeza […] <br/><br/>
                        
                        Apretó el botón del cinturón, tratando de disimular lo más posible el clic, 
                        controlando con el dedo gordo el botón rojo para que no saltara demasiado. Cuando 
                        vio que nadie reaccionó a esa operación, lenta y cuidadosamente deslizó el cinturón 
                        hacia atrás sosteniéndolo con su mano para que no hiciera ese ruido de bzbzbz al 
                        retraerse. <br/><br/>
                        
                        ¡Todo fue un éxito! Libre al fin, Pablo oprimió el botón de la ventanilla. Le preocupaba 
                        que lo descubrieran por el ruido incontenible de la ventana bajando 
                        automáticamente, pero todos los demás pasajeros viajaban como zombis con la cara 
                        perdida en la pantalla, en su trabajo o en la carretera.<br/><br/>
                        
                        […] Fascinado, disfrutando sacar la cabeza por la ventana y perderse un poco entre el 
                        miedo y el misterio, Pablo por fin pudo distraerse del calor y la frustración […] De un 
                        zarpazo, Mariana tomó a Pablo por la camiseta y lo jaló dentro del coche. Lo arrastró 
                        como si Pablo hubiera estado a punto de saltar de un edificio gigantesco […]<br/><br/>

                        ―Te vas a caer —lo reprendió Mariana―. ¡Ma, Pablo se quitó el cinturón!<br/><br/>

                        […]<br/><br/>

                        ―No es justo ―se quejó Pablo poniéndose el cinturón de nuevo―. Soy el único que 
                        no tiene nada que hacer […]<br/><br/>

                        —Tienes tus cómics, la tarea, colores y un montón de juguetes en la mochila. Tienes 
                        muchas cosas que no tienen otros niños. Sé más agradecido, Pablo.<br/><br/>

                        Los cómics no podía leerlos, la tarea no era algo para divertirse y no se podía colorear 
                        en el auto. “¿Y con quién voy a jugar con los juguetes? Ya no soy un bebé para jugar 
                        solo.”<br/><br/>

                        […]<br/><br/>

                        Furioso, Pablo tomó la pelota que estaba a su lado y la arrojó con todas sus fuerzas. 
                        La pelota alcanzó a escurrirse por el espacio entre los asientos de adelante y pegó
                        por dentro en el parabrisas, obligando a su padre a dar un terrible volantazo y orillar 
                        el coche. Las llantas rechinaron, el automóvil se zarandeó y todos gritaron asustados. 
                        Su papá se estacionó rápidamente en el acotamiento. El susto no había pasado a
                        mayores. Aun así, Pablo supo que era hombre muerto. ¡Estaría castigado hasta 
                        después del apocalipsis!
                       </p>                   
                    </Grid>
                    <Grid container justifyContent='center' >
                        <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion23/1.png" alt="Tarjeta" />
                    </Grid>
                </Grid>
        </Grid>
    </Grid>
    );
}
 
export default Pablo_y_el_intraterrestre_Cap_1;
