import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
    ContenedorLectura:{
            width:'502px',
                  [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
    },
    ContainerTitulo:{
        margin:'0 0 34px 0',
        '& .Titulo':{
            letterSpacing: '0px',
            color: '#F6664D',
            margin:'0',
            textAlign:'center',
            font:'43px fredoka one',
            lineHeight:'52px',
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            margin:'0 0 36px 0',
            color:'#000',
            font:'18px lato',
            lineHeight :'28px',
            letterSpacing: '0.17px',
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .TextImg':{
            color:'#717476',
            margin:'24px 0 0 0',
            font:' 16px lato',
            lineHeight :'25px',
            letterSpacing: '0.15px', 
            [theme.breakpoints.down(502)]:{
                font:' 11px lato',
                lineHeight :'14px',
                letterSpacing: '0.10px',
            },
        },
        '& .TextImg2':{
            margin:'0',
        },

        '& .Imagen':{
            width:'100%',
        }, 

    }
}));

const La_desaparición_de_los_mayas = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >La desaparición<br/> de los mayas</h2>

                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                      <p className="Lectura">
                        Los mayas fueron una civilización prehispánica muy avanzada. Tenían una cultura 
                        compleja, eran excelentes astrónomos y vivían en grandes ciudades. Uno de los 
                        mayores misterios de Mesoamérica es su repentina desaparición.
                      </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <Grid container justifyContent="center" >
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img1ro/Sesion22/1.png" alt="Tarjeta" />
                        </Grid>
                        <Grid container justifyContent="center" >
                            <p className="TextImg">
                                La cultura maya era importante en la Mesoamérica clásica.
                            </p>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                      <p className="Lectura">
                        Algunas teorías sobre la desaparición de esta importante 
                        cultura tienen que ver con la destrucción del suelo, una guerra 
                        con otras tribus o incluso una revuelta entre la población. Sin 
                        embargo, no hay evidencia que las apoye. Actualmente, un 
                        profesor de anatomía del Medical College de Ohio, Frank P. Saul, 
                        tiene una nueva opinión. Él piensa que desafortunadamente los 
                        mayas desaparecieron a causa de una mala alimentación y 
                        enfermedades. Dado el frágil estado de salud de los mayas, 
                        Saul piensa que su civilización fue desapareciendo poco a poco, 
                        proceso que duró muchas décadas, quizá siglos. Para 
                        desarrollar la explicación de su teoría, el profesor se apoyó en 
                        la información que obtuvo de huesos de miles de años de 
                        antigüedad.
                      </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <Grid container justifyContent="center" >
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img1ro/Sesion22/2.png" alt="Tarjeta" />
                        </Grid>
                        <Grid container justifyContent="center" >
                            <p className="TextImg TextImg2">
                                Los arqueólogos estudian restos óseos para desarrollar nuevas teorías.
                            </p>
                        </Grid>
                    </Grid>
                </Grid>  
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                      <p className="Lectura">
                        Todavía hay muchas dudas sobre cómo desaparecieron los antiguos mayas, pero no 
                        sobre su importancia en la cultura clásica de Mesoamérica. ¿Qué piensas tú? ¿Cuál es 
                        tu teoría?
                      </p>
                    </Grid>
                </Grid>      
        </Grid>
    </Grid>
    );
}
 
export default La_desaparición_de_los_mayas;
