import { makeStyles, Grid } from "@material-ui/core";
import React from "react";
import { useContext } from "react";
import AuthContext from "../../../auth/AuthContext";

const useStyles = makeStyles((theme) => ({
  barChat: {
    background: "#FDF6E1 0% 0% no-repeat padding-box",
    boxShadow: "0px 3px 6px #00000029",
    display: "flex",
    gap: "20px",
    padding: "20px",
    justifyContent: "flex-start",
    color: "#007FA3",
    alignItems: "center",
    flexWrap: "nowrap",
  },
  name: {
    fontSize: "15px",
    margin: 0,
  },
}));

const ChatListItemDefault = () => {
  const classes = useStyles({});
  const {
    user: { rol },
  } = useContext(AuthContext);

  return (
    <>
      <Grid
        container
        item
        direction="row"
        justifyContent="center"
        className={classes.barChat}
      >
        <Grid container direction="column" justifyContent="flex-start">
          <p className={classes.name}>
            {rol === "alumno"
              ? "Aún no tienes un docente asignado."
              : "Aún no tienes alumnos en tu(s) grupo(s)."}
          </p>
        </Grid>
      </Grid>
    </>
  );
};

export default ChatListItemDefault;
