import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
     Contenedor:{
        background:'#E9FBF9',
        width:'100vw',
        overflowX:'clip',
        [theme.breakpoints.down(1161)]:{
            background:'none',
           overflowX:'unset',
        },
        [theme.breakpoints.down(600)]:{
            marginTop: '79.51px',
        }
    },
  ContainerInfoLectura: {
    position: "relative",
    height: "86px",
    [theme.breakpoints.down(502)]:{
        height: "43px",
    },
    background: "#76D4C3",
    "& .BarraInfoLectura": {
      height: "86px",
      background: `Url(https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Barras/Azul.png)`,
        [theme.breakpoints.down(502)]:{
            height: "43px",
        },
    },
    "& .InfoLectura": {
      position: "absolute",
      height: "86px",
      width: "219px",
      boxSizing: "border-box",
      padding: "17.2px 0",
      background: "#FAFAFA",
      borderRadius: "50px",
      boxShadow: "2px 2px 2px 2px #00000029",
      zIndex: "4",
        [theme.breakpoints.down(502)]:{
            height: "43px",
            width: "109px",
            padding: "8.6px 0",
            borderRadius: "25px",
        },
    },
    "& .InfoLectura:before": {
      position: "absolute",
      display: "flex",
      boxSizing: "border-box",
      padding: "0 0 0 10px",
      justifyContent: "center",
      alignItems: "center",
      height: "39.88px",
      width: "39.88px",
      borderRadius: "50%",
      content: '""',
      boxShadow: "3px 0px .5px 0px #00000029",
      background: "#FAFAFA",
      left: "90px",
      top: "65px",
      transform: "rotate(0.25turn)",
      [theme.breakpoints.down(502)]:{
        left: "45px",
        top: "30px",
        height: "20.88px",
        width: "20.88px",
        },
    },
    "& .InfoLectura:after": {
      content: '""',
      height: "15px",
      width: "15px",
      borderTop: "2px solid #20A698",
      borderLeft: "2px solid #20A698",
      position: "absolute",
      bottom: "-10px",
      left: "Calc(50% - 8px)",
      transform: "rotate(0.625turn)",
      [theme.breakpoints.down(502)]:{
        height: "10px",
        width: "10px",
        left: "Calc(50% - 4.5px)",
        bottom: "-3px",
        borderTop: "1px solid #20A698",
        borderLeft: "1px solid #20A698",
        },
    },
    "& .Sesion": {
      borderLeft: "2px solid #D8D8D8",
    },
    "& .Dato": {
      margin: "0 0 5px 0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#20A698",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        margin: "0 0 2.5px 0",
        },
    },
    "& .Caracter": {
      margin: "0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#000000",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        },
    },   },
    ContenedorLectura:{
        background:'#fff',
        width:'1160px',
        height:'100%',
        padding:'60px 168px 35px ',
        [theme.breakpoints.down(1161)]:{
            width:'502px',
            padding:'60px 0 0 0',
          },
        [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
        '& .ContainerDerechos':{
            bottom:'80px',
            [theme.breakpoints.down(1161)]:{
                display:'none',
            },
        },
        '& .Derechos':{
            textAlign:"center",
            margin:"0px",
            font:" 15px lato",
            color:"#717476",
        },
        '& .Escritorio':{
            display:'block',
            [theme.breakpoints.down(1161)]:{
                display:'none'
            },
        },
        '& .Tablet':{
            display:'none',
            [theme.breakpoints.down(1161)]:{
                display:'block'
            },
        }
    },
    ContainerTitulo:{
        margin:'0 0 46px 0',
        [theme.breakpoints.down(1161)]:{
            margin:'0 0 34px 0',
        },
        '& .Titulo':{
            font:'48px fredoka one',
            letterSpacing: '0px',
            lineHeight:'58px',
            color: '#FF0000',
            margin:'0',
            textAlign:'center',
            [theme.breakpoints.down(1161)]:{
                font:'43px fredoka one',
                lineHeight:'52px',
            },
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            lineHeight : '30px',
            margin:'0 0 36px 0',
            font:'22px lato',
            color:'#000',
            letterSpacing: '0.20px',
            [theme.breakpoints.down(1161)]:{
                font:'18px lato',
                lineHeight :'28px',
                letterSpacing: '0.17px',
            },
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .Lectura2':{
            lineHeight : '30px',
            margin:'0 ',
            font:'22px lato',
            color:'#000',
            letterSpacing: '0.20px',
            [theme.breakpoints.down(1161)]:{
                font:'18px lato',
                lineHeight :'28px',
                letterSpacing: '-0.17px',
            },
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .SubTitulo':{
            font:'bold 30px lato',
            color:'#591AAF',
            letterSpacing:'.28px',
            lineHeight:'30px',
            margin:'0',
            [theme.breakpoints.down(1161)]:{
                lineHeight :'28px',
                font:'bold 26px lato',
                letterSpacing: '0.24px',
            },
            [theme.breakpoints.down(502)]:{
                font:'bold 18px lato',
                lineHeight :'24px',
                letterSpacing: '0.21px',
            },
        },
        '& .TextImg':{
            font:'20px lato',
            color:'#717476',
            letterSpacing:'.19px',
            lineHeight:'30px',
            margin:'24px 0 0 0',
            [theme.breakpoints.down(1161)]:{
                font:' 16px lato',
                lineHeight :'25px',
                letterSpacing: '0.15px',
            },
            [theme.breakpoints.down(502)]:{
                font:' 11px lato',
                lineHeight :'14px',
                letterSpacing: '0.10px',
            },
        },

        '& .Imagen':{
            [theme.breakpoints.down(1161)]:{
                width:'100%',
            },
        },  
      
    }
}));

const El_arte_urbano = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
             <Grid container item justifyContent="center" className={classes.ContainerInfoLectura}>
               <Grid container className="BarraInfoLectura"></Grid>
               <Grid container item className="InfoLectura">
                    <Grid container item direction="column" xs={6} justifyContent="center">
                        <p className="Dato">Grado</p>
                        <p className="Caracter">5</p>
                    </Grid>
                    <Grid container item direction="column" xs={6} justifyContent="center" className="Sesion">
                        <p className="Dato">Sesión</p>
                        <p className="Caracter">39</p>
                    </Grid>
               </Grid>
            </Grid>

            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                        <h2 className="Titulo">El arte urbano</h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                       <p className='Lectura'>
                        Los seres humanos hemos pintado y escrito cosas en las paredes desde hace miles de 
                        años. Ciertas razones han llevado a las personas a comunicarse con colores y figuras 
                        en un espacio compartido y público.<br/><br/> 
                        
                        <h3 className='SubTitulo'>El concepto de <em>arte urbano</em></h3><br/> 

                        <p className='Lectura2'>
                        Aunque es difícil encontrar una fecha exacta, en la década de 1990 se inventó el 
                        término <em>arte urbano</em> para definir el trabajo que los artistas (muchas veces anónimos) 
                        desarollaban en la calle.<br/><br/>
                        </p>
                        
                        Podríamos preguntarnos si este tipo de arte incluye la música que se compone y se 
                        difunde en la calle, o las <em>performances</em> que se interpretan en las plazas y los metros. 
                        En todo caso, sus formas más reconocibles son las artes plásticas. Estas obras ocupan 
                        paredes, anuncios y edificios, porque el arte urbano se caracteriza por estar a la vista 
                        de todos, es decir, por ser público.<br/><br/>
                        
                        Como todo arte, es un medio para que los creadores expresen sus emociones 
                        respecto a un tema y pone una diversidad de técnicas e instrumentos a disposición 
                        de los artistas, para que cada uno cree su estilo particular. Pero hay algo especial con 
                        él. ¿Qué consecuencia crees que tenga el usual anonimato de los artistas? Como ellos 
                        permanecen ocultos, el arte urbano muestra que el creador de una obra es sobre 
                        todo la sociedad, el contexto y la ideología que rodean a los artistas, a veces sin que 
                        ellos mismos se den cuenta. Las llamadas <em>bellas artes</em> muy rara vez reconocen esta 
                        importante lección. <br/><br/>
                        
                        En sus inicios, el arte urbano era algo casi exclusivo de los barrios que se 
                        encontraban alrededor de las ciudades. Quienes diseñaban y ejecutaban murales en 
                        la vía pública no eran profesionales. Eran jóvenes que buscaban lugares para la 
                        expresión y la creatividad. Hoy el arte urbano es una disciplina muy sólida en la que 
                        varios artistas comienzan a especializarse. Esto ha propiciado que entre ellos mismos 
                        se desarrollen nuevos estilos, temáticas, técnicas y materiales con los cuales realizar 
                        sus piezas.
                       </p>                   
                    </Grid>
                    <Grid container justifyContent='center'>
                        <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion39/1.png" alt="Tarjeta" />
                    </Grid>
                    <Grid container justifyContent='center' >
                        <p className="TextImg">
                            En su mural <em>Etnias</em>, Eduardo Kobra representó diferentes pueblos del mundo.
                        </p>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container >
                        <p className='Lectura'>
                            Una pregunta frecuente es cómo diferenciar una pieza de arte urbano de un grafiti. 
                            El grafiti es una técnica en la que se utiliza pintura en aerosol. Esta manera de 
                            plasmar fue muy utilizada entre pandillas para comunicarse a través de los muros de 
                            los barrios y quizá por eso es común confundirla con vandalismo. ¿Cómo distinguirías 
                            tú el arte de lo que no es arte? ¿Tiene sentido esta distinción? ¡En este tema debemos 
                            de tener la mente muy abierta! <br/><br/>
                            
                            La mayoría de las piezas de arte urbano se crean a partir de ideas políticas: alzan una
                            queja hacia el gobierno, proponen una forma de organización o denuncian una 
                            injusticia. A veces también buscan mostrar la manera de pensar del barrio o país 
                            donde se crean. En tercer lugar, el arte urbano se interesa por la cultura popular: se 
                            relaciona con la moda, el comercio y la publicidad, con los personajes de un momento 
                            histórico, y los mezcla con ideas o diseños locales. <br/><br/>
                            
                            Uno de los representantes más famosos del arte urbano es el inglés Banksy. Éste es 
                            un seudónimo, pues se desconoce su identidad real. Por eso hay muchos mitos e 
                            información falsa sobre él. Sabemos que ha llevado el arte urbano por todo el mundo, 
                            y que las pinturas que no realiza en la calle se venden en millones de dólares. Su 
                            estilo es muy particular, al igual que las temáticas que toca: le interesan la 
                            paz y la guerra, la desigualdad y la discriminación.
                        </p>
                    </Grid>
                    <Grid container justifyContent='center'>
                        <Grid container justifyContent='center'>
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion39/2.png" alt="Tarjeta" />
                        </Grid>
                        <Grid container >
                            <p className="TextImg">
                                Esta pieza de Banksy se ha llamado <em>The Whip</em> (El látigo). ¿Qué es lo que está representando el artista?
                            </p>
                        </Grid>
                    </Grid>

                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container >
                        <p className='Lectura'>
                            En las ciudades latinoamericanas existen muchos artistas urbanos locales que difunden su 
                            trabajo por las calles. También han logrado tener una difusión casi mundial a través de 
                            las redes sociales. Es importante buscar este arte y disfrutarlo, porque está al alcance 
                            de todos. Nos invita a reflexionar y ayuda a hacer de las ciudades lugares más complejos.
                        </p>
                    </Grid>
                </Grid>

            <Grid container item xs={12} justifyContent="center" className="ContainerDerechos">
                <p className="Derechos">
                    © 2022. Todos los derechos reservados, Pearson, Circuito de lectores.
                </p>                
            </Grid>
        </Grid>
    </Grid>
    );
}
 
export default El_arte_urbano;
