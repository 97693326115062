import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
    ContenedorLectura:{
        width:'502px',
                  [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
    },
    ContainerTitulo:{
        margin:'0 0 34px 0',
        '& .Titulo':{
            letterSpacing: '0px',
            color: '#926DD1',
            margin:'0',
            textAlign:'center',
            font:'43px fredoka one',
            lineHeight:'52px',
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            margin:'0 0 36px 0',
            color:'#000',
            font:'18px lato',
            lineHeight :'28px',
            letterSpacing: '0.17px',
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .TextImg':{
            color:'#717476',
            margin:'24px 0 0 0',
            font:' 16px lato',
            lineHeight :'25px',
            letterSpacing: '0.15px',
            [theme.breakpoints.down(502)]:{
                font:' 11px lato',
                lineHeight :'14px',
                letterSpacing: '0.10px',
            },
        },
        '& .Esp':{
            margin:'0',
        },
        '& .Imagen':{
            width:'100%',
        },  
      
    }
}));

const Personas_artesanas = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >Personas artesanas</h2>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                      <p className="Lectura">
                        En América Latina hay una gran diversidad de comunidades originarias que se 
                        dedican a elaborar artesanías a partir de los materiales que tienen alrededor. En 
                        Paraguay las guaraníes hacen artesanías de madera, arcilla e hilo como vasijas, 
                        tejidos, platos, máscaras y estatuillas.<br/><br/> 
                        
                        Entre otros lugares, en el centro y en los mercados de diversas ciudades de 
                        Latinoamérica podemos encontrar personas (regularmente vestidas con trajes 
                        típicos de su cultura) vendiendo objetos poco habituales, como: juguetes, ropa, 
                        elementos decorativos y recuerdos. Llaman la atención debido a los materiales de los 
                        que están hechos y por el detalle de su elaboración. Esos objetos son artesanías, y 
                        quienes los venden son las artesanas, que los elaboran con sus propias manos.
                      </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <Grid container justifyContent="center" alignItems='flex-end'>
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img2do/Sesion7/1.png" alt="Tarjeta" />
                        </Grid>
                        <Grid container >
                        <p className="TextImg">
                            En algunos pueblos las personas artesanas tienen designado un espacio específico que 
                            normalmente se conoce como <em>mercado de las artesanías</em> o <em>mercado artesanal</em>.
                        </p>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                      <p className="Lectura Esp">
                        Si los observas, notarás que estos productos son muy distintos a los que podemos 
                        encontrar en tiendas comunes. ¿Sabes qué los hace diferentes? Las manos que los 
                        trabajan. La mayoría de las personas que hacen artesanías pertenecen a 
                        comunidades indígenas y se calcula que en algunos lugares más de la mitad de las 
                        personas artesanas son mujeres.<br/><br/> 
                        
                        Las artesanías provienen de la tradición de la comunidad: son un conocimiento 
                        familiar o colectivo que se remonta a muchos años atrás en su historia.<br/><br/>
                        
                        Algunas técnicas de telar o de bordado se transmiten sólo de madre a hija. Gracias a 
                        este conocimiento, las mujeres artesanas tienen una fuente de trabajo que les 
                        permite colaborar o ser el sustento de su familia, al tiempo que ayudan a mantener 
                        vivas la historia y la lengua de sus pueblos.<br/>
                      </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <Grid container justifyContent="center" alignItems='flex-end'>
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img2do/Sesion7/2.png"alt="Tarjeta" />
                        </Grid>
                        <Grid container >
                            <p className="TextImg Esp">
                                A pesar de la complicada labor artesanal, muchas veces los compradores buscan pagar 
                                precios muy bajos por estos productos. Debemos ser conscientes y promover el comercio 
                                justo.
                            </p>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                      <p className="Lectura">
                        Una buena parte de las personas artesanas habla alguna de las lenguas indígenas de 
                        Latinoamérica. Gracias a ellas, las plazas y los parques están llenos de colores, no 
                        solamente por su mercancía, sino también por la diversidad de palabras que 
                        pronuncian.
                      </p>
                    </Grid>
                </Grid>
        </Grid>
    </Grid>
    );
}
 
export default Personas_artesanas;
