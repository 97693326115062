import React from "react";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";

import IconButton from "@mui/material/IconButton";

import { GridCloseIcon } from "@mui/x-data-grid";
import { makeStyles } from "@material-ui/core";
import { Link } from "react-router-dom";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-root": {
    backdropFilter: "blur(10px)",
    height: "100%",
  },
  "& .MuiDialogContent-root": {
    // padding: theme.spacing(2),
    padding: "0",
    position: "relative",
    height: "100%",
    // [theme.breakpoints.down("sm")]: {
    //   "@media (orientation: landscape)": {
    //     position: "relative",
    //     display: "flex",
    //     flexDirection: "column",
    //     alignContent: "center",
    //     alignItems: "center",
    //   },
    // },
    [theme.breakpoints.up("sm")]: {
      // margin: "20px 0",
      paddingTop: "0",
      paddingBottom: "0",
    },
    [theme.breakpoints.up("sm")]: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      "@media(max-height:400px)": {
        justifyContent: "flex-start",
      },
    },
    [theme.breakpoints.up("xl")]: {
      justifyContent: "flex-start",
    },
  },
  "& .MuiDialog-paper": {
    borderRadius: "12.1px",
    backgroundColor: "transparent",
    boxShadow: "none",
    height: "100%",
    margin: "10px",
    maxHeight: "max-content",
    [theme.breakpoints.up("sm")]: {
      minHeight: "70%",
      "@media (min-height: 500px)": {
        height: "auto",
      },
    },
    [theme.breakpoints.up("lg")]: {
      margin: "32px",
    },
  },
  "& .MuiDialog-container": {
    backdropFilter: "blur(10px)",
    alignItems: "flex-start",
    [theme.breakpoints.up("sm")]: {
      alignItems: "center",
    },
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
    display: "flex",
    justifyContent: "center",
    gap: "5%",
  },
  "& .MuiDialogTitle-root": {
    [theme.breakpoints.up("sm")]: {
      // margin: "20px 0",
      padding: "22px",
    },
  },
}));

const useStyles = makeStyles((theme) => ({
  containerContent: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    position: "relative",
    [theme.breakpoints.up("sm")]: {
      // margin: "20px 0",
    },
  },
  button: {
    color: "#FFFFFF",
    background: (props) =>
      props.color ? `${props.color} 0% 0% no-repeat padding-box` : "#000000",
    borderRadius: "30px",
    // margin: "0 auto",
    width: "30%",
    maxWidth: "264px",
    outline: "0",
    border: "0",
    position: "absolute",
    right: "0%",
    textDecoration: "none",
    textAlign: "center",
    padding: "10px",
    "&:hover": {
      filter: "brightness(1.15)",
    },
    [theme.breakpoints.down("1024")]: {
      "@media (orientation: landscape)": {
        position: "fixed",
        bottom: "3%",
        right: "3%",
        width: "25%",
      },
    },
    [theme.breakpoints.up("sm")]: {
      "@media(min-height:500px)": {
        position: "static",
        alignSelf: "end",
      },
    },
  },

  text: {
    background: (props) =>
      props.color ? `${props.color} 0% 0% no-repeat padding-box` : "#61728C",
    padding: "20px",
    borderRadius: "20px",
    fontFamily: "Lato",
    margin: 0,
    letterSpacing: "0px",
    textAlign: "center",
    color: "#F8F8F8",
    maxWidth: "580px",
    maxHeight: "254px",
    font: "normal normal bold 16px Lato",
    position: "relative",
    marginBottom: "20px",
    // "&::before": {
    //   border: "25px solid white",
    //   display: "block",
    //   content: ".",
    //   borderLeftColor: "transparent",
    //   borderBottomColor: "transparent",
    //   borderRightColor: "transparent",
    //   position: "absolute",
    //   bottom: "-48px",
    //   left: "calc(50% - 25px)",
    // },
    "&:before": {
      // display: (props) => (props.openMenuInDesktop ? "none" : "flex"),
      display: "flex",
      zIndex: 1,
      textTransform: "none",
      justifyContent: "center",
      alignItems: "center",
      // borderRadius: "25px",
      position: "absolute",
      content: '""',
      height: "16px",
      // width: "80px",
      // background: (props) =>
      //   props.color ? `${props.color} 0% 0% no-repeat padding-box` : "#61728C",
      border: "20px solid",
      borderTopColor: (props) => `${props.color}`,
      borderLeftColor: "transparent",
      borderBottomColor: "transparent",
      borderRightColor: "transparent",
      font: "bold 14px lato",
      // border: "25px solid white",

      // position: "absolute",
      bottom: "-55px",
      left: "calc(50% - 20px)",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "18px",
      maxWidth: "720px",
      // margin: "10px",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "24px",
      maxWidth: "720px",
      // margin: "10px",
    },
    [theme.breakpoints.up("xl")]: {
      fontSize: "28px",
      padding: "40px",
      maxWidth: "1200px",
      // margin: "10px",
    },
  },

  img: {
    // width: "100%",
    // maxWidth: "320px",
    minHeight: "40vh",
    height: "auto",
    maxHeight: "50vh",
    maxWidth: "100%",
    "@media (max-height: 400px)": {
      minHeight: "40vh",
    },
    "@media (orientation: landscape)": {
      minHeight: "300px",
    },
    "@media (min-height: 1200px)": {
      maxHeight: "320px",
    },
    [theme.breakpoints.up("lg")]: {
      // maxWidth: "360px",
      // maxHeight: "fit-content",
      // margin: "10px",
    },
    [theme.breakpoints.up("xl")]: {
      // maxWidth: "360px",
      // maxHeight: "fit-content",
      // margin: "10px",
    },
    // boxShadow: "0px 11px 15px -7px rgb(0 0 0 / 20%), 0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%)",
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 3 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <GridCloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function IntervencionHeraldoModal({
  open,
  handleClose,
  handleNext,
  path,
  narrativa,
  alt,
  to,
  grado,
  toCard,
  sesion,
}) {
  // const [animationType, setAnimationType] = React.useState("saludando");
  const colores = {
    grado1: {
      sesion3: "#6B4374",
      sesion5: "#61728C",
      sesion7: "#6B4374",
      sesion9: "#61728C",
      sesion11: "#61728C",
      sesion13: "#6B4374",
      sesion15: "#61728C",
      sesion17: "#D8655D",
      sesion20: "#D8655D",
      sesion23: "#6B4374",
      sesion25: "#61728C",
      sesion27: "#6B4374",
      sesion29: "#D8655D",
      sesion31: "#61728C",
      sesion33: "#6B4374",
      sesion35: "#61728C",
      sesion38: "#D8655D",
      sesion41: "#D8655D",
      sesion43: "#61728C",
      sesion45: "#6B4374",
      sesion47: "#61728C",
      sesion49: "#6B4374",
      sesion51: "#61728C",
      sesion53: "#6B4374",
      sesion55: "#D8655D",
      sesion56: "#6B4374",
      sesion57: "#61728C",
      sesion59: "#D8655D",
      sesion60: "#6B4374",
    },

    grado2: {
      sesion3: "#E55A5A",
      sesion5: "#2CA798",
      sesion7: "#ED5959",
      sesion9: "#2CA798",
      sesion11: "#ED5959",
      sesion13: "#2CA798",
      sesion15: "#ED5959",
      sesion17: "#2CA798",
      sesion20: "#ED5959",
      sesion23: "#2CA798",
      sesion25: "#ED5959",
      sesion27: "#2CA798",
      sesion29: "#ED5959",
      sesion31: "#2CA798",
      sesion33: "#ED5959",
      sesion35: "#2CA798",
      sesion38: "#ED5959",
      sesion41: "#2CA798",
      sesion43: "#ED5959",
      sesion45: "#2CA798",
      sesion47: "#ED5959",
      sesion49: "#2CA798",
      sesion51: "#ED5959",
      sesion53: "#2CA798",
      sesion55: "#ED5959",
      sesion56: "#2CA798",
      sesion57: "#ED5959",
      sesion59: "#2CA798",
      sesion60: "#2CA798",
    },

    grado3: {
      sesion3: "#B15788",
      sesion5: "#D86C77",
      sesion7: "#B15788",
      sesion9: "#D86C77",
      sesion11: "#B15788",
      sesion13: "#D86C77",
      sesion15: "#B15788",
      sesion17: "#D86C77",
      sesion20: "#B15788",
      sesion23: "#D86C77",
      sesion25: "#B15788",
      sesion27: "#D86C77",
      sesion29: "#B15788",
      sesion31: "#D86C77",
      sesion33: "#B15788",
      sesion35: "#D86C77",
      sesion38: "#B15788",
      sesion41: "#B15788",
      sesion43: "#D86C77",
      sesion45: "#B15788",
      sesion47: "#D86C77",
      sesion49: "#B15788",
      sesion51: "#D86C77",
      sesion53: "#B15788",
      sesion55: "#D86C77",
      sesion56: "#B15788",
      sesion57: "#D86C77",
      sesion59: "#B15788",
      sesion60: "#B15788",
    },

    grado4: {
      sesion3: "#CE2B4E",
      sesion5: "#157B6B",
      sesion7: "#CE2B4E",
      sesion9: "#157B6B",
      sesion11: "#CE2B4E",
      sesion13: "#157B6B",
      sesion15: "#CE2B4E",
      sesion17: "#157B6B",
      sesion20: "#CE2B4E",
      sesion23: "#157B6B",
      sesion25: "#CE2B4E",
      sesion27: "#157B6B",
      sesion29: "#CE2B4E",
      sesion31: "#157B6B",
      sesion33: "#CE2B4E",
      sesion35: "#157B6B",
      sesion38: "#CE2B4E",
      sesion41: "#CE2B4E",
      sesion43: "#157B6B",
      sesion45: "#CE2B4E",
      sesion47: "#157B6B",
      sesion49: "#CE2B4E",
      sesion51: "#157B6B",
      sesion53: "#CE2B4E",
      sesion55: "#157B6B",
      sesion56: "#157B6B",
      sesion57: "#CE2B4E",
      sesion59: "#157B6B",
      sesion60: "#CE2B4E",
    },
    grado5: {
      sesion3: "#DD5C58",
      sesion5: "#5263A9",
      sesion7: "#DD5C58",
      sesion9: "#5263A9",
      sesion11: "#DD5C58",
      sesion13: "#5263A9",
      sesion15: "#DD5C58",
      sesion17: "#5263A9",
      sesion20: "#DD5C58",
      sesion23: "#5263A9",
      sesion25: "#DD5C58",
      sesion27: "#5263A9",
      sesion29: "#DD5C58",
      sesion31: "#5263A9",
      sesion33: "#DD5C58",
      sesion35: "#5263A9",
      sesion38: "#DD5C58",
      sesion41: "#DD5C58",
      sesion43: "#5263A9",
      sesion45: "#DD5C58",
      sesion47: "#5263A9",
      sesion49: "#DD5C58",
      sesion51: "#5263A9",
      sesion53: "#DD5C58",
      sesion55: "#5263A9",
      sesion56: "#5263A9",
      sesion57: "#DD5C58",
      sesion59: "#5263A9",
      sesion60: "#DD5C58",
    },
    grado6: {
      sesion3: "#9D4CAA",
      sesion5: "#F06736",
      sesion7: "#9D4CAA",
      sesion9: "#F06736",
      sesion11: "#9D4CAA",
      sesion13: "#F06736",
      sesion15: "#9D4CAA",
      sesion17: "#9D4CAA",
      sesion20: "#F06736",
      sesion23: "#F06736",
      sesion25: "#9D4CAA",
      sesion27: "#F06736",
      sesion29: "#9D4CAA",
      sesion31: "#F06736",
      sesion33: "#9D4CAA",
      sesion35: "#F06736",
      sesion38: "#9D4CAA",
      sesion41: "#9D4CAA",
      sesion43: "#F06736",
      sesion45: "#9D4CAA",
      sesion47: "#F06736",
      sesion49: "#9D4CAA",
      sesion51: "#F06736",
      sesion53: "#9D4CAA",
      sesion55: "#F06736",
      sesion56: "#F06736",
      sesion57: "#9D4CAA",
      sesion59: "#F06736",
      sesion60: "#9D4CAA",
    },
  };
  const color = colores[grado][`sesion${sesion}`];
  const classes = useStyles({ color });

  return (
    <>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        maxWidth="xl"
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        ></BootstrapDialogTitle>
        <DialogContent>
          <div className={classes.containerContent}>
            <div className={classes.text}>{narrativa}</div>
            <img src={path} alt={alt} className={classes.img} />
          </div>

          {handleNext ? (
            <>
              <Link className={classes.button} onClick={handleNext} to={"#"}>
                Continuar
              </Link>
            </>
          ) : (
            <>
              <Link
                className={classes.button}
                onClick={handleClose}
                to={to ? `/session${to}` : "#"}
              >
                Continuar
              </Link>
            </>
          )}
          {/* <AcceptButton className={classes.button} variant="contained"></AcceptButton> */}
        </DialogContent>
        {/* <DialogActions></DialogActions> */}
      </BootstrapDialog>
    </>
  );
}
