import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
    ContenedorLectura:{
        width:'502px',
                  [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
    },
    ContainerTitulo:{
        margin:'0 0 34px 0',
        '& .Titulo':{
            letterSpacing: '0px',        
            color: '#F6644B',
            margin:'0',
            textAlign:'center',
            font:'43px fredoka one',
            lineHeight:'52px',
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            margin:'0 0 36px 0',
            color:'#000',
            font:'18px lato',
            lineHeight :'28px',
            letterSpacing: '0.17px',
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .SubTitulo':{
            color:'#2FA6DE',
            margin:'0',
            lineHeight :'28px',
            font:'bold 26px lato',
            letterSpacing: '0.24px',
            [theme.breakpoints.down(502)]:{
                font:'bold 18px lato',
                lineHeight :'24px',
                letterSpacing: '0.21px',
            },
        },

        '& .Amarillo':{
            background:'#FEF7E8',
            borderRadius:'100px',
            height:'59px',
            margin:'0 0 10px 0',
            width:'100%',
            [theme.breakpoints.down(502)]:{
                borderRadius:'50px',
                height:'28px',
            },
        },
        '& .Amarillo2':{
            margin:'0 0 36px 0',
        },
        '& .Esp':{
           margin:'0', 
        },
        '& .Cuadro':{
            position:'relative',
        },
        '& .Esp2':{
            position:'absolute',
            width:'85.25%',
            margin:'53px 0 0 0',
            [theme.breakpoints.down(502)]:{
                margin:'25px 0 0 0',
                lineHeight :'17px!important',
            },
        },
        '& .Imagen':{
            width:'100%',
        },  
        '& .ImgEsp':{
            width:'100%',
            [theme.breakpoints.down(502)]:{
                height:'195px',
            },
        }, 
    }
}));


const Operaciones_con_conjuntos = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >Operaciones<br/> con conjuntos</h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container sm={12}>
                      <p className="Lectura Esp"> 
                        Los colores del arcoíris son rojo, naranja, amarillo, verde, azul y violeta. Estos colores
                        tienen una relación entre sí y por eso forman un <em>conjunto</em>. De este conjunto podemos
                        hacer dos <em>subconjuntos</em>. Uno conformado por los colores primarios: rojo, amarillo y 
                        azul. Y otro, por los colores secundarios: naranja, verde y violeta.
                      </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img3ro/Sesion08/1.png" alt="Tarjeta" />
                    </Grid>
                </Grid>
               
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container sm={12}>
                      <p className="Lectura "> 
                        Es posible escribir matemáticamente este conjunto y sus dos subconjuntos. Para 
                        comenzar, hay que elegir tres letras mayúsculas, por ejemplo: A, P y S. Estas letras 
                        servirán para nombrar al conjunto y a los subconjuntos. Después, se tienen que 
                        escribir los elementos de cada conjunto dentro de unas llaves como éstas: &#123; &#125;. ¡No 
                        olvides usar comas para separar los elementos!
                      </p>
                    </Grid>
                    <Grid container item justifyContent='center' sm={12}>
                        <Grid container item justifyContent='center' alignContent='center' className='Amarillo'>
                            <p className="Lectura Esp"> 
                                A = &#123;rojo, naranja, amarillo, verde, azul, violeta&#125;
                            </p>
                        </Grid>
                        <Grid container item justifyContent='center' alignContent='center' className='Amarillo'>
                            <p className="Lectura Esp"> 
                                P = &#123;rojo, amarillo, azul&#125;
                            </p>
                        </Grid>
                        <Grid container item justifyContent='center' alignContent='center' className='Amarillo Amarillo2'>
                            <p className="Lectura Esp"> 
                                S = &#123;naranja, verde, violeta&#125;
                            </p>
                        </Grid>
                        <Grid container item justifyContent='center' className='Cuadro' sm={12}>
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img3ro/Sesion08/2R.png" alt="Tarjeta" />
                            <p className='Lectura Esp2'>
                                A esta forma de escribir conjuntos se le llama por <em>extensión</em>, pues enumeramos una lista de todos sus elementos.
                            </p>
                        </Grid>
                    </Grid>
                </Grid>
                
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container item justifyContent='center' sm={12}>
                        <Grid container sm={12}>
                            <p className="Lectura "> 
                                <h3 className='SubTitulo'>Unión de conjuntos</h3><br/>
                                La operación que permite hacer operaciones entre conjuntos recibe el nombre de 
                                <em> unión</em>. Como su nombre lo indica, une dos o más conjuntos. La finalidad de unir los 
                                conjuntos es formar uno nuevo. Antes de continuar, recuerda el siguiente ejemplo:
                            </p>
                        </Grid>
                        <Grid container item justifyContent='center' className='Cuadro' sm={12}>
                            <img className="ImgEsp" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img3ro/Sesion08/3R.png" alt="Tarjeta" />
                            <p className='Lectura Esp2'>
                                Imagina que los conjuntos son como unas bolsas mágicas que crecen de 
                                tamaño cada vez que les agregas un objeto. Ahora bien, si tienes dos bolsas y 
                                quieres hacer una sola, ¿qué harías? ¡Exactamente! Abres ambas bolsas y 
                                vacías todos sus elementos dentro de una nueva.
                            </p>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container sm={12}>
                        <p className="Lectura "> 
                            Comencemos. Piensa en dos conjuntos: el de números pares y el de impares. El 
                            primer conjunto estará conformado por los números pares del 0 al 10 y el segundo 
                            por los números impares del 0 al 10. Éstos se escriben de la siguiente manera:
                        </p>
                    </Grid>
                    <Grid container item justifyContent='center' alignContent='center' className='Amarillo'>
                        <p className="Lectura Esp"> 
                            P = &#123;0, 2, 4, 6, 8, 10&#125;
                        </p>
                    </Grid>
                    <Grid container item justifyContent='center' alignContent='center' className='Amarillo'>
                        <p className="Lectura Esp"> 
                            I = &#123;1, 3 ,5 ,7, 9&#125;
                        </p>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container sm={12}>
                        <p className="Lectura "> 
                            Para unirlos, hay que realizar la “operación unión”. La unión puede representarse con 
                            el símbolo ∪. Entonces, la operación se escribiría así: P ∪ I. Y se leería de la siguiente 
                            manera: “P unión I”. Esta unión puede representarse así:
                        </p>
                    </Grid>
                    <Grid container item justifyContent='center' alignContent='center' className='Amarillo'>
                        <p className="Lectura Esp"> 
                            P ∪ I = &#123;0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10&#125;
                        </p>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container sm={12}>
                        <p className="Lectura "> 
                            Pero también podría escribirse con un orden distinto. Por ejemplo, podrían aparecer 
                            primero los elementos del conjunto P y luego los elementos del I:
                        </p>
                    </Grid>
                    <Grid container item justifyContent='center' alignContent='center' className='Amarillo'>
                        <p className="Lectura Esp"> 
                            P ∪ I = &#123;0, 2, 4, 6, 8, 10, 1, 3, 5, 7, 9&#125;
                        </p>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container item justifyContent='center' sm={12}>
                        <Grid container sm={12}>
                            <p className="Lectura Esp"> 
                                Recuerda: lo importante en la unión es que no falte ningún elemento de los conjuntos 
                                que se unen.<br/><br/>
                                
                                <h3 className='SubTitulo'>Intersección de conjuntos</h3><br/>
                                
                                Otra operación que permite realizar procedimientos entre conjuntos es la 
                                intersección. Ésta se forma a partir de los elementos que tienen en común dos o más 
                                conjuntos. Y también hace que dos conjuntos hagan uno nuevo.
                            </p>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container item justifyContent='center' className='Cuadro' sm={12}>
                        <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img3ro/Sesion08/4R.png" alt="Tarjeta" />
                        <p className='Lectura Esp2'>
                            Si los conjuntos son como bolsas mágicas, la <em>intersección</em> consistiría en abrir 
                            cada bolsa, extraer los objetos que se repiten y formar con éstos un nuevo conjunto.
                        </p>
                    </Grid>
                </Grid>
    
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container sm={12}>
                        <p className="Lectura "> 
                            Por ejemplo, imagina los siguientes conjuntos:
                        </p>
                    </Grid>
                    <Grid container item justifyContent='center' alignContent='center' className='Amarillo'>
                        <p className="Lectura Esp"> 
                            D =  &#123;1, 2, 3, 4, 5, 6 &#125;
                        </p>
                    </Grid>
                    <Grid container item justifyContent='center' alignContent='center' className='Amarillo'>
                        <p className="Lectura Esp"> 
                            E =  &#123;1, 2, 3, 5, 8, 13… &#125;
                        </p>
                    </Grid>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container sm={12}>
                        <p className="Lectura "> 
                            Los elementos que se repiten tanto en el conjunto D como en el E son: 1, 2, 3 y 5. La 
                            intersección puede representarse con el símbolo <span style={{fontVariant: "all-small-caps"}}>∩</span>. Entonces, la operación se 
                            escribiría así: D <span style={{fontVariant: "all-small-caps"}}>∩</span> E. Y se leería así: “D intersección E”. Esta intersección puede 
                            representarse de la siguiente manera:
                        </p>
                    </Grid>
                    <Grid container item justifyContent='center' alignContent='center' className='Amarillo'>
                        <p className="Lectura Esp"> 
                            D ∩ E =  &#123;1, 2, 3, 5 &#125;
                        </p>
                    </Grid>
                </Grid>
        </Grid>
    </Grid>
    );
}
 
export default Operaciones_con_conjuntos;
