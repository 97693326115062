import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
    ContenedorLectura:{
        width:'502px',
        [theme.breakpoints.down(502)]:{
            width:'350px',
        },

    },
    ContainerTitulo:{
        margin:'0 0 38px 0',
        '& .Titulo':{
            letterSpacing: '0px',
            color: '#2783FF',
            margin:'0',
            font:'43px fredoka one',
            lineHeight:'52px',
            textAlign:'center',
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            margin:'0 0 36px 0 ',
            color:'#000',
            font:'18px lato',
            letterSpacing:'.17px',
            lineHeight:'28px',
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .TextImg':{
            margin:'24px 0 0 0',
            alignSelf:'flex-start',
            color:'#717476',
            font:'16px lato',
            letterSpacing:'.15px',
            lineHeight:'25px',
            [theme.breakpoints.down(502)]:{
                font:' 11px lato',
                lineHeight :'14px',
                letterSpacing: '0.10px',
            },
        },
        '& .Imagen':{
            width:'100%',
        },
        '& .Imagen2':{
            width:'100%',
            [theme.breakpoints.down(502)]:{
                width:'100%',
                height:'105%',
            },
        },

        '& .ContainerCuadro':{
            position:'relative',
        },
        '& .Esp':{
            position:'absolute',
            margin:'45px 0 0 0 ',
            width:'85%',
            [theme.breakpoints.down(502)]:{
                margin:'25px 0 0 0',
                lineHeight :'17px!important',
            },
        },

    },

}));

const El_canal_de_Panamá_una_renovación_para_el_futuro = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo " >
                        El canal de Panamá:<br/>
                        una renovación 
                    </h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                            El canal de Panamá es una ruta que conecta el océano Pacífico con el mar Caribe, a 
                            través del punto más estrecho del istmo de Panamá. 
                        </p>
                    </Grid>
                    <Grid container justifyContent='center' className='ContainerCuadro'>
                        <img className="Imagen2" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion39R/1R.png" alt="Tarjeta" />
                        <p className='Lectura Esp'>
                            <em>Istmo</em>. Es una franja delgada de tierra, la cual une dos áreas grandes de tierra 
                            por medio del mar.                     
                        </p>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion39R/2.png" alt="Tarjeta" />
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                            Este canal fue construido por los Estados Unidos de América con el permiso del 
                            gobierno de Panamá, por lo que estuvo bajo el control estadounidense hasta 1979. 
                            Posteriormente fue controlado por la Comisión del Canal de Panamá, la cual era 
                            integrada por dicho país y también por los Estados Unidos. Finalmente, en 1999 el 
                            gobierno del país centroamericano asumió su control absoluto por medio de la 
                            conformación de la Autoridad del Canal de Panamá.<br/><br/>

                            Su inauguración fue en 1914 y modificó el tráfico marítimo para siempre, pues abrió 
                            una vía más rápida de comunicación entre el océano Pacífico y el océano Atlántico. 
                            Antes de que existiera el canal de Panamá las embarcaciones debían rodear América 
                            por el cabo de Hornos, ubicado en Chile, en el extremo sur del continente. Por lo 
                            tanto, esta nueva vía les ahorró ¡semanas enteras de navegación!<br/><br/>

                            Durante muchas décadas el canal de Panamá fue una innovación que facilitó el 
                            comercio marítimo. Pero con el paso del tiempo surgieron embarcaciones que 
                            superaban las medidas y el peso permitidos por el canal de Panamá, conocidas 
                            como <em>Post-Panamax</em>. Este cambio en las dimensiones de los navíos se convirtió en 
                            un problema para el canal, porque lo ponía en riesgo de volverlo obsoleto. Es decir, 
                            por no tener las características necesarias para dar servicio a esos nuevos barcos, el 
                            canal de Panamá corría el riesgo de dejar de ser utilizado. <br/><br/>

                            Con el objetivo de evitar lo anterior, en 2006 el entonces presidente de Panamá, 
                            Martín Torrijos Espino, propuso que se ampliara el canal para que las embarcaciones 
                            <em>Post-Panamax</em> pudieran utilizarlo.<br/><br/>

                            De acuerdo con las leyes panameñas, cualquier cambio importante en el canal de 
                            Panamá debía ser aprobado por la mayoría de la ciudadanía. Con el fin de cumplir 
                            ese requisito se llevó a cabo en octubre de 2006 una consulta popular, por medio 
                            de la cual las obras de ampliación fueron autorizadas.<br/><br/>

                            La ceremonia de inauguración de los trabajos de ampliación se llevó a cabo el 3 de 
                            septiembre de 2007. Al evento acudieron distintos mandatarios latinoamericanos, 
                            como los presidentes de Nicaragua, El Salvador, Honduras y Colombia. También, 
                            asistió Jimmy Carter, expresidente estadounidense que en 1977 firmó los Tratados 
                            del canal de Panamá, a través de los cuales se le devolvió al país el control de esa 
                            vía en 1999.
                        </p>
                    </Grid>
                    <Grid container  sm={12}>
                            <Grid container>
                                <img  className='Imagen' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion39R/3.png"  alt="Tarjeta" />
                            </Grid>
                            <Grid>
                                <p className='TextImg'>El puente de las Américas cruza encima del canal de Panamá y sirve para conectar dos 
                                ciudades de Panamá que quedaron separadas.</p>
                            </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                            Las obras de ampliación fueron llevadas a cabo por una agrupación de empresas 
                            denominada Grupo Unido por el Canal de Panamá. Al inicio de la obra se esperaba 
                            que los trabajos estuvieran concluidos en 2014. Pero en enero de ese año el grupo 
                            encargado de las obras anunció la suspensión de los trabajos, pues no iba a ser 
                            posible concluirlos con el costo que originalmente había pactado la Autoridad del 
                            Canal de Panamá.<br/><br/>

                            Como resultado de una serie de negociaciones, las obras de ampliación se 
                            reanudaron después de 21 días, en febrero de 2014. Finalmente, la inauguración se 
                            llevó a cabo en junio de 2016, es decir, nueve años después de haberse comenzado. 
                            El recorrido inaugural lo realizó la embarcación china <em>Cosco Shipping Panama</em>, la cual 
                            fue seleccionada mediante un sorteo. Esta embarcación zarpó de Grecia y realizó un 
                            viaje de 14 días para llegar a Panamá.<br/><br/>

                            A la ceremonia inaugural asistieron centenares de panameños, así como los 
                            mandatarios de 12 países, entre ellos el rey emérito de España Juan Carlos. Con 
                            estas obras se dio comienzo a una nueva etapa en la vida del canal, en la que se 
                            esperaba una mayor integración del país centroamericano a la economía global.

                        </p>
                    </Grid>
                </Grid>
        </Grid>
    </Grid>
    );
}
 
export default El_canal_de_Panamá_una_renovación_para_el_futuro;
