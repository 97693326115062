import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
     Contenedor:{
        background:'#E9FBF9',
        width:'100vw',
        overflowX:'clip',
        [theme.breakpoints.down(1161)]:{
            background:'none',
           overflowX:'unset',
        },
        [theme.breakpoints.down(600)]:{
            marginTop: '79.51px',
        }
    },
  ContainerInfoLectura: {
    position: "relative",
    height: "86px",
    [theme.breakpoints.down(502)]:{
        height: "43px",
    },
    background: "#76D4C3",
    "& .BarraInfoLectura": {
      height: "86px",
      background: `Url(https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Barras/Azul.png)`,
        [theme.breakpoints.down(502)]:{
            height: "43px",
        },
    },
    "& .InfoLectura": {
      position: "absolute",
      height: "86px",
      width: "219px",
      boxSizing: "border-box",
      padding: "17.2px 0",
      background: "#FAFAFA",
      borderRadius: "50px",
      boxShadow: "2px 2px 2px 2px #00000029",
      zIndex: "4",
        [theme.breakpoints.down(502)]:{
            height: "43px",
            width: "109px",
            padding: "8.6px 0",
            borderRadius: "25px",
        },
    },
    "& .InfoLectura:before": {
      position: "absolute",
      display: "flex",
      boxSizing: "border-box",
      padding: "0 0 0 10px",
      justifyContent: "center",
      alignItems: "center",
      height: "39.88px",
      width: "39.88px",
      borderRadius: "50%",
      content: '""',
      boxShadow: "3px 0px .5px 0px #00000029",
      background: "#FAFAFA",
      left: "90px",
      top: "65px",
      transform: "rotate(0.25turn)",
      [theme.breakpoints.down(502)]:{
        left: "45px",
        top: "30px",
        height: "20.88px",
        width: "20.88px",
        },
    },
    "& .InfoLectura:after": {
      content: '""',
      height: "15px",
      width: "15px",
      borderTop: "2px solid #20A698",
      borderLeft: "2px solid #20A698",
      position: "absolute",
      bottom: "-10px",
      left: "Calc(50% - 8px)",
      transform: "rotate(0.625turn)",
      [theme.breakpoints.down(502)]:{
        height: "10px",
        width: "10px",
        left: "Calc(50% - 4.5px)",
        bottom: "-3px",
        borderTop: "1px solid #20A698",
        borderLeft: "1px solid #20A698",
        },
    },
    "& .Sesion": {
      borderLeft: "2px solid #D8D8D8",
    },
    "& .Dato": {
      margin: "0 0 5px 0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#20A698",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        margin: "0 0 2.5px 0",
        },
    },
    "& .Caracter": {
      margin: "0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#000000",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        },
    },   },
    ContenedorLectura:{
        background:'#fff',
        width:'1160px',
        height:'100%',
        padding:'60px 168px 35px ',
        [theme.breakpoints.down(1161)]:{
            width:'502px',
            padding:'60px 0 0 0',
          },
        [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
        '& .ContainerDerechos':{
            bottom:'80px',
            [theme.breakpoints.down(1161)]:{
                display:'none',
            },
        },
        '& .Derechos':{
            textAlign:"center",
            margin:"0px",
            font:" 15px lato",
            color:"#717476",
        },
        '& .Escritorio':{
            display:'block',
            [theme.breakpoints.down(1161)]:{
                display:'none'
            },
        },
        '& .Tablet':{
            display:'none',
            [theme.breakpoints.down(1161)]:{
                display:'block'
            },
        }
    },
    ContainerTitulo:{
        margin:'0 0 34px 0',
        [theme.breakpoints.down(1161)]:{
            margin:'0 0 34px 0',
        },
        '& .Titulo':{
            font:'48px fredoka one',
            letterSpacing: '0px',
            lineHeight:'58px',
            color: '#0783FF',
            margin:'0',
            textAlign:'center',
            [theme.breakpoints.down(1161)]:{
                font:'43px fredoka one',
                lineHeight:'52px',
            },
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            lineHeight : '30px',
            margin:'0 0 36px 0',
            font:'22px lato',
            color:'#000',
            letterSpacing: '0.20px',
            [theme.breakpoints.down(1161)]:{
                font:'18px lato',
                lineHeight :'28px',
                letterSpacing: '0.17px',
            },
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .SubTitulo':{
            font:'bold 30px lato',
            color:'#F6664D',
            letterSpacing:'.28px',
            lineHeight:'30px',
            margin:'0',
            [theme.breakpoints.down(1161)]:{
                lineHeight :'28px',
                font:'bold 26px lato',
                letterSpacing: '0.24px',
            },
            [theme.breakpoints.down(502)]:{
                font:'bold 18px lato',
                lineHeight :'24px',
                letterSpacing: '0.21px',
            },
        },
        "& .SubTitulo2": {
            font: "bold 26px lato",
            letterSpacing: ".24px",
            lineHeight: "28px",
            margin: "0",
            [theme.breakpoints.down(1161)]: {
              font: "bold 22px lato",
              lineHeight: "24px",
              letterSpacing: "0.21px",
            },
            [theme.breakpoints.down(502)]: {
              font: "bold 18px lato",
              lineHeight: "20px",
              letterSpacing: "0.16px",
            },
        },
        '& .Azul':{
            color:'#0783FF',
        },
        '& .Naranja':{
            color:'#F6644B',
        },
        '& .Cuadro':{
            position:'relative',
        },
        '& .Amarillo':{
            background:'#FEF7E8',
            borderRadius:'20px',
            width:'100%'
        },
        '& .Esp':{
            margin:'25px 0',
            width:'750px',
            [theme.breakpoints.down(1161)]:{
                width:'85.25%',
                margin:'21px 0',
            },
        },
        '& .Esp2':{
            position:'absolute',
            width:'750px',
            margin:'53px 0 0 0',
            [theme.breakpoints.down(1161)]:{
                width:'85.25%',
            },
                  [theme.breakpoints.down(502)]:{
                margin:'13px 0 0 0',
            },
        },
        '& .Imagen':{
            width:'100%',
        },   
    }
}));


const Diferentes_reglas = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
             <Grid container item justifyContent="center" className={classes.ContainerInfoLectura}>
               <Grid container className="BarraInfoLectura"></Grid>
               <Grid container item className="InfoLectura">
                    <Grid container item direction="column" xs={6} justifyContent="center">
                        <p className="Dato">Grado</p>
                        <p className="Caracter">3</p>
                    </Grid>
                    <Grid container item direction="column" xs={6} justifyContent="center" className="Sesion">
                        <p className="Dato">Sesión</p>
                        <p className="Caracter">18</p>
                    </Grid>
               </Grid>
            </Grid>

            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >Diferentes reglas</h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container sm={12}>
                      <p className="Lectura "> 
                        ¿Alguna vez te han llamado la atención por comer sin haberte lavado las manos
                        antes? Sin duda, esto se debe a que existe un modo adecuado de hacer las cosas. Por 
                        ejemplo, en los juegos de mesa o en los deportes hay ciertas normas que hay que 
                        seguir para participar en ellos y asegurar una buena convivencia. A continuación, 
                        conocerás más sobre las reglas. ¡Adelante!<br/><br/>
                        <h3 className='SubTitulo'>¿Qué son las reglas?</h3><br/>
                        
                        Las <em>reglas</em> son un conjunto de compromisos o conductas que las personas tienen que
                        seguir para relacionarse de manera sana. Así, las reglas ayudan al bienestar de los 
                        seres humanos y hacen que el entorno sea amigable.
                      </p>
                    </Grid>
                    <Grid container justifyContent="center" className='Cuadro' sm={12}>
                        <img className="Imagen Escritorio" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img3ro/Sesion18/1.png" alt="Tarjeta" />
                        <img className="Imagen Tablet" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img3ro/Sesion18/1R.png" alt="Tarjeta" />
                        <p className='Lectura Esp2'>
                            El Código Hammurabi es el conjunto de reglas más antiguo que se conoce. 
                            Está escrito en una roca de dos metros de altura, data del año 1700 a. n. e. y 
                            contiene más de 200 leyes que obedecían las personas de la civilización 
                            babilónica. ¡En internet puedes encontrar imágenes de esta sorprendente 
                            estructura!
                        </p>
                    </Grid>
                </Grid>
               
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container sm={12}>
                        <p className="Lectura "> 
                            Seguramente puedes reconocer conductas que parecen atractivas, pero que no son 
                            las mejores a largo plazo. Por ejemplo, mentir sobre una calificación para evitar un 
                            regaño. Pero entonces, ¿qué hace que nuestras acciones sean correctas o 
                            incorrectas? ¿Por qué es importante seguir las reglas?
                        </p>
                    </Grid>
                    <Grid container item justifyContent='center' sm={12}>
                        <Grid container item justifyContent='center' sm={12}>
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img3ro/Sesion18/2.png" alt="Tarjeta" />
                        </Grid>
                    </Grid>
                </Grid>
                
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container item justifyContent='center' sm={12}>
                        <Grid container sm={12}>
                            <p className="Lectura "> 
                                <h3 className='SubTitulo'>Tipos de reglas</h3><br/>

                                La ética distingue dos tipos de reglas. Las primeras son las reglas legales, 
                                también conocidas como <em>leyes</em>. Y las segundas son las normas o reglas morales.<br/><br/>
                                
                                <h3 className='SubTitulo2 Azul'><span className="Naranja">1.</span> Reglas legales o leyes</h3><br/>

                                Son reglas de comportamiento establecidas por el gobierno de acuerdo con las 
                                necesidades de la sociedad. Se encuentran en las constituciones políticas de cada
                                país y en cada reglamento oficial. El propósito de las leyes es indicar las obligaciones, 
                                las prohibiciones y los derechos que poseen todas las personas que viven en un 
                                territorio. Tienen que cumplirse aunque no se esté de acuerdo con ellas. Y las 
                                autoridades imponen castigos o sanciones para asegurar su cumplimiento, por 
                                ejemplo, multas o tiempo en la cárcel.
                            </p>
                            <Grid container item justifyContent='center' alignContent='center' className='Amarillo'>
                                <p className='Lectura Esp'>
                                    Los países explican en sus constituciones qué es un robo para evitar que 
                                    alguien se apodere de una cosa que no es suya. También indican cuáles serán
                                    las sanciones que tendrá quien robe, dependiendo de la gravedad del daño.
                                </p>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container item justifyContent='center' sm={12}>
                        <Grid container sm={12}>
                            <p className="Lectura">
                                <h3 className='SubTitulo2 Azul'><span className="Naranja">2.</span> Normas morales</h3><br/>

                                Las normas morales son reglas de comportamiento que establecen los individuos, no 
                                el gobierno. Provienen de los valores y las creencias que se transmiten mediante la
                                educación. Las normas no son las mismas para todos, aunque se compartan algunas 
                                con otras personas. No hay castigos económicos o cárcel para quienes tengan una 
                                falta moral. Pero las consecuencias pueden ser de otro tipo.
                            </p>
                            <Grid container item justifyContent='center' alignContent='center' className='Amarillo'>
                                <p className='Lectura Esp'>
                                    Por ejemplo, una regla moral es no decir mentiras. Si le mientes a tu mamá, 
                                    papá, amigos o a alguien que quieres, no recibirás una multa, pero será difícil 
                                    que vuelvan a confiar en tu palabra.
                                </p>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container item justifyContent='center' sm={12}>
                        <Grid container sm={12}>
                            <p className="Lectura">
                             Las reglas ayudan a mantener un orden en la sociedad. Es importante atender las leyes para mantenerlo.
                            </p>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item xs={12} justifyContent="center" className="ContainerDerechos">
                    <p className="Derechos">
                        © 2022. Todos los derechos reservados, Pearson, Circuito de lectores.
                    </p>                
                </Grid>
        </Grid>
    </Grid>
    );
}
 
export default Diferentes_reglas;
