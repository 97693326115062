import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
      Contenedor:{
        background:'#E9FBF9',
        width:'100vw',
        overflowX:'clip',
        [theme.breakpoints.down(1161)]:{
            background:'none',
           overflowX:'unset',
        },
        [theme.breakpoints.down(600)]:{
            marginTop: '79.51px',
        }
    },
  ContainerInfoLectura: {
    position: "relative",
    height: "86px",
    [theme.breakpoints.down(502)]:{
        height: "43px",
    },
    background: "#76D4C3",
    "& .BarraInfoLectura": {
      height: "86px",
      background: `Url(https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Barras/Azul.png)`,
        [theme.breakpoints.down(502)]:{
            height: "43px",
        },
    },
    "& .InfoLectura": {
      position: "absolute",
      height: "86px",
      width: "219px",
      boxSizing: "border-box",
      padding: "17.2px 0",
      background: "#FAFAFA",
      borderRadius: "50px",
      boxShadow: "2px 2px 2px 2px #00000029",
      zIndex: "4",
        [theme.breakpoints.down(502)]:{
            height: "43px",
            width: "109px",
            padding: "8.6px 0",
            borderRadius: "25px",
        },
    },
    "& .InfoLectura:before": {
      position: "absolute",
      display: "flex",
      boxSizing: "border-box",
      padding: "0 0 0 10px",
      justifyContent: "center",
      alignItems: "center",
      height: "39.88px",
      width: "39.88px",
      borderRadius: "50%",
      content: '""',
      boxShadow: "3px 0px .5px 0px #00000029",
      background: "#FAFAFA",
      left: "90px",
      top: "65px",
      transform: "rotate(0.25turn)",
      [theme.breakpoints.down(502)]:{
        left: "45px",
        top: "30px",
        height: "20.88px",
        width: "20.88px",
        },
    },
    "& .InfoLectura:after": {
      content: '""',
      height: "15px",
      width: "15px",
      borderTop: "2px solid #20A698",
      borderLeft: "2px solid #20A698",
      position: "absolute",
      bottom: "-10px",
      left: "Calc(50% - 8px)",
      transform: "rotate(0.625turn)",
      [theme.breakpoints.down(502)]:{
        height: "10px",
        width: "10px",
        left: "Calc(50% - 4.5px)",
        bottom: "-3px",
        borderTop: "1px solid #20A698",
        borderLeft: "1px solid #20A698",
        },
    },
    "& .Sesion": {
      borderLeft: "2px solid #D8D8D8",
    },
    "& .Dato": {
      margin: "0 0 5px 0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#20A698",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        margin: "0 0 2.5px 0",
        },
    },
    "& .Caracter": {
      margin: "0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#000000",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        },
    },   },
    ContenedorLectura:{
        background:'#fff',
        width:'1160px',
        height:'100%',
        padding:'70px 168px 35px',
        [theme.breakpoints.down(1161)]:{
            width:'502px',
            padding:'70px 0 0',
          },
        [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
        '& .ContainerDerechos':{
            bottom:'80px',
            [theme.breakpoints.down(1161)]:{
                display:'none',
            },
        },

        '& .Derechos':{
            textAlign:"center",
            margin:"0px",
            font:" 15px lato",
            color:"#717476",
        } ,
        '& .Escritorio':{
            display:'block',
            [theme.breakpoints.down(1161)]:{
                display:'none'
            },
        },
        '& .Tablet':{
            display:'none',
            [theme.breakpoints.down(1161)]:{
                display:'block'
            },
        }
    },
    ContainerTitulo:{
        margin:'0 0 46px 0',
        '& .Titulo':{
            font:'48px fredoka one',
            letterSpacing: '0px',
            color: '#694CD1',
            margin:'0',
            textAlign:'center',
            [theme.breakpoints.down(1161)]:{
                font:'43px fredoka one',
                lineHeight:'52px',
            },
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            lineHeight : '30px',
            margin:'0 0 36px 0',
            font:'22px lato',
            color:'#000',
            letterSpacing: '0.20px',
            [theme.breakpoints.down(1161)]:{
                lineHeight :'28px',
                font:'18px lato',
                letterSpacing: '0.17px',
            },
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .SubTitulo':{
            font:'bold 30px lato',
            color:'#EA699D',
            letterSpacing:'.28px',
            margin:'0',
            lineHeight:'30px',
            [theme.breakpoints.down(1161)]:{
                font:'bold 26px lato',
                letterSpacing:'.24px',
            },
            [theme.breakpoints.down(502)]:{
                font:'bold 18px lato',
                lineHeight :'24px',
                letterSpacing: '0.21px',
            },
        },
        '& .Imagen':{
            [theme.breakpoints.down(1161)]:{
                width:'100%',
            },
        },
 
    },

}));

const Manual_para_saber_que_es_cierto_y_que_no_en_las_apps = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
             <Grid container item justifyContent="center" className={classes.ContainerInfoLectura}>
               <Grid container className="BarraInfoLectura"></Grid>
               <Grid container item className="InfoLectura">
                    <Grid container item direction="column" xs={6} justifyContent="center">
                        <p className="Dato">Grado</p>
                        <p className="Caracter">6</p>
                    </Grid>
                    <Grid container item direction="column" xs={6} justifyContent="center" className="Sesion">
                        <p className="Dato">Sesión</p>
                        <p className="Caracter">7</p>
                    </Grid>
               </Grid>
            </Grid>

            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <Grid container className="Escritorio">
                        <h2 className="Titulo" >Manual para saber qué es <br/>
                        cierto y qué no en las <em>apps</em></h2>
                    </Grid>
                    <Grid container className="Tablet">
                        <h2 className="Titulo" >
                        Manual para saber<br/>  
                        qué es cierto <br/>
                        y qué no en las <em>apps</em></h2>
                    </Grid>

                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                            Quizá para ti sea normal tener toda la información que requieres al alcance de una 
                            búsqueda en internet. La humanidad se encuentra en la era de la información, 
                            aunque este fenómeno es reciente. Hasta hace sólo unas décadas, la información 
                            estaba almacenada en los libros, pero ahora la mayoría también se encuentra en 
                            internet.<br/><br/>
                            
                            En la actualidad, puedes enterarte de noticias mundiales, así como lo que les sucede 
                            a tus compañeros en su vida, en el mismo instante en que las cosas pasan. Pero no se 
                            trata solamente de saber acerca de sucesos, también puedes tener acceso a 
                            conocimientos de todo tipo: desde cosas sencillas como recetas de cocina hasta 
                            asuntos complejos como clases de termodinámica o microbiología.<br/><br/>

                            A pesar de que todo esto representa una gran ventaja en tu vida, es importante que 
                            sepas que existe una desventaja: la calidad de la información. Antes, la gente recurría 
                            a fuentes confiables como libros o periódicos para obtener información. Que los 
                            datos estuvieran en esos soportes no significaba que todos fueran ciertos, pero eran 
                            revisados por personas que se aseguraban de la veracidad de la información. En la 
                            actualidad, cualquiera con acceso a internet puede crear contenido para que otros lo 
                            consulten.<br/><br/>

                            Aunque esto último es algo positivo (porque así todos pueden ejercer su libertad de 
                            expresión), tienes que saber distinguir lo verídico de aquello que podría ser falso. 
                            Para ello, lee las siguientes recomendaciones.<br/><br/>

                            <h3 className='SubTitulo'>No creas todo lo que ves en las redes sociales</h3><br/>

                            Recuerda que la gente sólo sube las cosas que quiere compartir, por lo que no 
                            muestran todos los aspectos de su realidad. Por ejemplo, si sigues en sus redes 
                            sociales a una persona que sube videos en los que hace trucos perfectos en patineta, 
                            ten en cuenta que no te está mostrando las veces que ese truco le salió mal. Omitir el 
                            proceso puede darte la impresión de que es fácil andar en patineta y te animes a 
                            intentarlo y te lastimes al hacerlo.<br/><br/>

                            O bien puede ser que la gente decida subir a la red las fotos en las que se ve feliz, 
                            pero eso no significa que no estén tristes a veces. Lo mismo sucede con la 
                            información. Continuamente, la gente dice o repite datos sin saber si son 
                            completamente ciertos. A este tipo de información se le conoce como noticias falsas 
                            o <em>fake news</em>, las cuales han tenido graves repercusiones sociales, políticas y 
                            económicas. 
                        </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <Grid container justifyContent="center" alignItems='flex-end'>
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion7/1.png" alt="Tarjeta" />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>                                      
                        <p className="Lectura">
                            <h3 className='SubTitulo'>Busca más información</h3><br/>

                            Si ves algún video sobre temas importantes de salud, como ansiedad y depresión, no 
                            pienses que es algo sencillo de tratar sólo porque alguien baila mientras dice que se 
                            puede solucionar sólo con proponérselo. Problemas como éstos y muchos más 
                            requieren una atención profesional. <br/><br/>
                            
                            <h3 className='SubTitulo'>Identifica a qué campo del conocimiento pertenece el tema 
                            que te interesa</h3><br/> 

                            Este consejo tiene relación con el anterior, pues todo depende de que decidas buscar 
                            más información. Quizá no sepas por dónde empezar tu búsqueda, así que lo mejor 
                            será identificar con qué tema más general se relaciona lo que quieres investigar. Por 
                            ejemplo, si requieres información adecuada sobre los beneficios de andar en 
                            patineta, debes saber que este tema forma parte del área de deporte y que hay sitios 
                            especializados en esa disciplina. <br/><br/>

                            <h3 className='SubTitulo'>Consulta fuentes confiables</h3><br/>

                            Independientemente del tema sobre el que desees investigar, lo más probable es que 
                            haya alguna institución reconocida que aborde el tema. Esas fuentes de consulta 
                            suelen indicar el nombre de la persona que escribe el contenido; es decir, especifican 
                            quién se hace responsable por lo que escribe. Además, colocan la fecha de 
                            publicación e incluso añaden referencias de dónde obtuvieron su información. <br/><br/>
                            
                            Para encontrar en internet páginas con estas características, coloca en el buscador 
                            los filtros para que aparezcan sitios académicos o referencias bibliográficas, o 
                            cuando aparezcan los sitios de tu búsqueda, elige los que en su link terminen con las 
                            extensiones .<em>edu</em>, .<em>gob</em> u .<em>org</em>. <br/><br/>
                            
                            <h3 className='SubTitulo'>Comenta tus búsquedas con tus familiares mayores</h3><br/>
                            
                            Es probable que la información que encuentres en sitios especializados no siempre  
                            sea fácil de entender o te cueste trabajo saber a cuál de todas las fuentes de 
                            información les debes dar mayor prioridad. Por ello, conversarlo con algún adulto de 
                            tu casa te permitirá comprender mejor lo que encuentres. La capacidad de análisis de 
                            ellos combinada con tus habilidades digitales permitirán que obtengas mejores 
                            resultados.
                        </p>

                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <Grid container justifyContent="center" alignItems='flex-end'>
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img6to/Sesion7/2.png"alt="Tarjeta" />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura ">
                            Como has visto, no toda la información que se encuentre al alcance de tu mano 
                            significa que sea confiable. Por ello, cuando tengas una inquietud o encuentres algo, 
                            compártelo con tus amistades. Quizá ellos tengan información que puedan ofrecerte, 
                            para así crear comunidades de conocimiento confiable en las que todos participen.
                        </p>
                    </Grid>
                </Grid>
               
                <Grid container item xs={12} justifyContent="center" className="ContainerDerechos">
                    <p className="Derechos">
                        © 2022. Todos los derechos reservados, Pearson, Circuito de lectores.
                    </p>                
                </Grid>
        </Grid>
    </Grid>
    );
}
 
export default Manual_para_saber_que_es_cierto_y_que_no_en_las_apps;
