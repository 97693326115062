import React               from "react";
import {Grid, makeStyles}  from '@material-ui/core';


const useStyles = makeStyles(theme =>({
  ContainerReading:{
    // width: "80%",
    font: '24px Lato;',
    // height:"60%",
    letterSpacing: '0.15px',
    boxSizing:'border-box',
    paddin:'0px 10px 0 10px',
    // overflowY: "scroll",
    // scrollbarColor: "#F6DE95",
    // scrollbarWidth: "10px",
    // '&::-webkit-scrollbar': {
    //   width: '15px',
    // },
    // '&::-webkit-scrollbar-track': {
    //   borderRadius: '25px',
    //   backgroundColor: "#F6DE95",
    //   webkitBoxShadow: 'inset 0 0 3px rgba(0,0,0,0.00)'
    // },
    // '&::-webkit-scrollbar-thumb': {
    //   backgroundColor: '#8144DF',
    //   borderRadius: '25px',
    // },
    [theme.breakpoints.down(1400)]:{
      font: " 20px lato",
      width: "100%",
    },
    [theme.breakpoints.down(425)]:{
      font: " 16px lato",
    },
  },
  reading: {
    margin:'0px',
  },
}));

const QuestionText = (props) => {
  const classes = useStyles()
  const { questionText } = props

  return(
    <Grid className={classes.ContainerReading}>
      <p 
        dangerouslySetInnerHTML={{__html: `${questionText}`}}
        className={classes.reading}
      >
      </p>
    </Grid>

  )

}

export default QuestionText;
