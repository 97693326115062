import React, { useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import axios from "../../../../services/axios";
import { Link } from "react-router-dom";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import LoopIcon from "@material-ui/icons/Loop";
import EditIcon from "@material-ui/icons/Edit";
import IconButton from "@material-ui/core/IconButton";
import UsabilityContext from "../../../../usability/UsabilityContext";
import ModalConfirm from "./ModalConfirm";
import ModalMessage from "./ModalMessage";


const GroupsTable = () => {
  const { handleUserData, search } = React.useContext(UsabilityContext);
  const [rows, setRows] = React.useState([]);
  const [confirmationModalOpen, setConfirmationModalOpen] =
    React.useState(false);
  const [confirmationModalOpen2, setConfirmationModalOpen2] =
    React.useState(false);
  const [modalMessageOpen, setModalMessageOpen] = React.useState(false);
  const [confirmChangesResult, setConfirmChangesResult] = React.useState("");
  const [idLTIDelete, setIdSchoolToDelete] = React.useState(null);
  const [nombreLTI, setKeyToUpdate] = React.useState(null);
  const [nombreUpdata, setKeyToUpdate2] = React.useState(null);
  const [openEdit, setOpenEdit] = useState(false);


  const handleCloseConfirmationModal = () => {
    setConfirmationModalOpen(!confirmationModalOpen);
  };
  const handleCloseConfirmationModal2 = () => {
    setConfirmationModalOpen2(!confirmationModalOpen2);
  };
  const handleOpenEdit = () => {
    setOpenEdit(true);
  };

  function getStatus(params) {
    return params.row.reg_status ? "Si" : "No";
  }

  const columns = [
    {
      field: "id_lti",
      headerName: "ID",
      width: 50,
      editable: false,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "nombre",
      headerName: "Nombre",
      width: 150,
      editable: false,
    },
    {
      field: "url",
      headerName: "Url de plataforma",
      width: 125,
      editable: false,
    },
    {
      field: "correo",
      headerName: "Correo de contacto",
      width: 250,
      editable: false,
    },
    {
      field: "reg_status",
      headerName: "Habilitado",
      width: 100,
      editable: false,
      valueGetter: getStatus,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "llave",
      headerName: "Llave de consumidor",
      width: 350,
      editable: false,
    },
    {
      field: "secreto",
      headerName: "Secreto compartido",
      width: 300,
      editable: false,
    },
    {
      field: "delete",
      headerName: "Acciones",
      width: 150,
      editable: false,
      renderCell: (params) => (
        <>
          <IconButton color="primary" aria-label="edit">
            <Link
              to={`/admin/LMS/${params.row?.id_lti}/editar`}
              style={{ textDecoration: "none" }}
            >
              <EditIcon
                onClick={() => {
                  handleOpenEdit(true);
                  const datatoEdit = rows.find((idLTIDelete) => {
                    return idLTIDelete === Number(params.row?.id_lti);
                  });

                  handleUserData({ ...datatoEdit });
                }}
              />
            </Link>
          </IconButton>

          <IconButton
            aria-label="update"
            onClick={() => {
              handleUpdate(params?.row?.id_lti);
              handleUpdate2(params?.row?.nombre);
            }}
          >
            <LoopIcon />
          </IconButton>

          <IconButton
            color="secondary"
            aria-label="delete"
            onClick={() => {
              handleDelete(params?.row?.id_lti);
            }}
          >
            <DeleteForeverIcon />
          </IconButton>
        </>
      ),
    },
  ];

  const confirmDelete = () => {
    setConfirmationModalOpen(false);
    axios
      .delete(`/lti/delete?id_lti=${idLTIDelete}`)
      .then((response) => {
        if (response.status === 200) {
          setConfirmationModalOpen(false);
          setModalMessageOpen(true);
          setConfirmChangesResult("La plataforma ha sido eliminada");
          const listWithoutThisSchool = rows?.filter(
            (lti) => lti?.id_lti !== idLTIDelete
          );
          setRows(listWithoutThisSchool);
        } else {
        }
      })
      .catch((error) => {
        if (error.response) {
          // Request made and server responded
          const errorData = error.response.data;
          const mensaje =
            errorData?.detalles?.msg ||
            "Ha ocurrido un error al consumir los servicios, inténtelo de nuevo más tarde.";
          setConfirmChangesResult(mensaje);
          setConfirmationModalOpen(false);
          setModalMessageOpen(true);
        } else {
          // Something happened in setting up the request that triggered an Error
          const mensaje =
            "Ha ocurrido un error al consumir los servicios, inténtelo de nuevo más tarde.";
          setConfirmChangesResult(mensaje);
          setConfirmationModalOpen(false);
          setModalMessageOpen(true);
        }
      })
      .finally(() => {});
  };

  const handleDelete = (id_lti) => {
    setIdSchoolToDelete(id_lti);
    setConfirmationModalOpen(true);
  };

  const confirmUpdate = () => {
    setConfirmationModalOpen2(false);
    const body = {
      nombre: nombreUpdata,
    };
    axios
      .put(`lti/updateKeys?id_lti=${nombreLTI}`, body)
      .then((response) => {
        if (response.status === 200) {
          setConfirmationModalOpen2(false);
          setModalMessageOpen(true);
          setConfirmChangesResult(
            "Las llaves de la plataforma han sido actualizadas"
          );
          axios.get(`/lti/list`).then((response) => {
            if (response.status === 200) {
              const groupsList = response.data.resultado.plataformas?.map(
                (lti) => {
                  return {
                    ...lti,
                    id: lti.id_lti,
                  };
                }
              );
              setRows(groupsList);
            }
          });
        } else {
        }
      })
      .catch((error) => {
        if (error.response) {
          // Request made and server responded
          const errorData = error.response.data;
          const mensaje =
            errorData?.detalles?.msg ||
            "Ha ocurrido un error al consumir los servicios, inténtelo de nuevo más tarde.";
          setConfirmChangesResult(mensaje);
          setConfirmationModalOpen2(false);
          setModalMessageOpen(true);
        } else {
          // Something happened in setting up the request that triggered an Error
          const mensaje =
            "Ha ocurrido un error al consumir los servicios, inténtelo de nuevo más tarde.";
          setConfirmChangesResult(mensaje);
          setConfirmationModalOpen2(false);
          setModalMessageOpen(true);
        }
      })
      .finally(() => {});
  };

  const handleUpdate = (id_lti) => {
    setKeyToUpdate(id_lti);
    setConfirmationModalOpen2(true);
  };

  const handleUpdate2 = (nombre) => {
    setKeyToUpdate2(nombre);
  };

  const handleCloseMessageModal = () => {
    setModalMessageOpen(!modalMessageOpen);
  };

  React.useEffect(() => {
    axios
      .get(`/lti/list`)
      .then((response) => {
        if (response.status === 200) {
          const groupsList = response.data.resultado.plataformas?.map((lti) => {
            return {
              ...lti,
              id: lti.id_lti,
            };
          });
          setRows(groupsList);
        }
      })
      .catch((error) => {
        console.log(error, "error");
      });

    return () => {};
  }, []);
  const recargarTabla = (newRows) => {
    setRows({
      ...rows,
      newRows,
    });
  };

  return (
    <>
      <div style={{ height: 580, width: "100%" }}>
        <DataGrid
          rows={rows.filter((row) => {
            if (search) {
              return (
                row?.nombre?.toLowerCase().startsWith(search.toLowerCase()) ||
                row?.url?.toLowerCase().startsWith(search.toLowerCase()) ||
                row?.correo?.toLowerCase().startsWith(search.toLowerCase())
              );
            } else {
              return row;
            }
          })}
          columns={columns}
          pageSize={10}
          rowsPerPageOptions={[10]}
          disableSelectionOnClick
          disableColumnFilter
          disableColumnMenu
        />
      </div>
      <ModalConfirm
        open={confirmationModalOpen2}
        handleClose={handleCloseConfirmationModal2}
        title="¿Estás seguro de actualizar esta plataforma? 
      ten en cuenta que después no podrás recuperar los datos."
        handleConfirm={confirmUpdate}
      />

      <ModalConfirm
        open={confirmationModalOpen}
        handleClose={handleCloseConfirmationModal}
        title="¿Estás seguro de eliminar esta plataforma? ten en cuenta que después no podrás recuperar los datos."
        handleConfirm={confirmDelete}
      />

      <ModalMessage
        open={modalMessageOpen}
        handleClose={handleCloseMessageModal}
        title={confirmChangesResult}
      />
    </>
  );
};

export default GroupsTable;
