import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
    ContenedorLectura:{
        width:'502px',
                  [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
    },
    ContainerTitulo:{
        margin:'0 0 34px 0',
        '& .Titulo':{
            letterSpacing: '0px',
            color: '#E97BA8',
            margin:'0',
            textAlign:'center',
            font:'43px fredoka one',
            lineHeight:'52px',
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            margin:'0 0 36px 0',
            color:'#000',
            font:'18px lato',
            lineHeight :'28px',
            letterSpacing: '0.17px',
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .SubTitulo':{
            color:'#0CB5D6',
            margin:'0',
            lineHeight :'28px',
            font:'bold 26px lato',
            letterSpacing: '0.24px',
            [theme.breakpoints.down(502)]:{
                font:'bold 18px lato',
                lineHeight :'24px',
                letterSpacing: '0.21px',
            },
        },
        '& .SubTitulo2':{
            color:'#926DD1',
            margin:'0',
            font:'bold 22px lato',
            lineHeight :'24px',
            letterSpacing: '0.21px',
            [theme.breakpoints.down(502)]:{
                font:'bold 18px lato',
                lineHeight :'20px',
                letterSpacing: '0.16px',
            },
        },
        '& .TextImg':{
            color:'#717476',
            margin:'24px 0 0 0',
            font:' 16px lato',
            lineHeight :'25px',
            letterSpacing: '0.15px',
            [theme.breakpoints.down(502)]:{
                font:' 11px lato',
                lineHeight :'14px',
                letterSpacing: '0.10px',
            },
        },

        '& .Imagen':{
            width:'100%',
        },  
      
    }
}));

const Cuál_es_el_propósito_del_arte = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>

            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                        <h2 className="Titulo">¿Cuál es el propósito del arte?</h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                       <p className='Lectura'>
                        <h3 className='SubTitulo'>El arte busca el placer</h3><br/>

                        ¿Alguna vez te has preguntado para qué sirve el arte? Quizá encontraremos la 
                        respuesta pensando qué sucede cuando nos relacionamos con él. Por ejemplo, 
                        podemos llorar, reír, bailar o cantar si vemos una película o suena una canción que 
                        nos gusta. Experimentamos sensaciones.<br/><br/>
                        
                        ¿Eso quiere decir que el propósito del arte es causar placer a quienes lo ven, lo oyen y
                        lo recuerdan? <br/><br/>
                        <h3 className='SubTitulo2'>Ciencia, arte y placer</h3><br/>
                        
                        El doctor Semir Zeki, experto en neurobiología y estética del University College de 
                        Londres, realizó un experimento con 28 personas que carecían de conocimientos 
                        previos de arte. Les mostró cuadros que jamás habían visto para averiguar qué 
                        procesos ocurrían en sus cerebros. Todos los sujetos experimentaron un aumento de
                        flujo sanguíneo en zonas del cerebro relacionadas con el placer luego de apenas 10
                        segundos de contemplar alguna de esas obras. El estado resultante fue igual al de un 
                        cerebro enamorado. <br/><br/>
                        
                        ¿Has intentado ver una película sin sonido? La musicalización transmite buena parte 
                        de las emociones que sentimos en ciertas escenas. Sin ella, una película de terror 
                        causaría menos impacto. Todos los elementos del arte tratan de generar una 
                        experiencia sensorial en el espectador. <br/><br/>
                        
                        ¿Qué clase de placer experimentamos cuando vemos una película de terror? ¿Es el 
                        mismo que sentimos frente a la pintura de un paisaje? Si no, ¿qué tienen ambos en 
                        común? Reflexiónalo. <br/><br/>
                        
                        <h3 className='SubTitulo'>Más allá del placer</h3><br/>
                        
                        Pero ¿la finalidad del arte es aportar una sensación de bienestar inmediato o esta 
                        sensación más bien ayuda a conseguir otro fin? Por ejemplo, muchos escritores han
                         utilizado el arte para comunicar ideas o denuncias. Cuando muchos ciudadanos de 
                         Atenas mostraban desaprobación por la guerra, el dramaturgo griego Eurípides 
                         utilizó el teatro para expresarla.
                       </p>                   
                    </Grid>
                    <Grid container justifyContent='center'>
                        <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion21/1.png" alt="Tarjeta" />
                    </Grid>
                    <Grid container >
                            <p className="TextImg">
                                En obras como <em>Troyanas</em> y <em>Suplicantes</em>, Eurípides presenta la guerra como violencia sin sentido en beneficio de los gobernantes.
                            </p>
                        </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container >
                        <p className='Lectura'>
                            Muchos autores del siglo <span style={{fontVariant: "all-small-caps"}}>XX</span> se preguntaban cómo la literatura podía tener un  
                            impacto social. El dramaturgo Bertolt Brecht se preocupaba especialmente por las 
                            emociones que el arte produce sobre los espectadores. Por ejemplo, ¿qué pasa si 
                            acompañamos en su dolor a un personaje cuyos actos desaprobamos? Brecht quería 
                            un teatro en el que el espectador cuestionara las situaciones representadas, en vez 
                            de sólo dejarse llevar por ellas.
                        </p>
                    </Grid>
                    <Grid container justifyContent='center'>
                        <Grid container justifyContent='center'>
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion21/2.png" alt="Tarjeta" />
                        </Grid>
                        <Grid container justifyContent='center' >
                            <p className="TextImg">
                                Brecht quería que el público usara sus obras para pensar y discutir problemas sociales.
                            </p>
                        </Grid>
                    </Grid>

                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container >
                        <p className='Lectura'>
                            Asimismo, la canción de protesta latinoamericana denuncia las injusticias que 
                            ocurren en diversos países e intenta generar un sentimiento de unión entre las clases 
                            más desfavorecidas. <br/><br/>
                            
                            <h3 className='SubTitulo'>El artista</h3> <br/>
                            
                            Entonces, ¿qué relación hay entre el placer, el arte y el artista? En ciertas culturas 
                            existe el estereotipo del artista como una persona particularmente sensible que sólo 
                            puede librarse de sus problemas emocionales y experimientar placer mediante la 
                            producción de arte. Esta idea proviene de una corriente conocida como <em>romanticismo</em>.
                        </p>
                    </Grid>
                    <Grid container justifyContent='center'>
                        <Grid container justifyContent='center'>
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img5to/Sesion21/3.png" alt="Tarjeta" />
                        </Grid>
                        <Grid container justifyContent='center' >
                            <p className="TextImg">
                                El <em>romanticismo</em> fue una tendencia artística que surgió entre los siglos <span style={{fontVariant: "all-small-caps"}}>XVIII</span> y <span style={{fontVariant: "all-small-caps"}}>XIX</span>. Sugería que 
                                el arte se origina en los sentimientos del artista. Muchos rasgos del romanticismo perviven en 
                                el arte moderno.
                            </p>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container >
                        <p className='Lectura'>
                            Sin embargo, el arte no depende sólo de los sentimientos: es una cuestión de técnica 
                            en la que el artista también puede encontrar placer. El experto en literatura Jonathan 
                            Culler afirma que el poeta no usa solamente el poema como técnica para hablar de un 
                            tema, sino que también emplea el tema como una herramienta para producir un 
                            diseño novedoso que dialogue con su tradición y la transforme.<br/><br/>
                            
                            Pero recuerda: no todo lo que les ocurre a los personajes de una obra recrea la 
                            vida de alguien (autor, director, guionista o actor). Y tampoco todo lo que dicen 
                            o hacen refleja lo que el artista considera que está bien.
                        </p>
                    </Grid>
                </Grid>
        </Grid>
    </Grid>
    );
}
 
export default Cuál_es_el_propósito_del_arte;
