import { Button, Grid, makeStyles } from "@material-ui/core";
import React from "react";
import BlurContainer from "../../../BlurContainer";

import Entusiasmado from "../../../../Emotions/MoodImgs/entusiasmado_1.png";
import Entusiasmado2 from "../../../../Emotions/MoodImgs/entusiasmado_2.png";
import Feliz from "../../../../Emotions/MoodImgs/feliz_1.png";
import Feliz2 from "../../../../Emotions/MoodImgs/feliz_2.png";

const useStyles = makeStyles((theme) => ({
  Container: {
    height: "calc(100vh - 79.5px)",
    width: "97vw",
    display: "grid",
    gridTemplateColumns: "1fr",
    gridTemplateRows: "1fr 0.5fr 80px",
    // overflow: "auto",
    [theme.breakpoints.down(1161)]: {
      padding: "26px 0 0 0",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100vw",
    },
    [theme.breakpoints.up(1280)]: {
      // height: "calc(100% - 79.5px - 79.5px)",
    },
    "& .Boton": {
      position: "relative",
      [theme.breakpoints.down(1161)]: {
        padding: "26px 0 ",
      },
    },
  },
  ContainerEmotios: {
    display: "grid",
    gridTemplateColumns: "1fr",
    gridTemplateRows: "100px 1fr",
    [theme.breakpoints.up("lg")]: {
      gridTemplateRows: "200px 1fr",
    },
  },
  containerEmotionsButtons: {
    display: "grid",
    gridTemplateColumns: "repeat(3, 1fr)",
    gridTemplateRows: "repeat(2, 122px)",
    [theme.breakpoints.up("lg")]: {
      gridTemplateColumns: "repeat(6, 1fr)",
      gridTemplateRows: "182px",
    },
    "@media(min-height:680px)": {
      gridTemplateRows: "repeat(2, 182px)",
    },
    // overflow: "hidden",
  },
  Question: {
    font: "40px Fredoka One",
    color: "#E67196",
    letterSpacing: " 0px",
    margin: 0,
    textAlign: "center",
    [theme.breakpoints.down(1161)]: {
      font: "36px Fredoka One",
    },
    [theme.breakpoints.down(502)]: {
      font: "26px Fredoka One",
    },
  },

  Checkbox: {
    minHeight: "105px",
    [theme.breakpoints.up("460")]: {
      minHeight: "115px",
    },
    [theme.breakpoints.up("sm")]: {
      minHeight: "135px",
    },
    [theme.breakpoints.up("md")]: {
      minHeight: "155px",
    },
  },
  CheckboxSelected: {
    position: "relative",
    alignItems: "center",
    [theme.breakpoints.up("460")]: {
      minHeight: "115px",
    },
    [theme.breakpoints.up("sm")]: {
      minHeight: "135px",
    },
    [theme.breakpoints.up("md")]: {
      minHeight: "155px",
    },
  },

  Emocion: {
    cursor: "pointer",
    height: "105px",
    width: "105px",
    border: "none",
    alignSelf: "center",
    borderRadius: "50%",
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    "&:hover": {
      backgroundRepeat: "no-repeat",
    },
    [theme.breakpoints.up("460")]: {
      height: "115px",
      width: "115px",
    },
    [theme.breakpoints.up("sm")]: {
      height: "135px",
      width: "135px",
    },
    [theme.breakpoints.up("md")]: {
      height: "155px",
      width: "155px",
    },
    "@media(max-height:680px)": {
      width: "115px",
      height: "75%",
    },
  },

  Calmado: {
    // opacity: 0,
  },
  Feliz: {
    position: "relative",
    backgroundColor: "transparent",
    backgroundImage: (props) =>
      props.SelectFeliz ? `url(${Feliz2})` : `url(${Feliz})`,
    "&:before": {
      // display: (props) => (props.openMenuInDesktop ? "none" : "flex"),
      display: "flex",
      zIndex: -2,
      textTransform: "none",
      justifyContent: "center",
      alignItems: "center",
      // borderRadius: "25px",
      position: "absolute",
      content: '""',
      // border: "15px solid",
      // backgroundColor: "#fff",
      font: "bold 14px lato",
      width: "90px",
      height: "90px",
      top: "7px",
      right: "7px",
      // width: "300px",
      // height: "300px",
      // top: "-72.5px",
      // right: "-72.5px",
      borderRadius: "50%",
      boxShadow: "0px 20px 75px 75px white",
      [theme.breakpoints.up("460")]: {
        height: "100px",
        width: "100px",
      },
      [theme.breakpoints.up("sm")]: {
        top: "12px",
        right: "12px",
        width: "110px",
        height: "110px",
      },
      [theme.breakpoints.up("md")]: {
        width: "130px",
        height: "130px",
      },
      "@media(max-height:680px)": {
        top: "20px",
        left: "20px",
        right: "unset",
        width: "60px",
        height: "60px",
      },
    },
    "&:hover": {
      backgroundImage: `url(${Feliz2})`,
    },
  },
  Entusiasmado: {
    position: "relative",
    backgroundColor: "transparent",
    backgroundImage: (props) =>
      props.SelectEntusiasmado
        ? `url(${Entusiasmado2})`
        : `url(${Entusiasmado})`,
    "&:hover": {
      backgroundImage: `url(${Entusiasmado2})`,
    },
    "&:before": {
      // display: (props) => (props.openMenuInDesktop ? "none" : "flex"),
      display: "flex",
      zIndex: -2,
      textTransform: "none",
      justifyContent: "center",
      alignItems: "center",
      // borderRadius: "25px",
      position: "absolute",
      content: '""',
      // border: "15px solid",
      // backgroundColor: "#fff",
      font: "bold 14px lato",
      width: "90px",
      height: "90px",
      top: "7px",
      right: "7px",
      // width: "300px",
      // height: "300px",
      // top: "-72.5px",
      // right: "-72.5px",
      borderRadius: "50%",
      boxShadow: "0px 20px 75px 75px white",
      [theme.breakpoints.up("460")]: {
        height: "100px",
        width: "100px",
      },
      [theme.breakpoints.up("sm")]: {
        top: "10px",
        right: "10px",
        width: "110px",
        height: "110px",
      },
      [theme.breakpoints.up("md")]: {
        width: "130px",
        height: "130px",
      },
      "@media(max-height:680px)": {
        top: "20px",
        left: "20px",
        right: "unset",
        width: "60px",
        height: "60px",
      },
    },
  },
  mood: {
    margin: "0px",
    font: "18px Fredoka One",
    textAlign: "center",
    letterSpacing: "0px",
    color: "#575757",
    position: "relative",
    [theme.breakpoints.up("sm")]: {
      font: "22.24px Fredoka One",
    },
  },

  ContainerBarraInferior: {
    // background:
    //   "transparent linear-gradient(90deg, #DC5EA6 0%, #F58D7E 100%) 0% 0% no-repeat padding-box;",
    height: "79.5px",
  },
  dialog: {
    backgroundColor: "#3f43b3",
    fontFamily: "Lato",
    letterSpacing: "0.36px",
    fontSize: "16px",
    color: "#ffffff",
    position: "absolute",
    top: "165px",
    width: "100%",
    padding: "24px",
    boxSizing: "border-box",
    borderRadius: "21.25px",
    [theme.breakpoints.up("md")]: {
      top: "180px",
    },
    "&:before": {
      // display: (props) => (props.openMenuInDesktop ? "none" : "flex"),
      display: "flex",
      zIndex: 1,
      textTransform: "none",
      justifyContent: "center",
      alignItems: "center",
      // borderRadius: "25px",
      position: "absolute",
      content: '""',
      border: "15px solid",
      borderTopColor: "transparent",
      borderLeftColor: "transparent",
      borderBottomColor: "#3f43b3",
      borderRightColor: "transparent",
      font: "bold 14px lato",
      top: "-28px",
      right: "calc(50% - 15px)",
      [theme.breakpoints.down(1280)]: {
        left: "-28px",
        right: "unset",
        top: "calc(50% - 15px)",
        borderBottomColor: "transparent",
        borderRightColor: "#3f43b3",
      },
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "20px",
      padding: "24px",
    },
    [theme.breakpoints.up("lg")]: {
      minWidth: "425px",
    },
    [theme.breakpoints.down(1280)]: {
      top: "unset",
      left: "80%",
    },
  },
  BotonContinuar: {
    textTransform: "none",
    height: "62px",
    width: "238px",
    background: "#7A54BA",
    // boxShadow: "0px 3px 6px #00000029",
    borderRadius: "61px",
    color: "#FFFFFF",
    font: "bold 20px lato ",
    "&:hover": {
      background: "#dd78fb",
    },
    "&:first-letter": {
      textTransform: "uppercase",
    },
    boxShadow: "0px 0px 15px 20px white",
  },
  dialogButtonContinuar: {
    backgroundColor: "#3f43b3",
    fontFamily: "Lato",
    letterSpacing: "0.36px",
    fontSize: "20px",
    color: "#ffffff",
    position: "absolute",
    top: "90px",
    width: "auto",
    maxWidth: "425px",
    padding: "24px",
    boxSizing: "border-box",
    borderRadius: "21.25px",
    [theme.breakpoints.down(1280)]: {
      top: "unset",
      bottom: "100%",
    },
    "@media(max-height:680px)": {
      top: "unset",
      bottom: "100%",
    },
    "&:before": {
      // display: (props) => (props.openMenuInDesktop ? "none" : "flex"),
      display: "flex",
      zIndex: 1,
      textTransform: "none",
      justifyContent: "center",
      alignItems: "center",
      // borderRadius: "25px",
      position: "absolute",
      content: '""',
      border: "15px solid",
      borderTopColor: "transparent",
      borderLeftColor: "transparent",
      borderBottomColor: "#3f43b3",
      borderRightColor: "transparent",
      font: "bold 14px lato",
      top: "-28px",
      right: "calc(50% - 15px)",
      [theme.breakpoints.down(1280)]: {
        borderTopColor: "#3f43b3",
        borderBottomColor: "transparent",
        top: "unset",
        bottom: "-28px",
      },
      "@media(max-height:680px)": {
        borderTopColor: "#3f43b3",
        borderBottomColor: "transparent",
        top: "unset",
        bottom: "-28px",
      },
    },
  },
}));

const EmotionsStep = ({
  handleClose,
  open,
  step,
  handleStep,
  saveEmotion,
  handleSelectFeliz,
  handleSelectEntusiasmado,
}) => {
  const classes = useStyles({});
  return (
    <>
      <BlurContainer handleClose open>
        <Grid className={classes.Container} container justifyContent="center">
          <Grid
            container
            justifyContent="center"
            className={classes.ContainerEmotios}
          >
            <Grid
              container
              item
              justifyContent="center"
              alignContent="center"
            ></Grid>
            <Grid
              container
              item
              justifyContent="center"
              className={classes.containerEmotionsButtons}
            >
              <Grid
                container
                item
                direction="column"
                className={classes.Checkbox}
              ></Grid>
              <Grid
                container
                item
                direction="column"
                className={classes.Checkbox}
              ></Grid>
              <Grid
                container
                item
                direction="column"
                className={classes.Checkbox}
              ></Grid>
              <Grid
                container
                item
                direction="column"
                className={classes.CheckboxSelected}
              >
                {!step?.emotion.one && (
                  <>
                    <button
                      className={`${classes.Entusiasmado}  ${classes.Emocion}`}
                      onClick={() => {
                        handleSelectEntusiasmado();
                        handleStep({
                          ...step,
                          emotion: {
                            ...step?.emotion,
                            one: true,
                          },
                        });
                      }}
                    ></button>
                    <p className={classes.mood}>Entusiasmado</p>
                    <p className={classes.dialog}>
                      En esta pantalla deberás responder cómo te sientes
                      seleccionando una <b> carita.</b>
                    </p>
                  </>
                )}
              </Grid>
              <Grid
                container
                item
                direction="column"
                className={classes.CheckboxSelected}
              >
                {step?.emotion.one &&
                  step?.emotion.two &&
                  !step?.emotionEnd.one && (
                    <>
                      <button
                        className={`${classes.Feliz} ${classes.Emocion}`}
                        onClick={() => {
                          handleSelectFeliz();
                          handleStep({
                            ...step,
                            emotion: {
                              ...step?.emotionEnd,
                              one: true,
                            },
                          });
                        }}
                      ></button>
                      <p className={classes.mood}>Feliz</p>
                      <p className={classes.dialog}>
                        Nuevamente aparecerá esta pantalla, selecciona cómo te
                        sientes después de haber escuchado la práctica guiada.
                      </p>
                    </>
                  )}
              </Grid>
              <Grid
                container
                item
                direction="column"
                lg={2}
                md={4}
                sm={4}
                xs={6}
                className={classes.Checkbox}
              ></Grid>
            </Grid>
            <Grid container item justifyContent="center" className="Boton">
              {step.emotion.one && !step?.emotion.two && (
                <>
                  <Button
                    onClick={() => {
                      handleStep({
                        ...step,
                        emotion: {
                          ...step?.emotion,
                          two: true,
                        },
                      });
                      saveEmotion();
                    }}
                    className={classes.BotonContinuar}
                    // disabled={pristine}
                  >
                    Continuar
                  </Button>
                  <p className={classes.dialogButtonContinuar}>
                    Una vez seleccionada la carita se activará el botón
                    <b> Continuar</b>, haz clic sobre él.
                  </p>
                </>
              )}
            </Grid>
          </Grid>
          <Grid
            container
            item
            sm={12}
            justifyContent="center"
            className={classes.ContainerBarraInferior}
          ></Grid>
        </Grid>
      </BlurContainer>
    </>
  );
};

export default EmotionsStep;
