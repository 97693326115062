import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
     Contenedor:{
        background:'#E9FBF9',
        width:'100vw',
        overflowX:'clip',
        [theme.breakpoints.down(1280)]:{
            background:'none',
        },
        [theme.breakpoints.down(600)]:{
            marginTop: '79.51px',
        }
    },
  ContainerInfoLectura: {
    position: "relative",
    height: "86px",
    [theme.breakpoints.down(502)]:{
        height: "43px",
    },
    background: "#76D4C3",
    "& .BarraInfoLectura": {
      height: "86px",
      background: `Url(https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Barras/Azul.png)`,
        [theme.breakpoints.down(502)]:{
            height: "43px",
        },
    },
    "& .InfoLectura": {
      position: "absolute",
      height: "86px",
      width: "219px",
      boxSizing: "border-box",
      padding: "17.2px 0",
      background: "#FAFAFA",
      borderRadius: "50px",
      boxShadow: "2px 2px 2px 2px #00000029",
      zIndex: "4",
        [theme.breakpoints.down(502)]:{
            height: "43px",
            width: "109px",
            padding: "8.6px 0",
            borderRadius: "25px",
        },
    },
    "& .InfoLectura:before": {
      position: "absolute",
      display: "flex",
      boxSizing: "border-box",
      padding: "0 0 0 10px",
      justifyContent: "center",
      alignItems: "center",
      height: "39.88px",
      width: "39.88px",
      borderRadius: "50%",
      content: '""',
      boxShadow: "3px 0px .5px 0px #00000029",
      background: "#FAFAFA",
      left: "90px",
      top: "65px",
      transform: "rotate(0.25turn)",
      [theme.breakpoints.down(502)]:{
        left: "45px",
        top: "30px",
        height: "20.88px",
        width: "20.88px",
        },
    },
    "& .InfoLectura:after": {
      content: '""',
      height: "15px",
      width: "15px",
      borderTop: "2px solid #20A698",
      borderLeft: "2px solid #20A698",
      position: "absolute",
      bottom: "-10px",
      left: "Calc(50% - 8px)",
      transform: "rotate(0.625turn)",
      [theme.breakpoints.down(502)]:{
        height: "10px",
        width: "10px",
        left: "Calc(50% - 4.5px)",
        bottom: "-3px",
        borderTop: "1px solid #20A698",
        borderLeft: "1px solid #20A698",
        },
    },
    "& .Sesion": {
      borderLeft: "2px solid #D8D8D8",
    },
    "& .Dato": {
      margin: "0 0 5px 0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#20A698",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        margin: "0 0 2.5px 0",
        },
    },
    "& .Caracter": {
      margin: "0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#000000",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        },
    },   },
    ContenedorLectura:{
        background:'#fff',
        width:'1160px',
        height:'100%',
        padding:'60px 168px 35px ',
        [theme.breakpoints.down(1280)]:{
            width:'502px',
            padding:'60px 0 0 0',
          },
        [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
        '& .ContainerDerechos':{
            bottom:'80px',
            [theme.breakpoints.down(1280)]:{
                display:'none',
            },
        },
        '& .Derechos':{
            textAlign:"center",
            margin:"0px",
            font:" 15px lato",
            color:"#717476",
        },
        '& .Escritorio':{
            display:'block',
            [theme.breakpoints.down(1280)]:{
                display:'none'
            },
        },
        '& .Tablet':{
            display:'none',
            [theme.breakpoints.down(1280)]:{
                display:'block'
            },
        }
    },
    ContainerTitulo:{
        margin:'0 0 34px 0',
        [theme.breakpoints.down(1280)]:{
            margin:'0 0 34px 0',
        },
        '& .Titulo':{
            font:'48px fredoka one',
            letterSpacing: '0px',
            lineHeight:'58px',
            color: '#776F13',
            margin:'0',
            textAlign:'center',
            [theme.breakpoints.down(1280)]:{
                font:'43px fredoka one',
                lineHeight:'52px',
            },
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            lineHeight : '30px',
            margin:'0 0 36px 0',
            font:'22px lato',
            color:'#000',
            letterSpacing: '0.20px',
            [theme.breakpoints.down(1280)]:{
                font:'18px lato',
                lineHeight :'28px',
                letterSpacing: '0.17px',
            },
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        "& .SubTitulo": {
            font: "bold 30px lato",
            color: "#D17E43",
            letterSpacing: ".28px",
            lineHeight: "30px",
            margin: "0",
            [theme.breakpoints.down(1280)]: {
              lineHeight: "28px",
              font: "bold 26px lato",
              letterSpacing: "0.24px",
            },
            [theme.breakpoints.down(502)]: {
              font: "bold 22px lato",
              lineHeight: "24px",
              letterSpacing: "0.21px",
            },
          },
          '& .Esp':{
            boxSizing:'border-box',
            padding:'0 46px 0 0',
            margin:'0',
            [theme.breakpoints.down(1280)]: {
                padding:'0 ',
                margin:'0 0 36px 0',
              },
          },
        '& .TextImg':{
            font:'20px lato',
            color:'#717476',
            letterSpacing:'.19px',
            lineHeight:'30px',
            margin:'0 0 20px 0',
            [theme.breakpoints.down(1280)]:{
                font:' 16px lato',
                lineHeight :'25px',
                letterSpacing: '0.15px',
            },
            [theme.breakpoints.down(502)]:{
                font:' 11px lato',
                lineHeight :'14px',
                letterSpacing: '0.10px',
            },
        },

        '& .Imagen':{
            [theme.breakpoints.down(1280)]:{
                width:'100%',
            },
        }, 
        '& .Imagen2':{
            width:'508px',
            [theme.breakpoints.down(1280)]:{
                width:'86.45%',
            },
        },    
      
    }
}));

const Todos_los_seres_vivos_tienen_un_papel_en_la_Tierra = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
             <Grid container item justifyContent="center" className={classes.ContainerInfoLectura}>
               <Grid container className="BarraInfoLectura"></Grid>
               <Grid container item className="InfoLectura">
                    <Grid container item direction="column" xs={6} justifyContent="center">
                        <p className="Dato">Grado</p>
                        <p className="Caracter">2</p>
                    </Grid>
                    <Grid container item direction="column" xs={6} justifyContent="center" className="Sesion">
                        <p className="Dato">Sesión</p>
                        <p className="Caracter">42</p>
                    </Grid>
               </Grid>
            </Grid>

            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >
                        Todos los seres vivos<br className='Tablet'/> tienen<br className='Escritorio'/> un papel<br className='Tablet'/> en la Tierra
                    </h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                      <p className="Lectura">
                        <h3 className='SubTitulo'>La naturaleza es sorprendente</h3><br/>
                        ¿Cuántas veces te has emocionado ante los eventos de la naturaleza o ante los seres 
                        que la integran? La puesta de sol, un arcoíris, el brote de las flores o el nacimiento de 
                        algún animal son momentos que guardas en tu memoria porque su impacto se 
                        conecta con tus emociones.<br/><br/>
                        
                        Hay toda clase de especies en la Tierra: las que se imponen por su tamaño, las que
                        nos parecen misteriosas y las que consideramos bellas o llamativas. También hay
                        algunas que nos producen temor o repulsión porque su apariencia nos resulta 
                        espeluznante o porque sabemos que pueden ser peligrosas. Pero cada ser vivo tiene 
                        un papel importante en el ecosistema. Recuerda: la Tierra es de todos.
                      </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <Grid container justifyContent="center" >
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img2do/Sesion42/1.png" alt="Tarjeta" />
                        </Grid>
                        <Grid container justifyContent="center" >
                            <p className="TextImg">
                            Mira la gran diversidad de especies que pueden apreciarse en la ilustración. Así de inmenso es el planeta, y cada ser vivo importa.
                            </p>
                        </Grid>

                    </Grid>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                            <h3 className='SubTitulo'>¿Deberíamos temer a las arañas?</h3>
                        </p>
                    </Grid>
                    <Grid container md={12} lg={5}>
                      <p className="Lectura Esp">

                        Un ejemplo de esos animales que nos asustan son las arañas. 
                        Algunas personas les tienen un miedo irracional llamado 
                        <em> aracnofobia</em>. Aunque es importante que sepas que no todas las 
                        arañas son peligrosas. <br/><br/>
                        
                        Existen cerca de 45 000 especies diferentes de arañas, pero 
                        sólo alrededor de 30 resultan de verdad amenazantes para el 
                        ser humano. Por ejemplo, dos de las especies más peligrosas 
                        son la reclusa chilena, que vive en Sudamérica, y la viuda negra, 
                        en Norteamérica. Ésas sí son venenosas, y es mejor alejarnos y 
                        no alterarlas. Por fortuna, podemos convivir tranquilamente 
                        con la mayoría de ellas. <br/><br/>
                        
                        <span className="Tablet">De hecho, las arañas son muy importantes para el medio 
                        ambiente. Son depredadoras naturales y cazan insectos (como 
                        mosquitos, chinches o polillas). Las más grandes incluso 
                        pueden atrapar roedores pequeños y algunos pájaros. Si tienes 
                        una plaga en casa, pueden ser tus mejores aliadas para 
                        terminar con ella. Y muy pocas son agresivas. Casi todas tejen 
                        una telaraña para atrapar ahí a sus presas, así que es muy poco 
                        probable que ataquen a una persona.</span>
                      </p>
                    </Grid>
                    <Grid container justifyContent="center" md={12} lg={7}>
                        <Grid container justifyContent="center" >
                            <img className="Imagen2" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img2do/Sesion42/2.png" alt="Tarjeta" />
                        </Grid>
                        <Grid container justifyContent="center" >
                            <p className="TextImg">
                            Tejer es uno de los oficios de la araña. ¿Cuántos oficios crees que podría aprender un ser humano en su vida?
                            </p>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                      <p className="Lectura">
                        Existen enciclopedias y libros especializados. Tanto allí como 
                        en internet encontrarás información para saber qué papel 
                        representa cada ser vivo en el planeta. Además, conocerás 
                        opciones para cuidarlos a todos y convivir respetuosamente 
                        con ellos. También puedes hablar con personas mayores de tu 
                        comunidad, pues poseen conocimientos valiosos al respecto.
                      </p>
                    </Grid>
                </Grid>
        </Grid>
        <Grid container item xs={12} justifyContent="center" className="ContainerDerechos">
            <p className="Derechos">
                © 2022. Todos los derechos reservados, Pearson, Circuito de lectores.
            </p>                
        </Grid>
    </Grid>
    );
}
 
export default Todos_los_seres_vivos_tienen_un_papel_en_la_Tierra ;
