import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
    ContenedorLectura:{
        width:'502px',
                  [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
    },
    ContainerTitulo:{
        margin:'0 0 34px 0',
        '& .Titulo':{
            letterSpacing: '0px',
            color: '#0CB5D6',
            margin:'0',
            textAlign:'center',
            font:'43px fredoka one',
            lineHeight:'52px',
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            margin:'0 0 36px 0',
            color:'#000',
            font:'18px lato',
            lineHeight :'28px',
            letterSpacing: '0.17px',
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        "& .SubTitulo": {
            color: "#0CB5D6",
            margin: "0",
            lineHeight: "28px",
            font: "bold 26px lato",
            letterSpacing: "0.24px",
            [theme.breakpoints.down(502)]: {
              font: "bold 22px lato",
              lineHeight: "24px",
              letterSpacing: "0.21px",
            },
        },
        '& .TextImg':{
            color:'#717476',
            margin:'20px 0 0 0',
            font:' 16px lato',
            lineHeight :'25px',
            letterSpacing: '0.15px',
            [theme.breakpoints.down(502)]:{
                font:' 11px lato',
                lineHeight :'14px',
                letterSpacing: '0.10px',
            },
        },

        '& .Imagen':{
            width:'100%',
        }, 

    }
}));

const Arquitectura_y_ciudad = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >
                        Arquitectura y ciudad               
                    </h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                      <p className="Lectura">
                        La arquitectura es una de las artes más antiguas de la humanidad. Existe desde hace 
                        mucho tiempo y ha evolucionado mucho. Surge de la necesidad humana de tener un
                        espacio seguro y agradable donde vivir. La utilidad es la parte más importante, pero 
                        el diseño es una propiedad que no puede ignorarse. Es por ello que esta disciplina 
                        conjunta dos características fundamentales: la necesidad y la belleza.<br/><br/>
                        
                        Todos los edificios de las civilizaciones antiguas eran prácticos, ya que se hacían con 
                        un propósito específico. Los principios de construcción y los diseños han cambiado 
                        mucho a lo largo de los siglos, pero las bases son las mismas. De hecho, hubo 
                        sociedades que hicieron ciudades inmensas con poca tecnología, pero que hoy siguen 
                        sorprendiendo a quienes las visitan por su precisión. En algunas poblaciones 
                        egipcias, incas y mayas se utilizaron los mismos principios creativos que en las 
                        capitales más modernas del mundo, como Tokio y Londres. <br/><br/>
                        
                        La arquitectura brinda lugares con misiones diferentes. Por ejemplo, algunos sirven 
                        como viviendas, otros como restaurantes, otros más como escuelas y algunos más se 
                        utilizan como tiendas. Por lo tanto, la finalidad de cada establecimiento va a 
                        determinar sus características particulares y su diseño. Las personas especialistas 
                        que construyen las ciudades tienen que conocer el ritmo de vida, los hábitos y los 
                        gustos de su población. Es importante que presten atención a sus clientes para que 
                        puedan satisfacerlos con éxito.
                      </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <Grid container justifyContent="center" >
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img4to/Sesion56/1.png" alt="Tarjeta" />
                        </Grid>
                        <Grid container justifyContent="center" >
                            <p className="TextImg">
                                Las ciudades son construidas por especialistas, como urbanistas e ingenieros.                          
                            </p>
                        </Grid>
                    </Grid>
                </Grid>
                
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                            Muchas personas aportan su talento y su trabajo, pero todo empieza con la gente 
                            que habitará los edificios. Son los inquilinos quienes aportan las propiedades básicas. 
                            Después, los arquitectos diseñan la estructura, el interior y el exterior. Ellos deciden 
                            qué elementos estarán presentes, así como el orden que tendrán y los materiales con 
                            los que estarán hechos. Elaboran planos y dibujos que explican todo lo que imaginan 
                            en su mente.<br/><br/>
                            
                            Después, hacen llegar esos planos a quienes darán forma a sus ideas. Requieren la 
                            colaboración de una gran variedad de profesiones para que todo salga bien. Por 
                            ejemplo, los albañiles colocan todos los ladrillos, hacen mezclas de cemento y 
                            levantan los muros que sostendrán las estructuras. ¡Su aportación es fundamental! 
                            Es importante que lo hagan con precisión y calma, pues los cimientos de todo están 
                            sus manos, no pueden ser débiles. <br/><br/>
                            
                            Después, aparecen otras personas que darán vida a los interiores: plomeros que 
                            instalarán las tuberías y los sistemas de agua, electricistas que proveerán energía e 
                            instalaciones seguras de usar, carpinteros que harán muebles y afinarán detalles en 
                            los muros y, por último, pintores, quienes darán color a cada habitación. Todos 
                            aportan visiones y avances, el trabajo sería imposible si uno de ellos faltara. <br/><br/>
                            
                            Los pueblos y las urbes son creados a partir de la arquitectura, por pequeños que
                            sean. Quizá no lo hayas notado, pero cada construcción que observas pasó por el 
                            trabajo de múltiples individuos. Basta con ver el lugar en el que vives: hay casas, 
                            oficinas, tiendas, escuelas, restaurantes y parques que diariamente utilizas. Por ello, 
                            éste es el arte más utilizado, está presente en todas partes, pero no es fácil notarlo 
                            porque resulta cotidiano.<br/><br/> 
                            
                            Ahora conoces la relación que guarda la arquitectura con las personas y todo el 
                            proceso que hay detrás de un lugar. ¿Alguna vez imaginaste que requería de tantos 
                            especialistas?
                        </p>
                    </Grid>
                </Grid>
        </Grid>
    </Grid>
    );
}
 
export default Arquitectura_y_ciudad ;
