import { makeStyles, Grid } from "@material-ui/core";
import React from "react";
import { Link } from "react-router-dom";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
const useStyles = makeStyles((theme) => ({
  barChat: {
    background: "#FDF6E1 0% 0% no-repeat padding-box",
    boxShadow: "0px 3px 6px #00000029",
    display: "flex",
    gap: "20px",
    padding: "20px",
    justifyContent: "flex-start",
    color: "#007FA3",
    alignItems: "center",
    flexWrap: "nowrap",
  },
  name: {
    fontSize: "15px",
    margin: 0,
  },
  group: {
    fontSize: "12px",
    margin: 0,
    textTransform: "capitalize",
    color: " #7A54BA",
  },
  iconStar: {
    color: "#F3CD52",
  },
  iconStarOff: {
    color: "#00000050",
  },
  iconNewMessage: {
    transform: "rotate(0deg)",
    borderRadius: "24.6px",
    backgroundColor: "#F6644B",
    width: "20px",
    height: "20px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "8px",
    /* UI Properties */
    // background: "#f00 0% 0% no-repeat padding-box",
    color: "#fff",
    [theme.breakpoints.up("lg")]: {
      fontSize: "12px",
      minWidth: "25px",
      height: "25px",
      borderRadius: "25px",
    },
  },
  //
  link: {
    cursor: "pointer",
    textDecoration: "none",
    width: "100%",
  },
}));
const ChatListItem = ({ chat }) => {
  const classes = useStyles({});

  return (
    <>
      <Link className={classes.link} to={`/chat/${chat?.sala}`}>
        <Grid
          container
          item
          direction="row"
          justifyContent="center"
          className={classes.barChat}
        >
          <PersonOutlineIcon />
          <Grid container direction="column" justifyContent="flex-start">
            <p className={classes.name}>
              {chat?.nombre} {chat?.apellido_paterno}
            </p>
            <p className={classes.group}>
              {chat?.grado} {chat?.gru}
            </p>
          </Grid>
          {chat?.notificaciones > 0 && (
            <span className={classes.iconNewMessage}>
              {chat?.notificaciones || 5}
            </span>
          )}
        </Grid>
      </Link>
    </>
  );
};

export default ChatListItem;
