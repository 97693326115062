import { Button, Grid, makeStyles } from "@material-ui/core";
import React, { useContext, useEffect } from "react";
import DrawerContext from "../../../../../usability/DrawerContext";
import { useOnLoadImages } from "../../../../hooks/useOnLoadImages";
import Loader from "../../../../Loader/Loader";
import Drawer from "../../ReadingTools/Drawer";

const useStyles =  makeStyles (theme =>({
     Contenedor:{
        background:'#E9FBF9',
        width:'100vw',
        overflowX:'clip',
        [theme.breakpoints.down(1161)]:{
            background:'none',
           overflowX:'unset',
        },
        [theme.breakpoints.down(600)]:{
            marginTop: '79.51px',
        }
    },
ContainerInfoLectura: {
    position: "relative",
    height: "86px",
    background: "#817FD9",
    [theme.breakpoints.down(502)]:{
        height: "43px",
    },
    "& .BarraInfoLectura": {
      height: "86px",
      background: `Url(https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Barras/Morada.png)`,
      [theme.breakpoints.down(502)]:{
        height: "43px",
    },
    },
    "& .InfoLectura": {
      position: "absolute",
      height: "86px",
      width: "219px",
      boxSizing: "border-box",
      padding: "17.2px 0",
      background: "#FAFAFA",
      borderRadius: "50px",
      boxShadow: "2px 2px 2px 2px #00000029",
      zIndex: "4",
      [theme.breakpoints.down(502)]:{
        height: "43px",
        width: "109px",
        padding: "8.6px 0",
        borderRadius: "25px",
    },
    },
    "& .InfoLectura:before": {
      position: "absolute",
      display: "flex",
      boxSizing: "border-box",
      padding: "0 0 0 10px",
      justifyContent: "center",
      alignItems: "center",
      height: "39.88px",
      width: "39.88px",
      borderRadius: "50%",
      content: '""',
      boxShadow: "3px 0px .5px 0px #00000029",
      background: "#FAFAFA",
      left: "90px",
      top: "65px",
      transform: "rotate(0.25turn)",
      [theme.breakpoints.down(502)]:{
        left: "45px",
        top: "30px",
        height: "20.88px",
        width: "20.88px",
        },
    },
    "& .InfoLectura:after": {
      content: '""',
      height: "15px",
      width: "15px",
      borderTop: "2px solid #817FD9",
      borderLeft: "2px solid #817FD9",
      position: "absolute",
      bottom: "-10px",
      left: "Calc(50% - 8px)",
      transform: "rotate(0.625turn)",
      [theme.breakpoints.down(502)]:{
        height: "10px",
        width: "10px",
        left: "Calc(50% - 4.5px)",
        bottom: "-3px",
        borderTop: "1px solid #20A698",
        borderLeft: "1px solid #20A698",
        },
    },
    "& .Sesion": {
      borderLeft: "2px solid #D8D8D8",
    },
    "& .Dato": {
      margin: "0 0 5px 0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#817FD9",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        margin: "0 0 2.5px 0",
        },
    },
    "& .Caracter": {
      margin: "0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#000000",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        },
    },
       '& .Regresar':{
        color: '#F2624F',
        border: '#F2624F 1px solid',
        height: '30px',
        width:'100px',
        borderRadius: '20px',
        letterSpacing: '0.14px',
        textTransform: 'none',
        background:'#FEFAF1',
        top:'calc(50% - 15px)',
        left:'5%',
        zIndex:4,
        [theme.breakpoints.down(502)]: {
          font:'bold 10px lato',
          width: "60px",
        },
      },
  },
    ContenedorLectura:{
        background:'#fff',
        width:'1160px',
        height:'100%',
        padding:'60px 168px 35px ',
        
        transform: (props) =>
        props.scale > 1 ? `scale(${props.scale}) translate(0px, 10%)` : `none`,
        [theme.breakpoints.down(1161)]:{
            width:'502px',
            padding:'60px 0 0 0',
          },
        [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },

        '& .Escritorio':{
            display:'block',
            [theme.breakpoints.down(1161)]:{
                display:'none'
            },
        },
        '& .Tablet':{
            display:'none',
            [theme.breakpoints.down(1161)]:{
                display:'block'
            },
        }
    },
    ContainerTitulo:{
        margin:'0 0 46px 0',
        '& .Titulo':{
            letterSpacing: '0px',
            color: '#4E91FD',
            margin:'0',
            textAlign:'center',
            font:'48px fredoka one',
            [theme.breakpoints.down(1161)]:{
                font:'43px fredoka one',
                lineHeight:'52px',
            },
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            margin:'0',
            color:'#000',
            lineHeight : '30px',
            font:'22px lato',
            letterSpacing: '0.18px',
            [theme.breakpoints.down(1161)]:{
                font:'18px lato',
                lineHeight :'28px',
                letterSpacing: '0.17px',
            },
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .SubTitulo':{
            color:'#00ADB6',
            margin:'0',
            font:'bold 30px lato',
            letterSpacing:'.28px',
            lineHeight:'30px',
            [theme.breakpoints.down(1161)]:{
                lineHeight :'28px',
                font:'bold 26px lato',
                letterSpacing: '0.24px',
            },
            [theme.breakpoints.down(502)]:{
                font:'bold 18px lato',
                lineHeight :'24px',
                letterSpacing: '0.21px',
            },
        },
        '& .TextImg':{
            color:'#717476',
            margin:'13px 0 0 0',
            font:'20px lato',
            lineHeight:'30px',
            letterSpacing:'.19px',
            [theme.breakpoints.down(1161)]:{
                margin:'21px 0 0 0',
                font:' 16px lato',
                lineHeight :'25px',
                letterSpacing: '0.15px',

            },
            [theme.breakpoints.down(502)]:{
                font:' 11px lato',
                lineHeight :'14px',
                letterSpacing: '0.10px',
            },
        },
        '& .Imagen':{
            [theme.breakpoints.down(1161)]:{
                width:'100%',
            },
        },
    },
}));


const Urge_implementar_estrategias_para_reducir_nuestra_huella_ecológica_en_el_planetalatina = (props) => {


  const {activeStep, setActiveStep, handlePreviewStep, id_actividad} = props
  
  const handleContinue = () => {
    setActiveStep(activeStep - 1)
    handlePreviewStep()
  }

  const {
    handleSize,
    disabledZoom,
    closeZoom,
    handleZoom,
    scale
  }= useContext(DrawerContext);
  const [loading, setLoading] = React.useState(true);
  const wrapperRef = React.useRef(null);
  const imagesLoaded = useOnLoadImages(wrapperRef);

  const detectSize = () => {
    const lectura = document.getElementById("lectura");
    const newWidth = lectura?.offsetWidth;
    const newHeight = lectura?.offsetHeight;
    setLoading(false);
    if (lectura) handleSize(newHeight, newWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", detectSize);
    return () => {
      handleZoom()
      window.removeEventListener("resize", detectSize);
      closeZoom()
    };
  }, []);

  const classes = useStyles({ disabledZoom,scale });
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
             <Grid container item justifyContent="center" className={classes.ContainerInfoLectura}>
                       <Grid container className="BarraInfoLectura">
            <Button className="Regresar" onClick={handleContinue}>
                Regresar
            </Button>
        </Grid>
               <Grid container item className="InfoLectura">
                    <Grid container item direction="column" xs={6} justifyContent="center">
                        <p className="Dato">Grado</p>
                        <p className="Caracter">4</p>
                    </Grid>
                    <Grid container item direction="column" xs={6} justifyContent="center" className="Sesion">
                        <p className="Dato">Sesión</p>
                        <p className="Caracter">38</p>
                    </Grid>
               </Grid>
            </Grid>
            {!imagesLoaded && <Loader />}
        <Grid
            container
            item
            className={classes.ContenedorLectura}
            justifyContent="center"
            id="lectura"
            onLoad={detectSize}
            ref={wrapperRef}
        >
<Drawer id_lectura={id_actividad} />
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >
                        Urge implementar<br className='Tablet'/> estrategias<br className='Escritorio'/> para <br className='Tablet'/>reducir nuestra huella<br/> ecológica en el planeta
                    </h2>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container item justifyContent='center' xs={12}>
                        <p className="Lectura">
                            <h3 className='SubTitulo'>La marca que dejamos en el mundo</h3><br/>
                            
                            El concepto de huella ecológica fue creado por William Rees y Mathis Wackernagel 
                            en 1996. ¿Lo habías escuchado? ¿Sabes de qué se trata? Se refiere al impacto que 
                            nuestra vida, hábitos cotidianos y ritmo de consumo tienen en el planeta. Hacemos 
                            uso de muchos recursos diariamente, por ejemplo, el agua y el gas. Continuamente 
                            generamos residuos en forma de basura, algunos de los cuales permanecerán 
                            durante siglos antes de degradarse. <br/><br/>
                            
                            También contaminamos al utilizar la luz o los automóviles, aunque sea de forma 
                            indirecta. Lo que comemos, cómo nos transportamos, las cosas que compramos y 
                            otros factores de este tipo contaminan nuestro entorno. Por lo tanto, es importante 
                            buscar que esta situación disminuya de manera inmediata.<br/><br/>
                            
                            <h3 className='SubTitulo'>¿Cómo calcular nuestra huella ecológica?</h3><br/>
                            
                            Se han creado varias aplicaciones que ayudan a calcularla. Están disponibles en 
                            internet y tienen factores comunes que ayudan a llegar a un mismo resultado. Para 
                            conocer la respuesta, te hacen preguntas sobre tu estilo de vida. Por ejemplo, el 
                            número de veces que comes carne en una semana, el medio de transporte que 
                            utilizas para ir a la escuela, cuántos focos hay en tu casa y cuánto tiempo tomas para 
                            darte un baño. <br/><br/>
                            
                            La calculadora obtiene el impacto de tus hábitos diarios con este tipo de datos y te 
                            proporciona una serie de resultados que muestra cuál es la superficie que necesitaría 
                            tener el mundo para poder soportar las secuelas que le causas. <br/><br/>
                            
                            Por ejemplo, si la plataforma muestra que las consecuencias de alguien abarcan tres 
                            planetas, quiere decir que el globo terráqueo tendría que ser tres veces más grande 
                            para poder asumir sus hábitos sin que se acaben sus recursos y tengan tiempo de 
                            regenerarse. Pues cada recurso que usamos requirió un tiempo en estar listo para
                            usarse y necesitará más para reponerse. <br/><br/>
                            
                            <h3 className='SubTitulo'>¿Cómo disminuirla?</h3><br/>
                            
                            En un escenario ideal, la suma de los efectos de todas las personas tendría que ser de 
                            una Tierra o menos, pues únicamente contamos con un planeta. Esto significaría que 
                            éste sería capaz de controlar los desastres y que ninguno de sus recursos se 
                            terminaría. Es decir, que no sería un problema sostener a la humanidad. Pero 
                            podemos comprobar que ese ideal está muy lejos de la realidad cuando realizamos el 
                            cálculo en alguna de las aplicaciones. 
                        </p>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">

                    <Grid container item className='ultimo' md={12} lg={6}>
                        <p className="Lectura Esp">
                            Es fundamental tomar medidas para 
                            disminuir la alteración que estamos 
                            causando en el mundo. No seremos los 
                            únicos habitantes, por eso es necesario 
                            conservarlo tanto para nosotros como 
                            para las generaciones futuras.<br/><br/>
                            
                            <span className='Escritorio'>
                                Un par de medidas sencillas y útiles son el 
                                ahorro de agua y luz. Puedes hacer lo 
                                primero fácilmente cerrando el grifo 
                                siempre que te laves los dientes, usando 
                                poca agua al lavarte las manos y tomando 
                                duchas cortas, en las que también cierres 
                                la llave mientras te enjabonas.
                            </span>
                        </p>
                    </Grid>
                    <Grid container item justifyContent="center" md={12} lg={6} >
                        <Grid container item justifyContent="center" xs={12} >
                            <img className='Imagen' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img4to/Sesion38/1.png"  alt="Tarjeta" />
                        </Grid>
                        <Grid container item justifyContent="center" xs={12} >
                            <p className='TextImg'>
                                Idealmente, la huella ecológica generada por toda la gente tendría que ser de un planeta, o menos que eso.
                            </p>
                        </Grid>
                    </Grid>
                    
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">

                    <Grid container item  xs={12} >
                        <p className="Lectura ">
                            <span className='Tablet '>
                            Un par de medidas sencillas y útiles son el 
                            ahorro de agua y luz. Puedes hacer lo 
                            primero fácilmente cerrando el grifo 
                            siempre que te laves los dientes, usando 
                            poca agua al lavarte las manos y tomando 
                            duchas cortas, en las que también cierres 
                            la llave mientras te enjabonas.<br/><br/>
                            </span>
                            Para ahorrar luz, puedes apagar los focos que no estés ocupando, desconectar todos 
                            los aparatos y enchufes que no utilices, disminuir el uso del aire acondicionado o la 
                            calefacción y aprovechar la luz natural siempre que sea posible.<br/><br/>
                            
                            Es posible hacer grandes cambios con estas medidas, aunque parezcan pequeñas. ¡Inténtalo!
                        </p>
                    </Grid>
                </Grid>
        </Grid>
        <Grid container item style={{ marginTop: 80}} ></Grid>
    </Grid>
    );
}
 
export default Urge_implementar_estrategias_para_reducir_nuestra_huella_ecológica_en_el_planetalatina;
