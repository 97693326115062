import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
     Contenedor:{
        background:'#E9FBF9',
        width:'100vw',
        overflowX:'clip',
        [theme.breakpoints.down(1161)]:{
            background:'none',
           overflowX:'unset',
        },
        [theme.breakpoints.down(600)]:{
            marginTop: '79.51px',
        }
    },
  ContainerInfoLectura: {
    position: "relative",
    height: "86px",
    [theme.breakpoints.down(502)]:{
        height: "43px",
    },
    background: "#76D4C3",
    "& .BarraInfoLectura": {
      height: "86px",
      background: `Url(https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Barras/Azul.png)`,
        [theme.breakpoints.down(502)]:{
            height: "43px",
        },
    },
    "& .InfoLectura": {
      position: "absolute",
      height: "86px",
      width: "219px",
      boxSizing: "border-box",
      padding: "17.2px 0",
      background: "#FAFAFA",
      borderRadius: "50px",
      boxShadow: "2px 2px 2px 2px #00000029",
      zIndex: "4",
        [theme.breakpoints.down(502)]:{
            height: "43px",
            width: "109px",
            padding: "8.6px 0",
            borderRadius: "25px",
        },
    },
    "& .InfoLectura:before": {
      position: "absolute",
      display: "flex",
      boxSizing: "border-box",
      padding: "0 0 0 10px",
      justifyContent: "center",
      alignItems: "center",
      height: "39.88px",
      width: "39.88px",
      borderRadius: "50%",
      content: '""',
      boxShadow: "3px 0px .5px 0px #00000029",
      background: "#FAFAFA",
      left: "90px",
      top: "65px",
      transform: "rotate(0.25turn)",
      [theme.breakpoints.down(502)]:{
        left: "45px",
        top: "30px",
        height: "20.88px",
        width: "20.88px",
        },
    },
    "& .InfoLectura:after": {
      content: '""',
      height: "15px",
      width: "15px",
      borderTop: "2px solid #20A698",
      borderLeft: "2px solid #20A698",
      position: "absolute",
      bottom: "-10px",
      left: "Calc(50% - 8px)",
      transform: "rotate(0.625turn)",
      [theme.breakpoints.down(502)]:{
        height: "10px",
        width: "10px",
        left: "Calc(50% - 4.5px)",
        bottom: "-3px",
        borderTop: "1px solid #20A698",
        borderLeft: "1px solid #20A698",
        },
    },
    "& .Sesion": {
      borderLeft: "2px solid #D8D8D8",
    },
    "& .Dato": {
      margin: "0 0 5px 0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#20A698",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        margin: "0 0 2.5px 0",
        },
    },
    "& .Caracter": {
      margin: "0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#000000",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        },
    },   },
    ContenedorLectura:{
        background:'#fff',
        width:'1160px',
        height:'100%',
        padding:'60px 168px 35px ',
        [theme.breakpoints.down(1161)]:{
            width:'502px',
            padding:'60px 0 0 0',
          },
        [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
        '& .ContainerDerechos':{
            bottom:'80px',
            [theme.breakpoints.down(1161)]:{
                display:'none',
            },
        },
        '& .Derechos':{
            textAlign:"center",
            margin:"0px",
            font:" 15px lato",
            color:"#717476",
        },
        '& .Escritorio':{
            display:'block',
            [theme.breakpoints.down(1161)]:{
                display:'none'
            },
        },
        '& .Tablet':{
            display:'none',
            [theme.breakpoints.down(1161)]:{
                display:'block'
            },
        }
    },
    ContainerTitulo:{
        margin:'0 0 34px 0',
        [theme.breakpoints.down(1161)]:{
            margin:'0 0 34px 0',
        },
        '& .Titulo':{
            font:'48px fredoka one',
            letterSpacing: '0px',
            lineHeight:'58px',
            color: '#F6644B',
            margin:'0',
            textAlign:'center',
            [theme.breakpoints.down(1161)]:{
                font:'43px fredoka one',
                lineHeight:'52px',
            },
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            lineHeight : '30px',
            margin:'0 0 36px 0',
            font:'22px lato',
            color:'#000',
            letterSpacing: '0.20px',
            [theme.breakpoints.down(1161)]:{
                font:'18px lato',
                lineHeight :'28px',
                letterSpacing: '0.17px',
            },
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .SubTitulo':{
            font:'bold 30px lato',
            color:'#161CB1',
            letterSpacing:'.28px',
            lineHeight:'30px',
            margin:'0',
            [theme.breakpoints.down(1161)]:{
                lineHeight :'28px',
                font:'bold 26px lato',
                letterSpacing: '0.24px',
            },
            [theme.breakpoints.down(502)]:{
                font:'bold 18px lato',
                lineHeight :'24px',
                letterSpacing: '0.21px',
            },
        },
        '& .SubTitulo2':{
            font:'bold 26px lato',
            color:'#D63390',
            letterSpacing:'.24px',
            lineHeight:'28px',
            margin:'0',
            [theme.breakpoints.down(1161)]:{
                font:'bold 22px lato',
                lineHeight :'24px',
                letterSpacing: '0.21px',
            },
            [theme.breakpoints.down(502)]:{
                font:'bold 18px lato',
                lineHeight :'20px',
                letterSpacing: '0.16px',
            },
        },
        '& .Espacio':{
            position:'relative',
        },
        '& .SubTitulo3':{
            position:'absolute',
            font:' 30px Fredoka One',
            color:'#FF1919',
            lineHeight:'36px',
            margin:'0',
            [theme.breakpoints.down(1161)]:{
                font:' 16px Fredoka One',
                lineHeight :'20px',
            },
            [theme.breakpoints.down(502)]:{
                font:'bold 12px lato',
                lineHeight :'14px',
            },
        },
        '& .Pos1':{
            width:'189px',
            top:'98px',
            left:'427px',
            [theme.breakpoints.down(1161)]:{
                width:'101px',
                top:'59px',
                left:'260px',
            },
            [theme.breakpoints.down(502)]:{
                width:'69px',
                top:'43px',
                left:'181px',
            },
        },
        '& .Pos2':{
            width:'180px',
            top:'488px',
            left:'153px',
            [theme.breakpoints.down(1161)]:{
                width:'95px',
                top:'294px',
                left:'94px',
            },
            [theme.breakpoints.down(502)]:{
                width:'61px',
                top:'206px',
                left:'63px',
            },
        },
        '& .TextImg':{
            font:'20px lato',
            color:'#717476',
            letterSpacing:'.19px',
            lineHeight:'30px',
            margin:'21px 0 0 0',
            [theme.breakpoints.down(1161)]:{
                font:' 16px lato',
                lineHeight :'25px',
                letterSpacing: '0.15px',
            },
            [theme.breakpoints.down(502)]:{
                font:' 11px lato',
                lineHeight :'14px',
                letterSpacing: '0.10px',
            },
        },
        '& .Imagen':{
            width:'100%',
        },  
      
    }
}));


const Un_sistema_solar_en_acción = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
             <Grid container item justifyContent="center" className={classes.ContainerInfoLectura}>
               <Grid container className="BarraInfoLectura"></Grid>
               <Grid container item className="InfoLectura">
                    <Grid container item direction="column" xs={6} justifyContent="center">
                        <p className="Dato">Grado</p>
                        <p className="Caracter">2</p>
                    </Grid>
                    <Grid container item direction="column" xs={6} justifyContent="center" className="Sesion">
                        <p className="Dato">Sesión</p>
                        <p className="Caracter">4</p>
                    </Grid>
               </Grid>
            </Grid>

            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo Escritorio" >Un sistema solar en acción</h2>
                    <h2 className="Titulo Tablet" >Un sistema solar <br/>en acción</h2>

                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                      <p className="Lectura">
                        <h3 className='SubTitulo'>¿El Sol se mueve? </h3><br/>

                        Quizá tu respuesta inmediata sea “sí”, pues cuando amanece lo vemos en un sitio, al 
                        mediodía se coloca justo sobre nuestra cabeza y por la tarde se oculta del lado 
                        contrario al que lo vimos aparecer. Hace mucho tiempo se creía que la Tierra era el 
                        centro del universo y que el Sol y todos los astros giraban alrededor de ella. Esta 
                        manera de ver las cosas se conoce como <em>teoría geocéntrica</em>. Pero en realidad, el hecho 
                        de que observemos al Sol en distintos puntos a lo largo del día se debe al movimiento 
                        de nuestro planeta.<br/><br/>
                      </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <Grid container justifyContent="center" >
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img2do/Sesion4/1.png" alt="Tarjeta" />
                        </Grid>
                        
                        <Grid container >
                            <p className="TextImg">
                            Si pudieras tomar distancia para ver nuestro sistema solar, observarías que los planetas,
                            incluida la Tierra, giran alrededor del Sol.
                            </p>
                        </Grid>
                        
                    </Grid>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container sm={12}>
                      <p className="Lectura"> 
                        Después de casi 2 000 años de investigación, los científicos revelaron que la Tierra es 
                        la que se mueve de lugar con respecto al Sol, siguiendo un camino a su alrededor. A 
                        ese trayecto se le conoce como <em>movimiento de traslación</em>. Además, nuestro planeta 
                        gira sobre su propio eje, como un objeto que da vueltas sin moverse de lugar. Ése es 
                        el <em>movimiento de rotación</em>. Se piensa que la Tierra recorre 1 800 kilómetros de su 
                        órbita en un segundo.<br/><br/> 

                        ¿Lo sentiste? Justo acaba de moverse.
                      </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <Grid container justifyContent="center" className='Espacio'>
                            <h3 className='SubTitulo3 Pos1'>Movimiento de traslación</h3>
                            <h3 className='SubTitulo3 Pos2'>Movimiento de rotación</h3>

                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img2do/Sesion4/2.png" alt="Tarjeta" />
                        </Grid>
                        
                        <Grid container item justifyContent='center'  >
                            <p className="TextImg">
                            Nuestro planeta no es estático, por el contrario, está en movimiento permanente.
                            </p>
                        </Grid>
                        
                    </Grid>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                      <p className="Lectura">
                        <h3 className='SubTitulo2'>La historia del Sol y sus oficios </h3><br/>

                        El Sol es una estrella de gran tamaño, alrededor de la cual se encuentran los planetas 
                        que conforman el sistema solar. Tiene unos 4 500 millones de años de antigüedad, es 
                        decir, es muy viejo. Está formado por hidrógeno y helio en su núcleo. En su superficie 
                        hay una gran actividad producida por las explosiones y el movimiento de los gases.<br/><br/>  
                        
                        La vida en la Tierra depende de la energía que recibe del Sol. Gracias a él las plantas 
                        hacen fotosíntesis, algunos animales cumplen con sus actividades, y la temperatura 
                        en el planeta es la adecuada para habitarlo. Su importancia es tal que sin él la Tierra 
                        perdería buena parte de sus movimientos.<br/><br/>  
                        
                        ¿Has reflexionado acerca de cómo influyen los movimientos de la Tierra en tu vida? 
                        Puedes pensar en qué estación del año ocurren tus celebraciones o temporada 
                        vacacional favoritas. Muchas de nuestras acciones están estrechamente 
                        relacionadas con los movimientos de nuestro planeta.
                      </p>
                    </Grid>
                </Grid>
               
            <Grid container item xs={12} justifyContent="center" className="ContainerDerechos">
                <p className="Derechos">
                    © 2022. Todos los derechos reservados, Pearson, Circuito de lectores.
                </p>                
            </Grid>
        </Grid>
    </Grid>
    );
}
 
export default Un_sistema_solar_en_acción;
