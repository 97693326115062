import { makeStyles, Grid, Button } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import axios from "../../../services/axios";
import Loader from "../../Loader/Loader";
import ModalMessage from "../../Modals/ModalMessage";

import SelectSchool from "../Admin_Pearson/CargarDatos/SelectSchool";
import ModalRegistroExitoso from "./ModalRegistroExitoso";

import SelectGradeGroup from "./SelectGrades";

const useStyles = makeStyles((theme) => ({
  formContainer: {
    // display: "flex",
    display: "grid",
    // flexDirection: "column"
    gridTemplateColumns: "1fr",
    width: "80%",
    boxSizing: "border-box",
    maxWidth: "560px",
    alignItems: "flex-start",
    justifyItems: "center",
    gap: "30px",

    [theme.breakpoints.up("lg")]: {
      width: "100%",
      maxWidth: "680px",
      alignItems: "end",
      gridTemplateColumns: "1fr 1fr",
    },
  },
  formItemContainer: {
    gap: "10px",
  },
  input: {
    width: "100%",
    height: "40px",
    paddingLeft: "27px",
    paddingRight: "27px",
    borderRadius: "61px",
    boxSizing: "border-box",
    // border: " solid #1D2AAD 3px ",
    border: "none",
    background: "#FFFFFF",
    font: "Helvetica",
    fontSize: "16px",
    //    width: '449px',
    // height: '72px',
    // margin: '43px 139px 19px 66px',
    // padding: '26px 24px 25px',
    backgroundColor: "#f0f0f0",
    "&:focus": {
      outline: "none",
    },
    [theme.breakpoints.up("lg")]: {
      // height: "60px",
      // maxWidth: "449px",
      fontSize: "18px",
    },
  },
  label: {
    fontWeight: "bold",
    fontFamily: "Lato, sans-serif",
  },
  PurpleButton: {
    color: "#FFFFFF",
    backgroundColor: "#9051b1",
    margin: "0 auto",
    borderRadius: "30px",
    alignSelf: "center",
    width: "100%",
    maxWidth: "238px",
    minHeight: "45px",
    border: "none",
    textTransform: "unset",
    cursor: "pointer",
    fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
    fontWeight: "500",
    padding: "6px 16px",
    lineHeight: "1.75",
    transition:
      "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    boxShadow:
      "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
    "&:hover": {
      backgroundColor: "#B965D3 ",
    },
  },
  schoolAddress: {
    color: "#323232",
    margin: 0,
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    font: "14px Lato",
    width: "100%",
    overflow: "hidden",
    maxWidth: "220px",
    [theme.breakpoints.up("md")]: {
      maxWidth: "40ch",
    },
  },
}));

const DocenteGenerico = ({ data }) => {
  const classes = useStyles();

  const [formData, setFormData] = useState({
    escuela: data?.nombreEscuela,
    id_escuela: data?.id_escuela,
    grado: data?.id_grado,
    perfil: 2,
  });

  const [listOfSchools, setListOfSchools] = useState([]);
  const [loading, setLoading] = useState(false);
  const [optionGrades, setOptionGrades] = useState([]);
  const [readyToRegister, setReadyToRegister] = useState(false);
  const [modalMessage, setModalMessage] = useState("");

  const [modalMessageOpen, setModalMessageOpen] = useState();
  const [responseRegister, setResponseRegister] = useState({});

  const [registerCompletedModalOpen, setRegisterCompletedModalOpen] =
    useState(false);
  const handleCloseMessageModal = () => {
    setModalMessageOpen(!modalMessageOpen);
  };

  const handleCloseRegisterCompleted = () => {
    setRegisterCompletedModalOpen(!registerCompletedModalOpen);
  };

  useEffect(() => {
    axios
      .get(`/listado/escuelas?id_usuario=44`)
      .then((response) => {
        if (response.status === 200) {
          const list = response.data.resultado;
          setListOfSchools(list);
        }
      })
      .catch((error) => {
        console.log(error, "errorESC");
      });

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (formData?.id_escuela) {
      axios
        .get(`listado/grado/grupos?id_escuela=${formData?.id_escuela}`)
        .then((response) => {
          if (response.status === 200) {
            setOptionGrades(response?.data?.resultado?.listado);
          }
        })
        .catch((error) => {
          console.log(error, "error");
        });
    }
  }, [formData?.id_escuela]);
  // }, [id_escuela]);

  useEffect(() => {
    if (
      formData?.id_escuela &&
      formData?.nombre &&
      formData?.primer_apellido &&
      formData?.correo &&
      formData?.correo === formData?.correo_confirmacion
    ) {
      setReadyToRegister(true);
    } else {
      setReadyToRegister(false);
    }
  }, [formData]);

  const handleForm = (e) => {
    if (e.target.name === "id_escuela") {
      setFormData({
        ...formData,
        [e.target.name.toLowerCase()]: e.target.value,
        grupo: "",
      });
    } else {
      setFormData({
        ...formData,
        [e.target.name.toLowerCase()]: e.target.value,
      });
    }
  };

  const registerTeacher = () => {
    setLoading(true);
    const body = {
      nombre: formData?.nombre,
      apellido1: formData?.primer_apellido,
      apellido2: formData?.segundo_apellido,
      correo: formData?.correo,
      contraseña: formData?.password,
      codigo: data?.codigo,
      id_tipo_uso: data?.id_tipo_uso,
      asignacion: {
        id_escuela: formData?.id_escuela,
        id_grado: data?.id_grado,
        id_grupo: formData?.grupo,
      },
    };
    axios
      .post("/user/codes/register", body)
      .then((response) => {
        if (response.status === 200) {
          const data = response.data.resultado.usuario;
          setResponseRegister(data);
          setRegisterCompletedModalOpen(true);
        }
      })
      .catch((error) => {
        if (error.response) {
          // Request made and server responded
          const errorData = error.response.data;
          const mensaje =
            errorData?.detalles?.msg ||
            "Ha ocurrido un error al consumir los servicios, inténtelo de nuevo más tarde.";
          setModalMessage(mensaje);
          setModalMessageOpen(true);
        } else {
          // Something happened in setting up the request that triggered an Error
          const mensaje =
            "Ha ocurrido un error al consumir los servicios, inténtelo de nuevo más tarde.";
          setModalMessage(mensaje);
          setModalMessageOpen(true);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const schoolAddress = listOfSchools.find(
    (item) => item.id_escuela === formData.id_escuela
  )?.direccion;

  return (
    <>
      <form
        className={classes.formContainer}
        autoComplete="off"
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <Grid
          container
          direction="column"
          className={classes.formItemContainer}
        >
          <SelectSchool
            list={listOfSchools}
            label="Nombre de la escuela"
            fieldName="id_escuela"
            handleForm={handleForm}
            defaultValue={formData?.id_escuela}
            value={formData?.id_escuela}
            formData={formData}
            disabled={listOfSchools?.length === 0}
          />
          <p className={classes.schoolAddress}>{schoolAddress}</p>
        </Grid>
        <br />
        <Grid
          container
          direction="column"
          className={classes.formItemContainer}
        >
          <label className={classes.label} htmlFor="input-grade">
            Grado
          </label>
          <input
            type="text"
            className={classes.input}
            id="input-grade"
            name="grado"
            value={formData?.grado}
            disabled
          />
        </Grid>
        <Grid
          container
          direction="column"
          className={classes.formItemContainer}
        >
          <SelectGradeGroup
            list={
              optionGrades.find(
                (item) => item.id_grado === Number(formData?.grado)
              )?.grupos
            }
            title="grupo"
            handleForm={handleForm}
            defaultValue={formData?.grupo}
            value={formData?.grupo}
            formData={formData}
            disabled={!formData?.grado || !formData?.id_escuela}
          />
        </Grid>
        <Grid
          container
          direction="column"
          className={classes.formItemContainer}
        >
          <label className={classes.label}>Datos del docente</label>
        </Grid>
        <br />
        <Grid
          container
          direction="column"
          className={classes.formItemContainer}
        >
          <input
            type="text"
            placeholder="Nombre(s)"
            className={classes.input}
            id="input-name"
            name="nombre"
            value={formData.nombre}
            onChange={handleForm}
            required
          />
        </Grid>
        <br />
        <Grid
          container
          direction="column"
          className={classes.formItemContainer}
        >
          <input
            type="text"
            placeholder="Primer apellido"
            className={classes.input}
            id="input-lastName"
            name="primer_apellido"
            value={formData.primer_apellido}
            onChange={handleForm}
            required
          />
        </Grid>

        <Grid
          container
          direction="column"
          className={classes.formItemContainer}
        >
          <input
            type="text"
            placeholder="Segundo apellido"
            name="segundo_apellido"
            value={formData.segundo_apellido}
            onChange={handleForm}
            className={classes.input}
            id="input-secondLastName"
            required
          />
        </Grid>

        <Grid
          container
          direction="column"
          className={classes.formItemContainer}
        >
          <input
            type="email"
            className={classes.input}
            id="input-email"
            name="correo"
            placeholder="Correo"
            value={formData.correo}
            onChange={handleForm}
            required
          />
        </Grid>
        <Grid
          container
          direction="column"
          className={classes.formItemContainer}
        >
          <input
            type="email"
            className={classes.input}
            id="input-email-confirmation"
            name="correo_confirmacion"
            placeholder="Confirmar correo"
            value={formData.correo_confirmacion}
            onChange={handleForm}
            required
          />
        </Grid>
      </form>
      <Button
        className={classes.PurpleButton}
        onClick={registerTeacher}
        disabled={!readyToRegister}
      >
        Acceder
      </Button>
      {loading && <Loader />}
      <ModalRegistroExitoso
        open={registerCompletedModalOpen}
        handleClose={handleCloseRegisterCompleted}
        data={responseRegister}
      />
      <ModalMessage
        open={modalMessageOpen}
        handleClose={handleCloseMessageModal}
        title={modalMessage}
      />
    </>
  );
};

export default DocenteGenerico;
