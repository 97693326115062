import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
    ContenedorLectura:{
        width:'502px',
                  [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
    },
    ContainerTitulo:{
        margin:'0 0 36px 0',
        '& .Titulo':{
            letterSpacing: '0px',
            color: '#FBA819',
            margin:'0',
            textAlign:'center',
            font:'43px fredoka one',
            lineHeight:'52px',
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        position:'relative',
        '& .Lectura':{
            margin:'0',
            color:'#000',
            font:'18px lato',
            lineHeight :'28px',
            letterSpacing: '0.17px',
            [theme.breakpoints.down(502)]:{
                font:'14px lato',
                lineHeight :'18px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'16px',
                letterSpacing: '0.09px',
            },
        },
        '& .Esp':{
            margin:'0 0 36px 0',
        },
        '& .Pos':{
            margin:'0',
            position:'absolute',
        },
        '& .Subtitulo':{
            color:'#fff',
            textAlign:'center',
            font:'23px Fredoka One',
            lineHeight :'30px',
            letterSpacing: '0.22px',
            top:'11px',
            left:'109px',
            [theme.breakpoints.down(502)]:{
                font:'18px Fredoka One',
                textAlign:'center',
                lineHeight :'20px',
                letterSpacing: '0.16px',
            },
        },
        '& .Texto':{
            top:'102px',
            left:'37px',
            width:'437px',
            [theme.breakpoints.down(502)]:{
                top:'65px',
                left:'25px',
                width:'300px',
            },
        },
        '& .Text2':{
            left:'114px',
            width:'351px',
            [theme.breakpoints.down(502)]:{
                left:'85px',
                width:'250px',
            },
        },
        '& .Texto2':{
            top:'206px',
            [theme.breakpoints.down(502)]:{
                top:'140px',
            },
        },
        '& .Texto3':{
            top:'282px',
            padding:'0 7px 0 0',
            boxSizing:'border-box',
            [theme.breakpoints.down(502)]:{
                top:'200px',
                padding:'0 11px 0 0',

            },
        },
        '& .Texto4':{
            top:'610.87px',
            [theme.breakpoints.down(502)]:{
                top:'430px',
            },
        },
        '& .Texto5':{
            top:'954px',
            padding:'0 35px 0 0',
            boxSizing:'border-box',
            [theme.breakpoints.down(502)]:{
                top:'670px',
                padding:'0',
            },
        },
        '& .Texto6':{
            top:'1276px',
            [theme.breakpoints.down(502)]:{
                top:'886px',
                padding:'0 15px 0 0',
                boxSizing:'border-box',
            },
        },
        '& .Texto7':{
            top:'1594px',
            [theme.breakpoints.down(502)]:{
                top:'1111px',
                padding:'0 15px 0 0',
                boxSizing:'border-box',
            },
        },
        '& .Texto8':{
            top:'1940px',
            [theme.breakpoints.down(502)]:{
                top:'1350px',
            },
        },
        '& .Imagen':{
            [theme.breakpoints.down(502)]:{
                width:'100%'
            },
        },
        '& .Imagen2':{
            width:'100%'
        },
        '& .Ocultar':{
            [theme.breakpoints.down(350)]:{
                display:'none'
            },
        },
        '& .Imagen3':{
            width:'100%',
            display:'none',
            [theme.breakpoints.down(350)]:{
                display:'flex'
            },
        },
    },

    
    

}));

const El_Tangram = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >
                        El Tangram
                    </h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                            La creatividad es una gran herramienta para las matemáticas. Y algunos juegos te 
                            dan la oportunidad para ponerla en práctica, porque puedes elaborarlos en casa y 
                            usar tu imaginación para elegir los materiales. Te sorprenderá la cantidad de cosas 
                            que puedes crear con piezas de reúso o reciclaje. De esta manera podrías poner en 
                            práctica no sólo tus conocimientos de matemáticas, sino los de ciencias naturales y 
                            los de ética. Por ejemplo, podrías hacer un <em>Tangram</em> con materiales de reúso.<br/><br/> 
                            
                            Un Tangram es un rompecabezas conformado por diferentes figuras geométricas que 
                            caben en un cuadrado y con el cual podemos armar diversas formas usando nuestra 
                            imaginación.
                        </p>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container item className='Ocultar'>
                    <h3 className='Pos Subtitulo'>Instrucciones para hacer<br/> un Tangram</h3>
                   
                    <p className='Pos Lectura Texto'>
                        Te invitamos a hacer uno observando las ilustraciones y también queremos 
                        acompañarte en el proceso. Sólo sigue estos sencillos pasos y disfruta:
                    </p>
                    <p className='Pos Lectura Text2 Texto2'>
                        Pide ayuda a una persona adulta, sobre todo porque tendrás 
                        que cortar los materiales.

                    </p>
                    <p className='Pos Lectura Text2 Texto3'>
                        Haz un cuadrado de papel o cartulina (recuerda que un 
                        cuadrado tiene sus cuatro lados iguales). Dobla la figura por 
                        una de sus diagonales (observa la línea punteada) y córtala: 
                    </p>
                    <p className='Pos Lectura Text2 Texto4'>
                        Dobla por la mitad uno de los triángulos que obtuviste y 
                        córtalo como se muestra en la ilustración:
                    </p>
                    <p className='Pos Lectura Text2 Texto5'>
                        Dobla el triángulo más grande, de tal manera que los puntos 
                        rojos coincidan y córtalo:
                    </p>
                    <p className='Pos Lectura Text2 Texto6'>
                        Esta figura alargada de cuatro lados es un <em>trapecio</em>. Dóblalo 
                        a la mitad y córtalo como se muestra enseguida:
                    </p>
                    <p className='Pos Lectura Text2 Texto7'>
                        Dobla una de las figuras resultantes del corte anterior, tal 
                        como lo indica la línea punteada. Obtendrás un cuadrado y 
                        un triángulo pequeños. Recórtalos:
                    </p>
                    <p className='Pos Lectura Text2 Texto8'>
                        Finalmente, haz coincidir los puntos rojos para que la figura 
                        se doble por la línea punteada y recórtala:
                    </p>
                  
                    <img className='Imagen' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img2do/Sesion26/1R.png" alt="" />
                    </Grid>
                    <img className='Imagen3' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img2do/Sesion26/F.png" alt="" />

                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura Esp">
                            ¡Listo! Ya tienes tu Tangram. Con él puedes formar muchísimas figuras y estimular tu 
                            imaginación y tu creatividad. La única regla es que siempre debes usar todas las 
                            piezas.<br/><br/> 
                            
                            Aquí te mostramos algunas posibilidades para que realices figuras. ¡Intenta 
                            formarlas en casa!
                        </p>
                    </Grid>
                    <Grid contaier justifyContent='center'> 
                        <img className='Imagen2' src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img2do/Sesion26/2.png" alt="" />
                    </Grid>
                </Grid>

        </Grid>
    </Grid>
    );
}
 
export default El_Tangram;
