import React               from "react";
import {Grid, makeStyles}                 from '@material-ui/core';

const useStyles = makeStyles(theme =>({
  questionContainer:{
    width: "100%",
    height:"100%",
  },
  reading: {
    width: "98%",
    font: "22px lato",
    letterSpacing:'0',
    margin:"14px 0 14px 0",
    [theme.breakpoints.down(1370)]:{
      font: "500 16px lato",
      width: "90%",
      margin:"10px 0 10px 0"
    },
    [theme.breakpoints.down(1310)]:{
      font: "500 16px lato",
      // width: "80%",
      margin:"10px 0 10px 0"
    },
    [theme.breakpoints.down(925)]:{
      font: "500 14px lato",
      // width: "70%",
      margin:"6px 0 6px 0"
    },
    [theme.breakpoints.down(426)]:{
      font: "500 12px lato",
      // width: "70%",
      margin:"6px 0 6px 5%"
    }, 
  },
  table: {
    // border: '2px solid black',
    width: "100%",
    font: '22px lato',
    [theme.breakpoints.down(1400)]:{
      font: " 18px lato",
    },
    [theme.breakpoints.down(1370)]:{
      font: "16px lato",
      width: "90%",
      // margin:"10px 0 10px 0"
    },
    [theme.breakpoints.down(1310)]:{
      font: "16px lato",
      // margin:"10px 0 10px 0"
    },
    [theme.breakpoints.down(925)]:{
      font: "14px lato",
      marginLeft: '10px',
      // width: "70%",
      // margin:"6px 0 6px 0"
    },
    [theme.breakpoints.down(426)]:{
      font: "12px lato",
      // width: "70%",
      // margin:"6px 0 6px 5%"
    }, 
  },
  tdTable: {
    padding: "0 1%",
  }
}));

const QuestionTable = (props) => {
  const classes = useStyles()

  return(
    <Grid container className={classes.questionContainer}>
      <p
        className={classes.reading}
      >
       6. Relaciona las actividades con la manera en que usan las matemáticas.
      </p>
      <br/><br/>
      <table className={classes.table}>
        <thead>
          <th style={{width: "50%",}}><b>Actividades</b></th>
          <th style={{width: "50%",}}><b>Uso de las matemáticas</b></th>
        </thead>
        <tbody>
          <tr>
            <td className={classes.tdTable}>
              <p className={classes.reading}>
                1. Publicidad	
              </p>
            </td>
            <td className={classes.tdTable}>
              <p className={classes.reading}>
                a. Uso de la geometría para definir la forma espacial de una edificación.
              </p>
            </td>
          </tr>
          <tr>
            <td className={classes.tdTable}>
              <p className={classes.reading}>
                2. Gastronomía 
              </p>
            </td>
            <td className={classes.tdTable}>
              <p className={classes.reading}>
                b. Uso de la estadística para optimizar las técnicas de los atletas.
              </p>
            </td>
          </tr>
          <tr>
            <td className={classes.tdTable}>
              <p className={classes.reading}>
                3. Deporte
              </p>
            </td>
            <td className={classes.tdTable}>
              <p className={classes.reading}>
                c. Uso de la estadística para descubrir qué público es más probable que compre un producto.
              </p>
            </td>
          </tr>
          <tr>
            <td className={classes.tdTable}>
              <p className={classes.reading}>
                4. Arquitectura
              </p>
            </td>
            <td className={classes.tdTable}>
              <p className={classes.reading}>
                d. Uso del cálculo y otras fórmulas para implementar procesos de elaboración de alimentos.
              </p>
            </td>
          </tr>
        </tbody>
      </table>
    </Grid>
  )

}

export default QuestionTable;
