
const RedirecToErrorActivity = (data, history) => {
  

    if(
      data?.status.toLowerCase() === "contador" 
      || data?.status.toLowerCase() === "bloqueada"
      || data?.status.toLowerCase() === "remedial"
    ){
      history.push({
        pathname: '/error/sesion-bloqueada',
        state: {
          reason:'sesion-bloqueada'
        },
      }); 
    }
  // history.push({
  //   pathname: "/error/sesion-bloqueada",
  //   state: { reason: "sesion-bloqueada" },
  // });
};
  
export default RedirecToErrorActivity;