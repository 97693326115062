import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
     Contenedor:{
        background:'#E9FBF9',
        width:'100vw',
        overflowX:'clip',
        [theme.breakpoints.down(1161)]:{
            background:'none',
           overflowX:'unset',
        },
        [theme.breakpoints.down(600)]:{
            marginTop: '79.51px',
        }
    },
  ContainerInfoLectura: {
    position: "relative",
    height: "86px",
    [theme.breakpoints.down(502)]:{
        height: "43px",
    },
    background: "#76D4C3",
    "& .BarraInfoLectura": {
      height: "86px",
      background: `Url(https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Barras/Azul.png)`,
        [theme.breakpoints.down(502)]:{
            height: "43px",
        },
    },
    "& .InfoLectura": {
      position: "absolute",
      height: "86px",
      width: "219px",
      boxSizing: "border-box",
      padding: "17.2px 0",
      background: "#FAFAFA",
      borderRadius: "50px",
      boxShadow: "2px 2px 2px 2px #00000029",
      zIndex: "4",
        [theme.breakpoints.down(502)]:{
            height: "43px",
            width: "109px",
            padding: "8.6px 0",
            borderRadius: "25px",
        },
    },
    "& .InfoLectura:before": {
      position: "absolute",
      display: "flex",
      boxSizing: "border-box",
      padding: "0 0 0 10px",
      justifyContent: "center",
      alignItems: "center",
      height: "39.88px",
      width: "39.88px",
      borderRadius: "50%",
      content: '""',
      boxShadow: "3px 0px .5px 0px #00000029",
      background: "#FAFAFA",
      left: "90px",
      top: "65px",
      transform: "rotate(0.25turn)",
      [theme.breakpoints.down(502)]:{
        left: "45px",
        top: "30px",
        height: "20.88px",
        width: "20.88px",
        },
    },
    "& .InfoLectura:after": {
      content: '""',
      height: "15px",
      width: "15px",
      borderTop: "2px solid #20A698",
      borderLeft: "2px solid #20A698",
      position: "absolute",
      bottom: "-10px",
      left: "Calc(50% - 8px)",
      transform: "rotate(0.625turn)",
      [theme.breakpoints.down(502)]:{
        height: "10px",
        width: "10px",
        left: "Calc(50% - 4.5px)",
        bottom: "-3px",
        borderTop: "1px solid #20A698",
        borderLeft: "1px solid #20A698",
        },
    },
    "& .Sesion": {
      borderLeft: "2px solid #D8D8D8",
    },
    "& .Dato": {
      margin: "0 0 5px 0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#20A698",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        margin: "0 0 2.5px 0",
        },
    },
    "& .Caracter": {
      margin: "0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#000000",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        },
    },   },
    ContenedorLectura:{
        background:'#fff',
        width:'1160px',
        height:'100%',
        padding:'60px 91px 35px 105px',
        [theme.breakpoints.down(1161)]:{
            width:'628px',
            padding:'60px 0 0 0',
          },
        [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
        '& .ContainerDerechos':{
            bottom:'80px',
            [theme.breakpoints.down(1161)]:{
                display:'none',
            },
        },
        '& .Derechos':{
            textAlign:"center",
            margin:"0px",
            font:" 15px lato",
            color:"#717476",
        },
        '& .Escritorio':{
            display:'block',
            [theme.breakpoints.down(1161)]:{
                display:'none'
            },
        },
        '& .Tablet':{
            display:'none',
            [theme.breakpoints.down(1161)]:{
                display:'block'
            },
        },
    },
    ContainerTitulo:{
        margin:'0 0 46px 0',
        [theme.breakpoints.down(1161)]:{
            margin:'0 0 18px 0',
        },
        '& .Titulo':{
            font:'48px fredoka one',
            letterSpacing: '0px',
            lineHeight:'58px',
            color: '#FF0000',
            margin:'0',
            textAlign:'center',
            [theme.breakpoints.down(1161)]:{
                font:'43px fredoka one',
                lineHeight:'52px',
            },
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        boxSizing:'border-box',
        padding:'0 62px 0 62px',
        [theme.breakpoints.down(502)]:{
            padding:'0 32px 0 32px',
        },
        '& .Lectura':{
            lineHeight : '30px',
            margin:'0 0 36px 0',
            font:'22px lato',
            color:'#000',
            letterSpacing: '0.20px',
            [theme.breakpoints.down(1161)]:{
                font:'18px lato',
                lineHeight :'28px',
                letterSpacing: '0.17px',
            },
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .SubTitulo':{
            font:'bold 35px lato',
            color:'#000000',
            letterSpacing:'.28px',
            lineHeight:'40px',
            margin:'0',
            textAlign:'center',
            width:'100%',
            padding:'38px 0 22px 0',
            borderBottom:'solid 7px #FF0000',
            [theme.breakpoints.down(1161)]:{
                font:'bold 24px lato',
                lineHeight :'28px',
                letterSpacing: '0.24px',
            },
            [theme.breakpoints.down(502)]:{
                borderBottom:'solid 5px #FF0000',
                padding:'19px 0 12px 0',
                font:'bold 13px lato',
                lineHeight :'20px',
                letterSpacing: '0.17px',
            },
        },
        '& .TextImg':{
            font:'20px lato',
            color:'#717476',
            letterSpacing:'.19px',
            lineHeight:'30px',
            margin: "24px 0 36px 0",
            [theme.breakpoints.down(1161)]:{
                font:' 16px lato',
                lineHeight :'25px',
                letterSpacing: '0.15px',
            },
            [theme.breakpoints.down(502)]:{
                font:' 11px lato',
                lineHeight :'14px',
                letterSpacing: '0.10px',
            },
        },
        '& .Imagen':{
            [theme.breakpoints.down(1161)]:{
                width:'100%',
            },
        },  
      
    },
    Fondo:{
        background:`Url(https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img1ro/Sesion40/1.png)`,
        margin:'0 0 36px 0',
        width:'100%'
    },
    Esp:{
        [theme.breakpoints.down(1161)]:{
            padding:'0 48px 0 62px',
        },
        [theme.breakpoints.down(502)]:{
            padding:'0 24px',
        },
    },
}));

const Volcanes_por_aquí_y_por_allá = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
             <Grid container item justifyContent="center" className={classes.ContainerInfoLectura}>
               <Grid container className="BarraInfoLectura"></Grid>
               <Grid container item className="InfoLectura">
                    <Grid container item direction="column" xs={6} justifyContent="center">
                        <p className="Dato">Grado</p>
                        <p className="Caracter">1</p>
                    </Grid>
                    <Grid container item direction="column" xs={6} justifyContent="center" className="Sesion">
                        <p className="Dato">Sesión</p>
                        <p className="Caracter">50</p>
                    </Grid>
               </Grid>
            </Grid>

            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo Escritorio" >
                        Volcanes por aquí y por allá
                    </h2>
                    <h2 className="Titulo Tablet" >
                        Volcanes por aquí<br/> y por allá
                    </h2>
                </Grid>
                <Grid className={classes.Fondo}>
                    <Grid container item sm={12} className={`${classes.Parrafo1}  ${classes.Esp}`} justifyContent="center">
                        <Grid container sm={12} justifyContent='center' className='SubTitulo'>
                            <em> En América hay más de 3 000 volcanes.<br/> Los científicos los consideran reguladores del clima.</em>
                        </Grid>
                    </Grid>
                    <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                        <Grid container sm={12} justifyContent='center' >
                            <p className='Lectura'>
                                Los geógrafos definen un volcán como un cerro o una montaña con una abertura por 
                                la que salen rocas y gases generalmente dañinos, mismos que son lanzados a grandes 
                                alturas. Hay algunos altos y otros no tanto, unos activos y otros que ya no lo están.<br/><br/> 

                                Cuando un volcán entra en erupción, causa temblores que pueden afectar los sitios a 
                                su alrededor. También puede ocasionar incendios, ya que la lava expulsada está tan 
                                caliente que, cuando corre por los lados de las montañas, quema todo lo que 
                                encuentra a su paso. 
                            </p>
                        </Grid>
                        <Grid container justifyContent="center" sm={12}>
                            <Grid container justifyContent="center" >
                                <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img1ro/Sesion50/1.png" alt="Tarjeta" />
                            </Grid>
                            <Grid container justifyContent="center" >
                                <p className="TextImg">
                                    Los volcanes son un elemento importante para el control del clima.
                                </p>
                            </Grid>
                        </Grid>
                        <Grid container sm={12} justifyContent='center' >
                            <p className='Lectura'>
                                Pero no todo acerca de los volcanes es negativo, pues éstos también pueden ser 
                                considerados como reguladores del clima. Los científicos piensan que los minerales 
                                expulsados pueden capturar el principal gas responsable del calentamiento global, el 
                                dióxido de carbono, y refrescar el clima de la Tierra.<br/><br/>

                                Los expertos aconsejan a las personas que identifiquen los volcanes más próximos a 
                                su localidad y que se mantengan pendientes de su actividad. También invitan a los 
                                niños a explorar su historia y así conocer los efectos que han tenido en su área.
                            </p>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item xs={12} justifyContent="center" className="ContainerDerechos">
                    <p className="Derechos">
                        © 2022. Todos los derechos reservados, Pearson, Circuito de lectores.
                    </p>                
                </Grid>
        </Grid>
    </Grid>
    );
}
 
export default Volcanes_por_aquí_y_por_allá ;
