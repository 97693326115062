import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
    ContenedorLectura:{
        width:'502px',
                  [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
    },
    ContainerTitulo:{
        margin:'0 0 61px 0',
        '& .Titulo':{
            letterSpacing: '0px',
            color: '#305F82',
            margin:'0',
            textAlign:'center',
            font:'43px fredoka one',
            lineHeight:'52px',
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            margin:'0 0 36px 0 ',
            color:'#000',
            font:'18px lato',
            lineHeight :'28px',
            letterSpacing: '0.17px',
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .SubTitulo':{
            color:'#0CB5D6',
            margin:'0',
            lineHeight :'28px',
            font:'bold 26px lato',
            letterSpacing: '0.24px',
            [theme.breakpoints.down(502)]:{
                font:'bold 18px lato',
                lineHeight :'24px',
                letterSpacing: '0.21px',
            },
        },
        '& .TextImg':{
            margin:'22px 0 0 0',
            alignSelf:'flex-start',
            color:'#717476',
            font:' 16px lato',
            lineHeight :'25px',
            letterSpacing: '0.15px',
            [theme.breakpoints.down(502)]:{
                font:' 11px lato',
                lineHeight :'14px',
                letterSpacing: '0.10px',
            },
        },
        '& .Imagen':{
            width:'100%',
        },
    },

}));

const La_evolución_de_las_especies_a_través_de_los_fósiles = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
            
            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >
                    La evolución <br/> de las especies <br/>
                    a través de los fósiles 
                    </h2>
                    
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                    <p className="Lectura">
                        <h3 className='SubTitulo'>Un pasado fascinante</h3><br/>

                         Hace millones de años, cuando los seres humanos todavía no habitaban la Tierra, 
                         existieron especies fascinantes de seres vivos: animales y plantas difíciles de 
                         imaginar debido a su tamaño y su majestuosidad.<br/><br/> 
                         
                         Por ejemplo, se sabe que hubo insectos gigantes, caracoles enormes y reptiles de 
                         imponentes dimensiones, mucho más grandes que los pequeños lagartos a los que 
                         estamos habituados. <br/><br/>
                         
                         Un descubrimiento increíble fue el de las ballenas primitivas, los parientes lejanos de 
                         las ballenas que conocemos en la actualidad. Eran unos mamíferos con tamaño 
                         similar al de un perro mediano que vivían en tierra firme, y caminaban en cuatro 
                         patas. Como todas las especies, evolucionaron para adaptarse a sus circunstancias
                        hasta convertirse en esos seres que hoy habitan los mares.<br/>
                      </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <Grid container justifyContent="center" alignItems='flex-end'>
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img2do/Sesion17/1.png" alt="Tarjeta" />
                        </Grid>
                        <Grid container  >
                            <p className="TextImg"> 
                             El pasado de las ballenas nos ayuda a comprender por qué no se les considera peces, sino
                             mamíferos acuáticos.
                            </p>
                            
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                        <p className="Lectura">
                            <h3 className='SubTitulo'>Paleontólogos y fósiles </h3><br/>

                            Te preguntarás cómo es posible que conozcamos esta información si estamos 
                            hablando de hace 50 millones de años. La respuesta está en la <em>paleontología</em>, la ciencia 
                            que se encarga de estudiar a los seres que habitaron la Tierra en épocas pasadas. Las 
                            personas que se dedican a esto se llaman <em>paleontólogos</em>.<br/><br/> 
                            
                            Los restos de aquellos seres se conocen como <em>fósiles</em>, y suelen encontrarse en 
                            distintas formas de conservación, tales como: enterrados bajo grandes cantidades de 
                            tierra, sumergidos en los mares, congelados en los glaciares o petrificados entre 
                            resina. La palabra <em>fósil</em> significa “excavado”. La utilizarás mucho en el área de Ciencias 
                            Naturales.<br/><br/> 
                            
                            Los fósiles son los restos o huellas de los seres que vivieron en el pasado. Hay
                            materiales (como conchas, huesos y dientes) que se descomponen con mayor 
                            dificultad, y gracias a ellos los paleontólogos han logrado reconstruir osamentas de 
                            las antiguas especies. Actualmente, con ayuda de la tecnología, es más fácil 
                            reconstruir los fósiles encontrados. Así fue como descifraron la forma de aquella 
                            ballena cuadrúpeda.<br/><br/> 
                            
                            Imagina cómo serán las especies del futuro: ¿Cómo crees que se verán las ballenas 
                            dentro de 50 millones de años?
                        </p>
                        <Grid container >
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img2do/Sesion17/2.png" alt="Tarjeta" />
                        </Grid>
                        <Grid container  >
                            <p className="TextImg"> 
                            Tal vez podrías preguntar en tu comunidad o en tu familia para ver qué vestigios del pasado se
                            han encontrado cerca.
                            </p>
                        </Grid>
                    </Grid>
                </Grid>
        </Grid>
    </Grid>
    );
}
 
export default La_evolución_de_las_especies_a_través_de_los_fósiles;
