import React               from "react";
import BasicAnswer          from "./BasicAnswer";


const Answers = (props) => {

  const{
   respuestas,
  } = props

  return(
    respuestas.map(
        (respuesta, index) => {
          return(
            <BasicAnswer
              respuesta={respuesta}
              key={respuesta?.id_respuesta}
              {...props}
            />
          )
        }
        )
  )
}

export default Answers;