import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
    ContenedorLectura:{
        width:'502px',
                  [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
    },
    ContainerTitulo:{
        margin:'0 0 34px 0',
        '& .Titulo':{
            letterSpacing: '0px',
            color: '#A0278F',
            margin:'0',
            textAlign:'center',
            font:'43px fredoka one',
            lineHeight:'52px',
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            margin:'0 0 36px 0',
            color:'#000',
            font:'18px lato',
            lineHeight :'28px',
            letterSpacing: '0.17px',
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .TextImg':{
            color:'#717476',
            margin:'24px 0 0 0',
            font:' 16px lato',
            lineHeight :'25px',
            letterSpacing: '0.15px',
            [theme.breakpoints.down(502)]:{
                font:' 11px lato',
                lineHeight :'14px',
                letterSpacing: '0.10px',
            },
        },

        '& .Imagen':{
            width:'100%',
            height:'392px',
            [theme.breakpoints.down(502)]:{
                width:'100%',
                height:'250px',
            },
        },  
    }
}));

const Tal_vez_cumplimos_años_el_mismo_día = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo">Tal vez cumplimos<br/> años el mismo día</h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12} justifyContent='center'>
                       <p className='Lectura'>
                        ¡Feliz, feliz no cumpleaños! ¿Te suena familiar? Sí, es la canción de la película <em>Alicia 
                        en el país de las maravillas</em>. Según ella, es más probable que las personas compartan un 
                        “no cumpleaños” que un cumpleaños de verdad. Y eso es cierto, pero las matemáticas 
                        tienen algo que decir al respecto.<br/><br/> 
                        
                        La probabilidad es el grado de certeza de que ocurra algo. Por ejemplo, en los 
                        reportes del clima suelen decir que “hay una alta probabilidad de que llueva o que 
                        baje la temperatura”. Para determinar la probabilidad se hacen diversos cálculos. <br/><br/> 
                        
                        Regresando a los cumpleaños, ¿a cuántas personas crees que necesitarías preguntar 
                        para encontrar a dos que cumplan años el mismo día? Sorprendentemente, a menos 
                        de las que imaginas. Por ejemplo, en un grupo de 23 individuos es probable que al
                        menos dos de ellos cumplan años el mismo día. Aunque la probabilidad es poca, en un 
                        grupo de 50 personas tal probabilidad aumenta al 97 %. Mientras que en uno de 70, 
                        ¡sube al 99.9 %!

                       </p>                   
                    </Grid>
                    <Grid container justifyContent='center'>
                        <Grid container justifyContent="center" >
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img1ro/Sesion55/1.png" alt="Tarjeta" />
                        </Grid>
                        <Grid container justifyContent="center" >
                            <p className="TextImg">
                                Las matemáticas son exactas y calculan la probabilidad de encontrar dos personas con la
                                misma fecha de cumpleaños. Interesante, ¿no crees?
                            </p>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
    </Grid>
    );
}
 
export default Tal_vez_cumplimos_años_el_mismo_día;
