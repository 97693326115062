import React               from "react";
import {Grid, makeStyles}                 from '@material-ui/core';

const useStyles = makeStyles(theme =>({
  questionContainer:{
    width: "100%",
    height:"100%",
  },
  reading: {
    width: "80%",
    margin:"10px 0 10px 0 ",
    font: '22px lato',
    letterSpacing: '0.21px',
    [theme.breakpoints.down(1400)]:{
      font: " 18px lato",
      width: "95%",
    },
    [theme.breakpoints.down(1310)]:{
      font: "500 16px lato",
      // width: "80%",
      margin:"10px 0 10px 0"
    },
    [theme.breakpoints.down(925)]:{
      font: "500 14px lato",
      // width: "70%",
      margin:"6px 0 6px 0"
    },
    [theme.breakpoints.down(426)]:{
      font: "500 12px lato",
      // width: "70%",
      margin:"6px 0 6px 5%"
    }, 
  },
  table: {
    width: "100%",
    font: '22px lato',
    [theme.breakpoints.down(1400)]:{
      font: " 18px lato",
      width: "95%",
    },
    [theme.breakpoints.down(1310)]:{
      font: "500 16px lato",
      // width: "80%",
      margin:"10px 0 10px 0"
    },
    [theme.breakpoints.down(925)]:{
      font: "500 14px lato",
      // width: "70%",
      margin:"6px 0 6px 0"
    },
    [theme.breakpoints.down(426)]:{
      font: "500 12px lato",
      // width: "70%",
      margin:"6px 0 6px 5%"
    }, 
  },
  tdTable: {
    padding: "0 1%",
    // border: '1px solid black',
  }
}));

const QuestionTable = (props) => {
  const classes = useStyles()


  return(
    <Grid container className={classes.questionContainer}>
      <p
        className={classes.reading}
      >
        9. Relaciona los conceptos con sus descripciones.
      </p>
      <br/>
      <table className={classes.table}>
        <tbody>
          <tr>
            <td className={classes.tdTable}>
              <p>
                1. Habilidad artística
              </p>
            </td>
            <td className={classes.tdTable}>
              <p>
                a. Control adecuado de la iluminación, el tiempo de exposición y herramientas de revelado o edición digital.
              </p>
            </td>
          </tr>
          <tr>
            <td className={classes.tdTable}>
              <p>
                2. Habilidad técnica 
              </p>
            </td>
            <td className={classes.tdTable}>
              <p>
                b. Capacidad de conectar con los intereses de las personas para transmitir todo tipo de ideas.
              </p>
            </td>
          </tr>
          <tr>
            <td className={classes.tdTable}>
              <p>
                3. Habilidad comunicativa
              </p>
            </td>
            <td className={classes.tdTable}>
              <p>
                c. Apreciación de los elementos de la escena (luz, composición, texturas, etcétera) para transmitir emociones, historias 
                o situaciones.
              </p>
            </td>
          </tr>
          <tr>
            <td className={classes.tdTable}>
              <p>
                4. Equipo
              </p>
            </td>
            <td className={classes.tdTable}>
              <p>
                d. Cámaras, lentes, <i>flashes</i>, rebotadores, etcétera
              </p>
            </td>
          </tr>
        </tbody>
      </table>
    </Grid>
  )
}

export default QuestionTable;
