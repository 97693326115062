import React               from "react";
import {Grid, makeStyles}                 from '@material-ui/core';

const useStyles = makeStyles(theme =>({
  questionContainer:{
    width: "100%",
    height:"100%",
  },
  reading: {
    width: "80%",
    margin:"10px 0 10px 0 ",
    font: '22px lato',
    letterSpacing: '0.21px',
    [theme.breakpoints.down(1400)]:{
      font: " 18px lato",
      width: "85%",
    },
    [theme.breakpoints.down(1310)]:{
      font: "500 16px lato",
      // width: "80%",
      margin:"10px 0 10px 0"
    },
    [theme.breakpoints.down(925)]:{
      font: "500 14px lato",
      // width: "70%",
      margin:"6px 0 6px 0"
    },
    [theme.breakpoints.down(426)]:{
      font: "500 12px lato",
      // width: "70%",
      margin:"6px 0 6px 5%"
    }, 
  },
  imgStyle: {
    alignItems: "center",
    width:'90%',
    height:'90%',
    [theme.breakpoints.down(1400)]:{
      width:'55%px',
      height:'55%px',
    },
  },
  table: {
    // border: '1px solid black',
    width: "100%",
    font: '22px lato',
    [theme.breakpoints.down(1400)]:{
      font: " 18px lato",
    },
  },
  tdTable: {
    padding: "0 1%",
    // border: '1px solid black',
  }
}));

const Question = (props) => {
  const classes = useStyles()
  const { picture } = props


  return(
    <Grid container justifyContent="center" alignItems="center" className={classes.questionContainer}>
      <p 
        // dangerouslySetInnerHTML={{__html: `${step+1}. ${pregunta.replace( '\n', '<br/><br/>')}`}}
        className={classes.reading}
      >
        9. Observa las dos imágenes y selecciona la respuesta que contenga las diferencias entre ambas.
      </p>
      <br/>

        <img alt="img" src={picture} className={classes.imgStyle}/>
      <p className={classes.reading}>
      a. El conductor<br/>
      b. La cantidad de pasajeros<br/>
      c. El color de los asientos<br/>
      d. La cantidad de ventanas<br/>
      e. La forma de la puerta<br/>
      </p>

    </Grid>
  )

}

export default Question;
