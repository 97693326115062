import { Button, Grid, makeStyles } from "@material-ui/core";
import React, { useContext, useEffect } from "react";
import DrawerContext from "../../../../../usability/DrawerContext";
import { useOnLoadImages } from "../../../../hooks/useOnLoadImages";
import Loader from "../../../../Loader/Loader";
import Drawer from "../../ReadingTools/Drawer";

const useStyles =  makeStyles (theme =>({
     Contenedor:{
        background:'#E9FBF9',
        width:'100vw',
        overflowX:'clip',
        [theme.breakpoints.down(1161)]:{
            background:'none',
           overflowX:'unset',
        },
        [theme.breakpoints.down(600)]:{
            marginTop: '79.51px',
        }
    },
      ContainerInfoLectura: {
    position: "relative",
    height: "86px",
    [theme.breakpoints.down(502)]:{
        height: "43px",
    },
    background: "#76D4C3",
    "& .BarraInfoLectura": {
      height: "86px",
      background: `Url(https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Barras/Azul.png)`,
        [theme.breakpoints.down(502)]:{
            height: "43px",
        },
    },
    "& .InfoLectura": {
      position: "absolute",
      height: "86px",
      width: "219px",
      boxSizing: "border-box",
      padding: "17.2px 0",
      background: "#FAFAFA",
      borderRadius: "50px",
      boxShadow: "2px 2px 2px 2px #00000029",
      zIndex: "4",
        [theme.breakpoints.down(502)]:{
            height: "43px",
            width: "109px",
            padding: "8.6px 0",
            borderRadius: "25px",
        },
    },
    "& .InfoLectura:before": {
      position: "absolute",
      display: "flex",
      boxSizing: "border-box",
      padding: "0 0 0 10px",
      justifyContent: "center",
      alignItems: "center",
      height: "39.88px",
      width: "39.88px",
      borderRadius: "50%",
      content: '""',
      boxShadow: "3px 0px .5px 0px #00000029",
      background: "#FAFAFA",
      left: "90px",
      top: "65px",
      transform: "rotate(0.25turn)",
      [theme.breakpoints.down(502)]:{
        left: "45px",
        top: "30px",
        height: "20.88px",
        width: "20.88px",
        },
    },
    "& .InfoLectura:after": {
      content: '""',
      height: "15px",
      width: "15px",
      borderTop: "2px solid #20A698",
      borderLeft: "2px solid #20A698",
      position: "absolute",
      bottom: "-10px",
      left: "Calc(50% - 8px)",
      transform: "rotate(0.625turn)",
      [theme.breakpoints.down(502)]:{
        height: "10px",
        width: "10px",
        left: "Calc(50% - 4.5px)",
        bottom: "-3px",
        borderTop: "1px solid #20A698",
        borderLeft: "1px solid #20A698",
        },
    },
    "& .Sesion": {
      borderLeft: "2px solid #D8D8D8",
    },
    "& .Dato": {
      margin: "0 0 5px 0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#20A698",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        margin: "0 0 2.5px 0",
        },
    },
    "& .Caracter": {
      margin: "0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#000000",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        },
    },
       '& .Regresar':{
        color: '#F2624F',
        border: '#F2624F 1px solid',
        height: '30px',
        width:'100px',
        borderRadius: '20px',
        letterSpacing: '0.14px',
        textTransform: 'none',
        background:'#FEFAF1',
        top:'calc(50% - 15px)',
        left:'5%',
        zIndex:4,
        [theme.breakpoints.down(502)]: {
          font:'bold 10px lato',
          width: "60px",
        },
      },
  },
    ContenedorLectura:{
        background:'#fff',
        width:'1160px',
        height:'100%',
        padding:'60px 168px 35px ',
        
        transform: (props) =>
        props.scale > 1 ? `scale(${props.scale}) translate(0px, 10%)` : `none`,
        [theme.breakpoints.down(1161)]:{
            width:'502px',
            padding:'60px 0 0 0',
          },
        [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },

        '& .Escritorio':{
            display:'block',
            [theme.breakpoints.down(1161)]:{
                display:'none'
            },
        },
        '& .Tablet':{
            display:'none',
            [theme.breakpoints.down(1161)]:{
                display:'block'
            },
        }
    },
    ContainerTitulo:{
        margin:'0 0 34px 0',
        [theme.breakpoints.down(1161)]:{
            margin:'0 0 34px 0',
        },
        '& .Titulo':{
            letterSpacing: '0px',
            color: '#F6644B',
            margin:'0',
            textAlign:'center',
            font:'48px fredoka one',
            lineHeight:'58px',
            [theme.breakpoints.down(1161)]:{
                font:'43px fredoka one',
                lineHeight:'52px',
            },
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            margin:'0 0 36px 0',
            color:'#000',
            font:'22px lato',
            lineHeight : '30px',
            letterSpacing: '0.12px',
            [theme.breakpoints.down(1161)]:{
                font:'18px lato',
                lineHeight :'28px',
                letterSpacing: '0.17px',
            },
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .Imagen':{
            [theme.breakpoints.down(1161)]:{
                width:'100%',
            },
        }, 

    }
}));

const El_método_científico = (props) => {

  const {activeStep, setActiveStep, handlePreviewStep, id_actividad} = props
  
  const handleContinue = () => {
    setActiveStep(activeStep - 1)
    handlePreviewStep()
  }

  const {
    handleSize,
    disabledZoom,
    closeZoom,
    handleZoom,
    scale
  }= useContext(DrawerContext);
  const [loading, setLoading] = React.useState(true);
  const wrapperRef = React.useRef(null);
  const imagesLoaded = useOnLoadImages(wrapperRef);

  const detectSize = () => {
    const lectura = document.getElementById("lectura");
    const newWidth = lectura?.offsetWidth;
    const newHeight = lectura?.offsetHeight;
    setLoading(false);
    if (lectura) handleSize(newHeight, newWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", detectSize);
    return () => {
      handleZoom()
      window.removeEventListener("resize", detectSize);
      closeZoom()
    };
  }, []);

  const classes = useStyles({ disabledZoom,scale });
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
             <Grid container item justifyContent="center" className={classes.ContainerInfoLectura}>
                       <Grid container className="BarraInfoLectura">
            <Button className="Regresar" onClick={handleContinue}>
                Regresar
            </Button>
        </Grid>
               <Grid container item className="InfoLectura">
                    <Grid container item direction="column" xs={6} justifyContent="center">
                        <p className="Dato">Grado</p>
                        <p className="Caracter">4</p>
                    </Grid>
                    <Grid container item direction="column" xs={6} justifyContent="center" className="Sesion">
                        <p className="Dato">Sesión</p>
                        <p className="Caracter">44</p>
                    </Grid>
               </Grid>
            </Grid>
            {!imagesLoaded && <Loader />}
        <Grid
            container
            item
            className={classes.ContenedorLectura}
            justifyContent="center"
            id="lectura"
            onLoad={detectSize}
            ref={wrapperRef}
        >
<Drawer id_lectura={id_actividad} />
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >
                        El método científico                   
                    </h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                      <p className="Lectura ">
                        Pedro estaba en su habitación observando la ventana tranquilamente. Era un día 
                        nublado y parecía que estaba a punto de llover. Pedro miraba con curiosidad las
                        nubes que se agrupaban en el cielo gris. De pronto, tuvo una duda y decidió acudir 
                        con su padre, quien siempre tenía una respuesta interesante para todo porque era un 
                        científico.<br/><br/> 
                        
                        —Papá, ¿el agua de la lluvia se puede beber? <br/><br/>
                        
                        Su padre solía dar respuestas claras y breves, pero esta vez se quedó mirando al cielo 
                        en silencio. Después de unos momentos, respondió: <br/><br/>
                        
                        —¿Por qué no me dices qué piensas tú? <br/><br/>
                        
                        Pedro se sorprendió de que su papá no tuviera una respuesta, pero aceptó su 
                        propuesta con entusiasmo. Se asomó por la ventana una vez una y exclamó:<br/><br/>
                        
                        —Las nubes están muy grises. Yo imagino que el agua que cae va a tener el mismo 
                        color y no se puede tomar. <br/><br/>
                        
                        —Muy bien, Pedro. Es verdad que las nubes tienen ese color. Pero no determina que 
                        pueda beberse el agua o no. ¿Estás seguro de que ése es el motivo? <br/><br/>
                        
                        —No, pero creo que así es. El agua contaminada siempre hace daño. <br/><br/>
                        
                        —¿Cómo podrías estar seguro?<br/><br/>
                        
                        Pedro se quedó pensativo un rato. En realidad, nunca se había preguntado las 
                        razones. Después respondió: <br/><br/>
                        
                        —¿Y si juntamos un poco dentro de un recipiente para ver? <br/><br/>
                        
                        —¡Sí! Muy bien, podemos hacer eso. <br/><br/>
                        
                        Los dos se dirigieron a la cocina y seleccionaron un vaso para dejarlo bajo la lluvia 
                        durante unos minutos. Pedro comprobó que estaba sucia una vez que se llenó. <br/><br/>
                        
                        —¡Te lo dije! No se puede ingerir. <br/><br/>
                        
                        —¿Cómo tienes la certeza de ello? <br/><br/>
                        
                        —Porque se ve gris.<br/><br/>
                        
                        —Sí, ¿pero estás completamente seguro de que ésa es la causa? ¿Qué tal si sólo es 
                        tierra, pero no tiene bacterias ni nada que te pueda enfermar? <br/><br/>
                        
                        —¿Entonces sí se podría beber? <br/><br/>
                        
                        —No lo sé. ¿Se te ocurre alguna manera de comprobarlo? <br/><br/>
                        
                        Recordó que su papá le había regalado un microscopio en su cumpleaños. <br/><br/>
                        
                        —¿Y si la vemos en el microscopio? <br/><br/>
                        
                        Entonces, fueron juntos adonde estaba el aparato y pusieron una gota debajo de la 
                        lente. Después se acercaron y vieron algunas bacterias.
                      </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <Grid container justifyContent="center" >
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img4to/Sesion44/1.png" alt="Tarjeta" />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container xs={12} >
                        <p className="Lectura ">
                            —Papá, se ve muy sucia. <br/><br/>
                            
                            —¿Entonces qué concluyes? ¿Piensas que el agua es bebible? <br/><br/>
                            
                            —No, yo creo que nos podemos enfermar si lo hacemos porque ahora ya hemos visto 
                            que tiene bacterias. Pero tú ya lo sabías, ¿verdad? ¿Por qué no me lo dijiste? <br/><br/>
                            
                            —No te respondí porque quería que aprendieras a usar el método científico. <br/><br/>
                            
                            —Pero yo no soy científico. <br/><br/>
                            
                            —¡Claro que sí! El método científico consiste en una serie de pasos. Primero, se hace 
                            una observación y después se plantea una pregunta. Tú estabas mirando las nubes 
                            cargadas de lluvia cuando te surgió la duda, ¿cierto? Después, te pregunté qué creías 
                            tú y me explicaste tu hipótesis, que es una posible respuesta a la pregunta. No 
                            estabas totalmente seguro, así que planteaste un experimento. Tal vez no te diste 
                            cuenta, pero al atrapar agua y ponerla bajo el microscopio estabas llevando a cabo 
                            una experimentación. Después analizaste tus resultados y viste que estaba sucia 
                            realmente. Llegaste a una conclusión gracias a eso: que el agua de lluvia no se puede 
                            tomar. Los investigadores siempre usamos este método para hacer descubrimientos. <br/><br/>
                            
                            —Entonces… ¿ya soy un científico como tú? <br/><br/>
                            
                            Su padre se rio y dijo:<br/><br/>
                            
                            —Sólo si sigues usando el método científico.
                        </p>
                    </Grid>
                </Grid>
        </Grid>
        <Grid container item style={{ marginTop: 80}} ></Grid>
    </Grid>
    );
}
 
export default El_método_científico ;
