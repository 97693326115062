import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({

    ContenedorLectura:{
        height:'100%',
            width:'502px',
                  [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
    },
    ContainerTitulo:{
        margin:'0 0 34px 0',
        '& .Titulo':{
            letterSpacing: '0px',
            color: '#F6644B',
            margin:'0',
            textAlign:'center',
            font:'43px fredoka one',
            lineHeight:'52px',
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            margin:'0 0 36px 0',
            color:'#000',
            font:'18px lato',
            lineHeight :'28px',
            letterSpacing: '0.17px',
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .SubTitulo':{
            color:'#161CB1',
            margin:'0',
            lineHeight :'28px',
            font:'bold 26px lato',
            letterSpacing: '0.24px',
            [theme.breakpoints.down(502)]:{
                font:'bold 18px lato',
                lineHeight :'24px',
                letterSpacing: '0.21px',
            },
        },
        '& .SubTitulo2':{
            color:'#D63390',
            margin:'0',
            font:'bold 22px lato',
            lineHeight :'24px',
            letterSpacing: '0.21px',
            [theme.breakpoints.down(502)]:{
                font:'bold 18px lato',
                lineHeight :'20px',
                letterSpacing: '0.16px',
            },
        },
        '& .Espacio':{
            position:'relative',
        },
        '& .SubTitulo3':{
            position:'absolute',
            color:'#FF1919',
            margin:'0',
            font:' 16px Fredoka One',
            lineHeight :'20px',
            [theme.breakpoints.down(502)]:{
                font:'bold 12px lato',
                lineHeight :'14px',
            },
        },
        '& .Pos1':{
                width:'101px',
                top:'59px',
                left:'260px',
            [theme.breakpoints.down(502)]:{
                width:'69px',
                top:'43px',
                left:'181px',
            },
        },
        '& .Pos2':{
            width:'95px',
            top:'294px',
            left:'94px',
            [theme.breakpoints.down(502)]:{
                width:'61px',
                top:'206px',
                left:'63px',
            },
        },
        '& .TextImg':{
            color:'#717476',
            margin:'21px 0 0 0',
            font:' 16px lato',
            lineHeight :'25px',
            letterSpacing: '0.15px',
            [theme.breakpoints.down(502)]:{
                font:' 11px lato',
                lineHeight :'14px',
                letterSpacing: '0.10px',
            },
        },
        '& .Imagen':{
            width:'100%',
        },  
      
    }
}));


const Un_sistema_solar_en_acción = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>

            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo " >Un sistema solar <br/>en acción</h2>

                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                      <p className="Lectura">
                        <h3 className='SubTitulo'>¿El Sol se mueve? </h3><br/>

                        Quizá tu respuesta inmediata sea “sí”, pues cuando amanece lo vemos en un sitio, al 
                        mediodía se coloca justo sobre nuestra cabeza y por la tarde se oculta del lado 
                        contrario al que lo vimos aparecer. Hace mucho tiempo se creía que la Tierra era el 
                        centro del universo y que el Sol y todos los astros giraban alrededor de ella. Esta 
                        manera de ver las cosas se conoce como <em>teoría geocéntrica</em>. Pero en realidad, el hecho 
                        de que observemos al Sol en distintos puntos a lo largo del día se debe al movimiento 
                        de nuestro planeta.<br/><br/>
                      </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <Grid container justifyContent="center" >
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img2do/Sesion4/1.png" alt="Tarjeta" />
                        </Grid>
                        
                        <Grid container >
                            <p className="TextImg">
                            Si pudieras tomar distancia para ver nuestro sistema solar, observarías que los planetas,
                            incluida la Tierra, giran alrededor del Sol.
                            </p>
                        </Grid>
                        
                    </Grid>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container sm={12}>
                      <p className="Lectura"> 
                        Después de casi 2 000 años de investigación, los científicos revelaron que la Tierra es 
                        la que se mueve de lugar con respecto al Sol, siguiendo un camino a su alrededor. A 
                        ese trayecto se le conoce como <em>movimiento de traslación</em>. Además, nuestro planeta 
                        gira sobre su propio eje, como un objeto que da vueltas sin moverse de lugar. Ése es 
                        el <em>movimiento de rotación</em>. Se piensa que la Tierra recorre 1 800 kilómetros de su 
                        órbita en un segundo.<br/><br/> 

                        ¿Lo sentiste? Justo acaba de moverse.
                      </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <Grid container justifyContent="center" className='Espacio'>
                            <h3 className='SubTitulo3 Pos1'>Movimiento de traslación</h3>
                            <h3 className='SubTitulo3 Pos2'>Movimiento de rotación</h3>

                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img2do/Sesion4/2.png" alt="Tarjeta" />
                        </Grid>
                        
                        <Grid container item justifyContent='center' >
                            <p className="TextImg">
                            Nuestro planeta no es estático, por el contrario, está en movimiento permanente.
                            </p>
                        </Grid>
                        
                    </Grid>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                      <p className="Lectura">
                        <h3 className='SubTitulo2'>La historia del Sol y sus oficios </h3><br/>

                        El Sol es una estrella de gran tamaño, alrededor de la cual se encuentran los planetas 
                        que conforman el sistema solar. Tiene unos 4 500 millones de años de antigüedad, es 
                        decir, es muy viejo. Está formado por hidrógeno y helio en su núcleo. En su superficie 
                        hay una gran actividad producida por las explosiones y el movimiento de los gases.<br/><br/>  
                        
                        La vida en la Tierra depende de la energía que recibe del Sol. Gracias a él las plantas 
                        hacen fotosíntesis, algunos animales cumplen con sus actividades, y la temperatura 
                        en el planeta es la adecuada para habitarlo. Su importancia es tal que sin él la Tierra 
                        perdería buena parte de sus movimientos.<br/><br/>  
                        
                        ¿Has reflexionado acerca de cómo influyen los movimientos de la Tierra en tu vida? 
                        Puedes pensar en qué estación del año ocurren tus celebraciones o temporada 
                        vacacional favoritas. Muchas de nuestras acciones están estrechamente 
                        relacionadas con los movimientos de nuestro planeta.
                      </p>
                    </Grid>
                </Grid>
        </Grid>
    </Grid>
    );
}
 
export default Un_sistema_solar_en_acción;
