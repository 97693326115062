import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';


const useStyles =  makeStyles (theme =>({
    ContenedorLectura:{
        position:'relative',
        width:'100%',
          [theme.breakpoints.down(767)]:{
            width:'350px',
          },
    },
    Parrafo1:{
        '& .Imagen':{
            width:'100%',
        },
    }
}));

const El_yoyo_de_la_señora_chu = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img1ro/Sesion34/F3.png" alt="Tarjeta" />
                </Grid>
        </Grid>
    </Grid>
    );
}
 
export default El_yoyo_de_la_señora_chu ;
