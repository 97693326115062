import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
    ContenedorLectura:{
        width:'502px',
                  [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
    },
    ContainerTitulo:{
        margin:'0 0 34px 0',
        '& .Titulo':{
            letterSpacing: '0px',
            color: '#F78572',
            margin:'0',
            textAlign:'center',
            font:'43px fredoka one',
            lineHeight:'52px',
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            margin:'0 0 36px 0',
            color:'#000',
            font:'18px lato',
            lineHeight :'28px',
            letterSpacing: '0.17px',
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .Imagen':{
            width:'100%',
        },  

      
    }
}));

const Como_un_pequeño_sol = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo" >
                        Como un pequeño sol
                    </h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                      <p className="Lectura">
                            La ciencia moderna debe su origen a todas las personas que han experimentado 
                            desde la Antigüedad con diversas sustancias en busca de oro, el elixir de la vida y la 
                            piedra filosofal. Se llamó a estos individuos <em>alquimistas</em> y el padre Pietro Francescoli 
                            fue uno de ellos. Ésta es su historia.<br/><br/>
                            
                            Pietro Francescoli era un herrero. Fue testigo desde pequeño de cómo el fuego 
                            transformaba los metales en diversos objetos. Su padre le contó los secretos de la
                            forja, así como él los había recibido de su abuelo. Ésa era la única forma de entender 
                            el enigma del fuego, pues era un conocimiento especial que los seres humanos 
                            habían heredado de Hefesto, el herrero de los dioses griegos. <br/><br/>
                            
                            Pietro ingresó como aprendiz al taller familiar a los siete años de edad y pronto 
                            adquirió la habilidad de fabricar clavos, sillas, cadenas y mesas. Pero él intuía que 
                            podían obtenerse otros elementos del fuego. Estaba consciente de que esos saberes 
                            no serían concedidos por su padre, por ello, decidió visitar otras ciudades a los 
                            quince años en busca de respuestas. <br/><br/>
                            
                            Descubrió muchas cosas en sus viajes. En una ocasión, conoció a un hombre que le 
                            regaló unos manuscritos antiguos provenientes de Egipto. En ellos, leyó por primera 
                            vez acerca de la piedra filosofal y el poder espiritual del fuego. Quedó fascinado por 
                            la lectura, pues era una clave importante que afirmaba sus sospechas. Regresó a casa 
                            después de eso y se hizo cargo del taller de su padre, quien ya era mayor para 
                            trabajar. <br/><br/>
                            
                            Se dedicó a estudiar el espíritu y el poder de los elementos a través de diversos 
                            libros, al mismo tiempo que fabricaba objetos para venderlos. Hacía experimentos en 
                            sus ratos libres, mezclaba plomo, mercurio y otras sustancias en redomas de vidrio. 
                            Luego las hacía arder y esperaba. Analizaba, observaba y anotaba los resultados. 
                            Sabía que además del agua, el fuego, el aire y la tierra, existía un quinto elemento 
                            capaz de transformar cualquier metal en oro. Sospechaba que podría prepararse con 
                            él una mezcla alquímica que concedería vida eterna a quien la bebiera.
                      </p>
                    </Grid>
                    <Grid container justifyContent="center" sm={12}>
                        <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img4to/Sesion49/1.png" alt="Tarjeta" />
                    </Grid>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                      <p className="Lectura">
                        Manipuló sustancias y forjó grandes piezas metálicas durante años, pero no 
                        consiguió oro ni el elíxir de la vida. Un día preparó un brebaje con un líquido dorado, 
                        pero le provocó dolores estomacales y fiebre por dos semanas. Retomó sus 
                        experimentos e investigaciones cuando se recuperó, contrario a lo que la gente 
                        esperaba. Sentía que estaba cerca de obtener la piedra filosofal. <br/><br/>
                        
                        Dedicó tanto tiempo a sus estudios que se olvidó del mundo y del amor. Nunca se 
                        casó ni tuvo hijos y murió solo en su taller. Dos jóvenes curiosos que eran sus 
                        ayudantes lo encontraron recostado sobre su escritorio una mañana. Aún tenía en la 
                        mano el punzón con el que escribía. Acomodaron el cuerpo en su catre. Uno de ellos 
                        avisó a las autoridades de la ciudad mientras el otro guardó el manuscrito que Pietro 
                        había dejado incompleto, sin leerlo. Nadie se percató del fuerte olor que provenía del 
                        horno.<br/><br/>
                        
                        Los muchachos fueron al taller a recoger sus cosas al día siguiente del funeral. Una 
                        luz que emanaba del horno llamó su atención antes de salir. Abrieron la puerta y 
                        encontraron una pepita de auténtico oro, resplandeciente como un pequeño sol.
                      </p>
                    </Grid>
                </Grid>
        </Grid>
    </Grid>
    );
}
 
export default Como_un_pequeño_sol ;
