import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
    ContenedorLectura:{
        height:'100%',
        width:'502px',
                  [theme.breakpoints.down(502)]:{
            width:'350px',
            padding: '60px 0 0 0',
        },
        [theme.breakpoints.down(350)]:{
            width:'300px',
            padding: '30px 0 0 0',
        },
    },
    ContainerTitulo:{
        margin:'0 0 34px 0',
        '& .Titulo':{
            letterSpacing: '0px',
            color: '#EC0042',
            margin:'0',
            textAlign:'center',
            font:'43px fredoka one',
            lineHeight:'52px',
            [theme.breakpoints.down(502)]:{
                font:'30px fredoka one',
                lineHeight:'32px',
            },
            [theme.breakpoints.down(350)]:{
                font:'28px fredoka one',
                lineHeight:'32px',
            },
        },
    },
    Parrafo1:{
        margin:' 0 0 36px 0',
        '& .Lectura':{
            margin:'0 0 36px 0',
            color:'#000',
            font:'18px lato',
            lineHeight :'28px',
            letterSpacing: '0.17px',
            [theme.breakpoints.down(502)]:{
                font:'16px lato',
                lineHeight :'21px',
                letterSpacing: '0.09px',
            },
            [theme.breakpoints.down(350)]:{
                font:'12px lato',
                lineHeight :'17px',
                letterSpacing: '0.09px',
            },
        },
        '& .TextImg':{
            color:'#717476',
            margin:'24px 0 0 0',
            font:' 16px lato',
            lineHeight :'25px',
            letterSpacing: '0.15px',
            [theme.breakpoints.down(502)]:{
                font:' 11px lato',
                lineHeight :'14px',
                letterSpacing: '0.10px',
            },
        },

        '& .Imagen':{
            width:'100%',
            
        },  
        '& .Imagen2':{
            width:'68.4%',
        },  
      
    }
}));

const Diferentes_representaciones_de_los_números = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.ContainerTitulo} justifyContent="center">
                    <h2 className="Titulo ">Diferentes<br/> representaciones <br/>de los números</h2>
                </Grid>
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                    <Grid container sm={12}>
                       <p className='Lectura'>
                        Los seres humanos siempre han necesitado contar diferentes elementos de su 
                        mundo. Pero antes no había números como los que ahora conoces, así que las 
                        personas solamente contaban con sus dedos u otros objetos.<br/><br/> 
                        
                        Al pasar el tiempo, los seres humanos necesitaron contar cantidades mayores, así 
                        que los dedos ya no funcionaban. Por ello, inventaron símbolos que representaban 
                        cantidades. A estos conjuntos de símbolos se les llamó <em>sistemas de numeración</em>. <br/><br/> 
                        
                        Uno de los primeros sistemas fue el egipcio, que representaba cifras mediante 
                        dibujos. Tenían una espiral, un dedo e incluso una rana.
                       </p>                   
                    </Grid>
                    <Grid container justifyContent='center'>
                        <Grid container item justifyContent='center'>
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img1ro/Sesion10/1.png" alt="Tarjeta" />
                        </Grid>
                        <Grid container justifyContent='center' >
                            <p className="TextImg">
                                El sistema de numeración egipcio.
                            </p>
                        </Grid>
                    </Grid>
                    
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container >
                        <p className='Lectura'>
                            La cultura romana utilizó letras. Si un maestro romano tenía X alumnos significaba 
                            que tenía 10 niños en su clase. ¿Qué significaba la L? ¡Mira la tabla!
                        </p>
                    </Grid>
                    <Grid container justifyContent='center'>
                        <Grid container justifyContent='center'>
                            <img className="Imagen2" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img1ro/Sesion10/2.png" alt="Tarjeta" />
                        </Grid>
                        <Grid container justifyContent='center' >
                            <p className="TextImg">
                                El sistema de numeración romano.                            
                            </p>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container >
                        <p className='Lectura'>
                            Los mayas usaron un símbolo especial para el 0. El resto de sus números se formaba 
                            con puntos y líneas, aunque sólo tenían 20 símbolos diferentes: del 0 al 19.
                        </p>
                    </Grid>
                    <Grid container justifyContent='center'>
                        <Grid container justifyContent='center'>
                            <img className="Imagen" src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img1ro/Sesion10/3.png" alt="Tarjeta" />
                        </Grid>
                        <Grid container justifyContent='center' >
                            <p className="TextImg">
                            El sistema de numeración maya.                            
                            </p>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center" >
                    <Grid container >
                        <p className='Lectura'>
                            Los indios desarrollaron un sistema de diez dígitos: 0, 1, 2, 3, 4, 5, 6, 7, 8 y 9. El resto 
                            de los números se puede formar combinando dos o más dígitos. Los árabes llevaron 
                            este sistema al resto del mundo. Le llamamos <em>sistema decimal</em> y los números que usas 
                            en tu clase de matemáticas se llaman <em>números arábigos</em>.
                        </p>
                    </Grid>
                </Grid>
            </Grid>
    </Grid>
    );
}
 
export default Diferentes_representaciones_de_los_números;

