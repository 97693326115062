import { Grid,makeStyles}              from '@material-ui/core';
import React                           from 'react';

const useStyles =  makeStyles (theme =>({
     Contenedor:{
        background:'#E9FBF9',
        width:'100vw',
        overflowX:'clip',
        [theme.breakpoints.down(1161)]:{
            background:'none',
           overflowX:'unset',
        },
        [theme.breakpoints.down(600)]:{
            marginTop: '79.51px',
        }
    },
  ContainerInfoLectura: {
    position: "relative",
    height: "86px",
    [theme.breakpoints.down(502)]:{
        height: "43px",
    },
    background: "#76D4C3",
    "& .BarraInfoLectura": {
      height: "86px",
      background: `Url(https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Barras/Azul.png)`,
        [theme.breakpoints.down(502)]:{
            height: "43px",
        },
    },
    "& .InfoLectura": {
      position: "absolute",
      height: "86px",
      width: "219px",
      boxSizing: "border-box",
      padding: "17.2px 0",
      background: "#FAFAFA",
      borderRadius: "50px",
      boxShadow: "2px 2px 2px 2px #00000029",
      zIndex: "4",
        [theme.breakpoints.down(502)]:{
            height: "43px",
            width: "109px",
            padding: "8.6px 0",
            borderRadius: "25px",
        },
    },
    "& .InfoLectura:before": {
      position: "absolute",
      display: "flex",
      boxSizing: "border-box",
      padding: "0 0 0 10px",
      justifyContent: "center",
      alignItems: "center",
      height: "39.88px",
      width: "39.88px",
      borderRadius: "50%",
      content: '""',
      boxShadow: "3px 0px .5px 0px #00000029",
      background: "#FAFAFA",
      left: "90px",
      top: "65px",
      transform: "rotate(0.25turn)",
      [theme.breakpoints.down(502)]:{
        left: "45px",
        top: "30px",
        height: "20.88px",
        width: "20.88px",
        },
    },
    "& .InfoLectura:after": {
      content: '""',
      height: "15px",
      width: "15px",
      borderTop: "2px solid #20A698",
      borderLeft: "2px solid #20A698",
      position: "absolute",
      bottom: "-10px",
      left: "Calc(50% - 8px)",
      transform: "rotate(0.625turn)",
      [theme.breakpoints.down(502)]:{
        height: "10px",
        width: "10px",
        left: "Calc(50% - 4.5px)",
        bottom: "-3px",
        borderTop: "1px solid #20A698",
        borderLeft: "1px solid #20A698",
        },
    },
    "& .Sesion": {
      borderLeft: "2px solid #D8D8D8",
    },
    "& .Dato": {
      margin: "0 0 5px 0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#20A698",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        margin: "0 0 2.5px 0",
        },
    },
    "& .Caracter": {
      margin: "0",
      textAlign: "center",
      font: "bold 17px lato",
      color: "#000000",
      [theme.breakpoints.down(502)]:{
        font: "bold 12px lato",
        },
    },   },
    ContenedorLectura:{
        background:'#fff',
        width:'1160px',
        height:'100%',
        padding:'0',
        [theme.breakpoints.down(1161)]:{
            width:'768px',
          },
          [theme.breakpoints.down(768)]:{
            width:'350px',
          },
        '& .Derechos':{
            position:'absolute',
            bottom:'33px',
            textAlign:"center",
            margin:"0px",
            font:" 15px lato",
            color:"#FFFFFF",
            [theme.breakpoints.down(1161)]:{
                display:'none'
            },
        },
        '& .Escritorio':{
            display:'block',
            [theme.breakpoints.down(1161)]:{
                display:'none'
            },
        },
        '& .Tablet':{
            display:'none',
            [theme.breakpoints.down(1161)]:{
                display:'block'
            },
        }
    },
    Parrafo1:{
        position:'relative',
        [theme.breakpoints.down(768)]:{
            display:'none'
        },
        '& .Titulo':{
            position:'absolute',
            font:'59px fredoka one',
            letterSpacing: '0px',
            lineHeight:'71px',
            color: '#FFFFFF',
            margin:'0',
            top:'2.17%',
            [theme.breakpoints.down(1161)]:{
                font:'39px fredoka one',
                lineHeight:'47px',
            },
            [theme.breakpoints.down(768)]:{
                font:'17px fredoka one',
                lineHeight:'25px',
            },
        },
        '& .Titulo2':{
            position:'absolute',
            font:'48px fredoka one',
            letterSpacing: '0px',
            lineHeight:'58px',
            color: '#000000',
            margin:'0',
            top:'17%',
            [theme.breakpoints.down(1161)]:{
                font:'31px fredoka one',
                lineHeight:'37px',
            },
            [theme.breakpoints.down(768)]:{
                font:'14px fredoka one',
                lineHeight:'18px',
            },
        },
        '& .SubTitulo':{
            position:'absolute',
            font:'bold 33px lato',
            textAlign:'center',
            color:'#000000',
            lineHeight:'41px',
            margin:'0',
            top:'19.53%',
            [theme.breakpoints.down(1161)]:{
                font:'bold 22px lato',
                lineHeight :'27px',
            },
            [theme.breakpoints.down(768)]:{
                font:'bold 10px lato',
                lineHeight :'13px',
            },
        },
        '& .Barra':{
            borderBottom:'solid 5px #FF0000',
            [theme.breakpoints.down(768)]:{
                borderBottom:'solid 1px #FF0000',
            },
        },
    
        '& .SubTitulo2':{
            position:'absolute',
            font:'40px Fredoka One',
            textAlign:'center',
            color:'#FFFFFF',
            lineHeight:'48px',
            margin:'0',
            top:'54.90%',
            [theme.breakpoints.down(1161)]:{
                font:'26px Fredoka One',
                lineHeight :'31px',
            },
            [theme.breakpoints.down(768)]:{
                font:'12px Fredoka One',
                lineHeight :'16px',
            },
        },
        '& .SubTitulo3':{
            position:'absolute',
            font:'40px Fredoka One',
            textAlign:'center',
            color:'#1A20AF',
            lineHeight:'48px',
            margin:'0',
            top:'71.84%',
            [theme.breakpoints.down(1161)]:{
                font:'26px Fredoka One',
                lineHeight :'31px',
            },
            [theme.breakpoints.down(768)]:{
                font:'12px Fredoka One',
                lineHeight :'16px',
            },
        },

        '& .Lectura':{
            margin:'0 ',
            color:'#000000',
            font:' 28px lato',
            lineHeight : '35px',
            letterSpacing: '0.26px',
            [theme.breakpoints.down(1161)]:{
                font:' 18px lato',
                lineHeight :'23px',
                letterSpacing: '0.17px',
            },
            [theme.breakpoints.down(768)]:{
                font:'8.5px lato',
                lineHeight :'12px',
                letterSpacing: '0.10px',
            },
        },

        '& .ul':{
            padding:'0 0 0 19px',
            margin:0,
            color:'#FF0000',
            fontSize:'28px',
            [theme.breakpoints.down(1161)]:{
                fontSize:'18px',
            },
            [theme.breakpoints.down(768)]:{
                fontSize:'13px',
            },
        },
        '& .li':{
            margin:'0 0 8px 0',
            [theme.breakpoints.down(768)]:{
                margin:'0 0 4px 0',
            },
        },
        '& .Esp':{
            margin:'0',
        },
        '& .Text':{
            position:'absolute',
            top:'92.51%',
            left:'8.45%',
            margin:'0 ',
            color:'#000000',
            font:' 22px lato',
            letterSpacing: '0.26px',
            [theme.breakpoints.down(1161)]:{
                font:' 16px lato',
                letterSpacing: '0.15px',
            },
            [theme.breakpoints.down(768)]:{
                font:'6px lato',
                letterSpacing: '0.10px',
            },
        },
        '& .Pos1':{
            position:'absolute',
            top:'22.45%',
        },
        '& .Pos2':{
            position:'absolute',
            top:'59.04%',
            left:'10.25%'
        },
        '& .Pos3':{
            position:'absolute',
            top:'74.65%',
            left:'10.25%'
        },
        
        '& .Pos4':{
            top:'86.85%',
            textAlign:'center',
        },

        '& .Imagen':{
            [theme.breakpoints.down(1161)]:{
                width:'100%',
            },
        },
    },
    Imagen2:{
        width:'100%',
        display:'none',
        [theme.breakpoints.down(768)]:{
            width:'100%',
            display:'flex'
        },
    }
}));

const Anuncio_impresora = () => {
const classes = useStyles ()
    return (  
        <Grid container item justifyContent="center" xs={12} className={classes.Contenedor}>
             <Grid container item justifyContent="center" className={classes.ContainerInfoLectura}>
               <Grid container className="BarraInfoLectura"></Grid>
               <Grid container item className="InfoLectura">
                    <Grid container item direction="column" xs={6} justifyContent="center">
                        <p className="Dato">Grado</p>
                        <p className="Caracter">3</p>
                    </Grid>
                    <Grid container item direction="column" xs={6} justifyContent="center" className="Sesion">
                        <p className="Dato">Sesión</p>
                        <p className="Caracter">43</p>
                    </Grid>
               </Grid>
            </Grid>

            <Grid container item className={classes.ContenedorLectura} justifyContent="center">
                <Grid container item sm={12} className={classes.Parrafo1} justifyContent="center">
                        <img className="Imagen " src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img3ro/Sesion43/Grupo 2867.png" alt="Tarjeta" />
                        <h2 className='Titulo'>
                            Anuncio impresora 3D
                        </h2>
                        <h3 className='Titulo2'>
                            ¿Te gustaría crear tus propios juguetes?
                        </h3>
                        <h3 className='SubTitulo'>
                            Sólo debes tener… ¡la <span className="Barra">Super Hero Designer Pro!</span>
                        </h3>
                        <p className='Lectura Pos1'>
                            Una impresora para diseñar figuras de acción ¡directo de tu imaginación! 
                        </p>
                        <h3 className='SubTitulo2'>
                            Con Super Hero Designer Pro podrás hacer todo esto:
                        </h3>

                        <div className='Pos2'>
                            <ul className='ul'>
                                <li className='li'>
                                    <p className='Lectura Esp'>
                                        Crear una figura de acción en 3D desde tu casa.
                                    </p>
                                </li>
                                <li className='li'>
                                    <p className='Lectura Esp'>
                                        Descargar los modelos de tus superhéroes favoritos y<br/>
                                        tenerlos en cuestión de minutos.
                                    </p>
                                </li>
                                <li className='li'>
                                    <p className='Lectura Esp'>
                                        Modificar y decorar ejemplares de cualquier superhéroe.
                                    </p>
                                </li>
                                <li className='li'>
                                    <p className='Lectura Esp'>
                                        Combinar las características de tus personajes preferidos en uno solo.
                                    </p>
                                </li>
                                <li className='li'>
                                    <p className='Lectura Esp'>
                                        Diseñar vestuarios, herramientas y artefactos adicionales.
                                    </p>
                                </li>
                                <li className='li'>
                                    <p className='Lectura Esp'>
                                        Colorear los moldes a tu gusto.
                                    </p>
                                </li>
                            </ul>

                        </div>
                        
                        <h3 className='SubTitulo3'>
                            Y no sólo eso. Además, podrás…
                        </h3>

                        <div className='Pos3'>
                            <ul className='ul '>
                                <li className='li'>
                                    <p className='Lectura Esp'>
                                        Usar cualquier impresora 3D con la aplicación de Super Hero<br/> 
                                        Designer Pro. También es posible agregar una a la compra de<br/> 
                                        tu aplicación y la recibirás en menos de 24 horas.
                                    </p>
                                </li>
                                <li className='li'>
                                    <p className='Lectura Esp'>
                                        ¡Incluir a los protagonistas de tus sagas favoritas!
                                    </p>
                                </li>
                                <li className='li'>
                                    <p className='Lectura Esp'>
                                        Tener personajes nuevos con cada actualización.
                                    </p>
                                </li>
                                <li className='li'>
                                    <p className='Lectura Esp'>
                                        Adquirir aplicaciones complementarias y descargar modelos<br/> de juguetes clásicos.
                                    </p>
                                </li>
                                
                            </ul>
                        </div>
                        
                        <h3 className='Titulo2 Pos4'>
                            ¡Descarga Super Hero Designer Pro ahora<br/>
                             y crea tu historia de superhéroes!
                        </h3>

                        <p className='Text'>
                        * La impresora de tinta se vende por separado.<br/>
                        * La disponibilidad de colores depende del catálogo.
                        </p>

                        <p className="Derechos">
                            © 2022. Todos los derechos reservados, Pearson, Circuito de lectores.
                        </p>    
                </Grid>
                <img className={classes.Imagen2} src="https://img-lecturas-cdl.s3.us-west-1.amazonaws.com/Imagenes+Lecturas/Img3ro/Sesion43/F.png" alt="Tarjeta" />

            
        </Grid>
    </Grid>
    );
}
 
export default Anuncio_impresora;
